import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem, ScrollPanel } from 'primeng';
import { AppMainComponent } from './app.main.component';
import { UsuarioEsquema } from './usuarioEsquema';
import { Usuario } from './usuario';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendService } from './servicios/backend.service';
import { log } from 'util';
import { UtilitariosService } from './advantage/advantageservices/utilitarios.service';
import { ConfInicialesService } from './servicios/conf-iniciales.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
  animations: [
    trigger('inline', [
      state('hiddenAnimated', style({
        height: '0px',
        overflow: 'hidden'
      })),
      state('visibleAnimated', style({
        height: '*',
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppMenuComponent implements OnInit, AfterViewInit {

  @Input() reset: boolean;

  model: any[];

  items: any[];
  newTab: boolean;
  tabs: any;
  open = true;
  url: any;
  way:any;
  inlineModel: any[];
  opcionesmenu: any;
  aux: any;
  modulos: any;

  inlineUserMenuActive = false;

  isSingleClick = true;

  @ViewChild('layoutMenuScroller', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;

  constructor(public app: AppMainComponent,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    public route: Router,
    public http: HttpClient,
    public backService: BackendService,
    private utilitariosService: UtilitariosService,
    private confIniciales: ConfInicialesService
  ) {
  }

  ngOnInit() {
    this.usuarioEsquema.loadStorage();
    this.usuario.loadStorage();
    this.backService.getUrl(resp => {
      this.url = resp;
      this.way = sessionStorage.getItem('way');

      this.newTab = false;
      this.tabs = [
        {

          label: 'Inicio',
          component: 'adasdsa'
        },
        /*{
          label: 'Articulo',
          component: 'frmINV_MAEARTICULO'
        },*/
        /*{
          label: 'imagenes',
          component: 'pruebas'
        }*/
      ];
      this.open = true;


      this.consularModulos().subscribe((respuesta) => {
        // console.log(respuesta);
        this.aux = respuesta;
        // this.modulos = JSON.parse(this.aux._body);
        this.modulos = this.aux;
        // consulta de los sub menus
        if (respuesta === 'no tiene acceso') {
          this.route.navigate(['login']);

          return;
        }
        this.ConsultarOpcionesMenu().subscribe(opcmenu => {

          this.aux = opcmenu;
          // this.opcionesmenu = JSON.parse(this.aux._body);
          this.opcionesmenu = this.aux;
          // console.log(this.opcionesmenu);
          this.consultaModulosMenu().subscribe(menumod => {

            this.aux = menumod;
            // let menu = JSON.parse(this.aux._body);
            const menu = this.aux;
            // tslint:disable-next-line:forin
            for (const mod in this.modulos) {
              this.modulos[mod].menu = menu;
              this.modulos[mod].menu = menu.filter(x => {
                if (x.MODPREFIJO === this.modulos[mod].MODPREFIJO) {
                  return true;
                }
              });
              // tslint:disable-next-line:forin
              for (const men in this.modulos[mod].menu) {
                this.modulos[mod].menu[men].opcionesmenu = this.opcionesmenu.filter(x => {
                  if (x.MENCODIGO === this.modulos[mod].menu[men].MENCODIGO && x.MODPREFIJO === this.modulos[mod].MODPREFIJO) {
                    return true;
                  }
                });
              }

            }
            this.llenaritems(this.modulos);
          });

        });
      }, error => {
        console.log(error);
        // this.snackbar.open('Error al realizar la consulta en la base de datos', 'cerrar', {duration: 4000});
      });


      this.model = [
        { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
        {
          label: 'Layouts', icon: 'pi pi-fw pi-th-large',
          items: [
            { label: 'Static', icon: 'pi pi-fw pi-bars', command: event => this.app.changeMenuMode('static') },
            { label: 'Overlay', icon: 'pi pi-fw pi-bars', command: event => this.app.changeMenuMode('overlay') },
            {
              label: 'Slim', icon: 'pi pi-fw pi-bars', command: event => {
                this.app.changeMenuMode('slim');
                this.app.inlineUser = false;
                this.inlineUserMenuActive = false;
              }
            },
            {
              label: 'Horizontal', icon: 'pi pi-fw pi-bars', command: event => {
                this.app.changeMenuMode('horizontal');
                this.app.inlineUser = false;
                this.inlineUserMenuActive = false;
              }
            },
            {
              label: 'Orientation', icon: 'pi pi-fw pi-align-right',
              items: [
                {
                  label: 'LTR', icon: 'pi pi-fw pi-align-left', command: (event) => {
                    this.app.isRTL = false;
                  }
                },
                {
                  label: 'RTL', icon: 'pi pi-fw pi-align-right', command: (event) => {
                    this.app.isRTL = true;
                  }
                }
              ]
            }
          ]
        },
        {
          label: 'Topbar Colors', icon: 'pi pi-fw pi-pencil',
          items: [
            {
              label: 'Light', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-light', 'logo-roma')
            },
            {
              label: 'Dark', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-dark', 'logo-roma-white')
            },
            {
              label: 'Blue', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-blue', 'logo-roma-white')
            },
            {
              label: 'Green', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-green', 'logo-roma-white')
            },
            {
              label: 'Orange', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-orange', 'logo-roma-white')
            },
            {
              label: 'Magenta', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-magenta', 'logo-roma-white')
            },
            {
              label: 'Blue Grey', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-bluegrey', 'logo-roma-white')
            },
            {
              label: 'Deep Purple', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-deeppurple', 'logo-roma-white')
            },
            {
              label: 'Brown', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-brown', 'logo-roma-white')
            },
            {
              label: 'Lime', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-lime', 'logo-roma-white')
            },
            {
              label: 'Rose', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-rose', 'logo-roma-white')
            },
            {
              label: 'Cyan', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-cyan', 'logo-roma-white')
            },
            {
              label: 'Teal', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-teal', 'logo-roma-white')
            },
            {
              label: 'Deep Orange', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-deeporange', 'logo-roma-white')
            },
            {
              label: 'Indigo', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-indigo', 'logo-roma-white')
            },
            {
              label: 'Pink', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-pink', 'logo-roma-white')
            },
            {
              label: 'Purple', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTopbarColor('layout-topbar-purple', 'logo-roma-white')
            }
          ]
        },
        {
          label: 'Menu Colors', icon: 'pi pi-fw pi-list',
          items: [
            { label: 'Light', icon: 'pi pi-fw pi-circle-off', command: event => this.app.lightMenu = true },
            { label: 'Dark', icon: 'pi pi-fw pi-circle-on', command: event => this.app.lightMenu = false }
          ]
        },
        {
          label: 'User Profile', icon: 'pi pi-fw pi-user',
          items: [
            {
              label: 'Popup', icon: 'pi pi-fw pi-user', command: event => {
                this.app.inlineUser = false;
                this.inlineUserMenuActive = false;
              }
            },
            {
              label: 'Inline', icon: 'pi pi-fw pi-user', command: event => {
                if (this.app.isStatic() || this.app.isOverlay()) {
                  this.app.inlineUser = true;
                }
              }
            }
          ]
        },
        {
          label: 'Theme', icon: 'pi pi-fw pi-pencil',
          items: [
            {
              label: 'Blue', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('blue')
            },
            {
              label: 'Green', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('green')
            },
            {
              label: 'Orange', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('orange')
            },
            {
              label: 'Magenta', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('magenta')
            },
            {
              label: 'Blue Grey', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('bluegrey')
            },
            {
              label: 'Deep Purple', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('deeppurple')
            },
            {
              label: 'Brown', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('brown')
            },
            {
              label: 'Lime', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('lime')
            },
            {
              label: 'Rose', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('rose')
            },
            {
              label: 'Cyan', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('cyan')
            },
            {
              label: 'Teal', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('teal')
            },
            {
              label: 'Deep-Orange', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('deeporange')
            },
            {
              label: 'Indigo', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('indigo')
            },
            {
              label: 'Pink', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('pink')
            },
            {
              label: 'Purple', icon: 'pi pi-fw pi-pencil',
              command: (event) => this.changeTheme('purple')
            }
          ]
        },
        {
          label: 'Components', icon: 'pi pi-fw pi-star',
          items: [
            { label: 'Sample Page', icon: 'pi pi-fw pi-th-large', routerLink: ['/sample'] },
            { label: 'Forms', icon: 'pi pi-fw pi-file', routerLink: ['/forms'] },
            { label: 'Data', icon: 'pi pi-fw pi-table', routerLink: ['/pagos'] },
            { label: 'Panels', icon: 'pi pi-fw pi-list', routerLink: ['/panels'] },
            { label: 'Overlays', icon: 'pi pi-fw pi-clone', routerLink: ['/overlays'] },
            { label: 'Menus', icon: 'pi pi-fw pi-plus', routerLink: ['/menus'] },
            { label: 'Messages', icon: 'pi pi-fw pi-envelope', routerLink: ['/messages'] },
            { label: 'Charts', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/charts'] },
            { label: 'File', icon: 'pi pi-fw pi-upload', routerLink: ['/file'] },
            { label: 'Misc', icon: 'pi pi-fw pi-spinner', routerLink: ['/misc'] }
          ]
        },
        {
          label: 'Pages', icon: 'pi pi-fw pi-copy',
          items: [
            { label: 'Empty', icon: 'pi pi-fw pi-clone', routerLink: ['/empty'] },
            { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'], target: '_blank' },
            { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
            { label: 'Error', icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['/error'], target: '_blank' },
            { label: '404', icon: 'pi pi-fw pi-times', routerLink: ['/404'], target: '_blank' },
            {
              label: 'Access Denied', icon: 'pi pi-fw pi-ban',
              routerLink: ['/accessdenied'], target: '_blank'
            }
          ]
        },
        {
          label: 'Hierarchy', icon: 'pi pi-fw pi-sitemap',
          items: [
            {
              label: 'Submenu 1', icon: 'pi pi-fw pi-sign-in',
              items: [
                {
                  label: 'Submenu 1.1', icon: 'pi pi-fw pi-sign-in',
                  items: [
                    { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-sign-in' },
                    { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-sign-in' },
                    { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-sign-in' },
                  ]
                },
                {
                  label: 'Submenu 1.2', icon: 'pi pi-fw pi-sign-in',
                  items: [
                    { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-sign-in' }
                  ]
                },
              ]
            },
            {
              label: 'Submenu 2', icon: 'pi pi-fw pi-sign-in',
              items: [
                {
                  label: 'Submenu 2.1', icon: 'pi pi-fw pi-sign-in',
                  items: [
                    { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-sign-in' },
                    { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-sign-in' },
                  ]
                },
                {
                  label: 'Submenu 2.2', icon: 'pi pi-fw pi-sign-in',
                  items: [
                    { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-sign-in' },
                  ]
                },
              ]
            }
          ]
        },
        {
          label: 'Docs', icon: 'pi pi-fw pi-file', routerLink: ['/documentation']
        },
        {
          label: 'Buy Now', icon: 'pi pi-fw pi-money-bill', url: ['https://www.primefaces.org/store']
        }
      ];


      this.inlineModel = [
        {
          label: 'Profile', icon: 'pi pi-fw pi-user'
        },
        {
          label: 'Settings', icon: 'pi pi-fw pi-cog'
        },
        {
          label: 'Messages', icon: 'pi pi-fw pi-envelope'
        },
        {
          label: 'Notifications', icon: 'pi pi-fw pi-bell'
        }
      ];


    });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.layoutMenuScrollerViewChild.moveBar();
    }, 100);
  }

  consularModulos(): Observable<any> {
    return this.http.post(this.way + '/loginsql/cc5f96c37d5', {
      elementos: {
        usuario: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions());
  }

  consultaModulosMenu(): Observable<any> {
    return this.http.post(this.way + '/loginsql/198bf2d86d9d7', {
      elementos: {
        usuario: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions());
  }

  ConsultarOpcionesMenu(): Observable<any> {
  
    return this.http.post(this.way + '/loginsql/198bf319b3afe', {
      elementos: {
        usuario: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions());
  }

  methodforclick(event) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        // this.app.openNewTab(event.item.opcobjeto, event.item.label, event.item.modprefijo);
        this.methodfordoubleclick(event);
      }
    }, 250);
  }

  methodfordoubleclick(event) {
    this.isSingleClick = false;
    this.app.openNewTab(event.item.opcobjeto, event.item.label, event.item.modprefijo, event.item.labelmenu);
    this.utilitariosService.timeLeft = 5 * 60;
  }

  llenaritems(menuitems) {
    const array = [];
    // tslint:disable-next-line:forin
    for (const mod in menuitems) {
      array.push({
        label: menuitems[mod].MODNOMBRE
      });
      const item = [];
      // tslint:disable-next-line:forin
      for (const menu in menuitems[mod].menu) {
        // item.push({ label: menuitems[mod].menu[menu].MENNOMBRE});

        const opciones = [];
        // tslint:disable-next-line:forin
        for (const opc in menuitems[mod].menu[menu].opcionesmenu) {
          opciones.push({
            label: menuitems[mod].menu[menu].opcionesmenu[opc].OPCNOMBRE,
            icon: this.addicon(menuitems[mod].menu[menu].MENCODIGO),
            opcobjeto: menuitems[mod].menu[menu].opcionesmenu[opc].OPCOBJETO,
            modprefijo: menuitems[mod].menu[menu].opcionesmenu[opc].MODPREFIJO,
            labelmenu: menuitems[mod].menu[menu].MENNOMBRE,
            command: (event) => {
              // console.log(event.item.opcobjeto);
              this.methodforclick(event);
            }
          });
        }
        item.push({
          label: menuitems[mod].menu[menu].MENNOMBRE,
          icon: this.addicon(menuitems[mod].menu[menu].MENCODIGO),
          items: opciones
        });
        array[mod].items = item;
      }

    }
    array.push({
      label: 'SALIR',
      icon: 'pi pi-sign-out',
      command: (event) => {
        this.confIniciales.cerrarSesion();                
      },
      routerLink: ['/login']
    });
    this.items = array;
    console.log('items',this.items)
  }

  /*openNewTab(opcion, label, modprefijo) {
      if (opcion === 'frmSACI_MAEEMPRESAS' || 'frmTOUCHPANTALLA') {
          this.newTab = true;
      }
      let posicion;
     // posicion = this.init.obtenerComponenteNum();
      console.log(opcion, label);
      this.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
      });
      //this.calc.editFocus(0, posicion);
      //this.value = this.tabs.length;
  }*/
  addicon(menuitem) {
    let icon: string;
    switch (menuitem) {
      case 1: {
        icon = 'pi pi-folder-open'; // matenimiento de archivos
        break;
      }
      case 2: {
        icon = 'pi pi-file'; // reportes
        break;
      }
      case 3: {
        icon = 'pi pi-th-large'; // procesos
        break;
      }
      case 4: {
        icon = 'pi pi-sitemap'; // utilitarios
        break;
      }
      case 5: {
        icon = 'pi pi-cog'; // configuracion
        break;
      }
      case 6: {
        icon = 'pi pi-pencil'; // ingreso de docuemntos
        break;
      }
      case 7: {
        icon = 'pi pi-list'; // listados
        break;
      }
      case 8: {
        icon = 'pi pi-briefcase'; // mantenimiento de archivos
        break;
      }
      case 17: {
        icon = 'pi pi-table'; // Presupuesto general plan de ctas
        break;
      }
      case 18: {
        icon = 'pi pi-clone'; // Presupuesto por centro de costos
        break;
      }
      default: {
        icon = 'pi pi-cog';
        break;
      }
    }
    return icon;
  }

  changeTheme(theme: string) {
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    themeLink.href = 'assets/theme/' + 'theme-' + theme + '.css';
  }

  changeTopbarColor(topbarColor, logo) {
    this.app.topbarColor = topbarColor;
    const topbarLogoLink: HTMLImageElement = document.getElementById('topbar-logo') as HTMLImageElement;
    topbarLogoLink.src = 'assets/layout/images/' + logo + '.svg';
  }

  onMenuClick(event) {
    if (!this.app.isHorizontal()) {
      setTimeout(() => {
        this.layoutMenuScrollerViewChild.moveBar();
      }, 450);
    }
    this.app.onMenuClick(event);
  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
  
	  <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
		  <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *ngIf="child.visible === false ? false : true">
			  <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
				 *ngIf="!child.routerLink" [ngClass]="child.styleClass"
				 [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
				  <i [ngClass]="child.icon" class="layout-menuitem-icon"></i>
				  <span class="layout-menuitem-text">{{child.label}}</span>
				  <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
        </a>
        
			  <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
				 [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [fragment]="child.fragment"
				 [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
				  <i [ngClass]="child.icon" class="layout-menuitem-icon"></i>
				  <span class="layout-menuitem-text">{{child.label}}</span>
				  <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
			  </a>
			  <div class="layout-menu-tooltip">
				  <div class="layout-menu-tooltip-arrow"></div>
				  <div class="layout-menu-tooltip-text">{{child.label}}</div>
			  </div>
			  <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
				  [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
		  </li>
	  </ng-template>
  `,
  animations: [
    trigger('children', [
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*',
        'z-index': 100
      })),
      state('hidden', style({
        height: '0px',
        'z-index': '*'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppSubMenuComponent {

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _parentActive: boolean;

  _reset: boolean;

  activeIndex: number;

  constructor(public app: AppMainComponent, public appMenu: AppMenuComponent) {
  }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
    console.log('1')
    // avoid processing disabled items
    if (item.disabled) {
      console.log('2')
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = (this.activeIndex === index) ? null : index;

    // execute command
    if (item.command) {
      console.log('3')
      item.command({ originalEvent: event, item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      console.log('4')
      setTimeout(() => {
        this.appMenu.layoutMenuScrollerViewChild.moveBar();
        console.log('5')
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      console.log('6')
      if (this.app.isHorizontal() || this.app.isSlim()) {
        console.log('7')
        this.app.resetMenu = true;
      } else {
        console.log('8')
        this.app.resetMenu = false;
      }
      console.log('9')
      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
      this.app.unblockBodyScroll();
    }
  }

  onMouseEnter(index: number) {
    if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
      && !this.app.isMobile() && !this.app.isTablet()) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    //console.log('active',this.activeIndex)
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;
    if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = null;
    }
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;
    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
  debugItems(child) {
    console.log('Datos en item.items: ', this.item.items);
    console.log('routerlink ', child.routerLink);
    return true;
  }
}
