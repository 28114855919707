import { Component, OnInit,ViewChild } from '@angular/core';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';
import { Usuario } from '../../usuario';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { ConMaeImpuesto, SaciMaeConfiguracion, SaciMaeNumDec, VenMaeImpuesto,SacieImpuestoSri } from '../seginterfaces/sacimaeconfiguracion';
import { SacimaeconfiguracionService } from '../segservicios/sacimaeconfiguracion.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
@Component({
  selector: 'app-sacimaeconfiguracion',
  templateUrl: './sacimaeconfiguracion.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class SacimaeconfiguracionComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnGuardar: boolean;
  listaPermisos: SegMaePermiso;
  indexTab = 0;


  // BUSQUEDA

  opcion: string;
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;

  // CONFIGURACION
  strSentencia = '';
  datoTxt;
  intValorConf;
  private valor; opcion1 = 0; opcion2 = 0; opcion3 = 0;
  codConfig; strPriInicial; strOpcLista;
  intValorAsignado;

  // INVENTARIO
  chbtnPERELISER = false; chbtnUNIBODDET = false; chbtnFACCANUND = false; chbtnLOTINV = false; chbtnFUNFAR = false; chbtnFUNLAB = false;
  chbtnINVFRACAJ = false; chbtnINVCAJFRA = false; chbtnSALCUR = false; chbtnNOCOMPEXT = false; chbtnDESESPEK = false;
  chbtnVISCOSADM = false; chbtnSALCAMEST = false; chbtnLEECBA = false; chbtnINSFAC = false; chbtnINSPREXPTO = false;
  chbtnHABCLISAL = false; chbtnDESCTASUB = false; chbtnFOCNUELIN = false; txtCODCAMVEN = ''; chbtnPREINCIVAE = false;
  chbtnFPLOTSIM = false; chbtnCOSTRFINV = false; txtNumDecCp = ''; txtNumCarSim = ''; txtNumCarSer = ''; txtNumPreArt = '';
  chbtnNoReces = false; chbtnProvTrf = false; chbtnCosTuTil = false; chbtnMulNumTra = false;
  chbtnCenCoses = false; chbtnConSerSim = false; chbtnInvCtaCaDi = false; chbtnProxProc = false; chbtnInvMcSa = false;
  chbtnInvAsiAut = false; chbtnPrecioArt = false; chbtnInvNegSa = false; chbtnTrabajarInvNeg = false; chbtnCodContArticulos = false;
  chbtnGruposPorNiveles = false; chbtnPorDisES = false; chbtnCTNORDSAL = false; chbtnPEDPESCAN = false; ChkMOSHISPRE = false;
  chkHABGREES = false; chbtnLOTENTSAL = false; chbtnCODAUTGRU = false; chbtnLOTEADOC = false; chbtnNODEEXNEG = false; chbtnPORUTIPC = false;
  chbtnORDPROENT = false; chbtnCALVOLDEN = false; intTamanioCod; intValor; strValorCod; strValorSinDecimal;
  strDato = ''; strCadena = ''; txtDIASVALRES = ''; chbtnCONCOSFEC = false; dateTimeCONCOSFEC = new Date();
  chkINVHUC=false; chkCCPIES=false; chkCBODUSCE=false; chbtnCUMBCE=false//CDPJ;
  
  chbtnHORTRANS=false;//GSRF
  // COMPRAS
  chkbtnMODCODODC = false; chkbtnMOSNUMPO = false; txtCodFarmacia = ''; chbtnVAROBLNR = false; chbtnNUMAUTESP = false; chbtnNOVAL332 = false;
  chBtnCONPRGASI = false; chBtnNOSALFSCC = false; chBtnRECCOSPRO = false; chbtnNOMODDR = false; chBtnCONSOLFAC = false; txtRetencion6 = '';
  txtRetencion5 = ''; txtRetencion4 = ''; txtRetencion3 = ''; chBtnFACTIPGAS = false; chBtnDISCENCOS = false; chbtnrucduppro = false;
  chbtnDescArtDet = false; chbtnNotRec = false; chbtnOrdCompra = false; chbtnDesUltCostoCompra = false; chbtnDesUltCostoComNotRec = false;
  chbtnDesUltCostoComOC = false; chbtnContFacProveeFecRec = false; chbtnSecOrdCom = false; chbtnCodProv = false; chbtnIvaNotaRec = false;
  chbtnIceDesc = false; chbtnNrFactorNotRec = false; chbtnActPorIva = false; chbtnIngTotRetC = false; chbtnIceBaseIva = false;
  chbtnCenCosDet = false; chbtnPrecioFob = false; chbtnCenCosCarg = false; chbtnNcAnioAntPagDoc = false; chbtnPerModNR = false;
  chbtnRetProv = false; chbtnNumRetAut = false; chbtnValidaTSri = false; chbtnGastosCC = false; chbtnAutSri = false; chbtnNumIng = false;
  chbtnFacAproveedores = false; chbtnSecNotRec = false; chbtnNumIngAut = false; chbtnDesOrdCom = false; chBtnINGRETOBL = false;
  ChBtnBloTotalRet = false; chbtnRETSINVAL = false; chBtnMOSCOLP = false; chBtnRETFECEMI = false; ChBtnSALCM = false; chBtnSECEDIOC = false;
  chBtnAUTRETBOD = false; chbtnNUMRETSEC = false; chbtnCENXCTACON = false; chbtnACCTADC = false; chkbtnFPLOTSIM = false; chkbtnACTCPAP = false;
  chkbtnRETBASAUT = false; chkbtnDIVRETCOM = false; txtRetencion2 = ''; txtRetencion1 = ''; txtIniPro = ''; chkbtnNOCATOTFP = false;chkbtnAUCREFNT=false;
  chkbtnSCANNR=false; chkbtnCENCOSNT=false; chbtnADANPROFC=false;chkbtnCDDTIMP=false;chkbtnNCDGASINR=false//CDPJ

  chkbtnNOTRECSIFA=false;//GSRF

  // VENTAS
  txtDIAVALPED = ''; chbtnNOTMFACCN = false; chbtnFECINTIF = false; chbtnLOTCARCF = false; chbtnNOMCLIOBS = false; chbtnNOFCCERO = false;
  txtNUMDECPES = ''; txtLIMCONFIN = '.00'; chbtnREFLOCMED = false; chbtnBLOIMPFAC = false; chbtnDETFACLOT = false; chbtnCCMANG=false; chbtnBLFHFAC=false;chbtnPAVFPT=false; chbtnCAJXVEN = false;
  chbtnNUMPTOUSU = false; chbtNUMPORVEN = false; chbtnCOMFPELE = false; txtSERSEGVTA = ''; txtPORSEGVTA = '.00'; chbtnACTZONEXC = false; chbtnNODPDNC = false;
  chbtnRESPEDBOD = false; chbtnGENCUOAUT = false; chbtnPREBAJCOM = false; chbtnNOFACPREL = false; chbtnRESUAP = false; chbtnREFMAE = false;
  chbtnBODDEFPED = false; chbtnRESARTPED = false; chbtnPREMAECLI = false; chbthNOGSCC = false; chbtnDSCPED = false; txtDOLXPUNTO = '';
  chbtnPUNVTAS = false; chbtnDESGLOFP = false; chbtnFOCCANLIN = false; chbtnREFLOCPED = false; chbtnPEDEXTNEG = false; chbtnPEDFOCOCL = false;
  chkbtnINGCODALT = false; chkbtnNBFPIMPFA_1 = false; chkbtnNBFPIMPFA = false; chkbtnCODVENAUT = false; chkbtnGUASINFP = false;
  chkbtnINGRAPFAC = false; chbtnBusqDesc = false; chbtnPrSerIncIva = false; chbtnPrecIncIva = false; chbtnBloqFacImp = false;
  chbtnActDocFact = false; chbtnManBultGRem = false; chbtnGenNumFact = false; chkbtnGUIRENDE = false; chbtnPORSEG = false; chbtnFacExisNeg = false;
  chbtnFvFocDet = false; chbtnFcCobDir = false; chbtnDesNroFac = false; chbtnFcPromoUna = false; chbtnDevSelecFacIng = false;
  chbtnDesMaxCli = false; chbtnFacTipoCli = false; chbtnLisPrec = false; chbtnDevSelecFacPend = false; chbtnIngImpFcSinFPago = false;
  chbtnNoElimFacImp = false; chbtnNoModVenFac = false; chbtnDsctoLineaAutom = false; chbtnAutFcVen = false; chbtnFechaAsiPagoDir = false;
  chbtnSeleccTipoIvaCli = false; chbtnNoModBodDetFac = false; chbtnFcPreciCantFisicaDetFac = false; chbtnSeleccTipoIva = false; chbtnBodDef = false;
  chbtnValPorVen = false; chbtnDesIce = false; chbtnFacCentroCostos = false; chbtnCtrlPrecUni = false; chbtnCodCliente = false;
  chbtnCodContClientes = false; chbtnCambiarCliFin = false; chbtnCodCliAlf = false; chbtnConsideraNotVta = false; chbtnNotVtaNoIva = false;
  chbtnMarPrecBajoCosto = false; chbtnIceBajoIvaV = false; chbtnCalMaxCli = false; chbtnVenFijPag = false; chbtnConCodCli = false;
  chbtnCtrlEstadoCliV = false; chbtnNoIceFacVta = false; chbtnCodBarFac = false; chbtnBodDefectoUsu = false; chbtnCreaServMae = false;
  chbtnNoBusArtF = false; chbtnGeneTransacPedidos = false; chbtnNoDupRuc = false; chkbtnAsiGuiAut = false; chkbtnFOCONLPED = false;
  chkbtnGRAPAGCRE = false; chkbtnCONFINAUT = false; chbtnARTANTBOD = false; chbtnPEDVAL5 = false; chbtnNOASIFAC = false; chbtnFACLIGREQ = false;
  chbtnFACNOAIMP = false; chbtnAUTGUAFAC = false; chbtnPERCAMSER = false; chbtnNOINSCF = false; chbtnGUANUMPED = false; chbtnUSURAZELI = false;
  chbtnNOMCLICON = false; chbtnTRAPEDCOM = false; chbtnTRPEACES = false; chbtnAGINFOADI = false; chbtnGUINOKARD = false; chbtnCTRLDESLP = false;
  txtNumItemsFac = ''; txtEtiquetaVendedor = ''; txtIniCodCli = ''; txtCodConFinal = ''; txtGrupoConsumFinal = ''; chbtnMULVERVEN = false;
  chbtnFACRESCLI = false; chbtnCodBarPre = false; chbtnVENUSU = false; chbtnESTPEDESP = false; chbtnDIRCLIGR = false; chbtnBUSEXTBOD = false;
  chbtnACTCOMFE = false; chbtnREFFACNUM = false; chbtnABOPD = false; chbtnCENCOSBOD = false; lblCliente; lblGrpCliente;
  chbtnVALINGLOT = false; chbtnVALLOTART = false; chbtnAGRLOTCOM = false; chbtnACTPEDVAL = false; chbtnCOMNUMPED = false;
  chkbtnNOPEDCERO = false;
  //CDPJ
  chkbtnSALDP=false; chbtnNFNEGR=false;chbtnCCPIPED=false;
  chkbtnVEPLDFAC=false; chbtnCNAFEPV=false;chbtnBCPPG=false;
  chbtnACCCFV=false;chbtnNVTOREMVEN=false;
  chbtnARDFCG=false;chbtnCEINFOADI=false;chbtnDVBUSGR=false;chbtnDSNEBINGR=false;
  //CDPJ 
  //GSRF
  chbtnPEDNOMAXEX=false; chbtnblobod=false; chbtnblobvd=false;
  //GSRF

  // PUNTO DE VENTA
  txtTTAPTOC = ''; txtTTAPTOD = ''; chbtnDEVFACFRAC = false; chbtnCodBarPrePV = false;
  chbtnBloqPvImp = false; chbtnGenNumGrab = false; chbtnRESARTREP = false; txtNroLimCheProtestado = ''; txtPvNumItemsFac = ''; txtCfgValor3 = '';
  chbtnPvFacExNeg = false; chbtnPvFvFocoDet = false; chbtnPVDeshabilitadoNroFac = false; chbtnPvImpFacFormaPago = false; chbtnPvPromoAutoFac = false;
  chbtnPvAsienFecDocVta = false; chbtnPvNoElimFacImpr = false; chbtnBloquearListaPrecios = false; chbtnImpDirectoFacVta = false;
  chbtnPvAutFcVen = false; chbtnPvCajaDepVen = false; chbtnPreArtInIva = false; chbtnNueFCurLd = false; chbtnCntrlEstdCli = false;
  chbtnFocoPre = false; chbtnNoModifVendedorPv = false; chbtnFacConsumFinal = false; chbtnNumFacPto = false; chbtnFacSinKar = false;
  chbtnVENFUNCLI = false; chbtnFacNoPreciCero = false; chbtnFacValoreCero = false; chbtnBLODSCDET = false; chbtnINGTOUCH = false;
  chbtnINGESPFAC = false; chbtnREFNUMER = false; chbtnCONCALCAN = false; chbtnNOFACCF = false; chbtnMAINVPTVTA = false; chbtnSERTARCOB = false;
  chbtnPLAOBLIFAC = false; chbtnBUSSIMCLI = false; chbtnFORPAGAUT = false; chbtnSELNVPV = false; btnChkFacturaPorUnidades = false; chbtnLSTPXVEN = false;
  chbtnPARESCLI = false; chbtnNOPEDCLAV = false;

  // CXC
  txDIAGRACIA = ''; chbtnOCUDETCUO = false; txtCODCOMCOB = ''; txtCODCOMMOR = ''; txtTASACOB = ''; txtPORINTMOR = '';
  chbtnAPLPORINT = false; chbtnELIASIELI = false; chbtnNumAsiComp = false; chbtnCHPOSDEU = false; chbtnANTEXCPAG = false;
  chbtnCOMAUTBP = false; chbtnComprobtesIngAuto = false; chbtnNotDebDocObli = false; chbtnCntrlChPos = false; chbtnTotRetCuentasXC = false;
  chbtnNOVFECCAN = false; chbtnCHEPOS = false;chbtnIMOBANCXCV=false;  chbtnCCCAVNC=false;chbtnCHPOSCOBR=false;//CDPJ

  // CXP
  chbtnCxPAutrzPagos = false; chbtnElimAstoForPagoCancel = false; chbtnNroAstoPagoMod = false; chbtnPvImpCheqImp = false; chbtnANUCHEA = false;
  chbtnCOMCCCAJ=false//CDPJ
  chbtnCXPASIC=false; //GSRF
  // BANCOS
  txtCODBANGUA = ''; txtCUETRACT = ''; cchbtnCOMBPAUT = false; chbtnNumCheqAut = false; cchbtnDESREQBAN = false; txtCODBANPRO = '';
  txtCODBANINT = ''; txtCODBANPIC = '';

  // CONTABILIDAD
  chBtnNUMMENASI = false; chBtnOBSASIDET = false; chkCONTIPCI = false; chBtnNOPRGDCC = false; chBtnCONEXPED = false;
  chBtnPROGDETAS = false; chBtnCOMCCBOD = false; txtCuentaAper = ''; chbtnAstoAuto = false; chbtnNroAstoModificable = false;
  chbtnAstoLoteCuadre = false; chbtnTotRetConAstoLote = false; chbtnRevAsiCon = false; chbtnDocSinAsi = false; chbtnBODCENCOS = false;
  chbtnCOLASICOM = false; chBtnCRLVISASI = false; chBtnINFCONBAN = false; chkConMayGnrl = false; dateTimeAsIni = new Date();

  // ANEXOS
  CHKGENXMLIND = false; txtSERNOATS4 = ''; txtSERNOATS3 = ''; txtSERNOATS2 = ''; txtSERNOATS1 = '';
  txtNoCodRet = ''; txtPathAnex = ''; CHKFECRECEMI = false; CHKNCNOFAC = false;

  // PRODUCCION
  chbtnDATTECP = false; chbtnVISCANFOR = false;
  //CDPJ
  chbtnCCPBOPP=false;chbtnCLORDPRO=false;
  //CDPJ
  // OTROS
  chbtnEjeBusCarIng = false; chbtnGenClaAut = false;
  lstNumOrdPro: any[]; listCondBus: any[]; lstNumDvf: any[]; lstNumCom: any[]; lstIvaPre: any[]; lstTipDet: any[]; lstNumPed: any[];
  lstNumGre: any[]; lstNumFac: any[]; lstNumCot: any[];
  lblNumCot = ''; lblNumFac = ''; lblNumPed = ''; lblNumGre = ''; lblTipDet = ''; lblIvaPre = ''; lblNumCom = ''; lblNumDvf = '';
  lblCondBus = ''; lblNumOrdPro = ''; chbtnEmpresaAutoImpre = false; chbtnDesIndBus = false;
  strCodigo = ''; strPrefijo = ''; chbtnDesplePantActEmail = false; chbtnCLAAUTFE = false;
  chbtnRangfechacontabilisa= false;

  selectNumOrdPro: any; selectCondBus: any; selectNumDvf; selectNumCom: any; selectIvaPre: any; selectTipDet: any; selectNumPed: any;
  selectNumGre: any; selectNumFac: any; selectNumCot: any;

  // TABLAS
  // Tabla
  public frameworkComponents;
  rowStyle;
  defaultConMaeImpuesto;
  defaultSaciNum;
  defaultVenMaeImp;
  defaultMaeImpSri;//CDPJ
  rowSelection = 'multiple';
  largo: string;
  indicador: any;
  maeimsriseleccted:SacieImpuestoSri//CDPJ
  columnDefsConMaeImpuesto = [
    {
      headerName: 'Código', field: 'CODIGO', cellEditor: 'cellPrueba', width: 110, editable: false,
    },
    {
      headerName: 'Nombre', field: 'NOMBRE', cellEditor: 'cellPrueba', width: 380, editable: false,
    },
    {
      headerName: 'Código Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 25,
          tienecalculos: true,
          opcionbuscar: true
        }
      }
    }
  ];


  columnDefsSaciMaeNumDec = [
    {
      headerName: 'Campo', field: 'NUMDEC_CAMPO', cellEditor: 'cellPrueba', width: 110, editable: false,
    },
    {
      headerName: 'Forma', field: 'NUMDEC_FORMA', cellEditor: 'cellPrueba', width: 225, editable: false,
    },
    {
      headerName: 'Tipo Doc', field: 'NUMDEC_TIPDOC', cellEditor: 'cellPrueba', width: 150, editable: false,
    },
    {
      headerName: 'Num Dec', field: 'NUMDEC_NUMDEC', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 1,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Descipción', field: 'NUMDEC_DESCRIPCION', cellEditor: 'cellPrueba', width: 350, editable: false,
    },
  ];

  columnDefsVenMaeImpuesto = [
    {
      headerName: 'Cod.Tipo', field: 'IMP_TIPO', cellEditor: 'cellPrueba', width: 100, editable: false,
    },
    {
      headerName: 'Cod.Tari', field: 'IMP_TARIFA', cellEditor: 'cellPrueba', width: 100, editable: false,
    },
    {
      headerName: 'Descripción', field: 'IMP_DESCRIPCION', cellEditor: 'cellPrueba', width: 110, editable: false,
    },
    {
      headerName: '%', field: 'IMP_PORCENTAJE', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Valor', field: 'IMP_VALOR', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
  ];
  //CDPJ
  columnDefsMaeImpuestoSri = [
    {
      headerName: 'Código', field: 'TARIIVA_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
    { // 2
      headerName: 'Descripción', field: 'TARIIVA_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,
        editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 200
        }
      }
    },
    {
      headerName: '%', field: 'TARIIVA_PORCENTAJE', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Activo',
      field: 'TARIIVA_ACTIVO',
      cellRendererFramework: AggridBanComponent,
      editable: () => {
        return false;
      },
      width: 50,
    },
    
  ];
  //CDPJ
  constructor(
    public sacimaeConfigService: SacimaeconfiguracionService,
    private auditoriaGralService: AuditoriagralService, private permisosService: PermisosService,
    private message: MessageService, public usuario: Usuario, private errorService: ErroresBaseDatosService,
    private datePipe: DatePipe, private confirmationService: ConfirmationService, private confIniciales: ConfInicialesService,
    private init: NuevoComponentService,
    public busquedaSer: InvbusquedaService) {
    this.agTable();
    this.sacimaeConfigService.detConMaeImpArray[this.indicador] = [];
    this.sacimaeConfigService.detSaciMaeNumDecArray[this.indicador] = [];
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultConMaeImpuesto = {
      objeto: 'saciconfmaeimp',
      resizable: true
    };
    this.defaultSaciNum = {
      objeto: 'saciconfnumdec',
      resizable: true
    };

    this.defaultVenMaeImp = {
      objeto: 'confvenmaeimp',
      resizable: true
    };
    this.defaultMaeImpSri={
      objeto: 'confmaeimpsri',
      resizable: true
    }
  }

  ngOnInit() {

    this.btnGuardar = true;

    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.largo = '400';
    this.permisosService.getListaPermisos('SEG', '5', '131').subscribe((permiso) => {
      this.listaPermisos = permiso[0];
    });
    this.presentarValoresInventario();
    this.lstNumFac = [];
    this.lstNumFac.push({ name: 'Punto de Venta', codigo: 0, data: 'NUMFAC' });
    this.lstNumFac.push({ name: 'Clase de Documento', codigo: 1, data: 'NUMFAC' });
    this.lstNumGre = [];
    this.lstNumGre.push({ name: 'Punto de Venta', codigo: 0, data: 'NUMGRE' });
    this.lstNumGre.push({ name: 'Clase de Documento', codigo: 1, data: 'NUMGRE' });
    this.lstNumPed = [];
    this.lstNumPed.push({ name: 'Punto de Venta', codigo: 0, data: 'NUMPED' });
    this.lstNumPed.push({ name: 'Clase de Documento', codigo: 1, data: 'NUMPED' });
    this.lstTipDet = [];
    this.lstTipDet.push({ name: 'Artículo', codigo: 0, data: 'TIPDET' });
    this.lstTipDet.push({ name: 'Servicio', codigo: 1, data: 'TIPDET' });
    this.lstTipDet.push({ name: 'Cuenta Contable', codigo: 2, data: 'TIPDET' });
    this.lstTipDet.push({ name: 'Activo Fijo', codigo: 3, data: 'TIPDET' });
    this.lstTipDet.push({ name: 'Comentario', codigo: 4, data: 'TIPDET' });
    this.lstIvaPre = [];
    this.lstIvaPre.push({ name: 'Exento', codigo: 0, data: 'IVAPRE' });
    this.lstIvaPre.push({ name: 'Neto + IVA', codigo: 1, data: 'IVAPRE' });
    this.lstIvaPre.push({ name: 'Incluído', codigo: 2, data: 'IVAPRE' });
    this.lstNumCom = [];
    this.lstNumCom.push({ name: 'Punto de Venta', codigo: 0, data: 'NUMCOM' });
    this.lstNumCom.push({ name: 'Clase de Documento', codigo: 1, data: 'NUMCOM' });
    this.lstNumDvf = [];
    this.lstNumDvf.push({ name: 'Punto de Venta', codigo: 0, data: 'NUMDVF' });
    this.lstNumDvf.push({ name: 'Clase de Documento', codigo: 1, data: 'NUMDVF' });
    this.lstNumOrdPro = [];
    this.lstNumOrdPro.push({ name: 'Punto de Venta', codigo: 0, data: 'NUMORDPRO' });
    this.lstNumOrdPro.push({ name: 'Clase de Documento', codigo: 1, data: 'NUMORDPRO' });
    this.listCondBus = [];
    this.listCondBus.push({ name: 'Palabras Completas', codigo: 0, data: 'TIPOBUS' });
    this.listCondBus.push({ name: 'Coincidencias', codigo: 1, data: 'TIPOBUS' });
    this.listCondBus.push({ name: 'Palabras Iniciales', codigo: 2, data: 'TIPOBUS' });
  }

  manejarSenales(valor) {
    if (valor === 'Guardar') {
      if (this.listaPermisos != null) {
        if (this.listaPermisos.PERTODO === 0) {
          if (this.listaPermisos.PERACTUALIZACION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ' , no tiene permiso de Actualización. Consulte con el Administrador del Sistema.');
            return;
          } else {
            this.AlmacenarCambios();
          }

        } else {
          this.AlmacenarCambios();
        }
      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  changeTab(e) {
    if (this.indexTab === 1) {
      this.presentarValoresCompras();
    }
    if (this.indexTab === 2) {
      this.presentarValoresVentas();
    }
    if (this.indexTab === 3) {
      this.presentarValoresPuntoVenta();
    }
    if (this.indexTab === 4) {
      this.presentarValoresCtasPorCobrar();
    }
    if (this.indexTab === 5) {
      this.presentarValoresCtasPorPagar();
    }
    if (this.indexTab === 6) {
      this.presentarValoresBancos();
    }
    if (this.indexTab === 7) {
      this.presentarValoresConta();
    }
    if (this.indexTab === 8) {
      this.presentarValoresAne();
    }
    if (this.indexTab === 9) {
      this.presentarValoresProd();
    }
    if (this.indexTab === 10) {
      this.presentarValoresOtros();
    }
    if (this.indexTab === 11) {
      this.presentarValoresSACI();
    }

  }

  async presentarValoresInventario() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);

    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;
        if ('GRUNIVELES' === valorUno) {
          this.chbtnGruposPorNiveles = item.CFG_VALOR1 === 1;
        } else if ('ATMCUEART' === valorUno) {
          this.chbtnCodContArticulos = item.CFG_VALOR1 === 1;
        } else if ('TRAINVNEG' === valorUno) {
          this.chbtnTrabajarInvNeg = item.CFG_VALOR1 === 1;
        } else if ('INVNEG_SA' === valorUno) {
          this.chbtnInvNegSa = item.CFG_VALOR1 === 1;
        } else if ('PREART' === valorUno) {
          this.chbtnPrecioArt = item.CFG_VALOR1 === 1;
        } else if ('INVASIAUT' === valorUno) {
          this.chbtnInvAsiAut = item.CFG_VALOR1 === 1;
        } else if ('INVMCSA' === valorUno) {
          this.chbtnInvMcSa = item.CFG_VALOR1 === 1;
        } else if ('PROXPROC' === valorUno) {
          this.chbtnProxProc = item.CFG_VALOR1 === 1;
        } else if ('INVCTACADI' === valorUno) {
          this.chbtnInvCtaCaDi = item.CFG_VALOR1 === 1;
        } else if ('NUMDPREART' === valorUno) {
          if (String(item.CFG_VALOR2) !== null) {
            this.txtNumPreArt = String(item.CFG_VALOR2);
          } else {
            this.txtNumPreArt = '2';
          }
        } else if ('NUMCARSER' === valorUno) {
          if (String(item.CFG_VALOR2) !== null) {
            this.strValorCod = Number(item.CFG_VALOR2).toFixed(1);
            this.intTamanioCod = this.strValorCod.length - 2;
            this.strValorSinDecimal = this.strValorCod.substring(0, this.intTamanioCod);
            this.txtNumCarSer = String(this.strValorSinDecimal);
          }
        } else if ('NUMCARSIM' === valorUno) {
          console.log(item.CFG_VALOR2);
          if (String(item.CFG_VALOR2) !== null) {
            this.strValorCod = Number(item.CFG_VALOR2).toFixed(1);
            this.intTamanioCod = this.strValorCod.length - 2;
            this.strValorSinDecimal = this.strValorCod.substring(0, this.intTamanioCod);
            this.txtNumCarSim = this.strValorSinDecimal;
          }
        } else if ('CONSERSIM' === valorUno) {
          this.chbtnConSerSim = item.CFG_VALOR1 === 1;
        } else if ('INGMOTMER' === valorUno) {
          // chbtnIngMotMer.setSelection(item.getCfgValor1() == 1);
        } else if ('CENCOSES' === valorUno) {
          this.chbtnCenCoses = item.CFG_VALOR1 === 1;
        } else if ('NUMDECCP' === valorUno) {
          if (String(item.CFG_VALOR2) !== null) {
            this.txtNumDecCp = String(item.CFG_VALOR2);
          }
        } else if ('MULNUMTRA' === valorUno) {
          this.chbtnMulNumTra = item.CFG_VALOR1 === 1;
        } else if ('COSTUTIL' === valorUno) {
          this.chbtnCosTuTil = item.CFG_VALOR1 === 1;
        } else if ('PROVTRF' === valorUno) {
          this.chbtnProvTrf = item.CFG_VALOR1 === 1;
        } else if ('NORECES' === valorUno) {
          this.chbtnNoReces = item.CFG_VALOR1 === 1;
        } else if ('PREINCIVAE' === valorUno) {
          this.chbtnPREINCIVAE = item.CFG_VALOR1 === 1;
        } else if ('PORCTAORD' === valorUno) {
          this.chbtnPorDisES = item.CFG_VALOR1 === 1;
        } else if ('CTNORDSAL' === valorUno) {
          this.chbtnCTNORDSAL = item.CFG_VALOR1 === 1;
        }  //CDPJ
        else if ('INVHUC' === valorUno) {
          this.chkINVHUC = item.CFG_VALOR1 === 1;
        }else if ('CCPIES' === valorUno) {
          this.chkCCPIES = item.CFG_VALOR1 === 1;
        }else if ('CBODUSCE' === valorUno) {
          this.chkCBODUSCE = item.CFG_VALOR1 === 1;
        } else if ('CUMBCE' === valorUno) {
          this.chbtnCUMBCE = item.CFG_VALOR1 === 1;
        } 
        //CDPJ
        //GSRF
        else if ('HORTRANS' === valorUno) {
          this.chbtnHORTRANS = item.CFG_VALOR1 === 1;
        } 
        //GSRF
        else if ('PEDPESCAN' === valorUno) {
          this.chbtnPEDPESCAN = item.CFG_VALOR1 === 1;
        } else if ('MOSHISPRE' === valorUno) {
          this.ChkMOSHISPRE = item.CFG_VALOR1 === 1;
        } else if ('HABGREES' === valorUno) {
          this.chkHABGREES = item.CFG_VALOR1 === 1;
        } else if ('LOTENTSAL' === valorUno) {
          this.chbtnLOTENTSAL = item.CFG_VALOR1 === 1;
        } else if ('CODCAMVEN' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtCODCAMVEN = String(item.CFG_VALOR3);
          }
        } else if ('CODAUTGRU' === valorUno) {
          this.chbtnCODAUTGRU = item.CFG_VALOR1 === 1;
        } else if ('FOCNUELIN' === valorUno) {
          this.chbtnFOCNUELIN = item.CFG_VALOR1 === 1;
        } else if ('DESCTASUB' === valorUno) {
          this.chbtnDESCTASUB = item.CFG_VALOR1 === 1;
        } else if ('LOTEADOC' === valorUno) {
          this.chbtnLOTEADOC = item.CFG_VALOR1 === 1;
        } else if ('HABCLISAL' === valorUno) {
          this.chbtnHABCLISAL = item.CFG_VALOR1 === 1;
        } else if ('INSPREXPTO' === valorUno) {
          this.chbtnINSPREXPTO = item.CFG_VALOR1 === 1;
        } else if ('INSFAC' === valorUno) {
          this.chbtnINSFAC = item.CFG_VALOR1 === 1;
        } else if ('LEECBA' === valorUno) {
          this.chbtnLEECBA = item.CFG_VALOR1 === 1;
        } else if ('SALCAMEST' === valorUno) {
          this.chbtnSALCAMEST = item.CFG_VALOR1 === 1;
        } else if ('VISCOSADM' === valorUno) {
          this.chbtnVISCOSADM = item.CFG_VALOR1 === 1;
        } else if ('DESESPEK' === valorUno) {
          this.chbtnDESESPEK = item.CFG_VALOR1 === 1;
        } else if ('NODEEXNEG' === valorUno) {
          this.chbtnNODEEXNEG = item.CFG_VALOR1 === 1;
        } else if ('NOCOMPEXT' === valorUno) {
          this.chbtnNOCOMPEXT = item.CFG_VALOR1 === 1;
        } else if ('SALCUR' === valorUno) {
          this.chbtnSALCUR = item.CFG_VALOR1 === 1;
        } else if ('INVCAJFRA' === valorUno) {
          this.chbtnINVCAJFRA = item.CFG_VALOR1 === 1;
        } else if ('INVFRACAJ' === valorUno) {
          this.chbtnINVFRACAJ = item.CFG_VALOR1 === 1;
        } else if ('FUNLAB' === valorUno) {
          this.chbtnFUNLAB = item.CFG_VALOR1 === 1;
        } else if ('FUNFAR' === valorUno) {
          this.chbtnFUNFAR = item.CFG_VALOR1 === 1;
        } else if ('LOTINV' === valorUno) {
          this.chbtnLOTINV = item.CFG_VALOR1 === 1;
        } else if ('FPLOTSIM' === valorUno) {
          this.chbtnFPLOTSIM = item.CFG_VALOR1 === 1;
        } else if ('COSTRFINV' === valorUno) {
          this.chbtnCOSTRFINV = item.CFG_VALOR1 === 1;
        } else if ('ORDPROENT' === valorUno) {
          this.chbtnORDPROENT = item.CFG_VALOR1 === 1;
        } else if ('CALVOLDEN' === valorUno) {
          this.chbtnCALVOLDEN = item.CFG_VALOR1 === 1;
        } else if ('FACCANUND' === valorUno) {
          this.chbtnFACCANUND = item.CFG_VALOR1 === 1;
        } else if ('UNIBODDET' === valorUno) {
          this.chbtnUNIBODDET = item.CFG_VALOR1 === 1;
        } else if ('PERELISER' === valorUno) {
          this.chbtnPERELISER = item.CFG_VALOR1 === 1;
        } else if ('PORUTIPC' === valorUno) {
          this.chbtnPORUTIPC = item.CFG_VALOR1 === 1;
        } else if ('CONCOSFEC' === valorUno) {
          this.chbtnCONCOSFEC = item.CFG_VALOR1 === 1;
          this.dateTimeCONCOSFEC = item.CFG_VALOR3 === null ? new Date() : new Date(item.CFG_VALOR3);
        } else if ('DIASVALRES' === valorUno) {
          if (item.CFG_VALOR2 !== null) {
            this.txtDIASVALRES = String(item.CFG_VALOR2);
          } else {
            this.txtDIASVALRES = '';
          }
        }

      }
    }

  }

  async presentarValoresCompras() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);
    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;
        if ('PROCOM' === valorUno) {
          if (item.CFG_VALOR1 === 0) {
            this.chbtnOrdCompra = false;
            this.chbtnNotRec = false;
            this.chbtnFacAproveedores = true;
          } else if (item.CFG_VALOR1 === 1) {
            this.chbtnOrdCompra = true;
            this.chbtnNotRec = true;
            this.chbtnFacAproveedores = true;
          } else if (item.CFG_VALOR1 === 2) {
            this.chbtnOrdCompra = false;
            this.chbtnNotRec = true;
            this.chbtnFacAproveedores = true;
          } else if (item.CFG_VALOR1 === 3) {
            this.chbtnOrdCompra = true;
            this.chbtnNotRec = false;
            this.chbtnFacAproveedores = true;
          } else if (item.CFG_VALOR1 === 4) {
            this.chbtnOrdCompra = true;
            this.chbtnNotRec = true;
            this.chbtnFacAproveedores = false;
          } else if (item.CFG_VALOR1 === 5) {
            this.chbtnOrdCompra = false;
            this.chbtnNotRec = false;
            this.chbtnFacAproveedores = false;
          } else if (item.CFG_VALOR1 === 6) {
            this.chbtnOrdCompra = true;
            this.chbtnNotRec = false;
            this.chbtnFacAproveedores = false;
          } else if (item.CFG_VALOR1 === 7) {
            this.chbtnOrdCompra = false;
            this.chbtnNotRec = true;
            this.chbtnFacAproveedores = false;
          }
        } else if ('DSCARTCOM' === valorUno) {
          this.chbtnDescArtDet = item.CFG_VALOR1 === 1;
        } else if ('DESPULCOSC' === valorUno) {
          this.chbtnDesUltCostoCompra = item.CFG_VALOR1 === 1;
        } else if ('DSPUCOSNR' === valorUno) {
          this.chbtnDesUltCostoComNotRec = item.CFG_VALOR1 === 1;
        } else if ('DSPUCOSOC' === valorUno) {
          this.chbtnDesUltCostoComOC = item.CFG_VALOR1 === 1;
        } else if ('CONFACFREC' === valorUno) {
          this.chbtnContFacProveeFecRec = item.CFG_VALOR1 === 1;
        } else if ('SECNOTREC' === valorUno) {
          this.chbtnSecNotRec = item.CFG_VALOR1 === 1;
        } else if ('SECORDCOM' === valorUno) {
          this.chbtnSecOrdCom = item.CFG_VALOR1 === 1;
        } else if ('CODPROV' === valorUno) {
          this.chbtnCodProv = item.CFG_VALOR1 === 1;
        } else if ('IVANR' === valorUno) {
          this.chbtnIvaNotaRec = item.CFG_VALOR1 === 1;
        } else if ('ICEDESC' === valorUno) {
          this.chbtnIceDesc = item.CFG_VALOR1 === 1;
        } else if ('NRFACTORNR' === valorUno) {
          this.chbtnNrFactorNotRec = item.CFG_VALOR1 === 1;
        } else if ('ACTPORIVA' === valorUno) {
          this.chbtnActPorIva = item.CFG_VALOR1 === 1;
        } else if ('INGTOTRETC' === valorUno) {
          this.chbtnIngTotRetC = item.CFG_VALOR1 === 1;
        } else if ('ICEBASEIVA' === valorUno) {
          this.chbtnIceBaseIva = item.CFG_VALOR1 === 1;
        } else if ('CENCOSDET' === valorUno) {
          this.chbtnCenCosDet = item.CFG_VALOR1 === 1;
        } else if ('PRECIOFOB' === valorUno) {
          this.chbtnPrecioFob = item.CFG_VALOR1 === 1;
        } else if ('CENCOSCAR' === valorUno) {
          this.chbtnCenCosCarg = item.CFG_VALOR1 === 1;
        } else if ('NCAAPD' === valorUno) {
          this.chbtnNcAnioAntPagDoc = item.CFG_VALOR1 === 1;
        } else if ('PERMODNR' === valorUno) {
          this.chbtnPerModNR = item.CFG_VALOR1 === 1;
        } else if ('RETPROV' === valorUno) {
          this.chbtnRetProv = item.CFG_VALOR1 === 1;
        } else if ('NUMRETAUT' === valorUno) {
          this.chbtnNumRetAut = item.CFG_VALOR1 === 1;
        } else if ('INGRETOBL' === valorUno) {
          this.chBtnINGRETOBL = item.CFG_VALOR1 === 1;
        } else if ('SECEDIOC' === valorUno) {
          this.chBtnSECEDIOC = item.CFG_VALOR1 === 1;
        } else if ('NOMODDR' === valorUno) {
          this.chbtnNOMODDR = item.CFG_VALOR1 === 1;
        } else if ('CONPRGASI' === valorUno) {
          this.chBtnCONPRGASI = item.CFG_VALOR1 === 1;
        } else if ('CENXCTACON' === valorUno) {
          this.chbtnCENXCTACON = item.CFG_VALOR1 === 1;
        } else if ('VAROBLNR' === valorUno) {
          this.chbtnVAROBLNR = item.CFG_VALOR1 === 1;
        } else if ('FPLOTSIM' === valorUno) {
          this.chkbtnFPLOTSIM = item.CFG_VALOR1 === 1;
        } else if ('RETBASAUT' === valorUno) {
          this.chkbtnRETBASAUT = item.CFG_VALOR1 === 1;
        } else if ('ACTCPAP' === valorUno) {
          this.chkbtnACTCPAP = item.CFG_VALOR1 === 1;
        } else if ('DIVRETCOM' === valorUno) {
          this.chkbtnDIVRETCOM = item.CFG_VALOR1 === 1;
        } else if ('MODCODODC' === valorUno) {
          this.chkbtnMODCODODC = item.CFG_VALOR1 === 1;
        } else if ('MOSNUMPO' === valorUno) {
          this.chkbtnMOSNUMPO = item.CFG_VALOR1 === 1;
        } else if ('NOCATOTFP' === valorUno) {
          this.chkbtnNOCATOTFP = item.CFG_VALOR1 === 1;
        } //CDPJ
        else if ('AUCREFNT' === valorUno) {
          this.chkbtnAUCREFNT = item.CFG_VALOR1 === 1;
        }else if ('SCANNR' === valorUno) {
          this.chkbtnSCANNR = item.CFG_VALOR1 === 1;
        }else if ('CENCOSNT' === valorUno) {
          this.chkbtnCENCOSNT = item.CFG_VALOR1 === 1;
        }else if('ADANPROFC' === valorUno){
          this.chbtnADANPROFC=item.CFG_VALOR1 === 1;
        }else if('CDDTIMP' === valorUno){
          this.chkbtnCDDTIMP=item.CFG_VALOR1 === 1;
        }
        else if('NCDGASINR' === valorUno){
          this.chkbtnNCDGASINR=item.CFG_VALOR1 === 1;
        }
        //CDPJ
        //GSRF
        else if ('NOTRECSIFA' === valorUno) {
          this.chkbtnNOTRECSIFA = item.CFG_VALOR1 === 1;
        }
        //GSRF
         if ('CODRET1' === valorUno) {
          if (String(item.CFG_VALOR3) !== null) {
            this.txtRetencion1 = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
          }
        }  if ('CODRET2' === valorUno) {
          if (String(item.CFG_VALOR3) !== null) {
            this.txtRetencion2 = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
          }
        }  if ('CODRET3' === valorUno) {
          if (String(item.CFG_VALOR3) !== null) {
            this.txtRetencion3 = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
          }
        }  if ('CODRET4' === valorUno) {
          if (String(item.CFG_VALOR3) !== null) {
            this.txtRetencion4 = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
          }
        }  if ('CODRET5' === valorUno) {
          if (String(item.CFG_VALOR3) !== null) {
            this.txtRetencion5 = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
          }
        }  if ('CODRET6' === valorUno) {
          if (String(item.CFG_VALOR3) !== null) {
            this.txtRetencion6 = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
          }
        }  if ('VALDATSRI' === valorUno) {
          this.chbtnValidaTSri = item.CFG_VALOR1 === 1;
        }  if ('CODFAR' === valorUno) {
          if (String(item.CFG_VALOR3) !== null) {
            this.txtCodFarmacia = item.CFG_VALOR3 === null ? '' : String(item.CFG_VALOR3);
          }
        }  if ('INIPRO' === valorUno) {
          if (String(item.CFG_VALOR2) !== null && String(item.CFG_VALOR2) !== 'null') {
            this.txtIniPro = String(item.CFG_VALOR2) === null || String(item.CFG_VALOR2) === undefined ?'':String(item.CFG_VALOR2);
          }
        } else if ('GASTOSCC' === valorUno) {
          this.chbtnGastosCC = item.CFG_VALOR1 === 1;
        } else if ('AUTSRI' === valorUno) {
          this.chbtnAutSri = item.CFG_VALOR1 === 1;
        } else if ('NUMING' === valorUno) {
          this.chbtnNumIng = item.CFG_VALOR1 === 1;
        } else if ('DESORDCOM' === valorUno) {
          this.chbtnDesOrdCom = item.CFG_VALOR1 === 1;
        } else if ('NUMINGAUT' === valorUno) {
          this.chbtnNumIngAut = item.CFG_VALOR1 === 1;
        } else if ('BLOTOTRET' === valorUno) {
          this.ChBtnBloTotalRet = item.CFG_VALOR1 === 1;
        } else if ('RUCDUPPRO' === valorUno) {
          this.chbtnrucduppro = item.CFG_VALOR1 === 1;
        } else if ('MOSCOLP' === valorUno) {
          this.chBtnMOSCOLP = item.CFG_VALOR1 === 1;
        } else if ('DISCENCOS' === valorUno) {
          this.chBtnDISCENCOS = item.CFG_VALOR1 === 1;
        } else if ('FACTIPGAS' === valorUno) {
          this.chBtnFACTIPGAS = item.CFG_VALOR1 === 1;
        } else if ('RETFECEMI' === valorUno) {
          this.chBtnRETFECEMI = item.CFG_VALOR1 === 1;
        } else if ('SALCM' === valorUno) {
          this.ChBtnSALCM = item.CFG_VALOR1 === 1;
        } else if ('AUTRETBOD' === valorUno) {
          this.chBtnAUTRETBOD = item.CFG_VALOR1 === 1;
        } else if ('CONSOLFAC' === valorUno) {
          this.chBtnCONSOLFAC = item.CFG_VALOR1 === 1;
        } else if ('RECCOSPRO' === valorUno) {
          this.chBtnRECCOSPRO = item.CFG_VALOR1 === 1;
        } else if ('NOSALFSCC' === valorUno) {
          this.chBtnNOSALFSCC = item.CFG_VALOR1 === 1;
        } else if ('NUMRETSEC' === valorUno) {
          this.chbtnNUMRETSEC = item.CFG_VALOR1 === 1;
        } else if ('NOVAL332' === valorUno) {
          this.chbtnNOVAL332 = item.CFG_VALOR1 === 1;
        } else if ('NUMAUTESP' === valorUno) {
          this.chbtnNUMAUTESP = item.CFG_VALOR1 === 1;
        } else if ('ACCTADC' === valorUno) {
          this.chbtnACCTADC = item.CFG_VALOR1 === 1;
        } else if ('DIVRETCOM' === valorUno) {
          this.chkbtnDIVRETCOM = item.CFG_VALOR1 === 1;
        } else if ('RETSINVAL' === valorUno) {
          this.chbtnRETSINVAL = item.CFG_VALOR1 === 1;
        }
      }
    }

  }

  async presentarValoresVentas() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);
    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;
        if ('FCEXSNEG' === valorUno) {
          this.chbtnFacExisNeg = item.CFG_VALOR1 === 1;
        } else if ('FVFOCDET' === valorUno) {
          this.chbtnFvFocDet = item.CFG_VALOR1 === 1;
        } else if ('FCCOBDIR' === valorUno) {
          this.chbtnFcCobDir = item.CFG_VALOR1 === 1;
        } else if ('DESNROFAC' === valorUno) {
          this.chbtnDesNroFac = item.CFG_VALOR1 === 1;
        } else if ('FCPROMUNA' === valorUno) {
          this.chbtnFcPromoUna = item.CFG_VALOR1 === 1;
        } else if ('DVFSELFI' === valorUno) {
          this.chbtnDevSelecFacIng = item.CFG_VALOR1 === 1;
        } else if ('DVFSELFPND' === valorUno) {
          this.chbtnDevSelecFacPend = item.CFG_VALOR1 === 1;
        } else if ('IMPFCSFP' === valorUno) {
          this.chbtnIngImpFcSinFPago = item.CFG_VALOR1 === 1;
        } else if ('NOELIMFACI' === valorUno) {
          this.chbtnNoElimFacImp = item.CFG_VALOR1 === 1;
        } else if ('NOMODVENFA' === valorUno) {
          this.chbtnNoModVenFac = item.CFG_VALOR1 === 1;
        } else if ('DESCLINAUT' === valorUno) {
          this.chbtnDsctoLineaAutom = item.CFG_VALOR1 === 1;
        } else if ('BLOQFACIMP' === valorUno) {
          this.chbtnBloqFacImp = item.CFG_VALOR1 === 1;
        } else if ('FCPRINIVA' === valorUno) {
          this.chbtnPrecIncIva = item.CFG_VALOR1 === 1;
        } else if ('FCPRSERIV' === valorUno) {
          this.chbtnPrSerIncIva = item.CFG_VALOR1 === 1;
        } else if ('BUSQDESCF' === valorUno) {
          this.chbtnBusqDesc = item.CFG_VALOR1 === 1;
        } else if ('AUTFCVEN' === valorUno) {
          this.chbtnAutFcVen = item.CFG_VALOR1 === 1;
        } else if ('ASIPDFECSI' === valorUno) {
          this.chbtnFechaAsiPagoDir = item.CFG_VALOR1 === 1;
        } else if ('SELTIPIVA' === valorUno) {
          this.chbtnSeleccTipoIva = item.CFG_VALOR1 === 1;
        } else if ('STIPIVACLI' === valorUno) {
          this.chbtnSeleccTipoIvaCli = item.CFG_VALOR1 === 1;
        } else if ('NOMODBODFA' === valorUno) {
          this.chbtnNoModBodDetFac = item.CFG_VALOR1 === 1;
        } else if ('CANTFISICA' === valorUno) {
          this.chbtnFcPreciCantFisicaDetFac = item.CFG_VALOR1 === 1;
        } else if ('NUMITEMFAC' === valorUno) {
          if (String(item.CFG_VALOR2) !== null) {
            this.strValorCod = Number(item.CFG_VALOR2).toFixed(1);
            this.intTamanioCod = this.strValorCod.length - 2;
            this.strValorSinDecimal = this.strValorCod.substring(0, this.intTamanioCod);
            this.txtNumItemsFac = this.strValorSinDecimal;
          }
        } else if ('DIAVALPED' === valorUno) {
          if (String(item.CFG_VALOR2) !== null) {
            this.strValorCod = Number(item.CFG_VALOR2).toFixed(1);
            this.intTamanioCod = this.strValorCod.length - 2;
            this.strValorSinDecimal = this.strValorCod.substring(0, this.intTamanioCod);
            this.txtDIAVALPED = this.strValorSinDecimal;
          }
        } else if ('NUMDECPES' === valorUno) {
          if (String(item.CFG_VALOR2) !== null) {
            this.strValorCod = Number(item.CFG_VALOR2).toFixed(1);
            this.intTamanioCod = this.strValorCod.length - 2;
            this.strValorSinDecimal = this.strValorCod.substring(0, this.intTamanioCod);
            this.txtNUMDECPES = this.strValorSinDecimal;
          }
        } else if ('BODDEF' === valorUno) {
          this.chbtnBodDef = item.CFG_VALOR1 === 1;
        } else if ('DESICE' === valorUno) {
          this.chbtnDesIce = item.CFG_VALOR1 === 1;
        } else if ('ATMCODCLI' === valorUno) {
          this.chbtnCodCliente = item.CFG_VALOR1 === 1;
        } else if ('CODCLIALF' === valorUno) {
          this.chbtnCodCliAlf = item.CFG_VALOR1 === 1;
        } else if ('INICODCLI' === valorUno) {
          if (String(item.CFG_VALOR3) !== null) {
            this.txtIniCodCli = String(item.CFG_VALOR3);
          } else {
            this.txtIniCodCli = '';
          }
        } else if ('ATMCUECLI' === valorUno) {
          this.chbtnCodContClientes = item.CFG_VALOR1 === 1;
        } else if ('CAMBIARCF' === valorUno) {
          this.chbtnCambiarCliFin = item.CFG_VALOR1 === 1;
        } else if ('ASIGUIAUT' === valorUno) {
          this.chkbtnAsiGuiAut = item.CFG_VALOR1 === 1;
        } else if ('NOFACPREL' === valorUno) {
          this.chbtnNOFACPREL = item.CFG_VALOR1 === 1;
        } else if ('MULVERVEN' === valorUno) {
          this.chbtnMULVERVEN = item.CFG_VALOR1 === 1;
        } else if ('FACRESCLI' === valorUno) {
          this.chbtnFACRESCLI = item.CFG_VALOR1 === 1;
        } else if ('GENCUOAUT' === valorUno) {
          this.chbtnGENCUOAUT = item.CFG_VALOR1 === 1;
        } else if ('COMFPELE' === valorUno) {
          this.chbtnCOMFPELE = item.CFG_VALOR1 === 1;
        } else if ('DETFACLOT' === valorUno) {
          this.chbtnDETFACLOT = item.CFG_VALOR1 === 1;
        } //CDPJ
        else if ('CCMANG' === valorUno) {
          this.chbtnCCMANG = item.CFG_VALOR1 === 1;
        }else if ('BLFHFAC' === valorUno) {
          this.chbtnBLFHFAC = item.CFG_VALOR1 === 1;
        }else if ('SALDP' === valorUno) {
          this.chkbtnSALDP = item.CFG_VALOR1 === 1;
        }else if ('NFNEGR' === valorUno) {
          this.chbtnNFNEGR = item.CFG_VALOR1 === 1;
        }else if ('CCPIPED' === valorUno) {
          this.chbtnCCPIPED = item.CFG_VALOR1 === 1;
        }else if ('VEPLDFAC' === valorUno) {
          this.chkbtnVEPLDFAC = item.CFG_VALOR1 === 1;
        }else if ('CNAFEPV' === valorUno) {
          this.chbtnCNAFEPV = item.CFG_VALOR1 === 1;
        }else if ('BCPPG' === valorUno) {
          this.chbtnBCPPG = item.CFG_VALOR1 === 1;
        }else if ('ACCCFV' === valorUno) {
          this.chbtnACCCFV = item.CFG_VALOR1 === 1;
        } else if ('NVTOREMVEN' === valorUno) {
          this.chbtnNVTOREMVEN = item.CFG_VALOR1 === 1;
        }
        else if ('ARDFCG' === valorUno) {
          this.chbtnARDFCG = item.CFG_VALOR1 === 1;
        }else if ('CEINFOADI' === valorUno) {
          this.chbtnCEINFOADI = item.CFG_VALOR1 === 1;
        }else if ('DVBUSGR' === valorUno) {
          this.chbtnDVBUSGR = item.CFG_VALOR1 === 1;
        }else if('DSNEBINGR' === valorUno){
          this.chbtnDSNEBINGR = item.CFG_VALOR1 === 1;
        }
        
        
        //CDPJ
        //GSRF
        else if ('PEDNOMAXEX' === valorUno) {
          this.chbtnPEDNOMAXEX = item.CFG_VALOR1 === 1;
        }else if ('PEDBLOBOD' === valorUno) {
          this.chbtnblobod = item.CFG_VALOR1 === 1;
        }else if ('FACBLOBVD' === valorUno) {
          this.chbtnblobvd = item.CFG_VALOR1 === 1;
        }
        //GSRF
        else if ('NUMPORVEN' === valorUno) {
          this.chbtNUMPORVEN = item.CFG_VALOR1 === 1;
        } else if ('ABOPD' === valorUno) {
          this.chbtnABOPD = item.CFG_VALOR1 === 1;
        } else if ('AUTGUAFAC' === valorUno) {
          this.chbtnAUTGUAFAC = item.CFG_VALOR1 === 1;
        } else if ('CENCOSBOD' === valorUno) {
          this.chbtnCENCOSBOD = item.CFG_VALOR1 === 1;
        } else if ('REFLOCMED' === valorUno) {
          this.chbtnREFLOCMED = item.CFG_VALOR1 === 1;
        } else if ('NOFCCERO' === valorUno) {
          this.chbtnNOFCCERO = item.CFG_VALOR1 === 1;
        } else if ('FECINTIF' === valorUno) {
          this.chbtnFECINTIF = item.CFG_VALOR1 === 1;
        } else if ('NOTMFACCN' === valorUno) {
          this.chbtnNOTMFACCN = item.CFG_VALOR1 === 1;
        } else if ('PORSEGVTA' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtPORSEGVTA = String(item.CFG_VALOR3);
          } else {
            this.txtPORSEGVTA = '';
          }
        } else if ('CODCONFIN' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtCodConFinal = String(item.CFG_VALOR3);
            try {
              const rs = await this.sacimaeConfigService.erCliNombre1(this.txtCodConFinal);
              this.lblCliente = rs === null ? '' : rs[0].CLI_NOMBRE;
            } catch (err) {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
              this.mensajeAlerta('error', 'Error', 'No se puede consultar los clientes. ' + mensaje);
            }
          }
        } else if ('GRUPOCF' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtGrupoConsumFinal = String(item.CFG_VALOR3);
            try {
              const rs = await this.sacimaeConfigService.erGruNombre1(this.txtGrupoConsumFinal);
              this.lblGrpCliente = rs === null ? '' : rs[0].GRU_NOMBRE;
            } catch (err) {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
              this.mensajeAlerta('error', 'Error', 'No se puede consultar los grupos. ' + mensaje);
            }
          }
        } else if ('CALCMAXCLI' === valorUno) {
          this.chbtnCalMaxCli = item.CFG_VALOR1 === 1;
        } else if ('CONSIDNV' === valorUno) {
          this.chbtnConsideraNotVta = item.CFG_VALOR1 === 1;
        } else if ('NVNOIVA' === valorUno) {
          this.chbtnNotVtaNoIva = item.CFG_VALOR1 === 1;
        } else if ('MARPREBCOS' === valorUno) {
          this.chbtnMarPrecBajoCosto = item.CFG_VALOR1 === 1;
        } else if ('VENFIJPAG' === valorUno) {
          this.chbtnVenFijPag = item.CFG_VALOR1 === 1;
        } else if ('ICEBIVAV' === valorUno) {
          this.chbtnIceBajoIvaV = item.CFG_VALOR1 === 1;
        } else if ('CTRLESCLIV' === valorUno) {
          this.chbtnCtrlEstadoCliV = item.CFG_VALOR1 === 1;
        } else if ('BODDEFUSU' === valorUno) {
          this.chbtnBodDefectoUsu = item.CFG_VALOR1 === 1;
        } else if ('CRESERMAE' === valorUno) {
          this.chbtnCreaServMae = item.CFG_VALOR1 === 1;
        } else if ('CONCODCLI' === valorUno) {
          this.chbtnConCodCli = item.CFG_VALOR1 === 1;
        } else if ('NOICEFVTA' === valorUno) {
          this.chbtnNoIceFacVta = item.CFG_VALOR1 === 1;
        } else if ('CODBARFAC' === valorUno) {
          this.chbtnCodBarFac = item.CFG_VALOR1 === 1;
        } else if ('NOBUSARTF' === valorUno) {
          this.chbtnNoBusArtF = item.CFG_VALOR1 === 1;
        } else if ('GENTRNPED' === valorUno) {
          this.chbtnGeneTransacPedidos = item.CFG_VALOR1 === 1;
        } else if ('ETIVEND' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtEtiquetaVendedor = String(item.CFG_VALOR3);
          } else {
            this.txtEtiquetaVendedor = '';
          }
        } else if ('DESMAXCLI' === valorUno) {
          this.chbtnDesMaxCli = item.CFG_VALOR1 === 1;
        } else if ('LISTPREC' === valorUno) {
          this.chbtnLisPrec = item.CFG_VALOR1 === 1;
        } else if ('INICODCLI' === valorUno) {
          this.chbtnFacTipoCli = item.CFG_VALOR1 === 1;
        } else if ('FACTIPCLI' === valorUno) {
          this.chbtnFacTipoCli = item.CFG_VALOR1 === 1;
        } else if ('VALPORVEN' === valorUno) {
          this.chbtnValPorVen = item.CFG_VALOR1 === 1;
        } else if ('FACCC' === valorUno) {
          this.chbtnFacCentroCostos = item.CFG_VALOR1 === 1;
        } else if ('PRECUNI' === valorUno) {
          this.chbtnCtrlPrecUni = item.CFG_VALOR1 === 1;
        } else if ('NUMGUAFAC' === valorUno) {
          this.chbtnGenNumFact = item.CFG_VALOR1 === 1;
        } else if ('GREBULTO' === valorUno) {
          this.chbtnManBultGRem = item.CFG_VALOR1 === 1;
        } else if ('ACTDOCFAC' === valorUno) {
          this.chbtnActDocFact = item.CFG_VALOR1 === 1;
        } else if ('NODUPRUC' === valorUno) {
          this.chbtnNoDupRuc = item.CFG_VALOR1 === 1;
        } else if ('ASIGUIAUT' === valorUno) {
          this.chkbtnAsiGuiAut = item.CFG_VALOR1 === 1;
        } else if ('FOCONLPED' === valorUno) {
          this.chkbtnFOCONLPED = item.CFG_VALOR1 === 1;
        } else if ('GRAPAGCRE' === valorUno) {
          this.chkbtnGRAPAGCRE = item.CFG_VALOR1 === 1;
        } else if ('INGRAPFAC' === valorUno) {
          this.chkbtnINGRAPFAC = item.CFG_VALOR1 === 1;
        } else if ('CONFINAUT' === valorUno) {
          this.chkbtnCONFINAUT = item.CFG_VALOR1 === 1;
        } else if ('GUASINFP' === valorUno) {
          this.chkbtnGUASINFP = item.CFG_VALOR1 === 1;
        } else if ('CODVENAUT' === valorUno) {
          this.chkbtnCODVENAUT = item.CFG_VALOR1 === 1;
        } else if ('GUIRENDE' === valorUno) {
          this.chkbtnGUIRENDE = item.CFG_VALOR1 === 1;
        } else if ('NBFPIMPFA' === valorUno) {
          this.chkbtnNBFPIMPFA = item.CFG_VALOR1 === 1;
        } else if ('INGCODALT' === valorUno) {
          this.chkbtnINGCODALT = item.CFG_VALOR1 === 1;
        } else if ('PEDFOCOCL' === valorUno) {
          this.chbtnPEDFOCOCL = item.CFG_VALOR1 === 1;
        } else if ('PEDEXTNEG' === valorUno) {
          this.chbtnPEDEXTNEG = item.CFG_VALOR1 === 1;
        } else if ('REFLOCPED' === valorUno) {
          this.chbtnREFLOCPED = item.CFG_VALOR1 === 1;
        } else if ('FOCCANLIN' === valorUno) {
          this.chbtnFOCCANLIN = item.CFG_VALOR1 === 1;
        } else if ('DESGLOFP' === valorUno) {
          this.chbtnDESGLOFP = item.CFG_VALOR1 === 1;
        } else if ('PUNVTAS' === valorUno) {
          this.chbtnPUNVTAS = item.CFG_VALOR1 === 1;
        } else if ('DSCPED' === valorUno) {
          this.chbtnDSCPED = item.CFG_VALOR1 === 1;
        } else if ('PREMAECLI' === valorUno) {
          this.chbtnPREMAECLI = item.CFG_VALOR1 === 1;
        } else if ('NOGSCC' === valorUno) {
          this.chbthNOGSCC = item.CFG_VALOR1 === 1;
        } else if ('RESARTPED' === valorUno) {
          this.chbtnRESARTPED = item.CFG_VALOR1 === 1;
        } else if ('BODDEFPED' === valorUno) {
          this.chbtnBODDEFPED = item.CFG_VALOR1 === 1;
        } else if ('REFMAE' === valorUno) {
          this.chbtnREFMAE = item.CFG_VALOR1 === 1;
        } else if ('RESUAP' === valorUno) {
          this.chbtnRESUAP = item.CFG_VALOR1 === 1;
        } else if ('ARTANTBOD' === valorUno) {
          this.chbtnARTANTBOD = item.CFG_VALOR1 === 1;
        } else if ('PEDVAL5' === valorUno) {
          this.chbtnPEDVAL5 = item.CFG_VALOR1 === 1;
        } else if ('NOASIFAC' === valorUno) {
          this.chbtnNOASIFAC = item.CFG_VALOR1 === 1;
        } else if ('FACLIGREQ' === valorUno) {
          this.chbtnFACLIGREQ = item.CFG_VALOR1 === 1;
        } else if ('FACNOAIMP' === valorUno) {
          this.chbtnFACNOAIMP = item.CFG_VALOR1 === 1;
        } else if ('PREBAJCOM' === valorUno) {
          this.chbtnPREBAJCOM = item.CFG_VALOR1 === 1;
        } else if ('RESPEDBOD' === valorUno) {
          this.chbtnRESPEDBOD = item.CFG_VALOR1 === 1;
        } else if ('NODPDNC' === valorUno) {
          this.chbtnNODPDNC = item.CFG_VALOR1 === 1;
        } else if ('ACTZONEXC' === valorUno) {
          this.chbtnACTZONEXC = item.CFG_VALOR1 === 1;
        } else if ('CODBARPRE' === valorUno) {
          this.chbtnCodBarPre = item.CFG_VALOR1 === 1;
        } else if ('VENUSU' === valorUno) {
          this.chbtnVENUSU = item.CFG_VALOR1 === 1;
        } else if ('ESTPEDESP' === valorUno) {
          this.chbtnESTPEDESP = item.CFG_VALOR1 === 1;
        } else if ('DIRCLIGR' === valorUno) {
          this.chbtnDIRCLIGR = item.CFG_VALOR1 === 1;
        } else if ('BUSEXTBOD' === valorUno) {
          this.chbtnBUSEXTBOD = item.CFG_VALOR1 === 1;
        } else if ('PORSEG' === valorUno) {
          this.chbtnPORSEG = item.CFG_VALOR1 === 1;
        } else if ('ACTCOMFE' === valorUno) {
          this.chbtnACTCOMFE = item.CFG_VALOR1 === 1;
        } else if ('REFFACNUM' === valorUno) {
          this.chbtnREFFACNUM = item.CFG_VALOR1 === 1;
        } else if ('COMNUMPED' === valorUno) {
          this.chbtnCOMNUMPED = item.CFG_VALOR1 === 1;
        } else if ('NUMPTOUSU' === valorUno) {
          this.chbtnNUMPTOUSU = item.CFG_VALOR1 === 1;
        } else if ('CAJXVEN' === valorUno) {
          this.chbtnCAJXVEN = item.CFG_VALOR1 === 1;
        } else if ('PERCAMSER' === valorUno) {
          this.chbtnPERCAMSER = item.CFG_VALOR1 === 1;
        } else if ('NOINSCF' === valorUno) {
          this.chbtnNOINSCF = item.CFG_VALOR1 === 1;
        } else if ('GUANUMPED' === valorUno) {
          this.chbtnGUANUMPED = item.CFG_VALOR1 === 1;
        } else if ('USURAZELI' === valorUno) {
          this.chbtnUSURAZELI = item.CFG_VALOR1 === 1;
        } else if ('NOMCLIOBS' === valorUno) {
          this.chbtnNOMCLIOBS = item.CFG_VALOR1 === 1;
        } else if ('SERSEGVTA' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtSERSEGVTA = String(item.CFG_VALOR3);
          } else {
            this.txtSERSEGVTA = '';
          }
        } else if ('LIMCONFIN' === valorUno) {
          if (item.CFG_VALOR1 !== null) {
            this.txtLIMCONFIN = String(item.CFG_VALOR2);
          } else {
            this.txtLIMCONFIN = '';
          }
        } else if ('BLOIMPFAC' === valorUno) {
          this.chbtnBLOIMPFAC = item.CFG_VALOR1 === 1;
        } else if ('LOTCARCF' === valorUno) {
          this.chbtnLOTCARCF = item.CFG_VALOR1 === 1;
        } else if ('NOMCLICON' === valorUno) {
          this.chbtnNOMCLICON = item.CFG_VALOR1 === 1;
        } else if ('TRAPEDCOM' === valorUno) {
          this.chbtnTRAPEDCOM = item.CFG_VALOR1 === 1;
        } else if ('TRPEACES' === valorUno) {
          this.chbtnTRPEACES = item.CFG_VALOR1 === 1;
        } else if ('AGINFOADI' === valorUno) {
          this.chbtnAGINFOADI = item.CFG_VALOR1 === 1;
        } else if ('GUINOKARD' === valorUno) {
          this.chbtnGUINOKARD = item.CFG_VALOR1 === 1;
        } else if ('CTRLDESLP' === valorUno) {
          this.chbtnCTRLDESLP = item.CFG_VALOR1 === 1;
        } else if ('VALINGLOT' === valorUno) {
          this.chbtnVALINGLOT = item.CFG_VALOR1 === 1;
        } else if ('VALLOTART' === valorUno) {
          this.chbtnVALLOTART = item.CFG_VALOR1 === 1;
        } else if ('AGRLOTCOM' === valorUno) {
          this.chbtnAGRLOTCOM = item.CFG_VALOR1 === 1;
        } else if ('DOLXPUNTO' === valorUno) {
          if (item.CFG_VALOR2 !== null) {
            this.strValorCod = String(item.CFG_VALOR2);
            this.txtDOLXPUNTO = String(this.strValorCod);
          }
        } else if ('ACTPEDVAL' === valorUno) {
          this.chbtnACTPEDVAL = item.CFG_VALOR1 === 1;
        } else if ('NOPEDCERO' === valorUno) {
          this.chkbtnNOPEDCERO = item.CFG_VALOR1 === 1;
        }
      }
    }
  }

  async presentarValoresPuntoVenta() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);
    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;
        if ('PVFACEXNEG' === valorUno) {
          this.chbtnPvFacExNeg = item.CFG_VALOR1 === 1;
        } else if ('PVFVFOCDET' === valorUno) {
          this.chbtnPvFvFocoDet = item.CFG_VALOR1 === 1;
        } else if ('PVDNROFAC' === valorUno) {
          this.chbtnPVDeshabilitadoNroFac = item.CFG_VALOR1 === 1;
        } else if ('PVIMPFRFP' === valorUno) {
          this.chbtnPvImpFacFormaPago = item.CFG_VALOR1 === 1;
        } else if ('PVPROAUFAC' === valorUno) {
          this.chbtnPvPromoAutoFac = item.CFG_VALOR1 === 1;
        } else if ('PVASTFECDV' === valorUno) {
          this.chbtnPvAsienFecDocVta = item.CFG_VALOR1 === 1;
        } else if ('PVNELINIMP' === valorUno) {
          this.chbtnPvNoElimFacImpr = item.CFG_VALOR1 === 1;
        } else if ('PVBLISTAP' === valorUno) {
          this.chbtnBloquearListaPrecios = item.CFG_VALOR1 === 1;
        } else if ('PVIMPDIR' === valorUno) {
          this.chbtnImpDirectoFacVta = item.CFG_VALOR1 === 1;
        } else if ('PVAUTFCVEN' === valorUno) {
          this.chbtnPvAutFcVen = item.CFG_VALOR1 === 1;
        } else if ('PVCAJADEPV' === valorUno) {
          this.chbtnPvCajaDepVen = item.CFG_VALOR1 === 1;
        } else if ('PVPREAIVA' === valorUno) {
          this.chbtnPreArtInIva = item.CFG_VALOR1 === 1;
        } else if ('PVCURLD' === valorUno) {
          this.chbtnNueFCurLd = item.CFG_VALOR1 === 1;
        }//CDPJ
        else if ('PAVFPT' === valorUno) {
          this.chbtnPAVFPT = item.CFG_VALOR1 === 1;
        } //CDPJ
         else if ('CNTRLESCLI' === valorUno) {
          this.chbtnCntrlEstdCli = item.CFG_VALOR1 === 1;
        } else if ('FOCOPRE' === valorUno) {
          this.chbtnFocoPre = item.CFG_VALOR1 === 1;
        } else if ('NOMODVENPV' === valorUno) {
          this.chbtnNoModifVendedorPv = item.CFG_VALOR1 === 1;
        } else if ('REFNUMER' === valorUno) {
          this.chbtnREFNUMER = item.CFG_VALOR1 === 1;
        } else if ('CONCALCAN' === valorUno) {
          this.chbtnCONCALCAN = item.CFG_VALOR1 === 1;
        } else if ('DEVFACFRAC' === valorUno) {
          this.chbtnDEVFACFRAC = item.CFG_VALOR1 === 1;
        } else if ('MAINVPTVTA' === valorUno) {
          this.chbtnMAINVPTVTA = item.CFG_VALOR1 === 1;
        } else if ('LSTPXVEN' === valorUno) {
          this.chbtnLSTPXVEN = item.CFG_VALOR1 === 1;
        } else if ('SERTARCOB' === valorUno) {
          this.chbtnSERTARCOB = item.CFG_VALOR1 === 1;
        } else if ('RESARTREP' === valorUno) {
          this.chbtnRESARTREP = item.CFG_VALOR1 === 1;
        } else if ('SELNVPV' === valorUno) {
          this.chbtnSELNVPV = item.CFG_VALOR1 === 1;
        } else if ('PLAOBLIFAC' === valorUno) {
          this.chbtnPLAOBLIFAC = item.CFG_VALOR1 === 1;
        } else if ('CONCALCAN' === valorUno) {
          this.chbtnCONCALCAN = item.CFG_VALOR1 === 1;
        } else if ('BUSSIMCLI' === valorUno) {
          this.chbtnBUSSIMCLI = item.CFG_VALOR1 === 1;
        } else if ('FORPAGAUT' === valorUno) {
          this.chbtnFORPAGAUT = item.CFG_VALOR1 === 1;
        } else if ('NUMCHEPRO' === valorUno) {
          if (item.CFG_VALOR2 !== null) {
            this.strValorCod = Number(item.CFG_VALOR2).toFixed(1);
            this.intTamanioCod = this.strValorCod.length - 2;
            this.strValorSinDecimal = this.strValorCod.substring(0, this.intTamanioCod);
            this.txtNroLimCheProtestado = String(this.strValorSinDecimal);
          } else {
            this.txtNroLimCheProtestado = '';
          }
        } else if ('NUMITEMPV' === valorUno) {
          if (item.CFG_VALOR2 !== null) {
            this.strValorCod = Number(item.CFG_VALOR2).toFixed(1);
            this.intTamanioCod = this.strValorCod.length - 2;
            this.strValorSinDecimal = this.strValorCod.substring(0, this.intTamanioCod);
            this.txtPvNumItemsFac = String(this.strValorSinDecimal);
          } else {
            this.txtPvNumItemsFac = '';
          }
        } else if ('FACCONF' === valorUno) {
          this.chbtnFacConsumFinal = item.CFG_VALOR1 === 1;
        } else if ('NUMFACPTO' === valorUno) {
          this.chbtnNumFacPto = item.CFG_VALOR1 === 1;
        } else if ('FACSINKAR' === valorUno) {
          this.chbtnFacSinKar = item.CFG_VALOR1 === 1;
        } else if ('FACNOPCER' === valorUno) {
          this.chbtnFacNoPreciCero = item.CFG_VALOR1 === 1;
        } else if ('NROPVGRB' === valorUno) {
          this.chbtnGenNumGrab = item.CFG_VALOR1 === 1;
        } else if ('BLOQPVIMP' === valorUno) {
          this.chbtnBloqPvImp = item.CFG_VALOR1 === 1;
        } else if ('FACVALCER' === valorUno) {
          this.chbtnFacValoreCero = item.CFG_VALOR1 === 1;
        } else if ('BLODSCDET' === valorUno) {
          this.chbtnBLODSCDET = item.CFG_VALOR1 === 1;
        } else if ('INGTOUCH' === valorUno) {
          this.chbtnINGTOUCH = item.CFG_VALOR1 === 1;
        } else if ('INGESPFAC' === valorUno) {
          this.chbtnINGESPFAC = item.CFG_VALOR1 === 1;
        } else if ('REFNUMER' === valorUno) {
          this.chbtnREFNUMER = item.CFG_VALOR1 === 1;
        } else if ('VENFUNCLI' === valorUno) {
          this.chbtnVENFUNCLI = item.CFG_VALOR1 === 1;
        } else if ('NOFACCF' === valorUno) {
          this.chbtnNOFACCF = item.CFG_VALOR1 === 1;
        } else if ('CODBARPV' === valorUno) {
          this.chbtnCodBarPrePV = item.CFG_VALOR1 === 1;
        } else if ('FACCAJUNI' === valorUno) {
          this.btnChkFacturaPorUnidades = item.CFG_VALOR1 === 1;
        } else if ('LSTPXVEN' === valorUno) {
          this.chbtnLSTPXVEN = item.CFG_VALOR1 === 1;
        } else if ('PARESCLI' === valorUno) {
          this.chbtnPARESCLI = item.CFG_VALOR1 === 1;
        } else if ('SERTARCOB' === valorUno) {
          this.chbtnSERTARCOB = item.CFG_VALOR1 === 1;
        } else if ('NOPEDCLAV' === valorUno) {
          this.chbtnNOPEDCLAV = item.CFG_VALOR1 === 1;
        } else if ('TTAPTOD' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtTTAPTOD = String(item.CFG_VALOR3);
          }
        } else if ('TTAPTOC' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtTTAPTOC = String(item.CFG_VALOR3);
          }
        }
      }
    }

  }
  async presentarValoresCtasPorCobrar() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);
    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;

        if ('NROCMPRAUT' === valorUno) {
          this.chbtnComprobtesIngAuto = item.CFG_VALOR1 === 1;
        } //CDPJ
        else if ('IMOBANCXCV' === valorUno) {
          this.chbtnIMOBANCXCV = item.CFG_VALOR1 === 1;
        } else if ('CCCAVNC' === valorUno) {
          this.chbtnCCCAVNC = item.CFG_VALOR1 === 1;
        }  else if ('CHPOSCOBR' === valorUno) {
          this.chbtnCHPOSCOBR = item.CFG_VALOR1 === 1;
        }   
        //CDPJ
        else if ('CXCNDDOCOB' === valorUno) {
          this.chbtnNotDebDocObli = item.CFG_VALOR1 === 1;
        } else if ('CNTRLCHPOS' === valorUno) {
          this.chbtnCntrlChPos = item.CFG_VALOR1 === 1;
        } else if ('TOTRETCXC' === valorUno) {
          this.chbtnTotRetCuentasXC = item.CFG_VALOR1 === 1;
        } else if ('COMAUTBP' === valorUno) {
          this.chbtnCOMAUTBP = item.CFG_VALOR1 === 1;
        } else if ('NOVFECCAN' === valorUno) {
          this.chbtnNOVFECCAN = item.CFG_VALOR1 === 1;
        } else if ('ANTEXCPAG' === valorUno) {
          this.chbtnANTEXCPAG = item.CFG_VALOR1 === 1;
        } else if ('CHPOSDEU' === valorUno) {
          this.chbtnCHPOSDEU = item.CFG_VALOR1 === 1;
        } else if ('NUMASICOM' === valorUno) {
          this.chbtnNumAsiComp = item.CFG_VALOR1 === 1;
        } else if ('ELIASIELI' === valorUno) {
          this.chbtnELIASIELI = item.CFG_VALOR1 === 1;
        } else if ('CHEPOS' === valorUno) {
          this.chbtnCHEPOS = item.CFG_VALOR1 === 1;
        } else if ('APLPORINT' === valorUno) {
          this.chbtnAPLPORINT = item.CFG_VALOR1 === 1;
        } else if ('OCUDETCUO' === valorUno) {
          this.chbtnOCUDETCUO = item.CFG_VALOR1 === 1;
        } else if ('PORINTMOR' === valorUno) {
          if (item.CFG_VALOR2 !== null) {
            this.strValorCod = String(item.CFG_VALOR2);
            this.txtPORINTMOR = this.strValorCod;
          }
        } else if ('TASACOB' === valorUno) {
          if (item.CFG_VALOR2 !== null) {
            this.strValorCod = String(item.CFG_VALOR2);
            this.txtTASACOB = this.strValorCod;
          }
        } else if ('CODCOMCOB' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtCODCOMCOB = String(item.CFG_VALOR3);
          } else {
            this.txtCODCOMCOB = '';
          }
        } else if ('CODCOMMOR' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtCODCOMMOR = String(item.CFG_VALOR3);
          } else {
            this.txtCODCOMMOR = '';
          }
        } else if ('DIAGRACIA' === valorUno) {
          if (item.CFG_VALOR2 !== null) {
            this.strValorCod = String(item.CFG_VALOR2);
            this.txDIAGRACIA = this.strValorCod;
          }
        }
      }
    }
  }

  async presentarValoresCtasPorPagar() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);
    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;

        if ('CXPAUTPG' === valorUno) {
          this.chbtnCxPAutrzPagos = item.CFG_VALOR1 === 1;
        } else if ('EAFPCA' === valorUno) {
          this.chbtnElimAstoForPagoCancel = item.CFG_VALOR1 === 1;
        } else if ('NROAPAGMOD' === valorUno) {
          this.chbtnNroAstoPagoMod = item.CFG_VALOR1 === 1;
        } else if ('CHEQIMP' === valorUno) {
          this.chbtnPvImpCheqImp = item.CFG_VALOR1 === 1;
        } else if ('ANUCHEA' === valorUno) {
          this.chbtnANUCHEA = item.CFG_VALOR1 === 1;
        }//GSRF
        else if ('CXPASIC' === valorUno) {
          this.chbtnCXPASIC = item.CFG_VALOR1 === 1; 
        }//GSRF
        else if('COMCCCAJ' === valorUno){
          this.chbtnCOMCCCAJ=item.CFG_VALOR1 === 1; 
        }
      }
    }
  }

  async presentarValoresBancos() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);
    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;

        if ('NUMCHEAUT' === valorUno) {
          this.chbtnNumCheqAut = item.CFG_VALOR1 === 1;
        } else if ('DESREQBAN' === valorUno) {
          this.cchbtnDESREQBAN = item.CFG_VALOR1 === 1;
        } else if ('COMBPAUT' === valorUno) {
          this.cchbtnCOMBPAUT = item.CFG_VALOR1 === 1;
        } else if ('CODBANPIC' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtCODBANPIC = item.CFG_VALOR3;
          }
        } else if ('CODBANPRO' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtCODBANPRO = item.CFG_VALOR3;
          }
        } else if ('CODBANINT' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtCODBANINT = item.CFG_VALOR3;
          }
        } else if ('CODBANGUA' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtCODBANGUA = item.CFG_VALOR3;
          }
        } else if ('CUETRACT' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtCUETRACT = item.CFG_VALOR3;
          }
        }
      }
    }
  }

  async presentarValoresConta() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);
    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;

        if ('CONASATMTC' === valorUno) {
          this.chbtnAstoAuto = item.CFG_VALOR1 === 1;
        } else if ('NROAMOD' === valorUno) {
          this.chbtnNroAstoModificable = item.CFG_VALOR1 === 1;
        } else if ('ASILOTCUA' === valorUno) {
          this.chbtnAstoLoteCuadre = item.CFG_VALOR1 === 1;
        } else if ('TOTRETCON' === valorUno) {
          this.chbtnTotRetConAstoLote = item.CFG_VALOR1 === 1;
        } else if ('REVASINRO' === valorUno) {
          this.chbtnRevAsiCon = item.CFG_VALOR1 === 1;
        } else if ('DOCSINASI' === valorUno) {
          this.chbtnDocSinAsi = item.CFG_VALOR1 === 1;
        } else if ('CONMAYGRL' === valorUno) {
          this.chkConMayGnrl = item.CFG_VALOR1 === 1;
        } else if ('PROGDETAS' === valorUno) {
          this.chBtnPROGDETAS = item.CFG_VALOR1 === 1;
        } else if ('CONEXPED' === valorUno) {
          this.chBtnCONEXPED = item.CFG_VALOR1 === 1;
        } else if ('BODCENCOS' === valorUno) {
          this.chbtnBODCENCOS = item.CFG_VALOR1 === 1;
        } else if ('CTAINI' === valorUno) {
          this.txtCuentaAper = item.CFG_VALOR3 === null ? '' : item.CFG_VALOR3;
        } else if ('NOPRGDCC' === valorUno) {
          this.chBtnNOPRGDCC = item.CFG_VALOR1 === 1;
        } else if ('OBSASIDET' === valorUno) {
          this.chBtnOBSASIDET = item.CFG_VALOR1 === 1;
        } else if ('NUMMENASI' === valorUno) {
          this.chBtnNUMMENASI = item.CFG_VALOR1 === 1;
        } else if ('CRLVISASI' === valorUno) {
          this.chBtnCRLVISASI = item.CFG_VALOR1 === 1;
        } else if ('INFCONBAN' === valorUno) {
          this.chBtnINFCONBAN = item.CFG_VALOR1 === 1;
        } else if ('CONTIPCI' === valorUno) {
          this.chkCONTIPCI = item.CFG_VALOR1 === 1;
        } else if ('COLASICOM' === valorUno) {
          this.chbtnCOLASICOM = item.CFG_VALOR1 === 1;
        } else if ('COMCCBOD' === valorUno) {
          this.chBtnCOMCCBOD = item.CFG_VALOR1 === 1;
        } else if ('FECINI' === valorUno) {
          this.dateTimeAsIni = item.CFG_VALOR3 === null ? new Date() : new Date(item.CFG_VALOR3);
        }
      }
    }
  }

  async presentarValoresAne() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);
    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;

        if ('NOCODRET' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtNoCodRet = String(item.CFG_VALOR3);
          } else {
            this.txtNoCodRet = '';
          }
        } else if ('PATHANEX' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtPathAnex = String(item.CFG_VALOR3);
          } else {
            this.txtPathAnex = '';
          }
        } else if ('FECRECEMI' === valorUno) {
          this.CHKFECRECEMI = item.CFG_VALOR1 === 1;
        } else if ('NCNOFAC' === valorUno) {
          this.CHKNCNOFAC = item.CFG_VALOR1 === 1;
        } else if ('SERNOATS1' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtSERNOATS1 = String(item.CFG_VALOR3);
          }
        } else if ('SERNOATS2' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtSERNOATS2 = String(item.CFG_VALOR3);
          }
        } else if ('SERNOATS3' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtSERNOATS3 = String(item.CFG_VALOR3);
          }
        } else if ('SERNOATS4' === valorUno) {
          if (item.CFG_VALOR3 !== null) {
            this.txtSERNOATS4 = String(item.CFG_VALOR3);
          }
        } else if ('GENXMLIND' === valorUno) {
          this.CHKGENXMLIND = item.CFG_VALOR1 === 1;
        }
      }
    }
  }

  async presentarValoresProd() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);
    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;
        if ('DATTECP' === valorUno) {
          this.chbtnDATTECP = item.CFG_VALOR1 === 1;
        } else if ('VISCANFOR' === valorUno) {
          this.chbtnVISCANFOR = item.CFG_VALOR1 === 1;
        }
        //CDPJ
        else if ('CCPBOPP' === valorUno) {
          this.chbtnCCPBOPP = item.CFG_VALOR1 === 1;
        }else if ('CLORDPRO' === valorUno) {
          this.chbtnCLORDPRO = item.CFG_VALOR1 === 1;
        }
        
        //CDPJ
      }
    }
  }

  async presentarValoresOtros() {
    let valores: SaciMaeConfiguracion[] = [];
    this.strSentencia = 'Select * From SACI_MAECONFIGURACION';
    valores = await this.confListar(this.strSentencia);
    for (const item of valores) {
      if (item !== null) {
        const valorUno = item.CFG_CODIGO;

        if ('NUMCOT' === valorUno) {
          this.lstNumCot = [];
          this.lstNumCot.push({ name: 'Punto de Venta', codigo: 0, data: 'NUMCOT' });
          this.lstNumCot.push({ name: 'Clase de Documento', codigo: 1, data: 'NUMCOT' });
          this.lblNumCot = item.CFG_NOMBRE;
          if (String(item.CFG_VALOR3) === 'C') {
            this.selectNumCot = this.lstNumCot[0];
          } else {
            this.selectNumCot = this.lstNumCot[1];
          }
        } else if ('NUMFAC' === valorUno) {
          this.lblNumFac = item.CFG_NOMBRE;
          if (String(item.CFG_VALOR3) === 'C') {
            this.selectNumFac = this.lstNumFac[0];
          } else {
            this.selectNumFac = this.lstNumFac[1];
          }
        } else if ('NUMPED' === valorUno) {
          this.lblNumPed = item.CFG_NOMBRE;
          if (String(item.CFG_VALOR3) === 'C') {
            this.selectNumPed = this.lstNumPed[0];
          } else {
            this.selectNumPed = this.lstNumPed[1];
          }
        } else if ('NUMGRE' === valorUno) {
          this.lblNumGre = item.CFG_NOMBRE;
          if (String(item.CFG_VALOR3) === 'C') {
            this.selectNumGre = this.lstNumGre[0];
          } else {
            this.selectNumGre = this.lstNumGre[1];
          }
        } else if ('NUMDVF' === valorUno) {
          this.lblNumDvf = item.CFG_NOMBRE;
          if (String(item.CFG_VALOR3) === 'C') {
            this.selectNumDvf = this.lstNumDvf[0];
          } else {
            this.selectNumDvf = this.lstNumDvf[1];
          }
        } else if ('NUMCOM' === valorUno) {
          this.lblNumCom = item.CFG_NOMBRE;
          if (String(item.CFG_VALOR3) === 'C') {
            this.selectNumCom = this.lstNumCom[0];
          } else {
            this.selectNumCom = this.lstNumCom[1];
          }
        } else if ('IVAPRE' === valorUno) {
          this.lblIvaPre = item.CFG_NOMBRE;
          if (String(item.CFG_VALOR3) === 'E') {
            this.selectIvaPre = this.lstIvaPre[0];
          } else if (String(item.CFG_VALOR3) === 'N') {
            this.selectIvaPre = this.lstIvaPre[1];
          } else if (String(item.CFG_VALOR3) === 'I') {
            this.selectIvaPre = this.lstIvaPre[2];
          }
        } else if ('TIPDET' === valorUno) {
          this.lblTipDet = item.CFG_NOMBRE;
          if (String(item.CFG_VALOR3) === 'A') {
            this.selectTipDet = this.lstTipDet[0];
          } else if (String(item.CFG_VALOR3) === 'S') {
            this.selectTipDet = this.lstTipDet[1];
          } else if (String(item.CFG_VALOR3) === 'C') {
            this.selectTipDet = this.lstTipDet[2];
          } else if (String(item.CFG_VALOR3) === 'F') {
            this.selectTipDet = this.lstTipDet[3];
          } else if (String(item.CFG_VALOR3) === '*') {
            this.selectTipDet = this.lstTipDet[4];
          }
        } else if ('NUMORDPRO' === valorUno) {
          this.lblNumOrdPro = item.CFG_NOMBRE;
          if (String(item.CFG_VALOR3) === 'C') {
            this.selectNumOrdPro = this.lstNumOrdPro[0];
          } else {
            this.selectNumOrdPro = this.lstNumOrdPro[1];
          }
        } else if ('TIPOBUS' === valorUno) {
          if (String(item.CFG_VALOR3) === '1') {
            this.selectCondBus = this.listCondBus[0];
          } else if (String(item.CFG_VALOR3) === '2') {
            this.selectCondBus = this.listCondBus[1];
          } else if (String(item.CFG_VALOR3) === '3') {
            this.selectCondBus = this.listCondBus[2];
          }
        } else if ('REPEMPAUTO' === valorUno) {
          this.chbtnEmpresaAutoImpre = item.CFG_VALOR1 === 1;
        } else if ('DESINDBUS' === valorUno) {
          this.chbtnDesIndBus = item.CFG_VALOR1 === 1;
        } else if ('GENCLAAUT' === valorUno) {
          this.chbtnGenClaAut = item.CFG_VALOR1 === 1;
        } else if ('BUSDIGCAR' === valorUno) {
          this.chbtnEjeBusCarIng = item.CFG_VALOR1 === 1;
        } else if ('PANACTEMA' === valorUno) {
          this.chbtnDesplePantActEmail = item.CFG_VALOR1 === 1;
        } else if ('CLAAUTFE' === valorUno) {
          this.chbtnCLAAUTFE = item.CFG_VALOR1 === 1;
        }else if ('RANGFECHA' === valorUno) {
          this.chbtnRangfechacontabilisa = item.CFG_VALOR1 === 1;
        }
      }
    }
  }

  async confListar(declaracion) {
    const configuracion: SaciMaeConfiguracion[] = [];
    try {
      const rs = await this.sacimaeConfigService.presentarValoresInventario(declaracion);
      if (rs !== null) {
        for (const conf of rs) {
          const item: SaciMaeConfiguracion = {};
          item.CFG_CODIGO = conf.CFG_CODIGO;
          item.CFG_NOMBRE = conf.CFG_NOMBRE;
          item.CFG_VALOR1 = conf.CFG_VALOR1;
          item.CFG_VALOR2 = conf.CFG_VALOR2;
          item.CFG_VALOR3 = conf.CFG_VALOR3;
          configuracion.push(item);
        }
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    return configuracion;
  }

  async AlmacenarCambios() {
    if (this.indexTab === 0) {
      this.configuracionINV('/INV');
      this.deshabilitarbotonGuardar();
    }
    if (this.indexTab === 1) {
      this.configuracionCOM('/COM');
      this.deshabilitarbotonGuardar();
    }
    if (this.indexTab === 2) {
      this.configuracionVEN('/VEN');
      this.deshabilitarbotonGuardar();
    }
    if (this.indexTab === 3) {
      this.configuracionPTOVTA('/PTOVTA');
      this.deshabilitarbotonGuardar();
    }
    if (this.indexTab === 4) {
      this.configuracionCXC('/CXC');
      this.deshabilitarbotonGuardar();
    }
    if (this.indexTab === 5) {
      this.configuracionCXP('/CXP');
      this.deshabilitarbotonGuardar();
    }
    if (this.indexTab === 6) {
      this.configuracionBAN('/BAN');
      this.deshabilitarbotonGuardar();
    }
    if (this.indexTab === 7) {
      if (! await this.informacionCorrecta()) {
        return;
      }
      this.configuracionCON('/CON');
      this.deshabilitarbotonGuardar();
    }
    if (this.indexTab === 8) {
      this.configuracionANEX('/ANEX');
      this.deshabilitarbotonGuardar();
    }
    if (this.indexTab === 9) {
      this.configuracionPROD('/PROD');
      this.deshabilitarbotonGuardar();
    }
    if (this.indexTab === 10) {
      this.configuracionOTROS('/OTROS');
      this.deshabilitarbotonGuardar();
    }
  }

  async configuracionINV(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkInv');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;
      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }

    // Txt
    const inputText = document.getElementsByClassName('txtInv');
    for (let i = 0; i < inputText.length; i++) {
      this.datoTxt = inputText[i].getAttribute('ng-reflect-model');
      this.codConfig = inputText[i].getAttribute('name');
      this.validarNumcarSer(this.datoTxt, prefijo, this.codConfig);
      this.validarNumcarSim(this.datoTxt, prefijo, this.codConfig);
      this.actualizarValorText(this.datoTxt, this.codConfig, prefijo);
    }

    // DateTime
    const inputCalendar = document.getElementsByClassName('dateInv');
    for (let i = 0; i < inputCalendar.length; i++) {
      console.log(inputCalendar[i]);
      this.datoTxt = new Date(inputCalendar[i].getAttribute('ng-reflect-model'));
      this.codConfig = inputCalendar[i].getAttribute('name');
      this.datoTxt = this.datoTxt.toLocaleString('en-GB', { timeZone: 'UTC' }).split(',');
      const strFecha = this.datoTxt[0];

      this.actualizarValorText(strFecha, this.codConfig, prefijo);
    }


  }

  async configuracionCOM(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkCom');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;
      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }

    // Txt
    const inputText = document.getElementsByClassName('txtCom');
    for (let i = 0; i < inputText.length; i++) {
      this.datoTxt = inputText[i].getAttribute('ng-reflect-model');
      this.codConfig = inputText[i].getAttribute('name');
      this.validarNumcarSer(this.datoTxt, prefijo, this.codConfig);
      this.validarNumcarSim(this.datoTxt, prefijo, this.codConfig);
      this.actualizarValorText(this.datoTxt, this.codConfig, prefijo);
    }

    // DateTime
    const inputCalendar = document.getElementsByClassName('dateCom');
    for (let i = 0; i < inputCalendar.length; i++) {
      console.log(inputCalendar[i]);
      this.datoTxt = new Date(inputCalendar[i].getAttribute('ng-reflect-model'));
      this.codConfig = inputCalendar[i].getAttribute('name');
      this.datoTxt = this.datoTxt.toLocaleString('en-GB', { timeZone: 'UTC' }).split(',');
      const strFecha = this.datoTxt[0];

      this.actualizarValorText(strFecha, this.codConfig, prefijo);

    }
  }

  async configuracionVEN(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkVen');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;

      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }

    // Txt
    const inputText = document.getElementsByClassName('txtVen');
    for (let i = 0; i < inputText.length; i++) {
      this.datoTxt = inputText[i].getAttribute('ng-reflect-model');
      this.codConfig = inputText[i].getAttribute('name');
      this.validarNumcarSer(this.datoTxt, prefijo, this.codConfig);
      this.validarNumcarSim(this.datoTxt, prefijo, this.codConfig);
      this.actualizarValorText(this.datoTxt, this.codConfig, prefijo);
    }

    // DateTime
    const inputCalendar = document.getElementsByClassName('dateVen');
    for (let i = 0; i < inputCalendar.length; i++) {
      console.log(inputCalendar[i]);
      this.datoTxt = new Date(inputCalendar[i].getAttribute('ng-reflect-model'));
      this.codConfig = inputCalendar[i].getAttribute('name');
      this.datoTxt = this.datoTxt.toLocaleString('en-GB', { timeZone: 'UTC' }).split(',');
      const strFecha = this.datoTxt[0];

      this.actualizarValorText(strFecha, this.codConfig, prefijo);

    }
  }

  async configuracionPTOVTA(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkPV');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;
      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }

    // Txt
    const inputText = document.getElementsByClassName('txtPV');
    for (let i = 0; i < inputText.length; i++) {
      this.datoTxt = inputText[i].getAttribute('ng-reflect-model');
      this.codConfig = inputText[i].getAttribute('name');
      this.validarNumcarSer(this.datoTxt, prefijo, this.codConfig);
      this.validarNumcarSim(this.datoTxt, prefijo, this.codConfig);
      this.actualizarValorText(this.datoTxt, this.codConfig, prefijo);
    }

    // DateTime
    const inputCalendar = document.getElementsByClassName('datePV');
    for (let i = 0; i < inputCalendar.length; i++) {
      console.log(inputCalendar[i]);
      this.datoTxt = new Date(inputCalendar[i].getAttribute('ng-reflect-model'));
      this.codConfig = inputCalendar[i].getAttribute('name');
      this.datoTxt = this.datoTxt.toLocaleString('en-GB', { timeZone: 'UTC' }).split(',');
      const strFecha = this.datoTxt[0];

      this.actualizarValorText(strFecha, this.codConfig, prefijo);

    }
  }

  async configuracionCXC(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkCXC');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;
      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }

    // Txt
    const inputText = document.getElementsByClassName('txtCXC');
    for (let i = 0; i < inputText.length; i++) {
      this.datoTxt = inputText[i].getAttribute('ng-reflect-model');
      this.codConfig = inputText[i].getAttribute('name');
      this.validarNumcarSer(this.datoTxt, prefijo, this.codConfig);
      this.validarNumcarSim(this.datoTxt, prefijo, this.codConfig);
      this.actualizarValorText(this.datoTxt, this.codConfig, prefijo);
    }

    // DateTime
    const inputCalendar = document.getElementsByClassName('dateCXC');
    for (let i = 0; i < inputCalendar.length; i++) {
      console.log(inputCalendar[i]);
      this.datoTxt = new Date(inputCalendar[i].getAttribute('ng-reflect-model'));
      this.codConfig = inputCalendar[i].getAttribute('name');
      this.datoTxt = this.datoTxt.toLocaleString('en-GB', { timeZone: 'UTC' }).split(',');
      const strFecha = this.datoTxt[0];

      this.actualizarValorText(strFecha, this.codConfig, prefijo);

    }
  }

  async configuracionCXP(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkCXP');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;
      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }

    // Txt
    const inputText = document.getElementsByClassName('txtCXP');
    for (let i = 0; i < inputText.length; i++) {
      this.datoTxt = inputText[i].getAttribute('ng-reflect-model');
      this.codConfig = inputText[i].getAttribute('name');
      this.validarNumcarSer(this.datoTxt, prefijo, this.codConfig);
      this.validarNumcarSim(this.datoTxt, prefijo, this.codConfig);
      this.actualizarValorText(this.datoTxt, this.codConfig, prefijo);
    }

    // DateTime
    const inputCalendar = document.getElementsByClassName('dateCXP');
    for (let i = 0; i < inputCalendar.length; i++) {
      console.log(inputCalendar[i]);
      this.datoTxt = new Date(inputCalendar[i].getAttribute('ng-reflect-model'));
      this.codConfig = inputCalendar[i].getAttribute('name');
      this.datoTxt = this.datoTxt.toLocaleString('en-GB', { timeZone: 'UTC' }).split(',');
      const strFecha = this.datoTxt[0];

      this.actualizarValorText(strFecha, this.codConfig, prefijo);

    }
  }

  async configuracionBAN(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkBAN');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;
      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }

    // Txt
    const inputText = document.getElementsByClassName('txtBAN');
    for (let i = 0; i < inputText.length; i++) {
      this.datoTxt = inputText[i].getAttribute('ng-reflect-model');
      this.codConfig = inputText[i].getAttribute('name');
      this.validarNumcarSer(this.datoTxt, prefijo, this.codConfig);
      this.validarNumcarSim(this.datoTxt, prefijo, this.codConfig);
      this.actualizarValorText(this.datoTxt, this.codConfig, prefijo);
    }

    // DateTime

    const inputCalendar = document.getElementsByClassName('dateBAN');
    for (let i = 0; i < inputCalendar.length; i++) {
      console.log(inputCalendar[i]);
      this.datoTxt = new Date(inputCalendar[i].getAttribute('ng-reflect-model'));
      this.codConfig = inputCalendar[i].getAttribute('name');
      this.datoTxt = this.datoTxt.toLocaleString('en-GB', { timeZone: 'UTC' }).split(',');
      const strFecha = this.datoTxt[0];

      this.actualizarValorText(strFecha, this.codConfig, prefijo);

    }
  }

  async configuracionCON(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkCON');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;
      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }

    // Txt
    const inputText = document.getElementsByClassName('txtCON');
    for (let i = 0; i < inputText.length; i++) {
      this.datoTxt = inputText[i].getAttribute('ng-reflect-model');
      this.codConfig = inputText[i].getAttribute('name');
      this.validarNumcarSer(this.datoTxt, prefijo, this.codConfig);
      this.validarNumcarSim(this.datoTxt, prefijo, this.codConfig);
      this.actualizarValorText(this.datoTxt, this.codConfig, prefijo);
    }

    // DateTime
    const inputCalendar = document.getElementsByClassName('dateCON');
    for (let i = 0; i < inputCalendar.length; i++) {
      console.log(inputCalendar[i]);
      this.datoTxt = new Date(inputCalendar[i].getAttribute('ng-reflect-model'));
      this.codConfig = inputCalendar[i].getAttribute('name');
      this.datoTxt = this.datoTxt.toLocaleString('en-GB', { timeZone: 'UTC' }).split(',');
      const strFecha = this.datoTxt[0];

      this.actualizarValorText(strFecha, this.codConfig, prefijo);
    }
  }

  async configuracionANEX(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkANE');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;
      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }

    // Txt
    const inputText = document.getElementsByClassName('txtANE');
    for (let i = 0; i < inputText.length; i++) {
      this.datoTxt = inputText[i].getAttribute('ng-reflect-model');
      this.codConfig = inputText[i].getAttribute('name');
      this.validarNumcarSer(this.datoTxt, prefijo, this.codConfig);
      this.validarNumcarSim(this.datoTxt, prefijo, this.codConfig);
      this.actualizarValorText(this.datoTxt, this.codConfig, prefijo);
    }

    // DateTime
    const inputCalendar = document.getElementsByClassName('dateANE');
    for (let i = 0; i < inputCalendar.length; i++) {
      console.log(inputCalendar[i]);
      this.datoTxt = new Date(inputCalendar[i].getAttribute('ng-reflect-model'));
      this.codConfig = inputCalendar[i].getAttribute('name');
      this.datoTxt = this.datoTxt.toLocaleString('en-GB', { timeZone: 'UTC' }).split(',');
      const strFecha = this.datoTxt[0];

      this.actualizarValorText(strFecha, this.codConfig, prefijo);
    }
  }

  async configuracionPROD(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkProd');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;
      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }
  }

  async configuracionOTROS(prefijo) {
    // CheckBox
    const checkBox = document.getElementsByClassName('chkOTRO');
    for (let i = 0; i < checkBox.length; i++) {
      const strTextChbtn = checkBox[i].getAttribute('label');
      this.codConfig = checkBox[i].getAttribute('name') === null ? '' : checkBox[i].getAttribute('name');
      const bolChequeado: boolean = checkBox[i].getAttribute('ng-reflect-model') === 'true' ? true : false;
      if (bolChequeado === true) {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          await this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 1;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      } else {
        if (this.codConfig === 'PROCOM') {
          this.intValorAsignado = this.determinarProcesoCompras(bolChequeado, strTextChbtn);
          this.actualizarValorCheckProcCompras(this.intValorAsignado, this.codConfig, prefijo);
        } else {
          this.intValor = 0;
          this.actualizarValorCheck(this.intValor, this.codConfig, prefijo);
        }
      }
    }

    // Txt
    const inputText = document.getElementsByClassName('txtOTRO');
    for (let i = 0; i < inputText.length; i++) {
      this.datoTxt = inputText[i].getAttribute('ng-reflect-model');
      this.codConfig = inputText[i].getAttribute('name');
      this.validarNumcarSer(this.datoTxt, prefijo, this.codConfig);
      this.validarNumcarSim(this.datoTxt, prefijo, this.codConfig);
      this.actualizarValorText(this.datoTxt, this.codConfig, prefijo);
    }

    // DateTime
    const inputCalendar = document.getElementsByClassName('dateOTRO');
    for (let i = 0; i < inputCalendar.length; i++) {
      console.log(inputCalendar[i]);
      this.datoTxt = new Date(inputCalendar[i].getAttribute('ng-reflect-model'));
      this.codConfig = inputCalendar[i].getAttribute('name');
      this.datoTxt = this.datoTxt.toLocaleString('en-GB', { timeZone: 'UTC' }).split(',');
      const strFecha = this.datoTxt[0];

      this.actualizarValorText(strFecha, this.codConfig, prefijo);
    }

    /*** Guardar las listas ***/
    // TIPOBUS
    this.codConfig = this.selectCondBus.data;
    if (this.codConfig === 'TIPOBUS' && this.selectCondBus.codigo === 0) {
      this.actualizarValorLista('1', this.codConfig, prefijo);
    } else if (this.codConfig === 'TIPOBUS' && this.selectCondBus.codigo === 1) {
      this.actualizarValorLista('2', this.codConfig, prefijo);
    } else if (this.codConfig === 'TIPOBUS' && this.selectCondBus.codigo === 2) {
      this.actualizarValorLista('3', this.codConfig, prefijo);
    }

    // NUMCOT
    this.codConfig = this.selectNumCot.data;
    if (this.selectNumCot.codigo === 0) {
      this.strOpcLista = this.lstNumCot[0].name;
      if (this.strOpcLista === 'Punto de Venta') {
        this.strOpcLista = 'Clase';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectNumCot.codigo === 1) {
      this.strOpcLista = this.lstNumCot[1].name;
      if (this.strOpcLista === 'Clase de Documento') {
        this.strOpcLista = 'General';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    }

    // NUMCOM
    this.codConfig = this.selectNumCom.data;
    if (this.selectNumCom.codigo === 0) {
      this.strOpcLista = this.lstNumCom[0].name;
      if (this.strOpcLista === 'Punto de Venta') {
        this.strOpcLista = 'Clase';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectNumCom.codigo === 1) {
      this.strOpcLista = this.lstNumCom[1].name;
      if (this.strOpcLista === 'Clase de Documento') {
        this.strOpcLista = 'General';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    }

    // NUMFAC
    this.codConfig = this.selectNumFac.data;
    if (this.selectNumFac.codigo === 0) {
      this.strOpcLista = this.lstNumFac[0].name;
      if (this.strOpcLista === 'Punto de Venta') {
        this.strOpcLista = 'Clase';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectNumFac.codigo === 1) {
      this.strOpcLista = this.lstNumFac[1].name;
      if (this.strOpcLista === 'Clase de Documento') {
        this.strOpcLista = 'General';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    }

    // TIPODET
    this.codConfig = this.selectTipDet.data;
    if (this.selectTipDet.codigo === 0) {
      this.strOpcLista = this.lstTipDet[0].name;
      if (this.strOpcLista === 'Punto de Venta') {
        this.strOpcLista = 'Clase';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectTipDet.codigo === 1) {
      this.strOpcLista = this.lstTipDet[1].name;
      if (this.strOpcLista === 'Clase de Documento') {
        this.strOpcLista = 'General';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectTipDet.codigo === 2) {
      this.strOpcLista = this.lstTipDet[2].name;
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectTipDet.codigo === 3) {
      this.strOpcLista = this.lstTipDet[3].name;
      if (this.strOpcLista === 'Activo Fijo') {
        this.strOpcLista = 'Fijo';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectTipDet.codigo === 4) {
      this.strOpcLista = this.lstTipDet[4].name;
      this.strPriInicial = '*';
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    }

    // NUMPED
    this.codConfig = this.selectNumPed.data;
    if (this.selectNumPed.codigo === 0) {
      this.strOpcLista = this.lstNumPed[0].name;
      if (this.strOpcLista === 'Punto de Venta') {
        this.strOpcLista = 'Clase';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectNumPed.codigo === 1) {
      this.strOpcLista = this.lstNumPed[1].name;
      if (this.strOpcLista === 'Clase de Documento') {
        this.strOpcLista = 'General';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    }

    // NUMORDPRO
    this.codConfig = this.selectNumOrdPro.data;
    if (this.selectNumOrdPro.codigo === 0) {
      this.strOpcLista = this.lstNumOrdPro[0].name;
      if (this.strOpcLista === 'Punto de Venta') {
        this.strOpcLista = 'Clase';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectNumOrdPro.codigo === 1) {
      this.strOpcLista = this.lstNumOrdPro[1].name;
      if (this.strOpcLista === 'Clase de Documento') {
        this.strOpcLista = 'General';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    }

    // NUMGRE
    this.codConfig = this.selectNumGre.data;
    if (this.selectNumGre.codigo === 0) {
      this.strOpcLista = this.lstNumGre[0].name;
      if (this.strOpcLista === 'Punto de Venta') {
        this.strOpcLista = 'Clase';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectNumGre.codigo === 1) {
      this.strOpcLista = this.lstNumGre[1].name;
      if (this.strOpcLista === 'Clase de Documento') {
        this.strOpcLista = 'General';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    }

    // NUMDVF
    this.codConfig = this.selectNumDvf.data;
    if (this.selectNumDvf.codigo === 0) {
      this.strOpcLista = this.lstNumDvf[0].name;
      if (this.strOpcLista === 'Punto de Venta') {
        this.strOpcLista = 'Clase';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    } else if (this.selectNumDvf.codigo === 1) {
      this.strOpcLista = this.lstNumDvf[1].name;
      if (this.strOpcLista === 'Clase de Documento') {
        this.strOpcLista = 'General';
      }
      this.strPriInicial = this.strOpcLista.substring(0, 1);
      this.actualizarValorLista(this.strPriInicial, this.codConfig, prefijo);
    }

  }

  async actualizarValorLista(strPriInicial, strCodigo, strPrefijo) {
    try {
      this.strCadena = 'update saci_maeconfiguracion set cfg_valor3=\'' + strPriInicial + '\' where cfg_codigo = \'' + strCodigo + '\'';
      await this.sacimaeConfigService.actualizarValorLista(strPriInicial, strCodigo);
      this.strDato = strCodigo + strPriInicial + strPrefijo;
      this.auditoriaGralService.registrarAuditoria('SACI_MAECONFIGURACION', this.strDato, 'A',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }



  validarNumcarSer(datotxt, prefijo, codigoConf) {
    let datoTxt = datotxt;
    const codConfig = codigoConf;
    if (codConfig === 'NUMCARSER') {
      if (String(datoTxt).trim() === '' || Number(datoTxt) > 30) {
        this.mensajeAlerta('info', 'Valor', 'Mayor máximo 30 caracteres');
        this.txtNumCarSer = '30';
        datoTxt = '30';
        this.actualizarValorText(datoTxt, codConfig, prefijo);
      }
    }
  }

  validarNumcarSim(datotxt, prefijo, codigoConf) {
    let datoTxt = datotxt;
    const codConfig = codigoConf;
    if (codConfig === 'NUMCARSIM') {
      if (String(datoTxt).trim() === '' || Number(datoTxt) > 30) {
        this.mensajeAlerta('info', 'Valor', 'Mayor máximo 30 caracteres');
        this.txtNumCarSer = '30';
        datoTxt = '30';
        this.actualizarValorText(datoTxt, codConfig, prefijo);
      }
    }
  }

  async actualizarValorText(strTexto, strCodigo, prefijo) {
    try {
      if (strCodigo === 'INICODCLI' || strCodigo === 'CODCONFIN' || strCodigo === 'GRUPOCF' || strCodigo === 'ETIVEND' || strCodigo === 'IMPSERCLI'
        || strCodigo === 'PATHANEX' || strCodigo === 'NOCODRET' || strCodigo === 'SERSEGVTA' || strCodigo === 'PORSEGVTA' ||
        strCodigo === 'CTAINI' || strCodigo === 'FECINI' || strCodigo === 'CODCAMVEN' || strCodigo === 'CODBANPIC' || strCodigo === 'CODBANPRO'
        || strCodigo === 'CODBANINT' || strCodigo === 'CODBANGUA' || strCodigo === 'CONCOSFEC'
        || strCodigo === 'SERNOATS1'|| strCodigo ==='SERNOATS2'|| strCodigo ==='SERNOATS3'|| strCodigo ==='SERNOATS4') {
        this.strCadena = 'update saci_maeconfiguracion set cfg_valor3 = \'' + strTexto + '\' where cfg_codigo =\'' + strCodigo + '\'';
        await this.sacimaeConfigService.actualizarValor3(strTexto, strCodigo);
      } else {
        this.strCadena = 'update saci_maeconfiguracion set cfg_valor2 = \'' + strTexto + '\' where cfg_codigo =\'' + strCodigo + '\'';
        await this.sacimaeConfigService.actualizarValor2(strTexto, strCodigo);
      }

      // await this.sacimaeConfigService.ejecutarSentencia(this.strCadena);

      this.strDato = strCodigo + strTexto + prefijo;
      this.auditoriaGralService.registrarAuditoria('SACI_MAECONFIGURACION', this.strDato, 'A',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  determinarProcesoCompras(bolVisto: boolean, strTxtChbtn: string): number {
    // Determina el proceso de compras
    if (bolVisto === true && strTxtChbtn === 'Ordenes de compra.') { // chbtnOrdCompra.getSelection() = true
      this.opcion1 = 1;
    }
    if (bolVisto === true && strTxtChbtn === 'Notas de recepción.') { // chbtnNotRec.getSelection() = true
      this.opcion2 = 1;
    }
    if (bolVisto === true && strTxtChbtn === 'Facturas a proveedores.') { // chbtnFacAproveedores.getSelection() = true
      this.opcion3 = 1;
    }
    // Es necesario que exista la orden de compra para realizar la nota de recepción
    // Es necesario que exista la nota de recepción para realizar la factura
    if (this.opcion1 === 1 && this.opcion2 === 1 && this.opcion3 === 1) {
      this.valor = 1;
    } else if (this.opcion1 === 0 && this.opcion2 === 1 && this.opcion3 === 1) { // No es necesario que exista la orden de compra para
      // realizar la nota de recepción
      this.valor = 2;                                                        // Es necesario que exista la nota de recepción para realizar la factura
    } else if (this.opcion1 === 1 && this.opcion2 === 0 && this.opcion3 === 1) {
      this.valor = 3;
    } else if (this.opcion1 === 1 && this.opcion2 === 1 && this.opcion3 === 0) { // casos independientes
      this.valor = 4;
    } else if (this.opcion1 === 0 && this.opcion2 === 0 && this.opcion3 === 0) {
      this.valor = 5;
    } else if (this.opcion1 === 1 && this.opcion2 === 0 && this.opcion3 === 0) {
      this.valor = 6;
    } else if (this.opcion1 === 0 && this.opcion2 === 1 && this.opcion3 === 0) {
      this.valor = 7;
    } else { // Puede hacer indistintamente  la orden de compra, nota de recepción, y factura
      this.valor = 0;
    }
    return this.valor;
  }

  async actualizarValorCheckProcCompras(intChequeado, strCodigo, prefijo) {
    try {
      this.strCadena = 'update saci_maeconfiguracion set cfg_valor1= \'' + intChequeado + '\' where cfg_codigo =\'' + strCodigo + '\'';
      await this.sacimaeConfigService.actualizarValor1(intChequeado,strCodigo);
      this.strDato = strCodigo + String(intChequeado) + prefijo;
      this.auditoriaGralService.registrarAuditoria('SACI_MAECONFIGURACION', this.strDato, 'A',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  async actualizarValorCheck(intChequeado, strCodigo, prefijo) {
    try {
      this.strCadena = 'update saci_maeconfiguracion set cfg_valor1= \'' + intChequeado + '\' where cfg_codigo =\'' + strCodigo + '\'';
      await this.sacimaeConfigService.actualizarValor1(intChequeado,strCodigo);
      this.strDato = strCodigo + String(intChequeado) + prefijo;
      this.auditoriaGralService.registrarAuditoria('SACI_MAECONFIGURACION', this.strDato, 'A',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  async informacionCorrecta() {
    let bolCorrecto = true;
    let strCuentaNombre = '';
    // validar la información de la cuenta contable de apertura
    if (this.txtCuentaAper.length > 0) {
      try {
        const rs = await this.sacimaeConfigService.erConNombre1(this.txtCuentaAper);
        strCuentaNombre = rs === null ? '' : rs[0].CON_NOMBRE;
        if (strCuentaNombre.length === 0) {
          bolCorrecto = false;
          this.mensajeAlerta('info', 'Información', 'La cuenta contable de la contrapartida no existe en el plan de cuentas.');
          this.txtCuentaAper = '';
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia de la cuenta contable ' + mensaje);
      }
    }
    return bolCorrecto;
  }

  async buscarCliente() {
    this.lblCliente = '';
    let strNombreCliente = '';
    const strValorCajTexto = this.txtCodConFinal;

    if (strValorCajTexto.length > 0) { // si ingreso de datos en la caja de texto

      try {
        const rs = await this.sacimaeConfigService.erCliNombre2(strValorCajTexto);
        strNombreCliente = rs === null ? '' : rs[0].CLI_NOMBRE;
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'No se puede consultar los clientes. ' + mensaje);
      }
    }

    this.buscarClienteDlg(strNombreCliente, strValorCajTexto);
  }

  buscarClienteDlg(strNombreCliente, strValorCajTexto) {

    if (strNombreCliente.length === 0) { // si el valor que ingreso esta incorrecto

      this.opcion = 'BuscarCliente';
      this.types = [
        { label: 'CODIGO', value: 'CLI_CODIGO' },
        { label: 'NOMBRE', value: 'CLI_NOMBRE' },
        { label: 'RUCIDE', value: 'CLI_RUCIDE' },
        { label: 'ZONA', value: 'CLI_ZONA' },
        { label: 'VENDEDOR', value: 'VENDEDOR' },
        { label: 'GRUPO', value: 'CLI_GRUPCODIGO' },
        { label: 'CONTACTO', value: 'CLI_CONTACTO' },
        { label: 'PLACA', value: 'CLI_PLACA' },
        { label: 'NOMBREC', value: 'CLI_NOMBREC' },

      ];

      this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
      this.consulta = 'CLI_nombre, CLI_codigo, CLI_rucide, VEN_FNC_OBTENER_ZONACLI(NVL(CLI_ZONA,\'NN\')) as CLI_ZONA, ' +
        'VEN_FNC_NOMBRE_VENDEDOR(VEN_codigo) as VENDEDOR, VEN_FNC_OBTENER_GRU_NOMBRE_CLI(CLI_codigo) as CLI_GRUPCODIGO, ' +
        'cli_contacto,cli_placa,cli_nombrec';
      this.tabla = 'ven_maecliente';
      this.where = '';

      this.sacimaeConfigService.erVenMaeCliente(strValorCajTexto).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.manejarSeleccion(eR[0]);
            } else {
              this.busquedaSer.busquedaVenMaeCliente5(strValorCajTexto).subscribe((datos: any[]) => {
                  this.arregloCons = datos;
                  this.displayDialogBusquedaFast = true;
                });
            }
          } else {
            this.busquedaSer.busquedaVenMaeCliente5(strValorCajTexto).subscribe((datos: any[]) => {
                this.arregloCons = datos;
                this.displayDialogBusquedaFast = true;
              });
          }
        });

    } else { // si el valor que fue ingresado es correcto lo despliega
      this.lblCliente = strNombreCliente;
    }
  }

  async buscarGrpCliente() {
    this.lblGrpCliente = '';
    let strNombreGrpCliente = '';
    const strValorCajTexto = this.txtGrupoConsumFinal;

    if (strValorCajTexto.length > 0) { // si ingreso de datos en la caja de texto
      try {
        const rs = await this.sacimaeConfigService.erGruNombre2(strValorCajTexto);
        strNombreGrpCliente = rs === null ? '' : rs[0].GRU_NOMBRE;
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'No se puede consultar los clientes. ' + mensaje);
      }
    }

    this.buscarGrpClienteDlg(strNombreGrpCliente, strValorCajTexto);
  }

  buscarGrpClienteDlg(strNombreGrpCliente, strValorCajTexto) {

    if (strNombreGrpCliente.length === 0) { // si el valor que ingreso esta incorrecto

      this.opcion = 'BuscarGrpCliente';
      this.types = [
        { label: 'CODIGO', value: 'GRU_CODIGO' },
        { label: 'NOMBRE', value: 'GRU_NOMBRE' },
        { label: 'CTA.CONTABLE', value: 'CON_CODIGO' },
        { label: 'DESCTO', value: 'CON_CODIGODES' },
        { label: 'PORCE.DSCTO', value: 'GRU_PORDES' },
        { label: 'CNT.COSTOS', value: 'GRU_PORDES' },
      ];

      this.busquedacampos = ['', '', '', '', '', ''];
      this.consulta = '*';
      this.tabla = 'ven_maeGRUPO';
      this.where = 'ROWNUM < 100 AND ' + this.types[0].value +
        ' LIKE \'%' + strValorCajTexto + '%\' and GRU_TIPO = \'CLI\'';

      this.sacimaeConfigService.erVenMaeGrupo(strValorCajTexto).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.manejarSeleccion(eR[0]);
            } else {
              this.busquedaSer.busquedaVenMaeGrupo2(strValorCajTexto).subscribe((datos: any[]) => {
                this.arregloCons = datos;
                this.displayDialogBusquedaFast = true;
              });
            }
          } else {
            this.busquedaSer.busquedaVenMaeGrupo2(strValorCajTexto).subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        });

    } else { // si el valor que fue ingresado es correcto lo despliega
      this.lblGrpCliente = strNombreGrpCliente;
    }
  }

  async buscarCuentaContable() {
    let strNombreCuenta = '';
    const strValorCajTexto = this.txtCuentaAper;

    if (strValorCajTexto.length > 0) { // si ingreso de datos en la caja de texto
      try {
        const rs = await this.sacimaeConfigService.erConNombre2(strValorCajTexto);
        strNombreCuenta = rs === null ? '' : rs[0].CON_NOMBRE;
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', 'No se puede consultar los clientes. ' + mensaje);
      }
    }

    this.buscarCuentaContDlg(strNombreCuenta, strValorCajTexto);
  }

  buscarCuentaContDlg(strNombreCuenta, strValorCajTexto) {

    if (strNombreCuenta.length === 0) { // si el valor que ingreso esta incorrecto

      this.opcion = 'BuscarCuentaContable';
      this.types = [
        { label: 'CODIGO', value: 'CON_CODIGO' },
        { label: 'NOMBRE', value: 'CON_NOMBRE' },
        { label: 'CNT.COSTOS', value: 'CON_CENTRO' },
        { label: 'COD.SRI', value: 'CON_CODSRI' }
      ];

      this.busquedacampos = ['', '', '', ''];
      this.consulta = '*';
      this.tabla = 'con_maecon';
      this.where = 'ROWNUM < 100 AND ' + this.types[0].value +
        ' LIKE \'%' + strValorCajTexto + '%\'';

      this.sacimaeConfigService.erConMaeCon(strValorCajTexto).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.manejarSeleccion(eR[0]);
            } else {
              this.busquedaSer.busquedaConMaeCon5(strValorCajTexto).subscribe((datos: any[]) => {
                this.arregloCons = datos;
                this.displayDialogBusquedaFast = true;
              });
            }
          } else {
            this.busquedaSer.busquedaConMaeCon5(strValorCajTexto).subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        });
    }
  }

  async presentarValoresSACI() {
    const detalleImpuesto: ConMaeImpuesto[] = [];
    try {
      const sentencia = 'SELECT codigo,nombre,con_codigo, com_codigo FROM CON_MAEIMPUESTOS';
      const rs = await this.sacimaeConfigService.getConMaeImpuestos();
      if (rs !== null) {
        for (const imp of rs) {
          const item: ConMaeImpuesto = {};
          item.CODIGO = imp.CODIGO;
          item.NOMBRE = imp.NOMBRE;
          item.CON_CODIGO = imp.CON_CODIGO;
          item.COM_CODIGO = imp.COM_CODIGO;
          detalleImpuesto.push(item);
        }
        this.sacimaeConfigService.detConMaeImpArray[this.indicador] = detalleImpuesto;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    const detalleNumDec: SaciMaeNumDec[] = [];
    try {
      const sentencia = 'SELECT numdec_campo,numdec_forma,numdec_tipdoc,numdec_numdec ,NUMDEC_DESCRIPCION FROM SACI_MAENUMDEC order by numdec_forma';
      const rs = await this.sacimaeConfigService.getSaciMaeNumDec();
      if (rs !== null) {
        for (const numdec of rs) {
          const item: SaciMaeNumDec = {};
          item.NUMDEC_CAMPO = numdec.NUMDEC_CAMPO;
          item.NUMDEC_DESCRIPCION = numdec.NUMDEC_DESCRIPCION;
          item.NUMDEC_FORMA = numdec.NUMDEC_FORMA;
          item.NUMDEC_NUMDEC = numdec.NUMDEC_NUMDEC;
          item.NUMDEC_TIPDOC = numdec.NUMDEC_TIPDOC;
          detalleNumDec.push(item);
        }
        this.sacimaeConfigService.detSaciMaeNumDecArray[this.indicador] = detalleNumDec;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    const detalleVenImp: VenMaeImpuesto[] = [];
    try {
      const sentencia = 'SELECT * FROM VEN_MAEIMPUESTO';
      const rs = await this.sacimaeConfigService.getVenMaeImpuestos();
      if (rs !== null) {
        for (const numdec of rs) {
          const item: VenMaeImpuesto = {};
          item.IMP_DESCRIPCION = numdec.IMP_DESCRIPCION;
          item.IMP_PORCENTAJE = Number(numdec.IMP_PORCENTAJE).toFixed(1);
          item.IMP_TARIFA = numdec.IMP_TARIFA;
          item.IMP_TIPO = numdec.IMP_TIPO;
          item.IMP_VALOR = Number(numdec.IMP_VALOR).toFixed(1);
          detalleVenImp.push(item);
        }
        this.sacimaeConfigService.detVenMaeImpArray[this.indicador] = detalleVenImp;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    //CDPJ
    try {
      //const sentencia = 'SELECT * FROM VEN_MAEIMPUESTO';
      const detalleVenImp: SacieImpuestoSri[] = [];
      const rs = await this.sacimaeConfigService.genMaeImpuestosSri();
      if (rs !== null && rs !== undefined) {
        for (const numdec of rs) {
          const item: SacieImpuestoSri = {};
          item.TARIIVA_CODIGO = numdec.TARIIVA_CODIGO;
          item.TARIIVA_DESCRIPCION =numdec.TARIIVA_DESCRIPCION;
          item.TARIIVA_PORCENTAJE = Number(numdec.TARIIVA_PORCENTAJE).toFixed(1);
          item.TARIIVA_ACTIVO = numdec.TARIIVA_ACTIVO ==='S'?true:false
          item.NUEVO=false;
          detalleVenImp.push(item);
        }
        this.sacimaeConfigService.detMaeImpSriArray[this.indicador] = detalleVenImp;
      }
    } catch (err) {
      this.sacimaeConfigService.detMaeImpSriArray[this.indicador]=[];
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    //CDPJ
  }
//CDPJ
seleccionimpsri(params){
  if (params === 'nuevo') { 
    this.nuevo();
    
    return;
  }
  if (params === 'guardar') {
    this.guardarimp();
    return;
  }
  if (params === 'cancelar') {
    //this.cancelar();
    return;
  }
  if (params === 'eliminar') {
    this.eliminar();
    return;
  }

  this.maeimsriseleccted = params.object;
  this.init.auxsalto = false;
}
nuevo(){
  const impsrinuevo: SacieImpuestoSri = {
    TARIIVA_CODIGO:'',
    TARIIVA_DESCRIPCION:'',
    TARIIVA_PORCENTAJE:'',
    TARIIVA_ACTIVO:true,
    NUEVO: true
  };
  const indi = this.sacimaeConfigService.detMaeImpSriArray[this.indicador].indexOf(this.maeimsriseleccted);
  if (indi === -1) {
    //impsrinuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    this.maeimsriseleccted=impsrinuevo
    this.sacimaeConfigService.detMaeImpSriArray[this.indicador].push(impsrinuevo);
    //this.aggrid.refreshaggrid(this.artService.maeartprecio, this.defaultColDef.objeto);
    this.aggrid.refreshaggrid(this.sacimaeConfigService.detMaeImpSriArray[this.indicador], this.defaultMaeImpSri.objeto);
    if (this.sacimaeConfigService.detMaeImpSriArray[this.indicador].length === 1) {
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultMaeImpSri.objeto].setFocusedCell(0, 'TARIIVA_CODIGO');
    } else {
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultMaeImpSri.objeto].setFocusedCell((this.sacimaeConfigService.detMaeImpSriArray[this.indicador].length) - 1, 'TARIIVA_CODIGO');
    }
  } else {
    //precioNuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    this.sacimaeConfigService.detMaeImpSriArray[this.indicador].splice(indi + 1, 0, impsrinuevo);
    this.maeimsriseleccted=impsrinuevo
    this.aggrid.refreshaggridindex(this.sacimaeConfigService.detMaeImpSriArray[this.indicador], this.defaultMaeImpSri.objeto, indi);
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultMaeImpSri.objeto].setFocusedCell(indi + 1, 'TARIIVA_CODIGO');
  }
}
async guardarimp(){
  let mensaje: string;
  if(this.maeimsriseleccted.TARIIVA_CODIGO === ''){
    mensaje='Ingrese el código del Impuesto';
    this.mensajeAlerta('error', 'Error', mensaje);
    return;
  }
  if(this.maeimsriseleccted.TARIIVA_PORCENTAJE === ''){
    mensaje='Ingrese el porcentaje del Impuesto';
    this.mensajeAlerta('error', 'Error', mensaje);
    return;
  }
  if(this.maeimsriseleccted.TARIIVA_DESCRIPCION === ''){
    mensaje='Ingrese la descripción del Impuesto';
    this.mensajeAlerta('error', 'Error', mensaje);
    return;
  }
  try {
    this.maeimsriseleccted.TARIIVA_ACTIVO= this.maeimsriseleccted.TARIIVA_ACTIVO === true?'S':'N';
    if(this.maeimsriseleccted.NUEVO === true){
    await this.sacimaeConfigService.guardarimp(this.maeimsriseleccted);
    mensaje='El impuesto se guardó correctamente'
    this.mensajeAlerta('success', 'Información', mensaje);
    this.auditoriaGralService.registrarAuditoria('SACI_MAECONFIGURACION', this.maeimsriseleccted.TARIIVA_CODIGO, 'I',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
    }else{
      await this.sacimaeConfigService.actualizaimp(this.maeimsriseleccted);
    mensaje='El impuesto se actualizó correctamente'
    this.mensajeAlerta('success', 'Información', mensaje);
    this.auditoriaGralService.registrarAuditoria('SACI_MAECONFIGURACION', this.maeimsriseleccted.TARIIVA_CODIGO, 'A',
    this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
    });
    }
  } catch (err) {
    mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
    this.mensajeAlerta('error', 'Error', mensaje);
  }
}
async eliminar(){
  let mensaje: string;
  if (this.maeimsriseleccted === undefined) {
    mensaje='No se ha seleccionado el impuesto para eliminar'
    this.mensajeAlerta('error', 'Error', mensaje);
    return;
  }
  if(this.maeimsriseleccted.NUEVO === true){
    this.sacimaeConfigService.detMaeImpSriArray[this.indicador].splice(this.sacimaeConfigService.detMaeImpSriArray[this.indicador].findIndex(
      detfac => detfac.TARIIVA_CODIGO === this.maeimsriseleccted.TARIIVA_CODIGO && detfac.NUEVO === true ),
      1);
      this.aggrid.refreshaggrid(this.sacimaeConfigService.detMaeImpSriArray[this.indicador], this.defaultMaeImpSri.objeto);
      mensaje='El impuesto se eliminó correctamente'
      this.mensajeAlerta('success', 'Información', mensaje);
      if (this.sacimaeConfigService.detMaeImpSriArray[this.indicador].length > 0) {
        this.maeimsriseleccted = this.sacimaeConfigService.detMaeImpSriArray[this.indicador][this.sacimaeConfigService.detMaeImpSriArray[this.indicador].length - 1];
      }
  }else{
    try {
      await this.sacimaeConfigService.eliminarimpsri(this.maeimsriseleccted.TARIIVA_CODIGO)
      this.sacimaeConfigService.detMaeImpSriArray[this.indicador].splice(this.sacimaeConfigService.detMaeImpSriArray[this.indicador].findIndex(
        detfac => detfac.TARIIVA_CODIGO === this.maeimsriseleccted.TARIIVA_CODIGO && detfac.NUEVO === false ),
        1);
        this.auditoriaGralService.registrarAuditoria('SACI_MAECONFIGURACION', this.maeimsriseleccted.TARIIVA_CODIGO, 'E',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
        mensaje='El impuesto se eliminó correctamente'
        this.mensajeAlerta('success', 'Información', mensaje);
        this.aggrid.refreshaggrid(this.sacimaeConfigService.detMaeImpSriArray[this.indicador], this.defaultMaeImpSri.objeto);
        if (this.sacimaeConfigService.detMaeImpSriArray[this.indicador].length > 0) {
          this.maeimsriseleccted = this.sacimaeConfigService.detMaeImpSriArray[this.indicador][this.sacimaeConfigService.detMaeImpSriArray[this.indicador].length - 1];
        }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
   
  }
}
//CDPJ

  manejarSeleccion(opcion) {
    if (this.opcion === 'BuscarCliente') {
      this.btnGuardar = false;
      this.lblCliente = opcion.CLI_NOMBRE;
      this.txtCodConFinal = opcion.CLI_CODIGO;
      this.displayDialogBusquedaFast = false;
    }
    if (this.opcion === 'BuscarGrpCliente') {
      this.btnGuardar = false;
      this.lblGrpCliente = opcion.GRU_NOMBRE;
      this.txtGrupoConsumFinal = opcion.GRU_CODIGO;
      this.displayDialogBusquedaFast = false;
    }
    if (this.opcion === 'BuscarCuentaContable') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
      } else {
        this.btnGuardar = false;
        this.txtCuentaAper = opcion.CON_CODIGO;
        this.displayDialogBusquedaFast = false;
      }
    }

  }

  deshabilitarbotonGuardar() {
    this.btnGuardar = true;
  }

  activarBotonGuardar(event) {
    this.btnGuardar = false;
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'saciMaeConfig',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

}

