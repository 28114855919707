import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { Cxctrnresumen } from '../cxcinterfaces/cxctrnresumen';
import { Cxctrncobro } from '../cxcinterfaces/cxctrncobro';
import { Cxctrnretencion } from '../cxcinterfaces/cxctrnretencion';
import { Cxctrnotrosg } from '../cxcinterfaces/cxctrnotrosg';
import { Cxctrnformapago } from '../cxcinterfaces/cxctrnformapago';
import { Cxctrnvarios } from '../cxcinterfaces/cxctrnvarios';
import { Cxctrndebitobancario } from '../cxcinterfaces/cxctrndebitobancario';
import { Cxctrnanticipos } from '../cxcinterfaces/cxctrnanticipos';
import { Cxctrnformapagodev } from '../cxcinterfaces/cxctrnformapagodev';
import { Cxctrnanexos } from '../cxcinterfaces/cxctrnanexos';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CxccarteraactivaService {


  public way: string;

  public encabezadoCarteraActivaarray: Cxctrncobro[] = [];
  public tipoDocumentoarray: string[] = [];
  public cxctrnresumenarray: Cxctrnresumen[][] = [];
  public cxctrnfaccanceladasarray: Cxctrnresumen[][] = [];
  public cxctrnretencionarray: Cxctrnretencion[][] = [];
  public cxctrnotrosgarray: Cxctrnotrosg[][] = [];
  public cxctrnformapagoarray: Cxctrnformapago[][] = [];
  public cxctrnformapagodevarray: Cxctrnformapagodev[][] = [];
  public cxctrnvariosarray: Cxctrnvarios[][] = [];
  public cxcdebitobancarioarray: Cxctrndebitobancario[][] = [];
  public cxctrnanticiposarray: Cxctrnanticipos[][] = [];
  public dblTotalDocarray: number[] = [];
  public dblTotalRetarray: number[] = [];
  public dblTotalOtrosGarray: number[] = [];
  public dblTotalFormaPagoarray: number[] = [];
  public dblTotalFormaDevarray: number[] = [];
  public dblTotalVariosarray: number[] = [];
  public dblTotalNDarray: number[] = [];
  public dblTotalNCarray: number[] = [];
  public dblTotalCobrararray: number[] = [];


  public decimalesCantidadDevF: number;
  public decimalesPrecioDevF: number;
  public decimalesTotalDevF: number;
  public dblPorcIvaDevF = 12;

  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService,
    private message: MessageService, private init: NuevoComponentService,
    private utilitariosService: UtilitariosService, private datePipe: DatePipe) {
    this.way = sessionStorage.getItem('way');
  }

  getEncCartera(cobroNro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/10775d76315beb000', {
      elementos: {
        TRNCOBRO_NRODOC: cobroNro
      },
    }, this.confIniciales.httpOptions());
  }

  getEncCarteraProm(cobroNro): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/10775d76315beb000', {
      elementos: {
        TRNCOBRO_NRODOC: cobroNro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getListaClientes(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/20eed65cea995a0000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getListaReferencias(codCli): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/10776b2e755b74000', {
      elementos: {
        CLI_CODIGO: codCli
      },
    }, this.confIniciales.httpOptions());
  }

  llenarTipoDoc(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/ad5d757659c3700', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getOrigen(aplorgcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/107771b941d7', {
      elementos: {
        APLORG_CODIGO: aplorgcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  getOrigenProm(aplorgcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/107771b941d7', {
      elementos: {
        APLORG_CODIGO: aplorgcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getRealizado(usuidentificacion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/83bbb72ab2', {
      elementos: {
        USUIDENTIFICACION: usuidentificacion
      },
    }, this.confIniciales.httpOptions());
  }

  getRealizadoProm(usuidentificacion): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/83bbb72ab2', {
      elementos: {
        USUIDENTIFICACION: usuidentificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDocCobrados(cliCodigo, numFac): Observable<any[]> {
    let sentencia = '';
    if (cliCodigo !== '') {
      sentencia += ' AND D.CLI_CODIGO=\'' + cliCodigo + '\'';
    }
    if (numFac !== '') {
      sentencia += ' AND UPPER(D.TRNDOCUMENTO_NRODOC) LIKE \'%' + numFac + '%\'';
    }

    const sqlQ = 'SELECT D.TRNDOCUMENTO_NRODOC,C.TRNCOBRO_NRODOC,C.TRNCOBRO_FECHATRN,D.TRNDOCUMENTO_IMPORTE, D.CLI_CODIGO, C.TRNCOBRO_NROCOMPROBANTE ' +
      'FROM CXC_TRNDOCUMENTO D,CXC_TRNCOBRO C WHERE D.CLI_CODIGO = C.CLI_CODIGO AND D.TRNCOBRO_NRODOC=C.TRNCOBRO_NRODOC AND D.COM_CODIGO=' +
      'C.COM_CODIGO AND C.COM_CODIGO=\'01\'' + sentencia + ' ORDER BY D.TRNDOCUMENTO_NRODOC ASC,C.TRNCOBRO_FECHATRN ASC';

    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/83badc330bda980', {
      elementos: {
        strsentencia: sentencia
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerClienteNom(cliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/185f59593afdbe000', {
      elementos: {
        CLI_CODIGO: cliCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  consultarDocumentosACobrar(opcion, condicion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/198bf3d56e18a60000', {
      elementos: {
        p_opcion: opcion,
        p_filtro: 'COM_CODIGO LIKE \'01\'',
        p_condicion: condicion
      },
    }, this.confIniciales.httpOptions());
  }

  erTrncobronrodoc(strNrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1dbeeeecc2f77e0000', {
      elementos: {
        TRNPAGO_NUMERO: strNrodoc
      },
    }, this.confIniciales.httpOptions());
  }

  erMaecliente(strClicodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76eca732b275fc0', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erBancodigo(strConcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b6b55d986ca18', {
      elementos: {
        CON_CODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConcodigo(strCajcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b6cc5d986ca18', {
      elementos: {
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erCajcodigo(strFormapagotipo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76d8a9b30d94300', {
      elementos: {
        FORMAPAGO_TIPO: strFormapagotipo
      },
    }, this.confIniciales.httpOptions());
  }

  erCountSacitrnretencion(strReferencia): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/198f5fbdddf6ef0000', {
      elementos: {
        TRNRETENCION_REFERENCIA: strReferencia
      },
    }, this.confIniciales.httpOptions());
  }


  erAnexMaeCodSri(strWhere, strIdcretri): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1db55dd65399870000', {
      elementos: {
        where: strWhere,
        TRNANEX_IDCRETRI: strIdcretri
      },
    }, this.confIniciales.httpOptions());
  }

  erCodsridescripcion(strTipcom): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76d986f3726bb80', {
      elementos: {
        TRNANEX_TIPCOM: strTipcom
      },
    }, this.confIniciales.httpOptions());
  }

  erSacimaereferencia(strWhere, strParametro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/edd94edb9eedb800', {
      elementos: {
        where: strWhere,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erTrnpagorefer(strNrodoc){
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1dbeeef95431b70000', {
      elementos: {
        TRNCOBRO_NRODOC: strNrodoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrnbanconcilia(strNrodoc, strBanclicodigo, strPagotipo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/edf7775aaecc6000', {
      elementos: {
        TRNCOBRO_NRODOC: strNrodoc,
        BANCLI_CODIGO: strBanclicodigo,
        TRNPAGO_TIPO: strPagotipo
      },
    }, this.confIniciales.httpOptions());
  }

  erTrncajdepositonro(strNumero, strBanclicodigo, strPagotipo, strDocnro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1dbeeeec54db9f0000', {
      elementos: {
        TRNPAGO_NUMERO: strNumero,
        BANCLI_CODIGO: strBanclicodigo,
        TRNPAGO_TIPO: strPagotipo,
        TRNCOBRO_NRODOC: strDocnro
      },
    }, this.confIniciales.httpOptions());
  }

  erTrncajdepositonro2(strNumero, strComprobante, strPagotipo, strCajcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/edf777629b9bf000', {
      elementos: {
        TRNPAGO_NUMERO: strNumero,
        TRNCOBRO_NROCOMPROBANTE: strComprobante,
        TRNPAGO_TIPO: strPagotipo,
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erTrntarjetacodigo(strNumero, strComprobante, strPagotipo, strCajcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76faadcd986ca00', {
      elementos: {
        TRNPAGO_NUMERO: strNumero,
        TRNCOBRO_NROCOMPROBANTE: strComprobante,
        TRNPAGO_TIPO: strPagotipo,
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erTrncobrocuadre(strNrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/edf7776616cf2800', {
      elementos: {
        TRNCOBRO_NRODOC: strNrodoc
      },
    }, this.confIniciales.httpOptions());
  }

  erCountTrnDatosAnex(strNrodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1db65fbddddaaf0000', {
      elementos: {
        TRNCOBRO_NRODOC: strNrodoc
      },
    }, this.confIniciales.httpOptions());
  }

  erRefnombrec(strCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76f6e7df165edc0', {
      elementos: {
        REF_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConcodigo2(strCodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76d98bb30d94300', {
      elementos: {
        CLI_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erMaeclienteProm(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76eca732b275fc0', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erBanbancoProm(strcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1db5aaeb55d98', {
      elementos: {
        BAN_CODIGO: strcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erBanclinombreProm(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b6b55d9595f16', {
      elementos: {
        BANCLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erCountTrnDatosAnexProm(strNrodoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1db65fbddddaaf0000', {
      elementos: {
        TRNCOBRO_NRODOC: strNrodoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erRefnombrecProm(strCodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76f6e7df165edc0', {
      elementos: {
        REF_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrnbanconciliaProm(strNrodoc, strBanclicodigo, strPagotipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/edf7775aaecc6000', {
      elementos: {
        TRNCOBRO_NRODOC: strNrodoc,
        BANCLI_CODIGO: strBanclicodigo,
        TRNPAGO_TIPO: strPagotipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrncajdepositonroProm(strNumero, strBanclicodigo, strPagotipo, strDocnro): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1dbeeeec54db9f0000', {
      elementos: {
        TRNPAGO_NUMERO: strNumero,
        BANCLI_CODIGO: strBanclicodigo,
        TRNPAGO_TIPO: strPagotipo,
        TRNCOBRO_NRODOC: strDocnro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrncajdepositonro2Prom(strNumero, strComprobante, strPagotipo, strCajcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/edf777629b9bf000', {
      elementos: {
        TRNPAGO_NUMERO: strNumero,
        TRNCOBRO_NROCOMPROBANTE: strComprobante,
        TRNPAGO_TIPO: strPagotipo,
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrntarjetacodigoProm(strNumero, strComprobante, strPagotipo, strCajcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76faadcd986ca00', {
      elementos: {
        TRNPAGO_NUMERO: strNumero,
        TRNCOBRO_NROCOMPROBANTE: strComprobante,
        TRNPAGO_TIPO: strPagotipo,
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrncobrocuadreProm(strNrodoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/edf7776616cf2800', {
      elementos: {
        TRNCOBRO_NRODOC: strNrodoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrncobronrodocProm(strNrodoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1dbeeeecc2f77e0000', {
      elementos: {
        TRNPAGO_NUMERO: strNrodoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erClinombreProm(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b6cacaf8b2f6e', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erVennombreProm(strVencodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b7f75ef8b2f6e', {
      elementos: {
        VEN_CODIGO: strVencodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erRetencioncodigoProm(strRetencioncod): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/ededdd75d986c800', {
      elementos: {
        RETENCION_CODIGO: strRetencioncod
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erComisioncodigoProm(strcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76d98b49986ca00', {
      elementos: {
        COMISION_CODIGO: strcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erFormapagotipoProm(strtipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/edbf1bb6550c7800', {
      elementos: {
        TRNPAGO_TIPO: strtipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erCodsricodigo1Prom(strcodsri): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76d986f372661c0', {
      elementos: {
        TRNPAGO_CODSRI: strcodsri
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erCodsricodigo2Prom(strcodsri): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1db661bcdc982', {
      elementos: {
        TRNPAGO_CODSRI: strcodsri
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConcodigo3Prom(strcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b6cc5d986ca04', {
      elementos: {
        CON_CODIGO: strcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erCajcodigoProm(strFormapagotipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76d8a9b30d94300', {
      elementos: {
        FORMAPAGO_TIPO: strFormapagotipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConcodigoProm(strCajcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b6cc5d986ca18', {
      elementos: {
        CAJ_CODIGO: strCajcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erFormapagocodsriProm(strtipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b6fc6f2a87372', {
      elementos: {
        FORMAPAGO_TIPO: strtipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erClidiachposProm(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/edb2b26c94c8e800', {
      elementos: {
        CLI_CODIGO: strClicodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
  insertarCobroFCND(cobro: Cxctrncobro, tipoDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/95f8edf54cc2f80', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }  
  //GSRF

  insertarCobro(cobro: Cxctrncobro, tipoDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/95f8edf54cc2f80', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TOTALNC),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }
  insertarCobroP1(cobro: Cxctrncobro, tipoDoc): Promise<any> {
    console.log(cobro);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/95f8edf54cc2f80', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarCobroP(cobro: Cxctrncobro, tipoDoc): Promise<any[]> {
    console.log(cobro);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/95f8edf54cc2f80', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarDocPorCobrar(cobro: Cxctrnresumen, nroDoc, nroComp): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/12bf1b8665985f000', {
      elementos: {
        p_TRNCOBRO_nroDoc_1: nroDoc,
        p_TRNDOCUMENTO_nroDoc_2: cobro.TRNCOBRO_NRODOC,
        p_TRNDOCUMENTO_importe_3: Number(cobro.TRNCOBRO_IMPORTE),
        p_COM_codigo_4: '01',
        p_CLI_codigo_5: cobro.CLI_CODIGO,
        p_TRNDOCUMENTO_REF: cobro.STRCUOTAFAC,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
      },
    }, this.confIniciales.httpOptions());
  }

  insertarDocPorCobrarP(cobro: Cxctrnresumen, nroDoc, nroComp): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/12bf1b8665985f000', {
      elementos: {
        p_TRNCOBRO_nroDoc_1: nroDoc,
        p_TRNDOCUMENTO_nroDoc_2: cobro.TRNCOBRO_NRODOC,
        p_TRNDOCUMENTO_importe_3: Number(cobro.TRNCOBRO_IMPORTE),
        p_COM_codigo_4: '01',
        p_CLI_codigo_5: cobro.CLI_CODIGO,
        p_TRNDOCUMENTO_REF: cobro.STRCUOTAFAC,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarRetencion(retencion: Cxctrnretencion, nroDoc, nroComp): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/4afceeefb775d800000', {
      elementos: {
        p_retencion_codigo_1: retencion.RETENCION_CODIGO,
        p_trnretencion_descripcion_4: retencion.TRNRETENCION_DESCRIPCION,
        p_trnretencion_nro_1: retencion.TRNRETENCION_NRO,
        p_trnretencion_base_5: Number(retencion.TRNRETENCION_BASE),
        p_trnretencion_porcentaje_6: Number(retencion.TRNRETENCION_PORCENTAJE),
        p_trnretencion_totalretenido_7: Number(retencion.TRNRETENCION_TOTALRETENIDO),
        p_trncobro_nrodoc_8: nroDoc,
        p_com_codigo_3: '01',
        p_trnretencion_origen: retencion.TRNRETENCION_ORIGEN,
        p_con_codigo: retencion.CON_CODIGO,
        p_cen_codigo: retencion.CEN_CODIGO,
        p_cli_codigo: retencion.CLI_CODIGO,
        p_ENCFAC_NUMERO: retencion.ENCFAC_NUMERO,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
      },
    }, this.confIniciales.httpOptions());
  }

  insertarRetencionP(retencion: Cxctrnretencion, nroDoc, nroComp): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/4afceeefb775d800000', {
      elementos: {
        p_retencion_codigo_1: retencion.RETENCION_CODIGO,
        p_trnretencion_descripcion_4: retencion.TRNRETENCION_DESCRIPCION,
        p_trnretencion_nro_1: retencion.TRNRETENCION_NRO,
        p_trnretencion_base_5: Number(retencion.TRNRETENCION_BASE),
        p_trnretencion_porcentaje_6: Number(retencion.TRNRETENCION_PORCENTAJE),
        p_trnretencion_totalretenido_7: Number(retencion.TRNRETENCION_TOTALRETENIDO),
        p_trncobro_nrodoc_8: nroDoc,
        p_com_codigo_3: '01',
        p_trnretencion_origen: retencion.TRNRETENCION_ORIGEN,
        p_con_codigo: retencion.CON_CODIGO,
        p_cen_codigo: retencion.CEN_CODIGO,
        p_cli_codigo: retencion.CLI_CODIGO,
        p_ENCFAC_NUMERO: retencion.ENCFAC_NUMERO,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarTipoRetencion(retencion, tipo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/533bd9671b775c000000', {
      elementos: {
        trnretencion_tiporet: tipo,
        trnretencion_referencia: retencion.ENCFAC_NUMERO,
        cli_codigo: retencion.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarTipoRetencionProm(retencion, tipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/533bd9671b775c000000', {
      elementos: {
        trnretencion_tiporet: tipo,
        trnretencion_referencia: retencion.ENCFAC_NUMERO,
        cli_codigo: retencion.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarComision(comision: Cxctrnotrosg, nroDoc, nroComp): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/257e3b7d662d2e0000', {
      elementos: {
        p_COMISION_codigo_1: comision.COMISION_CODIGO,
        p_TRNCOMISION_descripcion_4: comision.TRNCOMISION_DESCRIPCION,
        p_TRNCOMISION_nro_1: comision.TRNCOMISION_NRO,
        p_TRNCOMISION_base_5: 0,
        p_TRNCOMISION_porcentaje_6: 0,
        p_TRNCOMISION_totalretenido_7: Number(comision.TRNCOMISION_TOTALRETENIDO),
        p_TRNCOBRO_nroDoc_8: nroDoc,
        p_COM_codigo_3: '01',
        p_trncomision_origen: comision.TRNCOMISION_ORIGEN,
        p_con_codigo: comision.CON_CODIGO,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
        p_CLI_CODIGO: comision.CLI_CODIGO,
        p_trncomision_flag: comision.TRNCOMISION_FLAG
      },
    }, this.confIniciales.httpOptions());
  }

  insertarComisionProm(comision: Cxctrnotrosg, nroDoc, nroComp): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/257e3b7d662d2e0000', {
      elementos: {
        p_COMISION_codigo_1: comision.COMISION_CODIGO,
        p_TRNCOMISION_descripcion_4: comision.TRNCOMISION_DESCRIPCION,
        p_TRNCOMISION_nro_1: comision.TRNCOMISION_NRO,
        p_TRNCOMISION_base_5: 0,
        p_TRNCOMISION_porcentaje_6: 0,
        p_TRNCOMISION_totalretenido_7: Number(comision.TRNCOMISION_TOTALRETENIDO),
        p_TRNCOBRO_nroDoc_8: nroDoc,
        p_COM_codigo_3: '01',
        p_trncomision_origen: comision.TRNCOMISION_ORIGEN,
        p_con_codigo: comision.CON_CODIGO,
        p_TRNCOBRO_NROCOMPROBANTE: nroComp,
        p_CLI_CODIGO: comision.CLI_CODIGO,
        p_trncomision_flag: comision.TRNCOMISION_FLAG
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarFormadePago(pago: Cxctrnformapago, nroDoc, nroComp): Observable<any[]> {
    if (pago.CAJ_CODIGO === '' || pago.CAJ_CODIGO === null) {
      pago.CAJ_CODIGO = pago.BANCLI_CODIGO;
    }
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/12bf1db7e376cb000', {
      elementos: {
        p_TRNPAGO_tipo_2: pago.TRNPAGO_TIPO,
        p_CAJ_codigo_3: pago.CAJ_CODIGO,
        p_TRNPAGO_numero_4: pago.TRNPAGO_NUMERO,
        p_TRNPAGO_fecha_5: pago.TRNPAGO_FECHA,
        p_BANCLI_CODIGO: pago.BANCLI_CODIGO,
        p_TRNPAGO_NROCTA: pago.TRNPAGO_NROCTA,
        p_TRNPAGO_concepto_6: pago.TRNPAGO_CONCEPTO,
        p_TRNPAGO_importe_7: Number(pago.TRNPAGO_IMPORTE),
        p_TRNPAGO_numero_anterior: '',
        p_CON_codigo_9: pago.CON_CODIGO,
        p_TRNCOBRO_nroDoc_1: nroDoc,
        p_COM_codigo_8: '01',
        p_TRNCOBRO_nroComprobante: nroComp,
        p_CLI_CODIGO: pago.CLI_CODIGO,
        p_TRNPAGO_CODSRI: pago.TRNPAGO_CODSRI,
        p_TRNCHEPOS_NUMDOC: pago.TRNCHEPOS_NUMDOC,
        p_TRNPAGO_TIPDIS: pago.TRNPAGO_TIPDIS
      },
    }, this.confIniciales.httpOptions());
  }

  insertarFormadePagoProm(pago: Cxctrnformapago, nroDoc, nroComp): Promise<any[]> {
    if (pago.CAJ_CODIGO === '' || pago.CAJ_CODIGO === null) {
      pago.CAJ_CODIGO = pago.BANCLI_CODIGO;
    }
    console.log('NumComprobante' + nroComp);
    console.log(pago);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/12bf1db7e376cb000', {
      elementos: {
        p_TRNPAGO_tipo_2: pago.TRNPAGO_TIPO,
        p_CAJ_codigo_3: pago.CAJ_CODIGO,
        p_TRNPAGO_numero_4: pago.TRNPAGO_NUMERO,
        p_TRNPAGO_fecha_5: pago.TRNPAGO_FECHA,
        p_BANCLI_CODIGO: pago.BANCLI_CODIGO,
        p_TRNPAGO_NROCTA: pago.TRNPAGO_NROCTA,
        p_TRNPAGO_concepto_6: pago.TRNPAGO_CONCEPTO,
        p_TRNPAGO_importe_7: Number(pago.TRNPAGO_IMPORTE),
        p_TRNPAGO_numero_anterior: '',
        p_CON_codigo_9: pago.CON_CODIGO,
        p_TRNCOBRO_nroDoc_1: nroDoc,
        p_COM_codigo_8: '01',
        p_TRNCOBRO_nroComprobante: nroComp,
        p_CLI_CODIGO: pago.CLI_CODIGO,
        p_TRNPAGO_CODSRI: pago.TRNPAGO_CODSRI,
        p_TRNCHEPOS_NUMDOC: pago.TRNCHEPOS_NUMDOC,
        p_TRNPAGO_TIPDIS: pago.TRNPAGO_TIPDIS
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarBanco(banco: Cxctrnformapagodev, nroDoc, origen): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/4afc76fab55d98', {
      elementos: {
        p_BAN_codigo_1: banco.BAN_CODIGO,
        p_TRNBAN_fecha_2: banco.TRNCAJ_FECHA,
        p_TRNBAN_tipo_3: banco.TRNCAJ_TIPO,
        p_TRNBAN_numero_4: banco.TRNCAJ_NUMERO,
        p_TRNBAN_docnro_5: nroDoc,
        p_TRNBAN_importe_6: Number(banco.TRNCAJ_IMPORTE) * (-1),
        p_TRNBAN_concepto_7: banco.TRNCAJ_DESCRIPCION,
        p_TRNBAN_beneficiario_9: banco.CLI_CODIGO,
        p_TRNBAN_anulado_10: '0',
        p_CON_codigo_11: banco.CON_CODIGO,
        p_COM_codigo_12: '01',
        p_TRNBAN_origen_13: origen,
        p_TRNBAN_NUMEROREFUSER: '',
        p_TRNBAN_FECHAVENCE: banco.TRNCAJ_FECHA,
        p_ENCREQ_NUMERO: '',
        P_PRO_CODIGO: '',
        p_TRNBAN_PAGOELEC: '',
      },
    }, this.confIniciales.httpOptions());
  }

  insertarCaja(caja: Cxctrnformapagodev, nroDoc, origen): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/4afc76faa62a68', {
      elementos: {
        p_CAJ_codigo_1: caja.CAJ_CODIGO,
        p_TRNCAJ_fecha_2: caja.TRNCAJ_FECHA,
        p_TRNCAJ_tipo_3: caja.TRNCAJ_TIPO,
        p_TRNCAJ_numero_4: caja.TRNCAJ_NUMERO,
        p_TRNCAJ_formapago_5: caja.TRNCAJ_TIPO,
        p_TRNCAJ_docnro_6: nroDoc,
        p_TRNCAJ_importe_7: Number(caja.TRNCAJ_IMPORTE) * (-1),
        p_TRNCAJ_descripcion_8: caja.TRNCAJ_DESCRIPCION,
        p_TRNCAJ_anulado: '0',
        p_TRNCAJ_fechavenci_9: '',
        p_TRNCAJ_origen_10: origen,
        p_COM_codigo_11: '01',
        p_BANCLI_codigo_12: caja.BAN_CODIGO,
        p_USU_IDENTIFICACION: this.usuario.identificacion,
        p_CLI_codigo: caja.CLI_CODIGO,
        p_cen_codigo: '',
      },
    }, this.confIniciales.httpOptions());
  }

  insertarCajaFP(caja: Cxctrnformapago, nroDoc, origen): Observable<any[]> {
    console.log(caja, nroDoc, origen);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/4afc76faa62a68', {
      elementos: {
        p_CAJ_codigo_1: caja.CAJ_CODIGO,
        p_TRNCAJ_fecha_2: caja.TRNPAGO_FECHA,
        p_TRNCAJ_tipo_3: caja.TRNPAGO_TIPO,
        p_TRNCAJ_numero_4: caja.TRNPAGO_NUMERO,
        p_TRNCAJ_formapago_5: caja.TRNPAGO_TIPO,
        p_TRNCAJ_docnro_6: nroDoc,
        p_TRNCAJ_importe_7: Number(caja.TRNPAGO_IMPORTE),
        p_TRNCAJ_descripcion_8: caja.TRNPAGO_CONCEPTO,
        p_TRNCAJ_anulado: '0',
        p_TRNCAJ_fechavenci_9: '',
        p_TRNCAJ_origen_10: origen,
        p_COM_codigo_11: '01',
        p_BANCLI_codigo_12: caja.BANCLI_CODIGO,
        p_USU_IDENTIFICACION: this.usuario.identificacion,
        p_CLI_codigo: caja.CLI_CODIGO,
        p_cen_codigo: '',
      },
    }, this.confIniciales.httpOptions());
  }

  insertarBancoFP(banco: Cxctrnformapago, nroDoc, origen): Observable<any[]> {
    console.log(banco);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/4afc76fab55d98', {
      elementos: {
        p_BAN_codigo_1: banco.BANCLI_CODIGO,
        p_TRNBAN_fecha_2: banco.TRNPAGO_FECHA,
        p_TRNBAN_tipo_3: banco.TRNPAGO_TIPO,
        p_TRNBAN_numero_4: banco.CAJ_CODIGO,
        p_TRNBAN_docnro_5: nroDoc,
        p_TRNBAN_importe_6: Number(banco.TRNPAGO_IMPORTE),
        p_TRNBAN_concepto_7: banco.TRNPAGO_CONCEPTO,
        p_TRNBAN_beneficiario_9: banco.CLI_CODIGO,
        p_TRNBAN_anulado_10: '0',
        p_CON_codigo_11: banco.CON_CODIGO,
        p_COM_codigo_12: '01',
        p_TRNBAN_origen_13: origen,
        p_TRNBAN_NUMEROREFUSER: '',
        p_TRNBAN_FECHAVENCE: banco.TRNPAGO_FECHA,
        p_ENCREQ_NUMERO: '',
        P_PRO_CODIGO: '',
        p_TRNBAN_PAGOELEC: '',
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarCheque(cheque: string, banCod): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/carteraactiva/a677caac99176800', {
      elementos: {
        BAN_ULTIMOCHEQUE: cheque,
        BAN_CODIGO: banCod
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnCobro(docNro): Observable<any> {
    console.log(docNro);
    return this.http.post<any>(this.way + '/cxc/carteraactiva/eacad2eeeecc3000', {
      elementos: {
        TRNCOBRO_NRODOC: docNro,
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnCobroProm(docNro) {    
    return this.http.post<any>(this.way + '/cxc/carteraactiva/eacad2eeeecc3000', {
      elementos: {
        p_trncobro_nrodoc: docNro,
        p_com_codigo:'01',
        p_usu_identificacion:this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarNDATrnResumen(nroDoc): Observable<any[]> {
    console.log('eliminando nda', nroDoc);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1d595daaeeefb70000', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarAntAsoc(nroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/7565655faae6180', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
 //CDPJ
 eliminarMovBan (docNro) {    
  return this.http.post<any>(this.way + '/cxc/carteraactiva/1d595a5756ed9000000000000000', {
    elementos: {
      p_TRNCOBRO_NRODOC: docNro,
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
ernumcheque(codban,tipo,num): Promise<any> {
  return this.http.post(this.way + '/cxc/carteraactiva/20eeb31ebf7bbc0000', {
    elementos: {
      bancod:codban,
      tipodoc:tipo,
      numero:num
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
  eliminarCaja(docNro): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/carteraactiva/3ab2b4aea62a6a', {
      elementos: {
        TRNCAJ_DOCNRO: docNro,
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarBanco(docNro): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/carteraactiva/3ab2b4aea5aaec', {
      elementos: {
        TRNBAN_DOCNRO: docNro,
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarDPBanco(pagoNumero): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/carteraactiva/756569372b55d80', {
      elementos: {
        TRNBAN_NUMERO: pagoNumero,
        TRNBAN_TIPO: 'DP'
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarDPBancoProm(pagoNumero) {
    return this.http.post<any>(this.way + '/cxc/carteraactiva/756569372b55d80', {
      elementos: {
        TRNBAN_NUMERO: pagoNumero,
        TRNBAN_TIPO: 'DP'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarPagadoCuota(doc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/299df655067b1c', {
      elementos: {
        p_ENCFAC_NUMERO: doc.TRNCOBRO_NRODOC,
        p_CLI_CODIGO: doc.CLI_CODIGO,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarestadoFac(doc): Observable<any[]> {
    console.log(doc);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/299df73ad7a99c', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: doc.CLI_CODIGO,
        p_encfac_numero: doc.TRNCOBRO_NRODOC,
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarestadoFacProm(doc) {
    console.log(doc);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/299df73ad7a99c', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: doc.CLI_CODIGO,
        p_encfac_numero: doc.TRNCOBRO_NRODOC,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  anularAsiento(asi): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/2afeaab9275fb8', {
      elementos: {
        p_ASI_nro: asi,
        p_COM_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarAsiento(asi): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/756565724cc5fc0', {
      elementos: {
        p_ASI_nro_1: asi,
        p_COM_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  reenviarCorreo(docNro): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/carteraactiva/6dcebfd98dedd8', {
      elementos: {
        TRNDOC_NUMDOC: docNro,
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarAsiento(cobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/299df2b9275fb8', {
      elementos: {
        p_nro_doc: cobro.TRNCOBRO_NRODOC,
        p_asi_nro: cobro.ASI_NRO,
        p_asi_origen: 'CXC',
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  getAsiento(codigo: string): Observable<any> {
    return this.http.post<any>(this.way + '/cxc/carteraactiva/617d5724cc5fa8', {
      elementos: {
        TRNCOBRO_NRODOC: codigo
      }
    }, this.confIniciales.httpOptions());
  }

  getAsientoProm(codigo: string) {
    return this.http.post<any>(this.way + '/cxc/carteraactiva/617d5724cc5fa8', {
      elementos: {
        TRNCOBRO_NRODOC: codigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarCobro(cobro: Cxctrncobro, tipoDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cef94cc2f78', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarCobroProm(cobro: Cxctrncobro, tipoDoc) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cef94cc2f78', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarNDBancario(cobro: Cxctrncobro, tipoDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/20ebddab55d8a', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_TIPODOC: tipoDoc,
        p_dtpTRNCOBRO_fechatrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_BAN_codigo: cobro.BAN_CODIGO,
        p_CON_codigo_BAN: cobro.CON_CODIGO,
        p_ORIGEN: 'CXC',
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_USUIDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_COM_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  generarAsientoND(cobro: Cxctrncobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/20ebab9275eed', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_trncobro_nrocomprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_cli_codigo: cobro.CLI_CODIGO,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ANDC',
      },
    }, this.confIniciales.httpOptions());
  }
//CDPJ
insertarMovbancos(varios,strFechaEmision,cli_nombre,origen){
  return this.http.post<any[]>(this.way + '/cxc/carteraactiva/63257e3b7d56ec00000000000', {
    elementos: {
      p_TRNCOBRO_NRODOC: varios,
      p_COM_CODIGO: '01',
      p_trnban_fecha:strFechaEmision,
      p_trnban_beneficiario:cli_nombre,
      p_trnban_origen:origen
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
buscarmovibanconciliados(varios: Cxctrnvarios): Observable<any[]> {
  return this.http.post<any[]>(this.way + '/cxc/carteraactiva/18c5fb8c56ed900000000000000000000', {
    elementos: {
      p_TRNCOBRO_NRODOC: varios.TRNCOBRO_NRODOC,
      p_COM_CODIGO: '01'
    },
  }, this.confIniciales.httpOptions());
}
//CDPJ
  generarAsientoNDBan(cobro: Cxctrncobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/83aeae49d76ad80', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_TIPODOC: 'ND',
        p_CLI_CODIGO: cobro.CLI_CODIGO,
        p_tipdia_codigo: 'NDB',
        p_USUIDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_COM_codigo_2: '01'
      },
    }, this.confIniciales.httpOptions());
  }

  generarAsientoNC(cobro: Cxctrncobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/41d75724ebf6ec', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_trncobro_nrocomprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_cli_codigo: cobro.CLI_CODIGO,
        p_com_codigo: '01',
        p_tipdia_codigo: 'ANCC',
      },
    }, this.confIniciales.httpOptions());
  }

  generarAsientoRT(cobro: Cxctrncobro): Observable<any[]> {
    console.log(cobro.TRNCOBRO_NRODOC, cobro.TRNCOBRO_NROCOMPROBANTE);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/41d75724ebf77c', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'ARTC',
        p_asi_comprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
      },
    }, this.confIniciales.httpOptions());
  }

  generarAsientoRTProm(cobro: Cxctrncobro): Promise<any[]> {
    console.log(cobro.TRNCOBRO_NRODOC, cobro.TRNCOBRO_NROCOMPROBANTE);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/41d75724ebf77c', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'ARTC',
        p_asi_comprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsientoCI(cobro: Cxctrncobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/41d75724ebf590', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'CI',
        p_asi_comprobante: cobro.TRNCOBRO_NROCOMPROBANTE.toString().trim(),
      },
    }, this.confIniciales.httpOptions());
  }

  generarAsientoCIProm(cobro: Cxctrncobro) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/41d75724ebf590', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'CI',
        p_asi_comprobante: cobro.TRNCOBRO_NROCOMPROBANTE.toString().trim(),
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsientoDAN(cobro: Cxctrncobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/41d75724ebb558', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_cli_codigo: cobro.CLI_CODIGO,
        p_com_codigo: '01',
        p_origen: cobro.APLORG_CODIGO,
        p_tipdia_codigo: 'CE',
        p_usuario: cobro.USU_IDENTIFICACION,
      },
    }, this.confIniciales.httpOptions());
  }

  generarAsientoANC(cobro: Cxctrncobro): Observable<any[]> {
    console.log(cobro);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/41d75724ebaaec', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_cli_codigo: cobro.CLI_CODIGO,
        p_com_codigo: '01',
        p_origen: cobro.APLORG_CODIGO,
        p_tipdia_codigo: 'CI',
        p_usuario: this.usuario.identificacion,
      },
    }, this.confIniciales.httpOptions());
  }

  generarAsientoANCProm(cobro: Cxctrncobro) {
    console.log(cobro);
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/41d75724ebaaec', {
      elementos: {
        p_trncobro_nrodoc: cobro.TRNCOBRO_NRODOC,
        p_cli_codigo: cobro.CLI_CODIGO,
        p_com_codigo: '01',
        p_origen: cobro.APLORG_CODIGO,
        p_tipdia_codigo: 'CI',
        p_usuario: this.usuario.identificacion,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerClave(accion): Observable<any> {
    if (accion === 'activarXML') {
      return this.http.post<any>(this.way + '/cxc/carteraactiva/30beb2aafa99d', {
        elementos: {},
      }, this.confIniciales.httpOptions());
    } else {
      return this.http.post<any>(this.way + '/cxc/carteraactiva/c2facaabeaf7600', {
        elementos: {},
      }, this.confIniciales.httpOptions());
    }

  }

  insertarTrnBanDoc(banco: Cxctrndebitobancario, cobro: Cxctrncobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/95f9dddd6abb700', {
      elementos: {
        p_TRNPAGO_FECHA: banco.TRNPAGO_FECHA,
        p_TRNPAGO_TIPO: banco.TRNPAGO_TIPO,
        p_TRNPAGO_NUMERO: banco.TRNPAGO_NUMERO,
        p_TRNPAGO_IMPORTE: Number(banco.TRNPAGO_IMPORTE),
        p_TRNPAGO_NROCTA: banco.TRNPAGO_NROCTA,
        p_BANCLI_CODIGO: banco.BANCLI_CODIGO,
        p_TRNCOBRO_NRODOC: banco.TRNCOBRO_NRODOC,
        p_CLI_CODIGO: cobro.CLI_CODIGO,
        p_TRNBANDOC_COD: cobro.TRNCOBRO_NRODOC,
        p_TRNBAN_NUMERO: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNBAN_TIPO: cobro.TRNCOBRO_TIPODOC,
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_COM_CODIGO: '01',
        p_TRNBANDOC_ORIGEN: 'CXC',
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_FECHATRN: cobro.TRNCOBRO_FECHATRN
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarVarios(cobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/7565695feadcb00', {
      elementos: {
        p_TRNCOBRO_NRODOC: cobro.TRNCOBRO_NRODOC,
        p_CLI_CODIGO: cobro.CLI_CODIGO,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  insertarVarios(varios: Cxctrnvarios): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/95f8edf7eadcb00', {
      elementos: {
        p_TRNCOBRO_NRODOC: varios.TRNCOBRO_NRODOC,
        p_CLI_CODIGO: varios.CLI_CODIGO,
        p_COM_CODIGO: '01',
        p_TRNVARIOS_LINEA: 0,
        p_CON_CODIGO: varios.CON_CODIGO,
        p_TRNVARIOS_IMPORTE: Number(varios.TRNVARIOS_IMPORTE),
        p_ban_codigo: varios.BAN_CODIGO,
        p_trnban_tipo: varios.TRNBAN_TIPO,
        p_trnban_numero: varios.TRNBAN_NUMERO,
        p_TRNCOBRO_NROCOMPROBANTE: varios.TRNCOBRO_NROCOMPROBANTE,
        p_CON_NOMBRE: varios.CON_NOMBRE,
        p_cen_codigo:varios.CEN_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  firmarDoc(cartera: Cxctrncobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/f96ecadb70c', {
      elementos: {
        p_trndoc_numdoc: cartera.TRNCOBRO_NRODOC,
        p_trndoc_tipo: cartera.TRNCOBRO_TIPODOC,
        p_trndoc_estado: 'VA',
        p_trndoc_resultado: '',
        p_trndoc_ubicaarch: '',
        p_trndoc_secuencial: '',
        p_usuidentificacion: this.usuario.identificacion,
        p_com_codigo: '01',
        p_trndoc_tipoamb_fe: '1',
      },
    }, this.confIniciales.httpOptions());
  }

  cargarDatosDoc(nroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/315b8355dc370c', {
      elementos: {
        trncobro_nrodoc: nroDoc
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnDocumento(strCondicion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/75656eeeedc33c0', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnDocumentoProm(strCondicion) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/75656eeeedc33c0', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTrnRetencion(strCondicion): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/75656eeefb775c0', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnRetencionProm(strCondicion): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/75656eeefb775c0', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTrnComision(numDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/75656eeeecc5a40', {
      elementos: {
        p_TRNCOBRO_nroDoc_4: numDoc,
        p_COM_codigo_3: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarFormaPagoProm(numdoc) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/756567e37656540', {
      elementos: {
        TRNCOBRO_NRODOC: numdoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarFormaPago(numdoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/756567e37656540', {
      elementos: {
        TRNCOBRO_NRODOC: numdoc
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerDocumentos(opcion, numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/c2fadc33d675f80', {
      elementos: {
        p_opcion: opcion,
        p_filtro: 'TRNCOBRO_NRODOC = \'' + numeroDoc + '\'',
        p_condicion: 'COM_CODIGO = \'01\''
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerDocumentosProm(opcion, numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/c2fadc33d675f80', {
      elementos: {
        p_opcion: opcion,
        p_filtro: 'TRNCOBRO_NRODOC = \'' + numeroDoc + '\'',
        p_condicion: 'COM_CODIGO = \'01\''
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerRetenciones(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/185f76eebaec96000', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerRetencionesProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/185f76eebaec96000', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerOtrosGastos(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/c2fb8eee0ae7700', {
      elementos: {
        p_opcion: 'DR',
        p_filtro: 'TRNCOBRO_NRODOC = \'' + numeroDoc + '\'',
        p_condicion: 'COM_CODIGO = \'01\''
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerOtrosGastosProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/c2fb8eee0ae7700', {
      elementos: {
        p_opcion: 'DR',
        p_filtro: 'TRNCOBRO_NRODOC = \'' + numeroDoc + '\'',
        p_condicion: 'COM_CODIGO = \'01\''
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerFormadePago(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617d7e376caa18', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerFormadePagoProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617d7e376caa18', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerVarios(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617d75feadcb1c', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerVariosProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617d75feadcb1c', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerDebitoBancario(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617d75dae5aaec', {
      elementos: {
        p_TRNBANDOC_COD: numeroDoc,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerDebitoBancarioProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617d75dae5aaec', {
      elementos: {
        p_TRNBANDOC_COD: numeroDoc,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerAnticipos(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/c2faebaafd93280', {
      elementos: {
        TRNPAGO_NUMERO: numeroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerNombreBan(banCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617d75f165aaec', {
      elementos: {
        BAN_CODIGO: banCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerNombreBanProm(banCodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617d75f165aaec', {
      elementos: {
        BAN_CODIGO: banCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerFormadePagoDev(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/c2fae7e376cb600', {
      elementos: {
        TRNCAJ_DOCNRO: numeroDoc
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerFormadePagoDevProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/c2fae7e376cb600', {
      elementos: {
        TRNCAJ_DOCNRO: numeroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerFormadePagoDevBanco(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/185f5fbb65aefb000', {
      elementos: {
        TRNBAN_DOCNRO: numeroDoc
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerFormadePagoDevBancoProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/185f5fbb65aefb000', {
      elementos: {
        TRNBAN_DOCNRO: numeroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerTipoRet(numeroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/30bef659c6ddd', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerTipoRetProm(numeroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/30bef659c6ddd', {
      elementos: {
        TRNCOBRO_NRODOC: numeroDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerSaldoCliente(cliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617de2aadc32b0', {
      elementos: {
        CLI_CODIGO: cliCodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerSaldoClienteP(cliCodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617de2aadc32b0', {
      elementos: {
        CLI_CODIGO: cliCodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerChequesCli(cliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/c2fac8e99593b00', {
      elementos: {
        p_CLI_CODIGO: cliCodigo,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerNumeracion(tipodoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/30bebafeb3b6a60000', {
      elementos: {
        NUM_DOCREF: tipodoc,
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarNumeracion(serie): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cefafeb3b6a60000', {
      elementos: {
        p_NUM_clase: '',
        p_COM_codigo: '01',
        p_NUM_docref: 'CI',
        p_NUM_nemonico: '',
        p_NUM_serie: serie
      },
    }, this.confIniciales.httpOptions());
  }

  anularDocumento(cobroNroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/2afeaab6dc33d6', {
      elementos: {
        TRNCOBRO_NRODOC: cobroNroDoc,
      }
    }, this.confIniciales.httpOptions());
  }

  anularAsientoDoc(asiNro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/55fd55724ebb700', {
      elementos: {
        asi_nro: asiNro,
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerLogFE(docNum: string, tipoFac: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/30bebaf5c41ee', {
      elementos: {
        TRNDOC_NUMDOC: docNum,
        TRNDOC_TIPO: tipoFac
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarDocAnulado(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3ab2b370c55fd4', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarDocAnuladoEnc(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/eac9b862afeab800', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarPagoAnulado(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/eacb2a86157f5800', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarRetencionAnulado(documento): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/7565b775d762b00', {
      elementos: {
        trncobro_nrodoc: documento.TRNCOBRO_NRODOC,
        cli_codigo: documento.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  existePagoDoc(nroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1dc66550c370c', {
      elementos: {
        TRNDOCUMENTO_NRODOC: nroDoc,
      }
    }, this.confIniciales.httpOptions());
  }

  getAnexos(nroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/185f6eedaaee', {
      elementos: {
        trncobro_nrodoc: nroDoc,
      }
    }, this.confIniciales.httpOptions());
  }

  getAnexosProm(nroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/185f6eedaaee', {
      elementos: {
        trncobro_nrodoc: nroDoc,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerDatosAnexos(nroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/30beddaaeaaee', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerDatosAnexosProm(nroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/30beddaaeaaee', {
      elementos: {
        p_trncobro_nrodoc: nroDoc,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarDatosAnexos(anexo: Cxctrnanexos): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/43cadb55dc2af0', {
      elementos: {
        p_trncobro_nrodoc: anexo.TRNCOBRO_NRODOC,
        p_com_codigo: '01',
        p_trnanex_idcretri: anexo.TRNANEX_IDCRETRI,
        p_trnanex_tipcom: anexo.TRNANEX_TIPCOM,
        p_trnanex_autorizacion: anexo.TRNANEX_AUTORIZACION,
        p_trnanex_baseimptarcero: anexo.TRNANEX_BASEIMPTARCERO,
        p_trnanex_baseimpgrabadaiva: anexo.TRNANEX_BASEIMPGRABADAIVA,
        p_trnanex_montoiva: anexo.TRNANEX_MONTOIVA,
        p_trnanex_baseimpnoobjetoiva: anexo.TRNANEX_BASEIMPNOOBJETOIVA,
        p_trnanex_montoice: anexo.TRNANEX_MONTOICE,
        p_trnanex_montoretivabie: anexo.TRNANEX_MONTORETIVABIE,
        p_trnanex_montoretivaser: anexo.TRNANEX_MONTORETIVASER,
        p_trnanex_retvacien: anexo.TRNANEX_RETVACIEN,
        p_usu_identificacion: this.usuario.identificacion,
        p_trnanex_autdocmod: anexo.TRNANEX_AUTDOCMOD,
        p_trnanex_secdocmod: anexo.TRNANEX_SECDOCMOD,
        p_trnanex_seriedocmod: anexo.TRNANEX_SERIEDOCMOD,
        p_trnanex_tiposritarcero: anexo.TRNANEX_TIPOSRITARCERO,
        p_trnanex_tiposrigravada: anexo.TRNANEX_TIPOSRIGRAVADA,
        p_trnanex_tipcommod: anexo.TRNANEX_TIPCOMMOD,
      },
    }, this.confIniciales.httpOptions());
  }

  verificarComprobanteUnico(parametro, numero, numeroFac): Promise<any[]> {
    if (parametro === 'N') {
      return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1f76e4f662d9f6000', {
        elementos: {
          p_operacion: parametro,
          p_nrocomprobante: numero,
          p_com_codigo: '01',
          p_trncobro_nrodoc: ''
        },
      }, this.confIniciales.httpOptions()).toPromise();
    } else if (parametro === 'M') {
      return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1f76e4f662d9f6000', {
        elementos: {
          p_operacion: parametro,
          p_nrocomprobante: numero,
          p_com_codigo: '01',
          p_trncobro_nrodoc: numeroFac
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
  }

  calcularTotalDoc() {
    this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice] = 0;
    if (this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_TIPODOC !== 'NC') {
      if (this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].length !== 0) {
        for (let fila = 1; fila <= this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
          const item = this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
          if (item.SELECCIONADO === true && this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_NRODOC === '') {
            if (Number(item.TRNCOBRO_IMPORTE) > Number(item.TRNCOBRO_SALDOINI)) {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Información',
                detail: 'El importe no puede ser mayor que el saldo'
              });
              item.TRNCOBRO_IMPORTE = item.TRNCOBRO_SALDOINI;
              item.TRNCOBRO_IMPORTE = Number(item.TRNCOBRO_IMPORTE).toFixed(2);
            } else {
              item.TRNCOBRO_IMPORTE = Number(item.TRNCOBRO_IMPORTE).toFixed(2);
            }
          } else if (item.SELECCIONADO === false) {
            item.TRNCOBRO_IMPORTE = 0;
          }
          this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice] += Number(item.TRNCOBRO_IMPORTE);
          this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALDOC = this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
          this.calcularTotalCobrar();
        }
      } else {
        if (this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].SALDOANC === undefined) {
          this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].SALDOANC = 0;
        }
        this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice] += this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].SALDOANC;
        this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALDOC = Number(this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice]).toFixed(2);
        this.calcularTotalCobrar();
      }
    } else {
      this.calcularTotalNC();
    }
  }

  calcularTotalNC() {
    this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice] = 0;
    for (let fila = 1; fila <= this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const item = this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
      if (item.SELECCIONADO === true) {
        this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice] += Number(item.TRNCOBRO_IMPORTE);
      } else {
        item.TRNCOBRO_IMPORTE = 0;
      }
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALNC = this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
    
  }

  calcularTotalFormaPago() {
    this.dblTotalFormaPagoarray[this.init.tabs[this.init.tabindex].indice] = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALFORMAPAGO = 0;
    for (let fila = 1; fila <= this.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      if (this.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNPAGO_TIPO.length > 0) {
        this.dblTotalFormaPagoarray[this.init.tabs[this.init.tabindex].indice] += Number(this.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNPAGO_IMPORTE);
        this.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNPAGO_IMPORTE = Number(this.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNPAGO_IMPORTE).toFixed(2);
      }
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALFORMAPAGO = Number(this.dblTotalFormaPagoarray[this.init.tabs[this.init.tabindex].indice]).toFixed(2);
  }

  calcularTotalFormaPagoDev() {
    this.dblTotalFormaDevarray[this.init.tabs[this.init.tabindex].indice] = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALFORMAPAGO = 0;
    for (let fila = 1; fila <= this.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      if (this.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNCAJ_TIPO.length > 0) {
        this.dblTotalFormaDevarray[this.init.tabs[this.init.tabindex].indice] += Math.abs(this.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNCAJ_IMPORTE);
        this.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNCAJ_IMPORTE = Number(this.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][fila - 1].TRNCAJ_IMPORTE).toFixed(2);
      }
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALFORMAPAGO = this.dblTotalFormaDevarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
  }

  calcularTotalOtrosG() {
    this.dblTotalOtrosGarray[this.init.tabs[this.init.tabindex].indice] = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALOTROSG = 0;
    for (let fila = 1; fila <= this.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const item = this.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
      this.dblTotalOtrosGarray[this.init.tabs[this.init.tabindex].indice] += Number(item.TRNCOMISION_TOTALRETENIDO);
      item.TRNCOMISION_TOTALRETENIDO = Number(item.TRNCOMISION_TOTALRETENIDO).toFixed(2);
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALOTROSG = this.dblTotalOtrosGarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
    this.calcularTotalCobrar();
  }

  calcularTotalND() {
    this.dblTotalNDarray[this.init.tabs[this.init.tabindex].indice] = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALND = 0;
    if (this.cxcdebitobancarioarray[this.init.tabs[this.init.tabindex].indice].length === 0) {
      this.dblTotalNDarray[this.init.tabs[this.init.tabindex].indice] = Number(this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_IMPORTE);
    } else {
      for (let fila = 1; fila <= this.cxcdebitobancarioarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
        const item = this.cxcdebitobancarioarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
        if (item.SELECCIONADO === true) {
          this.dblTotalNDarray[this.init.tabs[this.init.tabindex].indice] += Number(item.TRNPAGO_IMPORTE);
          item.TRNPAGO_IMPORTE = Number(item.TRNPAGO_IMPORTE).toFixed(2);
        }
      }
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALND = this.dblTotalNDarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_IMPORTE = this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALND;
  }

  calcularTotalCobrar() {
    this.dblTotalCobrararray[this.init.tabs[this.init.tabindex].indice] = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALACOBRAR = 0;
    this.dblTotalCobrararray[this.init.tabs[this.init.tabindex].indice] = Number(this.dblTotalDocarray[this.init.tabs[this.init.tabindex].indice]) - Number(this.dblTotalNCarray[this.init.tabs[this.init.tabindex].indice]) - Number(this.dblTotalOtrosGarray[this.init.tabs[this.init.tabindex].indice]) - Number(this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice]);
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALACOBRAR = this.dblTotalCobrararray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
  }

  calcularTotalRetenido(intFilaActual) {
    let dblTotalFila = 0;
    let dblBase = 0;
    let dblPorcentaje = 0;
    this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice] = 0;
    // let dblTotalRetFac = 0;
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALRET = 0;
    console.log(this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
    for (let fila = 1; fila <= this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const itemR = this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
      if (itemR.TRNRETENCION_NRO !== '') {
        if (itemR.TRNRETENCION_NRO.length !== 0) {
          if (itemR.TRNRETENCION_NRO.length < 9 && itemR.TRNRETENCION_NRO.length !== 0) {
            do {
              itemR.TRNRETENCION_NRO = '0' + itemR.TRNRETENCION_NRO;
            } while (itemR.TRNRETENCION_NRO.length < 9);
          } else if (itemR.TRNRETENCION_NRO.length < 9 && itemR.TRNRETENCION_NRO.length === 0) {
            itemR.TRNRETENCION_NRO = '1';
            do {
              itemR.TRNRETENCION_NRO = '0' + itemR.TRNRETENCION_NRO;
            } while (itemR.TRNRETENCION_NRO.length < 9);
          }
        }
      }
      dblBase = Number(itemR.TRNRETENCION_BASE);
      dblPorcentaje = Number(itemR.TRNRETENCION_PORCENTAJE);
      //dblTotalFila = dblBase * dblPorcentaje / 100;
       //CDPJ
       dblTotalFila = dblBase * (dblPorcentaje/100);
       //CDPJ
       console.log('ret*/*/*/',dblTotalFila)//GSRF
       console.log('ret*/*/*/',this.redondearDecimales(dblTotalFila) )//GSRF
      itemR.TRNRETENCION_BASE = Number(itemR.TRNRETENCION_BASE).toFixed(2);
      itemR.TRNRETENCION_TOTALRETENIDO = this.redondearDecimales(dblTotalFila);//GSRF
      this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice] += Number(itemR.TRNRETENCION_TOTALRETENIDO);
    }
    this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALRET = this.dblTotalRetarray[this.init.tabs[this.init.tabindex].indice].toFixed(2);
    this.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].map((resu) => {
      if (this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice].length !== 0) {
        if (resu.TRNCOBRO_NRODOC === this.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][0].ENCFAC_NUMERO && this.tipoDocumentoarray[this.init.tabs[this.init.tabindex].indice] === 'RT') {
          resu.TRNCOBRO_IMPORTE = this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TOTALRET;
        }
      }
    });
    this.calcularTotalDoc();
    /*for (const fila = 1; fila <= listaRetFact.size(); fila++ )
    {
      SaciTrnRetencion itemR = listaRetFact.get(fila-1);
    
      dblTotalRetFac= dblTotalRetFac + itemR.getTrnretencionTotalretenido();
    }
  
    dlgCxC.lblTotalRetFac.setText( Validations.numberValidation("" + dblTotalRetFac, 15, dlgCxC.NUMDECRET,
      true, false) );

//		SI ES UNA RETENCION COLOCA EL VALOR TOTAL DE LA RETENCION EN EL IMPORTE DEL DOCUMENTO
    if (this.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_TIPODOC === 'RT') {
      int filaSel = dlgCxC.getTablaDoc().getCellSelection()[0].y;
      if (dlgCxC.modeloDoc.listaDocPorCobrar.size()>0)
      {
        CxcTrnCobro item = dlgCxC.modeloDoc.listaDocPorCobrar.get(filaSel-1);
      
        item.setTrnCobroImporte(Double.parseDouble(dlgCxC.lblTotalRetFac.getText().replace(",", "")));
      
        dlgCxC.modeloDoc.calcularTotalDoc();
      }
    }*/
    this.calcularTotalCobrar();
  }
//GSRF
redondearDecimales(num) {
  let val=0
  val=(Math.round((num + Number.EPSILON) *100)/100);
  val=Number(Number(val).toFixed(2));
  return val;
}
//GSRF
  actualizarNotaDebitoAnticipo(strNumDoc, strNumAnticipo): Observable<any[]> {
    let strSql = 'UPDATE CXC_TRNRESUMEN SET TRNCOBRO_REFERENCIA = \'' + strNumAnticipo + '\'';
    strSql = strSql + ' WHERE TRNCOBRO_NRODOC = \'' + strNumDoc + '\' AND COM_CODIGO = \'01\'';

    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cefaf8ea9ae60000', {
      elementos: {
        NumAnticipo: strNumAnticipo,
        NumDoc: strNumDoc
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarNotaDebitoAnticipoProm(strNumDoc, strNumAnticipo) {
    let strSql = 'UPDATE CXC_TRNRESUMEN SET TRNCOBRO_REFERENCIA = \'' + strNumAnticipo + '\'';
    strSql = strSql + ' WHERE TRNCOBRO_NRODOC = \'' + strNumDoc + '\' AND COM_CODIGO = \'01\'';

    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cefaf8ea9ae60000', {
      elementos: {
        NumAnticipo: strNumAnticipo,
        NumDoc: strNumDoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarFormaPagoND(strNumND, strNumPago, strNumDoc): Observable<any[]> {
    let strSql = 'UPDATE CXC_TRNPAGO SET TRNPAGO_REFER = \'' + strNumND + '\'';
    strSql = strSql + ' WHERE TRNCOBRO_NRODOC = \'' + strNumDoc + '\' AND COM_CODIGO = \'01\'';
    strSql = strSql + ' AND TRNPAGO_TIPO = \'ANCL\' AND TRNPAGO_NUMERO = \'' + strNumPago + '\'';
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cef9f8ddb2a80000', {
      elementos: {
        NumND: strNumND,
        NumDoc: strNumDoc,
        NumPago: strNumPago
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarFormaPagoNDProm(strNumND, strNumPago, strNumDoc) {
    let strSql = 'UPDATE CXC_TRNPAGO SET TRNPAGO_REFER = \'' + strNumND + '\'';
    strSql = strSql + ' WHERE TRNCOBRO_NRODOC = \'' + strNumDoc + '\' AND COM_CODIGO = \'01\'';
    strSql = strSql + ' AND TRNPAGO_TIPO = \'ANCL\' AND TRNPAGO_NUMERO = \'' + strNumPago + '\'';
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/14cef9f8ddb2a80000', {
      elementos: {
        NumND: strNumND,
        NumDoc: strNumDoc,
        NumPago: strNumPago
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  refrescarURL() {
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }

  consultarDevolucionesAnt(strCobroNrodoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/662fcf55aefab00', {
      elementos: {
        TRNCOBRO_NRODOC: strCobroNrodoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  cxc_fnc_verificar_ant_util(strCobroNrodoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/fbb727aafdf7680', {
      elementos: {
        TrnCobro: strCobroNrodoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  existePagoDocProm(strCobroNrodoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/1dc66550c370c', {
      elementos: {
        TRNDOCUMENTO_NRODOC: strCobroNrodoc,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerSaldoANC(strNumFact): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617de2aadc2aec', {
      elementos: {
        NumFact: strNumFact,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenermaecliente(strClicodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/617d7594e65650', {
      elementos: {
        CLI_CODIGO: strClicodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actPagadoDETCUOTA(item): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/a6772a835dd67800', {
      elementos: {
        p_ENCFAC_NUMERO: item.ENCFAC_NUMERO,
        p_CLI_CODIGO: item.CLI_CODIGO,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async obtenerCodSRI(strTipoPago) {
    let strCodSri = '';

    try {
      const er = await this.erFormapagocodsriProm(strTipoPago);
      if (er !== null) {
        if (er[0] !== undefined) {
          strCodSri = er[0].FORMAPAGO_CODIGOSRI === null ? '' : er[0].FORMAPAGO_CODIGOSRI;
        }
      }
    } catch (error) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede obtener el código SRI'
      });
    }
    return strCodSri;
  }

  async controlarChequesPosf(item) {
    let bolControl = false;
    let lngDias = 0;
    let intDiasChequeP = await this.obtenerDiasChPosf(item.CLI_CODIGO);
    const dateActual = new Date();
    let dateHasta: any;

    try {

      const lngDesde = new Date(dateActual.getFullYear(), dateActual.getUTCMonth(), dateActual.getDate()).getTime();

      const arrFechaHasta = String(item.TRNPAGO_FECHA).split('/');
      dateHasta = new Date(Number(arrFechaHasta[2]), Number(arrFechaHasta[1]) - 1, Number(arrFechaHasta[0]));

      const lngHasta = new Date(Number(arrFechaHasta[2]), Number(arrFechaHasta[1]) - 1, Number(arrFechaHasta[0])).getTime();

      const dblDiferencia = lngHasta - lngDesde;
      lngDias = Math.round((dblDiferencia / (1000 * 60 * 60 * 24)));

    } catch (err) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Forma de Pago',
        detail: err.error
      });
    }

    if (lngDias > intDiasChequeP) {
      this.message.add({
        key: 'cartera',
        severity: 'info',
        summary: 'Información',
        detail: 'El Cliente tiene ' + intDiasChequeP + ' día(s) para los cheques posfechados'
      });

      return this.datePipe.transform(dateActual, 'dd/MM/yyyy');
    } else {
      return this.datePipe.transform(dateHasta, 'dd/MM/yyyy');
    }
  }

  async obtenerDiasChPosf(strCodCliente) {
    let intDias = 0;
    let strDias = '';
    try {
      const rs = await this.erClidiachposProm(strCodCliente);

      if (rs !== null) {
        strDias = rs[0].CLI_DIACHPOS;
      }

      if (String(strDias).length > 0) {
        intDias = Number(strDias);
      }

    }
    catch (err) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Forma de Pago',
        detail: 'No se puede obtener el número de días de los cheques posfechados ' + err.error
      });
    }
    console.log(intDias);
    return intDias;
  }

  async encontrarNumDoc(strNumComp) {
    let strNumDoc = '';
    try {
      const rs = await this.erTrncobronrodocProm(strNumComp);
      strNumDoc = rs[0].TRNCOBRO_NRODOC === null ? '' : rs[0].TRNCOBRO_NRODOC;
    } catch (err) {

      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede encontrar el número de documento ' + err.error
      });
    }

    return strNumDoc;
  }

  async obtenerSaldoFactura(strNumFact) {

    let strSql = 'SELECT VEN_FNC_SALDOFACTURA(\'01\', \'' + strNumFact + '\') as SALDO FROM DUAL';
    let dblSaldo = 0;

    try {
      const res = await this.obtenerSaldoANC(strNumFact);
      for (const rs of res) {
        dblSaldo = rs.SALDO;
      }
    } catch (err) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede obtener el saldo de la factura ' + err.error
      });
    }
    return dblSaldo;
  }

  async obtenerCaja(strTipoPago) {
    let strCaja = '';

    try {
      const rs = await this.erCajcodigoProm(strTipoPago);
      console.log(rs);
      strCaja = rs === null ? '' : (rs[0].CAJ_CODIGO === null ? '' : rs[0].CAJ_CODIGO);
    }
    catch (err) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede obtener la caja ' + err.error
      });
    }
    return strCaja;
  }

  async obtenerCuentaCaja(strCaja) {
    let strCuenta = '';

    try {
      const rs = await this.erConcodigoProm(strCaja);
      console.log(rs);
      strCuenta = rs === null ? '' : (rs[0].CON_CODIGO === null ? '' : rs[0].CON_CODIGO);
    } catch (err) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede consultar la cuenta contable de la caja ' + err.error
      });
    }
    return strCuenta;
  }

  async obtenerNombreBanco(strCodBanco) {
    let strNombre = '';

    try {
      const rs = this.erBanclinombreProm(strCodBanco);
      console.log(rs);
      strNombre = rs[0].BANCLI_NOMBRE === null ? '' : rs[0].BANCLI_NOMBRE;
    } catch (err) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede consultar el nombre del banco ' + err.error
      });
    }
    return strNombre;
  }

  leerConfiguracionDatosElectronicos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/2ae76daaee18f80000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  actualizarNumeroComprobante(strAsiNro, strNroDoc) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/533bebfaccc5b40', {
      elementos: {
        ASI_NRO: strAsiNro,
        NRODOC: strNroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTarDescripcion(strcodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/76faadb5dc66e40', {
      elementos: {
        TAR_CODIGO: strcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  asibangettrnpago(strTrnCobroNroDoc): Promise<any[]> {    
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/5724b55e0eef78000000', {
      elementos: {
        strTrnCobroNroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  updateasientobantrnban(strAsiNro, strBanCodigo, strTrnBanTipo, strTrnBanNumero): Promise<any[]> {    
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3d96ab1ae5724ec00000', {
      elementos: {
        strAsiNro, strBanCodigo, strTrnBanTipo, strTrnBanNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  updateasientobantrncaj(strAsiNro, strBanCodigo, strTrnBanTipo, strTrnBanNumero): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/c54fd96abae570000000', {
      elementos: {
        strAsiNro, strBanCodigo, strTrnBanTipo, strTrnBanNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF  
  getcaja(caj_codigo: string){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/u8974dsle3zx4hr', {
        elementos: {
          Caj_codigo: caj_codigo
        },
      },this.confIniciales.httpOptions()).toPromise();
  }

  getDP(TRNPAGO_NUMERO: string,BANCLI_CODIGO: string){
    return this.http.post<any[]>(this.way + '/nom/transotregresos/30bec9d7735d9', {
        elementos: {
          P_TRNBAN_NUMERO: TRNPAGO_NUMERO,
          P_BAN_CODIGO: BANCLI_CODIGO
        },
      },this.confIniciales.httpOptions()).toPromise();
  }
  getDetConencasilProm(strAsiNro, strOrigen) {
    return this.http.post<any[]>(this.way + '/cont/conencasil/83bad77598bbb00', {
      elementos: {
        p_asi_nro: strAsiNro,
        p_com_codigo: '01',
        p_origen: strOrigen,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarCobroDAN(cobro: Cxctrncobro, tipoDoc): Observable<any[]> {
    console.log('+++++',cobro.TRNCOBRO_IMPORTE)
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/95f8edf54cc2f80', {
      elementos: {
        p_TRNCOBRO_nroDoc: cobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: cobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: tipoDoc,
        p_TRNCOBRO_fechaTrn: cobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: cobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: Number(cobro.TRNCOBRO_IMPORTE),
        p_TRNCOBRO_fechavence: cobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: cobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: cobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: cobro.CLI_CODIGO,
        p_VEN_codigo: cobro.VEN_CODIGO,
        p_COM_codigo: '01',
        p_ASI_NRO: cobro.ASI_NRO,
        p_USU_IDENTIFICACION: cobro.USU_IDENTIFICACION,
        p_TRNCOBRO_FLAG: Number(cobro.TRNCOBRO_FLAG),
        p_BAN_CODIGO: cobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: Number(cobro.TRNCOBRO_EFECTIVO),
        p_TRNCOBRO_CAMBIO: Number(cobro.TRNCOBRO_CAMBIO),
        p_CON_CODIGO: cobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: Number(cobro.TRNCOBRO_SALDOINI),
        p_BOD_CODIGO: cobro.BOD_CODIGO,
      },
    }, this.confIniciales.httpOptions());
  }

  
  getcuentconta(cuenta) {
    return this.http.post<any[]>(this.way + '/cxc/carteraactiva/10776ca773bbc300000000', {
      elementos: {
        con_codigo: cuenta
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  deleteimptable(auto) {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/1aeabbae95b3d50000', {
      elementos: {
        autorizacion: auto
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
}
