import { Component, OnInit } from '@angular/core';
import { ActmaeactivoService } from '../actservicios/actmaeactivo.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { Usuario } from 'src/app/usuario';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { DatePipe, JsonPipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { ActTrnTranf } from '../actinterfaces/actrntransf';
import { ActDetKardex } from '../actinterfaces/actdetkardex';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';

@Component({
  selector: 'app-actmaeactivo',
  templateUrl: './actmaeactivo.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ActmaeactivoComponent implements OnInit {

  btnNuevo = false;
  btnGuardar = true;
  btnBorrar = false;
  btnRegresar = false;
  botonPrimero = false;
  botonAnterior = false;
  botonSiguiente = false;
  botonUltimo = false;
  btnBuscar = false;

  // variables
  txtActCod = '';txtActGru = '';lblGruNom = '';txtActNom = '';txtActNomCor = '';
  cmbOrig: any[] = [];strOrigen: any;txtLugOrig = '';txtCiuCod = '';lblCiuNomb = '';
  txtCodOfi = '';lblNomOfi = '';txtCodArea = '';lblNomArea = '';lblNomDep = '';
  txtCodDep = '';cmbProc: any[] = [];strProceso: any;cmbEsta: any[] = [];strEstado: any;
  cmbUso: any[] = [];strTipUsuario: any;txtCodCli1 = '';lblNomCli1 = '';txtCodCli2 = '';
  lblNomCli2 = '';txtCodProp = '';lblNomProp = '';dateFecUso: any = '';txtCodProv = '';
  lblNomProv = '';dateFecFabr: any = '';dateFecComp: any;txtCompComp = '';txtMarc = '';
  txtMode = '';txtNumSeri = '';txtChas = '';txtPlac = '';lblNumPoli = '';txtNumPoli = '';
  txtColo = '';txtCompEgre = '';txtCtaActi = '';lblNomCtaActi = '';txtCodCcAct = '';
  lblNomCcAct = '';txtCodCtaDepr = '';lblNomCtaDepr = '';txtCodCcDep = '';lblNomCcDep = '';
  txtCodCcGas = '';lblNomCcGas = '';txtCome = '';lblNomCtaGas = '';txtCodGas = '';
  txtCostAct = '';txtCosMonOri = '';txtCodMonOri = '';lblNomMonOri = ''; txtCotCamb = '';
  txtVidaUtil = ''; txtValoResi = ''; dateUltiDepr: any = ''; dateFechRegi:any = ''; txtDeprAcum = '';
  txtCodProvMant = ''; lblNomProvMant = ''; txtCodFreMant = '';lblNombFreMant = ''; txtObsMant = '';
  txtTiemGara =''; cmbTipoGara:any[] = []; strTipoGara: any; txtValoGara = ''; dateFecIniGara: any = '';
  dateFecFinGara: any = ''; txtCobeGara = ''; txtExceGara = ''; txtAdicGara = ''; txtObsGara = '';
  btnSellSegu = false; txtTipoMant = '';

  detalleActTrnTransfer: ActTrnTranf[] = [];
  detalleKardex: ActDetKardex[] = [];

  largo: string;
  public frameworkComponents;
  rowSelection = 'multiple';
  rowStyle;
  defaultActTrnTransf;
  defaultActDetKardex;

  // Permisos
  permisos: SegMaePermiso;

  // Busqueda
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  where: string;
  opcion: string;

  selectTab = 0;
  displayDialogDocumentos

  columnDefsActTrnTransf = [
    {
      headerName: 'Fecha', field: 'TRNTRANSF_FECHA', cellEditor: 'cellPrueba', width: 130, editable:false,
    },
    {
      headerName: 'Número', field: 'TRNTRANSF_NUMERO', cellEditor: 'cellPrueba', width: 130, editable:false,
    },
    {
      headerName: 'Referencia', field: 'TRNTRANSF_REFERENCIA', cellEditor: 'cellPrueba', width: 120, editable:false,
    },
    {
      headerName: 'Autorizado', field: 'TRNTRANSF_AUTORIZADO', cellEditor: 'cellPrueba', width: 200, editable:false,
    },
    {
      headerName: 'Comentario', field: 'TRNTRANSF_COMENTARIO', width: 400, cellEditor: 'cellPrueba', editable:false,
    },
    {
      headerName: 'Estado', field: 'TRNTRANSF_ESTADO', cellEditor: 'cellPrueba', width: 120, editable:false
    },
    {
      headerName: 'Origen', field: 'TRNTRANSF_ORIGEN', cellEditor: 'cellPrueba', width: 150, editable:false
    }
  ];

  columnDefsActDetKardex = [
    {
      headerName: 'Referencia', field: 'ENCKARDEX_COMPROBANTE', cellEditor: 'cellPrueba', width: 130, editable:false,
    },
    {
      headerName: 'Fecha', field: 'ENCKARDEX_FECHA', cellEditor: 'cellPrueba', width: 130, editable:false,
    },
    
    {
      headerName: 'Depreciación', field: 'DETKARDEX_IMPORTE', cellEditor: 'cellPrueba', width: 120, editable:false, cellStyle: {textAlign: 'right'}
    },
    {
      headerName: 'Valor Actual', field: 'VALOR_ACTUAL', cellEditor: 'cellPrueba', width: 200, editable:false, cellStyle: {textAlign: 'right'}
    }
  ];

  constructor(public actmaeactivoService:ActmaeactivoService, private auditoriaGralService: AuditoriagralService,
    private permisosService: PermisosService, private message: MessageService, public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private confirmationService: ConfirmationService, 
    private confIniciales: ConfInicialesService, private init: NuevoComponentService) { 
      this.agTable();
      this.detalleActTrnTransfer = [];
      this.detalleKardex = [];
    }

    agTable() {
      this.frameworkComponents = {
        cellPrueba: CellEditorPrueba
      };
      this.rowStyle = (params) => {
        if (params.node.rowIndex % 2 !== 0) {
          return {background: '#D6EAF8'};
        }
      };
      
      this.defaultActTrnTransf = {
        editable: false,
        objeto: 'acttrntransf',
        resizable: true
      };

      this.defaultActDetKardex = {
        editable: false,
        objeto: 'actdetkardex',
        resizable: true
      };
    }

  async  ngOnInit() {
    this.largo = '350';
    this.dateFecUso = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateFecFabr = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateFecComp = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateUltiDepr = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateFechRegi = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateFecIniGara = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateFecFinGara = this.datePipe.transform(new Date() , 'dd/MM/yyyy');

    this.detalleActTrnTransfer = [];
    this.detalleKardex = [];

    this.cmbOrig = [{name: 'Nacional'}, {name:'Extranjero'}];
    this.strOrigen = this.cmbOrig[0];
    this.cmbProc = [{name: 'Depreciacion'}, {name:'Control'}];
    this.strProceso = this.cmbProc[0];
    this.cmbEsta = [{name: 'Activo'}, {name:'Vendido'}, {name: 'De Baja'}];
    this.strEstado = this.cmbEsta[0];
    this.cmbUso = [{name: 'Interno'}, {name:'Externo'}];
    this.strTipUsuario = this.cmbUso[0];
    this.cmbTipoGara = [{name: 'Dias'}, {name:'Meses'},{name:'Años'}];
    this.strTipoGara = this.cmbTipoGara[0];

    await this.getPermisos();
  }

  async manejarSenales(valor) {
  
    if (valor === 'Primero') {
      await this.seleccionarRegistro('P');
    }
    if (valor === 'Siguiente') {
      await this.seleccionarRegistro('S');
    }
    if (valor === 'Anterior') {
      await this.seleccionarRegistro('A');
    }
    if (valor === 'Ultimo') {
      await this.seleccionarRegistro('U');
    }
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }
    if (valor === 'Cancelar') {
      await this.mostrarDatos(this.txtActCod);
      this.activaBotones('INI',true, false, false, true,true,true,true, true, true,true);
      this.selectTab = 0;
    }
    if (valor === 'Buscar') {
      this.buscarRegistro();
    }
    if (valor === 'Imprimir') {
      
    }
    if (valor === 'Almacenardoc') {
      if (this.txtActCod !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.mensajeAlerta('info', 'Información','No tiene los permisos necesarios. Consulte con el Administrador del Sistema');
          
        }
        
      }
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }


  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('error', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }

  eliminarRegistro() {
    if (this.selectTab === 0) {
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERELIMACION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }
    
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro?',
      header: 'Guardar',
      icon: 'pi pi-exclamation-triangle',
      key: 'actmaeactivoConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async() => {
        const Parametros: any[] = [];
        Parametros[0] = this.txtActCod;
	      Parametros[1] = '01';

        try {
          await this.actmaeactivoService.eliminarActivo(Parametros);

          this.auditoriaGralService.registrarAuditoria('ACT_MAEACTIVO', this.txtActCod , 'E',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
           
          this.limpiarDatos();
          this.activaBotones('E', true, false, false, true, true,true, true, true, true, true);
          
            
        } catch (err) {
          this.mensajeAlerta('error', 'Error', '! Proceso eliminar activo no terminó satisfactoriamente.... ! ' + err.error);
        }

      },reject: () => {
        
      }
    });

  }

  async guardarRegistro() {
	 let strTipoGuardar;
	
	
    if (this.txtActCod === '') {
        strTipoGuardar='N';
        /*Verifica los permisos*/
        if (this.permisos !== null ) {
          if (this.permisos.PERTODO === 0 ) {
            if (this.permisos.PERINSERCION === 0 ) {
              this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
                ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
              return;
            }
          }
        }

    } else { /*Modifica registro*/
    
      strTipoGuardar='M';	
      /*Verifica los permisos*/
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERACTUALIZACION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }

    if (this.faltaCampos() === true) { //Si los datos de la cabecera son correctos
      if (strTipoGuardar === 'N') {
        if (await this.insertaActivo()) {
          this.activaBotones('G', true, false, false,true,true, true,true,true, true, true);
        } 
      } else {
        if (await this.actualizarActivo()) {
          this.activaBotones('G', true, false, false,true,true, true,true,true, true, true);
        }
      }
		}
  }

  async actualizarActivo() {
    let strDia = '',strMes= '',strAnio = '';

    if (this.dateFecComp.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecComp.getDate()));
      strMes = this.completarFecha(String(this.dateFecComp.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecComp.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecComp).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFechComp = strAnio + '-' + strMes + '-' + strDia;	

    if (this.dateFecUso.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecUso.getDate()));
      strMes = this.completarFecha(String(this.dateFecUso.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecUso.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecUso).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFechUso = strAnio + '-' + strMes + '-' + strDia;

    if (this.dateFecFabr.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecFabr.getDate()));
      strMes = this.completarFecha(String(this.dateFecFabr.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecFabr.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecFabr).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFechFabr = strAnio + '-' + strMes + '-' + strDia;

    if (this.dateFechRegi.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFechRegi.getDate()));
      strMes = this.completarFecha(String(this.dateFechRegi.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFechRegi.getFullYear()));
    } else {
      const fechaArray = String(this.dateFechRegi).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFechRegi = strAnio + '-' + strMes + '-' + strDia;
  
    if (this.dateUltiDepr.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateUltiDepr.getDate()));
      strMes = this.completarFecha(String(this.dateUltiDepr.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateUltiDepr.getFullYear()));
    } else {
      const fechaArray = String(this.dateUltiDepr).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFecUltiDepr = strAnio + '-' + strMes + '-' + strDia;

    if (this.dateFecIniGara.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecIniGara.getDate()));
      strMes = this.completarFecha(String(this.dateFecIniGara.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecIniGara.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecIniGara).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFecIniGar = strAnio + '-' + strMes + '-' + strDia;

    if (this.dateFecFinGara.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecFinGara.getDate()));
      strMes = this.completarFecha(String(this.dateFecFinGara.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecFinGara.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecFinGara).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFecFinGar = strAnio + '-' + strMes + '-' + strDia;

    const Parametros: any[] = [];

    Parametros[0] = this.txtActCod;			
		Parametros[1] = this.txtActCod;
		Parametros[2] = '01'; 
		Parametros[3] = this.txtActGru;
    Parametros[4] = this.txtActNom;
    Parametros[5] = this.txtActNomCor; 		 		
		Parametros[6] = this.cmbProc.findIndex(index => index.name === this.strProceso.name) + 1; 		 		
		Parametros[7] = this.cmbOrig.findIndex(index => index.name === this.strOrigen.name) + 1;
		Parametros[8] = this.txtLugOrig;	 		 		
		Parametros[9] = this.cmbEsta.findIndex(index => index.name === this.strEstado.name) + 1;
		Parametros[10] = this.txtCtaActi;	 		 
		Parametros[11] = this.txtCiuCod;	 		 
		Parametros[12] = this.txtCodOfi;	 		 
		Parametros[13] = this.txtCodDep;	 		 
		Parametros[14] = this.txtCodArea;		 		 
		Parametros[15] = this.cmbUso.findIndex(index => index.name === this.strTipUsuario.name) + 1;
		Parametros[16] = this.txtCodCli1;		 		 
		Parametros[17] = this.txtCodProv;	 		 
		Parametros[18] = this.txtCompEgre;		 		 
		Parametros[19] = strFechComp;	 
		Parametros[20] = strFechUso; 		 
		Parametros[21] = strFechFabr;		 		 
		Parametros[22] = this.txtNumPoli;	 		 
		Parametros[23] = this.txtMarc;	 		 
		Parametros[24] = this.txtMode;	 		 
		Parametros[25] = this.txtNumSeri;	 		 
		Parametros[26] = this.txtChas;	 		 
		Parametros[27] = this.txtPlac;	 		 
		Parametros[28] = this.txtColo;	 		 
		Parametros[29] = Number(this.txtCostAct);			
		Parametros[30] = Number(this.txtCosMonOri);	
		Parametros[31] = this.txtCodMonOri;			
		Parametros[32] = Number(this.txtCotCamb);		
		Parametros[33] = Number(this.txtVidaUtil);				
		Parametros[34] = this.txtCodProvMant;			
		Parametros[35] = this.txtTipoMant;				
		Parametros[36] = this.txtCodFreMant;			
		Parametros[37] = this.txtObsMant;				
		Parametros[38] = Number(this.txtTiemGara);			
		Parametros[39] = this.cmbTipoGara.findIndex(index => index.name === this.strTipoGara.name) + 1; 
		Parametros[40] = '';			
		Parametros[41] = strFecIniGar;			
		Parametros[42] = strFecFinGar;			
		Parametros[43] = this.txtCobeGara;				
		Parametros[44] = this.txtExceGara;				
		Parametros[45] = this.txtAdicGara;
		
		let strSellSeg = '';
		if (this.btnSellSegu === true) {
      strSellSeg='1';
    } else {
      strSellSeg='0';
    }
	    					
		Parametros[46] = strSellSeg;			
		Parametros[47] = Number(this.txtValoGara);			
		Parametros[48] = this.txtObsGara;
		Parametros[49] = this.txtCodCtaDepr;
		Parametros[50] = Number(this.txtDeprAcum);			
		Parametros[51] = Number(this.txtValoResi);				
		Parametros[52] = strFechRegi;
		Parametros[53] = this.txtCome;
		Parametros[54] = this.txtCodCli2;
		Parametros[55] = this.txtCompComp;
		Parametros[56] = this.txtCodProp;
		Parametros[57] = this.txtCodGas;
		Parametros[58] = strFecUltiDepr;
		Parametros[59] = this.txtCodCcAct;
		Parametros[60] = this.txtCodCcDep;
    Parametros[61] = this.txtCodCcGas;

    try {
      await this.actmaeactivoService.actualizarActivo(Parametros);
      this.auditoriaGralService.registrarAuditoria('ACT_MAEACTIVO', this.txtActCod , 'A',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      return true;
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso actualizar maestro de activos no terminó satisfactoriamente.... ! ' + err.error)
      return false;
    }
  }

  async insertaActivo() {
    let strDia = '',strMes= '',strAnio = '';

    if (this.dateFecComp.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecComp.getDate()));
      strMes = this.completarFecha(String(this.dateFecComp.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecComp.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecComp).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFechComp = strAnio + '-' + strMes + '-' + strDia;	

    if (this.dateFecUso.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecUso.getDate()));
      strMes = this.completarFecha(String(this.dateFecUso.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecUso.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecUso).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFechUso = strAnio + '-' + strMes + '-' + strDia;

    if (this.dateFecFabr.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecFabr.getDate()));
      strMes = this.completarFecha(String(this.dateFecFabr.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecFabr.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecFabr).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFechFabr = strAnio + '-' + strMes + '-' + strDia;

    if (this.dateFechRegi.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFechRegi.getDate()));
      strMes = this.completarFecha(String(this.dateFechRegi.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFechRegi.getFullYear()));
    } else {
      const fechaArray = String(this.dateFechRegi).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFechRegi = strAnio + '-' + strMes + '-' + strDia;
  
    if (this.dateUltiDepr.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateUltiDepr.getDate()));
      strMes = this.completarFecha(String(this.dateUltiDepr.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateUltiDepr.getFullYear()));
    } else {
      const fechaArray = String(this.dateUltiDepr).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFecUltiDepr = strAnio + '-' + strMes + '-' + strDia;

    if (this.dateFecIniGara.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecIniGara.getDate()));
      strMes = this.completarFecha(String(this.dateFecIniGara.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecIniGara.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecIniGara).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFecIniGar = strAnio + '-' + strMes + '-' + strDia;

    if (this.dateFecFinGara.toString().includes('-')) {
      strDia = this.completarFecha(String(this.dateFecFinGara.getDate()));
      strMes = this.completarFecha(String(this.dateFecFinGara.getMonth() + 1));
      strAnio = this.completarFecha(String(this.dateFecFinGara.getFullYear()));
    } else {
      const fechaArray = String(this.dateFecFinGara).split('/');
      strDia = fechaArray[0];
      strMes = fechaArray[1];
      strAnio = fechaArray[2];
    }

    const strFecFinGar = strAnio + '-' + strMes + '-' + strDia;

    const Parametros: any[] = [];

    Parametros[0] = this.txtActCod;			
		Parametros[1] = '01';
		Parametros[2] = this.txtActGru; 
		Parametros[3] = this.txtActNom;
		Parametros[4] = this.txtActNomCor;	 		 		
		Parametros[5] = this.cmbProc.findIndex(index => index.name === this.strProceso.name) + 1; 		 		
		Parametros[6] = this.cmbOrig.findIndex(index => index.name === this.strOrigen.name) + 1;
		Parametros[7] = this.txtLugOrig;	 		 		
		Parametros[8] = this.cmbEsta.findIndex(index => index.name === this.strEstado.name) + 1;
		Parametros[9] = this.txtCtaActi;	 		 
		Parametros[10] = this.txtCiuCod;	 		 
		Parametros[11] = this.txtCodOfi;	 		 
		Parametros[12] = this.txtCodDep;	 		 
		Parametros[13] = this.txtCodArea;		 		 
		Parametros[14] = this.cmbUso.findIndex(index => index.name === this.strTipUsuario.name) + 1;
		Parametros[15] = this.txtCodCli1;		 		 
		Parametros[16] = this.txtCodProv;	 		 
		Parametros[17] = this.txtCompEgre;		 		 
		Parametros[18] = strFechComp;	 
		Parametros[19] = strFechUso; 		 
		Parametros[20] = strFechFabr;		 		 
		Parametros[21] = this.txtNumPoli;	 		 
		Parametros[22] = this.txtMarc;	 		 
		Parametros[23] = this.txtMode;	 		 
		Parametros[24] = this.txtNumSeri;	 		 
		Parametros[25] = this.txtChas;	 		 
		Parametros[26] = this.txtPlac;	 		 
		Parametros[27] = this.txtColo;	 		 
		Parametros[28] = Number(this.txtCostAct);			
		Parametros[29] = Number(this.txtCosMonOri);	
		Parametros[30] = this.txtCodMonOri;			
		Parametros[31] = Number(this.txtCotCamb);		
		Parametros[32] = Number(this.txtVidaUtil);				
		Parametros[33] = this.txtCodProvMant;			
		Parametros[34] = this.txtTipoMant;				
		Parametros[35] = this.txtCodFreMant;			
		Parametros[36] = this.txtObsMant;				
		Parametros[37] = Number(this.txtTiemGara);			
		Parametros[38] = this.cmbTipoGara.findIndex(index => index.name === this.strTipoGara.name) + 1; 
		Parametros[39] = '';			
		Parametros[40] = strFecIniGar;			
		Parametros[41] = strFecFinGar;			
		Parametros[42] = this.txtCobeGara;				
		Parametros[43] = this.txtExceGara;				
		Parametros[44] = this.txtAdicGara;
		
		let strSellSeg = '';
		if (this.btnSellSegu === true) {
      strSellSeg='1';
    } else {
      strSellSeg='0';
    }
	    					
		Parametros[45] = strSellSeg;			
		Parametros[46] = Number(this.txtValoGara);			
		Parametros[47] = this.txtObsGara;
		Parametros[48] = this.txtCodCtaDepr;
		Parametros[49] = Number(this.txtDeprAcum);			
		Parametros[50] = Number(this.txtValoResi);				
		Parametros[51] = strFechRegi;
		Parametros[52] = this.txtCome;
		Parametros[53] = this.txtCodCli2;
		Parametros[54] = this.txtCompComp;
		Parametros[55] = this.txtCodProp;
		Parametros[56] = this.txtCodGas;
		Parametros[57] = strFecUltiDepr;
		Parametros[58] = this.txtCodCcAct;
		Parametros[59] = this.txtCodCcDep;
    Parametros[60] = this.txtCodCcGas;
    
    try {

      const rs = await this.actmaeactivoService.guardarDatos(Parametros);

      if (rs !== null) {
        
        this.txtActCod = rs[0].V_ACT_CODIGO;

        this.auditoriaGralService.registrarAuditoria('ACT_MAEACTIVO', this.txtActCod , 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        }); 
				
			    return true;	    	    
			}

    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso insertar maestro de activos  no terminó satisfactoriamente.... ! ' + err.error)
      return false;
    }

  }

  completarFecha(strFecha): string {
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
    
  }

  
  faltaCampos() {
   
    //Código del proveedor
    if (this.txtActNom === '') {
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese el nombre del activo');
      document.getElementById('actnombre').focus();
      return false;	
    }
  
    if (this.txtActNomCor === '') {
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese el alias del activo');
      document.getElementById('aliasnombre').focus();
      return false;	
    }
    
    if (this.txtCtaActi === '') {
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese la cuenta contable del activo');
      document.getElementById('ctaactivo').focus();
      return false;	
    }	
    
    if (this.txtCodCtaDepr === '') {
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese la cuenta contable de depreciación del activo');
      document.getElementById('ctadpacum').focus();
      return false;	
    }	
  
    if (this.txtCodGas === '') {
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese la cuenta contable de gasto del activo');
      document.getElementById('ctagasto').focus();
      return false;	
    }			
    
    return true;
  }

  nuevoRegistro() {
    if (this.selectTab === 0) {
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERINSERCION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }

      this.activaBotones('N',false, true, true,false, false,false,false, false, false,false);
      this.limpiarDatos();
      document.getElementById('cod_actgrupo').focus();
    }
  }

  buscarRegistro() {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERSELECCION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.opcion = 'BuscarRegistro';
    this.types = [
      { label: 'CÓDIGO', value: 'ACT_CODIGO' },
      { label: 'NOMBRE', value: 'ACT_NOMBRE' },
      { label:'Alias',   value: 'ACT_NOMBREC'}
    ];
    this.busquedacampos = ['', '', ''];
    this.consulta = '*'
    this.tabla = 'act_maeactivo';
    this.where = 'ROWNUM < 50';

    this.busquedaSer.busquedaActMaeActivo1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });

  }

  busquedaGrupo(parametro) {
    this.opcion = 'grupo';
    this.types = [
      {label: 'CÓDIGO', value: 'ACTGR_CODIGO'},
      {label: 'NOMBRE', value: 'ACTGR_NOMBRE'},
      {label: 'REF.CONTABLE', value: 'CON_CODIGO'},
      {label: 'CENTRO DE COSTOS', value: 'CEN_CODIGO'}
    ];
    this.busquedacampos = [parametro, '', '', ''];
    this.consulta = '*';
    this.where = 'ROWNUM < 50';
    this.tabla = 'act_maegrupo';
    
    this.actmaeactivoService.encontrarActMaeGrupo(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaActMaeGrupo().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaActMaeGrupo().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaCiudad(parametro) {
    this.opcion = 'ciudad';
    this.types = [
      {label: 'CÓDIGO', value: 'CIU_CODIGO'},
      {label: 'NOMBRE', value: 'CIU_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'CIU_NOMBREC'}
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.where = 'ROWNUM < 50';
    this.tabla = 'ven_maeciudad';
    
    this.actmaeactivoService.encontrarVenMaeCiudad(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaVenMaeCiudad2().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaVenMaeCiudad2().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaOficina(parametro) {
    this.opcion = 'oficina';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'}
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.where = 'ref_tipo = \'ACTOF\' and ROWNUM < 50';
    this.tabla = 'SACI_MAEREFERENCIA';
    
    this.actmaeactivoService.encontrarReferencia(parametro, 'ACTOF').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaSaciMaeReferencia10('ACTOF').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaSaciMaeReferencia10('ACTOF').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaFrecMant(parametro) {
    this.opcion = 'frecmant';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'}
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.where = 'ref_tipo = \'ACTFR\' and ROWNUM < 50';
    this.tabla = 'SACI_MAEREFERENCIA';
    
    this.actmaeactivoService.encontrarReferencia(parametro, 'ACTFR').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaSaciMaeReferencia10('ACTFR').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaSaciMaeReferencia10('ACTFR').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaArea(parametro) {
    this.opcion = 'area';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'}
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.where = 'ref_tipo = \'ACTAR\' and ROWNUM < 50';
    this.tabla = 'SACI_MAEREFERENCIA';
    
    this.actmaeactivoService.encontrarReferencia(parametro, 'ACTAR').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaSaciMaeReferencia10('ACTAR').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaSaciMaeReferencia10('ACTAR').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaDepartamento(parametro) {
    this.opcion = 'departamento';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'}
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.where = 'ref_tipo = \'ACTDP\' and ROWNUM < 50';
    this.tabla = 'SACI_MAEREFERENCIA';
    
    this.actmaeactivoService.encontrarReferencia(parametro, 'ACTDP').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaSaciMaeReferencia10('ACTDP').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaSaciMaeReferencia10('ACTDP').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaMoneda(parametro) {
    this.opcion = 'moneda';
    this.types = [
      {label: 'CÓDIGO', value: 'REF_CODIGO'},
      {label: 'NOMBRE', value: 'REF_NOMBRE'}
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.where = 'ref_tipo = \'ACTMO\' and ROWNUM < 50';
    this.tabla = 'SACI_MAEREFERENCIA';
    
    this.actmaeactivoService.encontrarReferencia(parametro, 'ACTMO').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaSaciMaeReferencia10('ACTMO').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaSaciMaeReferencia10('ACTMO').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaUsuario1(parametro) {
    this.opcion = 'usuario1';
    this.types = [
      {label: 'CÓDIGO', value: 'EMP_CODIGO'},
      {label: 'NOMBRES', value: 'EMP_NOMBRE'},
      {label: 'APELLIDOS', value: 'EMP_APELLIDO'}
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = "EMP_CODIGO, emp_nombre || ' ' || emp_apellido as EMP_NOMBRE";
    this.where = ' ROWNUM < 50';
    this.tabla = 'nom_maeempleado';
    
    this.actmaeactivoService.encontrarNomMaeEmpleado(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaNomMaeEmpleado3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaNomMaeEmpleado3().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaUsuario2(parametro) {
    this.opcion = 'usuario2';
    this.types = [
      {label: 'CÓDIGO', value: 'CLI_CODIGO'},
      {label: 'NOMBRE', value: 'CLI_NOMBRE'},
      {label: 'RUCIDE', value: 'CLI_RUCIDE'},
      {label: 'NOMBREC', value: 'CLI_NOMBREC'}
    ];
    this.busquedacampos = [parametro, '', '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 50';
    this.tabla = 'ven_maecliente';
    
    this.actmaeactivoService.encontrarVenMaeCliente(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaVenMaeCliente7().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaVenMaeCliente7().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaPropietario(parametro) {
    this.opcion = 'propietario';
    this.types = [
      {label: 'CÓDIGO', value: 'CLI_CODIGO'},
      {label: 'NOMBRE', value: 'CLI_NOMBRE'},
      {label: 'RUCIDE', value: 'CLI_RUCIDE'},
      {label: 'NOMBREC', value: 'CLI_NOMBREC'}
    ];
    this.busquedacampos = [parametro, '', '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 50';
    this.tabla = 'ven_maecliente';
    
    this.actmaeactivoService.encontrarVenMaeCliente(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaVenMaeCliente7().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaVenMaeCliente7().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaProveedor(parametro) {
    this.opcion = 'proveedor';
    this.types = [
      {label: 'CÓDIGO', value: 'PRO_CODIGO'},
      {label: 'NOMBRE', value: 'PRO_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'PRO_NOMBREC'},
      {label: 'GRUPO', value: 'GRU_CODIGO'},
      {label: 'RUC', value: 'PRO_RUCIDE'},
      {label: 'CUENTA', value: 'CON_CODIGO1'}
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 50';
    this.tabla = 'COM_MAEPROVEEDOR';
    
    this.actmaeactivoService.encontrarComMaeProveedor(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaComMaeProveedor7().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaComMaeProveedor7().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaPoliza(parametro) {
    this.opcion = 'poliza';
    this.types = [
      {label: 'CÓDIGO', value: 'POL_CODIGO'},
      {label: 'NÚMERO', value: 'POL_NUMERO'},
      {label: 'EMPRESA', value: 'POL_EMPRESA'},
      {label: 'FECHAINI', value: 'POL_FECHAINI'},
      {label: 'FECHAFIN', value: 'POL_FECHAFIN'}
    ];
    this.busquedacampos = [parametro, '', '', '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 50';
    this.tabla = 'act_maepoliza';
    
    this.actmaeactivoService.encontrarActMaePoliza(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaActMaePoliza1().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaActMaePoliza1().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaCtaAct(parametro) {
    this.opcion = 'ctaactivo';
    this.types = [
      {label: 'CÓDIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT.COSTO', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 100';
    this.tabla = 'CON_MAECON';
    
    this.actmaeactivoService.encontrarConMaeCon(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          if (eR[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon2().subscribe((datos: any[]) => {
              this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
            });
          } else {
            this.manejarSeleccion(eR[0]);
          }
        } else {
          this.busquedaSer.busquedaConMaeCon2().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaConMaeCon2().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaCCActivo(parametro) {
    this.opcion = 'ccactivo';
    this.types = [
      {label: 'CÓDIGO', value: 'CEN_CODIGO'},
      {label: 'NOMBRE', value: 'CEN_NOMBRE'},
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 100';
    this.tabla = 'CON_MAECEN';
    
    this.actmaeactivoService.encontrarConMaeCen(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaConMaeCen3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaConMaeCen3().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaCtaDepr(parametro) {
    this.opcion = 'ctadepr';
    this.types = [
      {label: 'CÓDIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT.COSTO', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 100';
    this.tabla = 'CON_MAECON';
    
    this.actmaeactivoService.encontrarConMaeCon(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          if (eR[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon2().subscribe((datos: any[]) => {
              this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
            });
          } else {
            this.manejarSeleccion(eR[0]);
          }
        } else {
          this.busquedaSer.busquedaConMaeCon2().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaConMaeCon2().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaCCDepr(parametro) {
    this.opcion = 'ccdepr';
    this.types = [
      {label: 'CÓDIGO', value: 'CEN_CODIGO'},
      {label: 'NOMBRE', value: 'CEN_NOMBRE'},
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 100';
    this.tabla = 'CON_MAECEN';
    
    this.actmaeactivoService.encontrarConMaeCen(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaConMaeCen3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaConMaeCen3().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaCtaGas(parametro) {
    this.opcion = 'ctagasto';
    this.types = [
      {label: 'CÓDIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT.COSTO', value: 'CON_CENTRO'},
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 100';
    this.tabla = 'CON_MAECON';
    
    this.actmaeactivoService.encontrarConMaeCon(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          if (eR[0].CON_CODIGO.substr(-1, 1) === '.') {
            this.busquedaSer.busquedaConMaeCon2().subscribe((datos: any[]) => {
              this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
            });
          } else {
            this.manejarSeleccion(eR[0]);
          }
        } else {
          this.busquedaSer.busquedaConMaeCon2().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaConMaeCon2().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaCCGasto(parametro) {
    this.opcion = 'ccgasto';
    this.types = [
      {label: 'CÓDIGO', value: 'CEN_CODIGO'},
      {label: 'NOMBRE', value: 'CEN_NOMBRE'},
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 100';
    this.tabla = 'CON_MAECEN';
    
    this.actmaeactivoService.encontrarConMaeCen(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaConMaeCen3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaConMaeCen3().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaProvMant(parametro) {
    this.opcion = 'proveedormant';
    this.types = [
      {label: 'CÓDIGO', value: 'PRO_CODIGO'},
      {label: 'NOMBRE', value: 'PRO_NOMBRE'},
      {label: 'NOMBRE CORTO', value: 'PRO_NOMBREC'},
      {label: 'GRUPO', value: 'GRU_CODIGO'},
      {label: 'RUC', value: 'PRO_RUCIDE'},
      {label: 'CUENTA', value: 'CON_CODIGO1'}
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '*';
    this.where = ' ROWNUM < 50';
    this.tabla = 'COM_MAEPROVEEDOR';
    
    this.actmaeactivoService.encontrarComMaeProveedor(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaComMaeProveedor7().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaComMaeProveedor7().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }


  manejarSeleccion(valor) {

    if (this.opcion === 'BuscarRegistro') {
      if (valor.ACT_CODIGO !== '') {
        this.mostrarDatos(valor.ACT_CODIGO);
      }
    }

    if (this.opcion === 'grupo') {
      if (valor.ACTGR_CODIGO !== '') {
        this.txtActGru = valor.ACTGR_CODIGO;
			  this.lblGruNom = valor.ACTGR_NOMBRE;
      }
    }

    if (this.opcion === 'ciudad') {
      if (valor.CIU_CODIGO !== '') {
        this.txtCiuCod = valor.CIU_CODIGO;
			  this.lblCiuNomb = valor.CIU_NOMBRE;
      }
    }

    if (this.opcion === 'oficina') {
      if (valor.REF_CODIGO !== '') {
        this.txtCodOfi = valor.REF_CODIGO;
			  this.lblNomOfi = valor.REF_NOMBRE;
      }
    }

    if (this.opcion === 'area') {
      if (valor.REF_CODIGO !== '') {
        this.txtCodArea = valor.REF_CODIGO;
			  this.lblNomArea = valor.REF_NOMBRE;
      }
    }

    if (this.opcion === 'departamento') {
      if (valor.REF_CODIGO !== '') {
        this.txtCodDep = valor.REF_CODIGO;
			  this.lblNomDep = valor.REF_NOMBRE;
      }
    }

    if (this.opcion === 'moneda') {
      if (valor.REF_CODIGO !== '') {
        this.txtCodMonOri = valor.REF_CODIGO;
			  this.lblNomMonOri = valor.REF_NOMBRE;
      }
    }

    if (this.opcion === 'usuario1') {
      if (valor.EMP_CODIGO !== '') {
        this.txtCodCli1 = valor.EMP_CODIGO;
			  this.lblNomCli1 = valor.EMP_NOMBRE;
      }
    }

    if (this.opcion === 'usuario2') {
      if (valor.CLI_CODIGO !== '') {
        this.txtCodCli2 = valor.CLI_CODIGO;
			  this.lblNomCli2 = valor.CLI_NOMBRE;
      }
    }

    if (this.opcion === 'propietario') {
      if (valor.CLI_CODIGO !== '') {
        this.txtCodProp = valor.CLI_CODIGO;
			  this.lblNomProp = valor.CLI_NOMBRE;
      }
    }

    if (this.opcion === 'proveedor') {
      if (valor.PRO_CODIGO !== '') {
        this.txtCodProv = valor.PRO_CODIGO;
			  this.lblNomProv = valor.PRO_NOMBRE;
      }
    }

    if (this.opcion === 'poliza') {
      if (valor.POL_CODIGO !== '') {
        this.txtNumPoli = valor.POL_CODIGO;
			  this.lblNumPoli = valor.POL_NUMERO;
      }
    }

    if (this.opcion === 'ctaactivo') {
      if (valor.CON_CODIGO !== '') {
        this.txtCtaActi = valor.CON_CODIGO;
			  this.lblNomCtaActi = valor.CON_NOMBRE;
      }
    }

    if (this.opcion === 'ccactivo') {
      if (valor.CEN_CODIGO !== '') {
        this.txtCodCcAct = valor.CEN_CODIGO;
			  this.lblNomCcAct = valor.CEN_NOMBRE;
      }
    }

    if (this.opcion === 'ctadepr') {
      if (valor.CON_CODIGO !== '') {
        this.txtCodCtaDepr = valor.CON_CODIGO;
			  this.lblNomCtaDepr = valor.CON_NOMBRE;
      }
    }

    if (this.opcion === 'ccdepr') {
      if (valor.CEN_CODIGO !== '') {
        this.txtCodCcDep = valor.CEN_CODIGO;
			  this.lblNomCcDep = valor.CEN_NOMBRE;
      }
    }

    if (this.opcion === 'ctagasto') {
      if (valor.CON_CODIGO !== '') {
        this.txtCodGas = valor.CON_CODIGO;
			  this.lblNomCtaGas = valor.CON_NOMBRE;
      }
    }

    if (this.opcion === 'ccgasto') {
      if (valor.CEN_CODIGO !== '') {
        this.txtCodCcGas = valor.CEN_CODIGO;
			  this.lblNomCcGas = valor.CEN_NOMBRE;
      }
    }
    if (this.opcion === 'proveedormant') {
      if (valor.PRO_CODIGO !== '') {
        this.txtCodProvMant = valor.PRO_CODIGO;
			  this.lblNomProvMant = valor.PRO_NOMBRE;
      }
    }

    if (this.opcion === 'frecmant') {
      if (valor.REF_CODIGO !== '') {
        this.txtCodFreMant = valor.REF_CODIGO;
			  this.lblNombFreMant = valor.REF_NOMBRE;
      }
    }
    
    this.displayDialogBusquedaFast = false;
  }

  async mostrarDatos(strActCod) {
    this.limpiarDatos();

    const parametros: any[] = [];
    parametros[0]='01';
    parametros[1]=strActCod;

    try {
      const rs  = await this.actmaeactivoService.consultaDatos(parametros);

      for (const item of rs) {
        this.txtActCod = item.ACT_CODIGO;
        this.txtActGru = item.ACT_GRUPO === null ? '' : item.ACT_GRUPO; 
        this.txtActNom = item.ACT_NOMBRE === null ? '' : item.ACT_NOMBRE;
        this.txtActNomCor = item.ACT_NOMBREC === null ? '' : item.ACT_NOMBREC;
        this.txtLugOrig = item.ACT_ORIGEN === null ? '' : item.ACT_ORIGEN;
        this.txtCiuCod = item.CIU_CODIGO === null ? '' : item.CIU_CODIGO;
			  this.txtCodOfi = item.ACT_OFICINA === null ? '' : item.ACT_OFICINA;
        this.txtCodArea = item.ACT_AREA === null ? '' : item.ACT_AREA;
        this.txtCodDep = item.ACT_DEPARTAMENTO === null ? '' : item.ACT_DEPARTAMENTO;
        this.txtCodCli1 = item.ACT_USUARIO === null ? '' : item.ACT_USUARIO;
        this.txtCodCli2 = item.ACT_USUARIO2 === null ? '' : item.ACT_USUARIO2;
        this.txtCodProp = item.ACT_PROPIETARIO === null ? '' : item.ACT_PROPIETARIO;

				if (item.ACT_FECHAUSO !== null) {
          this.dateFecUso = this.datePipe.transform(item.ACT_FECHAUSO , 'dd/MM/yyyy');
        } else {
          this.dateFecUso = '';
        }

        this.txtCodProv = item.PRO_CODIGO === null ? '' : item.PRO_CODIGO;

        if (item.ACT_FECHAFAB !== null) {
          this.dateFecFabr = this.datePipe.transform(item.ACT_FECHAFAB , 'dd/MM/yyyy');
        } else {
          this.dateFecFabr = '';
        }

        if (item.ACT_FECHACOMPRA !== null) {
          this.dateFecComp = this.datePipe.transform(item.ACT_FECHACOMPRA , 'dd/MM/yyyy');
        } else {
          this.dateFecComp = '';
        }
			
			  this.txtCompComp = item.ACT_CMPBCOMPRA === null ? '' : item.ACT_CMPBCOMPRA;
			  this.txtMarc = item.ACT_MARCA === null ? '' : item.ACT_MARCA;
			  this.txtMode = item.ACT_MODELO === null ? '' : item.ACT_MODELO;
			  this.txtNumSeri = item.ACT_MOTORSERIE === null ? '' : item.ACT_MOTORSERIE;
			  this.txtChas = item.ACT_CHASIS === null ? '' : item.ACT_CHASIS;
			  this.txtPlac = item.ACT_PLACA === null ? '' : item.ACT_PLACA;
			  this.txtNumPoli = item.ACT_POLIZA === null ? '' : item.ACT_POLIZA;
			  this.txtColo = item.ACT_COLOR === null ? '' : item.ACT_COLOR;
			  this.txtCompEgre = item.ACT_NUMCE === null ? '' : item.ACT_NUMCE;
			  this.txtCtaActi = item.CON_CODIGO === null ? '' : item.CON_CODIGO;
			  this.txtCodCtaDepr = item.CON_CODIGODEP === null ? '' : item.CON_CODIGODEP;
			  this.txtCodGas = item.CON_CODIGOGAS === null ? '' : item.CON_CODIGOGAS;
			  this.txtCodCcAct = item.CEN_CODIGOACT === null ? '' : item.CEN_CODIGOACT;
			  this.txtCodCcDep = item.CEN_CODIGODEP === null ? '' : item.CEN_CODIGODEP;
			  this.txtCodCcGas = item.CEN_CODIGOGAS === null ? '' : item.CEN_CODIGOGAS;
			  this.txtCome = item.ACT_COMENTARIO === null ? '' : item.ACT_COMENTARIO;
			  this.txtCostAct = item.ACT_COSTO === null ? '' : Number(item.ACT_COSTO).toFixed(2);
			  this.txtCosMonOri = item.ACT_COSTOORG === null ? '' : Number(item.ACT_COSTOORG).toFixed(2);
			  this.txtCodMonOri = item.ACT_MONEDA === null ? '' : item.ACT_MONEDA;
			  this.txtCotCamb = item.ACT_COTIZACAMBIO === null ? '' : Number(item.ACT_COTIZACAMBIO).toFixed(2);						
			  this.txtVidaUtil = item.ACT_VIDAUTIL === null ? '' : Number(item.ACT_VIDAUTIL).toFixed(2);
        this.txtDeprAcum = item.ACT_DPRCACUM === null ? '' : Number(item.ACT_DPRCACUM).toFixed(2);
        
        if (item.ACT_FECULTMDPRC !== null) {
          this.dateUltiDepr = this.datePipe.transform(item.ACT_FECULTMDPRC , 'dd/MM/yyyy');
        } else {
          this.dateUltiDepr = '';
        }
        
        this.txtValoResi = item.ACT_VALRSDL === null ? '' : Number(item.ACT_VALRSDL).toFixed(2);
        
        if (item.ACT_FECHRGSTR !== null) {
          this.dateFechRegi = this.datePipe.transform(item.ACT_FECHRGSTR , 'dd/MM/yyyy');
        } else {
          this.dateFechRegi = '';
        }
        
        this.txtCodProvMant = item.PRO_CODIGOMANT === null ? '' : item.PRO_CODIGOMANT;
			  this.txtTipoMant = item.ACT_TIPOMANT === null ? '' : item.ACT_TIPOMANT;
			  this.txtCodFreMant = item.ACT_FRECUENCIAMANT === null ? '' : item.ACT_FRECUENCIAMANT;
			  this.txtObsMant = item.ACT_OBSERVACIONMANT === null ? '' : item.ACT_OBSERVACIONMANT;
			  this.txtTiemGara = item.ACT_TIEMPOGAR === null ? '' : Number(item.ACT_TIEMPOGAR).toFixed(2);
			  this.txtValoGara = item.ACT_VALORGAR === null ? '' : Number(item.ACT_VALORGAR).toFixed(2);
        
        if (item.ACT_FECHAINIGAR !== null) {
          this.dateFecIniGara = this.datePipe.transform(item.ACT_FECHAINIGAR , 'dd/MM/yyyy');
        } else {
          this.dateFecIniGara = '';
        }

        if (item.ACT_FECHAFINGAR !== null) {
          this.dateFecFinGara = this.datePipe.transform(item.ACT_FECHAFINGAR , 'dd/MM/yyyy');
        } else {
          this.dateFecFinGara = '';
        }

        if (item.ACT_SELLOSEGUGAR !== null) {
          this.btnSellSegu = item.ACT_SELLOSEGUGAR === '1';
        } else {
          this.btnSellSegu = false;
        }
        
			  this.txtCobeGara = item.ACT_COBERTURAGAR === null ? '' : item.ACT_COBERTURAGAR;
			  this.txtExceGara = item.ACT_EXCEPCIONGAR === null ? '' : item.ACT_EXCEPCIONGAR;
			  this.txtAdicGara = item.ACT_ADICIONALGAR === null ? '' : item.ACT_ADICIONALGAR;
			  this.txtObsGara = item.ACT_OBSERVACIONGAR === null ? '' : item.ACT_OBSERVACIONGAR;
			
		
			  const intActEstado = item.ACT_ESTADO;
      
        this.strOrigen = this.cmbOrig[Number(item.ACT_ORIGEN) -1];
        this.strProceso = this.cmbProc[Number(item.ACT_PROCESO) -1];
        this.strEstado = this.cmbEsta[Number(item.ACT_ESTADO) -1];
        this.strTipUsuario = this.cmbUso[Number(item.ACT_TIPOUSUARIO) -1];
        this.strTipoGara = this.cmbTipoGara[Number(item.ACT_UNIDADTIEMPOGAR) -1];
		
        let codEmp=0;
        if (this.txtCodCli1 === '')  {
          codEmp = 0;
        } else {
          codEmp = Number(this.txtCodCli1);
        }
			
			  this.lblNomCli1 = await this.encontrarRegistro("emp_nombre || ' ' || emp_apellido", "nom_maeempleado", "emp_codigo = " + codEmp);
			  this.lblNomCli2 = await this.encontrarRegistro("cli_nombre", "ven_maecliente", "cli_codigo = '" + this.txtCodCli2 +"'");
        this.lblNomProp = await this.encontrarRegistro("cli_nombre", "ven_maecliente", "cli_codigo = '" + this.txtCodProp +"'");
        this.lblNomProv = await this.encontrarRegistro("pro_nombre", "com_maeproveedor", "pro_codigo = '" + this.txtCodProv +"'");
        this.lblNomCtaActi = await this.encontrarRegistro("con_nombre", "con_maecon", "con_codigo = '" + this.txtCtaActi +"'");
        this.lblNomCtaDepr = await this.encontrarRegistro("con_nombre", "con_maecon", "con_codigo = '" + this.txtCodCtaDepr +"'");
        this.lblNomCtaGas = await this.encontrarRegistro("con_nombre", "con_maecon", "con_codigo = '" + this.txtCodGas +"'");
        this.lblNomCcAct = await this.encontrarRegistro("cen_nombre", "con_maecen", "cen_codigo = '" + this.txtCodCcAct +"'");
        this.lblNomCcDep = await this.encontrarRegistro("cen_nombre", "con_maecen", "cen_codigo = '" + this.txtCodCcDep +"'");
        this.lblNomCcGas = await this.encontrarRegistro("cen_nombre", "con_maecen", "cen_codigo = '" + this.txtCodCcGas +"'");
        this.lblNomMonOri = await this.encontrarRegistro("ref_nombre", "saci_maereferencia", "ref_codigo = '" + this.txtCodMonOri + "' and ref_tipo = 'ACTMO'");
        this.lblNomProvMant = await this.encontrarRegistro("pro_nombre", "com_maeproveedor", "pro_codigo = '" + this.txtCodProvMant +"'");
        this.lblNombFreMant = await this.encontrarRegistro("ref_nombre", "saci_maereferencia", "ref_codigo = '" + this.txtCodFreMant + "' and ref_tipo = 'ACTFR'");
        this.lblGruNom = await this.encontrarRegistro("actgr_nombre", "act_maegrupo", "actgr_codigo = '" + this.txtActGru + "'");
        this.lblCiuNomb = await this.encontrarRegistro("ciu_nombre", "ven_maeciudad", "ciu_codigo = '" + this.txtCiuCod + "'");
        this.lblNomOfi = await this.encontrarRegistro("ref_nombre", "saci_maereferencia", "ref_codigo = '" + this.txtCodOfi + "' and ref_tipo = 'ACTOF'");
        this.lblNomArea = await this.encontrarRegistro("ref_nombre", "saci_maereferencia", "ref_codigo = '" + this.txtCodArea + "' and ref_tipo = 'ACTAR'");
        this.lblNomDep = await this.encontrarRegistro("ref_nombre", "saci_maereferencia", "ref_codigo = '" + this.txtCodDep + "' and ref_tipo = 'ACTDP'");
        this.lblNumPoli = await this.encontrarRegistro("pol_numero", "act_maepoliza", "pol_codigo = '" + this.txtNumPoli+ "'");
        
        if (intActEstado === 3){
				  this.activaBotones('NoModificar',true, false, false, true,true,true,true, true, true,true);
				} else {
				  this.activaBotones('INI',true, false, false, true,true,true,true, true, true,true);
			  }
			  await this.listarDatosNove(this.txtActCod);
			  await this.listarDatosKar(this.txtActCod);
      }

    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Error al mostrar los datos del activo.... ! ' + err.error);
    }
  }

  async listarDatosKar(strActCod) {
    let valActual =  Number(this.txtDeprAcum);

    try {
      this.detalleKardex = [];
      const rs = await this.actmaeactivoService.consultarKardex(strActCod);
      const detalle = [];
      for (const res of rs) {
        const item: ActDetKardex = {};

        if (res.ENCKARDEX_FECHA !== null) {
          item.ENCKARDEX_FECHA = this.datePipe.transform(res.ENCKARDEX_FECHA, 'dd/MM/yyyy');
        }

        item.ENCKARDEX_COMPROBANTE = res.ENCKARDEX_COMPROBANTE === null ? '' : res.ENCKARDEX_COMPROBANTE;
        item.DETKARDEX_IMPORTE = res.DETKARDEX_IMPORTE === 0 ? Number(0).toFixed(2) : Number(res.DETKARDEX_IMPORTE).toFixed(2);

        valActual = valActual + Number(item.DETKARDEX_IMPORTE);
        item.VALOR_ACTUAL = Number(valActual).toFixed(2);

				detalle.push(item);
      }

      this.detalleKardex = detalle;

    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso listarDatos no terminó satisfactoriamente.... ! ' + err.error)
    }
  }

  async listarDatosNove(strActCod) {
    
    try {
      this.detalleActTrnTransfer = [];
      const rs = await this.actmaeactivoService.consultarNovedades(strActCod);
      const detalle = [];
      for (const res of rs) {
        const item: ActTrnTranf = {};

        if (res.TRNTRANSF_FECHA !== null) {
          item.TRNTRANSF_FECHA = this.datePipe.transform(res.TRNTRANSF_FECHA, 'dd/MM/yyyy');
        }

        item.TRNTRANSF_NUMERO = res.TRNTRANSF_NUMERO === null ? '' : res.TRNTRANSF_NUMERO;
        item.TRNTRANSF_USUARIO = res.TRNTRANSF_USUARIO === null ? '' : res.TRNTRANSF_USUARIO;
        item.TRNTRANSF_REFERENCIA = res.TRNTRANSF_REFERENCIA === null ? '' : res.TRNTRANSF_REFERENCIA;
        item.TRNTRANSF_AUTORIZADO = res.TRNTRANSF_AUTORIZADO === null ? '' : res.TRNTRANSF_AUTORIZADO;
        item.TRNTRANSF_COMENTARIO = res.TRNTRANSF_COMENTARIO === null ? '' : res.TRNTRANSF_COMENTARIO;
        item.TRNTRANSF_ESTADO = res.TRNTRANSF_ESTADO === null ? '' : res.TRNTRANSF_ESTADO;
        item.TRNTRANSF_ORIGEN = res.TRNTRANSF_ORIGEN === null ? '' : res.TRNTRANSF_ORIGEN;
        
        detalle.push(item);
      }

      this.detalleActTrnTransfer = detalle;

    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso listarDatos no terminó satisfactoriamente.... ! ' + err.error)
    }
		
  }

  async encontrarRegistro(strCampo, strTabla, strCondicion) {
    let strResultado = '';
    try {
      const rs = await this.actmaeactivoService.encontrarRegistroPromise(strCampo, strTabla, strCondicion);
      if (rs != null) {
        for (const item of rs) {
          console.log('item: ',item);
          for (const clave in item) {
            console.log('clave: ',clave);
            if (item[clave] !== null) {
              strResultado = item[clave];
            } else{
              strResultado = '';
            }
          }
        }
       
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', err.error);
    }
    return strResultado;
  }

  async seleccionarRegistro(strTipo) {
    if (this.permisos !== null ) {
      if (this.permisos.PERTODO === 0 ) {
        if (this.permisos.PERSELECCION === 0 ) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ', no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    let strActCod = '';

    const parametros: any[] = [];
    parametros[0] = strTipo;
	  parametros[1] = '01'; 
    parametros[2] = this.txtActCod; 
    
    try {

      const rs = await this.actmaeactivoService.navegarRegistro(parametros);
      if (rs !== null) {
        if (rs.length > 0) {
          strActCod = rs[0].ACT_CODIGO === null ? '' : rs[0].ACT_CODIGO;
        }
      }

    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Error al seleccionar registro.... ! ' + err.error);
    }

    if (strActCod !== '') {
		  await this.mostrarDatos(strActCod);
	  }

  }


  limpiarDatos() {
    this.txtActCod = '';
    this.txtActGru = '';
	  this.txtActNom = '';
	  this.txtActNomCor = '';
	  this.txtLugOrig = '';
	  this.txtCiuCod = '';
	  this.txtCodOfi = '';
	  this.txtCodArea = '';
	  this.txtCodDep = '';
	  this.txtCodCli1 = '';
	  this.txtCodCli2 = '';
    this.txtCodProp = '';
    this.txtCodProv = '';
    this.txtCompComp = '';
    this.txtMarc = '';
    this.txtMode = '';
    this.txtNumSeri = '';
    this.txtChas = '';
    this.txtPlac = '';
    this.txtNumPoli = '';
    this.txtColo = '';
    this.txtCompEgre = '';
    this.txtCtaActi = '';
    this.txtCodCtaDepr = '';
    this.txtCodGas = '';
    this.txtCodCcAct = '';
    this.txtCodCcDep = '';
    this.txtCodCcGas = '';
    this.txtCome = '';
	  this.txtCostAct = '';
	  this.txtCosMonOri = '';
	  this.txtCodMonOri = '';
	  this.txtCotCamb = '';
	  this.txtVidaUtil = '';
	  this.txtDeprAcum = '';
	  this.txtValoResi = '';
		this.txtCodProvMant = '';
	  this.txtTipoMant = '';
	  this.txtCodFreMant = '';
	  this.txtObsMant = '';
	  this.txtTiemGara = '';
	  this.txtValoGara = '';
  
    this.btnSellSegu = false;
		this.txtCobeGara = '';
	  this.txtExceGara = '';
	  this.txtAdicGara = '';
	  this.txtObsGara = '';
	
    this.lblGruNom = '';
	  this.lblCiuNomb = '';
	  this.lblNomOfi = '';
	  this.lblNomArea = '';
	  this.lblNomDep = '';
    this.lblNomCli1 = '';
    this.lblNomCli2 = '';
    this.lblNomProp = '';
    this.lblNomProv = '';
    this.lblNomCtaActi = '';
    this.lblNomCtaDepr = '';
    this.lblNomCtaGas = '';
    this.lblNomCtaGas = '';
    this.lblNomCtaDepr = '';
    this.lblNomCtaGas = '';
    this.lblNomMonOri = '';
    this.lblNomProvMant = '';
    this.lblNombFreMant = '';
    this.lblNumPoli = '';

    this.strEstado = this.cmbEsta[0];
    this.strOrigen = this.cmbOrig[0];
    this.strProceso = this.cmbProc[0];
    this.strTipoGara = this.cmbTipoGara[0];
    this.strTipUsuario = this.cmbUso[0];

    this.dateFecUso = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateFecFabr = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateFecComp = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateUltiDepr = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateFechRegi = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateFecIniGara = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.dateFecFinGara = this.datePipe.transform(new Date() , 'dd/MM/yyyy');

  }

  cambiarFoco(evento) {
    if (evento.target.id === 'cod_actgrupo') {
      document.getElementById('actnombre').focus();
    }
    if (evento.target.id === 'actnombre') {
      document.getElementById('aliasnombre').focus();
    }
    if (evento.target.id === 'aliasnombre') {
      document.getElementById('origen').focus();
    }
    if (evento.target.id === 'origen') {
      document.getElementById('lugOrigen').focus();
    }
    if (evento.target.id === 'lugOrigen') {
      document.getElementById('ciudad').focus();
    }
    if (evento.target.id === 'ciudad') {
      document.getElementById('oficina').focus();
    }
    if (evento.target.id === 'oficina') {
      document.getElementById('area').focus();
    }
    if (evento.target.id === 'area') {
      document.getElementById('departamento').focus();
    }
    if (evento.target.id === 'departamento') {
      document.getElementById('proceso').focus();
    }
    if (evento.target.id === 'proceso') {
      document.getElementById('estado').focus();
    }
    if (evento.target.id === 'estado') {
      document.getElementById('tipousuario').focus();
    }
    if (evento.target.id === 'tipousuario') {
      document.getElementById('codcli1').focus();
    }
    if (evento.target.id === 'codcli1') {
      document.getElementById('codcli2').focus();
    }
    if (evento.target.id === 'codcli2') {
      document.getElementById('codpropietario').focus();
    }
    if (evento.target.id === 'codpropietario') {
      document.getElementById('codprov').focus();
    }
    if (evento.target.id === 'codprov') {
      document.getElementById('numcomprc').focus();
    }
    if (evento.target.id === 'numcomprc') {
      document.getElementById('marca').focus();
    }
    if (evento.target.id === 'marca') {
      document.getElementById('modelo').focus();
    }
    if (evento.target.id === 'modelo') {
      document.getElementById('serienum').focus();
    }
    if (evento.target.id === 'serienum') {
      document.getElementById('chasis').focus();
    }
    if (evento.target.id === 'chasis') {
      document.getElementById('mumplaca').focus();
    }
    if (evento.target.id === 'mumplaca') {
      document.getElementById('numpoli').focus();
    }
    if (evento.target.id === 'numpoli') {
      document.getElementById('color').focus();
    }
    if (evento.target.id === 'color') {
      document.getElementById('numcompegr').focus();
    }
    if (evento.target.id === 'numcompegr') {
      document.getElementById('ctaactivo').focus();
    }
    if (evento.target.id === 'ctaactivo') {
      document.getElementById('cc1').focus();
    }
    if (evento.target.id === 'cc1') {
      document.getElementById('ctadpacum').focus();
    }
    if (evento.target.id === 'ctadpacum') {
      document.getElementById('ccdep').focus();
    }
    if (evento.target.id === 'ccdep') {
      document.getElementById('ctagasto').focus();
    }
    if (evento.target.id === 'ctagasto') {
      document.getElementById('ccgasto').focus();
    }
    if (evento.target.id === 'ccgasto') {
      document.getElementById('comentario').focus();
    }
    if (evento.target.id === 'comentario') {
      this.selectTab = 1;
      document.getElementById('costoactivo').focus();
    }
    if (evento.target.id === 'costoactivo') {
      document.getElementById('costomonori').focus();
    }
    if (evento.target.id === 'costomonori') {
      document.getElementById('monorigen').focus();
    }
    if (evento.target.id === 'monorigen') {
      document.getElementById('cotizacambio').focus();
    }
    if (evento.target.id === 'cotizacambio') {
      document.getElementById('vidautil').focus();
    }
    if (evento.target.id === 'vidautil') {
      document.getElementById('depreacum').focus();
    }
    if (evento.target.id === 'depreacum') {
      document.getElementById('valorresi').focus();
    }
    if (evento.target.id === 'valorresi') {
      document.getElementById('codprovmant').focus();
    }
    if (evento.target.id === 'codprovmant') {
      document.getElementById('tipomant').focus();
    }
    if (evento.target.id === 'tipomant') {
      document.getElementById('frecuencia').focus();
    }
    if (evento.target.id === 'frecuencia') {
      document.getElementById('obsermant').focus();
    }
    if (evento.target.id === 'obsermant') {
      document.getElementById('tiempogara').focus();
    }
    if (evento.target.id === 'tiempogara') {
      document.getElementById('tipogara').focus();
    }
    if (evento.target.id === 'tipogara') {
      document.getElementById('valorgara').focus();
    }
    if (evento.target.id === 'valorgara') {
      document.getElementById('cobegara').focus();
    }
    if (evento.target.id === 'cobegara') {
      document.getElementById('excepgara').focus();
    }
    if (evento.target.id === 'excepgara') {
      document.getElementById('adicionales').focus();
    }
    if (evento.target.id === 'adicionales') {
      document.getElementById('obsgara').focus();
    }
    if (evento.target.id === 'obsgara') {
      this.selectTab = 0;
      document.getElementById('cod_actgrupo').focus();
    }
    
  }

  cambio(event) {
    this.activaBotones('M', false, true, true, false, false, false, false, false, false, false);
  }


  activaBotones(strTipo, bolNuevo, bolGuardar, bolCancelar, bolEliminar, bolInicio, bolAnterior, bolSiguiente, bolUltimo, bolBuscar,
		bolImprimir) {
      this.btnGuardar = !bolGuardar
      this.btnNuevo = !bolNuevo;
      this.btnBorrar = !bolEliminar;
      this.botonPrimero = !bolInicio;
      this.botonAnterior = !bolAnterior;
      this.botonSiguiente = !bolSiguiente;
      this.botonUltimo = !bolUltimo;
      this.btnBuscar = !bolBuscar;
      if (strTipo === 'NoModificar') {
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        document.getElementById('divPrincipal2').style.pointerEvents = 'none';
        this.btnBorrar = !false;
        this.btnGuardar = !false
      } else {
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        document.getElementById('divPrincipal2').style.pointerEvents = 'all';
        this.btnBorrar = !true;
      }
  } //Fin activar botones

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'actmaeactivo',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }



}
