<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="saciMaeConfig"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="saciMaeConfig" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="" [botonGuardar]="btnGuardar" [botonBorrar]="" [botonRegresar]=""
			[barraBotonesCRUD]="" [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="false"
			[barraBotones5]="true" (signal)="manejarSenales($event)">

		</app-invmaebarra>
	</div>
</div>
<p-tabView [(activeIndex)]="indexTab" (onChange)="changeTab($event)">
	<p-tabPanel header="INV">
		<p-tabView>
			<p-tabPanel header="INV-1">
				<div class="divgrups" name="divPincipalInv1">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkInv" name="GRUNIVELES" label="Grupo por niveles." binary="true"
								[(ngModel)]="chbtnGruposPorNiveles" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkInv" name="CONSERSIM"
								label="Considerar Serie y Sim en Entradas y Salidas de artículos" binary="true"
								[(ngModel)]="chbtnConSerSim" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="ATMCUEART"
										label="Asignar automáticamente los códigos de la cuentas contables en el maestro de artículos."
										binary="true" [(ngModel)]="chbtnCodContArticulos"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="CENCOSES"
										label="Considerar Centro de Costos en Entradas/Salidas." binary="true"
										[(ngModel)]="chbtnCenCoses" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="TRAINVNEG"
										label="Trabajar con inventario negativo." binary="true"
										[(ngModel)]="chbtnTrabajarInvNeg" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="MULNUMTRA"
										label="Múltiples Numeraciones en transferencias." binary="true"
										[(ngModel)]="chbtnMulNumTra" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="INVNEG_SA"
										label="Permitir realizar salidas sin verificar el inventario." binary="true"
										[(ngModel)]="chbtnInvNegSa" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="COSTUTIL"
										label="Calcular el precio utilizando costos y utilidad." binary="true"
										[(ngModel)]="chbtnCosTuTil" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="PREART"
										label="Pedir al menos un Precio en el Maestro de Artículos." binary="true"
										[(ngModel)]="chbtnPrecioArt" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="PROVTRF"
										label="Código de Proveedor en Transferencias." binary="true"
										[(ngModel)]="chbtnProvTrf" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="INVASIAUT"
										label="Entradas/Salidas: Asiento Contable Automático con el ingreso de la cuenta contable por artículo."
										binary="true" [(ngModel)]="chbtnInvAsiAut"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="PREINCIVAE"
										label="Visualizar los precios incluidos el iva en la opción Consultar existencias."
										binary="true" [(ngModel)]="chbtnPREINCIVAE"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="INVMCSA"
										label="Salidas: Modifica Costo de Salida con Clave." binary="true"
										[(ngModel)]="chbtnInvMcSa" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="NORECES"
										label="No Recalcular Costos de Entradas/Salidas." binary="true"
										[(ngModel)]="chbtnNoReces" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="PROXPROC"
										label="Considerar tipo de producción por procesos en las Ordenes de Producción."
										binary="true" [(ngModel)]="chbtnProxProc"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="PORCTAORD"
										label="Considerar Porcentajes de Distribución en entradas y salidas."
										binary="true" [(ngModel)]="chbtnPorDisES"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="INVCTACADI"
										label="Cuenta Contable para Ventas y Descuento Adicional." binary="true"
										[(ngModel)]="chbtnInvCtaCaDi" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="CTNORDSAL"
										label="Considerar cuentas de Orden 1 y Cuentas de Orden 2 en asiento de salidas de inventario."
										binary="true" [(ngModel)]="chbtnCTNORDSAL"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-3">
									<span>Número decimales en el precio de los artículos: </span>
								</div>
								<div class="p-col-1">
									<input class="txtInv" name="NUMDPREART" id="numDecPreArt" type="text" pInputText
										maxlength="1" [(ngModel)]="txtNumPreArt" (input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-2">
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="PEDPESCAN"
										label="Pedidos: Desplegar el peso del artículo como cantidad." binary="true"
										[(ngModel)]="chbtnPEDPESCAN" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-3">
									<span>Número decimales en el costo promedio: </span>
								</div>
								<div class="p-col-1">
									<input class="txtInv" name="NUMDECCP" id="numDecCostoProm" type="text" pInputText
										maxlength="1" [(ngModel)]="txtNumDecCp" (input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-2">
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="MOSHISPRE"
										label="Mostrar historico de precios por Campaña." binary="true"
										[(ngModel)]="ChkMOSHISPRE" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>Número caracteres Serie: </span>
								</div>
								<div class="p-col-1">
									<input class="txtInv" name="NUMCARSER" id="numCaractSerie" type="text" pInputText
										maxlength="2" [(ngModel)]="txtNumCarSer" (input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-3">
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="HABGREES"
										label="Entradas: Permitir seleccionar la Guía de Remisión." binary="true"
										[(ngModel)]="chkHABGREES" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>Número caracteres Sim: </span>
								</div>
								<div class="p-col-1">
									<input class="txtInv" name="CONSERSIM" id="numCaractSim" type="text" pInputText
										maxlength="2" [(ngModel)]="txtNumCarSim" (input)="activarBotonGuardar($event)">
								</div>
								<!-- CDPJ -->
								<div class="p-col-3">
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="INVHUC"
										label="Inventario: Habilitar edición de la unidad de costeo del artículo" binary="true"
										[(ngModel)]="chkINVHUC" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<!-- CDPJ -->
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="INV-2">
				<div class="divgrups" name="divPincipalInv2">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkInv" name="LOTENTSAL" label="Habilitar Número de lote en Salidas."
								binary="true" [(ngModel)]="chbtnLOTENTSAL" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkInv" name="CODAUTGRU"
								label="Generar el código automático en función del grupo del artículo." binary="true"
								[(ngModel)]="chbtnCODAUTGRU" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Campaña: </span>
								</div>
								<div class="p-col-1">
									<input class="txtInv" name="CODCAMVEN" id="campania" type="text" pInputText
										maxlength="20" [(ngModel)]="txtCODCAMVEN" (input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-4">
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="FACCANUND"
										label="Activar ingreso de cantidad en unidades." binary="true"
										[(ngModel)]="chbtnFACCANUND" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="FOCNUELIN"
										label="Saltar el foco a una nueva línea luego de ingresar el artículo en Entradas"
										binary="true" [(ngModel)]="chbtnFOCNUELIN"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="UNIBODDET" label="Manejar única bodega en detalle."
										binary="true" [(ngModel)]="chbtnUNIBODDET"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="DESCTASUB"
										label="Desplegar las cuentas contables  del subgrupo en el maestro de articulos."
										binary="true" [(ngModel)]="chbtnDESCTASUB"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="PERELISER"
										label="Permitir en exclusividad la eliminación de seriales." binary="true"
										[(ngModel)]="chbtnPERELISER" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="LOTEADOC"
										label="Salidas: Asignar el código del lote en la columna documento."
										binary="true" [(ngModel)]="chbtnLOTEADOC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="INVCAJFRA"
										label="Considerar la cantidad del inventario en cajas y fracciones(Unidad de costeo Fracciones)."
										binary="true" [(ngModel)]="chbtnINVCAJFRA"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="HABCLISAL"
										label="Salidas: Habilitar la columna del código del cliente." binary="true"
										[(ngModel)]="chbtnHABCLISAL" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="INVFRACAJ"
										label="Considerar la cantidad del inventario en cajas y fracciones(Unidad de costeo Cajas)"
										binary="true" [(ngModel)]="chbtnINVFRACAJ"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="INSPREXPTO"
										label="Salidas: Permitir insertar los premios por puntos." binary="true"
										[(ngModel)]="chbtnINSPREXPTO" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="FUNLAB" label="Funcionamiento de laboratorio."
										binary="true" [(ngModel)]="chbtnFUNLAB"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="INSFAC"
										label="Salidas: Permitir insertar la factura del cliente sin afectar al kardex."
										binary="true" [(ngModel)]="chbtnINSFAC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="FUNFAR" label="Funcionamiento Farmacia."
										binary="true" [(ngModel)]="chbtnFUNFAR"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="LEECBA"
										label="Transferencias: Permitir leer el código de barras al insertar artículos."
										binary="true" [(ngModel)]="chbtnLEECBA"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="LOTINV" label="Activar Lotes." binary="true"
										[(ngModel)]="chbtnLOTINV" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="SALCAMEST"
										label="Salidas: Considerar el campo estado." binary="true"
										[(ngModel)]="chbtnSALCAMEST" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="FPLOTSIM"
										label="Activar Lotes independientes de farmacia." binary="true"
										[(ngModel)]="chbtnFPLOTSIM" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="VISCOSADM"
										label="Visualizar los costos de artículos solo a usuarios administradores."
										binary="true" [(ngModel)]="chbtnVISCOSADM"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="ORDPROENT"
										label="Entradas: Ingresar orden de producción." binary="true"
										[(ngModel)]="chbtnORDPROENT" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="DESESPEK"
										label="Descripción especial de etiquetas en maestro de artículos." binary="true"
										[(ngModel)]="chbtnDESESPEK" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="CALVOLDEN" label="Calcular volumen y densidad."
										binary="true" [(ngModel)]="chbtnCALVOLDEN"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="NOCOMPEXT"
										label="No tomar en cuenta los componentes en la verificación de la existencia."
										binary="true" [(ngModel)]="chbtnNOCOMPEXT"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="COSTRFINV"
										label="No visualizar el costo de las transferencias." binary="true"
										[(ngModel)]="chbtnCOSTRFINV" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="SALCUR"
										label="Precios: Saltar el cursor a una nueva línea en cantidad final."
										binary="true" [(ngModel)]="chbtnSALCUR"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="PORUTIPC"
										label="Calcular el porcentaje de utilidad en el listado de precios si modifica Precio o el Costo."
										binary="true" [(ngModel)]="chbtnPORUTIPC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="NODEEXNEG"
										label="No desplegar los artículos que no tienen existencias al consultar las existencias."
										binary="true" [(ngModel)]="chbtnNODEEXNEG"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-2">
									<span>Días de validez de las reservas: </span>
								</div>
								<div class="p-col-1">
									<input class="txtInv" name="DIASVALRES" id="diasreserva" type="text" pInputText
										maxlength="2" [(ngModel)]="txtDIASVALRES" (input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="INV-3">
				<div class="divgrups" name="divPincipalInv3">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkInv" name="CONCOSFEC"
								label="Considerar costo promedio desde (dd/mm/yyyy)" binary="true"
								[(ngModel)]="chbtnCONCOSFEC" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-1">
							<p-calendar [(ngModel)]="dateTimeCONCOSFEC" dateFormat="dd/mm/yy"
								[inputStyle]="{'width': '100px'}" inputId="calendarCONCOSFEC" class="dateInv"
								name="CONCOSFEC" (ngModelChange)="activarBotonGuardar($event)"></p-calendar>
						</div>
						<!-- CDPJ -->
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="CCPIES"
										label="Considerar Centro de Costos por ítem en Entradas y Salidas" binary="true"
										[(ngModel)]="chkCCPIES" (onChange)="activarBotonGuardar($event)"></p-checkbox>
									</div>	
									<div class="p-col-6">
										<p-checkbox class="chkInv" name="CBODUSCE"
											label="Considerar bodega del usuario en consultar existencias" binary="true"
											[(ngModel)]="chkCBODUSCE" (onChange)="activarBotonGuardar($event)"></p-checkbox>
										</div>
							</div>
				</div>
						
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="CUMBCE"
										label="Considerar la ubicación del maestro de Bodega en el detalle de consultar existencias"
										binary="true" [(ngModel)]="chbtnCUMBCE"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>

								<!-- GSRF -->
								<div class="p-col-6">
									<p-checkbox class="chkInv" name="HORTRANS"
									label="Registrar la hora del documento en la transferencia del kardex"
									binary="true" [(ngModel)]="chbtnHORTRANS"
									(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<!-- GSRF -->
							</div>
						</div>	
					<!-- CDPJ -->
						</div>					
				</div>
			</p-tabPanel>
		</p-tabView>
	</p-tabPanel>
	<p-tabPanel header="COM">
		<p-tabView>
			<p-tabPanel header="C1-Compras">
				<div class="divgrups" name="divPincipalCom1">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCom" name="PROCOM" label="Ordenes de compra." binary="true"
								[(ngModel)]="chbtnOrdCompra" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkCom" name="ACTPORIVA"
								label="Factura Proveedor: Activar el porcentaje del Iva para las cuentas contables"
								binary="true" [(ngModel)]="chbtnActPorIva" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>

						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="PROCOM" label="Notas de recepción." binary="true"
										[(ngModel)]="chbtnNotRec" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="INGTOTRETC"
										label="Retenciones: Ingresar el total retenido en lugar de la base retenida"
										binary="true" [(ngModel)]="chbtnIngTotRetC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="PROCOM" label="Facturas a proveedores."
										binary="true" [(ngModel)]="chbtnFacAproveedores"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="ICEBASEIVA"
										label="Valor del ICE forma parte de la base imponible del IVA." binary="true"
										[(ngModel)]="chbtnIceBaseIva" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="DSCARTCOM"
										label="Considerar tres descuentos por cada Artículo en la línea de detalle."
										binary="true" [(ngModel)]="chbtnDescArtDet"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="CENCOSDET"
										label="Considerar Centro de Costos por línea de detalle en la factura del proveedor y devoluciones de proveedores"
										binary="true" [(ngModel)]="chbtnCenCosDet"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="DESPULCOSC"
										label="Desplegar último costo de compra al ingresar el producto en la Factura del Proveedor."
										binary="true" [(ngModel)]="chbtnDesUltCostoCompra"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="PRECIOFOB" label="Considerar Precio FOB"
										binary="true" [(ngModel)]="chbtnPrecioFob"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="DSPUCOSNR"
										label="Desplegar último costo de compra al ingresar el producto en la Nota de Recepción."
										binary="true" [(ngModel)]="chbtnDesUltCostoComNotRec"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="CENCOSCAR"
										label="Considerar Centro de Costos por cada cargo ingresado en la factura"
										binary="true" [(ngModel)]="chbtnCenCosCarg"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="DSPUCOSOC"
										label="Desplegar último costo de compra al ingresar el producto en la Orden de Compra."
										binary="true" [(ngModel)]="chbtnDesUltCostoComOC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NCAAPD"
										label="Considerar Notas de Crédito de años anteriores en Pago Documentos"
										binary="true" [(ngModel)]="chbtnNcAnioAntPagDoc"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="CONFACFREC"
										label="Contabilizar Factura de proveedores con Fecha de recepción."
										binary="true" [(ngModel)]="chbtnContFacProveeFecRec"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="PERMODNR"
										label="Permitir Modificar Notas de Recepción de Años anteriores sin afectar al inventario"
										binary="true" [(ngModel)]="chbtnPerModNR"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="SECNOTREC"
										label="Secuencial para los Comprobantes de Nota de Recepción" binary="true"
										[(ngModel)]="chbtnSecNotRec" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="RETPROV"
										label="Considerar retenciones en el maestro de proveedores" binary="true"
										[(ngModel)]="chbtnRetProv" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="SECORDCOM"
										label="Secuencial para los Comprobantes de Ordenes de Compra " binary="true"
										[(ngModel)]="chbtnSecOrdCom" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="BLOTOTRET"
										label="Bloquear el porcentaje y el total retenido en la factura de compras"
										binary="true" [(ngModel)]="ChBtnBloTotalRet"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="CODPROV" label="Código del Proveedor Automático"
										binary="true" [(ngModel)]="chbtnCodProv"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="RUCDUPPRO"
										label="No permitir duplicar el Ruc del proveedor" binary="true"
										[(ngModel)]="chbtnrucduppro" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="IVANR"
										label="No considerar el Iva en las Notas de Recepción" binary="true"
										[(ngModel)]="chbtnIvaNotaRec" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="SALCM"
										label="Controlar los saldos de los items de OC y NR por cantidad(A) y montos(S, C)"
										binary="true" [(ngModel)]="ChBtnSALCM" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="ICEDESC"
										label="Cálculo de la Base del ICE en compras sin que afecte el descuento"
										binary="true" [(ngModel)]="chbtnIceDesc"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NRFACTORNR"
										label="Nota de Recepción: Considerar un Factor por línea de detalle"
										binary="true" [(ngModel)]="chbtnNrFactorNotRec"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="C2-Compras">
				<div class="divgrups" name="divPincipalCom2">
					<div class="p-grid">
						<div class="p-col-3">
							<p-checkbox class="chkCom" name="NUMRETAUT" label="Numeración de retenciones automáticas:"
								binary="true" [(ngModel)]="chbtnNumRetAut" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-1">
							<input class="txtCom" name="CODRET1" id="txtRetencion1" type="text" pInputText
								maxlength="10" [(ngModel)]="txtRetencion1" (input)="activarBotonGuardar($event)">
						</div>
						<div class="p-col-1">
							<input class="txtCom" name="CODRET2" id="txtRetencion2" type="text" pInputText
								maxlength="10" [(ngModel)]="txtRetencion2" (input)="activarBotonGuardar($event)">
						</div>
						<div class="p-col-1">
							<input class="txtCom" name="CODRET3" id="txtRetencion3" type="text" pInputText
								maxlength="10" [(ngModel)]="txtRetencion3" (input)="activarBotonGuardar($event)">
						</div>

						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-3">
								</div>
								<div class="p-col-1">
									<input class="txtCom" name="CODRET4" id="txtRetencion4" type="text" pInputText
										maxlength="10" [(ngModel)]="txtRetencion4"
										(input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-1">
									<input class="txtCom" name="CODRET5" id="txtRetencion5" type="text" pInputText
										maxlength="10" [(ngModel)]="txtRetencion5"
										(input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-1">
									<input class="txtCom" name="CODRET6" id="txtRetencion6" type="text" pInputText
										maxlength="10" [(ngModel)]="txtRetencion6"
										(input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="VALDATSRI"
										label="Verificar que los datos correspodientes a los Anexos en Compras esten ingresados"
										binary="true" [(ngModel)]="chbtnValidaTSri"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="RETFECEMI"
										label="Registrar la retención con la fecha de Emisión de la factura"
										binary="true" [(ngModel)]="chBtnRETFECEMI"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-4">
									<span>Iniciar el Código del proveedor con la siguiente Inicial (Módulo de Balanzas):
									</span>
								</div>
								<div class="p-col-1">
									<input class="txtCom" name="INIPRO" id="txtIniPro" type="text" pInputText
										maxlength="2" [(ngModel)]="txtIniPro" (input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="SECEDIOC"
										label="Habilitar el número de secuencia en la Ordenes de Compra" binary="true"
										[(ngModel)]="chBtnSECEDIOC" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="GASTOSCC"
										label="Distribuir servicios de compras por centro de costos" binary="true"
										[(ngModel)]="chbtnGastosCC" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="AUTRETBOD"
										label="Poner el número de autorización de la retención en función de la bodega"
										binary="true" [(ngModel)]="chBtnAUTRETBOD"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="AUTSRI"
										label="Controlar el número de factura de compras con la Autorización del SRI para el Proveedor"
										binary="true" [(ngModel)]="chbtnAutSri"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="CONSOLFAC"
										label="Considerar Solicitud de facturación al proveedor" binary="true"
										[(ngModel)]="chBtnCONSOLFAC" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NUMING"
										label="Habilitar el número de ingreso en la factura de proveedores"
										binary="true" [(ngModel)]="chbtnNumIng"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="RECCOSPRO"
										label="Recalcular el costo promedio al realizar una modificación en compras"
										binary="true" [(ngModel)]="chBtnRECCOSPRO"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="DESORDCOM"
										label="Controlar limite de descuento en ingreso de orden de compra"
										binary="true" [(ngModel)]="chbtnDesOrdCom"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NOMODDR"
										label="No permitir modificar la serie, autorización, y secuencia  de las retenciones de compras"
										binary="true" [(ngModel)]="chbtnNOMODDR"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NUMINGAUT"
										label="Número de ingreso de la factura de proveedores  automático" binary="true"
										[(ngModel)]="chbtnNumIngAut" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NOSALFSCC"
										label="No permitir guarda la factura si no tiene ingresado el centro de costos"
										binary="true" [(ngModel)]="chBtnNOSALFSCC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="INGRETOBL"
										label="Ingresar en la factura de proveedores al menos una retención"
										binary="true" [(ngModel)]="chBtnINGRETOBL"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="CONPRGASI"
										label="Considerar programa en Asiento FP" binary="true"
										[(ngModel)]="chBtnCONPRGASI" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="RETSINVAL"
										label="Permitir ingresar todas las retenciones sin validar el valor con los totales de la factura"
										binary="true" [(ngModel)]="chbtnRETSINVAL"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NOVAL332"
										label="No validar los totales de la retención 332 en la factura de proveedores "
										binary="true" [(ngModel)]="chbtnNOVAL332"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="MOSCOLP"
										label="Mostrar columnas adicionales para lista de precios (A,B,C) en factura de proveedores"
										binary="true" [(ngModel)]="chBtnMOSCOLP"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NUMRETSEC"
										label="Número de la retención secuencial" binary="true"
										[(ngModel)]="chbtnNUMRETSEC" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="DISCENCOS"
										label="Considerar distribución centro de costos en compras" binary="true"
										[(ngModel)]="chBtnDISCENCOS" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="CENXCTACON"
										label="Verificar el ingreso de centro de costos en función de la cuenta contable del item"
										binary="true" [(ngModel)]="chbtnCENXCTACON"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="FACTIPGAS"
										label="Considerar factura de proveedores tipo gasto" binary="true"
										[(ngModel)]="chBtnFACTIPGAS" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NUMAUTESP"
										label="Númeración de autorización por 49 dígitos" binary="true"
										[(ngModel)]="chbtnNUMAUTESP" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="C3-Compras">
				<div class="divgrups" name="divPincipalCom2">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCom" name="ACCTADC"
								label="Actualizar la cuenta contable en la Distribución de centro de costos al modificar la cuenta en el detalle de la factura"
								binary="true" [(ngModel)]="chbtnACCTADC" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkCom" name="NOTRECSIFA" label="Nota de recepción: No permitir editar si ya esta facturado los items de la nota"
								binary="true" [(ngModel)]="chkbtnNOTRECSIFA"
								(onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="VAROBLNR"
										label="Notas de Recepción: El ingreso de la pestaña varios debe de ser obligatorio."
										binary="true" [(ngModel)]="chbtnVAROBLNR"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="ADANPROFC"
										label="Agregar los datos anexos del proveedor en la pestaña de datos anexos"
										binary="true" [(ngModel)]="chbtnADANPROFC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>Código de la Farmacia:</span>
								</div>
								<div class="p-col-1">
									<input class="txtCom" name="CODFAR" id="txtCodFarmacia" type="text" pInputText
										maxlength="5" [(ngModel)]="txtCodFarmacia"
										(input)="activarBotonGuardar($event)">
								</div>

							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="FPLOTSIM" label="Manejar ingreso de lotes "
										binary="true" [(ngModel)]="chkbtnFPLOTSIM"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="CDDTIMP" label="Cambiar la descripción en el detalle de facturas importadas "
										binary="true" [(ngModel)]="chkbtnCDDTIMP"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="ACTCPAP"
										label="Actualizar el costo promedio al momento de actualizar los precios en la factura de proveedores"
										binary="true" [(ngModel)]="chkbtnACTCPAP"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NCDGASINR" label="No considerar el descuento global para el asiento en notas de recepcion"
										binary="true" [(ngModel)]="chkbtnNCDGASINR"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="RETBASAUT"
										label="Ingresar las bases de la retención automáticamente" binary="true"
										[(ngModel)]="chkbtnRETBASAUT" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="MODCODODC"
										label="Factura Proveedores: Modificar código proveniente de orden de compra"
										binary="true" [(ngModel)]="chkbtnMODCODODC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="DIVRETCOM"
										label="Considerar Dividendos en el manejo de las reteciones" binary="true"
										[(ngModel)]="chkbtnDIVRETCOM" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="MOSNUMPO" label="Mostrar número de PO"
										binary="true" [(ngModel)]="chkbtnMOSNUMPO"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkCom" name="NOCATOTFP" label="No calcular totales al mostrar la factura de proveedor"
										binary="true" [(ngModel)]="chkbtnNOCATOTFP"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-6">
									<!-- CDPJ -->
									<p-checkbox class="chkCom" name="AUCREFNT" label="Aumentar tamaño del campo referencia en notas de recepcion"
										binary="true" [(ngModel)]="chkbtnAUCREFNT"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
										<!-- CDPJ -->
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-6">
									<!-- CDPJ -->
									<p-checkbox class="chkCom" name="SCANNR" label="Solicitar clave de autorización para anular la Nota de Recepción"
										binary="true" [(ngModel)]="chkbtnSCANNR"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
										<!-- CDPJ -->
								</div>
							</div>
						</div>

						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-6">
									<!-- CDPJ -->
									<p-checkbox class="chkCom" name="CENCOSNT" label="Considerar centro de costos en Varios de Notas de recepcion"
										binary="true" [(ngModel)]="chkbtnCENCOSNT"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
									
								</div>
										<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-6">
								</div>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-6">
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
		</p-tabView>
	</p-tabPanel>
	<p-tabPanel header="VEN">
		<p-tabView>
			<p-tabPanel header="C1-Ventas">
				<div class="divgrups" name="divPincipalVen1">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkVen" name="FCEXSNEG" label="Factura con existencias negativas."
								binary="true" [(ngModel)]="chbtnFacExisNeg" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkVen" name="BUSQDESCF"
								label="Utilizar la búsqueda en la descripción del detalle de la Factura" binary="true"
								[(ngModel)]="chbtnBusqDesc" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FVFOCDET"
										label="Foco luego de ingresar una linea detalle (código) en  nueva línea de detalle."
										binary="true" [(ngModel)]="chbtnFvFocDet"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DESGLOFP"
										label="Descuento global automático en pedidos y facturas  de acuerdo al maestro de clientes "
										binary="true" [(ngModel)]="chbtnDESGLOFP"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FCCOBDIR" label="Hacer cobros directos."
										binary="true" [(ngModel)]="chbtnFcCobDir"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6" [hidden]="true">
									<p-checkbox class="chkVen" name="DVFSELFI"
										label="Devolución: Seleccionar Facturas o Ingresos de Mantenimiento Técnico"
										binary="true" [(ngModel)]="chbtnDevSelecFacIng"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="AUTFCVEN"
										label="Autorizar crédito, si excede el límite del crédito." binary="true"
										[(ngModel)]="chbtnAutFcVen" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DESNROFAC" label="Nro. Factura deshabilitado."
										binary="true" [(ngModel)]="chbtnDesNroFac"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ASIPDFECSI"
										label="Pago Directo: Generar el Asiento del pago directo con Fecha del Documento de Venta."
										binary="true" [(ngModel)]="chbtnFechaAsiPagoDir"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FCPROMUNA"
										label="Restringir a una promoción por Artículo o Servicio." binary="true"
										[(ngModel)]="chbtnFcPromoUna" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="SELTIPIVA" label="Seleccionar tipo de Iva."
										binary="true" [(ngModel)]="chbtnSeleccTipoIva"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DVFSELFPND"
										label="Devolución: Seleccionar  Sólo Facturas  Pendientes" binary="true"
										[(ngModel)]="chbtnDevSelecFacPend" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="STIPIVACLI"
										label="Seleccionar tipo de Iva de acuerdo al Cliente." binary="true"
										[(ngModel)]="chbtnSeleccTipoIvaCli" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="IMPFCSFP"
										label="Imprimir Factura sin registrar la forma de pago" binary="true"
										[(ngModel)]="chbtnIngImpFcSinFPago" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOMODBODFA"
										label="No modificar la Bodega en los Renglones de la Factura." binary="true"
										[(ngModel)]="chbtnNoModBodDetFac" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOELIMFACI"
										label="No permitir eliminar la factura luego  de imprimir." binary="true"
										[(ngModel)]="chbtnNoElimFacImp" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CANTFISICA"
										label="Ver Cantidad Física por Item de Factura." binary="true"
										[(ngModel)]="chbtnFcPreciCantFisicaDetFac"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOMODVENFA"
										label="No modificar el código del vendedor." binary="true"
										[(ngModel)]="chbtnNoModVenFac" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-2">
									<span>Número de items en la factura:</span>
								</div>
								<div class="p-col-1">
									<input class="txtVen" name="NUMITEMFAC" id="txtNumItemsFac" type="text" pInputText
										maxlength="10" [(ngModel)]="txtNumItemsFac"
										(input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DESCLINAUT"
										label="Aplicar automáticamente el descuento del cliente por línea."
										binary="true" [(ngModel)]="chbtnDsctoLineaAutom"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="BODDEF"
										label="Devolución: Considerar la Bodega asignada por defecto en el maestro de Usuarios"
										binary="true" [(ngModel)]="chbtnBodDef"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="BLOQFACIMP"
										label="Bloquear la factura luego de impresa." binary="true"
										[(ngModel)]="chbtnBloqFacImp" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DESICE"
										label="Desgloce del ICE en los Documentos de Ventas" binary="true"
										[(ngModel)]="chbtnDesIce" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FCPRINIVA"
										label="Precios de los artículos incluyen I.V.A" binary="true"
										[(ngModel)]="chbtnPrecIncIva" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FCPRSERIV"
										label="Precios de los servicios incluyen I.V.A" binary="true"
										[(ngModel)]="chbtnPrSerIncIva" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="C2-Ventas">
				<div class="divgrups" name="divPincipalVen2">
					<div class="p-grid">
						<div class="p-col-6">
							<span style="font-weight: bold">Clientes</span>
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ATMCODCLI"
										label="Código del cliente automático (Código Numérico)" binary="true"
										[(ngModel)]="chbtnCodCliente" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="BODDEFUSU"
										label="Considerar Bodegas por defecto las registradas en el mantenimiento de Usuarios"
										binary="true" [(ngModel)]="chbtnBodDefectoUsu"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-3">
									<p-checkbox class="chkVen" name="CODCLIALF"
										label="Código del cliente automático (Código Alfanumérico)" binary="true"
										[(ngModel)]="chbtnCodCliAlf" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-1">
									<input class="txtVen" name="INICODCLI" id="txtIniCodCli" type="text" pInputText
										maxlength="14" [(ngModel)]="txtIniCodCli" (input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-2">
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CRESERMAE"
										label="Crear Servicios al momento de facturar" binary="true"
										[(ngModel)]="chbtnCreaServMae" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ATMCUECLI"
										label="Asignar automáticamente los códigos de la cuentas contables en el maestro de clientes."
										binary="true" [(ngModel)]="chbtnCodContClientes"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CONCODCLI"
										label="Consulta del Código del Cliente directa" binary="true"
										[(ngModel)]="chbtnConCodCli" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CAMBIARCF"
										label="Permitir Cambiar Datos de Cliente Final en la Factura de Ventas"
										binary="true" [(ngModel)]="chbtnCambiarCliFin"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOICEFVTA"
										label="No considerar Ice en facturas de ventas si se encuentra considerado en el maestro de artículos"
										binary="true" [(ngModel)]="chbtnNoIceFacVta"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>Código Cliente Consumidor final:</span>
								</div>
								<div class="p-col-1">
									<input class="txtVen" name="CODCONFIN" id="txtCodConFinal" type="text" pInputText
										maxlength="13" [(ngModel)]="txtCodConFinal"
										(input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-1">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												class="littlebuttons" (click)="buscarCliente()"></button>
										</div>
										<div class="p-col-11">
											<span style="font-weight: bold">{{lblCliente}}</span>
										</div>
									</div>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOBUSARTF"
										label="No permitir buscar artículos al facturar" binary="true"
										[(ngModel)]="chbtnNoBusArtF" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -17px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>Grupo del cliente de Consumidor final:</span>
								</div>
								<div class="p-col-1">
									<input class="txtVen" name="GRUPOCF" id="txtGrupoConsumFinal" type="text" pInputText
										maxlength="13" [(ngModel)]="txtGrupoConsumFinal"
										(input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-1">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												class="littlebuttons" (click)="buscarGrpCliente()"></button>
										</div>
										<div class="p-col-11">
											<span style="font-weight: bold">{{lblGrpCliente}}</span>
										</div>
									</div>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CODBARFAC"
										label="Ingresar el código de barras en lugar del código del artículo en el detalle de la factura"
										binary="true" (onChange)="activarBotonGuardar($event)" [(ngModel)]="chbtnCodBarFac"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NODUPRUC"
										label="No permitir duplicar el Ruc del cliente" binary="true"
										[(ngModel)]="chbtnNoDupRuc" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="GENTRNPED"
										label="Generar transacciones de Caja en Pedidos" binary="true"
										[(ngModel)]="chbtnGeneTransacPedidos" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CONSIDNV"
										label="En la factura considerar Notas de Venta" binary="true"
										[(ngModel)]="chbtnConsideraNotVta" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="VENFIJPAG"
										label="Ingreso de la caja y la bodega al inicio de la facturación" binary="true"
										[(ngModel)]="chbtnVenFijPag" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NVNOIVA"
										label="Al seleccionar Nota de Venta la factura  no tributa Iva" binary="true"
										[(ngModel)]="chbtnNotVtaNoIva" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CCMANG"
										label="Centro de costos mandatorio en Guías de Resmisión" binary="true"
										[(ngModel)]="chbtnCCMANG" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CALCMAXCLI"
										label="Calcular el Código del Cliente con  Secuencia Automática" binary="true"
										[(ngModel)]="chbtnCalMaxCli" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<!-- CDPJ -->
							<div class="p-col-6">
								<p-checkbox class="chkVen" name="BLFHFAC"
									label="Bloquear fecha y hora en el encabezado de facturas" binary="true"
									[(ngModel)]="chbtnBLFHFAC" (onChange)="activarBotonGuardar($event)">
								</p-checkbox>
							</div>
							<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="MARPREBCOS"
										label="Marcar los precios que estan bajo el costo" binary="true"
										[(ngModel)]="chbtnMarPrecBajoCosto" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ICEBIVAV"
										label="Valor del ICE forma parte de la base imponible del IVA." binary="true"
										[(ngModel)]="chbtnIceBajoIvaV" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CTRLESCLIV"
										label="Controlar el Estado del Cliente." binary="true"
										[(ngModel)]="chbtnCtrlEstadoCliV" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="C3-Ventas">
				<div class="divgrups" name="divPincipalVen3">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Etiqueta del Campo Vendedor:</span>
						</div>
						<div class="p-col-1">
							<input class="txtVen" name="ETIVEND" id="txtEtiquetaVendedor" type="text" pInputText
								maxlength="14" [(ngModel)]="txtEtiquetaVendedor" (input)="activarBotonGuardar($event)">
						</div>
						<div class="p-col-3">
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkVen" name="INGRAPFAC"
								label="Habilitar el ingreso rápido en la factura de ventas" binary="true"
								[(ngModel)]="chkbtnINGRAPFAC" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DESMAXCLI"
										label="Controlar Descuento Máximo por Cliente en la factura de ventas"
										binary="true" [(ngModel)]="chbtnDesMaxCli"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CONFINAUT"
										label="Desplegar el Código del Cliente del Comsumidor final automáticamente"
										binary="true" [(ngModel)]="chkbtnCONFINAUT"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="LISTPREC"
										label="Seleccionar lista de precios en el detalle de la factura de ventas"
										binary="true" [(ngModel)]="chbtnLisPrec"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="GUASINFP"
										label="Permitir guardar la factura sin forma de pago" binary="true"
										[(ngModel)]="chkbtnGUASINFP" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FACTIPCLI"
										label="Controlar la facturación de acuerdo al tipo de cliente" binary="true"
										[(ngModel)]="chbtnFacTipoCli" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="GUIRENDE"
										label="Habilitar la guía de remisión como un despacho especial" binary="true"
										[(ngModel)]="chkbtnGUIRENDE" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="VALPORVEN"
										label="Valida el porcentaje de comisión de Vendedores que no sobrepase el 100%"
										binary="true" [(ngModel)]="chbtnValPorVen"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name=""
										label="Habilitar las retenciones en cobro directo luego de pagada la factura"
										binary="true" [(ngModel)]="chkbtnNBFPIMPFA_1"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FACCC"
										label="Factura y Devolución de Ventas con Centro de Costos por Línea de Detalle"
										binary="true" [(ngModel)]="chbtnFacCentroCostos"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="INGCODALT"
										label="Pedidos: Permitir localizar al código del artículo a través del ingreso del código alternativo de campaña"
										binary="true" [(ngModel)]="chkbtnINGCODALT"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PRECUNI"
										label="Calcular el precio unitario al facturar " binary="true"
										[(ngModel)]="chbtnCtrlPrecUni" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="REFLOCPED"
										label="Facturación: Habilitar el campo de referencia para localizar a un pedido"
										binary="true" [(ngModel)]="chbtnREFLOCPED"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NUMGUAFAC"
										label="Generar el número de la factura al momento de guardar la factura"
										binary="true" [(ngModel)]="chbtnGenNumFact"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PUNVTAS"
										label="Aplicar Puntos en factura de ventas" binary="true"
										[(ngModel)]="chbtnPUNVTAS" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="GREBULTO"
										label="Manejar Bultos y Cantidad en Guías de Remisión" binary="true"
										[(ngModel)]="chbtnManBultGRem" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PREMAECLI"
										label="Considerar el precio del maestro de articulos (ASFALTO)" binary="true"
										[(ngModel)]="chbtnPREMAECLI" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ACTDOCFAC"
										label="Activar Documentación en la Factura" binary="true"
										[(ngModel)]="chbtnActDocFact" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PEDFOCOCL"
										label="Pedidos: Foco iniciar en el código del cliente" binary="true"
										[(ngModel)]="chbtnPEDFOCOCL" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ASIGUIAUT"
										label="Asiento de Guías de Remisión Automático" binary="true"
										[(ngModel)]="chkbtnAsiGuiAut" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PEDEXTNEG"
										label="Pedidos: Permitir guardar el pedido con existencia en negativo"
										binary="true" [(ngModel)]="chbtnPEDEXTNEG"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FOCONLPED"
										label="Pedidos: Foco luego de ingresar una linea detalle (código) en  nueva línea de detalle."
										binary="true" [(ngModel)]="chkbtnFOCONLPED"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FOCCANLIN"
										label="Foco luego de ingresar la cantidad saltar a la sig. línea" binary="true"
										[(ngModel)]="chbtnFOCCANLIN" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="GRAPAGCRE"
										label="Al grabar la factura asignar como forma de pago crédito" binary="true"
										[(ngModel)]="chkbtnGRAPAGCRE" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CODVENAUT"
										label="Vendedor: Código del vendedor secuencial automático" binary="true"
										[(ngModel)]="chkbtnCODVENAUT" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NBFPIMPFA"
										label="No bloquear la forma de pago luego de imprimir la factura" binary="true"
										[(ngModel)]="chkbtnNBFPIMPFA" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-1">
									<span>Dólares por punto:</span>
								</div>
								<div class="p-col-1">
									<input class="txtVen" name="DOLXPUNTO" id="txtDOLXPUNTO" type="text" pInputText
										maxlength="14" [(ngModel)]="txtDOLXPUNTO" (input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DSCPED" label="Aplicar descuentos en pedidos"
										binary="true" [(ngModel)]="chbtnDSCPED"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOPEDCERO" label="No permitir items con precio cero en pedidos"
										binary="true" [(ngModel)]="chkbtnNOPEDCERO"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="C4-Ventas">
				<div class="divgrups" name="divPincipalVen4">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkVen" name="NOGSCC"
								label="No permitir guardar la factura  si no tiene ingresado el centro de costos"
								binary="true" [(ngModel)]="chbthNOGSCC" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkVen" name="GENCUOAUT"
								label="Generar las cuotas automáticamente al momento de seleccionar forma de pago cuotas"
								binary="true" [(ngModel)]="chbtnGENCUOAUT" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="RESARTPED"
										label="Pedido: Reservar mercadería en el pedido" binary="true"
										[(ngModel)]="chbtnRESARTPED" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="RESPEDBOD"
										label="Reservar mercadería en el pedido por bodega " binary="true"
										[(ngModel)]="chbtnRESPEDBOD" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="BODDEFPED"
										label="Pedido:Considerar por defecto la bodega asignada en el maestro de usuarios"
										binary="true" [(ngModel)]="chbtnBODDEFPED"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NODPDNC"
										label="No desplegar el primer caracter del numero de las Notas de Crédito"
										binary="true" [(ngModel)]="chbtnNODPDNC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="REFMAE"
										label="Factura: Leer en el campo referencia los datos del maestro de referencias"
										binary="true" [(ngModel)]="chbtnREFMAE"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<!--								<div class="p-col-6">-->
								<!--									<p-checkbox class="chkVen" name="FACTIPCLI"-->
								<!--												label="Controlar la facturación de acuerdo al tipo de cliente"-->
								<!--												binary="true" [(ngModel)]="chbtnFacTipoCli"></p-checkbox>-->
								<!--								</div>-->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ACTZONEXC" label="Activar zona de exclusión"
										binary="true" [(ngModel)]="chbtnACTZONEXC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="RESUAP"
										label="Pedido: Resumir artículos del pedido" binary="true"
										[(ngModel)]="chbtnRESUAP" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CODBARPRE"
										label="Código de barras en la lista de precios" binary="true"
										[(ngModel)]="chbtnCodBarPre" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ARTANTBOD"
										label="Ingresar el código de artículo antes de la bodega" binary="true"
										[(ngModel)]="chbtnARTANTBOD" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="VENUSU"
										label="Desplegar el código del vendedor en Pedidos y Facturas de ventas  según el usuario"
										binary="true" [(ngModel)]="chbtnVENUSU"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PEDVAL5"
										label="Pedido: Poner fecha de validez a 5 años" binary="true"
										[(ngModel)]="chbtnPEDVAL5" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ESTPEDESP"
										label="Pedidos: Considerar estados del pedido especiales" binary="true"
										[(ngModel)]="chbtnESTPEDESP" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOFACPREL"
										label="No permitir facturar por debajo del precio de la lista de precios"
										binary="true" [(ngModel)]="chbtnNOFACPREL"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="BUSEXTBOD"
										label="Búsqueda de artículos agregando existencia por bodega" binary="true"
										[(ngModel)]="chbtnBUSEXTBOD" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="MULVERVEN"
										label="Considerar múltiplo para verificar la cantidad a vender" binary="true"
										[(ngModel)]="chbtnMULVERVEN" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DIRCLIGR"
										label="Guías de Remisión: Desplegar en Destino la dirección del cliente"
										binary="true" [(ngModel)]="chbtnDIRCLIGR"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FACRESCLI"
										label="En la factura de ventas utilizar la pantalla resumida de clientes"
										binary="true" [(ngModel)]="chbtnFACRESCLI"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PORSEG"
										label="Factura: Considerar porcentaje   de seguro" binary="true"
										[(ngModel)]="chbtnPORSEG" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOASIFAC"
										label="No generar el asiento de la factura de ventas en forma automática"
										binary="true" [(ngModel)]="chbtnNOASIFAC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-2">
									<span>Porcentaje de Seguro:</span>
								</div>
								<div class="p-col-1">
									<input class="txtVen" name="PORSEGVTA" id="txtPORSEGVTA" type="text" pInputText
										maxlength="10" [(ngModel)]="txtPORSEGVTA" (input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FACLIGREQ"
										label="Todas las facturas de ventas tienen que estar ligadas a una requisición"
										binary="true" [(ngModel)]="chbtnFACLIGREQ"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-2">
									<span>Servicio Seguro:</span>
								</div>
								<div class="p-col-1">
									<input class="txtVen" name="SERSEGVTA" id="txtSERSEGVTA" type="text" pInputText
										maxlength="20" [(ngModel)]="txtSERSEGVTA" (input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FACNOAIMP"
										label="Las facturas por convenio y asignación por convenio central no tiene que afectar a los impuestos"
										binary="true" [(ngModel)]="chbtnFACNOAIMP"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-3">
									<span>Límite de facturación del consumidor final:</span>
								</div>
								<div class="p-col-1">
									<input class="txtVen" name="LIMCONFIN" id="txtLIMCONFIN" type="text" pInputText
										maxlength="10" [(ngModel)]="txtLIMCONFIN" (input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PREBAJCOM"
										label="Marcar los precios que estan bajo el costo de compra" binary="true"
										[(ngModel)]="chbtnPREBAJCOM" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ACTCOMFE"
										label="Activar Comentarios en la factura electrónica" binary="true"
										[(ngModel)]="chbtnACTCOMFE" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="REFFACNUM"
										label="Considerar la referencia en factura de Ventas con tipo de datos númerico"
										binary="true" [(ngModel)]="chbtnREFFACNUM"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="COMNUMPED"
										label="Al agregar pedido, no mostrar como comentario el número del pedido"
										binary="true" [(ngModel)]="chbtnCOMNUMPED"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="C5-Ventas">
				<div class="divgrups" name="divPincipalVen5">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkVen" name="COMFPELE"
								label="Activar Compensación en forma de pago electrónica" binary="true"
								[(ngModel)]="chbtnCOMFPELE" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkVen" name="USURAZELI"
								label="Ingresar usuario y razón al eliminar transacciones" binary="true"
								[(ngModel)]="chbtnUSURAZELI" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DETFACLOT"
										label="Aplicar los componentes como comentario en el detalle de la factura"
										binary="true" [(ngModel)]="chbtnDETFACLOT"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOMCLIOBS"
										label="Desplegar el nombre del cliente en las observaciones de la factura"
										binary="true" [(ngModel)]="chbtnNOMCLIOBS"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CAJXVEN"
										label="Considerar la caja de acuerdo al vendedor" binary="true"
										[(ngModel)]="chbtnCAJXVEN" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FECINTIF"
										label="Pedido:Fecha entrega independiente de la factura" binary="true"
										[(ngModel)]="chbtnFECINTIF" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NUMPTOUSU"
										label="Considerar las numeraciones por punto de venta de acuerdo al usuario"
										binary="true" [(ngModel)]="chbtnNUMPTOUSU"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOFCCERO"
										label="No permitir facturar con valor cero" binary="true"
										[(ngModel)]="chbtnNOFCCERO" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NUMPORVEN"
										label="Numeración por vendedor en facturas automáticas" binary="true"
										[(ngModel)]="chbtNUMPORVEN" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="LOTCARCF"
										label="Lote y caract. de pedido como comentario y datos Adicionales."
										binary="true" [(ngModel)]="chbtnLOTCARCF"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ABOPD"
										label="Permitir realizar un abono por pago directo en factura de ventas"
										binary="true" [(ngModel)]="chbtnABOPD" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOTMFACCN"
										label="Factura: Notificación Masiva de Factura Comercial Negociable"
										binary="true" [(ngModel)]="chbtnNOTMFACCN"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="AUTGUAFAC"
										label="Generar la clave de autorización de la factura luego  de guardar"
										binary="true" [(ngModel)]="chbtnAUTGUAFAC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOMCLICON"
										label="Transf. Pedidos a Facturas. Utilizar nombre de contacto" binary="true"
										[(ngModel)]="chbtnNOMCLICON" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CENCOSBOD"
										label="Facturas: Ingresar Centro de Costos de acuerdo a la Bodega" binary="true"
										[(ngModel)]="chbtnCENCOSBOD" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="TRAPEDCOM"
										label="Transf. Pedidos a Facturas. Añadir como comentario el mes de la transacción"
										binary="true" [(ngModel)]="chbtnTRAPEDCOM"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="BLOIMPFAC"
										label="Facturas: Bloquear las columnas  si aplica impuestos por línea de detalle"
										binary="true" [(ngModel)]="chbtnBLOIMPFAC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="TRPEACES"
										label="Transf. Pedidos a Facturas. Actualizar el estado del pedido"
										binary="true" [(ngModel)]="chbtnTRPEACES"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="REFLOCMED"
										label="Facturacin: Habilitar el campo de referencia para localizar a un médico"
										binary="true" [(ngModel)]="chbtnREFLOCMED"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="AGINFOADI"
										label="Transf. Pedidos a Facturas. Insertar información adicional" binary="true"
										[(ngModel)]="chbtnAGINFOADI" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-3">
									<span>Número enteros en el peso incluido en el Código de barras</span>
								</div>
								<div class="p-col-1">
									<input class="txtVen" name="NUMDECPES" id="txtNUMDECPES" type="text" pInputText
										maxlength="2147483646" [(ngModel)]="txtNUMDECPES"
										(input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-2">
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="GUINOKARD"
										label="Guardar Guía de Remisión sin actualizar kardex y sin generar asiento contable"
										binary="true" [(ngModel)]="chbtnGUINOKARD"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PERCAMSER"
										label="Cambiar la serie de la factura dependiendo del número de facturas emitidas al cliente"
										binary="true" [(ngModel)]="chbtnPERCAMSER"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CTRLDESLP"
										label="Controlar el descuento del detalle de la factura de acuerdo a la lista de precios"
										binary="true" [(ngModel)]="chbtnCTRLDESLP"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NOINSCF"
										label="No permitir insertar la cotización en la factura" binary="true"
										[(ngModel)]="chbtnNOINSCF" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-3">
									<span>Número de días de validez del pedido:</span>
								</div>
								<div class="p-col-1">
									<input class="txtVen" name="DIAVALPED" id="txtDIAVALPED" type="text" pInputText
										maxlength="2147483646" [(ngModel)]="txtDIAVALPED"
										(input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="GUANUMPED"
										label="Generar el número del pedido al momento de guardar el pedido"
										binary="true" [(ngModel)]="chbtnGUANUMPED"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="VALINGLOT" label="Validar ingreso de lotes"
										binary="true" [(ngModel)]="chbtnVALINGLOT"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="USURAZELI"
										label="Ingresar usuario y razón al eliminar transacciones" binary="true"
										[(ngModel)]="chbtnUSURAZELI" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="VALLOTART"
										label="Validar lotes con articulo y cantidad" binary="true"
										[(ngModel)]="chbtnVALLOTART" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ACTPEDVAL"
										label="Activar pedidos satisfechos por valor" binary="true"
										[(ngModel)]="chbtnACTPEDVAL" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="AGRLOTCOM"
										label="Agregar cantidad en la información de lote como comentario" binary="true"
										[(ngModel)]="chbtnAGRLOTCOM" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="C6-Ventas">
				<div class="divgrups" name="divPincipalVen6">
					<div class="p-grid">
						<div class="p-col-12" style="margin-top: 5">
							<div class="p-grid">
								<div class="p-col-6">
									<!-- CDPJ -->
									<p-checkbox class="chkVen" name="SALDP" label="Solo poder ingresar un articulo por linea de detalle en pedidos"
										binary="true" [(ngModel)]="chkbtnSALDP"
										(onChange)="activarBotonGuardar($event)">
									</p-checkbox>
									<!-- CDPJ -->
								</div>
								<div class="p-col-6">
									<!-- CDPJ -->
									<p-checkbox class="chkVen" name="VEPLDFAC" label="Verificar existencias por línea de detalle en facturas"
										binary="true" [(ngModel)]="chkbtnVEPLDFAC"
										(onChange)="activarBotonGuardar($event)">
									</p-checkbox>
									<!-- CDPJ -->
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NFNEGR"
											label="No firmar notas de entrega en guías de remisión" binary="true"
											[(ngModel)]="chbtnNFNEGR" (onChange)="activarBotonGuardar($event)">
										</p-checkbox>
								</div>
								<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CNAFEPV"
											label="Considerar las numeraciones activas para firmar electronicamente desde punto de venta" binary="true"
											[(ngModel)]="chbtnCNAFEPV" (onChange)="activarBotonGuardar($event)">
										</p-checkbox>
								</div>
								<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
										<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CCPIPED"
										label="Considerar el centro de costos de la bodega por línea en el detalle en pedidos" binary="true"
										[(ngModel)]="chbtnCCPIPED" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="BCPPG"
										label="Bloquear los campos Cantidad y Precio al atar un Pedido en Guías de Remisión" binary="true"
										[(ngModel)]="chbtnBCPPG" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
										<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
										<!-- GSRF -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PEDNOMAXEX"
										label="Pedidos: No llenar con la cantidad existente" binary="true"
										[(ngModel)]="chbtnPEDNOMAXEX" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
										<!-- GSRF -->
										<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ACCCFV"
										label="Agregar contacto de la ficha del cliente como comentario en facturas de ventas" binary="true"
										[(ngModel)]="chbtnACCCFV" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
										<!-- GSRF -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="PEDBLOBOD"
										label="Pedidos: bloquear la bodega" binary="true"
										[(ngModel)]="chbtnblobod" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div> 
										<!-- GSRF -->
								<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="NVTOREMVEN"
										label="No validar el total de los reembolsos en facturas de ventas" binary="true"
										[(ngModel)]="chbtnNVTOREMVEN" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div> 
								<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
										<!-- GSRF -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="FACBLOBVD"
										label="Factura: bloquear bodega y dias de credito" binary="true"
										[(ngModel)]="chbtnblobvd" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div> 
										<!-- GSRF -->
										<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="ARDFCG"
										label="Armar descripción de la factura con grupo" binary="true"
										[(ngModel)]="chbtnARDFCG" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div> 
								<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="CEINFOADI"
										label="Colocar etiquetas en información adicional" binary="true"
										[(ngModel)]="chbtnCEINFOADI" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div> 
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DVBUSGR"
										label="Dividir búsqueda en guías de remisión" binary="true"
										[(ngModel)]="chbtnDVBUSGR" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div> 
								<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkVen" name="DSNEBINGR"
										label="Desplegar notas de entrega en botón insertar guías de remisión" binary="true"
										[(ngModel)]="chbtnDSNEBINGR" (onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div> 
								<!-- CDPJ -->
							</div>
						</div>
					</div>
				</div>
				
			</p-tabPanel>
		</p-tabView>
	</p-tabPanel>
	<p-tabPanel header="PV">
		<p-tabView>
			<p-tabPanel header="C1-PuntoVenta">
				<div class="divgrups" name="divPincipalPV1">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkPV" name="PVFACEXNEG" label="Facturar con existencias negativas."
								binary="true" [(ngModel)]="chbtnPvFacExNeg" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkPV" name="FOCOPRE"
								label="Luego de ingresar la cantidad, ubicar el foco en en la Lista de precios"
								binary="true" [(ngModel)]="chbtnFocoPre" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVFVFOCDET"
										label="Foco luego de ingresar una linea detalle (código) en  nueva línea de detalle."
										binary="true" [(ngModel)]="chbtnPvFvFocoDet"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="NOMODVENPV"
										label="No modificar el código del vendedor." binary="true"
										[(ngModel)]="chbtnNoModifVendedorPv" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVDNROFAC" label="Nro. Factura deshabilitado."
										binary="true" [(ngModel)]="chbtnPVDeshabilitadoNroFac"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-5">
									<span>Nro. límite de cheques protestados para alertar que el cliente no puede pagar
										con cheque:</span>
								</div>
								<div class="p-col-1">
									<input class="txtPV" name="NUMCHEPRO" id="txtNroLimCheProtestado" type="text"
										pInputText maxlength="2" [(ngModel)]="txtNroLimCheProtestado"
										(input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVIMPFRFP"
										label="Imprimir Factura sin registrar la forma de pago" binary="true"
										[(ngModel)]="chbtnPvImpFacFormaPago" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-2">
									<span>Nro. de items en la factura:</span>
								</div>
								<div class="p-col-1">
									<input class="txtPV" name="NUMITEMPV" id="txtPvNumItemsFac" type="text" pInputText
										maxlength="5" [(ngModel)]="txtPvNumItemsFac"
										(input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVPROAUFAC"
										label="Promociones se agregan automáticamente al detalle de la Factura"
										binary="true" [(ngModel)]="chbtnPvPromoAutoFac"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="FACCONF"
										label="Permitir que las facturas de consumidor final queden pendientes."
										binary="true" [(ngModel)]="chbtnFacConsumFinal"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVASTFECDV"
										label="Pago Directo: Generar el Asiento del pago directo con Fecha del Documento de Venta"
										binary="true" [(ngModel)]="chbtnPvAsienFecDocVta"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="DEVFACFRAC"
										label="Activar la pantalla para el ingreso de fracciones en la devolución de ventas"
										binary="true" [(ngModel)]="chbtnDEVFACFRAC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVNELINIMP"
										label="No permitir eliminar la factura luego  de imprimir." binary="true"
										[(ngModel)]="chbtnPvNoElimFacImpr" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="FACSINKAR"
										label="Al facturar  en el Punto de venta no afectar al kardex" binary="true"
										[(ngModel)]="chbtnFacSinKar" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="FACNOPCER"
										label="No permitir grabar la factura con precios igual a cero" binary="true"
										[(ngModel)]="chbtnFacNoPreciCero" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="BLOQPVIMP"
										label="Bloquear la factura luego de impresa" binary="true"
										[(ngModel)]="chbtnBloqPvImp" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVBLISTAP"
										label="Bloquear la Lista de Precios del detalle" binary="true"
										[(ngModel)]="chbtnBloquearListaPrecios"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="FACVALCER"
										label="Permitir facturar con el valor de cero" binary="true"
										[(ngModel)]="chbtnFacValoreCero" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVIMPDIR"
										label="Imprimir Directamente la factura de venta luego de pagar" binary="true"
										[(ngModel)]="chbtnImpDirectoFacVta" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="BLODSCDET"
										label="Bloquear el descuento en la línea de detalle de la factura" binary="true"
										[(ngModel)]="chbtnBLODSCDET" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVAUTFCVEN"
										label="Autorizar Crédito si  excede el límite de crédito" binary="true"
										[(ngModel)]="chbtnPvAutFcVen" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="REFNUMER" label="Número de referencia numérico"
										binary="true" [(ngModel)]="chbtnREFNUMER"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVCAJADEPV"
										label="Asignar la caja para el pago dependiendo del vendedor" binary="true"
										[(ngModel)]="chbtnPvCajaDepVen" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="RESARTREP"
										label="Resumir el ingreso de productos repetidos" binary="true"
										[(ngModel)]="chbtnRESARTREP" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PVCURLD"
										label="Al crear una factura el cursor se coloca en la línea de detalle"
										binary="true" [(ngModel)]="chbtnNueFCurLd"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="SERTARCOB"
										label="Servicios para identificar el tipo de tarjeta para el cobro"
										binary="true" [(ngModel)]="chbtnSERTARCOB"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="CNTRLESCLI"
										label="Controlar el Estado del Cliente (activo - desactivo)" binary="true"
										[(ngModel)]="chbtnCntrlEstdCli" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-1">
									<span>Servicio Pago ND:</span>
								</div>
								<div class="p-col-1">
									<input class="txtPV" name="TTAPTOD" id="txtTTAPTOD" type="text" pInputText
										maxlength="2147483646" [(ngModel)]="txtTTAPTOD"
										(input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-1">
									<span>Servicio Pago NC:</span>
								</div>
								<div class="p-col-1">
									<input class="txtPV" name="TTAPTOC" id="txtTTAPTOC" type="text" pInputText
										maxlength="2147483646" [(ngModel)]="txtTTAPTOC"
										(input)="activarBotonGuardar($event)">
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="C2-PuntoVenta">
				<div class="divgrups" name="divPincipalPV2">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkPV" name="PVPREAIVA" label="Precios de Artículos incluyen I.V.A."
								binary="true" [(ngModel)]="chbtnPreArtInIva" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkPV" name="PLAOBLIFAC"
								label="Considerar como obligatorio el ingreso de la placa en el Pto. de Venta"
								binary="true" [(ngModel)]="chbtnPLAOBLIFAC" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="CODBARPV"
										label="Código de barras en la lista de precios" binary="true"
										[(ngModel)]="chbtnCodBarPrePV" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="CONCALCAN"
										label="Considerar calculo de la cantidad (Gasolinera)" binary="true"
										[(ngModel)]="chbtnCONCALCAN" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="NOFACCF" label="No facturar al consumidor final"
										binary="true" [(ngModel)]="chbtnNOFACCF"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="BUSSIMCLI"
										label="Busqueda simple de clientes en punto de venta con placa" binary="true"
										[(ngModel)]="chbtnBUSSIMCLI" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="FACCAJUNI" label="Facturar por cajas y  unidades"
										binary="true" [(ngModel)]="btnChkFacturaPorUnidades"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="FORPAGAUT"
										label="Ingresar la forma de pago de forma automática al guardar la factura"
										binary="true" [(ngModel)]="chbtnFORPAGAUT"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PARESCLI"
										label="Desplegar la pantalla resumida para crear un nuevo cliente" binary="true"
										[(ngModel)]="chbtnPARESCLI" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
									
								</div>
								<!-- CDPJ -->
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="PAVFPT"
										label="Permitir al usuario administrador ver todas las facturas de punto de venta" binary="true"
										[(ngModel)]="chbtnPAVFPT" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>						
							<!-- CDPJ -->
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="MAINVPTVTA"
										label="En la búsquedas de artículos solo habilitar pestañas principales"
										binary="true" [(ngModel)]="chbtnMAINVPTVTA"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="LSTPXVEN"
										label="Aplicar la lista de precios por vendedor" binary="true"
										[(ngModel)]="chbtnLSTPXVEN" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-2" [hidden]="true">
									<span>Aplicar impuesto al servicio al cliente:</span>
								</div>
								<div class="p-col-1" [hidden]="true">
									<input class="txtPV" name="IMPSERCLI" id="txtCfgValor3" type="text" pInputText
										maxlength="14" [(ngModel)]="txtCfgValor3" (input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="NROPVGRB"
										label="Generar el número de la factura al momento de guardar la factura"
										binary="true" [(ngModel)]="chbtnGenNumGrab"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="NUMFACPTO"
										label="Número de la factura con unica serie." binary="true"
										[(ngModel)]="chbtnNumFacPto" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="SELNVPV"
										label="Seleccionar como  Nota de Venta al ingresar a la factura" binary="true"
										[(ngModel)]="chbtnSELNVPV" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="INGTOUCH" label="Ingreso del touch" binary="true"
										[(ngModel)]="chbtnINGTOUCH" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="INGESPFAC" label="Ingreso  especial de la factura"
										binary="true" [(ngModel)]="chbtnINGESPFAC"
										(onChange)="activarBotonGuardar($event)"></p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="VENFUNCLI"
										label="Desplegar el vendedor en función del cliente" binary="true"
										[(ngModel)]="chbtnVENFUNCLI" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-6">
									<p-checkbox class="chkPV" name="NOPEDCLAV"
										label="No pedir la clave del vendedor al iniciar la facturación" binary="true"
										[(ngModel)]="chbtnNOPEDCLAV" (onChange)="activarBotonGuardar($event)">
									</p-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
		</p-tabView>
	</p-tabPanel>
	<p-tabPanel header="CXC">
		<div class="divgrups" name="divPincipalCXC1">
			<div class="p-grid">
				<div class="p-col-6">
					<p-checkbox class="chkCXC" name="NROCMPRAUT" label="Número de comprobantes de ingreso automático."
						binary="true" [(ngModel)]="chbtnComprobtesIngAuto" (onChange)="activarBotonGuardar($event)">
					</p-checkbox>
				</div>
				<!-- CDPJ -->
				<div class="p-col-6">
					<p-checkbox class="chkCXC" name="IMOBANCXCV" label="Registrar en los movimientos bancarios los RE y DP ingresados en Notas de Crédito y Notas de Débito de Varios"
						binary="true" [(ngModel)]="chbtnIMOBANCXCV" (onChange)="activarBotonGuardar($event)">
					</p-checkbox>
				</div>
				<!-- CDPJ -->
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="CXCNDDOCOB"
								label="Ingreso obligatorio de los documentos incluídos en la Nota de Débito"
								binary="true" [(ngModel)]="chbtnNotDebDocObli" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
				<!-- CDPJ -->
				<div class="p-col-6">
					<p-checkbox class="chkCXC" name="CCCAVNC" label="Considerar centro de costros en cartera activa en el detalle de varios para notas de crédito"
						binary="true" [(ngModel)]="chbtnCCCAVNC" (onChange)="activarBotonGuardar($event)">
					</p-checkbox>
				</div>
				<!-- CDPJ -->
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="CNTRLCHPOS"
								label="Controlar los Días de Cheques Posfechados" binary="true"
								[(ngModel)]="chbtnCntrlChPos" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
				<!-- CDPJ -->
				<div class="p-col-6">
					<p-checkbox class="chkCXC" name="CHPOSCOBR" label="Considerar cheques posfechados en el proceso de cobranzas"
						binary="true" [(ngModel)]="chbtnCHPOSCOBR" (onChange)="activarBotonGuardar($event)">
					</p-checkbox>
				</div>
				<!-- CDPJ -->
					</div>
					
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="TOTRETCXC"
								label="Retenciones: Ingresar el total retenido en lugar de la base retenida"
								binary="true" [(ngModel)]="chbtnTotRetCuentasXC"
								(onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="COMAUTBP"
								label="Número de comprobante automático en pago grupal de facturas" binary="true"
								[(ngModel)]="chbtnCOMAUTBP" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="NOVFECCAN"
								label="No validar fecha de cobro en función de la fecha de la cuentra por cobrar"
								binary="true" [(ngModel)]="chbtnNOVFECCAN" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="ANTEXCPAG"
								label="Permitir generar un anticipo a partir del valor que excede la forma de pago  "
								binary="true" [(ngModel)]="chbtnANTEXCPAG" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="CHPOSDEU"
								label="Considerar los cheques posfechados en cartera activa como deuda vigente"
								binary="true" [(ngModel)]="chbtnCHPOSDEU" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="NUMASICOM"
								label="Tomar en cuenta el número del asiento en el comprobante" binary="true"
								[(ngModel)]="chbtnNumAsiComp" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="ELIASIELI"
								label="Eliminar el asiento cuando se elimine la cuenta por cobrar" binary="true"
								[(ngModel)]="chbtnELIASIELI" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="CHEPOS" label="Cheques posfechados independientes"
								binary="true" [(ngModel)]="chbtnCHEPOS" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="APLPORINT"
								label="Aplicar porcentaje de interés por mora en el pago" binary="true"
								[(ngModel)]="chbtnAPLPORINT" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Mora:</span>
						</div>
						<div class="p-col-1">
							<div class="p-grid">
								<div class="p-col-10">
									<input class="txtCXC" name="PORINTMOR" id="txtPORINTMOR" type="text" pInputText
										maxlength="2147483646" [(ngModel)]="txtPORINTMOR"
										(input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-2">
									<span>%</span>
								</div>
							</div>
						</div>
						<div class="p-col-1">
							<span>Tasa de cobranza:</span>
						</div>
						<div class="p-col-1">
							<input class="txtCXC" name="TASACOB" id="txtTASACOB" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txtTASACOB" (input)="activarBotonGuardar($event)">
						</div>
						<div class="p-col-1">
							<span>Días de gracia:</span>
						</div>
						<div class="p-col-1">
							<input class="txtCXC" name="DIAGRACIA" id="txDIAGRACIA" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txDIAGRACIA" (input)="activarBotonGuardar($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Código Com.Mora</span>
						</div>
						<div class="p-col-1">
							<input class="txtCXC" name="CODCOMMOR" id="txtCODCOMMOR" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txtCODCOMMOR" (input)="activarBotonGuardar($event)">
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-8">
									<span>Código Com.Cobranza:</span>
								</div>
								<div class="p-col-4">
									<input class="txtCXC" name="CODCOMCOB" id="txtCODCOMCOB" type="text" pInputText
										maxlength="2147483646" [(ngModel)]="txtCODCOMCOB"
										(input)="activarBotonGuardar($event)">
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXC" name="OCUDETCUO"
								label="Ocultar detalle del interes en el ingreso de cuotas" binary="true"
								[(ngModel)]="chbtnOCUDETCUO" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="CXP">
		<div class="divgrups" name="divPincipalCXP1">
			<div class="p-grid">
				<div class="p-col-6">
					<p-checkbox class="chkCXP" name="CXPAUTPG" label="Autorizar Pagos." binary="true"
						[(ngModel)]="chbtnCxPAutrzPagos" (onChange)="activarBotonGuardar($event)"></p-checkbox>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXP" name="EAFPCA"
								label="Eliminar asiento y forma de pago al eliminar la cancelación o abono."
								binary="true" [(ngModel)]="chbtnElimAstoForPagoCancel"
								(onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXP" name="NROAPAGMOD" label="Número de asiento del pago modificable."
								binary="true" [(ngModel)]="chbtnNroAstoPagoMod"
								(onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXP" name="CHEQIMP"
								label="No modificar los cheques en pago a documentos cuando ya está impreso."
								binary="true" [(ngModel)]="chbtnPvImpCheqImp" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXP" name="ANUCHEA"
								label="Anular el cheque si se anula la cancelación o abono en pago a documentos"
								binary="true" [(ngModel)]="chbtnANUCHEA" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXP" name="CXPASIC"
								label="Cxp: Generar asientos contable de factura"
								binary="true" [(ngModel)]="chbtnCXPASIC" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCXP" name="COMCCCAJ"
								label="CXP: Generar Asiento con centro de costos de acuerdo a la caja"
								binary="true" [(ngModel)]="chbtnCOMCCCAJ" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="BAN">
		<div class="divgrups" name="divPincipalBAN1">
			<div class="p-grid">
				<div class="p-col-6">
					<span style="font-weight: bold">Caja-Bancos</span>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkBAN" name="NUMCHEAUT" label="Número de cheque automático"
								binary="true" [(ngModel)]="chbtnNumCheqAut" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkBAN" name="DESREQBAN" label="Desplegar el Número de Requisición"
								binary="true" [(ngModel)]="cchbtnDESREQBAN" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkBAN" name="COMBPAUT"
								label="Comprobante de Bandeja de pagos automático" binary="true"
								[(ngModel)]="cchbtnCOMBPAUT" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Códigos Banco Pichincha:</span>
						</div>
						<div class="p-col-2">
							<input class="txtBAN" name="CODBANPIC" id="txtCODBANPIC" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txtCODBANPIC" (input)="activarBotonGuardar($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Códigos Banco Internacional:</span>
						</div>
						<div class="p-col-2">
							<input class="txtBAN" name="CODBANINT" id="txtCODBANINT" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txtCODBANINT" (input)="activarBotonGuardar($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Códigos Banco Produbanco:</span>
						</div>
						<div class="p-col-2">
							<input class="txtBAN" name="CODBANPRO" id="txtCODBANPRO" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txtCODBANPRO" (input)="activarBotonGuardar($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Códigos Banco Guayaquil:</span>
						</div>
						<div class="p-col-2">
							<input class="txtBAN" name="CODBANGUA" id="txtCODBANGUA" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txtCODBANGUA" (input)="activarBotonGuardar($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Cnta.Cont Déposito Pendiente en conciliación de tarjetas de crédito:</span>
						</div>
						<div class="p-col-2">
							<input class="txtBAN" name="CUETRACT" id="txtCUETRACT" type="text" pInputText maxlength="40"
								[(ngModel)]="txtCUETRACT" (input)="activarBotonGuardar($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="CON">
		<div class="divgrups" name="divPincipalCON1">
			<div class="p-grid">
				<div class="p-col-6">
					<p-checkbox class="chkCON" name="CONASATMTC" label="Códigos de Asientos Automáticos." binary="true"
						[(ngModel)]="chbtnAstoAuto" (onChange)="activarBotonGuardar($event)"></p-checkbox>
				</div>
				<div class="p-col-6">
					<p-checkbox class="chkCON" name="OBSASIDET"
						label="Asignar la observación del asiento contable en el detalle del asiento" binary="true"
						[(ngModel)]="chBtnOBSASIDET" (onChange)="activarBotonGuardar($event)"></p-checkbox>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="NROAMOD" label="Número de asiento modificable."
								binary="true" [(ngModel)]="chbtnNroAstoModificable"
								(onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="NUMMENASI" label="Numeración de asientos por mes y año"
								binary="true" [(ngModel)]="chBtnNUMMENASI" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="ASILOTCUA" label="Controlar que el asiento contable cuadre"
								binary="true" [(ngModel)]="chbtnAstoLoteCuadre"
								(onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="CRLVISASI"
								label="Controlar la visualización de asientos con clave se seguridad" binary="true"
								[(ngModel)]="chBtnCRLVISASI" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="TOTRETCON"
								label="Retenciones(Asiento contable): Ingresar el total retenido en lugar de la base retenida"
								binary="true" [(ngModel)]="chbtnTotRetConAstoLote"
								(onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="INFCONBAN"
								label="Llevar información (Fecha, Observación) del asiento contable a la transacción en bancos"
								binary="true" [(ngModel)]="chBtnINFCONBAN" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="REVASINRO"
								label="Reversar el asiento Asiento contable con otro asiento en lugar de Eliminar o Anular el Asiento"
								binary="true" [(ngModel)]="chbtnRevAsiCon" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="CONTIPCI"
								label="Considerar tipo de diario CI en registros contables" binary="true"
								[(ngModel)]="chkCONTIPCI" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="DOCSINASI"
								label="Permitir guardar los documentos sin Asiento Contable" binary="true"
								[(ngModel)]="chbtnDocSinAsi" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="CONMAYGRL"
								label="Concepto de mayor general desde conceptos de asiento de diario" binary="true"
								[(ngModel)]="chkConMayGnrl" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Asiento Inicial</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1" style="text-align: right">
							<span style="text-align: right">Fecha:</span>
						</div>
						<div class="p-col-1">
							<p-calendar [(ngModel)]="dateTimeAsIni" dateFormat="dd/mm/yy"
								[inputStyle]="{'width': '100px'}" inputId="CalendarioAsIni" class="dateCON"
								name="FECINI" (ngModelChange)="activarBotonGuardar($event)"></p-calendar>
						</div>
						<div class="p-col-1" style="text-align: right">
							<span style="text-align: right">Contrapartida:</span>
						</div>
						<div class="p-col-2">
							<div class="p-grid">
								<div class="p-col-9">
									<input class="txtCON" name="CTAINI" id="txtCuentaAper" type="text" pInputText
										maxlength="2147483646" [(ngModel)]="txtCuentaAper"
										(input)="activarBotonGuardar($event)">
								</div>
								<div class="p-col-3">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										class="littlebuttons" (click)="buscarCuentaContable()"></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-7">
							<p-checkbox class="chkCON" name="BODCENCOS"
								label="Desplegar la bodega como centro de costos en cada línea de detalle de la factura de compras y ventas"
								binary="true" [(ngModel)]="chbtnBODCENCOS" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="COLASICOM"
								label="Colocar el numero del asiento en el numero de comprobante si esta en blanco"
								binary="true" [(ngModel)]="chbtnCOLASICOM" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="COMCCBOD"
								label="Generar Asiento de compras y ventas con centro de costos de acuerdo a la bodega"
								binary="true" [(ngModel)]="chBtnCOMCCBOD" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="PROGDETAS"
								label="Visualizar la columna programas en el detalle del asiento" binary="true"
								[(ngModel)]="chBtnPROGDETAS" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="CONEXPED"
								label="Considerar manejo de expediente en Registros contable" binary="true"
								[(ngModel)]="chBtnCONEXPED" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkCON" name="NOPRGDCC"
								label="No considerar programas en distribución por centro de costos" binary="true"
								[(ngModel)]="chBtnNOPRGDCC" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="ANE">
		<div class="divgrups" name="divPincipalANE1">
			<div class="p-grid">
				<div class="p-col-3">
					<span>No Considerar retención en Ventas Resumidas:</span>
				</div>
				<div class="p-col-2">
					<input class="txtANE" name="NOCODRET" id="txtNoCodRet" type="text" pInputText maxlength="10"
						[(ngModel)]="txtNoCodRet" (input)="activarBotonGuardar($event)">
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-3">
							<span>Ubicación de los archivos en el SaciAnex</span>
						</div>
						<div class="p-col-2">
							<input class="txtANE" name="PATHANEX" id="txtPathAnex" type="text" pInputText maxlength="50"
								[(ngModel)]="txtPathAnex" (input)="activarBotonGuardar($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-3">
						</div>
						<div class="p-col-3">
							<span>Ejem. C:\Saci\Anex52\EMPRE00\</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkANE" name="FECRECEMI"
								label="Utilizar la Fecha de Recepción de la factura en lugar de la Fecha de Emisión (Compras)"
								binary="true" [(ngModel)]="CHKFECRECEMI" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<span>No considerar las facturas de ventas en el ATS con las series:</span>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
							<input class="txtANE" name="SERNOATS1" id="txtSERNOATS1" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txtSERNOATS1" (input)="activarBotonGuardar($event)">
						</div>
						<div class="p-col-1">
							<input class="txtANE" name="SERNOATS2" id="txtSERNOATS2" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txtSERNOATS2" (input)="activarBotonGuardar($event)">
						</div>
						<div class="p-col-1">
							<input class="txtANE" name="SERNOATS3" id="txtSERNOATS3" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txtSERNOATS3" (input)="activarBotonGuardar($event)">
						</div>
						<div class="p-col-1">
							<input class="txtANE" name="SERNOATS4" id="txtSERNOATS4" type="text" pInputText
								maxlength="2147483646" [(ngModel)]="txtSERNOATS4" (input)="activarBotonGuardar($event)">
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkANE" name="GENXMLIND"
								label="Generar el XML independiente de los módulos" binary="true"
								[(ngModel)]="CHKGENXMLIND" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-6">
							<p-checkbox class="chkANE" name="NCNOFAC"
								label="Considerar las notas de Crédito sin tomar en cuenta la  factura" binary="true"
								[(ngModel)]="CHKNCNOFAC" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-6">
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="PROD">
		<div class="divgrups" name="divPincipalPROD1">
			<div class="p-grid">
				<div class="p-col-6">
					<p-checkbox class="chkProd" name="DATTECP" label="Datos técnicos en ordenes de producción"
						binary="true" [(ngModel)]="chbtnDATTECP" (onChange)="activarBotonGuardar($event)">
					</p-checkbox>
				</div>
				<!-- CDPJ -->
				<div class="p-col-6">
					<p-checkbox class="chkProd" name="CCPBOPP" label="Considerar centro de costos por bodega en Ord. Prod. Por Procesos"
						binary="true" [(ngModel)]="chbtnCCPBOPP" (onChange)="activarBotonGuardar($event)">
					</p-checkbox>
				</div>
				<!-- CDPJ -->
			</div>
			<div class="p-grid">
				<div class="p-col-6">
					<p-checkbox class="chkProd" name="VISCANFOR" label="Permitir visualizar la cantidad necesaria de  la formulación sin que haya en existencia"
						binary="true" [(ngModel)]="chbtnVISCANFOR" (onChange)="activarBotonGuardar($event)">
					</p-checkbox>
				</div>
				<!-- CDPJ -->
				<div class="p-col-6">
					<p-checkbox class="chkProd" name="CLORDPRO" label="Considerar el trabajar con lotes en las Ord.Producción"
						binary="true" [(ngModel)]="chbtnCLORDPRO" (onChange)="activarBotonGuardar($event)">
					</p-checkbox>
				</div>
				<!-- CDPJ -->
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="OTROS">
		<div class="divgrups" name="divPincipalOTROS1">
			<div class="p-grid">
				<div class="p-col-3">
					<span style="font-weight: bold">Numeraciones</span>
				</div>
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-3">
							<span>{{lblNumCot}}</span>
						</div>
						<div class="p-col-2">
							<p-listbox [options]="lstNumCot" [(ngModel)]="selectNumCot" optionLabel="name"
								(onChange)="activarBotonGuardar($event)"></p-listbox>
						</div>
						<div class="p-col-3">
							<span>{{lblNumCom}}</span>
						</div>
						<div class="p-col-2">
							<p-listbox [options]="lstNumCom" [(ngModel)]="selectNumCom" optionLabel="name"
								(onChange)="activarBotonGuardar($event)"></p-listbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-3">
							<span>{{lblNumFac}}</span>
						</div>
						<div class="p-col-2">
							<p-listbox [options]="lstNumFac" [(ngModel)]="selectNumFac" optionLabel="name"
								(onChange)="activarBotonGuardar($event)"></p-listbox>
						</div>
						<div class="p-col-3">
							<span>{{lblTipDet}}</span>
						</div>
						<div class="p-col-2">
							<p-listbox [listStyle]="{'max-height':'70px'}" [options]="lstTipDet"
								[(ngModel)]="selectTipDet" optionLabel="name" (onChange)="activarBotonGuardar($event)">
							</p-listbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-3">
							<span>{{lblNumPed}}</span>
						</div>
						<div class="p-col-2">
							<p-listbox [options]="lstNumPed" [(ngModel)]="selectNumPed" optionLabel="name"
								(onChange)="activarBotonGuardar($event)"></p-listbox>
						</div>
						<div class="p-col-3">
							<span>{{lblNumOrdPro}}</span>
						</div>
						<div class="p-col-2">
							<p-listbox [options]="lstNumOrdPro" [(ngModel)]="selectNumOrdPro" optionLabel="name"
								(onChange)="activarBotonGuardar($event)"></p-listbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-3">
							<span>{{lblNumGre}}</span>
						</div>
						<div class="p-col-2">
							<p-listbox [options]="lstNumGre" [(ngModel)]="selectNumGre" optionLabel="name"
								(onChange)="activarBotonGuardar($event)"></p-listbox>
						</div>
						<div class="p-col-3">
							<span>Condiciones Búsqueda:</span>
						</div>
						<div class="p-col-2">
							<p-listbox [listStyle]="{'max-height':'70px'}" [options]="listCondBus"
								[(ngModel)]="selectCondBus" optionLabel="name" (onChange)="activarBotonGuardar($event)">
							</p-listbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-3">
							<span>{{lblNumDvf}}</span>
						</div>
						<div class="p-col-2">
							<p-listbox [options]="lstNumDvf" [(ngModel)]="selectNumDvf" optionLabel="name"
								(onChange)="activarBotonGuardar($event)"></p-listbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-5">
							<p-checkbox class="chkOTRO" name="REPEMPAUTO"
								label="Considerar a la empresa como Auto Impresora:" binary="true"
								[(ngModel)]="chbtnEmpresaAutoImpre" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkOTRO" name="DESINDBUS"
								label="Desplegar indefinidamente el número de items del resultado de la búsqueda."
								binary="true" [(ngModel)]="chbtnDesIndBus" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-5">
							<p-checkbox class="chkOTRO" name="GENCLAAUT"
								label="Generar claves de autorización tipo Token" binary="true"
								[(ngModel)]="chbtnGenClaAut" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkOTRO" name="BUSDIGCAR"
								label="Ejecución de la búsqueda por cada caracter ingresado" binary="true"
								[(ngModel)]="chbtnEjeBusCarIng" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-5">
							<p-checkbox class="chkOTRO" name="PANACTEMA"
								label="Desplegar Pantalla de actualización de email." binary="true"
								[(ngModel)]="chbtnDesplePantActEmail" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
						<div class="p-col-6">
							<p-checkbox class="chkOTRO" name="CLAAUTFE"
								label="Pedir clave de autorización para Firmar electrónicamente" binary="true"
								[(ngModel)]="chbtnCLAAUTFE" (onChange)="activarBotonGuardar($event)"></p-checkbox>
						</div>
					</div>
				</div>
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-5">
							<p-checkbox class="chkOTRO" name="RANGFECHA"
								label="Habilitar contabilización por mes" binary="true"
								[(ngModel)]="chbtnRangfechacontabilisa" (onChange)="activarBotonGuardar($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Sistema">
		<p-tabView>
			<p-tabPanel header="Contabilidad Impuestos">
				<div class="divgrups" name="divPincipalSACI1">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-1">
								</div>
								<div class="p-col-6">
									<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true"
										[enableFilter]="true"
										[rowData]="sacimaeConfigService.detConMaeImpArray[indicador]"
										[frameworkComponents]="frameworkComponents" [animateRows]="true"
										[rowSelection]="rowSelection" [columnDefs]="columnDefsConMaeImpuesto"
										[defaultColDef]="defaultConMaeImpuesto" [rowStyle]="rowStyle"
										[singleClickEdit]="false" [botones]="false" [mostrarGuardar]="false"
										[botonNuevo]="true" [botonRegresar]="true" [botonBorrar]="true">
									</app-aggridsaci>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="Numeración Decimal">
				<div class="divgrups" name="divPincipalSACI2">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-1">
								</div>
								<div class="p-col-8">
									<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true"
										[enableFilter]="true"
										[rowData]="sacimaeConfigService.detSaciMaeNumDecArray[indicador]"
										[frameworkComponents]="frameworkComponents" [animateRows]="true"
										[rowSelection]="rowSelection" [columnDefs]="columnDefsSaciMaeNumDec"
										[defaultColDef]="defaultSaciNum" [rowStyle]="rowStyle" [singleClickEdit]="false"
										[botones]="false" [mostrarGuardar]="false" [botonNuevo]="true"
										[botonRegresar]="true" [botonBorrar]="true">
									</app-aggridsaci>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="Impuestos">
				<div class="divgrups" name="divPincipalSACI3">
					<div class="p-grid">
						<div class="p-col-1">
						</div>
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-1">
								</div>
								<div class="p-col-6">
									<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true"
										[enableFilter]="true"
										[rowData]="sacimaeConfigService.detVenMaeImpArray[indicador]"
										[frameworkComponents]="frameworkComponents" [animateRows]="true"
										[rowSelection]="rowSelection" [columnDefs]="columnDefsVenMaeImpuesto"
										[defaultColDef]="defaultVenMaeImp" [rowStyle]="rowStyle"
										[singleClickEdit]="false" [botones]="false" [mostrarGuardar]="false"
										[botonNuevo]="true" [botonRegresar]="true" [botonBorrar]="true">
									</app-aggridsaci>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-tabPanel>
						<!-- CDPJ -->
						<p-tabPanel header="%Impuestos SRI">
							<div class="divgrups" name="divPincipalSACI4">
								<div class="p-grid">
									<div class="p-col-1">
									</div>
									<div class="p-col-12" style="margin-top: -13px">
										<div class="p-grid">
											<div class="p-col-1">
											</div>
											<div class="p-col-6">
												<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true"
													[enableFilter]="true"
													[rowData]="sacimaeConfigService.detMaeImpSriArray[indicador]"
													[frameworkComponents]="frameworkComponents" [animateRows]="true"
													[rowSelection]="rowSelection" [columnDefs]="columnDefsMaeImpuestoSri"
													[defaultColDef]="defaultMaeImpSri" [rowStyle]="rowStyle"
													[singleClickEdit]="false" [botones]="true" (selected)="seleccionimpsri($event)">
												</app-aggridsaci>
											</div>
										</div>
									</div>
								</div>
							</div>
						</p-tabPanel>
						<!-- CDPJ -->
		</p-tabView>
		
	</p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
		[where]="where" [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)">
	</app-busquedadlg>
</p-dialog>