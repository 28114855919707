//
// SACI WEB
// Rutina: Cartera Pasiva
// Propietario: Victor Perez
// Modificado por: VP
// Fecha de creación: 20-08-2020
// Fecha de Modificación: 26-11-2020
//

import { Component, OnInit, ViewChild } from '@angular/core';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { CxptrncobroService } from '../cxpservicios/cxptrncobro.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';

// Ag-grid
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { CxpFpant } from '../cxpinterfaces/cxpfpant';
import { CxpEcant } from '../cxpinterfaces/cxpecant';
import { CxpVarios } from '../cxpinterfaces/cxpvarios';
import { CxpDocs } from '../cxpinterfaces/cxpdocs';
import { CxpFpdev } from '../cxpinterfaces/cxpfpdev';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { CxpTrncobro } from '../../com/cominterfaces/cxptrncobro';
import { DatosanexosComponent } from '../../com/datosanexos/datosanexos.component';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';

@Component({
  selector: 'app-cxptrncobro',
  templateUrl: './cxptrncobro.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxptrncobroComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  // @ts-ignore
  @ViewChild(DatosanexosComponent) datosanexos: DatosanexosComponent;

  hmpConfiguracion: any[];
  indicador: any;

  permisoPasiva: SegMaePermiso;

  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonFactura: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonesNav: boolean;
  selectasiento: any = {};
  index: number;
  tabDocumentos: number;

  impreso: string;

  intNOIMPCXP: number;

  // disable
  discodproveedor: boolean;
  boltxtCodProveedor: boolean;
  boltxtNumeroDocumento: boolean;
  bolbtnBuscarDocumento: boolean;
  boltxtSerie: boolean;
  boltxtNumero: boolean;
  boltxtImporte: boolean;
  bollblSaldoDocumento: boolean;
  boltxtFormaPagoSri: boolean;
  bolbtnFormaPagoSRI: boolean;
  bollblDesFormaPago: boolean;
  boldateTimeVencimiento: boolean;
  boldateTimeRecepcion: boolean;
  bolcmbTipo: boolean;
  boldateTimeFechaEmi = true;

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;

  chkSaldoInicial: boolean;
  bolchkSaldoInicial: boolean;

  largovar: string;
  largofp: string;

  cmbtipo: any[];
  selecttipo: any;

  txtNumeroTextLimit: number;
  strCodigoConPro = '';

  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];  
  busquedaCompuesta: boolean;
  consulta: string;
  tabla: string;
  where: string;
  opcionbusqueda: string;
//CDPJ
strNombreForma: string;
ASI_NRO='';
auxreporte: number = 0;
displayDialogReportes: boolean;
cmbasiento: any[];
//CDPJ
displayDialogfrmcorreos:boolean;//CDPJ
  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogAutorizacion: boolean;
  displayAsiContable: boolean;
  displayAcciones: boolean;
  displayDialogDatosAnexos: boolean;

  asiento: any;

  tabItemVarios: boolean;
  tabItemFPAnt: boolean;
  tabItemECAnt: boolean;
  tabItemDoc: boolean;
  tabItemFPDev: boolean;

  botonNuevoVarios: boolean;
  botonRegresarVarios: boolean;
  botonBorrarVarios: boolean;

  public frameworkComponents;
  rowStyle;
  rowStyleEcant;
  rowStyleFpdev;
  rowStyleFpant;
  desCodigo: boolean;

  selectedDetTrnCobro: CxpVarios = {};
  selectedFpAnt: CxpFpant = {};
  selectedDocumento: CxpDocs = {};
  selectedFpDev: CxpFpdev = {};

  defaultColDefVarios;
  defaultColDefDoc;
  defaultColDefECAnt;
  defaultColDefFPAnt;
  defaultColDefFPDev;

  rowSelection = 'multiple';

  columnDefsVarios = [
    { // 0
      headerName: 'Cuenta Contable', field: 'CONCODIGO', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Descripción', field: 'CONNOMBRE', cellEditor: 'cellPrueba', width: 180,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Cen.Costos', field: 'CENCODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 3
      headerName: 'Debe', field: 'DEBE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          tienecalculos: true
        }
      }
    },
    { // 4
      headerName: 'Haber', field: 'HABER', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          tienecalculos: true
        }
      }
    },
    { // 5
      headerName: 'Tipo', field: 'TRNBANTIPO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35
        }
      }
    },
    { // 6
      headerName: 'Tipo Gasto', field: 'TIPGASCODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    }
  ];

  columnDefsFPAnt = [
    { // 0
      headerName: 'Tipo', field: 'TIPODOC', cellEditor: 'cellPrueba', width: 70,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Caja', field: 'CAJADOC', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Banco', field: 'BANCODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 3
      headerName: 'Nombre Banco', field: 'BANBANCO', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 4
      headerName: 'Número', field: 'NUMERODOC', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 5
      headerName: 'Fecha', field: 'FECHADOC', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          tamanocelda: 35,
          tienecalculos: true
        }
      }
    },
    { // 6
      headerName: 'Concepto', field: 'CONCEPTODOC', cellEditor: 'cellPrueba', width: 140,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 7
      headerName: 'Importe', field: 'IMPORTEDOC', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 8
      headerName: 'Pag.Elect',
      field: 'PAGOELECTRONICO',
      cellRendererFramework: AggridBanComponent,
      editable: false,
      width: 50,
      onCellClicked: () => {
      }
    }
  ];

  columnDefsECAnt = [
    { // 0
      headerName: 'Nro. Documento', field: 'TRNCOBRONROCOMPROBANTE', cellEditor: 'cellPrueba', width: 150
    },
    { // 1
      headerName: 'Tipo', field: 'TRNCOBROTIPODOC', cellEditor: 'cellPrueba', width: 70
    },
    { // 2
      headerName: 'Fecha', field: 'TRNCOBROFECHATRN', cellEditor: 'cellPrueba', width: 100
    },
    { // 3
      headerName: 'Concepto', field: 'TRNCOBROCONCEPTO', cellEditor: 'cellPrueba', width: 200
    },
    { // 4
      headerName: 'Importe', field: 'TRNCOBROIMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }
    }
  ];

  columnDefsDoc = [
    { // 0
      headerName: 'S/N',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: false,
      width: 50,
      onCellClicked: (params) => {
        this.docSeleccionado(params);
      }
    },
    { // 1
      headerName: 'Nro. Documento', field: 'TRNCOBRONRODOC', cellEditor: 'cellPrueba', width: 140,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35
        }
      }
    },
    { // 2
      headerName: 'Fecha', field: 'TRNCOBROFECHATRN', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35
        }
      }
    },
    { // 3
      headerName: 'Concepto', field: 'TRNCOBROCONCEPTO', cellEditor: 'cellPrueba', width: 180,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35
        }
      }
    },
    { // 4
      headerName: 'Saldo', field: 'TRNCOBROSALDO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35
        }
      }
    },
    { // 5
      headerName: 'Importe', field: 'TRNCOBROIMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          tienecalculos: true
        }
      }, onCellValueChanged: (params) => {
        if (params.data.TRNCOBROIMPORTE !== null || params.data.TRNCOBROIMPORTE !== '') {
          this.calcularTotalDoc();

        }

      }
    }
  ];

  columnDefsFPDev = [
    { // 0
      headerName: 'Tipo', field: 'TRNPAGOTIPO', cellEditor: 'cellPrueba', width: 70,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Código', field: 'CAJCODIGO', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Número', field: 'TRNPAGONUMERO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: false
        }
      }
    },
    { // 3
      headerName: 'Fecha', field: 'TRNPAGOFECHA', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          tamanocelda: 35,
          tienecalculos: true
        }
      }
    },
    { // 4
      headerName: 'Ban.', field: 'BANCLICODIGO', cellEditor: 'cellPrueba', width: 70,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 5
      headerName: 'Nombre', field: 'BANCLINOMBRE', cellEditor: 'cellPrueba', width: 120,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 6
      headerName: 'Nro. Cta.', field: 'TRNPAGONROCTA', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 7
      headerName: 'Concepto', field: 'TRNPAGOCONCEPTO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    { // 8
      headerName: 'Importe', field: 'TRNPAGOIMPORTE', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    }
  ];

  constructor(public cxptrncobroService: CxptrncobroService, private init: NuevoComponentService,
    private busqService: InvbusquedaService, private messageService: MessageService,
    private datePipe: DatePipe, private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService, private confirmationService: ConfirmationService,
    private permisosService: PermisosService, private utilitariosService: UtilitariosService,private confIniciales: ConfInicialesService) {
    this.agTable();
    this.cxptrncobroService.variosarray[this.indicador] = [];
    this.cxptrncobroService.fpantarray[this.indicador] = [];
    this.cxptrncobroService.ecantarray[this.indicador] = [];
    this.cxptrncobroService.docarray[this.indicador] = [];
    this.cxptrncobroService.fpdevarray[this.indicador] = [];
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyle = (params) => {
      if (params.data.COLOR === 'CXC') {
        return { background: 'rgb(128, 255, 128)' };
      }
      if (params.data.COLOR === 'ANT') {
        return { background: 'rgb(255,255,0)' };
      }
    };

    this.rowStyleEcant = (params) => {
      if (params.data.TRNCOBRONROCOMPROBANTE === '') {
        return { background: 'rgb(255,255,0)' };
      }
    };

    this.rowStyleFpdev = (params) => {
      if (params.data.COLORDOCDEP === true) {
        return { background: 'rgb(255, 207, 185)' };
      }
      if (params.data.COLORDOCCONC === true) {
        return { background: 'rgb(135, 234, 130)' };
      }
    };

    this.rowStyleFpant = (params) => {
      if (params.data.COLORDOCCONC === true) {
        return { background: 'rgb(135, 234, 130)' };
      }
    };

    this.defaultColDefVarios = {
      editable: true,
      width: 100,
      objeto: 'cxpvarios'
    };

    this.defaultColDefDoc = {
      editable: true,
      width: 100,
      objeto: 'cxpdoc'
    };

    this.defaultColDefECAnt = {
      editable: true,
      width: 100,
      objeto: 'cxpecant'
    };

    this.defaultColDefFPAnt = {
      editable: true,
      width: 100,
      objeto: 'cxpfpant'
    };

    this.defaultColDefFPDev = {
      editable: true,
      width: 100,
      objeto: 'cxpfpdev'
    };
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.index = 0;
    this.barraBotones2 = true;
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonFactura = false;
    this.botonAnular = false;
    this.botonXML = false;
    this.botonesNav = false;

    this.botonNuevoVarios = true;
    this.botonRegresarVarios = true;
    this.botonBorrarVarios = true;

    this.largovar = '580';
    this.largofp = '600';
    this.impreso = '';

    this.llenarCombos();

    this.tabItemVarios = true;
    this.tabItemFPAnt = false;
    this.tabItemECAnt = false;
    this.tabItemDoc = false;
    this.tabItemFPDev = false;

    this.txtNumeroTextLimit = 50;
    this.intNOIMPCXP = 0;
    this.cxptrncobroService.trncobroarray[this.indicador] = {
      TRNCOBRO_NRODOC: '',
      COM_CODIGO: '',
      PRO_CODIGO: '',
      PRO_NOMBRE: '',
      TRNCOBRO_TIPODOC: '',
      TRNCOBRO_FECHATRN: '',
      TRNCOBRO_NROCOMPROBANTE: '',
      TRNCOBRO_CONCEPTO: '',
      TRNCOBRO_IMPORTE: '',
      TRNCOBRO_FECHAVENCE: '',
      APLORG_CODIGO: '',
      TRNCOBRO_REFERENCIAEXTERNA: '',
      VEN_CODIGO: '',
      ASI_NRO: '',
      TIPDIA_CODIGO: '',
      TRNCOBRO_FECHA_ACT: '',
      TRNCOBRO_USUARIO_ACT: '',
      TRNBAN_BENEFICIARIO: '',
      TRNCOBRO_FECHARECEPCION: '',
      TRNCOBRO_FECCADATRZ: '',
      TRNCOBRO_IMPRTATRZ: '',
      CEN_CODIGO: '',
      TRNCOBRO_REFFC: '',
      TRNCOBRO_FLAG: '',
      CON_CODIGO: '',
      TRNCOBRO_CUADRE: '',
      TRNCOBRO_ESTADO: '',
      TRNCOBRO_SALDOINICIAL: '',
      TRNCOBRO_SERIE: '',
      TRNCOBRO_IMPRESO: '',
      ASI_NROCHPOSFH: '',
      ENCREQ_NUMERO: '',
      DETREQ_LINEA: '',
      TRNCOBRO_PAGOELECTRONICO: '',
      TRNCOBRO_CODFORPAG: '',
      TRNCOBRO_PAGOLOCALEXT: '',
      TRNCOBRO_PAISPAGO: '',
      TRNCOBRO_CONVDOBLETRIB: '',
      TRNCOBRO_PAGOEXTERIOR: '',
      TRNCOBRO_RETSERIEEST: '',
      TRNCOBRO_RETSERIEPTOEMI: '',
      TRNCOBRO_RETSECUENCIAL: '',
      TRNCOBRO_RETAUTORIZACION: '',
      TRNCOBRO_PAGOREGFISCAL: '',
      TRNCOBRO_RETFECHA: '',
      TRNCOBRO_TIPOREGFISCAL: '',
      TRNCOBRO_CODPAISREGGEN: '',
      TRNCOBRO_CODPAISPARFIS: '',
      TRNCOBRO_DENOMINACION: '',
      ENCFACPRO_REFERENCIA: ''
    };

    this.cxptrncobroService.trndatosanexarray[this.indicador] = {};

    this.cxptrncobroService.variosarray[this.indicador] = [];
    this.cxptrncobroService.fpantarray[this.indicador] = [];
    this.cxptrncobroService.ecantarray[this.indicador] = [];
    this.cxptrncobroService.docarray[this.indicador] = [];
    this.cxptrncobroService.fpdevarray[this.indicador] = [];

    this.getConfiguracion();
    this.getPermisos();
    this.cxptrncobroService.hmpConfiguracion = await this.auditoriagral.obtenerConfiguracionProm();
    this.cxptrncobroService.intNumCheAut = Number(this.cxptrncobroService.getConfiguraciones('NUMCHEAUT'));
    console.log(this.cxptrncobroService.intNumCheAut);

    document.getElementById('contorno').style.pointerEvents = 'none';

  }
  //CDPJ  
  ngOnDestroy(): void {
    this.confIniciales.instanciasCartPas--;
}
//CDPJ
  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoPasiva = data[0];
        } else {
          this.permisoPasiva = null;
        }
      } else {
        this.permisoPasiva = null;
      }
    } catch (e) {
      this.permisoPasiva = null;
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }

  nuevo() {
    this.bolcmbTipo = true;
    this.boltxtCodProveedor = true;
    this.eliminarPestanias();

    this.boltxtFormaPagoSri = false;
    this.bollblDesFormaPago = false;
    this.bolbtnFormaPagoSRI = false;

    this.limpiarDatos(false);
    this.limpiarDatosAnexos();

    this.selecttipo = this.cmbtipo[0];
    this.seleccionarTipo('nuevo');

    if (this.tabItemVarios === true) {
      this.cxptrncobroService.variosarray[this.indicador] = [];
    }

    const intNROAPAGMOD = 1;
    this.boltxtNumeroDocumento = intNROAPAGMOD === 1;

    this.botonesmodificar();
    document.getElementById('contorno').style.pointerEvents = 'all';
    document.getElementById('txtCodProveedor').focus();
    document.getElementById('txtCodProveedor').focus();
  }

  eliminarUltimaLineaFPAnt() {
    if (this.cxptrncobroService.fpantarray[this.indicador].length > 1) {
      const intUltimaFila = this.cxptrncobroService.fpantarray[this.indicador].length - 1;

      if (this.cxptrncobroService.fpantarray[this.indicador][intUltimaFila - 1].CAJADOC.length === 0 &&
        this.cxptrncobroService.fpantarray[this.indicador][intUltimaFila - 1].IMPORTEDOC === 0) {
        this.cxptrncobroService.fpantarray[this.indicador].splice(intUltimaFila - 1);
        this.aggrid.refreshaggrid(this.cxptrncobroService.fpantarray[this.indicador], this.defaultColDefFPAnt.objeto);
      }

      if (this.cxptrncobroService.fpantarray[this.indicador].length === 0) {
        // dlgCxP.btnNuevoFPAnt.setEnabled(true);
        // dlgCxP.btnCancelarFPAnt.setEnabled(false);
      }
    }
  }

  async faltaDatosFPAnt() {
    let bolFalta = false;

    for (let fila = 1; fila <= this.cxptrncobroService.fpantarray[this.indicador].length; fila++) {
      const item = this.cxptrncobroService.fpantarray[this.indicador][fila - 1];
      let strCodigo = '';

      if (item.TIPODOC.length === 0) { // si no existe tipo de pago
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese el tipo de pago.'
        });
        bolFalta = true;
      } else {
        if (item.TIPODOC.length > 0) { // existencia de la forma de pago
          try {
            const er = await this.cxptrncobroService.erFormaPagoTipoProm(item.TIPODOC);
            
            strCodigo = er[0].FORMAPAGO_TIPO;
          } catch (error1) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Error',
              detail: 'No se puede verificar la existencia' +
                ' de la forma de pago'
            });
            this.cxptrncobroService.spin=false;//CDPJ
          }

          if (strCodigo.length === 0) {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Información',
              detail: 'La forma de pago no existe.'
            });
            return bolFalta = true;
          }
        }
        if (item.NUMERODOC.length === 0 && item.PAGOELECTRONICO === false || item.FECHADOC.length === 0) {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Error',
            detail: 'La información está incompleta.'
          });
          return bolFalta = true;
        }

        // PAGOS EN CHEQUE
        if (item.TIPODOC === 'CH') {
          const numpago = await this.cxptrncobroService.ernumcheque(item.BANCODIGO,item.TIPODOC,item.NUMERODOC);
            console.log(numpago)
            console.log(numpago[0])
          if (item.BANCODIGO.length === 0 || item.BANBANCO.length === 0) {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Error',
              detail: 'La información está incompleta.'
            });
            return bolFalta = true;
          }
          if(numpago[0].NUMERO>0 
            && (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC === ''
            || this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC === null
            || this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC === undefined
            )
            ){
              this.messageService.add({
                key: 'cxptrncobro',
                severity: 'error',
                summary: 'Error',
                detail: 'El numero '+item.NUMERODOC+' ya existe en el movimiento de bancos.'
              });
              return bolFalta = true;
          }
        }

        if (Number(item.IMPORTEDOC) <= 0) {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Error',
            detail: 'El pago no puede ser menor o igual a cero.'
          });
          bolFalta = true;
        }

        if (item.CAJADOC.length > 0) {
          if (await this.verificarExistenciaCaja(item.CAJADOC) === false) {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Error',
              detail: 'No existe la caja.'
            });
            bolFalta = true;
          }
        }
        //GSRF
        console.log('-*-------*',item.FECHADOC);
        console.log('-*-------*',this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy'))
        const strfecha1= this.strintoDate(item.FECHADOC,'dd/MM/yyyy','/')
        const strfecha2= this.strintoDate(this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy'),'dd/MM/yyyy','/')
        console.log('-*-------*',strfecha1);
        console.log('-*-------*',strfecha2)
        if(strfecha1 < strfecha2){
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Error',
            detail: 'La fecha de forma pago no puede ser menor a la del documento.'
          });
          bolFalta = true;
        }
        //GSRF
        
      }
    }

    return bolFalta;

  }
  strintoDate(dateString,format,delimiter){
const formatitems=format.split(delimiter);
const dateitems=dateString.split(delimiter);
const dayindex=formatitems.indexOf("dd");
const monthindex=formatitems.indexOf("MM");
const yearindex=formatitems.indexOf("yyyy");
const dia=parseInt(dateitems[dayindex],10);
const mes=parseInt(dateitems[monthindex],10)-1;
const anio=parseInt(dateitems[yearindex],10);
return new Date(anio,mes,dia)

  }
  async faltaCampos() {
    let strFechaEmision = '';
    let strFechaRecepcion = '';
    let strFechaVencimiento = '';
    let strTipoDocumento = '';

    // Verifica que documento este dentro del periodo contable
    if (await this.verificarPeriodoContable(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN) === false) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, fecha debe de estar dentro del período contable'
      });
      this.cxptrncobroService.spin=false;//CDPJ
      return false;
    }

    // Código del proveedor
    if (this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO === '') {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, Por favor ingrese el código del proveedor'
      });
      document.getElementById('txtCodProveedor').focus();
      this.cxptrncobroService.spin=false;//CDPJ
      return false;
    }

    // strFechaEmision = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    // strFechaRecepcion = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHARECEPCION, 'dd/MM/yyyy');
    // strFechaVencimiento = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
    strFechaEmision = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN;
    strFechaRecepcion = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHARECEPCION;
    strFechaVencimiento = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHAVENCE;

    if (strFechaEmision > strFechaRecepcion) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, La fecha de emisión no puede ser mayor a la fecha de recepción'
      });
      document.getElementById('dateTimeFechaEmision').focus();
      this.cxptrncobroService.spin=false;//CDPJ
      return false;
    }

    if (strFechaEmision > strFechaVencimiento) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'La fecha de recepción no puede ser mayor a la fecha de vencimiento'
      });
      document.getElementById('dateTimeRecepcion').focus();
      this.cxptrncobroService.spin=false;//CDPJ
      return false;
    }

    // Tipo de documento
    if (this.selecttipo.name === 'Factura') {
      strTipoDocumento = 'FC';
    }
    if (this.selecttipo.name === 'Nota de Crédito') {
      strTipoDocumento = 'NC';
    }
    if (this.selecttipo.name === 'Nota de Débito') {
      strTipoDocumento = 'ND';
    }
    if (this.selecttipo.name === 'Anticipo') {
      strTipoDocumento = 'ANP';
    }
    if (this.selecttipo.name === 'Dev. de Anticipos') {
      strTipoDocumento = 'DANP';
    }

    if (strTipoDocumento !== 'ANP' && strTipoDocumento !== 'DANP' || this.chkSaldoInicial) {
      if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese el Número del documento'
        });
        document.getElementById('txtNumero').focus();
        this.cxptrncobroService.spin=false;//CDPJ
        return false;
      }
    }

    if (this.chkSaldoInicial === false) {
      if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA === ''
        && strTipoDocumento === 'NC') {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese el número del documento'
        });
        document.getElementById('txtNumeroDocumento').focus();
        this.cxptrncobroService.spin=false;//CDPJ
        return false;
      }
    } else {
      // Verifica que exista una forma de pago del importe
      if (Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE) <= 0) {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'Valor del importe incorrecto'
        });
        this.cxptrncobroService.spin=false;//CDPJ
        return false;
      }
    }

    if (strTipoDocumento === 'NC') {
      if (await this.verificarImporte() === false) {
        this.cxptrncobroService.spin=false;//CDPJ
        return false;
      }
    }

    if ((strTipoDocumento !== 'ANP' && strTipoDocumento !== 'FC')
      && this.chkSaldoInicial === false
      && strTipoDocumento !== 'DANP') {
      if (this.autorizacionSRI() === false) {
        this.cxptrncobroService.spin=false;//CDPJ
        return false;
      }
    }

    if (await this.verificarExistenciaComprobante() === true) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'Número del comprobante ya existe, por favor verifique'
      });
      this.cxptrncobroService.spin=false;//CDPJ
      return false;
    }

    if (strTipoDocumento === 'ANP' && this.chkSaldoInicial === false) {

      if (this.VerificaTotalFPImporte() === false) {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'El total de la forma de pago no es igual al importe, por favor verifique'
        });
        this.cxptrncobroService.spin=false;//CDPJ
        return false;
      }
    }
    /*
    if (txtNumRequisicion.getText().trim().compareTo('') != 0) {
      if (verificarSaldoRequisicion(txtNumRequisicion.getText(), Integer
        .valueOf(lblLineaDetReq.getText())) == false) {
        return false;
      }
    }
    */
//CDPJ SI ES UNA FATURA VERIFICA QUE EL TOTAL DEL DEBE SEA IGUAL AL TOTAL DEL IMPORTA
if (this.selecttipo.name === 'Factura' && this.chkSaldoInicial === false) {
  if(this.ValidarFacTotVar() === false){
    this.cxptrncobroService.spin=false;//CDPJ
    return false
  }
}
if (strTipoDocumento === 'NC') {
  if (await this.ValidarNCTotVar() === false) {
    this.cxptrncobroService.spin=false;//CDPJ
    return false;
  }
}
if (strTipoDocumento === 'ND') {
  if (await this.ValidarNDTotVar() === false) {
    this.cxptrncobroService.spin=false;//CDPJ
    return false;
  }
}
//CDPJ
    // verificar Ingreso de tipo de gastos

    if (await this.verificarIngresoTG() === false) {
      this.cxptrncobroService.spin=false;//CDPJ
      return false;
    }

    return true;
  }
  //CDPJ
ValidarFacTotVar(){
  let intIndiceLista = 0;
  let itemVarios;
  let itemhaber=0;
  let itemdebe=0;
  let dblTotalImporteVarios = 0;
  let dblTotalImporteVariosH=0
  let dblImporte = 0;
  let item: CxpVarios = {};
  // Total de varios
  for (intIndiceLista = 0; intIndiceLista < this.cxptrncobroService.variosarray[this.indicador].length; intIndiceLista++) {
    item = this.cxptrncobroService.variosarray[this.indicador][intIndiceLista];
    //itemdebe=Number(item.DEBE)+itemdebe;
    //itemhaber=Number(item.HABER)+itemhaber;
    //dblTotalImporteVariosH=Number(itemdebe)-Number(itemhaber);
    dblTotalImporteVarios = dblTotalImporteVarios + Number(item.DEBE) +
        (Number(item.HABER) * -1);
    //dblTotalImporteVarios=Number(itemdebe)+Number(itemhaber);
    

  } // fin de for
  console.log('dblTotalImporteVarios',Number(dblTotalImporteVarios).toFixed(2))//GSRF
  console.log(Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE).toFixed(2))//GSRF
  if (Number(dblTotalImporteVarios).toFixed(2) !== Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE).toFixed(2)) {//GSRF
    this.messageService.add({
      key: 'cxptrncobro',
      severity: 'error',
      summary: 'Información',
      detail: 'Error, El Total en el DEBE es diferente al total del importe.'
    });
    
    return false;
  }
  return true;
}
ValidarNCTotVar(){
  let intIndiceLista = 0;
  let dblTotalImporteVarios = 0;
  let item: CxpVarios = {};
  // Total de varios
  for (intIndiceLista = 0; intIndiceLista < this.cxptrncobroService.variosarray[this.indicador].length; intIndiceLista++) {
    item = this.cxptrncobroService.variosarray[this.indicador][intIndiceLista];
    dblTotalImporteVarios=Number(item.HABER)+dblTotalImporteVarios;
  } // fin de for
  console.log('dblTotalImporteVarios',Number(dblTotalImporteVarios).toFixed(2))
  console.log(Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE).toFixed(2))
  if (Number(dblTotalImporteVarios).toFixed(2) !== Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE).toFixed(2)) {
    this.messageService.add({
      key: 'cxptrncobro',
      severity: 'error',
      summary: 'Información',
      detail: 'Error, El Total en el HABER, es diferente al total del importe.'
    });
    
    return false;
  }
  return true;
}
ValidarNDTotVar(){
  let intIndiceLista = 0;
  let dblTotalImporteVarios = 0;
  let item: CxpVarios = {};
  // Total de varios
  for (intIndiceLista = 0; intIndiceLista < this.cxptrncobroService.variosarray[this.indicador].length; intIndiceLista++) {
    item = this.cxptrncobroService.variosarray[this.indicador][intIndiceLista];
    dblTotalImporteVarios=Number(item.DEBE)+dblTotalImporteVarios;
  } // fin de for
  console.log('dblTotalImporteVarios',Number(dblTotalImporteVarios).toFixed(2))
  console.log(Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE).toFixed(2))
  if (Number(dblTotalImporteVarios).toFixed(2) !== Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE).toFixed(2)) {
    this.messageService.add({
      key: 'cxptrncobro',
      severity: 'error',
      summary: 'Información',
      detail: 'Error, El Total en el DEBE, es diferente al total del importe.'
    });
    
    return false;
  }
  return true;
}
//CDPJ

  eliminarLineaEnBlanco() {
    let intIndiceLista = 0;
    let item;

    for (intIndiceLista = 0; intIndiceLista < this.cxptrncobroService.variosarray[this.indicador].length; intIndiceLista++) {
      item = this.cxptrncobroService.variosarray[this.indicador][intIndiceLista];
      if (item.CONCODIGO === '') {
        this.cxptrncobroService.variosarray[this.indicador].splice(intIndiceLista);
      }
    }

    this.aggrid.refreshaggrid(this.cxptrncobroService.variosarray[this.indicador], this.defaultColDefVarios.objeto);
  }

  obtenerTotalPagado(): number {
    let dblTotal = 0;

    for (let fila = 1; fila <= this.cxptrncobroService.fpantarray[this.indicador].length; fila++) {
      if (this.cxptrncobroService.fpantarray[this.indicador][fila - 1].TIPODOC.length > 0) {
        dblTotal = dblTotal + Math.abs(Number(this.cxptrncobroService.fpantarray[this.indicador][fila - 1].IMPORTEDOC));
      }
    }

    return dblTotal;
  }

  obtenerTotalPagadoDev(): number {
    let dblTotal = 0;

    for (let fila = 1; fila <= this.cxptrncobroService.fpdevarray[this.indicador].length; fila++) {
      if (this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGOTIPO.length > 0) {
        dblTotal = dblTotal + Number(this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGOIMPORTE);
      }
    }

    return dblTotal;
  }

  async guardar() {
    this.cxptrncobroService.spin=true;//CDPJ
    let strTipoDocumento = '';

    if (this.cxptrncobroService.trncobroarray[this.indicador].ANULADO === 'ANULADO') { // Si esta estado anulado
      this.cxptrncobroService.spin=false;//CDPJ
      return;
    }

    if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC === '') { // Nuevo registro
      // Verifica los permisos
      if (this.permisoPasiva !== null) {
        if (this.permisoPasiva.PERTODO === 0) {
          if (this.permisoPasiva.PERINSERCION === 0) {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.cxptrncobroService.usuario.identificacion
                + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
            });
            this.cxptrncobroService.spin=false;//CDPJ
            return;
          }
        }
      }
    } else { // Modifica registro
      // Verifica los permisos
      if (this.permisoPasiva !== null) {
        if (this.permisoPasiva.PERTODO === 0) {
          if (this.permisoPasiva.PERACTUALIZACION === 0) {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.cxptrncobroService.usuario.identificacion
                + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
            });
            this.cxptrncobroService.spin=false;//CDPJ
            return;
          }
        }
      }
    }

    // Tipo de documento

    if (this.selecttipo.name === 'Factura') {
      strTipoDocumento = 'FC';
    }
    if (this.selecttipo.name === 'Nota de Crédito') {
      strTipoDocumento = 'NC';
    }
    if (this.selecttipo.name === 'Nota de Débito') {
      strTipoDocumento = 'ND';
    }
    if (this.selecttipo.name === 'Anticipo') {
      strTipoDocumento = 'ANP';
    }
    if (this.selecttipo.name === 'Dev. de Anticipos') {
      strTipoDocumento = 'DANP';
    }
    console.log(strTipoDocumento);
    if (strTipoDocumento === 'ND' || strTipoDocumento === 'NC') {
      if (this.intNOIMPCXP === 0 && this.chkSaldoInicial === false) {
        if (this.verificarTotalImpuestos('guardar') === false) {
          this.confirmationService.confirm({
            message: 'Si no requiere que se valide los datos para los anexos, ingrese la clave de autorización',
            header: 'Anexos',
            icon: 'pi pi-exclamation-triangle',
            key: 'confirmarcxptrncobro',
            acceptLabel: 'Si',
            accept: () => {
              this.confirmationService.close();
              this.claveAuth = '';
              this.autorizacionCodigo = 'NOIMPCXP';
              this.displayDialogAutorizacion = true;
            }
          });
        } else {
          this.guardarparte2(strTipoDocumento);
        }
      } else {
        this.guardarparte2(strTipoDocumento);
      }
    } else {
      this.intNOIMPCXP = 1;
      this.guardarparte2(strTipoDocumento);
    }
  }

  async guardarparte2(strTipoDocumento) {
    this.cxptrncobroService.spin=true;//CDPJ
    let data = [];
    //GSRF
    let errores=0
    this.cxptrncobroService.variosarray[this.indicador].map((vario) => {
      if (vario.CONCODIGO.substr(-1, 1) === '.') {
        console.log('entra2')
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en varios'
        });
        vario.CONCODIGO = '';
        vario.CONNOMBRE = '';
        this.aggrid.refreshColumnDefs();
        this.aggrid.refreshaggrid(this.cxptrncobroService.variosarray[this.indicador], this.defaultColDefVarios.objeto);
         errores++;
         this.cxptrncobroService.spin=false;//CDPJ
          return;
      }
      
    })
    if(errores>0){
      this.cxptrncobroService.spin=false;//CDPJ
      return;
    }
    //GSRF
    // VALIDACIONES DE LA FORMA DE PAGO PARA LAS DEVOLUCIONES DE ANTICIPOS
    if (this.tabItemFPAnt !== false) {
      this.eliminarUltimaLineaFPAnt();
      if (await this.faltaDatosFPAnt() === true) {
        this.cxptrncobroService.spin=false;//CDPJ
        return;
      }
    }

    if (await this.faltaCampos() === true) { // Si los datos de la cabecera son correctos
      if (this.tabItemVarios !== false) {
        this.eliminarLineaEnBlanco();
      }

      // Tipo de documento
      if (this.selecttipo.name === 'Factura') {
        strTipoDocumento = 'FC';
      }
      if (this.selecttipo.name === 'Nota de Crédito') {
        strTipoDocumento = 'NC';
      }
      if (this.selecttipo.name === 'Nota de Débito') {
        strTipoDocumento = 'ND';
      }
      if (this.selecttipo.name === 'Anticipo') {
        strTipoDocumento = 'ANP';
      }
      if (this.selecttipo.name === 'Dev. de Anticipos') {
        strTipoDocumento = 'DANP';
      }

      // Validar que el total de documentos sea igual al total de la forma de pago
      if (strTipoDocumento === 'DANP') {
        console.log(Number(this.cxptrncobroService.trncobroarray[this.indicador].TOTALES), '!==', this.obtenerTotalPagadoDev());
        if (Number(this.cxptrncobroService.trncobroarray[this.indicador].TOTALES) !== this.obtenerTotalPagadoDev()) {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Información',
            detail: 'El total de los documentos es distinto al'
              + ' total de la forma de pago.'
          });
          this.cxptrncobroService.spin=false;//CDPJ
          return;
        }
        //GSRF

        for (let fila = 1; fila <= this.cxptrncobroService.fpdevarray[this.indicador].length; fila++) {
          const item1 = this.cxptrncobroService.fpdevarray[this.indicador][fila - 1];
          console.log(item1);
    
          console.log('-*-------*',item1.TRNPAGOFECHA);
        console.log('-*-------*',this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy'))
        //GSRF
        if(item1.TRNPAGOFECHA < this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy')){
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Error',
            detail: 'La fecha de forma pago no puede ser menor a la del documento.'
          });
          this.cxptrncobroService.spin=false;//CDPJ
          return;
        }
        }
        //GSRF
        
      }
      // Guardar la cabecera de la factura

      try {
        const strFechaEmision = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        const strFechaRecepcion = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHARECEPCION, 'dd/MM/yyyy');
        const strFechaVencimiento = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');

        const item: CxpTrncobro = {};
        item.TRNCOBRO_NRODOC = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC;
        item.COM_CODIGO = '01';
        item.TRNCOBRO_FECHATRN = strFechaEmision;
        console.log(strTipoDocumento);
        item.TRNCOBRO_TIPODOC = strTipoDocumento;
        item.TRNCOBRO_NROCOMPROBANTE = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
        item.TRNCOBRO_CONCEPTO = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_CONCEPTO;
        if (strTipoDocumento !== 'DANP') {
          item.TRNCOBRO_IMPORTE = Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE);
        } else {
          item.TRNCOBRO_IMPORTE = Number(this.cxptrncobroService.trncobroarray[this.indicador].TOTALES);
        }
        item.TRNCOBRO_FECHAVENCE = strFechaVencimiento;
        item.PRO_CODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
        item.APLORG_CODIGO = 'CXP';
        item.TRNCOBRO_USUARIO_ACT = this.cxptrncobroService.usuario.identificacion;
        item.TRNCOBRO_FECHARECEPCION = strFechaRecepcion;

        if (strTipoDocumento === 'FC') {
          item.TRNCOBRO_REFERENCIAEXTERNA = 'SALDO INICIAL';
        }
        if (strTipoDocumento === 'NC') {
          item.TRNCOBRO_REFERENCIAEXTERNA = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA;
        }
        if (strTipoDocumento === 'ND') {
          item.TRNCOBRO_REFERENCIAEXTERNA = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA;//CDPJ SE AUMENTO LA REFERENCIA EXTERNA
        }
        if (strTipoDocumento === 'ANP' || strTipoDocumento === 'DANP') {
          item.TRNCOBRO_REFERENCIAEXTERNA = '';
        }

        item.CON_CODIGO = this.strCodigoConPro;
        item.TRNCOBRO_SERIE = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SERIE;


        if ((strTipoDocumento === 'FC' || strTipoDocumento === 'ANP' || strTipoDocumento === 'ND') && this.chkSaldoInicial) {
          // si es factura genera el registro de saldo inicial
          data = await this.cxptrncobroService.insertarSaldosIni(item);
        } else {
          item.ENCREQ_NUMERO = '';
          item.DETREQ_LINEA = '';
          item.TRNCOBRO_CODFORPAG = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_CODFORPAG;
          data = await this.cxptrncobroService.insertarTrncobroCxp(item);
        }

        for (const rs of data) {
          if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC === '') { // nuevo registro
            this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC = rs[':B1'];
            this.auditoriagral.registrarAuditoria('CXP_TRNCOBRO', this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC
              + '/' + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, 'I',
              '', '01', '', '', '', '').subscribe();
            if (strTipoDocumento === 'NC') {
              await this.actualizarEstadoFacturaProv(
                this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO,
                this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA);
            }
            this.bolcmbTipo = false;
            this.bolchkSaldoInicial = false;

            this.botonNuevo = false;
            this.botonGuardar = true;
            this.botonBorrar = false;
            this.botonRegresar = false;
          } else {// actualizacion
            this.auditoriagral.registrarAuditoria('CXP_TRNCOBRO', this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC
              + '/' + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, 'A',
              '', '01', '', '', '', '').subscribe();
            // actualiza el estado de la factura del proveedor
            if (strTipoDocumento === 'NC') {
              await this.actualizarEstadoFacturaProv(
                this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO,
                this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA);
            }

            this.botonNuevo = false;
            this.botonGuardar = true;
            this.botonBorrar = false;
            this.botonRegresar = false;
          }

          // despliega datos informaticos para los anexos en la pestaña Anex
          if (strTipoDocumento !== 'FC' && strTipoDocumento !== 'ANP') {
            this.desplegarDatosSRI();
          }

          // Si es una nota de crédito o débito verifica el total impuestos con el nuevo importe
          if ((strTipoDocumento === 'NC' || strTipoDocumento === 'ND') && this.chkSaldoInicial === false) {
            if (this.verificarTotalImpuestos('guardarparte2') === false) {
              this.index = 1;
              this.botonesmodificar();
            }
          }

          // si es un anticipo guardar la forma de pago
          if (strTipoDocumento === 'ANP' && this.chkSaldoInicial === false) {
            if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC.length > 0) { // si es
              // actualización se debe eliminar la fp y luego insertar
              await this.eliminarFPAnt(rs[':B1'], false);
            }
            await this.insertarFormaPago(rs[':B1']);
          }

          // colocar el número del comprobante si es anticipo o es una
          // devolución de anticipos
          if (strTipoDocumento === 'ANP' || strTipoDocumento === 'DANP') {
            this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = await this.obtenerComprobante(rs[':B1']);
            this.boltxtSerie = false;
            this.boltxtNumero = false;
          }

          // si es una devolución de anticipo guardar los documentos involucrados
          if (strTipoDocumento === 'DANP') {
            if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC.length > 0) {
              // si es actualización se debe eliminar la fp y luego insertar
              await this.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' + rs[':B1'] + '\' AND COM_CODIGO = \'01\'');
              await this.eliminarFPDev(rs[':B1'], false);
            }
            await this.insertarDocumentos(rs[':B1']);
            await this.insertarFormaPagoDev(rs[':B1']);
          }

          // Si no es factura guarda los datos de varios y luego genera el asiento
          if ((strTipoDocumento !== 'ANP' && this.chkSaldoInicial === false) && strTipoDocumento !== 'DANP') {
            await this.guardarDetalleVarios(); // guarda el detalle de varios
            //  Genera los depósitos o retiros en bancos de la grilla varios
            await this.insertaDepositosYRetiros();
            /*this.generarAsiento();
          } else if (strTipoDocumento === 'ANP' && this.chkSaldoInicial === false) {
            this.generarAsientoAnt(rs[':B1']);
          } else if (strTipoDocumento === 'DANP') {
            this.generarAsientoDevAnt(rs[':B1']);*/
          }

          await this.generarAsientoContable();
        }
        for (const rs of data) {
          /*if (modeloFPAnt != null)
            modeloFPAnt.filaNueva = -1;
          
          txtCodProveedor.setEditable(false);*/
          this.boltxtCodProveedor = false;
        }
      } catch (error1) {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Proceso guardar Cuenta por Pagar no terminó satisfactoriamente...!',
          detail: error1
        });
        this.cxptrncobroService.spin=false;//CDPJ
      }
    }else{// Fin si los datos de la cabecera son correctos */
      this.cxptrncobroService.spin=false;//CDPJ
    }
    this.cxptrncobroService.spin=false;//CDPJ
  }// fin de guardar

  faltaDatosAnexos() {
    let bolFalta = false;
    // verificar si la autorización tiene 10 dígitos
    if (this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_AUTORIZACION.length !== 49) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'El número de autorización debe tener 49 dígitos.'
      });
      bolFalta = true;
      return bolFalta;
    }
    return bolFalta;
  }

  async guardarDatosAnexos() {

    if (await this.verificarTotalImpuestos('guardardatosanex') === false) {
      return;
    }

    if (this.faltaDatosAnexos()) {
      return;
    }

    const itemAnex: any = {};
    itemAnex.TRNCOBRO_NRODOC = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC;
    itemAnex.COM_CODIGO = '01';
    itemAnex.TRNANEX_IDCRETRI = this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_IDCRETRI;
    itemAnex.TRNANEX_TIPCOM = this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPCOM;
    itemAnex.TRNANEX_AUTORIZACION = this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_AUTORIZACION;
    itemAnex.TRNANEX_BASEIMPTARCERO = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPTARCERO);
    itemAnex.TRNANEX_BASEIMPGRABADAIVA = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPGRABADAIVA);
    itemAnex.TRNANEX_MONTOIVA = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOIVA);
    itemAnex.TRNANEX_BASEIMPNOOBJETOIVA = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPNOOBJETOIVA);
    itemAnex.TRNANEX_MONTOICE = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOICE);
    itemAnex.TRNANEX_MONTORETIVABIE = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTORETIVABIE);
    itemAnex.TRNANEX_MONTORETIVASER = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTORETIVASER);
    itemAnex.TRNANEX_RETVACIEN = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_RETVACIEN);
    itemAnex.USUIDENTIFICACION = this.cxptrncobroService.usuario.identificacion;
    itemAnex.TRNANEX_AUTDOCMOD = this.cxptrncobroService.trndatosanexarray[this.indicador].AUTORIZACIONDOCMODIF;
    itemAnex.TRNANEX_SECDOCMOD = this.cxptrncobroService.trndatosanexarray[this.indicador].SECUENCIADOCMODIF;
    itemAnex.TRNANEX_SERIEDOCMOD = this.cxptrncobroService.trndatosanexarray[this.indicador].SERIEDOCMODIF;
    itemAnex.TRNANEX_TIPOSRITARCERO = this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPOSRITARCERO;
    itemAnex.TRNANEX_TIPOSRIGRAVADA = this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPOSRIGRAVADA;
    itemAnex.TRNANEX_TIPCOMMOD = this.cxptrncobroService.trndatosanexarray[this.indicador].TIPOCOMPMODIF;

    try {
      await this.cxptrncobroService.INSERTARDATOSANEX(itemAnex);
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'success',
        summary: 'Información',
        detail: 'Datos de Anexos guardados correctamente'
      });
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: '! Proceso guardar datos de Anexos no terminó satisfactoriamente.... !'
      });
    }
  }

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable
      const dia = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN.getDate();
      const mes = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN.getMonth();
      const anio = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN.getFullYear();

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    if (valor === 'Nuevo') {
      this.nuevo();
    }
    if (valor === 'Guardar') {
      if (this.index === undefined) {
        this.index = 0;
      }

      if (this.index === 0) {
        this.guardar();
      } else {
        this.guardarDatosAnexos();
      }
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      this.busquedaCarteraPasiva();
    }
    if (valor === 'Asiento') {
      this.generarAsientoContable();
    }
    if (valor === 'AsientoVer') {
      this.verAsiento();
    }
    if (valor === 'Anular') {
      this.anularDocumento();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    //CDPJ
    if (valor === 'Imprimir') {
      this.ASI_NRO = this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO;
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
     
     if (valor === 'FormularioCorreos'){
      this.ASI_NRO = this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO;
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogfrmcorreos=true
    }
    
    //CDPJ
  }
  //CDPJ
  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  cerrarfrmcorreos(){
    this.displayDialogfrmcorreos=false;
  }
  //CDPJ
  async seleccionarRegistro(strTipo) {
    if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC === '') {
      if (strTipo === 'A') {
        strTipo = 'P';
      }
      if (strTipo === 'S') {
        strTipo = 'U';
      }
    }
    const sR = await this.cxptrncobroService.seleccionarRegistro(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC, strTipo);
    if (sR !== null) {
      if (sR[0] !== undefined) {
        this.mostrarDatos(sR[0].TRNCOBRO_NRODOC);
      }
    }

  }

  cancelar() {
    const numero = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC;
    this.nuevo();
    document.getElementById('contorno').style.pointerEvents = 'none';
    this.mostrarDatos(numero);

    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = false;
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'txtCodProveedor': {
        this.busquedaproveedor(event.target.value);
        break;
      }
      case 'txtSerie': {
        element = document.getElementById('txtNumero');
        break;
      }
      case 'txtNumero': {
        let strTipoDocumento = '';

        // Tipo de documento
        if (this.selecttipo.name === 'Factura') {
          strTipoDocumento = 'FC';
        }
        if (this.selecttipo.name === 'Nota de Crédito') {
          strTipoDocumento = 'NC';
        }
        if (this.selecttipo.name === 'Nota de Débito') {
          strTipoDocumento = 'ND';
        }


        if (strTipoDocumento === 'NC'
          || strTipoDocumento === 'ND'
          || strTipoDocumento === 'FC') {
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE =
            this.autoCompletar(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, 9);
        }

        if (this.chkSaldoInicial === true) {
          // this.chkSaldoInicial.setFocus();
          element = document.getElementById('chkSaldoInicial');
        } else {
          // dateTimeVencimiento.setFocus();
          element = document.getElementById('txtNumeroDocumento');
        }
        break;
      }
      case 'txtNumeroDocumento': {
        this.listarDocumentos(event.target.value);
        break;
      }
      case 'txtImporte': {
        element = document.getElementById('txtConcepto');
        if (this.selecttipo.name === 'Anticipo') {
          if (String(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC).trim().length === 0) {
            this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SALDOINICIAL =
              this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE;
          }
        }
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  autoCompletar(strCadena, intTamanioCadena): string {
    let intAux = 0;
    let indice = 0;
    let strAutoCompletar = '';

    intAux = strCadena.length;
    for (indice = 1; indice <= intTamanioCadena - intAux; indice++) {
      strAutoCompletar = '0' + strAutoCompletar;
    }

    strAutoCompletar = strAutoCompletar + strCadena;

    return strAutoCompletar;

  }

  busquedaproveedor(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'proveedor';
    this.types = [
      { label: 'Código', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' },
      { label: 'Nombre Corto', value: 'PRO_NOMBREC' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Ruc', value: 'PRO_RUCIDE' },
      { label: 'Cuenta', value: 'CON_CODIGO1' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '';
    this.where = '';
    this.tabla = 'COM_MAEPROVEEDOR';


    this.cxptrncobroService.erProNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          const opcion = {
            PRO_CODIGO: parametro,
            PRO_NOMBRE: eR[0].PRO_NOMBRE
          };
          this.manejarSeleccion(opcion);
        } else {
          this.busqService.busquedaComMaeProveedor3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaComMaeProveedor3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaCarteraPasiva() {
    this.opcionbusqueda = 'cartera';    
    this.busquedaCompuesta = false;
    this.types = [
      { label: 'NroDoc', value: 'TRNCOBRO_NRODOC' },
      { label: 'NroComprobante', value: 'TRNCOBRO_NROCOMPROBANTE' },
      { label: 'FechaEmision', value: 'TRNCOBRO_FECHATRN' },
      { label: 'TipoDoc', value: 'TRNCOBRO_TIPODOC' },
      { label: 'CódigoProv', value: 'PRO_CODIGO' },
      { label: 'NombreProveedor', value: 'PRO_NOMBRE' },
      { label: 'Concepto', value: 'TRNCOBRO_CONCEPTO' },//GSRF
    ];
    this.busquedacampos = ['', '', '', '', '', '', ''];
    this.consulta = 'TRNCOBRO_NRODOC,TRNCOBRO_NROCOMPROBANTE,TRNCOBRO_FECHATRN,TRNCOBRO_TIPODOC,PRO_CODIGO,COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) AS PRO_NOMBRE,TRNCOBRO_CONCEPTO';
    this.where = '';
    this.tabla = 'CXP_TRNCOBRO';


    this.busqService.busquedaCXPTrnCobro2().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.TRNCOBRO_FECHATRN !== null) {
          registro.TRNCOBRO_FECHATRN = this.datePipe.transform(registro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        } else {
          registro.TRNCOBRO_FECHATRN = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });

  }


  listarDocumentos(parametro) {
    if (parametro === null) {
      parametro = '';
    }
    this.busquedaCompuesta = false;//GSRF
    this.opcionbusqueda = 'documentos';
    this.types = [
      { label: 'NroDoc', value: 'TRNCOBRO_NRODOC' },
      { label: 'Comprobante', value: 'TRNCOBRO_NROCOMPROBANTE' },
      { label: 'CodProveedor', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' },
      { label: 'Saldo', value: 'SALDO' }
    ];
    this.busquedacampos = [parametro, '', '', '', '',''];
    this.consulta = 'TRNCOBRO_NRODOC,TRNCOBRO_NROCOMPROBANTE,PRO_CODIGO,' +
      'COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) AS PRO_NOMBRE,' +
      'CXP_FNC_SALDODOCUMENTO(TRNCOBRO_NRODOC,COM_CODIGO,PRO_CODIGO) AS SALDO, COM_CODIGO, TRNCOBRO_TIPODOC';
    this.where = 'TRNCOBRO_TIPODOC IN (\'FC\',\'ND\')' ;
    //+' AND CXP_FNC_SALDODOCUMENTO(TRNCOBRO_NRODOC,COM_CODIGO,PRO_CODIGO)>0';
    this.where = this.where + ' and com_codigo=\'01\'';
    this.where = this.where + ' and PRO_CODIGO=\'' + this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO + '\'';
    this.tabla = 'CXP_TRNCOBRO';

    // this.cxptrncobroService.erDocumentos(this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO, parametro)
    this.cxptrncobroService.erDocumentos(this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaCXPTrnCobro3(this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaCXPTrnCobro3(this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  seleccionVarios(params) {
    if (params === 'nuevo') {
      this.nuevoElemento();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarVarios();
      return;
    }

    if (params.object !== undefined) {
      this.selectedDetTrnCobro = params.object;
      console.log(this.selectedDetTrnCobro);
    }
  }

  nuevoElemento() {
    let item: CxpVarios = {};
    let strConCodigo = '';
    let intFilaSel;

    intFilaSel = this.cxptrncobroService.variosarray[this.indicador].indexOf(this.selectedDetTrnCobro);
    if (intFilaSel >= 0) {
      item = this.cxptrncobroService.variosarray[this.indicador][intFilaSel];
      strConCodigo = item.CONCODIGO;
    }

    if (strConCodigo !== '' || intFilaSel === -1) {
      item = {};
      item.CLAVE = (intFilaSel) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      item.DEBE = 0;
      item.DEBE = item.DEBE.toFixed(2);
      item.TRNCOBROIMPORTE = 0;
      item.TRNCOBROIMPORTE = item.TRNCOBROIMPORTE.toFixed(2);
      item.HABER = 0;
      item.HABER = item.HABER.toFixed(2);
      item.DETTRNCOBRONROSEC = 0;
      item.DETTRNCOBRONROSEC = item.DETTRNCOBRONROSEC.toFixed(2);
      item.BANCODIGO = '';
      item.CENCODIGO = '';
      item.COMCODIGO = '01';
      item.CONCENTRO = '';
      item.CONCODIGO = '';
      item.CONNOMBRE = '';
      item.CXCTRNCOBROCLICODIGO = '';
      item.CXCTRNCOBROCOMCODIGO = '';
      item.CXCTRNCOBRONRODOC = '';
      item.DETTRNCOBROREFERENCIA = '';
      item.ENCFACCLICODIGO = '';
      item.ENCFACCOMCODIGO = '';
      item.ENCFACNUMERO = '';
      item.PROCODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
      item.RETENCIONCODIGO = '';
      item.TRNBANNUMERO = '';
      item.TRNBANTIPO = '';
      item.TRNCOBRONRODOC = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC;
      item.TRNRETENCIONNRO = '';
      item.TIPGASCODIGO = '';

      this.selectedDetTrnCobro = item;
      if (intFilaSel === -1) {
        this.cxptrncobroService.variosarray[this.indicador].push(item);
      } else {
        this.cxptrncobroService.variosarray[this.indicador].splice(intFilaSel + 1, 0, item);
      }

      this.aggrid.refreshaggrid(this.cxptrncobroService.variosarray[this.indicador], this.defaultColDefVarios.objeto);
      if (intFilaSel === -1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefVarios.objeto].setFocusedCell(this.cxptrncobroService.variosarray[this.indicador].length - 1, 'CENCODIGO');//GSRF
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefVarios.objeto].setFocusedCell(this.cxptrncobroService.variosarray[this.indicador].length - 1, 'CONCODIGO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefVarios.objeto].setFocusedCell(intFilaSel + 1, 'CENCODIGO');//GSRF
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefVarios.objeto].setFocusedCell(intFilaSel + 1, 'CONCODIGO');
      }
      this.botonesmodificar();
      /*
      frmCxpTrnCobro.getTableVarios().setSelection(1, intFilaSel + 1, true);
      frmCxpTrnCobro.getTableVarios().openEditorInFocus();
      frmCxpTrnCobro.getTableVarios().redraw();
      
      frmCxpTrnCobro.activaBotones('N', false, true, true, false, false, false, false, false, false, false, false, false);
      frmCxpTrnCobro.activarBotonesDetalle(false, false, true);
      */
    } else {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'Ingrese todos los campos'
      });
    }
  }

  async eliminarVarios() {
    const intFilaSel = this.cxptrncobroService.variosarray[this.indicador].indexOf(this.selectedDetTrnCobro);
    const item = this.cxptrncobroService.variosarray[this.indicador][intFilaSel];
    console.log(intFilaSel);
    console.log(item);
    console.log(this.selectedDetTrnCobro);




    try {
      if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC !== '') {
        await this.cxptrncobroService.eliminaDettrncobro(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC,
          item.DETTRNCOBRONROSEC);
        this.auditoriagral.registrarAuditoria('CXP_DETTRNCOBRO',
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC, 'E',
          '', '01', '', '', '', '').subscribe();

        this.cxptrncobroService.variosarray[this.indicador].splice(this.cxptrncobroService.variosarray[this.indicador].findIndex(
          item => item.CLAVE === this.selectedDetTrnCobro.CLAVE),
          1);

        this.aggrid.refreshaggrid(this.cxptrncobroService.variosarray[this.indicador], this.defaultColDefVarios.objeto);
      } else {
        this.cxptrncobroService.variosarray[this.indicador] = this.cxptrncobroService.variosarray[this.indicador].filter((val, j) => j !==
          this.cxptrncobroService.variosarray[this.indicador].indexOf(this.selectedDetTrnCobro));
        this.aggrid.refreshaggrid(this.cxptrncobroService.variosarray[this.indicador], this.defaultColDefVarios.objeto);
      }
      /*console.log(intFilaSel, item.DETTRNCOBRONROSEC);
      this.cxptrncobroService.variosarray[this.indicador].splice(intFilaSel, 1);*/
      //this.calcularTotalImporte();
      //CDPJ
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'success',
        summary: 'Información',
        detail: 'El registro se eliminó exitosamente'
      });
      this.cxptrncobroService.calcularImporteTotal();
      //CDPJ


      /*
        frmCxpTrnCobro.getTableVarios().redraw();
        frmCxpTrnCobro.getTableVarios().setSelection( 1, intFilaSel, true );
        frmCxpTrnCobro.activaBotones("N",false,true,true,false,false,false,false,false,false,false,false,false);
        frmCxpTrnCobro.activarBotonesDetalle(true,true,false);
      */
    } catch (error) {
      console.log(error.error);
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: '! Proceso eliminar datos de varios no terminó satisfactoriamente.... !'
      });
    }
    return true;
  }

  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;

    if (this.chkSaldoInicial) {
      this.botonNuevoVarios = false;
      this.botonRegresarVarios = false;
      this.botonBorrarVarios = false;

      if (this.selecttipo.name === 'Anticipo') {
        this.boltxtSerie = true;
        this.boltxtNumero = true;
        this.tabItemFPAnt = false;
      }
    } else {
      this.botonNuevoVarios = true;
      this.botonRegresarVarios = true;
      this.botonBorrarVarios = true;

      if (this.selecttipo.name === 'Anticipo') {
        this.tabItemFPAnt = true;
        this.tabDocumentos = 0;  
        this.boltxtSerie = false;
        this.boltxtNumero = false;
      }
    }
  }

  seleccionFpant(params) {
    if (params === 'nuevo') {
      this.nuevaLineaFPAnt();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaFPAnt();
      return;
    }

    if (params.object !== undefined) {
      this.selectedFpAnt = params.object;
    }
  }

  seleccionDocumentos(params) {
    if (params === 'nuevo') {
      return;
    }

    if (params.object !== undefined) {
      this.selectedDocumento = params.object;
    }
  }

  seleccionFpDev(params) {
    if (params === 'nuevo') {
      this.nuevaLineaFPDev();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaFPDev();
      return;
    }

    if (params.object !== undefined) {
      this.selectedFpDev = params.object;
    }
  }

  nuevaLineaFPDev() {
    let intFilaSel;

    intFilaSel = this.cxptrncobroService.fpdevarray[this.indicador].indexOf(this.selectedFpDev);
    console.log(this.selectedFpDev);
    console.log(intFilaSel);

    const item: CxpFpdev = {};
    item.CLAVE = (intFilaSel) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    item.BANCLICODIGO = '';
    item.BANCLINOMBRE = '';
    item.CAJCODIGO = '';
    item.CLICODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
    item.COMCODIGO = '01';
    item.CONCODIGO = '';
    item.CXPTRNCOBROCODIGO = '';
    item.TRNCOBRONROCOMPROBANTE = '';
    item.TRNCOBRONRODOC = '';
    item.TRNPAGOCONCEPTO = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_CONCEPTO;
    item.TRNPAGOFECHA = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    item.TRNPAGOFLAG = 1;
    item.TRNPAGONROCTA = '';
    item.TRNPAGOREFER = '';
    item.TRNPAGOTIPO = '';

    if (this.cxptrncobroService.fpdevarray[this.indicador].length === 0) {
      item.TRNPAGOIMPORTE = this.cxptrncobroService.trncobroarray[this.indicador].TOTALES;
    } else {
      const dblImporte = Number(this.cxptrncobroService.trncobroarray[this.indicador].TOTALES) - this.obtenerTotalPagadoDev();

      if (dblImporte > 0) {
        item.TRNPAGOIMPORTE = dblImporte;
      } else if (dblImporte === 0) {
        return;
      }
    }
    item.TRNPAGONUMERO = '';
    this.selectedFpDev = item;
    if (intFilaSel === -1) {
      this.cxptrncobroService.fpdevarray[this.indicador].push(item);
    } else {
      this.cxptrncobroService.fpdevarray[this.indicador].splice(intFilaSel + 1, 0, item);
    }

    this.aggrid.refreshaggrid(this.cxptrncobroService.fpdevarray[this.indicador], this.defaultColDefFPDev.objeto);
    if (intFilaSel === -1) {
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefFPDev.objeto].setFocusedCell(this.cxptrncobroService.fpdevarray[this.indicador].length - 1, 'TRNPAGOTIPO');
    } else {
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefFPDev.objeto].setFocusedCell(intFilaSel + 1, 'TRNPAGOTIPO');
    }

    /*
      if ( item.getTrnPagoTipo().length() > 0 && item.getTrnPagoImporte() > 0 )
      {
        dlgCxP.btnNuevoFPDev.setEnabled(true);
        dlgCxP.btnEliminarFPDev.setEnabled(false);
        dlgCxP.btnCancelarFPDev.setEnabled(false);
      }
      else{
        dlgCxP.btnNuevoFPDev.setEnabled(false);
        dlgCxP.btnEliminarFPDev.setEnabled(true);
        dlgCxP.btnCancelarFPDev.setEnabled(true);
      }*/
  }

  nuevaLineaFPAnt() {
    let intFilaSel;

    intFilaSel = this.cxptrncobroService.fpantarray[this.indicador].indexOf(this.selectedFpAnt);

    const item: CxpFpant = {};

    item.CLAVE = (intFilaSel) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    item.TIPODOC = '';
    item.CAJADOC = '';
    item.BANBANCO = '';
    item.BANCODIGO = '';
    item.COMCODIGO = '01';
    item.CONCODIGO = '';
    item.FECHADOC = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    item.TRNCOBRONROCOMPROBANTE = '';
    item.TRNCOBRONRODOC = '';
    item.CONCEPTODOC = '';
    item.IMPORTEDOC = 0;
    item.IMPORTEDOC = item.IMPORTEDOC.toFixed(2);
    item.NUMERODOC = '';
    item.PAGOELECTRONICO = false;
    // item.PAGOELECTRONICO = 0;

    console.log(this.cxptrncobroService.trncobroarray[this.indicador]);
    if (this.cxptrncobroService.fpantarray[this.indicador].length === 0) {
      item.IMPORTEDOC = Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE);
    } else {
      const dblImporte = Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE) - this.obtenerTotalPagado();

      if (dblImporte > 0) {
        item.IMPORTEDOC = dblImporte.toFixed(2);
      } else if (dblImporte === 0) {
        return;
      }
    }

    this.selectedFpAnt = item;
    if (intFilaSel === -1) {
      this.cxptrncobroService.fpantarray[this.indicador].push(item);
    } else {
      this.cxptrncobroService.fpantarray[this.indicador].splice(intFilaSel + 1, 0, item);
    }

    this.aggrid.refreshaggrid(this.cxptrncobroService.fpantarray[this.indicador], this.defaultColDefFPAnt.objeto);
    if (intFilaSel === -1) {
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefFPAnt.objeto].setFocusedCell(this.cxptrncobroService.fpantarray[this.indicador].length - 1, 'TIPODOC');
    } else {
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefFPAnt.objeto].setFocusedCell(intFilaSel + 1, 'TIPODOC');
    }

    this.botonesmodificar();

    /*
    if ( item.getTipoDoc().length() > 0 && item.getImporteDoc() > 0 )
    {
      dlgCxP.btnNuevoFPAnt.setEnabled(true);
      dlgCxP.btnEliminarFPAnt.setEnabled(false);
      dlgCxP.btnCancelarFPAnt.setEnabled(false);
    }
    else{
      dlgCxP.btnNuevoFPAnt.setEnabled(false);
      dlgCxP.btnEliminarFPAnt.setEnabled(true);
      dlgCxP.btnCancelarFPAnt.setEnabled(true);
    }*/
  }

  async eliminarLineaFPAnt() {

    const intIndex = this.cxptrncobroService.fpantarray[this.indicador].indexOf(this.selectedFpAnt) + 1;
    console.log(this.selectedFpAnt);
    console.log(intIndex);

    let bolEliminar = false;

    if (intIndex > 0 && this.cxptrncobroService.fpantarray[this.indicador].length >= 1) {
      // verificar que el cheque no haya sido conciliado
      if (this.cxptrncobroService.fpantarray[this.indicador][intIndex - 1].TIPODOC === 'CH') {
        if (await this.documentoConciliado(this.cxptrncobroService.fpantarray[this.indicador][intIndex - 1].NUMERODOC,
          this.cxptrncobroService.fpantarray[this.indicador][intIndex - 1].BANCODIGO,
          this.cxptrncobroService.fpantarray[this.indicador][intIndex - 1].TIPODOC)) {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Información',
            detail: 'No puede eliminar el pago, el cheque ' +
              this.cxptrncobroService.fpantarray[this.indicador][intIndex - 1].NUMERODOC + ' está conciliado.'
          });
          return;
        }
      }
      this.cxptrncobroService.fpantarray[this.indicador].splice(intIndex - 1, 1);

      this.aggrid.refreshaggrid(this.cxptrncobroService.fpantarray[this.indicador], this.defaultColDefFPAnt.objeto);

      bolEliminar = true;

      if (bolEliminar) {
        /*dlgCxP.btnNuevoFPAnt.setEnabled(true); //Nueva Linea
        dlgCxP.btnEliminarFPAnt.setEnabled(true); //Eliminar Linea
        dlgCxP.btnCancelarFPAnt.setEnabled(false); //Cancelar Linea*/
        this.obtenerTotalPagado();
        /*dlgCxP.activaBotones("M",false,true,true,false,false,false,false,false,false,false,false,false);
      
        if ( intIndex > 0)
          dlgCxP.getTablaFPAnt().setSelection( 1, intIndex - 1, true );
        else
          dlgCxP.getTablaFPAnt().setSelection( 1, intIndex , true );
      */
      }
    } else {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'Seleccione la fila que desea eliminar.'
      });
    }
  }

  async eliminarLineaFPDev() {

    const intIndex = this.cxptrncobroService.fpdevarray[this.indicador].indexOf(this.selectedFpDev) + 1;
    console.log(this.selectedFpDev);
    console.log(intIndex);

    let bolEliminar = false;

    if (intIndex > 0 && this.cxptrncobroService.fpdevarray[this.indicador].length >= 1) {

      // verificar que el cheque no haya sido depositado
      if (this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].TRNPAGOTIPO === 'CH') {
        const strNumDep = await this.chequeDepositadoUtil(this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].TRNPAGONUMERO,
          this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].BANCLICODIGO,
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
        if (strNumDep !== null) {
          if (strNumDep.length > 0) {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Información',
              detail: 'No puede eliminar el cobro, el cheque ' +
                this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].TRNPAGONUMERO +
                ' está depositado.\r\rNro. Depósito: ' + strNumDep,
            });
            return;
          }
        }
      }


      // verificar que el efectivo no haya sido depositado
      if (this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].TRNPAGOTIPO === 'EF') {
        const strNumDep = await this.efectivoDepositadoUtil(this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].TRNPAGONUMERO,
          this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].CAJCODIGO,
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
          if (strNumDep !== null) {
            if (strNumDep.length > 0) {
              this.messageService.add({
                key: 'cxptrncobro',
                severity: 'error',
                summary: 'Información',
                detail: 'No puede eliminar el cobro, el efectivo ' +
                  this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].TRNPAGONUMERO + ' está depositado.\r\rNro. Depósito: ' + strNumDep
              });
              return;
            }
          }
        
      }
      // verificar que el cheque no haya sido conciliado
      if (this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].TRNPAGOTIPO === 'DP') {
        if (await this.documentoConciliado(this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].TRNPAGONUMERO,
          this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].BANCLICODIGO,
          this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].TRNPAGOTIPO)) {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Información',
            detail: 'No puede eliminar el pago, el depósito ' +
              this.cxptrncobroService.fpdevarray[this.indicador][intIndex - 1].TRNPAGONUMERO + ' está conciliado.'
          });
          return;
        }
      }
      this.cxptrncobroService.fpdevarray[this.indicador].splice(intIndex - 1, 1);

      this.aggrid.refreshaggrid(this.cxptrncobroService.fpdevarray[this.indicador], this.defaultColDefFPDev.objeto);

      bolEliminar = true;

      if (bolEliminar) {
        /*dlgCxP.btnNuevoFPAnt.setEnabled(true); //Nueva Linea
        dlgCxP.btnEliminarFPAnt.setEnabled(true); //Eliminar Linea
        dlgCxP.btnCancelarFPAnt.setEnabled(false); //Cancelar Linea*/
        this.obtenerTotalPagadoDev();
        /*dlgCxP.activaBotones("M",false,true,true,false,false,false,false,false,false,false,false,false);
      
        if ( intIndex > 0)
          dlgCxP.getTablaFPAnt().setSelection( 1, intIndex - 1, true );
        else
          dlgCxP.getTablaFPAnt().setSelection( 1, intIndex , true );
      */
      }
    } else {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'Seleccione la fila que desea eliminar.'
      });
    }
  }

  cambio(cambio) {
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = false;
    }
  }

  seleccionarItem() {

  }

  llenarCombos() {

    this.cmbtipo = [{ codigo: 'NC', name: 'Nota de Crédito' },
    { codigo: 'ND', name: 'Nota de Débito' },
    { codigo: 'FC', name: 'Factura' },
    { codigo: 'ANP', name: 'Anticipo' },
    { codigo: 'DANP', name: 'Dev. de Anticipos' }];
    this.selecttipo = this.cmbtipo[0];
  }

  limpiarDatos(bolCambioDoc) {
    const fechaActual = new Date();

    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN = fechaActual;
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHAVENCE = fechaActual;
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHARECEPCION = fechaActual;

    this.intNOIMPCXP = 0;

    if (!bolCambioDoc) {
      this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO = '';
      this.cxptrncobroService.trncobroarray[this.indicador].PRO_NOMBRE = '';
      this.strCodigoConPro = '';
    }
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_CONCEPTO = '';
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE = '';
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SERIE = '';
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA = '';
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC = '';
    this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO = '';
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SALDOINICIAL = '';
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE = Number('0').toFixed(2);

    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
    this.cxptrncobroService.trncobroarray[this.indicador].ENCFACPRO_REFERENCIA = '';
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_CODFORPAG = '';
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_DESFORPAG = '';

    this.chkSaldoInicial = false;
    this.botonNuevoVarios = true;
    this.botonRegresarVarios = true;
    this.botonBorrarVarios = true;

    this.boltxtSerie = true;
    this.boltxtNumero = true;
    this.boldateTimeRecepcion = true;
    this.boldateTimeVencimiento = true;
    this.boltxtImporte = true;
    this.boldateTimeFechaEmi = true;
    this.discodproveedor = false;

    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPRESO = '';
    this.cxptrncobroService.trncobroarray[this.indicador].ANULADO = '';

    this.limpiarDatosAnexos();

    this.cxptrncobroService.variosarray[this.indicador] = [];
    this.cxptrncobroService.docarray[this.indicador] = [];
    this.cxptrncobroService.fpantarray[this.indicador] = [];
    this.cxptrncobroService.fpdevarray[this.indicador] = [];
    this.cxptrncobroService.ecantarray[this.indicador] = [];

    if (this.tabItemDoc === true) {
      this.cxptrncobroService.docarray[this.indicador] = [];
    }

    this.tabItemVarios = true;

    this.bolchkSaldoInicial = false;
    this.boltxtNumeroDocumento = true;
    this.bolbtnBuscarDocumento = true;
    this.cmbasiento = [{ codigo: '', name: '' }];

  }

  limpiarDatosAnexos() {

    this.cxptrncobroService.trndatosanexarray[this.indicador].IDSUSTRIB = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].DESIDSUSTRIB = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].IDPROVEEDOR = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].PERIODOINF = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].DESPERIODOINF = '';

    this.cxptrncobroService.trndatosanexarray[this.indicador].FECHACONTAB = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].SERIECOMP = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].SECUENCIALCOMP = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].FECHAEMISIONCOMP = '';

    this.cxptrncobroService.trndatosanexarray[this.indicador].TIPOCOMPMODIF = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].DESCTIPOCOMPMODIFICADO = '';

    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_IDCRETRI = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].DESCIDCRETRI = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPCOM = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].DESCTIPOCOMP = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].AUTSRI_AUTORIZASRI = '';

    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPTARCERO = Number('0').toFixed(2);
    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPGRABADAIVA = Number('0').toFixed(2);
    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOIVA = Number('0').toFixed(2);
    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPNOOBJETOIVA = Number('0').toFixed(2);
    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOICE = Number('0').toFixed(2);
    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTORETIVABIE = Number('0').toFixed(2);
    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_RETVACIEN = Number('0').toFixed(2);
    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTORETIVASER = Number('0').toFixed(2);

    this.cxptrncobroService.trndatosanexarray[this.indicador].SERIEDOCMODIF = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].SECUENCIADOCMODIF = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].AUTORIZACIONDOCMODIF = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPOSRIGRAVADA = '';
    this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPOSRITARCERO = '';

  }


  eliminarPestanias() {
    this.tabItemFPAnt = false;
    this.tabItemECAnt = false;
    this.tabItemVarios = false;
    this.tabItemDoc = false;
    this.tabItemFPDev = false;
  }

  mostrarPestanias() {
    if (this.selecttipo.name !== 'Anticipo' && this.selecttipo.name !== 'Dev. de Anticipos') {
      this.tabItemVarios = true;
    } else if (this.selecttipo.name === 'Anticipo') {
      if (this.chkSaldoInicial === false) {
        this.tabItemFPAnt = true;
      }
      this.diseniarPestaniaECAnt();

    } else if (this.selecttipo.name === 'Dev. de Anticipos') {
      this.tabItemDoc = true;
      this.tabItemFPDev = true;
    }

    this.tabDocumentos = 0;
  }


  diseniarPestaniaECAnt() {
    this.tabItemECAnt = true;
    this.cxptrncobroService.ecantarray[this.indicador] = [];
    this.consultarECAnticipo();
    /*setSpecialRowColor(listaEC.size(), new Color( composite.getDisplay(), 255,255,170)); //color al total
    setColumnAlignment(5, DERECHA);*/
  }

  consultarECAnticipo() {
    this.consultarEstadoCuentaAnt();
    this.agTable();
  }

  async consultarEstadoCuentaAnt() {
    this.cxptrncobroService.ecantarray[this.indicador] = [];
    if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC.length === 0) {
      return;
    }
    try {
      const data = await this.cxptrncobroService.EstadoCuentaAnticipo(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
      if (data === null) {
        return;
      }
      for (const rs of data) {
        const item: CxpEcant = {};
        item.TRNCOBRONROCOMPROBANTE = rs.DOCUMENTO;
        item.TRNCOBROTIPODOC = rs.TIPO;
        item.TRNCOBROFECHATRN = this.datePipe.transform(rs.FECHA, 'dd/MM/yyyy');
        item.TRNCOBROCONCEPTO = rs.CONCEPTO;
        item.TRNCOBROIMPORTE = rs.IMPORTE;
        item.TRNCOBROIMPORTE = item.TRNCOBROIMPORTE.toFixed(2);
        this.cxptrncobroService.ecantarray[this.indicador].push(item);
      }




    } catch (error1) {
      // let mensaje: string;
      // mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: error1
      });
    }

    this.calcularTotalImporte();
  }

  calcularTotalImporte() {
    let dblTotal = 0;

    for (let fila = 0; fila < this.cxptrncobroService.ecantarray[this.indicador].length; fila++) {
      dblTotal = dblTotal + Number(this.cxptrncobroService.ecantarray[this.indicador][fila].TRNCOBROIMPORTE);
    }

    const item: CxpEcant = {};
    item.TRNCOBROIMPORTE = dblTotal;
    item.TRNCOBROIMPORTE = item.TRNCOBROIMPORTE.toFixed(2);
    item.TRNCOBRONROCOMPROBANTE = '';
    item.TRNCOBROTIPODOC = '';
    item.TRNCOBROFECHATRN = '';
    item.TRNCOBROCONCEPTO = '';

    this.cxptrncobroService.ecantarray[this.indicador].push(item);

    this.cxptrncobroService.ecantarray[this.indicador].map(item => {
      item.TRNCOBROCONCEPTO = item.TRNCOBROCONCEPTO
    })


    /*if (this.cxptrncobroService.ecantarray[this.indicador].length >= 2) {
      document.getElementById('contorno').style.pointerEvents = 'none';
    } else {
      document.getElementById('contorno').style.pointerEvents = 'all';
    } */


    this.aggrid.refreshaggrid(this.cxptrncobroService.ecantarray[this.indicador], this.defaultColDefECAnt.objeto);
  }

  cargarAnticiposPend() {
    if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC.length === 0) {
      this.consultarDocumentosPorCobrar('ANP',
        'UPPER(PRO_CODIGO) LIKE \''
        + this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO + '\'');
    }
  }

  consultarDocumentosPorCobrar(strOpcion, strCondicion) {
    this.cxptrncobroService.CONSULTARDATOS(strOpcion, 'COM_CODIGO LIKE \'01\'', strCondicion).subscribe(data => {
      this.cxptrncobroService.docarray[this.indicador] = [];
      if (data === null) {
        return;
      }
      for (const rs of data) {
        if (rs.SALDO > 0.0099999) {
          const item: CxpDocs = {};
          item.TRNCOBRONRODOC = rs.DOCUMENTO;
          item.TRNCOBROFECHATRN = this.datePipe.transform(rs.FECHA, 'dd/MM/yyyy');
          item.TRNCOBROCONCEPTO = rs.CONCEPTO;
          item.TRNCOBROSALDO = rs.SALDO.toFixed(2);
          item.APLORGCODIGO = rs.APLORG_CODIGO;
          item.TRNCOBROTIPODOC = rs.TRNCOBRO_TIPODOC;
          this.cxptrncobroService.docarray[this.indicador].push(item);
        }
      }
      if (this.cxptrncobroService.docarray[this.indicador].length > 0) {
        for (let fila = 1; fila <= this.cxptrncobroService.docarray[this.indicador].length; fila++) {
          // colorear las filas si las facturas pertenecen a una cuota
          if (this.cxptrncobroService.docarray[this.indicador][fila - 1].APLORGCODIGO === 'SISTEMA' &&
            this.cxptrncobroService.docarray[this.indicador][fila - 1].TRNCOBROTIPODOC === 'FC') {
            //  setSpecialRowColor(fila, new Color(dlgCxP.getParent().getDisplay(), 255, 255, 170));
          }
          // desactivar casillas para los documentos presentados
          this.cxptrncobroService.docarray[this.indicador][fila - 1].SELECCIONADO = false;
        }
      }
    });
  }

  seleccionarTipo(accion) {
    this.limpiarDatos(true);
    this.eliminarPestanias();
    this.mostrarPestanias();

    if (this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO.length === 0 && accion !== 'nuevo') {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'Escoja el proveedor.'
      });
      this.eliminarPestanias();
      this.tabItemVarios = true;
      return;
    }

    this.boltxtFormaPagoSri = false;
    this.bollblDesFormaPago = false;
    this.bolbtnFormaPagoSRI = false;

    this.botonNuevoVarios = true;
    this.botonBorrarVarios = true;
    this.botonRegresarVarios = true;

    if (this.selecttipo.name === 'Factura') {
      this.bolchkSaldoInicial = true;
      this.chkSaldoInicial = true;
      this.boltxtNumeroDocumento = false;
      this.bolbtnBuscarDocumento = false;
      // toolBarNavegarDetalle.setEnabled(false);
      this.cxptrncobroService.variosarray[this.indicador] = [];
      this.botonNuevoVarios = false;
      this.botonBorrarVarios = false;
      this.botonRegresarVarios = false;
    } else {
      if (this.selecttipo.name === 'Nota de Crédito') {
        this.bolchkSaldoInicial = false;
        this.chkSaldoInicial = false;
        this.boltxtNumeroDocumento = true;
        this.bolbtnBuscarDocumento = true;
        document.getElementById('txtNumeroDocumento').focus();
        // toolBarNavegarDetalle.setEnabled(true);
      } else if (this.selecttipo.name === 'Anticipo') {
        this.bolchkSaldoInicial = true;
        this.boltxtNumeroDocumento = false;
        this.bolbtnBuscarDocumento = false;
        if (!this.chkSaldoInicial) {
          this.boltxtSerie = false;
          this.boltxtNumero = false;
        } else {
          this.boltxtSerie = false;
          this.boltxtNumero = false;
        }
        document.getElementById('txtImporte').focus();
        // lblSaldoDocumento.setText(Validations.numberValidation('0', 15, SystemDecimals.getSystemDecimals(), true, true));
      } else if (this.selecttipo.name === 'Dev. de Anticipos') {
        this.bolchkSaldoInicial = false;
        this.boltxtNumeroDocumento = false;
        this.bolbtnBuscarDocumento = false;
        this.boltxtSerie = false;
        this.boltxtNumero = false;
        this.boldateTimeRecepcion = false;
        this.boldateTimeVencimiento = false;
        this.boltxtImporte = false;
        this.cargarAnticiposPend();
      } else if (this.selecttipo.name === 'Nota de Débito') {
        this.bolchkSaldoInicial = false;
        this.boltxtNumeroDocumento = true;
        this.bolbtnBuscarDocumento = true;
        this.boltxtSerie = true;
        this.boltxtNumero = true;
        this.boldateTimeRecepcion = true;//CDPJ ANTES FALSE
        this.boldateTimeVencimiento = true;//CDPJ ANTES FALSE
        this.boltxtImporte = true;
        this.boltxtFormaPagoSri = true;
        this.bollblDesFormaPago = true;
        this.bolbtnFormaPagoSRI = true;
        document.getElementById('txtNumeroDocumento').focus();
      } else {
        this.bolchkSaldoInicial = true;
        this.chkSaldoInicial = false;
        this.boltxtNumeroDocumento = false;
        this.bolbtnBuscarDocumento = false;
        // toolBarNavegarDetalle.setEnabled(true);
      }
    }

    this.keycmbtipo(accion);
  }

  keycmbtipo(accion) {
    let strTipoDocumento = '';

    // Tipo de documento
    if (this.selecttipo.name === 'Nota de Crédito') {
      strTipoDocumento = '04';
    }
    if (this.selecttipo.name === 'Nota de Débito') {
      strTipoDocumento = '05';
    }
    if (this.selecttipo.name === 'Factura') {
      strTipoDocumento = '01';
    }
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SERIE = '';
    this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';

    if ((strTipoDocumento === '01'
      || strTipoDocumento === '04'
      || strTipoDocumento === '05') && accion !== 'nuevo') {
      // this.extrarSerieTipoDoc(strTipoDocumento);
    }
    document.getElementById('txtSerie').focus();
  }

  extrarSerieTipoDoc(strTipoDoc) {


    let strCondicion = '';
    const strFechaEmision = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');

    strCondicion = 'pro_codigo=\'' + this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO + '\''
      + ' and autsri_tipodoc=\'' + strTipoDoc + '\' and ';
    strCondicion = strCondicion + ' autsri_fechaini<=to_date(\''
      + strFechaEmision
      + '\',\'dd/mm/yyyy\') and autsri_fechafin >= to_date(\''
      + strFechaEmision + '\',\'dd/mm/yyyy\') ';

    this.cxptrncobroService.erAutSriSerieEstab(this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO,
      strTipoDoc, strFechaEmision).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SERIE = er[0].AUTSRI_SERIEESTAB + er[0].AUTSRI_SERIEPTOEMI;
            this.cxptrncobroService.trndatosanexarray[this.indicador].AUTSRI_AUTORIZASRI = er[0].AUTSRI_AUTORIZASRI;
          } else {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Información',
              detail: 'No existe la numeración para este tipo de documento, ' +
                'por favor verifique la información en el maestro del proveedor.'
            });
          }
        } else {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Información',
            detail: 'No existe la numeración para este tipo de documento, ' +
              'por favor verifique la información en el maestro del proveedor.'
          });
        }
      });

  }

  buscarFPSri(data) {

  }

  async manejarSeleccion(opcion) {

    if (this.opcionbusqueda === 'cartera') {
      this.mostrarDatos(opcion.TRNCOBRO_NRODOC);
    }
    if (this.opcionbusqueda === 'proveedor') {
      this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO = opcion.PRO_CODIGO;
      this.cxptrncobroService.trncobroarray[this.indicador].PRO_NOMBRE = opcion.PRO_NOMBRE;
      this.desplegarDatosProveedor();
      document.getElementById('txtSerie').focus();
    }
    if (this.opcionbusqueda === 'documentos') {
      this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA = opcion.TRNCOBRO_NRODOC;
      this.cxptrncobroService.trncobroarray[this.indicador].ENCFACPRO_REFERENCIA = opcion.TRNCOBRO_NROCOMPROBANTE;
      const dblSaldo = await this.saldoDocumento(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA,
        this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO);
      const dblImporte = await this.ImporteDocumento(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA);

      this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SALDOINICIAL = dblSaldo.toFixed(2); // detpagoDoc
      this.cxptrncobroService.trncobroarray[this.indicador].IMPORTEDOC = dblImporte.toFixed(2); // detpagoDoc

      document.getElementById('txtImporte').focus();
    }

    if (this.opcionbusqueda === 'IDCRETRI') {
      this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_IDCRETRI = opcion.CODSRI_CODIGO;
      this.cxptrncobroService.trndatosanexarray[this.indicador].DESCIDCRETRI = opcion.CODSRI_DESCRIPCION;
    }

    if (this.opcionbusqueda === 'tipcom') {
      this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPCOM = opcion.CODSRI_CODIGO;
      this.cxptrncobroService.trndatosanexarray[this.indicador].DESCTIPOCOMP = opcion.CODSRI_DESCRIPCION;
    }

    if (this.opcionbusqueda === 'txtTipoSriTarCero') {
      this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPOSRITARCERO = opcion.REF_CODIGO;
    }

    if (this.opcionbusqueda === 'txtTipoSriGravada') {
      this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPOSRIGRAVADA = opcion.REF_CODIGO;
    }


    this.salirDialog();
  }

  salirDialog() {
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
    this.displayDialogAutorizacion = false;
    this.displayAsiContable = false;
    this.displayAcciones = false;
    this.displayDialogDatosAnexos = false;
  }

  async mostrarDatos(strTrnCobroNroDoc) {

    let strTipoTrans = '';
    let strSaldoInicial = '';
    let StrImpreso = '';
    //    let intNumLinReq = 0;

    let strSentencia = 'select a.*,b.pro_codigo,b.pro_nombre from cxp_trncobro a,com_maeproveedor b ';
    strSentencia = strSentencia + ' where b.pro_codigo(+)=a.pro_codigo and trncobro_nrodoc= '
      + '\'' + strTrnCobroNroDoc + '\'';
    strSentencia = strSentencia + ' and a.com_codigo=' + '\'01\'';

    this.limpiarDatos(false);
    this.cxptrncobroService.variosarray[this.indicador] = [];

    try {
      const data = await this.cxptrncobroService.mostrarDatosPromise(strTrnCobroNroDoc);
      for (const rs of data) {
        document.getElementById('contorno').style.pointerEvents = 'all';
        strTipoTrans = rs.TRNCOBRO_TIPODOC === null ? '' : rs.TRNCOBRO_TIPODOC;
        // tabDatos.setEnabled(true);
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC = rs.TRNCOBRO_NRODOC === null ? '' : rs.TRNCOBRO_NRODOC;
        this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO = rs.PRO_CODIGO === null ? '' : rs.PRO_CODIGO;
        this.cxptrncobroService.trncobroarray[this.indicador].PRO_NOMBRE = rs.PRO_NOMBRE === null ? '' : rs.PRO_NOMBRE;
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SERIE = rs.TRNCOBRO_SERIE === null ? '' : rs.TRNCOBRO_SERIE;
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_CONCEPTO = rs.TRNCOBRO_CONCEPTO === null ? '' : rs.TRNCOBRO_CONCEPTO;
        this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO = rs.ASI_NRO === null ? '' : rs.ASI_NRO;

        strSaldoInicial = rs.TRNCOBRO_SALDOINICIAL === null ? '' : rs.TRNCOBRO_SALDOINICIAL;
        StrImpreso = rs.TRNCOBRO_IMPRESO === null ? '' : rs.TRNCOBRO_IMPRESO;

        if (StrImpreso === 'S') {
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPRESO = 'IMPRESO';
        } else {
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPRESO = '';
        }

        if (strSaldoInicial === '1') {
          this.chkSaldoInicial = true;
        }

        if (strTipoTrans === 'NC' || strTipoTrans === 'ND') {//CDPJ SE AUMENTO || strTipoTrans === 'ND'
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA =
            rs.TRNCOBRO_REFERENCIAEXTERNA === null ? '' : rs.TRNCOBRO_REFERENCIAEXTERNA;
        }

        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_ESTADO = rs.TRNCOBRO_ESTADO === null ? '' : rs.TRNCOBRO_ESTADO;

        if ((rs.TRNCOBRO_ESTADO === null ? '' : rs.TRNCOBRO_ESTADO) === 'A') {
          this.cxptrncobroService.trncobroarray[this.indicador].ANULADO = 'ANULADO';
        }

        this.strCodigoConPro = rs.CON_CODIGO === null ? '' : rs.CON_CODIGO;

        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE = rs.TRNCOBRO_IMPORTE.toFixed(2); // detpagoDoc

        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN = new Date(rs.TRNCOBRO_FECHATRN);
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHAVENCE = new Date(rs.TRNCOBRO_FECHAVENCE);
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHARECEPCION = new Date(rs.TRNCOBRO_FECHARECEPCION);

        if (strTipoTrans === 'NC') {
          this.selecttipo = this.cmbtipo[0];
        }
        if (strTipoTrans === 'ND') {
          this.selecttipo = this.cmbtipo[1];
        }
        if (strTipoTrans === 'FC') {
          this.selecttipo = this.cmbtipo[2];
        }
        if (strTipoTrans === 'ANP') {
          this.selecttipo = this.cmbtipo[3];
        }
        if (strTipoTrans === 'DANP') {
          this.selecttipo = this.cmbtipo[4];
        }


        this.bolcmbTipo = false;
        this.boltxtCodProveedor = false;
        this.bolchkSaldoInicial = false;

        if (strTipoTrans === 'FC' || strTipoTrans === 'NC' || strTipoTrans === 'ND') {
          this.txtNumeroTextLimit = 7;
        } else {
          this.txtNumeroTextLimit = 30;
        }

        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = rs.TRNCOBRO_NROCOMPROBANTE === null ? '' : rs.TRNCOBRO_NROCOMPROBANTE;

        this.eliminarPestanias();
        this.mostrarPestanias();

        /*this.cxptrncobroService.trncobroarray[this.indicador].ENCREQ_NUMERO = rs.ENCREQ_NUMERO === null ? '' : rs.ENCREQ_NUMERO;
        intNumLinReq = rs.DETREQ_LINEA === null ? '' : rs.DETREQ_LINEA;
        groupRequisicion.setEnabled(false);*/

        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_CODFORPAG = rs.TRNCOBRO_CODFORPAG === null ? '' : rs.TRNCOBRO_CODFORPAG;

        let strNumFP = '';

        this.cxptrncobroService.erCodSriDescripcion(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_CODFORPAG).subscribe(er => {
          if (er !== null) {
            if (er[0] !== undefined) {
              strNumFP = er[0].CODSRI_DESCRIPCION;
            }
          }
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_DESFORPAG = strNumFP;
        });

        /*
        if (txtNumRequisicion.getText().trim().compareTo('') != 0)
          traerDatosLineaRequisicion(txtNumRequisicion.getText(),
            intNumLinReq);
            */

        let strNumRef = '';
        if (strTipoTrans === 'NC') {
          this.cxptrncobroService.erEncfacproReferencia(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA).subscribe(er => {
            if (er !== null) {
              if (er[0] !== undefined) {
                strNumRef = er[0].ENCFACPRO_REFERENCIA;
              }
            }
            this.cxptrncobroService.trncobroarray[this.indicador].ENCFACPRO_REFERENCIA = strNumRef;
          });
        } else {
          this.boltxtNumeroDocumento = false;
          this.bolbtnBuscarDocumento = false;
        }

        if (strTipoTrans !== 'ANP') {

          const dblSaldo = await this.saldoDocumento(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA,
            this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO);
          const dblImporte = await this.ImporteDocumento(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA);

          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SALDOINICIAL = dblSaldo.toFixed(2); // detpagoDoc
          this.cxptrncobroService.trncobroarray[this.indicador].IMPORTEDOC = dblImporte.toFixed(2); // detpagoDoc

          if (strTipoTrans === 'DANP') {
            this.boldateTimeRecepcion = false;
            this.boldateTimeVencimiento = false;
            this.boltxtImporte = false;

            // CARGAR LOS DOCUMENTOS DE LA TRANSACCION
            this.consultarDocumentosTrn('DT', 'TRNCOBRO_NRODOC = \'' + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC + '\'');
          }
        } else {

          let dblSaldo = await this.saldoDocumento(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC,
            this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO);
          console.log(dblSaldo);
          dblSaldo = dblSaldo * (-1);
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SALDOINICIAL = dblSaldo.toFixed(2); // detpagoDoc
          this.boldateTimeRecepcion = false;
          this.boldateTimeVencimiento = false;

          if (await this.cxp_fnc_verificar_trans_cash(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC,
            this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO, 'ANTICIPOS') >= 1) {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Información',
              detail: 'Documento no puede ser modificado ya que se encuentra en un documento de Cash Management.'
            });
            document.getElementById('contorno').style.pointerEvents = 'none';
          }
        }

        if (this.tabItemVarios !== false) {
          this.listarDatosVarios(this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO);
        }

        if (await this.verificarPeriodoContable(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN) === false
          || this.cxptrncobroService.trncobroarray[this.indicador].ANULADO === 'ANULADO') {
          document.getElementById('contorno').style.pointerEvents = 'none';
        }

        if (strTipoTrans !== 'FC'
          && strTipoTrans !== 'ANP') {
          this.desplegarTrnDatosAnexos();
          this.desplegarDatosSRI();
        }

        // FORMA DE PAGO DE LOS ANTICIPOS
        if (this.tabItemFPAnt !== false) {
          this.consultarFPAnt(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);

          if (this.cxptrncobroService.fpantarray[this.indicador].length > 0) {
            this.obtenerTotalPagadoFPAnt();
          }

          this.chequeConciliado('COLOR');
          this.desplegarAsientos();
        }

        // FORMA DE PAGO DE LAS DEVOLUCIONES DE ANTICIPOS
        if (this.tabItemFPDev !== false) {
          this.consultarFPDev(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);

          if (this.cxptrncobroService.fpdevarray.length > 0) {
            this.obtenerTotalPagadoDev();
          }


          this.efectivoDepositado('COLOR');
          this.chequeDepositado('COLOR');
          this.depositoConciliado('COLOR');
        }


        if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPRESO === 'IMPRESO') {
          /*groupCabecera.setEnabled(false);
          groupCabecera2.setEnabled(false);
          groupCabecera3.setEnabled(false);*/
        }

        // bloquear el anticipo en el caso de que haya sido utilizado
        this.discodproveedor = false;

        if (strTipoTrans === 'ANP' && (Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SALDOINICIAL) !==
          Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE))) {
          this.boldateTimeFechaEmi = false;
          this.boltxtSerie = false;
          this.boltxtNumero = false;
          this.boltxtImporte = false;
          this.discodproveedor = true;
          /*groupCabecera.setEnabled(false);
          groupCabecera2.setEnabled(false);
          groupCabecera3.setEnabled(false);*/
          /*if (toolBarFPAnt != null)
            toolBarFPAnt.setEnabled(false);*/
        } else if (strTipoTrans === 'ANP' && (Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SALDOINICIAL) ===
          Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE))) {
          this.boldateTimeFechaEmi = true;
          this.boltxtSerie = true;
          this.boltxtNumero = true;
          this.boltxtImporte = true;
          this.discodproveedor = false;
          this.boltxtFormaPagoSri = true;
          this.bolbtnFormaPagoSRI = true;
          this.boldateTimeVencimiento = true;
          this.boldateTimeRecepcion = true;

        }

        if (strTipoTrans === 'FC') {
          this.botonNuevoVarios = false;
          this.botonBorrarVarios = false;
          this.botonRegresarVarios = false;
        } else {
          this.botonNuevoVarios = true;
          this.botonBorrarVarios = true;
          this.botonRegresarVarios = true;
        }


      }// fin si encuentra datos

      // bloquear el documento si ya se concilió o depositó o está en el cierre de caja
      // boolean bolCierre = documentoCierre("");

      if (await this.chequeConciliado('') || await this.chequeDepositado('')
        || await this.depositoConciliado('') || await this.efectivoDepositado('')) {
        /*groupCabecera.setEnabled(false);
        groupCabecera2.setEnabled(false);
        groupCabecera3.setEnabled(false);*/
      }

      if (this.chkSaldoInicial === true) {
        const dblPagoDoc = await this.cxp_fnc_PagadoDocumento(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC,
          this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO);

        if (dblPagoDoc !== 0) {
          this.boltxtImporte = false;
          return;
        }
      }
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
    }
  }
//CDPJ
  async desplegarAsientos(){
    try {
      const data = await this.cxptrncobroService.consultarAsientos(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
      this.cmbasiento = [];
      for (const rs of data) {
        if (rs.ASI_NRO !== null) {
          this.cmbasiento.push({ codigo: rs.ASI_NRO === null ? '' : rs.ASI_NRO, name: rs.ASI_NRO === null ? '' : rs.ASI_NRO });
          this.cmbasiento.push({
            codigo: rs.ASI_NROCHPOSFH === null ? '' : rs.ASI_NROCHPOSFH,
            name: rs.ASI_NROCHPOSFH === null ? '' : rs.ASI_NROCHPOSFH
          });
        }
      }

      if (this.cmbasiento.length > 0) {
        this.selectasiento = { codigo: this.cmbasiento[0].codigo, name: this.cmbasiento[0].name };
      }
    } catch (err) {
      console.log(err)
    }
  }
//CDPJ
  async chequeConciliado(strProceso) {
    let bolConc = false;

    if (this.tabItemFPAnt === true) {
      for (let fila = 1; fila <= this.cxptrncobroService.fpantarray[this.indicador].length; fila++) {
        if (this.cxptrncobroService.fpantarray[this.indicador][fila - 1].TIPODOC === 'CH') {
          if (await this.documentoConciliado(this.cxptrncobroService.fpantarray[this.indicador][fila - 1].NUMERODOC,
            this.cxptrncobroService.fpantarray[this.indicador][fila - 1].BANCODIGO,
            this.cxptrncobroService.fpantarray[this.indicador][fila - 1].TIPODOC)) {
            if (strProceso !== 'COLOR') {
              if (strProceso.length > 0) {
                this.messageService.add({
                  key: 'cxptrncobro',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el pago, el cheque '
                    + this.cxptrncobroService.fpantarray[this.indicador][fila - 1].NUMERODOC
                    + ' está conciliado.'
                });
              }
              bolConc = true;
              break;
            } else {
              this.cxptrncobroService.fpantarray[this.indicador][fila - 1].COLORDOCCONC = true;
            }
          }
        }
      }
    }

    return bolConc;
  }

  async chequeDepositadoUtil(strNumCheque, strCodBanco, strDocumento) {
    let strDepositado = '';

    try {
      const er = await this.cxptrncobroService.erTrnCajDpositoNro1Prom(strNumCheque, strCodBanco, strDocumento);
      if (er !== null) {
        if (er[0] !== undefined) {
          strDepositado = er[0].TRNCAJ_DEPOSITONRO;
        }
      }
    } catch (error1) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el cheque está depositado.'
      });
    }

    return strDepositado;
  }

  async chequeDepositado(strProceso) {
    let bolDep = false;

    if (this.tabItemFPDev === true) {
      for (let fila = 1; fila <= this.cxptrncobroService.fpdevarray[this.indicador].length; fila++) {
        if (this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGOTIPO === 'CH') {
          const strNumDep = await this.chequeDepositadoUtil(
            this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGONUMERO,
            this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].BANCLICODIGO,
            this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
            if (strNumDep !== null) {
              if (strNumDep.length > 0) {
                if (strProceso !== 'COLOR') {
                  if (strProceso.length > 0) {
                    this.messageService.add({
                      key: 'cxptrncobro',
                      severity: 'error',
                      summary: 'Información',
                      detail: 'No puede '
                        + strProceso
                        + ' el cobro, el cheque '
                        + this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGONUMERO
                        + ' está depositado.'
                        + '\r\rNro. Depósito: '
                        + strNumDep
                    });
                  }
                  bolDep = true;
                  break;
                } else {
                  this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].COLORDOCDEP = true;
                }
              }
            }
          

        }
      }
    }
    return bolDep;
  }

  async depositoConciliado(strProceso) {
    let bolConc = false;

    if (this.tabItemFPDev === true) {
      for (let fila = 1; fila <= this.cxptrncobroService.fpdevarray[this.indicador].length; fila++) {
        if (this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGOTIPO === 'DP') {
          if (await this.documentoConciliado(this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGONUMERO,
            this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].BANCLICODIGO,
            this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGOTIPO)) {
            if (strProceso !== 'COLOR') {
              if (strProceso.length > 0) {
                this.messageService.add({
                  key: 'cxptrncobro',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el pago, el depósito '
                    + this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGONUMERO
                    + ' está conciliado.'
                });
              }
              bolConc = true;
              break;
            } else {
              this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].COLORDOCCONC = true;
            }
          }
        }
      }
    }
    return bolConc;
  }

  async efectivoDepositadoUtil(strNumero, strCodCaja, strDocNro) {
    let strDepositado = '';

    try {
      const er = await this.cxptrncobroService.erTrnCajDpositoNro2Prom(strNumero, strDocNro, strCodCaja);

      if (er !== null) {
        if (er[0] !== undefined) {
          strDepositado = er[0].TRNCAJ_DEPOSITONRO === null ? '' : er[0].TRNCAJ_DEPOSITONRO;
        }
      }
    } catch (error1) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el efectivo está depositado.'
      });
    }

    return strDepositado;
  }

  async efectivoDepositado(strProceso) {
    let bolDep = false;

    if (this.tabItemFPDev === true) {
      for (let fila = 1; fila <= this.cxptrncobroService.fpdevarray[this.indicador].length; fila++) {
        if (this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGOTIPO === 'EF') {
          const strNumDep = await this.efectivoDepositadoUtil(
            this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGONUMERO,
            this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].CAJCODIGO,
            this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
            if (strNumDep !== null) {
              if (strNumDep.length > 0) {
                if (strProceso !== 'COLOR') {
                  if (strProceso.length() > 0) {
                    this.messageService.add({
                      key: 'cxptrncobro',
                      severity: 'error',
                      summary: 'Información',
                      detail: 'No puede '
                        + strProceso
                        + ' el cobro, el efectivo '
                        + this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].TRNPAGONUMERO
                        + ' está depositado.'
                        + '\r\rNro. Depósito: '
                        + strNumDep
                    });
                  }
                  bolDep = true;
                  break;
                } else {
                  this.cxptrncobroService.fpdevarray[this.indicador][fila - 1].COLORDOCDEP = true;
                }
              }
            }
          
        }
      }
    }
    return bolDep;
  }

  anticipoUtilizado(strProceso): boolean {
    let bolUtilizado = false;

    if (this.selecttipo.name === 'Anticipo') {
      if (Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SALDOINICIAL) !==
        Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE)) {
        bolUtilizado = true;

        if (strProceso.length > 0) {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Información',
            detail: 'No puede ' + strProceso + ' el anticipo, ya está '
              + 'utilizado.'
          });
        }
      }
    }
    return bolUtilizado;
  }

  async cxp_fnc_PagadoDocumento(strTrnCobroNroDoc, strProCodigo) {
    const strSql = 'SELECT cxp_fnc_PagadoDocumento(\'' + strTrnCobroNroDoc + '\',' + '\'01\', \'' +
      strProCodigo + '\') AS PAGADO FROM DUAL';
    let dblSaldo = 0;
    try {
      const data = await this.cxptrncobroService.cxp_fnc_PagadoDocumento(strTrnCobroNroDoc, strProCodigo);
      for (const rs of data) {
        dblSaldo = rs.PAGADO;
      }
    } catch (error1) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede obtener el valor pagado del documento'
      });
    }
    return dblSaldo;
  }

  async saldoDocumento(strTrnCobroNroDoc, strCodProveedor) {

    let strSentencia = '';
    let dblSaldo = 0;

    strSentencia = 'select cxp_fnc_saldodocumento(\'' + strTrnCobroNroDoc + '\'' + ',' + '\'01\'' + ',' + '\'' + strCodProveedor + '\')';
    strSentencia = strSentencia + ' as SALDO from dual ';

    console.log(strSentencia);
    try {
      const data = await this.cxptrncobroService.saldoDocumento(strTrnCobroNroDoc, strCodProveedor);
      for (const rs of data) {
        dblSaldo = rs.SALDO === 0 ? 0 : rs.SALDO;
      }
    } catch (error1) {
      dblSaldo = -1;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
    }

    return dblSaldo;
  }

  async ImporteDocumento(strTrnCobroNroDoc) {
    let strSentencia = '';
    let dblSaldo = 0;

    strSentencia = 'select trncobro_importe from cxp_trncobro where trncobro_nrodoc=' + '\'' + strTrnCobroNroDoc + '\'';
    strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';

    try {
      const data = await this.cxptrncobroService.ImporteDocumento(strTrnCobroNroDoc);
      for (const rs of data) {
        dblSaldo = rs.TRNCOBRO_IMPORTE === 0 ? 0 : rs.TRNCOBRO_IMPORTE;
      }
    } catch (error1) {
      dblSaldo = -1;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
    }

    return dblSaldo;

  }

  consultarDocumentosTrn(strOpcion, strCondicion) { // pendiente
    this.cxptrncobroService.docarray[this.indicador] = [];
    this.cxptrncobroService.consultarTRNDOCUMENTO(strOpcion, strCondicion).subscribe(data => {
      if (data === null) {
        return;
      }
      for (const rs of data) {
        const item: CxpDocs = {};
        item.TRNCOBRONRODOC = rs.TRNDOCUMENTO_NRODOC;
        item.TRNCOBROFECHATRN = this.datePipe.transform(
          rs['(SELECTC.TRNCOBRO_FECHATRNFROMCXP_TRNCOBROCWHERED.TRNDOCUMENTO_NRODOCLIKEC.TRNCOBRO_NRODOC)'], 'dd/MM/yyyy');
        item.TRNCOBROCONCEPTO = rs['(SELECTC.TRNCOBRO_CONCEPTOFROMCXP_TRNCOBROCWHERED.TRNDOCUMENTO_NRODOCLIKEC.TRNCOBRO_NRODOC)'];
        if (rs['NVL(CXP_FNC_SALDODOCUMENTO(D.TRNDOCUMENTO_NRODOC,D.COM_CODIGO,D.PRO_CODIGO),0)'] < 0) {
          item.TRNCOBROSALDO = rs['NVL(CXP_FNC_SALDODOCUMENTO(D.TRNDOCUMENTO_NRODOC,D.COM_CODIGO,D.PRO_CODIGO),0)'] * (-1);
        } else {
          item.TRNCOBROSALDO = rs['NVL(CXP_FNC_SALDODOCUMENTO(D.TRNDOCUMENTO_NRODOC,D.COM_CODIGO,D.PRO_CODIGO),0)'];
        }
        item.TRNCOBROSALDO = item.TRNCOBROSALDO.toFixed(2);
        item.TRNCOBROIMPORTE = rs.TRNDOCUMENTO_IMPORTE;
        item.TRNCOBROIMPORTE = rs.TRNDOCUMENTO_IMPORTE.toFixed(2);
        item.CLICODIGO = rs.PRO_CODIGO;
        item.CUOTAFACT = 0;
        item.VENCODIGO = 0;
        item.APLORGCODIGO = rs['(SELECTAPLORG_CODIGOFROMCXP_TRNCOBROCWHEREC.TRNCOBRO_NRODOC=D.TRNCOBRO_NRODOCANDC.COM_CODIGO=D.COM_CODIGO)'];
        item.TRNCOBROTIPODOC = rs.TRNDOCUMENTO_TIPODOC;
        this.cxptrncobroService.docarray[this.indicador].push(item);
      }

      if (this.cxptrncobroService.docarray[this.indicador].length > 0) {
        for (let fila = 1; fila <= this.cxptrncobroService.docarray[this.indicador].length; fila++) {
          this.cxptrncobroService.docarray[this.indicador][fila - 1].SELECCIONADO = true;
          /*
          //	colorear las filas si las facturas pertenecen a una cuota
          if (listaDocPorCobrar.get(fila - 1).getStrCuotaFact() != null) {
            if (listaDocPorCobrar.get(fila - 1).getStrCuotaFact().equals('C'))
              setSpecialRowColor(fila, new Color(dlgCxP.getParent().getDisplay(), 255, 255, 170));
          }
           */
        }
      }

      if (this.cxptrncobroService.docarray[this.indicador].length > 0) {
        this.calcularTotalDoc(); // calcular total de los documentos
        /*tablaDoc.setSelection(1, 1, true);*/
      }
    });

  }

  async cxp_fnc_verificar_trans_cash(strDocumento, strProCodigo, strTipo) {
    const strSql = 'SELECT cxp_fnc_verificar_trans_cash(\'' + strDocumento + '\',' + '\'' + strProCodigo + '\', \'' + strTipo + '\') as CASH FROM DUAL';
    let intSaldo = 0;
    try {
      const data = await this.cxptrncobroService.cxp_fnc_verificar_trans_cash(strDocumento, strProCodigo, strTipo);
      for (const rs of data) {
        intSaldo = rs.CASH;
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede verificar si transacción esta en el CashManagement'
      });
    }
    return intSaldo;
  }

  async verificarPeriodoContable(fechatrn) {
    let bolVerificar = false;
    let mesCerrado = 0;
    const fecha = this.datePipe.transform(fechatrn, 'dd/MM/yyyy');
    const sentencia = '	select count(*) as contador from  saci_maeperiodo  ' +
      'where  to_date(\'' + fecha + '\',\'dd/mm/yyyy\' ) >=per_fechainicial' +
      '	and to_date(\'' + fecha + '\',\'dd/mm/yyyy\' ) <=per_fechafinal';

    try {
      const data = await this.cxptrncobroService.verificarPeriodoContable(fecha);
      for (const periodoCerrado of data) {
        mesCerrado = periodoCerrado.CONTADOR;
        // Si esta dentro del periodo contable
        if (mesCerrado === 1) {
          bolVerificar = true;
        }
      }
    } catch (error1) {
      return false;
    }

    return bolVerificar;
  }

  desplegarTrnDatosAnexos() {

    let strSentencia = '';

    this.limpiarDatosAnexos();

    strSentencia = 'select a.*,b.CODSRI_DESCRIPCION as DescIdCreTri,c.CODSRI_DESCRIPCION as DescTipoComp' +
      ' from CXP_TRNDATOSANEX a, anex_maecodsri b ,anex_maecodsri c where ';
    strSentencia = strSentencia
      + ' a.trnanex_idcretri=b.CODSRI_CODIGO and b.codsri_tipo=\'CT\'';
    strSentencia = strSentencia
      + ' and a.trnanex_tipcom=c.codsri_codigo and c.codsri_tipo=\'TC\'';
    strSentencia = strSentencia + ' and a.trncobro_nrodoc=' + '\''
      + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC + '\'';
    strSentencia = strSentencia + ' and a.com_codigo=\'01\'';

    this.cxptrncobroService.desplegarTrnDatosAnexos(
      this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC).subscribe(data => {
        for (const rs of data) {
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_IDCRETRI =
            rs.TRNANEX_IDCRETRI === null ? '' : rs.TRNANEX_IDCRETRI;
          this.cxptrncobroService.trndatosanexarray[this.indicador].DESCIDCRETRI =
            rs.DESCIDCRETRI === null ? '' : rs.DESCIDCRETRI;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPCOM =
            rs.TRNANEX_TIPCOM === null ? '' : rs.TRNANEX_TIPCOM;
          this.cxptrncobroService.trndatosanexarray[this.indicador].DESCTIPOCOMP =
            rs.DESCTIPOCOMP === null ? '' : rs.DESCTIPOCOMP;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_AUTORIZACION =
            rs.TRNANEX_AUTORIZACION === null ? '' : rs.TRNANEX_AUTORIZACION;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPCOMMOD =
            rs.TRNANEX_TIPCOMMOD === null ? '' : rs.TRNANEX_TIPCOMMOD;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPTARCERO =
            rs.TRNANEX_BASEIMPTARCERO === 0 ? 0 : rs.TRNANEX_BASEIMPTARCERO;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPGRABADAIVA =
            rs.TRNANEX_BASEIMPGRABADAIVA === 0 ? 0 : rs.TRNANEX_BASEIMPGRABADAIVA;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOIVA =
            rs.TRNANEX_MONTOIVA === 0 ? 0 : rs.TRNANEX_MONTOIVA;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPNOOBJETOIVA =
            rs.TRNANEX_BASEIMPNOOBJETOIVA === 0 ? 0 : rs.TRNANEX_BASEIMPNOOBJETOIVA;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOICE =
            rs.TRNANEX_MONTOICE === 0 ? 0 : rs.TRNANEX_MONTOICE;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTORETIVABIE =
            rs.TRNANEX_MONTORETIVABIE === 0 ? 0 : rs.TRNANEX_MONTORETIVABIE;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTORETIVASER =
            rs.TRNANEX_MONTORETIVASER === 0 ? 0 : rs.TRNANEX_MONTORETIVASER;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_RETVACIEN =
            rs.TRNANEX_RETVACIEN === 0 ? 0 : rs.TRNANEX_RETVACIEN;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPTARCERO =
            this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPTARCERO.toFixed(2);
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPGRABADAIVA =
            this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPGRABADAIVA.toFixed(2);
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOIVA =
            this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOIVA.toFixed(2);
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPNOOBJETOIVA =
            this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPNOOBJETOIVA.toFixed(2);
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOICE =
            this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOICE.toFixed(2);
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTORETIVABIE =
            this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTORETIVABIE.toFixed(2);
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTORETIVASER =
            this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTORETIVASER.toFixed(2);
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_RETVACIEN =
            this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_RETVACIEN.toFixed(2);
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPOSRITARCERO =
            rs.TRNANEX_TIPOSRITARCERO === null ? '' : rs.TRNANEX_TIPOSRITARCERO;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPOSRIGRAVADA =
            rs.TRNANEX_TIPOSRIGRAVADA === null ? '' : rs.TRNANEX_TIPOSRIGRAVADA;
          this.cxptrncobroService.trndatosanexarray[this.indicador].AUTORIZACIONDOCMODIF =
            rs.TRNANEX_AUTDOCMOD === null ? '' : rs.TRNANEX_AUTDOCMOD;
          this.cxptrncobroService.trndatosanexarray[this.indicador].SECUENCIADOCMODIF =
            rs.TRNANEX_SECDOCMOD === null ? '' : rs.TRNANEX_SECDOCMOD;
          this.cxptrncobroService.trndatosanexarray[this.indicador].SERIEDOCMODIF =
            rs.TRNANEX_SERIEDOCMOD === null ? '' : rs.TRNANEX_SERIEDOCMOD;
        }
      });
  }// desplegarTrnDatosAnexos


  desplegarDatosSRI() {
    this.cxptrncobroService.getDATOSANEXOS(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC).subscribe(data => {
      for (const rs of data) {
        if (rs.IDSUSTRIB !== null) {
          this.cxptrncobroService.trndatosanexarray[this.indicador].IDSUSTRIB =
            rs.IDSUSTRIB === null ? '' : rs.IDSUSTRIB;
          this.cxptrncobroService.trndatosanexarray[this.indicador].DESIDSUSTRIB =
            rs.DESIDSUSTRIB === null ? '' : rs.DESIDSUSTRIB;
          this.cxptrncobroService.trndatosanexarray[this.indicador].IDPROVEEDOR =
            rs.IDPROVEEDOR === null ? '' : rs.IDPROVEEDOR;
          this.cxptrncobroService.trndatosanexarray[this.indicador].PERIODOINF =
            rs.PERIODOINF === null ? '' : rs.PERIODOINF;
          this.cxptrncobroService.trndatosanexarray[this.indicador].DESPERIODOINF =
            rs.DESPERIODOINF === null ? '' : rs.DESPERIODOINF;
          this.cxptrncobroService.trndatosanexarray[this.indicador].FECHACONTAB =
            rs.FECHACONTAB === null ? '' : rs.FECHACONTAB;
          this.cxptrncobroService.trndatosanexarray[this.indicador].SERIECOMP =
            rs.SERIECOMP === null ? '' : rs.SERIECOMP;
          this.cxptrncobroService.trndatosanexarray[this.indicador].SECUENCIALCOMP =
            rs.SECUENCIALCOMP === null ? '' : rs.SECUENCIALCOMP;
          this.cxptrncobroService.trndatosanexarray[this.indicador].FECHAEMISIONCOMP =
            rs.FECHAEMISIONCOMP === null ? '' : rs.FECHAEMISIONCOMP;
          this.cxptrncobroService.trndatosanexarray[this.indicador].TIPOCOMPMODIF =
            rs.TIPOCOMPMODIF === null ? '' : rs.TIPOCOMPMODIF;
          let factura = '';
          if (rs.TIPOCOMPMODIF !== '01') {
            factura = 'FACTURA';
          }


          this.verificarIsAnexoNuevo('datosanex', rs);
        }
      }
    });
  }

  verificarIsAnexoNuevo(accion, rs) {
    const sentencia = ' select count(*) as CONTADOR from CXP_TRNDATOSANEX  where trncobro_nrodoc=\''
      + (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC === null ? '' :
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC)
      + '\' and com_codigo=\'01\'';
    const trnCobro = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC === null ? '' :
      this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC;

    let contDatosAnexo = 0;
    let existDatosAnexo = false;
    //    console.log('sentencia para verificar existencia de anexo' + sentencia);
    this.cxptrncobroService.verificarIsAnexoNuevo(trnCobro).subscribe(data => {
      for (const rs1 of data) {
        contDatosAnexo = rs1.CONTADOR;
      }

      if (contDatosAnexo > 0) {
        existDatosAnexo = true;
      }

      if (accion === 'datosanex') {
        if (existDatosAnexo === false) {
          this.cxptrncobroService.trndatosanexarray[this.indicador].DESCTIPOCOMPMODIFICADO =
            rs.DESCTIPOCOMPMODIFICADO === null ? '' : rs.DESCTIPOCOMPMODIFICADO;
          this.cxptrncobroService.trndatosanexarray[this.indicador].SERIEDOCMODIF =
            rs.SERIEDOCMODIF === null ? '' : rs.SERIEDOCMODIF;
          this.cxptrncobroService.trndatosanexarray[this.indicador].SECUENCIADOCMODIF =
            rs.SECUENCIADOCMODIF === null ? '' : rs.SECUENCIADOCMODIF;
          this.cxptrncobroService.trndatosanexarray[this.indicador].AUTORIZACIONDOCMODIF =
            rs.AUTORIZACIONDOCMODIF === null ? '' : rs.AUTORIZACIONDOCMODIF;
        }
      }
    });
  }

  consultarFPAnt(strNumDoc) {
    this.cxptrncobroService.fpantarray[this.indicador] = [];
    this.consultarCaja(strNumDoc);
    this.consultarBancos(strNumDoc);
  }

  obtenerTotalPagadoFPAnt() {
    let dblTotal = 0;
    for (let fila = 1; fila <= this.cxptrncobroService.fpantarray[this.indicador].length; fila++) {
      if (this.cxptrncobroService.fpantarray[this.indicador][fila - 1].TIPODOC.length > 0) {
        dblTotal = dblTotal + Math.abs(this.cxptrncobroService.fpantarray[this.indicador][fila - 1].IMPORTEDOC);
      }
    }
    return dblTotal.toFixed(2);
  }

  consultarFPDev(strNumDoc) {
    this.cxptrncobroService.fpdevarray[this.indicador] = [];
    this.consultarCajaDev(strNumDoc);
    this.consultarBancosDev(strNumDoc);

  }

  async consultarCaja(strNumDoc) {
    let strSql = 'SELECT * FROM BAN_TRNCAJ WHERE TRNCAJ_DOCNRO = \'' + strNumDoc + '\' AND ';
    strSql = strSql + ' trncaj_anulado=0 ';
    strSql = strSql + ' and COM_CODIGO = \'01\'';

    try {
      const data = await this.cxptrncobroService.consultarCaja(strNumDoc);

      for (const rs of data) {
        const item: CxpFpant = {};
        item.BANBANCO = '';
        item.BANCODIGO = '';
        item.CAJADOC = rs.CAJ_CODIGO;
        item.COMCODIGO = rs.COM_CODIGO;
        item.CONCEPTODOC = rs.TRNCAJ_DESCRIPCION;
        item.CONCODIGO = await this.obtenerCuentaCaja(rs.CAJ_CODIGO);
        item.FECHADOC = this.datePipe.transform(rs.TRNCAJ_FECHA, 'dd/MM/yyyy');
        item.IMPORTEDOC = Number(rs.TRNCAJ_IMPORTE) * (-1);
        item.IMPORTEDOC = item.IMPORTEDOC.toFixed(2);
        item.NUMERODOC = rs.TRNCAJ_NUMERO;
        item.TIPODOC = rs.TRNCAJ_TIPO;
        this.cxptrncobroService.fpantarray[this.indicador].push(item);
      }

      this.aggrid.refreshaggrid(this.cxptrncobroService.fpantarray[this.indicador], this.defaultColDefFPAnt.objeto);
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
    }
  }


  async consultarBancos(strNumDoc) {
    const strSql = 'SELECT * FROM BAN_TRNBAN WHERE TRNBAN_DOCNRO = \'' + strNumDoc + '\' AND ' +
      'COM_CODIGO = \'01\' AND TRNBAN_ORIGEN = \'CXP\' AND TRNBAN_ANULADO = 0';
    try {
      const data = await this.cxptrncobroService.consultarBancos(strNumDoc);
      for (const rs of data) {
        const item: CxpFpant = {};
        item.BANBANCO = await this.obtenerNombreBanco(rs.BAN_CODIGO);
        item.BANCODIGO = rs.BAN_CODIGO;
        item.CAJADOC = '';
        item.COMCODIGO = rs.COM_CODIGO;
        item.CONCEPTODOC = rs.TRNBAN_CONCEPTO;
        item.CONCODIGO = await this.obtenerCuentaBanco(rs.BAN_CODIGO);
        item.FECHADOC = this.datePipe.transform(rs.TRNBAN_FECHA, 'dd/MM/yyyy');
        item.IMPORTEDOC = Number(rs.TRNBAN_IMPORTE) * (-1);
        item.IMPORTEDOC = item.IMPORTEDOC.toFixed(2);
        item.NUMERODOC = rs.TRNBAN_NUMERO;
        item.TIPODOC = rs.TRNBAN_TIPO;
        if (rs.TRNBAN_PAGOELEC === null) {
          // item.PAGOELECTRONICO = 0;
          item.PAGOELECTRONICO = false
        } else {
          if (rs.TRNBAN_PAGOELEC === '1') {
            //item.PAGOELECTRONICO = 1;
            item.PAGOELECTRONICO = true;
          } else {
            // item.PAGOELECTRONICO = 0;
            item.PAGOELECTRONICO = false;
          }
        }
        this.cxptrncobroService.fpantarray[this.indicador].push(item);
      }

      this.aggrid.refreshaggrid(this.cxptrncobroService.fpantarray[this.indicador], this.defaultColDefFPAnt.objeto);
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
    }
  }

  async obtenerCuentaCaja(strCaja) {
    let strCuenta = '';
    try {
      const er = await this.cxptrncobroService.erConCodigo5Prom(strCaja);
      if (er !== null) {
        if (er[0] !== undefined) {
          strCuenta = er[0].CON_CODIGO;
        }
      }
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
    }
    return strCuenta;
  }

  async obtenerNombreBanco(strCodBanco) {
    let strNombre = '';
    try {
      const er = await this.cxptrncobroService.erBanBancoProm(strCodBanco);
      if (er !== null) {
        if (er[0] !== undefined) {
          strNombre = er[0].BAN_BANCO;
        }
      }
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
    }
    return strNombre;
  }

  async obtenerCuentaBanco(strCodBanco) {
    let strCuenta = '';
    try {
      const er = await this.cxptrncobroService.erConCodigo6Prom(strCodBanco);
      if (er !== null) {
        if (er[0] !== undefined) {
          strCuenta = er[0].CON_CODIGO;
        }
      }
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
    }
    return strCuenta;
  }

  listarDatosVarios(StrCodigoProveedor) {
    this.cxptrncobroService.variosarray[this.indicador] = [];
    this.cxptrncobroService.consultarDetTrnCobro(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC).subscribe(data => {
      if (data !== null) {
        for (const rs of data) {
          const item: CxpVarios = {};
          item.DEBE = Number('0').toFixed(2);
          item.HABER = Number('0').toFixed(2);
          if (Number(rs.DETTRNCOBRO_IMPORTE) > 0) {
            item.DEBE = rs.DETTRNCOBRO_IMPORTE; // detpagoDoc
            item.DEBE = item.DEBE.toFixed(2);
          } else {
            item.HABER = rs.DETTRNCOBRO_IMPORTE * -1; // detpagoDoc
            item.HABER = item.HABER.toFixed(2);
          }
          item.DETTRNCOBRONROSEC = rs.DETTRNCOBRO_NROSEC;
          item.BANCODIGO = rs.BAN_CODIGO === null ? '' : rs.BAN_CODIGO;
          item.CENCODIGO = rs.CEN_CODIGO === null ? '' : rs.CEN_CODIGO;
          item.COMCODIGO = rs.COM_CODIGO;
          item.CONCODIGO = rs.CON_CODIGO === null ? '' : rs.CON_CODIGO;
          item.CONCENTRO = rs.CON_CENTRO === null ? '' : rs.CON_CENTRO;
          item.CONNOMBRE = rs.CON_NOMBRE === null ? '' : rs.CON_NOMBRE;
          item.CXCTRNCOBROCLICODIGO = rs.CXCTRNCOBRO_CLICODIGO === null ? '' : rs.CXCTRNCOBRO_CLICODIGO;
          item.CXCTRNCOBROCOMCODIGO = rs.CXCTRNCOBRO_COMCODIGO === null ? '' : rs.CXCTRNCOBRO_COMCODIGO;
          item.CXCTRNCOBRONRODOC = rs.CXCTRNCOBRO_NRODOC === null ? '' : rs.CXCTRNCOBRO_NRODOC;
          item.DETTRNCOBROREFERENCIA = rs.DETTRNCOBRO_REFERENCIA === null ? '' : rs.DETTRNCOBRO_REFERENCIA;
          item.ENCFACCLICODIGO = rs.ENCFAC_CLICODIGO === null ? '' : rs.ENCFAC_CLICODIGO;
          item.ENCFACCOMCODIGO = rs.ENCFAC_COMCODIGO === null ? '' : rs.ENCFAC_COMCODIGO;
          item.ENCFACNUMERO = rs.ENCFAC_NUMERO === null ? '' : rs.ENCFAC_NUMERO;
          item.PROCODIGO = rs.PRO_CODIGO === null ? '' : rs.PRO_CODIGO;
          item.RETENCIONCODIGO = rs.RETENCION_CODIGO === null ? '' : rs.RETENCION_CODIGO;
          item.TRNBANNUMERO = rs.TRNBAN_NUMERO === null ? '' : rs.TRNBAN_NUMERO;
          item.TRNBANTIPO = rs.TRNBAN_TIPO === null ? '' : rs.TRNBAN_TIPO;
          item.TRNCOBRONRODOC = rs.TRNCOBRO_NRODOC === null ? '' : rs.TRNCOBRO_NRODOC;
          item.TRNRETENCIONNRO = rs.TRNRETENCION_NRO === null ? '' : rs.TRNRETENCION_NRO;
          item.TIPGASCODIGO = rs.TIPGAS_CODIGO === null ? '' : rs.TIPGAS_CODIGO;
          /*
          String strDato = "";
    
          strDato = eje.encontrarRegistro("CON_TIPGAS", "con_maecon",
            "CON_CODIGO='" + item.getStrConCodigo() + "'");
    
    
          if (strDato.equals("Si")) {
            item.setBoltipogasto(true);
          }
          */
          this.cxptrncobroService.variosarray[this.indicador].push(item);
        }
      }

      this.aggrid.refreshaggrid(this.cxptrncobroService.variosarray[this.indicador], this.defaultColDefVarios.objeto);
    });
    /*
      ponerColorAFilaxTipoCuenta();
      frmCxpTrnCobro.getTableVarios().redraw();
   */
  }

  calcularTotalDoc() {
    let dblTotalDoc = 0;

    for (let fila = 1; fila <= this.cxptrncobroService.docarray[this.indicador].length; fila++) {
      const item = this.cxptrncobroService.docarray[this.indicador][fila - 1];
      if (item.SELECCIONADO != null) {
        if (item.SELECCIONADO === true) {
          dblTotalDoc = dblTotalDoc + Number(item.TRNCOBROIMPORTE);
        } else {
          item.TRNCOBROIMPORTE = 0;
        }
      } else {
        item.TRNCOBROIMPORTE = 0;
      }
    }

    this.aggrid.refreshaggrid(this.cxptrncobroService.docarray[this.indicador], this.defaultColDefDoc.objeto);
    this.cxptrncobroService.trncobroarray[this.indicador].TOTALES = dblTotalDoc.toFixed(2);
  }

  async consultarCajaDev(strNumDoc) {

    const strSql = 'SELECT * FROM BAN_TRNCAJ WHERE TRNCAJ_DOCNRO = \'' + strNumDoc + '\' AND ' +
      'COM_CODIGO = \'01\' AND TRNCAJ_ORIGEN = \'CXP\' AND TRNCAJ_ANULADO = 0';

    try {
      const data = await this.cxptrncobroService.consultarCajaDev(strNumDoc);

      for (const rs of data) {
        const item: CxpFpdev = {};

        if (rs.TRNCAJ_TIPO === 'EF') {
          item.BANCLICODIGO = '';
        } else {
          item.BANCLICODIGO = rs.BANCLI_CODIGO;
          item.BANCLINOMBRE = await this.obtenerNombreBanco(rs.BANCLI_CODIGO);
        }

        item.CAJCODIGO = rs.CAJ_CODIGO;
        item.COMCODIGO = rs.COM_CODIGO;
        item.TRNPAGOCONCEPTO = rs.TRNCAJ_DESCRIPCION;
        item.CONCODIGO = await this.obtenerCuentaCaja(rs.CON_CODIGO);
        item.TRNPAGOFECHA = this.datePipe.transform(rs.TRNCAJ_FECHA, 'dd/MM/yyyy');
        item.TRNPAGOIMPORTE = rs.TRNCAJ_IMPORTE;
        item.TRNPAGOIMPORTE = item.TRNPAGOIMPORTE.toFixed(2);
        item.TRNPAGONUMERO = rs.TRNCAJ_NUMERO;
        item.TRNPAGOTIPO = rs.TRNCAJ_TIPO;
        this.cxptrncobroService.fpdevarray[this.indicador].push(item);
      }

      this.aggrid.refreshaggrid(this.cxptrncobroService.fpdevarray[this.indicador], this.defaultColDefFPDev.objeto);
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
    }
  }


  async consultarBancosDev(strNumDoc) {
    const strSql = 'SELECT * FROM BAN_TRNBAN WHERE TRNBAN_DOCNRO = \'' + strNumDoc + '\' AND ' +
      'COM_CODIGO = \'01\' AND TRNBAN_ORIGEN = \'CXP\' AND TRNBAN_ANULADO = 0';

    try {
      const data = await this.cxptrncobroService.consultarBancosDev(strNumDoc);
      for (const rs of data) {
        const item: CxpFpdev = {};
        item.BANCLINOMBRE = await this.obtenerNombreBanco(rs.BAN_CODIGO);
        item.BANCLICODIGO = rs.BAN_CODIGO;
        item.CAJCODIGO = '';
        item.COMCODIGO = rs.COM_CODIGO;
        item.TRNPAGOCONCEPTO = rs.TRNBAN_CONCEPTO;
        item.CONCODIGO = await this.obtenerCuentaBanco(rs.BAN_CODIGO);
        item.TRNPAGOFECHA = this.datePipe.transform(rs.TRNBAN_FECHA, 'dd/MM/yyyy');
        item.TRNPAGOIMPORTE = rs.TRNBAN_IMPORTE;
        item.TRNPAGOIMPORTE = item.TRNPAGOIMPORTE.toFixed(2);
        item.TRNPAGONUMERO = rs.TRNBAN_NUMERO;
        item.TRNPAGOTIPO = rs.TRNBAN_TIPO;
        this.cxptrncobroService.fpdevarray[this.indicador].push(item);
      }

      this.aggrid.refreshaggrid(this.cxptrncobroService.fpdevarray[this.indicador], this.defaultColDefFPDev.objeto);
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: mensaje
      });
    }
  }

  tabDatos() {
    /*
    let strTipoDocumento = '';
    // Tipo de documento
    if (this.selecttipo.name === 'Factura') {
      strTipoDocumento = 'FC';
    }
    if (this.selecttipo.name === 'Nota de Crédito') {
      strTipoDocumento = 'NC';
    }
    if (this.selecttipo.name === 'Nota de Débito') {
      strTipoDocumento = 'ND';
    }
    this.index = e.index;
    console.log(this.index);
    if (strTipoDocumento === 'NC'
      || strTipoDocumento === 'ND') {
      this.index = 1;
    } else {
      this.index = 0;
    }
    */
    if (this.index === 1) {
      // habilicacionCamposDeldocMod(verificarIsSaldoInicial());
      this.pasaTipodocumento();

      let factura = '';
      if (this.cxptrncobroService.trndatosanexarray[this.indicador].DESCTIPOCOMP !== 'NOTA DE CREDITO') {
        factura = 'FACTURA';
      }

      this.cxptrncobroService.trndatosanexarray[this.indicador].DESCTIPOCOMPMODIFICADO = factura;

    }
  }

  async pasaTipodocumento() {
    let strTipoDocumento = '';
    let tipDocNombre = '';

    // Tipo de documento
    if (this.selecttipo.name === 'Nota de Crédito') {
      tipDocNombre = 'NOTA DE CREDITO';
    }
    if (this.selecttipo.name === 'Nota de Débito') {
      tipDocNombre = 'NOTA DE DEBITO';
    }

    let codSriRef = '';
    const strFechaEmision = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    let strSentencia = '';
    strSentencia = strSentencia + ' to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')>= codsri_fechaini';
    strSentencia = strSentencia + ' and to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')<= codsri_fechafin';
    const sentencia = ' select codsri_codigo from  anex_maecodsri  where codsri_tipo=\'TC\' and  codsri_descripcion  like \''
      + tipDocNombre + '\' ' + ' and ' + strSentencia;

    try {
      const data = await this.cxptrncobroService.pasaTipodocumento(strFechaEmision, tipDocNombre);

      for (const ref of data) {
        codSriRef = ref.CODSRI_CODIGO;
      }

      if (codSriRef != null) {
        strTipoDocumento = codSriRef;
      } else {
        strTipoDocumento = '';
      }

    } catch (error1) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al conseguir el tipo referencia del tipo de SRI'
      });
    }


    if (strTipoDocumento.trim().length > 0) {
      this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPCOM = strTipoDocumento;
      this.cxptrncobroService.trndatosanexarray[this.indicador].DESCTIPOCOMP = tipDocNombre;
      /*txtTipoComprobante.setText(strTipoDocumento);
      txtTipoComprobante.setEnabled(false);
      lblTipoComprobante.setText(tipDocNombre);
      btnTipoComprobante.setEnabled(false);*/
    } else {
      this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_TIPCOM = '';
      this.cxptrncobroService.trndatosanexarray[this.indicador].DESCTIPOCOMP = '';
      /*txtTipoComprobante.setText('');
      txtTipoComprobante.setEnabled(true);
      lblTipoComprobante.setText('');
      btnTipoComprobante.setEnabled(true);*/
    }

  }

  verificarTotalImpuestos(accion): boolean {
    if (this.intNOIMPCXP === 1) {
      return true;
    }

    let dblBaseImpTarifa0 = 0;
    let dblBaseImpGrabadaIva = 0;
    let dblMontoIva = 0;
    let dblBaseImpNoObjetoIva = 0;
    let dblMontoIce = 0;
    let dblTotalImporteDoc = 0;
    let dblTotalImpuestos = 0;

    dblBaseImpTarifa0 = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPTARCERO);
    dblBaseImpGrabadaIva = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPGRABADAIVA);
    dblMontoIva = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOIVA);
    dblBaseImpNoObjetoIva = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_BASEIMPNOOBJETOIVA);
    dblMontoIce = Number(this.cxptrncobroService.trndatosanexarray[this.indicador].TRNANEX_MONTOICE);
    dblTotalImporteDoc = Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE);
    dblTotalImpuestos = dblBaseImpTarifa0 + dblBaseImpGrabadaIva
      + dblMontoIva + dblBaseImpNoObjetoIva + dblMontoIce;

    console.log(dblTotalImporteDoc, '!==', dblTotalImpuestos);
    if (dblTotalImporteDoc !== dblTotalImpuestos) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información ' + accion,
        detail: 'Error, el total impuestos debe de ser igual al Importe. El importe del documento tiene un valor de '
          + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE
      });
      this.cxptrncobroService.spin=false;//CDPJ
      return false;
    }

    return true;
  }

  getConfiguracion() {
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
    });
  }

  async eliminarFPAnt(strNumDoc, rollback) {
    let bolEliminar = false;

    bolEliminar = await this.eliminarCaja(strNumDoc, rollback);
    bolEliminar = await this.eliminarBancos(strNumDoc, rollback);

    return bolEliminar;
  }

  async eliminarCaja(strNumDoc, rollback) {
    let bolEliminar = false;

    const strSql = 'DELETE FROM BAN_TRNCAJ WHERE TRNCAJ_DOCNRO = \'' + strNumDoc + '\' AND ' +
      'COM_CODIGO = \'01\' AND TRNCAJ_ORIGEN = \'CXP\' AND TRNCAJ_ANULADO = 0';

    try {
      await this.cxptrncobroService.eliminarCaja(strNumDoc);
      bolEliminar = true;
      if (bolEliminar && !rollback) {
        this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', strNumDoc, 'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede eliminar el documento.'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }
    
    return bolEliminar;
  }

  async eliminarBancos(strNumDoc, rollback) {
    let bolEliminar = false;

    const strSql = 'DELETE FROM BAN_TRNBAN WHERE TRNBAN_DOCNRO = \'' + strNumDoc + '\' AND ' +
      'COM_CODIGO = \'01\'';

    try {
      await this.cxptrncobroService.eliminarBancos(strNumDoc);
      bolEliminar = true;

      if (bolEliminar && !rollback) {
        this.auditoriagral.registrarAuditoria('BAN_TRNBAN', strNumDoc, 'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede eliminar el documento.'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }

    return bolEliminar;
  }

  async eliminarCajaDev(strNumDoc, rollback) {
    let bolEliminar = false;

    const strSql = 'DELETE FROM BAN_TRNCAJ WHERE TRNCAJ_DOCNRO = \'' + strNumDoc + '\' AND ' +
      'COM_CODIGO = \'01\' AND TRNCAJ_ORIGEN = \'CXP\' AND TRNCAJ_ANULADO = 0';

    try {
      await this.cxptrncobroService.eliminarCajaDev(strNumDoc);
      bolEliminar = true;
      if (bolEliminar && !rollback) {
        this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', strNumDoc, 'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede eliminar el documento.'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }
    return bolEliminar;
  }

  async eliminarBancosDev(strNumDoc, rollback) {
    let bolEliminar = false;

    const strSql = 'DELETE FROM BAN_TRNBAN WHERE TRNBAN_DOCNRO = \'' + strNumDoc + '\' AND ' +
      'COM_CODIGO = \'01\' AND TRNBAN_ORIGEN = \'CXP\'';

    try {
      await this.cxptrncobroService.eliminarBancosDev(strNumDoc);
      bolEliminar = true;

      if (bolEliminar && !rollback) {
        this.auditoriagral.registrarAuditoria('BAN_TRNBAN', strNumDoc, 'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede eliminar el documento.'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }
    return bolEliminar;
  }

  async insertarFormaPago(strTrnCobroNroDoc) {
    let bolInsertar = false;

    for (let fila = 1; fila <= this.cxptrncobroService.fpantarray[this.indicador].length; fila++) {
      const item = this.cxptrncobroService.fpantarray[this.indicador][fila - 1];

      if (item.TIPODOC === 'EF') {
        bolInsertar = await this.insertarCaja(item, strTrnCobroNroDoc);
      } else {
        bolInsertar = await this.insertarBanco(item, strTrnCobroNroDoc);
      }
    }

    return bolInsertar;
  }

  async insertarCaja(item, strTrnCobroNroDoc) {
    let bolInsertar = false;
    const itemCaj: any = {};
    itemCaj.CAJ_CODIGO = item.CAJADOC;
    itemCaj.TRNCAJ_FECHA = item.FECHADOC.toString().includes('-') === true ? this.datePipe.transform(item.FECHADOC, 'dd/MM/yyyy') : item.FECHADOC;
    itemCaj.TRNCAJ_TIPO = item.TIPODOC;
    itemCaj.TRNCAJ_NUMERO = item.NUMERODOC;
    itemCaj.TRNCAJ_FORMAPAGO = item.TIPODOC;
    itemCaj.TRNCAJ_DOCNRO = strTrnCobroNroDoc;
    itemCaj.TRNCAJ_IMPORTE = Number(item.IMPORTEDOC) * (-1);
    if (item.CONCEPTODOC.length === 0) {
      item.CONCEPTODOC = '*';
    }
    itemCaj.TRNCAJ_DESCRIPCION = item.CONCEPTODOC;
    itemCaj.TRNCAJ_ANULADO = '0';
    itemCaj.TRNCAJ_FECHAVENCI = '';
    itemCaj.TRNCAJ_ORIGEN = 'CXP';
    itemCaj.COM_CODIGO = item.COMCODIGO;
    itemCaj.BANCLI_CODIGO = '';
    itemCaj.USU_IDENTIFICACION = this.cxptrncobroService.usuario.identificacion;
    itemCaj.CLI_CODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
    itemCaj.CEN_CODIGO = '';
    try {
      await this.cxptrncobroService.insertarCajaMovimiento(itemCaj);
      this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', item.NUMERODOC + '/' + strTrnCobroNroDoc, 'I',
        '', '01', '', '', '', '').subscribe(() => {
        });
      bolInsertar = true;
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede insertar la forma de pago en ' +
          'la caja'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }
    return bolInsertar;
  }

  async insertarBanco(item, strTrnCobroNroDoc) {
    console.log(item);
    let bolInsertar = false;

    const itemBan: any = {};
    itemBan.BAN_CODIGO = item.BANCODIGO;
    itemBan.TRNBAN_FECHA = item.FECHADOC;
    itemBan.TRNBAN_TIPO = item.TIPODOC;
    itemBan.TRNBAN_NUMERO = item.NUMERODOC;
    // itemBan.TRNBAN_DOCNRO = item.TRNCOBRONRODOC;
    itemBan.TRNBAN_DOCNRO = strTrnCobroNroDoc;
    itemBan.TRNBAN_IMPORTE = Number(item.IMPORTEDOC) * -1;
    itemBan.TRNBAN_CONCEPTO = item.CONCEPTODOC;
    itemBan.TRNBAN_BENEFICIARIO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_NOMBRE;
    itemBan.TRNBAN_ANULADO = '0';
    itemBan.CON_CODIGO = item.CONCODIGO;
    itemBan.COM_CODIGO = item.COMCODIGO;
    itemBan.TRNBAN_ORIGEN = 'CXP';
    itemBan.TRNBAN_NUMEROREFUSER = '';
    itemBan.TRNBAN_FECHAVENCE = item.FECHADOC;
    itemBan.ENCREQ_NUMERO = '';
    itemBan.PRO_CODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
    itemBan.TRNBAN_PAGOELEC = item.PAGOELECTRONICO === true ? '1' : '0';
    // itemBan.TRNBAN_PAGOELEC = item.PAGOELECTRONICO === ;


    try {

      const data = await this.cxptrncobroService.insertarBancoMovimiento(itemBan);

      for (const rs of data) {
        bolInsertar = true;
        // if (item.PAGOELECTRONICO === 0) {
        if (item.PAGOELECTRONICO === false) {
          if (item.TIPODOC === 'CH') {
            this.actualizaUltimoCheque(item.BANCODIGO, item.NUMERODOC);
          }
        } else {
          item.NUMERODOC = rs[':B1'];
          this.aggrid.refreshaggrid(this.cxptrncobroService.fpantarray[this.indicador], this.defaultColDefFPAnt.objeto);
        }

        // dlgCxP.getTablaFPAnt().redraw();
        this.auditoriagral.registrarAuditoria('BAN_TRNBAN', item.NUMERODOC + '/' + strTrnCobroNroDoc, 'I',
          '', '01', '', '', '', '').subscribe(() => {
          });
      }


    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede insertar la forma de pago en el banco'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }

    return bolInsertar;
  }

  async insertarCajaDev(item, strTrnCobroNroDoc) {
    let bolInsertar = false;
    const itemCaj: any = {};
    itemCaj.CAJ_CODIGO = item.CAJCODIGO;
    itemCaj.TRNCAJ_FECHA = item.TRNPAGOFECHA;
    itemCaj.TRNCAJ_TIPO = item.TRNPAGOTIPO;
    itemCaj.TRNCAJ_NUMERO = item.TRNPAGONUMERO;
    itemCaj.TRNCAJ_FORMAPAGO = item.TRNPAGOTIPO;
    itemCaj.TRNCAJ_DOCNRO = strTrnCobroNroDoc;
    itemCaj.TRNCAJ_IMPORTE = Number(item.TRNPAGOIMPORTE);
    if (item.TRNPAGOCONCEPTO.length === 0) {
      item.TRNPAGOCONCEPTO = '*';
    }
    itemCaj.TRNCAJ_DESCRIPCION = item.TRNPAGOCONCEPTO;
    itemCaj.TRNCAJ_ANULADO = '0';
    if (item.TRNPAGOTIPO === 'CH') {
      itemCaj.TRNCAJ_FECHAVENCI = item.TRNPAGOFECHA;
    } else {
      itemCaj.TRNCAJ_FECHAVENCI = ''; // Fecha de vencimiento
    }
    itemCaj.TRNCAJ_ORIGEN = 'CXP';
    itemCaj.COM_CODIGO = '01';
    itemCaj.BANCLI_CODIGO = item.BANCLICODIGO;
    itemCaj.USU_IDENTIFICACION = this.cxptrncobroService.usuario.identificacion;
    itemCaj.CLI_CODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
    itemCaj.CEN_CODIGO = '';
    try {
      await this.cxptrncobroService.insertarCajaMovimiento(itemCaj);
      this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', item.TRNPAGONUMERO + '/' + strTrnCobroNroDoc, 'I',
        '', '01', '', '', '', '').subscribe(() => {
        });
      bolInsertar = true;
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede insertar la forma de pago en ' +
          'la caja'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }
    return bolInsertar;
  }

  async insertarBancoDev(item, strTrnCobroNroDoc) {
    let bolInsertar = false;

    const itemBan: any = {};
    itemBan.BAN_CODIGO = item.BANCLICODIGO;
    itemBan.TRNBAN_FECHA = item.TRNPAGOFECHA;
    itemBan.TRNBAN_TIPO = item.TRNPAGOTIPO;
    itemBan.TRNBAN_NUMERO = item.TRNPAGONUMERO;
    itemBan.TRNBAN_DOCNRO = strTrnCobroNroDoc;
    itemBan.TRNBAN_IMPORTE = Number(item.TRNPAGOIMPORTE);
    itemBan.TRNBAN_CONCEPTO = item.TRNPAGOCONCEPTO;
    itemBan.TRNBAN_BENEFICIARIO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
    itemBan.TRNBAN_ANULADO = '0';
    itemBan.CON_CODIGO = item.CONCODIGO;
    itemBan.COM_CODIGO = item.COMCODIGO;
    itemBan.TRNBAN_ORIGEN = 'CXP';
    itemBan.TRNBAN_NUMEROREFUSER = '';
    itemBan.TRNBAN_FECHAVENCE = item.TRNPAGOFECHA;
    itemBan.ENCREQ_NUMERO = '';
    itemBan.PRO_CODIGO = '';
    itemBan.TRNBAN_PAGOELEC = '';

    try {
      const data = await this.cxptrncobroService.insertarBancoMovimiento(itemBan);
      for (const rs of data) {
        bolInsertar = true;

        if (item.TRNPAGOTIPO === 'CH') {
          this.actualizaUltimoCheque(item.BANCLICODIGO, item.TRNPAGONUMERO);
        }

        // dlgCxP.getTablaFPAnt().redraw();
        this.auditoriagral.registrarAuditoria('BAN_TRNBAN', item.TRNPAGONUMERO + '/' + strTrnCobroNroDoc, 'I',
          '', '01', '', '', '', '').subscribe(() => {
          });
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede insertar la forma de pago en el banco'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }

    return bolInsertar;
  }

  async eliminarTrnDocumento(strCondicion) {
    let bolEliminar = false;
    const strSentencia = 'DELETE FROM CXP_TRNDOCUMENTO WHERE ' + strCondicion;

    try {
      await this.cxptrncobroService.eliminarDocumento(strCondicion);
      bolEliminar = true;
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede eliminar el documento'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }

    return bolEliminar;
  }

  async eliminarFPDev(strNumDoc, rollback) {
    let bolEliminar = false;

    bolEliminar = await this.eliminarCajaDev(strNumDoc, rollback);
    bolEliminar = await this.eliminarBancosDev(strNumDoc, rollback);

    return bolEliminar;
  }


  async insertarDocumentos(strNroDoc) {
    let bolInsertar = false;
    for (let fila = 1; fila <= this.cxptrncobroService.docarray[this.indicador].length; fila++) {
      const item = this.cxptrncobroService.docarray[this.indicador][fila - 1];
      if (item.SELECCIONADO !== null) {
        if (item.SELECCIONADO === true) {
          const itemDoc: any = {};
          itemDoc.TRNCOBRO_NRODOC = strNroDoc;
          itemDoc.PRO_CODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
          itemDoc.COM_CODIGO = '01';
          itemDoc.TRNDOCUMENTO_NRODOC = item.TRNCOBRONRODOC;
          itemDoc.TRNDOCUMENTO_TIPODOC = item.TRNCOBROTIPODOC;
          itemDoc.TRNDOCUMENTO_IMPORTE = Number(item.TRNCOBROIMPORTE);
          itemDoc.TRNDOCUMENTO_DESCUENTO = 0;
          itemDoc.TRNDOCUMENTO_TIPODSCTO = '';
          itemDoc.TRNDOCUMENTO_REF = 'F';
          itemDoc.TRNDOCUMENTO_FLAG = '0';
          try {
            await this.cxptrncobroService.INSERTARTRNDOCUMENTO(itemDoc);
            bolInsertar = true;
          } catch (error) {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Error',
              detail: 'No se puede insertar la información.'
            });
            this.cxptrncobroService.spin=false;//CDPJ
          }
        }
      }
    }
    return bolInsertar;
  }

  async insertarFormaPagoDev(strTrnCobroNroDoc) {
    let bolInsertar = false;

    for (let fila = 1; fila <= this.cxptrncobroService.fpdevarray[this.indicador].length; fila++) {
      const item = this.cxptrncobroService.fpdevarray[this.indicador][fila - 1];
      console.log(item);

      if (item.TRNPAGOTIPO === 'EF' || item.TRNPAGOTIPO === 'CH') {
        bolInsertar = await this.insertarCajaDev(item, strTrnCobroNroDoc);
      } else {
        bolInsertar = await this.insertarBancoDev(item, strTrnCobroNroDoc);
      }
    }

    return bolInsertar;
  }

  async faltanCamposDet() {
    let intIndiceLista = 0;
    let item: CxpVarios = {};
    let strDato = '';

    for (intIndiceLista = 0; intIndiceLista < this.cxptrncobroService.variosarray[this.indicador].length; intIndiceLista++) {
      item = this.cxptrncobroService.variosarray[this.indicador][intIndiceLista];
      if (item.CONCODIGO === '') {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'Error, Cuenta contable incorrecta'
        });
        // frmCxpTrnCobro.getTableVarios().setSelection(9, intIndiceLista+1 , true);
        // frmCxpTrnCobro.getTableVarios().redraw();
        return false;
      } else {
        // Verifica existencia de la cuenta contable
        try {
          const er = await this.cxptrncobroService.erConNombreProm(item.CONCODIGO);
          if (er !== null) {
            if (er[0] !== undefined) {
              strDato = er[0].CON_NOMBRE;
            }
          }
        } catch (error) {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Información',
            detail: '! Proceso validar cuenta contable no terminó satisfactoriamente...!'
          });
          this.cxptrncobroService.spin=false;//CDPJ
        }
        if (strDato === '') {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Información',
            detail: 'Error, Código de la cuenta contable incorrecta'
          });
          item.CONCODIGO = '';
          return false;
        }
      }


      if (item.DEBE === 0 && item.HABER === 0) {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'Error, Cuenta contable incorrecta'
        });
        // frmCxpTrnCobro.getTableVarios().setSelection(9, intIndiceLista + 1, true);
        // frmCxpTrnCobro.getTableVarios().redraw();
        return false;
      }

    } // fin de for


    return true;

  }

  async guardarDetalleVarios() {
    let intIndiceLista = 0;
    let item;

    if (await this.faltanCamposDet() === false) {
      this.cxptrncobroService.spin=false;//CDPJ
      return false;
    }

    for (intIndiceLista = 0; intIndiceLista < this.cxptrncobroService.variosarray[this.indicador].length; intIndiceLista++) {
      item = this.cxptrncobroService.variosarray[this.indicador][intIndiceLista];
      const itemVar: any = {};
      itemVar.TRNCOBRO_NRODOC = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC;
      itemVar.COM_CODIGO = '01';
      itemVar.DETTRNCOBRO_NROSEC = item.DETTRNCOBRONROSEC;
      itemVar.CON_CODIGO = item.CONCODIGO;
      if (Number(item.DEBE) !== 0) {
        itemVar.DETTRNCOBRO_IMPORTE = Number(item.DEBE);
      }
      if (Number(item.HABER) !== 0) {
        itemVar.DETTRNCOBRO_IMPORTE = Number(item.HABER) * -1;
      }
      // Si es anticipo guardar en código del anticipo
      item.DETTRNCOBROREFERENCIA = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC;
      itemVar.DETTRNCOBRO_REFERENCIA = item.DETTRNCOBROREFERENCIA;
      itemVar.TRNRETENCION_NRO = item.TRNRETENCIONNRO;
      itemVar.RETENCION_CODIGO = item.RETENCIONCODIGO;
      itemVar.ENCFAC_NUMERO = item.ENCFACNUMERO;
      itemVar.ENCFAC_COMCODIGO = item.ENCFACCOMCODIGO;
      itemVar.ENCFAC_CLICODIGO = item.ENCFACCLICODIGO;
      itemVar.BAN_CODIGO = item.BANCODIGO;
      itemVar.TRNBAN_TIPO = item.TRNBANTIPO;
      itemVar.TRNBAN_NUMERO = item.TRNBANNUMERO;
      itemVar.CEN_CODIGO = item.CENCODIGO;
      itemVar.PRO_CODIGO = item.PROCODIGO;
      itemVar.DETTRNCOBRO_TIPO = '';
      itemVar.DETTRNCOBRO_COMPROBANTE = '';
      itemVar.TIPGAS_CODIGO = item.TIPGASCODIGO;

      try {
        const data = await this.cxptrncobroService.insertarDETTRNCOBRO(itemVar);
        if (item.DETTRNCOBRONROSEC === 0) {
          this.auditoriagral.registrarAuditoria('CXP_DETTRNCOBRO',
            this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC + '/' + item.CONCODIGO, 'I',
            '', '01', '', '', '', '').subscribe();

        } else {
          this.auditoriagral.registrarAuditoria('CXP_DETTRNCOBRO',
            this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC + '/' + item.CONCODIGO, 'A',
            '', '01', '', '', '', '').subscribe();

        }
        for (const rs of data) {
          item.DETTRNCOBRONROSEC = rs.DETTRNCOBRO_NROSEC;
          item.TRNBANNUMERO = rs.TRNBAN_NUMERO === null ? '' : rs.TRNBAN_NUMERO;
        }
      } catch (error) {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: '! Proceso guardar datos de los documentos no terminó satisfactoriamente.... !'
        });
        this.cxptrncobroService.spin=false;//CDPJ
        return false;
      }
    } // Fin de for

    this.messageService.add({
      key: 'cxptrncobro',
      severity: 'success',
      summary: 'Información',
      detail: 'Datos guardados correctamente'
    });
    
    return true;

  }

  async generarAsiento() {

    let strAsiNro = '';
    let strTipoDiario = '';

    // Tipo de documento
    if (this.selecttipo.name === 'Nota de Crédito') {
      strTipoDiario = 'NCP';
    }
    if (this.selecttipo.name === 'Nota de Débito') {
      strTipoDiario = 'NDP';
    }
    if (this.selecttipo.name === 'Factura') {
      strTipoDiario = 'ASIC'; // Diario de proveedores GSRF
    }
    const item: any = {};
    item.TRNCOBRO_NRODOC = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC;
    item.COM_CODIGO = '01';
    item.ASI_USUARIO_ACT = this.cxptrncobroService.usuario.identificacion;
    item.TIPDIA_CODIGO = strTipoDiario;
    item.ASI_COMPROBANTE = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SERIE +
      this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;

    try {
      const data = await this.cxptrncobroService.CXPGENERAASIENTOPROVPRO(item);

      for (const rs of data) {
        strAsiNro = rs[':B1'] == null ? '' : rs[':B1'];
        this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO = strAsiNro;
      }
      this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
        this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO, 'I',
        '', '01', '', '', '', '').subscribe();
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'success',
        summary: 'Información',
        detail: 'El Asiento Contable se generó correctamente'
      });
      this.cxptrncobroService.spin=false;//CDPJ
     
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: '! Proceso genera asiento contable no terminó satisfactoriamente.... !'
      });
      this.cxptrncobroService.spin=false;//CDPJ
      return false;
    }

    return true;
  }

  async insertaDepositosYRetiros() {

    let item;
    let intIndiceLista = 0;

    const strFechaEmision = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');

    for (intIndiceLista = 0; intIndiceLista < this.cxptrncobroService.variosarray[this.indicador].length; intIndiceLista++) {
      item = this.cxptrncobroService.variosarray[this.indicador][intIndiceLista];

      if (item.TRNBANTIPO === 'DP' || item.TRNBANTIPO === 'RE') {
        const itemDR: any = {};
        itemDR.BAN_CODIGO = item.BANCODIGO;
        itemDR.TRNBAN_TIPO = item.TRNBANTIPO;
        itemDR.TRNBAN_NUMERO = item.TRNBANNUMERO;
        itemDR.TRNBAN_FECHA = strFechaEmision;

        if (Number(item.HABER) !== 0) {
          itemDR.TRNBAN_IMPORTE = Number(item.HABER) * -1;
        } else {
          itemDR.TRNBAN_IMPORTE = Number(item.DEBE);
        }
        itemDR.TRNBAN_CONCEPTO = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_CONCEPTO;
        itemDR.TRNBAN_BENEFICIARIO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_NOMBRE;
        itemDR.COM_CODIGO = '01';
        itemDR.ASI_NRO = this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO;
        itemDR.TRNBAN_ORIGEN = 'CXP';
        itemDR.TRNCOBRO_NRODOC = this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC;
        itemDR.DETTRNCOBRO_NROSEC = item.DETTRNCOBRONROSEC;

        try {
          const data = await this.cxptrncobroService.actualizaTrnBan(itemDR);
          for (const rs of data) {
            item.TRNBANNUMERO = rs.TRNBAN_NUMERO === null ? '' : rs.TRNBAN_NUMERO;
          }
        } catch (error) {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Error',
            detail: '! Proceso generar Depósitos o Retiros por las cuentas ingresadas en varios, no terminó satisfactoriamente.... !'
          });
          this.cxptrncobroService.spin=false;//CDPJ
          return false;
        } /*Fin si es depósito o retiro*/
      }
    }

    return true;
  }

  async generarAsientoAnt(strTrnCobroNroDoc) {
    let bolGenerar = false;

    const item: any = {};
    item.TRNCOBRO_NRODOC = strTrnCobroNroDoc;
    item.PRO_CODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
    item.COM_CODIGO = '01';
    item.ORIGEN = 'CXP';
    item.TIPDIA_CODIGO = 'CE';
    item.USUARIO = this.cxptrncobroService.usuario.identificacion;

    try {
      await this.cxptrncobroService.GENASIENTOANT(item);

      // Recuperar el número del asiento
      const er = await this.cxptrncobroService.erAsiNroProm(strTrnCobroNroDoc);
      let strNumAsi = '';
      if (er !== null) {
        if (er[0] !== undefined) {
          strNumAsi = er[0].ASI_NRO;
        }
      }

      if (strNumAsi.length > 0) {
        this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO = strNumAsi;

        this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
          strNumAsi + '/CXP', 'I',
          '', '01', '', '', '', '').subscribe();

        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'success',
          summary: 'Información',
          detail: 'El Asiento Contable se generó correctamente'
        });
        this.cxptrncobroService.spin=false;//CDPJ
        bolGenerar = true;
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se generó el asiento contable'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }
    return bolGenerar;
  }
  //CDPJ
  async generarAsientoAntN(strTrnCobroNroDoc) {
    let bolGenerar = false;

    const item: any = {};
    item.TRNCOBRO_NRODOC = strTrnCobroNroDoc;
    item.PRO_CODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
    item.COM_CODIGO = '01';
    item.ORIGEN = 'CXP';
    item.TIPDIA_CODIGO = 'CE';
    item.USUARIO = this.cxptrncobroService.usuario.identificacion;

    try {
      await this.cxptrncobroService.GENASIENTOANTN(item);

      // Recuperar el número del asiento
      const er = await this.cxptrncobroService.erAsiNroProm(strTrnCobroNroDoc);
      let strNumAsi = '';
      if (er !== null) {
        if (er[0] !== undefined) {
          strNumAsi = er[0].ASI_NRO;
        }
      }

      if (strNumAsi.length > 0) {
        this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO = strNumAsi;

        this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
          strNumAsi + '/CXP', 'I',
          '', '01', '', '', '', '').subscribe();

        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'success',
          summary: 'Información',
          detail: 'El Asiento Contable se generó correctamente'
        });
        this.cxptrncobroService.spin=false;//CDPJ
        bolGenerar = true;
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se generó el asiento contable'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }
    return bolGenerar;
  }
  async generarAsientoAnt2(strTrnCobroNroDoc) {
    let bolGenerar = false;

    const item: any = {};
    item.TRNCOBRO_NRODOC = strTrnCobroNroDoc;
    item.PRO_CODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
    item.COM_CODIGO = '01';
    item.ORIGEN = 'CXP';
    item.TIPDIA_CODIGO = 'CHPF';
    item.USUARIO = this.cxptrncobroService.usuario.identificacion;

    try {
      await this.cxptrncobroService.GENASIENTOANT2(item);

      // Recuperar el número del asiento
      const er = await this.cxptrncobroService.erAsiNroProm(strTrnCobroNroDoc);
      let strNumAsi = '';
      if (er !== null) {
        if (er[0] !== undefined) {
          strNumAsi = er[0].ASI_NRO;
        }
      }

      if (strNumAsi.length > 0) {
        this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO = strNumAsi;

        this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
          strNumAsi + '/CXP', 'I',
          '', '01', '', '', '', '').subscribe();

        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'success',
          summary: 'Información',
          detail: 'El Asiento Contable se generó correctamente'
        });
        this.cxptrncobroService.spin=false;//CDPJ
        bolGenerar = true;
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se generó el asiento contable del cheque'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }
    return bolGenerar;
  }
  //CDPJ
  async generarAsientoDevAnt(strTrnCobroNroDoc) {
    let bolGenerar = false;

    const item: any = {};
    item.TRNCOBRO_NRODOC = strTrnCobroNroDoc;
    item.PRO_CODIGO = this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO;
    item.COM_CODIGO = '01';
    item.ORIGEN = 'CXP';
    item.TIPDIA_CODIGO = 'CI';
    item.USUARIO = this.cxptrncobroService.usuario.identificacion;

    try {
      await this.cxptrncobroService.GenAsientoDevAnt(item);
      // Recuperar el número del asiento
      const er = await this.cxptrncobroService.erAsiNroProm(strTrnCobroNroDoc);
      let strNumAsi = '';
      if (er !== null) {
        if (er[0] !== undefined) {
          strNumAsi = er[0].ASI_NRO;
        }
      }

      if (strNumAsi.length > 0) {
        this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO = strNumAsi;

        this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
          strNumAsi + '/CXP', 'I',
          '', '01', '', '', '', '').subscribe();

        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'success',
          summary: 'Información',
          detail: 'El Asiento Contable se generó correctamente'
        });
        this.cxptrncobroService.spin=false;//CDPJ
        bolGenerar = true;
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se generó el asiento contable'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }

    return bolGenerar;
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'FCCLDA') {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para la Facturación.'
        });
        document.getElementById('CLI_CODIGO').focus();
      }
    }
  }

  comprobarClave() {
    this.cxptrncobroService.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  async accionesAutorizacion() {
    if (this.autorizacionCodigo === 'NOIMPCXP') {
      let strTipoDocumento = '';

      if (this.selecttipo.name === 'Factura') {
        strTipoDocumento = 'FC';
      }
      if (this.selecttipo.name === 'Nota de Crédito') {
        strTipoDocumento = 'NC';
      }
      if (this.selecttipo.name === 'Nota de Débito') {
        strTipoDocumento = 'ND';
      }
      if (this.selecttipo.name === 'Anticipo') {
        strTipoDocumento = 'ANP';
      }
      if (this.selecttipo.name === 'Dev. de Anticipos') {
        strTipoDocumento = 'DANP';
      }

      this.displayDialogAutorizacion = false;
      this.intNOIMPCXP = 1;
      this.guardarparte2(strTipoDocumento);
    }

    if (this.autorizacionCodigo === 'ACTDOC') {
      if (await this.actualizarEstadoDocumento(
        'cxp_trncobro', 'trncobro_estado', '',
        'trncobro_nrodoc=' + '\''
        + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC + '\'') === true) {
        try {
          this.auditoriagral.registrarAuditoria('CXP_TRNCOBRO',
            this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC + '/ACTIVA', 'A',
            '', '01', '', '', '', '').subscribe();

        } catch (error) {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Error',
            detail: 'No se puede guardar en la auditoría.'
          });
        }
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'Tiene que volver a grabar la factura para que se registre en cartera pasiva, ' +
            'realice un cambio en las observaciones'
        });

        this.mostrarDatos(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);

      }
    }
  }

  async actualizarEstadoDocumento(strTabla, strCampoAct, strEstado, strCondicion) {
    let consulta = '';
    let resultado = false;

    consulta = 'update ' + strTabla + ' set ' + strCampoAct + '=\'' + strEstado + '\'';
    consulta = consulta + ' where com_codigo=' + '\'01\'';
    consulta = consulta + ' and ' + strCondicion;


    try {
      await this.cxptrncobroService.actualizarEstadoDocumento(strEstado, strCondicion);
      resultado = true;
    } catch (error) {
    }

    return resultado;


  }

  async verificarExistenciaCaja(strCajCodigo) {
    let bolExiste = true;

    try {
      const data = await this.cxptrncobroService.verificarCaja(strCajCodigo);
      if (data === null) {
        bolExiste = false;
      }
      for (const rs of data) {
        if (rs.CAJ_CODIGO === null) {
          bolExiste = false;
          return bolExiste;
        }
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar la existencia de la caja'
      });
    }

    return bolExiste;
  }

  async verificarImporte() {

    let dblImporteAnt = 0;
    let dblSaldo = 0;
    let dblSaldoActual = 0;
    let dblImporteAct = 0;

    // Consulta el importe anterior
    try {
      const data = await this.cxptrncobroService.consultarImporteAnterior(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
      for (const rs of data) {
        dblImporteAnt = rs.TRNCOBRO_IMPORTE === 0 ? 0 : rs.TRNCOBRO_IMPORTE;
      }
    } catch (error1) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'Consultar importe de Documento no terminó satisfactoriamente.... !'
      });
    }

    // Verifica que importe no sea mayor que el saldo
    dblSaldo = await this.saldoDocumento(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA,
      this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO);
    dblSaldoActual = dblSaldo + dblImporteAnt;

    dblImporteAct = Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE);

    // if (dblImporteAct > dblSaldoActual) {
    //   this.messageService.add({
    //     key: 'cxptrncobro',
    //     severity: 'error',
    //     summary: 'Información',
    //     detail: 'Error, el importe no puede ser mayor al saldo'
    //   });
    //   return false;
    // } else {
      return true;
    //}
  }


  autorizacionSRI(): boolean {
    if (this.intNOIMPCXP === 1) {
      return true;
    }

    let strCondicion = '';
    const strFechaEmision = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    let strTipoDocumento = '';

    // Tipo de documento
    if (this.selecttipo.name === 'Nota de Crédito') {
      strTipoDocumento = '04';
    }
    if (this.selecttipo.name === 'Nota de Débito') {
      strTipoDocumento = '05';
    }
    if (this.selecttipo.name === 'Factura') {
      strTipoDocumento = '01';
    }

    strCondicion = 'pro_codigo=\'' + this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO + '\''
      + ' and autsri_tipodoc=\'' + strTipoDocumento + '\' and ';
    strCondicion = strCondicion + ' autsri_fechaini<=to_date(\''
      + strFechaEmision
      + '\',\'dd/mm/yyyy\') and autsri_fechafin >= to_date(\''
      + strFechaEmision + '\',\'dd/mm/yyyy\') and ';
    strCondicion = strCondicion
      + ' autsri_serieestab||autsri_serieptoemi = \''
      + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SERIE + '\' and';
    strCondicion = strCondicion + ' autsri_secuencialini<=to_number(\''
      + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE + '\')'
      + ' and autsri_secuencialfin >= to_number(\''
      + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE + '\')';

    try {
      const strDatos = this.cxptrncobroService.erAutorizacionSriProm(this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO, strTipoDocumento, strFechaEmision,
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_SERIE, this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
      if (strDatos != null) {
        if (strDatos[0] !== undefined) {
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHAVENCE = strDatos[0].AUTSRI_FECHAFIN;
        } else {
          this.messageService.add({
            key: 'cxptrncobro',
            severity: 'error',
            summary: 'Información',
            detail: 'No existe número de autorización del SRI para este documento, por favor verifique la información en el maestro del proveedor'
          });
          this.cxptrncobroService.spin=false;//CDPJ
        }
      } else {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'No existe número de autorización del SRI para este documento, por favor verifique la información en el maestro del proveedor'
        });
        this.cxptrncobroService.spin=false;//CDPJ
      }
    } catch (error1) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al Leer el Número de Autorización de acuerdo al maestro de proveedores'
      });
      this.cxptrncobroService.spin=false;//CDPJ
      return false;
    }
    return true;
  }

  async verificarExistenciaComprobante() {

    let sentencia = '';
    let strTipoDocumento = '';
    let bolResultado = false;

    // Tipo de documento
    if (this.selecttipo.name === 'Factura') {
      strTipoDocumento = 'FC';
    }
    if (this.selecttipo.name === 'Nota de Crédito') {
      strTipoDocumento = 'NC';
    }
    if (this.selecttipo.name === 'Nota de Débito') {
      strTipoDocumento = 'ND';
    }
    if (this.selecttipo.name === 'Anticipo') {
      strTipoDocumento = 'ANP';
    }
    if (this.selecttipo.name === 'Dev. de Anticipos') {
      strTipoDocumento = 'DANP';
    }

    sentencia = 'select count(*) as coun from cxp_trncobro where trncobro_nrocomprobante='
      + '\'' + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE + '\'';
    sentencia = sentencia + ' and com_codigo=' + '\'01\'';
    sentencia = sentencia + ' and pro_codigo=' + '\''
      + this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO + '\'';
    sentencia = sentencia + ' and trncobro_tipodoc=' + '\''
      + strTipoDocumento + '\'';
    sentencia = sentencia + ' and trncobro_nrodoc<>' + 'nvl(' + '\''
      + this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC + '\'' + ',\'rere\')';

    try {
      const data = await this.cxptrncobroService.verificarExistenciaComprobante(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
        this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO, strTipoDocumento, this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
      for (const rstipo of data) {
        if (rstipo.COUN === 1) {
          bolResultado = true;
        } else {
          bolResultado = false;
        }
      }

    } catch (error1) {
    }

    return bolResultado;
  }

  VerificaTotalFPImporte(): boolean {
    let intIndiceLista = 0;
    let itemVarios;
    let dblTotalImporteVarios = 0;
    let dblImporte = 0;

    // Total de varios
    for (intIndiceLista = 0; intIndiceLista < this.cxptrncobroService.fpantarray[this.indicador].length; intIndiceLista++) {
      itemVarios = this.cxptrncobroService.fpantarray[this.indicador][intIndiceLista];
      dblTotalImporteVarios = dblTotalImporteVarios + Number(itemVarios.IMPORTEDOC);
    }

    dblTotalImporteVarios = Math.abs(dblTotalImporteVarios);

    dblImporte = Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE);

    if (dblTotalImporteVarios !== dblImporte) {
      return false;
    } else {
      return true;
    }

  }

  async verificarIngresoTG() {
    let intIndiceLista = 0;
    let item;
    let strDato = '';
    for (intIndiceLista = 0; intIndiceLista < this.cxptrncobroService.variosarray[this.indicador].length; intIndiceLista++) {
      item = this.cxptrncobroService.variosarray[this.indicador][intIndiceLista];

      try {
        const er = await this.cxptrncobroService.erConTipgas(item.CONCODIGO);
        if (er !== null) {
          if (er[0] !== undefined) {
            strDato = er[0].CON_TIPGAS;
          }
        }
      } catch (error1) {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Error',
          detail: '! Proceso validar bodega  no terminó satisfactoriamente.... !'
        });
      }

      if (item.TIPGASCODIGO === '' && strDato === 'Si') {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Error',
          detail: 'Ingrese el tipo de gasto en los items de la factura'
        });
        return false;
      }
    }
    return true;
  }

  desplegarDatosProveedor() {
    this.cxptrncobroService.erConcodigo1(this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO).subscribe(strDatos => {
      if (strDatos !== null) {
        if (strDatos[0] !== undefined) {
          this.strCodigoConPro = strDatos[0].CON_CODIGO1;
        }
      }
    });
  }

  async actualizarEstadoFacturaProv(strCodProv, strTrnCobroNroDoc) {
    try {
      await this.cxptrncobroService.ACTUALIZAESTADOFACPRO(strCodProv, strTrnCobroNroDoc);
    } catch (error1) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: '! Proceso actualizar estado de las facturas de proveedores, no terminó satisfactoriamente.... !'
      });
      this.cxptrncobroService.spin=false;//CDPJ
    }
  }

  actualizaUltimoCheque(strBanCodigo, strNumCh) {
    let strSql = 'UPDATE BAN_MAEBAN SET BAN_ULTIMOCHEQUE = \'' + strNumCh + '\'';
    strSql = strSql + ' WHERE BAN_CODIGO = \'' + strBanCodigo + '\' AND COM_CODIGO =\'01\'';

    try {
      this.cxptrncobroService.actualizaUltimoCheque(strNumCh, strBanCodigo);
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede actualizar el último cheque del banco'
      });
    }
  }

  async obtenerComprobante(strNumDoc) {
    let strNumC = '';

    try {
      const er = await this.cxptrncobroService.erTrnCobroNroComprobanteProm(strNumDoc);
      if (er !== null) {
        if (er[0] !== undefined) {
          strNumC = er[0].TRNCOBRO_NROCOMPROBANTE;
        }
      }

    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede encontrar el número de comprobante.'
      });
    }
    return strNumC;
  }

  docSeleccionado(valor) {
    if (valor.data.SELECCIONADO === true) {
      valor.data.TRNCOBROIMPORTE = valor.data.TRNCOBROSALDO;
    }

    this.calcularTotalDoc();
  }

  async eliminarRegistro() {
    let strTipoDocumento = '';
    if (this.index === undefined) {
      this.index = 0;
    }
    if (this.index === 0) { // generales
      // Verifica los permisos
      if (this.permisoPasiva !== null) {
        if (this.permisoPasiva.PERTODO === 0) {
          if (this.permisoPasiva.PERELIMACION === 0) {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.cxptrncobroService.usuario.identificacion
                + ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
    } else {
      return;
    }

    // verificar que el cheque girado por la devolución de anticipos no haya
    // sido conciliado
    if (await this.chequeConciliado('eliminar')) {
      return;
    }

    // verificar que el cheque cobrado no haya sido depositado
    if (await this.chequeDepositado('eliminar')) {
      return;
    }

    // verificar que el efectivo no haya sido depositado
    if (await this.efectivoDepositado('eliminar')) {
      return;
    }

    // verificar que los depósitos no hayan sido conciliados
    if (await this.depositoConciliado('eliminar')) {
      return;
    }

    // verificar que el anticipo no haya sido utilizado
    if (this.anticipoUtilizado('eliminar')) {
      return;
    }

    // verificar si tiene pagos

    if (this.selecttipo.name === 'Nota de Crédito') {
      strTipoDocumento = 'NC';
    }
    if (this.selecttipo.name === 'Nota de Débito') {
      strTipoDocumento = 'ND';
    }
    if (this.selecttipo.name === 'Anticipo') {
      strTipoDocumento = 'ANP';
    }
    if (this.selecttipo.name === 'Dev. de Anticipos') {
      strTipoDocumento = 'DANP';
    }

    if (this.chkSaldoInicial === true) {
      const dblPagoDoc = await this.cxp_fnc_PagadoDocumento(
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC,
        this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO);

      if (dblPagoDoc !== 0) {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Información',
          detail: 'El documento no puede ser eliminado ya que tiene abonos'
        });
        return;
      }
    }

    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Eliminar',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarcxptrncobro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarCartera(strTipoDocumento);
      },
      reject: () => {
        this.confirmationService.close();
        return;
      }
    });

  }

  async eliminarCartera(strTipoDocumento) {
    if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_ESTADO === 'A') {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'El documento no puede ser eliminado ya que se encuentra anulado'
      });
    }

    if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC === '') {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'Seleccione el registro a Eliminar'
      });
      return;
    }

    // Tipo de documento
    if (this.selecttipo.name === 'Nota de Crédito') {
      strTipoDocumento = 'NC';
    }
    if (this.selecttipo.name === 'Anticipo') {
      strTipoDocumento = 'ANP';
    }
    if (this.selecttipo.name === 'Dev. de Anticipos') {
      strTipoDocumento = 'DANP';
    }

    try {
      await this.cxptrncobroService.eliminarTrnCobro(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
      this.auditoriagral.registrarAuditoria('CXP_TRNCOBRO',
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC + '/' +
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, 'E',
        '', '01', '', '', '', '').subscribe();

      if (strTipoDocumento === 'NC') {
        await this.actualizarEstadoFacturaProv(
          this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO,
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA);
      }

      if (strTipoDocumento === 'ANP') {
        await this.eliminarFPAnt(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC, false);
      }

      if (strTipoDocumento === 'DANP') {
        await this.eliminarFPDev(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC, false);
      }
      this.eliminarPestanias();
      this.cancelar();
      /*
      nuevoRegistro();
      groupCabecera.setEnabled(false);
      groupCabecera2.setEnabled(false);
      groupCabecera3.setEnabled(false);
      tabDocumentos.setEnabled(false);
      cmbTipo.deselectAll();
      activaBotones("INI", true, false, false, false, true, true, true,
        true, true, false, false, false);
    */
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: '! Proceso Eliminar Registro no terminó satisfactoriamente.... !'
      });
    }
  }

  async documentoConciliado(strNumDoc, strCodBanco, strTipoDoc) {
    let strConcilia = 0;
    let bolConcilia = false;
    try {
      const er = await this.cxptrncobroService.erTrnBanConciliaProm(strNumDoc, strCodBanco, strTipoDoc);
      if (er !== null) {
        if (er[0] !== undefined) {
          strConcilia = er[0].TRNBAN_CONCILIA;
          console.log(er[0].TRNBAN_CONCILIA);
        }
      }

      if (strConcilia === 1) {
        bolConcilia = true;
      }
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el cheque está conciliado.'
      });
    }

    console.log(bolConcilia);

    return bolConcilia;

  }

  async anularDocumento() {

    if (this.index === 0) { // generales

      // Verifica los permisos
      if (this.permisoPasiva !== null) {
        if (this.permisoPasiva.PERTODO === 0) {
          if (this.permisoPasiva.PERACTUALIZACION === 0) {
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.cxptrncobroService.usuario.identificacion
                + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
    } else {
      return;
    }


    // verificar que el cheque girado por la devolución de anticipos no haya
    // sido conciliado
    if (await this.chequeConciliado('anular')) {
      return;
    }

    // verificar que el cheque cobrado no haya sido depositado
    if (await this.chequeDepositado('anular')) {
      return;
    }

    // verificar que el efectivo no haya sido depositado
    if (await this.efectivoDepositado('anular')) {
      return;
    }

    // verificar que los depósitos no hayan sido conciliados
    if (await this.depositoConciliado('anular')) {
      return;
    }

    // verificar que el anticipo no haya sido utilizado
    if (this.anticipoUtilizado('anular')) {
      return;
    }

    this.confirmationService.confirm({
      message: 'Está seguro de anular el registro?',
      header: 'Anular',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarcxptrncobro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.anularCartera();
      },
      reject: () => {
        this.confirmationService.close();
        return;
      }
    });


  }/* Fin Anular Documento */

  async anularCartera() {

    if (this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC === '') {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Información',
        detail: 'Seleccione el registro'
      });
      return;
    }

    let strTipoDocumento = '';

    try {
      // Tipo de documento
      if (this.selecttipo.name === 'Nota de Crédito') {
        strTipoDocumento = 'NC';
      }
      if (this.selecttipo.name === 'Anticipo') {
        strTipoDocumento = 'ANP';
      }
      if (this.selecttipo.name === 'Dev. de Anticipos') {
        strTipoDocumento = 'DANP';
      }

      await this.cxptrncobroService.anularTrnCobroCXP(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
      this.auditoriagral.registrarAuditoria('CXP_TRNCOBRO',
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC + '/' +
        this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, 'AN',
        '', '01', '', '', '', '').subscribe();
      this.cxptrncobroService.trncobroarray[this.indicador].ANULADO = 'ANULADO';

      if (strTipoDocumento === 'NC') {
        this.actualizarEstadoFacturaProv(this.cxptrncobroService.trncobroarray[this.indicador].PRO_CODIGO,
          this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_REFERENCIAEXTERNA);
      }

      if (strTipoDocumento === 'DANP') {
        await this.eliminarTrnDoc(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
        this.eliminarFPDev(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC, false);
      }

      this.mostrarDatos(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);

      if (strTipoDocumento === 'ANP') {
        this.eliminarFPAnt(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC, false);
      }

    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: '! Proceso Anular Documento no terminó satisfactoriamente.... !'
      });
    }
  }

  async eliminarTrnDoc(strNroDoc) {
    let bolEliminar = false;

    try {
      await this.cxptrncobroService.ELIMINARTRNDOCUMENTO(strNroDoc);
      bolEliminar = true;
    } catch (error) {
      this.messageService.add({
        key: 'cxptrncobro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede eliminar los documentos'
      });
    }
    return bolEliminar;
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  verAsiento() {
    
    //CDPJ
    if (this.selecttipo.name === 'Anticipo') {
      if (this.selectasiento.codigo !== '' && this.selectasiento.codigo !== null) {
        this.asiento = this.selectasiento.codigo;
        this.displayAsiContable = true;
      }
    }else{
      this.asiento = this.cxptrncobroService.trncobroarray[this.indicador].ASI_NRO;
    if (this.asiento === null || this.asiento === undefined || this.asiento === '') {
      this.messageService.add({
        key: 'encfac',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.displayAsiContable = true;
    }
    }
    //CDPJ
  }

  datosAnexos() {
    this.datosanexos.cargarDatos();
    this.displayDialogDatosAnexos = true;
    this.closeSidebar();
  }

  activarAnulado() {
    if (this.cxptrncobroService.trncobroarray[this.indicador].ANULADO === 'ANULADO') {


      /*
        if (!Utilidades
          .verificarPeriodoContable(dateTimeFechaEmision
              .getDay(), dateTimeFechaEmision.getMonth(),
            dateTimeFechaEmision.getYear())) {
          return;
        }
      */
      this.claveAuth = '';
      this.autorizacionCodigo = 'ACTDOC';
      this.displayDialogAutorizacion = true;
    }

  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }

  }

  verificarDatosAnexos() {
    if (this.datosanexos.datosFP.ENCFACPRO_PAGOLOCALEXT === '') {
      this.displayDialogDatosAnexos = true;
      this.messageService.add({
        key: 'datane',
        severity: 'warn',
        summary: 'Error',
        detail: 'Ingrese código del pago Local o al Exterior'
      });
    } else if (Number(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_IMPORTE) > 1000
      && this.datosanexos.datosanexService.formapago.length === 0) {
      this.displayDialogDatosAnexos = true;
      this.messageService.add({
        key: 'datane',
        severity: 'warn',
        summary: 'Error',
        detail: 'Ingrese la forma de pago. La factura es mayor a 1000.'
      });
    } else {
      if (this.datosanexos.botonGuardar === false) {
        this.confirmationService.confirm({
          message: 'Existen cambios, desea guardarlos?',
          header: 'Salir',
          icon: 'pi pi-exclamation-triangle',
          key: 'dataneconf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            this.datosanexos.guardarRegistro();
            this.displayDialogDatosAnexos = false;
            this.messageService.add({
              key: 'cxptrncobro',
              severity: 'success',
              summary: 'Información',
              detail: 'Datos guardados correctamente.'
            });
          }
        });
      }
    }
  }

  busquedaidcretri(parametro, opcionbusqueda) {
    const strFechaEmision = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    let strSentencia = '';
    strSentencia = strSentencia + ' to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')>= codsri_fechaini';
    strSentencia = strSentencia + ' and to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')<= codsri_fechafin';

    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripcion', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref.', value: 'CODSRI_REF' },
      { label: 'Codigo Con.', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'ANEX_MAECODSRI';
    this.where = 'CODSRI_TIPO=\'CT\' AND ' + strSentencia;

    this.cxptrncobroService.erCodSriDescripcion2(strFechaEmision, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.cxptrncobroService.trndatosanexarray[this.indicador].DESCIDCRETRI = eR[0].CODSRI_DESCRIPCION;
          document.getElementById('ENCFACPRO_TIPCOM').focus();
        } else {
          this.busqService.busquedaAnexMaeCodSri2(strSentencia, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri2(strSentencia, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedatipcom(parametro) {
    const strFechaEmision = this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    let strSentencia = '';
    strSentencia = strSentencia + ' to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')>= codsri_fechaini';
    strSentencia = strSentencia + ' and to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')<= codsri_fechafin';

    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = 'tipcom';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripcion', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref.', value: 'CODSRI_REF' },
      { label: 'Codigo Con.', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'ANEX_MAECODSRI';
    this.where = 'CODSRI_TIPO=\'TC\' AND ' + strSentencia + '';

    this.cxptrncobroService.erCodSriDescripcion3(strFechaEmision, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.cxptrncobroService.trndatosanexarray[this.indicador].DESCTIPOCOMP = eR[0].CODSRI_DESCRIPCION;
          document.getElementById('BOD_CODIGO').focus();
        } else {
          this.busqService.busquedaAnexMaeCodSri15(strSentencia, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri15(strSentencia, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  asignarTipoSRi(opcionbusq, parametro) {
    this.opcionbusqueda = opcionbusq;
    this.types = [
      { label: 'CÓDIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE', value: 'REF_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.consulta = '*';
    this.where = 'REF_TIPO=\'RESCOM\'';
    this.cxptrncobroService.erFindSaciMaeReferencia(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaSaciMaeReferencia5(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia5(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  async generarAsientoContable() {

    let strTipoDocumento = '';

    // Tipo de documento
    if (this.selecttipo.name === 'Factura') {
      strTipoDocumento = 'FC';
    }
    if (this.selecttipo.name === 'Nota de Crédito') {
      strTipoDocumento = 'NC';
    }
    if (this.selecttipo.name === 'Nota de Débito') {
      strTipoDocumento = 'ND';
    }
    if (this.selecttipo.name === 'Anticipo') {
      strTipoDocumento = 'ANP';
    }
    if (this.selecttipo.name === 'Dev. de Anticipos') {
      strTipoDocumento = 'DANP';
    }

     //GSRF
     let BLOQ_BOD = this.cxptrncobroService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CXPASIC').CFG_VALOR1;
     if(BLOQ_BOD === 0){
       if (strTipoDocumento === 'FC') {
         console.log('aqui--->',strTipoDocumento)
         return;
       }
     }
   //GSRF
    if ((strTipoDocumento !== 'ANP' && this.chkSaldoInicial === false)
      && strTipoDocumento !== 'DANP') {
      await this.generarAsiento();
    } else if (strTipoDocumento === 'ANP' && this.chkSaldoInicial === false) {
      let num=0
      
      if(this.cxptrncobroService.fpantarray[this.indicador].length === 1){
        for (let fila = 1; fila <= this.cxptrncobroService.fpantarray[this.indicador].length; fila++) {
          const item = this.cxptrncobroService.fpantarray[this.indicador][fila - 1];
          if (item.TIPODOC === 'CH') {
            const strfecha1= this.strintoDate(item.FECHADOC,'dd/MM/yyyy','/')
            const strfecha2= this.strintoDate(this.datePipe.transform(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy'),'dd/MM/yyyy','/')
            console.log('-*-------*',strfecha1);
            console.log('-*-------*',strfecha2)
            if(strfecha1 > strfecha2){
              num=1
            }
          }
        }
      }
      if(num === 0){
        await this.generarAsientoAnt(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
      }else{
        await this.generarAsientoAntN(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
        await this.generarAsientoAnt2(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
      }
    } else if (strTipoDocumento === 'DANP') {
      await this.generarAsientoDevAnt(this.cxptrncobroService.trncobroarray[this.indicador].TRNCOBRO_NRODOC);
    }
    await this.desplegarAsientos();
  }

  seleccionECAnt(params) {
    console.log(params);
    if (params.object !== undefined) {
      // this.selectedFpAnt = params.object;
    }
  }
}
