<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="ventickets"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="VenTickets" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="true" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="mostrartickets()"
									class="archivoOpc">Mostrar todos los tickets</span>
							</div>
							<!-- CDPJ -->
						</p-scrollPanel>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="btnNuevo"
						 [botonGuardar]="btnGuardar"
						 [botonBorrar]="btnBorrar" [botonRegresar]="false"
						 [botonFrmCorreos]="boolformscorreo" [barraCartera]="false"
						 [barraBotonesDesplaz]="true" [barraBotones3]="true"
						 [barraBotonesAsiCon]="true" [barraBotones5]="true"[botonFacturaPermanente]="true"
						 [botonBuscarPermanente]="true"
		></app-invmaebarra>
    </div>
</div>
<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()" [style]="{'width':'98%'}">
    <p-tabPanel header="General">
        <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
            <div class="contorno" id="contorno">
                <div class="divgrups">
                    <div class="p-grid">
                        <!--LINEA 1-->
                        <div class="p-col-1">
                            <span>Cliente:</span>
                        </div>
                        <div class="p-col-3">
                            <label for="lblcliente"></label>
                            <input class="frm" id="lblcliente" type="text" pInputText disabled [(ngModel)]="lblcliente"
                                style="color: blue; font-weight: bold;" autocomplete="off">
                        </div>
                        <div class="p-col-1">
                            <span>Teléfonos:</span>
                        </div>
                        <div class="p-col-2">
                            <label for="lbltelefono"></label>
                            <input class="frm" id="lbltelefono" type="text" pInputText disabled
                                [(ngModel)]="lbltelefono" style="color: blue; font-weight: bold;"
                                autocomplete="off">
                        </div>
                       
                        <!-- <div class="p-col-1">
                            <span>Ordenar:</span>
                        </div> -->
                        <!-- <div class="p-col-3">
                                <p-dropdown [options]="opcionesOrderBy" [style]="{'width':'100%'}"
                                optionLabel="name" [panelStyle]="{'width':'110%'}"
                                (onChange)="busqorderby($event)"
                                [(ngModel)]="selecttipoorderby"
                                ></p-dropdown>
                                       
                        </div> -->
                        <!-- <div class="p-col-1">
                            <p-checkbox label="Aplicar" binary="true"
                                (click)="aplicarorderby()" [(ngModel)]="chkaplicaorderby" [disabled] = "disabledorderby">
                            </p-checkbox>
                        </div> -->
                        <!--LINEA 2-->
                        <div class="p-col-12" style="margin-top: -10px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Contacto:</span>
                                </div>
                                <div class="p-col-3">
                                    <label for="lblcontacto"></label>
                                    <input class="frm" id="lblcontacto" type="text" pInputText disabled
                                        style="color: blue; font-weight: bold;"
                                        [(ngModel)]="lblcontacto" autocomplete="off">
                                </div>   
                                <div class="p-col-1">
                                    <span>Vendedor:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="lblvendedor"></label>
                                    <input class="frm" id="lblvendedor" type="text" pInputText disabled
                                        style="color: blue; font-weight: bold;"
                                        [(ngModel)]="lblvendedor" autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Correo:</span>
                                </div>
                                <div class="p-col-3">
                                    <label for="lblcorreo"></label>
                                    <input class="frm" id="lblcorreo" type="text" pInputText disabled
                                        style="color: blue; font-weight: bold;"
                                        [(ngModel)]="lblcorreo" autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <!--LINEA 3-->
                        <div class="p-col-12" style="margin-top: -10px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Dirección:</span>
                                </div>
                                <div class="p-col-3">
                                    <label for="lbldireccion"></label>
                                    <input class="frm" id="lbldireccion" type="text" pInputText disabled
                                        style="color: blue; font-weight: bold;"
                                        [(ngModel)]="lbldireccion" autocomplete="off">
                                </div>

                                <!-- <div class="p-col-1">
                                    <span>Observacion:</span>
                                </div> -->
                                <!-- <div class="p-col-5">
                                    <label for="lblobservacion"></label>
                                    <input class="frm" id="lblobservacion" type="text" pInputText disabled
                                        style="color: blue; font-weight: bold;"
                                        [(ngModel)]="lblobservacion" autocomplete="off">
                                </div> -->
                                <!-- <div class="p-col-1">
                                    <span>Saldo:</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="lblsaldo"></label>
                                    <input class="frm" id="lblsaldo" type="text" pInputText disabled
                                        style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldo"
                                        autocomplete="off">
                                </div> -->
                            </div>
                        </div>
                        <!--LINEA 4-->
                        <div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Total tiempo acumulado:</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="lbltiempoacum"></label>
                                    <input class="frm" id="lbltiempoacum" type="text" pInputText disabled
                                        style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lbltiempoacum"
                                        autocomplete="off">
                                </div>

                                <!-- <div class="p-col-1">
                                    <span>Saldo de Cartera:</span>
                                </div> -->
                                <!-- <div class="p-col-1">
                                    <label for="lblsaldo"></label>
                                    <input class="frm" id="lblsaldo" type="text" pInputText disabled
                                        style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldo"
                                        autocomplete="off">
                                </div> -->
                                <!-- <div class="p-col-1">
                                    <span>Seleccionar Todos:</span>
                                </div> -->
                                <!-- <div class="p-col-1">
                                    <label for="lblsaldo"></label>
                                    <p-checkbox value="valSelect" (onChange)="seleccionarall($event)"></p-checkbox>
                                </div> -->
                            </div>
						</div>
						<!--LINEA 5-->
                        <div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span></span>
                                </div>
                                <div class="p-col-1">
                                    <!-- <label for="lbltiempoacum"></label>
                                    <input class="frm" id="lbltiempoacum" type="text" pInputText disabled
                                        style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lbltiempoacum"
                                        autocomplete="off"> -->
                                </div>

                                <!-- <div class="p-col-1">
                                    <span>Saldo de Cartera:</span>
                                </div> -->
                                <!-- <div class="p-col-1">
                                    <label for="lblsaldo"></label>
                                    <input class="frm" id="lblsaldo" type="text" pInputText disabled
                                        style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldo"
                                        autocomplete="off">
                                </div> -->
                                <!-- <div class="p-col-1">
                                    <span>Seleccionar Todos:</span>
                                </div> -->
                                <!-- <div class="p-col-1">
                                    <label for="lblsaldo"></label>
                                    <p-checkbox value="valSelect" (onChange)="seleccionarall($event)"></p-checkbox>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-aggridsaci 
            [width]=""
            [height]="largo" 
            [enableSorting]="true" 
            [enableFilter]="true"
            [rowData]="vencontrolticketsService.ctrltickets" 
            [frameworkComponents]="frameworkComponents"
            [animateRows]="true" 
            [rowSelection]="rowSelection" 
            [columnDefs]="columDefsVenCtrlTickets"
            [defaultColDef]="defaultVenCtrlTickets" 
            [rowStyle]="rowStyle" 
            [singleClickEdit]="false" 
            [botones]="false"
            [mostrarGuardar]="false" 
            [botonesAdicionales]="false" 
            [comboOpciones]=""
			(selected)="seleccionItem($event)" 
			(cambios)="cambio($event)"
            [tipodetalle]="">
            </app-aggridsaci>
        </p-scrollPanel>
    </p-tabPanel>
  <p-tabPanel header="Estado de Cuenta" [disabled]="diasabledtabs">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span>{{lblclicodigo + ' - ' + lblcliente}}</span>
				</div>
				<div class="p-col-2">
				</div>
				 <!-- <div class="p-col-2">
					<button class="medium" style="background-image: url(../../assets/images/iconos/024-message.png)"
						(click)="enviarEstadoCuenta()">
					</button>
				</div>  -->
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen"
			[defaultColDef]="defaultColDefCxctmptrnresumen" [rowStyle]="rowStyleEC" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
    </p-tabPanel>
    
	<p-tabPanel header="Saldos" [disabled]="diasabledtabs">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span >{{lblclicodigo + ' - ' + lblcliente}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen"
			[defaultColDef]="defaultColDefCxctrnresumen" [rowStyle]="rowStyleSaldo" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
    </p-tabPanel> 
    
    <p-tabPanel header="Resumen" [disabled]="diasabledtabsR">
        <div class="contorno" id="contorno2">
            <div class="divgrups">
                <div class="p-grid">
                    <!--LINEA 1-->
                    <div class="p-col-1">
                        <span>Cliente:</span>
                    </div>
                    <div class="p-col-3">
                        <label for="lblcliente"></label>
                        <input class="frm" id="lblcliente" type="text" pInputText disabled [(ngModel)]="lblcliente"
                            style="color: blue; font-weight: bold;" autocomplete="off">
                    </div>
                    <div class="p-col-1">
                        <span>Teléfonos:</span>
                    </div>
                    <div class="p-col-2">
                        <label for="lbltelefono"></label>
                        <input class="frm" id="lbltelefono" type="text" pInputText disabled
                            [(ngModel)]="lbltelefono" style="color: blue; font-weight: bold;"
                            autocomplete="off">
                    </div>
                   
                    <!-- <div class="p-col-1">
                        <span>Ordenar:</span>
                    </div> -->
                    <!-- <div class="p-col-3">
                            <p-dropdown [options]="opcionesOrderBy" [style]="{'width':'100%'}"
                            optionLabel="name" [panelStyle]="{'width':'110%'}"
                            (onChange)="busqorderby($event)"
                            [(ngModel)]="selecttipoorderby"
                            [disabled] = "true"
                            ></p-dropdown>
                                   
                    </div> -->
                    <!-- <div class="p-col-1">
                        <p-checkbox label="Aplicar" binary="true"
                            (click)="aplicarorderby()" [(ngModel)]="chkaplicaorderby" [disabled] = "true">
                        </p-checkbox>
                    </div> -->
                    <!--LINEA 2-->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span>Contacto:</span>
                            </div>
                            <div class="p-col-3">
                                <label for="lblcontacto"></label>
                                <input class="frm" id="lblcontacto" type="text" pInputText disabled
                                    style="color: blue; font-weight: bold;"
                                    [(ngModel)]="lblcontacto" autocomplete="off">
                            </div>   
                            <div class="p-col-1">
                                <span>Vendedor:</span>
                            </div>
                            <div class="p-col-2">
                                <label for="lblvendedor"></label>
                                <input class="frm" id="lblvendedor" type="text" pInputText disabled
                                    style="color: blue; font-weight: bold;"
                                    [(ngModel)]="lblvendedor" autocomplete="off">
                            </div>
                            <div class="p-col-1">
                                <span>Correo:</span>
                            </div>
                            <div class="p-col-3">
                                <label for="lblcorreo"></label>
                                <input class="frm" id="lblcorreo" type="text" pInputText disabled
                                    style="color: blue; font-weight: bold;"
                                    [(ngModel)]="lblcorreo" autocomplete="off">
                            </div>
                        </div>
                    </div>
                     <!--LINEA 3-->
                     <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span>Dirección:</span>
                            </div>
                            <div class="p-col-3">
                                <label for="lbldireccion"></label>
                                <input class="frm" id="lbldireccion" type="text" pInputText disabled
                                    style="color: blue; font-weight: bold;"
                                    [(ngModel)]="lbldireccion" autocomplete="off">
                            </div>
    
                            <!-- <div class="p-col-1">
                                <span>Observacion:</span>
                            </div> -->
                            <!-- <div class="p-col-5">
                                <label for="lblobservacion"></label>
                                <input class="frm" id="lblobservacion" type="text" pInputText disabled
                                    style="color: blue; font-weight: bold;"
                                    [(ngModel)]="lblobservacion" autocomplete="off">
                            </div> -->
                            <!-- <div class="p-col-1">
                                <span>Saldo:</span>
                            </div>
                            <div class="p-col-1">
                                <label for="lblsaldo"></label>
                                <input class="frm" id="lblsaldo" type="text" pInputText disabled
                                    style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldo"
                                    autocomplete="off">
                            </div> -->
                        </div>
                    </div>
                    <!--LINEA 4-->
                    <div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span>Total tiempo acumulado:</span>
                            </div>
                            <div class="p-col-1">
                                <label for="lbltiempoacum"></label>
                                <input class="frm" id="lbltiempoacum" type="text" pInputText disabled
                                    style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lbltiempoacum"
                                    autocomplete="off">
                            </div>
    
                            <!-- <div class="p-col-1">
                                <span>Saldo de Cartera:</span>
                            </div> -->
                            <!-- <div class="p-col-1">
                                <label for="lblsaldo"></label>
                                <input class="frm" id="lblsaldo" type="text" pInputText disabled
                                    style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldo"
                                    autocomplete="off">
                            </div> -->
                            
                        </div>
					</div>
					<!--LINEA 5-->
					<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
						<div class="p-grid">
							<div class="p-col-1">
								<span></span>
							</div>
							<div class="p-col-1">
								<!-- <label for="lbltiempoacum"></label>
								<input class="frm" id="lbltiempoacum" type="text" pInputText disabled
									style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lbltiempoacum"
									autocomplete="off"> -->
							</div>

							<!-- <div class="p-col-1">
								<span>Saldo de Cartera:</span>
							</div> -->
							<!-- <div class="p-col-1">
								<label for="lblsaldo"></label>
								<input class="frm" id="lblsaldo" type="text" pInputText disabled
									style="text-align: right; color: blue; font-weight: bold;" [(ngModel)]="lblsaldo"
									autocomplete="off">
							</div> -->
							<!-- <div class="p-col-1">
								<span>Seleccionar Todos:</span>
							</div> -->
							<!-- <div class="p-col-1">
								<label for="lblsaldo"></label>
								<p-checkbox value="valSelect" (onChange)="seleccionarall($event)"></p-checkbox>
							</div> -->
						</div>
					</div>
                </div>
            </div>

        </div>
        <app-aggridsaci 
        [width]="" 
        [height]="largoestcta"
        [enableSorting]="true" 
        [enableFilter]="true"
        [rowData]="vencontrolticketsService.ctrlticketsresumen"
        [frameworkComponents]="frameworkComponents" 
        [animateRows]="true"
        [rowSelection]="rowSelection" 
        [columnDefs]="columnDefsVenCtrlTicketsresumen"
        [defaultColDef]="defaultColDefVenCtrlTicketsResumen" 
        [rowStyle]="rowStyle" 
        [botones]="false"
        [singleClickEdit]="false"
        (selected)="seleccionItemCob($event)" ></app-aggridsaci>
	</p-tabPanel> 
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
						[where]="condicion"
						[busquedaParams]="busquedacampos"
						(opcionSeleccionada)="manejarSeleccion($event)"
						[busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>
<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBus" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>