import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { Usuario } from '../../usuario';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { DatePipe } from '@angular/common';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { BantrntarjetaService } from '../banservicios/bantrntarjeta.service';
import {
  BanDetModeloConciliacion,
  BanDetModeloRetencion,
  BanDetModeloVarios,
  BanDetTrnTarjeta,
  BanEncTrnTarjeta
} from '../baninterfaces/bantrntarjeta';
import { AggridBanComponent } from '../aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Component({
  selector: 'app-bantrntarjeta',
  templateUrl: './bantrntarjeta.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BantrntarjetaComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  index: number;//GSRF
  tarjdis: boolean;//GSRF
  editarCeldas: Boolean//GSRF
  btnNuevoGridRet: boolean;
  btnBorrarGridRet: boolean;
  btnCancelarGridRet: boolean;
  btnNuevoGridVar: boolean;
  btnBorrarGridVar: boolean;
  btnCancelarGridVar: boolean;

  public frameworkComponents;
  rowStyle;
  defaultColBanTarjeta;
  defaultColBanTarRetencion;
  defaultColBanTarVarios;
  defaultColBanTarConcil;
  rowSelection = 'multiple';
  largo: string;

  // Busqueda

  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  opcion: string;

  selectTab = 0;
  permisos: SegMaePermiso;

  encDepositoTarjeta: BanEncTrnTarjeta;
  detDepositoTarjeta: BanDetTrnTarjeta[];
  // detModeloRetencion: BanDetModeloRetencion[];
  // detModeloVarios: BanDetModeloVarios[];
  detalleRetencionSel: BanDetModeloRetencion;
  detalleVariosSel: BanDetModeloVarios;
  detModeloConciliacion: BanDetModeloConciliacion[];

  strControlActivaOpciones = '';
  auxiliar = '0';
  CHKAsientoMultiple: boolean;
  chkseleccionarTodo: boolean;
  lblDepConciliacion = '';
  txtConceptoDeposito = '';
  txtImporte = '';
  txtDepPend = '';
  txtObservaciones = '';
  txtNumeroComprobante = '';
  txtValorCobrado = '';
  txtCodTarjeta = '';
  txtBase = '';
  txtComision = '';
  txtPorcentaje = '';
  txtCodBanco = '';
  txtComprobante = '';
  lblOrigen = 'TAR';
  lblOrigen2 = 'TAR';
  lblNombreTarjeta = '';
  lblTotalDebitos = '';
  lblCreditos = '';
  lblNombreBanco = '';
  lblAsiento = '';
  dateTimFechaEmision: any;
  dateTimeFechaDeposito: any;
  intItemSelec = 0;
  lblTotalImporte = '';
  txtTrnTarjetaCodigo = '';
  // lblTotalRetenciones = '';
  lblTotalVarios = '';
  lblAsientoRet = '';
  strControlAbrirDoc = '0';

  dblTotalRet = 0;

  editTxtComprobante: boolean;

  indicador: any;

  public hmpConfiguracion: any[];

  claveDialog: boolean;
  claveAuth: string;
  intNumReg = 0;
  accionClave: string;

  displayAsiContable: boolean;
  NumAsiRegCont: string;

  columnDefsBanTarjeta = [
    {
      headerName: '', field: 'SELECCION', cellRendererFramework: AggridBanComponent, editable: false, width: 50, onCellClicked: (params) => {
        this.seleccionItemTarjeta(params.data);
      }
    },
    {
      headerName: 'Fecha', field: 'TRNCAJ_FECHA', editable: false, width: 125, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Nro.Documento', field: 'TRNCAJ_NUMERO', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Cliente', field: 'CLI_NOMBRE', editable: false, width: 200, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Importe', field: 'TRNTARJETADET_IMPORTE', editable: false, width: 150, cellStyle: { textAlign: 'right' },
    },
  ];

  columnDefsBanTarRetencion = [
    {
      headerName: 'Código', field: 'RETENCION_CODIGO', cellEditor: 'cellPrueba', editable: true, width: 120, cellStyle: { textAlign: 'left' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'TRNRETENCION_DESCRIPCION', cellEditor: 'cellPrueba', editable: true, width: 250, cellStyle: { textAlign: 'left' },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 200,
          tienecalculos: true

        }
      }
    },
    {
      headerName: 'Serie', field: 'TRNRETENCION_SERIE', cellEditor: 'cellPrueba', editable: true, width: 120, cellStyle: { textAlign: 'left' },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 6,
          int: true,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Número', field: 'TRNRETENCION_NRO', cellEditor: 'cellPrueba', editable: true, width: 120, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 9,
          int: true,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Base', field: 'TRNRETENCION_BASE', cellEditor: 'cellPrueba', editable: true, width: 130, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          int: true,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: '%', field: 'TRNRETENCION_PORCENTAJE', editable: false, width: 130, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'T.Retenido', field: 'TRNRETENCION_TOTALRETENIDO', editable: false, width: 130, cellStyle: { textAlign: 'left' },
    },
  ];

  columnDefsBanTarVarios = [
    {
      headerName: 'Ref.Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', editable: true, width: 120, cellStyle: { textAlign: 'left' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'TRNTARVARIOS_DESCRIPCION', cellEditor: 'cellPrueba', editable: true, width: 250, cellStyle: { textAlign: 'left' },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: true

        }
      }
    },
    {
      headerName: 'Debe', field: 'DEBE', cellEditor: 'cellPrueba', editable: true, width: 120, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          int: true,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Haber', field: 'HABER', cellEditor: 'cellPrueba', editable: true, width: 120, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          int: true,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Cnt.Costo', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30,
          opcionbuscar: true
        }
      }
    },
  ];

  columnDefsBanTarConcil = [
    {
      headerName: '', field: 'CONCIL_SELECT', cellRendererFramework: AggridBanComponent, editable: false, width: 50, onCellClicked: (params) => {
        this.seleccionItemConcil(params.data);
      }
    },
    {
      headerName: 'Fecha', field: 'TRNTARJETA_FECHATRN', editable: false, width: 125, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Comprobante', field: 'TRNTARJETA_NRORECAP', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Val.Cobrado', field: 'TRNTARJETA_IMPCOBRADO', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Comisión', field: 'TRNTARJETA_COMISION', editable: false, width: 150, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Total Ret.', field: 'TRNTARJETA_RETENCION', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Total Importe', field: 'TRNTARJETA_TOTIMPORTE', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Vouchers', field: 'TRNTARJETA_VOUCHERS', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
  ];



  constructor(public trnTarjetaService: BantrntarjetaService, private init: NuevoComponentService,
    public usuario: Usuario, private permisosService: PermisosService,
    private message: MessageService, private errorService: ErroresBaseDatosService,
    private datePipe: DatePipe, private busquedaSer: InvbusquedaService,
    private auditoriagral: AuditoriagralService, private confirmationService: ConfirmationService,
    private utilitariosService: UtilitariosService,
    private confIniciales: ConfInicialesService) {
    this.agTable();
    this.encDepositoTarjeta = {};
    this.detDepositoTarjeta = [];
    this.trnTarjetaService.detModeloRetencion[this.indicador] = [];
    this.trnTarjetaService.detModeloVarios[this.indicador] = [];
    this.detModeloConciliacion = [];
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColBanTarjeta = {
      editable: true,
      objeto: 'bantarjeta',
      resizable: true
    };

    this.defaultColBanTarRetencion = {
      editable: true,
      objeto: 'bantarRetencion',
      resizable: true
    };
    this.defaultColBanTarVarios = {
      editable: true,
      objeto: 'bantarVarios',
      resizable: true
    };
    this.defaultColBanTarConcil = {
      editable: true,
      objeto: 'bantarConcil',
      resizable: true
    };
    //
    // this.defaultColComEgreso = {
    //   editable: true,
    //   objeto: 'bandejacomegreso',
    //   resizable: true
    // };

  }

  async ngOnInit() {
    this.index=0;//GSRF
    this.tarjdis=false;//GSRF
    this.editarCeldas=false //GSRF
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.trnTarjetaService.detModeloRetencion[this.indicador] = [];
    this.trnTarjetaService.detModeloVarios[this.indicador] = [];

    this.largo = '600';
    this.btnBuscar = false;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.botonAnterior = false;
    this.botonPrimero = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;

    this.btnNuevoGridRet = false;
    this.btnCancelarGridRet = false;
    this.btnBorrarGridRet = false;
    this.btnNuevoGridVar = false;
    this.btnCancelarGridVar = false;
    this.btnBorrarGridVar = false;

    this.permisosService.getListaPermisos('BAN', '8', '1113').subscribe((permiso) => {
      this.permisos = permiso[0];
    });

    this.encDepositoTarjeta = {
      TRNTARJETA_CODIGO: '',
      TRNTARJETA_NRORECAP: '',
      TRNTARJETA_FECHATRN: '',
      TRNTARJETA_IMPCOBRADO: '',
      TRNTARJETA_IMCOMISION: '',
      TRNTARJETA_IMPRETFUENTE: '',
      TRNTARJETA_IMPRETIVA: '',
      BAN_CODIGO: '',
      TAR_CODIGO: '',
      COM_CODIGO: '01',
      TRNTARJETA_ORIGEN: '',
      TRNTARJETA_OBSERVACION: '',
      TRNTARJETA_PORCTAR: '',
      TRNTARJETA_COMISION: '',
      ASI_NRO: '',
      TRNTARJETA_TOTIMPORTE: '',
      TRNTARJETA_VOUCHERS: '',
      TRNBAN_TIPO: '',
      TRNBAN_NUMERO: '',
      TRNTARJETA_RETENCION: '',
      TRNTARJETA_BASECOMISION: '',
      TRNTARJETA_ASIMUL: '',
      ASI_NRO_RET: '',
      TAR_DESCRIPCION: ''
    };

    const rs = await this.trnTarjetaService.obtenerConfiguracion();
    this.hmpConfiguracion = rs;

    this.claveDialog = false;
    this.claveAuth = '';
    this.accionClave = '';
  }
//CDPJ
ngOnDestroy(): void {
  this.confIniciales.instanciasDeposiTarjCredi--;
}
//CDPJ
  async manejarSenales(valor) {
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      if (this.selectTab === 0) {
        this.buscarRegistro();
      }
      if (this.selectTab === 1) {
        this.buscarDeposito();
      }
    }
    if (valor === 'Nuevo') {
      this.editarCeldas=false //GSRF
      this.tarjdis=false;//GSRF
      if (this.selectTab === 0) { // conciliación
        this.nuevoRegistro();
      }
      if (this.selectTab === 1) { // Deposito
        this.nuevoDeposito();
      }
    }
    if (valor === 'Guardar') {
      if (this.selectTab === 0) { // conciliación
        this.guardarRegistro();
      }
      if (this.selectTab === 1) {// Deposito
        this.guardarDeposito();
      }
    }
    if (valor === 'Borrar') {
      if (this.selectTab === 0) { // conciliación'
        this.eliminarRegistro();
        if (this.txtCodBanco !== '') {
          this.eliminarDeposito();
        }

      }

      if (this.selectTab === 1) { // Depósito

        await this.eliminarDeposito();
        this.lblDepConciliacion = '';
      }
    }
    if (valor === 'Cancelar') {
      this.cancelarRegistro();
    }

    if (valor === 'Asiento') {
      if (this.selectTab === 0) { // conciliación
        if (this.CHKAsientoMultiple === true) {
          this.generarAsientoRetExi();
        }
      }
      if (this.selectTab === 1) {
        this.generarAsientoContable();
      }

    }

    if (valor === 'AsientoVer') {
      if (await this.verificarVisulizacionAsiento() === false) {
        return;
      }

      if (this.selectTab === 0) { // conciliación
        if (this.lblAsientoRet !== '') {
          this.NumAsiRegCont = this.lblAsientoRet;
          this.displayAsiContable = true;
        } else {
          this.mensajeAlerta('info', 'Información', 'No ha generado el Asiento Contable');
        }

      }

      if (this.selectTab === 1) { // Deposito
        if (this.lblAsiento !== '') {
          this.NumAsiRegCont = this.lblAsiento;
          this.displayAsiContable = true;

        } else {
          this.mensajeAlerta('info', 'Información', 'No ha generado el Asiento Contable');
        }
      }

    }

    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }

  }

  async seleccionarRegistro(strTipo) {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    let trnTarjetaCodigo = 0;
    try {
      const rs = await this.trnTarjetaService.navegarRegistro(strTipo, this.txtTrnTarjetaCodigo);
      if (rs.length > 0) {
        trnTarjetaCodigo = rs[0].TRNTARJETA_CODIGO === 0 ? 0 : rs[0].TRNTARJETA_CODIGO;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Error al seleccionar registro.... ! ' + mensaje);
    }

    if (trnTarjetaCodigo !== 0) {
      this.mostrarDatos(trnTarjetaCodigo);
    }
  }

  async mostrarDatos(trnTarjetaCodigo) {
    this.auxiliar = '1';
    let value = null;
    let strDeposito = '';

    let strCodigoBancoDep = '';
    let strTipoTransaccionBan = '';
    let strNumeroTransaccionBan = '';
    let strTipoAsiRet = '';

    this.limpiarDatos();
    this.detDepositoTarjeta = [];
    this.trnTarjetaService.detModeloRetencion[this.indicador] = [];
    this.trnTarjetaService.detModeloVarios[this.indicador] = [];
    this.detModeloConciliacion = [];
    this.tarjdis=true;//GSRF

    try {
      const rs = await this.trnTarjetaService.obtenerDatosEnc(trnTarjetaCodigo);

      if (rs.length > 0) {
        for (const dato of rs) {
          // tabDatos.setEnabled(true);
          this.txtTrnTarjetaCodigo = dato.TRNTARJETA_CODIGO === null ? '' : dato.TRNTARJETA_CODIGO;
          this.txtCodTarjeta = dato.TAR_CODIGO === null ? '' : dato.TAR_CODIGO;
          this.lblNombreTarjeta = dato.TAR_DESCRIPCION === null ? '' : dato.TAR_DESCRIPCION;
          this.txtNumeroComprobante = dato.TRNTARJETA_NRORECAP === null ? '' : dato.TRNTARJETA_NRORECAP;

          value = dato.TRNTARJETA_IMPCOBRADO === 0 ? 0 : dato.TRNTARJETA_IMPCOBRADO;
          this.txtValorCobrado = Number(value).toFixed(2);

          value = dato.TRNTARJETA_BASECOMISION === 0 ? 0 : dato.TRNTARJETA_BASECOMISION;
          this.txtBase = Number(value).toFixed(2);

          value = dato.TRNTARJETA_PORCTAR === 0 ? 0 : dato.TRNTARJETA_PORCTAR;
          this.txtPorcentaje = Number(value).toFixed(2);


          value = dato.TRNTARJETA_COMISION === 0 ? 0 : dato.TRNTARJETA_COMISION;
          this.txtComision = Number(value).toFixed(2);

          this.txtObservaciones = dato.TRNTARJETA_OBSERVACION === null ? '' : dato.TRNTARJETA_OBSERVACION;
          this.dateTimFechaEmision = dato.TRNTARJETA_FECHATRN === null ? '' : new Date(dato.TRNTARJETA_FECHATRN);

          strTipoAsiRet = dato.TRNTARJETA_ASIMUL === null ? '' : dato.TRNTARJETA_ASIMUL;

          this.lblAsientoRet = dato.ASI_NRO_RET === null ? '' : dato.ASI_NRO_RET;

          if (strTipoAsiRet === 'S') {
            this.CHKAsientoMultiple = true;
          } else {
            this.CHKAsientoMultiple = false;
          }
          // depósito
          strDeposito = '';
          strCodigoBancoDep = '';
          strTipoTransaccionBan = '';
          strNumeroTransaccionBan = '';

          if (dato.BAN_CODIGO !== null) {
            strCodigoBancoDep = dato.BAN_CODIGO;
          }

          if (dato.TRNBAN_TIPO !== null) {
            strTipoTransaccionBan = dato.TRNBAN_TIPO;
          }

          if (dato.TRNBAN_NUMERO !== null) {
            strNumeroTransaccionBan = dato.TRNBAN_NUMERO;
          }

          if (dato.BAN_CODIGO !== null) {
            strDeposito = 'Banco:' + dato.BAN_CODIGO;
            if (dato.TRNBAN_NUMERO !== null) {
              strDeposito = strDeposito + ' Número:'
                + dato.TRNBAN_NUMERO;
            }

            this.lblDepConciliacion = strDeposito;
          }

          await this.mostrarDocumentos();
          await this.mostrarDatosRetencion(String(trnTarjetaCodigo));
          await this.mostrarVarios(trnTarjetaCodigo);
          this.calcularImporteTotal();

          const dia = this.dateTimFechaEmision.getDate();
          const mes = this.dateTimFechaEmision.getMonth();
          const anio = this.dateTimFechaEmision.getFullYear();

          if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
            this.strControlActivaOpciones = 'NoModificar';
          }

          if (strDeposito !== '') {
            this.strControlActivaOpciones = 'NoModificar';
          }

          if (this.strControlActivaOpciones === 'NoModificar') {
            this.activaBotones('NoModificar', false, true, true, false,
              false, false, false, false, false, false, false);
          } else {
            this.activaBotones('NAV', false, true, true, false, false, false,
              false, false, false, false, false);
          }
          await this.mostrarDatosDeposito(strCodigoBancoDep, strTipoTransaccionBan, strNumeroTransaccionBan);
        }
      }

      if (this.lblDepConciliacion === '') {
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      } else {
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      }


    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso desplegar datos no terminó satisfactoriamente.... ! ' + mensaje);
    }
    this.noeliminar();//GSRF
  }

  async mostrarDatosDeposito(strBanCodigo, strTipo, strNumero) {
    let value = null;
    this.limpiarDatosDeposito();
    this.detModeloConciliacion = [];

    try {
      const rs = await this.trnTarjetaService.obtenerEncConciliacion(strBanCodigo, strTipo, strNumero);

      if (rs.length > 0) {
        for (const dato of rs) {

          // tabDeposito.setEnabled(true);
          this.editTxtComprobante = true;
          this.txtCodBanco = dato.BAN_CODIGO === null ? '' : dato.BAN_CODIGO;
          this.lblNombreBanco = dato.BAN_BANCO === null ? '' : dato.BAN_BANCO;
          this.txtComprobante = dato.TRNBAN_NUMERO === null ? '' : dato.TRNBAN_NUMERO;
          this.txtConceptoDeposito = dato.TRNBAN_CONCEPTO === null ? '' : dato.TRNBAN_CONCEPTO;
          this.lblAsiento = dato.ASI_NRO === null ? '' : dato.ASI_NRO;
          value = dato.TRNBAN_IMPORTE === 0 ? 0 : dato.TRNBAN_IMPORTE;
          this.txtValorCobrado = Number(value).toFixed(2);

          value = dato.TRNBAN_IMPORTE_NO_DP === 0 ? 0 : dato.TRNBAN_IMPORTE_NO_DP;
          this.txtDepPend = Number(value).toFixed(2);

          this.dateTimeFechaDeposito = new Date(dato.TRNBAN_FECHA);
          this.dateTimFechaEmision=new Date(dato.TRNBAN_FECHA)//CDPJ

          await this.mostrarDocumentosConcil(strBanCodigo, strNumero, strTipo);
          this.calcularImporteTotalConcil();

          const dia = this.dateTimFechaEmision.getDate();
          const mes = this.dateTimFechaEmision.getMonth();
          const anio = this.dateTimFechaEmision.getFullYear();

          if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
            this.strControlActivaOpciones = 'NoModificar';
          }

          if (this.strControlActivaOpciones === 'NoModificar') {
            this.activaBotones('NoModificar', false, true, true, false,
              false, false, false, false, false, false, false);
          } else {
            this.activaBotones('NAV', false, true, true, false, false, false,
              false, false, false, false, false);
          }

          if (this.selectTab === 1) {
            this.activaBotones('NAV', false, true, true, false, false, false,
              false, false, false, false, false);
          }

          const concilia = await this.trnTarjetaService.encontrarTrnTarjetaCodigo(this.txtComprobante);


          if (this.auxiliar === '0') {
            if (concilia !== null) {
              await this.mostrarDatosConciliacion(Number(concilia[0].TRNTARJETA_CODIGO));
            } else {
              await this.mostrarDatosConciliacion(0);
            }
          }
        }
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso desplegar datos del depósito no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  limpiarDatosDeposito() {

    this.dateTimeFechaDeposito = new Date();
    this.txtCodBanco = '';
    this.lblNombreBanco = '';
    this.txtComprobante = '';
    this.txtConceptoDeposito = '';
    this.lblAsiento = '';

    this.txtImporte = Number(0).toFixed(2);
    this.txtDepPend = Number(0).toFixed(2);

  }


  async mostrarDatosConciliacion(trnTarjetaCodigo) {
    let value = null;
    let strDeposito = '';

    let strTipoAsiRet = '';

    this.limpiarDatos();
    this.detDepositoTarjeta = [];
    this.trnTarjetaService.detModeloRetencion[this.indicador] = [];
    this.trnTarjetaService.detModeloVarios[this.indicador] = [];

    try {
      const rs = await this.trnTarjetaService.obtenerDatosEncConcil(trnTarjetaCodigo);
      if (rs.length > 0) {
        for (const dato of rs) {
          // tabDatos.setEnabled(true);
          this.txtTrnTarjetaCodigo = dato.TRNTARJETA_CODIGO === null ? '' : dato.TRNTARJETA_CODIGO;
          this.txtCodTarjeta = dato.TAR_CODIGO === null ? '' : dato.TAR_CODIGO;
          this.lblNombreTarjeta = dato.TAR_DESCRIPCION === null ? '' : dato.TAR_DESCRIPCION;
          this.txtNumeroComprobante = dato.TRNTARJETA_NRORECAP === null ? '' : dato.TRNTARJETA_NRORECAP;

          value = dato.TRNTARJETA_IMPCOBRADO === 0 ? 0 : dato.TRNTARJETA_IMPCOBRADO;
          this.txtValorCobrado = Number(value).toFixed(2);

          value = dato.TRNTARJETA_BASECOMISION === 0 ? 0 : dato.TRNTARJETA_BASECOMISION;
          this.txtBase = Number(value).toFixed(2);

          value = dato.TRNTARJETA_PORCTAR === 0 ? 0 : dato.TRNTARJETA_PORCTAR;
          this.txtPorcentaje = Number(value).toFixed(2);


          value = dato.TRNTARJETA_COMISION === 0 ? 0 : dato.TRNTARJETA_COMISION;
          this.txtComision = Number(value).toFixed(2);

          this.txtObservaciones = dato.TRNTARJETA_OBSERVACION === null ? '' : dato.TRNTARJETA_OBSERVACION;
          this.dateTimFechaEmision = dato.TRNTARJETA_FECHATRN === null ? '' : new Date(dato.TRNTARJETA_FECHATRN);

          strTipoAsiRet = dato.TRNTARJETA_ASIMUL === null ? '' : dato.TRNTARJETA_ASIMUL;

          this.lblAsientoRet = dato.ASI_NRO_RET === null ? '' : dato.ASI_NRO_RET;

          if (strTipoAsiRet === 'S') {
            this.CHKAsientoMultiple = true;
          } else {
            this.CHKAsientoMultiple = false;
          }
          // depósito
          strDeposito = '';

          if (dato.BAN_CODIGO !== null) {
            strDeposito = 'Banco:' + dato.BAN_CODIGO;
            if (dato.TRNBAN_NUMERO !== null) {
              strDeposito = strDeposito + ' Número:'
                + dato.TRNBAN_NUMERO;
            }

            this.lblDepConciliacion = strDeposito;
          }

          await this.mostrarDocumentos();
          await this.mostrarDatosRetencion(String(trnTarjetaCodigo));
          await this.mostrarVarios(trnTarjetaCodigo);
          this.calcularImporteTotal();
        }

        if (this.lblDepConciliacion === '') {
          // groupCabecera.setEnabled(true);
        } else {
          // groupCabecera.setEnabled(false);
        }

      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso desplegar datos no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  calcularImporteTotalConcil() {
    let intIndiceLista = 0;
    let item: BanDetModeloConciliacion = {};
    let dblTotalImporte = 0;
    // Total de los documentos seleccionados
    for (intIndiceLista = 0; intIndiceLista < this.detModeloConciliacion.length; intIndiceLista++) {
      item = this.detModeloConciliacion[intIndiceLista];
      if (item.CONCIL_SELECT === true) {
        dblTotalImporte = dblTotalImporte + Number(item.TRNTARJETA_IMPCOBRADO);
      }

    }

    const value = dblTotalImporte;
    this.txtImporte = Number(value).toFixed(2);

    return dblTotalImporte;

  }

  async mostrarDocumentosConcil(strBanCodigo, strNumero, strTipo) {
    let dblImporte = 0;
    let intIndiceLista = 0;

    try {
      const detalle: BanDetModeloConciliacion[] = [];
      const rs = await this.trnTarjetaService.obtenerDetConciliacion(strBanCodigo, strTipo, strNumero);
      if (rs.length > 0) {
        for (const dato of rs) {
          const item: BanDetModeloConciliacion = {};
          dblImporte = 0;
          dblImporte = dato.TRNTARJETA_COMISION === 0 ? 0 : dato.TRNTARJETA_COMISION;
          item.TRNTARJETA_COMISION = Number(dblImporte).toFixed(2);

          item.TRNTARJETA_NRORECAP = dato.TRNTARJETA_NRORECAP === null ? '' : dato.TRNTARJETA_NRORECAP;
          item.TRNTARJETA_FECHATRN = dato.TRNTARJETA_FECHATRN === null ? '' : dato.TRNTARJETA_FECHATRN;
          if (item.TRNTARJETA_FECHATRN.toString().includes('-') && item.TRNTARJETA_FECHATRN !== '') {
            item.TRNTARJETA_FECHATRN = this.datePipe.transform(item.TRNTARJETA_FECHATRN, 'dd/MM/yyyy');
          }
          item.CONCIL_SELECT = false;

          dblImporte = 0;
          dblImporte = dato.TRNTARJETA_TOTIMPORTE === 0 ? 0 : dato.TRNTARJETA_TOTIMPORTE;
          item.TRNTARJETA_TOTIMPORTE = Number(dblImporte).toFixed(2);

          dblImporte = 0;
          dblImporte = dato.TRNTARJETA_RETENCION === 0 ? 0 : dato.TRNTARJETA_RETENCION;
          item.TRNTARJETA_RETENCION = Number(dblImporte).toFixed(2);

          item.TRNTARJETA_CODIGO = dato.TRNTARJETA_CODIGO === null ? '' : dato.TRNTARJETA_CODIGO;

          dblImporte = 0;
          dblImporte = dato.TRNTARJETA_IMPCOBRADO === 0 ? 0 : dato.TRNTARJETA_IMPCOBRADO;
          item.TRNTARJETA_IMPCOBRADO = Number(dblImporte).toFixed(2);

          item.TRNTARJETA_VOUCHERS = dato.TRNTARJETA_VOUCHERS === null ? '' : dato.TRNTARJETA_VOUCHERS;
          item.CONCIL_SELECT = true;
          // this.detModeloConciliacion.push(item);
          detalle.push(item);
          intIndiceLista = intIndiceLista + 1;
        }

        this.detModeloConciliacion = detalle;
        // this.aggrid.refreshColumnDefs();
        // this.aggrid.refreshaggrid(this.detModeloConciliacion, this.defaultColBanTarConcil.objeto);
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso mostrar Conciliaciones no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async mostrarDocumentos() {
    let dblImporte = 0;
    let intIndiceLista = 0;

    try {
      const detalle: BanDetTrnTarjeta[] = [];
      const rs = await this.trnTarjetaService.obtenerTRNTARJETADET(this.txtTrnTarjetaCodigo);

      if (rs.length > 0) {
        for (const dato of rs) {
          const item: BanDetTrnTarjeta = {};

          dblImporte = dato.TRNTARJETADET_IMPORTE === 0 ? 0 : dato.TRNTARJETADET_IMPORTE;
          item.TRNTARJETADET_IMPORTE = Number(dblImporte).toFixed(2);
          item.COM_CODIGO = dato.COM_CODIGO === null ? '' : dato.COM_CODIGO;
          item.CAJ_CODIGO = dato.CAJ_CODIGO === null ? '' : dato.CAJ_CODIGO;
          item.CLI_CODIGO = dato.CLI_CODIGO === null ? '' : dato.CLI_CODIGO;
          item.CLI_NOMBRE = dato.CLI_NOMBRE === null ? '' : dato.CLI_NOMBRE;
          item.SELECCION = true;
          item.TRNCAJ_DOCNRO = dato.TRNCAJ_DOCNRO === null ? '' : dato.TRNCAJ_DOCNRO;
          item.TRNCAJ_FECHA = dato.TRNCAJ_FECHA === null ? '' : dato.TRNCAJ_FECHA;
          if (item.TRNCAJ_FECHA.toString().includes('-') && item.TRNCAJ_FECHA !== '') {
            item.TRNCAJ_FECHA = this.datePipe.transform(item.TRNCAJ_FECHA, 'dd/MM/yyyy');
          }
          item.TRNCAJ_NUMERO = dato.TRNCAJ_NUMERO === null ? '' : dato.TRNCAJ_NUMERO;
          item.TRNCAJ_TIPO = dato.TRNCAJ_TIPO === null ? '' : dato.TRNCAJ_TIPO;
          item.TRNTARJETA_CODIGO = dato.TRNTARJETA_CODIGO;
          item.TRNTARJETADET_NROSEC = dato.TRNTARJETADET_NROSEC;

          // this.detDepositoTarjeta.push(item);
          detalle.push(item);
          intIndiceLista = intIndiceLista + 1;
        }
        this.detDepositoTarjeta = detalle;
        this.totalRetencion();
        //  this.aggrid.refreshColumnDefs();
        //   this.aggrid.refreshaggrid(this.detDepositoTarjeta, this.defaultColBanTarjeta.objeto);
      } else {
        this.trnTarjetaService.detModeloRetencion[this.indicador] = [];
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso mostrar vouchers no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async mostrarDatosRetencion(trnTarjetaCodigo) {
    try {
      const detalle: BanDetModeloRetencion[] = [];
      const rs = await this.trnTarjetaService.obtenerDetalleRetencion(trnTarjetaCodigo);

      if (rs.length > 0) {
        let numero = 0;
        for (const dato of rs) {

          const item: BanDetModeloRetencion = {};

          item.RETENCION_CODIGO = dato.RETENCION_CODIGO === null ? '' : dato.RETENCION_CODIGO;
          item.TRNRETENCION_DESCRIPCION = dato.TRNRETENCION_DESCRIPCION === null ? '' : dato.TRNRETENCION_DESCRIPCION;
          item.TRNRETENCION_NRO = dato.TRNRETENCION_NRO === null ? '' : dato.TRNRETENCION_NRO;
          item.TRNRETENCION_BASE = dato.TRNRETENCION_BASE === 0 ? Number(0).toFixed(2) :
            Number(dato.TRNRETENCION_BASE).toFixed(2);
          item.TRNRETENCION_PORCENTAJE = dato.TRNRETENCION_PORCENTAJE === 0 ? Number(0).toFixed(2) :
            Number(dato.TRNRETENCION_PORCENTAJE).toFixed(2);
          item.TRNRETENCION_TOTALRETENIDO = dato.TRNRETENCION_TOTALRETENIDO === 0 ? Number(0).toFixed(2) :
            Number(dato.TRNRETENCION_TOTALRETENIDO).toFixed(2);
          item.CON_CODIGO = dato.CON_CODIGO === null ? '' : dato.CON_CODIGO;
          item.TRNRETENCION_SERIE = dato.TRNRETENCION_SERIE === null ? '' : dato.TRNRETENCION_SERIE;
          item.LINEA = numero++;

          // this.trnTarjetaService.detModeloRetencion[this.indicador].push(item);
          detalle.push(item);
        }
        this.trnTarjetaService.detModeloRetencion[this.indicador] = detalle;
        // this.aggrid.refreshColumnDefs();
        //  this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloRetencion[this.indicador], this.defaultColBanTarRetencion.objeto);
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso mostrar retenciones no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  calcularImporteTotal(): number {
    let intIndiceLista = 0;
    let item: BanDetTrnTarjeta = {};

    let dblTotalImporte = 0;
    let dblTotalImporteVarios = 0;
    let dblTotalRetencion = 0;
    let dblTotalComision = 0;
    let dblTotalACobrar = 0;
    let dblBase = 0;
    let dblPorcentaje = 0;

    // Total de los documentos seleccionados
    for (intIndiceLista = 0; intIndiceLista < this.detDepositoTarjeta.length; intIndiceLista++) {
      item = this.detDepositoTarjeta[intIndiceLista];
      if (item.SELECCION === true) {
        dblTotalImporte = dblTotalImporte + Number(item.TRNTARJETADET_IMPORTE);
      }

    }
    // Total Retenciones
    dblTotalRetencion = this.totalRetencion();
    // Total de varios
    dblTotalImporteVarios = this.obtenerTotalVarios();

    let value = dblTotalImporte;
    this.lblTotalImporte = Number(value).toFixed(2);

    dblTotalComision = 0;

    // calculo de la comision
    dblBase = Number(this.txtBase);
    dblPorcentaje = Number(this.txtPorcentaje);
    dblTotalComision = dblBase * dblPorcentaje / 100;
    value = dblTotalComision;

    this.txtComision = Number(value).toFixed(2);

    dblTotalACobrar = dblTotalImporte - dblTotalRetencion - dblTotalComision - dblTotalImporteVarios;

    value = dblTotalACobrar;
    this.txtValorCobrado = Number(value).toFixed(2);



    this.obtenerDebitosCreditos();

    return dblTotalImporte;

  }

  obtenerDebitosCreditos() {
    let intIndiceLista = 0;
    let item: BanDetModeloVarios = {};
    let dblTotalDebitos = 0;
    let dblTotalHaber = 0;
    let dblComision = 0;
    let dblTotalRetencion = 0;
    let dblTotalCobrado = 0;
    let dblTotalImporte = 0;

    let value = null;

    value = this.txtComision;
    dblComision = Number(value);
    dblTotalRetencion = this.totalRetencion();

    value = this.txtValorCobrado;
    dblTotalCobrado = Number(value);
    value = this.lblTotalImporte;
    dblTotalImporte = Number(value);

    dblTotalDebitos = dblComision + dblTotalRetencion + dblTotalCobrado;
    dblTotalHaber = dblTotalImporte;

    for (intIndiceLista = 0; intIndiceLista < this.trnTarjetaService.detModeloVarios[this.indicador].length; intIndiceLista++) {
      item = this.trnTarjetaService.detModeloVarios[this.indicador][intIndiceLista];

      if (item.CON_CODIGO !== '') {
        dblTotalDebitos = dblTotalDebitos + Number(item.DEBE);
        dblTotalHaber = dblTotalHaber + Number(item.HABER);
      }
    }

    value = dblTotalDebitos;
    this.lblTotalDebitos = Number(value).toFixed(2);

    value = dblTotalHaber;
    this.lblCreditos = Number(value).toFixed(2);


  }

  totalRetencion(): number {
    let intIndiceLista = 0;
    let item: BanDetModeloRetencion = {};
    let dblTotalRetencion = 0;
    let value = null;

    for (intIndiceLista = 0; intIndiceLista < this.trnTarjetaService.detModeloRetencion[this.indicador].length; intIndiceLista++) {
      item = this.trnTarjetaService.detModeloRetencion[this.indicador][intIndiceLista];
      if (item.RETENCION_CODIGO !== '' || item.CON_CODIGO !== '') {
        dblTotalRetencion = dblTotalRetencion + Number(item.TRNRETENCION_TOTALRETENIDO);
      }

    }

    value = dblTotalRetencion;
    this.trnTarjetaService.lblTotalRetenciones = Number(value).toFixed(2);

    return dblTotalRetencion;

  }

  obtenerTotalVarios(): number {
    let intIndiceLista = 0;
    let item: BanDetModeloVarios = {};
    let dblSaldo = 0;
    let dblTotalDebitos = 0;
    let dblTotalHaber = 0;
    let value = null;

    for (intIndiceLista = 0; intIndiceLista < this.trnTarjetaService.detModeloVarios[this.indicador].length; intIndiceLista++) {
      item = this.trnTarjetaService.detModeloVarios[this.indicador][intIndiceLista];
      if (item.CON_CODIGO !== '') {
        dblTotalDebitos = dblTotalDebitos + Number(item.DEBE);
        dblTotalHaber = dblTotalHaber + Number(item.HABER);
      }
    }

    dblSaldo = dblTotalDebitos - dblTotalHaber;
    value = dblSaldo;
    this.trnTarjetaService.lblTotalVarios = Number(value).toFixed(2);

    return dblSaldo;
  }

  async mostrarVarios(trnTarjetaCodigo) {
    const bolConsultar = false;

    try {
      const detalle: BanDetModeloVarios[] = [];
      const rs = await this.trnTarjetaService.obtenerDetalleVarios(trnTarjetaCodigo);

      if (rs.length > 0) {
        let linea = 0;
        for (const dato of rs) {
          const item: BanDetModeloVarios = {};
          item.TRNTARJETA_CODIGO = dato.TRNTARJETA_CODIGO === null ? '' : dato.TRNTARJETA_CODIGO;
          item.CEN_CODIGO = dato.CEN_CODIGO === null ? '' : dato.CEN_CODIGO;
          item.COM_CODIGO = dato.COM_CODIGO === null ? '' : dato.COM_CODIGO;
          item.CON_CODIGO = dato.CON_CODIGO === null ? '' : dato.CON_CODIGO;


          if (dato.TRNTARVARIOS_IMPORTE >= 0) {
            item.DEBE = Number(dato.TRNTARVARIOS_IMPORTE).toFixed(2);
            item.HABER = Number(0).toFixed(2);
          } else {
            item.DEBE = Number(0).toFixed(2);
            item.HABER = Number(dato.TRNTARVARIOS_IMPORTE * (-1)).toFixed(2);
          }

          item.TRNTARVARIOS_DESCRIPCION = dato.TRNTARVARIOS_DESCRIPCION === null ? '' : dato.TRNTARVARIOS_DESCRIPCION;
          item.TRNTARVARIOS_IMPORTE = dato.TRNTARVARIOS_IMPORTE;
          item.TRNTARVARIOS_NROSEC = dato.TRNTARVARIOS_NROSEC;

          item.CON_CENTRO = await this.verificarCentroCostosCuenta(item.CON_CODIGO);
          item.LINEA = linea++;

          detalle.push(item);

          // this.trnTarjetaService.detModeloVarios.push(item);
        }
        this.trnTarjetaService.detModeloVarios[this.indicador] = detalle;
        // this.aggrid.refreshColumnDefs();
        // this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloVarios, this.defaultColBanTarVarios.objeto);
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', 'No se puede recuperar la información de la pestaña Varios.' + mensaje);
    }

    return bolConsultar;
  }
    //GSRF  
    noeliminar() {
      console.log('------>',this.lblDepConciliacion);
          console.log('------>',this.btnBorrar);
          if (this.lblDepConciliacion !== '') {
            this.btnBorrar = true;
          }
          console.log('------>',this.btnBorrar);
    }
    //GSRF
  nuevoRegistro() {
    this.limpiarDatos();
    this.limpiarDatosDeposito();
    this.nuevoDeposito();//GSRF
    this.detDepositoTarjeta = [];
    this.trnTarjetaService.detModeloRetencion[this.indicador] = [];
    this.trnTarjetaService.detModeloVarios[this.indicador] = [];
    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    this.activaBotones('N', true, false, false, true, true, true, true,
      true, true, true, true);

    document.getElementById('codigoTarjeta').focus();
  }

  nuevoDeposito() {
    this.editTxtComprobante = false;
    this.limpiarDatosDeposito();
    this.detModeloConciliacion = [];

    this.activaBotones('N', true, false, false, true, true, true, true,
      true, true, true, true);
    document.getElementById('codigoBanco').focus();

  }

  seleccionDetRetencion(valor) {
    if (valor === 'nuevo') {
      if (this.lblDepConciliacion === '') {
        this.nuevaLineaDetRet();
      }

    }
    if (valor === 'eliminar') {
      if (this.lblDepConciliacion === '') {
        this.eliminarRegistroRet();
      }

    }
    if (valor === 'cancelar') {
      if (this.lblDepConciliacion === '') {
        this.cancelarAccionesRet();
      }
    }

    if (valor.object !== undefined) {
      this.detalleRetencionSel = valor.object;
    }

  }

  seleccionDetVarios(valor) {
    if (valor === 'nuevo') {
      if (this.lblDepConciliacion === '') {
        this.nuevaLineaDetVarios();
      }
    }
    if (valor === 'eliminar') {
      if (this.lblDepConciliacion === '') {
        this.eliminarRegistroVarios();
      }
    }
    if (valor === 'cancelar') {
      if (this.lblDepConciliacion === '') {
        this.cancelarAccionesVarios();
      }
    }

    if (valor.object !== undefined) {
      this.detalleVariosSel = valor.object;
      if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColBanTarVarios.objeto].getFocusedCell().column.colId === 'DEBE') {
        this.calcularImporteTotal();
      }

      if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColBanTarVarios.objeto].getFocusedCell().column.colId === 'CON_CODIGO') {
        this.calcularImporteTotal();
      }


      if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColBanTarVarios.objeto].getFocusedCell().column.colId === 'HABER') {
        this.calcularImporteTotal();
      }
    }

  }

  cancelarAccionesVarios() {
    let intIndiceLista = 0;
    let item: BanDetModeloVarios = {};
    for (intIndiceLista = 0; intIndiceLista < this.trnTarjetaService.detModeloVarios[this.indicador].length; intIndiceLista++) {
      item = this.trnTarjetaService.detModeloVarios[this.indicador][intIndiceLista];
      if (item.CON_CODIGO.length === 0) {
        this.trnTarjetaService.detModeloVarios[this.indicador].splice(this.trnTarjetaService.detModeloVarios[this.indicador].findIndex(
          detalle => detalle.LINEA === item.LINEA),
          1);
        this.obtenerTotalVarios();
        this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloVarios[this.indicador], this.defaultColBanTarVarios.objeto);
      }
    }

    this.btnBorrarGridVar = false;
    this.btnNuevoGridVar = false;
    this.btnCancelarGridVar = true;
  }

  eliminarRegistroVarios() {
    if (Object.keys(this.detalleVariosSel).length > 0) {
      // this.confirmEliminarDetAsi();
      this.confirmationService.confirm({
        message: '¿ Está seguro de eliminar el registro ?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'depositoTarjetaconf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          try {
            if (this.detalleVariosSel.LINEA === 0) {
              this.trnTarjetaService.detModeloVarios[this.indicador] = this.trnTarjetaService.detModeloVarios[this.indicador].filter((val, j) => j !==
                this.trnTarjetaService.detModeloVarios[this.indicador].indexOf(this.detalleVariosSel));
              this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloVarios[this.indicador], this.defaultColBanTarVarios.objeto);
              this.trnTarjetaService.calcularTotalVarios();
            } else {
              this.trnTarjetaService.detModeloVarios[this.indicador].splice(this.trnTarjetaService.detModeloVarios[this.indicador].findIndex(
                detalle => detalle.LINEA === this.detalleVariosSel.LINEA),
                1);
              this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloVarios[this.indicador], this.defaultColBanTarVarios.objeto);


            }

            this.activaBotones('N', true, false, false, true, true,
              true, true, true, true, true, true);

            this.btnNuevoGridVar = false;
            this.btnBorrarGridVar = false;
            this.btnCancelarGridVar = true;

            this.obtenerTotalVarios();


          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('info', 'Error', '! Proceso eliminar linea detalle  no terminó satisfactoriamente.... !' + mensaje);
          }

        },
        reject: () => {
        }
      });
    } else {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
    }
  }

  nuevaLineaDetVarios() {
    const linea = this.trnTarjetaService.detModeloVarios[this.indicador].length;
    const nuevodetVarios = {
      TRNTARJETA_CODIGO: '',
      TRNTARVARIOS_NROSEC: 0,
      CON_CODIGO: '',
      CON_COMCODIGO: '',
      TRNTARVARIOS_DESCRIPCION: '',
      TRNTARVARIOS_IMPORTE: 0,
      COM_CODIGO: '01',
      CEN_CODIGO: '',
      DEBE: 0,
      HABER: 0,
      LINEA: linea + 1

    };

    const indi = this.trnTarjetaService.detModeloVarios[this.indicador].indexOf(this.detalleVariosSel);
    if (indi === -1) {
      this.trnTarjetaService.detModeloVarios[this.indicador].push(nuevodetVarios);
      this.detalleVariosSel = this.trnTarjetaService.detModeloVarios[this.indicador][this.trnTarjetaService.detModeloVarios
      [this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloVarios[this.indicador], this.defaultColBanTarVarios.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColBanTarVarios.objeto].setFocusedCell(
        this.trnTarjetaService.detModeloVarios[this.indicador].length - 1, 'CON_CODIGO');
    } else {
      this.trnTarjetaService.detModeloVarios[this.indicador].splice(indi + 1, 0, nuevodetVarios);
      this.aggrid.refreshaggridindex(this.trnTarjetaService.detModeloVarios[this.indicador], this.defaultColBanTarVarios.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColBanTarVarios.objeto].setFocusedCell(indi + 1, 'CON_CODIGO');
    }

  }

  cancelarAccionesRet() {
    let intIndiceLista = 0;
    let item: BanDetModeloRetencion = {};
    for (intIndiceLista = 0; intIndiceLista < this.trnTarjetaService.detModeloRetencion[this.indicador].length; intIndiceLista++) {
      item = this.trnTarjetaService.detModeloRetencion[this.indicador][intIndiceLista];
      if (item.RETENCION_CODIGO.length === 0) {
        this.trnTarjetaService.detModeloRetencion[this.indicador].splice(this.trnTarjetaService.detModeloRetencion[this.indicador].findIndex(
          detalle => detalle.LINEA === item.LINEA),
          1);
        this.totalRetencion();
        this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloRetencion[this.indicador], this.defaultColBanTarRetencion.objeto);
      }
    }

    this.btnBorrarGridRet = false;
    this.btnNuevoGridRet = false;
    this.btnCancelarGridRet = true;

  }

  eliminarRegistroRet() {
    const intIndiceFila = this.trnTarjetaService.detModeloRetencion[this.indicador].indexOf(this.detalleRetencionSel) + 1;
    // let item: BanDetModeloRetencion;

    if (Object.keys(this.detalleRetencionSel).length > 0) {
      // this.confirmEliminarDetAsi();
      this.confirmationService.confirm({
        message: '¿ Está seguro de eliminar el registro ?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'depositoTarjetaconf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          try {
            if (this.detalleRetencionSel.LINEA === 0) {
              this.trnTarjetaService.detModeloRetencion[this.indicador] = this.trnTarjetaService.detModeloRetencion[this.indicador].filter((val, j) => j !==
                this.trnTarjetaService.detModeloRetencion[this.indicador].indexOf(this.detalleRetencionSel));
              this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloRetencion[this.indicador], this.defaultColBanTarRetencion.objeto);
              this.trnTarjetaService.calcularTotalRetenciones();
            } else {
              this.trnTarjetaService.detModeloRetencion[this.indicador].splice(this.trnTarjetaService.detModeloRetencion[this.indicador].findIndex(
                detalle => detalle.LINEA === this.detalleRetencionSel.LINEA),
                1);
              this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloRetencion[this.indicador], this.defaultColBanTarRetencion.objeto);


            }

            this.activaBotones('N', true, false, false, true, true,
              true, true, true, true, true, true);

            this.btnNuevoGridRet = false;
            this.btnBorrarGridRet = false;
            this.btnCancelarGridRet = true;

            this.totalRetencion();


          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('info', 'Error', '! Proceso eliminar retención  no terminó satisfactoriamente.... !' + mensaje);
          }

        },
        reject: () => {
        }
      });
    } else {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
    }

  }

  nuevaLineaDetRet() {
    const linea = this.trnTarjetaService.detModeloRetencion[this.indicador].length;
    const nuevodetRetencion = {
      CLI_CODIGO: '',
      COM_CODIGO: '01',
      RETENCION_CODIGO: '',
      RETENCION_AUTORIZACION: '',
      TRNRETENCION_BASE: 0,
      TRNRETENCION_BASE0: 0,
      TRNRETENCION_BASEG: 0,
      TRNRETENCION_BASENG: 0,
      TRNRETENCION_DESCRIPCION: '',
      TRNRETENCION_PORCENTAJE: 0,
      TRNRETENCION_SERIE: '',
      TRNRETENCION_TOTALRETENIDO: 0,
      CON_CODIGO: '',
      CEN_CODIGO: '',
      ENCFAC_NUMERO: '',
      TRNRETENCION_NRO: '',
      LINEA: linea + 1,
      TRNRETENCION_FLAG: 1,
      TRNRETENCION_ORIGEN: 'FAC'
    };

    const indi = this.trnTarjetaService.detModeloRetencion[this.indicador].indexOf(this.detalleRetencionSel);
    if (indi === -1) {
      this.trnTarjetaService.detModeloRetencion[this.indicador].push(nuevodetRetencion);
      this.detalleRetencionSel = this.trnTarjetaService.detModeloRetencion[this.indicador][this.trnTarjetaService.detModeloRetencion
      [this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloRetencion[this.indicador], this.defaultColBanTarRetencion.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColBanTarRetencion.objeto].setFocusedCell(
        this.trnTarjetaService.detModeloRetencion[this.indicador].length - 1, 'RETENCION_CODIGO');
    } else {
      this.trnTarjetaService.detModeloRetencion[this.indicador].splice(indi + 1, 0, nuevodetRetencion);
      this.aggrid.refreshaggridindex(this.trnTarjetaService.detModeloRetencion[this.indicador], this.defaultColBanTarRetencion.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColBanTarRetencion.objeto].setFocusedCell(indi + 1, 'RETENCION_CODIGO');
    }

    this.btnNuevoGridRet = true;
    this.btnBorrarGridRet = true;
    this.btnCancelarGridRet = false;

  }

  async verificarCentroCostosCuenta(strCodCuenta) {
    try {
      const rs = await this.trnTarjetaService.verificarCentroCostos(strCodCuenta);
      if (rs.length > 0) {
        return rs[0].CON_CENTRO;
      } else {
        return 'No';
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', 'No se puede obtener la información de la base de datos ' + mensaje);
    }
  }

  limpiarDatos() {

    this.dateTimFechaEmision = new Date();
    this.txtCodTarjeta = '';
    this.lblNombreTarjeta = '';
    this.txtNumeroComprobante = '';
    this.txtValorCobrado = '';
    this.txtBase = '';
    this.txtPorcentaje = '';
    this.txtComision = '';
    this.txtObservaciones = '';
    this.lblTotalDebitos = '';
    this.lblCreditos = '';
    this.lblTotalImporte = '';
    this.strControlAbrirDoc = '0';
    this.txtTrnTarjetaCodigo = '';
    this.lblDepConciliacion = '';
    this.lblAsientoRet = '';
    this.txtBase = Number(0).toFixed(2);
    this.txtPorcentaje = '';
    this.txtComision = Number(0).toFixed(2);

    this.CHKAsientoMultiple = false;
  }

  buscarRegistro() {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.opcion = 'BuscarRegistro';
    this.types = [
      { label: 'CÓDIGO', value: 'TRNTARJETA_CODIGO' },
      { label: 'VOUCHERS', value: 'TRNTARJETA_VOUCHERS' },
      { label: 'COMPROBANTE', value: 'TRNTARJETA_NRORECAP' },
      { label: 'DP', value: 'TRNBAN_NUMERO' },
      { label: 'FECHA', value: 'TRNTARJETA_FECHATRN' },
      { label: 'BANCO', value: 'BAN_CODIGO' },
      { label: 'OBSERVACIÓN', value: 'TRNTARJETA_OBSERVACION' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', ''];
    this.tabla = 'ban_trntarjeta';
    this.where = 'TRNTARJETA_ORIGEN=\'TAR\' AND COM_CODIGO = \'01\' and ROWNUM < 50';
    this.consulta = ' trntarjeta_codigo , trntarjeta_vouchers ,trntarjeta_nrorecap ,nvl(trnban_numero,\'Ninguno\') as trnban_numero,' +
      '  trntarjeta_fechatrn, ban_codigo, trntarjeta_observacion, TRNTARJETA_ORIGEN, COM_CODIGO';
    this.busquedaSer.busquedaBanTrnTarjeta().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((dato) => {
        if (dato.TRNTARJETA_FECHATRN !== null) {
          dato.TRNTARJETA_FECHATRN = this.datePipe.transform(dato.TRNTARJETA_FECHATRN, 'dd/MM/yyyy');
        } else {
          dato.TRNTARJETA_FECHATRN = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  buscarDeposito() {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.opcion = 'BuscarDeposito';
    this.types = [
      { label: 'BANCO', value: 'BAN_CODIGO' },
      { label: 'TIPO', value: 'TRNBAN_TIPO' },
      { label: 'NÚMERO', value: 'TRNBAN_NUMERO' },
      { label: 'FECHA', value: 'TRNBAN_FECHA' },
      { label: 'IMPORTE', value: 'TRNBAN_IMPORTE' }
    ];
    this.busquedacampos = ['', '', '', '', ''];
    this.tabla = 'BAN_TRNBAN';
    this.where = ' TRNBAN_ORIGEN =\'TAR\' and ROWNUM <50';
    this.consulta = 'BAN_CODIGO, TRNBAN_TIPO , TRNBAN_NUMERO, TRNBAN_FECHA, TRNBAN_IMPORTE, TRNBAN_ORIGEN';
    this.busquedaSer.busquedaBanTrnBan1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((dato) => {
        if (dato.TRNBAN_FECHA !== null) {
          dato.TRNBAN_FECHA = this.datePipe.transform(dato.TRNBAN_FECHA, 'dd/MM/yyyy');
        } else {
          dato.TRNBAN_FECHA = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  async manejarSeleccion(valor) {
    if (this.opcion === 'BuscarRegistro') {
      this.mostrarDatos(Number(valor.TRNTARJETA_CODIGO));
    }

    if (this.opcion === 'BuscarDeposito') {
      this.auxiliar = '0';
      this.mostrarDatosDeposito(valor.BAN_CODIGO,
        valor.TRNBAN_TIPO, valor.TRNBAN_NUMERO);
    }

    if (this.opcion === 'BuscarTarjeta') {
      this.txtCodTarjeta = valor.TAR_CODIGO;
      this.lblNombreTarjeta = valor.TAR_DESCRIPCION;
      this.extraePorcentajeTarjeta(this.txtCodTarjeta);
      this.listarDatos();
      document.getElementById('comprobante').focus();
    }

    if (this.opcion === 'BuscarBanco') {

      this.editTxtComprobante = false;
      this.limpiarDatosDeposito();
      this.detModeloConciliacion = [];

  

      this.txtCodBanco = valor.BAN_CODIGO;
      this.lblNombreBanco = valor.BAN_BANCO;

      this.activaBotones('M', true, false, false, true, true, true,
        true, true, true, true, false);
      this.listarDatosConciliacion();
      document.getElementById('comprobanteBanco').focus();

    }


    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'codigoTarjeta') {
      this.buscarTarjeta(this.txtCodTarjeta);
      document.getElementById('comprobante').focus();
    }

    if (evento.target.id === 'comprobante') {
      document.getElementById('base').focus();
    }
    if (evento.target.id === 'base') {
      //GSRF
      if(this.txtTrnTarjetaCodigo !== '' && this.txtTrnTarjetaCodigo !== null && this.txtTrnTarjetaCodigo !== undefined ){
        console.log('<<<<<<>>>>>>', this.txtTrnTarjetaCodigo);
        this.btnGuardar = false;
      }
      //GSRF
      this.calcularImporteTotal();
      this.txtBase = Number(this.txtBase).toFixed(2);
      document.getElementById('porcentaje').focus();
    }
    if (evento.target.id === 'porcentaje') {
      //GSRF
      if(this.txtTrnTarjetaCodigo !== '' && this.txtTrnTarjetaCodigo !== null && this.txtTrnTarjetaCodigo !== undefined ){
        console.log('<<<<<<>>>>>>', this.txtTrnTarjetaCodigo);
        this.btnGuardar = false;
      }
      //GSRF
      this.calcularImporteTotal();
      document.getElementById('comision').focus();
    }
    if (evento.target.id === 'comision') {
       //GSRF
       if(this.txtTrnTarjetaCodigo !== '' && this.txtTrnTarjetaCodigo !== null && this.txtTrnTarjetaCodigo !== undefined ){
        console.log('<<<<<<>>>>>>', this.txtTrnTarjetaCodigo);
        this.btnGuardar = false;
      }
      //GSRF
      this.calcularImporteTotal();
      this.txtComision = Number(this.txtComision).toFixed(2);
      document.getElementById('observaciones').focus();
    }

    if (evento.target.id === 'codigoBanco') {
      this.buscarBanco(this.txtCodBanco);
      document.getElementById('comprobanteBanco').focus();
    }
    if (evento.target.id === 'comprobanteBanco') {
      document.getElementById('conceptoBanco').focus();
    }
    //GSRF
    if (evento.target.id === 'fechaBanco') {
      if(this.txtTrnTarjetaCodigo !== '' && this.txtTrnTarjetaCodigo !== null && this.txtTrnTarjetaCodigo !== undefined ){
        console.log('<<<<<<>>>>>>', this.txtTrnTarjetaCodigo);
        this.btnGuardar = false;
      }
    }
    if (evento.target.id === 'observaciones') {
      if(this.txtTrnTarjetaCodigo !== '' && this.txtTrnTarjetaCodigo !== null && this.txtTrnTarjetaCodigo !== undefined ){
        console.log('<<<<<<>>>>>>', this.txtTrnTarjetaCodigo);
        this.btnGuardar = false;
      }
    }
    if (evento.target.id === 'importeBanco') {
      if(this.txtTrnTarjetaCodigo !== '' && this.txtTrnTarjetaCodigo !== null && this.txtTrnTarjetaCodigo !== undefined ){
        console.log('<<<<<<>>>>>>', this.txtTrnTarjetaCodigo);
        this.btnGuardar = false;
      }
    }
    if (evento.target.id === 'conceptoBanco') {
      if(this.txtTrnTarjetaCodigo !== '' && this.txtTrnTarjetaCodigo !== null && this.txtTrnTarjetaCodigo !== undefined ){
        console.log('<<<<<<>>>>>>', this.txtTrnTarjetaCodigo);
        this.btnGuardar = false;
      }
    }
    if (evento.target.id === 'pendienteBanco') {
      if(this.txtTrnTarjetaCodigo !== '' && this.txtTrnTarjetaCodigo !== null && this.txtTrnTarjetaCodigo !== undefined ){
        console.log('<<<<<<>>>>>>', this.txtTrnTarjetaCodigo);
        this.btnGuardar = false;
      }
    }
    
    //GSRF

  }

  activaBotones(strTipo, bolNuevo, bolGuardar, bolCancelar, bolEliminar, bolInicio, bolAnterior, bolSiguiente, bolUltimo, bolBuscar,
    bolVisualizarAsiento, bolImprimir) {

    this.btnGuardar = bolGuardar;
    this.btnRegresar = bolCancelar;
    this.btnNuevo = bolNuevo;
    this.btnBorrar = bolEliminar;
    this.botonPrimero = bolInicio;
    this.botonAnterior = bolAnterior;
    this.botonSiguiente = bolSiguiente;
    this.botonUltimo = bolUltimo;
    this.btnBuscar = bolBuscar;


    if (strTipo === 'NoModificar') {
      this.editarCeldas=true;//GSRF
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
    } else {
      this.editarCeldas=false;//GSRF 
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal2').style.pointerEvents = 'all';
    }

  }

  onTab1Change(event) {
    this.selectTab = event.index;
    if (this.selectTab === 0) {
      if (this.txtComprobante.length > 0) {
        this.activaBotones(this.strControlActivaOpciones, false, true, true,
          true, false, false, false, false, false, false, false);
      } else {
        this.activaBotones(this.strControlActivaOpciones, false, true, true,
          false, false, false, false, false, false, false, false);
      }
      this.cancelarRegistro();//GSRF
    }
    if (this.selectTab === 1) {
      this.activaBotones(this.strControlActivaOpciones, false, true, true,
        false, false, false, false, false, false, false, false);
        this.cancelarRegistro();//GSRF
    }

  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'depositoTarjeta',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  isEdit(data): boolean {
    if (data.CON_CENTRO === 'Si') {
      return true;
    } else {
      return false;
    }
    return true;
  }

  async guardarRegistro() {
    let strTipoGuardar;
    let dblComision = 0;
    let dblImComision = 0;
    let dblTotalImporte = 0;
    for (let intIndiceLista = 0; intIndiceLista < this.trnTarjetaService.detModeloVarios[this.indicador].length; intIndiceLista++) {
      let item = this.trnTarjetaService.detModeloVarios[this.indicador][intIndiceLista];
      console.log('entra1',item.CON_CODIGO)
      if (item.CON_CODIGO.substr(-1, 1) === '.') {
        console.log('entra2')
        this.mensajeAlerta('error', 'Cuenta Contable', 'No se puede elegir esa cuenta contable');
        item.CON_CODIGO = '';
        item.TRNTARVARIOS_DESCRIPCION = '';
        this.aggrid.refreshaggrid(this.trnTarjetaService.detModeloVarios, this.defaultColBanTarVarios.objeto);
        this.index=2;
       return;
      }
      //GSRF
      let numdep = await this.trnTarjetaService.getcuentconta(item.CON_CODIGO);
      console.log('NC',numdep.length);
      if (numdep.length === 0) {
        this.mensajeAlerta('error', 'Cuenta Contable', 'Cuenta contable no existe'); 
        return;
      }
      //GSRF
    }
    if (this.txtCodTarjeta === '') { /* Nuevo registro */
      strTipoGuardar = 'N';
      /* Verifica los permisos */
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERINSERCION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ' , no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    } else { /* Modifica registro */
      strTipoGuardar = 'M';
      /* Verifica los permisos */
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERACTUALIZACION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ' , no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }
    if (await this.faltaCampos() === true) { // Si los datos de la cabecera son correctos
      this.cancelarAccionesRet();
      this.cancelarAccionesVarios();
      if (this.faltanCamposDet() === true) { // Si los datos del detalle son correctos
        if (await this.faltaDatosRet() === true) {
          this.btnCancelarGridRet = true;
          this.btnNuevoGridRet = false;
          this.btnCancelarGridRet = false;
          return;
        }
        this.calcularImporteTotal();

        const strFechaEmision = this.dateTimFechaEmision.toLocaleString('en-GB').split(',');
        const valueValorCobrado = Number(this.txtValorCobrado).toFixed(2);
        const valueComision = Number(this.txtComision).toFixed(2);
        dblComision = Number(valueComision);
        const totalImporte = Number(this.lblTotalImporte);
        dblTotalImporte = Number(totalImporte);
        dblImComision = dblTotalImporte - dblComision;

        const valuePorcentaje = Number(this.txtPorcentaje).toFixed(2);
        const valueCom = Number(this.txtComision).toFixed(2);
        const valueTotalImporte = Number(this.lblTotalImporte).toFixed(2);
        const vouchers = this.voucherSeleccionado();
        const valueBase = Number(this.txtBase).toFixed(2);

        if (this.trnTarjetaService.lblTotalRetenciones=== '') {
          this.trnTarjetaService.lblTotalRetenciones = Number(0).toFixed(2);
        }

        const valueTotalRetencion = Number(this.trnTarjetaService.lblTotalRetenciones).toFixed(2);
        let multiple = '';
        if (this.CHKAsientoMultiple) {
          multiple = 'S';
        } else {
          multiple = '';
        }


        try {
          this.txtNumeroComprobante=this.txtNumeroComprobante.trim()
          this.txtNumeroComprobante = this.txtNumeroComprobante.replace(/[' ']/g,'');
          const rs = await this.trnTarjetaService.guardarEncTarjeta(Number(this.txtTrnTarjetaCodigo), this.txtNumeroComprobante,
            strFechaEmision[0], Number(valueValorCobrado), Number(dblImComision), 0,
            0, '', this.txtCodTarjeta, '01', this.lblOrigen, this.txtObservaciones, Number(valuePorcentaje),
            Number(valueCom), Number(valueTotalImporte), vouchers, Number(valueBase), Number(valueTotalRetencion), multiple);
          if (rs.length > 0) {
            if (this.txtTrnTarjetaCodigo === '') {

              this.txtTrnTarjetaCodigo = rs[0][':B1'];

              /*this.auditoriagral.registrarAuditoria('BAN_TRNTARJETA', this.txtTrnTarjetaCodigo, 'I',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                });*/

            } else {
              /*this.auditoriagral.registrarAuditoria('BAN_TRNTARJETA', this.txtTrnTarjetaCodigo, 'M',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                });*/
            }
            this.mensajeAlerta('success', 'Conciliación Guardada', 'La Conciliación ha sido guardada correctamente. ' );//GSRF
            this.tarjdis=true;//GSRF
            await this.guardarDetalle();
            await this.guardarVarios();
            await this.guardarRetencion();

            if (this.CHKAsientoMultiple === true) {
              await this.generarAsientoRetExi();
            }


            this.activaBotones('G', false, true, true, false, false,
              false, false, false, false, false, false);
          }

        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Proceso guardar Cabecera de la conciliación  no terminó satisfactoriamente.... ! ' + mensaje);
        }
      }
    }
  }

  async generarAsientoRetExi() {
    let strCodAsiento: any;

    try {
      await this.trnTarjetaService.generarAsientoRetExi(this.txtTrnTarjetaCodigo);
      // Traer el código del asiento
      strCodAsiento = await this.trnTarjetaService.encontrarAsiNroRet(Number(this.txtTrnTarjetaCodigo));
      console.log(strCodAsiento);
      this.lblAsientoRet = strCodAsiento === null ? '' : strCodAsiento[0].ASI_NRO_RET;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso generar asiento de retención no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async guardarRetencion() {
    let intIndiceLista = 0;
    let item: BanDetModeloRetencion = {};
    for (intIndiceLista = 0; intIndiceLista < this.trnTarjetaService.detModeloRetencion[this.indicador].length; intIndiceLista++) {
      item = this.trnTarjetaService.detModeloRetencion[this.indicador][intIndiceLista];
      if (item.CON_CODIGO === '') {
        this.trnTarjetaService.detModeloRetencion[this.indicador].splice(this.trnTarjetaService.detModeloRetencion[this.indicador].findIndex(
          detalle => detalle.LINEA === item.LINEA),
          1);
      } else {
        this.calcularTotalRetenido(intIndiceLista);
      }
    }
    this.totalRetencion();
    intIndiceLista = 0;

    // Elimina retenciones

    try {
      await this.trnTarjetaService.eliminarRetencion(String(this.txtTrnTarjetaCodigo));
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar retenciones no terminó satisfactoriamente.... ! ' + mensaje);
      return false;
    }

    for (intIndiceLista = 0; intIndiceLista < this.trnTarjetaService.detModeloRetencion[this.indicador].length; intIndiceLista++) {
      item = this.trnTarjetaService.detModeloRetencion[this.indicador][intIndiceLista];
      if (item.CON_CODIGO !== '') {
        try {
          await this.trnTarjetaService.guardarDetRetencion(item.RETENCION_CODIGO, item.TRNRETENCION_DESCRIPCION, item.TRNRETENCION_NRO,
            Number(item.TRNRETENCION_BASE), Number(item.TRNRETENCION_PORCENTAJE), Number(item.TRNRETENCION_TOTALRETENIDO).toFixed(2),//GSRF 
            'BAN', item.CON_CODIGO, '', this.txtTrnTarjetaCodigo, item.TRNRETENCION_SERIE);

          this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION', 'RT/' + item.RETENCION_CODIGO + '/' + this.txtTrnTarjetaCodigo, 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          this.activaBotones('M', true, false, false, true, true, true,
            true, true, true, true, false);
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Proceso guardar retencion no terminó satisfactoriamente.... ! ' + mensaje);

        }
      } // Fin si no es un valor en blanco

    }
    return true;

  }

  calcularTotalRetenido(intFilaActual: number) {
    let dblTotalFila = 0;
    let dblBase = 0;
    let dblPorcentaje = 0;
    this.dblTotalRet = 0;
    let itemR: BanDetModeloRetencion = {};

    if (intFilaActual >= 0) {
      dblBase = Number(this.trnTarjetaService.detModeloRetencion[this.indicador][intFilaActual].TRNRETENCION_BASE);
      dblPorcentaje = Number(this.trnTarjetaService.detModeloRetencion[this.indicador][intFilaActual].TRNRETENCION_PORCENTAJE);
      dblTotalFila = dblBase * dblPorcentaje / 100;
      this.trnTarjetaService.detModeloRetencion[this.indicador][intFilaActual].TRNRETENCION_TOTALRETENIDO = dblTotalFila;
    }

    for (let fila = 0; fila < this.trnTarjetaService.detModeloRetencion[this.indicador].length; fila++) {
      itemR = this.trnTarjetaService.detModeloRetencion[this.indicador][fila];
      this.dblTotalRet = this.dblTotalRet + Number(itemR.TRNRETENCION_TOTALRETENIDO);
    }
  }

  async guardarVarios() {
    let intIndiceLista = 0;
    let item: BanDetModeloVarios = {};
    for (intIndiceLista = 0; intIndiceLista < this.trnTarjetaService.detModeloVarios[this.indicador].length; intIndiceLista++) {
      item = this.trnTarjetaService.detModeloVarios[this.indicador][intIndiceLista];
      if (item.CON_CODIGO === '') {
        this.trnTarjetaService.detModeloVarios[this.indicador].splice(this.trnTarjetaService.detModeloVarios[this.indicador].findIndex(
          detalle => detalle.LINEA === item.LINEA),
          1);
      }

    }
    this.obtenerTotalVarios();
    intIndiceLista = 0;

    // Elimina varios

    try {
      await this.trnTarjetaService.eliminarVarios(this.txtTrnTarjetaCodigo);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar varios no terminó satisfactoriamente.... ! ' + mensaje);
      return;
    }

    for (intIndiceLista = 0; intIndiceLista < this.trnTarjetaService.detModeloVarios[this.indicador].length; intIndiceLista++) {
      item = this.trnTarjetaService.detModeloVarios[this.indicador][intIndiceLista];
      if (item.CON_CODIGO !== '') {

        let debeHaber = '';
        if (Number(item.DEBE) !== 0) {
          debeHaber = Number(item.DEBE).toFixed(2);
        }
        if (Number(item.HABER) !== 0) {
          debeHaber = Number(Number(item.HABER) * -1).toFixed(2);
        }

        try {
          const rs = await this.trnTarjetaService.guardarDetVarios(Number(this.txtTrnTarjetaCodigo), 0, item.CON_CODIGO,
            item.TRNTARVARIOS_DESCRIPCION, Number(debeHaber), item.CEN_CODIGO);
          if (rs.length > 0) {
            // item.setTrnTarVariosNroSec(rs.getInt(1));
            this.auditoriagral.registrarAuditoria('"BAN_TRNTARVARIOS', 'RT/' + item.CON_CODIGO + '/' + this.txtTrnTarjetaCodigo, 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
            this.activaBotones('M', true, false, false, true, true,
              true, true, true, true, true, false);
          }
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Proceso guardar varios no terminó satisfactoriamente.... ! ' + mensaje);
        }
      } // Fin si no es un valor en blanco

    }
  }

  async guardarDetalle() {
    let intIndiceLista = 0;
    let item: BanDetTrnTarjeta = {};

    if (this.faltanCamposDet() === false) {
      return false;
    }

    for (intIndiceLista = 0; intIndiceLista < this.detDepositoTarjeta.length; intIndiceLista++) {
      item = this.detDepositoTarjeta[intIndiceLista];
      if (item.SELECCION === true) { // si esta seleccionado

        try {
          const rs = await this.trnTarjetaService.guardarDetalleDep(Number(this.txtTrnTarjetaCodigo), Number(item.TRNTARJETADET_NROSEC),
            item.TRNCAJ_TIPO, item.TRNCAJ_NUMERO, item.CAJ_CODIGO, item.TRNCAJ_DOCNRO, Number(item.TRNTARJETADET_IMPORTE));
          if (rs.length > 0) {
            item.TRNTARJETADET_NROSEC = rs[0][':B1'];
          }

          this.auditoriagral.registrarAuditoria('BAN_TRNTARJETADET', this.txtTrnTarjetaCodigo, 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });

        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Proceso guardar datos de los vouchers no terminó satisfactoriamente.... ! ' + mensaje);
          return false;
        }
      } else {

        if (item.TRNTARJETADET_NROSEC !== 0) {

          try {
            const rs = await this.trnTarjetaService.eliminarDetalleDep(this.txtTrnTarjetaCodigo, item.TRNTARJETADET_NROSEC);
            this.auditoriagral.registrarAuditoria('BAN_TRNTARJETADET', this.txtCodTarjeta, 'E',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', '! Proceso eliminar vouchers de la conciliación no terminó satisfactoriamente.... ! ' + mensaje);
            return false;
          }
        }
      }
    } // Fin de for*/


    if (await this.actualizaBanTrnCaj() === false) {
      return false;
    }

    return true;
  }

  async actualizaBanTrnCaj() {
    try {
      await this.trnTarjetaService.actualizaBanTrnCaj(this.txtTrnTarjetaCodigo);
      return true;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso mostrar vouchers no terminó satisfactoriamente.... ! ' + mensaje);
      return false;
    }

    return true;
  }

  async faltaDatosRet() {
    let bolFalta = false;
    let item: BanDetModeloRetencion = {};

    for (let fila = 1; fila <= this.trnTarjetaService.detModeloRetencion[this.indicador].length; fila++) {
      item = this.trnTarjetaService.detModeloRetencion[this.indicador][fila - 1];
      let strCodigo: any;

      if (item.RETENCION_CODIGO.length === 0) {
        this.mensajeAlerta('info', 'Información', 'Ingrese el código de la Retención.');
        bolFalta = true;
      } else {
        if (item.RETENCION_CODIGO.length > 0) { // existencia de la retención
          try {
            strCodigo = await this.trnTarjetaService.encontrarRetencionCodigo(item.RETENCION_CODIGO);
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('info', 'Error', 'No se puede verificar la existencia de la retención ' + mensaje);
          }

          if (strCodigo === null) {
            this.mensajeAlerta('info', 'Información', 'La retención no existe.');
            return bolFalta = true;
          }
        }
        if (item.TRNRETENCION_DESCRIPCION.length === 0 || item.TRNRETENCION_NRO.length === 0) {
          this.mensajeAlerta('info', 'Información', 'La descripción y el número de retención son obligatorios.');
          return bolFalta = true;
        }

        if (Number(item.TRNRETENCION_BASE) <= 0) {
          this.mensajeAlerta('info', 'Información', 'La base de la retención no puede ser menor o igual a cero.');
          return bolFalta = true;
        }

        if (Number(item.TRNRETENCION_PORCENTAJE) < 0) {
          this.mensajeAlerta('info', 'Información', 'El porcentaje de la retención no puede ser menor a cero.');
          return bolFalta = true;
        }

        if (Number(item.TRNRETENCION_TOTALRETENIDO) < 0) {
          this.mensajeAlerta('info', 'Información', 'El total de la retención no puede ser menor a cero.');
          return bolFalta = true;
        }

        if (item.CON_CODIGO === 0 || item.CON_CODIGO === null) {
          this.mensajeAlerta('info', 'Información', 'La retención no tiene asignado la cuenta contable');
          return bolFalta = true;
        }
      }
    }
    return bolFalta;
  }

  faltanCamposDet(): boolean {
    let intIndiceLista = 0;
    let item: BanDetTrnTarjeta = {};
    let intSwControl = 0;

    for (intIndiceLista = 0; intIndiceLista < this.detDepositoTarjeta.length; intIndiceLista++) {
      item = this.detDepositoTarjeta[intIndiceLista];
      if (item.SELECCION === true) { // si esta seleccionado
        intSwControl = 1;
      }
    } // fin de for
    if (intSwControl === 0) {
      this.mensajeAlerta('info', 'Información', 'No existe ningún item seleccionado');
      return false;
    }
    return true;
  } // Fin faltan CamposDet

  async faltaCampos() {

    const dia = this.dateTimFechaEmision.getDate();
    const mes = this.dateTimFechaEmision.getMonth();
    const anio = this.dateTimFechaEmision.getFullYear();
    this.txtNumeroComprobante=this.txtNumeroComprobante.trim()
    this.txtNumeroComprobante = this.txtNumeroComprobante.replace(/[' ']/g,'');
    // Verifica que documento este dentro del periodo contable
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.mensajeAlerta('info', 'Información', 'Error, fecha debe de estar dentro del período contable');
      return false;
    }

    if (this.txtCodTarjeta === '') {
      this.mensajeAlerta('info', 'Información', 'Error, ingrese el código de la tarjeta');
      return false;
    }

    if (this.txtNumeroComprobante === '') {
      this.mensajeAlerta('info', 'Información', 'Error, ingrese el número del comprobante');
      return false;
    }

    return true;
  }

  async guardarDeposito() {
    let strTipoOperacion = '';

    if (this.editTxtComprobante === false) { /* Nuevo registro */
      strTipoOperacion = 'N';
      /* Verifica los permisos */
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERINSERCION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ' , no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    } else { /* Modifica registro */
      strTipoOperacion = 'M';
      /* Verifica los permisos */
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERACTUALIZACION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ' , no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }

    if (await this.faltaCamposDeposito() === true) { // Si los datos de la cabecera son correctos
      if (this.faltanCamposDetConcil() === true) {
        this.calcularImporteTotalConcil();

        const strFechaEmision = this.dateTimeFechaDeposito.toLocaleString('en-GB').split(',');
        if (strTipoOperacion === 'N') {
          const value = Number(this.txtImporte).toFixed(2);
          const valuePend = Number(this.txtDepPend).toFixed(2);
          try {
            this.txtComprobante=this.txtComprobante.trim()
            this.txtComprobante = this.txtComprobante.replace(/[' ']/g,'');
            await this.trnTarjetaService.guardarEncConcil(this.txtCodBanco, strFechaEmision[0], 'DP', this.txtComprobante,
              '', Number(value), this.txtConceptoDeposito, '', 0, '',
              this.lblOrigen, this.txtComprobante, strFechaEmision[0], '', '', '', Number(valuePend));

            this.auditoriagral.registrarAuditoria('BAN_TRNBAN', this.txtComprobante, 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });

            await this.guardarDetalleConcil();
            await this.generarAsientoContable();
            this.activaBotones('G', false, true, true, false, false,
              false, false, false, false, false, false);
            this.editTxtComprobante = true;
            this.mensajeAlerta('success', 'Depósito Guardado', 'La Depósito ha sido guardado correctamente. ' );//GSRF
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('info', 'Error', '! Proceso guardar depósito no terminó satisfactoriamente.... ! ' + mensaje);
          }

        } else {
          const value = Number(this.txtImporte).toFixed(2);
          const valuePendiente = Number(this.txtDepPend).toFixed(2);

          try {
            this.txtComprobante=this.txtComprobante.trim()
            this.txtComprobante = this.txtComprobante.replace(/[' ']/g,'');

            await this.trnTarjetaService.actualizarEncConcil(this.txtCodBanco, strFechaEmision[0], 'DP',
              this.txtComprobante.trim(), '', Number(value), this.txtConceptoDeposito, '',
              0, '', this.lblOrigen, this.txtComprobante, strFechaEmision[0], '',
              '', '', Number(valuePendiente));
            this.auditoriagral.registrarAuditoria('BAN_TRNBAN', this.txtComprobante, 'M',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
            await this.guardarDetalleConcil();
            await this.generarAsientoContable();
            this.activaBotones('G', false, true, true, false, false,
              false, false, false, false, false, false);
              this.mensajeAlerta('success', 'Depósito Guardado', 'La Depósito ha sido guardado correctamente. ' );//GSRF
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('info', 'Error', '! Proceso actualizar depósito no terminó satisfactoriamente.... ! ' + mensaje);
          }
        }
        this.cancelarRegistro();//GSRF
      }

    }
  }

  async generarAsientoContable() {
    let strCodAsiento;

    try {
      this.txtComprobante=this.txtComprobante.trim()
      this.txtComprobante = this.txtComprobante.replace(/[' ']/g,'');
      if (this.CHKAsientoMultiple === true) {
        await this.trnTarjetaService.generaAsientoTar_dep_n(this.txtCodBanco, this.txtComprobante, 'DP');
      } else {
        await this.trnTarjetaService.generaAsientoCntblTar_n(this.txtCodBanco, this.txtComprobante, 'DP');
      }

      // Traer el código del asiento
      strCodAsiento = await this.trnTarjetaService.encontrarAsiNro(this.txtCodBanco,this.txtComprobante);

      this.lblAsiento = strCodAsiento[0].ASI_NRO;

    } catch (err) {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso generar asiento del depósito no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async guardarDetalleConcil() {
    let intIndiceLista = 0;
    let item: BanDetModeloConciliacion = {};


    if (this.faltanCamposDetConcil() === false) {
      return false;
    }

    await this.actualizaNullTransaccionesTarjeta();

    

        try {
          this.txtComprobante=this.txtComprobante.trim()
          this.txtComprobante = this.txtComprobante.replace(/[' ']/g,'');
          for (intIndiceLista = 0; intIndiceLista < this.detModeloConciliacion.length; intIndiceLista++) {
            item = this.detModeloConciliacion[intIndiceLista];
      
            console.log(this.detModeloConciliacion);
      
            if (item.CONCIL_SELECT === true) { // si esta seleccionado
          await this.trnTarjetaService.actlzTrnBanEnBanTrnTarj(Number(item.TRNTARJETA_CODIGO), this.txtCodBanco, this.txtComprobante, 'DP');
          this.auditoriagral.registrarAuditoria('BAN_TRNTARJETADET', this.txtCodTarjeta, 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });

          }
        } // Fin de for*/

        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('info', 'Error', '! Proceso guardar datos de las tarjetas no terminó satisfactoriamente.... ! ' + mensaje);
          return false;
        }
     

    return true;
  }

  async actualizaNullTransaccionesTarjeta() {
    try {
      this.txtComprobante=this.txtComprobante.trim()
      this.txtComprobante = this.txtComprobante.replace(/[' ']/g,'');
      await this.trnTarjetaService.actlzNullTrnBanTrnTarj(this.txtCodBanco, this.txtComprobante, 'DP');
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso actualizar en nulo las transacciones de la tarjeta no terminó satisfactoriamente.... ! ' + mensaje);
      return false;
    }

    return true;
  }

  faltanCamposDetConcil() {
    let intIndiceLista = 0;
    let item: BanDetModeloConciliacion = {};
    let intSwControl = 0;
    let totenc = 0;//GSRF
    let totim = 0;//GSRF

    for (intIndiceLista = 0; intIndiceLista < this.detModeloConciliacion.length; intIndiceLista++) {
      item = this.detModeloConciliacion[intIndiceLista];
      if (item.CONCIL_SELECT === true) { // si esta seleccionado
        console.log('<<<>>>', item.TRNTARJETA_IMPCOBRADO );
        totim=totim+Number(item.TRNTARJETA_IMPCOBRADO);
        intSwControl = 1;
      }
    } // fin de for
        // //GSRF
        // console.log('<<<>>>', totim);
        // totenc=Number(this.txtImporte)+Number(this.txtDepPend);
        // console.log('<<<>>>', totenc);
        // if (totim.toFixed(2) !== totenc.toFixed(2)) {
        //   this.mensajeAlerta('info', 'Información', 'Error,El total a depositar es diferente al valor cobrado');
        //   return false;
        // }
        // //GSRF
    if (intSwControl === 0) {
      this.mensajeAlerta('info', 'Información', 'Error, No existe ningún item seleccionado');
      return false;
    }

    return true;
  }

  async faltaCamposDeposito() {
    const dia = this.dateTimeFechaDeposito.getDate();
    const mes = this.dateTimeFechaDeposito.getMonth();
    const anio = this.dateTimeFechaDeposito.getFullYear();
    this.txtComprobante=this.txtComprobante.trim()
    this.txtComprobante = this.txtComprobante.replace(/[' ']/g,'');
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.mensajeAlerta('info', 'Información', 'Error, fecha debe de estar dentro del período contable');
      return false;
    }

    if (await this.verificarConcilia() === false) {
      return false;
    }

    if (this.txtCodBanco === '') {
      this.mensajeAlerta('info', 'Información', 'Error, ingrese el código del banco');
      return false;
    }

    if (this.txtComprobante === '') {
      this.mensajeAlerta('info', 'Información', 'Error, ingrese el número del comprobante del banco');
      return false;
    }

    if (this.txtConceptoDeposito === '') {
      this.mensajeAlerta('info', 'Información', 'Error, ingrese el concepto del depósito');
      return false;
    }
    let value = null;
    let dblImporteDeposito = 0;
    value = Number(this.txtImporte).toFixed(2);
    dblImporteDeposito = Number(value);

    if (dblImporteDeposito === 0) {
      this.mensajeAlerta('info', 'Información', 'Error, No puede realizar el depósito con un valor igual a cero');
      return false;
    }

    return true;
  }


  async verificarConcilia() {
    let conciliado;
    try {

      conciliado = await this.trnTarjetaService.encontrarTrnBanConcilia(this.txtComprobante);

      if (conciliado !== null) {
        if (Number(conciliado[0].TRNBAN_CONCILIA) === 1) {
          this.mensajeAlerta('info', 'Información', 'Registro conciliado. Realice la desconciliación.');
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
    return true;
  }

  buscarTarjeta(parametro) {
    this.opcion = 'BuscarTarjeta';
    this.types = [
      { label: 'NÚMERO', value: 'TAR_CODIGO' },
      { label: 'DESCRIPCIÓN', value: 'TAR_DESCRIPCION' },
      { label: '%', value: 'TAR_PORCENTAJE' },
      { label: 'R.C.COMISIÓN', value: 'CON_CODIGO' },
      { label: 'RET.FUENTE', value: 'CON_CODIGORFTE' },
      { label: 'RET.IVA', value: 'CON_CODIGOFIVA' },
      { label: 'RUC', value: 'TAR_RUC' },
      { label: 'CODSRI', value: 'TAR_CODSRI' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', ''];
    this.consulta = 'TAR_codigo , TAR_descripcion , TAR_porcentaje, CON_codigo,con_codigocom,con_codigorfte, ' +
      ' con_codigofiva,tar_ruc ,tar_codsri';
    this.tabla = 'cxc_maetarjeta';
    this.where = ' TAR_codigo is not null order by TAR_codigo';

    this.trnTarjetaService.encontrarTarjeta(parametro).subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.manejarSeleccion(eR[0]);
          } else {
            this.busquedaSer.busquedaCxcMaeTarjeta2().subscribe((res: any[]) => {
              this.arregloCons = res;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busquedaSer.busquedaCxcMaeTarjeta2().subscribe((res: any[]) => {
            this.arregloCons = res;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  buscarBanco(parametro) {
    this.opcion = 'BuscarBanco';
    this.types = [
      { label: 'CÓDIGO', value: 'BAN_CODIGO' },
      { label: 'BANCO', value: 'BAN_BANCO' },
      { label: 'CUENTA NRO', value: 'BAN_CTANRO' },
      { label: 'JEFE CUENTA', value: 'BAN_JEFECTA' },
      { label: 'SUCURSAL', value: 'BAN_SUCURSAL' },
      { label: 'DIRECCIÓN', value: 'BAN_DIRECCION' },
      { label: 'TELEFONO1', value: 'BAN_TELEFONO1' },
      { label: 'REF. CONTABLE', value: 'CON_CODIGO' },
      { label: 'CEN_CODIGO', value: 'CEN_CODIGO' },
      { label: 'TIPO DE CUENTA', value: 'BAN_TIPOCUENTA' },
      { label: 'DOC. POR PAGAR', value: 'CON_CODIGO_2' },
      { label: 'PROCESO', value: 'BAN_PROCESO_CM' },
      { label: 'DIRECTORIOCM', value: 'BAN_DIRECTORIO_CM' },
      { label: 'DINEROELEC', value: 'BAN_DINEROELEC' },
      { label: 'TELCEL', value: 'BAN_TELEFONOCEL' },
      { label: 'ULT. CHEQUE', value: 'BAN_ULTIMOCHEQUE' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = 'BAN_codigo,BAN_banco, NVL(BAN_ctanro, \'*\') AS BAN_ctanro, BAN_jefecta,BAN_sucursal,' +
      ' BAN_direccion, BAN_telefono1, NVL(CON_codigo, \'\') as CON_codigo, CEN_CODIGO,BAN_tipocuenta,' +
      ' con_codigo_2, ban_proceso_cm, ban_directorio_cm, ban_dineroelec, ban_telefonocel,BAN_ULTIMOCHEQUE';
    this.tabla = 'BAN_MAEBAN';
    this.where = '';

    this.trnTarjetaService.encontrarBanco(parametro).subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.manejarSeleccion(eR[0]);
          } else {
            this.busquedaSer.busquedaBanMaeBan6().subscribe((res: any[]) => {
              this.arregloCons = res;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busquedaSer.busquedaBanMaeBan6().subscribe((res: any[]) => {
            this.arregloCons = res;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  async extraePorcentajeTarjeta(strCodTarjeta) {
    let strPorcentaje = '';
    this.txtPorcentaje = '';
    try {
      const rs = await this.trnTarjetaService.encontrarTarPorcentaje(strCodTarjeta);
      strPorcentaje = rs[0].TAR_PORCENTAJE;
      this.txtPorcentaje = strPorcentaje;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso desplegar porcentaje no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async listarDatos() {
    let dblImporte = 0;
    let intIndiceLista = 0;

    try {
      const detalle: BanDetTrnTarjeta[] = [];
      const rs = await this.trnTarjetaService.listarDatos(this.txtCodTarjeta);
      console.log(rs);
      this.detDepositoTarjeta = [];
      if (rs !== null) {
        for (const dato of rs) {
          const item: BanDetTrnTarjeta = {};

          dblImporte = dato.TRNTARJETADET_IMPORTE === 0 ? 0 : dato.TRNTARJETADET_IMPORTE;
          item.TRNTARJETADET_IMPORTE = Number(dblImporte).toFixed(2);
          item.COM_CODIGO = dato.COM_CODIGO === null ? '' : dato.COM_CODIGO;
          item.CAJ_CODIGO = dato.CAJ_CODIGO === null ? '' : dato.CAJ_CODIGO;
          item.CLI_CODIGO = dato.CLI_CODIGO === null ? '' : dato.CLI_CODIGO;
          item.CLI_NOMBRE = dato.CLI_NOMBRE === null ? '' : dato.CLI_NOMBRE;
          item.SELECCION = false;
          item.TRNCAJ_DOCNRO = dato.TRNCAJ_DOCNRO === null ? '' : dato.TRNCAJ_DOCNRO;
          item.TRNCAJ_FECHA = dato.TRNCAJ_FECHA === null ? '' : dato.TRNCAJ_FECHA;
          if (item.TRNCAJ_FECHA.toString().includes('-') && item.TRNCAJ_FECHA !== '') {
            item.TRNCAJ_FECHA = this.datePipe.transform(item.TRNCAJ_FECHA, 'dd/MM/yyyy');
          }
          item.TRNCAJ_NUMERO = dato.TRNCAJ_NUMERO === null ? '' : dato.TRNCAJ_NUMERO;
          item.TRNCAJ_TIPO = dato.TRNCAJ_TIPO === null ? '' : dato.TRNCAJ_TIPO;
          item.TRNTARJETA_CODIGO = 0;
          item.TRNTARJETADET_NROSEC = 0;

          // this.detDepositoTarjeta.push(item);
          detalle.push(item);
          intIndiceLista = intIndiceLista + 1;
        }
        this.detDepositoTarjeta = detalle;
        //  this.aggrid.refreshColumnDefs();
        //   this.aggrid.refreshaggrid(this.detDepositoTarjeta, this.defaultColBanTarjeta.objeto);
      } else {
        this.detDepositoTarjeta = [];
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso mostrar vouchers no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async listarDatosConciliacion() {
    let dblImporte = 0;
    let intIndiceLista = 0;

    try {
      const detalle: BanDetModeloConciliacion[] = [];
      const rs = await this.trnTarjetaService.listarDatosConcil();
      if (rs.length > 0) {
        for (const dato of rs) {
          const item: BanDetModeloConciliacion = {};
          dblImporte = 0;
          dblImporte = dato.TRNTARJETA_COMISION === 0 ? 0 : dato.TRNTARJETA_COMISION;
          item.TRNTARJETA_COMISION = Number(dblImporte).toFixed(2);

          item.TRNTARJETA_NRORECAP = dato.TRNTARJETA_NRORECAP === null ? '' : dato.TRNTARJETA_NRORECAP;
          item.TRNTARJETA_FECHATRN = dato.TRNTARJETA_FECHATRN === null ? '' : dato.TRNTARJETA_FECHATRN;
          if (item.TRNTARJETA_FECHATRN.toString().includes('-') && item.TRNTARJETA_FECHATRN !== '') {
            item.TRNTARJETA_FECHATRN = this.datePipe.transform(item.TRNTARJETA_FECHATRN, 'dd/MM/yyyy');
          }
          item.CONCIL_SELECT = false;

          dblImporte = 0;
          dblImporte = dato.TRNTARJETA_TOTIMPORTE === 0 ? 0 : dato.TRNTARJETA_TOTIMPORTE;
          item.TRNTARJETA_TOTIMPORTE = Number(dblImporte).toFixed(2);

          dblImporte = 0;
          dblImporte = dato.TRNTARJETA_RETENCION === 0 ? 0 : dato.TRNTARJETA_RETENCION;
          item.TRNTARJETA_RETENCION = Number(dblImporte).toFixed(2);

          item.TRNTARJETA_CODIGO = dato.TRNTARJETA_CODIGO === null ? '' : dato.TRNTARJETA_CODIGO;

          dblImporte = 0;
          dblImporte = dato.TRNTARJETA_IMPCOBRADO === 0 ? 0 : dato.TRNTARJETA_IMPCOBRADO;
          item.TRNTARJETA_IMPCOBRADO = Number(dblImporte).toFixed(2);

          item.TRNTARJETA_VOUCHERS = dato.TRNTARJETA_VOUCHERS === null ? '' : dato.TRNTARJETA_VOUCHERS;
          item.CONCIL_SELECT = false;
          // this.detModeloConciliacion.push(item);
          detalle.push(item);
          intIndiceLista = intIndiceLista + 1;
        }

        this.detModeloConciliacion = detalle;
        // this.aggrid.refreshColumnDefs();
        // this.aggrid.refreshaggrid(this.detModeloConciliacion, this.defaultColBanTarConcil.objeto);
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso mostrar Conciliaciones no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  selectAll(e) {
    if (e.checked) {
      this.detDepositoTarjeta.map(item => {
        item.SELECCION = true;
      });
      this.calcularImporteTotal();
      this.aggrid.refreshaggrid(this.detDepositoTarjeta, this.defaultColBanTarjeta.objeto);
    }
    if (!e.checked) {
      this.detDepositoTarjeta.map(item => {
        item.SELECCION = false;
      });
      this.calcularImporteTotal();
      this.aggrid.refreshaggrid(this.detDepositoTarjeta, this.defaultColBanTarjeta.objeto);
    }
  }

  seleccionItemTarjeta(valor) {
    //GSRF  
    if(this.editarCeldas === true){
      return;
    }else{
//GSRF
    const index = this.detDepositoTarjeta.findIndex(i => i.TRNCAJ_NUMERO === valor.TRNCAJ_NUMERO);

    if (this.detDepositoTarjeta[index].SELECCION === true) {
      if (this.strControlAbrirDoc === '0') {
        this.activaBotones('M', true, false, false, true, true, true,
          true, true, true, true, true);
        this.calcularImporteTotal();
      }
    }
    if (this.detDepositoTarjeta[index].SELECCION === false) {
      if (this.strControlAbrirDoc === '0') {
        this.activaBotones('M', true, false, false, true, true, true,
          true, true, true, true, true);
        this.calcularImporteTotal();
      }
    }
  }
  }

  seleccionItemConcil(valor) {
    const index = this.detModeloConciliacion.findIndex(i => i.TRNTARJETA_NRORECAP === valor.TRNTARJETA_NRORECAP);

    if (this.detModeloConciliacion[index].CONCIL_SELECT === true) {
      if (this.strControlAbrirDoc === '0') {
        this.activaBotones('M', true, false, false, true, true, true,
          true, true, true, true, true);
        this.calcularImporteTotalConcil();

      }
    }
    if (this.detModeloConciliacion[index].CONCIL_SELECT === false) {
      if (this.strControlAbrirDoc === '0') {
        this.activaBotones('M', true, false, false, true, true, true,
          true, true, true, true, true);
        this.calcularImporteTotalConcil();
      }
    }
  }

  cancelarRegistro() {
    if (this.selectTab === 0) { // Generales

      if (this.txtTrnTarjetaCodigo !== '') {
        this.mostrarDatos(Number(this.txtTrnTarjetaCodigo));
      } else {
        this.limpiarDatos();
        this.detDepositoTarjeta = [];
        this.activaBotones('INI', false, true, true, true, false, false,
          false, false, false, true, true);
      }
    }

    if (this.selectTab === 1) { // Deposito
      if (this.lblAsiento !== '') {
        this.auxiliar = '0';
        this.mostrarDatosDeposito(this.txtCodBanco, 'DP', this.txtComprobante);
      } else {
        this.limpiarDatosDeposito();
        this.detModeloConciliacion = [];
        this.activaBotones('INI', false, true, true, true, false, false,
          false, false, false, true, true);
      }

    }
  }

  voucherSeleccionado(): string {
    let intIndiceLista = 0;
    let item: BanDetTrnTarjeta = {};
    let strVoucherSeleccionados = '';

    for (intIndiceLista = 0; intIndiceLista < this.detDepositoTarjeta.length; intIndiceLista++) {
      item = this.detDepositoTarjeta[intIndiceLista];
      if (item.SELECCION === true) { // si esta seleccionado
        strVoucherSeleccionados = strVoucherSeleccionados + '/' + item.TRNCAJ_NUMERO;
      }

    } // fin de for

    return strVoucherSeleccionados;
  }

  async eliminarRegistro() {

    if (this.selectTab === 0) { // generales
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERELIMACION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ' , no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }

    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'depositoTarjetaconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmarEliminarRegistro();
      },
      reject: () => {
      }
    });

  }

  async confirmarEliminarRegistro() {

    if (this.txtTrnTarjetaCodigo === '') {
      this.mensajeAlerta('info', 'Información', 'Seleccione el registro a Eliminar (Registro)');
      return;
    }

    if (this.lblAsientoRet !== '') {
      if (await this.eliminarAsientoContable(this.lblAsientoRet) === false) {
        return;
      }
    }

    try {
      await this.trnTarjetaService.eliminartrnTarjeta(Number(this.txtTrnTarjetaCodigo));
      this.auditoriagral.registrarAuditoria('BAN_TRNTARJETA', this.txtTrnTarjetaCodigo, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      this.limpiarDatos();

      this.detDepositoTarjeta = [];
      this.trnTarjetaService.detModeloRetencion[this.indicador] = [];
      this.trnTarjetaService.detModeloVarios[this.indicador] = [];
      this.activaBotones('INI', false, true, true, true, false, false, false,
        false, false, true, true);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('err', 'Error', '! Proceso eliminar datos no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async eliminarAsientoContable(strAsiNro) {
    try {
      await this.trnTarjetaService.eliminarAsientoContable(strAsiNro);
      this.auditoriagral.registrarAuditoria('CON_ENCASI_L', strAsiNro, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar asiento no terminó satisfactoriamente.... ! ' + mensaje);
      return false;
    }
    return true;
  }

  async eliminarDeposito() {
    if (this.selectTab === 0) { // generales
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERELIMACION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ' , no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }

    if (await this.verificarConcilia() === false) {
      return;
    }

    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'depositoTarjetaconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmarEliminarDeposito();
      },
      reject: () => {
      }
    });

  }

  async confirmarEliminarDeposito() {
    if (this.lblAsiento === '' && this.selectTab === 1) {
      this.mensajeAlerta('info', 'error', 'Seleccione el registro a Eliminar');
      return;
    }

    if (this.txtCodBanco !== '') {
      if (await this.actualizaNullTransaccionesTarjeta() === true) {
        if (await this.eliminarAsientoContable(this.lblAsiento) === true) {
          if (await this.eliminarTrnBancaria() === true) {
            this.limpiarDatosDeposito();
            this.detModeloConciliacion = [];
            this.activaBotones('INI', false, true, true, true, false, false,
              false, false, false, true, true);
          }
        }
      }
    }
  }

  async eliminarTrnBancaria() {

    try {
      await this.trnTarjetaService.eliminarBancoMovimiento(this.txtCodBanco, 'DP', this.txtComprobante);
      this.auditoriagral.registrarAuditoria('BAN_TRNBAN', this.txtCodBanco + '/DP/' + this.txtComprobante, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso eliminar transacción bancaria no terminó satisfactoriamente.... ! ' + mensaje);
      return false;
    }

    return true;
  }

  cambio(event) {
    if (this.lblDepConciliacion === '') {
      this.btnGuardar = false;
      this.btnRegresar = false;
      this.btnNuevo = true;
      // this.btnNuevoGrid = false;
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
    }
  }

  async verificarVisulizacionAsiento() {
    const config = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CRLVISASI').CFG_VALOR1;
    const bolCRLVISASI = config === 0 ? false : true;

    if (bolCRLVISASI === false) {
      return true;
    }

    let bolVisAsi = false;
    this.claveDialog = true;

    if (await this.comprobarClave() === false) {
      this.mensajeAlerta('info', 'Información', 'Debe ingresar correctamente la clave para visualizar el asiento.');
      bolVisAsi = false;
    } else {
      bolVisAsi = true;
    }

    return bolVisAsi;
  }

  async comprobarClave() {
    try {
      const clave = await this.trnTarjetaService.obtenerClave();
      let claveReal;
      if (Object.keys(clave).length > 0) {
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.claveAuth = '';
          return true;
        } else if (clave !== this.claveAuth) {
          this.claveDialog = false;
          this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
          return false;
        }
      } else {
        this.claveDialog = false;
        this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
        return false;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', 'No se puede validar la clave' + mensaje);
    }
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
}
