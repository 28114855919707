import { Component, OnInit } from '@angular/core';
import { ConasidescuadreService } from '../conservicios/conasidescuadre.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import { ConAsiDes, ConDocSinAsi } from '../coninterfaces/conasidescuadre';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-conasidescuadre',
  templateUrl: './conasidescuadre.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ConasidescuadreComponent implements OnInit {
  btnvisto:boolean;
  tablevis:boolean;
  dateDesde:Date;
  dateHasta:Date;
  txtAsientos:string;
  txtComprobante:string;
  spin:boolean//CDPJ
  //GSRF
  txtidreferencia:string;
  txtnomreferencia:string;
  //GSRF
  fecha1:string;
  fecha2:string;
  strOptionSelect;
  opcion=[{label:''},{label:'01. Asientos descuadrados'},{label:'02. Documentos sin asiento'}];
  /*Basic Table*/
  public frameworkComponents;
  rowStyle;
  largo = '340';
  rowSelection = 'multiple';
  /*Tabla 1*/
  defaultColDefConAsiDesT;
  conAsiDesT:ConAsiDes[]=[];
  selectedConAsiDest:ConAsiDes;
  columnDefConAsiDesT=[
    {
      headerName: 'Asiento', field: 'ASIENTO', cellEditor: 'cellPrueba', width: 125, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas:false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellEditor: 'cellPrueba', width: 80, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas:false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10,
            tienecalculos: false,
          }
        }
    },
    {
      headerName: 'Fecha', field: 'ASI_FECHA', cellEditor: 'cellPrueba', width: 100, editable:false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    },
     {
      headerName: 'Tipo Diario', field: 'TIPDIA_CODIGO', cellEditor: 'cellPrueba', width: 80, editable:false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    },
     {
      headerName: 'Comprobante', field: 'ASI_COMPROBANTE', cellEditor: 'cellPrueba', width: 100, editable:false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    },
     {
      headerName: 'Origen', field: 'ASI_ORIGEN', cellEditor: 'cellPrueba', width: 80, editable:false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    },
    {
     headerName: 'ID Ref', field: 'ID_REF', cellEditor: 'cellPrueba', width: 100, editable:false,
     cellEditorParams:
       {
         values: {
           mayusculas: true,
           numeros: true,
           alphabetic: true,
           saltoslinea: 1,
           newrow: false,
           numerodecimales: 0,
           obligatorio: true,
           fecha: false,
           tamanocelda: 30,
           tienecalculos: false,
         }
       }
   },
   {
    headerName: 'Referencia', field: 'NOM_REF', cellEditor: 'cellPrueba', width: 300, editable:false,
    cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30,
          tienecalculos: false,
        }
      }
  }
  ]; 
  /*Tabla 2*/ 
  defaultColDefConAsiDesD;
  conAsiDesD:ConDocSinAsi[]=[];
  selectedConAsiDestD:ConDocSinAsi;
  columnDefConAsiDesD=[
  
    {
      headerName: 'Fecha', field: 'FECHA', cellEditor: 'cellPrueba', width: 100, editable:false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    },
     {
      headerName: 'Tipo Diario', field: 'AFC', cellEditor: 'cellPrueba', width: 100, editable:false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    },
     {
      headerName: 'Comprobante', field: 'ENCFACPRO_NUMERO', cellEditor: 'cellPrueba', width: 200, editable:false,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    },
     {
      headerName: 'Origen', field: 'ORIGEN', cellEditor: 'cellPrueba', width: 100, editable:true,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 30,
            tienecalculos: false,
          }
        }
    },
    {
     headerName: 'ID Ref', field: 'ID_REF1', cellEditor: 'cellPrueba', width: 100, editable:false,
     cellEditorParams:
       {
         values: {
           mayusculas: true,
           numeros: true,
           alphabetic: true,
           saltoslinea: 1,
           newrow: false,
           numerodecimales: 0,
           obligatorio: true,
           fecha: false,
           tamanocelda: 30,
           tienecalculos: false,
         }
       }
   },
   {
    headerName: 'Referencia', field: 'NOM_REF1', cellEditor: 'cellPrueba', width: 300, editable:false,
    cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30,
          tienecalculos: false,
        }
      }
  }
  ];
  constructor(private conasidesSer:ConasidescuadreService,private init: NuevoComponentService,
              private messageService: MessageService,
              private errorService: ErroresBaseDatosService,
              private confirmationService: ConfirmationService,
              private datePipe: DatePipe,
              private auditoriaGralService: AuditoriagralService ) {
                
               }

  ngOnInit(): void {
    //this.btnvisto=false;
    this.tablevis=true;
    this. agTable() ;
    this.dateDesde=new Date();
    this.dateHasta=new Date();
    this.fecha1=this.conasidesSer.parseDate(this.dateDesde);
    this.fecha2=this.conasidesSer.parseDate(this.dateHasta);
    this.spin=false;
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefConAsiDesT = {
      editable: true,
      width: 130,
      objeto: 'tConAsiDesT'
    };
    this.defaultColDefConAsiDesD = {
      editable: true,
      width: 130,
      objeto: 'tConAsiDesD'
    };
    
  }
  manejarSenales(valor){
    switch (valor) {
      case 'Visto':
        this.spin=true;
        this.accionVisto();
       
        break;
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
        break;
    
    }
  }
  cambio(elemento){
    
    this.fecha1=this.conasidesSer.parseDate(this.dateDesde);
    
  }
  enviar(elemento){}
  cambioOption(elemento){
  }
  cambioHasta(elemento){
    
    this.fecha2=this.conasidesSer.parseDate(this.dateHasta);
    
  }
  cambiarFoco2(elemento){
   
  }
  cambioDefConAsiDesT(elemento){

  }
  cambioDefConAsiDesD(elemento){

  }
  selectDefConAsiDesT(elemento){
    if (elemento.object !== null) {
      this.selectedConAsiDest=elemento.object;
      
      this.txtAsientos=this.selectedConAsiDest.ASIENTO;
      this.txtComprobante=this.selectedConAsiDest.ASI_COMPROBANTE;
      //GSRF
      this.txtidreferencia=this.selectedConAsiDest.ID_REF;
      this.txtnomreferencia=this.selectedConAsiDest.NOM_REF;  
      //GSRF
    }
  }
  selectDefConAsiDesD(elemento){
    if (elemento.object !== null) {
      this.selectedConAsiDestD=elemento.object;
      
      this.txtAsientos="";
      this.txtComprobante=this.selectedConAsiDestD.ENCFACPRO_NUMERO;
      //GSRF
      this.txtidreferencia=this.selectedConAsiDestD.ID_REF1;
      this.txtnomreferencia=this.selectedConAsiDestD.NOM_REF1;
      //GSRF
    }
  }
  async accionVisto(){
    
    this.txtAsientos="";
    this.txtComprobante="";
    this.txtidreferencia="";
      this.txtnomreferencia="";
    if(this.strOptionSelect==undefined || this.strOptionSelect==''){
      
      this.mensajeAlerta('error', 'Error', 'Selecione un tipo');
      this.spin=false
      return;
    };
    if(this.dateDesde>this.dateHasta){    
      this.mensajeAlerta('error', 'Error', 'La fecha incial es mayor a la fecha final');
      this.spin=false
      return;
    }else{
     
      if(this.strOptionSelect.label=="01. Asientos descuadrados"){
        this.conasidesSer.consultarListar1(this.fecha1, this.fecha2).then(res=>{      
          this.conAsiDesT=res;
          this.conAsiDesT.map(x=>{
            if( x.ASI_FECHA==null ||  x.ASI_FECHA==undefined){
               x.ASI_FECHA='';
            }else{
              if( x.ASI_FECHA.includes('-')){
                x.ASI_FECHA=this.datePipe.transform( x.ASI_FECHA, 'dd/MM/yyyy');
              }else{
                x.ASI_FECHA='';
              }
            } 

          });
          this.spin=false
          this.tablevis=true;
        });

      }else if(this.strOptionSelect.label=="02. Documentos sin asiento"){
      //   this.conasidesSer.consultarListar2(this.fecha1, this.fecha2).then(res=>{
      //   this.conAsiDesD=res;
      //   this.conAsiDesD.map(x=>{
      //     if( x.FECHA==null ||  x.FECHA==undefined){
      //       x.FECHA;
      //    }else{
      //      if( x.FECHA.includes('-')){
      //       x.FECHA=this.datePipe.transform( x.FECHA, 'dd/MM/yyyy');
      //      }else{
      //       x.FECHA='';
      //      }
      //    } 
      //   });
      //   this.tablevis=false;
      //  })
      //CDPJ
      const a=await this.conasidesSer.llenarListar2(this.fecha1, this.fecha2);
      this.conasidesSer.consultarListar2(this.fecha1, this.fecha2).then(res=>{
        console.log(res)
        if(res !== null || res !== undefined){
          this.conAsiDesD=res;
          this.conAsiDesD.map(x=>{
            if( x.FECHA==null ||  x.FECHA==undefined){
              x.FECHA;
           }else{
             if( x.FECHA.includes('-')){
              x.FECHA=this.datePipe.transform( x.FECHA, 'dd/MM/yyyy');
             }else{
              x.FECHA='';
             }
           } 
          });
          this.tablevis=false;  
        }else{
          this.mensajeAlerta('error', 'Error', 'No se han encontrado documentos sin asiento');
          this.spin=false
        }  
     }).catch(e => {
      this.mensajeAlerta('error', 'Error', 'No se han encontrado documentos sin asiento');
      this.spin=false
     return
    });
    this.spin=false
      //CDPJ
      }
        
    }

  }
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'conasidestoast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
    
  }


}
