
// SACI WEB
// Rutina: Maestro de Artículos
// Propietario: Victor Perez
// Modificado por: VP
// Fecha de creación: 18-06-2019
// Fecha de Modificación: 09-12-2019
import { VenmaeclienteService } from 'src/app/ven/venservicios/venmaecliente.service'
import { ComencfacproService } from 'src/app/com/comservicios/comencfacpro.service'
import { Component, OnInit, ViewChild } from '@angular/core';
import { InvMaearticulo } from '../invinterfaces/invmaearticulo';
import { InvmaearticuloService } from '../invservicios/invmaearticulo.service';
import { NombresCuentas } from '../invinterfaces/nombrescuentas';
import { InvbusquedaService } from '../invservicios/invbusqueda.service';
import { MessageService, SelectItem, TreeNode } from 'primeng/api';
import { InvMaeartprecio } from '../invinterfaces/invmaeartprecio';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ProdMaeartparte } from '../invinterfaces/prodmaeartparte';
import { ProdMaeartformula } from '../invinterfaces/prodmaeartformula';
import { InvMaeesptec } from '../invinterfaces/invmaeesptec';
import { InvExistenciabod } from '../invinterfaces/invexistenciabod';
import { InvTrnkardexp } from '../invinterfaces/invtrnkardexp';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { CalculosServiceService } from '../../servicios/calculos-service.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { Invmaeprofab } from "../invinterfaces/invmaeprofab";
import { Invmaeprocont } from "../invinterfaces/invmaeprocont";
// Ag-grid
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ProdMaecostoestand } from '../invinterfaces/prodmaecostoestand';
import { DatePipe } from '@angular/common';
import { Invprodtrncomqui } from '../invinterfaces/invprodtrncomqui';
import { ProdTrnAplicacion } from '../invinterfaces/prodtrnaplicacion';
import { Invmaecomart } from '../invinterfaces/invmaecomart';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';

import { Usuario } from '../../usuario';
import { UploadfilesService } from 'src/app/advantage/advantageservices/uploadfiles.service';

@Component({
  selector: 'app-invmaearticulo',
  templateUrl: './invmaearticulo.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})

export class InvmaearticuloComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  indicador: any;

  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;

  maearticulo: InvMaearticulo = {};
  trnkardexp: InvTrnkardexp[];
  maeesptec: InvMaeesptec = {};

  precioSeleccionado: InvMaeartprecio;
  parteSeleccionada: ProdMaeartparte;
  formulaSeleccionada: ProdMaeartformula;
  existbodSeleccionada: InvExistenciabod;
  costoestanSeleccionado: ProdMaecostoestand;
  comquimicaSeleccionada: Invprodtrncomqui;
  aplicacionSeleccionada: ProdTrnAplicacion;
  descripcionSeleccionada: Invmaecomart;
  profabricaSeleccionada: Invmaeprofab;
  procontrolSeleccionada: Invmaeprocont;
  nombrescuentas: NombresCuentas = {};
  articulos: any[];

  // chks
  chkactivo: boolean;
  chkcompuesto: boolean;
  chktributaiva: boolean;
  chkactivarserialfac: boolean;
  chktributaice: boolean;
  chkbnobojiva: boolean;
  chkbtnservicio: boolean;
  chkmultiunidad: boolean;
  chkcaduca: boolean;
  chkactivarserial: boolean;
  chkactivardim: boolean;
  chkactivartelefono: boolean;
  chkmateriaprima: boolean;
  chkproducido: boolean;

  // dialogs
  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  displayGrupoNiveles: boolean;
  displayDialogDocumentos = false;

  types: SelectItem[];
  arregloCons: any[];
  opcionbusqueda: string;

  index: number;
  indexunidad: number;
  busquedacampos: string[];
  tabla: string;

  codactiv: boolean;
  // formula: string;
  busqkar: string;
  // formulas: any[];
  // selectformula: any;
  //CDPJ
  CODAUTGRU = false;
  bolkardex = false;//CDPJ
  bolkardexGr: boolean//CDPJ
  oculto = false;
  displayDialogactiva = false;
  tariivacod = '';
  tariivaporcentaje: string;
  tariivadescripcion = '';
  displayDialogBusquedaFasttariva = false;
  claveDialog = false;
  accion = ''
  claveAuth = '';
  //CDPJ
  decimalesConfigVenENCFAC = [];
  decimalesInvMaeArticulo = [];

  ultimoProveComp: string;
  ultimoCostoProd: string;
  existencia: string;
  Toten: string;
  Totsa: string;

  cmbkardex: any[];
  cmbkardexaux: any[];

  // Busqueda
  where = '';

  // Codigos alternos
  displayAcciones: boolean;
  displayDialogCodAlt = false;

  bolnuevo: boolean;

  // Ficha Técnica
  displayDialogFichaT = false;
  artCodigoFicha = '';
  artNombreFicha = '';

  txtNumArtCosEst = '';
  largoDescrip: string;
  strTipoCateg: any;
  cmbTiposCateg: any[];
  // AG-GRID
  // Columnas del ag grid

  // Tipo Kardex
  cmbTipoKardex: any[];
  strTipoKardex: any;
  intCONCOSFEC = 0;
  GRUNIVELES = false;

  strCantMatPrima = '';

  gruniv: TreeNode[];

  // dropdowns
  opcionesTITULO: SelectItem[];
  opcionesAUTOR: SelectItem[];
  opcionesEDITORIAL: SelectItem[];
  opcionesORIGEN: SelectItem[];
  opcionesTAMANIO: SelectItem[];
  opcionesENCUADERNACION: SelectItem[];

  columnDefsPrecio = [
    { // 0
      headerName: 'Código', field: 'ARTPRE_CODIGO', cellEditor: 'agSelectCellEditor',
      editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams: {
        values: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      }
    },
    { // 1
      headerName: 'Unidad', field: 'UNI_CODIGO', cellEditor: 'cellPrueba', editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          clave: false,
          tamanocelda: 10,
        }
      }
    },
    { // 2
      headerName: 'Precio', field: 'ARTPRE_PRECIO', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true,
        }
      }
    },
    { // 3
      headerName: 'Costo', field: 'ARTPRE_COSTO', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true,
        }
      }
    },
    { // 4
      headerName: 'Util.D(%)', field: 'ARTPRE_PORCENTAJE', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true,
        }
      }
    },
    { // 5
      headerName: 'Dsc.(%)', field: 'ARTPRE_DESCUENTO', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 6
      headerName: 'Cod.Barras', field: 'ARTPRE_CODBARRA', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: false,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 25,
        }
      }
    },
    { // 7
      headerName: 'DescF(*)', field: 'ARTPRE_DESCF', editable: true, cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 8
      headerName: 'DescP(*)', field: 'ARTPRE_DESCP', editable: true, cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 9
      headerName: ' PrecioFpvp', field: 'ARTPRE_PRECIOFPVP', editable: true, cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
  ];

  columnDefsComponente = [
    { // 0
      headerName: 'Código', field: 'ART_CODIGOP', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 25
        }
      }
    },
    { // 1
      headerName: 'Nombre', field: 'ART_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          obligatorio: false,
          fecha: false,
          tamanocelda: 80
        }
      }
    },
    { // 2
      headerName: 'Unidad', field: 'UNI_CODIGO', cellEditor: 'cellPrueba',
      editable: (params) => {
        console.log('-*-*-', params)
        if (params.data.ART_MULTIUNI === 'S') {
          return true;
        } else if (params.data.ART_MULTIUNI === 'N') {
          return false;
        }

      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 10
        }
      }
    },
    { // 3
      headerName: 'Cantidad', field: 'ARTPARTE_CANTIDAD', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 4
      headerName: 'Precio Lst.', field: 'ARTPRE_PRECIO', cellEditor: 'cellPrueba', editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32
        }
      }
    }
  ];

  columnDefsKardex = [
    {
      headerName: 'Fecha', field: 'TRNART_FECHAES'
    },
    {
      headerName: 'Hora', field: 'TRNART_HORA'
    },
    {
      headerName: 'Bodega', field: 'BOD_CODIGO'
    },
    {
      headerName: 'Origen', field: 'TRNART_ORIGEN'
    },
    {
      headerName: 'Documento', field: 'TRNART_REFERENCIA', width: 200,
    },
    {
      headerName: 'T.', field: 'TRNART_TIPO'
    },
    {
      headerName: 'Entradas', field: 'TRNART_CANTIDAD_E', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Salidas', field: 'TRNART_CANTIDAD_S', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Costo Uni.', field: 'TRNART_COSTO_UNITARIO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Costo Tota', field: 'TRNART_COSTOT', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo Uni', field: 'TRNART_CANTIDAD_SALDO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Costo Pro', field: 'TRNART_COSTOPROM', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Total', field: 'TRNART_COSTOT_SALDO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Número', field: 'TRNART_NUMERO'
    },
  ];

  columnDefsFormula = [
    { // 0
      headerName: 'Cód.Form.', field: 'FOR_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10
        }
      }
    },
    { // 1
      headerName: 'Cant.Pro', field: 'FOR_CANTTOTAL', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },

    { // 2
      headerName: 'Código', field: 'ART_CODIGOP', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 25
        }
      }
    },
    { // 3
      headerName: 'Nombre', field: 'ART_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80
        }
      }
    },
    { // 4
      headerName: 'Unidad', field: 'UNI_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 10
        }
      }
    },
    { // 5
      headerName: 'Cantidad', field: 'FOR_CANTIDAD', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 6
      headerName: 'Exist.', field: 'ART_EXISTENCIA', cellEditor: 'cellPrueba', editable: false, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 0
        }
      }
    }

  ];

  columnDefsCostoEst = [
    { // 0
      headerName: 'Costo.Ind.', field: 'CIN_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 5
        }
      }
    },
    { // 1
      headerName: 'Nombre', field: 'CIN_NOMBRE', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80
        }
      }
    },
    { // 2
      headerName: 'Costo Estimado', field: 'CEST_COSTO', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          opcionbuscar: false
        }
      }
    },
  ];

  columnDefsExistbod = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 10
        }
      }
    },
    { // 1
      headerName: 'Ext.Max.', field: 'ARTEXT_MAXIMA', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.calc.getDecimalesConfig('CANTIDAD'),
          obligatorio: true,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 2
      headerName: 'Ext.Min.', field: 'ARTEXT_MINIMA', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 2,
          newrow: false,
          numerodecimales: this.calc.getDecimalesConfig('CANTIDAD'),
          obligatorio: true,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 3
      headerName: 'Ext.Actual', field: 'EXISTENCIAFECHA', cellEditor: 'cellPrueba', editable: false, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: this.calc.getDecimalesConfig('CANTIDAD'),
          obligatorio: false,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 4
      headerName: 'Referencia', field: 'ARTEXT_REFERENCIA', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 20
        }
      }
    },
    { // 5
      headerName: 'Cant.Ord.', field: 'ARTEXT_ORDENAR', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: this.calc.getDecimalesConfig('CANTIDAD'),
          obligatorio: true,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
  ];

  columnDefsComQuimica = [
    { // 0
      headerName: 'Código', field: 'TRNCOMQUI_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 20
        }
      }
    },
    { // 1
      headerName: 'Nombre', field: 'TRNCOMQUI_NOMBRE', cellEditor: 'cellPrueba', editable: false, width: 300
    }
  ];

  columnDefsAplicacion = [
    { // 0
      headerName: 'Código', field: 'TRNAPLI_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 20
        }
      }
    },
    { // 1
      headerName: 'Nombre', field: 'TRNAPLI_NOMBRE', cellEditor: 'cellPrueba', editable: true, width: 300,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 50
        }
      }
    }
  ];

  columnDefsMaecomart = [
    { // 1
      headerName: 'Nombre', field: 'COMART_DESCRIPCION', cellEditor: 'cellPrueba', editable: true, width: 300,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: true
        }
      }
    }
  ];
  //CDPJ
  columnDefsProfab = [
    { // 0
      headerName: 'Etapa', field: 'PROFAB_ETAPA', cellEditor: 'cellPrueba', editable: true, width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: false,
          tamanocelda: 20
        }
      }
    },
    { // 1
      headerName: 'Código', field: 'ART_CODIGO_MP', cellEditor: 'cellPrueba', editable: true, width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 25
        }
      }
    },
    { // 2
      headerName: 'Nombre', field: 'ART_NOMBRE_MP', cellEditor: 'cellPrueba', editable: (params) => {
        return false;
      }, width: 150,

    },
    { // 3
      headerName: 'Descripción', field: 'PROFAB_DESCRIPCION', cellEditor: 'cellPrueba', editable: true, width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 300
        }
      }
    },
    { // 4
      headerName: 'Cantidad', field: 'PROFAB_CANTIDAD', cellEditor: 'cellPrueba', editable: (params) => {
        return false;
      }, width: 80,

    },
    { // 5
      headerName: 'Unidad', field: 'PROFAB_UNIDAD', cellEditor: 'cellPrueba', editable: (params) => {
        return false;
      }, width: 80,

    },
    { // 6
      headerName: 'Hora Inicio', field: 'PROFAB_HORA_INICIO', cellEditor: 'cellPrueba', width: 80,
      editable: (params) => {
        return false;
      },

    },
    { // 7
      headerName: 'Hora Fin', field: 'PROFAB_HORA_FIN', cellEditor: 'cellPrueba', width: 80,
      editable: (params) => {
        return false;
      },

    },
  ];
  columnDefsProcontrol = [
    { // 0
      headerName: 'Prámetro', field: 'PROCONT_PARAMETRO', cellEditor: 'cellPrueba', editable: true, width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: false,
          tamanocelda: 50
        }
      }
    },
    { // 1
      headerName: 'Especif.Mín', field: 'PROCONT_ESP_MINIMA', cellEditor: 'cellPrueba', editable: true, width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: false,
          tamanocelda: 100
        }
      }
    },
    { // 2
      headerName: 'Especif.Máx', field: 'PROCONT_ESP_MAXIMA', cellEditor: 'cellPrueba', editable: true, width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: false,
          tamanocelda: 100
        }
      },
    },
    { // 3
      headerName: 'Método', field: 'PROCONT_METODO', cellEditor: 'cellPrueba', editable: true, width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: false,
          tamanocelda: 20
        }
      }
    },
  ];
  //CDPJ

  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDef;
  defaultColDefParte;
  defaultColDefFormula;
  defaultColDefCostoest;
  defaultColDefExistbod;
  defaultColDefKardex;
  defaultColDefComquim;
  defaultColDefAplicacion;
  defaultColDefMaecomart;
  //CDPJ
  defaultColDefProfab;
  defaultColDefProcont;
  //CDPJ
  largo: string;
  largokardex: string;

  // AG-GRID FIN
  constructor(public artService: InvmaearticuloService, private busqService: InvbusquedaService,
    private messageService: MessageService, private errorService: ErroresBaseDatosService,
    private initConf: ConfInicialesService, private calc: CalculosServiceService, private datePipe: DatePipe,
    private auditoriagral: AuditoriagralService, private init: NuevoComponentService,
    public usuario: Usuario, public uploadFileService: UploadfilesService, public cliService: VenmaeclienteService,
    public encfacproService: ComencfacproService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'maeartprecio',
      resizable: true
    };

    this.defaultColDefParte = {
      editable: true,
      width: 100,
      objeto: 'maeartparte',
      resizable: true
    };

    this.defaultColDefFormula = {
      editable: true,
      width: 100,
      objeto: 'maeartformula',
      resizable: true
    };

    this.defaultColDefCostoest = {
      editable: true,
      width: 200,
      objeto: 'maecostoest',
      resizable: true
    };

    this.defaultColDefExistbod = {
      editable: true,
      width: 100,
      objeto: 'maeexistbod',
      resizable: true
    };

    this.defaultColDefKardex = {
      editable: false,
      width: 100,
      objeto: 'trnkardexp',
      resizable: true
    };

    this.defaultColDefComquim = {
      editable: true,
      width: 100,
      objeto: 'invprodtrncomqui',
      resizable: true
    };

    this.defaultColDefAplicacion = {
      editable: true,
      width: 100,
      objeto: 'invprodtrnaplica',
      resizable: true
    };

    this.defaultColDefMaecomart = {
      editable: true,
      width: 100,
      objeto: 'invmaecomart',
      resizable: true
    };
    this.defaultColDefProfab = {
      editable: true,
      width: 100,
      objeto: 'invmaeprofab',
      resizable: true
    }
    this.defaultColDefProcont = {
      editable: true,
      width: 100,
      objeto: 'invmaeprocont',
      resizable: true
    }
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.largoDescrip = '590';
    this.index = 0;
    this.indexunidad = 0;
    this.codactiv = true;
    this.bolnuevo = false;

    this.barraBotones2 = true;
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.largo = '390';
    this.largokardex = '420';
    this.ultimoProveComp = '';
    this.ultimoCostoProd = '';
    this.Toten = '';
    this.Totsa = '';
    this.limpiartablas();

    const respuesta: any = await this.initConf.getDecimalesP('frmINV_MAEARTICULO');
    this.artService.configDecimales = respuesta;

    this.cmbTipoKardex = [];
    this.cmbTipoKardex.push({ tipo: 'Todo el Historial' }, { tipo: 'Periodo Actual' });

    this.strTipoKardex = this.cmbTipoKardex[0];

    this.columnDefsPrecio[2].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('PRECIO', 'MaePrecio'),
        obligatorio: true,
        fecha: false,
        clave: false,
        tamanocelda: 32,
        tienecalculos: true,

      }
    };
    this.columnDefsPrecio[3].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('COSTO', 'MaePrecio'),
        obligatorio: false,
        fecha: false,
        clave: false,
        tamanocelda: 32,
        tienecalculos: true,
      }
    };
    this.columnDefsPrecio[4].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('UTILIDAD', 'MaePrecio'),
        obligatorio: false,
        fecha: false,
        clave: false,
        tamanocelda: 32,
        tienecalculos: true,

      }
    };
    this.columnDefsPrecio[5].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('DESCUENTO', 'MaePrecio'),
        obligatorio: false,
        fecha: false,
        clave: false,
        tamanocelda: 32,
        tienecalculos: true,
      }
    };
    this.columnDefsPrecio[7].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('DescF', 'MaePrecio'),
        obligatorio: false,
        fecha: false,
        clave: false,
        tamanocelda: 32,
        tienecalculos: true,
      }
    };
    this.columnDefsPrecio[8].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('DescP', 'MaePrecio'),
        obligatorio: false,
        fecha: false,
        clave: false,
        tamanocelda: 32,
        tienecalculos: true,
      }
    };
    this.columnDefsPrecio[9].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: true,
        numerodecimales: this.artService.obtenerConfig('PrecioFpvp', 'MaePrecio'),
        obligatorio: false,
        fecha: false,
        clave: false,
        tamanocelda: 32,
        tienecalculos: true,
      }
    };

    /* */
    this.columnDefsComponente[3].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('CANTIDAD', 'MaeComponentes'),
        obligatorio: false,
        fecha: false,
        tamanocelda: 32
      }
    };
    this.columnDefsComponente[4].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('PRECIO', 'MaeComponentes'),
        obligatorio: false,
        fecha: false,
        tamanocelda: 32
      }
    };

    /* */
    this.columnDefsFormula[1].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('CANTIDAD', 'formula'),
        obligatorio: true,
        fecha: false,
        tamanocelda: 32,
        tienecalculos: true,
      }
    };
    this.columnDefsFormula[5].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('CANTIDAD', 'formula'),
        obligatorio: true,
        fecha: false,
        tamanocelda: 32,
        tienecalculos: true
      }
    };

    /* */
    this.columnDefsCostoEst[2].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 0,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('COSTO', 'MaeCostosEst'),
        obligatorio: true,
        fecha: false,
        tamanocelda: 32,
        tienecalculos: true,
        opcionbuscar: false
      }
    };

    /* */
    this.columnDefsExistbod[1].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('EXIMAX', 'MaeExiBod'),
        obligatorio: true,
        fecha: false,
        tamanocelda: 32
      }
    };
    this.columnDefsExistbod[2].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('EXIMIN', 'MaeExiBod'),
        obligatorio: true,
        fecha: false,
        tamanocelda: 32
      }
    };
    this.columnDefsExistbod[3].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: 2,
        obligatorio: true,
        fecha: false,
        tamanocelda: 32
      }
    };
    this.columnDefsExistbod[5].cellEditorParams = {
      values: {
        mayusculas: false,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.artService.obtenerConfig('CANTORD', 'MaeExiBod'),
        obligatorio: true,
        fecha: false,
        tamanocelda: 32
      }
    };

    this.initConf.getConfiguracion('COSTUTIL').subscribe((respuesta) => {
      if (respuesta[0].CFG_VALOR1 === 1) {
        this.artService.intUtilizarUtilCost = true;
      } else {
        this.artService.intUtilizarUtilCost = false;
      }
    });

    this.initConf.getConfiguracion('CONCOSFEC').subscribe((respuesta) => {
      if (respuesta !== null) {
        if (respuesta[0].CFG_VALOR1 === 1) {
          this.intCONCOSFEC = 1;
        } else {
          this.intCONCOSFEC = 0;
        }
      } else {
        this.intCONCOSFEC = 0;
      }
    });

    this.initConf.getConfiguracion('GRUNIVELES').subscribe((respuesta) => {
      if (respuesta[0].CFG_VALOR1 === 1) {
        this.GRUNIVELES = true;
      } else {
        this.GRUNIVELES = false;
      }
    });
    //CDPJ
    this.initConf.getConfiguracion('CODAUTGRU').subscribe((respuesta) => {
      if (respuesta[0].CFG_VALOR1 === 1) {
        this.CODAUTGRU = true;
        this.codactiv = true;
      } else {
        this.CODAUTGRU = false;
      }
    });
    this.initConf.getConfiguracion('INVHUC').subscribe((respuesta) => {
      if (respuesta[0].CFG_VALOR1 === 1) {
        this.bolkardexGr = true;
      } else {
        this.bolkardexGr = false;
      }
    });
    //CDPJ
    this.cmbkardexaux = [];

    for (const columns of this.columnDefsKardex) {
      this.cmbkardexaux.push({ codigo: columns.headerName, name: columns.headerName });
    }

    setTimeout(() => {
      this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultColDef.objeto, this.columnDefsPrecio);
      this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultColDefParte.objeto, this.columnDefsComponente);
      this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultColDefFormula.objeto, this.columnDefsFormula);
      this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultColDefCostoest.objeto, this.columnDefsCostoEst);
      this.aggrid.refreshColumnDefsIndice(this.indicador, this.defaultColDefExistbod.objeto, this.columnDefsExistbod);
    }, 2000);

    this.listaTiposCategoria();
    await this.initConf.obtenerSrifeTariIva()
  }
  //CDPJ
  ngOnDestroy(): void {
    this.initConf.instanciasArticulos--;
  }
  //CDPJ
  async manejarSenales(valor) {
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    if (valor === 'Nuevo') {
      this.nuevo();
    }
    if (valor === 'Guardar') {
      this.guardar();
    }
    if (valor === 'Borrar') {
      this.eliminar();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
      if (this.maearticulo.ART_CODIGO === null || this.maearticulo.ART_CODIGO === undefined || this.maearticulo.ART_CODIGO === '') {
        document.getElementById('contorno').style.pointerEvents = 'none';
      }
    }
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      this.busquedaarticulo();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    ////------nuevo imagen
    if (valor === 'Almacenardoc') {
      if (this.maearticulo.ART_CODIGO !== '') {
        const usu = await this.initConf.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'art',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    document.getElementById('contorno').style.pointerEvents = 'all';
  }

  nuevoArt() {
    this.maearticulo = {
      ART_CODIGO: '', COM_CODIGO: '01', GRUP_CODIGO: '', GRUP_NOMBRE: '', ART_NOMBRE: '', ART_NOMBREC: '',
      GRUP_CODIGOP: '', GRUP_NOMBREP: '', ART_TRIBUTAIVA: '', ART_TRIBUTAICE: '',
      ART_FECHAING: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      ART_LABORATORIO: '',
      ART_UBICACION: '', ART_MULTIUNIDAD: '', ART_UNIDADVENTA: '', ART_UNIDADCOSTEO: '', ART_CADUCA: '',
      ART_CODIGOALT1: '', ART_CODIGOALT2: '', ART_CODIGOALT3: '', ART_CODIGOALT4: '', ART_LARGO: '',
      ART_ALTURA: '', ART_ANCHO: '', ART_PESO: '', ART_COMPUESTO: '', ART_CANTMIN: '', ART_CANTMAX: '',
      CON_CODIGOACT: '', CON_NOMBREACT: '', CON_CODIGOGAS: '', CON_NOMBREGAS: '', CON_CODIGOING: '',
      CON_NOMBREING: '', CON_CODIGOORD1: '', CON_NOMBREORD1: '', CON_CODIGOORD2: '', CON_NOMBREORD2: '',
      ART_OBSERVACION: '', ART_CODIGOICE: '', ART_MARCA: '', ART_MODELO: '', ART_SERIE: '', ART_VOLUMEN: '',
      PRESENTACION_CODIGO: '', ART_FACTOR: '', ART_FLAG: '', ART_FORMAVTA: '', ART_DESCUENTO: '',
      ART_SERIALFLAG: '', ART_DIASGARANTIA: '', ART_VALORICE: '', ART_ACTIVO: '', ART_COSTOHISTORICO: '', CEN_CODIGO: '',
      CON_CODIGODSCTO: '', CON_NOMBREDSCTO: '', ART_TIPO: '', CON_CODIGOPRODPROC: '', CON_NOMBREPRODPROC: '', ART_PRODUCTOPROD: '',
      CON_CODIGOING2: '', CON_NOMBREING2: '', CON_CODIGODSCTO2: '', CON_NOMBREDSCTO2: '', ART_COSTOPROMEDIO: '',
      ART_FECHAULTCOSTO: '', CON_CODIGOMP: '', CON_NOMBREMP: '', ART_ACTIVARSERIAL: '', ART_ACTIVARDIM: '',
      ART_ACTIVARNUMEROTEL: '', ART_UNIDADCOMPRA: '', ART_FORMSRIVTAS: '', ART_NOMFORMSRIVTAS: '', ART_FORMSRICOM: '',
      ART_NOMFORMSRICOM: '', ART_PORDISES1: '', ART_PORDISES2: '', ART_CAMPANIA: '', ART_CAMTIPO: '',
      ART_COMENTARIO: '', ART_TIPOCATEGORIA: '', ART_BASENOOBJIVA: '', ART_SUBSIDIO: '', ART_UNIPORCAJA: '',
      ART_CODSRIICE: '', ART_DENSIDAD: '', NOMBREUNIDADCOMPRA: '', NOMBREUNIDADVENTA: '', IMP_DESCRIPCION: '',
      PRESENTACION_NOMBRE: '', CAMPANIA_NOMBRE: '', ART_NOMSRIICE: '', ART_LIB_TITULO: '',
      ART_LIB_AUTOR: '', ART_LIB_EDITORIAL: '', ART_LIB_ORIGEN: '', ART_LIB_TAMANIO: '', ART_LIB_ENCUADERNACION: '', ART_PORIGEN: '',
      ART_IRBPNR: '', ART_PORIVA: '', TARIIVA_CODIGO: ''
    };

    this.maeesptec = {
      ART_CODIGO: '', COM_CODIGO: '01', ESPTEC_CODFAB: '', ESPTEC_COSTFAB: 0, ESPTEC_NOMBREIMP: '',
      ESPTEC_PARTIDA: '', ESPTEC_ARANCEL: 0, ESPTEC_ICE: 0, ESPTEC_VOL: 0, ESPTEC_MBOX: 0,
      ESPTEC_FOTO: '', ESPTEC_DATTEC: '', ESPTEC_ADVERTENCIA: '', ESPTEC_ZONADECONTROL: '', ESPTEC_POSICION: '',
      ESPTEC_PAIS: '', ESPTEC_FLUJOLUMINICO: '', ESPTEC_POTENCIA: 0, ESPTEC_VOLTAJEINPUT: '', ESPTEC_VOLTAJEOUTPUT: '',
      ESPTEC_CORRIENTE: '', ESPTEC_CRI: '', ESPTEC_TEMPERATURA: '', ESPTEC_IP: '', ESPTEC_POTENCIATOTAL: 0,
      ESPTEC_ANGULOAPERTURA: '', ESPTEC_FLETE: 0, ESPTEC_SEGURO: 0, ESPTEC_SALVAGUARDA: 0, ESPTEC_ATENUABLE: ''
    };

    //CDPJ
    if (this.CODAUTGRU === true) {
      this.codactiv = true;
    } else {
      this.codactiv = false;
    }
    //CDPJ
    this.chkactivo = true;
    this.chkcompuesto = false;
    this.chktributaiva = false;
    this.chkactivarserialfac = false;
    this.chktributaice = false;
    this.chkbnobojiva = false;
    this.chkbtnservicio = false;
    this.chkmultiunidad = false;
    this.chkcaduca = false;
    this.chkactivarserial = false;
    this.chkactivardim = false;
    this.chkactivartelefono = false;
    this.chkmateriaprima = false;
    this.chkproducido = false;
    this.bolkardex = false;//CDPJ
    this.desplegarCuentasContables();
    this.strTipoCateg = { REF_CODIGO: '', REF_NOMBRE: '' };

    this.opcionesTITULO = [];
    this.opcionesAUTOR = [];
    this.opcionesEDITORIAL = [];
    this.opcionesORIGEN = [];
    this.opcionesTAMANIO = [];
    this.opcionesENCUADERNACION = [];
    this.listardropdowns();
  }


  nuevo() {
    if (this.index === 0 || this.index === 1) {
      this.botonNuevo = true;
      this.botonGuardar = false;
      this.botonBorrar = true;
      this.botonRegresar = false;
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
      this.bolnuevo = true;
      this.limpiartablas();
      this.index = 0;
    }

    if (this.index === 2) {
      // this.aggrid.isEditable(true);
      const precioNuevo: InvMaeartprecio = {
        ART_CODIGO: this.maearticulo.ART_CODIGO,
        COM_CODIGO: '01',
        ARTPRE_CODIGO: '',
        ARTPRE_PRECIO: 0,
        ARTPRE_DESCUENTO: '',
        ARTPRE_PORCENTAJE: 0,
        UNI_CODIGO: '',
        ARTPRE_CODBARRA: '',
        ARTPRE_COSTO: 0,
        ARTPRE_CANTIDADINI: '',
        ARTPRE_CANTIDADFIN: '',
        ARTPRE_DESCF: '',
        ARTPRE_DESCP: '',
        ARTPRE_PRECIOFPVP: '',
        NUEVO: true
      };
      const indi = this.artService.maeartprecio.indexOf(this.precioSeleccionado);
      if (indi === -1) {
        precioNuevo.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');

        this.artService.maeartprecio.push(precioNuevo);
        this.aggrid.refreshaggrid(this.artService.maeartprecio, this.defaultColDef.objeto);
      } else {
        precioNuevo.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.artService.maeartprecio.splice(indi + 1, 0, precioNuevo);
        this.aggrid.refreshaggridindex(this.artService.maeartprecio, this.defaultColDef.objeto, indi);
      }
    }

    if (this.index === 3) {
      const parteNueva: ProdMaeartparte = {
        ART_CODIGO: this.maearticulo.ART_CODIGO,
        COM_CODIGO: '01',
        ART_CODIGOP: '',
        ART_NOMBRE: '',
        ARTPRE_PRECIO: '',
        ARTPARTE_CANTIDAD: '',
        ARTPARTE_TOTALART: '',
        UNI_CODIGO: ''
      };
      const indi = this.artService.maeartparte.indexOf(this.parteSeleccionada);
      if (indi === -1) {
        this.artService.maeartparte.push(parteNueva);
        this.aggrid.refreshaggrid(this.artService.maeartparte, this.defaultColDefParte.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefParte.objeto].setFocusedCell(this.artService.maeartparte.length - 1, 'ART_CODIGOP');
      } else {
        this.artService.maeartparte.splice(indi + 1, 0, parteNueva);
        this.aggrid.refreshaggridindex(this.artService.maeartparte, this.defaultColDefParte.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefParte.objeto].setFocusedCell(indi + 1, 'ART_CODIGOP');
      }
    }

    if (this.index === 4) {
      const formulaNueva: ProdMaeartformula = {
        ART_CODIGO: this.maearticulo.ART_CODIGO,
        COM_CODIGO: '01',
        ART_CODIGOP: '',
        FOR_CANTIDAD: '',
        UNI_CODIGO: '',
        FOR_CANTTOTAL: '',
        FOR_CODIGO: '',
        FOR_ORDEN: '',
        AUX: '',
        ART_NOMBRE: '',
        ART_EXISTENCIA: ''
      };
      const indi = this.artService.maeartformula.indexOf(this.formulaSeleccionada);
      if (indi === -1) {
        this.artService.maeartformula.push(formulaNueva);
        this.aggrid.refreshaggrid(this.artService.maeartformula, this.defaultColDefFormula.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefFormula.objeto].setFocusedCell(this.artService.maeartformula.length - 1, 'FOR_CODIGO');
      } else {
        this.artService.maeartformula.splice(indi + 1, 0, formulaNueva);
        this.aggrid.refreshaggridindex(this.artService.maeartformula, this.defaultColDefFormula.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefFormula.objeto].setFocusedCell(indi + 1, 'FOR_CODIGO');
      }
    }

    if (this.index === 5) {
      const costoestNuevo: ProdMaecostoestand = {
        CEST_CODIGO: '',
        CIN_CODIGO: '',
        COM_CODIGO: '01',
        CEST_COSTO: '',
        ART_CODIGO: this.maearticulo.ART_CODIGO,
        CEST_CANTIDAD: '',
        CIN_NOMBRE: ''
      };
      const indi = this.artService.maecostoestand.indexOf(this.costoestanSeleccionado);
      if (indi === -1) {
        this.artService.maecostoestand.push(costoestNuevo);
        this.aggrid.refreshaggrid(this.artService.maecostoestand, this.defaultColDefCostoest.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefCostoest.objeto].setFocusedCell(this.artService.maecostoestand.length - 1, 'CIN_CODIGO');
      } else {
        this.artService.maecostoestand.splice(indi + 1, 0, costoestNuevo);
        this.aggrid.refreshaggridindex(this.artService.maecostoestand, this.defaultColDefCostoest.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefCostoest.objeto].setFocusedCell(indi + 1, 'CIN_CODIGO');
      }
    }

    if (this.index === 6) {
      const existbodNueva: InvExistenciabod = {
        ART_CODIGO: this.maearticulo.ART_CODIGO,
        COM_CODIGO: '01',
        BOD_CODIGO: '',
        ARTEXT_MAXIMA: '',
        ARTEXT_MINIMA: '',
        ARTEXT_REFERENCIA: '',
        ARTEXT_ORDENAR: '',
        EXISTENCIAFECHA: ''
      };
      const indi = this.artService.maeexistbod.indexOf(this.existbodSeleccionada);
      if (indi === -1) {
        this.artService.maeexistbod.push(existbodNueva);
        this.aggrid.refreshaggrid(this.artService.maeexistbod, this.defaultColDefExistbod.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefExistbod.objeto].setFocusedCell(this.artService.maeexistbod.length - 1, 'BOD_CODIGO');
      } else {
        this.artService.maeexistbod.splice(indi + 1, 0, existbodNueva);
        this.aggrid.refreshaggridindex(this.artService.maeexistbod, this.defaultColDefExistbod.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefExistbod.objeto].setFocusedCell(indi + 1, 'BOD_CODIGO');
      }
    }

    if (this.index === 9) {
      const item: Invprodtrncomqui = {
        ART_CODIGO: '',
        COM_CODIGO: '01',
        TRNCOMQUI_CODIGO: '',
        TRNCOMQUI_NOMBRE: '',
        AUXILIAR: '',
        FILA: this.artService.prodtrncomqui.length + 1
      };
      const indi = this.artService.prodtrncomqui.indexOf(this.comquimicaSeleccionada);
      if (indi === -1) {
        this.artService.prodtrncomqui.push(item);
        this.aggrid.refreshaggrid(this.artService.prodtrncomqui, this.defaultColDefComquim.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefComquim.objeto].setFocusedCell(this.artService.prodtrncomqui.length - 1, 'TRNCOMQUI_CODIGO');
      } else {
        this.artService.prodtrncomqui.splice(indi + 1, 0, item);
        this.aggrid.refreshaggridindex(this.artService.prodtrncomqui, this.defaultColDefComquim.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefComquim.objeto].setFocusedCell(indi + 1, 'TRNCOMQUI_CODIGO');
      }
    }

    if (this.index === 10) {
      const item: ProdTrnAplicacion = {
        ART_CODIGO: '',
        COM_CODIGO: '01',
        TRNAPLI_CODIGO: '',
        TRNAPLI_NOMBRE: '',
        AUXILIAR: '',
        FILA: this.artService.prodtrnaplicacion.length + 1
      };
      const indi = this.artService.prodtrnaplicacion.indexOf(this.aplicacionSeleccionada);
      if (indi === -1) {
        this.artService.prodtrnaplicacion.push(item);
        this.aggrid.refreshaggrid(this.artService.prodtrnaplicacion, this.defaultColDefAplicacion.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefAplicacion.objeto].setFocusedCell(this.artService.prodtrnaplicacion.length - 1, 'TRNAPLI_CODIGO');
      } else {
        this.artService.prodtrnaplicacion.splice(indi + 1, 0, item);
        this.aggrid.refreshaggridindex(this.artService.prodtrnaplicacion, this.defaultColDefAplicacion.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefAplicacion.objeto].setFocusedCell(indi + 1, 'TRNAPLI_CODIGO');
      }
    }
    //CDPJ
    if (this.index === 12) {
      const item: Invmaeprofab = {
        ART_CODIGO: this.maearticulo.ART_CODIGO,
        PROFAB_ETAPA: '0',
        COM_CODIGO: '01',
        PROFAB_DESCRIPCION: '',
        ART_CODIGO_MP: '',
        PROFAB_CANTIDAD: 0,
        PROFAB_UNIDAD: '',
        PROFAB_HORA_INICIO: '',
        PROFAB_HORA_FIN: '',
        PROFAB_LINEA: 0,
        ART_NOMBRE_MP: '',
      };
      const indi = this.artService.profabricacion.indexOf(this.profabricaSeleccionada);
      if (indi === -1) {
        this.artService.profabricacion.push(item);
        this.profabricaSeleccionada = item
        this.aggrid.refreshaggrid(this.artService.profabricacion, this.defaultColDefProfab.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefProfab.objeto].setFocusedCell(this.artService.profabricacion.length - 1, 'PROFAB_ETAPA');
      } else {
        this.artService.profabricacion.splice(indi + 1, 0, item);
        this.profabricaSeleccionada = item
        this.aggrid.refreshaggridindex(this.artService.profabricacion, this.defaultColDefProfab.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefProfab.objeto].setFocusedCell(indi + 1, 'PROFAB_ETAPA');
      }
    }
    if (this.index === 13) {
      const item: Invmaeprocont = {
        ART_CODIGO: this.maearticulo.ART_CODIGO,
        COM_CODIGO: '01',
        PROCONT_PARAMETRO: '',
        PROCONT_ESP_MINIMA: '',
        PROCONT_ESP_MAXIMA: '',
        PROCONT_METODO: '',
        NUEVO: 0

      };
      const indi = this.artService.procontrol.indexOf(this.procontrolSeleccionada);
      if (indi === -1) {
        this.artService.procontrol.push(item);
        this.procontrolSeleccionada = item
        this.aggrid.refreshaggrid(this.artService.procontrol, this.defaultColDefProcont.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefProcont.objeto].setFocusedCell(this.artService.procontrol.length - 1, 'PROCONT_PARAMETRO');
      } else {
        this.artService.procontrol.splice(indi + 1, 0, item);
        this.procontrolSeleccionada = item
        this.aggrid.refreshaggridindex(this.artService.procontrol, this.defaultColDefProcont.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefProcont.objeto].setFocusedCell(indi + 1, 'PROCONT_PARAMETRO');
      }
    }
    //CDPJ

  }

  guardar() {
    if (this.index === 0 || this.index === 1) {
      this.guardararticulo();
    }

    if (this.index === 2) {
      this.guardarprecio();
    }

    if (this.index === 3) {
      this.guardarparte();
    }

    if (this.index === 4) {
      this.guardarformula();
    }

    if (this.index === 5) {
      this.guardarcostoest();
    }

    if (this.index === 6) {
      this.guardarexistbod();
    }

    if (this.index === 8) {
      this.guardarespecitecnica();
    }

    if (this.index === 11) {
      this.guardardatosautoarticulo();
    }

  }
  //CDPJ
  validarNumero() {
    this.maearticulo.ART_IRBPNR = (<HTMLInputElement>document.getElementById("ART_IRBPNR")).value
    console.log(this.maearticulo.ART_IRBPNR);
    (<HTMLInputElement>document.getElementById("ART_IRBPNR")).value = (this.maearticulo.ART_IRBPNR.indexOf(".") >= 0) ? (this.maearticulo.ART_IRBPNR.substr(0, this.maearticulo.ART_IRBPNR.indexOf(".")) + this.maearticulo.ART_IRBPNR.substr(this.maearticulo.ART_IRBPNR.indexOf("."), 3)) : this.maearticulo.ART_IRBPNR;
  }
  async seleccionDetProcontrol(params) {
    if (params === 'guardar') {
      this.artService.spin = true;
      if (this.verificarDatosProCont() === false) {
        const parametros: any[] = [];
        for (let i = 0; i < this.artService.procontrol.length; i++) {
          const item = this.artService.procontrol[i];
          if (item.NUEVO === 0) {
            parametros[0] = this.maearticulo.ART_CODIGO;
            parametros[1] = '01';
            parametros[2] = item.PROCONT_PARAMETRO;
            parametros[3] = item.PROCONT_ESP_MINIMA;
            parametros[4] = item.PROCONT_ESP_MAXIMA;
            parametros[5] = item.PROCONT_METODO;
            await this.artService.guardarProcontrol(parametros).catch(error => {
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Información',
                detail: 'Hubo un error al guardar el parámetro ' + item.PROCONT_PARAMETRO,
              });
              this.artService.spin = false;
              return;
            });
            this.auditoriagral.registrarAuditoria('INV_MAEPROCONT',
              this.maearticulo.ART_CODIGO + '/' + item.PROCONT_PARAMETRO, 'I', '', '01', '', '', '', '').subscribe();
          } else {
            parametros[0] = this.maearticulo.ART_CODIGO;
            parametros[1] = '01';
            parametros[2] = item.PROCONT_PARAMETRO;
            parametros[3] = item.PROCONT_ESP_MINIMA;
            parametros[4] = item.PROCONT_ESP_MAXIMA;
            parametros[5] = item.PROCONT_METODO;
            await this.artService.actualizarProcontrol(parametros).catch(error => {
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Información',
                detail: 'Hubo un error al actualizar el parámetro ' + item.PROCONT_PARAMETRO,
              });
              this.artService.spin = false;
              return;
            });
            this.auditoriagral.registrarAuditoria('INV_MAEPROCONT',
              this.maearticulo.ART_CODIGO + '/' + item.PROCONT_PARAMETRO, 'A', '', '01', '', '', '', '').subscribe();
          }
        }
        await this.listardatosprocontrol()
        this.messageService.add({
          key: 'art',
          severity: 'success',
          summary: 'Información',
          detail: 'Registros guardados exitosamente...!!!'
        });
        this.artService.spin = false;
      }
      return;
    }
    if (params === 'cancelar') {
      this.cancelar();
      return;
    }
    if (params === 'eliminar') {
      this.eliminar();
      return;
    }

    if (params === 'nuevo') {
      this.nuevo();
      return;
    }
    this.procontrolSeleccionada = params.object;
    this.init.auxsalto = false;
  }
  async listardatosprocontrol() {
    const item = await this.artService.listarProcontrol(this.maearticulo.ART_CODIGO).toPromise()//.subscribe((item) => {
    this.artService.procontrol = item;
  }
  async seleccionDetProFabri(params) {
    if (params === 'guardar') {
      this.artService.spin = true;
      if (this.verificarDatosProfabri() === false) {
        const parametros: any[] = [];
        for (let i = 0; i < this.artService.profabricacion.length; i++) {
          const item = this.artService.profabricacion[i];
          if (item.PROFAB_LINEA === 0) {
            //if (item.AUXILIAR === '') {
            parametros[0] = this.maearticulo.ART_CODIGO;
            parametros[1] = item.PROFAB_ETAPA;
            parametros[2] = '01';
            parametros[3] = item.PROFAB_DESCRIPCION;
            parametros[4] = item.ART_CODIGO_MP;
            parametros[5] = item.PROFAB_CANTIDAD;
            parametros[6] = item.PROFAB_UNIDAD;
            parametros[7] = item.PROFAB_HORA_INICIO;
            parametros[8] = item.PROFAB_HORA_FIN;
            parametros[9] = item.PROFAB_LINEA;
            await this.artService.guardarProfabri(parametros).catch(error => {
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Información',
                detail: 'Hubo un error al guadra el artículo ' + item.ART_CODIGO_MP,
              });
              this.artService.spin = false;
              return;
            });
            this.auditoriagral.registrarAuditoria('INV_MAEPROFAB',
              this.maearticulo.ART_CODIGO + '/' + item.PROFAB_ETAPA + '/' + item.ART_CODIGO_MP, 'I', '', '01', '', '', '', '').subscribe();
          } else {
            parametros[0] = this.maearticulo.ART_CODIGO;
            parametros[1] = item.PROFAB_LINEA;
            parametros[2] = item.PROFAB_ETAPA;
            parametros[3] = '01';
            parametros[4] = item.PROFAB_DESCRIPCION;
            parametros[5] = item.ART_CODIGO_MP;
            await this.artService.actualizarProfabri(parametros).catch(error => {
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Información',
                detail: 'Hubo un error al actualizar el artículo ' + item.ART_CODIGO_MP,
              });
              this.artService.spin = false;
              return;
            });
            this.auditoriagral.registrarAuditoria('INV_MAEPROFAB',
              this.maearticulo.ART_CODIGO + '/' + item.PROFAB_ETAPA + '/' + item.ART_CODIGO_MP, 'A', '', '01', '', '', '', '').subscribe();
          }
        }
        await this.listardatosprofabri()
        this.messageService.add({
          key: 'art',
          severity: 'success',
          summary: 'Información',
          detail: 'Registros guardados exitosamente...!!!'
        });
        this.artService.spin = false;
      }

      return;
    }
    if (params === 'cancelar') {
      this.cancelar();
      return;
    }
    if (params === 'eliminar') {
      this.eliminar();
      return;
    }

    if (params === 'nuevo') {
      this.nuevo();
      return;
    }

    this.profabricaSeleccionada = params.object;
    this.init.auxsalto = false;
  }
  verificarDatosProCont() {
    let valor = false;
    for (let i = 0; i < this.artService.procontrol.length; i++) {
      const item = this.artService.procontrol[i];
      if (item.PROCONT_PARAMETRO === null || item.PROCONT_PARAMETRO === '' || item.PROCONT_PARAMETRO === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo parámetro no puede estar vacío'
        });
        this.artService.spin = false;
        valor = true;
      }
      if (item.PROCONT_ESP_MINIMA === null || item.PROCONT_ESP_MINIMA === '' || item.PROCONT_ESP_MINIMA === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo Especif.Mín no puede estar vacío'
        });
        this.artService.spin = false;
        valor = true;
      }
      if (item.PROCONT_ESP_MAXIMA === null || item.PROCONT_ESP_MAXIMA === '' || item.PROCONT_ESP_MAXIMA === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo Especif.Máx no puede estar vacío'
        });
        this.artService.spin = false;
        valor = true;
      }
      if (item.PROCONT_METODO === null || item.PROCONT_METODO === '' || item.PROCONT_METODO === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo Método no puede estar vacío'
        });
        this.artService.spin = false;
        valor = true;
      }
    }
    return valor;
  }
  async listardatosprofabri() {
    const item = await this.artService.listarProfabri(this.maearticulo.ART_CODIGO).toPromise()//.subscribe((item) => {
    this.artService.profabricacion = item;
    this.artService.profabricacion.map((com) => {
      com.PROFAB_CANTIDAD = Number(com.PROFAB_CANTIDAD).toFixed(2)
    });
    //   console.log(this.artService.profabricacion);
    // });
  }
  verificarDatosProfabri() {
    let valor = false;
    for (let i = 0; i < this.artService.profabricacion.length; i++) {
      const item = this.artService.profabricacion[i];
      if (item.PROFAB_ETAPA === 0) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'El valor de la etapa no puede ser 0'
        });
        this.artService.spin = false;
        valor = true;
      }
    }
    return valor;
  }
  //CDPJ
  async guardararticulo() {
    //GSRF
    this.maearticulo.ART_CODIGO = this.maearticulo.ART_CODIGO.trim();
    console.log('-*--*-', this.maearticulo.ART_CODIGO)
    this.maearticulo.ART_CODIGO = this.maearticulo.ART_CODIGO.replace(/[' ']/g, '');
    console.log('-*--*-', this.maearticulo.ART_CODIGO)
    //GSRF
    if (this.maearticulo.CON_CODIGOGAS !== null) {
      if (this.maearticulo.CON_CODIGOGAS.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en Costo de Ventas'
        });
        this.maearticulo.CON_CODIGOGAS = '';
        this.maearticulo.CON_NOMBREGAS = '';
        this.index = 1;
        document.getElementById('CON_CODIGOGAS').focus()
        return
      }
    }
    if (this.maearticulo.CON_CODIGOACT !== null) {
      if (this.maearticulo.CON_CODIGOACT.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en Costo de Ventas'
        });
        this.maearticulo.CON_CODIGOACT = '';
        this.maearticulo.CON_NOMBREACT = '';
        this.index = 1;
        document.getElementById('CON_CODIGOACT').focus()
        return
      }
    }
    if (this.maearticulo.CON_CODIGODSCTO !== null) {
      if (this.maearticulo.CON_CODIGODSCTO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en Costo de Ventas'
        });
        this.maearticulo.CON_CODIGODSCTO = '';
        this.maearticulo.CON_NOMBREDSCTO = '';
        this.index = 1;
        document.getElementById('CON_CODIGODSCTO').focus()
        return
      }
    }
    if (this.maearticulo.CON_CODIGOING2 !== null) {
      if (this.maearticulo.CON_CODIGOING2.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en Costo de Ventas'
        });
        this.maearticulo.CON_CODIGOING2 = '';
        this.maearticulo.CON_NOMBREING2 = '';
        this.index = 1;
        document.getElementById('CON_CODIGOING2').focus()
        return
      }
    }
    if (this.maearticulo.CON_CODIGOING !== null) {
      if (this.maearticulo.CON_CODIGOING.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en Costo de Ventas'
        });
        this.maearticulo.CON_CODIGOING = '';
        this.maearticulo.CON_NOMBREING = '';
        this.index = 1;
        document.getElementById('CON_CODIGOING').focus()
        return
      }
    }
    if (this.maearticulo.CON_CODIGODSCTO2 !== null) {
      if (this.maearticulo.CON_CODIGODSCTO2.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en Costo de Ventas'
        });
        this.maearticulo.CON_CODIGODSCTO2 = '';
        this.maearticulo.CON_NOMBREDSCTO2 = '';
        this.index = 1;
        document.getElementById('CON_CODIGODSCTO2').focus()
        return
      }
    }
    if (this.maearticulo.CON_CODIGOORD1 !== null) {
      if (this.maearticulo.CON_CODIGOORD1.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en Costo de Ventas'
        });
        this.maearticulo.CON_CODIGOORD1 = '';
        this.maearticulo.CON_NOMBREORD1 = '';
        this.index = 1;
        document.getElementById('CON_CODIGOORD1').focus()
        return
      }
    }
    if (this.maearticulo.CON_CODIGOPRODPROC !== null) {
      if (this.maearticulo.CON_CODIGOPRODPROC.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en Costo de Ventas'
        });
        this.maearticulo.CON_CODIGOPRODPROC = '';
        this.maearticulo.CON_NOMBREPRODPROC = '';
        this.index = 1;
        document.getElementById('CON_CODIGOPRODPROC').focus()
        return
      }
    }
    if (this.maearticulo.CON_CODIGOORD2 !== null) {
      if (this.maearticulo.CON_CODIGOORD2.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable en Costo de Ventas'
        });
        this.maearticulo.CON_CODIGOORD2 = '';
        this.maearticulo.CON_NOMBREORD2 = '';
        this.index = 1;
        document.getElementById('CON_CODIGOORD2').focus()
        return
      }
    }
    //GSRF
    //CDPJ
    if (this.chktributaiva === true && this.maearticulo.TARIIVA_CODIGO !== null && this.maearticulo.TARIIVA_CODIGO !== undefined && this.maearticulo.TARIIVA_CODIGO !== '') {
      let data = this.initConf.srifetariiva.find(element => element.TARIIVA_CODIGO === this.maearticulo.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0)
      if (data === undefined || data === null) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Error en IVA',
          detail: 'El %IVA con el código ' + this.maearticulo.TARIIVA_CODIGO + ' no es parte de los códigos que puede seleccionar para servicios que tributan IVA.'
        });

        return;
      }
    }
    if (this.chktributaiva === false
      && this.maearticulo.TARIIVA_CODIGO !== null && this.maearticulo.TARIIVA_CODIGO !== undefined && this.maearticulo.TARIIVA_CODIGO !== '') {
      let data = this.initConf.srifetariiva.find(element => element.TARIIVA_CODIGO === this.maearticulo.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0)
      console.log(data)
      console.log(this.initConf.srifetariiva.find(element => element.TARIIVA_CODIGO === this.maearticulo.TARIIVA_CODIGO))
      if (data === undefined || data === null) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Error en IVA',
          detail: 'El %IVA con el código ' + this.maearticulo.TARIIVA_CODIGO + ' no es parte de los códigos que puede seleccionar para servicios que no tributan IVA.'
        });

        return;
      }
    }

    //CDPJ
    if (this.CODAUTGRU === true) {
      //console.log('si');
      if (
        this.maearticulo.ART_NOMBRE === '' ||
        this.maearticulo.GRUP_CODIGO === '' ||
        this.maearticulo.ART_UNIDADCOSTEO === '' ||
        this.maearticulo.ART_UNIDADVENTA === '') {
        this.messageService.add({
          key: 'art',
          severity: 'warn',
          summary: 'Información',
          detail: 'Los datos obligatorios son Nombre, Grupo, Unidad de costeo, Unidad de venta '
        });
        return;
      }
      if (Number(await this.validaCntaCont()) !== 0) {
        return;
      }
      if (this.chkactivo === true) {
        this.maearticulo.ART_ACTIVO = 'S';
      } else {
        this.maearticulo.ART_ACTIVO = 'N';
      }
      if (this.chkcompuesto === true) {
        this.maearticulo.ART_COMPUESTO = 'S';
      } else {
        this.maearticulo.ART_COMPUESTO = 'N';
      }
      if (this.chktributaiva === true) {
        this.maearticulo.ART_TRIBUTAIVA = 'S';
      } else {
        this.maearticulo.ART_TRIBUTAIVA = 'N';
      }
      if (this.chkactivarserialfac === true) {
        this.maearticulo.ART_SERIALFLAG = 'S';
      } else {
        this.maearticulo.ART_SERIALFLAG = 'N';
      }
      if (this.chktributaice === true) {
        this.maearticulo.ART_TRIBUTAICE = 'S';
      } else {
        this.maearticulo.ART_TRIBUTAICE = 'N';
      }
      if (this.chkbnobojiva === true) {
        this.maearticulo.ART_BASENOOBJIVA = 'S';
      } else {
        this.maearticulo.ART_BASENOOBJIVA = 'N';
      }
      if (this.chkmultiunidad === true) {
        this.maearticulo.ART_MULTIUNIDAD = 'S';
      } else {
        this.maearticulo.ART_MULTIUNIDAD = 'N';
      }
      if (this.chkcaduca === true) {
        this.maearticulo.ART_CADUCA = 'S';
      } else {
        this.maearticulo.ART_CADUCA = 'N';
      }
      if (this.chkactivarserial === true) {
        this.maearticulo.ART_ACTIVARSERIAL = 'S';
      } else {
        this.maearticulo.ART_ACTIVARSERIAL = 'N';
      }
      if (this.chkactivardim === true) {
        this.maearticulo.ART_ACTIVARDIM = 'S';
      } else {
        this.maearticulo.ART_ACTIVARDIM = 'N';
      }
      if (this.chkactivartelefono === true) {
        this.maearticulo.ART_ACTIVARNUMEROTEL = 'S';
      } else {
        this.maearticulo.ART_ACTIVARNUMEROTEL = 'N';
      }
      if (this.chkmateriaprima === true) {
        this.maearticulo.ART_TIPO = 'M';
      } else {
        this.maearticulo.ART_TIPO = '';
      }
      if (this.chkproducido === true) {
        this.maearticulo.ART_PRODUCTOPROD = 'S';
      } else {
        this.maearticulo.ART_PRODUCTOPROD = 'N';
      }
      if (this.chkbtnservicio === true) {
        this.maearticulo.ART_APLICASERVICIO = 'S';
      } else {
        this.maearticulo.ART_APLICASERVICIO = 'N';
      }


      this.maearticulo.ART_TIPOCATEGORIA = this.strTipoCateg.REF_CODIGO;

      if (this.maearticulo.ART_FECHAING.toString().includes('-')) {
        this.maearticulo.ART_FECHAING = this.datePipe.transform(this.maearticulo.ART_FECHAING,
          'dd/MM/yyyy');
      } else {
        this.maearticulo.ART_FECHAING = this.maearticulo.ART_FECHAING;
      }
      this.maearticulo.ART_PORIVA = this.maearticulo.TARIIVA_CODIGO === null || this.maearticulo.TARIIVA_CODIGO || this.maearticulo.TARIIVA_CODIGO === undefined ? '' : this.maearticulo.TARIIVA_CODIGO;//CDPJ
      this.artService.guardarArticulo(this.maearticulo).subscribe((datos: any[]) => {
        (<HTMLInputElement>document.getElementById('ART_CODIGO')).value = datos[0][":B1"];
        console.log('datos:', datos)
        this.artService.actualizarDatosNuevos(datos[0][":B1"]);
        this.messageService.add({
          key: 'art',
          severity: 'success',
          summary: 'Información',
          detail: 'El artículo se insertó correctamente'
        });

        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = false;
        this.botonRegresar = true;
        this.bolnuevo = false;
        this.auditoriagral.registrarAuditoria('INV_MAEARTICULO',
          datos[0][":B1"], 'I', '', '01', '', '', '', '').subscribe();

      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Insertar artículo',
          detail: mensaje
        });
      });


      this.busqService.busquedaInvMaeArticulo2('').subscribe((datos: any[]) => {
        this.articulos = datos;
      });

    }
    //CDPJ
    else {
      if (this.bolnuevo) {
        const er = await this.artService.encontrarRegistroPromise(this.maearticulo.ART_CODIGO);
        console.log(er);
        if (er !== null) {
          this.messageService.add({
            key: 'art',
            severity: 'error',
            summary: 'Información',
            detail: 'El código ya existe'
          });
          return;
        }
      }
      if (Number(await this.validaCntaCont()) !== 0) {
        return;
      }
      if (this.maearticulo.ART_CODIGO === '' ||
        this.maearticulo.ART_NOMBRE === '' ||
        this.maearticulo.ART_NOMBREC === '' ||
        this.maearticulo.GRUP_CODIGO === '' ||
        this.maearticulo.ART_UNIDADCOSTEO === '' ||
        this.maearticulo.ART_UNIDADVENTA === '') {
        this.messageService.add({
          key: 'art',
          severity: 'warn',
          summary: 'Información',
          detail: 'Los datos obligatorios son Nombre, Grupo, Unidad de costeo, Unidad de venta '
        });
        return;
      }

      if (this.chkactivo === true) {
        this.maearticulo.ART_ACTIVO = 'S';
      } else {
        this.maearticulo.ART_ACTIVO = 'N';
      }
      if (this.chkcompuesto === true) {
        this.maearticulo.ART_COMPUESTO = 'S';
      } else {
        this.maearticulo.ART_COMPUESTO = 'N';
      }
      if (this.chktributaiva === true) {
        this.maearticulo.ART_TRIBUTAIVA = 'S';
      } else {
        this.maearticulo.ART_TRIBUTAIVA = 'N';
      }
      if (this.chkactivarserialfac === true) {
        this.maearticulo.ART_SERIALFLAG = 'S';
      } else {
        this.maearticulo.ART_SERIALFLAG = 'N';
      }
      if (this.chktributaice === true) {
        this.maearticulo.ART_TRIBUTAICE = 'S';
      } else {
        this.maearticulo.ART_TRIBUTAICE = 'N';
      }
      if (this.chkbnobojiva === true) {
        this.maearticulo.ART_BASENOOBJIVA = 'S';
      } else {
        this.maearticulo.ART_BASENOOBJIVA = 'N';
      }
      if (this.chkmultiunidad === true) {
        this.maearticulo.ART_MULTIUNIDAD = 'S';
      } else {
        this.maearticulo.ART_MULTIUNIDAD = 'N';
      }
      if (this.chkcaduca === true) {
        this.maearticulo.ART_CADUCA = 'S';
      } else {
        this.maearticulo.ART_CADUCA = 'N';
      }
      if (this.chkactivarserial === true) {
        this.maearticulo.ART_ACTIVARSERIAL = 'S';
      } else {
        this.maearticulo.ART_ACTIVARSERIAL = 'N';
      }
      if (this.chkactivardim === true) {
        this.maearticulo.ART_ACTIVARDIM = 'S';
      } else {
        this.maearticulo.ART_ACTIVARDIM = 'N';
      }
      if (this.chkactivartelefono === true) {
        this.maearticulo.ART_ACTIVARNUMEROTEL = 'S';
      } else {
        this.maearticulo.ART_ACTIVARNUMEROTEL = 'N';
      }
      if (this.chkmateriaprima === true) {
        this.maearticulo.ART_TIPO = 'M';
      } else {
        this.maearticulo.ART_TIPO = '';
      }
      if (this.chkproducido === true) {
        this.maearticulo.ART_PRODUCTOPROD = 'S';
      } else {
        this.maearticulo.ART_PRODUCTOPROD = 'N';
      }
      if (this.chkbtnservicio === true) {
        this.maearticulo.ART_APLICASERVICIO = 'S';
      } else {
        this.maearticulo.ART_APLICASERVICIO = 'N';
      }


      this.maearticulo.ART_TIPOCATEGORIA = this.strTipoCateg.REF_CODIGO;

      if (this.maearticulo.ART_FECHAING.toString().includes('-')) {
        this.maearticulo.ART_FECHAING = this.datePipe.transform(this.maearticulo.ART_FECHAING,
          'dd/MM/yyyy');
      } else {
        this.maearticulo.ART_FECHAING = this.maearticulo.ART_FECHAING;
      }
      this.maearticulo.ART_PORIVA = this.maearticulo.TARIIVA_CODIGO === null || this.maearticulo.TARIIVA_CODIGO === '' || this.maearticulo.TARIIVA_CODIGO === undefined ? '' : this.maearticulo.ART_PORIVA;//CDPJ

      this.artService.guardarArticulo(this.maearticulo).subscribe((datos: any[]) => {
        this.artService.actualizarDatosNuevos(this.maearticulo);
        this.messageService.add({
          key: 'art',
          severity: 'success',
          summary: 'Información',
          detail: 'El artículo se insertó correctamente'
        });
        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = false;
        this.botonRegresar = true;
        this.bolnuevo = false;
        this.auditoriagral.registrarAuditoria('INV_MAEARTICULO',
          this.maearticulo.ART_CODIGO, 'I', '', '01', '', '', '', '').subscribe();
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Insertar artículo',
          detail: mensaje
        });
      });

      this.busqService.busquedaInvMaeArticulo2('').subscribe((datos: any[]) => {
        this.articulos = datos;
      });
    }
  }
  //CDPJ
  async validaCntaCont() {
    let errores = 0;
    // cuenta de inventario
    if (this.maearticulo.CON_CODIGOACT !== null && this.maearticulo.CON_CODIGOACT !== undefined && this.maearticulo.CON_CODIGOACT !== '') {
      const data = await this.artService.EncontrarCnta(this.maearticulo.CON_CODIGOACT);
      if (data !== null && data !== undefined) {
        for (const data1 of data) {
          if (Number(data1.NUMERO) === 0) {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Insertar artículo',
              detail: 'Error, la cuenta contable ' + this.maearticulo.CON_CODIGOACT + ', no existe'
            });
            errores++;
          }
        }
      }
    }
    if (this.maearticulo.CON_CODIGOGAS !== null && this.maearticulo.CON_CODIGOGAS !== undefined && this.maearticulo.CON_CODIGOGAS !== '') {
      const data = await this.artService.EncontrarCnta(this.maearticulo.CON_CODIGOGAS);
      if (data !== null && data !== undefined) {
        for (const data1 of data) {
          if (Number(data1.NUMERO) === 0) {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Insertar artículo',
              detail: 'Error, la cuenta contable ' + this.maearticulo.CON_CODIGOGAS + ', no existe'
            });
            errores++;
          }
        }
      }
    }
    if (this.maearticulo.CON_CODIGOING !== null && this.maearticulo.CON_CODIGOING !== undefined && this.maearticulo.CON_CODIGOING !== '') {
      const data = await this.artService.EncontrarCnta(this.maearticulo.CON_CODIGOING);
      if (data !== null && data !== undefined) {
        for (const data1 of data) {
          if (Number(data1.NUMERO) === 0) {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Insertar artículo',
              detail: 'Error, la cuenta contable ' + this.maearticulo.CON_CODIGOING + ', no existe'
            });
            errores++;
          }
        }
      }
    }
    if (this.maearticulo.CON_CODIGOORD1 !== null && this.maearticulo.CON_CODIGOORD1 !== undefined && this.maearticulo.CON_CODIGOORD1 !== '') {
      const data = await this.artService.EncontrarCnta(this.maearticulo.CON_CODIGOORD1);
      if (data !== null && data !== undefined) {
        for (const data1 of data) {
          if (Number(data1.NUMERO) === 0) {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Insertar artículo',
              detail: 'Error, la cuenta contable ' + this.maearticulo.CON_CODIGOORD1 + ', no existe'
            });
            errores++;
          }
        }
      }
    }
    if (this.maearticulo.CON_CODIGOORD2 !== null && this.maearticulo.CON_CODIGOORD2 !== undefined && this.maearticulo.CON_CODIGOORD2 !== '') {
      const data = await this.artService.EncontrarCnta(this.maearticulo.CON_CODIGOORD2);
      if (data !== null && data !== undefined) {
        for (const data1 of data) {
          if (Number(data1.NUMERO) === 0) {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Insertar artículo',
              detail: 'Error, la cuenta contable ' + this.maearticulo.CON_CODIGOORD2 + ', no existe'
            });
            errores++;
          }
        }
      }
    }
    if (this.maearticulo.CON_CODIGODSCTO !== null && this.maearticulo.CON_CODIGODSCTO !== undefined && this.maearticulo.CON_CODIGODSCTO !== '') {
      const data = await this.artService.EncontrarCnta(this.maearticulo.CON_CODIGODSCTO);
      if (data !== null && data !== undefined) {
        for (const data1 of data) {
          if (Number(data1.NUMERO) === 0) {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Insertar artículo',
              detail: 'Error, la cuenta contable ' + this.maearticulo.CON_CODIGODSCTO + ', no existe'
            });
            errores++;
          }
        }
      }
    }
    if (this.maearticulo.CON_CODIGOING2 !== null && this.maearticulo.CON_CODIGOING2 !== undefined && this.maearticulo.CON_CODIGOING2 !== '') {
      const data = await this.artService.EncontrarCnta(this.maearticulo.CON_CODIGOING2);
      if (data !== null && data !== undefined) {
        for (const data1 of data) {
          if (Number(data1.NUMERO) === 0) {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Insertar artículo',
              detail: 'Error, la cuenta contable ' + this.maearticulo.CON_CODIGOING2 + ', no existe'
            });
            errores++;
          }
        }
      }
    }
    if (this.maearticulo.CON_CODIGODSCTO2 !== null && this.maearticulo.CON_CODIGODSCTO2 !== undefined && this.maearticulo.CON_CODIGODSCTO2 !== '') {
      const data = await this.artService.EncontrarCnta(this.maearticulo.CON_CODIGODSCTO2);
      if (data !== null && data !== undefined) {
        for (const data1 of data) {
          if (Number(data1.NUMERO) === 0) {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Insertar artículo',
              detail: 'Error, la cuenta contable ' + this.maearticulo.CON_CODIGODSCTO2 + ', no existe'
            });
            errores++;
          }
        }
      }
    }
    if (this.maearticulo.CON_CODIGOPRODPROC !== null && this.maearticulo.CON_CODIGOPRODPROC !== undefined && this.maearticulo.CON_CODIGOPRODPROC !== '') {
      const data = await this.artService.EncontrarCnta(this.maearticulo.CON_CODIGOPRODPROC);
      if (data !== null && data !== undefined) {
        for (const data1 of data) {
          if (Number(data1.NUMERO) === 0) {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Insertar artículo',
              detail: 'Error, la cuenta contable ' + this.maearticulo.CON_CODIGOPRODPROC + ', no existe'
            });
            errores++;
          }
        }
      }
    }

    // cuenta costo de ventas

    console.log('errores', errores)
    return errores;

  }
  //CDPJ
  guardarprecio() {

    for (const maeprecio of this.artService.maeartprecio) {
      if (maeprecio.ART_CODIGO === '' || maeprecio.ARTPRE_PRECIO === '') {
        this.messageService.add({
          key: 'art',
          severity: 'warn',
          summary: 'Información',
          detail: 'Complete los campos'
        });
        return;
      }
    }

    for (const maeprecio of this.artService.maeartprecio) {
      if (maeprecio.ARTPRE_PRECIO !== '' && maeprecio.ARTPRE_CODIGO !== '') {

        maeprecio.ARTPRE_PRECIO = Number(maeprecio.ARTPRE_PRECIO);
        maeprecio.ARTPRE_DESCUENTO = Number(maeprecio.ARTPRE_DESCUENTO);
        maeprecio.ARTPRE_PORCENTAJE = Number(maeprecio.ARTPRE_PORCENTAJE);
        maeprecio.ARTPRE_COSTO = Number(maeprecio.ARTPRE_COSTO);
        maeprecio.ARTPRE_CANTIDADINI = Number(maeprecio.ARTPRE_CANTIDADINI);
        maeprecio.ARTPRE_CANTIDADFIN = Number(maeprecio.ARTPRE_CANTIDADFIN);
        maeprecio.ARTPRE_DESCF = Number(maeprecio.ARTPRE_DESCF);
        maeprecio.ARTPRE_DESCP = Number(maeprecio.ARTPRE_DESCP);
        maeprecio.ARTPRE_PRECIOFPVP = Number(maeprecio.ARTPRE_PRECIOFPVP);

        this.artService.encontrarArtPreCodigo('ART_CODIGO = \'' + maeprecio.ART_CODIGO.toUpperCase() +
          '\' AND ARTPRE_CODIGO=\'' + maeprecio.ARTPRE_CODIGO + '\'').subscribe((existedata) => {
            console.log(existedata);
            console.log('1');
            if (existedata !== null) {
              console.log('2');
              this.artService.actualizarPrecio(maeprecio).subscribe(() => {
                this.messageService.add({
                  key: 'art',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'El precio del artículo se actualizo correctamente'
                });
                console.log('3');
                this.auditoriagral.registrarAuditoria('INV_MAEARTPRECIO',
                  maeprecio.ART_CODIGO + '/' + maeprecio.ARTPRE_CODIGO, 'A', '', '01', '', '', '', '').subscribe();
              }, error1 => {
                const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.messageService.add({
                  key: 'art',
                  severity: 'error',
                  summary: 'Error',
                  detail: error
                });
              });
            } else {
              console.log('4');
              this.artService.insertarPrecio(maeprecio).subscribe(() => {
                this.messageService.add({
                  key: 'art',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'El precio del artículo se insertó correctamente'
                });
                console.log('5');
                this.auditoriagral.registrarAuditoria('INV_MAEARTPRECIO',
                  maeprecio.ART_CODIGO + '/' + maeprecio.ARTPRE_CODIGO, 'I', '', '01', '', '', '', '').subscribe();
              }, error1 => {
                const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
                this.messageService.add({
                  key: 'art',
                  severity: 'error',
                  summary: 'Error',
                  detail: error
                });
              });
            }
          });
      }
    }
  }


  guardarparte() {
    for (const maeparte of this.artService.maeartparte) {
      if (maeparte.ART_CODIGO === '' || maeparte.ART_CODIGOP === '') {
        this.messageService.add({
          key: 'art',
          severity: 'warn',
          summary: 'Información',
          detail: 'Complete los campos'
        });
        return;
      }
    }

    for (const maeparte of this.artService.maeartparte) {

      maeparte.ARTPARTE_CANTIDAD = Number(maeparte.ARTPARTE_CANTIDAD);
      maeparte.ARTPARTE_TOTALART = Number(maeparte.ARTPARTE_TOTALART);

      if (maeparte.ART_CODIGO !== '' && maeparte.ART_CODIGOP !== '') {

        this.artService.encontrarArtCodigoPMaeParte(maeparte.ART_CODIGO, maeparte.ART_CODIGOP).subscribe((existedata) => {
          if (existedata !== null) {
            this.artService.actualizarParte(maeparte).subscribe(() => {
              this.messageService.add({
                key: 'art',
                severity: 'success',
                summary: 'Información',
                detail: 'El componente del artículo se actualizo correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEARTPARTE',
                maeparte.ART_CODIGO + '/' + maeparte.ART_CODIGOP, 'A', '', '01', '', '', '', '').subscribe();
            }, error1 => {
              const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Error',
                detail: error
              });
            });
          } else {
            this.artService.insertarParte(maeparte).subscribe((datos: any[]) => {
              this.messageService.add({
                key: 'art',
                severity: 'success',
                summary: 'Información',
                detail: 'El componente del artículo se insertó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAEARTPARTE',
                maeparte.ART_CODIGO + '/' + maeparte.ART_CODIGOP, 'I', '', '01', '', '', '', '').subscribe();
            }, error1 => {
              const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Error',
                detail: error
              });
            });
          }
        });
      }
    }
  }

  async guardarformula() {
    for (const maeformula of this.artService.maeartformula) {
      if (maeformula.ART_CODIGO === '' || maeformula.FOR_CODIGO === '') {
        this.messageService.add({
          key: 'art',
          severity: 'warn',
          summary: 'Información',
          detail: 'Complete los campos'
        });
        return;
      }
    }

    for (const maeformula of this.artService.maeartformula) {
      maeformula.FOR_CANTIDAD = Number(maeformula.FOR_CANTIDAD);
      maeformula.FOR_CANTTOTAL = Number(maeformula.FOR_CANTTOTAL);

      if (maeformula.FOR_CODIGO !== '' && maeformula.ART_CODIGOP !== '') {

        const existedata = await this.artService.encontrarArtCodigoPMaeFor(maeformula.ART_CODIGO, maeformula.ART_CODIGOP, maeformula.FOR_CODIGO);
        if (existedata !== null) {
          try {
            await this.artService.actualizarFormula(maeformula);
            this.messageService.add({
              key: 'art',
              severity: 'success',
              summary: 'Información',
              detail: 'La fórmula del artículo se actualizo correctamente'
            });
            this.auditoriagral.registrarAuditoria('INV_MAEARTFORMULA',
              maeformula.ART_CODIGO + '/' + maeformula.ART_CODIGOP, 'A', '', '01', '', '', '', '').subscribe();
          } catch (error1) {
            const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Error',
              detail: error
            });
          }
        } else {
          try {
            await this.artService.insertarFormula(maeformula);
            this.messageService.add({
              key: 'art',
              severity: 'success',
              summary: 'Información',
              detail: 'La fórmula del artículo se insertó correctamente'
            });
            this.auditoriagral.registrarAuditoria('INV_MAEARTFORMULA',
              maeformula.ART_CODIGO + '/' + maeformula.ART_CODIGOP, 'I', '', '01', '', '', '', '').subscribe();
          } catch (error1) {
            const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Error',
              detail: error
            });
          }
        }
      }
    }
  }

  guardarcostoest() {
    for (const maecostoesst of this.artService.maecostoestand) {
      if (maecostoesst.ART_CODIGO === '' || maecostoesst.CIN_CODIGO === '') {
        this.messageService.add({
          key: 'art',
          severity: 'warn',
          summary: 'Información',
          detail: 'Complete los campos'
        });
        return;
      }
    }

    for (const maecostoesst of this.artService.maecostoestand) {
      maecostoesst.CEST_COSTO = Number(maecostoesst.CEST_COSTO);
      maecostoesst.CEST_CANTIDAD = Number(this.txtNumArtCosEst);
      console.log(maecostoesst);

      if (maecostoesst.CIN_CODIGO !== '' && maecostoesst.CEST_COSTO !== '') {

        this.artService.encontrarCinCodMaeCostEst(maecostoesst.ART_CODIGO, maecostoesst.CIN_CODIGO).subscribe((existedata) => {
          if (existedata !== null) {
            this.artService.actualizarCostoEst(maecostoesst).subscribe(() => {
              this.artService.actualizarCantCostoEst(maecostoesst.CEST_CANTIDAD, maecostoesst.ART_CODIGO).subscribe(res => { });
              this.messageService.add({
                key: 'art',
                severity: 'success',
                summary: 'Información',
                detail: 'El costo estandar del artículo se actualizo correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAECOSTOEST',
                maecostoesst.ART_CODIGO + '/' + maecostoesst.CIN_CODIGO, 'A', '', '01', '', '', '', '').subscribe();
            }, error1 => {
              const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Error',
                detail: error
              });
            });
          } else {
            this.artService.insertarCostoEst(maecostoesst).subscribe((datos: any[]) => {
              this.artService.actualizarCantCostoEst(maecostoesst.CEST_CANTIDAD, maecostoesst.ART_CODIGO).subscribe(res => { });
              this.messageService.add({
                key: 'art',
                severity: 'success',
                summary: 'Información',
                detail: 'El costo estandar del artículo se insertó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_MAECOSTOEST',
                maecostoesst.ART_CODIGO + '/' + maecostoesst.CIN_CODIGO, 'I', '', '01', '', '', '', '').subscribe();
            }, error1 => {
              const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Error',
                detail: error
              });
            });
          }
        });
      }
    }


  }


  guardarexistbod() {
    for (const maeexistbod of this.artService.maeexistbod) {
      if (maeexistbod.ART_CODIGO === '' || maeexistbod.BOD_CODIGO === '') {
        this.messageService.add({
          key: 'art',
          severity: 'warn',
          summary: 'Información',
          detail: 'Complete los campos'
        });
        return;
      }
    }

    for (const maeexistbod of this.artService.maeexistbod) {
      maeexistbod.ARTEXT_ORDENAR = Number(maeexistbod.ARTEXT_ORDENAR);
      maeexistbod.ARTEXT_MAXIMA = Number(maeexistbod.ARTEXT_MAXIMA);
      maeexistbod.ARTEXT_MINIMA = Number(maeexistbod.ARTEXT_MINIMA);
      maeexistbod.EXISTENCIAFECHA = Number(maeexistbod.EXISTENCIAFECHA);

      if (maeexistbod.BOD_CODIGO !== '' && maeexistbod.ARTEXT_MAXIMA !== '') {

        this.artService.encontrarBodCodigoMaeExist(maeexistbod.ART_CODIGO, maeexistbod.BOD_CODIGO).subscribe((existedata) => {
          if (existedata !== null) {
            this.artService.actualizarExistencia(maeexistbod).subscribe(() => {
              this.messageService.add({
                key: 'art',
                severity: 'success',
                summary: 'Información',
                detail: 'La existencia por bodega del artículo se actualizo correctamente'
              });

              this.auditoriagral.registrarAuditoria('INV_MAECOSTOEST',
                maeexistbod.ART_CODIGO + '/' + maeexistbod.ARTEXT_REFERENCIA, 'A', '', '01', '', '', '', '').subscribe();
            }, error1 => {
              const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Error',
                detail: error
              });
            });
          } else {
            this.artService.insertarExistencia(maeexistbod).subscribe((datos: any[]) => {
              this.messageService.add({
                key: 'art',
                severity: 'success',
                summary: 'Información',
                detail: 'La existencia por bodega del artículo se insertó correctamente'
              });

              this.auditoriagral.registrarAuditoria('INV_MAECOSTOEST',
                maeexistbod.ART_CODIGO + '/' + maeexistbod.ARTEXT_REFERENCIA, 'A', '', '01', '', '', '', '').subscribe();
            }, error1 => {
              const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Error',
                detail: error
              });
            });
          }
        });
      }
    }
  }

  guardarespecitecnica() {
    if (this.maearticulo.ART_CODIGO === '' ||
      this.maearticulo.ART_NOMBRE === '' ||
      this.maearticulo.ART_NOMBREC === '' ||
      this.maearticulo.GRUP_CODIGO === '' ||
      this.maearticulo.ART_UNIDADCOSTEO === '' ||
      this.maearticulo.ART_UNIDADVENTA === '') {
      this.messageService.add({
        key: 'art',
        severity: 'warn',
        summary: 'Información',
        detail: 'Los datos obligatorios son Nombre, Grupo, Unidad de costeo, Unidad de venta '
      });
      return;
    }

    this.maeesptec.ART_CODIGO = this.maearticulo.ART_CODIGO;
    this.artService.guardarEspecTec(this.maeesptec).subscribe((datos: any[]) => {
      this.artService.actAlternosArticulo(this.maearticulo.ART_CODIGOALT1, this.maearticulo.ART_CODIGOALT2,
        this.maearticulo.ART_CODIGOALT3, this.maearticulo.ART_CODIGOALT4, this.maearticulo.ART_CODIGO).subscribe();

      this.messageService.add({
        key: 'art',
        severity: 'success',
        summary: 'Información',
        detail: 'Las especificaciones técnicas se insertaron correctamente'
      });
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonBorrar = false;
      this.botonRegresar = true;
      this.auditoriagral.registrarAuditoria('INV_MAEESPTEC',
        this.maearticulo.ART_CODIGO + '/ESPTEC', 'A', '', '01', '', '', '', '').subscribe();
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'art',
        severity: 'error',
        summary: 'Insertar artículo',
        detail: mensaje
      });
    });

  }

  async arbolGrupo() {
    this.artService.encontrarGruNombre('GRUP_CODIGO' +
      ' LIKE \'' + this.maearticulo.GRUP_CODIGO + '\'').subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.maearticulo.GRUP_NOMBRE = eR[0].GRUP_NOMBRE;
            this.botonesmodificar();
            document.getElementById('GRUP_CODIGOP').focus();
          } else {
            this.arbolGrupo2();
          }
        } else {
          this.arbolGrupo2();
        }
      });
  }

  async arbolGrupo2() {
    const data: any = await this.artService.getgruposnivel1();
    for (const rs of data) {
      const hijos: any = await this.MostrarSubgruposXGrupo({ intNivel: "1", strGrupCodigo: rs.GRUP_CODIGO });
      const dt = {
        "label": rs.GRUP_NOMBRE,
        "data": rs.GRUP_CODIGO,
        "expandedIcon": "pi pi-folder-open",
        "collapsedIcon": "pi pi-folder",
        "children": hijos
      }

      this.gruniv.push(dt);
    }

    this.displayGrupoNiveles = true;
  }

  nodeSelect(event) {
    if (event.node.children === undefined) {
      this.maearticulo.GRUP_CODIGO = event.node.data;
      this.maearticulo.GRUP_NOMBRE = event.node.label;
      this.displayGrupoNiveles = false;
    }
  }

  async setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'GRUP_CODIGO': {
        this.busquedagrupo(event.target.value);
        break;
      }
      case 'GRUP_CODIGOP': {
        this.busquedasubgrupo(event.target.value);
        break;
      }
      case 'ART_CODIGO': {
        //GSRF
        this.maearticulo.ART_CODIGO = this.maearticulo.ART_CODIGO.trim();
        console.log('-*--*-', this.maearticulo.ART_CODIGO)
        this.maearticulo.ART_CODIGO = this.maearticulo.ART_CODIGO.replace(/[' ']/g, '');
        console.log('-*--*-', this.maearticulo.ART_CODIGO)
        const er = await this.artService.encontrarRegistroPromise(this.maearticulo.ART_CODIGO);
        console.log(er);
        if (er !== null) {
          this.messageService.add({
            key: 'art',
            severity: 'error',
            summary: 'Información',
            detail: 'El código ya existe'
          });
          element = document.getElementById('ART_CODIGO');
          this.maearticulo.ART_CODIGO = '';
          break;
        } else {
          element = document.getElementById('ART_NOMBRE');
          this.maearticulo.ART_NOMBREC = this.maearticulo.ART_CODIGO.substr(0, 25);
          break;
        }
        //GSRF
      }
      case 'ART_NOMBRE': {
        element = document.getElementById('ART_NOMBREC');
        break;
      }
      case 'ART_NOMBREC': {
        element = document.getElementById('ART_CODIGOALT1');
        break;
      }
      case 'ART_CODIGOALT1': {
        element = document.getElementById('ART_CODIGOALT2');
        break;
      }
      case 'ART_CODIGOALT2': {
        element = document.getElementById('ART_UBICACION');
        break;
      }
      case 'ART_UBICACION': {
        element = document.getElementById('ART_DIASGARANTIA');
        break;
      }
      case 'ART_DIASGARANTIA': {
        element = document.getElementById('ART_CODIGOICE');
        break;
      }
      case 'ART_CODIGOICE': {
        this.busquedaice(event.target.value);
        break;
      }
      case 'ART_VALORICE': {
        element = document.getElementById('ART_CODSRIICE');
        break;
      }
      case 'ART_CODSRIICE': {
        this.busquedacsriice(event.target.value);
        break;
      }
      case 'ART_DESCUENTO': {
        element = document.getElementById('ART_COSTOHISTORICO');
        break;
      }
      case 'ART_COSTOHISTORICO': {
        element = document.getElementById('ART_IRBPNR');// CDPJ
        break;
      } case 'ART_IRBPNR': {//CDPJ
        element = document.getElementById('ART_PESO');
        break;
      } case 'TARIIVA_CODIGO': {//CDPJ
        this.busquedatariivacod(event.target.value);
        break;
      } case 'tariivacod': {
        this.busquedaivacod(event.target.value)
        break;
      }
      //CDPJ
      case 'ART_PESO': {
        element = document.getElementById('ART_LARGO');
        break;
      }
      case 'ART_LARGO': {
        element = document.getElementById('ART_ANCHO');
        break;
      }
      case 'ART_ANCHO': {
        element = document.getElementById('ART_ALTURA');
        break;
      }
      case 'ART_ALTURA': {
        element = document.getElementById('ART_DENSIDAD');
        break;
      }
      case 'ART_DENSIDAD': {
        element = document.getElementById('ART_VOLUMEN');
        break;
      }
      case 'ART_VOLUMEN': {
        element = document.getElementById('ART_MODELO');
        break;
      }
      case 'ART_MODELO': {
        element = document.getElementById('ART_MARCA');
        break;
      }
      case 'ART_MARCA': {
        element = document.getElementById('ART_SERIE');
        break;
      }
      case 'ART_SERIE': {
        element = document.getElementById('ART_UNIDADCOSTEO');
        break;
      }
      case 'ART_UNIDADCOSTEO': {
        this.busquedaunidadcom(event.target.value);
        break;
      }
      case 'ART_UNIDADVENTA': {
        this.busquedaunidadven(event.target.value);
        break;
      }
      case 'ART_CANTMIN': {
        element = document.getElementById('ART_CANTMAX');
        break;
      }
      case 'ART_CANTMAX': {
        element = document.getElementById('ART_SUBSIDIO');
        break;
      }
      case 'ART_SUBSIDIO': {
        element = document.getElementById('ART_OBSERVACION');
        break;
      }
      case 'ART_OBSERVACION': {
        element = document.getElementById('GRUP_CODIGO');
        break;
      }
      case 'ART_CODIGOALT3': {
        element = document.getElementById('ART_CODIGOALT4');
        break;
      }
      case 'ART_CODIGOALT4': {
        element = document.getElementById('CON_CODIGOACT');
        break;
      }
      case 'CON_CODIGOACT': {
        this.busquedacodigoact(event.target.value);
        break;
      }
      case 'CON_CODIGODSCTO': {
        this.busquedacodigodscto(event.target.value);
        break;
      }
      case 'CON_CODIGOGAS': {
        this.busquedacodigogas(event.target.value);
        break;
      }
      case 'CON_CODIGOING2': {
        this.busquedacodigoing2(event.target.value);
        break;
      }
      case 'CON_CODIGOING': {
        this.busquedacodigoing(event.target.value);
        break;
      }
      case 'CON_CODIGODSCTO2': {
        this.busquedacodigodscto2(event.target.value);
        break;
      }
      case 'CON_CODIGOORD1': {
        this.busquedacodigoord1(event.target.value);
        break;
      }
      case 'CON_CODIGOPRODPROC': {
        this.busquedacodigoprodproc(event.target.value);
        break;
      }
      case 'CEN_CODIGO': {
        this.busquedacodigocentro(event.target.value);
        break;
      }

      case 'CON_CODIGOORD2': {
        this.busquedacodigoord2(event.target.value);
        break;
      }
      case 'ART_FORMSRICOM': {
        this.busquedarescom(event.target.value);
        break;
      }
      case 'ART_FORMSRIVTAS': {
        this.busquedaresvta(event.target.value);
        break;
      }
      case 'ART_CAMPANIA': {
        this.busquedacampana(event.target.value);
        break;
      }
      case 'PRESENTACION_CODIGO': {
        element = document.getElementById('ART_FACTOR');
        break;
      }
      case 'ART_FACTOR': {
        element = document.getElementById('ART_CODIGOALT3');
        break;
      }

      case 'ART_CODIGOESPTEC': {
        element = document.getElementById('ART_CODIGOALT1ESPTEC');
        break;
      }
      case 'ART_CODIGOALT1ESPTEC': {
        element = document.getElementById('ART_CODIGOALT2ESPTEC');
        break;
      }
      case 'ART_CODIGOALT2ESPTEC': {
        element = document.getElementById('ART_CODIGOALT3ESPTEC');
        break;
      }
      case 'ART_CODIGOALT3ESPTEC': {
        element = document.getElementById('ART_CODIGOALT4ESPTEC');
        break;
      }
      case 'ART_CODIGOALT4ESPTEC': {
        element = document.getElementById('ESPTEC_CODFAB');
        break;
      }
      case 'ESPTEC_CODFAB': {
        element = document.getElementById('ESPTEC_COSTFAB');
        break;
      }
      case 'ESPTEC_COSTFAB': {
        element = document.getElementById('ESPTEC_NOMBREIMP');
        break;
      }
      case 'ESPTEC_NOMBREIMP': {
        element = document.getElementById('ESPTEC_PARTIDA');
        break;
      }
      case 'ESPTEC_PARTIDA': {
        element = document.getElementById('ESPTEC_ARANCEL');
        break;
      }
      case 'ESPTEC_ARANCEL': {
        element = document.getElementById('ESPTEC_ICE');
        break;
      }
      case 'ESPTEC_ICE': {
        element = document.getElementById('ESPTEC_VOL');
        break;
      }
      case 'ESPTEC_VOL': {
        element = document.getElementById('ESPTEC_MBOX');
        break;
      }
      case 'ESPTEC_MBOX': {
        element = document.getElementById('ART_CODIGOESPTEC');
        break;
      }
      case 'ESPTEC_FOTO': {
        element = document.getElementById('ESPTEC_DATTEC');
        break;
      }
      case 'ESPTEC_DATTEC': {
        element = document.getElementById('ESPTEC_ADVERTENCIA');
        break;
      }
      case 'ESPTEC_ADVERTENCIA': {
        element = document.getElementById('ESPTEC_FOTO');
        break;
      }

      case 'ESPTEC_ZONADECONTROL': {
        element = document.getElementById('ESPTEC_POSICION');
        break;
      }
      case 'ESPTEC_POSICION': {
        element = document.getElementById('ESPTEC_PAIS');
        break;
      }
      case 'ESPTEC_PAIS': {
        element = document.getElementById('ESPTEC_FLUJOLUMINICO');
        break;
      }
      case 'ESPTEC_FLUJOLUMINICO': {
        element = document.getElementById('ESPTEC_POTENCIA');
        break;
      }
      case 'ESPTEC_POTENCIA': {
        element = document.getElementById('ESPTEC_VOLTAJEINPUT');
        break;
      }
      case 'ESPTEC_VOLTAJEINPUT': {
        element = document.getElementById('ESPTEC_ANGULOAPERTURA');
        break;
      }
      case 'ESPTEC_ANGULOAPERTURA': {
        element = document.getElementById('ESPTEC_FLETE');
        break;
      }
      case 'ESPTEC_FLETE': {
        element = document.getElementById('ESPTEC_SEGURO');
        break;
      }
      case 'ESPTEC_SEGURO': {
        element = document.getElementById('ESPTEC_SALVAGUARDA');
        break;
      }
      case 'ESPTEC_SALVAGUARDA': {
        element = document.getElementById('ESPTEC_ATENUABLE');
        break;
      }
      case 'ESPTEC_ATENUABLE': {
        element = document.getElementById('ESPTEC_CORRIENTE');
        break;
      }
      case 'ESPTEC_CORRIENTE': {
        element = document.getElementById('ESPTEC_CRI');
        break;
      }
      case 'ESPTEC_CRI': {
        element = document.getElementById('ESPTEC_TEMPERATURA');
        break;
      }
      case 'ESPTEC_TEMPERATURA': {
        element = document.getElementById('ESPTEC_IP');
        break;
      }
      case 'ESPTEC_IP': {
        element = document.getElementById('ESPTEC_POTENCIATOTAL');
        break;
      }
      case 'ESPTEC_POTENCIATOTAL': {
        element = document.getElementById('ESPTEC_ZONADECONTROL');
        break;
      }
      // -------------------------CASE VEHICULO --------------------------
      case 'ART_RAMV': {
        element = document.getElementById('ART_MOTOR');
        break;
      }
      case 'ART_MOTOR': {
        element = document.getElementById('ART_MODELOSRI');
        break;
      }
      case 'ART_MODELOSRI': {
        element = document.getElementById('ART_SERIEV');
        break;
      }
      case 'ART_SERIEV': {
        element = document.getElementById('ART_CAPACIDAD');
        break;
      }
      case 'ART_CAPACIDAD': {
        element = document.getElementById('ART_CILINDRAJE');
        break;
      }
      case 'ART_CILINDRAJE': {
        element = document.getElementById('ART_COLOR');
        break;
      }
      case 'ART_COLOR': {
        element = document.getElementById('ART_ANIO');
        break;
      }
      case 'ART_ANIO': {
        element = document.getElementById('ART_MARCAV');
        break;
      }
      case 'ART_TONELAJE': {
        element = document.getElementById('ART_CLASE');
        break;
      }
      case 'ART_NEJES': {
        element = document.getElementById('ART_TIPOV');
        break;
      }
      case 'ART_NRUEDAS': {
        element = document.getElementById('ART_COMBUSTIBLE');
        break;
      }
      case 'ART_FFABRICA': {
        element = document.getElementById('ART_DISPOSITIVO');
        break;
      }
      case 'ART_MODELOAT': {
        element = document.getElementById('ART_PORIGEN');
        break;
      }
      case 'ART_DPAGO': {
        element = document.getElementById('ART_BANCO');
        break;
      }
      case 'ART_MARCAV': {
        this.busquedamarcavehiculo(event.target.value);
        break;
      }

      case 'ART_CLASE': {
        this.busquedaclasevehiculo(event.target.value);
        break;
      }
      case 'ART_TIPOV': {
        this.busquedatipovehiculo(event.target.value);
        break;
      }
      case 'ART_COMBUSTIBLE': {
        this.busquedacomvehiculo(event.target.value);
        break;
      }
      case 'ART_DISPOSITIVO': {
        this.busquedadisvehiculo(event.target.value);
        break;
      }
      case 'ART_PORIGEN': {
        this.busquedapaisvehiculo(event.target.value);
        break;
      }
      case 'ART_BANCO': {
        this.busquedabanco(event.target.value);
        break;
      }
      case 'verimagen': {
        this.verimagen(event.target.value);
        break;
      }


      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  busquedaarticulo() {
    this.opcionbusqueda = 'busquedaarticulo';
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Bod-Def', value: 'ART_UBICACION' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Ubicación Física', value: 'ART_CODIGOALT2' },
      { label: 'Alterno3', value: 'ART_CODIGOALT3' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ROWNUM < 100';

    this.busqService.busquedaInvMaeArticulo2('ROWNUM < 100').subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  busquedagrupo(parametro) {
    if (this.GRUNIVELES === true) {
      this.gruniv = [];
      this.arbolGrupo();
      return;
    }

    this.opcionbusqueda = 'busquedagrupo';
    this.types = [
      { label: 'Código', value: 'GRUP_CODIGO' },
      { label: 'Nombre', value: 'GRUP_NOMBRE' }
    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'INV_MAEGRUPO';
    this.where = 'GRUP_CODIGOP IS NULL';

    this.artService.encontrarGruNombre(this.types[0].value +
      ' LIKE \'' + parametro + '\'').subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.maearticulo.GRUP_CODIGO = parametro;
            this.maearticulo.GRUP_NOMBRE = eR[0].GRUP_NOMBRE;
            this.botonesmodificar();
            document.getElementById('GRUP_CODIGOP').focus();
          } else {
            this.busqService.busquedaInvMaeGrupo1('GRUP_CODIGOP IS NULL').subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busqService.busquedaInvMaeGrupo1('GRUP_CODIGOP IS NULL').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  busquedasubgrupo(parametro) {
    this.opcionbusqueda = 'busquedasubgrupo';
    this.types = [
      { label: 'Código', value: 'GRUP_CODIGO' },
      { label: 'Nombre', value: 'GRUP_NOMBRE' }
    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'INV_MAEGRUPO';
    this.where = 'GRUP_CODIGOP=\'' + this.maearticulo.GRUP_CODIGO + '\'';

    this.artService.encontrarGruNombre('GRUP_CODIGOP=\'' +
      this.maearticulo.GRUP_CODIGO + '\' AND ' + this.types[0].value +
      ' LIKE \'' + parametro + '\'').subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.maearticulo.GRUP_CODIGOP = parametro;
            this.maearticulo.GRUP_NOMBREP = eR[0].GRUP_NOMBRE;
            this.botonesmodificar();
            document.getElementById('ART_CODIGO').focus();
          } else {
            this.busqService.busquedaInvMaeGrupo1('GRUP_CODIGOP=\'' +
              this.maearticulo.GRUP_CODIGO + '\'').subscribe((datos: any[]) => {
                this.arregloCons = datos;
                this.displayDialogBusquedaFast = true;
              });
          }
        } else {

          this.busqService.busquedaInvMaeGrupo1('GRUP_CODIGOP=\'' +
            this.maearticulo.GRUP_CODIGO + '\'').subscribe((datos: any[]) => {
              this.arregloCons = datos;
              this.displayDialogBusquedaFast = true;
            });
        }
      });
  }
  //CDPJ
  busquedatariivacod(parametro) {
    this.opcionbusqueda = 'busquedatariiva';
    this.types = [
      { label: 'Código', value: 'TARIIVA_CODIGO' },
      { label: 'Descripcion', value: 'TARIIVA_DESCRIPCION' },
      { label: 'Porcentaje', value: 'TARIIVA_PORCENTAJE' }
    ];

    this.busquedacampos = [parametro, '', '', ''];
    this.tabla = 'SRIFE_TARIIVA';
    this.where = 'TARIIVA_ACTIVO=\'S\'';
    if (this.chktributaiva === true) {
      this.where = this.where + ' and TARIIVA_PORCENTAJE<>0 '
    } else {
      this.where = this.where + ' and TARIIVA_PORCENTAJE=0 '
    }


    this.artService.encontrartariiva(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.TARIIVA_CODIGO = eR[0].TARIIVA_CODIGO;
          this.maearticulo.TARIIVA_DESCRIPCION = eR[0].TARIIVA_DESCRIPCION;
          this.botonesmodificar();
          document.getElementById('ART_CODIGOICE').focus();
        } else {
          this.busqService.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  //CDPJ
  busquedaice(parametro) {
    this.opcionbusqueda = 'busquedaice';
    this.types = [
      { label: 'Tarifa', value: 'IMP_TARIFA' },
      { label: 'Descripcion', value: 'IMP_DESCRIPCION' },
      { label: 'Porcentaje', value: 'IMP_PORCENTAJE' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'VEN_MAEIMPUESTO';
    this.where = 'IMP_TIPO=\'C\'';

    this.artService.encontrarImpDescripcion(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_CODIGOICE = parametro;
          this.maearticulo.IMP_DESCRIPCION = eR[0].IMP_DESCRIPCION;
          this.botonesmodificar();
          document.getElementById('ART_VALORICE').focus();
        } else {
          this.busqService.busquedaVenMaeImpuesto1().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaVenMaeImpuesto1().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  busquedacsriice(parametro) {
    this.opcionbusqueda = 'busquedacsriice';
    this.types = [
      { label: 'Codigo', value: 'CODSRI_CODIGO' },
      { label: 'Descripcion', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'CodigoCon', value: 'CON_CODIGO' }
    ];

    this.busquedacampos = ['', '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.where = ' CODSRI_TIPO=\'PC\'';

    this.artService.encontrarCodSriDescrip(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_CODSRIICE = parametro;
          this.maearticulo.ART_NOMSRIICE = eR[0].CODSRI_DESCRIPCION;
          this.botonesmodificar();
          document.getElementById('ART_DESCUENTO').focus();
        } else {
          this.busqService.busquedaAnexMaeCodSri16().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri16().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  busquedaunidadcom(parametro) {
    this.opcionbusqueda = 'busquedaunidadcom';
    this.types = [
      { label: 'Codigo', value: 'UNI_CODIGO' },
      { label: 'Nombre', value: 'UNI_NOMBRE' },
      { label: 'Simbolo', value: 'UNI_SIMBOLO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'INV_MAEUNIDAD';
    this.where = '';

    this.artService.encontrarUniNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_UNIDADCOSTEO = parametro;
          this.maearticulo.NOMBREUNIDADCOMPRA = eR[0].UNI_NOMBRE;
          this.botonesmodificar();
          document.getElementById('ART_UNIDADVENTA').focus();
        } else {
          this.busqService.busquedaInvMaeUnidad1().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaInvMaeUnidad1().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaunidadven(parametro) {
    this.opcionbusqueda = 'busquedaunidadven';
    this.types = [
      { label: 'Codigo', value: 'UNI_CODIGO' },
      { label: 'Nombre', value: 'UNI_NOMBRE' },
      { label: 'Simbolo', value: 'UNI_SIMBOLO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'INV_MAEUNIDAD';
    this.where = '';

    this.artService.encontrarUniNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_UNIDADVENTA = parametro;
          this.maearticulo.NOMBREUNIDADVENTA = eR[0].UNI_NOMBRE;
          this.botonesmodificar();
          document.getElementById('ART_CANTMIN').focus();
        } else {
          this.busqService.busquedaInvMaeUnidad1().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaInvMaeUnidad1().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  busquedacodigoact(parametro) {
    this.opcionbusqueda = 'busquedacodigoact';
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.artService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          //GSRF
          if (parametro.substr(-1, 1) === '.') {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            this.maearticulo.CON_CODIGOACT = '';
            this.maearticulo.CON_NOMBREACT = '';
            return
          }
          //GSRF
          this.maearticulo.CON_CODIGOACT = parametro;
          this.maearticulo.CON_NOMBREACT = eR[0].CON_NOMBRE;
          this.botonesmodificar();
          document.getElementById('CON_CODIGODSCTO').focus();
        } else {
          this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  busquedacodigodscto(parametro) {
    this.opcionbusqueda = 'busquedacodigodscto';
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.artService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          //GSRF
          if (parametro.substr(-1, 1) === '.') {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            this.maearticulo.CON_CODIGODSCTO = '';
            this.maearticulo.CON_NOMBREDSCTO = '';
            return
          }
          //GSRF
          this.maearticulo.CON_CODIGODSCTO = parametro;
          this.maearticulo.CON_NOMBREDSCTO = eR[0].CON_NOMBRE;
          this.botonesmodificar();
          document.getElementById('CON_CODIGOGAS').focus();
        } else {
          this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacodigogas(parametro) {
    this.opcionbusqueda = 'busquedacodigogas';
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];
    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.artService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          //GSRF
          if (parametro.substr(-1, 1) === '.') {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            this.maearticulo.CON_CODIGOGAS = '';
            this.maearticulo.CON_NOMBREGAS = '';
            return
          }
          //GSRF
          this.maearticulo.CON_CODIGOGAS = parametro;
          this.maearticulo.CON_NOMBREGAS = eR[0].CON_NOMBRE;
          this.botonesmodificar();
          document.getElementById('CON_CODIGOING2').focus();
        } else {
          this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacodigoing2(parametro) {
    this.opcionbusqueda = 'busquedacodigoing2';
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.artService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          //GSRF
          if (parametro.substr(-1, 1) === '.') {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            this.maearticulo.CON_CODIGOING2 = '';
            this.maearticulo.CON_NOMBREING2 = '';
            return
          }
          //GSRF
          this.maearticulo.CON_CODIGOING2 = parametro;
          this.maearticulo.CON_NOMBREING2 = eR[0].CON_NOMBRE;
          this.botonesmodificar();
          document.getElementById('CON_CODIGOING').focus();
        } else {
          this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacodigoing(parametro) {
    this.opcionbusqueda = 'busquedacodigoing';
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.artService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          //GSRF
          if (parametro.substr(-1, 1) === '.') {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            this.maearticulo.CON_CODIGOING = '';
            this.maearticulo.CON_NOMBREING = '';
            return
          }
          //GSRF
          this.maearticulo.CON_CODIGOING = parametro;
          this.maearticulo.CON_NOMBREING = eR[0].CON_NOMBRE;
          this.botonesmodificar();
          document.getElementById('CON_CODIGODSCTO2').focus();
        } else {
          this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacodigodscto2(parametro) {
    this.opcionbusqueda = 'busquedacodigodscto2';
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];
    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.artService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          //GSRF
          if (parametro.substr(-1, 1) === '.') {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            this.maearticulo.CON_CODIGODSCTO2 = '';
            this.maearticulo.CON_NOMBREDSCTO2 = '';
            return
          }
          //GSRF
          this.maearticulo.CON_CODIGODSCTO2 = parametro;
          this.maearticulo.CON_NOMBREDSCTO2 = eR[0].CON_NOMBRE;
          this.botonesmodificar();
          document.getElementById('CON_CODIGOORD1').focus();
        } else {
          this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacodigoord1(parametro) {
    this.opcionbusqueda = 'busquedacodigoord1';
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.artService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          //GSRF
          if (parametro.substr(-1, 1) === '.') {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            this.maearticulo.CON_CODIGOORD1 = '';
            this.maearticulo.CON_NOMBREORD1 = '';
            return
          }
          //GSRF
          this.maearticulo.CON_CODIGOORD1 = parametro;
          this.maearticulo.CON_NOMBREORD1 = eR[0].CON_NOMBRE;
          this.botonesmodificar();
          document.getElementById('CON_CODIGOPRODPROC').focus();
        } else {
          this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacodigoprodproc(parametro) {
    this.opcionbusqueda = 'busquedacodigoprodproc';
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.artService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          //GSRF
          if (parametro.substr(-1, 1) === '.') {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            this.maearticulo.CON_CODIGOPRODPROC = '';
            this.maearticulo.CON_NOMBREPRODPROC = '';
            return
          }
          //GSRF
          this.maearticulo.CON_CODIGOPRODPROC = parametro;
          this.maearticulo.CON_NOMBREPRODPROC = eR[0].CON_NOMBRE;
          this.botonesmodificar();
          document.getElementById('CON_CODIGOORD2').focus();
        } else {
          this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacodigocentro(parametro) {
    this.opcionbusqueda = 'busquedacodigocentro';
    this.types = [
      { label: 'Codigo', value: 'CEN_CODIGO' },
      { label: 'Nombre', value: 'CEN_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'CON_MAECEN';
    this.where = '';

    this.artService.encontrarCenCosto(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.CEN_CODIGO = parametro;
          this.maearticulo.CEN_NOMBRE = eR[0].CEN_NOMBRE;
          this.botonesmodificar();
          document.getElementById('ART_FORMSRICOM').focus();
        } else {
          this.busqService.busquedaConMaeCen4().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCen4().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacodigoord2(parametro) {
    this.opcionbusqueda = 'busquedacodigoord2';
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];
    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.artService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          //GSRF
          if (parametro.substr(-1, 1) === '.') {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            this.maearticulo.CON_CODIGOORD2 = '';
            this.maearticulo.CON_NOMBREORD2 = '';
            return
          }
          //GSRF
          this.maearticulo.CON_CODIGOORD2 = parametro;
          this.maearticulo.CON_NOMBREORD2 = eR[0].CON_NOMBRE;
          this.botonesmodificar();
          document.getElementById('ART_FORMSRICOM').focus();
        } else {
          this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedarescom(parametro) {
    this.opcionbusqueda = 'busquedarescom';
    this.types = [
      { label: 'Codigo', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'REF_TIPO=\'RESCOM\'';

    this.artService.encontrarRefNombre('RESCOM', parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_FORMSRICOM = parametro;
          this.maearticulo.ART_NOMFORMSRICOM = eR[0].REF_NOMBRE;
          this.botonesmodificar();
          document.getElementById('ART_FORMSRIVTAS').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'RESCOM\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'RESCOM\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });

  }

  busquedaresvta(parametro) {
    this.opcionbusqueda = 'busquedaresvta';
    this.types = [
      { label: 'Codigo', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'REF_TIPO=\'RESVTA\'';

    this.artService.encontrarRefNombre('RESVTA', parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_FORMSRIVTAS = parametro;
          this.maearticulo.ART_NOMFORMSRIVTAS = eR[0].REF_NOMBRE;
          this.botonesmodificar();
          document.getElementById('ART_CAMPANIA').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'RESVTA\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'RESVTA\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacampana(parametro) {
    this.opcionbusqueda = 'busquedacampana';
    this.types = [
      { label: 'Codigo', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'REF_TIPO=\'CAMPA\'';

    this.artService.encontrarRefNombre('CAMPA', parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_CAMPANIA = parametro;
          this.botonesmodificar();
          document.getElementById('ART_CAMPANIA').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'CAMPA\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'CAMPA\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaarticulodetalle(rowData, opcionbusqueda) {
    this.opcionbusqueda = opcionbusqueda;
    this.indexunidad = this.artService.maeartparte.findIndex(parte => parte.ART_CODIGOP === rowData.ART_CODIGOP);
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'NombreCorto', value: 'ART_NOMBREP' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Ubicación', value: 'ART_UBICACION' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Ubicación Física', value: 'ART_CODIGOALT2' },
      { label: 'Alterno3', value: 'ART_CODIGOALT3' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';

    this.busqService.busquedaInvMaeArticulo2('ROWNUM < 10').subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  //CDPJ
  busquedaivacod(parametro) {
    this.opcionbusqueda = 'busquedatariivacod';
    this.types = [
      { label: 'Código', value: 'TARIIVA_CODIGO' },
      { label: 'Descripcion', value: 'TARIIVA_DESCRIPCION' },
      { label: 'Porcentaje', value: 'TARIIVA_PORCENTAJE' }
    ];

    this.busquedacampos = [parametro, '', '', ''];
    this.tabla = 'SRIFE_TARIIVA';
    this.where = 'TARIIVA_ACTIVO=\'S\'';
    //if(this.chktributaiva === true){
    this.where = this.where + ' and TARIIVA_PORCENTAJE<>0 '
    //}
    // else{
    //   this.where=this.where+' and TARIIVA_PORCENTAJE=0 '
    // }
    this.artService.encontrartariivacod(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.tariivacod = eR[0].TARIIVA_CODIGO;
          this.tariivadescripcion = eR[0].TARIIVA_DESCRIPCION;
        } else {
          this.busqService.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFasttariva = true;
          });
        }
      } else {
        this.busqService.busquedaSrifeTariIva(this.where).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFasttariva = true;
        });
      }
    });
  }
  //CDPJ
  manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'busquedaarticulo') {
      this.artService.spin = true;
      this.encontrarArticulo(opcion.ART_CODIGO);
    }
    if (this.opcionbusqueda === 'busquedagrupo') {
      this.maearticulo.GRUP_CODIGO = opcion.GRUP_CODIGO;
      this.maearticulo.GRUP_NOMBRE = opcion.GRUP_NOMBRE;
      document.getElementById('GRUP_CODIGOP').focus();
    }
    if (this.opcionbusqueda === 'busquedasubgrupo') {
      this.maearticulo.GRUP_CODIGOP = opcion.GRUP_CODIGO;
      this.maearticulo.GRUP_NOMBREP = opcion.GRUP_NOMBRE;
      document.getElementById('ART_CODIGO').focus();
    }
    if (this.opcionbusqueda === 'busquedaice') {
      this.maearticulo.ART_CODIGOICE = opcion.IMP_TARIFA;
      this.maearticulo.IMP_DESCRIPCION = opcion.IMP_DESCRIPCION;
      document.getElementById('ART_VALORICE').focus();
    }
    //CDPJ
    if (this.opcionbusqueda === 'busquedatariiva') {
      this.maearticulo.TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      this.maearticulo.TARIIVA_DESCRIPCION = opcion.TARIIVA_DESCRIPCION;
      this.maearticulo.ART_PORIVA = opcion.TARIIVA_PORCENTAJE;
      document.getElementById('ART_CODIGOICE').focus();
    }
    if (this.opcionbusqueda === 'busquedatariivacod') {
      this.tariivacod = opcion.TARIIVA_CODIGO;
      this.tariivaporcentaje = opcion.TARIIVA_PORCENTAJE
      this.tariivadescripcion = opcion.TARIIVA_DESCRIPCION;
      this.displayDialogBusquedaFasttariva = false;
    }
    //CDPJ
    if (this.opcionbusqueda === 'busquedacsriice') {
      this.maearticulo.ART_CODSRIICE = opcion.CODSRI_CODIGO;
      this.maearticulo.ART_NOMSRIICE = opcion.CODSRI_DESCRIPCION;
      document.getElementById('ART_DESCUENTO').focus();

    }
    if (this.opcionbusqueda === 'busquedaunidadcom') {
      this.maearticulo.ART_UNIDADCOSTEO = opcion.UNI_CODIGO;
      this.maearticulo.NOMBREUNIDADCOMPRA = opcion.UNI_NOMBRE;
      document.getElementById('ART_UNIDADVENTA').focus();
    }
    if (this.opcionbusqueda === 'busquedaunidadven') {
      this.maearticulo.ART_UNIDADVENTA = opcion.UNI_CODIGO;
      this.maearticulo.NOMBREUNIDADVENTA = opcion.UNI_NOMBRE;
      document.getElementById('ART_CANTMIN').focus();
    }
    if (this.opcionbusqueda === 'busquedacodigoact') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maearticulo.CON_CODIGOACT = opcion.CON_CODIGO;
        this.maearticulo.CON_NOMBREACT = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGODSCTO').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedacodigodscto') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maearticulo.CON_CODIGODSCTO = opcion.CON_CODIGO;
        this.maearticulo.CON_NOMBREDSCTO = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGOGAS').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedacodigogas') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maearticulo.CON_CODIGOGAS = opcion.CON_CODIGO;
        this.maearticulo.CON_NOMBREGAS = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGOING2').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedacodigoing2') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maearticulo.CON_CODIGOING2 = opcion.CON_CODIGO;
        this.maearticulo.CON_NOMBREING2 = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGOING').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedacodigoing') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maearticulo.CON_CODIGOING = opcion.CON_CODIGO;
        this.maearticulo.CON_NOMBREING = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGODSCTO2').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedacodigodscto2') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maearticulo.CON_CODIGODSCTO2 = opcion.CON_CODIGO;
        this.maearticulo.CON_NOMBREDSCTO2 = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGOORD1').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedacodigoord1') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maearticulo.CON_CODIGOORD1 = opcion.CON_CODIGO;
        this.maearticulo.CON_NOMBREORD1 = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGOPRODPROC').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedacodigoprodproc') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maearticulo.CON_CODIGOPRODPROC = opcion.CON_CODIGO;
        this.maearticulo.CON_NOMBREPRODPROC = opcion.CON_NOMBRE;
        document.getElementById('CON_CODIGOORD2').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedacodigoord2') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.maearticulo.CON_CODIGOORD2 = opcion.CON_CODIGO;
        this.maearticulo.CON_NOMBREORD2 = opcion.CON_NOMBRE;
        document.getElementById('ART_FORMSRICOM').focus();
      }
    }
    if (this.opcionbusqueda === 'busquedarescom') {
      this.maearticulo.ART_FORMSRICOM = opcion.REF_CODIGO;
      this.maearticulo.ART_NOMFORMSRICOM = opcion.REF_NOMBRE;
      document.getElementById('ART_FORMSRIVTAS').focus();

    }
    if (this.opcionbusqueda === 'busquedaresvta') {
      this.maearticulo.ART_FORMSRIVTAS = opcion.REF_CODIGO;
      this.maearticulo.ART_NOMFORMSRIVTAS = opcion.REF_NOMBRE;
      document.getElementById('ART_CAMPANIA').focus();

    }
    if (this.opcionbusqueda === 'busquedacampana') {
      this.maearticulo.ART_CAMPANIA = opcion.REF_CODIGO;
      this.maearticulo.CAMPANIA_NOMBRE = opcion.REF_NOMBRE;

    }
    if (this.opcionbusqueda === 'busquedacodigocentro') {
      this.maearticulo.CEN_CODIGO = opcion.CEN_CODIGO;
      this.maearticulo.CEN_NOMBRE = opcion.CEN_NOMBRE;
      document.getElementById('ART_FORMSRICOM').focus();

    }

    // -------cambios vehiculos-------------
    if (this.opcionbusqueda === 'busquedamarcavehiculo') {
      this.maearticulo.ART_MARCAV = opcion.REF_CODIGO;
      this.maearticulo.ART_MARVEH = opcion.REF_NOMBRE;
      document.getElementById('ART_TONELAJE').focus();

    }
    if (this.opcionbusqueda === 'busquedaclasevehiculo') {
      this.maearticulo.ART_CLAVEH = opcion.REF_NOMBRE;
      this.maearticulo.ART_CLASE = opcion.REF_CODIGO;
      document.getElementById('ART_NEJES').focus();
    }
    if (this.opcionbusqueda === 'busquedatipovehiculo') {
      this.maearticulo.ART_TIPVEH = opcion.REF_NOMBRE;
      this.maearticulo.ART_TIPOV = opcion.REF_CODIGO;
      document.getElementById('ART_NRUEDAS').focus();
    }
    if (this.opcionbusqueda === 'busquedacomvehiculo') {
      this.maearticulo.ART_COMVEH = opcion.REF_NOMBRE;
      this.maearticulo.ART_COMBUSTIBLE = opcion.REF_CODIGO;
      document.getElementById('ART_FFABRICA').focus();
    }
    if (this.opcionbusqueda === 'busquedadisvehiculo') {
      this.maearticulo.ART_DISVEH = opcion.REF_NOMBRE;
      this.maearticulo.ART_DISPOSITIVO = opcion.REF_CODIGO;
      document.getElementById('ART_MODELOAT').focus();
    }
    if (this.opcionbusqueda === 'busquedapaisvehiculo') {
      this.maearticulo.ART_P = opcion.REF_NOMBRE;
      this.maearticulo.ART_PORIGEN = opcion.REF_CODIGO;
      document.getElementById('ART_DPAGO').focus();
    }
    if (this.opcionbusqueda === 'busquedabanco') {
      this.maearticulo.ART_BANCO = opcion.BANCLI_CODIGO;
      this.maearticulo.ART_NBANCO = opcion.BANCLI_NOMBRE;
      //document.getElementById('verimagen').focus();
    }

    // ------------ FIN cambios vehiculos-------------

    if (this.opcionbusqueda === 'verimagen') {
      this.verimagen(opcion.ART_CODIGO);
    }

    this.botonesmodificar();
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;

  }


  async desplegarCuentasContables() {
    const datos: any = await this.artService.getcuentascontables();

    if (datos !== null) {
      this.maearticulo.CON_CODIGOACT = datos[0].CON_CODIGOACT;
      this.maearticulo.CON_CODIGOGAS = datos[0].CON_CODIGOGAS;
      this.maearticulo.CON_CODIGOING = datos[0].CON_CODIGOING;
      this.maearticulo.CON_CODIGODSCTO = datos[0].CON_CODIGODSCTO;
      this.maearticulo.CON_CODIGOING2 = datos[0].CON_CODIGOING2;
      this.maearticulo.CON_CODIGODSCTO2 = datos[0].CON_CODIGODSCTO2;
      this.maearticulo.CON_CODIGOORD1 = datos[0].CON_CODIGOORD1;
      this.maearticulo.CON_CODIGOORD2 = datos[0].CON_CODIGOORD2;
    }

  }
  //CDPJ
  modporceiva() {
    this.closeSidebar();
    this.claveDialog = true;
    this.accion = 'claveiva'
  }
  comprobarClave() {
    //CDPJ
    if (this.accion === 'claveiva') {
      this.artService.obtenerClaveIva().subscribe((clave) => {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          //this.boolIva=false
          this.claveAuth = '';
          this.displayDialogactiva = true;
        } else if (clave !== this.claveAuth) {
          this.claveAuth = '';
          this.messageService.add({
            key: 'artmaeclave',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      });
    }
  }
  //CDPJ
  cambio() {
  }

  seleccionprecio(params) {

    if (params === 'nuevo') {
      this.nuevo();

      return;
    }

    if (params === 'guardar') {
      for (const item of this.artService.maeartprecio) {
        this.artService.saveprecioservice(item);
      }

      this.artService.maeartprecio.map(precio => {
        precio.NUEVO = false;
      })
      // this.artService.saveprecioservice(this.precioSeleccionado);
      // return;
    }

    if (params === 'cancelar') {
      this.cancelar();
      return;
    }

    if (params === 'eliminar') {
      this.eliminar();
      return;
    }

    this.precioSeleccionado = params.object;
    this.init.auxsalto = false;

  }

  seleccionparte2(params) {
    if (params === 'guardar') {
      for (const item of this.artService.maeartparte) {
        this.artService.saveparteservice(item);
      }
      // this.artService.saveparteservice(this.parteSeleccionada);
      // return;
    }

    if (params === 'cancelar') {
      this.cancelar();
      return;
    }

    if (params === 'eliminar') {
      this.eliminar();
      return;
    }

    if (params === 'nuevo') {
      this.nuevo();
      return;
    }

    this.parteSeleccionada = params.object;
    this.init.auxsalto = false;

  }
  async validarUnicodigo() {
    let bool = true;
    for (const item of this.artService.maeartformula) {
      let unicosto = '';
      let univenta = '';
      const unidad = await this.artService.erUniCodigo1Prom(item.UNI_CODIGO)
      const data = await this.artService.getUnidadCosteoArticulo(item.ART_CODIGOP);
      const data1 = await this.artService.getunidadventasart(item.ART_CODIGOP);
      for (const rs of data) {
        if (rs !== null && rs !== null) {
          unicosto = rs.UNICOSTEO;
        }
      }
      for (const rs of data1) {
        if (rs !== null && rs !== null) {
          univenta = rs.UNIVENTA;
        }
      }
      if (item.UNI_CODIGO === null || item.UNI_CODIGO === undefined || item.UNI_CODIGO === '') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo Unidad está vacío'
        });
        bool = false;
      }
      if (unidad === null || unidad === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'No Existe la unidad ' + item.UNI_CODIGO
        });
        bool = false;
      }
      if (unicosto === null || unicosto === undefined || unicosto === '') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'Hubo un error al consultar la unidad de costeo del artículo ' + item.ART_CODIGOP
        });
        bool = false;
      }
      if (unicosto === null || unicosto === undefined || unicosto === '') {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'Hubo un error al consultar la unidad de venta del artículo ' + item.ART_CODIGOP
        });
        bool = false;
      }
      if (item.ART_MULTIUNIDAD === 'N' && item.UNI_CODIGO !== unicosto) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'El artículo ' + item.ART_CODIGOP + ', no es multiunidad y no puede cambiar su código de unidad'
        });
        bool = false;
      }
      if (item.ART_MULTIUNIDAD === 'S' && item.UNI_CODIGO !== unicosto && item.UNI_CODIGO !== univenta) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'La unidad ' + item.UNI_CODIGO + ' del artículo ' + item.ART_CODIGOP + ' no es ni unidad de venta ni unidad de costeo, por favor revisar la ficha del artículo'
        });
        bool = false;
      }
    }
    return bool;
  }
  async seleccionformula(params) {
    if (params === 'guardar') {
      try {
        this.artService.spin = true//CDPJ
        if (await this.validarUnicodigo() === false) {
          this.artService.spin = false//CDPJ
          return;
        }

        for (const item of this.artService.maeartformula) {
          this.artService.spin = true//CDPJ
          this.artService.saveformulaservice(item);
        }
        this.messageService.add({
          key: 'art',
          severity: 'success',
          summary: 'Información',
          detail: 'Datos guardados correctamente'
        });
      } catch (error) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'Los datos no se guardaron correctamente'
        });
        this.artService.spin = false//CDPJ
      }
      this.artService.spin = false//CDPJ
    }

    if (params === 'cancelar') {
      this.cancelar();
      return;
    }

    if (params === 'eliminar') {
      this.eliminar();
      return;
    }

    if (params === 'nuevo') {
      this.nuevo();
      return;
    }

    this.formulaSeleccionada = params.object;
    this.init.auxsalto = false;
  }

  seleccioncostoest(params) {

    if (params === 'guardar') {
      for (const item of this.artService.maecostoestand) {
        item.CEST_CANTIDAD = this.txtNumArtCosEst === '' ? '' : Number(this.txtNumArtCosEst);
        this.artService.savecostoestservice(item);
        this.artService.actualizarCantCostoEst(item.CEST_CANTIDAD, item.ART_CODIGO).subscribe(res => { });
      }
      this.messageService.add({
        key: 'art',
        severity: 'success',
        summary: 'Información',
        detail: 'Datos guardados correctamente'
      });
    }

    if (params === 'cancelar') {
      this.cancelar();
      return;
    }

    if (params === 'eliminar') {
      this.eliminar();
      return;
    }

    if (params === 'nuevo') {
      this.nuevo();
      return;
    }
    this.costoestanSeleccionado = params.object;
    this.init.auxsalto = false;
  }

  seleccionexistbod(params) {
    if (params === 'guardar') {
      for (const item of this.artService.maeexistbod) {
        this.artService.saveexistbodservice(item);
      }
      this.messageService.add({
        key: 'art',
        severity: 'success',
        summary: 'Información',
        detail: 'Datos guardados correctamente'
      });
    }

    if (params === 'cancelar') {
      this.cancelar();
      return;
    }

    if (params === 'eliminar') {
      this.eliminar();
      return;
    }

    if (params === 'nuevo') {
      this.nuevo();
      return;
    }

    this.existbodSeleccionada = params.object;
    this.init.auxsalto = false;
  }

  async eliminar() {
    if (this.index === 0 || this.index === 1) {
      this.artService.eliminarArticulo(this.maearticulo).subscribe((datos: any[]) => {
        this.artService.encontrarArtCodigomaeInv(this.maearticulo.ART_CODIGO).subscribe((existeart) => {
          if (existeart !== null) {
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Información',
              detail: 'No se puede eliminar el artículo porque tiene movimientos'
            });
            return;
          } else {
            this.messageService.add({
              key: 'art',
              severity: 'success',
              summary: 'Información',
              detail: 'Artículo Eliminado'
            });
            this.nuevoArt();
          }
        });
        this.auditoriagral.registrarAuditoria('INV_MAEARTICULO',
          this.maearticulo.ART_CODIGO, 'E', '', '01', '', '', '', '').subscribe();
      }, error1 => {
        const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Error',
          detail: error
        });
      });
    }

    if (this.index === 2) {

      if (this.precioSeleccionado === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'No se ha seleccionado el precio para eliminar'
        });
        return;
      }

      this.artService.eliminarPrecio(this.precioSeleccionado).subscribe((datos: any[]) => {
        this.artService.maeartprecio.splice(this.artService.maeartprecio.findIndex(
          precio => precio.ARTPRE_CODIGO === this.precioSeleccionado.ARTPRE_CODIGO),
          1);
        this.messageService.add({
          key: 'art',
          severity: 'success',
          summary: 'Información',
          detail: 'El precio del artículo se elimino correctamente'
        });
        this.auditoriagral.registrarAuditoria('INV_MAEARTPRECIO',
          this.precioSeleccionado.ART_CODIGO + '/' + this.precioSeleccionado.ARTPRE_CODIGO, 'E', '', '01', '', '', '', '').subscribe();
        if (this.artService.maeartprecio.length > 0) {
          this.precioSeleccionado = this.artService.maeartprecio[this.artService.maeartprecio.length - 1];
        }
        this.aggrid.refreshaggrid(this.artService.maeartprecio, this.defaultColDef.objeto);
      }, error1 => {
        const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Error',
          detail: error
        });
      });
    }

    if (this.index === 3) {

      if (this.parteSeleccionada === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'No se ha seleccionado el componente para eliminar'
        });
        return;
      }

      this.artService.eliminarParte(this.parteSeleccionada).subscribe((datos: any[]) => {
        this.artService.maeartparte.splice(this.artService.maeartparte.findIndex(
          parte => parte.ART_CODIGOP === this.parteSeleccionada.ART_CODIGOP),
          1);
        this.messageService.add({
          key: 'art',
          severity: 'success',
          summary: 'Información',
          detail: 'El componente del artículo se elimino correctamente'
        });
        this.auditoriagral.registrarAuditoria('INV_MAEARTPRECIO',
          this.parteSeleccionada.ART_CODIGO + '/' + this.parteSeleccionada.ART_CODIGOP, 'E', '', '01', '', '', '', '').subscribe();
        this.aggrid.refreshaggrid(this.artService.maeartparte, this.defaultColDefParte.objeto);
      }, error1 => {
        const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Error',
          detail: error
        });
      });
    }


    if (this.index === 4) {

      if (this.formulaSeleccionada === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'No se ha seleccionado la formula para eliminar'
        });
        return;
      }

      this.artService.eliminarFormula(this.formulaSeleccionada).subscribe((datos: any[]) => {
        this.artService.maeartformula.splice(this.artService.maeartformula.findIndex(
          formula => formula.ART_CODIGOP === this.formulaSeleccionada.ART_CODIGOP),
          1);
        this.aggrid.refreshaggrid(this.artService.maeartformula, this.defaultColDefFormula.objeto);
        this.messageService.add({
          key: 'art',
          severity: 'success',
          summary: 'Información',
          detail: 'La formula del artículo se elimino correctamente'
        });
        this.auditoriagral.registrarAuditoria('INV_MAEARTFORMULA',
          this.formulaSeleccionada.ART_CODIGO + '/' + this.formulaSeleccionada.FOR_CODIGO, 'E', '', '01', '', '', '', '').subscribe();
      }, error1 => {
        const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Error',
          detail: error
        });
      });
    }


    if (this.index === 5) {

      if (this.costoestanSeleccionado === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'No se ha seleccionado el costo para eliminar'
        });
        return;
      }

      this.artService.eliminarCostoEst(this.costoestanSeleccionado).subscribe((datos: any[]) => {
        this.artService.maecostoestand.splice(this.artService.maecostoestand.findIndex(
          costo => costo.CIN_CODIGO === this.costoestanSeleccionado.CIN_CODIGO),
          1);
        this.aggrid.refreshaggrid(this.artService.maecostoestand, this.defaultColDefCostoest.objeto);
        this.messageService.add({
          key: 'art',
          severity: 'success',
          summary: 'Información',
          detail: 'El costo estandar del artículo se elimino correctamente'
        });
        this.auditoriagral.registrarAuditoria('INV_MAEARTPRECIO',
          this.precioSeleccionado.ART_CODIGO + '/' + this.precioSeleccionado.ARTPRE_CODIGO, 'E', '', '01', '', '', '', '').subscribe();
      }, error1 => {
        const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Error',
          detail: error
        });
      });
    }

    if (this.index === 6) {

      if (this.existbodSeleccionada === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'No se ha seleccionado la formula para eliminar'
        });
        return;
      }

      this.artService.eliminarExistencia(this.existbodSeleccionada).subscribe((datos: any[]) => {
        this.artService.maeexistbod.splice(this.artService.maeexistbod.findIndex(
          existbod => existbod.BOD_CODIGO === this.existbodSeleccionada.BOD_CODIGO),
          1);
        this.aggrid.refreshaggrid(this.artService.maeexistbod, this.defaultColDefExistbod.objeto);
        this.messageService.add({
          key: 'art',
          severity: 'success',
          summary: 'Información',
          detail: 'La existencia por bodega del artículo se elimino correctamente'
        });
        this.auditoriagral.registrarAuditoria('INV_MAEARTEXISTENCIA',
          this.existbodSeleccionada.ART_CODIGO + '/' + this.existbodSeleccionada.BOD_CODIGO, 'E', '', '01', '', '', '', '').subscribe();
      }, error1 => {
        const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Error',
          detail: error
        });
      });
    }

    if (this.index === 9) {

      if (this.comquimicaSeleccionada === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'No se ha seleccionado la composición química para eliminar'
        });
        return;
      }

      if (this.comquimicaSeleccionada.TRNCOMQUI_CODIGO !== '') {
        const parametros: any[] = [];
        parametros[0] = this.maearticulo.ART_CODIGO;
        parametros[1] = '01';
        parametros[2] = this.comquimicaSeleccionada.TRNCOMQUI_CODIGO;

        try {
          await this.artService.eliminarTrnComqui(parametros);
          this.messageService.add({
            key: 'art',
            severity: 'success',
            summary: 'Información',
            detail: 'Registro eliminado exitosamente...!!!'
          });
          this.auditoriagral.registrarAuditoria('INV_TRNCOMQUI',
            this.maearticulo.ART_CODIGO + '/' + this.comquimicaSeleccionada.TRNCOMQUI_CODIGO, 'E', '', '01', '', '', '', '').subscribe();
        } catch (err) {
          this.messageService.add({
            key: 'art',
            severity: 'error',
            summary: 'Error',
            detail: err.error
          });
        }
      }
      this.artService.prodtrncomqui.splice(this.artService.prodtrncomqui.findIndex(
        existbod => existbod.TRNCOMQUI_CODIGO === this.comquimicaSeleccionada.TRNCOMQUI_CODIGO),
        1);
      this.aggrid.refreshaggrid(this.artService.prodtrncomqui, this.defaultColDefComquim.objeto);
    }

    if (this.index === 10) {

      if (this.aplicacionSeleccionada === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'No se ha seleccionado la aplicación para eliminar'
        });
        return;
      }

      if (this.aplicacionSeleccionada.TRNAPLI_CODIGO !== '') {
        const parametros: any[] = [];
        parametros[0] = this.maearticulo.ART_CODIGO;
        parametros[1] = '01';
        parametros[2] = this.aplicacionSeleccionada.TRNAPLI_CODIGO;

        try {
          await this.artService.eliminarTrnAplicacion(parametros);
        } catch (err) {
          this.messageService.add({
            key: 'art',
            severity: 'error',
            summary: 'Error',
            detail: err.error
          });
        }
      }
      this.artService.prodtrnaplicacion.splice(this.artService.prodtrnaplicacion.findIndex(
        existbod => existbod.TRNAPLI_CODIGO === this.aplicacionSeleccionada.TRNAPLI_CODIGO),
        1);
      this.aggrid.refreshaggrid(this.artService.prodtrnaplicacion, this.defaultColDefAplicacion.objeto);
    }
    if (this.index === 12) {
      if (this.profabricaSeleccionada === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'No se ha seleccionado el proceso de fabricación para eliminar'
        });
        return;
      }
      if (this.profabricaSeleccionada.PROFAB_LINEA !== 0) {
        const parametros: any[] = [];
        parametros[0] = this.maearticulo.ART_CODIGO;
        parametros[1] = this.profabricaSeleccionada.PROFAB_LINEA;
        parametros[2] = '01';
        try {
          await this.artService.eliminarProfabri(parametros);
          this.messageService.add({
            key: 'art',
            severity: 'error',
            summary: 'Información',
            detail: 'Registro eliminado exitosamente...!!!'
          });
          this.auditoriagral.registrarAuditoria('INV_MAEPROFAB',
            this.maearticulo.ART_CODIGO + '/' + this.profabricaSeleccionada.ART_CODIGO_MP + '/' + this.profabricaSeleccionada.PROFAB_LINEA, 'E', '', '01', '', '', '', '').subscribe();
        } catch (err) {
          this.messageService.add({
            key: 'art',
            severity: 'error',
            summary: 'Error',
            detail: err.error
          });
        }
      }
      this.artService.profabricacion.splice(this.artService.profabricacion.findIndex(
        existbod => existbod.PROFAB_ETAPA === this.profabricaSeleccionada.PROFAB_ETAPA),
        1);
      this.aggrid.refreshaggrid(this.artService.profabricacion, this.defaultColDefProfab.objeto);
    }
    if (this.index === 13) {
      if (this.procontrolSeleccionada === undefined) {
        this.messageService.add({
          key: 'art',
          severity: 'error',
          summary: 'Información',
          detail: 'No se ha seleccionado el proceso de fabricación para eliminar'
        });
        return;
      }
      if (this.procontrolSeleccionada.NUEVO !== 0) {
        const parametros: any[] = [];
        parametros[0] = this.maearticulo.ART_CODIGO;
        parametros[1] = '01';
        parametros[2] = this.procontrolSeleccionada.PROCONT_PARAMETRO;
        try {
          await this.artService.eliminarProcontrol(parametros);
          this.messageService.add({
            key: 'art',
            severity: 'error',
            summary: 'Información',
            detail: 'Registro eliminado exitosamente...!!!'
          });
          this.auditoriagral.registrarAuditoria('INV_MAEPROCONT',
            this.maearticulo.ART_CODIGO + '/' + this.procontrolSeleccionada.PROCONT_PARAMETRO, 'E', '', '01', '', '', '', '').subscribe();
        } catch (err) {
          this.messageService.add({
            key: 'art',
            severity: 'error',
            summary: 'Error',
            detail: err.error
          });
        }
      }
      this.artService.procontrol.splice(this.artService.procontrol.findIndex(
        existbod => existbod.PROCONT_PARAMETRO === this.procontrolSeleccionada.PROCONT_PARAMETRO),
        1);
      this.aggrid.refreshaggrid(this.artService.procontrol, this.defaultColDefProcont.objeto);
    }
  }

  cancelar() {
    if (this.index < 2) {
      this.botonNuevo = false;
      this.botonGuardar = true;
      this.botonRegresar = true;
    }
    const articulo = this.maearticulo.ART_CODIGO;
    this.limpiartablas();
    this.encontrarArticulo(articulo);
  }

  encontrarArticulo(artcodigo) {
    this.artService.getArticulo(artcodigo).subscribe(async (res) => {
      this.bolnuevo = false;
      for (const i of res) {
        this.maearticulo = i;
        this.codactiv = true;
        this.chkactivo = i.ART_ACTIVO === 'S';
        this.chkcompuesto = i.ART_COMPUESTO === 'S';
        this.chktributaiva = i.ART_TRIBUTAIVA === 'S';
        this.chkactivarserialfac = i.ART_SERIALFLAG === 'S';
        this.chktributaice = i.ART_TRIBUTAICE === 'S';
        this.chkbnobojiva = i.ART_BASENOOBJIVA === 'S';
        this.chkmultiunidad = i.ART_MULTIUNIDAD === 'S';
        this.chkcaduca = i.ART_CADUCA === 'S';
        this.chkactivarserial = i.ART_ACTIVARSERIAL === 'S';
        this.chkactivardim = i.ART_ACTIVARDIM === 'S';
        this.chkactivartelefono = i.ART_ACTIVARNUMEROTEL === 'S';
        this.chkmateriaprima = i.ART_TIPO === 'M';
        this.chkproducido = i.ART_PRODUCTOPROD === 'S';
        this.chkbtnservicio = i.ART_APLICASERVICIO === 'S';

        // Formato Decimales
        this.maearticulo.ART_COSTOHISTORICO = Number(this.maearticulo.ART_COSTOHISTORICO).toFixed(2);
        this.maearticulo.ART_DESCUENTO = Number(this.maearticulo.ART_DESCUENTO).toFixed(2);
        this.maearticulo.ART_VALORICE = Number(this.maearticulo.ART_VALORICE).toFixed(2);
        this.maearticulo.ART_ANCHO = Number(this.maearticulo.ART_ANCHO).toFixed(2);
        this.maearticulo.ART_ALTURA = Number(this.maearticulo.ART_ALTURA).toFixed(2);
        this.maearticulo.ART_PESO = Number(this.maearticulo.ART_PESO).toFixed(2);
        this.maearticulo.ART_LARGO = Number(this.maearticulo.ART_LARGO).toFixed(2);
        this.maearticulo.ART_IRBPNR = Number(this.maearticulo.ART_IRBPNR).toFixed(2);//CDPJ

        this.maearticulo.LBL_UCOSTEO = this.maearticulo.ART_UNIDADCOSTEO;
        this.maearticulo.LBL_UVENTA = this.maearticulo.ART_UNIDADVENTA;

        this.actualizarTiposCategoria(this.maearticulo.ART_TIPOCATEGORIA);
        this.listarDescripciones(this.maearticulo.ART_CODIGO);
        //facturas
        if (i.ART_RAMV !== null) {
          this.artService.getencfacpro(artcodigo).subscribe(res => {
            if (res[0] !== undefined) {
              this.bolnuevo = false;
              for (const i of res) {
                this.maearticulo = i;
                this.encfacproService.erMaeProveedor(i.PRO_CODIGO).subscribe(eR => {
                  if (eR !== null) {
                    if (eR[0] !== undefined) {
                      this.maearticulo.ART_NOMFACPRO = i.ENCFACPRO_SERIE + " " + i.ENCFACPRO_REFERENCIA;
                      this.maearticulo.ART_NUMFACPRO = eR[0].PRO_NOMBRE;
                      this.maearticulo.ART_ESTADOFACPRO = i.ENCFACPRO_ESTADO;
                      console.log(this.maearticulo.ART_NOMFACPRO);
                      console.log(this.maearticulo.ART_ESTADOFACPRO);
                      if (this.maearticulo.ART_NOMFACPRO !== null) {
                        if (this.maearticulo.ART_ESTADOFACPRO === 'G') {
                          this.maearticulo.ART_ESTADOFACPRO = "- Factura " + eR[0].PRO_NOMBRE + " pagada"
                        } else if (this.maearticulo.ART_ESTADOFACPRO === 'P') {
                          this.maearticulo.ART_ESTADOFACPRO = "- Factura " + eR[0].PRO_NOMBRE + " pendiente"
                        } else if (this.maearticulo.ART_ESTADOFACPRO === 'A') {
                          this.maearticulo.ART_ESTADOFACPRO = "- Factura " + eR[0].PRO_NOMBRE + " anulada"
                        }
                      }
                    }
                  }
                })
              }
            }
          });

          this.artService.getencfac(artcodigo).subscribe(res => {
            if (res[0] !== undefined) {
              this.bolnuevo = false;
              for (const i of res) {
                this.maearticulo = i;
                this.maearticulo.ART_NOMFAC = i.ENCFAC_NUMERO;
                this.maearticulo.ART_NUMFAC = i.CLI_NOMBRE;
                this.maearticulo.ART_ESTADOFAC = i.ENCFAC_ESTADO;
                if (this.maearticulo.ART_NOMFAC !== null) {
                  if (this.maearticulo.ART_ESTADOFAC === 'G') {
                    this.maearticulo.ART_ESTADOFAC = "- Factura " + i.CLI_NOMBRE + " pagada"
                  } else if (this.maearticulo.ART_ESTADOFAC === 'P') {
                    this.maearticulo.ART_ESTADOFAC = "- Factura " + i.CLI_NOMBRE + " pendiente"
                  } else if (this.maearticulo.ART_ESTADOFAC === 'A') {
                    this.maearticulo.ART_ESTADOFAC = "- Factura " + i.CLI_NOMBRE + " anulada"
                  }
                };
              }
            }
          });
        }

        //fin facturas
        this.artService.encontrarCenCosto(this.maearticulo.CEN_CODIGO).subscribe((cen) => {
          if (cen !== null) {
            this.maearticulo.CEN_NOMBRE = cen[0].CEN_NOMBRE;
          }
        })
        this.artService.nombresCuentasContables(i.ART_CODIGO).subscribe((nombres) => {
          this.nombrescuentas = nombres[0];
          this.maearticulo.CON_NOMBREACT = this.nombrescuentas.NOMBRECTAACT;
          this.maearticulo.CON_NOMBREGAS = this.nombrescuentas.NOMBRECTAGAS;
          this.maearticulo.CON_NOMBREING = this.nombrescuentas.NOMBRECTAING;
          this.maearticulo.CON_NOMBREORD1 = this.nombrescuentas.NOMBRECTAORDEN1;
          this.maearticulo.CON_NOMBREORD2 = this.nombrescuentas.NOMBRECTAORDEN2;
          this.maearticulo.CON_NOMBREDSCTO = this.nombrescuentas.NOMBRECTADSCTO;
          this.maearticulo.CON_NOMBREING2 = this.nombrescuentas.NOMBRECTAING2;
          this.maearticulo.CON_NOMBREDSCTO2 = this.nombrescuentas.NOMBRECTADSCTO2;
          this.maearticulo.CON_NOMBREPRODPROC = this.nombrescuentas.NOMBRECTAPRODPROC;
          if (i.ART_FORMSRICOM !== null) {
            this.artService.encontrarRefNombreMaeRef(i.ART_FORMSRICOM, 'RESCOM').subscribe((formsricom) => {
              if (formsricom[0] !== undefined) {
                this.maearticulo.ART_NOMFORMSRICOM = formsricom[0].REF_NOMBRE;
              }
            });
          }
          if (i.ART_FORMSRIVTAS !== null) {
            this.artService.encontrarRefNombreMaeRef(i.ART_FORMSRIVTAS, 'RESVTA').subscribe((formsriven) => {
              if (formsriven[0] !== undefined) {
                this.maearticulo.ART_NOMFORMSRIVTAS = formsriven[0].REF_NOMBRE;
              }
            });
          }
          if (i.ART_CAMPANIA !== null) {
            this.artService.encontrarRefNombreMaeRef(i.ART_CAMPANIA, 'CAMPA').subscribe((formsriven) => {
              if (formsriven[0] !== undefined) {
                this.maearticulo.CAMPANIA_NOMBRE = formsriven[0].REF_NOMBRE;
              }
            });
          }

          //////encontra vehiculo//////
          if (i.ART_MARCAV !== null) {
            this.artService.encontrarRefNombreMaeRef(i.ART_MARCAV, 'MARVEH').subscribe((formsriven) => {
              if (formsriven[0] !== undefined) {
                this.maearticulo.ART_MARVEH = formsriven[0].REF_NOMBRE;
              }
            });
          }
          if (i.ART_CLASE !== null) {
            this.artService.encontrarRefNombreMaeRef(i.ART_CLASE, 'CLAVEH').subscribe((formsriven) => {
              if (formsriven[0] !== undefined) {
                this.maearticulo.ART_CLAVEH = formsriven[0].REF_NOMBRE;
              }
            });
          }
          if (i.ART_TIPOV !== null) {
            this.artService.encontrarRefNombreMaeRef(i.ART_TIPOV, 'TIPVEH').subscribe((formsriven) => {
              if (formsriven[0] !== undefined) {
                this.maearticulo.ART_TIPVEH = formsriven[0].REF_NOMBRE;
              }
            });
          }
          if (i.ART_COMBUSTIBLE !== null) {
            this.artService.encontrarRefNombreMaeRef(i.ART_COMBUSTIBLE, 'COMVEH').subscribe((formsriven) => {
              if (formsriven[0] !== undefined) {
                this.maearticulo.ART_COMVEH = formsriven[0].REF_NOMBRE;
              }
            });
          }
          if (i.ART_DISPOSITIVO !== null) {
            this.artService.encontrarRefNombreMaeRef(i.ART_DISPOSITIVO, 'DISVEH').subscribe((formsriven) => {
              if (formsriven[0] !== undefined) {
                this.maearticulo.ART_DISVEH = formsriven[0].REF_NOMBRE;
              }
            });
          }
          if (i.ART_PORIGEN !== null) {
            this.artService.encontrarRefNombreMaeRef(i.ART_PORIGEN, 'P').subscribe((formsriven) => {
              if (formsriven[0] !== undefined) {
                this.maearticulo.ART_P = formsriven[0].REF_NOMBRE;
              }
            });
          }

          //////////////////////////////////////////////////////////////////
        });
        //CDPJ DESCRIPCION DEL IVA
        if (i.TARIIVA_CODIGO !== null && i.TARIIVA_CODIGO !== undefined) {
          this.artService.encontrarTarriIva(i.TARIIVA_CODIGO).subscribe((formsriven) => {
            if (formsriven[0] !== undefined && formsriven[0] !== null) {
              this.maearticulo.TARIIVA_DESCRIPCION = formsriven[0].TARIIVA_DESCRIPCION;
            }
          });
        }
        //CDPJ
        if (this.maearticulo.ART_BANCO !== null) {
          this.cliService.erNombreBanco(this.maearticulo.ART_BANCO).subscribe((formsricom) => {
            if (formsricom !== null) {
              if (formsricom[0] !== undefined) {
                this.maearticulo.ART_NBANCO = formsricom[0].BANCLI_NOMBRE;
              }
            }
          });
        }

        this.maearticulo.ART_LIB_TITULO = this.maearticulo.ART_LIB_TITULO === null ? '' : this.maearticulo.ART_LIB_TITULO;
        this.maearticulo.ART_LIB_AUTOR = this.maearticulo.ART_LIB_AUTOR === null ? '' : this.maearticulo.ART_LIB_AUTOR;
        this.maearticulo.ART_LIB_EDITORIAL = this.maearticulo.ART_LIB_EDITORIAL === null ? '' : this.maearticulo.ART_LIB_EDITORIAL;
        this.maearticulo.ART_LIB_ORIGEN = this.maearticulo.ART_LIB_ORIGEN === null ? '' : this.maearticulo.ART_LIB_ORIGEN;
        this.maearticulo.ART_LIB_TAMANIO = this.maearticulo.ART_LIB_TAMANIO === null ? '' : this.maearticulo.ART_LIB_TAMANIO;
        this.maearticulo.ART_LIB_ENCUADERNACION = this.maearticulo.ART_LIB_ENCUADERNACION === null ? '' : this.maearticulo.ART_LIB_ENCUADERNACION;

        this.maearticulo.ART_FFABRICA = this.datePipe.transform(this.maearticulo.ART_FFABRICA, 'yyyy-MM-dd')

        this.artService.getPrecio(i.ART_CODIGO).subscribe((precio) => {
          this.artService.maeartprecio = precio;

          this.artService.maeartprecio.map((item) => {
            item.ARTPRE_PRECIO = item.ARTPRE_PRECIO.toFixed(this.artService.obtenerConfig('PRECIO', 'MaePrecio'));
            item.ARTPRE_COSTO = item.ARTPRE_COSTO.toFixed(this.artService.obtenerConfig('COSTO', 'MaePrecio'));
            item.ARTPRE_PORCENTAJE = item.ARTPRE_PORCENTAJE.toFixed(this.artService.obtenerConfig('UTILIDAD', 'MaePrecio'));
            item.ARTPRE_DESCUENTO = item.ARTPRE_DESCUENTO.toFixed(this.artService.obtenerConfig('DESCUENTO', 'MaePrecio'));
            item.ARTPRE_DESCF = item.ARTPRE_DESCF.toFixed(this.artService.obtenerConfig('DescF', 'MaePrecio'));
            item.ARTPRE_DESCP = item.ARTPRE_DESCP.toFixed(this.artService.obtenerConfig('DescP', 'MaePrecio'));
            item.ARTPRE_PRECIOFPVP = item.ARTPRE_PRECIOFPVP.toFixed(this.artService.obtenerConfig('PrecioFpvp', 'MaePrecio'));
            item.NUEVO = false;
          });

          for (let j = 0; j < this.artService.maeartprecio.length; j++) {
            this.artService.maeartprecio[j].CLAVE = j;
          }
        });

        this.artService.getComponente(i.ART_CODIGO).subscribe((parte) => {
          this.artService.maeartparte = parte;
          this.artService.maeartparte.map((item) => {
            item.ARTPARTE_CANTIDAD = item.ARTPARTE_CANTIDAD.toFixed(this.artService.obtenerConfig('CANTIDAD', 'MaeComponentes'));
          });
        });

        this.artService.llenarComboFormula(i.ART_CODIGO).subscribe((combo) => {
          this.artService.formulas = [];
          for (const rs of combo) {
            this.artService.formulas.push({ codigo: rs.FOR_CODIGO, name: rs.FOR_CODIGO });
          }

          if (combo.length > 0) {
            this.artService.formula = this.artService.formulas[0].codigo;
            this.artService.selectformula = { codigo: this.artService.formulas[0].codigo, name: this.artService.formulas[0].name };
            this.artService.getFormula(i.ART_CODIGO, this.artService.formula).subscribe( async (formula) => {
              this.artService.maeartformula = formula;
              let totcant = 0;
              for (const item of this.artService.maeartformula) {
                
              
              //this.artService.maeartformula.map(async (item) => {
                totcant = totcant + item.FOR_CANTIDAD;
                item.FOR_CANTIDAD = item.FOR_CANTIDAD.toFixed(this.artService.obtenerConfig('CANTIDAD', 'formula'));
                item.FOR_CANTTOTAL = item.FOR_CANTTOTAL.toFixed(this.artService.obtenerConfig('CANTIDAD', 'formula'));
                const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
                const strHora = this.datePipe.transform(new Date(), 'HH:mm');
                // extrae la existencia
                const data = await this.artService.obtenerExistenciaBodegaProm('PED', item.ART_CODIGOP, '', strFecha, strHora);
                //EXTRAER SI ES MULTIUNIDAD
                const data1 = await this.artService.obtenerMultiunidad(item.ART_CODIGOP);
                for (const rs of data) {
                  item.ART_EXISTENCIA = Number(rs.EXIST).toFixed(this.artService.obtenerConfig('CANTIDAD', 'formula'));
                }
                for (const rs of data1) {
                  item.ART_MULTIUNIDAD = rs.ART_MULTIUNIDAD;
                }
              }//);
              this.strCantMatPrima = totcant.toFixed(this.artService.obtenerConfig('CANTIDAD', 'formula'));
              this.aggrid.refreshaggrid(this.artService.maeartformula, this.defaultColDefFormula.objeto);
            });
          }
        });

        this.artService.getCostosEstandares(i.ART_CODIGO).subscribe((cosest) => {
          this.artService.maecostoestand = cosest;
          this.artService.maecostoestand.map((item) => {
            item.CEST_COSTO = item.CEST_COSTO.toFixed(this.artService.obtenerConfig('COSTO', 'MaeCostosEst'));
            if (item.CEST_CANTIDAD !== null) {
              this.txtNumArtCosEst = Number(item.CEST_CANTIDAD).toFixed(this.artService.obtenerConfig('COSTO', 'MaeCostosEst'));
            }
          });

        });

        this.artService.getExistenciasBodega(i.ART_CODIGO).subscribe((existbod) => {
          this.artService.maeexistbod = existbod;
          this.artService.maeexistbod.map((item) => {
            item.ARTEXT_MINIMA = item.ARTEXT_MINIMA.toFixed(this.artService.obtenerConfig('EXIMIN', 'MaeExiBod'));
            item.ARTEXT_MAXIMA = item.ARTEXT_MAXIMA.toFixed(this.artService.obtenerConfig('EXIMAX', 'MaeExiBod'));
            item.EXISTENCIAFECHA = item.EXISTENCIAFECHA.toFixed(2);
            item.ARTEXT_ORDENAR = item.ARTEXT_ORDENAR.toFixed(this.artService.obtenerConfig('CANTORD', 'MaeExiBod'));
          });
        });

        let fecha = '01/01/1970';
        let hora = '00:00';

        this.strTipoKardex = this.cmbTipoKardex[0];
        this.trnkardexp = [];
        this.artService.getKardex(i.ART_CODIGO, fecha, hora).subscribe((kardex: InvTrnkardexp[]) => {
          if (kardex !== null) {
            if (this.bolkardexGr === true) {
              this.bolkardex = false;//CDPJ
            } else {
              this.bolkardex = true;//CDPJ
            }
            for (const o of kardex) {
              o.TRNART_FECHAES = this.datePipe.transform(o.TRNART_FECHAES, 'dd/MM/yyyy');
            }
            this.artService.getToten(i.ART_CODIGO).subscribe((dato) => {
              for (const d of dato) {
                this.Toten = d.TOTEN;
              }
            });
            this.artService.getTotsa(i.ART_CODIGO).subscribe((dato) => {
              for (const d of dato) {
                this.Totsa = d.TOTSA;
              }
            });

            this.trnkardexp = kardex;
            this.trnkardexp.map((item) => {
              item.TRNART_CANTIDAD_E = item.TRNART_CANTIDAD_E.toFixed(this.artService.obtenerConfig('CANTIDAD', 'kardex'));
              item.TRNART_CANTIDAD_S = item.TRNART_CANTIDAD_S.toFixed(this.artService.obtenerConfig('CANTIDAD', 'kardex'));
              item.TRNART_COSTO_UNITARIO = item.TRNART_COSTO_UNITARIO.toFixed(this.artService.obtenerConfig('COSTO', 'kardex'));
              item.TRNART_COSTOT = item.TRNART_COSTOT.toFixed(this.artService.obtenerConfig('COSTO', 'kardex'));
              item.TRNART_CANTIDAD_SALDO = item.TRNART_CANTIDAD_SALDO.toFixed(this.artService.obtenerConfig('CANTIDAD', 'kardex'));
              item.TRNART_COSTOPROM = item.TRNART_COSTOPROM.toFixed(this.artService.obtenerConfig('COSTO', 'kardex'));
              item.TRNART_COSTOT_SALDO = item.TRNART_COSTOT_SALDO.toFixed(this.artService.obtenerConfig('COSTO', 'kardex'));

            });
            
          } else {//CDPJ
            this.bolkardex = false;
          }//CDPJ
          
        });
       
        

        this.artService.getEspecificacionesTecnicas(i.ART_CODIGO).subscribe((esptec) => {
          for (const k of esptec) {
            this.maeesptec = k;
          }
          if (this.maeesptec.ART_CODIGO === undefined || this.maeesptec.ART_CODIGO === null || this.maeesptec.ART_CODIGO === '') {
            this.maeesptec.ART_CODIGO = i.ART_CODIGO;
          }
        });

        this.artService.listarTrnComqui(i.ART_CODIGO).subscribe((item) => {
          this.artService.prodtrncomqui = item;
          let contar = 0;
          this.artService.prodtrncomqui.map((com) => {
            contar++;
            com.AUXILIAR = 'X';
            com.FILA = contar;
          });
          console.log(this.artService.prodtrncomqui);
        });
        //CDPJ
        this.artService.listarProfabri(i.ART_CODIGO).subscribe(async (item) => {
          if(item.length>0){
            this.artService.profabricacion = item;
          this.artService.profabricacion.map((com) => {
            com.PROFAB_CANTIDAD = Number(com.PROFAB_CANTIDAD).toFixed(2)
          });
          console.log(this.artService.profabricacion);
          }else{
            const data = await this.artService.listarProfabriformulacion(i.ART_CODIGO,this.artService.selectformula.codigo).toPromise();
            if(data !== null && data !== undefined && data.length>0){
              for (const resp of data){
                let item: Invmaeprofab = {
                  ART_CODIGO: this.maearticulo.ART_CODIGO,
                  PROFAB_ETAPA: '0',
                  COM_CODIGO: '01',
                  PROFAB_DESCRIPCION: '',
                  ART_CODIGO_MP: resp.ART_CODIGOP,
                  PROFAB_CANTIDAD: 0,
                  PROFAB_UNIDAD: resp.UNI_CODIGO,
                  PROFAB_HORA_INICIO: '',
                  PROFAB_HORA_FIN: '',
                  PROFAB_LINEA: 0,
                  ART_NOMBRE_MP: resp.ART_NOMBRE,
                };
                this.artService.profabricacion.push(item);
              }
              
              this.aggrid.refreshaggrid(this.artService.profabricacion, this.defaultColDefProfab.objeto);
             
            }
          }
          
        });
        this.artService.listarProcontrol(i.ART_CODIGO).subscribe((item) => {
          this.artService.procontrol = item;
          this.artService.procontrol.map((com) => {
            com.NUEVO = 1;
          });
          console.log(this.artService.profabricacion);
        });
        //CDPJ
        this.artService.listarTrnAplicacion(i.ART_CODIGO).subscribe((item) => {
          this.artService.prodtrnaplicacion = item;
          let contar = 0;
          this.artService.prodtrnaplicacion.map((com) => {
            contar++;
            com.AUXILIAR = 'X';
            com.FILA = contar;

          });
          console.log(this.artService.prodtrnaplicacion);
        });

        this.index = 0;
        this.artService.spin = false;
      }

      if (this.index < 2) {
        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = false;
        this.botonRegresar = true;
      }
      
    });
  }

  seleccionarRegistro(strTipo) {
    if (strTipo === 'P') {
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }
    if (strTipo === 'A' || strTipo === 'S') {
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    }
    if (strTipo === 'U') {
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = false;
      this.botonUltimo = false;
    }

    this.artService.seleccionarRegistro(strTipo, this.maearticulo).subscribe((datos: any[]) => {
      this.index = 0;
      this.limpiartablas();
      this.encontrarArticulo(datos[0].ART_CODIGO);
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'art',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }

  opcion(opcion) {
    this.artService.formula = opcion.value.codigo;
    this.artService.getFormula(this.maearticulo.ART_CODIGO, this.artService.formula).subscribe((formula) => {
      this.artService.maeartformula = formula;
      this.artService.maeartformula.map(async (item) => {
        item.FOR_CANTIDAD = item.FOR_CANTIDAD.toFixed(this.artService.obtenerConfig('CANTIDAD', 'formula'));
        item.FOR_CANTTOTAL = item.FOR_CANTTOTAL.toFixed(this.artService.obtenerConfig('CANTIDAD', 'formula'));
        const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
        const strHora = this.datePipe.transform(new Date(), 'HH:mm');
        // extrae la existencia
        const data = await this.artService.obtenerExistenciaBodegaProm('PED', item.ART_CODIGOP, '', strFecha, strHora);
        for (const rs of data) {
          item.ART_EXISTENCIA = Number(rs.EXIST).toFixed(this.artService.obtenerConfig('CANTIDAD', 'formula'));
        }
      });
    });
  }

  busqkardex(opcion) {
    this.busqkar = opcion;
    this.busqkar = opcion.value.name;
    /*this.artService.getFormula(this.maearticulo.ART_CODIGO, this.formula).subscribe((formula) => {
      this.artService.maeartformula = formula;
    });*/
  }

  gridsnull() {
    for (const o of this.init.grids) {
      o.setFocusedCell(false);
    }

    if (this.index >= 12) {
      this.botonNuevo = true;
      this.botonGuardar = true;
      this.botonBorrar = true;
      this.botonRegresar = true;
    } else {
      if (this.botonGuardar === true) {
        this.botonNuevo = false;
        this.botonGuardar = true;
        this.botonBorrar = false;
        this.botonRegresar = false;
      }
    }
    if (this.index === 2) {
      this.FuncObtenerDatosExistenciaBodega();
    }
    if (this.index === 7) {
      this.FuncObtenerDatosExistenciaBodega();

      /* this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
         this.defaultColDefKardex.objeto].setFocusedCell(this.trnkardexp.length - 1, 'ART_CODIGO');*/
      console.log('visualizarAbajoencambiodropdown');
     // let index=this.trnkardexp.length-1
       // console.log(typeof index);
        //this.aggrid.refreshaggridindex(this.trnkardexp, this.defaultColDefKardex.objeto,this.trnkardexp.length-2);
     // this.aggrid.gridApi.ensureIndexVisible(this.trnkardexp.length-1,'bottom')
      this.aggrid.visualizarAbajo(this.defaultColDefKardex.objeto, this.trnkardexp.length - 1);
      // const gridBody= document.querySelector('ag-body-viewport')
      // if(gridBody){
      //   gridBody.scrollTop=gridBody.scrollHeight;
      // }
    }
  }

  limpiartablas() {
    this.nuevoArt();
    this.artService.formula = '';
    this.artService.maeartprecio = [];
    this.artService.maeartparte = [];
    this.artService.maeartformula = [];
    this.artService.maeexistbod = [];
    this.artService.maecostoestand = [];
    this.trnkardexp = [];
    this.artService.formulas = [];
    this.artService.prodtrncomqui = [];
    this.artService.prodtrnaplicacion = [];
    this.artService.maecomart = [];
    this.artService.profabricacion = [];//CDPJ
    this.artService.procontrol = [];//CDPJ
  }

  botonesmodificar() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
  }

  llenarcombobusqkardex() {
    this.cmbkardex = this.cmbkardexaux;
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }
  //CDPJ
  actualizariva() {
    this.closeSidebar();
    this.claveDialog = true;
    this.accion = 'claveiva'

  }
  cancelariva() {
    this.tariivaporcentaje = '';
    this.tariivadescripcion = '';
    this.tariivacod = ''
  }
  async aplicarActualizar() {
    if (this.tariivaporcentaje === null || this.tariivaporcentaje === undefined || this.tariivaporcentaje === '') {
      this.messageService.add({
        key: 'artiva',
        severity: 'warn',
        summary: 'Información',
        detail: 'El campo IVA, no puede estar vacío'
      });
      return;
    }
    if (this.tariivaporcentaje !== null && this.tariivaporcentaje !== undefined && this.tariivaporcentaje !== '') {
      let data = this.initConf.srifetariiva.find(element => element.TARIIVA_CODIGO === this.tariivacod && Number(element.TARIIVA_PORCENTAJE) === Number(this.tariivaporcentaje))
      if (data === undefined || data === null) {
        this.messageService.add({
          key: 'artiva',
          severity: 'error',
          summary: 'Error en IVA',
          detail: 'El %IVA con el porcentaje ' + this.tariivaporcentaje + ' no es parte de los porcentajes que puede seleccionar para actualizar.'
        });
        return;
      }
    }
    await this.finalmenteactualiza()
  }
  async finalmenteactualiza() {
    try {
      await this.artService.actualizarporceiva(this.tariivacod, Number(this.tariivaporcentaje));
      this.auditoriagral.registrarAuditoria('INV_MAEARTICULO', 'porcentaje' + '/' +
        this.tariivaporcentaje + '/' + 'codporcentaje' + '/' + this.tariivacod, 'A', '', '01', '', '', '', '').subscribe();
      this.messageService.add({
        key: 'artiva',
        severity: 'success',
        summary: 'Información',
        detail: 'Actualización exitosa'
      });
    } catch (error) {

    }

  }
  //CDPJ
  agregarCodigosAlternos() {
    console.log(this.maearticulo);
    if (Object.keys(this.maearticulo).length > 0) {
      this.displayDialogCodAlt = true;
      this.closeSidebar();
    } else {
      this.messageService.add({
        key: 'art',
        severity: 'warn',
        summary: 'Información',
        detail: 'Seleccione un artículo...!!!'
      });
    }

  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  recibirSenalesCodAlt(valor) {
    if (valor === true) {
      this.displayDialogCodAlt = false;
    }
  }

  abrirFichaTecnica() {

    this.artCodigoFicha = this.maearticulo.ART_CODIGO;
    this.artNombreFicha = this.maearticulo.ART_NOMBRE;
    this.displayDialogFichaT = true;
  }

  recibirSenalesFTecnica(valor) {
    if (valor === true) {
      this.displayDialogFichaT = false;
    }
  }

  async seleccionDetComQuimica(params) {
    if (params === 'guardar') {
      const parametros: any[] = [];
      console.log(this.artService.prodtrncomqui);
      for (let i = 0; i < this.artService.prodtrncomqui.length; i++) {
        const item = this.artService.prodtrncomqui[i];
        if (item.TRNCOMQUI_CODIGO !== '') {
          if (item.AUXILIAR === '') {
            parametros[0] = this.maearticulo.ART_CODIGO;
            parametros[1] = '01';
            parametros[2] = item.TRNCOMQUI_CODIGO;
            parametros[3] = item.TRNCOMQUI_NOMBRE;
            await this.artService.guardarTrnComqui(parametros);
            this.artService.prodtrncomqui[i].AUXILIAR = 'X';
          }
        }
      }
      this.messageService.add({
        key: 'art',
        severity: 'success',
        summary: 'Información',
        detail: 'Registro guardado exitosamente...!!!'
      });
    }

    if (params === 'cancelar') {
      this.cancelar();
      return;
    }

    if (params === 'eliminar') {
      this.eliminar();
      return;
    }

    if (params === 'nuevo') {
      this.nuevo();
      return;
    }

    this.comquimicaSeleccionada = params.object;
    this.init.auxsalto = false;

  }

  async seleccionDetAplicacion(params) {
    if (params === 'guardar') {
      const parametros: any[] = [];
      for (const item of this.artService.prodtrnaplicacion) {
        if (item.TRNAPLI_CODIGO !== '') {
          if (item.AUXILIAR === '') {
            parametros[0] = this.maearticulo.ART_CODIGO;
            parametros[1] = '01';
            parametros[2] = item.TRNAPLI_CODIGO;
            parametros[3] = item.TRNAPLI_NOMBRE;
            await this.artService.guardarTrnAplicacion(parametros);
            item.AUXILIAR = 'X';
          }
        }
      }
      this.messageService.add({
        key: 'art',
        severity: 'success',
        summary: 'Información',
        detail: 'Datos de apicación guardados correctamente'
      });
    }

    if (params === 'cancelar') {
      this.cancelar();
      return;
    }

    if (params === 'eliminar') {
      this.eliminar();
      return;
    }

    if (params === 'nuevo') {
      this.nuevo();
      return;
    }

    this.aplicacionSeleccionada = params.object;
    this.init.auxsalto = false;

  }
  cambioDescrip(event) {
    this.artService.btnNuevoDesc = true;
    this.artService.btnEliminarDesc = true;
    this.artService.btnCancelarDesc = false;
  }

  seleccionDetDescripcion(params) {
    if (params === 'cancelar') {
      this.cancelarDescrip();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarDescrip();
      return;
    }

    if (params === 'nuevo') {
      this.nuevoDescrip();
      return;
    }

    this.descripcionSeleccionada = params.object;
    this.init.auxsalto = false;
  }

  async eliminarDescrip() {
    if (this.descripcionSeleccionada === undefined) {
      this.messageService.add({
        key: 'art',
        severity: 'error',
        summary: 'Información',
        detail: 'No se ha seleccionado la descripción para eliminar'
      });
      return;
    }


    const parametros: any[] = [];
    parametros[0] = this.maearticulo.ART_CODIGO;
    parametros[1] = Number(this.descripcionSeleccionada.COMART_NUMERO);
    parametros[2] = '01';

    try {
      await this.artService.eliminarDescripcion(parametros);
    } catch (err) {
      this.messageService.add({
        key: 'art',
        severity: 'error',
        summary: 'Error',
        detail: err.error
      });
    }

    this.artService.maecomart.splice(this.artService.maecomart.findIndex(
      existbod => existbod.COMART_NUMERO === this.descripcionSeleccionada.COMART_NUMERO),
      1);
    this.aggrid.refreshaggrid(this.artService.maecomart, this.defaultColDefMaecomart.objeto);
  }

  async cancelarDescrip() {
    this.artService.btnNuevoDesc = false;
    this.artService.btnEliminarDesc = false;
    this.artService.btnCancelarDesc = true;
    await this.listarDescripciones(this.maearticulo.ART_CODIGO);
  }

  async listarDescripciones(StrCodArticulo) {
    try {
      const rs = await this.artService.listarDescripciones(StrCodArticulo);
      this.artService.maecomart = rs === null ? [] : rs;
    } catch (err) {
    }
  }

  nuevoDescrip() {
    if (this.bolnuevo) {
      this.messageService.add({
        key: 'art',
        severity: 'error',
        summary: 'Error',
        detail: 'Guarde el artículo para ingresar la descripción'
      });
      return;
    }
    const item: Invmaecomart = {
      ART_CODIGO: this.maearticulo.ART_CODIGO,
      COM_CODIGO: '01',
      COMART_NUMERO: 0,
      COMART_DESCRIPCION: ''
    };
    const indi = this.artService.maecomart.indexOf(this.descripcionSeleccionada);
    if (indi === -1) {
      this.artService.maecomart.push(item);
      this.aggrid.refreshaggrid(this.artService.maecomart, this.defaultColDefMaecomart.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefMaecomart.objeto].setFocusedCell(this.artService.maecomart.length - 1, 'COMART_DESCRIPCION');
    } else {
      this.artService.maecomart.splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.artService.maecomart, this.defaultColDefMaecomart.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefMaecomart.objeto].setFocusedCell(indi + 1, 'COMART_DESCRIPCION');
    }

    this.artService.btnNuevoDesc = true;
    this.artService.btnEliminarDesc = true;
    this.artService.btnCancelarDesc = false;
  }

  async listaTiposCategoria() {
    const tipCategoria = await this.artService.getTiposCategoria();
    this.cmbTiposCateg = [];
    this.cmbTiposCateg.push({ REF_CODIGO: '', REF_NOMBRE: '' })
    for (const a of tipCategoria) {
      this.cmbTiposCateg.push({ REF_CODIGO: a.REF_CODIGO, REF_NOMBRE: a.REF_NOMBRE });
    }
    this.strTipoCateg = this.cmbTiposCateg[0];
  }

  async actualizarTiposCategoria(TipCatCodigo) {
    const strTipCatCodigo = TipCatCodigo === null ? '' : TipCatCodigo
    if (this.cmbTiposCateg === undefined) {
      await this.listaTiposCategoria();
    }
    for (const a of this.cmbTiposCateg) {
      if (a.REF_CODIGO === strTipCatCodigo) {
        this.strTipoCateg = { REF_CODIGO: a.REF_CODIGO, REF_NOMBRE: a.REF_NOMBRE };
      }
    }

  }

  async FuncObtenerDatosExistenciaBodega() {
    const data = await this.artService.getUltimoCostoCompra(this.maearticulo.ART_CODIGO);
    if (data === null) {
      this.maearticulo.LBL_ULTCOSTOCOMPRC = Number('0').toFixed(2);
    } else {
      for (const rs of data) {
        this.maearticulo.LBL_ULTCOSTOCOMPRC = Number(rs.ULTCOSTOCOMP).toFixed(2);
      }
    }

    const Fecha = new Date();
    const strFecha = this.datePipe.transform(Fecha, 'dd/MM/yyyy');
    const strHora = Fecha.getHours() + ':' + Fecha.getMinutes();

    const data1 = await this.artService.getCostoPromedio(this.maearticulo.ART_CODIGO, strFecha, strHora);

    if (data1 === null) {
      this.maearticulo.LBL_COSTOPROMPRC = Number('0').toFixed(2);
    } else {
      for (const rs1 of data1) {
        this.maearticulo.LBL_COSTOPROMPRC = Number(rs1.COSTOPROMEDIO).toFixed(2);
      }
    }

    const data2 = await this.artService.getUltProveedorCompra(this.maearticulo.ART_CODIGO);

    if (data2 === null) {
      this.maearticulo.LBL_PRONOMBREPRC = '';
    } else {
      for (const rs2 of data2) {
        this.maearticulo.LBL_PRONOMBREPRC = rs2.PROVEEDOR;
        this.ultimoProveComp = rs2.PROVEEDOR;
      }
    }

    const data3 = await this.artService.getUltimoCostoProduccion(this.maearticulo.ART_CODIGO);

    if (data3 === null) {
      this.ultimoCostoProd = Number('0').toFixed(2);
    } else {
      for (const rs3 of data3) {
        this.ultimoCostoProd = rs3.COSTOPROD;
      }
    }

    const fecha = new Date();
    const data4 = await this.artService.obtenerExistenciaBodegaProm('PED', this.maearticulo.ART_CODIGO, '',
      this.datePipe.transform(fecha, 'dd/MM/yyyy'), this.datePipe.transform(fecha, 'HH:mm'));
    for (const rs of data4) {
      this.existencia = rs.EXIST;
    }
  }

  isEdit(data): boolean {
    if (data.NUEVO === true) {
      return true;
    }
    if (data.NUEVO === false) {
      return false;
    }
    return true;
  }

  async cambioDropdown(e) {

    this.trnkardexp = [];
    let fecha = '';
    let hora = '00:00';

    if (e.value.tipo === 'Todo el Historial') {
      /*if (this.intCONCOSFEC === 1) {
        const rs = await this.artService.loadData();
        console.log(rs);
        for (const result of rs) {
          const dateFecha = new Date(result.PER_FECHAINICIAL);
          fecha = this.datePipe.transform(dateFecha, 'dd/MM/yyyy');
        }
      } else {
        fecha = '01/01/1970';
      }*/
      fecha = '01/01/1970';

    } else {
      if (this.intCONCOSFEC === 1) {
        const rs: any = await this.artService.obtenerConfiguracionFecha('CONCOSFEC');
        console.log(rs);
        for (const result of rs) {
          const dateFecha = new Date(result.CFG_VALOR3);
          fecha = this.datePipe.transform(dateFecha, 'dd/MM/yyyy');
        }
      } else {
        const anio = new Date();
        hora = '00:00';
        fecha = '01/01/' + anio.getFullYear();
      }

    }
    this.trnkardexp = [];
    this.artService.getKardex(this.maearticulo.ART_CODIGO, fecha, hora).subscribe((kardex: InvTrnkardexp[]) => {
      if (kardex !== null) {
        for (const o of kardex) {
          o.TRNART_FECHAES = this.datePipe.transform(o.TRNART_FECHAES, 'dd/MM/yyyy');
        }
        this.artService.getToten(this.maearticulo.ART_CODIGO).subscribe((dato) => {
          for (const d of dato) {
            this.Toten = d.TOTEN;
          }
        });
        this.artService.getTotsa(this.maearticulo.ART_CODIGO).subscribe((dato) => {
          for (const d of dato) {
            this.Totsa = d.TOTSA;
          }
        });

        this.trnkardexp = kardex;
        this.trnkardexp.map((item) => {
          item.TRNART_CANTIDAD_E = item.TRNART_CANTIDAD_E.toFixed(this.artService.obtenerConfig('CANTIDAD', 'kardex'));
          item.TRNART_CANTIDAD_S = item.TRNART_CANTIDAD_S.toFixed(this.artService.obtenerConfig('CANTIDAD', 'kardex'));
          item.TRNART_COSTO_UNITARIO = item.TRNART_COSTO_UNITARIO.toFixed(this.artService.obtenerConfig('COSTO', 'kardex'));
          item.TRNART_COSTOT = item.TRNART_COSTOT.toFixed(this.artService.obtenerConfig('COSTO', 'kardex'));
          item.TRNART_CANTIDAD_SALDO = item.TRNART_CANTIDAD_SALDO.toFixed(this.artService.obtenerConfig('CANTIDAD', 'kardex'));
          item.TRNART_COSTOPROM = item.TRNART_COSTOPROM.toFixed(this.artService.obtenerConfig('COSTO', 'kardex'));
          item.TRNART_COSTOT_SALDO = item.TRNART_COSTOT_SALDO.toFixed(this.artService.obtenerConfig('COSTO', 'kardex'));

        });
      }
    });
  }

  FixedTo(data, val) {
    var numbers = '';

    for (var i = 0; i < val; i++) {
      numbers += '0';
    }
    var s = 1 + numbers;
    var spot = "." + numbers;
    var value = (Math.round(parseFloat(data) * Number(s)) / Number(s)).toString();
    var d = value.toString().split(".");
    if (d.length == 1) {
      value = value.toString() + spot;
      return value;
    }
    if (d.length > 1) {
      if (d[1].length < 2) {
        value = value.toString() + "0";
      }
      return value;
    }

  }

  async MostrarSubgruposXGrupo(datos) {
    let arre: any = [];
    // datos.setStrMostrarDatos(true);
    if (datos.strGrupCodigo.substring(datos.strGrupCodigo.length - 1, datos.strGrupCodigo.length) !== '.') {
      const data = await this.artService.getgruposnivelsnpto(datos.intNivel, datos.strGrupCodigo);
      for (const rs of data) {
        let hijos = await this.MostrarSubgruposXGrupo({ intNivel: Number(datos.intNivel) + 1, strGrupCodigo: rs.GRUP_CODIGO });
        if (hijos === undefined && hijos === null) {
          hijos = [];
        }
        if (hijos.length > 0) {
          arre.push({
            "label": rs.GRUP_NOMBRE,
            "data": rs.GRUP_CODIGO,
            "expandedIcon": "pi pi-folder-open",
            "collapsedIcon": "pi pi-folder",
            "children": hijos
          });
        } else {
          arre.push({
            "label": rs.GRUP_NOMBRE,
            "data": rs.GRUP_CODIGO,
            "expandedIcon": "pi pi-folder-open",
            "collapsedIcon": "pi pi-folder",
          });
        }
      }
    } else {
      const data = await this.artService.getgruposnivelpto(datos.intNivel, datos.strGrupCodigo);
      for (const rs of data) {
        let hijos = await this.MostrarSubgruposXGrupo({ intNivel: Number(datos.intNivel) + 1, strGrupCodigo: rs.GRUP_CODIGO });
        if (hijos === undefined && hijos === null) {
          hijos = [];
        }
        if (hijos.length > 0) {
          arre.push({
            "label": rs.GRUP_NOMBRE,
            "data": rs.GRUP_CODIGO,
            "expandedIcon": "pi pi-folder-open",
            "collapsedIcon": "pi pi-folder",
            "children": hijos
          });
        } else {
          arre.push({
            "label": rs.GRUP_NOMBRE,
            "data": rs.GRUP_CODIGO,
            "expandedIcon": "pi pi-folder-open",
            "collapsedIcon": "pi pi-folder",
          });
        }
      }
    }

    return arre;
  }

  busqueda() {

  }

  async listardropdowns() {
    await this.listartitulos();
    await this.listarAutor();
    await this.listarEditorial();
    await this.listarOrigen();
    await this.listarTamanio();
    await this.listarEncuadernacion();
  }

  async listartitulos() {
    this.opcionesTITULO = [];
    const data: any = await this.artService.encontrartitulos();
    for (const rs of data) {
      this.opcionesTITULO.push({ label: rs.ART_LIB_TITULO, value: rs.ART_LIB_TITULO });
    }
  }

  async listarAutor() {
    this.opcionesAUTOR = [];
    const data: any = await this.artService.encontrarautor();
    for (const rs of data) {
      this.opcionesAUTOR.push({ label: rs.ART_LIB_AUTOR, value: rs.ART_LIB_AUTOR });
    }
  }

  async listarEditorial() {
    this.opcionesEDITORIAL = [];
    const data: any = await this.artService.encontrareditorial();
    for (const rs of data) {
      this.opcionesEDITORIAL.push({ label: rs.ART_LIB_EDITORIAL, value: rs.ART_LIB_EDITORIAL });
    }
  }

  async listarOrigen() {
    this.opcionesORIGEN = [];
    const data: any = await this.artService.encontrarorigen();
    for (const rs of data) {
      this.opcionesORIGEN.push({ label: rs.ART_LIB_ORIGEN, value: rs.ART_LIB_ORIGEN });
    }
  }

  async listarTamanio() {
    this.opcionesTAMANIO = [];
    const data: any = await this.artService.encontrartamanio();
    for (const rs of data) {
      this.opcionesTAMANIO.push({ label: rs.ART_LIB_TAMANIO, value: rs.ART_LIB_TAMANIO });
    }
  }

  async listarEncuadernacion() {
    this.opcionesENCUADERNACION = [];
    const data: any = await this.artService.encontrarencuadernacion();
    for (const rs of data) {
      this.opcionesENCUADERNACION.push({ label: rs.ART_LIB_ENCUADERNACION, value: rs.ART_LIB_ENCUADERNACION });
    }
  }


  async guardardatosautoarticulo() {
    if (this.maearticulo.ART_CODIGO === null || this.maearticulo.ART_CODIGO === undefined || this.maearticulo.ART_CODIGO === '') {
      this.messageService.add({
        key: 'art',
        severity: 'error',
        summary: 'Información',
        detail: 'No se ha seleccionado ningún artículo'
      });
      return;
    }

    const auxarticulo = this.maearticulo;

    this.maearticulo.ART_CODIGO = auxarticulo.ART_CODIGO === null ? '' : auxarticulo.ART_CODIGO;
    this.maearticulo.ART_RAMV = auxarticulo.ART_RAMV === null ? '' : auxarticulo.ART_RAMV;
    this.maearticulo.ART_MODELOSRI = auxarticulo.ART_MODELOSRI === null ? '' : auxarticulo.ART_MODELOSRI;
    this.maearticulo.ART_MODELOAT = auxarticulo.ART_MODELOAT === null ? '' : auxarticulo.ART_MODELOAT;
    this.maearticulo.ART_MARCAV = auxarticulo.ART_MARCAV === null ? '' : auxarticulo.ART_MARCAV;
    this.maearticulo.ART_CLASE = auxarticulo.ART_CLASE === null ? '' : auxarticulo.ART_CLASE;
    this.maearticulo.ART_TIPOV = auxarticulo.ART_TIPOV === null ? '' : auxarticulo.ART_TIPOV;
    this.maearticulo.ART_CILINDRAJE = auxarticulo.ART_CILINDRAJE === '' ? null : auxarticulo.ART_CILINDRAJE;
    this.maearticulo.ART_ANIO = auxarticulo.ART_ANIO;
    this.maearticulo.ART_TONELAJE = auxarticulo.ART_TONELAJE === '' ? null : auxarticulo.ART_TONELAJE;
    this.maearticulo.ART_COLOR = auxarticulo.ART_COLOR === null ? '' : auxarticulo.ART_COLOR;
    this.maearticulo.ART_PORIGEN = auxarticulo.ART_PORIGEN === null ? '' : auxarticulo.ART_PORIGEN;
    this.maearticulo.ART_MOTOR = auxarticulo.ART_MOTOR === null ? '' : auxarticulo.ART_MOTOR;
    this.maearticulo.ART_SERIEV = auxarticulo.ART_SERIEV === null ? '' : auxarticulo.ART_SERIEV;
    this.maearticulo.ART_NRUEDAS = auxarticulo.ART_NRUEDAS === '' ? null : auxarticulo.ART_NRUEDAS;
    this.maearticulo.ART_NEJES = auxarticulo.ART_NEJES === '' ? null : auxarticulo.ART_NEJES;
    this.maearticulo.ART_CAPACIDAD = auxarticulo.ART_CAPACIDAD === '' ? null : auxarticulo.ART_CAPACIDAD;
    this.maearticulo.ART_COMBUSTIBLE = auxarticulo.ART_COMBUSTIBLE === null ? '' : auxarticulo.ART_COMBUSTIBLE;
    this.maearticulo.ART_BANCO = auxarticulo.ART_BANCO === null ? '' : auxarticulo.ART_BANCO;
    this.maearticulo.ART_DPAGO = auxarticulo.ART_DPAGO === null ? '' : auxarticulo.ART_DPAGO;
    console.log(auxarticulo.ART_FFABRICA);
    console.log(this.datePipe.transform(auxarticulo.ART_FFABRICA, 'dd/MM/yyyy'));

    const fecha = this.datePipe.transform(auxarticulo.ART_FFABRICA, 'dd/MM/yyyy') === null ? '' : this.datePipe.transform(auxarticulo.ART_FFABRICA, 'dd/MM/yyyy');
    this.maearticulo.ART_DISPOSITIVO = auxarticulo.ART_DISPOSITIVO === null ? '' : auxarticulo.ART_DISPOSITIVO;

    await this.artService.actualizarDatosAutoArticulo(this.maearticulo, fecha);
    this.messageService.add({
      key: 'art',
      severity: 'success',
      summary: 'Información',
      detail: 'Datos del vehículo guardardos exitosamente'
    });


    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.bolnuevo = false;
    this.auditoriagral.registrarAuditoria('INV_MAEARTICULO',
      this.maearticulo.ART_CODIGO + '_DATOSVEHICULO', 'A', '', '01', '', '', '', '').subscribe();
  }

  // -----------------------------------------busqueda vehiculo -------------------------
  busquedamarcavehiculo(parametro) {
    this.opcionbusqueda = 'busquedamarcavehiculo';
    this.types = [
      { label: 'Codigo', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'REF_TIPO=\'MARVEH\'';

    this.artService.encontrarRefNombre('MARVEH', parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_MARCAV = parametro;
          this.botonesmodificar();
          document.getElementById('ART_MARCAV').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'MARVEH\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'MARVEH\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaclasevehiculo(parametro) {
    this.opcionbusqueda = 'busquedaclasevehiculo';
    this.types = [
      { label: 'Codigo', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'REF_TIPO=\'CLAVEH\'';

    this.artService.encontrarRefNombre('CLAVEH', parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_CLASE = parametro;
          this.botonesmodificar();
          document.getElementById('ART_CLASE').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'CLAVEH\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'CLAVEH\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedatipovehiculo(parametro) {
    this.opcionbusqueda = 'busquedatipovehiculo';
    this.types = [
      { label: 'Codigo', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'REF_TIPO=\'TIPVEH\'';

    this.artService.encontrarRefNombre('TIPVEH', parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_TIPOV = parametro;
          this.botonesmodificar();
          document.getElementById('ART_TIPOV').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'TIPVEH\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'TIPVEH\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacomvehiculo(parametro) {
    this.opcionbusqueda = 'busquedacomvehiculo';
    this.types = [
      { label: 'Codigo', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'REF_TIPO=\'COMVEH\'';

    this.artService.encontrarRefNombre('COMVEH', parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_COMBUSTIBLE = parametro;
          this.botonesmodificar();
          document.getElementById('ART_COMBUSTIBLE').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'COMVEH\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'COMVEH\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedadisvehiculo(parametro) {
    this.opcionbusqueda = 'busquedadisvehiculo';
    this.types = [
      { label: 'Codigo', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'REF_TIPO=\'DISVEH\'';

    this.artService.encontrarRefNombre('DISVEH', parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_DISPOSITIVO = parametro;
          this.botonesmodificar();
          document.getElementById('ART_DISPOSITIVO').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'DISVEH\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'DISVEH\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedapaisvehiculo(parametro) {
    this.opcionbusqueda = 'busquedapaisvehiculo';
    this.types = [
      { label: 'Codigo', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'REF_TIPO=\'P\'';

    this.artService.encontrarRefNombre('P', parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_PORIGEN = parametro;
          this.botonesmodificar();
          document.getElementById('ART_PORIGEN').focus();
        } else {
          this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'P\'').subscribe((datos: any[]) => {
            this.arregloCons = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6('REF_TIPO=\'P\'').subscribe((datos: any[]) => {
          this.arregloCons = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedabanco(parametro) {
    this.opcionbusqueda = 'busquedabanco';
    this.types = [
      { label: 'Código', value: 'BANCLI_CODIGO' },
      { label: 'Nombre', value: 'BANCLI_NOMBRE' },
      { label: 'Estado', value: 'BANCLI_ESTADO' },
      { label: 'Nombre', value: 'COM_CODIGO' },
    ];
    this.cliService.erNombreBanco(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.maearticulo.ART_BANCO = parametro;
          this.maearticulo.ART_NBANCO = eR[0].BANCLI_NOMBRE;
          document.getElementById('ART_BANCO').focus();
        } else {
          this.busqService.busquedaCXCMaeBanCli3().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusqueda = true;
          });
        }
      } else {
        this.busqService.busquedaCXCMaeBanCli3().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusqueda = true;
        });
      }
    });
  }
  // ----------------------------------------- fin busqueda vehiculo -------------------------

  async verimagen(parametro) {
    this.opcionbusqueda = 'verimagen';
    if (this.maearticulo.ART_CODIGO !== '') {
      const usu = await this.initConf.obtenerPermisoUsuario(this.usuario.identificacion);
      if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
        this.displayDialogDocumentos = true;
      } else {
        this.messageService.add({
          key: 'art',
          severity: 'info',
          summary: 'Información',
          detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
        });
      }

    }
  }
}
