import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MessageService, SelectItem } from 'primeng';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';

@Component({
  selector: 'app-busquedadlgmae',
  templateUrl: './busquedadlgmae.component.html',
  styleUrls: ['./busquedadlgmae.component.css']
})
export class BusquedadlgmaeComponent implements OnInit, OnChanges {

  @Input() arregloConsulta: any[];
  @Input() arrayOptions: SelectItem[];
  @Input() tabla: string;
  @Input() busquedaParams: string[];
  @Input() consulta: string;
  @Input() where: string;
  @Input() busquedaCompuesta: boolean;
  @Output() opcionSeleccionada = new EventEmitter<any>();

  tipoBusqueda: string;
  tipoSeleccionado: string;
  spin: boolean;

  constructor(private busqService: InvbusquedaService, private message: MessageService) { }

  ngOnInit() {
    this.tipoBusqueda = 'contains';
    this.tipoSeleccionado = '';
    this.consulta = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const valor in changes) {
      if (valor === 'arrayOptions') {
        if (this.arrayOptions !== undefined) {
          this.tipoSeleccionado = this.arrayOptions[0].value;
        }
      } else if (valor === 'arregloConsulta') {
      }
    }
  }

  busquedaIndi(params, tipo) {

    console.log(params, tipo);

    let condicion = '';
    let aux = 0;

    if (tipo === 'contains') {
      aux = 0;
      // for (const {item, index} of this.arrayOptions.map((item, index) => ({item, index}))) {
      // this.arrayOptions.forEach((item, index) => {
      //   if (params[index] !== '') {
      //     if (aux === 0) {
      //       condicion = 'UPPER(' + item.value + ') LIKE UPPER(\'%' + params[index] + '%\')';
      //       aux++;
      //     } else {
      //       condicion = condicion + ' AND UPPER(' + item.value + ') LIKE UPPER(\'%' + params[index] + '%\')';
      //       aux++;
      //     }
      //   }
      //   if (aux === 0) {
      //     condicion = 'ROWNUM<100';
      //   }
      // });
      this.arrayOptions.forEach((item, index) => {
        if (params[index] !== '') {
          if (aux === 0) {
            condicion = 'UPPER(' + item.value + ') LIKE UPPER(\'%' + params[index] + '%\')';
            if (item.value === 'TRNRES_FECHA' || item.value === 'TRNRES_CADUCA' || item.value === 'TRNBAN_FECHA'
              || item.value === 'EMP_FECINGRESO' || item.value === 'TRNCAJ_FECHA' || item.value === 'CONTROLTICKET_FECHA') {
              condicion = 'TO_CHAR(' + item.value + ',\'DD/MM/YYYY\') LIKE UPPER(\'%' + params[index] + '%\')';
            }
            //CDPJ
            if (this.tabla === 'VEN_CONTROLTICKET A' && item.value === 'REF_NOMBRE') {
              condicion = 'UPPER(SACI_FNC_OBTIENEREFNOMBRE(\'TECOP\',ref_codigo)) LIKE UPPER(\'%' + params[index] + '%\')'
            } else if (this.tabla === 'VEN_CONTROLTICKET A' && item.value === 'CLI_NOMBRE') {
              condicion = 'UPPER(VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,\'01\')) LIKE UPPER(\'%' + params[index] + '%\')'
            }
            //CDPJ
            aux++;
          } else {
            if (item.value === 'TRNRES_FECHA' || item.value === 'TRNRES_CADUCA' || item.value === 'TRNBAN_FECHA' || item.value === 'EMP_FECINGRESO' || item.value === 'TRNCAJ_FECHA' || item.value === 'CONTROLTICKET_FECHA') {
              condicion = condicion + ' AND TO_CHAR(' + item.value + ',\'DD/MM/YYYY\') LIKE UPPER(\'%' + params[index] + '%\')';
            } else {
              //CDPJ
              if (this.tabla === 'VEN_CONTROLTICKET A' && item.value === 'REF_NOMBRE') {
                condicion = condicion + ' AND UPPER(SACI_FNC_OBTIENEREFNOMBRE(\'TECOP\',ref_codigo)) LIKE UPPER(\'%' + params[index] + '%\')';
              } else if (this.tabla === 'VEN_CONTROLTICKET A' && item.value === 'CLI_NOMBRE') {
                condicion = condicion + ' AND UPPER(VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,\'01\')) LIKE UPPER(\'%' + params[index] + '%\')';
              } else {
                condicion = condicion + ' AND UPPER(' + item.value + ') LIKE UPPER(\'%' + params[index] + '%\')';
              }
              //CDPJ
            }
            aux++;
          }
        }
        if (aux === 0) {
          condicion = 'ROWNUM<100';
        }
      });
    }

    if (tipo === 'equals') {
      aux = 0;
      this.arrayOptions.forEach((item, index) => {
        if (params[index] !== '') {
          if (aux === 0) {
            condicion = 'UPPER(' + item.value + ') = UPPER(\'' + params[index] + '\')';
            if (item.value === 'TRNRES_FECHA' || item.value === 'TRNRES_CADUCA' || item.value === 'TRNBAN_FECHA' || item.value === 'EMP_FECINGRESO' || item.value === 'TRNCAJ_FECHA' || item.value === 'CONTROLTICKET_FECHA') {
              condicion = 'TO_CHAR(' + item.value + ',\'DD/MM/YYYY\') = UPPER(\'' + params[index] + '\')';
            }
            //CDPJ
            if (this.tabla === 'VEN_CONTROLTICKET A' && item.value === 'REF_NOMBRE') {
              condicion = 'UPPER(SACI_FNC_OBTIENEREFNOMBRE(\'TECOP\',ref_codigo)) = UPPER(\'' + params[index] + '\')'
            } else if (this.tabla === 'VEN_CONTROLTICKET A' && item.value === 'CLI_NOMBRE') {
              condicion = 'UPPER(VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,\'01\')) = UPPER(\'' + params[index] + '\')'
            }
            //CDPJ
            aux++;
          } else {
            if (item.value === 'TRNRES_FECHA' || item.value === 'TRNRES_CADUCA' || item.value === 'TRNBAN_FECHA' || item.value === 'EMP_FECINGRESO' || item.value === 'TRNCAJ_FECHA' || item.value === 'CONTROLTICKET_FECHA') {
              condicion = condicion + ' AND TO_CHAR(' + item.value + ',\'DD/MM/YYYY\') = UPPER(\'' + params[index] + '\')';
            } else {
              //CDPJ
              if (this.tabla === 'VEN_CONTROLTICKET A' && item.value === 'REF_NOMBRE') {
                condicion = condicion + ' AND UPPER(SACI_FNC_OBTIENEREFNOMBRE(\'TECOP\',ref_codigo)) = UPPER(\'' + params[index] + '\')'
              } else if (this.tabla === 'VEN_CONTROLTICKET A' && item.value === 'CLI_NOMBRE') {
                condicion = condicion + ' AND UPPER(VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,\'01\')) = UPPER(\'' + params[index] + '\')'
              } else {
                condicion = condicion + ' AND UPPER(' + item.value + ') LIKE UPPER(\'%' + params[index] + '%\')';
              }
              //CDPJ
            }
            aux++;
          }
        }
        if (aux === 0) {
          condicion = 'ROWNUM<100';
        }
      });
      // this.arrayOptions.forEach((item, index) => {
      //   if (params[index] !== '') {
      //     if (aux === 0) {
      //       condicion = 'UPPER(' + item.value + ') = UPPER(\'' + params[index] + '\')';
      //       aux++;
      //     } else {
      //       condicion = condicion + ' AND UPPER(' + item.value + ') = UPPER(\'' + params[index] + '\')';
      //       aux++;
      //     }
      //   }
      //   if (aux === 0) {
      //     condicion = 'ROWNUM<100';
      //   }
      // });
    }

    if (this.where !== undefined) {
      if (this.where.length > 0) {
        condicion = condicion + ' AND ' + this.where;
      }
    }

    this.spin = true;

    console.log(this.consulta);

    if (this.consulta === '') {

      this.busqService.busquedaTabla('*', this.tabla, condicion).subscribe((datos: any[]) => {
        if (datos === null) {
          this.mensajeAlerta('error', 'ERROR', 'No se han encontrado coincidencias ' +
            ' bajo los criterios especifiados, modifiquelos, he intente nuevamente');
        } else {
          this.datosObtenidos(datos);
        }
        this.spin = false;
      });


    } else if (this.consulta !== '' && this.busquedaCompuesta === false) {
      this.busqService.busquedaTabla(this.consulta, this.tabla, condicion).subscribe((datos: any[]) => {
        if (datos === null) {
          this.mensajeAlerta('error', 'ERROR', 'No se han encontrado coincidencias ' +
            ' bajo los criterios especifiados, modifiquelos, he intente nuevamente');
        } else {
          this.datosObtenidos(datos);
        }
        this.spin = false;
      });
    }

  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'busqDlgMae',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  datosObtenidos(datos) {
    for (let i = 0; i < 30; i++) {
      this.busquedaParams[i] = '';
    }
    this.opcionSeleccionada.emit(datos);
  }

}
