import { Component, OnInit, ViewChild } from '@angular/core';

// Ag-grid
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ProdencordproService } from '../prodservicios/prodencordpro.service';
import { DatePipe } from '@angular/common';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { MessageService, SelectItem, ConfirmationService } from 'primeng';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { ProdDetordpro } from '../prodinterfaces/proddetordpro';
import { ProdEncordpro } from '../prodinterfaces/prodencordpro';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { ProdTrncostosind } from '../prodinterfaces/prodtrncostosind';
import { ProdTrntecnicos } from '../prodinterfaces/prodtrntecnicos';
import { ProdTrnpedidos } from '../prodinterfaces/prodtrnpedidos';
import { ProdTrnmaquinaria } from '../prodinterfaces/prodtrnmaquinaria';
import { ProdTrnliquidacion } from '../prodinterfaces/prodtrnliquidacion';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { Usuario } from 'src/app/usuario';
import { ProdTrnhorashombre } from '../prodinterfaces/prodtrnhorashombre';
import { Prodprofabri } from "../prodinterfaces/prodprofabri";
import { Prodprocontrol } from "../prodinterfaces/prodprocontrol";

@Component({
  selector: 'app-prodencordpro',
  templateUrl: './prodencordpro.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ProdencordproComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;
  index: number;
  permisoOrdPro: SegMaePermiso;

  // barra
  barraBotones2: boolean;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonFactura: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  botonNuevoL: boolean;
  botonGuardarL: boolean;
  botonBorrarL: boolean;
  botonRegresarL: boolean;

  // dialogs    
  displayDialogBusquedaFast: boolean;
  displayAcciones: boolean;
  displayDialogAutorizacion: boolean;
  displayDialogSupervisor: boolean;
  displayAsiContable: boolean;
  displayDialogReportes: boolean;
  displayDialogObservacion: boolean;
  displayControlCalidad: boolean;
  displayDialogLotes: boolean;
  displayDialogLotesMP: boolean;
  displayToken = false;
  spin = false;

  // dropdowns
  cmb: any[];
  select: any;
  cmbProcedimiento: any[];
  selectProcedimiento: any;
  cmbTipoProd: any[];
  // selectTipoProd: any;
  cmbProceso: any[];
  selectProceso: any;
  formula: string;
  cmbAsiento: any[];
  selectAsiento: any;
  asiento: string;

  // checkbox
  chk: boolean;
  chbtnIngreso: boolean;
  chbtnInicio: boolean;
  chbtnTermino: boolean;
  chbtnVencLote: boolean;

  // enabled
  enabledtxtReferencia: boolean;
  enabledcmbProceso: boolean;
  enabledcmbTipoProd: boolean;

  GRUPO: string;
  ESTADO: string;
  USUARIO: string;
  FECHA: string;
  HORA: string;
  tabItemLiquidacion = '';
  tabItemAdicionales = '';
  tabItemTecnicos = '';
  tabItemMaquinaria = '';
  tabProfabri = '';
  tabprocontrol = '';
  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;
  consulta: string;

  vistxtgrupo: boolean;
  vistxtestado: boolean;
  detordproSelected: ProdDetordpro = {};
  trncostoindSelected: ProdTrncostosind;
  trnpedidoSelected: ProdTrnpedidos;
  trntecnicoSelected: ProdTrntecnicos;
  trnmaquinSelected: ProdTrnmaquinaria;
  trnliquidacionSelected: ProdTrnliquidacion;
  trnhorashombreSelected: ProdTrnhorashombre;
  loteSelected: any;
  prodfabricaselected: Prodprofabri;//CDPJ
  prodcontrolselected: Prodprocontrol;//CDPJ


  str_NumOrdPro: any;
  globalNemonico: any;
  globalSerie: any;
  str_CONCALPRD: any;
  str_GENASIMC: any;
  auxiliar: number;
  auxiliar1: number;
  str_observacion: string;
  bolordenactivada: boolean;
  LOTEPRO: any;
  // reporte
  auxreporte: number = 0;
  strNombreForma: string;
  tokenusuario = '';
  auxiliarlot: number = 0;

  // autorizacion
  claveAuth: string;
  autorizacionCodigo: string;
  bolAutorizacion: boolean;

  // supervision
  usuarioSup: string;
  claveSup: string;
  strDialogo: string;
  strObjeto: string;
  intPestaniaCol: number;
  bolSupervisor: boolean;

  // aggrid
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDef;
  defaultColDefcostoind;
  defaultColDefhorashombre;
  defaultColDefpedido;
  defaultColDefliquidacion;
  defaultColDeftecnico;
  defaultColDefmaquinaria;
  defaultColDefLote;
  largo: string;
  largol: string;
  //CDPJ
  defaultColDefProfab;
  defaultColDefProcont;
  reghora = /^([01]\d|2[0-3)]):([0-5]\d)$/
  etiquetasaudi:any[];
  //CDPJ
  displayDialogDocumentos = false;

  columnDefs = [
    { // 0
      headerName: 'L', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'T', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 85,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 3
      headerName: 'Código', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 110,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    { // 4
      headerName: 'Descripción', field: 'DETORDPRO_DESCRIPCION', cellEditor: 'cellPrueba', width: 300,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 5
      headerName: 'Unidad', field: 'UNI_CODIGO', cellEditor: 'cellPrueba', width: 80,
      editable: (params) => {
        const existDesp = this.pruebaCambio(params.data);
        return existDesp;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 6
      headerName: 'Cantidad', field: 'DETORDPRO_CANTIDAD', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    { // 7
      headerName: 'Costo', field: 'DETORDPRO_COSTO', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    { // 8
      headerName: 'Total', field: 'DETORDPRO_TOTAL', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    { // 9
      headerName: 'CodUnidadCosteo', field: 'UNI_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 10
      headerName: 'CodUnidaSalida', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 11
      headerName: 'ArtParFor', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 12
      headerName: 'Lote', field: 'DETORDPRO_LOTE', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 13
      headerName: 'Existencia', field: 'EXISTENCIA', cellEditor: 'cellPrueba', width: 110, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 14
      headerName: 'PPA', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 15
      headerName: 'CPPA', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }
  ];


  columnDefscostoind = [
    { // 0
      headerName: 'L', field: '', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 1
      headerName: 'Fecha', field: 'TRNCOSTOSIND_FECHA', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 2
      headerName: 'Hora', field: 'TRNCOSTOSIND_HORA', cellEditor: 'cellPrueba', width: 85,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 3
      headerName: 'CodCostoEstandar', field: 'CEST_CODIGO', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 4
      headerName: 'Cod.CostoInd.', field: 'CIN_CODIGO', cellEditor: 'cellPrueba', width: 110,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 5
      headerName: 'Nombre', field: 'CIN_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 6
      headerName: 'Costo', field: 'TRNCOSTOSIND_COSTO', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      },
    },
    { // 7
      headerName: 'CodigoAuxiliar', field: 'CODIGO_AUXILIAR', cellEditor: 'cellPrueba', width: 110, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    {
      headerName: 'Sel.', field: 'CHECK',
      cellRendererFramework: AggridBanComponent,
      editable: false,
      width: 50,
      onCellClicked: (params) => {
        this.seleccionItem(params.data);
      }
    }
  ];

  columnDefshorashombre = [
    { // 0
      headerName: 'Fecha', field: 'TRNHH_FECHA', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    { // 1
      headerName: 'Hora Inicio', field: 'TRNHH_HORAINI', cellEditor: 'cellPrueba', width: 105,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 5,
          tienecalculos: true
        }
      }
    },
    { // 2
      headerName: 'Hora Final', field: 'TRNHH_HORAFIN', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 5,
          tienecalculos: true
        }
      }
    },
    { // 3
      headerName: 'Cod.CostoInd.', field: 'CIN_CODIGO', cellEditor: 'cellPrueba', width: 125,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 2,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 4
      headerName: 'Descripción', field: 'CIN_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 4
      headerName: 'Empleado', field: 'EMP_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: true,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 5
      headerName: 'Referencia', field: 'TRNHH_REFERENCIA', cellEditor: 'cellPrueba', width: 120,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 2,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 6
      headerName: 'Cantidad', field: 'TRNHH_CANTIDAD', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      },
    },
    { // 7
      headerName: 'Costo', field: 'TRNHH_COSTO', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      },
    },
    { // 8
      headerName: 'Total', field: 'TRNHH_TOTAL', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      },
    }
  ];

  columnDefspedido = [
    { // 0
      headerName: 'Pedido', field: 'ENCPED_NUMERO', cellEditor: 'cellPrueba', width: 150,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Referencia', field: 'ENCPED_REFERENCIA', cellEditor: 'cellPrueba', width: 130,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Fecha', field: 'ENCPED_FECHAEMISION', cellEditor: 'cellPrueba', width: 85,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 3
      headerName: 'CodigoAuxiliar', field: 'CODIGO_AUXILIAR', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 4
      headerName: 'Código', field: 'DETPED_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 5
      headerName: 'Nombre', field: 'DETPED_DESCRIPCION', cellEditor: 'cellPrueba', width: 110,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 6
      headerName: 'Cantidad', field: 'DETPED_CANTIDAD', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      },
    },
    { // 7
      headerName: 'Linea', field: 'DETPED_LINEA', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }];



  columnDefsliquidacion = [
    { // 0
      headerName: 'Fecha', field: 'TRNLIQ_FECHAES', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Tipo', field: 'TRNLIQ_TIPO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 85,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 3
      headerName: 'Código', field: 'TRNLIQ_CODIGO', cellEditor: 'cellPrueba', width: 110,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 4
      headerName: 'Descripción', field: 'TRNLIQ_DESCRIPCION', cellEditor: 'cellPrueba', width: 300,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 5	
      headerName: 'Cantidad', field: 'TRNLIQ_CANTIDAD', cellEditor: 'cellPrueba', width: 80,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    { // 6
      headerName: 'Entradas', field: 'TRNLIQ_ENTRADA', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      },
    },
    { // 7  
      headerName: 'Salidas', field: 'TRNLIQ_SALIDA', cellEditor: 'cellPrueba', width: 110, cellStyle: { textAlign: 'right' },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 8
      headerName: 'Hora', field: 'TRNLIQ_HORA', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }, { // 9
      headerName: 'Número Ref', field: 'TRNLIQ_NUMERO', cellEditor: 'cellPrueba', width: 120,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }, { // 10   
      headerName: 'Cuenta Ref', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }, { // 11
      headerName: 'Nombre Cuenta', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', width: 150,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }
  ];

  columnDefstecnico = [
    { // 0
      headerName: 'Código', field: 'TRNTEC_CODIGO', cellEditor: 'cellPrueba', width: 150,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Nombre', field: 'TRNTEC_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'CodigoAuxiliar', field: 'CODIGO_AUXILIAR', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }
  ];

  columnDefsmaquinaria = [
    { // 0
      headerName: 'Código', field: 'TRNMAQ_CODIGO', cellEditor: 'cellPrueba', width: 150,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Nombre', field: 'TRNMAQ_NOMBRE', cellEditor: 'cellPrueba', width: 250,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'CodigoAuxiliar', field: 'CODIGO_AUXILIAR', cellEditor: 'cellPrueba', width: 0, 'hide': true,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    }];

  columnDefsLotes = [
    { // 0
      headerName: 'Lote', field: 'TRNLOTE_LOTE', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Cantidad', field: 'TRNLOTE_CANTIDAD', cellEditor: 'cellPrueba', width: 100,
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    }
  ];
  //CDPJ
  columnDefsProfab = [
    { // 0
      headerName: 'Etapa', field: 'PROFAB_ETAPA', cellEditor: 'cellPrueba', editable: true, width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: false,
          tamanocelda: 20
        }
      }
    },
    { // 1
      headerName: 'Descripción', field: 'PROFAB_DESCRIPCION', cellEditor: 'cellPrueba', editable: true, width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 300
        }
      }
    },
    { // 2
      headerName: 'Código', field: 'ART_CODIGO_MP', cellEditor: 'cellPrueba', editable: true, width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 25
        }
      }
    },
    { // 3
      headerName: 'Nombre', field: 'ART_NOMBRE_MP', cellEditor: 'cellPrueba', editable: (params) => {
        return false;
      }, width: 150,

    },
    
    { // 4
      headerName: 'Cantidad', field: 'PROFAB_CANTIDAD', cellEditor: 'cellPrueba', editable: true, width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 10
        }
      }
    },
    { // 5
      headerName: 'Unidad', field: 'PROFAB_UNIDAD', cellEditor: 'cellPrueba', editable: true, width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 25
        }
      }
    },
    { // 6
      headerName: 'Hora Inicio', field: 'PROFAB_HORA_INICIO', cellEditor: 'cellPrueba', width: 80,
      editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 5,
          tienecalculos: true
        }
      }

    },
    { // 7
      headerName: 'Hora Fin', field: 'PROFAB_HORA_FIN', cellEditor: 'cellPrueba', width: 80,
      editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 5,
          tienecalculos: true
        }
      }
    },
  ];
  columnDefsProcontrol = [
    { // 0
      headerName: 'Prámetro', field: 'PROCONT_PARAMETRO', cellEditor: 'cellPrueba', editable: true, width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: false,
          tamanocelda: 50
        }
      }
    },
    { // 1
      headerName: 'Especif.Mín', field: 'PROCONT_ESP_MINIMA', cellEditor: 'cellPrueba', editable: true, width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: false,
          tamanocelda: 100
        }
      }
    },
    { // 2
      headerName: 'Especif.Máx', field: 'PROCONT_ESP_MAXIMA', cellEditor: 'cellPrueba', editable: true, width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: false,
          tamanocelda: 100
        }
      },
    },
    { // 3
      headerName: 'Método', field: 'PROCONT_METODO', cellEditor: 'cellPrueba', editable: true, width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: false,
          tamanocelda: 20
        }
      }
    },
  ];
  //CDPJ

  constructor(public encordproService: ProdencordproService, public init: NuevoComponentService,
    private datePipe: DatePipe, private auditoriagral: AuditoriagralService,
    private messageService: MessageService, private confIniciales: ConfInicialesService,
    private permisosService: PermisosService, private busqService: InvbusquedaService,
    private confirmationService: ConfirmationService, private utilitariosService: UtilitariosService,
    public usuario: Usuario) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyle = (params) => {
      if (params.data.COLOREXISTENCIA === 'Y') {
        return { background: 'rgb(250, 163, 225)' };
      }
      if (params.data.COLORFORMULACION === 'Y') {
        return { background: 'rgb(133, 207, 37)' };
      }
    };

    this.defaultColDef = {
      editable: true,
      width: 100,
      objeto: 'detordpro',
      resizable: true
    };

    this.defaultColDefcostoind = {
      editable: true,
      width: 100,
      objeto: 'trncostoind',
      resizable: true
    };

    this.defaultColDefhorashombre = {
      editable: true,
      width: 100,
      objeto: 'trnhorashombre',
      resizable: true
    };


    this.defaultColDefpedido = {
      editable: true,
      width: 100,
      objeto: 'trnpedidos',
      resizable: true
    };

    this.defaultColDefliquidacion = {
      editable: true,
      width: 100,
      objeto: 'trnliquidacion',
      resizable: true
    };

    this.defaultColDeftecnico = {
      editable: true,
      width: 100,
      objeto: 'trntecnico',
      resizable: true
    };

    this.defaultColDefmaquinaria = {
      editable: true,
      width: 100,
      objeto: 'trnmaquinaria',
      resizable: true
    };

    this.defaultColDefLote = {
      editable: true,
      width: 100,
      objeto: 'trnloteprod',
      resizable: true
    };
    this.defaultColDefProfab = {
      editable: true,
      width: 100,
      objeto: 'prodprofab',
      resizable: true
    }
    this.defaultColDefProcont = {
      editable: true,
      width: 100,
      objeto: 'prodprocontrol',
      resizable: true
    }
  }

  botonesInicio() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.botonFactura = false;
    this.botonAnular = true;
    this.botonXML = false;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.botonNuevoL = true;
    this.botonGuardarL = false;
    this.botonBorrarL = true;
    this.botonRegresarL = true;
   
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    const componente = this.init.tabs[this.init.tabindex - 1].component;

    this.index = 0;
    this.largo = '600';
    this.largol = '700';
    this.barraBotones2 = true;
    this.auxiliar = 0;
    this.auxiliar1 = 0;
    this.botonesInicio();

    this.vistxtgrupo = false;
    this.vistxtestado = false;

    this.encordproService.encordpro[this.indicador] = {};
    this.encordproService.detordpro[this.indicador] = [];
    this.encordproService.trncostosindirectos[this.indicador] = [];
    this.encordproService.trnpedidos[this.indicador] = [];
    this.encordproService.trnliquidacion[this.indicador] = [];
    this.encordproService.trntecnicos[this.indicador] = [];
    this.encordproService.trnhorashombre[this.indicador] = [];
    //CDPJ
    this.encordproService.profabricacion[this.indicador] = []
    this.encordproService.procontrol[this.indicador] = []
    //CDPJ
    this.GRUPO = '';
    this.ESTADO = '';
    this.USUARIO = this.encordproService.usuario.identificacion;
    this.FECHA = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.HORA = this.datePipe.transform(new Date(), 'HH:mm');
    this.bolordenactivada = false;

    this.hmpConfiguracion = await this.auditoriagral.obtenerConfiguracionProm();
    this.encordproService.hmpConfiguracion = this.hmpConfiguracion;

    this.str_NumOrdPro = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMORDPRO').CFG_VALOR3;
    this.str_GENASIMC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GENASIMC').CFG_VALOR1;
    this.LOTEPRO = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LOTEPRO').CFG_VALOR1;

    this.confIniciales.getDecimales('frmPROD_ENCORDPRO').subscribe((dec) => {
      if (dec !== null) {
        for (const decimales of dec) {
          if (decimales.NUMDEC_TIPDOC === 'detordpro') {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.encordproService.decimalesCantidad = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'COSTO') {
              this.encordproService.decimalesCosto = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
              this.encordproService.decimalesTotal = Number(decimales.NUMDEC_NUMDEC);
            }
          }
          if (decimales.NUMDEC_TIPDOC === 'encordpro') {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.encordproService.decimalesCantEnc = Number(decimales.NUMDEC_NUMDEC);
            }
          }
          if (decimales.NUMDEC_TIPDOC === 'trncostosind') {
            if (decimales.NUMDEC_CAMPO === 'COSTO') {
              this.encordproService.decimalesCostoind = Number(decimales.NUMDEC_NUMDEC);
            }
          }
        }
        /*dec.map((decimales) => {
          if (decimales.NUMDEC_TIPDOC === 'detordpro') {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.encordproService.decimalesCantidad = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'COSTO') {
              this.encordproService.decimalesCosto = Number(decimales.NUMDEC_NUMDEC);
            } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
              this.encordproService.decimalesTotal = Number(decimales.NUMDEC_NUMDEC);
            }
          }
          if (decimales.NUMDEC_TIPDOC === 'encordpro') {
            if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
              this.encordproService.decimalesCantEnc = Number(decimales.NUMDEC_NUMDEC);
            }
          }
          if (decimales.NUMDEC_TIPDOC === 'trncostosind') {
            if (decimales.NUMDEC_CAMPO === 'COSTO') {
              this.encordproService.decimalesCostoind = Number(decimales.NUMDEC_NUMDEC);
            }
          }
        });*/
        // this.encordproService.decimalesTotal = this.confIniciales.getNumDecSist();
        this.columnDefs[6].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encordproService.decimalesCantidad,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.columnDefs[7].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encordproService.decimalesCosto,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.columnDefs[8].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.encordproService.decimalesTotal,
            obligatorio: true,
            fecha: false,
            clave: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.aggrid.refreshColumnDefs();
      }
    });

    this.llenarCombos();
    this.activarTabsDeacuerdoEmp();
    this.getPermisos(componente);
  }

  async getPermisos(componente) {
    try {
      const data = await this.permisosService.getPermisosFrm(componente);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoOrdPro = data[0];
        } else {
          this.permisoOrdPro = null;
        }
      } else {
        this.permisoOrdPro = null;
      }
    } catch (e) {
      this.permisoOrdPro = null;
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }


  llenarCombos() {
    this.cmbProcedimiento = [{ codigo: '01', name: 'Producción' },
    { codigo: '02', name: 'Envasado' },
    { codigo: '', name: '' }];
    this.selectProcedimiento = { codigo: '', name: '' };

    this.cmbTipoProd = [];
    this.cmbTipoProd.push({ codigo: 'F', name: 'Formulación' });
    this.encordproService.selectTipoProd[this.indicador] = { codigo: 'F', name: 'Formulación' };
    const PROXPROC = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PROXPROC').CFG_VALOR1;
    if (PROXPROC === 1) {
      this.cmbTipoProd.push({ codigo: 'P', name: 'Proceso' });
    }
    this.etiquetasaudi=[
      {codigo:'ENCORDPRO_PROCEDIMIENTO',name:'Procedimiento'},{codigo:'ENCORDPRO_FECHAENTRADA',name:'Ingreso del producto a bodega'},
      {codigo:'ENCORDPRO_REFERENCIA',name:'Comprobante'},{codigo:'ENCORDPRO_TIPOPROD',name:'Tipo Producción:'},
      {codigo:'ENCORDPRO_LOTE',name:'Lote:'},{codigo:'ENCPED_NUMERO',name:'Pedido:'},{codigo:'ART_CODIGO',name:'Artículo:'},
      {codigo:'ENCORDPRO_VENCIMIENTOLOTE',name:'Vencimiento:'},{codigo:'CLIENTEPEDIDO',name:'Cliente:'},
      {codigo:'ENCORDPRO_CANTIDAD',name:'Cantidad:'},{codigo:'ENCORDPRO_FECHAEMISION',name:'Emisión:'},{codigo:'ENCORDPRO_FECHAEMISIONH',name:'EmisiónH:'},
      {codigo:'FOR_CODIGO',name:'Fórmula:'},{codigo:'BOD_CODIGO_ENT',name:'Bodega PT:'},{codigo:'ENCORDPRO_FECHAINICIO',name:'Inicio:'},
      {codigo:'ENCORDPRO_HORAINI',name:'InicioH:'},{codigo:'ENCORDPRO_OPERARIO',name:'Operador:'},{codigo:'BOD_CODIGO',name:'Bodega MP:'},
      {codigo:'ENCORDPRO_FECHAFIN',name:'Término:'},{codigo:'ENCORDPRO_HORAFIN',name:'TérminoH:'},{codigo:'ENCORDPRO_HORASHOMBRE',name:'Horas Hombre:'},
      {codigo:'ENCORDPRO_EQUIPO',name:'Equipo:'},{codigo:'ENCORDPRO_COMENTARIO',name:'Observ.:'}
    ]
    this.ingresaTiposProcesos();
  }

  async ingresaTiposProcesos() {
    const formulitas = [{ codigo: '', name: '' }];
    this.selectProceso = { codigo: '', name: '' };
    const data = await this.encordproService.getTipoProceso(this.encordproService.encordpro[this.indicador].ART_CODIGO);
    for (const rs of data) {
      formulitas.push({ codigo: rs.FOR_CODIGO, name: rs.FOR_CODIGO });
    }
    this.cmbProceso = formulitas;
  }

  async manejarSenales(valor) {
    //CDPJ
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable
      const dia = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getDate();
      const mes = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getMonth();
      const anio = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getFullYear();


      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'encordpro',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }
    let strCodigo = 0
    const detalle = await this.encordproService.verificarDetalleOrdProd(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO)
    if (detalle !== null && detalle !== undefined) {
      for (const rs of detalle) {
        strCodigo = rs.NUMERO;
      }
    }
    //CDPJ
    if (valor === 'Nuevo') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if (strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.NuevaOrdenProduccion();
    }
    if (valor === 'Guardar') {
      this.guardarRegistro();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Cancelar') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if (strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.cancelar('boton');
    }
    if (valor === 'Primero') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if (strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('P');
    }
    if (valor === 'Anterior') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if (strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('A');
    }
    if (valor === 'Siguiente') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if (strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('S');
    }
    if (valor === 'Ultimo') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if (strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if (strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.busquedaorden();
    }
    if (valor === 'IngresarProducto') {
      this.IngresarProductoTerminado();
    }
    if (valor === 'BorrarAsiento') {
      this.EliminarAsientoContable();
    }
    if (valor === 'Asiento') {
      this.GenerarAsientoContable();
    }
    if (valor === 'AsientoVer') {
      this.verAsiento();
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'ClaseNumeracion') {
      this.GRUPO = '';
    }
    if (valor === 'Salir') {
      //CDPJ
      if (this.encordproService.detordpro[this.indicador].length == 0
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        return;
      }
      if (strCodigo === 0 && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== null
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== undefined
        && this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== ''
        && this.encordproService.selectTipoProd[this.indicador].codigo !== 'P') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'la Orden de Producción no tiene guardado el detalle'
        });
        return;
      }
      //CDPJ
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (valor === 'Almacenardoc') {
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'encordpro',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    //CDPJ
    if (valor === 'Anular') {
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'I'
        || this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'T') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'Solo se pueden anular Ordenes de Producción en estado PENDIENTE'
        });
        return;
      } else {
        this.confirmationService.confirm({
          message: 'Está seguro de anular el registro?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarencordpro',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: async () => {
            this.confirmationService.close();
            await this.anularOrdProd();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      }
    }
    //CDPJ
  }
  //CDPJ
  async anularOrdProd() {
    try {
      let asiento = ''
      if (this.cmbAsiento.length > 0) {
        this.selectAsiento = this.cmbAsiento[0];
        asiento = this.selectAsiento.codigo
      } else {
        asiento = ''
        //   this.messageService.add({
        //     key: 'encordpro',
        //     severity: 'warn',
        //     summary: 'Información',
        //     detail: 'El asiento contable está vacío'
        //   });
        //   return;
      }
      //console.log('asi', this.selectAsiento.codigo)
      await this.encordproService.anularOrdProdu(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO, asiento);
      this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO, 'AN',
        '', '01', '', '', '', '').subscribe();
      if (this.cmbAsiento.length > 0) {
        await this.encordproService.anularAsinro(asiento);
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
          this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + asiento, 'AN', '', '01', '', '', '', '').subscribe();
      }

      await this.cancelar('boton');
    } catch (error) {
      console.log('error', error)
    }
  }
  //CDPJ
  async seleccionarRegistro(Opcion) {
    const str_BUSORDREF = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BUSORDREF').CFG_VALOR1;
    let data: any;
    if (str_BUSORDREF === 1) {
      data = await this.encordproService.seleccionarregistroref(Opcion, this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA);
    } else {
      data = await this.encordproService.seleccionarregistro(Opcion, this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
    }

    if (data !== null) {
      this.botonesInicio();
      this.mostrarDatos(data[0].ENCORDPRO_NUMERO, 'boton');
    }
  }

  async cancelar(flag) {
    this.spin = true;
    this.botonesInicio();
    await this.mostrarDatos(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO, flag);
    this.spin = false;
  }

  busquedaorden() {
    this.opcionbusqueda = 'orden';
    this.types = [
      { label: 'Orden', value: 'ENCORDPRO_NUMERO' },
      { label: 'Referencia', value: 'ENCORDPRO_REFERENCIA' },
      { label: 'Fecha Emi.', value: 'ENCORDPRO_FECHAEMISION' },
      { label: 'Fecha Inicio', value: 'ENCORDPRO_FECHAINICIO' },
      { label: 'Estado', value: 'ENCORDPRO_ESTADO' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'PROD_ENCORDPRO';
    this.where = ' ENCORDPRO_TIPOPROD<>\'P\' and ROWNUM < 50';

    this.busqService.busquedaProEncOrdPro1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.ENCORDPRO_FECHAEMISION !== null) {
          registro.ENCORDPRO_FECHAEMISION = this.datePipe.transform(registro.ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCORDPRO_FECHAEMISION = '';
        }
        if (registro.ENCORDPRO_FECHAINICIO !== null) {
          registro.ENCORDPRO_FECHAINICIO = this.datePipe.transform(registro.ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
        } else {
          registro.ENCORDPRO_FECHAINICIO = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  async busquedaarticulo(parametro) {
    this.opcionbusqueda = 'articulo';
    this.types = [
      { label: 'Orden', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'NombreCorto', value: 'ART_NOMBREC' },
      { label: 'CodigoAlterno', value: 'ART_CODIGOALT1' },
      { label: 'Ubicación', value: 'ART_UBICACION' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Alterno2', value: 'ART_CODIGOALT2' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'COM_CODIGO = \'01\' AND ART_ACTIVO=\'S\'' +
      ' AND NVL(ART_PRODUCTOPROD,\'X\')=\'S\'';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.encordproService.encontrararticulo(parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.busqService.busquedaInvMaeArticulo4(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async busquedapedido(parametro) {
    this.opcionbusqueda = 'pedido';
    this.types = [
      { label: 'Número', value: 'ENCPED_NUMERO' },
      { label: 'Comprobante', value: 'ENCPED_REFERENCIA' },
      { label: 'Fec. Emisión', value: 'ENCPED_FECHAEMISION' },
      { label: 'Fec. Entrega', value: 'ENCPED_FECHAENTREGA' },
      { label: 'Estado', value: 'ENCPED_ESTADO' },
      { label: 'Nombre Cliente', value: 'CLI_NOMBRE' },
      { label: 'Cod Cliente', value: 'CLI_CODIGO' },
      { label: 'Cod_Vendedor', value: 'VEN_CODIGO' },
      { label: 'Observación', value: 'ENCPED_OBSERVACION' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', ''];
    this.consulta = 'A.*, VEN_FNC_NOMBRE_CLIENTE(A.CLI_CODIGO, A.COM_CODIGO)';
    this.tabla = 'VEN_ENCPED A';
    this.where = 'ENCPED_NUMERO IN (SELECT ENCPED_NUMERO FROM VEN_DETPED where DETPED_CODIGO = \''
      + this.encordproService.encordpro[this.indicador].ART_CODIGO + '\')';

    this.busqService.busquedaVenEncped1(this.encordproService.encordpro[this.indicador].ART_CODIGO, parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async busquedabodega(parametro, tipo) {
    if (tipo === 'mp') {
      this.opcionbusqueda = 'bodegamp';
    }
    if (tipo === 'pt') {
      this.opcionbusqueda = 'bodegapt';
    }
    this.types = [
      { label: 'Código', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEBODEGA';
    this.where = '';

    if (parametro !== '' && parametro !== undefined && parametro !== null) {
      const eR = await this.encordproService.encontrarbodega(parametro);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          return;
        }
      }
    }

    this.busqService.busquedaInvMaeBodega1(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async changeTabs() {

  }

  activarOrden() {
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'T') {
      this.bolordenactivada = true;
      document.getElementById('contorno').style.pointerEvents = 'all';
      // document.getElementById('contornodetalle').style.pointerEvents = 'all';
      this.aggrid.habilitarGrid();
      this.messageService.add({
        key: 'encordpro',
        severity: 'success',
        summary: 'Información',
        detail: 'Orden de producción activada'
      });
    }

    this.closeSidebar();
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }

  async selecciondetalle(params) {
    this.closeSidebar();
    if (params === 'nuevo') {
      this.nuevodetalle();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalle();
      return;
    }

    if (params === 'lotes') {
      this.abrirLotes();
      return;
    }

    if (params.object !== undefined) {
      this.detordproSelected = params.object;
    }
  }

  nuevodetalle() {
    if (this.encordproService.encordpro[this.indicador].ART_CODIGO.length === 0) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese el artículo.'
      });
      return;
    }

    const detordpro: ProdDetordpro = this.nuevoElemento();;
    const indi = this.encordproService.detordpro[this.indicador].indexOf(this.detordproSelected);

    if (indi === -1) {
      detordpro.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.detordpro[this.indicador].push(detordpro);
      this.detordproSelected = detordpro;
      this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);
      if (this.encordproService.detordpro[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell(0, 'ART_CODIGO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell((this.encordproService.detordpro[this.indicador].length) - 1, 'ART_CODIGO');
      }
    } else {
      detordpro.CLAVE = (indi + 1) + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.detordpro[this.indicador].splice(indi + 1, 0, detordpro);
      this.detordproSelected = detordpro;
      this.aggrid.refreshaggridindex(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDef.objeto].setFocusedCell(indi + 1, 'ART_CODIGO');
    }
  }

  nuevoElemento() {
    const item: ProdDetordpro = {};

    item.DETORDPRO_LINEA = 0;
    item.BOD_CODIGO = this.encordproService.encordpro[this.indicador].BOD_CODIGO;
    item.ART_CODIGO = '';
    item.DETORDPRO_DESCRIPCION = '';
    item.UNI_CODIGO = '';
    item.DETORDPRO_CANTIDAD = 0.0;
    item.DETORDPRO_COSTO = 0.0;
    item.DETORDPRO_TOTAL = 0.0;
    item.DETORDPRO_LOTE = '';
    item.EXISTENCIA = 0.0;
    item.DETORDPRO_PPA = '';
    item.DETORDPRO_CPPA = '';
    item.COLOREXISTENCIA = '';
    item.COLORFORMULACION = '';

    return item
  }

  cancelardetalle() {

  }

  editarDetalle() {

  }

  eliminarLineaDetalle() {
    if (this.detordproSelected !== undefined && this.encordproService.detordpro[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.detordproSelected.DETORDPRO_LINEA !== 0) {
            this.encordproService.eliminarDetalle(this.detordproSelected);
            this.encordproService.detordpro[this.indicador].splice(this.encordproService.detordpro[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.detordproSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            //CDPJ
            this.auditoriagral.registrarAuditoria('PROD_DETORDPRO',
              this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.detordproSelected.ART_CODIGO + '/', 'E',
              '', '01', '', '', '', '').subscribe();
            //CDPJ
            if (this.encordproService.detordpro[this.indicador].length > 0) {
              this.detordproSelected = this.encordproService.detordpro[this.indicador]
              [this.encordproService.detordpro[this.indicador].length - 1];
            }

            this.encordproService.SumarTotal();
            this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);

            if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO !== '') {
              this.guardarRegistro();
            }
          } else {
            this.encordproService.detordpro[this.indicador].splice(this.encordproService.detordpro[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.detordproSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.detordpro[this.indicador].length > 0) {
              this.detordproSelected = this.encordproService.detordpro[this.indicador]
              [this.encordproService.detordpro[this.indicador].length - 1];
            }

            this.encordproService.SumarTotal();
            this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  cambio(cambio) {
    if (cambio === true) {
      this.botonesmodificar();
    }
  }

  cambiol(cambio) {
    if (cambio === true) {
      this.botonesmodificarlotes();
    }
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'txtReferencia': {
        element = document.getElementById('txtArticulo');
        break;
      }
      case 'txtArticulo': {
        this.busquedaarticulo(event.target.value);
        break;
      }
      case 'txtUnidad': {
        element = document.getElementById('txtCantidad');
        break;
      }
      case 'txtCantidad': {
        if (this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD > 0) {
          this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD).toFixed(4);
          this.changeProceso();
          element = document.getElementById('txtBodegaPT');
        } else {
          element = document.getElementById('txtBodegaPT');
        }
        break;
      }
      case 'txtBodegaPT': {
        this.busquedabodega(event.target.value, 'pt');
        break;
      }
      case 'txtBodegaMP': {
        this.busquedabodega(event.target.value, 'mp');
        break;
      }
      case 'txtLote': {
        element = document.getElementById('txtPedido');
        break;
      }
      case 'txtPedido': {
        this.busquedapedido(event.target.value);
        break;
      }
      case 'txtOpe': {
        element = document.getElementById('txtEquipo');
        break;
      }
      case 'txtEquipo': {
        element = document.getElementById('txtObservacion');
        break;
      }
      case 'txtObservacion': {
        element = document.getElementById('txtArticulo');
        break;
      }
      // pestaña Adicionales
      case 'txtTemperatura': {
        element = document.getElementById('txtSolvente');
        break;
      }
      case 'txtSolvente': {
        element = document.getElementById('txtMezcla');
        break;
      }
      case 'txtMezcla': {
        element = document.getElementById('txtTipoSolvente');
        break;
      }
      case 'txtTipoSolvente': {
        element = document.getElementById('txtTemperatura');
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }
  }

  botonesmodificar() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
  }

  botonesmodificarlotes() {
    this.botonNuevoL = false;
    this.botonGuardarL = true;
    this.botonBorrarL = false;
    this.botonRegresarL = true;
  }


  busqueda(parametro) {

  }

  async mostrarDatos(CodOrdPro, flag) {
    this.spin = true//GSRF
    if (flag === 'boton') {
      this.bolordenactivada = false;
    }
    // DESPLIEGA LA CABECERA DE LA ORDEN DE PRODUCCION
    await this.limpiarDatos();
    document.getElementById('contorno').style.pointerEvents = 'all';
    // document.getElementById('contornodetalle').style.pointerEvents = 'all';
    this.aggrid.habilitarGrid();

    if (CodOrdPro === '' || CodOrdPro === undefined || CodOrdPro === null) {
      this.spin = false//GSRF
      return;
    }

    this.enabledtxtReferencia = false;
    const data = await this.encordproService.getEncordpro(CodOrdPro);
    for (const rs of data) {
      this.encordproService.encordpro[this.indicador] = rs;
    }

    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION);
    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION);
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA !== null) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA);
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA);
      this.chbtnIngreso = true;
    } else {
      this.chbtnIngreso = false;
    }
    //CDPJ
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE !== null) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE)
      this.chbtnVencLote = true;
    } else {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE = new Date();
      this.chbtnVencLote = false;
    }
    //CDPJ
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO !== null) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO);
      this.chbtnInicio = true;
    } else {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO = new Date();
      this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI = this.datePipe.transform(new Date(), 'HH:mm');
      this.chbtnInicio = false;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN !== null) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN = new Date(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN);
      this.chbtnTermino = true;
    } else {
      this.chbtnTermino = false;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_PROCEDIMIENTO !== null) {
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_PROCEDIMIENTO === '01') {
        this.selectProcedimiento = { codigo: '01', name: 'Producción' }
      }
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_PROCEDIMIENTO === '02') {
        this.selectProcedimiento = { codigo: '02', name: 'Envasado' }
      }
    } else {
      this.selectProcedimiento = { codigo: '', name: '' }
    }

    this.ingresaTiposProcesos();
    if (this.encordproService.encordpro[this.indicador].FOR_CODIGO !== null) {
      this.selectProceso = { codigo: this.encordproService.encordpro[this.indicador].FOR_CODIGO, name: this.encordproService.encordpro[this.indicador].FOR_CODIGO }
      this.formula = this.selectProceso.codigo;
    }

    // Tipo de Produccion
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOPROD !== null) {
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOPROD === "F") {
        this.encordproService.selectTipoProd[this.indicador] = { codigo: 'F', name: 'Formulación' };
        this.enabledcmbProceso === true;
      } else {
        this.encordproService.selectTipoProd[this.indicador] = { codigo: 'P', name: 'Proceso' };
        this.enabledcmbProceso === false;
      }
    }

    this.enabledcmbTipoProd === false;
    this.activarDesactivarTabsPorTipoProc();

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'I'
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'T'
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'A') {
      if (this.bolordenactivada === false) {
        document.getElementById('contorno').style.pointerEvents = 'none';
        this.aggrid.bloquearGrid();
        this.botonAnular = false;//CDPJ
      }
    }
    const dataest = await this.encordproService.getNombreEstado(this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO);
    for (const rs of dataest) {
      this.ESTADO = rs.REF_NOMBREC;
    }
    if (this.ESTADO === undefined || this.ESTADO === '' || this.ESTADO === null) {
      this.ESTADO = this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO;
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'La descripción para el código de estado ' + this.ESTADO + ' no existe'
      });
    }
    this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD =
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD).toFixed(this.encordproService.decimalesCantEnc);
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP =
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP).toFixed(this.confIniciales.getNumDecSist());
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND =
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND).toFixed(this.confIniciales.getNumDecSist());
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM =
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM).toFixed(this.confIniciales.getNumDecSist());
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO =
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO).toFixed(this.confIniciales.getNumDecSist());
    await this.modeloDetallelistarDatos(CodOrdPro);
    this.modeloCostosIndlistarDatos(CodOrdPro);

    this.modeloTecnicoslistarDatos(CodOrdPro);
    this.modeloMaquinarialistarDatos(CodOrdPro);
    this.modeloPedidolistarDatos(CodOrdPro);
    this.modeloHorashombrelistarDatos(CodOrdPro);

    this.listardetalleprofabri(CodOrdPro);
    this.listardetalleprocontrol(CodOrdPro);

    this.modeloDetalleVerificaSiEsParteFormulaAll();
    this.DespliegaNumeroAsiento(CodOrdPro);

    // Despliega Datos de la liquidación si fue realizada por procesos
    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'P') {
      this.modeloLiquidacionDesplegarDatosLiquidacion(CodOrdPro);
    }

    const str_LOTORDPRO = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LOTORDPRO').CFG_VALOR1;
    if (str_LOTORDPRO === "1") {
      this.desplegarLotesProd();
    }
    /*
        if (txtEstado.getText().equals("P")) {
          ActivarBoton("E");
        } else if (txtEstado.getText().equals("T")) {
          ActivarBoton("T");
        } else if (txtEstado.getText().equals("I")) {
          ActivarBoton("I");
        } else if (txtEstado.getText().equals("A")) {
          ActivarBoton("A");
        }  */
    this.spin = false//GSRF
  }

  async limpiarDatos() {
    this.encordproService.encordpro[this.indicador] = {};

    document.getElementById('contorno').style.pointerEvents = 'all';
    // document.getElementById('contornodetalle').style.pointerEvents = 'all';
    this.aggrid.habilitarGrid();

    this.botonAnular = true;//CDPJ ANTES FALSE AHORA TRUE

    this.encordproService.encordpro[this.indicador].ART_CODIGO = '';
    this.encordproService.encordpro[this.indicador].ART_NOMBRE = '';
    this.encordproService.encordpro[this.indicador].UNI_CODIGO = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_COMENTARIO = '';

    this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI = this.datePipe.transform(new Date(), 'HH:mm');
    this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN = this.datePipe.transform(new Date(), 'HH:mm');

    this.encordproService.encordpro[this.indicador].ENCPED_NUMERO = '';
    this.encordproService.encordpro[this.indicador].BOD_CODIGO = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO = 'P';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO = '';

    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION = new Date();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH = new Date();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO = new Date();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN = new Date();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE = new Date();

    this.chbtnIngreso = false;
    this.chbtnInicio = true;
    this.chbtnTermino = false;
    this.chbtnVencLote = false;
    this.selectProcedimiento = this.cmbProcedimiento[2];

    const dataest = await this.encordproService.getNombreEstado(this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO);
    for (const rs of dataest) {
      this.ESTADO = rs.REF_NOMBREC;
    }
    this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD = Number('1').toFixed(this.encordproService.decimalesCantEnc);

    this.encordproService.encordpro[this.indicador].BOD_CODIGO_ENT = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO = '';

    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA = new Date();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH = new Date();

    this.llenarCombos();
    /*if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') {
      this.enabledcmbProceso === true;
    } else {
      this.enabledcmbProceso === false;
    }*/

    this.encordproService.detordpro[this.indicador] = [];
    this.encordproService.trncostosindirectos[this.indicador] = [];
    this.encordproService.trnpedidos[this.indicador] = [];
    this.encordproService.trnliquidacion[this.indicador] = [];
    this.encordproService.trntecnicos[this.indicador] = [];
    this.encordproService.trnhorashombre[this.indicador] = [];
    this.encordproService.profabricacion[this.indicador] = []
    this.encordproService.procontrol[this.indicador] = []
    /*		
		// limpiar la tabla
		tablaCostosInd.cerrarEditorSinGrabar();
		modeloCostosInd.getlstCostosInd().clear();
		tablaCostosInd.redraw();
		// limpiar la tabla
		tablaTecnicos.cerrarEditorSinGrabar();
		modeloTecnicos.getlstTrnTecnicos().clear();
		tablaTecnicos.redraw();
		// limpiar la tabla
		tablaMaquinaria.cerrarEditorSinGrabar();
		modeloMaquinaria.getlstTrnMaquinaria().clear();
		tablaMaquinaria.redraw();
		// limpiar la tabla
		tablaPedidos.cerrarEditorSinGrabar();
		modeloPedido.getlstPedidos().clear();
		tablaPedidos.redraw();
		// limpiar la Liquidacion
		tablaLiquidacion.cerrarEditorSinGrabar();
		modeloLiquidacion.getlstProdTrnliquid().clear();
		tablaLiquidacion.redraw();
		*/
    this.encordproService.encordpro[this.indicador].ENCORDPRO_LOTE = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_OPERARIO = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_EQUIPO = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TEMPERATURA = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_SOLVENTE = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_MEZCLA = '';
    this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOSOLVENTE = '';

    this.cmbAsiento = [];
  }

  removerColores() {
  }

  async modeloDetallelistarDatos(strNumOrd) {
    this.removerColores();

    const FechaInicio = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
    const HoraInicia = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;

    const data = await this.encordproService.getDetordpro(FechaInicio, HoraInicia, strNumOrd);
    for (const rs of data) {
      rs.CLAVE = rs.DETORDPRO_LINEA;
      rs.DETORDPRO_CANTIDAD = Number(rs.DETORDPRO_CANTIDAD).toFixed(this.encordproService.decimalesCantidad);
      rs.DETORDPRO_COSTO = Number(rs.DETORDPRO_COSTO).toFixed(this.encordproService.decimalesCosto);
      rs.DETORDPRO_TOTAL = Number(rs.DETORDPRO_TOTAL).toFixed(this.encordproService.decimalesTotal);
      rs.EXISTENCIA = Number(rs.EXISTENCIA).toFixed(this.encordproService.decimalesCosto);
    }

    this.encordproService.detordpro[this.indicador] = data;
  }

  activarDesactivarTabsPorTipoProc() {
    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') {
      this.tabItemLiquidacion = '';
    } else {
      this.tabItemLiquidacion = 'Liquidación de la Orden de Producción';
    }
  }

  activarTabsDeacuerdoEmp() {
    const DATTECP = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DATTECP').CFG_VALOR1;
    const LOTEPRO = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LOTEPRO').CFG_VALOR1;
    if (DATTECP == 1) {
      this.tabItemAdicionales = 'Adicionales';
      this.tabItemTecnicos = 'Técnicos asignados';
      this.tabItemMaquinaria = 'Máquinaria asignada';
    } else {
      this.tabItemAdicionales = '';
      this.tabItemTecnicos = '';
      this.tabItemMaquinaria = '';
    }
    if (LOTEPRO === 1) {
      this.tabProfabri = 'Proceso Fabricación'
      this.tabprocontrol = 'Control Proceso';
    } else {
      this.tabProfabri = ''
      this.tabprocontrol = '';
    }

  }

  changetipoprod(event) {
    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') {
      this.enabledcmbProceso = true;
    } else {
      this.enabledcmbProceso = false;
    }
    this.activarDesactivarTabsPorTipoProc();
  }

  changeProceso() {
    this.formula = this.selectProceso.codigo;

    this.desplegarFormula();
  }

  async NuevaOrdenProduccion() {
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO === '') { // Nuevo registro
      // Verifica los permisos
      if (this.permisoOrdPro !== null) {
        if (this.permisoOrdPro.PERTODO === 0) {
          if (this.permisoOrdPro.PERINSERCION === 0) {
            this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.encordproService.usuario.identificacion
                + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
    }

    this.enabledcmbTipoProd = true;
    this.enabledtxtReferencia = true;

    this.obtenerNumOrden();
    this.limpiarDatos();
    this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA = '';
    this.USUARIO = this.encordproService.usuario.identificacion;
    this.FECHA = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.HORA = this.datePipe.transform(new Date(), 'HH:mm');

    const dbod = await this.encordproService.extraerCodigoBodega();
    for (const rs of dbod) {
      this.encordproService.encordpro[this.indicador].BOD_CODIGO = rs.REF_CODIGO === null ? '' : rs.REF_CODIGO;
    }
    this.botonesmodificar();
    document.getElementById('txtReferencia').focus();
  }

  async obtenerNumOrden() {
    let bolNum = false;
    let strNumActual = "";
    let strNemonico = "";
    let strSerie = "";

    if (this.str_NumOrdPro === 'G') { // numeración general (clase de doc)
    } else { // numeración por punto de venta      
      if (this.GRUPO === '') {
        this.busquedanumeracion();
      } else {
        strNumActual = await this.obtenerNumSiguiente();
        strSerie = this.globalSerie;
        strNemonico = this.globalNemonico;
        this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA = strNemonico + "" + strSerie + "" + strNumActual;
        this.enabledtxtReferencia = true;
      }
    }

    return bolNum;
  }

  busquedanumeracion() {
    this.opcionbusqueda = 'numeracion';
    this.types = [
      { label: 'Descripción', value: 'NUM_CLASE' },
      { label: 'Nemónico', value: 'NUM_NEMONICO' },
      { label: 'Serie', value: 'NUM_SERIE' },
      { label: 'Actual', value: 'NUM_SECACTUAL' }
    ];
    this.busquedacampos = ['', '', '', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAENUMERACION';
    this.where = '(NUM_DOCREF=\'ORP\') AND NUM_TIPO = \'C\'';

    this.busqService.busquedaSaciMaeNumeracion2().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  async manejarSeleccion(opcion) {
    if (this.opcionbusqueda === 'orden') {
      this.botonesInicio();
      this.mostrarDatos(opcion.ENCORDPRO_NUMERO, 'boton');
    }
    if (this.opcionbusqueda === 'numeracion') {
      let strNumActual = "";
      let strNemonico = "";
      let strSerie = "";
      if (opcion.NUM_SECACTUAL.length > 0) {
        strNumActual = opcion.NUM_SECACTUAL;
        this.GRUPO = opcion.NUM_CLASE;
        strNemonico = opcion.NUM_NEMONICO;
        this.globalNemonico = strNemonico;
        strSerie = opcion.NUM_SERIE;
        this.globalSerie = strSerie;
        this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA = strNemonico + "" + strSerie + "" + strNumActual;
        this.enabledtxtReferencia = true;
      } else {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe escoger una numeración'
        });
      }
    }
    if (this.opcionbusqueda === 'articulo') {
      this.encordproService.encordpro[this.indicador].ART_CODIGO = opcion.ART_CODIGO;
      this.encordproService.encordpro[this.indicador].ART_NOMBRE = opcion.ART_NOMBRE;
      const data: any = await this.encordproService.completararticulo(opcion.ART_CODIGO);
      for (const rs of data) {
        this.encordproService.encordpro[this.indicador].UNI_CODIGO = rs.ART_UNIDADCOSTEO;
        this.encordproService.encordpro[this.indicador].ENCORDPRO_COMENTARIO = rs.ART_OBSERVACION;
      }
      this.ingresaTiposProcesos();
      document.getElementById('txtCantidad').focus();
    }
    if (this.opcionbusqueda === 'pedido') {
      this.encordproService.encordpro[this.indicador].ENCPED_NUMERO = opcion.ENCPED_NUMERO;
      this.encordproService.encordpro[this.indicador].CLIENTEPEDIDO = opcion.CLI_NOMBRE;
      document.getElementById('txtOpe').focus();
    }
    if (this.opcionbusqueda === 'bodegamp') {
      this.encordproService.encordpro[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
      document.getElementById('txtLote').focus();
    }
    if (this.opcionbusqueda === 'bodegapt') {
      this.encordproService.encordpro[this.indicador].BOD_CODIGO_ENT = opcion.BOD_CODIGO;
      document.getElementById('txtBodegaMP').focus();
    }
    this.displayDialogBusquedaFast = false;
  }

  async modeloDetalleVerificaSiEsParteFormulaAll() {
    for (const item of this.encordproService.detordpro[this.indicador]) {
      let Sw = 0;
      let Codigo = item.ART_CODIGO; // Código del artículo      
      const data: any = await this.encordproService.verificarparteformula(this.encordproService.encordpro[this.indicador].ART_CODIGO, Codigo);
      if (data !== null) {
        for (const rs of data) {
          if (rs.CONTFORMULA !== 0) {
            Sw = 1; // Si el artículo pertenece a la fórmula
          }
          if (Sw == 0) { // Si no pertenece el artículo a la fórmula          
            item.ARTPARFOR = 'X';
            item.COLORFORMULACION = 'Y';
          } else {
            item.ARTPARFOR = '';
            item.COLORFORMULACION = '';
          }
        }
      }
    }
    this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);
  }

  async guardarRegistro() {
    // TODO Auto-generated method stub
    this.spin = true;
    console.log(this.index)
    if (this.index == 0) {
      await this.GuardarOrdenProduccion();
    }
    if (this.index == 1) {
      await this.GuardarCostosIndirectos();
    }
    if (this.index == 2) {
      await this.GuardarHorasHombre();
    }
    if (this.index == 3) {
      await this.GuardarPedido();
    }
    if (this.index == 5) {
      await this.GuardarDatosAdicionales();
    }
    if (this.index == 6) {
      await this.GuardarTecnicos();
    }
    if (this.index == 7) {
      await this.GuardarMaquinaria();
    }
    if (this.index == 8) {
     
      await this.GuardarProfabri();
    }
    if (this.index == 9) {
      await this.GuardarProcontrol();
    }
    this.spin = false;
  }
  //CDPJ
  async GuardarProcontrol() {
    try {
      if (this.validadetprocontrol() === true) {
        for (const item of this.encordproService.procontrol[this.indicador]) {
          await this.encordproService.insertarprocontrol(item);
          if(item.PROCONT_LINEA === 0){
            this.auditoriagral.registrarAuditoria('PROD_TRNPROCONT',
            this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + item.PROCONT_PARAMETRO, 'I', '', '01', '', '', '', '').subscribe();
          }else {
            this.auditoriagral.registrarAuditoria('PROD_TRNPROCONT',
              this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + item.PROCONT_PARAMETRO, 'A', '', '01', '', '', '', '').subscribe();
          }
        }
        await this.listardetalleprocontrol(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
        this.messageService.add({
          key: 'encordpro',
          severity: 'success',
          summary: 'Información',
          detail: 'Registros guardados con éxito'
        });
        this.botonesInicio();
      }
    } catch (error) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrió un error al guardar'
      });
      this.spin=false
    }
  }
   async listardetalleprocontrol(CodOrdPro){
    try {
      const data: any = await this.encordproService.listardatosprocontrol(CodOrdPro);
      if (data !== null && data !== undefined) {
        for (const rs of data) {
          rs.CLAVE = rs.PROCONT_LINEA
        }
        this.encordproService.procontrol[this.indicador] = data
      }
    } catch (error) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrió un error al consultar los datos'
      });
      this.spin=false
    }
   }
  validadetprocontrol(){
    let bool = true
    for (const item of this.encordproService.procontrol[this.indicador]) {
      if (item.PROCONT_PARAMETRO === null || item.PROCONT_PARAMETRO === undefined || item.PROCONT_PARAMETRO === '') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese el número de la etapa'
        });
        this.spin = false
        bool = false
      }
    }
    return bool;
  }
  async GuardarProfabri() {
    try {
      if (this.validadetprofabri() === true) {
        
        for (const item of this.encordproService.profabricacion[this.indicador]) {
          await this.encordproService.insertarprofabri(item);
          if (item.PROFAB_LINEA === 0) {
            this.auditoriagral.registrarAuditoria('PROD_TRNPROFAB',
              this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + item.PROFAB_ETAPA, 'I', '', '01', '', '', '', '').subscribe();
          } else {
            this.auditoriagral.registrarAuditoria('PROD_TRNPROFAB',
              this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + item.PROFAB_ETAPA, 'A', '', '01', '', '', '', '').subscribe();
          }
        }
        await this.listardetalleprofabri(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
        this.messageService.add({
          key: 'encordpro',
          severity: 'success',
          summary: 'Información',
          detail: 'Registros guardados con éxito'
        });
        this.botonesInicio();
      }

    } catch (error) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Error al insertar el detalle'
      });
      this.spin = false;
      return;
      
    }
  }
  async listardetalleprofabri(CodOrdPro) {
    try {
      const data: any = await this.encordproService.listardatosprofabri(CodOrdPro);
      if (data !== null && data !== undefined) {
        for (const rs of data) {
          rs.CLAVE = rs.PROFAB_LINEA
          rs.ART_NOMBRE_MP=rs.ART_NOMBRE
        }
        this.encordproService.profabricacion[this.indicador] = data
      }
    } catch (error) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrió un error al consultar los datos'
      });
    }
  }
  validadetprofabri() {
    let bool = true
    
    for (const item of this.encordproService.profabricacion[this.indicador]) {
      if (item.PROFAB_ETAPA === null || item.PROFAB_ETAPA === undefined || item.PROFAB_ETAPA === '') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese el número de la etapa'
        });
        this.spin = false
        bool = false
      }
      if (this.reghora.test(item.PROFAB_HORA_INICIO) === false) {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo hora inicio debe estar el formato HH:mm'
        });
        this.spin = false
        bool = false
      }
      if (this.reghora.test(item.PROFAB_HORA_FIN) === false) {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo hora fin debe estar el formato HH:mm'
        });
        this.spin = false
        bool = false
      }
    }
    
    return bool;
  }
  //CDPJ
  async GuardarOrdenProduccion() {

    let Codigo;
    let Cantidad = 0.0;
    let CtrlEliminaDet = 0;
    let cmdguardarTag = "";
    // Si el tipo de producción es por formulación entonces guarda en el kardex

    if (this.encordproService.selectTipoProd[this.indicador].codigo === "F") {
      if (this.encordproService.detordpro[this.indicador].length == 0) {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese los artículos en el detalle de la Orden de Producción'
        });
        this.spin = false;
        return;
      }
    }

    const dia = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getDate();
    const mes = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getMonth();
    const anio = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getFullYear();

    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'info',
        summary: 'Información',
        detail: 'Error, fecha debe de estar dentro del período contable'
      });
      this.spin = false;
      return;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'A') {
      this.spin = false;
      return;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO === '') {
      cmdguardarTag = "N"; // nuevo
    } else {
      cmdguardarTag = "M"; // modificación
    }
    const faltacampos = await this.FaltaCampos();
    const faltacamposd = await this.FaltaCamposD();
    if (await this.verificarExistenciasArticulosOP() === false) {
      return;
    }

    CtrlEliminaDet = 0;


    if (faltacampos === true) { // Valida que los datos de la cabecera esten ingresados
      if (faltacamposd === true) { // 'Valida los datos ingresados en el detalle
        if (cmdguardarTag === "N") { // Nuevo registro
          // Verifica los permisos
          if (this.permisoOrdPro !== null) {
            if (this.permisoOrdPro.PERTODO === 0) {
              if (this.permisoOrdPro.PERINSERCION === 0) {
                this.messageService.add({
                  key: 'encordpro',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'El usuario '
                    + this.encordproService.usuario.identificacion
                    + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
                });
                return;
              }
            }
          }
        } else { // Modifica registro
          // Verifica los permisos
          if (this.permisoOrdPro !== null) {
            if (this.permisoOrdPro.PERTODO === 0) {
              if (this.permisoOrdPro.PERACTUALIZACION === 0) {
                this.messageService.add({
                  key: 'encordpro',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'El usuario '
                    + this.encordproService.usuario.identificacion
                    + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
                });
                return;
              }
            }
          }
        }
      } else {
        return;
      }
    } else {
      return;
    }

    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') {
      this.encordproService.SumarTotal();
    } else {
      this.encordproService.SumarTotalXProceso();
    }

    // -------------------------------------------------
    // GRABA LA CABECERA DE LA ORDEN DE PRODUCCION
    // -------------------------------------------------

    const item: ProdEncordpro = {}

    item.ENCORDPRO_NUMERO = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;
    item.COM_CODIGO = '01';
    item.ART_CODIGO = this.encordproService.encordpro[this.indicador].ART_CODIGO;
    item.UNI_CODIGO = this.encordproService.encordpro[this.indicador].UNI_CODIGO;
    item.BOD_CODIGO = this.encordproService.encordpro[this.indicador].BOD_CODIGO;
    item.ENCORDPRO_TIPOPROC = this.encordproService.selectTipoProd[this.indicador].codigo === '' ? 'F' : this.encordproService.selectTipoProd[this.indicador].codigo;
    item.ENCORDPRO_ESTADO = this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO;
    const fechaemi = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
    const fechaemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');
    item.ENCORDPRO_FECHAEMISION = fechaemi + ' ' + fechaemih;
    if (this.chbtnInicio === true) {
      item.ENCORDPRO_FECHAINICIO = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI.toString().includes('-')) {
        item.ENCORDPRO_HORAINI = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI, 'HH:mm');
      } else {
        item.ENCORDPRO_HORAINI = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
      }
    } else {
      item.ENCORDPRO_FECHAINICIO = '';
      item.ENCORDPRO_HORAINI = '';
    }
    item.ENCORDPRO_CANTIDAD = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD);

    if (this.chbtnTermino === true) {
      item.ENCORDPRO_FECHAFIN = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
        item.ENCORDPRO_HORAFIN = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
      } else {
        item.ENCORDPRO_HORAFIN = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
      }
    } else {
      item.ENCORDPRO_FECHAFIN = '';
      item.ENCORDPRO_HORAFIN = '';
    }
    item.ENCORDPRO_COSTOTOTAL = '0';
    item.ENCORDPRO_COSTOUNITARIO = '0';
    item.ENCORDPRO_COMENTARIO = this.encordproService.encordpro[this.indicador].ENCORDPRO_COMENTARIO;
    item.ENCORDPRO_TOTALCOSTOMP = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP);
    item.ENCORDPRO_TOTALCOSTOIND = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND);
    item.ENCORDPRO_TOTALCOSTOPRM = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM);
    item.ENCORDPRO_TOTALPRODUCIDO = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO);
    item.ENCORDPRO_REFERENCIA = this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA;
    item.BOD_CODIGO_ENT = this.encordproService.encordpro[this.indicador].BOD_CODIGO_ENT;
    if (this.chbtnIngreso === true) {
      const fechaent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'dd/MM/yyyy');
      const fechaenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');
      item.ENCORDPRO_FECHAENTRADA = fechaent + ' ' + fechaenth;
    } else {
      item.ENCORDPRO_FECHAENTRADA = '';
    }
    // item.FOR_CODIGO = this.formula;
    //CDPJ
    item.FOR_CODIGO = this.formula;
    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'P' && (this.formula === null || this.formula === '' || this.formula === undefined)) {
      item.FOR_CODIGO = '';
    }

    //CDPJ
    item.ENCPED_NUMERO = this.encordproService.encordpro[this.indicador].ENCPED_NUMERO;
    item.ENCORDPRO_TIPOPROD = this.encordproService.selectTipoProd[this.indicador].codigo;
    item.ENCORDPRO_LOTE = this.encordproService.encordpro[this.indicador].ENCORDPRO_LOTE;
    item.ENCORDPRO_OPERARIO = this.encordproService.encordpro[this.indicador].ENCORDPRO_OPERARIO;
    item.ENCORDPRO_EQUIPO = this.encordproService.encordpro[this.indicador].ENCORDPRO_EQUIPO;
    item.ENCORDPRO_PROCEDIMIENTO = this.leeTipoProceso();
    if (this.chbtnVencLote === true) {
      item.ENCORDPRO_VENCIMIENTOLOTE = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE, 'dd/MM/yyyy');
    } else {
      item.ENCORDPRO_VENCIMIENTOLOTE = '';
    }
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORASHOMBRE === ''
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_HORASHOMBRE === null
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_HORASHOMBRE === undefined) {
      item.ENCORDPRO_HORASHOMBRE = '';
    } else {
      item.ENCORDPRO_HORASHOMBRE = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORASHOMBRE);
    }

    const data: any = await this.encordproService.guardarencabezado(item).catch(e => {
      this.spin = false;
    });

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO === '') { // Inserta un nuevo registro
      for (const rs of data) {
        if (this.str_NumOrdPro === 'C') {
          this.ActualizarNumero(this.GRUPO, 'ORP', this.globalNemonico, this.globalSerie);
        }
        this.enabledtxtReferencia = false;
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO = rs[':B3'];

        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA, 'I',
        '', '01', '', '', '', '').subscribe();
        for(const data of this.etiquetasaudi){
          this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO', this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO+'/'+
          data.name+'/'+ (item[data.codigo] === null || item[data.codigo] === undefined?'':item[data.codigo])
          , 'I',
          '', '01', '', '', '', '').subscribe();
        }

        //GuardarCostosIndirectos();
        //ActualizaFechaCostoEstandar();
        //enabledcmbTipoProd = false;
      }
    } else {
      this.enabledtxtReferencia = false;
      this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA, 'A',
        '', '01', '', '', '', '').subscribe();
        for(const data of this.etiquetasaudi){
          this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO', this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO+'/'+
          data.name+'/'+ (item[data.codigo] === null || item[data.codigo] === undefined?'':item[data.codigo])
          , 'A',
          '', '01', '', '', '', '').subscribe();
        }
      //GuardarCostosIndirectos();
      //ActualizaFechaCostoEstandar();
    }


    // -------------------------------------------------
    // GRABA EL DETALLE
    // -------------------------------------------------

    for (const det of this.encordproService.detordpro[this.indicador]) {
      Codigo = det.ART_CODIGO;
      Cantidad = det.DETORDPRO_CANTIDAD;

      if (Codigo !== '') {
        const itemdet: ProdDetordpro = {};
        itemdet.ENCORDPRO_NUMERO = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;
        itemdet.COM_CODIGO = '01';
        itemdet.DETORDPRO_LINEA = det.DETORDPRO_LINEA;
        itemdet.ART_CODIGO = det.ART_CODIGO;
        itemdet.UNI_CODIGO = det.UNI_CODIGO;
        itemdet.BOD_CODIGO = det.BOD_CODIGO;
        itemdet.DETORDPRO_DESCRIPCION = det.DETORDPRO_DESCRIPCION;
        itemdet.DETORDPRO_CANTIDAD = Number(det.DETORDPRO_CANTIDAD);
        itemdet.DETORDPRO_COSTO = Number(det.DETORDPRO_COSTO);
        itemdet.DETORDPRO_TOTAL = Number(det.DETORDPRO_TOTAL);
        itemdet.DETORDPRO_LOTE = det.DETORDPRO_LOTE;
        itemdet.DETORDPRO_PPA = det.DETORDPRO_PPA;
        itemdet.DETORDPRO_CPPA = det.DETORDPRO_CPPA;
        itemdet.DETORDPRO_RENDIMIENTO = 0.0;
        const datadet: any = await this.encordproService.guardardetalle(itemdet).catch(e => {
          this.spin = false;
        })

        for (const rsdet of datadet) {
          if (det.DETORDPRO_LINEA === 0) {
            det.DETORDPRO_LINEA = rsdet[':B1'];; // retorna el nuevo numero de línea
            this.auditoriagral.registrarAuditoria('PROD_DETORDPRO',
              this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + det.ART_CODIGO + '/', 'I',
              '', '01', '', '', '', '').subscribe();
          } else {
            this.auditoriagral.registrarAuditoria('PROD_DETORDPRO',
              this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + det.ART_CODIGO + '/', 'A',
              '', '01', '', '', '', '').subscribe();
          }
        }
      }
    }
    this.messageService.add({
      key: 'encordpro',
      severity: 'success',
      summary: 'Información',
      detail: 'Orden de producción guardada'
    });


    // Si el tipo de producción es por formulación entonces guarda en el kardex
    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') {
      this.GenerarKardex();
    }

    console.log('this.GenerarAsientoContable();');
    await this.GenerarAsientoContable();

    if (this.chbtnTermino === true) {
      await this.ActualizaEstadoFactura("T", this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      // ActivarBoton("E");
    } else {
      await this.ActualizaEstadoFactura("P", this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      // ActivarBoton("G");
    }

    this.cancelar('auto');
  }

  async GuardarCostosIndirectos() {

    for (const det of this.encordproService.trncostosindirectos[this.indicador]) {
      if (det.CODIGO_AUXILIAR === "") { // Inserta un nuevo registro
        const item = det;
        item.TRNCOSTOSIND_COSTO = Number(det.TRNCOSTOSIND_COSTO);
        if (item.CHECK === true) {
          item.TRNCOSTOSIND_INICIAL = "S";
        } else {
          item.TRNCOSTOSIND_INICIAL = "";
        }
        await this.encordproService.guardarcostoindirecto(item);
        item.CODIGO_AUXILIAR = "X";
      } else { // Actualiza
        const item = det;
        item.TRNCOSTOSIND_COSTO = Number(det.TRNCOSTOSIND_COSTO);
        if (item.CHECK === true) {
          item.TRNCOSTOSIND_INICIAL = "S";
        } else {
          item.TRNCOSTOSIND_INICIAL = "";
        }
        this.encordproService.actualizarcostoindirecto(item);
      }
    }

    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') { // Formulación
      this.encordproService.SumarTotal();
    } else { // Por proceso
      this.encordproService.SumarTotalXProceso();
    }

    this.ActualizarTotales(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);

    if (this.selectAsiento.codigo === "") {
      await this.GenerarAsientoContable();
    }
    this.botonesInicio();
  }

  async GuardarDatosAdicionales() {
    const item: ProdEncordpro = {};
    item.ENCORDPRO_NUMERO = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;
    item.COM_CODIGO = '01';
    item.ENCORDPRO_TEMPERATURA = this.encordproService.encordpro[this.indicador].ENCORDPRO_TEMPERATURA;
    item.ENCORDPRO_SOLVENTE = this.encordproService.encordpro[this.indicador].ENCORDPRO_SOLVENTE;
    item.ENCORDPRO_MEZCLA = this.encordproService.encordpro[this.indicador].ENCORDPRO_MEZCLA;
    item.ENCORDPRO_TIPOSOLVENTE = this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOSOLVENTE;
    await this.encordproService.guardardatosadicionales(item);
    this.botonesInicio();
  }

  GuardarTecnicos() {
    for (const item of this.encordproService.trntecnicos[this.indicador]) {
      if (item.CODIGO_AUXILIAR === "") {
        if (item.TRNTEC_CODIGO !== "") {
          this.encordproService.guardartecnicos(item);
          item.CODIGO_AUXILIAR === "X";
        }
      }
      this.botonesInicio();
    }
  }

  GuardarMaquinaria() {
    for (const item of this.encordproService.trnmaquinaria[this.indicador]) {
      if (item.CODIGO_AUXILIAR === "") {
        if (item.TRNMAQ_CODIGO !== "") {
          this.encordproService.guardarmaquinaria(item);
          item.CODIGO_AUXILIAR === "X";
        }
      }
      this.botonesInicio();
    }
  }

  GuardarPedido() {
    for (const det of this.encordproService.trnpedidos[this.indicador]) {
      const item: ProdTrnpedidos = det;
      if (item.ENCPED_NUMERO !== "") {
        if (item.CODIGO_AUXILIAR === "") {
          item.DETPED_CANTIDAD = Number(det.DETPED_CANTIDAD);
          this.encordproService.guardarpedido(item);
          item.CODIGO_AUXILIAR = 'X';
        } else {
          item.DETPED_CANTIDAD = Number(det.DETPED_CANTIDAD);
          this.encordproService.actualizarpedido(item);
        }
      }
      this.botonesInicio();
    }
  }

  async verificarExistenciasArticulosOP() {
    let Codigo;
    let Cantidad;
    let CodigoBodega;
    let cont = 0;
    let fila = 1;
    for (const item of this.encordproService.detordpro[this.indicador]) {
      Codigo = item.ART_CODIGO;
      if (Codigo !== '') { // Código del artículo
        Cantidad = item.DETORDPRO_CANTIDAD;
        CodigoBodega = item.BOD_CODIGO;
        if (await this.verificarExistenciaArticulos(Cantidad, Codigo, CodigoBodega, "VS", fila - 1) === false) {
          item.COLOREXISTENCIA = 'Y';
          cont++;
        }
      }
      fila++;
    }
    this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);
    if (cont > 0) {
      this.spin = false//CDPJ
      return false;
    }
    return true;
  }

  async verificarExistenciaArticulos(Cantidad, CodArticulo, CodigoBodega, Opcion, Fila) {
    let Existencia = 0.0;
    let CantidadDisp = 0.0;
    let ExistenciaArt = 0.0, CantidadArtDetFac = 0.0;
    let Unidad = "";
    let boolState = true;

    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'P') {
      return boolState;
    }
    Unidad = this.encordproService.detordpro[this.indicador][Fila].UNI_CODIGO;
    Cantidad = await this.cantidadEnUnidadCosteo(Cantidad, CodArticulo, Unidad);
    // Existencia del artículo en unidad de costeo
    ExistenciaArt = await this.funExistenciaArtSinIncRefArt(CodArticulo, CodigoBodega,
      this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA);
    // Cantidad de artículos existentes en la orden de producción actual
    CantidadArtDetFac = await this.funcCantArtDetFacEnUniCosteo(CodArticulo);
    // Cantidad disponible
    Existencia = ExistenciaArt - CantidadArtDetFac;

    if (Existencia >= 0) {
      if ((ExistenciaArt - Cantidad) < 0) {
        CantidadDisp = Existencia;
        if (Opcion == "VS") { // VSPREAD
          // this.encordproService.detordpro[this.indicador][Fila].DETORDPRO_CANTIDAD = CantidadDisp.toFixed(this.encordproService.decimalesCantidad);
          const VISCANFOR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VISCANFOR').CFG_VALOR1;
          if (VISCANFOR !== 1) {
            this.encordproService.detordpro[this.indicador][Fila].DETORDPRO_CANTIDAD = Number(CantidadDisp).toFixed(this.encordproService.decimalesCantidad);
          }
        }
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'No hay las existencias necesarias.'
        });
        console.log('if', 'CodArticulo', CodArticulo, 'ExistenciaArt', ExistenciaArt, 'CantidadArtDetFac', CantidadArtDetFac, 'Existencia', Existencia);
        return false;
      }
    } else {
      if (Opcion == "VS") { // VSPREAD
        const VISCANFOR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VISCANFOR').CFG_VALOR1;
        if (VISCANFOR === 1) {
          this.encordproService.detordpro[this.indicador][Fila].DETORDPRO_CANTIDAD = Number(Cantidad).toFixed(this.encordproService.decimalesCantidad);
        } else {
          this.encordproService.detordpro[this.indicador][Fila].DETORDPRO_CANTIDAD = Number('0').toFixed(this.encordproService.decimalesCantidad);
        }
      }
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'No hay las existencias necesarias.'
      });
      console.log('else', 'CodArticulo', CodArticulo, 'ExistenciaArt', ExistenciaArt, 'CantidadArtDetFac', CantidadArtDetFac, 'Existencia', Existencia);
      return false;
    }
    return true;
  }

  async cantidadEnUnidadCosteo(Cantidad, CodArticulo, Uni_codigo) {

    let str_artunidadcosteo = "";
    let Existencia = 0.0;

    const data: any = await this.encordproService.getunidadcosteo(CodArticulo);

    for (const rs of data) {
      if (rs.ART_UNIDADCOSTEO !== null) {
        str_artunidadcosteo = rs.ART_UNIDADCOSTEO;
      }
    }
    // Transforma la existencia en unidades a las que se solicita
    if (Uni_codigo !== str_artunidadcosteo) {
      const data2: any = await this.encordproService.transformaunidad(Uni_codigo, str_artunidadcosteo, Cantidad);
      for (const rs of data2) {
        Existencia = Number(rs.EXISTENCIA);
      }
    } else {
      Existencia = Cantidad;
    }

    return Existencia;

  }

  async FaltaCampos() {
    const dia = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getDate();
    const mes = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getMonth();
    const anio = this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION.getFullYear();
    const rsdata: any = await this.encordproService.encontrarArt(this.encordproService.encordpro[this.indicador].ART_CODIGO);
    const rsdatacnta: any = await this.encordproService.encontrarCntaArt(this.encordproService.encordpro[this.indicador].ART_CODIGO);

    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, fecha debe de estar dentro del período contable'
      });
      this.spin = false//CDPJ
      return false;
    }

    let strCodigo = 0;
    let strcntart = 0;
    if (rsdata !== null) {
      for (const rs of rsdata) {
        strCodigo = rs.NUMERO;
      }
    }
    if (rsdatacnta !== null) {
      for (const rs of rsdatacnta) {
        strcntart = rs.NUMERO;
      }
    }
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA === '') { // Número de comprobante
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese el número del comprobante'
      });
      this.spin = false//CDPJ
      return false;
    }

    if (this.encordproService.encordpro[this.indicador].ART_CODIGO === '') { // Codigo del artículo
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese el artículo'
      });
      document.getElementById('txtArticulo').focus();
      this.spin = false//CDPJ
      return false;
    }

    if (this.encordproService.encordpro[this.indicador].UNI_CODIGO === '') { // Unidad
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese la Unidad del artículo'
      });
      document.getElementById('txtUnidad').focus();
      this.spin = false//CDPJ
      return false;
    }

    if (this.encordproService.encordpro[this.indicador].BOD_CODIGO === '') { // Bodega
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese la Bodega'
      });
      document.getElementById('txtBodegaMP').focus();
      this.spin = false//CDPJ
      return false;
    }

    if (this.encordproService.encordpro[this.indicador].BOD_CODIGO_ENT === '') { // Bodega
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese la Bodega en donde va a ingresar el producto terminado'
      });
      document.getElementById('txtBodegaPT').focus();
      this.spin = false//CDPJ
      return false;
    }
    if (strCodigo === 0) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'El artículo '
          + this.encordproService.encordpro[this.indicador].ART_CODIGO
          + ', no existe.'
      });
      this.spin = false;
      return false;
    }
    if (strcntart === 0) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'warn',
        summary: 'Información',
        detail: 'El artículo '
          + this.encordproService.encordpro[this.indicador].ART_CODIGO
          + ', no tiene la cuenta para ordenes de producción registrada, revise la ficha del arítuclo.'
      });
      this.spin = false;
      return false;
    }
    //CDPJ
    if (this.LOTEPRO === 1 && this.selectProcedimiento.codigo === '') {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese el tipo de procedimiento'
      });
      this.spin = false;
      return false;
    }
    if (this.LOTEPRO === 1 && (this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE === ''
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE === null
      || this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE === undefined
      || this.chbtnVencLote === false
    ) && this.selectProcedimiento.codigo === '01') {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese la fecha de vencimiento del lote'
      });
      this.spin = false;
      return false;
    }
    //CDPJ
    if (this.chbtnInicio === true) {
      let tmpFechaIni = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'yyyy-MM-dd');
      let tmpHoraIni = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI.toString().includes('-')) {
        tmpHoraIni = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI, 'HH:mm');
      }

      const dateemi = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'yyyy-MM-dd');
      const dateemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');

      const FechaEmi = new Date(dateemi + ' ' + dateemih);
      const FechaIni = new Date(tmpFechaIni + 'T' + tmpHoraIni + ':00');

      if (FechaEmi > FechaIni) {
        console.log(FechaEmi);
        console.log('FechaEmi mayor > FechaIni');
        console.log(FechaIni);
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'La Fecha en que Inicia no puede ser MENOR a la Fecha de Emisión'
        });
        this.spin = false//CDPJ
        return false;
      }
    }

    if (this.chbtnInicio === true && this.chbtnTermino === true) {
      let tmpFechaIni = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'yyyy-MM-dd');
      let tmpFechaFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'yyyy-MM-dd');
      let tmpHoraIni = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
      let tmpHoraFin = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI.toString().includes('-')) {
        tmpHoraIni = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI, 'HH:mm');
      }
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
        tmpHoraFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
      }

      const FechaIni = new Date(tmpFechaIni + 'T' + tmpHoraIni + ':00');
      const FechaFin = new Date(tmpFechaFin + 'T' + tmpHoraFin + ':00');

      if (FechaIni > FechaFin) {
        console.log(FechaIni);
        console.log('FechaIni mayor > FechaFin');
        console.log(FechaFin);
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'La Fecha en que Termina no puede ser MENOR a la Fecha en que Inicia'
        });
        this.spin = false//CDPJ
        return false;
      }
    }

    if (this.chbtnIngreso === true && this.chbtnTermino === true) {

      let tmpFechaFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'yyyy-MM-dd');
      let tmpHoraFin = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
        tmpHoraFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
      }

      const dateent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'yyyy-MM-dd');
      const dateenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');

      const FechaFin = new Date(tmpFechaFin + 'T' + tmpHoraFin + ':00');
      const FechaEnt = new Date(dateent + ' ' + dateenth);

      if (FechaFin > FechaEnt) {
        console.log(FechaFin);
        console.log('FechaFin mayor > FechaEnt');
        console.log(FechaEnt);
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'La Fecha en que Ingresa el producto no puede ser MENOR a la Fecha en que Termina'
        });
        this.spin = false//CDPJ
        return false;
      }
    }
    return true;
  }

  async FaltaCamposD() {

    // Valida los campos ingresados en el detalle
    for (const item of this.encordproService.detordpro[this.indicador]) {
      const data: any = await this.encordproService.verificarexistearticulo(item.ART_CODIGO).catch(e => {
        this.spin = false;
        return;
      });
      const rsdatacnta: any = await this.encordproService.encontrarCntaArt(item.ART_CODIGO).catch(e => {
        this.spin = false;
        return;
      });
      let strcntart = 0;
      if (rsdatacnta !== null) {
        for (const rs of rsdatacnta) {
          strcntart = rs.NUMERO;
        }
      }
      if (item.ART_CODIGO !== '') {

        if (item.BOD_CODIGO === "") { // BODEGA
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'Por Favor Ingrese La Bodega.'
          });
          this.spin = false//CDPJ
          return false;
        }
        if (strcntart === 0) {
          this.messageService.add({
            key: 'encordpro',
            severity: 'warn',
            summary: 'Información',
            detail: 'El artículo '
              + item.ART_CODIGO
              + ', no tiene la cuenta para ordenes de producción registrada, revise la ficha del arítuclo.'
          });
          this.spin = false;
          return false;
        }

        // CODIGO DEL ARTICULO
        if (data === null) {
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El artículo ' + item.ART_CODIGO + ' no existe'
          });
          this.spin = false//CDPJ
          return false;
        } else {
          for (const rs of data) {
            if (rs.ART_CODIGO === '') {
              this.messageService.add({
                key: 'encordpro',
                severity: 'error',
                summary: 'Información',
                detail: 'El artículo ' + item.ART_CODIGO + ' no existe'
              });
              this.spin = false//CDPJ
              return false;
            }
          }
        }

        // UNIDAD DE MATERIA PRIMA
        if (item.UNI_CODIGO === "") { // BODEGA
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'Por Favor Ingrese La Unidad'
          });
          this.spin = false//CDPJ
          return false;
        }

        if (Number(item.DETORDPRO_CANTIDAD) === 0) { // BODEGA
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El artículo ' + item.ART_CODIGO + ' tiene cantidad 0.00'
          });
          this.spin = false//CDPJ
          return false;
        }
      } else {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese el artículo'
        });
        this.spin = false//CDPJ
        return false;
      }
    }
    return true;
  }


  leeTipoProceso(): string {
    let strtipProd = "";
    if (this.selectProcedimiento.codigo === '')
      strtipProd = "";
    if (this.selectProcedimiento.codigo === "01")
      strtipProd = "01";
    if (this.selectProcedimiento.codigo === "02")
      strtipProd = "02";
    return strtipProd;
  }

  ActualizarNumero(Clase, DocRef, Nemonico, Serie) {
    this.encordproService.actualizarnumeracion(Clase, DocRef, Nemonico, Serie);
  }

  desplegarFormula() {
    if (this.encordproService.detordpro[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro que desea desplegar nuevamente la formulación del producto',
        header: 'Información',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        accept: () => {
          this.confirmationService.close();
          this.encordproService.eliminarTodoDetalle(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
          this.desplegarFormulaPaso();
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else {
      this.desplegarFormulaPaso();
    }
  }

  desplegarFormulaPaso() {
    if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F'
      || this.encordproService.selectTipoProd[this.indicador].codigo === '') { // Si es por formulación
      this.desplegaFormulaDelArticulo(this.encordproService.encordpro[this.indicador].ART_CODIGO,
        this.encordproService.encordpro[this.indicador].BOD_CODIGO, this.formula);
    } else {
      document.getElementById('txtObservacion').focus();
    }
  }

  async desplegaFormulaDelArticulo(CodArticulo, CodigoBodega, CodigoFormula) {
    this.encordproService.detordpro[this.indicador] = [];
    let dbl_CantidadArt = 0.0;
    let dbl_CantidadTotalArt = 0.0;
    let dbl_cantidad_prod = 0.0;
    let dbl_cantidad_necesaria = 0.0;

    // Despliega la fórmula
    const strFechaInicio = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
    let strHora = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI.toString().includes('-')) {
      strHora = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI, 'HH:mm');
    }

    const data: any = await this.encordproService.getformulacion(strFechaInicio, strHora, CodigoBodega, CodArticulo, CodigoFormula);
    dbl_cantidad_prod = Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD);
    console.log('dbl_cantidad_prod', dbl_cantidad_prod);
    let clave = 0;
    if (data !== null) {
      this.spin = true;
      for (const rs of data) {
        clave++;
        dbl_CantidadTotalArt = 0;
        dbl_CantidadArt = 0;
        dbl_cantidad_necesaria = 0;

        const item: ProdDetordpro = {};

        item.DETORDPRO_LINEA = 0;
        item.CLAVE = clave + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        item.BOD_CODIGO = CodigoBodega;
        item.ART_CODIGO = rs.ART_CODIGOP;
        item.DETORDPRO_DESCRIPCION = rs.ART_NOMBRE;
        item.UNI_CODIGO = rs.UNI_CODIGO;

        dbl_CantidadTotalArt = rs.FOR_CANTTOTAL; // cantidad total que se van a producir con la formulación
        dbl_CantidadArt = rs.FOR_CANTIDAD; // Cantidad de productos necesarios para producir      

        if (dbl_CantidadTotalArt > 0) {
          dbl_cantidad_necesaria = (dbl_cantidad_prod * dbl_CantidadArt)
            / dbl_CantidadTotalArt;
        } else {
          dbl_cantidad_necesaria = 0;
        }

        item.DETORDPRO_CANTIDAD = dbl_cantidad_necesaria;
        item.DETORDPRO_COSTO = rs.COSTOPROM;
        item.DETORDPRO_TOTAL = 0.0;
        item.UNI_COSTEO = rs.ART_UNIDADCOSTEO;
        item.UNI_CODIGO = rs.UNI_CODIGO;
        item.DETORDPRO_LOTE = '';
        item.EXISTENCIA = Number(rs.EXISTENCIA).toFixed(this.encordproService.decimalesCantidad);
        item.DETORDPRO_PPA = '';
        item.DETORDPRO_CPPA = '';
        item.COLOREXISTENCIA = '';
        item.COLORFORMULACION = '';
        this.encordproService.detordpro[this.indicador].push(item);
      }

      // desplegarCostosEstandares(txtArticulo.getText());
      await this.verificarExistenciasArticulosOP();

      if (this.encordproService.selectTipoProd[this.indicador].codigo === 'F') { // Formulación
        this.encordproService.SumarTotal();
      } else { // Por proceso
        this.encordproService.SumarTotalXProceso();
      }

      this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);
      this.botonesmodificar();
      this.spin = false;
    }
  }

  eliminarRegistro() {

    let Clave;
    // Verifica los permisos
    if (this.permisoOrdPro !== null) {
      if (this.permisoOrdPro.PERTODO === 0) {
        if (this.permisoOrdPro.PERELIMACION === 0) {
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario '
              + this.encordproService.usuario.identificacion
              + ', no tiene permiso de Eliminación. Consulte con el Administrador del Sistema.'
          });
          return;
        }
      }
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO === '') {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Seleccione el Registro a Eliminar.'
      });
      return;
    }

    Clave = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;

    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencordpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        this.confirmationService.close();
        // await this.encordproService.eliminarordenproduccion(Clave);
        // this.EliminarAsientoContable();
        //CDPJ
        //await this.EliminarAsientoContable();
        await this.encordproService.eliminarordenproduccion(Clave);
        // await this.EliminarAsientoContable();
        if (this.cmbAsiento.length > 0) {
          for (const asiento of this.cmbAsiento) {
            await this.encordproService.eliminarasientocontable(asiento.codigo);
            this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
              Clave + '/' + asiento.codigo, 'E', '', '01', '', '', '', '').subscribe();
          }

        }
        this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
          Clave + '/' + this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA, 'E', '', '01', '', '', '', '').subscribe();
        this.cmbAsiento = []
        await this.limpiarDatos();
        await this.botonesInicio();
        //CDPJ
        // await this.limpiarDatos();
        // this.botonesInicio();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });

  }

  ingresochbtnIngreso() {

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === "I") {
      this.chbtnIngreso = true;
      return;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO !== "T") {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'La Orden de Producción debe estar en estado TERMINADA.'
      });
      this.chbtnIngreso = false;
      return;
    }

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA === null || this.chbtnIngreso === true) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA = new Date();
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH = new Date();
    }

  }

  ingresochbtnVencLote() {
    this.botonesmodificar();
  }

  ingresochbtnInicio() {
    this.botonesmodificar();

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO === null || this.chbtnInicio === true) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO = new Date();
      this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI = this.datePipe.transform(new Date(), 'HH:mm');
    }
  }

  ingresochbtnTermino() {
    this.botonesmodificar();

    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN === null || this.chbtnTermino === true) {
      this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN = new Date();
      this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN = this.datePipe.transform(new Date(), 'HH:mm');
    }
  }

  async ActualizaEstadoFactura(Estado, CodEncOrdPro) {
    this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO = Estado;
    const dataest = await this.encordproService.getNombreEstado(this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO);
    for (const rs of dataest) {
      this.ESTADO = rs.REF_NOMBREC;
    }
    // Actualiza el estado de la orden de producción y la fecha de la entrada
    await this.encordproService.actualizarestado(Estado, CodEncOrdPro);
  }

  IngresarProductoTerminado() {
    if (this.chbtnIngreso === false) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese la fecha de Ingreso del producto'
      });
      return;
    }
    //CDPJ
    if (this.LOTEPRO === 1 && this.selectProcedimiento.codigo === '') {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Por favor ingrese el tipo de procedimiento'
      });
      this.spin = false;
      return false;
    }
    //CDPJ
    this.IngresarProductoTerminado2();
  }

  async IngresarProductoTerminado2() {
    // verificar control de calidad
    if (this.str_CONCALPRD === '1') {
      const Dato1: any = await this.encordproService.verificarControlCalidad(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);

      if (Dato1 !== null) {
        for (const rs of Dato1) {
          if (rs.TRNCON_ESTADO !== "OK") {
            this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'El certificado de calidad no esta en OK, por favor verifique'
            });
            return;
          } else {
            this.IngresarProductoTerminado3();
          }
        }
      } else {
        this.messageService.add({
          key: 'encordpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'El certificado de calidad no esta en OK, por favor verifique'
        });
        this.claveAuth = '';
        this.autorizacionCodigo = 'OKCONCAL';
        this.displayDialogAutorizacion = true;
      }
    } else {
      this.IngresarProductoTerminado3();
    }
  }

  async IngresarProductoTerminado3() {
    const Dato: any = await this.encordproService.encontrarestado(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO)

    if (Dato !== null) {
      for (const rs of Dato) {
        if (rs.ENCORDPRO_ESTADO === "I") {
          this.confirmationService.confirm({
            message: 'Está seguro de reversar la entrada del Producto',
            header: 'Pregunta',
            icon: 'pi pi-exclamation-triangle',
            key: 'confirmarencordpro',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: async () => {
              this.confirmationService.close();
              const dateent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'dd/MM/yyyy');
              const dateenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');
              const datefin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
              const datefinh = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
              const dateini = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
              const dateinih = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
              const dateemi = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
              const dateemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');
              const DTFechaIngreso = dateent + ' ' + dateenth;
              const rs = await this.utilitariosService.enviarCorreoTokenRvProdAdmaws(this.encordproService.usuarioEsquema.esquema,
                this.encordproService.usuario.identificacion,
                this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA,
                DTFechaIngreso,
                dateemi + ' ' + dateemih,
                dateini + ' ' + dateinih,
                datefin + ' ' + datefinh
              );
              if (rs) {
                this.displayToken = true;
              }
              //this.ReversarEntrada();
            },
            reject: () => {
              this.confirmationService.close();
            }
          });
          return;
        } else {
          if (rs.ENCORDPRO_ESTADO === "T") {
            // Valida fechas
            let tmpFechaFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'yyyy-MM-dd');
            let tmpHoraFin = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
            if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
              tmpHoraFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
            }
            const dateent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'yyyy-MM-dd');
            const dateenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');

            const FechaFin = new Date(tmpFechaFin + 'T' + tmpHoraFin + ':00');
            const FechaEnt = new Date(dateent + ' ' + dateenth);

            if (FechaFin > FechaEnt) {
              console.log(FechaFin);
              console.log('mayor >');
              console.log(FechaEnt);
              this.messageService.add({
                key: 'encordpro',
                severity: 'error',
                summary: 'Información',
                detail: 'La Fecha en que Ingresa el producto no puede ser MENOR a la Fecha en que Termina'
              });
              return;
            }

            this.confirmationService.confirm({
              message: 'Está seguro de realizar el ingreso',
              header: 'Pregunta',
              icon: 'pi pi-exclamation-triangle',
              key: 'confirmarencordpro',
              acceptLabel: 'Si',
              rejectLabel: 'No',
              accept: () => {
                this.confirmationService.close();
                this.RegistrarEntradaProducto(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
                document.getElementById('contorno').style.pointerEvents = 'none';
                this.aggrid.bloquearGrid();
              },
              reject: () => {
                this.confirmationService.close();
              }
            });
          } else {
            this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'La orden de producción tiene que tener estado de terminada'
            });
            return;
          }
        }
      }
    }
  }
  //CDPJ
  async enterToken(event: any) {
    if (event.keyCode === 13) {
      const resp = await this.utilitariosService.validarToken(this.tokenusuario, this.encordproService.usuario.identificacion);
      if (resp === true) {
        this.displayToken = false;
        this.ReversarEntrada();
      } else {
        this.tokenusuario = '';
        this.messageService.add({
          key: 'prodUsuToken',
          severity: 'error',
          summary: 'Error de Actualización',
          detail: 'Token ingresado no coincide. Verifique su correo'
        });
      }
    }

  }
  async aceptarToken() {
    const resp = await this.utilitariosService.validarToken(this.tokenusuario, this.encordproService.usuario.identificacion);
    if (resp === true) {
      this.displayToken = false;
      this.ReversarEntrada();
    } else {
      this.tokenusuario = '';
      this.messageService.add({
        key: 'prodUsuToken',
        severity: 'error',
        summary: 'Error de Actualización',
        detail: 'Token ingresado no coincide. Verifique su correo'
      });
    }
  }

  cancelarToken() {
    this.displayToken = false;
  }
  //CDPJ
  validarClave() {
    if (this.usuarioSup.length > 0) {
      this.verificarUsuarioSupervisor();  // verifica si el usuario es supervisor
    } else {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el usuario.'
      });
    }
  }
  //GSRF
  pruebaCambio(param): boolean {
    if (param.DETORDPRO_CANTIDAD > 0) {
      return false;
    } else {
      return true;
    }
  }
  //GSRF
  verificarUsuarioSupervisor() {
    this.encordproService.ecVerificarUsuarioSupervisor(this.usuarioSup).subscribe(eC => {
      for (const rs of eC) {
        if (rs.COUN > 0) {
          if (this.claveSup.length > 0) {
            this.verificarClaveSupervisor();
          } else {
            this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'Ingrese la clave.'
            });
          }
        } else {
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario ' + this.usuarioSup + ' no es Supervisor o No existe'
          });
        }
      }
    });
  }

  verificarClaveSupervisor() {
    let strSql = 'SELECT NVL(S.USU_CLAVESUP,\'\') AS USUCLAVESUP FROM  SACI_MAEGUIOBJ O, SACI_MAEGUIOBJPER S WHERE ';
    strSql = strSql + ' O.GUIOBJ_CODIGO = S.GUIOBJ_CODIGO AND ';
    strSql = strSql + ' O.GUIOBJ_FORMA = \'' + this.strDialogo + '\' AND ';
    strSql = strSql + ' O.GUIOBJ_NOMBOBJ = \'' + this.strObjeto + '\' AND ';
    strSql = strSql + ' S.COM_CODIGO = \'01\' AND ';
    strSql = strSql + ' S.USU_IDENTIFICACION = \'' + this.encordproService.usuario.identificacion + '\' AND ';
    strSql = strSql + ' S.USU_IDENTIFICACIONSUP = \'' + this.usuarioSup + '\'';
    if (this.intPestaniaCol > 0) {
      strSql = strSql + ' AND O.GUIOBJ_TABCOL = ' + this.intPestaniaCol;
    }

    this.encordproService.ecVerificarClaveSupervisor(this.strDialogo, this.strObjeto, this.encordproService.usuario.identificacion, this.usuarioSup, this.intPestaniaCol).subscribe(data => {
      if (data.length === 0) {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Asigne la clave en los permisos ' +
            'especiales del usuario.'
        });
      } else {
        for (const rs of data) {
          if (rs.USUCLAVESUP !== null) {
            if (rs.USUCLAVESUP.length > 0) {
              if (rs.USUCLAVESUP === this.claveSup) {
                this.bolSupervisor = true;
                this.accionesSupervisor();
              } else {
                this.messageService.add({
                  key: 'encordpro',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'La clave está incorrecta..'
                });
              }
            } else {
              this.messageService.add({
                key: 'encordpro',
                severity: 'error',
                summary: 'Información',
                detail: 'Asigne la clave en los permisos ' +
                  'especiales del usuario.'
              });
            }
          } else {
            this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'Asigne la clave en los permisos ' +
                'especiales del usuario.'
            });
          }
        }
      }
    });
  }

  accionesSupervisor() {
    if (this.strObjeto === 'cmdEntradaProducto') {
      this.IngresarProductoTerminado2();
      this.displayDialogSupervisor = false;
    }
  }

  onHideSupervisor() {
    if (this.bolSupervisor === false) {
      if (this.strObjeto === 'cmdEntradaProducto') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave para el ingreso de la orden de producción'
        });
      }
    }
  }

  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'OKCONCAL') {
        this.messageService.add({
          key: 'encordpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente la clave.'
        });
      }
    }
  }

  comprobarClave() {
    this.encordproService.obtenerClave(this.autorizacionCodigo).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.accionesAutorizacion();
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  accionesAutorizacion() {
    if (this.autorizacionCodigo === 'OKCONCAL') {
      this.IngresarProductoTerminado3();
    }
    this.displayDialogAutorizacion = false;
  }

  ReversarEntrada() {
    this.str_observacion = '';
    this.displayDialogObservacion = true;
    this.chbtnIngreso = false;
  }

  async ReversarEntrada2(CodEncOrdPro) {
    this.displayDialogObservacion = false;

    if (this.str_observacion.length == 0) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Error, para reversar debe ingresar la razón por la que va ha reversar la entrada'
      });
      return;
    }
    if (this.str_observacion.length > 250) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Máxima longitud 250, actual = ' + this.str_observacion.length
      });
      return;
    }

    // Elimina el registro de la transacción de kardex
    await this.encordproService.eliminarkardex(CodEncOrdPro);
    if (this.LOTEPRO === 1) {
      await this.encordproService.eliminarloteprod(this.encordproService.encordpro[this.indicador].ENCORDPRO_LOTE, this.encordproService.encordpro[this.indicador].ART_CODIGO)
      const strDatoL = CodEncOrdPro + '/' + "REV_E" + '/' + this.str_observacion + '/' + 'Lote' + '/' + this.encordproService.encordpro[this.indicador].ENCORDPRO_LOTE;
      this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        strDatoL, 'A', '', '01', '', '', '', '').subscribe();
    }
    const strDato = CodEncOrdPro + '/' + "REV_E" + '/' + this.str_observacion;
    this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
      strDato, 'A', '', '01', '', '', '', '').subscribe();
    const dateent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'dd/MM/yyyy');
    const dateenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');
    const datefin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
    const datefinh = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
    const dateini = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
    const dateinih = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
    const dateemi = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
    const dateemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');
    this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
      strDato + '/Fec-Hing/' + dateent + ' ' + dateenth, 'A', '', '01', '', '', '', '').subscribe();
    this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
      strDato + '/Fec-Hemi/' + dateemi + ' ' + dateemih, 'A', '', '01', '', '', '', '').subscribe();
    this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
      strDato + '/Fec-Hini/' + dateini + ' ' + dateinih, 'A', '', '01', '', '', '', '').subscribe();
    this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
      strDato + '/Fec-Hterm/' + datefin + ' ' + datefinh, 'A', '', '01', '', '', '', '').subscribe();
    // Actualiza el estado de la orden de producción y la fecha de la entrada
    this.ActualizaEstadoFactura("T", CodEncOrdPro);
    this.chbtnIngreso === false;
    this.encordproService.actualizarFechaIngreso("", CodEncOrdPro);
    await this.GenerarAsientoContable();
    this.EliminarAsientoContableOPT();
		/*	ActivarBoton("T");
		*/
  }

  async RegistrarEntradaProducto(CodEncOrdPro) {
    const dateent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'dd/MM/yyyy');
    const dateenth = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADAH, 'HH:mm');

    const DTFechaIngreso = dateent + ' ' + dateenth;

    await this.encordproService.actualizarFechaIngreso(DTFechaIngreso, CodEncOrdPro);
    // REGISTRA LA ENTRADA DE PRODUCTO TERMINADO
    await this.encordproService.regtranskardexent(CodEncOrdPro);

    this.ActualizaEstadoFactura("I", CodEncOrdPro);
    // ActivarBoton("I");
    // ActualizaFechaCostoEstandar();
    await this.GenerarAsientoContable();
    if (this.LOTEPRO === 1) {
      await this.insertarmaelote();
    }
    const datefin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'dd/MM/yyyy');
    const datefinh = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
    const dateini = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
    const dateinih = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
    const dateemi = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
    const dateemih = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAEMISIONH, 'HH:mm');
    this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
      this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA + '/Fec-Hing/' + dateent + ' ' + dateenth, 'I', '', '01', '', '', '', '').subscribe();
    this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
      this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA + '/Fec-Hemi/' + dateemi + ' ' + dateemih, 'I', '', '01', '', '', '', '').subscribe();
    this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
      this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA + '/Fec-Hini/' + dateini + ' ' + dateinih, 'I', '', '01', '', '', '', '').subscribe();
    this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
      this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA + '/Fec-Hterm/' + datefin + ' ' + datefinh, 'I', '', '01', '', '', '', '').subscribe();
  }
  async insertarmaelote() {
    try {
      const dateent = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA, 'dd/MM/yyyy');
      const datefechavenci = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_VENCIMIENTOLOTE, 'dd/MM/yyyy');
      let tipoartprod=this.selectProcedimiento.codigo === '01'?'PRD':this.selectProcedimiento.codigo === '02'?'ENV':''
      this.encordproService.insertarmaeloteprod(this.encordproService.encordpro[this.indicador].ENCORDPRO_LOTE, datefechavenci,
        this.encordproService.encordpro[this.indicador].ART_CODIGO, this.encordproService.encordpro[this.indicador].ENCORDPRO_CANTIDAD,
        dateent,tipoartprod)
      this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
        this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.encordproService.encordpro[this.indicador].ENCORDPRO_REFERENCIA + '/Lote/' + this.encordproService.encordpro[this.indicador].ENCORDPRO_LOTE
        + ' ' + datefechavenci, 'I', '', '01', '', '', '', '').subscribe();
    } catch (error) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'No se ha insertado correctamente el lote'
      });
      return;
    }
  }
  async GenerarAsientoContable() {

    if (this.encordproService.selectTipoProd[this.indicador].codigo === "F") { // Formulación // CDPJ SE CAMBIÓ ANTES this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOPROD
      if (this.str_GENASIMC === '1') {
        // ASIENTO REGISTRO DE ENVIO DE MATERIA PRIMA AL COSTO (DE
        // ACUERDO AL CODIGO DEL MAESTRO DE
        // INVENTARIO
        await this.encordproService.generarasiprocostos(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      }

      // ASIENTO DE PRODUCTO EN PROCESO
      await this.encordproService.generarasiordpro(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);

      // ASIENTO DE PRODUCTO TERMINADO
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'I') {
        await this.encordproService.generarasiordproi(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      }
    } else { // Si es por procesos
      if (this.encordproService.encordpro[this.indicador].ENCORDPRO_ESTADO === 'I') {
        await this.encordproService.generarasientoaju(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
      }
    }

    this.DespliegaNumeroAsiento(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
  }

  async DespliegaNumeroAsiento(NumOrdPro) {
    this.cmbAsiento = [];
    let asientito = [];
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_TIPOPROD === "F") { // Formulación
      // Código el asiento de materia prima al costo
      if (this.chbtnInicio === true) {
        const mes = this.completarFecha(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO.getMonth() + 1);
        const data: any = await this.encordproService.getasientomateriaprima(NumOrdPro, mes);
        if (data !== null) {
          for (const rs of data) {
            asientito.push({ codigo: rs.ASI_NRO, name: rs.ASI_NRO });
          }
        }
      }
      // Código el asiento del producto en proceso
      if (this.chbtnInicio === true) {
        const mes = this.completarFecha(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO.getMonth() + 1);
        const data: any = await this.encordproService.getasientoproductoenproceso(NumOrdPro, mes);
        if (data !== null) {
          for (const rs of data) {
            asientito.push({ codigo: rs.ASI_NRO, name: rs.ASI_NRO });
          }
        }
      }
      // Código del asiento del producto terminado
      if (this.chbtnIngreso === true) {
        const mes = this.completarFecha(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA.getMonth() + 1);
        const data: any = await this.encordproService.getasientoproductoterminado(NumOrdPro, mes);
        if (data !== null) {
          for (const rs of data) {
            asientito.push({ codigo: rs.ASI_NRO, name: rs.ASI_NRO });
          }
        }
      }
    } else { // Por procesos
      // Código del asiento del producto terminado
      if (this.chbtnIngreso === true) {
        const mes = this.completarFecha(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAENTRADA.getMonth() + 1);
        const data: any = await this.encordproService.getasientoproductoterminadoproceso(NumOrdPro, mes);
        if (data !== null) {
          for (const rs of data) {
            asientito.push({ codigo: rs.ASI_NRO, name: rs.ASI_NRO });
          }
        }
      }
    }

    this.cmbAsiento = asientito;
    if (this.cmbAsiento.length > 0) {
      this.selectAsiento = this.cmbAsiento[0];
    }
  }

  completarFecha(strFecha) {
    strFecha = strFecha.toString();
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
  }


  verAsiento() {
    this.asiento = this.selectAsiento.codigo;
    if (this.asiento === null || this.asiento === undefined || this.asiento === '') {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      this.displayAsiContable = true;
    }
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  EliminarAsientoContable() {
    let Clave = this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO;
    this.confirmationService.confirm({
      message: '¿Está seguro de eliminar el asiento contable?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarencordpro',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        this.confirmationService.close();
        for (const asiento of this.cmbAsiento) {
          await this.encordproService.eliminarasientocontable(asiento.codigo);
          //CDPJ
          this.auditoriagral.registrarAuditoria('PROD_ENCORDPRO',
            Clave + '/' + asiento.codigo, 'E', '', '01', '', '', '', '').subscribe();
          //CDPJ
        }
        this.cmbAsiento = []
        //CDPJ
        await this.limpiarDatos();
        this.botonesInicio();
        //CDPJ
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async EliminarAsientoContableOPT() {
    for (const asiento of this.cmbAsiento) {
      if (asiento.codigo.includes('OPT')) {
        await this.encordproService.eliminarasientocontable(asiento.codigo);
      }
    }
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  seleccionItem(valor) {
    this.botonesmodificar();
  }

  async modeloCostosIndlistarDatos(CodOrdPro) {
    const data: any = await this.encordproService.gettrncostosind(CodOrdPro);
    for (const rs of data) {
      rs.CLAVE = rs.DETORDPRO_LINEA;
      rs.TRNCOSTOSIND_FECHA = this.datePipe.transform(rs.TRNCOSTOSIND_FECHA, 'dd/MM/yyyy');
      rs.TRNCOSTOSIND_COSTO = Number(rs.TRNCOSTOSIND_COSTO).toFixed(this.encordproService.decimalesCostoind);
      rs.CODIGO_AUXILIAR = 'X';
      if (rs.TRNCOSTOSIND_INICIAL === 'S') {
        rs.CHECK = true;
      } else {
        rs.CHECK = false;
      }
    }

    this.encordproService.trncostosindirectos[this.indicador] = data;
  }

  async modeloTecnicoslistarDatos(CodOrdPro) {
    const data: any = await this.encordproService.gettrntecnicos(CodOrdPro);
    for (const rs of data) {
      rs.CLAVE = rs.TRNTEC_CODIGO;
      rs.CODIGO_AUXILIAR = 'X';
    }
    this.encordproService.trntecnicos[this.indicador] = data;
  }

  async modeloMaquinarialistarDatos(CodOrdPro) {
    const data: any = await this.encordproService.gettrnmaquinaria(CodOrdPro);
    for (const rs of data) {
      rs.CLAVE = rs.TRNMAQ_CODIGO;
      rs.CODIGO_AUXILIAR = 'X';
    }
    this.encordproService.trnmaquinaria[this.indicador] = data;
  }

  async modeloPedidolistarDatos(CodOrdPro) {
    const data: any = await this.encordproService.gettrnpedidos(CodOrdPro);
    for (const rs of data) {
      rs.CLAVE = rs.DETPED_LINEA;
      rs.ENCPED_FECHAEMISION = this.datePipe.transform(rs.ENCPED_FECHAEMISION, 'dd/MM/yyyy');
      rs.DETPED_CANTIDAD = Number(rs.DETPED_CANTIDAD).toFixed(this.encordproService.decimalesCantidad);
      rs.CODIGO_AUXILIAR = 'X';
    }
    this.encordproService.trnpedidos[this.indicador] = data;
  }

  async modeloHorashombrelistarDatos(CodOrdPro) {
    const data: any = await this.encordproService.gettrnhorashombre(CodOrdPro);
    for (const rs of data) {
      rs.CLAVE = rs.CIN_CODIGO + '' + rs.EMP_CODIGO;
      rs.TRNHH_FECHA = this.datePipe.transform(rs.TRNHH_FECHA, 'dd/MM/yyyy');
      rs.TRNHH_CANTIDAD = Number(rs.TRNHH_CANTIDAD).toFixed(this.confIniciales.getNumDecSist());
      rs.TRNHH_COSTO = Number(rs.TRNHH_COSTO).toFixed(this.confIniciales.getNumDecSist());
      rs.TRNHH_TOTAL = Number(rs.TRNHH_TOTAL).toFixed(this.confIniciales.getNumDecSist());
      rs.CODIGO_AUXILIAR = 'X';
    }
    this.encordproService.trnhorashombre[this.indicador] = data;
    this.encordproService.SumarTotal();
  }

  modeloLiquidacionDesplegarDatosLiquidacion(CodOrdPro) {

  }

  reversarliquidacion() {

  }

  async seleccioncostoindirecto(params) {
    if (params === 'nuevo') {
      this.nuevodetallecostoind();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetallecostoind();
      return;
    }

    if (params.object !== undefined) {
      this.trncostoindSelected = params.object;
    }
  }

  nuevodetallecostoind() {
    const detalle: ProdTrncostosind = {
      ENCORDPRO_NUMERO: this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO,
      COM_CODIGO: '01',
      TRNCOSTOSIND_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      TRNCOSTOSIND_HORA: '',
      CEST_CODIGO: '',
      CIN_CODIGO: '',
      TRNCOSTOSIND_COSTO: '',
      TRNCOSTOSIND_INICIAL: '',
      CODIGO_AUXILIAR: '',
      CHECK: false
    }
    const indi = this.encordproService.trncostosindirectos[this.indicador].indexOf(this.trncostoindSelected);

    if (indi === -1) {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trncostosindirectos[this.indicador].push(detalle);
      this.trncostoindSelected = detalle;
      this.aggrid.refreshaggrid(this.encordproService.trncostosindirectos[this.indicador], this.defaultColDefcostoind.objeto);
      if (this.encordproService.trncostosindirectos[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefcostoind.objeto].setFocusedCell(0, 'TRNCOSTOSIND_FECHA');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefcostoind.objeto].setFocusedCell((this.encordproService.trncostosindirectos[this.indicador].length) - 1, 'TRNCOSTOSIND_FECHA');
      }
    } else {
      detalle.CLAVE = (indi + 1) + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trncostosindirectos[this.indicador].splice(indi + 1, 0, detalle);
      this.trncostoindSelected = detalle;
      this.aggrid.refreshaggridindex(this.encordproService.trncostosindirectos[this.indicador], this.defaultColDefcostoind.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefcostoind.objeto].setFocusedCell(indi + 1, 'TRNCOSTOSIND_FECHA');
    }
  }

  eliminarLineaDetallecostoind() {
    if (this.trncostoindSelected !== undefined && this.encordproService.trncostosindirectos[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.trncostoindSelected.CODIGO_AUXILIAR === 'X') {
            this.encordproService.eliminarDetallecostoind(this.trncostoindSelected);
            this.encordproService.trncostosindirectos[this.indicador].splice(this.encordproService.trncostosindirectos[this.indicador].findIndex(
              det => det.CLAVE === this.trncostoindSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trncostosindirectos[this.indicador].length > 0) {
              this.trncostoindSelected = this.encordproService.trncostosindirectos[this.indicador]
              [this.encordproService.trncostosindirectos[this.indicador].length - 1];
            }

            this.aggrid.refreshaggrid(this.encordproService.trncostosindirectos[this.indicador], this.defaultColDefcostoind.objeto);
          } else {
            this.encordproService.trncostosindirectos[this.indicador].splice(this.encordproService.trncostosindirectos[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.trncostoindSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trncostosindirectos[this.indicador].length > 0) {
              this.trncostoindSelected = this.encordproService.trncostosindirectos[this.indicador]
              [this.encordproService.trncostosindirectos[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encordproService.trncostosindirectos[this.indicador], this.defaultColDefcostoind.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  abrircontrolcalidad() {
    this.auxiliar++;
    this.displayControlCalidad = true;
    this.closeSidebar();
  }

  cerrarControlCalidad() {
    this.displayControlCalidad = false;
  }

  async seleccionpedido(params) {
    if (params === 'nuevo') {
      this.nuevodetallepedido();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetallepedido();
      return;
    }

    if (params.object !== undefined) {
      this.trnpedidoSelected = params.object;
    }
  }

  nuevodetallepedido() {
    const detalle: ProdTrnpedidos = {
      ENCORDPRO_NUMERO: this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO,
      COM_CODIGO: '01',
      ENCPED_NUMERO: '',
      DETPED_LINEA: '',
      DETPED_CANTIDAD: '',
      DETPED_DESCRIPCION: '',
      DETPED_CODIGO: '',
      ENCPED_REFERENCIA: '',
      ENCPED_FECHAEMISION: '',
      CODIGO_AUXILIAR: ''
    }
    const indi = this.encordproService.trnpedidos[this.indicador].indexOf(this.trnpedidoSelected);

    if (indi === -1) {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trnpedidos[this.indicador].push(detalle);
      this.trnpedidoSelected = detalle;
      this.aggrid.refreshaggrid(this.encordproService.trnpedidos[this.indicador], this.defaultColDefpedido.objeto);
      if (this.encordproService.trnpedidos[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefpedido.objeto].setFocusedCell(0, 'ENCPED_NUMERO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefpedido.objeto].setFocusedCell((this.encordproService.trnpedidos[this.indicador].length) - 1, 'ENCPED_NUMERO');
      }
    } else {
      detalle.CLAVE = (indi + 1) + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trnpedidos[this.indicador].splice(indi + 1, 0, detalle);
      this.trnpedidoSelected = detalle;
      this.aggrid.refreshaggridindex(this.encordproService.trnpedidos[this.indicador], this.defaultColDefpedido.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefpedido.objeto].setFocusedCell(indi + 1, 'ENCPED_NUMERO');
    }
  }

  eliminarLineaDetallepedido() {
    if (this.defaultColDefpedido !== undefined && this.encordproService.trnpedidos[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.trnpedidoSelected.CODIGO_AUXILIAR === 'X') {
            this.encordproService.eliminarDetallepedido(this.trnpedidoSelected);
            this.encordproService.trnpedidos[this.indicador].splice(this.encordproService.trnpedidos[this.indicador].findIndex(
              det => det.CLAVE === this.trnpedidoSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trnpedidos[this.indicador].length > 0) {
              this.trnpedidoSelected = this.encordproService.trnpedidos[this.indicador]
              [this.encordproService.trnpedidos[this.indicador].length - 1];
            }

            this.aggrid.refreshaggrid(this.encordproService.trnpedidos[this.indicador], this.defaultColDefpedido.objeto);
          } else {
            this.encordproService.trnpedidos[this.indicador].splice(this.encordproService.trnpedidos[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.trnpedidoSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trnpedidos[this.indicador].length > 0) {
              this.trnpedidoSelected = this.encordproService.trnpedidos[this.indicador]
              [this.encordproService.trnpedidos[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encordproService.trnpedidos[this.indicador], this.defaultColDefpedido.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  async selecciontecnico(params) {
    if (params === 'nuevo') {
      this.nuevodetalletecnico();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalletecnico();
      return;
    }

    if (params.object !== undefined) {
      this.trntecnicoSelected = params.object;
    }
  }

  nuevodetalletecnico() {
    const detalle: ProdTrntecnicos = {
      ENCORDPRO_NUMERO: this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO,
      COM_CODIGO: '01',
      TRNTEC_CODIGO: '',
      TRNTEC_NOMBRE: '',
      CODIGO_AUXILIAR: '',
    }
    const indi = this.encordproService.trntecnicos[this.indicador].indexOf(this.trntecnicoSelected);

    if (indi === -1) {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trntecnicos[this.indicador].push(detalle);
      this.trntecnicoSelected = detalle;
      this.aggrid.refreshaggrid(this.encordproService.trntecnicos[this.indicador], this.defaultColDeftecnico.objeto);
      if (this.encordproService.trntecnicos[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDeftecnico.objeto].setFocusedCell(0, 'TRNTEC_CODIGO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDeftecnico.objeto].setFocusedCell((this.encordproService.trntecnicos[this.indicador].length) - 1, 'TRNTEC_CODIGO');
      }
    } else {
      detalle.CLAVE = (indi + 1) + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trntecnicos[this.indicador].splice(indi + 1, 0, detalle);
      this.trntecnicoSelected = detalle;
      this.aggrid.refreshaggridindex(this.encordproService.trntecnicos[this.indicador], this.defaultColDeftecnico.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDeftecnico.objeto].setFocusedCell(indi + 1, 'TRNTEC_CODIGO');
    }
  }

  eliminarLineaDetalletecnico() {
    if (this.trntecnicoSelected !== undefined && this.encordproService.trntecnicos[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.trntecnicoSelected.CODIGO_AUXILIAR === 'X') {
            this.encordproService.eliminarDetalletecnico(this.trntecnicoSelected);
            this.encordproService.trntecnicos[this.indicador].splice(this.encordproService.trntecnicos[this.indicador].findIndex(
              det => det.CLAVE === this.trntecnicoSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trntecnicos[this.indicador].length > 0) {
              this.trntecnicoSelected = this.encordproService.trntecnicos[this.indicador]
              [this.encordproService.trntecnicos[this.indicador].length - 1];
            }

            this.aggrid.refreshaggrid(this.encordproService.trntecnicos[this.indicador], this.defaultColDeftecnico.objeto);
          } else {
            this.encordproService.trntecnicos[this.indicador].splice(this.encordproService.trntecnicos[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.trntecnicoSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trntecnicos[this.indicador].length > 0) {
              this.trntecnicoSelected = this.encordproService.trntecnicos[this.indicador]
              [this.encordproService.trntecnicos[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encordproService.trntecnicos[this.indicador], this.defaultColDeftecnico.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  //CDPJ
  seleccionDetProcontrol(params) {
    if (params === 'nuevo') {
      this.nuevodetalleprocontrol();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalleprocontrol();
      return;
    }

    if (params.object !== undefined) {
      this.prodcontrolselected = params.object;
    }
  }
  nuevodetalleprocontrol() {
    const detalle: Prodprocontrol = {
      ENCORDPRO_NUMERO: this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO,
      PROCONT_LINEA: 0,
      COM_CODIGO: '01',
      ART_CODIGO: this.encordproService.encordpro[this.indicador].ART_CODIGO,
      PROCONT_PARAMETRO: '',
      PROCONT_ESP_MINIMA: '',
      PROCONT_ESP_MAXIMA: '',
      PROCONT_METODO: '',
    }
    const indi = this.encordproService.procontrol[this.indicador].indexOf(this.prodcontrolselected);
    if (indi === -1) {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.procontrol[this.indicador].push(detalle);
      this.prodcontrolselected = detalle;
      this.aggrid.refreshaggrid(this.encordproService.procontrol[this.indicador], this.defaultColDefProcont.objeto);
      if (this.encordproService.procontrol[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefProcont.objeto].setFocusedCell(0, 'PROCONT_PARAMETRO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefProcont.objeto].setFocusedCell((this.encordproService.procontrol[this.indicador].length) - 1, 'PROCONT_PARAMETRO');
      }
    } else {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.procontrol[this.indicador].splice(indi + 1, 0, detalle);
      this.prodcontrolselected = detalle;
      this.aggrid.refreshaggridindex(this.encordproService.procontrol[this.indicador], this.defaultColDefProcont.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefProcont.objeto].setFocusedCell(indi + 1, 'PROCONT_PARAMETRO');
    }

  }
  eliminarLineaDetalleprocontrol() {
    if (this.prodcontrolselected !== undefined && this.encordproService.procontrol[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          this.confirmationService.close();
          if (this.prodcontrolselected.PROCONT_LINEA !== 0) {
            await this.encordproService.eliminarDetalleprocontrol(this.prodcontrolselected);
            this.encordproService.procontrol[this.indicador].splice(this.encordproService.procontrol[this.indicador].findIndex(
              det => det.CLAVE === this.prodcontrolselected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.procontrol[this.indicador].length > 0) {
              this.prodcontrolselected = this.encordproService.procontrol[this.indicador]
              [this.encordproService.procontrol[this.indicador].length - 1];
            }
            this.auditoriagral.registrarAuditoria('PROD_TRNPROCONT',
              this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.prodcontrolselected.PROCONT_PARAMETRO, 'E', '', '01', '', '', '', '').subscribe();
            this.aggrid.refreshaggrid(this.encordproService.procontrol[this.indicador], this.defaultColDefProcont.objeto);
          } else {
            this.encordproService.procontrol[this.indicador].splice(this.encordproService.procontrol[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.prodcontrolselected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.procontrol[this.indicador].length > 0) {
              this.prodcontrolselected = this.encordproService.procontrol[this.indicador]
              [this.encordproService.procontrol[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encordproService.procontrol[this.indicador], this.defaultColDefProcont.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });

    }
  }


  seleccionDetProFabri(params) {
    if (params === 'nuevo') {
      this.nuevodetalleprofabri();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetalleprofabri();
      return;
    }

    if (params.object !== undefined) {
      this.prodfabricaselected = params.object;
    }
  }
  nuevodetalleprofabri() {
    const detalle: Prodprofabri = {
      ENCORDPRO_NUMERO: this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO,
      PROFAB_LINEA: 0,
      COM_CODIGO: '01',
      PROFAB_ETAPA: '',
      PROFAB_DESCRIPCION: '',
      ART_CODIGO_MP: '',
      PROFAB_CANTIDAD: Number('0').toFixed(2),
      PROFAB_UNIDAD: '',
      PROFAB_HORA_INICIO: this.datePipe.transform(new Date(), 'HH:mm'),
      PROFAB_HORA_FIN: this.datePipe.transform(new Date(), 'HH:mm'),
    }
    const indi = this.encordproService.profabricacion[this.indicador].indexOf(this.prodfabricaselected);
    if (indi === -1) {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.profabricacion[this.indicador].push(detalle);
      this.prodfabricaselected = detalle;
      this.aggrid.refreshaggrid(this.encordproService.profabricacion[this.indicador], this.defaultColDefProfab.objeto);
      if (this.encordproService.profabricacion[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefProfab.objeto].setFocusedCell(0, 'PROFAB_ETAPA');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefProfab.objeto].setFocusedCell((this.encordproService.profabricacion[this.indicador].length) - 1, 'PROFAB_ETAPA');
      }
    } else {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.profabricacion[this.indicador].splice(indi + 1, 0, detalle);
      this.prodfabricaselected = detalle;
      this.aggrid.refreshaggridindex(this.encordproService.profabricacion[this.indicador], this.defaultColDefProfab.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefProfab.objeto].setFocusedCell(indi + 1, 'PROFAB_ETAPA');
    }

  }

  eliminarLineaDetalleprofabri() {
    if (this.prodfabricaselected !== undefined && this.encordproService.profabricacion[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          this.confirmationService.close();
          if (this.prodfabricaselected.PROFAB_LINEA !== 0) {
            await this.encordproService.eliminarDetalleprofabri(this.prodfabricaselected);
            this.encordproService.profabricacion[this.indicador].splice(this.encordproService.profabricacion[this.indicador].findIndex(
              det => det.CLAVE === this.prodfabricaselected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.profabricacion[this.indicador].length > 0) {
              this.prodfabricaselected = this.encordproService.profabricacion[this.indicador]
              [this.encordproService.profabricacion[this.indicador].length - 1];
            }
            this.auditoriagral.registrarAuditoria('PROD_TRNPROFAB',
              this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO + '/' + this.prodfabricaselected.PROFAB_ETAPA, 'E', '', '01', '', '', '', '').subscribe();
            this.aggrid.refreshaggrid(this.encordproService.profabricacion[this.indicador], this.defaultColDefProfab.objeto);
          } else {
            this.encordproService.profabricacion[this.indicador].splice(this.encordproService.profabricacion[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.prodfabricaselected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.profabricacion[this.indicador].length > 0) {
              this.prodfabricaselected = this.encordproService.profabricacion[this.indicador]
              [this.encordproService.profabricacion[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encordproService.profabricacion[this.indicador], this.defaultColDefProfab.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });

    }
  }
  async LlenarDatosOPEnProcControl() {
    try {
      await this.encordproService.LlenarDatosOPEnProcControl(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO)
      await this.listardetalleprocontrol(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO)
    } catch (error) {
      this.messageService.add({
              key: 'encordpro',
              severity: 'error',
              summary: 'Información',
              detail: 'Ocurrio un error al ejecutar el proceso'
            });
    }
  }
  async LlenarDatosOPEnProcFab(){
    try {
      await this.encordproService.llenardatosprofabri(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO)
      await this.listardetalleprofabri(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
    } catch (error) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrio un error al ejecutar el proceso'
      });
    }
  }
  //CDPJ
  async seleccionmaquinaria(params) {
    if (params === 'nuevo') {
      this.nuevodetallemaquinaria();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetallemaquinaria();
      return;
    }

    if (params.object !== undefined) {
      this.trnmaquinSelected = params.object;
    }
  }

  nuevodetallemaquinaria() {
    const detalle: ProdTrnmaquinaria = {
      ENCORDPRO_NUMERO: this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO,
      COM_CODIGO: '01',
      TRNMAQ_CODIGO: '',
      TRNMAQ_NOMBRE: '',
      CODIGO_AUXILIAR: ''
    }
    const indi = this.encordproService.trnmaquinaria[this.indicador].indexOf(this.trnmaquinSelected);

    if (indi === -1) {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trnmaquinaria[this.indicador].push(detalle);
      this.trnmaquinSelected = detalle;
      this.aggrid.refreshaggrid(this.encordproService.trnmaquinaria[this.indicador], this.defaultColDefmaquinaria.objeto);
      if (this.encordproService.trnmaquinaria[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefmaquinaria.objeto].setFocusedCell(0, 'TRNMAQ_CODIGO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefmaquinaria.objeto].setFocusedCell((this.encordproService.trnmaquinaria[this.indicador].length) - 1, 'TRNMAQ_CODIGO');
      }
    } else {
      detalle.CLAVE = (indi + 1) + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trnmaquinaria[this.indicador].splice(indi + 1, 0, detalle);
      this.trnmaquinSelected = detalle;
      this.aggrid.refreshaggridindex(this.encordproService.trnmaquinaria[this.indicador], this.defaultColDefmaquinaria.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefmaquinaria.objeto].setFocusedCell(indi + 1, 'TRNMAQ_CODIGO');
    }
  }

  eliminarLineaDetallemaquinaria() {
    if (this.trnmaquinSelected !== undefined && this.encordproService.trnmaquinaria[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.trnmaquinSelected.CODIGO_AUXILIAR === 'X') {
            this.encordproService.eliminarDetallemaquinaria(this.trnmaquinSelected);
            this.encordproService.trnmaquinaria[this.indicador].splice(this.encordproService.trnmaquinaria[this.indicador].findIndex(
              det => det.CLAVE === this.trnmaquinSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trnmaquinaria[this.indicador].length > 0) {
              this.trnmaquinSelected = this.encordproService.trnmaquinaria[this.indicador]
              [this.encordproService.trnmaquinaria[this.indicador].length - 1];
            }

            this.aggrid.refreshaggrid(this.encordproService.trnmaquinaria[this.indicador], this.defaultColDefmaquinaria.objeto);
          } else {
            this.encordproService.trnmaquinaria[this.indicador].splice(this.encordproService.trnmaquinaria[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.trnmaquinSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trnmaquinaria[this.indicador].length > 0) {
              this.trnmaquinSelected = this.encordproService.trnmaquinaria[this.indicador]
              [this.encordproService.trnmaquinaria[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encordproService.trnmaquinaria[this.indicador], this.defaultColDefmaquinaria.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  async funExistenciaArtSinIncRefArt(CodArticulo, CodigoBodega, Referencia) {
    // Función extrae la existencia del artículo sin incluir los valores de
    // la orden de producción actual
    let CantidadArtDetFac = 0.0;
    let ExistenciaArt = 0.0;
    let strFechaInicio = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
    let strHora = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI.toString().includes('-')) {
      strHora = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI, 'HH:mm');
    }
    const data: any = await this.encordproService.canpartesreservadas(CodArticulo, CodigoBodega, strFechaInicio, strHora);
    for (const rs of data) {
      ExistenciaArt = rs.EXISTENCIA;
    }
    // Cantidad de articulos que estan actualmente grabados en el detalle de la factura
    const data2: any = await this.encordproService.cantidadarticulosguardados(CodArticulo, CodigoBodega, Referencia);
    for (const rs of data2) {
      CantidadArtDetFac = rs.CANTIDAD;
    }
    return (ExistenciaArt + CantidadArtDetFac);
  }

  async  funcCantArtDetFacEnUniCosteo(Art_codigo) {
    // Cantidad total de un producto en la unidad de costeo en el detalle de una factura    
    let Uni_codigo = "", str_artunidadcosteo = "";
    let Cantidad = 0.0;
    let Existencia = 0.0;
    let CantidadTotal = 0.0;

    for (const item of this.encordproService.detordpro[this.indicador]) {
      if (item.ART_CODIGO === Art_codigo) {
        Cantidad = Number(item.DETORDPRO_CANTIDAD);
        Uni_codigo = item.UNI_CODIGO;
        const data: any = await this.encordproService.getunidadcosteo(Art_codigo);
        for (const rs of data) {
          if (rs.ART_UNIDADCOSTEO !== null) {
            str_artunidadcosteo = rs.ART_UNIDADCOSTEO
          }
        }
        // Transforma la existencia en unidades a las que se solicita
        if (Uni_codigo != str_artunidadcosteo) {
          const data2: any = await this.encordproService.transformaunidad(Uni_codigo, str_artunidadcosteo, Cantidad);
          for (const rs of data2) {
            Existencia = Number(rs.EXISTENCIA)
          }
        } else {
          Existencia = 0;
        }
      } else {
        Existencia = Number(Existencia);
      }
      CantidadTotal = CantidadTotal + Existencia;
    }
    return CantidadTotal;
  }

  async ActualizarTotales(CodOrdPro) {
    await this.encordproService.actualizartotales(
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOMP),
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOIND),
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALCOSTOPRM),
      Number(this.encordproService.encordpro[this.indicador].ENCORDPRO_TOTALPRODUCIDO),
      CodOrdPro);
  }

  async GenerarKardex() {
    await this.encordproService.generarkardex(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO);
  }

  async abrirLotes() {
    let dblCantidad;
    let item = this.detordproSelected;
    dblCantidad = Number(item.DETORDPRO_CANTIDAD);
console.log('item',item)
    if (item.DETORDPRO_LINEA > 0) {
      const PROMAELOTE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PROMAELOTE').CFG_VALOR1;
      const FPLOTSIM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FPLOTSIM').CFG_VALOR1;
      console.log(PROMAELOTE)
      console.log(FPLOTSIM)
      if (PROMAELOTE === 1) {
        if (FPLOTSIM === 1) {
          const Dato: any = await this.encordproService.encontrarArtcaduca(item.ART_CODIGO);

          let strArtCaduca = '';
          if (Dato !== null) {
            for (const rs of Dato) {
              strArtCaduca = rs.ART_CADUCA;
            }
          }

          if (strArtCaduca === "S") {
            this.auxiliarlot++;
            this.displayDialogLotes = true;
          }
        }
      } else {
        this.encordproService.lotes[this.indicador] = [];
        this.encordproService.articulo[this.indicador] = this.detordproSelected.ART_CODIGO;
        this.displayDialogLotesMP = true;
        this.botonNuevoL = false;
        this.botonGuardarL = true;
        this.botonBorrarL = false;
        this.botonRegresarL = false;
      }

    } else {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'Primero guarde la orden de producción...!!!!'
      });
    }

  }

  cerrarLotes() {
    this.displayDialogLotes = false;
    this.desplegarLotesProd();
    /*    const DETFACLOT = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DETFACLOT').CFG_VALOR1;
        if (DETFACLOT == 1) {
          this.confirmationService.confirm({
            message: 'Agregar la información de lotes como comentario?',
            header: 'Pregunta',
            icon: 'pi pi-exclamation-triangle',
            key: 'confirmarencfac',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
              this.confirmationService.close();
              this.listarLotes(this.detfacSelected.DETFAC_LINEA,
                this.encfacService.encfacarray[this.indicador].ENCFAC_NUMERO);
              
            },
            reject: () => {
              this.confirmationService.close();
            }
          });
    
        }*/
  }

  manejarSenalesLotes(valor) {
    if (valor === 'Nuevo') {
      this.nuevodetallelote();
    }
    if (valor === 'Guardar') {
      // this.guardarRegistro();
    }
    if (valor === 'Borrar') {
      this.eliminarLineaDetallelote();
    }
    if (valor === 'Cancelar') {

    }
    if (valor === 'Salir') {
      this.displayDialogLotesMP = false;
    }
  }

  async seleccionLotes(params) {

    if (params.object !== undefined) {
      this.trntecnicoSelected = params.object;
    }
  }


  nuevodetallelote() {
    const detalle: any = {
      TRNLOTE_CANTIDAD: 0,
      TRNLOTE_LOTE: ''
    }
    const indi = this.encordproService.lotes[this.indicador].indexOf(this.loteSelected);

    if (indi === -1) {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.lotes[this.indicador].push(detalle);
      this.loteSelected = detalle;
      this.aggrid.refreshaggrid(this.encordproService.lotes[this.indicador], this.defaultColDefLote.objeto);
      if (this.encordproService.lotes[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefLote.objeto].setFocusedCell(0, 'TRNLOTE_LOTE');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefLote.objeto].setFocusedCell((this.encordproService.lotes[this.indicador].length) - 1, 'TRNLOTE_LOTE');
      }
    } else {
      detalle.CLAVE = (indi + 1) + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.lotes[this.indicador].splice(indi + 1, 0, detalle);
      this.loteSelected = detalle;
      this.aggrid.refreshaggridindex(this.encordproService.lotes[this.indicador], this.defaultColDefLote.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefLote.objeto].setFocusedCell(indi + 1, 'TRNLOTE_LOTE');
    }
  }

  eliminarLineaDetallelote() {
    if (this.loteSelected !== undefined && this.encordproService.lotes[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.loteSelected.CODIGO_AUXILIAR === 'X') {
            this.encordproService.eliminarDetallelote(this.loteSelected);
            this.encordproService.lotes[this.indicador].splice(this.encordproService.lotes[this.indicador].findIndex(
              det => det.CLAVE === this.loteSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.lotes[this.indicador].length > 0) {
              this.loteSelected = this.encordproService.lotes[this.indicador]
              [this.encordproService.lotes[this.indicador].length - 1];
            }

            this.aggrid.refreshaggrid(this.encordproService.lotes[this.indicador], this.defaultColDeftecnico.objeto);
          } else {
            this.encordproService.lotes[this.indicador].splice(this.encordproService.lotes[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.loteSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.lotes[this.indicador].length > 0) {
              this.loteSelected = this.encordproService.lotes[this.indicador]
              [this.encordproService.lotes[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encordproService.lotes[this.indicador], this.defaultColDeftecnico.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  async desplegarLotesProd() {
    let cadena = '';
    for (const item of this.encordproService.detordpro[this.indicador]) {
      const data: any = await this.encordproService.getProdtrnlote(this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO, item.DETORDPRO_LINEA)
      if (data !== null) {
        for (const rs of data) {
          cadena = cadena + "/" + rs.TRNLOTE_LOTE;
        }
      }
      item.DETORDPRO_LOTE = cadena;
    }
    this.aggrid.refreshaggrid(this.encordproService.detordpro[this.indicador], this.defaultColDef.objeto);
  }

  async obtenerNumSiguiente() {
    let strNumActual = "";
    const data: any = await this.encordproService.obtenernumsig(this.GRUPO);
    for (const rs of data) {
      strNumActual = rs.NUM_SECACTUAL;
    }
    return strNumActual;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  calcularHorasHombre() {
    let tmpFechaIni = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAINICIO, 'yyyy-MM-dd');
    let tmpFechaFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_FECHAFIN, 'yyyy-MM-dd');
    let tmpHoraIni = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI;
    let tmpHoraFin = this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN;
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI.toString().includes('-')) {
      tmpHoraIni = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAINI, 'HH:mm');
    }
    if (this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN.toString().includes('-')) {
      tmpHoraFin = this.datePipe.transform(this.encordproService.encordpro[this.indicador].ENCORDPRO_HORAFIN, 'HH:mm');
    }

    const FechaIni = new Date(tmpFechaIni + 'T' + tmpHoraIni + ':00');
    const FechaFin = new Date(tmpFechaFin + 'T' + tmpHoraFin + ':00');

    const diffMS = (FechaFin.getTime() - FechaIni.getTime());
    const diffDays = Math.floor(diffMS / 86400000) * 8; // days
    const diffHrs = Math.floor((diffMS % 86400000) / 3600000); // hours
    const diffMins = Math.floor(((diffMS % 86400000) / 3600000) / 60000); // hours
    const totalhoras = diffDays + ((diffHrs > 8) ? 8 : diffHrs);
    this.encordproService.encordpro[this.indicador].ENCORDPRO_HORASHOMBRE = totalhoras;

    this.activarOrden();
    this.botonesmodificar();
  }


  async seleccionhorahombre(params) {
    if (params === 'nuevo') {
      this.nuevodetallehorahombre();
      return;
    }

    if (params === 'cancelar') {
      this.cancelardetalle();
      return;
    }

    if (params === 'eliminar') {
      this.eliminarLineaDetallehorahombre();
      return;
    }

    if (params.object !== undefined) {
      this.trnhorashombreSelected = params.object;
    }
  }

  nuevodetallehorahombre() {
    const detalle: ProdTrnhorashombre = {
      ENCORDPRO_NUMERO: this.encordproService.encordpro[this.indicador].ENCORDPRO_NUMERO,
      COM_CODIGO: '01',
      TRNHH_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      TRNHH_HORAINI: '00:00',
      TRNHH_HORAFIN: '00:00',
      CIN_CODIGO: '',
      CIN_NOMBRE: '',
      EMP_CODIGO: '',
      TRNHH_REFERENCIA: '',
      TRNHH_CANTIDAD: '',
      TRNHH_COSTO: '',
      TRNHH_TOTAL: '',
      CODIGO_AUXILIAR: ''
    }
    const indi = this.encordproService.trnhorashombre[this.indicador].indexOf(this.trnhorashombreSelected);

    if (indi === -1) {
      detalle.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trnhorashombre[this.indicador].push(detalle);
      this.trnhorashombreSelected = detalle;
      this.aggrid.refreshaggrid(this.encordproService.trnhorashombre[this.indicador], this.defaultColDefhorashombre.objeto);
      if (this.encordproService.trnhorashombre[this.indicador].length === 1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefhorashombre.objeto].setFocusedCell(0, 'TRNHH_FECHA');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefhorashombre.objeto].setFocusedCell((this.encordproService.trnhorashombre[this.indicador].length) - 1, 'TRNHH_FECHA');
      }
    } else {
      detalle.CLAVE = (indi + 1) + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.encordproService.trnhorashombre[this.indicador].splice(indi + 1, 0, detalle);
      this.trnhorashombreSelected = detalle;
      this.aggrid.refreshaggridindex(this.encordproService.trnhorashombre[this.indicador], this.defaultColDefhorashombre.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefhorashombre.objeto].setFocusedCell(indi + 1, 'TRNHH_FECHA');
    }
  }

  eliminarLineaDetallehorahombre() {
    if (this.trnhorashombreSelected !== undefined && this.encordproService.trnhorashombre[this.indicador].length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarencordpro',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.trnhorashombreSelected.CODIGO_AUXILIAR === 'X') {
            this.encordproService.eliminarDetallehorashombre(this.trnhorashombreSelected);
            this.encordproService.trnhorashombre[this.indicador].splice(this.encordproService.trnhorashombre[this.indicador].findIndex(
              det => det.CLAVE === this.trnhorashombreSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trnhorashombre[this.indicador].length > 0) {
              this.trnhorashombreSelected = this.encordproService.trnhorashombre[this.indicador]
              [this.encordproService.trnhorashombre[this.indicador].length - 1];
            }

            this.aggrid.refreshaggrid(this.encordproService.trnhorashombre[this.indicador], this.defaultColDefhorashombre.objeto);
          } else {
            this.encordproService.trnhorashombre[this.indicador].splice(this.encordproService.trnhorashombre[this.indicador].findIndex(
              detfac => detfac.CLAVE === this.trnhorashombreSelected.CLAVE),
              1);
            this.messageService.add({
              key: 'encordpro',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });
            if (this.encordproService.trnhorashombre[this.indicador].length > 0) {
              this.trnhorashombreSelected = this.encordproService.trnhorashombre[this.indicador]
              [this.encordproService.trnhorashombre[this.indicador].length - 1];
            }
            this.aggrid.refreshaggrid(this.encordproService.trnhorashombre[this.indicador], this.defaultColDefhorashombre.objeto);
          }
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  async GuardarHorasHombre() {
    this.encordproService.SumarTotal();

    for (const det of this.encordproService.trnhorashombre[this.indicador]) {
      if (det.CODIGO_AUXILIAR === "") { // Inserta un nuevo registro
        const item = det;
        item.TRNHH_CANTIDAD = Number(det.TRNHH_CANTIDAD);
        item.TRNHH_COSTO = Number(det.TRNHH_COSTO);
        item.TRNHH_TOTAL = Number(det.TRNHH_TOTAL);

        await this.encordproService.guardarhorashombre(item);
        item.CODIGO_AUXILIAR = "X";
      } else { // Actualiza
        const item = det;
        item.TRNHH_CANTIDAD = Number(det.TRNHH_CANTIDAD);
        item.TRNHH_COSTO = Number(det.TRNHH_COSTO);
        item.TRNHH_TOTAL = Number(det.TRNHH_TOTAL);

        this.encordproService.actualizarhorashombre(item);
      }
    }

    this.messageService.add({
      key: 'encordpro',
      severity: 'success',
      summary: 'Información',
      detail: 'Datos Horas Hombre guardados satisfactoriamente'
    });

    this.botonesInicio();
  }


}