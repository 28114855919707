import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { Invsalida } from '../invinterfaces/invsalida';
import { Invencsalida } from '../invinterfaces/invencsalida';
import { Invsalidavar } from '../invinterfaces/invsalidavar';
import { InvMaearticulo } from '../invinterfaces/invmaearticulo';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { InvsalidaprodService } from '../invservicios/invsalidaprod.service';
import { InvbusquedaService } from '../invservicios/invbusqueda.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { DatePipe } from '@angular/common';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { Usuario } from 'src/app/usuario';
@Component({
  selector: 'app-invsalidaprod',
  templateUrl: './invsalidaprod.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvsalidaprodComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;


  salidaSeleccionada: Invsalida;
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  botonPrimero: boolean;
  botonSiguiente: boolean;
  botonAnterior: boolean;
  botonUltimo: boolean;
  barraBotones2: boolean;
  barraBotones4: boolean;
  panelSalidas: boolean;
  inputsboolean: boolean;
  botonNuevoP: boolean;
  botonBorrarP: boolean;
  botonRegresarP: boolean;
  decimalesPrecioSal: number;
  decimalesCantidadSal: number;
  decimalesTotalSal: number;
  displayDialogBus: boolean;
  displayDialogBusFast: boolean;
  displayDialogAsiento: boolean;
  displayAsiContable: boolean;
  totalSalida: number;
  displayAcciones: boolean;
  displayDialogSeriales: boolean;
  displayDialogLotes: boolean;
  spin = false;
  strNumFact: any;
  strFechaSer: any;
  indice: number;
  opcion: string;
  types: SelectItem[];
  arregloBusq: any[];
  busquedaCampos: string[];
  auxiliar: number;
  tabla: string;
  where: string;
  largo: string;
  consulta: string;
  public frameworkComponents;
  rowStyle;
  defaultColDefSalida;
  rowSelection = 'multiple';
  auxreporte: number = 0;
  displayDialogReportes: boolean;
  displayDialogDocumentos = false;
  auxiliarlot = 0;
  strNombreForma: string;
  activeIndex: number = 0;
  columnDefsSalida = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 10,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Artículo', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: false,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 25
        }
      }
    },
    { // 2
      headerName: 'Nombre', field: 'ART_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: false,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80
        }
      }
    },
    { // 3
      headerName: 'Documento', field: 'DETKAR_NRODOC', cellEditor: 'cellPrueba', editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: false,
          obligatorio: true,
          fecha: false
        }
      }
    },
    { // 4
      headerName: 'Cantidad', field: 'DETKAR_CANTIDAD', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // , editable: true,
      onCellClicked: (params) => {
        this.abrirLotes(params.data);
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 6,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
        }
      }
    },
    { // 5
      headerName: 'Costo', field: 'DETKAR_COSTOUNIT', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      editable: () => {
        return false;
      }
      /*cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 6,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        }*/
    },
    { // 6
      headerName: 'Costo Total', field: 'DETKAR_COSTOTOTAL', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      editable: () => {
        return false;
      }
      /*cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: true,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32
          }
        }*/
    }, { // 7
      headerName: 'C.Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 35
        }
      }
    }
  ];






  constructor(public invsalidaprodService: InvsalidaprodService, private datePipe: DatePipe,
    private busquedaService: InvbusquedaService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private confIniciales: ConfInicialesService,
    private init: NuevoComponentService,
    private auditoriagral: AuditoriagralService,
    private utilitariosService: UtilitariosService,
    public usuario: Usuario) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefSalida = {
      editable: true,
      width: 150,
      objeto: 'maesalidaprod'
    };
  }
  ngOnInit(): void {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.confIniciales.getDecimales('frmINV_ENCKARDEX').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'COSTO') {
            this.decimalesPrecioSal = Number(decimales.NUMDEC_NUMDEC);
            this.invsalidaprodService.decimalesPrecioSal = this.decimalesPrecioSal;
          } else if (decimales.NUMDEC_CAMPO === 'COSTOTOTAL') {
            this.decimalesTotalSal = Number(decimales.NUMDEC_NUMDEC);
            this.invsalidaprodService.decimalesTotalSal = this.decimalesTotalSal;
          } else if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
            this.decimalesCantidadSal = Number(decimales.NUMDEC_NUMDEC);
            this.invsalidaprodService.decimalesCantidadSal = this.decimalesCantidadSal;
          }
        });

        this.columnDefsSalida[4].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.invsalidaprodService.decimalesCantidadSal,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.columnDefsSalida[5].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.invsalidaprodService.decimalesPrecioSal,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.aggrid.refreshColumnDefs();
      }
    });
    this.tabla = '';
    this.auxiliar = 0;
    this.busquedaCampos = [];
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.botonPrimero = false;
    this.botonSiguiente = false;
    this.botonAnterior = false;
    this.botonUltimo = false;
    this.barraBotones2 = false;
    this.barraBotones4 = false;
    this.panelSalidas = false;
    this.botonNuevoP = true;
    this.botonBorrarP = true;
    this.botonRegresarP = true;
    this.inputsboolean = true;
    this.displayDialogReportes = false;
    this.largo = '-5800';
    this.invsalidaprodService.date1 = new Date();
    this.invsalidaprodService.hora = new Date();
    this.inputsboolean = true;
    this.invsalidaprodService.encsalidaSeleccionada[this.indicador] = {};
    this.invsalidaprodService.detkardexsarray[this.indicador] = [];
    this.salidaSeleccionada = {};
    this.getConfiguracion();



  }
  getConfiguracion() {
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      //CDPJ
      const ccpies = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCPIES').CFG_VALOR1;
      //CDPJ
      if (ccpies === 0 || ccpies === null || ccpies === undefined) {
        this.columnDefsSalida.splice(7, 1);
      }
      this.aggrid.refreshColumnDefs();

    });
  }
  abrirLotes(params) {
    this.salidaSeleccionada = params;
    this.auxiliarlot++;
    this.displayDialogLotes = this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO.length > 0 && this.salidaSeleccionada.ART_CADUCA === 'S';
  }
  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable
      const dia = this.invsalidaprodService.date1.getDate();
      const mes = this.invsalidaprodService.date1.getMonth();
      const anio = this.invsalidaprodService.date1.getFullYear();

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'salprod',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }
    if (valor === 'Guardar') {
      //this.realizarGuardar();
    }
    if (valor === 'Borrar') {
      //this.confirmarBorrarT('documento');
    }
    if (valor === 'Cancelar' || valor === 'Nuevo' || valor === 'Primero' || valor === 'Ultimo'
      || valor === 'Anterior' || valor === 'Siguiente') {
      if (valor === 'Nuevo' || valor === 'Primero' || valor === 'Ultimo'
        || valor === 'Anterior' || valor === 'Siguiente') {
        console.log(!this.existeAsiento())
        if (!this.existeAsiento()) {
          return;
        }
      }
      console.log('valor', valor)
      this.mostrarSalida(valor);
    }
    if (valor === 'Buscar') {
        this.opcion = 'BUSQUEDA';
        this.types = [
          { label: 'Número', value: 'KAR_NRO' },
          { label: 'Fecha', value: 'KAR_FECHA' },
          { label: 'Comentario', value: 'KAR_COMENTARIO' },
          { label: 'Comprobante', value: 'KAR_NROCOMPROBANTE' },
          { label: 'No. Asiento', value: 'ASI_NRO' },
          { label: 'ORDEN PROD', value: 'ENCORDPRO_NUMERO' },
          { label: 'Origen', value: 'KAR_ORIGEN' },
        ];
        //CDPJ
        this.busquedaCampos = ['', '', '', '', '', '', '']
        this.tabla = 'INV_ENCKARDEX';
        
          this.where = 'KAR_TIPO = \'SA\' AND KAR_ORIGEN = \'PROD\'';
        
        this.busquedaService. busquedaInvEncKardex2NEW('PROD').subscribe((res: any[]) => {
          this.arregloBusq = res;
          this.arregloBusq.map((bus) => {
            bus.KAR_FECHA = this.datePipe.transform(bus.KAR_FECHA, 'dd/MM/yyyy');
          });
          this.displayDialogBusFast = true;
          this.spin=false;
        });
    }

    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Salir') {

        this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  cerrarLotes() {
    this.displayDialogLotes = false;
  }

  existeAsiento() {
    let bolExiste = false;
    if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DOCSINASI').CFG_VALOR1 === 0) {
      let bolSaldoIni = false;
      if (this.invsalidaprodService.encsalidaSeleccionada[this.indicador].ASI_NRO === null || this.invsalidaprodService.encsalidaSeleccionada[this.indicador].ASI_NRO === undefined) {
        this.invsalidaprodService.encsalidaSeleccionada[this.indicador].ASI_NRO = '';
      }

      if (this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO === null || this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO === undefined) {
        this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO = '';
      }

      if (this.invsalidaprodService.encsalidaSeleccionada[this.indicador].ASI_NRO.length === 0 &&
        this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO.length > 0 && !bolSaldoIni) {
        this.confirmationService.confirm({
          message: 'El documento no tiene Asiento Contable.' +
            ' Desea eliminar el documento?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarSalprod',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            this.borrarEncSalida();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      } else {
        bolExiste = true;
      }
    } else {
      bolExiste = true;
    }
    return bolExiste;
  }
  async borrarEncSalida() {
    //validar que la salida no esté en una orden de producción ya ingresada
    let prodin = 0;
    const rs = await this.invsalidaprodService.ValidaEnSaOrdProd(this.invsalidaprodService.encsalidaSeleccionada[this.indicador]);
    if (rs !== null && rs !== undefined) {
      for (const data of rs) {
        prodin = data.NUMERO
      }
    }
    if (prodin > 0) {
      this.messageService.add({
        key: 'salprod',
        severity: 'error',
        summary: 'Eliminación de Salida',
        detail: 'No se puede eliminar la salida ya que la Orden de Producción está ingresada'
      });
      return;
    }
    try {
      await this.invsalidaprodService.borrarEncSalida(this.invsalidaprodService.encsalidaSeleccionada[this.indicador]).toPromise()//subscribe(() => {
      this.auditoriagral.registrarAuditoria('INV_ENCKARDEX', this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      let numero;
      if (this.invsalidaprodService.encsalidaSeleccionada[this.indicador].ASI_NRO !== undefined || this.invsalidaprodService.encsalidaSeleccionada[this.indicador].ASI_NRO !== '') {
        numero = this.invsalidaprodService.encsalidaSeleccionada[this.indicador].ASI_NRO;
        if (numero !== undefined || numero !== '') {
          await this.invsalidaprodService.eliminarAsientoCont(numero).toPromise()//.subscribe(() => {
          //});
        }
      }
      this.messageService.add({
        key: 'salprod',
        severity: 'success',
        summary: 'Eliminación de Salida',
        detail: 'Se eliminó la salida correctamente'
      });
      this.salidaSeleccionada = {};
      this.invsalidaprodService.date1 = new Date();
      this.invsalidaprodService.hora = new Date();
      this.limpiardatos();
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'salprod',
        severity: 'error',
        summary: 'Eliminicación de Salida',
        detail: mensaje
      });
    }
  }
  async mostrarSalida(valor) {
    let strCondicion = 'KAR_NRO IS NOT NULL AND COM_CODIGO = \'01\'';
    let strTipo = ''

    if (valor === 'Primero') {
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = false;
      this.botonUltimo = false;
      strTipo = 'P';
      this.consultarregistro(strTipo, strCondicion);
    }

    if (valor === 'Ultimo') {
    
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = true;
      this.botonUltimo = true;
      strTipo = 'U';
      this.consultarregistro(strTipo, strCondicion);
    }

    if (valor === 'Anterior') {
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
      
      strTipo = 'A';
      if (this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO.length > 0) {
        strCondicion = strCondicion + ' AND KAR_NRO < \''
          + this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO + '\'';
      } else {
        strTipo = 'P';
      }
      this.consultarregistro(strTipo, strCondicion);
    }

    if (valor === 'Siguiente') {
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
      strTipo = 'S';
      if (this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO.length > 0) {
        strCondicion = strCondicion + ' AND KAR_NRO > \''
          + this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO + '\' AND KAR_NRO LIKE \''
          + this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO.substr(0, 3) + '%\'';
      } else {
        strTipo = 'U';
      }
      this.consultarregistro(strTipo, strCondicion);
    } if (valor === 'Nuevo') {
      this.realizarNuevo();
    } if (valor === 'Cancelar') {
      this.botonNuevo = false;
      console.log(this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO);
      if (this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO === '' || this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO === undefined
        || this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO === null) {
        this.limpiardatos();
      } else {
        this.botonRegresarP = true;
        this.botonNuevoP = false;
        this.botonBorrarP = false;
        await this.mostrarDatos(this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO)
      }
    }
  }
  consultarregistro(strTipo, strCondicion) {
    this.invsalidaprodService.seleccionarRegistro(strTipo, ' ', strCondicion).subscribe((datos: any[]) => {
      if (datos !== null) {
        this.mostrarDatos(datos[0].KAR_NRO);
      }
    }, error1 => {
      const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: 'salprod',
        severity: 'error',
        summary: 'Error',
        detail: error
      });
    });
  }
  async mostrarDatos(KAR_NRO) {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.barraBotones2 = false;
    this.barraBotones4 = false;
    this.botonNuevoP = false;
    this.botonBorrarP = false;
    this.botonRegresarP = false;

    let fecha = '';
    let hora = '';
    let resp = []
    resp = await this.invsalidaprodService.getencKardex(KAR_NRO);
    if (resp === null) {
      this.init.disabledtab = false;
      return;
    }
    for (const data of resp) {
      this.invsalidaprodService.encsalidaSeleccionada[this.indicador] = data
      if (this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_FECHA === undefined) {
        return;
      }
      this.invsalidaprodService.date1 = new Date(this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_FECHA);
      this.invsalidaprodService.hora = new Date(this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_FECHA);
      if (this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA.toString().length === 4) {
        const separador = ':';
        const arregloHora = this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA.toString().split(separador);
        console.log(arregloHora);
        for (let i = 0; i < arregloHora.length; i++) {
          if (arregloHora[i].length !== 2) {
            arregloHora[i] = '0' + arregloHora[i];
          }
        }
        const horaDefinitiva = arregloHora.join(':');
        console.log(horaDefinitiva);
        this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA = horaDefinitiva;
      } else if (this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA.toString().length === 3) {
        this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA = '0' + this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA.toString();
        this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA = this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA.toString().substr(0, 2) + '0'
          + this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA.toString().substr(3, 1);
      }
      this.invsalidaprodService.hora.setHours(this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA.substr(0, 2));
      this.invsalidaprodService.hora.setMinutes(this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_HORA.substr(3, 2));
      this.invsalidaprodService.consultarDetKardex(this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO).subscribe((res) => {
        this.invsalidaprodService.detkardexsarray[this.indicador] = res;
        this.invsalidaprodService.detkardexsarray[this.indicador].map((salida) => {
          salida.DETKAR_COSTOUNIT = salida.DETKAR_COSTOUNIT.toFixed(this.decimalesPrecioSal);
          salida.DETKAR_CANTIDAD = salida.DETKAR_CANTIDAD.toFixed(this.decimalesCantidadSal);
          salida.CEN_CODIGO = salida.CEN_CODIGO === null || salida.CEN_CODIGO === undefined ? '' : salida.CEN_CODIGO;//CDPJ
        });
        // colocar en cada articulo su cantidad guardada y existente
        fecha = this.datePipe.transform(this.invsalidaprodService.date1, 'dd/MM/yyyy');
        hora = this.datePipe.transform(this.invsalidaprodService.hora, 'HH:mm');
        for (const i of this.invsalidaprodService.detkardexsarray[this.indicador]) {
          this.invsalidaprodService.getcantidadGrabadaDoc(this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO, i.ART_CODIGO).subscribe((res2) => {
            i.CANTIDAD_GUARDADA = res2[0].CANTGRABADA;
            this.invsalidaprodService.getExistenciaArt('FAC', i.ART_CODIGO, i.BOD_CODIGO, fecha, hora).subscribe((res3) => {
              i.CANTIDAD_DISPONIBLE = res3[0].ARTEXISTENCIA;
            });
          });
        }
        this.invsalidaprodService.calcularTotal();
      });
    }
  }
  async limpiardatos() {
    this.tabla = '';
    this.auxiliar = 0;
    this.busquedaCampos = [];
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.botonPrimero = false;
    this.botonSiguiente = false;
    this.botonAnterior = false;
    this.botonUltimo = false;
    this.barraBotones2 = false;
    this.barraBotones4 = false;
    this.panelSalidas = false;
    //this.panelVarios = false;
    this.botonNuevoP = true;
    this.botonBorrarP = true;
    this.botonRegresarP = true;
    // this.botonNuevoPV = true;
    // this.botonBorrarPV = true;
    // this.botonRegresarPV = true;
    this.displayDialogBus = false;
    this.displayDialogBusFast = false;
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.displayAcciones = false;
    this.displayDialogSeriales = false;
    this.displayDialogLotes = false;
    this.totalSalida = 0;

    this.invsalidaprodService.detkardexsarray[this.indicador] = [];
    this.indice = 0;
    this.invsalidaprodService.date1 = new Date();
    this.invsalidaprodService.hora = new Date();
    this.salidaSeleccionada = {};
    this.inputsboolean = true;
    this.largo = '500';
    this.consulta = '';
  }
  realizarNuevo() {

    this.tabla = '';
    this.auxiliar = 0;
    this.busquedaCampos = [];
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = true;
    this.botonRegresar = true;
    this.botonPrimero = false;
    this.botonSiguiente = false;
    this.botonAnterior = false;
    this.botonUltimo = false;
    this.barraBotones2 = false;
    this.barraBotones4 = false;
    this.panelSalidas = false;
    //this.panelVarios = false;
    this.botonNuevoP = true;
    this.botonBorrarP = true;
    this.botonRegresarP = true;
    // this.botonNuevoPV = true;
    // this.botonBorrarPV = true;
    // this.botonRegresarPV = true;
    this.displayDialogBus = false;
    this.displayDialogBusFast = false;
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.displayAcciones = false;
    this.displayDialogSeriales = false;
    this.displayDialogLotes = false;
    this.totalSalida = 0;
    // this.contadorLineaVar = 0;
    // this.saldoTotalVarios = 0;
    this.invsalidaprodService.detkardexsarray[this.indicador] = [];
    //this.activeIndex = 0;
    // this.invsalidaprodService.detkardexsvararray[this.indicador] = [];
    this.indice = 0;
    // this.artNombre = {};
    // this.checkSaldoI = false;
    // this.opcion = '';
    this.invsalidaprodService.date1 = new Date();
    this.invsalidaprodService.hora = new Date();
    this.salidaSeleccionada = {};
    //this.encsalidaSeleccionada = {};
    this.inputsboolean = true;
    this.largo = '500';
    // this.asiento = '';
    // this.tieneAsiento = false;
    this.consulta = '';
    //this.obtenerEncSalidas();
    //this.contadorAsiento = 0;
    //this.panelVarios = true;
    this.inputsboolean = false;
    this.botonNuevoP = false;
    this.botonBorrarP = false;
    let encSalida: Invencsalida;
    encSalida = {
      KAR_NRO: '',
      KAR_TIPO: 'SA',
      KAR_NROCOMPROBANTE: '',
      COM_CODIGO: '01',
      TIPDIA_CODIGO: 'SA',
      USUIDENTIFICACION: this.usuario.identificacion,
      KAR_NO_KARDEX: '0',
      KAR_SALDOINI: '',
      KAR_ESTADO: '',
      ENCORDPRO_NUMERO: '',
      KAR_COMENTARIO: '',
      KAR_FECHA: new Date()
    };
    // this.encSalidas.push(encSalida);
    this.invsalidaprodService.encsalidaSeleccionada[this.indicador] = encSalida
    this.invsalidaprodService.detkardexsarray[this.indicador] = [];
    // this.salidaService.detkardexsvararray[this.indicador] = [];
    document.getElementById('nroComp').focus();
    this.desactivarBotonesNuevo();
    this.invsalidaprodService.calcularTotal();
    
  }
  desactivarBotonesNuevo() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
    this.botonPrimero = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonUltimo = true;
    this.barraBotones2 = true;
    this.barraBotones4 = false;
  }
  cambio() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
    this.botonUltimo = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonPrimero = true;
    //CDPJ
    this.botonNuevoP = false;
  }
  cambiarFoco(evento) {
    if (evento.target.id === 'nroComp') {
      document.getElementById('comentario').focus();
    } else if (evento.target.id === 'comentario') {
      this.enviarNuevoSalida();
    }
  }
  enviarNuevoSalida() {
    //CDPJ
    this.botonNuevoP = true;
    this.botonRegresarP = false;
    let hora;
    let minutos;
    hora = this.invsalidaprodService.hora.getHours();
    minutos = this.invsalidaprodService.hora.getMinutes();
    if (hora.toString().length === 1) {
      hora = '0' + hora;
    }
    if (minutos.toString().length === 1) {
      minutos = '0' + minutos;
    }
    const hour = hora + ':' + minutos;
    const date = this.datePipe.transform(this.invsalidaprodService.date1, 'dd/MM/yyyy');
    let i = this.invsalidaprodService.detkardexsarray[this.indicador].length + 1;//CDPJ

    const numeroInicial = 1;
    const nuevaSalida = {
      CLAVE: i + this.datePipe.transform(new Date(), 'dd/MM/yyyyHHmmss'),//CDPJ
      // BOD_CODIGO: '',
      ART_CODIGO: '',
      ART_NOMBRE: '',
      KAR_NRO: this.invsalidaprodService.encsalidaSeleccionada[this.indicador].KAR_NRO,
      DETKAR_CANTIDAD: numeroInicial.toFixed(this.decimalesCantidadSal),
      DETKAR_COSTOUNIT: numeroInicial.toFixed(this.decimalesPrecioSal),
      DETKAR_COSTOTOTAL: numeroInicial.toFixed(this.decimalesTotalSal),
      CANTIDAD_DISPONIBLE: 0,
      DETKAR_NRODOC: '',
      CON_CODIGO: '',
      DETKAR_LINEA: 0,
      ENCGRE_CODIGO: '',
      DETGRE_LINEA: '0',
      DETKAR_LOTE: '',
      CLI_CODIGO: '',
      DETKAR_PUNTOS: '0',
      MAEPUN_CODIGO: '',
      DETKAR_CODIGOAUX: '',
      DETKAR_CAJAS: '',
      DETKAR_FRACCIONES: '',
      CANTIDAD_GUARDADA: 0, FECHA: date + '' + hour, HORA: hour,
      CEN_CODIGO: ''//CDPJ

    };
    const indi = this.invsalidaprodService.detkardexsarray[this.indicador].indexOf(this.salidaSeleccionada);
    if (indi === -1) {
      this.invsalidaprodService.detkardexsarray[this.indicador].push(nuevaSalida);
      this.salidaSeleccionada = nuevaSalida;
      this.aggrid.refreshaggrid(this.invsalidaprodService.detkardexsarray[this.indicador], this.defaultColDefSalida.objeto);
      this.invsalidaprodService.calcularTotal();
    } else {
      this.invsalidaprodService.detkardexsarray[this.indicador].splice(indi + 1, 0, nuevaSalida);
      this.salidaSeleccionada = nuevaSalida;
      this.aggrid.refreshaggridindex(this.invsalidaprodService.detkardexsarray[this.indicador], this.defaultColDefSalida.objeto, indi);
      this.invsalidaprodService.calcularTotal();
    }
  }
  seleccionsalida(params) {
    if (params === 'cancelar') {
      //this.enviarCancelarSalida();
      return;
    }
    if (params === 'eliminar') {
      //this.verificarBorrarLinea();
      return;
    }
    if (params === 'nuevo') {
      this.enviarNuevoSalida();
      return;
    }
    this.salidaSeleccionada = params.object;
    //this.salidaService.auxsalto = false;
  }
  manejarSeleccion(opcion) {
    if (this.opcion === 'BUSQUEDA') {
      this.displayDialogBusFast = false;
      this.mostrarDatos(opcion.KAR_NRO);
    }
    if (this.opcion === 'orden') {
      this.invsalidaprodService.encsalidaSeleccionada[this.indicador].ENCORDPRO_NUMERO =opcion.ENCORDPRO_NUMERO
      this.displayDialogBusFast = false;
    }
  }
  busquedaorden() {
    this.opcion = 'orden';
    this.types = [
      { label: 'Orden', value: 'ENCORDPRO_NUMERO' },
      { label: 'Referencia', value: 'ENCORDPRO_REFERENCIA' },
      { label: 'Fecha Emi.', value: 'ENCORDPRO_FECHAEMISION' },
      { label: 'Fecha Inicio', value: 'ENCORDPRO_FECHAINICIO' },
      { label: 'Estado', value: 'ENCORDPRO_ESTADO' }
    ];
    this.busquedaCampos = ['', '', '', '', '', '', '', '', '', ''];
    //this.consulta = '*';
    this.tabla = 'PROD_ENCORDPRO';
    this.where = 'ROWNUM < 50';
    this.busquedaService.busquedaNEW().subscribe((datos: any[]) => {
      this.arregloBusq = datos;
      this.arregloBusq.map((registro) => {
        if (registro.ENCORDPRO_FECHAEMISION !== null) {
          registro.ENCORDPRO_FECHAEMISION = this.datePipe.transform(registro.ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCORDPRO_FECHAEMISION = '';
        }
        if (registro.ENCORDPRO_FECHAINICIO !== null) {
          registro.ENCORDPRO_FECHAINICIO = this.datePipe.transform(registro.ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
        } else {
          registro.ENCORDPRO_FECHAINICIO = '';
        }
      });
      this.displayDialogBusFast = true;
    });
  }
}
