import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { Invsalida } from '../invinterfaces/invsalida';
import { Invencsalida } from '../invinterfaces/invencsalida';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvsalidaprodService {
  public url: string;
  public decimalesPrecioSal: number;
  public decimalesCantidadSal: number;
  public decimalesTotalSal: number;
  public auxsalto;
  public date1: any;
  public hora: any;
  public detkardexsarray: Invsalida[][] = [];
  public encsalidaSeleccionada: Invencsalida[] = []
  public totalSalidaarray: string[] = [];
  public way: string;
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService,
    private init: NuevoComponentService) {
    this.way = sessionStorage.getItem('way');
  }
  erBodCodigo1(strCodigo) {
    return this.http.post(this.way + '/inventario/entradas/76d786b30d94300', {
      elementos: {
        BOD_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ValidaEnSaOrdProd(salida: Invencsalida) {

    return this.http.post<any[]>(this.way + '/inventario/kardexsalidas/20eebafc5636dc0000', {
      elementos: {
        p_prod_numero: salida.ENCORDPRO_NUMERO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  borrarEncSalida(salida: Invencsalida): Observable<any> {

    return this.http.post<any[]>(this.way + '/inventario/kardexsalidas/gmey60v3p7gmdqn', {
      elementos: {
        p_KAR_nro_1: salida.KAR_NRO,
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarAsientoCont(salida): Observable<any> {

    return this.http.post<any[]>(this.way + '/inventario/kardexsalidas/nmmj0hg4se1zahz', {
      elementos: {
        p_ASI_nro: salida,
        p_COM_codigo_2: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  seleccionarRegistro(tipo, cadena, condicion): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/kardexsalidas/38eab992c5d5be000000000', {
      elementos: {
        p_opcion: tipo,
        p_filtro: 'KAR_ORIGEN = \'PROD\' AND KAR_TIPO=\'SA\' ',
        p_condicion: condicion
      }
    }, this.confIniciales.httpOptions());
  }
  consultarDetKardex(codigo): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/inventario/kardexsalidas/9w6rp0a3psq29sw', {
      elementos: {
        p_KAR_nro_1: codigo,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  getcantidadGrabadaDoc(strnroDocumento, strcodArticulo): Observable<any> {
    console.log(strnroDocumento, strcodArticulo);
     return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/180d2zzl3dhh0dv', {
       elementos: {
         nroDocumento:strnroDocumento,
         codArticulo:strcodArticulo, 
       },
     }, this.confIniciales.httpOptions());
   }
   getExistenciaArt(strTipo, strartCod, strbodCod,strfecha,strhora): Observable<any> {
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/ej234mlq0zshp0x', {
      elementos: {
        tipo:strTipo,
        artCod:strartCod,
        bodCod:strbodCod  ,
        fecha:strfecha,
        hora:strhora
      },
    }, this.confIniciales.httpOptions());
  }
  calcularTotal() {
    let dblCostoTotal = 0;
    
    
    for (let fila = 0; fila < this.detkardexsarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      dblCostoTotal = 0;
      dblCostoTotal = this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila].DETKAR_COSTOUNIT * this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila].DETKAR_CANTIDAD;
      this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila].DETKAR_COSTOTOTAL = dblCostoTotal;
    }
    let dblCostoTotalGlobal = 0;
    for (let fila1 = 0; fila1 < this.detkardexsarray[this.init.tabs[this.init.tabindex].indice].length; fila1++) {
      this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_CANTIDAD = Number(this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_CANTIDAD).toFixed(this.decimalesCantidadSal);
      this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOUNIT = Number(this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOUNIT).toFixed(this.decimalesPrecioSal);
      this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL = Number(this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL).toFixed(this.decimalesTotalSal);
      dblCostoTotalGlobal = dblCostoTotalGlobal + Number(this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL);
    }
    this.totalSalidaarray[this.init.tabs[this.init.tabindex].indice] = String(dblCostoTotalGlobal);
    this.totalSalidaarray[this.init.tabs[this.init.tabindex].indice] = Number(this.totalSalidaarray[this.init.tabs[this.init.tabindex].indice]).toFixed(this.decimalesTotalSal);
  }
  getencKardex(karnro): Promise<any> {
    return this.http.post<any>(this.way + '/inventario/kardexsalidas/41dd75d9456db0', {
      elementos: {
        p_opcion: 'R',
        p_filtro: 'KAR_ORIGEN = \'PROD\' AND KAR_TIPO=\'SA\' ',
        p_condicion: 'KAR_nro=\'' + karnro + '\''
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
