import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { ConfirmationService, MessageService } from 'primeng';
import { Router } from '@angular/router';
import { UtilitariosService } from '../advantageservices/utilitarios.service';

@Component({
  selector: 'app-invmaebarra',
  templateUrl: './invmaebarra.component.html',
  styleUrls: ['./invmaebarra.component.css']
})

export class InvmaebarraComponent implements OnInit {
  @Output() signal = new EventEmitter<string>();
  @Input() botonNuevo: boolean;
  @Input() botonGuardar: boolean;
  @Input() botonRegresar: boolean;
  @Input() botonBorrar: boolean;
  @Input() botonBuscar: boolean;
  @Input() botonFolder: boolean;//GSRF
  @Input() botonArchivo: boolean;//GSRF
  @Input() barraBotonesIMP: boolean;//GSRF
  @Input() botonvernofirmados: boolean;//GSRF
  @Input() barraBotonesCRUD: boolean;
  @Input() barraBotonesDesplaz: boolean;
  @Input() barraBotones3: boolean;
  @Input() barraBotonesAsiCon: boolean;
  @Input() barraBotones5: boolean;
  @Input() barraBotones3Alt: boolean;
  @Input() botonUltimo: boolean;
  @Input() botonPrimero: boolean;
  @Input() botonSiguiente: boolean;
  @Input() botonAnterior: boolean;
  @Input() botonAnular: boolean;
  @Input() botonXML: boolean;
  @Input() botonPagoCredito: boolean;
  @Input() botonPago: boolean;
  @Input() botonAsiento: boolean;
  @Input() botonverAsiento: boolean;
  @Input() botonReenviarCorreo: boolean;
  @Input() botonVisto: boolean;
  @Input() barraFactura: boolean;
  @Input() barraPtoVta: boolean;
  @Input() barraDevolucion: boolean;
  @Input() barraOrden: boolean;
  @Input() barraNotasRecepcion: boolean;
  @Input() barraPedido: boolean;
  @Input() barraFacturaPro: boolean;
  @Input() barraGre: boolean;
  @Input() barraCxpPagoDoc: boolean;
  @Input() barraCartera: boolean;
  @Input() barraConEncasil: boolean;
  @Input() barraOrdenProd: boolean;
  @Input() noNuevo: boolean;
  @Input() noEliminar: boolean;
  @Input() barraFormaPago: boolean;
  @Input() noNuevoDVF: boolean;
  @Input() imprimir: boolean;
  @Input() reporte: boolean;
  @Input() check: boolean;
  @Input() barraCashmng: boolean;
  @Input() barraBandejaPago: boolean;
  @Input() botonBinocular: boolean;
  @Input() barraTransferencia: boolean;
  @Input() botonAutorizar: boolean;
  @Input() barraCxpTrnCobro: boolean;
  @Input() kardex: boolean;
  @Input() botonImprimir: boolean;
  @Input() botonReversar: boolean;
  @Input() botonCabInpRent: boolean;
  @Input() botonGenexml: boolean;
  @Input() botonRecImpRen: boolean;
  @Input() botonCamLiq: boolean;
  @Input() botonRevEstLiq: boolean;
  @Input() botonRecargo: boolean;
  @Input() botonUtiProcesar: boolean;
  @Input() btnLiqBenSoc: boolean;
  @Input() btnConLiq: boolean;
  @Input() btnReveEstLiq: boolean;
  @Input() botonBorrAsieCont: boolean;
  @Input() botonGenAsieCont: boolean;
  @Input() botonVisAsieCont: boolean;
  @Input() botonGenArText: boolean;
  @Input() botonAlmacenardoc: boolean;
  @Input() btnRevActFijo: boolean;
  @Input() botonGuardarPermanente: boolean;
  @Input() botonCancelarPermanente:boolean;//CDPJ
  @Input() excel:boolean;//CDPJ
  @Input() botonBuscarPermanente: boolean;
  @Input() barraEnviarroles:boolean//CDPJ
  @Input() enviofrmcorreo:boolean;//CDPJ
  @Input() botonFrmCorreos:boolean//CDPJ
  @Input() botonEditDatosCli:boolean//CDPJ
  @Input() botonFacturaPermanente:boolean;//CDPJ
  @Input() botonactplan:boolean;
  @Input() botonconsolida:boolean;
  @Input() botonImprimirPermanente: boolean;
  @Input() botonEstadoPermanente:boolean;
  @Input() botonCopOrdComPermanente:boolean;
  deshabilitarbotones: boolean;

  constructor(private confIniciales: ConfInicialesService, private confirmationService: ConfirmationService,
    public route: Router, private utilitariosService: UtilitariosService, private messageService: MessageService) {
    this.barraBotonesCRUD = false;
    this.barraBotonesDesplaz = false;
    this.barraBotones3 = false;
    this.barraBotones5 = false;
  }

  ngOnInit() {
    if (this.utilitariosService.getActivacion()) {
      this.deshabilitarbotones = true;
    } else {
      this.deshabilitarbotones = false;
    }
  }

  async enviarSenal(valor) {
    console.log(valor);
    const nta = await this.confIniciales.comprobarSesion();
    if (nta === false) {
      this.confirmNoTieneAcceso();
      return;
    }

    if (valor === 'Primero' || valor === 'Anterior' || valor === 'Siguiente'
      || valor === 'Ultimo' || valor === 'AsientoVer' || valor === 'Buscar'
      || valor === 'Salir') {
      this.signal.emit(valor);
    } else {
      if (this.utilitariosService.getActivacion()) {
        this.signal.emit(valor);
      } else {
        this.messageService.add({
          key: 'barra',
          severity: 'error',
          summary: 'Error',
          detail: 'No Activado!'
        });
      }
    }
  }

  confirmNoTieneAcceso() {
    this.confirmationService.confirm({
      message: 'Su sesión fue cerrada por ' + this.confIniciales.usuario.nombre + ' ' +
        this.confIniciales.usuario.apellido,
      header: 'Notificación',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmbarra',
      acceptLabel: 'Aceptar',
      rejectVisible: false,
      accept: () => {
        this.route.navigate(['login']);
      }
    });
  }

}
