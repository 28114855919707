import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { ConencasilService } from '../conservicios/conencasil.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';
import { Usuario } from '../../usuario';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { Condetasil } from '../coninterfaces/condetasil';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { VenencfacService } from '../../ven/venservicios/venencfac.service';
import { BantrnbaneditService } from '../../ban/banservicios/bantrnbanedit.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-conencasil',
  templateUrl: './conencasil.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ConencasilComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  @Input() NumAsientoBan: string;
  @Input() abiertoDesde: string;
  @Output() signalSalir = new EventEmitter<boolean>();

  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  btnAnular: boolean;

  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  btnOrganizarGrid: boolean;
  btnCXCGrid: boolean;
  btnTransBanGrid: boolean;
  btnDistribCCGrid: boolean;

  opcion: string;

  largo: string;
  choosedColorSB: string;
  choosedColorG: string;
  choosedColorGb: string;

  permisosRegistrosCont: SegMaePermiso;
  opcionesDiario: SelectItem[];
  cmbTipoDiarios: any[];
  boolCmbTipDia = false;
  strTipoDiario: any;

  intCOLASICOM = 0;
  intPROGDETAS = 0;
  intCONEXPED = 0;
  intOBSASIDET = 0;
  intNODPDNC = 0;
  intCONTIPCI = 0;

  strContrlInsLineaUno = '';
  strCodigoAsiento = '';

  detalleConEncasilSel: Condetasil;

  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  indice: number;
  public frameworkComponents;
  rowStyle;
  defaultConEncasilDet;
  rowSelection = 'multiple';

  displayAcciones: boolean;
  claveDialog: boolean;
  claveAuth: string;
  accionClave: string;

  displayDialogBanTrnBan: boolean;

  banCodigoTrnBan: string;
  fechaEmisionTrnBan: string;
  origenTrnBan: string;
  tipoTrnBan: string;
  anuladoTrnBan: number;
  trnbanNumeroTrnBan: string;
  referenciaTrnBan: string;
  conceptoTrnBan: string;
  importeTrnBan: string;
  beneficiarioTrnBan: string;
  nroDocumentoTrnBan: string;
  conciliaTrnBan: number;
  fechaVencimientoTrnBan: string;
  asiNroTrnBan: string;
  numeroConciliacionTrnBan: string;
  regContableTrnBan: boolean;
  asientoTrnBan: boolean;
  tipoAccionTrnBan: string;

  displayCentroCostos: boolean;

  strAsiNroDCC: string;
  intDetasiNroFilaDCC: number;
  strCodigoDCC: string;
  strDescripcionDCC: string;
  dblValorDCC: string;
  strTipoDetDCC: string;

  numDecTotalDCC: number;

  displayDialogReportes: boolean;
  auxreporte: number = 0;
  strNombreForma: string;
  spin = false;

  displayDialogDocumentos = false;

  columnDefsConEncasilDet = [
    {
      headerName: 'Cuenta Cont', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 110, onCellDoubleClicked: (params) => {
        this.abrirMoviBancos(params.data);
      }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 30,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Referencia', field: 'DETASI_REFERENCIA', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: false
        }
      }
    },
    {
      headerName: 'Concepto Contable ', field: 'DETASI_CONCEPTO', cellEditor: 'cellPrueba', editable: true
      , width: 250,
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'DETASI_CONCEPTOPRY', cellEditor: 'cellPrueba', editable: true, width: 200,
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 80,

        }
      }
    },

    // {
    //   headerName: 'Programa', field: 'PROG_CODIGO', cellEditor: 'cellPrueba', editable: true, width: 100,
    //   cellEditorParams:
    //     {
    //       values: {
    //         mayusculas: true,
    //         numeros: true,
    //         alphabetic: true,
    //         saltoslinea: 1,
    //         newrow: false,
    //         numerodecimales: 0,
    //         obligatorio: false,
    //         fecha: false,
    //         tamanocelda: 10,
    //         opcionbuscar: true,
    //         tienecalculos: true
    //       }
    //     }
    // },

    {
      headerName: 'Centro C', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Debe',
      field: 'DETASI_DEBE',
      cellEditor: 'cellPrueba',
      width: 100,
      cellStyle: { textAlign: 'right' },
      editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Haber',
      field: 'DETASI_HABER',
      width: 100,
      cellEditor: 'cellPrueba',
      cellStyle: { textAlign: 'right' },
      editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Tipo', field: 'TRNBAN_TIPO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
        }
      }
    },
    {
      headerName: 'Tip. Gasto',
      field: 'TIPGAS_CODIGO',
      cellEditor: 'cellPrueba',
      editable: true,
      width: 100,
      cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          numeros: true,
          mayusculas: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 2,

        }
      }
    }
  ];

  constructor(public conencasilService: ConencasilService, private auditoriaGralService: AuditoriagralService,
    private permisosService: PermisosService, private message: MessageService, public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private auditoriagral: AuditoriagralService,
    private confirmationService: ConfirmationService, private confIniciales: ConfInicialesService,
    private init: NuevoComponentService, public encfacService: VenencfacService,
    public bantrnbaneditService: BantrnbaneditService, private utilitariosService: UtilitariosService) {
    this.agTable();
    this.opcionesDiario = [];
    this.conencasilService.encabezadoEncasilSelect = {};
    this.conencasilService.detencasil = [];
    this.detalleConEncasilSel = {};
    // this.usuario.loadStorage();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.rowStyle = (params) => {

      if (params.data.RETENCION_CODIGO !== '' && params.data.RETENCION_CODIGO !== null) {
        return { background: 'rgb(0,255,255)' };
      }
      if (params.data.ENCFAC_NUMERO !== '' && params.data.ENCFAC_NUMERO !== null) {
        return { background: 'rgb(128,255,128)' };
      }
      if (params.data.BAN_CODIGO !== '' && params.data.BAN_CODIGO !== null &&
        (params.data.ENCFAC_NUMERO === '' || params.data.ENCFAC_NUMERO === null)) {
        return { background: 'rgb(255,128,255)' };
      }
      if (params.data.DEPOSITADO_CONCILIADO === 1) {
        return { background: 'rgb(80,240,203)' };
      }
    };

    this.defaultConEncasilDet = {
      editable: true,
      width: 100,
      objeto: 'conencasil',
      resizable: true
    };
  }

  async ngOnInit() {

    this.conencasilService.refrescarURL();
    this.encfacService.refrescarURL();
    this.displayDialogBanTrnBan = false;
    this.displayDialogBus = false;
    this.displayAcciones = false;
    this.claveDialog = false;
    this.claveAuth = '';
    this.arregloBus = [];
    this.opcion = '';
    this.indice = 0;
    this.conencasilService.encabezadoEncasilSelect = {};
    this.detalleConEncasilSel = {};
    this.accionClave = '';

    this.displayCentroCostos = false;

    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = true;
    this.btnCancelarGrid = true;
    this.btnBorrarGrid = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
    this.btnAnular = true;

    this.btnOrganizarGrid = false;
    this.btnCXCGrid = false;
    this.btnTransBanGrid = false;
    this.btnDistribCCGrid = false;

    this.largo = '-3000';
    this.choosedColorSB = '#ff80ff';
    this.choosedColorG = '#00FF00';
    this.choosedColorGb = '#b0ffb0';
    this.conencasilService.dblSaldoAsiento=0

    this.strCodigoAsiento = this.NumAsientoBan;

    if (this.strCodigoAsiento !== undefined) {
      this.btnBuscar = true;
      this.mostrarDatos(this.strCodigoAsiento);
    } else {
      this.strCodigoAsiento = '';


      this.conencasilService.hmpConfiguracion = await this.auditoriaGralService.obtenerConfiguracionProm();
      this.listarConfiguraciones();
      await this.despliegaTiposDiarios('');

      // setTimeout(() => {
      //   this.listarConfiguraciones();
      // }, 1500);

      this.permisosService.getListaPermisos('CON', '6', '32').subscribe((permiso) => {
        this.permisosRegistrosCont = permiso[0];
      });

      // this.listarConfiguraciones();


      this.conencasilService.encabezadoEncasilSelect.ASI_FECHA = new Date();
      this.conencasilService.encabezadoEncasilSelect.ASI_NRO = '';

      // this.strCodigoAsiento = this.NumAsientoBan;


      /*setTimeout(() => {
        if (this.strCodigoAsiento !== '' && this.strCodigoAsiento !== null && this.strCodigoAsiento !== undefined) {
          this.btnBuscar = true;
          this.mostrarDatos(this.strCodigoAsiento);
        } else {
          this.strCodigoAsiento = '';
        }
      }, 1500); */

    }
    this.confIniciales.getDecimales('frmCOM_ENCFACPRO').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'TOTAL' && decimales.NUMDEC_TIPDOC === 'detfacpro') {//antes DetFacPro
            this.numDecTotalDCC = Number(decimales.NUMDEC_NUMDEC);
          }
        });
      }
    });

  }

  async manejarSenales(valor) {

    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir' 
      && valor !== 'facturasAbonadas') {
      // Verifica el periodo contable
      const dia = this.conencasilService.encabezadoEncasilSelect.ASI_FECHA.getDate();
    const mes = this.conencasilService.encabezadoEncasilSelect.ASI_FECHA.getMonth();
    const anio = this.conencasilService.encabezadoEncasilSelect.ASI_FECHA.getFullYear();


      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.mensajeAlerta('info', 'Información', 'Error, la fecha debe de estar dentro del período contable');
        return;
      }
    }



    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }

    if (valor === 'Primero') {
      //this.seleccionarRegistro('P');
      //CDPJ
      if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '' && Number(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO) !== 1) {
        console.log(Number(this.conencasilService.dblSaldoAsiento))
        if (Number(this.conencasilService.dblSaldoAsiento) !== 0) {
          this.mensajeAlerta('info', 'Información', 'El Asiento no esta cuadrado, por favor verifique los Débitos y los Créditos');

        } else {
          this.seleccionarRegistro('P');
        }
      } else {
        this.seleccionarRegistro('P');
      }
      //CDPJ
    }
    if (valor === 'Siguiente') {
      //this.seleccionarRegistro('S');
      if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '' && Number(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO) !== 1) {
        if (Number(this.conencasilService.dblSaldoAsiento) !== 0) {
          this.mensajeAlerta('info', 'Información', 'El Asiento no esta cuadrado, por favor verifique los Débitos y los Créditos');
          return;
        } else {
          this.seleccionarRegistro('S');
        }
      } else {
        this.seleccionarRegistro('S');
      }
    }
    if (valor === 'Anterior') {
      //this.seleccionarRegistro('A');
      //CDPJ
      if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '' && Number(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO) !== 1) {
        if (Number(this.conencasilService.dblSaldoAsiento) !== 0) {
          this.mensajeAlerta('info', 'Información', 'El Asiento no esta cuadrado, por favor verifique los Débitos y los Créditos');
          return;
        } else {
          this.seleccionarRegistro('A');
        }
      } else {
        this.seleccionarRegistro('A');
      }
      //CDPJ
    }
    if (valor === 'Ultimo') {
      //this.seleccionarRegistro('U');
      //CDPJ
      if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '' && Number(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO) !== 1) {
        if (Number(this.conencasilService.dblSaldoAsiento) !== 0) {
          this.mensajeAlerta('info', 'Información', 'El Asiento no esta cuadrado, por favor verifique los Débitos y los Créditos');
          return;
        } else {
          this.seleccionarRegistro('U');
        }
      } else {
        this.seleccionarRegistro('U');
      }
      //CDPJ
    }
    if (valor === 'Nuevo') {
      //this.nuevoRegistro('N');
      //CDPJ
      if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '' && Number(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO) !== 1) {
        if (Number(this.conencasilService.dblSaldoAsiento) !== 0) {
          this.mensajeAlerta('info', 'Información', 'El Asiento no esta cuadrado, por favor verifique los Débitos y los Créditos');
          return;
        } else {
          this.nuevoRegistro('N');
        }
      } else {
        this.nuevoRegistro('N');
      }
      //CDPJ
    }
    if (valor === 'Borrar') {
      this.eliminarAsiento(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
    }
    if (valor === 'Guardar') {
      this.guardar();
    }
    if (valor === 'Cancelar') {
      //this.cancelarRegistro();
      //CDPJ
      if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '' && Number(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO) !== 1
        && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== null
        && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== undefined
        && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== '') {
        if (Number(this.conencasilService.dblSaldoAsiento) !== 0) {
          this.mensajeAlerta('info', 'Información', 'El Asiento no esta cuadrado, por favor verifique los Débitos y los Créditos');
          return;
        } else {
          this.cancelarRegistro();
        }
      } else {
        this.cancelarRegistro();
      }
      //CDPJ
    }
    if (valor === 'Buscar') {
      //this.buscarRegistro();
      //CDPJ
      if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '' && Number(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO) !== 1
        && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== null
        && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== undefined
        && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== '') {
        if (Number(this.conencasilService.dblSaldoAsiento) !== 0) {
          this.mensajeAlerta('info', 'Información', 'El Asiento no esta cuadrado, por favor verifique los Débitos y los Créditos');
          return;
        } else {
          this.buscarRegistro();
        }
      } else {
        this.buscarRegistro();
      }
      //CDPJ
    }
    if (valor === 'Anular') {
      this.anularAsiento(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
    }

    if (valor === 'Imprimir') {
      this.strNombreForma = 'frmCON_ENCASI_L';
      this.auxreporte++;
      this.displayDialogReportes = true;
    }

    if (valor === 'Almacenardoc') {
      if (this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.message.add({
            key: 'encasil',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }

    /* if (valor === 'Salir') {
       if (this.abiertoDesde === 'banTrnBanEdit') {
         this.signalSalir.emit(true);
       } else if (this.abiertoDesde === 'banTrnBan') {
         this.signalSalir.emit(true);
       } else {
         this.init.tabs.splice(this.init.tabindex, 1);
       }
       
     }*/

    if (valor === 'Salir') {
      //CDPJ
      if (this.abiertoDesde === undefined) {
        if (this.btnGuardar === false) {
          this.confirmationService.confirm({
            message: 'Existen cambios, desea guardarlos?',
            header: 'Salir',
            icon: 'pi pi-exclamation-triangle',
            key: 'encasilConf',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
              this.guardar();
            },
            reject: () => {
              if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '' && Number(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO) !== 1
                && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== null
                && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== undefined
                && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== '') {
                if (Number(this.conencasilService.dblSaldoAsiento) !== 0) {
                  this.mensajeAlerta('info', 'Información', 'El Asiento no esta cuadrado, por favor verifique los Débitos y los Créditos');
                  return;
                } else {
                  this.init.tabs.splice(this.init.tabindex, 1);
                }
              } else {
                this.init.tabs.splice(this.init.tabindex, 1);
              }

            }
          });
        } else {
          if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '' && Number(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO) !== 1
            && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== null
            && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== undefined
            && this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== '') {
            if (Number(this.conencasilService.dblSaldoAsiento) !== 0) {
              this.mensajeAlerta('info', 'Información', 'El Asiento no esta cuadrado, por favor verifique los Débitos y los Créditos');
              return;
            } else {
              this.init.tabs.splice(this.init.tabindex, 1);
            }
          } else {
            this.init.tabs.splice(this.init.tabindex, 1);
          }
        }
      } else {
        this.signalSalir.emit(true);
      }
      //CDPJ
      // if (this.abiertoDesde === undefined) {
      //   if (this.btnGuardar === false) {
      //     this.confirmationService.confirm({
      //       message: 'Existen cambios, desea guardarlos?',
      //       header: 'Salir',
      //       icon: 'pi pi-exclamation-triangle',
      //       key: 'encasilConf',
      //       acceptLabel: 'Si',
      //       rejectLabel: 'No',
      //       accept: () => {
      //         this.guardar();
      //       },
      //       reject: () => {
      //         this.init.tabs.splice(this.init.tabindex, 1);
      //       }
      //     });
      //   } else {
      //     this.init.tabs.splice(this.init.tabindex, 1);
      //   }
      // } else {
      //   this.signalSalir.emit(true);
      // }
    }

  }

  busqTipComp($event) {

  }

  cambioDropdown(event) {

    // this.conencasilService.encabezadoEncasilSelect.TIPDIA_CODIGO = event.value.codigo;
    // this.strTipoDiario = event.value.name;
  }

  cambio(event) {
    if (this.conencasilService.encabezadoEncasilSelect !== undefined) {
      this.btnGuardar = false;
      this.btnRegresar = false;
      this.btnNuevo = true;
      // this.btnNuevoGrid = false;
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
    }
  }

  listarConfiguraciones() {
    this.intCOLASICOM = Number(this.conencasilService.getConfiguraciones('COLASICOM'));
    this.intPROGDETAS = Number(this.conencasilService.getConfiguraciones('PROGDETAS'));
    this.intCONEXPED = Number(this.conencasilService.getConfiguraciones('CONEXPED'));
    this.intCONTIPCI = Number(this.conencasilService.getConfiguraciones('CONTIPCI'));
    this.intNODPDNC = Number(this.conencasilService.getConfiguraciones('NODPDNC'));
    this.intOBSASIDET = Number(this.conencasilService.getConfiguraciones('OBSASIDET'));

    // if (this.intPROGDETAS === 0) {
    //   this.aggrid.hideColumn('PROG_CODIGO', false);
    // }

  }

  async despliegaTiposDiarios(strTipo) {
    const sentencia1 = 'SELECT * FROM CON_MAETIPDIA order by TIPDIA_NOMBRE';
    const sentencia2 = 'SELECT * FROM CON_MAETIPDIA order by TIPDIA_CODIGO';
    if (this.intCONTIPCI === 1) {  // Si utiliza solo registros contables los Comprobante de ingreso

      const tipdiario = await this.conencasilService.getTiposdeDiariosProm();
      this.cmbTipoDiarios = [];
      for (const a of tipdiario) {
        this.cmbTipoDiarios.push({ codigo: a.TIPDIA_CODIGO, name: a.TIPDIA_CODIGO + ' - ' + a.TIPDIA_NOMBRE });

      }
      this.strTipoDiario = this.cmbTipoDiarios[0];
      /*this.conencasilService.getTiposdeDiarios(sentencia1).subscribe((tipdiario) => {
        this.cmbTipoDiarios = [];
        for (const a of tipdiario) {
          this.cmbTipoDiarios.push({codigo: a.TIPDIA_CODIGO, name: a.TIPDIA_CODIGO + ' - ' + a.TIPDIA_NOMBRE});
          
        }
        this.strTipoDiario = this.cmbTipoDiarios[0];
      });*/
      return;
    }
    const tipdiario = await this.conencasilService.getTiposdeDiariosProm1();
    this.cmbTipoDiarios = [];
    if (strTipo === 'N') {  // Nuevo
      for (const a of tipdiario) {
        if (a.TIPDIA_CODIGO !== 'CI') {
          this.cmbTipoDiarios.push({ codigo: a.TIPDIA_CODIGO, name: a.TIPDIA_CODIGO + ' - ' + a.TIPDIA_NOMBRE });

        }
      }
    } else {
      for (const a of tipdiario) {
        this.cmbTipoDiarios.push({ codigo: a.TIPDIA_CODIGO, name: a.TIPDIA_CODIGO + ' - ' + a.TIPDIA_NOMBRE });

      }


    }
    this.strTipoDiario = this.cmbTipoDiarios[0];

    /*this.conencasilService.getTiposdeDiarios(sentencia2).subscribe((tipdiario) => {
      this.cmbTipoDiarios = [];
      if (strTipo === 'N') {  // Nuevo
        for (const a of tipdiario) {
          if (a.TIPDIA_CODIGO !== 'CI') {
            this.cmbTipoDiarios.push({codigo: a.TIPDIA_CODIGO, name: a.TIPDIA_CODIGO + ' - ' + a.TIPDIA_NOMBRE});
            
          }
        }
      } else {
        for (const a of tipdiario) {
          this.cmbTipoDiarios.push({codigo: a.TIPDIA_CODIGO, name: a.TIPDIA_CODIGO + ' - ' + a.TIPDIA_NOMBRE});
          
        }
        
        
      }
      this.strTipoDiario = this.cmbTipoDiarios[0];
    }); */
  }

  nuevoRegistro(parametro) {

    if (parametro === 'N') {

      this.activaBotones('N', true, false, false,
        true, true, true, true);
      this.boolCmbTipDia = false;
      this.indice = -1;
      this.conencasilService.encabezadoEncasilSelect = {};
      this.conencasilService.encabezadoEncasilSelect.ASI_FECHA = new Date();
      this.conencasilService.detencasil = [];
      this.conencasilService.encabezadoEncasilSelect.ESTADO = '';
      this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO = 0;
      this.conencasilService.encabezadoEncasilSelect.ASI_DESCRIPCION = '';
      this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE = '';
      this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN = '';
      this.conencasilService.encabezadoEncasilSelect.ASI_NRO = '';
      document.getElementById('nrocomprobante').focus();
      this.activarBotonesDetalle(false, false, true, false);
      this.despliegaTiposDiarios('N');
    }

    this.detalleConEncasilSel.DETASI_CONCEPTO = '';
    this.detalleConEncasilSel.DETASI_CONCEPTOPRY = '';
    this.detalleConEncasilSel.CON_NOMBRE = '';

  }

  seleccionarRegistro(strTipo) {
    if (this.conencasilService.encabezadoEncasilSelect.TIPDIA_CODIGO === undefined) {
      this.conencasilService.encabezadoEncasilSelect.TIPDIA_CODIGO = this.cmbTipoDiarios[0].codigo;
    }
    if (this.permisosRegistrosCont != null) {
      if (this.permisosRegistrosCont.PERTODO === 0) {
        if (this.permisosRegistrosCont.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    this.conencasilService.navegarRegistro(strTipo, this.conencasilService.encabezadoEncasilSelect).subscribe((res) => {
      let strAsiNro = '';
      if (res !== null) {
        if (res.length > 0) {
          strAsiNro = res[0].ASI_NRO;
          if (strAsiNro !== '') {
            this.mostrarDatos(strAsiNro);
            // mostrarComprobanteDeCompIng();
            // deplegarComprobantesIngreso(strAsiNro);
          }
        }
      }

    });

  }


  async mostrarDatos(strAsiNro) {
    let value = 0;
    // this.despliegaTiposDiarios('');
    try {

      const enc = await this.conencasilService.getEncConencasilProm(strAsiNro);

      this.conencasilService.encabezadoEncasilSelect.ASI_NRO = enc[0].ASI_NRO;
      this.conencasilService.encabezadoEncasilSelect.ASI_DOCUMENTOCONT = enc[0].ASI_DOCUMENTOCONT;
      this.conencasilService.encabezadoEncasilSelect.ASI_FECHA = new Date(enc[0].ASI_FECHA);
      this.conencasilService.encabezadoEncasilSelect.ASI_DESCRIPCION = enc[0].ASI_DESCRIPCION;
      this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE = enc[0].ASI_COMPROBANTE;
      this.boolCmbTipDia = true;
      this.conencasilService.encabezadoEncasilSelect.TIPDIA_CODIGO = enc[0].TIPDIA_CODIGO;
      this.actualizarTipDiario(enc[0].TIPDIA_CODIGO);
      value = Number(enc[0].ASI_DEBITOS);
      this.conencasilService.encabezadoEncasilSelect.TOTAL_DEBITOS = Number(value).toFixed(this.confIniciales.getNumDecSist());
      value = Number(enc[0].ASI_CREDITOS) * (-1);
      this.conencasilService.encabezadoEncasilSelect.TOTAL_CREDITOS = Number(value).toFixed(this.confIniciales.getNumDecSist());
      this.conencasilService.encabezadoEncasilSelect.SALDO = Number(enc[0].ASI_DEBITOS + enc[0].ASI_CREDITOS).toFixed(this.confIniciales.getNumDecSist());
      this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO = enc[0].ASI_ANULADO;
      if (enc[0].ASI_ANULADO === 1) {
        this.conencasilService.encabezadoEncasilSelect.ESTADO = 'ANULADO';
      } else {
        this.conencasilService.encabezadoEncasilSelect.ESTADO = '';
      }

      this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN = enc[0].ASI_ORIGEN === null ? '' : enc[0].ASI_ORIGEN;

      // if (enc[0].ASI_ORIGEN === null) {
      //   this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN = '';
      // } else {
      //   this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN = enc[0].ASI_ORIGEN;
      // }

      this.boolCmbTipDia = true;
      console.log(this.strCodigoAsiento);
      console.log(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO);
      console.log(this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN);
      if (this.strCodigoAsiento !== '') {
        this.activaBotones('NoModif', true, true, true, true, true, true, true);
        this.activarBotonesDetalle(true, true, true, true);
      } else {
        if (this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO === 0) {

          this.activaBotones('NAV', false, true, false, false, false, false, false);
        } else {
          this.activaBotones('NoModif', false, true, true, false, false, false, false);
          this.activarBotonesDetalle(true, true, true, true);
        }
        if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN !== '') {
          this.activaBotones('NoModif', false, true, false, false, false,
            false, false);
          this.activarBotonesDetalle(true, true, true, true);
        } else {
          this.activarBotonesDetalle(false, false, true, false);
        }

      }
      this.conencasilService.comas();
      this.mostrarDetalle(enc[0].ASI_NRO, enc[0].TIPDIA_CODIGO, this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  async actualizarTipDiario(strTipDiaCodigo) {
    if (this.cmbTipoDiarios === undefined) {
      await this.despliegaTiposDiarios('');
    }
    for (const a of this.cmbTipoDiarios) {
      if (a.codigo === strTipDiaCodigo) {
        this.strTipoDiario = { codigo: a.codigo, name: a.name };
      }
    }

  }

  async mostrarDetalle(StrAsiNro, strTipoDiario, strOrigen) {

    let strDetasiReferencia = '';

    /* if (strTipoDiario === 'CE' && strOrigen === 'COM') {
      sentenciaSql = 'select a.*,b.con_centro from con_detasi_l a,con_maecon b where a.con_codigo=b.con_codigo ' +
        'and a.com_codigo=b.com_codigo and  asi_nro= \'' + StrAsiNro + '\' and a.com_codigo= \'01\' order by  detasi_orden asc';
    } else if (strOrigen === '' || strTipoDiario === 'APER') {
      sentenciaSql = 'select a.*,b.con_centro from con_detasi_l a,con_maecon b where a.con_codigo=b.con_codigo ' +
        'and a.com_codigo=b.com_codigo and  asi_nro= \'' + StrAsiNro + '\' and a.com_codigo= \'01\' order by  detasi_nrofila asc';
    } else {
      sentenciaSql = 'select a.*,b.con_centro from con_detasi_l a,con_maecon b where a.con_codigo=b.con_codigo ' +
        'and a.com_codigo=b.com_codigo and  asi_nro= \'' + StrAsiNro + '\' and a.com_codigo= \'01\' order by  detasi_importe desc';
    } */

    try {

      const det = await this.conencasilService.getDetConencasilProm(StrAsiNro, strOrigen);
      console.log(det);
      if (det !== null && det.length > 0) {
        this.conencasilService.detencasil = det;
        let num = 0;
        this.conencasilService.detencasil.map(res => {
          let numLinea = 0;
          numLinea++;
          res.LINEA = numLinea;
          res.TRNBAN_TIPO = res.TRNBAN_TIPO === null ? '' : res.TRNBAN_TIPO;
          if (Number(res.DETASI_IMPORTE) > 0) {

            res.DETASI_DEBE = res.DETASI_IMPORTE;
            res.DETASI_HABER = 0.0;
          } else {
            res.DETASI_DEBE = 0.0;
            res.DETASI_HABER = res.DETASI_IMPORTE * -1;
          }

          if (res.PROG_CODIGO === null) {
            res.PROG_CODIGO = '';
          }

          res.DETASI_DEBE = res.DETASI_DEBE.toFixed(this.confIniciales.getNumDecSist());
          res.DETASI_HABER = res.DETASI_HABER.toFixed(this.confIniciales.getNumDecSist());


          res.DETASI_REFERENCIA = res.DETASI_REFERENCIA === null ? '' : res.DETASI_REFERENCIA;
          res.DETASI_CONCEPTO = res.DETASI_CONCEPTO === null ? '' : res.DETASI_CONCEPTO;
          res.DETASI_CONCEPTOPRY = res.DETASI_CONCEPTOPRY === null ? '' : res.DETASI_CONCEPTOPRY;
          res.BAN_CODIGO=res.BAN_CODIGO === null || res.BAN_CODIGO === undefined?'':res.BAN_CODIGO;//CDPJ

          strDetasiReferencia = res.DETASI_REFERENCIA;
          if (strDetasiReferencia !== '' && this.intNODPDNC === 1 &&
            (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === 'FAC' || this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === 'CXC')
            && (strTipoDiario === 'NC' || strTipoDiario === 'ANCC')) {
            strDetasiReferencia = strDetasiReferencia.substring(1);
            res.DETASI_REFERENCIA = strDetasiReferencia;
          }

          const strDato = res.CON_TIPGAS === null ? '' : res.CON_TIPGAS;

          if (strDato === 'Si') {
            res.BOL_TIPOGASTO = true;
          }

        });

      } else {
        this.detalleConEncasilSel = {};
        this.conencasilService.detencasil = [];
        this.conencasilService.encabezadoEncasilSelect.TOTAL_DEBITOS = Number(0).toFixed(this.confIniciales.getNumDecSist());
        this.conencasilService.encabezadoEncasilSelect.TOTAL_CREDITOS = Number(0).toFixed(this.confIniciales.getNumDecSist());
        this.conencasilService.encabezadoEncasilSelect.SALDO = Number(0).toFixed(this.confIniciales.getNumDecSist());
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }


  activarBotonesDetalle(bolNuevoDetalle, bolEliminarDetalle, bolCancelarDetalle, bolInsertarLinIni) {

    this.btnNuevoGrid = bolNuevoDetalle;
    this.btnBorrarGrid = bolEliminarDetalle;
    this.btnCancelarGrid = bolCancelarDetalle;
    this.btnOrganizarGrid = bolInsertarLinIni;
    if (bolNuevoDetalle === true && bolEliminarDetalle === true && bolCancelarDetalle === true && bolInsertarLinIni === true) {
      this.btnCXCGrid = true;
      this.btnTransBanGrid = true;
    } else {
      this.btnCXCGrid = false;
      this.btnTransBanGrid = false;
    }
  }
  //CDPJ
  distribuisPCC() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }
    this.confirmationService.confirm({
      message: '¿ Está seguro de aplicar la distribución de programas ?',
      header: 'Eliminar Asiento Contable',
      icon: 'pi pi-exclamation-triangle',
      key: 'encasilConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        try {
          if (this.strCodigoAsiento !== null && this.strCodigoAsiento !== undefined && this.strCodigoAsiento !== '') {
            this.conencasilService.aplicardistribucionPCC(this.strCodigoAsiento).subscribe(er => {
              console.log(er);

              this.auditoriagral.registrarAuditoria('CON_DETASI_L', String(this.conencasilService.encabezadoEncasilSelect.ASI_NRO), 'D',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                });
              this.mostrarDatos(this.strCodigoAsiento);
            })

          } else {
            this.mensajeAlerta('error', 'Error', 'El número del asiento está vacío');
          }
        } catch (error) {
          this.mensajeAlerta('error', 'Error', 'Erro al aplicar la distribución ' + error);
        }
      },
      reject: () => {

      }
    });

  }
  //CDPJ

  activaBotones(strTipo, bolNuevo, bolGuardar, bolEliminar, bolInicio, bolAnterior, bolSiguiente, bolUltimo) {

    this.btnNuevo = bolNuevo;
    this.btnGuardar = bolGuardar;
    this.btnBorrar = bolEliminar;
    this.botonPrimero = bolInicio;
    this.botonAnterior = bolAnterior;
    this.botonSiguiente = bolSiguiente;
    this.botonUltimo = bolUltimo;
    if (strTipo === 'NAV') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      this.aggrid.habilitarGrid();
      this.btnAnular = false;
    }
    if (strTipo === 'NoModif') {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      // this.aggrid.bloquearGrid();
      this.btnAnular = true;
      // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      this.btnBorrar = true;
      if (this.abiertoDesde === undefined && Number(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO) === 1) {
        // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
        this.aggrid.bloquearGrid();
      }
    }
    if (strTipo === 'N') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      this.aggrid.habilitarGrid();
      this.btnAnular = false;
    }
    if (strTipo === 'INI') {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      this.aggrid.bloquearGrid();
      // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      this.btnAnular = true;
    }
  }

  cambiarFoco(evento) {

    if (evento.target.id === 'nrocomprobante') {
      document.getElementById('tipoDiarios').focus();
    }
    if (evento.target.id === 'tipoDiarios') {
      document.getElementById('observaciones').focus();
    }
    if (evento.target.id === 'observaciones') {
      this.insertarLineaDetalle();
    }

  }

  insertarLineaDetalle() {

    if (this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE === undefined && this.intCOLASICOM === 0) {
      this.mensajeAlerta('info', 'Información', 'Debe ingresar el Comprobante');
      return;
    }

    if (this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE.trim() === '' && this.intCOLASICOM === 0) {
      this.mensajeAlerta('info', 'Información', 'Debe ingresar el Comprobante');
      return;
    }

    if (this.strTipoDiario !== '' && this.conencasilService.encabezadoEncasilSelect.ASI_DESCRIPCION !== '') {
      this.nuevoElemento();
    } else {
      this.mensajeAlerta('info', 'Información', 'Debe ingresar datos en Tipo de Comprobante y Observaciones');
    }


  }

  nuevoElemento() {
    const linea = this.conencasilService.detencasil.length;
    const nuevodetConEncasil = {
      ASI_FECHA: new Date(),
      ASI_NRO: '',
      BAN_CODIGO: '',
      CEN_CODIGO: '',
      CENSUB_CODIGO: '',
      COM_CODIGO: '01',
      CON_CODIGO: '',
      CON_NOMBRE: '',
      CXCTRNCOBRO_CLICODIGO: '',
      CXCTRNCOBRO_COMCODIGO: '',
      CXCTRNCOBRO_NRODOC: '',
      DETASI_CONCEPTO: '',
      DETASI_CONCEPTOPRY: '',
      DETASI_HORA: '',
      DETASI_DEBE: Number(0).toFixed(this.confIniciales.getNumDecSist()),
      DETASI_HABER: Number(0).toFixed(this.confIniciales.getNumDecSist()),
      DETASI_ORDEN: 0,
      DETASI_REFERENCIA: '',
      ENCFAC_CLICODIGO: '',
      ENCFAC_COMCODIGO: '',
      ENCFAC_NUMERO: '',
      TRNBAN_BENEFICIARIO: '',
      TRNBAN_NUMERO: '',
      TRNBAN_TIPO: '',
      TRNRETENCION_NRO: '',
      RETENCION_CODIGO: '',
      DEPOSITADO_CONCILIADO: '',
      PROG_CODIGO: '',
      TIPGAS_CODIGO: '',
      DETASI_NROFILA: 0,
      LINEA: linea + 1,
      CON_TIPGAS: '',
      DETASI_IMPORTE: 0,
      DETASI_TIPOREG: ''
    };

    if (this.intOBSASIDET === 1) {
      nuevodetConEncasil.DETASI_CONCEPTO = this.conencasilService.encabezadoEncasilSelect.ASI_DESCRIPCION;
    } else {
      nuevodetConEncasil.DETASI_CONCEPTO = '';
    }

    if (this.intOBSASIDET === 1) {
      nuevodetConEncasil.DETASI_CONCEPTOPRY = this.conencasilService.encabezadoEncasilSelect.ASI_DESCRIPCION;
    } else {
      nuevodetConEncasil.DETASI_CONCEPTOPRY = '';
    }

    const indi = this.conencasilService.detencasil.indexOf(this.detalleConEncasilSel);
    if (indi === -1) {
      this.conencasilService.detencasil.push(nuevodetConEncasil);
      this.detalleConEncasilSel = this.conencasilService.detencasil[this.conencasilService.detencasil.length - 1];
      this.aggrid.refreshaggrid(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultConEncasilDet.objeto].setFocusedCell(
        this.conencasilService.detencasil.length - 1, 'CON_CODIGO');
    } else {
      // this.detalleConEncasilSel = nuevodetConEncasil;
      // this.conencasilService.detencasil.splice(indi + 1, 0, nuevodetConEncasil);
      //CDPJ
      this.conencasilService.detencasil.push(nuevodetConEncasil);
      this.detalleConEncasilSel = this.conencasilService.detencasil[this.conencasilService.detencasil.length - 1];
      //CDPJ
      this.aggrid.refreshaggridindex(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultConEncasilDet.objeto].setFocusedCell(
        indi + 1, 'CON_CODIGO');
    }

    /* const indi = this.conencasilService.detencasil.indexOf(this.detalleConEncasilSel);
     this.conencasilService.detencasil.push(nuevodetConEncasil);
     this.aggrid.refreshaggridindex(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto, indi);
     
     this.aggrid.gridApi.setFocusedCell(indi + 1, 'CON_CODIGO');
 
     this.detalleConEncasilSel = nuevodetConEncasil; */


    // ACTIVAR BOTONES
    this.activaBotones('N', true, false, true, true, true, true, true);
    //this.activarBotonesDetalle(true, true, false, true);
    this.strContrlInsLineaUno = '';


  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'encasil',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  seleccionDetEncasil(valor) {

    if (valor === 'nuevo') {
      if (this.conencasilService.encabezadoEncasilSelect.ASI_DESCRIPCION !== '') {
        this.insertarLineaDetalle();
      } else {
        this.mensajeAlerta('info', 'Información', 'Por favor ingrese la observación del asiento');
      }

    }
    if (valor === 'eliminar') {
      this.eliminarRegistro();
    }
    if (valor === 'cancelar') {
      this.cancelarAcciones();
    }

    if (valor === 'btnDesplCXC') {
      if(this.detalleConEncasilSel !== null && this.detalleConEncasilSel !== undefined){
        if(this.detalleConEncasilSel.CON_CODIGO === ''){
          this.listarFacPendientes();
        }else{
          this.mensajeAlerta('info', 'Información', 'Es necesario seleccionar una línea vacía');
        }
      }else{
        this.mensajeAlerta('info', 'Información', 'Es necesario seleccionar una línea del detalle');
      }
      
    }

    if (valor === 'btnDistribCC') {
      // if(this.detalleConEncasilSel !== null && this.detalleConEncasilSel !== undefined){
      //   if(this.detalleConEncasilSel.CON_CODIGO === ''){
      //     this.abrirDistribucionCC();
      //   }else{
      //     this.mensajeAlerta('info', 'Información', 'Es necesario seleccionar una línea vacía');
      //   }
      // }else{
      //   this.mensajeAlerta('info', 'Información', 'Es necesario seleccionar una línea del detalle');
      // }
      this.abrirDistribucionCC();
    }

    if (valor === 'btnTransBanc') {
      // console.log(this.detalleConEncasilSel);
      // const item = this.detalleConEncasilSel = this.conencasilService.detencasil[this.conencasilService.detencasil.length - 1];

      // if (item.CON_CODIGO === '') {
        
      // } else {
      //   this.mensajeAlerta('info', 'Información', 'Error, Selecciona una línea en blanco por favor');
      // }
      if(this.detalleConEncasilSel !== null && this.detalleConEncasilSel !== undefined){
        if(this.detalleConEncasilSel.CON_CODIGO === ''){
          this.listarTransBancariasPen();
        }else{
          this.mensajeAlerta('info', 'Información', 'Es necesario seleccionar una línea vacía');
        }
      }else{
        this.mensajeAlerta('info', 'Información', 'Es necesario seleccionar una línea del detalle');
      }
    }

    if (valor.object !== undefined) {
      console.log(valor.object);
      this.detalleConEncasilSel = valor.object;
    }
    //   if (this.aggrid.gridApi.getFocusedCell().column.colId === 'DETASI_HABER') {

    //   }
    // } else {
    //   if (this.aggrid.gridApi.getFocusedCell() !== null) {
    //     if (this.aggrid.gridApi.getFocusedCell().column.colId === 'CON_CODIGO') {
    //       const indi = this.conencasilService.detencasil.indexOf(this.detalleConEncasilSel);
    //       this.aggrid.refreshaggridindex(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto, indi);
    //       this.aggrid.gridApi.setFocusedCell(indi + 1, 'CON_CODIGO');
    //     }
    //   }
    //   // this.detalleConEncasilSel = this.conencasilService.detencasil[this.conencasilService.detencasil.length - 1];
    // }

  }

  buscarRegistro() {
    this.opcion = 'BUSCAR';
    this.types = [
      { label: 'ASINRO', value: 'ASI_NRO' },
      { label: 'FECHA', value: 'ASI_FECHA' },
      { label: 'TIPO', value: 'TIPDIA_CODIGO' },
      { label: 'ORIGEN', value: 'ASI_ORIGEN' },
      { label: 'COMPROBANTE', value: 'ASI_COMPROBANTE' },
      { label: 'ESTADO', value: 'ASI_ANULADO' },
      { label: 'DESCRIPCIÓN', value: 'ASI_DESCRIPCION' },
      { label: 'BENEFICIARIO', value: 'TRNBAN_BENEFICIARIO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.tabla = 'CON_ENCASI_L';
    this.where = ' ASI_NRO IS NOT NULL ORDER BY ASI_FECHA DESC,ASI_NRO DESC';
    this.busquedaSer.busquedaEncAsi().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((dato) => {
        if (dato.ASI_FECHA !== null) {
          dato.ASI_FECHA = this.datePipe.transform(dato.ASI_FECHA, 'dd/MM/yyyy');
        } else {
          dato.ASI_FECHA = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  manejarSeleccion(valor) {

    if (this.opcion === 'BUSCAR') {
      this.seleccionEncAsi(valor);

    }

    if (this.opcion === 'BuscarFactP') {
      this.seleccionFactPend(valor);
    }
    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }

  seleccionEncAsi(valor) {
    let value = 0;
    this.conencasilService.encabezadoEncasilSelect = {};
    this.conencasilService.encabezadoEncasilSelect.ASI_NRO = valor.ASI_NRO;
    this.conencasilService.encabezadoEncasilSelect.ASI_DOCUMENTOCONT = valor.ASI_DOCUMENTOCONT;
    const fecha = String(valor.ASI_FECHA).split('/');
    this.conencasilService.encabezadoEncasilSelect.ASI_FECHA = new Date(Number(fecha[2]), Number(fecha[1]) - 1, Number(fecha[0]));
    this.conencasilService.encabezadoEncasilSelect.ASI_DESCRIPCION = valor.ASI_DESCRIPCION;
    this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE = valor.ASI_COMPROBANTE;
    this.boolCmbTipDia = true;
    this.conencasilService.encabezadoEncasilSelect.TIPDIA_CODIGO = valor.TIPDIA_CODIGO;
    this.actualizarTipDiario(valor.TIPDIA_CODIGO);
    value = Number(valor.ASI_DEBITOS);
    this.conencasilService.encabezadoEncasilSelect.TOTAL_DEBITOS = Number(value).toFixed(this.confIniciales.getNumDecSist());
    value = Number(valor.ASI_CREDITOS) * (-1);
    this.conencasilService.encabezadoEncasilSelect.TOTAL_CREDITOS = Number(value).toFixed(this.confIniciales.getNumDecSist());
    this.conencasilService.encabezadoEncasilSelect.SALDO = Number(valor.ASI_DEBITOS + valor.ASI_CREDITOS).toFixed(this.confIniciales.getNumDecSist());
    this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO = valor.ASI_ANULADO;
    if (valor.ASI_ANULADO === 1) {
      this.conencasilService.encabezadoEncasilSelect.ESTADO = 'ANULADO';
    } else {
      this.conencasilService.encabezadoEncasilSelect.ESTADO = '';
    }
    if (valor.ASI_ORIGEN === null) {
      this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN = '';
    } else {
      this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN = valor.ASI_ORIGEN;
    }

    this.boolCmbTipDia = true;
    console.log(this.strCodigoAsiento);
    console.log(this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO);
    console.log(this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN);

    if (this.strCodigoAsiento !== '') {
      this.activaBotones('NoModif', true, true, true, true, true, true, true);
      this.activarBotonesDetalle(true, true, true, true);
    } else {
      if (this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO === 0) {
        //this.activaBotones('NAV', false, true, true, false, false, false, false);
        this.activaBotones('NAV', false, true, false, false, false, false, false);
      } else {
        this.activaBotones('NoModif', false, true, true, true, false, false, false);
        this.activarBotonesDetalle(true, true, true, true);
      }
      if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN !== '') {
        this.activaBotones('NoModif', false, true, false, false, false,
          false, false);
        this.activarBotonesDetalle(true, true, true, true);
      } else {
        this.activarBotonesDetalle(false, false, true, false);
      }
    }
    // this.conencasilService.detencasil = [];
    this.conencasilService.comas();
    this.mostrarDetalle(valor.ASI_NRO, valor.TIPDIA_CODIGO, valor.ASI_ORIGEN);

  }


  guardar() {
    this.spin=true;//CDPJ
    this.btnGuardar = true;//CDPJ
    this.conencasilService.encabezadoEncasilSelect.TOTAL_DEBITOS = this.init.quitarComas(this.conencasilService.encabezadoEncasilSelect.TOTAL_DEBITOS);
    this.conencasilService.encabezadoEncasilSelect.TOTAL_CREDITOS = this.init.quitarComas(this.conencasilService.encabezadoEncasilSelect.TOTAL_CREDITOS);
    this.conencasilService.encabezadoEncasilSelect.SALDO = this.init.quitarComas(this.conencasilService.encabezadoEncasilSelect.SALDO);

    if (this.conencasilService.detencasil.length === 0) {
      this.mensajeAlerta('info', 'Información', 'Es necesario ingresar información en el detalle del Asiento Contable');
      this.spin=false//CDPJ
      this.btnGuardar = false;//CDPJ
      return;
    }

    if (this.conencasilService.detencasil.length > 0) {
      if (this.conencasilService.detencasil[0].CON_CODIGO === '') {
        this.mensajeAlerta('info', 'Información', 'Es necesario ingresar información en el detalle del Asiento Contable');
        this.spin=false//CDPJ
        this.btnGuardar = false;//CDPJ
        return;
      }
    }
    if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '') {
      this.guardarRegistro();
    } else {
      this.actualizarConceptosProy(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
      // modelDetasiL.actualizarConceptosProy(lblAsiNro.getText());
      const borrar = this.abiertoDesde === undefined ? false : true;
      this.activaBotones('G', false, true, borrar, false, false, false, false);
    }
  }

  async guardarRegistro() {
    if (this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO === 1) { // Si esta estado anulado
      this.spin=false//CDPJ
      this.btnGuardar = false;//CDPJ
      return;
    }

    if (this.conencasilService.encabezadoEncasilSelect.ASI_NRO === '') { /*Nuevo registro*/
      /*Verifica los permisos*/
      if (this.permisosRegistrosCont != null) {

        if (this.permisosRegistrosCont.PERTODO === 0) {
          if (this.permisosRegistrosCont.PERINSERCION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ',  no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
              this.spin=false//CDPJ
              this.btnGuardar = false;//CDPJ
            return;
          }
        }
      }
    } else {
      if (this.permisosRegistrosCont != null) {
        if (this.permisosRegistrosCont.PERTODO === 0) {
          if (this.permisosRegistrosCont.PERACTUALIZACION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ',  no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
              this.spin=false//CDPJ
              this.btnGuardar = false;//CDPJ
            return;
          }
        }
      }
    }

    if (this.faltaCampos() === true) { // Si los datos de la cabecera son correctos
      this.eliminarLineaEnBlanco();
      this.conencasilService.calcularCostoTotal();
      if (Number(this.conencasilService.dblSaldoAsiento) !== 0) {
        this.mensajeAlerta('info', 'Información', 'El Asiento no esta cuadrado, por favor verifique los Débitos y los Créditos');
        this.spin=false//CDPJ
        this.btnGuardar = false;//CDPJ
        return;
      }

      if (this.conencasilService.encabezadoEncasilSelect.ASI_NRO === '') { // Nuevo Registro
        // this.verificarDatos();
        this.spin = true;
        if  (await this.faltanCamposDet() === false){
          return;
        }
        if (this.verificarCuentaInsertadaDetalle() === false) {
          this.spin=false//CDPJ
        this.btnGuardar = false;//CDPJ
          return ;
        }
        // Si los datos del detalle son correctos
        // Guardar la cabecera del Asiento
        let strFecha = '';
        if (this.conencasilService.encabezadoEncasilSelect.ASI_FECHA.toString().includes('-')) {
          strFecha = this.datePipe.transform(this.conencasilService.encabezadoEncasilSelect.ASI_FECHA,
            'dd/MM/yyyy');
        }

        try {

          const save = await this.conencasilService.guardarEncasilProm(this.conencasilService.encabezadoEncasilSelect, strFecha, this.strTipoDiario);
          if (save !== null) {
            this.conencasilService.encabezadoEncasilSelect.ASI_NRO = save[0][':B1'];
            this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.conencasilService.encabezadoEncasilSelect.ASI_NRO), 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
              });
              if (!await this.guardarDetalle(strFecha)) {
                this.spin = false;
                this.btnGuardar = false;//CDPJ
                this.reversaGrabacionTransacciones();
                return;
              }
            this.deplegarNumeroDocumentoContable();
            if (this.intCOLASICOM === 1) {
              this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE = this.conencasilService.encabezadoEncasilSelect.ASI_NRO;
            }

            
            this.generaTrnCaja(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
            this.generarAbonoCXC(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
            // deplegarComprobantesIngreso(lblAsiNro.getText());
            const botonEliminar = this.abiertoDesde === undefined ? false : true;

            this.activaBotones('G', false, true, botonEliminar, false, false, false, false);
            this.boolCmbTipDia = true;
            this.mensajeAlerta('success', 'Información', 'Asiento contable guardado exitosamente !!!');
            this.spin = false;
            this.btnGuardar = true;//CDPJ
          }
        } catch (err) {
          this.spin = false;
          this.btnGuardar = false;//CDPJ
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
          this.reversaGrabacionTransacciones();
        }

        /*this.conencasilService.guardarEncasil(this.conencasilService.encabezadoEncasilSelect, strFecha, this.strTipoDiario).subscribe(save => {
          if (save !== null) {
            this.conencasilService.encabezadoEncasilSelect.ASI_NRO = save[0][':B1'];
            this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.conencasilService.encabezadoEncasilSelect.ASI_NRO), 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
            this.deplegarNumeroDocumentoContable();
            if (this.intCOLASICOM === 1) {
              this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE = this.conencasilService.encabezadoEncasilSelect.ASI_NRO;
            }
            
            if (! this.guardarDetalle(strFecha)) {
               this.reversaGrabacionTransacciones();
               return;
            }
            this.generaTrnCaja(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
            this.generarAbonoCXC(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
            // deplegarComprobantesIngreso(lblAsiNro.getText());
            this.activaBotones('G', false, true, false, false, false, false, false);
            this.boolCmbTipDia = true;
            this.mensajeAlerta('success', 'Información', 'Asiento contable guardado exitosamente !!!');
          }
          
          
        }, error1 => {
       
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
          this.mensajeAlerta('error', 'Error', mensaje);
          this.reversaGrabacionTransacciones();
  
        });*/


        /*Fin si los datos del detalle son correctos*/
        // Fin si es un nuevo registro
      } else {
        this.spin = true;
        if  (await this.faltanCamposDet() === false){
          return;
        } // Si los datos del detalle son correctos
        let strFecha = '';
        if (this.conencasilService.encabezadoEncasilSelect.ASI_FECHA.toString().includes('-')) {
          strFecha = this.datePipe.transform(this.conencasilService.encabezadoEncasilSelect.ASI_FECHA,
            'dd/MM/yyyy');
        } else {
          strFecha = this.conencasilService.encabezadoEncasilSelect.ASI_FECHA;
        }

        try {

          await this.conencasilService.actualizarEncasilProm(this.conencasilService.encabezadoEncasilSelect, strFecha, this.strTipoDiario);

          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.conencasilService.encabezadoEncasilSelect.ASI_NRO), 'A',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });

          this.generaTrnCaja(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);

          //  this.guardarDetalle(strFecha);
          if (!await this.guardarDetalle(strFecha)) {
            this.spin = false;
            return;
          }
          this.generarAbonoCXC(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
          this.generaTrnCaja(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
          const botonEliminar = this.abiertoDesde === undefined ? false : true;
          this.activaBotones('G', false, true, botonEliminar, false, false, false, false);
          this.mensajeAlerta('success', 'Información', 'Asiento contable actualizado exitosamente');
          this.spin = false;

        } catch (err) {
          this.spin = false;
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          return this.mensajeAlerta('error', 'Error', mensaje);
        }


        /*this.conencasilService.actualizarEncasil(this.conencasilService.encabezadoEncasilSelect, strFecha, this.strTipoDiario).subscribe(() => {
          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.conencasilService.encabezadoEncasilSelect.ASI_NRO), 'A',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          this.generaTrnCaja(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
        //  this.guardarDetalle(strFecha);
          if ( !this.guardarDetalle(strFecha)) {
            return;
          }
          this.generarAbonoCXC(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
          this.generaTrnCaja(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
          this.activaBotones('G', false, true, false, false, false, false, false);
          this.mensajeAlerta('success', 'Información', 'Asiento contable actualizado exitosamente');
          }, error1 => {
      
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
          return this.mensajeAlerta('error', 'Error', mensaje);
        }); */

      }


    }// Fin si los datos de la cabecera son correctos

  }

  faltaCampos(): boolean {
    // const dia = this.conencasilService.encabezadoEncasilSelect.ASI_FECHA.getDate();
    // const mes = this.conencasilService.encabezadoEncasilSelect.ASI_FECHA.getMonth();
    // const anio = this.conencasilService.encabezadoEncasilSelect.ASI_FECHA.getFullYear();

    // Verifica que documento este dentro del periodo contable

    // this.conencasilService.verificarPeriodoContable(dia, mes, anio);
    // if ( this.conencasilService.bolVerificar === false) {
    //   this.mensajeAlerta('info', 'Información', 'Error, la fecha debe de estar dentro del período contable');
    //   return false;
    // }
    // Código del proveedor
    if (this.strTipoDiario === '') {
      this.mensajeAlerta('info', 'Información', 'Error, Por favor ingrese el Tipo de Comprobante');
      this.spin=false//CDPJ
      this.btnGuardar = false;//CDPJ
      return false;
    }
    // Número del Comprobante
    if (this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE === '' && this.intCOLASICOM === 0) {
      this.mensajeAlerta('info', 'Información', 'Error, Por favor ingrese el Número del Comprobante');
      document.getElementById('nrocomprobante').focus();
      this.spin=false//CDPJ
      this.btnGuardar = false;//CDPJ
      return false;
    }
    return true;
  }


  eliminarLineaEnBlanco() {

    let intIndiceLista = 0;

    for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {
      const item = this.conencasilService.detencasil[intIndiceLista];

      if (item.CON_CODIGO === '') {
        this.conencasilService.detencasil.splice(this.conencasilService.detencasil.findIndex(
          encasil => encasil.DETASI_NROFILA === item.DETASI_NROFILA && encasil.LINEA === item.LINEA),
          1);
      }

    }
    this.aggrid.refreshColumnDefs();
    this.aggrid.refreshaggrid(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto);
    this.conencasilService.calcularCostoTotal();
  }

  async faltanCamposDet() {
    let intIndiceLista = 0;
    let strDato = '';
    let booleanv=true;

    for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {
      const item = this.conencasilService.detencasil[intIndiceLista];
      // Cuenta Contable

      // this.conencasilService.erConnombre(item.CON_CODIGO).subscribe((reg) => {
      try {
        const reg = await this.conencasilService.erConnombreProm(item.CON_CODIGO);
      console.log(reg);
      if (reg === null || reg[0] === undefined) {
        strDato = '';
      } else {
        strDato = reg[0].CON_NOMBRE;
      }

      if (strDato === '') {
        this.mensajeAlerta('info', 'Información', 'Error, Código de la cuenta contable '+item.CON_CODIGO+ ' es incorrecto');
        item.CON_CODIGO = '';
        this.spin=false//CDPJ
        this.btnGuardar = false;//CDPJ
        return booleanv=false;
        
      }

      // Concepto contable
      if (item.DETASI_CONCEPTO === '') {
        this.mensajeAlerta('info', 'Información', 'Error, Ingrese el concepto contable en uno de los items');
        this.spin=false//CDPJ
        this.btnGuardar = false;//CDPJ
        return booleanv=false;
        
      }
      } catch (error) {
        this.mensajeAlerta('info', 'Información', error);
        this.spin=false//CDPJ
        this.btnGuardar = false;//CDPJ
        return booleanv=false;
        
      }
      

      // });

    }// fin de for
  return booleanv;
  } // Fin faltan CamposDet

  deplegarNumeroDocumentoContable() {
    this.conencasilService.getDocumentCont(this.conencasilService.encabezadoEncasilSelect.ASI_NRO).subscribe(doc => {
      if (doc !== null) {
        this.conencasilService.encabezadoEncasilSelect.ASI_DOCUMENTOCONT = doc[0].asi_documentocont;
      }
    });
  }

  async guardarDetalle(strFecha): Promise<any> {

    let intOrden = 0;
    let intNumLineaDetalle = 0;
    // let bolCorrecto = false;
    let intIndiceLista: number;

    for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {
      const item = this.conencasilService.detencasil[intIndiceLista];
      if (item.CON_CODIGO === '') {
        this.conencasilService.detencasil.splice(this.conencasilService.detencasil.findIndex(
          encasil => encasil.DETASI_NROFILA === this.detalleConEncasilSel.DETASI_NROFILA && encasil.LINEA === this.detalleConEncasilSel.LINEA),
          1);
      }
    }


    // if (this.verificarCuentaInsertadaDetalle() === false) {

    //   return false;
    // }
    let intIndiceLista2: number;

    for (intIndiceLista2 = 0; intIndiceLista2 < this.conencasilService.detencasil.length; intIndiceLista2++) {

      const item = this.conencasilService.detencasil[intIndiceLista2];


      if (item.CON_CODIGO !== '') {
        intOrden = intOrden + 1;

        if (item.DETASI_NROFILA !== 0) {
          intNumLineaDetalle = item.DETASI_NROFILA;
        } else {
          intNumLineaDetalle = 0;
        }


        // item.DETASI_NROFILA = Number(intNumLineaDetalle);
        // item.ASI_NRO = this.conencasilService.encabezadoEncasilSelect.ASI_NRO;
        // item.ASI_FECHA = strFecha;
        // item.CENSUB_CODIGO = '';

        if (Number(item.DETASI_DEBE) !== 0) {
          item.DETASI_IMPORTE = Number(item.DETASI_DEBE);
        }
        if (Number(item.DETASI_HABER) !== 0) {
          item.DETASI_IMPORTE = Number(item.DETASI_HABER) * -1;
        }
        // item.COM_CODIGO = '01';
        // item.DETASI_ORDEN = Number(intOrden);
        // item.ENCFAC_COMCODIGO = '01';

        try {
          const saveDet = await this.conencasilService.guardarDetasil(item, strFecha, intNumLineaDetalle, intOrden);
          if (item.DETASI_NROFILA === 0) { /*Inserta un nuevo registro*/
            item.DETASI_NROFILA = saveDet[0].NUMFILA;
            item.TRNBAN_NUMERO = saveDet[0][':B2'];
            item.ASI_NRO = this.conencasilService.encabezadoEncasilSelect.ASI_NRO;
            await this.auditoriagral.registrarAuditoriaPromise('CON_DETASI_L', item.ASI_NRO + '/' + item.CON_CODIGO, 'I',
              this.usuario.identificacion, '01', '', '', '', '');
            /*this.auditoriagral.registrarAuditoria('CON_DETASI_L', item.CON_CODIGO, 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });*/
          } else { /*Actualiza el registro*/
            await this.auditoriagral.registrarAuditoriaPromise('CON_DETASI_L', item.ASI_NRO + '/' + item.CON_CODIGO, 'A',
              this.usuario.identificacion, '01', '', '', '', '');
            /*this.auditoriagral.registrarAuditoria('CON_DETASI_L', item.CON_CODIGO, 'A',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });*/
          }
        } catch (err) {

          //  let mensaje: string;
          // mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.reversaGrabacionTransacciones();
          this.mensajeAlerta('error', 'Error Detalle', err.error);
          this.spin=false//CDPJ
          return false;
        }

        // this.conencasilService.guardarDetasil(item, strFecha, intNumLineaDetalle, intOrden).subscribe((saveDet) => {
        //   if (item.DETASI_NROFILA === 0)  { /*Inserta un nuevo registro*/
        //     item.DETASI_NROFILA = saveDet[0].NUMFILA;
        //     item.TRNBAN_NUMERO = saveDet[0][':B2'];
        //     item.ASI_NRO = this.conencasilService.encabezadoEncasilSelect.ASI_NRO;
        //
        //     this.auditoriagral.registrarAuditoria('CON_DETASI_L', item.CON_CODIGO, 'I',
        //       this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        //     });
        //   } else { /*Actualiza el registro*/
        //     this.auditoriagral.registrarAuditoria('CON_DETASI_L', item.CON_CODIGO, 'A',
        //       this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        //     });
        //   }
        //
        // } , error1 => {
        //
        //   let mensaje: string;
        //   mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
        //   this.reversaGrabacionTransacciones();
        //   this.mensajeAlerta('error', 'Error Detalle', mensaje);
        //   return false;
        // });


      } // Fin si no es un valor en blanco

    }

    return true;
  }

  verificarCuentaInsertadaDetalle(): boolean {

    let intIndiceLista = 0;

    for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {
      const item = this.conencasilService.detencasil[intIndiceLista];
      if (item.CON_CODIGO === '') {
        this.mensajeAlerta('error', 'Error', 'Cuenta Contable Incorrecta');
        return false;
      }
      if (item.DETASI_CONCEPTO === '') {
        this.mensajeAlerta('error', 'Error', 'Concepto Contable Incorrecto');
        return false;
      }
      if (Number(item.DETASI_DEBE) === 0 && Number(item.DETASI_HABER) === 0) {
        this.mensajeAlerta('error', 'Error', 'Ingrese un valor en el Debe o Haber');
        return false;
      }

      if (item.CON_CODIGO.substring(item.CON_CODIGO.length - 1) === '.') {
        this.mensajeAlerta('error', 'Error', 'La cuenta contable ' + item.CON_CODIGO + ' no puede ser una cuenta de mayor');
        return false;
      }
      if (item.CON_CENTRO === 'Si' && item.CEN_CODIGO === '') {
        this.mensajeAlerta('error', 'Error', 'Ingrese el Centro de costos');
        return false;
      }

    }
    return true;
  }

  reversaGrabacionTransacciones() {

    if (this.boolCmbTipDia === true) {
      return;
    }
    this.conencasilService.reversarTransaccion(this.conencasilService.encabezadoEncasilSelect).subscribe(() => {
      this.conencasilService.encabezadoEncasilSelect.ASI_DOCUMENTOCONT = '';
      this.conencasilService.encabezadoEncasilSelect.ASI_NRO = '';
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });

  }

  generaTrnCaja(strAsiNro) {
    this.conencasilService.generarTrnCaja(strAsiNro).subscribe(() => {

    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });
  }

  generarAbonoCXC(strAsiNro) {
    this.conencasilService.generarAbonoCXC(strAsiNro).subscribe(() => {

    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }

  deplegarComprobantesIngreso() {

  }

  eliminarAsiento(srtAsiNro) {

    if (this.permisosRegistrosCont != null) {
      if (this.permisosRegistrosCont.PERTODO === 0) {
        if (this.permisosRegistrosCont.PERELIMACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.confirmarEliminacion(srtAsiNro);
  }

  confirmarEliminacion(srtAsiNro) {
    this.confirmationService.confirm({
      message: '¿ Esta seguro que desea eliminar el asiento contable ?',
      header: 'Eliminar Asiento Contable',
      icon: 'pi pi-exclamation-triangle',
      key: 'encasilConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminar(srtAsiNro);
      },
      reject: () => {
      }
    });
  }

  eliminar(srtAsiNro) {
    this.conencasilService.eliminarAbono(srtAsiNro).subscribe(() => {
      this.conencasilService.eliminarAsiento(srtAsiNro).subscribe(() => {
        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', srtAsiNro, 'E',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
        this.mensajeAlerta('success', 'Información', 'Asiento contable eliminado exitosamente !!!');

        this.actualizarEstadoFac();

        if (srtAsiNro === '') {
          this.eliminarTransBancosTodoAsi();
        }

        this.nuevoRegistro('N');
        this.activaBotones('INI', false, true, true, false, false, false, false);
        this.activarBotonesDetalle(true, true, true, true);


      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      });
    }, error1 => {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    });
  }

  actualizarEstadoFac() {
    let intIndiceLista = 0;
    let strCodCliente = '';
    let strEncNumFac = '';

    for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {
      const item = this.conencasilService.detencasil[intIndiceLista];
      strCodCliente = item.ENCFAC_CLICODIGO;
      strEncNumFac = item.ENCFAC_NUMERO;
      // Actualiza el estado de la factura
      if ((strCodCliente !== '' || strCodCliente !== null) && (strEncNumFac !== '' || strEncNumFac !== null)) {
        this.conencasilService.actualizaestadoFac(strCodCliente, strEncNumFac).subscribe(() => {

        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        });
      }

    }
  }

  eliminarTransBancosTodoAsi() {
    let intIndiceLista = 0;

    for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {
      this.eliminarTransaccionBancos(intIndiceLista);
    }
  }

  eliminarTransaccionBancos(intIndiceFila: number) {
    const item = this.conencasilService.detencasil[intIndiceFila];
    if (item.BAN_CODIGO !== '') {
      this.conencasilService.eliminarTransaccionBancos(item.BAN_CODIGO, item.TRNBAN_TIPO, item.TRNBAN_NUMERO).subscribe(() => {
      });
    }
  }

  cancelarRegistro() {
    if (this.conencasilService.encabezadoEncasilSelect.ASI_NRO !== '') {
      this.mostrarDatos(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
    } else {
      this.limpiarDatos();
      this.activaBotones('INI', false, true, true, false, false, false, false);
      this.activarBotonesDetalle(true, true, true, true);
    }
  }

  limpiarDatos() {
    this.conencasilService.encabezadoEncasilSelect.ASI_NRO = '';
    this.conencasilService.encabezadoEncasilSelect.ASI_DESCRIPCION = '';
    this.conencasilService.encabezadoEncasilSelect.TOTAL_CREDITOS = '';
    this.conencasilService.encabezadoEncasilSelect.TOTAL_DEBITOS = '';
    this.conencasilService.encabezadoEncasilSelect.SALDO = '';
    this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE = '';
    this.conencasilService.encabezadoEncasilSelect.ESTADO = '';
    this.conencasilService.detencasil = [];
    this.conencasilService.dblSaldoAsiento=0
  }

  cancelarAcciones() {
    let intIndiceLista = 0;
    for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {
      const item = this.conencasilService.detencasil[intIndiceLista];
      if (item.CON_CODIGO === '') {
        this.conencasilService.detencasil.splice(this.conencasilService.detencasil.findIndex(
          encasil => encasil.DETASI_NROFILA === item.DETASI_NROFILA && encasil.LINEA === item.LINEA),
          1);
      }
    }
    this.aggrid.refreshColumnDefs();
    this.aggrid.refreshaggrid(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto);
    this.conencasilService.calcularCostoTotal();
    this.activarBotonesDetalle(false, false, true, false);
  }

  confirmEliminarDetAsi() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'encasilConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDetalleSel();
      },
      reject: () => {
      }
    });
  }


  eliminarRegistro() {
    if (Object.keys(this.detalleConEncasilSel).length > 0) {
      this.confirmEliminarDetAsi();
    } else {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
    }
  }

  eliminarDetalleSel() {
    let strCodCliente = '';
    let strEncNumFac = '';

    if (this.detalleConEncasilSel.DETASI_NROFILA === 0) {
      this.conencasilService.detencasil = this.conencasilService.detencasil.filter((val, j) => j !==
        this.conencasilService.detencasil.indexOf(this.detalleConEncasilSel));
      // elimina la transaccion de bancos si aun no graba el siento
      if (this.detalleConEncasilSel.ASI_NRO === '') {
        if (this.detalleConEncasilSel.BAN_CODIGO !== '' && this.detalleConEncasilSel.TRNBAN_NUMERO !== '') {
          this.conencasilService.eliminarTransaccionBancos(this.detalleConEncasilSel.BAN_CODIGO,
            this.detalleConEncasilSel.TRNBAN_TIPO, this.detalleConEncasilSel.TRNBAN_NUMERO).subscribe(() => {
            });
        }
      }
      this.aggrid.refreshaggrid(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto);
      this.conencasilService.calcularCostoTotal();
      this.aggrid.refreshaggrid(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto);
    } else {
      strCodCliente = this.detalleConEncasilSel.ENCFAC_CLICODIGO;
      strEncNumFac = this.detalleConEncasilSel.ENCFAC_NUMERO;
      this.conencasilService.eliminarDetalleAsiento(this.detalleConEncasilSel).subscribe(() => {
        this.auditoriagral.registrarAuditoria('CON_DETASI_L', this.detalleConEncasilSel.ASI_NRO + '/' + this.detalleConEncasilSel.CON_CODIGO, 'E',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
        // elimina la transaccion de bancos si aun no graba el siento
        if (this.detalleConEncasilSel.ASI_NRO === '') {
          if (this.detalleConEncasilSel.BAN_CODIGO !== '') {
            this.conencasilService.eliminarTransaccionBancos(this.detalleConEncasilSel.BAN_CODIGO,
              this.detalleConEncasilSel.TRNBAN_TIPO, this.detalleConEncasilSel.TRNBAN_NUMERO).subscribe(() => {
              });
          }
        }
        // Actualiza el estado de la factura
        this.actualizarEstadoFac();

        this.conencasilService.detencasil.splice(this.conencasilService.detencasil.findIndex(
          encasil => encasil.DETASI_NROFILA === this.detalleConEncasilSel.DETASI_NROFILA && encasil.LINEA === this.detalleConEncasilSel.LINEA),
          1);
        this.conencasilService.calcularCostoTotal();//CDPJ
        this.aggrid.refreshaggrid(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto);
        this.activaBotones('N', true, false, true, true, true, true, true);
        this.activarBotonesDetalle(false, false, true, true);

      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(error1.error);
        this.mensajeAlerta('error', 'Error', error1.error);
      });
    }
  }

  acciones(valor) {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className = 'layout-config';
      }
    }
  }

  comprobarClave() {
    this.conencasilService.obtenerClave(this.accionClave).subscribe((clave) => {
      let claveReal;
      if (Object.keys(clave).length > 0) {
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          if (this.accionClave === 'eliminarAsi') {
            this.eliminarAsiOtroOrigen();
          }
          if (this.accionClave === 'actDocAnulado') {
            this.activarAsientoCont();
          }
          this.claveAuth = '';
        } else if (clave !== this.claveAuth) {
          this.claveDialog = false;
          this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
        }
      } else {
        this.claveDialog = false;
        this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
      }

    });
  }

  abrirClave(accion) {
    this.displayAcciones = false;
    this.accionClave = accion;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }
    this.claveDialog = true;
  }

  anularAsiento(strAsiNro) {

    let intNumRegistro = 0;
    const strTipoExp = '';
    this.conencasilService.countEncasil(strAsiNro).subscribe((cont) => {
      if (cont !== null) {
        intNumRegistro = cont[0].NUMREG;

        if (intNumRegistro === 1) {
          // eliminae el abono o el canje
          // if (eliminaAbono()==false)
          //   return;
          this.conencasilService.eliminarAbono(strAsiNro).subscribe(() => {
            this.conencasilService.anularAsiento(strAsiNro).subscribe(() => {
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', strAsiNro, 'AN',
                this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
                });
              this.actualizarEstadoFac();
              this.mostrarDatos(strAsiNro);
              this.eliminarDistribPrograma();
              this.mensajeAlerta('success', 'Información', 'Asiento contable anulado exitosamente !!!');
            });
          });
        } else {
          this.mensajeAlerta('error', 'Error', 'No se puede anular el asiento. Debido a que pertenece a otro Origen');
        }
      }
    });

  }

  verificarCambios() {
    if (this.btnGuardar === false) {

    }
  }

  confirmGuardarCambios() {
    this.confirmationService.confirm({
      message: 'Existen cambios, desea guardarlos ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'encasilConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.guardar();
      },
      reject: () => {
      }
    });
  }

  eliminarDistribPrograma() {
    this.conencasilService.eliminarDistribProg().subscribe(() => {
    });
  }

  eliminarAsiOtroOrigen() {
    let strDocumento = '';
    this.conencasilService.asientoAsociadoConDocumento(this.conencasilService.encabezadoEncasilSelect.ASI_NRO,
      this.strTipoDiario, this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN).subscribe((doc) => {

        if (doc !== null) {
          strDocumento = doc[0][':B1'];
          if (strDocumento === null) {
            this.eliminarAsiento(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
          } else {
            this.mensajeAlerta('info', 'Información', 'No se puede eliminar ya que existe un documento relacionado : ' + strDocumento);
          }
        }
      });
  }

  activarAsientoAnulado(seleccion) {
    if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN !== '') {
      this.mensajeAlerta('info', 'Información', 'El asiento a ser activado tiene que ser realizado por registros contables');
      return;
    }

    if (this.conencasilService.encabezadoEncasilSelect.ESTADO !== 'ANULADO') {
      this.mensajeAlerta('info', 'Información', 'El asiento tiene que estar anulado');
      return;
    }

    if (this.permisosRegistrosCont != null) {
      if (this.permisosRegistrosCont.PERTODO === 0) {
        if (this.permisosRegistrosCont.PERACTUALIZACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.confirmActivarAsi(seleccion);

  }

  confirmActivarAsi(actDocAnulado) {
    this.confirmationService.confirm({
      message: 'Está seguro de activar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'encasilConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.abrirClave(actDocAnulado);
      },
      reject: () => {
      }
    });
  }

  activarAsientoCont() {

    this.conencasilService.activarAsientoAnulado().subscribe(() => {
      this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
        this.conencasilService.encabezadoEncasilSelect.ASI_NRO + '/' + this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE,
        'A', this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      this.mostrarDatos(this.conencasilService.encabezadoEncasilSelect.ASI_NRO);
      // this.mostrarComprobanteDeCompIng();
    });
  }

  listarFacPendientes() {
    if (this.permisosRegistrosCont != null) {
      if (this.permisosRegistrosCont.PERTODO === 0) {
        if (this.permisosRegistrosCont.PERINSERCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.buscarFactPendientes();
  }

  buscarFactPendientes() {
    this.opcion = 'BuscarFactP';
    this.types = [
      { label: 'REFERENCIA', value: 'TRNCOBRO_REFERENCIA' },
      { label: 'CLICODIGO', value: 'CLI_CODIGO' },
      { label: 'NOMBRE', value: 'CLI_NOMBRE' },
      { label: 'FECHAEMISION', value: 'TRNCOBRO_FECHATRN' }
    ];
    this.busquedacampos = ['', '', '', ''];
    this.consulta = 'TRNCOBRO_REFERENCIA, CLI_CODIGO, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE, TRNCOBRO_FECHATRN,' +
      ' CXC_FNC_SALDODOCUMENTO_G(TRNCOBRO_REFERENCIA,\'01\',CLI_CODIGO) AS SUMA, TRNCOBRO_TIPODOC';
    this.tabla = 'cxc_trnresumen';
    this.where = ' TRNCOBRO_TIPODOC=\'FC\' and CXC_FNC_SALDODOCUMENTO_G(TRNCOBRO_REFERENCIA,\'01\',CLI_CODIGO)>0 and ROWNUM <50';

    this.busquedaSer.busquedaCxcTrnResumen1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((dato) => {
        if (dato.TRNCOBRO_FECHATRN !== null) {
          dato.TRNCOBRO_FECHATRN = this.datePipe.transform(dato.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        } else {
          dato.TRNCOBRO_FECHATRN = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  seleccionFactPend(valor) {
    if (valor.TRNCOBRO_REFERENCIA !== '') {
      this.desplegarFacPorCobrar(valor.TRNCOBRO_REFERENCIA, valor.CLI_CODIGO);
    }

  }

  desplegarFacPorCobrar(strDocumento, strCodigoCli) {

    const item = this.detalleConEncasilSel = this.conencasilService.detencasil[this.conencasilService.detencasil.length - 1];
    let dblSaldo = '';

    if (this.conencasilService.detencasil.length === 0) {
      this.mensajeAlerta('info', 'Información', 'Error, No existen items en el detalle, por favor verifique');
      return;
    }

    if (this.detalleConEncasilSel.CON_CODIGO === '') {

      this.conencasilService.buscarDetFacPendiente(strDocumento, strCodigoCli).subscribe(detFacPend => {
        if (detFacPend !== null) {
          if (detFacPend[0].CON_CODIGO1 === null) {
            this.mensajeAlerta('info', 'Información', 'Error, Código de la cuenta contable del Cliente es incorrecta, por favor verifique');
            return;
          }


          item.CON_CENTRO = detFacPend[0].CON_CENTRO;
          item.CON_CODIGO = detFacPend[0].CON_CODIGO1;
          item.DETASI_REFERENCIA = detFacPend[0].TRNCOBRO_NROCOMPROBANTE;
          item.DETASI_CONCEPTO = detFacPend[0].CON_NOMBRE + '-' +
            detFacPend[0].TRNCOBRO_TIPODOC + ':' + detFacPend[0].TRNCOBRO_NROCOMPROBANTE;
          item.ENCFAC_NUMERO = detFacPend[0].TRNCOBRO_NROCOMPROBANTE;

          dblSaldo = Number(detFacPend[0].SALDO).toFixed(this.confIniciales.getNumDecSist());
          item.DETASI_HABER = dblSaldo;
          item.ENCFAC_CLICODIGO = detFacPend[0].CLI_CODIGO;
          item.ASI_FECHA = '';
          item.ASI_NRO = '';
          item.BAN_CODIGO = '';
          item.CEN_CODIGO = '';
          item.CENSUB_CODIGO = '';
          item.COM_CODIGO = '01';
          item.CON_NOMBRE = detFacPend[0].CON_NOMBRE;
          item.CXCTRNCOBRO_CLICODIGO = '';
          item.CXCTRNCOBRO_COMCODIGO = '';
          item.CXCTRNCOBRO_NRODOC = '';
          item.DETASI_CONCEPTOPRY = '';
          item.DETASI_HORA = '';
          item.DETASI_NROFILA = 0;
          item.DETASI_REFERENCIA = '';
          item.TRNBAN_BENEFICIARIO = '';
          item.TRNBAN_NUMERO = '';
          item.TRNBAN_TIPO = '';
          item.TRNRETENCION_NRO = '';
          item.CON_CENTRO = '';
          item.RETENCION_CODIGO = '';

          this.conencasilService.calcularCostoTotal();
          this.aggrid.refreshColumnDefs();
          this.aggrid.refreshaggrid(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto);
          this.activarBotonesDetalle(false, false, false, false);

        }
      });

    } else {
      this.mensajeAlerta('info', 'Información', 'Error, Selecciona una línea en blanco por favor');
    }
  }

  listarTransBancariasPen() {

    if (this.permisosRegistrosCont != null) {
      if (this.permisosRegistrosCont.PERTODO === 0) {
        if (this.permisosRegistrosCont.PERINSERCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de inserción. Consulte con el Administrador del Sistema .');
          return;
        }
      }
    }

    this.tipoAccionTrnBan = 'newCont';
    this.asiNroTrnBan = '';
    this.banCodigoTrnBan = '';
    this.fechaEmisionTrnBan = this.conencasilService.encabezadoEncasilSelect.ASI_FECHA;
    this.origenTrnBan = 'BAN';
    this.tipoTrnBan = '';
    this.anuladoTrnBan = 0;
    this.trnbanNumeroTrnBan = '';
    this.referenciaTrnBan = '';
    this.conceptoTrnBan = this.conencasilService.encabezadoEncasilSelect.ASI_DESCRIPCION;
    this.importeTrnBan = Number(0).toFixed(this.confIniciales.getNumDecSist());


    this.beneficiarioTrnBan = '';
    this.nroDocumentoTrnBan = '';
    this.conciliaTrnBan = 0;
    this.fechaVencimientoTrnBan = '';
    this.numeroConciliacionTrnBan = '';
    this.regContableTrnBan = true;
    this.asientoTrnBan = true;
    this.displayDialogBanTrnBan = true;

  }

  abrirMoviBancos(data) {

    if (typeof this.aggrid.gridApi.getFocusedCell().column.colDef.editable === 'function') {
      this.aggrid.gridApi.forEachNode((node) => {
        if (node.selected === true) {
          if (this.aggrid.gridApi.getFocusedCell().column.colDef.editable(node) === false) {
            this.conencasilService.buscarDatosBantrnban(data.TRNBAN_NUMERO, data.BAN_CODIGO, data.TRNBAN_TIPO).subscribe(trnban => {
              if (Object.keys(trnban).length !== 0) {
                this.asiNroTrnBan = trnban[0].ASI_NRO;
                this.banCodigoTrnBan = data.BAN_CODIGO;
                this.fechaEmisionTrnBan = trnban[0].FECHAEMISION;
                this.origenTrnBan = trnban[0].TRNBAN_ORIGEN;
                this.tipoTrnBan = trnban[0].TRNBAN_TIPO;
                this.anuladoTrnBan = trnban[0].TRNBAN_ANULADO;
                this.trnbanNumeroTrnBan = data.TRNBAN_NUMERO;
                this.referenciaTrnBan = '';
                this.conceptoTrnBan = trnban[0].TRNBAN_CONCEPTO;
                this.importeTrnBan = Number(trnban[0].TRNBAN_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
                this.beneficiarioTrnBan = trnban[0].TRNBAN_BENEFICIARIO;
                this.nroDocumentoTrnBan = trnban[0].TRNBAN_DOCNRO;
                this.conciliaTrnBan = trnban[0].TRNBAN_CONCILIA;
                this.fechaVencimientoTrnBan = trnban[0].FECHAVENCIMIENTO;
                this.numeroConciliacionTrnBan = '';
                this.regContableTrnBan = false;
                this.asientoTrnBan = true;
                this.tipoAccionTrnBan = 'view';
                this.displayDialogBanTrnBan = true;
              }
            });
          } else {
          }
        }
      });
    }


  }

  isEdit(data): boolean {


    if (this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN === '' && this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO === 0) {

      const StrTipoReg = data.DETASI_TIPOREG === null ? '' : data.DETASI_TIPOREG;
      const strTrnBanNum = data.TRNBAN_NUMERO === null ? '' : data.TRNBAN_NUMERO;

      if (StrTipoReg === '' && strTrnBanNum === '') { // Si es una cuenta que puede ser modificada

        if (this.aggrid.gridApi.getFocusedCell().column.colId === 'CON_CODIGO') { // Cuenta contable
          return true;
        }
        if (this.aggrid.gridApi.getFocusedCell().column.colId === 'DETASI_DEBE') { // Debe
          return true;
        }
        if (this.aggrid.gridApi.getFocusedCell().column.colId === 'DETASI_HABER') {
          return true;
        }// Haber
        if (this.aggrid.gridApi.getFocusedCell().column.colId === 'DETASI_REFERENCIA') { // Referencia
          return true;
        }
      } // Fin si es una cuenta que puede ser modificada

      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'CEN_CODIGO') { // Centro de Costos
        return true;
      }

      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'TIPGAS_CODIGO') {
        if (this.detalleConEncasilSel.BOL_TIPOGASTO) {
          return true;
        }
      }
    }

    if (this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO === 0) {
      if (this.aggrid.gridApi.getFocusedCell().column.colId === 'DETASI_CONCEPTO') { // Concepto Contable
        return true;
      }
    }
    //CDPJ
    if (this.aggrid.gridApi.getFocusedCell().column.colId === 'TRNBAN_TIPO') {
      return false;
    }

    //CDPJ
    return false;
  }

  recibirSenalesBanTrnban(valor) {
    if (valor === true) {
      this.displayDialogBanTrnBan = false;
      if (this.bantrnbaneditService.glbStrCodigo !== '' && this.tipoAccionTrnBan === 'newCont') {
        this.desplegarTransaccionBancaria(this.bantrnbaneditService.glbStrCodigo, this.bantrnbaneditService.glbStrNombre,
          this.bantrnbaneditService.glbStrCodigo2);
      }
    }
  }

  desplegarTransaccionBancaria(strTrnBanNumero, strTrnBanTipo, strTrnBanCodigo) {
    console.log(this.detalleConEncasilSel);
    const item = this.detalleConEncasilSel = this.conencasilService.detencasil[this.conencasilService.detencasil.length - 1];
    let dblSaldo = '';

    if (this.conencasilService.detencasil.length === 0) {
      this.mensajeAlerta('info', 'Información', 'Error, No existen items en el detalle, por favor verifique');
      return;
    }


    if (item.CON_CODIGO === '' || (item.TRNBAN_NUMERO === strTrnBanNumero && item.TRNBAN_TIPO === strTrnBanTipo && item.BAN_CODIGO === strTrnBanCodigo)) {
      this.conencasilService.desplegarTransaccionBancaria(strTrnBanNumero, strTrnBanTipo, strTrnBanCodigo).subscribe((transBan) => {

        if (Object.keys(transBan).length > 0) {

          if (transBan[0].CONCODIGOBAN === null) {
            this.mensajeAlerta('info', 'Error', 'Error, Código de la cuenta contable del banco es incorrecta, por favor verifique');
            return;
          }

          item.CON_CODIGO = transBan[0].CONCODIGOBAN;
          item.DETASI_REFERENCIA = transBan[0].TRNBAN_NUMERO;

          if (this.intOBSASIDET === 1) {
            item.DETASI_CONCEPTO = transBan[0].TRNBAN_CONCEPTO === null ? '' : transBan[0].TRNBAN_CONCEPTO;
            item.DETASI_CONCEPTOPRY = transBan[0].TRNBAN_CONCEPTO === null ? '' : transBan[0].TRNBAN_CONCEPTO;
          } else {
            item.DETASI_CONCEPTO = transBan[0].TRNBAN_BENEFICIARIO === null ? '' : transBan[0].TRNBAN_BENEFICIARIO;
            item.DETASI_CONCEPTOPRY = transBan[0].TRNBAN_BENEFICIARIO === null ? '' : transBan[0].TRNBAN_BENEFICIARIO;
          }

          dblSaldo = Number(transBan[0].TRNBAN_IMPORTE).toFixed(this.confIniciales.getNumDecSist());

          if (Number(dblSaldo) < 0) {
            item.DETASI_HABER = Number(dblSaldo) * -1;
          } else {
            item.DETASI_DEBE = dblSaldo;
          }

          item.TRNBAN_TIPO = transBan[0].TRNBAN_TIPO;
          item.TRNBAN_NUMERO = transBan[0].TRNBAN_NUMERO;
          item.TRNBAN_BENEFICIARIO = transBan[0].TRNBAN_BENEFICIARIO === null ? '' : transBan[0].TRNBAN_BENEFICIARIO;
          item.BAN_CODIGO = transBan[0].BAN_CODIGO;
          item.CON_CENTRO = transBan[0].CON_CENTRO === null ? '' : transBan[0].CON_CENTRO;
          item.CON_NOMBRE = transBan[0].CON_NOMBRE === null ? '' : transBan[0].CON_NOMBRE;

          /*  item.ENCFAC_NUMERO = '';
            item.ENCFAC_CLICODIGO = '';
            item.ASI_FECHA = '';
            item.ASI_NRO = '';
            
            item.CEN_CODIGO = '';
            item.CENSUB_CODIGO = '';
            item.COM_CODIGO = '01';
           
            item.CXCTRNCOBRO_CLICODIGO = '';
            item.CXCTRNCOBRO_COMCODIGO = '';
            item.CXCTRNCOBRO_NRODOC = '';
            item.DETASI_CONCEPTOPRY = '';
            item.DETASI_HORA = '';
            item.DETASI_NROFILA = 0;
            item.DETASI_REFERENCIA = '';
            
            
            
            item.TRNRETENCION_NRO = '';
            item.CON_CENTRO = '';
            item.RETENCION_CODIGO = ''; */

          this.conencasilService.calcularCostoTotal();
          this.aggrid.refreshColumnDefs();
          this.aggrid.refreshaggrid(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto);
          this.activarBotonesDetalle(false, false, false, false);
        }
      });
    } else {
      this.mensajeAlerta('info', 'Información', 'Error, Selecciona una línea en blanco por favor');
    }

  }

  actualizarConceptosProy(strAsiNro) {
    let strSentencia = '';
    let intIndiceLista = 0;

    for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {
      const item = this.conencasilService.detencasil[intIndiceLista];

      if (item.CON_CODIGO !== '') {
        strSentencia = 'update con_detasi_l set detasi_conceptopry=' + '\'' + item.DETASI_CONCEPTOPRY + '\'' + ',';
        strSentencia = strSentencia + ' detasi_concepto=' + '\'' + item.DETASI_CONCEPTO + '\'';
        strSentencia = strSentencia + ' where asi_nro=' + '\'' + strAsiNro + '\'';
        strSentencia = strSentencia + ' and com_codigo=' + '\'01\'';
        strSentencia = strSentencia + ' and detasi_nrofila=' + item.DETASI_NROFILA;

        this.conencasilService.actualizarConceptosProy(item.DETASI_CONCEPTOPRY, item.DETASI_CONCEPTO, strAsiNro, item.DETASI_NROFILA).subscribe(() => {
          this.spin=false;
        });

      }
    }
    
  }

  abrirDistribucionCC() {
    if (this.conencasilService.detencasil.length > 0) {

      const intIndex = this.conencasilService.detencasil.indexOf(this.detalleConEncasilSel);
      let dblImporte = 0;

      const item = this.conencasilService.detencasil[intIndex];

      if (Number(item.DETASI_DEBE) !== 0) {
        dblImporte = item.DETASI_DEBE;
      } else {
        dblImporte = item.DETASI_HABER;
      }

      this.strAsiNroDCC = this.conencasilService.encabezadoEncasilSelect.ASI_NRO;
      this.intDetasiNroFilaDCC = item.DETASI_NROFILA;
      this.strCodigoDCC = item.CON_CODIGO;
      this.strDescripcionDCC = item.CON_NOMBRE;
      this.dblValorDCC = Number(dblImporte).toFixed(this.numDecTotalDCC);
      this.strTipoDetDCC = '';

      this.displayCentroCostos = true;
    }
  }

  getSenalesDistribCC(valor) {
    if (valor === true) {
      this.displayCentroCostos = false;


    }
  }

  verificarChequeConciliado() {
    let intIndiceLista = 0;

    for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {
      const item = this.conencasilService.detencasil[intIndiceLista];
      if (item.BAN_CODIGO !== '') {
        this.conencasilService.erTrnbanconcilia(item.TRNBAN_NUMERO, item.BAN_CODIGO, item.TRNBAN_TIPO).subscribe((dato) => {

        });
      }

    }
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }

  copiarFactura() {
    this.conencasilService.encabezadoEncasilSelect.ASI_DOCUMENTOCONT = '';
    this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE = '';
    this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN = '';

    if (this.conencasilService.detencasil.length === 0) {
      this.mensajeAlerta('info', 'Información', 'Debe seleccionar un registro para duplicar');
    } else {
      this.conencasilService.encabezadoEncasilSelect.ASI_DOCUMENTOCONT = '';
      this.conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE = '';
      this.conencasilService.encabezadoEncasilSelect.ASI_ORIGEN = '';
      this.encerarLineaDetalle();
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      this.aggrid.bloquearGrid();
      this.boolCmbTipDia = false;
      this.conencasilService.encabezadoEncasilSelect.ASI_NRO = '';
      /*this.detalleConEncasilSel.DETASI_CONCEPTO = ''
	    this.detalleConEncasilSel.DETASI_CONCEPTOPRY = '';
      this.detalleConEncasilSel.CON_NOMBRE = '';*/
      this.conencasilService.encabezadoEncasilSelect.ASI_ANULADO = 0;
      this.conencasilService.encabezadoEncasilSelect.ESTADO = '';
      this.activaBotones('N', true, false, false, true, true, true, true);
      this.activarBotonesDetalle(false, false, true, false);//CDPJ
      document.getElementById('nrocomprobante').focus();
      this.eliminarMovBancosCaja();
    }
  }

  eliminarMovBancosCaja() {
    let intIndiceLista: number;
    console.log(this.conencasilService.detencasil.length);
    const detalle: Condetasil[] = [];
    try {
      for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {

        console.log(intIndiceLista);
        const item = this.conencasilService.detencasil[intIndiceLista];

        console.log(item);
        if (item.CON_CODIGO !== '' && item.TRNBAN_TIPO !== '') {
          /*detalle.splice(detalle.findIndex(
               encasil => encasil.DETASI_NROFILA === item.DETASI_NROFILA && encasil.DETASI_ORDEN === item.DETASI_ORDEN),
             1);*/
          this.conencasilService.detencasil.splice(this.conencasilService.detencasil.findIndex(
            encasil => encasil.DETASI_NROFILA === item.DETASI_NROFILA && encasil.DETASI_ORDEN === item.DETASI_ORDEN),
            1);
        } else {
          //  detalle.push(item);
        }

      }

      console.log(detalle);
    } catch (err) {
      console.log(err);
    }


    // this.conencasilService.detencasil = detalle;



    // this.ponerColorAFilaxTipoCuenta();
    this.conencasilService.calcularCostoTotal();
    this.aggrid.refreshaggrid(this.conencasilService.detencasil, this.defaultConEncasilDet.objeto);

  }

  encerarLineaDetalle() {
    let intIndiceLista: number;

    for (intIndiceLista = 0; intIndiceLista < this.conencasilService.detencasil.length; intIndiceLista++) {
      this.conencasilService.detencasil[intIndiceLista].DETASI_NROFILA = 0;

    }

  }


}
