import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { InvmaearticuloService } from '../../inv/invservicios/invmaearticulo.service';
import { InvmaebodegaService } from '../../inv/invservicios/invmaebodega.service';
import { InvmaeunidadesService } from '../../inv/invservicios/invmaeunidades.service';
import { AggridsaciComponent } from '../aggridsaci/aggridsaci.component';
import { InvtomafisicaService } from '../../inv/invservicios/invtomafisica.service';
import { BancierrecajaService } from '../../ban/banservicios/bancierrecaja.service';
import { DatePipe } from '@angular/common';
import { CommaeservicioService } from '../../com/comservicios/commaeservicio.service';
import { VenencpedService } from '../../ven/venservicios/venencped.service';
import { PagocontadoService } from '../../ven/venservicios/pagocontado.service';
import { VenencfacService } from '../../ven/venservicios/venencfac.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { ComencordcomService } from '../../com/comservicios/comencordcom.service';
import { InventradaService } from '../../inv/invservicios/inventrada.service';
import { InvsalidaService } from '../../inv/invservicios/invsalida.service';
import { InvtrnkardexotrService } from '../../ven/venservicios/invtrnkardexotr.service';
import { VenenguiaRemisionService } from '../../ven/venservicios/venenguia-remision.service';
import { ConmaeconService } from '../../cont/conservicios/conmaecon.service';
import { CommaeproveedorService } from '../../com/comservicios/commaeproveedor.service';
import { VenencdevfacService } from '../../ven/venservicios/venencdevfac.service';
import { ComnotrecService } from '../../com/comservicios/comnotrec.service';
import { ComencfacproService } from '../../com/comservicios/comencfacpro.service';
import { ComencdevfacService } from '../../com/comservicios/comencdevfac.service';
import { CxccarteraactivaService } from '../../cxc/cxcservicios/cxccarteraactiva.service';
import { CxctrnpagoretService } from '../../cxc/cxcservicios/cxctrnpagoret.service';
import { ComtrndistribucionccService } from '../../com/comservicios/comtrndistribucioncc.service';
import { CxptrnpagodocumentoService } from '../../com/comservicios/cxptrnpagodocumento.service';
import { ActualizacionpreciosfpService } from '../../com/comservicios/actualizacionpreciosfp.service';
import { CxcmaeretencionService } from 'src/app/cxc/cxcservicios/cxcmaeretencion.service';
import { ConencasilService } from '../../cont/conservicios/conencasil.service';
import { BantrnbaneditService } from '../../ban/banservicios/bantrnbanedit.service';
import { ContrndistribpccService } from '../../cont/conservicios/contrndistribpcc.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { BanconcilbancariaService } from '../../ban/banservicios/banconcilbancaria.service';
import { BantrntarjetaService } from '../../ban/banservicios/bantrntarjeta.service';
import { BantrncajaService } from '../../ban/banservicios/bantrncaja.service';
import { InvenctransferService } from '../../inv/invservicios/invenctransfer.service';
import { MessageService } from 'primeng';
import { CxptrncobroService } from '../../cxp/cxpservicios/cxptrncobro.service';
import { NommaedepartamentoService } from '../../nom/nomservicios/nommaedepartamento.service';
import { SegmaeusuarioService } from '../../seg/segservicios/segmaeusuario.service';
import { SegmaecompaniaService } from '../../seg/segservicios/segmaecompania.service';
import { SacimaeconfiguracionService } from '../../seg/segservicios/sacimaeconfiguracion.service';
import { CxpenccuotaService } from '../../cxp/cxpservicios/cxpenccuota.service';
import { InvtrnajustesService } from '../../inv/invservicios/invtrnajustes.service';
import { SacimaenumeracionCService } from 'src/app/seg/segservicios/sacimaenumeracion-c.service';
import { SegmaenumeraciongService } from 'src/app/seg/segservicios/segmaenumeraciong.service';
import { BanbandejapagosService } from '../../ban/banservicios/banbandejapagos.service';
import { NommaetablitaretService } from 'src/app/nom/nomservicios/nommaetablitaret.service';
import { NommaerubroService } from 'src/app/nom/nomservicios/nommaerubro.service';
import { InvtrnreservasService } from '../../inv/invservicios/invtrnreservas.service';
import { NomrhucabhoraextrService } from 'src/app/nom/nomservicios/nomrhucabhoraextr.service';
import { VenmaeclienteService } from 'src/app/ven/venservicios/venmaecliente.service';
import { InvtrnloteService } from 'src/app/ven/venservicios/invtrnlote.service';
import { ProdencordproService } from 'src/app/prod/prodservicios/prodencordpro.service';
import { NomutilidadesService } from 'src/app/nom/nomservicios/nomutilidades.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { ActmaegrupoService } from 'src/app/act/actservicios/actmaegrupo.service';
import { ActmaepolizaService } from 'src/app/act/actservicios/actmaepoliza.service';
import { TecencordtraService } from 'src/app/tec/tecservicios/tecencordtra.service';
import { ActencsalidaService } from 'src/app/act/actservicios/actencsalida.service';
import { VenencptovtaService } from '../../ven/venservicios/venencptovta.service';
import { NomencrolService } from 'src/app/nom/nomservicios/nomencrol.service';//GSRF
//CDPJ
import {CxctrnretencionesService} from '../../cxc/cxcservicios/cxctrnretenciones.service';//CDPJ
import {CxctrnabonoService} from '../../cxc/cxcservicios/cxctrnabono.service';//CDPJ
import {CxctrncancelacionService} from '../../cxc/cxcservicios/cxctrncancelacion.service';
import { InvtrnajustenegService } from "../../inv/invservicios/invtrnajusteneg.service";
import {PresuplancuentasService } from "../../cont/conservicios/presuplancuentas.service";
import { PresucentrocostosService } from 'src/app/cont/conservicios/presucentrocostos.service';
import { ConpresuanucenService } from "../../cont/conservicios/conpresuanucen.service";
//CDPJ
@Component({
  selector: 'prueba-cell',
  template: `
	  <input #input pInputText *ngIf="!fecha && !regexp && !checkBool && !int" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (focus)="focuact($event)" (blur)="focudes($event)"
			 [(ngModel)]="value" style="width: 100%" type="text" [maxLength]="tamanocelda">
	  <input #input pInputText *ngIf="!fecha && regexp && !checkBool && !int" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (focus)="focuact($event)" (blur)="focudes($event)"
			 [(ngModel)]="value" style="width: 100%" [pKeyFilter]="regexp" [maxLength]="1">
	  <input #input pInputText *ngIf="fecha && !checkBool && !int && !regexp" type="date" (keydown)="onKeyDownFecha($event)"
			 [(ngModel)]="value" style="width: 100%">
	  <input #input pInputText *ngIf="!fecha && int && !checkBool && !regexp" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (focus)="focuact($event)" (blur)="focudes($event)"
			 [(ngModel)]="value" style="width: 100%" pKeyFilter="num" [maxLength]="tamanocelda">
  `
})

export class CellEditorPrueba implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: string;
  private cancelBeforeStart: boolean;
  public gridApi;
  public int: boolean;
  public saltoslinea: number;
  public newrow: boolean;
  public tamanocelda: number;
  public columidactual: string;
  public onlynumber: boolean;
  public onlyalphabetic: boolean;
  public onlymayusculas: boolean;
  public decimales: number;
  public fecha: boolean;
  public checkBool: boolean;
  public clave: boolean;
  public objeto: any;
  public regexp: RegExp;
  public opcionbuscar: boolean;
  public tienecalculos: boolean;
  public autorizacion: boolean;
  public noblanco: boolean;
  public nosaltar: boolean;
  public focusout: boolean;
  //GSRF
  public idcell1 = '';
  public rowcell1 = '';
  //GSRF
  //CDPJ
  public onlynumberposneg:boolean
  //CDPJ
  // @ts-ignore
  @ViewChild('input', { read: ViewContainerRef }) public input;

  constructor(private messageService: MessageService, private artService: InvmaearticuloService, private bodService: InvmaebodegaService,
    private unidadService: InvmaeunidadesService, private aggrid: AggridsaciComponent, private tomaService: InvtomafisicaService,
    private cajaService: BancierrecajaService, private datePipe: DatePipe, private comservice: CommaeservicioService,
    private encpedService: VenencpedService, private pagconservice: PagocontadoService, private encfacservice: VenencfacService,
    private confIniciales: ConfInicialesService, private encordService: ComencordcomService,
    private entradaService: InventradaService, private salidaService: InvsalidaService,
    private tomFisService: InvtomafisicaService, private trnkardexotrservice: InvtrnkardexotrService,
    private venenguiaRemisionService: VenenguiaRemisionService, private cuentaService: ConmaeconService, private proveedorService: CommaeproveedorService,
    private venDevFacService: VenencdevfacService, private comnotrecServicio: ComnotrecService,
    private encfacproService: ComencfacproService, private comDevFacService: ComencdevfacService,
    private cxcCarteraService: CxccarteraactivaService, private distriCCService: ComtrndistribucionccService,
    private cxctrnpagoretService: CxctrnpagoretService, private cxptrnpagodocService: CxptrnpagodocumentoService,
    private actpreService: ActualizacionpreciosfpService, private cxcmaeretencionService: CxcmaeretencionService,
    private conecasilService: ConencasilService, private bantrnbanEditService: BantrnbaneditService,
    private contrndistribccService: ContrndistribpccService, private init: NuevoComponentService,
    private banConcilBanService: BanconcilbancariaService, private bantrntarjetaService: BantrntarjetaService,
    private bantrnCajaService: BantrncajaService, private enctransferService: InvenctransferService,
    private cxptrncobroService: CxptrncobroService, private nommaedepartamentoService: NommaedepartamentoService,
    private segmaeusuarioService: SegmaeusuarioService, private segmaeCompaniaService: SegmaecompaniaService,
    private sacimeconfigService: SacimaeconfiguracionService, private cxpEncCuotaService: CxpenccuotaService,
    private invtrnajustesService: InvtrnajustesService, private numCService: SacimaenumeracionCService,
    private segmaenumeraciongService: SegmaenumeraciongService, private banBandejaPagosService: BanbandejapagosService,
    private nommaetablitaretService: NommaetablitaretService, public encordproService: ProdencordproService,
    private nommaerubroService: NommaerubroService, private invreservasService: InvtrnreservasService,
    private cabHoraExtrSrv: NomrhucabhoraextrService, private cliService: VenmaeclienteService,
    private trnloteservice: InvtrnloteService, private nomUtilidadesService: NomutilidadesService,
    private busqService: InvbusquedaService, private actmaegrupoService: ActmaegrupoService,
    private actmaepolizaService: ActmaepolizaService, public encordtraService: TecencordtraService,
    private actencsalidaService: ActencsalidaService, private encptovtaService: VenencptovtaService,private rolProcesoSrv: NomencrolService,
    private cxctrnretencionesService:CxctrnretencionesService,
    private cxctrnabonoService:CxctrnabonoService,
    private cxctrncancelacionService:CxctrncancelacionService,
    private invtrnajustenegService:InvtrnajustenegService,
    private presuplancuentasService: PresuplancuentasService,
    private presucentrocostosService: PresucentrocostosService,
    private conpresuanucenService:ConpresuanucenService) {
  }

  agInit(params: any): void {
    console.log(params)
    this.params = params;
    this.value = this.params.value;
    this.gridApi = params.api;
    this.saltoslinea = params.values.saltoslinea;
    this.newrow = params.values.newrow;
    this.onlynumber = params.values.numeros;
    this.onlynumberposneg=params.values.numposneg;
    this.onlyalphabetic = params.values.alphabetic;
    this.onlymayusculas = params.values.mayusculas;
    this.decimales = params.values.numerodecimales;
    if (params.values.regexp !== undefined) {
      this.regexp = params.values.regexp;
    }
    if (params.values.opcionbuscar !== undefined) {
      this.opcionbuscar = params.values.opcionbuscar;
    }
    if (params.values.fecha !== undefined) {
      this.fecha = params.values.fecha;
    }
    if (params.values.clave !== undefined) {
      this.clave = params.values.clave;
    }
    if (params.values.checkBool !== undefined) {
      this.checkBool = params.values.checkBool;
    }
    if (params.values.int !== undefined) {
      this.int = params.values.int;
    }
    if (params.values.tamanocelda !== undefined) {
      this.tamanocelda = params.values.tamanocelda;
    }
    if (params.values.tienecalculos !== undefined) {
      this.tienecalculos = params.values.tienecalculos;
    }
    if (params.values.noblanco !== undefined) {
      this.noblanco = params.values.noblanco;
    }
    if (params.values.autorizacion !== undefined) {
      this.noblanco = params.values.noblanco;
    }
    if (params.values.focusout !== undefined) {
      this.focusout = params.values.focusout;
    }
    this.objeto = params.column.colDef.objeto;
    this.columidactual = params.column.colId;
    this.nosaltar = false;
  }

  getValue(): any {
    if (this.fecha === true) {
      if (this.value.toString().includes('-')) {
        this.value = this.datePipe.transform(this.value, 'dd/MM/yyyy');
      } else {
        this.value = this.value;
      }
    }
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    // let rex = new RegExp('^[0-9]*\.[0-9][0-9]$');
    return false;
  }

  onKeyUp(event) {
    if (this.onlymayusculas) {
      try {
        this.value = this.value.toUpperCase();
      } catch (err) {
      }
    }
  }

  focusnewRowEdit() {
    const index = this.gridApi.getFocusedCell().rowIndex;
    const columnas: any = this.params.columnApi.getAllColumns();
    let colid: string;

    colid = columnas[0].colId;
    if (colid !== undefined) {
      this.gridApi.setFocusedCell(index + 1, colid);
      this.gridApi.forEachNode((node) => {
        const h = index + 1;
        if (Number(node.id) === h) {
          node.setSelected(true);
        }
      });
    }
  }

  onKeyDownFecha(event) {

    if (this.aggrid.notlistenkey === true) {
      event.preventDefault();
      return;
    }

    this.nosaltar = false;
    if (event.keyCode === 13) {
      const index = this.gridApi.getFocusedCell().rowIndex;
      // bandera que permite saber si se puede o no hacer un nuevo registro
      let bandera = true;
      // console.log(index);
      const newdata = {};
      // console.log(this.gridApi.getEditingCells());

      if (this.tienecalculos) {
        this.calculos(event);
      }


      if (this.newrow === true) {
        const immutableStore = [];
        // bandera que permite saber si se puede o no hacer un nuevo registro
        bandera = this.isNewRow(this.params.columnApi.getAllGridColumns(), this.gridApi.getRowNode(index).data);
        if (bandera) {
          this.gridApi.forEachNode((rowNode, rowindex) => {
            immutableStore.push(rowNode.data);
          });
          immutableStore.splice(index + 1, 0, newdata);

          this.gridApi.setRowData(immutableStore);
          this.focusnewRowEdit();
        }
      } else {
        const index = this.gridApi.getFocusedCell().rowIndex;
        const columnas: any = this.params.columnApi.getAllColumns();
        let colid: string;
        let colindex = -1;
        for (const i in columnas) {
          if (columnas[i].colId === this.columidactual) {
            colindex = (+i) + this.saltoslinea;
          }
          if ((+i) === colindex) {
            colid = columnas[i].colId;
          }
        }
        if (colid !== undefined && this.nosaltar === false) {
          this.gridApi.setFocusedCell(index, colid);
          this.gridApi.ensureColumnVisible(colid);
        }
      }
    }
  }

  funcionFocusOut(event) {
    if (this.focusout) {
      this.calculos(event);
    }
  }
  //GSRF
focuact(event){
  this.rolProcesoSrv.numcellnomencrol=this.gridApi.getFocusedCell().rowIndex;
  this.encfacproService.numcellfacpro=this.gridApi.getFocusedCell().rowIndex;
  this.encfacproService.idcellfacpro=this.gridApi.getFocusedCell().column.colId; 
  this.comDevFacService.idcelldevfacpro=this.gridApi.getFocusedCell().column.colId;
  this.aggrid.idcell=this.gridApi.getFocusedCell().column.colId
  this.idcell1=this.gridApi.getFocusedCell().column.colId;
  console.log('celllocal ',  this.idcell1 )
  console.log('cellenvio ',  this.aggrid.idcell )
  this.aggrid.rowcell = this.gridApi.getFocusedCell().rowIndex;
  this.rowcell1=this.gridApi.getFocusedCell().rowIndex;
  console.log('rowcell ',  this.aggrid.rowcell )
}

focudes(event){

  if(this.aggrid.displayDialogBusqueda !== true && this.aggrid.displayDialogOpcionesDetProv!== true && this.aggrid.displayDialogBusquedaFast !== true && event.keyCode !== 13 ){
  this.gridApi.stopEditing();
  console.log('AQUIIIIIIUU222222') 
  console.log('cellid ',  this.idcell1 )
  console.log('cellrow',  this.rowcell1 )
  this.gridApi.setFocusedCell(this.rowcell1, this.idcell1);
  this.gridApi.startEditingCell({rowIndex: this.rowcell1, colKey: this.idcell1});
  window.alert('Presione enter para terminar edición de la celda');
  return;
  
  }
}

//GSRF

  onKeyDown(event): void {
    if (this.aggrid.notlistenkey === true) {
      event.preventDefault();
      return;
    }
    if (event.key === 'META' || event.key === 'Meta'){
      event.stopPropagation()
      event.preventDefault();
      return;
    }
    console.log('***>aqui8888 ', this.gridApi.selected)
    console.log(event)
    console.log(event.key)
    this.nosaltar = false;
    // console.log(event.key);
    if (event.keyCode === 13 || event.keyCode === 9 || event.keyCode === 37 || event.keyCode === 38 || event.keyCode === 39 || event.keyCode === 40 || event.keyCode === 27) {
       //console.log("se preciono el enter");
      const index = this.gridApi.getFocusedCell().rowIndex;
      //GSRF
      this.gridApi.stopEditing();
      this.aggrid.idcell='';
      this.aggrid.rowcell='';
     //GSRF
      let bandera = true;
      // bandera que permite saber si se puede o no hacer un nuevo registro
      // console.log(index);
      const newdata = {};
      // console.log(this.gridApi.getEditingCells());

      if (this.opcionbuscar) {
        this.busquedasEditor();
      }

      // console.log(this.tienecalculos);
      if (this.tienecalculos) {
        this.calculos(event);
      }

      if (this.noblanco) {
        this.llenarceldas();
      }

      if (this.newrow) {
        let immutableStore = [];

        // console.log(this.gridApi.getRowNode(index));
        // console.log(this.params.columnApi.getAllGridColumns()[0]);

        bandera = this.isNewRow(this.params.columnApi.getAllGridColumns(), this.gridApi.getRowNode(index).data);
        // console.log(bandera);
        if (bandera) {
          this.gridApi.stopEditing();
          this.gridApi.forEachNode((rowNode, rowindex) => {
            immutableStore.push(rowNode.data);
          });

          for (const o in this.gridApi.getRowNode(index).data) {
            newdata[o] = '';
          }

          if (this.objeto === 'maeartprecio') {
            this.artService.saveprecioservice(immutableStore[index]);
            newdata['ART_CODIGO'] = this.gridApi.getRowNode(index).data.ART_CODIGO;
            newdata['COM_CODIGO'] = '01';
            // immutableStore.splice(index + 1, 0, newdata);
            this.artService.maeartprecio = immutableStore;
            this.artService.maeartprecio.splice(index + 1, 0, newdata);
            immutableStore = this.artService.maeartprecio;
          }

          if (this.objeto === 'maeartparte') {
            this.artService.saveparteservice(immutableStore[index]);
            newdata['ART_CODIGO'] = this.gridApi.getRowNode(index).data.ART_CODIGO;
            newdata['COM_CODIGO'] = '01';
            this.artService.maeartparte = immutableStore;
            this.artService.maeartparte.splice(index + 1, 0, newdata);
            immutableStore = this.artService.maeartparte;
          }

          if (this.objeto === 'maeartformula') {
            this.artService.saveformulaservice(immutableStore[index]);
            newdata['ART_CODIGO'] = this.gridApi.getRowNode(index).data.ART_CODIGO;
            newdata['COM_CODIGO'] = '01';
            this.artService.maeartformula = immutableStore;
            this.artService.maeartformula.splice(index + 1, 0, newdata);
            immutableStore = this.artService.maeartformula;
          }

          if (this.objeto === 'maecostoestand') {
            this.artService.savecostoestservice(immutableStore[index]);
            newdata['ART_CODIGO'] = this.gridApi.getRowNode(index).data.ART_CODIGO;
            newdata['COM_CODIGO'] = '01';
            this.artService.maecostoestand = immutableStore;
            this.artService.maecostoestand.splice(index + 1, 0, newdata);
            immutableStore = this.artService.maecostoestand;
          }

          if (this.objeto === 'maeexistbod') {
            this.artService.saveexistbodservice(immutableStore[index]);
            newdata['ART_CODIGO'] = this.gridApi.getRowNode(index).data.ART_CODIGO;
            newdata['COM_CODIGO'] = '01';
            this.artService.maeexistbod = immutableStore;
            this.artService.maeexistbod.splice(index + 1, 0, newdata);
            immutableStore = this.artService.maeexistbod;
          }
          if (this.objeto === 'commaeproveedorRt') {
            this.aggrid.onCellKeyDown(event);

            newdata['RETENCION_CODIGO'] = '';
            newdata['RETENCION_NOMBRE'] = '';
            this.proveedorService.commaeproveedorRt = immutableStore;
            this.proveedorService.commaeproveedorRt.splice(index + 1, 0, newdata);
            immutableStore = this.proveedorService.commaeproveedorRt;
          }
          if (this.objeto === 'comnotrecRecepcion') {
            let ban = false;
            newdata['BOD_CODIGO'] = this.gridApi.getRowNode(index).data.BOD_CODIGO;
            newdata['DETNOTREC_CODIGO'] = '';
            newdata['DETNOTREC_TIPODET'] = 'A';
            newdata['DETNOTREC_CANTIDAD'] = 0;
            newdata['DETNOTREC_CANFACDEV'] = 0;
            newdata['DETNOTREC_COSTO'] = 0;
            newdata['DETNOTREC_PORFACTOR'] = 0;
            newdata['DETNOTREC_COSTOFOB'] = 0;
            newdata['DETNOTREC_ARTPESO'] = 0;
            newdata['DETNOTREC_CANTIDADUND'] = '0.0';
            newdata['COM_CODIGO'] = '01';
            newdata['DETNOTREC_DESPACHO'] = 0;
            newdata['DETNOTREC_DESCUENTO'] = 0;
            newdata['DETNOTREC_DESCUENTO2'] = 0;
            newdata['DETNOTREC_DESCUENTO3'] = 0;
            newdata['DETNOTREC_FACTOR'] = 0;
            newdata['DETNOTREC_LINEA'] = 0;
            newdata['LINEA'] = this.gridApi.getRowNode(index).data.LINEA + 1;
            //console.log( newdata['LINEA']);

            this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].map(aux => {
              if (aux.DETORDCOM_LINEA === index + 1) {
                ban = true;
              }

            });
            this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][index].DETORDCOM_LINEA = this.gridApi.getRowNode(index).data.LINEA + 1;
            if (!ban) {
              this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice] = immutableStore;
              this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].splice(index + 1, 0, newdata);

              immutableStore = this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice];
              /*
              this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice],this.objeto);
              this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].push(newdata);
              this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice],this.objeto);
             
              this.gridApi.setRowData(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice]);
              this.gridApi.setFocusedCell(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].length-1, 'DETNOTREC_CODIGO');
              this.gridApi.ensureIndexVisible(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].length-1, 'bottom'); */
            }

          }

          if (this.objeto === 'maebodega') {
            console.log(immutableStore[index]);
            immutableStore[index].BOD_FECHAVALIDEZ = this.datePipe.transform(immutableStore[index].BOD_FECHAVALIDEZ.toLocaleString()
              .substr(0, 10), 'dd/MM/yyyy');
            this.bodService.saveBodega(immutableStore[index]);
            const numero = Number(this.bodService.bodegas[this.bodService.bodegas.length - 1].BOD_CODIGO);
            newdata['BOD_CODIGO'] = '0' + String(numero + 1);
            this.bodService.bodegas = immutableStore;
            this.bodService.bodegas.splice(index + 1, 0, newdata);
            immutableStore = this.bodService.bodegas;
          }
          console.log(this.objeto);
          if (this.objeto === 'commaeproveedorFP') {
            console.log('entra asdfasdf');
            this.aggrid.onCellKeyDown(event);
            newdata['TRNFORMAPAGO_CODIGO'] = '';

            this.proveedorService.commaeproveedorFP = immutableStore;
            this.proveedorService.commaeproveedorFP.splice(index + 1, 0, newdata);
            immutableStore = this.proveedorService.commaeproveedorFP;
          }
          if (this.objeto === 'maetomafisica') {
            // newdata['BOD_CODIGO'] = '';
            // newdata['COM_CODIGO'] = '01';
            // newdata['ART_UNIDADCOSTEO'] = 'UNI';
            // this.tomaService.tomasfisicasDet = immutableStore;
            // this.tomaService.tomasfisicasDet.splice(index + 1, 0, newdata);
            // immutableStore = this.tomaService.tomasfisicasDet;
          }
          if (this.objeto === 'maemovimiento') {
            const numero = Number(immutableStore[index].TRNCAJ_NUMERO);
            newdata['TRNCAJ_NUMERO'] = String(numero + 1);
            newdata['CAJ_CODIGO'] = immutableStore[index].CAJ_CODIGO;
            newdata['TRNCAJ_DOCNRO'] = immutableStore[index].TRNCAJ_DOCNRO;
            newdata['TRNCAJ_TIPO'] = immutableStore[index].TRNCAJ_TIPO;
            newdata['TRNCAJ_ORIGEN'] = immutableStore[index].TRNCAJ_ORIGEN;
            this.cajaService.movimientos = immutableStore;
            this.cajaService.movimientos.splice(index + 1, 0, newdata);
            immutableStore = this.cajaService.movimientos;
          }
          if (this.objeto === 'maeunidad') {
            this.unidadService.saveUnidades(immutableStore[index]);
            newdata['UNI_CODIGO'] = '';
            this.unidadService.maeunidades = immutableStore;
            this.unidadService.maeunidades.splice(index + 1, 0, newdata);
            immutableStore = this.unidadService.maeunidades;
          }
          if (this.objeto === 'maeequivalencia') {
            console.log(immutableStore[index]);
            this.unidadService.saveEquivalencia(immutableStore[index], immutableStore[index].UNI_CODIGO);
            newdata['EQU_FACTOR'] = '';
            this.unidadService.maeequivalencias = immutableStore;
            this.unidadService.maeequivalencias.splice(index + 1, 0, newdata);
            immutableStore = this.unidadService.maeequivalencias;
          }
          if (this.objeto === 'maeentrada') {
            /* this.artService.saveparteservice(immutableStore[index]);
            newdata['ART_CODIGO'] = this.gridApi.getRowNode(index).data.ART_CODIGO;
            newdata['COM_CODIGO'] = '01';
            this.artService.maeartparte = immutableStore;
            this.artService.maeartparte.splice(index + 1, 0, newdata);
            immutableStore = this.artService.maeartparte;*/
          }
          if (this.objeto === 'vendetped') {
            newdata['BOD_CODIGO'] = this.gridApi.getRowNode(index).data.BOD_CODIGO;
            newdata['COM_CODIGO'] = '01';
            newdata['DETPED_TIPODET'] = 'A';
            newdata['DETPED_CODIGO'] = '';
            newdata['DETPED_LISTA'] = this.encpedService.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].ENCPED_LISTA;
            newdata['DETPED_LINEA'] = 0;
            newdata['LINEA'] = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice].length + 1;
            this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice] = immutableStore;
            this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice].splice(index + 1, 0, newdata);
            immutableStore = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice];
            this.gridApi.setFocusedCell(index, 'DETPED_CODIGO');

          }
          if (this.objeto === 'vendetguia') {
            newdata['BOD_CODIGO'] = this.gridApi.getRowNode(index).data.BOD_CODIGO;
           
            this.gridApi.setFocusedCell(index, 'DETGRE_CODIGO');

          }
          if (this.objeto === 'pagcon') {
            newdata['TRNPAGO_FECHA'] = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
            newdata['COM_CODIGO'] = '01';
            this.pagconservice.pagos = immutableStore;
            this.pagconservice.pagos.splice(index + 1, 0, newdata);
            immutableStore = this.pagconservice.pagos;
          }
          if (this.objeto === 'retpagcon') {
            newdata['COM_CODIGO'] = '01';
            this.pagconservice.reten = immutableStore;
            this.pagconservice.reten.splice(index + 1, 0, newdata);
            immutableStore = this.pagconservice.reten;
          }
          if (this.objeto === 'conencasil') {
            newdata['ASI_FECHA'] = new Date();
            newdata['ASI_NRO'] = '';
            newdata['BAN_CODIGO'] = '';
            newdata['CEN_CODIGO'] = '';
            newdata['CENSUB_CODIGO'] = '';
            newdata['COM_CODIGO'] = '01';
            newdata['CON_CODIGO'] = '';
            newdata['CON_NOMBRE'] = '';
            newdata['CXCTRNCOBRO_CLICODIGO'] = '';
            newdata['CXCTRNCOBRO_COMCODIGO'] = '';
            newdata['CXCTRNCOBRO_NRODOC'] = '';
            newdata['DETASI_HORA'] = '';
            newdata['DETASI_DEBE'] = Number(0).toFixed(this.confIniciales.getNumDecSist());
            newdata['DETASI_HABER'] = Number(0).toFixed(this.confIniciales.getNumDecSist());
            newdata['DETASI_ORDEN'] = 0;
            newdata['DETASI_REFERENCIA'] = '';
            newdata['ENCFAC_CLICODIGO'] = '';
            newdata['ENCFAC_COMCODIGO'] = '';
            newdata['ENCFAC_NUMERO'] = '';
            newdata['TRNBAN_BENEFICIARIO'] = '';
            newdata['TRNBAN_NUMERO'] = '';
            newdata['TRNBAN_TIPO'] = '';
            newdata['TRNRETENCION_NRO'] = '';
            newdata['RETENCION_CODIGO'] = '';
            newdata['DEPOSITADO_CONCILIADO'] = '';
            newdata['PROG_CODIGO'] = '';
            newdata['TIPGAS_CODIGO'] = '';
            newdata['DETASI_NROFILA'] = 0;
            newdata['LINEA'] = this.conecasilService.detencasil.length + 1;
            newdata['CON_TIPGAS'] = '';
            newdata['DETASI_IMPORTE'] = 0;
            newdata['DETASI_TIPOREG'] = '';

            if (Number(this.conecasilService.getConfiguraciones('OBSASIDET')) === 1) {
              newdata['DETASI_CONCEPTO'] = this.conecasilService.encabezadoEncasilSelect.ASI_DESCRIPCION;
              newdata['DETASI_CONCEPTOPRY'] = this.conecasilService.encabezadoEncasilSelect.ASI_DESCRIPCION;
            } else {
              newdata['DETASI_CONCEPTO'] = '';
              newdata['DETASI_CONCEPTOPRY'] = '';
            }
            //CDPJ
            this.conecasilService.detencasil= immutableStore;
            this.conecasilService.detencasil.splice(index + 1,0, newdata)
            
            //this.conencasilComponent.insertarLineaDetalle();
            // immutableStore = this.conencasilComponent.insertarLineaDetalle();
            immutableStore=this.conecasilService.detencasil
            // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultConEncasilDet.objeto].setFocusedCell(
            //   index + 1, 'CON_CODIGO');
            this.gridApi.setFocusedCell(index, 'CON_CODIGO');
            //CDPJ
          }

          if (this.objeto === 'bantarRetencion') {
            newdata['CLI_CODIGO'] = '';
            newdata['COM_CODIGO'] = '01';
            newdata['RETENCION_CODIGO'] = '';
            newdata['RETENCION_AUTORIZACION'] = '';
            newdata['TRNRETENCION_BASE'] = 0;
            newdata['TRNRETENCION_BASE0'] = 0;
            newdata['TRNRETENCION_BASEG'] = 0;
            newdata['TRNRETENCION_BASENG'] = 0;
            newdata['TRNRETENCION_DESCRIPCION'] = '';
            newdata['TRNRETENCION_PORCENTAJE'] = 0;
            newdata['TRNRETENCION_SERIE'] = '';
            newdata['TRNRETENCION_TOTALRETENIDO'] = 0;
            newdata['CON_CODIGO'] = '';
            newdata['CEN_CODIGO'] = '';
            newdata['ENCFAC_NUMERO'] = '';
            newdata['TRNRETENCION_NRO'] = '';
            newdata['TRNRETENCION_FLAG'] = 1;
            newdata['LINEA'] = this.bantrntarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice].length + 1;
            newdata['TRNRETENCION_ORIGEN'] = 'FAC';


            this.bantrntarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice] = immutableStore;
            this.bantrntarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice].splice(index + 1, 0, newdata);
            immutableStore = this.bantrntarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice];
            // this.gridApi.setFocusedCell(index, 'CON_CODIGO');

          }

          if (this.objeto === 'bantarVarios') {
            newdata['TRNTARJETA_CODIGO'] = '';
            newdata['TRNTARVARIOS_NROSEC'] = 0;
            newdata['CON_CODIGO'] = '';
            newdata['CON_COMCODIGO'] = '';
            newdata['TRNTARVARIOS_DESCRIPCION'] = '';
            newdata['TRNTARVARIOS_IMPORTE'] = 0;
            newdata['COM_CODIGO'] = '01';
            newdata['CEN_CODIGO'] = '';
            newdata['DEBE'] = 0;
            newdata['HABER'] = 0;
            newdata['LINEA'] = this.bantrntarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice].length + 1;

            this.bantrntarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice] = immutableStore;
            this.bantrntarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice].splice(index + 1, 0, newdata);
            immutableStore = this.bantrntarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice];
            // this.gridApi.setFocusedCell(index, 'CON_CODIGO');

          }

          this.gridApi.setRowData(immutableStore);
          this.focusnewRowEdit();
        }
      } else {
        const columnas: any = this.params.columnApi.getAllColumns();
        let colid: string;
        let colindex = -1;
        for (const i in columnas) {
          if (columnas[i].colId === this.columidactual) {
            colindex = (+i) + this.saltoslinea;
          }
          if ((+i) === colindex) {
            colid = columnas[i].colId;
          }
        }
        if (colid !== undefined) {
          this.gridApi.setFocusedCell(index, colid);
          this.gridApi.ensureColumnVisible(colid);
          this.init.auxsalto = true;
        }
      }
    }

    let auxiliar = 0;
    if (typeof this.gridApi.getFocusedCell().column.colDef.editable === 'function') {
      this.gridApi.forEachNode((node) => {
        if (node.selected === true) {
          if (this.gridApi.getFocusedCell().column.colDef.editable(node) === false) {
            auxiliar++;
          }
        }
      });
    }

    if (auxiliar > 0) {
      event.preventDefault();
    }

    if (this.value !== '' && this.clave === true) {
      event.preventDefault();
    } else {
      this.clave = false;
    }

    if (this.onlynumber === false && this.onlyalphabetic === false) {
      this.ExpReg2(event);
    }

    
    //CDPJ
    if (this.onlynumber && this.onlyalphabetic === false && this.onlynumberposneg === true) {
      this.ExpRegposneg(event);
    }else{
      if (this.onlynumber && this.onlyalphabetic === false) {
        this.ExpReg(event);
      } else {
        if (!this.isKeyPressedNumeric(event)) {
          if (event.preventDefault) {
            event.preventDefault();
          }
        }
      }
    }
    //CDPJ
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }

  private getCharCodeFromEvent(event): any {
    event = event || window.event;
    return (typeof event.which === 'undefined') ? event.keyCode : event.which;
  }

  private isAlphabetic(charStr): boolean {

    const str = new RegExp('/^[A-z]');
    return /[a-zA-Z ]+/.test(charStr);
  }

  private isCharNumeric(charStr): boolean {
    console.log(charStr);
    let text;
    let conpuntos;
    let numdecimales = 0;
    if (this.value !== undefined) {
      text = this.value.toString();
      conpuntos = text.indexOf('.');
      text += charStr;
      try {
        numdecimales = text.split('.')[1].length;
        // se obtiene el numero de decimales
      } catch (err) {
        numdecimales = 0;
      }
    } else {
      conpuntos = -1;
    }
    if (charStr === 'Backspace') {
      return true;
    } else {
      if (this.decimales === 0) {
        try {
          const num = +charStr;
          if (num > -1 && charStr !== '.') {
            return true;
          } else {
            return false;
          }
        } catch (err) {
          return false;
        }
      }
      if (numdecimales > this.decimales) {
        return false;
      } else {
        if (charStr === '.') {
          if (conpuntos >= 0) {
            return false;
          } else {
            return true;
          }
        } else {
          try {
            const num = +charStr;
            if (num > -1) {
              return true;
            }
          } catch (err) {
            return false;
          }
        }
      }
    }
    // return /^\d(\.\d)*$/.test(charStr);
  }

  private isKeyPressedNumeric(event): boolean {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    if (this.onlyalphabetic && this.onlynumber) {
      return true;
    } else {
      if (this.onlyalphabetic) {
        return this.isAlphabetic(charStr);
      } else if (this.onlynumber) {
        return this.esCaracterNumerico(charStr);
      } else {
        return false;
      }
    }
    // return true;
  }

  private busqueda(columnas, data): boolean {
    let bandera = true;
    for (const col of columnas) {
      try {
        if (col.colDef.cellEditorParams.values.opcionbuscar) {
          if (data[col.colId] === undefined || data[col.colId] === null || data[col.colId] === '') {
            return false;
          }
        }
      } catch (err) {
        bandera = true;
      }
    }
    return bandera;
  }

  private isNewRow(columnas, data): boolean {
    // console.log(columnas, data);
    let bandera = true;
    for (const col of columnas) {
      try {
        if (col.colDef.cellEditorParams.values.obligatorio) {
          if (data[col.colId] === undefined || data[col.colId] === null || data[col.colId] === '') {
            return false;
          }
        }
      } catch (err) {
        bandera = true;
      }
    }
    return bandera;
  }


  private esCaracterNumerico(charStr): boolean {
    // console.log(charStr);


    if (charStr === 'Dead') {
      return false;
    }
    if (charStr === '-') {
      return false;
    }

    let text;
    let conpuntos;
    let numdecimales = 0;
    if (this.value !== undefined) {
      text = this.value.toString();
      conpuntos = text.indexOf('.');
      text += charStr;
      try {
        numdecimales = text.split('.')[1].length; // se obtiene el numero de decimales
      } catch (err) {
        numdecimales = 0;
      }
    } else {
      conpuntos = -1;
    }
    if (charStr === 'Backspace') {
      return true;
    } else {
      if (this.decimales === 0) {
        try {
          const num = +charStr;
          if (num > -1 && charStr !== '.') {
            return true;
          } else {
            return false;
          }
        } catch (err) {
          return false;
        }
      }
      if (numdecimales > this.decimales) {
        return false;
      } else {
        if (charStr === '.') {
          if (conpuntos >= 0) {
            return false;
          } else {
            return true;
          }
        } else {
          try {
            const num = +charStr;
            if (num > -1) {
              return true;
            }
          } catch (err) {
            return false;
          }
        }
      }
    }

    // return /^\d(\.\d)*$/.test(charStr);
  }

  ExpReg(event) {
    console.log('positivos')
    const expresion = '^\\d*\\.?\\d{0,' + this.decimales + '}$';
    const regex: RegExp = new RegExp(expresion, 'g');
    const specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ñ', 'Ñ'];
    if (specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const next: string = (this.value + '').concat(event.key);
    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }
  ExpRegposneg(event){
    console.log('negativos')
    const expresion = '^-?\\d*\\.?\\d{0,' + this.decimales + '}$';
    const regex: RegExp = new RegExp(expresion, 'g');
    const specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ñ', 'Ñ'];
    if (specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const next: string = (this.value + '').concat(event.key);
    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }
  ExpReg2(event) {
    const specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ñ', 'Ñ'];
    if (specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    event.preventDefault();
  }

  busquedasEditor() {
    const index = this.gridApi.getFocusedCell().rowIndex;
    const immutableStore = [];
    this.gridApi.stopEditing();
    this.gridApi.forEachNode((rowNode, rowindex) => {
      immutableStore.push(rowNode.data);
    });


    if (this.columidactual === 'UNI_CODIGODESTINO') {
      if (this.gridApi.aggFuncService === 'maeequivalencia') {
        this.aggrid.busquedaunidad(immutableStore[index], 'UNI_CODIGODESTINO');
      }
    }

    if (this.columidactual === 'UNI_CODIGO') {
      if (this.gridApi.aggFuncService === 'maeartprecio') {
        this.aggrid.busquedaunidad(immutableStore[index], 'busquedaunidadprecio');
      }
      if (this.gridApi.aggFuncService === 'maeartparte') {
        this.aggrid.busquedaunidad(immutableStore[index], 'busquedaunidadparte');
      }
      if (this.gridApi.aggFuncService === 'maeartformula') {
        //this.aggrid.busquedaunidad(immutableStore[index], 'busquedaunidadformula');
        //CDPJ
        if (immutableStore[index].ART_MULTIUNIDAD === 'S'){
          this.aggrid.busquedaunidad(immutableStore[index], 'busquedaunidadformula');
        }
        //CDPJ
      }
    }

    if (this.columidactual === 'ART_CODIGOP') {
      if (this.gridApi.aggFuncService === 'maeartparte') {
        this.aggrid.busquedaarticulodetalle(immutableStore[index], 'busquedaarticuloparte', immutableStore[index].ART_CODIGOP);
      }
      if (this.gridApi.aggFuncService === 'maeartformula') {
        this.aggrid.busquedaarticulodetalle(immutableStore[index], 'busquedaarticuloformula', immutableStore[index].ART_CODIGOP);
      }
    }

    if (this.columidactual === 'BOD_CODIGO') {
      if (this.gridApi.aggFuncService === 'maeexistbod') {
        this.aggrid.busquedabodega(immutableStore[index], 'busquedabodega');
      }
      if (this.gridApi.aggFuncService === 'maesalida') {
        this.aggrid.busquedabodega(immutableStore[index], 'busquedabodegasal');
      }
      if (this.gridApi.aggFuncService === 'maeentrada') {
        this.aggrid.busquedabodega(immutableStore[index], 'busquedabodegaent');
      }
      if (this.gridApi.aggFuncService === 'detfac') {
        this.aggrid.busquedabodega(immutableStore[index], 'busquedabodegadetfac');
      }
      if (this.gridApi.aggFuncService === 'detfacpv') {
        this.aggrid.busquedabodega(immutableStore[index], 'busquedabodegadetfacpv');
      }
      if (this.gridApi.aggFuncService === 'vendetped') {
        this.aggrid.busquedabodega(immutableStore[index], 'busquedabodegaped');
      }
      if (this.gridApi.aggFuncService === 'detfacpro') {
        this.aggrid.busquedabodega(immutableStore[index], 'busquedabodegadetfacpro');
      }
      if (this.gridApi.aggFuncService === 'invtrnajustes') {
        this.aggrid.busquedabodega(immutableStore[index], 'BOD_CODIGOAJUSTE');
      }
      if (this.gridApi.aggFuncService === 'vendetguia') {
        console.log('aqui')
        this.aggrid.busquedabodega(immutableStore[index], 'BOD_CODIGOGUIAREM');
      }
      if (this.gridApi.aggFuncService === 'maetomafisica') {
        this.aggrid.busquedabodega(immutableStore[index], 'busquedaToma');
      }
      if (this.gridApi.aggFuncService === 'detalleordentrabajo') {
        this.aggrid.busquedabodega(immutableStore[index], 'bodordentrabajo');
      }
    }

    if (this.columidactual === 'BOD_CODIGODES') {
      if (this.gridApi.aggFuncService === 'invtrnreservas') {
        this.aggrid.busquedabodegaReserva(immutableStore[index], 'BOD_CODIGORESERVA');
      }
    }

    if (this.columidactual === 'CIN_CODIGO') {
      if (this.gridApi.aggFuncService === 'maecostoest') {
        this.aggrid.busquedacostosind(immutableStore[index], 'busquedacostosind');
      }
      if (this.gridApi.aggFuncService === 'trncostoind') {
        this.aggrid.busquedacostosind(immutableStore[index], 'busquedaprodcostosind');
      }
    }
    if (this.columidactual === 'CAJ_CODIGO') {
      console.log('1.1',this.gridApi.aggFuncService);
      if (this.gridApi.aggFuncService === 'formaPago') {
        this.aggrid.busquedaCaja(immutableStore[index], 'busquedaCaja', immutableStore[index].CAJ_CODIGO);//CDPJ
      }
      if (this.gridApi.aggFuncService === 'cxcformapago') {
        if (immutableStore[index].TRNPAGO_TIPO.trim() === 'EF' || immutableStore[index].TRNPAGO_TIPO.trim() === 'CH'
          || immutableStore[index].TRNPAGO_TIPO.trim() === 'ANCL' || immutableStore[index].TRNPAGO_TIPO.trim() === 'TA') {
          this.aggrid.busquedaCaja(immutableStore[index], 'busquedaCajaformapago', immutableStore[index].CAJ_CODIGO);//CDPJ
        }
      }
      //CDPJ
      // if (this.gridApi.aggFuncService === 'cxctrnanticipoformapago') {
      //   if (immutableStore[index].TRNPAGO_TIPO.trim() === 'EF' || immutableStore[index].TRNPAGO_TIPO.trim() === 'CH'
      //     || immutableStore[index].TRNPAGO_TIPO.trim() === 'ANCL' || immutableStore[index].TRNPAGO_TIPO.trim() === 'TA') {
      //     this.aggrid.busquedaCaja(immutableStore[index], 'busquedaCajaTrnanticipoformapago', immutableStore[index].CAJ_CODIGO);//CDPJ
      //   }
      // }
      if (this.gridApi.aggFuncService === 'cxctrnabonoformapago') {
        if (immutableStore[index].TRNPAGO_TIPO.trim() === 'EF' || immutableStore[index].TRNPAGO_TIPO.trim() === 'CH'
          || immutableStore[index].TRNPAGO_TIPO.trim() === 'ANCL' || immutableStore[index].TRNPAGO_TIPO.trim() === 'TA') {
          this.aggrid.busquedaCaja(immutableStore[index], 'busquedaCajaTrnabonoformapago', immutableStore[index].CAJ_CODIGO);//CDPJ
        }
      }
      if (this.gridApi.aggFuncService === 'cxctrncancelacionformapago') {
        if (immutableStore[index].TRNPAGO_TIPO.trim() === 'EF' || immutableStore[index].TRNPAGO_TIPO.trim() === 'CH'
          || immutableStore[index].TRNPAGO_TIPO.trim() === 'ANCL' || immutableStore[index].TRNPAGO_TIPO.trim() === 'TA') {
          this.aggrid.busquedaCaja(immutableStore[index], 'busquedaCajaTrncancelacionformapago', immutableStore[index].CAJ_CODIGO);//CDPJ
        }
      }
      if (this.gridApi.aggFuncService === 'pagcon') {
        if (immutableStore[index].TRNPAGO_TIPO.trim() === 'EF' || immutableStore[index].TRNPAGO_TIPO.trim() === 'CH'
          || immutableStore[index].TRNPAGO_TIPO.trim() === 'ANCL' || immutableStore[index].TRNPAGO_TIPO.trim() === 'TA') {
          this.aggrid.busquedaCaja(immutableStore[index], 'busquedaCajaPago', immutableStore[index].CAJ_CODIGO);//CDPJ
        }
      }
      if (this.gridApi.aggFuncService === 'cxcpagoretformapago') {
        if (immutableStore[index].TRNPAGO_TIPO.trim() === 'EF') {
          this.aggrid.busquedaCaja(immutableStore[index], 'busquedaCajaCXCpagoret', immutableStore[index].CAJ_CODIGO);//CDPJ
        }
      }
      if (this.gridApi.aggFuncService === 'pagcondvf') {
        this.aggrid.busquedaCaja(immutableStore[index], 'busquedaCajaPagodvf', immutableStore[index].CAJ_CODIGO);//CDPJ
      }
      if (this.gridApi.aggFuncService === 'cxcformapagodev') {
        if (immutableStore[index].TRNPAGO_TIPO.trim() === 'EF' || immutableStore[index].TRNPAGO_TIPO.trim() === 'CH') {
          this.aggrid.busquedaCaja(immutableStore[index], 'busquedaCajaformapagodev', immutableStore[index].CAJ_CODIGO);//CDPJ
        }
      }
      //CDPJ
    }
    if (this.columidactual === 'FORMAPAGO_CODIGOSRI') {
      if (this.gridApi.aggFuncService === 'formaPago') {
        this.aggrid.busquedaCodigoSRI(immutableStore[index], 'busquedaCodigoSRI');
      }
    }
    if (this.columidactual === 'CLI_CODIGO') {
      if (this.gridApi.aggFuncService === 'venmaepry') {
        this.aggrid.busquedaCliente(immutableStore[index], 'busquedaCodCli');
      }
    }


    if (this.columidactual === 'ART_CODIGO') {
      if (this.gridApi.aggFuncService === 'maesalida') {
        this.aggrid.busquedaarticuloexistencia(immutableStore[index], 'busquedaarticulodetallesalida',
          immutableStore[index].ART_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'maelote') {
        this.aggrid.busquedaarticulodetalle(immutableStore[index], 'artmaelote', immutableStore[index].ART_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'maeentrada') {
        this.aggrid.busquedaarticuloexistenciaEnt(immutableStore[index], 'artEntrada',
          immutableStore[index].ART_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'maetomafisica') {
        this.aggrid.busquedaarticuloexistenciaTom(immutableStore[index], 'tomaBusArt',
          immutableStore[index].ART_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'dettransfer') {
        this.aggrid.busquedaarticulotransferencia(immutableStore[index], 'dettransfer',
          immutableStore[index].ART_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'invtrnajustes') {
        this.aggrid.busquedaarticulodetalle(immutableStore[index], 'ARTCODIGO_AJUSTE',
          immutableStore[index].ART_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'invtrnreservas') {
        this.aggrid.busquedaarticuloreserva(immutableStore[index], 'ARTCODIGO_RESERVA', immutableStore[index].ART_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'detordpro') {
        this.aggrid.busquedaarticuloreserva(immutableStore[index], 'artcodigodetordpro', immutableStore[index].ART_CODIGO);
      }
    }

    if (this.columidactual === 'CON_CODIGO') {
      if (this.gridApi.aggFuncService === 'maebodega') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'maesalidavar') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOSAL', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'maemovimiento') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_MOV', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'maetomafisicavar') {
        this.aggrid.busquedaCON(immutableStore[index], 'TOM_CON', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'maeentradavar') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOENT', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'maegroup') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_GRUPO', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'maeservicios') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOSERV', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'commaeservicios') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_SERVICIO', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'commaegrupo') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_COM_GRUPO', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'maecargos') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CARGOS', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'comision') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_COMISION', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'cxcmaerentencion') {
        this.aggrid.busquedaCON(immutableStore[index], 'RETENCION', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'cxcmaetarjetacredito') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOT', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'districc') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGODISTRI', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'cxppagovarios') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOCXPVARIOS', immutableStore[index].CON_CODIGO);
      }

      if (this.gridApi.aggFuncService === 'conencasil') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOENCASIL', immutableStore[index].CON_CODIGO);
      }

      if (this.gridApi.aggFuncService === 'banTrnBanVar') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOBANVARIOS', immutableStore[index].CON_CODIGO);
      }

      if (this.gridApi.aggFuncService === 'contrnDistribcc') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGODISTRIBCC', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'bantarVarios') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOTRNTARJETA', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'bancajatrnVarios') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOBANCAJA', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'invtrnajustes') {
        this.aggrid.busquedaCON(immutableStore[index], 'CONCODIGO_AJUSTES', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'cxpcuotavar') {
        this.aggrid.busquedaCON(immutableStore[index], 'CONCODIGO_CXPCUOTA', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'nomliqdecetervar') {
        console.log('entra');
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGO_LIQDECEVAR', immutableStore[index].CON_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'actdetvarsal') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGO_ACTSALIDA', immutableStore[index].CON_CODIGO);
      }
    }

    if (this.columidactual === 'CON_CODIGO2') {
      if (this.gridApi.aggFuncService === 'commaeservicios') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_SERVICIOS2', immutableStore[index].CON_CODIGO2);
      }
    }
    if (this.columidactual === 'CON_CODIGO_VTA') {
      if (this.gridApi.aggFuncService === 'cxcmaerentencion') {
        this.aggrid.busquedaCON(immutableStore[index], 'RETENCIONvta', immutableStore[index].CON_CODIGO_VTA);
      }
    }
    if (this.columidactual === 'CON_CODIGOGAS') {
      this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOGAS', immutableStore[index].CON_CODIGOGAS);
    }
    //CDPJ
       if (this.columidactual === 'CON_CODIGOACT') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOACT', immutableStore[index].CON_CODIGOACT);
      }
    //CDPJ
    if (this.columidactual === 'CON_CODIGOING') {
      this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOING', immutableStore[index].CON_CODIGOING);
    }
    if (this.columidactual === 'CON_CODIGOCOM') {
      this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOCOM', immutableStore[index].CON_CODIGOCOM);
    }
    if (this.columidactual === 'CON_CODIGORFTE') {
      this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGORFTE', immutableStore[index].CON_CODIGORFTE);
    }
    if (this.columidactual === 'CON_CODIGOFIVA') {
      this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGOFIVA', immutableStore[index].CON_CODIGOFIVA);
    }
    if (this.columidactual === 'MAEPRM_ARTSRV') {
      if (this.gridApi.aggFuncService === 'maePromocion') {
        console.log(immutableStore[index].MAEPRM_TIPO,
          immutableStore[index].GRUP_CODIGO);
        if (immutableStore[index].MAEPRM_TIPO === 'A'
          && (immutableStore[index].GRUP_CODIGO === undefined
            || immutableStore[index].GRUP_CODIGO === null
            || immutableStore[index].GRUP_CODIGO === '')) {
          this.aggrid.busquedaarticulodetalle(immutableStore[index], 'busquedaPromocion', immutableStore[index].MAEPRM_ARTSRV);
        }
        if (immutableStore[index].MAEPRM_TIPO === 'S'
          && (immutableStore[index].GRUP_CODIGO === undefined
            || immutableStore[index].GRUP_CODIGO === null
            || immutableStore[index].GRUP_CODIGO === '')) {
          this.aggrid.busquedaserviciopro(immutableStore[index], 'busquedaPromocionServicio', immutableStore[index].MAEPRM_ARTSRV);
        }
      }
    }
    /*if (this.columidactual === 'MAEPRM_ARTSRV') {
      if (this.gridApi.aggFuncService === 'maePromocion') {
        this.aggrid.busquedaserviciopro(immutableStore[index], 'busquedaPromocionServicio', immutableStore[index].MAEPRM_ARTSRV);
      }
    }*/
    if (this.columidactual === 'MAEPRM_ARTORG') {
      if (this.gridApi.aggFuncService === 'maePromocion') {
        if (immutableStore[index].MAEPRM_TIPO === 'A') {
          this.aggrid.busquedaarticulodetalle(immutableStore[index], 'busquedaPromocion2', immutableStore[index].MAEPRM_ARTORG);
        }
        if (immutableStore[index].MAEPRM_TIPO === 'S'
          && (immutableStore[index].GRUP_CODIGO === undefined
            || immutableStore[index].GRUP_CODIGO === null
            || immutableStore[index].GRUP_CODIGO === '')) {
          this.aggrid.busquedaserviciopro(immutableStore[index], 'busquedaPromocionServicio2', immutableStore[index].MAEPRM_ARTORG);
        }
      }
    }
    if (this.columidactual === 'MAEDSCTO_ARTSVR') {
      if (this.gridApi.aggFuncService === 'maePromocion') {
        this.aggrid.busquedaarticulodetalle(immutableStore[index], 'busquedaDescuento', immutableStore[index].MAEDSCTO_ARTSVR);
      }
    }
    if (this.columidactual === 'MAEDSCTO_ARTSVR') {
      if (this.gridApi.aggFuncService === 'maePromocion') {
        this.aggrid.busquedaserviciopro(immutableStore[index], 'busquedadescuentoServicio', immutableStore[index].MAEDSCTO_ARTSVR);
      }
    }
    if (this.columidactual === 'GRUP_CODIGO') {
      if (this.gridApi.aggFuncService === 'maePromocion') {
        if (immutableStore[index].MAEPRM_TIPO === 'A'
          && (immutableStore[index].MAEPRM_ARTSRV === undefined
            || immutableStore[index].MAEPRM_ARTSRV === null
            || immutableStore[index].MAEPRM_ARTSRV === '')
        ) {
          this.aggrid.busquedagrupoPro(immutableStore[index], 'busquedaGrupoPromocion', immutableStore[index].GRUP_CODIGO);
        }

      }
    }
    if (this.columidactual === 'CON_CODIGO') {
      if (this.gridApi.aggFuncService === 'maeBanCaja') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_BANCAJA', immutableStore[index].CON_CODIGO);
      }
    }

    if (this.columidactual === 'RETENCION_CODIGO') {
      if (this.gridApi.aggFuncService === 'commaeproveedorRt') {
        this.aggrid.busquedanombreRetencion(immutableStore[index], 'busquedaNombre', immutableStore[index].RETENCION_CODIGO);

      }
      if (this.gridApi.aggFuncService === 'retpagcon') {
        this.aggrid.busquedaretencion(immutableStore[index], 'busquedaretencion',
          immutableStore[index].RETENCION_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'trnretencion') {
        this.aggrid.busquedaretencion(immutableStore[index], 'trnretencion',
          immutableStore[index].RETENCION_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'cxcpagoretret') {
        this.aggrid.busquedaretencion(immutableStore[index], 'cxcpagoretret',
          immutableStore[index].RETENCION_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'cxcretenciones') {
        this.aggrid.busquedaretencion(immutableStore[index], 'cxcretenciones',
          immutableStore[index].RETENCION_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'bantarRetencion') {
        this.aggrid.busquedaretencion(immutableStore[index], 'BANTARJETA_RETENCION',
          immutableStore[index].RETENCION_CODIGO);
      }
      //CDPJ
      if (this.gridApi.aggFuncService === 'cxctrnretenciones') {
        this.aggrid.busquedaretencion(immutableStore[index], 'cxctrnretenciones',
          immutableStore[index].RETENCION_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'cxctrnabonoretenciones') {
        this.aggrid.busquedaretencion(immutableStore[index], 'cxctrnabonoretenciones',
          immutableStore[index].RETENCION_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'cxctrncancelacionretenciones') {
        this.aggrid.busquedaretencion(immutableStore[index], 'cxctrncancelacionretenciones',
          immutableStore[index].RETENCION_CODIGO);
      }
      //CDPJ
    }

    if (this.columidactual === 'TRNFORMAPAGO_CODIGO') {
      if (this.gridApi.aggFuncService === 'commaeproveedorFP') {
        this.aggrid.busquedaFormaPago(immutableStore[index], 'busquedaNombresri', immutableStore[index].TRNFORMAPAGO_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'datosanexos') {
        this.aggrid.busquedaFormaPago(immutableStore[index], 'busquedafpdatoanexos', immutableStore[index].TRNFORMAPAGO_CODIGO);
      }
    }

    if (this.columidactual === 'NUM_DOCREF') {
      if (this.gridApi.aggFuncService === 'maeNumeracionC') {
        this.aggrid.busquedaSRI(immutableStore[index], 'NUM_DOCREFBUS');
      }
    }

    if (this.columidactual === 'SER_FORMSRICOM') {
      if (this.gridApi.aggFuncService === 'commaeservicios') {
        this.aggrid.busquedaSRI(immutableStore[index], 'SRI_BUS');
      }
    }
    if (this.columidactual === 'CEN_CODIGO') {
      console.log('cen',this.gridApi.aggFuncService);
      if (this.gridApi.aggFuncService === 'maeBanCaja') {
        this.aggrid.busquedaCEN(immutableStore[index], 'CEN_BANCAJA');
      }
      if (this.gridApi.aggFuncService === 'commaeservicios') {
        this.aggrid.busquedaCEN(immutableStore[index], 'CEN_SERVICIO');
      }
      if (this.gridApi.aggFuncService === 'districc') {
        this.aggrid.busquedaCEN(immutableStore[index], 'CEN_CODIGODISTRI');
      }
      if (this.gridApi.aggFuncService === 'cxppagovarios') {
        if (immutableStore[index].CON_CENTRO === 'Si') {
          this.aggrid.busquedaCentroCostos(immutableStore[index], 'CEN_CODIGOCXPVARIOS', immutableStore[index].CEN_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'conencasil') {
        if (immutableStore[index].CON_CENTRO === 'Si') {
          this.aggrid.busquedaCentroCostos(immutableStore[index], 'CEN_CONENCASIL', immutableStore[index].CEN_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'banTrnBanVar') {
        if (immutableStore[index].CON_CENTRO === 'Si') {
          this.aggrid.busquedaCentroCostos(immutableStore[index], 'CEN_BANVARIOS', immutableStore[index].CEN_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'contrnDistribcc') {
        this.aggrid.busquedaCentroCostos(immutableStore[index], 'CEN_CONDISTRIBCC', immutableStore[index].CEN_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'bantarVarios') {
        if (immutableStore[index].CON_CENTRO === 'Si') {
          this.aggrid.busquedaCentroCostos(immutableStore[index], 'CEN_CODIGOTRNTARJETA', immutableStore[index].CEN_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'bancajatrnVarios') {
        if (immutableStore[index].CON_CENTRO === 'Si') {
          this.aggrid.busquedaCentroCostos(immutableStore[index], 'CEN_CODIGOBANCAJA', immutableStore[index].CEN_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'invtrnajustes') {
        if (immutableStore[index].CON_CENTRO === 'Si') {
          this.aggrid.busquedaCentroCostos(immutableStore[index], 'CENCODIGO_AJUSTES', immutableStore[index].CEN_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'cxpcuotavar') {
        if (immutableStore[index].CON_CENTRO === 'Si') {
          this.aggrid.busquedaCentroCostos(immutableStore[index], 'CENCODIGO_CXPCUOTA', immutableStore[index].CEN_CODIGO);
        }
      }

      // if (this.gridApi.aggFuncService === 'detfac') {
      //   this.aggrid.busquedaCentroCostos(immutableStore[index], 'DETFAC_CENCOD', immutableStore[index].CEN_CODIGO);
      // }
      if (this.gridApi.aggFuncService === 'detfac') {
        if (immutableStore[index].CON_CENTRO === 'Si') {
        this.aggrid.busquedaCEN(immutableStore[index], 'DETFAC_CENCOD');
        }
      }
      if (this.gridApi.aggFuncService === 'nomliqdecetervar') {
        this.aggrid.busquedaCCVarios(immutableStore[index], 'CENCODIGO_LIQ_SER', immutableStore[index].CEN_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'vendetguia') {
        if (immutableStore[index].CON_CENTRO === 'Si') {
          //this.aggrid.busquedaCEN(immutableStore[index], 'CEN_GUIA');
          this.aggrid.busquedaCentroCostos(immutableStore[index], 'CEN_GUIA', immutableStore[index].CEN_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'vendetdvf') {
        if (immutableStore[index].CON_CENTRO === 'Si') {
          this.aggrid.busquedaCentroCostos(immutableStore[index], 'CEN_DEVF', immutableStore[index].CEN_CODIGO);
        }
      }
    }
    //GSRF  
    if (this.columidactual === 'DETFAC_CODIGOTIPO') {
      if (this.gridApi.aggFuncService === 'detfachomo') {
          console.log('------- ',immutableStore[index].DETFAC_TIPO )
          console.log('------- ',immutableStore[index].DETFAC_CODIGOTIPO)
        if (immutableStore[index].DETFAC_TIPO === 'A') {
          this.aggrid.busquedaarticulodetalle(immutableStore[index], 'busquedaarticulodetfachomo', immutableStore[index].DETFAC_CODIGOTIPO);
        }
        if (immutableStore[index].DETFAC_TIPO === 'S') {
          this.aggrid.busquedaserviciopro(immutableStore[index], 'busquedaserviciodetfachomo', immutableStore[index].DETFAC_CODIGOTIPO);
        }
        if (immutableStore[index].DETFAC_TIPO === 'C') {
          this.aggrid.busquedaCON(immutableStore[index], 'busquedacuentadetfachomo', immutableStore[index].DETFAC_CODIGOTIPO);
        }
      }
    }
    //GSRF

    if (this.columidactual === 'DETFAC_CODIGO') {
      if (this.gridApi.aggFuncService === 'detfac') {
        if (immutableStore[index].DETFAC_TIPODET === 'A') {
          this.aggrid.busquedaarticuloexistencia(immutableStore[index], 'busquedaarticulodetfac', immutableStore[index].DETFAC_CODIGO);
        }
        if (immutableStore[index].DETFAC_TIPODET === 'S') {
          this.aggrid.busquedaserviciodetalle(immutableStore[index], 'busquedaserviciodetfac', immutableStore[index].DETFAC_CODIGO);
        }
        if (immutableStore[index].DETFAC_TIPODET === 'C') {
          this.aggrid.busquedaCON(immutableStore[index], 'busquedacuentadetfac', immutableStore[index].DETFAC_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'detfacpv') {
        this.aggrid.busquedaarticuloexistencia(immutableStore[index], 'busquedaarticulodetfacpv', immutableStore[index].DETFAC_CODIGO);
      }
    }
    if (this.columidactual === 'DETPED_CODIGO') {
      if (this.gridApi.aggFuncService === 'vendetped') {
        if (immutableStore[index].DETPED_TIPODET === 'A') {
          this.aggrid.busquedaarticulopedido(immutableStore[index], 'busquedaPedido', immutableStore[index].DETPED_CODIGO);
        }
        if (immutableStore[index].DETPED_TIPODET === 'S') {
          this.aggrid.busquedaservicioPedido(immutableStore[index], 'busquedaPedidoServicio', immutableStore[index].DETPED_CODIGO);
        }
        if (immutableStore[index].DETPED_TIPODET === '*') {
        }
      }
    }
    if (this.columidactual === 'DETORDCOM_CODIGO') {
      if (this.gridApi.aggFuncService === 'comdetordcom') {
        if (immutableStore[index].DETORDCOM_TIPODET === 'A') {
          this.aggrid.busquedaarticuloorden(immutableStore[index], 'busquedaOrden', immutableStore[index].DETORDCOM_CODIGO);
        }
        if (immutableStore[index].DETORDCOM_TIPODET === 'S') {
          this.aggrid.busquedaservicioPedido(immutableStore[index], 'busquedaOrdenServicio', immutableStore[index].DETORDCOM_CODIGO);
        }
        if (immutableStore[index].DETORDCOM_TIPODET === '*') {
        }
      }
    }
    //CDPJ
    if(this.columidactual === 'SERCOM_PORCEIVA') {
      if (this.gridApi.aggFuncService === 'commaeservicios') {
          if (immutableStore[index].SERCOM_TRIBUTAIVA === 'S') {
            this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'tarivacodiocommaeserS', immutableStore[index].SERCOM_PORCEIVA);
          }
          if (immutableStore[index].SERCOM_TRIBUTAIVA === 'N') {
            this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'tarivacodiocommaeserN', immutableStore[index].SERCOM_PORCEIVA);
          }
      }
    }
    if(this.columidactual === 'SER_PORCEIVA') {
      if (this.gridApi.aggFuncService === 'maeservicios') {
          if (immutableStore[index].SER_TRIBUTAIVA === 'S') {
            this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'tarivacodiovenmaeserS', immutableStore[index].SER_PORCEIVA);
          }
          if (immutableStore[index].SER_TRIBUTAIVA === 'N') {
            this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'tarivacodiovenmaeserN', immutableStore[index].SER_PORCEIVA);
          }
      }
    }
    if (this.columidactual === 'DETORDCOM_PORIVA') {
      if (this.gridApi.aggFuncService === 'comdetordcom') {
        if (immutableStore[index].DETORDCOM_TIPODET !== '*') {
          if (immutableStore[index].DETORDCOM_TRIBIVA === 'S') {
            this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'detordcomtarivacodS', immutableStore[index].DETORDCOM_PORIVA);
          }
          if (immutableStore[index].DETORDCOM_TRIBIVA === 'N') {
            this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'detordcomtarivacodN', immutableStore[index].DETORDCOM_PORIVA);
          }
        }
      }
    }
    if (this.columidactual === 'DETNOTREC_PORIVA') {
      if (this.gridApi.aggFuncService === 'comnotrecRecepcion') {
        if (immutableStore[index].DETNOTREC_TIPODET !== '*') {
          if (immutableStore[index].DETNOTREC_TRIBIVA === 'S') {
            this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'detnotrectarivacodS', immutableStore[index].DETNOTREC_PORIVA);
          }
          if (immutableStore[index].DETNOTREC_TRIBIVA === 'N') {
            this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'detnotrectarivacodN', immutableStore[index].DETNOTREC_PORIVA);
          }
        }
      }
    }
    if (this.columidactual === 'DETFACPRO_PORIVA') {
      console.log('Number(immutableStore[index].DETFACPRO_PORIVA)',Number(immutableStore[index].DETFACPRO_PORIVA))
      if (this.gridApi.aggFuncService === 'detfacpro') {
        if (immutableStore[index].DETFACPRO_TIPODET !== '*') {
          // if (Number(immutableStore[index].DETFACPRO_PORIVA) !== 0) {
          //   this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'detfacprotarivacodS', immutableStore[index].DETFACPRO_PORIVA);
          // }
          // if (Number(immutableStore[index].DETFACPRO_PORIVA) === 0) {
          //   this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'detfacprotarivacodN', immutableStore[index].DETFACPRO_PORIVA);
          // }
          this.aggrid.busquedaTariivacodigo(immutableStore[index],'detfacprotarivacod', immutableStore[index].DETFACPRO_PORIVA);
        }
      }
    }
    if (this.columidactual === 'DETDEV_PORIVA') {
      console.log('Number(immutableStore[index].DETDEV_PORIVA)',Number(immutableStore[index].DETDEV_PORIVA))
      if (this.gridApi.aggFuncService === 'comdetdev') {
        if (immutableStore[index].DETDEV_TIPODET !== '*') {
          // if (Number(immutableStore[index].DETDEV_PORIVA) !== 0) {
          //   this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'detfacprotarivacodS', immutableStore[index].DETDEV_PORIVA);
          // }
          // if (Number(immutableStore[index].DETDEV_PORIVA) === 0) {
          //   this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'detfacprotarivacodN', immutableStore[index].DETDEV_PORIVA);
          // }
          this.aggrid.busquedaTariivacodigo(immutableStore[index],'detdevtarivacod', immutableStore[index].DETDEV_PORIVA);
        }
      }
    }
   //-- CDPJ busquedas devolcuiones de facturas de compras
   if (this.columidactual === 'DETDEV_CODIGO') {
    console.log('Number(immutableStore[index].DETDEV_PORIVA)',Number(immutableStore[index].DETDEV_PORIVA))
    if (this.gridApi.aggFuncService === 'comdetdev') {
      if (immutableStore[index].DETDEV_TIPODET === 'S') {
        this.aggrid.busquedaservicioPedido(immutableStore[index],'busquedaDevServicioCom', immutableStore[index].DETDEV_CODIGO);
      }else if (immutableStore[index].DETDEV_TIPODET === 'C') {
        this.aggrid.busquedaCON(immutableStore[index],'busquedacuentadevCOM', immutableStore[index].DETDEV_CODIGO);
      }
    }
  }
  //CDPJ
    /// VENTAS
    if (this.columidactual === 'DETPED_PORIVA') {
      if (this.gridApi.aggFuncService === 'vendetped') {
        if (immutableStore[index].DETPED_TIPODET !== '*') {
          if (immutableStore[index].DETPED_TRIBIVA === 'S') {
            this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'detpedtarivacodS', immutableStore[index].DETPED_PORIVA);
          }
          if (immutableStore[index].DETPED_TRIBIVA === 'N') {
            this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'detpedtarivacodN', immutableStore[index].DETPED_PORIVA);
          }
        }
      }
    }
    if (this.columidactual === 'DETGRE_PORIVA') {
      if (this.gridApi.aggFuncService === 'vendetguia') {
        if (immutableStore[index].DETGRE_TIPODET !== '*') {
          if (immutableStore[index].DETGRE_TRIBIVA === 'S') {
            this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'detgretarivacodS', immutableStore[index].DETGRE_PORIVA);
          }
          if (immutableStore[index].DETGRE_TRIBIVA === 'N') {
            this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'detgretarivacodN', immutableStore[index].DETGRE_PORIVA);
          }
        }
      }
    }
    if (this.columidactual === 'DETFAC_PORIVA') {
      if (this.gridApi.aggFuncService === 'detfac') {
        if (immutableStore[index].DETFAC_TIPODET !== '*') {
          if (immutableStore[index].DETFAC_TRIBIVA === 'S') {
            this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'detgretarivacodS', immutableStore[index].DETFAC_PORIVA);
          }
          if (immutableStore[index].DETFAC_TRIBIVA === 'N') {
            this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'detgretarivacodN', immutableStore[index].DETFAC_PORIVA);
          }
        }
      }
      if (this.gridApi.aggFuncService === 'detfacpv') {
        if (immutableStore[index].DETFAC_TIPODET !== '*') {
          if (immutableStore[index].DETFAC_TRIBIVA === 'S') {
            this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'detfacpvtarivacodS', immutableStore[index].DETFAC_PORIVA);
          }
          if (immutableStore[index].DETFAC_TRIBIVA === 'N') {
            this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'detfacpvtarivacodS', immutableStore[index].DETFAC_PORIVA);
          }
        }
      }
    }
    if (this.columidactual === 'DETDVF_PORIVA') {
      if (this.gridApi.aggFuncService === 'vendetdvf') {
        if (immutableStore[index].DETDVF_TIPODET !== '*') {
          if (immutableStore[index].DETDVF_TRIBIVA === 'S') {
            this.aggrid.busquedaTariivacodigoS(immutableStore[index], 'detdvffactarivacodS', immutableStore[index].DETDVF_PORIVA);
          }
          if (immutableStore[index].DETDVF_TRIBIVA === 'N') {
            this.aggrid.busquedaTariivacodigoN(immutableStore[index], 'detdvffactarivacodN', immutableStore[index].DETDVF_PORIVA);
          }
        }
      }
    }
    if (this.columidactual === 'ART_CODIGO_MP') {
      if (this.gridApi.aggFuncService === 'invmaeprofab') {
        this.aggrid.busquedaarticuloprofabri(immutableStore[index], 'ARTCODIGO_PROFABRI', immutableStore[index].ART_CODIGO_MP);
      }
      
    }
    //CDPJ
    if (this.columidactual === 'DETGRE_CODIGO') {
      
      if (this.gridApi.aggFuncService === 'vendetguia') {
        if (immutableStore[index].DETGRE_TIPODET === 'A') {
          this.aggrid.busquedaarticuloguia(immutableStore[index], 'busquedaGuia', immutableStore[index].DETGRE_CODIGO);
        }
        if (immutableStore[index].DETGRE_TIPODET === 'S') {
          this.aggrid.busquedaservicioPedido(immutableStore[index], 'busquedaGuiaServicio', immutableStore[index].DETGRE_CODIGO);
        }
        if (immutableStore[index].DETGRE_TIPODET === 'C') {
          this.aggrid.busquedaCON(immutableStore[index], 'busquedacuentaguia', immutableStore[index].DETGRE_CODIGO);
        }
        if (immutableStore[index].DETGRE_TIPODET === '*') {
        }
      }
    }
    if (this.columidactual === 'DETDVF_CODIGO') {
      if (this.gridApi.aggFuncService === 'vendetdvf') {
        if (immutableStore[index].DETDVF_TIPODET === 'A') {
          this.aggrid.busquedaarticulodev(immutableStore[index], 'busquedaDvf', immutableStore[index].DETDVF_CODIGO);
        }
        if (immutableStore[index].DETDVF_TIPODET === 'S') {
          this.aggrid.busquedaservicioPedido(immutableStore[index], 'busquedaDvfServicio', immutableStore[index].DETDVF_CODIGO);
        }
        if (immutableStore[index].DETDVF_TIPODET === 'C') {
          this.aggrid.busquedaCON(immutableStore[index], 'busquedacuentadev', immutableStore[index].DETDVF_CODIGO);
        }
        if (immutableStore[index].DETDVF_TIPODET === '*') {
        }
      }
    }

    if (this.columidactual === 'LOTE_NUMERO') {
      if (this.gridApi.aggFuncService === 'tablalote') {
        let origenLote=''
        const VALLOTART = this.trnloteservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VALLOTART').CFG_VALOR1;
        console.log(this.trnloteservice.strOrigen)
        console.log(this.trnloteservice.strOrigen[this.init.tabs[this.init.tabindex].indice])
        origenLote=this.trnloteservice.strOrigen[this.init.tabs[this.init.tabindex].indice]
        if (VALLOTART === 1 && (origenLote ==='FACVEN'|| origenLote ==='SA'|| origenLote === 'GUIREM' || origenLote === 'DEVCOM'|| origenLote === 'ORDPROD')) {
          this.aggrid.busquedalotescant(immutableStore[index], 'busquedalotes', immutableStore[index].LOTE_NUMERO);
        } else {
          this.aggrid.busquedalotes(immutableStore[index], 'busquedalotes', immutableStore[index].LOTE_NUMERO);
        }
      }
    }

    if (this.columidactual === 'DETFACPRO_CODIGO') {
      if (this.gridApi.aggFuncService === 'detfacpro') {
        if (immutableStore[index].DETFACPRO_TIPODET === 'A') {
          this.aggrid.busquedaarticulodetalle(immutableStore[index], 'busquedaarticulodetfacpro', immutableStore[index].DETFACPRO_CODIGO);
        }
        if (immutableStore[index].DETFACPRO_TIPODET === 'S') {
          this.aggrid.busquedaserviciopro(immutableStore[index], 'busquedaserviciodetfacpro', immutableStore[index].DETFACPRO_CODIGO);
        }
        if (immutableStore[index].DETFACPRO_TIPODET === 'C') {
          this.aggrid.busquedaCON(immutableStore[index], 'busquedacuentadetfacpro', immutableStore[index].DETFACPRO_CODIGO);
        }
      }
    }

    if (this.columidactual === 'TIPGAS_CODIGO') {
      if (this.gridApi.aggFuncService === 'detfacpro') {
        if (immutableStore[index].BOLTIPOGASTO === true) {
          this.aggrid.busquedatipogastos(immutableStore[index], 'busquedatipogastos', immutableStore[index].TIPGAS_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'districc') {
        if (immutableStore[index].BOLTIPOGASTO === true) {
          this.aggrid.busquedatipogastos(immutableStore[index], 'busquedatipogastos', immutableStore[index].TIPGAS_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'cxppagovarios') {
        if (immutableStore[index].BOLTIPOGASTO === true) {
          this.aggrid.busquedatipogastos(immutableStore[index], 'busquedatipogastoscxpvarios', immutableStore[index].TIPGAS_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'cxpvarios') {
        if (immutableStore[index].BOLTIPOGASTO === true) {
          this.aggrid.busquedatipogastos(immutableStore[index], 'tgcxpvarios', immutableStore[index].TIPGAS_CODIGO);
        }
      }
    }

    if (this.columidactual === 'BANCLI_CODIGO') {
      if (this.gridApi.aggFuncService === 'cxcpagoretformapago') {
        this.aggrid.busquedaBanco(immutableStore[index], 'busquedaBancoPagoRet', immutableStore[index].TRNPAGO_TIPO);
      }
    }
    if (this.columidactual === 'TRNCAJ_TIPO') {
      if (this.gridApi.aggFuncService === 'cxcformapagodev') {
        this.aggrid.busquedatipopago(immutableStore[index], 'busquedaTipoCaj', immutableStore[index].TRNCAJ_TIPO);
      }
    }
    if (this.columidactual === 'BAN_CODIGO') {
      if (this.gridApi.aggFuncService === 'cxcformapagodev') {
        this.aggrid.busquedaBanco(immutableStore[index], 'busquedaBancoFP', immutableStore[index].BAN_CODIGO);
      }
    }
    if (this.columidactual === 'BANCLI_CODIGO') {
      if (this.gridApi.aggFuncService === 'cxcformapago') {
        if (immutableStore[index].TRNPAGO_TIPO === 'CH') {
          this.aggrid.busquedaBancoCliente(immutableStore[index], 'busquedaBancoClienteCarteraCH', immutableStore[index].BANCLI_CODIGO);
        }
        if (immutableStore[index].TRNPAGO_TIPO === 'TA') {
          this.aggrid.busquedaTarjetaC(immutableStore[index], 'busquedaTarjetaCartera', immutableStore[index].BANCLI_CODIGO);
        }
        if (immutableStore[index].TRNPAGO_TIPO === 'DP') {
          this.aggrid.busquedaBanco(immutableStore[index], 'busquedaBancoClienteCartera', immutableStore[index].BANCLI_CODIGO);
        }
      }

      //CDPJ
      // if (this.gridApi.aggFuncService === 'cxctrnanticipoformapago') {
      //   if (immutableStore[index].TRNPAGO_TIPO === 'CH') {
      //     this.aggrid.busquedaBancoCliente(immutableStore[index], 'busquedaBancoClienteAnticipoCH', immutableStore[index].BANCLI_CODIGO);
      //   }
      //   if (immutableStore[index].TRNPAGO_TIPO === 'TA') {
      //     this.aggrid.busquedaTarjetaC(immutableStore[index], 'busquedaTarjetaAnticipo', immutableStore[index].BANCLI_CODIGO);
      //   }
      //   if (immutableStore[index].TRNPAGO_TIPO === 'DP') {
      //     this.aggrid.busquedaBanco(immutableStore[index], 'busquedaBancoClienteAnticipo', immutableStore[index].BANCLI_CODIGO);
      //   }
      // }
      if (this.gridApi.aggFuncService === 'cxctrnabonoformapago') {
        if (immutableStore[index].TRNPAGO_TIPO === 'CH') {
          this.aggrid.busquedaBancoCliente(immutableStore[index], 'busquedaBancoClienteAbonoCH', immutableStore[index].BANCLI_CODIGO);
        }
        if (immutableStore[index].TRNPAGO_TIPO === 'TA') {
          this.aggrid.busquedaTarjetaC(immutableStore[index], 'busquedaTarjetaAbono', immutableStore[index].BANCLI_CODIGO);
        }
        if (immutableStore[index].TRNPAGO_TIPO === 'DP') {
          this.aggrid.busquedaBanco(immutableStore[index], 'busquedaBancoClienteAbono', immutableStore[index].BANCLI_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'cxctrncancelacionformapago') {
        if (immutableStore[index].TRNPAGO_TIPO === 'CH') {
          this.aggrid.busquedaBancoCliente(immutableStore[index], 'busquedaBancoClienteCancelacionCH', immutableStore[index].BANCLI_CODIGO);
        }
        if (immutableStore[index].TRNPAGO_TIPO === 'TA') {
          this.aggrid.busquedaTarjetaC(immutableStore[index], 'busquedaTarjetaCancelacion', immutableStore[index].BANCLI_CODIGO);
        }
        if (immutableStore[index].TRNPAGO_TIPO === 'DP') {
          this.aggrid.busquedaBanco(immutableStore[index], 'busquedaBancoClienteCancelacion', immutableStore[index].BANCLI_CODIGO);
        }
      }
      if (this.gridApi.aggFuncService === 'pagcon') {
        if (immutableStore[index].TRNPAGO_TIPO === 'CH') {
          this.aggrid.busquedaBancoCliente(immutableStore[index], 'busquedaBancoCliente', immutableStore[index].BANCLI_CODIGO);
        }
        if (immutableStore[index].TRNPAGO_TIPO === 'TA') {
          this.aggrid.busquedaTarjetaC(immutableStore[index], 'busquedaTarjetaC', immutableStore[index].BANCLI_CODIGO);
        }
        if (immutableStore[index].TRNPAGO_TIPO === 'DP') {
          this.aggrid.busquedaBanco(immutableStore[index], 'busquedaBanco', immutableStore[index].BANCLI_CODIGO);
        }
      }
      //CDPJ


    }
    if (this.columidactual === 'TRNPAGO_TIPO') {
      if (this.gridApi.aggFuncService === 'pagcon') {
        this.aggrid.busquedatipopago(immutableStore[index], 'busquedatipopago', immutableStore[index].TRNPAGO_TIPO);
      }
      if (this.gridApi.aggFuncService === 'pagcondvf') {
        this.aggrid.busquedatipopago(immutableStore[index], 'busquedatipopagodvf', immutableStore[index].TRNPAGO_TIPO);
      }
      if (this.gridApi.aggFuncService === 'cxcformapago') {
        this.aggrid.busquedatipopago(immutableStore[index], 'busquedaFormapagoCXC', immutableStore[index].TRNPAGO_TIPO);
      }
      //CDPJ
      if (this.gridApi.aggFuncService === 'cxctrnabonoformapago') {
        this.aggrid.busquedatipopago(immutableStore[index], 'busquedaFormapagoCXCTrnabono', immutableStore[index].TRNPAGO_TIPO);
      }
      if (this.gridApi.aggFuncService === 'cxctrncancelacionformapago') {
        this.aggrid.busquedatipopago(immutableStore[index], 'busquedaFormapagoCXCTrncancelacion', immutableStore[index].TRNPAGO_TIPO);
      }
      // if (this.gridApi.aggFuncService === 'cxctrnanticipoformapago') {
      //   this.aggrid.busquedatipopago(immutableStore[index], 'busquedaFormapagoCXCTrnanticipo', immutableStore[index].TRNPAGO_TIPO);
      // }
      //CDPJ

    }

    if (this.columidactual === 'PROG_CODIGO') {
      if (this.gridApi.aggFuncService === 'conencasil') {
        this.aggrid.busquedaProgramas(immutableStore[index], 'PROG_CONENCASIL', immutableStore[index].PROG_CODIGO);
      }
    }

    if (this.columidactual === 'TIPODOC') {
      if (this.gridApi.aggFuncService === 'cxpfpant') {
        this.aggrid.busquedatipopago(immutableStore[index], 'TIPODOCcxpfpant', immutableStore[index].TIPODOC);
      }
    }

    if (this.columidactual === 'BANCODIGO') {
      if (this.gridApi.aggFuncService === 'cxpfpant') {
        if (immutableStore[index].TIPODOC === 'CH') {
          this.aggrid.busquedaBanco(immutableStore[index], 'BANCODIGOcxpfpant', immutableStore[index].BANCODIGO);
        }
      }
    }

    if (this.columidactual === 'CAJADOC') {
      if (this.gridApi.aggFuncService === 'cxpfpant') {
        if (immutableStore[index].TIPODOC === 'EF') {
          this.aggrid.busquedaCaja(immutableStore[index], 'CAJADOCcxpfpant', immutableStore[index].CAJADOC);//CDPJ
        }
      }
    }

    if (this.columidactual === 'TRNPAGOTIPO') {
      if (this.gridApi.aggFuncService === 'cxpfpdev') {
        this.aggrid.busquedatipopago(immutableStore[index], 'TRNPAGOTIPOcxpfpdev', immutableStore[index].TRNPAGOTIPO);
      }
    }

    if (this.columidactual === 'BANCLICODIGO') {
      if (this.gridApi.aggFuncService === 'cxpfpdev') {
        if (immutableStore[index].TRNPAGOTIPO === 'CH') {
          this.aggrid.busquedaBancoCliente(immutableStore[index], 'BANCLICODIGOcxpfpdev', immutableStore[index].BANCLICODIGO);
        }
        if (immutableStore[index].TRNPAGOTIPO === 'DP') {
          this.aggrid.busquedaBanco(immutableStore[index], 'BANCLICODIGOcxpfpdev', immutableStore[index].BANCLICODIGO);
        }
      }
    }

    if (this.columidactual === 'CAJCODIGO') {
      if (this.gridApi.aggFuncService === 'cxpfpdev') {
        if (immutableStore[index].TRNPAGOTIPO === 'EF') {
          this.aggrid.busquedaCaja(immutableStore[index], 'CAJCODIGOcxpfpdev', immutableStore[index].CAJCODIGO);//CDPJ
        }
      }
    }

    if (this.columidactual === 'CARGO_CODIGO') {
      if (this.gridApi.aggFuncService === 'trncargos') {
        this.aggrid.busquedacargos(immutableStore[index], 'busquedacargos', immutableStore[index].CARGO_CODIGO);
      }
    }

    if (this.columidactual === 'DETFACPRO_TIPOSRI') {
      if (this.gridApi.aggFuncService === 'detfacpro') {
        this.aggrid.busquedaSRI(immutableStore[index], 'DETFACPRO_TIPOSRI');
      }
    }

    if (this.columidactual === 'DETFAC_TIPO') {
      if (this.gridApi.aggFuncService === 'detfac') {
        this.aggrid.busquedaSRI(immutableStore[index], 'DETFAC_TIPO');
      }
    }
    if (this.columidactual === 'MAERUBRO_TIPO') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {

        this.aggrid.busquedaRubros(immutableStore[index], 'tiporubro', immutableStore[index].MAERUBRO_TIPO);
      }
    }
    if (this.columidactual === 'MAERUBRO_UNIDAD') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {

        this.aggrid.busquedaRubrosUnida(immutableStore[index], 'NomRubUnidad', immutableStore[index].MAERUBRO_UNIDAD);
      }
    }
    if (this.columidactual === 'CON_CODIGO') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'CON_CODIGO_NOM_RUB', immutableStore[index].CON_CODIGO);
      }
    }
    if (this.columidactual === 'CON_CODIGO_2') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodi2', immutableStore[index].CON_CODIGO_2);
      }
    }
    if (this.columidactual === 'CON_CODIGO_3') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodi3', immutableStore[index].CON_CODIGO_3);
      }
    }
    if (this.columidactual === 'CON_CODIGO_4') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodi4', immutableStore[index].CON_CODIGO_4);
      }
    }
    if (this.columidactual === 'CON_CODIGO_5') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodi5', immutableStore[index].CON_CODIGO_5);
      }
    }
    if (this.columidactual === 'CON_CODIGO_6') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodi6', immutableStore[index].CON_CODIGO_6);
      }
    }
    if (this.columidactual === 'CON_CODIGO_7') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodi7', immutableStore[index].CON_CODIGO_7);
      }
    }
    if (this.columidactual === 'CON_CODIGOHABE') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodHab', immutableStore[index].CON_CODIGOHABE);
      }
    }
    if (this.columidactual === 'CON_CODIGOHABE_2') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodHab2', immutableStore[index].CON_CODIGOHABE_2);
      }
    }
    if (this.columidactual === 'CON_CODIGOHABE_3') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodHab3', immutableStore[index].CON_CODIGOHABE_3);
      }
    }
    if (this.columidactual === 'CON_CODIGOHABE_4') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodHab4', immutableStore[index].CON_CODIGOHABE_4);
      }
    }
    if (this.columidactual === 'CON_CODIGOHABE_5') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodHab5', immutableStore[index].CON_CODIGOHABE_5);
      }
    }
    if (this.columidactual === 'CON_CODIGOHABE_6') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodHab6', immutableStore[index].CON_CODIGOHABE_6);
      }
    }
    if (this.columidactual === 'CON_CODIGOHABE_7') {
      if (this.gridApi.aggFuncService === 'nomMaeRubros') {
        this.aggrid.busquedaCON(immutableStore[index], 'NomRubConCodHab7', immutableStore[index].CON_CODIGOHABE_7);
      }
    }
    if (this.columidactual === 'MAERUBRO_CODIGO2') {
      if (this.gridApi.aggFuncService === 'nomMaeIngrFij') {
        this.aggrid.busquedaRubroCod(immutableStore[index], 'ingrRUBRO_CODIGO', immutableStore[index].MAERUBRO_CODIGO2);
      }
      if (this.gridApi.aggFuncService === 'nomMaeEgrFij') {
        this.aggrid.busquedaEgreRubroCod(immutableStore[index], 'egreRUBRO_CODIGO', immutableStore[index].MAERUBRO_CODIGO2);
      }
    }
    if (this.columidactual === 'EMPCARGFAMI_TIPOIDE') {
      if (this.gridApi.aggFuncService === 'nomMaeCargaFami') {
        this.aggrid.busquedaTipoID(immutableStore[index], 'cargaFAMI_ID', immutableStore[index].EMPCARGFAMI_TIPOIDE);
      }

    }
    if (this.columidactual === 'EMPCARGFAMI_SEXO') {
      if (this.gridApi.aggFuncService === 'nomMaeCargaFami') {
        this.aggrid.busquedaAGSexo(immutableStore[index], 'cargafaSEXO', immutableStore[index].EMPCARGFAMI_SEXO);
      }

    }
    if (this.columidactual === 'EMPCARGFAMI_TIPORELA2') {
      if (this.gridApi.aggFuncService === 'nomMaeCargaFami') {
        this.aggrid.busquedaAGTipoRela(immutableStore[index], 'cargafaTIPORELA', immutableStore[index].EMPCARGFAMI_TIPORELA2);
      }

    }
    if (this.columidactual === 'EMPCARGFAMI_ESTATUS') {
      if (this.gridApi.aggFuncService === 'nomMaeCargaFami') {
        this.aggrid.busquedaAGESTATUS(immutableStore[index], 'cargafaESTATUS', immutableStore[index].EMPCARGFAMI_TIPORELA2);
      }

    }
    if (this.columidactual === 'TRANVACAEMPL_TIPO') {
      if (this.gridApi.aggFuncService === 'nomMaeCrudVaca') {
        this.aggrid.busquedaTipoVac(immutableStore[index], 'valorcrudVaca', immutableStore[index].TRANVACAEMPL_TIPO);
      }

    }
    if (this.columidactual === 'TRANVACAEMPL_ESTADO') {
      if (this.gridApi.aggFuncService === 'nomMaeCrudVaca') {
        this.aggrid.busquedaVacSta(immutableStore[index], 'valorcrudVacEst', immutableStore[index].TRANVACAEMPL_ESTADO);
      }
    }
    if (this.columidactual === 'SER_CODIGO') {
      if (this.gridApi.aggFuncService === 'servicioped') {
        this.aggrid.busquedaservicioPedido(immutableStore[index], 'busquedavenserped', immutableStore[index].SER_CODIGO);
      }
    }
    if (this.columidactual === 'EMP_CODIGO') {
      if (this.gridApi.aggFuncService === 'nomCabimpurent') {

        this.aggrid.busquedaEmpleado(immutableStore[index], 'empcodigocabi', immutableStore[index].EMP_CODIGO);
      }
      if (this.gridApi.aggFuncService === 'nomTrannovedad') {

        this.aggrid.busquedaEmpleado(immutableStore[index], 'emptrannoved', immutableStore[index].EMP_CODIGO);
      }
    }
    if (this.columidactual === 'MAERUBRO_CODRUBR') {
      if (this.gridApi.aggFuncService === 'nomTrannovedad') {
        this.aggrid.busquedaRuboNovedad(immutableStore[index], 'rubcodnoved', immutableStore[index].MAERUBRO_CODRUBR);
      }
    }
    if (this.columidactual === 'DETFAC_TIPOSRI') {
      if (this.gridApi.aggFuncService === 'vendetdvf') {
        this.aggrid.busquedaSRI(immutableStore[index], 'DETDVF_TIPOSRI');
      }else{
        this.aggrid.busquedaSRI(immutableStore[index], 'DETFAC_TIPOSRI');
      }
      
    }

    if (this.columidactual === 'TRNCOMQUI_CODIGO') {
      if (this.gridApi.aggFuncService === 'invprodtrncomqui') {
        this.aggrid.busquedacomposicionquimica(immutableStore[index], 'TRNCOMQUI', immutableStore[index].TRNCOMQUI_CODIGO);
      }
    }

    if (this.columidactual === 'TRNAPLI_CODIGO') {
      if (this.gridApi.aggFuncService === 'invprodtrnaplica') {
        this.aggrid.busquedaprodaplicacion(immutableStore[index], 'TRNAPLI', immutableStore[index].TRNCOMQUI_CODIGO);
      }
    }
    if (this.columidactual === 'CIN_CODIGO_1') {

      if (this.gridApi.aggFuncService === 'prodcostfech') {
        console.log('entra');
        this.aggrid.busquedaCostFecha(immutableStore[index], 'costfecha', immutableStore[index].CIN_CODIGO_1);
      }
    }
    if (this.columidactual === 'NOM_TIPOPAGOUTIL') {
      if (this.gridApi.aggFuncService === 'nomutilidades') {
        this.aggrid.busquedaTiposUtilidades(immutableStore[index], 'NOM_TIPOPAGOUTIL', immutableStore[index].NOM_TIPOPAGOUTIL);
      }
    }
    if (this.columidactual === 'NOM_TIPOPAGO_SALDIG') {
      if (this.gridApi.aggFuncService === 'nomutilidades') {
        this.aggrid.busquedaTiposUtilidades(immutableStore[index], 'NOM_TIPOPAGO_SALDIG', immutableStore[index].NOM_TIPOPAGO_SALDIG);
      }
    }
    if (this.columidactual === 'NOM_FORMPAGOCOD') {
      if (this.gridApi.aggFuncService === 'nomutilidades') {
        this.aggrid.busquedaTiposUtilidades(immutableStore[index], 'NOM_FORMPAGOCOD', immutableStore[index].NOM_FORMPAGOCOD);
      }
    }

    if (this.columidactual === 'NOM_TIPOCUENCOD') {
      if (this.gridApi.aggFuncService === 'nomutilidades') {
        this.aggrid.busquedaTiposUtilidades(immutableStore[index], 'NOM_TIPOCUENCOD', immutableStore[index].NOM_TIPOCUENCOD);
      }
    }

    if (this.columidactual === 'NOM_BANCPAGOCOD') {
      if (this.gridApi.aggFuncService === 'nomutilidades') {
        this.aggrid.busquedaUtilidadesBancos(immutableStore[index], 'NOM_BANCPAGOCOD', immutableStore[index].NOM_BANCPAGOCOD);
      }
    }

    if (this.columidactual === 'ENCPED_NUMERO') {
      if (this.gridApi.aggFuncService === 'trnpedidos') {
        this.aggrid.busquedaPedidos(immutableStore[index], 'busquedapedidosprod', immutableStore[index].ENCPED_NUMERO);
      }
    }

    if (this.columidactual === 'TRNTEC_CODIGO') {
      if (this.gridApi.aggFuncService === 'trntecnico') {
        this.aggrid.busquedaTecnico(immutableStore[index], 'busquedatecnicosprod', immutableStore[index].TRNTEC_CODIGO);
      }
    }

    if (this.columidactual === 'TRNMAQ_CODIGO') {
      if (this.gridApi.aggFuncService === 'trnmaquinaria') {
        this.aggrid.busquedaMaquinaria(immutableStore[index], 'busquedamaquinariaprod', immutableStore[index].TRNMAQ_CODIGO);
      }
    }

    if (this.columidactual === 'DETLIQUBENESOCI_FORMPAGODESC') {
      if (this.gridApi.aggFuncService === 'nomliqdeceterc') {
        this.aggrid.busquedaFormaPagoLiq(immutableStore[index], 'busquedaFormaPagoLiq', immutableStore[index].DETLIQUBENESOCI_FORMPAGODESC);
      }
    }
    if (this.columidactual === 'DETLIQUBENESOCI_BANCPAGODESC') {
      if (this.gridApi.aggFuncService === 'nomliqdeceterc') {
        this.aggrid.busquedaBancoLiq(immutableStore[index], 'busquedaBancoLiq', immutableStore[index].DETLIQUBENESOCI_BANCPAGODESC);
      }
    }
    if (this.columidactual === 'DETLIQUBENESOCI_TIPOCUENDESC') {
      if (this.gridApi.aggFuncService === 'nomliqdeceterc') {
        this.aggrid.busquedaNumCuenLiq(immutableStore[index], 'busquedaNumeCueLiq', immutableStore[index].DETLIQUBENESOCI_TIPOCUENDESC);
      }
    }
    if (this.columidactual === 'TRNLOTE_LOTE') {
      if (this.gridApi.aggFuncService === 'trnloteprod') {
        this.aggrid.busquedalotesMP(immutableStore[index], 'busquedalotestrnlote',
          immutableStore[index].TRNLOTE_LOTE);
      }
    }
    if (this.columidactual === 'ETI_CODIGO') {
      if (this.gridApi.aggFuncService === 'maeequipodet') {
        this.aggrid.busquedaEtiqueta(immutableStore[index], 'busquedaetiqueta',
          immutableStore[index].ETI_CODIGO);
      }
    }
    if (this.columidactual === 'ACT_CODIGO') {
      if (this.gridApi.aggFuncService === 'actdetsalida') {
        this.aggrid.busquedaactivosalida(immutableStore[index], 'ACTCODIGO',
          immutableStore[index].ACT_CODIGO);
      }
    }
    if (this.columidactual === 'TRNPAGO_NUMERO') {
      if (this.gridApi.aggFuncService === 'cxcformapago') {
        if (immutableStore[index].TRNPAGO_TIPO === 'ANCL') {
          this.busqService.encontrarRegistro1(immutableStore[index].CLI_CODIGO).subscribe(eR => {
            let intAnticipos = 0;
            if (eR !== undefined) {
              if (eR[0] !== null) {
                intAnticipos = Number(eR[0].COUN);
              }
            }
            if (intAnticipos !== 0) {
              this.aggrid.busquedaAnticiposCli(immutableStore[index], 'cxcformapagoANC', immutableStore[index].CLI_CODIGO);
            } else {
              this.messageService.add({
                key: 'cartera',
                severity: 'warn',
                summary: 'Información',
                detail: 'No existen anticipos pendientes.'
              });
            }
          });
        }
      }
      //CDPJ
      if (this.gridApi.aggFuncService === 'cxctrnabonoformapago') {
        if (immutableStore[index].TRNPAGO_TIPO === 'ANCL') {
          this.busqService.encontrarRegistro1(immutableStore[index].CLI_CODIGO).subscribe(eR => {
            let intAnticipos = 0;
            if (eR !== undefined) {
              if (eR[0] !== null) {
                intAnticipos = Number(eR[0].COUN);
              }
            }
            if (intAnticipos !== 0) {
              this.aggrid.busquedaAnticiposCli(immutableStore[index], 'cxctrnabonoformapagoANC', immutableStore[index].CLI_CODIGO);
            } else {
              this.messageService.add({
                key: 'cxctrnabono',
                severity: 'warn',
                summary: 'Información',
                detail: 'No existen anticipos pendientes.'
              });
            }
          });
        }
      }
      if (this.gridApi.aggFuncService === 'cxctrncancelacionformapago') {
        if (immutableStore[index].TRNPAGO_TIPO === 'ANCL') {
          this.busqService.encontrarRegistro1(immutableStore[index].CLI_CODIGO).subscribe(eR => {
            let intAnticipos = 0;
            if (eR !== undefined) {
              if (eR[0] !== null) {
                intAnticipos = Number(eR[0].COUN);
              }
            }
            if (intAnticipos !== 0) {
              this.aggrid.busquedaAnticiposCli(immutableStore[index], 'cxctrncancelacionformapagoANC', immutableStore[index].CLI_CODIGO);
            } else {
              this.messageService.add({
                key: 'cxctrncancelacion',
                severity: 'warn',
                summary: 'Información',
                detail: 'No existen anticipos pendientes.'
              });
            }
          });
        }
      }
      // if (this.gridApi.aggFuncService === 'cxctrnanticipoformapago') {
      //   if (immutableStore[index].TRNPAGO_TIPO === 'ANCL') {
      //     this.busqService.encontrarRegistro1(immutableStore[index].CLI_CODIGO).subscribe(eR => {
      //       let intAnticipos = 0;
      //       if (eR !== undefined) {
      //         if (eR[0] !== null) {
      //           intAnticipos = Number(eR[0].COUN);
      //         }
      //       }
      //       if (intAnticipos !== 0) {
      //         this.aggrid.busquedaAnticiposCli(immutableStore[index], 'cxctrnanticipoformapagoANC', immutableStore[index].CLI_CODIGO);
      //       } else {
      //         this.messageService.add({
      //           key: 'cxctrncancelacion',
      //           severity: 'warn',
      //           summary: 'Información',
      //           detail: 'No existen anticipos pendientes.'
      //         });
      //       }
      //     });
      //   }
      // }
      //CDPJ
      if (this.gridApi.aggFuncService === 'pagcon') {
        if (immutableStore[index].TRNPAGO_TIPO === 'ANCL') {
          this.busqService.encontrarRegistro1(this.encfacservice.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO).subscribe(eR => {
            let intAnticipos = 0;
            if (eR !== undefined) {
              if (eR[0] !== null) {
                intAnticipos = Number(eR[0].COUN);
              }
            }
            if (intAnticipos !== 0) {
              this.aggrid.busquedaAnticiposCli(immutableStore[index], 'pagconFormaPagoAnc', this.encfacservice.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
            } else {
              this.messageService.add({
                key: 'pagcon',
                severity: 'warn',
                summary: 'Información',
                detail: 'No existen anticipos pendientes.'
              });
            }
          });
        }
      }
    }

  }

  async calculos(event) {
    const index = this.gridApi.getFocusedCell().rowIndex;
    const immutableStore = [];
    this.gridApi.stopEditing();
    this.gridApi.forEachNode((rowNode, rowindex) => {
      immutableStore.push(rowNode.data);
    });
    if (this.gridApi.aggFuncService === 'cxpvarios') {
      immutableStore[index].TRNBANTIPO = '';
      if (immutableStore[index].BANCODIGO !== '' && immutableStore[index].TRNBANTIPO !== 'CH') {
        if (immutableStore[index].DEBE != 0) {
          immutableStore[index].TRNBANTIPO = 'DP';
        }
        if (immutableStore[index].HABER != 0) {
          immutableStore[index].TRNBANTIPO = 'RE';
        }
      }

      this.cxptrncobroService.calcularImporteTotal();
    }

    if (this.gridApi.aggFuncService === 'pagcon') {
      if (this.columidactual === 'TRNPAGO_FECHA') {
        if (immutableStore[index].TRNPAGO_TIPO === 'EF') {
          this.gridApi.setFocusedCell(index, 'TRNPAGO_IMPORTE');
          this.gridApi.ensureColumnVisible('TRNPAGO_IMPORTE');
          this.nosaltar = true;
        }
      }
    }

    if (this.gridApi.aggFuncService === 'cxppagodoc') {
      this.aggrid.refreshaggridindexSNMVSCROLL(this.cxptrnpagodocService.documentos, 'cxppagodoc', index - 1);
      this.cxptrnpagodocService.calcularImporteTotal();
      
      //this.aggrid.refreshaggrid(this.cxptrnpagodocService.documentos, this.gridApi.aggFuncService);
    }

    if (this.gridApi.aggFuncService === 'retpagcon') {
      if (this.columidactual === 'TRNRETENCION_BASE') {
        const totalretenido = immutableStore[index].TRNRETENCION_BASE * (immutableStore[index].TRNRETENCION_PORCENTAJE / 100);//CDPJ antes sin los parentesis
        immutableStore[index].TRNRETENCION_TOTALRETENIDO = totalretenido.toFixed(this.confIniciales.getNumDecSist());
        this.pagconservice.reten[index].TRNRETENCION_TOTALRETENIDO=immutableStore[index].TRNRETENCION_TOTALRETENIDO;//CDPJ
      }
      if (this.columidactual === 'TRNRETENCION_NRO') {
        const intNumCar = 7;
        const intTamanioCad = immutableStore[index].TRNRETENCION_NRO.length;
        let strCadCeros = '';

        for (let i = 1; i <= intNumCar - intTamanioCad; i++) {
          strCadCeros = strCadCeros + '0';
        }

        immutableStore[index].TRNRETENCION_NRO = strCadCeros + immutableStore[index].TRNRETENCION_NRO;
      }
      //this.aggrid.refreshaggrid(immutableStore, 'retpagcon');
      this.aggrid.refreshaggrid(this.pagconservice.reten, 'retpagcon');//CDPJ
    }

    if (this.gridApi.aggFuncService === 'commaeproveedorADM') {
      if (this.columidactual === 'AUTSRI_SECUENCIALINI') {
        const intNumCar = 9;
        const intTamanioCad = immutableStore[index].AUTSRI_SECUENCIALINI.length;
        let strCadCeros = '';

        for (let i = 1; i <= intNumCar - intTamanioCad; i++) {
          strCadCeros = strCadCeros + '0';
        }

        immutableStore[index].AUTSRI_SECUENCIALINI = strCadCeros + immutableStore[index].AUTSRI_SECUENCIALINI;
      }
      this.aggrid.refreshaggrid(immutableStore, 'commaeproveedorADM');
      if (this.columidactual === 'AUTSRI_SECUENCIALINI') {
        const intNumCar = 9;
        const intTamanioCad = immutableStore[index].AUTSRI_SECUENCIALINI.length;
        let strCadCeros = '';

        for (let i = 1; i <= intNumCar - intTamanioCad; i++) {
          strCadCeros = strCadCeros + '0';
        }

        immutableStore[index].AUTSRI_SECUENCIALINI = strCadCeros + immutableStore[index].AUTSRI_SECUENCIALINI;
      }
      if (this.columidactual === 'AUTSRI_SECUENCIALFIN') {
        const intNumCar = 9;
        const intTamanioCad = immutableStore[index].AUTSRI_SECUENCIALFIN.length;
        let strCadCeros = '';

        for (let i = 1; i <= intNumCar - intTamanioCad; i++) {
          strCadCeros = strCadCeros + '0';
        }

        immutableStore[index].AUTSRI_SECUENCIALFIN = strCadCeros + immutableStore[index].AUTSRI_SECUENCIALFIN;
      }
      if (this.columidactual === 'AUTSRI_SERIEPTOEMI') {
        const intNumCar = 3;
        const intTamanioCad = immutableStore[index].AUTSRI_SERIEPTOEMI.length;
        let strCadCeros = '';

        for (let i = 1; i <= intNumCar - intTamanioCad; i++) {
          strCadCeros = strCadCeros + '0';
        }

        immutableStore[index].AUTSRI_SERIEPTOEMI = strCadCeros + immutableStore[index].AUTSRI_SERIEPTOEMI;
      }
      this.aggrid.refreshaggrid(immutableStore, 'commaeproveedorADM');
    }

    if (this.gridApi.aggFuncService === 'cxcmaerentencion') {
      if (this.columidactual === 'RETENCION_CODIGO') {
        this.cxcmaeretencionService.verificarCod(index);
        this.aggrid.refreshaggrid(this.cxcmaeretencionService.cxcmaeretencion, 'cxcmaerentencion');
        this.gridApi.setFocusedCell(index, this.columidactual);
        this.aggrid.onCellKeyDown(event);
        //sss

      } else if (this.columidactual === 'RETENCION_PORCENTAJE') {
        this.cxcmaeretencionService.calculoPor(index);
        this.aggrid.refreshaggridindex(this.cxcmaeretencionService.cxcmaeretencion, 'cxcmaerentencion', index - 1);
        /* this.aggrid.refreshaggrid(this.cxcmaeretencionService.cxcmaeretencion, 'cxcmaerentencion'); */
      }
    }

    if (this.gridApi.aggFuncService === 'formaPago') {
      if (this.columidactual === 'FORMAPAGO_NOMBRE') {
        this.aggrid.guardarEnter.emit(true);

      }/*
      if (this.columidactual === 'CAJ_CODIGO') {
        this.aggrid.guardarEnter.emit(true);
      }*/
    }
    if (this.gridApi.aggFuncService === 'cxcmaebancli') {
      if (this.columidactual === 'BANCLI_NOMBRE') {
        this.aggrid.guardarEnter.emit(true);
      }
    }
    if (this.gridApi.aggFuncService === 'cxcmaetarjetacredito') {
      if (this.columidactual === 'TAR_PORCENTAJE') {
        this.aggrid.guardarEnter.emit(true);
      }
    }
    if (this.gridApi.aggFuncService === 'comision') {
      if (this.columidactual === 'CON_CODIGO') {
        this.aggrid.guardarEnter.emit(true);
      }
    }

    if (this.gridApi.aggFuncService === 'presuCCGrid') {
      this.presucentrocostosService.calcularacm(index);
      this.aggrid.refreshaggridindexSNMVSCROLL(this.presucentrocostosService.presuCXC, 'presuCCGrid', index)
    }

    if (this.gridApi.aggFuncService === 'detfac') {
      //CDPJ
      if(this.columidactual === 'DETFAC_TRIBIVA'){
        if(immutableStore[index].DETFAC_TRIBIVA === 'N' || immutableStore[index].DETFAC_TRIBIVA === 'S'){
          immutableStore[index].DETFAC_PORIVA='';
          immutableStore[index].TARIIVA_CODIGO='';
        }
      }
      //CDPJ
      if (this.columidactual === 'DETFAC_CANTIDAD') {
        const intFCEXSNEG = this.encfacservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCEXSNEG').CFG_VALOR1;


        if (intFCEXSNEG === 0) { // no permitir facturar con existencias negativas

          await this.encfacservice.verificarExistenciaArticulo(immutableStore[index]);

          // await this.encfacservice.comprobarExistencia(immutableStore[index]);

          if (immutableStore[index].DETFAC_TIPODET !== '*' &&
            immutableStore[index].DETPED_CANTIDAD !== undefined &&
            immutableStore[index].ENCPED_NUMERO !== undefined &&
            immutableStore[index].ENCPED_NUMERO !== null &&
            immutableStore[index].ENCPED_NUMERO !== '') {
            if (Number(immutableStore[index].DETFAC_CANTIDAD) > Number(immutableStore[index].DETPED_CANTIDAD)) {
              this.encfacservice.verificarCantidadPedido(immutableStore[index]);
            }
          }

          /*  this.encfacservice.calcularTotales();
           this.aggrid.refreshaggrid(this.encfacservice.detfacarray[this.init.tabs[this.init.tabindex].indice], 'detfac');
           this.gridApi.setFocusedCell(index, 'DETFAC_PRECIO'); */

        } //else {
        // await this.encfacservice.comprobarExistencia(immutableStore[index]);

        /* this.encfacservice.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacservice.detfacarray[this.init.tabs[this.init.tabindex].indice], 'detfac');
        this.gridApi.setFocusedCell(index, 'DETFAC_PRECIO'); */
        //}
        await this.encfacservice.comprobarExistencia(immutableStore[index]);

        this.encfacservice.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacservice.detfacarray[this.init.tabs[this.init.tabindex].indice], 'detfac');
        const FOCCANLIN =this.encfacservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FOCCANLIN').CFG_VALOR1;
        if(FOCCANLIN===0){
          this.gridApi.setFocusedCell(index, 'DETFAC_PRECIO');
        }



      } else if (this.columidactual === 'DETFAC_DESCUENTO') {
        if (Number(immutableStore[index].DETFAC_DESCUENTO) > 100) {
          immutableStore[index].DETFAC_DESCUENTO = 100;
        }
        this.encfacservice.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacservice.detfacarray[this.init.tabs[this.init.tabindex].indice], 'detfac');
        this.gridApi.setFocusedCell(index, 'DETFAC_DESCUENTO');
      } else if (this.columidactual === 'DETFAC_PRECIO') {

        const intACTPEDVAL = this.encfacservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ACTPEDVAL').CFG_VALOR1;
         //CDPJ
         const intFCEXSNEG = this.encfacservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCEXSNEG').CFG_VALOR1;


         if (intFCEXSNEG === 0) { // no permitir facturar con existencias negativas
 
           await this.encfacservice.verificarExistenciaArticulo(immutableStore[index]);
 
           // await this.encfacservice.comprobarExistencia(immutableStore[index]);
 
           if (immutableStore[index].DETFAC_TIPODET !== '*' &&
             immutableStore[index].DETPED_CANTIDAD !== undefined &&
             immutableStore[index].ENCPED_NUMERO !== undefined &&
             immutableStore[index].ENCPED_NUMERO !== null &&
             immutableStore[index].ENCPED_NUMERO !== '') {
             if (Number(immutableStore[index].DETFAC_CANTIDAD) > Number(immutableStore[index].DETPED_CANTIDAD)) {
               this.encfacservice.verificarCantidadPedido(immutableStore[index]);
             }
           }
 
           /*  this.encfacservice.calcularTotales();
            this.aggrid.refreshaggrid(this.encfacservice.detfacarray[this.init.tabs[this.init.tabindex].indice], 'detfac');
            this.gridApi.setFocusedCell(index, 'DETFAC_PRECIO'); */
 
         }
         //CDPJ
        if (intACTPEDVAL === 1) {
          if (Number(immutableStore[index].DETFAC_PRECIO) > Number(immutableStore[index].PRECIO_FACTURADO)) {
            immutableStore[index].DETFAC_PRECIO = immutableStore[index].PRECIO_FACTURADO;
          }
        }

        const intNOFACPREL = this.encfacservice.hmpConfiguracion.find(element => element.CFG_CODIGO ===
          'NOFACPREL').CFG_VALOR1;
        if (intNOFACPREL === 1) { // no permitir facturar con existencias negativas
          await this.encfacservice.verificarPrecioBajoListaPre(immutableStore[index]);
        }
        await this.encfacservice.comprobarPrecio(immutableStore[index]);


        this.encfacservice.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacservice.detfacarray[this.init.tabs[this.init.tabindex].indice], 'detfac');
        this.gridApi.setFocusedCell(index, 'DETFAC_DESCUENTO');

      } else {
        this.encfacservice.calcularTotales();
        this.aggrid.refreshaggrid(this.encfacservice.detfacarray[this.init.tabs[this.init.tabindex].indice], 'detfac');
        this.gridApi.setFocusedCell(index, 'DETFAC_DESCUENTO');
      }
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'presucon') {
      this.presuplancuentasService.sumarAcumumulado(index);
      this.aggrid.refreshaggridindexSNMVSCROLL(this.presuplancuentasService.detpresupuestoarray, 'presucon',index);
    }
    if (this.gridApi.aggFuncService === 'presuanucon') {
      this.conpresuanucenService.sumarAcumumuladoCnta(index);
      this.aggrid.refreshaggridindexSNMVSCROLL(this.conpresuanucenService.detpresuanupuestoarray, 'presuanucon',index);
    }
    if (this.gridApi.aggFuncService === 'presuanucen') {
      this.conpresuanucenService.sumarAcumumuladoCen(index);
      this.aggrid.refreshaggridindexSNMVSCROLL(this.conpresuanucenService.detpresuanupuestocenarray, 'presuanucen',index);
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'cxcpagoretret') {
      this.cxctrnpagoretService.calcularTRetenido(index);

      if (this.columidactual === 'TRNRETENCION_NRO') {
        const intNumCar = 7;
        const intTamanioCad = immutableStore[index].TRNRETENCION_NRO.length;
        let strCadCeros = '';

        for (let i = 1; i <= intNumCar - intTamanioCad; i++) {
          strCadCeros = strCadCeros + '0';
        }

        immutableStore[index].TRNRETENCION_NRO = strCadCeros + immutableStore[index].TRNRETENCION_NRO;
      }

      this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretretenciones, 'cxcpagoretret');

    }

    if (this.gridApi.aggFuncService === 'cxcpagoretdoc') {
      this.cxctrnpagoretService.calcularTotalDocumentos();
    }

    if (this.gridApi.aggFuncService === 'commaeproveedorFP') {
      this.aggrid.onCellKeyDown(event);

    }
    if (this.gridApi.aggFuncService === 'cxcpagoretformapago') {

      this.cxctrnpagoretService.calcularTotalFormaPagoDev();

      if (this.columidactual === 'TRNPAGO_TIPO') { // TRNPAGO_TIPO  BANCLI_CODIGO
        if (immutableStore[index].TRNPAGO_TIPO.trim() === 'EF') {
          const fecha = new Date();
          const numeroEF = (index + 1).toString() + this.datePipe.transform(fecha, 'ddMMyyyyhhmmss');
          immutableStore[index].CAJ_CODIGO = '001';
          immutableStore[index].NUMERO = numeroEF;
        }
        if (immutableStore[index].TRNPAGO_TIPO.trim() === 'CH') {
          // this.aggrid.onCellKeyDown(event);
          console.log('focus banco');
          this.gridApi.setFocusedCell(index, 'BANCLI_CODIGO');

        }

      }
      if (this.columidactual === 'BANCLI_CODIGO') { // TRNPAGO_TIPO  BANCLI_CODIGO
        if (immutableStore[index].TRNPAGO_TIPO.trim() === 'CH') {
          this.aggrid.onCellKeyDown(event);
        }

      }


      this.aggrid.refreshaggrid(this.cxctrnpagoretService.cxcpagoretformapago, 'cxcpagoretformapago');

    }

    if (this.gridApi.aggFuncService === 'trnretencion') {
      if (this.columidactual === 'TRNRETENCION_FECHAEMISION') {
        if (immutableStore[index].TRNRETENCION_FECHAEMISION.toString().includes('-')) {
          immutableStore[index].TRNRETENCION_FECHAEMISION = this.datePipe.transform(immutableStore[index].TRNRETENCION_FECHAEMISION, 'dd/MM/yyyy');
        }
      }

      if (this.columidactual === 'TRNRETENCION_TOTALRETENIDO') {
        this.encfacproService.calcularSoloTotalRetenido();
        this.encfacproService.calcularTotalConRetenciones();
        this.aggrid.refreshaggrid(this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice], 'trnretencion');
      } else {
        this.encfacproService.calcularTotalesRetencion();
        this.encfacproService.calcularTotalConRetenciones();
        this.aggrid.refreshaggrid(this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice], 'trnretencion');
      }
    }

    if (this.gridApi.aggFuncService === 'detfacpro') {
      if (this.columidactual === 'DETFACPRO_VALDES') {
        if (Number(immutableStore[index].DETFACPRO_VALDES) === 0) {
          immutableStore[index].DETFACPRO_PORDES = 0;
        }
      }
      // if(this.columidactual === 'DETFACPRO_VALDES' || this.columidactual === 'DETFACPRO_CANTIDAD' || this.columidactual ==='DETFACPRO_COSTO'
      // ||this.columidactual === 'DETFACPRO_PORDES' ||this.columidactual ==='DETFACPRO_PORDES2' ||this.columidactual ==='DETFACPRO_PORDES3'){
      this.encfacproService.calcularTotales();
      this.aggrid.refreshaggrid(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice], 'detfacpro');
      this.gridApi.setFocusedCell(index, 'DETFACPRO_PORIVA'); 
      //}
      
    }

    if (this.gridApi.aggFuncService === 'comnotrecRecepcion') {
      if (this.columidactual === 'DETNOTREC_CODIGO') {
        this.aggrid.onCellKeyDown(event);

      } else {
        if(this.columidactual === 'DETNOTREC_TRIBIVA'){
          if(immutableStore[index].DETNOTREC_TRIBIVA === 'N' || immutableStore[index].DETNOTREC_TRIBIVA === 'S'){
            immutableStore[index].DETNOTREC_PORIVA='';
            immutableStore[index].TARIIVA_CODIGO='';
          }
        }
        this.comnotrecServicio.calcularTotales(index);
        this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice], 'comnotrecRecepcion');
      }

    }

    if (this.gridApi.aggFuncService === 'comnotrecImportaciones') {

      this.comnotrecServicio.calcularImportacion(index);
      this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice], 'comnotrecImportaciones');
    }
    if (this.gridApi.aggFuncService === 'comnotrecVarios') {

      this.comnotrecServicio.calcularVariosmodificacion(index);
      this.aggrid.refreshaggrid(this.comnotrecServicio.comnotrecVararray[this.init.tabs[this.init.tabindex].indice], 'comnotrecVarios');
    }


    if (this.gridApi.aggFuncService === 'vendetped') {
      //CDPJ
      if(this.columidactual === 'DETPED_TRIBIVA'){
        if(immutableStore[index].DETPED_TRIBIVA === 'N' || immutableStore[index].DETPED_TRIBIVA === 'S'){
          immutableStore[index].DETPED_PORIVA='';
          immutableStore[index].TARIIVA_CODIGO='';
        }
      }
      //CDPJ
      if (this.columidactual === 'DETPED_DESCUENTO') {
        if (Number(immutableStore[index].DETPED_DESCUENTO) > 100) {
          this.aggrid.msgValidacionDescVenPed('El porcentaje de descuento no puede ser mayor al 100%');
          immutableStore[index].DETPED_DESCUENTO = 0;
          this.aggrid.refreshaggridindex(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped', index - 1);
        }

      }

      if (this.columidactual === 'DETPED_CANTIDAD') {
        if (Number(immutableStore[index].DETPED_CANTIDAD) < Number(immutableStore[index].DETPED_DESPACHO)) {
          this.aggrid.msgValidacionDescVenPed('La cantidad que ha ingresado es menor a la cantidad despachada');
          immutableStore[index].DETPED_CANTIDAD = Number(immutableStore[index].DETPED_DESPACHO).toFixed(this.encpedService.decimalesCantidadPed);
          this.encpedService.calcularTotal();
          this.aggrid.refreshaggridindex(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped', index - 1);
          return;
        }
        if (immutableStore[index].DETPED_TIPODET === 'A') {
          if (this.encpedService.obtenerConfig() === 0 && this.encpedService.cotizacionBooleanarray[this.init.tabs[this.init.tabindex].indice] === false) {
            this.encpedService.existenciaActualArticulo(immutableStore[index].DETPED_CODIGO,
              immutableStore[index].BOD_CODIGO).subscribe((res) => {
                res.map(existArt => {
                  let cantArtTotal = 0;
                  let existenciaRest = 0;

                  for (let fila = 0; fila < immutableStore.length; fila++) {
                    const item = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila];
                    if (item.DETPED_CODIGO === immutableStore[index].DETPED_CODIGO) {
                      cantArtTotal = cantArtTotal + Number(item.DETPED_CANTIDAD);
                    }

                  }
                  if (cantArtTotal > existArt.EXISTENCIA) {
                    cantArtTotal = 0;
                    immutableStore[index].DETPED_CANTIDAD = 0;
                    for (let fila = 0; fila < immutableStore.length; fila++) {
                      const item = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila];
                      if (item.DETPED_CODIGO === immutableStore[index].DETPED_CODIGO) {
                        cantArtTotal = cantArtTotal + Number(item.DETPED_CANTIDAD);
                      }
                    }
                    existenciaRest = existArt.EXISTENCIA - cantArtTotal;
                    this.aggrid.msgValidacionDescVenPed('La cantidad que ha ingresado es mayor a la cantidad de existencia del articulo');
                    //GSRF
                    if(this.encpedService.obtenerConfig2() === 1){
                      console.log('4--->')
                      immutableStore[index].DETPED_CANTIDAD = '';
                    }else{
                      console.log('5--->')
                      immutableStore[index].DETPED_CANTIDAD = existenciaRest.toFixed(this.encpedService.decimalesCantidadPed);
                    }
                    //immutableStore[index].DETPED_CANTIDAD = existenciaRest.toFixed(this.encpedService.decimalesCantidadPed);
                    //GSRF
                    this.encpedService.calcularTotal();//GSRF
                    immutableStore[index].COLOR = 'SI';//GSRF
                    this.aggrid.refreshaggridindex(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped', index - 1);
                    //  this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped');
                  }else{
                    immutableStore[index].COLOR = 'NO';
                  }
                  // if (Number(immutableStore[index].DETPED_CANTIDAD) >= existArt.EXISTENCIA) {
                  //   immutableStore[index].DETPED_CANTIDAD = 0;
                  //   for ( let fila = 0; fila < immutableStore.length; fila++) {
                  //     const item = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila];
                  //     if (item.DETPED_CODIGO === immutableStore[index].DETPED_CODIGO ) {
                  //       cantArtTotal = cantArtTotal + Number(item.DETPED_CANTIDAD);
                  //     }
                  //   }
                  //   const existenciaRest = existArt.EXISTENCIA - cantArtTotal;
                  //   this.aggrid.msgValidacionDescVenPed('La cantidad que ha ingresado es mayor a la cantidad de existencia del articulo');
                  //   immutableStore[index].DETPED_CANTIDAD = existenciaRest.toFixed(this.encpedService.decimalesCantidadPed);
                  //   this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped');
                  // }
                });
              });
          }
        }
        const intACTPEDVAL = Number(this.encpedService.getConfiguraciones('ACTPEDVAL'));
        if (intACTPEDVAL === 1) {
          if (immutableStore[index].DETPED_TIPODET === 'Z') {
            if (this.encpedService.obtenerConfig() === 0 && this.encpedService.cotizacionBooleanarray[this.init.tabs[this.init.tabindex].indice] === false) {
              this.encpedService.existenciaActualArticulo(immutableStore[index].DETPED_CODIGO,
                immutableStore[index].BOD_CODIGO).subscribe((res) => {
                  res.map(existArt => {
                    let cantArtTotal = 0;
                    let existenciaRest = 0;

                    for (let fila = 0; fila < immutableStore.length; fila++) {
                      const item = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila];
                      if (item.DETPED_CODIGO === immutableStore[index].DETPED_CODIGO) {
                        cantArtTotal = cantArtTotal + Number(item.DETPED_CANTIDAD);
                      }

                    }
                    if (cantArtTotal > existArt.EXISTENCIA) {
                      cantArtTotal = 0;
                      immutableStore[index].DETPED_CANTIDAD = 0;
                      for (let fila = 0; fila < immutableStore.length; fila++) {
                        const item = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][fila];
                        if (item.DETPED_CODIGO === immutableStore[index].DETPED_CODIGO) {
                          cantArtTotal = cantArtTotal + Number(item.DETPED_CANTIDAD);
                        }
                      }
                      existenciaRest = existArt.EXISTENCIA - cantArtTotal;
                      this.aggrid.msgValidacionDescVenPed('La cantidad que ha ingresado es mayor a la cantidad de existencia del articulo');
                      immutableStore[index].DETPED_CANTIDAD = existenciaRest.toFixed(this.encpedService.decimalesCantidadPed);
                      immutableStore[index].COLOR = 'SI';//GSRF
                      this.aggrid.refreshaggridindex(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped', index - 1);
                      //  this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped');
                    }else{
                      immutableStore[index].COLOR = 'NO';
                  }
                  });
                });
            }
            console.log(index);
            this.encpedService.FunGlbCambiaCantArtConCompFac(index);
            console.log(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]);
            setTimeout(() => {
              this.encpedService.calcularTotal();
              this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped');
              this.aggrid.refreshaggridindex(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped', index - 1);
              this.gridApi.setFocusedCell(index, 'DETPED_PRECIO');
            }, 500);
          }
        }
        this.gridApi.tabToNextCell();
        this.gridApi.tabToNextCell();
        this.gridApi.tabToNextCell();
      }
      this.encpedService.calcularTotal();
      this.aggrid.refreshaggridindex(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped', index - 1);
      // this.aggrid.refreshaggrid(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice], 'vendetped');
    }
    if (this.gridApi.aggFuncService === 'comdetordcom') {
      if(this.columidactual === 'DETORDCOM_TRIBIVA'){
        if(immutableStore[index].DETORDCOM_TRIBIVA === 'N' || immutableStore[index].DETORDCOM_TRIBIVA === 'S'){
          immutableStore[index].DETORDCOM_PORIVA='';
          immutableStore[index].TARIIVA_CODIGO='';
        }
      }
      this.encordService.calcularTotal();
      this.aggrid.refreshaggrid(this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice], 'comdetordcom');
    }
    if (this.gridApi.aggFuncService === 'maeentrada') {
      this.entradaService.calcularTotal();
      this.aggrid.refreshaggrid(this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice], 'maeentrada');
    }
    if (this.gridApi.aggFuncService === 'maesalida') {
      this.salidaService.calcularTotal();
      this.aggrid.refreshaggrid(this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice], 'maesalida');
    }
    if (this.gridApi.aggFuncService === 'maetomafisica') {
      if (this.columidactual === 'TRNTOMAFISICA_CANTIDAD') {
        let dblAjuste = 0;

        dblAjuste = Number(immutableStore[index].TRNTOMAFISICA_CANTIDAD) - Number(immutableStore[index].TRNTOMAFISICA_CANTIDADK);
        immutableStore[index].TRNVALOR_AJUSTE = Number(dblAjuste).toFixed(this.tomFisService.obtenerConfig('CANTIDAD', 'trntomaf'));
        immutableStore[index].TRNTOMAFISICA_TOTAL = Number(Number(immutableStore[index].TRNTOMAFISICA_COSTOPROM) *
          Number(immutableStore[index].TRNVALOR_AJUSTE)).toFixed(this.tomFisService.obtenerConfig('COSTO', 'trntomaf'));
        this.tomFisService.cargarTotalTomaFisica();
        immutableStore[index].TRNTOMAFISICA_CANTIDAD = Number(immutableStore[index].TRNTOMAFISICA_CANTIDAD).toFixed(this.tomFisService.obtenerConfig('CANTIDAD', 'trntomaf'));

      }
      // this.tomFisService.calcularTotal();
      this.aggrid.refreshaggridindex(this.tomFisService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice], 'maetomafisica', index - 1);
    }
    if (this.gridApi.aggFuncService === 'tablaseriales') {
      this.trnkardexotrservice.extraerArticuloPorSerie(immutableStore[index]).subscribe(data => {
        for (const rs of data) {
          immutableStore[index].TRNKARDEXOTR_DIM = rs.TRNKARDEXOTR_DIM;
          immutableStore[index].TRNKARDEXOTR_TELEFONO = rs.TRNKARDEXOTR_TELEFONO;
        }
        this.aggrid.refreshaggrid(immutableStore, 'tablaseriales');
      });
    }
    if (this.gridApi.aggFuncService === 'vendetguia') {
      //CDPJ
      if(this.columidactual === 'DETGRE_TRIBIVA'){
        if(immutableStore[index].DETGRE_TRIBIVA === 'N' || immutableStore[index].DETGRE_TRIBIVA === 'S'){
          immutableStore[index].DETGRE_PORIVA='';
          immutableStore[index].TARIIVA_CODIGO='';
        }
      }
      //CDPJ



      if (this.columidactual === 'DETGRE_CANTIDAD') {
        if (immutableStore[index].DETGRE_TIPODET === 'A') {
          if (this.venenguiaRemisionService.getConfigFCEXNEG() === 0) {
            this.venenguiaRemisionService.cantidadVsExistencia(immutableStore[index]);
            setTimeout(() => {
              this.aggrid.refreshaggridindex(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice], 'vendetguia', index - 1);
              // this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice], 'vendetguia');
              this.gridApi.setFocusedCell(index, 'DETGRE_PRECIO');
            }, 1500);
          }
        }
      }

      if (this.columidactual === 'DETGRE_DESCUENTO') {
        if (Number(immutableStore[index].DETGRE_DESCUENTO) > 100) {
          this.aggrid.msgValidacionDescDETGRE('El porcentaje de descuento no puede ser mayor al 100%');
          immutableStore[index].DETGRE_DESCUENTO = 0;
          this.aggrid.refreshaggridindex(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice], 'vendetguia', index - 1);
        }
      }

      if (this.columidactual === 'DETGRE_PRECIO') {
        const intACTPEDVAL = this.venenguiaRemisionService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ACTPEDVAL').CFG_VALOR1;
        if (intACTPEDVAL === 1) {
          if (Number(immutableStore[index].DETGRE_PRECIO) > Number(immutableStore[index].PRECIO_FACTURADO)) {
            immutableStore[index].DETGRE_PRECIO = immutableStore[index].PRECIO_FACTURADO;
            this.aggrid.refreshaggridindex(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice], 'vendetguia', index - 1);
          }
        }

      }

      this.venenguiaRemisionService.calcularTotal();
      this.aggrid.refreshaggridindex(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice], 'vendetguia', index - 1);
      // this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice], 'vendetguia');
    }
    if (this.gridApi.aggFuncService === 'vendetdvf') {
      //CDPJ
      if(this.columidactual === 'DETDVF_TRIBIVA'){
        if(immutableStore[index].DETDVF_TRIBIVA === 'N' || immutableStore[index].DETDVF_TRIBIVA === 'S'){
          immutableStore[index].DETDVF_PORIVA='';
          immutableStore[index].TARIIVA_CODIGO='';
        }
      }
      if (this.columidactual === 'DETDVF_CANTIDAD' && immutableStore[index].ENCFAC_NUMERO.length !== 0) {
        this.venDevFacService.verificarCantidadDev(immutableStore[index]);
        setTimeout(() => {
          this.venDevFacService.calcularTotal();
          this.aggrid.refreshaggrid(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice], 'vendetdvf');
          this.gridApi.setFocusedCell(index, 'DETDVF_PRECIO');//CDPJ
        }, 500);
      } else {
        this.venDevFacService.calcularTotal();
        this.aggrid.refreshaggrid(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice], 'vendetdvf');
        this.gridApi.setFocusedCell(index, 'DETDVF_PRECIO');//CDPJ
      }
    }
    if (this.gridApi.aggFuncService === 'cxcdocumentos') {
      this.cxcCarteraService.calcularTotalDoc();
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice], 'cxcdocumentos');
      const indi = this.cxcCarteraService.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].indexOf(immutableStore[index]);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.gridApi.aggFuncService].forEachNode((node) => {
          const h = indi;
          if (Number(node.id) === h) {
            node.setSelected(true);
          }
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.gridApi.aggFuncService].ensureIndexVisible(h, 'bottom');
        });
    }
     //CDPJ
     if (this.gridApi.aggFuncService === 'cxctrnabonodocumentos') {
      this.cxctrnabonoService.calcularTotalDoc();
      this.aggrid.refreshaggrid(this.cxctrnabonoService.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice], 'cxctrnabonodocumentos');
      const indi = this.cxctrnabonoService.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].indexOf(immutableStore[index]);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.gridApi.aggFuncService].forEachNode((node) => {
          const h = indi;
          if (Number(node.id) === h) {
            node.setSelected(true);
          }
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.gridApi.aggFuncService].ensureIndexVisible(h, 'bottom');
        });
    }
    if (this.gridApi.aggFuncService === 'cxctrncancelaciondocumentos') {
      this.cxctrncancelacionService.calcularTotalDoc();
      this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice], 'cxctrncancelaciondocumentos');
      const indi = this.cxctrncancelacionService.cxctrnresumenarray[this.init.tabs[this.init.tabindex].indice].indexOf(immutableStore[index]);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.gridApi.aggFuncService].forEachNode((node) => {
          const h = indi;
          if (Number(node.id) === h) {
            node.setSelected(true);
          }
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.gridApi.aggFuncService].ensureIndexVisible(h, 'bottom');
        });
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'comdetdev') {
      this.comDevFacService.calcularTotal();
      this.aggrid.refreshaggrid(this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice], 'comdetdev');
    }
    if (this.gridApi.aggFuncService === 'maepresupuesto') {
      this.cuentaService.calculosPresupuesto(immutableStore);
      this.aggrid.refreshaggrid(this.cuentaService.conmaePresupuesto, 'maepresupuesto');
    }
    if (this.gridApi.aggFuncService === 'cxcretenciones') {
      this.cxcCarteraService.calcularTotalRetenido(-1);
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice], 'cxcretenciones');
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'cxctrnretenciones') {
      this.cxctrnretencionesService.calcularTotalRetenido(-1);
      this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice], 'cxctrnretenciones');
    }
    if (this.gridApi.aggFuncService === 'cxctrnabonoretenciones') {
      this.cxctrnabonoService.calcularTotalRetenido(-1);
      this.aggrid.refreshaggrid(this.cxctrnabonoService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice], 'cxctrnabonoretenciones');
    }
    if (this.gridApi.aggFuncService === 'cxctrncancelacionretenciones') {
      this.cxctrncancelacionService.calcularTotalRetenido(-1);
      this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice], 'cxctrncancelacionretenciones');
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'cxcformapagodev') {
      this.cxcCarteraService.calcularTotalFormaPagoDev();
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice], 'cxcformapagodev');
    }
    if (this.gridApi.aggFuncService === 'cxcformapago') {
      if (this.columidactual === 'TRNPAGO_FECHA') {
        if (immutableStore[index].TRNPAGO_TIPO === 'CH') {
          immutableStore[index].TRNPAGO_FECHA = await this.cxcCarteraService.controlarChequesPosf(immutableStore[index]); //controlar los días del cheque posfechado de acuerdo al cliente
          this.aggrid.refreshaggridindex(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice], 'cxcformapago', index - 1);
          this.gridApi.setFocusedCell(index, this.columidactual);
        }
      } else {
        this.cxcCarteraService.calcularTotalFormaPago();
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice], 'cxcformapago');
      }

    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'cxctrnabonoformapago') {
      if (this.columidactual === 'TRNPAGO_FECHA') {
        if (immutableStore[index].TRNPAGO_TIPO === 'CH') {
          immutableStore[index].TRNPAGO_FECHA = await this.cxctrnabonoService.controlarChequesPosf(immutableStore[index]); //controlar los días del cheque posfechado de acuerdo al cliente
          this.aggrid.refreshaggridindex(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice], 'cxctrnabonoformapago', index - 1);
          this.gridApi.setFocusedCell(index, this.columidactual);
        }
      } else {
        this.cxctrnabonoService.calcularTotalFormaPago();
        this.aggrid.refreshaggrid(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice], 'cxctrnabonoformapago');
      }

    }
    if (this.gridApi.aggFuncService === 'cxctrncancelacionformapago') {
      if (this.columidactual === 'TRNPAGO_FECHA') {
        if (immutableStore[index].TRNPAGO_TIPO === 'CH') {
          immutableStore[index].TRNPAGO_FECHA = await this.cxctrncancelacionService.controlarChequesPosf(immutableStore[index]); //controlar los días del cheque posfechado de acuerdo al cliente
          this.aggrid.refreshaggridindex(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice], 'cxctrncancelacionformapago', index - 1);
          this.gridApi.setFocusedCell(index, this.columidactual);
        }
      } else {
        this.cxctrncancelacionService.calcularTotalFormaPago();
        this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice], 'cxctrncancelacionformapago');
      }

    }
    // if (this.gridApi.aggFuncService === 'cxctrnanticipoformapago') {
    //   if (this.columidactual === 'TRNPAGO_FECHA') {
    //     if (immutableStore[index].TRNPAGO_TIPO === 'CH') {
    //       immutableStore[index].TRNPAGO_FECHA = await this.cxctrnanticipoService.controlarChequesPosf(immutableStore[index]); //controlar los días del cheque posfechado de acuerdo al cliente
    //       this.aggrid.refreshaggridindex(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice], 'cxctrnanticipoformapago', index - 1);
    //       this.gridApi.setFocusedCell(index, this.columidactual);
    //     }
    //   } else {
    //     this.cxctrnanticipoService.calcularTotalFormaPago();
    //     this.aggrid.refreshaggrid(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice], 'cxctrnanticipoformapago');
    //   }

    // }
    //CDPJ
    if (this.gridApi.aggFuncService === 'cxcotrosgastos') {
      this.cxcCarteraService.calcularTotalOtrosG();
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice], 'cxcotrosgastos');
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'cxctrnabonootrosgastos') {
      this.cxctrnabonoService.calcularTotalOtrosG();
      this.aggrid.refreshaggrid(this.cxctrnabonoService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice], 'cxctrnabonootrosgastos');
    }
    if (this.gridApi.aggFuncService === 'cxctrncancelacionotrosgastos') {
      this.cxctrncancelacionService.calcularTotalOtrosG();
      this.aggrid.refreshaggrid(this.cxctrncancelacionService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice], 'cxctrncancelacionotrosgastos');
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'districc') {
      this.distriCCService.calcular(this.columidactual, immutableStore[index]);
      this.aggrid.refreshaggrid(this.distriCCService.distribucionCC, 'districc');
    }
    if (this.gridApi.aggFuncService === 'cxppagovarios') {

      if (this.columidactual === 'DEBE') {
        if (Number(immutableStore[index].HABER) > 0) {
          immutableStore[index].DEBE = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }

        if (Number(immutableStore[index].HABER) === 0) {
          if (immutableStore[index].BAN_CODIGO !== '' && immutableStore[index].TRNBAN_TIPO !== 'CH') {
            if (immutableStore[index].DEBE !== 0) {
              immutableStore[index].TRNBAN_TIPO = 'DP';
            }
          }
        }

      }

      if (this.columidactual === 'HABER') {
        if (Number(immutableStore[index].DEBE) > 0) {
          immutableStore[index].HABER = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }
        if (Number(immutableStore[index].DEBE) === 0) {
          if (immutableStore[index].BAN_CODIGO !== '' && immutableStore[index].TRNBAN_TIPO !== 'CH') {
            if (Number(immutableStore[index].HABER) !== 0) {
              immutableStore[index].TRNBAN_TIPO = 'RE';
            }
          }
        }

      }


      // immutableStore[index].TRNBAN_TIPO = '';
      // if (immutableStore[index].BAN_CODIGO !== '' && immutableStore[index].TRNBAN_TIPO !== 'CH') {
      //   if (Number(immutableStore[index].DEBE) !== 0) {
      //     immutableStore[index].TRNBAN_TIPO = 'DP';
      //   }
      //   if (Number(immutableStore[index].HABER) !== 0) {
      //     immutableStore[index].TRNBAN_TIPO = 'RE';
      //   }
      // }
      this.cxptrnpagodocService.calcularImporteTotal();
      this.aggrid.refreshaggrid(this.cxptrnpagodocService.varios, 'cxppagovarios');
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'cxcvarios') {

      if (this.columidactual === 'DEBE') {
         //GSRF
         if(Number(immutableStore[index].DEBE) !== 0){
          immutableStore[index].DEBE = Number(immutableStore[index].DEBE).toFixed(this.confIniciales.getNumDecSist())
        }
       //GSRF
        if (Number(immutableStore[index].HABER) > 0) {
          immutableStore[index].DEBE = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }

        if (Number(immutableStore[index].HABER) === 0) {
          if (immutableStore[index].BAN_CODIGO !== '') {
            if (immutableStore[index].DEBE !== 0) {
              immutableStore[index].TRNBAN_TIPO = 'DP';
            }
          }
        }

      }

      if (this.columidactual === 'HABER') {
        //GSRF
        if(Number(immutableStore[index].HABER) !== 0){
          immutableStore[index].HABER = Number(immutableStore[index].HABER).toFixed(this.confIniciales.getNumDecSist())
        }
        //GSRF
        if (Number(immutableStore[index].DEBE) > 0) {
          immutableStore[index].HABER = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }
        if (Number(immutableStore[index].DEBE) === 0) {
          if (immutableStore[index].BAN_CODIGO !== '') {
            if (Number(immutableStore[index].HABER) !== 0) {
              immutableStore[index].TRNBAN_TIPO = 'RE';
            }
          }
        }

      }


      // immutableStore[index].TRNBAN_TIPO = '';
      // if (immutableStore[index].BAN_CODIGO !== '' && immutableStore[index].TRNBAN_TIPO !== 'CH') {
      //   if (Number(immutableStore[index].DEBE) !== 0) {
      //     immutableStore[index].TRNBAN_TIPO = 'DP';
      //   }
      //   if (Number(immutableStore[index].HABER) !== 0) {
      //     immutableStore[index].TRNBAN_TIPO = 'RE';
      //   }
      // }
      //this.cxptrnpagodocService.calcularImporteTotal();

      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice], 'cxcvarios');
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'trncargos') {
      let sumcargos = 0;
      for (const cargo of this.encfacproService.trncargosarray[this.init.tabs[this.init.tabindex].indice]) {
        sumcargos = sumcargos + Number(cargo.TRNCARGOS_IMPORTE);
        cargo.TRNCARGOS_IMPORTE = Number(cargo.TRNCARGOS_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
      }
      this.encfacproService.totalcargosarray[this.init.tabs[this.init.tabindex].indice] = sumcargos.toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].TOTALCARGOS = this.encfacproService.totalcargosarray[this.init.tabs[this.init.tabindex].indice];
      this.aggrid.refreshaggrid(this.encfacproService.trncargosarray[this.init.tabs[this.init.tabindex].indice], 'trncargos');
    }
    if (this.gridApi.aggFuncService === 'actpreciosfp') {
      let incremento = 0;
      for (const precio of this.actpreService.listdetalleprecios) {
        incremento = Number(precio.PRECIO) - Number(precio.ARTPRE_PRECIO);
        precio.ARTPRE_PORCENTAJE = Number(precio.ARTPRE_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
        precio.PRECIO = Number(precio.PRECIO).toFixed(this.confIniciales.getNumDecSist());
        precio.INCREMENTO = Number(incremento).toFixed(this.confIniciales.getNumDecSist());
      }

      this.aggrid.refreshaggrid(this.actpreService.listdetalleprecios, 'actpreciosfp');
    }
    if (this.gridApi.aggFuncService === 'conencasil') {

      for (const detalle of this.conecasilService.detencasil) {
        detalle.DETASI_DEBE = Number(detalle.DETASI_DEBE).toFixed(this.confIniciales.getNumDecSist());
        detalle.DETASI_HABER = Number(detalle.DETASI_HABER).toFixed(this.confIniciales.getNumDecSist());
      }

      if (this.columidactual === 'CEN_CODIGO') {
        if (immutableStore[index].CON_CENTRO === 'No') {
          immutableStore[index].CEN_CODIGO = '';
        }
      }

      if (this.columidactual === 'DETASI_CONCEPTO') {
        if (immutableStore[index].DETASI_CONCEPTO !== '') {
          immutableStore[index].DETASI_CONCEPTOPRY = immutableStore[index].DETASI_CONCEPTO;
        }
      }
      if (this.columidactual === 'DETASI_DEBE') {
        if (immutableStore[index].DETASI_HABER > 0) {
          immutableStore[index].DETASI_DEBE = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }
        if (immutableStore[index].BAN_CODIGO !== '' && immutableStore[index].TRNBAN_TIPO !== 'CH') {
          if (Number(immutableStore[index].DETASI_DEBE) !== 0 && Number(immutableStore[index].DETASI_HABER) === 0) {
            immutableStore[index].TRNBAN_TIPO = 'DP';
          }
        }
      }

      if (this.columidactual === 'DETASI_HABER') {
        if (immutableStore[index].DETASI_DEBE > 0) {
          immutableStore[index].DETASI_HABER = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }
        if (immutableStore[index].BAN_CODIGO !== '' && immutableStore[index].TRNBAN_TIPO !== 'CH') {
          if (immutableStore[index].DETASI_HABER !== 0 && Number(immutableStore[index].DETASI_DEBE) === 0) {
            immutableStore[index].TRNBAN_TIPO = 'RE';
          }
        }
      }


      this.conecasilService.calcularCostoTotal();
      this.aggrid.refreshaggrid(this.conecasilService.detencasil, 'conencasil');
    }

    if (this.gridApi.aggFuncService === 'banTrnBanCaj') {
      for (const item of this.bantrnbanEditService.banTrnBanCaja) {
        if (item.CHECK_ITEM === true) {
          item.TRNCAJDET_IMPORTE = Number(item.TRNCAJDET_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
        }
      }
      this.bantrnbanEditService.suma();
      this.aggrid.refreshaggrid(this.bantrnbanEditService.banTrnBanCaja, 'conencasil');
    }

    if (this.gridApi.aggFuncService === 'contrnDistribcc') {
      let dblPorcentaje = 0;
      let dblValorXPorce = 0;
      const dblValor = this.contrndistribccService.dblValor;


      if (this.columidactual === 'TRNDISP_PORCE') {
        if (Number(immutableStore[index].TRNDISP_PORCE) > 100) {
          this.aggrid.msgValidacionDescVenPed('El porcentaje no puede ser mayor al 100%');
          immutableStore[index].TRNDISP_PORCE = 0;
          this.aggrid.refreshaggridindex(this.contrndistribccService.detDistribcc, 'contrnDistribcc', index - 1);
        } else {
          dblPorcentaje = Number(immutableStore[index].TRNDISP_PORCE) / 100;
          dblValorXPorce = Number(dblValor) * dblPorcentaje;
          immutableStore[index].TRNDISP_VALOR = Number(dblValorXPorce).toFixed(this.confIniciales.getNumDecSist());
        }
      }
      if (this.columidactual === 'TRNDISP_VALOR') {
        // if (Number(immutableStore[index].TRNDISP_PORCE) === 0) {
        dblValorXPorce = Number(immutableStore[index].TRNDISP_VALOR);
        dblPorcentaje = (100 * dblValorXPorce) / Number(dblValor);
        immutableStore[index].TRNDISP_PORCE = Number(dblPorcentaje).toFixed(this.confIniciales.getNumDecSist());
        // }
      }

      for (const item of this.contrndistribccService.detDistribcc) {
        immutableStore[index].TRNDISP_VALOR = Number(immutableStore[index].TRNDISP_VALOR).toFixed(this.confIniciales.getNumDecSist());
        immutableStore[index].TRNDISP_PORCE = Number(immutableStore[index].TRNDISP_PORCE).toFixed(this.confIniciales.getNumDecSist());
      }

      this.contrndistribccService.calcularTotal();
      this.aggrid.refreshaggrid(this.contrndistribccService.detDistribcc, 'contrnDistribcc');
    }

    if (this.gridApi.aggFuncService === 'banpartconsil') {
      if (this.columidactual === 'TRNBAN_FECHA') {
        if (immutableStore[index].TRNBAN_FECHA === '') {
          immutableStore[index].TRNBAN_FECHA = new Date();
          if (immutableStore[index].TRNBAN_FECHA.toString().includes('-')) {
            immutableStore[index].TRNBAN_FECHA = this.datePipe.transform(immutableStore[index].TRNBAN_FECHA, 'dd/MM/yyyy');
          }
        }
      }
      this.aggrid.refreshaggrid(this.banConcilBanService.patidasconsil, 'banpartconsil');
    }
    if (this.gridApi.aggFuncService === 'bantarRetencion') {
      if (this.columidactual === 'TRNRETENCION_NRO') {
        immutableStore[index].TRNRETENCION_NRO = this.bantrntarjetaService.autoCompletar(immutableStore[index].TRNRETENCION_NRO, 9);
      }

      if (this.columidactual === 'TRNRETENCION_BASE') {
        immutableStore[index].TRNRETENCION_TOTALRETENIDO = (Number(immutableStore[index].TRNRETENCION_BASE)
          * Number(immutableStore[index].TRNRETENCION_PORCENTAJE)) / 100;
        immutableStore[index].TRNRETENCION_TOTALRETENIDO = Number(immutableStore[index].TRNRETENCION_TOTALRETENIDO).toFixed(2);
        immutableStore[index].TRNRETENCION_BASE = Number(immutableStore[index].TRNRETENCION_BASE).toFixed(2);
        this.bantrntarjetaService.calcularTotalRetenciones();
      }
      //this.aggrid.refreshaggrid(this.bantrntarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice], 'bantarRetencion');
      this.aggrid.refreshaggrid(this.bantrntarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice], 'bantarRetencion');//CDPJ
    }
    if (this.gridApi.aggFuncService === 'bantarVarios') {

      for (const detalle of this.bantrntarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice]) {
        detalle.DEBE = Number(detalle.DEBE).toFixed(this.confIniciales.getNumDecSist());
        detalle.HABER = Number(detalle.HABER).toFixed(this.confIniciales.getNumDecSist());
      }

      if (this.columidactual === 'CEN_CODIGO') {
        if (immutableStore[index].CON_CENTRO === 'No') {
          immutableStore[index].CEN_CODIGO = '';
        }
      }

      if (this.columidactual === 'DEBE') {
        if (immutableStore[index].HABER > 0) {
          immutableStore[index].DEBE = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }
      }

      if (this.columidactual === 'HABER') {
        if (immutableStore[index].DEBE > 0) {
          immutableStore[index].HABER = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }
      }


      this.bantrntarjetaService.calcularTotalVarios();
      this.aggrid.refreshaggrid(this.bantrntarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice], 'bantarVarios');

    }

    if (this.gridApi.aggFuncService === 'bancajatrnVarios') {

      for (const detalle of this.bantrnCajaService.detModeloVarCaja) {
        detalle.VARIOS_DEBE = Number(detalle.VARIOS_DEBE).toFixed(this.confIniciales.getNumDecSist());
        detalle.VARIOS_HABER = Number(detalle.VARIOS_HABER).toFixed(this.confIniciales.getNumDecSist());
      }

      if (this.columidactual === 'CEN_CODIGO') {
        if (immutableStore[index].CON_CENTRO === 'No') {
          immutableStore[index].CEN_CODIGO = '';
        }
      }

      this.aggrid.refreshaggrid(this.bantrnCajaService.detModeloVarCaja, 'bancajatrnVarios');
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'banTrnBanVar') {

      for (const detalle of this.bantrnbanEditService.banTrnBanVarios) {
        detalle.VARIOS_DEBE = Number(detalle.VARIOS_DEBE).toFixed(this.confIniciales.getNumDecSist());
        detalle.VARIOS_HABER = Number(detalle.VARIOS_HABER).toFixed(this.confIniciales.getNumDecSist());
       
      }
      if (this.columidactual === 'VARIOS_DEBE') {
        if (immutableStore[index].VARIOS_HABER > 0) {
          immutableStore[index].VARIOS_DEBE = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }
      }

      if (this.columidactual === 'VARIOS_HABER') {
        if (immutableStore[index].VARIOS_DEBE > 0) {
          immutableStore[index].VARIOS_HABER = Number(0).toFixed(this.confIniciales.getNumDecSist());
        }
      }
      if (this.columidactual === 'CEN_CODIGO') {
        if (immutableStore[index].CON_CENTRO === 'No') {
          immutableStore[index].CEN_CODIGO = '';
        }
      }

      this.aggrid.refreshaggrid(this.bantrnbanEditService.banTrnBanVarios, 'banTrnBanVar');
    }
    //CDPJ

    if (this.gridApi.aggFuncService === 'dettransfer') {
      if (this.columidactual === 'DETTRANSFER_CANTIDAD') {
        
        const fecha = this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_FECHAEMISION;
        const strFecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
        let strHora = this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_HORA;
        const strArtCodigo = immutableStore[index].ART_CODIGO;
        const strBodCodigo = this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].BOD_CODIGO;
        if(strHora.toString().includes('-')){
          strHora=this.datePipe.transform(this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_HORA, 'HH:mm');
        }


        // Verifica la existencia en negativo
        this.enctransferService.ecGetExistArt(strArtCodigo, strBodCodigo, strFecha, strHora).subscribe(data => {
          let dblExistencia = 0;
          for (const rs of data) {
            dblExistencia = Number(rs.EXIST);
          }

          if (dblExistencia < immutableStore[index].DETTRANSFER_CANTIDAD) {
            this.messageService.add({
              key: 'enctransfer',
              severity: 'error',
              summary: 'Información',
              detail: 'No hay en Existencia'
            });
            if (dblExistencia >= 0)
              immutableStore[index].DETTRANSFER_CANTIDAD = dblExistencia.toFixed(this.enctransferService.decdetcantidad); //Cantidad
          }

          this.enctransferService.calcularCostoTotal();
          this.aggrid.refreshaggrid(this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice], 'dettransfer');
        });
      }

      if (this.columidactual === 'DETTRANSFER_COSTOUNIT') {
        immutableStore[index].DETTRANSFER_COSTOUNIT = Number(immutableStore[index].DETTRANSFER_COSTOUNIT).toFixed(this.enctransferService.decdetcosto); //Cantidad
        this.enctransferService.calcularCostoTotal();
        this.aggrid.refreshaggrid(this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice], 'dettransfer');

      }

    }
    if (this.gridApi.aggFuncService === 'maeDepartamento') {
      if (this.columidactual === 'DEP_NOMBRE' || this.columidactual === 'DEP_CODIGO') {
        if (immutableStore[index].nuevo && immutableStore[index].DEP_NOMBRE !== ''
          && immutableStore[index].DEP_CODIGO !== '') {
          //
          this.nommaedepartamentoService.insertar(immutableStore[index]);
        } else if (immutableStore[index].editado && immutableStore[index].DEP_NOMBRE !== ''
          && immutableStore[index].DEP_CODIGO !== '') {
          this.nommaedepartamentoService.actualizar(immutableStore[index]);
        }
      }
    }
    if (this.gridApi.aggFuncService === 'segUsuarioPerEsp') {
      if (this.columidactual === 'APLICA_CLAVE') {
        if (immutableStore[index].GUIOBJ_TIPOPER === 'C' && (immutableStore[index].APLICA_CLAVE === 'S' || immutableStore[index].APLICA_CLAVE === '')) {
          if (immutableStore[index].APLICA_CLAVE === 'S') {
            immutableStore[index].USU_CLAVESUP = this.segmaeusuarioService.strSuperUserSelect.clave;
            immutableStore[index].GUIOBJPERSUP_ESTADO = 1;
            immutableStore[index].GUIOBJPERESTADO = 'S';
          } else {
            immutableStore[index].USU_CLAVESUP = '';
            immutableStore[index].GUIOBJPERSUP_ESTADO = 0;
            immutableStore[index].GUIOBJPERESTADO = '';
          }

          immutableStore[index].APLICA_CLAVE = immutableStore[index].APLICA_CLAVE;

        } else {
          immutableStore[index].USU_CLAVESUP = '';
          immutableStore[index].GUIOBJPERSUP_ESTADO = 0;
        }
      }

      if (this.columidactual === 'USU_CLAVESUP') {
        if (immutableStore[index].USU_CLAVESUP === '' || immutableStore[index].USU_CLAVESUP !== '') {
          immutableStore[index].USU_CLAVESUP = immutableStore[index].USU_CLAVESUP;

          if (immutableStore[index].USU_CLAVESUP === '') {
            immutableStore[index].USU_CLAVESUP = '';
          }

        }
      }

      if (this.columidactual === 'GUIOBJPERESTADO') {
        if (immutableStore[index].GUIOBJPERESTADO === 'S' || immutableStore[index].GUIOBJPERESTADO === '') {
          immutableStore[index].GUIOBJPERESTADO = immutableStore[index].GUIOBJPERESTADO;
        }
      }

      this.aggrid.refreshaggrid(this.segmaeusuarioService.detguiobjpermiarray[this.init.tabs[this.init.tabindex].indice], 'segUsuarioPerEsp');

    }

    if (this.gridApi.aggFuncService === 'maeartprecio') {

      if ((this.columidactual === 'ARTPRE_COSTO' ||
        this.columidactual === 'ARTPRE_PORCENTAJE') && (Number(immutableStore[index].ARTPRE_COSTO) !== 0 &&
          Number(immutableStore[index].ARTPRE_PORCENTAJE) !== 0)) {
        if (this.artService.intUtilizarUtilCost === true) {
          immutableStore[index].ARTPRE_PRECIO = Number(immutableStore[index].ARTPRE_COSTO) +
            (Number(immutableStore[index].ARTPRE_COSTO) * Number(immutableStore[index].ARTPRE_PORCENTAJE) / 100);
          immutableStore[index].ARTPRE_PRECIO = immutableStore[index].ARTPRE_PRECIO.toFixed(this.artService.obtenerConfig('PRECIO', 'MaePrecio'));
        }
      }
      if (this.columidactual === 'ARTPRE_COSTO') {
        immutableStore[index].ARTPRE_COSTO = Number(immutableStore[index].ARTPRE_COSTO).toFixed(this.artService.obtenerConfig('COSTO', 'MaePrecio'));
      }

      if (this.columidactual === 'ARTPRE_PRECIO') {
        immutableStore[index].ARTPRE_PRECIO = Number(immutableStore[index].ARTPRE_PRECIO).toFixed(this.artService.obtenerConfig('PRECIO', 'MaePrecio'));
      }

      if (this.columidactual === 'ARTPRE_PORCENTAJE') {
        immutableStore[index].ARTPRE_PORCENTAJE = Number(immutableStore[index].ARTPRE_PORCENTAJE).toFixed(this.artService.obtenerConfig('UTILIDAD', 'MaePrecio'));
      }

      if (this.columidactual === 'ARTPRE_DESCUENTO') {
        immutableStore[index].ARTPRE_DESCUENTO = Number(immutableStore[index].ARTPRE_DESCUENTO).toFixed(this.artService.obtenerConfig('DESCUENTO', 'MaePrecio'));
      }

      if (this.columidactual === 'ARTPRE_DESCF') {
        immutableStore[index].ARTPRE_DESCF = Number(immutableStore[index].ARTPRE_DESCF).toFixed(this.artService.obtenerConfig('DescF', 'MaePrecio'));
      }

      if (this.columidactual === 'ARTPRE_DESCP') {
        immutableStore[index].ARTPRE_DESCP = Number(immutableStore[index].ARTPRE_DESCP).toFixed(this.artService.obtenerConfig('DescP', 'MaePrecio'));
      }

      if (this.columidactual === 'ARTPRE_PRECIOFPVP') {
        immutableStore[index].ARTPRE_PRECIOFPVP = Number(immutableStore[index].ARTPRE_PRECIOFPVP).toFixed(this.artService.obtenerConfig('PrecioFpvp', 'MaePrecio'));

      }

      this.aggrid.refreshaggrid(this.artService.maeartprecio, 'maeartprecio');


      // if (this.columidactual === 'ARTPRE_COSTO' ||
      //   this.columidactual === 'ARTPRE_PORCENTAJE') {
      //   if (this.artService.intUtilizarUtilCost === true) {
      //     immutableStore[index].ARTPRE_PRECIO = Number(immutableStore[index].ARTPRE_COSTO) +
      //       (Number(immutableStore[index].ARTPRE_COSTO) * Number(immutableStore[index].ARTPRE_PORCENTAJE) / 100);
      //     immutableStore[index].ARTPRE_PRECIO = immutableStore[index].ARTPRE_PRECIO.toFixed(3);
      //     this.aggrid.refreshaggrid(this.artService.maeartprecio, 'maeartprecio');
      //   }
      // }
    }

    if (this.gridApi.aggFuncService === 'segComConexion') {
      if (this.columidactual === 'IP_DESCRIPCION' ||
        (this.columidactual === 'IP_NUMERO' && immutableStore[index].IP_CODIGO !== 0)) {
        this.segmaeCompaniaService.guardarConexionEnter(immutableStore[index]);

      }
    }

    if (this.gridApi.aggFuncService === 'segMaeSucCom') {
      if (this.columidactual === 'SUC_DESCRIPCION' || this.columidactual === 'SUC_REGIMEN' ||
        (this.columidactual === 'SUC_CODIGO' && immutableStore[index].INT_NUMSEC !== 0)) {
        this.segmaeCompaniaService.guardarSucursalEnter(immutableStore[index]);

      }
    }

    if (this.gridApi.aggFuncService === 'segInfAdiFactCom') {
      if (this.columidactual === 'INFAD_DESCRIPCION' ||
        (this.columidactual === 'INFAD_CODIGO' && immutableStore[index].INFAD_NUMSEC !== 0)) {
        this.segmaeCompaniaService.guardarInfoFactEnter(immutableStore[index]);

      }
    }

    if (this.gridApi.aggFuncService === 'segInfAdiGuiRemCom') {
      if (this.columidactual === 'INFAD_DESCRIPCION' ||
        (this.columidactual === 'INFAD_CODIGO' && immutableStore[index].INFAD_NUMSEC !== 0)) {
        this.segmaeCompaniaService.guardarInfoGuiaRemEnter(immutableStore[index]);

      }
    }

    if (this.gridApi.aggFuncService === 'segInfAdiLiqComCom') {
      if (this.columidactual === 'INFAD_DESCRIPCION' ||
        (this.columidactual === 'INFAD_CODIGO' && immutableStore[index].INFAD_NUMSEC !== 0)) {
        this.segmaeCompaniaService.guardarInfoLiqComEnter(immutableStore[index]);

      }
    }

    //GSRF
    if (this.gridApi.aggFuncService === 'segInfAdidevfac') {
      if (this.columidactual === 'INFAD_DESCRIPCION' ||
        (this.columidactual === 'INFAD_CODIGO' && immutableStore[index].INFAD_NUMSEC !== 0)) {
        this.segmaeCompaniaService.guardarInfodevfacenter(immutableStore[index]);

      }
    }
    //GSRF

    if (this.gridApi.aggFuncService === 'saciconfnumdec') {
      if (this.columidactual === 'NUMDEC_NUMDEC') {
        if (immutableStore[index].NUMDEC_NUMDEC !== null && immutableStore[index].NUMDEC_NUMDEC !== '') {
          immutableStore[index].NUMDEC_NUMDEC = Number(immutableStore[index].NUMDEC_NUMDEC);
          this.sacimeconfigService.actualizarNumDec(immutableStore[index]);
          this.aggrid.refreshaggridindex(this.sacimeconfigService.detSaciMaeNumDecArray[this.init.tabs[this.init.tabindex].indice], 'saciconfnumdec', index - 1);
        } else {
          immutableStore[index].NUMDEC_NUMDEC = 0;
          this.sacimeconfigService.actualizarNumDec(immutableStore[index]);
          this.aggrid.refreshaggridindex(this.sacimeconfigService.detSaciMaeNumDecArray[this.init.tabs[this.init.tabindex].indice], 'saciconfnumdec', index - 1);
        }

      }
    }

    if (this.gridApi.aggFuncService === 'confvenmaeimp') {
      if (this.columidactual === 'IMP_PORCENTAJE') {
        if (immutableStore[index].IMP_PORCENTAJE !== null && immutableStore[index].IMP_PORCENTAJE !== '') {
          immutableStore[index].IMP_PORCENTAJE = Number(immutableStore[index].IMP_PORCENTAJE).toFixed(1);
          this.sacimeconfigService.actualizarPorImp(immutableStore[index]);
          this.aggrid.refreshaggridindex(this.sacimeconfigService.detVenMaeImpArray[this.init.tabs[this.init.tabindex].indice], 'confvenmaeimp', index - 1);
        } else {
          immutableStore[index].IMP_PORCENTAJE = Number(0).toFixed(1);
          this.sacimeconfigService.actualizarPorImp(immutableStore[index]);
          this.aggrid.refreshaggridindex(this.sacimeconfigService.detVenMaeImpArray[this.init.tabs[this.init.tabindex].indice], 'confvenmaeimp', index - 1);
        }

      }

      if (this.columidactual === 'IMP_VALOR') {
        if (immutableStore[index].IMP_VALOR !== null && immutableStore[index].IMP_VALOR !== '') {
          immutableStore[index].IMP_VALOR = Number(immutableStore[index].IMP_VALOR).toFixed(1);
          this.sacimeconfigService.actualizarPorImp(immutableStore[index]);
          this.aggrid.refreshaggridindex(this.sacimeconfigService.detVenMaeImpArray[this.init.tabs[this.init.tabindex].indice], 'confvenmaeimp', index - 1);
        } else {
          immutableStore[index].IMP_VALOR = Number(0).toFixed(1);
          ;
          this.sacimeconfigService.actualizarPorImp(immutableStore[index]);
          this.aggrid.refreshaggridindex(this.sacimeconfigService.detVenMaeImpArray[this.init.tabs[this.init.tabindex].indice], 'confvenmaeimp', index - 1);
        }

      }
    }
    if (this.gridApi.aggFuncService === 'maeNumeraciong') {
      if (this.columidactual === 'NUM_FECHACAD') {
        if (immutableStore[index].NUM_FECHACAD.toString().includes('-')) {
          immutableStore[index].NUM_FECHACAD = this.datePipe.transform(immutableStore[index].NUM_FECHACAD, 'dd/MM/yyyy');
        }
      }
      this.aggrid.refreshaggridindex(this.segmaenumeraciongService.segMaeNumeraciong, 'maeNumeraciong', index - 1);
    }

    if (this.gridApi.aggFuncService === 'cxpenccuota') {
      if (this.columidactual === 'DETCUOTA_IMPORTE') {
        if (String(immutableStore[index].DETCUOTA_IMPORTE) === '' || String(immutableStore[index].DETCUOTA_IMPORTE) === null) {
          immutableStore[index].DETCUOTA_IMPORTE = Number(0).toFixed(this.confIniciales.getNumDecSist());
        } else {
          immutableStore[index].DETCUOTA_IMPORTE = Number(immutableStore[index].DETCUOTA_IMPORTE).toFixed(this.confIniciales.getNumDecSist());
        }

        immutableStore[index].DETCUOTA_SALDO = immutableStore[index].DETCUOTA_IMPORTE;
        this.cxpEncCuotaService.calcularTotalCuotas();

      }
      this.aggrid.refreshaggridindex(this.cxpEncCuotaService.detCuotaArray[this.init.tabs[this.init.tabindex].indice], 'cxpenccuota', index - 1);
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'invtrnajustesneg') {
      if (this.columidactual === 'TRNART_COSTOT') {
        immutableStore[index].TRNART_COSTOT = Number(immutableStore[index].TRNART_COSTOT).toFixed(this.invtrnajustenegService.decimales);
      }
    }
    //CDPJ
    if (this.gridApi.aggFuncService === 'invtrnajustes') {
      if (this.columidactual === 'ART_CODIGO') {
        if (immutableStore[index].ART_CODIGO === '') {
          immutableStore[index].ART_NOMBRE = '';
        }
      }
      if (this.columidactual === 'COSTO_AJUSTE') {

        if (Number(immutableStore[index].COSTO_AJUSTE) === 0) {
          this.messageService.add({
            key: 'invtrnajustes',
            severity: 'info',
            summary: 'Información',
            detail: 'El Costo del ajuste no puede ser igual a cero'
          });
        } else {
          immutableStore[index].COSTO_AJUSTE = Number(immutableStore[index].COSTO_AJUSTE).toFixed(
            this.invtrnajustesService.obtenerConfig('COSTO', 'kardex'));
          immutableStore[index].TRNART_COSTOT = Number(immutableStore[index].COSTO_AJUSTE) + Number(immutableStore[index].ART_COSTO);
          immutableStore[index].TRNART_COSTOT = Number(immutableStore[index].TRNART_COSTOT).toFixed(2);
        }

      }
      if (this.columidactual === 'CEN_CODIGO') {
        if (immutableStore[index].CON_CENTRO === 'No') {
          immutableStore[index].CEN_CODIGO = '';
          this.invtrnajustesService.activarBotonesDet(false, false, true);
        }
      }

      /* if (this.columidactual === 'TRNART_FECHAES') {
         if (immutableStore[index].TRNART_FECHAES === '') {
           immutableStore[index].TRNART_FECHAES = new Date();
           if (immutableStore[index].TRNART_FECHAES.toString().includes('-')) {
             immutableStore[index].TRNART_FECHAES = this.datePipe.transform(immutableStore[index].TRNART_FECHAES, 'dd/MM/yyyy');
           }
         }
       }*/
      // this.invtrnajustesService.insertarElemento(immutableStore[index], this.columidactual);
      this.aggrid.refreshaggridindex(this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice], 'invtrnajustes', index - 1);
    }

    if (this.gridApi.aggFuncService === 'maeNumeracionC') {
      if (this.columidactual === 'NUM_FECHACAD') {
        if (immutableStore[index].NUM_FECHACAD.toString().includes('-')) {
          immutableStore[index].NUM_FECHACAD = this.datePipe.transform(immutableStore[index].NUM_FECHACAD, 'dd/MM/yyyy');
        }
      }
      if (this.columidactual === 'NUM_FECHAINI') {
        if (immutableStore[index].NUM_FECHAINI.toString().includes('-')) {
          immutableStore[index].NUM_FECHAINI = this.datePipe.transform(immutableStore[index].NUM_FECHAINI, 'dd/MM/yyyy');
        }
      }
      if (this.columidactual === 'NUM_SECINICIAL') {
        if (immutableStore[index].NUM_SECINICIAL.length < 9) {
          const largo = 9 - immutableStore[index].NUM_SECINICIAL.length;
          let str = new Array(largo + 1).join('0');
          immutableStore[index].NUM_SECINICIAL = str.concat(immutableStore[index].NUM_SECINICIAL);
        }
      }
      if (this.columidactual === 'NUM_SECFINAL') {
        if (immutableStore[index].NUM_SECFINAL.length < 9) {
          const largo = 9 - immutableStore[index].NUM_SECFINAL.length;
          let str = new Array(largo + 1).join('0');
          immutableStore[index].NUM_SECFINAL = str.concat(immutableStore[index].NUM_SECFINAL);
        }
      }
      if (this.columidactual === 'NUM_SECACTUAL') {
        if (immutableStore[index].NUM_SECACTUAL.length < 9) {
          const largo = 9 - immutableStore[index].NUM_SECACTUAL.length;
          let str = new Array(largo + 1).join('0');
          immutableStore[index].NUM_SECACTUAL = str.concat(immutableStore[index].NUM_SECACTUAL);
        }
      }
      this.aggrid.refreshaggridindex(this.numCService.detNumeracionC, 'maeNumeracionC', index - 1);
    }

    if (this.gridApi.aggFuncService === 'maetomafisicavar') {
      if (this.columidactual === 'DEBE') {
        if (immutableStore[index].DEBE === '') {
          immutableStore[index].DEBE = Number(0).toFixed(2);
        } else {
          immutableStore[index].DEBE = Number(immutableStore[index].DEBE).toFixed(2);
        }
      }
      if (this.columidactual === 'HABER') {
        if (immutableStore[index].HABER === '') {
          immutableStore[index].HABER = Number(0).toFixed(2);
        } else {
          immutableStore[index].HABER = Number(immutableStore[index].HABER).toFixed(2);
        }
      }
      this.aggrid.refreshaggridindex(this.tomFisService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice], 'maetomafisicavar', index - 1);
    }

    if (this.gridApi.aggFuncService === 'bandejapagos') {

      if (this.columidactual === 'DETBANDEJA_IMPORTE' || this.columidactual === 'DETBANDEJA_DESCUENTO') {
        this.aggrid.refreshaggridindexSNMVSCROLL(this.banBandejaPagosService.detBandejaPagos, 'bandejapagos', index - 1);
        this.banBandejaPagosService.calcularImporteTotal();
        
      }

    }

    //FINAL JAV

    if (this.gridApi.aggFuncService === 'maeTablitaRet') {
      console.log('entra');
      if (this.columidactual === 'MAETABLITARET_FRACBASICA') {
        if (immutableStore[index].MAETABLITARET_FRACBASICA === '') {
          immutableStore[index].MAETABLITARET_FRACBASICA = Number(0).toFixed(2);
        } else {
          immutableStore[index].MAETABLITARET_FRACBASICA = Number(immutableStore[index].MAETABLITARET_FRACBASICA).toFixed(2);
        }
      }
      if (this.columidactual === 'MAETABLITARET_MAXFRACBSC') {
        if (immutableStore[index].MAETABLITARET_MAXFRACBSC === '') {
          immutableStore[index].MAETABLITARET_MAXFRACBSC = Number(0).toFixed(2);
        } else {
          immutableStore[index].MAETABLITARET_MAXFRACBSC = Number(immutableStore[index].MAETABLITARET_MAXFRACBSC).toFixed(2);
        }
      }
      if (this.columidactual === 'MAETABLITARET_IMPFRCBSC') {
        if (immutableStore[index].MAETABLITARET_IMPFRCBSC === '') {
          immutableStore[index].MAETABLITARET_IMPFRCBSC = Number(0).toFixed(2);
        } else {
          immutableStore[index].MAETABLITARET_IMPFRCBSC = Number(immutableStore[index].MAETABLITARET_IMPFRCBSC).toFixed(2);
        }
      }
      if (this.columidactual === 'MAETABLITARET_EXCEDENTE') {
        if (immutableStore[index].MAETABLITARET_EXCEDENTE === '') {
          immutableStore[index].MAETABLITARET_EXCEDENTE = Number(0).toFixed(2);
        } else {
          immutableStore[index].MAETABLITARET_EXCEDENTE = Number(immutableStore[index].MAETABLITARET_EXCEDENTE).toFixed(2);
        }
      }
      this.aggrid.refreshaggridindex(this.nommaetablitaretService.nommaeTablitaRet, 'maeTablitaRet', index - 1);
      //this.aggrid.refreshaggridindex(this.nommaetablitaretService.nommaeTablitaRet[this.init.tabs[this.init.tabindex].indice], 'maeTablitaRet', index - 1);
    }

    if (this.gridApi.aggFuncService === 'nomMaeRubros') {
      if (this.columidactual === 'MAERUBRO_FECINI') {
        if (immutableStore[index].MAERUBRO_FECINI.toString().includes('-')) {
          immutableStore[index].MAERUBRO_FECINI = this.datePipe.transform(immutableStore[index].MAERUBRO_FECINI, 'dd/MM/yyyy');
        }
      }
      if (this.columidactual === 'MAERUBRO_FECFIN') {
        if (immutableStore[index].MAERUBRO_FECFIN.toString().includes('-')) {
          immutableStore[index].MAERUBRO_FECFIN = this.datePipe.transform(immutableStore[index].MAERUBRO_FECFIN, 'dd/MM/yyyy');
        }
      }
      this.aggrid.refreshaggridindex(this.nommaerubroService.nommaeRubro, 'nomMaeRubros', index - 1);
    }

    if (this.gridApi.aggFuncService === 'invtrnreservas') {
      if (this.columidactual === 'TRNRES_CANTIDAD') {
        if (immutableStore[index].TRNRES_CANTIDAD === '') {
          immutableStore[index].TRNRES_CANTIDAD = Number(0).toFixed(2);
        } else {
          if (Number(immutableStore[index].TRNRES_CANTIDAD) > Number(immutableStore[index].ART_EXISTENCIA)) {
            this.invreservasService.mensajeAlerta('error', 'Error', 'La cantidad que ha ingresado es mayor a la cantidad de existencia del articulo');
            immutableStore[index].TRNRES_CANTIDAD = Number(0).toFixed(2);
          } else {
            immutableStore[index].TRNRES_CANTIDAD = Number(immutableStore[index].TRNRES_CANTIDAD).toFixed(2);
          }

        }
        this.aggrid.refreshaggridindex(this.invreservasService.detalleReservas[this.init.tabs[this.init.tabindex].indice], 'invtrnreservas', index);
      }
    }

    if (this.gridApi.aggFuncService === 'cxpfpant') {
      if (this.columidactual === 'FECHADOC') {
        if (immutableStore[index].FECHADOC === '') {
          immutableStore[index].FECHADOC = new Date();
          if (immutableStore[index].FECHADOC.toString().includes('-')) {
            immutableStore[index].FECHADOC = this.datePipe.transform(immutableStore[index].FECHADOC, 'dd/MM/yyyy');
          }
        } else if (immutableStore[index].FECHADOC.toString().includes('-')) {
          immutableStore[index].FECHADOC = this.datePipe.transform(immutableStore[index].FECHADOC, 'dd/MM/yyyy');
        }
        this.aggrid.refreshaggridindex(this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice], 'cxpfpant', index);
      }
    }

    if (this.gridApi.aggFuncService === 'detHorasExtra') {
      console.log('Ingresa detHorasExtra');
      if (this.columidactual === 'NUM_NUMHORAEXTR50') {
        immutableStore[index].NUM_NUMHORAEXTR50 = Number(immutableStore[index].NUM_NUMHORAEXTR50).toFixed(2);
      }
      if (this.columidactual === 'NUM_NUMHORAEXTR25') {
        immutableStore[index].NUM_NUMHORAEXTR25 = Number(immutableStore[index].NUM_NUMHORAEXTR25).toFixed(2);
      }
      if (this.columidactual === 'NUM_NUMHORAEXTR100') {
        immutableStore[index].NUM_NUMHORAEXTR100 = Number(immutableStore[index].NUM_NUMHORAEXTR100).toFixed(2);
      }
      this.aggrid.refreshaggridindex(this.cabHoraExtrSrv.detHorasExtra, 'detHorasExtra', index - 1);
    }

    if (this.gridApi.aggFuncService === 'servicioped') {
      if (this.columidactual === 'TRNSERTRAN_PRECIO') {
        immutableStore[index].TRNSERTRAN_PRECIO = Number(immutableStore[index].TRNSERTRAN_PRECIO).toFixed(this.cliService.decimalesPrecio);
      }
      if (this.columidactual === 'TRNSERTRAN_DESCUENTO') {
        immutableStore[index].TRNSERTRAN_DESCUENTO = Number(immutableStore[index].TRNSERTRAN_DESCUENTO).toFixed(this.cliService.decimalesPrecio);
      }
    }

    if (this.gridApi.aggFuncService === 'maeartformula') {
      if (this.columidactual === 'FOR_CANTIDAD') {
        immutableStore[index].FOR_CANTIDAD = Number(immutableStore[index].FOR_CANTIDAD).toFixed(this.artService.obtenerConfig('CANTIDAD', 'formula'));
      }
      if (this.columidactual === 'FOR_CANTTOTAL') {
        immutableStore[index].FOR_CANTTOTAL = Number(immutableStore[index].FOR_CANTTOTAL).toFixed(this.artService.obtenerConfig('CANTIDAD', 'formula'));
      }
      this.aggrid.refreshaggridindex(this.artService.maeartformula, 'maeartformula', index);

    }

    if (this.gridApi.aggFuncService === 'maecostoest') {
      if (this.columidactual === 'CEST_COSTO') {
        immutableStore[index].CEST_COSTO = Number(immutableStore[index].CEST_COSTO).toFixed(this.artService.obtenerConfig('COSTO', 'MaeCostosEst'));
      }
      this.aggrid.refreshaggridindex(this.artService.maecostoestand, 'maecostoest', index);

    }

    if (this.gridApi.aggFuncService === 'invmaecomart') {
      if (this.columidactual === 'COMART_DESCRIPCION') {
        if (immutableStore[index].COMART_DESCRIPCION !== '') {
          await this.artService.guardarDescripcion(immutableStore[index]);
        }
      }

      this.aggrid.refreshaggridindex(this.artService.maecomart, 'invmaecomart', index);
    }

    if (this.gridApi.aggFuncService === 'nomutilidades') {
      if (this.columidactual === 'NOM_NUMCARFAM') {
        immutableStore[index].NOM_NUMCARFAM = Number(immutableStore[index].NOM_NUMCARFAM).toFixed(2);
      }
      if (this.columidactual === 'NOM_DIASLABORADOS') {
        immutableStore[index].NOM_DIASLABORADOS = Number(immutableStore[index].NOM_DIASLABORADOS).toFixed(2);
      }
      if (this.columidactual === 'NOM_PARTICIPACION_UTI') {
        immutableStore[index].NOM_PARTICIPACION_UTI = Number(immutableStore[index].NOM_PARTICIPACION_UTI).toFixed(2);
      }
      if (this.columidactual === 'NOM_SALDIG') {
        immutableStore[index].NOM_SALDIG = Number(immutableStore[index].NOM_SALDIG).toFixed(2);
      }
      if (this.columidactual === 'NOM_UTILIDAD') {
        immutableStore[index].NOM_UTILIDAD = Number(immutableStore[index].NOM_UTILIDAD).toFixed(2);
        immutableStore[index].NOM_NETOAPAGAR = Number(this.nomUtilidadesService.totalLineaPagarUtilidad(immutableStore[index])).toFixed(2);
      }
      if (this.columidactual === 'NOM_DESCPROV') {
        immutableStore[index].NOM_DESCPROV = Number(immutableStore[index].NOM_DESCPROV).toFixed(2);
        immutableStore[index].NOM_NETOAPAGAR = Number(this.nomUtilidadesService.totalLineaPagarUtilidad(immutableStore[index])).toFixed(2);
      }
      if (this.columidactual === 'NOM_RETENCION_JUD') {
        immutableStore[index].NOM_RETENCION_JUD = Number(immutableStore[index].NOM_RETENCION_JUD).toFixed(2);
        immutableStore[index].NOM_NETOAPAGAR = Number(this.nomUtilidadesService.totalLineaPagarUtilidad(immutableStore[index])).toFixed(2);
      }
      if (this.columidactual === 'NOM_IMPUESTO_RET') {
        immutableStore[index].NOM_IMPUESTO_RET = Number(immutableStore[index].NOM_IMPUESTO_RET).toFixed(2);
        immutableStore[index].NOM_NETOAPAGAR = Number(this.nomUtilidadesService.totalLineaPagarUtilidad(immutableStore[index])).toFixed(2);
      }

      this.aggrid.refreshaggridindex(this.nomUtilidadesService.detalleUtilidades, 'nomutilidades', index);
    }

    if (this.gridApi.aggFuncService === 'tablalote') {
      if (this.columidactual === 'TRNLOTE_CANTIDAD') {
        const VALLOTART = this.trnloteservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VALLOTART').CFG_VALOR1;
        const LOTEPRO = this.trnloteservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LOTEPRO').CFG_VALOR1;
        let cantPermitida = 0;

        if (this.trnloteservice.strOrigen[this.init.tabs[this.init.tabindex].indice] === "ORDPROD") {
          let dblsaldo=0;
        if (LOTEPRO === 1) {
          dblsaldo = await this.verificarSaldoLoteML(immutableStore[index].LOTE_NUMERO, immutableStore[index].ART_CODIGO)
        } else {
          //const data1 = await this.verificarSaldo(lote.LOTE_NUMERO, this.auxcodigo)
          dblsaldo = await this.verificarSaldo(immutableStore[index].LOTE_NUMERO, immutableStore[index].ART_CODIGO)
        }
        if (Number(immutableStore[index].TRNLOTE_CANTIDAD) > dblsaldo) {
          this.messageService.add({
            key: 'trnlote',
            severity: 'error',
            summary: 'Error',
            detail: 'No existe esa cantidad'
          });
          immutableStore[index].TRNLOTE_CANTIDAD = dblsaldo
          this.aggrid.refreshaggrid(immutableStore, 'tablalote');
          return;
        }

      } else if (VALLOTART === 1
          && (this.trnloteservice.strOrigen[this.init.tabs[this.init.tabindex].indice] === "DEVCOM"
            || this.trnloteservice.strOrigen[this.init.tabs[this.init.tabindex].indice] === "SA"
            || this.trnloteservice.strOrigen[this.init.tabs[this.init.tabindex].indice] === "FACVEN"
            || this.trnloteservice.strOrigen[this.init.tabs[this.init.tabindex].indice] === "GUIREM"
            )) {
          console.log(immutableStore[index]);
          const data = await this.trnloteservice.existencialote(immutableStore[index].LOTE_NUMERO,this.trnloteservice.auxcod);
          console.log(data);
          for (const rs of data) {
            cantPermitida = Number(rs.EXISTENCIA);
          }
          if (Number(immutableStore[index].TRNLOTE_CANTIDAD) > cantPermitida) {
            this.messageService.add({
              key: 'trnlote',
              severity: 'error',
              summary: 'Error',
              detail: 'No existe esa cantidad'
            });
            immutableStore[index].TRNLOTE_CANTIDAD = cantPermitida;
          }
          this.aggrid.refreshaggrid(immutableStore, 'tablalote');
        }
      }
    }

    if (this.gridApi.aggFuncService === 'detordpro') {
      this.encordproService.SumarTotal();
      this.aggrid.refreshaggrid(immutableStore, 'detordpro');
    }

    if (this.gridApi.aggFuncService === 'actmaegrupo') {
      if (this.columidactual === 'ACTGR_CODIGO') {
        if (immutableStore[index].IS_NEW_ROW === false) {
          immutableStore[index].ACTGR_CODIGO = immutableStore[index].COD_ANTERIOR;
          this.actmaegrupoService.activateButtons('NORMAL_STATE');
        }
      }
      this.aggrid.refreshaggridindex(this.actmaegrupoService.detmaeGrupoArray[this.init.tabs[this.init.tabindex].indice], 'actmaegrupo', index - 1);
    }

    if (this.gridApi.aggFuncService === 'actmaepoliza') {
      if (this.columidactual === 'POL_CODIGO') {
        if (immutableStore[index].IS_NEW_ROW === false) {
          immutableStore[index].POL_CODIGO = immutableStore[index].COD_ANTERIOR;
        }
      }
      this.aggrid.refreshaggridindex(this.actmaepolizaService.detmaePolizaArray[this.init.tabs[this.init.tabindex].indice], 'actmaepoliza', index - 1);
    }
    if (this.gridApi.aggFuncService === 'actdetsalida') {
      if (this.columidactual === 'DETSALIDA_IMPORTE') {
        immutableStore[index].DETSALIDA_IMPORTE = Number(immutableStore[index].DETSALIDA_IMPORTE).toFixed(2);
        this.aggrid.refreshaggridindex(this.actencsalidaService.detSalidaArray[this.init.tabs[this.init.tabindex].indice], 'actdetsalida', index - 1);
      }

    }

    if (this.gridApi.aggFuncService === 'actdetvarsal') {
      if (this.columidactual === 'DETVARIOSSAL_IMPORTE') {
        immutableStore[index].DETVARIOSSAL_IMPORTE = Number(immutableStore[index].DETVARIOSSAL_IMPORTE).toFixed(2);
        this.aggrid.refreshaggridindex(this.actencsalidaService.detVariosSalidaArray[this.init.tabs[this.init.tabindex].indice], 'actdetvarsal', index - 1);
      }
    }


    if (this.gridApi.aggFuncService === 'detalleordentrabajo') {
      this.encordtraService.calcularTotales();
      this.aggrid.refreshaggridindex(this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice], 'detalleordentrabajo', index - 1);
    }

    if (this.gridApi.aggFuncService === 'trnhorashombre') {
      await this.encordproService.SumarTotal();
      this.aggrid.refreshaggrid(immutableStore, 'trnhorashombre');
      if (this.columidactual === 'TRNHH_FECHA') {
        this.gridApi.setFocusedCell(index, 'TRNHH_HORAINI');
      }
      if (this.columidactual === 'TRNHH_HORAINI') {
        this.gridApi.setFocusedCell(index, 'TRNHH_HORAFIN');
      }
      if (this.columidactual === 'TRNHH_HORAFIN') {
        this.gridApi.setFocusedCell(index, 'CIN_CODIGO');
      }
    }

    if (this.gridApi.aggFuncService === 'detfacpv') {
       //CDPJ
       if(this.columidactual === 'DETFAC_TRIBIVA'){
        if(immutableStore[index].DETFAC_TRIBIVA === 'N' || immutableStore[index].DETFAC_TRIBIVA === 'S'){
          immutableStore[index].DETFAC_PORIVA='';
          immutableStore[index].TARIIVA_CODIGO='';
        }
      }
      //CDPJ
      this.encptovtaService.calcularTotalesFact();
      this.aggrid.refreshaggrid(immutableStore, 'detfacpv');
    }

    if (this.gridApi.aggFuncService === 'detalleRol') {
      console.log(immutableStore[index]);
      // immutableStore[index].CALCULAR = 'calcular';
    }
  }


  llenarceldas() {
    const index = this.gridApi.getFocusedCell().rowIndex;
    const immutableStore = [];
    this.gridApi.stopEditing();
    this.gridApi.forEachNode((rowNode, rowindex) => {
      immutableStore.push(rowNode.data);
    });
    if (this.gridApi.aggFuncService === 'detfac') {
      if (this.columidactual === 'DETFAC_TRIBIVA') {
        if (immutableStore[index].DETFAC_TRIBIVA === '') {
          immutableStore[index].DETFAC_TRIBIVA = immutableStore[index].DETFAC_TRIBIVAAUX;
        }
        this.aggrid.refreshaggrid(this.encfacservice.detfacarray[this.init.tabs[this.init.tabindex].indice], 'detfac');
      }
    }
  }
    //CDPJ
    async verificarSaldoLoteML(lote, artcod) {
    const index = this.gridApi.getFocusedCell().rowIndex;
    const immutableStore = [];
    this.gridApi.stopEditing();
    this.gridApi.forEachNode((rowNode, rowindex) => {
      immutableStore.push(rowNode.data);
    });
      let dblsaldo = 0
      let dblCantidadReg = 0;
      //cantidad grabada actualmente en el documento 
      const data = await this.trnloteservice.cantidadgrabada(immutableStore[index].TRNLOTE_DOCUMENTO , immutableStore[index].TRNLOTE_LINEADOC);
      // saldo
      const data2 = await this.trnloteservice.cantidadsaldo(lote, artcod);
      if (data !== null && data !== undefined && data.length > 0) {
        for (const item of data) {
          dblCantidadReg = Number(item.TRNLOTE_CANTIDAD)
        }
      }
      if (data2 !== null && data2 !== undefined && data2.length > 0) {
        for (const item of data2) {
          dblsaldo = Number(item.SALDO)
        }
      }
  
      dblsaldo = dblsaldo + dblCantidadReg
      return dblsaldo;
  
    }
    async verificarSaldo(lote, artcod) {
    const index = this.gridApi.getFocusedCell().rowIndex;
    const immutableStore = [];
    this.gridApi.stopEditing();
    this.gridApi.forEachNode((rowNode, rowindex) => {
      immutableStore.push(rowNode.data);
    });
      let dblsaldo = 0
      let dblCantidadReg = 0;
      //cantidad grabada actualmente en el documento 
      const data = await this.trnloteservice.cantidadgrabada(immutableStore[index].TRNLOTE_DOCUMENTO , immutableStore[index].TRNLOTE_LINEADOC);
      // saldo
      const data2 = await this.trnloteservice.cantidadsaldo2(lote, artcod);
      if (data !== null && data !== undefined && data.length > 0) {
        for (const item of data) {
          dblCantidadReg = Number(item.TRNLOTE_CANTIDAD)
        }
      }
      if (data2 !== null && data2 !== undefined && data2.length > 0) {
        for (const item of data2) {
          dblsaldo = Number(item.SALDO)
        }
      }
  
      dblsaldo = dblsaldo + dblCantidadReg
      return dblsaldo;
  
    }
    //CDPJ
}
