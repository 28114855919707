import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { InvMaearticulo } from '../invinterfaces/invmaearticulo';
import { InvMaeartprecio } from '../invinterfaces/invmaeartprecio';
import { ProdMaeartparte } from '../invinterfaces/prodmaeartparte';
import { ProdMaeartformula } from '../invinterfaces/prodmaeartformula';
import { ProdMaecostoestand } from '../invinterfaces/prodmaecostoestand';
import { InvExistenciabod } from '../invinterfaces/invexistenciabod';
import { MessageService } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { Invprodtrncomqui } from '../invinterfaces/invprodtrncomqui';
import { ProdTrnAplicacion } from '../invinterfaces/prodtrnaplicacion';
import { Invmaecomart } from '../invinterfaces/invmaecomart';
//CDPJ
import { Invmaeprofab } from "../invinterfaces/invmaeprofab";
import { Invmaeprocont } from "../invinterfaces/invmaeprocont";
//CDPJ
@Injectable({
  providedIn: 'root'
})
export class InvmaearticuloService {

  public maeartprecio: InvMaeartprecio[];
  public maeartparte: ProdMaeartparte[];
  public maeartformula: ProdMaeartformula[];
  public maeexistbod: InvExistenciabod[];
  public maecostoestand: ProdMaecostoestand[];
  public prodtrncomqui: Invprodtrncomqui[];
  public prodtrnaplicacion: ProdTrnAplicacion[];
  //CDPJ
  public profabricacion: Invmaeprofab[];
  public procontrol:Invmaeprocont[];
  //CDPJ
  public maecomart: Invmaecomart[];
  public btnNuevoDesc = false;
  public btnEliminarDesc = false;
  public btnCancelarDesc = true;

  public configDecimales: any[];

  public intUtilizarUtilCost: boolean;
  private way: string;

  formulas: any[];
  formula: string;
  selectformula: any;
  spin = false;//GSRF

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService) {

    this.way = sessionStorage.getItem('way');
  }

  getArticulo(artcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/41dd56fb267ab8', {
      elementos: {
        C: 'C',
        ART_CODIGO: artcodigo
      }
    }, this.confIniciales.httpOptions());
  }

  getPrecio(artcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/107773b73258', {
      elementos: {
        ART_CODIGO: artcodigo
      }
    }, this.confIniciales.httpOptions());
  }

  getComponente(artcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/1077598b671776000', {
      elementos: {
        ART_CODIGO: artcodigo
      }
    }, this.confIniciales.httpOptions());
  }

  llenarComboFormula(artcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/15abaeadb2cb7e000', {
      elementos: {
        ART_CODIGO: artcodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }


  getFormula(artcodigo, formula): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/20eebf1bb7aaa', {
      elementos: {
        ART_CODIGO: artcodigo,
        COM_CODIGO: '01',
        FORMULA: formula
      }
    }, this.confIniciales.httpOptions());
  }

  getEspecificacionesTecnicas(artcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/10775dccf5ccbd000', {
      elementos: {
        ART_CODIGO: artcodigo
      }
    }, this.confIniciales.httpOptions());
  }

  getCostosEstandares(artcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/41dd6639dc3b9c', {
      elementos: {
        ART_CODIGO: artcodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  getExistenciasBodega(artcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/20eebb8c973abc0000', {
      elementos: {
        ART_CODIGO: artcodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }


  obtenerExistenciaBodega(strTipo, strArtCodigo, strBodCodigo, strFecha, strHora): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/30bebb8c973abc0000', {
      elementos: {
        tipo: strTipo,
        art_codigo: strArtCodigo,
        bod_codigo: strBodCodigo,
        fecha: strFecha,
        hora: strHora
      }
    }, this.confIniciales.httpOptions());
  }


  getKardex(artcodigo, strFecha, strHora) {
    console.log('INV_PCK_TRNKARDEX_C.INV_SP_CONSULTA_KARDEX_DET(\'' + artcodigo + '\',\'01\',\'' + strFecha + '\',\'' + strHora + '\'');
    return this.http.post(this.way + '/inventario/articulo/83bb456dae', {
      elementos: {
        p_art_codigo: artcodigo,
        p_com_codigo: '01',
        p_fecha: strFecha,
        p_hora: strHora
      },
    }, this.confIniciales.httpOptions());
  }


  nombresCuentasContables(articulo) {
    return this.http.post(this.way + '/inventario/articulo/17c597b733aa66000', {
      elementos: {
        p_ART_codigo: articulo,
        p_COM_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarArtPreCodigo(strCondicion) {
    return this.http.post(this.way + '/inventario/articulo/f95daadf73b73000', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
  encontrartariiva(strParametro) {
    return this.http.post(this.way + '/inventario/articulo/20eef55b94bea', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  encontrartariivacod(strParametro) {
    return this.http.post(this.way + '/inventario/articulo/83bbd56e52fab000000000000', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  //CDPJ
  EncontrarCnta(concodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/ebb317eed5b66000', {
      elementos: {
        con_codigo: concodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erUniCodigo1Prom(strDetfacUnidad) {
    return this.http.post(this.way + '/compras/factproveedores/3b7ebc9986ca00', {
      elementos: {
        DETFACPRO_UNIDAD: strDetfacUnidad
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getunidadventasart(artcodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/41ddf5e5f75fac00000', {
      elementos: {
        articulo: artcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerMultiunidad(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/20eedbd5ecbd7a0000', {
      elementos: {
        articulo: strArtCodigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  guardarProfabri(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/95f8edf55bcef0000000', {
      elementos: {
        p_art_codigo: parametro[0],
        p_profab_etapa: parametro[1],
        p_com_codigo: parametro[2],
        p_profab_descripcion: parametro[3],
        p_art_codigo_mp: parametro[4],
        p_profab_cantidad: parametro[5],
        p_profab_unidad: parametro[6],
        p_profab_hora_ini: parametro[7],
        p_profab_hora_fin: parametro[8],
        p_profab_linea: parametro[9],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarProfabri(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/299dcef0f52f72', {
      elementos: {
        p_art_codigo: parametro[0],
        p_profab_linea: parametro[1],
        p_profab_etapa: parametro[2],
        p_com_codigo: parametro[3],
        p_profab_descripcion: parametro[4],
        p_art_codigo_mp: parametro[5],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarProfabri(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/7565695d5bcef0000000', {
      elementos: {
        p_art_codigo: parametro[0],
        p_profab_linea: parametro[1],
        p_com_codigo: parametro[2],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  listarProfabri(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/15973aadef0f53000', {
      elementos: {
        ART_CODIGO: strArtCodigo
      }
    }, this.confIniciales.httpOptions());
  }
  
  guardarProcontrol(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/10f2b6d56f3bc300000000', {
      elementos: {
        p_art_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_procont_parametro: parametro[2],
        p_procont_esp_min: parametro[3],
        p_procont_esp_max: parametro[4],
        p_procont_metodo: parametro[5],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarProcontrol(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/a6773bc3317ef000', {
      elementos: {
        p_art_codigo: parametro[0],
        p_profab_linea: parametro[1],
        p_profab_etapa: parametro[2],
        p_com_codigo: parametro[3],
        p_profab_descripcion: parametro[4],
        p_art_codigo_mp: parametro[5],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarProcontrol(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/1d595a5756f3bc000000000', {
      elementos: {
        p_art_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_procont_parametro: parametro[2],
       
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  listarProcontrol(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/107773bc3317ef000', {
      elementos: {
        ART_CODIGO: strArtCodigo
      }
    }, this.confIniciales.httpOptions());
  }
  listarProfabriformulacion(strArtCodigo,form) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/acb9d56daeee78000000000000000000000', {
      elementos: {
        ART_CODIGO: strArtCodigo,
        formula:form
      }
    }, this.confIniciales.httpOptions());
  }









  //CDPJ
  encontrarArtCodigoPMaeParte(strArtCodigo, strArtCodigop) {
    return this.http.post(this.way + '/inventario/articulo/fdab7d661b9ca800', {
      elementos: {
        art_codigo: strArtCodigo,
        art_codigop: strArtCodigop
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarArtCodigoPMaeFor(strArtCodigo, strArtCodigop, forcodigo) {
    return this.http.post(this.way + '/inventario/articulo/fdab7d661b97e000', {
      elementos: {
        art_codigo: strArtCodigo,
        art_codigop: strArtCodigop,
        for_codigo: forcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarCinCodMaeCostEst(strArtCodigo, strCinCodigo) {
    return this.http.post(this.way + '/inventario/articulo/7ed92bb30d63b80', {
      elementos: {
        art_codigo: strArtCodigo,
        cin_codigo: strCinCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarBodCodigoMaeExist(strArtCodigo, strBodCodigo) {
    return this.http.post(this.way + '/inventario/articulo/fdaf0d661aee3000', {
      elementos: {
        art_codigo: strArtCodigo,
        bod_codigo: strBodCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarGruNombre(strCondicion) {
    return this.http.post(this.way + '/inventario/articulo/3b70dfaf8b2f6e', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }
  encontrarImpDescripcion(strParametro) {
    return this.http.post(this.way + '/inventario/articulo/76e56cb5dc66e40', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarCodSriDescrip(strParametro) {
    return this.http.post(this.way + '/inventario/articulo/76d986f3726bb80', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarUniNombre(strParametro) {
    return this.http.post(this.way + '/inventario/articulo/3b7ebcaf8b2f6e', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarConNombre(strParametro) {
    return this.http.post(this.way + '/inventario/articulo/3b6cc5ef8b2f6e', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarRefNombre(strRefTipo, strParametro) {
    return this.http.post(this.way + '/inventario/articulo/3b7b73ef8b2f6e', {
      elementos: {
        ref_tipo: strRefTipo,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarArtCodigomaeInv(strArtCodigo) {
    return this.http.post(this.way + '/inventario/articulo/3b6adf5986cb00', {
      elementos: {
        art_codigo: strArtCodigo
      },
    }, this.confIniciales.httpOptions());
  }


  encontrarRefNombreMaeRef(strRefCodigo, strRefTipo) {
    return this.http.post(this.way + '/inventario/articulo/3b7b73ef8b6dd0', {
      elementos: {
        ref_codigo: strRefCodigo,
        ref_tipo: strRefTipo,
      },
    }, this.confIniciales.httpOptions());
  }


  encontrarRegistroPromise(strArtCodigo) {
    return this.http.post(this.way + '/inventario/articulo/3b6adf5986cb00', {
      elementos: {
        art_codigo: strArtCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarArticulo(articulo: InvMaearticulo): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/10f2b6d56fb268000', {
      elementos: {
        p_ART_codigo_1: articulo.ART_CODIGO.toUpperCase(),
        p_COM_codigo_2: articulo.COM_CODIGO,
        p_ART_nombre_3: articulo.ART_NOMBRE,
        p_ART_nombrec_4: articulo.ART_NOMBREC,
        p_GRUP_codigo_5: articulo.GRUP_CODIGO,
        p_GRUP_codigop: articulo.GRUP_CODIGOP,
        p_ART_tributaiva_6: articulo.ART_TRIBUTAIVA,
        p_ART_tributaice: articulo.ART_TRIBUTAICE,
        p_ART_fechaing_7: articulo.ART_FECHAING,
        p_ART_laboratorio_8: articulo.ART_LABORATORIO,
        p_ART_multiunidad: articulo.ART_MULTIUNIDAD,
        p_ART_ubicacion_9: articulo.ART_UBICACION,
        p_ART_unidadventa: articulo.ART_UNIDADVENTA,
        p_ART_unidadcosteo: articulo.ART_UNIDADCOSTEO,
        p_ART_caduca_11: articulo.ART_CADUCA,
        p_ART_codigoalt1_12: articulo.ART_CODIGOALT1,
        p_ART_codigoalt2_13: articulo.ART_CODIGOALT2,
        p_ART_codigoalt3_14: articulo.ART_CODIGOALT3,
        p_ART_codigoalt4_15: articulo.ART_CODIGOALT4,
        p_ART_largo_16: articulo.ART_LARGO,
        p_ART_altura_17: articulo.ART_ALTURA,
        p_ART_ancho_18: articulo.ART_ANCHO,
        p_ART_peso_19: articulo.ART_PESO,
        p_ART_compuesto_20: articulo.ART_COMPUESTO,
        p_CON_codigoAct_23: articulo.CON_CODIGOACT,
        p_CON_codigoGas_24: articulo.CON_CODIGOGAS,
        p_CON_codigoIng_25: articulo.CON_CODIGOING,
        p_CON_codigoOrd1_26: articulo.CON_CODIGOORD1,
        p_CON_codigoOrd2_27: articulo.CON_CODIGOORD2,
        p_ART_observacion_28: articulo.ART_OBSERVACION,
        p_ART_codigoice_29: articulo.ART_CODIGOICE,
        p_ART_marca_30: articulo.ART_MARCA,
        p_ART_modelo_31: articulo.ART_MODELO,
        p_ART_serie_32: articulo.ART_SERIE,
        p_ART_volumen_33: articulo.ART_VOLUMEN,
        p_presentacion_codigo_34: articulo.PRESENTACION_CODIGO,
        p_ART_factor_35: articulo.ART_FACTOR,
        p_ART_formavta: articulo.ART_FORMAVTA,
        p_ART_descuento: articulo.ART_DESCUENTO,
        p_ART_SERIALFLAG: articulo.ART_SERIALFLAG,
        p_ART_diasgarantia: articulo.ART_DIASGARANTIA,
        p_ART_valorice: articulo.ART_VALORICE,
        p_ART_activo: articulo.ART_ACTIVO,
        p_ART_costohistorico: articulo.ART_COSTOHISTORICO,
        p_CEN_codigo: articulo.CEN_CODIGO,
        p_CON_codigodscto: articulo.CON_CODIGODSCTO,
        p_CON_codigoIng2: articulo.CON_CODIGOING2,
        p_CON_codigodscto2: articulo.CON_CODIGODSCTO2,
        p_ART_activarserial: articulo.ART_ACTIVARSERIAL,
        p_ART_activardim: articulo.ART_ACTIVARDIM,
        p_ART_activarnumerotel: articulo.ART_ACTIVARNUMEROTEL,
        p_ART_formsrivtas: articulo.ART_FORMSRIVTAS,
        p_ART_formsricom: articulo.ART_FORMSRICOM,
        p_ART_pordises1: articulo.ART_PORDISES1,
        p_ART_pordises2: articulo.ART_PORDISES2,
        p_ART_campania: articulo.ART_CAMPANIA,
        p_ART_camtipo: articulo.ART_CAMTIPO,
        p_art_cantmax: articulo.ART_CANTMAX,
        p_art_cantmin: articulo.ART_CANTMIN,
        p_art_basenoobjiva: articulo.ART_BASENOOBJIVA,
        p_art_subsidio: articulo.ART_SUBSIDIO,
        p_con_codigoprodproc: articulo.CON_CODIGOPRODPROC,
        p_art_productoprod: articulo.ART_PRODUCTOPROD,
        p_art_tipo: articulo.ART_TIPO,
        p_art_uniporcaja: articulo.ART_UNIPORCAJA,
        p_art_codsriice: articulo.ART_CODSRIICE,
        p_art_densidad: articulo.ART_DENSIDAD,
        p_art_cantidad_und: '',
        p_art_tipocategoria: articulo.ART_TIPOCATEGORIA,
        p_art_comentario: articulo.ART_COMENTARIO,
        p_art_irbpnr: articulo.ART_IRBPNR,
        p_art_poriva: articulo.ART_PORIVA,
        p_tariiva_codigo: articulo.TARIIVA_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  guardarEspecTec(articulo): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/10f2b6d7732e67000', {
      elementos: {
        p_art_codigo: articulo.ART_CODIGO,
        p_com_codigo: articulo.COM_CODIGO,
        p_esptec_codfab: articulo.ESPTEC_CODFAB,
        p_esptec_costfab: articulo.ESPTEC_COSTFAB,
        p_esptec_nombreimp: articulo.ESPTEC_NOMBREIMP,
        p_esptec_partida: articulo.ESPTEC_PARTIDA,
        p_esptec_arancel: articulo.ESPTEC_ARANCEL,
        p_esptec_ice: articulo.ESPTEC_ICE,
        p_esptec_vol: articulo.ESPTEC_VOL,
        p_esptec_mbox: articulo.ESPTEC_MBOX,
        p_esptec_foto: articulo.ESPTEC_FOTO,
        p_esptec_dattec: articulo.ESPTEC_DATTEC,
        p_esptec_advertencia: articulo.ESPTEC_ADVERTENCIA,
        p_esptec_zonadecontrol: articulo.ESPTEC_ZONADECONTROL,
        p_esptec_posicion: articulo.ESPTEC_POSICION,
        p_esptec_pais: articulo.ESPTEC_PAIS,
        p_esptec_flujoluminico: articulo.ESPTEC_FLUJOLUMINICO,
        p_esptec_potencia: articulo.ESPTEC_POTENCIA,
        p_esptec_voltajeinput: articulo.ESPTEC_VOLTAJEINPUT,
        p_esptec_voltajeoutput: articulo.ESPTEC_VOLTAJEOUTPUT,
        p_esptec_corriente: articulo.ESPTEC_CORRIENTE,
        p_esptec_cri: articulo.ESPTEC_CRI,
        p_esptec_temperatura: articulo.ESPTEC_TEMPERATURA,
        p_esptec_ip: articulo.ESPTEC_IP,
        p_esptec_potenciatotal: articulo.ESPTEC_POTENCIATOTAL,
        p_esptec_anguloapertura: articulo.ESPTEC_ANGULOAPERTURA,
        p_esptec_flete: articulo.ESPTEC_FLETE,
        p_esptec_seguro: articulo.ESPTEC_SEGURO,
        p_esptec_salvaguarda: articulo.ESPTEC_SALVAGUARDA,
        p_esptec_atenuable: articulo.ESPTEC_ATENUABLE
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarArticulo(articulo: InvMaearticulo): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/7565695d5b56fc0', {
      elementos: {
        p_ART_codigo_1: articulo.ART_CODIGO,
        p_COM_codigo_2: '01'
      }
    }, this.confIniciales.httpOptions());
  }


  insertarPrecio(precio: InvMaeartprecio): Observable<any> {
    console.log(precio);
    return this.http.post<any>(this.way + '/inventario/articulo/95f8edf73b73280', {
      elementos: {
        p_ARTPRE_codigo_1: precio.ARTPRE_CODIGO,
        p_ARTPRE_precio_2: precio.ARTPRE_PRECIO,
        p_ARTPRE_descuento_3: precio.ARTPRE_DESCUENTO,
        p_ARTPRE_porcentaje_4: precio.ARTPRE_PORCENTAJE,
        p_ART_codigo_4: precio.ART_CODIGO.toUpperCase(),
        p_COM_codigo_5: precio.COM_CODIGO,
        p_UNI_codigo_6: precio.UNI_CODIGO,
        p_ARTPRE_CODBARRA: precio.ARTPRE_CODBARRA,
        p_ARTPRE_COSTO: precio.ARTPRE_COSTO,
        p_ARTPRE_CANTIDADINI: precio.ARTPRE_CANTIDADINI,
        p_ARTPRE_CANTIDADFIN: precio.ARTPRE_CANTIDADFIN,
        p_ARTPRE_DESCF: precio.ARTPRE_DESCF,
        p_ARTPRE_DESCP: precio.ARTPRE_DESCP,
        p_ARTPRE_PRECIOFPVP: precio.ARTPRE_PRECIOFPVP
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarPrecio(precio: InvMaeartprecio): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/a677caacb3b73000', {
      elementos: {
        p_ARTPRE_codigo: precio.ARTPRE_CODIGO,
        p_ART_codigo: precio.ART_CODIGO,
        p_COM_codigo: precio.COM_CODIGO,
        p_ARTPRE_precio: precio.ARTPRE_PRECIO,
        p_ARTPRE_descuento: precio.ARTPRE_DESCUENTO,
        p_ARTPRE_porcentaje: precio.ARTPRE_PORCENTAJE,
        p_UNI_codigo: precio.UNI_CODIGO,
        p_ARTPRE_CODBARRA: precio.ARTPRE_CODBARRA,
        p_ARTPRE_COSTO: precio.ARTPRE_COSTO,
        p_ARTPRE_CANTIDADINI: precio.ARTPRE_CANTIDADINI,
        p_ARTPRE_CANTIDADFIN: precio.ARTPRE_CANTIDADFIN,
        p_ARTPRE_DESCF: precio.ARTPRE_DESCF,
        p_ARTPRE_DESCP: precio.ARTPRE_DESCP,
        p_ARTPRE_PRECIOFPVP: precio.ARTPRE_PRECIOFPVP
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarPrecio(precio: InvMaeartprecio): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/eacad2bab3b73000', {
      elementos: {
        p_ARTPRE_codigo_1: precio.ARTPRE_CODIGO,
        p_ART_codigo_2: precio.ART_CODIGO,
        p_COM_codigo_3: precio.COM_CODIGO,
        p_uni_codigo: precio.UNI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }


  insertarParte(parte: ProdMaeartparte): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/95f8edf55956f80', {
      elementos: {
        p_ART_codigo_1: parte.ART_CODIGO,
        p_COM_codigo_2: parte.COM_CODIGO,
        p_ART_codigop_3: parte.ART_CODIGOP,
        p_TRNART_cantidad_4: parte.ARTPARTE_CANTIDAD,
        p_TRNART_totalart_5: parte.ARTPARTE_TOTALART,
        p_uni_codigo: parte.UNI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarParte(parte: ProdMaeartparte): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/533be5565956fc0', {
      elementos: {
        p_ART_codigo: parte.ART_CODIGO,
        p_COM_codigo: parte.COM_CODIGO,
        p_ART_codigop: parte.ART_CODIGOP,
        p_TRNART_cantidad: parte.ARTPARTE_CANTIDAD,
        p_TRNART_totalart: parte.ARTPARTE_TOTALART,
        p_uni_codigo: parte.UNI_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarParte(parte: ProdMaeartparte): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/7565695d5956fc0', {
      elementos: {
        p_ART_codigop_3: parte.ART_CODIGOP,
        p_ART_codigo_1: parte.ART_CODIGO,
        p_COM_codigo_2: parte.COM_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }

  insertarFormula(formula: ProdMaeartformula): Promise<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/95f8edf54fc6f00', {
      elementos: {
        p_ART_codigo_1: formula.ART_CODIGO,
        p_COM_codigo_2: formula.COM_CODIGO,
        p_ART_codigop_3: formula.ART_CODIGOP,
        p_for_cantidad_4: formula.FOR_CANTIDAD,
        P_FOR_canttotal: formula.FOR_CANTTOTAL,
        p_uni_codigo: formula.UNI_CODIGO,
        p_for_codigo: formula.FOR_CODIGO,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarFormula(formula: ProdMaeartformula): Promise<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/533be5564fc6ec0', {
      elementos: {
        p_ART_codigo_1: formula.ART_CODIGO,
        p_COM_codigo_2: formula.COM_CODIGO,
        p_ART_codigop_3: formula.ART_CODIGOP,
        p_ART_codigop_4: formula.ART_CODIGOP,
        p_for_cantidad_5: formula.FOR_CANTIDAD,
        P_FOR_canttotal: formula.FOR_CANTTOTAL,
        p_uni_codigo: formula.UNI_CODIGO,
        p_for_codigo: formula.FOR_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarFormula(formula: ProdMaeartformula): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/7565695d4fc6ec0', {
      elementos: {
        p_ART_codigop_3: formula.ART_CODIGOP,
        p_ART_codigo_1: formula.ART_CODIGO,
        p_COM_codigo_2: formula.COM_CODIGO,
        p_for_codigo: formula.FOR_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }


  insertarCostoEst(costo: ProdMaecostoestand): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/4afc6639dc3b9c', {
      elementos: {
        p_cest_codigo: costo.CEST_CODIGO,
        p_cin_codigo: costo.CIN_CODIGO,
        p_com_codigo: costo.COM_CODIGO,
        p_cest_costo: costo.CEST_COSTO,
        p_art_codigo: costo.ART_CODIGO,
        p_cest_cantidad: costo.CEST_CANTIDAD
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarCostoEst(costo: ProdMaecostoestand): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/533be6639dc3b80', {
      elementos: {
        p_cest_codigo: costo.CEST_CODIGO,
        p_cin_codigo: costo.CIN_CODIGO,
        p_com_codigo: costo.COM_CODIGO,
        p_cest_costo: costo.CEST_COSTO,
        p_art_codigo: costo.ART_CODIGO,
        p_cest_cantidad: costo.CEST_CANTIDAD
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarCostoEst(costo: ProdMaecostoestand): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/756566639dc3b80', {
      elementos: {
        p_cest_codigo: costo.CEST_CODIGO,
        p_com_codigo: costo.COM_CODIGO,
        p_art_codigo: costo.ART_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }


  insertarExistencia(existencia: InvExistenciabod): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/95f8edf5dc64b80', {
      elementos: {
        p_ART_CODIGO: existencia.ART_CODIGO,
        p_COM_CODIGO: existencia.COM_CODIGO,
        p_BOD_CODIGO: existencia.BOD_CODIGO,
        p_ARTEXT_MAXIMA: existencia.ARTEXT_MAXIMA,
        p_ARTEXT_MINIMA: existencia.ARTEXT_MINIMA,
        p_ARTEXT_REFERENCIA: existencia.ARTEXT_REFERENCIA,
        p_ARTEXT_ORDENAR: existencia.ARTEXT_ORDENAR
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarExistencia(existencia: InvExistenciabod): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/299df29dc64b9c', {
      elementos: {
        p_ART_CODIGO: existencia.ART_CODIGO,
        p_COM_CODIGO: existencia.COM_CODIGO,
        p_BOD_CODIGO: existencia.BOD_CODIGO,
        p_ARTEXT_MAXIMA: existencia.ARTEXT_MAXIMA,
        p_ARTEXT_MINIMA: existencia.ARTEXT_MINIMA,
        p_ARTEXT_REFERENCIA: existencia.ARTEXT_REFERENCIA,
        p_ARTEXT_ORDENAR: existencia.ARTEXT_ORDENAR
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarExistencia(existencia: InvExistenciabod): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/7565677192e75c0', {
      elementos: {
        P_ART_CODIGO: existencia.ART_CODIGO,
        P_COM_CODIGO: existencia.COM_CODIGO,
        P_BOD_CODIGO: existencia.BOD_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }


  seleccionarRegistro(tipo, precio: InvMaearticulo): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/3aecc5fbb7425c', {
      elementos: {
        p_tipo: tipo,
        p_ART_codigo: precio.ART_CODIGO,
        p_COM_codigo: '01'

      }
    }, this.confIniciales.httpOptions());
  }

  getgruposnivel1() {
    return this.http.post<any>(this.way + '/inventario/articulo/83bb0dfb38e5e8000', {
      elementos: {}
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getgruposnivelsnpto(intNivel, strGrupCodigo) {
    return this.http.post<any>(this.way + '/inventario/articulo/107761bf671cbd000', {
      elementos: {
        intNivel, strGrupCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  getgruposnivelpto(intNivel, strGrupCodigo) {
    return this.http.post<any>(this.way + '/inventario/articulo/1eaf60eec37ece000', {
      elementos: {
        intNivel, strGrupCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  saveprecioservice(maeprecio) {

    if (maeprecio === undefined) {
      this.messageService.add({
        key: 'art',
        severity: 'warn',
        summary: 'Información',
        detail: 'No ha seleccionado el precio'
      });
      return;
    }

    if (maeprecio.ARTPRE_CODIGO === '' || maeprecio.UNI_CODIGO === '') {
      this.messageService.add({
        key: 'art',
        severity: 'warn',
        summary: 'Información',
        detail: 'Complete los campos'
      });
      return;
    }

    if (maeprecio.ARTPRE_PRECIO !== '' && maeprecio.ARTPRE_CODIGO !== '') {

      maeprecio.ARTPRE_PRECIO = Number(maeprecio.ARTPRE_PRECIO);
      maeprecio.ARTPRE_DESCUENTO = Number(maeprecio.ARTPRE_DESCUENTO);
      maeprecio.ARTPRE_PORCENTAJE = Number(maeprecio.ARTPRE_PORCENTAJE);
      maeprecio.ARTPRE_COSTO = Number(maeprecio.ARTPRE_COSTO);
      maeprecio.ARTPRE_CANTIDADINI = Number(maeprecio.ARTPRE_CANTIDADINI);
      maeprecio.ARTPRE_CANTIDADFIN = Number(maeprecio.ARTPRE_CANTIDADFIN);
      maeprecio.ARTPRE_DESCF = Number(maeprecio.ARTPRE_DESCF);
      maeprecio.ARTPRE_DESCP = Number(maeprecio.ARTPRE_DESCP);
      maeprecio.ARTPRE_PRECIOFPVP = Number(maeprecio.ARTPRE_PRECIOFPVP);

      this.encontrarArtPreCodigo(
        'ART_CODIGO = \'' + maeprecio.ART_CODIGO +
        '\' AND ARTPRE_CODIGO=\'' + maeprecio.ARTPRE_CODIGO +
        '\' AND UNI_CODIGO=\'' + maeprecio.UNI_CODIGO + '\'').subscribe((existedata) => {
          console.log(existedata);
          if (existedata !== null) {
            this.actualizarPrecio(maeprecio).subscribe(() => {
              this.messageService.add({
                key: 'art',
                severity: 'success',
                summary: 'Información',
                detail: 'El precio del artículo se actualizo correctamente'
              });

            }, error1 => {
              const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Error',
                detail: error
              });
            });
          } else {
            this.insertarPrecio(maeprecio).subscribe(() => {
              this.messageService.add({
                key: 'art',
                severity: 'success',
                summary: 'Información',
                detail: 'El precio del artículo se insertó correctamente'
              });
            }, error1 => {
              const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'art',
                severity: 'error',
                summary: 'Error',
                detail: error
              });
            });
          }
        });
    }
  }


  saveparteservice(maeparte) {

    if (maeparte.ART_CODIGO === '' || maeparte.ART_CODIGOP === '') {
      this.messageService.add({
        key: 'art',
        severity: 'warn',
        summary: 'Información',
        detail: 'Complete los campos'
      });
      return;
    }


    maeparte.ARTPARTE_CANTIDAD = Number(maeparte.ARTPARTE_CANTIDAD);
    maeparte.ARTPARTE_TOTALART = Number(maeparte.ARTPARTE_TOTALART);

    if (maeparte.ART_CODIGO !== '' && maeparte.ART_CODIGOP !== '') {
      this.encontrarArtCodigoPMaeParte(maeparte.ART_CODIGO, maeparte.ART_CODIGOP).subscribe((existedata) => {
        if (existedata !== null) {
          this.actualizarParte(maeparte).subscribe(() => {
            // this.messageService.add({
            //   key: 'art',
            //   severity: 'success',
            //   summary: 'Información',
            //   detail: 'El componente del artículo se actualizo correctamente'
            // });
          }, error1 => {
            const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Error',
              detail: error
            });
          });
        } else {
          this.insertarParte(maeparte).subscribe((datos: any[]) => {
            // this.messageService.add({
            //   key: 'art',
            //   severity: 'success',
            //   summary: 'Información',
            //   detail: 'El componente del artículo se insertó correctamente'
            // });
          }, error1 => {
            const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Error',
              detail: error
            });
          });
        }
      });
    }
  }


  async saveformulaservice(maeformula) {
    if (maeformula.ART_CODIGO === '' || maeformula.FOR_CODIGO === '') {
      this.messageService.add({
        key: 'art',
        severity: 'warn',
        summary: 'Información',
        detail: 'Complete los campos'
      });
      return;
    }
    maeformula.FOR_CANTIDAD = Number(maeformula.FOR_CANTIDAD);
    maeformula.FOR_CANTTOTAL = Number(maeformula.FOR_CANTTOTAL);

    if (maeformula.FOR_CODIGO !== '' && maeformula.ART_CODIGOP !== '') {
      const existedata = await this.encontrarArtCodigoPMaeFor(maeformula.ART_CODIGO, maeformula.ART_CODIGOP, maeformula.FOR_CODIGO).toPromise();
      console.log(existedata);
      if (existedata !== null) {
        try {
          await this.actualizarFormula(maeformula);
          this.llenarCmbFormula(maeformula.ART_CODIGO);
        } catch (error1) {
          const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.messageService.add({
            key: 'art',
            severity: 'error',
            summary: 'Error',
            detail: error
          });
        }
      } else {
        try {
          await this.insertarFormula(maeformula);
          this.llenarCmbFormula(maeformula.ART_CODIGO);
        } catch (error1) {
          const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.messageService.add({
            key: 'art',
            severity: 'error',
            summary: 'Error',
            detail: error
          });
        }
      }
    }
  }


  savecostoestservice(maecostoesst) {
    if (maecostoesst.ART_CODIGO === '' || maecostoesst.CIN_CODIGO === '') {
      this.messageService.add({
        key: 'art',
        severity: 'warn',
        summary: 'Información',
        detail: 'Complete los campos'
      });
      return;
    }

    maecostoesst.CEST_COSTO = Number(maecostoesst.CEST_COSTO);

    if (maecostoesst.CIN_CODIGO !== '' && maecostoesst.CEST_COSTO !== '') {

      this.encontrarCinCodMaeCostEst(maecostoesst.ART_CODIGO, maecostoesst.CIN_CODIGO).subscribe((existedata) => {
        if (existedata !== null) {
          this.actualizarCostoEst(maecostoesst).subscribe(() => {
          }, error1 => {
            const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Error',
              detail: error
            });
          });
        } else {
          this.insertarCostoEst(maecostoesst).subscribe((datos: any[]) => {
          }, error1 => {
            const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Error',
              detail: error
            });
          });
        }
      });
    }
  }


  saveexistbodservice(maeexistbod) {
    if (maeexistbod.ART_CODIGO === '' || maeexistbod.BOD_CODIGO === '') {
      this.messageService.add({
        key: 'art',
        severity: 'warn',
        summary: 'Información',
        detail: 'Complete los campos'
      });
      return;
    }

    maeexistbod.ARTEXT_ORDENAR = Number(maeexistbod.ARTEXT_ORDENAR);
    maeexistbod.ARTEXT_MAXIMA = Number(maeexistbod.ARTEXT_MAXIMA);
    maeexistbod.ARTEXT_MINIMA = Number(maeexistbod.ARTEXT_MINIMA);
    maeexistbod.EXISTENCIAFECHA = Number(maeexistbod.EXISTENCIAFECHA);

    if (maeexistbod.BOD_CODIGO !== '' && maeexistbod.ARTEXT_MAXIMA !== '') {

      this.encontrarBodCodigoMaeExist(maeexistbod.ART_CODIGO, maeexistbod.BOD_CODIGO).subscribe((existedata) => {
        if (existedata !== null) {
          this.actualizarExistencia(maeexistbod).subscribe(() => {
            // this.messageService.add({
            //   key: 'art',
            //   severity: 'success',
            //   summary: 'Información',
            //   detail: 'La existencia por bodega del artículo se actualizo correctamente'
            // });

          }, error1 => {
            const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Error',
              detail: error
            });
          });
        } else {
          this.insertarExistencia(maeexistbod).subscribe((datos: any[]) => {
            // this.messageService.add({
            //   key: 'art',
            //   severity: 'success',
            //   summary: 'Información',
            //   detail: 'La existencia por bodega del artículo se insertó correctamente'
            // });
          }, error1 => {
            const error = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.messageService.add({
              key: 'art',
              severity: 'error',
              summary: 'Error',
              detail: error
            });
          });
        }
      });
    }
  }

  getToten(artcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/20eef71d555d7', {
      elementos: {
        ART_CODIGO: artcodigo
      }
    }, this.confIniciales.httpOptions());
  }

  getTotsa(artcodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/41ddee3aaaf154', {
      elementos: {
        ART_CODIGO: artcodigo
      }
    }, this.confIniciales.httpOptions());
  }

  actAlternosArticulo(alterno1, alterno2, alterno3, alterno4, artCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/articulo/a675986aabd77000', {
      elementos: {
        cod_alterno1: alterno1,
        cod_alterno2: alterno2,
        cod_alterno3: alterno3,
        cod_alterno4: alterno4,
        art_codigo: artCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerConfig(campo, tabla): number {
    let numDecimales = 0;
    const decimales = this.configDecimales.find(element => element.NUMDEC_CAMPO === campo && element.NUMDEC_TIPDOC === tabla);
    if (decimales !== undefined) {
      numDecimales = decimales.NUMDEC_NUMDEC;
    } else {
      numDecimales = 2;
    }

    return numDecimales;
  }

  llenarCmbFormula(artCodigo) {
    this.llenarComboFormula(artCodigo).subscribe((combo) => {

      this.formulas = [];
      for (const rs of combo) {
        this.formulas.push({ codigo: rs.FOR_CODIGO, name: rs.FOR_CODIGO });
      }

      if (combo.length > 0) {
        this.formula = this.formulas[0].codigo;
        this.selectformula = { codigo: this.formulas[0].codigo, name: this.formulas[0].name };
      }
    });
  }

  deplegarInformacion(artCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/15973aadab7d', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erCliNombre(strClicodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/3b6cacaf8b2f6e', {
      elementos: {
        CLI_CODIGO: strClicodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerDeclaracion(artCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/c2faebb5ccaab80', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerTrnAplicacion(artCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/c2faeeeeeacd680', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerComposicionQuim(artCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/617d662daf4ad0', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerPerfilAromatico(artCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/617dcbb6ade2c8', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontarMarCliente(cli_codigo) {
    return this.http.post(this.way + '/inventario/articulo/76eca732b275fc0', {
      elementos: {
        parametro: cli_codigo
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarFichaTecnica(parametros) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/299d7c99d732f2', {
      elementos: {
        p_art_codigo: parametros[0],
        p_com_codigo: parametros[1],
        p_art_ftcodcli: parametros[2],
        p_art_ftdeclaracion: parametros[3],
        p_art_ftcalidad: parametros[4],
        p_art_ftaplicacion: parametros[5],
        p_art_ftcomposicion: parametros[6],
        p_art_ftvidaanaquel: parametros[7],
        p_art_ftestadofisico: parametros[8],
        p_art_ftcolor: parametros[9],
        p_art_ftperfilaro: parametros[10],
        p_art_ftpresentacion: parametros[11],
        p_art_ftmanejo: parametros[12],
        p_art_ftcertificado: parametros[13],
        p_art_ftcodigo: parametros[14],
        p_art_ftFechaVigencia: parametros[15],
        p_art_ftversion: parametros[16],
        p_art_fttitulo: parametros[17],
        p_art_ftrevisado: parametros[18]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerDatosFisQuim(artCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/83bad53e5cd7a80', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerDatosMicrobiologicas(artCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/83bad55a4cde180', {
      elementos: {
        ART_CODIGO: artCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarFisQuim(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/3ab2b3e5cd7a56', {
      elementos: {
        p_art_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_trnfic_parametro: parametro[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarFisQuim(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/8795b6be5cd7a80', {
      elementos: {
        p_art_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_trnfic_parametro: parametro[2],
        p_trnfic_metodo: parametro[3],
        p_trnfic_resultado: parametro[4],
        p_trnfic_tipo: parametro[5],
        p_trnfic_orden: parametro[6]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTodoFTecnica(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/1d595bad7f5cc', {
      elementos: {
        p_art_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_trnfic_tipo: parametro[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarTrnComqui(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/8795b6f77766300', {
      elementos: {
        p_art_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_trncomqui_codigo: parametro[2],
        p_trncomqui_nombre: parametro[3],
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  listarTrnComqui(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/83bbdddd98b6c00', {
      elementos: {
        ART_CODIGO: strArtCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnComqui(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/7565dddd98b6bc0', {
      elementos: {
        p_art_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_trncomqui_codigo: parametro[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarTrnAplicacion(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/7565dddd59ac980', {
      elementos: {
        p_art_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_trnapli_codigo: parametro[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  listarTrnAplicacion(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/acb9deeeeacd680', {
      elementos: {
        ART_CODIGO: strArtCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  guardarTrnAplicacion(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/8795b6f77756680', {
      elementos: {
        p_art_codigo: parametro[0],
        p_com_codigo: parametro[1],
        p_trnapli_codigo: parametro[2],
        p_trnapli_nombre: parametro[3]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getUnidadCosteoArticulo(articulo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/41ddf5e4cc73b000000', {
      elementos: {
        articulo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarCantCostoEst(intCantidad, strArtcodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/14ceb15763b9d', {
      elementos: {
        CANTIDAD: intCantidad,
        ART_CODIGO: strArtcodigo
      }
    }, this.confIniciales.httpOptions());
  }

  encontrarCenCosto(strParametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/3b765398ebb31c', {
      elementos: {
        CEN_CODIGO: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  listarDescripciones(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/565ceb5dc66e58', {
      elementos: {
        ART_CODIGO: strArtCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDescripcion(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/3ab2b35dc66e58', {
      elementos: {
        p_ART_CODIGO: parametro[0],
        p_COMART_NUMERO: parametro[1],
        p_COM_CODIGO: parametro[2]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarDescripcion(parametro) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/4afc6bb8cdcb2c', {
      elementos: {
        p_ART_CODIGO: parametro[0],
        p_COMART_NUMERO: parametro[1],
        p_COM_CODIGO: parametro[2],
        p_COMART_DESCRIPCION: parametro[3]
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async guardarDescripcion(item: Invmaecomart) {
    const parametro: any[] = [];
    parametro[0] = item.ART_CODIGO;
    parametro[1] = Number(item.COMART_NUMERO);
    parametro[2] = '01';
    parametro[3] = item.COMART_DESCRIPCION;

    try {
      const rs = await this.insertarDescripcion(parametro);
      this.btnCancelarDesc = true;
      this.btnNuevoDesc = false;
      this.btnEliminarDesc = false;

      const numero = Number(Object.values(rs[0]));

      if (numero !== 0) {
        for (let i = 0; i < this.maecomart.length; i++) {
          if (this.maecomart[i].COMART_NUMERO === item.COMART_NUMERO) {
            this.maecomart[i].COMART_NUMERO = numero;
          }
        }
      }
    } catch (err) {

    }
  }

  getTiposCategoria() {
    return this.http.post<any[]>(this.way + '/inventario/articulo/20eecb2c575d0', {
      elementos: {}
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getUltimoCostoCompra(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/c2fbeaf598e3300', {
      elementos: {
        ART_CODIGO: strArtCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getUltimoCostoProduccion(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/30bebaeedfabda00000000000000', {
      elementos: {
        ART_CODIGO: strArtCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getCostoPromedio(strArtCodigo, strFecha, strHora) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/185f598e773bc6000', {
      elementos: {
        ART_CODIGO: strArtCodigo,
        FECHA: strFecha,
        HORA: String(strHora)
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getUltProveedorCompra(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/617df57b9de3f0', {
      elementos: {
        ART_CODIGO: strArtCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerExistenciaBodegaProm(strTipo, strArtCodigo, strBodCodigo, strFecha, strHora) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/30bebb8c973abc0000', {
      elementos: {
        tipo: strTipo,
        art_codigo: strArtCodigo,
        bod_codigo: strBodCodigo,
        fecha: strFecha,
        hora: strHora
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  loadData() {
    return this.http.post<any[]>(this.way + '/cont/saciperiodo/cc5f9eaf72edc800', {
      elementos: {
        p_opcion: '',
        p_filtro: 'COM_CODIGO = \'01\'',
        p_condicion: '',
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getcuentascontables() {
    return this.http.post<any[]>(this.way + '/inventario/articulo/41dd679d7eab8c0000000000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerConfiguracionFecha(strCodigo): Promise<[]> {
    return this.http.post<[]>(this.way + '/seg/configuracion/30beb3177ca1e', {
      elementos: {
        codigo: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrartitulos() {
    return this.http.post<any[]>(this.way + '/inventario/articulo/83baadf6b25f68000000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarautor() {
    return this.http.post<any[]>(this.way + '/inventario/articulo/20eeab7dac96b00000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrareditorial() {
    return this.http.post<any[]>(this.way + '/inventario/articulo/20eeab7dac96e6000000000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarorigen() {
    return this.http.post<any[]>(this.way + '/inventario/articulo/41dd56fb592f1c00000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrartamanio() {
    return this.http.post<any[]>(this.way + '/inventario/articulo/83baadf6b25f58000000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  encontrarencuadernacion() {
    return this.http.post<any[]>(this.way + '/inventario/articulo/41dd56fb592dd8000000', {
      elementos: {
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarDatosNuevos(articulo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/299d6abb8e564c', {
      elementos: {
        ART_CODIGO: articulo.ART_CODIGO,
        ART_LIB_TITULO: articulo.ART_LIB_TITULO,
        ART_LIB_AUTOR: articulo.ART_LIB_AUTOR,
        ART_LIB_EDITORIAL: articulo.ART_LIB_EDITORIAL,
        ART_LIB_ORIGEN: articulo.ART_LIB_ORIGEN,
        ART_LIB_TAMANIO: articulo.ART_LIB_TAMANIO,
        ART_LIB_ENCUADERNACION: articulo.ART_LIB_ENCUADERNACION,
        ART_APLICASERVICIO: articulo.ART_APLICASERVICIO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  /*------------NUEVO VEHICULO-----------*/
  actualizarDatosAutoArticulo(articulo, fecha) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/3d96abae6abb8e000000000', {
      elementos: {
        ART_CODIGO: articulo.ART_CODIGO,
        ART_RAMV: articulo.ART_RAMV,
        ART_MODELOSRI: articulo.ART_MODELOSRI,
        ART_MODELOAT: articulo.ART_MODELOAT,
        ART_MARCAV: articulo.ART_MARCAV,
        ART_CLASE: articulo.ART_CLASE,
        ART_TIPOV: articulo.ART_TIPOV,
        ART_CILINDRAJE: articulo.ART_CILINDRAJE,
        ART_ANIO: articulo.ART_ANIO,
        ART_TONELAJE: articulo.ART_TONELAJE,
        ART_COLOR: articulo.ART_COLOR,
        ART_PORIGEN: articulo.ART_PORIGEN,
        ART_MOTOR: articulo.ART_MOTOR,
        ART_SERIEV: articulo.ART_SERIEV,
        ART_NRUEDAS: articulo.ART_NRUEDAS,
        ART_NEJES: articulo.ART_NEJES,
        ART_CAPACIDAD: articulo.ART_CAPACIDAD,
        ART_COMBUSTIBLE: articulo.ART_COMBUSTIBLE,
        ART_BANCO: articulo.ART_BANCO,
        ART_DPAGO: articulo.ART_DPAGO,
        ART_FFABRICA: fecha,
        ART_DISPOSITIVO: articulo.ART_DISPOSITIVO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erNombreBanco(ART_BANCO) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edcb56000', {
      elementos: {
        ART_BANCO
      },
    }, this.confIniciales.httpOptions());
  }

  getencfacpro(artcodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/83baebb1ea66780', {
      elementos: {
        artcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  getencfac(artcodigo) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/10775d763d4c', {
      elementos: {
        artcodigo
      },
    }, this.confIniciales.httpOptions());
  }
  encontrarTarriIva(tariiva) {
    return this.http.post(this.way + '/inventario/articulo/10777aadca5f530000000000000', {
      elementos: {
        p_tariiva_codigo: tariiva,

      },
    }, this.confIniciales.httpOptions());
  }
  obtenerClaveIva(): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/articulo/c2faebbb6caac00', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  actualizarporceiva(tariivacod, tariivaporce) {
    return this.http.post<any[]>(this.way + '/inventario/articulo/299df2ab2', {
      elementos: {
        p_tariiva_codigo: tariivacod,
        p_tariiva_porcentaje: tariivaporce
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
