import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { Observable } from 'rxjs';
import { Inventrada } from '../invinterfaces/inventrada';
import { Invencentrada } from '../invinterfaces/invencentrada';
import { Inventradavar } from '../invinterfaces/inventradavar';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class InventradaService {

  public url: string;
  public way: string;
  public detkardexarray: Inventrada[][] = [];
  public detkardexvararray: Inventradavar[][] = [];
  public totalEntradaarray: string[] = [];

  public decimalesPrecioEnt: number;
  public decimalesCantidadEnt: number;
  public decimalesTotalEnt: number;

  public hmpConfiguracion: any[];

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private init: NuevoComponentService,
    private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  getEncEntradas(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/entradas/f3at8txdznwy4en', {
      sql: 'select * from inv_enckardex where kar_tipo like \'EN\' ORDER BY KAR_NRO',
      elementos: {},
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions());
  }

  getEntradas(codigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/entradas/09ixggoj37x52zq', {
      elementos: {
        KAR_NRO: codigo,
      },
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions());
  }

  consultarDetKardex(codigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/entradas/bly1ifkzrbu9o2s', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: 'INV_PCK_DETKARDEX_C.INV_SP_consultarDetKardexWeb',
      elementos: {
        p_KAR_nro_1: codigo,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  getEntradasVar(codigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/entradas/0kyc0188lf1smc4', {
      sql: 'select * from inv_detkardexvar where  KAR_NRO=:KAR_NRO',
      elementos: {
        KAR_NRO: codigo,
      },
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions());
  }

  getArticulo(artCod): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/nwu1ni5c7hgcdpy', {
      sql: 'select ART_NOMBRE AS ART_NOMBRE from inv_maearticulo where ART_CODIGO=:ART_CODIGO',
      elementos: {
        ART_CODIGO: artCod,
      },
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions());
  }

  insertarEntrada(entrada: Inventrada): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/1v63nxwsebeqnil', {
      elementos: {
        p_KAR_nro_1: entrada.KAR_NRO,
        p_BOD_codigo_2: entrada.BOD_CODIGO,
        p_ART_codigo_3: entrada.ART_CODIGO,
        p_DETKAR_NRODOC: entrada.DETKAR_NRODOC,
        p_DETKAR_cantidad_4: Number(entrada.DETKAR_CANTIDAD),
        p_DETKAR_costounit_5: Number(entrada.DETKAR_COSTOUNIT),
        p_DETKAR_costototal_6: Number(entrada.DETKAR_CANTIDAD * entrada.DETKAR_COSTOUNIT),
        p_BOD_codigo_ant: entrada.BOD_CODIGO,
        p_ART_codigo_ant: entrada.ART_CODIGO,
        p_CON_CODIGO: entrada.CON_CODIGO,
        p_com_codigo: '01',
        p_detkar_linea: Number(entrada.DETKAR_LINEA),
        p_encgre_codigo: Number(entrada.ENCGRE_CODIGO),
        p_detgre_linea: Number(entrada.DETGRE_LINEA),
        p_detkar_lote: entrada.DETKAR_LOTE,
        p_cli_codigo: entrada.CLI_CODIGO,
        p_detkar_puntos: Number(entrada.DETKAR_PUNTOS),
        p_maepun_codigo: entrada.MAEPUN_CODIGO,
        p_detkar_cantidadund: entrada.DETKAR_CANTIDADUND,
        p_cen_codigo:entrada.CEN_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  insertarEncEntrada(encentrada: Invencentrada): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/6xukpiz7z5b9q5q', {
      elementos: {
        p_KAR_fecha_3: encentrada.KAR_FECHA,
        p_KAR_hora_4: encentrada.KAR_HORA,
        p_KAR_comentario_5: encentrada.KAR_COMENTARIO,
        p_KAR_tipo_2: encentrada.KAR_TIPO,
        p_KAR_origen_6: encentrada.KAR_ORIGEN,
        p_COM_codigo_7: '01',
        p_tipdia_codigo: encentrada.TIPDIA_CODIGO,
        p_KAR_NROCOMPROBANTE: encentrada.KAR_NROCOMPROBANTE,
        p_USUidentificacion_1: encentrada.USUIDENTIFICACION,
        p_encordpro_numero: encentrada.ENCORDPRO_NUMERO,
        p_KAR_SALDOINI: encentrada.KAR_SALDOINI,
        p_KAR_NO_KARDEX: encentrada.KAR_NO_KARDEX,
        p_KAR_ESTADO: encentrada.KAR_ESTADO
      }
    }, this.confIniciales.httpOptions());
  }

  insertarEntradaVar(entradavar: Inventradavar): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/qhkpxugn59k56ck', {
      elementos: {
        p_kar_nro: entradavar.KAR_NRO,
        p_CON_CODIGO: entradavar.CON_CODIGO,
        p_CON_NOMBRE: entradavar.CON_NOMBRE,
        p_CEN_CODIGO: entradavar.CEN_CODIGO,
        p_COM_CODIGO: '01',
        p_DETKARDEXVAR_LINEA: Number(entradavar.DETKARDEXVAR_LINEA),
        p_DETKARDEXVAR_IMPORTE: Number('-' + entradavar.DETKARDEXVAR_IMPORTE)
      }
    }, this.confIniciales.httpOptions());
  }

  insertarEntradaVarProm(entradavar: Inventradavar) {
    return this.http.post<any>(this.way + '/inventario/entradas/qhkpxugn59k56ck', {
      elementos: {
        p_kar_nro: entradavar.KAR_NRO,
        p_CON_CODIGO: entradavar.CON_CODIGO,
        p_CON_NOMBRE: entradavar.CON_NOMBRE,
        p_CEN_CODIGO: entradavar.CEN_CODIGO,
        p_COM_CODIGO: '01',
        p_DETKARDEXVAR_LINEA: Number(entradavar.DETKARDEXVAR_LINEA),
        p_DETKARDEXVAR_IMPORTE: Number('-' + entradavar.DETKARDEXVAR_IMPORTE)
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarEncEntrada(encentrada: Invencentrada): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/iq4t96yr7q7mxl2', {
      elementos: {
        p_KAR_nro_1: encentrada.KAR_NRO,
        p_KAR_fecha_3: encentrada.KAR_FECHA,
        p_KAR_hora_4: encentrada.KAR_HORA,
        p_KAR_comentario_5: encentrada.KAR_COMENTARIO,
        p_KAR_tipo_2: encentrada.KAR_TIPO,
        p_KAR_origen_6: encentrada.KAR_ORIGEN,
        p_COM_codigo_7: '01',
        p_tipdia_codigo: encentrada.TIPDIA_CODIGO,
        p_KAR_NROCOMPROBANTE: encentrada.KAR_NROCOMPROBANTE,
        p_USUidentificacion_1: encentrada.USUIDENTIFICACION,
        p_encordpro_numero: encentrada.ENCORDPRO_NUMERO,
        p_KAR_SALDOINI: encentrada.KAR_SALDOINI,
        p_KAR_NO_KARDEX: encentrada.KAR_NO_KARDEX,
        p_KAR_ESTADO: encentrada.KAR_ESTADO
      }
    }, this.confIniciales.httpOptions());
  }

  borrarEntrada(entrada: Inventrada): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/n1c7itxf5zztag9', {
      elementos: {
        p_KAR_nro_1: entrada.KAR_NRO,
        p_com_codigo: '01',
        p_detkar_linea: entrada.DETKAR_LINEA
      },
    }, this.confIniciales.httpOptions());
  }

  borrarEncEntrada(entrada: Invencentrada): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/v3if4gwjwp55ffg', {
      elementos: {
        p_KAR_nro_1: entrada.KAR_NRO,
      },
    }, this.confIniciales.httpOptions());
  }

  borrarEntradaVar(entradavar: Inventradavar): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/bl8t3o3ppnwagsz', {
      elementos: {
        p_kar_nro: entradavar.KAR_NRO,
        p_detkardexvar_linea: entradavar.DETKARDEXVAR_LINEA,
        p_con_codigo: entradavar.CON_CODIGO,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarEntradasVar(karnro): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/3xslqmnbp13o9y', {
      elementos: {
        KAR_NRO: karnro,
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarEntradasVarProm(karnro) {
    return this.http.post<any>(this.way + '/inventario/entradas/3xslqmnbp13o9y', {
      elementos: {
        KAR_NRO: karnro,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  generarAsiento(entrada: Invencentrada): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/tf40595zlocd1l7', {
      elementos: {
        p_KAR_NRO: entrada.KAR_NRO,
        p_kar_tipo: entrada.KAR_TIPO,
        p_com_codigo: '01',
        p_usuario: 'admin',
        p_tipdia_codigo: 'AE',
      },
    }, this.confIniciales.httpOptions());
  }

  generarAsientoProm(entrada: Invencentrada) {
    console.log(entrada);
    return this.http.post<any>(this.way + '/inventario/entradas/tf40595zlocd1l7', {
      elementos: {
        p_KAR_NRO: entrada.KAR_NRO,
        p_kar_tipo: entrada.KAR_TIPO,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'AE',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
//CDPJ
generarAsientoProdProm(entrada: Invencentrada) {
  console.log(entrada);
  return this.http.post<any>(this.way + '/inventario/entradas/83aeedab6ae4a000000000000', {
    elementos: {
      p_KAR_NRO: entrada.KAR_NRO,
      p_kar_tipo: entrada.KAR_TIPO,
      p_com_codigo: '01',
      p_usuario: this.usuario.identificacion,
      p_tipdia_codigo: 'OPT',
    },
  }, this.confIniciales.httpOptions()).toPromise();
}
//CDPJ
  getAsiento(entrada: Invencentrada): Observable<any> {
    return this.http.post<any>(this.way + '/inventario/entradas/p0tbawppb16hn0u', {
      elementos: {
        KAR_NRO: entrada.KAR_NRO
      }
    }, this.confIniciales.httpOptions());
  }

  getAsientoProm(entrada: Invencentrada) {
    return this.http.post<any>(this.way + '/inventario/entradas/p0tbawppb16hn0u', {
      elementos: {
        KAR_NRO: entrada.KAR_NRO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarKardex_en_sa(encEntrada: Invencentrada) {
    return this.http.post<any>(this.way + '/inventario/entradas/9fz8wu8mxefpxbn', {
      elementos: {
        p_kar_nro: encEntrada.KAR_NRO,
        p_com_codigo: '01',
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarAsientoCont(salida): Observable<any> {
    console.log(salida);
    return this.http.post<any>(this.way + '/inventario/entradas/dzgxjbz6kn19l0y', {
      elementos: {
        p_ASI_nro: salida,
        p_COM_codigo_2: '01',
      },
    }, this.confIniciales.httpOptions());
  }


  validarSeriales(articulo, strNumDoc, intLinea): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/inventario/entradas/x9wi61rwr724tex', {
      elementos: {
        art: articulo,
        numDoc: strNumDoc,
        linea: intLinea
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  async obtenerCstPromArtEntrada(strArtCodigo, strFecha, strHora) {
    let dblCostoProm = 0;


    try {

      const data = await this.http.post<any[]>(this.way + '/inventario/entradas/tf1tihhanrowsra', {
        elementos: {
          art_codigo: strArtCodigo,
          fecha: strFecha,
          hora: strHora
        }
      }, this.confIniciales.httpOptions()).toPromise();



      for (const rs of data) {
        dblCostoProm = rs.COSTO;
      }

    } catch (error1) {
      console.error;
    }
    return dblCostoProm;
  }

  async obtenerCstPromBusArtDetSal(strArtCodigo, strFecha, strHora) {
    let dblCostoProm = 0;


    try {

      const data = await this.http.post<any[]>(this.way + '/inventario/entradas/mytl1kgd3d0wnuz', {
        elementos: {
          art_codigo: strArtCodigo,
          fecha: strFecha,
          hora: strHora
        }
      }, this.confIniciales.httpOptions()).toPromise();



      for (const rs of data) {
        dblCostoProm = rs.COSTO;
      }

    } catch (error1) {
      console.error;
    }
    return dblCostoProm;

  }
  redondearDecimales(num) {
    let val=0
    //console.log(num);
    val=(Math.round((num + Number.EPSILON) *100)/100);
    val=Number(Number(val).toFixed(this.decimalesTotalEnt));
    //console.log(val.toFixed(2))
    //console.log('>><<<<<',total)
    return val;
  }
  calcularTotal() {
    let dblCostoTotal = 0;
    for (let fila = 0; fila < this.detkardexarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      dblCostoTotal = 0;
      dblCostoTotal = this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila].DETKAR_COSTOUNIT * this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila].DETKAR_CANTIDAD;
      this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila].DETKAR_COSTOTOTAL = dblCostoTotal;
      console.log('dblCostoTotal',dblCostoTotal)
    }
    let dblCostoTotalGlobal = 0;
    for (let fila1 = 0; fila1 < this.detkardexarray[this.init.tabs[this.init.tabindex].indice].length; fila1++) {
      console.log('suma',Number(this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL))
      dblCostoTotalGlobal = dblCostoTotalGlobal + Number(this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL);
    }
    for (let fila1 = 0; fila1 < this.detkardexarray[this.init.tabs[this.init.tabindex].indice].length; fila1++) {
      this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_CANTIDAD = Number(this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_CANTIDAD).toFixed(this.decimalesCantidadEnt);
      this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOUNIT = Number(this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOUNIT).toFixed(this.decimalesPrecioEnt);
      this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL = Number(this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL).toFixed(this.decimalesTotalEnt);
      //console.log('suma',Number(this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL))
      //dblCostoTotalGlobal = dblCostoTotalGlobal + Number(this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL);
    }
    dblCostoTotalGlobal=this.redondearDecimales(dblCostoTotalGlobal)
    this.totalEntradaarray[this.init.tabs[this.init.tabindex].indice] = String(dblCostoTotalGlobal);
    console.log(this.totalEntradaarray[this.init.tabs[this.init.tabindex].indice])
    this.totalEntradaarray[this.init.tabs[this.init.tabindex].indice] = String(Number(this.totalEntradaarray[this.init.tabs[this.init.tabindex].indice]).toFixed(this.decimalesTotalEnt));
  }
  //CDPJ
  erBodCodigo1(strCodigo) {
    return this.http.post(this.way + '/inventario/entradas/76d786b30d94300', {
      elementos: {
        BOD_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ValidaEnSaOrdProd(encEntrada: Invencentrada) {
   
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/20eebafc5636dc0000', {
      elementos: {
        p_prod_numero: encEntrada.ENCORDPRO_NUMERO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
}
