import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {InvMaelote} from '../veninterfaces/invmaelote';
import {DatePipe} from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})

export class InvmaeloteService {
  
  
  public url: string;
  private _auxsalto: boolean;
  private _detmaelote: InvMaelote[];
  public way: string;
  public decimalesCANTIDADlote:number;//CDPJ

  constructor(private http: HttpClient,
              private confIniciales: ConfInicialesService,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private datePipe: DatePipe) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  
  
  get detmaelote(): InvMaelote[] {
    return this._detmaelote;
  }
  
  set detmaelote(value: InvMaelote[]) {
    this._detmaelote = value;
  }
  
  get auxsalto(): boolean {
    return this._auxsalto;
  }
  
  set auxsalto(value: boolean) {
    this._auxsalto = value;
  }
  
  
  /* encontrarRegistro(strCampo, strTabla, strCondicion) {
    let strSQL: string;
    strSQL = 'Select ' + strCampo + ' FROM ' + strTabla;
    if (strCondicion !== '') {
      strSQL = strSQL + ' where ' + strCondicion;
    }
    
    return this.http.post(this.url + '/ejecutarproceso', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: 'SACI_PCK_CONSULTA.GEN_SP_consulta',
      elementos: {
        p_CADENA: strSQL
      },
    });
  } */
  
  listarDatos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/lotes/565ceab6d5771c', {      
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  
  
  insertarMaeLote(maelote): Observable<any[]> {
    //noout
    /* maelote.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(maelote.LOTE_FECHAVENCIMIENTO.toLocaleString()
      .substr(0, 10), 'dd/MM/yyyy'); */
    /* maelote.LOTE_FECHAFABRICACION= this.datePipe.transform(maelote.LOTE_FECHAFABRICACION.toLocaleString()
      .substr(0, 10), 'dd/MM/yyyy'); */
   
    return this.http.post<any[]>(this.way + '/ventas/lotes/12bf1dbeab75c7000', {      
      elementos: {
        p_com_codigo: maelote.COM_CODIGO,
        p_lote_numero: maelote.LOTE_NUMERO,
        p_lote_fechavencimiento: maelote.LOTE_FECHAVENCIMIENTO,
        p_art_codigo: maelote.ART_CODIGO,
        p_lote_origen: maelote.LOTE_ORIGEN,
        p_lote_cantidad: maelote.LOTE_CANTIDAD,
        p_lote_fechafabricacion: maelote.LOTE_FECHAFABRICACION,
        p_lote_regsanitario: maelote.LOTE_REGSANITARIO,
        p_lote_presentacion: maelote.LOTE_PRESENTACION,
        p_lote_tipoartprod: maelote.LOTE_TIPOARTPROD

      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarMaeLote(maelote): Observable<any[]> {
    /* maelote.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(maelote.LOTE_FECHAVENCIMIENTO.toLocaleString()
      .substr(0, 10), 'dd/MM/yyyy'); */

    /* maelote.LOTE_FECHAFABRICACION= this.datePipe.transform(maelote.LOTE_FECHAFABRICACION.toLocaleString()
      .substr(0, 10), 'dd/MM/yyyy'); */
 
    return this.http.post<any[]>(this.way + '/ventas/lotes/533dd55655c75c0', {      
      elementos: {
        p_com_codigo: maelote.COM_CODIGO,
        p_lote_numero: maelote.LOTE_NUMERO,
        p_lote_fechavencimiento: maelote.LOTE_FECHAVENCIMIENTO,
        p_art_codigo: maelote.ART_CODIGO,
        p_lote_origen: maelote.LOTE_ORIGEN,
        p_lote_cantidad: maelote.LOTE_CANTIDAD,
        p_lote_fechafabricacion: maelote.LOTE_FECHAFABRICACION,
        p_lote_regsanitario: maelote.LOTE_REGSANITARIO,
        p_lote_presentacion: maelote.LOTE_PRESENTACION,
        p_lote_tipoartprod: maelote.LOTE_TIPOARTPROD
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarMaeLote(maelote): Observable<any[]> {
  
    return this.http.post<any[]>(this.way + '/ventas/lotes/eacad2ae3ae', {      
      elementos: {
        p_lote_numero: maelote.LOTE_NUMERO,
      },
    }, this.confIniciales.httpOptions());
  }
}




