<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encasil"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="encasilConf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar"
						 (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior" [botonPrimero]="botonPrimero"
						 [botonUltimo]="botonUltimo"
						 [botonSiguiente]="botonSiguiente" [barraBotones3]="true" [barraConEncasil]="false"
						 [botonAnular]="btnAnular" [botonBuscar]="btnBuscar" [botonBinocular]="true" [botonAlmacenardoc] = "true">
		</app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<div class="p-col-5"></div>
	<div class="p-col-2" style="margin-bottom: 5px;">
		<input type="text" pInputText style="color: mediumblue; text-align: center; font-weight: bold" disabled
			   [(ngModel)]="conencasilService.encabezadoEncasilSelect.ESTADO">
	</div>
	<div class="p-col-5"></div>
</div>
<p-tabView [style]="{'width':'98%'}">
	<p-tabPanel header="Asiento">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
			<div class="divgrups" id="divPrincipal">
				<div class="p-grid">
					<div class="p-col-2">
						<span>Documento:</span>
					</div>
					<div class="p-col-2">
						<input type="text" pInputText [disabled]="true"
							   [(ngModel)]="conencasilService.encabezadoEncasilSelect.ASI_DOCUMENTOCONT">
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-2">
						<span>Origen:</span>
					</div>
					<div class="p-col-2">
						<div class="p-grid">
							<div class="p-col-5">
								<input type="text" pInputText [disabled]="true"
									   [(ngModel)]="conencasilService.encabezadoEncasilSelect.ASI_ORIGEN">
							</div>
							<div class="p-col-7"></div>
						</div>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-12" style="margin-top: -17px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Nro. Comprobante:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<input id="nrocomprobante" (input)="cambio($event)" type="text" pInputText
											   [(ngModel)]="conencasilService.encabezadoEncasilSelect.ASI_COMPROBANTE"
											   (keydown.enter)="cambiarFoco($event)" autocomplete="off">
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<div class="p-col-2"></div>
							<div class="p-col-2">
								<span>Tipo de Comprobante:</span>
							</div>
							<div class="p-col-3">
								<p-dropdown [style]="{'width':'100%'}" (keydown.enter)="cambiarFoco($event)"
											[(ngModel)]="strTipoDiario"
											optionLabel="name" [options]="cmbTipoDiarios"
											[panelStyle]="{'width':'110%'}" (onChange)="cambioDropdown($event)"
											[readonly]="boolCmbTipDia" inputId="tipoDiarios"
											(keydown.enter)="cambiarFoco($event)">
								</p-dropdown>
							</div>
							<div class="p-col-1"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Fecha:</span>
							</div>
							<div class="p-col-1">
								<p-calendar [(ngModel)]="conencasilService.encabezadoEncasilSelect.ASI_FECHA"
											dateFormat="dd/mm/yy" [inputStyle]="{'width': '100px'}"
											(ngModelChange)="cambio($event)" inputId="Calendario"
											(keydown.enter)="cambiarFoco($event)"></p-calendar>
							</div>
							<div class="p-col-3"></div>
							<div class="p-col-2">
								<span>Numeración por Tipo:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<input type="text" pInputText [disabled]="true"
											   [(ngModel)]="conencasilService.encabezadoEncasilSelect.ASI_NRO">
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<div class="p-col-2"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px; margin-bottom: -15px;">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Observaciones:</span>
							</div>
							<div class="p-col-9">
								<input (input)="cambio($event)" id="observaciones" type="text" pInputText
									   [(ngModel)]="conencasilService.encabezadoEncasilSelect.ASI_DESCRIPCION"
									   (keydown.enter)="cambiarFoco($event)" autocomplete="off">
							</div>
							<div class="p-col-1"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="divg" id="divPrincipal2">
				<app-aggridsaci [width]="1250"
								[height]="largo"
								[enableSorting]="false"
								[enableFilter]="true"
								[rowData]="conencasilService.detencasil"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsConEncasilDet"
								[defaultColDef]="defaultConEncasilDet"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="true"
								[mostrarGuardar]="false"
								[botonesAdicionales]="true"
								[comboOpciones]=""
								[tipodetalle]=""
								(selected)="seleccionDetEncasil($event)"
								(keydown.f7)="aggrid.gridApi.getFocusedCell().column.colId === 'DETASI_HABER' ? insertarLineaDetalle():''"
								(cambios)="cambio($event)"
								[botonNuevo]="btnNuevoGrid"
								[botonGuardar]="btnGuardarGrid"
								[botonBorrar]="btnBorrarGrid"
								[botonRegresar]="btnCancelarGrid"
								[botonCXC]="btnCXCGrid"
								[botonInsertTransBan]="btnTransBanGrid"
								[btnsubirxls]="false"
								[botonDistribCC]="btnDistribCCGrid"
				>
				</app-aggridsaci>
			</div>
			<div class="divgrups">
				<!--			<div class="p-col-12">-->
				<div class="p-grid">
					<div class="p-col-2">
						<span>Concepto Contable: </span>
					</div>
					<div class="p-col-9">
						<input type="text" pInputText [disabled]="true"
							   [(ngModel)]="detalleConEncasilSel.DETASI_CONCEPTO">
					</div>
					<div class="p-col-1"></div>

					<!--			</div>-->
					<div class="p-col-12" style="margin-top: -10px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Concepto de Proyecto: </span>
							</div>
							<div class="p-col-9">
								<input type="text" pInputText [disabled]="true"
									   [(ngModel)]="detalleConEncasilSel.DETASI_CONCEPTOPRY">
							</div>
							<div class="p-col-1"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px;margin-bottom: -15px;">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Cuenta Contable: </span>
							</div>
							<div class="p-col-9">
								<input type="text" pInputText [disabled]="true"
									   [(ngModel)]="detalleConEncasilSel.CON_NOMBRE">
							</div>
							<div class="p-col-1"></div>
						</div>
					</div>
<!--					<div class="p-col-12" style="margin-top: -15px">-->
<!--						<div class="p-grid">-->
<!--							<div class="p-col-5"></div>-->
<!--							<div class="p-col-1">-->
<!--								<span>Proy1: </span>-->
<!--							</div>-->
<!--							<div class="p-col-1">-->
<!--								<input type="text" pInputText [disabled]="true">-->
<!--							</div>-->
<!--							<div class="p-col-1">-->
<!--								<span>Proy3: </span>-->
<!--							</div>-->
<!--							<div class="p-col-1">-->
<!--								<input type="text" pInputText [disabled]="true">-->
<!--							</div>-->
<!--							<div class="p-c ol-1">-->
<!--								<span>Proy5: </span>-->
<!--							</div>-->
<!--							<div class="p-col-1">-->
<!--								<input type="text" pInputText [disabled]="true">-->
<!--							</div>-->
<!--							<div class="p-col-1"></div>-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px;">-->
<!--						<div class="p-grid">-->
<!--							<div class="p-col-5"></div>-->
<!--							<div class="p-col-1">-->
<!--								<span>Proy2: </span>-->
<!--							</div>-->
<!--							<div class="p-col-1">-->
<!--								<input type="text" pInputText [disabled]="true">-->
<!--							</div>-->
<!--							<div class="p-col-1">-->
<!--								<span>Proy4: </span>-->
<!--							</div>-->
<!--							<div class="p-col-1">-->
<!--								<input type="text" pInputText [disabled]="true">-->
<!--							</div>-->
<!--							<div class="p-col-1">-->
<!--								<span>Saldo Pry. Sel: </span>-->
<!--							</div>-->
<!--							<div class="p-col-1">-->
<!--								<input type="text" pInputText [disabled]="true">-->
<!--							</div>-->
<!--							<div class="p-col-1"></div>-->
<!--						</div>-->
<!--					</div>-->
				</div>
			</div>
			<div class="divgrupstabla">
				<!--				<div class="p-col-12">-->
				<div class="p-grid">
					<div class="p-col-1">
						<span class="obligatorio">Débitos: </span>
					</div>
					<div class="p-col-1">
						<input class="totales" type="text" pInputText [disabled]="true"
							   [(ngModel)]="conencasilService.encabezadoEncasilSelect.TOTAL_DEBITOS">
					</div>
					<div class="p-col-1">
						<span class="obligatorio">Créditos: </span>
					</div>
					<div class="p-col-1">
						<input class="totales" type="text" pInputText [disabled]="true"
							   [(ngModel)]="conencasilService.encabezadoEncasilSelect.TOTAL_CREDITOS">
					</div>
					<div class="p-col-1">
						<span class="obligatorio">Saldo: </span>
					</div>
					<div class="p-col-1">
						<input class="totales" type="text" pInputText [disabled]="true"
							   [(ngModel)]="conencasilService.encabezadoEncasilSelect.SALDO">
					</div>
					<div class="p-col-2">
						<p-colorPicker [disabled]="true" [(ngModel)]="choosedColorSB"></p-colorPicker>
						<span class="obligatorio">Trans. Caja y Bancos </span>
					</div>
					<div class="p-col-1">
						<p-colorPicker [disabled]="true" [(ngModel)]="choosedColorG"></p-colorPicker>
						<span class="obligatorio">CXC</span>
					</div>
					<div class="p-col-2">
						<p-colorPicker [disabled]="true" [(ngModel)]="choosedColorGb"></p-colorPicker>
						<span class="obligatorio">Conc./Dep. </span>
					</div>
					<div class="p-col-1"></div>
				</div>
				<!--				</div>-->
			</div>
		</p-scrollPanel>
	</p-tabPanel>
</p-tabView>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBus"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [closable]="false"
		  [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones($event)">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
				   [dismissible]="false" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="abrirClave('eliminarAsi')"
																		 class="archivoOpc">Eliminar el asiento correspondiente a otro origen</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span
								(click)="activarAsientoAnulado('actDocAnulado')" class="archivoOpc">Activar documento anulado</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span
								(click)="copiarFactura()" class="archivoOpc">Copiar Asiento</span>
						</div>
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span
								(click)="distribuisPCC()" class="archivoOpc">Aplicar distribución por programas en registro contable</span>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<p-dialog header="Clave de Autorización" [(visible)]="claveDialog"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: 'auto'} ">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							   (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Movimiento de Bancos" [(visible)]="displayDialogBanTrnBan"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'80vw', height:'120vh'}"
		  [draggable]="false"
		  [closable]="false">
	<app-bantrnbanedit *ngIf="displayDialogBanTrnBan"
					   [banCodigo]="banCodigoTrnBan" [fechaEmision]="fechaEmisionTrnBan" [org]="origenTrnBan"
					   [tipo]="tipoTrnBan"
					   [an]="anuladoTrnBan" [numero]="trnbanNumeroTrnBan" [referencia]="referenciaTrnBan"
					   [concepto]="conceptoTrnBan" [importe]="importeTrnBan" [beneficiario]="beneficiarioTrnBan"
					   [numDoc]="nroDocumentoTrnBan" [concilia]="conciliaTrnBan" [fechaconta]="fechaVencimientoTrnBan"
					   [asiNum]="asiNroTrnBan" [numConcilia]="numeroConciliacionTrnBan"
					   [registroContable]="regContableTrnBan"
					   [asiento]="asientoTrnBan" (signalSalir)="recibirSenalesBanTrnban($event)"
					   [tipoAccion]="tipoAccionTrnBan"
					   [isBandera]="true"
	></app-bantrnbanedit>
</p-dialog>
<p-dialog header="Distribución de costos" [(visible)]="displayCentroCostos"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width:'70vw', height:'70vh'}"
		  [draggable]="false">
	<app-contrndistribucionpcc *ngIf="displayCentroCostos" [strAsiNro]="strAsiNroDCC"
							   [intDetasiNroFila]="intDetasiNroFilaDCC" [strCodigo]="strCodigoDCC"
							   [dblValor]="dblValorDCC" [strDescripcion]="strDescripcionDCC"
							   [strTipoDet]="strTipoDetDCC"
							   (signalSalir)="getSenalesDistribCC($event)"></app-contrndistribucionpcc>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
						 [strNumDoc]="conencasilService.encabezadoEncasilSelect.ASI_NRO"
						 [strCampo]="'ASI_NRO'" (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<div style=" 	position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
</p-dialog>
</div>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'CONENCASI'" [txtNumComprobante] = "conencasilService.encabezadoEncasilSelect.ASI_NRO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
 

			<input  #inputFile type="file" style="display: none; opacity: 0.0; position: absolute; 
			top: 0; left: 0; bottom: 0; right: 0; width: 25px; height: 25px; cursor: pointer;"
			accept=".xls, .xlsx"
                (change)="onBasicUpload(inputFile.files)" />