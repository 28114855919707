<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encordpro"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarencordpro" title="Pregunta" appendTo="body">
</p-confirmDialog>
<div class="layout-config">
    <div class="layout-config-content-wrapper">
        <a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
            <i class="pi pi-bars" style="cursor: pointer"></i>
        </a>
        <p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
            [dismissible]="true" [showCloseIcon]="false">
            <br>
            <br>
            <br>
            <p-tabView>
                <p-tabPanel header="Acciones">
                    <div class="p-grid">
                        <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
                            <div class="p-col-12">
                                <i class="pi pi-caret-right archivoOpc"></i><span (click)="activarOrden()"
                                    class="archivoOpc">Activar Orden de Produccion</span>
                            </div>
                            <div class="p-col-12">
                                <i class="pi pi-caret-right archivoOpc"></i><span (click)="abrircontrolcalidad()"
                                    class="archivoOpc">Certificado de Calidad</span>
                            </div>
                            <div class="p-col-12">
                                <i class="pi pi-caret-right archivoOpc"></i><span (click)="nuevodetalle()"
                                    class="archivoOpc">Nueva Línea de detalle</span>
                            </div>
                            <div class="p-col-12">
                                <i class="pi pi-caret-right archivoOpc"></i><span (click)="editarDetalle()"
                                    class="archivoOpc">Editar Detalle</span>
                            </div>
                            <div class="p-col-12">
                                <i class="pi pi-caret-right archivoOpc"></i><span (click)="eliminarLineaDetalle()"
                                    class="archivoOpc">Eliminar Línea de detalle</span>
                            </div>
                            <div class="p-col-12">
                                <i class="pi pi-caret-right archivoOpc"></i><span (click)="calcularHorasHombre()"
                                    class="archivoOpc">Calcular Horas Hombre</span>
                            </div>
                        </p-scrollPanel>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </p-sidebar>
    </div>
</div>
<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra (signal)="manejarSenales($event)" [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="false"
            [botonBorrar]="!botonBorrar" [botonNuevo]="!botonNuevo" [botonGuardar]="!botonGuardar"
            [botonRegresar]="!botonRegresar" [botonPrimero]="!botonPrimero" [botonAnterior]="!botonAnterior"
            [botonSiguiente]="!botonSiguiente" [botonUltimo]="!botonUltimo" [barraBotones5]="false"
            [barraOrdenProd]="false" [botonAnular]="!botonAnular" [botonAsiento]="false" [botonverAsiento]="false"
            [botonBuscar]="false" [botonBinocular]="true" [botonAlmacenardoc]="true"></app-invmaebarra>
    </div>
</div>
<div class="p-grid" style="margin-top: -12px; margin-bottom: -15px; width: 99%;">
    <!--LINEA 1-->
    <div class="p-col-2">
        <label for="txtNumero"></label>
        <input class="frm" id="txtNumero" type="text" pInputText
            style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
            [disabled]="true" [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_NUMERO"
            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
    </div>
    <div class="p-col-2">
        <label for="txtGrupo"></label>
        <input *ngIf="vistxtgrupo" class="frm" id="txtGrupo" type="text" pInputText
            style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
            [disabled]="true" [(ngModel)]="GRUPO" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
            autocomplete="off">
    </div>
    <div class="p-col-2">
        <div class="p-grid">
            <div class="p-col-8">
                <label for="lblEstado"></label>
                <input class="frm" id="lblEstado" type="text" pInputText
                    style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                    [disabled]="true" [(ngModel)]="ESTADO" (keydown.enter)="setFocus($event)"
                    (keydown)="botonesmodificar()" autocomplete="off">
            </div>
            <div class="p-col-4">
                <label for="txtEstado"></label>
                <input *ngIf="vistxtestado" class="frm" id="txtEstado" type="text" pInputText
                    style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                    [disabled]="true" [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_ESTADO"
                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
            </div>
        </div>
    </div>
    <div class="p-col-1">
    </div>
    <div class="p-col-4">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="txtUsuarioModulo"></label>
                <input class="frm" id="txtUsuarioModulo" type="text" pInputText
                    style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                    [disabled]="true" [(ngModel)]="USUARIO" (keydown.enter)="setFocus($event)"
                    (keydown)="botonesmodificar()" autocomplete="off">
            </div>
            <div class="p-col-4">
                <label for="txtFecha"></label>
                <input class="frm" id="txtFecha" type="text" pInputText
                    style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                    [disabled]="true" [(ngModel)]="FECHA" (keydown.enter)="setFocus($event)"
                    (keydown)="botonesmodificar()" autocomplete="off">
            </div>
            <div class="p-col-4">
                <label for="txtHora"></label>
                <input class="frm" id="txtHora" type="text" pInputText
                    style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                    [disabled]="true" [(ngModel)]="HORA" (keydown.enter)="setFocus($event)"
                    (keydown)="botonesmodificar()" autocomplete="off">
            </div>
        </div>
    </div>
    <div class="p-col-1">
    </div>
</div>

<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()" [style]="{'width':'98%'}">
    <p-tabPanel header="Orden de Producción">
        <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
            <!--LINEA 1-->
            <div class="divgrups">
                <div class="p-grid" style="margin-bottom: -10px;">
                    <div class="p-col-1">
                        <span>Procedimiento:</span>
                    </div>
                    <div class="p-col-2">
                        <p-dropdown [options]="cmbProcedimiento" [style]="{'minWidth': '100%', 'width':'100%'}"
                            optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectProcedimiento"
                            (onChange)="busqueda($event)"></p-dropdown>
                    </div>
                    <div class="p-col-2">
                        <span>Ingreso del producto a bodega:</span>
                    </div>
                    <div class="p-col-2">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <p-checkbox name="chbtnIngreso" value="" label="" binary="{{chbtnIngreso}}"
                                    [(ngModel)]="chbtnIngreso" (click)="ingresochbtnIngreso()">
                                </p-checkbox>
                            </div>
                            <div class="p-col-6">
                                <p-calendar id="dateTimeIngreso"
                                    [disabled]="!chbtnIngreso || encordproService.encordpro[indicador].ENCORDPRO_ESTADO === 'I'"
                                    [inputStyle]="{width: '100%', height: '20px'}"
                                    [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_FECHAENTRADA"
                                    [readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                    (keydown)="botonesmodificar()" (onSelect)="botonesmodificar()">
                                </p-calendar>
                            </div>
                            <div class="p-col-4">
                                <p-calendar id="dateTimeHoraIngreso"
                                    [disabled]="!chbtnIngreso || encordproService.encordpro[indicador].ENCORDPRO_ESTADO === 'I'"
                                    [inputStyle]="{width: '80%', height: '20px'}" [timeOnly]="true"
                                    [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_FECHAENTRADAH"
                                    [readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                    (keydown)="botonesmodificar()" (onSelect)="botonesmodificar()">
                                </p-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-1">
                    </div>
                    <div class="p-col-2">
                        <span>Asiento Contable:</span>
                    </div>
                    <div class="p-col-2">
                        <p-dropdown [options]="cmbAsiento" [style]="{'minWidth': '100%', 'width':'100%'}"
                            optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectAsiento"
                            (onChange)="busqueda($event)"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="contorno" id="contorno">
                <div class="divgrups">
                    <div class="p-grid">
                        <!--LINEA 2-->
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Comprobante:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="txtReferencia"></label>
                                    <input class="frm" id="txtReferencia" type="text" pInputText
                                        [disabled]="!enabledtxtReferencia"
                                        [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_REFERENCIA"
                                        (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                        autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                </div>
                                <div class="p-col-1">
                                    <span>Tipo Producción:</span>
                                </div>
                                <div class="p-col-2">
                                    <p-dropdown [options]="cmbTipoProd" [style]="{'minWidth': '100%', 'width':'100%'}"
                                        optionLabel="name" [panelStyle]="{'width':'110%'}"
                                        [(ngModel)]="encordproService.selectTipoProd[indicador]"
                                        (onChange)="changetipoprod($event)"></p-dropdown>

                                </div>
                                <div class="p-col-1">
                                    <span>Lote:</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="txtLote"></label>
                                    <input class="frm" id="txtLote" type="text" pInputText
                                        [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_LOTE"
                                        (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                        autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Pedido:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-9">
                                            <label for="txtPedido"></label>
                                            <input class="frm" id="txtPedido" type="text" pInputText
                                                [(ngModel)]="encordproService.encordpro[indicador].ENCPED_NUMERO"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                autocomplete="off">
                                        </div>
                                        <div class="p-col-3">
                                            <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busquedapedido(encordproService.encordpro[indicador].ENCPED_NUMERO)"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--LINEA 3-->
                        <div class="p-col-12" style="margin-top: -27px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Artículo:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-9">
                                            <label for="txtArticulo"></label>
                                            <input class="frm" id="txtArticulo" type="text" pInputText
                                                [(ngModel)]="encordproService.encordpro[indicador].ART_CODIGO"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                autocomplete="off">
                                        </div>
                                        <div class="p-col-3">
                                            <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busquedaarticulo(encordproService.encordpro[indicador].ART_CODIGO)"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4">
                                    <div class="p-grid">
                                        <div class="p-col-10">
                                            <span
                                                style="color: rgb(0, 0,255);">{{encordproService.encordpro[indicador].ART_NOMBRE}}</span>
                                        </div>
                                        <div class="p-col-2">
                                            <div class="p-grid">
                                                <div class="p-col-9">
                                                    <label for="txtUnidad"></label>
                                                    <input class="frm" id="txtUnidad" type="text" pInputText disabled
                                                        [(ngModel)]="encordproService.encordpro[indicador].UNI_CODIGO"
                                                        (keydown.enter)="setFocus($event)"
                                                        (keydown)="botonesmodificar()" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-1">
                                    <div class="p-grid">
                                        <div class="p-col-9">
                                            <span>Vencimiento:</span>
                                        </div>
                                        <div class="p-col-3">
                                            <p-checkbox name="chbtnVencLote" value="" label=""
                                                binary="{{chbtnVencLote}}" [(ngModel)]="chbtnVencLote"
                                                (click)="ingresochbtnVencLote()">
                                            </p-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-1">
                                    <p-calendar id="dateTimeFechaVencLote" [disabled]="!chbtnVencLote"
                                        [inputStyle]="{width: '100%', height: '20px'}"
                                        [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_VENCIMIENTOLOTE"
                                        [readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
                                        (keydown)="botonesmodificar()" (onSelect)="botonesmodificar()">
                                    </p-calendar>
                                </div>
                                <div class="p-col-1">
                                    <span>Cliente:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-9">
                                            <label for="txtUnidad"></label>
                                            <input class="frm" id="txtUnidad" type="text" pInputText disabled
                                                [(ngModel)]="encordproService.encordpro[indicador].CLIENTEPEDIDO"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--LINEA 4-->
                        <div class="p-col-12" style="margin-top: -27px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Cantidad:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-9">
                                            <label for="txtCantidad"></label>
                                            <input class="frm" id="txtCantidad" type="text" pInputText
                                                style="text-align: right; font-weight: bold;"
                                                [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_CANTIDAD"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                autocomplete="off" appFourDigitDecimalNumber>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-1">
                                </div>
                                <div class="p-col-1">
                                    <span>Emisión:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-2"></div>
                                        <div class="p-col-6">
                                            <p-calendar id="dateTimeFecha"
                                                [inputStyle]="{width: '100%', height: '20px'}"
                                                [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_FECHAEMISION"
                                                [readonlyInput]="true" dateFormat="dd/mm/yy"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                (onSelect)="botonesmodificar()">
                                            </p-calendar>
                                        </div>
                                        <div class="p-col-4">
                                            <p-calendar id="dateTimeHora" [inputStyle]="{width: '80%', height: '20px'}"
                                                [timeOnly]="true"
                                                [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_FECHAEMISIONH"
                                                [readonlyInput]="false" dateFormat="dd/mm/yy"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                (onSelect)="botonesmodificar()">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-2"></div>
                                <div class="p-col-1">
                                    <span>Fórmula:</span>
                                </div>
                                <div class="p-col-2">
                                    <p-dropdown [options]="cmbProceso" [style]="{'minWidth': '100%', 'width':'100%'}"
                                        optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectProceso"
                                        (onChange)="changeProceso()"></p-dropdown>
                                </div>
                            </div>
                        </div>
                        <!--LINEA 5-->
                        <div class="p-col-12" style="margin-top: -20px">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Bodega PT:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-9">
                                            <label for="txtBodegaPT"></label>
                                            <input class="frm" id="txtBodegaPT" type="text" pInputText
                                                [(ngModel)]="encordproService.encordpro[indicador].BOD_CODIGO_ENT"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                autocomplete="off">
                                        </div>
                                        <div class="p-col-3">
                                            <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busquedabodega(encordproService.encordpro[indicador].BOD_CODIGO_ENT,'pt')"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-1">
                                </div>
                                <div class="p-col-1">
                                    <span>Inicio:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <p-checkbox name="chbtnInicio" value="" label="" binary="{{chbtnInicio}}"
                                                [(ngModel)]="chbtnInicio" (click)="ingresochbtnInicio()">
                                            </p-checkbox>
                                        </div>
                                        <div class="p-col-6">
                                            <p-calendar id="dateTimeInicio" [disabled]="!chbtnInicio"
                                                [inputStyle]="{width: '100%', height: '20px'}"
                                                [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_FECHAINICIO"
                                                [readonlyInput]="true" dateFormat="dd/mm/yy"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                (onSelect)="botonesmodificar()">
                                            </p-calendar>
                                        </div>
                                        <div class="p-col-4">
                                            <p-calendar id="dateTimeHoraInicio" [disabled]="!chbtnInicio"
                                                [inputStyle]="{width: '80%', height: '20px'}" [timeOnly]="true"
                                                [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_HORAINI"
                                                [readonlyInput]="false" dateFormat="dd/mm/yy"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                (onSelect)="botonesmodificar()">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-2"></div>
                                <div class="p-col-1">
                                    <span>Operador:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="txtOpe"></label>
                                    <input class="frm" id="txtOpe" type="text" pInputText
                                        [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_OPERARIO"
                                        (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                        autocomplete="off">
                                </div>
                            </div>
                        </div>

                        <!--LINEA 6-->
                        <div class="p-col-12" style="margin-top: -27px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Bodega MP:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-9">
                                            <label for="txtBodegaMP"></label>
                                            <input class="frm" id="txtBodegaMP" type="text" pInputText
                                                [(ngModel)]="encordproService.encordpro[indicador].BOD_CODIGO"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                autocomplete="off">
                                        </div>
                                        <div class="p-col-3">
                                            <button type="button"
                                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                                (click)="busquedabodega(encordproService.encordpro[indicador].BOD_CODIGO,'mp')"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-1"></div>
                                <div class="p-col-1">
                                    <span>Término:</span>
                                </div>
                                <div class="p-col-2">
                                    <div class="p-grid">
                                        <div class="p-col-2">
                                            <p-checkbox name="chbtnTermino" value="" label="" binary="{{chbtnTermino}}"
                                                [(ngModel)]="chbtnTermino" (click)="ingresochbtnTermino()">
                                            </p-checkbox>
                                        </div>
                                        <div class="p-col-6">
                                            <p-calendar id="dateTimeTermino" [disabled]="!chbtnTermino"
                                                [inputStyle]="{width: '100%', height: '20px'}"
                                                [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_FECHAFIN"
                                                [readonlyInput]="true" dateFormat="dd/mm/yy"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                (onSelect)="botonesmodificar()">
                                            </p-calendar>
                                        </div>
                                        <div class="p-col-4">
                                            <p-calendar id="dateTimeHoraTermino" [disabled]="!chbtnTermino"
                                                [inputStyle]="{width: '80%', height: '20px'}" [timeOnly]="true"
                                                [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_HORAFIN"
                                                [readonlyInput]="false" dateFormat="dd/mm/yy"
                                                (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                                (onSelect)="botonesmodificar()">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-1">
                                    <span>Horas Hombre:</span>
                                </div>
                                <div class="p-col-1">
                                    <label for="txtHorasHombre"></label>
                                    <input class="frm" id="txtHorasHombre" [disabled]="!chbtnTermino" type="number"
                                        pInputText style="text-align: right;"
                                        [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_HORASHOMBRE"
                                        (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                        autocomplete="off">
                                </div>
                                <div class="p-col-1">
                                    <span>Equipo:</span>
                                </div>
                                <div class="p-col-2">
                                    <label for="txtEquipo"></label>
                                    <input class="frm" id="txtEquipo" type="text" pInputText
                                        [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_EQUIPO"
                                        (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                        autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <!--LINEA 7-->
                        <div class="p-col-12" style="margin-top: -27px; margin-bottom: -25px;">
                            <div class="p-grid">
                                <div class="p-col-1">
                                    <span>Observ.:</span>
                                </div>
                                <div class="p-col-8">
                                    <label for="txtObservacion"></label>
                                    <input class="frm" id="txtObservacion" type="text" pInputText
                                        [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_COMENTARIO"
                                        (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                        autocomplete="off">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div id="contornodetalle">-->
            <app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
                [rowData]="encordproService.detordpro[indicador]" [frameworkComponents]="frameworkComponents"
                [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
                (selected)="selecciondetalle($event)" [mostrarGuardar]="false" [botonesAdicionales]="true"
                (cambios)="cambio($event)" [btnInsertarLotes]="false"></app-aggridsaci>
            <!--</div>-->
            <div class="divgrups" style="margin-top: -10px;">
                <div class="p-grid" style="margin-bottom: -10px;">
                    <div class="p-col-2">
                        <span>No hay en Existencia</span>
                    </div>
                    <div class="p-col-1" style="margin-top: 10px; height: 25%; background-color: rgb(253, 163, 230)">
                    </div>
                    <div class="p-col-1"></div>
                    <div class="p-col-4">
                        <span>Artículos que no pertenecen a la formulación del artículo</span>
                    </div>
                    <div class="p-col-1" style="margin-top: 10px; height: 25%; background-color: rgb(133, 207, 37)">
                    </div>
                </div>
            </div>
            <div class="divgrupstabla" style="margin-top: -5px;">
                <div class="p-grid" style="margin-bottom: -20px;">
                    <div class="p-col-12">
                        <div class="p-grid">                            
                            <div class="p-col-1"></div>
                            <div class="p-col-2">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <span>Total Costo MP</span>
                                    </div>
                                    <div class="p-col-6">
                                        <label for="txtTotalCostoMP"></label>
                                        <input class="totales" id="txtTotalCostoMP" type="text" pInputText disabled
                                            [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_TOTALCOSTOMP"
                                            autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-2">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <span>Total Costo Ind.</span>
                                    </div>
                                    <div class="p-col-6">
                                        <label for="txtTotalCostoInd"></label>
                                        <input class="totales" id="txtTotalCostoInd" type="text" pInputText disabled
                                            [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_TOTALCOSTOIND"
                                            autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-2">
                                <div class="p-grid">
                                    <div class="p-col-7">
                                        <span>Total Horas Hombre</span>
                                    </div>
                                    <div class="p-col-5">
                                        <label for="totalhorashombre"></label>
                                        <input class="totales" id="totalhorashombre" type="text" pInputText disabled
                                            [(ngModel)]="encordproService.totalhorashombre[indicador]"
                                            autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-2">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <span>Total Costo Prom.</span>
                                    </div>
                                    <div class="p-col-6">
                                        <label for="txtTotalCostoProm"></label>
                                        <input class="totales" id="txtTotalCostoProm" type="text" pInputText disabled
                                            [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_TOTALCOSTOPRM"
                                            autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-2">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <span>Total Producido</span>
                                    </div>
                                    <div class="p-col-6">
                                        <label for="txtTotalProducido"></label>
                                        <input class="totales" id="txtTotalProducido" type="text" pInputText disabled
                                            [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_TOTALPRODUCIDO"
                                            autocomplete="off">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-scrollPanel>
    </p-tabPanel>
    <p-tabPanel header="Costos Indirectos">
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
            [rowData]="encordproService.trncostosindirectos[indicador]" [frameworkComponents]="frameworkComponents"
            [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefscostoind"
            [defaultColDef]="defaultColDefcostoind" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
            (selected)="seleccioncostoindirecto($event)" [mostrarGuardar]="false" [botonesAdicionales]="true"
            (cambios)="cambio($event)"></app-aggridsaci>
        <div class="p-grid">
            <div class="p-col-12">
                <span style="color: rgb(0, 0,255);">Seleccione los Costos Indirectos que va a intervenir en el Asiento
                    del Producto en Proceso</span>
            </div>
            <div class="p-col-12">
                <span style="color: rgb(0, 0,255);">Los Costos indirectos que no están seleccionados serán considerados
                    en el Asiento del Producto Terminado</span>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Costo Horas Hombre">
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
            [rowData]="encordproService.trnhorashombre[indicador]" [frameworkComponents]="frameworkComponents"
            [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefshorashombre"
            [defaultColDef]="defaultColDefhorashombre" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
            (selected)="seleccionhorahombre($event)" [mostrarGuardar]="false" [botonesAdicionales]="true"
            (cambios)="cambio($event)"></app-aggridsaci>
        <div class="p-grid">
            <div class="p-col-9"></div>
            <div class="p-col-1">
                <span style="color: rgb(0, 0,255);">Total:</span>
            </div>
            <div class="p-col-1">
                <span>{{encordproService.totalhorashombre[indicador]}}</span>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Pedidos">
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
            [rowData]="encordproService.trnpedidos[indicador]" [frameworkComponents]="frameworkComponents"
            [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefspedido"
            [defaultColDef]="defaultColDefpedido" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
            (selected)="seleccionpedido($event)" [mostrarGuardar]="false" [botonesAdicionales]="true"
            (cambios)="cambio($event)"></app-aggridsaci>
    </p-tabPanel>
    <p-tabPanel header="{{tabItemLiquidacion}}" [disabled]="tabItemLiquidacion===''">
        <div class="p-grid">
            <div class="p-col-10"></div>
            <div class="p-col-2">
                <button class="sincss" pButton (click)="reversarliquidacion()">Reversar Liquidación
                </button>
            </div>
        </div>
        <div class="divgrups">
            <div class="p-grid" style="margin-bottom: -10px;">
                <div class="p-col-2">
                    <span>Término:</span>
                </div>
                <div class="p-col-2">
                    <p-calendar id="dateTimeInicio" [disabled]="!chbtnInicio"
                        [inputStyle]="{width: '100%', height: '20px'}"
                        [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_FECHAINICIO" [readonlyInput]="true"
                        dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                        (onSelect)="botonesmodificar()">
                    </p-calendar>
                </div>

                <div class="p-col-1">
                    <span>Hora:</span>
                </div>
                <div class="p-col-1">
                    <p-calendar id="dateTimeHoraInicio" [disabled]="!chbtnInicio"
                        [inputStyle]="{width: '80%', height: '20px'}" [timeOnly]="true"
                        [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_HORAINI" [readonlyInput]="false"
                        dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                        (onSelect)="botonesmodificar()">
                    </p-calendar>
                </div>
                <div class="p-col-6"></div>
                <div class="p-col-2">
                    <button class="sincss" pButton (click)="reversarliquidacion()">Procesar Liquidación
                    </button>
                </div>
            </div>
        </div>
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
            [rowData]="encordproService.trnliquidacion[indicador]" [frameworkComponents]="frameworkComponents"
            [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsliquidacion"
            [defaultColDef]="defaultColDefliquidacion" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
            (selected)="selecciondetalle($event)" [mostrarGuardar]="false" [botonesAdicionales]="true"
            (cambios)="cambio($event)"></app-aggridsaci>
    </p-tabPanel>
    <p-tabPanel header="{{tabItemAdicionales}}" [disabled]="tabItemAdicionales===''">
        <div class="divgrups">
            <div class="p-grid">
                <!--LINEA 1-->
                <div class="p-col-12">
                    <div class="p-grid"></div>
                    <div class="p-col-2">
                        <span>Temperatura:</span>
                    </div>
                    <div class="p-col-2">
                        <label for="txtTemperatura"></label>
                        <input class="frm" id="txtTemperatura" type="text" pInputText
                            [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_TEMPERATURA"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                    </div>
                </div>
                <div class="p-col-12">
                    <div class="p-grid"></div>
                    <div class="p-col-2">
                        <span>Solvente cantidad en KG:</span>
                    </div>
                    <div class="p-col-2">
                        <label for="txtSolvente"></label>
                        <input class="frm" id="txtSolvente" type="text" pInputText
                            [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_SOLVENTE"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                    </div>
                </div>
                <div class="p-col-12">
                    <div class="p-grid"></div>
                    <div class="p-col-2">
                        <span>Tiempo de Mezcla:</span>
                    </div>
                    <div class="p-col-2">
                        <label for="txtMezcla"></label>
                        <input class="frm" id="txtMezcla" type="text" pInputText
                            [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_MEZCLA"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                    </div>
                </div>
                <div class="p-col-12">
                    <div class="p-grid"></div>
                    <div class="p-col-2">
                        <span>Tipo de solvente:</span>
                    </div>
                    <div class="p-col-2">
                        <label for="txtTipoSolvente"></label>
                        <input class="frm" id="txtTipoSolvente" type="text" pInputText
                            [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_TIPOSOLVENTE"
                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                    </div>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="{{tabItemTecnicos}}" [disabled]="tabItemTecnicos===''">
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
            [rowData]="encordproService.trntecnicos[indicador]" [frameworkComponents]="frameworkComponents"
            [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefstecnico"
            [defaultColDef]="defaultColDeftecnico" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
            (selected)="selecciontecnico($event)" [mostrarGuardar]="false" [botonesAdicionales]="true"
            (cambios)="cambio($event)"></app-aggridsaci>
    </p-tabPanel>
    <p-tabPanel header="{{tabItemMaquinaria}}" [disabled]="tabItemMaquinaria===''">
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
            [rowData]="encordproService.trnmaquinaria[indicador]" [frameworkComponents]="frameworkComponents"
            [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsmaquinaria"
            [defaultColDef]="defaultColDefmaquinaria" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
            (selected)="seleccionmaquinaria($event)" [mostrarGuardar]="false" [botonesAdicionales]="true"
            (cambios)="cambio($event)"></app-aggridsaci>
    </p-tabPanel>
    <!-- CDPJ -->
    <p-tabPanel header="{{tabProfabri}}"  [disabled]="tabProfabri===''">
        <div class="divgrups">
            <div class="p-grid">
                <div class="p-col-2">
                    <button class="sincss" pButton (click)="LlenarDatosOPEnProcFab()">Agregar datos orden de producción
                    </button>
                </div>
                <div class="p-col-10">
                    
                </div>
            </div>
        </div>
        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="false"
            [rowData]="encordproService.profabricacion[indicador]" [frameworkComponents]="frameworkComponents" [animateRows]="true"
            [rowSelection]="rowSelection" [columnDefs]="columnDefsProfab" [defaultColDef]="defaultColDefProfab"
            [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" [botonesAdicionales]="true"
            (selected)="seleccionDetProFabri($event)" [mostrarGuardar]="false"
            (cambios)="cambio($event)"></app-aggridsaci>
    </p-tabPanel>
    <!-- ---------------------------------------------------------Fin Proceso Fabricación------------------------------------------- -->

    <!-- --------------------------------------------------------- Control Proceso------------------------------------------- -->
		<p-tabPanel header="{{tabprocontrol}}" [disabled]="tabprocontrol===''">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-2">
                        <button class="sincss" pButton (click)="LlenarDatosOPEnProcControl()">Agregar datos del maestro de artículos
                        </button>
					</div>
					<div class="p-col-10">
						
					</div>
				</div>
			</div>
			<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
				[rowData]="encordproService.procontrol[indicador]" [frameworkComponents]="frameworkComponents" [animateRows]="true"
				[rowSelection]="rowSelection" [columnDefs]="columnDefsProcontrol" [defaultColDef]="defaultColDefProcont"
				[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" [botonesAdicionales]="true"
                (selected)="seleccionDetProcontrol($event)" [mostrarGuardar]="false"
                (cambios)="cambio($event)"></app-aggridsaci>
		</p-tabPanel>
		<!-- ---------------------------------------------------------Fin Control Proceso------------------------------------------- -->
</p-tabView>

<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
        [consulta]="consulta" [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)">
    </app-busquedadlg>
</p-dialog>

<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
    <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
    <div class="divgrups">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-1"></div>
                    <div class="p-col-4">
                        <label for="clave" style="font-weight: bold">Clave de Autorización:</label>
                    </div>
                    <div class="p-col-2"></div>
                    <div class="p-col-4">
                        <input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
                            (keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
                    </div>
                    <div class="p-col-1"></div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Clave Supervisor" [(visible)]="displayDialogSupervisor" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" (onHide)="onHideSupervisor()" [style]="{width: '40vw', height: 'auto'} ">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1"></div>
                <div class="p-col-4">
                    <label for="usuariosup" style="font-weight: bold">Usuario:</label>
                </div>
                <div class="p-col-2"></div>
                <div class="p-col-4">
                    <input pInputText id="usuariosup" [(ngModel)]="usuarioSup" placeholder="Ingrese el usuario">
                </div>
                <div class="p-col-1"></div>
                <div class="p-col-1"></div>
                <div class="p-col-4">
                    <label for="clavesup" style="font-weight: bold">Clave:</label>
                </div>
                <div class="p-col-2"></div>
                <div class="p-col-4">
                    <input type="password" pInputText id="clavesup" [(ngModel)]="claveSup"
                        (keydown.enter)="validarClave()" placeholder="Ingrese la clave">
                </div>
                <div class="p-col-1"></div>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
    <app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'prodencordpro'"
        (signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '40vw', height: '400px'}">
    <app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
        [strNumDoc]="encordproService.encordpro[indicador].ENCORDPRO_NUMERO" [strCampo]="'ENCORDPRO_NUMERO'"
        (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Ingrese la razón por la que va ha reversar la entrada del producto"
    [(visible)]="displayDialogObservacion" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
    [style]="{width: '40vw', height: '165px'}">
    <div class="p-grid" style="width: 99%">
        <div class="p-col-12">
            <input pInputText id="str_observacion" [(ngModel)]="str_observacion" placeholder="Ingrese la razón"
                (keydown.enter)="ReversarEntrada2(encordproService.encordpro[indicador].ENCORDPRO_NUMERO)">
        </div>
    </div>
</p-dialog>

<p-dialog header="Certificado de Calidad" [(visible)]="displayControlCalidad" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '65vw', height: 'auto'}" (onHide)="cerrarControlCalidad()">
    <app-prodcontrolcalidad [auxiliar]="auxiliar" [EncOrdPro]="encordproService.encordpro[indicador].ENCORDPRO_NUMERO"
        [ArtCodigo]="encordproService.encordpro[indicador].ART_CODIGO"
        [ArtNombre]="encordproService.encordpro[indicador].ART_NOMBRE" (cerrar)="cerrarControlCalidad()"
        [indicador]="indicador">
    </app-prodcontrolcalidad>
</p-dialog>

<p-dialog header="Lotes" [(visible)]="displayDialogLotes" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
    [style]="{width: '50vw', height: 'auto'}">
    <app-invtrnlote [auxiliar]="auxiliarlot" [detalle]="detordproSelected" [encabezado]="encordproService.encordpro[indicador]"
        [cadena]="'ORDPROD'" (salir)="cerrarLotes()"></app-invtrnlote>
</p-dialog>

<p-dialog header="Lotes" [(visible)]="displayDialogLotesMP" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
    [style]="{width: '50vw', height: 'auto'}">
    <div class="p-grid" style="width: 99%">
        <div class="p-col-12">
            <app-invmaebarra [botonNuevo]="botonNuevoL" [botonGuardar]="botonGuardarL" [botonRegresar]="botonRegresarL"
                (signal)="manejarSenalesLotes($event)" [barraBotonesDesplaz]="barraBotones2"
                [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="barraBotones2" [barraBotones5]="true"
                [botonBorrar]="botonBorrarL"></app-invmaebarra>
        </div>
        <div class="p-col-12" style="margin-top: -15px">
            <div class="p-grid">
                <div class="p-col-8"></div>
                <div class="p-col-4">
                    <label for="lblEncOrdProd"></label>
                    <input class="frm" id="lblEncOrdProd" type="text" pInputText
                        style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                        [disabled]="true" [(ngModel)]="encordproService.encordpro[indicador].ENCORDPRO_NUMERO"
                        (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                </div>
            </div>
        </div>
        <div class="p-col-12" style="margin-top: -15px">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="lblCodArticulo"></label>
                    <input class="frm" id="lblCodArticulo" type="text" pInputText
                        style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                        [disabled]="true" [(ngModel)]="detordproSelected.ART_CODIGO" (keydown.enter)="setFocus($event)"
                        (keydown)="botonesmodificar()" autocomplete="off">
                </div>
                <div class="p-col-8">
                    <label for="lblNombreArticulo"></label>
                    <input class="frm" id="lblNombreArticulo" type="text" pInputText
                        style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                        [disabled]="true" [(ngModel)]="detordproSelected.DETORDPRO_DESCRIPCION"
                        (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
                </div>
            </div>
        </div>
        <div style="overflow: hidden" class="p-col-12" style="margin-top: -15px">
            <app-aggridsaci [width]="" [height]="largol" [enableSorting]="false" [enableFilter]="true"
                [rowData]="encordproService.lotes[indicador]" [frameworkComponents]="frameworkComponents"
                [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsLotes"
                [defaultColDef]="defaultColDefLote" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false"
                (selected)="seleccionLotes($event)" (cambios)="cambiol($event)"></app-aggridsaci>
        </div>
    </div>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '50vw', height: 'auto'}">
    <app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'PRODENCORDPROD'"
        [txtNumComprobante]="encordproService.encordpro[indicador].ENCORDPRO_NUMERO"
        (signalSalir)="recibirSenalesDocumentos($event)">

    </app-uploadfiles>
</p-dialog>

<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
    </p-progressSpinner>
    <p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>	
</div>
<p-dialog header="Código de seguridad" [(visible)]="displayToken" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [closable]="true" [showHeader]="true">
	<p-toast [style]="{marginTop: '35vh'}" position="top-center" key="prodUsuToken"></p-toast>
	<div style="background-color: rgb(235,245,251); padding: 1.5em;">
		<div class="p-col-12" style="text-align: center">
			<input style="font-size: 25pt; width: 150px; height: 60px;" type="password" [(ngModel)]="tokenusuario"
				(keydown)="enterToken($event)" pInputText autocomplete="off">
		</div>
		<div class="p-col-12" style="text-align: center">
			<div class="p-grid">
				<div class="p-col-6" style="text-align: center">
					<button class="aceptar" (click)="aceptarToken()" pButton type="button" icon="pi pi-user"
						label="Aceptar"></button>
				</div>
				<div class="p-col-6" style="text-align: center">
					<button class="aceptar" (click)="cancelarToken()" pButton type="button" icon="pi pi-undo"
						label="Cancelar"></button>
				</div>
			</div>
		</div>
	</div>
</p-dialog>