import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { VenControlTicketsResumen } from "../veninterfaces/vencontrolticketresumen";
import { VenControlTickets } from "../veninterfaces/vencontroltickets";
@Injectable({
  providedIn: 'root'
})
export class VencontrolticketsService {
  private way: string;
  get ctrltickets(): VenControlTickets[] {
    return this._ctrltickets;
  }
  
  set ctrltickets(value: VenControlTickets[]) {
    this._ctrltickets = value;
  }
  get ctrlticketsresumen(): VenControlTicketsResumen[] {
    return this._ctrlticketsresumen;
  }
  
  set ctrlticketsresumen(value: VenControlTicketsResumen[]) {
    this._ctrlticketsresumen = value;
  }
  public _ctrltickets: VenControlTickets[];
  public _ctrlticketsresumen


  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService) {
      this.way = sessionStorage.getItem('way');
     }
     getlistadatos() {
      return this.http.post<any[]>(this.way + '/ventas/controltickets/41ddacb9d5764c00000', {
        elementos: {},
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }
    EncontrarCliente(strclicodigo) {
      return this.http.post(this.way + '/ventas/pedido/75d98bf76adb2c000000', {
        elementos: {
          cli_codigo: strclicodigo,
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    EncontrarTecnico(strrefcodigo) {
      return this.http.post(this.way + '/ventas/controltickets/3aecc5faadf5cc00000', {
        elementos: {
          ref_codigo: strrefcodigo,
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    insertarregistro(item) {
      return this.http.post(this.way + '/ventas/controltickets/4afc76faadb3bc0000000000', {
        elementos: {
          p_CONTROLTICKET_FACTURADO :item.CONTROLTICKET_FACTURADO,
          p_CLI_CODIGO            :item.CLI_CODIGO,
          p_CONTROLTICKET_FECHA   :item.CONTROLTICKET_FECHA,
          p_COM_CODIGO  :'01',
          p_REF_CODIGO :item.REF_CODIGO,
          p_CONTROLTICKET_PARTNER :item.CONTROLTICKET_PARTNER,
          p_CONTROLTICKET_NUMTICKET :item.CONTROLTICKET_NUMTICKET,
          p_CONTROLTICKET_DETALLESER :item.CONTROLTICKET_DETALLESER,
          p_CONTROLTICKET_TIEMPO :item.CONTROLTICKET_TIEMPO,
          p_ENCFAC_NUMERO   :item.ENCFAC_NUMERO,
          p_NUEVO : item.NUEVO
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    actualizarregistro(item) {
      return this.http.post(this.way + '/ventas/controltickets/a677bbaf64ca3800', {
        elementos: {
          p_CLI_CODIGO            :item.CLI_CODIGO,
          p_CONTROLTICKET_FECHA   :item.CONTROLTICKET_FECHA,
          p_COM_CODIGO  :'01',
          p_REF_CODIGO :item.REF_CODIGO,
          p_CONTROLTICKET_PARTNER :item.CONTROLTICKET_PARTNER,
          p_CONTROLTICKET_NUMTICKET :item.CONTROLTICKET_NUMTICKET,
          p_CONTROLTICKET_DETALLESER :item.CONTROLTICKET_DETALLESER,
          p_CONTROLTICKET_TIEMPO :item.CONTROLTICKET_TIEMPO,
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    informacioncli(clicodigo) {
      return this.http.post<any[]>(this.way + '/cxc/cxctrncobranzas/662fcf57aadcb0000000', {
          elementos: {
            p_clicodigo:clicodigo,
            p_comcodigo:'01'
          }
        }, this.confIniciales.httpOptions()
        
      ).toPromise();
    }
    informacionven(vencodigo) {
      return this.http.post<any[]>(this.way + '/cxc/cxctrncobranzas/cc5f9eaf55b960000000000000', {
          elementos: {
            p_vencodigo:vencodigo,
            p_comcodigo:'01'
          }
        }, this.confIniciales.httpOptions()
        
      ).toPromise();
    }
    eliminarregistro(item: VenControlTickets) {
      return this.http.post<any[]>(this.way + '/ventas/controltickets/3ab2b4aeadb3bc0000000000', {
        elementos: {
          p_CONTROLTICKET_NUMTICKET: item.CONTROLTICKET_NUMTICKET,
          p_CLI_CODIGO: item.CLI_CODIGO,
          p_COM_CODIGO: '01',
        },
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }
    actualizarticketsfac(numticket,clicod,encfac) {
      return this.http.post<any[]>(this.way + '/ventas/controltickets/533aebb1ea633c00000000000', {
        elementos: {
          p_CONTROLTICKET_NUMTICKET: numticket,
          p_CLI_CODIGO: clicod,
          p_ENCFAC_NUMERO: encfac,
          p_COM_CODIGO: '01',
        },
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }
    getallctrltickets() {
      return this.http.post<any[]>(this.way + '/ventas/controltickets/1077555ab3bbaf00000000', {
        elementos: {},
      }, this.confIniciales.httpOptions()
      ).toPromise();
    }
}
