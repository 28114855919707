
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="prodrecalcostind"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="prodrecalcostindConf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 100%">
	<div class="p-col-12">
		<app-invmaebarra   [barraBotonesCRUD]="true"  [barraBotonesDesplaz]="true" [barraBotones3]="true" [barraBotonesAsiCon]="true"
						 [barraBotones5]="true" [botonVisto]="false" [botonReversar]="false" [barraBotones3Alt]="false"
						 (signal)="manejarSenales($event)"
						 style="text-align: left;">
		</app-invmaebarra>
	</div>
</div>

<div class="divgrups" id="divPrincipal5" style="height: 250px;">
    <div class="p-grid">
        <!--LINE 1-->
        <div class="p-col-1">
            <span>Periodo:</span>
        </div>
        <div class="p-col-1" style="text-align: left">
            <p-dropdown [options]="cmbMes"
                        [style]="{'width': '100%', 'min-width': '100%'}"
                        [(ngModel)]="strCmbMes"
                        optionLabel="mes"  inputId="inputMes"
            ></p-dropdown>
        </div>
        <div class="p-col-1">
        </div>
        <div class="p-col-1">
            <span>Año:</span>
        </div>
        <div class="p-col-1">
            <input id="anio" type="number" pInputText autocomplete="off"
                   [(ngModel)]="txtAnio">
        </div>
        <div class="p-col-6">
        </div>
       
        <div class="p-col-12" style="margin-top: -5px">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Centro de Costos:</span>
                </div>
                <div class="p-col-1" style="text-align: left">
                    <input id="CEN_CODIGO" type="text" pInputText autocomplete="off"
                           [(ngModel)]="txtCenCodigo">
                </div>
                <div class="p-col-1">
                    <button type="button"
                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                            class="littlebuttons" (click)="buscarCentro(txtCenCodigo)"></button>
                </div>
                <div class="p-col-3" style="text-align: left">
                    <input id="CEN_NOMBRE" type="text" pInputText disabled autocomplete="off"
                           [(ngModel)]="lblCenNombre">
                </div>
            </div>
        </div>
        <div class="p-col-12" style="margin-top: -5px">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Tipo de producción:</span>
                </div>
                <div class="p-col-1" style="text-align: left">
                    <p-dropdown [options]="cmbTipoProduccion"
                        [style]="{'width': '100%', 'min-width': '100%'}"
                        [(ngModel)]="strCmbTipoProduccion"
                        optionLabel="tipo"  inputId="inputMes"
                    ></p-dropdown>
                </div>
               
            </div>
        </div>
    </div>
</div>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>


