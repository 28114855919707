import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {CxcTrnpago} from '../../interfaces/cxctrnpago';
import {SaciTrnretencion} from '../../interfaces/sacitrnretencion';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class PagocontadoService {
  
  public url: string;
  public pagos: CxcTrnpago[];
  public reten: SaciTrnretencion[];
  public way: string;
  public CAJXVEN: number;
  public hmpConfiguracion: any[];//CDPJ
  public modulo:string;
  public ven_codigo:string;//CDPJ
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  




  consultarFormaPago(strNumComprobante): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/k4zzr2gqfaa4pbp', {
        elementos: {},
        datos: {
          NumComprobante: strNumComprobante ,
        }
    }, this.confIniciales.httpOptions());
  }
  
  consultarTrnRetencion(strNumComprobante): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/grmx5vqzk87m5qe', {
        elementos: {},
        datos: {
          NumComprobante: strNumComprobante
        }
    }, this.confIniciales.httpOptions());
  }



  // aggGRID =>

  erCodigoMaeCaja(cajaCodigo): Observable<any[]>{
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/6f497j3uwgr4t7s', {
      elementos: {
        CAJ_CODIGO: cajaCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erCodigoDineroElc(banCodigo){
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/qgh7sdljffs1h3p', {
      elementos: {
        BAN_CODIGO: banCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erCodigoVtaRetencion(retCondigo){
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/dephay8rb5gbpef', {
      elementos: {
        RETENCION_CODIGO: retCondigo
      },
    }, this.confIniciales.httpOptions());
  }

  erFormaPagoSri(formaPagoTipo){

    return this.http.post<any[]>(this.way + '/ventas/pagocontado/s11nh14su9mso2t', {
      elementos: {
        FORMAPAGO_TIPO: formaPagoTipo
      },
    }, this.confIniciales.httpOptions());

  }

  erCodigoFormaP(formaPagoTipo){
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/76h6ya56cjzjalt', {
      elementos: {
        FORMAPAGO_TIPO: formaPagoTipo
      },
    }, this.confIniciales.httpOptions());
  }
//CDPJ
erCodigoFormaPromise(formaPagoTipo){
  return this.http.post(this.way + '/ventas/pagocontado/76h6ya56cjzjalt', {
    elementos: {
      FORMAPAGO_TIPO: formaPagoTipo
    },
  }, this.confIniciales.httpOptions()).toPromise();
}

// erCajaCodigoVenPromise(){
//   return this.http.post(this.way + '/ventas/pagocontado/udld7m85vg9j5yl', {
//     elementos: {},
//   }, this.confIniciales.httpOptions()).toPromise();
// }

erCodigoVendedorPromise(venCodigo){
  return this.http.post(this.way + '/ventas/pagocontado/qblfstrx7zogjnt', {
    elementos: {
      VEN_CODIGO: venCodigo
    },
  }, this.confIniciales.httpOptions()).toPromise();
}

erCajaCodigoBanPromise(cajaCodigo){
  return this.http.post(this.way + '/ventas/pagocontado/umd8urxjtq5w08x', {
    elementos: {
      CAJ_CODIGO: cajaCodigo
    },
  }, this.confIniciales.httpOptions()).toPromise();
}

//CDPJ
  erCajaCodigoBan(cajaCodigo){
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/umd8urxjtq5w08x', {
      elementos: {
        CAJ_CODIGO: cajaCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erCajaCodigoVen(){
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/udld7m85vg9j5yl', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
//CDPJ
consultardeposito(strNumComprobante,TRNBAN_TIPO) {
  return this.http.post<any[]>(this.way + '/ventas/pagocontado/662fcf57aadb5c000000000000000000000', {
      elementos: {},
      datos: {
        NumComprobante: strNumComprobante,
        TRNPAGOTIPO:TRNBAN_TIPO
      }
  }, this.confIniciales.httpOptions()).toPromise();
};

consultarconciliacion(strNumComprobante,ban_codigo) {
  return this.http.post<any[]>(this.way + '/ventas/pagocontado/662fcf57aadb3000000000000000000000000000', {
      elementos: {},
      datos: {
        NumComprobanteC: strNumComprobante,
        p_BAN_CODIGO:ban_codigo
      }
  }, this.confIniciales.httpOptions()).toPromise();
};

//CDPJ

  //  <= aggGRID

  erFindTransfer( strNumFact ){

    return this.http.post<any[]>(this.way + '/ventas/pagocontado/ddvormfbxyrjdj3', {
      elementos: {
        NumFact: strNumFact
      },
    }, this.confIniciales.httpOptions());

  }

  erVenCliCodigo(cli_codigo){
    
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/e7ljm9wqiuon1k9', {
      elementos: {
        CLI_CODIGO: cli_codigo
      },
    }, this.confIniciales.httpOptions());
  }

  

  erCodigoFormapago(strTipoPago){
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/esvbq37zge7ppmh', {
      elementos: {
        TipoPago: strTipoPago
      },
    }, this.confIniciales.httpOptions());
  }

  erCodigoVendedor(venCodigo){
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/qblfstrx7zogjnt', {
      elementos: {
        VEN_CODIGO: venCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erCodigobanCaja(cajaCodigo){

    return this.http.post<any[]>(this.way + '/ventas/pagocontado/1e171gcng0byc95', {
      elementos: {
        CAJ_CODIGO: cajaCodigo
      },
    }, this.confIniciales.httpOptions());

  }

  erCodigoBanCliente(banCliCodigo){
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/az2ig9v30gcbmph', {
      elementos: {
        BANCLI_CODIGO: banCliCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erTarjetaCodigo(banCliCodigo){
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/pb5jgst9tpjulpw', {
      elementos: {
        BANCLI_CODIGO: banCliCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  //CDPJ
  erBannombreDP(banCliCodigo){
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/edad57be2cbdb800', {
      elementos: {
        BANCLI_CODIGO: banCliCodigo
      },
    }, this.confIniciales.httpOptions());
  }
  //CDPJ
  erBanCajaCodigo(caj_codigo){
    return this.http.post<any>(this.way + '/ventas/pagocontado/xx01azfwf71lmpp', {
      elementos: {
        CAJ_CODIGO: caj_codigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erCobroResumenRef(numFact){
    return this.http.post<any>(this.way + '/ventas/pagocontado/sgbtn9c3b4pel9p', {
      elementos: {
        NumFact: numFact
      },
    }, this.confIniciales.httpOptions());
  }






  ///////////////////////////////////////////////////


  erpCodigoRet(retencionCodigo){
    return this.http.post(this.way + '/ventas/pagocontado/mlmq5e8uawymdr7', {
      elementos: {
        RETENCION_CODIGO: retencionCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpTipoFormaPago(trnPagoTipo){
    return this.http.post(this.way + '/ventas/pagocontado/wvfwtwyafy9ghay', {
      elementos: {
        TRNPAGO_TIPO: trnPagoTipo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpFechaEmisionEncFac(encFacNumero){
    return this.http.post(this.way + '/ventas/pagocontado/1uz72o7xg6pb6v7', {
      elementos: {
        ENCFAC_NUMERO: encFacNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpBanCliCodigo(banCliCodigo){
    return this.http.post(this.way + '/ventas/pagocontado/60dlxpy7j5w4o8h', {
      elementos: {
        BANCLI_CODIGO: banCliCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  erpBanCliCodigoDP(banCliCodigo){
    return this.http.post(this.way + '/ventas/pagocontado/76f2b55eca72d400000000000', {
      elementos: {
        BANCLI_CODIGO: banCliCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erpExisFormaPago(banCliCodigo,tipopago,numeropago){
    return this.http.post(this.way + '/ventas/pagocontado/7d559354e', {
      elementos: {
        p_banclicodigo: banCliCodigo,
        p_bantrntipopago:tipopago,
        p_bantrnpagonum:numeropago
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  erpContarTarjetaCod(banCliCodigo){
    return this.http.post(this.way + '/ventas/pagocontado/bv84v5j81exnenj', {
      elementos: {
        BANCLI_CODIGO: banCliCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpCodMaeSri(trnPagoCodSri){
    return this.http.post(this.way + '/ventas/pagocontado/oqn38poosksl7gu', {
      elementos: {
        TRNPAGO_CODSRI: trnPagoCodSri
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpCodMaeSriCF(trnPagoCodSri){
    return this.http.post(this.way + '/ventas/pagocontado/05rvr4l3pd9cmbk', {
      elementos: {
        TRNPAGO_CODSRI: trnPagoCodSri
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }





  /***
   * ===========================================
   * =====================================
   */
  
  obtenerSaldoFactura(strNumFact): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/165k00dag26kt8i', {
        elementos: {},
        datos: {
          NumFact: strNumFact
        }
    }, this.confIniciales.httpOptions());
  }
  
  consultarTrnCobro(strOpcion, strCondicion, strFiltro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/qhwnd4pd7pu7xtm', {
      elementos: {
        p_opcion: strOpcion,
        p_filtro: strFiltro,
        p_condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  consultarTrnCobroPromise(strOpcion, strCondicion, strFiltro) {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/qhwnd4pd7pu7xtm', {
      elementos: {
        p_opcion: strOpcion,
        p_filtro: strFiltro,
        p_condicion: strCondicion
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarTrnCobro(trncobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/ne6evf5n9uocpyo', {
      elementos: {
        p_TRNCOBRO_nroDoc: trncobro.TRNCOBRO_NRODOC,
        p_TRNCOBRO_nroComprobante: trncobro.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_tipoDoc: trncobro.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_fechaTrn: trncobro.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_concepto: trncobro.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_importe: trncobro.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_fechavence: trncobro.TRNCOBRO_FECHAVENCE,
        p_APLORG_codigo: trncobro.APLORG_CODIGO,
        p_TRNCOBRO_referenciaExterna: trncobro.TRNCOBRO_REFERENCIAEXTERNA,
        p_CLI_codigo: trncobro.CLI_CODIGO,
        p_VEN_codigo: trncobro.VEN_CODIGO,
        p_COM_codigo: trncobro.COM_CODIGO,
        p_ASI_NRO: trncobro.ASI_NRO,
        p_USU_IDENTIFICACION: this.usuario.identificacion,
        p_TRNCOBRO_FLAG: trncobro.TRNCOBRO_FLAG,
        p_BAN_CODIGO: trncobro.BAN_CODIGO,
        p_TRNCOBRO_EFECTIVO: trncobro.TRNCOBRO_EFECTIVO,
        p_TRNCOBRO_CAMBIO: trncobro.TRNCOBRO_CAMBIO,
        p_CON_CODIGO: trncobro.CON_CODIGO,
        p_TRNCOBRO_SALDOINI: trncobro.TRNCOBRO_SALDOINI,
        p_BOD_CODIGO: trncobro.BOD_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  insertarTrnDocumento(strTrnCobroNroDoc, strTrnNroComprobante, trncobro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/rr9x7g5c41za2ss', {
      elementos: {
        p_TRNCOBRO_nroDoc_1: strTrnCobroNroDoc,
        p_TRNDOCUMENTO_nroDoc_2: trncobro.TRNCOBRO_NRODOC,
        p_TRNDOCUMENTO_importe_3: trncobro.TRNCOBRO_IMPORTE,
        p_COM_codigo_4: trncobro.COM_CODIGO,
        p_CLI_codigo_5: trncobro.CLI_CODIGO,
        p_TRNDOCUMENTO_REF: trncobro.CUOTAFAC,
        p_TRNCOBRO_NROCOMPROBANTE: strTrnNroComprobante
      },
    }, this.confIniciales.httpOptions());
  }
  
  insertarTrnRetencion(item): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/27r0svt5husn2fi', {
      elementos: {
        p_retencion_codigo_1: item.RETENCION_CODIGO,
        p_trnretencion_descripcion_4: item.TRNRETENCION_DESCRIPCION,
        p_trnretencion_nro_1: item.TRNRETENCION_NRO,
        p_trnretencion_base_5: item.TRNRETENCION_BASE,
        p_trnretencion_porcentaje_6: item.TRNRETENCION_PORCENTAJE,
        p_trnretencion_totalretenido_7: item.TRNRETENCION_TOTALRETENIDO,
        p_trncobro_nrodoc_8: item.TRNCOBRO_NRODOC,
        p_com_codigo_3: item.COM_CODIGO,
        p_trnretencion_origen: item.TRNRETENCION_ORIGEN,
        p_con_codigo: item.CON_CODIGO,
        p_cen_codigo: item.CEN_CODIGO,
        p_cli_codigo: item.CLI_CODIGO,
        p_ENCFAC_NUMERO: item.ENCFAC_NUMERO,
        p_TRNCOBRO_NROCOMPROBANTE: item.TRNCOBRO_NROCOMPROBANTE,
      },
    }, this.confIniciales.httpOptions());
  }

  insertarFormaPago(strTrnCobroNroComprobante, strNumFact, item): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/864gj34yo5gg4i6', {
      elementos: {
        p_TRNPAGO_tipo_2: item.TRNPAGO_TIPO,
        p_CAJ_codigo_3: item.CAJ_CODIGO,
        p_TRNPAGO_numero_4: item.TRNPAGO_NUMERO,
        p_TRNPAGO_fecha_5: item.TRNPAGO_FECHA,
        p_BANCLI_CODIGO: item.BANCLI_CODIGO,
        p_TRNPAGO_NROCTA: item.TRNPAGO_NROCTA,
        p_TRNPAGO_concepto_6: 'PAGO DIRECTO FAC.' + strNumFact,
        p_TRNPAGO_importe_7: item.TRNPAGO_IMPORTE,
        p_TRNPAGO_numero_anterior: '',
        p_CON_codigo_9: item.CON_CODIGO,
        p_TRNCOBRO_nroDoc_1: item.TRNCOBRO_NRODOC,
        p_COM_codigo_8: item.COM_CODIGO,
        p_TRNCOBRO_nroComprobante: strTrnCobroNroComprobante,
        p_CLI_CODIGO: item.CLI_CODIGO,
        p_TRNPAGO_CODSRI: item.TRNPAGO_CODSRI,
        p_TRNCHEPOS_NUMDOC: '',
        p_TRNPAGO_TIPDIS: item.TRNPAGO_TIPDIS
      },
    }, this.confIniciales.httpOptions());
  }
  insertarFormaPagoProm(strTrnCobroNroComprobante, strNumFact, item) {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/864gj34yo5gg4i6', {
      elementos: {
        p_TRNPAGO_tipo_2: item.TRNPAGO_TIPO,
        p_CAJ_codigo_3: item.CAJ_CODIGO,
        p_TRNPAGO_numero_4: item.TRNPAGO_NUMERO,
        p_TRNPAGO_fecha_5: item.TRNPAGO_FECHA,
        p_BANCLI_CODIGO: item.BANCLI_CODIGO,
        p_TRNPAGO_NROCTA: item.TRNPAGO_NROCTA,
        p_TRNPAGO_concepto_6: 'PAGO DIRECTO FAC.' + strNumFact,
        p_TRNPAGO_importe_7: item.TRNPAGO_IMPORTE,
        p_TRNPAGO_numero_anterior: '',
        p_CON_codigo_9: item.CON_CODIGO,
        p_TRNCOBRO_nroDoc_1: item.TRNCOBRO_NRODOC,
        p_COM_codigo_8: item.COM_CODIGO,
        p_TRNCOBRO_nroComprobante: strTrnCobroNroComprobante,
        p_CLI_CODIGO: item.CLI_CODIGO,
        p_TRNPAGO_CODSRI: item.TRNPAGO_CODSRI,
        p_TRNCHEPOS_NUMDOC: '',
        p_TRNPAGO_TIPDIS: item.TRNPAGO_TIPDIS
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarTrnCobro(strCondicion, strTrnCobroNroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/a6pmf897wsgqs0s', {
      elementos: {
        p_trncobro_nrodoc: strTrnCobroNroDoc,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarTrnCobro2(strCondicion, strTrnCobroNroDoc): Observable<any> {
    
    return this.http.post<any>(this.way + '/ventas/pagocontado/105ufkwng6pswsg', {
      elementos: {},
      datos: {
        TrnCobroNroDoc: strTrnCobroNroDoc
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizaEstadoFactura(strNumFact, strCodCliente): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/b05y8h0amat3jwv', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: strCodCliente,
        p_encfac_numero: strNumFact
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizaFormaPagoFact(strNumFact, strTipoFP): Observable<any> {    
    return this.http.post<any>(this.way + '/ventas/pagocontado/9iaxy04f9w9toch', {
      elementos: {},
      datos: {
        TipoFP: strTipoFP,
        NumFact: strNumFact
      }
    }, this.confIniciales.httpOptions());
  }
  
  anularAsientoContable(strAsiNro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/gbegpgocrrdy0eq', {
      elementos: {
        p_ASI_nro: strAsiNro,
        p_COM_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  
  actualizarAsiNroFact(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pagocontado/3vm9f5iqqx0x78s', {
      elementos: {},
      datos: {
        NumFact: strNumFact
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  obtenerClave(clave): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pagocontado/jwn7qmo1cc3b6ih', {
      elementos: {},
      datos: {
        Clave: clave
      }
    }, this.confIniciales.httpOptions());
  }
  eliminarinfoadi(encfac): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pagocontado/3ab2b4aeadcaf000000', {
      elementos: {},
      datos: {
        p_encfac_numero: encfac
      }
    }, this.confIniciales.httpOptions());
  }
  //CDPJ
  actualizarAsiNroCxc(trncobronum,asinro): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/pagocontado/299df2ab2', {
      elementos: {},
      datos: {
        trncobro: trncobronum,
        asi:asinro
      }
    }, this.confIniciales.httpOptions());
  }
  insertarinfoadic(trncobronum) {
    return this.http.post<any[]>(this.way + '/ventas/pagocontado/12bf1dbeab72bc0000000000000', {
      elementos: {
        p_encfac_numero:trncobronum,
        p_com_codigo:'01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  
}


