<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="barra"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmbarra" title="Notificación" appendTo="body"></p-confirmDialog>
<div class="encabezado">
	<div class="p-grid">
		<div class="p-col-5">
			<div class="p-grid">
				<div class="p-col-1">
					<!-- CDPJ -->
					<div *ngIf="excel===true" style="display: inline-block">
						<button style="background-image: url(../../assets/images/iconos/077-xls_2.png);"
							(click)="enviarSenal('Excel')" title="Descargar Excel"></button>
					</div>
					<!-- CDPJ -->
				</div>
				<div class="p-col-11" style="float: right">
					<div *ngIf="deshabilitarbotones" style="display: inline-block;">
						<div *ngIf="noNuevo!=true" style="display: inline-block">
							<button [hidden]="barraBotonesCRUD" *ngIf="botonNuevo === false" [disabled]="botonNuevo"
								style="background-image: url(../../../assets/images/iconos/nuevo.png);"
								(click)="enviarSenal('Nuevo')" title="Nuevo"></button>
							<button [hidden]="barraBotonesCRUD" *ngIf="botonNuevo === true" [disabled]="botonNuevo"
								style="background-image: url(../../../assets/images/iconos/nuevo-d.png);"
								(click)="enviarSenal('Nuevo')"></button>
						</div>
						<button [hidden]="barraBotonesCRUD" *ngIf="!botonGuardar" [disabled]="botonGuardar"
							style="background-image: url(../../../assets/images/iconos/guardar.png);"
							(click)="enviarSenal('Guardar')" title="Guardar"></button>
						<button [hidden]="barraBotonesCRUD" *ngIf="botonGuardar" [disabled]="botonGuardar"
							style="background-image: url(../../../assets/images/iconos/guardar-d.png);"
							(click)="enviarSenal('Guardar')"></button>
						<div *ngIf="noEliminar!=true" style="display: inline-block">
							<button [hidden]="barraBotonesCRUD" *ngIf="botonBorrar === false" [disabled]="botonBorrar"
								style="background-image: url(../../../assets/images/iconos/borrar.png);"
								(click)="enviarSenal('Borrar')" title="Eliminar"></button>
							<button [hidden]="barraBotonesCRUD" *ngIf="botonBorrar === true" [disabled]="botonBorrar"
								style="background-image: url(../../../assets/images/iconos/borrar-d.png);"
								(click)="enviarSenal('Borrar')"></button>
						</div>
						<button [hidden]="barraBotonesCRUD"
							style="background-image: url(../../../assets/images/iconos/regresar.png);"
							(click)="enviarSenal('Cancelar')" title="Cancelar"></button>
					</div>
					<div *ngIf="!deshabilitarbotones" style="display: inline-block;">
						<div *ngIf="noNuevo!=true" style="display: inline-block">
							<button [hidden]="barraBotonesCRUD" disabled
								style="background-image: url(../../../assets/images/iconos/nuevo-d.png);"
								(click)="enviarSenal('Nuevo')"></button>
						</div>
						<button [hidden]="barraBotonesCRUD" disabled
							style="background-image: url(../../../assets/images/iconos/guardar-d.png);"
							(click)="enviarSenal('Guardar')" title="Guardar"></button>
						<div *ngIf="noEliminar!=true" style="display: inline-block">
							<button [hidden]="barraBotonesCRUD" disabled
								style="background-image: url(../../../assets/images/iconos/borrar-d.png);"
								(click)="enviarSenal('Borrar')"></button>
						</div>
						<button [hidden]="barraBotonesCRUD" disabled
							style="background-image: url(../../../assets/images/iconos/regresar-d.png);"
							(click)="enviarSenal('Cancelar')" title="Cancelar"></button>
					</div>
					<div [hidden]="barraBotonesDesplaz" *ngIf="!barraBotonesDesplaz" style="display: inline-block;
          					margin-left:20px; border-left: 0px solid #005594; height: 30px"></div>
					<div *ngIf="barraFormaPago===true || noNuevoDVF === true" style="display: inline-block">
						<div *ngIf="imprimir!==true" style="display: inline-block">
							<div style="display: inline-block; margin-left:20px; border-left: 0px solid #005594;
									height: 30px"></div>
							<button style="background-image: url(../../../assets/images/iconos/visualizar_asiento.png);
									background-size: contain !important;" (click)="enviarSenal('AsientoVer')" title="Visualizar Asiento"></button>
							<button style="background-image: url(../../../assets/images/iconos/026-printer.png);"
								(click)="enviarSenal('Imprimir')" title="Imprimir"></button>
							<div style="display: inline-block; margin-left:20px; border-left: 0px solid #005594;
									height: 30px"></div>
							<!--<button *ngIf="barraFormaPago===true"
									style="background-image: url(../../../assets/images/iconos/091-credit-card-2.png);"
									(click)="enviarSenal('PagoTarjeta')" title="Pago con tarjeta de crédito"></button>-->
						</div>
					</div>
					<div *ngIf="kardex===true" style="display: inline-block">
						<button style="background-image: url(../../../assets/images/iconos/010-speed.png);"
							(click)="enviarSenal('Kardex')" title="Visualizar Kardex"></button>
					</div>
					<div *ngIf="reporte===true" style="display: inline-block">
						<button style="background-image: url(../../../assets/images/iconos/023-pdf-1.png);"
							(click)="enviarSenal('Reporte')" title="Visualiza lo que se va ha imprimir"></button>
					</div>
					<!-- CDPJ -->
					<div *ngIf="enviofrmcorreo===true" style="display: inline-block">
						<button style="background-image: url(../../../assets/images/iconos/107-mailbox.png); background-size: contain !important;"
							(click)="enviarSenal('Correo')" title="Enviar Correo"></button>
					</div>
					<!-- CDPJ -->
					<div *ngIf="imprimir===true" style="display: inline-block">
						<button style="background-image: url(../../../assets/images/iconos/026-printer.png);"
							(click)="enviarSenal('Imprimir')" title="Imprimir"></button>
					</div>
					<button [hidden]="barraBotonesDesplaz" *ngIf="!botonPrimero"
						style="background-image: url(../../../assets/images/iconos/primero.png);"
						(click)="enviarSenal('Primero')" [disabled]="botonPrimero" title="Primero"></button>
					<button [hidden]="barraBotonesDesplaz" *ngIf="botonPrimero"
						style="background-image: url(../../../assets/images/iconos/primero-d.png);"
						(click)="enviarSenal('Primero')" [disabled]="botonPrimero"></button>
					<button [hidden]="barraBotonesDesplaz" *ngIf="!botonAnterior"
						style="background-image: url(../../../assets/images/iconos/anterior.png);"
						(click)="enviarSenal('Anterior')" [disabled]="botonAnterior" title="Anterior"></button>
					<button [hidden]="barraBotonesDesplaz" *ngIf="botonAnterior"
						style="background-image: url(../../../assets/images/iconos/anterior-d.png);"
						(click)="enviarSenal('Anterior')" [disabled]="botonAnterior"></button>
					<button [hidden]="barraBotonesDesplaz" *ngIf="!botonSiguiente"
						style="background-image: url(../../../assets/images/iconos/siguiente.png);"
						(click)="enviarSenal('Siguiente')" [disabled]="botonSiguiente" title="Siguiente"></button>
					<button [hidden]="barraBotonesDesplaz" *ngIf="botonSiguiente"
						style="background-image: url(../../../assets/images/iconos/siguiente-d.png);"
						(click)="enviarSenal('Siguiente')" [disabled]="botonSiguiente"></button>
					<button [hidden]="barraBotonesDesplaz" *ngIf="!botonUltimo"
						style="background-image: url(../../../assets/images/iconos/ultimo.png);"
						(click)="enviarSenal('Ultimo')" [disabled]="botonUltimo" title="Último"></button>
					<button [hidden]="barraBotonesDesplaz" *ngIf="botonUltimo"
						style="background-image: url(../../../assets/images/iconos/ultimo-d.png);"
						(click)="enviarSenal('Ultimo')" [disabled]="botonUltimo"></button>
				</div>
			</div>
		</div>
		<div class="p-col-3">
			<button *ngIf="barraBotones3 === false" [hidden]="barraBotones3"
				style="background-image: url(../../../assets/images/iconos/exportar.png);"
				(click)="enviarSenal('Exportar')" title="Exportar"></button>
			<button *ngIf="barraBotones3 === false" [hidden]="barraBotones3"
				style="background-image: url(../../../assets/images/iconos/importar.png);"
				(click)="enviarSenal('Importar')" title="Importar"></button>
			<button *ngIf="barraBotones3Alt === false && botonGuardarPermanente === true" [hidden]="barraBotones3Alt"
				style="background-image: url(../../../assets/images/iconos/guardar.png);"
				(click)="enviarSenal('Guardar')"></button>
				<!-- CDPJ -->
				<button *ngIf="barraBotones3Alt === false && botonCancelarPermanente === true" [hidden]="barraBotones3Alt"
				style="background-image: url(../../../assets/images/iconos/regresar.png);"
				(click)="enviarSenal('Cancelar')"></button>
				<!-- CDPJ -->
				<!-- GSRF -->
			<button *ngIf="barraBotonesIMP === false && botonFolder === false" [hidden]="barraBotones3Alt"
				style="background-image: url(../../../assets/images/iconos/184-folder-10.png);"
				(click)="enviarSenal('Listaarch')" title="Buscar archivos"></button>
			<button *ngIf="barraBotonesIMP === false && botonArchivo === false" [hidden]="barraBotones3Alt"
				style="background-image: url(../../../assets/images/iconos/212-file-1.png); "
				(click)="enviarSenal('impdoc')" title="Importar a temporales"></button>
			<button *ngIf="barraBotonesIMP === false && botonArchivo === true" [hidden]="barraBotones3Alt"
				style="background-image: url(../../../assets/images/iconos/008-list-1-d.png); "
				(click)="enviarSenal('impdoc')"  [disabled]="botonArchivo"  title="Importar a temporales"></button>
				<!-- GSRF -->
			<button *ngIf="barraBotones3Alt === false && botonVisto === false" [hidden]="barraBotones3Alt"
				style="background-image: url(../../../assets/images/iconos/visto.png); border-radius: 20px"
				(click)="enviarSenal('Visto')"></button>
			<button *ngIf="barraBotones3Alt === false && botonVisto === true" [hidden]="barraBotones3Alt"
				style="background-image: url(../../../assets/images/iconos/visto-d.png); border-radius: 20px"
				(click)="enviarSenal('Visto')" [disabled]="botonVisto"></button>
			<button *ngIf="barraBotones3Alt === false && botonReversar === false" [hidden]="barraBotones3Alt"
				style="background-image: url(../../../assets/images/iconos/055-folder-34.png);"
				(click)="enviarSenal('Reversar')"></button>
			<button *ngIf="barraBotones3Alt === false && botonReversar === true" [hidden]="barraBotones3Alt"
				style="background-image: url(../../../assets/images/iconos/055-folder-34-d.png);"
				(click)="enviarSenal('Reversar')" [disabled]="botonReversar"></button>
			<div *ngIf="barraFactura === false || barraPedido === false || barraOrden === false ||
			barraGre === false || barraFacturaPro === false || barraNotasRecepcion === false || 
			barraOrdenProd === false || barraPtoVta === false" [hidden]="barraFactura"
				style="display: inline-block; margin-left:20px; border-left: 0px solid #005594; height: 30px"></div>
			<button *ngIf="barraFactura === false || barraPedido === false || barraGre === false"
				style="background-image: url(../../../assets/images/iconos/024-message.png);"
				(click)="enviarSenal('ClaseNumeracion')" title="Remover Clase Numeración"></button>
			<button *ngIf="barraOrdenProd === false"
				style="background-image: url(../../../assets/images/iconos/024-message.png);"
				(click)="enviarSenal('ClaseNumeracion')" title="Quitar Grupo"></button>
			<button *ngIf="botonAnular === false && (barraFactura === false || barraPedido === false || barraOrden === false ||
			 barraGre === false || barraFacturaPro === false || barraNotasRecepcion === false || barraCxpPagoDoc === false ||
			 barraCartera === false || barraConEncasil === false || barraCxpTrnCobro === false || barraOrdenProd === false ||
			 barraPtoVta === false)" style="background-image: url(../../../assets/images/iconos/077-delete.png);"
				(click)="enviarSenal('Anular')" title="Anular" [disabled]="botonAnular"></button>
			<button *ngIf="botonAnular === true"
				style="background-image: url(../../../assets/images/iconos/077-delete_d.png);"
				(click)="enviarSenal('Anular')" title="Anular" [disabled]="botonAnular"></button>
			<button *ngIf="barraOrdenProd === false"
				style="background-image: url(../../../assets/images/iconos/importar.png);"
				(click)="enviarSenal('IngresarProducto')" title="Ingresar el Producto Terminado"></button>
			<button *ngIf="barraBandejaPago === true"
				style="background-image: url(../../../assets/images/iconos/072-keys.png);"
				(click)="enviarSenal('Aprobar')" title="Aprobar"></button>
			<button *ngIf="barraBandejaPago === true"
				style="background-image: url(../../../assets/images/iconos/010-list.png);"
				(click)="enviarSenal('GenerarArchivo')" title="Generar Archivo"></button>
			<button *ngIf="barraBandejaPago === true"
				style="background-image: url(../../../assets/images/iconos/011-checklist.png);"
				(click)="enviarSenal('CashMng')" title="Aprobar"></button>
			<div *ngIf="barraFactura === false  || barraFacturaPro === false || barraPtoVta === false"
				[hidden]="barraFactura" style="display: inline-block;
           			margin-left:10px; border-left: 0px solid #005594; height: 30px"></div>
			<button *ngIf="barraFactura === false || barraDevolucion === false|| barraFacturaPro === false || 
				barraNotasRecepcion === false || barraPtoVta === false" [hidden]="barraFactura"
				style="background-image: url(../../../assets/images/iconos/059-discount.png); border-radius: 20px"
				(click)="enviarSenal('Descuento')" title="Descuento Global"></button>
			<button *ngIf="(barraFactura === false || barraDevolucion === false|| barraFacturaPro === false ||
				 barraPtoVta === false) && botonPago === false" [hidden]="barraFactura" [disabled]="botonPago"
				style="background-image: url(../../../assets/images/iconos/157-money.png);"
				(click)="enviarSenal('Pago')" title="Pago al Contado"></button>
			<button *ngIf="botonPago === true" [hidden]="barraFactura" [disabled]="botonPago"
				style="background-image: url(../../../assets/images/iconos/157-money_d.png);"
				(click)="enviarSenal('Pago')" title="Pago al Contado"></button>
			<button
				*ngIf="(barraFactura === false || barraFacturaPro === false || barraPtoVta === false) && botonPagoCredito === false"
				[hidden]="barraFactura" style="background-image: url(../../../assets/images/iconos/085-creditcard.png);"
				[disabled]="botonPagoCredito" (click)="enviarSenal('Credito')" title="Pago a Crédito"></button>
			<button *ngIf=" botonPagoCredito === true" [hidden]="barraFactura" [disabled]="botonPagoCredito"
				style="background-image: url(../../../assets/images/iconos/085-creditcard_d.png);"
				(click)="enviarSenal('Credito')" title="Pago a Crédito"></button>
			<button *ngIf="check === true" [hidden]="!check"
				style="background-image: url(../../../assets/images/iconos/visto.png);" (click)="enviarSenal('Generar')"
				title="Generar las cuotas"></button>
			<button *ngIf="check === false" [hidden]="check"
				style="background-image: url(../../../assets/images/iconos/visto-d.png);"
				(click)="enviarSenal('Generar')" title="Generar las cuotas" disabled></button>
			<div *ngIf="barraFacturaPro === false"
				style="display: inline-block; margin-left:20px; border-left: 0px solid #005594; height: 30px"></div>
			<button *ngIf="barraFacturaPro === false"
				style="background-image: url(../../../assets/images/iconos/cogwheel.png);"
				(click)="enviarSenal('ActualizarPrecios')" title="Actualización de Precios"></button>
			<button *ngIf="barraFacturaPro === false"
				style="background-image: url(../../../assets/images/iconos/094-cd.png); display: none"
				(click)="enviarSenal('ActualizarDescuentos')" title="Actualizar Descuentos"></button>
			<button *ngIf="barraTransferencia === false && botonAutorizar === true"
				style="margin-left: 25%; background-image: url(../../../assets/images/iconos/072-keys.png)"
				(click)="enviarSenal('Autorizar')" title="Autorizar"></button>
			<button *ngIf="barraTransferencia === false && botonAutorizar === false" disabled
				style="margin-left: 25%; background-image: url(../../../assets/images/iconos/072-keys_d.png)"
				(click)="enviarSenal('Autorizar')" title="Autorizar"></button>
			<button *ngIf="barraTransferencia === false"
				style="background-image: url(../../../assets/images/iconos/062-transfer.png)"
				(click)="enviarSenal('Transferir')" title="Transferir/Reversar"></button>
			<div *ngIf="kardex===true" style="display: inline-block">
				<button style="background-image: url(../../../assets/images/iconos/buscar.png);"
					(click)="enviarSenal('Buscar')" title="Visualizar Kardex"></button>
			</div>
			<ng-container *ngIf="botonCabInpRent">
				<button [hidden]="!botonGenexml" style="background-image: url(../../../assets/images/iconos/i2.png);"
					(click)="enviarSenal('gxml')" title="Generar archivo XML"></button>
				<button [hidden]="!botonRecImpRen" style="background-image: url(../../../assets/images/iconos/i3.png);"
					(click)="enviarSenal('rimrent')" title="Recalcular Impuesto a la Renta"></button>
				<div style="display: inline-block; margin-left:20px; border-left: 0px solid #005594;
				height: 30px"></div>
				<button [hidden]="!botonCamLiq"
					style="background-image: url(../../../assets/images/iconos/212-file-1.png);"
					(click)="enviarSenal('camliq')" title="Cambiar a liquidado"></button>
				<button [hidden]="!botonRevEstLiq"
					style="background-image: url(../../../assets/images/iconos/012-stop.png);"
					(click)="enviarSenal('reesliq')" title="Reversar Estado Liquidación"></button>
			</ng-container>
			<ng-container *ngIf="btnLiqBenSoc">
				<button [hidden]="!btnConLiq" title="Confirmación Liquidación"
					style="background-image: url(../../../assets/images/iconos/visto.png); border-radius: 20px"
					(click)="enviarSenal('ejecutarliq')"></button>
				<button [hidden]="!btnReveEstLiq"
					style="background-image: url(../../../assets/images/iconos/012-stop.png);"
					(click)="enviarSenal('revliq')" title="Reversar Estado Liquidación"></button>
				<div style="display: inline-block; margin-left:20px; border-left: 0px solid #005594;
				height: 30px"></div>
			</ng-container>
			<button *ngIf="btnRevActFijo === false"
				style="background-image: url(../../../assets/images/iconos/borrar.png)"
				(click)="enviarSenal('Reversar')" title="Reversar Confirmación Salida"></button>
			<button *ngIf="btnRevActFijo === true" disabled
				style="background-image: url(../../../assets/images/iconos/borrar-d.png)"
				(click)="enviarSenal('Reversar')" title="Reversar Confirmación Salida"></button>
		</div>
		<div class="p-col-2">
			<div *ngIf="barraBotonesAsiCon === false && ( barraPedido === false || barraFactura === false
			|| barraOrden === false || barraFacturaPro === false || barraNotasRecepcion === false
			|| barraCxpPagoDoc === false || barraPtoVta === false)" [hidden]="barraBotonesAsiCon" style="display: inline-block;
		   margin-left:10px; border-left: 0px solid #005594; height: 30px"></div>
			<button *ngIf="barraOrdenProd === false" [hidden]="barraBotonesAsiCon"
				style="background-image: url(../../../assets/images/iconos/006-eraser.png); background-size: contain !important;"
				(click)="enviarSenal('BorrarAsiento')" title="Borrar el Asiento contable"
				[disabled]="botonAsiento"></button>
			<button *ngIf="botonAsiento === false && barraCxpPagoDoc !== false" [hidden]="barraBotonesAsiCon"
				style="background-image: url(../../../assets/images/iconos/generar_asiento.png); background-size: contain !important;"
				(click)="enviarSenal('Asiento')" title="Generar Asiento" [disabled]="botonAsiento"></button>
			<button *ngIf="botonAsiento === true  && barraCxpPagoDoc !== false" [hidden]="barraBotonesAsiCon"
				style="background-image: url(../../../assets/images/iconos/generar_asiento_d.png); background-size: contain !important;"
				(click)="enviarSenal('Asiento')" title="Generar Asiento" [disabled]="botonAsiento"></button>
			<button *ngIf="botonAsiento === false && barraCxpPagoDoc === false" [hidden]="barraBotonesAsiCon"
				style="background-image: url(../../../assets/images/iconos/089-coin-1.png); background-size: contain !important;"
				(click)="enviarSenal('AbonoCXC')" title="Ver abono en Cuentas por Cobrar"
				[disabled]="botonAsiento"></button>
			<button *ngIf="botonverAsiento === false" [hidden]="barraBotonesAsiCon"
				style="background-image: url(../../../assets/images/iconos/visualizar_asiento.png); background-size: contain !important;"
				(click)="enviarSenal('AsientoVer')" title="Visualizar Asiento" [disabled]="botonverAsiento"></button>
			<button *ngIf="botonverAsiento === true" [hidden]="barraBotonesAsiCon"
				style="background-image: url(../../../assets/images/iconos/visualizar_asiento_d.png); background-size: contain !important;"
				(click)="enviarSenal('AsientoVer')" title="Visualizar Asiento" [disabled]="botonverAsiento"></button>
			<div *ngIf="barraFactura === false || barraGre === false || barraFacturaPro === false || barraPtoVta === false"
				[hidden]="barraFactura" style="display: inline-block;
           margin-left:10px; border-left: 0px solid #005594; height: 30px"></div>
			<button *ngIf="(barraFactura === false || barraGre === false ||
					barraFacturaPro === false || barraCartera === false || barraPtoVta === false) && botonXML === false" [hidden]="barraFactura"
				[disabled]="botonXML"
				style="background-image: url(../../../assets/images/iconos/009-connect-1.png); background-size: contain !important;"
				(click)="enviarSenal('Firmar')" title="Firmar Electrónicamente"></button>
			<button *ngIf="botonXML === true" [hidden]="barraFactura" [disabled]="botonXML"
				style="background-image: url(../../../assets/images/iconos/009-connect-1_d.png); background-size: contain !important;"
				(click)="enviarSenal('Firmar')" title="Firmar Electrónicamente"></button>

			<button *ngIf="(barraFactura === false || barraGre === false ||
			barraFacturaPro === false || barraPtoVta === false || barraEnviarroles === false) && botonReenviarCorreo === false"
			[hidden]="barraFactura"
			style="background-image: url(../../../assets/images/iconos/107-mailbox.png); background-size: contain !important;"
			(click)="enviarSenal('EnviarCorreo')" title="Enviar Correo de la factura electrónica"
			[disabled]="botonReenviarCorreo"></button>
		<button *ngIf="( barraCartera !== false && botonReenviarCorreo === true)" [hidden]="barraFactura"
			style="background-image: url(../../../assets/images/iconos/107-mailbox_d.png); background-size: contain !important;"
			(click)="enviarSenal('EnviarCorreo')" title="Enviar Correo de la factura electrónica"
			[disabled]="botonReenviarCorreo"></button>

				<!-- CDPJ -->
			<button *ngIf="( barraCartera === false ) && botonReenviarCorreo === false"
			[hidden]="barraFactura"
			style="background-image: url(../../../assets/images/iconos/024-message.png); background-size: contain !important;"
			(click)="enviarSenal('EnviarCorreo')" title="Enviar Correo de la factura electrónica"
			[disabled]="botonReenviarCorreo"></button>
			<button *ngIf="( barraCartera === false ) && botonReenviarCorreo === true" [hidden]="barraFactura"
			style="background-image: url(../../../assets/images/iconos/024-message_d.png); background-size: contain !important;"
			(click)="enviarSenal('EnviarCorreo')" title="Enviar Correo de la factura electrónica"
			[disabled]="botonReenviarCorreo"></button>

			<button *ngIf="(barraFactura === false || barraGre === false ||
			barraFacturaPro === false || barraCartera === false || barraPtoVta === false ) && botonFrmCorreos === false"
			[hidden]="barraFactura"
			style="background-image: url(../../../assets/images/iconos/107-mailbox.png); background-size: contain !important;"
			(click)="enviarSenal('FormularioCorreos')" title="Formularios del Envio de Correos"
			[disabled]="botonFrmCorreos"></button>
			<button *ngIf="botonFrmCorreos === true" [hidden]="barraFactura"
			style="background-image: url(../../../assets/images/iconos/107-mailbox_d.png); background-size: contain !important;"
			(click)="enviarSenal('FormularioCorreos')" title="Formularios del Envio de Correos"
			[disabled]="botonFrmCorreos"></button>
			<button *ngIf="(barraFactura === false || barraGre === false ||
			barraFacturaPro === false || barraCartera === false || barraPtoVta === false ) && botonFrmCorreos === false && botonEditDatosCli === false"
			[hidden]="barraFactura"
			style="background-image: url(../../../assets/images/iconos/nuevo.png); background-size: contain !important;"
			(click)="enviarSenal('Nuevo')" title="Formularios del Envio de Correos"
			[disabled]="botonFrmCorreos"></button>

			<button *ngIf="botonFrmCorreos === true && botonEditDatosCli === true" [hidden]="barraFactura"
			style="background-image: url(../../../assets/images/iconos/nuevo-d.png); background-size: contain !important;"
			(click)="enviarSenal('Nuevo')" title="Formularios del Envio de Correos"
			[disabled]="botonFrmCorreos"></button>
				<!-- CDPJ -->
				<!-- GSRF -->
				<button *ngIf="(barraFactura === false || barraGre === false ||
						barraFacturaPro === false || barraCartera === false || barraPtoVta === false || barraEnviarroles === false) && botonvernofirmados === false "
					[hidden]="barraFactura"
					style="background-image: url(../../../assets/images/iconos/014-view.png); background-size: contain !important;"
					(click)="enviarSenal('vernofirma')" title="Documentos firmados incorrectamente"
					[disabled]="botonvernofirmados"></button>
				<button *ngIf="botonvernofirmados === true" [hidden]="barraFactura"
					style="background-image: url(../../../assets/images/iconos/014-view-d.png); background-size: contain !important;"
					(click)="enviarSenal('vernofirma')" title="Documentos firmados incorrectamente"
					[disabled]="botonvernofirmados"></button>
				<!-- GSRF -->
			<button *ngIf="barraPedido === false || barraOrden === false" [hidden]="barraPedido"
				style="background-image: url(../../../assets/images/iconos/059-discount.png); border-radius: 20px"
				(click)="enviarSenal('Descuento')" title="Descuento Global"></button>
			<button *ngIf="barraOrden === false" [hidden]="barraOrden"
				style="background-image: url(../../../assets/images/iconos/072-keys.png); border-radius: 20px"
				(click)="enviarSenal('Autorizar')" title="Autorizar"></button>

			<div *ngIf="kardex===true" style="display: inline-block">
				<button style="background-image: url(../../../assets/images/iconos/flash.png);"
					(click)="enviarSenal('Rayo')" title="Visualizar Kardex"></button>
			</div>
			<ng-container *ngIf="btnLiqBenSoc">
				<button [hidden]="!botonBorrAsieCont"
					style="background-image: url(../../../assets/images/iconos/borrar-asi-cont.png);"
					(click)="enviarSenal('borrasiescont')" title="Borrar el Asiento Contable"></button>
				<button [hidden]="!botonGenAsieCont"
					style="background-image: url(../../../assets/images/iconos/genenar-asiento.png);"
					(click)="enviarSenal('genasiecont')" title="Generar Asiento Contable"></button>
				<button [hidden]="!botonVisAsieCont"
					style="background-image: url(../../../assets/images/iconos/visualizar-asiento.png);"
					(click)="enviarSenal('visasiecont')" title="Visualizar Asiento Contable"></button>
				<button [hidden]="!botonGenArText"
					style="background-image: url(../../../assets/images/iconos/gene-texto.png);"
					(click)="enviarSenal('genarchtext')" title="Generar Archivo de Texto"></button>
			</ng-container>
			<button *ngIf="botonBuscarPermanente === true"
				style="background-image: url(../../../assets/images/iconos/buscar.png);"
				(click)="enviarSenal('Buscar')" title="Buscar"></button>
				<button *ngIf="botonactplan === true"
				style="background-image: url(../../../assets/images/iconos/genenar-asiento.png);"
				(click)="enviarSenal('Cuentas')" title="Cuentas"></button>
				<button *ngIf="botonconsolida === true"
				style="background-image: url(../../../assets/images/iconos/visto.png);"
				(click)="enviarSenal('Consolidar')" title="Consolidar"></button>
				<button *ngIf="botonImprimirPermanente === true"
				style="background-image: url(../../../assets/images/iconos/026-printer.png);"
				(click)="enviarSenal('Imprimir')" title="Imprimir"></button>
				<button *ngIf="botonEstadoPermanente === true"
				style="background-image: url(../../../assets/images/iconos/flash.png);"
				(click)="enviarSenal('Cambiarestado')" title="Cambiarestado"></button>
				<button *ngIf="botonCopOrdComPermanente === true"
				style="background-image: url(../../../assets/images/iconos/exportar.png);"
				(click)="enviarSenal('Copiarordencom')" title="Copiarordencompra"></button>
				<button *ngIf="botonFacturaPermanente === true"
				style="background-image: url(../../../assets/images/iconos/folder3.png);"
				(click)="enviarSenal('Facturar')" title="Facturar"></button>
				<div style="display: inline-block; margin-left:20px; border-left: 0px solid #005594;
				height: 30px"></div>
		</div>
		<div class="p-col-2">
			<div *ngIf="barraFactura === false" [hidden]="barraFactura" style="display: inline-block;
		   margin-left:10px; border-left: 0px solid #005594; height: 30px"></div>
			<button *ngIf="botonUtiProcesar === true"
				style="background-image: url(../../../assets/images/iconos/cogwheel.png);"
				(click)="enviarSenal('procesar')" title="Procesar"></button>
			<button *ngIf="barraCashmng === true"
				style="background-image: url(../../../assets/images/iconos/011-checklist.png);"
				(click)="enviarSenal('generarTxt')" title="Generar Archivo de Texto"></button>
			<button *ngIf="botonBinocular !== true" [hidden]="barraBotones5"
				style="background-image: url(../../../assets/images/iconos/067-binocular.png);"
				(click)="enviarSenal('facturasAbonadas')" title=""></button>
			<button *ngIf="botonBuscar === false" [hidden]="barraBotones5"
				style="background-image: url(../../../assets/images/iconos/buscar.png);" (click)="enviarSenal('Buscar')"
				title="Buscar" [disabled]="botonBuscar"></button>
			<button *ngIf="botonBuscar === true" [hidden]="barraBotones5"
				style="background-image: url(../../../assets/images/iconos/buscar-d.png);"
				(click)="enviarSenal('Buscar')" title="Buscar" [disabled]="botonBuscar"></button>
			<button [hidden]="barraBotones5"
				style="background-image: url(../../../assets/images/iconos/026-printer.png);"
				(click)="enviarSenal('Imprimir')" title="Imprimir"></button>
			<button *ngIf="botonRecargo==false" style="background-image: url(../../../assets/images/iconos/flash.png);"
				(click)="enviarSenal('Recarga')" title="Recargar"></button>
			<button *ngIf="botonImprimir === true"
				style="background-image: url(../../../assets/images/iconos/026-printer.png);"
				(click)="enviarSenal('Imprimir')" title="Imprimir"></button>
			<button *ngIf="botonAlmacenardoc === true"
				style="background-image: url(../../../assets/images/iconos/folder3.png);"
				(click)="enviarSenal('Almacenardoc')" title="Almacenar documentos"></button>			
			<button style="background-image: url(../../../assets/images/iconos/salir.png);"
				(click)="enviarSenal('Salir')"></button>
		</div>
	</div>
</div>