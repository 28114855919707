//
// SACI WEB
// Rutina: Ingreso de Entradas
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 2019
// Fecha de Modificación: 14-01-2020
//
import { Component, OnInit, ViewChild } from '@angular/core';
import { InventradaService } from '../invservicios/inventrada.service';
import { Inventrada } from '../invinterfaces/inventrada';
import { Invencentrada } from '../invinterfaces/invencentrada';
import { InvMaearticulo } from '../invinterfaces/invmaearticulo';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { InvbusquedaService } from '../invservicios/invbusqueda.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { Inventradavar } from '../invinterfaces/inventradavar';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { DatePipe } from '@angular/common';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { Usuario } from 'src/app/usuario';

@Component({
  selector: 'app-inventrada',
  templateUrl: './inventrada.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css'],
  preserveWhitespaces: false
})
export class InventradaComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;
  modulo: any;

  entradas: Inventrada[];
  entradasAux: Inventrada[];
  entradasAuxVar: Inventradavar[];
  encEntradas: Invencentrada[];
  entradaSeleccionada: Inventrada;
  encentradaSeleccionada: Invencentrada = {};
  encentradaSeleccionadaAux: Invencentrada;
  entradavarSeleccionada: Inventradavar;
  permisoEntrada: SegMaePermiso;
  auxiliar: number;
  decimalesPrecioEnt: number;
  decimalesCantidadEnt: number;
  decimalesTotalEnt: number;
  botonNuevo: boolean;
  botonNuevoP: boolean;
  botonGuardarP: boolean;
  botonGuardar: boolean;
  botonRegresar: boolean;
  botonRegresarP: boolean;
  barraBotones2: boolean;
  botonPrimero: boolean;
  botonUltimo: boolean;
  botonSiguiente: boolean;
  botonAnterior: boolean;
  botonBorrar: boolean;
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  totalEntrada: number;
  displayAcciones: boolean;
  displayDialogSeriales: boolean;
  strNumFact: any;
  strFechaSer: any;
  asiento: any;
  date1: any;
  hora: any;
  indice: number;
  artNombre: InvMaearticulo;
  opcion: string;
  types: SelectItem[];
  arregloCons: any[];
  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogAsiento: boolean;
  displayAsiContable: boolean;
  displayDialogLotes: boolean;
  spin=false;//CDPJ
  control=0;
  auxiliarlot = 0;

  checkSaldoI: boolean;
  busquedacampos: string[];
  tabla: string;
  where: string;
  activeIndex: number;
  inputsBoolean: boolean;
  largo: string;
  public frameworkComponents;
  rowStyle;
  defaultColDefEntrada;
  defaultColDefEntradaVar;
  rowSelection = 'multiple';

  auxreporte: number = 0;
  strNombreForma: string;
  displayDialogReportes: boolean;

  displayDialogDocumentos = false;

  columnDefsEntrada = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba',
      // editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 10,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Artículo', field: 'ART_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    { // 2
      headerName: 'Nombre', field: 'ART_NOMBRE', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 80
        }
      }
    },
    { // 3
      headerName: 'Documento', field: 'DETKAR_NRODOC', editable: false,
      // , editable: true,
    },
    { // 4
      headerName: 'Cantidad', field: 'DETKAR_CANTIDAD', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // , editable: true,
      onCellClicked: (params) => {
        this.abrirLotes(params.data);
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    { // 5
      headerName: 'Costo', field: 'DETKAR_COSTOUNIT', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    { // 6
      headerName: 'Costo Total', field: 'DETKAR_COSTOTOTAL', cellEditor: 'cellPrueba', editable: false, cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 7
      headerName: 'C.Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 35
        }
      }
    }
  ];

  columnDefsEntradaVar = [
    {
      headerName: 'Ref. Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba',
      // editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 35
        }
      }
    },
    {
      headerName: 'Descripción', field: 'CON_NOMBRE', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 100
        }
      }
    },
    {
      headerName: 'Créditos', field: 'DETKARDEXVAR_IMPORTE', cellEditor: 'cellPrueba', cellStyle: { textAlign: 'right' },
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true
        }
      }
    },
    {
      headerName: 'Cnt. Costos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 35
        }
      }
    }];
  contadorLineaVar: number;

  constructor(public entradaService: InventradaService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private messageService: MessageService,
    private errorService: ErroresBaseDatosService, private confirmationService: ConfirmationService,
    private confIniciales: ConfInicialesService, private auditoriagral: AuditoriagralService,
    private permisosService: PermisosService, private init: NuevoComponentService,
    private utilitariosService: UtilitariosService, public usuario: Usuario) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefEntrada = {
      editable: true,
      width: 150,
      objeto: 'maeentrada'
    };
    this.defaultColDefEntradaVar = {
      editable: true,
      width: 150,
      objeto: 'maeentradavar'
    };
  }

  ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.modulo = this.init.tabs[this.init.tabindex - 1].modprefijo;
    this.entradaService.detkardexarray[this.indicador] = [];
    this.auxiliar = 0;
    this.tabla = '';
    this.displayAcciones = false;
    this.displayDialogSeriales = false;
    this.spin=false//CDPJ
    this.busquedacampos = [];
    this.botonesguardar();
    this.botonNuevoP = false;
    this.botonGuardarP = true;
    this.botonRegresarP = true;
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = true;
    this.btnCancelarGrid = true;
    this.btnBorrarGrid = true;
    this.barraBotones2 = false;
    this.totalEntrada = 0;
    this.entradas = [];
    this.activeIndex = 0;
    this.entradaService.detkardexvararray[this.indicador] = [];
    this.indice = 0;
    this.artNombre = {};
    this.checkSaldoI = false;
    this.opcion = '';
    this.date1 = new Date();
    this.hora = new Date();
    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.displayDialogLotes = false;
    this.entradaSeleccionada = {};
    this.encentradaSeleccionada = {};
    this.inputsBoolean = true;
    this.largo = '-5800';
    this.asiento = '';
    this.confIniciales.getDecimales('frmINV_ENCKARDEX').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'COSTO') {
            this.decimalesPrecioEnt = Number(decimales.NUMDEC_NUMDEC);
            this.entradaService.decimalesPrecioEnt = this.decimalesPrecioEnt;
          } else if (decimales.NUMDEC_CAMPO === 'COSTOTOTAL') {
            this.decimalesTotalEnt = Number(decimales.NUMDEC_NUMDEC);
            this.entradaService.decimalesTotalEnt = this.decimalesTotalEnt;
          } else if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
            this.decimalesCantidadEnt = Number(decimales.NUMDEC_NUMDEC);
            this.entradaService.decimalesCantidadEnt = this.decimalesCantidadEnt;
          }
        });

        this.columnDefsEntrada[4].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.entradaService.decimalesCantidadEnt,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };

        this.columnDefsEntrada[5].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.entradaService.decimalesPrecioEnt,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        };

        this.columnDefsEntrada[6].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.entradaService.decimalesTotalEnt,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32
          }
        };
        this.aggrid.refreshColumnDefs();
      }
    });
    this.permisosService.getListaPermisos('INV', '6', '79').subscribe((permiso) => {
      this.permisoEntrada = permiso[0];
    });

    this.entradaService.getEncEntradas().subscribe((res) => {
      this.encEntradas = res;
    });
    this.getConfiguracion();
  }

  getConfiguracion() {
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
      this.entradaService.hmpConfiguracion = conf;
      const ccpies = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCPIES').CFG_VALOR1;
      //CDPJ
      if (ccpies === 0 || ccpies === null || ccpies === undefined) {
        this.columnDefsEntrada.splice(7, 1);
      }
      //CDPJ
      this.aggrid.refreshColumnDefs();

    });
  }

  calcularTotalVarios() {
    let total: number;
    total = 0;
    for (const e of this.entradaService.detkardexvararray[this.indicador]) {
      total = Number(total) + Number(e.DETKARDEXVAR_IMPORTE);
    }
    return (total.toFixed(this.decimalesTotalEnt));
  }

  nuevo() {
    if (!this.existeAsiento()) {
      return;
    }
    if (this.permisoEntrada.PERINSERCION === 1) {
      this.nuevoEncEntrada();
    } else if (this.permisoEntrada.PERINSERCION === 0) {
      this.messageService.add({
        key: 'ent',
        severity: 'error',
        summary: 'Insertar Entrada',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
  }

  eliminar() {
    if (this.permisoEntrada.PERELIMACION === 1) {
      this.confirmarBorrar();
    } else if (this.permisoEntrada.PERELIMACION === 0) {
      this.messageService.add({
        key: 'ent',
        severity: 'error',
        summary: 'Eliminación Entrada',
        detail: 'El usuario no tiene permisos de eliminación, acción denegada'
      });
    }
  }

  async guardar() {
    //CDPJ
    let errores = 0;
    this.spin=true//CDPJ
    const ccpies = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCPIES').CFG_VALOR1;
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefEntrada.objeto].setFocusedCell(this.entradaService.detkardexarray[this.indicador].length - 1, 'DETKAR_COSTOTOTAL');
    this.aggrid.refreshaggrid(this.entradaService.detkardexarray[this.indicador], this.defaultColDefEntrada.objeto);
    //CDPJ
    if (this.permisoEntrada.PERACTUALIZACION === 1) {
      if (this.entradaService.detkardexarray[this.indicador].length === 0) {
        this.messageService.add({
          key: 'ent',
          severity: 'error',
          summary: 'Guardar Entrada',
          detail: 'El detalle de la entrada esta vació, no se pudo guardar la entrada'
        });
        this.control=0;
        this.spin=false//CDPJ
      } else if (this.encentradaSeleccionada.KAR_NROCOMPROBANTE === '') {
        this.messageService.add({
          key: 'ent',
          severity: 'error',
          summary: 'Guardar Entrada',
          detail: 'Ingrese el número de comprobante'
        });
        this.control=0;
        this.spin=false//CDPJ
      } //else {
      //   this.guardarEntrada();
      // }
      else {
        if((this.encentradaSeleccionada.ENCORDPRO_NUMERO === null || this.encentradaSeleccionada.ENCORDPRO_NUMERO === undefined||
          this.encentradaSeleccionada.ENCORDPRO_NUMERO === '')&&  this.modulo === 'PROD' ){
            this.messageService.add({
              key: 'ent',
              severity: 'error',
              summary: 'Guardar Entrada',
              detail: 'Ingrese el número de la orden de producción.'
            });
            this.spin=false//CDPJ
            errores=errores+1;
            this.control=0;
            return;
        }
        //this.entradaService.detkardexarray[this.indicador].map((entrada) => {
        await Promise.all(this.entradaService.detkardexarray[this.indicador].map(async (entrada) => {
          const rsdata: any = await this.entradaService.encontrarArtCntaySer(entrada.ART_CODIGO, 'A');
          const rs = await this.entradaService.erBodCodigo1(entrada.BOD_CODIGO);
          let strCodigo = 0;
                if (rsdata !== null) {
                  for (const rs of rsdata) {
                    strCodigo = rs.NUMERO;
                  }
                }
          if (ccpies === 1) {
            if (entrada.CEN_CODIGO === '') {
              this.messageService.add({
                key: 'ent',
                severity: 'error',
                summary: 'Guardar Entrada',
                detail: 'Ingrese el centro de costos'
              });
              this.spin=false//CDPJ
              errores = errores + 1;
              this.control=0;
            }

          }
          if (entrada.ART_CODIGO === '') {
            this.messageService.add({
              key: 'ent',
              severity: 'error',
              summary: 'Guardar Entrada',
              detail: 'Ingrese el código del artículo'
            });
            this.spin=false//CDPJ
            errores = errores + 1;
            this.control=0;
          }
          //CDPJ
           if(strCodigo === 0){//CDPJ
            this.messageService.add({
              key: 'ent',
              severity: 'error',
              summary: 'Información',
              detail: 'El artículo '+ entrada.ART_CODIGO +' no existe'
            });
            this.spin=false//CDPJ
            errores++;
            this.control=0;
          }
          if (entrada.BOD_CODIGO === null || entrada.BOD_CODIGO === '' || entrada.BOD_CODIGO === undefined) {
            this.messageService.add({
              key: 'ent',
              severity: 'warn',
              summary: 'Información',
              detail: 'Error, Código de la bodega está vacío'
            });
            this.spin=false//CDPJ
            errores++;
            this.control=0;
          }
          if (entrada.BOD_CODIGO !== null && entrada.BOD_CODIGO !== '' && entrada.BOD_CODIGO !== undefined) {
            if (rs === null || rs === undefined || rs === '') {
              this.messageService.add({
                key: 'ent',
                severity: 'warn',
                summary: 'Información',
                detail: 'Error, Código de la bodega ' + entrada.BOD_CODIGO + 'es incorrecto.'
              });
              this.spin=false//CDPJ
              errores++;
              this.control=0;
            }
          }
          //CDPJ
        }));
        //CDPJ
        //VALIDAR VARIOS
        for (const detentrada of this.entradaService.detkardexvararray[this.indicador]) {
          //GSRF
          console.log('/-/-/*-*-',detentrada.CON_CODIGO)
          if (detentrada.CON_CODIGO === '') {
              this.messageService.add({
                key: 'ent',
                severity: 'error',
                summary: 'Cuenta Contable',
                detail: 'Ingrese una cuenta contable'
              });
              this.spin=false//CDPJ
              errores++;
              this.control=0;
          }
          //GSRF
          if (detentrada.CON_CODIGO !== '') {
            if (detentrada.CON_CODIGO.substr(-1, 1) === '.') {
              this.messageService.add({
                key: 'ent',
                severity: 'error',
                summary: 'Cuenta Contable',
                detail: 'No se puede elegir esa cuenta contable'
              });
              this.spin=false//CDPJ
              errores++;
              this.control=0;
            }
          }
        }
        //CDPJ
        console.log('errores', errores)
        if (errores === 0) {
          this.guardarEntrada();
        }

      }
    } else if (this.permisoEntrada.PERACTUALIZACION === 0) {
      this.messageService.add({
        key: 'ent',
        severity: 'error',
        summary: 'Actualización Entrada',
        detail: 'El usuario no tiene permisos de actualización, acción denegada'
      });
      this.control=0;
    }
  }

  buscar() {
    if (!this.existeAsiento()) {
      return;
    }
    this.opcion = 'BUSQUEDA';
    this.types = [
      { label: 'Número', value: 'KAR_NRO' },
      { label: 'Fecha', value: 'KAR_FECHA' },
      { label: 'Comentario', value: 'KAR_COMENTARIO' },
      { label: 'Comprobante', value: 'KAR_NROCOMPROBANTE' },
      { label: 'No. Asiento', value: 'ASI_NRO' },
      { label: 'ORDEN PROD', value: 'ENCORDPRO_NUMERO' },
      { label: 'Origen', value: 'KAR_ORIGEN' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', ''];
    this.tabla = 'INV_ENCKARDEX';
    if (this.modulo === 'PROD') {
      this.where = 'KAR_TIPO = \'EN\' AND KAR_ORIGEN = \'PROD\' AND ROWNUM < 50 ORDER BY KAR_NRO DESC';
    } else {
      this.where = 'KAR_TIPO = \'EN\' AND (KAR_ORIGEN = \'ES_INV\' OR KAR_ORIGEN = \'INV\') AND ROWNUM < 50 ORDER BY KAR_NRO DESC';
    }
    this.busquedaSer.busquedaInvEncKardex1NEW(this.modulo).subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((registro) => {
        registro.KAR_FECHA = this.datePipe.transform(registro.KAR_FECHA, 'dd/MM/yyyy');
      });
      this.inputsBoolean = false;//GSRF 
      this.displayDialogBusquedaFast = true;
    });
  }

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir') {
      // Verifica el periodo contable
      const dia = this.date1.getDate();
      const mes = this.date1.getMonth();
      const anio = this.date1.getFullYear();


      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'ent',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    if (valor === 'Nuevo') {
      //  CDPJ
      if (this.modulo !== 'PROD') {
        if (this.encentradaSeleccionada.KAR_NRO === undefined || this.encentradaSeleccionada.KAR_NRO === null ||
          this.encentradaSeleccionada.KAR_NRO === '') {
          this.entradaService.totalEntradaarray[this.indicador] = Number(0).toFixed(0)
        }
        let totalvar: number;
        totalvar = Number(this.calcularTotalVarios());
        if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false) {
          this.messageService.add({
            key: 'ent',
            severity: 'error',
            summary: 'Generar asiento',
            detail: 'El total de varios es diferente al total del documento.'

          });
        } else {
          //await this.generarAsiento();
          this.nuevo();
        }
      } else {
        this.nuevo();
      }
      //CDPJ
      //this.nuevo();
    }


    if (valor === 'Borrar') {
      this.eliminar();
    }


    if (valor === 'Guardar') {
    if(this.control === 0){
      this.control=1
      this.guardar();
    }
      //this.guardar();
    }


    if (valor === 'Buscar') {
      if (await this.ValidarSeriales(this.encentradaSeleccionada.KAR_NRO)) {
       //  CDPJ
       if (this.modulo !== 'PROD') {
        if (this.encentradaSeleccionada.KAR_NRO === undefined || this.encentradaSeleccionada.KAR_NRO === null ||
          this.encentradaSeleccionada.KAR_NRO === '') {
          this.entradaService.totalEntradaarray[this.indicador] = Number(0).toFixed(0)
        }
        let totalvar: number;
        totalvar = Number(this.calcularTotalVarios());
        console.log('Number(this.entradaService.totalEntradaarray[this.indicador])',Number(this.entradaService.totalEntradaarray[this.indicador]))
        console.log('totalvar',totalvar)
        if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false) {
          this.messageService.add({
            key: 'ent',
            severity: 'error',
            summary: 'Generar asiento',
            detail: 'El total de varios es diferente al total del documento.'

          });
        } else {
          //await this.generarAsiento();
          this.buscar();
        }
      } else {
        this.buscar();
      }
      //CDPJ
      //this.buscar();
      }
    }


    if (valor === 'Primero') {
      if (await this.ValidarSeriales(this.encentradaSeleccionada.KAR_NRO)) {
        if (!this.existeAsiento()) {
          return;
        }
         //  CDPJ
         if (this.modulo !== 'PROD') {
          if (this.encentradaSeleccionada.KAR_NRO === undefined || this.encentradaSeleccionada.KAR_NRO === null ||
            this.encentradaSeleccionada.KAR_NRO === '') {
            this.entradaService.totalEntradaarray[this.indicador] = Number(0).toFixed(0)
          }
          let totalvar: number;
          totalvar = Number(this.calcularTotalVarios());
          if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false) {
            this.messageService.add({
              key: 'ent',
              severity: 'error',
              summary: 'Generar asiento',
              detail: 'El total de varios es diferente al total del documento.'
            });
          } else {
            this.indice = 0;
            if (this.botonUltimo === true) {
              this.botonUltimo = !this.botonUltimo;
            }
            if (this.botonSiguiente === true) {
              this.botonSiguiente = !this.botonSiguiente;
            }
            this.botonPrimero = true;
            this.botonAnterior = true;
            this.encentradaSeleccionada = this.encEntradas[0];
            this.inputsBoolean = false;
            this.botonBorrar = false;
            if (this.activeIndex === 1) {
              this.activeIndex = 0;
            }
            this.obtenerDatos();
          }
        } else {
          this.indice = 0;
          if (this.botonUltimo === true) {
            this.botonUltimo = !this.botonUltimo;
          }
          if (this.botonSiguiente === true) {
            this.botonSiguiente = !this.botonSiguiente;
          }
          this.botonPrimero = true;
          this.botonAnterior = true;
          this.encentradaSeleccionada = this.encEntradas[0];
          this.inputsBoolean = false;
          this.botonBorrar = false;
          if (this.activeIndex === 1) {
            this.activeIndex = 0;
          }
          this.obtenerDatos();
        }
        //CDPJ
        // this.indice = 0;
        // if (this.botonUltimo === true) {
        //   this.botonUltimo = !this.botonUltimo;
        // }
        // if (this.botonSiguiente === true) {
        //   this.botonSiguiente = !this.botonSiguiente;
        // }
        // this.botonPrimero = true;
        // this.botonAnterior = true;
        // this.encentradaSeleccionada = this.encEntradas[0];
        // this.inputsBoolean = false;
        // this.botonBorrar = false;
        // if (this.activeIndex === 1) {
        //   this.activeIndex = 0;
        // }
        // this.obtenerDatos();
      }
    }

    if (valor === 'Ultimo') {
      if (await this.ValidarSeriales(this.encentradaSeleccionada.KAR_NRO)) {
        if (!this.existeAsiento()) {
          return;
        }
        // this.inputsBoolean = false;
        // this.indice = this.encEntradas.length - 1;
        // this.encentradaSeleccionada = this.encEntradas[this.indice];
        // if (this.botonPrimero === true) {
        //   this.botonPrimero = !this.botonPrimero;
        // }
        // if (this.botonAnterior === true) {
        //   this.botonAnterior = !this.botonAnterior;
        // }
        // this.botonSiguiente = true;
        // this.botonUltimo = true;
        // this.obtenerDatos();
        //  CDPJ
        if (this.modulo !== 'PROD') {
          if (this.encentradaSeleccionada.KAR_NRO === undefined || this.encentradaSeleccionada.KAR_NRO === null ||
            this.encentradaSeleccionada.KAR_NRO === '') {
            this.entradaService.totalEntradaarray[this.indicador] = Number(0).toFixed(0)
          }
          let totalvar: number;
          totalvar = Number(this.calcularTotalVarios());
          if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false) {
            this.messageService.add({
              key: 'ent',
              severity: 'error',
              summary: 'Generar asiento',
              detail: 'El total de varios es diferente al total del documento.'
            });
          } else {
            this.inputsBoolean = false;
            this.indice = this.encEntradas.length - 1;
            this.encentradaSeleccionada = this.encEntradas[this.indice];
            if (this.botonPrimero === true) {
              this.botonPrimero = !this.botonPrimero;
            }
            if (this.botonAnterior === true) {
              this.botonAnterior = !this.botonAnterior;
            }
            this.botonSiguiente = true;
            this.botonUltimo = true;
            this.obtenerDatos();
          }
        } else {
          this.inputsBoolean = false;
          this.indice = this.encEntradas.length - 1;
          this.encentradaSeleccionada = this.encEntradas[this.indice];
          if (this.botonPrimero === true) {
            this.botonPrimero = !this.botonPrimero;
          }
          if (this.botonAnterior === true) {
            this.botonAnterior = !this.botonAnterior;
          }
          this.botonSiguiente = true;
          this.botonUltimo = true;
          this.obtenerDatos();
        }
        //CDPJ
      }
    }

    if (valor === 'Anterior') {
      if (await this.ValidarSeriales(this.encentradaSeleccionada.KAR_NRO)) {
        if (!this.existeAsiento()) {
          return;
        }
        //  CDPJ
        if (this.modulo !== 'PROD') {
          if (this.encentradaSeleccionada.KAR_NRO === undefined || this.encentradaSeleccionada.KAR_NRO === null ||
            this.encentradaSeleccionada.KAR_NRO === '') {
            this.entradaService.totalEntradaarray[this.indicador] = Number(0).toFixed(0)
          }
          let totalvar: number;
          totalvar = Number(this.calcularTotalVarios());
          if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false) {
            this.messageService.add({
              key: 'ent',
              severity: 'error',
              summary: 'Generar asiento',
              detail: 'El total de varios es diferente al total del documento.'

            });
          } else {
            this.botonBorrar = false;
            this.inputsBoolean = false;
            this.indice--;
            this.encentradaSeleccionada = this.encEntradas[this.indice];
            if (this.botonUltimo === true && this.botonSiguiente === true) {
              this.botonUltimo = false;
              this.botonSiguiente = false;
            }
            if (this.indice <= 0) {
              this.botonPrimero = true;
              this.botonAnterior = true;
              if (this.indice === 0) {
                this.obtenerDatos();
              } else {
                return;
              }
            } else {
              this.obtenerDatos();
            }
          }
        } else {
          this.botonBorrar = false;
          this.inputsBoolean = false;
          this.indice--;
          this.encentradaSeleccionada = this.encEntradas[this.indice];
          if (this.botonUltimo === true && this.botonSiguiente === true) {
            this.botonUltimo = false;
            this.botonSiguiente = false;
          }
          if (this.indice <= 0) {
            this.botonPrimero = true;
            this.botonAnterior = true;
            if (this.indice === 0) {
              this.obtenerDatos();
            } else {
              return;
            }
          } else {
            this.obtenerDatos();
          }
        }
        //CDPJ
        // this.botonBorrar = false;
        // this.inputsBoolean = false;
        // this.indice--;
        // this.encentradaSeleccionada = this.encEntradas[this.indice];
        // if (this.botonUltimo === true && this.botonSiguiente === true) {
        //   this.botonUltimo = false;
        //   this.botonSiguiente = false;
        // }
        // if (this.indice <= 0) {
        //   this.botonPrimero = true;
        //   this.botonAnterior = true;
        //   if (this.indice === 0) {
        //     this.obtenerDatos();
        //   } else {
        //     return;
        //   }
        // } else {
        //   this.obtenerDatos();
        // }
      }
    }

    if (valor === 'Siguiente') {
      if (await this.ValidarSeriales(this.encentradaSeleccionada.KAR_NRO)) {

        if (!this.existeAsiento()) {
          return;
        }
         //  CDPJ
         if (this.modulo !== 'PROD') {
          if (this.encentradaSeleccionada.KAR_NRO === undefined || this.encentradaSeleccionada.KAR_NRO === null ||
            this.encentradaSeleccionada.KAR_NRO === '') {
            this.entradaService.totalEntradaarray[this.indicador] = Number(0).toFixed(0)
          }
          let totalvar: number;
          totalvar = Number(this.calcularTotalVarios());
          if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false) {
            this.messageService.add({
              key: 'ent',
              severity: 'error',
              summary: 'Generar asiento',
              detail: 'El total de varios es diferente al total del documento.'

            });
          } else {
            this.inputsBoolean = false;
            this.indice++;
            this.encentradaSeleccionada = this.encEntradas[this.indice];
            if (this.botonPrimero === true && this.botonAnterior === true) {
              this.botonPrimero = false;
              this.botonAnterior = false;
            }
            if (this.indice >= this.encEntradas.length - 1) {
              this.botonUltimo = true;
              this.botonSiguiente = true;
              if (this.indice === this.encEntradas.length - 1) {
                this.obtenerDatos();
              } else {
                return;
              }
            } else {
              this.obtenerDatos();
            }
          }
        } else {
          this.inputsBoolean = false;
          this.indice++;
          this.encentradaSeleccionada = this.encEntradas[this.indice];
          if (this.botonPrimero === true && this.botonAnterior === true) {
            this.botonPrimero = false;
            this.botonAnterior = false;
          }
          if (this.indice >= this.encEntradas.length - 1) {
            this.botonUltimo = true;
            this.botonSiguiente = true;
            if (this.indice === this.encEntradas.length - 1) {
              this.obtenerDatos();
            } else {
              return;
            }
          } else {
            this.obtenerDatos();
          }
        }
        //CDPJ
        // this.inputsBoolean = false;
        // this.indice++;
        // this.encentradaSeleccionada = this.encEntradas[this.indice];
        // if (this.botonPrimero === true && this.botonAnterior === true) {
        //   this.botonPrimero = false;
        //   this.botonAnterior = false;
        // }
        // if (this.indice >= this.encEntradas.length - 1) {
        //   this.botonUltimo = true;
        //   this.botonSiguiente = true;
        //   if (this.indice === this.encEntradas.length - 1) {
        //     this.obtenerDatos();
        //   } else {
        //     return;
        //   }
        // } else {
        //   this.obtenerDatos();
        // }
      }
    }

    if (valor === 'Cancelar') {
      this.botonesguardar();
      this.encEntradas = [];
      this.entradaService.getEncEntradas().subscribe((res) => {
        this.encEntradas = res;
        for (const a in this.encEntradas) {
          if (this.encEntradas[a].KAR_NRO === this.encentradaSeleccionada.KAR_NRO) {
            this.encentradaSeleccionadaAux = this.encEntradas[a];
          }
        }
        if (this.encentradaSeleccionada.KAR_NRO !== '' && this.encentradaSeleccionada.KAR_NRO !== null &&
          this.encentradaSeleccionada.KAR_NRO !== undefined) {
          this.encentradaSeleccionada = this.encentradaSeleccionadaAux;
          this.obtenerDatos();
          this.enviarCancelarEntradaVar();
        } else {
          this.nuevoEncEntrada();
          this.botonesguardar();
        }
      });
    }

    if (valor === 'Asiento') {
      if (this.modulo !== 'PROD') {
        let totalvar: number;
        totalvar = Number(this.calcularTotalVarios());
        if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false) {
          this.messageService.add({
            key: 'ent',
            severity: 'error',
            summary: 'Generar asiento',
            detail: 'El total de varios es diferente al total del documento.' +
              'No se puede generar el Asiento contable'
          });
        } else {
          //await this.generarAsiento();
          this.generarAsiento();//CDPJ
        }
      }else{
        await this.generarAsientoPod();
        
      }
    }

    if (valor === 'AsientoVer') {
      //if (this.modulo !== 'PROD') {
        this.entradaService.getAsiento(this.encentradaSeleccionada).subscribe((res) => {
          this.encentradaSeleccionada.ASI_NRO = res[0].ASI_NRO;
          this.asiento = res[0].ASI_NRO;
          if (this.asiento === null) {
            this.messageService.add({
              key: 'ent',
              severity: 'error',
              summary: 'Visualizacion de Asiento Contable',
              detail: 'Esta entrada no tiene un asiento contable'
            });
          } else {
            this.auxiliar++;
            // this.displayDialogAsiento = true;
            this.displayAsiContable = true;
          }
        });
      //}
    }

    if (valor === 'Salir') {
      if (await this.ValidarSeriales(this.encentradaSeleccionada.KAR_NRO)) {
        if (!this.existeAsiento()) {
        } else {
           //  CDPJ
           if (this.modulo !== 'PROD') {
            if (this.encentradaSeleccionada.KAR_NRO === undefined || this.encentradaSeleccionada.KAR_NRO === null ||
              this.encentradaSeleccionada.KAR_NRO === '') {
              this.entradaService.totalEntradaarray[this.indicador] = Number(0).toFixed(0)
            }
            let totalvar: number;
            totalvar = Number(this.calcularTotalVarios());
            if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false) {
              this.messageService.add({
                key: 'ent',
                severity: 'error',
                summary: 'Generar asiento',
                detail: 'El total de varios es diferente al total del documento.'

              });
            } else {
              //await this.generarAsiento();
              this.init.tabs.splice(this.init.tabindex, 1);
            }
          } else {
            this.init.tabs.splice(this.init.tabindex, 1);
          }
          //CDPJ
          // this.init.tabs.splice(this.init.tabindex, 1);
        }
      }
    }

    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }

    if (valor === 'Almacenardoc') {
      if (this.encentradaSeleccionada.KAR_NRO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'ent',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
  }

  seleccionEntrada(params) {
    if (params === 'nuevo') {
      this.nuevoDetEntrad();
    } else if (params === 'eliminar') {
      this.enviarBorrarEntrada();
    } else if (params === 'cancelar') {
      // this.enviarCancelarEntrada();
    } else if (params.object !== undefined) {
      this.entradaSeleccionada = params.object;
      if (this.encentradaSeleccionada.KAR_NRO !== undefined || this.encentradaSeleccionada.KAR_NRO !== '') {
        this.entradaSeleccionada.KAR_NRO = this.encentradaSeleccionada.KAR_NRO;
      }
    }
  }

  nuevoDetEntrad() {
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = false;
    this.btnBorrarGrid = false;
    this.btnCancelarGrid = false;
    let numero;
    if (this.encentradaSeleccionada.KAR_NRO !== undefined || this.encentradaSeleccionada.KAR_NRO !== '') {
      numero = this.encentradaSeleccionada.KAR_NRO;
    } else if (this.encentradaSeleccionada.KAR_NRO === undefined || this.encentradaSeleccionada.KAR_NRO === '') {
      numero = '';
    }

    let hora;
    let minutos;
    hora = this.hora.getHours();
    minutos = this.hora.getMinutes();
    if (hora.toString().length === 1) {
      hora = '0' + hora;
    }
    if (minutos.toString().length === 1) {
      minutos = '0' + minutos;
    }
    const hour = hora + ':' + minutos;
    const date = this.datePipe.transform(this.date1, 'dd/MM/yyyy');
    let i = this.entradaService.detkardexarray[this.indicador].length + 1;//CDPJ
    const nuevaentrada = {
      CLAVE: i + this.datePipe.transform(new Date(), 'dd/MM/yyyyHHmmss'),//CDPJ
      DETKAR_CANTIDAD: 1.00, DETKAR_COSTOUNIT: 1.00, DETKAR_COSTOTOTAL: 1.00,
      DETKAR_LINEA: 0, KAR_NRO: numero, DETKAR_NRODOC: '', ENCGRE_CODIGO: '',
      DETGRE_LINEA: '0', DETKAR_LOTE: '', CLI_CODIGO: '', DETKAR_PUNTOS: '0', MAEPUN_CODIGO: '', DETKAR_CODIGOAUX: '',
      DETKAR_CAJAS: '', DETKAR_FRACCIONES: '', CON_CODIGO: '', BOD_CODIGO: '', ART_CODIGO: '', ART_NOMBRE: '', COM_CODIGO: '01',
      DETKAR_CANTIDADUND: 0, FECHA: date + '' + hour, HORA: hour, CEN_CODIGO: ''
    };
    this.entradaSeleccionada = nuevaentrada;
    this.entradaService.detkardexarray[this.indicador].push(nuevaentrada);
    this.aggrid.refreshaggrid(this.entradaService.detkardexarray[this.indicador], this.defaultColDefEntrada.objeto);
  }

  bodegaDetalle(): string {
    let bodega = '';
    let i = 0;
    for (const rs of this.entradaService.detkardexarray[this.indicador]) {
      if (i === 0) {
        bodega = rs.BOD_CODIGO;
      }
      i++;
    }
    return bodega;
  }

  seleccionEntradaVar(params) {
    if (params === 'nuevo') {
      let numeroLinea = 1;
      let cenCodigo = '';
      this.entradaService.getEntradasVar(this.encentradaSeleccionada.KAR_NRO).subscribe((res) => {
        if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BODCENCOS').CFG_VALOR1 === 0) {
          cenCodigo = '';
        } else {
          cenCodigo = this.bodegaDetalle();
        }
        numeroLinea = res.length + 1;
        let nuevaEntradaVar: Inventradavar;
        nuevaEntradaVar = {
          CLAVE: numeroLinea + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss'),
          DETKARDEXVAR_IMPORTE: Number(Number(this.entradaService.totalEntradaarray[this.indicador]) - this.totalesVarios()).toFixed(2),
          DETKARDEXVAR_LINEA: numeroLinea,
          KAR_NRO: this.encentradaSeleccionada.KAR_NRO,
          CON_NOMBRE: '',
          CEN_CODIGO: '',
          CON_CODIGO: '',
          COM_CODIGO: ''
        };
        this.entradaService.detkardexvararray[this.indicador].push(nuevaEntradaVar);
        this.entradavarSeleccionada = nuevaEntradaVar
        this.aggrid.refreshaggrid(this.entradaService.detkardexvararray[this.indicador], this.defaultColDefEntradaVar.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefEntradaVar.objeto].setFocusedCell(this.entradaService.detkardexvararray[this.indicador].length - 1, 'CON_CODIGO');
      });
    }

    if (params === 'eliminar') {
      this.enviarBorrarEntradaVar();
    }

    if (params === 'cancelar') {
      this.enviarCancelarEntradaVar();
    }

    if (params.object !== undefined) {
      this.entradavarSeleccionada = params.object;
      if (this.encentradaSeleccionada.KAR_NRO !== undefined || this.encentradaSeleccionada.KAR_NRO !== '') {
        this.entradavarSeleccionada.KAR_NRO = this.encentradaSeleccionada.KAR_NRO;
      }
    }
  }

  manejarSeleccion(opcion) {
    //GSRF
    if (this.opcion === 'orden') {
      this.encentradaSeleccionada.ENCORDPRO_NUMERO = opcion.ENCORDPRO_NUMERO
      this.displayDialogBusqueda = false;
      this.displayDialogBusquedaFast = false;
    }

    //GSRF
    if (this.opcion === 'BUSQUEDA') {
      this.encEntradas.map((enca) => {
        if (enca.KAR_NRO === opcion.KAR_NRO) {
          this.indice = this.encEntradas.indexOf(enca);
        }
      });
      if (this.indice === this.encEntradas.length - 1) {
        this.botonUltimo = true;
        this.botonSiguiente = true;
        this.encentradaSeleccionada = this.encEntradas[this.indice];
        this.obtenerDatos();
      } else if (this.indice === 0) {
        this.botonPrimero = true;
        this.botonAnterior = true;
        this.encentradaSeleccionada = this.encEntradas[this.indice];
        this.obtenerDatos();
      } else {
        this.botonPrimero = false;
        this.botonAnterior = false;
        this.botonSiguiente = false;
        this.botonUltimo = false;
        this.encentradaSeleccionada = this.encEntradas[this.indice];
        this.obtenerDatos();
      }
      this.displayDialogBusqueda = false;
      this.displayDialogBusquedaFast = false;
    }
  }

  nuevoEncEntrada() {
    this.botonNuevo = true;
    this.botonGuardar = false;
    this.botonBorrar = true;
    this.botonRegresar = false;
    this.inputsBoolean = false;
    let encEntrada: Invencentrada;
    encEntrada = {
      KAR_NRO: '', KAR_TIPO: 'EN', COM_CODIGO: '01', TIPDIA_CODIGO: 'EN', USUIDENTIFICACION: this.usuario.identificacion, KAR_NO_KARDEX: '0',
      KAR_SALDOINI: '', KAR_ESTADO: '', ENCORDPRO_NUMERO: '', KAR_COMENTARIO: ''
    };
    this.date1 = new Date();
    this.hora = new Date();
    // this.encEntradas.push(encEntrada);
    this.encentradaSeleccionada = encEntrada;
    this.entradaService.detkardexarray[this.indicador] = [];
    this.entradaService.detkardexvararray[this.indicador] = [];
    this.activeIndex = 0;
    document.getElementById('nroCompra').focus();
    this.checkSaldoI = false;
  }

  enviarBorrarEntrada() {
    if (this.entradaSeleccionada === undefined) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.entradaService.borrarEntrada(this.entradaService.detkardexarray[this.indicador][this.entradaService.detkardexarray[this.indicador].length - 1]).subscribe(() => {
        this.messageService.add({
          key: 'ent',
          severity: 'success',
          summary: 'Eliminación de Entrada',
          detail: 'La entrada se eliminó correctamente'
        });
        this.entradaService.detkardexarray[this.indicador] = this.entradaService.detkardexarray[this.indicador].filter
          ((val, j) => j !== this.entradaService.detkardexarray[this.indicador].indexOf
            (this.entradaService.detkardexarray[this.indicador][this.entradaService.detkardexarray[this.indicador].length - 1]));
        //CDPJ
        this.entradaService.calcularTotal();
        this.btnNuevoGrid = false;
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'ent',
          severity: 'error',
          summary: 'Eliminación de Entrada',
          detail: mensaje
        });
      });
      return;
    }
    let contador = 0;
    let i = 0;
    do {
      if (this.entradaSeleccionada === this.entradaService.detkardexarray[this.indicador][i]) {
        contador++;
      }
      i++;
    } while (this.entradaService.detkardexarray[this.indicador].length > i);
    if (contador === 1) {
      this.entradaService.borrarEntrada(this.entradaSeleccionada).subscribe(() => {
        this.messageService.add({
          key: 'ent',
          severity: 'success',
          summary: 'Eliminación de Entrada',
          detail: 'La entrada se eliminó correctamente'
        });
        this.entradaService.detkardexarray[this.indicador] = this.entradaService.detkardexarray[this.indicador].filter((val, j) =>
          j !== this.entradaService.detkardexarray[this.indicador].indexOf(this.entradaSeleccionada));
        //CDPJ
        this.entradaService.calcularTotal();
        this.btnNuevoGrid = false;
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'ent',
          severity: 'error',
          summary: 'Eliminación de Entrada',
          detail: mensaje
        });
      });
    } else if (contador !== 1) {
      this.entradaService.detkardexarray[this.indicador] = this.entradaService.detkardexarray[this.indicador].filter((val, j) =>
        j !== this.entradaService.detkardexarray[this.indicador].indexOf(this.entradaSeleccionada));
    }
  }

  enviarBorrarEntradaVar() {
    if (this.entradavarSeleccionada !== undefined) {
      this.entradaService.getEntradasVar(this.encentradaSeleccionada.KAR_NRO).subscribe((res) => {
        if (res.length !== 0) {
          if(this.entradavarSeleccionada.CLAVE === null || this.entradavarSeleccionada.CLAVE === undefined || this.entradavarSeleccionada.CLAVE === ''){
            this.entradaService.borrarEntradaVar(this.entradaService.detkardexvararray[this.indicador][this.entradaService.detkardexvararray[this.indicador].length - 1]).subscribe(() => {
              this.messageService.add({
                key: 'ent',
                severity: 'success',
                summary: 'Eliminación de Varios',
                detail: 'La entrada varios se eliminó correctamente'
              });
              this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR',this.encentradaSeleccionada.KAR_NRO+'/'+this.entradavarSeleccionada.CON_CODIGO, 'E',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
              this.entradaService.detkardexvararray[this.indicador] = this.entradaService.detkardexvararray[this.indicador].filter
                ((val, j) => j !== this.entradaService.detkardexvararray[this.indicador].indexOf
                  (this.entradaService.detkardexvararray[this.indicador][this.entradaService.detkardexvararray[this.indicador].length - 1]));
              //CDPJ
              
              this.calcularTotalVarios()
              this.btnNuevoGrid = false;
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.messageService.add({
                key: 'ent',
                severity: 'error',
                summary: 'Eliminación de Entrada',
                detail: mensaje
              });
            });
          }else{
            this.entradaService.detkardexvararray[this.indicador] = this.entradaService.detkardexvararray[this.indicador].filter
            ((val, j) => j !== this.entradaService.detkardexvararray[this.indicador].indexOf
              (this.entradaService.detkardexvararray[this.indicador][this.entradaService.detkardexvararray[this.indicador].length - 1]));
          }
          
        } else if (res.length === 0) {
          this.entradaService.detkardexvararray[this.indicador] = this.entradaService.detkardexvararray[this.indicador].filter
            ((val, j) => j !== this.entradaService.detkardexvararray[this.indicador].indexOf
              (this.entradaService.detkardexvararray[this.indicador][this.entradaService.detkardexvararray[this.indicador].length - 1]));
        }
      });
      return;
    }
    let contador = 0;
    let i = 0;
    do {
      if (this.entradavarSeleccionada === this.entradaService.detkardexvararray[this.indicador][i]) {
        contador++;
      }
      i++;
    } while (this.entradaService.detkardexvararray[this.indicador].length > i);
    if (contador === 1) {
      this.entradaService.borrarEntradaVar(this.entradavarSeleccionada).subscribe(() => {
        this.messageService.add({
          key: 'ent',
          severity: 'success',
          summary: 'Eliminación de Entrada Varios',
          detail: 'La entrada en varios se eliminó correctamente'
        });
        // this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR',this.encentradaSeleccionada.KAR_NRO+'/'+this.entradavarSeleccionada.CON_CODIGO, 'E',
        //         '', '01', '', '', '', '').subscribe(() => {
        //         });
        this.entradaService.detkardexvararray[this.indicador] = this.entradaService.detkardexvararray[this.indicador].filter((val, j) =>
          j !== this.entradaService.detkardexvararray[this.indicador].indexOf(this.entradavarSeleccionada));
        //CDPJ
        this.calcularTotalVarios()
        this.btnNuevoGrid = false;
      }, error1 => {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'ent',
          severity: 'error',
          summary: 'Eliminación de Entrada',
          detail: mensaje
        });
      });
    } else if (contador !== 1) {
      this.entradaService.detkardexvararray[this.indicador] = this.entradaService.detkardexvararray[this.indicador].filter((val, j) =>
        j !== this.entradaService.detkardexvararray[this.indicador].indexOf(this.entradavarSeleccionada));
    }
  }

  cambio(valor) {
    this.botonGuardar = false;
    this.botonRegresar = false;
    this.botonNuevo = true;
    this.botonBorrar = false;
    this.btnNuevoGrid = false;
    this.botonUltimo = true;
    this.botonAnterior = true;
    this.botonSiguiente = true;
    this.botonPrimero = true;
  }

  botonesguardar() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
  }

  async guardarEntrada() {
    //CDPJ
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefEntrada.objeto].setFocusedCell(this.entradaService.detkardexarray[this.indicador].length - 1, 'DETKAR_COSTOTOTAL');
    this.aggrid.refreshaggrid(this.entradaService.detkardexarray[this.indicador], this.defaultColDefEntrada.objeto);
    this.entradaService.calcularTotal();
    let totalvar: number;
    totalvar = Number(this.calcularTotalVarios());
    if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false && this.modulo !== 'PROD') {
      this.messageService.add({
        key: 'ent',
        severity: 'error',
        summary: 'Generar asiento',
        detail: 'El total de varios es diferente al total del documento.' +
          'No se puede guardar la entrada'
      });
      this.spin=false//CDPJ
      this.control=0;
      this.checkSaldoI = false;
      this.aggrid.refreshaggrid(this.entradaService.detkardexarray[this.indicador], this.defaultColDefEntrada.objeto);
      this.entradaService.calcularTotal();
      return;
    } else {
      //GSRF
      if (this.modulo === 'PROD') {
        this.encentradaSeleccionada.KAR_ORIGEN = 'PROD';
      } else {
        if (this.checkSaldoI === true) {
          this.encentradaSeleccionada.KAR_ORIGEN = 'INV';
          this.encentradaSeleccionada.KAR_SALDOINI = 'S';
        } else if (this.checkSaldoI === false) {
          this.encentradaSeleccionada.KAR_ORIGEN = 'ES_INV';
        }

      }
      //GSRF

      // if (this.checkSaldoI === true) {
      //   this.encentradaSeleccionada.KAR_ORIGEN = 'INV';
      //   this.encentradaSeleccionada.KAR_SALDOINI = 'S';
      // } else if (this.checkSaldoI === false) {
      //   this.encentradaSeleccionada.KAR_ORIGEN = 'ES_INV';

      // }
      let hora;
      let minutos;
      hora = this.hora.getHours();
      minutos = this.hora.getMinutes();
      if (hora.toString().length === 1) {
        hora = '0' + hora;
      }
      if (minutos.toString().length === 1) {
        minutos = '0' + minutos;
      }
      this.encentradaSeleccionada.KAR_HORA = hora + ':' + minutos;
      this.encentradaSeleccionada.KAR_FECHA = this.datePipe.transform(this.date1, 'dd/MM/yyyy');
      console.log(this.encentradaSeleccionada.KAR_NRO)
      if (this.encentradaSeleccionada.KAR_NRO !== '' && this.encentradaSeleccionada.KAR_NRO !== undefined && this.encentradaSeleccionada.KAR_NRO !== null) {
        this.entradaService.actualizarEncEntrada(this.encentradaSeleccionada).subscribe(async () => {
          this.messageService.add({
            key: 'ent',
            severity: 'success',
            summary: 'Actualización de Entrada',
            detail: 'Se actualizó la entrada correctamente'
          });
          this.spin=false//CDPJ
          this.control=0;
          this.auditoriagral.registrarAuditoria('INV_ENCKARDEX', String(this.encentradaSeleccionada.KAR_NRO), 'A',
            '', '01', '', '', '', '').subscribe(() => {
            });
          //CDPJ
          this.entradaService.calcularTotal();
          if (this.entradaService.detkardexarray[this.indicador].length !== 0) {
            // console.log(this.entradaSeleccionada);
            for (const entrada of this.entradaService.detkardexarray[this.indicador]){
            //this.entradaService.detkardexarray[this.indicador].map((entrada) => {
              entrada.KAR_NRO = this.encentradaSeleccionada.KAR_NRO;
              entrada.DETKAR_NRODOC = '';
              entrada.CON_CODIGO = '';
              entrada.ENCGRE_CODIGO = '';
              entrada.DETGRE_LINEA = '';
              entrada.DETKAR_LOTE = '';
              entrada.CLI_CODIGO = '';
              entrada.MAEPUN_CODIGO = '';
              entrada.DETKAR_CODIGOAUX = '';
              entrada.DETKAR_CAJAS = '';
              entrada.DETKAR_FRACCIONES = '';
              entrada.DETKAR_CANTIDADUND = 0;
              try {
                const res1=await this.entradaService.insertarEntrada(entrada).toPromise()
                 //this.entradaService.insertarEntrada(entrada).subscribe((res1) => {
                //const detKarLinea = JSON.stringify(res1[0]);
                //this.obtenerDetKarLinea(detKarLinea, entrada);
                if( entrada.DETKAR_LINEA === 0){
                  this.auditoriagral.registrarAuditoria('INV_DETKARDEX', String(entrada.KAR_NRO)+'/'+entrada.ART_CODIGO, 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                }else if( entrada.DETKAR_LINEA > 0){
                  this.auditoriagral.registrarAuditoria('INV_DETKARDEX', String(entrada.KAR_NRO)+'/'+entrada.ART_CODIGO, 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                }
               for(const rs of res1){
                 console.log(rs)
                 console.log(rs[':B1'])
                 entrada.DETKAR_LINEA=rs[':B1']
               }
               
                await this.entradaService.actualizarKardex_en_sa(this.encentradaSeleccionada).toPromise()//subscribe(() => {
                //});
              } catch (error) {
                  this.messageService.add({
                  key: 'ent',
                  severity: 'error',
                  summary: 'Actualización de Entrada',
                  detail: 'No se puede insertar el detalle'
                });
                this.control=0;
                this.spin=false//CDPJ
              }
            }
            if (this.modulo === 'PROD') {
              await this.generarAsientoPod()
             }
          } else {
            return;
          }
          
          if (this.entradavarSeleccionada !== {}) {
            this.contadorLineaVar = 0;
            try {
              //CDPJ
              await this.entradaService.eliminarEntradasVarProm(this.entradaSeleccionada.KAR_NRO);
              for (const detentrada of this.entradaService.detkardexvararray[this.indicador]) {
                this.contadorLineaVar += 1;
                if (detentrada.CON_CODIGO !== '') {
                  detentrada.KAR_NRO = this.encentradaSeleccionada.KAR_NRO;
                  detentrada.DETKARDEXVAR_LINEA = this.contadorLineaVar;
                  await this.entradaService.insertarEntradaVarProm(detentrada);
                  if(detentrada.CLAVE === null || detentrada.CLAVE === undefined || detentrada.CLAVE === ''){
                    this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR', String(detentrada.KAR_NRO)+'/'+detentrada.CON_CODIGO, 'A',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                  }else{
                    this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR', String(detentrada.KAR_NRO)+'/'+detentrada.CON_CODIGO, 'I',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                  }
                  
    
                }
              }
              let totalvar: number;
              totalvar = Number(this.calcularTotalVarios());
              // if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false) {
              //   this.messageService.add({
              //     key: 'ent',
              //     severity: 'error',
              //     summary: 'Generar asiento',
              //     detail: 'El total de varios es diferente al total del documento.' +
              //       'No se puede generar el Asiento contable'
              //   });
              // } else {
              //   //await this.generarAsiento();
              //   this.generarAsiento();//CDPJ
              //   this.botonesguardar();
              // }
              //CDPJ
              if (this.modulo !== 'PROD') {
                if (this.checkSaldoI === false) {
                  if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar) {
                    //console.log('porque entro ',this.checkSaldoI);
                    this.messageService.add({
                      key: 'ent',
                      severity: 'error',
                      summary: 'Generar asiento',
                      detail: 'El total de varios es diferente al total del documento.' +
                        'No se puede generar el Asiento contable'
                    });
                    this.spin=false//CDPJ
                  } else {
                    //console.log('porque entro al else',this.checkSaldoI);
                    //await this.generarAsiento();
                    this.generarAsiento();//CDPJ
                    this.botonesguardar();
                    
                  }
                }
              }
              //CDPJ
              if (this.modulo !== 'PROD') {
                this.messageService.add({
                  key: 'ent',
                  severity: 'success',
                  summary: 'Inserción de Entrada Varios',
                  detail: 'Se ingresó la entrada varios correctamente'
                });
                this.control=0;
                this.spin=false//CDPJ
              }
            } catch (err) {
              this.messageService.add({
                key: 'ent',
                severity: 'error',
                summary: 'Inserción de Entrada Varios',
                detail: 'Ocurrió un error al insertar entrada varios'
              });
              this.control=0;
              this.spin=false//CDPJ
            }
    
            // ANTIGUO
            /*this.entradaService.eliminarEntradasVar(this.entradaSeleccionada.KAR_NRO).subscribe(() => {
              this.entradaService.detkardexvararray[this.indicador].map((detentrada) => {
                if (detentrada.CON_CODIGO !== '') {
                  this.entradaService.insertarEntradaVar(detentrada).subscribe(() => {
                    this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR', String(detentrada.KAR_NRO), 'I',
                      '', '01', '', '', '', '').subscribe(() => { 
                    });
                  });
                }
              });
              let totalvar: number;
              totalvar = Number(this.calcularTotalVarios());
              if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar) {
                this.messageService.add({
                  key: 'ent',
                  severity: 'error',
                  summary: 'Generar asiento',
                  detail: 'El total de varios es diferente al total del documento.' +
                    'No se puede generar el Asiento contable'
                });
              } else {
                this.generarAsiento();
                this.botonesguardar();
              }
            }); */
            /* this.messageService.add({
              key: 'ent',
              severity: 'success',
              summary: 'Inserción de Entrada Varios',
              detail: 'Se ingresó la entrada varios correctamente'
            }); */
          } 
          //CDPJ
          this.botonesguardar();
      const res = await this.entradaService.getEncEntradas().toPromise()//subscribe((res) => {
        this.encEntradas = res;
       this.indice = this.encEntradas.length - 1
     //});
     await this.manejarSenales('Cancelar');
     //CDPJ
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.messageService.add({
            key: 'ent',
            severity: 'error',
            summary: 'Actualización de Entrada',
            detail: mensaje
          });
          this.spin=false//CDPJ
        });
        
      } else {
        this.entradaService.insertarEncEntrada(this.encentradaSeleccionada).subscribe(async (res) => {
          this.encentradaSeleccionada.KAR_NRO = JSON.stringify(res[0]).substr(8, 12);
          this.messageService.add({
            key: 'ent',
            severity: 'success',
            summary: 'Inserción de Entrada',
            detail: 'Se ingresó la entrada correctamente'
          });
          this.spin=false//CDPJ
          this.control=0;
          this.auditoriagral.registrarAuditoria('INV_ENCKARDEX', String(this.encentradaSeleccionada.KAR_NRO), 'I',
            '', '01', '', '', '', '').subscribe(() => {
            });
          if (this.entradaService.detkardexarray[this.indicador].length !== 0) {
            // console.log(this.entradaSeleccionada);
            for (const entrada of  this.entradaService.detkardexarray[this.indicador]){
            try {
              // this.entradaService.detkardexarray[this.indicador].map((entrada) => {
                entrada.KAR_NRO = this.encentradaSeleccionada.KAR_NRO;
                entrada.DETKAR_COSTOTOTAL = (Number(entrada.DETKAR_COSTOUNIT) *
                  Number(entrada.DETKAR_CANTIDAD)) + '';
                  const res1=await this.entradaService.insertarEntrada(entrada).toPromise()
                  console.log(entrada);
                  console.log(res1);
                   //this.entradaService.insertarEntrada(entrada).subscribe((res1) => {
                  //const detKarLinea = JSON.stringify(res1[0]);
                  //this.obtenerDetKarLinea(detKarLinea, entrada);
                 for(const rs of res1){
                   console.log(rs)
                   console.log(rs[':B1'])
                   entrada.DETKAR_LINEA=rs[':B1']
                 }
                  //const detKarLinea = JSON.stringify(res1[0]);
                  //this.obtenerDetKarLinea(detKarLinea, entrada);
                  this.auditoriagral.registrarAuditoria('INV_DETKARDEX', String(entrada.KAR_NRO), 'I',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                  await this.entradaService.actualizarKardex_en_sa(this.encentradaSeleccionada).toPromise()//subscribe(() => {
                  //});
                // }, error1 => {
                //   this.messageService.add({
                //     key: 'ent',
                //     severity: 'error',
                //     summary: 'Actualización de Entrada',
                //     detail: 'No se puede insertar el detalle'
                //   });
                //   this.spin=false
            } catch (error) {
              this.messageService.add({
                    key: 'ent',
                    severity: 'error',
                    summary: 'Actualización de Entrada',
                    detail: 'No se puede insertar el detalle'
                  });
                  this.control=0;
                  this.spin=false
            }
              // });
            }//);
            if (this.modulo === 'PROD') {
              await this.generarAsientoPod()
             }
          } else {
            this.spin=false//CDPJ
            return;
          }
          if (this.entradavarSeleccionada !== {}) {
            this.contadorLineaVar = 0;
            try {
              //CDPJ
              await this.entradaService.eliminarEntradasVarProm(this.entradaSeleccionada.KAR_NRO);
              for (const detentrada of this.entradaService.detkardexvararray[this.indicador]) {
                this.contadorLineaVar += 1;
                if (detentrada.CON_CODIGO !== '') {
                  detentrada.KAR_NRO = this.encentradaSeleccionada.KAR_NRO;
                  detentrada.DETKARDEXVAR_LINEA = this.contadorLineaVar;
                  await this.entradaService.insertarEntradaVarProm(detentrada);
                  if(detentrada.CLAVE === null || detentrada.CLAVE === undefined || detentrada.CLAVE === ''){
                    this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR', String(detentrada.KAR_NRO)+'/'+detentrada.CON_CODIGO, 'A',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                  }else{
                    this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR', String(detentrada.KAR_NRO)+'/'+detentrada.CON_CODIGO, 'I',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                  }
                  
    
                }
              }
              let totalvar: number;
              totalvar = Number(this.calcularTotalVarios());
              // if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar && this.checkSaldoI === false) {
              //   this.messageService.add({
              //     key: 'ent',
              //     severity: 'error',
              //     summary: 'Generar asiento',
              //     detail: 'El total de varios es diferente al total del documento.' +
              //       'No se puede generar el Asiento contable'
              //   });
              // } else {
              //   //await this.generarAsiento();
              //   this.generarAsiento();//CDPJ
              //   this.botonesguardar();
              // }
              //CDPJ
              if (this.modulo !== 'PROD') {
                if (this.checkSaldoI === false) {
                  if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar) {
                    //console.log('porque entro ',this.checkSaldoI);
                    this.messageService.add({
                      key: 'ent',
                      severity: 'error',
                      summary: 'Generar asiento',
                      detail: 'El total de varios es diferente al total del documento.' +
                        'No se puede generar el Asiento contable'
                    });
                    this.spin=false//CDPJ
                    this.control=0;
                  } else {
                    //console.log('porque entro al else',this.checkSaldoI);
                    //await this.generarAsiento();
                    this.generarAsiento();//CDPJ
                    this.botonesguardar();
                    
                  }
                }
              }
              //CDPJ
              if (this.modulo !== 'PROD') {
                this.messageService.add({
                  key: 'ent',
                  severity: 'success',
                  summary: 'Inserción de Entrada Varios',
                  detail: 'Se ingresó la entrada varios correctamente'
                });
                this.spin=false//CDPJ
              }
            } catch (err) {
              this.messageService.add({
                key: 'ent',
                severity: 'error',
                summary: 'Inserción de Entrada Varios',
                detail: 'Ocurrió un error al insertar entrada varios'
              });
              this.control=0;
              this.spin=false//CDPJ
            }
    
            // ANTIGUO
            /*this.entradaService.eliminarEntradasVar(this.entradaSeleccionada.KAR_NRO).subscribe(() => {
              this.entradaService.detkardexvararray[this.indicador].map((detentrada) => {
                if (detentrada.CON_CODIGO !== '') {
                  this.entradaService.insertarEntradaVar(detentrada).subscribe(() => {
                    this.auditoriagral.registrarAuditoria('INV_DETKARDEXVAR', String(detentrada.KAR_NRO), 'I',
                      '', '01', '', '', '', '').subscribe(() => { 
                    });
                  });
                }
              });
              let totalvar: number;
              totalvar = Number(this.calcularTotalVarios());
              if (Number(this.entradaService.totalEntradaarray[this.indicador]) !== totalvar) {
                this.messageService.add({
                  key: 'ent',
                  severity: 'error',
                  summary: 'Generar asiento',
                  detail: 'El total de varios es diferente al total del documento.' +
                    'No se puede generar el Asiento contable'
                });
              } else {
                this.generarAsiento();
                this.botonesguardar();
              }
            }); */
            /* this.messageService.add({
              key: 'ent',
              severity: 'success',
              summary: 'Inserción de Entrada Varios',
              detail: 'Se ingresó la entrada varios correctamente'
            }); */
          } 
      //CDPJ
      
      const res1 = await this.entradaService.getEncEntradas().toPromise()//subscribe((res) => {
        this.encEntradas = res1;
       this.indice = this.encEntradas.length - 1
     //});
     await this.manejarSenales('Cancelar');
     //CDPJ
          this.botonesguardar();
        }, error1 => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
          this.messageService.add({
            key: 'ent',
            severity: 'error',
            summary: 'Inserción de Entrada',
            detail: mensaje
          });
          this.spin=false//CDPJ
        });
      
      }
      
      
    }
  }

  confirmarBorrar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la entrada',
      header: 'Eliminar Entrada',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarEnt',
      accept: () => {
        this.borrarEncEntrada();
      },
      reject: () => {
      }
    });
  }

  async borrarEncEntrada() {
     //validar que la entrada no esté en una orden de producción ya ingresada
     let prodin=0;
     if(this.modulo === 'PROD'){
       const rs = await this.entradaService.ValidaEnSaOrdProd(this.encentradaSeleccionada);
       //console.log('rs',rs)
       if(rs !== null && rs !== undefined){
         for (const data of rs){
           prodin=data.NUMERO
         }
       } 
     }
       if(this.modulo === 'PROD' && prodin>0){
         this.messageService.add({
           key: 'ent',
           severity: 'error',
           summary: 'Eliminación de Entrada',
           detail: 'No se puede eliminar la entrada ya que la Orden de Producción está ingresada'
         });
         return;
       }
       try {
        await this.entradaService.borrarEncEntrada(this.encentradaSeleccionada).toPromise()//.subscribe(() => {
          let numero = '';
          if (this.encentradaSeleccionada.ASI_NRO !== undefined || this.encentradaSeleccionada.ASI_NRO !== '') {
            numero = this.encentradaSeleccionada.ASI_NRO;
            if (numero !== undefined || numero !== '') {
              await this.entradaService.eliminarAsientoCont(numero).toPromise()//.subscribe(() => {
              //});
            }
          }
          this.encEntradas.splice(this.encEntradas.findIndex(
            entrada => entrada.KAR_NRO === this.encentradaSeleccionada.KAR_NRO),
            1);
    
          this.entradaService.detkardexarray[this.indicador] = [];
          this.aggrid.refreshaggrid(this.entradaService.detkardexarray[this.indicador], this.defaultColDefEntrada.objeto);
          this.encentradaSeleccionada = {};
          this.date1 = new Date();
          this.hora = new Date();
          this.entradaService.detkardexvararray[this.indicador] = [];
          this.aggrid.refreshaggrid(this.entradaService.detkardexvararray[this.indicador], this.defaultColDefEntradaVar.objeto);
          this.messageService.add({
            key: 'ent',
            severity: 'success',
            summary: 'Eliminicación de Entrada',
            detail: 'Se eliminó la entrada correctamente'
          });
          this.entradaService.calcularTotal();
          this.auditoriagral.registrarAuditoria('INV_ENCKARDEX', String(this.encentradaSeleccionada.KAR_NRO), 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
        // }, error1 => {
        //   let mensaje: string;
        //   mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        //   this.messageService.add({
        //     key: 'ent',
        //     severity: 'error',
        //     summary: 'Eliminicación de Entrada',
        //     detail: mensaje
        //   });
        // });
       } catch (error1) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
        this.messageService.add({
          key: 'ent',
          severity: 'error',
          summary: 'Eliminicación de Entrada',
          detail: mensaje
        });
       }
    

  }


  enviarCancelarEntradaVar() {
    this.entradaService.getEntradasVar(this.encentradaSeleccionada.KAR_NRO).subscribe((res) => {
      // this.entradasAuxVar = res;
      // this.entradasAuxVar.map((entrvar) => {
      //   entrvar.DETKARDEXVAR_IMPORTE = entrvar.DETKARDEXVAR_IMPORTE.toString().substr(1);
      // });
      // this.entradaService.detkardexvararray[this.indicador] = this.entradasAuxVar;
      // this.aggrid.refreshaggrid(this.entradaService.detkardexvararray[this.indicador], this.defaultColDefEntradaVar.objeto);
      
      this.entradasAuxVar = res;
      console.log('var',this.entradasAuxVar)
      if(this.entradasAuxVar.length>0){
        this.entradasAuxVar.map((entrvar) => {
          entrvar.DETKARDEXVAR_IMPORTE = entrvar.DETKARDEXVAR_IMPORTE.toString().substr(1);
          entrvar.DETKARDEXVAR_IMPORTE = Number(entrvar.DETKARDEXVAR_IMPORTE).toFixed(2)
        });
        this.entradaService.detkardexvararray[this.indicador] = this.entradasAuxVar;
      this.aggrid.refreshaggrid(this.entradaService.detkardexvararray[this.indicador], this.defaultColDefEntradaVar.objeto);
       
      }
    
    }, error1 => {
      console.log(error1.error);
    });
  }

  async generarAsiento() {

    try {
      if (this.checkSaldoI === false) {//CDPJ
        await this.entradaService.generarAsientoProm(this.encentradaSeleccionada);
        const resp = await this.entradaService.getAsientoProm(this.encentradaSeleccionada);
        console.log(resp);
        this.encentradaSeleccionada.ASI_NRO = resp[0].ASI_NRO;
        console.log('asienyo numero', this.encentradaSeleccionada.ASI_NRO)
        this.messageService.add({
          key: 'ent',
          severity: 'success',
          summary: 'Asiento Contable',
          detail: 'Se generó el asiento contable'
        });
        this.spin=false//CDPJ
      } else {
        this.messageService.add({
          key: 'ent',
          severity: 'warn',
          summary: 'Asiento Contable',
          detail: 'La entrada tiene saldo inicial'
        });
        this.spin=false//CDPJ
      }
      //CDPJ
      // await this.entradaService.generarAsientoProm(this.encentradaSeleccionada);
      // const resp = await this.entradaService.getAsientoProm(this.encentradaSeleccionada);
      // console.log(resp);
      // this.encentradaSeleccionada.ASI_NRO = resp[0].ASI_NRO;
      // console.log('asienyo numero',this.encentradaSeleccionada.ASI_NRO)
      // this.messageService.add({
      //   key: 'ent',
      //   severity: 'success',
      //   summary: 'Asiento Contable',
      //   detail: 'Se generó el asiento contable'
      // });
    } catch (err) {
      this.messageService.add({
        key: 'ent',
        severity: 'error',
        summary: 'Asiento Contable',
        detail: 'No se generó el asiento contable'
      });
    }

    // Antiguo

    /*this.entradaService.generarAsiento(this.encentradaSeleccionada).subscribe(() => {
      this.entradaService.getAsiento(this.encentradaSeleccionada).subscribe((resp) => {
        this.encentradaSeleccionada.ASI_NRO = resp[0].ASI_NRO;
      });
      this.messageService.add({
        key: 'ent',
        severity: 'success',
        summary: 'Asiento Contable',
        detail: 'Se generó el asiento contable'
      });
    }, error1 => {
      console.log(error1.error);
      this.messageService.add({
        key: 'ent',
        severity: 'error',
        summary: 'Asiento Contable',
        detail: 'No se generó el asiento contable'
      });
    }); */
  }
//CDPJ
async generarAsientoPod() {

  try {
    if (this.checkSaldoI === false) {//CDPJ
      await this.entradaService.generarAsientoProdProm(this.encentradaSeleccionada);
      const resp = await this.entradaService.getAsientoProm(this.encentradaSeleccionada);
      console.log(resp);
      this.encentradaSeleccionada.ASI_NRO = resp[0].ASI_NRO;
      console.log('asienyo numero', this.encentradaSeleccionada.ASI_NRO)
      this.messageService.add({
        key: 'ent',
        severity: 'success',
        summary: 'Asiento Contable',
        detail: 'Se generó el asiento contable'
      });
      this.spin=false//CDPJ
    } else {
      this.messageService.add({
        key: 'ent',
        severity: 'warn',
        summary: 'Asiento Contable',
        detail: 'La entrada tiene saldo inicial'
      });
      this.spin=false//CDPJ
    }
    //CDPJ
    // await this.entradaService.generarAsientoProm(this.encentradaSeleccionada);
    // const resp = await this.entradaService.getAsientoProm(this.encentradaSeleccionada);
    // console.log(resp);
    // this.encentradaSeleccionada.ASI_NRO = resp[0].ASI_NRO;
    // console.log('asienyo numero',this.encentradaSeleccionada.ASI_NRO)
    // this.messageService.add({
    //   key: 'ent',
    //   severity: 'success',
    //   summary: 'Asiento Contable',
    //   detail: 'Se generó el asiento contable'
    // });
  } catch (err) {
    this.messageService.add({
      key: 'ent',
      severity: 'error',
      summary: 'Asiento Contable',
      detail: 'No se generó el asiento contable'
    });
  }

  // Antiguo

  /*this.entradaService.generarAsiento(this.encentradaSeleccionada).subscribe(() => {
    this.entradaService.getAsiento(this.encentradaSeleccionada).subscribe((resp) => {
      this.encentradaSeleccionada.ASI_NRO = resp[0].ASI_NRO;
    });
    this.messageService.add({
      key: 'ent',
      severity: 'success',
      summary: 'Asiento Contable',
      detail: 'Se generó el asiento contable'
    });
  }, error1 => {
    console.log(error1.error);
    this.messageService.add({
      key: 'ent',
      severity: 'error',
      summary: 'Asiento Contable',
      detail: 'No se generó el asiento contable'
    });
  }); */
}
//CDPJ
  obtenerDetKarLinea(detKarLinea, entrada) {
    console.log(detKarLinea.length);
    switch (detKarLinea.length) {
      case 9:
        this.entradaService.detkardexarray[this.indicador][this.entradaService.detkardexarray[this.indicador].indexOf(entrada)].DETKAR_LINEA =
          Number(detKarLinea.substr(7, 1));
        break;
      case 10:
        this.entradaService.detkardexarray[this.indicador][this.entradaService.detkardexarray[this.indicador].indexOf(entrada)].DETKAR_LINEA =
          Number(detKarLinea.substr(7, 2));
        break;
      case 11:
        this.entradaService.detkardexarray[this.indicador][this.entradaService.detkardexarray[this.indicador].indexOf(entrada)].DETKAR_LINEA =
          Number(detKarLinea.substr(7, 3));
        break;
      default:
        break;
    }
  }

  obtenerDatos() {
    //CDPJ
    this.btnNuevoGrid = false;
    this.btnBorrarGrid = false;
    this.btnCancelarGrid = false;
    if (this.encentradaSeleccionada.KAR_SALDOINI === 'S') {
      this.checkSaldoI = true;
    } else if (this.encentradaSeleccionada.KAR_SALDOINI !== 'S') {
      this.checkSaldoI = false;
    }

    this.date1 = new Date(this.encentradaSeleccionada.KAR_FECHA);
    this.hora = new Date(this.encentradaSeleccionada.KAR_FECHA);
    if (this.encentradaSeleccionada.KAR_HORA.toString().length === 4) {
      const separador = ':';
      const arregloHora = this.encentradaSeleccionada.KAR_HORA.toString().split(separador);
      for (let i = 0; i < arregloHora.length; i++) {
        if (arregloHora[i].length !== 2) {
          arregloHora[i] = '0' + arregloHora[i];
        }
      }
      const horaDefinitiva = arregloHora.join(':');
      this.encentradaSeleccionada.KAR_HORA = horaDefinitiva;
    } else if (this.encentradaSeleccionada.KAR_HORA.toString().length === 3) {
      this.encentradaSeleccionada.KAR_HORA = '0' + this.encentradaSeleccionada.KAR_HORA.toString();
      this.encentradaSeleccionada.KAR_HORA = this.encentradaSeleccionada.KAR_HORA.toString().substr(0, 2) + '0'
        + this.encentradaSeleccionada.KAR_HORA.toString().substr(3, 1);
    }
    this.hora.setHours(this.encentradaSeleccionada.KAR_HORA.substr(0, 2));
    this.hora.setMinutes(this.encentradaSeleccionada.KAR_HORA.substr(3, 2));
    this.entradaService.getEntradasVar(this.encentradaSeleccionada.KAR_NRO).subscribe((resp) => {
      this.entradaService.detkardexvararray[this.indicador] = resp;
      console.log(this.entradaService.detkardexvararray[this.indicador].length)
      if(this.entradaService.detkardexvararray[this.indicador].length>0){
        this.entradaService.detkardexvararray[this.indicador].map((entrvar) => {
          entrvar.DETKARDEXVAR_IMPORTE = entrvar.DETKARDEXVAR_IMPORTE.toString().substr(1);
          entrvar.DETKARDEXVAR_IMPORTE = Number(entrvar.DETKARDEXVAR_IMPORTE).toFixed(2);
        });
        this.aggrid.refreshaggrid(this.entradaService.detkardexvararray[this.indicador], this.defaultColDefEntradaVar.objeto);
      }
      
    });
    // this.entradaService.getEntradas(this.encentradaSeleccionada.KAR_NRO).subscribe((res1) => {
    this.entradaService.consultarDetKardex(this.encentradaSeleccionada.KAR_NRO).subscribe((res1) => {
      this.entradaService.detkardexarray[this.indicador] = res1 === null ? [] : res1;
      let hora;
      let minutos;
      hora = this.hora.getHours();
      minutos = this.hora.getMinutes();
      if (hora.toString().length === 1) {
        hora = '0' + hora;
      }
      if (minutos.toString().length === 1) {
        minutos = '0' + minutos;
      }
      const hour = hora + ':' + minutos;
      const date = this.datePipe.transform(this.date1, 'dd/MM/yyyy');
      let i = 0;
      this.entradaService.detkardexarray[this.indicador].map((entrada) => {
        i++;
        entrada.CEN_CODIGO = entrada.CEN_CODIGO === null || entrada.CEN_CODIGO === undefined ? '' : entrada.CEN_CODIGO //CDPJ
        entrada.DETKAR_COSTOUNIT = Number(entrada.DETKAR_COSTOUNIT).toFixed(this.decimalesPrecioEnt);
        entrada.DETKAR_COSTOTOTAL = Number(entrada.DETKAR_COSTOTOTAL).toFixed(this.decimalesTotalEnt);
        entrada.DETKAR_CANTIDAD = Number(entrada.DETKAR_CANTIDAD).toFixed(this.decimalesCantidadEnt);
        entrada.FECHA = date + '' + hour;
        entrada.HORA = hour;
        entrada.CLAVE = i + this.datePipe.transform(new Date(), 'dd/MM/yyyyHHmmss');
        this.entradaSeleccionada = entrada;
      });
      this.aggrid.refreshaggrid(this.entradaService.detkardexarray[this.indicador], this.defaultColDefEntrada.objeto);
      this.entradaService.calcularTotal();
    });
  }

  acciones(valor) {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className = 'layout-config';
      }
    }

  }

  abrirSeriales() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }
    this.strNumFact=this.encentradaSeleccionada.KAR_NRO
    if(this.encentradaSeleccionada.KAR_FECHA.includes('-')){
      this.strFechaSer = this.datePipe.transform(this.encentradaSeleccionada.KAR_FECHA, 'dd/MM/yyyy');
    }else{
      this.strFechaSer=this.encentradaSeleccionada.KAR_FECHA;
    }
    
    this.displayDialogSeriales = true;
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'nroCompra') {
      document.getElementById('comentario').focus();
    } else if (evento.target.id === 'comentario') {
      this.nuevoDetEntrad();
    }
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  totalesVarios(): number {
    let suma = 0;
    for (const rs of this.entradaService.detkardexvararray[this.indicador]) {
      suma = suma + Number(rs.DETKARDEXVAR_IMPORTE);
    }
    return suma;
  }

  existeAsiento() {
    let bolExiste = false;

    /*if (cmpEntradaSalida.chbtnNoAfectarKardex.getSelection()==true)
      return true;
    */
    if (this.encentradaSeleccionada.KAR_ORIGEN === 'PROD') {
      return true;
    }

    if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DOCSINASI').CFG_VALOR1 === 0) {
      let bolSaldoIni = false;

      if (this.checkSaldoI != null) {
        if (this.checkSaldoI) {
          bolSaldoIni = true;
        }
      }

      if (this.encentradaSeleccionada.ASI_NRO === null || this.encentradaSeleccionada.ASI_NRO === undefined) {
        this.encentradaSeleccionada.ASI_NRO = '';
      }

      if (this.encentradaSeleccionada.KAR_NRO === null || this.encentradaSeleccionada.KAR_NRO === undefined) {
        this.encentradaSeleccionada.KAR_NRO = '';
      }

      if (this.encentradaSeleccionada.ASI_NRO.length === 0 &&
        this.encentradaSeleccionada.KAR_NRO.length > 0 && !bolSaldoIni) {
        this.confirmationService.confirm({
          message: 'El documento no tiene Asiento Contable.' +
            ' Desea eliminar el documento?',
          header: 'Pregunta',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmarEnt',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            this.borrarEncEntrada();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      } else {
        bolExiste = true;
      }
    } else {
      bolExiste = true;
    }

    return bolExiste;
  }

  abrirLotes(params) {
    this.entradaSeleccionada = params;
    this.auxiliarlot++;
    this.displayDialogLotes = this.encentradaSeleccionada.KAR_NRO.length > 0 && this.entradaSeleccionada.ART_CADUCA === 'S';
  }

  cerrarLotes() {
    this.displayDialogLotes = false;
  }

  async ValidarSeriales(strNumDoc) {
    let errores = 0;
    let articulo = '';
    let cantidad = 0;
    let artserialflag = '';
    let intLinea = 0;
    let strSentencia = '';


    for (let i = 1; i <= this.entradaService.detkardexarray[this.indicador].length; i++) {

      artserialflag = this.entradaService.detkardexarray[this.indicador][i - 1].ART_SERIALFLAG;
      cantidad = this.entradaService.detkardexarray[this.indicador][i - 1].DETKAR_CANTIDAD;
      intLinea = this.entradaService.detkardexarray[this.indicador][i - 1].DETKAR_LINEA;
      articulo = this.entradaService.detkardexarray[this.indicador][i - 1].ART_CODIGO;

      if (artserialflag === 'S') {
        strSentencia = 'select count(*) AS COUN from INV_TRNKARDEXOTR ';
        strSentencia = strSentencia + ' where art_codigo = \''
          + articulo + '\' and TRNKARDEXOTR_NUMERO = \''
          + strNumDoc + '\'';
        strSentencia = strSentencia + ' and trnkardexotr_linea = ' + intLinea;

        const data3 = await this.entradaService.validarSeriales(articulo, strNumDoc, intLinea);
        for (const rs of data3) {
          if (rs.COUN !== Number(cantidad)) {
            errores++;
          }
        }
      }
    }

    if (errores > 0) {
      this.messageService.add({
        key: 'ent',
        severity: 'warn',
        summary: 'Información',
        detail: 'Ingrese los seriales.'
      });
      return false;
    } else {
      return true;
    }
  }

  cerrarSeriales() {
    this.displayDialogSeriales = false;
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }
  //GSRF
  busquedaorden() {
    this.opcion = 'orden';
    this.types = [
      { label: 'Orden', value: 'ENCORDPRO_NUMERO' },
      { label: 'Referencia', value: 'ENCORDPRO_REFERENCIA' },
      { label: 'Fecha Emi.', value: 'ENCORDPRO_FECHAEMISION' },
      { label: 'Fecha Inicio', value: 'ENCORDPRO_FECHAINICIO' },
      { label: 'Estado', value: 'ENCORDPRO_ESTADO' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    //this.consulta = '*';
    this.tabla = 'PROD_ENCORDPRO';
    this.where = 'ROWNUM < 50';

    this.busquedaSer.busquedaNEW().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.ENCORDPRO_FECHAEMISION !== null) {
          registro.ENCORDPRO_FECHAEMISION = this.datePipe.transform(registro.ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCORDPRO_FECHAEMISION = '';
        }
        if (registro.ENCORDPRO_FECHAINICIO !== null) {
          registro.ENCORDPRO_FECHAINICIO = this.datePipe.transform(registro.ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
        } else {
          registro.ENCORDPRO_FECHAINICIO = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }
  //GSRF
}
