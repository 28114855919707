import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Presuanuencplanc } from "../coninterfaces/presuanuencplanc";
import { Presuanudetplanc } from "../coninterfaces/presuanudetplanc";
import { Presuanudetplancen } from "../coninterfaces/presuanudetplancen";
import { Presuanudetplancenpro } from "../coninterfaces/prosuanudetplancenpro";
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
@Injectable({
  providedIn: 'root'
})
export class ConpresuanucenService {
  public encabezadoPresuAnuSeleccionadoarray: Presuanuencplanc[] = [];
  public way: string;

  public _detpresuanupuestoarray: Presuanudetplanc[] = [];
  get detpresuanupuestoarray(): Presuanudetplanc[] {
    return this._detpresuanupuestoarray;
  }
  set detpresuanupuestoarray(value: Presuanudetplanc[]) {
    this._detpresuanupuestoarray = value;
  }

  public _detpresuanupuestocenarray: Presuanudetplancen[] = [];
  get detpresuanupuestocenarray(): Presuanudetplancen[] {
    return this._detpresuanupuestocenarray;
  }
  set detpresuanupuestocenarray(value: Presuanudetplancen[]) {
    this._detpresuanupuestocenarray = value;
  }

  public _detpresuanupuestocenproarray: Presuanudetplancenpro[] = [];
  get detpresuanupuestocenproarray(): Presuanudetplancenpro[] {
    return this._detpresuanupuestocenproarray;
  }
  set detpresuanupuestocenproarray(value: Presuanudetplancenpro[]) {
    this._detpresuanupuestocenproarray = value;
  }

  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  }
  consultaplancdet(stranio) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/198bf3d56bbb9e00000000000000', {
      elementos: {
        p_ENCPRESANU_ANIO: stranio,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consultaplancenc(stranio) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/198bf3d575d99e00000000000000', {
      elementos: {
        p_ENCPRESANU_ANIO: stranio,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  cargarcentro(con, anio) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/198bf3d56bbb9e00rtyuio', {
      elementos: {
        p_ENCPRESANU_ANIO: anio,
        p_CON_CODIGO: con,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarplancenc(item: Presuanuencplanc) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/257e3b7d75d99e00000000000000', {
      elementos: {
        p_ENCPRESANU_ANIO: item.ENCPRESANU_ANIO,
        p_ENCPRESANU_DESCRIPCION: item.ENCPRESANU_DESCRIPCION,
        p_COM_CODIGO: item.COM_CODIGO,
        p_ENCPRESANU_CONSOLIDA: item.ENCPRESANU_CONSOLIDA
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarplancenc(item: Presuanuencplanc) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/299df2ab2', {
      elementos: {
        p_ENCPRESANU_ANIO: item.ENCPRESANU_ANIO,
        p_ENCPRESANU_DESCRIPCION: item.ENCPRESANU_DESCRIPCION,
        p_COM_CODIGO: item.COM_CODIGO,
        p_ENCPRESANU_CONSOLIDA: item.ENCPRESANU_CONSOLIDA
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarplancdet(item: Presuanudetplanc) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/299df2ab2021777444', {
      elementos: {
        p_ENCPRESANU_ANIO: item.ENCPRESANU_ANIO,
        p_COM_CODIGO: '01',
        p_CON_CODIGO: item.CON_CODIGO,
        p_DETPRESANUCON_01: item.DETPRESANUCON_01,
        p_DETPRESANUCON_02: item.DETPRESANUCON_02,
        p_DETPRESANUCON_03: item.DETPRESANUCON_03,
        p_DETPRESANUCON_04: item.DETPRESANUCON_04,
        p_DETPRESANUCON_05: item.DETPRESANUCON_05,
        p_DETPRESANUCON_06: item.DETPRESANUCON_06,
        p_DETPRESANUCON_07: item.DETPRESANUCON_07,
        p_DETPRESANUCON_08: item.DETPRESANUCON_08,
        p_DETPRESANUCON_09: item.DETPRESANUCON_09,
        p_DETPRESANUCON_10: item.DETPRESANUCON_10,
        p_DETPRESANUCON_11: item.DETPRESANUCON_11,
        p_DETPRESANUCON_12: item.DETPRESANUCON_12,
        p_DETPRESANUCON_ACM: item.DETPRESANUCON_ACM
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarplancdet(item: Presuanudetplanc) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/3ab2b4aea75d9a00000213561', {
      elementos: {
        p_ENCPRESANU_ANIO: item.ENCPRESANU_ANIO,
        p_COM_CODIGO: '01',
        p_CON_CODIGO: item.CON_CODIGO,
        p_DETPRESANUCON_01: item.DETPRESANUCON_01,
        p_DETPRESANUCON_02: item.DETPRESANUCON_02,
        p_DETPRESANUCON_03: item.DETPRESANUCON_03,
        p_DETPRESANUCON_04: item.DETPRESANUCON_04,
        p_DETPRESANUCON_05: item.DETPRESANUCON_05,
        p_DETPRESANUCON_06: item.DETPRESANUCON_06,
        p_DETPRESANUCON_07: item.DETPRESANUCON_07,
        p_DETPRESANUCON_08: item.DETPRESANUCON_08,
        p_DETPRESANUCON_09: item.DETPRESANUCON_09,
        p_DETPRESANUCON_10: item.DETPRESANUCON_10,
        p_DETPRESANUCON_11: item.DETPRESANUCON_11,
        p_DETPRESANUCON_12: item.DETPRESANUCON_12,
        p_DETPRESANUCON_ACM: item.DETPRESANUCON_ACM
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarDetalleCen(detcen): Observable<any> {
    return this.http.post<any>(this.way + '/cont/presplananuc/1d595a5756daef00000000000000', {
      elementos: {
        p_encpresanu_anio: detcen.ENCPRESANU_ANIO,
        p_cen_codigo: detcen.CEN_CODIGO,
        p_con_codigo: detcen.CON_CODIGO,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  ///---------centros de costos
  actualizarplancendet(item: Presuanudetplancen) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/299df2ab2031555555555', {
      elementos: {
        p_ENCPRESANU_ANIO: item.ENCPRESANU_ANIO,
        p_COM_CODIGO: '01',
        p_CEN_CODIGO: item.CEN_CODIGO,
        p_CON_CODIGO: item.CON_CODIGO,
        p_DETPRESANUCEN_01: item.DETPRESANUCEN_01,
        p_DETPRESANUCEN_02: item.DETPRESANUCEN_02,
        p_DETPRESANUCEN_03: item.DETPRESANUCEN_03,
        p_DETPRESANUCEN_04: item.DETPRESANUCEN_04,
        p_DETPRESANUCEN_05: item.DETPRESANUCEN_05,
        p_DETPRESANUCEN_06: item.DETPRESANUCEN_06,
        p_DETPRESANUCEN_07: item.DETPRESANUCEN_07,
        p_DETPRESANUCEN_08: item.DETPRESANUCEN_08,
        p_DETPRESANUCEN_09: item.DETPRESANUCEN_09,
        p_DETPRESANUCEN_10: item.DETPRESANUCEN_10,
        p_DETPRESANUCEN_11: item.DETPRESANUCEN_11,
        p_DETPRESANUCEN_12: item.DETPRESANUCEN_12,
        p_DETPRESANUCEN_ACM: item.DETPRESANUCEN_ACM
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarplancendet(item: Presuanudetplancen) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/95f8edf54d777000000000000', {
      elementos: {
        p_ENCPRESANU_ANIO: item.ENCPRESANU_ANIO,
        p_COM_CODIGO: '01',
        p_CEN_CODIGO: item.CEN_CODIGO,
        p_CON_CODIGO: item.CON_CODIGO,
        p_DETPRESANUCEN_01: item.DETPRESANUCEN_01,
        p_DETPRESANUCEN_02: item.DETPRESANUCEN_02,
        p_DETPRESANUCEN_03: item.DETPRESANUCEN_03,
        p_DETPRESANUCEN_04: item.DETPRESANUCEN_04,
        p_DETPRESANUCEN_05: item.DETPRESANUCEN_05,
        p_DETPRESANUCEN_06: item.DETPRESANUCEN_06,
        p_DETPRESANUCEN_07: item.DETPRESANUCEN_07,
        p_DETPRESANUCEN_08: item.DETPRESANUCEN_08,
        p_DETPRESANUCEN_09: item.DETPRESANUCEN_09,
        p_DETPRESANUCEN_10: item.DETPRESANUCEN_10,
        p_DETPRESANUCEN_11: item.DETPRESANUCEN_11,
        p_DETPRESANUCEN_12: item.DETPRESANUCEN_12,
        p_DETPRESANUCEN_ACM: item.DETPRESANUCEN_ACM
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizavalorcntas(anio, con) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/a677eaae36cbf800', {
      elementos: {
        p_ENCPRESANU_ANIO: anio,
        p_CON_CODIGO: con,
        p_COM_CODIGO: '01',

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consulpresupro(item) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/83bad7773b774000000000000', {
      elementos: {
        p_ENCPRESANU_ANIO: item.ENCPRESANU_ANIO,
        p_con_codigo: item.CON_CODIGO,
        p_cen_codigo: item.CEN_CODIGO,
        p_com_codigo: '01',

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertardetcenpro(item) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/12bf1dbeab6d77000000000000000000', {
      elementos: {
        p_encpresanu_anio: item.ENCPRESANU_ANIO,
        p_con_codigo: item.CON_CODIGO,
        p_cen_codigo: item.CEN_CODIGO,
        p_com_codigo: '01',
        p_detpresanucenpro_des: item.DETPRESANUCENPRO_DES,
        p_detpresanucenpro_valor: item.DETPRESANUCENPRO_VALOR,
        p_detpresanucenpro_orden: item.DETPRESANUCENPRO_ORDEN

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizardetcenpro(item) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/533ad7773b773c00000000000', {
      elementos: {
        p_encpresanu_anio: item.ENCPRESANU_ANIO,
        p_con_codigo: item.CON_CODIGO,
        p_cen_codigo: item.CEN_CODIGO,
        p_com_codigo: '01',
        p_detpresanucenpro_des: item.DETPRESANUCENPRO_DES,
        p_detpresanucenpro_valor: item.DETPRESANUCENPRO_VALOR,
        p_detpresanucenpro_orden: item.DETPRESANUCENPRO_ORDEN

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarregistro(stranio) {
    return this.http.post<any[]>(this.way + '/cont/presplananuc/7565695d5bdba0000000', {
      elementos: {
        p_ENCPRESANU_ANIO: stranio,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consolidarcuentas(stranio){
    return this.http.post<any[]>(this.way + '/cont/presplananuc/662fcc564d567800000000000', {
      elementos: {
        p_CON_ENCPRESANIO: stranio,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  sumarAcumumuladoCnta(index) {
    let item: Presuanudetplanc = this.detpresuanupuestoarray[index]
    this.detpresuanupuestoarray[index].DETPRESANUCON_ACM =
      Number(
        Number(item.DETPRESANUCON_01) + Number(item.DETPRESANUCON_02) + Number(item.DETPRESANUCON_03) + Number(item.DETPRESANUCON_04) +
        Number(item.DETPRESANUCON_05) + Number(item.DETPRESANUCON_06) + Number(item.DETPRESANUCON_07) + Number(item.DETPRESANUCON_08) +
        Number(item.DETPRESANUCON_09) + Number(item.DETPRESANUCON_10) + Number(item.DETPRESANUCON_11) + Number(item.DETPRESANUCON_12)
      ).toFixed(2)
  }
  sumarAcumumuladoCen(index) {
    let item: Presuanudetplancen = this.detpresuanupuestocenarray[index]
    this.detpresuanupuestocenarray[index].DETPRESANUCEN_ACM =
      Number(
        Number(item.DETPRESANUCEN_01) + Number(item.DETPRESANUCEN_02) + Number(item.DETPRESANUCEN_03) + Number(item.DETPRESANUCEN_04) +
        Number(item.DETPRESANUCEN_05) + Number(item.DETPRESANUCEN_06) + Number(item.DETPRESANUCEN_07) + Number(item.DETPRESANUCEN_08) +
        Number(item.DETPRESANUCEN_09) + Number(item.DETPRESANUCEN_10) + Number(item.DETPRESANUCEN_11) + Number(item.DETPRESANUCEN_12)
      ).toFixed(2)
  }
}
