<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encfacpro"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarencfacpro" title="Pregunta" appendTo="body">
</p-confirmDialog>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="true" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarSeriales()"
									class="archivoOpc">Ingresar Seriales</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="desglosarElValorIva()"
									class="archivoOpc">Desglosar el iva del item => F10</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="datosAnexos()"
									class="archivoOpc">Datos Anexos</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarReembolsos()"
									class="archivoOpc">Reembolsos</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="insertarreembolsos()"
									class="archivoOpc">Insertar Reembolsos</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="copiarFactura()"
									class="archivoOpc">Copiar Factura</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span
									(click)="desplegarRetencionesSegunProv()" class="archivoOpc">Desplegar las
									retenciones de acuerdo al proveedor => Ctrl+R</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span
									(click)="cambiarTipoIvaABaseNoObjetoDeIva()" class="archivoOpc">Cambiar los Items a
									Base No Objeto de Iva</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="buscarRegistroSinRet()"
									class="archivoOpc">Visualizar Liquidación sin retención</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="activarSerieSecuencial()"
									class="archivoOpc">Activar Número de serie y secuencia</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="activarFacAnulada()"
									class="archivoOpc">Activar liquidación anulada</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="actbasesiva()"
									class="archivoOpc">Actualizar bases IVA</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="verbasesiva()"
									class="archivoOpc">Visualizar bases IVA</span>
							</div>
							<hr />
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span
									(click)="activarBotonAnularLiquidacionFirmadaElectr()" class="archivoOpc">Activar
									botón Anular de Liquidación Firmada Electrónicamente</span>
							</div>

							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span
									(click)="actualizarDatosElectronicosLiq()" class="archivoOpc">Refrescar información
									de liquidación electronica => Ctrl+F5</span>
							</div>
							<div *ngIf="boolfacturaElec === true" class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="continuarFELiq()"
									class="archivoOpc">Continuar proceso de firma electrónica de liquidación</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span
									(click)="reversarInicioProcesFactElectronicaLiq()" class="archivoOpc">Reversar
									inicio
									de proceso de firma electrónica de liquidación</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="visualizarLogLiq()"
									class="archivoOpc">Visualizar Log del Documento - Liquidación</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="visualizarEst()"
									class="archivoOpc">Estados firma electrónica</span>
							</div>
							<hr />
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span
									(click)="activarBotonAnularRetFirElectro()" class="archivoOpc">Activar botón Anular
									de Retención Firmada Electrónicamente</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="guardarDatosRetElec()"
									class="archivoOpc">Cargar el Número de Autorización de la retención => Ctrl+U</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span
									(click)="actualizarDatosElectronicos()" class="archivoOpc">Refrescar información de
									retención electronica => Ctrl+F5</span>
							</div>
							<div *ngIf="boolfacturaElec === true" class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="continuarFE()"
									class="archivoOpc">Continuar proceso de firma electrónica de retención</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span
									(click)="reversarInicioProcesFactElectronica()" class="archivoOpc">Reversar inicio
									de proceso de firma electrónica de retención</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="visualizarLog()"
									class="archivoOpc">Visualizar Log del Documento - Retencion</span>
							</div>
							<hr />
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="actualizarCodigosAlternos()"
									class="archivoOpc">Actualizar Códigos alternos</span>
							</div>
						</p-scrollPanel>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="false"
			[botonBorrar]="botonBorrar" [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar"
			[botonRegresar]="botonRegresar" [botonPrimero]="!botonPrimero" [botonAnterior]="!botonAnterior"
			[botonSiguiente]="!botonSiguiente" [botonUltimo]="!botonUltimo" [barraBotones5]="false"
			[barraFacturaPro]="false" [botonAnular]="botonAnular" [botonAsiento]="false" [botonverAsiento]="false"
			[botonPago]="false" [botonPagoCredito]="false" [botonBuscar]="false" [botonXML]="botonXML"
			[botonReenviarCorreo]="false" [botonAlmacenardoc]="true"></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<!--LINEA 1-->
	<div class="p-col-2">
		<label for="ENCFACPRO_NUMERO"></label>
		<input class="frm" id="ENCFACPRO_NUMERO" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_NUMERO"
			(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-2">
		<label for="ENCFACPRO_ESTADO"></label>
		<input class="frm" id="ENCFACPRO_ESTADO" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_ESTADO"
			(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-1">
		<label for="ENCFACPRO_ESTADO_FELIQ"></label>
		<input class="frm" id="ENCFACPRO_ESTADO_FELIQ" type="text" pInputText
			style="background-color: rgb(40,138,210); font-weight: bold; color: white;" [disabled]="true"
			[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_ESTADO_FELIQ"
			(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-2">
		<label for="ENCFACPRO_AUTORIZACION_FELIQ"></label>
		<input class="frm" id="ENCFACPRO_AUTORIZACION_FELIQ" type="text" pInputText
			style="background-color: rgb(40,138,210); font-weight: bold; color: white;" [disabled]="true"
			[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_AUTORIZACION_FELIQ"
			(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-2">
		<label for="ENCFACPRO_CLAVEACCESO_FELIQ"></label>
		<input class="frm" id="ENCFACPRO_CLAVEACCESO_FELIQ" type="text" pInputText
			style="background-color: rgb(40,138,210); font-weight: bold; color: white;" [disabled]="true"
			[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_CLAVEACCESO_FELIQ"
			(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-2">
		<label for="ASI_NRO"></label>
		<input class="frm" id="ASI_NRO" type="text" pInputText style="background-color: #EFFFF7" [disabled]="true"
			[(ngModel)]="encfacproService.encfacproarray[indicador].ASI_NRO" (keydown.enter)="setFocus($event)"
			(keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-1">
		<div class="p-grid">
			<div class="p-col-1"></div>
			<div class="p-col-8">
				<label for="USUARIO"></label>
				<input class="frm" id="USUARIO" type="text" pInputText style="background-color: #EFFFF7"
					[disabled]="true" [(ngModel)]="encfacproService.encfacproarray[indicador].USU_IDENTIFICACION"
					(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
			</div>
		</div>
	</div>
</div>
<p-tabView [(activeIndex)]="index" [style]="{'width':'98%'}" (onChange)="changeTabs()">
	<p-tabPanel header="Liquidación">
		<!-- <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 350px)'}"> -->
		<div class="contorno" id="contorno">
			<div class="divgrups">
				<div class="p-grid">
					<!--LINEA 1-->
					<div class="p-col-1">
						<span>Proveedor:</span>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<label for="PRO_CODIGO"></label>
								<input class="frm" id="PRO_CODIGO" type="text" pInputText [disabled]="bolNuevo"
									[(ngModel)]="encfacproService.encfacproarray[indicador].PRO_CODIGO"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaproveedor(encfacproService.encfacproarray[indicador].PRO_CODIGO)"></button>
							</div>
							<div class="p-col-7">
								<label for="PRO_NOMBRE"></label>
								<input class="frm" id="PRO_NOMBRE" type="text" pInputText disabled
									[(ngModel)]="encfacproService.encfacproarray[indicador].PRO_NOMBRE"
									autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-1">
						<span>IVA:</span>
					</div>
					<div class="p-col-2">
						<p-dropdown [options]="cmbiva" [style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
							[panelStyle]="{'width':'110%'}" (onClick)="llenarcmbiva()" (onChange)="busqiva($event)">
						</p-dropdown>
					</div>
					<div class="p-col-1">
					</div>
					<div class="p-col-1">
						<span>Autorización:</span>
					</div>
					<div class="p-col-2">
						<label for="ENCFACPRO_AUTORIZACION"></label>
						<input class="frm" id="ENCFACPRO_AUTORIZACION" type="text" pInputText maxlength="49"
							[disabled]="bolAutoriza"
							[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_AUTORIZACION"
							(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
					</div>
					<!--LINEA 2-->
					<div class="p-col-12" style="margin-top: -17px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Comprobante:</span>
							</div>
							<div class="p-col-1">
								<label for="ENCFACPRO_SERIE"></label>
								<input class="frm" id="ENCFACPRO_SERIE" type="text" pInputText maxlength="6"
									oninput="this.value= this.value.replace(/[^0-9]/g, '');" [disabled]="bolSerie"
									[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_SERIE"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-2">
								<label for="ENCFACPRO_REFERENCIA"></label>
								<input class="frm" id="ENCFACPRO_REFERENCIA" type="text" pInputText maxlength="9"
									oninput="this.value= this.value.replace(/[^0-9]/g, '');" [disabled]="bolComprobante"
									[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_REFERENCIA"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-4">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Id.Crédito:</span>
									</div>
									<div class="p-col-3">
										<label for="ENCFACPRO_IDCRETRI"></label>
										<input class="frm" id="ENCFACPRO_IDCRETRI" type="text" pInputText maxlength="2"
											[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_IDCRETRI"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedaidcretri(encfacproService.encfacproarray[indicador].ENCFACPRO_IDCRETRI, 'cretri')"></button>
									</div>
									<div class="p-col-5">
										<label for="ENCFACPRO_IDCRETRIDET"></label>
										<input class="frm" id="ENCFACPRO_IDCRETRIDET" type="text" pInputText
											maxlength="2" disabled="true"
											[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_IDCRETRIDET"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-1">
								<span>Núm.Días Plazo:</span>
							</div>
							<div class="p-col-1">
								<label for="ENCFACPRO_NUMDIASPLAZO"></label>
								<input class="frm" id="ENCFACPRO_NUMDIASPLAZO" type="text" pInputText
									[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_NUMDIASPLAZO"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off"
									pKeyFilter="pint">
							</div>
						</div>
					</div>
					<!--LINEA 3-->
					<div class="p-col-12" style="margin-top: -25px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Contacto:</span>
							</div>
							<div class="p-col-4">
								<label for="ENCFACPRO_CONTACTO"></label>
								<input class="frm" id="ENCFACPRO_CONTACTO" type="text" pInputText
									[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_CONTACTO"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-4">
								<div class="p-grid">
									<div class="p-col-3">
										<span>T.Doc:</span>
									</div>
									<div class="p-col-3">
										<label for="ENCFACPRO_TIPCOM"></label>
										<input class="frm" id="ENCFACPRO_TIPCOM" type="text" pInputText
											[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_TIPCOM"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedatipcom(encfacproService.encfacproarray[indicador].ENCFACPRO_TIPCOM)"></button>
									</div>
									<div class="p-col-5">
										<label for="ENCFACPRO_TIPCOMDET"></label>
										<input class="frm" id="ENCFACPRO_TIPCOMDET" type="text" pInputText
											disabled="true"
											[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_TIPCOMDET"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-1">
								<span>Vencimiento:</span>
							</div>
							<div class="p-col-1">
								<p-calendar id="ENCFACPRO_FECHAVENCIMIENTO"
									[inputStyle]="{width: '100%', height: '20px'}"
									[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_FECHAVENCIMIENTO"
									[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()">
								</p-calendar>
							</div>
						</div>
					</div>
					<!--LINEA 4-->
					<div class="p-col-12" style="margin-top: -25px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Emisión:</span>
							</div>
							<div class="p-col-1">
								<p-calendar id="ENCFACPRO_FECHAEMISION" [inputStyle]="{width: '100%', height: '20px'}"
									[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_FECHAEMISION"
									[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" (onSelect)="emisionSelect()">
								</p-calendar>
							</div>
							<div class="p-col-1">
								<span>Recepción:</span>
							</div>
							<div class="p-col-1">
								<p-calendar id="ENCFACPRO_FECHAREC" [inputStyle]="{width: '100%', height: '20px'}"
									[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_FECHAREC"
									[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()">
								</p-calendar>
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-4">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Bodega:</span>
									</div>
									<div class="p-col-3">
										<label for="BOD_CODIGO"></label>
										<input class="frm" id="BOD_CODIGO" type="text" pInputText
											[(ngModel)]="encfacproService.encfacproarray[indicador].BOD_CODIGO"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedaBodega(encfacproService.encfacproarray[indicador].BOD_CODIGO,'bodega')"></button>
									</div>
									<div class="p-col-5">
										<label for="BOD_NOMBRE"></label>
										<input class="frm" id="BOD_NOMBRE" type="text" pInputText disabled="true"
											[(ngModel)]="encfacproService.encfacproarray[indicador].BOD_NOMBRE"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-1">
								<span>Forma Pago:</span>
							</div>
							<div class="p-col-1">
								<label for="TRNFORMAPAGO_CODIGO"></label>
								<input class="frm" id="TRNFORMAPAGO_CODIGO" type="text" pInputText
									[(ngModel)]="encfacproService.encfacproarray[indicador].TRNFORMAPAGO_CODIGO"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaFormaPago(encfacproService.encfacproarray[indicador].TRNFORMAPAGO_CODIGO)"></button>
							</div>
						</div>
					</div>
					<!--LINEA 5-->
					<div class="p-col-12" style="margin-top: -25px; margin-bottom: -15px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Comentario:</span>
							</div>
							<div class="p-col-8">
								<label for="ENCFACPRO_COMENTARIO"></label>
								<input class="frm" id="ENCFACPRO_COMENTARIO" type="text" pInputText
									[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_COMENTARIO"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-3">
								<label for="ENCFACPRO_FORMAPAGONAME"></label>
								<input class="frm" id="ENCFACPRO_FORMAPAGONAME" type="text" pInputText disabled
									[(ngModel)]="encfacproService.encfacproarray[indicador].TRNFORMAPAGO_NOMBRE"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
						</div>
					</div>
					<!--LINEA 6-->
					<!--<div class="p-col-12" style="margin-top: -15px; margin-bottom: -25px">
							<div class="p-grid">
								<div class="p-col-1">
									<span *ngIf="invisible===false">Ord.Compra:</span>
								</div>
								<div class="p-col-1">
									<label for="ENCORDCOM_NUMERO"></label>
									<input *ngIf="invisible===false" class="frm" id="ENCORDCOM_NUMERO" type="text"
										   pInputText
										   [(ngModel)]="encfacproService.encfacproarray[indicador].ENCORDCOM_NUMERO"
										   (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										   autocomplete="off">
								</div>
								<div class="p-col-1">
									<button *ngIf="invisible===false" type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedaproveedor(encfacproService.encfacproarray[indicador].PRO_CODIGO)"></button>
								</div>
								<div class="p-col-1">
									<p-checkbox *ngIf="invisible===false" id="chkgasto"
												name="chkgasto" value="" label="" binary="{{chkgasto}}"
												[(ngModel)]="chkgasto" (click)="botonesmodificar()"
												(keydown.enter)="setFocus($event)"></p-checkbox>
									&nbsp;
									<span *ngIf="invisible===false">Gasto</span>
								</div>
								<div class="p-col-2">
									<p-checkbox id="chknoobjiva"
												name="chknoobjiva" value="" label="" binary="{{chknoobjiva}}"
												[(ngModel)]="chknoobjiva" (click)="botonesmodificar()"
												(keydown.enter)="setFocus($event)"></p-checkbox>
									&nbsp;
									<span>No Objeto de Iva</span>
								</div>
								<div class="p-col-2">
								</div>
								<div class="p-col-1">
								</div>
							</div>
						</div>-->
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="1500" [height]="largo" [enableSorting]="false" [enableFilter]="true"
			[rowData]="encfacproService.detfacproarray[indicador]" [frameworkComponents]="frameworkComponents"
			[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsDetfac"
			[defaultColDef]="defaultColDef" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
			(selected)="selecciondetfacpro($event)" (rdbtipodet)="rdbtipodet($event)" [comboOpciones]="comboOpciones"
			[tipodetalle]="tipodetalle" [mostrarGuardar]="false" [botonesAdicionales]="true"
			[botonFacturaPro]="botonFactura" (cambios)="cambio($event)" (click)="selecciondetfacpro($event)">
		</app-aggridsaci>
		<!-- </p-scrollPanel> -->
	</p-tabPanel>
	<p-tabPanel header="Retenciones" [disabled]="(botonNuevo && index<1)">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 350px)'}">
			<div class="contorno" id="contorno2">
				<div class="divgrups">
					<div class="p-grid">
						<!--LINEA 1-->
						<div class="p-col-1">
							<span>Serie:</span>
						</div>
						<div class="p-col-1">
							<p-dropdown [disabled]="bolcmbSerie" [options]="cmbserie"
								[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
								[panelStyle]="{'width':'110%'}" (onClick)="llenarcmbserie()"
								(onChange)="busqserie($event)" (click)="busqserie($event)"></p-dropdown>
						</div>
						<div class="p-col-1">
							<span>Secuencial:</span>
						</div>
						<div class="p-col-1">
							<label for="TRNRETENCION_NRO"></label>
							<input class="frm" id="TRNRETENCION_NRO" type="text" pInputText [disabled]="bolNumRet"
								[(ngModel)]="encfacproService.encretarray[indicador].TRNRETENCION_NRO"
								autocomplete="off">
						</div>
						<div class="p-col-3">
							<div class="p-grid">
								<div class="p-col-3">
									<label for="DOCREF"></label>
									<input class="frm" id="DOCREF" type="text" pInputText [disabled]="true"
										[(ngModel)]="encfacproService.encretarray[indicador].DOCREF" autocomplete="off">
								</div>
								<div class="p-col-9">
									<label for="TRNRETENCION_AUTORIZACION"></label>
									<input class="frm" id="TRNRETENCION_AUTORIZACION" type="text" pInputText
										[disabled]="true"
										[(ngModel)]="encfacproService.encretarray[indicador].TRNRETENCION_AUTORIZACION"
										autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<button class="sincss" pButton (click)="activarNumero()">Activar número
							</button>
						</div>
						<div class="p-col-2">
							<button class="sincss" pButton (click)="veerificarAnularRetenciones()"
								[disabled]="bolAnularRetencion">Anular Retención
							</button>
						</div>

					</div>
				</div>
				<app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
					[rowData]="encfacproService.trnretencionarray[indicador]"
					[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
					[columnDefs]="columnDefsRet" [defaultColDef]="defaultColDefRet" [rowStyle]="rowStyle"
					[singleClickEdit]="false" [botones]="true" (selected)="selecciontrnretencion($event)"
					[mostrarGuardar]="false" (cambios)="cambio($event)" (click)="selecciontrnretencion($event)">
				</app-aggridsaci>
				<div class="divgrups">
					<div class="p-grid" style="margin-bottom: -20px">
						<div class="p-col-12">
							<!--LINEA 1-->
							<div class="p-grid">
								<div class="p-col-2">
									<span>RUC:</span>
								</div>
								<div class="p-col-3">
									<label for="ENCFACPRO_RUCTRANSPORTISTA"></label>
									<input class="frm" id="ENCFACPRO_RUCTRANSPORTISTA" type="text" pInputText
										[disabled]="bolTransporte"
										[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_RUCTRANSPORTISTA"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button" [disabled]="bolTransporte"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedatransportista(encfacproService.encfacproarray[indicador].ENCFACPRO_RUCTRANSPORTISTA)"></button>
								</div>
								<div class="p-col-2" style="visibility: hidden">
									<label for="UNO1"></label>
									<input class="frm" id="UNO1" type="text" pInputText [disabled]="true"
										[(ngModel)]="encfacproService.encretarray[indicador].TRNRETENCION_NRO"
										autocomplete="off">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-5" style="margin-top: -10px">
											<span>Total Retenciones:</span>
										</div>
										<div class="p-col-4" style="margin-top: -10px">
											<label for="TOTALRETENCIONES"></label>
											<input class="frm" id="TOTALRETENCIONES" type="text" pInputText
												[disabled]="true"
												[(ngModel)]="encfacproService.encretarray[indicador].TOTALRETENCIONES"
												autocomplete="off">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -20px">
							<!--LINEA 2-->
							<div class="p-grid">
								<div class="p-col-2">
									<span>Razón Social:</span>
								</div>
								<div class="p-col-3">
									<label for="ENCFACPRO_RAZONTRANSPORTISTA"></label>
									<input class="frm" id="ENCFACPRO_RAZONTRANSPORTISTA" type="text" pInputText
										[disabled]="true"
										[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_RAZONTRANSPORTISTA"
										autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -14px">
							<!--LINEA 3-->
							<div class="p-grid">
								<div class="p-col-2">
									<span>Régimen:</span>
								</div>
								<div class="p-col-3">
									<label for="ENCFACPRO_REGIMENTRANSPOR"></label>
									<input class="frm" id="ENCFACPRO_REGIMENTRANSPOR" type="text" pInputText
										[disabled]="true"
										[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_REGIMENTRANSPOR"
										autocomplete="off">
								</div>
								<div class="p-col-2">
									<span>Número Retención:</span>
								</div>
								<div class="p-col-3">
									<label for="TRNRETENCION_SERIENRO"></label>
									<input class="frm" id="TRNRETENCION_SERIENRO" type="text" pInputText
										[disabled]="true" [(ngModel)]="
										   encfacproService.encretarray[indicador].TRNRETENCION_SERIE + '' +
										   encfacproService.encretarray[indicador].TRNRETENCION_NRO" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -14px">
							<!--LINEA 4-->
							<div class="p-grid">
								<div class="p-col-2">
									<span>Estado:</span>
								</div>
								<div class="p-col-1">
									<label for="ENCFACPRO_ESTADO_FE"></label>
									<input class="frm" id="ENCFACPRO_ESTADO_FE" type="text" pInputText
										style="background-color: rgb(40,138,210); font-weight: bold; color: white;"
										[disabled]="true"
										[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_ESTADO_FE"
										autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<!--LINEA 5-->
							<div class="p-grid">
								<div class="p-col-5">
									<div class="p-grid">
										<div class="p-col-4">
											<span>Clave de acceso:</span>
										</div>
										<div class="p-col-1">
										</div>
										<div class="p-col-7">
											<label for="ENCFACPRO_CLAVEACCESO_FE"></label>
											<input class="frm" id="ENCFACPRO_CLAVEACCESO_FE" type="text" pInputText
												style="background-color: rgb(40,138,210); font-weight: bold; color: white; margin-left: -8px; width: calc(100% + 8px)"
												[disabled]="true"
												[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_CLAVEACCESO_FE"
												autocomplete="off">
										</div>
										<!--LINEA 6-->
										<div class="p-col-12" style="margin-top: -7px">
											<div class="p-grid">
												<div class="p-col-4">
													<span>Autorización:</span>
												</div>
												<div class="p-col-1">
												</div>
												<div class="p-col-7">
													<label for="ENCFACPRO_AUTORIZACION_FE"></label>
													<input class="frm" id="ENCFACPRO_AUTORIZACION_FE" type="text"
														pInputText
														style="background-color: rgb(40,138,210); font-weight: bold; color: white; margin-left: -8px; width: calc(100% + 8px)"
														[disabled]="true"
														[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_AUTORIZACION_FE"
														autocomplete="off">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-2">
										</div>
										<div class="p-col-3">
											<button
												style="background-image: url(../../../assets/images/iconos/009-connect-1.png); background-size: contain !important; width: 50px; height: 50px"
												(click)="enviarSenal('Firmar')"
												title="Firmar Electronicamente"></button>
										</div>
										<div class="p-col-2">
										</div>
										<div class="p-col-5">
											<button
												style="background-image: url(../../../assets/images/iconos/107-mailbox.png); background-size: contain !important;  width: 50px; height: 50px"
												(click)="enviarSenal('EnviarCorreo')"
												title="Enviar Correo de la factura electronica"></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Cargos" [disabled]="botonNuevo && index<1">
		<div class="contorno" id="contorno3">
			<app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
				[rowData]="encfacproService.trncargosarray[indicador]" [frameworkComponents]="frameworkComponents"
				[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsCargos"
				[defaultColDef]="defaultColDefCargos" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
				(selected)="seleccionCargos($event)" [mostrarGuardar]="false" (cambios)="cambio($event)">
			</app-aggridsaci>
			<div class="p-grid">
				<div class="p-col-3">
					<p-checkbox id="chkDisCargosSobreCostos" name="chkDisCargosSobreCostos" value="" label=""
						binary="{{chkDisCargosSobreCostos}}" [(ngModel)]="chkDisCargosSobreCostos"
						(click)="botonesmodificar()" (keydown.enter)="setFocus($event)"></p-checkbox>
					&nbsp;
					<span>Distribuir Cargos sobre Costos</span>
				</div>
				<div class="p-col-5">
				</div>
				<div class="p-col-2">
					<span>Total Cargos:</span>
				</div>
				<div class="p-col-2">
					{{encfacproService.totalcargosarray[indicador]}}
				</div>
			</div>
			<!-- <div style="height: 200px"></div> -->
		</div>
	</p-tabPanel>
	<p-tabPanel header="Información Adicional" [disabled]="botonNuevo && index<1">
		<div class="divgrups">
			<div class="p-grid">
				<!--LINEA 1-->
				<div class="p-col-8">
				</div>
				<div class="p-col-1">
					<button type="button" style="background-image: url(../../../assets/images/iconos/visto.png);"
						(click)="guardarDatosBloque()"></button>
				</div>
			</div>
		</div>

		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="false" [enableFilter]="true"
			[rowData]="veninfoadicliq" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsInfoAdic" [defaultColDef]="defaultColDefInfoAdic"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (selected)="seleccioninfoadic($event)"
			[mostrarGuardar]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Estado de Cuenta" [disabled]="botonNuevo && index<1">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Proveedor:</span>
				</div>
				<div class="p-col-6">
					<span
						style="color: blue">{{encfacproService.encfacproarray[indicador].PRO_CODIGO + ' - ' + encfacproService.encfacproarray[indicador].PRO_NOMBRE}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen"
			[defaultColDef]="defaultColDefCxctmptrnresumen" [rowStyle]="rowStyleEC" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Saldos" [disabled]="botonNuevo && index<1">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Proveedor:</span>
				</div>
				<div class="p-col-6">
					<span
						style="color: blue">{{encfacproService.encfacproarray[indicador].PRO_CODIGO + ' - ' + encfacproService.encfacproarray[indicador].PRO_NOMBRE}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen"
			[defaultColDef]="defaultColDefCxctrnresumen" [rowStyle]="rowStyleSaldo" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Estadísticas" [disabled]="disabledEstadisticas">
		<div class="p-grid">
			<!--LINEA 1-->
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-4">
								<button pButton (click)="mostrarEstadisticas()"
									style="background-image: url(../../../assets/images/iconos/cogwheel.png); width: 40px; height: 40px; background-color: transparent;"
									title="Generar estadísticas">
								</button>
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">COMPRAS POR
									MESES</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-chart [options]="options" type="bar" [data]="dataComprasProveedor" height="415px"></p-chart>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-6">
								<span style="font-size: 16px; font-weight: bold;">
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									PRODUCTOS MÁS COMPRADOS</span>
							</div>
							<div class="p-col-3">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="cantidad" (onClick)="orderByTopProd('cantidad')"
									inputId="orden1"></p-radioButton>
								<label> Cantidad </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="precio" (onClick)="orderByTopProd('total')"
									inputId="orden2"></p-radioButton>
								<label> Total </label>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px;">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detTopProductos" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsTopProductos"
							[defaultColDef]="defaultColTopProductos" [rowStyle]="rowStyleTopProd" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
			<div class="p-col-12" style="margin-top: 20px;">
				<div class="p-grid">
					<div class="p-col-5">
					</div>
					<div class="p-col-2">
						<span style="font-size: 16px; font-weight: bold;">
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							FORMULARIO</span>
					</div>
					<div class="p-col-5">
					</div>

					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Artículo:</span>
							</div>

							<div class="p-col-2" style="text-align: left">
								<div class="p-grid">
									<div class="p-col-8">
										<input id="ART_CODIGO" type="text" pInputText autocomplete="off"
											[(ngModel)]="txtArtCodigo">
									</div>
									<div class="p-col-4">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="buscarArticulo()"></button>
									</div>
								</div>
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Unidad:</span>
							</div>
							<div class="p-col-1">
								<input id="ART_UNIDAD" type="text" pInputText disabled [(ngModel)]="txtArtUnidad"
									autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Existencia Máxima:</span>
									</div>
									<div class="p-col-5">
										<input id="ART_EXISTMAX" type="text" pInputText disabled autocomplete="off"
											[(ngModel)]="txtExistenciaMax">
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -5px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-1">
								<span>Descripción:</span>
							</div>

							<div class="p-col-2" style="text-align: left">
								<input id="ART_DESCRIPCION" type="text" pInputText disabled autocomplete="off"
									[(ngModel)]="txtArtDescripcion">
							</div>

							<div class="p-col-2">
								<!--								<span>Rotación:</span>-->
							</div>
							<div class="p-col-1">
								<!--								<input id="ART_ROTACION" type="text" pInputText disabled [(ngModel)]="txtArtRotacion"-->
								<!--									   autocomplete="off">-->
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-7">
										<span>Existencia Mínima:</span>
									</div>
									<div class="p-col-5">
										<input id="ART_EXISTMIN" type="text" pInputText disabled
											[(ngModel)]="txtExistenciaMin" autocomplete="off">
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-table [value]="detalleExistencia">
							<ng-template pTemplate="header">
								<tr>
									<th>Existencia</th>
									<th>Ordenes de Compra</th>
									<th>Pedidos Clientes</th>
									<th>Saldo</th>
									<th>Existencia Mínima</th>
									<th>Comprar</th>
									<th>Últ. Costo Compra</th>
									<th>Total Compra</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-existencia>
								<tr>
									<td style="text-align: right">{{existencia.EXISTENCIA}}</td>
									<td style="text-align: right">{{existencia.ORDENES_COMPRA}}</td>
									<td style="text-align: right">{{existencia.PEDIDOS_CLIENTES}}</td>
									<td style="text-align: right">{{existencia.SALDO}}</td>
									<td style="text-align: right">{{existencia.EXIST_MIN}}</td>
									<td style="text-align: right">{{existencia.COMPRAR}}</td>
									<td style="text-align: right">{{existencia.ULTCOSTO_COM}}</td>
									<td style="text-align: right">{{existencia.TOTAL_COMPRA}}</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
				<p-tabView>
					<p-tabPanel header="Movimientos">
						<div class="p-col-12" style="margin-top: 10px;">
							<div class="p-grid">
								<div class="p-col-3">
								</div>
								<div class="p-col-2">
									<span>Ordenar por:</span>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-radioButton name="orden" value="fecha" (onClick)="llenarMovByProvArt('fecha')"
										inputId="orden1"></p-radioButton>
									<label> Fecha </label>
								</div>
								<div class="p-col-2" style="text-align: left">
									<p-radioButton name="orden" value="costo" (onClick)="llenarMovByProvArt('costo')"
										inputId="orden2"></p-radioButton>
									<label> Costo </label>
								</div>
							</div>
						</div>
						<app-aggridsaci [width]="" [height]="'450'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detMovimientosProv" [frameworkComponents]="frameworkComponents"
							[animateRows]="true" [rowSelection]="rowSelection"
							[columnDefs]="columnDefsMovimientosByProv" [defaultColDef]="defaultColMovByProv"
							[rowStyle]="rowStyle" [botones]="false" [singleClickEdit]="false">
						</app-aggridsaci>


					</p-tabPanel>
					<p-tabPanel header="Existencia por Bodega">
						<app-aggridsaci [width]="" [height]="'450'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detExistenciaBodegas" [frameworkComponents]="frameworkComponents"
							[animateRows]="true" [rowSelection]="rowSelection"
							[columnDefs]="columnDefsExistenciaBodegas" [defaultColDef]="defaultColExistBod"
							[rowStyle]="rowStyle" [botones]="false" [singleClickEdit]="false">
						</app-aggridsaci>
					</p-tabPanel>
					<p-tabPanel header="Ventas vs Compras">
						<div class="p-grid">
							<!--LINEA 1-->
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-12">
										<p-chart type="bar" [data]="dataVentasVsCompras" height="415px"></p-chart>
									</div>
								</div>
							</div>
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-4">
									</div>
									<div class="p-col-4"><span style="font-size: 16px; font-weight: bold;">
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											Índice de Provisión</span>
									</div>
									<div class="p-col-4">
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Inventario inicial:</span>
											</div>
											<div class="p-col-2">
												<input id="INVENTARIO_INICIAL" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtInventarioIni" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>(+) Compras del periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="COMPRAS_PERIODO" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtComprasPeriodo" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Total disponible:</span>
											</div>
											<div class="p-col-2">
												<input id="TOTAL_DISPONIBLE" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtTotalDisponible"
													style="text-align: right; background-color: yellow">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Ventas del último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="VENTA_ULTIMOPER" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtVenUltimoPer" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Ventas promedio 10 periodos anteriores:</span>
											</div>
											<div class="p-col-2">
												<input id="VENTASPROM_PERANT" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtVenPromPerAnt" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV_ULTPER" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtIndiceProvUltPer"
													style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión 10 últimos periodos:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV" type="text" pInputText autocomplete="off" readonly
													[(ngModel)]="txtIndiceProvPeriodos" style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Compras planificadas:</span>
											</div>
											<div class="p-col-2">
												<input id="COMPRAS_PLANIFICADAS" type="number" pInputText
													autocomplete="off" [(ngModel)]="txtComprasPlanificadas"
													style="text-align: right">
											</div>
											<div class="p-col-2">
												<button pButton (click)="calcularIndice()"
													style="background-image: url(../../../assets/images/iconos/cogwheel.png);background-color: transparent;"
													title="Calcular Índice">
												</button>
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Total disponible:</span>
											</div>
											<div class="p-col-2">
												<input id="totalDisponible" type="text" pInputText readonly
													autocomplete="off" [(ngModel)]="txtTotalDisponibleCom"
													style="text-align: right; background-color: yellow">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: 20px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión último periodo:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPROV_ULT" type="text" pInputText autocomplete="off"
													readonly [(ngModel)]="txtIndiceProvUltPer2"
													style="text-align: right">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -10px;">
										<div class="p-grid">
											<div class="p-col-2">
											</div>
											<div class="p-col-4">
												<span>Índice de provisión 10 últimos periodos:</span>
											</div>
											<div class="p-col-2">
												<input id="INDPRO" type="text" pInputText autocomplete="off" readonly
													[(ngModel)]="txtIndiceProvPeriodos2" style="text-align: right">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
	</p-tabPanel>
</p-tabView>
<div class="divgrupstabla" style="padding-bottom:-20px; margin-top: 0px">
	<div class="p-grid">
		<div class="p-col-1">
			<span class="obligatorio">Neto</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">Dsc(Valor)</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">Dsc(%)</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">Base I.V.A.</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">Valor I.V.A.</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">Base I.C.E.:</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">Valor I.C.E.</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">Base Cero:</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">Cargos:</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">Retención:</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">B.No Imp.Iva:</span>
		</div>
		<div class="p-col-1">
			<span class="obligatorio">Total</span>
		</div>
		<div class="p-col-12" style="margin-top: -7px">
			<div class="p-grid">
				<div class="p-col-1">
					<label for="ENCFACPRO_TOTALNETO"></label>
					<input class="totales" id="ENCFACPRO_TOTALNETO" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_TOTALNETO" autocomplete="off"
						style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="ENCFACPRO_VALORDES"></label>
					<input class="totales" id="ENCFACPRO_VALORDES" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_VALORDES" autocomplete="off"
						style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="ENCFACPRO_PORCEDES"></label>
					<input class="totales" id="ENCFACPRO_PORCEDES" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_PORCEDES" autocomplete="off"
						style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="ENCFACPRO_BASEIVA"></label>
					<input class="totales" id="ENCFACPRO_BASEIVA" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_BASEIVA" autocomplete="off"
						style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="ENCFACPRO_VALORIVA"></label>
					<input class="totales" id="ENCFACPRO_VALORIVA" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_VALORIVA" autocomplete="off"
						style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="ENCFACPRO_BASEICE"></label>
					<input class="totales" id="ENCFACPRO_BASEICE" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_BASEICE" autocomplete="off"
						style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="ENCFACPRO_VALORICE"></label>
					<input class="totales" id="ENCFACPRO_VALORICE" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_VALORICE" autocomplete="off"
						style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="ENCFACPRO_BASECERO"></label>
					<input class="totales" id="ENCFACPRO_BASECERO" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_BASECERO" autocomplete="off"
						style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="TOTALCARGOS"></label>
					<input class="totales" id="TOTALCARGOS" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].TOTALCARGOS" autocomplete="off"
						style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="TOTALRETENCION"></label>
					<input class="totales" id="TOTALRETENCION" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].TOTALRETENCION" autocomplete="off"
						style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="ENCFACPRO_BASENOOBJIVA"></label>
					<input class="totales" id="ENCFACPRO_BASENOOBJIVA" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_BASENOOBJIVA"
						autocomplete="off" style="text-align: right">
				</div>
				<div class="p-col-1">
					<label for="ENCFACPRO_TOTAL"></label>
					<input class="totales" id="ENCFACPRO_TOTAL" type="text" pInputText disabled
						[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_TOTAL" autocomplete="off"
						style="text-align: right">
				</div>
			</div>
		</div>
	</div>
</div>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" [consulta]="consulta" (opcionSeleccionada)="manejarSeleccion($event)">
	</app-busquedadlg>
</p-dialog>

<p-dialog header="Descuento Global" [(visible)]="displayDialogDescuento" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '35vw', height: '35vh'}">
	<div class="p-grid">
		<div class="p-col-12">
			<button pButton (click)="aplicarDescuento()"
				style="background-image: url(../../../assets/images/iconos/visto.png);">
			</button>
		</div>
		<div class="p-col-12">
			<p-tabView>
				<p-tabPanel header="Tipo Descuento">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="Por Valor" value="dscValor"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-3"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="En Porcentaje" value="dscPorcentaje"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
						<div class="p-col-4">
							<label for="DscValor"></label>
							<input id="DscValor" type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								[(ngModel)]="valorDscValor" [disabled]="booleanValor" appTwoDigitDecimaNumber>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-4">
							<label for="DscPorcentaje"></label>
							<input id="DscPorcentaje" type="text" pInputText pKeyFilter="num"
								(focusout)="comprobarValor()" [(ngModel)]="valorDscPorcentaje"
								[disabled]="booleanPorcentaje" appTwoDigitDecimaNumber>
						</div>
						<div class="p-col-1"></div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</p-dialog>

<p-dialog header="Datos Anexos" [(visible)]="displayDialogDatosAnexos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'}" (onHide)="verificarDatosAnexos()">
	<app-datosanexos [strnumfac]="encfacproService.encfacproarray[indicador].ENCFACPRO_NUMERO" [strOrigen]="'COM'"
		(salir)="salirDialog()">
	</app-datosanexos>
</p-dialog>

<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
						(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Clave Supervisor" [(visible)]="displayDialogSupervisor" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="usuariosup" style="font-weight: bold">Usuario:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input pInputText id="usuariosup" [(ngModel)]="usuarioSup" placeholder="Ingrese el usuario">
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clavesup" style="font-weight: bold">Clave:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clavesup" [(ngModel)]="claveSup"
						(keydown.enter)="validarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'comencfacpro'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr [strNumFact]="strNumFact" [strFechaSer]="strFechaSer" [strTipoDoc]="'FACPRO'"
		[strTipoTrn]="'EN'"></app-invtrnkardexotr>
</p-dialog>

<p-dialog header="Actualización Datos" [(visible)]="displayDialogActualizacionDE" [transitionOptions]="'0ms'"
	[modal]="true" appendTo="body" [style]="{width: '45vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<button pButton (click)="actualizarDatos('P', encfacproService.encfacproarray[indicador].PRO_CODIGO)" style="background-image: url(../../../assets/images/iconos/guardar.png);
 					background-size: contain !important; width: 40px; height: 40px">
			</button>
		</div>
		<div class="p-col-12">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-4">
						<label for="TXTCORREO1" style="font-weight: bold">Correo Electrónico1:</label>
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-6">
						<label for="TXTCORREO1"></label>
						<input class="frm" id="TXTCORREO1" type="text" pInputText [(ngModel)]="txtCorreo1"
							autocomplete="off">
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="TXTCORREO2" style="font-weight: bold">Correo Electrónico2:</label>
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-6">
						<label for="TXTCORREO2"></label>
						<input class="frm" id="TXTCORREO2" type="text" pInputText [(ngModel)]="txtCorreo2"
							autocomplete="off">
					</div>
					<div class="p-col-2"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Distribución de costos" [(visible)]="displayDialogDistribucion" [transitionOptions]="'0ms'"
	[modal]="true" appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-comtrndistribucioncc [strnumfac]="encfacproService.encfacproarray[indicador].ENCFACPRO_NUMERO"
		[detalle]="detfacproSelected" [decimalesTOTALDetFacPro]="encfacproService.decimalesTOTALDetFacPro">
	</app-comtrndistribucioncc>
</p-dialog>


<p-dialog header="Pago a Documento" [(visible)]="displayDialogPagoDocumento" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '90vw', height: '90vh'}" (onHide)="onHidePDEvent($event)" closable="false">
	<app-cxptrnpagodocumento *ngIf="displayDialogPagoDocumento" [auxiliar]="auxiliar2"
		[auxNumFact]="encfacproService.encfacproarray[indicador].ENCFACPRO_NUMERO"
		[encfacpro]="encfacproService.encfacproarray[indicador]" (cerrar)="cerrarPago()"></app-cxptrnpagodocumento>
</p-dialog>


<p-dialog header="Reembolsos" [(visible)]="displayDialogReembolsos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '65vw', height: 'auto'}" (onHide)="verificarImportes()">
	<app-comtrnreembolsos [auxiliar]="auxiliar" [strNumFact]="strNumFact" [ivavigente]="ivavigente"
		(cerrar)="cerrarReembolsos()">
	</app-comtrnreembolsos>
</p-dialog>

<p-dialog header="Actualización de Precios" [(visible)]="displayDialogActualizacionPrecios" [transitionOptions]="'0ms'"
	[modal]="true" appendTo="body" [style]="{width: '80vw', height: '90vh'}">
	<app-actualizacionpreciosfp [auxiliar]="auxiliar" [strNumFact]="strNumFact" [hmpConfiguracion]="hmpConfiguracion"
		(cerrar)="cerrarActPrecios()"></app-actualizacionpreciosfp>
</p-dialog>

<p-dialog header="Log de la Firma Electrónica" [(visible)]="displayDialogLog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: '60vh'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<textarea rows="12" cols="100" pInputTextarea style="width: 100%; height: 400px" [(ngModel)]="logResultado"
				[disabled]="true" maxlength="3000"></textarea>
		</div>
	</div>
</p-dialog>

<p-dialog header="Cargar Datos Retención Electrónica" [(visible)]="displayDialogCargarRetElec"
	[transitionOptions]="'0ms'" [modal]="true" appendTo="body" [style]="{width: '65vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<button pButton (click)="cargarDatosRetencionElec()" style="background-image: url(../../../assets/images/iconos/guardar.png);
 					background-size: contain !important; width: 40px; height: 40px">
			</button>
		</div>
		<div class="p-col-12">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12" style="margin-bottom: -20px">
						<div class="p-grid">
							<div class="p-col-6">
							</div>
							<div class="p-col-4">
								<span>{{encfacproService.encfacproarray[indicador].PRO_CODIGO}}</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-4">
								<span>{{encfacproService.encfacproarray[indicador].ENCFACPRO_NUMERO}}</span>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-bottom: -20px">
						<div class="p-grid">
							<div class="p-col-2">
								<label for="LOCALIZACIONXML" style="font-weight: bold">Localización XML:</label>
							</div>
							<div class="p-col-5">
								<label for="LOCALIZACIONXML"></label>
								<input class="frm" id="LOCALIZACIONXML" type="text" pInputText
									[(ngModel)]="encfacproService.encfacproarray[indicador].ENCFACPRO_LOCALIZACIONXMLRET"
									autocomplete="off">
							</div>
							<div class="p-col-5">
								<p-fileUpload (uploadHandler)="onBasicUpload($event)" accept=".xml" chooseLabel="..."
									auto="false" customUpload="true" mode="advanced" name="myfile[]">
								</p-fileUpload>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<span>{{encfacproService.encfacproarray[indicador].ENCFACPRO_PATHXMLRET}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Importar Xml Factura" [(visible)]="displayDialogSubirXmlFactura" [transitionOptions]="'0ms'"
	[modal]="true" appendTo="body" [style]="{width: '65vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encfacproimpxml"></p-toast>
	<div class="p-grid">
		<div class="p-col-12">
			<button pButton (click)="procesar()" style="background-image: url(../../../assets/images/iconos/visto.png);
 					background-size: contain !important; width: 40px; height: 40px">
			</button>
		</div>
		<div class="p-col-12">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Bodega:</span>
							</div>
							<div class="p-col-2">
								<label for="BOD_CODIGOXML"></label>
								<input class="frm" id="BOD_CODIGOXML" type="text" pInputText [(ngModel)]="bodcodigoxml"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaBodega(bodcodigoxml, 'bodegaxml')"></button>
							</div>
							<div class="p-col-3">
								<label for="BOD_NOMBREXML"></label>
								<input class="frm" id="BOD_NOMBREXML" type="text" pInputText [(ngModel)]="bodnombrexml"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<button class="sincss" pButton (click)="actualizarCodigosAlternosXml()">Agrupar Códigos
									Alternos
								</button>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Id. del Crédito Tributario:</span>
							</div>
							<div class="p-col-2">
								<label for="IDCREDITOXML"></label>
								<input class="frm" id="IDCREDITOXML" type="text" pInputText [(ngModel)]="idcreditoxml"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaidcretri(idcreditoxml, 'cretrixml')"></button>
							</div>
							<div class="p-col-3">
								<label for="IDCREDITONOMBREXML"></label>
								<input class="frm" id="IDCREDITONOMBREXML" type="text" pInputText
									[(ngModel)]="idcreditonombrexml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Centro de costos:</span>
							</div>
							<div class="p-col-2">
								<label for="CENTROCOSTOSXML"></label>
								<input class="frm" id="CENTROCOSTOSXML" type="text" pInputText
									[(ngModel)]="centrocostosxml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaCEN(centrocostosxml)"></button>
							</div>
							<div class="p-col-3">
								<label for="CENTROCOSTOSNOMBREXML"></label>
								<input class="frm" id="CENTROCOSTOSNOMBREXML" type="text" pInputText
									[(ngModel)]="centrocostosnombrexml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Localización de archivos:</span>
							</div>
							<div class="p-col-4">
								<label for="LOCALIZACIONXML2"></label>
								<input class="frm" id="LOCALIZACIONXML2" type="text" pInputText
									[(ngModel)]="localizacionxml2" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-3">
								<p-fileUpload (uploadHandler)="SeleccionarDirectorio($event)" accept=".xml"
									chooseLabel="..." auto="false" customUpload="true" mode="advanced" name="myfile[]">
								</p-fileUpload>
							</div>
							<div class="p-col-2">
								<button class="sincss" pButton (click)="convertir()">Convertir
								</button>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-bottom: -20px">
						<div class="p-grid">
							<div class="p-col-1">
								<span style="text-align: left">IMPORTANTE:</span>
							</div>
							<div class="p-col-11">
							</div>
							<div class="p-col-11" style="text-align: left">
								<span>1.- El formato del archivo tiene que ser bajado de la página del SRI.</span>
							</div>
							<div class="p-col-11" style="text-align: left; margin-top: -10px">
								<span style="text-align: left">2.- El código del artículo que maneja el proveedor no es
									igual al código del artículo que maneja la empresa, para permitir que el sistema
									identifique
									y relacione los códigos correctamente es necesario por favor ingresar el código que
									maneja el proveedor en los campos código alt.1, código alt.2,
									código alt.3 del maestro de artículos.</span>
							</div>
							<div class="p-col-11" style="text-align: left; margin-top: -10px">
								<span style="text-align: left">3.- Para iniciar por favor presione el botón Agrupar
									códigos alternos, para optimizar la búsqueda de códigos alternos guardados en la
									base de datos</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Leer factura de proveedores (formato1)" [(visible)]="displayDialogSubirXmlFacturaFormato1"
	[transitionOptions]="'0ms'" [modal]="true" appendTo="body" [style]="{width: '65vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encfacproimpxml"></p-toast>
	<div class="p-grid">
		<div class="p-col-12">
			<button pButton (click)="procesarFormato1('1')" style="background-image: url(../../../assets/images/iconos/visto.png);
 					background-size: contain !important; width: 40px; height: 40px">
			</button>
		</div>
		<div class="p-col-12">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Bodega:</span>
							</div>
							<div class="p-col-2">
								<label for="BOD_CODIGOXMLF1"></label>
								<input class="frm" id="BOD_CODIGOXMLF1" type="text" pInputText
									[(ngModel)]="bodcodigoxml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaBodega(bodcodigoxml, 'bodegaxml')"></button>
							</div>
							<div class="p-col-3">
								<label for="BOD_NOMBREXMLF1"></label>
								<input class="frm" id="BOD_NOMBREXMLF1" type="text" pInputText
									[(ngModel)]="bodnombrexml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<button class="sincss" pButton (click)="numeroBloqueArchivos()">Número de bloque de
									Archivos
								</button>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Id. del Crédito Tributario:</span>
							</div>
							<div class="p-col-2">
								<label for="IDCREDITOXMLF1"></label>
								<input class="frm" id="IDCREDITOXMLF1" type="text" pInputText [(ngModel)]="idcreditoxml"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaidcretri(idcreditoxml, 'cretrixml')"></button>
							</div>
							<div class="p-col-3">
								<label for="IDCREDITONOMBREXMLF1"></label>
								<input class="frm" id="IDCREDITONOMBREXMLF1" type="text" pInputText
									[(ngModel)]="idcreditonombrexml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<label for="NUMEROBLOQUEARCHIVOS"></label>
								<input class="frm" id="NUMEROBLOQUEARCHIVOS" type="text" pInputText
									[(ngModel)]="numerobloquearchivosxml" disabled="true" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Centro de costos:</span>
							</div>
							<div class="p-col-2">
								<label for="CENTROCOSTOSXMLF1"></label>
								<input class="frm" id="CENTROCOSTOSXMLF1" type="text" pInputText
									[(ngModel)]="centrocostosxml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaCEN(centrocostosxml)"></button>
							</div>
							<div class="p-col-3">
								<label for="CENTROCOSTOSNOMBREXMLF1"></label>
								<input class="frm" id="CENTROCOSTOSNOMBREXMLF1" type="text" pInputText
									[(ngModel)]="centrocostosnombrexml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Localización de archivos:</span>
							</div>
							<div class="p-col-4">
								<label for="LOCALIZACIONXML2F1"></label>
								<input class="frm" id="LOCALIZACIONXML2F1" type="text" pInputText
									[(ngModel)]="localizacionxml2" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-3">
								<p-fileUpload (uploadHandler)="SeleccionarDirectorioMultiple($event)" accept=".xml"
									chooseLabel="..." multiple="true" auto="false" customUpload="true" mode="advanced"
									name="myfile[]">
								</p-fileUpload>
							</div>
							<div class="p-col-3">
								<div class="p-grid">
									<div class="p-col-10">
										<div class="p-grid">
											<div class="p-col-12">
												<label for="NUMEROBLOQUEARCHIVOSEDIT">Bloque de Archivos:</label>
												<input class="frm" id="NUMEROBLOQUEARCHIVOSEDIT" type="text" pInputText
													[(ngModel)]="strnumerobloquearchivosxml"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-12">
												<button class="sincss" pButton (click)="listarArchivos()">Listar
													Archivos
												</button>
											</div>
										</div>
									</div>
									<div class="p-col-2">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/eliminar.png);"
											(click)="eliminarBloqueArchivos()"></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div style="margin-top: -20px">
		<app-aggridsaci [width]="" [height]="680" [enableSorting]="true" [enableFilter]="true" [rowData]="subirxml"
			[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
			[columnDefs]="columnDefsSubirXml" [defaultColDef]="defaultColDefSubirXml" [rowStyle]="rowStyle"
			[botones]="false" [singleClickEdit]="false"></app-aggridsaci>
	</div>
</p-dialog>

<p-dialog header="Leer factura de proveedores (formato2)" [(visible)]="displayDialogSubirXmlFacturaFormato2"
	[transitionOptions]="'0ms'" [modal]="true" appendTo="body" [style]="{width: '65vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encfacproimpxml"></p-toast>
	<div class="p-grid">
		<div class="p-col-12">
			<button pButton (click)="procesarFormato1('2')" style="background-image: url(../../../assets/images/iconos/visto.png);
 					background-size: contain !important; width: 40px; height: 40px">
			</button>
		</div>
		<div class="p-col-12">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Bodega:</span>
							</div>
							<div class="p-col-2">
								<label for="BOD_CODIGOXMLF2"></label>
								<input class="frm" id="BOD_CODIGOXMLF2" type="text" pInputText
									[(ngModel)]="bodcodigoxml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaBodega(bodcodigoxml, 'bodegaxml')"></button>
							</div>
							<div class="p-col-3">
								<label for="BOD_NOMBREXMLF2"></label>
								<input class="frm" id="BOD_NOMBREXMLF2" type="text" pInputText
									[(ngModel)]="bodnombrexml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<button class="sincss" pButton (click)="numeroBloqueArchivos()">Número de bloque de
									Archivos
								</button>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Id. del Crédito Tributario:</span>
							</div>
							<div class="p-col-2">
								<label for="IDCREDITOXMLF2"></label>
								<input class="frm" id="IDCREDITOXMLF2" type="text" pInputText [(ngModel)]="idcreditoxml"
									(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaidcretri(idcreditoxml, 'cretrixml')"></button>
							</div>
							<div class="p-col-3">
								<label for="IDCREDITONOMBREXMLF2"></label>
								<input class="frm" id="IDCREDITONOMBREXMLF2" type="text" pInputText
									[(ngModel)]="idcreditonombrexml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<label for="NUMEROBLOQUEARCHIVOS2"></label>
								<input class="frm" id="NUMEROBLOQUEARCHIVOS2" type="text" pInputText
									[(ngModel)]="numerobloquearchivosxml" disabled="true" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Centro de costos:</span>
							</div>
							<div class="p-col-2">
								<label for="CENTROCOSTOSXMLF2"></label>
								<input class="frm" id="CENTROCOSTOSXMLF2" type="text" pInputText
									[(ngModel)]="centrocostosxml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaCEN(centrocostosxml)"></button>
							</div>
							<div class="p-col-3">
								<label for="CENTROCOSTOSNOMBREXMLF2"></label>
								<input class="frm" id="CENTROCOSTOSNOMBREXMLF2" type="text" pInputText
									[(ngModel)]="centrocostosnombrexml" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Localización de archivos:</span>
							</div>
							<div class="p-col-4">
								<label for="LOCALIZACIONXML2F2"></label>
								<input class="frm" id="LOCALIZACIONXML2F2" type="text" pInputText
									[(ngModel)]="localizacionxml2" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-3">
								<p-fileUpload (uploadHandler)="SeleccionarDirectorioMultiple($event)" accept=".xml"
									chooseLabel="..." multiple="true" auto="false" customUpload="true" mode="advanced"
									name="myfile[]">
								</p-fileUpload>
							</div>
							<div class="p-col-3">
								<div class="p-grid">
									<div class="p-col-10">
										<div class="p-grid">
											<div class="p-col-12">
												<label for="NUMEROBLOQUEARCHIVOSEDIT2">Bloque de Archivos:</label>
												<input class="frm" id="NUMEROBLOQUEARCHIVOSEDIT2" type="text" pInputText
													[(ngModel)]="strnumerobloquearchivosxml"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-12">
												<button class="sincss" pButton (click)="listarArchivos()">Listar
													Archivos
												</button>
											</div>
										</div>
									</div>
									<div class="p-col-2">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/eliminar.png);"
											(click)="eliminarBloqueArchivos()"></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div style="margin-top: -20px">
		<app-aggridsaci [width]="" [height]="680" [enableSorting]="true" [enableFilter]="true" [rowData]="subirxml"
			[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
			[columnDefs]="columnDefsSubirXml" [defaultColDef]="defaultColDefSubirXml" [rowStyle]="rowStyle"
			[botones]="false" [singleClickEdit]="false"></app-aggridsaci>
	</div>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="encfacproService.encfacproarray[indicador].ENCFACPRO_NUMERO" [strCampo]="'ENCFACPRO_NUMERO'"
		(cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>


<p-dialog header="Lotes" [(visible)]="displayDialogLotes" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-invtrnlote [auxiliar]="auxiliarlot" [detalle]="detfacproSelected"
		[encabezado]="encfacproService.encfacproarray[indicador]" [cadena]="'FACPRO'" (salir)="cerrarLotes()">
	</app-invtrnlote>
</p-dialog>
<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'COMLIQUIDCOM'"
		[txtNumComprobante]="encfacproService.encfacproarray[indicador].ENCFACPRO_NUMERO"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
<!-- CDPJ -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true" [closable]="false"
		[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>

<p-dialog header="Estados firma electrónica" [(visible)]="displayDialogEst" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: '60vh'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<label for="ESTFIRMA"></label>
			<textarea id="ESTFIRMA" rows="12" cols="100" pInputTextarea
				style="width: 100%; height: 230px;font-size: 16px;" [(ngModel)]="estfirma" [disabled]="true"
				maxlength="3000"></textarea>
		</div>
	</div>
</p-dialog>
<p-dialog header="Bases IVA" [(visible)]="displaybasesiva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '55vw', height: '55vh'} " [focusOnShow]="true" appendTo="body">

	<app-aggridsaci [width]="-800" [height]="-800" [enableSorting]="true" [enableFilter]="true" [rowData]="basesiva"
		[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
		[columnDefs]="columnDefsBasesIva" [defaultColDef]="defaultColBasesIva" [rowStyle]="rowStyle" [botones]="false"
		[singleClickEdit]="false"></app-aggridsaci>



</p-dialog>
<p-dialog header="Seleccion Reembolsos" [(visible)]="displayDialogSelectReem" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '65vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encfacproselecreem"></p-toast>
	<div class="p-grid" style="width: 98%">
		<div class="p-col-12">
			<app-invmaebarra (signal)="manejarSenalesReem($event)" [barraBotonesCRUD]="true"
				[barraBotonesDesplaz]="true" [barraBotones3]="true" [botonVisto]="false" [barraBotonesAsiCon]="true"
				[barraBotones3Alt]="false" [barraBotones5]="true"></app-invmaebarra>
		</div>
	</div>

	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-1">
				<span>Caja:</span>
			</div>
			<div class="p-col-3">
				<p-dropdown [options]="cmbenccaja" [style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
					[panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipocaj" (onChange)="busqlote($event)">
				</p-dropdown>
			</div>
			<div class="p-col-1">
				<span>Id.Crédito:</span>
			</div>
			<div class="p-col-2">
				<input class="frm" id="lblIdCreTri" type="text" pInputText maxlength="49" [disabled]="true"
					[(ngModel)]="lblIdCreTri" autocomplete="off">
			</div>
			<div class="p-col-12" style="margin-top: 5px">
				<div class="p-grid">
					<div class="p-col-1">
						<span>Lote:</span>
					</div>
					<div class="p-col-3">
						<p-dropdown [options]="cmblote" [style]="{'minWidth': '100%', 'width':'100%'}"
							optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipoclote"
							>
						</p-dropdown>
					</div>
				</div>
			</div>
		</div>
	</div>










</p-dialog>
<!-- CDPJ -->