import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Invsalida} from '../invinterfaces/invsalida';
import {Invencsalida} from '../invinterfaces/invencsalida';
import {Invsalidavar} from '../invinterfaces/invsalidavar';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvsalidaService {
  
  public url: string;
  public decimalesPrecioSal: number;
  public decimalesCantidadSal: number;
  public decimalesTotalSal: number;
  public auxsalto;
  public date1: any;
  public hora: any;
  public detkardexsarray: Invsalida[][] = [];
  public detkardexsvararray: Invsalidavar[][] = [];
  public totalSalidaarray: string[] = [];
  public way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService,
              private init: NuevoComponentService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  
  getEncSalidas(): Observable<any[]> {
 
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/fw3yykopa4yvrqq', {
      elementos: {},
  }, this.confIniciales.httpOptions());
  }
  
  getSalidas(codigo): Observable<any[]> {
    
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/7ckcjjx3pn3xr5i', {
      elementos: {},
  }, this.confIniciales.httpOptions());
  }
  
  consultarDetKardex(codigo): Observable<any[]> {
   
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/9w6rp0a3psq29sw', {
      elementos: {
        p_KAR_nro_1: codigo,
        p_com_codigo: '01'
      },
  }, this.confIniciales.httpOptions());
  }
  
  getSalidasVar(codigo): Observable<any[]> {
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/g40q1dyi9z4jhlw', {
      elementos: {
        KAR_NRO: codigo,
      },
  }, this.confIniciales.httpOptions());
  }
  
  getArticulo(artCod): Observable<any> {
    
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/9u4b3dhplyngzc2', {
      elementos: {
        ART_CODIGO: artCod,
      },
  }, this.confIniciales.httpOptions());
  }
  
  
  insertarEncSalida(encsalida: Invencsalida): Observable<any> {
    this.url = sessionStorage.getItem('url');
    
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/2hhj6bmys5ttxra', {
      elementos: {
        p_KAR_fecha_3: encsalida.KAR_FECHA,
        p_KAR_hora_4: encsalida.KAR_HORA,
        p_KAR_comentario_5: encsalida.KAR_COMENTARIO,
        p_KAR_tipo_2: encsalida.KAR_TIPO,
        p_KAR_origen_6: encsalida.KAR_ORIGEN,
        p_COM_codigo_7: '01',
        p_tipdia_codigo: encsalida.TIPDIA_CODIGO,
        p_KAR_NROCOMPROBANTE: encsalida.KAR_NROCOMPROBANTE,
        p_USUidentificacion_1: encsalida.USUIDENTIFICACION,
        p_encordpro_numero: encsalida.ENCORDPRO_NUMERO,
        p_KAR_SALDOINI: encsalida.KAR_SALDOINI,
        p_KAR_NO_KARDEX: encsalida.KAR_NO_KARDEX,
        p_KAR_ESTADO: encsalida.KAR_ESTADO
      },
  }, this.confIniciales.httpOptions());
  }
  
  insertarSalida(salida: Invsalida): Observable<any> {
    this.url = sessionStorage.getItem('url');
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/v336arq3rdvr4y', {
      elementos: {
        p_KAR_nro_1: salida.KAR_NRO,
        p_BOD_codigo_2: salida.BOD_CODIGO,
        p_ART_codigo_3: salida.ART_CODIGO,
        p_DETKAR_NRODOC: salida.DETKAR_NRODOC,
        p_DETKAR_cantidad_4: Number(salida.DETKAR_CANTIDAD),
        p_DETKAR_costounit_5: Number(salida.DETKAR_COSTOUNIT),
        p_DETKAR_costototal_6: Number(salida.DETKAR_CANTIDAD * salida.DETKAR_COSTOUNIT),
        p_BOD_codigo_ant: salida.BOD_CODIGO,
        p_ART_codigo_ant: salida.ART_CODIGO,
        p_CON_CODIGO: salida.CON_CODIGO,
        p_com_codigo: '01',
        p_detkar_linea: salida.DETKAR_LINEA,
        p_encgre_codigo: salida.ENCGRE_CODIGO,
        p_detgre_linea: salida.DETGRE_LINEA,
        p_detkar_lote: salida.DETKAR_LOTE,
        p_cli_codigo: salida.CLI_CODIGO,
        p_detkar_puntos: salida.DETKAR_PUNTOS,
        p_maepun_codigo: salida.MAEPUN_CODIGO,
        p_detkar_cantidadund: 0,
        p_cen_codigo:salida.CEN_CODIGO
      },
  }, this.confIniciales.httpOptions());
  }
  
  insertarSalidaVar(salidavar: Invsalidavar): Observable<any> {
   
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/31cztmf433nq7rk', {
      elementos: {
        p_kar_nro: salidavar.KAR_NRO,
        p_CON_CODIGO: salidavar.CON_CODIGO,
        p_CON_NOMBRE: salidavar.CON_NOMBRE,
        p_CEN_CODIGO: salidavar.CEN_CODIGO,
        p_COM_CODIGO: '01',
        p_DETKARDEXVAR_LINEA: Number(salidavar.DETKARDEXVAR_LINEA),
        p_DETKARDEXVAR_IMPORTE: Number(salidavar.DETKARDEXVAR_IMPORTE)
      },
  }, this.confIniciales.httpOptions());
  }
  
  actualizarKardex_en_sa(encSalida: Invencsalida) {

    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/8rh8mkj8peth3xu', {
      elementos: {
        p_kar_nro: encSalida.KAR_NRO,
        p_com_codigo: encSalida.COM_CODIGO,
      },
  }, this.confIniciales.httpOptions());
    
  }
  
  actualizarEncSalida(encsalida: Invencsalida): Observable<any> {
    
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/9geupxhq6fbs5ps', {
      elementos: {
        p_KAR_nro_1: encsalida.KAR_NRO,
        p_KAR_fecha_3: encsalida.KAR_FECHA,
        p_KAR_hora_4: encsalida.KAR_HORA,
        p_KAR_comentario_5: encsalida.KAR_COMENTARIO,
        p_KAR_tipo_2: encsalida.KAR_TIPO,
        p_KAR_origen_6: encsalida.KAR_ORIGEN,
        p_COM_codigo_7: '01',
        p_tipdia_codigo: encsalida.TIPDIA_CODIGO,
        p_KAR_NROCOMPROBANTE: encsalida.KAR_NROCOMPROBANTE,
        p_USUidentificacion_1: encsalida.USUIDENTIFICACION,
        p_encordpro_numero: encsalida.ENCORDPRO_NUMERO,
        p_KAR_SALDOINI: encsalida.KAR_SALDOINI,
        p_KAR_NO_KARDEX: encsalida.KAR_NO_KARDEX,
        p_KAR_ESTADO: encsalida.KAR_ESTADO
      },
  }, this.confIniciales.httpOptions());
  }
  
  borrarSalida(salida: Invsalida): Observable<any> {
  
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/6ao6awzzfoda4c3', {
      elementos: {
        p_KAR_nro_1: salida.KAR_NRO,
        p_com_codigo: '01',
        p_detkar_linea: salida.DETKAR_LINEA
      },
  }, this.confIniciales.httpOptions());
  }
  
  borrarSalidaVarios(salidaVar: Invsalidavar): Observable<any> {
  
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/1l9f2u3m69j0b14', {
      elementos: {
        p_kar_nro: salidaVar.KAR_NRO,
        p_detkardexvar_linea: salidaVar.DETKARDEXVAR_LINEA,
        p_con_codigo: salidaVar.CON_CODIGO,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  borrarEncSalida(salida: Invencsalida): Observable<any> {
   
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/gmey60v3p7gmdqn', {
      elementos: {
        p_KAR_nro_1: salida.KAR_NRO,
      },
    }, this.confIniciales.httpOptions());
  }
  
  generarAsiento(salida: Invencsalida): Observable<any> {
  
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/50ygv0r1kmgkopa', {
      elementos: {
        p_KAR_NRO: salida.KAR_NRO,
        p_kar_tipo: salida.KAR_TIPO,
        p_com_codigo: '01',
        p_usuario: 'admin',
        p_tipdia_codigo: 'AS',
      },
    }, this.confIniciales.httpOptions());
  }

  generarAsientoProm(salida: Invencsalida) {
  
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/50ygv0r1kmgkopa', {
      elementos: {
        p_KAR_NRO: salida.KAR_NRO,
        p_kar_tipo: salida.KAR_TIPO,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'AS',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  generarAsientoProdProm(salida: Invencsalida,usuident: string) {
  
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/20ebbb6adab928000000000', {
      elementos: {
        p_KAR_NRO: salida.KAR_NRO,
        p_kar_tipo: salida.KAR_TIPO,
        p_com_codigo: '01',
        p_usuario: usuident,
        p_tipdia_codigo: 'OPP',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  getAsiento(salida: Invencsalida): Observable<any> {
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/k4l7b22az9tgfsr', {
      elementos: {
        KAR_NRO: salida.KAR_NRO
      },
    }, this.confIniciales.httpOptions());
  }

  getAsientoProm(salida: Invencsalida) {
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/k4l7b22az9tgfsr', {
      elementos: {
        KAR_NRO: salida.KAR_NRO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  getExistenciaArt(strTipo, strartCod, strbodCod,strfecha,strhora): Observable<any> {
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/ej234mlq0zshp0x', {
      elementos: {
        tipo:strTipo,
        artCod:strartCod,
        bodCod:strbodCod  ,
        fecha:strfecha,
        hora:strhora
      },
    }, this.confIniciales.httpOptions());
  }
  
  getcantidadGrabadaDoc(strnroDocumento, strcodArticulo): Observable<any> {
   console.log(strnroDocumento, strcodArticulo);
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/180d2zzl3dhh0dv', {
      elementos: {
        nroDocumento:strnroDocumento,
        codArticulo:strcodArticulo, 
      },
    }, this.confIniciales.httpOptions());
  }
  
  getCostoPromedioArt(strcodArticulo): Observable<any> {
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/6keh5yd1l31xfbd', {
      elementos: {
        codArticulo:strcodArticulo
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarAsientoCont(salida): Observable<any> {
  
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/nmmj0hg4se1zahz', {
      elementos: {
        p_ASI_nro: salida,
        p_COM_codigo_2: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  redondearDecimales(num,numdecimales) {
    //let val=0
    console.log(num);
    //val=(Math.round((num + Number.EPSILON) *100)/100);
    //val=Number((num + Number.EPSILON).toFixed(this.decimalesTotalEnt))
    let factor = Math.pow(10,numdecimales);
    let val = Math.round((num + Number.EPSILON) * factor) / factor
    val=Number(val.toFixed(numdecimales))



    console.log('val',val)
    //val=Number(Number(val).toFixed(this.decimalesTotalEnt));
    //console.log(val.toFixed(2))
    //console.log('>><<<<<',total)
    return val;
  }
  calcularTotal() {
    let dblCostoTotal = 0;
    
    
    for (let fila = 0; fila < this.detkardexsarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      dblCostoTotal = 0;
      dblCostoTotal = this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila].DETKAR_COSTOUNIT * this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila].DETKAR_CANTIDAD;
      this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila].DETKAR_COSTOTOTAL = dblCostoTotal;
    }
    
    let dblCostoTotalGlobal = 0;
    for (let fila1 = 0; fila1 < this.detkardexsarray[this.init.tabs[this.init.tabindex].indice].length; fila1++) {
      console.log('suma',Number(this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL))
      //dblCostoTotalGlobal = dblCostoTotalGlobal + Number(this.detkardexarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL);
      dblCostoTotalGlobal = dblCostoTotalGlobal + Number(this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL);
    }
    for (let fila1 = 0; fila1 < this.detkardexsarray[this.init.tabs[this.init.tabindex].indice].length; fila1++) {
      this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_CANTIDAD = Number(this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_CANTIDAD).toFixed(this.decimalesCantidadSal);
      this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOUNIT = Number(this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOUNIT).toFixed(this.decimalesPrecioSal);
      this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL = Number(this.redondearDecimales(this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL,this.decimalesTotalSal)).toFixed(this.decimalesTotalSal);
     // dblCostoTotalGlobal = dblCostoTotalGlobal + Number(this.detkardexsarray[this.init.tabs[this.init.tabindex].indice][fila1].DETKAR_COSTOTOTAL);
    }
    dblCostoTotalGlobal=this.redondearDecimales(dblCostoTotalGlobal,'2')
    this.totalSalidaarray[this.init.tabs[this.init.tabindex].indice] = String(dblCostoTotalGlobal);
    this.totalSalidaarray[this.init.tabs[this.init.tabindex].indice] = Number(this.totalSalidaarray[this.init.tabs[this.init.tabindex].indice]).toFixed(2);
  }
  
  insertarSalidaVarProm(salidavar: Invsalidavar) {
    return this.http.post(this.way +'/inventario/kardexsalidas/5b6zk9n21z70ick', {
      elementos: {
        p_kar_nro: salidavar.KAR_NRO,
        p_CON_CODIGO: salidavar.CON_CODIGO,
        p_CON_NOMBRE: salidavar.CON_NOMBRE,
        p_CEN_CODIGO: salidavar.CEN_CODIGO,
        p_COM_CODIGO: '01',
        p_DETKARDEXVAR_LINEA: Number(salidavar.DETKARDEXVAR_LINEA),
        p_DETKARDEXVAR_IMPORTE: Number(salidavar.DETKARDEXVAR_IMPORTE)
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  borrarSalidaVariosProm(salidaVar: Invsalidavar) {
      
    return this.http.post(this.way +'/inventario/kardexsalidas/ne9mi5bpk0zdbu6', {
      elementos: {
        p_kar_nro: salidaVar.KAR_NRO,
        p_detkardexvar_linea: salidaVar.DETKARDEXVAR_LINEA,
        p_con_codigo: salidaVar.CON_CODIGO,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ecvalidarseriales(strarticulo,strNumDoc,strintLinea){
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/i69g8q6duizv4rl', {
      elementos: {
        articulo:strarticulo,
        NumDoc:strNumDoc,
        intLinea:strintLinea
      },
    }, this.confIniciales.httpOptions()).toPromise();
    
  }
   //CDPJ
   erBodCodigo1(strCodigo) {
    return this.http.post(this.way + '/inventario/entradas/76d786b30d94300', {
      elementos: {
        BOD_CODIGO: strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ValidaEnSaOrdProd(salida: Invencsalida) {
   
    return this.http.post<any[]>(this.way +'/inventario/kardexsalidas/20eebafc5636dc0000', {
      elementos: {
        p_prod_numero: salida.ENCORDPRO_NUMERO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  
}
