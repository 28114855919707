import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { MessageService } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { VenEncfacZ } from '../veninterfaces/venencfacz';
import { VenDetfacZ } from '../veninterfaces/vendetfacz';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';

@Injectable({
  providedIn: 'root'
})
export class VenencfacService {

  public url: string;
  public way: string;

  public encfacarray: VenEncfacZ[] = [];
  public detfacarray: VenDetfacZ[][] = [];

  public hmpConfiguracion: any[];
  public dblPorcDescGlb: any;
  public dblValorDescGlb: any;
  public dblValorotrdscto:any;//CDPJ
  public dblValorotrdsctoDif:any;//CDPJ
  //CDPJ
  public porcetariiva:number;
  public codtariiva:string;
  public grunombre:string='';
  //CDPJ
  public dblValorotrdsctoBcero:any;
  public dblPorcIva: any;
  public decimalesCantidadFac: number;
  public decimalesPrecioFac: number;
  public decimalesTotalFac: number;
  public decimalesDescFac: number;
  public grids: any[] = [];
  private indicador = 0;
  spin=false;//GSRF

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private confIniciales: ConfInicialesService,
    private datePipe: DatePipe,
    private init: NuevoComponentService
  ) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  refrescarURL() {
    if (this.url === null) {
      this.url = sessionStorage.getItem('url');
    }
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }

  insertarEncabezadoFact(item, txtNumero): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/95f8edf5d763d80', {
      elementos: {
        p_ENCFAC_numero: txtNumero,
        p_ENCFAC_numero_1: item.ENCFAC_NUMERO,
        p_COM_codigo_2: item.COM_CODIGO,
        p_ENCFAC_fechaemision_3: item.ENCFAC_FECHAEMISION,
        p_ENCFAC_fechaentrega_4: item.ENCFAC_FECHAENTREGA,
        p_ENCFAC_estado_5: item.ENCFAC_ESTADO,
        p_CLI_codigo_6: item.CLI_CODIGO,
        p_CLI_nombre_7: item.CLI_NOMBRE,
        p_BOD_codigo_8: item.BOD_CODIGO,
        p_VEN_codigo_9: item.VEN_CODIGO,
        p_ENCFFA_codigo: item.ENCFFA_CODIGO,
        p_ENCFAC_formapago_10: item.ENCFAC_FORMAPAGO,
        p_ENCFAC_referencia: item.ENCFAC_REFERENCIA,
        p_ENCFAC_tipo_11: item.ENCFAC_TIPO,
        p_ENCFAC_lista_12: item.ENCFAC_LISTA,
        p_ENCFAC_fechavencimiento_13: item.ENCFAC_FECHAVENCIMIENTO,
        p_ENCFAC_total_14: item.ENCFAC_TOTAL,
        p_ENCFAC_totalneto: item.ENCFAC_TOTALNETO,
        p_ENCFAC_porceiva_15: item.ENCFAC_PORCEIVA,
        p_ENCFAC_valoriva_16: item.ENCFAC_VALORIVA,
        p_ENCFAC_porcecar_17: item.ENCFAC_PORCECAR,
        p_ENCFAC_valorcar_18: item.ENCFAC_VALORCAR,
        p_ENCFAC_porcertc_19: item.ENCFAC_PORCERTC,
        p_ENCFAC_valorrtc_20: item.ENCFAC_VALORRTC,
        p_ENCFAC_porcedes_21: item.ENCFAC_PORCEDES,
        p_ENCFAC_valordes_22: item.ENCFAC_VALORDES,
        p_ENCFAC_valordev_23: item.ENCFAC_VALORDEV,
        p_ENCFAC_porceint_26: item.ENCFAC_PORCEINT,
        p_ENCFAC_valorint_27: item.ENCFAC_VALORINT,
        p_ENCFAC_porcerec_28: item.ENCFAC_PORCEREC,
        p_ENCFAC_valorrec_29: item.ENCFAC_VALORREC,
        p_ENCFAC_VALORICE: item.ENCFAC_VALORICE,
        p_ENCFAC_PORCEICE: item.ENCFAC_PORCEICE,
        p_ENCFAC_impreso_30: item.ENCFAC_IMPRESO,
        p_ENCFAC_comentario_31: item.ENCFAC_COMENTARIO,
        p_ENCFAC_tipofac_32: item.ENCFAC_TIPOFAC,
        p_ENCFAC_generafac_33: item.ENCFAC_GENERAFAC,
        p_ENCFAC_numerodob_34: item.ENCFAC_NUMERODOB,
        p_ENCFAC_direccion_35: item.ENCFAC_DIRECCION,
        p_ENCFAC_telefono_36: item.ENCFAC_TELEFONO,
        p_ENCFAC_rucide_37: item.ENCFAC_RUCIDE,
        p_ENCFAC_chofer_38: item.ENCFAC_CHOFER,
        p_ENCFAC_carro_39: item.ENCFAC_CARRO,
        p_ENCFAC_placa_40: item.ENCFAC_PLACA,
        p_ENCFAC_iva_41: item.ENCFAC_IVA,
        p_ENCFAC_baseiva: item.ENCFAC_BASEIVA,
        p_ENCFAC_baseice: item.ENCFAC_BASEICE,
        p_ENCFAC_basecero: item.ENCFAC_BASECERO,
        p_ENCFAC_otros: item.ENCFAC_OTROS,
        p_ENCFAC_grupo_42: item.ENCFAC_GRUPO,
        p_ENCFAC_nemonico: item.ENCFAC_NEMONICO,
        p_ENCFAC_serie: item.ENCFAC_SERIE,
        p_ENCFAC_secactual: item.ENCFAC_SECACTUAL,
        p_encfac_diascrdt: item.ENCFAC_DIASCRDT,
        p_encfac_clidsct: item.ENCFAC_CLIDSCT,
        p_usu_identificacion: this.usuario.identificacion,
        p_ENCFAC_TIPODSCTO: item.ENCFAC_TIPODSCTO,
        p_ENCFAC_REFCLI: item.ENCFAC_REFCLI,
        p_ENCFAC_DIRECCION2: item.ENCFAC_DIRECCION2,
        p_ENCFAC_TELEFONO2: item.ENCFAC_TELEFONO2,
        p_ENCFAC_FAX: item.ENCFAC_FAX,
        p_ENCFAC_CORREO: item.ENCFAC_CORREO,
        p_ENCFAC_NOTADEVENTA: item.ENCFAC_NOTADEVENTA,
        p_ENCFAC_ORIGEN: item.ENCFAC_ORIGEN,
        p_ENCFAC_AUTORIZACION: item.ENCFAC_AUTORIZACION,
        p_encfac_numpuntos: item.ENCFAC_NUMPUNTOS,
        p_ENCFAC_TIPOFACTURA: item.ENCFAC_TIPOFACTURA,
        p_ENCFAC_BASENOOBJIVA: item.ENCFAC_BASENOOBJIVA,
        p_ENCFAC_VALORIRBPNR: item.ENCFAC_VALORIRBPNR,//GSRF
        P_ENCFAC_OTRDSCTOS:item.ENCFAC_OTRDSCTOS,//CDPJ
        P_ENCFAC_OTRDSCTOSBASEDIF:item.ENCFAC_OTRDSCTOSBASEDIF,
        P_ENCFAC_OTRDSCTOSBASECERO:item.ENCFAC_OTRDSCTOSBASECERO,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  insertarEncabezadoFactServ(item, txtNumero): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/95fa8edfadad763d80', {
      elementos: {
        p_ENCFAC_numero: txtNumero,
        p_ENCFAC_numero_1: item.ENCFAC_NUMERO,
        p_COM_codigo_2: item.COM_CODIGO,
        p_ENCFAC_fechaemision_3: item.ENCFAC_FECHAEMISION,
        p_ENCFAC_fechaentrega_4: item.ENCFAC_FECHAENTREGA,
        p_ENCFAC_estado_5: item.ENCFAC_ESTADO,
        p_CLI_codigo_6: item.CLI_CODIGO,
        p_CLI_nombre_7: item.CLI_NOMBRE,
        p_BOD_codigo_8: item.BOD_CODIGO,
        p_VEN_codigo_9: item.VEN_CODIGO,
        p_ENCFFA_codigo: item.ENCFFA_CODIGO,
        p_ENCFAC_formapago_10: item.ENCFAC_FORMAPAGO,
        p_ENCFAC_referencia: item.ENCFAC_REFERENCIA,
        p_ENCFAC_tipo_11: item.ENCFAC_TIPO,
        p_ENCFAC_lista_12: item.ENCFAC_LISTA,
        p_ENCFAC_fechavencimiento_13: item.ENCFAC_FECHAVENCIMIENTO,
        p_ENCFAC_total_14: item.ENCFAC_TOTAL,
        p_ENCFAC_totalneto: item.ENCFAC_TOTALNETO,
        p_ENCFAC_porceiva_15: item.ENCFAC_PORCEIVA,
        p_ENCFAC_valoriva_16: item.ENCFAC_VALORIVA,
        p_ENCFAC_porcecar_17: item.ENCFAC_PORCECAR,
        p_ENCFAC_valorcar_18: item.ENCFAC_VALORCAR,
        p_ENCFAC_porcertc_19: item.ENCFAC_PORCERTC,
        p_ENCFAC_valorrtc_20: item.ENCFAC_VALORRTC,
        p_ENCFAC_porcedes_21: item.ENCFAC_PORCEDES,
        p_ENCFAC_valordes_22: item.ENCFAC_VALORDES,
        p_ENCFAC_valordev_23: item.ENCFAC_VALORDEV,
        p_ENCFAC_porceint_26: item.ENCFAC_PORCEINT,
        p_ENCFAC_valorint_27: item.ENCFAC_VALORINT,
        p_ENCFAC_porcerec_28: item.ENCFAC_PORCEREC,
        p_ENCFAC_valorrec_29: item.ENCFAC_VALORREC,
        p_ENCFAC_VALORICE: item.ENCFAC_VALORICE,
        p_ENCFAC_PORCEICE: item.ENCFAC_PORCEICE,
        p_ENCFAC_impreso_30: item.ENCFAC_IMPRESO,
        p_ENCFAC_comentario_31: item.ENCFAC_COMENTARIO,
        p_ENCFAC_tipofac_32: item.ENCFAC_TIPOFAC,
        p_ENCFAC_generafac_33: item.ENCFAC_GENERAFAC,
        p_ENCFAC_numerodob_34: item.ENCFAC_NUMERODOB,
        p_ENCFAC_direccion_35: item.ENCFAC_DIRECCION,
        p_ENCFAC_telefono_36: item.ENCFAC_TELEFONO,
        p_ENCFAC_rucide_37: item.ENCFAC_RUCIDE,
        p_ENCFAC_chofer_38: item.ENCFAC_CHOFER,
        p_ENCFAC_carro_39: item.ENCFAC_CARRO,
        p_ENCFAC_placa_40: item.ENCFAC_PLACA,
        p_ENCFAC_iva_41: item.ENCFAC_IVA,
        p_ENCFAC_baseiva: item.ENCFAC_BASEIVA,
        p_ENCFAC_baseice: item.ENCFAC_BASEICE,
        p_ENCFAC_basecero: item.ENCFAC_BASECERO,
        p_ENCFAC_otros: item.ENCFAC_OTROS,
        p_ENCFAC_grupo_42: item.ENCFAC_GRUPO,
        p_ENCFAC_nemonico: item.ENCFAC_NEMONICO,
        p_ENCFAC_serie: item.ENCFAC_SERIE,
        p_ENCFAC_secactual: item.ENCFAC_SECACTUAL,
        p_encfac_diascrdt: item.ENCFAC_DIASCRDT,
        p_encfac_clidsct: item.ENCFAC_CLIDSCT,
        p_usu_identificacion: this.usuario.identificacion,
        p_ENCFAC_TIPODSCTO: item.ENCFAC_TIPODSCTO,
        p_ENCFAC_REFCLI: item.ENCFAC_REFCLI,
        p_ENCFAC_DIRECCION2: item.ENCFAC_DIRECCION2,
        p_ENCFAC_TELEFONO2: item.ENCFAC_TELEFONO2,
        p_ENCFAC_FAX: item.ENCFAC_FAX,
        p_ENCFAC_CORREO: item.ENCFAC_CORREO,
        p_ENCFAC_NOTADEVENTA: item.ENCFAC_NOTADEVENTA,
        p_ENCFAC_ORIGEN: item.ENCFAC_ORIGEN,
        p_ENCFAC_AUTORIZACION: item.ENCFAC_AUTORIZACION,
        p_encfac_numpuntos: item.ENCFAC_NUMPUNTOS,
        p_ENCFAC_TIPOFACTURA: item.ENCFAC_TIPOFACTURA,
        p_ENCFAC_BASENOOBJIVA: item.ENCFAC_BASENOOBJIVA,
        p_ENCFAC_SERVICIO: item.ENCFAC_SERVICIO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarFactura(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/7565693d4cefb80', {
      elementos: {
        p_ENCFAC_numero_1: strNumFact,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  insertarDetalleFactura(item): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/95f8edf5aeebd80', {
      elementos: {
        // p_DETFAC_linea: item.DETFAC_LINEA,
        p_DETFAC_linea: '0',
        p_ENCFAC_numero_1: item.ENCFAC_NUMERO,
        p_COM_codigo_2: item.COM_CODIGO,
        p_DETFAC_linea_3: item.DETFAC_LINEA_3,
        p_DETFAC_tipodet_4: item.DETFAC_TIPODET,
        p_BOD_codigo_5: item.BOD_CODIGO,
        p_DETFAC_codigo_6: item.DETFAC_CODIGO,
        p_DETFAC_descripcion_7: item.DETFAC_DESCRIPCION,
        p_DETFAC_tribiva_8: item.DETFAC_TRIBIVA,
        p_DETFAC_tribice_9: item.DETFAC_TRIBICE,
        p_DETFAC_simbolo_10: item.DETFAC_UNIDAD,
        p_DETFAC_cantidad_11: item.DETFAC_CANTIDAD,
        p_DETFAC_precio_12: item.DETFAC_PRECIO,
        p_DETFAC_descuento_13: item.DETFAC_DESCUENTO,
        p_DETFAC_total_14: item.DETFAC_TOTAL,
        p_DETFAC_iva_15: item.DETFAC_IVA,
        p_DETFAC_ice_16: item.DETFAC_ICE,
        p_DETFAC_lista_17: item.DETFAC_LISTA,
        p_DETFAC_baseiva_18: item.DETFAC_BASEIVA,
        p_DETFAC_baseice_19: item.DETFAC_BASEICE,
        p_DETFAC_basecero_20: item.DETFAC_BASECERO,
        p_DETFAC_porceice_21: item.DETFAC_PORCEICE,
        p_ENCCOT_numero: item.ENCCOT_NUMERO,
        p_DETCOT_linea: item.DETCOT_LINEA,
        p_ENCPED_numero: item.ENCPED_NUMERO,
        p_DETPED_linea: item.DETPED_LINEA,
        p_ENCGRE_numero: item.ENCGRE_NUMERO,
        p_DETGRE_linea: item.DETGRE_LINEA,
        p_UNI_CODIGO: item.DETFAC_UNIDAD,
        p_DETFAC_tipo_22: item.DETFAC_TIPO,
        p_DETFAC_codigoref_23: item.DETFAC_CODIGOREF,
        p_ENCFAC_numero: item.ENCFAC_NUMERO,
        p_detfac_promocion: item.DETFAC_PROMOCION,
        p_detfac_orden: item.DETFAC_ORDEN,
        p_detfac_linorgprom: item.DETFAC_LINORGPROM,
        p_maeprm_codigo: item.MAEPRM_CODIGO,
        p_DETFAC_FECHAREALIZACION: '',
        p_detfac_precioc: item.DETFAC_PRECIOC,
        p_cen_codigo: item.CEN_CODIGO,
        p_detfac_bulto: item.DETFAC_BULTO,
        p_detfac_cantidadb: item.DETFAC_CANTIDADB,
        p_detfac_tiposri: item.DETFAC_TIPOSRI,
        p_detfac_signo: item.DETFAC_SIGNO,
        p_detfac_artacambio: item.DETFAC_ARTACAMBIO,
        p_ordtra_numero: item.ORDTRA_NUMERO,
        p_detordtra_linea: item.DETORDTRA_LINEA,
        p_encreq_numero: item.ENCREQ_NUMERO,
        p_detreq_linea: item.DETREQ_LINEA,
        p_detfac_basenoobjiva: item.DETFAC_BASENOOBJIVA,
        p_detfac_tribasenoobjiva: item.DETFAC_TRIBASENOOBJIVA,
        p_encmat_numero: item.ENCMAT_NUMERO,
        p_detmat_codigo: item.DETMAT_CODIGO,
        p_detfac_ordenlote: item.DETFAC_ORDENLOTE,
        p_DETFAC_cantidadund: item.DETFAC_CANTIDADUND,
        p_DETFAC_IRBPNR:  item.DETFAC_IRBPNR,//GSRF
        p_DETFAC_VALORIRBPNR: item.DETFAC_VALORIRBPNR,//GSRF
        p_DETFAC_INCLUENDSCTO: item.DETFAC_INCLUENDSCTO,//CDPJ
        p_DETFAC_PORIVA:item.DETFAC_PORIVA,//CDPJ
        p_TARIIVA_CODIGO:item.TARIIVA_CODIGO,//CDPJ

      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarDetalleFact(detfac): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/3ab2b49aeebd4c', {
      elementos: {
        p_ENCFAC_numero_1: detfac.ENCFAC_NUMERO,
        p_DETFAC_linea_2: detfac.DETFAC_LINEA,
        p_COM_codigo_3: '01'
      }
    }, this.confIniciales.httpOptions());
  }


  seleccionarRegistro(tipo, cadena, condicion): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/1c755ccebd4cf0000', {
      elementos: {
        p_tipo: tipo,
        p_Cadena: cadena,
        p_condicion: condicion
      }
    }, this.confIniciales.httpOptions());
  }


  getEncfac(encfacnumero): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/662fcf55d763d40', {
      elementos: {
        p_Tipo: 'R',
        p_Cadena: '',
        p_condicion: 'ENCFAC_NUMERO=\'' + encfacnumero + '\''
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getDetfac(strNumFact, strFecha, strHora): Promise<any[]> {
    return this.http.post<any>(this.way + '/ventas/facturas/662fcf55aeebd40', {
      elementos: {
        p_encfac_numero: strNumFact,
        p_fecha: 'TO_DATE(\'' + strFecha + '\',\'DD/MM/YYYY HH24:MI:SS\')',
        p_hora: strHora,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  existePagosCxC(encfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/1f056ebe2ab270000', {
      elementos: {
        TRNDOCUMENTO_NRODOC: encfacnumero,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  //CDPJ
existePagosCxCProm(encfacnumero) {
  return this.http.post<any[]>(this.way + '/ventas/facturas/662fcf57b9543000000000000', {
    elementos: {
      TRNDOCUMENTO_NRODOC: encfacnumero,
      COM_CODIGO: '01'
    }
  }, this.confIniciales.httpOptions()).toPromise();
}
obtenerNumFE(encgrupo) {

  return this.http.post<any[]>(this.way + '/ventas/guiaremision/c2faebbb77f5a00', {
    elementos: {
      num_fe: encgrupo,
      
    }
  }, this.confIniciales.httpOptions()).toPromise();

}
countSacimaeobj(strDialogo, strObjeto, strUsuid, intPestaniaCols): Observable<any[]> {
  return this.http.post<any[]>(this.way + '/compras/factproveedores/198f5fb653a1e90000', {
    elementos: {
      Dialogo: strDialogo,
      Objeto: strObjeto,
      Usuidentificacion: strUsuid,
      intPestaniaCol: intPestaniaCols
    },
  }, this.confIniciales.httpOptions());
}
//CDPJ
  ingDatosAdicCli(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/9r95a5wzo6ncq24', {
      elementos: {
        p_encfac_numero: strNumFact,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }


  obtenerNumeracionG(strTipoNum): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/zu5lbda3don9r60', {
      elementos: {
        NUM_DOCREF: strTipoNum,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }


  /**+++++++++++++++++++++++++++++++++++++++++++++++++++++
   * +++++++++++++++++++++++++++++++++++++++++++++++++++++
   *     ENCONTRAR REGISTRO
   *  +++++++++++++++++++++++++++++++++++++++++++++++++++++
   * +++++++++++++++++++++++++++++++++++++++++++++++++++++
   */

  erCrearNuevoItemServ(strCodBodega) {

    return this.http.post(this.way + '/ventas/facturas/w0qsbp43koievfy', {

      elementos: {
        CodBodega: strCodBodega
      },

    }, this.confIniciales.httpOptions());

  }

  erEncontrarFactura(encfacNumero) {
    return this.http.post(this.way + '/ventas/facturas/0pgxu6uoz6rsl5f', {

      elementos: {
        ENCFAC_NUMERO: encfacNumero
      },

    }, this.confIniciales.httpOptions());
  }

  erVerificarTipoCliente(cliCodigo) {
    return this.http.post(this.way + '/ventas/facturas/9c1jsco9ijtvs6j', {

      elementos: {
        CLI_CODIGO: cliCodigo
      },

    }, this.confIniciales.httpOptions());

  }


  erBusquedaVendedor(strCampo, strTabla, strValue, strParam) {

    return this.http.post(this.way + '/ventas/facturas/3rw6uc890a4nleo', {

      elementos: {
        CAMPO: strCampo,
        VALOR: strTabla,
        VALUE: strValue,
        PARAM: strParam
      },

    }, this.confIniciales.httpOptions());

  }

  erGenerarAsiento(encFacNumero) {
    return this.http.post(this.way + '/ventas/facturas/l0bt8h7bixv9kxw', {

      elementos: {
        ENCFAC_NUMERO: encFacNumero,
      },

    }, this.confIniciales.httpOptions());
  }

  erObtenerBodegaDefecto() {
    return this.http.post(this.way + '/ventas/facturas/tt34ml7cv56f1o6', {

      elementos: {},

    }, this.confIniciales.httpOptions());
  }

  erObtenerNumFact(encFacGrupo) {
    return this.http.post(this.way + '/ventas/facturas/m0k8w36hji57d18', {

      elementos: {
        ENCFAC_GRUPO: encFacGrupo
      },

    }, this.confIniciales.httpOptions());
  }

  erGetNumeracion(numClase) {

    return this.http.post(this.way + '/ventas/facturas/ap25un6kbs1hj7n', {

      elementos: {
        NUM_CLASE: numClase
      },

    }, this.confIniciales.httpOptions());

  }

  ercrearNuevoItem(strCodBodega) {

    return this.http.post(this.way + '/ventas/facturas/yuzgqlc31uh0gem', {

      elementos: {
        CodBodega: strCodBodega
      },

    }, this.confIniciales.httpOptions());

  }

  erGetClienteCodigo(cliCodigo) {

    return this.http.post(this.way + '/ventas/facturas/772ufkd85qb57gd', {

      elementos: {
        CLI_CODIGO: cliCodigo
      },

    }, this.confIniciales.httpOptions());

  }

  erCredito(cliCodigo) {
    return this.http.post(this.way + '/ventas/facturas/qac0h24ywzyg8id', {

      elementos: {
        CLI_CODIGO: cliCodigo
      },

    }, this.confIniciales.httpOptions());
  }


  erCerrarCuotas(encFacNumero) {
    return this.http.post(this.way + '/ventas/facturas/qgh0zcc0i1d4vbd', {

      elementos: {
        ENCFAC_NUMERO: encFacNumero
      },

    }, this.confIniciales.httpOptions());
  }


  erGetSeleccion(encFacNumero) {
    return this.http.post(this.way + '/ventas/facturas/qkughjycpv2i08t', {

      elementos: {
        ENCFAC_NUMERO: encFacNumero
      },

    }, this.confIniciales.httpOptions());
  }

  erInsertServicioSeguro(strCodigo) {

    return this.http.post(this.way + '/ventas/facturas/yfq66159eh79xvc', {

      elementos: {
        Codigo: strCodigo
      },

    }, this.confIniciales.httpOptions());

  }

  erExtraerPedidos(strNumFact) {
    return this.http.post(this.way + '/ventas/facturas/tfxjfp5qu4q3nai', {

      elementos: {
        NumFact: strNumFact
      },

    }, this.confIniciales.httpOptions());
  }

  erExtraerGuiasRem(strNumFact) {
    return this.http.post(this.way + '/ventas/facturas/1mqwamou207d4da', {

      elementos: {
        NumFact: strNumFact
      },

    }, this.confIniciales.httpOptions());
  }


  erTraerCodConsumidorFinal() {
    return this.http.post(this.way + '/ventas/facturas/fnvboyqyauzwgmj', {

      elementos: {},

    }, this.confIniciales.httpOptions());
  }


  erTrCodigoConsFinal(strCodigoConFinal) {

    return this.http.post(this.way + '/ventas/facturas/f3jsq0d1eesc8fm', {

      elementos: {
        CodigoConFinal: strCodigoConFinal
      },

    }, this.confIniciales.httpOptions());

  }

  erClienteSeleccionado(cliCodigo) {
    return this.http.post(this.way + '/ventas/facturas/5xrafuhbu0x0zbs', {

      elementos: {
        Codigo: cliCodigo
      },

    }, this.confIniciales.httpOptions());
  }

  erDatosCliCorreo(strCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/nn4wd2okz06i9ny', {

      elementos: {
        Codigo: strCodigo
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  // obtenerConfiguracionProm() {
  //   return this.http.post<any[]>(this.way + '/seg/usuario/41dd662ef943dc0000000000', {
  //     elementos: {}
  //   }, this.confIniciales.httpOptions()).toPromise();
  // }
  erDatosProCorreo(strCodigo) {
    return this.http.post(this.way + '/ventas/facturas/5kq2he94sr98eb4', {

      elementos: {
        Codigo: strCodigo
      },

    }, this.confIniciales.httpOptions());
  }

  erActualizarDatos() {
    return this.http.post(this.way + '/ventas/facturas/8999v9nyqulr7ze', {

      elementos: {},

    }, this.confIniciales.httpOptions());
  }

  /**+++++++++++++++++++++++++++++++++++++++++++++++++++++
   * +++++++++++++++++++++++++++++++++++++++++++++++++++++
   *     ENCONTRAR REGISTRO PROMISE
   *  +++++++++++++++++++++++++++++++++++++++++++++++++++++
   * +++++++++++++++++++++++++++++++++++++++++++++++++++++
   */





  erpGetExistenciaArticulo(strArtCodigo) {

    return this.http.post(this.way + '/ventas/facturas/gjhtglq6ig0dj4k', {

      elementos: {
        ArtCodigo: strArtCodigo
      },

    }, this.confIniciales.httpOptions()).toPromise();

  }


  erpGetExistenciaPartII(detFacCodigo) {

    return this.http.post(this.way + '/ventas/facturas/3vzlcuneem99ttd', {

      elementos: {
        DETFAC_CODIGO: detFacCodigo
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpBusquedaCliente(strValor, strValue, strParams) {
    return this.http.post(this.way + '/ventas/facturas/tq8cgrtwkhkurwk', {

      elementos: {
        Valor: strValor,
        Value: strValue,
        Params: strParams
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }

  erpDatosVendedor(codigo) {
    return this.http.post(this.way + '/ventas/facturas/qkgbppgxqu1mmic', {

      elementos: {
        VEN_CODIGO: codigo
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }


  erpCosteoarticulo(codigo) {

    return this.http.post(this.way + '/ventas/facturas/4vr8pys6ubqjs8a', {

      elementos: {
        DETFAC_CODIGO: codigo
      },

    }, this.confIniciales.httpOptions()).toPromise();

  }


  /**+++++++++++++++++++++++++++++++++++++++++++++++++++++
    * +++++++++++++++++++++++++++++++++++++++++++++++++++++
    *     EJECUTAR CONSULTA
    *  +++++++++++++++++++++++++++++++++++++++++++++++++++++
    * +++++++++++++++++++++++++++++++++++++++++++++++++++++
    */






  ecCantArticuloCosteo(detFacUnidad, strUnidadCosteo, detFacCantidad) {

    return this.http.post<any[]>(this.way + '/ventas/facturas/x1xkq4930n0v19s', {

      elementos: {
        DETFAC_UNIDAD: detFacUnidad,
        UnidadCosteo: strUnidadCosteo,
        DETFAC_CANTIDAD: detFacCantidad
      },

    }, this.confIniciales.httpOptions());

  }

  ecCantArticuloCosteoProm(detFacUnidad, strUnidadCosteo, detFacCantidad) {

    return this.http.post<any[]>(this.way + '/ventas/facturas/x1xkq4930n0v19s', {

      elementos: {
        DETFAC_UNIDAD: detFacUnidad,
        UnidadCosteo: strUnidadCosteo,
        DETFAC_CANTIDAD: detFacCantidad
      },

    }, this.confIniciales.httpOptions()).toPromise();

  }


  ecAnularFactDistri(encfacNUmero) {
    return this.http.post(this.way + '/ventas/facturas/r8j275uz5sr4r5n', {

      elementos: {
        ENCFAC_NUMERO: encfacNUmero
      },

    }, this.confIniciales.httpOptions());
  }

  ecVenInfoAdiFact(encfacNUmero) {
    return this.http.post(this.way + '/ventas/facturas/tnt134zezlzjo7v', {

      elementos: {
        ENCFAC_NUMERO: encfacNUmero
      },

    }, this.confIniciales.httpOptions());
  }


  ecVerificarUsuarioSupervisor(usuarioSup) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/ngngol02dsfkgdr', {

      elementos: {
        UsuarioSup: usuarioSup
      },

    }, this.confIniciales.httpOptions());
  }


  ecVerificarClaveSupervisor(strDialogo, strObjeto, identificacion, usuarioSup, intPestaniaCol) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/t5fb8lfmr1h6cmk', {

      elementos: {
        Dialogo: strDialogo,
        Objeto: strObjeto,
        Identificacion: identificacion,
        UsuarioSup: usuarioSup,
        IntPestaniaCol: intPestaniaCol,
      },

    }, this.confIniciales.httpOptions());
  }



  ecChangelistaprecios(detFacCodigo, detFacLista, detFacUnidad) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/j767gc1ptq86quh', {

      elementos: {
        DETFAC_CODIGO: detFacCodigo,
        DETFAC_LISTA: detFacLista,
        DETFAC_UNIDAD: detFacUnidad,
      },

    }, this.confIniciales.httpOptions());
  }


  ecExtraerPedidos(numFact) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/zesd1b5h4qqrrrh', {

      elementos: {
        NumFact: numFact,
      },

    }, this.confIniciales.httpOptions());
  }

  ecExtraerNumeroPedidos(numFact) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/klx6n4bx7362usk', {

      elementos: {
        NumFact: numFact,
      },

    }, this.confIniciales.httpOptions());
  }


  ecActualizarFechaEntregaPed(pedido) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/ryocfwg5thyb4yu', {

      elementos: {
        Pedido: pedido,
      },

    }, this.confIniciales.httpOptions());
  }


  ecExtraerGuiasRem(numFact) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/jf6tl8ten6ebc2n', {

      elementos: {
        NumFact: numFact,
      },

    }, this.confIniciales.httpOptions());
  }


  ecExtraerNumeroGuias(numFact) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/it4nq7fpr9tlqan', {

      elementos: {
        NumFact: numFact,
      },

    }, this.confIniciales.httpOptions());
  }

  ecActualizarCorreoCli(txtCorreo1, txtCorreo2, strCodigo) {
    return this.http.post(this.way + '/ventas/facturas/ibrkd41bgjy52jf', {

      elementos: {
        TxtCorreo1: txtCorreo1,
        TxtCorreo2: txtCorreo2,
        Codigo: strCodigo,
      },

    }, this.confIniciales.httpOptions());
  }

  ecActualizarCorreoPro(txtCorreo1, txtCorreo2, strCodigo) {
    return this.http.post(this.way + '/ventas/facturas/c21lfspd350ydab', {

      elementos: {
        TxtCorreo1: txtCorreo1,
        TxtCorreo2: txtCorreo2,
        Codigo: strCodigo,
      },

    }, this.confIniciales.httpOptions());
  }


  //////////////////////////////////


  ecpValidarSeriales(articulo, strNumDoc, intLinea) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/jp5ecihy8ogfx95', {

      elementos: {
        Articulo: articulo,
        NumDoc: strNumDoc,
        IntLinea: intLinea,
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }

  ecpGetExistenciaArticulo(strArtCodigo, strBodCodigo, strFecha, strHora) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/n3vll5ychza6s15', {

      elementos: {
        ArtCodigo: strArtCodigo,
        BodCodigo: strBodCodigo,
        Fecha: strFecha,
        Hora: strHora
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }


  ecpGetCantArticulo(strArtCodigo, strBodCodigo, strReferencia) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/gea5cej9l1dpq9c', {

      elementos: {
        ArtCodigo: strArtCodigo,
        BodCodigo: strBodCodigo,
        Referencia: strReferencia,
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }


  ecpGetExistenciasParte2(encPedNumero, detpedlinea) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/l78vm7bnddmbjn1', {

      elementos: {
        ENCPED_NUMERO: encPedNumero,
        Detpedlinea: detpedlinea,
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }



  ecpGetExist2(detFacUnidad, strUnidadCosteo, detFacCantidad) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/um6mgs8y1jphxfx', {

      elementos: {
        DETFAC_UNIDAD: detFacUnidad,
        DETFAC_CANTIDAD: detFacCantidad,
        UnidadCosteo: strUnidadCosteo
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }


  ecpGetCantArtCosteo(detFacCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/inm32qrfoknvvk3', {

      elementos: {
        DETFAC_CODIGO: detFacCodigo
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }


  ecpGetTransformaUnidad(strUniCosteo, detFacUnidad, dblCantTransformaunidad) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/t8tz7vxarvnyc4l', {

      elementos: {
        UniCosteo: strUniCosteo,
        DETFAC_UNIDAD: detFacUnidad,
        DblCantTransUn: dblCantTransformaunidad
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }

  /**+**************************************************
   * ************************************************
   * ***************************************************
   */

  getBodegaUsuario(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/rln905a8iwh252b', {
      sql: 'SELECT BOD_CODIGO,BOD_CODIGO_DEV FROM SEG_MAEUSUARIO WHERE USUIDENTIFICACION = :USUIDENTIFICACION' +
        ' AND COMCODIGO=:COMCODIGO',
      elementos: {
        USUIDENTIFICACION: this.usuario.identificacion,
        COMCODIGO: '01'
      },
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerListasPrecios(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/yxn1xb19h7umuqc', {
      elementos: {
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerBodegasInternas() {
    return this.http.post<any[]>(this.way + '/ventas/facturas/vcqb2fcwrmrcbny', {
      sql: 'SELECT BOD_CODIGO,BOD_NOMBRE FROM INV_MAEBODEGA WHERE COM_CODIGO = :COM_CODIGO AND BOD_TIPO IS NULL ORDER BY BOD_NOMBRE',
      elementos: {
        COM_CODIGO: '01'
      },
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerSaldoCliente(strCodliente): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/guof6mvp1m2bay1', {
      elementos: {
        CODCLIENTE: strCodliente,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerSaldoClienteFac(strCodliente, strNumFac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/deniiusvrcaf0f6', {
      elementos: {
        CODCLIENTE: strCodliente,
        COM_CODIGO: '01',
        NUMFAC: strNumFac
      }
    }, this.confIniciales.httpOptions());
  }

  consultarArticuloSel(strCodArticulo): Observable<any[]> {
    const FPLOTSIM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FPLOTSIM').CFG_VALOR1;
    const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(new Date(), 'HH:mm');

    return this.http.post<any[]>(this.way + '/ventas/facturas/w4fejsnym7i0pmt', {
      elementos: {
        LISTA: 'A',
        BOD_CODIGO: '001',
        strFecha: strFecha + '',
        strHora: strHora + '',
        ART_CODIGO: strCodArticulo,
        COM_CODIGO: '01'
      },
      datos: {
        FPLOTSIM: FPLOTSIM,
      }
    }, this.confIniciales.httpOptions());
  }

  consultarArticuloSelProm(strCodArticulo) {
    const FPLOTSIM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FPLOTSIM').CFG_VALOR1;
    const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(new Date(), 'HH:mm');

    return this.http.post<any[]>(this.way + '/ventas/facturas/w4fejsnym7i0pmt', {
      elementos: {
        LISTA: 'A',
        BOD_CODIGO: '001',
        strFecha: strFecha + '',
        strHora: strHora + '',
        ART_CODIGO: strCodArticulo,
        COM_CODIGO: '01'
      },
      datos: {
        FPLOTSIM: FPLOTSIM,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  existePagoDirecto(numfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/icwisntz58696rf', {
      elementos: {},
      datos: {
        NumFac: numfac
      }
    }, this.confIniciales.httpOptions());
  }

  consultarInfoAdicional(numfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/1nxmeb5alo0onj6', {
      elementos: {},
      datos: {
        NumFac: numfac
      }
    }, this.confIniciales.httpOptions());
  }


  generarAsientoContable(numfact, tipdia, asicomprobante): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/h96wodnruz2lymi', {
      elementos: {
        p_ENCFAC_numero: numfact,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: tipdia,
        p_asi_comprobante: asicomprobante
      }
    }, this.confIniciales.httpOptions());
  }

  actualizaFormaPagoFact(strNumFact, strTipoFP): Observable<any> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/hai63dq4kotgp5', {
      elementos: {},
      datos: {
        NumFact: strNumFact,
        TipoFP: strTipoFP
      }
    }, this.confIniciales.httpOptions());
  }

  generarAsientoContableSinCxc(numfact, tipdia, asicomprobante): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/caw03q7bp0zdgvd', {
      elementos: {
        p_ENCFAC_numero: numfact,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: tipdia,
        p_asi_comprobante: asicomprobante
      }
    }, this.confIniciales.httpOptions());
  }

  guardarInfoAdicFac(infoadicfac): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/j5bmzb65bi9a8gu', {
      elementos: {
        p_encfac_numero: infoadicfac.ENCFAC_NUMERO,
        p_com_codigo: '01',
        p_infad_numsec: infoadicfac.INFAD_NUMSEC,
        p_infad_codigo: infoadicfac.INFAD_CODIGO,
        p_infad_descripcion: infoadicfac.INFAD_DESCRIPCION
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarInfoAdicFac(infoadicfac): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/srcu3g9hr84wjhy', {
      elementos: {
        p_encfac_numero: infoadicfac.ENCFAC_NUMERO,
        p_com_codigo: '01',
        p_infad_numsec: infoadicfac.INFAD_NUMSEC,
      }
    }, this.confIniciales.httpOptions());
  }

  consultarPedido(strCodPedido): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/facturas/7hz01xdjlliki5t', {
      elementos: {},
      datos: {
        CodPedido: strCodPedido
      }
    }, this.confIniciales.httpOptions());
  }

  consultarGuia(intNumGuia, cmpFact): Observable<any[]> {


    return this.http.post<any[]>(this.way + '/ventas/facturas/bvbxjrqz8e4ljc6', {
      elementos: {},
      datos: {
        IntNumGuia: intNumGuia,
        ENCFAC_NUMERO: cmpFact.ENCFAC_NUMERO,
        CLI_CODIGO: cmpFact.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  verificarActualizarEstPed(encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/4yruu4q43shk64r', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: 'VEN_PCK_ENCPED.VEN_sp_VerificarPedido',
      elementos: {
        p_ENCPED_numero: encpednumero,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  ActualizaInvtrnlote(encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/a67657ff777ae000', {
      elementos: {
        p_fac_numero: encpednumero,
      }
    }, this.confIniciales.httpOptions());
  }

  ActualizaFechaEntrega(encpednumero, fechaentrega): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/f6gfrb9f0ansja1', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: 'VEN_PCK_ENCPED.VEN_sp_ActualizaFechaEntrega',
      elementos: {
        p_ENCPED_numero: encpednumero,
        p_ENCPED_fechaentrega: fechaentrega,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  actualizaDespachoPedido(encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/a3pxeaegfltzjcr', {
      elementos: {
        p_encped_numero: encpednumero,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarDespachoGuiaRem(guia): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/h9qg0rz8fpvzvol', {
      elementos: {
        p_encgre_codigo: guia.ENCGRE_NUMERO,
        p_detgre_linea: guia.DETGRE_LINEA,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  VerificarGuiaRem(encgrenumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/pj3x22t84442xi5', {
      elementos: {
        p_ENCGRE_codigo: encgrenumero,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  consultarComponentes(strArtCodigo, strBodCodigo, strListaPadre) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/01icv7ti6uu8pm8', {
      elementos: {},
      datos: {
        ArtCodigo: strArtCodigo,
        ListaPadre: strListaPadre,
        BodCodigo: strBodCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  crearNuevoItemServ(): VenDetfacZ {
    this.indicador = this.init.tabs[this.init.tabindex].indice;
    const strCodBodega = this.encfacarray[this.init.tabs[this.init.tabindex].indice].BOD_CODIGO;

    const detfac: VenDetfacZ = {};

    const ARTANTBOD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ARTANTBOD').CFG_VALOR1;
    if (ARTANTBOD === 1) {
      detfac.BOD_CODIGO = '';
    } else {
      detfac.BOD_CODIGO = strCodBodega;
    }

    const BODCENCOS = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BODCENCOS').CFG_VALOR1;
    if (BODCENCOS === 1) {
      detfac.CEN_CODIGO = strCodBodega;
    } else {
      detfac.CEN_CODIGO = '';
    }

    const CENCOSBOD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENCOSBOD').CFG_VALOR1;
    if (CENCOSBOD === 1) {
      this.erCrearNuevoItemServ(strCodBodega).subscribe(cen => {
        if (cen[0] !== undefined) {
          detfac.CEN_CODIGO = cen[0].CEN_CODIGO;
        }
      });
    } else {
      detfac.CEN_CODIGO = '';
    }

    detfac.ENCFAC_NUMERO = this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_NUMERO;
    detfac.COM_CODIGO = '01';
    detfac.DETCOT_LINEA = 0;
    detfac.DETFAC_ARTACAMBIO = '';
    detfac.DETFAC_BASECERO = 0;
    detfac.DETFAC_BASEICE = 0;
    detfac.DETFAC_BASEIVA = 0;
    detfac.DETFAC_CANTIDAD = 1;
    detfac.DETFAC_PRECIO = 0;
    detfac.DETFAC_DESCUENTO = 0;
    detfac.DETFAC_TOTAL = 0;
    detfac.DETFAC_CODIGO = '';
    detfac.DETFAC_CODIGOREF = '';
    detfac.DETFAC_CONCODCSTVTA = '';
    detfac.DETFAC_CONCODINV = '';
    detfac.DETFAC_DESCRIPCION = '';
    detfac.DETFAC_LINEA = this.detfacarray[this.init.tabs[this.init.tabindex].indice].length + 1;
    detfac.DETFAC_LISTA = this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_LISTA;
    detfac.DETFAC_LINEA_3 = 0;
    detfac.ENCFAC_IVA = this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_IVA;
    // datos iniciales
    detfac.DETFAC_PORCEICE = 0;
    detfac.ENCCOT_NUMERO = '';
    detfac.ENCPED_NUMERO = '';
    detfac.ENCGRE_NUMERO = '';
    detfac.DETGRE_LINEA = '';
    detfac.DETFAC_PROMOCION = '0';
    detfac.DETFAC_LINORGPROM = '';
    detfac.MAEPRM_CODIGO = '';
    detfac.DETFAC_PRECIOC = 0;
    detfac.DETFAC_TIPOSRI = '';
    detfac.ORDTRA_NUMERO = '';
    detfac.DETORDTRA_LINEA = '';
    detfac.ENCREQ_NUMERO = '';
    detfac.DETREQ_LINEA = '';
    detfac.ENCMAT_NUMERO = '';
    detfac.DETMAT_CODIGO = '';
    detfac.DETFAC_CANTIDADUND = '';
    detfac.DETFAC_BASENOOBJIVA = 0;
    detfac.DETFAC_ICE = 0;
    detfac.DETFAC_IVA = 0;
    detfac.DETFAC_TRIBIVA = '';
    detfac.DETFAC_TRIBICE = '';
    detfac.DETFAC_TRIBASENOOBJIVA = '';
    detfac.UNI_SIMBOLO = '';
    detfac.DETPED_LINEA = '';
    detfac.DETFAC_UNIDAD = '';
    detfac.ART_MULTIUNIDAD = '';

    return detfac;
  }

  async desplegarComponentes(item, index) {
    const data = await this.consultarComponentes(item.DETFAC_CODIGO, item
      .BOD_CODIGO, item.DETFAC_LISTA);
    for (const rs of data) {
      const detfac: VenDetfacZ = {};
      detfac.DETFAC_CODIGO = rs.ART_CODIGOP;
      detfac.DETFAC_UNIDAD = rs.UNI_CODIGO;
      detfac.DETFAC_DESCRIPCION = rs.ART_NOMBRE;
      detfac.DETFAC_CANTIDAD = rs.ARTPARTE_CANTIDAD;
      detfac.DETFAC_TIPODET = 'Y';
      detfac.BOD_CODIGO = item.BOD_CODIGO;
      detfac.DETFAC_PROMOCION = '0';
      detfac.ENCFAC_NUMERO = '';
      detfac.DETFAC_PRECIOC = rs.PRECIO;
      detfac.DETFAC_PRECIO = rs.PRECIO;

      this.detfacarray[this.init.tabs[this.init.tabindex].indice].push(detfac);
    }

  }


  anularFactura(strRazon, txtNumero): Observable<any> {

    return this.http.post<any[]>(this.way + '/ventas/facturas/4cazz72alngdclm', {
      elementos: {},
      datos: {
        Razon: strRazon,
        TxtNumero: txtNumero
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarEstadoDocumento(strTabla, strCampoAct, strEstado, strCondicion): Observable<any> {


    return this.http.post<any[]>(this.way + '/ventas/facturas/4hr5y900qon3yli', {
      elementos: {},
      datos: {
        Valor: strTabla,
        CampoAct: strCampoAct,
        Condicion: strCondicion,
        Estado: strEstado
      }
    }, this.confIniciales.httpOptions());
  }


  insertarEleTrnDocumento(eletrndoc): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/myir1gkxpoaceck', {
      elementos: {
        p_trndoc_numdoc: eletrndoc.TRNDOC_NUMDOC,
        p_trndoc_tipo: eletrndoc.TRNDOC_TIPO,
        p_trndoc_estado: eletrndoc.TRNDOC_ESTADO,
        p_trndoc_resultado: eletrndoc.TRNDOC_RESULTADO,
        p_trndoc_ubicaarch: eletrndoc.TRNDOC_UBICAARCH,
        p_trndoc_secuencial: eletrndoc.TRNDOC_SECUENCIAL,
        p_usuidentificacion: this.usuario.identificacion,
        p_com_codigo: eletrndoc.COM_CODIGO,
        p_trndoc_tipoamb_fe: eletrndoc.TRNDOC_TIPOAMB_FE
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerLogFE(docNum: string, tipoFac: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/a19xqc1r1rntnlw', {
      elementos: {
        TRNDOC_NUMDOC: docNum,
        TRNDOC_TIPO: tipoFac
      }
    }, this.confIniciales.httpOptions());
  }


  actNumeroSiguiente(strClase, strTipoDoc, strNemonico, strSerie): Promise<any> {
    console.log(strClase, strTipoDoc, strNemonico, strSerie);
    return this.http.post<any>(this.way + '/ventas/facturas/pxdbe7mijz35rqx', {
      esquema: this.usuarioEsquema,
      elementoEjecutar: 'SACI_PCK_MAENUMERACIONG.SACI_SP_actnumerosiguiente',
      elementos: {
        p_NUM_clase: strClase,
        p_COM_codigo: '01',
        p_NUM_docref: strTipoDoc,
        p_NUM_nemonico: strNemonico,
        p_NUM_serie: strSerie,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarKardex(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/qa866ewgggzwazi', {
      elementos: {
        p_encfac_numero: strNumFact,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  calcularTotales() {
    this.dblPorcIva = this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEIVA;
    const DESICE = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESICE').CFG_VALOR1;
    const ICEBIVAV = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ICEBIVAV').CFG_VALOR1;
    let dblValorTotal;
    let dblCostoTotalItem = 0;
    let dblValorIceItem = 0;
    let dblBaseIceItem = 0;
    let dblBaseIceTotal = 0;
    let dblValorIceTotal = 0;
    let dblBaseCeroTotal = 0;
    let dblBaseNoObjetoIva = 0;
    let dblBaseIvaTotal = 0;
    let dblValorIvaTotal = 0;
    let dblValorNeto = 0;
    let dblValorIRBPNR = 0;//GSRF
    let valordescfinal =0;
    let dblValorIvaItemsInludscto=0;
    let dblValorNoIvaItemsInludscto=0;
    valordescfinal= ((this.dblValorDescGlb + this.dblValorotrdscto)  * 100) /
       Number(this.init.quitarComas(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALNETO));
       if(isNaN(valordescfinal)){
        valordescfinal=0
       }
    for (let fila = 1; fila <= this.detfacarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TIPODET !== '*') {
        if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].ENCPED_NUMERO === null ||
          this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].ENCPED_NUMERO === undefined) {
          this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETPED_LINEA = fila;
        }
      }

      if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TIPODET !== '*') {
        if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CODIGO.length > 0) {
          // dblCostoTotalItem = this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD *
          //   this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO;
           //CDPJ
           
           let val=10 ** this.decimalesTotalFac;
           let total=Math.round(this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD * 
             val * this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO) / val;
           dblCostoTotalItem = Number(total.toFixed(this.decimalesTotalFac));
           console.log(dblCostoTotalItem);
           //CDPJ
 
          const dblDsct = dblCostoTotalItem * (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO / 100);
          dblCostoTotalItem = dblCostoTotalItem - dblDsct;
         // dblCostoTotalItem=Number(dblCostoTotalItem);//CDPJ
          console.log('itemSR',dblCostoTotalItem)
          dblCostoTotalItem=this.redondearDecimales(dblCostoTotalItem);//CDPJ
          console.log('itemRD',dblCostoTotalItem)
          //this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL = dblCostoTotalItem; // costo total por línea de detalle
          this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL = Number(dblCostoTotalItem); // costo total por línea de detalle CDPJ
          
          // GSRF
          console.log('entra  ----- ',this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO )
          if(Number(this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO) === 100){
            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_VALORIRBPNR = 0
          } else{
            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_VALORIRBPNR = this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD *
            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_IRBPNR;///GSRF
          }
          //GSRF
          // cálculo del ice por línea de detalle
          if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBICE != null) { // si tributa ice
            if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBICE === 'S') {
              if (DESICE === 1) {// si desglosa ice en la factura
                dblBaseIceItem = this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD *
                  this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO;
                this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASEICE = dblBaseIceItem;

                if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PORCEICE > 0) {
                  dblValorIceItem = dblBaseIceItem * this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PORCEICE / 100;
                } else if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_ICE > 0) {
                  dblValorIceItem = this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_ICE;
                }

                this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_ICE = dblValorIceItem;
              }
            }
          }

          // càlculo del iva por línea de detalle
          let dblBaseIvaItem = 0;
          let dblValorIvaItem = 0;
          let tmpValorIva=0
          let tmpValorBasenoIva=0;
          if(this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_INCLUENDSCTO === 'S' &&
          this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBIVA === 'S'
          ){
            tmpValorIva=dblCostoTotalItem * (1 - this.dblPorcDescGlb / 100);
            dblValorIvaItemsInludscto=dblValorIvaItemsInludscto + tmpValorIva

          }
          if(this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_INCLUENDSCTO === 'S' &&
          this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBIVA === 'N'
          ){
            tmpValorBasenoIva=dblCostoTotalItem * (1 - this.dblPorcDescGlb / 100);
            dblValorNoIvaItemsInludscto=dblValorNoIvaItemsInludscto + tmpValorBasenoIva;

          }
          //console.log('promo',this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PROMOCION)
          if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PROMOCION === '0' &&
            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBIVA === 'S') {
           // dblBaseIvaItem = dblCostoTotalItem * (1 - valordescfinal / 100) ;
           dblBaseIvaItem = dblCostoTotalItem * (1 - this.dblPorcDescGlb / 100);
            //console.log('si')
            //console.log('base',dblBaseIvaItem)
            //console.log('porce',this.dblPorcDescGlb)
          } else if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PROMOCION === '1' &&
            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO === 100
            && this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBIVA === 'S') {
            dblBaseIvaItem = this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD *
              this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO;
          } else if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PROMOCION === '1' &&
            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBIVA === 'S') {
            //dblBaseIvaItem = dblCostoTotalItem * (1 - valordescfinal / 100) ;
            dblBaseIvaItem = dblCostoTotalItem * (1 - this.dblPorcDescGlb / 100);
          } else {
            dblBaseIvaItem = 0;
          }

          if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBIVA === 'S') {

            if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBICE === 'S') {

              if (ICEBIVAV === 1) {// si ice forma parte de la base imponible
                dblBaseIvaItem = dblBaseIvaItem + this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_ICE;
              }
            }

            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASECERO = 0;
            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASENOOBJIVA = 0;

            dblValorIvaItem = dblBaseIvaItem * Number(this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PORIVA) / 100;
            // if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_INCLUENDSCTO === 'S' ){
            //   dblValorIvaItem=0;
            // }
            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_IVA = dblValorIvaItem;
            
          } else {

            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASECERO = 0;
            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASENOOBJIVA = 0;

            if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBASENOOBJIVA !== 'S') {
              ///this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASECERO = dblCostoTotalItem * (1 - valordescfinal / 100) ;
              this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASECERO = dblCostoTotalItem * (1 - this.dblPorcDescGlb / 100);
            } else {
              this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASENOOBJIVA =
              dblCostoTotalItem * (1 - this.dblPorcDescGlb / 100);
            }

            dblBaseIvaItem = 0;
            this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_IVA = 0;
          }

          this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASEIVA = dblBaseIvaItem;
        }
      }
      this.sumarCantidadTotal();
    }

    // TOTALES DEL DOCUMENTO
    let dblValorIvatotalIncluDsto=0
    for (let fila = 1; fila <= this.detfacarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TIPODET !== '*') {
        dblValorNeto = dblValorNeto + this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL;
        console.log(fila,' ', dblValorNeto ,' DT ',this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL);
        dblValorIRBPNR = dblValorIRBPNR + this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_VALORIRBPNR;//GSRF

        if (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TRIBICE === 'S') {
          dblValorIceTotal = dblValorIceTotal + (this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_ICE *  this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD);//GSRF
          dblBaseIceTotal = dblBaseIceTotal + this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASEICE;
        }
      }

      dblBaseCeroTotal = dblBaseCeroTotal + this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASECERO;
      dblBaseIvaTotal = dblBaseIvaTotal + this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASEIVA ;
      dblValorIvaTotal = dblValorIvaTotal + this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_IVA;
      dblBaseNoObjetoIva = dblBaseNoObjetoIva + this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_BASENOOBJIVA;
      //CDPJ
      if(this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_INCLUENDSCTO === 'S'){
        dblValorIvatotalIncluDsto = dblValorIvatotalIncluDsto + this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_IVA
      }
      //CDPJ
    }
    console.log('dblValorNoIvaItemsInludscto',dblValorNoIvaItemsInludscto)
    console.log('dblBaseIvaTotal',dblBaseIvaTotal)
    console.log('dblBaseCeroTotal',dblBaseCeroTotal)
    dblBaseIvaTotal = dblBaseIvaTotal - dblValorIvaItemsInludscto;
    dblBaseCeroTotal = dblBaseCeroTotal - dblValorNoIvaItemsInludscto;
    dblValorIvaTotal = dblValorIvaTotal - dblValorIvatotalIncluDsto;
    // cálculo del total de la factura
    //dblValorNeto=dblValorNeto - this.dblValorotrdscto;
    this.dblValorotrdsctoDif =Number(Number(dblValorIvaItemsInludscto).toFixed(this.confIniciales.getNumDecSist()));
    this.dblValorotrdsctoBcero = Number(Number(dblValorNoIvaItemsInludscto).toFixed(this.confIniciales.getNumDecSist()));
    this.dblValorotrdscto=dblValorIvaItemsInludscto+dblValorNoIvaItemsInludscto
    this.dblValorotrdscto=Number(Number(this.dblValorotrdscto).toFixed(this.confIniciales.getNumDecSist()))

    this.dblValorDescGlb = dblValorNeto * (this.dblPorcDescGlb / 100);
   // dblValorTotal = dblValorNeto + dblValorIvaTotal + dblValorIceTotal - this.dblValorDescGlb + dblValorIRBPNR;//GSRF;
    dblValorTotal = Number(dblValorNeto.toFixed(this.confIniciales.getNumDecSist())) + Number(this.redondearDecimalesGeneral(dblValorIvaTotal).toFixed(this.confIniciales.getNumDecSist())) + Number(dblValorIceTotal.toFixed(this.confIniciales.getNumDecSist())) - this.dblValorDescGlb + dblValorIRBPNR - this.dblValorotrdscto;//GSRF;

    console.log('Vneto',dblValorNeto)
    console.log('vIva',dblValorIvaTotal)
    console.log('descglobal',this.dblValorDescGlb)
    console.log('porce',this.dblPorcDescGlb)
    console.log('dblValorIvaItemsInludscto',dblValorIvaItemsInludscto)
    // decimales del detalle de la factura
    for (let fila = 1; fila <= this.detfacarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD =
        Number(this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_CANTIDAD).toFixed(this.decimalesCantidadFac);
      this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO =
        Number(this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_PRECIO).toFixed(this.decimalesPrecioFac);
      this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL =
        Number(this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_TOTAL).toFixed(this.decimalesTotalFac);
      this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO =
        Number(this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETFAC_DESCUENTO).toFixed(this.decimalesDescFac);
    }

    // colocar los valores en los labels del pie de la factura
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALNETO = Number(dblValorNeto).toFixed(this.confIniciales.getNumDecSist());
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEDES = Number(this.dblPorcDescGlb).toFixed(2);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_OTRDSCTOS = Number(this.dblValorotrdscto).toFixed(2);//CDPJ
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_OTRDSCTOSBASEDIF = Number(this.dblValorotrdsctoDif).toFixed(2);//CDPJ
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_OTRDSCTOSBASECERO = Number(this.dblValorotrdsctoBcero).toFixed(2);//CDPJ

    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORDES = Number(this.dblValorDescGlb).toFixed(2);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORICE = Number(dblValorIceTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEICE = Number(dblBaseIceTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASECERO = Number(dblBaseCeroTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEIVA = Number(dblBaseIvaTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORIVA = Number(this.redondearDecimalesGeneral(dblValorIvaTotal)).toFixed(this.confIniciales.getNumDecSist());
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTAL = Number(dblValorTotal).toFixed(this.confIniciales.getNumDecSist());
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASENOOBJIVA =
      Number(dblBaseNoObjetoIva).toFixed(this.confIniciales.getNumDecSist());
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORIRBPNR =
      Number(dblValorIRBPNR).toFixed(this.confIniciales.getNumDecSist());//GSRF

    if (this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO !== undefined
      && this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO !== null
      && this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO !== '') {
      this.aniadirServicio();
    }

    this.comas();
  }
  //CDPJ
  redondearDecimales(num) {
    let val=0
    //console.log(num);
    val=(Math.round((num + Number.EPSILON) *100)/100);
    val=Number(Number(val).toFixed(this.decimalesTotalFac));
    //console.log(val.toFixed(2))
    //console.log('>><<<<<',total)
    return val;
  }
  redondearDecimalesGeneral(num){
    let val=0
    //console.log(num);
    val=(Math.round((num + Number.EPSILON) *100)/100);
    val=Number(Number(val).toFixed(this.confIniciales.getNumDecSist()));
    //console.log(val.toFixed(2))
    //console.log('>><<<<<',total)
    return val;
  }
  //CDPJ
  aniadirServicio() {
    let baseServicio = 0;
    for (const det of this.detfacarray[this.init.tabs[this.init.tabindex].indice]) {
      if (det.ART_APLICASERVICIO === 'S') {
        const baseItem = det.DETFAC_CANTIDAD *
          det.DETFAC_PRECIO;
        baseServicio = baseServicio + Number(baseItem);
      }
    }
    let dblValorServicio =
      Number(baseServicio) * 10 / 100;
    let dblValorTotal = Number(this.init.quitarComas(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTAL));
    let total = dblValorTotal + dblValorServicio;
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_SERVICIO = this.init.moneyValidation(dblValorServicio.toFixed(this.confIniciales.getNumDecSist()));
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTAL = this.init.moneyValidation(total.toFixed(this.confIniciales.getNumDecSist()));
  }

  comas() {
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALNETO = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALNETO);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEDES = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEDES);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORDES = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORDES);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORICE = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORICE);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEICE = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEICE);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASECERO = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASECERO);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEIVA = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASEIVA);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORIVA = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_VALORIVA);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTAL = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTAL);
    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASENOOBJIVA = this.init.moneyValidation(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_BASENOOBJIVA);
  }


  leerConfiguracionDatosElectronicos(): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/facturas/2ymq2d11hk53thr', {
      elementos: {},
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions());
  }

  leerValidarRucsFacElec() {

    return this.http.post<any[]>(this.way + '/seg/compania/198bf3d5eefcce000000000', {
      elementos: {},
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions()).toPromise();;
  }
  actualizarDatosElectronicos(strNumFact): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/m2hf1jpi0tepblr', {
      elementos: {},
      datos: {
        NumFact: strNumFact
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarDatosElecProm(strNumFact) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/m2hf1jpi0tepblr', {
      elementos: {},
      datos: {
        NumFact: strNumFact
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  listarDatosArticulos(strCodCliente, strArticulo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/jj6a1evcb23u6zr', {
      elementos: {},
      datos: {
        CodCliente: strCodCliente,
        Articulo: strArticulo
      }
    }, this.confIniciales.httpOptions());
  }

  getDatosServicio(strCodServicio): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/2eawarxfhzcqcrj', {
      elementos: {},
      datos: {
        CodServicio: strCodServicio,
      }
    }, this.confIniciales.httpOptions());
  }


  obtenerCostoPromedio(strArtCodigo, strFecha, strHora): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/aj45h5ewq7d6via', {
      elementos: {},
      datos: {
        ArtCodigo: strArtCodigo,
        Fecha: strFecha,
        Hora: strHora
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  obtenerCostoCompraGen(strArtCodigo, strFecha, strHora): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/usa07pcexrzkj20', {
      elementos: {},
      datos: {
        ArtCodigo: strArtCodigo,
        Fecha: strFecha,
        Hora: strHora
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  obtenerCantMinArt(strArtCodigo, strBodCodigo) {

    return this.http.post<any[]>(this.way + '/ventas/facturas/8x04eukezy5ak5d', {
      elementos: {},
      datos: {
        ArtCodigo: strArtCodigo,
        BodCodigo: strBodCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  precioArticulo(strCodArt, strArtPreCodigo, strUniCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/st3bca4yprj95bg', {
      elementos: {},
      datos: {
        CodArt: strCodArt,
        ArtPreCodigo: strArtPreCodigo,
        UniCodigo: strUniCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarEstadoFE(strNumFact) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/36e75ff256d98e0000', {
      elementos: {
        strNumFact
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async verificarExistenciaArticulo(itemDet) {
    if (itemDet.DETFAC_TIPODET !== 'A') {
      return;
    }
    const fecha = new Date();
    const hora = fecha.getHours();
    const min = fecha.getMinutes();
    const horaAct = hora + ':' + min;
    await this.obtenerExistenciaArticulo(itemDet.DETFAC_CODIGO, itemDet.BOD_CODIGO, this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_NUMERO,
      this.datePipe.transform(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_FECHAEMISION, 'dd/MM/yyyy'),
      this.datePipe.transform(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_HORAEMISION, 'HH:mm'),
      //horaAct ,
       itemDet,
      'verificarexistencia');
  }

  verificarCantidadPedido(itemDet) {
    this.messageService.add({
      key: 'encfac',
      severity: 'warn',
      summary: 'Información',
      detail: 'La cantidad ingresada es mayor a la del pedido'
    });
    itemDet.DETFAC_CANTIDAD = itemDet.DETPED_CANTIDAD;
  }

  async obtenerExistenciaArticulo(strArtCodigo, strBodCodigo, strReferencia, strFecha, strHora, itemDet, tipo) {
    let dblCantPartesReserv = 0;
    let dblCantKardex = 0;

    // Extrae las partes incluídas en un artículo compuesto
    let strSql = 'SELECT INV_FNC_CANTPARTESRESERVADAS(\'' + strArtCodigo + '\',\'01\',\'';
    strSql = strSql + strBodCodigo + '\',\'' + strFecha + '\',\'' + strHora + '\') AS EXISTENCIA FROM DUAL';

    // extrae la cantidad del artículo en el kardex
    let strSentencia = 'SELECT NVL(SUM(TRNART_CANTIDAD),0) AS CANTIDAD  FROM INV_TRNKARDEX';
    strSentencia = strSentencia + ' WHERE  TRNART_TIPO=\'SA\'';
    strSentencia = strSentencia + ' AND COM_CODIGO = \'01\'';
    strSentencia = strSentencia + ' AND ART_CODIGO = \'' + strArtCodigo + '\'';
    strSentencia = strSentencia + '  AND BOD_CODIGO = \'' + strBodCodigo + '\'';
    strSentencia = strSentencia + ' AND TRNART_REFERENCIA = \'' + strReferencia + '\'';
    strSentencia = strSentencia + ' AND TRNART_ORIGEN = \'FAC\'';

    const data = await this.ecpGetExistenciaArticulo(strArtCodigo, strBodCodigo, strFecha, strHora);
    for (const rs1 of data) {
      dblCantPartesReserv = rs1.EXISTENCIA;
    }
    const data2 = await this.ecpGetCantArticulo(strArtCodigo, strBodCodigo, strReferencia);
    for (const rs2 of data2) {
      dblCantKardex = rs2.CANTIDAD;
    }
    console.log('dblCantPartesReserv',dblCantPartesReserv)
    console.log('dblCantKardex',dblCantKardex)
    let dblArtDet = Number(dblCantPartesReserv) + Number(dblCantKardex);
    let dblArtDetres = dblArtDet;
    let dblExistencia = 0;
    let dblCantUnidadCosteo = 0;

    let dblCantUnidadCosteoPed = 0;
    let dblCantUnidadPed = 0;
    let strEncGreNumero = '';

    dblCantUnidadCosteoPed = 0;
    for (let fila = 1; fila <= this.detfacarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const item = this.detfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1];
      let dblCantUnidad = 0;
      if (item.ENCGRE_NUMERO == null) {
        strEncGreNumero = '';
      }

      let dblCant = 0;
      let strUnidadCosteo = '';

      // obtener la unidad de costeo del artículo

      const er = await this.erpGetExistenciaArticulo(strArtCodigo);
      if (er !== null) {
        if (er[0] !== undefined) {
          strUnidadCosteo = er[0].ART_UNIDADCOSTEO;
        }
      }

      // obtener la cantidad del artículo según la unidad de costeo
      if (item.DETFAC_UNIDAD !== strUnidadCosteo) {
        /* strSql = 'SELECT INV_FNC_TRANSFORMA_UNIDAD(\'' + item.DETFAC_UNIDAD + '\',\'' + strUnidadCosteo + '\',';
        strSql = strSql + item.DETFAC_CANTIDAD + ',\'01\') FROM DUAL'; */
        const datacant = await this.ecCantArticuloCosteoProm(item.DETFAC_UNIDAD, strUnidadCosteo, item.DETFAC_CANTIDAD);
        //this.ecCantArticuloCosteo(item.DETFAC_UNIDAD, strUnidadCosteo, item.DETFAC_CANTIDAD).subscribe(datacant => {
        for (const rs of datacant) {
          dblCant = rs.CANTIDAD;
        }

        if (item.DETFAC_CODIGO != null
          && strEncGreNumero === '') {
          if (item.DETFAC_CODIGO === strArtCodigo
            && item.BOD_CODIGO === strBodCodigo) {
            dblCantUnidad = dblCant;
          }
        }
        dblCantUnidadCosteo = Number(dblCantUnidadCosteo) + Number(dblCantUnidad);

        const RESARTPED = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RESARTPED').CFG_VALOR1;
        if (RESARTPED === 1) {
          if (item.DETFAC_CODIGO != null) {
            if (item.DETFAC_CODIGO === strArtCodigo
              && item.BOD_CODIGO === strBodCodigo
              && item.ENCPED_NUMERO !== '') {
              dblCantUnidadPed = dblCant;
            }
          }

          dblCantUnidadCosteoPed = Number(dblCantUnidadCosteoPed)
            + Number(dblCantUnidadPed);
        }
        //});
      } else {
        dblCant = item.DETFAC_CANTIDAD;
        if (item.DETFAC_CODIGO != null
          && strEncGreNumero === '') {
          if (item.DETFAC_CODIGO === strArtCodigo
            && item.BOD_CODIGO === strBodCodigo) {
            dblCantUnidad = dblCant;
          }
        }
        dblCantUnidadCosteo = Number(dblCantUnidadCosteo) + Number(dblCantUnidad);

        const RESARTPED = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RESARTPED').CFG_VALOR1;
        if (RESARTPED === 1) {
          if (item.DETFAC_CODIGO != null) {
            if (item.DETFAC_CODIGO === strArtCodigo
              && item.BOD_CODIGO === strBodCodigo
              && item.ENCPED_NUMERO !== '') {
              dblCantUnidadPed = dblCant;
            }
          }

          dblCantUnidadCosteoPed = Number(dblCantUnidadCosteoPed)
            + Number(dblCantUnidadPed);
        }
      }
    }

    if(itemDet.ENCPED_NUMERO === null){
      itemDet.ENCPED_NUMERO ='';
    }
    if (itemDet.ENCGRE_NUMERO === null){
      itemDet.ENCGRE_NUMERO='';
    }
    // dblArtDet = dblArtDet + dblCantUnidadCosteoPed;
    // dblExistencia = dblArtDet - dblCantUnidadCosteo;
    // itemDet.EXISTENCIA = dblExistencia;
    //CDPJ
    // if (dblCantUnidadCosteoPed === 0) {
    //   dblArtDet = dblArtDet + dblCantUnidadCosteo
    //   dblExistencia = dblArtDet - dblCantUnidadCosteo;
    // } else {
    //   dblArtDet = dblArtDet + dblCantUnidadCosteoPed;
    //   //console.log('1>', dblArtDet)
    //   dblExistencia = dblArtDet - dblCantUnidadCosteo;
    // }
    //CDPJ
    //CDPJ
    console.log('dblArtDet>', dblArtDet)
    console.log('dblCantUnidadCosteoPed>', dblCantUnidadCosteoPed)
    console.log('dblCantUnidadCosteo>', dblCantUnidadCosteo)
    if (itemDet.ENCGRE_NUMERO === '' && itemDet.ENCPED_NUMERO !== '') {
      
      console.log('dblCantUnidadCosteo>>>', dblCantUnidadCosteo)
      if (dblCantUnidadCosteoPed === 0) {
        dblArtDet = dblArtDet + dblCantUnidadCosteo
        dblExistencia = dblArtDet - dblCantUnidadCosteo;
        
      } else {
        dblArtDet = dblArtDet + dblCantUnidadCosteoPed;
        //
        dblExistencia = dblArtDet - dblCantUnidadCosteo;
      }
      
      if (dblExistencia === 0) {
        dblArtDet = dblArtDet + dblCantUnidadCosteo
        dblExistencia = dblArtDet - dblCantUnidadCosteo;
      }
      //itemDet.EXISTENCIA = dblExistencia;
      console.log('2P>', dblExistencia);
    } else {
      if (dblCantUnidadCosteoPed === 0) {
        dblArtDet = dblArtDet + dblCantUnidadCosteoPed
        dblExistencia = dblArtDet - dblCantUnidadCosteo;
      } else {
        dblArtDet = dblArtDet + dblCantUnidadCosteoPed;
        //console.log('1>', dblArtDet)
        dblExistencia = dblArtDet - dblCantUnidadCosteo;
      }
      if (dblExistencia === 0) {
        dblArtDet = dblArtDet + dblCantUnidadCosteo
        dblExistencia = dblArtDet - dblCantUnidadCosteo;
      }
      console.log('2P1>', dblExistencia);
    }
    //CDPJ
    //GSRF
    console.log('1>', dblCantUnidadCosteo)
    console.log('2>', dblExistencia)
    console.log('entra', dblArtDetres)
    if ( itemDet.ENCGRE_NUMERO === '' && itemDet.ENCPED_NUMERO !==''){
      if(dblExistencia < dblCantUnidadCosteo ){
        dblExistencia=dblExistencia-dblCantUnidadCosteo;
        console.log('entra', dblExistencia)
      }else if(dblExistencia === dblCantUnidadCosteo && dblArtDetres ===0 ){
        dblExistencia=0-dblCantUnidadCosteo;
        console.log('entra1', dblExistencia)
      }
    }
    //GSRF
    itemDet.EXISTENCIA = dblExistencia;
    console.log('1>', dblArtDet)
    console.log('2>', dblExistencia)
    console.log('2>', dblCantKardex)
    await this.obtenerExistenciaParte2(itemDet, dblExistencia, tipo,dblCantKardex,dblArtDet);
  }

  async obtenerExistenciaParte2(itemDet, dblExistArt, tipo,dblCantKardex,dblArtDet) {
    let dblCantidadDisp = 0;
    let dblCantidadComprXFacturar = 0;
    let dblCantCompPedFac = 0;

    let detpedlinea = 0;
    if (itemDet.DETPED_LINEA === undefined || itemDet.DETPED_LINEA === null || itemDet.DETPED_LINEA === '') {
      detpedlinea = 0;
    } else {
      detpedlinea = itemDet.DETPED_LINEA;
    }

    /* let strSentencia = 'select INV_FNC_OBT_CANTI_CMP_PEDXFAC(\'' + itemDet.ENCPED_NUMERO + '\',';
    strSentencia = strSentencia + detpedlinea + ') AS CANTIDAD from dual '; */

    const dataEC1 = await this.ecpGetExistenciasParte2(itemDet.ENCPED_NUMERO, detpedlinea);
    for (const rs of dataEC1) {
      dblCantCompPedFac = rs.CANTIDAD;
    }

    // ******** VERIFICAR CANTIDAD FACTURADA SI SE TRATA DE UN PEDIDO
    const intRESPEDBOD = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RESPEDBOD').CFG_VALOR1;
    if (intRESPEDBOD === 1) {  // verificar si esta activada la configuracion de
      // reservar la cantidad comprometida en pedidos

      if (itemDet.ENCPED_NUMERO !== '') {
        // Cantidad comprometida del pedido por facturar
        dblCantidadComprXFacturar = dblCantCompPedFac;
        dblExistArt = dblExistArt + dblCantidadComprXFacturar;
      }
    }

    // EMPIEZA AQUI  cantArtDetFacEnUniCosteo
    let dblCantUniCos = 0;
    let strUnidadCosteo = '';

    // obtener la unidad de costeo del artículo

    const er = await this.erpGetExistenciaPartII(itemDet.DETFAC_CODIGO);
    if (er !== null) {
      if (er[0] !== undefined) {
        strUnidadCosteo = er[0].ART_UNIDADCOSTEO;
      }
    }
    let strSql = 'SELECT INV_FNC_TRANSFORMA_UNIDAD(\'' + itemDet.DETFAC_UNIDAD + '\',\'' + strUnidadCosteo + '\',';
    strSql = strSql + itemDet.DETFAC_CANTIDAD + ',\'01\') FROM DUAL';

    const data2 = await this.ecpGetExist2(itemDet.DETFAC_UNIDAD, strUnidadCosteo, itemDet.DETFAC_CANTIDAD);
    // obtener la cantidad del artículo según la unidad de costeo
    if (itemDet.DETFAC_UNIDAD !== strUnidadCosteo) {

      for (const rs of data2) {
        dblCantUniCos = rs.CANTIDAD;
      }

    } else {
      dblCantUniCos = itemDet.DETFAC_CANTIDAD;
    }

    console.log('dblCantUniCos',dblCantUniCos)
    const data = await this.ecpGetCantArtCosteo(itemDet.DETFAC_CODIGO);
    let strUniCosteo = '';
    for (const rs of data) {
      strUniCosteo = rs.UNIDAD;
    }
    if (itemDet.ENCGRE_NUMERO === null || itemDet.ENCGRE_NUMERO === undefined) {
      itemDet.ENCGRE_NUMERO = '';
    }
    console.log('*****-----',dblExistArt)
    console.log('*****-----',itemDet.ENCGRE_NUMERO.length)
    if (dblExistArt < 0 && itemDet.ENCGRE_NUMERO.length === 0) {
      console.log('entra',dblExistArt)
      if (tipo === 'verificarexistencia') {
        
       console.log('entra 2',tipo)
        const dblCantUniCosteo = dblCantUniCos;
        let dblCantTransformaunidad = Number(dblExistArt) + Number(dblCantUniCosteo);

        const datatu = await this.ecpGetTransformaUnidad(strUniCosteo, itemDet.DETFAC_UNIDAD, dblCantTransformaunidad);
        for (const rs of datatu) {
          dblCantTransformaunidad = rs.UNIDAD;
        }
        if (dblExistArt >= 0) {
          if (itemDet.DETFAC_TIPODET !== 'Y') { // si no es
            // componente
            dblCantidadDisp = dblExistArt + dblCantUniCosteo;
            dblCantidadDisp = dblCantUniCos;
          }
        } else {
          dblCantidadDisp = dblCantTransformaunidad;
        }
        this.messageService.add({
          key: 'encfac',
          severity: 'warn',
          summary: 'Información',
          detail: 'La cantidad ingresada es mayor a la existencia del artículo.'
        });
        itemDet.DETFAC_CANTIDAD = dblCantidadDisp;
        //itemDet.EXISTENCIA = dblCantidadDisp;
      }

      if (tipo === 'cantfisica') {
        dblCantidadDisp = dblExistArt;
        //itemDet.EXISTENCIA = dblCantidadDisp;
      }
    } else {
      if(isNaN(dblExistArt) === true || dblExistArt === null || dblExistArt=== undefined){
        dblExistArt=0
      }
      if(dblCantKardex > 0){
        if(dblCantKardex !== dblExistArt ){
          console.log('entra+++++',dblCantKardex)
          console.log('entra+++++',dblArtDet)
          dblExistArt=dblArtDet
        }
      }
      dblCantidadDisp = Number(dblExistArt);
      itemDet.EXISTENCIA = dblCantidadDisp;
    }
    console.log('dblCantidadDisp', dblCantidadDisp);
  }

  async comprobarPrecio(item) {
    let dblCostoProm = 0;
    const strFecha = this.datePipe.transform(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    const strHora = this.datePipe.transform(this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_HORAEMISION, 'HH:mm');


    const MARPREBCOS = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'MARPREBCOS').CFG_VALOR1;
    if (MARPREBCOS === 1) { // marcar el precio bajo el costo
      if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad
        // colorear el precio bajo el costo
        if (item.DETFAC_CODIGO.length > 0) {
          if (item.DETFAC_TIPODET !== 'Y') {
            const data = await this.obtenerCostoPromedio(item
              .DETFAC_CODIGO, strFecha, strHora);
            for (const rs of data) {
              dblCostoProm = rs.COSTO;
            }
            item.COSTOPROM = dblCostoProm;

          }
        }
      }
    }

    const PREBAJCOM = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PREBAJCOM').CFG_VALOR1;

    // Si esta activado Marcar los precios que estan bajo el costo de compra
    if (PREBAJCOM === 1) { // marcar el precio bajo el costo

      if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad

        // colorear el precio bajo el costo
        if (item.DETFAC_CODIGO.length > 0) {
          if (item.DETFAC_TIPODET !== 'Y') {
            const data = await this.obtenerCostoCompraGen(item
              .DETFAC_CODIGO, strFecha, strHora);
            for (const rs of data) {
              dblCostoProm = rs.COSTO;
            }
            item.COSTOPROM = dblCostoProm;

          }
        }
      }
    }
  }

  async comprobarExistencia(item) {
    let dblCantMin = 0;
    let dblExist = 0;

    if (item.DETFAC_TIPODET === 'A') { // si es artículo cambiar el color del precio y la cantidad
      if (item.DETFAC_TIPODET !== 'Y') {
        const data = await this.obtenerCantMinArt(item
          .DETFAC_CODIGO, item.BOD_CODIGO);
        for (const rs of data) {
          dblCantMin = rs.ARTEXT_MINIMA;
          // dblExist = rs.EXISTENCIA;
        }
        item.ART_CANTMIN = dblCantMin;
        // item.EXISTENCIA = dblExist;
      }
    }
  }

  async verificarPrecioBajoListaPre(item) {
    let dblPrecio = 0;

    if (item.DETFAC_TIPODET === 'A') {// si es artículo cambiar el  // color del precio y la  // cantidad
      // colorear el precio bajo el costo
      if (item.DETFAC_CODIGO.length > 0) {
        if (item.DETFAC_TIPODET !== 'Y') {
          const data = await this.precioArticulo(item.DETFAC_CODIGO, item.DETFAC_LISTA, item
            .DETFAC_UNIDAD);
          for (const rs of data) {
            dblPrecio = rs.PRECIO;
          }
          if (Number(item.DETFAC_PRECIO) < dblPrecio && dblPrecio > 0) {
            this.messageService.add({
              key: 'encfac',
              severity: 'warn',
              summary: 'Información',
              detail: 'El precios es menor a la lista de precio correspondiente.'
            });
            item.DETFAC_PRECIO = dblPrecio;
          }

        }
      }
    }
  }

  sumarCantidadTotal() {
    let dblTotal = 0;

    for (const item of this.detfacarray[this.init.tabs[this.init.tabindex].indice]) {
      if (item.DETFAC_TIPODET === 'A') { // si es artículo
        // cambiar el color del
        // precio y la cantidad
        dblTotal = dblTotal + Number(item.DETFAC_CANTIDAD);
      }
    }

    this.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_TOTALCANT = dblTotal.toFixed(2);
  }

  existenciaPorBodega(artCodigo, artPreCodigo, fecha, hora, iva) {
    fecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    hora = this.datePipe.transform(hora, 'hh:mm');
    iva = Number(iva) / 100;

    return this.http.post<any[]>(this.way + '/ventas/facturas/nh98r42ipdwb06b', {
      elementos: {},
      datos: {
        Codigo: artCodigo,
        PreCodigo: artPreCodigo,
        Fecha: fecha,
        Hora: hora,
        Iva: iva
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  anularAsientoContable(strAsiNro) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/abfaaadab9276', {
      elementos: {
        p_ASI_nro: strAsiNro,
        p_COM_codigo: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarAsientoContable(strAsiNro) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/3ab2b4aeadab92', {
      elementos: {
        p_ASI_nro_1: strAsiNro,
        p_COM_codigo: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarSeriales(strNumDoc, strOrigen, lngNumLinea, strTipo, strCodArt) {
    return this.http.post<any[]>(this.way + '/ventas/kardexseriales/7565695d5be3b8000000', {
      elementos: {
        strNumDoc, strOrigen, lngNumLinea, strTipo, strCodArt
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarlotecomentario(DetFacLinea, EncfacCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/1d595a5756eb8f', {
      elementos: {
        DetFacLinea, EncfacCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }


  selectlotecomentario(DetFacLinea, EncfacCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/e3aae676b8eb98', {
      elementos: {
        DetFacLinea, EncfacCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  erArtPreDescuento(strArtCodigo, strLista) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/76d5bee76e6bb80', {
      elementos: {
        ART_CODIGO: strArtCodigo,
        DETFAC_LISTA: strLista
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  consultarPedidoCompuesto(strCodPedido): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/facturas/3317e65cd662d8', {
      elementos: {},
      datos: {
        CodPedido: strCodPedido
      }
    }, this.confIniciales.httpOptions());
  }

  actualizaFacturadoPedido(encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/14cebd4cefb2e70000', {
      elementos: {
        p_encped_numero: encpednumero,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  verificarActualizarEstPedFact(encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/3eedc9fceb72e60000', {
      elementos: {
        p_ENCPED_numero: encpednumero,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  getImportesReembolso(strNumFact): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/venreembolsos/1077656ce37ddc000', {
      elementos: {
        NumFact: strNumFact
      },
    }, this.confIniciales.httpOptions());
  }

  ercuentaarticulo(strCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/76d9e75faa56fc', {
      elementos: {
        strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ercuentaservicio(strCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/76d9e75faae3b8', {
      elementos: {
        strCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  extraerordenestrabajo(strNumFact) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/e673bb53b77f580', {
      elementos: {
        strNumFact
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  ercentrocuenta(strCuenta) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/1db63afdde19e7', {
      elementos: {
        strCuenta
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consultarordentra(strOrdTraNumero) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/cc5f9eaf55bc70', {
      elementos: {
        strOrdTraNumero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  verificarActualizarEstOrdTra(ordtranumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/fbb727c98ada980', {
      elementos: {
        p_ORDTRA_numero: ordtranumero,
        p_COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  //CDPJ
  encontrarArticulo(getDetFacCodigo) {
    return this.http.post(this.way + '/ventas/facturas/ebb317eed5b5700000000', {
      elementos: {
        getDetFacCodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  cantidadequivalencia(DETFAC_UNIDAD){
    return this.http.post(this.way + '/ventas/facturas/41dd62afd9354c000000000000000', {
      elementos: {
        DETFAC_UNIDAD
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  desglobalCliente(clicodigo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/cc5f9eaf55b6b800000000000000000', {
      elementos: {
        cli_codigo: clicodigo,
        com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerEstFE(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/198bf3d5eab6ee0000', {
      elementos: {   
      }
    }, this.confIniciales.httpOptions());
  }
  getNumlotes(encfacnum) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/198bf3d5bfad5c0000', {
      elementos: {
        enfacnum: encfacnum,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  reversarLotes(encfacnum) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/4affeeefb77ddc0000000000', {
      elementos: {
        p_encfac_numero: encfacnum,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  distribuirLotes(encfacnum) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/12bffbbbb65cef0000000000000', {
      elementos: {
        p_encfac_numero: encfacnum,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  agregarlotescomenatario(encfacnum) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/150dba0add71d70000000000000000000', {
      elementos: {
        p_encfac_numero: encfacnum,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarKardexFacGui(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/299df2ab2', {
      elementos: {
        p_encfac_numero: strNumFact,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  obtenerbasesiva(numero) {
    return this.http.post<any>(this.way + '/ventas/facturas/107756ae3b92fb00000000', {
      elementos: {
        p_fac_numero:numero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizabasesiva(numero) {
    return this.http.post<any>(this.way + '/ventas/facturas/14cebd4c5ab8ee0000', {
      elementos: {
        p_encfac_numero:numero,
        p_com_codigo:'01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenernombregrucli(grupo)  {
    return this.http.post<any>(this.way + '/ventas/cliente/eddf165edd0df800', {
      elementos: {
        GRU_CODIGO:grupo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerNumeracionRef(strTipoNum) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/30bebaeeddfd6e000000000', {
      elementos: {
        NUM_DOCREF: strTipoNum,
        COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
   //GSRF
  
   busquedaAnexMaeCodSri9() {
    return this.http.post(this.way + '/inventario/busqueda/2eca7330de6e48009', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri10() {
    return this.http.post(this.way + '/inventario/busqueda/5d94e661bcdc800', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  busquedaAnexMaeCodSri11() {
    return this.http.post(this.way + '/inventario/busqueda/5d94e661bcdc840', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  obtenerDistrito(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/c2fae6cb9ddcb80', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  obtenerRegimenRefrendo(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/185f76e86dcf77000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getfacexp(numfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/41dd7a98e', {
      elementos: {
        NUMFAC: numfac,
        COM_CODIGO:'01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  deletfacexp(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/d755dd7a98e', {
      elementos: {
        p_ENCFAC_numero_1: strNumFact,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  insertexporadi(item,clavexp): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/4afc76fae', {
      elementos: {
        p_comercioexterior: item.COMERCIOEXTERIOR,
        p_incotermfactura: item.INCOTERMFACTURA,
        p_lugarincoterm: item.LUGARINCOTERM,
        p_paisorigen: item.PAISORIGEN,
        p_puertoembarque: item.PUERTOEMBARQUE,
        p_puertodestino: item.PUERTODESTINO,
        p_paisdestino: item.PAISDESTINO,
        p_paisadquisicion: item.PAISADQUISICION,
        p_dircomprador: item.DIRCOMPRADOR,               
        p_incotermtotalsinimpuestos: item.INCOTERMTOTALSINIMPUESTOS,
        p_fleteinternacional: item.FLETEINTERNACIONAL,
        p_segurointernacional: item.SEGUROINTERNACIONAL,       
        p_gastosaduaneros: item.GASTOSADUANEROS,
        p_gastostransporteotros: item.GASTOSTRANSPORTEOTROS,
        p_formapago: item.FORMAPAGO,
        p_encfac_numero: item.ENCFAC_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_clave: clavexp
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  obtenerTipoIExterior(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/185f5d776fb2ce000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }
  getCliente(clicodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/cliente/20eeb2b275fae', {
      elementos: {
        CLI_CODIGO: clicodigo
      }
    }, this.confIniciales.httpOptions());
  }
  insertexporadinew(item,clavexp): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/bf9dfc4afc76f80', {
      elementos: {
        p_tpidclienteex: item.CLI_TIPO,  
        p_parterelexp: item.CLI_PARTEREL,    
        p_denoexpcli: item.DENOEXPCLI,    
        p_tiporegi: item.TIPOREGI,    
        p_paisefecpagogen: item.PAISEFECPAGOGEN,   
        p_paisefecexp: item.PAISEFECEXP,    
        p_exportacionde: item.EXPORTACIONDE,    
        p_distaduanero: item.DISTADUANERO,    
        p_anio: item.ANIO,    
        p_regimen: item.REGIMEN,    
        p_correlativo: item.CORRELATIVO,   
        p_doctransp: item.DOCTRANSP,    
        p_fechaembarque: item.FECHAEMBARQUE,    
        p_valorfob: item.VALORFOB,   
        p_valorfobcomprobante: item.VALORFOBCOMPROBANTE,    
        p_tipoingreso: item.TIPOINGRESO,   
        p_inggravadoext: item.INGGRAVADOEXT,   
        p_valorimpext: item.VALORIMPEXT,   
        p_tipocomprobante: '01',
        p_encfac_numero: item.ENCFAC_NUMERO,
        p_com_codigo: item.COM_CODIGO,
        p_clave: clavexp
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  consulvenped(encgre_codigo, encgre_numero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/662fcf57eebc380', {
      elementos: {
        gre_codigo: encgre_codigo,
        gre_numero: encgre_numero
      }
    }, this.confIniciales.httpOptions());
  }
  descxlineacli(cliCodigo) {
    return this.http.post<any>(this.way + '/ventas/facturas/d7719facaee53000', {
      elementos: {
        p_cliCodigo: cliCodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //GSRF
}
