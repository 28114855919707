<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maelote"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="maeloteconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar"
						 [botonBorrar]="botonBorrar" [botonRegresar]="botonRegresar"
						 [barraBotonesDesplaz]="oculto" [barraBotones3]="oculto"
						 [barraBotonesAsiCon]="oculto" [barraBotones5]="oculto"
						 [botonBuscarPermanente]="true"></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="width: 99%;">
	<div class="p-col-12">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-8">
					<app-aggridsaci [width]=""
									[height]="largo"
									
									[enableFilter]="false"
									[rowData]="maeloteservice.detmaelote"
									[frameworkComponents]="frameworkComponents"
									[animateRows]="true"
									[rowSelection]="rowSelection"
									[columnDefs]="columnDefsDetalle"
									[defaultColDef]="defaultColDefDetalle"
									[rowStyle]="rowStyle"
									[singleClickEdit]="false"
									[botones]="false"
									(selected)="seleccionlote($event)"
									(click)="seleccionlote($event)"
									(cambios)="botonesmodificar()">
					</app-aggridsaci>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog> -->

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlgmae [arrayOptions]="types" [arregloConsulta]="arregloCons" [consulta]="consulta" [tabla]="tabla"
						[where]="where"
						[busquedaParams]="busquedacampos"
						(opcionSeleccionada)="manejarSeleccion($event)"
						[busquedaCompuesta]="false"></app-busquedadlgmae>
</p-dialog>