import { Component, OnInit, ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { ConpresuanucenService } from "../conservicios/conpresuanucen.service";
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Presuanudetplanc } from "../coninterfaces/presuanudetplanc";
import { Presuanuencplanc } from '../coninterfaces/presuanuencplanc';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { isNullOrUndefined } from 'util';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { Presuanudetplancen } from '../coninterfaces/presuanudetplancen';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
@Component({
  selector: 'app-conpresuanucen',
  templateUrl: './conpresuanucen.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class ConpresuanucenComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  indicador: any;
  //ag-grid
  rowSelection = 'multiple';
  public frameworkComponents;
  defaultColDefPresuanucon;
  defaultColDefPresuanucen;
  defaultColDefPresuanucenpro;
  rowStyle;
  detpresuplananuselect: Presuanudetplanc;
  detpresuplananucenselect: Presuanudetplancen;
  boolnuevo = false;
  boolconsolida = false;
  spin: boolean;
  opcion: string;
  types: SelectItem[];
  arregloCons: any[];
  displayDialogBusquedaFast: boolean;
  displayDialogSelectPCC: boolean;
  busquedacampos: string[];
  tabla: string;
  where: string;
  index: number;
  cuentaContableAux: string;
  nombreCuentaAux: string;
  boolcc: boolean;
  btnGridBorrar: boolean = true;
  permisoPanu: SegMaePermiso;
  columnDefspresuAnuCon = [
    {
      headerName: 'Cuenta', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {

        return false;
      },
    },
    {
      headerName: 'Descripcion', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        return false;
      },
    },
    {
      headerName: 'CC', field: 'CON_CENTRO', cellEditor: 'cellPrueba', width: 70, editable: (params) => {
        return false;
      },
    },
    {
      headerName: 'Enero', field: 'DETPRESANUCON_01', cellEditor: 'cellPrueba', width: 82, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Febrero', field: 'DETPRESANUCON_02', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Marzo', field: 'DETPRESANUCON_03', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Abril', field: 'DETPRESANUCON_04', cellEditor: 'cellPrueba', width: 82, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Mayo', field: 'DETPRESANUCON_05', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Junio', field: 'DETPRESANUCON_06', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Julio', field: 'DETPRESANUCON_07', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Agosto', field: 'DETPRESANUCON_08', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Septiembre', field: 'DETPRESANUCON_09', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Octubre', field: 'DETPRESANUCON_10', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Noviembre', field: 'DETPRESANUCON_11', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Diciembre', field: 'DETPRESANUCON_12', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Acumulado', field: 'DETPRESANUCON_ACM', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
  ];
  columnDefpresuAnuCen = [
    {
      headerName: 'CC', field: 'CEN_CODIGO'
      , width: 80,
      editable: false,
    },
    {
      headerName: 'Descripción', field: 'CEN_NOMBRE', cellEditor: 'cellPrueba', width: 120, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: false,
        }
      }
    },
    {
      headerName: 'Enero', field: 'DETPRESANUCEN_01', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Febrero', field: 'DETPRESANUCEN_02', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Marzo', field: 'DETPRESANUCEN_03', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Abril', field: 'DETPRESANUCEN_04', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Mayo', field: 'DETPRESANUCEN_05', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },

    {
      headerName: 'Junio', field: 'DETPRESANUCEN_06', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Julio', field: 'DETPRESANUCEN_07', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Agosto', field: 'DETPRESANUCEN_08', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Septiembre', field: 'DETPRESANUCEN_09', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Octubre', field: 'DETPRESANUCEN_10', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Noviembre', field: 'DETPRESANUCEN_11', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Diciembre', field: 'DETPRESANUCEN_12', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Acumulado', field: 'DETPRESANUCEN_ACM', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'right' }, editable: false,
      cellEditorParams:
      {
        values: {
          editable: false,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: false,
        }
      }
    },
  ];
  columnDefpresuAnuCenPro = [
    {
      headerName: 'Mes', field: 'DETPRESANUCENPRO_DES'
      , width: 80,
      editable: false,
    },

    {
      headerName: 'Presupuesto Real', field: 'DETPRESANUCENPRO_REAL', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Presupuesto Proyectado', field: 'DETPRESANUCENPRO_VALOR', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
  ];
  constructor(
    private init: NuevoComponentService,
    public conpresuanucenService: ConpresuanucenService, private messageService: MessageService,
    private confirmationService: ConfirmationService, private auditoriagral: AuditoriagralService,
    private busquedaSer: InvbusquedaService, private permisosService: PermisosService,
  ) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefPresuanucon = {
      editable: true,
      width: 130,
      objeto: 'presuanucon'
    };
    this.defaultColDefPresuanucen = {
      editable: true,
      width: 130,
      objeto: 'presuanucen'
    };
    this.defaultColDefPresuanucenpro = {
      editable: true,
      width: 130,
      objeto: 'presuanucenpro'
    };
  }
  async ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.index = 0;
    this.boolcc = false;
    this.displayDialogSelectPCC = false;
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador] = {};
    this.detpresuplananuselect = {};
    this.detpresuplananucenselect = {};
    this.conpresuanucenService.detpresuanupuestoarray = [];
    this.conpresuanucenService.detpresuanupuestocenarray = [];
    this.conpresuanucenService.detpresuanupuestocenproarray = [];
    await this.getPermisos();
  }
  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoPanu = data[0];
        } else {
          this.permisoPanu = null;
        }
      } else {
        this.permisoPanu = null;
      }
    } catch (e) {
      this.permisoPanu = null;
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }
  manejarSenales(evento) {
    if (evento === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (evento === 'Cancelar') {
      this.cencelar()
    }
    if (evento === 'Nuevo') {
      if (this.permisoPanu.PERTODO === 1) {
        this.nuevo();
      } else {
        if (this.permisoPanu.PERINSERCION === 1) {
          this.nuevo();
        } else {
          this.messageService.add({
            key: 'preanuplanc',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de inserción, acción denegada.'
          });
        }
      }
    }

    if (evento === 'Guardar') {
      if (this.index === 0) {
        if (this.permisoPanu.PERTODO === 1) {
          this.spin = true;
          this.guardarcntas()
        } else {
          if (this.permisoPanu.PERACTUALIZACION === 1) {
            this.spin = true;
            this.guardarcntas()
          } else {
            this.messageService.add({
              key: 'preanuplanc',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario no tiene permisos de actualización, acción denegada.'
            });
          }
        }
      }
      if (this.index === 1) {
        if (this.permisoPanu.PERTODO === 1) {
          this.spin = true;
          this.guardarcen()
        } else {
          if (this.permisoPanu.PERACTUALIZACION === 1) {
            this.spin = true;
            this.guardarcen()
          } else {
            this.messageService.add({
              key: 'preanuplanc',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario no tiene permisos de actualización, acción denegada.'
            });
          }
        }

      }
    }
    if (evento === 'Borrar') {
      if (this.index === 0) {
        if (this.permisoPanu.PERTODO === 1) {
          this.confirmarborrar();
        } else {
          if (this.permisoPanu.PERELIMACION === 1) {
            this.confirmarborrar();
          } else {
            this.messageService.add({
              key: 'preanuplanc',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario no tiene permisos de eliminación, acción denegada.'
            });
          }
        }
      }
    }
    if (evento === 'Consolidar') {
      if (this.index === 0) {
        this.consolida()
      }
    }
    if (evento === 'Buscar') {
      if (this.permisoPanu.PERTODO === 1) {
        this.busqueda()
      } else {
        if (this.permisoPanu.PERSELECCION === 1) {
          this.busqueda()
        } else {
          this.messageService.add({
            key: 'preanuplanc',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de consulta, acción denegada.'
          });
        }
      }
    }
  }
  async consolida() {
    try {
      if (this.btnGuardar === false) {
        this.messageService.add({
          key: 'preanuplanc',
          severity: 'error',
          summary: 'Consolidar Encabezado Presupuesto',
          detail: 'Guarde primero el registro'
        });
        return
      }
      await this.conpresuanucenService.consolidarcuentas(this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO).catch(e => {
        this.messageService.add({
          key: 'preanuplanc',
          severity: 'error',
          summary: 'Consolidar Encabezado Presupuesto',
          detail: e.error
        });

        return
      });
      this.auditoriagral.registrarAuditoria('CON_ENCPRESCON', this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO, 'CO',
        '', '01', '', '', '', '').subscribe(() => { });
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'success',
        summary: 'Insertar Presupuesto',
        detail: 'Se consolidó con éxito el presupuesto'
      });
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      await this.busquedaanio(this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO)
    } catch (er) {
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'Consolidar Encabezado Presupuesto',
        detail: er.error
      });
      this.spin = false;
      return
    }

  }
  confirmarborrar() {
    if (this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].CONTROL !== 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'PreAnuPlanc',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.eliminarregistro();
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }
  async eliminarregistro() {
    try {
      await this.conpresuanucenService.eliminarregistro(this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO);
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'success',
        summary: 'Eliminar Presupuesto',
        detail: 'Se eliminó con éxito el presupuesto por centro de costos'
      });
      this.auditoriagral.registrarAuditoria('CON_ENCPRESCON', this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO, 'E',
        '', '01', '', '', '', '').subscribe(() => { });
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.spin = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = true;
      this.boolnuevo = false;
      this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador] = {};
      this.conpresuanucenService.detpresuanupuestoarray = [];
      this.conpresuanucenService.detpresuanupuestocenarray = [];

    } catch (er) {
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'Eliminar Encabezado Presupuesto',
        detail: er.error
      });
      this.spin = false;
      return
    }
  }
  async guardarcntas() {
    let enc: Presuanuencplanc = this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador];
    if (enc.ENCPRESANU_ANIO === null || enc.ENCPRESANU_ANIO === undefined || enc.ENCPRESANU_ANIO === '') {
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'INFORMACIÓN',
        detail: 'El campo Año no puede estar vacío'
      });
      this.spin = false;
      return;
    } else if (enc.ENCPRESANU_DESCRIPCION === null || enc.ENCPRESANU_DESCRIPCION === undefined || enc.ENCPRESANU_DESCRIPCION === '') {
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'INFORMACIÓN',
        detail: 'El campo Descripción no puede estar vacío'
      });
      this.spin = false;
      return;
    } else {
      for (const rs of this.conpresuanucenService.detpresuanupuestoarray) {
        if (isNullOrUndefined(rs.DETPRESANUCON_01) || isNullOrUndefined(rs.DETPRESANUCON_02) || isNullOrUndefined(rs.DETPRESANUCON_03) || isNullOrUndefined(rs.DETPRESANUCON_04)
          || isNullOrUndefined(rs.DETPRESANUCON_05) || isNullOrUndefined(rs.DETPRESANUCON_06) || isNullOrUndefined(rs.DETPRESANUCON_07) || isNullOrUndefined(rs.DETPRESANUCON_08)
          || isNullOrUndefined(rs.DETPRESANUCON_09) || isNullOrUndefined(rs.DETPRESANUCON_10) || isNullOrUndefined(rs.DETPRESANUCON_11) || isNullOrUndefined(rs.DETPRESANUCON_12)
          || isNullOrUndefined(rs.DETPRESANUCON_ACM)
        ) {
          this.messageService.add({
            key: 'preanuplanc',
            severity: 'error',
            summary: 'INFORMACIÓN',
            detail: 'Revise los valores de la cuenta ' + rs.CON_CODIGO
          });
          this.spin = false;
          return;
        }
      }
      await this.finguardarCntas();
    }
  }
  async finguardarCntas() {
    let enc: Presuanuencplanc = this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador];
    if (enc.CONTROL === 0) {
      await this.conpresuanucenService.insertarplancenc(enc).catch(e => {
        this.messageService.add({
          key: 'preanuplanc',
          severity: 'error',
          summary: 'Insertar Encabezado Presupuesto',
          detail: e.error
        });
        this.spin = false;
        return
      });
      this.auditoriagral.registrarAuditoria('CON_ENCPRESANUCON', enc.ENCPRESANU_ANIO, 'I',
        '', '01', '', '', '', '').subscribe(() => { });

      for (const rs of this.conpresuanucenService.detpresuanupuestoarray) {
        //rs.CON_DETPRES01=rs.CON_DETPRES01;
        await this.conpresuanucenService.insertarplancdet(rs).catch(e => {
          this.messageService.add({
            key: 'preanuplanc',
            severity: 'error',
            summary: 'Insertar Detalle Presupuesto de la cuenta ' + rs.CON_CODIGO,
            detail: e.error
          });
          this.spin = false;
          return
        });
        this.auditoriagral.registrarAuditoria('CON_DETPRESANUCON', rs.ENCPRESANU_ANIO + '/' + rs.CON_CODIGO + '/' + rs.DETPRESANUCON_ACM, 'I',
          '', '01', '', '', '', '').subscribe(() => { });
      }
    } else {
      await this.conpresuanucenService.actualizarplancenc(enc).catch(e => {
        this.messageService.add({
          key: 'preanuplanc',
          severity: 'error',
          summary: 'Insertar Encabezado Presupuesto',
          detail: e.error
        });
        this.spin = false;
        return
      });
      this.auditoriagral.registrarAuditoria('CON_ENCPRESANUCON', enc.ENCPRESANU_ANIO, 'A',
        '', '01', '', '', '', '').subscribe(() => { });
      for (const rs of this.conpresuanucenService.detpresuanupuestoarray) {
        await this.conpresuanucenService.actualizarplancdet(rs).catch(e => {
          this.messageService.add({
            key: 'preanuplanc',
            severity: 'error',
            summary: 'Insertar Detalle Presupuesto de la cuenta ' + rs.CON_CODIGO,
            detail: e.error
          });
          this.spin = false;
          return
        });
        this.auditoriagral.registrarAuditoria('CON_DETPRESANUCON', rs.ENCPRESANU_ANIO + '/' + rs.CON_CODIGO + '/' + rs.DETPRESANUCON_ACM, 'A',
          '', '01', '', '', '', '').subscribe(() => { });
      }
    }
    this.messageService.add({
      key: 'preanuplanc',
      severity: 'success',
      summary: 'Insertar Presupuesto',
      detail: 'Se guardó con éxito el presupuesto'
    });
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.spin = false;
    await this.busquedaanio(enc.ENCPRESANU_ANIO)
  }
  async guardarcen() {
    let enc: Presuanuencplanc = this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador];
    if (enc.ENCPRESANU_ANIO === null || enc.ENCPRESANU_ANIO === undefined || enc.ENCPRESANU_ANIO === '') {
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'INFORMACIÓN',
        detail: 'El campo Año no puede estar vacío'
      });
      this.spin = false;
      return;
    } else if (enc.ENCPRESANU_DESCRIPCION === null || enc.ENCPRESANU_DESCRIPCION === undefined || enc.ENCPRESANU_DESCRIPCION === '') {
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'INFORMACIÓN',
        detail: 'El campo Descripción no puede estar vacío'
      });
      this.spin = false;
      return;
    } else {
      for (const rs of this.conpresuanucenService.detpresuanupuestocenarray) {
        if (isNullOrUndefined(rs.DETPRESANUCEN_01) || isNullOrUndefined(rs.DETPRESANUCEN_02) || isNullOrUndefined(rs.DETPRESANUCEN_03) || isNullOrUndefined(rs.DETPRESANUCEN_04)
          || isNullOrUndefined(rs.DETPRESANUCEN_05) || isNullOrUndefined(rs.DETPRESANUCEN_06) || isNullOrUndefined(rs.DETPRESANUCEN_07) || isNullOrUndefined(rs.DETPRESANUCEN_08)
          || isNullOrUndefined(rs.DETPRESANUCEN_09) || isNullOrUndefined(rs.DETPRESANUCEN_10) || isNullOrUndefined(rs.DETPRESANUCEN_11) || isNullOrUndefined(rs.DETPRESANUCEN_12)
          || isNullOrUndefined(rs.DETPRESANUCEN_ACM)
        ) {
          this.messageService.add({
            key: 'preanuplanc',
            severity: 'error',
            summary: 'INFORMACIÓN',
            detail: 'Revise los valores del centro de costos ' + rs.CEN_CODIGO
          });
          this.spin = false;
          return;
        }
      }
      await this.finguardarCen();
    }
  }
  async finguardarCen() {
    try {
      for (const rs of this.conpresuanucenService.detpresuanupuestocenarray) {
        if (rs.NUEVO === 0) {
          await this.conpresuanucenService.insertarplancendet(rs).catch(e => {
            this.messageService.add({
              key: 'preanuplanc',
              severity: 'error',
              summary: 'Insertar Detalle Presupuesto del centro de costos' + rs.CON_CODIGO,
              detail: e.error
            });
            this.spin = false;
            return
          });
          this.auditoriagral.registrarAuditoria('CON_DETPRESANUCEN', rs.ENCPRESANU_ANIO + '/' + rs.CON_CODIGO + '/' + rs.CEN_CODIGO + '/' + rs.DETPRESANUCEN_ACM, 'I',
            '', '01', '', '', '', '').subscribe(() => { });
        } else if (rs.NUEVO === 1) {
          await this.conpresuanucenService.actualizarplancendet(rs).catch(e => {
            this.messageService.add({
              key: 'preanuplanc',
              severity: 'error',
              summary: 'Insertar Detalle Presupuesto del centro de costos' + rs.CON_CODIGO,
              detail: e.error
            });
            this.spin = false;
            return
          });
          this.auditoriagral.registrarAuditoria('CON_DETPRESANUCEN', rs.ENCPRESANU_ANIO + '/' + rs.CON_CODIGO + '/' + rs.CEN_CODIGO + '/' + rs.DETPRESANUCEN_ACM, 'A',
            '', '01', '', '', '', '').subscribe(() => { });
        }
      }

      await this.actualizavalorcnta(this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO);
      await this.busquedaanio(this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO);
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'success',
        summary: 'Insertar Presupuesto',
        detail: 'Se guardó con éxito el presupuesto por centro de costos'
      });
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.spin = false;
    } catch (er) {
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'Insertar Detalle Presupuesto por centro de costos',
        detail: er.error
      });
      this.spin = false;
      return
    }
  }
  async actualizavalorcnta(anio) {
    try {
      await this.conpresuanucenService.actualizavalorcntas(anio, this.detpresuplananuselect.CON_CODIGO).catch(e => {
        this.messageService.add({
          key: 'preanuplanc',
          severity: 'error',
          summary: 'Actualizar Detalle Presupuesto anual de plan de cuentas',
          detail: e.error
        });
        this.spin = false;
        return
      });
    } catch (error) {

    }
  }
  busqueda() {
    this.opcion = 'BUSQUEDA';
    this.types = [
      { label: 'Año', value: 'ENCPRESANU_ANIO' },
      { label: 'Descripcion', value: 'ENCPRESANU_DESCRIPCION' },

    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'CON_ENCPRESANUCON';

    this.busquedaSer.busquedaPresuanucon().subscribe((res: any[]) => {
      if (res !== null && res !== undefined) {
        this.arregloCons = res;
        this.displayDialogBusquedaFast = true;
      } else {
        this.messageService.add({
          key: 'preanuplanc',
          severity: 'error',
          summary: 'INFORMACIÓN',
          detail: 'Aún no hay existe ningún presupuesto'
        });
        this.spin = false;
        return;
      }

    });
  }
  manejarSeleccion(opcion) {
    this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO = opcion.ENCPRESANU_ANIO;
    this.busquedaanio(opcion.ENCPRESANU_ANIO)
    this.displayDialogBusquedaFast = false;
  }
  nuevo() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    this.boolnuevo = false;
    this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador] = {};
    this.conpresuanucenService.detpresuanupuestoarray = [];
    this.conpresuanucenService.detpresuanupuestocenarray = [];
    this.aggrid.habilitarGrid();
    const dato: Presuanuencplanc = {
      ENCPRESANU_ANIO: '',
      ENCPRESANU_DESCRIPCION: '',
      COM_CODIGO: '01',
      ENCPRESANU_CONSOLIDA: 0,
      CONTROL: 0,
    }
    this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador] = dato
    document.getElementById('ENCPRESANU_ANIO').focus();
  }
  cencelar() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    console.log(this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray.length)
    if (this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].CONTROL === 0 ||
      this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray.length === 0) {
      this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador] = {};
      //this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].CONTROL=0
      this.conpresuanucenService.detpresuanupuestoarray = [];
      this.conpresuanucenService.detpresuanupuestocenarray = [];
      this.aggrid.habilitarGrid();
    } else {
      this.busquedaanio(this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO)
    }
  }
  selectedPresuAnuCon(params) {
    if (params.object === undefined || params.object === null) {
      return;
    }
    this.detpresuplananuselect = params.object;

    this.validaedicion();
  }
  async validaedicion() {
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefPresuanucon.objeto].getFocusedCell() === null) {
      return;
    }
    console.log(this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefPresuanucon.objeto].getFocusedCell().column)

    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefPresuanucon.objeto].getFocusedCell().column === null) {
      return;
    }
    const colid = this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefPresuanucon.objeto].getFocusedCell().column.colId
    // if (this.detpresuplanselect.NUEVO === true) {
    this.columnDefspresuAnuCon[0].cellEditor = 'cellPrueba';
    this.columnDefspresuAnuCon[1].cellEditor = 'cellPrueba';
    this.columnDefspresuAnuCon[2].cellEditor = 'cellPrueba';
    this.columnDefspresuAnuCon[3].cellEditor = 'cellPrueba';
    this.columnDefspresuAnuCon[4].cellEditor = 'cellPrueba';
    this.columnDefspresuAnuCon[5].cellEditor = 'cellPrueba';
    this.columnDefspresuAnuCon[6].cellEditor = 'cellPrueba';
    this.columnDefspresuAnuCon[7].cellEditor = 'cellPrueba';

    this.columnDefspresuAnuCon[2].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[3].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[4].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[5].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[6].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[7].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[8].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[9].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[10].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[11].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[12].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuAnuCon[13].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.aggrid.refreshColumnDefs();
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefPresuanucon.objeto].setFocusedCell(
      this.conpresuanucenService.detpresuanupuestoarray.indexOf(this.detpresuplananuselect), colid);
    //}
    ///------- carga los centros de costos
    if (colid === 'CON_CODIGO' && this.detpresuplananuselect.CON_CENTRO === 'Si') {
      this.cuentaContableAux = this.detpresuplananuselect.CON_CODIGO;
      this.nombreCuentaAux = this.detpresuplananuselect.CON_NOMBRE;
      const rs1 = await this.conpresuanucenService.cargarcentro(this.detpresuplananuselect.CON_CODIGO, this.detpresuplananuselect.ENCPRESANU_ANIO)
      if (rs1 !== null && rs1 !== undefined && rs1.length > 0) {
        this.conpresuanucenService.detpresuanupuestocenarray = rs1
        for (const item of this.conpresuanucenService.detpresuanupuestocenarray) {
          item.DETPRESANUCEN_01 = Number(item.DETPRESANUCEN_01).toFixed(2)
          item.DETPRESANUCEN_02 = Number(item.DETPRESANUCEN_02).toFixed(2)
          item.DETPRESANUCEN_03 = Number(item.DETPRESANUCEN_03).toFixed(2)
          item.DETPRESANUCEN_04 = Number(item.DETPRESANUCEN_04).toFixed(2)
          item.DETPRESANUCEN_05 = Number(item.DETPRESANUCEN_05).toFixed(2)
          item.DETPRESANUCEN_06 = Number(item.DETPRESANUCEN_06).toFixed(2)
          item.DETPRESANUCEN_07 = Number(item.DETPRESANUCEN_07).toFixed(2)
          item.DETPRESANUCEN_08 = Number(item.DETPRESANUCEN_08).toFixed(2)
          item.DETPRESANUCEN_09 = Number(item.DETPRESANUCEN_09).toFixed(2)
          item.DETPRESANUCEN_10 = Number(item.DETPRESANUCEN_10).toFixed(2)
          item.DETPRESANUCEN_11 = Number(item.DETPRESANUCEN_11).toFixed(2)
          item.DETPRESANUCEN_12 = Number(item.DETPRESANUCEN_12).toFixed(2)
          item.DETPRESANUCEN_ACM = Number(item.DETPRESANUCEN_ACM).toFixed(2)
        }
      }
    } else {
      this.cuentaContableAux = '';
      this.nombreCuentaAux = '';
      this.conpresuanucenService.detpresuanupuestocenarray = []
    }
  }
  isedit(data) {
    let boolean = true;
    if (data.CON_CODIGO.substr(-1, 1) === '.' || data.CON_CENTRO === 'Si') {
      boolean = false
    }
    return boolean;
  }
  cambioTab() {

  }
  async busquedaanio(parametro) {
    if (this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO === null ||
      this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO === undefined ||
      this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO === '') {
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'INFORMACIÓN',
        detail: 'El campo Año no puede estar vacío'
      });
      this.spin = false;
      return;
    }
    this.conpresuanucenService.detpresuanupuestocenarray = []
    const rs1 = await this.conpresuanucenService.consultaplancenc(parametro);
    const rs = await this.conpresuanucenService.consultaplancdet(parametro);
    if (rs1 !== null && rs1 !== undefined && rs1.length > 0) {
      this.index = 0;
      for (const pre of rs1) {
        pre.ENCPRESANU_ANIO = this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO;
        pre.ENCPRESANU_DESCRIPCION = pre.ENCPRESANU_DESCRIPCION === null || pre.ENCPRESANU_DESCRIPCION === undefined ? '' : pre.ENCPRESANU_DESCRIPCION;

        this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador] = pre;
        if (this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].CONTROL === 1
          || this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_CONSOLIDA === 1) {
          this.boolnuevo = true
        } else {
          this.boolnuevo = false
        }
        if (this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].CONTROL === 0) {
          this.boolcc = true
        } else {
          this.boolcc = false
          this.btnGridBorrar = false;
        }

      }
    } else {
      this.boolnuevo = false;
    }
    if (rs !== null && rs !== undefined && rs.length > 0) {
      this.conpresuanucenService.detpresuanupuestoarray = rs
      for (const item of this.conpresuanucenService.detpresuanupuestoarray) {
        item.DETPRESANUCON_01 = Number(item.DETPRESANUCON_01).toFixed(2)
        item.DETPRESANUCON_02 = Number(item.DETPRESANUCON_02).toFixed(2)
        item.DETPRESANUCON_03 = Number(item.DETPRESANUCON_03).toFixed(2)
        item.DETPRESANUCON_04 = Number(item.DETPRESANUCON_04).toFixed(2)
        item.DETPRESANUCON_05 = Number(item.DETPRESANUCON_05).toFixed(2)
        item.DETPRESANUCON_06 = Number(item.DETPRESANUCON_06).toFixed(2)
        item.DETPRESANUCON_07 = Number(item.DETPRESANUCON_07).toFixed(2)
        item.DETPRESANUCON_08 = Number(item.DETPRESANUCON_08).toFixed(2)
        item.DETPRESANUCON_09 = Number(item.DETPRESANUCON_09).toFixed(2)
        item.DETPRESANUCON_10 = Number(item.DETPRESANUCON_10).toFixed(2)
        item.DETPRESANUCON_11 = Number(item.DETPRESANUCON_11).toFixed(2)
        item.DETPRESANUCON_12 = Number(item.DETPRESANUCON_12).toFixed(2)
        item.DETPRESANUCON_ACM = Number(item.DETPRESANUCON_ACM).toFixed(2)
      }
    }
    this.aggrid.refreshaggrid(this.conpresuanucenService.detpresuanupuestoarray, this.defaultColDefPresuanucon.objeto);
    document.getElementById('ENCPRESANU_DESCRIPCION').focus();
    // if(this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_CONSOLIDA === 1){
    //   this.aggrid.bloquearGrid()
    //   this.boolconsolida=true;
    // }
  }
  selectedPresuAnucen(params) {
    if (params === 'eliminar') {
      if (this.permisoPanu.PERTODO === 1) {
        this.eliminardet()
        return;
      } else {
        if (this.permisoPanu.PERELIMACION === 1) {
          this.eliminardet()
          return;
        } else {
          this.messageService.add({
            key: 'preanuplanc',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de eliminación, acción denegada.'
          });
        }
      }
    }
    if (params === 'abrirpresu') {
      this.abrirpresupuestado();
      return;
    }
    if (params.object !== undefined && params.object !== null) {
      this.detpresuplananucenselect = params.object;

    }
  }
  async eliminardet() {
    if (this.detpresuplananucenselect !== undefined && this.conpresuanucenService.detpresuanupuestocenarray.length > 0) {
      this.confirmationService.confirm({
        message: 'Está seguro de eliminar el registro?',
        header: 'Pregunta',
        icon: 'pi pi-exclamation-triangle',
        key: 'PreAnuPlanc',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          if (this.detpresuplananucenselect.NUEVO !== 0) {
            this.conpresuanucenService.eliminarDetalleCen(this.detpresuplananucenselect).subscribe(() => {

              this.conpresuanucenService.detpresuanupuestocenarray = this.conpresuanucenService.detpresuanupuestocenarray.filter((val, j) => j !==
                this.conpresuanucenService.detpresuanupuestocenarray.indexOf(this.detpresuplananucenselect));

              this.messageService.add({
                key: 'preanuplanc',
                severity: 'success',
                summary: 'Información',
                detail: 'El detalle se eliminó correctamente'
              });
              //CDPJ
              this.auditoriagral.registrarAuditoria('CON_DETPRESANUCEN',
                this.detpresuplananucenselect.ENCPRESANU_ANIO + '/' + this.detpresuplananucenselect.CON_CODIGO + '/' + this.detpresuplananucenselect.CEN_CODIGO, 'E',
                '', '01', '', '', '', '').subscribe();
              //CDPJ
              // if (this.encfacService.detfacarray[this.indicador].length > 0) {
              //   this.detfacSelected = this.encfacService.detfacarray[this.indicador][this.encfacService.detfacarray[this.indicador].length - 1];
              // }

              this.aggrid.refreshaggrid(this.conpresuanucenService.detpresuanupuestocenarray, this.defaultColDefPresuanucen.objeto);

              if (this.conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[this.indicador].ENCPRESANU_ANIO !== '') {
                this.spin = true;
                this.guardarcen();
              }

            });

          } else {
            this.conpresuanucenService.detpresuanupuestocenarray = this.conpresuanucenService.detpresuanupuestocenarray.filter((val, j) => j !==
              this.conpresuanucenService.detpresuanupuestocenarray.indexOf(this.detpresuplananucenselect));
            this.messageService.add({
              key: 'preanuplanc',
              severity: 'success',
              summary: 'Información',
              detail: 'El detalle se eliminó correctamente'
            });

            this.aggrid.refreshaggrid(this.conpresuanucenService.detpresuanupuestocenarray, this.defaultColDefPresuanucen.objeto);
          }

        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }
  async abrirpresupuestado() {
    try {
      if (this.detpresuplananucenselect.NUEVO === 1) {
        const data = await this.conpresuanucenService.consulpresupro(this.detpresuplananucenselect);
        if (data !== undefined && data !== null && data.length > 0) {
          this.conpresuanucenService.detpresuanupuestocenproarray = [];
          this.conpresuanucenService.detpresuanupuestocenproarray = data
          for (const rs of this.conpresuanucenService.detpresuanupuestocenproarray) {
            rs.DETPRESANUCENPRO_VALOR = Number(rs.DETPRESANUCENPRO_VALOR).toFixed(2);
            rs.DETPRESANUCENPRO_REAL = Number(rs.DETPRESANUCENPRO_REAL).toFixed(2);
          }
          this.aggrid.refreshaggrid(this.conpresuanucenService.detpresuanupuestocenproarray, this.defaultColDefPresuanucenpro.objeto);
          this.displayDialogSelectPCC = true;
        }
      }else{
        this.messageService.add({
          key: 'preanuplanc',
          severity: 'error',
          summary: 'Consultar Detalle Presupuesto proyectado',
          detail: 'Debe guardar primero el presupuesto por centro de costos'
        });
        this.spin = false;
        return
      }

    } catch (er) {
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'Consultar Detalle Presupuesto proyectado',
        detail: er.error
      });
      this.spin = false;
      return
    }
  }
  cambio(evento) {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
  }
  manejarSenalesPCC(evento) {
    if (evento === 'Visto') {
      this.spin=true;
      this.guardarcenpro()
    }

    if (evento === 'Salir') {
      this.displayDialogSelectPCC = false;
    }
  }
  async guardarcenpro() {
    try {
      for (const rs of this.conpresuanucenService.detpresuanupuestocenproarray) {
        rs.DETPRESANUCENPRO_VALOR = rs.DETPRESANUCENPRO_VALOR === null || rs.DETPRESANUCENPRO_VALOR === undefined ? 0 : Number(Number(rs.DETPRESANUCENPRO_VALOR).toFixed(2))
        if (rs.NUEVO === 0) {
          await this.conpresuanucenService.insertardetcenpro(rs);
        }
        if (rs.NUEVO === 1) {
          await this.conpresuanucenService.actualizardetcenpro(rs);
        }
      }
      this.messageService.add({
        key: 'selectcenpro',
        severity: 'success',
        summary: 'Presupuesto Poyectado',
        detail: 'Se guardó con éxito el presupuesto proyectado.'
      });
      this.spin=false
      await this.buscarcenpro()
    } catch (er) {
      this.messageService.add({
        key: 'selectcenpro',
        severity: 'error',
        summary: 'Insertar Detalle Presupuesto del presupuesto proyectado',
        detail: er.error
      });
      this.spin = false;
      return
    }
  }
  async buscarcenpro() {
    try {
      const data = await this.conpresuanucenService.consulpresupro(this.detpresuplananucenselect);
      if (data !== undefined && data !== null && data.length > 0) {
        this.conpresuanucenService.detpresuanupuestocenproarray = [];
        this.conpresuanucenService.detpresuanupuestocenproarray = data
        for (const rs of this.conpresuanucenService.detpresuanupuestocenproarray) {
          rs.DETPRESANUCENPRO_VALOR = Number(rs.DETPRESANUCENPRO_VALOR).toFixed(2);
        }
        this.aggrid.refreshaggrid(this.conpresuanucenService.detpresuanupuestocenproarray, this.defaultColDefPresuanucenpro.objeto);
      }
    } catch (er) {
      this.messageService.add({
        key: 'preanuplanc',
        severity: 'error',
        summary: 'Consultar Detalle Presupuesto proyectado',
        detail: er.error
      });
      this.spin = false;
      return
    }
  }
  cambio1(evento) {
  }
  selectedPresuAnuCenPro(evento) {
  }
}
