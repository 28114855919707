import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from 'src/app/usuario';
import { DatePipe } from '@angular/common';
import { NomtransotregresosService } from '../nomservicios/nomtransotregresos.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { MessageService, SelectItem, ConfirmationService } from 'primeng';
import { NommaeEmpleado } from '../nominterfaces/nomempleado';
import { NomencrolService } from '../nomservicios/nomencrol.service';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { FormaPagoData } from '../nominterfaces/nomtransotregresos';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { isNullOrUndefined } from 'util';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';//CDPJ
@Component({
  selector: 'app-nomtransotregresos',
  templateUrl: './nomtransotregresos.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomtransotregresosComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnGuardar = true;
  btnBorrar = false;
  btnRegresar = false;
  btnNuevo = false;

  botonPrimero = false;
  botonAnterior = false;
  botonSiguiente = false;
  botonUltimo = false;
  btnBuscar = false;

  botonnuevo = false;
  botoneliminar = false;
  botoncancelar = true;

  usuarioPermisos = {
    PERACTUALIZACION: 0,
    PERELIMACION: 0,
    PERINSERCION: 0,
    PERSELECCION: 0,
    PERTODO: 0
  }


  fecha = new Date();
  identiUsuario = '';

  nomEmpl: NommaeEmpleado = new NommaeEmpleado;
  ctaContable = {
    COM_CODIGO: '',
    CON_CENTRO: '',
    CON_CODIGO: '',
    CON_NOMBRE: ''
  };
  centroCostos = {
    CEN_CODIGO: '',
    CEN_NOMBRE: ''
  };

  txtENCANTEMP_NUMECUOT = null;
  txtENCANTEMP_NUMDIASVACA = '';

  int_NumCheAut;
  int_NRREFPEM;
  cmbMAECONCPOTREGRE = [];
  conceptoSeleccionado;
  strMAERUBRO_CODRUBR = '';
  cmb_td = [];
  tipSeleccionado;
  txtNumComprobante = '';
  txtNumeroDoc = {
    Enabled: true,
    text: ''
  }
  dTFechaEmision:any = '';
  fraCUOTAS = false;
  fraPERIODO = false;
  fra_dias = false;
  //txtImporteEnabled = true;
  txtImporte = {
    Enabled: true,
    text: ''
  }
  importeAnt = null;
  txtPROcodigo = {
    Enabled: true,
    text: ''
  }
  lblPROnombre = '';
  lblEMP_CODIGO = '';
  txtConcepto = '';
  matNOM_PERIODO = {
    per_codigo: '',
    per_fecini: '',
    per_fecfin: '',
    per_descripcion: ''
  }
  lblasi_nro = '';
  lblEstado = '';
  lblPERIODO_FECHAINI = '';
  txtCON_CODIGO = '';
  strMAECONCPOTREGRE_CODIGO = null;
  lblPERIODO_CODIGO = null;
  strPERIODO_FECFIN = null;
  txtEMP_CENTCOST = null;
  lblSaldo = null;
  saldoAnt = null;
  txtReferenciaAdicional = '';
  /* mat_periodo = {
    per_codigo: 0,
    per_fecini: '',
    per_fecfin: '',
    per_descripcion: ''
  } */

  displayDialogBusquedaEmpleado = false;
  displayDialogBusquedaEgresos = false;
  displayDialogReportes: boolean;

  // reporte
  auxreporte: number = 0;
  strNombreForma: string;

  types: SelectItem[];
  tabla: string;
  consulta: string;
  busquedacampos: any[];
  where: string;
  arregloCons: any[];
  arregloBus: any[];



  opcion: string;
  estadoAsientoCon = false;
  /*Cuenta Contable */
  arregloCuenCont = [];
  inp_cuentaConta: string = "";
  displayDialogBusquedaFastCuenCont: boolean;

  displayDialogBusquedaCentroCostos = false;
  //variables frmNOM_ENCROL_3
  monstrarEncRol3 = false;
  datosRol3 = {
    TIPO_RUBRO: '',
    CODIGO_RUBRO: '',
    TIPO_CALCULO: '',
    dblNUMERO_DIAS: 0,
    lblNUM_DIASVACA: 0,
    lblPERIODO_FECINI: '',
    lblEMP_CODIGO: '',
    txtPERIODO_FECINI: '',
    txtPERIODO_FECFIN: '',
    lblPERIODO_CODIGO: '',
    lblRUBRO_CODRUBR: '',
    lblENCROLINDI_CODIGO: '',
    detrolindi_diasvacagoza: '',
    valor_vacaciones: '',
    fecha_inicio: '',
    fecha_fin: '',
    observacion: ''
  }
  mat_datosVacaciones = {
    valor_vacaciones: null,
    fecha_inicio: null,
    fecha_fin: null,
    observacion: null,
    detrolindi_valovacagoza: null,
    detrolindi_diasvacagoza: null,
  }

  //variables frmNOM_EMPLEADO_2
  displaydialogEgrePerEmpl = false;
  periodoSeleccionado = {
    fechaIni: '',
    fechaFin: '',
    codigo: null
  }
  frmNomEmpleado2 = {
    lblCUOTAS: '',
    lblFECHA: ''
  }

  int_PVCAJADEPV
  control=0;//CDPJ
  spin=false;
  defaultColumnDefFormaPago;
  displaydialogFormaPago = false;
  displaydialogBancos = false;
  displaydialogCodigoCaja = false;
  selectedForPago: FormaPagoData;

  dataFormaPago: FormaPagoData[] = [];
  public frameworkComponents;
  largo = '258';
  rowStyle;
  rowSelection = 'multiple';

  displayAsiContable = false;
  asiento;

  esNuevo = false;
  displayDialogDocumentos = false;

  columDefFormaPago = [
    {
      headerName: 'T', field: 'tipo', cellEditor: 'cellPrueba', width: 50,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 4,
          opcionbuscar: true
        }
      }, onCellValueChanged: (params) => {
        if (params.data.nuevo) {
          if (params.data.tipo === '') {
            this.busquedaFormaPago();
          } else {
            this.despliegaDatosTipoPago(params.data.tipo)
          }

        }

      },
      editable: (params) => {
        return (params.data.nuevo) ? true : false;
      }
    },
    {
      headerName: 'Código', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      },
      editable: (params) => {
        if (params.data.tipo === 'CH' || params.data.tipo === 'DP' || params.data.tipo === 'TF') {
          return false;
        }
        return true
        //return (params.data.nuevo ) ? true : false ;

      }, onCellValueChanged: (params) => {

        this.busquedaCodigoCaja();


      },
    },
    {
      headerName: 'Número', field: 'TRNCAJ_NUMERO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 45
        }
      },
      editable: (params) => {
        if (params.data.tipo === 'CH' || params.data.tipo === 'DP' || params.data.tipo === 'TF') {
          return false;
        }
        return true
        //return (params.data.nuevo ) ? true : false ;

      }
    },
    {
      headerName: 'Ban./T.C.', field: 'banCodigo', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32
        }
      }, onCellValueChanged: (params) => {

        this.busquedaBancos();


      },
      editable: (params) => {
        if (params.data.tipo === 'EF'  /* || params.data.tipo === 'DP' */) {
          return false;
        }
        return true
        //return (params.data.nuevo ) ? true : false ;

      }
    },
    {
      headerName: 'Nombre', field: 'col5', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }, editable: (params) => {
        if (params.data.tipo === 'EF') {
          return false;
        }
        return true
        //return (params.data.nuevo ) ? true : false ;

      }
    },
    {
      headerName: 'NroCheque', field: 'TRNBAN_NUMERO', cellEditor: 'cellPrueba', width: 100,
      editable: (params) => {
        if (params.data.tipo === 'EF') {
          return false;
        }
        return true
        //return (params.data.nuevo ) ? true : false ;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 25,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Fecha', field: 'trnFecha', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 1000,
          tienecalculos: true
        }
      }, editable: true,
    },
    {
      headerName: 'Importe', field: 'trnImporte', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'PE', field: 'TRNBAN_PAGOELEC', cellRendererFramework: AggridBanComponent,
      editable: (params) => {
        if (params.data.tipo === 'EF') {
          return false;
        }
        return true
      }, width: 100, onCellClicked: (params) => {
      }
    },

  ];

  constructor(
    private datePipe: DatePipe,
    public usuario: Usuario,
    private otregresoSrv: NomtransotregresosService,
    private errorService: ErroresBaseDatosService,
    private messageService: MessageService,
    private rolProcesoSrv: NomencrolService,
    private permisosService: PermisosService,
    private init: NuevoComponentService,
    private confirmationService: ConfirmationService,
    private auditoriaGralService: AuditoriagralService,
    private confIniciales: ConfInicialesService,
    private utilitariosService: UtilitariosService,
  ) {
    this.identiUsuario = this.usuario.identificacion;
    this.datePipe.transform(this.fecha, 'dd/MM/yy');
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColumnDefFormaPago = {
      editable: true,
      width: 130,
      objeto: 'otrosEgresos'
    };
  }

  async ngOnInit() {
    this.setFecha();
    await this.getPermisos();
    this.getDataInicial();
  }
  async getPermisos() {

    return await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component).then(res => {



      this.usuarioPermisos = res[0];

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });

  }


  convertDate(date: string) {
    console.log(date);
    if (date === '' || date === undefined || date === null) {

      return '';

    }


    const fecha = new Date(date);
    console.log(fecha)

    let month = '' + (fecha.getMonth() + 1);
    let day = '' + fecha.getDate();
    let year = fecha.getFullYear();

    if (month.length < 2) { month = '0' + month }
    if (day.length < 2) { day = '0' + day }

    return `${day}/${month}/${year}`;
  }

  setFecha() {
    let fecha = new Date();

    this.dTFechaEmision = fecha;
  }

  async manejarSenales(valor) {

    //periodo
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir' 
      && valor !== 'facturasAbonadas' && valor !=='Almacenardoc') {
    //console.log(this.dTFechaEmision);
    let dia = new Date().getDate();
    let mes = new Date().getMonth();
    let anio = new Date().getFullYear();
    // Verifica el periodo contable
    try {
      dia = this.dTFechaEmision.getDate();
      mes = this.dTFechaEmision.getMonth();
      anio = this.dTFechaEmision.getFullYear();
    } catch (e) {
      const tmpfecha = this.dTFechaEmision.substr(0, 10);
      const splfec = tmpfecha.split('/');
      const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
      dia = fecha.getDate();
      mes = fecha.getMonth();
      anio = fecha.getFullYear();
    }

    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      
      this.messageService.add({
        key: 'otrosEgresos',
        severity: 'info',
        summary: 'Información',
        detail: 'Error, fecha debe de estar dentro del período contable'
      });
      return;
    }
  }
    


    switch (valor) {
      case 'Nuevo':
        this.botonPrimero = true;
        this.botonAnterior = true;
        this.botonSiguiente = true;
        this.botonUltimo = true;
        this.btnBuscar = true;
        this.control=0;
        this.btnNuevo = true;
        this.dataFormaPago = [];
        this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);
        this.btnGuardar = false;
        //CDPJ
        this.limpiaCampos()
        this.setFecha();
        //CDPJ
        this.opcionNuevo();
        break;
      case 'Guardar':
        if (this.control === 0){
          this.btnGuardar = true;
          this.control=1;
          this.spin=true;
          if (await this.guardar()) {
            // if (await this.funActuInfoTransVacaPaga()) {
              await this.funGlbVerificaAsientoCuadrado(this.lblasi_nro);
            // }//CDPJ POR CUALQUIER EGRESO QUE SE INGRESABA SE GENERABAN VACACIONES PAGADAS EN EL MAESTRO DE EMPLEADOS
          }
        }
        
        break;
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
      case 'Siguiente':
        this.getSiguiente();
        break;

      case 'Ultimo':
        this.getUltimo();
        break;
      case 'Anterior':
        this.getAnterior();
        break;
      case 'Primero':
        this.getPrimero();
        break;

      case 'Buscar':
        this.busquedaOtrosEgresos();
        break;

      case 'Asiento':
        this.generarAsiento();
        break;

      case 'AsientoVer':
        this.cmdasilote();
        break;

      case 'Cancelar':
        this.botonPrimero = false;
        this.botonAnterior = false;
        this.botonSiguiente = false;
        this.botonUltimo = false;
        this.btnBuscar = false;

        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.btnNuevo = false;
        this.limpiaCampos();
        break;

      case 'Borrar':
        this.confirmationService.confirm({
          message: '¿Está seguro que desea eliminar el registro?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmotrosEgresos',
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            this.funValidaAntNoLigCierrCaj()

          },
          reject: () => {
          }
        })

        break;
      case 'Imprimir':
        this.strNombreForma = this.init.tabs[this.init.tabindex].component;
        this.auxreporte++;
        this.displayDialogReportes = true;
        break;
      case 'Almacenardoc':
          if (this.txtNumeroDoc.text !== '') {
            const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
            if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
              this.displayDialogDocumentos = true;
            } else {
              this.messageService.add({
                key: 'otrosEgresos',
                severity: 'info',
                summary: 'Información',
                detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
              });
            }
            
          }
        break;
    }
  }

  cmdasilote() {

    if (this.lblasi_nro === '') {
      this.mensajeAlerta('error', 'Error', 'No ha generado el Asiento Contable');
    } else {
      this.asiento = this.lblasi_nro;
      this.displayAsiContable = true;
    }

  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }


  async generarAsiento() {
    return await this.otregresoSrv.generarAsiento(this.txtNumeroDoc.text, this.lblEMP_CODIGO, this.tipSeleccionado.TIPDIA_CODIGO).then(res => {

      this.lblasi_nro = res[0][':B1'];
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Error generando el asiento`);
      this.control=0;
      this.spin=false;
    });
  }

  async GeneraCuotasEgresosFijos() {
    if (this.strMAERUBRO_CODRUBR !== 'OEF') {
      return true;
    }
    if (Number(this.txtImporte.text) !== Number(this.lblSaldo)) {
      this.mensajeAlerta('warn', 'Información', `Error prestamo tiene abonos no puede realizar esta transacción.`);
      this.spin=false;
      return true;
    }

    try {
      await this.otregresoSrv.generarCuotaEgreFijo(this.txtNumeroDoc.text);
      await this.auditoriaGralService.registrarAuditoria('NOM_ENCANTICIPO-EGRESO FIJO', this.txtNumeroDoc.text, ' I',
      this.usuario.identificacion, '01', '', '', '', '').toPromise();
      return true;
    } catch (err) {
      let mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', `Error al generar las cuotas de Egresos Fijos`);
      this.control=0;
      this.spin=false;
      return false;
    }

    
  }

  busquedaOtrosEgresos() {
    this.types = [

      { label: 'NroDocumento', value: 'ENCANTEMP_NRODOCUMENTO' },
      { label: 'Comprobante', value: 'ENCANTEMP_NROCOMPROBANTE' },
      { label: 'FechaEmisión', value: 'ENCANTEMP_FECHAEMISION' },
      { label: 'CódigoEmpleado', value: 'EMP_CODIGO' },
      { label: 'NombreEmpleado', value: 'EMPLEADO' },
      { label: 'Importe', value: 'ENCANTEMP_IMPORTE' },
      { label: 'Saldo', value: 'ENCANTEMP_SALDO' }


    ];
    this.busquedacampos = ['', '', '', '', '', '', ''];
    this.consulta = `ENCANTEMP_NRODOCUMENTO, ENCANTEMP_NROCOMPROBANTE, ENCANTEMP_FECHAEMISION, EMP_CODIGO, ENCANTEMP_IMPORTE, ENCANTEMP_SALDO,  (select EMP_NOMBRE || ' ' || EMP_APELLIDO from nom_maeempleado e where a.emp_codigo = e.emp_codigo) as EMPLEADO`;
    this.tabla = 'NOM_ENCANTICIPO a';
    this.where = 'rownum < 200';

    this.otregresoSrv.busquedaOtrosEgresos(this.consulta, this.where).then((res: any[]) => {


      res.map(x => {
        x.ENCANTEMP_FECHAEMISION = this.convertDate(x.ENCANTEMP_FECHAEMISION);
      })


      this.arregloCons = [...res];
      this.displayDialogBusquedaEgresos = true;
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  manejarSeleccionEgresos(dato) {
    this.llenarDatos(dato.ENCANTEMP_NRODOCUMENTO);
    this.displayDialogBusquedaEgresos = false;
  }

  async getDataInicial() {
    this.int_NumCheAut = await this.funGlbObtieneCnfgSistema("NUMCHEAUT")
    this.int_NRREFPEM = await this.funGlbObtieneCnfgSistema("NRREFPEM")
    await this.funListaConceptoOtroEgresos();
    await this.funDesplietaTipDiario();
    await this.obtiePeriodo();
  }

  async funGlbObtieneCnfgSistema(cfg_codigo: string) {
    let codigo = 0;
    codigo = await this.otregresoSrv.funGlbObtieneCnfgSistema(cfg_codigo).then(res => {

      if (res.length === 0 || res === null) {
        return 0;
      }
      return res[0].CFG_VALOR1;
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
    return codigo;
  }


  async funListaConceptoOtroEgresos() { // llenar conceptos
    let concepto = {
      COM_CODIGO: null,
      MAECONCPOTREGRE_CODIGO: null,
      MAECONCPOTREGRE_CTACONT: null,
      MAECONCPOTREGRE_LIQUROL: null,
      MAECONCPOTREGRE_NOMBRE: null,
      MAERUBRO_CODIGO: null,
      MAERUBRO_CODRUBR: null,
    }
    await this.otregresoSrv.funListaConceptoOtroEgresos().then(conc => {

      const concVaca = conc.find(x => x.MAECONCPOTREGRE_CODIGO === 6)

      const index = conc.indexOf(concVaca);

      if (index > -1) {
        conc.splice(index, 1);

      }




      this.cmbMAECONCPOTREGRE = [concepto, ...conc]
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });

  }

  async funDesplietaTipDiario() { //llenar tip
    let tip = { TIPDIA_CODIGO: null }
    await this.otregresoSrv.funDesplietaTipDiario().then(tips => {
      this.cmb_td = [tip, ...tips]
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  async obtiePeriodo() {
    await this.otregresoSrv.funNomGlbObtienePeriodo().then(r => {

      this.matNOM_PERIODO.per_codigo = r[0].PERIODO_CODIGO;
      this.matNOM_PERIODO.per_fecini = this.convertDate(r[0].PERIODO_FECINI);
      this.matNOM_PERIODO.per_fecfin = this.convertDate(r[0].PERIODO_FECFIN);
      this.matNOM_PERIODO.per_descripcion = r[0].PERIODO_DESPRICION;
      this.txtPROcodigo.Enabled = true;
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }


  async selecConcepto(event) {
    this.funObtieneCtaContableConcepto()

  }
  async funObtieneCtaContableConcepto() {
    return await this.otregresoSrv.funObtieneCtaContableConcepto(this.conceptoSeleccionado.MAECONCPOTREGRE_CODIGO).then(async res => {


      if (res !== null || res.length > 0) {
        this.strMAERUBRO_CODRUBR = res[0].MAERUBRO_CODRUBR;
      } else {
        this.strMAERUBRO_CODRUBR = '';
      }

      return await this.funDespliegaCamposPrestamosFijos();
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  funDespliegaCamposPrestamosFijos() {
    this.fraCUOTAS = false;
    this.fraPERIODO = false;
    this.fra_dias = false;
    this.txtImporte.Enabled = true
    switch (this.strMAERUBRO_CODRUBR) {
      case 'OEF':

        this.fraCUOTAS = true;
        this.fraPERIODO = true;
        break;
      case 'ANTOE':

        this.fraCUOTAS = false;
        this.fraPERIODO = true;
        break;
      case 'ASUE':

        this.fraCUOTAS = false;
        this.fraPERIODO = true;
        break;
      case 'VACP':

        //fra_dias.Left = fraCUOTAS.Left
        //fra_dias.Top = fraCUOTAS.Top
        this.fraPERIODO = true;
        this.txtImporte.Enabled = false;
        this.fra_dias = true;
        break;
      default:

        this.fraCUOTAS = false;
        this.fraPERIODO = false;
        this.fra_dias = false;
        break;
    }

    return;

  }

  async selecTip(event) {
    await this.otregresoSrv.getNroAsi(this.tipSeleccionado.TIPDIA_CODIGO).then(nro_asi => {

      this.txtNumComprobante = nro_asi[0][`CON_FC_OBTIENE_NRO_ASI('${this.tipSeleccionado.TIPDIA_CODIGO}','01')`]
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  busquedaEmpleado() {
    this.types = [
      { label: 'CÓDIGO', value: 'EMP_CODIGO' },
      { label: 'NOMBRES', value: 'EMP_NOMBRE' },
      { label: 'APELLIDOS', value: 'EMP_APELLIDO' },
      { label: 'RucIde', value: 'EMP_RUCIDE' },
      { label: 'Ingreso', value: 'EMP_FECINGRESO' }


    ];
    this.busquedacampos = ['', '', '', '', '', ''];
    this.consulta = `emp_codigo,emp_rucide,emp_nombre,emp_apellido,EMP_FECINGRESO,EMP_ACTIVO, com_codigo`;
    this.tabla = 'NOM_MAEEMPLEADO';
    let condicionCodigo = (this.txtPROcodigo.text.length === 10) ? `and EMP_RUCIDE like '${this.txtPROcodigo}'` : '';
    this.where = `com_codigo = '01' and rownum < 200 ${condicionCodigo}`;

    this.otregresoSrv.busquedaEmpleado(this.consulta, this.where).then((res: any[]) => {


      res.map(emp => {
        emp.EMP_FECINGRESO = this.convertDate(emp.EMP_FECINGRESO);
      })

      this.arregloCons = [...res];
      this.displayDialogBusquedaEmpleado = true;
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  manejarSeleccionEmpleado(empleado) {
    this.nomEmpl = empleado;
    this.lblEMP_CODIGO = empleado.EMP_CODIGO;
    this.lblPROnombre = empleado.EMP_APELLIDO + ' ' + empleado.EMP_NOMBRE;
    this.txtPROcodigo.text = empleado.EMP_RUCIDE;
    this.displayDialogBusquedaEmpleado = false;
  }



  busCuentaCons(parametro) {
    if (parametro == null || parametro == undefined) {
      parametro = '';
    }
    this.opcion = 'BuscarCuentaCons';
    this.types = [
      { label: 'CODIGO', value: 'CON_CODIGO' },
      { label: 'NOMBRE', value: 'CON_NOMBRE' },
      { label: 'CNT. COSTOS', value: 'CON_CENTRO' },
      //CNT. COSTOS
    ];
    this.busquedacampos = ['', '', ''];
    this.consulta = 'CON_NOMBRE, CON_CODIGO, CON_CENTRO, COM_CODIGO';
    this.tabla = 'CON_MAECON';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\'' + " AND  COM_CODIGO = '01' and rownum < 200 ORDER BY CON_CODIGO";

    this.otregresoSrv.busCuentaCons(this.types[0].value, this.types[0].value +
      ' LIKE \'' + parametro + '\' ').then((res: any[]) => {
        if (res != null) {
          if (res[0] != undefined) {
            if (res[0].CON_CODIGO.substr(-1, 1) === '.') {
              this.otregresoSrv.busquedaTabla(this.consulta, this.where).then((res: any[]) => {
                this.arregloCuenCont = res;
                this.displayDialogBusquedaFastCuenCont = true;
              });
            } else {
              this.nomEmpl.EMP_CONCODIGO = res[0].REF_NOMBRE;
              document.getElementById('inpCargEmpr').focus();
            }
          } else {
            this.otregresoSrv.busquedaTabla(this.consulta, this.where).then((res: any[]) => {
              this.arregloCuenCont = res;
              this.displayDialogBusquedaFastCuenCont = true;
            });
          }
        } else {
          this.otregresoSrv.busquedaTabla(this.consulta, this.where).then((res: any[]) => {
            this.arregloCuenCont = res;
            this.displayDialogBusquedaFastCuenCont = true;
          });
        }
      });
  }

  manejarSeleccionCuenCont(ctaContable) {
    this.ctaContable = ctaContable;
    if (ctaContable.CON_CODIGO.charAt(ctaContable.CON_CODIGO.length - 1) === '.') {
      this.mensajeAlerta('error', 'Error', `No puede seleccionar una cuenta mayor`);

    } else {
      this.txtCON_CODIGO = ctaContable.CON_CODIGO
    }

    this.displayDialogBusquedaFastCuenCont = false;
  }


  busquedaCentroCostos() {
    this.types = [
      { label: 'CÓDIGO', value: 'CEN_CODIGO' },
      { label: 'NOMBRE', value: 'CEN_NOMBRE' }
    ];

    this.busquedacampos = ['', ''];
    this.consulta = `CEN_CODIGO ,CEN_NOMBRE`;
    this.tabla = 'CON_MAECEN';
    this.where = ' ROWNUM<=50 ORDER BY CEN_CODIGO ';


    this.otregresoSrv.busquedaCentroCostos(this.consulta, this.where).then((res: any[]) => {


      this.arregloCons = [...res];
      this.displayDialogBusquedaCentroCostos = true;
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  manejarSeleccionCentroCost(centroCosto) {
    this.centroCostos = centroCosto;
    this.txtEMP_CENTCOST = centroCosto.CEN_CODIGO;
    this.displayDialogBusquedaCentroCostos = false;
  }

  async opcionNuevo() {
    this.esNuevo = true;
    this.tipSeleccionado = {
      TIPDIA_CODIGO: "CEN"
    }
    this.txtImporte.text = '0';
    await this.otregresoSrv.getNumComprobante(this.tipSeleccionado.TIPDIA_CODIGO).then(async  comp => {

      this.txtNumComprobante = comp[0][`CON_FC_OBTIENE_NRO_ASI('${this.tipSeleccionado.TIPDIA_CODIGO}','01')`];
      let secuencial = await this.generarSecuencial();

      this.txtNumeroDoc.text = secuencial;
      this.obtiePeriodo();
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });


  }

  buscarPeriodos() {
    if (this.fra_dias === false) {
      this.funDespligaPantallPeriodoPrestamosFijos();
    } else if (this.txtENCANTEMP_NUMDIASVACA !== '' && Number(this.txtENCANTEMP_NUMDIASVACA) > 0) {
      this.funMuestaFormaVacaciones();
    }
  }

  funDespligaPantallPeriodoPrestamosFijos() {
    switch (this.strMAERUBRO_CODRUBR) {

      case 'ANTOE':
        this.txtENCANTEMP_NUMECUOT = '1';
        this.frmNomEmpleado2.lblCUOTAS = this.txtENCANTEMP_NUMECUOT;
        this.frmNomEmpleado2.lblFECHA = this.dTFechaEmision;
        this.displaydialogEgrePerEmpl = true;
        break;
      case 'ASUE':
        this.txtENCANTEMP_NUMECUOT = '1';
        this.frmNomEmpleado2.lblCUOTAS = this.txtENCANTEMP_NUMECUOT;
        this.frmNomEmpleado2.lblFECHA = this.dTFechaEmision
        this.displaydialogEgrePerEmpl = true;
        break;
      case 'OEF':
        if (this.txtENCANTEMP_NUMECUOT !== '0') {
          this.frmNomEmpleado2.lblCUOTAS = this.txtENCANTEMP_NUMECUOT;
          this.frmNomEmpleado2.lblFECHA = this.dTFechaEmision
          this.displaydialogEgrePerEmpl = true;
        }
        break;
      default:
        break;
    }
  }

  salirEgrePerEmpl(infoPerio) {

    this.displaydialogEgrePerEmpl = false;
  }

  llenarEgrePerEmpl(infoPerio) {
    try {
      this.periodoSeleccionado = {
        fechaIni: infoPerio[0],
        fechaFin: infoPerio[1],
        codigo: infoPerio[2]
      }
      this.lblPERIODO_FECHAINI = infoPerio[0];
      this.lblPERIODO_CODIGO = infoPerio[2];
      this.strPERIODO_FECFIN = infoPerio[1]
    } catch{
      this.periodoSeleccionado = {
        fechaIni: '',
        fechaFin: '',
        codigo: null
      }
    }
  }


  funMuestaFormaVacaciones() {
    if (this.lblEMP_CODIGO === '') {
      this.mensajeAlerta('info', 'Otros Egresos', `Seleccione un empleado`);
    } else {

      this.datosRol3.lblNUM_DIASVACA = Number(this.txtENCANTEMP_NUMDIASVACA),
        this.datosRol3.lblPERIODO_FECINI = this.matNOM_PERIODO.per_fecini,
        this.datosRol3.lblEMP_CODIGO = this.lblEMP_CODIGO,
        this.datosRol3.txtPERIODO_FECINI = this.matNOM_PERIODO.per_fecini,
        this.datosRol3.txtPERIODO_FECFIN = this.matNOM_PERIODO.per_fecfin,
        this.datosRol3.lblPERIODO_CODIGO = this.matNOM_PERIODO.per_codigo,
        this.datosRol3.lblRUBRO_CODRUBR = 'VACP',
        //this.datosRol3.lblENCROLINDI_CODIGO = this.lblENCROLINDI_CODIGO

        this.monstrarEncRol3 = true;
    }
  }

  salirf(elemento) {
    if (!elemento) {//llega false
      this.monstrarEncRol3 = false;
      this.mat_datosVacaciones.fecha_inicio = this.rolProcesoSrv.AUXPANTVACA.fecha_inicio;
      this.mat_datosVacaciones.fecha_fin = this.rolProcesoSrv.AUXPANTVACA.fecha_fin;

      this.mat_datosVacaciones.detrolindi_diasvacagoza = this.rolProcesoSrv.AUXPANTVACA.detrolindi_diasvacagoza;
      this.mat_datosVacaciones.detrolindi_valovacagoza = this.rolProcesoSrv.AUXPANTVACA.valor_vacaciones;
      this.mat_datosVacaciones.observacion = this.rolProcesoSrv.AUXPANTVACA.observacion;

      this.lblPERIODO_FECHAINI = this.rolProcesoSrv.AUXPANTVACA.fecha_inicio;
      this.txtImporte.text = this.rolProcesoSrv.AUXPANTVACA.valor_vacaciones.toFixed(2);
    }
  }

  async generarSecuencial() {
    let secuencial = '';
    secuencial = await this.otregresoSrv.generarSecuencial(this.identiUsuario, 'EGN').then(async  cod => {


      if (cod.length > 0) {
        return `${cod[0].NUM_DOCREF}${cod[0].NUM_CLASE}${cod[0].NUM_SECACTUAL}`
      } else {
        return await this.otregresoSrv.guardarNuevoSecuencial(this.identiUsuario, 'EGN').then((res) => {

          return 'EGN' + this.identiUsuario + '000000001';
        })
      }
      //return'EGN' + this.identiUsuario +  this.otregresoSrv.llenarString( '0', 8 );
    })

    return secuencial;
  }



  /**
   * 
   * BOTONES NAVEGACION
   * 
   */

  async getAnterior() {
    if (this.btnGuardar) {

      await this.otregresoSrv.getAnterior(this.txtNumeroDoc.text).then(cod => {


        if (cod.length > 0) {
          if (cod[0]['ENCANTEMP_NRODOCUMENTO'] !== '') {

            this.llenarDatos(cod[0]['ENCANTEMP_NRODOCUMENTO'])
          }
        }

      }).catch(e => {

        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      });
    }
  }

  async getPrimero() {
    await this.otregresoSrv.getPrimero().then(cod => {


      if (cod.length > 0) {
        if (cod[0]['ENCANTEMP_NRODOCUMENTO'] !== '') {

          this.llenarDatos(cod[0]['ENCANTEMP_NRODOCUMENTO'])
        }
      }

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  async getSiguiente() {
    if (this.btnGuardar) {

      await this.otregresoSrv.getSiguiente(this.txtNumeroDoc.text).then(cod => {


        if (cod.length > 0) {
          if (cod[0]['ENCANTEMP_NRODOCUMENTO'] !== '') {

            this.llenarDatos(cod[0]['ENCANTEMP_NRODOCUMENTO'])
          }
        }

      }).catch(e => {

        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      });
    }
  }

  async getUltimo() {
    await this.otregresoSrv.getUltimo().then(cod => {

      if (cod.length > 0) {
        if (cod[0]['ENCANTEMP_NRODOCUMENTO'] !== '') {

          this.llenarDatos(cod[0]['ENCANTEMP_NRODOCUMENTO'])
        }
      }

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  async llenarDatos(nroDocumento: string) {
    this.esNuevo = false;
    this.limpiaCampos()//CDPJ
    await this.otregresoSrv.consultaLlenarDatos(nroDocumento).then(async r => {

      this.txtPROcodigo.Enabled = false;
      this.txtNumeroDoc.Enabled = false
      this.txtNumeroDoc.text = r[0]['ENCANTEMP_NRODOCUMENTO'];
      this.txtConcepto = r[0]['ENCANTEMP_CONCEPTO'];
      this.txtImporte.text = r[0]['ENCANTEMP_IMPORTE'].toFixed(2);
      this.importeAnt = r[0]['ENCANTEMP_IMPORTE'].toFixed(2);


      this.txtReferenciaAdicional = r[0]['ENCANTEMP_REFERENCIA'];
      this.dTFechaEmision = this.convertDate(r[0]['ENCANTEMP_FECHAEMISION']);
      this.lblEMP_CODIGO = r[0]['EMP_CODIGO'];
      this.lblPROnombre = r[0]['PRO_NOMBRE']
      this.lblasi_nro = r[0]['ASI_NRO'];
      if (r[0]['ENCANTEMP_ESTADO'] === 'A') {
        this.lblEstado = 'Anulado';
      } else {

        this.lblEstado = r[0]['ENCANTEMP_ESTADO'];
      }

      this.conceptoSeleccionado = this.cmbMAECONCPOTREGRE.find(conc => conc.MAECONCPOTREGRE_CODIGO === r[0]['MAECONCPOTREGRE_CODIGO']);


      this.txtNumComprobante = r[0]['ENCANTEMP_NROCOMPROBANTE'];
      this.txtCON_CODIGO = r[0]['CON_CODIGO'];
      this.strMAECONCPOTREGRE_CODIGO = r[0].MAECONCPOTREGRE_CODIGO;
      this.txtENCANTEMP_NUMECUOT = r[0].ENCANTEMP_NUMECUOT;
      this.lblPERIODO_CODIGO = r[0].PERIODO_CODIGO;
      this.lblPERIODO_FECHAINI = this.convertDate(r[0].PERIODO_FECINI);
      this.strMAERUBRO_CODRUBR = r[0].MAERUBRO_CODRUBR;
      this.strPERIODO_FECFIN = this.convertDate(r[0].PERIODO_FECFIN);
      this.fraPERIODO = true;
      this.tipSeleccionado = {
        TIPDIA_CODIGO: r[0].TIPDIA_CODIGO
      };
      this.txtEMP_CENTCOST = r[0].CEN_CODIGO;
      this.lblSaldo = r[0].ENCANTEMP_SALDO.toFixed(2);
      this.saldoAnt = r[0].ENCANTEMP_SALDO.toFixed(2);
      this.txtENCANTEMP_NUMDIASVACA = r[0].ENCANTEMP_NUMDIASVACA;
      this.mat_datosVacaciones.observacion = r[0].ENCANTEMP_OBSEVACAPAGA;
      this.mat_datosVacaciones.detrolindi_valovacagoza = r[0].ENCANTEMP_VALOVACAPAGA;
      this.mat_datosVacaciones.fecha_inicio = r[0].ENCANTEMP_FECINIVACA;
      this.mat_datosVacaciones.fecha_fin = r[0].ENCANTEMP_FECFINVACA;
      let txtReferenciaAdicional = (r[0].ENCANTEMP_REFERENCIA === null) ? '' : r[0].ENCANTEMP_REFERENCIA;
      await this.otregresoSrv.getRucIde('EMP_RUCIDE', `EMP_CODIGO = '${this.lblEMP_CODIGO}'`).then(proCod => {

        this.txtPROcodigo.text = proCod[0]['EMP_RUCIDE']
        return
      });
      this.transaccionesCaja(nroDocumento);
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }




  //TRANSACCIONES DE CAJA

  async transaccionesCaja(codigo: string) {
    await this.otregresoSrv.transaccionesCaja(codigo).then(async  datos => {


      for (let data of datos) {
        let nuevaData: FormaPagoData = {
          tipo: null,
          //TRNPAGO_TIPO: null,
          CAJ_CODIGO: null,
          TRNCAJ_NUMERO: null,
          banCodigo: null,
          col5: null,
          TRNBAN_NUMERO: null,
          TRNPAGO_IMPORTE: null,
          trnFecha: null,
          trnImporte: null,
          CON_CODIGO: null,
          TRNBAN_PAGOELEC: null,
          caja: true
        }
        nuevaData.tipo = data['TRNCAJ_TIPO'];
        nuevaData.CAJ_CODIGO = data['CAJ_CODIGO'];
        nuevaData.TRNCAJ_NUMERO = data['TRNCAJ_NUMERO']
        nuevaData.banCodigo = data['BANCLI_CODIGO'];

        if (data['TRNCAJ_TIPO'] === 'CH' || data['TRNCAJ_TIPO'] === 'DP' || data['TRNCAJ_TIPO'] === 'TF') {

          let infoBanco = await this.otregresoSrv.getBANCLI_NOMBRE(data['BANCLI_CODIGO']);

          nuevaData.col5 = infoBanco[0].BANCLI_NOMBRE
        } else if (data['TRNCAJ_TIPO'] === 'TA') {
          let infoBanco = await this.otregresoSrv.getTAR_DESCRIPCION(data['BANCLI_CODIGO']);

          nuevaData.col5 = infoBanco[0].TAR_DESCRIPCION
        }
        nuevaData.trnFecha = this.convertDate(data.TRNCAJ_FECHA);
        nuevaData.trnImporte = data.TRNCAJ_IMPORTE * -1;
        this.dataFormaPago.push(nuevaData);
      }
      this.transaccionesBancos(codigo);
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  async transaccionesBancos(codigo) {
    await this.otregresoSrv.transaccionesBancos(codigo).then(async datos => {

      for (let data of datos) {
        let nuevaData: FormaPagoData = {
          tipo: null,
          //TRNPAGO_TIPO: null,
          CAJ_CODIGO: null,
          TRNCAJ_NUMERO: null,
          banCodigo: null,
          col5: null,
          TRNBAN_NUMERO: null,
          TRNPAGO_IMPORTE: null,
          trnFecha: null,
          trnImporte: null,
          CON_CODIGO: null,
          TRNBAN_PAGOELEC: null,
          bancos: true
        }
        if (data['TRNBAN_TIPO'] === 'RE') {
          nuevaData.tipo = 'TF';
        } else {
          nuevaData.tipo = data['TRNBAN_TIPO'];
        }
        nuevaData.banCodigo = data.BAN_CODIGO;
        if (data['TRNBAN_TIPO'] === 'CH' || data['TRNBAN_TIPO'] === 'DP' || data['TRNBAN_TIPO'] === 'RE') {

          let infoBanco = await this.otregresoSrv.getBanco(`BAN_CODIGO= '${data['BAN_CODIGO']}'`);

          nuevaData.col5 = infoBanco[0].BAN_BANCO
        } else if (data[0]['TRNBAN_TIPO'] === 'TA') {
          let infoBanco = await this.otregresoSrv.getTarjeta(`TAR_CODIGO= '${data['BAN_CODIGO']}'`);

          nuevaData.col5 = infoBanco[0].TAR_DESCRIPCION
        }

        nuevaData.TRNBAN_NUMERO = data.TRNBAN_NUMERO;
        nuevaData.trnFecha = this.convertDate(data.TRNBAN_FECHA);
        nuevaData.trnImporte = data.TRNBAN_IMPORTE * -1;
        nuevaData.CON_CODIGO = data.CON_CODIGO;
        nuevaData.TRNBAN_PAGOELEC = data.TRNBAN_PAGOELEC;

        this.dataFormaPago.push(nuevaData);
      }
      this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);


      this.funGlbVerificarAsientoLinea();
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  async funGlbVerificarAsientoLinea() {
    await this.otregresoSrv.funGlbVerificarAsientoLinea(this.lblasi_nro).then(res => {

      if (res.length > 0) {
        this.estadoAsientoCon = (res[0].ASI_ESTADO === 1) ? true : false;
      }

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }


  cambio(event) {
  }
  seleccionFormaPago(valor) {
    if (valor.nameobject !== undefined && valor.object !== undefined) {
      this.selectedForPago = valor.object;
    } else {

      switch (valor) {
        case 'nuevo':
          this.nuevaLineaDetalle();
          break;

        case 'eliminar':
          this.confirmationService.confirm({
            message: '¿Está seguro que desea eliminar el registro?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            key: 'confirmotrosEgresos',
            acceptLabel: 'Sí',
            rejectLabel: 'No',
            accept: () => {
              this.cmdBorrar();

            },
            reject: () => {
            }
          })
          break;

      }
    }
  }


  setSaldo() {
    this.lblSaldo = this.txtImporte.text;
  }


  despliegaDatosTipoPago(tipoPago: string) {

    switch (tipoPago) {
      case 'EF':
        this.obtieneDatosEfectivo();
        break;
      case 'CH':
        break;
      case 'DP':
        break;
      case 'TF':
        break;


      default:
        this.busquedaFormaPago();
        break;
    }
  }

  /* async obtieneDatos(){
    let caja = '';
    if( this.int_PVCAJADEPV = 1 ){
       await this.otregresoSrv.getcajCodigoVendedor().then(async  caj => {

      }) 
    }else{
      await this.otregresoSrv.getcajCodigoFormaPago().then(async  caj => {

      }).catch( e => {
      
      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    } );
    }
  } */

  async obtieneDatosEfectivo() {
    await this.otregresoSrv.getcajCodigoFormaPago().then(async  caj => {
      let fecha = new Date();
      let date = this.datePipe.transform(fecha, 'dd/MM/yyyy');
      let hours = this.datePipe.transform(fecha, 'hh:mm:ss');
      let dateSplit = date.split("/");
      let hoursSplit = hours.split(":");
      this.selectedForPago.CAJ_CODIGO = caj[0].CAJ_CODIGO;
      this.selectedForPago.TRNCAJ_NUMERO = `${dateSplit[0]}${dateSplit[1]}${dateSplit[2]}${hoursSplit[0]}${hoursSplit[1]}${hoursSplit[2]}`;
      this.selectedForPago.CON_CODIGO = await this.otregresoSrv.funGlbObtieneCuentaCaja(caj[0].CAJ_CODIGO).then(r => {
        if (r.length > 0 && r !== null) {
          return (r[0].CON_CODIGO === null) ? '' : r[0].CON_CODIGO;
        }
        return '';

      })
      this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  nuevaLineaDetalle() {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      this.defaultColumnDefFormaPago.objeto].stopEditing();
    let importe = this.saldoXPagarFacPantalla(Number(this.txtImporte.text));

    if (importe <= 0 || this.lblPERIODO_FECHAINI === '') {
      return;
    }
    let nuevaData: FormaPagoData = {
      tipo: null,
      //TRNPAGO_TIPO: null,
      CAJ_CODIGO: null,
      TRNCAJ_NUMERO: null,
      banCodigo: null,
      col5: null,
      TRNBAN_NUMERO: null,
      TRNPAGO_IMPORTE: null,
      trnFecha: this.convertDate(this.dTFechaEmision),
      trnImporte: importe,
      CON_CODIGO: null,
      TRNBAN_PAGOELEC: null,
      nuevo: true
    }
    

    const indi = this.dataFormaPago.indexOf(this.selectedForPago);
    if (indi === -1) {
      this.dataFormaPago.push(nuevaData);
      this.selectedForPago = this.dataFormaPago[this.dataFormaPago.length - 1];
      this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefFormaPago.objeto].setFocusedCell(
        this.dataFormaPago.length - 1, 'tipo');
    } else {
      this.selectedForPago= nuevaData;
      this.dataFormaPago.splice(indi + 1, 0, nuevaData);
      this.aggrid.refreshaggridindex(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefFormaPago.objeto].setFocusedCell(
        indi + 1, 'tipo');
    }
    
    
    // this.dataFormaPago.push(nuevaData);
    // this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);
  }


  saldoXPagarFacPantalla(totalAPagar: number) {
    let total = 0;
    this.dataFormaPago.map(d => {
      total = total + Number(d.trnImporte);
    });
    return (totalAPagar - total);
  }


  busquedaFormaPago() {
    this.types = [
      { label: 'Forma de Pago', value: 'FORMAPAGO_NOMBRE' }
    ];

    this.busquedacampos = [''];
    this.consulta = `*`;
    this.tabla = 'NOM_maeformapago';
    this.where = '';


    this.otregresoSrv.busquedaFormaPago().then((res: any[]) => {


      this.arregloCons = [...res];
      this.displaydialogFormaPago = true;
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  manejarSeleccionFormaPago(formaPago) {
    console.log(formaPago);
    this.selectedForPago.tipo = formaPago.FORMAPAGO_TIPO;
    //this.selectedForPago.col5 = formaPago.col5;
    this.despliegaDatosTipoPago(this.selectedForPago.tipo)
    this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);
    console.log(this.dataFormaPago);
    this.displaydialogFormaPago = false;
  }


  busquedaBancos() {
    this.types = [
      { label: 'Código', value: 'BAN_CODIGO' },
      { label: 'Banco', value: 'BAN_BANCO' },
      { label: 'Nro.Cuenta', value: 'BAN_CTANRO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.consulta = `*`;
    this.tabla = 'ban_maeban';
    this.where = '';


    this.otregresoSrv.busquedaBancos().then((res: any[]) => {
      this.arregloCons = [...res];
      this.displaydialogBancos = true;
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  manejarSeleccionBancos(formaPago) {
    this.selectedForPago.banCodigo = formaPago.BAN_CODIGO;
    this.selectedForPago.col5 = formaPago.BAN_BANCO;
    this.selectedForPago.CON_CODIGO = formaPago.CON_CODIGO;
    //this.selectedForPago.TRNBAN_NUMERO = Number( formaPago.BAN_ULTIMOCHEQUE) +1;

    if (this.selectedForPago.tipo === 'CH') {

      this.funcObtieneNumeroCheque(formaPago.BAN_CODIGO);
    }
    this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);
    this.displaydialogBancos = false;
  }



  async guardar() {
    if (Number(this.txtImporte) < 0) {
      this.mensajeAlerta('error', 'Error', `El importe no puede ser menor a cero`);
      this.control=0;
      this.btnGuardar = false;
      this.spin=false;
      return false;
    }
      //CDPJ
      if (this.txtCON_CODIGO === null || this.txtCON_CODIGO === undefined || this.txtCON_CODIGO ==='') {
        this.mensajeAlerta('error', 'Error', `Ingrese la cuenta contable.`);
        this.control=0;
        this.btnGuardar = false;
        this.spin=false;
        return false;
      }
      //CDPJ
    if (await this.verificarTotalImporte()) {
      this.mensajeAlerta('error', 'Error', `El Total detallado en la forma de pago no es igual al Total importe`);
      this.control=0;
      this.btnGuardar = false;
      this.spin=false;
      return false;
    }
    if (await this.faltaCampos()) { //Valida que los datos de la cabecera esten ingresados
      if (this.esNuevo) {
        if (this.usuarioPermisos.PERINSERCION === 0) {
          this.mensajeAlerta('error', 'Error', `No posee permiso de Inserción. Consulte con el Administrador del Sistema.`);
          this.control=0;
          this.btnGuardar = false;
          this.spin=false;
          return false;
        }
      } else {
        if (this.usuarioPermisos.PERACTUALIZACION === 0) {
          this.mensajeAlerta('error', 'Error', `No posee permiso de Actualización. Consulte con el Administrador del Sistema.`);
          this.control=0;
          this.btnGuardar = false;
          this.spin=false;
          return false;
        }
      }

      if (this.txtNumComprobante === '') {

        this.txtNumComprobante = this.txtNumeroDoc.text

      }
      if (!this.verifacNumChyTransferencia()) {
        this.control=0;
        this.btnGuardar = false;
        this.spin=false;
        return false;
      }

      if (this.esNuevo) {
        let resol: boolean;
        resol = await this.otregresoSrv.nuevoRegistro(this.txtNumeroDoc.text, this.lblEMP_CODIGO, this.txtConcepto, Number(this.txtImporte.text),
          this.convertDate(this.dTFechaEmision), this.txtNumComprobante, this.txtReferenciaAdicional,
          this.txtCON_CODIGO, this.conceptoSeleccionado.MAECONCPOTREGRE_CODIGO, this.txtENCANTEMP_NUMECUOT,
          this.lblPERIODO_CODIGO, this.lblPERIODO_FECHAINI, this.strMAERUBRO_CODRUBR, this.strPERIODO_FECFIN, this.tipSeleccionado.TIPDIA_CODIGO,
          this.txtEMP_CENTCOST).then(async () => {
            await this.actualizarNumero();
            console.log('Actualiza numero ');
            this.txtNumeroDoc.Enabled = false;
            this.txtPROcodigo.Enabled = false;
            await this.otregresoSrv.funGlbBorraTrnDocumentoYTrnCajaBan(this.txtNumeroDoc.text);
            console.log('borra trncaja ban ');
            return await this.insertaBanTrnCaj();
            
          }).catch(e => {

            /* let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error); */
            this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción:`);
            this.control=0;
            this.btnGuardar = false;
            this.spin=false;
            return false;
          });
        
        return resol;
      } else {
        return await this.actualizaRegistro();
      }


    }
  }

  async actualizaRegistro() {
    let resol: boolean;
    resol = await this.otregresoSrv.actualizaRegistro(this.txtNumeroDoc.text, this.txtConcepto, Number(this.txtImporte),
      this.dTFechaEmision, this.txtNumComprobante, this.txtReferenciaAdicional, this.conceptoSeleccionado.MAECONCPOTREGRE_CODIGO, this.txtENCANTEMP_NUMECUOT,
      this.lblPERIODO_CODIGO, this.lblPERIODO_FECHAINI, this.strMAERUBRO_CODRUBR, this.strPERIODO_FECFIN,
      this.txtEMP_CENTCOST).then(async () => {
        await this.otregresoSrv.actualizarSaldoAnticipos(this.txtNumeroDoc);
        return await this.insertaBanTrnCaj();
      }).catch(e => {

        let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
        this.control=0;
        this.btnGuardar = false;
        this.spin=false;
        return false;
        
      });
    
    return resol;
  }

  //INSERTA BAN_TRNCAJ( guardar datos del detalle)
  async insertaBanTrnCaj() {
    let resolve = true;
    for (let formaPago of this.dataFormaPago) {
      console.log(formaPago);
      if ((formaPago.tipo !== '' && formaPago.tipo !== null) && formaPago.trnImporte !== 0) {
        if (formaPago.tipo === 'EF' || formaPago.tipo === 'CH' || formaPago.tipo === 'DP' || formaPago.tipo === 'TF') {
          if (formaPago.tipo === 'EF') {
            console.log('Ingresa al If.');
            await this.otregresoSrv.insertarCajaMovimiento(formaPago.CAJ_CODIGO, formaPago.trnFecha, formaPago.tipo, formaPago.TRNCAJ_NUMERO, this.txtNumeroDoc.text, formaPago.trnImporte).then(async () => {
              console.log('Inserta CajaBan');
              // auditoria

              return await this.auditoriaGralService.registrarAuditoria('BAN_TRNCAJ', this.txtNumeroDoc.text + '/' + formaPago.tipo, ' I',
                this.usuario.identificacion, '01', '', '', '', '').toPromise();

            }

            )
              .catch(e => {

                let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
                this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
                this.control=0;
                this.btnGuardar = false;
                this.spin=false;
                resolve = false;
              });
          } else if (formaPago.tipo === 'CH' || formaPago.tipo === 'DP' || formaPago.tipo === 'TF') {
            let parametrosSp3;
            if (formaPago.tipo === 'CH') {
              parametrosSp3 = formaPago.tipo;
            } else {
              parametrosSp3 = 'RE';
            }
            await this.otregresoSrv.insertarBancoMovimiento(parametrosSp3, formaPago.banCodigo, formaPago.trnFecha, formaPago.TRNBAN_NUMERO, this.txtNumeroDoc.text, formaPago.trnImporte, this.lblPROnombre, formaPago.CON_CODIGO, formaPago.TRNBAN_PAGOELEC, this.txtPROcodigo.text).then(async (res) => {
              return await this.auditoriaGralService.registrarAuditoria('BAN_TRNBAN', this.txtNumeroDoc.text + '/' + formaPago.tipo, ' I',
                this.usuario.identificacion, '01', '', '', '', '').toPromise();
            }).catch(e => {

              let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
              this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
              this.control=0;
              this.btnGuardar = false;
              this.spin=false;
              resolve = false;
            });

          }
        }
      }
      if (!resolve) { break; }
    }
    return resolve;
  }



  verificarTotalImporte() {
    let totalImporte = 0
    this.dataFormaPago.map(d => {
      totalImporte = totalImporte + Number(d.trnImporte);
    })
    // if (totalImporte !== Number(this.txtImporte)) {
    //   return false;
    // }

    // return true;
    if (totalImporte !== Number(this.txtImporte.text)) {
      return true;
    }

    return false;
  }

  async faltaCampos() {
    if (this.txtPROcodigo.text === '') {
      this.mensajeAlerta('error', 'Error', `Ingrese el código del Empleado`);
      return false;

    }
    if (Number(this.txtImporte) === 0 || this.txtImporte.text === '') {
      this.mensajeAlerta('error', 'Error', `Ingrese el valor del importe`);
      return;
    }
    let validar = await this.otregresoSrv.encontrarEmpleado(this.lblEMP_CODIGO).then(res => {
      if (res === null || res.length === 0) {
        this.mensajeAlerta('error', 'Error', `El Código del Empleado no existe`);
        return false;
      }
      return true;
    }).catch(e => {


      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      return false
    });

    if (!validar) {
      return false;
    }

    if (this.txtConcepto === '') {
      this.mensajeAlerta('error', 'Error', `Ingrese la descripción`);
      return false;
    }


    await this.funObtieneCtaContableConcepto();
    switch (this.strMAERUBRO_CODRUBR) {
      case 'OEF':
        if (this.txtENCANTEMP_NUMECUOT === '' || Number(this.txtENCANTEMP_NUMECUOT) <= 0) {
          this.mensajeAlerta('error', 'Error', `Ingrese un número de cuotas válido`);
          return false;
        }
        if (Number(this.lblPERIODO_CODIGO) === 0 || this.lblPERIODO_CODIGO === null || this.lblPERIODO_FECHAINI === '') {
          this.mensajeAlerta('error', 'Error', `Ingrese el período`);
          return false;
        }
        break;
      case 'ANTOE':
        if (Number(this.lblPERIODO_CODIGO) === 0 || this.lblPERIODO_CODIGO === null || this.lblPERIODO_FECHAINI === '') {
          this.mensajeAlerta('error', 'Error', `Ingrese el período`);
          return false;
        }
        break;
      case 'ASUE':
        if (Number(this.lblPERIODO_CODIGO) === 0 || this.lblPERIODO_CODIGO === null || this.lblPERIODO_FECHAINI === '') {
          this.mensajeAlerta('error', 'Error', `Ingrese el período`);
          return false;
        }
        break;
    }


    if (this.otregresoSrv.stringToDate(this.convertDate(this.dTFechaEmision)) < this.otregresoSrv.stringToDate(this.matNOM_PERIODO.per_fecini)) {
      this.mensajeAlerta('error', 'Error', `Fecha de emisión no puede ser menor que el período activo`);
      return false;
    }

    return true; // si pasa todos los controles
  }

  verifacNumChyTransferencia() {
    let validar = true;
    this.dataFormaPago.map(x => {
      if (x.tipo === 'CH' || x.tipo === 'TF') {
        if (x.TRNBAN_NUMERO === '') {
          this.mensajeAlerta('error', 'Error', `Ingrese el numero de CH o TF`);
          validar = false;
        }
      }
    })
    return validar;
  }

  async actualizarNumero() {
    await this.otregresoSrv.actualizarNumero(this.identiUsuario, 'EGN', 'EGN', this.identiUsuario).then(res => {

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.control=0;
    });
    return;
  }


  async funActuInfoTransVacaPaga() {
    if (this.mat_datosVacaciones.detrolindi_diasvacagoza === 0) {
      return true;
    }

    let consulta = await this.otregresoSrv.getInfoPeriodo(this.lblPERIODO_CODIGO).then(res => {
      return res;
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });

    let dblEMP_REMSALUNI = await this.otregresoSrv.getValorSueldo(this.lblEMP_CODIGO).then(res => {
      return res[0].EMP_REMSALUNI;
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });

    let proc = await this.otregresoSrv.getObtisueldoprordiasmes(consulta[0].PERIODO_DIACALC, dblEMP_REMSALUNI).then(res => {
      return res[0][`NOM_PCK_ENCROLINDI.NOM_FNC_OBTISUELDOPRORDIASMES(${consulta[0].PERIODO_DIACALC},${dblEMP_REMSALUNI})`]
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });



    let data = {
      p_com_codigo: '01',
      p_tranvacaempl_tipo: 'PAGADAS',
      p_tranvacaempl_periodo: this.convertDate(this.dTFechaEmision),
      p_tranvacaempl_feciniperi: this.convertDate(consulta[0].PERIODO_FECINI),
      p_tranvacaempl_fecfinperi: this.convertDate(consulta[0].PERIODO_FECFIN),
      p_tranvacaempl_diasperi: consulta[0].PERIODO_DIACALC,
      p_tranvacaempl_diasprorr: this.mat_datosVacaciones.detrolindi_diasvacagoza,
      p_tranvacaempl_baseacum: (proc === null) ? 0 : proc,
      p_tranvacaempl_valor: this.mat_datosVacaciones.detrolindi_valovacagoza,
      p_periodo_codigo: this.lblPERIODO_CODIGO,
      p_emp_codigo: this.lblEMP_CODIGO,
      p_encrolindi_codigo: null,
      p_tranvacaempl_estado: 'CERRADO',
      p_tranvacaempl_fechsalivaca: this.mat_datosVacaciones.fecha_inicio,
      p_tranvacaempl_fechregrvaca: this.mat_datosVacaciones.fecha_fin,
      p_tranvacaempl_observaciones: this.mat_datosVacaciones.observacion,
      p_encantemp_nrodocumento: this.txtNumeroDoc.text,
    }

    return await this.otregresoSrv.actVacEmplDesOtrEgrCont(data).then(() => {
      return true;
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      return false;
    });









  }

  async funGlbVerificaAsientoCuadrado(str_asiNro) {
    let dbl_asicreditos = 0;
    let dbl_asidebitos = 0;
    await this.otregresoSrv.funGlbVerificaAsientoCuadrado(str_asiNro).then(async (res) => {

      if (res !== null) {
        if (res.length > 0) {
          dbl_asicreditos = res[0]['ASI_CREDITOS'];
          dbl_asidebitos = res[0]['ASI_DEBITOS'];
        }
      }


      this.mensajeAlerta('success', 'Éxito', `Proceso exitoso`);
      
      this.txtNumeroDoc.Enabled = false;
      this.btnGuardar = true;
      //CDPJ
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.btnNuevo = false;

      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
      this.btnBuscar = false;
      //CDPJ
      await this.generarAsiento();
      await this.GeneraCuotasEgresosFijos();
      this.control=0;
      this.spin=false;
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.control=0;
      return false;
    });
  }


  limpiaCampos() {
    this.txtENCANTEMP_NUMECUOT = null;
    this.txtENCANTEMP_NUMDIASVACA = '';

    this.int_NumCheAut;
    this.int_NRREFPEM;
    this.conceptoSeleccionado;
    this.strMAERUBRO_CODRUBR = '';
    this.tipSeleccionado;
    this.txtNumComprobante = '';
    this.txtNumeroDoc = {
      Enabled: true,
      text: ''
    }
    this.dTFechaEmision = '';
    this.fraCUOTAS = false;
    this.fraPERIODO = false;
    this.fra_dias = false;
    //txtImporteEnabled = true;
    this.txtImporte = {
      Enabled: true,
      text: ''
    }
    this.importeAnt = null;
    this.txtPROcodigo = {
      Enabled: true,
      text: ''
    }
    this.lblPROnombre = '';
    this.lblEMP_CODIGO = '';
    this.txtConcepto = '';
    this.matNOM_PERIODO = {
      per_codigo: '',
      per_fecini: '',
      per_fecfin: '',
      per_descripcion: ''
    }
    this.lblasi_nro = '';
    this.lblEstado = '';
    this.lblPERIODO_FECHAINI = '';
    this.txtCON_CODIGO = '';
    this.strMAECONCPOTREGRE_CODIGO = null;
    this.lblPERIODO_CODIGO = null;
    this.strPERIODO_FECFIN = null;
    this.txtEMP_CENTCOST = null;
    this.lblSaldo = null;
    this.saldoAnt = null;
    this.txtReferenciaAdicional = '';


    this.dataFormaPago = [];
    this.conceptoSeleccionado={ codigo: '', name: ''}//CDPJ
    this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);
  }

  async funValidaAntNoLigCierrCaj() {
    await this.otregresoSrv.funValidaAntNoLigCierrCaj(this.txtNumeroDoc.text).then(res => {

      if (res.length > 0) {

      } else if (res.length === 0) {
        this.borrarEgreso();
      }
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      return false;
    });
  }

  async borrarEgreso() {

    if (this.usuarioPermisos.PERELIMACION === 0) {
      this.mensajeAlerta('error', 'Error', `No posee permiso de Eliminación. Consulte con el Administrador del Sistema.`);
      return;
    }

    if (this.txtNumeroDoc.text === '') {
      this.mensajeAlerta('info', 'Información', 'Seleccione el Registro a Eliminar');
      return;
    }

    
    try {
      await this.otregresoSrv.eliminarEncAnticipo(this.txtNumeroDoc.text);
      const res =  await this.otregresoSrv.funGlbBorraTrnDocumentoYTrnCajaBan(this.txtNumeroDoc.text);
      await this.EliminarAsientoContable();
        this.mensajeAlerta('success', 'Proceso', 'Registro eliminado');
        this.limpiaCampos();
    } catch(e) {
      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      return false;
    }
    

    /*return await this.otregresoSrv.eliminarEncAnticipo(this.txtNumeroDoc.text).then(async () => {

      return await this.otregresoSrv.funGlbBorraTrnDocumentoYTrnCajaBan(this.txtNumeroDoc.text).then(res => {
        await this.EliminarAsientoContable();
        this.mensajeAlerta('success', 'Proceso', 'Registro eliminado');
        this.limpiaCampos();
      })
    }).catch(e => {

      
    });*/




  }


  async EliminarAsientoContable(){

    await this.otregresoSrv.EliminarAsientoContable(this.lblasi_nro);
    await this.otregresoSrv.ActualizarBanTrnBan(this.txtNumeroDoc.text);
    this.lblasi_nro = '';
    await this.otregresoSrv.ActualizarBanTrnCaj(this.txtNumeroDoc.text);
    this.lblasi_nro = '';
    await this.otregresoSrv.ActualizarAsinroEnc(this.txtNumeroDoc.text);
    this.lblasi_nro = '';
    
  }
  cmdBorrar() {
    if (this.selectedForPago !== undefined) {

      const index = this.dataFormaPago.indexOf(this.selectedForPago);

      if (index > -1) {
        this.dataFormaPago.splice(index, 1);

      }

      this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);
    }

  }


  async busquedaCodigoCaja() {
    this.types = [
      { label: 'Código', value: 'CAJ_CODIGO' },
      { label: 'Descripción', value: 'CAJ_DESCRIPCION' }
    ];

    this.busquedacampos = [''];
    this.consulta = `*`;
    this.tabla = 'ban_maecaj';
    this.where = `com_codigo = '01' `;


    await this.otregresoSrv.busquedaCodigoCaja().then((res: any[]) => {


      this.arregloCons = [...res];
      this.displaydialogCodigoCaja = true;
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });
  }

  manejarSeleccionCodigoCaja(caja) {
    this.selectedForPago.CAJ_CODIGO = caja.CAJ_CODIGO;
    this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);
    this.displaydialogCodigoCaja = false;
  }

  async funcObtieneNumeroCheque(banCodigo) {
    await this.otregresoSrv.funcObtieneNumeroCheque(banCodigo).then((res: any[]) => {


      if (res !== null || res.length > 0) {

        this.selectedForPago.TRNBAN_NUMERO = Number(res[0].BAN_ULTIMOCHEQUE) + 1;
        this.aggrid.refreshaggrid(this.dataFormaPago, this.defaultColumnDefFormaPago.objeto);
      }
    }).catch(e => {

      let mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    });

  }

  selecFechaEmi(event) {
  }



  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'otrosEgresos',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }

}
