
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="salprod"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarSalprod" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>

<p-confirmDialog [transitionOptions]="'0ms'" key="confSinAsiento" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>


<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar" [botonBorrar]="botonBorrar"
			[botonRegresar]="botonRegresar" (signal)="manejarSenales($event)" [botonAnterior]="botonAnterior"
			[botonPrimero]="botonPrimero" [botonUltimo]="botonUltimo" [botonSiguiente]="botonSiguiente"
			[barraBotonesDesplaz]="barraBotones2" [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="barraBotones4"
			[botonBuscar]="false" [botonAsiento]="false" [botonverAsiento]="false" [botonAlmacenardoc]="true">
		</app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -5px; width: 99%;">
	<div class="p-col-8"></div>
	<div class="p-col-2">
		<label for="ASI_NRO"></label>
		<input  class="frm" id="ASI_NRO" type="text" pInputText style="background-color: #EFFFF7" [disabled]="true"
			[(ngModel)]="invsalidaprodService.encsalidaSeleccionada[indicador].ASI_NRO" autocomplete="off" autocomplete="off">
	</div>
</div>
<p-tabView [(activeIndex)]="activeIndex" [style]="{'width':'98%'}">
	<p-tabPanel header="Salidas">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 400px)'}">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Nro. Doc: </span>
							</div>
							<div class="p-col-2">
								<input pInputText type="text" size="14" [disabled]="true"
									[(ngModel)]="invsalidaprodService.encsalidaSeleccionada[indicador].KAR_NRO" (keydown)="cambio()" autocomplete="off">
							</div>
							<div class="p-col-2"></div>
							<div  class="p-col-5">
								<div class="p-grid">
									<div  class="p-col-4"><label for="produccion">Orden de Producción:</label></div>
									<div class="p-col-6"><input type="text" pInputText id="produccion"
										[(ngModel)]="invsalidaprodService.encsalidaSeleccionada[indicador].ENCORDPRO_NUMERO" [disabled]="true"
											autocomplete="off"></div>
									<div class="p-col-2">
										<button  class="littlebuttons"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedaorden()"
											></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<input type="text" pInputText id="origen" style="width: 80px"
									[(ngModel)]="invsalidaprodService.encsalidaSeleccionada[indicador].KAR_ORIGEN" [disabled]="true" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -14px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Nro. Compr: </span>
							</div>
							<div class="p-col-2">
								<input pInputText type="text" size="14" id="nroComp"
									(keydown.enter)="cambiarFoco($event)"
									[(ngModel)]="invsalidaprodService.encsalidaSeleccionada[indicador].KAR_NROCOMPROBANTE" (input)="cambio()"
									[disabled]="inputsboolean" autocomplete="off">
							</div>
							<div class="p-col-9" style="align-self: center">
								<div class="p-grid">
									<div class="p-col-1">
									</div>
									<div class="p-col-3">
										<label for="fecha">Fecha: </label>
										<p-calendar id="fecha" [inputStyle]="{width: '100px', height: '20px'}"
											[(ngModel)]="invsalidaprodService.date1" (input)="cambio()" dateFormat="dd/mm/yy"
											[disabled]="inputsboolean"></p-calendar>

									</div>
									<div class="p-col-2">
										<span>dd/MM/YYYY</span>
									</div>
									<div class="p-col-4">
										<label for="hora">Hora: </label>
										<p-calendar id="hora" [timeOnly]="true"
											[inputStyle]="{width: '50px', height:'20px'}" [(ngModel)]="invsalidaprodService.hora"
											(input)="cambio()" [disabled]="inputsboolean"></p-calendar>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px; margin-bottom: -15px;">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Comentario: </span>
							</div>
							<div class="p-col-11">
								<input pInputText type="text" id="comentario" (keydown.enter)="cambiarFoco($event)"
									style="width:100%" [(ngModel)]="invsalidaprodService.encsalidaSeleccionada[indicador].KAR_COMENTARIO"
									(input)="cambio()" [disabled]="inputsboolean" autocomplete="off">
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- CDPJ -->
			<app-aggridsaci [width]="1150" [height]="largo" [enableSorting]="false" [enableFilter]="true"
				[rowData]="invsalidaprodService.detkardexsarray[indicador]" [frameworkComponents]="frameworkComponents"
				[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefsSalida"
				[defaultColDef]="defaultColDefSalida" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
				[botonNuevo]="botonNuevoP" [botonBorrar]="botonBorrarP" [botonRegresar]="botonRegresarP"
				(cambios)="cambio()" [mostrarGuardar]="false" (selected)="seleccionsalida($event)"></app-aggridsaci>
		</p-scrollPanel>
	</p-tabPanel>
	
</p-tabView>
<div class="p-col-12">
	<div class="p-grid">
		<div class="p-col-8"></div>
		<div class="p-col-4">
			<div class="divgrupstabla">
				<div class="p-grid">
					<div class="p-col-8">
						<span for="total" class="obligatorio">Costo Total:</span>
					</div>
					<div class="p-col-4">
						<input type="text" class="totales" pInputText id="total" [disabled]="true"
							[(ngModel)]="invsalidaprodService.totalSalidaarray[indicador]" autocomplete="off">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog header="Seleccione la opción" [(visible)]="displayDialogBus" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'70vw', height:'70vh'}">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloBusq" 
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Seleccione la opción" [(visible)]="displayDialogBusFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'70vw', height:'70vh'}">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloBusq" [tabla]="tabla"
		[busquedaParams]="busquedaCampos" [consulta]="consulta" [where]="where"
		(opcionSeleccionada)="manejarSeleccion($event)">

	</app-busquedadlg>
</p-dialog>


<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'invsalida'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<!-- <div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="false" [showCloseIcon]="false">
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<div class="p-col-12">
							<i class="pi pi-folder archivoOpc"></i><span (click)="abrirSeriales()"
								class="archivoOpc">Ingresar Seriales</span>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div> -->
<!-- <p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr  [strNumFact]="strNumFact"
		[strFechaSer]="strFechaSer" [strTipoDoc]="'INVSAL'" [strTipoTrn]="'SA'"
		(cerrar)="cerrarSeriales()"></app-invtrnkardexotr>
</p-dialog> -->

<p-dialog header="Lotes" [(visible)]="displayDialogLotes" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-invtrnlote [auxiliar]="auxiliarlot" [detalle]="salidaSeleccionada" [encabezado]="invsalidaprodService.encsalidaSeleccionada[indicador]"
		[cadena]="'SA'" (salir)="cerrarLotes()"></app-invtrnlote>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="invsalidaprodService.encsalidaSeleccionada[indicador].KAR_NRO" [strCampo]="'KAR_NRO'" (cerrar)="cerrarReportes()">
	</app-sacimaecfngimpr>
</p-dialog>
<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'INVSALIDA'"
		[txtNumComprobante]="invsalidaprodService.encsalidaSeleccionada[indicador].KAR_NRO" (signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
<!-- CDPJ -->
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<!-- CDPJ -->