//
// SACI WEB
// Rutina: Cartera Activa
// Propietario: Wilson Ramos
// Modificado por: WR
// Fecha de creación: 05-03-2020
// Fecha de Modificación: 09-03-2020
//

import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from '../../usuario';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { Cxctmptrnresumen } from '../../ven/veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../../ven/veninterfaces/cxctrnresumen';
import { Comencdevfac } from '../../com/cominterfaces/comencdevfac';
import { Cxctrndebitobancario } from '../cxcinterfaces/cxctrndebitobancario';
import { Cxctrnretencion } from '../cxcinterfaces/cxctrnretencion';
import { Cxctrnotrosg } from '../cxcinterfaces/cxctrnotrosg';
import { Cxctrnformapago } from '../cxcinterfaces/cxctrnformapago';
import { Cxctrnanexos } from '../cxcinterfaces/cxctrnanexos';
import { Cxctrnvarios } from '../cxcinterfaces/cxctrnvarios';
import { Cxctrncobro } from '../cxcinterfaces/cxctrncobro';
import { SegMaeCompania } from '../../ven/veninterfaces/segmaecompania';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { VenmaeclienteService } from '../../ven/venservicios/venmaecliente.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { CxccarteraactivaService } from '../cxcservicios/cxccarteraactiva.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';

// Ag-grid
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Cxctrnanticipos } from '../cxcinterfaces/cxctrnanticipos';
import { CxcTrncobro } from 'src/app/interfaces/cxctrncobro';


@Component({
  selector: 'app-cxccarteraactiva',
  templateUrl: './cxccarteraactiva.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})

export class CxccarteraactivaComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;

  // dropdowns
  opcionIva: SelectItem[];
  opcionIvaDesc: SelectItem[];
  opcionesBodega: SelectItem[];
  opcionesRegimen: SelectItem[];
  opcionesTipoDoc: SelectItem[];
  opcionesExportacion: SelectItem[];
  opcionesDistrito: SelectItem[];
  opcionesRegRefrendo: SelectItem[];
  opcionesIngresoExterior: SelectItem[];
  opcionesTipoIngresoExterior: SelectItem[];
  opcionesprecio: SelectItem[];

  // dialogs
  displayDialogDetFac: boolean;
  displayDialogPedidos: boolean;
  displayDialogAsiento: boolean;
  displayAsiContable: boolean;
  displayDialogSeriales: boolean;
  displayDialogDescuento: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogBus: boolean;
  disimporte:boolean;
  displayDialogPago: boolean;
  dialogFacCanceladas: boolean;
  displayAcciones: boolean;
  displayLog: boolean;
  displayDialogReportes: boolean;
  claveDialog: boolean;
  spin = false;

  // barra
  btnGuardar: boolean;
  btnRegresar: boolean;
  btnBorrar: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonReenviarCorreo: boolean;
  botonAsiento: boolean;
  botonPago: boolean;
  botonBuscar: boolean;
  botonVerAsiento: boolean;
  botonAnterior: boolean;
  botonPrimero: boolean;
  botonUltimo: boolean;
  botonSiguiente: boolean;
  barraBotones2: boolean;
  btnNuevo: boolean;

  // interfaces
  trnresumenSeleccionado: Cxctrnresumen;
  facturaPend: Cxctrnresumen;
  detalleRetencionSeleccionado: Cxctrnretencion;
  detalleVariosSeleccionado: Cxctrnvarios;
  detalleVariosSeleccionadoAux: Cxctrnvarios;
  detalleOtrasFSeleccionada: Cxctrnotrosg;
  detalleFormaPago: Cxctrnformapago;
  detalleFormaPagoDev: Cxctrnformapago;
  detalleRetencionSeleccionadoAux: Cxctrnretencion;
  detalleOtrasFSeleccionadaAux: Cxctrnotrosg;
  detalleFormaPagoAux: Cxctrnformapago;
  detalleFormaPagoDevAux: Cxctrnformapago;
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  encabezadosCartera: Comencdevfac[];
  anexoSeleccionado: Cxctrnanexos;
  permisosCartera: SegMaePermiso;

  auxreporte: number = 0;
  strNombreForma: string;

  auxiliar: number;
  auxContEC: number;
  debeTotal: number;
  haberTotal: number;
  indiceTablas: number;
  totalimporte: number;
  totalpagado: number;
  totalsaldo: number;
  totalApagar: number;
  valorDscValor: number;
  valorDscPorcentaje: number;
  numdecsist: number;
  dscClienteActual: number;
  numeroActual: number;
  indice: number;
  i: number;
  activeIndex: number;
  indiceCliente: number;
  indiceDocRef: number;
  listaSaldos: any;
  itemTipoDoc: any;
  listaClientes: any[];
  listaReferencias: any[];
  numeracionActual: any;
  fechaEmision: any;
  fechaInicio: any;
  fechaFin: any;
  horaSistema: any;
  horaInicio: any;
  horaFin: any;
  listaprecios: any[];
  tabDocumentos: boolean;
  booleanAnulado: boolean;
  tabVarios: boolean;
  tabRetenciones: boolean;
  tabOtrosGastos: boolean;
  booleanNumero: boolean;
  tabFormaPago: boolean;
  tabFormaPagoDev: boolean;
  tabEstadoCuentaAnt: boolean;
  tabDebitoBancario: boolean;
  valorInvalidoV: boolean;
  saldoInicial: boolean;
  bolsaldoIni: boolean;
  valorInvalidoP: boolean;
  numeracionDialog: boolean;
  editarCeldas: boolean;
  booleanValor: boolean;
  booleanDatosPantalla: boolean;
  booleanPorcentaje: boolean;
  booleanRegimen: boolean;
  booleanParaiso: boolean;
  booleanRegimenP: boolean;
  booleanRefrendo: boolean;
  booleanImpuestoRenta: boolean;
  booleanBodega: boolean;
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  btnVerificarExis: boolean;
  ventasboolean: boolean;
  comprasboolean: boolean;
  consigboolean: boolean;
  trasladoboolean: boolean;
  devboolean: boolean;
  impboolean: boolean;
  expboolean: boolean;
  otrosboolean: boolean;
  transboolean: boolean;
  cotizacionBoolean: boolean;
  porcIvaBoolean: boolean;
  retencionElect: boolean;
  bolnc: boolean;
  choosedColor: string;
  claveExportNuevo: string;
  tipodetalleactivo: string;
  botonFacNR: string;
  impresoString: string;
  grupo1: string;
  numSerie: string;
  nombrePaisSeleccionado: string;
  codigoPaisRegimen: string;
  codigoPaisParaiso: string;
  dscSeleccionado: string;
  asiento: string;
  numeroFactura: string;
  claveAuth: string;
  saldoCliente: string;
  nombreProveedor: string;
  logResultado: string;
  accionClave: string;
  cobroCodigo: string;
  opcion: string;
  numDocRef: string;
  imagenProducto: string;
  estadoRef: string;
  largoestcta: string;
  choosedColorG: string;
  choosedColorSB: string;
  nombrebodega: string;
  codClienteFac: string;
  nombreClienteFac: string;
  nroFacCancelada: string;
  lblAplicaRegMicroempresas: string;
  tipodetalle = '';
  boltrnaextipcom: boolean;

  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  arregloBus: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;
  consulta: string;
  busquedaCompuesta: boolean;
  bolestcta: boolean;
  bolsaldo: boolean;

  // aggrid
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  rowStyleEC;
  rowStyleSaldo;
  rowStyleECAnc;
  rowStyleFp;
  rowStyleFpdev;
  defaultColumnDefCarteraDoc;
  defaultColumnDefCarteraRet;
  defaultColumnDefCarteraOtrosG;
  defaultColumnDefCarteraFormaPago;
  defaultColumnDefCarteraFormaPagoDev;
  defaultColumnDefCarteraVarios;
  defaultColumnDefCarteraDebito;
  defaultColumnDefCarteraAnticipo;
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  defaultColumnDefFacCanceladas;
  comboOpciones: any[];
  largo: string;
  largoRef: string;

  ecanticipo: any[];
  lblDocCierre = false;

  bolNuevo = false;
  listaDoc: CxcTrncobro[] = [];
  datosfe: SegMaeCompania = {};

  btneditConcil = true;
  editConcil = true;
  ////GSRF
  editpagofr = true;
displayDialogfrmcorreos:boolean;//CDPJ

  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', width: 100,
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];
  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHAVENCE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];
  columDefCarteraDoc = [
    {
      headerName: 'S/N',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: (params) => {
        return false;
      },
      width: 50,
      onCellClicked: (params) => {
        this.docSeleccionado(params);
      }
    },
    {
      headerName: 'Nro. Documento', field: 'TRNCOBRO_NRODOC', cellEditor: 'cellPrueba', width: 180, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 50,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32
        }
      }
    },
    {
      headerName: 'Fecha Vec.', field: 'TRNCOBRO_FECHAVENCE', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32,
        }
      }
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200,
        }
      }
    },
    {
      headerName: 'Saldo', field: 'TRNCOBRO_SALDOINI', cellEditor: 'agSelectCellEditor', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Vendedor', field: 'VEN_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 5,
          tienecalculos: true,
          int: true
        }
      }
    }];
  columDefCarteraRetencion = [
    {
      headerName: 'Código', field: 'RETENCION_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'TRNRETENCION_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200
        }
      }
    },
    {
      headerName: 'Número', field: 'TRNRETENCION_NRO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 15,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Base', field: 'TRNRETENCION_BASE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: '%', field: 'TRNRETENCION_PORCENTAJE', cellEditor: 'agSelectCellEditor', width: 80, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'T.Retenido', field: 'TRNRETENCION_TOTALRETENIDO', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    }];
  columDefCarteraOtrosG = [
    {
      headerName: 'Código', field: 'COMISION_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'TRNCOMISION_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200
        }
      }
    },
    {
      headerName: 'Número', field: 'TRNCOMISION_NRO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 35,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Importe', field: 'TRNCOMISION_TOTALRETENIDO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    }];
  columDefCarteraFormaPago = [
    {
      headerName: 'Tipo', field: 'TRNPAGO_TIPO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 4,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Código', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.idEditcajcodigoFormapago(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Número', field: 'TRNPAGO_NUMERO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 45,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Fecha', field: 'TRNPAGO_FECHA', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Ban', field: 'BANCLI_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 35,
          tienecalculos: true,
          int: true,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre', field: 'BANCLI_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Número Cuenta', field: 'TRNPAGO_NROCTA', cellEditor: 'cellPrueba', width: 100,
      editable: p => {
        return p.data.TRNPAGO_TIPO === 'TA' || p.data.TRNPAGO_TIPO === 'CH';
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 25,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Concepto', field: 'TRNPAGO_CONCEPTO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 1000,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Importe', field: 'TRNPAGO_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'C.Sri', field: 'TRNPAGO_CODSRI', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 2,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Disp.', field: 'TRNPAGO_TIPDIS', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 4,
          tienecalculos: true
        }
      }
    }];
  columDefCarteraVarios = [
    {
      headerName: 'Ref. Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', width: 250, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100
        }
      }
    },
    {
      headerName: 'Debe', field: 'DEBE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Haber', field: 'HABER', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Tipo', field: 'TRNBAN_TIPO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'C.Costo', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      // , editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 32
        }
      }
    }
  ];
  columDefCarteraDebito = [
    {
      headerName: 'S/N',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: (params) => {
        return false;
      },
      width: 50,
      onCellClicked: (params) => {
        console.log('-----',params.data.SELECCIONADO)
          this.disimporte=true;
        this.debitoSeleccionado();
      }
    },
    {
      headerName: 'Fecha', field: 'TRNPAGO_FECHA', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 20,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Tipo', field: 'TRNPAGO_TIPO', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 4,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Número', field: 'TRNPAGO_NUMERO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 45
        }
      }
    },
    {
      headerName: 'Importe', field: 'TRNPAGO_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: false,//GSRF
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Nro Cta.', field: 'TRNPAGO_NROCTA', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 25,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Cod Ban.', field: 'BANCLI_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Nombre Banco', field: 'BANCLI_NOMBRE', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Nro. Doc.', field: 'TRNCOBRO_NRODOC', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true
        }
      }
    }];
  columDefCarteraAnticipo = [
    {
      headerName: 'Nro Documento', field: 'TRNCOBRO_NRODOC', cellEditor: 'cellPrueba', width: 200,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Tipo', field: 'TRNPAGO_TIPO', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 4
        }
      }
    },
    {
      headerName: 'Fecha', field: 'TRNPAGO_FECHA', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Concepto', field: 'TRNPAGO_CONCEPTO', cellEditor: 'cellPrueba', width: 250,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 1000,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Importe', field: 'TRNPAGO_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    }];
  columDefCarteraFormaPagoDev = [
    {
      headerName: 'Tipo', field: 'TRNCAJ_TIPO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Caja', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.idEditcajcodigo(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Banco', field: 'BAN_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre Banco', field: 'BAN_BANCO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 100,
          int: true
        }
      }
    },
    {
      headerName: 'Número', field: 'TRNCAJ_NUMERO', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 20,
        }
      }
    },
    {
      headerName: 'Fecha', field: 'TRNCAJ_FECHA', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32,
        }
      }
    },
    {
      headerName: 'Concepto', field: 'TRNCAJ_DESCRIPCION', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200,
        }
      }
    },
    {
      headerName: 'Importe', field: 'TRNCAJ_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true,
          tienecalculos: true
        }
      }
    }];
  columDefFacCanceladas = [
    {
      headerName: 'Nro. Documento', field: 'TRNDOCUMENTO_NRODOC', width: 150, editable: false
    },
    {
      headerName: 'Nro. Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', editable: false
    },
    {
      headerName: 'Importe Cancelado', field: 'TRNDOCUMENTO_IMPORTE', editable: false, cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Cod. Cliente', field: 'CLI_CODIGO', editable: false
    }];

  constructor(public cxcCarteraService: CxccarteraactivaService, private confirmationService: ConfirmationService,
    private busquedaSer: InvbusquedaService, private datePipe: DatePipe,
    private message: MessageService, private utilitariosService: UtilitariosService, private cliService: VenmaeclienteService,
    private auditoriagral: AuditoriagralService, private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService, private permisosService: PermisosService, public usuario: Usuario,
    private init: NuevoComponentService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.data.APLORG_CODIGO === 'SISTEMA' &&
        params.data.TRNCOBRO_CONCEPTO.includes('CUOTA')) {
        return { background: 'rgb(255, 255, 170)' };
      }

      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }

      if ((params.data.TRNCOBRO_FECHATRN === '' || params.data.TRNCOBRO_FECHATRN === null) &&
        (this.activeIndex === 1 || this.activeIndex === 2)) {
        return { background: '#AAFF00' };
      }
      /*if (params.data.TRNCOBRO_NRODOC === '' && this.activeIndex === 1) {
        return {background: '#AAFF00'};
      }*/
      /*if (params.data.APLORG_CODIGO === 'SISTEMA') {
        return {background: '#AAFF00'};
      }*/

    };

    this.rowStyleEC = (params) => {
      if (params.data.TRNCOBRO_TIPO !== 'A') {
        if (params.data.TRNCOBRO_FECHATRN === '') {
          return { background: 'rgb( 255, 255, 170)' };
        } else {
          return { background: 'rgb( 196, 225, 255)' };
        }
      }
    };

    this.rowStyleSaldo = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb( 255, 255, 170)' };
      }
    };

    this.rowStyleECAnc = (params) => {
      if (params.data.TRNPAGO_FECHA === '') {
        return { background: 'rgb( 255, 255, 170)' };
      } else {
        return { background: 'rgb( 196, 225, 255)' };
      }
    };

    this.rowStyleFp = (params) => {
      if (params.data.APLORG_CODIGO === 'SISTEMA' &&
        params.data.TRNCOBRO_CONCEPTO.includes('CUOTA')) {
        return { background: 'rgb(255, 255, 170)' };
      }
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if ((params.data.TRNCOBRO_FECHATRN === '' || params.data.TRNCOBRO_FECHATRN === null) &&
        (this.activeIndex === 1 || this.activeIndex === 2)) {
        return { background: '#AAFF00' };
      }
      if (params.data.COLORDOCDEP === true) {
        return { background: 'rgb(255, 207, 185)' };
      }
      if (params.data.COLORDOCCONC === true) {
        return { background: 'rgb(135, 234, 130)' };
      }
    };

    this.rowStyleFpdev = (params) => {
      if (params.data.APLORG_CODIGO === 'SISTEMA' &&
        params.data.TRNCOBRO_CONCEPTO.includes('CUOTA')) {
        return { background: 'rgb(255, 255, 170)' };
      }
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if ((params.data.TRNCOBRO_FECHATRN === '' || params.data.TRNCOBRO_FECHATRN === null) &&
        (this.activeIndex === 1 || this.activeIndex === 2)) {
        return { background: '#AAFF00' };
      }
      if (params.data.COLORDOCDEP === true) {
        return { background: 'rgb(255, 207, 185)' };
      }
      if (params.data.COLORDOCCONC === true) {
        return { background: 'rgb(135, 234, 130)' };
      }
    };

    this.defaultColumnDefCarteraDoc = {
      editable: true,
      width: 100,
      objeto: 'cxcdocumentos'
    };
    this.defaultColumnDefCarteraRet = {
      editable: true,
      width: 100,
      objeto: 'cxcretenciones'
    };
    this.defaultColumnDefCarteraOtrosG = {
      editable: true,
      width: 100,
      objeto: 'cxcotrosgastos'
    };
    this.defaultColumnDefCarteraFormaPago = {
      editable: true,
      width: 100,
      objeto: 'cxcformapago'
    };
    this.defaultColumnDefCarteraFormaPagoDev = {
      editable: true,
      width: 100,
      objeto: 'cxcformapagodev'
    };
    this.defaultColumnDefCarteraVarios = {
      editable: true,
      width: 100,
      objeto: 'cxcvarios'
    };
    this.defaultColumnDefCarteraDebito = {
      editable: true,
      width: 100,
      objeto: 'cxcdepositoban'
    };
    this.defaultColumnDefCarteraAnticipo = {
      editable: true,
      width: 100,
      objeto: 'cxcanticipo'
    };
    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen'
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen'
    };
    this.defaultColumnDefFacCanceladas = {
      editable: true,
      width: 100,
      objeto: 'cxcfacCanceladas'
    };
  }

  ngOnInit() {
    this.cxcCarteraService.refrescarURL();
    this.disimporte=false;//GSRF
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    console.log(this.indicador);
    this.cxcCarteraService.dblTotalDocarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalRetarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalOtrosGarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalFormaPagoarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalFormaDevarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalVariosarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalNDarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalNCarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalCobrararray[this.indicador] = 0;

    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = {};
    this.botonFacNR = '';
    this.encabezadosCartera = [];
    this.detalleRetencionSeleccionado = {};
    this.detalleVariosSeleccionado = {};
    this.detalleRetencionSeleccionadoAux = {};
    this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = [];
    this.cxcCarteraService.cxcdebitobancarioarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = [];
    this.opcionesprecio = [];
    this.listaprecios = [];
    this.opcionesBodega = [];
    this.opcionesRegimen = [];
    this.opcionesExportacion = [];
    this.opcionesDistrito = [];
    this.opcionesTipoDoc = [];
    this.opcionesRegRefrendo = [];
    this.opcionesIngresoExterior = [];
    this.opcionesTipoIngresoExterior = [];
    this.usuario.loadStorage();
    this.anexoSeleccionado = {};
    this.cxctrnresumen = [];
    this.cxctmptrnresumen = [];
    this.listaSaldos = [];
    this.trnresumenSeleccionado = {};

    this.displayAcciones = false;
    this.displayDialogDescuento = false;
    this.booleanValor = true;
    this.booleanPorcentaje = false;
    this.retencionElect = false;
    this.booleanNumero = true;
    this.bolnc = true;
    this.numSerie = '';
    this.auxiliar = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = {};
    this.permisosService.getListaPermisos('CXC', '8', '22').subscribe((permiso) => {
      this.permisosCartera = permiso[0];
    });
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
    });
    this.numDocRef = '';
    this.auxContEC = 0;
    this.debeTotal = 0;
    this.haberTotal = 0;
    this.asiento = '';
    this.consulta = '';
    this.detalleOtrasFSeleccionada = {};
    this.detalleOtrasFSeleccionadaAux = {};
    this.detalleVariosSeleccionadoAux = {};
    this.accionClave = '';
    this.indiceCliente = 0;
    this.indiceDocRef = 0;
    this.codClienteFac = '';
    this.nombreClienteFac = '';
    this.nroFacCancelada = '';
    this.activeIndex = 0;
    this.tabDocumentos = true;
    this.tabFormaPago = false;
    this.booleanAnulado = false;
    this.tabOtrosGastos = false;
    this.tabRetenciones = false;
    this.dialogFacCanceladas = false;
    this.tabVarios = false;
    this.tabFormaPagoDev = false;
    this.indiceTablas = 0;
    this.numeroFactura = '';
    this.tabEstadoCuentaAnt = false;
    this.tabDebitoBancario = false;
    this.booleanDatosPantalla = true;
    this.largoestcta = '420';
    this.nombrePaisSeleccionado = '';
    this.tipodetalleactivo = 'all';
    this.saldoInicial = false;
    this.dscSeleccionado = 'dscPorcentaje';
    this.claveExportNuevo = '';
    this.codigoPaisRegimen = '';
    this.codigoPaisParaiso = '';
    this.where = '';
    this.booleanBodega = false;
    this.auxContEC = 0;
    this.debeTotal = 0;
    this.haberTotal = 0;
    this.totalimporte = 0;
    this.totalpagado = 0;
    this.totalsaldo = 0;
    this.cobroCodigo = '';
    this.impresoString = '';
    this.valorDscValor = 0;
    this.busquedaCompuesta = false;
    this.valorDscPorcentaje = 0;
    this.porcIvaBoolean = true;
    this.booleanRegimen = true;
    this.booleanParaiso = true;
    this.booleanRegimenP = true;
    this.booleanRefrendo = true;
    this.numeracionActual = {};
    this.dscClienteActual = 0;
    this.totalApagar = 0;
    this.displayDialogDetFac = false;
    this.displayDialogPedidos = false;
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.displayDialogSeriales = false;
    this.displayDialogfrmcorreos=false;//CDPJ
    this.editarCeldas = false;
    this.displayDialogPago = false;
    this.claveDialog = false;
    this.displayLog = false;
    this.spin = false;
    this.displayDialogReportes = false;
    this.valorInvalidoP = false;
    this.valorInvalidoV = false;
    this.numeracionDialog = false;
    this.logResultado = '';
    this.largo = '630';
    this.saldoCliente = '';
    this.estadoRef = '';
    this.nombreProveedor = '';
    this.valorDscValor = 0;
    this.valorDscPorcentaje = 0;
    this.choosedColorG = '#804000';
    this.choosedColorSB = '#00FF00';
    this.choosedColor = '#AAFF00';
    this.claveAuth = '';
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.botonAnular = false;
    this.botonXML = true;
    this.botonReenviarCorreo = true;
    this.botonAsiento = false;
    this.botonVerAsiento = false;
    this.botonBuscar = false;
    this.botonPago = false;
    this.cotizacionBoolean = false;
    this.booleanImpuestoRenta = true;
    this.activeIndex = 0;
    this.ventasboolean = false;
    this.imagenProducto = '';
    this.comprasboolean = false;
    this.consigboolean = false;
    this.trasladoboolean = false;
    this.nombrebodega = '';
    this.devboolean = false;
    this.impboolean = false;
    this.expboolean = false;
    this.otrosboolean = false;
    this.transboolean = false;
    this.displayDialogBus = false;
    this.numeroActual = 0;
    this.numdecsist = this.confIniciales.getNumDecSist();
    this.displayDialogBusquedaFast = false;
    this.comboOpciones = [{ label: 'A', value: 'A' },
    { label: 'S', value: 'S' }, { label: 'C', value: 'C' }, { label: '*', value: '*' }];
    this.tipodetalle = 'A';
    this.opcionIva = [{ label: '12', value: 12 }, { label: '14', value: 14 }];
    this.opcionIvaDesc = [{ label: 'Neto + IVA', value: '1' },
    { label: 'Incluido IVA', value: '2' }, { label: 'Exento', value: '0' }];
    this.cxcCarteraService.dblPorcIvaDevF = 12;
    this.fechaEmision = new Date().toLocaleString().substr(0, 10);
    this.fechaInicio = new Date().toLocaleString().substr(0, 10);
    this.fechaFin = new Date().toLocaleString().substr(0, 10);
    this.horaInicio = new Date();
    this.horaFin = new Date();
    this.horaSistema = new Date();
    this.largo = '650';
    this.largoRef = '400';
    this.indice = 0;
    this.i = 0;
    this.cxcCarteraService.llenarTipoDoc().subscribe((tipodoc) => {
      tipodoc.map((doc) => {
        this.itemTipoDoc = { label: doc.NOMBRE, value: doc.CODIGO };
        this.opcionesTipoDoc.push(this.itemTipoDoc);
      });
      this.opcionesTipoDoc.push({ label: '', value: '' });
    });
    this.grupo1 = 'fac';
    this.cxcCarteraService.getListaClientes().subscribe((resCli) => {
      this.listaClientes = resCli;
    });
    this.lblAplicaRegMicroempresas = '';
    this.bolsaldoIni = true;
    this.bolestcta = false;
    this.bolsaldo = false;
    this.listaDoc = [];
    this.cxcCarteraService.leerConfiguracionDatosElectronicos().subscribe(data => {
      for (const rs of data) {
        this.datosfe = rs;
      }
    });
  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciasCartActiv--;
  }
//CDPJ
  consultarPosicionCliente(valor) {
    if (valor === 'P') {
      this.indiceCliente = 0;
    } else if (valor === 'S') {
      if (this.indiceCliente < this.listaClientes.length - 1) {
        this.indiceCliente++;
      } else {
        this.indiceCliente = this.listaClientes.length - 1;
        valor = 'U';
      }
    } else if (valor === 'A') {
      if (this.indiceCliente > 0) {
        this.indiceCliente--;
      } else {
        this.indiceCliente = 0;
        valor = 'P';
      }
    } else if (valor === 'U') {
      this.indiceCliente = this.listaClientes.length - 1;
    }
    this.consultarReferencias(valor);
  }

  consultarReferencias(valor) {
    this.cxcCarteraService.getListaReferencias(this.listaClientes[this.indiceCliente].CLI_CODIGO).subscribe((resRef) => {
      this.listaReferencias = resRef;
      if (valor === 'P' || valor === 'S') {
        this.indiceDocRef = 0;
      } else if (valor === 'A' || valor === 'U') {
        this.indiceDocRef = this.listaReferencias.length - 1;
      }
      this.obtenerDatos(this.listaReferencias[this.indiceDocRef].TRNCOBRO_REFERENCIAEXTERNA);
    });
  }

  primero() {
    this.editpagofr = true;
    this.consultarPosicionCliente('P');
    if (this.botonUltimo === true) {
      this.botonUltimo = !this.botonUltimo;
    }
    if (this.botonSiguiente === true) {
      this.botonSiguiente = !this.botonSiguiente;
    }
    this.botonPrimero = true;
    this.botonAnterior = true;
  }

  siguiente() {
    this.editpagofr = true;
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE.length === 0) {
      this.consultarPosicionCliente('S');
      return;
    }
    if (this.indiceDocRef < this.listaReferencias.length - 1) {
      this.indiceDocRef++;
    } else {
      this.consultarPosicionCliente('S');
      return;
    }
    if (this.botonPrimero === true && this.botonAnterior === true) {
      this.botonPrimero = false;
      this.botonAnterior = false;
    }
    this.consultarReferencias('');
  }

  anterior() {
    this.editpagofr = true;
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE.length === 0) {
      this.consultarPosicionCliente('A');
      return;
    }
    if (this.indiceDocRef > 0) {
      this.indiceDocRef--;
    } else {
      this.consultarPosicionCliente('A');
      return;
    }
    if (this.botonUltimo === true && this.botonSiguiente === true) {
      this.botonUltimo = false;
      this.botonSiguiente = false;
    }
    this.consultarReferencias('');
  }

  ultimo() {
    this.editpagofr = true;
    this.consultarPosicionCliente('U');
    if (this.botonPrimero === true) {
      this.botonPrimero = !this.botonPrimero;
    }
    if (this.botonAnterior === true) {
      this.botonAnterior = !this.botonAnterior;
    }
    this.botonUltimo = true;
    this.botonSiguiente = true;
  }

  nuevo() {
    this.editConcil = true;
    this.lblDocCierre = false;
    this.opcionesTipoDoc = [];
    this.cxcCarteraService.llenarTipoDoc().subscribe((tipodoc) => {
      tipodoc.map((doc) => {
        this.itemTipoDoc = { label: doc.NOMBRE, value: doc.CODIGO };
        this.opcionesTipoDoc.push(this.itemTipoDoc);
      });
      this.opcionesTipoDoc.push({ label: '', value: '' });
    });

    if (this.permisosCartera.PERINSERCION === 1) {
      this.nuevaCartera('N');
    } else if (this.permisosCartera.PERINSERCION === 0) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Inserción de Cartera',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
    document.getElementById("errasiento").style.display = 'none';
  }

  async borrar() {
    this.editpagofr = true;
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDOANC) !==
        Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE)) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Anular Cartera',
          detail: 'No se puede Eliminar el anticipo ya está siendo utilizado'
        });
        return;
      }
    }
    // conciliado
    if (this.permisosCartera.PERELIMACION === 1) {
      if (this.activeIndex === 0) {

        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.length > 0) {
          // ver que los documentos no tengan pagos para la eliminación
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
            if (await this.existePagoDoc()) {
              return;
            }
          }

          // verificar que el cheque girado por la devolución de anticipos
          // no haya sido conciliado
          if (await this.chequeConciliado('eliminar')) {
            return;
          }

          // verificar que el cheque cobrado no haya sido depositado
          if (await this.chequeDepositado('eliminar')) {
            return;
          }

          // verificar que el efectivo no haya sido depositado
          if (await this.efectivoDepositado('eliminar')) {
            return;
          }

          // verificar que los depósitos no hayan sido conciliados
          if (await this.depositoConciliado('eliminar')) {
            return;
          }

          // verificar que los vouchers no hayan sido conciliados
          if (await this.tarjetaConciliada('eliminar')) {
            return;
          }

          // verificar que el documento no esté en el cierre de caja
          if (await this.documentoCierre('eliminar')) {
            return;
          }
          // verificar si el anticipo ya fue utilizado y no dejar
          // eliminarlo
          if (this.anticipoUtilizado('eliminar')) {
            return;
          }
          // verifica si existe algun anticipo asociado y si este anticipo ha sido utilizado
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CI') {
            if (await this.cxc_fnc_verificar_ant_util(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC) !== 0) {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Información',
                detail: 'No se puede eliminar la transacción, ya que tiene asociado un anticipo ya utilizado'
              });
              return;
            }
          }
          this.confirmarCartera();
        } else {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Información',
            detail: 'No hay un documento para la eliminación.'
          });
        }
      } else if (this.activeIndex === 3) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Eliminación de Anexos',
          detail: 'No se puede eliminar un anexo, solo modificar'
        });
      }
    } else if (this.permisosCartera.PERELIMACION === 0) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Eliminación de Cartera',
        detail: 'El usuario no tiene permisos de eliminación, acción denegada'
      });
    }
    document.getElementById("errasiento").style.display = 'none';
  }

  async guardarNew() {
    this.editpagofr = true;
    if (this.activeIndex === 0) {
      let bolNuevoAux = this.bolNuevo;
      let intTotalDiasMora = 0;

      if (this.bolNuevo) { // nuevo documento
        if (this.permisosCartera !== null) {
          if (this.permisosCartera.PERTODO === 0) {
            if (this.permisosCartera.PERINSERCION === 0) {
              this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
              return;
            }
          }
        }
      } else { // modificar el documento
        if (this.permisosCartera !== null) {
          if (this.permisosCartera.PERTODO === 0) {
            if (this.permisosCartera.PERACTUALIZACION === 0) {
              this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
              return;
            }
          }
        }
      }

      // validar la fecha de cobro
      if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length > 0) {
        const strNOVFECCAN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOVFECCAN').CFG_VALOR1 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOVFECCAN').CFG_VALOR1;
        if (strNOVFECCAN === 0) {
          if (!this.controlarFechaCobro()) {
            this.mensajeAlerta('info', 'Información', 'La fecha del cobro es menor a la fecha de los documentos.');
            return;
          }
        }
      }

      // validar que se haya seleccionado el tipo de documento
      if (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).length === 0) {
        this.mensajeAlerta('info', 'Información', 'Debe escoger el tipo de documento.');
        return;
      }

      // verificar que se haya seleccionado un cliente
      if (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).length === 0) {
        this.mensajeAlerta('info', 'Información', 'No ha seleccionado el cliente.');
        return;
      } else { // verificar la existencia del cliente
        if (await this.existeCliente() === false) {
          this.mensajeAlerta('info', 'Información', 'No existe el cliente.');
          document.getElementById('cliente').focus();
          return;
        }
      }

      // verificar la existencia del vendedor
      if (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO).length > 0) {
        if (await this.existeVendedor() === false) {
          this.mensajeAlerta('info', 'Información', 'No existe el vendedor.');
          document.getElementById('vendedor').focus();
          return;
        }
      }

      // verificar que el número de comprobante(documento) no esté en blanco
      // cuando sea una factura
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
        if (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).trim().length === 0) {
          this.mensajeAlerta('info', 'Información', 'Debe ingresar el número del documento.');
          return;
        }
      }

      // verificar que el número de comprobante sea único
      if (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length > 0) {
        if (await this.verificarComprobanteUnico() === false) {
          this.mensajeAlerta('info', 'Información', 'El número de comprobante ya existe.');
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
          document.getElementById('numeroCartera').focus();
          return;
        }
      }

      // verificar que el importe no sea mayor que el saldo y que alguna
      // factura haya sido afectada en caso de una cancelación

      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
        if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length > 0) {
          if (this.verificarImporteDoc() === false) {
            return;
          }
        }
      }

      // validar que el total por cobrar sea igual al total de la forma de
      // pago
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {

        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB') {
          if (await this.verificarCobroDocVencidos() > 0) {
            intTotalDiasMora = await this.verificarDiasMoraDoc();
            if (this.verificaSiAgregoDatosMora(intTotalDiasMora) === false) {
              this.mensajeAlerta('warn', 'Advertencia', 'Falta ingresar el valor de comisión por mora y comisión por cobranza');
              return;
            }
          }
        }

        this.valorExcedeFormaPago();

        const strANTEXCPAG = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ANTEXCPAG').CFG_VALOR1 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ANTEXCPAG').CFG_VALOR1;
        if (strANTEXCPAG === 0) { // si no Permitir generar un anticipo a partir del valor que excede la forma de pago
          if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO) !== Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR)) {
            this.mensajeAlerta('info', 'Información', 'El total de la forma de pago es distinto al total por cobrar');
            return;
          }
        } else {
          if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO) !==
            (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) + Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO))) {
            this.mensajeAlerta('info', 'Información', 'El total de la forma de pago es distinto al total por cobrar');
            return;
          }
        }
      }

      // validar que el total por cobrar sea igual al total de la forma de pago
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
        if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO) !==
          Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC)) {
          this.mensajeAlerta('info', 'Información', 'El total de la forma de pago es distinto al total de los documentos');
          return;
        }
      }

      // verificar el importe que sea mayor a cero
      /*  console.log(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE);
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE !== null) {
        if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE) == 0) {
          this.mensajeAlerta('info', 'Información', 'El total de la forma de pago es distinto al total de los documentos');
          return;
        }
      } */

      // VALIDACIONES DE LAS RETENCIONES
      if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length > 0) {
        if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length > 0) {
          this.eliminarLineaRetEnBlanco();
          if (await this.faltaDatosRet() === true) {
            return;
          }
        }
      }

      // VALIDACIONES DE LOS OTROS GASTOS
      if (this.cxcCarteraService.cxctrnotrosgarray[this.indicador].length > 0) {
        this.eliminarUltimaLineaOtrosG();
        if (await this.faltaDatosOtrosG() === true) {
          return;
        }
      }

      // VALIDACIONES DE LA FORMA DE PAGO
      if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length > 0) {
        this.eliminarUltimaLineaFP();
        if (await this.faltaDatosFP()) {
          return;
        }

        if (await this.verificarCodSri() === false) {
          this.mensajeAlerta('info', 'Información', 'El código SRI en la forma de pago es incorrecto, por favor verifique');
          return;
        }
      }

      /* // VALIDACIONES DE VARIOS
      if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length > 0) {
        this.eliminarUltimaLineaVarios()
        if (await this.faltaDatosVarios() === true) {
          return;
        }	
      } */
      await this.guardarCartera();

    }

    if (this.activeIndex === 3) {
      this.validacionTiposSri();
    }
  }

  async guardar() {

    if (this.activeIndex === 0) {
      this.spin=true//CDPJ
      // validar la fecha de cobro
      if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length > 0) {
        const strNOVFECCAN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOVFECCAN').CFG_VALOR1 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOVFECCAN').CFG_VALOR1;
        if (strNOVFECCAN === 0) {
          if (!this.controlarFechaCobro()) {
            this.mensajeAlerta('info', 'Información', 'La fecha del cobro es menor a la fecha de los documentos.');
            this.spin=false//CDPJ
            return;
          }
          //GSRF
          if (!this.controlarFechaforpago()) {
            this.mensajeAlerta('info', 'Información', 'La fecha de pago es menor a la fecha de los documentos.');
            this.spin=false//CDPJ
            return;
          }
          if (!this.controlarFechaforpagodev()) {
            this.mensajeAlerta('info', 'Información', 'La fecha de pago es menor a la fecha de los documentos.');
            this.spin=false//CDPJ
            return;
          }
          //GSRF
        }
      }
      console.log(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
      // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPago.objeto];
      if (this.permisosCartera.PERACTUALIZACION === 1) {
        if (this.booleanNumero === false) {
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
            if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE !== '') {
              if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
                const exis = await this.cxcCarteraService.verificarComprobanteUnico('N',
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, '');

                if (exis[0][':B1'] !== '0' && exis[0][':B1'] !== null && exis[0][':B1'] !== undefined) {
                  this.message.add({
                    key: 'cartera',
                    severity: 'error',
                    summary: 'Inserción de Cartera',
                    detail: 'El numero de comprobante ya existe'
                  });
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
                  document.getElementById('numeroCartera').focus();
                  this.spin=false//CDPJ
                  return;
                }

              } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
                let numeroFac = '';
                if (this.cxctrnresumen.length !== 0) {
                  for (const resumen of this.cxctrnresumen) {
                    if (resumen.SELECCIONADO === true) {
                      numeroFac = resumen.TRNCOBRO_NRODOC;
                      const exis = await this.cxcCarteraService.verificarComprobanteUnico('M',
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, numeroFac).catch(e=>{
                          this.spin=false//CDPJ
                        });
                      console.log(exis);
                      if (exis[0][':B1'] !== '0') {
                        this.message.add({
                          key: 'cartera',
                          severity: 'error',
                          summary: 'Inserción de Cartera',
                          detail: 'El numero de comprobante ya existe'
                        });
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
                        document.getElementById('numeroCartera').focus();
                        this.spin=false//CDPJ
                        return;
                      } else {

                      }

                    }
                  }
                }
              }
              if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Inserción de Cartera',
                  detail: 'Escoja un tipo de documento'
                });
                this.spin=false//CDPJ
                return;
              } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Inserción de Cartera',
                  detail: 'Escoja un cliente'
                });
                this.spin=false//CDPJ
                return;
              } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Inserción de Cartera',
                  detail: 'Escoja un vendedor'
                });
                this.spin=false//CDPJ
                return;
              } else {
                let auxiliar = 0;
                let seleccionados = 0;
                if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    if (resumen.SELECCIONADO === true) {
                      seleccionados++;
                    }
                  });

                  if (seleccionados === 0) {
                    this.message.add({
                      key: 'cartera',
                      severity: 'error',
                      summary: 'Inserción de Cartera',
                      detail: 'Ningún documento ha sido ' +
                        'seleccionado.'
                    });
                    this.spin=false//CDPJ
                    return;
                  }

                  if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
                    this.message.add({
                      key: 'cartera',
                      severity: 'error',
                      summary: 'Inserción de Cartera',
                      detail: 'El total debe ser diferente de cero'
                    });
                    this.spin=false//CDPJ
                    return;
                  }
                }
                if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
                  for (const formap of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]){
                  //this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formap) => {             
                    if (formap.TRNPAGO_TIPO === 'TA' || formap.TRNPAGO_TIPO === 'CH') {
                      if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') { // formap.TRNPAGO_NUMERO
                        this.message.add({
                          key: 'cartera',
                          severity: 'error',
                          summary: 'Inserción de Cartera',
                          detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
                        });
                        this.spin=false//CDPJ
                        auxiliar++;
                      }
                    }
                   
                    let fecenc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
                    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
                      fecenc = this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                    }
                    console.log(1, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
                    /* if (formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { // formap.TRNPAGO_TIPO === 'DP' || 
                      if (formap.TRNPAGO_FECHA !== fecenc) {
                        this.message.add({
                          key: 'cartera',
                          severity: 'error',
                          summary: 'Inserción de Cartera',
                          detail: 'Fecha de pago incorrecta'
                        });
                        auxiliar++;
                      }
                    } */
                  }//);
                  if (auxiliar === 0) {
                    this.guardarCartera();
                  }
                } else if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
                  let auxiliar1 = 0;
                  let cont = 0;
                  let nroretencion = '';
                  this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((ret) => {
                    if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
                      this.message.add({
                        key: 'cartera',
                        severity: 'error',
                        summary: 'Inserción de Cartera',
                        detail: 'No se puede insertar el registro por que falta el código o número de retención'
                      });
                      this.spin=false//CDPJ
                      auxiliar1++;
                    }

                    // Verificar número de la retención

                    cont++;
                    if (cont > 1) {
                      if (nroretencion !== ret.TRNRETENCION_NRO) {
                        this.message.add({
                          key: 'pagcon',
                          severity: 'error',
                          summary: 'Error',
                          detail: 'El número de la retención debe ser el mismo'
                        });
                        this.spin=false//CDPJ
                        auxiliar1++;
                      }
                    } else {
                      nroretencion = ret.TRNRETENCION_NRO;
                    }
                  });

                  if (auxiliar1 === 0) {
                    this.guardarCartera();
                  }
                } else {
                  this.guardarCartera();
                }
              }
            } else {
              if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Inserción de Cartera',
                  detail: 'Escoja un tipo de documento'
                });
                this.spin=false//CDPJ
                return;
              } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Inserción de Cartera',
                  detail: 'Escoja un cliente'
                });
                this.spin=false//CDPJ
                return;
              } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Inserción de Cartera',
                  detail: 'Escoja un vendedor'
                });
                this.spin=false//CDPJ
                return;
              } else {
                let auxiliar = 0;
                let seleccionados = 0;
                if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    if (resumen.SELECCIONADO === true) {
                      seleccionados++;
                    }
                  });
                  if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
                    this.message.add({
                      key: 'cartera',
                      severity: 'error',
                      summary: 'Inserción de Cartera',
                      detail: 'El total debe ser diferente de cero'
                    });
                    this.spin=false//CDPJ
                    return;
                  }
                  if (seleccionados === 0) {
                    this.message.add({
                      key: 'cartera',
                      severity: 'error',
                      summary: 'Inserción de Cartera',
                      detail: 'Ningún documento ha sido ' +
                        'seleccionado.'
                    });
                    this.spin=false//CDPJ
                    return;
                  }
                }

                /* if (seleccionados === 0) {
                  this.message.add({
                    key: 'cartera',
                    severity: 'error',
                    summary: 'Inserción de Cartera',
                    detail: 'Ningún documento ha sido ' +
                      'seleccionado.'
                  });
                  return;
                } */
                if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
                  //this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formap) => {
                    for (const formap of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]){
                    
                    if (formap.TRNPAGO_TIPO === 'TA' || formap.TRNPAGO_TIPO === 'CH') { // formap.TRNPAGO_TIPO === 'DP' || formap.TRNPAGO_TIPO === 'CH'
                      if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') {
                        this.message.add({
                          key: 'cartera',
                          severity: 'error',
                          summary: 'Inserción de Cartera',
                          detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
                        });
                        this.spin=false//CDPJ
                        auxiliar++;
                      }
                    }
                    let fecenc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
                    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
                      fecenc = this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                    }
                    console.log(2, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
                    /* if ( formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { // formap.TRNPAGO_TIPO === 'DP' 
                      if (formap.TRNPAGO_FECHA !== fecenc) {
                        this.message.add({
                          key: 'cartera',
                          severity: 'error',
                          summary: 'Inserción de Cartera',
                          detail: 'Fecha de pago incorrecta'
                        });
                        auxiliar++;
                      }
                    } */
                  }//);
                  if (auxiliar === 0) {
                    this.guardarCartera();
                  }
                } else if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
                  let auxiliar1 = 0;
                  let cont = 0;
                  let nroretencion = '';
                  this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((ret) => {
                    if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
                      this.message.add({
                        key: 'cartera',
                        severity: 'error',
                        summary: 'Inserción de Cartera',
                        detail: 'No se puede insertar el registro por que falta el código o número de retención'
                      });
                      this.spin=false//CDPJ
                      auxiliar1++;
                    }

                    // Verificar número de la retención

                    cont++;
                    if (cont > 1) {
                      if (nroretencion !== ret.TRNRETENCION_NRO) {
                        this.message.add({
                          key: 'pagcon',
                          severity: 'error',
                          summary: 'Error',
                          detail: 'El número de la retención debe ser el mismo'
                        });
                        this.spin=false//CDPJ
                        auxiliar1++;
                      }
                    } else {
                      nroretencion = ret.TRNRETENCION_NRO;
                    }
                  });

                  if (auxiliar1 === 0) {
                    this.guardarCartera();
                  }
                } else {
                  this.guardarCartera();
                }
              }
            }
          } else {
            if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
              if (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).trim().length === 0) {
                this.message.add({
                  key: 'cartera',
                  severity: 'info',
                  summary: 'Inserción de Cartera',
                  detail: 'Debe ingresar el número del documento.'
                });
                this.spin=false//CDPJ
                return;
              }
            }

            if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Inserción de Cartera',
                detail: 'Escoja un tipo de documento'
              });
              this.spin=false//CDPJ
              return;
            } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Inserción de Cartera',
                detail: 'Escoja un cliente'
              });
              this.spin=false//CDPJ
              return;
            } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Inserción de Cartera',
                detail: 'Escoja un vendedor'
              });
              this.spin=false//CDPJ
              return;
            } else {
              let auxiliar = 0;
              let seleccionados = 0;
              if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                  if (resumen.SELECCIONADO === true) {
                    seleccionados++;
                  }
                });

                if (seleccionados === 0 &&
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ND') {
                  this.message.add({
                    key: 'cartera',
                    severity: 'error',
                    summary: 'Inserción de Cartera',
                    detail: 'Ningún documento ha sido ' +
                      'seleccionado.'
                  });
                  this.spin=false//CDPJ
                  return;
                }
                console.log(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);

                if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'NC'
                  && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'RT') {
                  if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
                    this.message.add({
                      key: 'cartera',
                      severity: 'error',
                      summary: 'Inserción de Cartera',
                      detail: 'El total debe ser diferente de cero'
                    });
                    this.spin=false//CDPJ
                    return;
                  }
                }
              }
              if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
                this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formap) => {
                  if (formap.TRNPAGO_TIPO === 'TA' || formap.TRNPAGO_TIPO === 'CH') {
                    if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') {
                      this.message.add({
                        key: 'cartera',
                        severity: 'error',
                        summary: 'Inserción de Cartera',
                        detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
                      });
                      this.spin=false//CDPJ
                      auxiliar++;
                    }
                  }
                  let fecenc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
                  if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
                    fecenc = this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                  }
                  console.log(3, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
                  /* if (formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { // formap.TRNPAGO_TIPO === 'DP' || 
                    if (formap.TRNPAGO_FECHA !== fecenc) {
                      this.message.add({
                        key: 'cartera',
                        severity: 'error',
                        summary: 'Inserción de Cartera',
                        detail: 'Fecha de pago incorrecta'
                      });
                      auxiliar++;
                    }
                  } */
                });
                if (auxiliar === 0) {
                  this.guardarCartera();
                }
              } else if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
                let auxiliar1 = 0;
                let cont = 0;
                let nroretencion = '';
                if (this.verificarImporteTotalRet() === false) {
                  auxiliar1++;
                }
                this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((ret) => {
                  if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
                    this.message.add({
                      key: 'cartera',
                      severity: 'error',
                      summary: 'Inserción de Cartera',
                      detail: 'No se puede insertar el registro por que falta el código o número de retención'
                    });
                    this.spin=false//CDPJ
                    auxiliar1++;
                  }

                  // Verificar número de la retención

                  cont++;
                  if (cont > 1) {
                    if (nroretencion !== ret.TRNRETENCION_NRO) {
                      this.message.add({
                        key: 'pagcon',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'El número de la retención debe ser el mismo'
                      });
                      this.spin=false//CDPJ
                      auxiliar1++;
                    }
                  } else {
                    nroretencion = ret.TRNRETENCION_NRO;
                  }
                });

                if (auxiliar1 === 0) {
                  this.guardarCartera();
                }
              } else {
                this.guardarCartera();
              }
            }
          }
        } else if (this.booleanNumero === true) {
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
            this.message.add({
              key: 'cartera',
              severity: 'error',
              summary: 'Inserción de Cartera',
              detail: 'Escoja un tipo de documento'
            });
            this.spin=false//CDPJ
            return;
          } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
            this.message.add({
              key: 'cartera',
              severity: 'error',
              summary: 'Inserción de Cartera',
              detail: 'Escoja un cliente'
            });
            this.spin=false//CDPJ
            return;
          } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
            this.message.add({
              key: 'cartera',
              severity: 'error',
              summary: 'Inserción de Cartera',
              detail: 'Escoja un vendedor'
            });
            this.spin=false//CDPJ
            return;
          } else {
            let seleccionados = 0;
            if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
              this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                if (resumen.SELECCIONADO === true) {
                  seleccionados++;
                }
              });

              if (seleccionados === 0) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Inserción de Cartera',
                  detail: 'Ningún documento ha sido ' +
                    'seleccionado.'
                });
                this.spin=false//CDPJ
                return;
              }

              if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'NC'
                && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'RT') {
                if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
                  this.message.add({
                    key: 'cartera',
                    severity: 'error',
                    summary: 'Inserción de Cartera',
                    detail: 'El total debe ser diferente de cero'
                  });
                  this.spin=false//CDPJ
                  return;
                }
              }
            }
            if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
              let auxiliar = 0;
              this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formap) => {
                if (formap.TRNPAGO_TIPO === 'TA' || formap.TRNPAGO_TIPO === 'CH') { // formap.TRNPAGO_TIPO === 'DP' || formap.TRNPAGO_TIPO === 'CH'
                  if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') {
                    this.message.add({
                      key: 'cartera',
                      severity: 'error',
                      summary: 'Inserción de Cartera',
                      detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
                    });
                    this.spin=false//CDPJ
                    auxiliar++;
                  }
                }

                let fecenc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
                if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
                  fecenc = this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                }
                console.log(4, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
                /* if (formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { //formap.TRNPAGO_TIPO === 'DP'
                  if (formap.TRNPAGO_FECHA !== fecenc) {
                    this.message.add({
                      key: 'cartera',
                      severity: 'error',
                      summary: 'Inserción de Cartera',
                      detail: 'Fecha de pago incorrecta'
                    });
                    auxiliar++;
                  }
                } */
              });
              if (auxiliar === 0) {
                this.guardarCartera();
              }
            } else if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {

              let auxiliar1 = 0;
              let cont = 0;
              let nroretencion = '';
              if (this.verificarImporteTotalRet() === false) {
                auxiliar1++;
              }

              this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((ret) => {
                if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
                  this.message.add({
                    key: 'cartera',
                    severity: 'error',
                    summary: 'Inserción de Cartera',
                    detail: 'No se puede insertar el registro por que falta el código o número de retención'
                  });
                  this.spin=false//CDPJ
                  auxiliar1++;
                }

                // Verificar número de la retención

                cont++;
                if (cont > 1) {
                  if (nroretencion !== ret.TRNRETENCION_NRO) {
                    this.message.add({
                      key: 'pagcon',
                      severity: 'error',
                      summary: 'Error',
                      detail: 'El número de la retención debe ser el mismo'
                    });
                    this.spin=false//CDPJ
                    auxiliar1++;
                  }
                } else {
                  nroretencion = ret.TRNRETENCION_NRO;
                }
              });

              if (auxiliar1 === 0) {
                this.guardarCartera();
              }
            } else {
              this.guardarCartera();
            }
          }
        }
      } else if (this.permisosCartera.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Actualización de Cartera',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
        this.spin=false//CDPJ
      }
    }

    if (this.activeIndex === 3) {
      this.validacionTiposSri();
    }
  }

  generarasiento() {
    if (this.btnGuardar === true) {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Asiento Cartera',
          detail: 'No se puede generar un asiento debido a que el documento proviene de otro origen'
        });
        return;
      } else {
        if (this.saldoInicial === false) {
          this.confirmarAsiento();
        }
      }
    } else {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Asiento Cartera',
        detail: 'No se puede generar un asiento debido a que la cartera no esta guardada'
      });
    }
  }

  asientover() {
    this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
      this.asiento = res[0].ASI_NRO;
      if (this.asiento === null) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Visualizacion de Asiento Contable',
          detail: 'Esta cartera no tiene un asiento contable'
        });
      } else {
        this.auxiliar++;
        // this.displayDialogAsiento = true;
        this.displayAsiContable = true;
      }
    });
  }

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir'
      && valor !== 'facturasAbonadas') {
      let dia = new Date().getDate();
      let mes = new Date().getMonth();
      let anio = new Date().getFullYear();
      try {
        // Verifica el periodo contable
        dia = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN === undefined ? dia : this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.getDate();
        mes = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN === undefined ? mes : this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.getMonth();
        anio = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN === undefined ? anio : this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.getFullYear();
      } catch {
        const tmpfecha = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.substr(0, 10);
        console.log(tmpfecha);
        const splfec = tmpfecha.split('/');
        const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
        dia = fecha.getDate();
        mes = fecha.getMonth();
        anio = fecha.getFullYear();
      }

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.message.add({
          key: 'cartera',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }

    if (valor === 'Primero') {
      this.primero();
    } else if (valor === 'Siguiente') {
      this.siguiente();
    } else if (valor === 'Anterior') {
      this.anterior();
    } else if (valor === 'Ultimo') {
      this.ultimo();
    } else if (valor === 'Nuevo') {
      this.nuevo();
    } else if (valor === 'Borrar') {
      this.borrar();
    } else if (valor === 'Guardar') {
      // this.guardarNew();
      this.guardar();
    } else if (valor === 'Cancelar') {
      this.cancelarEdicionCartera();
    } else if (valor === 'Anular') {
      if(await this.anularCartera() === true){
        return;
      }
      this.abrirClave('clave');
    } else if (valor === 'Buscar') {
      this.buscarCartera();
    } else if (valor === 'Asiento') {
      this.generarasiento();
    } else if (valor === 'AsientoVer') {
      this.asientover();
    } else if (valor === 'Firmar') {
      this.insertarEleTrnDoc();
    } else if (valor === 'EnviarCorreo') {
      this.reenviarCorreo();
    } else if (valor === 'facturasAbonadas') {
      this.abrirFacturasAbonadas();
    }

    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
        //CDPJ
        if (valor === 'FormularioCorreos'){
          this.strNombreForma = this.init.tabs[this.init.tabindex].component;
          this.auxreporte++;
          this.displayDialogfrmcorreos=true
        }
        //CDPJ
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  nuevaCartera(parametro) {
    this.bolNuevo = true;
    if (parametro === 'N') { // N para nuevo
      this.btnNuevo = true;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal').style.opacity = '1.0';
      /*this.columnDefsDVFDetalle.map((columna) => {
        columna.editable = true;
      });*/
      // this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
      this.editarCeldas = false;
      this.tipodetalleactivo = 'all';
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
      this.botonPago = true;
      this.botonAnular = true;
      this.botonBuscar = true;
      this.botonAsiento = true;
      this.botonVerAsiento = true;
      this.porcIvaBoolean = true;
      this.booleanDatosPantalla = false;
      this.tabVarios = false;
      this.cxcCarteraService.dblTotalRetarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalDocarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalFormaDevarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalNDarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalFormaPagoarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalOtrosGarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalNCarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalCobrararray[this.indicador] = 0;
      this.tabDebitoBancario = false;
      this.tabFormaPagoDev = false;
      this.tabRetenciones = false;
      this.tabOtrosGastos = false;
      this.tabEstadoCuentaAnt = false;
      this.tabFormaPago = false;
      this.impresoString = '';
      this.retencionElect = false;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = {
        SALDO: '',
        TRNCOBRO_TIPODOC: '',
        TOTALFORMAPAGO: 0,
        TOTALND: 0,
        TRNCOBRO_IMPORTE: 0,
        TOTALNC: 0,
        TOTALOTROSG: 0,
        TOTALRET: 0,
        TOTALACOBRAR: 0,
        TOTALDOC: 0,
        BAN_BANCO: '',
        BAN_CODIGO: '',
        TRNCOBRO_NROCOMPROBANTE: '',
        TRNCOBRO_SALDOINI: '',
        CON_CODIGO: '',
        TRNCOBRO_NRODOC: '',
        TOTALANTICIPO: '',
        TRNCOBRO_FECHAVENCE: '',
        CLI_NOMBRE: '',
        REALIZADO: '',
        ORIGEN: '',
        VEN_NOMBRE: '',
        APLORG_CODIGO: 'CXC',
        USU_IDENTIFICACION: this.usuario.identificacion,
        TRNCOBRO_CONCEPTO: '',
        BOD_CODIGO: '',
        TRNCOBRO_FECHATRN: new Date(),
        VEN_CODIGO: '',
        CLI_CODIGO: '',
        ASI_NRO: '',
        CABROLADMN_CODIGO: '',
        CABROLVEND_CODIGO: '',
        COM_CODIGO: '01',
        COM_CODIGO_CXP: '01',
        COM_TIPOAMBFACTELEC: '',
        EMP_CODIGO: '',
        EMP_CODIGOVEND: '',
        TRNCOBRO_ANULADO: '',
        TRNCOBRO_CAMBIO: '',
        TRNCOBRO_CUADRE: '',
        TRNCOBRO_EFECTIVO: '',
        TRNCOBRO_FLAG: '1',
        TRNCOBRO_IMPRESO: '',
        TRNCOBRO_LOCALIZACIONXMLRET: '',
        TRNCOBRO_NRODOC_CXP: '',
        TRNCOBRO_NRODOC_OLD: '',
        TRNCOBRO_PATHPDF_FE: '',
        TRNCOBRO_PATHXMLNOAUTO_FE: '',
        TRNCOBRO_PATHXMLRET: '',
        TRNCOBRO_REFERENCIAEXTERNA: '',
        TRNCOBROND_AUTORIZACION_FE: '',
        TRNCOBROND_CLAVEACCESO_FE: '',
        TRNCOBROND_ESTADO_FE: '',
        TRNCOBROND_FECHAAUT_FE: '',
        TRNCOBROND_NUMFACTELEC: ''
      };
      document.getElementById('cliente').focus();
      this.cxcCarteraService.getOrigen(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe((resApl) => {
        if (resApl.length !== 0) {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-' + resApl[0].APLORG_NOMBRE;
        } else {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-';
        }
      });
      this.cxcCarteraService.getRealizado(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION).subscribe((resReal) => {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].REALIZADO = resReal[0].NOMBRE;
      });
      this.indice = -1;
      this.booleanBodega = false;
      this.saldoInicial = false;
      this.booleanAnulado = false;
      this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
      this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
      this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
      this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = [];
      this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = [];
      this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
      this.lblAplicaRegMicroempresas = '';
      this.lblAplicaRegMicroempresas = '';
      this.bolestcta = false;
      this.bolsaldo = false;
      this.btneditConcil = true;
    } else if (parametro === 'C') { // C para cancelar
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.botonUltimo = false;
      this.botonAnterior = false;
      this.botonPrimero = false;
      this.botonSiguiente = false;
      this.botonBuscar = false;
      this.botonPago = false;
      this.botonAnular = false;
      this.botonAsiento = false;
      this.botonVerAsiento = false;
      this.porcIvaBoolean = true;
      this.booleanDatosPantalla = false;
      this.tabVarios = false;
      this.tabDebitoBancario = false;
      this.tabFormaPagoDev = false;
      this.tabRetenciones = false;
      this.tabOtrosGastos = false;
      this.cxcCarteraService.dblTotalRetarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalDocarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalFormaDevarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalNDarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalFormaPagoarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalOtrosGarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalNCarray[this.indicador] = 0;
      this.cxcCarteraService.dblTotalCobrararray[this.indicador] = 0;
      this.tabEstadoCuentaAnt = false;
      this.tabFormaPago = false;
      this.impresoString = '';
      this.retencionElect = false;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = {
        SALDO: '',
        TRNCOBRO_TIPODOC: '',
        TOTALFORMAPAGO: 0,
        TOTALND: 0,
        TRNCOBRO_IMPORTE: 0,
        TOTALNC: 0,
        TOTALOTROSG: 0,
        TOTALRET: 0,
        TOTALACOBRAR: 0,
        TOTALDOC: 0,
        BAN_BANCO: '',
        BAN_CODIGO: '',
        TRNCOBRO_NROCOMPROBANTE: '',
        TRNCOBRO_SALDOINI: '',
        CON_CODIGO: '',
        TRNCOBRO_NRODOC: '',
        TOTALANTICIPO: '',
        TRNCOBRO_FECHAVENCE: '',
        CLI_NOMBRE: '',
        REALIZADO: '',
        ORIGEN: '',
        VEN_NOMBRE: '',
        APLORG_CODIGO: 'CXC',
        USU_IDENTIFICACION: this.usuario.identificacion,
        TRNCOBRO_CONCEPTO: '',
        BOD_CODIGO: '',
        TRNCOBRO_FECHATRN: new Date(),
        VEN_CODIGO: '',
        CLI_CODIGO: '',
        ASI_NRO: '',
        CABROLADMN_CODIGO: '',
        CABROLVEND_CODIGO: '',
        COM_CODIGO: '01',
        COM_CODIGO_CXP: '01',
        COM_TIPOAMBFACTELEC: '',
        EMP_CODIGO: '',
        EMP_CODIGOVEND: '',
        TRNCOBRO_ANULADO: '',
        TRNCOBRO_CAMBIO: '',
        TRNCOBRO_CUADRE: '',
        TRNCOBRO_EFECTIVO: '',
        TRNCOBRO_FLAG: '1',
        TRNCOBRO_IMPRESO: '',
        TRNCOBRO_LOCALIZACIONXMLRET: '',
        TRNCOBRO_NRODOC_CXP: '',
        TRNCOBRO_NRODOC_OLD: '',
        TRNCOBRO_PATHPDF_FE: '',
        TRNCOBRO_PATHXMLNOAUTO_FE: '',
        TRNCOBRO_PATHXMLRET: '',
        TRNCOBRO_REFERENCIAEXTERNA: '',
        TRNCOBROND_AUTORIZACION_FE: '',
        TRNCOBROND_CLAVEACCESO_FE: '',
        TRNCOBROND_ESTADO_FE: '',
        TRNCOBROND_FECHAAUT_FE: '',
        TRNCOBROND_NUMFACTELEC: ''
      };
      this.booleanBodega = false;
      this.booleanAnulado = false;
      this.saldoInicial = false;
      this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
      this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
      this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
      this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = [];
      this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = [];
      this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
      this.lblAplicaRegMicroempresas = '';
      this.btneditConcil = true;
    }
  }

  async guardarCarteraantes() {
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('-')) {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN =
        this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,
          'dd/MM/yyyy');
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('/')) {
    }
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE.toString().includes('-')) {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
        this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE,
          'dd/MM/yyyy');
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE.toString().includes('/')) {
    }
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE === 0) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'El valor del importe no puede ser cero'
        });
        return;
      }
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
        const dblTotalVarios = this.calcularTotalVarios();
        const dblImporteDoc = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALND);
        const strNombreDoc = 'Nota de Débito';
        console.log('1', dblImporteDoc, dblTotalVarios);
        if (this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].length === 0) {
          if (dblImporteDoc === dblTotalVarios) {
            this.message.add({
              key: 'cartera',
              severity: 'error',
              summary: 'Información',
              detail: 'El total de Varios debe ser igual al ' +
                'total del documento ' + strNombreDoc
            });
            return;
          }
        }
      }

      if (this.saldoInicial === true) {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 1;
      } else if (this.saldoInicial === false) {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 0;
      }
      if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO = this.cxcCarteraService.cxctrnvariosarray[this.indicador][0].BAN_CODIGO;
      }
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
        this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((res) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = res[0].TRNCOBRO_NRODOC;
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = res[0].TRNCOBRO_NROCOMPROBANTE;
            this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
              this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(res[0].TRNCOBRO_NRODOC + '/' + res[0].TRNCOBRO_NROCOMPROBANTE), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Inserción de Cartera',
                    detail: 'Se ha insertado el documento exitosamente'
                  });
                  if (this.tabDebitoBancario === true) {
                    this.cxcCarteraService.generarNDBancario(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe(() => {
                        if (this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].length !== 0) {
                          this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].map((debito) => {
                            if (debito.SELECCIONADO === true) {
                              this.cxcCarteraService.insertarTrnBanDoc(debito, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                              });
                            }
                          });
                        }
                        this.cxcCarteraService.generarAsientoNDBan(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
                          this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                              this.message.add({
                                key: 'cartera',
                                severity: 'success',
                                summary: 'Asiento Contable',
                                detail: 'Se ha generado el asiento contable'
                              });
                            });
                        });
                      });
                  }
                  if (this.tabVarios === true) {
                    this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                      this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'E',
                        '', '01', '', '', '', '').subscribe(() => {
                        });
                      if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
                        this.cxcCarteraService.cxctrnvariosarray[this.indicador].map((vario) => {
                          vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                          vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                          vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
                          if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
                            vario.TRNVARIOS_IMPORTE = vario.DEBE;
                            vario.TRNBAN_TIPO = 'DP';//------NO
                          } else if (vario.HABER !== '' && Number(vario.HABER) > 0) {
                            vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
                            vario.TRNBAN_TIPO = 'RE';///-------NO
                          } else {
                            vario.TRNBAN_TIPO = '';
                          }
                          this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
                            vario.TRNVARIOS_LINEA = vario.LINEA;
                            this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                              });
                          });
                          this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
                            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
                            this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                            this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                                this.message.add({
                                  key: 'cartera',
                                  severity: 'success',
                                  summary: 'Asiento Contable',
                                  detail: 'Se ha generado el asiento contable'
                                });
                              });
                          });
                        });
                      }
                    });
                  }
                });
            });
            // this.cambiarBotones();
          });
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
        if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO =
            this.cxcCarteraService.cxctrnvariosarray[this.indicador][0].BAN_CODIGO;
        }
        this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((res) => {
            this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
              this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(res[0].TRNCOBRO_NRODOC + '/' + res[0].TRNCOBRO_NROCOMPROBANTE), 'A',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Inserción de Cartera',
                    detail: 'Se ha actualizado el documento exitosamente'
                  });
                  if (this.tabDebitoBancario === true) {
                    this.cxcCarteraService.generarNDBancario(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe(() => {
                        if (this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].length !== 0) {
                          this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].map((debito) => {
                            if (debito.SELECCIONADO === true) {
                              this.cxcCarteraService.insertarTrnBanDoc(debito, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                              });
                            }
                          });
                        }
                        this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
                          this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                              this.message.add({
                                key: 'cartera',
                                severity: 'success',
                                summary: 'Asiento Contable',
                                detail: 'Se ha generado el asiento contable'
                              });
                            });
                        });
                      });
                  }
                  if (this.tabVarios === true) {
                    this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                      this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'E',
                        '', '01', '', '', '', '').subscribe(() => {
                        });
                      if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
                        this.cxcCarteraService.cxctrnvariosarray[this.indicador].map((vario) => {
                          vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                          vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                          vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
                          if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
                            vario.TRNVARIOS_IMPORTE = vario.DEBE;
                            vario.TRNBAN_TIPO = 'DP';///--------NO
                          } else if (vario.HABER !== '' && Number(vario.HABER) > 0) {
                            vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
                            vario.TRNBAN_TIPO = 'RE';//------NO
                          } else {
                            vario.TRNBAN_TIPO = '';
                          }
                          this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
                            vario.TRNVARIOS_LINEA = vario.LINEA;
                            this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                              });
                          });
                          this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
                            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
                            this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                            this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                                this.message.add({
                                  key: 'cartera',
                                  severity: 'success',
                                  summary: 'Asiento Contable',
                                  detail: 'Se ha generado el asiento contable'
                                });
                              });
                          });
                        });
                      }
                    });
                  }
                });
            });
            // this.cambiarBotones();
            // this.consultarPestañas();
          });
      }
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) !==
        Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO)) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'El total de la forma de pago es distinto al total a cobrar'
        });
        return;
      }
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE === 0) {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Inserción de Cartera',
            detail: 'El valor del importe no puede ser cero'
          });
          return;
        }
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        if (this.saldoInicial === false) {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 0;
        } else if (this.saldoInicial === true) {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 1;
        }
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
          this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
              if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NRODOC;
              }
              this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
                this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                    this.message.add({
                      key: 'cartera',
                      severity: 'success',
                      summary: 'Inserción de Cartera',
                      detail: 'Se ha insertado el documento exitosamente'
                    });
                  });
                if (this.saldoInicial === false) {
                  if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
                    this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formap) => {
                      formap.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
                        formap.TRNPAGO_CONCEPTO = '*';
                      }
                      this.cxcCarteraService.insertarFormadePago(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                          this.cxcCarteraService.generarAsientoANC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                            this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                              this.asiento = res[0].ASI_NRO;
                              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                              this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                                '', '01', '', '', '', '').subscribe(() => {
                                });
                            });
                          }, () => {
                          });
                          this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                          /*if (formap.TRNPAGO_TIPO === 'EF') {
                            this.cxcCarteraService.insertarCajaFP(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
                              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
                              this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.CAJ_CODIGO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
                                '', '01', '', '', '', '').subscribe(() => {
                              });
                            });
                          } else if (formap.TRNPAGO_TIPO === 'CH') {
                            this.cxcCarteraService.insertarBancoFP(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
                              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe((resban) => {
                              this.cxcCarteraService.actualizarCheque(formap.CAJ_CODIGO, formap.BANCLI_CODIGO).subscribe(() => {
                                this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.CAJ_CODIGO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
                                  '', '01', '', '', '', '').subscribe(() => {
                                });
                              });
                            });
                          }*/
                        }, error1 => {
                          this.message.add({
                            key: 'cartera',
                            severity: 'error',
                            summary: 'Forma de Pago',
                            detail: 'No se guardo la forma de pago, error ' + error1.error
                          });
                        });
                    });
                    this.cxcCarteraService.generarAsientoANC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                      this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                        this.asiento = res[0].ASI_NRO;
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                        this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                            this.message.add({
                              key: 'cartera',
                              severity: 'success',
                              summary: 'Asiento Contable',
                              detail: 'Se ha generado el asiento contable'
                            });
                          });
                      });
                    }, () => {
                      this.message.add({
                        key: 'cartera',
                        severity: 'error',
                        summary: 'Asiento Contable',
                        detail: 'No se ha generado el asiento contable'
                      });
                    });
                  }
                }
              });
              // this.cambiarBotones();
              // this.consultarPestañas();
              if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROCMPRAUT').CFG_VALOR1 === 1) {
                this.cxcCarteraService.actualizarNumeracion(this.numSerie).subscribe((res) => {
                  console.log(res);
                });
              }
            });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
          this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
              this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
                this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
                  });
                if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    if (resumen.SELECCIONADO === true) {
                      resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      resumen.STRCUOTAFAC = 'F';
                      if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                        resumen.STRCUOTAFAC = 'C';
                      }
                      this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                          if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                            this.actualizarCuotasPagadas(resumen);
                          }
                          this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                        });
                    }
                  });
                }
                if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.eliminarFormaPago(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                    this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formap) => {
                      if (formap.TRNPAGO_TIPO === 'DP') {
                        this.cxcCarteraService.eliminarDPBanco(formap.TRNPAGO_NUMERO).subscribe(() => {
                        });
                      }
                      formap.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
                        formap.TRNPAGO_CONCEPTO = '*';
                      }
                      this.cxcCarteraService.insertarFormadePago(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                          this.cxcCarteraService.generarAsientoANC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                            this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                              this.asiento = res[0].ASI_NRO;
                              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                              this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                                '', '01', '', '', '', '').subscribe(() => {
                                });
                            });
                          }, () => {
                          });
                          this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                        }, error1 => {
                          this.message.add({
                            key: 'cartera',
                            severity: 'error',
                            summary: 'Forma de Pago',
                            detail: 'No se guardo la forma de pago, error ' + error1.error
                          });
                        });
                    });
                  });
                }
                this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                    this.message.add({
                      key: 'cartera',
                      severity: 'success',
                      summary: 'Inserción de Cartera',
                      detail: 'Se ha insertado el documento exitosamente'
                    });
                  });
                this.cxcCarteraService.generarAsientoANC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                  this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                    this.asiento = res[0].ASI_NRO;
                    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                    this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                    this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                      '', '01', '', '', '', '').subscribe(() => {
                        this.message.add({
                          key: 'cartera',
                          severity: 'success',
                          summary: 'Asiento Contable',
                          detail: 'Se ha generado el asiento contable'
                        });
                      });
                  });
                });
              });
              // this.cambiarBotones();
              // this.consultarPestañas();
            });
        }
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
        let numeroDoc = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE =
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC;
          this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
              if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
                console.log(cobro[0]);
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NRODOC;
              }
              this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
                if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    if (resumen.SELECCIONADO === true) {
                      resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      numeroDoc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                      resumen.STRCUOTAFAC = 'F';
                      if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                        resumen.STRCUOTAFAC = 'C';
                      }
                      this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                          if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                            this.actualizarCuotasPagadas(resumen);
                          }
                          this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                        });
                    }
                  });
                }
                this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                    this.message.add({
                      key: 'cartera',
                      severity: 'success',
                      summary: 'Inserción de Cartera',
                      detail: 'Se ha insertado el documento exitosamente'
                    });
                  });
                if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((ret) => {
                    ret.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                    if (ret.CON_CODIGO === '') {
                      ret.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                    }
                    ret.CEN_CODIGO = '';
                    ret.ENCFAC_NUMERO = this.numeroFactura;
                    this.cxcCarteraService.insertarRetencion(ret, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                        if (this.retencionElect === true) {
                          this.cxcCarteraService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
                          });
                        } else if (this.retencionElect === false) {
                          this.cxcCarteraService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
                          });
                        }
                      });
                  });
                }
                if (this.cxcCarteraService.cxctrnotrosgarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.cxctrnotrosgarray[this.indicador].map((com) => {
                    if (com.CON_CODIGO === '' || com.CON_CODIGO === null) {
                      com.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                    }
                    this.cxcCarteraService.insertarComision(com, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                        com.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                        this.auditoriagral.registrarAuditoria('SACI_TRNCOMISION', String(com.TRNCOMISION_NRO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                          });
                      });
                  });
                }
                if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.eliminarFormaPago(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                    this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formap) => {
                      if (formap.TRNPAGO_TIPO === 'DP') {
                        this.cxcCarteraService.eliminarDPBanco(formap.TRNPAGO_NUMERO).subscribe(() => {
                        });
                      }
                      formap.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
                        formap.TRNPAGO_CONCEPTO = '*';
                      }
                      this.cxcCarteraService.insertarFormadePago(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                          this.cxcCarteraService.generarAsientoCI(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                            this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                              this.asiento = res[0].ASI_NRO;
                              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                              if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                                this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                                  if (resumen.SELECCIONADO === true) {
                                    resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                                    this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                                    }, error1 => {
                                      console.log(error1.error);
                                    });
                                  }
                                });
                              }
                              this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                                '', '01', '', '', '', '').subscribe(() => {
                                });
                            });
                          }, () => {
                          });


                          if (formap.TRNPAGO_TIPO === 'ANCL') {
                            const itemND: Cxctrncobro = {};
                            itemND.TRNCOBRO_NRODOC = '';
                            itemND.TRNCOBRO_NROCOMPROBANTE = '';
                            itemND.TRNCOBRO_FECHATRN = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
                            itemND.TRNCOBRO_CONCEPTO = 'NOTA DE DEBITO POR EL ANTICIPO ' + formap.TRNPAGO_NUMERO;
                            itemND.TRNCOBRO_IMPORTE = formap.TRNPAGO_IMPORTE;
                            itemND.TRNCOBRO_FECHAVENCE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE;
                            itemND.APLORG_CODIGO = 'SISTEMA';
                            itemND.TRNCOBRO_REFERENCIAEXTERNA = null;
                            itemND.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                            itemND.VEN_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO;
                            itemND.ASI_NRO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO;
                            itemND.USU_IDENTIFICACION = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION;
                            itemND.TRNCOBRO_FLAG = '1';
                            itemND.BAN_CODIGO = null;
                            itemND.TRNCOBRO_EFECTIVO = '0.0';
                            itemND.TRNCOBRO_CAMBIO = '0.0';
                            itemND.CON_CODIGO = null;
                            itemND.TRNCOBRO_SALDOINI = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI;
                            itemND.BOD_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO;

                            this.cxcCarteraService.insertarCobro(itemND, 'NDA').subscribe(data => {
                              console.log(data);
                              let strNumDocAnt = '';
                              let strTrnCobroNroDoc = '';
                              let strTrnCobroNroComprobante = '';
                              this.cxcCarteraService.erTrncobronrodoc(formap.TRNPAGO_NUMERO).subscribe(eR => {
                                console.log(eR);
                                if (eR !== undefined) {
                                  if (eR[0].TRNCOBRO_NRODOC !== null) {
                                    strNumDocAnt = eR[0].TRNCOBRO_NRODOC;
                                  }
                                }

                                for (const rs of data) {
                                  strTrnCobroNroDoc = rs.TRNCOBRO_NRODOC;
                                  strTrnCobroNroComprobante = rs.TRNCOBRO_NROCOMPROBANTE;
                                }
                                // actualizar la referencia de la nota de débito con el número del anticipo strTrnCobroNroDoc
                                this.cxcCarteraService.actualizarNotaDebitoAnticipo(strTrnCobroNroDoc, strNumDocAnt).subscribe();

                                this.cxcCarteraService.actualizarFormaPagoND(strTrnCobroNroDoc,
                                  formap.TRNPAGO_NUMERO, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe();
                              });


                            });
                          }
                          this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                        }, error1 => {
                          this.message.add({
                            key: 'cartera',
                            severity: 'error',
                            summary: 'Forma de Pago',
                            detail: 'No se guardo la forma de pago, error ' + error1.error
                          });
                        });
                    });
                  });
                }

                setTimeout(() => {
                  this.cxcCarteraService.generarAsientoCI(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                    this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                      this.asiento = res[0].ASI_NRO;
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                      if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                        this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                          if (resumen.SELECCIONADO === true) {
                            resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                            this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                            }, error1 => {
                              console.log(error1.error);
                            });
                          }
                        });
                      }
                      this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                      this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                        '', '01', '', '', '', '').subscribe(() => {
                          this.message.add({
                            key: 'cartera',
                            severity: 'success',
                            summary: 'Asiento Contable',
                            detail: 'Se ha generado el asiento contable'
                          });
                        });
                    });
                  }, error1 => {
                    this.message.add({
                      key: 'cartera',
                      severity: 'error',
                      summary: 'Asiento Contable',
                      detail: 'No se ha generado el asiento contable, error' + error1.error
                    });
                  });
                }, 500);
              });
              // this.cambiarBotones();
              // this.consultarPestañas();
              if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROCMPRAUT').CFG_VALOR1 === 1) {
                this.cxcCarteraService.actualizarNumeracion(this.numSerie).subscribe((res) => {
                  console.log(res);
                });
              }
            });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
          this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
              this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
                this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
                    if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                      this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                        if (resumen.SELECCIONADO === true) {
                          numeroDoc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                          resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                          resumen.STRCUOTAFAC = 'F';
                          if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                            resumen.STRCUOTAFAC = 'C';
                          }
                          this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                              if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                                this.actualizarCuotasPagadas(resumen);
                              }
                              this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                                '', '01', '', '', '', '').subscribe(() => {
                                });
                            });
                        }
                      });
                    }
                    if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
                      this.cxcCarteraService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' +
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC +
                        '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(() => {
                          this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((ret) => {
                            ret.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                            if (ret.CON_CODIGO === '') {
                              ret.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                            }
                            ret.CEN_CODIGO = '';
                            ret.ENCFAC_NUMERO = this.numeroFactura;
                            this.cxcCarteraService.insertarRetencion(ret, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                                if (this.retencionElect === true) {
                                  this.cxcCarteraService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
                                  });
                                } else if (this.retencionElect === false) {
                                  this.cxcCarteraService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
                                  });
                                }
                              });
                          });
                        });
                    }
                    if (this.cxcCarteraService.cxctrnotrosgarray[this.indicador].length !== 0) {
                      this.cxcCarteraService.eliminarTrnComision(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                        this.cxcCarteraService.cxctrnotrosgarray[this.indicador].map((com) => {
                          if (com.CON_CODIGO === '' || com.CON_CODIGO === null) {
                            com.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                          }
                          this.cxcCarteraService.insertarComision(com, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                              com.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                              this.auditoriagral.registrarAuditoria('SACI_TRNCOMISION', String(com.TRNCOMISION_NRO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
                                '', '01', '', '', '', '').subscribe(() => {
                                });
                            });
                        });
                      });
                    }
                    if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
                      this.cxcCarteraService.eliminarFormaPago(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                        this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formap) => {
                          if (formap.TRNPAGO_TIPO === 'DP') {
                            this.cxcCarteraService.eliminarDPBanco(formap.TRNPAGO_NUMERO).subscribe(() => {
                            });
                          }
                          formap.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                          if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
                            formap.TRNPAGO_CONCEPTO = '*';
                          }
                          this.cxcCarteraService.insertarFormadePago(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                              this.cxcCarteraService.generarAsientoCI(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                                this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                                  this.asiento = res[0].ASI_NRO;
                                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                                  if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                                    this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                                      if (resumen.SELECCIONADO === true) {
                                        resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                                        this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                                        }, error1 => {
                                          console.log(error1.error);
                                        });
                                      }
                                    });
                                  }
                                  this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                                  this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                                    '', '01', '', '', '', '').subscribe(() => {
                                    });
                                });
                              });
                              this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
                                '', '01', '', '', '', '').subscribe(() => {
                                });
                            }, error1 => {
                              this.message.add({
                                key: 'cartera',
                                severity: 'error',
                                summary: 'Forma de Pago',
                                detail: 'No se guardo la forma de pago, error ' + error1.error
                              });
                            });
                        });
                      });
                    }
                    this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
                      '', '01', '', '', '', '').subscribe(() => {
                        this.message.add({
                          key: 'cartera',
                          severity: 'success',
                          summary: 'Inserción de Cartera',
                          detail: 'Se ha insertado el documento exitosamente'
                        });
                      });
                    setTimeout(() => {
                      this.cxcCarteraService.generarAsientoCI(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                        this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                          this.asiento = res[0].ASI_NRO;
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                          if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                            this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                              if (resumen.SELECCIONADO === true) {
                                resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                                this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                                }, error1 => {
                                  console.log(error1.error);
                                });
                              }
                            });
                          }
                          this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                              this.message.add({
                                key: 'cartera',
                                severity: 'success',
                                summary: 'Asiento Contable',
                                detail: 'Se ha generado el asiento contable'
                              });
                            });
                        });
                      }, error1 => {
                        this.message.add({
                          key: 'cartera',
                          severity: 'error',
                          summary: 'Asiento Contable',
                          detail: 'No se ha generado el asiento, error ' + error1.error
                        });
                      });
                    }, 500);
                  });
              });
              // this.cambiarBotones();
              // this.consultarPestañas();
            });
        }
      }
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
      if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) !== Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO)) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'El total de la forma de pago es distinto al total a cobrar'
        });
        return;
      }
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
      this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resu) => {
        if (resu.SELECCIONADO === true) {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = resu.TRNCOBRO_IMPORTE;
        }
      });
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
        this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NROCOMPROBANTE;
            this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
              this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Inserción de Cartera',
                    detail: 'Se ha insertado el documento exitosamente'
                  });
                });
              if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                  if (resumen.SELECCIONADO === true) {
                    resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                    resumen.STRCUOTAFAC = 'F';
                    if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                      resumen.STRCUOTAFAC = 'C';
                    }
                    this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                        if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                          this.actualizarCuotasPagadas(resumen);
                        }
                        this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                          });
                      });
                  }
                });
              }
              if (this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].length !== 0) {
                this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].map((formap) => {
                  if (formap.TRNCAJ_TIPO === 'EF') {
                    this.cxcCarteraService.insertarCaja(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
                        this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                          });
                      });
                  } else {
                    if (formap.CON_CODIGO === '') {
                      formap.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                    }
                    this.cxcCarteraService.insertarBanco(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
                        this.cxcCarteraService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).subscribe(() => {
                          this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                        });
                      });
                  }
                  this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                    this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                      this.asiento = res[0].ASI_NRO;
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                      this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                    });
                  });
                });
                this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                  this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                    this.asiento = res[0].ASI_NRO;
                    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                    this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                    this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                      '', '01', '', '', '', '').subscribe(() => {
                        this.message.add({
                          key: 'cartera',
                          severity: 'success',
                          summary: 'Asiento Contable',
                          detail: 'Se ha generado el asiento contable'
                        });
                      });
                  });
                });
              }
            });
            // this.cambiarBotones();
            // this.consultarPestañas();
          });
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
        this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
            this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
              this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
                });
              if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                  if (resumen.SELECCIONADO === true) {
                    resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                    resumen.STRCUOTAFAC = 'F';
                    if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                      resumen.STRCUOTAFAC = 'C';
                    }
                    this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                        if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                          this.actualizarCuotasPagadas(resumen);
                        }
                        this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'A',
                          '', '01', '', '', '', '').subscribe(() => {
                          });
                      });
                  }
                });
              }
              if (this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].length !== 0) {
                this.cxcCarteraService.eliminarCaja(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                  this.cxcCarteraService.eliminarBanco(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                    this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].map((formap) => {
                      if (formap.TRNCAJ_TIPO === 'EF') {
                        this.cxcCarteraService.insertarCaja(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
                            this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'A',
                              '', '01', '', '', '', '').subscribe(() => {
                              });
                          });
                      } else {
                        if (formap.CON_CODIGO === '') {
                          formap.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                        }
                        this.cxcCarteraService.insertarBanco(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
                            this.cxcCarteraService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).subscribe(() => {
                              this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'A',
                                '', '01', '', '', '', '').subscribe(() => {
                                });
                            });
                          });
                      }
                    });
                    this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                      this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                        this.asiento = res[0].ASI_NRO;
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                        this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                      });
                    });
                    this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
                      '', '01', '', '', '', '').subscribe(() => {
                      });
                  });
                  this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                });
              }
              this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Inserción de Cartera',
                    detail: 'Se ha insertado el documento exitosamente'
                  });
                });
              this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                  this.asiento = res[0].ASI_NRO;
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                  this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                  this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
                    String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'),
                    'I', '', '01', '', '', '', '').subscribe(() => {
                      this.message.add({
                        key: 'cartera',
                        severity: 'success',
                        summary: 'Asiento Contable',
                        detail: 'Se ha generado el asiento contable'
                      });
                    });
                });
              });
            });
            // this.cambiarBotones();
            // this.consultarPestañas();
          });
      }
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
      const dblTotalVarios = this.calcularTotalVarios();
      const dblImporteDoc = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC) * (-1);
      const strNombreDoc = 'Nota de Crédito';
      console.log('2', dblImporteDoc, dblTotalVarios);
      if (dblImporteDoc === dblTotalVarios) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Información',
          detail: 'El total de Varios debe ser igual al ' +
            'total del documento ' + strNombreDoc
        });
        return;
      }

      const exis = await this.cxcCarteraService.verificarComprobanteUnico('N',
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, '');
      console.log(exis);
      if (exis[0][':B1'] !== '0' && exis[0][':B1'] !== null && exis[0][':B1'] !== undefined) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'El numero de comprobante ya existe'
        });
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        document.getElementById('numeroCartera').focus();
        return;
      }

      if (this.saldoInicial === false) {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 0;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
          if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
            this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
              if (resumen.SELECCIONADO === true) {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = resumen.TRNCOBRO_IMPORTE;
              }
            });
          }
          this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
              if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NROCOMPROBANTE;
              }
              this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
                if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    if (resumen.SELECCIONADO === true) {
                      resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      resumen.TRNCOBRO_REFERENCIA = 'F';
                      resumen.STRCUOTAFAC = 'F';
                      if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                        resumen.STRCUOTAFAC = 'C';
                      }
                      this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                          if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                            this.actualizarCuotasPagadas(resumen);
                          }
                          this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                        });
                    }
                  });
                }
                this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                    this.message.add({
                      key: 'cartera',
                      severity: 'success',
                      summary: 'Inserción de Cartera',
                      detail: 'Se ha insertado el documento exitosamente'
                    });
                  });
              });
              if (this.tabVarios === true) {
                this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                  this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(cobro[0].TRNCOBRO_NRODOC), 'E',
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                  if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
                    this.cxcCarteraService.cxctrnvariosarray[this.indicador].map((vario) => {
                      vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                      vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
                      if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
                        vario.TRNVARIOS_IMPORTE = vario.DEBE;
                      }
                      if (vario.HABER !== '' && Number(vario.HABER) > 0) {
                        vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
                      }
                      this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
                        vario.TRNVARIOS_LINEA = vario.LINEA;
                        this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(vario.TRNCOBRO_NRODOC), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                          });
                        this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
                          if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                            this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                              if (resumen.SELECCIONADO === true) {
                                resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                                this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                                }, error1 => {
                                  console.log(error1.error);
                                });
                              }
                            });
                          }
                          this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                              this.message.add({
                                key: 'cartera',
                                severity: 'success',
                                summary: 'Asiento Contable',
                                detail: 'Se ha generado el asiento contable'
                              });
                            });
                        });
                      });
                    });
                  }
                });
              } else {
                this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
                  if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                    this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                      if (resumen.SELECCIONADO === true) {
                        resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                        this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                        }, error1 => {
                          console.log(error1.error);
                        });
                      }
                    });
                  }
                  this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                  this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
                    '', '01', '', '', '', '').subscribe(() => {
                      this.message.add({
                        key: 'cartera',
                        severity: 'success',
                        summary: 'Asiento Contable',
                        detail: 'Se ha generado el asiento contable'
                      });
                    });
                });
              }
              // this.cambiarBotones();
              // this.consultarPestañas();
            });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
          if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
            this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
              if (resumen.SELECCIONADO === true) {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = resumen.TRNCOBRO_IMPORTE;
              }
            });
          }
          this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
              this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
                this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
                  });
                if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    if (resumen.SELECCIONADO === true) {
                      resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      resumen.TRNCOBRO_REFERENCIA = 'F';
                      resumen.STRCUOTAFAC = 'F';
                      if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                        resumen.STRCUOTAFAC = 'C';
                      }
                      this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                          if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                            this.actualizarCuotasPagadas(resumen);
                          }
                          this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                        });
                    }
                  });
                }
                if (this.tabVarios === true) {
                  this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                    this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(cobro[0].TRNCOBRO_NRODOC), 'E',
                      '', '01', '', '', '', '').subscribe(() => {
                      });
                    if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
                      this.cxcCarteraService.cxctrnvariosarray[this.indicador].map((vario) => {
                        vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                        vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                        vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
                        if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
                          vario.TRNVARIOS_IMPORTE = vario.DEBE;
                        }
                        if (vario.HABER !== '' && Number(vario.HABER) > 0) {
                          vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
                        }
                        this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
                          vario.TRNVARIOS_LINEA = vario.LINEA;
                          this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
                            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
                            if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                              this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                                if (resumen.SELECCIONADO === true) {
                                  resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                                  this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                                  }, error1 => {
                                    console.log(error1.error);
                                  });
                                }
                              });
                            }
                            this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                            this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                                this.message.add({
                                  key: 'cartera',
                                  severity: 'success',
                                  summary: 'Asiento Contable',
                                  detail: 'Se ha generado el asiento contable'
                                });
                              });
                          });
                          this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(vario.TRNCOBRO_NRODOC), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                        });
                      });
                    }
                  });
                } else {
                  this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
                    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
                    if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                      this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                        if (resumen.SELECCIONADO === true) {
                          resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                          this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                          }, error1 => {
                            console.log(error1.error);
                          });
                        }
                      });
                    }
                    this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                    this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
                      '', '01', '', '', '', '').subscribe(() => {
                        this.message.add({
                          key: 'cartera',
                          severity: 'success',
                          summary: 'Asiento Contable',
                          detail: 'Se ha generado el asiento contable'
                        });
                      });
                  });
                }
                this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                    this.message.add({
                      key: 'cartera',
                      severity: 'success',
                      summary: 'Inserción de Cartera',
                      detail: 'Se ha insertado el documento exitosamente'
                    });
                  });
              });
              // this.cambiarBotones();
              // this.consultarPestañas();
            });
        }
      } else if (this.saldoInicial === true) {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 1;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
          this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
              this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
                this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                    this.message.add({
                      key: 'cartera',
                      severity: 'success',
                      summary: 'Inserción de Cartera',
                      detail: 'Se ha insertado el documento exitosamente'
                    });
                  });
              });
            });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
          this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
              this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
                this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                    this.message.add({
                      key: 'cartera',
                      severity: 'success',
                      summary: 'Inserción de Cartera',
                      detail: 'Se ha insertado el documento exitosamente'
                    });
                  });
              });
              // this.cambiarBotones();
              // this.consultarPestañas();
            });
        }
      }
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC;
        this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NROCOMPROBANTE;
            this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
              if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                  if (resumen.SELECCIONADO === true) {
                    resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                    resumen.STRCUOTAFAC = 'F';
                    if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                      resumen.STRCUOTAFAC = 'C';
                    }
                    this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                        if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                          this.actualizarCuotasPagadas(resumen);
                        }
                        this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                          });
                      });
                  }
                });
              }
              this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Inserción de Cartera',
                    detail: 'Se ha insertado el documento exitosamente'
                  });
                });
              if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
                this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((ret) => {
                  ret.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                  if (ret.CON_CODIGO === '') {
                    ret.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                  }
                  ret.CEN_CODIGO = '';
                  console.log(this.numeroFactura);
                  ret.ENCFAC_NUMERO = this.numeroFactura;
                  this.cxcCarteraService.insertarRetencion(ret, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                      if (this.retencionElect === true) {
                        this.cxcCarteraService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
                        });
                      } else if (this.retencionElect === false) {
                        this.cxcCarteraService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
                        });
                      }
                    });
                });
                this.cxcCarteraService.generarAsientoRT(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                  this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                    this.asiento = res[0].ASI_NRO;
                    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                    if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                      this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                        if (resumen.SELECCIONADO === true) {
                          resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                          this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                          }, error1 => {
                            console.log(error1.error);
                          });
                        }
                      });
                    }
                    this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                    this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                      '', '01', '', '', '', '').subscribe(() => {
                        this.message.add({
                          key: 'cartera',
                          severity: 'success',
                          summary: 'Asiento Contable',
                          detail: 'Se ha generado el asiento contable'
                        });
                      });
                  });
                });
              }
            });
            // this.cambiarBotones();
            // this.consultarPestañas();
          });
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
        this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
            this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
              this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
                });
              if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                  if (resumen.SELECCIONADO === true) {
                    resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                    resumen.STRCUOTAFAC = 'F';
                    if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                      resumen.STRCUOTAFAC = 'C';
                    }
                    this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                        if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                          this.actualizarCuotasPagadas(resumen);
                        }
                        this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                          });
                      });
                  }
                });
              }
              if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
                this.cxcCarteraService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' +
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC +
                  '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(() => {
                  });
                this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((ret) => {
                  ret.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                  if (ret.CON_CODIGO === '') {
                    ret.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                  }
                  ret.CEN_CODIGO = '';
                  ret.ENCFAC_NUMERO = this.numeroFactura;
                  this.cxcCarteraService.insertarRetencion(ret, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                      if (this.retencionElect === true) {
                        this.cxcCarteraService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
                        });
                      } else if (this.retencionElect === false) {
                        this.cxcCarteraService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
                        });
                      }
                    });
                });
              }
              this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Inserción de Cartera',
                    detail: 'Se ha insertado el documento exitosamente'
                  });
                });
              this.cxcCarteraService.generarAsientoRT(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                  this.asiento = res[0].ASI_NRO;
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                  if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                    this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                      if (resumen.SELECCIONADO === true) {
                        resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                        this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                        }, error1 => {
                          console.log(error1.error);
                        });
                      }
                    });
                  }
                  this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                  this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
                    String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'),
                    'I', '', '01', '', '', '', '').subscribe(() => {
                      this.message.add({
                        key: 'cartera',
                        severity: 'success',
                        summary: 'Asiento Contable',
                        detail: 'Se ha generado el asiento contable'
                      });
                    });
                });
              });
            });
            // this.cambiarBotones();
            // this.consultarPestañas();
          });
      }
    }

    setTimeout(() => {
      this.cambiarBotones();
      this.cancelarEdicionCartera();
    }, 3000);
  }

  async obtenerDatos(valor) {
    ///GSRF    
    this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = [];
    this.cxcCarteraService.cxcdebitobancarioarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = [];
    this.cxcCarteraService.dblTotalDocarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalRetarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalOtrosGarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalFormaPagoarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalFormaDevarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalVariosarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalNDarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalNCarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalCobrararray[this.indicador] = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = {};
    ///GSRF
    // this.cxcCarteraService.dblTotalNCarray[this.indicador] = 0;
    //this.cxcCarteraService.dblTotalOtrosGarray[this.indicador] = 0;
    //this.cxcCarteraService.dblTotalFormaPagoarray[this.indicador] = 0;
    //this.cxcCarteraService.dblTotalFormaDevarray[this.indicador] = 0;
    //this.cxcCarteraService.dblTotalRetarray[this.indicador] = 0;
    //this.cxcCarteraService.dblTotalDocarray[this.indicador] = 0;
    // this.cxcCarteraService.dblTotalNDarray[this.indicador] = 0;
    this.numeroFactura = '';
    this.booleanAnulado = false;
    const res = await this.cxcCarteraService.getEncCarteraProm(valor);
    console.log(res[0]);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = res[0];
    //CDPJ
    if(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT'||
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB'||
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA'){
      this.spin=true;
    }
    //CDPJ
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_ANULADO === 1) {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
      this.booleanAnulado = true;
      this.btnBorrar = true;
    }

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NDA') {
      this.itemTipoDoc = { label: 'NOTA DE DEBITO', value: 'NDA' };
      this.opcionesTipoDoc.push(this.itemTipoDoc);
    }

    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO =
      Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO).toFixed(2);

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'FC' && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ANC' &&
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ND' && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'NDA') {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = null;
    } else {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE =
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE.toFixed(2);
    }


    console.log(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]);

    const data = await this.cxcCarteraService.erMaeclienteProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
    for (const resCli of data) {
      if (resCli.CLI_CONREGMIC === 'S'
        || resCli.CLI_AGENTERET === 'S') {
        if (resCli.CLI_CONREGMIC === 'S'
          && resCli.CLI_AGENTERET === 'S') {
          this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
        } else {
          if (resCli.CLI_CONREGMIC === 'S') {
            this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
          }
          if (resCli.CLI_AGENTERET === 'S') {
            this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
          }
        }
      } else {
        this.lblAplicaRegMicroempresas = '';
      }
    }

    // this.consultarPestañas();
    await this.cargarAnexos();
    //GSRF
    if ( this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
    this.disimporte = true;
     }else{
      this.disimporte = false;
     }

     //GSRF
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC'
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA'
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB'
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT'
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN'
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
      this.bolsaldoIni = true;
    } else {
      this.bolsaldoIni = false;
    }
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NC') === true ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('ND') === true) {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('ANC') === false &&
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NDA') === false &&
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NDR') === false) {
        this.bolnc = false;
      } else {
        this.bolnc = true;
      }
    } else {
      this.bolnc = true;
    }

    const bolCierre = await this.documentoCierre('');

    if (bolCierre) {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      this.btnBorrar = true;
      this.botonAnular = true;
    }



    if (bolCierre) {
      this.lblDocCierre = true;
    }

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
      this.impresoString = 'IMPRESO';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
      this.btnBorrar = true;
      this.botonAnular = true;
      /*this.columDefCarteraDoc.map((columna) => {
        columna.editable = false;
      });
      this.aggrid.refreshColumnDefs2(this.columDefCarteraDoc);
      this.editarCeldas = true;
      this.tipodetalleactivo = 'none';*/
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO === 'CXC') {
      this.impresoString = '';
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal').style.opacity = '1.0';
      this.botonAnular = false;
      this.btnBorrar = false;
      /*this.columDefCarteraDoc.map((columna) => {
        columna.editable = true;
      });
      this.aggrid.refreshColumnDefs2(this.columDefCarteraDoc);
      this.editarCeldas = false;
      this.tipodetalleactivo = 'all';*/
    }

    console.log(new Date(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN));
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN =
      new Date(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN);
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE === null) {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
        new Date(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN);
    } else {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
        new Date(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE);
      console.log(new Date(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE));
    }

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
      this.booleanBodega = true;
      this.tabVarios = false;
      this.tabRetenciones = false;
      this.tabOtrosGastos = false;
      this.tabFormaPago = false;
      this.tabFormaPagoDev = false;
      this.tabEstadoCuentaAnt = false;
      this.tabDebitoBancario = false;
    } else {
      this.booleanBodega = false;
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NDA') {
        this.tabVarios = false;
        this.tabRetenciones = false;
        this.tabOtrosGastos = false;
        this.tabFormaPago = false;
        this.tabEstadoCuentaAnt = false;
        this.tabDebitoBancario = false;
        this.tabFormaPagoDev = false;
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
        this.tabVarios = true;
        this.tabRetenciones = false;
        this.tabOtrosGastos = false;
        this.tabFormaPago = false;
        this.tabEstadoCuentaAnt = false;
        this.tabDebitoBancario = false;
        this.tabFormaPagoDev = false;

        const resTrnRes = await this.cxcCarteraService.obtenerDocumentosProm('DT',
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);

        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        resTrnRes.map((resumen) => {
          console.log(resumen);
          let objRes: Cxctrnresumen;
          objRes = {};
          objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
          objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
          objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
          objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
          objRes.TRNCOBRO_IMPORTE = resumen.TRNDOCUMENTO_IMPORTE;
          objRes.VEN_CODIGO = resumen.VEN_CODIGO;
          objRes.SELECCIONADO = true;
          objRes.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
          this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(objRes);
        });

        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
        this.cxcCarteraService.calcularTotalDoc();

        const varios = await this.cxcCarteraService.obtenerVariosProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
        if (varios !== null) {
          varios.map((vario) => {
            if (Number(vario.TRNVARIOS_IMPORTE) < 0) {
              vario.DEBE = 0.00;
              vario.HABER = Math.abs(Number(vario.TRNVARIOS_IMPORTE)).toFixed(2);
            } else if (Number(vario.TRNVARIOS_IMPORTE) >= 0) {
              vario.DEBE = Number(vario.TRNVARIOS_IMPORTE).toFixed(2);
              vario.HABER = 0.00;
            }
          });
          this.cxcCarteraService.cxctrnvariosarray[this.indicador] = varios;
        }
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
        this.tabFormaPagoDev = true;
        this.tabRetenciones = false;
        this.tabOtrosGastos = false;
        this.tabEstadoCuentaAnt = false;
        this.tabVarios = false;
        this.tabDebitoBancario = false;
        this.tabFormaPago = false;

        const resTrnRes = await this.cxcCarteraService.obtenerDocumentosProm('DT', this.cxcCarteraService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);

        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        resTrnRes.map((resumen) => {
          let objRes: Cxctrnresumen;
          objRes = {};
          console.log(resumen);
          objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
          objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
          objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
          objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
          objRes.TRNCOBRO_IMPORTE = resumen.TRNDOCUMENTO_IMPORTE;
          objRes.VEN_CODIGO = resumen.VEN_CODIGO;
          objRes.SELECCIONADO = true;
          objRes.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
          this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(objRes);
        });
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
        this.cxcCarteraService.calcularTotalDoc();

        const formapago = await this.cxcCarteraService.obtenerFormadePagoDevProm(this.cxcCarteraService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);
        console.log(formapago);
        const arreglo = [];
        for (const fpagodev of formapago) {
          fpagodev.TRNCAJ_FECHA = this.datePipe.transform(fpagodev.TRNCAJ_FECHA, 'dd/MM/yyyy');
          fpagodev.TRNCAJ_IMPORTE = Math.abs(fpagodev.TRNCAJ_IMPORTE);
          arreglo.push(fpagodev);
        }

        const res2 = await this.cxcCarteraService.obtenerFormadePagoDevBancoProm(this.cxcCarteraService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);
        for (const detalle of res2) {
          detalle.TRNCAJ_FECHA = this.datePipe.transform(detalle.TRNBAN_FECHA, 'dd/MM/yyyy');
          detalle.TRNCAJ_IMPORTE = Math.abs(detalle.TRNBAN_IMPORTE);
          detalle.TRNCAJ_TIPO = detalle.TRNBAN_TIPO;
          detalle.TRNCAJ_NUMERO = detalle.TRNBAN_NUMERO;
          detalle.CAJ_CODIGO = '';
          detalle.TRNCAJ_DESCRIPCION = detalle.TRNBAN_CONCEPTO;
          const banN = await this.cxcCarteraService.erBanbancoProm(detalle.BAN_CODIGO);
          detalle.BAN_BANCO = banN[0].BAN_BANCO;
          arreglo.push(detalle);
        }

        this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = arreglo;
        // this.cxcCarteraService.calcularTotalFormaPagoDev();
        await this.efectivoDepositado('COLOR');
        await this.chequeDepositado('COLOR');
        await this.depositoConciliado('COLOR');
        await this.tarjetaConciliada('COLOR');
        this.cxcCarteraService.calcularTotalFormaPagoDev();

      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
        this.tabFormaPago = true;
        this.tabEstadoCuentaAnt = true;
        this.tabRetenciones = false;
        this.tabOtrosGastos = false;
        this.tabVarios = false;
        this.tabFormaPagoDev = false;
        this.tabDebitoBancario = false;

        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC != null) {
          const dblSaldoAnt = await this.cxcCarteraService.obtenerSaldoFactura(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC) * (-1);
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = Number(dblSaldoAnt).toFixed(2);

          if (this.saldoInicial === false) {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE).toFixed(2);
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE).toFixed(2);
            const dblTotalCobrar = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) - Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET)
              - Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG);
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = Number(dblTotalCobrar).toFixed(2);
          }
        }
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 0) {
          const formapago = await this.cxcCarteraService.obtenerFormadePagoProm(this.cxcCarteraService.encabezadoCarteraActivaarray
          [this.indicador].TRNCOBRO_NRODOC);
          console.log(formapago);
          if (formapago !== null) {
            const arreglo = [];
            for (const form of formapago) {
              form.TRNPAGO_FECHA = this.datePipe.transform(form.TRNPAGO_FECHA, 'dd/MM/yyyy');
              
              if (form.TRNPAGO_TIPO === 'CH') {
                const banN = await this.cxcCarteraService.erBanclinombreProm(form.BANCLI_CODIGO);
                form.BANCLI_NOMBRE = banN[0].BANCLI_NOMBRE;
              } else if (form.TRNPAGO_TIPO === 'TA') {
                const banN = await this.cxcCarteraService.erTarDescripcion(form.BANCLI_CODIGO);
                if (banN !== null) {
                  form.BANCLI_NOMBRE = banN[0].TAR_DESCRIPCION;
                }
              } else if (form.TRNPAGO_TIPO === 'DP') {
                const banN = await this.cxcCarteraService.erBanbancoProm(form.BANCLI_CODIGO);
                form.BANCLI_NOMBRE = banN[0].BAN_BANCO;
              }
              arreglo.push(form);
            }
            this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = arreglo;
            this.cxcCarteraService.calcularTotalFormaPago();
          }
        }

        await this.consultarECAnticipo();
        await this.efectivoDepositado('COLOR');
        await this.chequeDepositado('COLOR');
        await this.depositoConciliado('COLOR');
        await this.tarjetaConciliada('COLOR');
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDOANC = await this.obtenerSaldoANC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);

      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
        this.tabRetenciones = false;
        this.tabOtrosGastos = false;
        this.tabFormaPago = false;
        this.tabEstadoCuentaAnt = false;
        this.tabFormaPagoDev = false;
        this.botonXML = false;
        this.botonReenviarCorreo = false;

        const varios = await this.cxcCarteraService.obtenerVariosProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
        if (varios.length > 0) {
          const arreglo = [];
          for (const vario of varios) {
            if (Number(vario.TRNVARIOS_IMPORTE) < 0) {
              vario.DEBE = 0.00;
              vario.HABER = Math.abs(Number(vario.TRNVARIOS_IMPORTE)).toFixed(2);
            } else if (Number(vario.TRNVARIOS_IMPORTE) >= 0) {
              vario.DEBE = Number(vario.TRNVARIOS_IMPORTE).toFixed(2);
              vario.HABER = 0.00;
            }
            arreglo.push(vario);
          }
          this.cxcCarteraService.cxctrnvariosarray[this.indicador] = arreglo;
          this.tabVarios = true;
          this.tabDebitoBancario = false;
        } else {
          this.tabVarios = false;
          this.tabDebitoBancario = true;
          const ban = await this.cxcCarteraService.obtenerNombreBanProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO);
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO = ban[0].BAN_BANCO;

          const debito = await this.cxcCarteraService.obtenerDebitoBancarioProm(this.cxcCarteraService.encabezadoCarteraActivaarray
          [this.indicador].TRNCOBRO_NRODOC);
          const arreglo = [];
          for (const deb of debito) {
            deb.TRNPAGO_FECHA = this.datePipe.transform(deb.TRNPAGO_FECHA, 'dd/MM/yyyy');
            deb.SELECCIONADO = true;
            arreglo.push(deb);
          }
          this.cxcCarteraService.cxcdebitobancarioarray[this.indicador] = arreglo;
          this.cxcCarteraService.calcularTotalND();
        }

      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
        this.tabRetenciones = true;
        this.tabOtrosGastos = true;
        this.tabFormaPago = true;
        this.tabEstadoCuentaAnt = false;
        this.tabVarios = false;
        this.tabDebitoBancario = false;
        this.tabFormaPagoDev = false;
        const resTrnRes = await this.cxcCarteraService.obtenerDocumentosProm('DT', this.cxcCarteraService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);

        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        if (resTrnRes !== null) {
          for (const resumen of resTrnRes) {
            let objRes: Cxctrnresumen;
            objRes = {};
            objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
            objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
            objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
            objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
            objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
            objRes.TRNCOBRO_IMPORTE = String(resumen.TRNDOCUMENTO_IMPORTE.toFixed(2));
            objRes.VEN_CODIGO = resumen.VEN_CODIGO;
            objRes.STRCUOTAFAC = resumen.TRNDOCUMENTO_REF;
            objRes.SELECCIONADO = true;
            objRes.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(objRes);
          }

        }
        if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
          this.numeroFactura = this.cxcCarteraService.cxctrnresumenarray[this.indicador][0].TRNCOBRO_NRODOC;
        }

        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
        this.cxcCarteraService.calcularTotalDoc();

        const retenciones = await this.cxcCarteraService.obtenerRetencionesProm(this.cxcCarteraService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);
        console.log(retenciones);
        this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
        if (retenciones.length !== 0) {
          this.cxcCarteraService.cxctrnretencionarray[this.indicador] = retenciones;
          //CDPJ
          for (const itemRet of this.cxcCarteraService.cxctrnretencionarray[this.indicador]) {
            itemRet.NUEVALINEA=itemRet.LINEA;
          }
          //CDPJ
          this.cxcCarteraService.calcularTotalRetenido(-1);
        }

        const otrosG = await this.cxcCarteraService.obtenerOtrosGastosProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        if (otrosG !== null) {
          this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = otrosG;
          this.cxcCarteraService.calcularTotalOtrosG();
        }

        const formapago = await this.cxcCarteraService.obtenerFormadePagoProm(this.cxcCarteraService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);
        console.log(formapago);
        if (formapago !== null) {
          const arreglo = [];
          for (const form of formapago) {
            form.TRNPAGO_FECHA = this.datePipe.transform(form.TRNPAGO_FECHA, 'dd/MM/yyyy');
            if (form.TRNPAGO_TIPO === 'DP') {
              form.CAJ_CODIGO=''
            }
            if (form.TRNPAGO_TIPO === 'CH') {
              const banN = await this.cxcCarteraService.erBanclinombreProm(form.BANCLI_CODIGO);
              if (banN !== null) {
                form.BANCLI_NOMBRE = banN[0].BANCLI_NOMBRE;
              }
            } else if (form.TRNPAGO_TIPO === 'TA') {
              const banN = await this.cxcCarteraService.erTarDescripcion(form.BANCLI_CODIGO);
              if (banN !== null) {
                form.BANCLI_NOMBRE = banN[0].TAR_DESCRIPCION;
              }
            } else {
              const banN = await this.cxcCarteraService.erBanbancoProm(form.BANCLI_CODIGO);
              if (banN !== null) {
                form.BANCLI_NOMBRE = banN[0].BAN_BANCO;
              }
            }
            arreglo.push(form);
          }

          this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = arreglo;
          this.cxcCarteraService.calcularTotalFormaPago();
          await this.efectivoDepositado('COLOR');
          await this.chequeDepositado('COLOR');
          await this.depositoConciliado('COLOR');
          await this.tarjetaConciliada('COLOR');
        }
        
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
        this.tabRetenciones = true;
        this.tabVarios = false;
        this.tabOtrosGastos = false;
        this.tabFormaPago = false;
        this.tabEstadoCuentaAnt = false;
        this.tabDebitoBancario = false;
        this.tabFormaPagoDev = false;
        this.spin=true;//CDPJ

        const resTrnRes = await this.cxcCarteraService.obtenerDocumentosProm('DT', this.cxcCarteraService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);
        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        if(resTrnRes !== null && resTrnRes !== undefined){
          for (const resumen of resTrnRes) {
            let objRes: Cxctrnresumen;
            objRes = {};
            objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
            this.numeroFactura = objRes.TRNCOBRO_NRODOC;
            objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
            objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
            objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
            objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
            objRes.TRNCOBRO_IMPORTE = Number(resumen.TRNDOCUMENTO_IMPORTE).toFixed(2);
            objRes.VEN_CODIGO = resumen.VEN_CODIGO;
            objRes.SELECCIONADO = true;
            objRes.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(objRes);
          }
          if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
            this.numeroFactura = this.cxcCarteraService.cxctrnresumenarray[this.indicador][0].TRNCOBRO_NRODOC;
          }
  
          this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
          this.cxcCarteraService.calcularTotalDoc();
  
          const tiporet = await this.cxcCarteraService.obtenerTipoRetProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
          if (tiporet.length !== 0) {
            if (tiporet[0].TRNRETENCION_TIPORET === 'E') {
              this.retencionElect = true;
            } else {
              this.retencionElect = false;
            }
          }
  
          const retenciones = await this.cxcCarteraService.obtenerRetencionesProm(this.cxcCarteraService.encabezadoCarteraActivaarray
          [this.indicador].TRNCOBRO_NRODOC);
          if (retenciones.length !== 0) {
            this.cxcCarteraService.cxctrnretencionarray[this.indicador] = retenciones;
            //CDPJ
            for (const itemRet of this.cxcCarteraService.cxctrnretencionarray[this.indicador]) {
              itemRet.ENCFAC_NUMERO=this.numeroFactura;
            }
            //CDPJ
            this.cxcCarteraService.calcularTotalRetenido(-1);
          }
        }else{
          this.spin=false;
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Cartera Activa',
            detail: 'La retención no tiene nigún documento seleccionado'
          });
        }
        
      }
    }

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 1) {
      this.saldoInicial = true;
      this.bolsaldoIni = true;
      this.tabVarios = false;
      this.tabDebitoBancario = false;
      this.tabFormaPagoDev = false;
      this.tabRetenciones = false;
      this.tabOtrosGastos = false;
      this.tabEstadoCuentaAnt = false;
      //CDPJ
      if(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC'){
        this.tabEstadoCuentaAnt = true;
      }
      //CDPJ
      this.tabFormaPago = false;
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 0) {
      this.saldoInicial = false;
    }

    const resApl = await this.cxcCarteraService.getOrigenProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO);
    if (resApl.length !== 0) {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-' + resApl[0].APLORG_NOMBRE;
    } else {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-';
    }

    const resReal = await this.cxcCarteraService.getRealizadoProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].REALIZADO = resReal[0].NOMBRE;

    this.bolestcta = false;
    this.bolsaldo = false;

    const bolCierre2 = await this.documentoCierre('');

    if (await this.chequeConciliado('') || await this.chequeDepositado('')
      || await this.depositoConciliado('') || await this.efectivoDepositado('')
      || await this.tarjetaConciliada('') || bolCierre2
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
      this.btneditConcil = false;
      this.editConcil = false;
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
    } else {
      this.btneditConcil = true;
      this.editConcil = true;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal').style.opacity = '1.0';
    }
      ///GSRF
    this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(async(res)  => {
      console.log('----***',res)
      this.asiento = res[0].ASI_NRO;
      if (this.asiento !== null) {
        const det = await this.cxcCarteraService.getDetConencasilProm(this.asiento, 'CXC');
        console.log('----***',det);
        let sum = 0;
        for (const aux of det) {
          sum=aux.DETASI_IMPORTE + sum;
        }
        console.log('----new',Number(sum.toFixed(2)));
        if(Number(sum.toFixed(2)) !== 0){
          document.getElementById("errasiento").style.display = '';
        }else{
          document.getElementById("errasiento").style.display = 'none';
        }
      }else{
        document.getElementById("errasiento").style.display = 'none';
      } 
      this.spin=false;//CDPJ
    });
    //GSRF
  }


  async obtenerDatosOld(valor) {

    ///GSRF    
    this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = [];
    this.cxcCarteraService.cxcdebitobancarioarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = [];
    this.cxcCarteraService.dblTotalDocarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalRetarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalOtrosGarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalFormaPagoarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalFormaDevarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalVariosarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalNDarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalNCarray[this.indicador] = 0;
    this.cxcCarteraService.dblTotalCobrararray[this.indicador] = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = {};
    ///GSRF
    // this.cxcCarteraService.dblTotalNCarray[this.indicador] = 0;
    //this.cxcCarteraService.dblTotalOtrosGarray[this.indicador] = 0;
    //this.cxcCarteraService.dblTotalFormaPagoarray[this.indicador] = 0;
    //this.cxcCarteraService.dblTotalFormaDevarray[this.indicador] = 0;
    //this.cxcCarteraService.dblTotalRetarray[this.indicador] = 0;
    //this.cxcCarteraService.dblTotalDocarray[this.indicador] = 0;
    // this.cxcCarteraService.dblTotalNDarray[this.indicador] = 0;
    this.numeroFactura = '';
    this.booleanAnulado = false;
    this.cxcCarteraService.getEncCartera(valor).subscribe(async (res) => {
      console.log(res[0]);
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = res[0];
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_ANULADO === 1) {
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        document.getElementById('divPrincipal').style.opacity = '0.7';
        this.booleanAnulado = true;
        this.btnBorrar = true;
      }

      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NDA') {
        this.itemTipoDoc = { label: 'NOTA DE DEBITO', value: 'NDA' };
        this.opcionesTipoDoc.push(this.itemTipoDoc);
      }

      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = 0;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC =
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC.toFixed(2);
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = 0;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO =
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO.toFixed(2);
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO = 0;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO =
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO.toFixed(2);
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG = 0;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG =
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG.toFixed(2);
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = 0;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR =
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR.toFixed(2);
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET = 0;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET =
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET.toFixed(2);
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC = 0;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO =
        Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO).toFixed(2);
      console.log(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]);
      this.cxcCarteraService.erMaecliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe(data => {
        for (const resCli of data) {
          if (resCli.CLI_CONREGMIC === 'S'
            || resCli.CLI_AGENTERET === 'S') {
            if (resCli.CLI_CONREGMIC === 'S'
              && resCli.CLI_AGENTERET === 'S') {
              this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
            } else {
              if (resCli.CLI_CONREGMIC === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
              }
              if (resCli.CLI_AGENTERET === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
              }
            }
          } else {
            this.lblAplicaRegMicroempresas = '';
          }
        }
      });
      // this.consultarPestañas();
      this.cargarAnexos();
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC'
        || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA'
        || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB'
        || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT'
        || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN'
        || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
        this.bolsaldoIni = true;
      } else {
        this.bolsaldoIni = false;
      }
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NC') === true ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('ND') === true) {
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('ANC') === false &&
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NDA') === false &&
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NDR') === false) {
          this.bolnc = false;
        } else {
          this.bolnc = true;
        }
      } else {
        this.bolnc = true;
      }

      const bolCierre = await this.documentoCierre('');

      if (bolCierre) {
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        this.btnBorrar = true;
        this.botonAnular = true;
      }

      if (bolCierre) {
        this.lblDocCierre = true;
      }

      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        this.impresoString = 'IMPRESO';
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        document.getElementById('divPrincipal').style.opacity = '0.7';
        this.btnBorrar = true;
        this.botonAnular = true;
        /*this.columDefCarteraDoc.map((columna) => {
          columna.editable = false;
        });
        this.aggrid.refreshColumnDefs2(this.columDefCarteraDoc);
        this.editarCeldas = true;
        this.tipodetalleactivo = 'none';*/
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO === 'CXC') {
        this.impresoString = '';
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        document.getElementById('divPrincipal').style.opacity = '1.0';
        this.botonAnular = false;
        this.btnBorrar = false;
        /*this.columDefCarteraDoc.map((columna) => {
          columna.editable = true;
        });
        this.aggrid.refreshColumnDefs2(this.columDefCarteraDoc);
        this.editarCeldas = false;
        this.tipodetalleactivo = 'all';*/
      }

      console.log(new Date(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN));
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN =
        new Date(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN);
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE === null) {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
          new Date(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN);
      } else {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
          new Date(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE);
        console.log(new Date(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE));
      }

      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
        this.booleanBodega = true;
        this.tabVarios = false;
        this.tabRetenciones = false;
        this.tabOtrosGastos = false;
        this.tabFormaPago = false;
        this.tabFormaPagoDev = false;
        this.tabEstadoCuentaAnt = false;
        this.tabDebitoBancario = false;
      } else {
        this.booleanBodega = false;
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NDA') {
          this.tabVarios = false;
          this.tabRetenciones = false;
          this.tabOtrosGastos = false;
          this.tabFormaPago = false;
          this.tabEstadoCuentaAnt = false;
          this.tabDebitoBancario = false;
          this.tabFormaPagoDev = false;
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
          this.tabVarios = true;
          this.tabRetenciones = false;
          this.tabOtrosGastos = false;
          this.tabFormaPago = false;
          this.tabEstadoCuentaAnt = false;
          this.tabDebitoBancario = false;
          this.tabFormaPagoDev = false;
          this.cxcCarteraService.obtenerDocumentos('DT',
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((resTrnRes) => {
              this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
              resTrnRes.map((resumen) => {
                console.log(resumen);
                let objRes: Cxctrnresumen;
                objRes = {};
                objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
                objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
                objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
                objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
                objRes.TRNCOBRO_IMPORTE = resumen.TRNDOCUMENTO_IMPORTE;
                objRes.VEN_CODIGO = resumen.VEN_CODIGO;
                objRes.SELECCIONADO = true;
                objRes.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(objRes);
              });
              this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
              this.cxcCarteraService.calcularTotalDoc();
            });
          this.cxcCarteraService.obtenerVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((varios) => {
            this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
            if (varios !== null) {
              varios.map((vario) => {
                if (Number(vario.TRNVARIOS_IMPORTE) < 0) {
                  vario.DEBE = 0;
                  vario.HABER = Math.abs(Number(vario.TRNVARIOS_IMPORTE));
                } else if (Number(vario.TRNVARIOS_IMPORTE) >= 0) {
                  vario.DEBE = vario.TRNVARIOS_IMPORTE;
                  vario.HABER = 0;
                }
              });
              this.cxcCarteraService.cxctrnvariosarray[this.indicador] = varios;
            }
          });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
          this.tabFormaPagoDev = true;
          this.tabRetenciones = false;
          this.tabOtrosGastos = false;
          this.tabEstadoCuentaAnt = false;
          this.tabVarios = false;
          this.tabDebitoBancario = false;
          this.tabFormaPago = false;



          this.cxcCarteraService.obtenerDocumentos('DT', this.cxcCarteraService.encabezadoCarteraActivaarray
          [this.indicador].TRNCOBRO_NRODOC).subscribe((resTrnRes) => {
            this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
            resTrnRes.map((resumen) => {
              let objRes: Cxctrnresumen;
              objRes = {};
              console.log(resumen);
              objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
              objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
              objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
              objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
              objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
              objRes.TRNCOBRO_IMPORTE = resumen.TRNDOCUMENTO_IMPORTE;
              objRes.VEN_CODIGO = resumen.VEN_CODIGO;
              objRes.SELECCIONADO = true;
              objRes.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(objRes);
            });
            this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
            this.cxcCarteraService.calcularTotalDoc();
          });
          this.cxcCarteraService.obtenerFormadePagoDev(this.cxcCarteraService.encabezadoCarteraActivaarray
          [this.indicador].TRNCOBRO_NRODOC).subscribe((formapago) => {
            console.log(formapago);
            const arreglo = [];
            formapago.map((fpagodev) => {
              fpagodev.TRNCAJ_FECHA = this.datePipe.transform(fpagodev.TRNCAJ_FECHA, 'dd/MM/yyyy');
              fpagodev.TRNCAJ_IMPORTE = Math.abs(fpagodev.TRNCAJ_IMPORTE);
              arreglo.push(fpagodev);
            });
            this.cxcCarteraService.obtenerFormadePagoDevBanco(this.cxcCarteraService.encabezadoCarteraActivaarray
            [this.indicador].TRNCOBRO_NRODOC).subscribe((res2) => {
              res2.map((detalle) => {
                detalle.TRNCAJ_FECHA = this.datePipe.transform(detalle.TRNBAN_FECHA, 'dd/MM/yyyy');
                detalle.TRNCAJ_IMPORTE = Math.abs(detalle.TRNBAN_IMPORTE);
                detalle.TRNCAJ_TIPO = detalle.TRNBAN_TIPO;
                detalle.TRNCAJ_NUMERO = detalle.TRNBAN_NUMERO;
                detalle.CAJ_CODIGO = '';
                detalle.TRNCAJ_DESCRIPCION = detalle.TRNBAN_CONCEPTO;
                this.busquedaSer.encontrarRegistro42(detalle.BAN_CODIGO).subscribe((banN) => {
                  detalle.BAN_BANCO = banN[0].BAN_BANCO;
                });
                setTimeout(() => {
                  arreglo.push(detalle);
                }, 100);
              });
              setTimeout(async () => {
                this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = arreglo;
                this.cxcCarteraService.calcularTotalFormaPagoDev();

                await this.efectivoDepositado('COLOR');
                await this.chequeDepositado('COLOR');
                await this.depositoConciliado('COLOR');
                await this.tarjetaConciliada('COLOR');
              }, 200);
            });
            this.cxcCarteraService.calcularTotalFormaPagoDev();
          });

        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
          this.tabFormaPago = true;
          this.tabEstadoCuentaAnt = true;
          this.tabRetenciones = false;
          this.tabOtrosGastos = false;
          this.tabVarios = false;
          this.tabFormaPagoDev = false;
          this.tabDebitoBancario = false;

          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC != null) {
            const dblSaldoAnt = await this.cxcCarteraService.obtenerSaldoFactura(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC) * (-1);
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = Number(dblSaldoAnt).toFixed(2);

            if (this.saldoInicial === false) {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE);
              const dblTotalCobrar = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) - Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET)
                - Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG);
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = Number(dblTotalCobrar).toFixed(2);
            }
          }
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 0) {
            this.cxcCarteraService.obtenerFormadePago(this.cxcCarteraService.encabezadoCarteraActivaarray
            [this.indicador].TRNCOBRO_NRODOC).subscribe((formapago) => {
              console.log(formapago);
              if (formapago !== null) {
                formapago.map((form) => {
                  form.TRNPAGO_FECHA = this.datePipe.transform(form.TRNPAGO_FECHA, 'dd/MM/yyyy');
                  if (form.TRNPAGO_TIPO === 'CH') {
                    this.busquedaSer.encontrarRegistro43(form.BANCLI_CODIGO).subscribe((banN) => {
                      form.BANCLI_NOMBRE = banN[0].BANCLI_NOMBRE;
                    });
                  }
                  if (form.TRNPAGO_TIPO === 'DP' || form.TRNPAGO_TIPO === 'TA') {
                    this.busquedaSer.encontrarRegistro42(form.BANCLI_CODIGO).subscribe((banN) => {
                      form.BANCLI_NOMBRE = banN[0].BAN_BANCO;
                    });
                  }
                });
                this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = formapago;
                this.cxcCarteraService.calcularTotalFormaPago();
              }
            });
          }

          this.consultarECAnticipo();
          await this.efectivoDepositado('COLOR');
          await this.chequeDepositado('COLOR');
          await this.depositoConciliado('COLOR');
          await this.tarjetaConciliada('COLOR');
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDOANC = await this.obtenerSaldoANC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
          this.tabRetenciones = false;
          this.tabOtrosGastos = false;
          this.tabFormaPago = false;
          this.tabEstadoCuentaAnt = false;
          this.tabFormaPagoDev = false;
          this.botonXML = false;
          this.botonReenviarCorreo = false;
          this.cxcCarteraService.obtenerVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((varios) => {
            this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
            if (varios.length > 0) {
              varios.map((vario) => {
                if (Number(vario.TRNVARIOS_IMPORTE) < 0) {
                  vario.DEBE = 0;
                  vario.HABER = Math.abs(Number(vario.TRNVARIOS_IMPORTE));
                } else if (Number(vario.TRNVARIOS_IMPORTE) >= 0) {
                  vario.DEBE = vario.TRNVARIOS_IMPORTE;
                  vario.HABER = 0;
                }
              });
              this.cxcCarteraService.cxctrnvariosarray[this.indicador] = varios;
              this.tabVarios = true;
              this.tabDebitoBancario = false;
            } else {
              this.tabVarios = false;
              this.tabDebitoBancario = true;
              this.cxcCarteraService.obtenerNombreBan(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO).subscribe((ban) => {
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO = ban[0].BAN_BANCO;
              });
              this.cxcCarteraService.obtenerDebitoBancario(this.cxcCarteraService.encabezadoCarteraActivaarray
              [this.indicador].TRNCOBRO_NRODOC).subscribe((debito) => {
                debito.map((deb) => {
                  deb.TRNPAGO_FECHA = this.datePipe.transform(deb.TRNPAGO_FECHA, 'dd/MM/yyyy');
                  deb.SELECCIONADO = true;
                });
                this.cxcCarteraService.cxcdebitobancarioarray[this.indicador] = debito;
                this.cxcCarteraService.calcularTotalND();
              });
            }
          });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
          this.tabRetenciones = true;
          this.tabOtrosGastos = true;
          this.tabFormaPago = true;
          this.tabEstadoCuentaAnt = false;
          this.tabVarios = false;
          this.tabDebitoBancario = false;
          this.tabFormaPagoDev = false;
          this.cxcCarteraService.obtenerDocumentos('DT', this.cxcCarteraService.encabezadoCarteraActivaarray
          [this.indicador].TRNCOBRO_NRODOC).subscribe((resTrnRes) => {
            this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
            if (resTrnRes !== null) {
              resTrnRes.map((resumen) => {
                let objRes: Cxctrnresumen;
                objRes = {};
                objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
                objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
                objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
                objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
                objRes.TRNCOBRO_IMPORTE = String(resumen.TRNDOCUMENTO_IMPORTE.toFixed(2));
                objRes.VEN_CODIGO = resumen.VEN_CODIGO;
                objRes.STRCUOTAFAC = resumen.TRNDOCUMENTO_REF;
                objRes.SELECCIONADO = true;
                objRes.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(objRes);
              });
            }
            if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
              this.numeroFactura = this.cxcCarteraService.cxctrnresumenarray[this.indicador][0].TRNCOBRO_NRODOC;
            }
            this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
            this.cxcCarteraService.calcularTotalDoc();
          });
          this.cxcCarteraService.obtenerRetenciones(this.cxcCarteraService.encabezadoCarteraActivaarray
          [this.indicador].TRNCOBRO_NRODOC).subscribe((retenciones) => {
            if (retenciones.length !== 0) {
              this.cxcCarteraService.cxctrnretencionarray[this.indicador] = retenciones;
              this.cxcCarteraService.calcularTotalRetenido(-1);
            }
          });
          this.cxcCarteraService.obtenerOtrosGastos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((otrosG) => {
            if (otrosG !== null) {
              this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = otrosG;
              this.cxcCarteraService.calcularTotalOtrosG();
            }
          });
          this.cxcCarteraService.obtenerFormadePago(this.cxcCarteraService.encabezadoCarteraActivaarray
          [this.indicador].TRNCOBRO_NRODOC).subscribe(async (formapago) => {
            console.log(formapago);
            if (formapago !== null) {
              formapago.map((form) => {
                form.TRNPAGO_FECHA = this.datePipe.transform(form.TRNPAGO_FECHA, 'dd/MM/yyyy');
                if (form.TRNPAGO_TIPO === 'CH') {
                  this.busquedaSer.encontrarRegistro43(form.BANCLI_CODIGO).subscribe((banN) => {
                    if (banN !== null) {
                      form.BANCLI_NOMBRE = banN[0].BANCLI_NOMBRE;
                    }
                  });
                } else {
                  this.busquedaSer.encontrarRegistro42(form.BANCLI_CODIGO).subscribe((banN) => {
                    if (banN !== null) {
                      form.BANCLI_NOMBRE = banN[0].BAN_BANCO;
                    }
                  });
                }
              });
              this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = formapago;
              this.cxcCarteraService.calcularTotalFormaPago();
              await this.efectivoDepositado('COLOR');
              await this.chequeDepositado('COLOR');
              await this.depositoConciliado('COLOR');
              await this.tarjetaConciliada('COLOR');
            }
          });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
          this.tabRetenciones = true;
          this.tabVarios = false;
          this.tabOtrosGastos = false;
          this.tabFormaPago = false;
          this.tabEstadoCuentaAnt = false;
          this.tabDebitoBancario = false;
          this.tabFormaPagoDev = false;
          this.cxcCarteraService.obtenerDocumentos('DT', this.cxcCarteraService.encabezadoCarteraActivaarray
          [this.indicador].TRNCOBRO_NRODOC).subscribe((resTrnRes) => {
            this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
            resTrnRes.map((resumen) => {
              let objRes: Cxctrnresumen;
              objRes = {};
              objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
              this.numeroFactura = objRes.TRNCOBRO_NRODOC;
              objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
              objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
              objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
              objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
              objRes.TRNCOBRO_IMPORTE = resumen.TRNDOCUMENTO_IMPORTE;
              objRes.VEN_CODIGO = resumen.VEN_CODIGO;
              objRes.SELECCIONADO = true;
              objRes.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(objRes);
            });
            if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
              this.numeroFactura = this.cxcCarteraService.cxctrnresumenarray[this.indicador][0].TRNCOBRO_NRODOC;
            }
            this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
            this.cxcCarteraService.calcularTotalDoc();
            this.cxcCarteraService.obtenerTipoRet(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((tiporet) => {
              if (tiporet.length !== 0) {
                if (tiporet[0].TRNRETENCION_TIPORET === 'E') {
                  this.retencionElect = true;
                } else {
                  this.retencionElect = false;
                }
              }
            });
          });
          this.cxcCarteraService.obtenerRetenciones(this.cxcCarteraService.encabezadoCarteraActivaarray
          [this.indicador].TRNCOBRO_NRODOC).subscribe((retenciones) => {
            if (retenciones.length !== 0) {
              this.cxcCarteraService.cxctrnretencionarray[this.indicador] = retenciones;
              this.cxcCarteraService.calcularTotalRetenido(-1);
            }
          });
        }
      }
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 1) {
        this.saldoInicial = true;
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 0) {
        this.saldoInicial = false;
      }
      this.cxcCarteraService.getOrigen(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe((resApl) => {
        if (resApl.length !== 0) {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-' + resApl[0].APLORG_NOMBRE;
        } else {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-';
        }
      });
      this.cxcCarteraService.getRealizado(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION).subscribe((resReal) => {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].REALIZADO = resReal[0].NOMBRE;
      });
    });
    this.bolestcta = false;
    this.bolsaldo = false;
  }

  buscarCartera() {
    this.editpagofr = true;
    this.opcion = 'BUSCAR';
    this.busquedaCompuesta = false;
    this.types = [
      { label: 'NÚMERO', value: 'TRNCOBRO_NRODOC' },
      { label: 'TIPO DOC', value: 'TRNCOBRO_TIPODOC' },
      { label: 'FECHA TRANS', value: 'TRNCOBRO_FECHATRN' },
      { label: 'COD_CLIENTE', value: 'CLI_CODIGO' },
      { label: 'CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'CONCEPTO', value: 'TRNCOBRO_CONCEPTO' },
      { label: 'IMPORTE', value: 'TRNCOBRO_IMPORTE' },
      { label: 'FECHA VENC', value: 'TRNCOBRO_FECHAVENCE' },
      { label: 'REF_EXTERNA', value: 'TRNCOBRO_REFERENCIAEXTERNA' },
      { label: 'COMPROBANTE', value: 'TRNCOBRO_NROCOMPROBANTE' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'CXC_TRNCOBRO';
    this.consulta = 'CXC_TRNCOBRO.*, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE';
    this.where = ' CXC_TRNCOBRO.TRNCOBRO_NRODOC is not null order by CXC_TRNCOBRO.TRNCOBRO_FECHATRN desc ';
    this.spin = true;
    this.busquedaSer.busquedaCXCTrnCobro1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((registro) => {
        if (registro.TRNCOBRO_IMPORTE !== null) {
          registro.TRNCOBRO_IMPORTE = Number(registro.TRNCOBRO_IMPORTE).toFixed(2);
        }
        if (registro.TRNCOBRO_FECHATRN !== null) {
          registro.TRNCOBRO_FECHATRN = this.datePipe.transform(registro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        } else {
          registro.TRNCOBRO_FECHATRN = '';
        }
        if (registro.TRNCOBRO_FECHAVENCE !== null) {
          registro.TRNCOBRO_FECHAVENCE = this.datePipe.transform(registro.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
        } else {
          registro.TRNCOBRO_FECHAVENCE = '';
        }
      });
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  manejarSeleccion(opcion) {
    if (this.opcion === 'BUSCAR') {
      this.listaClientes.map((cliente) => {
        if (cliente.CLI_CODIGO === opcion.CLI_CODIGO) {
          this.indiceCliente = this.listaClientes.indexOf(cliente);
          this.cxcCarteraService.getListaReferencias(cliente.CLI_CODIGO).subscribe((ref) => {
            this.listaReferencias = ref;
            this.listaReferencias.map((refL) => {
              if (refL.TRNCOBRO_REFERENCIAEXTERNA === opcion.TRNCOBRO_NRODOC) {
                this.indiceDocRef = this.listaReferencias.indexOf(refL);
              }
            });
          });
        }
      });
      this.obtenerDatos(opcion.TRNCOBRO_NRODOC);
      this.displayDialogBusquedaFast = false;
    } else if (this.opcion === 'BUSQUEDACLI') {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE = opcion.CLI_NOMBRE;
      this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((res) => {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = Number(res[0].SALDO).toFixed(2);
      });
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO === null ? '' : opcion.VEN_CODIGO;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = opcion.VEN_NOMBRE === null ? '' : opcion.VEN_NOMBRE;
      this.displayDialogBusquedaFast = false;
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      this.busquedaSer.encontrarRegistro44(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((resCli) => {
        if (resCli !== null) {
          if (resCli[0].CLI_CONREGMIC === 'S'
            || resCli[0].CLI_AGENTERET === 'S') {
            if (resCli[0].CLI_CONREGMIC === 'S'
              && resCli[0].CLI_AGENTERET === 'S') {
              this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
            } else {
              if (resCli[0].CLI_CONREGMIC === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
              }
              if (resCli[0].CLI_AGENTERET === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
              }
            }
          } else {
            this.lblAplicaRegMicroempresas = '';
          }
        }
      });
      document.getElementById('vendedor').focus();
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== '') {
        this.cambiarTipoCartera();
      }
    } else if (this.opcion === 'BUSQUEDACLIFAC') {
      this.codClienteFac = opcion.CLI_CODIGO;
      this.nombreClienteFac = opcion.CLI_NOMBRE;
      this.cxcCarteraService.getDocCobrados(this.codClienteFac, this.nroFacCancelada).subscribe((facpendientes) => {
        facpendientes.map((facturas) => {
          facturas.TRNCOBRO_FECHATRN = this.datePipe.transform(facturas.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        });
        this.cxcCarteraService.cxctrnfaccanceladasarray[this.indicador] = facpendientes;
      });
      this.displayDialogBusquedaFast = false;
      document.getElementById('nroFacCanc').focus();
    } else if (this.opcion === 'BUSQUEDABOD') {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
      this.displayDialogBusquedaFast = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      document.getElementById('concepto').focus();
    } else if (this.opcion === 'BUSQUEDAVEN') {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = opcion.VEN_NOMBRE;
      this.displayDialogBusquedaFast = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
    } else if (this.opcion === 'BUSQUEDABAN') {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO = opcion.BAN_CODIGO;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO = opcion.BAN_BANCO;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO = opcion.CON_CODIGO;
      this.displayDialogBusquedaFast = false;
      this.cxcCarteraService.cxcdebitobancarioarray[this.indicador] = [];
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO !== null || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO !== '') {
        this.tabVarios = false;
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO !== '') {
          this.cxcCarteraService.obtenerChequesCli(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((cheques) => {
            if (cheques !== null) {
              cheques.map((cheque) => {
                const obj: Cxctrndebitobancario = {};
                obj.SELECCIONADO = false;
                obj.TRNPAGO_FECHA = this.datePipe.transform(cheque.TRNPAGO_FECHA, 'dd/MM/yyyy');
                obj.TRNPAGO_TIPO = cheque.TRNPAGO_TIPO;
                obj.TRNPAGO_NUMERO = cheque.TRNPAGO_NUMERO;
                obj.TRNPAGO_IMPORTE = Number(cheque.TRNPAGO_IMPORTE).toFixed(2);
                obj.TRNPAGO_NROCTA = cheque.TRNPAGO_NROCTA;
                obj.BANCLI_CODIGO = cheque.BANCLI_CODIGO;
                obj.BANCLI_NOMBRE = cheque.BANCLI_NOMBRE;
                obj.TRNCOBRO_NRODOC = cheque.TRNCOBRO_NROCOMPROBANTE;
                this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].push(obj);
              });
            }
            this.aggrid.refreshaggrid(this.cxcCarteraService.cxcdebitobancarioarray[this.indicador], this.defaultColumnDefCarteraDebito.objeto);
          });
        }
      } else {
        this.tabVarios = true;
      }
    }

    if (this.opcion === 'TRNANEX_IDCRETRI') {
      this.anexoSeleccionado.TRNANEX_IDCRETRI = opcion.CODSRI_CODIGO;
      this.anexoSeleccionado.DESCIDCRETRI = opcion.CODSRI_DESCRIPCION;
      document.getElementById('TRNANEX_MONTOIVA').focus();
    }

    if (this.opcion === 'TRNANEX_TIPOSRITARCERO') {
      this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = opcion.REF_CODIGO;
      document.getElementById('TRNANEX_BASEIMPGRABADAIVA').focus();
    }

    if (this.opcion === 'TRNANEX_TIPOSRIGRAVADA') {
      this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = opcion.REF_CODIGO;
      document.getElementById('TRNANEX_MONTOIVA').focus();
    }

    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }

  seleccionDocumento(valor) {
    /*if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
      
      if (valor.object !== undefined) {
        this.numeroFactura = valor.object.TRNCOBRO_NRODOC;
      }
    }*/
  }

  seleccionRetencion(valor) {
    if (valor === 'nuevo') {
      this.nuevaRetencion();
    } else if (valor === 'eliminar') {
      this.confirmarEliminarRetencion();
    } else if (valor === 'cancelar') {
      this.cancelarRetencion();
    }
    if (valor.object !== undefined) {
      this.detalleRetencionSeleccionado = valor.object;
    }
  }

  seleccionGastos(valor) {
    if (valor === 'nuevo') {
      this.nuevosGastos();
    } else if (valor === 'eliminar') {
      this.confirmarEliminarOtrosG();
    } else if (valor === 'cancelar') {
      this.cancelarOtrosG();
    }
    if (valor.object !== undefined) {
      this.detalleOtrasFSeleccionada = valor.object;
    }
  }

  seleccionFormaPago(valor) {
    if (valor === 'nuevo') {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        return;
      }
      this.nuevaFormaPago();
    } else if (valor === 'eliminar') {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        return;
      }
      //GSRF
      console.log('entra-*-*-*-*-*',this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC )
      console.log('entra-*-*-*-*-*',this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC )
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' 
      && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== null
      && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== undefined) {
        if(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC ==='AB'
          ||this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC ==='CA'){
            this.message.add({
              key: 'cartera',
              severity: 'error',
              summary: 'Cartera Activa',
              detail: 'No es posible eliminar la forma de pago. Elimine el documento y vuelva a ingresar.'
            });
          return;
        }
        
      }
      //GSRF
      this.confirmarEliminarFormaPago();
    } else if (valor === 'cancelar') {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        return;
      }
      this.cancelarFormaPago();
    }else if (valor === 'generaconcepto') {
      let seleccionados = 0;
    if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
      this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
        if (resumen.SELECCIONADO === true) {
          seleccionados++;
        }
      });
    }else{
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Inserción de Cancelación',
        detail: 'Ningún documento ha sido ' +
          'seleccionado. '
      });
      this.spin=false//CDPJ
      return;
    }
    if (seleccionados === 0) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Inserción de Cancelación',
        detail: 'Ningún documento ha sido ' +
          'seleccionado.'
      });
      this.spin=false//CDPJ
      return;
    }
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        return;
      }else if(this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length === 0){
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Abono',
          detail: 'Ingrese una forma de pago al detalle'
        });
      return;
      }
      this.generarconcepto()
    }
    if (valor.object !== undefined) {
      this.detalleFormaPago = valor.object;
    }
  }

  seleccionFormaPagoDev(valor) {
    if (valor === 'nuevo') {
      this.nuevaFormaPagoDev();
    } else if (valor === 'eliminar') {
      this.confirmarEliminarFormaPagoDev();
    } else if (valor === 'cancelar') {
      this.cancelarFormaPagoDev();
    }
    if (valor.object !== undefined) {
      this.detalleFormaPagoDev = valor.object;
    }
  }

  seleccionVarios(valor) {
    if (valor === 'nuevo') {
      this.nuevoVario();
    } else if (valor === 'eliminar') {
      this.confirmarEliminarVarios();
    } else if (valor === 'cancelar') {
      this.cancelarVarios();
    }
    if (valor.object !== undefined) {
      this.detalleVariosSeleccionado = valor.object;
    }
  }

  seleccionDebito() {

  }

  seleccionAnt() {

  }

  nuevoVario() {
    this.editpagofr = false;
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = false;
    this.btnBorrarGrid = false;
    this.btnCancelarGrid = false;
    const linea = this.cxcCarteraService.cxctrnvariosarray[this.indicador].length;
    const nuevodetalle = {
      CON_CODIGO: '',
      CON_NOMBRE: '',
      TRNVARIOS_IMPORTE: '',
      DEBE: '',
      HABER: '',
      TRNVARIOS_LINEA: 0,
      CLI_CODIGO: this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
      TRNCOBRO_NRODOC: '',
      BAN_CODIGO: '',
      TRNBAN_TIPO: '',
      TRNBAN_NUMERO: '',
      TRNCOBRO_NROCOMPROBANTE: '',
      LINEA: String(linea + 1),
      CEN_CODIGO:''
    };
    if (this.detalleVariosSeleccionado !== undefined && this.detalleVariosSeleccionado !== null) {
      const indi = this.cxcCarteraService.cxctrnvariosarray[this.indicador].indexOf(this.detalleVariosSeleccionado);
      if (indi === -1) {
        this.cxcCarteraService.cxctrnvariosarray[this.indicador].push(nuevodetalle);
        this.detalleVariosSeleccionado = this.cxcCarteraService.cxctrnvariosarray[this.indicador][this.cxcCarteraService.cxctrnvariosarray[this.indicador].length - 1];
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraVarios.objeto].setFocusedCell(this.cxcCarteraService.cxctrnvariosarray[this.indicador].length - 1, 'CON_CODIGO');
        this.tabDebitoBancario = false;
      } else {
        this.cxcCarteraService.cxctrnvariosarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
        this.aggrid.refreshaggridindex(this.cxcCarteraService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraVarios.objeto].setFocusedCell(indi + 1, 'CON_CODIGO');
        this.tabDebitoBancario = false;
      }
    }
  }

  confirmarEliminarVarios() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'carteraConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarVarios();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  eliminarVarios() {
    if (this.detalleVariosSeleccionado !== undefined) {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleVariosSeleccionado.TRNVARIOS_LINEA === 0) {
        this.cxcCarteraService.cxctrnvariosarray[this.indicador] = this.cxcCarteraService.cxctrnvariosarray[this.indicador].filter((val, j) => j !==
          this.cxcCarteraService.cxctrnvariosarray[this.indicador].indexOf(this.detalleVariosSeleccionado));
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
          this.cxcCarteraService.calcularTotalND();
          if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length === 0) {
            this.tabDebitoBancario = true;
          }
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
          this.cxcCarteraService.calcularTotalNC();
        }
      } else {
        this.cxcCarteraService.cxctrnvariosarray[this.indicador] = this.cxcCarteraService.cxctrnvariosarray[this.indicador].filter((val, j) => j !==
          this.cxcCarteraService.cxctrnvariosarray[this.indicador].indexOf(this.detalleVariosSeleccionado));
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
          this.cxcCarteraService.calcularTotalND();
          if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length === 0) {
            this.tabDebitoBancario = true;
          }
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
          this.cxcCarteraService.calcularTotalNC();
        }
      }
    }
  }

  cancelarVarios() {
    if (this.detalleVariosSeleccionado.TRNVARIOS_LINEA === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxcCarteraService.cxctrnvariosarray[this.indicador] = this.cxcCarteraService.cxctrnvariosarray[this.indicador].filter((val, j) => j !==
        this.cxcCarteraService.cxctrnvariosarray[this.indicador].indexOf(this.detalleVariosSeleccionado));
      this.detalleVariosSeleccionado = {};
    } else if (this.detalleVariosSeleccionado.TRNVARIOS_LINEA !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxcCarteraService.obtenerVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.cxcCarteraService.cxctrnvariosarray[this.indicador] = res;
        this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
        if (res !== null) {
          res.map((vario) => {
            if (Number(vario.TRNVARIOS_IMPORTE) < 0) {
              vario.DEBE = 0;
              vario.HABER = vario.TRNVARIOS_IMPORTE;
            } else if (Number(vario.TRNVARIOS_IMPORTE) >= 0) {
              vario.DEBE = vario.TRNVARIOS_IMPORTE;
              vario.HABER = 0;
            }
            if (vario.TRNVARIOS_LINEA === this.detalleVariosSeleccionado.LINEA) {
              this.detalleVariosSeleccionadoAux
                = vario;
            }
          });
          this.cxcCarteraService.cxctrnvariosarray[this.indicador] = res;
          this.detalleFormaPagoDev = this.detalleVariosSeleccionadoAux;
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
            this.cxcCarteraService.calcularTotalND();
          } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
            this.cxcCarteraService.calcularTotalNC();
          }
        }
      });
    }
  }

  actualizarTablas() {
    if (this.indiceTablas === 0) {
      if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      }
    }
    if (this.tabVarios === true) {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
        this.cxcCarteraService.calcularTotalND();
      }
    }
    //GSRF
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDOANC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE;
      this.cxcCarteraService.calcularTotalDoc();
    }  
    //GSRF
  }

  nuevaRetencion() {
    if (this.numeroFactura !== '') {
      let intNumRet = 0;
      this.cxcCarteraService.erCountSacitrnretencion(this.numeroFactura).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            intNumRet = Number(er[0].COUN);
          }
        }

        if (intNumRet > 0) {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Información',
            detail: 'La factura ya tiene ingresada una retención.'
          });
        } else {
          this.editpagofr = false;
          this.btnNuevoGrid = true;
          this.btnGuardarGrid = false;
          this.btnBorrarGrid = false;
          this.btnCancelarGrid = false;
          const linea = this.cxcCarteraService.cxctrnretencionarray[this.indicador].length;
          const nuevodetalle = {
            RETENCION_CODIGO: '',
            TRNRETENCION_DESCRIPCION: '',
            TRNRETENCION_NRO: '',
            TRNRETENCION_BASE: 0,
            TRNRETENCION_PORCENTAJE: 0,
            TRNRETENCION_TOTALRETENIDO: 0,
            ENCFAC_NUMERO: this.numeroFactura,
            CLI_CODIGO: this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
            CEN_CODIGO: '',
            CON_CODIGO: '',
            TRNRETENCION_ORIGEN: 'CXC',
            LINEA: String(linea + 1),
            NUEVALINEA:0//CDPJ
          };
          if (this.detalleRetencionSeleccionado !== undefined && this.detalleRetencionSeleccionado !== null) {
            const indi = this.cxcCarteraService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado);
            if (indi === -1) {
              this.cxcCarteraService.cxctrnretencionarray[this.indicador].push(nuevodetalle);
              this.detalleRetencionSeleccionado = this.cxcCarteraService.cxctrnretencionarray[this.indicador][this.cxcCarteraService.cxctrnretencionarray[this.indicador].length - 1];
              this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto);
              this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraRet.objeto].setFocusedCell(this.cxcCarteraService.cxctrnretencionarray[this.indicador].length - 1, 'RETENCION_CODIGO');
            } else {
              //CDPJ
              this.cxcCarteraService.cxctrnretencionarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
              this.detalleRetencionSeleccionado = nuevodetalle;
              this.aggrid.refreshaggridindex(this.cxcCarteraService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto, indi);
              //CDPJ
              this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraRet.objeto].setFocusedCell(indi + 1, 'RETENCION_CODIGO');
            }
          }
        }
      });
    } else {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Información',
        detail: 'Escoja una factura...'
      });
    }
  }

  nuevosGastos() {
    this.editpagofr = false;
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = false;
    this.btnBorrarGrid = false;
    this.btnCancelarGrid = false;
    const linea = this.cxcCarteraService.cxctrnotrosgarray[this.indicador].length;
    const nuevodetalle = {
      COMISION_CODIGO: '',
      TRNCOMISION_DESCRIPCION: '',
      TRNCOMISION_NRO: '',
      TRNCOMISION_TOTALRETENIDO: 0,
      LINEA: String(linea + 1),
      TRNCOMISION_BASE: 0,
      TRNCOMISION_PORCENTAJE: 0,
      TRNCOBRO_NRODOC: this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
      TRNCOMISION_ORIGEN: 'CXC',
      CLI_CODIGO: this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
      CON_CODIGO: '',
      TRNCOBRO_NROCOMPROBANTE: this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
      TRNCOMISION_FLAG: ''
    };
    if (this.detalleOtrasFSeleccionada !== undefined && this.detalleOtrasFSeleccionada !== null) {
      const indi = this.cxcCarteraService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleOtrasFSeleccionada);
      if (indi === -1) {
        this.cxcCarteraService.cxctrnotrosgarray[this.indicador].push(nuevodetalle);
        this.detalleOtrasFSeleccionada = this.cxcCarteraService.cxctrnotrosgarray[this.indicador][this.cxcCarteraService.cxctrnotrosgarray[this.indicador].length - 1];
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCarteraOtrosG.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraOtrosG.objeto].setFocusedCell(this.cxcCarteraService.cxctrnotrosgarray[this.indicador].length - 1, 'COMISION_CODIGO');
      } else {
        this.cxcCarteraService.cxctrnotrosgarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
        this.aggrid.refreshaggridindex(this.cxcCarteraService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCarteraOtrosG.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraOtrosG.objeto].setFocusedCell(indi + 1, 'COMISION_CODIGO');
      }
    }
  }

  nuevaFormaPago() {
    this.editpagofr = false;
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = false;
    this.btnBorrarGrid = false;
    this.btnCancelarGrid = false;
    const linea = this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length;
    const nuevodetalle = {
      TRNCOBRO_NRODOC: '',
      TRNPAGO_TIPO: '',
      CAJ_CODIGO: '',
      TRNPAGO_NUMERO: '',
      TRNPAGO_CONCEPTO: this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_CONCEPTO,
      TRNPAGO_IMPORTE:
        Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) - Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO),
      BANCLI_CODIGO: '',
      BANCLI_NOMBRE: '',
      TRNPAGO_NROCTA: '',
      TRNPAGO_CODSRI: '',
      TRNPAGO_TIPDIS: '',
      TRNPAGO_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      CON_CODIGO: '',
      CLI_CODIGO: this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
      TRNCHEPOS_NUMDOC: '',
      LINEA: String(linea + 1)
    };
    this.detalleFormaPago = nuevodetalle;
    if (this.detalleFormaPago !== undefined && this.detalleFormaPago !== null) {
      const indi = this.cxcCarteraService.cxctrnformapagoarray[this.indicador].indexOf(this.detalleFormaPago);
      if (indi === -1) {
        this.cxcCarteraService.cxctrnformapagoarray[this.indicador].push(nuevodetalle);
        this.detalleFormaPago = this.cxcCarteraService.cxctrnformapagoarray[this.indicador][this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length - 1];
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPago.objeto].setFocusedCell(this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length - 1, 'TRNPAGO_TIPO');
        this.cxcCarteraService.calcularTotalFormaPago();
      } else {
        this.cxcCarteraService.cxctrnformapagoarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
        this.aggrid.refreshaggridindex(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPago.objeto].setFocusedCell(indi + 1, 'TRNPAGO_TIPO');
        this.cxcCarteraService.calcularTotalFormaPago();
      }
    }
  }

  nuevaFormaPagoDev() {
    this.editpagofr = false;
    this.btnNuevoGrid = true;
    this.btnGuardarGrid = false;
    this.btnBorrarGrid = false;
    this.btnCancelarGrid = false;
    const linea = this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].length;
    const nuevodetalle = {
      TRNCOBRO_NRODOC: '',
      TRNCAJ_TIPO: '',
      TRNCAJ_NUMERO: '',
      CAJ_CODIGO: '',
      BAN_CODIGO: '',
      TRNCAJ_FECHA:
        String(this.datePipe.transform(new Date(), 'dd/MM/yyyy')),
      TRNCAJ_IMPORTE: Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) -
        Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO),
      TRNCAJ_DESCRIPCION: this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_CONCEPTO,
      LINEA: String(linea + 1),
      BAN_BANCO: '',
      CLI_CODIGO: this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO
    };
    this.detalleFormaPagoDev = nuevodetalle;
    if (this.detalleFormaPagoDev !== undefined && this.detalleFormaPagoDev !== null) {
      const indi = this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].indexOf(this.detalleFormaPagoDev);
      if (indi === -1) {
        this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].push(nuevodetalle);
        this.detalleFormaPagoDev = this.cxcCarteraService.cxctrnformapagodevarray[this.indicador][this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].length - 1];
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagodevarray[this.indicador], this.defaultColumnDefCarteraFormaPagoDev.objeto);
        this.aggrid.refreshGridData(this.cxcCarteraService.cxctrnformapagodevarray[this.indicador]);
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagodevarray[this.indicador], this.defaultColumnDefCarteraFormaPagoDev.objeto);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPagoDev.objeto].setFocusedCell(this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].length - 1, 'TRNCAJ_TIPO');
      } else {
        this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
        this.aggrid.refreshaggridindex(this.cxcCarteraService.cxctrnformapagodevarray[this.indicador], this.defaultColumnDefCarteraFormaPagoDev.objeto, indi);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPagoDev.objeto].setFocusedCell(indi + 1, 'TRNCAJ_TIPO');
      }
    }
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'cliente') {
      this.busquedaSer.encontrarRegistro44(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((resCli) => {
        if (resCli !== null) {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO = resCli[0].CLI_CODIGO;
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE = resCli[0].CLI_NOMBRE;
          this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((res) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = Number(res[0].SALDO).toFixed(2);
          });
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = resCli[0].VEN_CODIGO;
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = resCli[0].VEN_NOMBRE;
          this.displayDialogBusquedaFast = false;
          this.btnNuevoGrid = false;
          this.btnBorrarGrid = false;
          this.btnCancelarGrid = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('vendedor').focus();
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== '') {
            this.cambiarTipoCartera();
          }
          if (resCli[0].CLI_CONREGMIC === 'S'
            || resCli[0].CLI_AGENTERET === 'S') {
            if (resCli[0].CLI_CONREGMIC === 'S'
              && resCli[0].CLI_AGENTERET === 'S') {
              this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
            } else {
              if (resCli[0].CLI_CONREGMIC === 'S') {

                this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
              }
              if (resCli[0].CLI_AGENTERET === 'S') {

                this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
              }
            }
          } else {
            this.lblAplicaRegMicroempresas = '';
          }
        } else {
          this.abrirClientes();
        }
      });
    } else if (evento.target.id === 'clientefacab') {
      this.busquedaSer.encontrarRegistro44(this.codClienteFac).subscribe((resCli) => {
        console.log('entro', resCli);
        if (resCli !== null) {
          this.codClienteFac = resCli[0].CLI_CODIGO;
          this.nombreClienteFac = resCli[0].CLI_NOMBRE;
          this.cxcCarteraService.getDocCobrados(this.codClienteFac, this.nroFacCancelada).subscribe((facpendientes) => {
            facpendientes.map((facturas) => {
              facturas.TRNCOBRO_FECHATRN = this.datePipe.transform(facturas.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
            });
            this.cxcCarteraService.cxctrnfaccanceladasarray[this.indicador] = facpendientes;
          });
          this.displayDialogBusquedaFast = false;
          document.getElementById('nroFacCanc').focus();
        } else {
          this.abrirClientesFacAbonadas();
        }
      });
    } else if (evento.target.id === 'vendedor') {
      this.busquedaSer.encontrarRegistro45(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO).subscribe((resVen) => {
        if (resVen !== null) {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = resVen[0].VEN_CODIGO;
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = resVen[0].VEN_NOMBRE;
          this.displayDialogBusquedaFast = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
        } else {
          this.abrirVendedor();
        }
      });
    } else if (evento.target.id === 'numeroCartera') {
      if (this.booleanBodega === true) {
        document.getElementById('bodega').focus();
      } else if (this.booleanBodega === false) {
        document.getElementById('concepto').focus();
      }
    } else if (evento.target.id === 'bodega') {
      this.busquedaSer.encontrarRegistro40(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO).subscribe((resBod) => {
        if (resBod !== null) {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = resBod[0].BOD_CODIGO;
          this.displayDialogBusquedaFast = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('concepto').focus();
        } else {
          this.abrirBodega();
        }
      });
    } else if (evento.target.id === 'concepto') {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
      } else {
        document.getElementById('importe').focus();
      }
    } else if (evento.target.id === 'importe') {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDOANC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE;
        this.cxcCarteraService.calcularTotalDoc();
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
        this.cxcCarteraService.calcularTotalND();
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
      } else {
        this.cxcCarteraService.calcularTotalDoc();
      }
    } else if (evento.target.id === 'banco') {
      this.busquedaSer.encontrarRegistro37(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO).subscribe((resBanco) => {
        if (resBanco !== null) {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO = resBanco[0].BAN_CODIGO;
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO = resBanco[0].BAN_BANCO;
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO = resBanco[0].CON_CODIGO;
          this.displayDialogBusquedaFast = false;
          this.cxcCarteraService.cxcdebitobancarioarray[this.indicador] = [];
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO !== null || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO !== '') {
            this.tabVarios = false;
            if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO !== '') {
              this.cxcCarteraService.obtenerChequesCli(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((cheques) => {
                if (cheques.length !== 0) {
                  cheques.map((cheque) => {
                    const obj: Cxctrndebitobancario = {};
                    obj.SELECCIONADO = false;
                    obj.TRNPAGO_FECHA = this.datePipe.transform(cheque.TRNPAGO_FECHA, 'dd/MM/yyyy');
                    obj.TRNPAGO_TIPO = cheque.TRNPAGO_TIPO;
                    obj.TRNPAGO_NUMERO = cheque.TRNPAGO_NUMERO;
                    obj.TRNPAGO_IMPORTE = Number(cheque.TRNPAGO_IMPORTE).toFixed(2);
                    obj.TRNPAGO_NROCTA = cheque.TRNPAGO_NROCTA;
                    obj.BANCLI_CODIGO = cheque.BANCLI_CODIGO;
                    obj.BANCLI_NOMBRE = cheque.BANCLI_NOMBRE;
                    obj.TRNCOBRO_NRODOC = cheque.TRNCOBRO_NROCOMPROBANTE;
                    this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].push(obj);
                  });
                }
                this.aggrid.refreshaggrid(this.cxcCarteraService.cxcdebitobancarioarray[this.indicador], this.defaultColumnDefCarteraDebito.objeto);
              });
            }
          } else {
            this.tabVarios = true;
          }
        } else {
          this.abrirBancos();
        }
      });
    }
  }

  abrirClientes() {
    this.opcion = 'BUSQUEDACLI';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RUCIDE', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'ZONA_NOMBRE' },
      { label: 'Vendedor', value: 'VEN_NOMBRE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'Nombreec', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.consulta = 'ven_maecliente.*,  VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO) AS' +
      ' VEN_NOMBRE, VEN_FNC_OBTENER_ZONACLI(CLI_ZONA) AS ZONA_NOMBRE';
    this.where = '';
    this.spin = true;
    this.busquedaSer.busquedaVenMaeCliente1(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirClientesFacAbonadas() {
    this.opcion = 'BUSQUEDACLIFAC';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RUCIDE', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'ZONA_NOMBRE' },
      { label: 'Vendedor', value: 'VEN_NOMBRE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'Nombreec', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [this.codClienteFac, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.consulta = 'ven_maecliente.*,  VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO) AS' +
      ' VEN_NOMBRE, VEN_FNC_OBTENER_ZONACLI(CLI_ZONA) AS ZONA_NOMBRE';
    this.where = '';
    this.spin = true;
    this.busquedaSer.busquedaVenMaeCliente1(this.codClienteFac).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirBodega() {
    this.opcion = 'BUSQUEDABOD';
    this.busquedaCompuesta = false;
    this.types = [
      { label: 'Código', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' }
    ];
    this.busquedacampos = [this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO, ''];
    this.tabla = 'inv_maebodega';
    this.consulta = '*';
    this.where = '';
    this.spin = true;
    this.busquedaSer.busquedaInvMaeBodega1(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirVendedor() {
    this.opcion = 'BUSQUEDAVEN';
    this.types = [
      { label: 'Código', value: 'VEN_CODIGO' },
      { label: 'Nombre', value: 'VEN_NOMBRE' }
    ];
    this.busquedacampos = [this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO, ''];
    this.tabla = 'ven_maevendedor';
    this.consulta = '*';
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '' || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === null) {
      this.where = '';
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO !== '') {
      this.where = this.types[0].value + ' LIKE \'%' +
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO + '%\'';
    }
    this.spin = true;
    this.busquedaSer.busquedaVenMaeVendedor2(this.where).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirSustentoTrib() {
    if (this.anexoSeleccionado.TRNANEX_IDCRETRI === undefined) {
      this.anexoSeleccionado.TRNANEX_IDCRETRI = '';
    }

    this.opcion = 'TRNANEX_IDCRETRI';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código Con', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = [this.anexoSeleccionado.TRNANEX_IDCRETRI, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
    this.where = 'CODSRI_TIPO=\'CT\'';
    this.cxcCarteraService.erAnexMaeCodSri(this.where, this.anexoSeleccionado.TRNANEX_IDCRETRI).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.manejarSeleccion(er[0]);
        } else {
          this.spin = true;
          this.busquedaSer.busquedaAnexMaeCodSri13(this.anexoSeleccionado.TRNANEX_IDCRETRI).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.spin = false;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.spin = true;
        this.busquedaSer.busquedaAnexMaeCodSri13(this.anexoSeleccionado.TRNANEX_IDCRETRI).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.spin = false;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  abrirTipoComprobante() {
    if (this.anexoSeleccionado.TRNANEX_TIPCOM === undefined) {
      this.anexoSeleccionado.TRNANEX_TIPCOM = '';
    }
    this.opcion = 'BUSQUEDATIPOCOMP';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código Con', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = [this.anexoSeleccionado.TRNANEX_TIPCOM, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
    this.where = 'UPPER(CODSRI_CODIGO) = \'' +
      this.anexoSeleccionado.TRNANEX_TIPCOM +
      '\' AND CODSRI_TIPO=\'CT\'';
    this.spin = true;
    this.cxcCarteraService.erCodsridescripcion(this.anexoSeleccionado.TRNANEX_TIPCOM).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirCODSRI() {
    if (this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO === undefined) {
      this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = '';
    }
    this.opcion = 'BUSQUEDATIPOSRI';
    this.busquedacampos = [this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO, '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = '*';
    this.where = 'CODSRI_TIPO = \'CF\'';

    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'CODIGOCON', value: 'CON_CODIGO' }
    ];
    this.spin = true;
    this.busquedaSer.busquedaAnexMaeCodSri14().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  buscarReferencia(opcion) {
    let parametro = '';
    if (opcion === 'TRNANEX_TIPOSRIGRAVADA') {
      if (this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA === undefined) {
        this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = '';
      }
      parametro = this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA;
    }
    if (opcion === 'TRNANEX_TIPOSRITARCERO') {
      if (this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO === undefined) {
        this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = '';
      }
      parametro = this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO;
    }


    this.opcion = opcion;
    this.busquedacampos = [parametro, '', '', '', ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.consulta = '*';
    this.where = 'REF_TIPO = \'RESVTA\'';

    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];
    this.cxcCarteraService.erSacimaereferencia(this.where, parametro).subscribe(er => {
      console.log(er);
      if (er !== null) {
        if (er[0] !== undefined) {
          this.manejarSeleccion(er[0]);
        } else {
          this.spin = true;
          this.busquedaSer.busquedaSaciMaeReferencia4(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.spin = false;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.spin = true;
        this.busquedaSer.busquedaSaciMaeReferencia4(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.spin = false;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  cambiarTipoCartera() {
    this.disimporte=false;
    this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = [];
    this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET =
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO =
      Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO).toFixed(2);
    this.tabVarios = false;
    this.tabDebitoBancario = false;
    this.tabFormaPagoDev = false;
    this.tabRetenciones = false;
    this.tabOtrosGastos = false;
    this.tabEstadoCuentaAnt = false;
    this.tabFormaPago = false;
    this.obtenerNumeracionDoc();

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC'
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA'
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB'
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT'
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN'
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
      this.bolsaldoIni = true;
    } else {
      this.bolsaldoIni = false;
    }

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Información',
        detail: 'Seleccione un cliente'
      });
      document.getElementById('cliente').focus();
    } else {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'FC';
        this.booleanBodega = true;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE = new Date();
        this.saldoInicial = true;
        this.booleanNumero = false;
        this.bolsaldoIni = true;
        setTimeout(() => {
          document.getElementById('numeroCartera').focus();
        }, 1000);
      }

      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'DAN';
        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabFormaPagoDev = true;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.consultarDocumentosACobrar('ANC', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
            if (res !== null) {
              res.map((devanc) => {
                if (devanc.SALDO > 0) {
                  const obj: Cxctrnresumen = {};
                  obj.SELECCIONADO = false;
                  obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
                  obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
                  if (devanc.FECHAVENCE !== null) {
                    obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
                  } else {
                    obj.TRNCOBRO_FECHAVENCE = '';
                  }
                  obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
                  obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
                  obj.TRNCOBRO_IMPORTE = 0;
                  if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
                    obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
                  }
                  if (devanc.VENDEDOR !== null) {
                    obj.VEN_CODIGO = devanc.VENDEDOR;
                  } else {
                    obj.VEN_CODIGO = '';
                  }
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(obj);
                }
              });
              this.aggrid.refreshGridData(this.cxcCarteraService.cxctrnresumenarray[this.indicador]);
            }
          });
      }

      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'ANC';
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabFormaPago = true;
        this.tabEstadoCuentaAnt = true;
        this.booleanNumero = false;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
        this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
        setTimeout(() => {
          document.getElementById('numeroCartera').focus();
        }, 1000);
      }

      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'NC';
        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabVarios = true;
        this.booleanNumero = false;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
        //CDPJ
        if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCCAVNC').CFG_VALOR1 !== 1){
          this.columDefCarteraVarios.splice(5, 1);
          this.aggrid.refreshColumnDefs();
        }
        //CDPJ
        this.cxcCarteraService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
            if (res !== null) {
              res.map((devanc) => {
                if (devanc.SALDO > 0) {
                  const obj: Cxctrnresumen = {};
                  obj.SELECCIONADO = false;
                  obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
                  obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
                  if (devanc.FECHAVENCE !== null) {
                    obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
                  } else {
                    obj.TRNCOBRO_FECHAVENCE = '';
                  }
                  obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
                  obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
                  obj.TRNCOBRO_IMPORTE = 0;
                  if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
                    obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
                  }
                  if (devanc.VENDEDOR !== null) {
                    obj.VEN_CODIGO = devanc.VENDEDOR;
                  } else {
                    obj.VEN_CODIGO = '';
                  }
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(obj);
                }
              });
              //CDPJ
              this.aggrid.refreshGridData(this.cxcCarteraService.cxctrnresumenarray[this.indicador]);
              //CDPJ
              //this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
              setTimeout(() => {
                this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
                document.getElementById('numeroCartera').focus();
              }, 1000);
            }
          });
      }

      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'ND';
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabVarios = true;
        this.tabDebitoBancario = true;
        this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
        this.cxcCarteraService.cxcdebitobancarioarray[this.indicador] = [];
        this.booleanNumero = false;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE = new Date();
        //CDPJ
        this.columDefCarteraVarios.splice(5, 1);
          this.aggrid.refreshColumnDefs();
        //CDPJ
        setTimeout(() => {
          document.getElementById('numeroCartera').focus();
        }, 1000);
      }

      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
        this.cxcCarteraService.tipoDocumentoarray[this.indicador] = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC;
        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabRetenciones = true;
        this.tabOtrosGastos = true;
        this.tabFormaPago = true;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
        this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = [];
        this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
        this.cxcCarteraService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
            if (res !== null) {
              res.map((devanc) => {
                if (devanc.SALDO > 0) {
                  const obj: Cxctrnresumen = {};
                  obj.SELECCIONADO = false;
                  obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
                  obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
                  if (devanc.FECHAVENCE !== null) {
                    obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
                  } else {
                    obj.TRNCOBRO_FECHAVENCE = '';
                  }
                  obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO === undefined || devanc.CONCEPTO === null ? '' : devanc.CONCEPTO;
                  obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
                  obj.TRNCOBRO_IMPORTE = 0;
                  if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
                    obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
                  }
                  if (devanc.VENDEDOR !== null) {
                    obj.VEN_CODIGO = devanc.VENDEDOR;
                  } else {
                    obj.VEN_CODIGO = '';
                  }
                  obj.TRNCOBRO_TIPODOC = devanc.TRNCOBRO_TIPODOC;
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(obj);
                }
              });
              //CDPJ
              this.aggrid.refreshGridData(this.cxcCarteraService.cxctrnresumenarray[this.indicador]);
              //CDPJ
              //this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
              console.log(this.cxcCarteraService.cxctrnresumenarray[this.indicador]);
              setTimeout(() => {
                document.getElementById('numeroCartera').focus();
              }, 1000);
            }
          });
      }

      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
        this.cxcCarteraService.tipoDocumentoarray[this.indicador] = 'RT';
        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabRetenciones = true;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
        this.cxcCarteraService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
            if (res !== null) {
              res.map((devanc) => {
                if (devanc.SALDO > 0) {
                  const obj: Cxctrnresumen = {};
                  obj.SELECCIONADO = false;
                  obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
                  obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
                  if (devanc.FECHAVENCE !== null) {
                    obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
                  } else {
                    obj.TRNCOBRO_FECHAVENCE = '';
                  }
                  obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
                  obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
                  obj.TRNCOBRO_IMPORTE = 0;
                  if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
                    obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
                  }
                  if (devanc.VENDEDOR !== null) {
                    obj.VEN_CODIGO = devanc.VENDEDOR;
                  } else {
                    obj.VEN_CODIGO = '';
                  }
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(obj);
                }
              });
              //CDPJ
              this.aggrid.refreshGridData(this.cxcCarteraService.cxctrnresumenarray[this.indicador]);
              //CDPJ
              //this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
              setTimeout(() => {
                document.getElementById('numeroCartera').focus();
              }, 1000);
            }
          });
      }
    }
  }

  cambiarTipoCarteraWilson() {
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALRET.toFixed(2);
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO).toFixed(2);
    this.tabVarios = false;
    this.tabDebitoBancario = false;
    this.tabFormaPagoDev = false;
    this.tabRetenciones = false;
    this.tabOtrosGastos = false;
    this.tabEstadoCuentaAnt = false;
    this.tabFormaPago = false;
    this.obtenerNumeracionDoc();
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO !== '') {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'FC';
        this.booleanBodega = true;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE = new Date();
        this.saldoInicial = true;
        this.booleanNumero = false;
        setTimeout(() => {
          document.getElementById('numeroCartera').focus();
        }, 1000);
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'DAN';
        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabFormaPagoDev = true;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.consultarDocumentosACobrar('ANC', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
            if (res !== null) {
              res.map((devanc) => {
                if (devanc.SALDO > 0) {
                  const obj: Cxctrnresumen = {};
                  obj.SELECCIONADO = false;
                  obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
                  obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
                  if (devanc.FECHAVENCE !== null) {
                    obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
                  } else {
                    obj.TRNCOBRO_FECHAVENCE = '';
                  }
                  obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
                  obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
                  obj.TRNCOBRO_IMPORTE = 0;
                  if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
                    obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
                  }
                  if (devanc.VENDEDOR !== null) {
                    obj.VEN_CODIGO = devanc.VENDEDOR;
                  } else {
                    obj.VEN_CODIGO = '';
                  }
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(obj);
                }
              });
              this.aggrid.refreshGridData(this.cxcCarteraService.cxctrnresumenarray[this.indicador]);
            }
          });
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'ANC';
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabFormaPago = true;
        this.tabEstadoCuentaAnt = true;
        this.booleanNumero = false;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
        this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
        setTimeout(() => {
          document.getElementById('numeroCartera').focus();
        }, 1000);
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'NC';
        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabVarios = true;
        this.booleanNumero = false;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
        this.cxcCarteraService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
            if (res !== null) {
              res.map((devanc) => {
                if (devanc.SALDO > 0) {
                  const obj: Cxctrnresumen = {};
                  obj.SELECCIONADO = false;
                  obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
                  obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
                  if (devanc.FECHAVENCE !== null) {
                    obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
                  } else {
                    obj.TRNCOBRO_FECHAVENCE = '';
                  }
                  obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
                  obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
                  obj.TRNCOBRO_IMPORTE = 0;
                  if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
                    obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
                  }
                  if (devanc.VENDEDOR !== null) {
                    obj.VEN_CODIGO = devanc.VENDEDOR;
                  } else {
                    obj.VEN_CODIGO = '';
                  }
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(obj);
                }
              });
              this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
              setTimeout(() => {
                this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
                document.getElementById('numeroCartera').focus();
              }, 1000);
            }
          });
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'ND';
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabVarios = true;
        this.tabDebitoBancario = true;
        this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
        this.cxcCarteraService.cxcdebitobancarioarray[this.indicador] = [];
        this.booleanNumero = false;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE = new Date();
        setTimeout(() => {
          document.getElementById('numeroCartera').focus();
        }, 1000);
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
        this.cxcCarteraService.tipoDocumentoarray[this.indicador] = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC;
        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabRetenciones = true;
        this.tabOtrosGastos = true;
        this.tabFormaPago = true;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
        this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = [];
        this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
        this.cxcCarteraService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
            if (res !== null) {
              res.map((devanc) => {
                if (devanc.SALDO > 0) {
                  const obj: Cxctrnresumen = {};
                  obj.SELECCIONADO = false;
                  obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
                  obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
                  if (devanc.FECHAVENCE !== null) {
                    obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
                  } else {
                    obj.TRNCOBRO_FECHAVENCE = '';
                  }
                  obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
                  obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
                  obj.TRNCOBRO_IMPORTE = 0;
                  if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
                    obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
                  }
                  if (devanc.VENDEDOR !== null) {
                    obj.VEN_CODIGO = devanc.VENDEDOR;
                  } else {
                    obj.VEN_CODIGO = '';
                  }
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(obj);
                }
              });
              this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
              console.log(this.cxcCarteraService.cxctrnresumenarray[this.indicador]);
              setTimeout(() => {
                document.getElementById('numeroCartera').focus();
              }, 1000);
            }
          });
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
        this.cxcCarteraService.tipoDocumentoarray[this.indicador] = 'RT';
        this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabRetenciones = true;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
        this.cxcCarteraService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE UPPER(\'' +
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\')').subscribe((res) => {
            if (res !== null) {
              res.map((devanc) => {
                if (devanc.SALDO > 0) {
                  const obj: Cxctrnresumen = {};
                  obj.SELECCIONADO = false;
                  obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
                  obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
                  if (devanc.FECHAVENCE !== null) {
                    obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
                  } else {
                    obj.TRNCOBRO_FECHAVENCE = '';
                  }
                  obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
                  obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
                  obj.TRNCOBRO_IMPORTE = 0;
                  if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
                    obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
                  }
                  if (devanc.VENDEDOR !== null) {
                    obj.VEN_CODIGO = devanc.VENDEDOR;
                  } else {
                    obj.VEN_CODIGO = '';
                  }
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].push(obj);
                }
              });
              this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
              setTimeout(() => {
                document.getElementById('numeroCartera').focus();
              }, 1000);
            }
          });
      }
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Información',
        detail: 'Seleccione un cliente'
      });
      document.getElementById('cliente').focus();
    }
  }

  docSeleccionado(valor) {
    if (valor.data.SELECCIONADO === true && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      valor.data.TRNCOBRO_IMPORTE = valor.data.TRNCOBRO_SALDOINI;
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
        this.numeroFactura = valor.data.TRNCOBRO_NRODOC;
      }
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      const indi = this.cxcCarteraService.cxctrnresumenarray[this.indicador].indexOf(valor.data);
      if (indi === -1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(0, 'SELECCIONADO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(indi, 'SELECCIONADO');
      }
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColumnDefCarteraDoc.objeto].forEachNode((node) => {
          const h = indi;
          if (Number(node.id) === h) {
            node.setSelected(true);
          }
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColumnDefCarteraDoc.objeto].ensureIndexVisible(h, 'bottom');
        });
      this.cxcCarteraService.calcularTotalDoc();
    }

    if (valor.data.SELECCIONADO === false) {
      valor.data.TRNCOBRO_IMPORTE = 0;
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      const indi = this.cxcCarteraService.cxctrnresumenarray[this.indicador].indexOf(valor.data);
      if (indi === -1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(0, 'SELECCIONADO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(indi, 'SELECCIONADO');
      }
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColumnDefCarteraDoc.objeto].forEachNode((node) => {
          const h = indi;
          if (Number(node.id) === h) {
            node.setSelected(true);
          }
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
            this.defaultColumnDefCarteraDoc.objeto].ensureIndexVisible(h, 'bottom');
        });
      this.cxcCarteraService.calcularTotalDoc();
      
    }
    //console.log(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE)
  }

  debitoSeleccionado() {
    this.cxcCarteraService.calcularTotalND();
  }

  abrirBancos() {
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO === undefined) {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO = '';
    }
    this.opcion = 'BUSQUEDABAN';
    this.types = [
      { label: 'Código', value: 'BAN_CODIGO' },
      { label: 'Banco', value: 'BAN_BANCO' },
      { label: 'Cuenta Nro.', value: 'BAN_CTANRO' },
      { label: 'Jefe Cuenta', value: 'BAN_JEFECTA' },
      { label: 'Sucursal', value: 'BAN_SUCURSAL' },
      { label: 'Dirección', value: 'BAN_DIRECCION' },
      { label: 'Telefóno 1', value: 'BAN_TELEFONO1' },
      { label: 'Referencia Contable', value: 'CON_CODIGO' },
      { label: 'CEN_CODIGO', value: 'CEN_CODIGO' },
      { label: 'Tipo de cuenta', value: 'BAN_TIPOCUENTA' },
      { label: 'Doc por Pagar', value: 'CON_CODIGO2' },
      { label: 'Proceso', value: 'BAN_PROCESO_CM' },
      { label: 'Directorio CM', value: 'BAN_DIRECTORIO_CM' },
      { label: 'Dinero Elect', value: 'BAN_DINEROELEC' },
      { label: 'Tel Cel', value: 'BAN_TELEFONOCEL' },
      { label: 'Último Cheque', value: 'BAN_ULTIMOCHEQUE' },
    ];
    this.busquedacampos = [this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO, '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'BAN_MAEBAN';
    this.consulta = '*';
    this.where = '';
    this.spin = true;
    this.busquedaSer.busquedaBanMaeBan3(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  cambioSaldo() {
    if (this.saldoInicial === true) {
      this.tabVarios = false;
      this.tabDebitoBancario = false;
      this.tabFormaPagoDev = false;
      this.tabRetenciones = false;
      this.tabOtrosGastos = false;
      this.tabEstadoCuentaAnt = false;
      //CDPJ
      if(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC'){
        this.tabEstadoCuentaAnt = true;
      }
      //CDPJ
      this.tabFormaPago = false;
    } else if (this.saldoInicial === false) {
      const numeroDoc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
      this.cambiarTipoCartera();
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = numeroDoc;
    }
  }

  obtenerNumeracionDoc() {
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC
      === 'AB' || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
        if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROCMPRAUT').CFG_VALOR1 === 1) {
          this.booleanNumero = false;
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ANC') {
            this.cxcCarteraService.obtenerNumeracion('CI').subscribe((num) => {
              this.numSerie = num[0].NUM_SERIE;
              if (num.length === 0) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No existe una numeración para el documento'
                });
              } else if (Number(num[0].NUM_SECACTUAL) >= Number(num[0].NUM_SECFINAL)) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'Se terminó el secuencial de la factura'
                });
              } else {
                const numero = num[0].NUM_SECACTUAL.replace(/\b0+/g, '');
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = Number(numero);
                if (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length !== 0) {
                  if (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length <= 10 &&
                    String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length !== 0) {
                    do {
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '0' +
                        String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
                    } while (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length <= 10);
                  }
                }
              }
            });
          }
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
            this.cxcCarteraService.obtenerNumeracion('CI').subscribe((num1) => {
              console.log(num1);
              this.numSerie = num1[0].NUM_SERIE;
              if (num1.length === 0) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No existe una numeración para el documento'
                });
              } else if (Number(num1[0].NUM_SECACTUAL) >= Number(num1[0].NUM_SECFINAL)) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'Se terminó el secuencial de la factura'
                });
              } else {
                const numero1 = String(num1[0].NUM_SECACTUAL).replace(/\b0+/g, '');
                console.log(numero1);
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = Number(numero1);
                if (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length !== 0) {
                  if (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length <= 10 &&
                    String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length !== 0) {
                    do {
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '0' +
                        String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
                    } while (String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length <= 10);
                  }
                }
              }
            });
          }
        } else {
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ANC') {
            this.booleanNumero = false;
          }
        }
      } else {
        this.booleanNumero = true;
      }
    }
  }

  cambiarBotones() {
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.botonBuscar = false;
    this.botonAsiento = false;
    this.botonVerAsiento = false;
    this.botonAnular = false;
  }

  confirmarCartera() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el documento',
      header: 'Eliminar Cartera',
      icon: 'pi pi-exclamation-triangle',
      key: 'carteraConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarCartera();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async eliminarCartera() {
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] !== undefined) {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
        this.cxcCarteraService.existePagoDoc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((comp) => {
          if (comp.length !== 0) {
            this.message.add({
              key: 'cartera',
              severity: 'error',
              summary: 'Eliminación de Cartera',
              detail: 'El documento tiene cobros, por lo que no se puede eliminar'
            });
            return;
          }
        });
      }
      if (this.tabFormaPago === true) {
        if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
          this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map(async (formap) => {
            if (formap.TRNPAGO_TIPO === 'ANCL') {
              const eR = await this.cxcCarteraService.erTrnpagorefer(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
              let strNumDocAnt = '';
              if (eR !== undefined) {
                if (eR[0].TRNPAGO_REFER !== null) {
                  strNumDocAnt = eR[0].TRNPAGO_REFER;
                }
              }

              await this.cxcCarteraService.eliminarTrnCobroProm(strNumDocAnt);
              this.eliminarPagosCuotas();
              // this.cxcCarteraService.eliminarNDATrnResumen(formap.TRNCOBRO_NRODOC).subscribe(() => {
              //   console.log('Se actualiza');
              // }, error1 => {
              //   this.message.add({
              //     key: 'cartera',
              //     severity: 'error',
              //     summary: 'Eliminación de Cartera',
              //     detail: 'No se pudo eliminar la cartera, error ' + error1.error
              //   });
              // });
              // this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
              //   '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Eliminación de Cartera',
                    detail: 'Se eliminó la cartera correctamente'
                  });
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = {
                    SALDO: '',
                    TRNCOBRO_TIPODOC: '',
                    TOTALFORMAPAGO: 0,
                    TOTALND: 0,
                    TRNCOBRO_IMPORTE: 0,
                    TOTALNC: 0,
                    TOTALOTROSG: 0,
                    TOTALRET: 0,
                    TOTALACOBRAR: 0,
                    TOTALDOC: 0,
                    BAN_BANCO: '',
                    BAN_CODIGO: '',
                    TRNCOBRO_NROCOMPROBANTE: '',
                    TRNCOBRO_SALDOINI: '',
                    CON_CODIGO: '',
                    TRNCOBRO_NRODOC: '',
                    TOTALANTICIPO: '',
                    TRNCOBRO_FECHAVENCE: '',
                    CLI_NOMBRE: '',
                    REALIZADO: '',
                    ORIGEN: '',
                    VEN_NOMBRE: '',
                    APLORG_CODIGO: 'CXC',
                    USU_IDENTIFICACION: this.usuario.identificacion,
                    TRNCOBRO_CONCEPTO: '',
                    BOD_CODIGO: '',
                    TRNCOBRO_FECHATRN: new Date(),
                    VEN_CODIGO: '',
                    CLI_CODIGO: '',
                    ASI_NRO: '',
                    CABROLADMN_CODIGO: '',
                    CABROLVEND_CODIGO: '',
                    COM_CODIGO: '01',
                    COM_CODIGO_CXP: '01',
                    COM_TIPOAMBFACTELEC: '',
                    EMP_CODIGO: '',
                    EMP_CODIGOVEND: '',
                    TRNCOBRO_ANULADO: '',
                    TRNCOBRO_CAMBIO: '',
                    TRNCOBRO_CUADRE: '',
                    TRNCOBRO_EFECTIVO: '',
                    TRNCOBRO_FLAG: '1',
                    TRNCOBRO_IMPRESO: '',
                    TRNCOBRO_LOCALIZACIONXMLRET: '',
                    TRNCOBRO_NRODOC_CXP: '',
                    TRNCOBRO_NRODOC_OLD: '',
                    TRNCOBRO_PATHPDF_FE: '',
                    TRNCOBRO_PATHXMLNOAUTO_FE: '',
                    TRNCOBRO_PATHXMLRET: '',
                    TRNCOBRO_REFERENCIAEXTERNA: '',
                    TRNCOBROND_AUTORIZACION_FE: '',
                    TRNCOBROND_CLAVEACCESO_FE: '',
                    TRNCOBROND_ESTADO_FE: '',
                    TRNCOBROND_FECHAAUT_FE: '',
                    TRNCOBROND_NUMFACTELEC: ''
                  };
                  this.booleanBodega = false;
                  this.saldoInicial = false;
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
                  this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
                  this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
                //});


            }
          });
        }
      }

      if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ELIASIELI').CFG_VALOR1 === 0) {
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === false) {
          this.cxcCarteraService.anularAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO).subscribe(() => {
          });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === true) {
          this.cxcCarteraService.actualizarAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
          });
        }
      } else {
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === false) {
          this.cxcCarteraService.eliminarAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO).subscribe(() => {
          });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === true) {
          this.cxcCarteraService.actualizarAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
          });
        }
      }
      ////GSRF
      this.eliminarFormaPagoDev();
      ////GSRF

      this.cxcCarteraService.eliminarAntAsoc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe();
      try {
         //CDPJ 
         if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
          await this.cxcCarteraService.eliminarMovBan(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC)
         }      
         //CDPJ
        await this.cxcCarteraService.eliminarTrnCobroProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC)
        this.eliminarPagosCuotas();
        // this.cxcCarteraService.eliminarNDATrnResumen(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
        // });
        //GSRF 
        // const claveac = this.cxcCarteraService.cxctrnretencionarray[this.indicador]
        // console.log('<<<<<<enta1r>>>>>>',  claveac[0].TRNRETENCION_AUTORIZACION)
        // if( claveac[0].TRNRETENCION_AUTORIZACION !== null &&  claveac[0].TRNRETENCION_AUTORIZACION !=='' &&  claveac[0].TRNRETENCION_AUTORIZACION!==undefined){
        //   console.log('<<<<<<enta99991r>>>>>>')
        //   this.cxcCarteraService.deleteimptable(claveac[0].TRNRETENCION_AUTORIZACION)
        // }
        const claveac = this.cxcCarteraService.cxctrnretencionarray[this.indicador]
        console.log('1',claveac)
        console.log(claveac[0])
        if (claveac !== undefined && claveac !== null){
          if(claveac[0] !== undefined && claveac[0] !== null && claveac[0] !== ''){
            console.log('<<<<<<enta1r>>>>>>',  claveac[0].TRNRETENCION_AUTORIZACION)
          if( claveac[0].TRNRETENCION_AUTORIZACION !== null &&  claveac[0].TRNRETENCION_AUTORIZACION !=='' &&  claveac[0].TRNRETENCION_AUTORIZACION!==undefined){
            console.log('<<<<<<enta99991r>>>>>>')
            this.cxcCarteraService.deleteimptable(claveac[0].TRNRETENCION_AUTORIZACION)
            }
          }  
        }
        //GSRF
        this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
          '', '01', '', '', '', '').subscribe(() => {
            this.message.add({
              key: 'cartera',
              severity: 'success',
              summary: 'Eliminación de Cartera',
              detail: 'Se eliminó la cartera exitosamente'
            });
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = {
              SALDO: '',
              TRNCOBRO_TIPODOC: '',
              TOTALFORMAPAGO: 0,
              TOTALND: 0,
              TRNCOBRO_IMPORTE: 0,
              TOTALNC: 0,
              TOTALOTROSG: 0,
              TOTALRET: 0,
              TOTALACOBRAR: 0,
              TOTALDOC: 0,
              BAN_BANCO: '',
              BAN_CODIGO: '',
              TRNCOBRO_NROCOMPROBANTE: '',
              TRNCOBRO_SALDOINI: '',
              CON_CODIGO: '',
              TRNCOBRO_NRODOC: '',
              TOTALANTICIPO: '',
              TRNCOBRO_FECHAVENCE: '',
              CLI_NOMBRE: '',
              REALIZADO: '',
              ORIGEN: '',
              VEN_NOMBRE: '',
              APLORG_CODIGO: 'CXC',
              USU_IDENTIFICACION: this.usuario.identificacion,
              TRNCOBRO_CONCEPTO: '',
              BOD_CODIGO: '',
              TRNCOBRO_FECHATRN: new Date(),
              VEN_CODIGO: '',
              CLI_CODIGO: '',
              ASI_NRO: '',
              CABROLADMN_CODIGO: '',
              CABROLVEND_CODIGO: '',
              COM_CODIGO: '01',
              COM_CODIGO_CXP: '01',
              COM_TIPOAMBFACTELEC: '',
              EMP_CODIGO: '',
              EMP_CODIGOVEND: '',
              TRNCOBRO_ANULADO: '',
              TRNCOBRO_CAMBIO: '',
              TRNCOBRO_CUADRE: '',
              TRNCOBRO_EFECTIVO: '',
              TRNCOBRO_FLAG: '1',
              TRNCOBRO_IMPRESO: '',
              TRNCOBRO_LOCALIZACIONXMLRET: '',
              TRNCOBRO_NRODOC_CXP: '',
              TRNCOBRO_NRODOC_OLD: '',
              TRNCOBRO_PATHPDF_FE: '',
              TRNCOBRO_PATHXMLNOAUTO_FE: '',
              TRNCOBRO_PATHXMLRET: '',
              TRNCOBRO_REFERENCIAEXTERNA: '',
              TRNCOBROND_AUTORIZACION_FE: '',
              TRNCOBROND_CLAVEACCESO_FE: '',
              TRNCOBROND_ESTADO_FE: '',
              TRNCOBROND_FECHAAUT_FE: '',
              TRNCOBROND_NUMFACTELEC: ''
            };
            this.booleanBodega = false;
            this.saldoInicial = false;
            this.cxcCarteraService.cxctrnresumenarray[this.indicador] = [];
            this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = [];
            this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = [];
            this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = [];
            this.cxcCarteraService.cxctrnretencionarray[this.indicador] = [];
            this.cxcCarteraService.cxcdebitobancarioarray[this.indicador] = [];
            this.cxcCarteraService.cxctrnvariosarray[this.indicador] = [];
            this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
          });
        if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
          this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((doc) => {
            if (doc.APLORG_CODIGO === 'SISTEMA') {
              this.cxcCarteraService.actualizarPagadoCuota(doc).subscribe(() => {
              });
            }
            if (doc.SELECCIONADO === true && doc.APLORG_CODIGO !== 'SISTEMA') {
              this.cxcCarteraService.actualizarestadoFac(doc).subscribe(() => {
              });
            }
          });
        }
      } catch (error1) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Eliminación de Cartera',
          detail: 'No se pudo eliminar la cartera, error ' + error1.error
        });
      }
    } else {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Eliminación de Cartera',
        detail: 'Seleccione una cartera para ser eliminada'
      });
    }
  }

  async cancelarEdicionCartera() {
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== undefined) {
      const res = await this.cxcCarteraService.getEncCarteraProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
      if (res.length === 0) {
        this.nuevaCartera('C');
      } else {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.botonAnterior = false;
        this.botonPrimero = false;
        this.botonUltimo = false;
        this.botonSiguiente = false;
        this.botonBuscar = false;
        this.botonPago = false;
        this.botonAnular = false;
        this.botonAsiento = false;
        this.botonVerAsiento = false;
        const array: any = [];
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = array;//res[0];
        await this.obtenerDatos(res[0].TRNCOBRO_NRODOC);
      }
      /* this.cxcCarteraService.getEncCartera(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        if (res.length === 0) {
          this.nuevaCartera('C');
        } else {
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.botonAnterior = false;
          this.botonPrimero = false;
          this.botonUltimo = false;
          this.botonSiguiente = false;
          this.botonBuscar = false;
          this.botonPago = false;
          this.botonAnular = false;
          this.botonAsiento = false;
          this.botonVerAsiento = false;
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] = res[0];
          this.obtenerDatos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        }
      }); */
    } else {
      this.nuevaCartera('C');
    }
  }

  confirmarAnularCar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea anular la cartera',
      header: 'Anular Cartera',
      icon: 'pi pi-exclamation-triangle',
      key: 'carteraConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        //this.anularCartera();
        this.finalmenteAnular();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  // anularCartera() {
  //   let errores = 0;

  //   if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
  //     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
  //     this.cxcCarteraService.existePagoDoc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
  //       if (res.length !== 0) {
  //         this.message.add({
  //           key: 'cartera',
  //           severity: 'error',
  //           summary: 'Anular Cartera',
  //           detail: 'No se puede Anular la cartera porque existe un pago'
  //         });
  //         errores++;
  //         return;
  //       }
  //     });
  //   }
  //   if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
  //     this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formap) => {
  //       if (formap.TRNPAGO_TIPO === 'CH') {
  //         this.cxcCarteraService.erTrnbanconcilia(formap.TRNCOBRO_NRODOC, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO).subscribe((exis) => {
  //           if (exis[0].TRNBAN_CONCILIA === 1) {
  //             this.message.add({
  //               key: 'cartera',
  //               severity: 'error',
  //               summary: 'Anular Cartera',
  //               detail: 'No se puede Anular la cartera porque existen documentos conciliados'
  //             });
  //             errores++;
  //             return;
  //           }
  //         });
  //         this.cxcCarteraService.erTrncajdepositonro(formap.TRNPAGO_NUMERO, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO, formap.TRNCOBRO_NRODOC).subscribe((exis2) => {
  //           if (exis2[0].TRNCAJ_DEPOSITONRO !== null) {
  //             this.message.add({
  //               key: 'cartera',
  //               severity: 'error',
  //               summary: 'Anular Cartera',
  //               detail: 'No se puede Anular la cartera porque existen documentos depositados'
  //             });
  //             errores++;
  //             return;
  //           }
  //         });
  //       } else if (formap.TRNPAGO_TIPO === 'EF') {
  //         this.cxcCarteraService.erTrncajdepositonro2(formap.TRNPAGO_NUMERO, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
  //           formap.TRNPAGO_TIPO, formap.CAJ_CODIGO).subscribe((exis) => {
  //             console.log('*-/-/-/', exis)//GSRF
  //             if (exis[0].TRNCAJ_DEPOSITONRO !== null) {//GSRF
  //             //if (exis[0] === null) {
  //               this.message.add({
  //                 key: 'cartera',
  //                 severity: 'error',
  //                 summary: 'Anular Cartera',
  //                 detail: 'No puede anular el cobro, el efectivo ' + formap.TRNPAGO_NUMERO
  //                   + ' está depositado. Nro. Depósito: ' + exis[0].TRNCAJ_DEPOSITONRO
  //               });
  //               errores++;
  //               return;
  //             }
  //           });
  //       } else if (formap.TRNPAGO_TIPO === 'DP') {
  //         this.cxcCarteraService.erTrnbanconcilia(formap.TRNPAGO_NUMERO, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO).subscribe((exis) => {
  //           if (exis.length !== 0) {
  //             if (exis[0].TRNBAN_CONCILIA === 1) {
  //               this.message.add({
  //                 key: 'cartera',
  //                 severity: 'error',
  //                 summary: 'Anular Cartera',
  //                 detail: 'No se puede Anular el pago porque el deposito esta conciliado'
  //               });
  //               errores++;
  //               return;
  //             }

  //           }
  //         });
  //       } else if (formap.TRNPAGO_TIPO === 'TA') {
  //         this.cxcCarteraService.erTrntarjetacodigo(formap.TRNPAGO_NUMERO, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
  //           formap.TRNPAGO_TIPO, formap.CAJ_CODIGO).subscribe((exis) => {
  //             console.log('*/*/*/',exis[0].TRNTARJETA_CODIGO);//GSRF
  //             if (exis[0].TRNTARJETA_CODIGO !== null)  {//GSRF
  //               this.message.add({
  //                 key: 'cartera',
  //                 severity: 'error',
  //                 summary: 'Anular Cartera',
  //                 detail: 'No se puede Anular el pago porque el voucher está conciliado'
  //               });
  //               errores++;
  //               return;
  //             }
  //           });
  //       }
  //     });
  //   }
  //   if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
  //     this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((doc) => {
  //       this.cxcCarteraService.erTrncobrocuadre(doc.TRNCOBRO_NRODOC).subscribe((exis) => {
  //         if (exis[0].TRNCOBRO_CUADRE === 1) {
  //           this.message.add({
  //             key: 'cartera',
  //             severity: 'error',
  //             summary: 'Anular Cartera',
  //             detail: 'No se puede Anular el pago porque el documento está en el cierre de caja'
  //           });
  //           errores++;
  //           return;
  //         }
  //       });
  //     });
  //   }
  //   if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
  //     if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDOANC) !==
  //       Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE)) {
  //       this.message.add({
  //         key: 'cartera',
  //         severity: 'error',
  //         summary: 'Anular Cartera',
  //         detail: 'No se puede Anular el anticipo ya está siendo utilizado'
  //       });
  //       errores++;
  //       return;
  //     }
  //   }

  //   setTimeout(() => {
  //     if (errores === 0) {
  //       this.finalmenteAnular();
  //     }
  //   }, 1500);
  // }
  async anularCartera() {
    let errores = false;
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
        const res= await this.cxcCarteraService.existePagoDoc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).toPromise()
     // this.cxcCarteraService.existePagoDoc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
     console.log('res',res)   
     if (res.length !== 0) {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Anular Cartera',
            detail: 'No se puede Anular la cartera porque existe un pago'
          });
          return errores=true;
          ;
        }
      //});
    }
    if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
      for (const formap of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]){
        if (formap.TRNPAGO_TIPO === 'CH') {
          const exis=await this.cxcCarteraService.erTrnbanconcilia(formap.TRNCOBRO_NRODOC, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO).toPromise()
          //this.cxcCarteraService.erTrnbanconcilia(formap.TRNCOBRO_NRODOC, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO).subscribe((exis) => {
            if(exis !== null && exis !== undefined){
              console.log('exis[0].TRNBAN_CONCILIA',exis[0].TRNBAN_CONCILIA)
          if (Number(exis[0].TRNBAN_CONCILIA) === 1) {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Anular Cartera',
                detail: 'No se puede Anular la cartera porque existen documentos conciliados'
              });
              return errores=true;
              ;
            }
            }
            
          //});
          const exis2=await this.cxcCarteraService.erTrncajdepositonro(formap.TRNPAGO_NUMERO, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO, formap.TRNCOBRO_NRODOC).toPromise()
         // this.cxcCarteraService.erTrncajdepositonro(formap.TRNPAGO_NUMERO, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO, formap.TRNCOBRO_NRODOC).subscribe((exis2) => {
         console.log('exis2[0].TRNCAJ_DEPOSITONRO',exis2[0].TRNCAJ_DEPOSITONRO)   
         if (exis2[0].TRNCAJ_DEPOSITONRO !== null) {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Anular Cartera',
                detail: 'No se puede Anular la cartera porque existen documentos depositados'
              });
              return errores=true;
              ;
            }
          //});
        } else if (formap.TRNPAGO_TIPO === 'EF') {
          const exis= await this.cxcCarteraService.erTrncajdepositonro2(formap.TRNPAGO_NUMERO, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
            formap.TRNPAGO_TIPO, formap.CAJ_CODIGO).toPromise()
         // this.cxcCarteraService.erTrncajdepositonro2(formap.TRNPAGO_NUMERO, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
           // formap.TRNPAGO_TIPO, formap.CAJ_CODIGO).subscribe((exis) => {
             console.log('exis[0].TRNCAJ_DEPOSITONRO',exis[0].TRNCAJ_DEPOSITONRO)
              console.log('*-/-/-/', exis)//GSRF
              if (exis[0].TRNCAJ_DEPOSITONRO !== null) {//GSRF
              //if (exis[0] === null) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Anular Cartera',
                  detail: 'No puede anular el cobro, el efectivo ' + formap.TRNPAGO_NUMERO
                    + ' está depositado. Nro. Depósito: ' + exis[0].TRNCAJ_DEPOSITONRO
                });
                return errores=true;
                ;
              }
           //});
        } else if (formap.TRNPAGO_TIPO === 'DP') {
          const exis=await this.cxcCarteraService.erTrnbanconcilia(formap.TRNPAGO_NUMERO, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO).toPromise()
          //this.cxcCarteraService.erTrnbanconcilia(formap.TRNPAGO_NUMERO, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO).subscribe((exis) => {
            if (exis.length !== 0) {
              if (Number(exis[0].TRNBAN_CONCILIA) === 1) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Anular Cartera',
                  detail: 'No se puede Anular el pago porque el deposito esta conciliado'
                });
                return errores=true;
                ;
              }

            }
         // });
        } else if (formap.TRNPAGO_TIPO === 'TA') {
          const exis=await this.cxcCarteraService.erTrntarjetacodigo(formap.TRNPAGO_NUMERO, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
            formap.TRNPAGO_TIPO, formap.CAJ_CODIGO).toPromise();
          //this.cxcCarteraService.erTrntarjetacodigo(formap.TRNPAGO_NUMERO, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
            //formap.TRNPAGO_TIPO, formap.CAJ_CODIGO).subscribe((exis) => {
              console.log('*/*/*/',exis[0].TRNTARJETA_CODIGO);//GSRF
              if (exis[0].TRNTARJETA_CODIGO !== null)  {//GSRF
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Anular Cartera',
                  detail: 'No se puede Anular el pago porque el voucher está conciliado'
                });
                return errores=true;
                ;
              }
            //});
        }
      }
    }
    if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
      //this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((doc) => {
        //console.log(doc)
        for (const doc of this.cxcCarteraService.cxctrnresumenarray[this.indicador]){
          const exis=await this.cxcCarteraService.erTrncobrocuadre(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).toPromise()
          //this.cxcCarteraService.erTrncobrocuadre(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((exis) => {
          console.log('exis[0].TRNCOBRO_CUADRE',exis[0].TRNCOBRO_CUADRE)  
          if (Number(exis[0].TRNCOBRO_CUADRE) === 1) {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Anular Cartera',
                detail: 'No se puede Anular el pago porque el documento está en el cierre de caja'
              });
              return errores=true;
              ;
            }
         //});
        }
      //});
    }
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDOANC) !==
        Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE)) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Anular Cartera',
          detail: 'No se puede Anular el anticipo ya está siendo utilizado'
        });
        return errores=true;
        ;
      }
    }
    console.log('1',errores)
    return errores;
  // if (errores === 0) {
  //       this.finalmenteAnular();
  //     }
    // setTimeout(() => {
    
    // }, 1500);
  }

  finalmenteAnular() {
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
      this.cxcCarteraService.eliminarCaja(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
        this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
      });
      this.cxcCarteraService.eliminarBanco(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
        this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
      });
    }
    if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
      this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map(async (formap) => {
        if (formap.TRNPAGO_TIPO === 'ANCL') {
          //GSRF
          const eR = await this.cxcCarteraService.erTrnpagorefer(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
              let strNumDocAnt = '';
              if (eR !== undefined) {
                if (eR[0].TRNPAGO_REFER !== null) {
                  strNumDocAnt = eR[0].TRNPAGO_REFER;
                }
              }
          console.log(strNumDocAnt)
          await this.cxcCarteraService.eliminarTrnCobroProm(strNumDocAnt);
          //GSRF
          //await this.cxcCarteraService.eliminarTrnCobroProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
          this.eliminarPagosCuotas();
          // this.cxcCarteraService.eliminarNDATrnResumen(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
          // });
          this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });

        }
      });
    }
    this.cxcCarteraService.anularDocumento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '') {
        this.cxcCarteraService.anularAsientoDoc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO).subscribe(() => {
        });
      }
      // if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
      //   this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((doc) => {
      //     if (doc.SELECCIONADO === true) {
      //       this.cxcCarteraService.actualizarestadoFac(doc).subscribe(() => {
      //       });
      //     }
      //   });
      // }
      this.cxcCarteraService.eliminarDocAnulado(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      this.cxcCarteraService.eliminarDocAnuladoEnc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      this.cxcCarteraService.eliminarPagoAnulado(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      this.cxcCarteraService.eliminarRetencionAnulado(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      //CDPJ
      if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
        this.cxcCarteraService.cxctrnresumenarray[this.indicador].map(async (doc) => {
          if (doc.SELECCIONADO === true) {
            await this.cxcCarteraService.actualizarestadoFac(doc).toPromise().catch(e=>{
              
            })
          }
        });
      }
      //CDPJ
      this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC) + '/' +
        String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE), 'AN',
        '', '01', '', '', '', '').subscribe(() => {
          this.message.add({
            key: 'cartera',
            severity: 'success',
            summary: 'Anular Cartera',
            detail: 'La cartera fue anulada'
          });
          this.booleanAnulado = true;
          this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
            this.cancelarEdicionCartera();
          });
        });
    });
  }

  confirmarAsiento() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea generar el asiento',
      header: 'Asiento Cartera',
      icon: 'pi pi-exclamation-triangle',
      key: 'carteraConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
          this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
            this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
            this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
              '', '01', '', '', '', '').subscribe(() => {
                this.message.add({
                  key: 'cartera',
                  severity: 'success',
                  summary: 'Asiento Contable',
                  detail: 'Se ha generado el asiento contable'
                });
              });
          });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
          if (this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].length !== 0) {
            this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
              this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento contable'
                  });
                });
            });
          } else {
            this.cxcCarteraService.generarAsientoNDBan(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
              this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento contable'
                  });
                });
            });
          }
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
          this.cxcCarteraService.generarAsientoRT(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
            this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
              this.asiento = res[0].ASI_NRO;
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
              this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento contable'
                  });
                });
            });
          });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
          this.cxcCarteraService.generarAsientoCI(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
            this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
              this.asiento = res[0].ASI_NRO;
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
              this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento contable'
                  });
                });
            });
          }, error1 => {
            this.message.add({
              key: 'cartera',
              severity: 'error',
              summary: 'Asiento Contable',
              detail: 'No se ha generado el asiento, error ' + error1.error
            });
          });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
          this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
            this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
              this.asiento = res[0].ASI_NRO;
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
              this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento contable'
                  });
                });
            });
          });
        } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
          this.cxcCarteraService.generarAsientoANC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
            this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
              this.asiento = res[0].ASI_NRO;
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
              this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento contable'
                  });
                });
            });
          });
        }
        ///GSRF
    this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(async(res)  => {
      console.log('----***',res)
      this.asiento = res[0].ASI_NRO;
      if (this.asiento !== null) {
        const det = await this.cxcCarteraService.getDetConencasilProm(this.asiento, 'CXC');
        console.log('----***',det);
        let sum = 0;
        for (const aux of det) {
          sum=aux.DETASI_IMPORTE + sum;
        }
        console.log('----***',Number(sum.toFixed(2)));
        if(Number(sum.toFixed(2)) !== 0){
          document.getElementById("errasiento").style.display = '';
        }else{
          document.getElementById("errasiento").style.display = 'none';
        }
      }else{
        document.getElementById("errasiento").style.display = 'none';
      } 
    });
    //GSRF
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  insertarEleTrnDoc() {
    if (this.btnGuardar === false) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Información',
        detail: 'Primero guarde el documento'
      });
    } else {
      this.abrirClave('firmarDoc');
    }
  }

  abrirClave(accion) {
    this.displayAcciones = false;
    this.accionClave = accion;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
    this.claveDialog = true;
  }

  comprobarClave() {
    this.cxcCarteraService.obtenerClave(this.accionClave).subscribe((clave) => {
      if (this.accionClave === 'firmarDoc') {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.firmarDoc();
          this.claveAuth = '';
        } else if (clave !== this.claveAuth) {
          this.message.add({
            key: 'autorizacion',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      } else if (this.accionClave === 'activarXML') {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.botonXML = false;
          this.botonReenviarCorreo = false;
          this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '/CXC'), 'A',
            '', '01', '', '', '', '').subscribe(() => {
            });
          this.claveAuth = '';
        } else if (clave !== this.claveAuth) {
          this.message.add({
            key: 'autorizacion',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      } else {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.confirmarAnularCar();
          this.claveAuth = '';
        } else if (clave !== this.claveAuth) {
          this.message.add({
            key: 'autorizacion',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      }
    });
  }

  firmarDoc() {
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ND') {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Firmar Documento',
        detail: 'Esta opción solo esta disponible para notas de débito'
      });
      return;
    } else {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'VA') {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Firmar Documento',
          detail: 'El documento ya fue procesado'
        });
        return;
      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'AT' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'NT' || this.booleanAnulado === true) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Firmar Documento',
          detail: 'Documento no puede ser autorizado'
        });
        return;
      } else {
        this.confirmationService.confirm({
          message: 'Esta seguro de firmar el documento',
          header: 'Firmar Documento',
          icon: 'pi pi-exclamation-triangle',
          key: 'carteraConf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.confirmationService.close();
            const strNumDoc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
            const strFecha = this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
            this.cxcCarteraService.firmarDoc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE = 'VA';
              this.botonAnular = false;
              this.message.add({
                key: 'cartera',
                severity: 'success',
                summary: 'Información',
                detail: 'Proceso de firma electrónica iniciado correctamente.'
              });
              const data = await this.utilitariosService.procesoFirmaElectronica(strNumDoc, strFecha);
              if (data !== 'NOAUTORIZADO') {
                this.message.add({
                  key: 'cartera',
                  severity: 'warn',
                  summary: 'Información',
                  detail: data
                });
              }
              this.traerDatosAutorizacion();
            });
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      }
    }
  }

  traerDatosAutorizacion() {
    this.cxcCarteraService.cargarDatosDoc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((resDoc) => {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE = resDoc[0].TRNCOBROND_ESTADO_FE;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_AUTORIZACION_FE = resDoc[0].TRNCOBROND_AUTORIZACION_FE;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_CLAVEACCESO_FE = resDoc[0].TRNCOBROND_CLAVEACCESO_FE;
      this.message.add({
        key: 'cartera',
        severity: 'success',
        summary: 'Firmar Documento',
        detail: 'El documento fue firmado correctamente'
      });
    });
  }

  reenviarCorreo() {
    if (this.booleanAnulado === true) {
      return;
    }
    this.cxcCarteraService.cargarDatosDoc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((resDoc) => {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE = resDoc[0].TRNCOBROND_ESTADO_FE;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_AUTORIZACION_FE = resDoc[0].TRNCOBROND_AUTORIZACION_FE;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_CLAVEACCESO_FE = resDoc[0].TRNCOBROND_CLAVEACCESO_FE;
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'NT') {
        this.cxcCarteraService.reenviarCorreo(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(async () => {
          const data = await this.utilitariosService.reenviarCorreo(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
          this.message.add({
            key: 'cartera',
            severity: 'success',
            summary: 'Información',
            detail: data
          });
          this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + 'CXC_REENVIAR_CORREO'), 'A',
            '', '01', '', '', '', '').subscribe();
        });
      } else {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Error',
          detail: 'Solo puede reenviar el correo si la factura esta notificada'
        });
      }
    });
  }

  abrirFacturasAbonadas() {
    this.codClienteFac = '';
    this.nombreClienteFac = '';
    this.nroFacCancelada = '';
    this.cxcCarteraService.cxctrnfaccanceladasarray[this.indicador] = [];
    this.dialogFacCanceladas = true;
  }

  buscarFacturas() {
    this.cxcCarteraService.getDocCobrados(this.codClienteFac, this.nroFacCancelada).subscribe((facpendientes) => {
      facpendientes.map((facturas) => {
        facturas.TRNCOBRO_FECHATRN = this.datePipe.transform(facturas.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
      });
      this.cxcCarteraService.cxctrnfaccanceladasarray[this.indicador] = facpendientes;
    });
  }

  seleccionFacPendiente(valor) {
    if (valor.object !== undefined) {
      this.facturaPend = valor.object;
      this.codClienteFac = valor.object.CLI_CODIGO;
      this.cxcCarteraService.obtenerClienteNom(this.codClienteFac).subscribe((res) => {
        this.nombreClienteFac = res[0].CLI_NOMBRE;
        this.nroFacCancelada = valor.object.TRNDOCUMENTO_NRODOC;
      });
    }
  }

  seleccionarCartera() {
    this.obtenerDatos(this.facturaPend.TRNCOBRO_NRODOC);
    this.dialogFacCanceladas = false;
  }

  cambio(cambio) {
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador] !== undefined && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO === 'CXC') {
      this.btnGuardar = false;
      this.btnRegresar = false;
      this.btnNuevo = true;
      this.btnNuevoGrid = false;
    }
  }

  nuevoAnexos() {

    this.anexoSeleccionado.DESCTIPOCOMPMODIFICADO = '';
    this.anexoSeleccionado.DESIDSUSTRIB = '';
    this.anexoSeleccionado.DESPERIODOINF = '';
    this.anexoSeleccionado.FECHACONTAB = '';
    this.anexoSeleccionado.FECHAEMISIONCOMP = '';
    this.anexoSeleccionado.IDPROVEEDOR = '';
    this.anexoSeleccionado.IDSUSTRIB = '';
    this.anexoSeleccionado.PERIODOINF = '';

    this.anexoSeleccionado.SECUENCIALCOMP = '';
    this.anexoSeleccionado.SERIECOMP = '';
    this.anexoSeleccionado.COM_CODIGO = '';
    this.anexoSeleccionado.DESCIDCRETRI = '';
    this.anexoSeleccionado.DESCTIPOCOMP = '';
    this.anexoSeleccionado.TRNANEX_AUTDOCMOD = '';
    this.anexoSeleccionado.TRNANEX_AUTORIZACION = '';
    this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA = 0.00;
    this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA = 0.00;
    this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO = 0.00;
    this.anexoSeleccionado.TRNANEX_IDCRETRI = '';
    this.anexoSeleccionado.TRNANEX_MONTOICE = 0.00;
    this.anexoSeleccionado.TRNANEX_MONTOIVA = 0.00;
    this.anexoSeleccionado.TRNANEX_MONTORETIVABIE = 0.00;
    this.anexoSeleccionado.TRNANEX_MONTORETIVASER = 0.00;
    this.anexoSeleccionado.TRNANEX_RETVACIEN = 0.00;
    this.anexoSeleccionado.TRNANEX_SECDOCMOD = '';
    this.anexoSeleccionado.TRNANEX_SERIEDOCMOD = '';
    this.anexoSeleccionado.TRNANEX_TIPCOM = '';
    this.anexoSeleccionado.TRNANEX_TIPCOMMOD = '';
    this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = '';
    this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = '';
    this.anexoSeleccionado.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
  }

  async cargarAnexos() {
    this.nuevoAnexos();
    const res2 = await this.cxcCarteraService.obtenerDatosAnexosProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
    for (const data of res2) {
      this.anexoSeleccionado.DESCTIPOCOMPMODIFICADO = data.DESCTIPOCOMPMODIFICADO === null ? '' : data.DESCTIPOCOMPMODIFICADO;
      this.anexoSeleccionado.DESIDSUSTRIB = data.DESIDSUSTRIB === null ? '' : data.DESIDSUSTRIB;
      this.anexoSeleccionado.DESPERIODOINF = data.DESPERIODOINF === null ? '' : data.DESPERIODOINF;
      this.anexoSeleccionado.FECHACONTAB = data.FECHACONTAB === null ? '' : data.FECHACONTAB;
      this.anexoSeleccionado.FECHAEMISIONCOMP = data.FECHAEMISIONCOMP === null ? '' : data.FECHAEMISIONCOMP;
      this.anexoSeleccionado.IDPROVEEDOR = data.IDPROVEEDOR === null ? '' : data.IDPROVEEDOR;
      this.anexoSeleccionado.IDSUSTRIB = data.IDSUSTRIB === null ? '' : data.IDSUSTRIB;
      this.anexoSeleccionado.PERIODOINF = data.PERIODOINF === null ? '' : data.PERIODOINF;
      this.anexoSeleccionado.SECUENCIALCOMP = data.SECUENCIALCOMP === null ? '' : data.SECUENCIALCOMP;
      this.anexoSeleccionado.SERIECOMP = data.SERIECOMP === null ? '' : data.SERIECOMP;

      const er = await this.cxcCarteraService.erCountTrnDatosAnexProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);

      if (Number(er[0].CONTADOR) === 0) {
        this.anexoSeleccionado.TRNANEX_SERIEDOCMOD = data.SERIEDOCMODIF === null ? '' : data.SERIEDOCMODIF;
        this.anexoSeleccionado.TRNANEX_SECDOCMOD = data.SECUENCIADOCMODIF === null ? '' : data.SECUENCIADOCMODIF;
        this.anexoSeleccionado.TRNANEX_AUTDOCMOD = data.AUTORIZACIONDOCMODIF === null ? '' : data.AUTORIZACIONDOCMODIF;
      }


    }

    const res = await this.cxcCarteraService.getAnexosProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);

    for (const data of res) {
      this.anexoSeleccionado.COM_CODIGO = data.COM_CODIGO;
      this.anexoSeleccionado.DESCIDCRETRI = data.DESCIDCRETRI;
      this.anexoSeleccionado.DESCTIPOCOMP = data.DESCTIPOCOMP;
      this.anexoSeleccionado.TRNANEX_AUTDOCMOD = data.TRNANEX_AUTDOCMOD;
      this.anexoSeleccionado.TRNANEX_AUTORIZACION = data.TRNANEX_AUTORIZACION;
      this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA = data.TRNANEX_BASEIMPGRABADAIVA.toFixed(2);
      this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA = data.TRNANEX_BASEIMPNOOBJETOIVA.toFixed(2);
      this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO = data.TRNANEX_BASEIMPTARCERO.toFixed(2);
      this.anexoSeleccionado.TRNANEX_IDCRETRI = data.TRNANEX_IDCRETRI;
      this.anexoSeleccionado.TRNANEX_MONTOICE = data.TRNANEX_MONTOICE.toFixed(2);
      this.anexoSeleccionado.TRNANEX_MONTOIVA = data.TRNANEX_MONTOIVA.toFixed(2);
      this.anexoSeleccionado.TRNANEX_MONTORETIVABIE = data.TRNANEX_MONTORETIVABIE.toFixed(2);
      this.anexoSeleccionado.TRNANEX_MONTORETIVASER = data.TRNANEX_MONTORETIVASER.toFixed(2);
      this.anexoSeleccionado.TRNANEX_RETVACIEN = data.TRNANEX_RETVACIEN.toFixed(2);
      this.anexoSeleccionado.TRNANEX_SECDOCMOD = data.TRNANEX_SECDOCMOD;
      this.anexoSeleccionado.TRNANEX_SERIEDOCMOD = data.TRNANEX_SERIEDOCMOD;
      this.anexoSeleccionado.TRNANEX_TIPCOM = data.TRNANEX_TIPCOM;
      this.anexoSeleccionado.TRNANEX_TIPCOMMOD = data.TRNANEX_TIPCOMMOD;
      this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = data.TRNANEX_TIPOSRIGRAVADA;
      this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = data.TRNANEX_TIPOSRITARCERO;
      this.anexoSeleccionado.TRNCOBRO_NRODOC = data.TRNCOBRO_NRODOC;
    }
    // console.log(this.anexoSeleccionado);
    await this.pasaTipodocumento();
  }

  cargarAnexosOld() {
    this.nuevoAnexos();
    this.cxcCarteraService.obtenerDatosAnexos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res2) => {
      for (const data of res2) {
        this.anexoSeleccionado.DESCTIPOCOMPMODIFICADO = data.DESCTIPOCOMPMODIFICADO === null ? '' : data.DESCTIPOCOMPMODIFICADO;
        this.anexoSeleccionado.DESIDSUSTRIB = data.DESIDSUSTRIB === null ? '' : data.DESIDSUSTRIB;
        this.anexoSeleccionado.DESPERIODOINF = data.DESPERIODOINF === null ? '' : data.DESPERIODOINF;
        this.anexoSeleccionado.FECHACONTAB = data.FECHACONTAB === null ? '' : data.FECHACONTAB;
        this.anexoSeleccionado.FECHAEMISIONCOMP = data.FECHAEMISIONCOMP === null ? '' : data.FECHAEMISIONCOMP;
        this.anexoSeleccionado.IDPROVEEDOR = data.IDPROVEEDOR === null ? '' : data.IDPROVEEDOR;
        this.anexoSeleccionado.IDSUSTRIB = data.IDSUSTRIB === null ? '' : data.IDSUSTRIB;
        this.anexoSeleccionado.PERIODOINF = data.PERIODOINF === null ? '' : data.PERIODOINF;
        this.anexoSeleccionado.SECUENCIALCOMP = data.SECUENCIALCOMP === null ? '' : data.SECUENCIALCOMP;
        this.anexoSeleccionado.SERIECOMP = data.SERIECOMP === null ? '' : data.SERIECOMP;

        this.cxcCarteraService.erCountTrnDatosAnex(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(er => {
          if (Number(er[0].CONTADOR) === 0) {
            this.anexoSeleccionado.TRNANEX_SERIEDOCMOD = data.SERIEDOCMODIF === null ? '' : data.SERIEDOCMODIF;
            this.anexoSeleccionado.TRNANEX_SECDOCMOD = data.SECUENCIADOCMODIF === null ? '' : data.SECUENCIADOCMODIF;
            this.anexoSeleccionado.TRNANEX_AUTDOCMOD = data.AUTORIZACIONDOCMODIF === null ? '' : data.AUTORIZACIONDOCMODIF;
          }
        });
      }
      this.cxcCarteraService.getAnexos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        for (const data of res) {
          this.anexoSeleccionado.COM_CODIGO = data.COM_CODIGO;
          this.anexoSeleccionado.DESCIDCRETRI = data.DESCIDCRETRI;
          this.anexoSeleccionado.DESCTIPOCOMP = data.DESCTIPOCOMP;
          this.anexoSeleccionado.TRNANEX_AUTDOCMOD = data.TRNANEX_AUTDOCMOD;
          this.anexoSeleccionado.TRNANEX_AUTORIZACION = data.TRNANEX_AUTORIZACION;
          this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA = data.TRNANEX_BASEIMPGRABADAIVA.toFixed(2);
          this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA = data.TRNANEX_BASEIMPNOOBJETOIVA.toFixed(2);
          this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO = data.TRNANEX_BASEIMPTARCERO.toFixed(2);
          this.anexoSeleccionado.TRNANEX_IDCRETRI = data.TRNANEX_IDCRETRI;
          this.anexoSeleccionado.TRNANEX_MONTOICE = data.TRNANEX_MONTOICE.toFixed(2);
          this.anexoSeleccionado.TRNANEX_MONTOIVA = data.TRNANEX_MONTOIVA.toFixed(2);
          this.anexoSeleccionado.TRNANEX_MONTORETIVABIE = data.TRNANEX_MONTORETIVABIE.toFixed(2);
          this.anexoSeleccionado.TRNANEX_MONTORETIVASER = data.TRNANEX_MONTORETIVASER.toFixed(2);
          this.anexoSeleccionado.TRNANEX_RETVACIEN = data.TRNANEX_RETVACIEN.toFixed(2);
          this.anexoSeleccionado.TRNANEX_SECDOCMOD = data.TRNANEX_SECDOCMOD;
          this.anexoSeleccionado.TRNANEX_SERIEDOCMOD = data.TRNANEX_SERIEDOCMOD;
          this.anexoSeleccionado.TRNANEX_TIPCOM = data.TRNANEX_TIPCOM;
          this.anexoSeleccionado.TRNANEX_TIPCOMMOD = data.TRNANEX_TIPCOMMOD;
          this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = data.TRNANEX_TIPOSRIGRAVADA;
          this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = data.TRNANEX_TIPOSRITARCERO;
          this.anexoSeleccionado.TRNCOBRO_NRODOC = data.TRNCOBRO_NRODOC;
        }
        // console.log(this.anexoSeleccionado);
        this.pasaTipodocumentoOld();
      });
    });
  }

  confirmarEliminarFormaPago() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'carteraConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarFormaPago();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  eliminarFormaPago() {
    //this.editpagofr = false;
    if (this.detalleFormaPago !== undefined) {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleFormaPago.TRNCOBRO_NRODOC === '') {
        this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = this.cxcCarteraService.cxctrnformapagoarray[this.indicador].filter((val, j) => j !==
          this.cxcCarteraService.cxctrnformapagoarray[this.indicador].indexOf(this.detalleFormaPago));
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
        this.cxcCarteraService.calcularTotalFormaPago();
      } else {
        this.cxcCarteraService.eliminarFormaPago(this.detalleFormaPago.TRNCOBRO_NRODOC).subscribe(() => {
          if (this.detalleFormaPago.TRNPAGO_TIPO === 'DP') {
            this.cxcCarteraService.eliminarDPBanco(this.detalleFormaPago.TRNPAGO_NUMERO).subscribe(() => {
            });
          }
          this.cxcCarteraService.cxctrnformapagoarray[this.indicador] = this.cxcCarteraService.cxctrnformapagoarray[this.indicador].filter((val, j) => j !==
            this.cxcCarteraService.cxctrnformapagoarray[this.indicador].indexOf(this.detalleFormaPago));
          this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
          this.auditoriagral.registrarAuditoria('CXC_TRNPAGO',
            String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '/' +
              this.detalleFormaPago.TRNPAGO_NUMERO), 'I',
            '', '01', '', '', '', '').subscribe(() => {
            });
          this.cxcCarteraService.calcularTotalFormaPago();
        });
      }
    }
  }

  cancelarFormaPago() {
    this.editpagofr = true;
    if (this.detalleFormaPago.LINEA !== undefined && this.detalleFormaPago.TRNPAGO_TIPO.length === 0) {
      this.cxcCarteraService.cxctrnformapagoarray[this.indicador].splice(
        this.cxcCarteraService.cxctrnformapagoarray[this.indicador].findIndex(det =>
          det.LINEA === this.detalleFormaPago.LINEA && det.TRNPAGO_TIPO === this.detalleFormaPago.TRNPAGO_TIPO), 1);
      if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length > 0) {
        this.detalleFormaPago = this.cxcCarteraService.cxctrnformapagoarray[this.indicador]
        [this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length - 1];
      }
      this.cxcCarteraService.calcularTotalFormaPago();
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
    }
    /*
      if (this.detalleFormaPago.LINEA === 0) {
        this.btnNuevoGrid = false;
        this.btnGuardarGrid = true;
        this.cxcCarteraService.cxctrnformapago = this.cxcCarteraService.cxctrnformapago.filter((val, j) => j !==
          this.cxcCarteraService.cxctrnformapago.indexOf(this.detalleFormaPago));
        this.detalleFormaPago = {};
      } else if (this.detalleFormaPago.LINEA !== 0) {
        this.btnNuevoGrid = false;
        this.btnGuardarGrid = true;
        this.cxcCarteraService.obtenerFormadePago(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
          this.cxcCarteraService.cxctrnformapago = res;
          this.cxcCarteraService.cxctrnformapago.map((detalle) => {
            if (detalle.LINEA === this.detalleFormaPago.LINEA) {
              this.detalleFormaPagoAux
                = detalle;
            }
          });
          this.detalleFormaPago = this.detalleFormaPagoAux;
          this.cxcCarteraService.calcularTotalFormaPago();
        });
      }*/
  }

  confirmarEliminarOtrosG() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'carteraConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarOtrosG();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  eliminarOtrosG() {
    if (this.detalleOtrasFSeleccionada !== undefined) {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleOtrasFSeleccionada.TRNCOBRO_NRODOC === '') {
        this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = this.cxcCarteraService.cxctrnotrosgarray[this.indicador].filter((val, j) => j !==
          this.cxcCarteraService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleOtrasFSeleccionada));
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCarteraOtrosG.objeto);
        this.cxcCarteraService.calcularTotalOtrosG();
      } else {
        this.cxcCarteraService.eliminarCaja(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
          this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
        });
        this.cxcCarteraService.eliminarBanco(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
          this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
        });
        this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = this.cxcCarteraService.cxctrnotrosgarray[this.indicador].filter((val, j) => j !==
          this.cxcCarteraService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleFormaPago));
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCarteraOtrosG.objeto);
        this.cxcCarteraService.calcularTotalOtrosG();
      }
    }
  }

  cancelarOtrosG() {
    if (this.detalleOtrasFSeleccionada.LINEA === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = this.cxcCarteraService.cxctrnotrosgarray[this.indicador].filter((val, j) => j !==
        this.cxcCarteraService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleFormaPago));
      this.detalleFormaPago = {};
    } else if (this.detalleOtrasFSeleccionada.LINEA !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxcCarteraService.obtenerOtrosGastos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.cxcCarteraService.cxctrnotrosgarray[this.indicador] = res;
        this.cxcCarteraService.cxctrnotrosgarray[this.indicador].map((detalle) => {
          if (detalle.LINEA === this.detalleOtrasFSeleccionada.LINEA) {
            this.detalleOtrasFSeleccionadaAux
              = detalle;
          }
        });
        this.detalleOtrasFSeleccionada = this.detalleOtrasFSeleccionadaAux;
        this.cxcCarteraService.calcularTotalOtrosG();
      });
    }
  }

  confirmarEliminarRetencion() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'carteraConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarRetencion();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  eliminarRetencion() {
    if (this.detalleRetencionSeleccionado !== undefined) {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleRetencionSeleccionado.NUEVALINEA === 0) {
        this.cxcCarteraService.cxctrnretencionarray[this.indicador] = this.cxcCarteraService.cxctrnretencionarray[this.indicador].filter((val, j) => j !==
          this.cxcCarteraService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado));
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto);
        this.cxcCarteraService.calcularTotalRetenido(0);
      } else {
        // this.cxcCarteraService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' +
        //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(() => {
        //     this.cxcCarteraService.cxctrnretencionarray[this.indicador] = this.cxcCarteraService.cxctrnretencionarray[this.indicador].filter((val, j) => j !==
        //       this.cxcCarteraService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado));
        //     this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto);
        //     this.cxcCarteraService.calcularTotalRetenido(0);
        //   });
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Cartera Activa',
          detail: 'No es posible eliminar las retenciones guardadas. Elimine y vuelva a ingresar todo el documento.'
        });
        return;
      }
    }
  }

  cancelarRetencion() {
    if (this.detalleRetencionSeleccionado.LINEA === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxcCarteraService.cxctrnretencionarray[this.indicador] = this.cxcCarteraService.cxctrnretencionarray[this.indicador].filter((val, j) => j !==
        this.cxcCarteraService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado));
      this.detalleFormaPago = {};
    } else if (this.detalleRetencionSeleccionado.LINEA !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxcCarteraService.obtenerRetenciones(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.cxcCarteraService.cxctrnretencionarray[this.indicador] = res;
        this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((detalle) => {
          if (detalle.LINEA === this.detalleRetencionSeleccionado.LINEA) {
            this.detalleRetencionSeleccionadoAux
              = detalle;
          }
        });
        this.detalleRetencionSeleccionado = this.detalleRetencionSeleccionadoAux;
        this.cxcCarteraService.calcularTotalRetenido(0);
      });
    }
  }

  confirmarEliminarFormaPagoDev() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'carteraConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarFormaPagoDev();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  eliminarFormaPagoDev() {
    //this.editpagofr = false;
    if (this.detalleFormaPagoDev !== undefined) {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleFormaPagoDev.LINEA === 0) {
        this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].filter((val, j) => j !==
          this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].indexOf(this.detalleFormaPagoDev));
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagodevarray[this.indicador], this.defaultColumnDefCarteraFormaPagoDev.objeto);
        this.cxcCarteraService.calcularTotalFormaPagoDev();
      } else {
        this.cxcCarteraService.eliminarCaja(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
          this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
        });
        this.cxcCarteraService.eliminarBanco(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
          this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
        });
        this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].filter((val, j) => j !==
          this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].indexOf(this.detalleFormaPagoDev));
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagodevarray[this.indicador], this.defaultColumnDefCarteraFormaPagoDev.objeto);
        this.cxcCarteraService.calcularTotalFormaPagoDev();
      }
    }
  }

  cancelarFormaPagoDev() {
    this.editpagofr = true;
    if (this.detalleFormaPagoDev.LINEA === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].filter((val, j) => j !==
        this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].indexOf(this.detalleFormaPagoDev));
      this.detalleFormaPagoDev = {};
    } else if (this.detalleFormaPagoDev.LINEA !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxcCarteraService.obtenerFormadePagoDev(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.cxcCarteraService.cxctrnformapagodevarray[this.indicador] = res;
        this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].map((detalle) => {
          if (detalle.LINEA === this.detalleFormaPagoDev.LINEA) {
            this.detalleFormaPagoDevAux
              = detalle;
          }
        });
        this.detalleFormaPagoDev = this.detalleFormaPagoDevAux;
        this.cxcCarteraService.calcularTotalFormaPagoDev();
      });
    }
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }

  reversarProceso() {
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      return;
    }
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE !== 'AUT' &&
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE !== 'NT') {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea reversar el proceso?',
        header: 'Reversar Proceso',
        icon: 'pi pi-exclamation-triangle',
        key: 'carteraConf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          this.utilitariosService.reversarProcesoFE(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC, '05');
          this.utilitariosService.reversarProcesoFEClave(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_CLAVEACCESO_FE);
          this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO',
            String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + 'CXC_REVERSAR'), 'E',
            '', '01', '', '', '', '').subscribe(() => {
              this.botonXML = true;
              this.botonReenviarCorreo = true;
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE = '';
              this.message.add({
                key: 'carte',
                severity: 'success',
                summary: 'Reversar Proceso',
                detail: 'Reversar proceso de inicio de firma electrónica terminó satisfactoriamente'
              });
            });
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  abrirLog() {
    if (this.booleanAnulado === false) {
      this.cxcCarteraService.obtenerLogFE(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC, '06').subscribe((res) => {
        if (res.length !== 0) {
          if (res[0] !== null) {
            this.logResultado = JSON.stringify(res[0].TRNDOC_RESULTADO);
          }
        }
        this.displayLog = true;
        this.cerrarSideBar();
      });
    } else {
      return;
    }
  }

  cerrarSideBar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  activarBoton() {
    this.abrirClave('activarXML');
  }

  refrescarDatosFE() {
    this.cxcCarteraService.cargarDatosDoc(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((resDoc) => {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE = resDoc[0].TRNCOBROND_ESTADO_FE;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_AUTORIZACION_FE = resDoc[0].TRNCOBROND_AUTORIZACION_FE;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_CLAVEACCESO_FE = resDoc[0].TRNCOBROND_CLAVEACCESO_FE;
      this.message.add({
        key: 'cartera',
        severity: 'success',
        summary: 'Datos Facturación Electrónica',
        detail: 'Se han actualizado los datos de la facturación electrónica'
      });
    });
  }

  calcularTotalVarios(): number {
    let dblTotalVarios = 0;

    for (let fila = 1; fila <= this.cxcCarteraService.cxctrnvariosarray[this.indicador].length; fila++) {
      const item = this.cxcCarteraService.cxctrnvariosarray[this.indicador][fila - 1];
      dblTotalVarios = dblTotalVarios + Number(item.DEBE) - Number(item.HABER);
      item.DEBE = Number(item.DEBE).toFixed(2);
      item.HABER = Number(item.HABER).toFixed(2);
    }

    return Number(dblTotalVarios);
  }

  validacionTiposSri() {
    let state = true;
    let mensaje = '';

    if ((this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO === null ? 0 : this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO) > 0) {
      if (this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO === undefined) {
        this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = '';
      }
      if (this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO.length === 0) {
        state = false;
        mensaje = 'El tipo de SRI es obligatorio para la Base Imp. Tarifa 0%. si este es mayor que cero.';
      }
    } else {
      this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = '';
    }

    if ((this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA === null ? 0 : this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA) > 0) {
      if (this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA === undefined) {
        this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = '';
      }
      if (this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA.length === 0) {
        state = false;
        mensaje = 'El tipo de SRI es obligatorio para la  Base Imp. Gravada de IVA. si este es mayor que cero.';
      }
    } else {
      this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = '';
    }

    if (state === true) {
      this.guardarDatosAnexos();
    } else {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Información',
        detail: mensaje
      });
    }

  }

  verificarTotalImpuestos(): boolean {
    let dblBaseImpTarifa0 = 0;
    let dblBaseImpGrabadaIva = 0;
    let dblMontoIva = 0;
    let dblBaseImpNoObjetoIva = 0;
    let dblMontoIce = 0;
    let dblTotalImporteDoc = 0;
    let dblTotalImpuestos = 0;

    dblBaseImpTarifa0 = Number(this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO);
    dblBaseImpGrabadaIva = Number(this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA);
    dblMontoIva = Number(this.anexoSeleccionado.TRNANEX_MONTOIVA);
    dblBaseImpNoObjetoIva = Number(this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA);
    dblMontoIce = Number(this.anexoSeleccionado.TRNANEX_MONTOICE);

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
      dblTotalImporteDoc = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE);
    } else {
      dblTotalImporteDoc = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC);
    }

    dblTotalImpuestos = Number(dblBaseImpTarifa0) + Number(dblBaseImpGrabadaIva) + Number(dblMontoIva) +
      Number(dblBaseImpNoObjetoIva) + Number(dblMontoIce);

    console.log(dblTotalImporteDoc, '!==', dblTotalImpuestos);
    if (dblTotalImporteDoc !== dblTotalImpuestos) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Información',
        detail: 'El total de los impuestos debe de ser igual al valor del documento $' + dblTotalImporteDoc
      });
      return false;
    }

    return true;

  }

  faltaDatos(): boolean {
    let bolFalta = false;

    // verificar si la autorización tiene 49 dígitos
    if (this.anexoSeleccionado.TRNANEX_AUTORIZACION === null ||
      this.anexoSeleccionado.TRNANEX_AUTORIZACION === undefined) {
      this.anexoSeleccionado.TRNANEX_AUTORIZACION = '';
    }

    if (this.anexoSeleccionado.TRNANEX_AUTORIZACION.length > 49) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'El número de autorización debe tener 49 dígitos.'
      });
      bolFalta = true;
      return bolFalta;
    }

    // verificar si la autorización del documento origen tiene 49 dígitos

    if (this.anexoSeleccionado.TRNANEX_AUTDOCMOD.length > 49) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'El número de autorización del documento modificado debe tener 49 dígitos.'
      });
      bolFalta = true;
      return bolFalta;
    }

    return bolFalta;
  }

  guardarDatosAnexos() {

    if (this.verificarTotalImpuestos() === false) {
      return;
    }

    // VERIFICAR QUE EL NUMERO DE AUTORIZACION SEA DE 10 DIGITOS
    if (this.faltaDatos() === true) {
      return;
    }

    this.cxcCarteraService.guardarDatosAnexos(this.anexoSeleccionado).subscribe(() => {
      this.message.add({
        key: 'cartera',
        severity: 'success',
        summary: 'Datos Anexos',
        detail: 'Se guardaron los anexos correctamente'
      });
    });
    this.cambiarBotones();
  }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'TRNANEX_IDCRETRI': {
        this.abrirSustentoTrib();
        break;
      }
      case 'TRNANEX_AUTORIZACION': {
        element = document.getElementById('TIPOCOMPMODIF');
        break;
      }
      case 'TIPOCOMPMODIF': {
        element = document.getElementById('SERIEDOCMODIF');
        break;
      }
      case 'SERIEDOCMODIF': {
        element = document.getElementById('SECUENCIADOCMODIF');
        break;
      }
      case 'SECUENCIADOCMODIF': {
        element = document.getElementById('AUTORIZACIONDOCMODIF');
        break;
      }
      case 'AUTORIZACIONDOCMODIF': {
        element = document.getElementById('TRNANEX_BASEIMPTARCERO');
        break;
      }
      case 'TRNANEX_BASEIMPTARCERO': {
        this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO = Number(this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO).toFixed(2);
        element = document.getElementById('TRNANEX_TIPOSRITARCERO');
        break;
      }
      case 'TRNANEX_TIPOSRITARCERO': {
        this.buscarReferencia('TRNANEX_TIPOSRITARCERO');
        break;
      }
      case 'TRNANEX_BASEIMPGRABADAIVA': {
        this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA = Number(this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA).toFixed(2);
        element = document.getElementById('TRNANEX_TIPOSRIGRAVADA');
        break;
      }
      case 'TRNANEX_TIPOSRIGRAVADA': {
        this.buscarReferencia('TRNANEX_TIPOSRIGRAVADA');
        break;
      }
      case 'TRNANEX_MONTOIVA': {
        this.anexoSeleccionado.TRNANEX_MONTOIVA = Number(this.anexoSeleccionado.TRNANEX_MONTOIVA).toFixed(2);
        element = document.getElementById('TRNANEX_BASEIMPNOOBJETOIVA');
        break;
      }
      case 'TRNANEX_BASEIMPNOOBJETOIVA': {
        this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA = Number(this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA).toFixed(2);
        element = document.getElementById('TRNANEX_MONTOICE');
        break;
      }
      case 'TRNANEX_MONTOICE': {
        this.anexoSeleccionado.TRNANEX_MONTOICE = Number(this.anexoSeleccionado.TRNANEX_MONTOICE).toFixed(2);
        element = document.getElementById('TRNANEX_MONTORETIVABIE');
        break;
      }
      case 'TRNANEX_MONTORETIVABIE': {
        this.anexoSeleccionado.TRNANEX_MONTORETIVABIE = Number(this.anexoSeleccionado.TRNANEX_MONTORETIVABIE).toFixed(2);
        element = document.getElementById('TRNANEX_MONTORETIVASER');
        break;
      }
      case 'TRNANEX_MONTORETIVASER': {
        this.anexoSeleccionado.TRNANEX_MONTORETIVASER = Number(this.anexoSeleccionado.TRNANEX_MONTORETIVASER).toFixed(2);
        element = document.getElementById('TRNANEX_RETVACIEN');
        break;
      }
      case 'TRNANEX_RETVACIEN': {
        this.anexoSeleccionado.TRNANEX_RETVACIEN = Number(this.anexoSeleccionado.TRNANEX_RETVACIEN).toFixed(2);
        element = document.getElementById('TRNANEX_IDCRETRI');
        break;
      }
      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }

  }

  async pasaTipodocumento() {
    let tipoDoc = '';
    let tipDocNombre = '';

    console.log(this.opcionesTipoDoc);
    console.log(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);

    for (const listadoc of this.opcionesTipoDoc) {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === listadoc.value) {
        // strTipoDoc = listaDoc.value;
        tipoDoc = listadoc.value;
        tipDocNombre = listadoc.label;
      }
    }

    const er = await this.cxcCarteraService.erRefnombrecProm(tipoDoc);

    if (er !== null) {
      if (er[0] !== null) {
        tipoDoc = er[0].NOMBREC;
      }
    }
    if (tipoDoc === '1' || tipoDoc === '4' || tipoDoc === '5') {
      this.anexoSeleccionado.TRNANEX_TIPCOM = tipoDoc;
      this.anexoSeleccionado.DESCTIPOCOMP = tipDocNombre;
      this.boltrnaextipcom = true;
    } else {
      this.anexoSeleccionado.TRNANEX_TIPCOM = '';
      this.anexoSeleccionado.DESCTIPOCOMP = '';
      this.boltrnaextipcom = false;
    }
  }

  pasaTipodocumentoOld() {
    let tipoDoc = '';
    let tipDocNombre = '';

    console.log(this.opcionesTipoDoc);
    console.log(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);

    for (const listadoc of this.opcionesTipoDoc) {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === listadoc.value) {
        // strTipoDoc = listaDoc.value;
        tipoDoc = listadoc.value;
        tipDocNombre = listadoc.label;
      }
    }

    this.cxcCarteraService.erRefnombrec(tipoDoc).subscribe(er => {
      if (er !== null) {
        if (er[0] !== null) {
          tipoDoc = er[0].NOMBREC;
        }
      }
      if (tipoDoc === '1' || tipoDoc === '4' || tipoDoc === '5') {
        this.anexoSeleccionado.TRNANEX_TIPCOM = tipoDoc;
        this.anexoSeleccionado.DESCTIPOCOMP = tipDocNombre;
        this.boltrnaextipcom = true;
      } else {
        this.anexoSeleccionado.TRNANEX_TIPCOM = '';
        this.anexoSeleccionado.DESCTIPOCOMP = '';
        this.boltrnaextipcom = false;
      }
    });
  }

  async changeTabs() {
    if (this.activeIndex === 1) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.cliService.getInfoEstadoCuenta(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
          'cartera');
      }
    }
    if (this.activeIndex === 2) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.cliService.getInfoSaldos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
          'cartera');
      }
    }
    if (this.activeIndex === 3) {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND'
        || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
        this.cargarAnexos();
      }
    }
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }


  async consultarECAnticipo() {
    this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = [];
    this.ecanticipo = [];
    await this.consultarPagosConAnticipos();
    await this.consultarDevolucionesAnt();
    // this.cxcCarteraService.calcularTotalDoc();
    let totalAnticipo = 0;
    this.ecanticipo.map((ant) => {
      totalAnticipo += Number(ant.TRNPAGO_IMPORTE);
    });
    const totalAnt = { TRNPAGO_IMPORTE: totalAnticipo.toFixed(2), TRNCOBRO_NRODOC: '', TRNPAGO_TIPO: '' };
    this.ecanticipo.push(totalAnt);

    this.cxcCarteraService.cxctrnanticiposarray[this.indicador] = this.ecanticipo;
  }

  async consultarPagosConAnticipos() {
    const anticipo = await this.cxcCarteraService.obtenerAnticipos(this.cxcCarteraService.encabezadoCarteraActivaarray
    [this.indicador].TRNCOBRO_NROCOMPROBANTE);
    anticipo.map((ant) => {
      ant.TRNPAGO_FECHA = this.datePipe.transform(ant.TRNPAGO_FECHA, 'dd/MM/yyyy');
      ant.TRNPAGO_TIPO = 'CA/AB';
      ant.TRNPAGO_IMPORTE = Number(ant.TRNPAGO_IMPORTE).toFixed(2);
      this.ecanticipo.push(ant);
    });

  }

  async consultarDevolucionesAnt() {

    let strSql = 'SELECT D.*, C.TRNCOBRO_FECHATRN, C.TRNCOBRO_CONCEPTO FROM CXC_TRNDOCUMENTO D, CXC_TRNCOBRO C ';
    strSql = strSql + 'WHERE TRNDOCUMENTO_NRODOC = \'' + this.cxcCarteraService.encabezadoCarteraActivaarray
    [this.indicador].TRNCOBRO_NRODOC + '\'';
    strSql = strSql + 'AND C.TRNCOBRO_NRODOC = D.TRNCOBRO_NRODOC ';
    strSql = strSql + ' AND C.COM_CODIGO = \'01\' ORDER BY TRNCOBRO_FECHATRN ';

    try {

      const datos = await this.cxcCarteraService.consultarDevolucionesAnt(this.cxcCarteraService.encabezadoCarteraActivaarray
      [this.indicador].TRNCOBRO_NRODOC);

      for (const rs of datos) {
        const item: Cxctrnanticipos = {};

        item.TRNCOBRO_NRODOC = rs.TRNCOBRO_NROCOMPROBANTE;
        item.TRNPAGO_TIPO = 'DAN';
        item.TRNPAGO_FECHA = this.datePipe.transform(rs.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        item.TRNPAGO_CONCEPTO = rs.TRNCOBRO_CONCEPTO;
        item.TRNPAGO_IMPORTE = Number(rs.TRNDOCUMENTO_IMPORTE).toFixed(2);

        this.ecanticipo.push(item);
        // listaEC.add(item);
      }
    } catch (error) {
    }
  }

  async actualizarCuotasPagadas(item) {
    const data = {
      ENCFAC_NUMERO: item.TRNCOBRO_NRODOC,
      CLI_CODIGO: item.CLI_CODIGO
    };
    await this.cxcCarteraService.actPagadoDETCUOTA(data);
  }

  eliminarPagosCuotas() {
    console.log('ELIMINAR CUOTAS');
    console.log(this.cxcCarteraService.cxctrnresumenarray[this.indicador]);
    if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
      this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((item) => {
        console.log(item.STRCUOTAFAC);
        if (item.STRCUOTAFAC === 'C') {
          const data = {
            TRNCOBRO_NRODOC: item.TRNCOBRO_NRODOC,
            CLI_CODIGO: this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO
          };
          this.actualizarCuotasPagadas(data);
          //GSRF
          this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(item.TRNCOBRO_NRODOC +'/'+this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
          //GSRF
        }
      });
    }
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  cerrarfrmcorreos(){
    this.displayDialogfrmcorreos=false;
  }
  async cxc_fnc_verificar_ant_util(strTrnCobro) {
    let intSw = 0;

    let strSentencia = 'SELECT cxc_fnc_verificar_ant_util(\'' + strTrnCobro + '\',\'';
    strSentencia = strSentencia + '01\') AS DATO FROM DUAL';
    try {
      const data = await this.cxcCarteraService.cxc_fnc_verificar_ant_util(strTrnCobro);

      for (const rs of data) {
        intSw = rs.DATO;
      }
    } catch (error) {
      console.log(error);
      this.message.add({
        key: 'cartera',
        severity: 'success',
        summary: 'Error',
        detail: 'No se puede verificar si existe algun anticipo asociado'
      });
    }

    return intSw;
  }

  async existePagoDoc() {
    let bolExiste = false;
    let strCobros = '';

    /*let strSql = 'SELECT TRNCOBRO_NROCOMPROBANTE FROM CXC_TRNDOCUMENTO WHERE TRNDOCUMENTO_NRODOC=\''
      + this.cxcCarteraService.cxctrnresumenarray[this.indicador][0].TRNCOBRO_NRODOC + '\'';
    strSql = strSql + ' AND COM_CODIGO=\'01\'';*/

    try {
      const data = await this.cxcCarteraService.existePagoDocProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);

      for (const rs of data) {
        strCobros = strCobros + rs.TRNCOBRO_NROCOMPROBANTE + '/';
      }

      if (strCobros.length > 0) {
        bolExiste = true;

        this.message.add({
          key: 'cartera',
          severity: 'success',
          summary: 'Información',
          detail: 'El documento no se puede eliminar, porque tiene'
            + ' los siguientes cobros: '
            + strCobros.substring(0, strCobros.length - 1)
        });
      }
    } catch (error) {
      this.message.add({
        key: 'cartera',
        severity: 'success',
        summary: 'Error',
        detail: 'No se puede verificar los cobros del documento '
      });
    }

    return bolExiste;
  }

  async chequeConciliado(strProceso) {
    let bolConc = false;

    if (this.tabFormaPagoDev !== false) {
      for (let fila = 1; fila <= this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].length; fila++) {
        if (this.cxcCarteraService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_TIPO === 'CH') {
          if (await this.documentoConciliado(this.cxcCarteraService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_NUMERO,
            this.cxcCarteraService.cxctrnformapagodevarray[this.indicador][fila - 1].BAN_CODIGO,
            this.cxcCarteraService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_TIPO)) {
            if (strProceso !== 'COLOR') {
              if (String(strProceso).length > 0) {
                this.message.add({
                  key: 'cartera',
                  severity: 'success',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el pago, el cheque '
                    + this.cxcCarteraService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_NUMERO
                    + ' está conciliado.'
                });
              }
              bolConc = true;
              break;
            } else {
              // modeloFPDev.ponerColorDocConc(fila);
            }

          }
        }
      }
    }
    return bolConc;
  }

  async documentoConciliado(strNumDoc, strCodBanco, strTipoDoc) {
    let strConcilia = '';
    let bolConcilia = false;
    try {
      const er = await this.cxcCarteraService.erTrnbanconciliaProm(strNumDoc, strCodBanco, strTipoDoc);
      if (er !== null) {
        if (er[0] !== undefined) {
          strConcilia = er[0].TRNBAN_CONCILIA;
        }
      }

      console.log(strConcilia);

      if (Number(strConcilia) === 1) {
        bolConcilia = true;
      }
    } catch (error) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el cheque está conciliado.'
      });
    }

    console.log(bolConcilia);

    return bolConcilia;

  }

  async chequeDepositadoUtil(strNumCheque, strCodBanco, strDocumento) {
    let strDepositado = '';
    console.log(strNumCheque, strCodBanco, strDocumento);
    try {
      const er = await this.cxcCarteraService.erTrncajdepositonroProm(strNumCheque, strCodBanco, 'CH', strDocumento);
      if (er !== null) {
        if (er[0] !== undefined) {
          strDepositado = er[0].TRNCAJ_DEPOSITONRO === null ? '' : er[0].TRNCAJ_DEPOSITONRO;
        }
      }
    } catch (error1) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el cheque está depositado.'
      });
    }

    return strDepositado;
  }

  async chequeDepositado(strProceso) {
    let bolDep = false;

    if (this.tabFormaPago === true) {
      for (let fila = 1; fila <= this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length; fila++) {
        if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'CH') {
          const strNumDep = await this.chequeDepositadoUtil(
            this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
            this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].BANCLI_CODIGO,
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
          console.log(strNumDep);
          if (strNumDep.length > 0) {
            if (strProceso !== 'COLOR') {
              if (String(strProceso).length > 0) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el cobro, el cheque '
                    + this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
                    + ' está depositado.'
                    + '\r\rNro. Depósito: '
                    + strNumDep
                });
              }
              bolDep = true;
              break;
            } else {
              this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCDEP = true;
              this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);

            }
          }

        }
      }
    }
    return bolDep;
  }

  async efectivoDepositadoUtil(strNumero, strCodCaja, strDocNro) {
    let strDepositado = '';

    console.log(strNumero, strCodCaja, strDocNro);
    try {
      const er = await this.cxcCarteraService.erTrncajdepositonro2Prom(strNumero, strDocNro, 'EF', strCodCaja);

      console.log(er);

      if (er !== null) {
        if (er[0] !== undefined) {
          strDepositado = er[0].TRNCAJ_DEPOSITONRO === null ? '' : er[0].TRNCAJ_DEPOSITONRO;
        }
      }
    } catch (error1) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el efectivo está depositado.'
      });
    }

    return strDepositado;
  }

  async efectivoDepositado(strProceso) {
    let bolDep = false;

    if (this.tabFormaPago === true) {
      for (let fila = 1; fila <= this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length; fila++) {
        if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'EF') {
          const strNumDep = await this.efectivoDepositadoUtil(
            this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
            this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].CAJ_CODIGO,
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
          console.log(strNumDep);
          if (strNumDep.length > 0) {
            if (strProceso !== 'COLOR') {
              if (String(strProceso).length > 0) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el cobro, el efectivo '
                    + this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
                    + ' está depositado.'
                    + '\r\rNro. Depósito: '
                    + strNumDep
                });
              }
              bolDep = true;
              break;
            } else {
              this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCDEP = true;
              this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
            }
          }
        }
      }
    }
    return bolDep;
  }

  async depositoConciliado(strProceso) {
    let bolConc = false;

    if (this.tabFormaPago === true) {
      for (let fila = 1; fila <= this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length; fila++) {
        if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'DP') {
          if (await this.documentoConciliado(this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
            this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].BANCLI_CODIGO,
            this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO)) {
            if (strProceso !== 'COLOR') {
              if (strProceso.length > 0) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el pago, el depósito '
                    + this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
                    + ' está conciliado.'
                });
              }
              bolConc = true;
              break;
            } else {
              this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCCONC = true;
              this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);

            }
          }
        }
      }
    }
    return bolConc;
  }

  async tarjetaConciliadaUtil(strNumero, strCodCaja, strDocNro) {
    let bolConc = false;
    let strCodConc = '';
    try {
      const er = await this.cxcCarteraService.erTrntarjetacodigoProm(strNumero, strDocNro, 'TA', strCodCaja);
      if (er !== null) {
        if (er[0] !== undefined) {
          strCodConc = er[0].TRNTARJETA_CODIGO === null ? '' : er[0].TRNTARJETA_CODIGO;
        }
      }
      if (String(strCodConc).length > 0) {
        bolConc = true;
      }
    } catch (error1) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si la tarjeta está conciliada.'
      });
    }
    return bolConc;
  }

  async tarjetaConciliada(strProceso) {
    let bolConc = false;

    if (this.tabFormaPago === true) {
      for (let fila = 1; fila <= this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length; fila++) {
        if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'TA') {
          if (await this.tarjetaConciliadaUtil(this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
            this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].CAJ_CODIGO,
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE)) {
            if (strProceso !== 'COLOR') {
              if (strProceso.length > 0) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No puede '
                    + strProceso
                    + ' el pago, el voucher '
                    + this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
                    + ' está conciliado.'
                });
              }
              bolConc = true;
              break;
            } else {
              this.cxcCarteraService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCCONC = true;
              this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);

            }
          }
        }
      }
    }
    return bolConc;
  }

  async documentoCierre(strProceso) {
    let bolCierre = false;
    let strCierre: any = '0';

    let strNumDoc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;

    if (Object.keys(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).length > 0) {
      strNumDoc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
    }


    try {
      strCierre = await this.cxcCarteraService.erTrncobrocuadreProm(strNumDoc);

      if (strCierre[0].TRNCOBRO_CUADRE === '1') {
        if (String(strProceso).length > 0) {
          this.message.add({
            key: 'cartera',
            severity: 'info',
            summary: 'Información',
            detail: 'No puede ' + strProceso + ' el pago, el documento está en el cierre de caja.'
          });
        }
        bolCierre = true;
      }
    } catch (err) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede verificar si el documento ya está en el cierre de caja.'
      });
    }

    return bolCierre;
  }

  anticipoUtilizado(strProceso): boolean {
    let bolUtilizado = false;

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDOANC) !==
        Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE)) {
        bolUtilizado = true;

        if (String(strProceso).length > 0) {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Información',
            detail: 'No puede ' + strProceso + ' el anticipo, ya está '
              + 'utilizado.'
          });
        }
      }
    }
    return bolUtilizado;
  }

  async obtenerSaldoANC(strNumFact) {
    const sql = 'SELECT VEN_FNC_SALDOFACTURA(\'01\', \'' + strNumFact + '\') as SALDO FROM DUAL'
    let dblSaldo: any = 0;
    try {
      const rs = await this.cxcCarteraService.obtenerSaldoANC(strNumFact);
      if (rs !== null) {
        dblSaldo = Number(rs[0].SALDO * (-1)).toFixed(2);
      }
    }
    catch (err) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Información',
        detail: 'No se puede obtener el saldo de la factura'
      });
    }
    return dblSaldo;
  }

  async guardarCartera() {
    this.editpagofr = true;
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('-')) {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN =
        this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,
          'dd/MM/yyyy');
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('/')) {
    }
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE.toString().includes('-')) {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
        this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE,
          'dd/MM/yyyy');
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE.toString().includes('/')) {
    }
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
        await this.saveFCND();
        //GSRF
      // setTimeout(() => {
      //   this.cambiarBotones();
      //   this.cancelarEdicionCartera();
      // }, 3000);
      //GSRF
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      await this.saveCAABANC();
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
      await this.saveDAN();
      await this.consulAsiento()
      
      // setTimeout(() => {
      //   this.cambiarBotones();
      //   this.cancelarEdicionCartera();
      // }, 3000);
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
      await this.saveNC();
      // setTimeout(() => {
      //   this.cambiarBotones();
      //   this.cancelarEdicionCartera();
      // }, 3000);
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
      await this.saveRT();
      // this.cambiarBotones();
      // await this.cancelarEdicionCartera();
      /*setTimeout(() => {

        this.cambiarBotones();
        this.cancelarEdicionCartera();
      }, 3000);*/
    }
    // ///GSRF
    // this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(async(res)  => {
    //   console.log('----***',res)
    //   this.asiento = res[0].ASI_NRO;
    //   if (this.asiento !== null) {
    //     const det = await this.cxcCarteraService.getDetConencasilProm(this.asiento, 'CXC');
    //     console.log('----***',det);
    //     let sum = 0;
    //     for (const aux of det) {
    //       sum=aux.DETASI_IMPORTE + sum;
    //     }
    //     console.log('----***',Number(sum.toFixed(2)));
    //     if(Number(sum.toFixed(2)) !== 0){
    //       document.getElementById("errasiento").style.display = '';
    //       setTimeout(() => {
    //       this.message.add({
    //         key: 'cartera',
    //         severity: 'error',
    //         summary: 'Error en el Asiento Contable',
    //         detail: 'Asiento contable descuadrado'
    //       });
    //     }, 2000);
    //     }
    //   }else{
    //     document.getElementById("errasiento").style.display = 'none';
    //     setTimeout(() => {
    //     this.message.add({
    //       key: 'cartera',
    //       severity: 'error',
    //       summary: 'Visualizacion de Asiento Contable',
    //       detail: 'No se genero el asiento contable'
    //     });
    //   }, 2000);
    //   } 
    // });
    //GSRF
    /* setTimeout(() => {
       this.cambiarBotones();
       this.cancelarEdicionCartera();
     }, 3000); */
  }
  async consulAsiento(){
    ///GSRF
    console.log(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC)
    console.log('----1***',this.asiento)
  
    const resp1= await this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).toPromise()//.subscribe(async(res)  => {
      
      for (const res of resp1){
      this.asiento = res.ASI_NRO;
      console.log('----***',this.asiento)
      console.log('----res***',res)
      if (this.asiento !== null) {
        const det = await this.cxcCarteraService.getDetConencasilProm(this.asiento, 'CXC');
        console.log('----***',det);
        let sum = 0;
        for (const aux of det) {
          sum=aux.DETASI_IMPORTE + sum;
        }
        console.log('----***',Number(sum.toFixed(2)));
        if(Number(sum.toFixed(2)) !== 0){
          document.getElementById("errasiento").style.display = '';
          setTimeout(() => {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Error en el Asiento Contable',
            detail: 'Asiento contable descuadrado'
          });
          this.spin=false//CDPJ
        }, 2000);
        }
      }else{
        document.getElementById("errasiento").style.display = 'none';
        setTimeout(() => {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Visualizacion de Asiento Contable',
          detail: 'No se genero el asiento contable'
        });
        this.spin=false//CDPJ
      }, 2000);
      } 
    }//);
    //GSRF
  }
  async saveFCND() {
    let errores=0
    //GSRF
    let err=0
    let sumdebe = 0;
    let sumhaber = 0;
    let totvar = 0;
    let countban = 0;
    console.log('*/*',this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALND);
    console.log('*/*',this.cxcCarteraService.cxcdebitobancarioarray[this.indicador]);
    console.log('*/*',this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE);

    console.log('doc',this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC)
    console.log('saldo',this.saldoInicial)

    if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALND) === Number(0)
    && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND'
    && this.saldoInicial === false) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Inserción de Cartera',
        detail: 'El valor del importe no puede ser cero'
      });
      this.spin=false//CDPJ
      return;
    }
    this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].map((ban)=> {
      if(ban.SELECCIONADO === true){
        countban++;
      }
    })
    this.cxcCarteraService.cxctrnvariosarray[this.indicador].map((vari) => {
      sumdebe = sumdebe + Number(vari.DEBE) ;
      sumhaber = sumhaber +  Number(vari.HABER)
    })
    totvar = sumhaber-sumdebe;
    console.log('VARIOS*/*',totvar);
    console.log('BANCARIA*/*',countban);
   
    
    //GSRF
    await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async(vario) => {
      //GSRF
      //GSRF
      let numdep = await this.cxcCarteraService.getcuentconta(vario.CON_CODIGO);
      console.log('ND',numdep.length);
      if (numdep.length === 0) {
        this.mensajeAlerta('error', 'Cuenta Contable', 'Cuenta contable no existe'); 
        errores++;
        this.spin=false
        return;
      }
    //GSRF
      //GSRF
      if (vario.CON_CODIGO.substr(-1, 1) === '.') {
        console.log('entra2')
        this.mensajeAlerta('error', 'Cuenta Contable', 'No se puede elegir esa cuenta contable en varios');
        vario.CON_CODIGO = '';
        vario.CON_NOMBRE = '';
        this.aggrid.refreshColumnDefs();
        this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
         errores++;
          this.spin=false//CDPJ
          return;
      }
      //GSRF
    })
    )
    if(errores>0){
      return;
    }
    console.log('doc',this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC)
    console.log('saldo',this.saldoInicial)
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE === 0 
      && this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND'
      && this.saldoInicial === false ) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Inserción de Cartera',
        detail: 'El valor del importe no puede ser cero'
      });
      this.spin=false//CDPJ
      return;
    }//GSRF
    else{
      if(countban !== 0){
        console.log('BANCARIA*/*',this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO);
        if(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO === '' ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO === null ||
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO === undefined){
          
            this.message.add({
              key: 'cartera',
              severity: 'error',
              summary: 'Inserción de Cartera',
              detail: 'Ingrese un banco en la pestaña de Débito Bancario'  
            });
            this.spin=false
            return;
          }

      }else {
        if(Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALND) !== Number(totvar) && this.saldoInicial === false ){
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Inserción de Cartera',
            detail: 'El total de Varios debe ser igual al ' +
            'total del documento '
          });
          this.spin=false
          return;
        }
      }
    }
    //GSRF
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
      const dblTotalVarios = this.calcularTotalVarios();
      const dblImporteDoc = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALND);
      const strNombreDoc = 'Nota de Débito';
      console.log('1', dblImporteDoc, dblTotalVarios);
      if (this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].length === 0) {
        if (dblImporteDoc === dblTotalVarios) {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Información',
            detail: 'El total de Varios debe ser igual al ' +
              'total del documento ' + strNombreDoc
          });
          this.spin=false//CDPJ
          return;
        }
      }
    }

    if (this.saldoInicial === true) {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 1;
    } else if (this.saldoInicial === false) {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 0;
    }
    if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO = this.cxcCarteraService.cxctrnvariosarray[this.indicador][0].BAN_CODIGO;
    }
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      const data1 = await this.cxcCarteraService.insertarCobroFCND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).toPromise();
        for (const res of data1){

          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = res.TRNCOBRO_NRODOC;
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = res.TRNCOBRO_NROCOMPROBANTE;
          const data2= await this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).toPromise();
          for (const saldo of data2){
          //this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo.SALDO;

            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(res.TRNCOBRO_NRODOC + '/' + res.TRNCOBRO_NROCOMPROBANTE), 'I',
              '', '01', '', '', '', '').subscribe(async () => {
                this.message.add({
                  key: 'cartera',
                  severity: 'success',
                  summary: 'Inserción de Cartera',
                  detail: 'Se ha insertado el documento exitosamente'
                });
                this.spin=false//CDPJ
                if (this.tabDebitoBancario === true) {
                  await this.cxcCarteraService.generarNDBancario(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
                    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).toPromise()
                  // this.cxcCarteraService.generarNDBancario(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
                  //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe(() => {
                      if (this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].length !== 0) {
                        for (const debito of this.cxcCarteraService.cxcdebitobancarioarray[this.indicador]){
                        //this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].map((debito) => {
                          if (debito.SELECCIONADO === true) {
                            // this.cxcCarteraService.insertarTrnBanDoc(debito, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                            // });
                            await this.cxcCarteraService.insertarTrnBanDoc(debito, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
                          }
                        //});
                        }
                      }
                      const data3 = await this.cxcCarteraService.generarAsientoNDBan(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
                      for (const asiento of data3){
                      // this.cxcCarteraService.generarAsientoNDBan(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[':B1'];
                        await this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[':B1'] + '/CXC'), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                            this.message.add({
                              key: 'cartera',
                              severity: 'success',
                              summary: 'Asiento Contable',
                              detail: 'Se ha generado el asiento contable'
                            });
                            this.spin=false//CDPJ
                          });
                        this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                        }
                      //});
                   // });
                }
                if (this.tabVarios === true) {
                  await this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
                  //this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
                    this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res.TRNCOBRO_NRODOC), 'E',
                      '', '01', '', '', '', '').subscribe(() => {
                      });
                    if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
                      for (const vario of this.cxcCarteraService.cxctrnvariosarray[this.indicador]){
                      //await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async (vario) => {
                        vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                        vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                        vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
                        if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
                          vario.TRNVARIOS_IMPORTE = vario.DEBE;
                          vario.TRNBAN_TIPO = 'DP';
                        } else if (vario.HABER !== '' && Number(vario.HABER) > 0) {
                          vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
                          vario.TRNBAN_TIPO = 'RE';
                        } else {
                          vario.TRNBAN_TIPO = '';
                        }
                        vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
                        await this.cxcCarteraService.insertarVarios(vario).toPromise()
                        //this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
                          vario.TRNVARIOS_LINEA = vario.LINEA;
                          this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res.TRNCOBRO_NRODOC), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                       // });
                       const data4 = await this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
                       for (const asiento of data4){
                        // this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[':B1'];
                          await this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[':B1'] + '/CXC'), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                              this.message.add({
                                key: 'cartera',
                                severity: 'success',
                                summary: 'Asiento Contable',
                                detail: 'Se ha generado el asiento contable'
                              });
                              this.spin=false//CDPJ
                            });
                        //});
                        }
                        //CDPJ 
                        if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
                          const data= await this.cxcCarteraService.insertarMovbancos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).catch(e=>{
                            this.spin=false//CDPJ
                          });
                        }  
                      //CDPJ
                      ///}));
                      }
                    }
                  //});

                }
              });
          }//);
          // this.cambiarBotones();
        }
      // this.cxcCarteraService.insertarCobroFCND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
      //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((res) => {
      //     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = res[0].TRNCOBRO_NRODOC;
      //     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = res[0].TRNCOBRO_NROCOMPROBANTE;
      //     this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
      //       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
      //       this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(res[0].TRNCOBRO_NRODOC + '/' + res[0].TRNCOBRO_NROCOMPROBANTE), 'I',
      //         '', '01', '', '', '', '').subscribe(() => {
      //           this.message.add({
      //             key: 'cartera',
      //             severity: 'success',
      //             summary: 'Inserción de Cartera',
      //             detail: 'Se ha insertado el documento exitosamente'
      //           });
      //           this.spin=false//CDPJ
      //           if (this.tabDebitoBancario === true) {
      //             this.cxcCarteraService.generarNDBancario(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
      //               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe(() => {
      //                 if (this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].length !== 0) {
      //                   this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].map((debito) => {
      //                     if (debito.SELECCIONADO === true) {
      //                       this.cxcCarteraService.insertarTrnBanDoc(debito, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      //                       });
      //                     }
      //                   });
      //                 }
      //                 this.cxcCarteraService.generarAsientoNDBan(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
      //                   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
      //                   this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //                   this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
      //                     '', '01', '', '', '', '').subscribe(() => {
      //                       this.message.add({
      //                         key: 'cartera',
      //                         severity: 'success',
      //                         summary: 'Asiento Contable',
      //                         detail: 'Se ha generado el asiento contable'
      //                       });
      //                       this.spin=false//CDPJ
      //                     });
      //                   this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //                 });
      //               });
      //           }
      //           if (this.tabVarios === true) {
      //             this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
      //               this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'E',
      //                 '', '01', '', '', '', '').subscribe(() => {
      //                 });
      //               if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
      //                 await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async (vario) => {
      //                   vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
      //                   vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
      //                   vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
      //                   if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
      //                     vario.TRNVARIOS_IMPORTE = vario.DEBE;
      //                     vario.TRNBAN_TIPO = 'DP';
      //                   } else if (vario.HABER !== '' && Number(vario.HABER) > 0) {
      //                     vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
      //                     vario.TRNBAN_TIPO = 'RE';
      //                   } else {
      //                     vario.TRNBAN_TIPO = '';
      //                   }
      //                   vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
      //                   this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
      //                     vario.TRNVARIOS_LINEA = vario.LINEA;
      //                     this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'I',
      //                       '', '01', '', '', '', '').subscribe(() => {
      //                       });
      //                   });
      //                   this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
      //                     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
      //                     this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //                     this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
      //                       '', '01', '', '', '', '').subscribe(() => {
      //                         this.message.add({
      //                           key: 'cartera',
      //                           severity: 'success',
      //                           summary: 'Asiento Contable',
      //                           detail: 'Se ha generado el asiento contable'
      //                         });
      //                         this.spin=false//CDPJ
      //                       });
      //                   });
      //                   //CDPJ 
      //                   if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
      //                     const data= await this.cxcCarteraService.insertarMovbancos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).catch(e=>{
      //                       this.spin=false//CDPJ
      //                     });
      //                   }  
      //                 //CDPJ
      //                 }));
      //               }
      //             });
      //           }
      //         });
      //     });
      //     // this.cambiarBotones();
      //   });
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
      if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO =
          this.cxcCarteraService.cxctrnvariosarray[this.indicador][0].BAN_CODIGO;
      }
      const data1= await this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).toPromise()
        for (const res of data1 ){
          const data2=await this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).toPromise();
          for (const saldo of data2){

          
          //this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {

            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo.SALDO;
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(res.TRNCOBRO_NRODOC + '/' + res.TRNCOBRO_NROCOMPROBANTE), 'A',
              '', '01', '', '', '', '').subscribe(async () => {
                this.message.add({
                  key: 'cartera',
                  severity: 'success',
                  summary: 'Inserción de Cartera',
                  detail: 'Se ha actualizado el documento exitosamente'
                });
                this.spin=false//CDPJ
                if (this.tabDebitoBancario === true) {
                  await this.cxcCarteraService.generarNDBancario(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
                    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).toPromise();
                  // this.cxcCarteraService.generarNDBancario(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
                  //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe(() => {
                      if (this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].length !== 0) {
                        for(const debito of this.cxcCarteraService.cxcdebitobancarioarray[this.indicador]){
                        //this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].map((debito) => {
                          if (debito.SELECCIONADO === true) {
                            await  this.cxcCarteraService.insertarTrnBanDoc(debito, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
                            // this.cxcCarteraService.insertarTrnBanDoc(debito, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                            // });

                          }
                        }
                        //});
                      }
                      const data3 = await this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
                      for (const asiento of data3){
                      //this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[':B1'];
                        this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[':B1'] + '/CXC'), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                            this.message.add({
                              key: 'cartera',
                              severity: 'success',
                              summary: 'Asiento Contable',
                              detail: 'Se ha generado el asiento contable'
                            });
                            this.spin=false//CDPJ
                          });
                        this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                     // });
                        }
                   // });
                }
                if (this.tabVarios === true) {
                   await this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
                  
                  //this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
                    this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'E',
                      '', '01', '', '', '', '').subscribe(() => {
                      });
                    if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
                      for (const vario of this.cxcCarteraService.cxctrnvariosarray[this.indicador]){
                      //await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async (vario) => {
                        vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                        vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                        vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
                        if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
                          vario.TRNVARIOS_IMPORTE = vario.DEBE;
                          vario.TRNBAN_TIPO = 'DP';
                        } else if (vario.HABER !== '' && Number(vario.HABER) > 0) {
                          vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
                          vario.TRNBAN_TIPO = 'RE';
                        } else {
                          vario.TRNBAN_TIPO = '';
                        }
                        vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
                        await this.cxcCarteraService.insertarVarios(vario).toPromise()
                        //this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
                          vario.TRNVARIOS_LINEA = vario.LINEA;
                          this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                       // });
                       const data4= await this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
                        for (const asiento of data4){
                       //this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[':B1'];
                          this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[':B1'] + '/CXC'), 'I',
                            '', '01', '', '', '', '').subscribe(() => {
                              this.message.add({
                                key: 'cartera',
                                severity: 'success',
                                summary: 'Asiento Contable',
                                detail: 'Se ha generado el asiento contable'
                              });
                              this.spin=false//CDPJ
                            });
                          this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                        //});
                          }
                        //CDPJ 
                        if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
                          const data= await this.cxcCarteraService.insertarMovbancos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).catch(e=>{
                            this.spin=false//CDPJ
                          });
                        }  
                      //CDPJ
                      }
                      //}));

                    }
                  //});

                }
              });
         // });
        }
          // this.cambiarBotones();
          // this.consultarPestañas();
        }
      // this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
      //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((res) => {

      //     this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
      //       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
      //       this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(res[0].TRNCOBRO_NRODOC + '/' + res[0].TRNCOBRO_NROCOMPROBANTE), 'A',
      //         '', '01', '', '', '', '').subscribe(() => {
      //           this.message.add({
      //             key: 'cartera',
      //             severity: 'success',
      //             summary: 'Inserción de Cartera',
      //             detail: 'Se ha actualizado el documento exitosamente'
      //           });
      //           this.spin=false//CDPJ
      //           if (this.tabDebitoBancario === true) {
      //             this.cxcCarteraService.generarNDBancario(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
      //               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe(() => {
      //                 if (this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].length !== 0) {
      //                   this.cxcCarteraService.cxcdebitobancarioarray[this.indicador].map((debito) => {
      //                     if (debito.SELECCIONADO === true) {
      //                       this.cxcCarteraService.insertarTrnBanDoc(debito, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      //                       });
      //                     }
      //                   });
      //                 }
      //                 this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
      //                   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
      //                   this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //                   this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
      //                     '', '01', '', '', '', '').subscribe(() => {
      //                       this.message.add({
      //                         key: 'cartera',
      //                         severity: 'success',
      //                         summary: 'Asiento Contable',
      //                         detail: 'Se ha generado el asiento contable'
      //                       });
      //                       this.spin=false//CDPJ
      //                     });
      //                   this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //                 });
      //               });
      //           }
      //           if (this.tabVarios === true) {
      //             this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
      //               this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'E',
      //                 '', '01', '', '', '', '').subscribe(() => {
      //                 });
      //               if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
      //                 await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async (vario) => {
      //                   vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
      //                   vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
      //                   vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
      //                   if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
      //                     vario.TRNVARIOS_IMPORTE = vario.DEBE;
      //                     vario.TRNBAN_TIPO = 'DP';
      //                   } else if (vario.HABER !== '' && Number(vario.HABER) > 0) {
      //                     vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
      //                     vario.TRNBAN_TIPO = 'RE';
      //                   } else {
      //                     vario.TRNBAN_TIPO = '';
      //                   }
      //                   vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
      //                   this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
      //                     vario.TRNVARIOS_LINEA = vario.LINEA;
      //                     this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'I',
      //                       '', '01', '', '', '', '').subscribe(() => {
      //                       });
      //                   });
      //                   this.cxcCarteraService.generarAsientoND(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
      //                     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
      //                     this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //                     this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
      //                       '', '01', '', '', '', '').subscribe(() => {
      //                         this.message.add({
      //                           key: 'cartera',
      //                           severity: 'success',
      //                           summary: 'Asiento Contable',
      //                           detail: 'Se ha generado el asiento contable'
      //                         });
      //                         this.spin=false//CDPJ
      //                       });
      //                     this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //                   });
      //                   //CDPJ 
      //                   if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
      //                     const data= await this.cxcCarteraService.insertarMovbancos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).catch(e=>{
      //                       this.spin=false//CDPJ
      //                     });
      //                   }  
      //                 //CDPJ
      //                 }));
      //               }
      //             });
      //           }
      //         });
      //     });
      //     // this.cambiarBotones();
      //     // this.consultarPestañas();
      //   });
    }
    setTimeout(() => {
      this.cambiarBotones();
      this.cancelarEdicionCartera();
    }, 3000);
  }

  async saveCAABANC() {
    console.log('saveCAABANC');
    //GSRF
    for (const item of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]) {
      console.log('-->',item.CAJ_CODIGO)
      let caja = await this.cxcCarteraService.getcaja(item.CAJ_CODIGO)
      let numdep = await this.cxcCarteraService.getDP(item.TRNPAGO_NUMERO,item.BANCLI_CODIGO);
      console.log('-->',item.TRNPAGO_TIPO)
      console.log('-->',caja.length)
      console.log('-->',numdep.length)
      if(item.TRNPAGO_TIPO !=='DP' && item.TRNPAGO_TIPO === 'EF'){
      if(caja.length === 0 ){
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'Caja no existe'
        });
        this.spin=false//CDPJ
        return;
      }
    }

    if(item.TRNPAGO_TIPO ==='DP'){
      if (item.BANCLI_CODIGO === null || item.BANCLI_CODIGO === '' || item.BANCLI_CODIGO === undefined ){
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'Ingrese Banco'
        });
        this.spin=false//CDPJ
        return;
      }
      if (item.TRNPAGO_NUMERO === null || item.TRNPAGO_NUMERO === '' || item.TRNPAGO_NUMERO === undefined ){
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'Ingrese Número'
        });
        this.spin=false//CDPJ
        return;
      }
      if(numdep.length !== 0 ){
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'El número ya existe'
        });
        this.spin=false//CDPJ
        return;
      }
    }
      };

      if(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC'){
        if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length === 0 && this.saldoInicial === false) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'Ingrese forma de pago'
        });
        this.spin=false//CDPJ
        return;
      } 
      }
     
      //GSRF
    if ((Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) !==
      Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO)) && this.saldoInicial === false) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Inserción de Cartera',
        detail: 'El total de la forma de pago es distinto al total a cobrar'
      });
      this.spin=false//CDPJ
      return;
    }
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE === 0) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'El valor del importe no puede ser cero'
        });
        this.spin=false//CDPJ
        return;
      }
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
      if (this.saldoInicial === false) {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 0;
      } else if (this.saldoInicial === true) {
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 1;
      }
      console.log('1');
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
        console.log('2');
        let cobro;
        try {
          cobro = await this.cxcCarteraService.insertarCobroP(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC)

          console.log('1 Inserta cobro');
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;

          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NRODOC;
          }

          const saldo = await this.cxcCarteraService.obtenerSaldoClienteP(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
          console.log('2 Obtiene saldo cliente');

          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
          this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
            '', '01', '', '', '', '').subscribe(() => { });
          this.message.add({
            key: 'cartera',
            severity: 'success',
            summary: 'Inserción de Cartera',
            detail: 'Se ha insertado el documento exitosamente'
          });
          this.spin=false//CDPJ
        } catch (err) {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Error',
            detail: err.error
          });
          this.spin=false//CDPJ
        }

        if (this.saldoInicial === false) {
          if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
            for (const formap of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]) {
              formap.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
                formap.TRNPAGO_CONCEPTO = '*';
              }

              try {
                await this.cxcCarteraService.insertarFormadePagoProm(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
                this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                console.log('3 Inserta forma de pago');
              } catch (err) {
                this.message.add({
                  key: 'cartera',
                  severity: 'error',
                  summary: 'Forma de Pago',
                  detail: 'No se guardo la forma de pago, error ' + err.error
                });
                this.spin=false//CDPJ
              }
            }

            try {
              await this.cxcCarteraService.generarAsientoANCProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]);
              console.log('4 Genera el asiento');
              const res = await this.cxcCarteraService.getAsientoProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
              console.log('5 Obtiene el asiento');
              this.asiento = res[0].ASI_NRO;
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
              this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento contable'
                  });
                  this.spin=false//CDPJ
                });
              this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);

            } catch (err) {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Asiento Contable',
                detail: 'No se ha generado el asiento contable' + err.error
              });
              this.spin=false//CDPJ
            }

          }

        }
        // this.cambiarBotones();
        // this.consultarPestañas();
        this.cambiarBotones();
        await this.cancelarEdicionCartera();
        if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROCMPRAUT').CFG_VALOR1 === 1) {
          this.cxcCarteraService.actualizarNumeracion(this.numSerie).subscribe((res) => {
            console.log(res);
          });
        }

      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
        console.log('3');
        if (this.anticipoUtilizado('actualizar')) {
          this.spin=false;//CDPJ
          return;
        }
        const cobro = await this.cxcCarteraService.actualizarCobroProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
        const saldo = await this.cxcCarteraService.obtenerSaldoClienteP(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
        await this.cxcCarteraService.eliminarTrnDocumentoProm('TRNCOBRO_NRODOC = \'' +
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'');
        if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
          for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
            if (resumen.SELECCIONADO === true) {
              resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              resumen.STRCUOTAFAC = 'F';
              if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                resumen.STRCUOTAFAC = 'C';
              }
              await this.cxcCarteraService.insertarDocPorCobrarP(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
              if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                await this.actualizarCuotasPagadas(resumen);
              }
              this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                });
            }
          }

        }

        if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
          await this.cxcCarteraService.eliminarFormaPagoProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
          for (const formap of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]) {
            //if (formap.TRNPAGO_TIPO === 'DP') {
            //  await this.cxcCarteraService.eliminarDPBancoProm(formap.TRNPAGO_NUMERO);
            //}
            formap.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
              formap.TRNPAGO_CONCEPTO = '*';
            }

            try {
              await this.cxcCarteraService.insertarFormadePagoProm(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
              this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                });
            } catch (err) {
              this.message.add({
                key: 'cartera',
                severity: 'error',
                summary: 'Forma de Pago',
                detail: 'No se guardo la forma de pago, error ' + err.error
              });
              this.spin=false//CDPJ
            }
          }
        }

        this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
          '', '01', '', '', '', '').subscribe(() => {
            this.message.add({
              key: 'cartera',
              severity: 'success',
              summary: 'Inserción de Cartera',
              detail: 'Se ha insertado el documento exitosamente'
            });
            this.spin=false//CDPJ
          });

        try {
          await this.cxcCarteraService.generarAsientoANCProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]);
          const res = await this.cxcCarteraService.getAsientoProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
          this.asiento = res[0].ASI_NRO;
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
          this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
            '', '01', '', '', '', '').subscribe(() => {
              this.message.add({
                key: 'cartera',
                severity: 'success',
                summary: 'Asiento Contable',
                detail: 'Se ha generado el asiento contable'
              });
              this.spin=false//CDPJ
            });
          this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
        } catch (err) {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Asiento Contable',
            detail: 'No se ha generado el asiento contable ' + err.error
          });
          this.spin=false//CDPJ
        }

        this.cambiarBotones();
        this.cancelarEdicionCartera();


      }
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
      console.log('3');
      let numeroDoc = '';
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
      if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
        console.log('4');
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE =
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC;
        let cobro;
        try {
          cobro = await this.cxcCarteraService.insertarCobroP(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
          console.log('1 Insertar Cobro');
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
            console.log(cobro[0]);
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NRODOC;
          }

          const saldo = await this.cxcCarteraService.obtenerSaldoClienteP(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
          console.log('2 Obtiene saldo cliente');
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;

          if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
            for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
              if (resumen.SELECCIONADO === true) {
                resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                numeroDoc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                resumen.STRCUOTAFAC = 'F';
                if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                  resumen.STRCUOTAFAC = 'C';
                }
                await this.cxcCarteraService.insertarDocPorCobrarP(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
                console.log('3 insertarDocPorCobrar');
                if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                  await this.actualizarCuotasPagadas(resumen);
                  console.log('4 actualizarCuotasPagadas');
                }
                this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
              }
            }
          }

          this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
            '', '01', '', '', '', '').subscribe(() => {
              this.message.add({
                key: 'cartera',
                severity: 'success',
                summary: 'Inserción de Cartera',
                detail: 'Se ha insertado el documento exitosamente'
              });
              this.spin=false//CDPJ
            });

          if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
            for (const ret of this.cxcCarteraService.cxctrnretencionarray[this.indicador]) {
              ret.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              if (ret.CON_CODIGO === '') {
                ret.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
              }
              ret.CEN_CODIGO = '';
              ret.ENCFAC_NUMERO = this.numeroFactura;
              await this.cxcCarteraService.insertarRetencionP(ret, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
              console.log('5 insertarRetencion');
              if (this.retencionElect === true) {
                await this.cxcCarteraService.actualizarTipoRetencionProm(ret, 'E');
                console.log('6 insertarRetencion');
              } else if (this.retencionElect === false) {
                await this.cxcCarteraService.actualizarTipoRetencionProm(ret, 'F');
                console.log('6 insertarRetencion');
              }
            }
          }

          if (this.cxcCarteraService.cxctrnotrosgarray[this.indicador].length !== 0) {
            for (const com of this.cxcCarteraService.cxctrnotrosgarray[this.indicador]) {
              if (com.CON_CODIGO === '' || com.CON_CODIGO === null) {
                com.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
              }

              await this.cxcCarteraService.insertarComisionProm(com, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
              console.log('7 insertarComision');

              com.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
              this.auditoriagral.registrarAuditoria('SACI_TRNCOMISION', String(com.TRNCOMISION_NRO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                });
            }
          }


        } catch (err) {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Error',
            detail: err.error
          });
          this.spin=false//CDPJ
        }

        try {


          if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
            await this.cxcCarteraService.eliminarFormaPagoProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
            console.log('8 eliminarFormaPago');
            for (const formap of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]) {
              //if (formap.TRNPAGO_TIPO === 'DP') {
              //  await this.cxcCarteraService.eliminarDPBancoProm(formap.TRNPAGO_NUMERO);
              //  console.log('9 eliminarDPBanco');
              //}
              formap.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
                formap.TRNPAGO_CONCEPTO = '*';
              }

              await this.cxcCarteraService.insertarFormadePagoProm(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
              console.log('10 insertarFormadePago');
              console.log(formap.TRNPAGO_TIPO);
              if (formap.TRNPAGO_TIPO === 'ANCL') {
                const itemND: Cxctrncobro = {};
                itemND.TRNCOBRO_NRODOC = '';
                itemND.TRNCOBRO_NROCOMPROBANTE = '';
                itemND.TRNCOBRO_FECHATRN = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
                itemND.TRNCOBRO_CONCEPTO = 'NOTA DE DEBITO POR EL ANTICIPO ' + formap.TRNPAGO_NUMERO;
                itemND.TRNCOBRO_IMPORTE = formap.TRNPAGO_IMPORTE;
                itemND.TRNCOBRO_FECHAVENCE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE === null ? null :
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE;
                itemND.APLORG_CODIGO = 'SISTEMA';
                itemND.TRNCOBRO_REFERENCIAEXTERNA = null;
                itemND.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                itemND.VEN_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO;
                itemND.ASI_NRO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO;
                itemND.USU_IDENTIFICACION = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION;
                itemND.TRNCOBRO_FLAG = '1';
                itemND.BAN_CODIGO = null;
                itemND.TRNCOBRO_EFECTIVO = '0.0';
                itemND.TRNCOBRO_CAMBIO = '0.0';
                itemND.CON_CODIGO = null;
                itemND.TRNCOBRO_SALDOINI = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI;
                itemND.BOD_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO;

                console.log(itemND);
                const data = await this.cxcCarteraService.insertarCobroP(itemND, 'NDA');
                console.log('11 insertarCobro NDA');
                console.log(data);
                let strNumDocAnt = '';
                let strTrnCobroNroDoc = '';
                let strTrnCobroNroComprobante = '';

                const eR = await this.cxcCarteraService.erTrncobronrodocProm(formap.TRNPAGO_NUMERO);
                console.log(eR);
                console.log('12 encontrar TRNCOBRO_NRODOC');
                if (eR !== undefined) {
                  if (eR[0].TRNCOBRO_NRODOC !== null) {
                    strNumDocAnt = eR[0].TRNCOBRO_NRODOC;
                  }
                }

                for (const rs of data) {
                  strTrnCobroNroDoc = rs.TRNCOBRO_NRODOC;
                  strTrnCobroNroComprobante = rs.TRNCOBRO_NROCOMPROBANTE;
                }
                // actualizar la referencia de la nota de débito con el número del anticipo strTrnCobroNroDoc
                await this.cxcCarteraService.actualizarNotaDebitoAnticipoProm(strTrnCobroNroDoc, strNumDocAnt);
                console.log('12 actualizarNotaDebitoAnticipo');
                await this.cxcCarteraService.actualizarFormaPagoNDProm(strTrnCobroNroDoc,
                  formap.TRNPAGO_NUMERO, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
                console.log('12 actualizarFormaPagoND');
              }
              this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                });
            }
          }

        } catch (err) {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Forma de Pago',
            detail: 'No se guardo la forma de pago, error ' + err.error
          });
          this.spin=false//CDPJ
        }


        try {
          await this.cxcCarteraService.generarAsientoCIProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]);
          console.log('12 generarAsientoCI');
          const res = await this.cxcCarteraService.getAsientoProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
          console.log('12 getAsiento', res);
          this.asiento = res[0].ASI_NRO;
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
          if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
            for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
              console.log(resumen);
              if (resumen.SELECCIONADO === true) {
                resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                await this.cxcCarteraService.actualizarestadoFacProm(resumen);
                console.log('actualizarestadoFac');
              }
            }
          }
          this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
          this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
            '', '01', '', '', '', '').subscribe(() => {
              this.message.add({
                key: 'cartera',
                severity: 'success',
                summary: 'Asiento Contable',
                detail: 'Se ha generado el asiento contable'
              });
              this.spin=false//CDPJ
            });

        } catch (err) {
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Asiento Contable',
            detail: 'No se ha generado el asiento contable, error' + err.error
          });
          this.spin=false//CDPJ
        }

        this.cambiarBotones();
        await this.cancelarEdicionCartera();

        if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROCMPRAUT').CFG_VALOR1 === 1) {
          this.cxcCarteraService.actualizarNumeracion(this.numSerie).subscribe((res) => {
            console.log(res);
          });
        }

      } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Cartera Activa',
          detail: 'No es posible actualizar el documento. Elimine y vuelva a ingresar.'
        });
        this.spin=false//CDPJ
        return;

        this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
            this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
              this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
                  if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                    this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                      if (resumen.SELECCIONADO === true) {
                        numeroDoc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                        resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                        resumen.STRCUOTAFAC = 'F';
                        if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                          resumen.STRCUOTAFAC = 'C';
                        }
                        this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                              this.actualizarCuotasPagadas(resumen);
                            }
                            this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                              });
                          });
                      }
                    });
                  }
                  if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
                    this.cxcCarteraService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' +
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC +
                      '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(() => {
                        this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((ret) => {
                          ret.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                          if (ret.CON_CODIGO === '') {
                            ret.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                          }
                          ret.CEN_CODIGO = '';
                          ret.ENCFAC_NUMERO = this.numeroFactura;
                          this.cxcCarteraService.insertarRetencion(ret, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                              if (this.retencionElect === true) {
                                this.cxcCarteraService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
                                });
                              } else if (this.retencionElect === false) {
                                this.cxcCarteraService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
                                });
                              }
                            });
                        });
                      });
                  }
                  if (this.cxcCarteraService.cxctrnotrosgarray[this.indicador].length !== 0) {
                    this.cxcCarteraService.eliminarTrnComision(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                      this.cxcCarteraService.cxctrnotrosgarray[this.indicador].map((com) => {
                        if (com.CON_CODIGO === '' || com.CON_CODIGO === null) {
                          com.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                        }
                        this.cxcCarteraService.insertarComision(com, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                            com.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                            this.auditoriagral.registrarAuditoria('SACI_TRNCOMISION', String(com.TRNCOMISION_NRO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                              });
                          });
                      });
                    });
                  }
                  if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length !== 0) {
                    this.cxcCarteraService.eliminarFormaPago(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                      this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formap) => {
                        if (formap.TRNPAGO_TIPO === 'DP') {
                          this.cxcCarteraService.eliminarDPBanco(formap.TRNPAGO_NUMERO).subscribe(() => {
                          });
                        }
                        formap.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                        if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
                          formap.TRNPAGO_CONCEPTO = '*';
                        }
                        this.cxcCarteraService.insertarFormadePago(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(async () => {
                            if (formap.TRNPAGO_TIPO === 'ANCL') {
                              const itemND: Cxctrncobro = {};
                              itemND.TRNCOBRO_NRODOC = '';
                              itemND.TRNCOBRO_NROCOMPROBANTE = '';
                              itemND.TRNCOBRO_FECHATRN = this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
                              itemND.TRNCOBRO_CONCEPTO = 'NOTA DE DEBITO POR EL ANTICIPO ' + formap.TRNPAGO_NUMERO;
                              itemND.TRNCOBRO_IMPORTE = formap.TRNPAGO_IMPORTE;
                              itemND.TRNCOBRO_FECHAVENCE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE === null ? null :
                                this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
                              itemND.APLORG_CODIGO = 'SISTEMA';
                              itemND.TRNCOBRO_REFERENCIAEXTERNA = null;
                              itemND.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                              itemND.VEN_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO;
                              itemND.ASI_NRO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO;
                              itemND.USU_IDENTIFICACION = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION;
                              itemND.TRNCOBRO_FLAG = '1';
                              itemND.BAN_CODIGO = null;
                              itemND.TRNCOBRO_EFECTIVO = '0.0';
                              itemND.TRNCOBRO_CAMBIO = '0.0';
                              itemND.CON_CODIGO = null;
                              itemND.TRNCOBRO_SALDOINI = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI;
                              itemND.BOD_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO;

                              let strNumDocAnt = '';

                              const eRf = await this.cxcCarteraService.erTrnpagorefer(formap.TRNCOBRO_NRODOC);
                              if (eRf !== null) {
                                for (const rs of eRf) {
                                  strNumDocAnt = rs.TRNPAGO_REFER;
                                }
                              }

                              console.log(strNumDocAnt);
                              let data: any;
                              if (strNumDocAnt === '') {
                                let strTrnCobroNroDoc = '';
                                let strTrnCobroNroComprobante = '';
                                data = await this.cxcCarteraService.insertarCobroP(itemND, 'NDA');
                                console.log('11 insertarCobro NDA');
                                console.log(data);
                                const eR = await this.cxcCarteraService.erTrncobronrodocProm(formap.TRNPAGO_NUMERO);
                                console.log(eR);
                                console.log('12 encontrar TRNCOBRO_NRODOC');
                                if (eR !== undefined) {
                                  if (eR[0].TRNCOBRO_NRODOC !== null) {
                                    strNumDocAnt = eR[0].TRNCOBRO_NRODOC;
                                  }
                                }
                                for (const rs of data) {
                                  strTrnCobroNroDoc = rs.TRNCOBRO_NRODOC;
                                  strTrnCobroNroComprobante = rs.TRNCOBRO_NROCOMPROBANTE;
                                }
                                // actualizar la referencia de la nota de débito con el número del anticipo strTrnCobroNroDoc
                                await this.cxcCarteraService.actualizarNotaDebitoAnticipoProm(strTrnCobroNroDoc, strNumDocAnt);
                                console.log('12 actualizarNotaDebitoAnticipo');
                                await this.cxcCarteraService.actualizarFormaPagoNDProm(strTrnCobroNroDoc,
                                  formap.TRNPAGO_NUMERO, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
                                console.log('12 actualizarFormaPagoND');
                              } else {
                                data = await this.cxcCarteraService.actualizarCobroProm(itemND, 'NDA');
                                console.log('11 actualizarCobro NDA');
                              }
                            }
                            this.cxcCarteraService.generarAsientoCI(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                              this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                                this.asiento = res[0].ASI_NRO;
                                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                                if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                                    if (resumen.SELECCIONADO === true) {
                                      resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                                      this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                                      }, error1 => {
                                        console.log(error1.error);
                                      });
                                    }
                                  });
                                }
                                this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                                  '', '01', '', '', '', '').subscribe(() => {
                                  });
                              });
                            }, () => {
                            });
                            this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
                              '', '01', '', '', '', '').subscribe(() => {
                              });
                          }, error1 => {
                            this.message.add({
                              key: 'cartera',
                              severity: 'error',
                              summary: 'Forma de Pago',
                              detail: 'No se guardo la forma de pago, error ' + error1.error
                            });
                          });
                      });
                    });
                  }
                  this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
                    '', '01', '', '', '', '').subscribe(() => {
                      this.message.add({
                        key: 'cartera',
                        severity: 'success',
                        summary: 'Inserción de Cartera',
                        detail: 'Se ha insertado el documento exitosamente'
                      });
                    });
                  this.cambiarBotones();
                  this.cancelarEdicionCartera();
                  setTimeout(() => {
                    this.cxcCarteraService.generarAsientoCI(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                      this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                        this.asiento = res[0].ASI_NRO;
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                        if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                          this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                            if (resumen.SELECCIONADO === true) {
                              resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                              this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                              }, error1 => {
                                console.log(error1.error);
                              });
                            }
                          });
                        }
                        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                            this.message.add({
                              key: 'cartera',
                              severity: 'success',
                              summary: 'Asiento Contable',
                              detail: 'Se ha generado el asiento contable'
                            });
                          });
                      });
                    }, error1 => {
                      this.message.add({
                        key: 'cartera',
                        severity: 'error',
                        summary: 'Asiento Contable',
                        detail: 'No se ha generado el asiento, error ' + error1.error
                      });
                    });
                  }, 500);
                });
            });
            // this.cambiarBotones();
            // this.consultarPestañas();
          });
      }
    }
  }

  async saveDAN() {
    //GSRF
    for (const item of  this.cxcCarteraService.cxctrnformapagodevarray[this.indicador]) {
      console.log('-->',item.CAJ_CODIGO)
      let caja = await this.cxcCarteraService.getcaja(item.CAJ_CODIGO)
      //let numdep = await this.cxcCarteraService.getDP(item.TRNPAGO_NUMERO,item.BAN_CODIGO);
      console.log('-->',item.TRNCAJ_TIPO)
      console.log('-->',caja.length)
      //console.log('-->',numdep.length)
      if(item.TRNCAJ_TIPO !=='DP' && item.TRNCAJ_TIPO === 'EF'){
      if(caja.length === 0 ){
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'Caja no existe'
        });
        this.spin=false//CDPJ
        return;
      }
    }

    if(item.TRNCAJ_TIPO ==='CH'){
      console.log('------)', item.TRNCAJ_NUMERO)
      const numpago = await this.cxcCarteraService.ernumcheque(item.BAN_CODIGO,item.TRNCAJ_TIPO,item.TRNCAJ_NUMERO);
      console.log(numpago)
      console.log(numpago[0])
      if (item.BAN_CODIGO === null || item.BAN_CODIGO === '' || item.BAN_CODIGO === undefined ){
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'Ingrese Banco'
        });
        this.spin=false//CDPJ
        return;
      }
      if(numpago[0].NUMERO>0 
        && (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === ''
        || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === null
        || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === undefined
        )
        ){
          this.message.add({
            key: 'cartera',
            severity: 'error',
            summary: 'Error',
            detail: 'El numero '+item.TRNCAJ_NUMERO+' ya existe en el movimiento de bancos.'
          });
          this.spin=false//CDPJ
          return;
        }
      if (item.TRNCAJ_NUMERO === null || item.TRNCAJ_NUMERO === '' || item.TRNCAJ_NUMERO === undefined ){
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'Ingrese Número'
        });
        this.spin=false//CDPJ
        return;
      }
      
    }
      };

      if(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC'){
        if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length === 0) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Inserción de Cartera',
          detail: 'Ingrese forma de pago'
        });
        this.spin=false//CDPJ
        return;
      } 
      }
     
      //GSRF34
    if (Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) !== Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO)) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Inserción de Cartera',
        detail: 'El total de la forma de pago es distinto al total a cobrar'
      });
      this.spin=false//CDPJ
      return;
    }
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
    this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resu) => {
      if (resu.SELECCIONADO === true) {
        //// GSTR
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE =
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC;
      }
    });
    //CDPJ
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      // this.cxcCarteraService.insertarCobroDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
      //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).pipe(finalize(async() => {await  this.generarAsientoDAN()})).subscribe(async (cobro) => {
          
          const res=await this.cxcCarteraService.insertarCobroDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).toPromise()
            for (const cobro of res){
              
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro.TRNCOBRO_NRODOC;
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro.TRNCOBRO_NROCOMPROBANTE;
          
          //this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
            const resp1= await this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).toPromise()
            for (const saldo of resp1 ){  
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo.SALDO;
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro.TRNCOBRO_NRODOC + '/' + cobro.TRNCOBRO_NROCOMPROBANTE), 'I',
              '', '01', '', '', '', '').subscribe(() => {
                this.message.add({
                  key: 'cartera',
                  severity: 'success',
                  summary: 'Inserción de Cartera',
                  detail: 'Se ha insertado el documento exitosamente'
                });
                this.spin=false//CDPJ
              });
            if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
              await Promise.all(this.cxcCarteraService.cxctrnresumenarray[this.indicador].map(async (resumen) => {
                if (resumen.SELECCIONADO === true) {
                  resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                  resumen.STRCUOTAFAC = 'F';
                  if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                    resumen.STRCUOTAFAC = 'C';
                  }
                  // this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                  //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                      await this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).toPromise()
                      if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                        this.actualizarCuotasPagadas(resumen);
                      }
                      this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro.TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                        '', '01', '', '', '', '').subscribe(() => {
                        });
                    //});
                }
              }));
            }
            if (this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].length !== 0) {
              await Promise.all(this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].map(async (formap) => {
                if (formap.TRNCAJ_TIPO === 'EF') {
                  //GSRF
                  if(formap.TRNCAJ_DESCRIPCION === ''){
                    formap.TRNCAJ_DESCRIPCION = '*';
                  }
                  //GSRF
                  // this.cxcCarteraService.insertarCaja(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                  //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
                      await this.cxcCarteraService.insertarCaja(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).toPromise()
                      this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.TRNCAJ_NUMERO + '/' + cobro.TRNCOBRO_NRODOC), 'I',
                        '', '01', '', '', '', '').subscribe(() => {
                        });
                   // });
                } else {
                  if (formap.CON_CODIGO === '') {
                    formap.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                  }
                  // this.cxcCarteraService.insertarBanco(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                  //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
                  //     this.cxcCarteraService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).subscribe(() => {
                    await this.cxcCarteraService.insertarBanco(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                         this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).toPromise()
                    await this.cxcCarteraService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).toPromise()
                        this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.TRNCAJ_NUMERO + '/' + cobro.TRNCOBRO_NRODOC), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                          });
                     // });
                    //});
                }
                // this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                //   this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                //     this.asiento = res[0].ASI_NRO;
                //     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                //     this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                //   });
                // });
              }));
              // this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
              //   this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
              //     this.asiento = res[0].ASI_NRO;
              //     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
              //     this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              //     this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
              //       '', '01', '', '', '', '').subscribe(() => {
              //         this.message.add({
              //           key: 'cartera',
              //           severity: 'success',
              //           summary: 'Asiento Contable',
              //           detail: 'Se ha generado el asiento contable'
              //         });
              //       });
              //     this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              //   });
              // });
            }
            await this.generarAsientoDAN()
          }//);
          // this.cambiarBotones();
          // this.consultarPestañas();
           // setTimeout(() => {
      //   this.cambiarBotones();
      //   this.cancelarEdicionCartera();
      // }, 3000);
         // await this.generarAsientoDAN()
        }//);
        
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
      // this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
      //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
          const resp=await this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).toPromise()
            for (const cobro of resp ){

          // this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro.TRNCOBRO_NRODOC;
          // this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
           
            const resp1= await this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).toPromise()
            for (const saldo of resp1 ){
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo.SALDO;
            // this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
            //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
            //   });
              await this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').toPromise()
            
              if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                await Promise.all(this.cxcCarteraService.cxctrnresumenarray[this.indicador].map(async (resumen) => {
                if (resumen.SELECCIONADO === true) {
                  resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                  resumen.STRCUOTAFAC = 'F';
                  if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                    resumen.STRCUOTAFAC = 'C';
                  }
                  // this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                  //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                    await this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).toPromise()
                      if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                        this.actualizarCuotasPagadas(resumen);
                      }
                      this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro.TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'A',
                        '', '01', '', '', '', '').subscribe(() => {
                        });
                    //});
                }
              }));
            }
            if (this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].length !== 0) {
              //this.cxcCarteraService.eliminarCaja(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                await this.cxcCarteraService.eliminarCaja(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).toPromise()
                //this.cxcCarteraService.eliminarBanco(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
                await this.cxcCarteraService.eliminarBanco(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).toPromise()
                await Promise.all( this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].map(async (formap) => {
                    if (formap.TRNCAJ_TIPO === 'EF') {
                      //this.cxcCarteraService.insertarCaja(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        //this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
                        await this.cxcCarteraService.insertarCaja(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).toPromise()
                        this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.TRNCAJ_NUMERO + '/' + cobro.TRNCOBRO_NRODOC), 'A',
                            '', '01', '', '', '', '').subscribe(() => {
                            });
                        //});
                    } else {
                      if (formap.CON_CODIGO === '') {
                        formap.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                      }
                      // this.cxcCarteraService.insertarBanco(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
                          // this.cxcCarteraService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).subscribe(() => {
                        await this.cxcCarteraService.insertarBanco(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                             this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).toPromise()
                        await this.cxcCarteraService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).toPromise()
                            this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.TRNCAJ_NUMERO + '/' + cobro.TRNCOBRO_NRODOC), 'A',
                              '', '01', '', '', '', '').subscribe(() => {
                              });
                          //});
                        //});
                    }
                  }));
                  // this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
                  //   this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                  //     this.asiento = res[0].ASI_NRO;
                  //     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                  //     this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                  //   });
                  // });
                  // this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
                  //   '', '01', '', '', '', '').subscribe(() => {
                  //   });
               // });
                // this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
                //   '', '01', '', '', '', '').subscribe(() => {
                //   });
              //});
            }
            // this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro.TRNCOBRO_NRODOC + '/' + cobro.TRNCOBRO_NROCOMPROBANTE), 'A',
            //   '', '01', '', '', '', '').subscribe(() => {
            //     this.message.add({
            //       key: 'cartera',
            //       severity: 'success',
            //       summary: 'Inserción de Cartera',
            //       detail: 'Se ha insertado el documento exitosamente'
            //     });
            //   });
              await this.generarAsientoDAN()
          }//);
          // this.cambiarBotones();
          // this.consultarPestañas();
          
        }//);
    }
    //CDPJ
    // if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
    //   this.cxcCarteraService.insertarCobroDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
    //     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
    //       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
    //       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NROCOMPROBANTE;
    //       this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
    //         this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
    //         this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
    //           '', '01', '', '', '', '').subscribe(() => {
    //             this.message.add({
    //               key: 'cartera',
    //               severity: 'success',
    //               summary: 'Inserción de Cartera',
    //               detail: 'Se ha insertado el documento exitosamente'
    //             });
    //           });
    //         if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
    //           this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
    //             if (resumen.SELECCIONADO === true) {
    //               resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
    //               resumen.STRCUOTAFAC = 'F';
    //               if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
    //                 resumen.STRCUOTAFAC = 'C';
    //               }
    //               this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
    //                 this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
    //                   if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
    //                     this.actualizarCuotasPagadas(resumen);
    //                   }
    //                   this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
    //                     '', '01', '', '', '', '').subscribe(() => {
    //                     });
    //                 });
    //             }
    //           });
    //         }
    //         if (this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].length !== 0) {
    //           this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].map((formap) => {
    //             if (formap.TRNCAJ_TIPO === 'EF') {
    //               //GSRF
    //               if(formap.TRNCAJ_DESCRIPCION === ''){
    //                 formap.TRNCAJ_DESCRIPCION = '*';
    //               }
    //               //GSRF
    //               this.cxcCarteraService.insertarCaja(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
    //                 this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
    //                   this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
    //                     '', '01', '', '', '', '').subscribe(() => {
    //                     });
    //                 });
    //             } else {
    //               if (formap.CON_CODIGO === '') {
    //                 formap.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
    //               }
    //               this.cxcCarteraService.insertarBanco(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
    //                 this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
    //                   this.cxcCarteraService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).subscribe(() => {
    //                     this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
    //                       '', '01', '', '', '', '').subscribe(() => {
    //                       });
    //                   });
    //                 });
    //             }
    //             this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
    //               this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
    //                 this.asiento = res[0].ASI_NRO;
    //                 this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
    //                 this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
    //               });
    //             });
    //           });
    //           this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
    //             this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
    //               this.asiento = res[0].ASI_NRO;
    //               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
    //               this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
    //               this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
    //                 '', '01', '', '', '', '').subscribe(() => {
    //                   this.message.add({
    //                     key: 'cartera',
    //                     severity: 'success',
    //                     summary: 'Asiento Contable',
    //                     detail: 'Se ha generado el asiento contable'
    //                   });
    //                 });
    //               this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
    //             });
    //           });
    //         }
    //       });
    //       // this.cambiarBotones();
    //       // this.consultarPestañas();
    //     });
    // } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
    //   this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
    //     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
    //       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
    //       this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
    //         this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
    //         this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
    //           this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
    //           });
    //         if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
    //           this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
    //             if (resumen.SELECCIONADO === true) {
    //               resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
    //               resumen.STRCUOTAFAC = 'F';
    //               if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
    //                 resumen.STRCUOTAFAC = 'C';
    //               }
    //               this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
    //                 this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
    //                   if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
    //                     this.actualizarCuotasPagadas(resumen);
    //                   }
    //                   this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'A',
    //                     '', '01', '', '', '', '').subscribe(() => {
    //                     });
    //                 });
    //             }
    //           });
    //         }
    //         if (this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].length !== 0) {
    //           this.cxcCarteraService.eliminarCaja(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
    //             this.cxcCarteraService.eliminarBanco(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
    //               this.cxcCarteraService.cxctrnformapagodevarray[this.indicador].map((formap) => {
    //                 if (formap.TRNCAJ_TIPO === 'EF') {
    //                   this.cxcCarteraService.insertarCaja(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
    //                     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
    //                       this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'A',
    //                         '', '01', '', '', '', '').subscribe(() => {
    //                         });
    //                     });
    //                 } else {
    //                   if (formap.CON_CODIGO === '') {
    //                     formap.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
    //                   }
    //                   this.cxcCarteraService.insertarBanco(formap, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
    //                     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
    //                       this.cxcCarteraService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).subscribe(() => {
    //                         this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'A',
    //                           '', '01', '', '', '', '').subscribe(() => {
    //                           });
    //                       });
    //                     });
    //                 }
    //               });
    //               this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
    //                 this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
    //                   this.asiento = res[0].ASI_NRO;
    //                   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
    //                   this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
    //                 });
    //               });
    //               this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
    //                 '', '01', '', '', '', '').subscribe(() => {
    //                 });
    //             });
    //             this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
    //               '', '01', '', '', '', '').subscribe(() => {
    //               });
    //           });
    //         }
    //         this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
    //           '', '01', '', '', '', '').subscribe(() => {
    //             this.message.add({
    //               key: 'cartera',
    //               severity: 'success',
    //               summary: 'Inserción de Cartera',
    //               detail: 'Se ha insertado el documento exitosamente'
    //             });
    //           });
    //         this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
    //           this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
    //             this.asiento = res[0].ASI_NRO;
    //             this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
    //             this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
    //             this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
    //               String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'),
    //               'I', '', '01', '', '', '', '').subscribe(() => {
    //                 this.message.add({
    //                   key: 'cartera',
    //                   severity: 'success',
    //                   summary: 'Asiento Contable',
    //                   detail: 'Se ha generado el asiento contable'
    //                 });
    //               });
    //             this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
    //           });
    //         });
    //       });
    //       // this.cambiarBotones();
    //       // this.consultarPestañas();
    //     });
    // }
  }
//CDPJ
async generarAsientoDAN(){
  // this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).pipe(finalize(async() => {await  this.getAsientoDAN()})).subscribe(() => {
    
  // });
  await this.cxcCarteraService.generarAsientoDAN(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
await this.getAsientoDAN()
}
async getAsientoDAN(){
  //this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
    const resp = await  this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).toPromise()
    for (const res of resp){
    this.asiento = res.ASI_NRO;
    console.log('antes asiento',this.asiento)
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
    this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
    this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
      String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'),
      'I', '', '01', '', '', '', '').subscribe(() => {
        this.message.add({
          key: 'cartera',
          severity: 'success',
          summary: 'Asiento Contable',
          detail: 'Se ha generado el asiento contable'
        });
        this.spin=false//CDPJ
      });
    this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
    await  this.cambiarBotones();
    await this.cancelarEdicionCartera();
  }//);
}

//CDPJ
async saveNC() {
  let errores=0
  const dblTotalVarios = Number(this.calcularTotalVarios())*(-1);
  const dblImporteDoc = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC) * (-1);
  const strNombreDoc = 'Nota de Crédito';
  console.log('2', Number(Number(dblImporteDoc).toFixed(2)), Number(Number(dblTotalVarios).toFixed(2)));
  if (Number(Number(dblImporteDoc).toFixed(2)) !== Number(Number(dblTotalVarios).toFixed(2))) {//CDPJ ANTES dblImporteDoc == dblTotalVarios
    this.message.add({
      key: 'cartera',
      severity: 'error',
      summary: 'Información',
      detail: 'El total de Varios debe ser igual al ' +
        'total del documento ' + strNombreDoc
    });
    this.spin=false//CDPJ
    return;
  }
//CDPJ
if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCCAVNC').CFG_VALOR1 === 1 && this.saldoInicial === false ){
this.cxcCarteraService.cxctrnvariosarray[this.indicador].map((vario) => {
  vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;
  if(vario.CON_CENTRO ==='Si'){
    if(vario.CEN_CODIGO === ''){
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Información',
        detail: 'Ingrese el centro de costos de la cuenta contable ' + vario.CON_CODIGO
         
      });
      errores++;
      this.spin=false//CDPJ
      return;
    }
  }
  //GSRF
  if (vario.CON_CODIGO.substr(-1, 1) === '.') {
    console.log('entra2')
    this.mensajeAlerta('error', 'Cuenta Contable', 'No se puede elegir esa cuenta contable en varios');
    vario.CON_CODIGO = '';
    vario.CON_NOMBRE = '';
    this.aggrid.refreshColumnDefs();
    this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
     errores++;
      this.spin=false//CDPJ
      return;
  }
  //GSRF
})
}
await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async(vario) => {
//GSRF
if (vario.CON_CODIGO.substr(-1, 1) === '.') {
  console.log('entra2')
  this.mensajeAlerta('error', 'Cuenta Contable', 'No se puede elegir esa cuenta contable en varios');
  vario.CON_CODIGO = '';
  vario.CON_NOMBRE = '';
  this.aggrid.refreshColumnDefs();
  this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
   errores++;
    this.spin=false//CDPJ
    return;
}
//GSRF
//GSRF
let numdep = await this.cxcCarteraService.getcuentconta(vario.CON_CODIGO);
console.log('NC',numdep.length);
if (numdep.length === 0) {
  this.mensajeAlerta('error', 'Cuenta Contable', 'Cuenta contable no existe'); 
  errores++;
  this.spin=false
  return;
}
//GSRF
})
)
//CDPJ
  const exis = await this.cxcCarteraService.verificarComprobanteUnico('N',
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, '');
  console.log(exis);
  if (exis[0][':B1'] !== '0' && exis[0][':B1'] !== null && exis[0][':B1'] !== undefined) {
    this.message.add({
      key: 'cartera',
      severity: 'error',
      summary: 'Inserción de Cartera',
      detail: 'El numero de comprobante ya existe'
    });
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
    document.getElementById('numeroCartera').focus();
    this.spin=false//CDPJ
    return;
  }

  if (this.saldoInicial === false && errores === 0) {
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 0;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
        this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
          if (resumen.SELECCIONADO === true) {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE += Number(resumen.TRNCOBRO_IMPORTE);
          }
        });
      }

      //console.log('insertarCobro--->',this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC);
      const data1 = await this.cxcCarteraService.insertarCobroP(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC)
        for( const cobro of data1){    
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro.TRNCOBRO_NRODOC;
          if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro.TRNCOBRO_NROCOMPROBANTE;
          }
          const data2 = await this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).toPromise()
          for (const saldo of data2){
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo.SALDO;
            if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {

              //this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]){
                  if (resumen.SELECCIONADO === true) {
                    resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                    resumen.TRNCOBRO_REFERENCIA = 'F';
                    resumen.STRCUOTAFAC = 'F';
                    if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                      resumen.STRCUOTAFAC = 'C';
                    }
                    await this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).toPromise()//subscribe(() => {

                        if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                          this.actualizarCuotasPagadas(resumen);
                        }
                        this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro.TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                          });
                      //});
                  }
                }            
              //});
            }
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro.TRNCOBRO_NRODOC + '/' + cobro.TRNCOBRO_NROCOMPROBANTE), 'I',
              '', '01', '', '', '', '').subscribe(() => {
                this.message.add({
                  key: 'cartera',
                  severity: 'success',
                  summary: 'Inserción de Cartera',
                  detail: 'Se ha insertado el documento exitosamente'
                });
                this.spin=false//CDPJ
              });
          }
          // this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {              
          // });
          if (this.tabVarios === true) {
            await this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
            //this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
              this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(cobro.TRNCOBRO_NRODOC), 'E',
                '', '01', '', '', '', '').subscribe(() => {
                });
              if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {

                //await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async (vario) => {
                  for (const vario of this.cxcCarteraService.cxctrnvariosarray[this.indicador] ){
                  vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                  vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                  vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
                  if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
                    vario.TRNVARIOS_IMPORTE = vario.DEBE;
                  }
                  if (vario.HABER !== '' && Number(vario.HABER) > 0) {
                    vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
                  }
                  vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj

                  await this.cxcCarteraService.insertarVarios(vario).toPromise()//subscribe(() => {
                    vario.TRNVARIOS_LINEA = vario.LINEA;
                    this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(vario.TRNCOBRO_NRODOC), 'I',
                      '', '01', '', '', '', '').subscribe(() => {
                      });
                    //});

                    //this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
                     
                  //CDPJ 
                  if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
                    console.log(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV'))
                    let fecha = this.obtenerFechaDocString();
                    const data= await this.cxcCarteraService.insertarMovbancos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,fecha,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO);
                  }  
                //CDPJ
                }
               // }));
               const data3 = await this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
               for (const asi of data3){
               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[':B1'];
               await this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
               if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                 ///this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                   for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]){
                   if (resumen.SELECCIONADO === true) {
                     resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                     await this.cxcCarteraService.actualizarestadoFac(resumen).toPromise()
                     // this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                     // }, error1 => {
                     //   console.log(error1.error);
                     // });
                   }
                 }
                 //});
               }
               await this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
               this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[':B1'] + '/CXC'), 'I',
                 '', '01', '', '', '', '').subscribe(() => {
                   
                 });
               await this.cambiarBotones();
               await this.cancelarEdicionCartera();
             //});
             this.message.add({
              key: 'cartera',
              severity: 'success',
              summary: 'Asiento Contable',
              detail: 'Se ha generado el asiento contable'
            });
            this.spin=false//CDPJ
               }
              }
           // });

          } else {

            //this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
              const data4 = await this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
              for (const asi of data4){
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[':B1'];
              await this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                //this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                  for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]){
                  if (resumen.SELECCIONADO === true) {
                    resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                    this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                    }, error1 => {
                      console.log(error1.error);
                    });
                  }
                }
                //});
              }
              await this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[':B1'] + '/CXC'), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento contable'
                  });
                  this.spin=false//CDPJ
                });
              }
            //});

          }
          // this.cambiarBotones();
          // this.consultarPestañas();
        }

      // this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
      //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {

      //     this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
      //     if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
      //       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NROCOMPROBANTE;
      //     }
      //     this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
      //       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
      //       if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
      //         this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
      //           if (resumen.SELECCIONADO === true) {
      //             resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
      //             resumen.TRNCOBRO_REFERENCIA = 'F';
      //             resumen.STRCUOTAFAC = 'F';
      //             if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
      //               resumen.STRCUOTAFAC = 'C';
      //             }
      //             this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
      //               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
      //                 if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
      //                   this.actualizarCuotasPagadas(resumen);
      //                 }
      //                 this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
      //                   '', '01', '', '', '', '').subscribe(() => {
      //                   });
      //               });
      //           }
      //         });
      //       }
      //       this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
      //         '', '01', '', '', '', '').subscribe(() => {
      //           this.message.add({
      //             key: 'cartera',
      //             severity: 'success',
      //             summary: 'Inserción de Cartera',
      //             detail: 'Se ha insertado el documento exitosamente'
      //           });
      //           this.spin=false//CDPJ
      //         });
      //     });
      //     if (this.tabVarios === true) {
      //       this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
      //         this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(cobro[0].TRNCOBRO_NRODOC), 'E',
      //           '', '01', '', '', '', '').subscribe(() => {
      //           });
      //         if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
      //           await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async (vario) => {
      //             vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
      //             vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
      //             vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
      //             if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
      //               vario.TRNVARIOS_IMPORTE = vario.DEBE;
      //             }
      //             if (vario.HABER !== '' && Number(vario.HABER) > 0) {
      //               vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
      //             }
      //             vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
      //             this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
      //               vario.TRNVARIOS_LINEA = vario.LINEA;
      //               this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(vario.TRNCOBRO_NRODOC), 'I',
      //                 '', '01', '', '', '', '').subscribe(() => {
      //                 });
      //               });
      //               this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
      //                 this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
      //                 this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //                 if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
      //                   this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
      //                     if (resumen.SELECCIONADO === true) {
      //                       resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
      //                       this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
      //                       }, error1 => {
      //                         console.log(error1.error);
      //                       });
      //                     }
      //                   });
      //                 }
      //                 this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //                 this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
      //                   '', '01', '', '', '', '').subscribe(() => {
      //                     this.message.add({
      //                       key: 'cartera',
      //                       severity: 'success',
      //                       summary: 'Asiento Contable',
      //                       detail: 'Se ha generado el asiento contable'
      //                     });
      //                     this.spin=false//CDPJ
      //                   });
      //                   this.cambiarBotones();
      //                 this.cancelarEdicionCartera();
      //               });
                  
      //             //CDPJ 
      //             if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
      //               console.log(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV'))

      //               const data= await this.cxcCarteraService.insertarMovbancos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO);
      //             }  
      //           //CDPJ
      //           }));
      //         }
      //       });
      //     } else {
      //       this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
      //         this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
      //         this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //         if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
      //           this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
      //             if (resumen.SELECCIONADO === true) {
      //               resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
      //               this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
      //               }, error1 => {
      //                 console.log(error1.error);
      //               });
      //             }
      //           });
      //         }
      //         this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
      //         this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
      //           '', '01', '', '', '', '').subscribe(() => {
      //             this.message.add({
      //               key: 'cartera',
      //               severity: 'success',
      //               summary: 'Asiento Contable',
      //               detail: 'Se ha generado el asiento contable'
      //             });
      //             this.spin=false//CDPJ
      //           });
      //       });
      //     }
      //     // this.cambiarBotones();
      //     // this.consultarPestañas();
      //   });



    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {

      if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
        this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
          if (resumen.SELECCIONADO === true) {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = resumen.TRNCOBRO_IMPORTE;
          }
        });
      }
      // this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
      //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {

          const data1 = await this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).toPromise()
            for (const cobro of data1){
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro.TRNCOBRO_NRODOC;
          //this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
            const data2 = await this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).toPromise()
            for (const saldo of data2){
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo.SALDO;

            await this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').toPromise()//.subscribe(() => {});

            if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
              //this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]){
                if (resumen.SELECCIONADO === true) {
                  resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                  resumen.TRNCOBRO_REFERENCIA = 'F';
                  resumen.STRCUOTAFAC = 'F';
                  if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                    resumen.STRCUOTAFAC = 'C';
                  }
                  await this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).toPromise()//.subscribe(() => {
                      if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                        await this.actualizarCuotasPagadas(resumen);
                      }
                      this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro.TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                        '', '01', '', '', '', '').subscribe(() => {
                        });
                    //});

                }
              }
              //});
            }
            if (this.tabVarios === true) {

              await this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()//.subscribe(async () => {
                this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(cobro.TRNCOBRO_NRODOC), 'E',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {

                  //await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async (vario) => {
                    for (const vario of this.cxcCarteraService.cxctrnvariosarray[this.indicador]){
                    vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                    vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
                    vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
                    if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
                      vario.TRNVARIOS_IMPORTE = vario.DEBE;
                    }
                    if (vario.HABER !== '' && Number(vario.HABER) > 0) {
                      vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
                    }
                    vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
                    await this.cxcCarteraService.insertarVarios(vario).toPromise()//.subscribe(() => {
                      vario.TRNVARIOS_LINEA = vario.LINEA;
                    //});
                      //this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
                        
                      //});
                      this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(vario.TRNCOBRO_NRODOC), 'I',
                        '', '01', '', '', '', '').subscribe(() => {
                        });                     
                    //CDPJ 
                    if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
                      let fecha = this.obtenerFechaDocString();
                      const data= await this.cxcCarteraService.insertarMovbancos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,fecha,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO);
                    }  
                  //CDPJ
                  }
                  const data3 = await this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
                        for (const asi of data3){
                        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[':B1'];
                        await this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                        if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                          //this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                            for (const resumen  of  this.cxcCarteraService.cxctrnresumenarray[this.indicador]){
                            if (resumen.SELECCIONADO === true) {
                              resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                              await this.cxcCarteraService.actualizarestadoFac(resumen).toPromise()
                              // this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                              // }, error1 => {
                              //   console.log(error1.error);
                              // });
                            }
                          }
                          //});                          
                        }
                        await this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[':B1'] + '/CXC'), 'I',
                          '', '01', '', '', '', '').subscribe(() => {
                            this.message.add({
                              key: 'cartera',
                              severity: 'success',
                              summary: 'Asiento Contable',
                              detail: 'Se ha generado el asiento contable'
                            });
                            this.spin=false//CDPJ
                          });
                      await this.cambiarBotones();
                     await this.cancelarEdicionCartera();
                        }
                      
                  //}));
                }
             // });
            } else {
              //this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
                const data3 = await this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
                for (const asi of data3){
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[':B1'];
                await this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                  //this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]){
                    if (resumen.SELECCIONADO === true) {
                      resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      await this.cxcCarteraService.actualizarestadoFac(resumen).toPromise()
                      // this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                      // }, error1 => {
                      //   console.log(error1.error);
                      // });
                    }
                  }
                  //});
                }
                await this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
                this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[':B1'] + '/CXC'), 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                    this.message.add({
                      key: 'cartera',
                      severity: 'success',
                      summary: 'Asiento Contable',
                      detail: 'Se ha generado el asiento contable'
                    });
                    this.spin=false//CDPJ
                  });
                  
                }
              //});
            }
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro.TRNCOBRO_NRODOC + '/' + cobro.TRNCOBRO_NROCOMPROBANTE), 'A',
              '', '01', '', '', '', '').subscribe(() => {
                this.message.add({
                  key: 'cartera',
                  severity: 'success',
                  summary: 'Inserción de Cartera',
                  detail: 'Se ha insertado el documento exitosamente'
                });
                this.spin=false//CDPJ              
              });
              await this.cambiarBotones();
              await this.cancelarEdicionCartera();
            }
          //});
          // this.cambiarBotones();
          // this.consultarPestañas();
        }
        //});

    }
  } else if (this.saldoInicial === true) {
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 1;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      // this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
      //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
          const data1= await this.cxcCarteraService.insertarCobroP(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC)
            for (const cobro of data1){
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro.TRNCOBRO_NRODOC;
          //this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
            const data2= await this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).toPromise()
            for (const saldo of data2){
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo.SALDO;
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro.TRNCOBRO_NRODOC + '/' + cobro.TRNCOBRO_NROCOMPROBANTE), 'I',
              '', '01', '', '', '', '').subscribe(() => {
                this.message.add({
                  key: 'cartera',
                  severity: 'success',
                  summary: 'Inserción de Cartera',
                  detail: 'Se ha insertado el documento exitosamente'
                });
                this.spin=false//CDPJ
                });
                await this.cambiarBotones();
                await this.cancelarEdicionCartera();
              }
              //});
            }
        //});
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
      // this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
      //   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {

          const data6= await this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).toPromise()
          for(const cobro of data6){
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro.TRNCOBRO_NRODOC;

            //this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
              const data7 = await this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).toPromise()
              for (const saldo of data7){
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo.SALDO;
              this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro.TRNCOBRO_NRODOC + '/' + cobro.TRNCOBRO_NROCOMPROBANTE), 'A',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cartera',
                    severity: 'success',
                    summary: 'Inserción de Cartera',
                    detail: 'Se ha insertado el documento exitosamente'
                  });
                  this.spin=false//CDPJ
                });
                await this.cambiarBotones();
                await this.cancelarEdicionCartera();
              }
              
            //});

            // this.cambiarBotones();
            // this.consultarPestañas();
          }
        //});
    }
  }
}
//   async saveNC() {
//     let errores=0
//     const dblTotalVarios = Number(this.calcularTotalVarios())*(-1);
//     const dblImporteDoc = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC) * (-1);
//     const strNombreDoc = 'Nota de Crédito';
//     console.log('2', dblImporteDoc, dblTotalVarios);
//     if (dblImporteDoc !== dblTotalVarios) {//CDPJ ANTES dblImporteDoc == dblTotalVarios
//       this.message.add({
//         key: 'cartera',
//         severity: 'error',
//         summary: 'Información',
//         detail: 'El total de Varios debe ser igual al ' +
//           'total del documento ' + strNombreDoc
//       });
//       this.spin=false//CDPJ
//       return;
//     }
// //CDPJ
// if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCCAVNC').CFG_VALOR1 === 1 && this.saldoInicial === false ){
//   this.cxcCarteraService.cxctrnvariosarray[this.indicador].map((vario) => {
//     vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;
//     if(vario.CON_CENTRO ==='Si'){
//       if(vario.CEN_CODIGO === ''){
//         this.message.add({
//           key: 'cartera',
//           severity: 'error',
//           summary: 'Información',
//           detail: 'Ingrese el centro de costos de la cuenta contable ' + vario.CON_CODIGO
           
//         });
//         errores++;
//         this.spin=false//CDPJ
//         return;
//       }
//     }
//     //GSRF
//     if (vario.CON_CODIGO.substr(-1, 1) === '.') {
//       console.log('entra2')
//       this.mensajeAlerta('error', 'Cuenta Contable', 'No se puede elegir esa cuenta contable en varios');
//       vario.CON_CODIGO = '';
//       vario.CON_NOMBRE = '';
//       this.aggrid.refreshColumnDefs();
//       this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
//        errores++;
//         this.spin=false//CDPJ
//         return;
//     }
//     //GSRF
//   })
// }
// await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async(vario) => {
//   //GSRF
//   if (vario.CON_CODIGO.substr(-1, 1) === '.') {
//     console.log('entra2')
//     this.mensajeAlerta('error', 'Cuenta Contable', 'No se puede elegir esa cuenta contable en varios');
//     vario.CON_CODIGO = '';
//     vario.CON_NOMBRE = '';
//     this.aggrid.refreshColumnDefs();
//     this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
//      errores++;
//       this.spin=false//CDPJ
//       return;
//   }
//   //GSRF
//   //GSRF
//   let numdep = await this.cxcCarteraService.getcuentconta(vario.CON_CODIGO);
//   console.log('NC',numdep.length);
//   if (numdep.length === 0) {
//     this.mensajeAlerta('error', 'Cuenta Contable', 'Cuenta contable no existe'); 
//     errores++;
//     this.spin=false
//     return;
//   }
// //GSRF
// })
// )
// //CDPJ
//     const exis = await this.cxcCarteraService.verificarComprobanteUnico('N',
//       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, '');
//     console.log(exis);
//     if (exis[0][':B1'] !== '0' && exis[0][':B1'] !== null && exis[0][':B1'] !== undefined) {
//       this.message.add({
//         key: 'cartera',
//         severity: 'error',
//         summary: 'Inserción de Cartera',
//         detail: 'El numero de comprobante ya existe'
//       });
//       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
//       document.getElementById('numeroCartera').focus();
//       this.spin=false//CDPJ
//       return;
//     }

//     if (this.saldoInicial === false && errores === 0) {
//       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 0;
//       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
//       if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
//         if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
//           this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
//             if (resumen.SELECCIONADO === true) {
//               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = resumen.TRNCOBRO_IMPORTE;
//             }
//           });
//         }

//         //console.log('insertarCobro--->',this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC);
//         this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
//           this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//             this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//             if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
//               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NROCOMPROBANTE;
//             }
//             this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//               if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
//                 this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                   if (resumen.SELECCIONADO === true) {
//                     resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                     resumen.TRNCOBRO_REFERENCIA = 'F';
//                     resumen.STRCUOTAFAC = 'F';
//                     if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                       resumen.STRCUOTAFAC = 'C';
//                     }
//                     this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//                         if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                           this.actualizarCuotasPagadas(resumen);
//                         }
//                         this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                           });
//                       });
//                   }
//                 });
//               }
//               this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
//                 '', '01', '', '', '', '').subscribe(() => {
//                   this.message.add({
//                     key: 'cartera',
//                     severity: 'success',
//                     summary: 'Inserción de Cartera',
//                     detail: 'Se ha insertado el documento exitosamente'
//                   });
//                   this.spin=false//CDPJ
//                 });
//             });
//             if (this.tabVarios === true) {
//               this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
//                 this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(cobro[0].TRNCOBRO_NRODOC), 'E',
//                   '', '01', '', '', '', '').subscribe(() => {
//                   });
//                 if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
//                   await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async (vario) => {
//                     vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                     vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
//                     vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
//                     if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
//                       vario.TRNVARIOS_IMPORTE = vario.DEBE;
//                     }
//                     if (vario.HABER !== '' && Number(vario.HABER) > 0) {
//                       vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
//                     }
//                     vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
//                     this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
//                       vario.TRNVARIOS_LINEA = vario.LINEA;
//                       this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(vario.TRNCOBRO_NRODOC), 'I',
//                         '', '01', '', '', '', '').subscribe(() => {
//                         });
//                       });
//                       this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
//                         this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
//                         this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                         if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
//                           this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                             if (resumen.SELECCIONADO === true) {
//                               resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                               this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
//                               }, error1 => {
//                                 console.log(error1.error);
//                               });
//                             }
//                           });
//                         }
//                         this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                         this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                             this.message.add({
//                               key: 'cartera',
//                               severity: 'success',
//                               summary: 'Asiento Contable',
//                               detail: 'Se ha generado el asiento contable'
//                             });
//                             this.spin=false//CDPJ
//                           });
//           this.cambiarBotones();
//         this.cancelarEdicionCartera();
//                       });
                    
//                     //CDPJ 
//                     if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
//                       console.log(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV'))

//                       const data= await this.cxcCarteraService.insertarMovbancos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO);
//                     }  
//                   //CDPJ
//                   }));
//                 }
//               });
//             } else {
//               this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
//                 this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
//                 this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                 if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
//                   this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                     if (resumen.SELECCIONADO === true) {
//                       resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                       this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
//                       }, error1 => {
//                         console.log(error1.error);
//                       });
//                     }
//                   });
//                 }
//                 this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                 this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
//                   '', '01', '', '', '', '').subscribe(() => {
//                     this.message.add({
//                       key: 'cartera',
//                       severity: 'success',
//                       summary: 'Asiento Contable',
//                       detail: 'Se ha generado el asiento contable'
//                     });
//                     this.spin=false//CDPJ
//                   });
//               });
//             }
//             // this.cambiarBotones();
//             // this.consultarPestañas();
//           });
//       } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
//         if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
//           this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
//             if (resumen.SELECCIONADO === true) {
//               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = resumen.TRNCOBRO_IMPORTE;
//             }
//           });
//         }
//         this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
//           this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//             this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//             this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//               this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
//                 this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
//                 });
//               if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
//                 this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                   if (resumen.SELECCIONADO === true) {
//                     resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                     resumen.TRNCOBRO_REFERENCIA = 'F';
//                     resumen.STRCUOTAFAC = 'F';
//                     if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                       resumen.STRCUOTAFAC = 'C';
//                     }
//                     this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//                         if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                           this.actualizarCuotasPagadas(resumen);
//                         }
//                         this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                           });
//                       });
//                   }
//                 });
//               }
//               if (this.tabVarios === true) {
//                 this.cxcCarteraService.eliminarVarios(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
//                   this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(cobro[0].TRNCOBRO_NRODOC), 'E',
//                     '', '01', '', '', '', '').subscribe(() => {
//                     });
//                   if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length !== 0) {
//                     await Promise.all(this.cxcCarteraService.cxctrnvariosarray[this.indicador].map(async (vario) => {
//                       vario.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                       vario.TRNCOBRO_NRODOC = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
//                       vario.TRNCOBRO_NROCOMPROBANTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
//                       if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
//                         vario.TRNVARIOS_IMPORTE = vario.DEBE;
//                       }
//                       if (vario.HABER !== '' && Number(vario.HABER) > 0) {
//                         vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
//                       }
//                       vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
//                       this.cxcCarteraService.insertarVarios(vario).subscribe(() => {
//                         vario.TRNVARIOS_LINEA = vario.LINEA;
//                       });
//                         this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
//                           this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
//                           this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                           if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
//                             this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                               if (resumen.SELECCIONADO === true) {
//                                 resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                                 this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
//                                 }, error1 => {
//                                   console.log(error1.error);
//                                 });
//                               }
//                             });
//                           }
//                           this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                           this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
//                             '', '01', '', '', '', '').subscribe(() => {
//                               this.message.add({
//                                 key: 'cartera',
//                                 severity: 'success',
//                                 summary: 'Asiento Contable',
//                                 detail: 'Se ha generado el asiento contable'
//                               });
//                               this.spin=false//CDPJ
//                             });
//                         });
//                         this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(vario.TRNCOBRO_NRODOC), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                           });
                      
//                       //CDPJ 
//                       if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
//                         const data= await this.cxcCarteraService.insertarMovbancos(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO);
//                       }  
//                     //CDPJ
//                     }));
//                   }
//                 });
//               } else {
//                 this.cxcCarteraService.generarAsientoNC(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
//                   this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
//                   this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                   if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
//                     this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                       if (resumen.SELECCIONADO === true) {
//                         resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                         this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
//                         }, error1 => {
//                           console.log(error1.error);
//                         });
//                       }
//                     });
//                   }
//                   this.actualizarNumeroAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                   this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
//                     '', '01', '', '', '', '').subscribe(() => {
//                       this.message.add({
//                         key: 'cartera',
//                         severity: 'success',
//                         summary: 'Asiento Contable',
//                         detail: 'Se ha generado el asiento contable'
//                       });
//                       this.spin=false//CDPJ
//                     });
//                 });
//               }
//               this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
//                 '', '01', '', '', '', '').subscribe(() => {
//                   this.message.add({
//                     key: 'cartera',
//                     severity: 'success',
//                     summary: 'Inserción de Cartera',
//                     detail: 'Se ha insertado el documento exitosamente'
//                   });
//                   this.spin=false//CDPJ
//                 });
//             });
//             // this.cambiarBotones();
//             // this.consultarPestañas();
//           });
//       }
//     } else if (this.saldoInicial === true) {
//       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 1;
//       this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
//       if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
//         this.cxcCarteraService.insertarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
//           this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//             this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//             this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//               this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
//                 '', '01', '', '', '', '').subscribe(() => {
//                   this.message.add({
//                     key: 'cartera',
//                     severity: 'success',
//                     summary: 'Inserción de Cartera',
//                     detail: 'Se ha insertado el documento exitosamente'
//                   });
//                   this.spin=false//CDPJ
//                 });
//             });
//           });
//       } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
//         this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
//           this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//             this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//             this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//               this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//               this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
//                 '', '01', '', '', '', '').subscribe(() => {
//                   this.message.add({
//                     key: 'cartera',
//                     severity: 'success',
//                     summary: 'Inserción de Cartera',
//                     detail: 'Se ha insertado el documento exitosamente'
//                   });
//                   this.spin=false//CDPJ
//                 });
//             });
//             // this.cambiarBotones();
//             // this.consultarPestañas();
//           });
//       }
//     }
//   }

  async saveRT() {
    //CDPJ
    if(this.cxcCarteraService.cxctrnretencionarray[this.indicador].length === 0){
      this.mensajeAlerta('info', 'Información', 'El detalle de la retención está vacío');
      this.spin=false;
      return;
    }//CDPJ
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC;
      console.log('await insertarCobroP');
      const cobro = await this.cxcCarteraService.insertarCobroP(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NROCOMPROBANTE;
      console.log('await obtenerSaldoClienteP');
      const saldo = await this.cxcCarteraService.obtenerSaldoClienteP(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
      if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
        for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
          if (resumen.SELECCIONADO === true) {
            resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            resumen.STRCUOTAFAC = 'F';
            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              resumen.STRCUOTAFAC = 'C';
            }

            await this.cxcCarteraService.insertarDocPorCobrarP(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);

            console.log('await insertarDocPorCobrarP');

            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              await this.actualizarCuotasPagadas(resumen);
            }
            this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
              '', '01', '', '', '', '').subscribe(() => {
              });
          }
        }
        /*this.cxcCarteraService.cxctrnresumenarray[this.indicador].map(async (resumen) => {
          if (resumen.SELECCIONADO === true) {
            resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            resumen.STRCUOTAFAC = 'F';
            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              resumen.STRCUOTAFAC = 'C';
            }
            console.log('await insertarDocPorCobrarP');
            await this.cxcCarteraService.insertarDocPorCobrarP(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              this.actualizarCuotasPagadas(resumen);
            }
            this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
              '', '01', '', '', '', '').subscribe(() => {
              });
          }
        });*/
      }
      this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
        '', '01', '', '', '', '').subscribe();
      this.message.add({
        key: 'cartera',
        severity: 'success',
        summary: 'Inserción de Cartera',
        detail: 'Se ha insertado el documento exitosamente'
      });
      this.spin=false//CDPJ
      if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
        for (const ret of this.cxcCarteraService.cxctrnretencionarray[this.indicador]) {
          ret.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
          if (ret.CON_CODIGO === '') {
            ret.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
          }
          ret.CEN_CODIGO = '';
          console.log(this.numeroFactura);
          ret.ENCFAC_NUMERO = this.numeroFactura;
          console.log('await insertarRetencionP');
          await this.cxcCarteraService.insertarRetencionP(ret, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);

          this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/codret/' + cobro[0].TRNCOBRO_NROCOMPROBANTE)+'/'+ret.RETENCION_CODIGO, 'I',
        '', '01', '', '', '', '').subscribe();

          if (this.retencionElect === true) {
            await this.cxcCarteraService.actualizarTipoRetencionProm(ret, 'E');
            /*  this.cxcCarteraService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
             }); */
          } else if (this.retencionElect === false) {
            await this.cxcCarteraService.actualizarTipoRetencionProm(ret, 'F');
            /* this.cxcCarteraService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
            }); */
          }
        }
        /*this.cxcCarteraService.cxctrnretencionarray[this.indicador].map(async (ret) => {
          ret.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
          if (ret.CON_CODIGO === '') {
            ret.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
          }
          ret.CEN_CODIGO = '';
          console.log(this.numeroFactura);
          ret.ENCFAC_NUMERO = this.numeroFactura;
          console.log('await insertarRetencionP');
          await this.cxcCarteraService.insertarRetencionP(ret, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
          if (this.retencionElect === true) {
            this.cxcCarteraService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
            });
          } else if (this.retencionElect === false) {
            this.cxcCarteraService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
            });
          }
        });*/
        console.log('SUBSCRIBE generarAsientoRT');
        await this.generarasientoret();

      }
    } else if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {

      const cobro = await this.cxcCarteraService.actualizarCobroProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
      console.log('actualizaCobro')
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
      const saldo = await this.cxcCarteraService.obtenerSaldoClienteP(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
      console.log('obtiene saldo cliente');
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
      await this.cxcCarteraService.eliminarTrnDocumentoProm('TRNCOBRO_NRODOC = \'' +
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'');
      console.log('eliminartrndocumento');

      if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
        for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
          if (resumen.SELECCIONADO === true) {
            resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            resumen.STRCUOTAFAC = 'F';
            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              resumen.STRCUOTAFAC = 'C';
            }
            await this.cxcCarteraService.insertarDocPorCobrarP(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              await this.actualizarCuotasPagadas(resumen);
            }
            this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
              '', '01', '', '', '', '').subscribe(() => { });

          }
        }

      }

      if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
        await this.cxcCarteraService.eliminarTrnRetencionProm('TRNCOBRO_NRODOC = \'' +
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC +
          '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'');
        for (const ret of this.cxcCarteraService.cxctrnretencionarray[this.indicador]) {
          ret.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
          if (ret.CON_CODIGO === '') {
            ret.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
          }
          ret.CEN_CODIGO = '';
          ret.ENCFAC_NUMERO = this.numeroFactura;
          await this.cxcCarteraService.insertarRetencionP(ret, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
          if (this.retencionElect === true) {
            await this.cxcCarteraService.actualizarTipoRetencionProm(ret, 'E');
          } else if (this.retencionElect === false) {
            await this.cxcCarteraService.actualizarTipoRetencionProm(ret, 'F');
          }
        }
      }

      this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
        '', '01', '', '', '', '').subscribe(() => {
          this.message.add({
            key: 'cartera',
            severity: 'success',
            summary: 'Inserción de Cartera',
            detail: 'Se ha insertado el documento exitosamente'
          });
          this.spin=false//CDPJ
        });

      try {
        await this.cxcCarteraService.generarAsientoRTProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]);
        console.log('Se generó el asiento');
        const res = await this.cxcCarteraService.getAsientoProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        console.log('Obtiene el asiento');

        this.asiento = res[0].ASI_NRO;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
        this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
        if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
          for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
            if (resumen.SELECCIONADO === true) {
              resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              await this.cxcCarteraService.actualizarestadoFacProm(resumen);
            }
          }
        }

        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
          '', '01', '', '', '', '').subscribe();
        this.message.add({
          key: 'cartera',
          severity: 'success',
          summary: 'Asiento Contable',
          detail: 'Se ha generado el asiento contable'
        });
        this.spin=false//CDPJ
        this.cambiarBotones();
        await this.cancelarEdicionCartera();
      } catch (err) {
        this.message.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Asiento Contable',
          detail: err.error
        });
        this.spin=false//CDPJ
      }



      /*this.cxcCarteraService.actualizarCobro(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador],
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
          this.cxcCarteraService.obtenerSaldoCliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
            this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
            this.cxcCarteraService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
              this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
              });
            if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
              this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                if (resumen.SELECCIONADO === true) {
                  resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                  resumen.STRCUOTAFAC = 'F';
                  if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                    resumen.STRCUOTAFAC = 'C';
                  }
                  this.cxcCarteraService.insertarDocPorCobrar(resumen, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                      if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                        this.actualizarCuotasPagadas(resumen);
                      }
                      this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                        '', '01', '', '', '', '').subscribe(() => {
                        });
                    });
                }
              });
            }
            if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length !== 0) {
              this.cxcCarteraService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' +
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC +
                '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(() => {
                });
              this.cxcCarteraService.cxctrnretencionarray[this.indicador].map((ret) => {
                ret.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                if (ret.CON_CODIGO === '') {
                  ret.CON_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                }
                ret.CEN_CODIGO = '';
                ret.ENCFAC_NUMERO = this.numeroFactura;
                this.cxcCarteraService.insertarRetencion(ret, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                  this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                    if (this.retencionElect === true) {
                      this.cxcCarteraService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
                      });
                    } else if (this.retencionElect === false) {
                      this.cxcCarteraService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
                      });
                    }
                  });
              });
            }
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
              '', '01', '', '', '', '').subscribe(() => {
                this.message.add({
                  key: 'cartera',
                  severity: 'success',
                  summary: 'Inserción de Cartera',
                  detail: 'Se ha insertado el documento exitosamente'
                });
              });
            this.cxcCarteraService.generarAsientoRT(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
              this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                this.asiento = res[0].ASI_NRO;
                this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
                  this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    if (resumen.SELECCIONADO === true) {
                      resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
                      }, error1 => {
                        console.log(error1.error);
                      });
                    }
                  });
                }
                this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
                  String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'),
                  'I', '', '01', '', '', '', '').subscribe(() => {
                    this.message.add({
                      key: 'cartera',
                      severity: 'success',
                      summary: 'Asiento Contable',
                      detail: 'Se ha generado el asiento contable'
                    });
                  });
              });
            });
          });
          // this.cambiarBotones();
          // this.consultarPestañas();
        }); */
    }
  }


  // metodos nuevos

  generarasientoretOld() {
    this.cxcCarteraService.generarAsientoRT(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.asiento = res[0].ASI_NRO;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
        if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
          this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
            if (resumen.SELECCIONADO === true) {
              resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
              }, error1 => {
                console.log(error1.error);
              });
            }
          });
        }
        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
          '', '01', '', '', '', '').subscribe();
        this.message.add({
          key: 'cartera',
          severity: 'success',
          summary: 'Asiento Contable',
          detail: 'Se ha generado el asiento contable'
        });
      });
    });
  }

  async generarasientoret() {
    try {
      await this.cxcCarteraService.generarAsientoRTProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]);
      console.log('Se generó el asiento');
      const res = await this.cxcCarteraService.getAsientoProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
      console.log('Obtiene el asiento');

      this.asiento = res[0].ASI_NRO;
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
      if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
        for (const resumen of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
          if (resumen.SELECCIONADO === true) {
            resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            await this.cxcCarteraService.actualizarestadoFacProm(resumen);
          }
        }
      }

      this.actualizarNumeroComprobante(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);

      this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
        '', '01', '', '', '', '').subscribe();
      this.message.add({
        key: 'cartera',
        severity: 'success',
        summary: 'Asiento Contable',
        detail: 'Se ha generado el asiento contable'
      });
      this.spin=false//CDPJ
      this.cambiarBotones();
      await this.cancelarEdicionCartera();
    } catch (err) {
      this.message.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Asiento Contable',
        detail: err.error
      });
      this.spin=false//CDPJ
    }
    /*this.cxcCarteraService.generarAsientoRT(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      this.cxcCarteraService.getAsiento(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.asiento = res[0].ASI_NRO;
        this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
        if (this.cxcCarteraService.cxctrnresumenarray[this.indicador].length !== 0) {
          this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
            if (resumen.SELECCIONADO === true) {
              resumen.CLI_CODIGO = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              this.cxcCarteraService.actualizarestadoFac(resumen).subscribe(() => {
              }, error1 => {
                console.log(error1.error);
              });
            }
          });
        }
        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
          '', '01', '', '', '', '').subscribe();
        this.message.add({
          key: 'cartera',
          severity: 'success',
          summary: 'Asiento Contable',
          detail: 'Se ha generado el asiento contable'
        });
      });
    });*/
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'cartera',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  controlarFechaCobro() {
    let bolCorrecto = true;
    let strFechaDoc = this.obtenerFechaDoc();

    for (const item of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
      if (item.SELECCIONADO !== null) {
        if (item.SELECCIONADO) {
          const strFechaFact = this.dateValidation(item.TRNCOBRO_FECHATRN);
          console.log(strFechaFact.getTime() > strFechaDoc.getTime());
          console.log(strFechaFact.getTime(), strFechaDoc.getTime());
          if (strFechaFact.getTime() > strFechaDoc.getTime()) {
            bolCorrecto = false;
          }
        }
      }
    }

    return bolCorrecto;
  }
  //GSRF
  controlarFechaforpago() {
    let bolCorrecto = true;
    let strFechaDoc = this.obtenerFechaDoc();
    for (const item of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
      if (item.SELECCIONADO !== null) {
        if (item.SELECCIONADO) {
          const strFechaFact = this.dateValidation(item.TRNCOBRO_FECHATRN);
          for (const item1 of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]) {
            if (item1.TRNPAGO_TIPO !== null) {
              const strFechaFact1 = this.dateValidation(item1.TRNPAGO_FECHA);
              // console.log(strFechaFact1.getTime() > strFechaFact.getTime());
              //console.log(strFechaFact1.getTime(), strFechaFact.getTime());
              if (strFechaFact.getTime() > strFechaFact1.getTime()) {
                bolCorrecto = false;
              }
            }
          }
        }
      }
    };

    return bolCorrecto;
  }
  controlarFechaforpagodev() {
    let bolCorrecto = true;
    let strFechaDoc = this.obtenerFechaDoc();
    for (const item of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
      if (item.SELECCIONADO !== null) {
        if (item.SELECCIONADO) {
          const strFechaFact = this.dateValidation(item.TRNCOBRO_FECHATRN);
          for (const item1 of this.cxcCarteraService.cxctrnformapagodevarray[this.indicador]) {
            if (item1.TRNCAJ_TIPO !== null) {
              const strFechaFact1 = this.dateValidation(item1.TRNCAJ_FECHA);
              //console.log(strFechaFact1.getTime() > strFechaFact.getTime());
              //console.log(strFechaFact1.getTime(), strFechaFact.getTime());
              if (strFechaFact.getTime() > strFechaFact1.getTime()) {
                bolCorrecto = false;
              }
            }
          }
        }
      }
    };

    return bolCorrecto;
  }
  //GSRF

  obtenerFechaDoc() {
    let strFecha: any = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('-')) {
      strFecha = this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,
        'dd/MM/yyyy');
    }
    const fechaAct = String(strFecha).split('/');
    const fechaNew = new Date(Number(fechaAct[2]), Number(fechaAct[1]) - 1, Number(fechaAct[0]));

    return fechaNew;
  }

  obtenerFechaDocString() {
    let strFecha: any = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('-')) {
      strFecha = this.datePipe.transform(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,
        'dd/MM/yyyy');
    }

    return strFecha;
  }

  dateValidation(fecha) {
    let strFecha: any = fecha;
    if (fecha.toString().includes('-')) {
      strFecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    }
    const fechaAct = String(strFecha).split('/');
    const fechaNew = new Date(Number(fechaAct[2]), Number(fechaAct[1]) - 1, Number(fechaAct[0]));

    return fechaNew;
  }

  async existeCliente() {
    let bolExiste = true;
    let strNombreCliente = '';
    try {
      const er = await this.cxcCarteraService.erClinombreProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
      if (er !== null) {
        if (er[0] !== undefined) {
          strNombreCliente = er[0].CLI_NOMBRE === null ? '' : er[0].CLI_NOMBRE;
        }
      }
      if (String(strNombreCliente).length === 0) {
        bolExiste = false;
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede consultar los clientes. ' + mensaje);
    }

    return bolExiste;
  }

  async existeVendedor() {
    let bolExiste = true;
    let strNombreVend = '';
    try {
      const er = await this.cxcCarteraService.erVennombreProm(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO);
      if (er !== null) {
        if (er[0] !== undefined) {
          strNombreVend = er[0].VEN_NOMBRE === null ? '' : er[0].VEN_NOMBRE;
        }
      }
      if (String(strNombreVend).length === 0) {
        bolExiste = false;
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede consultar los vendedores. ' + mensaje);
    }

    return bolExiste;
  }

  async verificarComprobanteUnico() {
    let bolVerificar = true;
    const strParam: any[] = [];
    if (this.bolNuevo) {
      strParam[0] = 'N';
      strParam[3] = '';
    } else {
      strParam[0] = 'M';
      if (this.cxctrnresumen.length > 0) {
        strParam[3] = this.cxctrnresumen[0].TRNCOBRO_NRODOC;
      } else {
        strParam[3] = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
      }
    }

    strParam[1] = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
    strParam[2] = '01';
    /*try {
      const rs = await this.cxcCarteraService.verificarComprobanteUnicoNew(strParam);
      console.log(rs[0]["'0'"]);
      console.log(rs[0][':B1']);
      if (rs[0][':B1'] !== undefined) {
        bolVerificar = false;
      }
			/*if (rs.next()) {
				if (!rs.getString(1).equals("0"))
					bolVerificar = false;
			} 
    }*//* catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede verificar si el comprobante es único ' + mensaje);
    }*/
    return bolVerificar;
  }

  verificarImporteDoc() {
    let bolVerificar = true;
    let intFilaSel = 0;
    let fila = 0;
    if (this.bolNuevo) {
      for (const item of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
        fila = fila + 1;

        if (Number(item.TRNCOBRO_IMPORTE) > Number(item.TRNCOBRO_SALDOINI)) {
          this.mensajeAlerta('info', 'Información', 'El importe es mayor que el saldo en la fila ' + fila);
          bolVerificar = false;
          return bolVerificar;
        }

        if (item.SELECCIONADO !== null) {
          if (item.SELECCIONADO === false) {
            intFilaSel = intFilaSel + 1;
          }
        }
      }
    }

    if (intFilaSel === this.cxcCarteraService.cxctrnresumenarray[this.indicador].length) {
      this.mensajeAlerta('info', 'Información', 'Ningún documento ha sido seleccionado.');
      bolVerificar = false;
      return bolVerificar;
    }

    return bolVerificar;
  }

  async verificarCobroDocVencidos() {
    let dblPorIntMor = 0;
    let strSentencia = '';
    let intNumDias = 0;
    let strPorIntMor = '';
    let dblMontoxMora = 0;
    let dblMontoxMoraTotal = 0;

    const strFechaDoc = this.obtenerFechaDocString();

    dblPorIntMor = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PORINTMOR').CFG_VALOR2; // porcentaje de interés por mora
    for (const item of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {
      if (item.SELECCIONADO !== null) {
        /*if (item.SELECCIONADO === true) {
          strSentencia = 'select to_date(\'' + strFechaDoc + '\',\'dd/mm/yyyy\')-to_date(\'' + item.TRNCOBRO_FECHAVENCE + '\',\'dd/mm/yyyy\') as RESULT  from dual ';
          try {
            const rs = await this.cxcCarteraService.ejecutarConsultaPromise(strSentencia);
            if (rs !== null) {
              intNumDias = Number(rs[0].RESULT);
            }

          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'Error al leer número de días ' + mensaje);
          }

          if (intNumDias >= 1) {
            dblMontoxMora = dblMontoxMora + Number(item.TRNCOBRO_IMPORTE);
          }
        }*/
      }

    }

    if (dblMontoxMora > 0) {
      dblMontoxMoraTotal = ((dblPorIntMor / 100) * dblMontoxMora);
    } else {
      dblMontoxMoraTotal = 0;
    }

    console.log(dblMontoxMoraTotal);

    return dblMontoxMoraTotal;
  }

  async verificarDiasMoraDoc() {
    let strSentencia = '';
    let intNumDias = 0;
    let strPorIntMor = '';
    let dblDiasGracia = 0;
    let dblTotalDiasMora = 0;

    const strFechaDoc = this.obtenerFechaDocString();

    dblDiasGracia = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DIAGRACIA').CFG_VALOR2; // días de gracia

    for (const item of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {

      if (item.SELECCIONADO !== null) {

        /*if (item.SELECCIONADO === true) {
          strSentencia = 'select to_date(\'' + strFechaDoc + '\',\'dd/mm/yyyy\')-(to_date(\'' + item.TRNCOBRO_FECHAVENCE + '\',\'dd/mm/yyyy\') + ' + dblDiasGracia + ') as RESULT  from dual ';

          try {
            const rs = await this.cxcCarteraService.ejecutarConsultaPromise(strSentencia);
            if (rs !== null) {
              intNumDias = Number(rs[0].RESULT);
            }

          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'Error al leer número de días ' + mensaje);
          }

          if (intNumDias >= 1) {
            dblTotalDiasMora = dblTotalDiasMora + intNumDias;
          }


        }*/

      }

    }
    return dblTotalDiasMora;
  }

  verificaSiAgregoDatosMora(intTotalDiasMora: number) {
    let intCodComParaMora = 0;
    let intCodComParaCobranza = 0;
    let bolResult = false;


    if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'APLPORINT').CFG_VALOR1 == 0) {
      return true;
    }

    // total otros gastos
    for (const item of this.cxcCarteraService.cxctrnotrosgarray[this.indicador]) {
      const strCODCOMMOR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODCOMMOR').CFG_VALOR3 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODCOMMOR').CFG_VALOR3;
      if (item.COMISION_CODIGO === strCODCOMMOR) {
        intCodComParaMora = 1;
      }

      const strCODCOMCOB = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODCOMCOB').CFG_VALOR3 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODCOMCOB').CFG_VALOR3;

      if (item.COMISION_CODIGO === strCODCOMCOB) {
        intCodComParaCobranza = 1;
      }

    }


    if (Number(intCodComParaCobranza) === 0) { //si no esta incluido lo de la cobranza
      if (Number(intTotalDiasMora) === 0) {//si no hay dias de mora
        intCodComParaCobranza = 1;
      }
    }


    if (Number(intCodComParaMora) === 0 || Number(intCodComParaCobranza) === 0) {
      bolResult = false;
    } else {
      bolResult = true;
    }

    return bolResult;

  }

  valorExcedeFormaPago() {
    let dblFormaPago = 0;
    let dblTotalCobrar = 0;
    let dblDiferencia = 0;
    let value: any = null;

    dblFormaPago = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO);
    dblTotalCobrar = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR);
    dblDiferencia = dblFormaPago - dblTotalCobrar;

    if (dblDiferencia > 0) {
      this.confirmationService.confirm({
        message: 'Transformar el valor que excede en la forma de pago en un anticipo ?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'carteraConf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          value = Number(dblDiferencia).toFixed(2);
          this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = Number(value);
        }, reject: () => {
          this.confirmationService.close();
        }
      });
    }

  }

  eliminarLineaRetEnBlanco() {
    if (this.cxcCarteraService.cxctrnretencionarray[this.indicador].length > 0) {
      for (const item of this.cxcCarteraService.cxctrnretencionarray[this.indicador]) {
        if (String(item.RETENCION_CODIGO).length === 0 && Number(item.TRNRETENCION_TOTALRETENIDO) === 0) {
          this.cxcCarteraService.cxctrnretencionarray[this.indicador].splice(this.cxcCarteraService.cxctrnretencionarray[this.indicador].findIndex(
            dato => dato.LINEA === item.LINEA), 1);
        }
      }
      this.aggrid.refreshColumnDefs();
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto);
    }
  }

  eliminarUltimaLineaOtrosG() {
    if (this.cxcCarteraService.cxctrnotrosgarray[this.indicador].length > 0) {
      for (const item of this.cxcCarteraService.cxctrnotrosgarray[this.indicador]) {
        if (String(item.COMISION_CODIGO).length === 0 && Number(item.TRNCOMISION_TOTALRETENIDO) === 0) {
          this.cxcCarteraService.cxctrnotrosgarray[this.indicador].splice(this.cxcCarteraService.cxctrnotrosgarray[this.indicador].findIndex(
            dato => dato.LINEA === item.LINEA), 1);
        }
      }
      this.aggrid.refreshColumnDefs();
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCarteraOtrosG.objeto);
    }
  }

  async faltaDatosRet() {
    let bolFalta = false;

    for (const item of this.cxcCarteraService.cxctrnretencionarray[this.indicador]) {
      let strCodigo = '';
      if (String(item.RETENCION_CODIGO).length === 0) {
        this.mensajeAlerta('info', 'Información', 'Ingrese el código de la Retención.');
        bolFalta = true;
      } else {
        if (String(item.RETENCION_CODIGO).length > 0) { //existencia de la retención
          try {
            const rs = await this.cxcCarteraService.erRetencioncodigoProm(item.RETENCION_CODIGO);
            strCodigo = rs[0].RETENCION_CODIGO === null ? '' : rs[0].RETENCION_CODIGO;

          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia de la retención ' + mensaje);
          }

          if (String(strCodigo).length === 0) {
            this.mensajeAlerta('info', 'Información', 'La retención no existe.');
            return bolFalta = true;
          }
        }
        if (String(item.TRNRETENCION_DESCRIPCION).length === 0 || String(item.TRNRETENCION_NRO).length === 0) {
          this.mensajeAlerta('info', 'Información', 'La descripción y el número de retención son obligatorios.');
          return bolFalta = true;
        }

        if (Number(item.TRNRETENCION_BASE) < 0) {
          this.mensajeAlerta('error', 'Error', 'La base de la retención no puede ser menor a cero.');
          return bolFalta = true;
        }

        if (Number(item.TRNRETENCION_PORCENTAJE) < 0) {
          this.mensajeAlerta('error', 'Error', 'El porcentaje de la retención no puede ser menor a cero.');
          return bolFalta = true;
        }

        if (Number(item.TRNRETENCION_TOTALRETENIDO) < 0) {
          this.mensajeAlerta('error', 'Error', 'El total de la retención no puede ser menor a cero.');
          return bolFalta = true;
        }
      }
    }
    return bolFalta;
  }

  async faltaDatosOtrosG() {
    let bolFalta = false;

    for (const item of this.cxcCarteraService.cxctrnotrosgarray[this.indicador]) {
      let strCodigo = '';

      if (String(item.COMISION_CODIGO).length === 0) {
        this.mensajeAlerta('info', 'Información', 'Ingrese el código de la comisión.');
        bolFalta = true;
      } else {
        if (String(item.COMISION_CODIGO).length > 0) {
          try {
            const rs = await this.cxcCarteraService.erComisioncodigoProm(item.COMISION_CODIGO);
            strCodigo = rs[0].COMISION_CODIGO === null ? '' : rs[0].COMISION_CODIGO;
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia  de la comisión ' + mensaje);
          }

          if (String(strCodigo).length === 0) {
            this.mensajeAlerta('info', 'Información', 'La comisión no existe.');
            return bolFalta = true;
          }
        }
        if (String(item.TRNCOMISION_DESCRIPCION).length === 0 || String(item.TRNCOMISION_NRO).length === 0) {
          this.mensajeAlerta('info', 'Información', 'La descripción y el número de la comisión son obligatorios.');
          return bolFalta = true;
        }
      }
    }

    return bolFalta;
  }

  eliminarUltimaLineaFP() {
    if (this.cxcCarteraService.cxctrnformapagoarray[this.indicador].length > 0) {
      for (const item of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]) {
        if (String(item.TRNPAGO_TIPO).length === 0) {
          this.cxcCarteraService.cxctrnformapagoarray[this.indicador].splice(this.cxcCarteraService.cxctrnformapagoarray[this.indicador].findIndex(
            dato => dato.LINEA === item.LINEA), 1);
        }
      }
      this.aggrid.refreshColumnDefs();
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
    }
  }

  async faltaDatosFP() {
    let bolFalta = false;

    for (const item of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]) {
      let strCodigo = '';

      if (String(item.TRNPAGO_TIPO).length === 0) { // si no existe tipo de pago
        this.mensajeAlerta('info', 'Información', 'Ingrese el tipo de pago.');
        bolFalta = true;
      } else {
        if (String(item.TRNPAGO_TIPO).length > 0) { //existencia de la forma de pago
          try {
            const rs = await this.cxcCarteraService.erFormapagotipoProm(item.TRNPAGO_TIPO);
            strCodigo = rs[0].FORMAPAGO_TIPO === null ? '' : rs[0].FORMAPAGO_TIPO;
          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia de la forma de pago' + mensaje);
          }

          if (String(strCodigo).length === 0) {
            this.mensajeAlerta('info', 'Información', 'La forma de pago no existe.');
            return bolFalta = true;
          }
        }

        if (String(item.TRNPAGO_NUMERO).length === 0 || String(item.TRNPAGO_FECHA).length === 0) {
          this.mensajeAlerta('info', 'Información', 'La información está incompleta.');
          return bolFalta = true;
        }

        if (item.TRNPAGO_TIPO !== 'DP' && item.TRNPAGO_TIPO !== 'ANCL') {
          if (String(item.CAJ_CODIGO).length === 0) {
            this.mensajeAlerta('error', 'Error', 'Ingrese la caja.');
            return bolFalta = true;
          }
        }
        //	PAGOS EN CHEQUE O TARJETA
        if (item.TRNPAGO_TIPO === 'TA' || item.TRNPAGO_TIPO === 'CH') {
          if (String(item.BANCLI_CODIGO).length === 0 || String(item.BANCLI_NOMBRE).length === 0 ||
            String(item.TRNPAGO_NROCTA).length == 0) {
            this.mensajeAlerta('error', 'Error', 'La información está incompleta.');
            return bolFalta = true;
          }
        }
         //CDPJ
         if (item.TRNPAGO_TIPO === 'TA') {
          if (String(item.TRNPAGO_NROCTA).length === 0 ) {
            this.mensajeAlerta('error', 'Error', 'El campo número de cuenta no puede estar vacío.');
            return bolFalta = true;
          }
        }
        //CDPJ
        if (Number(item.TRNPAGO_IMPORTE) <= 0) {
          this.mensajeAlerta('error', 'Error', 'El pago no puede ser menor o igual a cero.');
          bolFalta = true;
        }

        if (String(item.CAJ_CODIGO).length > 0) {
          if (await this.verificarExistenciaCaja(item.CAJ_CODIGO) === false) {
            this.mensajeAlerta('error', 'Error', 'No existe la caja.');
            bolFalta = true;
          }
        }

      }
    }

    return bolFalta;
  }

  async verificarExistenciaCaja(strCajCodigo) {
    let bolExiste = true;

    /*try {
      const rs = await this.cxcCarteraService.verificarExistenciaCaja(strCajCodigo);
      if (rs.length > 0) {
        if (rs[0].CAJ_CODIGO === null) {
          bolExiste = false;
          return bolExiste;
        }
      }
      else
        bolExiste = false;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia de la caja ' + mensaje);
      bolExiste = false;
    }*/

    return bolExiste;
  }

  async verificarCodSri() {
    let bolEliminar = true;
    let strCodSri = '';

    for (const item of this.cxcCarteraService.cxctrnformapagoarray[this.indicador]) {
      if (item.TRNPAGO_TIPO !== '' && item.TRNPAGO_CODSRI !== '') {
        strCodSri = '';
        try {
          if (item.TRNPAGO_TIPO === 'TA') {
            const rs = await this.cxcCarteraService.erCodsricodigo1Prom(item.TRNPAGO_CODSRI);
            strCodSri = rs[0].CODSRI_CODIGO === null ? '' : rs[0].CODSRI_CODIGO;
          } else {
            const rs = await this.cxcCarteraService.erCodsricodigo2Prom(item.TRNPAGO_CODSRI);
            strCodSri = rs[0].CODSRI_CODIGO === null ? '' : rs[0].CODSRI_CODIGO;
          }

          if (strCodSri === '') {
            bolEliminar = false;
            item.TRNPAGO_CODSRI = '';
          }

        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', 'No se puede verificar el codigo SRI ' + mensaje);
        }
      }
    }
    return bolEliminar;
  }

  eliminarUltimaLineaVarios() {
    if (this.cxcCarteraService.cxctrnvariosarray[this.indicador].length > 0) {
      for (const item of this.cxcCarteraService.cxctrnvariosarray[this.indicador]) {
        if (String(item.CON_CODIGO).length === 0) {
          this.cxcCarteraService.cxctrnvariosarray[this.indicador].splice(this.cxcCarteraService.cxctrnvariosarray[this.indicador].findIndex(
            dato => dato.LINEA === item.LINEA), 1);
        }
      }
      this.aggrid.refreshColumnDefs();
      this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
    }
  }

  async faltaDatosVarios() {
    let bolFalta = false;
    let dblTotalVarios = this.calcularTotalVarios();
    let dblImporteDoc = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC) * (-1);
    let strNombreDoc = 'Nota de Crédito';

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
      dblImporteDoc = Number(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TOTALNC);
      strNombreDoc = 'Nota de Débito';
    }

    if (dblImporteDoc + dblTotalVarios !== 0) {
      this.mensajeAlerta('info', 'Información', 'El total de Varios debe ser igual al total del documento');
      bolFalta = true;
      return bolFalta;
    }

    for (const item of this.cxcCarteraService.cxctrnvariosarray[this.indicador]) {
      if (await this.existeCuentaContable(item.CON_CODIGO) === false) {
        this.mensajeAlerta('info', 'Información', 'No existe la cuenta contable');
        bolFalta = true;
        return bolFalta;
      }
    }
    return bolFalta;
  }

  async existeCuentaContable(strCodCuenta) {
    let bolExiste = false;

    try {

      const rs = await this.cxcCarteraService.erConcodigo3Prom(strCodCuenta);
      const strCuenta = rs[0].CON_CODIGO === null ? '' : rs[0].CON_CODIGO;

      if (String(strCuenta).length > 0) {
        bolExiste = true;
      }
    }
    catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia de la cuenta contable ' + mensaje);
    }
    return bolExiste;
  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  async continuarFELiq() {
    this.closeSidebar();
    const msgclave = 'cartera';
    const strNumDoc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;

    if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'No ha seleccionado un documento'
      });
      return;
    }

    if (this.datosfe.COM_FEACTIVADA === '0') {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Facturación electrónica no está activada.'
      });
      return;
    }

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === ''
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === undefined
      || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === null) {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento no ha iniciado el proceso de firma electrónica.'
      });
      return;
    }

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'VA') {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra en su estado inicial, reverse el proceso de firma electrónica.'
      });
      return;
    }

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'NT') {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra notificado.'
      });
      return;
    }

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'NA') {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Visualize el log. Corrija el documento y reverse el proceso de firma electrónica.'
      });
      return;
    }

    this.spin = true;
    const data = await this.utilitariosService.continuarFirmaElectronica(strNumDoc);
    this.spin = false;
    if (data !== 'NOAUTORIZADO') {
      this.message.add({
        key: msgclave,
        severity: 'success',
        summary: 'Información',
        detail: data
      });
    }
  }

  async actualizarNumeroComprobante(strAsiNro) {
    if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMASICOM').CFG_VALOR1 === 0) {
      return;
    }

    if (strAsiNro === '') {
      return;
    }

    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE !== '') {//si no esta en blanco el comprobante lo respeta
      return;
    }

    try {
      await this.cxcCarteraService.actualizarNumeroComprobante(strAsiNro, this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
      this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = strAsiNro;
    } catch (err) {
      this.spin=false//CDPJ
      this.mensajeAlerta('error', 'Error', '! Proceso actualiza numero de comprobante  no terminó satisfactoriamente.... ! ' + err.error);
    }

  }

  verificarImporteTotalRet(): boolean {
    let bolVerificar = true;
    for (const itemDoc of this.cxcCarteraService.cxctrnresumenarray[this.indicador]) {

      if (itemDoc.SELECCIONADO === true) {
        let dblTotalRet = 0;
        for (const itemRet of this.cxcCarteraService.cxctrnretencionarray[this.indicador]) {

          if (itemDoc.TRNCOBRO_NRODOC === itemRet.ENCFAC_NUMERO) {
            dblTotalRet = dblTotalRet + Number(itemRet.TRNRETENCION_TOTALRETENIDO);
          }
        }

        dblTotalRet = Number(Number(dblTotalRet).toFixed(2));

        if (Number(itemDoc.TRNCOBRO_IMPORTE) != dblTotalRet) {
          this.mensajeAlerta('error', 'Error', 'El importe de la factura ' + itemDoc.TRNCOBRO_NRODOC +
            ' no es igual al total de las retenciones');
          bolVerificar = false;
          return bolVerificar;
        }
      }
    }

    return bolVerificar;
  }
  ////GSRF
  isEdit(data): boolean {
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' && this.editpagofr === true ) {
      return false;
    }
    return true;
  }
  ////GSRF
  async actualizarNumeroAsiento(strAsiNro) {
    const strTrnCobroNroDoc = this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
    // FORMAS DE PAGO

    let lstFormaPago = [];

    const data = await this.cxcCarteraService.asibangettrnpago(strTrnCobroNroDoc);
    //CDPJ
    if(data !== null && data !== undefined){
      for (const rs of data) {
        lstFormaPago.push({
          strBanCodigo: rs.CAJ_CODIGO,
          strBanNumero: rs.TRNPAGO_NUMERO,
          strBanTipo: rs.TRNPAGO_TIPO
        });
      }
  
      // Actualiza en numero del asiento
  
      // Lee cada una de las formas de pago y actualiza el número del asiento en la transaccion de bancos
  
      for (const item of lstFormaPago) {
        if (item.strBanTipo === "DP") {
          await this.cxcCarteraService.updateasientobantrnban(strAsiNro, item.strBanCodigo, item.strBanTipo, item.strBanNumero);
        } else {
          await this.cxcCarteraService.updateasientobantrncaj(strAsiNro, item.strBanCodigo, item.strBanTipo, item.strBanNumero);
        }
      }
    }
    //CDPJ
  }

  isEditFP(data) {
    //CDPJ
    if (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT' ||
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC' ||
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
      this.editConcil=false
  }
    //CDPJ
    if (this.editConcil) {
      return true;
    }
    return false;
  }
  //CDPJ
  idEditcajcodigo(data):boolean{
    console.log(data.TRNCAJ_TIPO)
    if ((this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' && this.editpagofr === true)||
    (this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN' && data.TRNCAJ_TIPO=== 'CH')) 
    {
      console.log('entro')
      return false;
    }else{
      return true;
    }
  }
  idEditcajcodigoFormapago(data):boolean{
    console.log(data.TRNCAJ_TIPO)
    if ((this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' && this.editpagofr === true)||
    ((this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' || this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB') &&( data.TRNPAGO_TIPO=== 'DP'))) 
    {
      console.log('entro')
      return false;
    }else{
      return true;
    }
  }
  generarconcepto(){
    let concepto=' ';
    if(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA'){
      concepto='Cancelación a factura/s '
    }
    if(this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB'){
      concepto='Abono a factura/s '
    }
    
    let facturas='';
    let formasdepago='';
    this.cxcCarteraService.cxctrnresumenarray[this.indicador].map((resumen) => {
      if (resumen.SELECCIONADO === true) {
        facturas=facturas+resumen.TRNCOBRO_NRODOC + ' / ';
      }
    }); 
    this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formpag)=>{
      formasdepago=formasdepago+formpag.TRNPAGO_TIPO+' '+formpag.TRNPAGO_NUMERO+'/ '
    })  
    concepto=concepto+facturas+formasdepago;
    this.cxcCarteraService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_CONCEPTO=concepto;

    this.cxcCarteraService.cxctrnformapagoarray[this.indicador].map((formpag)=>{
      formpag.TRNPAGO_CONCEPTO=concepto
      
    }) 
    this.aggrid.refreshaggrid(this.cxcCarteraService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
  }
  //CDPJ
}
