import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { Venencdevfac } from '../veninterfaces/venencdevfac';
import { Vendetdevfac } from '../veninterfaces/vendetdevfac';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Venexpodvf } from '../veninterfaces/venexpodvf';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class VenencdevfacService {

  public encdevfacarray: Venencdevfac[] = [];
  public detdevfacarray: Vendetdevfac[][] = [];
  public dblPorcDescGlbDevFarray: number[] = [];
  public dblValorDescGlbDevFarray: number[] = [];
  public idcelldevfac = 0;//GSRF

  public decimalesCantidadDevF: number;
  public decimalesPrecioDevF: number;
  public decimalesTotalDevF: number;
  public decimalesDescDVF: number;
  public dblPorcIvaDevF: number[] = [];
  //CDPJ
  public porcetariiva:number;
  public codtariiva:string;
  //CDPJ
  private way: string;
  constructor(private http: HttpClient, public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario, private confIniciales: ConfInicialesService,
    private message: MessageService, private init: NuevoComponentService,
    private datePipe: DatePipe) {

    this.way = sessionStorage.getItem('way');
  }

  getEncDevF(): Observable<any[]> {
    // this.url = sessionStorage.getItem('url');
    return this.http.post<any[]>(this.way + '/ventas/devfactura/83baebb1aefbd80', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getDetDevF(devolucionCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/83bad775aefbd80', {
      elementos: {
        ENCDVF_NUMERO: devolucionCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  guardarENCDEV(devEnc, numero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/10f2b6d56dd763000', {
      elementos: {
        p_ENCDVF_numero: numero,
        p_ENCDVF_numero_1: devEnc.ENCDVF_NUMERO,
        p_COM_codigo_2: '01',
        p_ENCDVF_fechaemision_3: devEnc.ENCDVF_FECHAEMISION,
        p_ENCDVF_fechaentrega_4: devEnc.ENCDVF_FECHAENTREGA,
        p_ENCDVF_estado_5: devEnc.ENCDVF_ESTADO,
        p_CLI_codigo_6: devEnc.CLI_CODIGO,
        p_CLI_nombre_7: devEnc.CLI_NOMBRE,
        p_BOD_codigo_8: devEnc.BOD_CODIGO,
        p_VEN_codigo_9: devEnc.VEN_CODIGO,
        p_ENCFFA_codigo: devEnc.ENCFFA_CODIGO,
        p_ENCDVF_formapago_10: devEnc.ENCDVF_FORMAPAGO,
        p_ENCDVF_referencia: devEnc.ENCDVF_REFERENCIA,
        p_ENCDVF_tipo_11: devEnc.ENCDVF_TIPO,
        p_ENCDVF_lista_12: devEnc.ENCDVF_LISTA,
        p_ENCDVF_fechavencimiento_13: devEnc.ENCDVF_FECHAVENCIMIENTO,
        p_ENCDVF_total_14: Number(devEnc.ENCDVF_TOTAL),
        p_ENCDVF_totalneto: Number(devEnc.ENCDVF_TOTALNETO),
        p_ENCDVF_porceiva_15: Number(devEnc.ENCDVF_PORCEIVA),
        p_ENCDVF_valoriva_16: Number(devEnc.ENCDVF_VALORIVA),
        p_ENCDVF_porcecar_17: Number(devEnc.ENCDVF_PORCECAR),
        p_ENCDVF_valorcar_18: Number(devEnc.ENCDVF_VALORCAR),
        p_ENCDVF_porcertc_19: Number(devEnc.ENCDVF_PORCERTC),
        p_ENCDVF_valorrtc_20: Number(devEnc.ENCDVF_VALORRTC),
        p_ENCDVF_porcedes_21: Number(devEnc.ENCDVF_PORCEDES),
        p_ENCDVF_valordes_22: Number(devEnc.ENCDVF_VALORDES),
        p_ENCDVF_valordev_23: Number(devEnc.ENCDVF_VALORDEV),
        p_ENCDVF_porceint_26: Number(devEnc.ENCDVF_PORCEINT),
        p_ENCDVF_valorint_27: Number(devEnc.ENCDVF_VALORINT),
        p_ENCDVF_porcerec_28: Number(devEnc.ENCDVF_PORCEREC),
        p_ENCDVF_valorrec_29: Number(devEnc.ENCDVF_VALORREC),
        p_ENCDVF_impreso_30: devEnc.ENCDVF_IMPRESO,
        p_ENCDVF_comentario_31: devEnc.ENCDVF_COMENTARIO,
        p_ENCDVF_tipofac_32: devEnc.ENCDVF_TIPOFAC,
        p_ENCDVF_generafac_33: devEnc.ENCDVF_GENERAFAC,
        p_ENCDVF_numerodob_34: devEnc.ENCDVF_NUMERODOB,
        p_ENCDVF_direccion_35: devEnc.ENCDVF_DIRECCION,
        p_ENCDVF_telefono_36: devEnc.ENCDVF_TELEFONO,
        p_ENCDVF_rucide_37: devEnc.ENCDVF_RUCIDE,
        p_ENCDVF_chofer_38: devEnc.ENCDVF_CHOFER,
        p_ENCDVF_carro_39: devEnc.ENCDVF_CARRO,
        p_ENCDVF_placa_40: devEnc.ENCDVF_PLACA,
        p_ENCDVF_iva_41: devEnc.ENCDVF_IVA,
        p_ENCDVF_baseiva: Number(devEnc.ENCDVF_BASEIVA),
        p_ENCDVF_baseice: Number(devEnc.ENCDVF_BASEICE),
        p_ENCDVF_basecero: Number(devEnc.ENCDVF_BASECERO),
        p_ENCDVF_otros: devEnc.ENCDVF_OTROS,
        p_ENCDVF_grupo_42: devEnc.ENCDVF_GRUPO,
        p_ENCDVF_nemonico: devEnc.ENCDVF_NEMONICO,
        p_ENCDVF_serie: devEnc.ENCDVF_SERIE,
        p_ENCDVF_secactual: devEnc.ENCDVF_SECACTUAL,
        p_encfac_clidsct: devEnc.ENCDVF_CLIDSCT,
        p_usu_identificacion: this.usuario.identificacion,
        p_ENCDVF_TIPODSCTO: devEnc.ENCDVF_TIPODSCTO,
        p_ENCDVF_VALORICE: Number(devEnc.ENCDVF_VALORICE),
        p_ENCDVFP_NUMERO: devEnc.ENCDVFP_NUMERO,
        p_ENCDVF_AUTORIZACION: devEnc.ENCDVF_AUTORIZACION,
        p_ENCDVF_TIPONC: devEnc.ENCDVF_TIPONC,
        P_ENCDVF_VALORIRBPNR: devEnc.ENCDVF_VALORIRBPNR,//GSRF
      }
    }, this.confIniciales.httpOptions());
  }

  insertarDetalle(vendetDVF: Vendetdevfac): Observable<any> {
    return this.http.post(this.way + '/ventas/devfactura/95f8edf55b6bb80', {
      elementos: {
        p_DETDVF_linea: vendetDVF.DETDVF_LINEA,
        p_ENCDVF_numero_1: vendetDVF.ENCDVF_NUMERO,
        p_COM_codigo_2: '01',
        p_DETDVF_linea_3: vendetDVF.LINEA,
        p_DETDVF_tipodet_4: vendetDVF.DETDVF_TIPODET,
        p_BOD_codigo_5: vendetDVF.BOD_CODIGO,
        p_DETDVF_codigo_6: vendetDVF.DETDVF_CODIGO,
        p_DETDVF_descripcion_7: vendetDVF.DETDVF_DESCRIPCION,
        p_DETDVF_tribiva_8: vendetDVF.DETDVF_TRIBIVA,
        p_DETDVF_tribice_9: vendetDVF.DETDVF_TRIBICE,
        p_DETDVF_simbolo_10: vendetDVF.UNI_SIMBOLO,
        p_DETDVF_cantidad_11: Number(vendetDVF.DETDVF_CANTIDAD),
        p_DETDVF_precio_12: Number(vendetDVF.DETDVF_PRECIO),
        p_DETDVF_descuento_13: Number(vendetDVF.DETDVF_DESCUENTO),
        p_DETDVF_total_14: Number(vendetDVF.DETDVF_TOTAL),
        p_DETDVF_iva_15: Number(vendetDVF.DETDVF_IVA),
        p_DETDVF_ice_16: Number(vendetDVF.DETDVF_ICE),
        p_DETDVF_lista_17: vendetDVF.DETDVF_LISTA,
        p_DETDVF_baseiva_18: Number(vendetDVF.DETDVF_BASEIVA),
        p_DETDVF_baseice_19: Number(vendetDVF.DETDVF_BASEICE),
        p_DETDVF_basecero_20: Number(vendetDVF.DETDVF_BASECERO),
        p_DETDVF_porceice_21: Number(vendetDVF.DETDVF_PORCEICE),
        p_ENCFAC_numero: vendetDVF.ENCFAC_NUMERO,
        p_DETFAC_linea: vendetDVF.DETFAC_LINEA,
        p_UNI_CODIGO: vendetDVF.DETDVF_UNIDAD,
        p_detdvf_serie: vendetDVF.DETDVF_SERIAL,
        p_detdvf_precioc: Number(vendetDVF.DETDVF_PRECIOC),
        p_cen_codigo: vendetDVF.CEN_CODIGO,
        p_detfac_tiposri: vendetDVF.DETFAC_TIPOSRI,
        p_detdvf_cantidadund: Number(vendetDVF.DETDVF_CANTIDADUND),
        p_DETDVF_IRBPNR: vendetDVF.DETDVF_IRBPNR, //GSRF
        p_DETDVF_VALORIRBPNR: vendetDVF.DETDVF_VALORIRBPNR,//GSRF
        p_DETDVF_PORIVA:vendetDVF.DETDVF_PORIVA,//CDPJ
        p_TARIIVA_CODIGO:vendetDVF.TARIIVA_CODIGO,//CDPJ
        //CDPJ
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarEnc(devEnc: Venencdevfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/eacad2bab6ebb000', {
      elementos: {
        p_ENCDVF_numero_1: devEnc.ENCDVF_NUMERO,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarDetDVF(detDVF: Vendetdevfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/1d595a5756daef0000', {
      elementos: {
        p_ENCDVF_numero_1: detDVF.ENCDVF_NUMERO,
        p_DETDVF_linea_2: detDVF.DETDVF_LINEA,
        p_COM_codigo_3: '01'
      },
    }, this.confIniciales.httpOptions());
  }

  anularAsiento(asiNro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/abfaaadab9276000', {
      elementos: {
        p_ASI_nro: asiNro,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarSeriales(numDoc, origenDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/1d595a5756f8ee0000', {
      elementos: {
        TRNDOC_NUMDOC: numDoc,
        TRNDOC_TIPO: origenDoc
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarTrnDoc(numDoc, tipoDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/1d595a5756fbbc0000', {
      elementos: {
        TRNDOC_NUMDOC: numDoc,
        TRNDOC_TIPO: tipoDoc
      }
    }, this.confIniciales.httpOptions());
  }

  getEncDevolucionNum(numEncdvf): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/41dd75d8d77f0000000', {
      elementos: {
        ENCDVF_NUMERO: numEncdvf
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerClave(straccion): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/devfactura/c2faebbb6caac00', {
      elementos: {
        accion: straccion
      }
    }, this.confIniciales.httpOptions());
  }

  insertarEleTrnDoc(docNum, tipoFac, usuario, ambfe): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/95f8eabbbbbb700', {
      elementos: {
        p_trndoc_numdoc: docNum,
        p_trndoc_tipo: tipoFac,
        p_trndoc_estado: 'VA',
        p_trndoc_resultado: '',
        p_trndoc_ubicaarch: '',
        p_trndoc_secuencial: '',
        p_usuidentificacion: usuario,
        p_com_codigo: '01',
        p_trndoc_tipoamb_fe: ambfe
      },
    }, this.confIniciales.httpOptions());
  }

  reenviarCorreo(docNum: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/36e75ff256d98e0000', {
      elementos: {
        TRNDOC_NUMDOC: docNum
      }
    }, this.confIniciales.httpOptions());
  }

  anularDev(encDev): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/devfactura/55fd556daefbd40', {
      elementos: {
        ENCDVF_NUMERO: encDev
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarAsiNro(encDev): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/devfactura/a677caac95c96000', {
      elementos: {
        ENCDVF_NUMERO: encDev
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerPago(encDev): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/617d75ddbcaa18', {
      elementos: {
        ENCDVF_NUMERO: encDev
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerPago2(encDev): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/c2faebbb7954300', {
      elementos: {
        ENCDVF_NUMERO: encDev
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerPago3(encDev): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/617d75ddbcaa04', {
      elementos: {
        ENCDVF_NUMERO: encDev
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerSaldoFacturaSnc(encDev, encFac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/185f78aab5ea67000', {
      elementos: {
        ENCDVF_NUMERO: encDev,
        ENCFAC_NUMERO: encFac
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerSaldoFactura(strNumFact): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/617de2aadc3d4c00000', {
      elementos: {
        num_factura: strNumFact
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarEstFac(numFac, cliCod): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/299df2aaee754e00000', {
      elementos: {
        p_com_codigo: '01',
        p_cli_codigo: cliCod,
        p_encfac_numero: numFac,
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarKardex(numDev): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/14cef9559515b70000', {
      elementos: {
        p_encdvf_numero: numDev,
        p_com_codigo: '01',
      }
    }, this.confIniciales.httpOptions());
  }

  generarAsiento(numDev): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/20ebbb6adab9280000', {
      elementos: {
        p_ENCDVF_numero: numDev,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'NC',
        p_asi_comprobante: numDev
      }
    }, this.confIniciales.httpOptions());
  }

  getAsiento(codigo: string): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/devfactura/20eeab9275fb8', {
      elementos: {
        ENCDVF_NUMERO: codigo
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarNumeracion(encDev: Venencdevfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/a677caacafeb3800', {
      elementos: {
        p_NUM_clase: encDev.ENCDVF_GRUPO,
        p_COM_codigo: '01',
        p_NUM_docref: 'DVF',
        p_NUM_nemonico: encDev.ENCDVF_NEMONICO,
        p_NUM_serie: encDev.ENCDVF_SERIE
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarEstadoDev(estado, devolucion) {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/a677caac9dceb800', {
      elementos: {
        ENCDVF_ESTADO: estado,
        ENCDVF_NUMERO: devolucion.ENCDVF_NUMERO,
        CLI_CODIGO: devolucion.CLI_CODIGO
      }
    }, this.confIniciales.httpOptions());
  }

  encontrarEncfacEstado(strNumero): Observable<any> {
    return this.http.post(this.way + '/ventas/devfactura/edbaec7a98ee7800', {
      elementos: {
        encfac_numero: strNumero
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarCodSriDescipcion(strCondicion): Observable<any> {
    return this.http.post(this.way + '/ventas/devfactura/3b6cc379b935dc', {
      elementos: {
        condicion: strCondicion
      },
    }, this.confIniciales.httpOptions());
  }

  encontrarArtMultiunidad(strArtCodigo): Observable<any> {
    return this.http.post(this.way + '/ventas/devfactura/edab7db7abd97800', {
      elementos: {
        art_codigo: strArtCodigo
      },
    }, this.confIniciales.httpOptions());
  }


  ecCargarDatosRefe(strEncfacnumero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/c56daaee36e7b800', {
      elementos: {
        encfac_numero: strEncfacnumero
      },
    }, this.confIniciales.httpOptions());
  }

  ecVerifCantidaddev(strEncfacnumero, strlinea): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/fbb727b157eb600', {
      elementos: {
        encfac_numero: strEncfacnumero,
        detfac_linea: strlinea
      },
    }, this.confIniciales.httpOptions());
  }

  ejecutarConsultaPromise(strArtCodigo, strNumero, intLinea): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/e9b998bf3d5cf000', {
      elementos: {
        art_codigo: strArtCodigo,
        numero: strNumero,
        linea: intLinea
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getListaPrecios(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/20eed65ceab3b80000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getBodegas(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/20eeaf0d7415c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  obtenerNumeracion(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/185f6feb3b6a65000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  obtenerSaldoCliente(cliCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/185f78aab2b276000', {
      elementos: {
        CLI_CODIGO: cliCodigo,
        COM_CODIGO: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerNombreVen(venCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/c2fb7c597b77e00', {
      elementos: {
        VEN_CODIGO: venCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerEstadoReferencia(refCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/c2faee754dc6e00', {
      elementos: {
        ENCDVF_NUMERO: refCodigo
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerLogFE(docNum: string, tipoFac: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/c2faebbb75c4200', {
      elementos: {
        TRNDOC_NUMDOC: docNum,
        TRNDOC_TIPO: tipoFac
      }
    }, this.confIniciales.httpOptions());
  }

  actualizarDatosElectronicos(strNumFact): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/299d6abb8755cc', {
      elementos: {
        numero: strNumFact
      }
    }, this.confIniciales.httpOptions());
  }

  obtenerDistrito(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/c2fae6cb9ddcb80', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  obtenerRegimenRefrendo(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/185f76e86dcf77000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  obtenerTipoIExterior(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/185f5d776fb2ce000', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  guardarExportNuevo(expNuevo: Venexpodvf, clave: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/10f2b6d77199c7000', {
      elementos: {
        p_tpidclienteex: '',
        p_parterelexp: '',
        p_denoexpcli: expNuevo.DENOEXPCLI,
        p_tiporegi: expNuevo.TIPOREGI,
        p_paisefecpagogen: expNuevo.PAISEFECPAGOGEN,
        p_paisefecexp: expNuevo.PAISEFECEXP,
        p_exportacionde: expNuevo.EXPORTACIONDE,
        p_distaduanero: expNuevo.DISTADUANERO,
        p_anio: expNuevo.ANIO,
        p_regimen: expNuevo.REGIMEN,
        p_correlativo: expNuevo.CORRELATIVO,
        p_doctransp: expNuevo.DOCTRANSP,
        p_fechaembarque: expNuevo.FECHAEMBARQUE,
        p_valorfob: Number(expNuevo.VALORFOB),
        p_valorfobcomprobante: Number(expNuevo.VALORFOBCOMPROBANTE),
        p_tipoingreso: expNuevo.TIPOINGRESO,
        p_inggravadoext: expNuevo.INGGRAVADOEXT,
        p_valorimpext: Number(expNuevo.VALORIMPEXT),
        p_tipocomprobante: expNuevo.TIPOCOMPROBANTE,
        p_encdvf_numero: expNuevo.ENCDVF_NUMERO,
        p_com_codigo: '01',
        p_clave: clave,
      },
    }, this.confIniciales.httpOptions());
  }

  mostrarExportarNuevo(numero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/16c73bb77199c7000', {
      elementos: {
        ENCDVF_NUMERO: numero
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarExportNuevo(exportN: Venexpodvf): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/eacad277199c7000', {
      elementos: {
        p_encdvf_numero: exportN.ENCDVF_NUMERO,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }

  verificarCantidadDev(devEnc) {
    let dblCantFact = 0;

    if (devEnc.DETFAC_LINEA === '') {
      return;
    }


    this.ecVerifCantidaddev(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_REFERENCIA, devEnc.DETFAC_LINEA).subscribe(datcant => {
      for (const rs of datcant) {
        dblCantFact = rs.CANTIDAD;
      }

      if (dblCantFact < devEnc.DETDVF_CANTIDAD) {
        this.message.add({
          key: 'devolucion',
          severity: 'info',
          summary: 'Cantidad Inválida',
          detail: 'No se puede ingresar una cantidad mayor a la disponible en la factura'
        });
        devEnc.DETDVF_CANTIDAD = dblCantFact;
      }
    });
  }


  calcularTotal() {
    console.log(this.dblPorcIvaDevF[this.init.tabs[this.init.tabindex].indice]);
    let dblValorTotal: number;
    let dblCostoTotaldevEnc = 0;
    let dblValorIcedevEnc = 0;
    let dblBaseIcedevEnc = 0;
    let dblBaseIceTotal = 0;
    let dblValorIceTotal = 0;
    let dblBaseCeroTotal = 0;
    let dblBaseIvaTotal = 0;
    let dblValorIvaTotal = 0;
    let dblValorNeto = 0;
    let dblvalorirbpnr = 0;//GSRF

    for (let fila = 0; fila < this.detdevfacarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {

      const devEnc = this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila];

      if (devEnc.DETDVF_TIPODET !== '*') {
        if (devEnc.DETDVF_CODIGO !== '' && devEnc.DETDVF_CODIGO !== null) {
          
          //dblCostoTotaldevEnc = devEnc.DETDVF_CANTIDAD * devEnc.DETDVF_PRECIO;
          //CDPJ
          let val=10 ** this.decimalesTotalDevF;
          let total=Math.round(devEnc.DETDVF_CANTIDAD * val * devEnc.DETDVF_PRECIO) / val;
          dblCostoTotaldevEnc = Number(total.toFixed(this.decimalesTotalDevF));
          console.log('TOTALITEM',dblCostoTotaldevEnc);
          //CDPJ   
          const dblDsct = dblCostoTotaldevEnc * (devEnc.DETDVF_DESCUENTO / 100);
          dblCostoTotaldevEnc = Number(dblCostoTotaldevEnc) - Number(dblDsct);
          console.log('SR',dblCostoTotaldevEnc)
          dblCostoTotaldevEnc=this.redondearDecimales(dblCostoTotaldevEnc);
          devEnc.DETDVF_TOTAL = dblCostoTotaldevEnc; // costo total por línea de detalle
          console.log('RD',dblCostoTotaldevEnc)
          devEnc.DETDVF_TOTAL = Number(devEnc.DETDVF_TOTAL).toFixed(this.decimalesTotalDevF);
          //GSRF
          if(Number(devEnc.DETDVF_DESCUENTO) === 100){
            devEnc.DETDVF_VALORIRBPNR =  0;///GSRF
          }else{
            devEnc.DETDVF_VALORIRBPNR =  devEnc.DETDVF_CANTIDAD *
            devEnc.DETDVF_IRBPNR;///GSRF
          }
          //GSRF
          // devEnc.DETDVF_VALORIRBPNR =  devEnc.DETDVF_CANTIDAD *
          //   devEnc.DETDVF_IRBPNR;///GSRF
          // cálculo del ice por línea de detalle
          if (devEnc.DETDVF_TRIBICE !== null && devEnc.DETDVF_TRIBICE !== '') {
            if (devEnc.DETDVF_TRIBICE === 'S') { // si tributa ice
              dblBaseIcedevEnc = devEnc.DETDVF_CANTIDAD * devEnc.DETDVF_PRECIO;
              devEnc.DETDVF_BASEICE = dblBaseIcedevEnc;
              if (devEnc.DETDVF_PORCEICE > 0) {
                dblValorIcedevEnc = dblBaseIcedevEnc * devEnc.DETDVF_PORCEICE / 100;
              } else if (devEnc.DETDVF_ICE > 0) {
                dblValorIcedevEnc = devEnc.DETDVF_ICE;
              }
              devEnc.DETDVF_ICE = dblValorIcedevEnc;
            }
          }
          // càlculo del iva por línea de detalle
          let dblBaseIvadevEnc = 0;
          let dblValorIvadevEnc = 0;
          if (devEnc.DETDVF_TRIBIVA === 'S') {
            dblBaseIvadevEnc = dblCostoTotaldevEnc * (1 - Number(this.dblPorcDescGlbDevFarray[this.init.tabs[this.init.tabindex].indice]) / 100);
          } else {
            dblBaseIvadevEnc = 0;
          }
          if (devEnc.DETDVF_TRIBIVA === 'S') {
            if (devEnc.DETDVF_TRIBICE === 'S') {
              //dblBaseIvadevEnc = dblBaseIvadevEnc + devEnc.DETDVF_ICE;
              dblBaseIvadevEnc = dblBaseIvadevEnc;//GSRF
            }
            devEnc.DETDVF_BASECERO = 0;
            dblValorIvadevEnc = dblBaseIvadevEnc * Number(devEnc.DETDVF_PORIVA) / 100; // IVA 12% DEFECTO

            devEnc.DETDVF_IVA = dblValorIvadevEnc;
          } else {
            devEnc.DETDVF_BASECERO = 0;
            dblBaseIvadevEnc = 0;
            devEnc.DETDVF_BASECERO = (dblCostoTotaldevEnc * (1 - Number(this.dblPorcDescGlbDevFarray[this.init.tabs[this.init.tabindex].indice]) / 100));
            devEnc.DETDVF_IVA = 0;
          }
          devEnc.DETDVF_BASEIVA = dblBaseIvadevEnc;
        }
      }

    }
    // TOTALES DEL DOCUMENTO
    for (let fila2 = 0; fila2 < this.detdevfacarray[this.init.tabs[this.init.tabindex].indice].length; fila2++) {
      dblValorNeto = Number(dblValorNeto) + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_TOTAL);
      if (this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_TIPODET !== '*') {
        if (this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_TRIBIVA === 'S') {
          dblValorIceTotal = dblValorIceTotal + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_ICE * this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_CANTIDAD);
          dblBaseIceTotal = dblBaseIceTotal + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_BASEICE);
         //CDPJ
         dblBaseCeroTotal = dblBaseCeroTotal + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_BASECERO);
         dblBaseIvaTotal = dblBaseIvaTotal + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_BASEIVA);
         dblValorIvaTotal = dblValorIvaTotal + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_IVA);

        //CDPJ
        }
        //CDPJ
        dblvalorirbpnr = dblvalorirbpnr + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_VALORIRBPNR);//GSRF
        dblBaseCeroTotal = dblBaseCeroTotal + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_BASECERO);
        //CDPJ
      }
      // dblBaseCeroTotal = dblBaseCeroTotal + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_BASECERO);
      // dblBaseIvaTotal = dblBaseIvaTotal + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_BASEIVA);
      // dblValorIvaTotal = dblValorIvaTotal + Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila2].DETDVF_IVA);
    }
    for (let fila = 1; fila <= this.detdevfacarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDVF_CANTIDAD = Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDVF_CANTIDAD).toFixed
        (this.decimalesCantidadDevF);
      this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDVF_PRECIO = Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDVF_PRECIO).toFixed(this.decimalesPrecioDevF);
      this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDVF_TOTAL = Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDVF_TOTAL).toFixed(this.decimalesTotalDevF);
      this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDVF_DESCUENTO = Number(this.detdevfacarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETDVF_DESCUENTO).toFixed(this.decimalesDescDVF);
    }
    //dblValorTotal = Number(dblvalorirbpnr)+ Number(dblValorNeto) + Number(dblValorIvaTotal) + Number(dblValorIceTotal) - Number(this.dblValorDescGlbDevFarray[this.init.tabs[this.init.tabindex].indice]);
    dblValorTotal = Number(dblvalorirbpnr) + Number(dblValorNeto) + Number(dblValorIvaTotal.toFixed(this.confIniciales.getNumDecSist())) + Number(dblValorIceTotal.toFixed(this.confIniciales.getNumDecSist())) - Number(this.dblValorDescGlbDevFarray[this.init.tabs[this.init.tabindex].indice]);
    console.log('Vneto',dblValorNeto)
      console.log('vIva',dblValorIvaTotal)
      console.log('descglobal',this.dblValorDescGlbDevFarray[this.init.tabs[this.init.tabindex].indice]);
      
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTALNETO = String(dblValorNeto);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTALNETO = Number(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTALNETO).toFixed
      (this.confIniciales.getNumDecSist());
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORIVA = String(dblValorIvaTotal);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORIVA =
      Number(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORICE = String(dblValorIceTotal);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORICE =
      Number(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORICE).toFixed(this.confIniciales.getNumDecSist());
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEICE = String(dblBaseIceTotal);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEICE =
      Number(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEICE).toFixed(this.confIniciales.getNumDecSist());
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASECERO = String(dblBaseCeroTotal);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASECERO =
      Number(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASECERO).toFixed(this.confIniciales.getNumDecSist());
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEIVA = String(dblBaseIvaTotal);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEIVA =
      Number(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTAL = String(dblValorTotal);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTAL =
      Number(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTAL).toFixed(this.confIniciales.getNumDecSist());
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_PORCEDES = this.dblPorcDescGlbDevFarray[this.init.tabs[this.init.tabindex].indice];
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_PORCEDES =
      Number(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_PORCEDES).toFixed(this.confIniciales.getNumDecSist());
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORDES = this.dblValorDescGlbDevFarray[this.init.tabs[this.init.tabindex].indice];
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORDES =
      Number(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORDES).toFixed(this.confIniciales.getNumDecSist());
      this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORIRBPNR =
      Number(dblvalorirbpnr).toFixed(this.confIniciales.getNumDecSist());
      // colocar los valores en los labels del pie de la factura

    this.comas();
  }
  //CDPJ
  redondearDecimales(num) {
    let val=0
    console.log(num);
    val=(Math.round((num + Number.EPSILON) *100)/100);
    val=Number(Number(val).toFixed(this.decimalesTotalDevF));
    console.log(val.toFixed(2))
    //console.log('>><<<<<',total)
    return val;
  }
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  validarcencodigo(cencodigo) {

    return this.http.post<any[]>(this.way + '/ventas/guiaremision/1f5564d56d8ebf0000000000000', {
      elementos: {
        cen_codigo: cencodigo,
        con_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();

  }
  validartipocodsri(cod) {

    return this.http.post<any[]>(this.way + '/ventas/devfactura/faab26a9986f68000000', {
      elementos: {
        codsri: cod,
        
      }
    }, this.confIniciales.httpOptions()).toPromise();

  }
  obtenerNumFE(encgrupo) {

    return this.http.post<any[]>(this.way + '/ventas/guiaremision/c2faebbb77f5a00', {
      elementos: {
        num_fe: encgrupo,
        
      }
    }, this.confIniciales.httpOptions()).toPromise();
  
  }
  leerValidarRucsFacElec() {

    return this.http.post<any[]>(this.way + '/seg/compania/198bf3d5eefcce000000000', {
      elementos: {},
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions()).toPromise();;
  }
  countSacimaeobj(strDialogo, strObjeto, strUsuid, intPestaniaCols): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/198f5fb653a1e90000', {
      elementos: {
        Dialogo: strDialogo,
        Objeto: strObjeto,
        Usuidentificacion: strUsuid,
        intPestaniaCol: intPestaniaCols
      },
    }, this.confIniciales.httpOptions());
  }
  verificarPermisosSuperv(strDialogo, strObjeto, strUsuid, strUsuario, intPestaniaCols): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/1f76e4caabfcf6000', {
      elementos: {
        Dialogo: strDialogo,
        Objeto: strObjeto,
        Usuidentificacion: strUsuid,
        Usuariosup: strUsuario,
        intPestaniaCol: intPestaniaCols
      },
    }, this.confIniciales.httpOptions());
  }
  countSacimaeObjper(strUsuario): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/compras/factproveedores/cc5fb653b0b9e800', {
      elementos: {
        USU_IDENTIFICACIONSUP: strUsuario
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarEstadoDocumento(strNumero): Observable<any> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/299df2ab2', {
      elementos: {
        ENCDVF_NUMERO: strNumero
      }
    }, this.confIniciales.httpOptions());
  }
  obtenerEstFE(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/198bf3d5eab6ee0000', {
      elementos: {   
      }
    }, this.confIniciales.httpOptions());
  }
  obtenerbasesiva(numero) {
    return this.http.post<any>(this.way + '/ventas/devfactura/83bab571dc97d800000000000', {
      elementos: {
        p_encdvf_numero:numero
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizabasesiva(numero) {
    return this.http.post<any>(this.way + '/ventas/devfactura/14ceb7ef5ab8ee0000', {
      elementos: {
        p_encdvf_numero:numero,
        p_com_codigo:'01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getEncdvf(encfacnumero): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/devfactura/185f5d776dd763000', {
      elementos: {
        p_Tipo: 'R',
        p_Cadena: '',
        p_condicion: 'ENCDVF_NUMERO=\'' + encfacnumero + '\''
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  seleccionarRegistro(tipo, cadena, condicion): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/devfactura/1c755cc64b17dc00000000', {
      elementos: {
        p_tipo: tipo,
        p_Cadena: cadena,
        p_condicion: condicion
      }
    }, this.confIniciales.httpOptions());
  }
  //CDPJ


  comas() {
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTALNETO = this.init.moneyValidation(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTALNETO);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_PORCEDES = this.init.moneyValidation(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_PORCEDES);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORDES = this.init.moneyValidation(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORDES);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEIVA = this.init.moneyValidation(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEIVA);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORIVA = this.init.moneyValidation(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORIVA);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEICE = this.init.moneyValidation(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASEICE);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORICE = this.init.moneyValidation(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORICE);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASECERO = this.init.moneyValidation(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_BASECERO);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTAL = this.init.moneyValidation(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_TOTAL);
    this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORIRBPNR = this.init.moneyValidation(this.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_VALORIRBPNR);
  }

  existenciaPorBodega(artCodigo, artPreCodigo, strFecha, strHora, iva) {
    strFecha = this.datePipe.transform(strFecha, 'dd/MM/yyyy');
    strHora = this.datePipe.transform(strHora, 'hh:mm');
    iva = Number(iva) / 100;

    return this.http.post<any[]>(this.way + '/ventas/devfactura/77192e75786ab80', {
      elementos: {
        art_codigo: artCodigo,
        pre_codigo: artPreCodigo,
        fecha: strFecha,
        hora: strHora,
        valor_iva: iva
      }
    }, this.confIniciales.httpOptions()
    ).toPromise();

  }

  obtenerImgArticulo(strArtCodigo) {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/83bb2b2a0adf680', {
      elementos: {
        art_codigo: strArtCodigo
      },
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  leerConfiguracionDatosElectronicos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/2ymq2d11hk53thr', {
      elementos: {},
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions());
  }
  //GSRF
  ingDatosAdicCli(strNumFact): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/devfactura/9r95a5wzo6ncq24dv', {
      elementos: {
        p_encdvf_numero: strNumFact,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  consultarInfoAdicional(numfac): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/devfactura/1nxmeb5alo0onj6dv', {
      elementos: {},
      datos: {
        NumFac: numfac
      }
    }, this.confIniciales.httpOptions());
  }

  guardarInfoAdicFac(infoadicfac): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/devfactura/j5bmzb65bi9a8gudv', {
      elementos: {
        p_encdvf_numero: infoadicfac.ENCDVF_NUMERO,
        p_com_codigo: '01',
        p_infad_numsec: infoadicfac.INFAD_NUMSEC,
        p_infad_codigo: infoadicfac.INFAD_CODIGO,
        p_infad_descripcion: infoadicfac.INFAD_DESCRIPCION
      }
    }, this.confIniciales.httpOptions());
  }
  eliminarInfoAdicFac(infoadicfac): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/devfactura/srcu3g9hr84wjhydv', {
      elementos: {
        p_encdvf_numero: infoadicfac.ENCDVF_NUMERO,
        p_com_codigo: '01',
        p_infad_numsec: infoadicfac.INFAD_NUMSEC,
      }
    }, this.confIniciales.httpOptions());
  }
//GSRF

}
