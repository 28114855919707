import { AfterContentInit, AfterViewInit, Component, NgZone, OnInit, ViewChild } from '@angular/core';

import { UsuarioEsquema } from '../usuarioEsquema';
import { Usuario } from '../usuario';
import { UsuarioDescargas } from '../usuarioDescargas';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BackendService } from '../servicios/backend.service';
import { IpServiceService } from '../ip-service.service';
import { UtilitariosService } from '../advantage/advantageservices/utilitarios.service';
import { ConfInicialesService } from '../servicios/conf-iniciales.service';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  providers: [MessageService],
  styleUrls: ['./app.login.component.css'],
})
export class AppLoginComponent implements OnInit {
  ipFrontend = '127.0.0.1';
  ipAddress = '127.0.0.1';
  hostname = 'localhost';
  iplocal = '192.168.1.1';
  mac = '00:00:00:00:00:00';
  domain = 'domain';

  public tipologin: any;
  empresasel: any;
  nombreempresa: any;
  empresas: any;
  empresasContador: any;
  url: any;
  way: any;
  timerdata: any;
  usuario: string;
  pass: string;
  usuariores: any;
  public loginsel = 0; // permite saber que tipo de login es 0: ninguno; 1: Descargas, 2: Soy Empresa y 3: Soy contador
  public loginactivo = true;

  //  para el login de contador
  idcontador: string; // el usuario del contador para desplegar las empresas que tiene
  passcontador: string; // la contraseña del contador
  existecontador = false;

  // para login descargas
  public idDescargas: string;
  public passDescargas: string;

  // para la captcha
  public captchaexiste = false;
  public secretKeyCaptcha = '';
  private token = '';

  //test empresas end point
  resultado = '';
  ruc = '';
  servicio = '';
  user = '';

  imagenes: any[];
  displayDescargas: boolean;
  displayEmpresa: boolean;
  displayEndpoint: boolean;
  displayToken: boolean;
  private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);

  tokenusuario = '';
  auxtoken = '';

  items: MenuItem[];
  activeItem: MenuItem;

  titulopestania = '';
  //GSRF
  displayAcciones: boolean;
  displayTtyc: boolean;
  item1: boolean;
  item2: boolean;
  sacim: boolean;
  //GSRF
   // mensaje 1
   part1 = 'Optimiza el manejo de tus inventarios con la ';
   part2 = 'plataforma líder en el mercado.';
   // mensaje 2
   part3 = 'Impulsa la eficiencia en la gestión comercial de tu empresa personalizando ';
   part4 = 'los procesos a tu medida.';
   // mensaje 3
   part5 = 'Optimiza la logística de tu empresa con un ERP personalizado a tu medida, ';
   part6 = 'adaptado específicamente a las necesidades de tu organización.';
   // mensaje 4
   part7 = 'Impulsa la eficiencia en la gestión comercial de tu empresa al personalizar ';
   part8 = 'los procesos según tus necesidades específicas.';
   // mensaje 5
   part9 = 'No se trata solo de datos, se trata de descubrir oportunidades. ';
   part10 = 'Descubre cómo nuestra plataforma de inteligencia de negocios puede llevar tu empresa al siguiente nivel.';
   // mensaje 6
   part11 = 'Transforma tu manera de hacer negocios: adopta nuestra plataforma ERP ';
   part12 = 'y lleva la eficiencia y la organización de tu empresa a un nuevo nivel.';
 
  part13 = 'productos y servicios con';
  part14 = 'SaciERP es la mejor manera';
  part15 = 'de llevar el control de los';
  part16 = 'activos de tu empresa.';
  part17 = '!Contáctanos¡ ';

  // Temporizador
  public timeLeft = 60;
  public control = 1;
  interval;
  displayTimer: any;
  numIntentos = 0;
  timerInterval: any;

  constructor(
    private http: HttpClient,
    private ip: IpServiceService,
    private usuarioEsquema: UsuarioEsquema,
    public route: Router,
    public usuarioGlob: Usuario,
    public usuarioDescargas: UsuarioDescargas,
    public service: MessageService,
    public backService: BackendService,
    private utilitariosService: UtilitariosService,
    private confirmationService: ConfirmationService,
    private zone: NgZone,
    private confIniciales: ConfInicialesService
  ) {
    this.tipologin = [
      {
        id: 1,
        nombre: 'Descargas'
      },
      {
        id: 2,
        nombre: 'Soy Empresa'
      },
      {
        id: 3,
        nombre: 'Soy Contador'
      }
    ];
    
  }

  async ngOnInit() {
    this.displayDescargas = false;
    this.displayEmpresa = false;
    //GSRF
    this.item1=true;
    this.item2=false;
    this.displayTtyc=false;
    //GSRF
    this.confIniciales.msgclosereload = true;
    this.usuarioEsquema.clearStorage();
    this.utilitariosService.saveStorageMarca('SACIERP', 100);
    sessionStorage.setItem('version', JSON.stringify({
      Version: '17',
      Revision: '22',
      Fecha: '17/10/2024'
    }));

    await this.backService.getConfigJson();
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
    this.timerdata = sessionStorage.getItem('timer');
    if (isNaN(this.timerdata)) {
      this.timerdata = 2;
    }
    this.utilitariosService.refrescarURL();

    this.items = [];

    const valor = await this.utilitariosService.esmarcasaci();
    //GSRF
    if (valor === true) {
      this.sacim=true;
      this.items.push(
        {
          label: 'Ingreso a SACIERP\n',
          icon: 'pi pi-user',
          id:'item1',
          command: (event) => {
            this.openLogin(2);
          }
        }, {
          label: 'Descargar Documentos\n',
          icon: 'pi pi-user',
          id: 'item2',
          command: (event) => {
            this.openLogin(1);
          }
        },{
        label: 'Aula Virtual',
        icon: 'pi pi-user',
        command: (event) => {
          this.openLogin(3);
        }
      }, {
        label: 'Soporte Técnico',
        icon: 'pi pi-user',
        command: (event) => {
          this.openLogin(4);
        }
      });
      this.imagenes = [
        {
          image: 'banner1.png',
          name: '1'
        },
        {
          image: 'banner2.png',
          name: '2'
        },
        {
          image: 'banner3.png',
          name: '3'
        },
        {
          image: 'banner4.png',
          name: '4'
        },
        {
          image: 'banner5.png',
          name: '5'
        },
        {
          image: 'banner6.png',
          name: '6'
        }
      ];
    } else {
      this.sacim=false;
      this.items.push({
        label: 'Ingresar\n',
        icon: 'pi pi-user',
        id:'item1',
        command: (event) => {
          this.openLogin(2);
        }
      }, {
        label: 'Descargar Documentos\n',
        icon: 'pi pi-user',
        id: 'item2',
        command: (event) => {
          this.openLogin(1);
        }
      });
      // mensaje 1
      this.part1 = '';
      this.part2 = '';
      this.part3 = '';
      this.part4 = '';
      this.part5 = '';
      this.part6 = '';
      // mensaje 2
      this.part7 = '';
      this.part8 = '';
      this.part9 = '';
      this.part10 = '';
      this.part11 = '';
      // mensaje 3
      this.part12 = '';
      this.part13 = '';
      this.part14 = '';
      this.part15 = '';
      this.part16 = '';
      this.part17 = '';
      this.imagenes = [
        {
          image: 'banner1.jpg',
          name: '1'
        },
        {
          image: 'banner2.jpg',
          name: '2'
        },
        {
          image: 'banner3.jpg',
          name: '3'
        }
      ];
    }
    //GSRF

    this.activeItem = this.items[0];


    this.getServer(this.way + '/esquemas').subscribe(respuesta => {
      this.empresas = respuesta;
    }, error => {
      this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Refresque la página' });
    });

    this.cargarCaptcha();

    try {
      const res: any = await this.ip.getIPAddresshttps();
      if (res.ip !== undefined) {
        this.ipFrontend = res.ip;
      }
    } catch (e) {
      console.log(e);
    }


    try {
      const data = await this.utilitariosService.hostname();
      this.hostname = data['hostname'];
      this.utilitariosService.saveStorageIp(this.hostname, this.ipFrontend);
    } catch (e) { }

  }

  showResponse(event: { response: string; }) {
    console.log(event.response);
    this.token = event.response;
  }

  getServer(peticion: string): Observable<any> {
    return this.http.get(peticion);
  }

  getlogotipo() {
    this.utilitariosService.getLogotipo().subscribe(data => {
      for (const rs of data) {
        sessionStorage.setItem('logotipo', rs.COM_LOGO);
      }
    });
  }

  // login para Empresas
  async login() {
    if (this.nombreempresa === undefined) {
      this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Digite su Empresa' });
      this.back();
    } else {
      if (this.usuario === undefined) {
        this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Ingrese su usuario y contraseña' });
        this.back();
      } else {
        // se verifica si existe la empresa
        this.nombreempresa = this.nombreempresa.toUpperCase();
        const auxempresa = this.empresas.find((emp: { EMPRESA_NOMBRE: any; }) => emp.EMPRESA_NOMBRE === this.nombreempresa);
        // se verifica la empresa
        if (auxempresa === undefined) {
          this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'La empresa no existe' });
          this.back();
        } else {
          this.usuarioEsquema.esquema = auxempresa.EMPRESA_ESQUEMA;
          this.usuarioEsquema.usuario = auxempresa.EMPRESA_USUARIO;
          this.usuarioEsquema.password = auxempresa.EMPRESA_PASSWORD;
          this.empresasel = auxempresa.EMPRESA_ESQUEMA;
          this.usuarioEsquema.saveStorage();
          this.http.post(this.way + '/esquemas/7b9e56e58', {
            usuario: this.usuario,
            password: this.pass,
            esquema: this.usuarioEsquema.esquema,
            key: this.token // verifica la captcha enviada si esta desactivada no lo toma en cuenta
          }, { withCredentials: true }).subscribe(async respuesta => {
            // se obtiene el logotipo de la empresa
            const aux: any = respuesta;
            if (aux.length === 0) {
              // this.snackbar.open('Usuario o contraseña incorrecta', 'cerrar', {duration: 3000});
              this.service.add({
                severity: 'error', summary: 'Usuario o Contraseña Incorrecta',
                detail: 'Es posible que el usuario haya caducado. Intente conectarse nuevamente.'
              });
              this.back();
            } else {
              this.usuariores = respuesta;
              let enusu = 0;
              let usuarioactivo = 'N';
              for (const i of this.usuariores) {
                enusu = i.USU_ENUSO;
                this.usuarioGlob.login = true;
                this.usuarioGlob.nombre = i.USUNOMBRE;
                this.usuarioGlob.apellido = i.USUAPELLIDO;
                this.usuarioGlob.identificacion = i.USUIDENTIFICACION;
                this.usuarioGlob.ruc = i.USURUCI;
                this.usuarioGlob.usuperfil = i.USUPERFIL;
                this.usuarioGlob.codperfil = i.PERFIL_CODIGO;
                this.usuarioGlob.ipfrontend = i.USU_IPPUBLICA;
                this.usuarioGlob.autenticacion = i.USUAUTENTICACION;
                this.usuarioGlob.saveStorage();
                this.utilitariosService.updateDatosIps(this.ipFrontend, this.hostname, 1, this.usuario);
                this.utilitariosService.timeLeft = 5 * 60;
              }
              // se realiza consulta a la tabla sacijava para verificar si esta activo el usuario o si es nuevo
              this.http.post(this.way + '/sesiones/533b2fe38ee4b00', {
                USUIDENTIFICACION: this.usuario,
                ESQUEMA: this.usuarioEsquema.usuario
              }, { withCredentials: true }).subscribe(respuestaactivo => {
                const auxact: any = respuestaactivo;
                if (auxact === 'usuario nuevo') {
                  this.sacianex();
                } else {
                  usuarioactivo = auxact[0].ACTIVO;
                  if (usuarioactivo === 'S') {
                    this.confirmationService.confirm({
                      message: 'Existe una sesión activa con el usuario ' + this.usuarioGlob.nombre + ' '
                        + this.usuarioGlob.apellido + ' en "' + this.usuarioGlob.ipfrontend + '" Host "' + this.hostname +
                        '", está seguro de cerrar la sesión?',
                      header: 'Pregunta',
                      icon: 'pi pi-exclamation-triangle',
                      key: 'confirmlogin',
                      acceptLabel: 'Si',
                      rejectLabel: 'No',
                      accept: async() => {
                        ///GSRF
                        console.log(auxempresa.EMPRESA_NOMBRE);
                        // if(auxempresa.EMPRESA_NOMBRE ==='D' || auxempresa.EMPRESA_NOMBRE ==='DEMO' 
                        // || auxempresa.EMPRESA_NOMBRE ==='FRAS' || auxempresa.EMPRESA_NOMBRE ==='1721288221001'
                        // || auxempresa.EMPRESA_NOMBRE==='1712117975001' || auxempresa.EMPRESA_NOMBRE==='1793143865001'
                        // || auxempresa.EMPRESA_NOMBRE==='1708190911001' || auxempresa.EMPRESA_NOMBRE==='1706076104001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1704598513001' || auxempresa.EMPRESA_NOMBRE ==='2290352080001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1793168833001' || auxempresa.EMPRESA_NOMBRE ==='0925395733001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1791856910001' || auxempresa.EMPRESA_NOMBRE ==='1791323548001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1792326222001' || auxempresa.EMPRESA_NOMBRE ==='1793031331001'
                        // || auxempresa.EMPRESA_NOMBRE ==='0993373823001' || auxempresa.EMPRESA_NOMBRE ==='1792155495001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1710095108001' || auxempresa.EMPRESA_NOMBRE ==='1792088674001'
                        // || auxempresa.EMPRESA_NOMBRE ==='0992149213001' || auxempresa.EMPRESA_NOMBRE ==='1793082025001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1793095518001' || auxempresa.EMPRESA_NOMBRE ==='1801982636001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1792014778001' || auxempresa.EMPRESA_NOMBRE ==='0993252921001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1792581532001' || auxempresa.EMPRESA_NOMBRE ==='1002157871001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1792363640001' || auxempresa.EMPRESA_NOMBRE ==='1792506816001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1792028272001' || auxempresa.EMPRESA_NOMBRE ==='1791264169001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1706728944001' || auxempresa.EMPRESA_NOMBRE ==='1714402490001'
                        // || auxempresa.EMPRESA_NOMBRE ==='0992937238001' || auxempresa.EMPRESA_NOMBRE ==='1791050487001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1792788862002' || auxempresa.EMPRESA_NOMBRE ==='1793203069001'
                        // || auxempresa.EMPRESA_NOMBRE ==='1792902630001' || auxempresa.EMPRESA_NOMBRE ==='0890039278001'
                        // || auxempresa.EMPRESA_NOMBRE ==='ACCESSROSS' || auxempresa.EMPRESA_NOMBRE ==='CONSULTORA'
                        // || auxempresa.EMPRESA_NOMBRE ==='CRIBRYAR' || auxempresa.EMPRESA_NOMBRE ==='BARLA'
                        // || auxempresa.EMPRESA_NOMBRE ==='MARIGALDI' || auxempresa.EMPRESA_NOMBRE ==='CARSHOP'
                        // || auxempresa.EMPRESA_NOMBRE ==='AUDASA' || auxempresa.EMPRESA_NOMBRE ==='MULTIFASHION'
                        // || auxempresa.EMPRESA_NOMBRE ==='AUTOMAXGYE' || auxempresa.EMPRESA_NOMBRE ==='FRASCOSA'
                        // || auxempresa.EMPRESA_NOMBRE ==='VERONSA' || auxempresa.EMPRESA_NOMBRE ==='AUTOMAX'){
                          const essaci = await this.utilitariosService.esmarcasaci();
                           if(essaci === true){
                            console.log('aqui aws --->', auxempresa.EMPRESA_NOMBRE);
                            this.utilitariosService.enviarCorreoTokenaws(this.empresasel, this.usuario).subscribe(data => {
                              console.log('------>');
                              console.log(data);
                            if (data === false) {
                              this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
                              return;
                            } else {
                              if (this.usuario === 'admin' || this.usuarioGlob.autenticacion === 1) {
                                this.auxtoken = 'usuadmin'
                                this.timer(this.timerdata);
                                this.displayToken = true;
                              } else {
                                this.auxtoken = 'sesionactiva'
                                this.timer(this.timerdata);
                                this.displayToken = true;
                              }
                              this.service.add({ key: 'toast', severity: 'success', summary: 'Correo Enviado', detail: 'Verifique su correo: ' + data });
                            }
                          });
                        }
                        // else if(auxempresa.EMPRESA_NOMBRE ==='1792326222001'){
                        //   console.log('aqui normal --->', auxempresa.EMPRESA_NOMBRE);
                        //   this.utilitariosService.enviarCorreoToken(this.empresasel, this.usuario).subscribe(data => {
                        //     if (data === false) {
                        //       this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
                        //       return;
                        //     } else {
                        //       if (this.usuario === 'admin' || this.usuarioGlob.autenticacion === 1) {
                        //         this.auxtoken = 'usuadmin'
                        //         this.timer(this.timerdata);
                        //         this.displayToken = true;
                        //       } else {
                        //         this.auxtoken = 'sesionactiva'
                        //         this.timer(this.timerdata);
                        //         this.displayToken = true;
                        //       }
                        //       this.service.add({ key: 'toast', severity: 'success', summary: 'Correo Enviado', detail: 'Verifique su correo: ' + data });
                        //     }
                        //   });
                        // } 
                        else{
                          this.sacianex();
                        }
                      }
                      //GSRF
                      ,reject: ()=>{
                        this.back()
                        //this.cargarCaptcha();
                      }
                    });
                  } else {
                    this.sacianex();
                  }
                }
              }, error => {
                this.service.add({ severity: 'error', summary: 'Error /activosesion', detail: error.error });
                // this.snackbar.open('Error al conectarse a la base de datos', 'cerrar', {duration: 3000});
              });
            }
          }, error => {
            this.service.add({ severity: 'error', summary: 'Error de base de datos', detail: error.error });
            // this.snackbar.open('Error al conectarse a la base de datos', 'cerrar', {duration: 3000});
          });
        }
      }
    }
  }

  loginwithEnter(event: any) {
    // console.log(event.keyCode);
    if (event.keyCode === 13) {
      this.login();
    }
  }

  loginwithEnterDescargas(event: any) {
    // console.log(event.keyCode);
    if (event.keyCode === 13) {
      this.loginDescargas();
    }
  }

  async verificarIpPublica() {
    const valor = await this.utilitariosService.esmarcasaci();
    if (valor === true) {
      // permite todas las conexiones
      this.http.post(this.way + '/conformacion/83baa63b3d66300', {
        elementos: {}
      }, this.confIniciales.httpOptions()).subscribe(resp => {
        let datos: any = resp;
        let conexion;
        for (const data of datos) {
          conexion = data.COM_CONEXION === '1';
        }
        if (conexion === true) {
          this.iniciaElSistema();
        } else {
          // contar conexiones permitidas
          this.http.post(this.way + '/conformacion/41dd663d7ecb3c', {
            elementos: {}
          }, this.confIniciales.httpOptions()).subscribe(resp2 => {
            let datos2: any = resp2;
            let count = 0;
            for (const data of datos2) {
              count = Number(data.IPS);
            }
            if (count === 0) {
              this.iniciaElSistema();
            } else {
              // verificar conexion existente
              this.http.post(this.way + '/conformacion/83bb2ce7cbac980', {
                elementos: { ip: this.ipFrontend }
              }, this.confIniciales.httpOptions()).subscribe(resp3 => {
                let datos3: any = resp3;
                if (datos3.length > 0) {
                  this.iniciaElSistema();
                } else {
                  this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Acceso NO autorizado! Comuníquese con el administrador.' });
                  this.confIniciales.cerrarSesion();
                  this.utilitariosService.enviarCorreoAccesoIp(this.ipFrontend, this.empresasel, this.usuario).subscribe();
                }
              });
            }
          });
        }
      });
    } else {
      this.iniciaElSistema();
    }
  }

  iniciaElSistema() {
    this.route.navigate(['']);
  }

  async enterToken(event: any) {
    if (event.keyCode === 13) {
      this.numIntentos++;
      if (this.numIntentos <= 2) {
        const resp = await this.utilitariosService.validarToken(this.tokenusuario, this.usuarioGlob.identificacion);
        if (resp === true) {
          if (this.auxtoken === 'usuadmin') {
            this.auxtoken = "tokenverificado";
          }
          this.sacianex();
        } else {
          this.service.add({ key: 'toast', severity: 'error', summary: 'Error de Ingreso', detail: 'Token ingresado no coincide. Verifique su correo' });
        }
      } else {
        this.displayToken = false;
        this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Excedió el número de intentos permitidos' });
        this.numIntentos = 0;
      }

    }
  }

  async aceptarToken() {
    this.numIntentos++;
    if (this.numIntentos <= 2) {
      const resp = await this.utilitariosService.validarToken(this.tokenusuario, this.usuarioGlob.identificacion);
      if (resp === true) {
        if (this.auxtoken === 'usuadmin') {
          this.auxtoken = "tokenverificado";
        }
        this.sacianex();
      } else {
        this.service.add({ key: 'toast', severity: 'error', summary: 'Error de Ingreso', detail: 'Token ingresado no coincide. Verifique su correo' });
      }
    } else {
      this.displayToken = false;
      this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Excedió el número de intentos permitidos' });
      this.numIntentos = 0;
    }

  }

  cancelarToken() {
    this.displayToken = false;
    //GSRF
    this.back();
    //this.cargarCaptcha();
  }

  // Login para Descargas
  loginDescargas() {
    if (this.idDescargas === undefined) {
      this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'Ingrese su ID y contraseña' });
      // this.snackbar.open('Ingrese su usuario y contraseña', 'cerrar', {duration: 3000});
    } else {
      this.usuarioEsquema.esquema = 'SACIANEX';
      this.usuarioEsquema.usuario = 'SACIANEX';
      this.usuarioEsquema.saveStorage();

      this.http.post(this.way + '/esquemas/f73cadcb0d7718000000', {
        usuario: this.idDescargas,
        password: this.passDescargas,
        esquema: this.usuarioEsquema,
        key: this.token // verifica la captcha enviada si esta desactivada no lo toma en cuenta
      }).subscribe(respuesta => {
        // console.log(respuesta);
        const aux: any = respuesta;
        if (aux.length === 0) {
          // this.snackbar.open('Usuario o contraseña incorrecta', 'cerrar', {duration: 3000});
          this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'ID o Contraseña Incorrecta' });
        } else {
          this.usuariores = respuesta;
          for (const i of this.usuariores) {
            this.usuarioDescargas.login = true;
            this.usuarioDescargas.CLI_RUCIDE = i.CLI_RUCIDE;
            this.usuarioDescargas.CLI_NOMBRE = i.CLI_NOMBRE;
            this.usuarioDescargas.CLI_CORREO = i.CLI_CORREO;
            this.usuarioDescargas.saveStorage();
          }
          // console.log('usuario correto');
          this.route.navigate(['descargas']);
          const el = document.documentElement;
          // el.requestFullscreen(); //enra en modo pantalla completa
        }
      }, error => {
        this.service.add({ severity: 'error', summary: 'Error de base de datos', detail: error.error });
        // this.snackbar.open('Error al conectarse a la base de datos', 'cerrar', {duration: 3000});
      });
    }
  }

  async openLogin(opcion: number) {
    console.log('AQUI');
    this.displayAcciones = false
    this.captchaexiste = false;
    this.cargarCaptcha();
    this.loginsel = opcion;
    this.loginactivo = false;
    if (opcion === 1) {
      this.displayDescargas = true;
      this.item1=false;
      this.item2=true;
    }
    if (opcion === 2) {
      this.cargarCaptcha();
      this.displayEmpresa = true;
      this.item1=true;
      this.item2=false;
    }
    if (opcion === 3) {
      const url = 'https://www.saci-erp.com/aula-virtual';
      window.open(url);
    }
    if (opcion === 4) {
      const url = 'http://www.saci-soporte.com';
      window.open(url);
    }
    if (opcion === 5) {
      this.displayEndpoint = true;
    }
    if (document.getElementById('login-panel') !== null) {
      document.getElementById('login-panel').classList.add('login-panel-hide');
    }
  }

  back() {
    this.loginactivo = true;
    this.loginsel = 0;
    this.existecontador = false;
    this.displayDescargas = false;
    this.displayEmpresa = false;
    // resuelve si se usa captcha
    this.captchaexiste = false;
    this.cargarCaptcha();
    if (document.getElementById('login-panel') !== null) {
      document.getElementById('login-panel').classList.remove('login-panel-hide');
    }
  }

  cargarCaptcha() {
    this.http.post(this.way + '/esquemas/3159eb22a', {}).subscribe(resp => {
      const cap: any = resp;
      if (cap.captcha === true) {
        this.captchaexiste = true;
        this.secretKeyCaptcha = cap.secretKeyCliente;
      } else {
        this.captchaexiste = false;
      }
    });
  }

  loginSoyContador(event: any) {
    // console.log(event.keyCode);
    if (event.keyCode === 13) {
      // se realiza un metodo para comprobar si existe el contador y cargar los datos al formulario
      this.loginContador();
    }
  }

  loginContador() {
    // compueba si existe el contador en la base de datos y muestra las empresa.
    this.http.post(this.url + '/contador', {
      usuario: this.idcontador,
      pass: this.passcontador
    }).subscribe(respuesta => {
      console.log(respuesta);
      const aux: any = respuesta;
      if (aux === null) {
        // this.snackbar.open('Usuario o contraseña incorrecta', 'cerrar', {duration: 3000});
        this.existecontador = false;
        this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'ID o contraseña del Contador Incorrecta' });
      } else {
        // this.usuariores = respuesta;
        this.empresasContador = respuesta;
        this.existecontador = true;
        // console.log('usuario correto');
      }
    }, error => {
      console.log(error);
      this.service.add({ severity: 'error', summary: 'Error de base de datos', detail: error.error });
      // this.snackbar.open('Error al conectarse a la base de datos', 'cerrar', {duration: 3000});
    });

  }

  animacion() {
    const textWrapper = document.querySelector('.ml2');
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /\S/g, '<span class=\'letter\'>$&</span>'
    );
  }

  links(link) {
    let url;
    if (link === 'facebook') {
      url = 'https://www.facebook.com/SACIJAVA';
      window.open(url);
    }
    if (link === 'instagram') {
      url = 'https://www.instagram.com/softwarecontable_saci/';
      window.open(url);
    }
    if (link === 'twitter') {
      url = 'https://twitter.com/SaciJava';
      window.open(url);
    }
    if (link === 'youtube') {
      url = 'https://www.youtube.com/channel/UC1if0OQS3h4XRrh_CyUmi7Q/videos?view=1';
      window.open(url);
    }
    if (link === 'whatsapp') {
      url = 'https://bit.ly/2V0BWk5';
      window.open(url);
    }
  }

  testEmpresasEndPoint() {
    if (this.ruc) {
      let endPointUrl = `${this.url}/empresas/${this.ruc}/verificar`;
      endPointUrl = `${endPointUrl}?servicio=${this.servicio}&user=${this.user}`;
      this.getServer(endPointUrl).subscribe(respuesta => {
        if (respuesta.length > 0) {
          let oEmpresa = respuesta[0];
          this.resultado = `Resultado: Estado: ${oEmpresa.EMP_STD} , Forma Pago: ${oEmpresa.EMP_FP}`;
        } else
          this.resultado = '';
      });
    }
  }

  async sacianexLoginTest() {
    const respuestav = await this.confIniciales.verificarSesion(this.usuario, this.usuarioEsquema.usuario, this.ipFrontend);
    sessionStorage.setItem('session', respuestav.toString());
    this.getlogotipo();
    let ruc;
    const data = await this.confIniciales.getRuc();
    for (const rs of data) {
      ruc = rs.COMRUCI;
    }
    let conforma = '';
    if (ruc === '0991477365001') {
      conforma = 'AkKZLY6D/IjDexCSBAsa6ApjBo8LoGHuVT4mDRS3W1Z3DzXszEkcoH7zAw2Wfr6ByC9Q36nB2RfcbvdpaAQ81RWRkp2JGjFUDl+bZYOhsk1mUBB41hKgwCElREFeYFfHVpZGmqUF5oBA2AmULvpxeucEOyHjhiHWFIy1w/h8tNHaqhLtrUhyaykti55esEFMO+q35B0cTvGTlmQfD6oAgdsqzf6USIn8x7B2CYsBYuMYtTJNOo6aAMaLj0z41BnCwUVYj1+bp9tAlstMlMU//l8nAgNMMydJlp1qpej47sz9Xz/c7vIaBYr2zygjhCjC/eLMqdtj2QjwYYP79OEfNJH+1LahSD5ksnHTcHk4xMlnzUk5pJjmpPziGCHd3sevOxucmgZitOIyE3Fr5DwjrKCO5yCE1BkjEsGHKBW1ufeUCh/l3dOqBrE4kewF286Ss4lImeezT+AYbqlfAb6T3NHxAvs1PqdK3M/b8kkWPBhduda7tXcg95C3Xeql9bDBcc8LFgaqsAjp3vPRULbc/i3pXmryS4PPMFdegoBlAU5d20Ki7RxV/d7rxGpMfb4+PEYy1H2vKO8V6HTbcn5ZElF+ILD9zAe611NHZtTJgBkfWL4Qci7Zue1hb1PoenZrEQC9rGGKSim+vydqpkITB8LAgGJbU8Qm1r/lMiB3hLI='
    } else if (ruc === '1791717260001') {
      conforma = 'K7pF2ILO4pxugKfWQ+k/4SDeq3sg79PZ21o2Q1RZxr7mugo0nsK+1xeNdznG+vSJ6mFybLhfE4BeXxUwyrW+97HwEMZkcJtbz2DNSjLI1czOmziJAJ/Btth4D5Hwc4vSZB1e5lfxvE5dRHKBdo5YYkqCns7A9oa0JnEwDqXWPenLMnMnHIHEIa2sJ9ES4J85BdkCGmS2zO22WHqHtNJU3c/EhJSTuevOCebAs9A9oCXjQ2BmDIVlAbM4d4+31XlulerC2H9PtKh4kDsRr+Hmh7Jm0251yEsJDilOeMiIoq8mh6VoAX9koGSKiyb1/f2VvnuasV5MCIF8C5kP078llyUVLcFxNYtKvoQzoonw9OwhgjAZqeLIDv5ky/sKW0VPrDPHo2ZwPvggj//opI5cxBNC425wPpxTsjjGjAJWudQ+VFlxlC+XzyObOggkiGSRi9Ln9VbIUWGzWLkkteHbrpuhctdI2XfGb2FMEI3hKDjaIh5DjlRoOH2R7jrybVn8wJNcY1ROzfQNQ3RNVRVRpIQ5bDCnqnOzZuKFR6iCCMEQvONp7Lpv9oCT9TRvLC0YYo3rqnaosyLaJl8LrTZ7PrldKV9cwHtCYWYQ+jPtPQ6Ahzclk4X3pnUn2NzspyWLHrPLuVGiMx9/22gw4xRNSn8Np/GAXXeOqYVPQx7ndBk='
    } else if (ruc === '0992149213001') {
      conforma = 'KZ92WwNrBZH84anRwepjTL5XI2o3B3kN7YKUM83se8Bo0rWogTbTd3qW6ozpLj9nkOSry140qlaBNVvk57dQWhoIxLyvOgJhi3VSvtYOxsUWNqIDVvEwSnsGyzvhohPpNOYbVnlOCuS454bd+gLdKA4W38GyfD38MfjbKKlzcb1m0q4inLe+ZbAGMnFeuRL9OotpVlI0DWVkQzpK341zqx906+aLsHVXtCTIU8KA3o4KblGWcGkecyTPt/1n72BPXcqHEnj32jYXmKzXzRNaVgJVXo8GVBActbl+hRCJL+Zr9sPbHj6tsXK12DJ8zzBJR+9CP0gQL9OV1W0gbyX6PAO/Tfp7kkYiP8MaphAhqgXXirjTD6dN/WCHThOUEi9AcAIXeX4R6mAI6Re3OHJpcvKyNiT56OpXCJeH2ODvAzRhuY+onuC0RSEn2NIVXNmwKIV1mwyK5x60TXB0gvoC1rWh1SCG9kR/jBDTdSM6VZ7oHm3HdHfpD8y7yq2HaMzm6vMFk5EqQs1LPonqdWXGwSUDMnqaC5yJpfOgyjlNNjj5jjcnNA1v2+9FENOy6adMVRqE2VSSAruoo+GJmmJUf4OeFFwPCkduuNNThCiLiCGQYTp/8aoncx4Eqagg2yWHFwwIlhw2JNtZ5N4v4NR64EEbX7zo56e7abZlnN8yDDE='
    } else if (ruc === '1791856910001') {
      conforma = 'gQ5JQRb7AA7QLqGtvjRH2vO6QV2Aio3z8TQjJA8b5aM7/LTIxn8Sywhotb+MPOoESYK8znQK3HJy5EljHd9A/6s6QlnyMNzUsNf68/Q/29GIA79vZ74YYsBscotKSZpF+PvyQQF5FV3anW/NFMqr3mWgR5E5zHLO6+FXbfPLpymwsWIton8ThzagiVWb1WIVE6+M9flQIq1H84RXGhKkUAx+jWmzCyLQ4vOY0YZKB/i7KNQM9nsMrERpFRFQNMSwFWxighbHMy2zsCMZ0c5GNypCf3qcVTZENaMVzJgTSNuV6L2KlPBIvnevpdoIxWgFV0P2ErV3SDkUq0E9gHxdITmPMdbRyepuVltJkcKkaz6dcrs/LNL03NzfWWzMT0HfDoHLrxfFoyeTWWOAu6mG2X29+KCMlfc4qYLE6s8Gi8iL6eJIrJ/Lq9ZJBH/EA5hx6BH0PiCpcDqNaE8gRJ7aoamSOvqKlYmh0OvrBAjIr68diEDeVJb/0X80vGmjS17wrMvKc2a1Gl+qz3vaeeoaUmYPqeYk4EO6CmxQoS1nBRkknJ59SkhLCm8vC2YUt44k0iNEm6XVf/lvN/UvFes/+8PlHKSBq5JYdvCKjx9iW+r+5SVO73P2zTbvXK9C0EfyJZQedAIE05xnGh6p/duAQOQugUbaWSAUPvU9kLEnB2c='
    } else if (ruc === '1001756947001') {
      conforma = 'v/Isp7rmTaFZQ5TAZ3rRk4I4V2MVOdYzvAkzX581GVx/QD9VvZz1X20JQOoavVOf7SbEDB1kTnOiZRbYQAoklKD72olr+co7YQobkBgYhA+KB0F+mNs3uhqfYoLVg2g/dRMsgsX/4xpcZmpzeq2VS4MMippXM7Hd1ewDofcNMjv1e0SlnOITyK/4LNysqrJWSkDFsAsQfWeGMs6jfSPMGLVL4Ct/MQ2pGrksXfwqpdkb7Kijo5HsO3mhjDHnEUtNoqR4D6M1SSEMPJAxgi4Be8TOiFYA5hxP+1+7lMxxIAUJhrcjOqqpvwMcV20bGG8YNov4DUokaVN2LAS5U6R2Rnlgd4IwXbRAVY4DE0+WEQHlhkz5cLmjsFJQBMgu8TRguyfKHVQ9WDqZQF6Tp7NPGu1VdpkIz4tzhB4b/OYt310t+1sMO11AId+nA446JfjIAHM5S1xd6gVhlfEOFnqhLJzdz937n9FZ9kPRf53Q+8ZKsjGlRhCasJtlxHOkIDo6/RwQR/hchxd0fAiLxuUJpUZnhbJ8WRyBbcitTvABlkDy6axc31nb/yDdvaZZRLSiDbCe430oV2rSukV2Zk4leJpArTvMkDNlS74qYgQO6NlanuU5k0L2QmQElqrPxJUi947LPJFPsV5iqWujQ2yTznaNbTgtxsglvtw64IxZD5s='
    } else if (ruc === '1801982636001') {
      conforma = 'LuWV82ibuWG/1CfG3/ZEBlfvtwjRNh+HtjqQsg7tK863TPaj6OedlT/bZduN81LFvbMMULVN97yb44OKvOOnxMCajkeH682bUaZDPm0vk/mNQnqIfPlVtWtZ2WXN0Qj1YWMwo4VcLth25fjTcZD+2BcRMH16v5Ef9TAb5RfZqGvHQ/iVERMiXNQBjAs3JNx1j52T8IdNP9M5GLbuthzZ3yo34Pzz9suBXcYMl1nj+rEtPadXRUPoThiknLlgbIAADlrIrBHcofczW65IlVP/EPV1h0aBcw9+HZuByGOqtws6TDHgkaLQoPVJciuvKcKrSZpH9yu+Rj64GuNDtbZIhMNy+oYZmVhE/rI+hdmsrRyEnCjITuN/olwvH22iomK5goDN4ryXN887OgsFhzBCw4kqcsPT4Uz+8naS9smuSfSHmburO0aKaUlaKvqAd6voC/TJOU083kel18efkXHcc4/OoNeDKos6tSYn5TX2SnS48vQUzuwv7xSbrjgd2VCcvz/Epq+Kct+P3XamG2PhZcW3ZFg+XQcP0wp5Lbk+rH47sEMowqDfdwZp53OLJgV6xlU4NJ/xLRCRPMlypr30XZV0++6u3kIB5b8P/vHOrNyxHXS/DrCvmfx1AkrStE31Edhsp1qgAQeLwhAp/Ozrk644+5SYAfbH6YLmgFNJZPU='
    }else if (ruc === '1792363640001') {
      conforma='meeiTnaN/QgSXWE1607lVwkj18g16pDW7xMO67A7gpBLr/DX1xT5HeCHoj/XwEA8/mHIa71oNSiZbDPjPPF7FD2g/DUPans62W+2sVyTILHHMLpJNQQldyrdkx0YT6m5b2Dzj+KvKawBiJk1Ik7Km5eaAlDpZX09qZSb03rseMXPtytfRA6doJdfWnFbgTpI3wcDCltlppO284LtTp+oRkQyVTBvo9ebqdHunnwtUyihbXzgIDEhwlmt7mSy8sXrARPQdvIdj7ilS4TVe2dyFKBACWPNG8ald/MxGDdfvDcs0KofBkzZvBYonLqnbQDL8MErUt+vwALZIYpwvrMVOtP1z9ewUmlpeEqDmNdLOJEIAq6aVPRizG6jJ2b98CaEqC1lxPcHeZy7LjVxp+9heGwBsozIsQBZAZNUywCie7r5e+8Qnebc/rJta/JZM6b2fFOfMbmZhg/rynsiT8BgTuIWFuZIUIFUURN2hib0YAhz8Wn0E1bixXAC/1dVmlOrE2FggmtXpNXtbQ550+BqcS3LMJ4+kY4ojiTTca63246kbYh77prnjIgUyJYUd/8gjSOO0CJoOncZwwQTIGbhSbE9rN+BAXwMqWZqXhM4C9Q1j6Gb9SsLkQP3GWLVZ+/kEsdlzJbLeMOcCetpsu/1j2Keih2ctYrgj8Pvk+SYt+w='
    } else if (ruc === '1710095108001') {
      conforma='SNdF4gcqMLK8cDxSS4nTHuTZnsdGdZSHy7TjHbjIXZsy/MV6/YykATGQCCwQE4nhMu+Msa6GF6An/uWEEwKDWu0ehkUJ9UgrSPHY3Lal72yrWporvr0peG5pbiOA/lmzLSPfe2iyIsVQsPGmgSNEsIBtEZMLfjszIGzp09+PGMBTaAVdnKCvlammfEwzmx7DdnX4ozADv97YgshPK3Lv5lyPY5MO5f4n1jqSzI/Gw7o7U41Q1fM7L4PgFryb8Xr39+jbbefDFgbrY0njPXSh7AQ1t3wspzTOoIbZOyPBVjeexvTUDsfFYTXtBycmUWCVM9Gr14qa3iH0ZRDZC70PitFLnYP7AIFtdMaMylidPistS49jIOyvmbLkj81qDljbGh0+4mdthH2YkdS1rkRzEZnIpXIu0FFqCnn3svDdA23Vi5RAl+kMuTcxp+3D2P81fEXSoYnS2EIdZZz228K6vce/8BCPPgs6PqN4I7ZSJeObXksGw5debJGU+0NfAk3ie88/trqNIyGcoCAv/JNY3MKRu36m+DAWtHblECAzKzaVZdxTNScBh31IMV1DEDhasGJcu6qflTQeUHSRUIi6jgOCloqOVG8QbGgbsKeCzage0x3Rdk+DjzoCD+ISiRSnxvJGi8O1XQOOqnJ07RDrhi0JECIT+wyaIpgqR+RwbtA='
    } else if (ruc === '179215549500P') {
      conforma='v17JC7PO2csQEaX/Ye7hu1vOuaK/1yZJUc3BqEzMZ/GqbKuVFLq3Yt1ZXojtdnyVPM8YGwCkq8N0XREyGRhtN6nwnowdRSG20Rh45PAv9IJaFhmysG3QOksRKSSPR/yTt5iJW0V440FO4vtC6WkHQsEJIxUun+bdhWy+MoxQtdceWK22+0iqRlm+JC7OVS9hdmSJIUhCQbbEK0Tn0zrghwxG+ykm0uwo4XVqnMVxI6CeB6xrcFy2xNltesiAJGA0/NeucuKJ58FnU8i1in00hk4eNa08WXb+jzd9Pcjrk4gPvhYcoEjvI3xmxC+WKJkV1+M8osckkPdXKioxI9WruWIhB74S+4pKK29++JiqXDO8aFatFD+ijEPjuQ5m8E9/MibYfTfUrIRdtEXePv2FVYuXZm2Qbk0/s1PmMPgRm2ZAP7sWUgjqAZ+FIMcsB/BrBuMPHAoWwsla/jOW4SDQIp6b52hP6tDIaeKVFeUept0BLuQJAK+7dUQUKumOYtYRQPFtGTQe9HtjPlVBsp8uHWeB7Eg4G0IauTFpzmNDSGbYHmFUh8Gk+FNdCbDLEv/vNwg0MN/aohDNZBGrECtx/ACMelq2+WJZCMrEFx3j+6+cJclV8tXCu8ntugOzAZFgTyHf6R9jVGNeABqnthgh4neuI43I9da4741i54EYzB4='
    } else if (ruc === '179314386500P') {
      conforma='unAq/FtseuXV3usFul9aVisf/iwRPhzzjkNk9E/U8hKw9rmLxLXsAsl2JOdD4MlKyVxa8RhXfMTNadc0oZ/Tr9is3Z/3aPL5Era0stwn5/F3jYpctTI94dt+KXtHia+wVOjXChHXZ63gwudEZ2DB+aQtiAA3P9wi6sS+JQVGsW69xP2vNujYXRhsZBj+j9HxaJ2UbzpswncH0zsSb8QHBr91rpMJj8rw//FJy1JUbTdgyZqDZme0i8KWFtB0rsG7mFpPSYHTPg0XTZ2hNTY5xk7Ev6+TriRJ1risdBVUcf6J8OKGhmQ4RjVDAPMw9Jd1ac4TZtHGshOYskztGWbYKH6JS1o4t94VHGz9QG6sQRBQ5z2eKJ0okceLy82ev4Pe2EMjdx0ziVgZcGdRxUevS7dNjM+zyKjNWfk2K2Qq9BHqmX3OHdaDqyEeSidt4e6q+6wdZD44LfLLFuKHKv3L4v9drY0PRL5jmik+2pPOXj+FLJhvIFckMiM6mR3llsat5wB835JeG2PlRtn34rrxs1TnHsBWsPArXlKzK1XOhYVFRums7YfW4Sx3OAaG8V3kz+NQQ3tVURBaKlF/RJ1eAr43KqpS9eD+xTjmt+NRGaO4KnFrRuUQLPdTp9WBImcq6veBe+ulHLQrLYAQuXvWhRtiFH8E+I41QnwJSvLGrK4='
    }else if (ruc === '170459851300P') {
      conforma='shWgK3+zKmtS7Ojkh0ZAiA7l/8iV/I/EoYf0Y3ZJ4skgxjP3B9LyAHW3cSWcuTLTQbuzFfcup91pbvbE59Oj6oYDyBskpyTP2Uo3aUx7IctYZzBay/vuKJCtGxUqvu1h1763O96IR2s8PsLWy9/NSFPQDRtIrnjxcdOpookXH3VmkQgEL8e3jugBjtctt82T/Xpxn7HGxUyLZ2BleaJTaWBywN0UTNuojHf5stRqiP0Dup6WatsMj52DxwsJnZm5f4YyO6QWmcaVw5D0wRssDL2nJbV/5wGUxfJujzn0LNc+fWXNOBnkrUZbV4XAODoBaD5d2sQJGGroIYLfb/tsIrFx3R0irdJh6ZBk8Ofa3qc30FD7F28CZgjpevp+F2udExI9CFe9E0hXehsuUnoalBTyUN5GtgmVwh69Gw8pUo2iYt1U2/BE2kbmAsJK76qlBJHrrIPocR6wm+5zpNbpHReWk+sTG44eFv/h23//7xOHz4yOUGEjO6QHMBdZmJCXy32VrUNmybgtsUKrwC/CuIxuiRdyLrgnaSgGlGnvmBbGcmaAtk30Iah7aEHUqhRaYrQmgR9R/Crv4VOeht1MUO0E9C/4r70a4+QQGfY+ofkw+pE7d9AMR12i4N5KNACl0zhefLmeBGxWaM3hNPfLRHcVUHT4ZI8M5oLTiTDG72s='
    }else if (ruc === '179278886200R') {
      conforma='iD4lLLbmOLeplBSifB7zhnBHUHC4B7KlnS2k6OG3BKe17OhBv45CxuhSYCNzksZGAvtGDmZM8R11JPgWOjyNGQrawzBn/x5z+uAP9ksiU1d3teLj1l96UsQphtNuYZ5jphRzPVHUPEsucBcRth/4Xss+3VULNf9kWFgrZkER4oQ0zfrhLzokyV2qamlQvGMuZrue6JN259o/te/7CVmwDDEHf8JEwQON+2+W434ycQSOqV3gHPMsMflczCKX4g7wcOUVaPQM/jR1TtPmJ7ArLrjOAOeI1ZnzuGk4jcwid1Y7RP25L9qXB+8SNCKddFs81eGUEcdrbE6qRH/w9BLAYZ/bgdubXTvusnZU+ObVcysdrCyjMeA6tHVYwdVQwsPnuGmbP9SiMA3Q52roIxCw71QkRxWadbP90C4Ket6v1vfdbc0B+c0fCwilUde5Jy6RBQ12kBOXTxqd2cgR/UgV+ZW1yfIOrkl+Z1Psm37uCi7bxfFxdbjCXGGoiV+SeKfP1iCike2p1ORK9APhHtrTrBAq0yAAdWOjqZgVAaltFEvHUJxfdrW7Uw63awg4ZHYDuzeFPV37p2g8Zeu1B2zXYEPk/kwP2FElBSLUkmfUk43iYS34B2h8t+Slm4hH7HegifnItxXjU/MmStkicA6JyN51iQAfh/RwoePJF1MQH2A=';
    }else if (ruc === '099276064800P') {
      conforma='GqpbBq+mMJwYQH8ESbpNKZ106YNYmAoMQUaJeA4gVuA1IXyCHck1kyVOZGjrT6cWTjAjm4aEqgLXj8NpaaruO1SbaPH3steCZJzUmnRoyxZLYPotkCRJYLlIQpvzSDeHv9ycQzVN+Zy06vPw3HSxAGghfHnAcEiFG5M5yJjc5/k0BeKyE2zMFSxOkqhxeADj0Iov7deMwh+MGhqkXyieFZZUue30mLM3/YgIez6LobuOQ0Ya80WpZQnxm/sovwsaxQTmijYqu5PlSP0rgLVGCwAF0iWtXCO7bhvr/IdAGXTRI0jXDhVkiZh2TMVB8l5IKw//XspOaF+/XMRS+imQ2anAeccLpZ6Q//uCyelbCL+3L78g3rXPkmtAaw+F3FdJ99waNlgpqANKAtWDfBROTVSHujgLUJCIzXlH7Oa0FfBVBUMW1oyAVpCWPLuZ/MIfk7NR5HfxFtdkujjbIaUySBbVIMx2P/ybIhN4B4CRqcJ3KqU53C34wm6gKnw6G9m4FTT4Qmi3D0yiAo80lhEKyVcYA7j67y6fyS9fAIhtbLyIbNCnuWRiK2kHbw7/tmCjeBnNLChWgcXr/pJoyujbr155kXsNgiILCgOOlepYJpNXQ0uKyZ7SkPLju9U3kqFMNXVWf8RCoQ7wDAjqNGb3CDJsI8GgfpJxzuRiL3qmgEI=';
    }else if (ruc === '099215449700P') {
      conforma='vnx+XTjqnCdrHj4bEMO2cfg4M/fZxuh/uvYsKrqAF8iTAG1HJ7Cz5Q1+00F3NhLOz0zT0eHkknFvhBGQtzaXEMv6fIsTK6a9jW4yz0B7kbAlmA5wbvrYyel3NhyzztH03vjOMmykxtBaCNdx9KQAFjg20cVh8JD8VM+z2bGJd4sBeYhHI1yRKph1m1wcP3QSxMjuAAt/Sj2/plKYD66xWIf3mP7dYOmnTnZwg38vFOXSVJATw84nihOzoC6Ybzf9jaIGJtjZOYIjYHuFwB4tzWv7/9EJbZcWaSos8aggJAj4GMe72uF/063m/Y4WWSF4c14GMDZce1JQ1JYwYv6klHjsCkc5i2mXRbpANCLlQuzaTA5SyB1Vv8sQytGpAvNJgA4XOf7fbbmw4qcfJQuxdIjQ+20wAbr7sp+Ilq5iNvNvac552OAz7tXe1E/7lNmjkGbWBjzBix+gZapCHNjlOKZBQ8ZQO6kPt3frqmKuZmxB5LgdVkKn0AqQi7hoQARjxY9QOfmeouxdQjDubfKWyHMycyQeMD2TeYVVeSHst7filye42ouOVuYos1eMNYn/jc9qNLn5taR6mNC0741up05lgJKpoHTFSW6hB96ERK3Iams1FOB5fQF2/9WSAz6A9vBTAJRVr7mtkr52N9qUnlMjlaNhbxgRzyvGgX5UUNM='
    }else if (ruc === '179185691000P') {
      conforma='QMzqPkpnOHLI0+wrFDdjbm62kPtm11OilRoplDtPWirgz6LV2CTV8jimZpLNdioYNDu3dNrk0h4O73rnO62PI82Jp4HPvPusbU/12aYph0EBjqgVerSkg5BgyGXiFqeyHFQB+Af427veeHYeI1n/EoCT5lif46IWvQN0Ni07XrFgt5BfcEtsSV+ur8h1WAAc/BWdLFGcnUSAWlwtuiGiaFUHtWxdHd0KV8uZl8NrnyfIe6/mUVqc+6bQ+kTgPuAKf9G1l197YGd/lTWN3PwvY1P5n6gltBe+ZY3xB5frmcEhJmn/t+s/7AjDjmDmNole+ZPhgm1XNDZcNFms1eoYnRRgBshswLxgHBSGpPojkqfNmjBSbLbMkNxxpT8ctRlSCpeeaWoubXwszkZl84cRFDgPvLl2Tmw6ggaORyidKmQmIl4tuV63kMSoj2AVsMDgzT5OH5SuffrmrQg1ALoy6NHZaChWB3TSmm4zn8HvtcXGs1QHcHoQ++/dS+J2CDiQ5BgVUsqUp4+dnGa3Hu4skLS8eRDTasVvicngbfE6f+c5/1Jlt2cKimBuF7IINrvHBnjfp/oMpxpEVIDGYJme48OTq4/Lb5FUHkag/xHixLmdmCQyd3oSpdwI+NzHWcuBR3H8Hy0htdJ2BaQ3i32/AYHsjsahKJlcs9Cbn5LwSb4='
    }else if (ruc === '179320517500S'){
      conforma='kS/rVk2coEpxMQ2iVy6FT++OMRyJgwmuCKZVB9HemrtbcbALVRrmUgnSawcHriaT0/ZkzpPFZCT9nujvK46LkSrS/qTGkcwoqNHDNaoMb/TSYTwGahRTL79B7yXoG9L4LKtrYdJOfCL/cTCKNGeVXpSKIFrrXlJaQgKaN8ppWTjcsm7HTa9Rodd77TdgcHzOF8gocHcIk8+xMjPGr0fR9pDC4G1q7ofng5y5qyyILZzHFq3tmSCTG+dGyzPNlhlEwRL+SB/KKz44O3sOav8QPQv9uxG3ir/y9fkJosd05UL/vWKweO3Xf95+ZhZhS2obc1uG3Bo5c1KLOgGjS5xsBYjb3prSzl2npPpsakGbPm5Kr6CbEwwWJoQl/Etp+2B07lV6tJ1XqXePXq+QIwwB1cLA6yoN8yTQ0zLjq4DhVPIqCf5LI/f/SmntHyJchwQxNTYm8+sWmwMwAf0Tu0pV31AQjzTOFA4oceX5bcml3oz8GFRHmWmDebZl7AYpDJ9dYBMbV1gaWPzLSicdmghWijk+bVajM8Uza8cfglAC3Z+8FxzqT8Nm4Assy9xXxX0oleYqFzo7xHN7g/p3zpcKE+YG9vWuWymZVm8CLa2hp/J4XSzEZ0QHNQQIYhWtsXcTNMxSjcuDq1zsuuMh/FxVbbdmv1rWzlfzbbNlS7SBL5o=';
    }else if (ruc === '179232126300R'){
      conforma='lUxzmpv7k0Nv1cV/QuJqVnQteM/XPdDmB0y7S3ZCNI0mLmnhJRo3rmKEVuLbNT/Wj7Tx7/JMQeJ/IvtiInuZUo4cSmQFbG9Ux+TDznJa6NKUIslnkHIhLEfYxKHLK6vxA3Sbk6xLLC0uCPwzqV06TYd7HRBXZIhKQokN44bMi/OxZT4/JP/bEZJ17/Q7vF/TqVWqQJc1NlKzAmXJyMSoD63EEbuSGjIbhiBtEDbRdthpWtPO0pa7pm55eQjk8pflsyRIOQVMrsr/tDGPuiAS4j4dJv0CoE+nvSaKV2dS0B56yQ8zUv6nGU7reW47QGth7wd/OrIRELOXODfAlMcWUoiKZclEh/c7iuUUm7l7nmcsdn1mSFos9nmCI7RUynsiEtwJ87d5qfcpMeNb8GwU6UGO/dev+Zto/GnCp+x0ms4fBuZdCuUj2C6RUTLdW5pEuzra8w7FJMPJ8XkURYJrFIpC1loKFqYob5cTGBje4wRRczln1k/gB7ijF5y+eEpAHDmB/jknDc1RmYIaWA+nWutDhwXb63rsSYinWAxbR994AHvjS4Da2kWj24/tZw2Z0/QFNw4BzykzI2aFVE52FJNcmLwr2trBxRJxDijCwrFY4WO+LEp3bmR8irFVugVRsSl4mBtdDCZu+2aq1nveqTkjo3R9lkKCXITuPYqgMZE=';
    }else if (ruc === '179278886200A') {
      conforma='mGZKsDGZVRRXYNaGafuWTXxvUfBplx7HSuD9VPnHDrN990x87hFn/qxfgiyI9X1ayu3oqE/oZ7u3cvpxM3FwKHqyBGUP2x59wnFpdPQjg9eTVGokoe7s8v4/T4oPM7+jg3GEaDA/UZ52BEVgommfOFEMOmUGm89xFG+f9xEI7CqQXpK0EfuCJM13kiRO2YHCeoYhGWv49rkTe8tuDqGghPVDSQ4budlfKaa3JM6VbbTPtMY3ZzU97IOE3YUVHxe0Ok06RyF43fQyIQ6/rYXGLmm3Lwlz7MUDqiA+s7s8CvgOc2aZO2AoDqcrFCVK+51Vx/vRgJfgMXOieRE3eirFntUkih8NemeCSpwUxcZISPDRO3bknSExOO1a+qT6NC3r70Nk0c6rSGKTqwfT3dyVOk3+mu2uPvGHHSEkSa40PjUK3F1UnKFIpzgW+5Bh3k2x9cT5c+dld9bdeeJLuDaTSniUwCNCJW5+lTzJdUj5IOL5eOOLzss8hBkvGgxQOjkj8Ylt7LT4pA7N9ivvfCRcACcwGJywIbalSWF3kKCtFHj028VNfEeg1TVvbQ00/3zQ/To6cslcxc9Egv98uCcUVX4TCmWzGV9ep2uNtqLoVouabh6nKzPRU8suK7huHuOqHIuoWQCiGz/4va2D3HJExWfUFPRoRI06vHn20RPx2p0=';
    }else if(ruc === '179179528800P'){
      conforma='c4iBj/x9DubRqBKNRjDAqdRbOZyyQhHNAJ098/sVCNIh5XyWJjsw9zMyl7rLKsMfsYdh0+ps0/ARaE8njBS7Yv/Jbh87wCaiBVnOon1Y4grTfyYXxZ84MV2IFqCX/nmsm6bjF7ZurqakgQ4cB9v9EIk84wPW5Yz3tNbd4qc7N2coNz54KMAVa3Vk3NZvVEOSRfQxdw5M/xmqHx0nsuc9SoJUEU9gHzOJHGSfBpo1nJvF5JwXT0nAs6X8cP49S/Xj0Q8f1uOrdMsiabMWYUUrOqb4i3BwJXgjp7IKdLjCP2AnwQqXLeUFChENKKRGTrmRYPte1OWizZCEEU1LGQrL0b6Axrzy29XhDPHXuhthSMx8GWtf3sybFDsH7pz/te9sKG1fjgzX2RYdsBWXjZfySavGmpz12zP4o+91vPz5TIeAcuWxigTGMgDTsg0A1upGkVPN24PCbwFjTZnOFqZU9VVXSZAaslu6Kiu+0wiKfPNzQOnxmURUKad8AFXup+BX0reHD3O3FC1LGs5NPX+48qnxvWm/27NbhsFxeeTMJq/2YLGUVJDrxi8CzRrCBL/E/MF2jeOBsWIi1bQMsPBYYuYr/9NcrjkOOUCLGkNpKwENOI/w6mfq+McyrvMlbqYTVM7043+mVZ2TX3BsF1eyjmC7cjIezhX52tcEHp+HBwU=';
    }else if(ruc === '179290263000P'){
      conforma='tYEh1iPxD1wcXq2mIE0Rj99iUPm0k6nPcsKv6YGSAYEGpaPBu3zWR9mQVVaaNLEu5omB9dLghHIJaBMIbmSot3Dy/TPn2bYMcOhAkN1ev7aQHODV3/dvlPRvvC0Iakrlzpp+Yn/j4ACh9FMg9IbAW3uMqxvwSwXqNRmM4VWglCMMWEw9of45q3rFg7UIXm2Y4cfOtG+VT+yZTPlPVXe0CVhEySLlUJW8mFRvVC7Iw+jT4yp/kRSK8vOTr6J5kHOxGDolY0HcSW+o4qzQJ2spFd1LzgvC3ZyPOAxvCL1gRw1nKCpdJcLkII1/NcU/0+6sTZPaGggu3Wkl3+sDmTaMaMz2UxCPBT1QS7Lknb9ZR+FqGs9nciNGOkqFQQBxcW3ziYQkdfu2zPfAcBEy3mDJpu7ThqV97wij8DtA/G8+MLChx+bcEOVFp08sxwSOeOAEh+e9TOnQWh7OBxoIqbodqjAA+qSw35l8hC0gjlj5keFua+rjF7pt5tcUFB+APY39cJqfXopyMUGiEwGBE6AeCeOksWMPJ9q+3GveaFG3A3+aBETPc1JVzweQjZoE4exZoilCIpjTj62OTKh8kC/S55AgyvlBezXFLnJltSyLygpUqAey771siBwAGsCFd3Z830srD/rwAU6QQdjngwQ/AlEvMQU8B5n2uwcaQOdwHNI=';
    }else if(ruc === '069178532500P'){
      conforma='ugTg72GPUGbTjLDVrewnUEXLjbu3URDPZtIsvQV6pLh3zXzrgxSrg1yq9I6R/jNhVJmnpwEpISFtAG26aWs8TlOJCc59lE3Jx/l1vTOBJpnTPmnPl/4CWRJ3+Pf8Ut3C7jJQkCbXtws/GJF+VYSLfMjIMUEi93dL7Qs/sf78oZdwBiRtmDWOzbIBL0mQdmXh/aXe6o03l1pb6q1qr9hDLtEwHmgSrlUdH9tKYMyOh+WvfBSTGVmAzv2Fw0xGVbCCg+bthS7FLk2oDVKROH1lJ5K2Oa3OLespuqSuxcoyBBxxAdG+auHZYtZupCpwze4SN4n7DtkCysnJagmfT3CRwk3Y6OCqoBNPzO6j2n/Xe8AODKnsmNZ07BU2u59/F5nkr98jHHs9hdZtRXQNIR3ykaCMQ3Qn1BBmlggv2fQGaKWSm0hqi4EdujdCNsK7KBvHvRdzFtoX7m4OgYDUyBqD4wOXqHhXrzDt3jjQARSnndbLzfXAA/PA3HhLCOF2htcbohOLb4mzQyU3NEUC2CDxEp894t/Sy69PD7drImccpLeHT6p1prnmMQgvHVmmWJp7dAnCw6i0VvqGFY0Vl5FN7E+8fi3yAmsQT9H0i3Xc+Y630FoDXhpbjQaThD9rQrUCIHAq9DlYh+KvC3K0dQi+mCcAOenTiWwaHdakXp9t3bc=';
    }else if(ruc === '100244016000P'){
      conforma='HIzgLg7hkLUd1nIiVdZ//9EfEHvQFnsiQ4UtzEOepqgmfH6HHIrKyfy3506bXMmIAfTcjw6uXkxuzwThWlFEKLgSh5U5nxGvhi996MnE+SZonn+H0mjvANx+lUYTySiKbvyQpy8EPRvlYAmtggaP65yYey5kmdWn6CZeeROzg/iXg3OCgmYcgXbOlmYKUEatzBVmnqalX88PHoK100JTxxZlyeA1RC1kyTlGabjJ6g4LDP3vQJKdrfwhzqYGm2h+kuExOrqxWlD8f7Zt1Cf4a8LqVHnNU9xBjGPq/h00VtkyZY+tDJCVyKSgPS8VW181ib2zOBWnyfb/sJua2IuQZtgRVDNJnppNPRuMtHcA8iECCQX18zB0Otwqh/BXQsuxmt87lv1Mg2hd69l9LGWnYUWJ2EXpZTFP/jlOXSlk5N0uDMBR+0cTV9GFYGlU4u/es73m62OUU9MPWSNCyMq8jyef5tTy9HgY1LW1u5ZJ6u0AgqJaVtfw1CiP2ykqWIitWniru0AodCH4VgDooDFO5P7vUSvpCqLU+hwyKCLx6EbOlHyTLUzsotUOPT0RNjmWrpG14FElPmtLSqUQdjtLP5QJwyum/Scgs3E1fwDasS4/fzkaw1MxhO1AEKQfIvPemez6d26eL6sP9QHQy6kV6EhzNwtNMVhcCKsyo3or/N8=';
    }else if(ruc === '189181074500P'){
      conforma='nSW8JXmaQ20C2ZkZRpPs3WbydEcxaSy7lcEOFSdZGZroOfqaFwMf+664oX+8WVRbmjpYdLBSrSbjcvNy2B5nz8eoqGDtpIFAikcDltw+Ay8y6r0CoPo67vgU+M/UrOrNvnjfW5pkRZs2TVb9Q46+x05pOda64S0MMWd4iD15058We74TgRfgfIwTZAwCLgvqPu0HyKL/Lvv/1k2EWJI9eSP6EbVogmsFAQNLiJQoWggtpYjXAm/2Zs/Zip4RQ9ucSYisMFbIuJ0MRF8Hd10B59I43pJG+ZKd+1N9w4ySKwaRvOkm9aGJd0gNAhVkg7/iym4BiKovh9LfltzGhs+iWOQ9g/EVVHca/iaWYF3Nlcp7yoh+B9W79tToL0+DMm8joZkSwNHNrAULrAw2xIHcsyDfQz3vvusOgCojoBpGK8Cemqxx/fexuvgopzaEi4zqY2E25zfp5Sl1nt0TCz96AhfRcFC4XO/RY2XjFsiYTN8fk+7rLcDfG4Xv1+aiKacRXjinCoJdS6EtM9qI8bddv9BzbjZiK01keT8QeD5qvYQHzspTnC0p5LajZV7o1BWHTOxmxqRNspafQu3qUgC/J2lA28tzxzSibq5Mzb3leX0XQflxu+r2U9JSlATia4/dQSrCJAuHO834VQZaA0NnOIuGTzELfuVfU8mKD49njcU=';
    }else if(ruc === '179114584400P'){
      conforma='nwOZApjBNqrORUhT/R/9puzauK1aC3Iove+guL1X34OUHkoyLNVCysqRyRXg/S8cUTOUiMbkqT4iShoX9y5Io7GVZcPdEo/zEUM6f+zej9GN48Iup+Wq8EiN8BO6sBuMBLtHJvNQB6THwm/iCZ0ozQPUBT2G3Dwbt62YF2frh8QrkGphPynlaxBdBbkU3sk49XToz8HL60IGbeokKUu7r9fP8mNmGtq5Q+6W3CB08tLcstYBXm7dxRjwOr7BHy4c8b1YccQyEQYhj1whwHilu9EEd2azjyvoYWVg6f74kOs9af53bcitCaNzvYEBG7Zs/qy87UzzbpDxOtyFFZrdDP8GoUHBk6FLvZqjY1A42zz8KYbHgjPTTRoCShPZGizO9ASwlKP4xHPwI7/vxaDbD2K4yZNf/JBSbkoL7+YaxJw10a276qbCPUv3R8Fq9pCZbjNPruH8qQ9DYEOBqpyIc1ZRLV8UKHVujcQi8fXwMOVddS6v5ZsBqNJOnt/9/BpmxKkSrAQ5MKizi1jsaPt6pERRVkeYWQ7zFoBX/3garaY/X8GYE39qw2KvMqGPdesXcYySgoy6Y3WUftqXp4KfMBM4AvWmNiMbXkmhc2JlLOZWFp9VmO/6g4pJtG1Diqle08VCrhI4MVcqW1x/FwOqNUJCrJm8PEvJ6Qh3Em6vZ2c='
    }else if(ruc === '179276561700P'){
      conforma='u0rgvloGhrJs49b0AkYfW5zQ+LR0tq7Ywf5emTjypNLLtx0JEVh0Mq2M/WE17NOK6WWp2YoaXsxZs6uxXKMF43drilJzO6BO8pbbwWfdpLLUdCJnpxoUakzQDTdzSSICrPW1WLqHUGdGpLAh9IF17lhL1I2PDtBrPa/qcfJgYTQFFI1f2sEM2mC+80z87HAWDu8ouW+2caF1LuPhZjiO9gXLotV33URyY7u59EiUDNXy64DAgDS9AXDkS/ouA/YNdlLqEYVAN5l3gKclgZjyrM4InxrrIGxiah0a6nPfIrSFGI4Auk8kRDoAJll9Nxo5GkzfBRhkeN15qWgZ4lBR5jzBn2jAUPuwYwi2fcp8vha32+evQ9Dr8y46OVN10GgQki3XWIa3AECc65OiiL9+wMifCUzRkkVSwpevH9KjE9pInXDOM8NFNXJM5eaJ5gfQDQwXF/4cfQxqbVdn4eeT9X0IGT2DQYGbbmfYYP4VAFLF2SJLhyYAVyAztrUm3VPAl8XStcEkqyRdZUr8yw3bvbBG+JLW0gsG9Y85FRxbDwVf7QHsLY3uK85kkDjbxygHx8DSIqnJ/cSHu87U/YySJl/diWHBErNXJ2gMSokdy8JL6AJqI+6ogjtRgDaUaHL/0+JW+fsx8MyDurinMbgBRbs6tHNrFnRd9rQKjI+adN0='
    }
    else {
      conforma = 'QfJhBWJ2y3kUSbiuSqZDvtD031ie3q0fPlQtu9KpXhxpnPrU5ufuzNA4IGHFvaO0EQ2aFP1Sr1ljRcccU720501X8cPaZ/NqdYDTE8u5TnnViLDLuexgVUyj2J+DGypvsFQRdB7iMHA8uEvVyy6/fucrCSX1l5fZQcVpFuJCqWfI9sn5aBhVq5CoiJ5GRcAGAlFlLPOXhqiUBIIkbIGaZQAl0PoiREt/56172Aa3gdeTdapTwzuD60IrvJPVmeFomBMr4SSOsEWJCNMnw1ANw/yFDqn1afNUdU1vcOhPYGsoQ6HEllPllm+BC7FGcYwy9ns3rD2crKFRZVjgakNkotvAtkQMzL4AqpR8wm+Hk33FN/EGBNJxUsEjrqULlblw9OlSQInKquOsbaQghCH4A/29VCpUoEPXl2TbxRkz8Xr5VIZ4qkvZgWRMT1uB5mlu14Tm5AAKeVOuW1lIX8wPRjRPa3QcM4I3pYAkQLbaItXsHoazW3ZQptghzZQo0cCjAheaMmcjLHBmLMPecEKILdz6/21fNTA30CSgLucJHdM0XCsNH08ZPavgcRdf9BQJ+oVeE+t+z/5XzO28Kurt8iM1uzbxo1kKnpSBFIKd2AONhs/1TJNwrncJydsq09Fa45YqhEp8SxeCMmWHjuvFbZUG+rVClgXG4xxZ1cbr9oE='
    }

    this.http.post(this.way + '/conformacion/1f76e4f9315b660000000000000', {
      ruci: ruc,
      conforma: conforma
    }, this.confIniciales.httpOptions()).subscribe(async resp => {
      if (resp === true) {
        this.utilitariosService.saveActivacion(true);
      } else {
        this.utilitariosService.saveActivacion(false);
      }
      if (this.usuario === 'admin' || this.usuarioGlob.autenticacion === 1) {
        // generación de token     
        if (this.auxtoken !== 'tokenverificado') {
                  // console.log('------>',ruc)
                  // if(ruc ==='1708190911001' || ruc === '170819091100S' 
                  // ||ruc === '099215449700F' ||ruc === '1721288221001' 
                  // || ruc==='1712117975001' || ruc==='1793143865001'
                  // || ruc==='1708190911001' || ruc==='1706076104001'
                  // || ruc ==='1704598513001' || ruc ==='2290352080001'
                  // || ruc ==='1793168833001' || ruc ==='0925395733001'
                  // || ruc ==='1791856910001' || ruc ==='1791323548001'
                  // || ruc ==='1792326222001' || ruc ==='1793031331001'
                  // || ruc ==='0993373823001' || ruc ==='1792155495001'
                  // || ruc ==='1710095108001' || ruc ==='1792088674001'
                  // || ruc ==='0992149213001' || ruc ==='1793082025001'
                  // || ruc=== '1793095518001' || ruc=== '1801982636001'
                  // || ruc=== '1792014778001' || ruc=== '0993252921001'
                  // || ruc=== '1792581532001' || ruc=== '1002157871001'
                  // || ruc=== '1792363640001' || ruc=== '1792506816001'
                  // || ruc=== '1792028272001' || ruc=== '1791264169001'
                  // || ruc=== '1706728944001' || ruc=== '1714402490001'
                  // || ruc=== '0992937238001' || ruc=== '1791050487001'
                  // || ruc=== '1792788862002' || ruc=== '1793203069001'
                  // || ruc=== '1792902630001' || ruc=== '0890039278001'
                  // || ruc=== '0992869844001' || ruc=== '0993378980001'
                  // || ruc=== '0992306122001' || ruc=== '0990337640001'
                  // || ruc=== '0990871434001' || ruc=== '0992622296001'
                  // || ruc=== '0992629991001' || ruc=== '0992391944001'
                  // || ruc=== '0992760648001' || ruc=== '0992154497001'
                  // || ruc=== '0991448284001'){
                    const essaci = await this.utilitariosService.esmarcasaci();
                    if(essaci === true){
                      console.log('aqui aws --->', ruc);
                      this.utilitariosService.enviarCorreoTokenaws(this.empresasel, this.usuario).subscribe(data => {
                        console.log(data);
                      if (data === false) {
                        this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
                        return;
                      } else {
                        this.auxtoken = 'usuadmin';
                        this.timer(this.timerdata);
                        this.displayToken = true;
                        this.service.add({ key: 'toast', severity: 'success', summary: 'Correo Enviado', detail: 'Verifique su correo: ' + data });
                      }
                    });
                  }else{
                    console.log('aqui normal --->', ruc);
                    this.utilitariosService.enviarCorreoToken(this.empresasel, this.usuario).subscribe(data => {
                      if (data === false) {
                        this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
                        return;
                      } else {
                        this.auxtoken = 'usuadmin';
                        this.timer(this.timerdata);
                        this.displayToken = true;
                        this.service.add({ key: 'toast', severity: 'success', summary: 'Correo Enviado', detail: 'Verifique su correo: ' + data });
                      }
                    });
                  }
        } else {
          this.verificarIpPublica();
        }
      } else {
        this.verificarIpPublica();
      }

      const el = document.documentElement;
      // el.requestFullscreen(); //entra en modo pantalla completa
    });
  }

  async sacianexLogin() {
    const respuestav = await this.confIniciales.verificarSesion(this.usuario, this.usuarioEsquema.usuario, this.ipFrontend);
    sessionStorage.setItem('session', respuestav.toString());
    this.getlogotipo();
    let ruc;
    const data = await this.confIniciales.getRuc();
    for (const rs of data) {
      ruc = rs.COMRUCI;
    }
    let servicio = '007';
    let user = this.usuarioEsquema.esquema;
    if (ruc) {
      let endPointUrl = `${this.url}/empresas/${ruc}/verificar`;
      endPointUrl = `${endPointUrl}?servicio=${servicio}&user=${user}`;
      this.getServer(endPointUrl).subscribe(respuesta => {
        if (respuesta.length > 0) {
          let oEmpresa = respuesta[0];
          this.resultado = `Resultado: Estado: ${oEmpresa.EMP_STD} , Forma Pago: ${oEmpresa.EMP_FP}`;
          this.http.post(this.way + '/conformacion/1f76e4f9315b660000000000000', {
            ruci: ruc,
            conforma: `${oEmpresa.EMP_FP}`
          }, this.confIniciales.httpOptions()).subscribe(async resp => {
            if (resp === true) {
              this.utilitariosService.saveActivacion(true);
            } else {
              this.utilitariosService.saveActivacion(false);
            }
            if (this.usuario === 'admin' || this.usuarioGlob.autenticacion === 1) {
              // generación de token
              if (this.auxtoken !== 'tokenverificado') {
                  // console.log('------>',ruc)
                  // if(ruc ==='1708190911001' || ruc === '170819091100S' 
                  // ||ruc === '099215449700F' ||ruc === '1721288221001' 
                  // || ruc==='1712117975001' || ruc==='1793143865001'
                  // || ruc==='1708190911001' || ruc==='1706076104001'
                  // || ruc ==='1704598513001' || ruc ==='2290352080001'
                  // || ruc ==='1793168833001' || ruc ==='0925395733001'
                  // || ruc ==='1791856910001' || ruc ==='1791323548001'
                  // || ruc ==='1792326222001' || ruc ==='1793031331001'
                  // || ruc ==='0993373823001' || ruc ==='1792155495001'
                  // || ruc ==='1710095108001' || ruc ==='1792088674001'
                  // || ruc ==='0992149213001' || ruc ==='1793082025001'
                  // || ruc=== '1793095518001' || ruc=== '1801982636001'
                  // || ruc=== '1792014778001' || ruc=== '0993252921001'
                  // || ruc=== '1792581532001' || ruc=== '1002157871001'
                  // || ruc=== '1792363640001' || ruc=== '1792506816001'
                  // || ruc=== '1792028272001' || ruc=== '1791264169001'
                  // || ruc=== '1706728944001' || ruc=== '1714402490001'
                  // || ruc=== '0992937238001' || ruc=== '1791050487001'
                  // || ruc=== '1792788862002' || ruc=== '1793203069001'
                  // || ruc=== '1792902630001' || ruc=== '0890039278001'
                  // || ruc=== '0992869844001' || ruc=== '0993378980001'
                  // || ruc=== '0992306122001' || ruc=== '0990337640001'
                  // || ruc=== '0990871434001' || ruc=== '0992622296001'
                  // || ruc=== '0992629991001' || ruc=== '0992391944001'
                  // || ruc=== '0992760648001' || ruc=== '0992154497001'
                  // || ruc=== '0991448284001'){
                    const essaci = await this.utilitariosService.esmarcasaci();
                    if(essaci === true){
                      console.log('aqui aws --->', ruc);
                      this.utilitariosService.enviarCorreoTokenaws(this.empresasel, this.usuario).subscribe(data => {
                        console.log(data);
                      if (data === false) {
                        this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
                        return;
                      } else {
                        this.auxtoken = 'usuadmin';
                        this.timer(this.timerdata);
                        this.displayToken = true;
                        this.service.add({ key: 'toast', severity: 'success', summary: 'Correo Enviado', detail: 'Verifique su correo: ' + data });
                      }
                    });
                  }else{
                    console.log('aqui normal --->', ruc);
                    this.utilitariosService.enviarCorreoToken(this.empresasel, this.usuario).subscribe(data => {
                      if (data === false) {
                        this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
                        return;
                      } else {
                        this.auxtoken = 'usuadmin';
                        this.timer(this.timerdata);
                        this.displayToken = true;
                        this.service.add({ key: 'toast', severity: 'success', summary: 'Correo Enviado', detail: 'Verifique su correo: ' + data });
                      }
                    });
                  }
              } else {
                this.verificarIpPublica();
              }
            } else {
              this.verificarIpPublica();
            }
            const el = document.documentElement;
            // el.requestFullscreen(); // entra en modo pantalla completa
          }, () => {
            this.service.add({ severity: 'error', summary: 'Error 3', detail: 'No existe empresa. Necesita activación' });
          });
        } else {
          this.service.add({ severity: 'error', summary: 'Error 2', detail: 'No existe empresa. Necesita activación' });
        }
      }, () => {
        this.service.add({ severity: 'error', summary: 'Error 1', detail: 'No existe empresa. Necesita activación' });
      });
    }
  }

  async omitirConformacion() {
    const respuestav = await this.confIniciales.verificarSesion(this.usuario, this.usuarioEsquema.usuario, this.ipFrontend);
    sessionStorage.setItem('session', respuestav.toString());
    this.utilitariosService.saveActivacion(true);
    this.getlogotipo();
    this.iniciaElSistema();
    const el = document.documentElement;
  }

  async olvidocontrasena() {
    if (this.nombreempresa !== undefined && this.nombreempresa !== '') {
      this.nombreempresa = this.nombreempresa.toUpperCase();
      const auxempresa = this.empresas.find((emp: { EMPRESA_NOMBRE: any; }) => emp.EMPRESA_NOMBRE === this.nombreempresa);
      // se verifica la empresa
      if (auxempresa === undefined) {
        this.service.add({ severity: 'error', summary: 'Error de Ingreso', detail: 'La empresa no existe' });
        this.back();
      } else {
        this.empresasel = auxempresa.EMPRESA_ESQUEMA;
        if (this.usuario !== undefined && this.usuario !== '') {
          //GSRF
          console.log('--->',auxempresa.EMPRESA_NOMBRE);
          // if(auxempresa.EMPRESA_NOMBRE ==='D' || auxempresa.EMPRESA_NOMBRE ==='DEMO' 
          // || auxempresa.EMPRESA_NOMBRE ==='FRAS' || auxempresa.EMPRESA_NOMBRE ==='1721288221001' 
          // || auxempresa.EMPRESA_NOMBRE==='1712117975001' || auxempresa.EMPRESA_NOMBRE==='1793143865001'
          // || auxempresa.EMPRESA_NOMBRE==='1708190911001' || auxempresa.EMPRESA_NOMBRE==='1706076104001'
          // || auxempresa.EMPRESA_NOMBRE ==='1704598513001' || auxempresa.EMPRESA_NOMBRE ==='2290352080001'
          // || auxempresa.EMPRESA_NOMBRE ==='1793168833001' || auxempresa.EMPRESA_NOMBRE ==='0925395733001'
          // || auxempresa.EMPRESA_NOMBRE ==='1791856910001' || auxempresa.EMPRESA_NOMBRE ==='1791323548001'
          // || auxempresa.EMPRESA_NOMBRE ==='1792326222001' || auxempresa.EMPRESA_NOMBRE ==='1793031331001'
          // || auxempresa.EMPRESA_NOMBRE ==='0993373823001' || auxempresa.EMPRESA_NOMBRE ==='1792155495001'
          // || auxempresa.EMPRESA_NOMBRE ==='1710095108001' || auxempresa.EMPRESA_NOMBRE ==='1792088674001'
          // || auxempresa.EMPRESA_NOMBRE ==='0992149213001' || auxempresa.EMPRESA_NOMBRE ==='1793082025001'
          // || auxempresa.EMPRESA_NOMBRE ==='1793095518001' || auxempresa.EMPRESA_NOMBRE ==='1801982636001'
          // || auxempresa.EMPRESA_NOMBRE ==='1792014778001' || auxempresa.EMPRESA_NOMBRE ==='0993252921001'
          // || auxempresa.EMPRESA_NOMBRE ==='1792581532001' || auxempresa.EMPRESA_NOMBRE ==='1002157871001'
          // || auxempresa.EMPRESA_NOMBRE ==='1792363640001' || auxempresa.EMPRESA_NOMBRE ==='1792506816001'
          // || auxempresa.EMPRESA_NOMBRE ==='1792028272001' || auxempresa.EMPRESA_NOMBRE ==='1791264169001'
          // || auxempresa.EMPRESA_NOMBRE ==='1706728944001' || auxempresa.EMPRESA_NOMBRE ==='1714402490001'
          // || auxempresa.EMPRESA_NOMBRE ==='0992937238001' || auxempresa.EMPRESA_NOMBRE ==='1791050487001'
          // || auxempresa.EMPRESA_NOMBRE ==='1792788862002' || auxempresa.EMPRESA_NOMBRE ==='1793203069001'
          // || auxempresa.EMPRESA_NOMBRE ==='1792902630001' || auxempresa.EMPRESA_NOMBRE ==='0890039278001'
          // || auxempresa.EMPRESA_NOMBRE ==='ACCESSROSS' || auxempresa.EMPRESA_NOMBRE ==='CONSULTORA'
          // || auxempresa.EMPRESA_NOMBRE ==='CRIBRYAR' || auxempresa.EMPRESA_NOMBRE ==='BARLA'
          // || auxempresa.EMPRESA_NOMBRE ==='MARIGALDI' || auxempresa.EMPRESA_NOMBRE ==='CARSHOP'
          // || auxempresa.EMPRESA_NOMBRE ==='AUDASA' || auxempresa.EMPRESA_NOMBRE ==='MULTIFASHION'
          // || auxempresa.EMPRESA_NOMBRE ==='AUTOMAXGYE' || auxempresa.EMPRESA_NOMBRE ==='FRASCOSA'
          // || auxempresa.EMPRESA_NOMBRE ==='VERONSA' || auxempresa.EMPRESA_NOMBRE ==='AUTOMAX'){
            const essaci = await this.utilitariosService.esmarcasaci();
            if(essaci === true){
            console.log('aqui aws --->', auxempresa.EMPRESA_NOMBRE);
            this.utilitariosService.enviarCorreoaws(this.empresasel, this.usuario).subscribe(correo => {
              if (correo === false) {
                this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
                return;
              } else {
                this.service.add({
                  severity: 'success', summary: 'Información',
                  detail: 'Verifique su correo electrónico ' + correo
                });
              }
            });
          }else{
            console.log('aqui --->', auxempresa.EMPRESA_NOMBRE);
            this.utilitariosService.enviarCorreo(this.empresasel, this.usuario).subscribe(data => {
              if (data === false) {
                this.service.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe' });
                return;
              } else {
                this.service.add({
                  severity: 'success', summary: 'Información',
                  detail: 'Verifique su correo electrónico ' + data
                });
              }
            });
          } 
          //GSRF
        } else {
          this.service.add({ severity: 'error', summary: 'Error', detail: 'Ingrese el usuario' });
          return;
        }
      }
    } else {
      this.service.add({ severity: 'error', summary: 'Error', detail: 'Ingrese la empresa' });
      return;
    }
  }

  startTimer() {
    this.control = this.control + 1;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.displayToken = false;
        this.timeLeft = 60;
        this.control = 1;
      }
    }, 1000);
  }

  temporizador() {
    if (this.control === 1) {
      this.startTimer();
    }
  }

  timer(minute) {
    this.displayTimer = '';
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;
    const prefix = minute < 10 ? '0' : '';
    clearInterval(this.timerInterval);
    this.timerInterval = setInterval(() => {
      seconds--;

      if (statSec != 0) {
        statSec--;
      } else {
        statSec = 59;
      }

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else {
        textSec = statSec;
      }

      this.displayTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds === 0) {
        this.displayToken = false;
        console.log('TimerInterval' + this.timerInterval);
        clearInterval(this.timerInterval);
      }
    }, 1000);

  }
  //GSRF
    acciones() {
      console.log('entra 555555');
      this.displayAcciones = !this.displayAcciones;
      const elemento = document.getElementsByClassName('layout-config');
      if (this.displayAcciones === true) {
        for (let i = 1; i <= elemento.length; i++) {
          elemento[i - 1].className += ' layout-config-active';
        }
      } else if (this.displayAcciones === false) {
        for (let i = 1; i <= elemento.length; i++) {
          elemento[i - 1].className = 'layout-config';
        }
      }

    }
  //GSRF

  sacianex() {
    // this.omitirConformacion();
  //this.sacianexLoginTest();
  this.sacianexLogin();
  }

}
