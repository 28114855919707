import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { MessageService } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { VenencGuiaRemision } from '../veninterfaces/venencGuiaRemision';
import { VenenceReferencia } from '../veninterfaces/venenceReferencia';
import { VendetGuiaRemision } from '../veninterfaces/vendetGuiaRemision';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';

@Injectable({
  providedIn: 'root'
})
export class VenenguiaRemisionService {


  public encabezadoGuiaSeleccionada: VenencGuiaRemision = {};
  public detguiaRemision: VendetGuiaRemision[];
  public detguiaReferencia: VenenceReferencia[];

  public encabezadoGuiaSeleccionadaarray: VenencGuiaRemision[] = [];
  public detguiaRemisionarray: VendetGuiaRemision[][] = [];
  public detguiaReferenciaarray: VenenceReferencia[][] = [];
  public dblPorcDescGlbGrearray: number[] = [];
  public dblValorDescGlbGrearray: number[] = [];
  public dblPorcIvaGrearray: number[] = [];


  public dblPorcDescGlbGre: number;
  public dblValorDescGlbGre: number;
  public dblPorcIvaGre: number;

  public decimalesCantidadGre: number;
  public decimalesPrecioGre: number;
  public decimalesTotalGre: number;
  public decimalesDescGre: number;
  public hmpConfiguracion: any[];
  public cantExistArt: number;
  public strTipoIns: string;
  public max: number;
  public auxsalto: boolean;
  public url: string;
  public way: string;
  public grids: any[] = [];
  public horaSistema: any;//CDPJ
  //CDPJ
  public porcetariiva:number;
  public codtariiva:string;
  public control=0;
  public tipofiltrobus:string='';
  


  //CDPJ

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService, private confIniciales: ConfInicialesService, private datePipe: DatePipe,
    private init: NuevoComponentService) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  refrescarURL() {
    if (this.url === null) {
      this.url = sessionStorage.getItem('url');
    }
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }

  getEncGuia(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/88xepp0fjsn7a4w', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getDetGuia(guiaCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/s3kxi1lshujdlm3', {
      elementos: {
        p_ENCGRE_NUMERO: guiaCodigo,
        p_COM_CODIGO: '01',
        p_Condicion: '',
      }
    }, this.confIniciales.httpOptions());
  }

  getListaPrecios(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/vbjj5ysedmmzfem', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  obtenerNombreCli(cliCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/nppame3erx8ny7h', {
      elementos: {
        CLI_CODIGO: cliCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerNombreTransport(transportCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/191j2mkaz9g2eiv', {
      elementos: {
        TRANSPORT_CODIGO: transportCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerCorreo(ruc: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/fkkyv5r6momszqt', {
      elementos: {
        CLI_CODIGO: ruc
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerBodega(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/fdfu348zs7fy38e', {
      elementos: {
      },
    }, this.confIniciales.httpOptions());
  }

  obtenernuevoNumero(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/q5gfav4tweufx07', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  aumentarNumerion(numero): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/8yizjk7igs3m2r1', {
      elementos: {
        NUM_SECACTUAL: '00' + String(Number(numero) + 1),
        NUM_DOCREF: 'GRE'
      },
    }, this.confIniciales.httpOptions());
  }

  guardarENCGRE(encGre: VenencGuiaRemision): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/l7ph5rncql5fm59', {
      elementos: {
        p_encgre_codigo: encGre.ENCGRE_CODIGO2,
        p_encgre_numero: encGre.ENCGRE_NUMERO,
        p_com_codigo: '01',
        p_cli_codigo: encGre.CLI_CODIGO,
        p_bod_codigo: encGre.BOD_CODIGO,
        p_encgre_fechaemision: encGre.ENCGRE_FECHAEMISION,
        p_encgre_motivo: encGre.ENCGRE_MOTIVO,
        p_encgre_ptopartida: encGre.ENCGRE_PTOPARTIDA,
        p_encgre_nmbrdstntr: encGre.ENCGRE_NMBRDSTNTR,
        p_encgre_rucdstntr: encGre.ENCGRE_RUCDSTNTR,
        p_encgre_ptollegada: encGre.ENCGRE_PTOLLEGADA,
        p_encgre_nmbrtrnsp: encGre.ENCGRE_NMBRTRNSP,
        p_encgre_ructrnsp: encGre.ENCGRE_RUCTRNSP,
        p_transport_codigo: encGre.TRANSPORT_CODIGO,
        p_encfac_numero: encGre.ENCFAC_NUMERO,
        p_encfac_comcodigo: encGre.ENCFAC_COMCODIGO,
        p_encgre_direcciondstntr: encGre.ENCGRE_DIRECCIONDSTNTR,
        p_encgre_telefonodstntr: encGre.ENCGRE_TELEFONODSTNTR,
        p_encgre_autotntr: encGre.ENCGRE_AUTOTNTR,
        p_encgre_placatntr: encGre.ENCGRE_PLACATNTR,
        p_encgre_motivoletras: encGre.ENCGRE_MOTIVOLETRAS,
        p_encgre_iva: encGre.ENCGRE_IVA,
        p_encgre_totalneto: Number(encGre.ENCGRE_TOTALNETO),
        p_encgre_valordes: Number(encGre.ENCGRE_VALORDES),
        p_encgre_porcedes: Number(encGre.ENCGRE_PORCEDES),
        p_encgre_valoriva: Number(encGre.ENCGRE_VALORIVA),
        p_encgre_porceiva: Number(encGre.ENCGRE_PORCEIVA),
        p_encgre_valorice: Number(encGre.ENCGRE_VALORICE),
        p_encgre_porceice: Number(encGre.ENCGRE_PORCEICE),
        p_encgre_baseiva: Number(encGre.ENCGRE_BASEIVA),
        p_encgre_baseice: Number(encGre.ENCGRE_BASEICE),
        p_encgre_basecero: Number(encGre.ENCGRE_BASECERO),
        p_encgre_comentario: encGre.ENCGRE_COMENTARIO,
        p_encgre_fecinitraslado: encGre.ENCGRE_FECINITRASLADO,
        p_encgre_fechfintraslado: encGre.ENCGRE_FECHFINTRASLADO,
        p_encgre_lista: encGre.ENCGRE_LISTA,
        p_encgre_total: Number(encGre.ENCGRE_TOTAL),
        p_encgre_origen: encGre.ENCGRE_ORIGEN,
        p_encgre_estado: encGre.ENCGRE_ESTADO
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarDatos(encGre: VenencGuiaRemision, codigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/ydxwomciinfbvgs', {
      elementos: {
        p_ENCGRE_codigo: codigo,
        p_COM_CODIGO: '01',
        p_encgre_referencia: encGre.ENCGRE_REFERENCIA,
        p_encgre_horaini: encGre.ENCGRE_HORAINI,
        p_encgre_horafin: encGre.ENCGRE_HORAFIN,
        p_encgre_tonelaje: encGre.ENCGRE_TONELAJE,
        p_encgre_numref: encGre.ENCGRE_NUMREF
      },
    }, this.confIniciales.httpOptions());
  }

  insertarDetalle(vendetGuiaRemision: VendetGuiaRemision): Observable<any> {
    return this.http.post(this.way + '/ventas/guiaremision/1nak565adlu2341', {
      elementos: {
        p_encgre_codigo: Number(vendetGuiaRemision.ENCGRE_CODIGO),
        p_encgre_numero: vendetGuiaRemision.ENCGRE_NUMERO,
        p_detgre_linea: Number(vendetGuiaRemision.DETGRE_LINEA),
        p_detgre_tipodet: vendetGuiaRemision.DETGRE_TIPODET,
        p_detgre_codigo: vendetGuiaRemision.DETGRE_CODIGO,
        p_detgre_descripcion: vendetGuiaRemision.DETGRE_DESCRIPCION,
        p_detgre_unidad: vendetGuiaRemision.DETGRE_UNIDAD,
        p_detgre_cantidad: Number(vendetGuiaRemision.DETGRE_CANTIDAD),
        p_bod_codigo: vendetGuiaRemision.BOD_CODIGO,
        p_encfac_numero: vendetGuiaRemision.ENCFAC_NUMERO,
        p_detfac_linea: Number(vendetGuiaRemision.DETFAC_LINEA),
        p_detfac_comcodigo: vendetGuiaRemision.DETFAC_COMCODIGO,
        p_com_codigo: '01',
        p_detgre_precio: Number(vendetGuiaRemision.DETGRE_PRECIO),
        p_detgre_descuento: Number(vendetGuiaRemision.DETGRE_DESCUENTO),
        p_detgre_total: Number(vendetGuiaRemision.DETGRE_TOTAL),
        p_detgre_tribiva: vendetGuiaRemision.DETGRE_TRIBIVA,
        p_detgre_iva: Number(vendetGuiaRemision.DETGRE_IVA),
        p_detgre_tribice: vendetGuiaRemision.DETGRE_TRIBICE,
        p_detgre_ice: Number(vendetGuiaRemision.DETGRE_ICE),
        p_detgre_porceice: Number(vendetGuiaRemision.DETGRE_PORCEICE),
        p_detgre_lista: vendetGuiaRemision.DETGRE_LISTA,
        p_detgre_baseiva: Number(vendetGuiaRemision.DETGRE_BASEIVA),
        p_detgre_baseice: Number(vendetGuiaRemision.DETGRE_BASEICE),
        p_detgre_basecero: Number(vendetGuiaRemision.DETGRE_BASECERO),
        p_detgre_promocion: vendetGuiaRemision.DETGRE_PROMOCION,
        p_detgre_orden: Number(vendetGuiaRemision.DETGRE_ORDEN),
        p_encped_numero: vendetGuiaRemision.ENCPED_NUMERO,
        p_detped_linea: Number(vendetGuiaRemision.DETPED_LINEA),
        p_cen_codigo: vendetGuiaRemision.CEN_CODIGO,
        p_detger_bulto: Number(vendetGuiaRemision.DETGRE_BULTO),
        p_detgre_cantidadb: Number(vendetGuiaRemision.DETGRE_CANTIDADB),
        p_detgre_cantidadund: Number(vendetGuiaRemision.DETGRE_CANTIDADUND),
        p_linea: Number(vendetGuiaRemision.LINEA),

      },
    }, this.confIniciales.httpOptions());
  }

  eliminarDet(detGuia: VendetGuiaRemision): Observable<any[]> {

    return this.http.post<any[]>(this.way + '/ventas/guiaremision/7yqugelbl9upn7g', {
      elementos: {
        p_encgre_codigo: detGuia.ENCGRE_CODIGO,
        p_com_codigo: '01',
        p_detgre_linea: detGuia.DETGRE_LINEA,
      },
    }, this.confIniciales.httpOptions());
  }

  eliminarEnc(encGuia: VenencGuiaRemision): Observable<any[]> {
    console.log(encGuia);
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/8z6az2n7lusvz37', {
      elementos: {
        p_encgre_codigo: encGuia.ENCGRE_CODIGO2,
        p_com_codigo: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarDespachoPed(encpedNumero: string, pedLinea: number): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/tgtb6onm07sqz4b', {
      elementos: {
        p_ENCPED_numero: encpedNumero,
        p_DETPED_linea: pedLinea,
        p_COM_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  verificaEstadoPedidos(encpedNumero: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/297942eugs3a68e', {
      elementos: {
        p_ENCPED_numero: encpedNumero,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  actualizarFechaEntregaPedido(encpedNumero: string, fechaEntrega: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/a0g4719voee0jxs', {
      elementos: {
        p_ENCPED_numero: encpedNumero,
        p_ENCPED_fechaentrega: fechaEntrega,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  registrarKardex(encgreCodigo: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/c5oe0mag9cmhjej', {
      elementos: {
        p_encgre_codigo: encgreCodigo,
      },
    }, this.confIniciales.httpOptions());
  }
  generarAsientoContable(encgreCodigo: string, usuident: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/o0wd8uhzdcgr0t8', {
      elementos: {
        p_encgre_codigo: encgreCodigo,
        p_com_codigo: '01',
        p_tipdia_codigo: 'AGR',
        p_origen: 'VEN',
        p_usuario: usuident
      },
    }, this.confIniciales.httpOptions());
  }
  cambiarEstadoGRE(encgreCodigo: string, estado: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/4rgllas6hmsoz02', {
      elementos: {
        p_ENCGRE_codigo: encgreCodigo,
        p_COM_CODIGO: '01',
        p_ENCGRE_estado: estado,
      },
    }, this.confIniciales.httpOptions());
  }
  anularAsiento(encGre): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/v2enysq7ix857sm', {
      elementos: {
        COM_codigo: '01',
        ASI_NRO: encGre.ASI_NRO
      },
    }, this.confIniciales.httpOptions());
  }

  getAsiento(codigo: string): Observable<any> {

    return this.http.post<any>(this.way + '/ventas/guiaremision/p4ryoh688sdw9t4', {
      elementos: {
        ENCGRE_CODIGO: codigo
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerInfoAdic(codigo: string): Observable<any[]> {
    return this.http.post<any>(this.way + '/ventas/guiaremision/ob3m4km5h52jyaa', {
      elementos: {
        ENCGRE_NUMERO: codigo
      },
    }, this.confIniciales.httpOptions());
  }

  guardarInfoAdi(detInfo: VenenceReferencia): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/om7vu683ggk9kha', {
      elementos: {
        p_encgre_numero: detInfo.ENCGRE_NUMERO,
        p_com_codigo: '01',
        p_infad_numsec: detInfo.INFAD_NUMSEC,
        p_infad_codigo: detInfo.INFAD_CODIGO,
        p_infad_descripcion: detInfo.INFAD_DESCRIPCION
      },
    }, this.confIniciales.httpOptions());
  }
  eliminarInfoAdiDet(detInfo: VenenceReferencia): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/vs3cpww6wgfs3q5', {
      elementos: {
        p_encgre_numero: detInfo.ENCGRE_NUMERO,
        p_com_codigo: '01',
        p_infad_numsec: detInfo.INFAD_NUMSEC,
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerClave(accion): Observable<any> {
    let acc = '';

    if (accion === 'actAnular') {
      acc = 'actAnular';

    } else if (accion === 'actBtnAnular') {

      acc = 'actBtnAnular';

    } else if (accion === 'actBtnXML') {

      acc = 'actBtnXML';

    } else if (accion === 'firmarDoc') {

      acc = 'firmarDoc';

    }

    return this.http.post<any>(this.way + '/ventas/guiaremision/33mwut6jrgjd6ne', {
      elementos: {
      },
      accion: acc
    }, this.confIniciales.httpOptions());

  }

  insertarEleTrnDoc(docNum, tipoFac, usuario, ambfe): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/kjwfcmn4vvqt1td', {
      elementos: {
        p_trndoc_numdoc: docNum,
        p_trndoc_tipo: tipoFac,
        p_trndoc_estado: 'VA',
        p_trndoc_resultado: '',
        p_trndoc_ubicaarch: '',
        p_trndoc_secuencial: '',
        p_usuidentificacion: usuario,
        p_com_codigo: '01',
        p_trndoc_tipoamb_fe: ambfe
      },
    }, this.confIniciales.httpOptions());
  }

  obtenerLogFE(docNum: string, tipoFac: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/trlutol7vjau0a2', {
      elementos: {
        TRNDOC_NUMDOC: docNum,
        TRNDOC_TIPO: tipoFac
      },
    }, this.confIniciales.httpOptions());
  }

  reenviarCorreo(docNum: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/7gglevgqghgngwl', {
      elementos: {
        TRNDOC_NUMDOC: docNum,
      },
    }, this.confIniciales.httpOptions());
  }
  calcularTotal() {
    let dblValorTotal: number;
    let dblCostoTotalItem = 0;
    let dblValorIceItem = 0;
    let dblBaseIceItem = 0;
    let dblBaseIceTotal = 0;
    let dblValorIceTotal = 0;
    let dblBaseCeroTotal = 0;
    let dblBaseIvaTotal = 0;
    let dblValorIvaTotal = 0;
    let dblValorNeto = 0;

    for (let fila = 0; fila < this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const item = this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila];
      if (item.DETGRE_TIPODET !== '*') {
        if (item.DETGRE_CODIGO !== '' && item.DETGRE_CODIGO !== null) {
          //dblCostoTotalItem = item.DETGRE_CANTIDAD * item.DETGRE_PRECIO;
          //CDPJ
          let val=10 ** this.decimalesTotalGre;
          let total=Math.round(item.DETGRE_CANTIDAD * val * item.DETGRE_PRECIO) / val;
          dblCostoTotalItem = Number(total.toFixed(this.decimalesTotalGre));
          console.log(dblCostoTotalItem);
          //CDPJ
          const dblDsct = dblCostoTotalItem * (item.DETGRE_DESCUENTO / 100);
          dblCostoTotalItem = Number(dblCostoTotalItem) - Number(dblDsct);
          //dblCostoTotalItem=this.redondearDecimales(dblCostoTotalItem);
          //item.DETGRE_TOTAL = dblCostoTotalItem; // costo total por línea de detalle
          item.DETGRE_TOTAL = Number(dblCostoTotalItem.toFixed(this.decimalesTotalGre));//CDPJ
          //item.DETGRE_TOTAL = Number(item.DETGRE_TOTAL).toFixed(this.decimalesTotalGre);

          // cálculo del ice por línea de detalle
          if (item.DETGRE_TRIBICE !== null && item.DETGRE_TRIBICE !== '') {
            if (item.DETGRE_TRIBICE === 'S') { // si tributa ice
              dblBaseIceItem = item.DETGRE_CANTIDAD * item.DETGRE_PRECIO;
              item.DETGRE_BASEICE = dblBaseIceItem;
              if (item.DETGRE_PORCEICE > 0) {
                dblValorIceItem = dblBaseIceItem * item.DETGRE_PORCEICE / 100;
              } else if (item.DETGRE_ICE > 0) {
                dblValorIceItem = item.DETGRE_ICE;
              }
              item.DETGRE_ICE = dblValorIceItem;
            }
          }
          // càlculo del iva por línea de detalle
          let dblBaseIvaItem = 0;
          let dblValorIvaItem = 0;
          if (item.DETGRE_TRIBIVA === 'S') {
            dblBaseIvaItem = dblCostoTotalItem * (1 - Number(this.dblPorcDescGlbGrearray[this.init.tabs[this.init.tabindex].indice]) / 100);
          } else {
            dblBaseIvaItem = 0;
          }
          if (item.DETGRE_TRIBIVA === 'S') {
            if (item.DETGRE_TRIBICE === 'S') {
              //dblBaseIvaItem = dblBaseIvaItem + item.DETGRE_ICE;
              dblBaseIvaItem = dblBaseIvaItem ;//GSRF
            }
            item.DETGRE_BASECERO = 0;
            dblValorIvaItem = dblBaseIvaItem * Number(item.DETGRE_PORIVA) / 100; // IVA 12% DEFECTO

            item.DETGRE_IVA = dblValorIvaItem;
          } else {
            item.DETGRE_BASECERO = 0;
            dblBaseIvaItem = 0;
            item.DETGRE_BASECERO = (dblCostoTotalItem * (1 - Number(this.dblPorcDescGlbGrearray[this.init.tabs[this.init.tabindex].indice]) / 100));
            item.DETGRE_IVA = 0;
          }
          item.DETGRE_BASEIVA = dblBaseIvaItem;
        }
      }
    }
    // TOTALES DEL DOCUMENTO
    for (let fila2 = 0; fila2 < this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].length; fila2++) {
      dblValorNeto = Number(dblValorNeto) + Number(this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila2].DETGRE_TOTAL);
      if (this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila2].DETGRE_TIPODET !== '*') {
        if (this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila2].DETGRE_TRIBIVA === 'S') {
          dblValorIceTotal = dblValorIceTotal + Number(this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila2].DETGRE_ICE * this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila2].DETGRE_CANTIDAD);//GSRF
          dblBaseIceTotal = dblBaseIceTotal + Number(this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila2].DETGRE_BASEICE);
        }
      }
      dblBaseCeroTotal = dblBaseCeroTotal + Number(this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila2].DETGRE_BASECERO);
      dblBaseIvaTotal = dblBaseIvaTotal + Number(this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila2].DETGRE_BASEIVA);
      dblValorIvaTotal = dblValorIvaTotal + Number(this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila2].DETGRE_IVA);
    }
    for (let fila = 1; fila <= this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETGRE_CANTIDAD = Number(
        this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETGRE_CANTIDAD).toFixed(this.decimalesCantidadGre);
      this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETGRE_PRECIO = Number(
        this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETGRE_PRECIO).toFixed(this.decimalesPrecioGre);
      this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETGRE_TOTAL = Number(
        this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETGRE_TOTAL).toFixed(this.decimalesTotalGre);
      this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETGRE_DESCUENTO = Number(
        this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila - 1].DETGRE_DESCUENTO).toFixed(this.decimalesDescGre);
    }
    // dblValorTotal = Number(dblValorNeto) + Number(dblValorIvaTotal) + Number(dblValorIceTotal) - Number(
    //   this.dblValorDescGlbGrearray[this.init.tabs[this.init.tabindex].indice]);
    //CDPJ
    dblValorTotal = Number(dblValorNeto) + Number(dblValorIvaTotal.toFixed(this.confIniciales.getNumDecSist())) + Number(dblValorIceTotal.toFixed(this.confIniciales.getNumDecSist())) - Number(
      this.dblValorDescGlbGrearray[this.init.tabs[this.init.tabindex].indice]);
      console.log('Vneto',dblValorNeto)
      console.log('vIva',dblValorIvaTotal)
      console.log('descglobal',this.dblValorDescGlbGrearray[this.init.tabs[this.init.tabindex].indice])
    //CDPJ
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_TOTALNETO = String(dblValorNeto);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_TOTALNETO = Number(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_TOTALNETO).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORIVA = String(dblValorIvaTotal);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORIVA = Number(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORICE = String(dblValorIceTotal);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORICE = Number(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORICE).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASEICE = String(dblBaseIceTotal);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASEICE = Number(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASEICE).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASECERO = String(dblBaseCeroTotal);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASECERO = Number(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASECERO).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASEIVA = String(dblBaseIvaTotal);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASEIVA = Number(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_TOTAL = String(dblValorTotal);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_TOTAL = Number(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_TOTAL).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_PORCEDES = this.dblPorcDescGlbGrearray[
      this.init.tabs[this.init.tabindex].indice];
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_PORCEDES = Number(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_PORCEDES).toFixed(this.confIniciales.getNumDecSist());
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORDES = this.dblValorDescGlbGrearray[
      this.init.tabs[this.init.tabindex].indice];
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORDES = Number(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORDES).toFixed(this.confIniciales.getNumDecSist());
    // colocar los valores en los labels del pie de la factura

    this.comas();
  }
    //CDPJ
    leerValidarRucsFacElec() {

      return this.http.post<any[]>(this.way + '/seg/compania/198bf3d5eefcce000000000', {
        elementos: {},
        esquema: {
          usuario: this.usuarioEsquema.esquema,
          password: this.usuarioEsquema.password
        }
      }, this.confIniciales.httpOptions()).toPromise();;
    }
    redondearDecimales(num) {
      let val=0
      //console.log(num);
      val=(Math.round((num + Number.EPSILON) *100)/100);
      val=Number(Number(val).toFixed(this.decimalesTotalGre));
      //console.log(val.toFixed(2))
      //console.log('>><<<<<',total)
      return val;
    }
    validarcencodigo(cencodigo) {

      return this.http.post<any[]>(this.way + '/ventas/guiaremision/1f5564d56d8ebf0000000000000', {
        elementos: {
          cen_codigo: cencodigo,
          con_codigo: '01'
        }
      }, this.confIniciales.httpOptions()).toPromise();
  
    }
    encontrarArtCntaySer(strArtCodigo, strtipo) {
      return this.http.post<any[]>(this.way + '/ventas/guiaremision/75d98bf76adab8000000', {
        elementos: {
          ArtCodigo: strArtCodigo,
          strTIPO: strtipo
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    obtenerNumFE(encgrupo) {

      return this.http.post<any[]>(this.way + '/ventas/guiaremision/c2faebbb77f5a00', {
        elementos: {
          num_fe: encgrupo,
          
        }
      }, this.confIniciales.httpOptions()).toPromise();
  
    }
    obtenerEncgreEstado(encped) {
      return this.http.post<any[]>(this.way + '/ventas/guiaremision/617d75ddb75d900000000000', {
        elementos: {
          p_encgrenumero: encped
        },
      }, this.confIniciales.httpOptions()).toPromise();
    }
    obtenerEstFE(): Observable<any[]> {
      return this.http.post<any[]>(this.way + '/ventas/facturas/198bf3d5eab6ee0000', {
        elementos: {   
        }
      }, this.confIniciales.httpOptions());
    }
    obtenerbasesiva(numero) {
      return this.http.post<any>(this.way + '/ventas/guiaremision/107756ae3b92fb00000000', {
        elementos: {
          p_gre_numero:numero
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    actualizabasesiva(numero) {
      return this.http.post<any>(this.way + '/ventas/guiaremision/299d87a4a5ab8e00000', {
        elementos: {
          p_encgre_numero:numero,
          p_com_codigo:'01'
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    //CDPJ
  consultarImagen(artCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/0lvm5sux0p5rtfl', {
      elementos: {
        ART_CODIGO: artCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  getConfigFCEXNEG(): number {
    const FCEXSNEG = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCEXSNEG').CFG_VALOR1;
    return FCEXSNEG;
  }

  validarExistencias(strArtCodigo, strBodCodigo) {
    const fecha = new Date();
    const hora = fecha.getHours();
    const min = fecha.getMinutes();
    let fechafin=''
    //const horaAct = hora + ':' + min;
     //CDPJ
     let hora3;
     let minutos3;
     hora3 = this.horaSistema.getHours();
     minutos3 = this.horaSistema.getMinutes();
 
     if (hora3.toString().length === 1) {
       hora3 = '0' + hora3;
     } else if (minutos3.toString().length === 1) {
       minutos3 = '0' + minutos3;
     }
     const horaAct = hora3 + ':' + minutos3;
     //CDPJ

    if (this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_FECHAEMISION.toString().includes('-')) {
      fechafin = this.datePipe.transform(
        this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_FECHAEMISION,
        'dd/MM/yyyy');
    }else{
      fechafin=this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_FECHAEMISION;
    }


    return this.http.post<any[]>(this.way + '/ventas/guiaremision/v6hiigfo02e9vcl', {
      elementos: {
        ArtCodigo: strArtCodigo,
        BodCodigo: strBodCodigo,
        ENCGRE_FECHAEMISION: fechafin,
        HoraAct: horaAct
      },
    }, this.confIniciales.httpOptions()).toPromise();

  }

  async getCantActArt(existencia, item) {

    this.max = 0.0;
    this.cantExistArt = 0.0;
    this.cantExistArt = Number(existencia);
    if (Number(item.LINEA) > 0) {
      this.max =  this.sumarArticulo(item.DETGRE_CODIGO, Number(item.DETGRE_LINEA - 1));
    } else if (Number(item.LINEA === 0)) {
      this.max =  this.sumarArticulo(item.DETGRE_CODIGO, Number(item.LINEA - 1));
      
    }
    console.log('suma',this.sumarArticulo(item.DETGRE_CODIGO, Number(item.DETGRE_LINEA - 1)))
    this.strTipoIns = '';
    if (item.ENCFAC_NUMERO != null) {
      if (item.ENCFAC_NUMERO.trim() !== '') {
        this.strTipoIns = 'F';
        await this.ejecutarDetGreFac(item);
      }
    }

    if (item.ENCPED_NUMERO != null) {
      if (item.ENCPED_NUMERO.trim() !== '') {
        // this.ejecutarDetGrePed(item);
        await this.ejecutarCantGrabada(item);
      }
    }

    if ((item.ENCPED_NUMERO === '' || item.ENCPED_NUMERO === null) && (item.ENCFAC_NUMERO === '' || item.ENCFAC_NUMERO === null)) {
      await this.ejecutarCantGrabada(item);
    }

  }

  async cantidadVsExistencia(item) {
    const res = await this.validarExistencias(item.DETGRE_CODIGO, item.BOD_CODIGO);    
    for (const existArt of res) {      
      await this.getCantActArt(existArt.EXISTENCIA, item);
    }

  }
  //CDPJ
  async cantidadVsExistenciaNE(item) {
    const res = await this.validarExistencias(item.DETGRE_CODIGO, item.BOD_CODIGO);
     
    for (const existArt of res) {      
      await this.getCantActArtNE(existArt.EXISTENCIA, item);
    }

  }
  async getCantActArtNE(existencia, item) {

    this.max = 0.0;
    this.cantExistArt = 0.0;
    this.cantExistArt = Number(existencia);
    if (Number(item.LINEA) > 0) {
      this.max =  this.sumarArticulo(item.DETGRE_CODIGO, Number(item.DETGRE_LINEA - 1));
    } else if (Number(item.LINEA === 0)) {
      this.max =  this.sumarArticulo(item.DETGRE_CODIGO, Number(item.LINEA - 1));
    }
    console.log('suma',this.sumarArticulo(item.DETGRE_CODIGO, Number(item.DETGRE_LINEA - 1)))
     this.strTipoIns = '';
    // if (item.ENCFAC_NUMERO != null) {
    //   if (item.ENCFAC_NUMERO.trim() !== '') {
    //     this.strTipoIns = 'F';
    //     await this.ejecutarDetGreFac(item);
    //   }
    // }
    if (item.ENCPED_NUMERO != null) {
      if (item.ENCPED_NUMERO.trim() !== '') {
        // this.ejecutarDetGrePed(item);
        await this.ejecutarCantGrabadaNE(item);
      }
    }
    // if ((item.ENCPED_NUMERO === '' || item.ENCPED_NUMERO === null) && (item.ENCFAC_NUMERO === '' || item.ENCFAC_NUMERO === null)) {
    //   await this.ejecutarCantGrabadaNE(item);
    // }
  }
  async ejecutarCantGrabadaNE(item) {

    const res = await this.cantidadGrabadaGuiaRemision(item.DETGRE_CODIGO);

    if (res.length > 0) {
      for (const cantgrab of res) {
        await this.verificarCantGrabadaNE(cantgrab.DETGRE_CANTIDAD, item);
      }
    } else {
      await this.verificarCantGrabadaNE(0.0, item);
    }
  }
  async verificarCantGrabadaNE(cantidad, item) {

    let dblCantidadGrabada = 0.0;
    const min = item.DETGRE_DESPACHO;
    dblCantidadGrabada = Number(cantidad);

    this.max = this.max + dblCantidadGrabada;
    
    if (Number(this.max) > Number(this.cantExistArt) ) {
      console.log('verificar')
      if (this.strTipoIns === '') {
        // this.messageService.add({
        //   key: 'guiaRemision',
        //   severity: 'warn',
        //   summary: 'Información',
        //   detail: 'La cantidad que ha ingresado es mayor a la cantidad en existencia del articulo'
        // });
        this.control++
        console.log('1')
      } else {
        // this.messageService.add({
        //   key: 'guiaRemision',
        //   severity: 'warn',
        //   summary: 'Información',
        //   detail: 'La cantidad que ha ingresado es mayor a la cantidad de la factura  que falta por insertar en la guía de remisión'
        // });
        this.control++
        console.log('2')
      }
      
      for (const itemS of this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]) {        
        if (Number(item.DETGRE_LINEA) > 0) {          
          if (item.DETGRE_LINEA === itemS.DETGRE_LINEA) {
            if (Number(this.max) > Number(this.cantExistArt)) {
               

              //itemS.DETGRE_CANTIDAD = Number(this.cantExistArt);
            } else {

              //itemS.DETGRE_CANTIDAD = this.max;
            }
            itemS.EXISTENCIA=this.cantExistArt
          }
        } else if (Number(item.DETGRE_LINEA === 0)) {          
          if (item.LINEA === itemS.LINEA) {
            // if (this.max <= 0) {
            //   itemS.DETGRE_CANTIDAD = 0;
            // } else {
            //   itemS.DETGRE_CANTIDAD = this.max;
            // }
              if (Number(this.max) > Number(this.cantExistArt)) {
                
  
                //itemS.DETGRE_CANTIDAD = Number(this.cantExistArt);
              } else {
  
               // itemS.DETGRE_CANTIDAD = this.max;
              }
              itemS.EXISTENCIA=this.cantExistArt
          }
          
        }
      }
    }


    for (const itemS of this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]) {
      itemS.DETGRE_CANTIDAD = Number(itemS.DETGRE_CANTIDAD).toFixed(this.decimalesCantidadGre);      
    }

    if (item.DETGRE_CANTIDAD < Number(min)) {
      // this.messageService.add({
      //   key: 'guiaRemision',
      //   severity: 'warn',
      //   summary: 'Información',
      //   detail: 'La cantidad que ha ingresado es menor que la cantidad despachada del articulo'
      // });
      this.control++
      console.log('3')
      for (let fila = 0; fila < this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
        const itemT = this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila];
        if (item.LINEA === itemT.LINEA) {
          if (min < 0) {
            itemT.DETGRE_CANTIDAD = 0;
          } else {
            //itemT.DETGRE_CANTIDAD = min;
          }
        }
      }
    }

  }
//CDPJ

  sumarArticulo(artCodigo, actual): number {
    let suma = 0.0;
    for (let fila = 0; fila < this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
      const item = this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila];
      if (item.DETGRE_CODIGO === artCodigo && fila !== actual) {
        suma += Number(item.DETGRE_CANTIDAD);
      } else {
      }
    }
    return suma;
  }



  obtenerDisponibleDetGreFac(encfacNumero, idArticulo) {


    return this.http.post<any[]>(this.way + '/ventas/guiaremision/gkgcppux3f2oxd9', {
      elementos: {
        EncfacNumero: encfacNumero,
        IdArticulo: idArticulo
      },
    }, this.confIniciales.httpOptions());

  }

  obtenerDisponibleDetGreped(encpedNumero, idArticulo) {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/9788wbkbuznu97q', {
      elementos: {
        EncpedNumero: encpedNumero,
        IdArticulo: idArticulo
      }
    }, this.confIniciales.httpOptions());

  }





  ejecutarDetGrePed(item) {
    this.obtenerDisponibleDetGreped(item.ENCPED_NUMERO, item.ENCGRE_CODIGO).subscribe(res2 => {
      res2.map(existArt => {
        this.verificarCantidadDetGrePed(existArt.EXISTENCIA, item);
      });
    });

  }

  verificarCantidadDetGrePed(existencia, item) {
    let getDispDetGreFac = 0.0;
    getDispDetGreFac = Number(existencia);
    this.max = getDispDetGreFac - this.sumarArticulo(item.DETGRE_CODIGO, Number(item.DETPED_LINEA) - 1);
    this.ejecutarCantGrabada(item);

  }


  ejecutarDetGreFac(item) {
    this.obtenerDisponibleDetGreFac(item.ENCFAC_NUMERO, item.DETGRE_CODIGO).subscribe(res => {
      res.map(existArt => {
        this.verificarCantidadDetGreFac(existArt.EXISTENCIA, item);
      });
    });
  }

  verificarCantidadDetGreFac(existencia, item) {
    let getDispDetGreFac = 0.0;
    getDispDetGreFac = Number(existencia);
    this.max = getDispDetGreFac - this.sumarArticulo(item.DETGRE_CODIGO, Number(item.DETFAC_LINEA) - 1);
    this.ejecutarCantGrabada(item);

  }

  async ejecutarCantGrabada(item) {

    const res = await this.cantidadGrabadaGuiaRemision(item.DETGRE_CODIGO);

    if (res.length > 0) {
      for (const cantgrab of res) {
        await this.verificarCantGrabada(cantgrab.DETGRE_CANTIDAD, item);
      }
    } else {
      await this.verificarCantGrabada(0.0, item);
    }

  }

  async verificarCantGrabada(cantidad, item) {
    let dblCantidadGrabada = 0.0;
    const min = item.DETGRE_DESPACHO;
    dblCantidadGrabada = Number(cantidad);

    this.max = this.max + dblCantidadGrabada;
    
    if (Number(this.max) > Number(this.cantExistArt) ) {
      console.log('verificar')
      if (this.strTipoIns === '') {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'warn',
          summary: 'Información',
          detail: 'La cantidad que ha ingresado es mayor a la cantidad en existencia del articulo'
        });
      } else {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'warn',
          summary: 'Información',
          detail: 'La cantidad que ha ingresado es mayor a la cantidad de la factura  que falta por insertar en la guía de remisión'
        });
      }
      
      for (const itemS of this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]) {        
        if (Number(item.DETGRE_LINEA) > 0) {          
          if (item.DETGRE_LINEA === itemS.DETGRE_LINEA) {
            if (Number(this.max) > Number(this.cantExistArt)) {
               

              itemS.DETGRE_CANTIDAD = Number(this.cantExistArt);
            } else {

              itemS.DETGRE_CANTIDAD = this.max;
            }
          }
        } else if (Number(item.DETGRE_LINEA === 0)) {          
          if (item.LINEA === itemS.LINEA) {
            // if (this.max <= 0) {
            //   itemS.DETGRE_CANTIDAD = 0;
            // } else {
            //   itemS.DETGRE_CANTIDAD = this.max;
            // }
              if (Number(this.max) > Number(this.cantExistArt)) {
                
  
                itemS.DETGRE_CANTIDAD = Number(this.cantExistArt);
              } else {
  
                itemS.DETGRE_CANTIDAD = this.max;
              }
            
          }
        }
      }
    }


    for (const itemS of this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]) {
      itemS.DETGRE_CANTIDAD = Number(itemS.DETGRE_CANTIDAD).toFixed(this.decimalesCantidadGre);      
    }

    if (item.DETGRE_CANTIDAD < Number(min)) {
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'warn',
        summary: 'Información',
        detail: 'La cantidad que ha ingresado es menor que la cantidad despachada del articulo'
      });
      for (let fila = 0; fila < this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].length; fila++) {
        const itemT = this.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][fila];
        if (item.LINEA === itemT.LINEA) {
          if (min < 0) {
            itemT.DETGRE_CANTIDAD = 0;
          } else {
            itemT.DETGRE_CANTIDAD = min;
          }
        }
      }
    }
  }

  cantidadGrabadaGuiaRemision(strCodigoArticulo) {

    return this.http.post<any[]>(this.way + '/ventas/guiaremision/4q1v13unnsz3e58', {
      elementos: {
        ENCGRE_NUMERO: this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_NUMERO,
        CodigoArticulo: strCodigoArticulo
      }
    }, this.confIniciales.httpOptions()).toPromise();

  }

  insertarDetalleProm(vendetGuiaRemision: VendetGuiaRemision) {
    console.log('cen codigio',vendetGuiaRemision.CEN_CODIGO)
    if(vendetGuiaRemision.CEN_CODIGO === undefined || vendetGuiaRemision.CEN_CODIGO === null ){
      vendetGuiaRemision.CEN_CODIGO = '';
    }
    console.log('cen codigio',vendetGuiaRemision.CEN_CODIGO)
    return this.http.post(this.way + '/ventas/guiaremision/b7l7na57tpftwk1', {
      elementos: {
        p_encgre_codigo: Number(vendetGuiaRemision.ENCGRE_CODIGO),
        p_encgre_numero: vendetGuiaRemision.ENCGRE_NUMERO,
        p_detgre_linea: Number(vendetGuiaRemision.DETGRE_LINEA),
        p_detgre_tipodet: vendetGuiaRemision.DETGRE_TIPODET,
        p_detgre_codigo: vendetGuiaRemision.DETGRE_CODIGO,
        p_detgre_descripcion: vendetGuiaRemision.DETGRE_DESCRIPCION,
        p_detgre_unidad: vendetGuiaRemision.DETGRE_UNIDAD,
        p_detgre_cantidad: Number(vendetGuiaRemision.DETGRE_CANTIDAD),
        p_bod_codigo: vendetGuiaRemision.BOD_CODIGO,
        p_encfac_numero: vendetGuiaRemision.ENCFAC_NUMERO,
        p_detfac_linea: Number(vendetGuiaRemision.DETFAC_LINEA),
        p_detfac_comcodigo: vendetGuiaRemision.DETFAC_COMCODIGO,
        p_com_codigo: '01',
        p_detgre_precio: Number(vendetGuiaRemision.DETGRE_PRECIO),
        p_detgre_descuento: Number(vendetGuiaRemision.DETGRE_DESCUENTO),
        p_detgre_total: Number(vendetGuiaRemision.DETGRE_TOTAL),
        p_detgre_tribiva: vendetGuiaRemision.DETGRE_TRIBIVA,
        p_detgre_iva: Number(vendetGuiaRemision.DETGRE_IVA),
        p_detgre_tribice: vendetGuiaRemision.DETGRE_TRIBICE,
        p_detgre_ice: Number(vendetGuiaRemision.DETGRE_ICE),
        p_detgre_porceice: Number(vendetGuiaRemision.DETGRE_PORCEICE),
        p_detgre_lista: vendetGuiaRemision.DETGRE_LISTA,
        p_detgre_baseiva: Number(vendetGuiaRemision.DETGRE_BASEIVA),
        p_detgre_baseice: Number(vendetGuiaRemision.DETGRE_BASEICE),
        p_detgre_basecero: Number(vendetGuiaRemision.DETGRE_BASECERO),
        p_detgre_promocion: vendetGuiaRemision.DETGRE_PROMOCION,
        p_detgre_orden: Number(vendetGuiaRemision.DETGRE_ORDEN),
        p_encped_numero: vendetGuiaRemision.ENCPED_NUMERO,
        p_detped_linea: Number(vendetGuiaRemision.DETPED_LINEA),
        p_cen_codigo: vendetGuiaRemision.CEN_CODIGO,
        p_detger_bulto: Number(vendetGuiaRemision.DETGRE_BULTO),
        p_detgre_cantidadb: Number(vendetGuiaRemision.DETGRE_CANTIDADB),
        p_detgre_cantidadund: Number(vendetGuiaRemision.DETGRE_CANTIDADUND),
        p_linea: Number(vendetGuiaRemision.LINEA),
        p_detgre_poriva:Number(vendetGuiaRemision.DETGRE_PORIVA),
        p_tariiva_codigo:vendetGuiaRemision.TARIIVA_CODIGO

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarENCGREProm(encGre: VenencGuiaRemision) {
    console.log(encGre);
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/87yq6rwe9s4qv44', {
      elementos: {
        p_encgre_codigo: encGre.ENCGRE_CODIGO2,
        p_encgre_numero: encGre.ENCGRE_NUMERO,
        p_com_codigo: '01',
        p_cli_codigo: encGre.CLI_CODIGO,
        p_bod_codigo: encGre.BOD_CODIGO,
        p_encgre_fechaemision: encGre.ENCGRE_FECHAEMISION,
        p_encgre_motivo: encGre.ENCGRE_MOTIVO,
        p_encgre_ptopartida: encGre.ENCGRE_PTOPARTIDA,
        p_encgre_nmbrdstntr: encGre.ENCGRE_NMBRDSTNTR,
        p_encgre_rucdstntr: encGre.ENCGRE_RUCDSTNTR,
        p_encgre_ptollegada: encGre.ENCGRE_PTOLLEGADA,
        p_encgre_nmbrtrnsp: encGre.ENCGRE_NMBRTRNSP,
        p_encgre_ructrnsp: encGre.ENCGRE_RUCTRNSP,
        p_transport_codigo: encGre.TRANSPORT_CODIGO,
        p_encfac_numero: encGre.ENCFAC_NUMERO,
        p_encfac_comcodigo: encGre.ENCFAC_COMCODIGO,
        p_encgre_direcciondstntr: encGre.ENCGRE_DIRECCIONDSTNTR,
        p_encgre_telefonodstntr: encGre.ENCGRE_TELEFONODSTNTR,
        p_encgre_autotntr: encGre.ENCGRE_AUTOTNTR,
        p_encgre_placatntr: encGre.ENCGRE_PLACATNTR,
        p_encgre_motivoletras: encGre.ENCGRE_MOTIVOLETRAS,
        p_encgre_iva: encGre.ENCGRE_IVA,
        p_encgre_totalneto: Number(encGre.ENCGRE_TOTALNETO),
        p_encgre_valordes: Number(encGre.ENCGRE_VALORDES),
        p_encgre_porcedes: Number(encGre.ENCGRE_PORCEDES),
        p_encgre_valoriva: Number(encGre.ENCGRE_VALORIVA),
        p_encgre_porceiva: Number(encGre.ENCGRE_PORCEIVA),
        p_encgre_valorice: Number(encGre.ENCGRE_VALORICE),
        p_encgre_porceice: Number(encGre.ENCGRE_PORCEICE),
        p_encgre_baseiva: Number(encGre.ENCGRE_BASEIVA),
        p_encgre_baseice: Number(encGre.ENCGRE_BASEICE),
        p_encgre_basecero: Number(encGre.ENCGRE_BASECERO),
        p_encgre_comentario: encGre.ENCGRE_COMENTARIO,
        p_encgre_fecinitraslado: encGre.ENCGRE_FECINITRASLADO,
        p_encgre_fechfintraslado: encGre.ENCGRE_FECHFINTRASLADO,
        p_encgre_lista: encGre.ENCGRE_LISTA,
        p_encgre_total: Number(encGre.ENCGRE_TOTAL),
        p_encgre_origen: encGre.ENCGRE_ORIGEN,
        p_encgre_estado: encGre.ENCGRE_ESTADO,
        p_encgre_coolerserial: encGre.ENCGRE_COOLERSERIAL,
        p_encgre_grupo:encGre.ENCGRE_GRUPO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  registrarKardexProm(encgreCodigo: string) {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/nigmze196sa5s1h', {
      elementos: {
        p_encgre_codigo: encgreCodigo,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  comas() {
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_TOTALNETO = this.init.moneyValidation(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_TOTALNETO);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORIVA = this.init.moneyValidation(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORIVA);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORICE = this.init.moneyValidation(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORICE);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASEICE = this.init.moneyValidation(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASEICE);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASECERO = this.init.moneyValidation(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASECERO);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASEIVA = this.init.moneyValidation(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_BASEIVA);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_TOTAL = this.init.moneyValidation(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_TOTAL);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_PORCEDES = this.init.moneyValidation(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_PORCEDES);
    this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORDES = this.init.moneyValidation(
      this.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_VALORDES);


  }

  actualizarDatosElectronicos(encgre_numero) {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/7ulpxbh99v7jjli', {
      elementos: {
        ENCGRE_NUMERO: encgre_numero
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }



  erCodigoEncabezado(cli_codigo) {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/nh5u4y6wibykxjj', {
      elementos: {
        CLI_CODIGO: cli_codigo
      },
    }, this.confIniciales.httpOptions());
  }

  getConfiguracionNumeracion(strCodigo): string {
    const config = this.hmpConfiguracion.find(element => element.CFG_CODIGO === strCodigo).CFG_VALOR3;
    return config;
  }

  obtenerNumeracionG(strTipoDoc) {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/41ddbfaceda990', {
      elementos: {
        TipoDoc: strTipoDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }


  obtenerNumeracion(strClase) {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/20eedfd676d4c', {
      elementos: {
        clase: strClase
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarNumeracion(params: any[]) {
    console.log(params);
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/14cefafeb3b6a', {
      elementos: {
        p_NUM_clase: params[0],
        p_COM_codigo: params[1],
        p_NUM_docref: params[2],
        p_NUM_nemonico: params[3],
        p_NUM_serie: params[4]
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  leerConfiguracionDatosElectronicos(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/facturas/2ymq2d11hk53thr', {
      elementos: {},
      esquema: {
        usuario: this.usuarioEsquema.esquema,
        password: this.usuarioEsquema.password
      }
    }, this.confIniciales.httpOptions());
  }

  actualizaFacturadoPedido(encpednumero): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/facturas/14cebd4cefb2e70000', {
      elementos: {
        p_encped_numero: encpednumero,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  verificaEstadoPedidosFact(encpedNumero: string): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/guiaremision/3eedc9fceb72e60000', {
      elementos: {
        p_ENCPED_numero: encpedNumero,
        p_COM_CODIGO: '01'
      },
    }, this.confIniciales.httpOptions());
  }


}

