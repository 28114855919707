<script src="invmaearticulo.component.ts"></script>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="art"></p-toast>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [barraBotones3]="barraBotones2"
			[barraBotonesAsiCon]="barraBotones2" [botonBorrar]="botonBorrar" [botonNuevo]="botonNuevo"
			[botonGuardar]="botonGuardar" [botonBuscar]="false" [botonRegresar]="botonRegresar"
			[botonPrimero]="!botonPrimero" [botonAnterior]="!botonAnterior" [botonSiguiente]="!botonSiguiente"
			[botonUltimo]="!botonUltimo" [barraBotones5]="false" [botonAlmacenardoc]="true"></app-invmaebarra>
	</div>
</div>
<p-tabView [(activeIndex)]="index" (onChange)="gridsnull()">
	<p-tabPanel header="Generales">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
			<div class="contorno" id="contorno">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1">
									<span class="obligatorio">Grupo: (x)</span>
								</div>
								<div class="p-col-1">
									<label for="GRUP_CODIGO"></label>
									<input class="frm" id="GRUP_CODIGO" type="text" pInputText
										[(ngModel)]="maearticulo.GRUP_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedagrupo(maearticulo.GRUP_CODIGO)"></button>
								</div>
								<div class="p-col-3">
									<label for="GRUP_NOMBRE"></label>
									<input class="frm" id="GRUP_NOMBRE" type="text" pInputText disabled
										[(ngModel)]="maearticulo.GRUP_NOMBRE" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Subgrupo:</span>
								</div>
								<div class="p-col-1">
									<label for="GRUP_CODIGOP"></label>
									<input class="frm" id="GRUP_CODIGOP" type="text" pInputText
										[(ngModel)]="maearticulo.GRUP_CODIGOP" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedasubgrupo(maearticulo.GRUP_CODIGOP)"></button>
								</div>
								<div class="p-col-3">
									<label for="GRUP_NOMBREP"></label>
									<input class="frm" id="GRUP_NOMBREP" type="text" pInputText disabled
										[(ngModel)]="maearticulo.GRUP_NOMBREP" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -18px">
							<div class="p-grid">
								<div class="p-col-1">
									<span class="obligatorio">Código: (x)</span>
								</div>
								<div class="p-col-2">
									<label for="ART_CODIGO"></label>
									<input class="frm" id="ART_CODIGO" type="text" pInputText [disabled]="codactiv"
										[(ngModel)]="maearticulo.ART_CODIGO" style="text-transform: uppercase"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										(focusout)="setFocus($event)" autocomplete="off">
								</div>
								<div class="p-col-2">
									<span class="obligatorio">Nombre: (x)</span>
								</div>
								<div class="p-col-7">
									<label for="ART_NOMBRE"></label>
									<input class="frm" id="ART_NOMBRE" type="text" pInputText
										[(ngModel)]="maearticulo.ART_NOMBRE" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1">
									<span class="obligatorio">Alias:</span>
								</div>
								<div class="p-col-2">
									<label for="ART_NOMBREC"></label>
									<input class="frm" id="ART_NOMBREC" type="text" pInputText
										[(ngModel)]="maearticulo.ART_NOMBREC" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<span>Código de Barras:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_CODIGOALT1"></label>
									<input class="frm" id="ART_CODIGOALT1" type="text" pInputText
										[(ngModel)]="maearticulo.ART_CODIGOALT1" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Ubicación Física:</span>
								</div>
								<div class="p-col-3">
									<label for="ART_CODIGOALT2"></label>
									<input class="frm" id="ART_CODIGOALT2" type="text" pInputText
										[(ngModel)]="maearticulo.ART_CODIGOALT2" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px; margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Bod-Def:</span>
								</div>
								<div class="p-col-2">
									<label for="ART_UBICACION"></label>
									<input class="frm" id="ART_UBICACION" type="text" pInputText
										[(ngModel)]="maearticulo.ART_UBICACION" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<span>Meses de garantía:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_DIASGARANTIA"></label>
									<input class="frm" id="ART_DIASGARANTIA" type="text" pInputText
										[(ngModel)]="maearticulo.ART_DIASGARANTIA" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Fecha: </span>
								</div>
								<div class="p-col-3">
									<p-calendar id="ART_FECHAING" [inputStyle]="{width: '80%', height: '20px'}"
										[(ngModel)]="maearticulo.ART_FECHAING" [readonlyInput]="true"
										dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()">
									</p-calendar>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-2">
											<p-checkbox name="group1" value="" label="" binary="{{chkactivo}}"
												[(ngModel)]="chkactivo" (click)="botonesmodificar()"></p-checkbox>
										</div>
										<div class="p-col-10">
											<span>Activo</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-3">
									<div class="p-grid">
										
											
												<div class="p-col-1">
													<p-checkbox name="group1" value="" label="" binary="{{chktributaiva}}"
														[(ngModel)]="chktributaiva" (click)="botonesmodificar()"></p-checkbox>
												</div>
												<div class="p-col-2">
													<span>%IVA:</span>
												</div>
											
											
										
										<div class="p-col-3">
											<label for="TARIIVA_CODIGO"></label>
											<input class="frm" id="TARIIVA_CODIGO" type="text" pInputText
												[(ngModel)]="maearticulo.TARIIVA_CODIGO"
												(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												autocomplete="off">
										</div>
										<div class="p-col-2">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedatariivacod(maearticulo.TARIIVA_CODIGO)"></button>
										</div>
										<div class="p-col-4">
											<label for="TARIIVA_DESCRIPCION"></label>
											<input class="frm" id="TARIIVA_DESCRIPCION" type="text" pInputText disabled
												[(ngModel)]="maearticulo.TARIIVA_DESCRIPCION" autocomplete="off">
										</div>
										<div *ngIf="oculto" class="p-col-1">
											<p-checkbox name="group1" value="" label="" binary="{{chktributaiva}}"
												[(ngModel)]="chktributaiva" (click)="botonesmodificar()"></p-checkbox>
										</div>
										<div *ngIf="oculto" class="p-col-4">
											<span>Tributa IVA</span>
										</div>
									</div>
								</div>
								
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-3">
											<span>%ICE:</span>
										</div>
										<div class="p-col-3">
											<label for="ART_CODIGOICE"></label>
											<input class="frm" id="ART_CODIGOICE" type="text" pInputText
												[(ngModel)]="maearticulo.ART_CODIGOICE"
												(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
												autocomplete="off">
										</div>
										<div class="p-col-2">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedaice(maearticulo.ART_CODIGOICE)"></button>
										</div>
										<div class="p-col-4">
											<label for="IMP_DESCRIPCION"></label>
											<input class="frm" id="IMP_DESCRIPCION" type="text" pInputText disabled
												[(ngModel)]="maearticulo.IMP_DESCRIPCION" autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<span>Valor ICE:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_VALORICE"></label>
									<input class="frm" id="ART_VALORICE" type="text" pInputText style="width: 100%"
										[(ngModel)]="maearticulo.ART_VALORICE" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>C.SRI Ice:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_CODSRIICE"></label>
									<input class="frm" id="ART_CODSRIICE" type="text" pInputText style="width: 100%"
										[(ngModel)]="maearticulo.ART_CODSRIICE" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedacsriice(maearticulo.ART_CODSRIICE)"></button>
								</div>
								<div class="p-col-1">
									<label for="ART_NOMSRIICE"></label>
									<input class="frm" id="ART_NOMSRIICE" type="text" pInputText disabled
										[(ngModel)]="maearticulo.ART_NOMSRIICE" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -25px; margin-bottom: -25px">
							<div class="p-grid">							
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-1">
											<p-checkbox name="group1" value="" label="" binary="{{chkcompuesto}}"
												[(ngModel)]="chkcompuesto" (click)="botonesmodificar()"></p-checkbox>
										</div>
										<div class="p-col-5">
											<span>Compuesto</span>
										</div>
										
											<div class="p-col-1">
												<p-checkbox name="chkactivarserial" value="" label=""
													binary="{{chkactivarserialfac}}" [(ngModel)]="chkactivarserialfac"
													(click)="botonesmodificar()"></p-checkbox>
											</div>
											<div class="p-col-5">
												<span>Activar Seriales</span>
											</div>
									</div>
									
										
									
								</div>
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-1">
											<p-checkbox name="chktributaice" value="" label=""
												binary="{{chktributaice}}" [(ngModel)]="chktributaice"
												(click)="botonesmodificar()"></p-checkbox>
										</div>
										<div class="p-col-4">
											<span>Tributa ICE</span>
										</div>
										<div class="p-col-1">
											<p-checkbox name="chkbtnservicio" value="" label=""
												binary="{{chkbtnservicio}}" [(ngModel)]="chkbtnservicio"
												(click)="botonesmodificar()"></p-checkbox>
										</div>
										<div class="p-col-5">
											<span>Aplica Servicio</span>
										</div>
										<div *ngIf="oculto" class="p-col-1">
											<p-checkbox name="chkbnobojiva" value="" label="" binary="{{chkbnobojiva}}"
												[(ngModel)]="chkbnobojiva" (click)="botonesmodificar()"></p-checkbox>
										</div>
										<div *ngIf="oculto" class="p-col-5">
											<span>B. No Obj. de Iva</span>
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<span>Descuento</span>
								</div>
								<div class="p-col-1">
									<label for="ART_DESCUENTO"></label>
									<input class="frm" id="ART_DESCUENTO" type="text" pInputText style="width: 100%"
										[(ngModel)]="maearticulo.ART_DESCUENTO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Costo Histórico:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_COSTOHISTORICO"></label>
									<input class="frm" id="ART_COSTOHISTORICO" type="text" pInputText
										style="width: 100%" [(ngModel)]="maearticulo.ART_COSTOHISTORICO"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<!-- <div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"></button>
								</div> -->
								<div class="p-col-1">
									<!-- CDPJ -->
									<div class="p-grid">
										<div class="p-col-5">
											<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"></button>
										</div>
										<div class="p-col-1">
											<span>IRBPNR: </span>
										</div>
									</div>
									<!-- CDPJ -->
								</div>
								<!-- CDPJ -->
								<div class="p-col-1">
											<label for="ART_IRBPNR"></label>
											<input  id="ART_IRBPNR" type="text" pInputText pKeyFilter="num" style="width: 100%"
												[(ngModel)]="maearticulo.ART_IRBPNR" (keydown.enter)="setFocus($event)"
												(input)="validarNumero()"
												(keydown)="botonesmodificar()" autocomplete="off">							
								</div>
								<!-- CDPJ -->
							</div>
						</div>
					</div>
				</div>
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Peso:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_PESO"></label>
									<input class="frm" id="ART_PESO" type="text" pInputText
										[(ngModel)]="maearticulo.ART_PESO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-1">
									<span>Largo:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_LARGO"></label>
									<input class="frm" id="ART_LARGO" type="text" pInputText
										[(ngModel)]="maearticulo.ART_LARGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Ancho:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_ANCHO"></label>
									<input class="frm" id="ART_ANCHO" type="text" pInputText
										[(ngModel)]="maearticulo.ART_ANCHO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Alto:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_ALTURA"></label>
									<input class="frm" id="ART_ALTURA" type="text" pInputText
										[(ngModel)]="maearticulo.ART_ALTURA" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-1">
									<span>Densidad:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_DENSIDAD"></label>
									<input class="frm" id="ART_DENSIDAD" type="text" pInputText
										[(ngModel)]="maearticulo.ART_DENSIDAD" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px; margin-bottom: -17px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Volumen:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_VOLUMEN"></label>
									<input class="frm" id="ART_VOLUMEN" type="text" pInputText
										[(ngModel)]="maearticulo.ART_VOLUMEN" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Modelo:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_MODELO"></label>
									<input class="frm" id="ART_MODELO" type="text" pInputText
										[(ngModel)]="maearticulo.ART_MODELO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2"></div>
								<div class="p-col-1">
									<span>Marca:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_MARCA"></label>
									<input class="frm" id="ART_MARCA" type="text" pInputText
										[(ngModel)]="maearticulo.ART_MARCA" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Serie:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_SERIE"></label>
									<input class="frm" id="ART_SERIE" type="text" pInputText
										[(ngModel)]="maearticulo.ART_SERIE" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1">
									<div class="p-grid">
										<div class="p-col-3">
											<p-checkbox name="chkmultiunidad" value="" label=""
												binary="{{chkmultiunidad}}" [(ngModel)]="chkmultiunidad"
												(click)="botonesmodificar()"></p-checkbox>
										</div>
										<div class="p-col-9">
											<span>Multiunidad</span>
										</div>
									</div>
								</div>
								<div class="p-col-2"></div>
								<div class="p-col-1">
									<span class="obligatorio">Costeo: (x)</span>
								</div>
								<div class="p-col-1">
									<label for="ART_UNIDADCOSTEO"></label>
									<input class="frm" id="ART_UNIDADCOSTEO" type="text" pInputText style="width: 100%"
										[(ngModel)]="maearticulo.ART_UNIDADCOSTEO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off" [disabled]="bolkardex">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedaunidadcom(maearticulo.ART_UNIDADCOSTEO)" [disabled]="bolkardex"></button>
								</div>
								<div class="p-col-1">
									<label for="NOMBREUNIDADCOMPRA"></label>
									<input class="frm" id="NOMBREUNIDADCOMPRA" type="text" pInputText disabled
										[(ngModel)]="maearticulo.NOMBREUNIDADCOMPRA" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span class="obligatorio">Venta: (x)</span>
								</div>
								<div class="p-col-1">
									<label for="ART_UNIDADVENTA"></label>
									<input class="frm" id="ART_UNIDADVENTA" type="text" pInputText style="width: 100%"
										[(ngModel)]="maearticulo.ART_UNIDADVENTA" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedaunidadven(maearticulo.ART_UNIDADVENTA)"></button>
								</div>
								<div class="p-col-1">
									<label for="NOMBREUNIDADVENTA"></label>
									<input class="frm" id="NOMBREUNIDADVENTA" type="text" pInputText disabled
										[(ngModel)]="maearticulo.NOMBREUNIDADVENTA" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -20px; margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-2">
									<span>Cantidad Mínima:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_CANTMIN"></label>
									<input class="frm" id="ART_CANTMIN" type="text" pInputText
										[(ngModel)]="maearticulo.ART_CANTMIN" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<span>Cantidad Máxima:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_CANTMAX"></label>
									<input class="frm" id="ART_CANTMAX" type="text" pInputText
										[(ngModel)]="maearticulo.ART_CANTMAX" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<span>Subsidio:</span>
								</div>
								<div class="p-col-1">
									<label for="ART_SUBSIDIO"></label>
									<input class="frm" id="ART_SUBSIDIO" type="text" pInputText
										[(ngModel)]="maearticulo.ART_SUBSIDIO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-3">
									<div class="p-grid">
										<div class="p-col-1">
											<p-checkbox name="group1" value="" label="" binary="{{chkcaduca}}"
												[(ngModel)]="chkcaduca" (click)="botonesmodificar()"></p-checkbox>
										</div>
										<div class="p-col-10">
											<span>Caduca</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Observaciones:</span>
						</div>
						<div class="p-col-10">
							<label for="ART_OBSERVACION"></label>
							<input class="frm" id="ART_OBSERVACION" type="text" pInputText style="width: 100%"
								[(ngModel)]="maearticulo.ART_OBSERVACION" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
					</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Adicionales">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-1">
						<span>Cod. Alt 3:</span>
					</div>
					<div class="p-col-2">
						<label for="ART_CODIGOALT3"></label>
						<input class="frm" id="ART_CODIGOALT3" type="text" pInputText
							[(ngModel)]="maearticulo.ART_CODIGOALT3" (keydown.enter)="setFocus($event)"
							(keydown)="botonesmodificar()" autocomplete="off">
					</div>
					<div class="p-col-2">
						<span>Cod. Alt 4:</span>
					</div>
					<div class="p-col-2">
						<label for="ART_CODIGOALT4"></label>
						<input class="frm" id="ART_CODIGOALT4" type="text" pInputText
							[(ngModel)]="maearticulo.ART_CODIGOALT4" (keydown.enter)="setFocus($event)"
							(keydown)="botonesmodificar()" autocomplete="off">
					</div>
				</div>
			</div>
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Inventario:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<label for="CON_CODIGOACT"></label>
										<input class="frm" id="CON_CODIGOACT" type="text" pInputText style="width: 100%"
											[(ngModel)]="maearticulo.CON_CODIGOACT" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacodigoact(maearticulo.CON_CODIGOACT)"></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="CON_NOMBREACT"></label>
								<input class="frm" id="CON_NOMBREACT" type="text" pInputText disabled
									[(ngModel)]="maearticulo.CON_NOMBREACT" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Descuento:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<label for="CON_CODIGODSCTO"></label>
										<input class="frm" id="CON_CODIGODSCTO" type="text" pInputText
											[(ngModel)]="maearticulo.CON_CODIGODSCTO" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacodigodscto(maearticulo.CON_CODIGODSCTO)"></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="CON_NOMBREDSCTO"></label>
								<input class="frm" id="CON_NOMBREDSCTO" type="text" pInputText disabled
									[(ngModel)]="maearticulo.CON_NOMBREDSCTO" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -22px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Costo de Ventas:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<label for="CON_CODIGOGAS"></label>
										<input class="frm" id="CON_CODIGOGAS" type="text" pInputText style="width: 100%"
											[(ngModel)]="maearticulo.CON_CODIGOGAS" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacodigogas(maearticulo.CON_CODIGOGAS)"></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="CON_NOMBREGAS"></label>
								<input class="frm" id="CON_NOMBREGAS" type="text" pInputText disabled
									[(ngModel)]="maearticulo.CON_NOMBREGAS" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Ventas NV:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<label for="CON_CODIGOING2"></label>
										<input class="frm" id="CON_CODIGOING2" type="text" pInputText
											[(ngModel)]="maearticulo.CON_CODIGOING2" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacodigoing2(maearticulo.CON_CODIGOING2)"></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="CON_NOMBREING2"></label>
								<input class="frm" id="CON_NOMBREING2" type="text" pInputText disabled
									[(ngModel)]="maearticulo.CON_NOMBREING2" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -22px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Ventas:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<label for="CON_CODIGOING"></label>
										<input class="frm" id="CON_CODIGOING" type="text" pInputText style="width: 100%"
											[(ngModel)]="maearticulo.CON_CODIGOING" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacodigoing(maearticulo.CON_CODIGOING)"></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="CON_NOMBREING"></label>
								<input class="frm" id="CON_NOMBREING" type="text" pInputText disabled
									[(ngModel)]="maearticulo.CON_NOMBREING" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Descuento NV:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<label for="CON_CODIGODSCTO2"></label>
										<input class="frm" id="CON_CODIGODSCTO2" type="text" pInputText
											[(ngModel)]="maearticulo.CON_CODIGODSCTO2"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacodigodscto2(maearticulo.CON_CODIGODSCTO2)"></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="CON_NOMBREDSCTO2"></label>
								<input class="frm" id="CON_NOMBREDSCTO2" type="text" pInputText disabled
									[(ngModel)]="maearticulo.CON_NOMBREDSCTO2" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -22px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Cuenta Orden (1):</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<label for="CON_CODIGOORD1"></label>
										<input class="frm" id="CON_CODIGOORD1" type="text" pInputText
											style="width: 100%" [(ngModel)]="maearticulo.CON_CODIGOORD1"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacodigoord1(maearticulo.CON_CODIGOORD1)"></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="CON_NOMBREORD1"></label>
								<input class="frm" id="CON_NOMBREORD1" type="text" pInputText disabled
									[(ngModel)]="maearticulo.CON_NOMBREORD1" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Prod en Pro.:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<label for="CON_CODIGOPRODPROC"></label>
										<input class="frm" id="CON_CODIGOPRODPROC" type="text" pInputText
											[(ngModel)]="maearticulo.CON_CODIGOPRODPROC"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacodigoprodproc(maearticulo.CON_CODIGOPRODPROC)"></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="CON_NOMBREPRODPROC"></label>
								<input class="frm" id="CON_NOMBREPRODPROC" type="text" pInputText disabled
									[(ngModel)]="maearticulo.CON_NOMBREPRODPROC" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -22px; margin-bottom: -20px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Cuenta Orden (2):</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<label for="CON_CODIGOORD2"></label>
										<input class="frm" id="CON_CODIGOORD2" type="text" pInputText
											style="width: 100%" [(ngModel)]="maearticulo.CON_CODIGOORD2"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacodigoord2(maearticulo.CON_CODIGOORD2)"></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="CON_NOMBREORD2"></label>
								<input class="frm" id="CON_NOMBREORD2" type="text" pInputText disabled
									[(ngModel)]="maearticulo.CON_NOMBREORD2" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Centro C:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-9">
										<label for="CEN_CODIGO"></label>
										<input class="frm" id="CEN_CODIGO" type="text" pInputText
											[(ngModel)]="maearticulo.CEN_CODIGO" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacodigocentro(maearticulo.CEN_CODIGO)"></button>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<label for="CEN_NOMBRE"></label>
								<input class="frm" id="CEN_NOMBRE" type="text" pInputText disabled
									[(ngModel)]="maearticulo.CEN_NOMBRE" autocomplete="off">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="divgrups">
				<div class="p-grid" style="margin-bottom: -10px">
					<div class="p-col-2">
						<span>Form. Sri. Compras.:</span>
					</div>
					<div class="p-col-1">
						<label for="ART_FORMSRICOM"></label>
						<input class="frm" id="ART_FORMSRICOM" type="text" pInputText style="width: 100%"
							[(ngModel)]="maearticulo.ART_FORMSRICOM" (keydown.enter)="setFocus($event)"
							(keydown)="botonesmodificar()" autocomplete="off">
					</div>
					<div class="p-col-1">
						<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
							(click)="busquedarescom(maearticulo.ART_FORMSRICOM)"></button>
					</div>
					<div class="p-col-2">
						<label for="ART_NOMFORMSRICOM"></label>
						<input class="frm" id="ART_NOMFORMSRICOM" type="text" pInputText disabled
							[(ngModel)]="maearticulo.ART_NOMFORMSRICOM" autocomplete="off">
					</div>
					<div class="p-col-2">
						<span>Form. Sri. Ventas.:</span>
					</div>
					<div class="p-col-1">
						<label for="ART_FORMSRIVTAS"></label>
						<input class="frm" id="ART_FORMSRIVTAS" type="text" pInputText
							[(ngModel)]="maearticulo.ART_FORMSRIVTAS" (keydown.enter)="setFocus($event)"
							(keydown)="botonesmodificar()" autocomplete="off">
					</div>
					<div class="p-col-1">
						<button type="button" style="background-image: url(../../../assets/images/iconos/buscar.png);"
							(click)="busquedaresvta(maearticulo.ART_FORMSRIVTAS)"></button>
					</div>
					<div class="p-col-2">
						<label for="ART_NOMFORMSRIVTAS"></label>
						<input class="frm" id="ART_NOMFORMSRIVTAS" type="text" pInputText disabled
							[(ngModel)]="maearticulo.ART_NOMFORMSRIVTAS" autocomplete="off">
					</div>
				</div>
			</div>
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-2">
										<p-checkbox name="chkactivarserial" value="" label=""
											binary="{{chkactivarserial}}" [(ngModel)]="chkactivarserial"
											(click)="botonesmodificar()"></p-checkbox>
									</div>
									<div class="p-col-10">
										<span>Forzar Ingreso Serial</span>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-2">
										<p-checkbox name="chkactivardim" value="" label="" binary="{{chkactivardim}}"
											[(ngModel)]="chkactivardim" (click)="botonesmodificar()"></p-checkbox>
									</div>
									<div class="p-col-10">
										<span>Forzar Ingreso DIM</span>
									</div>
								</div>
							</div>
							<div class="p-col-3">
								<div class="p-grid">
									<div class="p-col-2">
										<p-checkbox name="chkactivartelefono" value="" label=""
											binary="{{chkactivartelefono}}" [(ngModel)]="chkactivartelefono"
											(click)="botonesmodificar()"></p-checkbox>
									</div>
									<div class="p-col-10">
										<span>Forzar Ingreso Número Teléfono</span>
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-2">
										<p-checkbox name="chkmateriaprima" value="" label=""
											binary="{{chkmateriaprima}}" [(ngModel)]="chkmateriaprima"
											(click)="botonesmodificar()"></p-checkbox>
									</div>
									<div class="p-col-10">
										<span>Materia Prima</span>
									</div>
								</div>
							</div>
							<div class="p-col-1">
								<span>Campaña:</span>
							</div>
							<div class="p-col-1">
								<label for="ART_CAMPANIA"></label>
								<input class="frm" id="ART_CAMPANIA" type="text" pInputText style="width: 100%"
									[(ngModel)]="maearticulo.ART_CAMPANIA" (keydown.enter)="setFocus($event)"
									autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedacampana(maearticulo.ART_CAMPANIA)"></button>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px; margin-bottom: -10px">
						<div class="p-grid">
							<div class="p-col-1">
								<span>Presentación:</span>
							</div>
							<div class="p-col-1">
								<label for="PRESENTACION_CODIGO"></label>
								<input class="frm" id="PRESENTACION_CODIGO" type="text" pInputText style="width: 100%"
									[(ngModel)]="maearticulo.PRESENTACION_CODIGO" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"></button>
							</div>
							<div class="p-col-2">
								<label for="PRESENTACION_NOMBRE"></label>
								<input class="frm" id="PRESENTACION_NOMBRE" type="text" pInputText disabled
									[(ngModel)]="maearticulo.PRESENTACION_NOMBRE" autocomplete="off">
							</div>
							<div class="p-col-1">
								<span>Factor:</span>
							</div>
							<div class="p-col-1">
								<label for="ART_FACTOR"></label>
								<input class="frm" id="ART_FACTOR" type="text" pInputText style="width: 100%"
									[(ngModel)]="maearticulo.ART_FACTOR" (keydown)="botonesmodificar()"
									autocomplete="off">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-2">
										<p-checkbox name="chkproducido" value="" label="" binary="{{chkproducido}}"
											[(ngModel)]="chkproducido" (click)="botonesmodificar()"></p-checkbox>
									</div>
									<div class="p-col-10">
										<span>Producido</span>
									</div>
								</div>
							</div>
							<div class="p-col-3">
								<label for="CAMPANIA_NOMBRE"></label>
								<input class="frm" id="CAMPANIA_NOMBRE" type="text" pInputText disabled
									style="width: 100%" [(ngModel)]="maearticulo.CAMPANIA_NOMBRE" autocomplete="off">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="divgrups">
				<div class="p-grid" style="margin-bottom: -10px">
					<div class="p-col-3">
						<span style="font-weight: bold">DESCRIPCIONES</span>
					</div>
					<div class="p-col-12" style="margin-top: -13px">
						<div class="p-grid">
							<div class="p-col-6">
								<app-aggridsaci [width]="" [height]="largoDescrip" [enableSorting]="true"
									[enableFilter]="true" [rowData]="artService.maecomart"
									[frameworkComponents]="frameworkComponents" [animateRows]="true"
									[rowSelection]="rowSelection" [columnDefs]="columnDefsMaecomart"
									[defaultColDef]="defaultColDefMaecomart" [rowStyle]="rowStyle"
									[singleClickEdit]="false" [botones]="true" [mostrarGuardar]="false"
									[botonNuevo]="artService.btnNuevoDesc" [botonRegresar]="artService.btnCancelarDesc"
									[botonBorrar]="artService.btnEliminarDesc"
									(selected)="seleccionDetDescripcion($event)" (cambios)="cambioDescrip($event)">
								</app-aggridsaci>
							</div>
							<div class="p-col-1">
							</div>
							<div class="p-col-5">
								<div class="p-grid">
									<div class="p-col-3">
										<span>Tipo:</span>
									</div>
									<div class="p-col-6">
										<p-dropdown [style]="{'width':'100%'}" [(ngModel)]="strTipoCateg"
											optionLabel="REF_NOMBRE" [options]="cmbTiposCateg"
											[panelStyle]="{'width':'110%'}" (onChange)="botonesmodificar()">
										</p-dropdown>
									</div>
									<div class="p-col-3"></div>
									<div class="p-col-3">
										<span>Título</span>
									</div>
									<div class="p-col-6">
										<p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}"
											[panelStyle]="{'width':'100%'}" [options]="opcionesTITULO"
											[(ngModel)]="maearticulo.ART_LIB_TITULO" (keydown.enter)="setFocus($event)"
											(onChange)="botonesmodificar()" (keydown)="botonesmodificar()"
											editable="true">
										</p-dropdown>
									</div>
									<div class="p-col-3"></div>
									<div class="p-col-3">
										<span>Autor</span>
									</div>
									<div class="p-col-6">
										<p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}"
											[panelStyle]="{'width':'100%'}" [options]="opcionesAUTOR"
											[(ngModel)]="maearticulo.ART_LIB_AUTOR" (keydown.enter)="setFocus($event)"
											(onChange)="botonesmodificar()" (keydown)="botonesmodificar()"
											editable="true">
										</p-dropdown>
									</div>
									<div class="p-col-3"></div>
									<div class="p-col-3">
										<span>Editorial</span>
									</div>
									<div class="p-col-6">
										<p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}"
											[panelStyle]="{'width':'100%'}" [options]="opcionesEDITORIAL"
											[(ngModel)]="maearticulo.ART_LIB_EDITORIAL"
											(keydown.enter)="setFocus($event)" (onChange)="botonesmodificar()"
											(keydown)="botonesmodificar()" editable="true">
										</p-dropdown>
									</div>
									<div class="p-col-3"></div>
									<div class="p-col-3">
										<span>Origen</span>
									</div>
									<div class="p-col-6">
										<p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}"
											[panelStyle]="{'width':'100%'}" [options]="opcionesORIGEN"
											[(ngModel)]="maearticulo.ART_LIB_ORIGEN" (keydown.enter)="setFocus($event)"
											(onChange)="botonesmodificar()" (keydown)="botonesmodificar()"
											editable="true">
										</p-dropdown>
									</div>
									<div class="p-col-3"></div>
									<div class="p-col-3">
										<span>Tamaño</span>
									</div>
									<div class="p-col-6">
										<p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}"
											[panelStyle]="{'width':'100%'}" [options]="opcionesTAMANIO"
											[(ngModel)]="maearticulo.ART_LIB_TAMANIO" (keydown.enter)="setFocus($event)"
											(onChange)="botonesmodificar()" (keydown)="botonesmodificar()"
											editable="true">
										</p-dropdown>
									</div>
									<div class="p-col-3"></div>
									<div class="p-col-3">
										<span>Encuadernación</span>
									</div>
									<div class="p-col-6">
										<p-dropdown [style]="{'minWidth': '100%', 'width': '100%'}"
											[panelStyle]="{'width':'100%'}" [options]="opcionesENCUADERNACION"
											[(ngModel)]="maearticulo.ART_LIB_ENCUADERNACION"
											(keydown.enter)="setFocus($event)" (onChange)="botonesmodificar()"
											(keydown)="botonesmodificar()" editable="true">
										</p-dropdown>
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="divgrups">
				<div class="p-grid" style="margin-bottom: -10px">
					<div class="p-col-3">
						<span style="font-weight: bold">COMENTARIO</span>
					</div>
					<div class="p-col-12">
						<textarea id="comentario" pInputTextarea [rows]="3" [cols]="180"
							[(ngModel)]="maearticulo.ART_COMENTARIO" (keydown)="botonesmodificar()"></textarea>
					</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Precios" [disabled]="botonNuevo && index<12">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Artículo</span>
				</div>
				<div class="p-col-8">
					<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
				</div>
				<div class="p-col-1">
					<span>Unidad de Costeo:</span>
				</div>
				<div class="p-col-1">
					<input class="frm" id="LBL_UCOSTEO" type="text" pInputText style="width: 100%"
						[(ngModel)]="maearticulo.LBL_UCOSTEO" disabled autocomplete="off">
				</div>
				<div class="p-col-10">
				</div>
				<div class="p-col-1">
					<span>Unidad de Venta:</span>
				</div>
				<div class="p-col-1">
					<input class="frm" id="LBL_UVENTA" type="text" pInputText style="width: 100%"
						[(ngModel)]="maearticulo.LBL_UVENTA" disabled autocomplete="off">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="artService.maeartprecio" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsPrecio" [defaultColDef]="defaultColDef"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (selected)="seleccionprecio($event)">
		</app-aggridsaci>
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Último costo de compra:</span>
				</div>
				<div class="p-col-1">
					<input class="frm" id="LBL_ULTCOSTOCOMPRC" type="text" pInputText style="width: 100%"
						[(ngModel)]="maearticulo.LBL_ULTCOSTOCOMPRC" disabled autocomplete="off">
				</div>
				<div class="p-col-9">
				</div>
				<div class="p-col-2">
					<span>Costo promedio:</span>
				</div>
				<div class="p-col-1">
					<input class="frm" id="LBL_COSTOPROMPRC" type="text" pInputText style="width: 100%"
						[(ngModel)]="maearticulo.LBL_COSTOPROMPRC" disabled autocomplete="off">
				</div>
				<div class="p-col-9">
				</div>
				<div class="p-col-2">
					<span>Último proveedor:</span>
				</div>
				<div class="p-col-3">
					<input class="frm" id="LBL_PRONOMBREPRC" type="text" pInputText style="width: 100%"
						[(ngModel)]="maearticulo.LBL_PRONOMBREPRC" disabled autocomplete="off">
				</div>
			</div>
		</div>

	</p-tabPanel>
	<p-tabPanel header="Componentes" [disabled]="botonNuevo && index<12">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Artículo</span>
				</div>
				<div class="p-col-10">
					<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="artService.maeartparte" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsComponente" [defaultColDef]="defaultColDefParte"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (selected)="seleccionparte2($event)">
		</app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Formulación" [disabled]="botonNuevo && index<12">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Artículo:</span>
				</div>
				<div class="p-col-6">
					<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
				</div>
			</div>
			<div class="p-grid">
				<div class="p-col-2">
					<span>Número de Fórmula:</span>
				</div>
				<div class="p-col-3">
					<p-dropdown [options]="artService.formulas" [style]="{'minWidth': '100%', 'width':'100%'}"
						optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="artService.selectformula"
						(onChange)="opcion($event)"></p-dropdown>
				</div>
				<div class="p-col-3"></div>
				<div class="p-col-4">
					<span>{{'Cantidad Materia Prima: ' + strCantMatPrima}}</span>
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="artService.maeartformula" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsFormula" [defaultColDef]="defaultColDefFormula"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (selected)="seleccionformula($event)">
		</app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Costos Estandares" [disabled]="botonNuevo && index<12">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Artículo:</span>
				</div>
				<div class="p-col-6">
					<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
				</div>
			</div>
			<div class="p-grid">

				<div class="p-col-2">
					<span>Número de Artículos:</span>
				</div>
				<div class="p-col-3">
					<label for="CEST_CANTIDAD"></label>
					<input class="frm" id="CEST_CANTIDAD" [(ngModel)]="txtNumArtCosEst" pInputText (input)="cambio()"
						type="text">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="artService.maecostoestand" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCostoEst" [defaultColDef]="defaultColDefCostoest"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (selected)="seleccioncostoest($event)">
		</app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Existencia por bodega" [disabled]="botonNuevo && index<12">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Artículo:</span>
				</div>
				<div class="p-col-6">
					<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="artService.maeexistbod" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsExistbod" [defaultColDef]="defaultColDefExistbod"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (selected)="seleccionexistbod($event)">
		</app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Kardex" [disabled]="botonNuevo && index<12">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Artículo:</span>
				</div>
				<div class="p-col-6">
					<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
				</div>
				<div class="p-col-1">
				</div>
				<div class="p-col-1">
					<span>Visualizar:</span>
				</div>
				<div class="p-col-2">
					<p-dropdown [style]="{'width':'100%'}" [(ngModel)]="strTipoKardex" optionLabel="tipo"
						[options]="cmbTipoKardex" [panelStyle]="{'width':'110%'}" (onChange)="cambioDropdown($event)"
						inputId="tipoKardex">
					</p-dropdown>
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largokardex" [enableSorting]="true" [enableFilter]="true"
			[rowData]="trnkardexp" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsKardex" [defaultColDef]="defaultColDefKardex"
			[rowStyle]="rowStyle" [botones]="false" [singleClickEdit]="false"
			></app-aggridsaci>
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Ultimo Proveedor:</span>
				</div>
				<div class="p-col-1">
					<span>{{ultimoProveComp}}</span>
				</div>
				<div class="p-col-1">
					<span>T.En.:</span>
				</div>
				<div class="p-col-1">
					<span>{{Toten}}</span>
				</div>
				<div class="p-col-1">
					<span>T.Sa.:</span>
				</div>
				<div class="p-col-1">
					<span>{{Totsa}}</span>
				</div>
				<div class="p-col-2">
					<span>Ult.Cost.Compra:</span>
				</div>
				<div class="p-col-1">
					<span>{{maearticulo.LBL_ULTCOSTOCOMPRC}}</span>
				</div>
				<div class="p-col-1">
					<span>Ult.Cost.Prod:</span>
				</div>
				<div class="p-col-1">
					<span>{{ultimoCostoProd}}</span>
				</div>
				<div class="p-col-1">
					<span>Costo Promedio:</span>
				</div>
				<div class="p-col-1">
					<span>{{maearticulo.LBL_COSTOPROMPRC}}</span>
				</div>
				<div class="p-col-1">
				</div>
				<div class="p-col-1">
					<span>Existencia:</span>
				</div>
				<div class="p-col-1">
					<span>{{existencia}}</span>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Especificaciones Técnicas" [disabled]="botonNuevo && index<12">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
			<p-tabView>
				<p-tabPanel header="Administrativo">
					<div class="divgrups">
						<div class="p-grid">
							<div class="p-col-4">
								<span class="obligatorio">{{maearticulo.ART_NOMBRE}}</span>
							</div>
							<div class="p-col-8">
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Código Artículo:</span>
									</div>
									<div class="p-col-2">
										<label for="ART_CODIGOESPTEC"></label>
										<input class="frm" id="ART_CODIGOESPTEC" type="text" pInputText
											[(ngModel)]="maearticulo.ART_CODIGO" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Código Alterno 1:</span>
									</div>
									<div class="p-col-2">
										<label for="ART_CODIGOALT1ESPTEC"></label>
										<input class="frm" id="ART_CODIGOALT1ESPTEC" type="text" pInputText
											[(ngModel)]="maearticulo.ART_CODIGOALT1" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Código Alterno 2:</span>
									</div>
									<div class="p-col-2">
										<label for="ART_CODIGOALT2ESPTEC"></label>
										<input class="frm" id="ART_CODIGOALT2ESPTEC" type="text" pInputText
											[(ngModel)]="maearticulo.ART_CODIGOALT2" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Código Alterno 3:</span>
									</div>
									<div class="p-col-2">
										<label for="ART_CODIGOALT3ESPTEC"></label>
										<input class="frm" id="ART_CODIGOALT3ESPTEC" type="text" pInputText
											[(ngModel)]="maearticulo.ART_CODIGOALT3" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Código Alterno 4:</span>
									</div>
									<div class="p-col-2">
										<label for="ART_CODIGOALT4ESPTEC"></label>
										<input class="frm" id="ART_CODIGOALT4ESPTEC" type="text" pInputText
											[(ngModel)]="maearticulo.ART_CODIGOALT4" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Código de Fabricación</span>
									</div>
									<div class="p-col-2">
										<label for="ESPTEC_CODFAB"></label>
										<input class="frm" id="ESPTEC_CODFAB" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_CODFAB" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Costo de Fabricación</span>
									</div>
									<div class="p-col-2">
										<label for="ESPTEC_COSTFAB"></label>
										<input class="frm" id="ESPTEC_COSTFAB" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_COSTFAB" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off" appTwoDigitDecimaNumber>
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Descripción de la Importación</span>
									</div>
									<div class="p-col-2">
										<label for="ESPTEC_NOMBREIMP"></label>
										<input class="frm" id="ESPTEC_NOMBREIMP" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_NOMBREIMP" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Partida Arancelaria</span>
									</div>
									<div class="p-col-2">
										<label for="ESPTEC_PARTIDA"></label>
										<input class="frm" id="ESPTEC_PARTIDA" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_PARTIDA" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Porcentaje del Arancel</span>
									</div>
									<div class="p-col-2">
										<label for="ESPTEC_ARANCEL"></label>
										<input class="frm" id="ESPTEC_ARANCEL" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_ARANCEL" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off" appTwoDigitDecimaNumber>
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Porcentaje del Ice</span>
									</div>
									<div class="p-col-2">
										<label for="ESPTEC_ICE"></label>
										<input class="frm" id="ESPTEC_ICE" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_ICE" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off" appTwoDigitDecimaNumber>
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Volumen Masterbox:</span>
									</div>
									<div class="p-col-2">
										<label for="ESPTEC_VOL"></label>
										<input class="frm" id="ESPTEC_VOL" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_VOL" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off" appTwoDigitDecimaNumber>
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Cantidad Masterbox:</span>
									</div>
									<div class="p-col-2">
										<label for="ESPTEC_MBOX"></label>
										<input class="frm" id="ESPTEC_MBOX" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_MBOX" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off" appTwoDigitDecimaNumber>
									</div>
									<div class="p-col-8">
									</div>
								</div>
							</div>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Archivo">
					<div class="divgrups">
						<div class="p-grid">
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Foto:</span>
									</div>
									<div class="p-col-8">
										<label for="ESPTEC_FOTO"></label>
										<input class="frm" id="ESPTEC_FOTO" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_FOTO" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busqueda()"></button>
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/files.png);"
											(click)="busqueda()"></button>
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Datos Técnicos:</span>
									</div>
									<div class="p-col-8">
										<label for="ESPTEC_DATTEC"></label>
										<input class="frm" id="ESPTEC_DATTEC" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_DATTEC" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busqueda()"></button>
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/files.png);"
											(click)="busqueda()"></button>
									</div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px">
								<div class="p-grid">
									<div class="p-col-2">
										<span>Advertencia:</span>
									</div>
									<div class="p-col-8">
										<label for="ESPTEC_ADVERTENCIA"></label>
										<input class="frm" id="ESPTEC_ADVERTENCIA" type="text" pInputText
											[(ngModel)]="maeesptec.ESPTEC_ADVERTENCIA"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busqueda()"></button>
									</div>
									<div class="p-col-1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/files.png);"
											(click)="busqueda()"></button>
									</div>
								</div>
							</div>
							<div class="p-col-6">
								<p-panel header="VISTA PREVIA FOTO">
									<!--<img src="{{maeesptec.ESPTEC_FOTO}}" alt="image">-->
								</p-panel>
							</div>
							<div class="p-col-6">
								<p-panel header="VISTA PREVIA DATOS TÉCNICOS">

								</p-panel>
							</div>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Otros">
					<div class="divgrups">
						<div class="p-grid">
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-3">
												<span>ZONA DE CONTROL:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_ZONADECONTROL"></label>
												<input class="frm" id="ESPTEC_ZONADECONTROL" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_ZONADECONTROL"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-3">
												<span>POSICIÓN:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_POSICION"></label>
												<input class="frm" id="ESPTEC_POSICION" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_POSICION"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-3">
												<span>PAÍS DE ORIGEN:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_PAIS"></label>
												<input class="frm" id="ESPTEC_PAIS" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_PAIS"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-3">
												<span>LUMENES:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_FLUJOLUMINICO"></label>
												<input class="frm" id="ESPTEC_FLUJOLUMINICO" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_FLUJOLUMINICO"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-3">
												<span>VATIOS:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_POTENCIA"></label>
												<input class="frm" id="ESPTEC_POTENCIA" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_POTENCIA"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off" appTwoDigitDecimaNumber>
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-3">
												<span>VOLTAJE:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_VOLTAJEINPUT"></label>
												<input class="frm" id="ESPTEC_VOLTAJEINPUT" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_VOLTAJEINPUT"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-3">
												<span>ANGULO:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_ANGULOAPERTURA"></label>
												<input class="frm" id="ESPTEC_ANGULOAPERTURA" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_ANGULOAPERTURA"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-3">
												<span>FLETE:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_FLETE"></label>
												<input class="frm" id="ESPTEC_FLETE" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_FLETE"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off" appTwoDigitDecimaNumber>
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-3">
												<span>SEGURO:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_SEGURO"></label>
												<input class="frm" id="ESPTEC_SEGURO" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_SEGURO"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off" appTwoDigitDecimaNumber>
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-3">
												<span>SALVA GUARDA:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_SALVAGUARDA"></label>
												<input class="frm" id="ESPTEC_SALVAGUARDA" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_SALVAGUARDA"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off" appTwoDigitDecimaNumber>
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-3">
												<span>ATENUABLE:</span>
											</div>
											<div class="p-col-6">
												<label for="ESPTEC_ATENUABLE"></label>
												<input class="frm" id="ESPTEC_ATENUABLE" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_ATENUABLE"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="p-col-12">
							</div>
							<div class="p-col-12">
							</div>
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-4">
												<span>CORRIENTE:</span>
											</div>
											<div class="p-col-5">
												<label for="ESPTEC_CORRIENTE"></label>
												<input class="frm" id="ESPTEC_CORRIENTE" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_CORRIENTE"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-4">
												<span>CRI:</span>
											</div>
											<div class="p-col-5">
												<label for="ESPTEC_CRI"></label>
												<input class="frm" id="ESPTEC_CRI" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_CRI"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-4">
												<span>TEMPERATURA DE COLOR:</span>
											</div>
											<div class="p-col-5">
												<label for="ESPTEC_TEMPERATURA"></label>
												<input class="frm" id="ESPTEC_TEMPERATURA" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_TEMPERATURA"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-4">
												<span>IP:</span>
											</div>
											<div class="p-col-5">
												<label for="ESPTEC_IP"></label>
												<input class="frm" id="ESPTEC_IP" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_IP" (keydown.enter)="setFocus($event)"
													(keydown)="botonesmodificar()" autocomplete="off">
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
									<div class="p-col-12" style="margin-top: -15px">
										<div class="p-grid">
											<div class="p-col-4">
												<span>CANTIDAD DE VATIOS:</span>
											</div>
											<div class="p-col-5">
												<label for="ESPTEC_POTENCIATOTAL"></label>
												<input class="frm" id="ESPTEC_POTENCIATOTAL" type="text" pInputText
													[(ngModel)]="maeesptec.ESPTEC_POTENCIATOTAL"
													(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
													autocomplete="off" appTwoDigitDecimaNumber>
											</div>
											<div class="p-col-3">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Composición Química" [disabled]="botonNuevo && index<12">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Artículo</span>
				</div>
				<div class="p-col-10">
					<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="artService.prodtrncomqui" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsComQuimica" [defaultColDef]="defaultColDefComquim"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
			(selected)="seleccionDetComQuimica($event)"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Aplicación" [disabled]="botonNuevo && index<12">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Artículo</span>
				</div>
				<div class="p-col-10">
					<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="artService.prodtrnaplicacion" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsAplicacion" [defaultColDef]="defaultColDefAplicacion"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
			(selected)="seleccionDetAplicacion($event)"></app-aggridsaci>
	</p-tabPanel>
	<!-- --------------------------------------------------------- Vehiculo------------------------------------------- -->
	<p-tabPanel header="Vehiculos" >
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Artículo</span>
				</div>
				<div class="p-col-10">
					<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
				</div>
			</div>
		</div>
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>RAMV:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_RAMV"></label>
							<input class="frm" id="ART_RAMV" type="text" maxlength="30"
								[(ngModel)]="maearticulo.ART_RAMV" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
							<span>Motor:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_MOTOR"></label>
							<input class="frm" id="ART_MOTOR" type="text" maxlength="35"
								[(ngModel)]="maearticulo.ART_MOTOR" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
							<span>Estado:</span>
						</div>
						<div class="p-col-5">
							<span>{{maearticulo.ART_ESTADOFACPRO }} {{maearticulo.ART_ESTADOFAC}}</span>
						</div>
					</div>

				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Modelo SRI:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_MODELOSRI"></label>
							<input class="frm" id="ART_MODELOSRI" type="text" maxlength="100"
								[(ngModel)]="maearticulo.ART_MODELOSRI" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
							<span>Serie:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_SERIEV"></label>
							<input class="frm" id="ART_SERIEV" type="text" maxlength="35"
								[(ngModel)]="maearticulo.ART_SERIEV" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
						</div>
						<div class="p-col-2">
							<p style="text-align: center;">Nombre del contribuyente:</p>
						</div>
						<div class="p-col-2">
							<p style="text-align: center;">Nro. Factura:</p>
						</div>


					</div>

				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Capacidad:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_CAPACIDAD"></label>
							<input class="frm" id="ART_CAPACIDAD" type="text" pInputText pKeyFilter="num" maxlength="10"
								[(ngModel)]="maearticulo.ART_CAPACIDAD" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
							<span>Cilidraje:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_CILINDRAJE"></label>
							<input class="frm" id="ART_CILINDRAJE" type="text" pInputText pKeyFilter="num" maxlength="10"
								[(ngModel)]="maearticulo.ART_CILINDRAJE" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">

						</div>
						<div class="p-col-1">
							<span>Compra:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_NUMFACPRO"></label>
							<input class="frm" id="ART_NUMFACPRO" type="text" pInputText disabled
							style="width: 100%" [(ngModel)]="maearticulo.ART_NUMFACPRO"
							(keydown.enter)="setFocus($event)" autocomplete="off">
						</div>
						<div class="p-col-2">
							<label for="ART_NOMFACPRO"></label>
							<input class="frm" id="ART_NOMFACPRO" type="text" pInputText disabled
							style="width: 100%" [(ngModel)]="maearticulo.ART_NOMFACPRO"
							(keydown.enter)="setFocus($event)" autocomplete="off">
						</div>
						<div class="p-col-1">
							<p style="text-align: center;">Activo</p>
						</div>
					</div>

				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Color:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_COLOR"></label>
							<input class="frm" id="ART_COLOR" type="text" maxlength="35"
								[(ngModel)]="maearticulo.ART_COLOR" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
							<span>Año:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_ANIO"></label>
							<input class="frm" id="ART_ANIO" type="text" pInputText pKeyFilter="num" maxlength="4"
								[(ngModel)]="maearticulo.ART_ANIO" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
							<span>Venta:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_NUMFAC"></label>
							<input class="frm" id="ART_NUMFAC" type="text" pInputText disabled
							style="width: 100%" [(ngModel)]="maearticulo.ART_NUMFAC"
							(keydown.enter)="setFocus($event)" autocomplete="off">
						</div>
						<div class="p-col-2">
							<label for="ART_NOMFAC"></label>
							<input class="frm" id="ART_NOMFAC" type="text" pInputText disabled
							style="width: 100%" [(ngModel)]="maearticulo.ART_NOMFAC"
							(keydown.enter)="setFocus($event)" autocomplete="off">
						</div>
						<div class="p-col-1">
							<p style="text-align: center;">
								<p-checkbox name="group1" value="" label="" binary="{{chkactivo}}"
									[(ngModel)]="chkactivo" (click)="botonesmodificar()"></p-checkbox>
							</p>
						</div>
					</div>

				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Marca:</span>
						</div>
						<div class="p-col-5">
							<div class="p-grid">
								<div class="p-col-3">
									<label for="ART_MARCAV"></label>
									<input class="frm" id="ART_MARCAV" type="text"  pInputText pKeyFilter="num"
									maxlength="5" style="width: 100%"
										[(ngModel)]="maearticulo.ART_MARCAV" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<p style="text-align: center;">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)=" busquedamarcavehiculo(maearticulo.ART_MARCAV)"></button>
									</p>
								</div>
								<div class="p-col-7">
									<label for="ART_MARVEH"></label>
									<input class="frm" id="ART_MARVEH" type="text" pInputText disabled
										style="width: 100%" [(ngModel)]="maearticulo.ART_MARVEH"
										(keydown.enter)="setFocus($event)" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span>Tonelaje:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_TONELAJE"></label>
							<input class="frm" id="ART_TONELAJE" type="text" pInputText pKeyFilter="num" maxlength="10"
								[(ngModel)]="maearticulo.ART_TONELAJE" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Clase:</span>
						</div>
						<div class="p-col-5">
							<div class="p-grid">
								<div class="p-col-3">
									<label for="ART_CLASE"></label>
									<input class="frm" id="ART_CLASE" type="text" maxlength="5" style="width: 100%"
										[(ngModel)]="maearticulo.ART_CLASE" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<p style="text-align: center;">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedaclasevehiculo(maearticulo.ART_CLASE)"></button>
									</p>
								</div>
								<div class="p-col-7">
									<label for="ART_CLAVEH"></label>
									<input class="frm" id="ART_CLAVEH" type="text" pInputText disabled
										style="width: 100%" [(ngModel)]="maearticulo.ART_CLAVEH"
										(keydown.enter)="setFocus($event)" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span>Número de ejes:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_NEJES"></label>
							<input class="frm" id="ART_NEJES" type="text" pInputText pKeyFilter="num" maxlength="10"
								[(ngModel)]="maearticulo.ART_NEJES" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>

					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Tipo de vehiculo:</span>
						</div>
						<div class="p-col-5">
							<div class="p-grid">
								<div class="p-col-3">
									<label for="ART_TIPOV"></label>
									<input class="frm" id="ART_TIPOV" type="text" pInputText pKeyFilter="num"
									maxlength="5" style="width: 100%"
										[(ngModel)]="maearticulo.ART_TIPOV" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<p style="text-align: center;">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedatipovehiculo(maearticulo.ART_TIPOV)"></button>
									</p>
								</div>
								<div class="p-col-7">
									<label for="ART_TIPVEH"></label>
									<input class="frm" id="ART_TIPVEH" type="text" pInputText disabled
										style="width: 100%" [(ngModel)]="maearticulo.ART_TIPVEH"
										(keydown.enter)="setFocus($event)" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span>Número de ruedas:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_NRUEDAS"></label>
							<input class="frm" id="ART_NRUEDAS" type="text" pInputText pKeyFilter="num" maxlength="10" 
								[(ngModel)]="maearticulo.ART_NRUEDAS" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Combustible:</span>
						</div>
						<div class="p-col-5">
							<div class="p-grid">
								<div class="p-col-3">
									<label for="ART_COMBUSTIBLE"></label>
									<input class="frm" id="ART_COMBUSTIBLE" type="text" pInputText pKeyFilter="num"
									maxlength="5" style="width: 100%"
										[(ngModel)]="maearticulo.ART_COMBUSTIBLE" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<p style="text-align: center;">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedacomvehiculo(maearticulo.ART_COMBUSTIBLE)"></button>
									</p>
								</div>
								<div class="p-col-7">
									<label for="ART_COMVEH"></label>
									<input class="frm" id="ART_COMVEH" type="text" pInputText disabled
										style="width: 100%" [(ngModel)]="maearticulo.ART_COMVEH"
										(keydown.enter)="setFocus($event)" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span>Fecha de fabricación:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_FFABRICA"></label>
							<input class="frm" id="ART_FFABRICA" type="date" [(ngModel)]="maearticulo.ART_FFABRICA"
								(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Dispositivo:</span>
						</div>
						<div class="p-col-5">
							<div class="p-grid">
								<div class="p-col-3">
									<label for="ART_DISPOSITIVO"></label>
									<input class="frm" id="ART_DISPOSITIVO" type="text" pInputText pKeyFilter="num" 
									maxlength="5" style="width: 100%"
										[(ngModel)]="maearticulo.ART_DISPOSITIVO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<p style="text-align: center;">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedadisvehiculo(maearticulo.ART_DISPOSITIVO)"></button>
								</div>
								<div class="p-col-7">
									<label for="ART_DISVEH"></label>
									<input class="frm" id="ART_DISVEH" type="text" pInputText disabled
										style="width: 100%" [(ngModel)]="maearticulo.ART_DISVEH"
										(keydown.enter)="setFocus($event)" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span>Modelo agencia de tránsito:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_MODELOAT"></label>
							<input class="frm" id="ART_MODELOAT" type="text" maxlength="100"
								[(ngModel)]="maearticulo.ART_MODELOAT" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
					</div>
				</div>

				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>País de origen:</span>
						</div>
						<div class="p-col-5">
							<div class="p-grid">
								<div class="p-col-3">
									<label for="ART_PORIGEN"></label>
									<input class="frm" id="ART_PORIGEN" type="text" pInputText pKeyFilter="num" maxlength="5" style="width: 100%"
										[(ngModel)]="maearticulo.ART_PORIGEN" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<p style="text-align: center;">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedapaisvehiculo(maearticulo.ART_PORIGEN)"></button>
								</div>
								<div class="p-col-7">
									<label for="ART_P"></label>
									<input class="frm" id="ART_P" type="text" pInputText disabled
										style="width: 100%" [(ngModel)]="maearticulo.ART_P"
										(keydown.enter)="setFocus($event)" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-2">
							<span>Detalle de pago:</span>
						</div>
						<div class="p-col-2">
							<label for="ART_DPAGO"></label>
							<input class="frm" id="ART_DPAGO" type="text" maxlength="300"
								[(ngModel)]="maearticulo.ART_DPAGO" (keydown.enter)="setFocus($event)"
								(keydown)="botonesmodificar()" autocomplete="off">
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Banco de Pago:</span>
						</div>
						<div class="p-col-5">
							<div class="p-grid">
								<div class="p-col-3">
									<label for="ART_BANCO"></label>
									<input class="frm" id="ART_BANCO" type="text" pInputText pKeyFilter="num" maxlength="5" style="width: 100%"
										[(ngModel)]="maearticulo.ART_BANCO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
									
								</div>
								<div class="p-col-2">
									<p style="text-align: center;">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedabanco(maearticulo.ART_BANCO)"></button>
									</p>
								</div>
								<div class="p-col-7">
									<label for="ART_NBANCO"></label>
									<input class="frm" id="ART_NBANCO" type="text" pInputText disabled
										style="width: 100%" [(ngModel)]="maearticulo.ART_NBANCO"
										(keydown.enter)="setFocus($event)" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-6">
							<p style="text-align: center;">
								<button class=button_largos type="button"
									style="background-image: url(../../../assets/images/iconspdv/boton-ver-imagen.png); width: 7em"
									(click)="verimagen(maearticulo.ART_CODIGO)"></button>
							</p>
						</div>

					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<!-- ---------------------------------------------------------Fin Vehiculo------------------------------------------- -->
	<!-- --------------------------------------------------------- Proceso Fabricación------------------------------------------- -->
		<p-tabPanel header="Proceso Fabricación" >
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-2">
						<span>Artículo</span>
					</div>
					<div class="p-col-10">
						<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
					</div>
				</div>
			</div>
			<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
				[rowData]="artService.profabricacion" [frameworkComponents]="frameworkComponents" [animateRows]="true"
				[rowSelection]="rowSelection" [columnDefs]="columnDefsProfab" [defaultColDef]="defaultColDefProfab"
				[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
				(selected)="seleccionDetProFabri($event)"></app-aggridsaci>
		</p-tabPanel>
		<!-- ---------------------------------------------------------Fin Proceso Fabricación------------------------------------------- -->
		<!-- --------------------------------------------------------- Control Proceso------------------------------------------- -->
		<p-tabPanel header="Control Proceso" >
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-2">
						<span>Artículo</span>
					</div>
					<div class="p-col-10">
						<span>{{maearticulo.ART_CODIGO + ' - ' + maearticulo.ART_NOMBRE}}</span>
					</div>
				</div>
			</div>
			<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
				[rowData]="artService.procontrol" [frameworkComponents]="frameworkComponents" [animateRows]="true"
				[rowSelection]="rowSelection" [columnDefs]="columnDefsProcontrol" [defaultColDef]="defaultColDefProcont"
				[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
				(selected)="seleccionDetProcontrol($event)"></app-aggridsaci>
		</p-tabPanel>
		<!-- ---------------------------------------------------------Fin Control Proceso------------------------------------------- -->
</p-tabView>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="true" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="abrirFichaTecnica()"
									class="archivoOpc">Ficha Técnica</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarCodigosAlternos()"
									class="archivoOpc">Códigos Alternos</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="actualizariva()"
									class="archivoOpc">Actualizar IVA</span>
							</div>
						</p-scrollPanel>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Código Alterno" [(visible)]="displayDialogCodAlt" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-invtrndatosalt *ngIf="displayDialogCodAlt" [strCodigo]="maearticulo.ART_CODIGO"
		[strDescripcion]="maearticulo.ART_NOMBRE" [strTipo]="'A'" (signalSalir)="recibirSenalesCodAlt($event)">
	</app-invtrndatosalt>
</p-dialog>

<p-dialog header="Ficha Técnica" [(visible)]="displayDialogFichaT" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '90vw', height: 'auto'}">
	<app-invtrnfichatecnica *ngIf="displayDialogFichaT" [art_codigo]="artCodigoFicha" [art_nombre]="artNombreFicha"
		(signalSalir)="recibirSenalesFTecnica($event)"></app-invtrnfichatecnica>
</p-dialog>

<p-dialog header="Grupos por niveles" [(visible)]="displayGrupoNiveles" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '30vw', height: 'auto'}">
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<p-tree [value]="gruniv" selectionMode="single" (onNodeSelect)="nodeSelect($event)"></p-tree>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'Invmaearticulo'"
		[txtNumComprobante]="maearticulo.ART_CODIGO" (signalSalir)="recibirSenalesDocumentos($event)">
	</app-uploadfiles>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="artService.spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="artService.spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<!-- CDPJ -->
<p-dialog header="Actualización de IVA" [(visible)]="displayDialogactiva" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '35vw', height: 'auto'}">
	<div class="p-grid">
		<div class="p-col-1">
			<button pButton (click)="aplicarActualizar()"
				style="background-image: url(../../../assets/images/iconos/visto.png);
				background-size: contain !important; width: 35px; height: 35px">
			</button>			
		</div>
		<div class="p-col-1">
			<button pButton (click)="cancelariva()"
				style="background-image: url(../../../assets/images/iconos/regresar.png); 
				background-size: contain !important; width: 35px; height: 35px">
		</button>
		</div>		
	</div>
	<div class="p-grid" >
		<div class="p-col-1">
			<span>IVA:</span>
		</div>
		<div class="p-col-4">
			<input id="tariivaporcentaje" type="text" pInputText  autocomplete="off" [(ngModel)]="tariivaporcentaje"
			(keydown.enter)="busquedaivacod(tariivaporcentaje)">
		</div>
		<div class="p-col-1">
			<button type="button"
			style="background-image: url(../../../assets/images/iconos/buscar.png);"
			(click)="busquedaivacod(tariivaporcentaje)"></button>	
		</div>	
		<div class="p-col-1">
			<span style="float: left"> {{tariivadescripcion}}</span>
		</div>	
	</div>
	<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFasttariva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="artiva"></p-toast>
</p-dialog>
<p-dialog header="Clave Autorización" [(visible)]="claveDialog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="artmaeclave"></p-toast>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
						(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>
<!-- CDPJ -->
