import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {CxpTrncobro} from '../../com/cominterfaces/cxptrncobro';
import {CxpTrndatosanex} from '../cxpinterfaces/cxptrndatosanex';
import {CxpFpant} from '../cxpinterfaces/cxpfpant';
import {CxpEcant} from '../cxpinterfaces/cxpecant';
import {CxpVarios} from '../cxpinterfaces/cxpvarios';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {CxpDocs} from '../cxpinterfaces/cxpdocs';
import {CxpFpdev} from '../cxpinterfaces/cxpfpdev';
import {DatePipe} from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class CxptrncobroService {
  
  public way: string;
  public trncobroarray: CxpTrncobro[] = [];
  public trndatosanexarray: CxpTrndatosanex[] = [];
  
  public variosarray: CxpVarios[][] = [];
  public fpantarray: CxpFpant[][] = [];
  public ecantarray: CxpEcant[][] = [];
  public docarray: CxpDocs[][] = [];
  public fpdevarray: CxpFpdev[][] = [];
  public spin:boolean=false;//CDPJ
  
  public hmpConfiguracion: any[];
  
  public grids: any[] = [];
  
  public intNumCheAut;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private init: NuevoComponentService,
              private datePipe: DatePipe,
              private confIniciales: ConfInicialesService) {
                
    this.way = sessionStorage.getItem('way');
  }
  
  insertarSaldosIni(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/4afce2aadc4af0', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_TRNCOBRO_FECHATRN: item.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_TIPODOC: item.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_NROCOMPROBANTE: item.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_CONCEPTO: item.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_IMPORTE: item.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_FECHAVENCE: item.TRNCOBRO_FECHAVENCE,
        p_pro_CODIGO: item.PRO_CODIGO,
        p_APLORG_CODIGO: item.APLORG_CODIGO,
        p_trncobro_usuario_act: item.TRNCOBRO_USUARIO_ACT,
        p_trncobro_fecharecepcion: item.TRNCOBRO_FECHARECEPCION,
        p_trncobro_referenciaexterna: item.TRNCOBRO_REFERENCIAEXTERNA,
        p_con_codigo: item.CON_CODIGO,
        p_TRNCOBRO_SERIE: item.TRNCOBRO_SERIE
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  insertarTrncobroCxp(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/4afc7330bde198', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_TRNCOBRO_FECHATRN: item.TRNCOBRO_FECHATRN,
        p_TRNCOBRO_TIPODOC: item.TRNCOBRO_TIPODOC,
        p_TRNCOBRO_NROCOMPROBANTE: item.TRNCOBRO_NROCOMPROBANTE,
        p_TRNCOBRO_CONCEPTO: item.TRNCOBRO_CONCEPTO,
        p_TRNCOBRO_IMPORTE: item.TRNCOBRO_IMPORTE,
        p_TRNCOBRO_FECHAVENCE: item.TRNCOBRO_FECHAVENCE,
        p_pro_CODIGO: item.PRO_CODIGO,
        p_APLORG_CODIGO: item.APLORG_CODIGO,
        p_trncobro_usuario_act: item.TRNCOBRO_USUARIO_ACT,
        p_trncobro_fecharecepcion: item.TRNCOBRO_FECHARECEPCION,
        p_trncobro_referenciaexterna: item.TRNCOBRO_REFERENCIAEXTERNA,
        p_con_codigo: item.CON_CODIGO,
        p_TRNCOBRO_SERIE: item.TRNCOBRO_SERIE,
        p_encreq_numero: item.ENCREQ_NUMERO,
        p_detreq_linea: item.DETREQ_LINEA,
        p_trncobro_codforpag: item.TRNCOBRO_CODFORPAG
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarCajaMovimiento(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/4afc73153b63f0', {
      elementos: {
        p_CAJ_codigo_1: item.CAJ_CODIGO,
        p_TRNCAJ_fecha_2: item.TRNCAJ_FECHA,
        p_TRNCAJ_tipo_3: item.TRNCAJ_TIPO,
        p_TRNCAJ_numero_4: item.TRNCAJ_NUMERO,
        p_TRNCAJ_formapago_5: item.TRNCAJ_FORMAPAGO,
        p_TRNCAJ_docnro_6: item.TRNCAJ_DOCNRO,
        p_TRNCAJ_importe_7: item.TRNCAJ_IMPORTE,
        p_TRNCAJ_descripcion_8: item.TRNCAJ_DESCRIPCION,
        p_TRNCAJ_anulado: item.TRNCAJ_ANULADO,
        p_TRNCAJ_fechavenci_9: item.TRNCAJ_FECHAVENCI,
        p_TRNCAJ_origen_10: item.TRNCAJ_ORIGEN,
        p_COM_codigo_11: item.COM_CODIGO,
        p_BANCLI_codigo_12: item.BANCLI_CODIGO,
        p_USU_IDENTIFICACION: item.USU_IDENTIFICACION,
        p_CLI_codigo: item.CLI_CODIGO,
        p_cen_codigo: item.CEN_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  insertarBancoMovimiento(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/4afc72d57b63f0', {
      elementos: {
        p_BAN_codigo_1: item.BAN_CODIGO,
        p_TRNBAN_fecha_2: item.TRNBAN_FECHA,
        p_TRNBAN_tipo_3: item.TRNBAN_TIPO,
        p_TRNBAN_numero_4: item.TRNBAN_NUMERO,
        p_TRNBAN_docnro_5: item.TRNBAN_DOCNRO,
        p_TRNBAN_importe_6: item.TRNBAN_IMPORTE,
        p_TRNBAN_concepto_7: item.TRNBAN_CONCEPTO,
        p_TRNBAN_beneficiario_9: item.TRNBAN_BENEFICIARIO,
        p_TRNBAN_anulado_10: item.TRNBAN_ANULADO,
        p_CON_codigo_11: item.CON_CODIGO,
        p_COM_codigo_12: item.COM_CODIGO,
        p_TRNBAN_origen_13: item.TRNBAN_ORIGEN,
        p_TRNBAN_NUMEROREFUSER: item.TRNBAN_NUMEROREFUSER,
        p_TRNBAN_FECHAVENCE: item.TRNBAN_FECHAVENCE,
        p_ENCREQ_NUMERO: item.ENCREQ_NUMERO,
        P_PRO_CODIGO: item.PRO_CODIGO,
        p_TRNBAN_PAGOELEC: item.TRNBAN_PAGOELEC,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  insertarDETTRNCOBRO(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/12bf1cd7777766000', {
      elementos: {
        p_TRNCOBRO_NRODOC: item.TRNCOBRO_NRODOC,
        p_COM_CODIGO: item.COM_CODIGO,
        p_DETTRNCOBRO_NROSEC: item.DETTRNCOBRO_NROSEC,
        p_CON_CODIGO: item.CON_CODIGO,
        p_DETTRNCOBRO_IMPORTE: item.DETTRNCOBRO_IMPORTE,
        p_DETTRNCOBRO_REFERENCIA: item.DETTRNCOBRO_REFERENCIA,
        p_TRNRETENCION_NRO: item.TRNRETENCION_NRO,
        p_RETENCION_CODIGO: item.RETENCION_CODIGO,
        p_encfac_numero: item.ENCFAC_NUMERO,
        p_encfac_comcodigo: item.ENCFAC_COMCODIGO,
        p_encfac_clicodigo: item.ENCFAC_CLICODIGO,
        p_ban_codigo: item.BAN_CODIGO,
        p_trnban_tipo: item.TRNBAN_TIPO,
        p_trnban_numero: item.TRNBAN_NUMERO,
        p_cen_codigo: item.CEN_CODIGO,
        p_pro_codigo: item.PRO_CODIGO,
        p_dettrncobro_tipo: item.DETTRNCOBRO_TIPO,
        p_dettrncobro_comprobante: item.DETTRNCOBRO_COMPROBANTE,
        p_tipgas_codigo: item.TIPGAS_CODIGO,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  CXPGENERAASIENTOPROVPRO(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/83aeae4b3bc7f00', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_usuario: item.ASI_USUARIO_ACT,
        p_tipdia_codigo: item.TIPDIA_CODIGO,
        p_asi_comprobante: item.ASI_COMPROBANTE
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizaTrnBan(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/299df2bbbbad58', {
      elementos: {
        p_ban_codigo: item.BAN_CODIGO,
        p_trnban_tipo: item.TRNBAN_TIPO,
        p_trnban_numero: item.TRNBAN_NUMERO,
        p_trnban_fecha: item.TRNBAN_FECHA,
        p_trnban_importe: item.TRNBAN_IMPORTE,
        p_trnban_concepto: item.TRNBAN_CONCEPTO,
        p_trnban_beneficiario: item.TRNBAN_BENEFICIARIO,
        p_com_codigo: item.COM_CODIGO,
        p_asi_nro: item.ASI_NRO,
        p_trnban_origen: item.TRNBAN_ORIGEN,
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_dettrncobro_nrosec: item.DETTRNCOBRO_NROSEC,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  INSERTARTRNDOCUMENTO(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/257e77776e19e', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_pro_codigo: item.PRO_CODIGO,
        p_com_codigo: item.COM_CODIGO,
        p_trndocumento_nrodoc: item.TRNDOCUMENTO_NRODOC,
        p_trndocumento_tipodoc: item.TRNDOCUMENTO_TIPODOC,
        p_trndocumento_importe: item.TRNDOCUMENTO_IMPORTE,
        p_trndocumento_descuento: item.TRNDOCUMENTO_DESCUENTO,
        p_trndocumento_tipodscto: item.TRNDOCUMENTO_TIPODSCTO,
        p_trndocumento_ref: item.TRNDOCUMENTO_REF,
        p_trndocumento_flag: item.TRNDOCUMENTO_FLAG
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  GENASIENTOANT(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/20ebb95c92afd', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_pro_codigo: item.PRO_CODIGO,
        p_com_codigo: item.COM_CODIGO,
        p_origen: item.ORIGEN,
        p_tipdia_codigo: item.TIPDIA_CODIGO,
        P_usuario: item.USUARIO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  GENASIENTOANTN(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/41d776d5b57250000000', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_pro_codigo: item.PRO_CODIGO,
        p_com_codigo: item.COM_CODIGO,
        p_origen: item.ORIGEN,
        p_tipdia_codigo: item.TIPDIA_CODIGO,
        P_usuario: item.USUARIO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  GENASIENTOANT2(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/41d776d5b57250000000000000000', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_pro_codigo: item.PRO_CODIGO,
        p_com_codigo: item.COM_CODIGO,
        p_origen: item.ORIGEN,
        p_tipdia_codigo: item.TIPDIA_CODIGO,
        P_usuario: item.USUARIO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  GenAsientoDevAnt(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/1075dcae49aefb000', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_pro_codigo: item.PRO_CODIGO,
        p_com_codigo: item.COM_CODIGO,
        p_origen: item.ORIGEN,
        p_tipdia_codigo: item.TIPDIA_CODIGO,
        P_usuario: item.USUARIO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  verificarCaja(cajcodigo): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/3eedc9f262a6a', {
      elementos: {
        p_CAJ_codigop: cajcodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  ACTUALIZAESTADOFACPRO(procodigo, trncobronrodoc): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/533bceb5ea66780', {
      elementos: {
        p_pro_codigo: procodigo,
        p_com_codigo: '01',
        p_trncobro_nrodoc: trncobronrodoc
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  INSERTARDATOSANEX(item): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/4afc6abb8e2af0', {
      elementos: {
        p_trncobro_nrodoc: item.TRNCOBRO_NRODOC,
        p_com_codigo: item.COM_CODIGO,
        p_trnanex_idcretri: item.TRNANEX_IDCRETRI,
        p_trnanex_tipcom: item.TRNANEX_TIPCOM,
        p_trnanex_autorizacion: item.TRNANEX_AUTORIZACION, //
        p_trnanex_baseimptarcero: item.TRNANEX_BASEIMPTARCERO,
        p_trnanex_baseimpgrabadaiva: item.TRNANEX_BASEIMPGRABADAIVA,
        p_trnanex_montoiva: item.TRNANEX_MONTOIVA,
        p_trnanex_baseimpnoobjetoiva: item.TRNANEX_BASEIMPNOOBJETOIVA,
        p_trnanex_montoice: item.TRNANEX_MONTOICE,
        p_trnanex_montoretivabie: item.TRNANEX_MONTORETIVABIE,
        p_trnanex_montoretivaser: item.TRNANEX_MONTORETIVASER,
        p_trnanex_retvacien: item.TRNANEX_RETVACIEN,
        p_usu_identificacion: item.USUIDENTIFICACION,
        p_trnanex_autdocmod: item.TRNANEX_AUTDOCMOD, //
        p_trnanex_secdocmod: item.TRNANEX_SECDOCMOD, //
        p_trnanex_seriedocmod: item.TRNANEX_SERIEDOCMOD, //
        p_trnanex_tiposritarcero: item.TRNANEX_TIPOSRITARCERO,
        p_trnanex_tiposrigravada: item.TRNANEX_TIPOSRIGRAVADA,
        p_trnanex_tipcommod: item.TRNANEX_TIPCOMMOD //
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultaUtilmoCheque(bancodigo): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/662fcf57b2645c0', {
      elementos: {
        p_BAN_codigop: bancodigo,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  eliminarTrnCobro(trncobronrodoc): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/75656eeeecc2f80', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminaDettrncobro(trncobronrodoc, dettrncobronrosec): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/756566bbbbbb300', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01',
        p_dettrncobro_nrosec: dettrncobronrosec
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  anularTrnCobroCXP(trncobronrodoc): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/55fd5eeeecc2f80', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  ELIMINARTRNDOCUMENTO(trncobronrodoc): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/75656eeeedc33c0', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  EstadoCuentaAnticipo(documento): Promise<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/1ceb59d52afd93000', {
      elementos: {
        p_documento: documento,
        p_com_codigo: '01',
        p_condicion: '',
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  consultarDetTrnCobro(nrodoc): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/cc5f8d777bbbb000', {
      elementos: {
        p_TRNCOBRO_NRODOC: nrodoc,
        p_com_codigo: '01',
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  consultarTRNDOCUMENTO(strOpcion, strCondicion): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/cc5f9ddddb867800', {
      elementos: {
        p_opcion: strOpcion,
        p_filtro: strCondicion,
        p_condicion: 'COM_CODIGO = \'01\''
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  getDATOSANEXOS(trncobronrodoc): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/41dd6abb8e2af0', {
      elementos: {
        p_trncobro_nrodoc: trncobronrodoc,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  CONSULTARDATOS(opcion, filtro, condicion): Observable<any[]> {
    return this.http.post<any>(this.way + '/cxp/trncobro/662fcf57ad57700', {
      elementos: {
        p_opcion: opcion,
        p_filtro: filtro,
        p_condicion: condicion
      }
    }, this.confIniciales.httpOptions());
  }
  
  seleccionarRegistro(strtrncobronrodoc, strTipo) {
    /*let strSQL: string;
    if (strTipo === 'P') {
      strSQL = 'SELECT * FROM (select * from cxp_trncobro' +
        '  where com_codigo=\'01\'' +
        '  order by trncobro_nrodoc asc) WHERE ROWNUM=1';
    }
    if (strTipo === 'U') {
      strSQL = 'SELECT * FROM (select * from cxp_trncobro' +
        '  where com_codigo=\'01\'' +
        '  order by trncobro_nrodoc desc) WHERE ROWNUM=1';
    }
    if (strTipo === 'S') {
      strSQL = 'SELECT * FROM (select * from cxp_trncobro' +
        ' where substr(trncobro_nrodoc,3,LENGTH(trncobro_nrodoc)-2) >' +
        ' substr(\'' + trncobronrodoc + '\',3,LENGTH(\'' + trncobronrodoc + '\')-2)' +
        ' and com_codigo=\'01\' order by substr(trncobro_nrodoc,3,LENGTH(trncobro_nrodoc)-2) asc)' +
        ' WHERE ROWNUM=1';
    }
    if (strTipo === 'A') {
      strSQL = 'SELECT * FROM (select * from cxp_trncobro' +
        ' where substr(trncobro_nrodoc,3,LENGTH(trncobro_nrodoc)-2) <' +
        ' substr(\'' + trncobronrodoc + '\',3,LENGTH(\'' + trncobronrodoc + '\')-2)' +
        ' and com_codigo=\'01\' order by substr(trncobro_nrodoc,3,LENGTH(trncobro_nrodoc)-2) asc)' +
        ' WHERE ROWNUM=1';
    }*/
    
    // console.log(strSQL);
    return this.http.post(this.way + '/cxp/trncobro/1c755ccdba12e7000', {
      elementos: {
        Tipo: strTipo,
        trncobronrodoc: strtrncobronrodoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erProNombre(strParametro) {
    return this.http.post(this.way + '/cxp/trncobro/3b79de2f8b2f6e', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erDocumentos(strProCodigo, strParametro) {
    return this.http.post(this.way + '/cxp/trncobro/76db867acebf700', {
      elementos: {
        PRO_CODIGO: strProCodigo,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erAutSriSerieEstab(strProCodigo, strTipoDoc, strFechaEmision) {
    return this.http.post(this.way + '/cxp/trncobro/3b6af779b971dc00000', {
      elementos: {
        PRO_CODIGO: strProCodigo,
        TipoDoc: strTipoDoc,
        FechaEmision: strFechaEmision
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion(strCodigo) {
    return this.http.post(this.way + '/cxp/trncobro/3b6cc379b935dc00000', {
      elementos: {
        TRNCOBRO_CODFORPAG: strCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erEncfacproReferencia(strReferencia) {
    return this.http.post(this.way + '/cxp/trncobro/edbaec7a99b74000', {
      elementos: {
        TRNCOBRO_REFERENCIAEXTERNA: strReferencia
      },
    }, this.confIniciales.httpOptions());
  }

  erConCentro(strConCodigo) {
    return this.http.post(this.way + '/cxp/trncobro/3b6cc5d8ebf778', {
      elementos: {
        CON_CODIGO: strConCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConTipgas(strConCodigo) {
    return this.http.post(this.way + '/cxp/trncobro/3b6cc5fb2cc15c', {
      elementos: {
        CON_CODIGO: strConCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConcodigo1(strProCodigo) {
    return this.http.post(this.way + '/cxp/trncobro/76d98bb30d94300', {
      elementos: {
        PRO_CODIGO: strProCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion2(strFechaEmision,strParametro) {
    return this.http.post(this.way + '/cxp/trncobro/edb30de6e4d77000', {
      elementos: {
        FechaEmision: strFechaEmision,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erCodSriDescripcion3(strFechaEmision,strParametro) {
    return this.http.post(this.way + '/cxp/trncobro/3b6cc379b935c4', {
      elementos: {
        FechaEmision: strFechaEmision,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erFindSaciMaeReferencia(strParametro) {
    return this.http.post(this.way + '/cxp/trncobro/edd94edb9eedb800', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }

  erCajCodigoProm(strTipoPago): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/3b6c54d986ca18', {
      elementos: {
        TipoPago: strTipoPago
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConCodigo3Prom(strCaja): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/3b6cc5d986ca04', {
      elementos: {
        Caja: strCaja
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConCodigo4Prom(strCodBanco): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/3b6cc5d986ca85', {
      elementos: {
        CodBanco: strCodBanco
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erFormaPagoTipoProm(strTipoDoc): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/76df8ddb2a87640', {
      elementos: {
        TIPODOC: strTipoDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  ernumcheque(codban,tipo,num): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/20eeb31ebf7bbc0000', {
      elementos: {
        bancod:codban,
        tipodoc:tipo,
        numero:num
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrnCajDpositoNro1Prom(strNumCheque, strCodBanco, strDocumento): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/3b6c54db9ee2e0', {
      elementos: {
        NumCheque: strNumCheque,
        CodBanco: strCodBanco,
        Documento: strDocumento
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrnCajDpositoNro2Prom(strNumero, strDocNro, strCodCaja): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/3b6c54db9ee2e2', {
      elementos: {
        Numero: strNumero,
        DocNro: strDocNro,
        CodCaja: strCodCaja
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConCodigo5Prom(strCaja): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/1db662ecc3645', {
      elementos: {
        Caja: strCaja
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erBanBancoProm(strCodBanco): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/1db5aaeb55d98', {
      elementos: {
        CodBanco: strCodBanco
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConCodigo6Prom(strCodBanco): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/1db662ecc3646', {
      elementos: {
        CodBanco: strCodBanco
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erConNombreProm(strConcodigo): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/3b6cc5ef8b2f6e', {
      elementos: {
        CONCODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erAsiNroProm(strTrnCobroNroDoc): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/1db7ab9275f78', {
      elementos: {
        TrnCobroNroDoc: strTrnCobroNroDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erAutorizacionSriProm(strProCodigo, strTipoDocumento, strFechaEmision, strSerie, strComprobante): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/3b6af771b97372', {
      elementos: {
        PRO_CODIGO: strProCodigo,
        TipoDocumento: strTipoDocumento,
        FechaEmision: strFechaEmision,
        TRNCOBRO_SERIE: strSerie,
        TRNCOBRO_NROCOMPROBANTE: strComprobante
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrnCobroNroComprobanteProm(strNumDoc): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/76efbc3316cef00', {
      elementos: {
        NumDoc: strNumDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erTrnBanConciliaProm(strNumDoc, strCodBanco, strTipoDoc): Promise<any> {
    return this.http.post(this.way + '/cxp/trncobro/76fbbbad5766300', {
      elementos: {
        NumDoc: strNumDoc,
        CodBanco: strCodBanco,
        TipoDoc: strTipoDoc
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  desplegarTrnDatosAnexos(strNroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/35dccb55dc2aee', {
      elementos: {
        TRNCOBRO_NRODOC: strNroDoc
      },
    }, this.confIniciales.httpOptions());
  }

  verificarIsAnexoNuevo(strNroDoc): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/fbb727b54abbb00', {
      elementos: {
        TRNCOBRO_NRODOC: strNroDoc
      },
    }, this.confIniciales.httpOptions());
  }

  actualizaUltimoCheque(strNumCh, strBanCodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/299df57ac8ebce', {
      elementos: {
        NumCh: strNumCh,
        BanCodigo: strBanCodigo
      },
    }, this.confIniciales.httpOptions());
  }

  mostrarDatosPromise(strTrnCobroNroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/2d8e776d5771c', {
        elementos: {
          TrnCobroNroDoc: strTrnCobroNroDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  cxp_fnc_PagadoDocumento(strTrnCobroNroDoc, strProCodigo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/3eeccaa0dc33d6', {
        elementos: {
          TrnCobroNroDoc: strTrnCobroNroDoc,
          ProCodigo: strProCodigo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  saldoDocumento(strTrnCobroNroDoc, strCodProveedor): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/71556e19eb3afc', {
        elementos: {
          TrnCobroNroDoc: strTrnCobroNroDoc,
          CodProveedor: strCodProveedor
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  ImporteDocumento(strTrnCobroNroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/4ad9c6fadc33d8', {
        elementos: {
          TrnCobroNroDoc: strTrnCobroNroDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  cxp_fnc_verificar_trans_cash(strDocumento, strProCodigo, strTipo): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/fbb3eedf76a62800', {
        elementos: {
          Documento: strDocumento,
          ProCodigo: strProCodigo,
          Tipo: strTipo
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  verificarPeriodoContable(strfecha): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/65db93317ea974', {
        elementos: {
          fecha: strfecha
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  consultarCaja(strNumDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/198bf3d562a6a', {
        elementos: {
          NumDoc: strNumDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  consultarBancos(strNumDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/198bf3d55aaec', {
        elementos: {
          NumDoc: strNumDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  consultarCajaDev(strNumDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/3317e3153535e0', {
        elementos: {
          NumDoc: strNumDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  consultarBancosDev(strNumDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/3317e2d57635e0', {
        elementos: {
          NumDoc: strNumDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  pasaTipodocumento(strFechaEmision, strtipDocNombre): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/655cecb2dc33d8', {
        elementos: {
          FechaEmision: strFechaEmision,
          tipDocNombre: strtipDocNombre
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  eliminarCaja(strNumDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/1d595a5762a6a', {
        elementos: {
          NumDoc: strNumDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  eliminarBancos(strNumDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/7565695d6abb300', {
        elementos: {
          NumDoc: strNumDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  eliminarCajaDev(strNumDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/1d59598a9b5df', {
        elementos: {
          NumDoc: strNumDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  eliminarBancosDev(strNumDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/1d59596abb5df', {
        elementos: {
          NumDoc: strNumDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  eliminarDocumento(strCondicion): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/1d5959b867ad7', {
        elementos: {
          Condicion: strCondicion
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  actualizarEstadoDocumento(strEstado, strCondicion): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/14cef3ad6e19e', {
        elementos: {
          Estado: strEstado,
          Condicion: strCondicion
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  consultarImporteAnterior(strNroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/662fc95b2abf5c0', {
        elementos: {
          TRNCOBRO_NRODOC: strNroDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  verificarExistenciaComprobante(strComprobante,  strProCodigo, strTipoDocumento, strNroDoc): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/cxp/trncobro/7ddb7b998b676c', {
        elementos: {
          TRNCOBRO_NROCOMPROBANTE: strComprobante,
          PRO_CODIGO: strProCodigo,
          TipoDocumento: strTipoDocumento,
          TRNCOBRO_NRODOC: strNroDoc
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  
  obtenerClave(strclave): Observable<any> {
    return this.http.post<any>(this.way + '/cxp/trncobro/185f5ccaabee', {
      elementos: {
        clave: strclave
      },
    }, this.confIniciales.httpOptions());
  }
  
  calcularImporteTotal() {
    let intIndiceLista = 0;
    let itemVarios: CxpVarios = {};
    let dblTotalImporteVarios = 0;
    
    // Total de varios
    for (intIndiceLista = 0; intIndiceLista < this.variosarray[this.init.tabs[this.init.tabindex].indice].length; intIndiceLista++) {
      itemVarios = this.variosarray[this.init.tabs[this.init.tabindex].indice][intIndiceLista];
      dblTotalImporteVarios = dblTotalImporteVarios + Number(itemVarios.DEBE) +
        (Number(itemVarios.HABER) * -1);
      itemVarios.DEBE = Number(itemVarios.DEBE).toFixed(2);
      itemVarios.HABER = Number(itemVarios.HABER).toFixed(2);
      console.log(dblTotalImporteVarios);
    }
    
    dblTotalImporteVarios = Math.abs(dblTotalImporteVarios);
    
    this.trncobroarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_IMPORTE = dblTotalImporteVarios.toFixed(2); // detpagoDoc
    
    return dblTotalImporteVarios;
  }
  
  async obtenerCaja(strTipoPago) {
    let strCaja = '';
    
    try {
      const er = await this.erCajCodigoProm(strTipoPago);
      if (er !== null) {
        if (er[0] !== undefined) {
          strCaja = er[0].CAJ_CODIGO;
        }
      }
    } catch (error1) {
    }
    return strCaja;
  }
  
  async obtenerCuentaCaja(strCaja) {
    let strCuenta = '';
    
    try {
      const er = await this.erConCodigo3Prom(strCaja);
      if (er !== null) {
        if (er[0] !== undefined) {
          strCuenta = er[0].CON_CODIGO;
        }
      }
    } catch (error1) {
    }
    return strCuenta;
  }
  
  obtenerNumero(intNumFila) {
    const strNumFila = (intNumFila + 1) + '';
    const strFechaActual = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    return strNumFila + strFechaActual;
  }
  
  async obtenerNumeroCheque(strCodBanco) {
    let strNumeroCh = '';
    try {
      const data = await this.consultaUtilmoCheque(strCodBanco);
      console.log(data);
      for (const rs of data) {
        strNumeroCh = rs.BAN_ULTIMOCHEQUE;
        let intNumCh;
        intNumCh = Number(strNumeroCh) + 1;
        strNumeroCh = intNumCh + '';
      }
    } catch (error) {
      /* e.printStackTrace();
       ErrorDialog.openError(dlgCxP.getParent(), 'Error', 'No se puede consultar el número del cheque.',
         Utilidades.createStatus(e));*/
    }
    return strNumeroCh;
  }
  
  async obtenerCuentaBanco(strCodBanco) {
    let strCuenta = '';
    
    try {
      const er = await this.erConCodigo4Prom(strCodBanco);
      if (er !== null) {
        if (er[0] !== null) {
          strCuenta = er[0].CON_CODIGO;
        }
      }
    } catch (error) {
      /* e.printStackTrace();
       ErrorDialog.openError(new Shell(), 'Error', 'No se puede consultar la cuenta contable del banco',
         Utilidades.createStatus(e));*/
    }
    return strCuenta;
  }

  getConfiguraciones(codigo): number {
    const valorConfig = this.hmpConfiguracion.find(element => element.CFG_CODIGO === codigo).CFG_VALOR1;
    return valorConfig;
  }
    //CDPJ
    erBancodigo(strConcodigo): Observable<any[]> {
      return this.http.post<any[]>(this.way + '/cxc/carteraactiva/3b6b55d986ca18', {
        elementos: {
          CON_CODIGO: strConcodigo
        },
      }, this.confIniciales.httpOptions());
    }
    consultarAsientos(strNroDoc): Promise<any[]> {
      return this.http.post<any[]>(this.way + '/cxp/trnpagodoc/cc5f9e5724ebf800', {
        elementos: {
          TRNCOBRO_NRODOC: strNroDoc
        }
      }, this.confIniciales.httpOptions()).toPromise();
    }
    //CDPJ
}
