<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="inversion"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="inver" title="Inversiones" appendTo="body">
</p-confirmDialog>
<div class="p-grid" style="width: 98%;">
	<div class="p-col-12">
		<app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
						 [botonBorrar]="btnBorrar"
						 (signal)="manejarSenales($event)"  [barraBotonesDesplaz]="true"
						  [barraBotones3]="true" [barraBotonesAsiCon]="false"
						 [botonAsiento]="false" [botonverAsiento]="false" [botonBuscar]="btnBuscar">
		</app-invmaebarra>
	</div>
</div>
<p-tabView (onChange)="onTab1Change($event)" [activeIndex]="selectTab">
	<p-tabPanel header="Datos" [disabled]="disabledTabDatos">
		<div class="divgrups" id="divPrincipal">
			<div class="p-grid">
				<!--LINE 1-->

				<div class="p-col-2" style="text-align: right">
					<span>Entidas Financiera:</span>
				</div>
				<div class="p-col-1" style="text-align: left">
                    
                    <input id="ENTFIN_CODIGO" type="text" pInputText autocomplete="off"  (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversion.ENTFIN_CODIGO" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                </div>
                <div class="p-col-1" style="text-align: left">
                    <button type="button"
                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                            class="littlebuttons" (click)="buscarBanco(bantrninversion.ENTFIN_CODIGO)"></button>
                </div>
                <div class="p-col-2" style="text-align: left">
                    <input id="nombreBanco" type="text" pInputText autocomplete="off"
                           [(ngModel)]="lblNombreBanco" disabled>
                </div>
                <div class="p-col-1">
                </div>
                <div class="p-col-1">
                    <span>Estado:</span>
                </div>
                <div class="p-col-1" style="text-align: left">
                    <input id="BANINV_ESTADO" type="text" pInputText autocomplete="off"  [(ngModel)]="bantrninversion.BANINV_ESTADO"   [disabled]="tarjdis">
                </div>
                <div class="p-col-1">
                    <span>Asiento:</span>
                </div>
                <!-- <div class="p-col-1" style="text-align: left">
                    <input id="ASI_NRO_APER" type="text" pInputText autocomplete="off"  [(ngModel)]="bantrninversion.ASI_NRO_APER"   [disabled]="tarjdis">
                </div> -->
                <div class="p-col-2">
                    <p-dropdown [options]="cmbasiento" [style]="{'minWidth': '100%', 'width':'100%'}"
                        optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectasiento">
                    </p-dropdown>
                </div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>No. de Certificado:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="BANINV_CERTIFICADO" type="text" pInputText autocomplete="off"  (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversion.BANINV_CERTIFICADO" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>No. de Certificado de Apertura:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="BANINV_CERTIFICADOAPER" type="text" pInputText autocomplete="off"  (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversion.BANINV_CERTIFICADOAPER" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                        </div>
                        <div class="p-col-7">
                        </div>
                        <div class="p-col-1" style="text-align: left">
							<input id="lblTrnBanNumeroRet" type="text" pInputText autocomplete="off"
								   [(ngModel)]="lblTrnBanNumeroRet"   disabled>
                        </div>
					</div>
				</div>
				<!--LINE 4-->
				 <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
						<div class="p-col-2">
							<span>Fecha de Emisión:</span>
						</div>
						<div class="p-col-1">
                            <p-calendar id="BANINV_FECHAEMISION"
                                [inputStyle]="{width: '100%', height: '20px'}"
                                [(ngModel)]="bantrninversion.BANINV_FECHAEMISION"  
                                [readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="cambiarFoco($event)"
                               >
                            </p-calendar>
                        </div>
						<!-- <div class="p-col-1">
							<span>Forma de pago Apertura:</span>
                        </div>
                        <div class="p-col-1" style="text-align: left">
                            <input id="BANINV_FPAPER" type="text" pInputText autocomplete="off"
                                   [(ngModel)]="bantrninversion.BANINV_FPAPER" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                        </div> -->
						<div class="p-col-2">
                            <div class="p-grid">
                                <div class="p-col-7">
                                    <span>Forma de pago Apertura:</span>
                                </div>
                                <div class="p-col-5" style="text-align: left">
                                    <input id="BANINV_FPAPER" type="text" pInputText autocomplete="off" 
                                           [(ngModel)]="bantrninversion.BANINV_FPAPER" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                                </div>
                            </div>
							
						</div>
                        <div class="p-col-1" style="text-align: left">
                            <button type="button"
                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                    class="littlebuttons" (click)="buscarConcodigo(bantrninversion.BANINV_FPAPER,'CONCODIGO1')"></button>
                        </div>
                        <div class="p-col-2" style="text-align: left">
                            <input id="lblNomCtaFPAper" type="text" pInputText autocomplete="off"
                                   [(ngModel)]="lblNomCtaFPAper" disabled>
                        </div>
						
					</div>
                </div> 
                <!--LINE 5-->
                <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
						<div class="p-col-2">
							<span>Plazo Día:</span>
						</div>
                            <div class="p-col-1" style="text-align: left">
                                <input id="BANINV_DIASPLAZO" type="text" pInputText autocomplete="off"  oninput="this.value= this.value.replace(/[^0-9]/g, '');"
                                (keydown)="botonesmodificar()"
                                       [(ngModel)]="bantrninversion.BANINV_DIASPLAZO" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                            </div>
						<div class="p-col-2">
                            <div class="p-grid">
                                <div class="p-col-7">
                                    <span>Forma de pago al cierre:</span>
                                </div>
                                <div class="p-col-5" style="text-align: left">
                                    <input id="BANINV_FPCIERRE" type="text" pInputText autocomplete="off"
                                    
                                           [(ngModel)]="bantrninversion.BANINV_FPCIERRE" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                                </div>
                            </div>
							
						</div>
                        <div class="p-col-1" style="text-align: left">
                            <button type="button"
                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                    class="littlebuttons" (click)="buscarConcodigo(bantrninversion.BANINV_FPCIERRE,'CONCODIGO2')"></button>
                        </div>
                        <div class="p-col-2" style="text-align: left">
                            <input id="lblNomCtaFPCierre" type="text" pInputText autocomplete="off"
                                   [(ngModel)]="lblNomCtaFPCierre" disabled>
                        </div>
						
					</div>
                </div> 
                <!--LINE 6-->
                <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
						<div class="p-col-2">
							<span>Fecha de Vencimiento:</span>
						</div>
                        <div class="p-col-1">
                            <p-calendar id="BANINV_FECHAVENCIMIENTO"
                                [inputStyle]="{width: '100%', height: '20px'}"
                                [(ngModel)]="bantrninversion.BANINV_FECHAVENCIMIENTO"
                                [readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="cambiarFoco($event)"
                               >
                            </p-calendar>
                        </div>
						<div class="p-col-2">
                            <div class="p-grid">
                                <div class="p-col-7">
                                    <span>Cnta. Certificado:</span>
                                </div>
                                <div class="p-col-5" style="text-align: left">
                                    <input id="BANINV_CTACERTIFICADO" type="text" pInputText autocomplete="off"  
                                           [(ngModel)]="bantrninversion.BANINV_CTACERTIFICADO" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                                </div>
                            </div>
							
						</div>
                        <div class="p-col-1" style="text-align: left">
                            <button type="button"
                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                    class="littlebuttons" (click)="buscarConcodigo(bantrninversion.BANINV_CTACERTIFICADO,'CONCODIGO3')"></button>
                        </div>
                        <div class="p-col-2" style="text-align: left">
                            <input id="lblNomCtaCert" type="text" pInputText autocomplete="off"
                                   [(ngModel)]="lblNomCtaCert" disabled>
                        </div>
						
					</div>
                </div> 
                <!--LINE 7-->
                <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
						<div class="p-col-2">
							<span>Valor Nominal:</span>
						</div>
                        <div class="p-col-1" >
                            <input  class="frm" id="BANINV_VALORNOMINAL" type="text" pInputText autocomplete="off"  (input)="solonumero($event)" 
                            style="text-align: right"  (keydown)="botonesmodificar()"
                             [(ngModel)]="bantrninversion.BANINV_VALORNOMINAL" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis"
                             appTwoDigitDecimaNumber>
                        </div>
						<div class="p-col-2">
                            <div class="p-grid">
                                <div class="p-col-7">
                                    <span>Cnta. Intereses:</span>
                                </div>
                                <div class="p-col-5" style="text-align: left">
                                    <input id="BANINV_CTAINTERES" type="text" pInputText autocomplete="off"  
                                           [(ngModel)]="bantrninversion.BANINV_CTAINTERES" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                                </div>
                            </div>
							
						</div>
                        <div class="p-col-1" style="text-align: left">
                            <button type="button"
                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                    class="littlebuttons" (click)="buscarConcodigo(bantrninversion.BANINV_CTAINTERES,'CONCODIGO4')"></button>
                        </div>
                        <div class="p-col-2" style="text-align: left">
                            <input id="lblNomCtaInteres" type="text" pInputText autocomplete="off"
                                   [(ngModel)]="lblNomCtaInteres" disabled>
                        </div>
						
					</div>
                </div> 
                <!--LINE 8-->
                <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
						<div class="p-col-2">
							<span>Tasa de Interés Anual:</span>
						</div>
                        <div class="p-col-1" >
                            <input class="frm" id="BANINV_INTERESANUAL" type="text" pInputText autocomplete="off" (input)="solonumero($event)" style="text-align: right"
                            (keydown)="botonesmodificar()"
                                   [(ngModel)]="bantrninversion.BANINV_INTERESANUAL" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis"
                                   appTwoDigitDecimaNumber>
                        </div>
						<div class="p-col-2">
                            <div class="p-grid">
                                <div class="p-col-7">
                                    <span>Cnta. Intereses Ganados:</span>
                                </div>
                                <div class="p-col-5" style="text-align: left">
                                    <input id="BANINV_CTAOTROSINT" type="text" pInputText autocomplete="off"
                                    
                                           [(ngModel)]="bantrninversion.BANINV_CTAOTROSINT" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                                </div>
                            </div>
							
						</div>
                        <div class="p-col-1" style="text-align: left">
                            <button type="button"
                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                    class="littlebuttons" (click)="buscarConcodigo(bantrninversion.BANINV_CTAOTROSINT,'CONCODIGO5')"></button>
                        </div>
                        <div class="p-col-2" style="text-align: left">
                            <input id="lblNomCtaOtrosInteres" type="text" pInputText autocomplete="off"
                                   [(ngModel)]="lblNomCtaOtrosInteres" disabled>
                        </div>
						
					</div>
                </div> 
                <!--LINE 9-->
                <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
						<div class="p-col-2">
							<span>Rentabilidad al vencimiento:</span>
						</div>
                        <div class="p-col-1" >
                            <input id="BANINV_RENTABILIDADVENC" type="text" pInputText autocomplete="off" (input)="solonumero($event)" style="text-align: right"
                            (keydown)="botonesmodificar()"
                                   [(ngModel)]="bantrninversion.BANINV_RENTABILIDADVENC" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                        </div>
						<div class="p-col-2">
                            <div class="p-grid">
                                <div class="p-col-7">
                                    <span>Cnta. Banco:</span>
                                </div>
                                <div class="p-col-5" style="text-align: left">
                                    <input id="BANINV_CTAALTBANCO" type="text" pInputText autocomplete="off"
                                    
                                           [(ngModel)]="bantrninversion.BANINV_CTAALTBANCO" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                                </div>
                            </div>
							
						</div>
                        <div class="p-col-1" style="text-align: left">
                            <button type="button"
                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                    class="littlebuttons" (click)="buscarConcodigo(bantrninversion.BANINV_CTAALTBANCO,'CONCODIGO6')"></button>
                        </div>
                        <div class="p-col-2" style="text-align: left">
                            <input id="lblNomCtaBanco" type="text" pInputText autocomplete="off"
                                   [(ngModel)]="lblNomCtaBanco" disabled>
                        </div>
						
					</div>
                </div> 
                <!--LINE 10-->
                <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
						<div class="p-col-2">
							<span>Interés Diario:</span>
						</div>
                        <div class="p-col-1" >
                            <input id="BANINV_INTERESDIARIO" type="text" pInputText autocomplete="off" (input)="solonumero($event)" style="text-align: right"
                            (keydown)="botonesmodificar()"
                                   [(ngModel)]="bantrninversion.BANINV_INTERESDIARIO" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                        </div>
					</div>
                </div> 
                 <!--LINE 11-->
                 <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
						<div class="p-col-2">
							<span>Origen de los fondos:</span>
						</div>
                        <div class="p-col-10" style="text-align: left">
                            <input id="BANINV_ORIGENFONDOS" type="text" pInputText autocomplete="off"
                            (keydown)="botonesmodificar()"
                                   [(ngModel)]="bantrninversion.BANINV_ORIGENFONDOS" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                        </div>
					</div>
                </div> 
                 <!--LINE 11-->
                 <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
						<div class="p-col-2">
							<span>Destino de los fondos:</span>
						</div>
                        <div class="p-col-10" style="text-align: left">
                            <input id="BANINV_DESTINOFONDOS" type="text" pInputText autocomplete="off"
                            (keydown)="botonesmodificar()"
                                   [(ngModel)]="bantrninversion.BANINV_DESTINOFONDOS" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                        </div>
					</div>
                </div> 
                <!--LINE 12-->
                <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
						<div class="p-col-2">
							<span>Archivo:</span>
						</div>
                        <div class="p-col-4">
                            <button type="button"
                            style="background-image: url(../../../assets/images/iconos/folder3.png);"
                            (click)="almacenardoc()"></button>
                        </div>
					</div>
				</div> 
			</div>
		</div>
		<!-- <p-tabView  [(activeIndex)]="index">
			<p-tabPanel header="Voucher">
				<div class="divgrups">
					<div class="p-grid">
						
						<div class="p-col-10">
						</div>
						<div class="p-col-2" style="margin-bottom: -5px;">
							<p-checkbox label="Seleccionar Todo" binary="true" [(ngModel)]="chkseleccionarTodo" (onChange)="selectAll($event)">
							</p-checkbox>
						</div>
					</div>
				</div>
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="detDepositoTarjeta"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsBanTarjeta"
								[defaultColDef]="defaultColBanTarjeta"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="false">
				</app-aggridsaci>
			</p-tabPanel>
			<p-tabPanel header="Retenciones">
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="trnTarjetaService.detModeloRetencion[indicador]"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsBanTarRetencion"
								[defaultColDef]="defaultColBanTarRetencion"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="true"
								[botonNuevo]="btnNuevoGridRet"
								[botonBorrar]="btnBorrarGridRet"
								[botonRegresar]="btnCancelarGridRet"
								[mostrarGuardar]="false"
								(selected)="seleccionDetRetencion($event)"
								(cambios)="cambio($event)"
				>
				</app-aggridsaci>
				<div class="divgrups">
					<div class="p-grid">
						
						<div class="p-col-10">
						</div>
						<div class="p-col-1">
							<span style="font-weight: bold">Total Retenciones:</span>
						</div>
						<div class="p-col-1" style="text-align: left" style="margin-bottom: -15px;">
							<input id="totalRetenciones" type="text" pInputText autocomplete="off" [(ngModel)]="trnTarjetaService.lblTotalRetenciones" disabled>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="Varios">
				<app-aggridsaci [width]=""
								[height]="largo"
								[enableSorting]="true"
								[enableFilter]="true"
								[rowData]="trnTarjetaService.detModeloVarios[indicador]"
								[frameworkComponents]="frameworkComponents"
								[animateRows]="true"
								[rowSelection]="rowSelection"
								[columnDefs]="columnDefsBanTarVarios"
								[defaultColDef]="defaultColBanTarVarios"
								[rowStyle]="rowStyle"
								[singleClickEdit]="false"
								[botones]="true"
								[botonNuevo]="btnNuevoGridVar"
								[botonBorrar]="btnBorrarGridVar"
								[botonRegresar]="btnCancelarGridVar"
								[mostrarGuardar]="false"
								(selected)="seleccionDetVarios($event)"
								(cambios)="cambio($event)"
				>
				</app-aggridsaci>
				<div class="divgrups">
					<div class="p-grid">
						
						<div class="p-col-10">
						</div>
						<div class="p-col-1">
							<span style="font-weight: bold">Total Varios:</span>
						</div>
						<div class="p-col-1" style="text-align: left" style="margin-bottom: -15px;">
							<input id="totalVarios" type="text" pInputText autocomplete="off" [(ngModel)]="trnTarjetaService.lblTotalVarios" disabled>
						</div>
					</div>
				</div>
			</p-tabPanel>
		</p-tabView> -->
	</p-tabPanel>
	<p-tabPanel header="Interes" [disabled]="disabledTabInteres">
		<div class="divgrups" id="divPrincipal2">
			<div class="p-grid">
                <!--LINE 1-->
                <div class="p-col-8">
                    <span></span>
                </div>
                <div class="p-col-1">
                    <span>Asiento:</span>
                </div>
                <!-- <div class="p-col-1" style="text-align: left">
                    <input id="ASI_NRO_APER" type="text" pInputText autocomplete="off"  [(ngModel)]="bantrninversion.ASI_NRO_APER"   [disabled]="tarjdis">
                </div> -->
                <div class="p-col-2">
                    <p-dropdown [options]="cmbasientoin" [style]="{'minWidth': '100%', 'width':'100%'}"
                        optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectasientoin">
                    </p-dropdown>
                </div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Fecha:</span>
						</div>
						<div class="p-col-1">
                            <p-calendar id="lblfechainteres"
                                [inputStyle]="{width: '100%', height: '20px'}"
                                [(ngModel)]="lblfechainteres"
                                [readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="cambiarFoco($event)"
                               >
                            </p-calendar>
                        </div>
                        <button pButton class="aceptar" (click)="generarinteres()"
								style="line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 100px">
								Generar interes
							</button>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-col-6">
                        <app-aggridsaci [width]="" [height]="largo" [enableSorting]="true"
                            [enableFilter]="true"
                            [rowData]="bantrninversionesService.detbantrninversion[indicador]"
                            [frameworkComponents]="frameworkComponents" [animateRows]="true"
                            [rowSelection]="rowSelection" [columnDefs]="columnDefsInvtrninver"
                            [defaultColDef]="defaultbantrninv" [rowStyle]="rowStyle"
                            [botones]="true"                       
                            [botonNuevo]="true"
                            [botonGuardar]="false"
                            [botonBorrar]="btnGridBorrar"
                            [botonRegresar]="true"
                            [mostrarGuardar]="false"
                            [singleClickEdit]="false"
                            (selected)="selecciontrninver($event)">
                        </app-aggridsaci>
                    </div>
				</div>
                <!--LINE 4-->
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-1">
							<span>Total Interes:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
                    
                            <input id="SumaInteres" type="text" pInputText autocomplete="off" 
                                           [(ngModel)]="sumaInteres"  disabled>
                        </div>
					</div>
				</div>
			</div>
		</div>
		
    </p-tabPanel>
    <p-tabPanel header="Impuestos" [disabled]="disabledTabImpu">
		<div class="divgrups" id="divPrincipal3">
			<div class="p-grid">
				<!--LINE 1-->
				<div class="p-col-2" style="text-align: right">
					<span>Retención:</span>
				</div>
				<div class="p-col-1" style="text-align: left">
                    
                    <input id="RETENCION_CODIGO" type="text" pInputText autocomplete="off" (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversionesService.dettrnretencion.RETENCION_CODIGO" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                </div>
                <div class="p-col-1" style="text-align: left">
                    <button type="button"
                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                            class="littlebuttons" (click)="buscarRetencion(bantrninversionesService.dettrnretencion.RETENCION_CODIGO)"></button>
                </div>
                <div class="p-col-1" style="text-align: left">
                    <input id="lblporcentajeret" type="text" pInputText autocomplete="off"
                           [(ngModel)]="lblporcentajeret" disabled>
                </div>
                <div class="p-col-2" style="text-align: left">
                    <input id="lblNombreret" type="text" pInputText autocomplete="off"
                           [(ngModel)]="lblNombreret" disabled>
                </div>
                <div class="p-col-1">
                </div>
                <div class="p-col-2">
                    <span>Fecha de Emisión:</span>
                </div>
                <div class="p-col-1">
                    <p-calendar id="TRNRETENCION_FECHAEMISION"
                        [inputStyle]="{width: '100%', height: '20px'}"
                        [(ngModel)]="bantrninversionesService.dettrnretencion.TRNRETENCION_FECHAEMISION"
                        [readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="cambiarFoco($event)"
                       >
                    </p-calendar>
                </div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-2">
							<span>Número:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="TRNRETENCION_NRO" type="text" pInputText autocomplete="off" (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversionesService.dettrnretencion.TRNRETENCION_NRO" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                        </div>
                        <div class="p-col-2">
							<span>Base:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="TRNRETENCION_BASE" type="text" pInputText autocomplete="off" (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversionesService.dettrnretencion.TRNRETENCION_BASE" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                        </div>
                        <div class="p-col-2">
							<span>Total Retenido:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
							<input id="TRNRETENCION_TOTALRETENIDO" type="text" pInputText autocomplete="off"
								   [(ngModel)]="bantrninversionesService.dettrnretencion.TRNRETENCION_TOTALRETENIDO" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
						</div>
					</div>
				</div>
			
			</div>
		</div>
		<div class="divgrups" id="divPrincipal3_1">
			<div class="p-grid">
				<!--LINE 1-->
				<div class="p-col-2" style="text-align: right">
					<span>ISD:</span>
				</div>
				<div class="p-col-1" style="text-align: left">
                    
                    <input id="BANINV_ISD" type="text" pInputText autocomplete="off" (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversion.BANINV_ISD" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                </div>
                <div class="p-col-1" style="text-align: left">
                    
                    <input id="ASI_CTAISD" type="text" pInputText autocomplete="off" (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversion.ASI_CTAISD" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                </div>
                <div class="p-col-1" style="text-align: left">
                    <button type="button"
                            style="background-image: url(../../../assets/images/iconos/buscar.png);"
                            class="littlebuttons" (click)="buscarConcodigo(bantrninversion.ASI_CTAISD,'CONCODIGO7')"></button>
                </div>
                <div class="p-col-1" style="text-align: left">
                    <input id="nombreIsd" type="text" pInputText autocomplete="off"
                           [(ngModel)]="lblNombreIsd" disabled>
                </div>
                <div class="p-col-1">
                </div>
			</div>
        </div>
        <div class="divgrups" id="divPrincipal3_2">
			<div class="p-grid">
				<!--LINE 1-->
				<div class="p-col-2" style="text-align: right">
					<span>DIferencia:</span>
				</div>
				<div class="p-col-1" style="text-align: left">
                    
                    <input id="BANINV_DIFERENCIA" type="text" pInputText autocomplete="off" (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversion.BANINV_DIFERENCIA" (keydown.enter)="cambiarFoco($event)"  [disabled]="tarjdis">
                </div>
               
                
                
			</div>
		</div>
    </p-tabPanel>
    <p-tabPanel header="Dia del Vencimiento" [disabled]="disabledTabDiavenci">
		<div class="divgrups" id="divPrincipal4">
			<div class="p-grid">
				<!--LINE 1-->
				<div class="p-col-2">
					<p-radioButton name="opcionFinPlazo" label="Renovación" value="fnpRenova" [disabled]="boolprocesa"
								[(ngModel)]="finplazoSeleccionado" (ngModelChange)="cambiofinplazo()"></p-radioButton>
				</div>
				<div class="p-col-1">
                </div>
                <div class="p-col-1" style="text-align: left">
                    <button pButton class="aceptar" (click)="procesar()" [disabled]="boolprocesa"
                    style="line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 100px">
                    Procesar
                </button>
                </div>
                <div class="p-col-1">
                    <span>Asiento:</span>
                </div>
                <div class="p-col-1" style="text-align: left">
                    <input id="ASI_FINPLAZO" type="text" pInputText autocomplete="off"  [(ngModel)]="bantrninversion.ASI_FINPLAZO"  [disabled]="boolprocesa">
                </div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
						<div class="p-col-2">
                            <p-radioButton name="opcionFinPlazo" label="Cancelación Local" value="fnpCanL"[disabled]="boolprocesa"
                                        [(ngModel)]="finplazoSeleccionado" (ngModelChange)="cambiofinplazo()"></p-radioButton>
                        
                        </div>
                        <div class="p-col-2"></div>

						<div class="p-col-1" style="text-align: left">
							<input id="TRNBAN_NUMERO_V" type="text" pInputText autocomplete="off" [disabled]="boolprocesa"
								   [(ngModel)]="bantrninversion.TRNBAN_NUMERO_V"  [disabled]="true">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
						<div class="p-col-2">
                            <p-radioButton name="opcionFinPlazo" label="Cancelación Exterior" value="fnpCanE" [disabled]="boolprocesa"
                            [(ngModel)]="finplazoSeleccionado" (ngModelChange)="cambiofinplazo()"></p-radioButton>
                           
						</div>
					</div>
				</div>
				<!--LINE 4-->
				 <div class="p-col-12" style="margin-top: -12px"> 
					<div class="p-grid">
                        <div class="p-col-1" style="text-align: left">
                            <button pButton class="aceptar" (click)="reversar()" [disabled]="boolreversavenci"
                            style="line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 100px">
                            Reversar
                        </button>
                        </div>
						
					</div>
                </div> 

			</div>
		</div>
		
    </p-tabPanel>
    <p-tabPanel header="Precancelación" [disabled]="disabledTabPrecan">
		<div class="divgrups" id="divPrincipal5">
			<div class="p-grid">
                <!--LINE 1-->
                <div class="p-col-1"></div>
				<div class="p-col-2">
                    <span>Fecha:</span>
                </div>
                <div class="p-col-1">
                    <p-calendar id="BANINV_FECHAPRECANCELA"
                        [inputStyle]="{width: '100%', height: '20px'}"
                        [(ngModel)]="bantrninversion.BANINV_FECHAPRECANCELA" [disabled]="boolprocesa"
                        [readonlyInput]="false" dateFormat="dd/mm/yy" (keydown.enter)="cambiarFoco($event)"
                       >
                    </p-calendar>
                </div>
                <div class="p-col-1"></div>
                <div class="p-col-1" style="text-align: left">
                                <button pButton class="aceptar" (click)="procesarprecancela()" [disabled]="boolprocesa"
                                style="line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 100px">
                                Procesar
                    </button>
                </div>
                <div class="p-col-1">
                    <span>Asiento:</span>
                </div>
                <div class="p-col-1" style="text-align: left">
                    <input id="ASI_PRECANCELA" type="text" pInputText autocomplete="off"  [(ngModel)]="bantrninversion.ASI_PRECANCELA"   [disabled]="boolprocesa">
                </div>
				<!--LINE 2-->
				<div class="p-col-12" style="margin-top: -7px">
					<div class="p-grid">
                        <div class="p-col-1"></div>
						<div class="p-col-2">
							<span>Tasa de Castigo:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
                            <input id="BANINV_TASACASTIGO" type="text" pInputText autocomplete="off"
                            (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversion.BANINV_TASACASTIGO" (keydown.enter)="cambiarFoco($event)"  [disabled]="boolprocesa">
                        </div>
                        <div class="p-col-1"></div>
                        <div class="p-col-1" style="text-align: left">
							<input id="TRNBAN_NUMERO_PRE" type="text" pInputText autocomplete="off"
								   [(ngModel)]="bantrninversion.TRNBAN_NUMERO_PRE"  [disabled]="true">
						</div>
					</div>
				</div>
				<!--LINE 3-->
				<div class="p-col-12" style="margin-top: -13px">
					<div class="p-grid">
                        <div class="p-col-1"></div>
						<div class="p-col-2">
							<span>Interés:</span>
						</div>
						<div class="p-col-1" style="text-align: left">
                            <input id="BANINV_INTPRECANCELA" type="text" pInputText autocomplete="off"
                            (keydown)="botonesmodificar()"
								   [(ngModel)]="bantrninversion.BANINV_INTPRECANCELA" (keydown.enter)="cambiarFoco($event)"  [disabled]="boolprocesa">
                        </div>
                       
					</div>
				</div>
				<!--LINE 4-->
                <div class="p-col-12" style="margin-top: -12px"> 
                        <div class="p-grid">
                            <div class="p-col-1" style="text-align: left">
                                <button pButton class="aceptar" (click)="reversarprecancela()"
                                style="line-height: 2.5em; font-family: Arial, serif; height: 30px; width: 100px">
                                Reversar
                            </button>
                        </div>
                    </div> 
                </div>
            </div>
		</div>
		
	</p-tabPanel>
</p-tabView>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [responsive]="true"
		  [transitionOptions]="'0ms'"
		  [modal]="false"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloBus" [tabla]="tabla"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>
<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'BANINV'"
		[txtNumComprobante]="bantrninversion.BANINV_CERTIFICADO"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'baninv'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>