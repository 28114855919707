import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from '../../usuario';
import { MessageService, ConfirmationService, SelectItem } from 'primeng/api';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { VenmaeclienteService } from '../../ven/venservicios/venmaecliente.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Cxctmptrnresumen } from '../../ven/veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../../ven/veninterfaces/cxctrnresumen';
import { VenControlTickets } from "../veninterfaces/vencontroltickets";
import { VenControlTicketsResumen } from "../veninterfaces/vencontrolticketresumen";
import { VencontrolticketsService } from "../venservicios/vencontroltickets.service";
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'app-vencontroltickets',
  templateUrl: './vencontroltickets.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class VencontrolticketsComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  largo: string;
  largoestcta: string;

  btnNuevo;
  btnGuardar;
  btnBorrar;
  indicador: any;
  permisos: SegMaePermiso;
  rowSelectionResumen;
  rowStyle;
  rowStyleEC;
  rowStyleSaldo;
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  detalleSelected: VenControlTickets;
  detalleSelectedResumen:VenControlTicketsResumen;
  ///labels encabezado

  lblcliente: string;
  lbltelefono: string;
  lbltiempoacum;
  lbldireccion: string;
  lblcorreo: string;
  lblvendedor: string;
  lblobservacion: string;
  lblcontacto: string;
  lblclicodigo: string;
  //tabs
  index: number;
  bolestcta: boolean;
  bolsaldo: boolean;
  bolresumen: boolean;
  diasabledtabs: boolean;
  diasabledtabsR: boolean;
  botonBorrar: boolean = true;
  //reportes-correos
  boolformscorreo: boolean
  public frameworkComponents;
  rowSelection = 'multiple';
  defaultVenCtrlTickets
  //
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  defaultColDefVenCtrlTicketsResumen;
  spin = false;
  //busqueda
   displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  where:string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  condicion: string;
  opcion: string;
  displayAcciones: boolean;
  columDefsVenCtrlTickets = [
    {
      headerName: 'S/N',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: (params) => {
        return false;
      },
      width: 100,
      onCellClicked: (params) => {
        this.seleccionchek(params.data);
      }
    },
    {
      headerName: 'Cliente', field: 'CLI_CODIGO', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 2,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 20,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre', field: 'CLI_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        return false;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200
        }
      }
    },
    {
      headerName: 'Fecha', field: 'CONTROLTICKET_FECHA', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {
      headerName: 'Tecnico', field: 'REF_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 2,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 20,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Nombre-Tecnico', field: 'REF_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        return false;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200
        }
      }
    },
    {
      headerName: 'Partner', field: 'CONTROLTICKET_PARTNER', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        return true;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50
        }
      }
    },
    {
      headerName: 'Ticket', field: 'CONTROLTICKET_NUMTICKET', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 20
        }
      }
    },
    {
      headerName: 'Detalle Servicio', field: 'CONTROLTICKET_DETALLESER', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        return true;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200
        }
      }
    },
    {
      headerName: 'Tiempo', field: 'CONTROLTICKET_TIEMPO', cellEditor: 'agSelectCellEditor',  editable: true,width: 90, onCellValueChanged: (params) => {
        this.cambio(params);
      },
      cellEditorParams:
      {
        values: ['5', '10', '15', '20', '25']

      }
    },
    {
      headerName: 'Factura', field: 'ENCFAC_NUMERO', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        return false;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50
        }
      }
    },
  ];

  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', editable: (params) => {
        return false;
      },
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Haber', field: 'HABER', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Saldo', field: 'SALDO', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
  ];

  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', editable: (params) => {
        return false;
      },
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Fecha Vence', field: 'TRNCOBRO_FECHAVENCE', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', editable: (params) => {
        return false;
      }, width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Pagado', field: 'PAGADO', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Saldo', field: 'SALDO', editable: (params) => {
        return false;
      }, cellStyle: { textAlign: 'right' }
    },
  ];
  columnDefsVenCtrlTicketsresumen = [
    {
      headerName: 'S/N',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: () => {
        return false;
      },
      width: 50,
    },
    {
      headerName: 'Cod. Cliente', field: 'CLI_CODIGO', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 200
    },
    {
      headerName: 'Cliente', field: 'CLI_NOMBRE', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 300
    },
    {
      headerName: 'Tiempo total Acumulado', field: 'TIEMPOACUM', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 170, cellStyle: { textAlign: 'right' }
    },

  ];
  constructor(private cliService: VenmaeclienteService,
    public vencontrolticketsService: VencontrolticketsService, public usuario: Usuario, private init: NuevoComponentService
    , private datePipe: DatePipe, private permisosService: PermisosService, private messageService: MessageService, private auditoriagral: AuditoriagralService,
    private confIniciales: ConfInicialesService,private busqService: InvbusquedaService, ) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.rowStyleEC = (params) => {
      if (params.data.TRNCOBRO_TIPO !== 'A') {
        if (params.data.TRNCOBRO_FECHATRN === '') {
          return { background: 'rgb( 255, 255, 170)' };
        } else {
          return { background: 'rgb( 196, 225, 255)' };
        }
      }
    };

    this.rowStyleSaldo = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb( 255, 255, 170)' };
      }
    }
    this.defaultVenCtrlTickets = {
      editable: false,
      objeto: 'venctrlticket',
      resizable: true
    };
    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen',
      resizable: true
    };
    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen',
      resizable: true
    };
    this.defaultColDefVenCtrlTicketsResumen = {
      editable: false,
      width: 100,
      objeto: 'venctrlticketresumen',
    };
  }
  ngOnInit(): void {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.largo = '250';
    this.largoestcta = '420';
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.bolestcta = false;
    this.bolsaldo = false;
    this.bolresumen=false;
    this.boolformscorreo=true;
    this.displayAcciones = false;
    this.vencontrolticketsService.ctrltickets = []
    this.vencontrolticketsService.ctrlticketsresumen = []
    this.detalleSelected = {}
    this.detalleSelectedResumen={}
    this.getPermisos();
  }
  async getPermisos() {
    console.log(this.init.tabs[this.init.tabindex - 1]);
    console.log(this.init.tabs[this.init.tabindex - 1].component);
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }
  manejarSenales(evento) {
    if (evento === 'Nuevo') {
      if (this.permisos.PERTODO === 1) {
        this.nuevo();
      } else {
        if (this.permisos.PERINSERCION === 1) {
          this.nuevo();
        } else {
          this.messageService.add({
            key: 'ventickets',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de inserción, acción denegada'
          });
        }
      }
    }
    if (evento === 'Cancelar') {
      this.cancelar();
    }
    if (evento === 'Guardar') {
      if (this.permisos.PERTODO === 1) {
        this.guardar();
      } else {
        if (this.permisos.PERACTUALIZACION === 1) {
          this.guardar();
        } else {
          this.messageService.add({
            key: 'ventickets',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de actualización, acción denegada'
          });
        }
      }
    }
    if(evento === 'Borrar'){
      if (this.permisos.PERTODO === 1) {
        this.eliminarregistro();
      } else {
        if (this.permisos.PERELIMACION === 1) {
          this.eliminarregistro();
        } else {
          this.messageService.add({
            key: 'ventickets',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de eliminación, acción denegada'
          });
        }
      }
    }
    if(evento === 'Buscar'){
      if (this.permisos.PERTODO === 1) {
        this.buscarregistro();
      } else {
        if (this.permisos.PERSELECCION === 1) {
          this.buscarregistro();
        } else {
          this.messageService.add({
            key: 'ventickets',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de búsqueda, acción denegada'
          });
        }
      }
    }
    if (evento === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if(evento === 'Facturar'){
      if( this.btnGuardar === false){
        this.messageService.add({
          key: 'ventickets',
          severity: 'error',
          summary: 'Información',
          detail: 'Primero guarde el registro'
        });
        return;
      }
      if(this.validaseleccion() === false){
        this.messageService.add({
          key: 'ventickets',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe seleccionar al menos un registro ya guardado'
        });
        return;
      }
      this.busquedafactura();
    }
  }
  validaseleccion(){
    let bool=false
    for (const item of this.vencontrolticketsService.ctrltickets){
      if (item.SELECCIONADO === true && item.NUEVO !== 0){
        bool=true;
      }
    }
    return bool;
  }
  busquedafactura() {
    this.opcion = 'factura';
    this.types = [
      { label: 'NÚMERO', value: 'ENCFAC_NUMERO' },
      { label: 'F_EMISIÓN', value: 'ENCFAC_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCFAC_ESTADO' },
      { label: 'COD_CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE_CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'RUC', value: 'ENCFAC_RUCIDE' },
      { label: 'BODEGA', value: 'BOD_CODIGO' },
      { label: 'VENDEDOR', value: 'VEN_CODIGO' },
      { label: 'TOTAL', value: 'ENCFAC_TOTAL' },
      { label: 'NETO', value: 'ENCFAC_TOTALNETO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCFAC';
    this.where = 'ENCFAC_ORIGEN'+' !=\'PTOVTA\' and ENCFAC_ESTADO<>\'A\' order by ENCFAC_FECHAEMISION desc ';

    //this.spin = true;
    this.busqService.busquedaVenEncFac3().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.ENCFAC_FECHAEMISION !== null) {
          registro.ENCFAC_FECHAEMISION = this.datePipe.transform(registro.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCFAC_FECHAEMISION = '';
        }
      });
      //this.spin = false;
      this.displayDialogBus= true;
    });
  }
  buscarregistro(){
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'Cod.Cliente', value: 'CLI_CODIGO'},
      {label: 'Nombre', value: 'CLI_NOMBRE'},
      {label: 'Técnico', value: 'REF_CODIGO'},
      {label: 'Nombre-Técnico', value: 'REF_NOMBRE'},
      {label: 'Fecha', value: 'CONTROLTICKET_FECHA'},
      {label: 'Factura', value: 'ENCFAC_NUMERO'},
    ];
  
    this.busquedacampos = ['', '', '', '', '',''];
    this.consulta = 'A.*, SACI_FNC_OBTIENEREFNOMBRE(\'TECOP\',ref_codigo) AS REF_NOMBRE, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,\'01\') AS CLI_NOMBRE ';
    this.tabla = 'VEN_CONTROLTICKET A';
    this.condicion = ' CLI_CODIGO IS NOT NULL and CONTROLTICKET_FACTURADO<>1 ORDER BY CLI_CODIGO asc,CONTROLTICKET_FECHA DESC';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true;

  }
  async eliminarregistro(){
    try {
      if(this.detalleSelected === null || this.detalleSelected === undefined || this.detalleSelected === ''){
        this.messageService.add({
          key: 'ventickets',
          severity: 'error',
          summary: 'Información',
          detail: 'Seleccione un registro'
        });
        return;
      }
      await this.vencontrolticketsService.eliminarregistro(this.detalleSelected)
      this.auditoriagral.registrarAuditoria('VEN_CONTROLTICKET', '/Cod.Cli' + this.detalleSelected.CLI_CODIGO + '/Fecha' + this.detalleSelected.CONTROLTICKET_FECHA
      +'/Cod.Tecnico'+this.detalleSelected.REF_CODIGO+'/Num.Ticket'+this.detalleSelected.CONTROLTICKET_NUMTICKET+'/Tiempo'+this.detalleSelected.CONTROLTICKET_TIEMPO,
      'E',
      '', '01', '', '', '', '').subscribe(() => {
      });
      this.vencontrolticketsService.ctrltickets = this.vencontrolticketsService.ctrltickets.filter((val, j) => j !==
      this.vencontrolticketsService.ctrltickets.indexOf(this.detalleSelected));
      this.aggrid.refreshaggrid(this.vencontrolticketsService.ctrltickets, this.defaultVenCtrlTickets.objeto);
      this.messageService.add({
        key: 'ventickets',
        severity: 'success',
        summary: 'Eliminación exitosa',
        detail: 'Se eliminó con éxito'
      });
      this
    } catch (error) {
      this.messageService.add({
            key: 'ventickets',
            severity: 'error',
            summary: 'Información',
            detail: 'Error al momento de eliminar el registro'
          });
    }
  }
  async guardar() {
    try {
      if (await this.validadetalle() === true) {
        if(this.detalleSelected.NUEVO === 0){
          if(this.detalleSelected.CONTROLTICKET_FECHA.includes('-')){
            this.detalleSelected.CONTROLTICKET_FECHA=this.datePipe.transform(this.detalleSelected.CONTROLTICKET_FECHA,'dd/MM/yyyy')
          }else{
            this.detalleSelected.CONTROLTICKET_FECHA=this.detalleSelected.CONTROLTICKET_FECHA
          }
          this.detalleSelected.CONTROLTICKET_TIEMPO=Number(this.detalleSelected.CONTROLTICKET_TIEMPO)
          await this.vencontrolticketsService.insertarregistro(this.detalleSelected);

          this.auditoriagral.registrarAuditoria('VEN_CONTROLTICKET', '/Cod.Cli' + this.detalleSelected.CLI_CODIGO + '/Fecha' + this.detalleSelected.CONTROLTICKET_FECHA
          +'/Cod.Tecnico'+this.detalleSelected.REF_CODIGO+'/Num.Ticket'+this.detalleSelected.CONTROLTICKET_NUMTICKET+'/Tiempo'+this.detalleSelected.CONTROLTICKET_TIEMPO,
          'I',
          '', '01', '', '', '', '').subscribe(() => {
          });
          this.messageService.add({
            key: 'ventickets',
            severity: 'success',
            summary: 'Información',
            detail: 'El registro se insertó correctamente'
          });
          this.detalleSelected.NUEVO=1
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.aggrid.refreshaggrid(this.vencontrolticketsService.ctrltickets, this.defaultVenCtrlTickets.objeto);
        }else if(this.detalleSelected.NUEVO === 1){
          if(this.detalleSelected.CONTROLTICKET_FECHA.includes('-')){
            this.detalleSelected.CONTROLTICKET_FECHA=this.datePipe.transform(this.detalleSelected.CONTROLTICKET_FECHA,'dd/MM/yyyy')
          }else{
            this.detalleSelected.CONTROLTICKET_FECHA=this.detalleSelected.CONTROLTICKET_FECHA
          }
          this.detalleSelected.CONTROLTICKET_TIEMPO=Number(this.detalleSelected.CONTROLTICKET_TIEMPO)
          await this.vencontrolticketsService.insertarregistro(this.detalleSelected);

          this.auditoriagral.registrarAuditoria('VEN_CONTROLTICKET', '/Cod.Cli' + this.detalleSelected.CLI_CODIGO + '/Fecha' + this.detalleSelected.CONTROLTICKET_FECHA
          +'/Cod.Tecnico'+this.detalleSelected.REF_CODIGO+'/Num.Ticket'+this.detalleSelected.CONTROLTICKET_NUMTICKET+'/Tiempo'+this.detalleSelected.CONTROLTICKET_TIEMPO,
          'A',
          '', '01', '', '', '', '').subscribe(() => {
          });
          this.messageService.add({
            key: 'ventickets',
            severity: 'success',
            summary: 'Información',
            detail: 'El registro se actualizó correctamente'
          });
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.aggrid.refreshaggrid(this.vencontrolticketsService.ctrltickets, this.defaultVenCtrlTickets.objeto);
        }
        
      }
    } catch (error) {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrió un error al insertar el registro'
      });
    }
  }
  async validadetalle() {
    let bool = true;
    const data1 = await this.vencontrolticketsService.EncontrarCliente(this.detalleSelected.CLI_CODIGO).catch(e => {
      this.spin = false
      bool = false;
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrió un error al consultar el cliente'
      });
      return bool;
    });
    const data2 = await this.vencontrolticketsService.EncontrarCliente(this.detalleSelected.CLI_CODIGO).catch(e => {
      this.spin = false
      bool = false;
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrió un error al consultar el técnico'
      });
      return bool;
    });
    if (isNullOrUndefined(this.detalleSelected.CLI_CODIGO) || this.detalleSelected.CLI_CODIGO === '') {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo cliente no puede estar vacío'
      });
      bool = false;
    }
    if (isNullOrUndefined(this.detalleSelected.CLI_NOMBRE) || this.detalleSelected.CLI_NOMBRE === '') {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Nombre no puede estar vacío'
      });
      bool = false;
    }
    if (isNullOrUndefined(this.detalleSelected.CONTROLTICKET_FECHA) || this.detalleSelected.CONTROLTICKET_FECHA === '') {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Fecha no puede estar vacío'
      });
      bool = false;
    }
    if (isNullOrUndefined(this.detalleSelected.REF_CODIGO) || this.detalleSelected.REF_CODIGO === '') {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Técnico no puede estar vacío'
      });
      bool = false;
    }
    if (isNullOrUndefined(this.detalleSelected.REF_NOMBRE) || this.detalleSelected.REF_NOMBRE === '') {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Nombre-Técnico no puede estar vacío'
      });
      bool = false;
    }
    if (isNullOrUndefined(this.detalleSelected.CONTROLTICKET_NUMTICKET) || this.detalleSelected.CONTROLTICKET_NUMTICKET === '') {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Ticket no puede estar vacío'
      });
      bool = false;
    }
    if (isNullOrUndefined(this.detalleSelected.CONTROLTICKET_TIEMPO) || this.detalleSelected.CONTROLTICKET_TIEMPO === '') {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Tiempo no puede estar vacío'
      });
      bool = false;
    }
    if (data1 === undefined || data1 === null || data1 === '') {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'El cliente con el código ' + this.detalleSelected.CLI_CODIGO + ' no existe'
      });
      bool = false
    }
    if (data1 === undefined || data1 === null || data1 === '') {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'El ténico con el código ' + this.detalleSelected.REF_CODIGO + ' no existe'
      });
      bool = false
    }
    return bool
  }
  nuevo() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    const ctrltickets: VenControlTickets = {
      CONTROLTICKET_FACTURADO: 0,
      CLI_CODIGO: '',
      CLI_NOMBRE: '',
      CONTROLTICKET_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      COM_CODIGO: '01',
      REF_CODIGO: '',
      REF_NOMBRE: '',
      CONTROLTICKET_PARTNER: '',
      CONTROLTICKET_NUMTICKET: '',
      CONTROLTICKET_DETALLESER: '',
      CONTROLTICKET_TIEMPO: '',
      ENCFAC_NUMERO: '',
      SELECCIONADO: false,
      NUEVO: 0,
    }
    this.vencontrolticketsService.ctrltickets.push(ctrltickets);
    this.aggrid.refreshaggrid(this.vencontrolticketsService.ctrltickets, this.defaultVenCtrlTickets.objeto);
    this.detalleSelected = this.vencontrolticketsService.ctrltickets[this.vencontrolticketsService.ctrltickets.length - 1];

    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
  this.defaultVenCtrlTickets.objeto].setFocusedCell( this.vencontrolticketsService.ctrltickets.indexOf(this.detalleSelected), 'CLI_CODIGO');
  }
  async cancelar() {
    try {
      this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
      const data = await this.vencontrolticketsService.getlistadatos();
      if (data !== null && data !== undefined) {
        this.vencontrolticketsService.ctrltickets = data;
        if (this.vencontrolticketsService.ctrltickets.length > 0) {
          for (const rs of this.vencontrolticketsService.ctrltickets) {
            
            rs.NUEVO = 1;
            rs.SELECCIONADO = false
            rs.CONTROLTICKET_FECHA=this.datePipe.transform(rs.CONTROLTICKET_FECHA, 'dd/MM/yyyy')
          }
          this.aggrid.refreshaggrid(this.vencontrolticketsService.ctrltickets, this.defaultVenCtrlTickets.objeto);
        }
        this.datosini()
      }
    } catch (error) {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'Hubo un error al consultar los registros'
      });
    }
  }
  async changeTabs() {
    if (this.index === 0) {
      this.bolestcta = false;
      this.bolsaldo = false;
      this.bolresumen = false;
      this.botonBorrar = false;
      this.boolformscorreo = true;
    }
    if (this.index === 1) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.botonBorrar = true;
        this.cxctmptrnresumen = await this.cliService.getInfoEstadoCuenta(this.detalleSelected.CLI_CODIGO,
          'ventickets');
        this.boolformscorreo = true;
      }
    }
    if (this.index === 2) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.botonBorrar = true;
        this.cxctrnresumen = await this.cliService.getInfoSaldos(this.detalleSelected.CLI_CODIGO,
          'ventickets');
        this.boolformscorreo = true;
      }
    }
    if (this.index === 3) {
      document.getElementById('contorno2').style.pointerEvents = 'none';
      if (this.bolresumen === false) {
        let arrayresumen: VenControlTicketsResumen[] = []
        this.vencontrolticketsService.ctrlticketsresumen = [];
        this.bolresumen = true;
        arrayresumen = await this.cliService.getInfoResumenTickets(
          'ventickets', this.usuario.identificacion);
        this.vencontrolticketsService.ctrlticketsresumen = arrayresumen
        this.vencontrolticketsService.ctrlticketsresumen.map(val => {
          val.SELECCIONADO = false;
        })
      }
      this.botonBorrar = true;
    }
  }
  seleccionchek(params: VenControlTickets) {
    console.log(params.SELECCIONADO)
  }
  isEdit(params) {
    if (params.CONTROLTICKET_FACTURADO === 0) {
      return true;
    } else {
      return false;
    }
  }
  cambio(params) {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
  }
  seleccionItem(valor) {
    if (valor.object !== undefined) {
      if (this.vencontrolticketsService.ctrltickets.length === 0) {
        return;
      }
      this.detalleSelected = valor.object;
      if(this.detalleSelected.NUEVO !== 0){
      this.cargarFcaCliente(this.detalleSelected);}
      this.diasabledtabs = false;
    }
  }
  async cargarFcaCliente(dato) {
    try {
      let ven_nombre='';
      const infocli = await this.vencontrolticketsService.informacioncli(dato.CLI_CODIGO)
      const infoven = await this.vencontrolticketsService.informacionven(dato.VEN_CODIGO)
      ven_nombre=infoven === null || infoven === undefined|| infoven[0] === undefined || infoven[0] === null?'':infoven[0].VEN_NOMBRE;
      this.lblcliente = dato.CLI_NOMBRE
      this.lbltelefono = infocli[0].CLI_TELEFONO1 === null || infocli[0].CLI_TELEFONO1 === undefined ?'':String(infocli[0].CLI_TELEFONO1) 
      this.lbltelefono = this.lbltelefono + (infocli[0].CLI_TELEFONO2 === null || infocli[0].CLI_TELEFONO2 === undefined ? '' : '-' + String(infocli[0].CLI_TELEFONO2))
      //this.lblsaldo = dato.TRNCOBRO_SALDO
     this.sumatoriasaldocliente(dato);
      this.lblcontacto=infocli[0].CLI_CONTACTO === null || infocli[0].CLI_CONTACTO === undefined?'':infocli[0].CLI_CONTACTO
      this.lbldireccion = infocli[0].CLI_DIRECCION1 === null || infocli[0].CLI_DIRECCION1 === undefined?'':infocli[0].CLI_DIRECCION1;
      this.lblcorreo = infocli[0].CLI_CORREO === null || infocli[0].CLI_CORREO === undefined?'':infocli[0].CLI_CORREO;
      this.lblvendedor = ven_nombre === null || ven_nombre === undefined?'':ven_nombre;
      this.lblclicodigo=dato.CLI_CODIGO === null || dato.CLI_CODIGO === undefined?'':dato.CLI_CODIGO;
    } catch (error) {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrió un error al cargar los datos del cliente'
      });
    }
  }
  sumatoriasaldocliente(dato){
    this.lbltiempoacum=0;
      for (const cob of this.vencontrolticketsService.ctrltickets) {
        if(cob.CLI_CODIGO === dato.CLI_CODIGO){
          this.lbltiempoacum=Number(this.lbltiempoacum)+Number(cob.CONTROLTICKET_TIEMPO); 
        }         
      }
      console.log(this.lbltiempoacum)
      this.lbltiempoacum=Number(this.lbltiempoacum);

  }
  seleccionItemCob(valor) {
    if (valor.object !== undefined) {
      if (this.vencontrolticketsService.ctrltickets.length === 0) {
        return;
      }
      this.detalleSelectedResumen = valor.object;
      //if(this.detalleSelected.NUEVO !== 0){
      this.cargarFcaCliente(this.detalleSelectedResumen);}
   // }
  }
  datosini() {
    this.lblcliente = '';
    this.lbltelefono = '';
    this.lbltiempoacum = Number(Number(0).toFixed(this.confIniciales.getNumDecSist()));
    this.lbldireccion = '';
    this.lblcorreo = '';
    this.lblvendedor = '';
    this.lblobservacion = '';
    this.lblcontacto='';
    this.lblclicodigo='';
    this.bolestcta = false;
    this.bolsaldo = false;
    this.bolresumen=false;
  }
  async manejarSeleccion(valor){
    if (this.opcion === 'BUSCAR') {
      this.displayDialogBusquedaFast = false;
      this.llenarTabla(valor);
    }
    if (this.opcion === 'factura') {
      this.displayDialogBus = false;
      await this.actualizarTicketsFac(valor.ENCFAC_NUMERO);
    }
  }
  llenarTabla(datos){
    this.vencontrolticketsService.ctrltickets = datos;
    if (this.vencontrolticketsService.ctrltickets.length > 0) {
      for (const rs of this.vencontrolticketsService.ctrltickets) {      
        rs.NUEVO = 1;
        rs.SELECCIONADO = rs.CONTROLTICKET_FACTURADO === 1?true:false
        rs.CONTROLTICKET_FECHA=this.datePipe.transform(rs.CONTROLTICKET_FECHA, 'dd/MM/yyyy')
      }
      this.aggrid.refreshaggrid(this.vencontrolticketsService.ctrltickets, this.defaultVenCtrlTickets.objeto);
      this.datosini()
    }
  }
  async actualizarTicketsFac(encfac){
    try {
      for(const item of this.vencontrolticketsService.ctrltickets){
        if(item.SELECCIONADO === true){
          this.vencontrolticketsService.actualizarticketsfac(item.CONTROLTICKET_NUMTICKET,item.CLI_CODIGO,encfac)
        }
      }
      this.cancelar();
    } catch (error) {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'Hubo un error al actualizar los registros'
      });
    }
  }
  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }
  async mostrartickets(){
    try {
      this.closeSidebar();
      const data = await this.vencontrolticketsService.getallctrltickets();
      if(data !== null && data !== undefined && data.length>0){
        this.vencontrolticketsService.ctrltickets=data;
        for (const rs of this.vencontrolticketsService.ctrltickets) {
          rs.NUEVO = 1;
          rs.SELECCIONADO = rs.CONTROLTICKET_FACTURADO === 1
          rs.CONTROLTICKET_FECHA=this.datePipe.transform(rs.CONTROLTICKET_FECHA, 'dd/MM/yyyy')
        }
        this.aggrid.refreshaggrid(this.vencontrolticketsService.ctrltickets, this.defaultVenCtrlTickets.objeto);
        this.datosini()
      }
    } catch (error) {
      this.messageService.add({
        key: 'ventickets',
        severity: 'error',
        summary: 'Información',
        detail: 'Hubo un error al consultar todos los registros'
      });
    }
  }
}
