<div class="p-grid">
	<div class="p-col-12">
		<div class="p-grid">
			<!-- GSRF -->
			<div class="p-col-11" *ngIf="botones === true && width !== '50' && (CC === undefined || CC === null)" style="height: 300px; overflow-y: scroll; overflow-x: scroll;">
				<div [ngStyle]="style">
					<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham"
						[enableSorting]="enableSorting" [enableFilter]="enableFilter" [rowData]="rowData"
						[frameworkComponents]="frameworkComponents" [animateRows]="animateRows"
						[rowSelection]='rowSelection' [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
						[getRowStyle]="rowStyle" [singleClickEdit]="singleClickEdit"
						[suppressClickEdit]="suppressCellSelection" [valueCache]="true"
						(gridReady)="onGridReady($event)" (keydown)="onCellKeyDown($event)"
						(rowClicked)="seleccion($event)" [overlayNoRowsTemplate]="'Ninguna fila para mostrar'"
						[enableColResize]="true" [localeText]="localeText"
						></ag-grid-angular>
				</div>
			</div>
			<!-- CDPJ -->
			<div class="p-col-11" *ngIf="botones === true && CC === true" style="height: 800px; overflow-y: scroll; overflow-x: scroll;">
				<div [ngStyle]="style">
					<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham"
						[enableSorting]="enableSorting" [enableFilter]="enableFilter" [rowData]="rowData"
						[frameworkComponents]="frameworkComponents" [animateRows]="animateRows"
						[rowSelection]='rowSelection' [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
						[getRowStyle]="rowStyle" [singleClickEdit]="singleClickEdit"
						[suppressClickEdit]="suppressCellSelection" [valueCache]="true"
						(gridReady)="onGridReady($event)" (keydown)="onCellKeyDown($event)"
						(rowClicked)="seleccion($event)" [overlayNoRowsTemplate]="'Ninguna fila para mostrar'"
						[enableColResize]="true" [localeText]="localeText"
						></ag-grid-angular>
				</div>
			</div>
			<!-- CDPJ -->
			<div class="p-col-6" *ngIf="botones === true && width === '50'">
				<div [ngStyle]="style">
					<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham"
						[enableSorting]="enableSorting" [enableFilter]="enableFilter" [rowData]="rowData"
						[frameworkComponents]="frameworkComponents" [animateRows]="animateRows"
						[rowSelection]='rowSelection' [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
						[getRowStyle]="rowStyle" [singleClickEdit]="singleClickEdit"
						[suppressClickEdit]="suppressCellSelection" [valueCache]="true"
						(gridReady)="onGridReady($event)" (keydown)="onCellKeyDown($event)"
						(rowClicked)="seleccion($event)" [overlayNoRowsTemplate]="'Ninguna fila para mostrar'"
						[enableColResize]="true" [localeText]="localeText"></ag-grid-angular>
				</div>
			</div>
			<div class="p-col-5" *ngIf="botones === true && width === '50'">
				<div [ngStyle]="styleimg">
					<img src="../assets/layout/images/oraclepartner.png" style="width: 100%; height: 100%;">
				</div>
			</div>
			<div class="p-col-1" *ngIf="botones === true" style="text-align: left">
				<div class="p-grid">
				<div class="p-col-6">
				<table *ngIf="botones">
					<tr *ngIf="solobotoneliminar !== undefined">
						<td style="margin-top: -7px; margin-bottom: -7px">
							<button class="littlebuttons"
								style="background-image: url(../../../assets/images/iconos/006-eraser.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('eliminar')" title="Eliminar"></button>
						</td>
					</tr>
					<div *ngIf="solobotoneliminar === undefined">
						<div *ngIf="deshabilitarbotones">
							<tr>
								<td>
									<button *ngIf="!botonNuevo" class="littlebuttons" [disabled]="botonNuevo"
										style="background-image: url(../../../assets/images/iconos/nuevotable.png); margin-top: -10px; margin-bottom: -10px"
										(click)="enviarSenalGrid('nuevo')" title="Nuevo"></button>
									<button *ngIf="botonNuevo" class="littlebuttons" [disabled]="botonNuevo"
										style="background-image: url(../../../assets/images/iconos/nuevo-d.png); margin-top: -10px; margin-bottom: -10px"></button>
								</td>
							</tr>
							<tr *ngIf="mostrarGuardar === undefined">
								<td>
									<button *ngIf="!botonGuardar" class="littlebuttons" [disabled]="botonGuardar"
										style="background-image: url(../../../assets/images/iconos/075-diskette.png); margin-top: -10px; margin-bottom: -10px"
										(click)="enviarSenalGrid('guardar')" title="Guardar"></button>
									<button *ngIf="botonGuardar" class="littlebuttons" [disabled]="botonGuardar"
										style="background-image: url(../../../assets/images/iconos/guardar-d.png); margin-top: -10px; margin-bottom: -10px"></button>
								</td>
							</tr>
							<tr>
								<td style="margin-top: -7px; margin-bottom: -7px">
									<button *ngIf="!botonBorrar" class="littlebuttons" [disabled]="botonBorrar"
										style="background-image: url(../../../assets/images/iconos/006-eraser.png); margin-top: -10px; margin-bottom: -10px"
										(click)="enviarSenalGrid('eliminar')" title="Eliminar"></button>
									<button *ngIf="botonBorrar" class="littlebuttons" [disabled]="botonBorrar"
										style="background-image: url(../../../assets/images/iconos/006-eraser-d.png); margin-top: -10px; margin-bottom: -10px"></button>
								</td>
							</tr>
							<tr>
								<td style="margin-top: -7px; margin-bottom: -7px">
									<button *ngIf="!botonRegresar" class="littlebuttons" [disabled]="botonRegresar"
										style="background-image: url(../../../assets/images/iconos/regresar.png); margin-top: -10px; margin-bottom: -10px"
										(click)="enviarSenalGrid('cancelar')" title="Cancelar"></button>
									<button *ngIf="botonRegresar" class="littlebuttons" [disabled]="botonRegresar"
										style="background-image: url(../../../assets/images/iconos/regresar-d.png); margin-top: -10px; margin-bottom: -10px"></button>
								</td>
							</tr>
						</div>
						<div *ngIf="!deshabilitarbotones">
							<tr>
								<td>
									<button class="littlebuttons" disabled
										style="background-image: url(../../../assets/images/iconos/nuevo-d.png); margin-top: -10px; margin-bottom: -10px"></button>
								</td>
							</tr>
							<tr *ngIf="mostrarGuardar === undefined">
								<td>
									<button class="littlebuttons" disabled
										style="background-image: url(../../../assets/images/iconos/guardar-d.png); margin-top: -10px; margin-bottom: -10px"></button>
								</td>
							</tr>
							<tr>
								<td style="margin-top: -7px; margin-bottom: -7px">
									<button class="littlebuttons" disabled
										style="background-image: url(../../../assets/images/iconos/006-eraser-d.png); margin-top: -10px; margin-bottom: -10px"></button>
								</td>
							</tr>
							<tr>
								<td style="margin-top: -7px; margin-bottom: -7px">
									<button class="littlebuttons" disabled
										style="background-image: url(../../../assets/images/iconos/regresar-d.png); margin-top: -10px; margin-bottom: -10px"></button>
								</td>
							</tr>
						</div>
					</div>
					<tr *ngIf="botonesAdicionales !== undefined">
						<td style="margin-top: -7px; margin-bottom: -7px">
							<button *ngIf="botonPedidos === false" class="littlebuttons" [disabled]="botonPedidos"
								style="background-image: url(../../../assets/images/iconos/043-cd.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('pedidos')" title="Abrir Pedidos"></button>
							<button *ngIf="botonOrdenCompra === false" class="littlebuttons" [disabled]="botonOrden"
								style="background-image: url(../../../assets/images/iconos/013-life-saver.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('ordenCompra')" title="Insertar Orden de Compra"></button>
							<button *ngIf="botonImagen === false && imagenProducto !== ''" class="littlebuttons"
								[disabled]="botonImagen"
								style="background-image: url(../../../assets/images/iconos/067-binocular.png); margin-top: -10px; margin-bottom: -10px"
								(click)="op1.toggle($event)" title="Imagen"></button>
								<!-- CDPJ -->
								<button *ngIf="btnXmlret === false" class="littlebuttons"
								style="background-image: url(../../../assets/images/iconos/025-file-1.png); margin-top: 3px; margin-bottom: -10px"
								(click)="enviarSenalGrid('subirxmlret')" title="Subir el XML de Retenciones"></button>
								<button *ngIf="btnGenconcepto === false" class="littlebuttons"
								style="background-image: url(../../../assets/images/iconos/gene-texto.png); margin-top: 3px; margin-bottom: -10px"
								(click)="enviarSenalGrid('generaconcepto')" title="Generar concepto"></button>
								<!-- CDPJ -->
							<p-overlayPanel #op1>
								<img width="400" height="400" [attr.src]="imagenProducto" alt="Imagen Producto">
							</p-overlayPanel>
							<!-- boton apagado<button *ngIf="botonRegresar" class="littlebuttons" [disabled]="botonRegresar"
									style="background-image: url(../../../assets/images/iconos/regresar-d.png); margin-top: -10px; margin-bottom: -10px"
									(click)="regresar()"></button>-->
							<button *ngIf="botonFactura === false" class="littlebuttons" [disabled]="botonFactura"
								style="background-image: url(../../../assets/images/iconos/010-list.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('agPed')" title="Agregar Pedido"></button>
							<button *ngIf="botonFacturaPro === false" class="littlebuttons" [disabled]="botonFacturaPro"
								style="background-image: url(../../../assets/images/iconos/013-life-saver.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('agOC')" title="Insertar Orden de Compra"></button>
							<button *ngIf="botonOrganizar === false" class="littlebuttons" [disabled]="botonOrganizar"
								style="background-image: url(../../../assets/images/iconos/010-list.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('btnOrganizar')"></button>
							<button *ngIf="botonEditCashmng === false" class="littlebuttons"
								title="Editar Ficha Cash Managment" [disabled]="botonOrganizar"
								style="background-image: url(../../../assets/images/iconos/010-list.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('editCashmng')"></button>
							<button *ngIf="botonExpedienteNuevo === false" class="littlebuttons"
								title="Asignar a un expediente nuevo" [disabled]="botonExpedienteNuevo"
								style="background-image: url(../../../assets/images/iconos/add.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('expedienteNuevo')"></button>
							<button *ngIf="btnRecalcularCosto === false" class="littlebuttons"
								style="background-image: url(../../../assets/images/iconos/064-create.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('recalcular')"
								title="Recalcular Costos en la Toma Física"></button>
							<button *ngIf="btnInsertarReservas === false" class="littlebuttons"
								style="background-image: url(../../../assets/images/iconos/164-wishlist.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('reservas')" title="Agregar reservas"></button>
							<button *ngIf="btnInsertarLotes === false" class="littlebuttons"
								style="background-image: url(../../../assets/images/iconos/184-folder-10.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('lotes')" title="Lotes"></button>

						</td>
					</tr>
					<tr *ngIf="botonesAdicionales !== undefined">
						<td style="margin-top: -7px; margin-bottom: -7px">
							<button *ngIf="botonFactura === false" class="littlebuttons" [disabled]="botonFactura"
								style="background-image: url(../../../assets/images/iconos/131-delivery-truck-2.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('agGui')" title="Agregar Guía Remisión"></button>
							<button *ngIf="botonFacturaPro === false" class="littlebuttons" [disabled]="botonFacturaPro"
								style="background-image: url(../../../assets/images/iconos/014-gear.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('agNR')" title="Insertar una Nota de Recepción"></button>
							<button *ngIf="botonCXC === false" class="littlebuttons" [disabled]="botonCXC"
								style="background-image: url(../../../assets/images/iconos/085-creditcard.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('btnDesplCXC')" title="Desplegar Cuentas por Cobrar"></button>
								
							<button *ngIf="botonCXC === true" class="littlebuttons" [disabled]="botonCXC"
								style="background-image: url(../../../assets/images/iconos/085-creditcard_d.png); margin-top: -10px; margin-bottom: -10px"></button>
							<button *ngIf="botonExpedienteExistente === false" class="littlebuttons"
								title="Agregar a un expediente existente" [disabled]="botonExpedienteExistente"
								style="background-image: url(../../../assets/images/iconos/064-create.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('expedienteExistente')"></button>
							<button *ngIf="btnCopiarCostos === false" class="littlebuttons"
								style="background-image: url(../../../assets/images/iconos/075-diskette.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('copiarCostos')"
								title="Recalcular Costos en la Toma Física"></button>
							<button *ngIf="btnVerExistenciaBodPed === false" class="littlebuttons"
								[disabled]="btnVerExistenciaBodPed"
								style="background-image: url(../../../assets/images/iconos/011-checklist.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('existenciasBod')" title="Existencia por bodega"></button>
						<!-- CDPJ -->
						<tr>
							<td style="margin-top: -7px; margin-bottom: -7px">
								<button *ngIf="btnAgitems === false" class="littlebuttons"
								style="background-image: url(../../../assets/images/iconos/008-list-1.png); margin-top: 3px; margin-bottom: -10px"
								(click)="enviarSenalGrid('agregaritems')" title="Agregar items"></button>
							</td>							
						</tr>
						<tr>
							<td style="margin-top: -7px; margin-bottom: -7px">
								<button *ngIf="btnAgitems === false" class="littlebuttons"
								style="background-image: url(../../../assets/images/iconos/094-cd.png); margin-top: 3px; margin-bottom: -10px"
								(click)="enviarSenalGrid('actcantkardex')" title="Actualizar la cantidad en el Kardex"></button>
							</td>
						</tr>
							
						<tr>
							<td style="margin-top: -7px; margin-bottom: -7px">
								<button *ngIf="btnPresu=== false" class="littlebuttons"
								style="background-image: url(../../../assets/images/iconos/055-folder-34.png); margin-top: 3px; margin-bottom: -10px"
								(click)="enviarSenalGrid('abrirpresu')" title="Mostrar Presupuestado y proyectado"></button>
							</td>
						</tr>
						<tr>
							<td>
								<!-- CDPJ -->
								<button *ngIf="btnsubirxls === false" class="littlebuttons" [disabled]="btnsubirxls"
								style="background-image: url(../../../assets/images/iconos/077-xls_2.png); margin-top: 3px; margin-bottom: -10px"
								(click)="enviarSenalGrid('subirxls')" title="Importar datos de Excel"></button>
								
								<!-- CDPJ -->
							</td>
						</tr>
					<!-- CDPJ -->
							</td>
					</tr>
					<tr *ngIf="botonesAdicionales !== undefined">
						<td style="margin-top: -7px; margin-bottom: -7px">
							<!--<button *ngIf="botonVerificarExis === false" class="littlebuttons" [disabled]="botonVerificarExis"
									style="background-image: url(../../../assets/images/iconos/011-checklist.png); margin-top: -10px; margin-bottom: -10px"
									(click)="enviarSenalGrid('verificarExist')" title="Verificiar Existencia"></button>-->
							<!--<boton apagado<button *ngIf="botonRegresar" class="littlebuttons" [disabled]="botonRegresar"
									style="background-image: url(../../../assets/images/iconos/regresar-d.png); margin-top: -10px; margin-bottom: -10px"
									(click)="regresar()"></button>-->
							<button *ngIf="botonFactura === false" class="littlebuttons" [disabled]="botonFactura"
								style="background-image: url(../../../assets/images/iconos/011-checklist.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('existenciasBod')" title="Existencia por bodega"></button>
							<button *ngIf="botonFacturaPro === false" class="littlebuttons" [disabled]="botonFacturaPro"
								style="background-image: url(../../../assets/images/iconos/001-calculator.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('btnDistribuirCostos')"
								title="Distribución por centro de costos"></button>
								
							<button *ngIf="botonInsertTransBan === false" class="littlebuttons"
								[disabled]="botonInsertTransBan"
								style="background-image: url(../../../assets/images/iconos/091-credit-card-2.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('btnTransBanc')"
								title="Insertar Transacción Bancaria Existente"></button>
							<button *ngIf="botonInsertTransBan === true" class="littlebuttons"
								[disabled]="botonInsertTransBan"
								style="background-image: url(../../../assets/images/iconos/091-credit-card-2_d.png); margin-top: -10px; margin-bottom: -10px"></button>
							<button *ngIf="botonAnticipo === false" class="littlebuttons" [disabled]="botonAnticipo"
								style="background-image: url(../../../assets/images/iconos/009-cash-8.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('btnAnticipo')" title="Anticipos"></button>
							<button *ngIf="botonAnticipo === true" class="littlebuttons" [disabled]="botonAnticipo"
								style="background-image: url(../../../assets/images/iconos/009-cash-8.png); margin-top: -10px; margin-bottom: -10px"></button>
							<button *ngIf="btnVerInfoPedArt === false" class="littlebuttons"
								[disabled]="btnVerInfoPedArt"
								style="background-image: url(../../../assets/images/iconos/064-create.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('verInfoArtPed')" title="Visualizar detalle">
							</button>
							
							<br>
							<br>
							<button *ngIf="btnVervehiculo === false" class="littlebuttons"
								[disabled]="btnVervehiculo"
								style="background-image: url(../../../assets/images/iconos/detalle_vehiculo.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('verInfoArtVeh')" title="Visualizar detalle vehiculo"></button>
							<button *ngIf="btnhomologa === false" class="littlebuttons"
								[disabled]="btnhomologa"
								style="background-image: url(../../../assets/images/iconos/164-wishlist.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('homologaitem')" title="Homologar Item"></button>
						</td>
					</tr>
					<!--<tr *ngIf="botonesAdicionales !== undefined">
						<td style="margin-top: -7px; margin-bottom: -7px">
							<button *ngIf="!botonRegresar" class="littlebuttons" [disabled]="botonRegresar"
									style="background-image: url(../../../assets/images/iconos/regresar.png); margin-top: -10px; margin-bottom: -10px"
									(click)="regresar()" title="Cancelar"></button>
							 boton apagado<button *ngIf="botonRegresar" class="littlebuttons" [disabled]="botonRegresar"
									style="background-image: url(../../../assets/images/iconos/regresar-d.png); margin-top: -10px; margin-bottom: -10px"
									(click)="regresar()"></button>
						</td>
					</tr>-->
					<tr *ngIf="botonesAdicionales !== undefined">
						<td style="margin-top: -7px; margin-bottom: -7px">

							<button *ngIf="botonDistribCC === false" class="littlebuttons" [disabled]="botonDistribCC"
								style="background-image: url(../../../assets/images/iconos/001-calculator.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('btnDistribCC')"
								title="Distribución por Centro de Costos"></button>
							<button *ngIf="botonFactura === false" class="littlebuttons" [disabled]="botonFactura"
								style="background-image: url(../../../assets/images/iconos/164-wishlist.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('agOT')" title="Agregar Orden de Trabajo"></button>
						</td>
					</tr>

					<tr *ngIf="botonesAdicionales !== undefined">
						<td style="margin-top: -7px; margin-bottom: -7px">
							<button *ngIf="botonBuscar === false" class="littlebuttons" [disabled]="botonDistribCC"
								style="background-image: url(../../../assets/images/iconos/buscar.png); margin-top: -10px; margin-bottom: -10px"
								(click)="enviarSenalGrid('btnGridBuscar')" title="Buscar en la tabla"></button>
							<button *ngIf="botonBuscar === true" class="littlebuttons" [disabled]="botonDistribCC"
								style="background-image: url(../../../assets/images/iconos/buscar-d.png); margin-top: -10px; margin-bottom: -10px"
								title="Buscar en la tabla"></button>
						</td>
					</tr>

					
				</table>
				</div>
				<div class="p-col-6">
					<tr *ngIf="comboOpciones !== undefined">
						<td>
							<div class="p-grid" [ngStyle]="{'pointer-events': tipodetalleactivo}">
								<div class="p-col-12" *ngFor="let opcion of comboOpciones" style="margin-top: -12px">
									<!--<p-radioButton name="grupo1" [value]="opcion.value" [label]="opcion.label"
												   [(ngModel)]="tipodetalle" (onClick)="seleccionrdb($event)"></p-radioButton>-->
									<!--<p-radioButton name="grupo1" [value]="opcion.value" [label]="opcion.label"
												   (onClick)="seleccionrdb($event)">{{opcion.label}}</p-radioButton>-->
									<label for="TIPODETALLE"></label>
									<input id="TIPODETALLE" type="radio" [name]='tipodetalle' [value]="opcion.value"
										[(ngModel)]="tipodetalle" (click)="seleccionrdb($event)">{{opcion.label}}
								</div>
							</div>
						</td>
					</tr>
				</div>
			</div>
			</div>
		</div>
	</div>
	<div class="p-col-12" *ngIf="botones === false && width !== '50'">
		<div [ngStyle]="style">
			<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham" [enableSorting]="enableSorting"
				[enableFilter]="enableFilter" [rowData]="rowData" [frameworkComponents]="frameworkComponents"
				[animateRows]="animateRows" [rowSelection]='rowSelection' [columnDefs]="columnDefs"
				[defaultColDef]="defaultColDef" [getRowStyle]="rowStyle" [singleClickEdit]="singleClickEdit"
				[enableCellChangeFlash]="true" [suppressClickEdit]="suppressCellSelection" [valueCache]="true"
				(gridReady)="onGridReady($event)" (keydown)="onCellKeyDown($event)" (rowClicked)="seleccion($event)"
				[overlayNoRowsTemplate]="'Ninguna fila para mostrar'" [enableColResize]="true"
				[localeText]="localeText"></ag-grid-angular>
		</div>
	</div>
	<div class="p-col-6" *ngIf="botones === false && width === '50'">
		<div [ngStyle]="style">
			<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham" [enableSorting]="enableSorting"
				[enableFilter]="enableFilter" [rowData]="rowData" [frameworkComponents]="frameworkComponents"
				[animateRows]="animateRows" [rowSelection]='rowSelection' [columnDefs]="columnDefs"
				[defaultColDef]="defaultColDef" [getRowStyle]="rowStyle" [singleClickEdit]="singleClickEdit"
				[enableCellChangeFlash]="true" [suppressClickEdit]="suppressCellSelection" [valueCache]="true"
				(gridReady)="onGridReady($event)" (keydown)="onCellKeyDown($event)" (rowClicked)="seleccion($event)"
				[overlayNoRowsTemplate]="'Ninguna fila para mostrar'" [enableColResize]="true"
				[localeText]="localeText"></ag-grid-angular>
		</div>
	</div>
	<div class="p-col-6" *ngIf="botones === false &&  width === '50'">
		<div [ngStyle]="styleimg">
			<img src="../assets/layout/images/oraclepartner.png" style="width: 100%; height: 100%;">
		</div>
	</div>
</div>

<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'}" (onShow)="onshowbusq()">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} " (onShow)="onshowbusqF()">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)" [consulta]="consulta"
		[existencias]="existencias" [precioarticulo]="precioarticulo" [descuentoarticulo]="descuentoarticulo">
	</app-busquedadlg>
</p-dialog>

<p-dialog header="Tipo de detalle" [(visible)]="displayDialogOpcionesDetProv" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '30vh', height: '30vh' } ">
	<div class="divgrups" style="text-align: left; align-items: left;">
		<p-radioButton name="orden" value="A" inputId="orden1" [(ngModel)]="tipoDetFactXML"
			(onClick)="seleccionTipoDetDetFacProv()"></p-radioButton>
		<label>   Artículo</label>
		<br>
		<br>
		<p-radioButton name="orden" value="S" inputId="orden1" [(ngModel)]="tipoDetFactXML"
			(onClick)="seleccionTipoDetDetFacProv()"></p-radioButton>
		<label>   Servicio </label>
		<br>
		<br>
		<p-radioButton name="orden" value="C" inputId="orden1" [(ngModel)]="tipoDetFactXML"
			(onClick)="seleccionTipoDetDetFacProv()"></p-radioButton>
		<label>   Cuenta Contable </label>
	</div>
</p-dialog>
