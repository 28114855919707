//
// SACI WEB
// Rutina: Maestro de Cuentas
// Propietario: Nicole Pirca
// Modificado por: SS
// Fecha de creación: 2019
// Fecha de Modificación: 10-12-2019
//

import {Component, OnInit, ViewChild} from '@angular/core';
import {Conmaecuenta} from '../coninterfaces/conmaecuenta';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ConmaeconService} from '../conservicios/conmaecon.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {DatePipe} from '@angular/common';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {Conmaepresupuesto} from '../coninterfaces/conmaepresupuesto';
import {Conmaeparametro} from '../coninterfaces/conmaeparametro';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Component({
  selector: 'app-conmaecon',
  templateUrl: './conmaecon.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class ConmaeconComponent implements OnInit {
  
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  cuentaseleccionada: Conmaecuenta;
  cuentaTipoCuentaSeleccionada: Conmaeparametro;
  presupuestoSeleccionado: Conmaepresupuesto;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnGuardar: boolean;
  btnPrimero: boolean;
  btnAnterior: boolean;
  btnSiguiente: boolean;
  btnUltimo: boolean;
  inputNum: boolean;
  indice: number;
  opcion: string;
  codigoAux: any;
  arregloCons: any[];
  tabla: string;
  displayDialogBusqueda: boolean;
  cmbcampo: any[];
  opcionbusqueda: string;
  tipoCuenta: any[];
  busquedacampos: string[];
  desabilitarCampos: boolean;
  cmbcampoaux: any[];
  presupuestoInicial: any [];
  nuevoCampo: boolean;
  index: number;
  centro;
  commaecuenta: Conmaecuenta = {};
  indexCuenta;
  indexPresupuesto;
  permisoscuenta: SegMaePermiso;
  largo: string;
  public frameworkComponents;
  rowStyle;
  types: SelectItem[];
  actualizar: boolean;
  codigoAnt: string;
  displayDialogBus2: boolean;
  displayDialogBusquedaFast: boolean;
  arregloBus: any[];
  meses: any[];
  valor;
  opcionDropDown: string;
  defaultColDefCuentas;
  defaultColDefPresupuesto;
  defaultFilasMes;
  focus: boolean;
  defaultFilaPresupuesto;
  defaultColDefMayorGeneral;
  cuentaContableAux;
  nombreCuentaAux;
  rowSelection = 'multiple';
  
  btnBuscar = false;
  consulta: string;
  condicion: string;
  
  columnDefsCuenta = [
    {
      headerName: 'Código', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150, editable: false,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
            
          }
        }
    },
    {
      headerName: 'Nombre', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            opcionbuscar: true
          }
        }
    },
    
    {
      headerName: 'Centro', field: 'CON_CENTRO', cellEditor: 'agSelectCellEditor', width: 200,  onCellValueChanged: (params) => {
        this.cambioCentro(params);
      }, 
      cellEditorParams:
        {
          values: ['No', 'Si']
          
        }
    },
    {
      headerName: 'Cod. SRI', field: 'CON_CODSRI', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 20
          }
        }
    },
    {
      headerName: 'Super CIA', field: 'CON_SUPERCIA', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 20
          }
        }
    },
    {
      headerName: 'Tipo de Gasto', field: 'CON_TIPGAS', cellEditor: 'agSelectCellEditor', width: 150, onCellValueChanged: (params) => {
        this.cambioCentro(params);
      }, 
      cellEditorParams:
        {
          values: ['No', 'Si']
          
        }
    }
  ];
  
  columnDefPresupuesto = [
    {
      headerName: 'Mes', field: 'ASI_FECHA', width: 100, editable: false,
      
    },
    {
      
      headerName: 'Presupuesto', field: 'Valor', cellEditor: 'cellPrueba', width: 200, editable: true, cellStyle: {textAlign: 'right'},
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 20,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Débito', field: 'Debe', width: 200, editable: false, cellStyle: {textAlign: 'right'},
      
      
    },
    {
      headerName: 'Crédito', field: 'Haber', width: 200, editable: false, cellStyle: {textAlign: 'right'},
      
      
    },
    {
      headerName: 'Saldo', field: 'Saldo', width: 200, editable: false, cellStyle: {textAlign: 'right'},
    },
  
  ];
  columnDefMayorGeneral = [
    {
      headerName: 'Fecha', field: 'ASI_FECHA', width: 100,
      
    },
    {
      headerName: 'Asiento', field: 'ASI_nro', width: 200,
      
    },
    {
      headerName: 'Concepto', field: 'DETASI_Concepto', width: 300,
      
      
    },
    {
      headerName: 'Debe', field: 'Debe', width: 100, cellStyle: {textAlign: 'right'},
    },
    {
      headerName: 'Haber', field: 'Haber', width: 100, cellStyle: {textAlign: 'right'},
    },
    {
      headerName: 'Saldo', field: 'Saldo', width: 100, cellStyle: {textAlign: 'right'},
    },
  
  ];
  
  constructor(public cuentaService: ConmaeconService, private permisosService: PermisosService,
              private auditoriagral: AuditoriagralService, private message: MessageService,
              private confirmationService: ConfirmationService, private busqService: InvbusquedaService,
              private datePipe: DatePipe, private errorService: ErroresBaseDatosService,
              private init: NuevoComponentService,private confIniciales: ConfInicialesService) {
    
    this.agTable();
    this.permisoscuenta = {};
    this.cuentaService.conmaeMayorGeneral = [];
    this.cuentaService.conmaePresupuesto = [];
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex === 3 || params.node.rowIndex === 7 || params.node.rowIndex === 8 ||
        params.node.rowIndex === 12 || params.node.rowIndex === 16 || params.node.rowIndex === 17 ||
        params.node.rowIndex === 18) {
        return {editable: false, cellEditor: '', cellEditorParams: {}};
      }
    };
    this.defaultColDefCuentas = {
      editable: true,
      width: 130,
      objeto: 'maecuenta'
    };
    this.defaultColDefMayorGeneral = {
      editable: true,
      width: 130,
      objeto: 'maemayorgeneral'
    };
    this.defaultColDefPresupuesto = {
      editable: true,
      width: 130,
      objeto: 'maepresupuesto'
    };
    this.defaultFilasMes = {
      editable: false
    };
  }
  
  ngOnInit() {
    this.index = 0;
    this.llenarCombos();
    this.permisosService.getListaPermisos('CON', '1', '9').subscribe((res) => {
      this.permisoscuenta = res[0];
    });
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.actualizar = false;
    this.nuevoCampo = false;
    this.codigoAux = 0;
    this.desabilitarCampos = true;
    this.presupuestoInicial = [{ASI_FECHA: 'Enero', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 1},
      {ASI_FECHA: 'Febrero', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 2}, {
        ASI_FECHA: 'Marzo', Presupuesto: 0,
        Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 3
      }, {ASI_FECHA: 'Trimestre 1', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0},
      {ASI_FECHA: 'Abril', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 4}, {
        ASI_FECHA: 'Mayo', Presupuesto: 0,
        Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 5
      }, {ASI_FECHA: 'Junio', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 6},
      {ASI_FECHA: 'Trimestre 2', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0},
      {ASI_FECHA: 'Semestre 1', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0},
      {ASI_FECHA: 'Julio', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 7}, {
        ASI_FECHA: 'Agosto', Presupuesto: 0, Debe: 0,
        Haber: 0, Saldo: 0, PRE_DMA: 8
      },
      {ASI_FECHA: 'Septiembre', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 9}, {
        ASI_FECHA: 'Trimestre 3', Presupuesto: 0,
        Debe: 0, Haber: 0, Saldo: 0
      }, {ASI_FECHA: 'Octubre', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 10},
      {ASI_FECHA: 'Noviembre', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 11}, {
        ASI_FECHA: 'Diciembre', Presupuesto: 0, Debe: 0,
        Haber: 0, Saldo: 0, PRE_DMA: 12
      }, {ASI_FECHA: 'Trimestre 4', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0},
      {ASI_FECHA: 'Semestre 2', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0}, {
        ASI_FECHA: 'TOTALES', Presupuesto: 0, Debe: 0,
        Haber: 0, Saldo: 0
      }];
    this.llenarComboCampo();
    this.cuentaService.getCuentas().subscribe((res) => {
      if (res !== null) {
        this.cuentaService.commaecuenta = res;
      }
    });
    
    
    this.largo = '300';
    this.btnBuscar = false;
    
  }
   //CDPJ
 ngOnDestroy(): void {
  this.confIniciales.instanciasCntacontable--;
}
//CDPJ
  manejarSenales(valor) {
    if (valor === 'Buscar') {
      // this.busquedacuentas();
      this.buscarRegistro();
    }
    if (valor === 'Nuevo') {
      this.nuevo();
    }
    if (valor === 'Guardar') {
      this.guardar();
    }
    if (valor === 'Borrar') {
      if (this.permisoscuenta.PERELIMACION === 1) {
        this.btnNuevo = false;
        this.aggrid.isEditable(false);
        if (this.cuentaseleccionada === undefined) {
          this.message.add({
            key: 'maecuenta',
            severity: 'error',
            summary: 'Actualizacion de la Cuenta',
            detail: ' Seleccion Incorrecta, no se ha selecionado una Cuenta'
          });
        } else {
          this.confirmarCuenta();
        }
      } else if (this.permisoscuenta.PERELIMACION === 0) {
        this.message.add({
          key: 'maecuenta',
          severity: 'error',
          summary: 'Elinacion de la Cuenta',
          detail: 'El usuario no tiene permisos de Eliminacion, acción denegada'
        });
        this.cuentaseleccionada.CON_CODIGO = '';
        this.aggrid.refreshaggrid(this.cuentaService.commaecuenta, this.defaultColDefCuentas.objeto);
      }
      this.btnBuscar = false;
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    
  }
  
  cancelar() {
    if (this.index === 0) {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.aggrid.isEditable(false);
      this.cuentaService.getCuentas().subscribe((res) => {
        this.cuentaService.commaecuenta = res;
        this.aggrid.refreshaggrid(this.cuentaService.commaecuenta, this.defaultColDefCuentas.objeto);
      });
    }
    if (this.index === 1) {
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = true;
      this.btnRegresar = true;
      this.cuentaService.obtenerPresupuesto(this.cuentaseleccionada).subscribe(res => {
        this.consultarPresupuesto(res);
      });
      /* this.cuentaService.obtenerTipoCuenta(this.cuentaseleccionada).subscribe((res1) => {
         console.log(res1);
       });*/
    }
    this.btnBuscar = false;
  }
  
  guardar() {
    
    if (this.index === 0) {
      this.guardarCuenta();
    }
    if (this.index === 1) {
      
      this.guardarPresupesto();
    }
  }
  
  nuevo() {
    if (this.index === 0) {
      if (this.permisoscuenta.PERINSERCION === 1) {
        this.btnNuevo = true;
        this.btnGuardar = false;
        this.btnBorrar = false;
        this.btnRegresar = false;
        this.btnPrimero = false;
        this.btnSiguiente = false;
        this.btnAnterior = false;
        this.btnUltimo = false;
        this.columnDefsCuenta[0].editable = true;
        this.aggrid.refreshColumnDefs();
        
        if (this.indexCuenta === -1) {
          //CDPJ
          this.cuentaseleccionada={
            CON_CODIGO: '',
            COM_CODIGO: '01',
            CON_NOMBRE: '',
            CON_CENTRO: 'No',
            CON_CODSRI: '',
            CON_NIVEL:'',
            CON_SUPERCIA:'',
            CON_TIPGAS:'No'
          }
          //CDPJ
          // this.cuentaService.commaecuenta.push({
          //   CON_CODIGO: '',
          //   COM_CODIGO: '01',
          //   CON_NOMBRE: '',
          //   CON_CENTRO: 'No',
          //   CON_CODSRI: '',
          // });
          //this.aggrid.refreshaggrid(this.cuentaService.commaecuenta, this.defaultColDefCuentas.objeto);
          //CDPJ
          this.cuentaService.commaecuenta.push(this.cuentaseleccionada);
          this.cuentaseleccionada=this.cuentaseleccionada;
          this.aggrid.refreshaggrid(this.cuentaService.commaecuenta, this.defaultColDefCuentas.objeto);
          //CDPJ
        } else {
          //CDPJ
          this.cuentaseleccionada={
            CON_CODIGO: '',
            COM_CODIGO: '01',
            CON_NOMBRE: '',
            CON_CENTRO: 'No',
            CON_CODSRI: '',
            CON_NIVEL:'',
            CON_SUPERCIA:'',
            CON_TIPGAS:'No'
          }
          //CDPJ
          // this.cuentaService.commaecuenta.splice(this.indexCuenta + 1, 0, {
          //   CON_CODIGO: '',
          //   COM_CODIGO: '01',
          //   CON_NOMBRE: '',
          //   CON_CENTRO: 'No',
          //   CON_CODSRI: '',
          // });
          // this.aggrid.refreshaggridindex(this.cuentaService.commaecuenta, this.defaultColDefCuentas.objeto, this.indexCuenta);
          // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          // this.defaultColDefCuentas.objeto].setFocusedCell(this.indexCuenta + 1, 'CON_CODIGO');
          //CDPJ
          this.cuentaService.commaecuenta.splice(this.indexCuenta + 1, 0,this.cuentaseleccionada)
          this.cuentaseleccionada=this.cuentaseleccionada;
          this.aggrid.refreshaggridindex(this.cuentaService.commaecuenta, this.defaultColDefCuentas.objeto, this.indexCuenta);
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDefCuentas.objeto].setFocusedCell(this.indexCuenta + 1, 'CON_CODIGO');
          //CDPJ       
        }
      } else if (this.permisoscuenta.PERINSERCION === 0) {
        this.message.add({
          key: 'maecuenta',
          severity: 'error',
          summary: 'Inserción de Cuenta',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    }
    if (this.index === 1) {
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = true;
      this.btnRegresar = false;
      this.btnPrimero = false;
      this.btnSiguiente = false;
      this.btnAnterior = false;
      this.btnUltimo = false;
      this.columnDefPresupuesto[1].editable = true;
    }
    this.btnBuscar = true;
  }
  
  manejarSeleccion(valor) {
    if (valor === 'Buscar') {
      this.cuentaService.commaecuenta.map((cuenta) => {
        if (cuenta.CON_CODIGO === valor.CON_CODIGO) {
          this.indice = this.cuentaService.commaecuenta.indexOf(cuenta);
        }
      });
      this.cuentaseleccionada = this.cuentaService.commaecuenta[this.indice];
      this.codigoAnt = this.cuentaseleccionada.CON_CODIGO;
      this.displayDialogBus2 = false;
    }
  
    if (this.opcionbusqueda === 'BUSCARCUENTA') {
      this.displayDialogBusquedaFast = false;
      this.cuentaService.commaecuenta = valor;
    }
  }
  
  cambio(cambio) {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnRegresar = false;
  }
  
  confirmarCuenta() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar la cuenta seleccionada',
      header: 'Eliminar Cuenta',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarcuenta',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarCuenta();
      },
      reject: () => {
      }
    });
  }
  
  eliminarCuenta() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    // @ts-ignore
    this.cuentaService.VerificarCuentaUso(this.cuentaseleccionada.CON_CODIGO).subscribe((res) => {
      if (res.length !== 0) {
        if (this.cuentaseleccionada.CON_CODIGO === '') {
          this.message.add({
            key: 'maecuenta',
            severity: 'error',
            summary: 'Eliminación de Cuenta',
            detail: 'No se ha seleccionado una cuenta  para eliminar'
          });
        } else if (this.cuentaseleccionada.CON_CODIGO !== '') {
          this.cuentaService.eliminarCuenta(this.cuentaseleccionada, this.codigoAux).subscribe(() => {
            this.message.add({
              key: 'maecuenta',
              severity: 'success',
              summary: 'Eliminación exitosa',
              detail: 'Se eliminó la cuenta'
            });
            this.auditoriagral.registrarAuditoria('CON_MAECON', String(this.cuentaseleccionada.CON_CODIGO), 'E',
              '', '01', '', '', '', '').subscribe(() => {
            });
            this.cuentaService.commaecuenta = this.cuentaService.commaecuenta.filter((val, j) => j !==
              this.cuentaService.commaecuenta.indexOf(this.cuentaseleccionada));
            this.aggrid.refreshaggrid(this.cuentaService.commaecuenta, this.defaultColDefCuentas.objeto);
          }, error1 => {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'maecuenta',
              severity: 'error',
              summary: 'Eliminacion de la cuenta',
              detail: mensaje
            });
          });
        }
        
      } else if (res.length === 0) {
        this.message.add({
          key: 'maecuenta',
          severity: 'error',
          summary: 'Eliminación de Cuenta',
          detail: 'Ha sido imposible eliminar la cuenta debido a que esta posee una o mas subcuentas'
        });
      }
    });
  }
  
  guardarPresupesto() {
    
    this.btnGuardar = false;
    this.btnNuevo = false;
    this.btnBorrar = false;
    this.btnRegresar = true;
    
    this.cuentaService.conmaePresupuesto.map((presu) => {
      
      presu.CON_CODIGO = this.cuentaContableAux;
      if (presu.ASI_FECHA === 'Enero') {
        presu.PRE_DMA = 1;
      }
      if (presu.ASI_FECHA === 'Febrero') {
        presu.PRE_DMA = 2;
      }
      if (presu.ASI_FECHA === 'Marzo') {
        presu.PRE_DMA = 3;
      }
      if (presu.ASI_FECHA === 'Abril') {
        presu.PRE_DMA = 4;
      }
      if (presu.ASI_FECHA === 'Mayo') {
        presu.PRE_DMA = 5;
      }
      if (presu.ASI_FECHA === 'Junio') {
        presu.PRE_DMA = 6;
      }
      if (presu.ASI_FECHA === 'Julio') {
        presu.PRE_DMA = 7;
      }
      if (presu.ASI_FECHA === 'Agosto') {
        presu.PRE_DMA = 8;
      }
      if (presu.ASI_FECHA === 'Septiembre') {
        presu.PRE_DMA = 9;
      }
      if (presu.ASI_FECHA === 'Octubre') {
        presu.PRE_DMA = 10;
      }
      if (presu.ASI_FECHA === 'Noviembre') {
        presu.PRE_DMA = 11;
      }
      if (presu.ASI_FECHA === 'Diciembre') {
        presu.PRE_DMA = 12;
      }
      
      if (presu.PRE_DMA === 1 || presu.PRE_DMA === 2 || presu.PRE_DMA === 3 || presu.PRE_DMA === 4 ||
        presu.PRE_DMA === 5 || presu.PRE_DMA === 6 || presu.PRE_DMA === 7 || presu.PRE_DMA === 8 ||
        presu.PRE_DMA === 9 || presu.PRE_DMA === 10 || presu.PRE_DMA === 11 || presu.PRE_DMA === 12) {
        
        
        this.cuentaService.existePresupuesto(presu).subscribe((existe) => {
          
          if (existe.length > 0) {
            this.cuentaService.actualizarPresupuesto(presu).subscribe(() => {
            });
            this.btnNuevo = true;
            this.btnGuardar = false;
            this.btnBorrar = true;
            this.btnRegresar = true;
            this.cuentaService.obtenerPresupuesto(this.cuentaseleccionada).subscribe(res => {
              this.consultarPresupuesto(res);
            });
          } else {
            this.cuentaService.insertarPresupuesto(presu).subscribe(() => {
              this.btnGuardar = true;
              this.btnNuevo = false;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.cuentaService.obtenerPresupuesto(this.cuentaseleccionada).subscribe(res1 => {
                this.consultarPresupuesto(res1);
              });
            });
          }
        });
      }
    });
  }
  
  
  guardarCuenta() {
    if (this.permisoscuenta.PERACTUALIZACION === 1) {
      this.btnGuardar = false;
      this.btnNuevo = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
      this.btnBuscar = false;
      this.cuentaService.existeCuenta(this.cuentaseleccionada.CON_CODIGO).subscribe((existe) => {
        if (existe.length > 0) {
          if(this.cuentaseleccionada.CON_NIVEL !== undefined && this.cuentaseleccionada.CON_NIVEL !== null && this.cuentaseleccionada.CON_NIVEL !== ''){//CDPJ
            this.cuentaService.actualizarCuenta(this.cuentaseleccionada, this.codigoAux).subscribe(() => {
              this.message.add({
                key: 'maecuenta',
                severity: 'success',
                summary: 'Actualización exitosa',
                detail: 'Se actualizó La cuenta Correctamente'
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.btnBuscar = false;
              this.auditoriagral.registrarAuditoria('CON_MAECON', this.cuentaseleccionada.CON_CODIGO, 'A', '',
                '01', '', '', '', '').subscribe(() => {
              });
              this.aggrid.isEditable(false);
            }, error1 => {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
              this.message.add({
                key: 'maecuenta',
                severity: 'error',
                summary: 'Actualización de la cuenta',
                detail: mensaje
              });
              this.btnNuevo = false;
              this.btnGuardar = true;
              this.btnBorrar = false;
              this.btnRegresar = true;
              this.btnBuscar = false;
              this.cuentaService.getCuentas().subscribe((res) => {
                this.cuentaService.commaecuenta = res;
                this.aggrid.refreshaggrid(this.cuentaService.commaecuenta, this.defaultColDefCuentas.objeto);
              });
            });
          }else{
            this.message.add({
              key: 'maecuenta',
              severity: 'error',
              summary: 'Actualización de la cuenta',
              detail: 'La cuenta contable que desea ingresar ya existe.'
            });
          }
        } else {
          this.cuentaseleccionada.CON_NIVEL = this.calculaNivel(this.cuentaseleccionada.CON_CODIGO);
          this.cuentaService.insertarCuenta(this.cuentaseleccionada).subscribe(() => {
            this.message.add({
              key: 'maecuenta',
              severity: 'success',
              summary: 'Inserción exitosa',
              detail: 'Se insertó la Cuenta'
            });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.btnBuscar = false;
            this.auditoriagral.registrarAuditoria('CON_MAECON',
              String(this.cuentaseleccionada.CON_CODIGO), 'I', '',
              '01', '', '', '', '').subscribe(() => {
            });
            this.aggrid.isEditable(false);
            this.nuevoCampo = false;
          }, error1 => {
            console.log(error1.error);
            let mensaje: string;
            mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
            this.message.add({
              key: 'maecuenta',
              severity: 'error',
              summary: 'Inserción de La Cuenta',
              detail: mensaje
            });
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.btnRegresar = true;
            this.cuentaService.getCuentas().subscribe((res) => {
              this.cuentaService.commaecuenta = res;
              this.aggrid.refreshaggrid(this.cuentaService.commaecuenta, this.defaultColDefCuentas.objeto);
            });
          });
        }
      });
    } else if (this.permisoscuenta.PERACTUALIZACION === 0) {
      this.message.add({
        key: 'maecuenta',
        severity: 'error',
        summary: 'Actualización de la Cuenta',
        detail: 'El usuario no tiene permisos de actualización, acción denegada'
      });
    }
  }
  
  
  /*
  	while (res.next())
				ifDelete = new Integer(res.getString(1));

			if (ifDelete != 1) { // /in dica que se no pudo borrar
				expError = new Exception(
						"Ha sido imposible eliminar la cuenta debido a "
								+ "que esta posee una o mas subcuentas",
						new Throwable("La cuenta posee subcuentas"));
				res.close();
				ejecutor.cierraResultSet();
				return false;
			}
			res.close();
  
  */
  selectedPresupuesto(valor) {
    
    if (this.index === 1) {
      if (valor.object !== null && valor.object !== undefined) {
        this.presupuestoSeleccionado = valor.object;
        this.presupuestoSeleccionado.CON_CODIGO = this.codigoAux;
        
        /* this.cuentaService.obtenerPresupuesto(this.cuentaseleccionada).subscribe((res) => {
           console.log(res);
         });*/
      }
    }
  }
  
  selectedMayorGeneral(valor) {
    
    if (this.index === 0) {
      if (valor.object !== null && valor.object !== undefined) {
        this.cuentaseleccionada = valor.object;
        this.codigoAux = valor.object.CON_CODIGO;
        this.cuentaService.conmaeMayorGeneral = [];
        const arregloMayorGeneral = [];
        this.cuentaService.obtenerMayorGeneral(this.cuentaseleccionada).subscribe((res) => {
          let totalsaldo = 0;
          let totaldebe = 0;
          let totalhaber = 0;
          if (res !== null) {
            res.map((centro) => {
              const centrocosto = {
                ASI_FECHA: '',
                ASI_nro: '',
                DETASI_Concepto: '',
                Debe: '',
                Haber: '',
                Saldo: ''
              };
              centrocosto.ASI_FECHA = centro.Fecha.toLocaleString().substr(0, 10);
              centrocosto.ASI_FECHA = this.datePipe.transform(centrocosto.ASI_FECHA, 'dd/MM/yyyy');
              centrocosto.ASI_nro = centro.Asiento;
              centrocosto.DETASI_Concepto = centro.Concepto;
              centrocosto.Debe = centro.Debe.toFixed(2);
              centrocosto.Haber = (centro.Haber * -1).toFixed(2);
              totalsaldo = totalsaldo + Number(centrocosto.Debe) - Number(centrocosto.Haber);
              centrocosto.Saldo = totalsaldo.toFixed(2);
              totaldebe = totaldebe + Number(centro.Debe);
              totalhaber = totalhaber + Number(centro.Haber);
              arregloMayorGeneral.push(centrocosto);
              
            });
          }
          arregloMayorGeneral.push({
            ASI_FECHA: '',
            ASI_nro: '',
            DETASI_Concepto: '',
            Debe: totaldebe.toFixed(2),
            Haber: (totalhaber * -1).toFixed(2),
            Saldo: totalsaldo.toFixed(2)
          });
          this.cuentaService.conmaeMayorGeneral = arregloMayorGeneral;
        });
      }
    }
  }
  
  consultarPresupuesto(res) {
    let arregloCuentaPresupuesto = [];
    let con = 0;
    let totalprevalor = 0;
    let totaldebe = 0;
    let totalhaber = 0;
    let totalsaldo = 0;
    let totalSaldoAux = 0;
    
    if (res === null) {
      this.cuentaService.conmaePresupuesto = [{ASI_FECHA: 'Enero', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 1, Valor: 0},
        {ASI_FECHA: 'Febrero', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 2, Valor: 0}, {
          ASI_FECHA: 'Marzo', Presupuesto: 0,
          Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 3, Valor: 0
        }, {ASI_FECHA: 'Trimestre 1', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, Valor: 0},
        {ASI_FECHA: 'Abril', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 4, Valor: 0}, {
          ASI_FECHA: 'Mayo', Presupuesto: 0,
          Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 5, Valor: 0
        }, {ASI_FECHA: 'Junio', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 6, Valor: 0},
        {ASI_FECHA: 'Trimestre 2', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, Valor: 0},
        {ASI_FECHA: 'Semestre 1', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, Valor: 0},
        {ASI_FECHA: 'Julio', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 7, Valor: 0}, {
          ASI_FECHA: 'Agosto', Presupuesto: 0, Debe: 0,
          Haber: 0, Saldo: 0, PRE_DMA: 8, Valor: 0
        },
        {ASI_FECHA: 'Septiembre', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 9, Valor: 0}, {
          ASI_FECHA: 'Trimestre 3', Presupuesto: 0,
          Debe: 0, Haber: 0, Saldo: 0, Valor: 0
        }, {ASI_FECHA: 'Octubre', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 10, Valor: 0},
        {ASI_FECHA: 'Noviembre', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, PRE_DMA: 11, Valor: 0}, {
          ASI_FECHA: 'Diciembre', Presupuesto: 0, Debe: 0,
          Haber: 0, Saldo: 0, PRE_DMA: 12, Valor: 0
        }, {ASI_FECHA: 'Trimestre 4', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, Valor: 0},
        {ASI_FECHA: 'Semestre 2', Presupuesto: 0, Debe: 0, Haber: 0, Saldo: 0, Valor: 0}, {
          ASI_FECHA: 'TOTALES', Presupuesto: 0, Debe: 0,
          Haber: 0, Saldo: 0, Valor: 0
        }];
    } else {
      
      arregloCuentaPresupuesto = this.presupuestoInicial;
      
      for (let i = 0; i < 12; i++) {
        let j = i;
        if (i >= 3) {
          j = i + 1;
        }
        if (i >= 6) {
          j = i + 3;
        }
        if (i >= 9) {
          j = i + 4;
        }
        arregloCuentaPresupuesto[j].Valor = res[i].Valor.toFixed(2);
        arregloCuentaPresupuesto[j].Debe = res[i].Debe.toFixed(2);
        arregloCuentaPresupuesto[j].Haber = res[i].Haber.toFixed(2) * -1;
        arregloCuentaPresupuesto[j].Saldo = res[i].Saldo.toFixed(2);
      }
      
      this.cuentaService.conmaePresupuesto = arregloCuentaPresupuesto;
      totalprevalor = 0;
      totaldebe = 0;
      totalhaber = 0;
      totalsaldo = 0;
      for (let i = 0; i < 19; i++) {
        const cuenta = {
          Valor: 0,
          Debe: 0,
          Haber: 0,
          Saldo: 0
        };
        if (con === 3) {
          totalprevalor = 0;
          totaldebe = 0;
          totalhaber = 0;
          totalsaldo = 0;
          for (let i = 0; i < 3; i++) {
            totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
            totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
            totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
          }
          totalhaber = Number(totalhaber);
          totalsaldo = Number(totalhaber) + Number(totaldebe) + Number(totalprevalor);
          this.cuentaService.conmaePresupuesto[3].Valor = totalprevalor.toFixed(2);
          this.cuentaService.conmaePresupuesto[3].Debe = totaldebe.toFixed(2);
          this.cuentaService.conmaePresupuesto[3].Haber = totalhaber.toFixed(2);
          this.cuentaService.conmaePresupuesto[3].Saldo = totalsaldo.toFixed(2);
          
          con++;
          // trimestre 2
        }
        if (con === 7) {
          totalprevalor = 0;
          totaldebe = 0;
          totalhaber = 0;
          totalsaldo = 0;
          for (let i = 4; i < 7; i++) {
            totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
            totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
            totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
          }
          totalhaber = Number(totalhaber);
          totalsaldo = Number(totalhaber) - Number(totaldebe);
          
          this.cuentaService.conmaePresupuesto[7].Valor = totalprevalor.toFixed(2);
          this.cuentaService.conmaePresupuesto[7].Debe = totaldebe.toFixed(2);
          this.cuentaService.conmaePresupuesto[7].Haber = totalhaber.toFixed(2);
          this.cuentaService.conmaePresupuesto[7].Saldo = totalsaldo.toFixed(2);
          con++;
          
        }
        // SEMETRE 1
        if (con === 8) {
          totalprevalor = 0;
          totaldebe = 0;
          totalhaber = 0;
          totalsaldo = 0;
          for (let i = 0; i < 8; i++) {
            if (i === 3 || i === 7) {
              totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
              totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
              totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
            }
          }
          totalhaber = Number(totalhaber);
          totalsaldo = Number(totalhaber) - Number(totaldebe);
          
          this.cuentaService.conmaePresupuesto[8].Valor = totalprevalor.toFixed(2);
          this.cuentaService.conmaePresupuesto[8].Debe = totaldebe.toFixed(2);
          this.cuentaService.conmaePresupuesto[8].Haber = totalhaber.toFixed(2);
          this.cuentaService.conmaePresupuesto[8].Saldo = totalsaldo.toFixed(2);
          con++;
          
        }
        //
        if (con === 12) {
          totalprevalor = 0;
          totaldebe = 0;
          totalhaber = 0;
          totalsaldo = 0;
          for (let i = 9; i < 12; i++) {
            
            totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
            totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
            totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
            
          }
          totalhaber = Number(totalhaber);
          totalsaldo = Number(totalhaber) - Number(totaldebe);
          
          this.cuentaService.conmaePresupuesto[12].Valor = totalprevalor.toFixed(2);
          this.cuentaService.conmaePresupuesto[12].Debe = totaldebe.toFixed(2);
          this.cuentaService.conmaePresupuesto[12].Haber = totalhaber.toFixed(2);
          this.cuentaService.conmaePresupuesto[12].Saldo = totalsaldo.toFixed(2);
          con++;
        }
        if (con === 16) {
          totalprevalor = 0;
          totaldebe = 0;
          totalhaber = 0;
          totalsaldo = 0;
          for (let i = 13; i < 16; i++) {
            totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
            totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
            totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
          }
          
          totalhaber = Number(totalhaber);
          totalsaldo = Number(totalhaber) - Number(totaldebe);
          
          this.cuentaService.conmaePresupuesto[16].Valor = totalprevalor.toFixed(2);
          this.cuentaService.conmaePresupuesto[16].Debe = totaldebe.toFixed(2);
          this.cuentaService.conmaePresupuesto[16].Haber = totalhaber.toFixed(2);
          this.cuentaService.conmaePresupuesto[16].Saldo = totalsaldo.toFixed(2);
          con++;
        }
        if (con === 17) {
          totalprevalor = 0;
          totaldebe = 0;
          totalhaber = 0;
          totalsaldo = 0;
          for (let i = 0; i < 17; i++) {
            if (i === 12 || i === 16) {
              
              totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
              totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
              totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
            }
            
          }
          totalhaber = Number(totalhaber);
          totalsaldo = Number(totalhaber) - Number(totaldebe);
          
          this.cuentaService.conmaePresupuesto[17].Valor = totalprevalor.toFixed(2);
          this.cuentaService.conmaePresupuesto[17].Debe = totaldebe.toFixed(2);
          this.cuentaService.conmaePresupuesto[17].Haber = totalhaber.toFixed(2);
          this.cuentaService.conmaePresupuesto[17].Saldo = totalsaldo.toFixed(2);
          con++;
        }
        if (con === 18) {
          totalprevalor = 0;
          totaldebe = 0;
          totalhaber = 0;
          totalsaldo = 0;
          for (let i = 0; i < 18; i++) {
            if (i === 8 || i === 17) {
              totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
              totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
              totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
            }
          }
          totalhaber = Number(totalhaber);
          totalsaldo = Number(totalhaber) - Number(totaldebe);
          
          this.cuentaService.conmaePresupuesto[18].Valor = totalprevalor.toFixed(2);
          this.cuentaService.conmaePresupuesto[18].Debe = totaldebe.toFixed(2);
          this.cuentaService.conmaePresupuesto[18].Haber = totalhaber.toFixed(2);
          this.cuentaService.conmaePresupuesto[18].Saldo = totalsaldo.toFixed(2);
          con++;
        }
        con++;
      }
      this.cuentaService.conmaePresupuesto = arregloCuentaPresupuesto;
      
      
    }
    
  }
  
  calcularSaldo(tipoCuenta, arregloCuentaPresupuesto) {
    
    let con = 0;
    let totalprevalor = 0;
    let totaldebe = 0;
    let totalhaber = 0;
    let totalsaldo = 0;
    this.cuentaService.obtenerTipoCuenta(this.cuentaseleccionada).subscribe((res) => {
      this.tipoCuenta = res;
      
      for (let i = 0; i < arregloCuentaPresupuesto.length; i++) {
        if (i !== 3 && i !== 7 && i !== 8 && i !== 12 && i !== 16 && i !== 17 && i !== 18) {
          if (tipoCuenta.PAR_NOMBRE === 'ACTIVOOS') {
            totalsaldo += (-totaldebe + totalhaber);
          }
        }
      }
    });
    
    
  }
  
  
  selectedCuenta(valor) {
    console.log(valor);
    
    if (valor.object !== undefined) {
      this.cuentaContableAux = valor.object.CON_CODIGO;
      this.nombreCuentaAux = valor.object.CON_NOMBRE;
      this.indexCuenta = this.cuentaService.commaecuenta.findIndex(cuenta => cuenta.CON_CODIGO === valor.object.CON_CODIGO);
      this.cuentaseleccionada = valor.object;
      this.cuentaTipoCuentaSeleccionada = valor.object;
      this.codigoAux = valor.object.CON_CODIGO;
      
      this.cuentaService.obtenerPresupuesto(this.cuentaseleccionada).subscribe((res) => {
        console.log(res);
        this.consultarPresupuesto(res);
      });
      
      /*this.cuentaService.obtenerTipoCuenta(this.cuentaTipoCuentaSeleccionada.PAR_CODIGO).subscribe((res1) => {
        console.log('entro', res1);
      });*/
      
      //this.selectedMayorGeneral(valor);CDPJ COMENTÉ
    }
    
    
  }
  
  cambioTab() {
    if (this.index === 1) {
      this.aggrid.refreshaggrid(this.cuentaService.conmaePresupuesto, this.defaultColDefPresupuesto.objeto);
    } else if (this.index === 0) {
      this.aggrid.refreshaggrid(this.cuentaService.commaecuenta, this.defaultColDefCuentas.objeto);
    } else if (this.index === 2) {
      this.aggrid.refreshaggrid(this.cuentaService.conmaeMayorGeneral, this.defaultColDefMayorGeneral.objeto);
    }
  }
  
  llenarComboCampo() {
    this.cmbcampo = this.cmbcampoaux;
  }
  
  llenarCombos() {
    this.cmbcampo = [{codigo: '1', name: 'Código'},
      {codigo: '2', name: 'Nombre'},
      {codigo: '3', name: 'Centro'},
      {codigo: '4', name: 'Cod.SRI'}];
  }
  
  
  busqcampo(id) {
    this.commaecuenta.CON_CENTRO = id.value.codigo;
    this.commaecuenta.CON_NOMBRE = id.value.name;
    this.commaecuenta.CON_CENTRO = id.value.centro;
    
  }
  
  busquedacuentas() {
    this.opcionbusqueda = 'buscarCuenta';
    this.types = [
      {label: 'Código', value: 'CON_codigo'},
      {label: 'Nombre', value: 'CON_nombre'},
      {label: 'CNT.COSTOS', value: 'CON_centro'},
    ];
    this.busquedacampos = ['', '', ''];
    this.displayDialogBusquedaFast = true;
    this.tabla = 'CON_MAECON';
    
    /*this.cuentaService.ConsultarCuenta('*').subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });*/
  }
  
  calculaNivel(codigo): number {
    if (codigo.length > 0) {
      let level = 0;
      let aux = codigo.trim();
      while (aux.indexOf('.') !== -1) {
        level++;
        aux = aux.substring(aux.indexOf('.') + 1, aux.length);
      }
      if (aux.length > 0) {
        level++;
      }
      return level;
    }
    return -1;
  }
  
  buscarRegistro() {
    this.opcionbusqueda = 'BUSCARCUENTA';
    this.types = [
      {label: 'CÓDIGO', value: 'CON_CODIGO'},
      {label: 'NOMBRE', value: 'CON_NOMBRE'},
      {label: 'CNT COSTOS', value: 'CON_CENTRO'},
      {label: 'COD.SRI', value: 'CON_CODSRI'},
      {label: 'TIPO DE GASTO', value: 'CON_TIPGAS'},
    ];
    
    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'CON_MAECON';
    this.condicion = '';
    this.arregloCons = [];
    this.displayDialogBusquedaFast = true;
  }

  cambioCentro(event) {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.btnPrimero = false;
    this.btnSiguiente = false;
    this.btnAnterior = false;
    this.btnUltimo = false;
  }
  
  
}


