import { Injectable } from '@angular/core';
import { InvTrnAjustes } from '../invinterfaces/invtrnajustes';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class InvtrnajustenegService {
  public _detTrnAjustesNeg: InvTrnAjustes[] = [];
  get detTrnAjustesNeg(): InvTrnAjustes[] {
    return this._detTrnAjustesNeg;
  }
  set detTrnAjustesNeg(value: InvTrnAjustes[]) {
    this._detTrnAjustesNeg = value;
  }
  private way: string;
  decimales:number;
  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private init: NuevoComponentService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService,
    private auditoriagral: AuditoriagralService,

  ) {
    this.way = sessionStorage.getItem('way');
  }
  getlistadatos() {
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/83bb5973aadb5800000000000', {
      elementos: {},
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  insertardetalle(item: InvTrnAjustes) {
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/95f8edf55b6bb80000000000000000', {
      elementos: {
        p_ART_codigo_1: item.ART_CODIGO,
        p_BOD_codigo_3: item.BOD_CODIGO,
        p_TRNART_fechaes_4: item.TRNART_FECHAES,
        p_TRNART_tipo_5: item.TRNART_TIPO,
        p_TRNART_hora_6: item.TRNART_HORA,
        p_TRNART_numero_7: item.TRNART_NUMERO,
        p_TRNART_referencia_8: item.TRNART_REFERENCIA,
        p_TRNART_cantidad_9: 0,
        p_TRNART_costot_10: item.TRNART_COSTOT,
        p_COM_codigo_2: '01',
        p_TRNART_origen_11: item.TRNART_ORIGEN,
        p_con_codigo: item.CON_CODIGO,
        p_cen_codigo: item.CEN_CODIGO

      },
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  actualizardetalle(item: InvTrnAjustes) {
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/299df2ab2', {
      elementos: {

        p_BOD_codigo_3: item.BOD_CODIGO,
        p_ART_codigo_1: item.ART_CODIGO,
        p_TRNART_numero_7: item.TRNART_NUMERO,
        p_TRNART_fechaes_4: item.TRNART_FECHAES,
        p_TRNART_tipo_5: item.TRNART_TIPO,
        p_TRNART_hora_6: item.TRNART_HORA,
        p_TRNART_referencia_8: item.TRNART_REFERENCIA,
        p_TRNART_cantidad_9: 0,
        p_TRNART_costot_10: item.TRNART_COSTOT,
        p_COM_codigo_2: '01',
        p_TRNART_origen_11: item.TRNART_ORIGEN,
        p_con_codigo: item.CON_CODIGO,
        p_cen_codigo: item.CEN_CODIGO

      },
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  eliminarregistro(item: InvTrnAjustes) {
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/1d595a5756d53f0000', {
      elementos: {
        p_BOD_codigo_3: item.BOD_CODIGO,
        p_ART_codigo_1: item.ART_CODIGO,
        p_TRNART_numero_7: item.TRNART_NUMERO,
        p_COM_codigo_2: '01',
      },
    }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  getBodcodigo(): Observable<any[]> {
    return this.http.post<any>(this.way + '/inventario/trnajustes/41dd5e1ae8299800000', {
      elementos: {}
    }, this.confIniciales.httpOptions());
  }
  existenciaActualArticuloAjNeg(strArtCodigo, strBodCodigo,index): Observable<any[]> {
    
    let strHora = '';
    
    if (this.detTrnAjustesNeg[index].TRNART_HORA.toString().includes('-')) {
      const time = this.detTrnAjustesNeg[index].TRNART_HORA;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      strHora = hora + ':' + minutos;
    } else {
      strHora = this.detTrnAjustesNeg[index].TRNART_HORA;
    }
    
    const fechaAct = this.detTrnAjustesNeg[index].TRNART_FECHAES;
    let strFecha = '';
    
    if (fechaAct.toString().includes('-')) {
      strFecha = this.datePipe.transform(fechaAct, 'dd/MM/yyyy');
    }else{
      strFecha=fechaAct
    }
    
    
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/3b8c533be56fb2', {
      elementos: {
        art_codigo: strArtCodigo,
        bod_codigo: strBodCodigo,
        fecha: strFecha,
        hora: strHora
      }
    }, this.confIniciales.httpOptions());
    
  }
  costoActualArticuloAjNeg(strArtCodigo,index): Observable<any[]> {
    
    let strHora = '';
    
    if (this.detTrnAjustesNeg[index].TRNART_HORA.toString().includes('-')) {
      const time = this.detTrnAjustesNeg[index].TRNART_HORA;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      strHora = hora + ':' + minutos;
    } else {
      strHora = this.detTrnAjustesNeg[index].TRNART_HORA;
    }
    
    const fechaAct = this.detTrnAjustesNeg[index].TRNART_FECHAES;
    let strFecha = '';
    
    if (fechaAct.toString().includes('-')) {
      strFecha = this.datePipe.transform(fechaAct, 'dd/MM/yyyy');
    }else{
      strFecha=fechaAct
    }
    
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/331cea99df2b7c', {
      elementos: {
        art_codigo: strArtCodigo,
        fecha: strFecha,
        hora: strHora
      }
    }, this.confIniciales.httpOptions());
    
  }
  validarcencodigo(cencodigo) {

    return this.http.post<any[]>(this.way + '/ventas/guiaremision/1f5564d56d8ebf0000000000000', {
      elementos: {
        cen_codigo: cencodigo,
        con_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  encontrarArtCntaySer(strArtCodigo, strtipo) {
    return this.http.post<any[]>(this.way + '/ventas/facturas/75d98bf76adab8000000', {
      elementos: {
        ArtCodigo: strArtCodigo,
        strTIPO: strtipo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
