import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { ComencdevfacService } from '../../com/comservicios/comencdevfac.service';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { CalculosServiceService } from '../../servicios/calculos-service.service';
import { VenenguiaRemisionService } from "../../ven/venservicios/venenguia-remision.service";//CDPJ
@Component({
  selector: 'app-busquedadlg',
  templateUrl: './busquedadlg.component.html',
  styleUrls: ['./busquedadlg.component.css']
})
export class BusquedadlgComponent implements OnInit, OnChanges {

  @Input() arregloConsulta: any[];
  @Input() arrayOptions: SelectItem[];
  @Input() tabla: string;
  @Input() busquedaParams: string[];
  @Input() consulta: string;
  @Input() where: string;
  @Input() busquedaCompuesta: boolean;
  @Input() existencias:string;//CDPJ
  @Input() precioarticulo:string;//CDPJ
  @Input() descuentoarticulo:string;//CDPJ
  @Input() DVBUSGR:Number;//CDPJ
  @Output() opcionSeleccionada = new EventEmitter<any>();

  tipoBusqueda: string;
  tipoSeleccionado: string;
  spin: boolean;
  maestro: string;

  constructor(private busqService: InvbusquedaService, private datePipe: DatePipe,
    private comdevService: ComencdevfacService, private calc: CalculosServiceService,
    public init: NuevoComponentService,public guiaservicio:VenenguiaRemisionService) {
    this.busquedaCompuesta = false;
  }

  ngOnInit() {
    this.tipoBusqueda = 'contains';
    this.tipoSeleccionado = '';
    this.consulta = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const valor in changes) {
      if (valor === 'arrayOptions') {
        if (this.arrayOptions !== undefined) {
          this.tipoSeleccionado = this.arrayOptions[0].value;
        }
      } else if (valor === 'arregloConsulta') {
      }
    }
  }

  busquedaIndi(params, tipo) {
    let condicion = '';
    let aux = 0;
    let filtrobusgr=''

    if (tipo === 'contains') {
      aux = 0;
      this.arrayOptions.forEach((item, index) => {
        if (params[index] !== '') {
          if (aux === 0) {
            condicion = 'UPPER(' + item.value + ') LIKE UPPER(\'%' + params[index] + '%\')';
            if (item.value === 'ENCFAC_FECHAEMISION' || item.value === 'ENCPED_FECHAEMISION'
              || item.value === 'ENCGRE_FECHAEMISION' || item.value === 'ENCDVF_FECHAEMISION'
              || item.value === 'ENCFACPRO_FECHAEMISION' || item.value === 'TRNCOBRO_FECHATRN'
              || item.value === 'ASI_FECHA' || item.value === 'DETCASHMNG_FECHA'
              || item.value === 'KAR_FECHA' || item.value === 'ENCORDCOM_FECHAEMISION'
              || item.value === 'ENCNOTREC_FECHAEMISION' || item.value === 'ENCCUADRECAJA_FECHA'
              || item.value === 'ENCCUADRECAJA_DESDE' || item.value === 'ENCCUADRECAJA_HASTA'
              || item.value === 'ENCCUOTA_FECHAINICIO' || item.value === 'TRNART_FECHAES'
              || item.value === 'FECHA' || item.value === 'ENCORDPRO_FECHAEMISION'
              || item.value === 'ENCORDPRO_FECHAINICIO'
              || (item.value === 'PERIODO_FECINI' && this.tabla !== 'nom_encrolindi a,nom_maeperiodo b ')
              || (item.value === 'PERIODO_FECFIN' && this.tabla !== 'nom_encrolindi a,nom_maeperiodo b ')
              //////////////////////////aumentos GSTRF
              || item.value === 'ENCPED_FECHAENTREGA'
              || item.value === 'ENCDEV_FECHAEMISION'
              || item.value === 'TRNTOMAFISICA_FECHA'
              || item.value === 'TRANSFER_FECHAEMISION'
              || item.value === 'TRANSFER_FECHATRANSFER'
              || item.value === 'TRANSFER_FECHAAUTORIZA'
              || item.value === 'TRNTARJETA_FECHATRN'
              || item.value === 'TRNBAN_FECHA' 
              || item.value === 'TRNCAJ_FECHA'
              || item.value === 'LOTE_FECHAVENCIMIENTO'
              || item.value === 'BANINV_FECHAVENCIMIENTO'
              || item.value === 'BANINV_FECHAEMISION'
              ) {
              params[index]=this.datePipe.transform(params[index], 'dd/MM/yyyy')
              condicion = 'TO_CHAR(' + item.value + ',\'DD/MM/YYYY\') LIKE UPPER(\'%' + params[index] + '%\')';
            }
            //CXC-cxc_trnresumen CDPJ
            let tablatemp=(this.tabla).toString().toUpperCase()
            if((this.tabla === 'CXC_TRNCOBRO' || tablatemp === 'CXC_TRNRESUMEN' || this.tabla === 'VEN_ENCPED' || this.tabla === 'VEN_ENCGRE') 
            && item.value === 'CLI_NOMBRE'){
              condicion='UPPER(VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,\'01\')) LIKE UPPER(\'%' + params[index] + '%\')'
            }// VEN_MAECLIENTE-VEN_NOMBRE
            if(this.tabla === 'VEN_MAECLIENTE' && item.value === 'VEN_NOMBRE'){
              condicion='UPPER (VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO)) LIKE UPPER(\'%' + params[index] + '%\')'
            }// VEN_MAECLIENTE-ZONA_NOMBRE
            if(this.tabla === 'VEN_MAECLIENTE' && item.value === 'ZONA_NOMBRE'){
              condicion='UPPER (VEN_FNC_OBTENER_ZONACLI(CLI_ZONA)) LIKE UPPER(\'%' + params[index] + '%\')'
            }// COM_ENCFACPRO_ENCNOTREC_ENCORDCOM-ZONA_NOMBRE
            if((tablatemp=== 'COM_ENCNOTREC' || tablatemp=== 'COM_ENCFACPRO A' || tablatemp=== 'COM_ENCORDCOM' || tablatemp=== 'COM_ENCFACPRO_IMP A') 
                && item.value === 'PRO_NOMBRE'){
              condicion='UPPER (COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO)) LIKE UPPER(\'%' + params[index] + '%\')'
            }//INV_MAEARTICULO-EXISTENCIAS
            if(this.tabla === 'INV_MAEARTICULO' && item.value === 'EXISTENCIA'){
              condicion='UPPER ( '+this.existencias+' ) LIKE UPPER(\'%' + params[index] + '%\')'
            } // VEN_ENCPED A,VEN_DETPED B
            if(this.tabla === 'VEN_ENCPED A,VEN_DETPED B  ' && item.value === 'ENCPED_NUMERO'){
              condicion='UPPER ( a.ENCPED_NUMERO ) LIKE UPPER(\'%' + params[index] + '%\')'
            }//cxc_trnresumen
            // if(tablatemp === 'CXC_TRNRESUMEN' && item.value === 'CLI_NOMBRE'){
            //   condicion='UPPER (VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\')) LIKE UPPER(\'%' + params[index] + '%\')'
            // }
            //cxp_trnresumen
            if(tablatemp === 'CXP_TRNRESUMEN A ' && item.value === 'PRO_NOMBRE'){
              condicion='UPPER (COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO)) LIKE UPPER(\'%' + params[index] + '%\')'
            }//nom_encrolindi a,nom_maeperiodo b 
            if(this.tabla === 'nom_encrolindi a,nom_maeperiodo b ' && item.value === 'EMP_ACTIVO'){
              params[index]=this.datePipe.transform(params[index], 'dd/MM/yyyy')
              condicion='UPPER (nom_fnc_obtienempactivo (emp_codigo)) LIKE UPPER(\'%' + params[index] + '%\')'
            }//nom_encrolindi a,nom_maeperiodo b 
            if(this.tabla === 'nom_encrolindi a,nom_maeperiodo b ' && item.value === 'EMP_FECINGRESO'){
              params[index]=this.datePipe.transform(params[index], 'dd/MM/yyyy')
              condicion = 'TO_CHAR(nom_fnc_obtienfecingempl(emp_codigo),\'DD/MM/YYYY\') LIKE UPPER(\'%' + params[index] + '%\')';
            }//nom_encrolindi a,nom_maeperiodo b 
            if(this.tabla === 'nom_encrolindi a,nom_maeperiodo b ' && item.value === 'PERIODO_FECINI'){
              params[index]=this.datePipe.transform(params[index], 'dd/MM/yyyy')
              condicion = 'TO_CHAR(a.periodo_fecini,\'DD/MM/YYYY\') LIKE UPPER(\'%' + params[index] + '%\')';
            }//nom_encrolindi a,nom_maeperiodo b 
            if(this.tabla === 'nom_encrolindi a,nom_maeperiodo b ' && item.value === 'PERIODO_FECFIN'){
              condicion = 'TO_CHAR(a.periodo_fecfin,\'DD/MM/YYYY\') LIKE UPPER(\'%' + params[index] + '%\')';
            }//INV_MAEARTICULO-PRECIO
            if(this.tabla === 'INV_MAEARTICULO' && item.value === 'PRECIO'){
              condicion='UPPER ( '+this.precioarticulo+' ) LIKE UPPER(\'%' + params[index] + '%\')'
            }//INV_MAEARTICULO-DESCUENTO
            if(this.tabla === 'INV_MAEARTICULO' && item.value === 'DESCUENTO'){
              condicion='UPPER ( '+this.descuentoarticulo+' ) LIKE UPPER(\'%' + params[index] + '%\')'
            }//INV_MAEARTICULO-GRUP_NOMBRE
            if(this.tabla === 'INV_MAEARTICULO' && item.value === 'GRUP_NOMBRE'){
              condicion='UPPER ( INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) ) LIKE UPPER(\'%' + params[index] + '%\')'
            }//CXP_TRNCOBRO O, CON_ENCASI_L C 
            if(this.tabla === 'CXP_TRNCOBRO O, CON_ENCASI_L C ' && item.value === 'PRO_NOMBRE'){
              condicion='UPPER ( COM_FNC_NOMBRE_PROVEEDOR(O.PRO_CODIGO)) LIKE UPPER(\'%' + params[index] + '%\')'
            }//CXP_TRNCOBRO
            if((this.tabla === 'CXP_TRNCOBRO' || this.tabla === 'COM_ENCDEV') && item.value === 'PRO_NOMBRE'){
              condicion='UPPER ( COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) ) LIKE UPPER(\'%' + params[index] + '%\')'
            }// VEN_ENCGRE
            if(this.tabla === 'VEN_ENCGRE'  && item.value === 'TRANSPORT_NOMBRE'){
              condicion='UPPER ( VEN_FNC_NOMBRE_TRANSPORT(TRANSPORT_CODIGO) ) LIKE UPPER(\'%' + params[index] + '%\')'
            }

            
            //CDPJ
            aux++;
          } else {
            if (item.value === 'ENCFAC_FECHAEMISION' || item.value === 'ENCPED_FECHAEMISION'
              || item.value === 'ENCGRE_FECHAEMISION' || item.value === 'ENCDVF_FECHAEMISION'
              || item.value === 'ENCFACPRO_FECHAEMISION' || item.value === 'TRNCOBRO_FECHATRN'
              || item.value === 'ASI_FECHA' || item.value === 'DETCASHMNG_FECHA'
              || item.value === 'KAR_FECHA' || item.value === 'ENCORDCOM_FECHAEMISION'
              || item.value === 'ENCNOTREC_FECHAEMISION' || item.value === 'ENCCUADRECAJA_FECHA'
              || item.value === 'ENCCUADRECAJA_DESDE' || item.value === 'ENCCUADRECAJA_HASTA'
              || item.value === 'ENCCUOTA_FECHAINICIO' || item.value === 'TRNART_FECHAES'
              || item.value === 'FECHA' || item.value === 'ENCORDPRO_FECHAEMISION'
              || item.value === 'ENCORDPRO_FECHAINICIO'
              || (item.value === 'PERIODO_FECINI' && this.tabla !== 'nom_encrolindi a,nom_maeperiodo b ')
              || (item.value === 'PERIODO_FECFIN' && this.tabla !== 'nom_encrolindi a,nom_maeperiodo b ')
              //////////////////////////aumentos GSTRF
              || item.value === 'ENCPED_FECHAENTREGA'
              || item.value === 'ENCDEV_FECHAEMISION'
              || item.value === 'TRNTOMAFISICA_FECHA'
              || item.value === 'TRANSFER_FECHAEMISION'
              || item.value === 'TRANSFER_FECHATRANSFER'
              || item.value === 'TRANSFER_FECHAAUTORIZA'
              || item.value === 'TRNTARJETA_FECHATRN'
              || item.value === 'TRNBAN_FECHA' 
              || item.value === 'TRNCAJ_FECHA'
              || item.value === 'LOTE_FECHAVENCIMIENTO'
              ) {
              params[index]=this.datePipe.transform(params[index], 'dd/MM/yyyy')
              condicion = condicion + ' AND TO_CHAR(' + item.value + ',\'DD/MM/YYYY\') LIKE UPPER(\'%' + params[index] + '%\')';
            } else {
            //CXC CDPJ
            let tablatemp=(this.tabla).toString().toUpperCase()
            if((this.tabla === 'CXC_TRNCOBRO' || tablatemp === 'CXC_TRNRESUMEN' || this.tabla === 'VEN_ENCPED' || this.tabla === 'VEN_ENCGRE') && item.value === 'CLI_NOMBRE'){
              condicion = condicion + ' AND UPPER(VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,\'01\')) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'VEN_MAECLIENTE' && item.value === 'VEN_NOMBRE'){
              condicion = condicion + ' AND UPPER(VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO)) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'VEN_MAECLIENTE' && item.value === 'ZONA_NOMBRE'){
              condicion = condicion + ' AND UPPER (VEN_FNC_OBTENER_ZONACLI(CLI_ZONA)) LIKE UPPER(\'%' + params[index] + '%\')'
            } else if((tablatemp=== 'COM_ENCNOTREC' || tablatemp=== 'COM_ENCFACPRO A' || tablatemp=== 'COM_ENCORDCOM' || tablatemp=== 'COM_ENCFACPRO_IMP A') 
                        && item.value === 'PRO_NOMBRE'){
              condicion = condicion + ' AND UPPER (COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO)) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'INV_MAEARTICULO' && item.value === 'EXISTENCIA'){
              condicion = condicion + ' AND UPPER ( '+this.existencias+' ) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'VEN_ENCPED A,VEN_DETPED B  ' && item.value === 'ENCPED_NUMERO'){
              condicion = condicion + ' AND UPPER ( a.ENCPED_NUMERO ) LIKE UPPER(\'%' + params[index] + '%\')'
            }
            // else if(tablatemp === 'CXC_TRNRESUMEN' && item.value === 'CLI_NOMBRE'){
            //   condicion = condicion + ' AND UPPER (VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\')) LIKE UPPER(\'%' + params[index] + '%\')'
            // }
            else if(tablatemp === 'CXP_TRNRESUMEN A ' && item.value === 'PRO_NOMBRE'){
              condicion = condicion + ' AND UPPER (COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO)) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'nom_encrolindi a,nom_maeperiodo b ' && item.value === 'EMP_ACTIVO'){
              condicion = condicion + ' AND UPPER (nom_fnc_obtienempactivo (emp_codigo)) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'nom_encrolindi a,nom_maeperiodo b ' && item.value === 'EMP_FECINGRESO'){
              params[index]=this.datePipe.transform(params[index], 'dd/MM/yyyy')
              condicion = condicion + ' AND TO_CHAR(nom_fnc_obtienfecingempl(emp_codigo),\'DD/MM/YYYY\') LIKE UPPER(\'%' + params[index] + '%\')';
            }else if(this.tabla === 'nom_encrolindi a,nom_maeperiodo b ' && item.value === 'PERIODO_FECINI'){
              params[index]=this.datePipe.transform(params[index], 'dd/MM/yyyy')
              condicion = condicion + ' AND TO_CHAR(a.periodo_fecini,\'DD/MM/YYYY\') LIKE UPPER(\'%' + params[index] + '%\')';
            }else if(this.tabla === 'nom_encrolindi a,nom_maeperiodo b ' && item.value === 'PERIODO_FECFIN'){
              params[index]=this.datePipe.transform(params[index], 'dd/MM/yyyy')
              condicion = condicion + ' AND TO_CHAR(a.periodo_fecfin,\'DD/MM/YYYY\') LIKE UPPER(\'%' + params[index] + '%\')';
            }else if(this.tabla === 'INV_MAEARTICULO' && item.value === 'PRECIO'){
              condicion = condicion + ' AND UPPER ( '+this.precioarticulo+' ) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'INV_MAEARTICULO' && item.value === 'DESCUENTO'){
              condicion = condicion + ' AND UPPER ( '+this.descuentoarticulo+' ) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'INV_MAEARTICULO' && item.value === 'GRUP_NOMBRE'){
              condicion = condicion + ' AND UPPER ( INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) ) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'CXP_TRNCOBRO O, CON_ENCASI_L C ' && item.value === 'PRO_NOMBRE'){
              condicion = condicion + ' AND UPPER ( COM_FNC_NOMBRE_PROVEEDOR(O.PRO_CODIGO)) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if((this.tabla === 'CXP_TRNCOBRO' || this.tabla === 'COM_ENCDEV') && item.value === 'PRO_NOMBRE'){
              condicion = condicion + ' AND UPPER ( COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO) ) LIKE UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'VEN_ENCGRE'  && item.value === 'TRANSPORT_NOMBRE'){
              condicion = condicion + ' AND UPPER ( VEN_FNC_NOMBRE_TRANSPORT(TRANSPORT_CODIGO) ) LIKE UPPER(\'%' + params[index] + '%\')'
            }else{
              condicion = condicion + ' AND UPPER(' + item.value + ') LIKE UPPER(\'%' + params[index] + '%\')';
            }
            //CDPJ
            }  
            aux++;
          }
        }
        // if (aux === 0) {
        //   condicion = 'ROWNUM<100';
        // }
      });
    }

    if (tipo === 'equals') {
      aux = 0;
      this.arrayOptions.forEach((item, index) => {
        if (params[index] !== '') {
          if (aux === 0) {
            condicion = 'UPPER(' + item.value + ') = UPPER(\'' + params[index] + '\')';
            if (item.value === 'ENCFAC_FECHAEMISION' || item.value === 'ENCPED_FECHAEMISION'
              || item.value === 'ENCGRE_FECHAEMISION' || item.value === 'ENCDVF_FECHAEMISION'
              || item.value === 'ENCFACPRO_FECHAEMISION' || item.value === 'TRNCOBRO_FECHATRN'
              || item.value === 'ASI_FECHA' || item.value === 'DETCASHMNG_FECHA'
              || item.value === 'KAR_FECHA' || item.value === 'ENCORDCOM_FECHAEMISION'
              || item.value === 'ENCNOTREC_FECHAEMISION' || item.value === 'ENCCUADRECAJA_FECHA'
              || item.value === 'ENCCUADRECAJA_DESDE' || item.value === 'ENCCUADRECAJA_HASTA'
              || item.value === 'ENCCUOTA_FECHAINICIO' || item.value === 'TRNART_FECHAES'
              || item.value === 'FECHA' || item.value === 'ENCORDPRO_FECHAEMISION'
              || item.value === 'ENCORDPRO_FECHAINICIO' 
              || (item.value === 'PERIODO_FECINI' && this.tabla !== 'nom_encrolindi a,nom_maeperiodo b ')
              || (item.value === 'PERIODO_FECFIN' && this.tabla !== 'nom_encrolindi a,nom_maeperiodo b ')
              //////////////////////////aumentos GSTRF
              || item.value === 'ENCPED_FECHAENTREGA'
              || item.value === 'ENCDEV_FECHAEMISION'
              || item.value === 'TRNTOMAFISICA_FECHA'
              || item.value === 'TRANSFER_FECHAEMISION'
              || item.value === 'TRANSFER_FECHATRANSFER'
              || item.value === 'TRANSFER_FECHAAUTORIZA'
              || item.value === 'TRNTARJETA_FECHATRN'
              || item.value === 'TRNBAN_FECHA' 
              || item.value === 'TRNCAJ_FECHA'
              || item.value === 'LOTE_FECHAVENCIMIENTO'
              ) {
              params[index]=this.datePipe.transform(params[index], 'dd/MM/yyyy')
              condicion = 'TO_CHAR(' + item.value + ',\'DD/MM/YYYY\') = UPPER(\'' + params[index] + '\')';
            }
            //CXC CDPJ
            let tablatemp=(this.tabla).toString().toUpperCase()
            if(this.tabla === 'CXC_TRNCOBRO' && item.value === 'CLI_NOMBRE'){
              condicion='UPPER(VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,\'01\')) = UPPER(\'' + params[index] + '\')'
            }// VEN_MAECLIENTE-VEN_NOMBRE
            if(this.tabla === 'VEN_MAECLIENTE' && item.value === 'VEN_NOMBRE'){
              condicion='UPPER (VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO)) = UPPER(\'' + params[index] + '\')'
            }// VEN_MAECLIENTE-ZONA_NOMBRE
            if(this.tabla === 'VEN_MAECLIENTE' && item.value === 'ZONA_NOMBRE'){
              condicion='UPPER (VEN_FNC_OBTENER_ZONACLI(CLI_ZONA)) = UPPER(\'' + params[index] + '\')'
            }// COM_ENCFACPRO_ENCNOTREC_ENCORDCOM-ZONA_NOMBRE
            if((tablatemp=== 'COM_ENCNOTREC' || tablatemp=== 'COM_ENCFACPRO A' || tablatemp=== 'COM_ENCORDCOM') 
                && item.value === 'PRO_NOMBRE'){
              condicion='UPPER (COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO)) = UPPER(\'%' + params[index] + '%\')'
            }//INV_MAEARTICULO
            if(this.tabla === 'INV_MAEARTICULO' && item.value === 'EXISTENCIA'){
              condicion='UPPER ( '+this.existencias+' ) = UPPER(\'%' + params[index] + '%\')'
            } // VEN_ENCPED A,VEN_DETPED B
            if(this.tabla === 'VEN_ENCPED A,VEN_DETPED B  ' && item.value === 'ENCPED_NUMERO'){
              condicion='UPPER ( a.ENCPED_NUMERO ) = UPPER(\'%' + params[index] + '%\')'
            }
            //CDPJ
            aux++;
          } else {
            if (item.value === 'ENCFAC_FECHAEMISION' || item.value === 'ENCPED_FECHAEMISION'
              || item.value === 'ENCGRE_FECHAEMISION' || item.value === 'ENCDVF_FECHAEMISION'
              || item.value === 'ENCFACPRO_FECHAEMISION' || item.value === 'TRNCOBRO_FECHATRN'
              || item.value === 'ASI_FECHA' || item.value === 'DETCASHMNG_FECHA'
              || item.value === 'KAR_FECHA' || item.value === 'ENCORDCOM_FECHAEMISION'
              || item.value === 'ENCNOTREC_FECHAEMISION' || item.value === 'ENCCUADRECAJA_FECHA'
              || item.value === 'ENCCUADRECAJA_DESDE' || item.value === 'ENCCUADRECAJA_HASTA'
              || item.value === 'ENCCUOTA_FECHAINICIO' || item.value === 'TRNART_FECHAES'
              || item.value === 'FECHA' || item.value === 'ENCORDPRO_FECHAEMISION'
              || item.value === 'ENCORDPRO_FECHAINICIO' 
              || (item.value === 'PERIODO_FECINI' && this.tabla !== 'nom_encrolindi a,nom_maeperiodo b ')
              || (item.value === 'PERIODO_FECFIN' && this.tabla !== 'nom_encrolindi a,nom_maeperiodo b ')
              //////////////////////////aumentos GSTRF
              || item.value === 'ENCPED_FECHAENTREGA'
              || item.value === 'ENCDEV_FECHAEMISION'
              || item.value === 'TRNTOMAFISICA_FECHA'
              || item.value === 'TRANSFER_FECHAEMISION'
              || item.value === 'TRANSFER_FECHATRANSFER'
              || item.value === 'TRANSFER_FECHAAUTORIZA'
              || item.value === 'TRNTARJETA_FECHATRN'
              || item.value === 'TRNBAN_FECHA' 
              || item.value === 'TRNCAJ_FECHA'
              || item.value === 'LOTE_FECHAVENCIMIENTO'
              ) {
              params[index]=this.datePipe.transform(params[index], 'dd/MM/yyyy')
              condicion = condicion + ' AND TO_CHAR(' + item.value + ',\'DD/MM/YYYY\') = UPPER(\'' + params[index] + '\')';
            } else {
               //CXC CDPJ
               let tablatemp=(this.tabla).toString().toUpperCase()
            if(this.tabla === 'CXC_TRNCOBRO' && item.value === 'CLI_NOMBRE'){
              condicion = condicion + ' AND UPPER(VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,\'01\')) = UPPER(\'' + params[index] + '\')'
            }else if(this.tabla === 'VEN_MAECLIENTE' && item.value === 'VEN_NOMBRE'){
              condicion = condicion + ' AND UPPER(VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO)) = UPPER(\'' + params[index] + '\')'
            }else if(this.tabla === 'VEN_MAECLIENTE' && item.value === 'ZONA_NOMBRE'){
              condicion = condicion + ' AND UPPER (VEN_FNC_OBTENER_ZONACLI(CLI_ZONA)) = UPPER(\'' + params[index] + '\')'
            }else if((tablatemp=== 'COM_ENCNOTREC' || tablatemp=== 'COM_ENCFACPRO A' || tablatemp=== 'COM_ENCORDCOM') 
            && item.value === 'PRO_NOMBRE'){
              condicion = condicion + ' AND UPPER (COM_FNC_NOMBRE_PROVEEDOR(PRO_CODIGO)) = UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'INV_MAEARTICULO' && item.value === 'EXISTENCIA'){
              condicion = condicion + ' AND UPPER ( '+this.existencias+' ) = UPPER(\'%' + params[index] + '%\')'
            }else if(this.tabla === 'VEN_ENCPED A,VEN_DETPED B  ' && item.value === 'ENCPED_NUMERO'){
              condicion = condicion + ' AND UPPER ( a.ENCPED_NUMERO ) = UPPER(\'%' + params[index] + '%\')'
            }else{
              condicion = condicion + ' AND UPPER(' + item.value + ') = UPPER(\'' + params[index] + '\')';
            }
            //CDPJ
              
            }
            aux++;
          }
        }
        // if (aux === 0) {
        //   condicion = 'ROWNUM<100';
        // }
      });
    }
    //CDPJ
    if(this.guiaservicio.tipofiltrobus === 'gr'){
      filtrobusgr='  NVL(SUBSTR(ENCGRE_NUMERO,0,2),\'Z\')<>\'NE\''
    }else if(this.guiaservicio.tipofiltrobus === 'ne'){
      filtrobusgr='  NVL(SUBSTR(ENCGRE_NUMERO,0,2),\'Z\')=\'NE\''
    }else{
      filtrobusgr='';
    }
    if(this.DVBUSGR === 1){
      if (this.where !== undefined) {
        if (this.where.length > 0) {
          if(condicion.length>0){
           if(filtrobusgr !== ''){
            condicion = condicion+ ' AND '  + filtrobusgr+ ' AND ' + this.where;
           }else{
            condicion = condicion + ' AND ' + this.where;
           }
            
          }else{
            if(filtrobusgr !== ''){
              condicion =filtrobusgr+' AND '+ this.where;
            } else{
              condicion = this.where;
            }
            
          }
        }
      }
    }else{
      if (this.where !== undefined) {
        if (this.where.length > 0) {
          if(condicion.length>0){
            condicion = condicion + ' AND ' + this.where;
          }else{
            condicion = this.where;
          }
        }
      }
    }
    //CDPJ
    this.spin = true;

    if (this.consulta === '') {
      this.busqService.busquedaTabla('*', this.tabla, condicion).subscribe((datos: any[]) => {
        this.arregloConsulta = datos;
        if (this.arregloConsulta !== null) {
          this.arregloConsulta.map((registro) => {
            if (registro.ENCFAC_FECHAEMISION !== null) {
              registro.ENCFAC_FECHAEMISION = this.datePipe.transform(registro.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCPED_FECHAEMISION !== null) {
              registro.ENCPED_FECHAEMISION = this.datePipe.transform(registro.ENCPED_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCGRE_FECHAEMISION !== null) {
              registro.ENCGRE_FECHAEMISION = this.datePipe.transform(registro.ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCDVF_FECHAEMISION !== null) {
              registro.ENCDVF_FECHAEMISION = this.datePipe.transform(registro.ENCDVF_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCFACPRO_FECHAEMISION !== null) {
              registro.ENCFACPRO_FECHAEMISION = this.datePipe.transform(registro.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.TRNCOBRO_FECHATRN !== null) {
              registro.TRNCOBRO_FECHATRN = this.datePipe.transform(registro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
            }
            if (registro.ASI_FECHA !== null) {
              registro.ASI_FECHA = this.datePipe.transform(registro.ASI_FECHA, 'dd/MM/yyyy');
            }
            if (registro.DETCASHMNG_FECHA !== null) {
              registro.DETCASHMNG_FECHA = this.datePipe.transform(registro.DETCASHMNG_FECHA, 'dd/MM/yyyy');
            }
            if (registro.KAR_FECHA !== null) {
              registro.KAR_FECHA = this.datePipe.transform(registro.KAR_FECHA, 'dd/MM/yyyy');
            }
            if (registro.ENCORDCOM_FECHAEMISION !== null) {
              registro.ENCORDCOM_FECHAEMISION = this.datePipe.transform(registro.ENCORDCOM_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCNOTREC_FECHAEMISION !== null) {
              registro.ENCNOTREC_FECHAEMISION = this.datePipe.transform(registro.ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCCUADRECAJA_FECHA !== null) {
              registro.ENCCUADRECAJA_FECHA = this.datePipe.transform(registro.ENCCUADRECAJA_FECHA, 'dd/MM/yyyy');
            }
            if (registro.ENCCUADRECAJA_DESDE !== null) {
              registro.ENCCUADRECAJA_DESDE = this.datePipe.transform(registro.ENCCUADRECAJA_DESDE, 'dd/MM/yyyy');
            }
            if (registro.ENCCUADRECAJA_HASTA !== null) {
              registro.ENCCUADRECAJA_HASTA = this.datePipe.transform(registro.ENCCUADRECAJA_HASTA, 'dd/MM/yyyy');
            }
            if (registro.ENCCUOTA_FECHAINICIO !== null) {
              registro.ENCCUOTA_FECHAINICIO = this.datePipe.transform(registro.ENCCUOTA_FECHAINICIO, 'dd/MM/yyyy');
            }
            if (registro.TRNART_FECHAES !== null) {
              registro.TRNART_FECHAES = this.datePipe.transform(registro.TRNART_FECHAES, 'dd/MM/yyyy');
            }
            if (registro.ENCORDPRO_FECHAEMISION !== null) {
              registro.ENCORDPRO_FECHAEMISION = this.datePipe.transform(registro.ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCORDPRO_FECHAINICIO !== null) {
              registro.ENCORDPRO_FECHAINICIO = this.datePipe.transform(registro.ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
            }
            if (registro.PERIODO_FECINI !== null) {
              registro.PERIODO_FECINI = this.datePipe.transform(registro.PERIODO_FECINI, 'dd/MM/yyyy');
            }
            if (registro.PERIODO_FECFIN !== null) {
              registro.PERIODO_FECFIN = this.datePipe.transform(registro.PERIODO_FECFIN, 'dd/MM/yyyy');
            }
            if (registro.FECHA !== null) {
              registro.FECHA = this.datePipe.transform(registro.FECHA, 'dd/MM/yyyy');
            }
            ///////////////// aumentos GSRF
            if (registro.ENCPED_FECHAENTREGA !== null) {
              registro.ENCPED_FECHAENTREGA = this.datePipe.transform(registro.ENCPED_FECHAENTREGA, 'dd/MM/yyyy');
            }
            if (registro.ENCDEV_FECHAEMISION !== null) {
              registro.ENCDEV_FECHAEMISION = this.datePipe.transform(registro.ENCDEV_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.TRNTOMAFISICA_FECHA !== null) {
              registro.TRNTOMAFISICA_FECHA = this.datePipe.transform(registro.TRNTOMAFISICA_FECHA, 'dd/MM/yyyy');
            }
            if (registro.TRANSFER_FECHAEMISION !== null) {
              registro.TRANSFER_FECHAEMISION = this.datePipe.transform(registro.TRANSFER_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.TRANSFER_FECHATRANSFER !== null) {
              registro.TRANSFER_FECHATRANSFER = this.datePipe.transform(registro.TRANSFER_FECHATRANSFER, 'dd/MM/yyyy');
            }
            if (registro.TRANSFER_FECHAAUTORIZA !== null) {
              registro.TRANSFER_FECHAAUTORIZA = this.datePipe.transform(registro.TRANSFER_FECHAAUTORIZA, 'dd/MM/yyyy');
            }
            if (registro.TRNTARJETA_FECHATRN !== null) {
              registro.TRNTARJETA_FECHATRN = this.datePipe.transform(registro.TRNTARJETA_FECHATRN, 'dd/MM/yyyy');
            }
            if (registro.TRNBAN_FECHA !== null) {
              registro.TRNBAN_FECHA = this.datePipe.transform(registro.TRNBAN_FECHA, 'dd/MM/yyyy');
            }
            if (registro.TRNCAJ_FECHA !== null) {
              registro.TRNCAJ_FECHA = this.datePipe.transform(registro.TRNCAJ_FECHA, 'dd/MM/yyyy');
            }
            if (registro.EMP_FECINGRESO !== null) {
              registro.EMP_FECINGRESO = this.datePipe.transform(registro.EMP_FECINGRESO, 'dd/MM/yyyy');
            }
            if (registro.LOTE_FECHAVENCIMIENTO !== null) {
              registro.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(registro.LOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
            }
            
            ////////////////////////////////////////////
          });
        }
        this.spin = false;
      });
    } else if (this.consulta !== '' && this.busquedaCompuesta === false) {
      this.busqService.busquedaTabla(this.consulta, this.tabla, condicion).subscribe((datos: any[]) => {
        this.arregloConsulta = datos;
        if (this.arregloConsulta !== null) {
          this.arregloConsulta.map((registro) => {
            if (registro.ENCFAC_FECHAEMISION !== null) {
              registro.ENCFAC_FECHAEMISION = this.datePipe.transform(registro.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCPED_FECHAEMISION !== null) {
              registro.ENCPED_FECHAEMISION = this.datePipe.transform(registro.ENCPED_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCGRE_FECHAEMISION !== null) {
              registro.ENCGRE_FECHAEMISION = this.datePipe.transform(registro.ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCDVF_FECHAEMISION !== null) {
              registro.ENCDVF_FECHAEMISION = this.datePipe.transform(registro.ENCDVF_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCFACPRO_FECHAEMISION !== null) {
              registro.ENCFACPRO_FECHAEMISION = this.datePipe.transform(registro.ENCFACPRO_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.TRNCOBRO_FECHATRN !== null) {
              registro.TRNCOBRO_FECHATRN = this.datePipe.transform(registro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
            }
            if (registro.ASI_FECHA !== null) {
              registro.ASI_FECHA = this.datePipe.transform(registro.ASI_FECHA, 'dd/MM/yyyy');
            }
            if (registro.DETCASHMNG_FECHA !== null) {
              registro.DETCASHMNG_FECHA = this.datePipe.transform(registro.DETCASHMNG_FECHA, 'dd/MM/yyyy');
            }
            if (registro.KAR_FECHA !== null) {
              registro.KAR_FECHA = this.datePipe.transform(registro.KAR_FECHA, 'dd/MM/yyyy');
            }
            if (registro.ENCORDCOM_FECHAEMISION !== null) {
              registro.ENCORDCOM_FECHAEMISION = this.datePipe.transform(registro.ENCORDCOM_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCNOTREC_FECHAEMISION !== null) {
              registro.ENCNOTREC_FECHAEMISION = this.datePipe.transform(registro.ENCNOTREC_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCCUADRECAJA_FECHA !== null) {
              registro.ENCCUADRECAJA_FECHA = this.datePipe.transform(registro.ENCCUADRECAJA_FECHA, 'dd/MM/yyyy');
            }
            if (registro.ENCCUADRECAJA_DESDE !== null) {
              registro.ENCCUADRECAJA_DESDE = this.datePipe.transform(registro.ENCCUADRECAJA_DESDE, 'dd/MM/yyyy');
            }
            if (registro.ENCCUADRECAJA_HASTA !== null) {
              registro.ENCCUADRECAJA_HASTA = this.datePipe.transform(registro.ENCCUADRECAJA_HASTA, 'dd/MM/yyyy');
            }
            if (registro.ENCCUOTA_FECHAINICIO !== null) {
              registro.ENCCUOTA_FECHAINICIO = this.datePipe.transform(registro.ENCCUOTA_FECHAINICIO, 'dd/MM/yyyy');
            }
            if (registro.TRNART_FECHAES !== null) {
              registro.TRNART_FECHAES = this.datePipe.transform(registro.TRNART_FECHAES, 'dd/MM/yyyy');
            }            
            if (registro.ENCORDPRO_FECHAEMISION !== null) {
              registro.ENCORDPRO_FECHAEMISION = this.datePipe.transform(registro.ENCORDPRO_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.ENCORDPRO_FECHAINICIO !== null) {
              registro.ENCORDPRO_FECHAINICIO = this.datePipe.transform(registro.ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
            }
            if (registro.FECHA !== null) {
              registro.FECHA = this.datePipe.transform(registro.FECHA, 'dd/MM/yyyy');
            }
            if (registro.TRNCOBRO_IMPORTE!== null) {
              registro.TRNCOBRO_IMPORTE = Number(registro.TRNCOBRO_IMPORTE).toFixed(2);
            }
            if (registro.ENCFACPRO_TOTALNETO !== null) {
              registro.ENCFACPRO_TOTALNETO = Number(registro.ENCFACPRO_TOTALNETO).toFixed(2);
            }
            if (registro.ENCFACPRO_BASEIVA !== null) {
              registro.ENCFACPRO_BASEIVA = Number(registro.ENCFACPRO_BASEIVA).toFixed(2);
            }
            if (registro.ENCFACPRO_TOTAL !== null) {
              registro.ENCFACPRO_TOTAL = Number(registro.ENCFACPRO_TOTAL).toFixed(2);
            }
            if (registro.PERIODO_FECINI !== null) {
              registro.PERIODO_FECINI = this.datePipe.transform(registro.PERIODO_FECINI, 'dd/MM/yyyy');
            }
            if (registro.PERIODO_FECFIN !== null) {
              registro.PERIODO_FECFIN = this.datePipe.transform(registro.PERIODO_FECFIN, 'dd/MM/yyyy');
            }
            ///////////////// aumentos GSRF
            if (registro.ENCPED_FECHAENTREGA !== null) {
              registro.ENCPED_FECHAENTREGA = this.datePipe.transform(registro.ENCPED_FECHAENTREGA, 'dd/MM/yyyy');
            }
            if (registro.ENCDEV_FECHAEMISION !== null) {
              registro.ENCDEV_FECHAEMISION = this.datePipe.transform(registro.ENCDEV_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.TRNTOMAFISICA_FECHA !== null) {
              registro.TRNTOMAFISICA_FECHA = this.datePipe.transform(registro.TRNTOMAFISICA_FECHA, 'dd/MM/yyyy');
            }
            if (registro.TRANSFER_FECHAEMISION !== null) {
              registro.TRANSFER_FECHAEMISION = this.datePipe.transform(registro.TRANSFER_FECHAEMISION, 'dd/MM/yyyy');
            }
            if (registro.TRANSFER_FECHATRANSFER !== null) {
              registro.TRANSFER_FECHATRANSFER = this.datePipe.transform(registro.TRANSFER_FECHATRANSFER, 'dd/MM/yyyy');
            }
            if (registro.TRANSFER_FECHAAUTORIZA !== null) {
              registro.TRANSFER_FECHAAUTORIZA = this.datePipe.transform(registro.TRANSFER_FECHAAUTORIZA, 'dd/MM/yyyy');
            }
            if (registro.TRNTARJETA_FECHATRN !== null) {
              registro.TRNTARJETA_FECHATRN = this.datePipe.transform(registro.TRNTARJETA_FECHATRN, 'dd/MM/yyyy');
            }
            if (registro.TRNBAN_FECHA !== null) {
              registro.TRNBAN_FECHA = this.datePipe.transform(registro.TRNBAN_FECHA, 'dd/MM/yyyy');
            }
            if (registro.TRNCAJ_FECHA !== null) {
              registro.TRNCAJ_FECHA = this.datePipe.transform(registro.TRNCAJ_FECHA, 'dd/MM/yyyy');
            }
            if (registro.EMP_FECINGRESO !== null) {
              registro.EMP_FECINGRESO = this.datePipe.transform(registro.EMP_FECINGRESO, 'dd/MM/yyyy');
            }
            if (registro.LOTE_FECHAVENCIMIENTO !== null) {
              registro.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(registro.LOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
            }
            ////////////////////////////////////////////
          });
        }
        this.spin = false;
      });
    }
    if (this.busquedaCompuesta === true) {
      this.busqService.buscarFactura(this.consulta, this.tabla, condicion).subscribe((dato) => {
        this.arregloConsulta = dato;
        this.spin = false;
      });
    }
  }

  recargar() {
    for (let i = 0; i < 30; i++) {
      this.busquedaParams[i] = '';
    }
    this.tipoBusqueda = 'contains';
    this.guiaservicio.tipofiltrobus='';//CDPJ
    this.busquedaIndi(this.busquedaParams, this.tipoBusqueda);
  }


  selectedOption(opcion) {
    for (let i = 0; i < 30; i++) {
      this.busquedaParams[i] = '';
    }
    this.opcionSeleccionada.emit(opcion);
  }

  prueba() {
    document.getElementById('tr').focus();
  }

  async abrirpestanamaestro() {

    switch (this.tabla) {
      case 'INV_MAEGRUPO':
        this.maestro = 'frmINV_MAEGRUPO';
        break;
      case 'VEN_MAEIMPUESTO':
        this.maestro = 'frmVEN_MAEIMPUESTO';
        break;
      case 'ANEX_MAECODSRI':
        this.maestro = 'frmANEX_MAECODSRI';
        break;
      case 'INV_MAEUNIDAD':
        this.maestro = 'frmINV_MAEUNIDAD';
        break;
      case 'CON_MAECON':
        this.maestro = 'frmCON_MAECON';
        break;
      case 'SACI_MAEREFERENCIA':
        this.maestro = 'frmSACI_MAEREFERENCIA';
        break;
      case 'VEN_MAECLIENTE':
        this.maestro = 'frmVEN_MAECLIENTE';
        break;
      case 'VEN_MAEVENDEDOR':
        this.maestro = 'frmVEN_MAEVENDEDOR';
        break;
      case 'INV_MAEARTICULO':
        this.maestro = 'frmINV_MAEARTICULO';
        break;
      case 'VEN_MAESERVICIO':
        this.maestro = 'frmVEN_MAESERVICIO';
        break;
      default:
        this.maestro = undefined;
        break;
    }

    if (this.maestro === undefined || this.maestro === null || this.maestro === '') {
      return;
    }

    let strlabel;
    let strmodprefijo;
    let strmenu;

    const sql = 'SELECT OPCOBJETO, OPCNOMBRE, MODPREFIJO, ' +
      '(SELECT MENNOMBRE FROM SEG_MAEMENU B WHERE B.MENCODIGO=A.MENCODIGO) AS MENNOMBRE ' +
      'FROM SEG_MAEOPCION A WHERE OPCOBJETO = \'' + this.maestro + '\'';

    const data = await this.busqService.ejecutarConsultaPromise(this.maestro);

    for (const rs of data) {
      strlabel = rs.OPCNOMBRE;
      strmodprefijo = rs.MODPREFIJO;
      strmenu = rs.MENNOMBRE;
    }

    let posicion;
    posicion = this.init.obtenerComponenteNum();
    // this.app.openNewTab('frmCON_MAECON', 'Cuentas', 'CON', 'Mantenimiento de archivo');
    this.init.tabs.push({
      label: strlabel,
      component: this.maestro,
      modprefijo: strmodprefijo,
      indice: this.init.indice,
      labelmenu: strmenu
    });
    this.calc.editFocus(0, posicion);
    this.init.tabindex = this.init.tabs.length;
  }
}
