import { InvmaearticuloService } from '../../inv/invservicios/invmaearticulo.service';
//import { VenDetped } from '../veninterfaces/vendetped';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
// Ag-grid
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { DatePipe } from '@angular/common';
import { VenenguiaRemisionService } from '../venservicios/venenguia-remision.service';
import { VenencGuiaRemision } from '../veninterfaces/venencGuiaRemision';
import { VendetGuiaRemision } from '../veninterfaces/vendetGuiaRemision';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { Usuario } from '../../usuario';
import { VenenceReferencia } from '../veninterfaces/venenceReferencia';
import { Cxctmptrnresumen } from '../veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../veninterfaces/cxctrnresumen';
import { VenmaeclienteService } from '../venservicios/venmaecliente.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { VenDetFacturas, VenTopProductos } from '../veninterfaces/venestadisticas';
import { VenestadisticaService } from '../venservicios/venestadistica.service';
import { SaciMaenumeracionG } from '../veninterfaces/sacimaenumeraciong';
import { SegMaeCompania } from '../veninterfaces/segmaecompania';
import { finalize } from 'rxjs/operators';
import { Venencgredbasesiva } from "../veninterfaces/venencgrebasesiva";

@Component({
  selector: 'app-venencguia-remision',
  templateUrl: './venencguia-remision.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class VenencguiaRemisionComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;


  nombreComponente = 'venGuiaRem';

  displayDialogDetFac: boolean;
  displayDialogPedidos: boolean;
  displayDialogAsiento: boolean;
  displayDialogSeriales: boolean;
  displayDialogLotes: boolean;
  displayAcciones: boolean;
  displayLog: boolean;
  spin = false;
  barraBotones2: boolean;
  mayoruno: boolean;
  btnNuevo: boolean;
  consulta: string;
  where:string;
  btnGuardar: boolean;
  btnRegresar: boolean;
  btnBorrar: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  asiento: string;
  auxiliar: number;
  botonAnterior: boolean;
  botonPrimero: boolean;
  botonUltimo: boolean;
  numeroActual: number;
  claveAuth: string;
  permisoGre: SegMaePermiso;
  logResultado: string;
  decimalesCantidadGre: number;
  decimalesPrecioGre: number;
  decimalesTotalGre: number;
  decimalesDescGre: number;
  botonSiguiente: boolean;
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  arregloCons: any[];
  displayDialogBusquedaFast: boolean;
  displayDialogBus: boolean;
  claveDialog: boolean;
  accionClave: string;
  codigoGuiaAux: string;
  opcion: string;
  imagenProducto: string;
  busquedacampos: any[];
  arregloBus: any[];
  types: SelectItem[];
  largo: string;
  largoRef: string;
  indice: number;
  i: number;
  tabla: string;
  largoestcta: string;
  cotizacionBoolean: boolean;
  fechaEmision: any;
  fechaInicio: any;
  fechaFin: any;
  //horaSistema: any;
  horaInicio: any;
  horaFin: any;
  porcIvaBoolean: boolean;
  //opcionIva: SelectItem[];
  opcionIvaDesc: SelectItem[];
  choosedColor: string;
  choosedColorR: string;
  choosedColorG: string;
  choosedColorSB: string;
  choosedColorSnSk: string//CDPJ
  ventasboolean: boolean;
  comprasboolean: boolean;
  consigboolean: boolean;
  trasladoboolean: boolean;
  devboolean: boolean;
  impboolean: boolean;
  expboolean: boolean;
  otrosboolean: boolean;
  transboolean: boolean;
  activeIndex: number;
  nombrebodega: string;
  opcionesprecio: SelectItem[];
  listaprecios: any[];
  fechaBoolean: boolean;
  btnOpenFact = false;
  auxiliarlot: number = 0;
  ///////vehiculos
  //detallePedidoSeleccionado: VenDetped;

  indicador: any;

  public frameworkComponents;
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  encabezadoGuiaSeleccionadaAux: VenencGuiaRemision;
  encGuiaRemision: VenencGuiaRemision[];
  detalleGuiaSeleccionado: VendetGuiaRemision;
  detalleReferenciaSeleccionada: VenenceReferencia;
  detallePedidoSeleccionadoAux: VendetGuiaRemision;
  detalleReferenciaSeleccionadaAux: VenenceReferencia;
  comboOpciones: any[];
  rowStyle;
  rowSelection = 'multiple';
  tipodetalle = '';
  defaultGuiaDetalle;
  defaultGuiaReferencia;
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  //CDPJ
  CCMANG: boolean;
  NFNEGR: boolean;
  CNAFEPV:number
  BCPPG:number;
  boolfacturaElec//CDPJ
  displayDialogEst:boolean//CDPJ
  estfirma:string//CDPJ
  GUINOKARD:number
  iva:number//CDPJ
  displaybasesiva:boolean=false;
  basesiva:Venencgredbasesiva[]=[]
  defaultColBasesIva;
  opcionIva: any[];
  selectcombtipoIva: any;
  TIPDET:string;
  DVBUSGR:Number;
  //CDPJ
  // Asiento Contable
  displayAsiContable: boolean;

  NumAsiRegCont: string;

  // Estadistica

  dataVentasAcum: any;
  detTopProductos: VenTopProductos[] = [];
  detFacturas: VenDetFacturas[] = [];
  defaultColTopProductos;
  defaultColDetFacturas;
  rowStyleTopProd;
  rowStyleDetFacts;
  disabledEstadisticas = true;
  totalDocXCobrar: any = '';
  totalDocXCobrarPorc: any = '';
  totalDocVencidos: any = '';
  totalDocVencidosPorc: any = '';
  totalChPosfechados: any = '';
  totalChPosfechadosPorc: any = '';
  totalDocumentos: any = '';
  totalPedPendientes: any = '';
  totalPedSatisfechos: any = '';
  fechaPrimeraFact: any = '';
  totalChProtestados: any = '';
  options = {
    legend: {
      display: false
    }
  };
  index = 0;
  bolestcta: boolean;
  bolsaldo: boolean;

  // Existencia por bodega

  displayDialogExistBod = false;
  txtArtCodigo = '';
  txtArtDescripcion = '';
  txtListaPrecio = '';
  txtObservacionRes = '';
  txtIva;
  txtBodegaRes = '';

  // Numeracion

  tipoNumeracion = '';
  tamNumPed: number;
  strNumDoc: string;
  tipoDoc: any = null;
  strClase = '';
  strSerie = '';
  strNemonico = '';

  // Pedidos con componentes
  intACTPEDVAL = 0;
  displayDialogDocumentos = false;
  blocknum: boolean;///GSRF

  ///// nuevo VEhiculo
  ART_RAMV = '';
  ART_MODELOSRI = '';
  ART_MODELOAT = '';
  ART_MARCAV = '';
  ART_CLASE = '';
  ART_TIPOV = '';
  ART_CILINDRAJE = '';
  ART_ANIO = '';
  ART_TONELAJE = '';
  ART_COLOR = '';
  ART_PORIGEN = '';
  ART_MOTOR = '';
  ART_SERIEV = '';
  ART_NRUEDAS = '';
  ART_NEJES = '';
  ART_CAPACIDAD = '';
  ART_COMBUSTIBLE = '';
  ART_BANCO = '';
  ART_NBANCO = '';
  ART_DPAGO = '';
  ART_FFABRICA = '';
  ART_DISPOSITIVO = '';
  ART_MARVEH = '';
  ART_CLAVEH = '';
  ART_TIPVEH = '';
  ART_COMVEH = '';
  ART_DISVEH = '';
  ART_P = '';
  displayvehiculo = false;
  displayDialogDocumentosim = false;

  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];

  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha Vence', field: 'TRNCOBRO_FECHAVENCE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];
  columnDefsGuiaReferencia = [
    {
      headerName: 'Nombre', field: 'INFAD_CODIGO', cellEditor: 'cellPrueba', width: 250,
      editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 300
        }
      }
    },
    {
      headerName: 'Informacion Adicional', field: 'INFAD_DESCRIPCION', cellEditor: 'cellPrueba', width: 250,
      editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 300
        }
      }
    },
  ];
  columnDefsGuiaDetalle = [
    { // 0
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba', width: 100, pinned: 'left', editable: (params) => {
        const boolEdit = this.editCampos(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          opcionbuscar: true
        }
      }
    },
    { // 1
      headerName: 'Código', field: 'DETGRE_CODIGO', cellEditor: 'cellPrueba', width: 150, pinned: 'left', editable: (params) => {
        const boolEdit = this.editCampos(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    {  // 2
      headerName: 'Descripción', field: 'DETGRE_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, pinned: 'left', editable: (params) => {
        const boolEdit = this.editCampos(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 80

        }
      }
    },
    { // 3
      headerName: 'Iva', field: 'DETGRE_TRIBIVA', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.editCampos(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          regexp: /^[S]$|^[N]$/,
          tamanocelda: 1,
          tienecalculos: true
        }
      }
    },
    { // 4
      headerName: 'Ice', field: 'DETGRE_TRIBICE', cellEditor: 'cellPrueba', width: 80,
      editable: (params) => {
        const boolEdit = this.editCampos(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          regexp: /^[S]$|^[N]$/,
          tamanocelda: 1,
          tienecalculos: true
        }
      }
    },
    { // 5
      headerName: 'Unidad', field: 'DETGRE_UNIDAD', cellEditor: 'cellPrueba',
      editable: (params) => {
        const boolEdit = this.editCampos(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 1
        }
      }
    },
    { // 6
      headerName: 'Cantidad', field: 'DETGRE_CANTIDAD', cellEditor: 'cellPrueba', width: 100,
      onCellClicked: (params) => {
        this.abrirLotes(params.data);
      },
      cellStyle: (params) => {
        const color = this.comprobarExistencia(params.data);
        return { background: color };
      }, editable: (params) => {
        const boolEdit = this.editCamposPed(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 7
      headerName: 'Precio', field: 'DETGRE_PRECIO', cellEditor: 'cellPrueba', width: 100, cellStyle: (params) => {
        const color = this.comprobarPrecio(params.data);
        return { background: color };
      },editable: (params) => {
        const boolEdit = this.editCamposPed(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 8
      headerName: 'Dsct (%)', field: 'DETGRE_DESCUENTO', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 4,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true

        }
      }
    },
    { // 9
      headerName: 'Total', field: 'DETGRE_TOTAL', cellEditor: 'cellPrueba', width: 100, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 10
      headerName: 'Lista', field: 'DETGRE_LISTA', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: []
      }
    },
    { // 11
      headerName: 'C.Costo', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 80,
      // editable: (params) => {
      //   const boolEdit = this.editCampos(params.data);
      //   return boolEdit;
      // },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 12
      headerName: 'Bultos', field: 'DETGRE_BULTO', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32
        }
      }
    },
    { // 13
      headerName: 'Unidades', field: 'DETGRE_CANTIDADB', cellEditor: 'cellPrueba', width: 80,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 32

        }
      }
    },
    { // 14
      headerName: '%IVA', field: 'DETGRE_PORIVA', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          int: true,
          opcionbuscar: true
        }
      }
    }
  ];

  columnDefsTopProductos = [
    {
      headerName: 'Producto', field: 'DETFAC_CODIGO', width: 115, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', width: 190, editable: false
    },
    {
      headerName: 'Cantidad', field: 'CANTIDAD_TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFAC_PRECIO', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'TOTAL', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Stock', field: 'STOCK', width: 90, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];

  columnDefsDetFacturas = [
    {
      headerName: 'Fecha', field: 'ENCFAC_FECHAEMISION', width: 90, editable: false
    },
    {
      headerName: 'Factura', field: 'ENCFAC_NUMERO', width: 140, editable: false
    },
    {
      headerName: 'Producto', field: 'DETFAC_CODIGO', width: 90, editable: false
    },
    {
      headerName: 'Descripción', field: 'DETFAC_DESCRIPCION', width: 100, editable: false
    },
    {
      headerName: 'Cantidad', field: 'DETFAC_CANTIDAD', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Precio', field: 'DETFAC_PRECIO', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    },
    {
      headerName: 'Total', field: 'DETFAC_TOTAL', width: 80, cellStyle: { textAlign: 'right' }, editable: false
    }
  ];
 //CDPJ
 columnDefsBasesIva = [
  {
    headerName: '%IVA', field: 'TARIIVA_DESCRIPCION', width: 115, editable: false
  },
  {
    headerName: 'Base', field: 'IVA_BASE', width: 90, cellStyle: { textAlign: 'right' }, editable: false
  },
  {
    headerName: 'Valor IVA', field: 'IVA_VALOR', width: 90, cellStyle: { textAlign: 'right' }, editable: false
  }
];
boolasiento=false;
//CDPJ

  auxreporte: number = 0;
  strNombreForma: string;
  displayDialogReportes: boolean;
  datosfe: SegMaeCompania;

  constructor(public venenguiaRemisionService: VenenguiaRemisionService, private confirmationService: ConfirmationService,
    private busqService: InvbusquedaService, private datePipe: DatePipe,
    private messageService: MessageService, private utilitariosService: UtilitariosService, private cliService: VenmaeclienteService,
    private auditoriagral: AuditoriagralService, private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService, private permisosService: PermisosService, public usuario: Usuario,
    private init: NuevoComponentService, private venEstadisticasService: VenestadisticaService, private busquedaVeh: InvmaearticuloService) {

    this.agTable();
    this.detalleGuiaSeleccionado = {};
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = {};
    this.detallePedidoSeleccionadoAux = {};
    this.detalleReferenciaSeleccionadaAux = {};
    this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = [];
    this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = [];
    this.detalleReferenciaSeleccionada = {};
    this.opcionesprecio = [];
    this.listaprecios = [];
    this.encabezadoGuiaSeleccionadaAux = {};
    this.cxctmptrnresumen = [];
    this.cxctrnresumen = [];
    // this.usuario.loadStorage();
    this.detTopProductos = [];
    this.detFacturas = [];
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.rowStyle = (params) => {
      if (params.data.DETGRE_TIPODET === 'Y') {
        return { background: 'rgb(255, 204, 255)' };
      }
    };

    this.rowStyleTopProd = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.DETFAC_CODIGO === '' || params.data.DETFAC_CODIGO === null) {
        return { background: 'rgb( 164, 255, 167)' };
      }
    };

    this.rowStyleDetFacts = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };


    this.defaultGuiaDetalle = {
      editable: true,
      width: 100,
      objeto: 'vendetguia',
      resizable: true
    };
    this.defaultGuiaReferencia = {
      editable: true,
      objeto: 'vendetreferencia',
      resizable: true
    };
    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen',
      resizable: true
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen',
      resizable: true
    };

    this.defaultColTopProductos = {
      editable: true,
      width: 100,
      objeto: 'topProductos',
      resizable: true
    };

    this.defaultColDetFacturas = {
      editable: true,
      width: 100,
      objeto: 'detalleFacturas',
      resizable: true
    };
   //CDPJ
   this.defaultColBasesIva = {
    editable: false,
    width: 100,
    objeto: 'basesiva',
  };
  //CDPJ
  }

  async ngOnInit() {
    this.spin=true;
    this.venenguiaRemisionService.refrescarURL();
    this.displayAsiContable = false;
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = {};
    this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = [];
    this.cxctmptrnresumen = [];
    this.cxctrnresumen = [];
    this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = [];
    this.bolestcta = false;
    this.bolsaldo = false;
    this.displayAcciones = false;
    this.blocknum = false;//GSRF
    
    this.confIniciales.getDecimales('frmVEN_ENCGRE').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
            this.decimalesCantidadGre = Number(decimales.NUMDEC_NUMDEC);
            this.venenguiaRemisionService.decimalesCantidadGre = this.decimalesCantidadGre;
          } else if (decimales.NUMDEC_CAMPO === 'PRECIO') {
            this.decimalesPrecioGre = Number(decimales.NUMDEC_NUMDEC);
            this.venenguiaRemisionService.decimalesPrecioGre = this.decimalesPrecioGre;
          } else if (decimales.NUMDEC_CAMPO === 'TOTAL') {
            this.decimalesTotalGre = Number(decimales.NUMDEC_NUMDEC);
            this.venenguiaRemisionService.decimalesTotalGre = this.decimalesTotalGre;
          } else if (decimales.NUMDEC_CAMPO === 'DESCUENTO') {
            this.decimalesDescGre = Number(decimales.NUMDEC_NUMDEC);
            this.venenguiaRemisionService.decimalesDescGre = this.decimalesDescGre;
          }
        });

        this.columnDefsGuiaDetalle[6].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.decimalesCantidadGre,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.columnDefsGuiaDetalle[7].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.decimalesPrecioGre,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.columnDefsGuiaDetalle[8].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.decimalesDescGre,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };

        this.aggrid.refreshColumnDefs();
      }
    });
    this.permisosService.getListaPermisos('VEN', '6', '102').subscribe((permiso) => {
      this.permisoGre = permiso[0];
    });
    this.asiento = '';
    this.auxiliar = 0;
    this.consulta = '';
    this.accionClave = '';
    this.largoestcta = '420';
    this.porcIvaBoolean = true;
    this.displayDialogDetFac = false;
    this.displayDialogPedidos = false;
    this.displayDialogAsiento = false;
    this.displayDialogSeriales = false;
    this.claveDialog = false;
    this.displayLog = false;
    this.displayDialogEst=false;//CDPJ
    this.logResultado = '';
    this.choosedColor = '#0000FF';
    this.choosedColorR = '#FF0000';
    this.choosedColorG = '#00FF00';
    this.choosedColorSB = '#FF80F0';
    this.choosedColorSnSk = '#E66405';
    this.claveAuth = '';
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.btnRegresar = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.botonAnular = false;
    this.botonXML = false;
    this.btnOpenFact = false;
    this.cotizacionBoolean = false;
    this.activeIndex = 0;
    this.fechaBoolean = false;
    this.ventasboolean = false;
    this.imagenProducto = '';
    this.comprasboolean = false;
    this.consigboolean = false;
    this.trasladoboolean = false;
    this.nombrebodega = '';
    this.devboolean = false;
    this.impboolean = false;
    this.expboolean = false;
    this.otrosboolean = false;
    this.transboolean = false;
    this.displayDialogBus = false;
    this.numeroActual = 0;
    this.displayDialogBusquedaFast = false;
    this.comboOpciones = [{ label: 'A', value: 'A' },
    { label: 'S', value: 'S' }, { label: 'C', value: 'C' }, { label: '*', value: '*' }];
    //this.tipodetalle = 'A';
    //this.opcionIva = [{ label: '12', value: 12 }, { label: '8', value: 8 }];
    this.opcionIvaDesc = [{ label: 'Neto + IVA', value: 'netoIva' },
    { label: 'Incluido IVA', value: 'incIva' }, { label: 'Exento', value: 'exentIva' }];
    this.venenguiaRemisionService.dblPorcIvaGrearray[this.indicador] = 12;
    this.fechaEmision = new Date().toLocaleString().substr(0, 10);
    this.fechaInicio = new Date().toLocaleString().substr(0, 10);
    this.fechaFin = new Date().toLocaleString().substr(0, 10);
    this.horaInicio = new Date();
    this.horaFin = new Date();
    this.venenguiaRemisionService.horaSistema = new Date(); 
    this.venenguiaRemisionService.getListaPrecios().subscribe((res) => {
      this.listaprecios = res;
      this.listaprecios.map((precio) => {
        let nuevoItem: any;
        nuevoItem = { label: precio.ARTPRE_CODIGO, value: precio.ARTPRE_CODIGO };
        this.opcionesprecio.push(nuevoItem);
      });
    });
    this.largo = '-5800';
    this.largoRef = '550';
    this.indice = 0;
    this.i = 0;
    this.venenguiaRemisionService.getEncGuia().subscribe((res) => {
      this.encGuiaRemision = res;
      this.codigoGuiaAux = this.encGuiaRemision[0].ENCGRE_CODIGO;
    });
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION = new Date();
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO = new Date();
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO = new Date();

    this.venenguiaRemisionService.hmpConfiguracion = await this.auditoriagral.obtenerConfiguracionProm();
    this.intACTPEDVAL = this.venenguiaRemisionService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ACTPEDVAL').CFG_VALOR1;
    this.tipoNumeracion = this.venenguiaRemisionService.getConfiguracionNumeracion('NUMGRE');
    this.venenguiaRemisionService.leerConfiguracionDatosElectronicos().subscribe(data => {
      for (const rs of data) {
        this.datosfe = rs;
      }
    });
    //CDPJ
    this.confIniciales.getConfiguracion('CCMANG').subscribe((respuesta) => {
      if (respuesta[0].CFG_VALOR1 === 1) {
        this.CCMANG = true;

      } else {
        this.CCMANG = false;
      }
    });
    this.confIniciales.getConfiguracion('NFNEGR').subscribe((respuesta) => {
      if (respuesta[0].CFG_VALOR1 === 1) {
        this.NFNEGR = true;

      } else {
        this.NFNEGR = false;
      }
    });
    this.CNAFEPV=this.venenguiaRemisionService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CNAFEPV').CFG_VALOR1;
    this.BCPPG=this.venenguiaRemisionService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'BCPPG').CFG_VALOR1;
    this.GUINOKARD=this.venenguiaRemisionService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'GUINOKARD').CFG_VALOR1;
    this.TIPDET=this.venenguiaRemisionService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'TIPDET').CFG_VALOR3;
    this.DVBUSGR=this.venenguiaRemisionService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DVBUSGR').CFG_VALOR1;
    this.tipodetalle = this.TIPDET === null || this.TIPDET=== undefined?'A':this.TIPDET;
    if(this.GUINOKARD === 1){
      this.boolasiento=true;
    }
    this.boolfacturaElec= await this.venenguiaRemisionService.leerValidarRucsFacElec().catch(e=>{
      this.spin=false;
    });
    console.log(this.boolfacturaElec)
    this.iva = await this.confIniciales.obtenerImpuesto('I', 'A');
    await this.confIniciales.obtenerSrifeTariIva()
    await this.llenarcombo();
    this.spin=false;
    //CDPJ
  }
    //CDPJ
    ngOnDestroy(): void {
      this.confIniciales.instanciaGuias--;
    }
    async llenarcombo(){
      // this.cmbtipoIva = [
      // { codigo: '12', name: '12' },
      // { codigo: '8', name: '8' }
      // ];
      //CDPJ
      this.opcionIva=[];
      for(let i=0;  this.confIniciales.srifetariiva[i];i++){
        let item=this.confIniciales.srifetariiva[i]
        if(item.TARIIVA_CODIGO !=='6' &&  item.TARIIVA_CODIGO !=='7'  && item.TARIIVA_CODIGO !== '-1'){
          this.opcionIva.push({codigo:item.TARIIVA_CODIGO,name:item.TARIIVA_PORCENTAJE.toString()})
        } 
      }
      console.log(this.opcionIva)
      for(const data of this.opcionIva){
        if(Number(data.name) === Number(this.iva)){
          this.selectcombtipoIva={codigo:data.codigo,name:data.name}
          this.venenguiaRemisionService.porcetariiva=Number(this.selectcombtipoIva.name)
          this.venenguiaRemisionService.codtariiva=this.selectcombtipoIva.codigo
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PORCEIVA=this.venenguiaRemisionService.porcetariiva
        }
      }
      //CDPJ
    }
    cambiavalorIva(select){
      this.selectcombtipoIva.codigo=select.value.codigo
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PORCEIVA=Number(select.value.name);
      //console.log(this.encpedService.dblPorcIvaPed)
      this.venenguiaRemisionService.porcetariiva=Number(select.value.name)
      this.venenguiaRemisionService.codtariiva=select.value.codigo
    }
  //CDPJ

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir'
      && valor !== 'facturasAbonadas') {
      console.log(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION);
      let dia = new Date().getDate();
      let mes = new Date().getMonth();
      let anio = new Date().getFullYear();
      try {
        // Verifica el periodo contable        
        dia = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.getDate();
        mes = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.getMonth();
        anio = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.getFullYear();
      } catch (e) {
        const tmpfecha = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.substr(0, 10);
        const splfec = tmpfecha.split('/');
        const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
        dia = fecha.getDate();
        mes = fecha.getMonth();
        anio = fecha.getFullYear();
      }

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }

    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }
    if (this.activeIndex === 5) {
      return;
    }

    if (valor === 'Primero') {
      this.venenguiaRemisionService.getEncGuia().subscribe((res) => {
        this.encGuiaRemision = res;
        this.codigoGuiaAux = this.encGuiaRemision[0].ENCGRE_CODIGO;
        this.indice = 0;
        if (this.botonUltimo === true) {
          this.botonUltimo = !this.botonUltimo;
        }
        if (this.botonSiguiente === true) {
          this.botonSiguiente = !this.botonSiguiente;
        }
        this.botonPrimero = true;
        this.botonAnterior = true;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encGuiaRemision[this.indice];
        this.obtenerDatos();
      });
    } else if (valor === 'Siguiente') {
      this.venenguiaRemisionService.getEncGuia().subscribe((res) => {
        this.encGuiaRemision = res;
        this.codigoGuiaAux = this.encGuiaRemision[0].ENCGRE_CODIGO;
        this.indice++;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encGuiaRemision[this.indice];
        if (this.botonPrimero === true && this.botonAnterior === true) {
          this.botonPrimero = false;
          this.botonAnterior = false;
        }
        if (this.indice >= this.encGuiaRemision.length - 1) {
          this.botonUltimo = true;
          this.botonSiguiente = true;
          if (this.indice === this.encGuiaRemision.length - 1) {
            this.encGuiaRemision[this.indice - 1].ENCGRE_CODIGO = this.codigoGuiaAux;
            this.obtenerDatos();
          } else if (this.indice !== this.encGuiaRemision.length - 1) {
            return;
          }
        } else if (this.indice < this.encGuiaRemision.length - 1) {
          this.encGuiaRemision[this.indice - 1].ENCGRE_CODIGO = this.codigoGuiaAux;
          this.obtenerDatos();
        }
      });
    } else if (valor === 'Anterior') {
      this.venenguiaRemisionService.getEncGuia().subscribe((res) => {
        this.encGuiaRemision = res;
        this.codigoGuiaAux = this.encGuiaRemision[0].ENCGRE_CODIGO;
        this.indice--;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encGuiaRemision[this.indice];
        if (this.botonUltimo === true && this.botonSiguiente === true) {
          this.botonUltimo = false;
          this.botonSiguiente = false;
        }
        if (this.indice <= 0) {
          this.botonPrimero = true;
          this.botonAnterior = true;
          if (this.indice === 0) {
            this.encGuiaRemision[this.indice + 1].ENCGRE_CODIGO = this.codigoGuiaAux;
            this.obtenerDatos();
          } else if (this.indice !== 0) {
            return;
          }
        } else if (this.indice > 0) {
          this.encGuiaRemision[this.indice + 1].ENCGRE_CODIGO = this.codigoGuiaAux;
          this.obtenerDatos();
        }
      });
    } else if (valor === 'Ultimo') {

      this.venenguiaRemisionService.getEncGuia().subscribe((res) => {
        this.encGuiaRemision = res;
        this.codigoGuiaAux = this.encGuiaRemision[0].ENCGRE_CODIGO;
        this.indice = this.encGuiaRemision.length - 1;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encGuiaRemision[this.indice];
        if (this.botonPrimero === true) {
          this.botonPrimero = !this.botonPrimero;
        }
        if (this.botonAnterior === true) {
          this.botonAnterior = !this.botonAnterior;
        }
        this.botonSiguiente = true;
        this.botonUltimo = true;
        this.obtenerDatos();
      });

    } else if (valor === 'Nuevo') {
      if (this.permisoGre.PERINSERCION === 1) {
        this.crearNuevaGuia('N');
        this.disabledEstadisticas = true;
      } else if (this.permisoGre.PERINSERCION === 0) {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'error',
          summary: 'Inserción de Guía',
          detail: 'El usuario no tiene permisos de inserción, acción denegada'
        });
      }
    } else if (valor === 'Borrar') {
      this.confirmarGuia();
      if (this.permisoGre.PERELIMACION === 1) {
        if (this.codigoGuiaAux === '') {
          return;
        }
      }
      /* if (this.permisoGre.PERELIMACION === 1) {
         if (this.codigoGuiaAux === '') {
           return;
         }
         if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN === 'GRE') {
           this.confirmarGuia();
         } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN !== 'GRE') {
           this.messageService.add({
             key: 'guiaRemision',
             severity: 'error',
             summary: 'Eliminación de Guia',
             detail: 'No se puede eliminar la guia por que proviene de otro origen'
           });
         }
       } else if (this.permisoGre.PERELIMACION === 0) {
         this.messageService.add({
           key: 'guiaRemision',
           severity: 'error',
           summary: 'Eliminación de Guia',
           detail: 'El usuario no tiene permisos de eliminación, acción denegada'
         });
       }*/
    } else if (valor === 'Guardar') {
      // this.eliminarLineaEnBlanco();
      this.spin=true;
      const NOFCCERO = this.venenguiaRemisionService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOFCCERO').CFG_VALOR1;//CDPJ
      //GSRF
      if (this.permisoGre.PERACTUALIZACION === 0 && this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO !== '' 
      && this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO !== null && this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO !== undefined) {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'error',
          summary: 'Actualización de Guía',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
        this.spin=false;//CDPJ
        return;
      }
      //GSRF
      //CDPJ
      let encpedestado='';
      const data = await this.venenguiaRemisionService.obtenerEncgreEstado(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO);
      if(data !== null && data !== undefined){
        for(const rs1 of data){
          encpedestado=rs1.ENCGRE_ESTADO
        }
      }
    //CDPJ
    console.log('encpedestado',encpedestado)
    if(encpedestado === 'F'){
      
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Actualización de Guía',
        detail: 'La Guía ya se encuentra en estado \'FACTURADA\''
      });
      this.spin=false;//CDPJ
      this.spin=false;
      this.cancelarEdicionGuia();
      return;
    }
    //CDPJ
        if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO === '' ||
          this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length === 0 
          ) {
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'error',
            summary: 'Inserción de Guía',
            detail: 'No existe un cliente o el detalle está vacio'
          });
          this.spin=false;//CDPJ
        } //CDPJ
        else if (this.validatransport() === false){
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'error',
            summary: 'Inserción de Guía',
            detail: 'No existe un transportista'
          });
          this.spin=false;//CDPJ
        //CDPJ
         } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION === '' ||
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION === null ||
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO === '' ||
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO === null ||
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO === '' ||
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO === null) {
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'error',
            summary: 'Inserción de Guía',
            detail: 'Ingrese fechas validas para guardar la guía'
          });
          this.spin=false;//CDPJ
        } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PTOPARTIDA === '' ||
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PTOLLEGADA === '') {
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'error',
            summary: 'Inserción de Guía',
            detail: 'Ingrese un punto de partida y un punto de llegada'
          });
          this.spin=false;//CDPJ
        } else if (this.validaplacaautotransport() === false) {        //CDPJ  
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'error',
            summary: 'Inserción de Guía',
            detail: 'Ingrese una placa y un auto en el transportista'
          });
          this.spin=false;//CDPJ
      }else if (this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length === 0) {
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'error',
            summary: 'Inserción de Guía',
            detail: 'El detalle está vacio'
          });
          this.spin=false;//CDPJ
        } else {
          if (this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length !== 0) {
            let errores = 0;
            //this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].map((guia) => {
              await Promise.all(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].map(async (guia) => {
                let cont=0;
                const num= await this.venenguiaRemisionService.validarcencodigo(guia.CEN_CODIGO);
                if (num !== null && num !== undefined){
                  for( const rs of num){
                    if(rs.NUMERO !== null && rs.NUMERO!== undefined && rs.NUMERO !== ''){
                      cont=rs.NUMERO
                    }
                    
                  }
                }
                let strCodigo = 0;
                const rsdata: any = await this.venenguiaRemisionService.encontrarArtCntaySer(guia.DETGRE_CODIGO, guia.DETGRE_TIPODET);
                if (rsdata !== null) {
                  for (const rs of rsdata) {
                    strCodigo = rs.NUMERO;
                  }
                }
                console.log('coont',strCodigo);
                console.log('coont',guia.DETGRE_TIPODET );
              if (guia.DETGRE_TIPODET === '*' || guia.DETGRE_TIPODET === 'Y') {
                errores += 0;
              } else {
                if (guia.DETGRE_TIPODET !== '*' && guia.DETGRE_CODIGO === '') {
                  this.messageService.add({
                    key: 'guiaRemision',
                    severity: 'error',
                    summary: 'Inserción de Guía',
                    detail: 'El detalle está vacio'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                } else if (guia.DETGRE_CODIGO === '') {
                  this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = this.venenguiaRemisionService.detguiaRemisionarray[
                    this.indicador].filter((val, j) => j !==
                      this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].indexOf(guia));
                  this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);
                } else if (guia.BOD_CODIGO === '') {
                  this.messageService.add({
                    key: 'guiaRemision',
                    severity: 'error',
                    summary: 'Inserción de Guía',
                    detail: 'Ingrese una bodega al detalle de la guía'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                } else if (Number(guia.DETGRE_CANTIDAD) <= 0) {
                  this.messageService.add({
                    key: 'guiaRemision',
                    severity: 'error',
                    summary: 'Inserción de Guía',
                    detail: 'Ingrese una cantidad valida al detalle de la guía'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                } else if (Number(guia.DETGRE_PRECIO) <= 0 && NOFCCERO === 1) {
                  this.messageService.add({
                    key: 'guiaRemision',
                    severity: 'error',
                    summary: 'Inserción de Guía',
                    detail: 'Ingrese un precio valido al detalle de la guía'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                }else if(guia.CEN_CODIGO !== undefined && guia.CEN_CODIGO !== null && guia.CEN_CODIGO !== ''){//CDPJ
                  console.log('entro al cen');
                  if(cont === 0){
                    this.messageService.add({
                      key: 'guiaRemision',
                      severity: 'error',
                      summary: 'Inserción de Guía',
                      detail: 'El código del centro de costos ingresado no existe'
                    });
                    this.spin=false;//CDPJ
                    errores++;
                  }
                  //CDPJ
                } else if (this.CCMANG === true) {
                  if (guia.CEN_CODIGO === undefined || guia.CEN_CODIGO === ''|| guia.CEN_CODIGO === null) {//CDPJ guia.CEN_CODIGO === null
                    this.messageService.add({
                      key: 'guiaRemision',
                      severity: 'error',
                      summary: 'Inserción de Guía',
                      detail: 'Ingrese el Centro de Costo al detalle de la guia'
                    });
                    this.spin=false;//CDPJ
                    errores++;
                  }
                }else if(guia.DETGRE_TIPODET === 'A' && strCodigo === 0){//CDPJ
                  this.messageService.add({
                    key: 'guiaRemision',
                    severity: 'error',
                    summary: 'Inserción de Guía',
                    detail: 'El artículo '+ guia.DETGRE_CODIGO +' no existe'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                }else if(guia.DETGRE_TIPODET === 'S' && strCodigo === 0){
                  this.messageService.add({
                    key: 'guiaRemision',
                    severity: 'error',
                    summary: 'Inserción de Guía',
                    detail: 'El servicio '+ guia.DETGRE_CODIGO +' no existe'
                  });
                  this.spin=false;//CDPJ
                  errores++;
                }else if(guia.DETGRE_TIPODET === 'C' && strCodigo === 0){
                  this.messageService.add({
                    key: 'guiaRemision',
                    severity: 'error',
                    summary: 'Inserción de Guía',
                    detail: 'La cuenta contable '+ guia.DETGRE_CODIGO +' no existe'
                  });
                  this.spin=false;//CDPJ
                  errores++;//CDPJ
                }else if (guia.DETGRE_TIPODET === 'A' || guia.DETGRE_TIPODET === 'S' || guia.DETGRE_TIPODET === 'C') {
                  if (guia.DETGRE_TRIBICE === '' || guia.DETGRE_TRIBIVA === '') {
                    this.messageService.add({
                      key: 'guiaRemision',
                      severity: 'error',
                      summary: 'Inserción de Guía',
                      detail: 'Los datos del detalle de la guía de remisión no estan completos, por favor revise los valores e intente nuevamente'
                    });
                    this.spin=false;//CDPJ
                    errores++;
                  }
                }
                //CDPJ
                if(guia.DETGRE_PORIVA === null || guia.DETGRE_PORIVA === undefined || guia.DETGRE_PORIVA === ''){
                  this.messageService.add({
                    key: 'guiaRemision',
                    severity: 'error',
                    summary: 'Error en IVA',
                    detail: 'El campo %IVA no puede estar vacio, a menos que sea un comentario'
                  });
                  this.spin=false;
                  errores++;             
              } else if(guia.TARIIVA_CODIGO === null || guia.TARIIVA_CODIGO === undefined || guia.TARIIVA_CODIGO === ''){       
                this.messageService.add({
                  key: 'guiaRemision',
                  severity: 'error',
                  summary: 'Error en IVA',
                  detail: 'Falta seleccionar el tipo de porcentaje del código '+guia.DETGRE_CODIGO
                });
                this.spin=false;
                  errores++;               
              }else if(guia.DETGRE_TRIBIVA === 'S' && guia.DETGRE_PORIVA !== null && guia.DETGRE_PORIVA !== undefined && guia.DETGRE_PORIVA !== ''){
                let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === guia.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) !== 0 && Number(guia.DETGRE_PORIVA) !== 0
                && Number(element.TARIIVA_PORCENTAJE) ===  Number(guia.DETGRE_PORIVA))
                if(data === undefined || data === null){
                  this.messageService.add({
                    key: 'guiaRemision',
                    severity: 'error',
                    summary: 'Error en IVA',
                    detail: 'El %IVA con el porcentaje '+guia.DETGRE_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando se tributa IVA.'
                  });
                  this.spin=false;
                  errores++;             
                }
              }else if(guia.DETGRE_TRIBIVA === 'N'
              && guia.DETGRE_PORIVA !== null && guia.DETGRE_PORIVA !== undefined && guia.DETGRE_PORIVA !== ''){
                let data=this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === guia.TARIIVA_CODIGO && Number(element.TARIIVA_PORCENTAJE) === 0 && Number(guia.DETGRE_PORIVA) === 0
                && Number(element.TARIIVA_PORCENTAJE) ===  Number(guia.DETGRE_PORIVA))
                console.log(data)
                console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === guia.TARIIVA_CODIGO))
                if(data === undefined || data === null){
                  this.messageService.add({
                    key: 'guiaRemision',
                    severity: 'error',
                    summary: 'Error en IVA',
                    detail: 'El %IVA con el porcentaje '+guia.DETGRE_PORIVA+' no es parte de los porcentajes que puede seleccionar cuando no se tributan IVA.'
                  });
                  this.spin=false;
                  errores++;
                }
              }
                //CDPJ
              }
            }));
            console.log('errores',errores)
            if (errores === 0) {
              await this.guardarGuia();
            }else{
              this.spin=false;//CDPJ
            }
          }
        }
      // } else if (this.permisoGre.PERACTUALIZACION === 0) {
      //   this.messageService.add({
      //     key: 'guiaRemision',
      //     severity: 'error',
      //     summary: 'Actualización de Guía',
      //     detail: 'El usuario no tiene permisos de actualización, acción denegada'
      //   });
      // }
    } else if (valor === 'Cancelar') {
      this.cancelarEdicionGuia();
    } else if (valor === 'Buscar') {
      //this.buscarGuia();
      //CDPJ
      this.spin=true;
      this.venenguiaRemisionService.getEncGuia().pipe(finalize(() => {this.buscarGuia();})).subscribe((res) => {
        this.encGuiaRemision = res;
      });
      //CDPJ
    }  else if (valor === 'facturasAbonadas') {
      //this.buscarGuia();
      //CDPJ
      this.venenguiaRemisionService.getEncGuia().pipe(finalize(() => {this.buscarGuianofirma();})).subscribe((res) => {
        this.encGuiaRemision = res;
      });
      //CDPJ
    } else if (valor === 'Anular') {
      //CDPJ
      let encpedestado='';
      const data = await this.venenguiaRemisionService.obtenerEncgreEstado(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO);
      if(data !== null && data !== undefined){
        for(const rs1 of data){
          encpedestado=rs1.ENCGRE_ESTADO
        }
      }
    //CDPJ
    console.log('encpedestado',encpedestado)
    if(encpedestado === 'F'){
      
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Anular Guía',
        detail: 'La Guía ya se encuentra en estado \'FACTURADA\''
      });
      this.spin=false;//CDPJ
      this.spin=false;
      this.cancelarEdicionGuia();
      return;
    }
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'PENDIENTE') {
        if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN === 'GRE') {
          this.confirmarAnularGre();
        } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN !== 'GRE') {
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'error',
            summary: 'Anular Guía',
            detail: 'No se puede anular la guia de remisión por que proviene de otro origen'
          });
        }
      } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO !== 'PENDIENTE') {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'error',
          summary: 'Anular Guía',
          detail: 'No se puede anular la guia de remisión'
        });
      }

    } else if (valor === 'Asiento') {
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === '' ||
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === null) {
        this.confirmarGenerarAsiento();
      } else {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'error',
          summary: 'Asiento Guía',
          detail: 'No se puede generar un asiento debido a que se escogió una factura'
        });
      }
    } else if (valor === 'AsientoVer') {

      // this.venenguiaRemisionService.getEncGuia().subscribe((res) => {
      //   this.encGuiaRemision = res;
      //   this.codigoGuiaAux = this.encGuiaRemision[0].ENCGRE_CODIGO ;
      //
      this.venenguiaRemisionService.getAsiento(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[
        this.indicador].ENCGRE_CODIGO2).subscribe((res1) => {
          this.asiento = res1[0].ASI_NRO;
          if (this.asiento === null) {
            this.messageService.add({
              key: 'guiaRemision',
              severity: 'error',
              summary: 'Visualizacion de Asiento Contable',
              detail: 'Esta guia no tiene un asiento contable'
            });
          } else {
            this.auxiliar++;
            // this.displayDialogAsiento = true;
            this.NumAsiRegCont = res1[0].ASI_NRO;
            this.displayAsiContable = true;
          }
        });
      // });

    } else if (valor === 'Firmar') {
      this.insertarEleTrnDoc();
    } else if (valor === 'EnviarCorreo') {
      this.reenviarCorreo();
    }
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (valor === 'Almacenardoc') {
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }

      }
    }
    if (valor === 'ClaseNumeracion') {
      this.strClase = '';
    }
    //CDPJ
  }
//CDPJ
validaplacaautotransport(){
  let boltrans=true;
  console.log('nemonico',this.strNemonico)
  //console.log('no tra',this.NTNEGR)
   if (this.strNemonico !=='NE') {          
    if(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_AUTOTNTR === '' ||
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PLACATNTR === ''){
      boltrans=false;
    }
  }
  return boltrans;
}
validatransport(){
  let boltrans=true;
  console.log('nemonico2',this.strNemonico)
  //console.log('no tra2',this.NTNEGR)
  if(this.strNemonico !=='NE'){
    if(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].TRANSPORT_CODIGO === ''){
      boltrans=false
    }
  }
  return boltrans;
}
//CDPJ
  cerrarReportes() {
    this.displayDialogReportes = false;
  }


  async guardarGuia() {

    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TOTALNETO = this.init.quitarComas(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TOTALNETO);
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORIVA = this.init.quitarComas(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORIVA);
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORICE = this.init.quitarComas(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORICE);
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASEICE = this.init.quitarComas(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASEICE);
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASECERO = this.init.quitarComas(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASECERO);
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASEIVA = this.init.quitarComas(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASEIVA);
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TOTAL = this.init.quitarComas(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TOTAL);
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PORCEDES = this.init.quitarComas(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PORCEDES);
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORDES = this.init.quitarComas(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORDES);

        //CDPJ
    let origen = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN;
    let posicion = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2.toString().indexOf(origen);
    if (posicion !== -1) {

      //console.log('el ifGuaradr', this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO);

      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO.replace(origen, ' ').trim();
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO.replace(origen, ' ').trim();
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2 = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2.replaceAll(origen, ' ').trim();
      //console.log('el if2', this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2);

    }
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_GRUPO=this.strClase === null || this.strClase === undefined ?'':this.strClase
    //CDPJ
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toString().includes('-')) {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION = this.datePipe.transform
        (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION,
          'dd/MM/yyyy');
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toString().includes('/')) {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION = this.datePipe.transform
        (new Date(),
          'dd/MM/yyyy');
    }
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO.toString().includes('-')) {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO = this.datePipe.transform
        (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO,
          'dd/MM/yyyy');
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO.toString().includes('/')) {
    }

    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO.toString().includes('-')) {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO = this.datePipe.transform
        (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO,
          'dd/MM/yyyy');
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO.toString().includes('/')) {
    }
    console.log('Estado1: ' + this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO);
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'PENDIENTE') {
      console.log('Estado: ' + this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO);
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO = 'P';
    }
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO === '') {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO = '0';
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2 = '0';
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO !== '') {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO = this.codigoGuiaAux;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2 = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2;//CDPJ this.codigoGuiaAux
    }
    const arregloChecks = [];
    const arregloLetras = [];
    if (this.ventasboolean === true) {
      arregloChecks.push('01');
      arregloLetras.push('VENTAS');
    }
    if (this.comprasboolean === true) {
      arregloChecks.push('02');
      arregloLetras.push('COMPRAS');
    }
    if (this.consigboolean === true) {
      arregloChecks.push('04');
      arregloLetras.push('CONSIGNACION');
    }
    if (this.trasladoboolean === true) {
      arregloChecks.push('06');
      arregloLetras.push('TRASLADO');
    }
    if (this.devboolean === true) {
      arregloChecks.push('07');
      arregloLetras.push('DEVOLUCION');
    }
    if (this.impboolean === true) {
      arregloChecks.push('08');
      arregloLetras.push('IMPORTACION');
    }
    if (this.expboolean === true) {
      arregloChecks.push('09');
      arregloLetras.push('EXPORTACION');
    }
    if (this.otrosboolean === true) {
      arregloChecks.push('10');
      arregloLetras.push('OTROS');
    }
    if (this.transboolean === true) {
      arregloChecks.push('11');
      arregloLetras.push('TRANSFORMACION');
    }
    let hora;
    let minutos;
    hora = this.horaFin.getHours();
    minutos = this.horaFin.getMinutes();
    if (hora.toString().length === 1) {
      hora = '0' + hora;
    } else if (minutos.toString().length === 1) {
      minutos = '0' + minutos;
    }
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN = hora + ':' + minutos;
    let hora2;
    let minutos2;
    hora2 = this.horaInicio.getHours();
    minutos2 = this.horaInicio.getMinutes();
    if (hora2.toString().length === 1) {
      hora2 = '0' + hora2;
    } else if (minutos2.toString().length === 1) {
      minutos2 = '0' + minutos2;
    }
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI = hora2 + ':' + minutos2;
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_MOTIVO = arregloChecks.join(';') + ';';
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_MOTIVOLETRAS = arregloLetras.join('/') + '/';
    let hora3;
    let minutos3;
    hora3 = this.venenguiaRemisionService.horaSistema.getHours();
    minutos3 = this.venenguiaRemisionService.horaSistema.getMinutes();
    if (hora3.toString().length === 1) {
      hora3 = '0' + hora3;
    } else if (minutos3.toString().length === 1) {
      minutos3 = '0' + minutos3;
    }
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION =
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION + ' ' + hora3 + ':' + minutos3;
    // console.log(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION);
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN = 'GRE';
    try {
      console.log('Estado3: ' + this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO);
      console.log(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador]);
      const res = await this.venenguiaRemisionService.guardarENCGREProm(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador]);
      if (res !== null) {
        // this.codigoGuiaAux = JSON.stringify(res[0]).substr(7, 5);
        // console.log('Codigo Auxiliar lin 1089', this.codigoGuiaAux);
        //CDPJ
        let b= JSON.stringify(res[0]);
        this.codigoGuiaAux = JSON.stringify(res[0]);
        console.log('PREVIOSUBSTRING', this.codigoGuiaAux);
        this.codigoGuiaAux = b.substring(7,b.length-1);//CDPJ
        console.log('Codigo Auxiliar lin 1089', this.codigoGuiaAux);
        if (this.indice === -1) {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO =
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN + ' ' + b.substring(7,b.length-1);//CDPJ
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2 = this.codigoGuiaAux;
          //this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].map(async (guia) => {
          for (const guia of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
            guia.ENCGRE_CODIGO = b.substring(7,b.length-1);//CDPJ
            try {
              console.log('linea', guia);
              //CDPJ
              if(guia.DETGRE_TIPODET === '*'){
                guia.DETGRE_PORIVA=0;
                guia.TARIIVA_CODIGO='-1';
              };
              //CDPJ
              await this.venenguiaRemisionService.insertarDetalleProm(guia);
              if (guia.LINEA === 0) {//CDPJ
                guia.LINEA = guia.DETGRE_LINEA;//CDPJ
                this.auditoriagral.registrarAuditoria('VEN_DETGRE', String(guia.ENCGRE_NUMERO)+'/'+guia.DETGRE_CODIGO, 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.DETGRE_CODIGO+ '/' 
                +'PorIva:'+ guia.DETGRE_PORIVA + '/' + 'CodTarIva:'+ guia.TARIIVA_CODIGO, 'I', 
                '', '01', '', '', '', '').subscribe(() => {
                });
                if(guia.ENCPED_NUMERO !== null  && guia.ENCPED_NUMERO !== ''
                && guia.ENCPED_NUMERO !== undefined){
                  this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.ENCPED_NUMERO+'/'+guia.DETGRE_CODIGO+ '/'+guia.DETGRE_CANTIDAD 
               , 'I', 
                '', '01', '', '', '', '').subscribe(() => {
                });
                }
                if(guia.ENCFAC_NUMERO !== null  && guia.ENCFAC_NUMERO !== ''
                && guia.ENCFAC_NUMERO !== undefined){
                    this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.ENCFAC_NUMERO+'/'+guia.DETGRE_CODIGO+ '/'+guia.DETGRE_CANTIDAD 
                , 'I', 
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                }

              } else if (guia.LINEA !== 0) {//CPDJ
                this.auditoriagral.registrarAuditoria('VEN_DETGRE', String(guia.ENCGRE_NUMERO)+'/'+guia.DETGRE_CODIGO, 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.DETGRE_CODIGO+ '/' 
                  +'PorIva:'+ guia.DETGRE_PORIVA + '/' + 'CodTarIva:'+ guia.TARIIVA_CODIGO, 'A', 
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  if(guia.ENCPED_NUMERO !== null  && guia.ENCPED_NUMERO !== ''
                  && guia.ENCPED_NUMERO !== undefined){
                    this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.ENCPED_NUMERO+'/'+guia.DETGRE_CODIGO+ '/'+guia.DETGRE_CANTIDAD 
                 , 'A', 
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  }
                  if(guia.ENCFAC_NUMERO !== null  && guia.ENCFAC_NUMERO !== ''
                  && guia.ENCFAC_NUMERO !== undefined){
                      this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.ENCFAC_NUMERO+'/'+guia.DETGRE_CODIGO+ '/'+guia.DETGRE_CANTIDAD 
                  , 'A', 
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                  }
              }
              if (guia.ENCPED_NUMERO !== '' && guia.ENCPED_NUMERO !== null) {
                let fecha=this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                  0, 10)
                fecha=fecha.toString().replaceAll(',','')

                // this.venenguiaRemisionService.actualizarDespachoPed(guia.ENCPED_NUMERO, guia.DETPED_LINEA).subscribe(() => {
                // });
                // this.venenguiaRemisionService.verificaEstadoPedidos(guia.ENCPED_NUMERO).subscribe(() => {
                // });
                // this.venenguiaRemisionService.actualizarFechaEntregaPedido(guia.ENCPED_NUMERO,
                //   this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                //     0, 10)).subscribe(() => {
                //     });
                //CDPJ
                await this.venenguiaRemisionService.actualizarDespachoPed(guia.ENCPED_NUMERO, guia.DETPED_LINEA).toPromise()
                await this.venenguiaRemisionService.verificaEstadoPedidos(guia.ENCPED_NUMERO).toPromise()
                await this.venenguiaRemisionService.actualizarFechaEntregaPedido(guia.ENCPED_NUMERO,fecha).toPromise()
                //CDPJ
                if (this.intACTPEDVAL === 1) {
                  // this.venenguiaRemisionService.actualizaFacturadoPedido(guia.ENCPED_NUMERO).subscribe(() => {
                  // });
                  // this.venenguiaRemisionService.verificaEstadoPedidosFact(guia.ENCPED_NUMERO).subscribe(() => {
                  // });
                  // this.venenguiaRemisionService.actualizarFechaEntregaPedido(guia.ENCPED_NUMERO,
                  //   this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                  //     0, 10)).subscribe(() => {
                  //     });
                  //CDPJ
                  await this.venenguiaRemisionService.actualizaFacturadoPedido(guia.ENCPED_NUMERO).toPromise()
                  await this.venenguiaRemisionService.verificaEstadoPedidosFact(guia.ENCPED_NUMERO).toPromise()
                  await this.venenguiaRemisionService.actualizarFechaEntregaPedido(guia.ENCPED_NUMERO,fecha).toPromise()
                  //CDPJ
                }
              }
            } catch (err) {
              this.spin=false;//CDPJ
            }
            // try {
            //   await this.venenguiaRemisionService.registrarKardexProm(this.codigoGuiaAux);
            //   if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === '' ||
            //     this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === null) {
            //     console.log('Codigo Auxiliar', this.codigoGuiaAux + 'user' + this.usuario.identificacion);
            //     this.venenguiaRemisionService.generarAsientoContable(this.codigoGuiaAux,
            //       this.usuario.identificacion).subscribe(() => {
            //         this.venenguiaRemisionService.getAsiento(this.codigoGuiaAux).subscribe((res2) => {
            //           this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ASI_NRO = res2[0].ASI_NRO;
            //         });
            //       }, error1 => {
            //         console.log(error1);
            //         this.messageService.add({
            //           key: 'guiaRemision',
            //           severity: 'error',
            //           summary: 'Asiento Contable ',
            //           detail: 'No se pudo generar el asiento contable'
            //         });
            //         this.spin=false;//CDPJ
            //       });
            //   }
            // } catch (err) {
            //   this.spin=false;//CDPJ
            // }
            if (this.GUINOKARD === 0){//CDPJ
              try {
                await this.venenguiaRemisionService.registrarKardexProm(this.codigoGuiaAux);
                if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === '' ||
                  this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === null) {
                  console.log('Codigo Auxiliar', this.codigoGuiaAux + 'user' + this.usuario.identificacion);
                  this.venenguiaRemisionService.generarAsientoContable(this.codigoGuiaAux,
                    this.usuario.identificacion).subscribe(() => {
                      this.venenguiaRemisionService.getAsiento(this.codigoGuiaAux).subscribe((res2) => {
                        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ASI_NRO = res2[0].ASI_NRO;
                      });
                    }, error1 => {
                      console.log(error1);
                      this.messageService.add({
                        key: 'guiaRemision',
                        severity: 'error',
                        summary: 'Asiento Contable ',
                        detail: 'No se pudo generar el asiento contable'
                      });
                      this.spin=false;//CDPJ
                    });
                }
              } catch (err) {
                this.spin=false;//CDPJ
              }
            }//CDPJ
            this.venenguiaRemisionService.actualizarDatos(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador],
              this.codigoGuiaAux).subscribe(() => {
              });
          }; // );
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'success',
            summary: 'Inserccion de la Guia ',
            detail: 'Guia insertada correctamente'
          });
          this.spin=false;//CDPJ
          this.venenguiaRemisionService.erCodigoEncabezado(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO).subscribe((tipoCli) => {
            if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
              this.disabledEstadisticas = true;
            } else {
              this.disabledEstadisticas = false;
            }
          });
          this.indice = this.encGuiaRemision.length + 1;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO = 'PENDIENTE';
          /*  this.venenguiaRemisionService.aumentarNumerion(this.numeroActual).subscribe(() => {
           }); */

          await this.actualizarNumeracion();

          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.auditoriagral.registrarAuditoria('VEN_ENCGRE',
            String(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO), 'I',
            '', '01', '', '', '', '').subscribe(() => {
            });
          let correoAd = '';
          this.venenguiaRemisionService.obtenerCorreo(
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO).subscribe((correo) => {
              correoAd = correo[0].CLI_CORREO;
              this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = [{
                ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                INFAD_NUMSEC: 0,
                LINEA: 1,
                COM_CODIGO: '01',
                INFAD_CODIGO: 'direccion',
                INFAD_DESCRIPCION: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_DIRECCIONDSTNTR
              }, {
                ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                INFAD_NUMSEC: 0,
                LINEA: 2,
                COM_CODIGO: '01',
                INFAD_CODIGO: 'correo',
                INFAD_DESCRIPCION: correoAd
              }, {
                ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                INFAD_NUMSEC: 0,
                LINEA: 3,
                COM_CODIGO: '01',
                INFAD_CODIGO: 'telefono',
                INFAD_DESCRIPCION: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TELEFONODSTNTR
              }];
            });
          this.indice = this.encGuiaRemision.length + 1;
        } else if (this.indice !== -1) {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO =
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN + ' ' + b.substring(7,b.length-1);//CDPJ
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2 = b.substring(7,b.length-1);//CDPJ
          //this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].map((guia) => {
            for (const guia of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
            guia.ENCGRE_CODIGO = b.substring(7,b.length-1);//CDPJ
            guia.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
            //CDPJ
            if(guia.DETGRE_TIPODET === '*'){
              guia.DETGRE_PORIVA=0;
              guia.TARIIVA_CODIGO='-1';
            };
            //CDPJ
            await this.venenguiaRemisionService.insertarDetalleProm(guia);//CDPJ
            //this.venenguiaRemisionService.insertarDetalle(guia).subscribe(() => {
              if (guia.LINEA === 0) {//CDPJ
                guia.LINEA = guia.DETGRE_LINEA;//CDPJ
                this.auditoriagral.registrarAuditoria('VEN_DETGRE', String(guia.ENCGRE_NUMERO)+'/'+guia.DETGRE_CODIGO, 'I',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.DETGRE_CODIGO+ '/' 
                  +'PorIva:'+ guia.DETGRE_PORIVA + '/' + 'CodTarIva:'+ guia.TARIIVA_CODIGO, 'I', 
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  if(guia.ENCPED_NUMERO !== null  && guia.ENCPED_NUMERO !== ''
                  && guia.ENCPED_NUMERO !== undefined){
                    this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.ENCPED_NUMERO+'/'+guia.DETGRE_CODIGO+ '/'+guia.DETGRE_CANTIDAD 
                 , 'I', 
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  }
                  if(guia.ENCFAC_NUMERO !== null  && guia.ENCFAC_NUMERO !== ''
                  && guia.ENCFAC_NUMERO !== undefined){
                      this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.ENCFAC_NUMERO+'/'+guia.DETGRE_CODIGO+ '/'+guia.DETGRE_CANTIDAD 
                  , 'I', 
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                  }
              } else if (guia.LINEA !== 0) {//CDPJ
                this.auditoriagral.registrarAuditoria('VEN_DETGRE', String(guia.ENCGRE_NUMERO)+'/'+guia.DETGRE_CODIGO, 'A',
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.DETGRE_CODIGO+ '/' 
                  +'PorIva:'+ guia.DETGRE_PORIVA + '/' + 'CodTarIva:'+ guia.TARIIVA_CODIGO, 'A', 
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  if(guia.ENCPED_NUMERO !== null  && guia.ENCPED_NUMERO !== ''
                  && guia.ENCPED_NUMERO !== undefined){
                    this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.ENCPED_NUMERO+'/'+guia.DETGRE_CODIGO+ '/'+guia.DETGRE_CANTIDAD 
                 , 'A', 
                  '', '01', '', '', '', '').subscribe(() => {
                  });
                  }
                  if(guia.ENCFAC_NUMERO !== null  && guia.ENCFAC_NUMERO !== ''
                  && guia.ENCFAC_NUMERO !== undefined){
                      this.auditoriagral.registrarAuditoria('VEN_DETGRE',  String(guia.ENCGRE_NUMERO)+'/'+guia.ENCFAC_NUMERO+'/'+guia.DETGRE_CODIGO+ '/'+guia.DETGRE_CANTIDAD 
                  , 'A', 
                    '', '01', '', '', '', '').subscribe(() => {
                    });
                  }
              }
              if (guia.ENCPED_NUMERO !== '' && guia.ENCPED_NUMERO !== null) {
                let fecha=this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                  0, 10)
                fecha=fecha.toString().replaceAll(',','')

                // this.venenguiaRemisionService.actualizarDespachoPed(guia.ENCPED_NUMERO, guia.DETPED_LINEA).subscribe(() => {
                // });
                // this.venenguiaRemisionService.verificaEstadoPedidos(guia.ENCPED_NUMERO).subscribe(() => {
                // });
                // this.venenguiaRemisionService.actualizarFechaEntregaPedido(
                //   guia.ENCPED_NUMERO,
                //   this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                //     0, 10)).subscribe(() => {
                //     });
                //CDPJ
                await this.venenguiaRemisionService.actualizarDespachoPed(guia.ENCPED_NUMERO, guia.DETPED_LINEA).toPromise()
                await this.venenguiaRemisionService.verificaEstadoPedidos(guia.ENCPED_NUMERO).toPromise()
                await this.venenguiaRemisionService.actualizarFechaEntregaPedido(
                    guia.ENCPED_NUMERO,fecha).toPromise()
                  //CDPJ
                if (this.intACTPEDVAL === 1) {
                  // this.venenguiaRemisionService.actualizaFacturadoPedido(guia.ENCPED_NUMERO).subscribe(() => {
                  // });
                  // this.venenguiaRemisionService.verificaEstadoPedidosFact(guia.ENCPED_NUMERO).subscribe(() => {
                  // });
                  // this.venenguiaRemisionService.actualizarFechaEntregaPedido(guia.ENCPED_NUMERO,
                  //   this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                  //     0, 10)).subscribe(() => {
                  //     });
                  //CDPJ
                  await this.venenguiaRemisionService.actualizaFacturadoPedido(guia.ENCPED_NUMERO).toPromise()
                  await this.venenguiaRemisionService.verificaEstadoPedidosFact(guia.ENCPED_NUMERO).toPromise()
                  await  this.venenguiaRemisionService.actualizarFechaEntregaPedido(guia.ENCPED_NUMERO,fecha).toPromise()
                  //CDPJ
                }
              }
            //});
          };//);
          // this.venenguiaRemisionService.registrarKardex(this.codigoGuiaAux).subscribe(() => {
          //   if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === '' ||
          //     this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === null) {
          //     this.venenguiaRemisionService.generarAsientoContable(this.codigoGuiaAux,
          //       this.usuario.identificacion).subscribe(() => {
          //         this.venenguiaRemisionService.getAsiento(this.codigoGuiaAux).subscribe((res2) => {
          //           this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ASI_NRO = res2[0].ASI_NRO;
          //         });
          //       });
          //   }
          // });
          if (this.GUINOKARD === 0){//CDPJ
            this.venenguiaRemisionService.registrarKardex(this.codigoGuiaAux).subscribe(() => {
              if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === '' ||
                this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === null) {
                this.venenguiaRemisionService.generarAsientoContable(this.codigoGuiaAux,
                  this.usuario.identificacion).subscribe(() => {
                    this.venenguiaRemisionService.getAsiento(this.codigoGuiaAux).subscribe((res2) => {
                      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ASI_NRO = res2[0].ASI_NRO;
                    });
                  });
              }
            });
          }//CDPJ
          this.venenguiaRemisionService.actualizarDatos(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador],
            this.codigoGuiaAux).subscribe(() => {
            });
          
          this.venenguiaRemisionService.erCodigoEncabezado(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO).subscribe((tipoCli) => {
            if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
              this.disabledEstadisticas = true;
            } else {
              this.disabledEstadisticas = false;
            }
          });
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.auditoriagral.registrarAuditoria('VEN_ENCGRE',
            String(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO), 'A',
            '', '01', '', '', '', '').subscribe(() => {
            });
          let correoAd = '';
          this.venenguiaRemisionService.obtenerCorreo(
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO).subscribe((correo) => {
              correoAd = correo[0].CLI_CORREO;
              this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = [{
                ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                INFAD_NUMSEC: 0,
                LINEA: 1,
                COM_CODIGO: '01',
                INFAD_CODIGO: 'direccion',
                INFAD_DESCRIPCION: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_DIRECCIONDSTNTR
              }, {
                ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                INFAD_NUMSEC: 0,
                LINEA: 2,
                COM_CODIGO: '01',
                INFAD_CODIGO: 'correo',
                INFAD_DESCRIPCION: correoAd
              }, {
                ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                INFAD_NUMSEC: 0,
                LINEA: 3,
                COM_CODIGO: '01',
                INFAD_CODIGO: 'telefono',
                INFAD_DESCRIPCION: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TELEFONODSTNTR
              }];
            });
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO = 'PENDIENTE';
        }
      }
      this.venenguiaRemisionService.getEncGuia().subscribe((res1) => {
        this.encGuiaRemision = res1;
      });
      this.porcIvaBoolean = true;
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
    } catch (error) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(error.error);
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Inserción ',
        detail: 'No se pudo guardar la guia, error ' + error.error
      });
      this.spin=false;//CDPJ
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btnRegresar = true;
    }
    //GSRF
    this.messageService.add({
      key: 'guiaRemision',
      severity: 'success',
      summary: 'Actualizacion de la Guia ',
      detail: 'Guia actualizada correctamente'
    });
    this.spin=false;//CDPJ
    this.cancelarEdicionGuia();
  }

  async obtenerDatos() {
    // console.log(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador]);
    this.porcIvaBoolean = true;
    this.blocknum = true;//GSRF
    this.ventasboolean = false;
    this.comprasboolean = false;
    this.consigboolean = false;
    this.trasladoboolean = false;
    this.devboolean = false;
    this.impboolean = false;
    this.expboolean = false;
    this.otrosboolean = false;
    this.transboolean = false;
    this.bolestcta = false;
    this.bolsaldo = false;
    //CDPJ
    for (const j of this.opcionIva) {
      if (Number(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PORCEIVA) === Number(j.name)) {
        this.selectcombtipoIva = { codigo: j.codigo, name: j.name };
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PORCEIVA =Number(this.selectcombtipoIva.name);
      }
    }
    let porcentaje=this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PORCEIVA
    let data1=this.confIniciales.srifetariiva.find(element => Number(element.TARIIVA_PORCENTAJE) === Number(porcentaje))
    this.venenguiaRemisionService.porcetariiva=Number(porcentaje);
    this.venenguiaRemisionService.codtariiva=data1.TARIIVA_CODIGO;
    //CDPJ

    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE !== '' &&
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE !== null) {
      this.botonAnular = true;
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE === '' ||
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE === null) {
      this.botonAnular = false;
    }
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'P' ||
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'PENDIENTE') {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO = 'PENDIENTE';
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal').style.opacity = '1.0';
      //document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal2').style.opacity = '1.0';
      this.aggrid.habilitarGrid();
      this.btnBorrar = false;
      this.botonXML = false;
      //CDPJ
      
      if(this.CNAFEPV === 1){
        const data= await this.venenguiaRemisionService.obtenerNumFE(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_GRUPO)
         if(data !== null && data !== undefined){
           for(const rs of data){
             if(rs.NUM_FE === 1){
               this.botonXML = false;
             }else if(rs.NUM_FE === 0) {
               this.botonXML = true;
             }
           }
         }else{
           this.botonXML = false;
         }
       }
       //CDPJ
      this.btnOpenFact = true;
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'F' ||
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'FACTURADA') {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO = 'FACTURADA';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      this.aggrid.bloquearGrid();
      this.btnBorrar = true;
      this.botonXML = false;
      //CDPJ
      if(this.CNAFEPV === 1){
        const data= await this.venenguiaRemisionService.obtenerNumFE(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_GRUPO)
         if(data !== null && data !== undefined){
           for(const rs of data){
             if(rs.NUM_FE === 1){
               this.botonXML = false;
             }else if(rs.NUM_FE === 0) {
               this.botonXML = true;
             }
           }
         }else{
           this.botonXML = false;
         }
       }
       //CDPJ
      this.btnOpenFact = true;
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'A' ||
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'ANULADA') {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO = 'ANULADA';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      //document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      this.aggrid.bloquearGrid();
      this.btnBorrar = true;
      this.botonXML = true;
      this.btnOpenFact = true;
    }
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION === null) {
      this.fechaBoolean = false;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION = new Date();
      this.venenguiaRemisionService.horaSistema = new Date();
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION !== '') {
      this.fechaBoolean = true;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION = new Date(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION);
        this.venenguiaRemisionService.horaSistema = new Date(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION);

    }
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_MOTIVO !== undefined) {
      const arregloChecks = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_MOTIVO.split(';');
      for (let i = 0; i < arregloChecks.length - 1; i++) {
        if (arregloChecks[i] === '01') {
          this.ventasboolean = true;
        }
        if (arregloChecks[i] === '02') {
          this.comprasboolean = true;
        }
        if (arregloChecks[i] === '04') {
          this.consigboolean = true;
        }
        if (arregloChecks[i] === '06') {
          this.trasladoboolean = true;
        }
        if (arregloChecks[i] === '07') {
          this.devboolean = true;
        }
        if (arregloChecks[i] === '08') {
          this.impboolean = true;
        }
        if (arregloChecks[i] === '09') {
          this.expboolean = true;
        }
        if (arregloChecks[i] === '10') {
          this.otrosboolean = true;
        }
        if (arregloChecks[i] === '11') {
          this.transboolean = true;
        }
      }
    }
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TOTALNETO = Number(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TOTALNETO).toFixed(this.confIniciales.getNumDecSist());
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORDES = Number(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORDES).toFixed(this.confIniciales.getNumDecSist());
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PORCEDES = Number(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PORCEDES).toFixed(this.confIniciales.getNumDecSist());
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASEIVA = Number(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASEIVA).toFixed(this.confIniciales.getNumDecSist());
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORIVA = Number(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORIVA).toFixed(this.confIniciales.getNumDecSist());
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASEICE = Number(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASEICE).toFixed(this.confIniciales.getNumDecSist());
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORICE = Number(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORICE).toFixed(this.confIniciales.getNumDecSist());
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASECERO = Number(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_BASECERO).toFixed(this.confIniciales.getNumDecSist());
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TOTAL = Number(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TOTAL).toFixed(this.confIniciales.getNumDecSist());
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO === null) {
      this.fechaBoolean = false;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO = new Date();
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO !== '') {
      this.fechaBoolean = true;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO = new Date(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO);
    }
    this.codigoGuiaAux = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO;
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2 =
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO;
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO =
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN + ' ' +
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO;
//CDPJ
let origen = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN;
let posicion = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2.toString().indexOf(origen);
if (posicion !== -1) {

 // console.log('el if', this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO);

  this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO.replace(origen, ' ').trim();
  this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO.replace(origen, ' ').trim();
  this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2 = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2.replace(origen, ' ').trim();
  //console.log('el if2', this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO);
  this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2 =
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO;

  this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO =
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN + ' ' +
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO;
}
//CDPJ
if(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === null || 
  this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === undefined){
    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO=''
  }
//CDPJ
//CDPJ







    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO === null) {
      this.fechaBoolean = false;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO = new Date();
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO !== '') {
      this.fechaBoolean = true;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO = new Date(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO);
    }
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_FECHAEMISION === null) {
      this.fechaBoolean = false;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_FECHAEMISION = new Date();
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_FECHAEMISION !== '') {
      this.fechaBoolean = true;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_FECHAEMISION = new Date(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_FECHAEMISION);
    }
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI === null) {
      this.fechaBoolean = false;
      this.horaInicio = new Date();
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI !== '') {
      this.fechaBoolean = true;
      this.horaInicio = new Date();
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI.toString().length === 4) {
        const separador = ':';
        const arregloHora = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI.toString().split(separador);
        for (let i = 0; i < arregloHora.length; i++) {
          if (arregloHora[i].length !== 2) {
            arregloHora[i] = '0' + arregloHora[i];
          }
        }
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI = arregloHora.join(':');
      } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI.toString().length === 3) {
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI = '0' +
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI.toString();
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI =
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI.toString().substr(0, 2) + '0'
          + this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI.toString().substr(3, 1);
      }
      this.horaInicio.setHours(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI.substr(0, 2));
      this.horaInicio.setMinutes(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAINI.substr(3, 2));
    }

    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN === null) {
      this.fechaBoolean = false;
      this.horaFin = new Date();
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN !== '') {
      this.fechaBoolean = true;
      this.horaFin = new Date();
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN.toString().length === 4) {
        const separador = ':';
        const arregloHora = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN.toString().split(separador);
        for (let i = 0; i < arregloHora.length; i++) {
          if (arregloHora[i].length !== 2) {
            arregloHora[i] = '0' + arregloHora[i];
          }
        }
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN = arregloHora.join(':');
      } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN.toString().length === 3) {
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN = '0' +
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN.toString();
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN =
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN.toString().substr(0, 2) + '0'
          + this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN.toString().substr(3, 1);
      }
      this.horaFin.setHours(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN.substr(0, 2));
      this.horaFin.setMinutes(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_HORAFIN.substr(3, 2));
    }
    this.venenguiaRemisionService.dblPorcDescGlbGrearray[this.indicador] = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[
      this.indicador].ENCGRE_PORCEDES;
    this.venenguiaRemisionService.dblValorDescGlbGrearray[this.indicador] = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[
      this.indicador].ENCGRE_VALORDES;
    this.venenguiaRemisionService.obtenerNombreCli(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO).subscribe
      ((res) => {
        if (res.length !== 0) {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NMBRDSTNTR = res[0].CLI_NOMBRE;
        }
      });

    this.venenguiaRemisionService.erCodigoEncabezado(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO).subscribe((tipoCli) => {
      if (String(tipoCli[0].CLI_TIPOIDE) === '6') {
        this.disabledEstadisticas = true;
      } else {
        this.disabledEstadisticas = false;
      }
    });

    this.venenguiaRemisionService.obtenerNombreTransport(
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].TRANSPORT_CODIGO).subscribe((res) => {
        if (res.length !== 0) {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NMBRTRNSP = res[0].TRANSPORT_NOMBRE;
        }
      });
    this.venenguiaRemisionService.comas();
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO !== '') {
      // this.venenguiaRemisionService.getDetGuia(this.codigoGuiaAux).subscribe((res) => {
      this.venenguiaRemisionService.getDetGuia(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[
        this.indicador].ENCGRE_NUMERO).subscribe(async (res) => {//ANTES ENCGRE_CODIGO2 CDPJ
          if (res !== null && res.length !== 0) {
            this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = res;
            for (const guia of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]){
              //this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]//.map(async (guia) => {
                guia.DETGRE_PRECIO = guia.DETGRE_PRECIO.toFixed(this.decimalesPrecioGre);
                guia.DETGRE_CANTIDAD = guia.DETGRE_CANTIDAD.toFixed(this.decimalesCantidadGre);
                guia.DETGRE_TOTAL = Number(guia.DETGRE_TOTAL).toFixed(this.decimalesTotalGre);
                guia.DETGRE_DESCUENTO = Number(guia.DETGRE_DESCUENTO).toFixed(this.decimalesDescGre);
                guia.LINEA=guia.DETGRE_LINEA;//CDPJ
                guia.CEN_CODIGO=guia.CEN_CODIGO === undefined || guia.CEN_CODIGO === null?'':guia.CEN_CODIGO;
                const res = await this.venenguiaRemisionService.validarExistencias(guia.DETGRE_CODIGO,guia.BOD_CODIGO);
                for (const existArt of res) {      
                 guia.EXISTENCIA=existArt.EXISTENCIA
                }
              }//);
            //this.detalleGuiaSeleccionado = this.venenguiaRemisionService.detguiaRemisionarray[this.indicador][0];// COMENTÉ CDPJ
          }else{
            this.spin=false;//CDPJ
          }
          this.venenguiaRemisionService.obtenerInfoAdic(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO).subscribe
            ((infoAdic) => {
              if (infoAdic.length === 0) {
                let correoAd = '';
                this.venenguiaRemisionService.obtenerCorreo(
                  this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO).subscribe((correo) => {
                    if (correo.length !== 0) {
                      correoAd = correo[0].CLI_CORREO;
                      this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = [{
                        ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                        INFAD_NUMSEC: 0,
                        LINEA: 1,
                        COM_CODIGO: '01',
                        INFAD_CODIGO: 'direccion',
                        INFAD_DESCRIPCION: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_DIRECCIONDSTNTR
                      }, {
                        ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                        INFAD_NUMSEC: 0,
                        LINEA: 2,
                        COM_CODIGO: '01',
                        INFAD_CODIGO: 'correo',
                        INFAD_DESCRIPCION: correoAd
                      }, {
                        ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                        INFAD_NUMSEC: 0,
                        LINEA: 3,
                        COM_CODIGO: '01',
                        INFAD_CODIGO: 'telefono',
                        INFAD_DESCRIPCION: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TELEFONODSTNTR
                      }];
                    }
                  });
              } else if (infoAdic.length !== 0) {
                this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = infoAdic;
              }
            });
            
            this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);
        });
    }

  }

  crearNuevaGuia(parametro) {
    this.llenarcombo();//CDPJ
    if (parametro === 'N') { // N para nuevo
      if (this.generarNumeracion()) {
      }
      this.blocknum = false;//GSRF
      this.btnNuevo = true;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal').style.opacity = '1.0';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'all';
      // document.getElementById('divPrincipal2').style.opacity = '1.0';
      this.aggrid.habilitarGrid();

      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
      this.btnOpenFact = false;
      this.tipodetalle = this.TIPDET === null || this.TIPDET=== undefined?'A':this.TIPDET;
      document.getElementById('ptoPartida').focus();
      this.indice = -1;
      let encGuiaRemision: VenencGuiaRemision;
      encGuiaRemision = {
        ASI_NRO: '',
        BOD_CODIGO: '001',
        CIU_CODIGO: '',
        CLI_CODIGO: '',
        COM_CODIGO: '',
        COM_TIPOAMBFACTELEC: '',
        ENCFAC_COMCODIGO: '',
        ENCFAC_NUMERO: '',
        ENCGRE_AUTORIZACION_FE: '',
        ENCGRE_AUTOTNTR: '',
        ENCGRE_BASECERO: 0,
        ENCGRE_BASEICE: 0,
        ENCGRE_BASEIVA: 0,
        ENCGRE_CLAVEACCESO_FE: '',
        ENCGRE_CODIGO: '',
        ENCGRE_COMENTARIO: '',
        ENCGRE_DIRECCIONDSTNTR: '',
        ENCGRE_ESTADO: 'PENDIENTE',
        ENCGRE_ESTADO_FE: '',
        ENCGRE_FACTURAFIN: '',
        ENCGRE_FECHAAUT_FE: new Date(),
        ENCGRE_FECHAEMISION: new Date(),
        ENCGRE_FECHFINTRASLADO: new Date(),
        ENCGRE_FECINITRASLADO: new Date(),
        ENCGRE_GRUPO: '',
        ENCGRE_HORAFIN: '',
        ENCGRE_HORAINI: '',
        ENCGRE_IVA: '',
        ENCGRE_LISTA: '',
        ENCGRE_MOTIVO: '01;',
        ENCGRE_MOTIVOLETRAS: '',
        ENCGRE_NMBRDSTNTR: '',
        ENCGRE_NMBRTRNSP: '',
        ENCGRE_NUMERO: '',
        ENCGRE_NUMFACTELEC: '',
        ENCGRE_NUMGUIPE: '',
        ENCGRE_NUMREF: '',
        ENCGRE_OBRA: '',
        ENCGRE_OBSERVACION: '',
        ENCGRE_ORIGEN: '',
        ENCGRE_PATHPDF_FE: '',
        ENCGRE_PATHXMLNOAUTO_FE: '',
        ENCGRE_PLACATNTR:
          '',
        ENCGRE_PORCEDES: 0,
        ENCGRE_PORCEICE: 0,
        ENCGRE_PORCEIVA: Number(this.selectcombtipoIva.name),
        ENCGRE_PTOLLEGADA: '',
        ENCGRE_PTOPARTIDA: '',
        ENCGRE_REFERENCIA: '',
        ENCGRE_RUCDSTNTR: '',
        ENCGRE_RUCTRNSP: '',
        ENCGRE_SELLOINFERIOR: '',
        ENCGRE_SELLOSUPERIOR: '',
        ENCGRE_TELEFONODSTNTR: '',
        ENCGRE_TIPODES: '',
        ENCGRE_TONELAJE: '',
        ENCGRE_TOTAL: 0,
        ENCGRE_TOTALNETO: 0,
        ENCGRE_VALORDES: 0,
        ENCGRE_VALORICE: 0,
        ENCGRE_VALORIVA: 0,
        TRANSPORT_CODIGO: '',
        TRNPLA_CODIGO: '',
        TRNPLA_LINEA: 0,
        ENCGRE_CODIGO2: '',
        ENCGRE_COOLERSERIAL: '',
      };
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = encGuiaRemision;
      this.venenguiaRemisionService.obtenerBodega().subscribe((resbod) => {
        this.nombrebodega = resbod[0].REF_NOMBRE;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].BOD_CODIGO = resbod[0].REF_CODIGO;
      });
      this.venenguiaRemisionService.dblPorcDescGlbGrearray[this.indicador] =
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PORCEDES;
      this.venenguiaRemisionService.dblValorDescGlbGrearray[this.indicador] =
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_VALORDES;
      /*this.venenguiaRemisionService.obtenernuevoNumero().subscribe((res) => {
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO = String(res[0].NUM_SERIE) +
          String(Number(res[0].NUM_SECACTUAL) + 1);
        this.numeroActual = Number(res[0].NUM_SECACTUAL); 
      }); */
      this.ventasboolean = true;
      this.comprasboolean = false;
      this.consigboolean = false;
      this.devboolean = false;
      this.expboolean = false;
      this.impboolean = false;
      this.otrosboolean = false;
      this.transboolean = false;
      this.trasladoboolean = false;
      this.venenguiaRemisionService.horaSistema = new Date();
      this.horaInicio = new Date();
      this.horaFin = new Date();
      this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = [];
      this.bolestcta = false;
      this.bolsaldo = false;
    } else if (parametro === 'C') { // C para cancelar
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
      this.aggrid.bloquearGrid();
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = true;
      this.botonUltimo = false;
      this.botonAnterior = false;
      this.botonPrimero = false;
      this.botonSiguiente = false;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = {};
      this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = [];
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION = new Date();
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECINITRASLADO = new Date();
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHFINTRASLADO = new Date();
    }
  }

  confirmarGuia() {
    ///////cambio autorizacion
    console.log(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE);
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE !== '' &&
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE !== null) {
      return this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede eliminar una guía de remisión'
          + ' que tiene autorización.'
      });
    } else {
      this.confirmationService.confirm({
        message: 'Esta seguro de eliminar la guía de remisión',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarGuia',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: async () => {
          this.confirmationService.close();
           //CDPJ
      let encpedestado='';
      const data = await this.venenguiaRemisionService.obtenerEncgreEstado(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO);
      if(data !== null && data !== undefined){
        for(const rs1 of data){
          encpedestado=rs1.ENCGRE_ESTADO
        }
      }
    //CDPJ
    console.log('encpedestado',encpedestado)
    if(encpedestado === 'F'){
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Eliminar Guía',
        detail: 'La Guía ya se encuentra en estado \'FACTURADA\''
      });
      this.spin=false;//CDPJ
      this.spin=false;
      this.cancelarEdicionGuia();
      return;
    }else{
      this.eliminarGuia();
    }
          //this.eliminarGuia();
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  eliminarGuia() {

    if (this.permisoGre.PERELIMACION === 0) {
      return this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Error',
        detail: 'Ha sido imposible eliminar la guía de remisión debido a que usted no posee permisos de eliminación'
      });

    }

    // if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ORIGEN !== 'GRE') {
    //   return this.messageService.add({
    //     key: 'guiaRemision',
    //     severity: 'error',
    //     summary: 'Error',
    //     detail: 'Esta guía de remisión ha sido generada desde otra aplicación y no puede ser borrada desde aqui'
    //   });
    //
    // }

    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'ANULADA') {
      return this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Error',
        detail: 'No es posible eliminar una guía de remisión que ha sido anulada'
      });

    }

    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] !== undefined) {
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO2 === '') {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'success',
          summary: 'Eliminación de Guía',
          detail: 'Guía de Remisión  eliminada correctamente'
        });

        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = {
          ASI_NRO: '',
          BOD_CODIGO: '',
          CIU_CODIGO: '',
          CLI_CODIGO: '',
          COM_CODIGO: '',
          COM_TIPOAMBFACTELEC: '',
          ENCFAC_COMCODIGO: '',
          ENCFAC_NUMERO: '',
          ENCGRE_AUTORIZACION_FE: '',
          ENCGRE_AUTOTNTR: '',
          ENCGRE_BASECERO: 0,
          ENCGRE_BASEICE: 0,
          ENCGRE_BASEIVA: 0,
          ENCGRE_CLAVEACCESO_FE: '',
          ENCGRE_CODIGO: '',
          ENCGRE_COMENTARIO: '',
          ENCGRE_DIRECCIONDSTNTR: '',
          ENCGRE_ESTADO: '',
          ENCGRE_ESTADO_FE: '',
          ENCGRE_FACTURAFIN: '',
          ENCGRE_FECHAAUT_FE: '',
          ENCGRE_FECHAEMISION: '',
          ENCGRE_FECHFINTRASLADO: '',
          ENCGRE_FECINITRASLADO: '',
          ENCGRE_GRUPO: '',
          ENCGRE_HORAFIN: '',
          ENCGRE_HORAINI: '',
          ENCGRE_IVA: '',
          ENCGRE_LISTA: '',
          ENCGRE_MOTIVO: '01;',
          ENCGRE_MOTIVOLETRAS: '',
          ENCGRE_NMBRDSTNTR: '',
          ENCGRE_NMBRTRNSP: '',
          ENCGRE_NUMERO: '',
          ENCGRE_NUMFACTELEC: '',
          ENCGRE_NUMGUIPE: '',
          ENCGRE_NUMREF: '',
          ENCGRE_OBRA: '',
          ENCGRE_OBSERVACION: '',
          ENCGRE_ORIGEN: '',
          ENCGRE_PATHPDF_FE: '',
          ENCGRE_PATHXMLNOAUTO_FE: '',
          ENCGRE_PLACATNTR: '',
          ENCGRE_PORCEDES: 0,
          ENCGRE_PORCEICE: 0,
          ENCGRE_PORCEIVA: Number(this.selectcombtipoIva.name),
          ENCGRE_PTOLLEGADA: '',
          ENCGRE_PTOPARTIDA: '',
          ENCGRE_REFERENCIA: '',
          ENCGRE_RUCDSTNTR: '',
          ENCGRE_RUCTRNSP: '',
          ENCGRE_SELLOINFERIOR: '',
          ENCGRE_SELLOSUPERIOR: '',
          ENCGRE_TELEFONODSTNTR: '',
          ENCGRE_TIPODES: '',
          ENCGRE_TONELAJE: 0,
          ENCGRE_TOTAL: 0,
          ENCGRE_TOTALNETO: 0,
          ENCGRE_VALORDES: 0,
          ENCGRE_VALORICE: 0,
          ENCGRE_VALORIVA: 0,
          TRANSPORT_CODIGO: 0,
          TRNPLA_CODIGO: '',
          TRNPLA_LINEA: 0,
        };
        this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = [];
        this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = [];
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
        this.aggrid.bloquearGrid();
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = true;
        this.btnRegresar = true;
        this.botonAnterior = false;
        this.botonPrimero = false;
        this.botonSiguiente = false;
        this.botonUltimo = false;
        this.venenguiaRemisionService.getEncGuia().subscribe((res1) => {
          this.encGuiaRemision = res1;
        });
      } else {
        //  this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO = this.codigoGuiaAux;
        this.venenguiaRemisionService.eliminarEnc(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador]).subscribe(async () => {
          //CG
          for (const guia of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
            try {
              if (guia.ENCPED_NUMERO !== '' && guia.ENCPED_NUMERO !== null) {
                let fecha=this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                  0, 10)
                fecha=fecha.toString().replaceAll(',','')
  
                // this.venenguiaRemisionService.actualizarDespachoPed(guia.ENCPED_NUMERO, guia.DETPED_LINEA).subscribe(() => {
                // });
                // this.venenguiaRemisionService.verificaEstadoPedidos(guia.ENCPED_NUMERO).subscribe(() => {
                // });
                // this.venenguiaRemisionService.actualizarFechaEntregaPedido(guia.ENCPED_NUMERO,
                //   this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                //     0, 10)).subscribe(() => {
                //     });
                //CDPJ
                await this.venenguiaRemisionService.actualizarDespachoPed(guia.ENCPED_NUMERO, guia.DETPED_LINEA).toPromise()
                await this.venenguiaRemisionService.verificaEstadoPedidos(guia.ENCPED_NUMERO).toPromise()
                await this.venenguiaRemisionService.actualizarFechaEntregaPedido(guia.ENCPED_NUMERO,fecha).toPromise()
                //CDPJ
                if (this.intACTPEDVAL === 1) {
                  // this.venenguiaRemisionService.actualizaFacturadoPedido(guia.ENCPED_NUMERO).subscribe(() => {
                  // });
                  // this.venenguiaRemisionService.verificaEstadoPedidosFact(guia.ENCPED_NUMERO).subscribe(() => {
                  // });
                  // this.venenguiaRemisionService.actualizarFechaEntregaPedido(guia.ENCPED_NUMERO,
                  //   this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                  //     0, 10)).subscribe(() => {
                  //     });
                  //CDPJ
                  await this.venenguiaRemisionService.actualizaFacturadoPedido(guia.ENCPED_NUMERO).toPromise()
                  await this.venenguiaRemisionService.verificaEstadoPedidosFact(guia.ENCPED_NUMERO).toPromise()
                  await this.venenguiaRemisionService.actualizarFechaEntregaPedido(guia.ENCPED_NUMERO,fecha).toPromise()
                  //CDPJ
                }
              }
            } catch (error) {
              this.spin=false;
            }
            
          }
          //CG
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'success',
            summary: 'Eliminación de Guía',
            detail: 'Guía de Remisión  eliminada correctamente'
          });
          this.auditoriagral.registrarAuditoria('VEN_ENCGRE', String(
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO),
            'E', '', '01', '', '', '', '').subscribe(() => {
            });
          this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].map(() => {
            this.auditoriagral.registrarAuditoria('VEN_DETGRE', String(
              this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO)
              , 'E', '', '01', '', '', '', '').subscribe(() => {
              });
          });
          this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].map(() => {
            this.auditoriagral.registrarAuditoria('VEN_INFOADICGRE', String(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador]
              .ENCGRE_NUMERO), 'E', '', '01', '', '', '', '').subscribe(() => {
              });
          });
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = {
            ASI_NRO: '',
            BOD_CODIGO: '',
            CIU_CODIGO: '',
            CLI_CODIGO: '',
            COM_CODIGO: '',
            COM_TIPOAMBFACTELEC: '',
            ENCFAC_COMCODIGO: '',
            ENCFAC_NUMERO: '',
            ENCGRE_AUTORIZACION_FE: '',
            ENCGRE_AUTOTNTR: '',
            ENCGRE_BASECERO: 0,
            ENCGRE_BASEICE: 0,
            ENCGRE_BASEIVA: 0,
            ENCGRE_CLAVEACCESO_FE: '',
            ENCGRE_CODIGO: '',
            ENCGRE_COMENTARIO: '',
            ENCGRE_DIRECCIONDSTNTR: '',
            ENCGRE_ESTADO: '',
            ENCGRE_ESTADO_FE: '',
            ENCGRE_FACTURAFIN: '',
            ENCGRE_FECHAAUT_FE: '',
            ENCGRE_FECHAEMISION: '',
            ENCGRE_FECHFINTRASLADO: '',
            ENCGRE_FECINITRASLADO: '',
            ENCGRE_GRUPO: '',
            ENCGRE_HORAFIN: '',
            ENCGRE_HORAINI: '',
            ENCGRE_IVA: '',
            ENCGRE_LISTA: '',
            ENCGRE_MOTIVO: '01;',
            ENCGRE_MOTIVOLETRAS: '',
            ENCGRE_NMBRDSTNTR: '',
            ENCGRE_NMBRTRNSP: '',
            ENCGRE_NUMERO: '',
            ENCGRE_NUMFACTELEC: '',
            ENCGRE_NUMGUIPE: '',
            ENCGRE_NUMREF: '',
            ENCGRE_OBRA: '',
            ENCGRE_OBSERVACION: '',
            ENCGRE_ORIGEN: '',
            ENCGRE_PATHPDF_FE: '',
            ENCGRE_PATHXMLNOAUTO_FE: '',
            ENCGRE_PLACATNTR: '',
            ENCGRE_PORCEDES: 0,
            ENCGRE_PORCEICE: 0,
            ENCGRE_PORCEIVA: Number(this.selectcombtipoIva.name),
            ENCGRE_PTOLLEGADA: '',
            ENCGRE_PTOPARTIDA: '',
            ENCGRE_REFERENCIA: '',
            ENCGRE_RUCDSTNTR: '',
            ENCGRE_RUCTRNSP: '',
            ENCGRE_SELLOINFERIOR: '',
            ENCGRE_SELLOSUPERIOR: '',
            ENCGRE_TELEFONODSTNTR: '',
            ENCGRE_TIPODES: '',
            ENCGRE_TONELAJE: 0,
            ENCGRE_TOTAL: 0,
            ENCGRE_TOTALNETO: 0,
            ENCGRE_VALORDES: 0,
            ENCGRE_VALORICE: 0,
            ENCGRE_VALORIVA: 0,
            TRANSPORT_CODIGO: 0,
            TRNPLA_CODIGO: '',
            TRNPLA_LINEA: 0,
          };
          this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = [];
          this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = [];
          // this.btnNuevo = false;
          // this.btnGuardar = true;
          // this.btnBorrar = false;
          // this.btnRegresar = true;
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = true;
          this.btnRegresar = true;
          this.botonAnterior = false;
          this.botonPrimero = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.venenguiaRemisionService.getEncGuia().subscribe((res1) => {
            this.encGuiaRemision = res1;
          });
        });
      }
    } else {
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Eliminación de Guía',
        detail: 'Seleccione una guía para ser eliminada'
      });
    }
  }

  cancelarEdicionGuia() {
    console.log('refresca');
    
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO === '' && this.btnNuevo === true) {
      this.crearNuevaGuia('C');
      this.spin=false;//CDPJ
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO !== '') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.blocknum = true;//GSRF
      this.guiaCancelada();
    }
  }

  guiaCancelada() {
    this.venenguiaRemisionService.getEncGuia().subscribe((res) => {
      res.map((guia) => {
        if (guia.ENCGRE_NUMERO === this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO) {
          this.encabezadoGuiaSeleccionadaAux = guia;
        }else{
          this.spin=false;//CDPJ
        }
      });
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encabezadoGuiaSeleccionadaAux;
      this.obtenerDatos();
    });
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
  }

  abrirBodega() {
    this.opcion = 'BUSQUEDABOD';
    this.types = [
      { label: 'Código', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' }
    ];
    this.busquedacampos = [this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].BOD_CODIGO, ''];
    this.tabla = 'inv_maebodega';
    this.consulta = '*';
    this.where=''
    this.busqService.busquedaInvMaeBodega1(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].BOD_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirFactura() {
    this.opcion = 'BUSQUEDAFAC';
    this.types = [
      { label: 'NÚMERO', value: 'ENCFAC_NUMERO' },
      { label: 'F_EMISIÓN', value: 'ENCFAC_FECHAEMISION' },
      { label: 'ESTADO', value: 'ENCFAC_ESTADO' },
      { label: 'COD_CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE_CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'RUC', value: 'ENCFAC_RUCIDE' },
      { label: 'BODEGA', value: 'BOD_CODIGO' },
      { label: 'VENDEDOR', value: 'VEN_CODIGO' },
      { label: 'TOTAL', value: 'ENCFAC_TOTAL' },
      { label: 'NETO', value: 'ENCFAC_TOTALNETO' },
    ];
    this.busquedacampos = [this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO, '', '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCFAC';
    this.consulta = '*';
    this.where=' ENCFAC_NUMERO is not null order by ENCFAC_FECHAEMISION desc '
    this.busqService.busquedaVenEncfac2(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.ENCFAC_FECHAEMISION !== null) {
          registro.ENCFAC_FECHAEMISION = this.datePipe.transform(registro.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCFAC_FECHAEMISION = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirClientes() {
    this.opcion = 'BUSQUEDACLI';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RUCIDE', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'ZONA_NOMBRE' },
      { label: 'Vendedor', value: 'VEN_NOMBRE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'Nombreec', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.consulta = 'ven_maecliente.*,  VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO) AS' +
      ' VEN_NOMBRE, VEN_FNC_OBTENER_ZONACLI(CLI_ZONA) AS ZONA_NOMBRE';
      this.where=''
    this.busqService.busquedaVenMaeCliente1(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirTransportista() {
    this.opcion = 'BUSQUEDATRANSPORT';
    this.types = [
      { label: 'CÓDIGO', value: 'TRANSPORT_CODIGO' },
      { label: 'NOMBRE', value: 'TRANSPORT_NOMBRE' },
      { label: 'RUC', value: 'TRANSPORT_RUC' },
      { label: 'TELEFONO1', value: 'TRANSPORT_TELEFONO1' },
      { label: 'TELEFONO2', value: 'TRANSPORT_TELEFONO2' },
      { label: 'DIRECCIÓN', value: 'TRANSPORT_DIRECCION' }
    ];
    this.busquedacampos = [this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].TRANSPORT_CODIGO, '', '', '', '', ''];
    this.tabla = 'ven_maetransport';
    this.consulta = '*';
    this.where=''
    this.busqService.busquedaVenMaeTranport1(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].TRANSPORT_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirReferencia() {
    this.opcion = 'BUSQUEDAREF';
    this.types = [
      { label: 'CODIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE', value: 'REF_NOMBRE' }
    ];
    this.busquedacampos = [this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_REFERENCIA, ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.consulta = '*';
    this.where=''
    this.busqService.busquedaSaciMaeReferencia6('ref_tipo = \'PRYEST\' and ' +
      this.types[0].value + ' LIKE \'%' +
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_REFERENCIA + '%\'').subscribe((datos: any[]) => {
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
      });
  }

  buscarGuia() {
    let condicionbus='';//CDPJ
    this.opcion = 'BUSCAR';
    this.types = [
      { label: 'CODIGO', value: 'ENCGRE_CODIGO' },
      { label: 'NÚMERO', value: 'ENCGRE_NUMERO' },
      { label: 'FECHA EMISIÓN', value: 'ENCGRE_FECHAEMISION' },
     // { label: 'TRANSPORTISTA', value: 'TRANSPORT_NOMBRE' },
      { label: 'COD_CLIENTE', value: 'CLI_CODIGO' },
      { label: 'NOMBRE CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'TOTAL', value: 'ENCGRE_TOTAL' },
      { label: 'FACTURA', value: 'ENCFAC_NUMERO' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_ENCGRE';
    this.consulta = 'ENCGRE_CODIGO, ENCGRE_NUMERO, ENCGRE_FECHAEMISION, CLI_CODIGO, ' +
      'ENCGRE_TOTAL,ENCFAC_NUMERO, TRANSPORT_CODIGO, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE';

    this.where='ENCGRE_CODIGO IS NOT NULL ORDER BY ENCGRE_FECHAEMISION DESC'
    if(this.DVBUSGR === 1){
    if(this.venenguiaRemisionService.tipofiltrobus === 'gr'){
      condicionbus='  NVL(SUBSTR(ENCGRE_NUMERO,0,2),\'Z\')<>\'NE\''
    }else if(this.venenguiaRemisionService.tipofiltrobus === 'ne'){
      condicionbus='  NVL(SUBSTR(ENCGRE_NUMERO,0,2),\'Z\')=\'NE\''
    }else{
      condicionbus=''
    }
    if(condicionbus !== ''){
      this.busqService.busquedaVenEncgreB(condicionbus).subscribe((res: any[]) => {
        this.arregloCons = res;
        this.arregloCons.map((registro) => {
          if (registro.ENCGRE_FECHAEMISION !== null) {
            registro.ENCGRE_FECHAEMISION = this.datePipe.transform(registro.ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
          } else {
            registro.ENCGRE_FECHAEMISION = '';
          }
          // this.venenguiaRemisionService.obtenerNombreTransport(registro.TRANSPORT_CODIGO).subscribe((res2) => {
          //   registro.TRANSPORT_NOMBRE = res2[0].TRANSPORT_NOMBRE;
          // });
        });
        this.displayDialogBusquedaFast = true;
      });
      this.spin=false;
    }else{
      this.busqService.busquedaVenEncgre2().subscribe((res: any[]) => {
        this.arregloCons = res;
        this.arregloCons.map((registro) => {
          if (registro.ENCGRE_FECHAEMISION !== null) {
            registro.ENCGRE_FECHAEMISION = this.datePipe.transform(registro.ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
          } else {
            registro.ENCGRE_FECHAEMISION = '';
          }
          // this.venenguiaRemisionService.obtenerNombreTransport(registro.TRANSPORT_CODIGO).subscribe((res2) => {
          //   registro.TRANSPORT_NOMBRE = res2[0].TRANSPORT_NOMBRE;
          // });
        });
        this.displayDialogBusquedaFast = true;
      });
      this.spin=false;
    }
  }else{
    this.busqService.busquedaVenEncgre2().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((registro) => {
        if (registro.ENCGRE_FECHAEMISION !== null) {
          registro.ENCGRE_FECHAEMISION = this.datePipe.transform(registro.ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          registro.ENCGRE_FECHAEMISION = '';
        }
        // this.venenguiaRemisionService.obtenerNombreTransport(registro.TRANSPORT_CODIGO).subscribe((res2) => {
        //   registro.TRANSPORT_NOMBRE = res2[0].TRANSPORT_NOMBRE;
        // });
      });
      this.displayDialogBusquedaFast = true;
    });
    this.spin=false;
  }
    this.spin=false;
  }
 //GSRF  
 buscarGuianofirma() {
  this.opcion = 'BUSCAR';
  this.types = [
    { label: 'ESTADO_FE', value: 'ENCGRE_ESTADO_FE' },
    { label: 'FECHA EMISIÓN', value: 'ENCGRE_FECHAEMISION' },
    { label: 'CODIGO', value: 'ENCGRE_CODIGO' },
    { label: 'NÚMERO', value: 'ENCGRE_NUMERO' },
    { label: 'CLAVE_ACCESO', value: 'ENCGRE_CLAVEACCESO_FE' },
    { label: 'TOTAL', value: 'ENCGRE_TOTAL' },
    { label: 'FACTURA', value: 'ENCFAC_NUMERO' },
  ];
  this.busquedacampos = ['', '', '', '', '', '', ''];
  this.tabla = 'VEN_ENCGRE';
  this.consulta = 'ENCGRE_CODIGO, ENCGRE_NUMERO, ENCGRE_FECHAEMISION, CLI_CODIGO, ' +
    'ENCGRE_TOTAL,ENCFAC_NUMERO, TRANSPORT_CODIGO, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE,ENCGRE_ESTADO_FE,ENCGRE_CLAVEACCESO_FE';
  this.where='ENCGRE_CODIGO IS NOT NULL and (ENCGRE_ESTADO_FE NOT IN (\'NT\',\'SN\') or ENCGRE_ESTADO_FE is null)AND ENCGRE_ESTADO !=\'A\' ORDER BY ENCGRE_FECHAEMISION DESC'
  this.busqService.busquedaVenEncgrenofirma().subscribe((res: any[]) => {
    this.arregloCons = res;
    this.arregloCons.map((registro) => {
      if (registro.ENCGRE_FECHAEMISION !== null) {
        registro.ENCGRE_FECHAEMISION = this.datePipe.transform(registro.ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
      } else {
        registro.ENCGRE_FECHAEMISION = '';
      }
      this.venenguiaRemisionService.obtenerNombreTransport(registro.TRANSPORT_CODIGO).subscribe((res2) => {
        registro.TRANSPORT_NOMBRE = res2[0].TRANSPORT_NOMBRE;
      });
    });
    this.displayDialogBusquedaFast = true;
  });

}
//GSRF
  manejarSeleccion(opcion) {
    if (this.opcion === 'BUSCAR') {
      if (this.encGuiaRemision.length !== 0) {
        console.log('aqui')
        this.encGuiaRemision.map((enca) => {
          if (enca.ENCGRE_NUMERO === opcion.ENCGRE_NUMERO) {
            this.indice = this.encGuiaRemision.indexOf(enca);
          }
        });
        if (this.indice === this.encGuiaRemision.length - 1) {
          this.botonUltimo = true;
          this.botonSiguiente = true;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encGuiaRemision[this.indice];
          this.obtenerDatos();
        } else if (this.indice === 0) {
          this.botonPrimero = true;
          this.botonAnterior = true;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encGuiaRemision[this.indice];
          this.obtenerDatos();
        } else {
          this.botonPrimero = false;
          this.botonAnterior = false;
          this.botonSiguiente = false;
          this.botonUltimo = false;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encGuiaRemision[this.indice];
          this.obtenerDatos();
        }
        this.displayDialogBusquedaFast = false;
      } else {
        this.venenguiaRemisionService.getEncGuia().subscribe((res) => {
          this.encGuiaRemision = res;
          this.encGuiaRemision.map((enca) => {
            if (enca.ENCGRE_NUMERO === opcion.ENCGRE_NUMERO) {
              this.indice = this.encGuiaRemision.indexOf(enca);
            }
          });
          if (this.indice === this.encGuiaRemision.length - 1) {
            this.botonUltimo = true;
            this.botonSiguiente = true;
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encGuiaRemision[this.indice];
            this.obtenerDatos();
          } else if (this.indice === 0) {
            this.botonPrimero = true;
            this.botonAnterior = true;
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encGuiaRemision[this.indice];
            this.obtenerDatos();
          } else {
            this.botonPrimero = false;
            this.botonAnterior = false;
            this.botonSiguiente = false;
            this.botonUltimo = false;
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] = this.encGuiaRemision[this.indice];
            this.obtenerDatos();
          }
          this.displayDialogBusquedaFast = false;
        });
      }
    } else if (this.opcion === 'BUSQUEDACLI') {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_RUCDSTNTR = opcion.CLI_RUCIDE;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NMBRDSTNTR = opcion.CLI_NOMBRE;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_DIRECCIONDSTNTR = opcion.CLI_DIRECCION1;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TELEFONODSTNTR = opcion.CLI_TELEFONO1;
      if (opcion.CLI_LISTA === null) {
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_LISTA = 'A';
      } else {
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_LISTA = opcion.CLI_LISTA;
      }
      if (opcion.CLI_IVA === null) {
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_IVA = '0';
      } else {
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_IVA = opcion.CLI_IVA;
      }
      this.displayDialogBusquedaFast = false;

      document.getElementById('transportNombre').focus();
    } else if (this.opcion === 'BUSQUEDATRANSPORT') {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].TRANSPORT_CODIGO = opcion.TRANSPORT_CODIGO;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NMBRTRNSP = opcion.TRANSPORT_NOMBRE;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_RUCTRNSP = opcion.TRANSPORT_RUC;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PLACATNTR = opcion.TRANSPORT_PLACA;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_AUTOTNTR = opcion.TRANSPORT_AUTO;
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TONELAJE = opcion.TRANSPORT_TONELAJE;
      this.displayDialogBusquedaFast = false;
      this.activeIndex = 0;
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === '') {
        this.nuevodetalleGuia();
      }
    } else if (this.opcion === 'BUSQUEDABOD') {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
      this.nombrebodega = opcion.BOD_NOMBRE;
      this.displayDialogBusquedaFast = false;
      this.activeIndex = 1;
      document.getElementById('nombreCli').focus();
    } else if (this.opcion === 'BUSQUEDAFAC') {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO = opcion.ENCFAC_NUMERO;
      // this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_FECHAEMISION = new Date(opcion.ENCFAC_FECHAEMISION);
      console.log(opcion.ENCFAC_FECHAEMISION);
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_FECHAEMISION = opcion.ENCFAC_FECHAEMISION;

      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;

      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO !== '') {
        this.btnOpenFact = true;
      }

      this.venenguiaRemisionService.obtenerNombreCli(opcion.CLI_CODIGO).subscribe((res) => {
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_RUCDSTNTR = res[0].CLI_RUCIDE;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NMBRDSTNTR = res[0].CLI_NOMBRE;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_DIRECCIONDSTNTR = res[0].CLI_DIRECCION1;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TELEFONODSTNTR = res[0].CLI_TELEFONO1;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_LISTA = res[0].CLI_LISTA;
        if (res[0].CLI_IVA === null) {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_IVA = '0';
        } else {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_IVA = res[0].CLI_IVA;
        }
      });
      this.displayDialogBusquedaFast = false;
      document.getElementById('bodega').focus();
    } else if (this.opcion === 'BUSQUEDAREF') {
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_REFERENCIA = opcion.REF_CODIGO;
    }


    if (this.opcion === 'numeracion') {
      let size = 0;
      let intNumActual = 0;
      let intNumFinal = 0;
      let strNumActual;
      const itemNum = opcion;
      size = String(itemNum.NUM_SECACTUAL).length > 0 ? 1 : 0;
      if (size > 0) {
        intNumActual = parseInt(String(itemNum.NUM_SECACTUAL).trim());
        intNumFinal = parseInt(String(itemNum.NUM_SECFINAL).trim());
        strNumActual = String(itemNum.NUM_SECACTUAL).trim();
        this.strNemonico = String(itemNum.NUM_NEMONICO).trim();
        this.strSerie = String(itemNum.NUM_SERIE).trim();
      } else {
        this.mensajeAlerta('info', 'Información', 'Debe escoger una numeración, no se creara la nueva guía de remisión');

        return false;
      }
      this.tipoDoc = itemNum.NUM_DOCREF;

      this.strClase = itemNum.NUM_CLASE;

      this.tamNumPed = String(strNumActual).length; // tamaño del número

      this.strNumDoc = this.strNemonico + this.strSerie + strNumActual;

      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO = this.strNumDoc;
      this.numeroActual = Number(intNumActual);

      this.displayDialogBus = false;
      //CDPJ
      if(this.strNemonico === 'NE' && this.NFNEGR === true){
        this.botonXML = true;
      }else{
        this.botonXML = false;
      }
      if(this.CNAFEPV === 1){
        itemNum.NUM_FE=itemNum.NUM_FE === null || itemNum.NUM_FE === undefined?0:itemNum.NUM_FE
        console.log(itemNum.NUM_FE)
            if(itemNum.NUM_FE === 1){
              this.botonXML = false;
            }else{
              this.botonXML = true;
            }
      }
      //CDPJ
      document.getElementById('ptoPartida').focus();
    }

  }


  nuevodetalleGuia() {
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE !== '' &&
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE !== null) {
      return;
    }
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === '' ||
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === null) {
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO !== '') {
        this.btnNuevoGrid = true;
        this.btnGuardarGrid = false;
        this.btnBorrarGrid = false;
        this.btnCancelarGrid = false;
        const linea = this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length;
        const nuevodetGuia = {
          ENCGRE_CODIGO: '',
          ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
          DETGRE_LINEA: Number(linea + 1),
          DETGRE_TIPODET: this.tipodetalle,
          DETGRE_CODIGO: '',
          DETGRE_DESCRIPCION: '',
          DETGRE_UNIDAD: '',
          DETGRE_CANTIDAD: 0,
          DETGRE_DESPACHO: 0,
          BOD_CODIGO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].BOD_CODIGO,
          ENCFAC_NUMERO: '',
          LINEA: 0,
          DETFAC_COMCODIGO: '',
          COM_CODIGO: '01',
          DETGRE_PRECIO: 0,
          DETGRE_DESCUENTO: 0,
          DETGRE_TOTAL: 0,
          DETGRE_TRIBIVA: '',
          DETGRE_IVA: 0,
          DETGRE_TRIBICE: '',
          DETGRE_ICE: 0,
          DETGRE_PORCEICE: '',
          DETGRE_LISTA: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_LISTA,
          DETGRE_BASEIVA: 0,
          DETGRE_BASEICE: 0,
          DETGRE_BASECERO: 0,
          DETGRE_PROMOCION: '',
          DETGRE_ORDEN: 0,
          ENCPED_NUMERO: '',
          DETPED_LINEA: 0,
          CEN_CODIGO: '',
          DETGRE_BULTO: 0,
          DETGRE_CANTIDADB: 0,
          DETGRE_PESOBRUTO: 0,
          DETGRE_PESOTARA: 0,
          DETGRE_NETO: 0,
          DETGRE_VOLUMEN: 0,
          COSTOPROM: 0,
          ART_CANTMIN: 0,
          DETGRE_PORIVA:this.venenguiaRemisionService.porcetariiva,
          TARIIVA_CODIGO:''

        };
        if (this.detalleGuiaSeleccionado !== undefined && this.detalleGuiaSeleccionado !== null) {
          const indi = this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].indexOf(this.detalleGuiaSeleccionado);
          if (indi === -1) {
            console.log('entra*/*/*/');
            this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].push(nuevodetGuia);
            this.detalleGuiaSeleccionado = this.venenguiaRemisionService.detguiaRemisionarray[
              this.indicador][this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length - 1];
            this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);
              //CDPJ
              if(this.detalleGuiaSeleccionado.DETGRE_TIPODET !== '*'){
                this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultGuiaDetalle.objeto].setFocusedCell(
                  this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length - 1, 'DETGRE_CODIGO');
              }else{
                this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultGuiaDetalle.objeto].setFocusedCell(
                  this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length - 1, 'DETGRE_DESCRIPCION');
                  this.detalleGuiaSeleccionado.DETGRE_PORIVA=0
                  this.detalleGuiaSeleccionado.TARIIVA_CODIGO='-1';
              }
              //CDPJ
          } else {
            console.log('entra*/*/*/22');
            this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].splice(indi + 1, 0, nuevodetGuia);
            this.detalleGuiaSeleccionado = nuevodetGuia;
            this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);//CDPJ
            //this.aggrid.refreshaggridindex(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto, indi);
             //CDPJ
             if(this.detalleGuiaSeleccionado.DETGRE_TIPODET !== '*'){
              this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultGuiaDetalle.objeto].setFocusedCell(indi + 1, 'DETGRE_CODIGO');
            }else{
              this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultGuiaDetalle.objeto].setFocusedCell(indi + 1, 'DETGRE_DESCRIPCION');
              this.detalleGuiaSeleccionado.DETGRE_PORIVA=0
              this.detalleGuiaSeleccionado.TARIIVA_CODIGO='-1';
            }
            //CDPJ
          }
        }
      } else {
        this.btnNuevoGrid = false;
        this.btnGuardarGrid = false;
        this.btnBorrarGrid = false;
        this.btnCancelarGrid = false;
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'error',
          summary: 'Inserción de detalle de la guía',
          detail: 'Primero ingrese un cliente'
        });
        this.activeIndex = 1;
        document.getElementById('nombreCli').focus();
      }
    } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO !== '' &&
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO !== null) {

      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Inserción de detalle de la guía',
        detail: 'No se puede insertar un detalle en la guia, para ingresar un detalle hagalo a traves de F9 y seleccione un detalle de la' +
          'factura'
      });
    }
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'ptoPartida') {
      document.getElementById('ptoLlegada').focus();
    } else if (evento.target.id === 'ptoLlegada') {
      document.getElementById('comentario').focus();
    } else if (evento.target.id === 'comentario') {
      document.getElementById('compraVta').focus();
    } else if (evento.target.id === 'compraVta') {
      this.busqService.encontrarRegistro49(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO).subscribe((resF) => {
        if (resF !== null) {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO = resF[0].ENCFAC_NUMERO;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_FECHAEMISION = new Date(resF[0].ENCFAC_FECHAEMISION);
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO = resF[0].CLI_CODIGO;
          this.venenguiaRemisionService.obtenerNombreCli(resF[0].CLI_CODIGO).subscribe((res) => {
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_RUCDSTNTR = res[0].CLI_RUCIDE;
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NMBRDSTNTR = res[0].CLI_NOMBRE;
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_DIRECCIONDSTNTR = res[0].CLI_DIRECCION1;
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TELEFONODSTNTR = res[0].CLI_TELEFONO1;
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_LISTA = res[0].CLI_LISTA;
            if (res[0].CLI_IVA === null) {
              this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_IVA = '0';
            } else {
              this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_IVA = res[0].CLI_IVA;
            }
          });
          this.displayDialogBusquedaFast = false;
          document.getElementById('bodega').focus();
        } else {
          this.abrirFactura();
        }
      });
    } else if (evento.target.id === 'bodega') {
      this.busqService.encontrarRegistro40(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].BOD_CODIGO).subscribe((resB) => {
        if (resB !== null) {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].BOD_CODIGO = resB[0].BOD_CODIGO;
          this.nombrebodega = resB[0].BOD_NOMBRE;
          this.displayDialogBusquedaFast = false;
          this.activeIndex = 1;
          document.getElementById('nombreCli').focus();
        } else {
          this.abrirBodega();
        }
      });
    } else if (evento.target.id === 'horaInicio') {
      document.getElementById('horaFin').focus();
    } else if (evento.target.id === 'nombreCli') {
      this.busqService.encontrarRegistro44(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO).subscribe((res) => {
        if (res !== null) {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO = res[0].CLI_CODIGO;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_RUCDSTNTR = res[0].CLI_RUCIDE;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NMBRDSTNTR = res[0].CLI_NOMBRE;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_DIRECCIONDSTNTR = res[0].CLI_DIRECCION1;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TELEFONODSTNTR = res[0].CLI_TELEFONO1;
          if (res[0].CLI_LISTA === null) {
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_LISTA = 'A';
          } else {
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_LISTA = res[0].CLI_LISTA;
          }
          if (res[0].CLI_IVA === null) {
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_IVA = '0';
          } else {
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_IVA = res[0].CLI_IVA;
          }
          this.displayDialogBusquedaFast = false;
          document.getElementById('transportNombre').focus();
        } else {
          this.abrirClientes();
        }
      });
    } else if (evento.target.id === 'transportNombre') {
      this.busqService.encontrarRegistro48(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].TRANSPORT_CODIGO).subscribe((resT) => {
        if (resT !== null) {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].TRANSPORT_CODIGO = resT[0].TRANSPORT_CODIGO;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NMBRTRNSP = resT[0].TRANSPORT_NOMBRE;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_RUCTRNSP = resT[0].TRANSPORT_RUC;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PLACATNTR = resT[0].TRANSPORT_PLACA;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_AUTOTNTR = resT[0].TRANSPORT_AUTO;
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TONELAJE = resT[0].TRANSPORT_TONELAJE;
          this.displayDialogBusquedaFast = false;
          this.activeIndex = 0;
          if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === '') {
            this.nuevodetalleGuia();
          }
        } else {
          this.abrirTransportista();
        }
      });
    } else if (evento.target.id === 'referencia') {
      this.busqService.encontrarRegistro47(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_REFERENCIA).subscribe((resRe) => {
        if (resRe !== null) {
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_REFERENCIA = resRe[0].REF_CODIGO;
        } else {
          this.abrirReferencia();
        }
      });
    }

  }

  abrirClave(accion) {
    this.displayAcciones = false;
    this.accionClave = accion;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }
    this.claveDialog = true;
  }

  comprobarClave() {
    this.venenguiaRemisionService.obtenerClave(this.accionClave).subscribe((clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.claveDialog = false;
        if (this.accionClave === 'actAnular') {
          if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'ANULADA') {
            this.venenguiaRemisionService.cambiarEstadoGRE(this.codigoGuiaAux, 'P').subscribe(() => {
              this.messageService.add({
                key: 'guiaRemision',
                severity: 'success',
                summary: 'Activar Guia de Remisión',
                detail: 'Se canceló la anulación de la guia, por favor guardelá nuevamente'
              });
              this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO = 'PENDIENTE';
              this.btnGuardar = false;
            });
          } else {
            this.messageService.add({
              key: 'guiaRemision',
              severity: 'error',
              summary: 'Activar Guia de Remisión',
              detail: 'No se puede activar una guia cuyo estado no sea Anulada'
            });
          }
        } else if (this.accionClave === 'actBtnAnular') {
          this.anularGre();
          this.auditoriagral.registrarAuditoria('VEN_ENCGRE',
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO + '/VEN/ACT-BOTON-ANULAR',
            'A', '', '01', '', '', '', '').subscribe(() => {
              this.botonAnular = false;
            });
        } else if (this.accionClave === 'actBtnXML') {
          this.auditoriagral.registrarAuditoria('VEN_ENCGRE',
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO + '/VEN', 'A',
            '', '01', '', '', '', '').subscribe(() => {
              this.botonXML = false;
            });
        } else if (this.accionClave === 'firmarDoc') {
          this.confirmarFirmar();
        } else if (this.accionClave === 'anularGuia') {

        }
        this.claveAuth = '';
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }

  async seleccionDetalleGuia(valor) {
    if (valor === 'nuevo') {
      this.nuevodetalleGuia();
    } else if (valor === 'eliminar') {
      this.confirmEliminarDetGuia();
    } else if (valor === 'cancelar') {
      this.cancelarDetGuia();
    } else if (valor === 'pedidos') {
      let dato=this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO=dato === null || dato === undefined?'':dato
console.log('encfac_numero', this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO);
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO !== '' &&
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO === '') {
        this.displayDialogPedidos = true;
      } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO === '') {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'error',
          summary: 'Inserción de Guía',
          detail: 'Primero ingrese un cliente'
        });
        this.activeIndex = 1;
      } else if ( String(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO).trim() !== '' &&
      String(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO).trim() !== null) {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'error',
          summary: 'Inserción de detalle de la guía',
          detail: 'No se puede ingresar detalle de pedido, cuando se ha seleccionado una factura. Para ingresar un detalle proveniente de la factura ' +
            'cierre esta ventana y presione F9 '+ this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO
        });
      }
    }

    if (valor === 'existenciasBod') {
      console.log(this.detalleGuiaSeleccionado);
      this.txtListaPrecio = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_LISTA;
      this.txtIva = this.venenguiaRemisionService.dblPorcIvaGrearray[this.indicador];
      this.txtBodegaRes = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].BOD_CODIGO;

      this.txtObservacionRes = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO + '-' +
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NMBRDSTNTR;

      if (this.detalleGuiaSeleccionado === undefined) {
        this.txtArtCodigo = '';
        this.txtArtDescripcion = '';
      } else if (Object.keys(this.detalleGuiaSeleccionado).length > 0) {

        if (this.detalleGuiaSeleccionado.DETGRE_CODIGO === '' || this.detalleGuiaSeleccionado.DETGRE_TIPODET === '*'
          || this.detalleGuiaSeleccionado.DETGRE_TIPODET === 'S' || this.detalleGuiaSeleccionado.DETGRE_TIPODET === 'C') {
          this.txtArtCodigo = '';
          this.txtArtDescripcion = '';
          this.displayDialogExistBod = true;
        } else {
          this.txtArtCodigo = this.detalleGuiaSeleccionado.DETGRE_CODIGO;
          this.txtArtDescripcion = this.detalleGuiaSeleccionado.DETGRE_DESCRIPCION;
          this.displayDialogExistBod = true;
        }
      }
    }

    ///////////////////////////vehiculo

    if (valor === 'verInfoArtVeh') {
      console.log(this.detalleGuiaSeleccionado);
      this.ART_RAMV = '';
      this.ART_MODELOSRI = '';
      this.ART_MODELOAT = '';
      this.ART_MARCAV = '';
      this.ART_CLASE = '';
      this.ART_TIPOV = '';
      this.ART_CILINDRAJE = '';
      this.ART_ANIO = '';
      this.ART_TONELAJE = '';
      this.ART_COLOR = '';
      this.ART_PORIGEN = '';
      this.ART_MOTOR = '';
      this.ART_SERIEV = '';
      this.ART_NRUEDAS = '';
      this.ART_NEJES = '';
      this.ART_CAPACIDAD = '';
      this.ART_COMBUSTIBLE = '';
      this.ART_BANCO = '';
      this.ART_DPAGO = '';
      this.ART_FFABRICA = '';
      this.ART_DISPOSITIVO = '';
      this.ART_MARVEH = '';
      this.ART_CLAVEH = '';
      this.ART_TIPVEH = '';
      this.ART_COMVEH = '';
      this.ART_DISVEH = '';
      this.ART_P = '';

      if (this.detalleGuiaSeleccionado.DETGRE_TIPODET === 'A') {
        this.busquedaVeh.getArticulo(this.detalleGuiaSeleccionado.DETGRE_CODIGO).subscribe(async (res) => {
          for (const i of res) {
            this.ART_RAMV = i.ART_RAMV === null ? '' : i.ART_RAMV;
            this.ART_MODELOSRI = i.ART_MODELOSRI === null ? '' : i.ART_MODELOSRI;
            this.ART_MODELOAT = i.ART_MODELOAT === null ? '' : i.ART_MODELOAT;
            this.ART_MARCAV = i.ART_MARCAV === null ? '' : i.ART_MARCAV;
            this.ART_CLASE = i.ART_CLASE === null ? '' : i.ART_CLASE;
            this.ART_TIPOV = i.ART_TIPOV === null ? '' : i.ART_TIPOV;
            this.ART_CILINDRAJE = i.ART_CILINDRAJE === null ? '' : i.ART_CILINDRAJE;
            this.ART_ANIO = i.ART_ANIO === null ? '' : i.ART_ANIO;
            this.ART_TONELAJE = i.ART_TONELAJE === null ? '' : i.ART_TONELAJE;
            this.ART_COLOR = i.ART_COLOR === null ? '' : i.ART_COLOR;
            this.ART_PORIGEN = i.ART_PORIGEN === null ? '' : i.ART_PORIGEN;
            this.ART_MOTOR = i.ART_MOTOR === null ? '' : i.ART_MOTOR;
            this.ART_SERIEV = i.ART_SERIEV === null ? '' : i.ART_SERIEV;
            this.ART_NRUEDAS = i.ART_NRUEDAS === null ? '' : i.ART_NRUEDAS;
            this.ART_NEJES = i.ART_NEJES === null ? '' : i.ART_NEJES;
            this.ART_CAPACIDAD = i.ART_CAPACIDAD === null ? '' : i.ART_CAPACIDAD;
            this.ART_COMBUSTIBLE = i.ART_COMBUSTIBLE === null ? '' : i.ART_COMBUSTIBLE;
            this.ART_BANCO = i.ART_BANCO === null ? '' : i.ART_BANCO;
            this.ART_DPAGO = i.ART_DPAGO === null ? '' : i.ART_DPAGO;
            this.ART_FFABRICA = i.ART_FFABRICA === null ? '' : this.datePipe.transform(i.ART_FFABRICA, 'dd/MM/yyyy');
            this.ART_DISPOSITIVO = i.ART_DISPOSITIVO === null ? '' : i.ART_DISPOSITIVO;

            if (i.ART_MARCAV !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_MARCAV, 'MARVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_MARVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_CLASE !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_CLASE, 'CLAVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_CLAVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_TIPOV !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_TIPOV, 'TIPVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_TIPVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_COMBUSTIBLE !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_COMBUSTIBLE, 'COMVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_COMVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_DISPOSITIVO !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_DISPOSITIVO, 'DISVEH').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_DISVEH = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_PORIGEN !== null) {
              this.busquedaVeh.encontrarRefNombreMaeRef(i.ART_PORIGEN, 'P').subscribe((formsriven) => {
                if (formsriven[0] !== undefined) {
                  this.ART_P = formsriven[0].REF_NOMBRE;
                }
              });
            }
            if (i.ART_BANCO !== null) {
              this.cliService.erNombreBanco(i.ART_BANCO).subscribe((formsricom) => {
                if (formsricom !== null) {
                  if (formsricom[0] !== undefined) {
                    this.ART_NBANCO = formsricom[0].BANCLI_NOMBRE;
                  }
                }
              });
            }
            if (i.ART_RAMV !== null) {
              this.displayvehiculo = true;
            } else {
              this.messageService.add({
                key: 'guiaRemision',
                severity: 'error',
                summary: 'Detalle Vehículo',
                detail: 'El articulo seleccionado no es un vehículo.'
              });
            }
          }
        });

      }
    }


    if (valor.object !== undefined) {
      this.detalleGuiaSeleccionado = valor.object;
      if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultGuiaDetalle.objeto].getFocusedCell().column.colId === 'DETGRE_UNIDAD') {
        if (this.detalleGuiaSeleccionado.ART_MULTIUNIDAD === 'S') {
          const opciones = [];
          opciones.push(this.detalleGuiaSeleccionado.DETGRE_UNIDAD);
          this.utilitariosService.getUnidadesEqui(this.detalleGuiaSeleccionado.DETGRE_UNIDAD).subscribe((res) => {
            if (res !== null) {
              res.map((unidades) => {
                opciones.push(unidades.UNI_CODIGODESTINO);
              });
            }
          });
          this.venenguiaRemisionService.detguiaRemisionarray[this.indicador][this.venenguiaRemisionService.detguiaRemisionarray[
            this.indicador].indexOf(this.detalleGuiaSeleccionado)].DETGRE_UNIDAD = this.detalleGuiaSeleccionado.DETGRE_UNIDAD;
          this.columnDefsGuiaDetalle[5].cellEditor = 'agSelectCellEditor';
          this.columnDefsGuiaDetalle[5].editable = true;
          this.columnDefsGuiaDetalle[5].cellEditorParams = {
            values: opciones
          };
          this.aggrid.refreshColumnDefs();
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultGuiaDetalle.objeto].setFocusedCell(
            this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].indexOf(this.detalleGuiaSeleccionado),
            'DETGRE_UNIDAD');
        } else if (this.detalleGuiaSeleccionado.ART_MULTIUNIDAD === 'N') {
          const opciones = [];
          opciones.push(this.detalleGuiaSeleccionado.DETGRE_UNIDAD);
          this.venenguiaRemisionService.detguiaRemisionarray[this.indicador][this.venenguiaRemisionService.detguiaRemisionarray[
            this.indicador].indexOf(this.detalleGuiaSeleccionado)].DETGRE_UNIDAD = this.detalleGuiaSeleccionado.DETGRE_UNIDAD;
          this.columnDefsGuiaDetalle[5].cellEditor = 'cellPrueba';
          this.columnDefsGuiaDetalle[5].editable = false;
          this.aggrid.refreshColumnDefs();
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultGuiaDetalle.objeto].setFocusedCell(
            this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].indexOf(this.detalleGuiaSeleccionado), 'DETGRE_UNIDAD');
        }
      } else if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultGuiaDetalle.objeto].getFocusedCell().column.colId === 'DETGRE_LISTA') {
        this.utilitariosService.getListaPrecioArt(this.detalleGuiaSeleccionado.DETGRE_CODIGO).subscribe((res1) => {
          const opciones1 = [];
          opciones1.push(this.venenguiaRemisionService.detguiaRemisionarray[
            this.indicador][this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].indexOf(this.detalleGuiaSeleccionado)].DETGRE_LISTA);
          res1.map((codigo) => {
            if (codigo.ARTPRE_CODIGO !== opciones1[0]) {
              opciones1.push(codigo.ARTPRE_CODIGO);
            }
          });
          this.columnDefsGuiaDetalle[10].cellEditorParams = {
            values: opciones1
          };
          this.aggrid.refreshColumnDefs();
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultGuiaDetalle.objeto].setFocusedCell(
            this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].indexOf(this.detalleGuiaSeleccionado), 'DETGRE_LISTA');
          res1.map((codigoPre) => {
            if (this.venenguiaRemisionService.detguiaRemisionarray[this.indicador][this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].indexOf
              (this.detalleGuiaSeleccionado)].DETGRE_LISTA ===
              codigoPre.ARTPRE_CODIGO) {
              this.venenguiaRemisionService.detguiaRemisionarray[this.indicador][this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].indexOf
                (this.detalleGuiaSeleccionado)].DETGRE_PRECIO =
                codigoPre.ARTPRE_PRECIO;
            }
          });
        });
      }
      if (this.detalleGuiaSeleccionado.DETGRE_CODIGO !== null && this.detalleGuiaSeleccionado.DETGRE_CODIGO !== '') {
        this.venenguiaRemisionService.consultarImagen(this.detalleGuiaSeleccionado.DETGRE_CODIGO).subscribe((res) => {
          if (res.length !== 0) {
            this.imagenProducto = res[0].ART_IMAGEN;
          } else if (res.length === 0) {
            this.imagenProducto = '';
          }
        });
      }
    }

  }

  seleccionDetalleRef(valor) {
    if (valor === 'nuevo') {
      this.nuevaInfAdi();
    } else if (valor === 'eliminar') {
      this.confirmarEliminacionInfoAdi();
    } else if (valor === 'cancelar') {
      this.cancelarInfoAdi();
    }
    if (valor.object !== undefined) {
      this.detalleReferenciaSeleccionada = valor.object;
    }
  }

  confirmEliminarDetGuia() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarGuia',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        this.confirmationService.close();
        //this.eliminardetGuia();
          //CDPJ
      let encpedestado='';
      const data = await this.venenguiaRemisionService.obtenerEncgreEstado(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO);
      if(data !== null && data !== undefined){
        for(const rs1 of data){
          encpedestado=rs1.ENCGRE_ESTADO
        }
      }
    //CDPJ
    console.log('encpedestado',encpedestado)
    if(encpedestado === 'F'){
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Eliminar Guía',
        detail: 'La Guía ya se encuentra en estado \'FACTURADA\''
      });
      this.spin=false;//CDPJ
      this.spin=false;
      this.cancelarEdicionGuia();
      return;
    }else{
      this.eliminardetGuia();
    }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  eliminardetGuia() {
    if (this.detalleGuiaSeleccionado !== undefined) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleGuiaSeleccionado.LINEA === 0) {
        this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = this.venenguiaRemisionService.detguiaRemisionarray[
          this.indicador].filter((val, j) => j !==
            this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].indexOf(this.detalleGuiaSeleccionado));
        this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);
        this.venenguiaRemisionService.calcularTotal();
      } else {
        this.venenguiaRemisionService.eliminarDet(this.detalleGuiaSeleccionado).subscribe(() => {
          // this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = this.venenguiaRemisionService.detguiaRemisionarray[
          //   this.indicador].filter((val, j) => j !==
          //     this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].indexOf(this.detalleGuiaSeleccionado));
          this.auditoriagral.registrarAuditoria('VEN_DETGRE',
          String(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO)+'/'+this.detalleGuiaSeleccionado.DETGRE_CODIGO, 'E',
          '', '01', '', '', '', '').subscribe(() => {
          });
          this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].splice(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].findIndex(
            detfac => detfac.DETGRE_LINEA === this.detalleGuiaSeleccionado.DETGRE_LINEA),
            1);//CDPJ
          this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);
          // this.auditoriagral.registrarAuditoria('VEN_DETGRE',
          //   String(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO), 'E',
          //   '', '01', '', '', '', '').subscribe(() => {
          //   });
          this.venenguiaRemisionService.calcularTotal();
          this.manejarSenales('Guardar');
        });
      }
    }
  }

  cancelarDetGuia() {
    this.btnNuevoGrid = false;
    this.btnGuardarGrid = true;

    if (this.detalleGuiaSeleccionado.DETGRE_CODIGO === '' && (this.detalleGuiaSeleccionado.ENCPED_NUMERO === '' &&
      this.detalleGuiaSeleccionado.ENCFAC_NUMERO === '')) {

      this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].splice(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].findIndex(
        guiaRem => guiaRem.DETGRE_LINEA === this.detalleGuiaSeleccionado.DETGRE_LINEA && guiaRem.LINEA === this.detalleGuiaSeleccionado.LINEA),
        1);
    }

    this.aggrid.refreshColumnDefs();
    this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);

    this.venenguiaRemisionService.calcularTotal();
  }

  nuevaInfAdi() {
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CODIGO !== '') {
      this.btnNuevoGrid = true;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      const linea = this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].length;
      const nuevodetGuia = {
        ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO, INFAD_NUMSEC: 0,
        COM_CODIGO: '01', INFAD_CODIGO: '', INFAD_DESCRIPCION: '', LINEA: Number(linea + 1)
      };
      if (this.detalleReferenciaSeleccionada !== undefined && this.detalleReferenciaSeleccionada !== null) {
        const indi = this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].indexOf(this.detalleReferenciaSeleccionada);
        if (indi === -1) {
          this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].push(nuevodetGuia);
          this.detalleReferenciaSeleccionada = this.venenguiaRemisionService.detguiaReferenciaarray[
            this.indicador][this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].length - 1];
          this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador], this.defaultGuiaReferencia.objeto);
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultGuiaDetalle.objeto].setFocusedCell(
            this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].length - 1, 'INFAD_CODIGO');
        } else {
          this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].splice(indi + 1, 0, nuevodetGuia);
          this.aggrid.refreshaggridindex(this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador], this.defaultGuiaReferencia.objeto, indi);
          this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultGuiaDetalle.objeto].setFocusedCell(indi + 1, 'INFAD_CODIGO');
        }
      }
    } else {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Inserción de información adicional',
        detail: 'Primero guarde la guia de remisión'
      });
    }
  }

  confirmarEliminacionInfoAdi() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarGuia',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminardetInfAdi();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  eliminardetInfAdi() {
    if (this.detalleReferenciaSeleccionada !== undefined) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleReferenciaSeleccionada.INFAD_NUMSEC === 0) {
        this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = this.venenguiaRemisionService.detguiaReferenciaarray[
          this.indicador].filter((val, j) => j !==
            this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].indexOf(this.detalleReferenciaSeleccionada));
        this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador], this.defaultGuiaReferencia.objeto);
      } else {
        this.venenguiaRemisionService.eliminarInfoAdiDet(this.detalleReferenciaSeleccionada).subscribe(() => {
          this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = this.venenguiaRemisionService.detguiaReferenciaarray[
            this.indicador].filter((val, j) => j !==
              this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].indexOf(this.detalleReferenciaSeleccionada));
          this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador], this.defaultGuiaReferencia.objeto);
          this.auditoriagral.registrarAuditoria('VEN_INFOADICGRE',
            String(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO), 'E',
            '', '01', '', '', '', '').subscribe(() => {
            });
        });
      }
    }
  }

  cancelarInfoAdi() {
    if (this.detalleReferenciaSeleccionada.INFAD_NUMSEC === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = this.venenguiaRemisionService.detguiaReferenciaarray[
        this.indicador].filter((val, j) => j !==
          this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].indexOf(this.detalleReferenciaSeleccionada));
      this.detalleReferenciaSeleccionada = {};
    } else if (this.detalleReferenciaSeleccionada.INFAD_NUMSEC !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.venenguiaRemisionService.obtenerInfoAdic(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO).subscribe
        ((infoAdic) => {
          if (infoAdic.length === 0) {
            let correoAd = '';
            this.venenguiaRemisionService.obtenerCorreo(
              this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO).subscribe((correo) => {
                correoAd = correo[0].CLI_CORREO;
                this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = [{
                  ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                  INFAD_NUMSEC: 1,
                  LINEA: 1,
                  COM_CODIGO: '01',
                  INFAD_CODIGO: 'direccion',
                  INFAD_DESCRIPCION: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_DIRECCIONDSTNTR
                }, {
                  ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                  INFAD_NUMSEC: 2,
                  LINEA: 2,
                  COM_CODIGO: '01',
                  INFAD_CODIGO: 'correo',
                  INFAD_DESCRIPCION: correoAd
                }, {
                  ENCGRE_NUMERO: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
                  INFAD_NUMSEC: 3,
                  LINEA: 3,
                  COM_CODIGO: '01',
                  INFAD_CODIGO: 'telefono',
                  INFAD_DESCRIPCION: this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_TELEFONODSTNTR
                }];
              });
          } else if (infoAdic.length !== 0) {
            this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador] = infoAdic;
          }
        });
    }
  }

  cambio(cambio) {
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] !== undefined) {
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO === 'PENDIENTE') {
        this.btnGuardar = false;
        this.btnRegresar = false;
        this.btnNuevo = true;
        this.btnNuevoGrid = false;
        this.botonUltimo = true;
        this.botonAnterior = true;
        this.botonSiguiente = true;
        this.botonPrimero = true;
      }
    }
    this.disabledEstadisticas = true;
  }

  abrirDetalleFactura() {
    const fac = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCFAC_NUMERO;
    if (fac === undefined) {
      return;
    }
    if (fac === '') {
      return this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Error ',
        detail: 'Debe incluir una factura para poder agregar el detalle de la misma en la guía de remisión'
      });

    }
    this.displayDialogDetFac = true;
  }

  recibirSenales(valor) {
    if (valor === true) {
      this.displayDialogDetFac = false;
    }
  }

  recibirDetalles(valores) {

    let guiaRemi: VendetGuiaRemision;
    let guiasRemi: VendetGuiaRemision[];
    guiasRemi = [];
    guiaRemi = {};
    if (valores !== null) {
      valores.map((valor) => {
        guiaRemi.BOD_CODIGO = valor.BOD_CODIGO;
        guiaRemi.DETGRE_CODIGO = valor.DETFAC_CODIGO;
        guiaRemi.DETGRE_DESCRIPCION = valor.DETFAC_DESCRIPCION;
        guiaRemi.DETGRE_TRIBIVA = valor.DETFAC_TRIBIVA;
        guiaRemi.DETGRE_TRIBICE = valor.DETFAC_TRIBICE;
        guiaRemi.DETGRE_UNIDAD = valor.DETFAC_UNIDAD;
        guiaRemi.DETGRE_CANTIDAD = valor.DETFAC_CANTIDAD;
        guiaRemi.DETGRE_PRECIO = valor.DETFAC_PRECIO;
        guiaRemi.DETGRE_TOTAL = valor.DETFAC_TOTAL;
        guiaRemi.DETGRE_DESCUENTO = valor.DETFAC_DESCUENTO;
        guiaRemi.DETGRE_LISTA = valor.DETFAC_LISTA;
        guiaRemi.DETGRE_TIPODET = valor.DETFAC_TIPODET;
        guiaRemi.DETFAC_LINEA = valor.DETFAC_LINEA;
        guiaRemi.LINEA = 0;//CDPJ
        guiaRemi.DETGRE_LINEA = valor.DETFAC_LINEA; //CDPJ
        guiaRemi.DETGRE_CANTIDADB = 0;
        guiaRemi.DETGRE_CANTIDADUND = '';
        guiaRemi.DETGRE_BULTO = 0;
        guiaRemi.CEN_CODIGO = valor.CEN_CODIGO === undefined ? '' : valor.CEN_CODIGO;
        guiaRemi.ENCFAC_NUMERO = valor.ENCFAC_NUMERO;
        guiaRemi.ENCPED_NUMERO = '';
        guiaRemi.DETGRE_DESPACHO = 0;
        guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
        guiaRemi.DETGRE_IVA = valor.DETFAC_IVA;
        guiaRemi.DETGRE_ICE = valor.DETFAC_ICE;
        guiaRemi.DETGRE_PORCEICE = valor.DETFAC_PORCEICE;
        guiaRemi.ENCGRE_CODIGO = '';
        guiaRemi.COM_CODIGO = '01';
        guiaRemi.DETFAC_COMCODIGO = '01';
        guiaRemi.DETGRE_BASEIVA = 0;
        guiaRemi.DETGRE_BASEICE = 0;
        guiaRemi.DETGRE_BASECERO = 0;
        guiaRemi.DETGRE_PROMOCION = valor.DETFAC_PROMOCION;
        guiaRemi.DETGRE_ORDEN = 0;
        guiaRemi.ENCPED_NUMERO = '';
        guiaRemi.DETPED_LINEA = 0;
        guiaRemi.DETGRE_PESOBRUTO = 0;
        guiaRemi.DETGRE_PESOTARA = 0;
        guiaRemi.DETGRE_NETO = 0;
        guiaRemi.DETGRE_VOLUMEN = 0;
        guiaRemi.DETGRE_PORIVA=valor.DETFAC_PORIVA;//CDPJ
        guiaRemi.TARIIVA_CODIGO=valor.TARIIVA_CODIGO;//CDPJ
        guiasRemi.push(guiaRemi);
        guiaRemi = {};
      });
      this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = guiasRemi;
      this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);
      this.venenguiaRemisionService.calcularTotal();
      this.displayDialogDetFac = false;
      this.cambio('value');
    }
  }

  recibirSenalesPedidos(valor) {
    if (valor === true) {
      this.displayDialogPedidos = false;
    }
  }
  //CDPJ
obtenerCantGuiaActual(strArtCodigo, intLineaPedido,
  intLineaGuia, strEncGreCodigo, strEncPedNumero): number {
  let dblCant = 0;

  for(const item of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
    

    if (item.DETGRE_TIPODET === 'A' || item.DETGRE_TIPODET === 'S' || item.DETGRE_TIPODET === 'C') {
      if (item.DETGRE_CODIGO === strArtCodigo) {
        if (intLineaPedido !== 0) {
          if (item.DETPED_LINEA === intLineaPedido
            && item.ENCPED_NUMERO === strEncPedNumero) {
            dblCant = dblCant + Number(item.DETGRE_CANTIDAD);
          }
         } //else if (intLineaGuia !== 0) {
        //   if (item.DETGRE_LINEA === intLineaGuia
        //     && item.ENCGRE_NUMERO === strEncGreCodigo) {
        //     dblCant = dblCant + Number(item.DETGRE_CANTIDAD);
        //   }
        // }
      }
    }
  }

  return dblCant;
}
obtenerTotalDetGuiaActual(strArtCodigo, intLineaPedido,
  intLineaGuia, strEncGreCodigo, strEncPedNumero): number {
  let dblCant = 0;

  for (const item of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
    //const item = this.encfacService.detfacarray[this.indicador][fila - 1];

    if (item.DETGRE_TIPODET === 'A' || item.DETGRE_TIPODET === 'S' || item.DETGRE_TIPODET === 'C' || item.DETGRE_TIPODET === 'Y') {
      if (item.DETGRE_CODIGO === strArtCodigo) {
        if (intLineaPedido !== 0) {
          if (item.DETPED_LINEA === intLineaPedido
            && item.ENCPED_NUMERO === strEncPedNumero) {
            dblCant = dblCant + Number(item.DETGRE_TOTAL);
          }
        } else if (intLineaGuia !== 0) {
          if (item.DETGRE_LINEA === intLineaGuia
            && item.ENCGRE_NUMERO === strEncGreCodigo) {
            dblCant = dblCant + Number(item.DETGRE_TOTAL);
          }
        }
      }
    }
  }

  return dblCant;
}
async recibirDetallesPedidos(valores) {

  // let guiaRemi: VendetGuiaRemision;
  // let guiasRemi: VendetGuiaRemision[];
  // guiasRemi = [];
  // guiaRemi = {};
  if (valores !== null) {
    if (this.intACTPEDVAL === 0) {
      valores.map((valor) => {
      if (valor.length !== 0) {
        let fila=1;
        let lineadetped=0;
        let final=0
          valor.map((valorDetped) => {
            const dblCantGuia =valorDetped.DETPED_DESPACHO
            let dblCantPend = 0;
            dblCantPend=valorDetped.DETPED_CANTIDAD-dblCantGuia
            const dblCantGuiaActual=this.obtenerCantGuiaActual(valorDetped.DETPED_CODIGO, valorDetped.DETPED_LINEA,
              0, '', valorDetped.ENCPED_NUMERO);
              final=final+1
        if(dblCantPend - dblCantGuiaActual > 0 || valorDetped.DETPED_TIPODET === '*'){
          this.eliminarLineaEnBlanco();
          if(fila===1){
            this.nuevodetalleGuia();
          const guiaRemi:VendetGuiaRemision =this.detalleGuiaSeleccionado;
          guiaRemi.BOD_CODIGO = valor[0].BOD_CODIGO;
          guiaRemi.DETGRE_CODIGO = '';
          guiaRemi.DETGRE_DESCRIPCION = '====<< PEDIDO "' + valor[0].ENCPED_NUMERO + '" >>====';
          guiaRemi.DETGRE_TRIBIVA = '';
          guiaRemi.DETGRE_TRIBICE = '';
          guiaRemi.DETGRE_UNIDAD = '';
          guiaRemi.DETGRE_CANTIDAD = '';
          guiaRemi.DETGRE_PRECIO = '';
          guiaRemi.DETGRE_TOTAL = '';
          guiaRemi.DETGRE_DESCUENTO = '';
          guiaRemi.DETGRE_LISTA = valor[0].DETPED_LISTA;
          guiaRemi.DETGRE_TIPODET = '*';
          guiaRemi.DETPED_LINEA = lineadetped;
          guiaRemi.LINEA = 0//CDPJ
          guiaRemi.DETGRE_LINEA = this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length + 1;//CDPJ
          guiaRemi.DETGRE_CANTIDADB = 0;
          guiaRemi.DETGRE_CANTIDADUND = '';
          guiaRemi.DETGRE_BULTO = 0;
          guiaRemi.CEN_CODIGO = '';//CDPJ
          guiaRemi.ENCFAC_NUMERO = '';
          guiaRemi.ENCPED_NUMERO = valor[0].ENCPED_NUMERO;
          guiaRemi.DETGRE_DESPACHO = 0;
          guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
          guiaRemi.DETGRE_IVA = '';
          guiaRemi.DETGRE_ICE = '';
          guiaRemi.DETGRE_PORCEICE = '';
          guiaRemi.ENCGRE_CODIGO = '';
          guiaRemi.COM_CODIGO = '01';
          guiaRemi.DETFAC_COMCODIGO = '';
          guiaRemi.DETGRE_BASEIVA = 0;
          guiaRemi.DETGRE_BASEICE = 0;
          guiaRemi.DETGRE_BASECERO = 0;
          guiaRemi.DETGRE_PROMOCION = '';
          guiaRemi.DETGRE_ORDEN = 0;
          guiaRemi.DETFAC_LINEA = 0;
          guiaRemi.DETGRE_PESOBRUTO = 0;
          guiaRemi.DETGRE_PESOTARA = 0;
          guiaRemi.DETGRE_NETO = 0;
          guiaRemi.DETGRE_VOLUMEN = 0;
          guiaRemi.DETGRE_PORIVA=0;//CDPJ
          guiaRemi.TARIIVA_CODIGO='-1';//CDPJ
          //guiasRemi.push(guiaRemi);
          //guiaRemi = {};
          console.log('arriba');
          fila=fila+1
          }
            this.nuevodetalleGuia();
            const guiaRemi:VendetGuiaRemision =this.detalleGuiaSeleccionado;
            guiaRemi.BOD_CODIGO = valorDetped.BOD_CODIGO;
            guiaRemi.DETGRE_CODIGO = valorDetped.DETPED_CODIGO;
            guiaRemi.DETGRE_DESCRIPCION = valorDetped.DETPED_DESCRIPCION;
            guiaRemi.DETGRE_TRIBIVA = valorDetped.DETPED_TRIBIVA;
            guiaRemi.DETGRE_TRIBICE = valorDetped.DETPED_TRIBICE;
            guiaRemi.DETGRE_UNIDAD = valorDetped.DETPED_UNIDAD;
            guiaRemi.DETGRE_CANTIDAD = valorDetped.DETPED_CANTIDAD-(valorDetped.DETPED_DESPACHO===null||valorDetped.DETPED_DESPACHO===undefined?0:valorDetped.DETPED_DESPACHO);
            guiaRemi.DETGRE_PRECIO = valorDetped.DETPED_PRECIO;
            guiaRemi.DETGRE_TOTAL = valorDetped.DETPED_TOTAL;
            guiaRemi.DETGRE_DESCUENTO = valorDetped.DETPED_DESCUENTO;
            guiaRemi.DETGRE_LISTA = valorDetped.DETPED_LISTA;
            guiaRemi.DETGRE_TIPODET = valorDetped.DETPED_TIPODET;
            guiaRemi.DETPED_LINEA = valorDetped.DETPED_LINEA;
            guiaRemi.LINEA = 0;//CDPJ
            guiaRemi.DETGRE_LINEA = this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length + 1;//CDPJ
            guiaRemi.DETGRE_CANTIDADB = 0;
            guiaRemi.DETGRE_CANTIDADUND = '';
            guiaRemi.DETGRE_BULTO = 0;
            guiaRemi.CEN_CODIGO = valorDetped.CEN_CODIGO === undefined ? '' : valorDetped.CEN_CODIGO;
            guiaRemi.ENCFAC_NUMERO = '';
            guiaRemi.ENCPED_NUMERO = valorDetped.ENCPED_NUMERO;
            guiaRemi.DETGRE_DESPACHO = 0;
            guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
            guiaRemi.DETGRE_IVA = valorDetped.DETPED_IVA;
            guiaRemi.DETGRE_ICE = valorDetped.DETPED_ICE;
            guiaRemi.DETGRE_PORCEICE = valorDetped.DETPED_PORCEICE;
            guiaRemi.ENCGRE_CODIGO = '';
            guiaRemi.COM_CODIGO = '01';
            guiaRemi.DETFAC_COMCODIGO = '';
            guiaRemi.DETGRE_BASEIVA = 0;
            guiaRemi.DETGRE_BASEICE = 0;
            guiaRemi.DETGRE_BASECERO = 0;
            guiaRemi.DETGRE_PROMOCION = valorDetped.DETPED_PROMOCION;
            guiaRemi.DETGRE_ORDEN = 0;
            guiaRemi.DETFAC_LINEA = 0;
            guiaRemi.DETGRE_PESOBRUTO = 0;
            guiaRemi.DETGRE_PESOTARA = 0;
            guiaRemi.DETGRE_NETO = 0;
            guiaRemi.DETGRE_VOLUMEN = 0;
            guiaRemi.DETGRE_PORIVA=valorDetped.DETPED_PORIVA;//CDPJ
            guiaRemi.TARIIVA_CODIGO=valorDetped.TARIIVA_CODIGO;//CDPJ
            guiaRemi.DETPED_CANTIDAD=valorDetped.DETPED_CANTIDAD;//CDPJ
            guiaRemi.ART_CANTMIN=valorDetped.ART_CANTMIN;//CDPJ
            //guiasRemi.push(guiaRemi);
            //guiaRemi = {};
            
            
            if(final === valor.length){
          this.nuevodetalleGuia();
          const guiaRemi:VendetGuiaRemision =this.detalleGuiaSeleccionado;
          guiaRemi.BOD_CODIGO = valor[0].BOD_CODIGO;
          guiaRemi.DETGRE_CODIGO = '';
          guiaRemi.DETGRE_DESCRIPCION = '=====================================';
          guiaRemi.DETGRE_TRIBIVA = '';
          guiaRemi.DETGRE_TRIBICE = '';
          guiaRemi.DETGRE_UNIDAD = '';
          guiaRemi.DETGRE_CANTIDAD = '';
          guiaRemi.DETGRE_PRECIO = '';
          guiaRemi.DETGRE_TOTAL = '';
          guiaRemi.DETGRE_DESCUENTO = '';
          guiaRemi.DETGRE_LISTA = valor[0].DETPED_LISTA;
          guiaRemi.DETGRE_TIPODET = '*';
          guiaRemi.DETPED_LINEA = valor.length + 1;
          guiaRemi.LINEA = 0;//CDPJ
          guiaRemi.DETGRE_LINEA = this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length + 1;//CDPJ
          guiaRemi.DETGRE_CANTIDADB = 0;
          guiaRemi.DETGRE_CANTIDADUND = '';
          guiaRemi.DETGRE_BULTO = 0;
          guiaRemi.CEN_CODIGO = '';
          guiaRemi.ENCFAC_NUMERO = '';
          guiaRemi.ENCPED_NUMERO = valor[0].ENCPED_NUMERO;
          guiaRemi.DETGRE_DESPACHO = 0;
          guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
          guiaRemi.DETGRE_IVA = '';
          guiaRemi.DETGRE_ICE = '';
          guiaRemi.DETGRE_PORCEICE = '';
          guiaRemi.ENCGRE_CODIGO = '';
          guiaRemi.COM_CODIGO = '01';
          guiaRemi.DETFAC_COMCODIGO = '';
          guiaRemi.DETGRE_BASEIVA = 0;
          guiaRemi.DETGRE_BASEICE = 0;
          guiaRemi.DETGRE_BASECERO = 0;
          guiaRemi.DETGRE_PROMOCION = '';
          guiaRemi.DETGRE_ORDEN = 0;
          guiaRemi.DETFAC_LINEA = 0;
          guiaRemi.DETGRE_PESOBRUTO = 0;
          guiaRemi.DETGRE_PESOTARA = 0;
          guiaRemi.DETGRE_NETO = 0;
          guiaRemi.DETGRE_VOLUMEN = 0;
          guiaRemi.DETGRE_PORIVA=0;//CDPJ
          guiaRemi.TARIIVA_CODIGO='-1';//CDPJ
          //guiasRemi.push(guiaRemi);
          //guiaRemi = {};
            }
        }
         
          });
          
        }
      });

      //this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = guiasRemi;

      // for (const det of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
      //   if (det.DETGRE_TIPODET === 'A') {
      //     if (this.venenguiaRemisionService.getConfigFCEXNEG() === 0) {
      //       await this.venenguiaRemisionService.cantidadVsExistencia(det);
      //     }
      //   }
      // }
      for (const det of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
        if (det.DETGRE_TIPODET === 'A') {
          if(this.strNemonico === 'NE'){
            await this.venenguiaRemisionService.cantidadVsExistenciaNE(det);
            
          }else{
            if (this.venenguiaRemisionService.getConfigFCEXNEG() === 0) {
              await this.venenguiaRemisionService.cantidadVsExistencia(det);
            }
          }
         
        }
      }
      if( this.venenguiaRemisionService.control>0){
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'warn',
          summary: 'Información',
          detail: 'La cantidad que se ha ingresado es mayor a la cantidad en existencia del articulo'
        });
      }
      this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);
      this.venenguiaRemisionService.calcularTotal();
      this.displayDialogPedidos = false;
    }

    if (this.intACTPEDVAL === 1) {
      valores.map((valor) => {       
        if (valor.length !== 0) {       
          let fila=1;
          let lineadetped=0
          let final=0
          valor.map((valorDetped) => {
            final=final+1
            const dblCantGuia =valorDetped.DETPED_FACTURADO
            let dblCantPend = 0;
            dblCantPend=valorDetped.DETPED_PRECIO-dblCantGuia
            const dblCantGuiaActual=this.obtenerTotalDetGuiaActual(valorDetped.DETPED_CODIGO, valorDetped.DETPED_LINEA,
              0, '', valorDetped.ENCPED_NUMERO);
              if(dblCantPend - dblCantGuiaActual > 0 || valorDetped.DETPED_TIPODET === '*'){
                this.eliminarLineaEnBlanco();
                if(fila === 1){
                  this.nuevodetalleGuia();
                const guiaRemi:VendetGuiaRemision =this.detalleGuiaSeleccionado;
                guiaRemi.BOD_CODIGO = valor[0].BOD_CODIGO;
                guiaRemi.DETGRE_CODIGO = '';
                guiaRemi.DETGRE_DESCRIPCION = '====<< PEDIDO "' + valor[0].ENCPED_NUMERO + '" >>====';
                guiaRemi.DETGRE_TRIBIVA = '';
                guiaRemi.DETGRE_TRIBICE = '';
                guiaRemi.DETGRE_UNIDAD = '';
                guiaRemi.DETGRE_CANTIDAD = '';
                guiaRemi.DETGRE_PRECIO = '';
                guiaRemi.DETGRE_TOTAL = '';
                guiaRemi.DETGRE_DESCUENTO = '';
                guiaRemi.DETGRE_LISTA = valor[0].DETPED_LISTA;
                guiaRemi.DETGRE_TIPODET = '*';
                guiaRemi.DETPED_LINEA = lineadetped;
                guiaRemi.LINEA = 0;//CDPJ
                guiaRemi.DETGRE_LINEA = this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length + 1;///CDPJ
                guiaRemi.DETGRE_CANTIDADB = 0;
                guiaRemi.DETGRE_CANTIDADUND = '';
                guiaRemi.DETGRE_BULTO = 0;
                guiaRemi.CEN_CODIGO = valor[0].CEN_CODIGO === undefined ? '' : valor[0].CEN_CODIGO;
                guiaRemi.ENCFAC_NUMERO = '';
                guiaRemi.ENCPED_NUMERO = valor[0].ENCPED_NUMERO;
                guiaRemi.DETGRE_DESPACHO = 0;
                guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
                guiaRemi.DETGRE_IVA = '';
                guiaRemi.DETGRE_ICE = '';
                guiaRemi.DETGRE_PORCEICE = '';
                guiaRemi.ENCGRE_CODIGO = '';
                guiaRemi.COM_CODIGO = '01';
                guiaRemi.DETFAC_COMCODIGO = '';
                guiaRemi.DETGRE_BASEIVA = 0;
                guiaRemi.DETGRE_BASEICE = 0;
                guiaRemi.DETGRE_BASECERO = 0;
                guiaRemi.DETGRE_PROMOCION = '';
                guiaRemi.DETGRE_ORDEN = 0;
                guiaRemi.DETFAC_LINEA = 0;
                guiaRemi.DETGRE_PESOBRUTO = 0;
                guiaRemi.DETGRE_PESOTARA = 0;
                guiaRemi.DETGRE_NETO = 0;
                guiaRemi.DETGRE_VOLUMEN = 0;
                guiaRemi.DETGRE_PORIVA=0;//CDPJ
                guiaRemi.TARIIVA_CODIGO='-1';//CDPJ
                // guiasRemi.push(guiaRemi);
                // guiaRemi = {};
                console.log('abajo');
                fila=fila+1
                }
                  this.nuevodetalleGuia();
                const guiaRemi:VendetGuiaRemision =this.detalleGuiaSeleccionado;
                  if (valorDetped.DETPED_TIPODET !== 'Y') {
                    guiaRemi.BOD_CODIGO = valorDetped.BOD_CODIGO;
                    guiaRemi.DETGRE_CODIGO = valorDetped.DETPED_CODIGO;
                    guiaRemi.DETGRE_DESCRIPCION = valorDetped.DETPED_DESCRIPCION;
                    guiaRemi.DETGRE_TRIBIVA = valorDetped.DETPED_TRIBIVA;
                    guiaRemi.DETGRE_TRIBICE = valorDetped.DETPED_TRIBICE;
                    guiaRemi.DETGRE_UNIDAD = valorDetped.DETPED_UNIDAD;
                    guiaRemi.DETGRE_CANTIDAD = 1;
                    guiaRemi.DETGRE_PRECIO = valorDetped.DETPED_TOTAL - valorDetped.DETPED_FACTURADO;
                    guiaRemi.DETGRE_TOTAL = valorDetped.DETPED_TOTAL - valorDetped.DETPED_FACTURADO;
                    guiaRemi.DETGRE_DESCUENTO = valorDetped.DETPED_DESCUENTO;
                    guiaRemi.DETGRE_LISTA = valorDetped.DETPED_LISTA;
                    guiaRemi.DETGRE_TIPODET = valorDetped.DETPED_TIPODET;
                    guiaRemi.DETPED_LINEA = valorDetped.DETPED_LINEA;
                    guiaRemi.LINEA = 0;//CDPJ
                    guiaRemi.DETGRE_LINEA = this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length + 1;//CDPJ
                    guiaRemi.DETGRE_CANTIDADB = 0;
                    guiaRemi.DETGRE_CANTIDADUND = '';
                    guiaRemi.DETGRE_BULTO = 0;
                    guiaRemi.CEN_CODIGO = valorDetped.CEN_CODIGO === undefined ? '' : valorDetped.CEN_CODIGO;
                    guiaRemi.ENCFAC_NUMERO = '';
                    guiaRemi.ENCPED_NUMERO = valorDetped.ENCPED_NUMERO;
                    guiaRemi.DETGRE_DESPACHO = 0;
                    guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
                    guiaRemi.DETGRE_IVA = valorDetped.DETPED_IVA;
                    guiaRemi.DETGRE_ICE = valorDetped.DETPED_ICE;
                    guiaRemi.DETGRE_PORCEICE = valorDetped.DETPED_PORCEICE;
                    guiaRemi.ENCGRE_CODIGO = '';
                    guiaRemi.COM_CODIGO = '01';
                    guiaRemi.DETFAC_COMCODIGO = '';
                    guiaRemi.DETGRE_BASEIVA = 0;
                    guiaRemi.DETGRE_BASEICE = 0;
                    guiaRemi.DETGRE_BASECERO = 0;
                    guiaRemi.DETGRE_PROMOCION = valorDetped.DETPED_PROMOCION;
                    guiaRemi.DETGRE_ORDEN = 0;
                    guiaRemi.DETFAC_LINEA = 0;
                    guiaRemi.DETGRE_PESOBRUTO = 0;
                    guiaRemi.DETGRE_PESOTARA = 0;
                    guiaRemi.DETGRE_NETO = 0;
                    guiaRemi.DETGRE_VOLUMEN = 0;
                    guiaRemi.PRECIO_FACTURADO = guiaRemi.DETGRE_PRECIO;
                    guiaRemi.DETGRE_PORIVA=valorDetped.DETPED_PORIVA;//CDPJ
                    guiaRemi.TARIIVA_CODIGO=valorDetped.TARIIVA_CODIGO;//CDPJ
                    guiaRemi.DETPED_CANTIDAD=valorDetped.DETPED_CANTIDAD;//CDPJ
                    guiaRemi.ART_CANTMIN=valorDetped.ART_CANTMIN;//CDPJ
                    // guiasRemi.push(guiaRemi);
                    // guiaRemi = {};
                  }
                 
                  console.log('final',final)
                  console.log('valor.length',valor.length)
                  if(final === valor.length){
                    this.nuevodetalleGuia();
          const guiaRemi:VendetGuiaRemision =this.detalleGuiaSeleccionado;
          guiaRemi.BOD_CODIGO = valor[0].BOD_CODIGO;
          guiaRemi.DETGRE_CODIGO = '';
          guiaRemi.DETGRE_DESCRIPCION = '=====================================';
          guiaRemi.DETGRE_TRIBIVA = '';
          guiaRemi.DETGRE_TRIBICE = '';
          guiaRemi.DETGRE_UNIDAD = '';
          guiaRemi.DETGRE_CANTIDAD = '';
          guiaRemi.DETGRE_PRECIO = '';
          guiaRemi.DETGRE_TOTAL = '';
          guiaRemi.DETGRE_DESCUENTO = '';
          guiaRemi.DETGRE_LISTA = valor[0].DETPED_LISTA;
          guiaRemi.DETGRE_TIPODET = '*';
          guiaRemi.DETPED_LINEA = valor.length + 1;
          guiaRemi.LINEA = 0;//CDPJ
          guiaRemi.DETGRE_LINEA = this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length + 1;//CDPJ
          guiaRemi.DETGRE_CANTIDADB = 0;
          guiaRemi.DETGRE_CANTIDADUND = '';
          guiaRemi.DETGRE_BULTO = 0;
          guiaRemi.CEN_CODIGO = '';
          guiaRemi.ENCFAC_NUMERO = '';
          guiaRemi.ENCPED_NUMERO = valor[0].ENCPED_NUMERO;
          guiaRemi.DETGRE_DESPACHO = 0;
          guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
          guiaRemi.DETGRE_IVA = '';
          guiaRemi.DETGRE_ICE = '';
          guiaRemi.DETGRE_PORCEICE = '';
          guiaRemi.ENCGRE_CODIGO = '';
          guiaRemi.COM_CODIGO = '01';
          guiaRemi.DETFAC_COMCODIGO = '';
          guiaRemi.DETGRE_BASEIVA = 0;
          guiaRemi.DETGRE_BASEICE = 0;
          guiaRemi.DETGRE_BASECERO = 0;
          guiaRemi.DETGRE_PROMOCION = '';
          guiaRemi.DETGRE_ORDEN = 0;
          guiaRemi.DETFAC_LINEA = 0;
          guiaRemi.DETGRE_PESOBRUTO = 0;
          guiaRemi.DETGRE_PESOTARA = 0;
          guiaRemi.DETGRE_NETO = 0;
          guiaRemi.DETGRE_VOLUMEN = 0;
          guiaRemi.DETGRE_PORIVA=0;//CDPJ
          guiaRemi.TARIIVA_CODIGO='-1';//CDPJ
          // guiasRemi.push(guiaRemi);
          // guiaRemi = {};
                  }
              }
           

          });
          
        }
      });

      //this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = guiasRemi;

      // for (const det of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
      //   if (det.DETGRE_TIPODET === 'A') {
      //     if (this.venenguiaRemisionService.getConfigFCEXNEG() === 0) {
      //       await this.venenguiaRemisionService.cantidadVsExistencia(det);
      //     }
      //   }
      // }
      for (const det of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
        if (det.DETGRE_TIPODET === 'A') {
          if(this.strNemonico === 'NE'){
            await this.venenguiaRemisionService.cantidadVsExistenciaNE(det);
            
          }else{
            if (this.venenguiaRemisionService.getConfigFCEXNEG() === 0) {
              await this.venenguiaRemisionService.cantidadVsExistencia(det);
            }
          }
         
        }
      }
      if( this.venenguiaRemisionService.control>0){
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'warn',
          summary: 'Información',
          detail: 'La cantidad que se ha ingresado es mayor a la cantidad en existencia del articulo'
        });
      }
      this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);
      this.venenguiaRemisionService.calcularTotal();
      this.displayDialogPedidos = false;
    }
  }
}
//CDPJ
  // async recibirDetallesPedidos(valores) {

  //   let guiaRemi: VendetGuiaRemision;
  //   let guiasRemi: VendetGuiaRemision[];
  //   guiasRemi = [];
  //   guiaRemi = {};
  //   if (valores !== null) {
  //     if (this.intACTPEDVAL === 0) {
  //       valores.map((valor) => {
  //         if (valor.length !== 0) {
  //           ///////////
  //           guiaRemi.BOD_CODIGO = valor[0].BOD_CODIGO;
  //           guiaRemi.DETGRE_CODIGO = '';
  //           guiaRemi.DETGRE_DESCRIPCION = '====<< PEDIDO "' + valor[0].ENCPED_NUMERO + '" >>====';
  //           guiaRemi.DETGRE_TRIBIVA = '';
  //           guiaRemi.DETGRE_TRIBICE = '';
  //           guiaRemi.DETGRE_UNIDAD = '';
  //           guiaRemi.DETGRE_CANTIDAD = '';
  //           guiaRemi.DETGRE_PRECIO = '';
  //           guiaRemi.DETGRE_TOTAL = '';
  //           guiaRemi.DETGRE_DESCUENTO = '';
  //           guiaRemi.DETGRE_LISTA = valor[0].DETPED_LISTA;
  //           guiaRemi.DETGRE_TIPODET = '*';
  //           guiaRemi.DETPED_LINEA = guiasRemi.length + 1;
  //           guiaRemi.LINEA = 0//CDPJ
  //           guiaRemi.DETGRE_LINEA = guiasRemi.length + 1;//CDPJ
  //           guiaRemi.DETGRE_CANTIDADB = 0;
  //           guiaRemi.DETGRE_CANTIDADUND = '';
  //           guiaRemi.DETGRE_BULTO = 0;
  //           guiaRemi.CEN_CODIGO = '';//CDPJ
  //           guiaRemi.ENCFAC_NUMERO = '';
  //           guiaRemi.ENCPED_NUMERO = valor[0].ENCPED_NUMERO;
  //           guiaRemi.DETGRE_DESPACHO = 0;
  //           guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
  //           guiaRemi.DETGRE_IVA = '';
  //           guiaRemi.DETGRE_ICE = '';
  //           guiaRemi.DETGRE_PORCEICE = '';
  //           guiaRemi.ENCGRE_CODIGO = '';
  //           guiaRemi.COM_CODIGO = '01';
  //           guiaRemi.DETFAC_COMCODIGO = '';
  //           guiaRemi.DETGRE_BASEIVA = 0;
  //           guiaRemi.DETGRE_BASEICE = 0;
  //           guiaRemi.DETGRE_BASECERO = 0;
  //           guiaRemi.DETGRE_PROMOCION = '';
  //           guiaRemi.DETGRE_ORDEN = 0;
  //           guiaRemi.DETFAC_LINEA = 0;
  //           guiaRemi.DETGRE_PESOBRUTO = 0;
  //           guiaRemi.DETGRE_PESOTARA = 0;
  //           guiaRemi.DETGRE_NETO = 0;
  //           guiaRemi.DETGRE_VOLUMEN = 0;
  //           guiasRemi.push(guiaRemi);
  //           guiaRemi = {};
  //           console.log('arriba');
  //           ////////////////

  //           valor.map((valorDetped) => {
  //             guiaRemi.BOD_CODIGO = valorDetped.BOD_CODIGO;
  //             guiaRemi.DETGRE_CODIGO = valorDetped.DETPED_CODIGO;
  //             guiaRemi.DETGRE_DESCRIPCION = valorDetped.DETPED_DESCRIPCION;
  //             guiaRemi.DETGRE_TRIBIVA = valorDetped.DETPED_TRIBIVA;
  //             guiaRemi.DETGRE_TRIBICE = valorDetped.DETPED_TRIBICE;
  //             guiaRemi.DETGRE_UNIDAD = valorDetped.DETPED_UNIDAD;
  //             guiaRemi.DETGRE_CANTIDAD = valorDetped.DETPED_CANTIDAD-(valorDetped.DETPED_DESPACHO===null||valorDetped.DETPED_DESPACHO===undefined?0:valorDetped.DETPED_DESPACHO);
  //             guiaRemi.DETGRE_PRECIO = valorDetped.DETPED_PRECIO;
  //             guiaRemi.DETGRE_TOTAL = valorDetped.DETPED_TOTAL;
  //             guiaRemi.DETGRE_DESCUENTO = valorDetped.DETPED_DESCUENTO;
  //             guiaRemi.DETGRE_LISTA = valorDetped.DETPED_LISTA;
  //             guiaRemi.DETGRE_TIPODET = valorDetped.DETPED_TIPODET;
  //             guiaRemi.DETPED_LINEA = valorDetped.DETPED_LINEA;
  //             guiaRemi.LINEA = 0;//CDPJ
  //             guiaRemi.DETGRE_LINEA = guiasRemi.length + 1;//CDPJ
  //             guiaRemi.DETGRE_CANTIDADB = 0;
  //             guiaRemi.DETGRE_CANTIDADUND = '';
  //             guiaRemi.DETGRE_BULTO = 0;
  //             guiaRemi.CEN_CODIGO = valorDetped.CEN_CODIGO === undefined ? '' : valorDetped.CEN_CODIGO;
  //             guiaRemi.ENCFAC_NUMERO = '';
  //             guiaRemi.ENCPED_NUMERO = valorDetped.ENCPED_NUMERO;
  //             guiaRemi.DETGRE_DESPACHO = 0;
  //             guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
  //             guiaRemi.DETGRE_IVA = valorDetped.DETPED_IVA;
  //             guiaRemi.DETGRE_ICE = valorDetped.DETPED_ICE;
  //             guiaRemi.DETGRE_PORCEICE = valorDetped.DETPED_PORCEICE;
  //             guiaRemi.ENCGRE_CODIGO = '';
  //             guiaRemi.COM_CODIGO = '01';
  //             guiaRemi.DETFAC_COMCODIGO = '';
  //             guiaRemi.DETGRE_BASEIVA = 0;
  //             guiaRemi.DETGRE_BASEICE = 0;
  //             guiaRemi.DETGRE_BASECERO = 0;
  //             guiaRemi.DETGRE_PROMOCION = valorDetped.DETPED_PROMOCION;
  //             guiaRemi.DETGRE_ORDEN = 0;
  //             guiaRemi.DETFAC_LINEA = 0;
  //             guiaRemi.DETGRE_PESOBRUTO = 0;
  //             guiaRemi.DETGRE_PESOTARA = 0;
  //             guiaRemi.DETGRE_NETO = 0;
  //             guiaRemi.DETGRE_VOLUMEN = 0;
  //             guiasRemi.push(guiaRemi);
  //             guiaRemi = {};
  //           });
  //           guiaRemi.BOD_CODIGO = valor[0].BOD_CODIGO;
  //           guiaRemi.DETGRE_CODIGO = '';
  //           guiaRemi.DETGRE_DESCRIPCION = '=====================================';
  //           guiaRemi.DETGRE_TRIBIVA = '';
  //           guiaRemi.DETGRE_TRIBICE = '';
  //           guiaRemi.DETGRE_UNIDAD = '';
  //           guiaRemi.DETGRE_CANTIDAD = '';
  //           guiaRemi.DETGRE_PRECIO = '';
  //           guiaRemi.DETGRE_TOTAL = '';
  //           guiaRemi.DETGRE_DESCUENTO = '';
  //           guiaRemi.DETGRE_LISTA = valor[0].DETPED_LISTA;
  //           guiaRemi.DETGRE_TIPODET = '*';
  //           guiaRemi.DETPED_LINEA = guiasRemi.length + 1;
  //           guiaRemi.LINEA = 0;//CDPJ
  //           guiaRemi.DETGRE_LINEA = guiasRemi.length + 1;//CDPJ
  //           guiaRemi.DETGRE_CANTIDADB = 0;
  //           guiaRemi.DETGRE_CANTIDADUND = '';
  //           guiaRemi.DETGRE_BULTO = 0;
  //           guiaRemi.CEN_CODIGO = '';
  //           guiaRemi.ENCFAC_NUMERO = '';
  //           guiaRemi.ENCPED_NUMERO = valor[0].ENCPED_NUMERO;
  //           guiaRemi.DETGRE_DESPACHO = 0;
  //           guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
  //           guiaRemi.DETGRE_IVA = '';
  //           guiaRemi.DETGRE_ICE = '';
  //           guiaRemi.DETGRE_PORCEICE = '';
  //           guiaRemi.ENCGRE_CODIGO = '';
  //           guiaRemi.COM_CODIGO = '01';
  //           guiaRemi.DETFAC_COMCODIGO = '';
  //           guiaRemi.DETGRE_BASEIVA = 0;
  //           guiaRemi.DETGRE_BASEICE = 0;
  //           guiaRemi.DETGRE_BASECERO = 0;
  //           guiaRemi.DETGRE_PROMOCION = '';
  //           guiaRemi.DETGRE_ORDEN = 0;
  //           guiaRemi.DETFAC_LINEA = 0;
  //           guiaRemi.DETGRE_PESOBRUTO = 0;
  //           guiaRemi.DETGRE_PESOTARA = 0;
  //           guiaRemi.DETGRE_NETO = 0;
  //           guiaRemi.DETGRE_VOLUMEN = 0;
  //           guiasRemi.push(guiaRemi);
  //           guiaRemi = {};
  //         }
  //       });

  //       this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = guiasRemi;

  //       for (const det of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
  //         if (det.DETGRE_TIPODET === 'A') {
  //           if (this.venenguiaRemisionService.getConfigFCEXNEG() === 0) {
  //             await this.venenguiaRemisionService.cantidadVsExistencia(det);
  //           }
  //         }
  //       }

  //       this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);
  //       this.venenguiaRemisionService.calcularTotal();
  //       this.displayDialogPedidos = false;
  //     }

  //     if (this.intACTPEDVAL === 1) {
  //       valores.map((valor) => {
  //         if (valor.length !== 0) {
  //           /////////////////
  //           guiaRemi.BOD_CODIGO = valor[0].BOD_CODIGO;
  //           guiaRemi.DETGRE_CODIGO = '';
  //           guiaRemi.DETGRE_DESCRIPCION = '====<< PEDIDO "' + valor[0].ENCPED_NUMERO + '" >>====';
  //           guiaRemi.DETGRE_TRIBIVA = '';
  //           guiaRemi.DETGRE_TRIBICE = '';
  //           guiaRemi.DETGRE_UNIDAD = '';
  //           guiaRemi.DETGRE_CANTIDAD = '';
  //           guiaRemi.DETGRE_PRECIO = '';
  //           guiaRemi.DETGRE_TOTAL = '';
  //           guiaRemi.DETGRE_DESCUENTO = '';
  //           guiaRemi.DETGRE_LISTA = valor[0].DETPED_LISTA;
  //           guiaRemi.DETGRE_TIPODET = '*';
  //           guiaRemi.DETPED_LINEA = guiasRemi.length + 1;
  //           guiaRemi.LINEA = 0;//CDPJ
  //           guiaRemi.DETGRE_LINEA = guiasRemi.length + 1;///CDPJ
  //           guiaRemi.DETGRE_CANTIDADB = 0;
  //           guiaRemi.DETGRE_CANTIDADUND = '';
  //           guiaRemi.DETGRE_BULTO = 0;
  //           guiaRemi.CEN_CODIGO = valor[0].CEN_CODIGO === undefined ? '' : valor[0].CEN_CODIGO;
  //           guiaRemi.ENCFAC_NUMERO = '';
  //           guiaRemi.ENCPED_NUMERO = valor[0].ENCPED_NUMERO;
  //           guiaRemi.DETGRE_DESPACHO = 0;
  //           guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
  //           guiaRemi.DETGRE_IVA = '';
  //           guiaRemi.DETGRE_ICE = '';
  //           guiaRemi.DETGRE_PORCEICE = '';
  //           guiaRemi.ENCGRE_CODIGO = '';
  //           guiaRemi.COM_CODIGO = '01';
  //           guiaRemi.DETFAC_COMCODIGO = '';
  //           guiaRemi.DETGRE_BASEIVA = 0;
  //           guiaRemi.DETGRE_BASEICE = 0;
  //           guiaRemi.DETGRE_BASECERO = 0;
  //           guiaRemi.DETGRE_PROMOCION = '';
  //           guiaRemi.DETGRE_ORDEN = 0;
  //           guiaRemi.DETFAC_LINEA = 0;
  //           guiaRemi.DETGRE_PESOBRUTO = 0;
  //           guiaRemi.DETGRE_PESOTARA = 0;
  //           guiaRemi.DETGRE_NETO = 0;
  //           guiaRemi.DETGRE_VOLUMEN = 0;
  //           guiasRemi.push(guiaRemi);
  //           guiaRemi = {};
  //           console.log('abajo');
  //           //////////////////

  //           valor.map((valorDetped) => {
  //             if (valorDetped.DETPED_TIPODET !== 'Y') {
  //               guiaRemi.BOD_CODIGO = valorDetped.BOD_CODIGO;
  //               guiaRemi.DETGRE_CODIGO = valorDetped.DETPED_CODIGO;
  //               guiaRemi.DETGRE_DESCRIPCION = valorDetped.DETPED_DESCRIPCION;
  //               guiaRemi.DETGRE_TRIBIVA = valorDetped.DETPED_TRIBIVA;
  //               guiaRemi.DETGRE_TRIBICE = valorDetped.DETPED_TRIBICE;
  //               guiaRemi.DETGRE_UNIDAD = valorDetped.DETPED_UNIDAD;
  //               guiaRemi.DETGRE_CANTIDAD = 1;
  //               guiaRemi.DETGRE_PRECIO = valorDetped.DETPED_TOTAL - valorDetped.DETPED_FACTURADO;
  //               guiaRemi.DETGRE_TOTAL = valorDetped.DETPED_TOTAL - valorDetped.DETPED_FACTURADO;
  //               guiaRemi.DETGRE_DESCUENTO = valorDetped.DETPED_DESCUENTO;
  //               guiaRemi.DETGRE_LISTA = valorDetped.DETPED_LISTA;
  //               guiaRemi.DETGRE_TIPODET = valorDetped.DETPED_TIPODET;
  //               guiaRemi.DETPED_LINEA = valorDetped.DETPED_LINEA;
  //               guiaRemi.LINEA = 0;//CDPJ
  //               guiaRemi.DETGRE_LINEA = guiasRemi.length + 1;//CDPJ
  //               guiaRemi.DETGRE_CANTIDADB = 0;
  //               guiaRemi.DETGRE_CANTIDADUND = '';
  //               guiaRemi.DETGRE_BULTO = 0;
  //               guiaRemi.CEN_CODIGO = valorDetped.CEN_CODIGO === undefined ? '' : valorDetped.CEN_CODIGO;
  //               guiaRemi.ENCFAC_NUMERO = '';
  //               guiaRemi.ENCPED_NUMERO = valorDetped.ENCPED_NUMERO;
  //               guiaRemi.DETGRE_DESPACHO = 0;
  //               guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
  //               guiaRemi.DETGRE_IVA = valorDetped.DETPED_IVA;
  //               guiaRemi.DETGRE_ICE = valorDetped.DETPED_ICE;
  //               guiaRemi.DETGRE_PORCEICE = valorDetped.DETPED_PORCEICE;
  //               guiaRemi.ENCGRE_CODIGO = '';
  //               guiaRemi.COM_CODIGO = '01';
  //               guiaRemi.DETFAC_COMCODIGO = '';
  //               guiaRemi.DETGRE_BASEIVA = 0;
  //               guiaRemi.DETGRE_BASEICE = 0;
  //               guiaRemi.DETGRE_BASECERO = 0;
  //               guiaRemi.DETGRE_PROMOCION = valorDetped.DETPED_PROMOCION;
  //               guiaRemi.DETGRE_ORDEN = 0;
  //               guiaRemi.DETFAC_LINEA = 0;
  //               guiaRemi.DETGRE_PESOBRUTO = 0;
  //               guiaRemi.DETGRE_PESOTARA = 0;
  //               guiaRemi.DETGRE_NETO = 0;
  //               guiaRemi.DETGRE_VOLUMEN = 0;
  //               guiaRemi.PRECIO_FACTURADO = guiaRemi.DETGRE_PRECIO;
  //               guiasRemi.push(guiaRemi);
  //               guiaRemi = {};
  //             }

  //           });
  //           guiaRemi.BOD_CODIGO = valor[0].BOD_CODIGO;
  //           guiaRemi.DETGRE_CODIGO = '';
  //           guiaRemi.DETGRE_DESCRIPCION = '=====================================';
  //           guiaRemi.DETGRE_TRIBIVA = '';
  //           guiaRemi.DETGRE_TRIBICE = '';
  //           guiaRemi.DETGRE_UNIDAD = '';
  //           guiaRemi.DETGRE_CANTIDAD = '';
  //           guiaRemi.DETGRE_PRECIO = '';
  //           guiaRemi.DETGRE_TOTAL = '';
  //           guiaRemi.DETGRE_DESCUENTO = '';
  //           guiaRemi.DETGRE_LISTA = valor[0].DETPED_LISTA;
  //           guiaRemi.DETGRE_TIPODET = '*';
  //           guiaRemi.DETPED_LINEA = guiasRemi.length + 1;
  //           guiaRemi.LINEA = 0;//CDPJ
  //           guiaRemi.DETGRE_LINEA = guiasRemi.length + 1;//CDPJ
  //           guiaRemi.DETGRE_CANTIDADB = 0;
  //           guiaRemi.DETGRE_CANTIDADUND = '';
  //           guiaRemi.DETGRE_BULTO = 0;
  //           guiaRemi.CEN_CODIGO = '';
  //           guiaRemi.ENCFAC_NUMERO = '';
  //           guiaRemi.ENCPED_NUMERO = valor[0].ENCPED_NUMERO;
  //           guiaRemi.DETGRE_DESPACHO = 0;
  //           guiaRemi.ENCGRE_NUMERO = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;
  //           guiaRemi.DETGRE_IVA = '';
  //           guiaRemi.DETGRE_ICE = '';
  //           guiaRemi.DETGRE_PORCEICE = '';
  //           guiaRemi.ENCGRE_CODIGO = '';
  //           guiaRemi.COM_CODIGO = '01';
  //           guiaRemi.DETFAC_COMCODIGO = '';
  //           guiaRemi.DETGRE_BASEIVA = 0;
  //           guiaRemi.DETGRE_BASEICE = 0;
  //           guiaRemi.DETGRE_BASECERO = 0;
  //           guiaRemi.DETGRE_PROMOCION = '';
  //           guiaRemi.DETGRE_ORDEN = 0;
  //           guiaRemi.DETFAC_LINEA = 0;
  //           guiaRemi.DETGRE_PESOBRUTO = 0;
  //           guiaRemi.DETGRE_PESOTARA = 0;
  //           guiaRemi.DETGRE_NETO = 0;
  //           guiaRemi.DETGRE_VOLUMEN = 0;
  //           guiasRemi.push(guiaRemi);
  //           guiaRemi = {};
  //         }
  //       });

  //       this.venenguiaRemisionService.detguiaRemisionarray[this.indicador] = guiasRemi;

  //       for (const det of this.venenguiaRemisionService.detguiaRemisionarray[this.indicador]) {
  //         if (det.DETGRE_TIPODET === 'A') {
  //           if (this.venenguiaRemisionService.getConfigFCEXNEG() === 0) {
  //             await this.venenguiaRemisionService.cantidadVsExistencia(det);
  //           }
  //         }
  //       }

  //       this.aggrid.refreshaggrid(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador], this.defaultGuiaDetalle.objeto);
  //       this.venenguiaRemisionService.calcularTotal();
  //       this.displayDialogPedidos = false;
  //     }
  //   }
  // }

  confirmarAnularGre() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea anular la guía',
      header: 'Anular Guía',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarGuia',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.abrirClave('actBtnAnular');
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async anularGre() {
    try {
      await this.venenguiaRemisionService.cambiarEstadoGRE(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[
        this.indicador].ENCGRE_CODIGO2, 'A').toPromise()
        //this.venenguiaRemisionService.cambiarEstadoGRE(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[
          //this.indicador].ENCGRE_CODIGO2, 'A').subscribe(() => {
            if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ASI_NRO !== '' &&
              this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ASI_NRO !== null) {
              //this.venenguiaRemisionService.anularAsiento(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ASI_NRO).subscribe(() => {
              //});
              //console.log()
              await this.venenguiaRemisionService.anularAsiento(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador]).toPromise();
            }
            //this.venenguiaRemisionService.registrarKardex(this.codigoGuiaAux).subscribe(() => {
            //});
            await this.venenguiaRemisionService.registrarKardex(this.codigoGuiaAux).toPromise()
            this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].map(async (det) => {
              try {
                if (det.ENCPED_NUMERO !== '' && det.ENCPED_NUMERO !== null) {
                  let fecha=this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                    0, 10)
                  fecha=fecha.toString().replaceAll(',','')
      
                  // this.venenguiaRemisionService.actualizarDespachoPed(det.ENCPED_NUMERO, det.DETPED_LINEA).subscribe(() => {
                  // });
                  // this.venenguiaRemisionService.verificaEstadoPedidos(det.ENCPED_NUMERO).subscribe(() => {
                  // });
                  // this.venenguiaRemisionService.actualizarFechaEntregaPedido(det.ENCPED_NUMERO,
                  //   this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                  //     0, 10)).subscribe(() => {
                  //     });
                  //CDPJ
                  await this.venenguiaRemisionService.actualizarDespachoPed(det.ENCPED_NUMERO, det.DETPED_LINEA).toPromise()
                  await this.venenguiaRemisionService.verificaEstadoPedidos(det.ENCPED_NUMERO).toPromise()
                  await this.venenguiaRemisionService.actualizarFechaEntregaPedido(det.ENCPED_NUMERO,fecha).toPromise()
                  //CDPJ
                  if (this.intACTPEDVAL === 1) {
                    // this.venenguiaRemisionService.actualizaFacturadoPedido(det.ENCPED_NUMERO).subscribe(() => {
                    // });
                    // this.venenguiaRemisionService.verificaEstadoPedidosFact(det.ENCPED_NUMERO).subscribe(() => {
                    // });
                    // this.venenguiaRemisionService.actualizarFechaEntregaPedido(det.ENCPED_NUMERO,
                    //   this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
                    //     0, 10)).subscribe(() => {
                    //     });
                    //CDPJ
                    await this.venenguiaRemisionService.actualizaFacturadoPedido(det.ENCPED_NUMERO).toPromise()
                    await this.venenguiaRemisionService.verificaEstadoPedidosFact(det.ENCPED_NUMERO).toPromise()
                    await this.venenguiaRemisionService.actualizarFechaEntregaPedido(det.ENCPED_NUMERO,fecha).toPromise()
                    //CDPJ
                  }
                }
              } catch (error) {
                this.spin=false;
              }
              
            });
            this.messageService.add({
              key: 'guiaRemision',
              severity: 'success',
              summary: 'Anular Guía',
              detail: 'Se anulo la guia de remisión'
            });
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO = 'ANULADA';
            document.getElementById('divPrincipal').style.pointerEvents = 'none';
            // document.getElementById('divPrincipal').style.opacity = '1.0';
            // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
            // document.getElementById('divPrincipal2').style.opacity = '1.0';
            this.aggrid.bloquearGrid();
          //});
    } catch (error) {
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Anular Guía',
        detail: 'Ocurrió un error al anular la guia de remisión'
      });
    }
    // this.venenguiaRemisionService.cambiarEstadoGRE(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[
    //   this.indicador].ENCGRE_CODIGO2, 'A').subscribe(() => {
    //     if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ASI_NRO !== '' &&
    //       this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ASI_NRO !== null) {
    //       this.venenguiaRemisionService.anularAsiento(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ASI_NRO).subscribe(() => {
    //       });
    //     }
    //     this.venenguiaRemisionService.registrarKardex(this.codigoGuiaAux).subscribe(() => {
    //     });
    //     this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].map(async (det) => {
    //       try {
    //         if (det.ENCPED_NUMERO !== '' && det.ENCPED_NUMERO !== null) {
    //           let fecha=this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
    //             0, 10)
    //           fecha=fecha.toString().replaceAll(',','')
  
    //           // this.venenguiaRemisionService.actualizarDespachoPed(det.ENCPED_NUMERO, det.DETPED_LINEA).subscribe(() => {
    //           // });
    //           // this.venenguiaRemisionService.verificaEstadoPedidos(det.ENCPED_NUMERO).subscribe(() => {
    //           // });
    //           // this.venenguiaRemisionService.actualizarFechaEntregaPedido(det.ENCPED_NUMERO,
    //           //   this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
    //           //     0, 10)).subscribe(() => {
    //           //     });
    //           //CDPJ
    //           await this.venenguiaRemisionService.actualizarDespachoPed(det.ENCPED_NUMERO, det.DETPED_LINEA).toPromise()
    //           await this.venenguiaRemisionService.verificaEstadoPedidos(det.ENCPED_NUMERO).toPromise()
    //           await this.venenguiaRemisionService.actualizarFechaEntregaPedido(det.ENCPED_NUMERO,fecha).toPromise()
    //           //CDPJ
    //           if (this.intACTPEDVAL === 1) {
    //             // this.venenguiaRemisionService.actualizaFacturadoPedido(det.ENCPED_NUMERO).subscribe(() => {
    //             // });
    //             // this.venenguiaRemisionService.verificaEstadoPedidosFact(det.ENCPED_NUMERO).subscribe(() => {
    //             // });
    //             // this.venenguiaRemisionService.actualizarFechaEntregaPedido(det.ENCPED_NUMERO,
    //             //   this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.toLocaleString().substr(
    //             //     0, 10)).subscribe(() => {
    //             //     });
    //             //CDPJ
    //             await this.venenguiaRemisionService.actualizaFacturadoPedido(det.ENCPED_NUMERO).toPromise()
    //             await this.venenguiaRemisionService.verificaEstadoPedidosFact(det.ENCPED_NUMERO).toPromise()
    //             await this.venenguiaRemisionService.actualizarFechaEntregaPedido(det.ENCPED_NUMERO,fecha).toPromise()
    //             //CDPJ
    //           }
    //         }
    //       } catch (error) {
    //         this.spin=false;
    //       }
          
    //     });
    //     this.messageService.add({
    //       key: 'guiaRemision',
    //       severity: 'success',
    //       summary: 'Anular Guía',
    //       detail: 'Se anulo la guia de remisión'
    //     });
    //     this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO = 'ANULADA';
    //     document.getElementById('divPrincipal').style.pointerEvents = 'none';
    //     // document.getElementById('divPrincipal').style.opacity = '1.0';
    //     // document.getElementById('divPrincipal2').style.pointerEvents = 'none';
    //     // document.getElementById('divPrincipal2').style.opacity = '1.0';
    //     this.aggrid.bloquearGrid();
    //   });
  }

  confirmarGenerarAsiento() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea generar el asiento',
      header: 'Asiento Guía',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarGuia',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        if (this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length !== 0) {
          //  this.venenguiaRemisionService.generarAsientoContable(this.codigoGuiaAux, this.usuario.identificacion).subscribe(() => {
          console.log('this.codigoGuiaAux ', this.codigoGuiaAux);
          this.venenguiaRemisionService.generarAsientoContable(this.codigoGuiaAux, this.usuario.identificacion).subscribe(() => {
            this.messageService.add({
              key: 'guiaRemision',
              severity: 'success',
              summary: 'Asiento Guía',
              detail: 'Se ingreso un asiento a la guia de remisión'
            });
          }, error1 => {
            this.messageService.add({
              key: 'guiaRemision',
              severity: 'error',
              summary: 'Asiento Contable ',
              detail: 'No se pudo generar el asiento contable'
            });
          });
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  acciones(valor) {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 0; i < elemento.length; i++) {
        elemento[i].className = 'layout-config';
      }
    }

  }

  abrirSeriales() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 0; i < elemento.length; i++) {
      elemento[i].className = 'layout-config';
    }
    this.displayDialogSeriales = true;
  }

  comprobarExistencia(existencia) {
    let color=''
    if (existencia.DETGRE_CODIGO !== '' && existencia.DETGRE_TIPODET === 'A') {
      if (Number(existencia.EXISTENCIA) >= Number(existencia.ART_CANTMIN)) {
        color= 'transparent';
      } 
      if ((Number(existencia.EXISTENCIA) < Number(existencia.ART_CANTMIN)) || (Number(existencia.EXISTENCIA) === 0)) {
        color =this.choosedColor;
        return color
      } 
      if(Number(existencia.EXISTENCIA)<Number(existencia.DETPED_CANTIDAD)){
        color =this.choosedColorSnSk;
      }
    }
    return color

  }

  comprobarPrecio(precio) {
    if (precio.DETGRE_CODIGO !== '') {
      if (Number(precio.DETGRE_PRECIO) < Number(precio.COSTOPROM)) {
        return this.choosedColorR;
      } else if (Number(precio.DETGRE_PRECIO) >= Number(precio.COSTOPROM)) {
        return 'transparent';
      }
    }
  }

  ingresarInfoAdi() {
    if (this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].length !== 0) {
      this.venenguiaRemisionService.detguiaReferenciaarray[this.indicador].map((infoadi) => {
        this.venenguiaRemisionService.guardarInfoAdi(infoadi).subscribe(() => {
          if (infoadi.INFAD_NUMSEC === 0) {
            infoadi.INFAD_NUMSEC = infoadi.LINEA;
            this.auditoriagral.registrarAuditoria('VEN_INFOADICGRE', String(infoadi.ENCGRE_NUMERO), 'I',
              '', '01', '', '', '', '').subscribe(() => {
              });
          } else if (infoadi.INFAD_NUMSEC !== 0) {
            this.auditoriagral.registrarAuditoria('VEN_INFOADICGRE', String(infoadi.ENCGRE_NUMERO), 'A',
              '', '01', '', '', '', '').subscribe(() => {
              });
          }
        });
      });
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'success',
        summary: 'Inserción de Información Adicional',
        detail: 'Información guardada exitosamente'
      });
    }
  }

  habilitarPorcIva() {
    this.porcIvaBoolean = false;
    this.activeIndex = 1;
    this.acciones('value');
    this.cambio('value');
  }

  cambiarTipo(valor) {
    this.tipodetalle = valor;
    // this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].map((pedido) => {
    //   if (pedido.LINEA === this.detalleGuiaSeleccionado.LINEA) {
    //     pedido.DETGRE_TIPODET = valor;
    //     this.detalleGuiaSeleccionado.DETGRE_TIPODET = valor;
    //   }
    // });
  }

  insertarEleTrnDoc() {
    if (this.btnGuardar === false) {
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Información',
        detail: 'Primero guarde la guía'
      });
    } else {
      this.abrirClave('firmarDoc');
    }
  }

  confirmarFirmar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea firmar el documento ' + this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO + '?',
      header: 'Firmar Electrónicamente',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmarGuia',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.firmarDoc();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  firmarDoc() {
    const tipoFac = 'GRE';
    const strNumDoc = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;

    let strFecha = '';
    try {
      // Verifica el periodo contable        
      strFecha = this.datePipe.transform(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].
        ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
        console.log('En el Try',strFecha);
     //strFecha= this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.substr(0, 10);
   
    } catch (e) {
      ///GSRF
      strFecha = this.datePipe.transform(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].
          ENCGRE_FECHAEMISION, 'dd/MM/yyyy');
          console.log('En el catch',strFecha);
          ///GSRF
        //this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_FECHAEMISION.substr(0, 10);
    }
    this.venenguiaRemisionService.insertarEleTrnDoc(strNumDoc, tipoFac,
      this.usuario.identificacion, this.datosfe.COM_TIPOAMBFACTELEC).subscribe(async () => {
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE = 'VA';
        this.botonAnular = true;
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'success',
          summary: 'Firma Electrónica',
          detail: 'Proceso de firma electrónica iniciado correctamente.'
        });
        this.spin = true;
        const data = await this.utilitariosService.procesoFirmaElectronica(strNumDoc, strFecha).catch(e=>{
          this.spin = false;
        });
        if (data !== 'NOAUTORIZADO') {
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'warn',
            summary: 'Información',
            detail: data
          });
          this.spin = false;
        this.cancelarEdicionGuia();
        }else{
          this.spin = true;
          await setTimeout(async() => {
          await this.actualizarDatosElectronicos();
           this.spin = false;
           this.messageService.add({
            key: 'guiaRemision',
            severity: 'success',
            summary: 'Información',
            detail: 'Documento procesado'
          });
          }, 6000);
          
        }
        
      });
      
  }

  reversarProceso() {
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO === '') {
      return;
    }
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE !== 'AT' &&
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE !== 'NT'&&
      this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE !== 'SN') {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea reversar el proceso?',
        header: 'Reversar Proceso',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmarGuia',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          this.utilitariosService.reversarProcesoFE(
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO, '06');
          this.utilitariosService.reversarProcesoFEClave(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CLAVEACCESO_FE);
          this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO',
            String(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO + 'VEN_REVERSAR_GRE'),
            'E', '', '01', '', '', '', '').subscribe(() => {
              this.botonXML = true;
              this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE = '';
              this.messageService.add({
                key: 'guiaRemision',
                severity: 'success',
                summary: 'Reversar Proceso',
                detail: 'Reversar proceso de inicio de firma electrónica terminó satisfactoriamente'
              });
            });
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  abrirLog() {
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO !== 'ANULADA') {
      this.venenguiaRemisionService.obtenerLogFE(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO,
        '06').subscribe((res) => {
          if (res.length !== 0) {
            if (res[0] !== null) {
              this.logResultado = JSON.stringify(res[0].TRNDOC_RESULTADO);
            }
          }
          this.displayLog = true;
        });
    } else {
      return;
    }
  }
//CDPJ
async visualizarEst(){
  this.closeSidebar();
  this.estfirma='';
  const data =await this.venenguiaRemisionService.obtenerEstFE().toPromise()
  this.displayDialogEst = true;
  if(data !== null && data !== undefined){
    for (const est of data){
      this.estfirma=this.estfirma+est.REF_CODIGO+' '+est.REF_NOMBRE+'\n'
    }
  }
 
}
//CDPJ
  reenviarCorreo() {
    const fecha = new Date();
    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador] !== undefined) {
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO !== 'ANULADA') {
        if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE === 'NT') {
          this.venenguiaRemisionService.reenviarCorreo(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO).subscribe(async () => {
            const data = await this.utilitariosService.reenviarCorreo(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO);
            this.messageService.add({
              key: 'guiaRemision',
              severity: 'success',
              summary: 'Información',
              detail: data
            });
            this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO',
              String(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO + 'VEN_REV_CORREO_GRE'),
              'A', '', '01', '', '', '', '').subscribe(() => {
                this.messageService.add({
                  key: 'guiaRemision',
                  severity: 'success',
                  summary: 'Envió de correo',
                  detail: 'Proceso actualizar estado para reenvió de correo  terminó satisfactoriamente'
                });
              });
          });
        } else {
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'error',
            summary: 'Envió de correo',
            detail: 'Solo puede reenviar el correo si la factura esta notificada'
          });
        }
      } else {
        return;
      }
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'F9') {
      console.log('AbrirFact');
      this.abrirDetalleFactura();
    }
  }

  editCampos(params): boolean {


    if ((params.ENCFAC_NUMERO === '' || params.ENCFAC_NUMERO === null) && (params.ENCPED_NUMERO === '' || params.ENCPED_NUMERO === null)) {
      return true;
    }

    if (params.ENCFAC_NUMERO !== '') {
      return false;
    }

    if (params.ENCPED_NUMERO !== '') {
      return false;
    }

  }
//CDPJ
editCamposPed(params): boolean {
  if(this.BCPPG === 1){
    if(params.DETGRE_TIPODET !== '*' && (params.ENCPED_NUMERO !== null && params.ENCPED_NUMERO !=='' && params.ENCPED_NUMERO !== undefined ) ){
      if(this.aggrid.gridApi.getFocusedCell().column.colId ==='DETGRE_PRECIO' || this.aggrid.gridApi.getFocusedCell().column.colId ==='DETGRE_CANTIDAD'){
        return false
      }
    }
  }
  return true;
}
//CDPJ
  eliminarLineaEnBlanco() {

    let intIndiceLista = 0;

    for (intIndiceLista = 0; intIndiceLista < this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].length; intIndiceLista++) {
      const item = this.venenguiaRemisionService.detguiaRemisionarray[this.indicador][intIndiceLista];
      if (item.DETGRE_TIPODET !== '*' && item.DETGRE_CODIGO === '') {
        this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].splice(this.venenguiaRemisionService.detguiaRemisionarray[this.indicador].findIndex(
          guia => guia.DETGRE_LINEA === this.detalleGuiaSeleccionado.DETGRE_LINEA && guia.LINEA === this.detalleGuiaSeleccionado.LINEA),
          1);
      }

    }
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }

  async actualizarDatosElectronicos() {

    try {

      const rs = await this.venenguiaRemisionService.actualizarDatosElectronicos(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO);
      if (rs !== null) {
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_AUTORIZACION_FE = rs[0].ENCGRE_AUTORIZACION_FE
          === null ? '' : rs[0].ENCGRE_AUTORIZACION_FE;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE = rs[0].ENCGRE_ESTADO_FE === null ?
          '' : rs[0].ENCGRE_ESTADO_FE;
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_CLAVEACCESO_FE = rs[0].ENCGRE_CLAVEACCESO_FE
          === null ? '' : rs[0].ENCGRE_CLAVEACCESO_FE;
      }
    } catch (err) {
      this.messageService.add({
        key: 'guiaRemision',
        severity: 'error',
        summary: 'Error',
        detail: '"No se puede refrescar la información del documento electrónico'
      });
    }
  }

  async changeTabs() {
    if (this.activeIndex == 0) {
      const bolTipoLlegada = this.venenguiaRemisionService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DIRCLIGR').CFG_VALOR1;
      if (bolTipoLlegada === 1) {
        if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PTOLLEGADA === '')
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_PTOLLEGADA =
            this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_DIRECCIONDSTNTR;
      }
    }

    if (this.activeIndex > 1) {
      this.mayoruno = true;
      this.btnNuevo = true;
      this.btnGuardar = true;
      this.btnBorrar = true;
      this.btnRegresar = true;
      this.botonPrimero = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonUltimo = true;
    } else {
      if (this.mayoruno === true) {
        this.mayoruno = false;
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.btnRegresar = true;
      }
      this.botonPrimero = false;
      this.botonAnterior = false;
      this.botonSiguiente = false;
      this.botonUltimo = false;
    }

    if (this.activeIndex === 3) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.cliService.getInfoEstadoCuenta(
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO,
          'guiaRemision');
      }
    }

    if (this.activeIndex === 4) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.cliService.getInfoSaldos(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO,
          'guiaRemision');
      }
    }

    if (this.activeIndex === 5) {
      this.dataVentasAcum = {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          label: 'Ventas',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ]
      };

      this.totalDocumentos = '';
      this.totalChPosfechadosPorc = '';
      this.totalDocVencidosPorc = '';
      this.totalDocXCobrar = '';
      this.totalDocXCobrarPorc = '';
      this.totalChPosfechados = '';
      this.totalDocVencidos = '';
      this.totalPedPendientes = '';
      this.totalPedSatisfechos = '';
      this.fechaPrimeraFact = '';
      this.detTopProductos = [];
      this.detFacturas = [];
      this.totalChProtestados = '';
    }
  }


  mostrarEstadisticas() {
    this.calcularDocumentos();
    this.ventasAcumuladas();
    this.topProductos();
    this.detalleVentas();
    this.pedidosPendientes();
    this.pedidosOk();
    this.primeraFactura();
    this.chequeProtestado();
  }

  async primeraFactura() {

    try {
      const rs6 = await this.venEstadisticasService.primeraFactura(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO);

      this.fechaPrimeraFact = this.datePipe.transform(rs6[0].ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
    } catch (err) {

    }

  }

  async pedidosPendientes() {

    try {
      const rs4 = await this.venEstadisticasService.cantidadPedidos(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO, 'P');
      this.totalPedPendientes = rs4[0].CANTIDAD;
    } catch (err) {

    }
  }

  async pedidosOk() {

    try {

      const rs5 = await this.venEstadisticasService.cantidadPedidos(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO, 'OK');
      this.totalPedSatisfechos = rs5[0].CANTIDAD;

    } catch (err) {

    }
  }

  async chequeProtestado() {

    try {

      const rs7 = await this.venEstadisticasService.cantidadChProtestados(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO);
      this.totalChProtestados = rs7[0].TOTAL;

    } catch (err) {

    }
  }

  async detalleVentas() {

    const detalle: VenDetFacturas[] = [];

    try {
      const rs8 = await this.venEstadisticasService.detalleVentas(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO, 'fecha');

      this.detFacturas = [];
      if (rs8 !== null) {
        for (const item of rs8) {
          const det: VenDetFacturas = {};
          det.ENCFAC_NUMERO = item.ENCFAC_NUMERO;
          det.ENCFAC_FECHAEMISION = this.datePipe.transform(item.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.DETFAC_CANTIDAD = Number(item.DETFAC_CANTIDAD).toFixed(2);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          det.DETFAC_TOTAL = Number(item.DETFAC_TOTAL).toFixed(2);
          detalle.push(det);
        }

        this.detFacturas = detalle;
      }
    } catch (err) {

    }

  }

  async calcularDocumentos() {

    try {
      const rs1 = await this.venEstadisticasService.encontrarDocumentos('PORCOBRAR',
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO);
      this.totalDocXCobrar = this.init.moneyValidation(Number(rs1[0].SALDO).toFixed(2));
      const rs2 = await this.venEstadisticasService.encontrarDocumentos('VENCIDOS',
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO);
      this.totalDocVencidos = this.init.moneyValidation(Number(rs2[0].SALDO).toFixed(2));
      const rs3 = await this.venEstadisticasService.encontrarDocumentos('POSFECHADOS',
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO);
      this.totalChPosfechados = this.init.moneyValidation(Number(rs3[0].SALDO).toFixed(2));

      this.totalDocumentos = Number(rs1[0].SALDO) + Number(rs2[0].SALDO) + Number(rs3[0].SALDO);
      this.totalDocXCobrarPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs1[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;
      this.totalDocVencidosPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs2[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;
      this.totalChPosfechadosPorc = this.totalDocumentos > 0 ?
        this.init.moneyValidation(Number(rs3[0].SALDO * 100 / this.totalDocumentos).toFixed(2)) : 0;

      this.totalDocumentos = this.init.moneyValidation(Number(this.totalDocumentos).toFixed(2));


    } catch (err) {

    }
  }

  async orderByDetFacturas(tipo) {
    this.detFacturas = [];
    const detalle: VenDetFacturas[] = [];
    let rs8;

    try {

      if (tipo === 'fecha') {
        rs8 = await this.venEstadisticasService.detalleVentas(
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO, 'fecha');
      }

      if (tipo === 'producto') {
        rs8 = await this.venEstadisticasService.detalleVentas(
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO, 'producto');
      }


      if (rs8 !== null) {
        for (const item of rs8) {
          const det: VenDetFacturas = {};
          det.ENCFAC_NUMERO = item.ENCFAC_NUMERO;
          det.ENCFAC_FECHAEMISION = this.datePipe.transform(item.ENCFAC_FECHAEMISION, 'dd/MM/yyyy');
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.DETFAC_CANTIDAD = Number(item.DETFAC_CANTIDAD).toFixed(2);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          det.DETFAC_TOTAL = Number(item.DETFAC_TOTAL).toFixed(2);
          detalle.push(det);
        }

        this.detFacturas = detalle;
      }

    } catch (err) {

    }
  }

  async ventasAcumuladas() {

    const fechaInicial = new Date();
    fechaInicial.setMonth(fechaInicial.getMonth() - 12);
    fechaInicial.setDate(1);

    const fechaAct = new Date();
    const rangoFechas: any[] = [];

    for (let i = 1; fechaInicial <= fechaAct; i++) {
      rangoFechas.push(this.datePipe.transform(fechaInicial, 'dd/MM/yyyy'));
      fechaInicial.setMonth(fechaInicial.getMonth() + 1);
    }


    try {

      const rs = await this.venEstadisticasService.ventasAcumuladas(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO);

      if (rs !== null) {

        const index1 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[0]);
        const valor1 = index1 >= 0 ? rs[index1].VENTA_TOTAL : 0;
        const index2 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[1]);
        const valor2 = index2 >= 0 ? rs[index2].VENTA_TOTAL : 0;
        const index3 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[2]);
        const valor3 = index3 >= 0 ? rs[index3].VENTA_TOTAL : 0;
        const index4 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[3]);
        const valor4 = index4 >= 0 ? rs[index4].VENTA_TOTAL : 0;
        const index5 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[4]);
        const valor5 = index5 >= 0 ? rs[index5].VENTA_TOTAL : 0;
        const index6 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[5]);
        const valor6 = index6 >= 0 ? rs[index6].VENTA_TOTAL : 0;
        const index7 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[6]);
        const valor7 = index7 >= 0 ? rs[index7].VENTA_TOTAL : 0;
        const index8 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[7]);
        const valor8 = index8 >= 0 ? rs[index8].VENTA_TOTAL : 0;
        const index9 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[8]);
        const valor9 = index9 >= 0 ? rs[index9].VENTA_TOTAL : 0;
        const index10 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[9]);
        const valor10 = index10 >= 0 ? rs[index10].VENTA_TOTAL : 0;
        const index11 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[10]);
        const valor11 = index11 >= 0 ? rs[index11].VENTA_TOTAL : 0;
        const index12 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[11]);
        const valor12 = index12 >= 0 ? rs[index12].VENTA_TOTAL : 0;
        const index13 = rs.findIndex(item => this.datePipe.transform(item.FECHA, 'dd/MM/yyyy') === rangoFechas[12]);
        const valor13 = index13 >= 0 ? rs[index13].VENTA_TOTAL : 0;

        this.dataVentasAcum = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [valor1, valor2, valor3, valor4, valor5, valor6, valor7,
              valor8, valor9, valor10, valor11, valor12, valor13]
          }
          ]
        };

      } else {
        this.dataVentasAcum = {
          labels: [
            String(rangoFechas[0]).substring(8, 10) + '/' + String(rangoFechas[0]).substring(3, 5),
            String(rangoFechas[1]).substring(8, 10) + '/' + String(rangoFechas[1]).substring(3, 5),
            String(rangoFechas[2]).substring(8, 10) + '/' + String(rangoFechas[2]).substring(3, 5),
            String(rangoFechas[3]).substring(8, 10) + '/' + String(rangoFechas[3]).substring(3, 5),
            String(rangoFechas[4]).substring(8, 10) + '/' + String(rangoFechas[4]).substring(3, 5),
            String(rangoFechas[5]).substring(8, 10) + '/' + String(rangoFechas[5]).substring(3, 5),
            String(rangoFechas[6]).substring(8, 10) + '/' + String(rangoFechas[6]).substring(3, 5),
            String(rangoFechas[7]).substring(8, 10) + '/' + String(rangoFechas[7]).substring(3, 5),
            String(rangoFechas[8]).substring(8, 10) + '/' + String(rangoFechas[8]).substring(3, 5),
            String(rangoFechas[9]).substring(8, 10) + '/' + String(rangoFechas[9]).substring(3, 5),
            String(rangoFechas[10]).substring(8, 10) + '/' + String(rangoFechas[10]).substring(3, 5),
            String(rangoFechas[11]).substring(8, 10) + '/' + String(rangoFechas[11]).substring(3, 5),
            String(rangoFechas[12]).substring(8, 10) + '/' + String(rangoFechas[12]).substring(3, 5),
          ],
          datasets: [{
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          ]
        };
      }
    } catch (err) {

    }
  }

  async topProductos() {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const detalle: VenTopProductos[] = [];

    try {
      const rs = await this.venEstadisticasService.topProductos(
        this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO, 'cantidad');
      if (rs !== null) {
        for (const item of rs) {
          const det: VenTopProductos = {};
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFAC_PRECIO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: VenTopProductos = {};
        totales.DETFAC_CODIGO = '';
        totales.DETFAC_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFAC_PRECIO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }

  async orderByTopProd(tipo) {
    this.detTopProductos = [];
    let totalCantidad = 0;
    let totalPrecio = 0;
    let total = 0;
    const detalle: VenTopProductos[] = [];
    let rs;

    try {
      if (tipo === 'total') {
        rs = await this.venEstadisticasService.topProductos(
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO, 'total');
      }

      if (tipo === 'cantidad') {
        rs = await this.venEstadisticasService.topProductos(
          this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].CLI_CODIGO, 'cantidad');
      }

      if (rs !== null) {
        for (const item of rs) {
          const det: VenTopProductos = {};
          det.DETFAC_CODIGO = item.DETFAC_CODIGO;
          det.DETFAC_DESCRIPCION = item.DETFAC_DESCRIPCION;
          det.CANTIDAD_TOTAL = Number(item.CANTIDAD_TOTAL).toFixed(2);
          totalCantidad = totalCantidad + Number(item.CANTIDAD_TOTAL);
          det.DETFAC_PRECIO = Number(item.DETFAC_PRECIO).toFixed(2);
          totalPrecio = totalPrecio + Number(item.DETFAC_PRECIO);
          det.TOTAL = Number(item.TOTAL).toFixed(2);
          total = total + Number(item.TOTAL);
          det.STOCK = Number(item.STOCK).toFixed(2);
          detalle.push(det);
        }
        const totales: VenTopProductos = {};
        totales.DETFAC_CODIGO = '';
        totales.DETFAC_DESCRIPCION = 'TOTAL';
        totales.CANTIDAD_TOTAL = Number(totalCantidad).toFixed(2);
        totales.DETFAC_PRECIO = Number(totalPrecio).toFixed(2);
        totales.TOTAL = Number(total).toFixed(2);
        totales.STOCK = '';
        detalle.push(totales);

        this.detTopProductos = detalle;
      }

    } catch (err) {

    }
  }


  salirExistenciasBod() {
    this.displayDialogExistBod = false;
  }

  busquedanumeracion() {
    this.opcion = 'numeracion';
    this.types = [
      { label: 'Descripción', value: 'NUM_CLASE' },
      { label: 'Nemónico', value: 'NUM_NEMONICO' },
      { label: 'Serie', value: 'NUM_SERIE' },
      { label: 'Actual', value: 'NUM_SECACTUAL' }
    ];

    this.busqService.busquedaSaciMaeNumeracion5().subscribe((datos: any[]) => {
      this.arregloBus = datos;
      this.displayDialogBus = true;
    });
  }

  async generarNumeracion() {
    let size = 0;
    let intNumActual = 0;
    let intNumFinal = 0;
    let strNumActual;
    if (this.tipoNumeracion === 'G') {
      const data: any[] = await this.crearNumeracionGeneral(this.tipoDoc);
      size = data[0];
      intNumActual = data[1];
      intNumFinal = data[2];
    } else if (this.tipoNumeracion === 'C') {
      if (String(this.strClase).trim() === '') {
        this.busquedanumeracion();

      } else {
        const data: any[] = await this.obtenerNumeraciones(this.strClase);
        strNumActual = data[0];
        this.strSerie = data[1];
        const strNumFinal = data[2];
        if (String(strNumFinal).length > 0)
          intNumFinal = parseInt(strNumFinal);
        if (String(strNumActual).length > 0) {
          size = 1;
        }
        //CDPJ
        if(this.CNAFEPV === 1){
          data[16]=data[16] === null || data[16] === undefined?0:data[16]
          console.log(data[16])
              if(data[16] === 1){
                this.botonXML = false;
              }else{
                this.botonXML = true;
              }
        }
        //CDPJ

      }

      this.tamNumPed = String(strNumActual).length; // tamaño del número

      this.strNumDoc = this.strNemonico + this.strSerie + strNumActual;

    }

    console.log(this.strNumDoc);

    this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO = this.strNumDoc;
    this.numeroActual = Number(intNumActual);

		/*txtNumero.setText(strNumDoc);
		if (size == 0) {
			CompositeUtilities.openInformation(getShell(), "INFORMACI" + Unicodes.O_UPPER + "N",
					"No existe una numeraci" + Unicodes.O_LOWER
							+ "n para el Pedido. Consulte con el Administrador.");
			return false;
		} else if (intNumActual >= intNumFinal) {
			CompositeUtilities.openInformation(getShell(), "INFORMACI" + Unicodes.O_UPPER + "N",
					"Se termin" + Unicodes.O_LOWER + " el secuencial de los Pedidos.");
			return false;
		} else {
			txtPartida.setFocus();
		} */
    return true;
  }

  async crearNumeracionGeneral(tipoDoc) {
    tipoDoc = tipoDoc === null ? 'GRE' : String(tipoDoc).trim() === '' ? 'GRE' : tipoDoc;
    const listaNumG: SaciMaenumeracionG[] = await this.obtenerNumeracionG(tipoDoc);
    const size = listaNumG.length;
    let act = 0;
    let fin = 0;
    if (size > 0) {
      act = parseInt(String(listaNumG[0].NUM_SECACTUAL).trim());
      fin = parseInt(String(listaNumG[0].NUM_SECFINAL).trim());
      this.tamNumPed = String(listaNumG[0].NUM_SECACTUAL).trim().length;
      this.strNumDoc = String(listaNumG[0].NUM_SERIE).trim()
        + String(listaNumG[0].NUM_SECACTUAL).trim();
    }
    return [size, act, fin];
  }

  async obtenerNumeracionG(strTipoDoc) {
    const listaNumG: SaciMaenumeracionG[] = [];
    try {
      const res = await this.venenguiaRemisionService.obtenerNumeracionG(strTipoDoc);
      console.log(res);

      for (const rs of res) {
        const item: SaciMaenumeracionG = {};

        item.COM_CODIGO = rs.COM_CODIGO; // compañia
        item.NUM_DOCREF = rs.NUM_DOCREF // tipo documento
        item.NUM_SERIE = rs.NUM_SERIE; // serie
        item.NUM_SECINICIAL = rs.NUM_SECINICIAL; // num inicial
        item.NUM_SECFINAL = rs.NUM_SECFINAL; // num final
        item.NUM_SECACTUAL = rs.NUM_SECACTUAL; // num actual
        item.NUM_AUTORIZACION = rs.NUM_AUTORIZACION; // autorización
        item.NUM_FECHACAD = rs.NUM_FECHACAD;


        listaNumG.push(item);
      }

    } catch (err) {
      this.mensajeAlerta('error', 'Error', 'No se puede consultar la numeración general ' + err.error);
    }

    return listaNumG;
  }

  async obtenerNumeraciones(clase) {
    const data: any[] = [];
    try {

      const rs = await this.venenguiaRemisionService.obtenerNumeracion(clase);
      console.log(rs);

      for (const result of rs) {
        data[0] = result.NUM_SECACTUAL;
        data[1] = result.NUM_SERIE;
        data[2] = result.NUM_SECFINAL;
        data[3] = result.NUM_DOCREF;
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', 'No se puede obtener la numeración ' + err.error);
    }

    return data;
  }

  async actualizarNumeracion() {
    if (this.tipoDoc === null) {
      this.tipoDoc = 'GRE';
    }
    await this.actualizarNumeracion2(this.strClase, this.tipoDoc, this.strNemonico, this.strSerie);
  }

  async actualizarNumeracion2(strClase, strTipoDoc, strNemonico, strSerie) {

    let bolActualizar = false;

    const strParam: any[] = [];

    strParam[0] = strClase;
    strParam[1] = '01';
    strParam[2] = strTipoDoc;
    strParam[3] = strNemonico;
    strParam[4] = strSerie;

    try {
      const rs = this.venenguiaRemisionService.actualizarNumeracion(strParam);
      console.log(rs);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', 'No se puede actualizar la numeración ' + err.error);
    }

    return bolActualizar;

  }

  mensajeAlerta(tipo, titulo, msg) {
    this.messageService.add({
      key: 'guiaRemision',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  async continuarFE() {
    this.closeSidebar();
    const msgclave = 'guiaRemision';
    const strNumDoc = this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO;

    if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'No ha seleccionado un documento'
      });
      return;
    }

    if (this.datosfe.COM_FEACTIVADA === '0') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Facturación electrónica no está activada.'
      });
      return;
    }

    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE === ''
      || this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE === undefined
      || this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE === null) {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento no ha iniciado el proceso de firma electrónica.'
      });
      return;
    }

    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE === 'VA') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra en su estado inicial, reverse el proceso de firma electrónica.'
      });
      return;
    }

    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE === 'NT') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra notificado.'
      });
      return;
    }


    if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_ESTADO_FE === 'NA') {
      this.messageService.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Visualize el log. Corrija el documento y reverse el proceso de firma electrónica.'
      });
      return;
    }

    this.spin = true;
    const data = await this.utilitariosService.continuarFirmaElectronica(strNumDoc).catch(e=>{
      this.spin = false;
    });
    if (data !== 'NOAUTORIZADO') {
      this.messageService.add({
        key: msgclave,
        severity: 'success',
        summary: 'Información',
        detail: data
      });
      this.spin = false;
      this.cancelarEdicionGuia();
    }
    this.cancelarEdicionGuia();
  }

  async abrirLotes(params) {
    const detalle = params;
    console.log(detalle);
    if ((this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO.length > 0
      && detalle.ART_CADUCA === 'S') === true) {
      if (detalle.ART_UNIDADVENTA !== detalle.DETGRE_UNIDAD) {
        const data: any = await this.utilitariosService.getFactorcantidad(detalle.ART_UNIDADVENTA, detalle.DETGRE_UNIDAD);
        let factor = '';
        let cant = '';
        for (const rs of data) {
          factor = rs.EQU_FACTOR;
          cant = rs.EQU_CANTIDAD;
        }
        if (factor === '*') {
          detalle.DETGRE_CANTIDADB = Number(detalle.DETGRE_CANTIDAD) * Number(cant);
        } else {
          detalle.DETGRE_CANTIDADB = Number(detalle.DETGRE_CANTIDAD) / Number(cant);
        }
      }
      this.detalleGuiaSeleccionado = detalle;
      this.auxiliarlot++;
      this.displayDialogLotes = true;
    }
  }

  cerrarLotes() {
    this.displayDialogLotes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }
  //////////////////////////vehiculo
  async verimagen(parametro) {
    this.opcion = 'verimagen';
    if (this.detalleGuiaSeleccionado.DETGRE_CODIGO !== '') {
      const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
      if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
        this.displayDialogDocumentosim = true;
      } else {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'info',
          summary: 'Información',
          detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
        });
      }

    }
  }
    // CDPJ
    async verbasesiva(){
      this.closeSidebar()
      this.basesiva=[]
      const data = await this.venenguiaRemisionService.obtenerbasesiva(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO);
      if(data !== null && data !== undefined){
       this.basesiva=data
       for (const data2 of  this.basesiva){
         data2.TARIIVA_PORCENTAJE=isNaN(Number(data2.TARIIVA_PORCENTAJE))?Number(0).toFixed(2):Number(data2.TARIIVA_PORCENTAJE).toFixed(2)
         data2.IVA_BASE=isNaN(Number(data2.IVA_BASE))?Number(0).toFixed(2):Number(data2.IVA_BASE).toFixed(2)
         data2.IVA_VALOR=isNaN(Number(data2.IVA_VALOR))?Number(0).toFixed(2):Number(data2.IVA_VALOR).toFixed(2)
       }
      }
      this.displaybasesiva=true;
  
    }
    async actbasesiva(){
      this.spin=true;
      this.closeSidebar();
      try {
        await this.venenguiaRemisionService.actualizabasesiva(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO);
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'success',
          summary: 'Información',
          detail: 'Bases IVA actualizadas correctamente'
        });
        this.auditoriagral.registrarAuditoria('VEN_ENCGRE',  this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.indicador].ENCGRE_NUMERO+'/'+'ACCIONES_ACT_VALORES_BASES_IVA', 'A', 
        '', '01', '', '', '', '').subscribe(() => {
        });
        this.spin=false;
      } catch (error) {
        this.messageService.add({
          key: 'guiaRemision',
          severity: 'error',
          summary: 'Información',
          detail: 'Error al actualiza las bases IVA'+ error
        });
        this.spin=false;
      }
      
    }
    //cdpj
}
