import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { MessageService } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { VenMaeCliente } from '../veninterfaces/venmaecliente';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { VenTrnsertran } from '../veninterfaces/ventrnsertran';

@Injectable({
  providedIn: 'root'
})

export class VenmaeclienteService {

  public way: string;
  public decimalesPrecio: number;
  public venservicioped: VenTrnsertran[][] = [];
  public hmpConfiguracion: any[];//CDPJ
  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private datePipe: DatePipe,
    private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }

  seleccionarRegistro(tipo, cliente: VenMaeCliente): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/71d573192c5d5c0000000000', {
      elementos: {
        p_tipo: tipo,
        p_CLI_codigo: cliente.CLI_CODIGO,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions());
  }

  seleccionarRegistroProm(tipo, cliente: VenMaeCliente) {
    return this.http.post<any>(this.way + '/ventas/cliente/71d573192c5d5c0000000000', {
      elementos: {
        p_tipo: tipo,
        p_CLI_codigo: cliente.CLI_CODIGO,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getCliente(clicodigo): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/cliente/20eeb2b275fae', {
      elementos: {
        CLI_CODIGO: clicodigo
      }
    }, this.confIniciales.httpOptions());
  }

  getClienteProm(clicodigo) {
    return this.http.post<any[]>(this.way + '/ventas/cliente/20eeb2b275fae', {
      elementos: {
        CLI_CODIGO: clicodigo
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  erNombreGrupo(GRU_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edd0df800', {
      elementos: {
        GRU_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreEstadoCivil(CLI_ESTCIV) {
    return this.http.post(this.way + '/ventas/cliente/3b77c597b73b9e0000000000', {
      elementos: {
        CLI_ESTCIV
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreOrigen(getNombreOrigen) {
    return this.http.post(this.way + '/ventas/cliente/1dbbe2cbdbb1b90000', {
      elementos: {
        getNombreOrigen
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreSexo(CLI_SEXO) {
    return this.http.post(this.way + '/ventas/cliente/1dbbe2cbdbb8e', {
      elementos: {
        CLI_SEXO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreVendedor(VEN_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/76ef8b2f6efbb0000000', {
      elementos: {
        VEN_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreCargo(CLI_CARGO) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edcc57000', {
      elementos: {
        CLI_CARGO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreProvinciaref(CLI_PROVINCIA) {
    return this.http.post(this.way + '/ventas/cliente/76ef8b2f6ecef000000000000', {
      elementos: {
        CLI_PROVINCIA
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreProvincia(CLI_PROVINCIA) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edd9de00000000', {
      elementos: {
        CLI_PROVINCIA
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreCiudad(CIU_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/1dbbe2cbdb992f0000', {
      elementos: {
        CIU_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreCanton(CAN_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/1dbbe2cbdb98ac0000', {
      elementos: {
        CAN_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreParroquia(PARR_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edd95700000000', {
      elementos: {
        PARR_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erCodProvincia(CLI_PROVINCIANOMBRE) {
    return this.http.post(this.way + '/ventas/cliente/1db661b9de3f2c0000', {
      elementos: {
        CLI_PROVINCIANOMBRE
      },
    }, this.confIniciales.httpOptions());
  }

  erCodCanton(CAN_NOMBRE) {
    return this.http.post(this.way + '/ventas/cliente/3b6cc358abf718', {
      elementos: {
        CAN_NOMBRE
      },
    }, this.confIniciales.httpOptions());
  }

  erCodParroquia(PARR_NOMBRE) {
    return this.http.post(this.way + '/ventas/cliente/1db661b956f78d0000', {
      elementos: {
        PARR_NOMBRE
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreZona(CLI_ZONA) {
    return this.http.post(this.way + '/ventas/cliente/76ef8b2f6e', {
      elementos: {
        CLI_ZONA
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreSujeto(CLI_SUJETO) {
    return this.http.post(this.way + '/ventas/cliente/1dbbe2cbdbb9ea0000', {
      elementos: {
        CLI_SUJETO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreBanco(BANCLI_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edcb56000', {
      elementos: {
        BANCLI_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreFormaPago(CLI_FORMAPAGO_CODSRI) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edcfc700000000', {
      elementos: {
        CLI_FORMAPAGO_CODSRI
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreEstado(CLI_ESTADO) {
    return this.http.post(this.way + '/ventas/cliente/1dbbe2cbdb9dcf0000', {
      elementos: {
        CLI_ESTADO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreConcodigo(CON_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/eddf165edccc600000000', {
      elementos: {
        CON_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erNombreCencos(CEN_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/1dbbe2cbdb98ec0000', {
      elementos: {
        CEN_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erTipoIdentifi(CLI_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/1dbecb38935d7f0000', {
      elementos: {
        CLI_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  erExisteCliente(rucide, codcliente) {
    return this.http.post(this.way + '/ventas/cliente/3b6e64b9d732b200000', {
      elemento: {
        rucide,
        codcliente
      },
    }, this.confIniciales.httpOptions());
  }

  erExisteClienteCod(CLI_CODIGO) {
    return this.http.post(this.way + '/ventas/cliente/1db7325ceb9959000000000', {
      elementos: {
        CLI_CODIGO
      },
    }, this.confIniciales.httpOptions());
  }

  actualizarDatosAdicionales(cliente: VenMaeCliente): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/533ad5771c53640', {
      elementos: {
        p_CLI_codigo: cliente.CLI_CODIGO,
        p_COM_codigo: '01',
        p_precioAc20: Number(cliente.CLI_PRECIOAC20),
        p_precioRC250: Number(cliente.CLI_PRECIORC250),
        p_usu_identificacion: cliente.USUARIO,
        p_numcuotas: cliente.CLI_NUMEROCUOTAS,
        p_cli_incremento: cliente.CLI_INCREMENTO,
        p_cli_facturaelect: cliente.CLI_FACTURAELECT,
        p_cli_correo2: cliente.CLI_CORREO2,
        p_cli_parterel: cliente.CLI_PARTEREL,
        p_cli_formapago_codsri: cliente.CLI_FORMAPAGO_CODSRI,
        p_cli_tipoden: cliente.CLI_TIPODEN,
        p_cli_placa: cliente.CLI_PLACA,
        p_cli_comentarios: cliente.CLI_COMENTARIOS,
        p_cli_conregmic: cliente.CLI_CONREGMIC,
        p_cli_agenteret: cliente.CLI_AGENTERET,
        p_cli_conregrimpe: cliente.CLI_CONREGRIMPE
      }
    }, this.confIniciales.httpOptions());
  }

  guardarCliente(cliente: VenMaeCliente): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/21e56daadb2b280000', {
      elementos: {
        p_CLI_codigo_1: cliente.CLI_CODIGO,
        p_GRU_codigo_2: cliente.GRU_CODIGO,
        p_VEN_codigo_3: cliente.VEN_CODIGO,
        p_CLI_nombrec_4: cliente.CLI_NOMBREC,
        p_CLI_nombre_5: cliente.CLI_NOMBRE,
        p_CLI_tipoide: cliente.CLI_TIPOIDE,
        p_CLI_rucide_6: cliente.CLI_RUCIDE,
        p_CLI_direccion1_7: cliente.CLI_DIRECCION1,
        p_CLI_direccion2_8: cliente.CLI_DIRECCION2,
        p_CLI_codpostal_9: cliente.CLI_CODPOSTAL,
        p_CLI_telefono1_10: cliente.CLI_TELEFONO1,
        p_CLI_telefono2_11: cliente.CLI_TELEFONO2,
        p_CLI_fax_12: cliente.CLI_FAX,
        p_CLI_correo_13: cliente.CLI_CORREO,
        p_CLI_contacto_14: cliente.CLI_CONTACTO,
        p_CLI_fecing_16: this.datePipe.transform(cliente.CLI_FECING, 'dd/MM/yyyy'),
        p_CLI_limcredit_16: cliente.CLI_LIMCREDIT,
        p_CLI_diacredit_17: cliente.CLI_DIACREDIT,
        p_CLI_descuento_18: cliente.CLI_DESCUENTO,
        p_CLI_iva_19: cliente.CLI_IVA,
        p_CLI_contribuyente_20: cliente.CLI_CONTRIBUYENTE,
        p_CLI_lista_21: cliente.CLI_LISTA,
        p_CON_codigo1_22: cliente.CON_CODIGO1,
        p_CON_codigo2_23: cliente.CON_CODIGO2,
        p_CLI_zona_24: cliente.CLI_ZONA,
        p_CLI_observacion_25: cliente.CLI_OBSERVACION,
        p_NOM_codigo_27: cliente.NOM_CODIGO,
        p_DEP_codigo_28: cliente.DEP_CODIGO,
        p_CLI_tipo_29: cliente.CLI_TIPO,
        p_CLI_provincia_30: cliente.CLI_PROVINCIA,
        p_CIU_codigo_31: cliente.CIU_CODIGO,
        p_TCR_codigo_32: cliente.TCR_CODIGO,
        p_CLI_trfiva_33: cliente.CLI_TRFIVA,
        p_CLI_trfretencion_34: cliente.CLI_TRFRETENCION,
        p_CBR_codigo_35: cliente.CBR_CODIGO,
        p_CLI_estado_36: cliente.CLI_ESTADO,
        p_ENCFFA_codigo_37: cliente.ENCFFA_CODIGO,
        p_CLI_linneg_38: cliente.CLI_LINNEG,
        p_CLI_porcedescuento_39: cliente.CLI_PORCEDESCUENTO,
        p_CLI_valorrecargo_40: cliente.CLI_VALORRECARGO,
        p_CLI_porcerecargo_41: cliente.CLI_PORCERECARGO,
        p_CEN_codigo_42: cliente.CEN_CODIGO,
        p_CLI_LOCAL: cliente.CLI_LOCAL,
        p_CLI_FECHANACIMIENTO: this.datePipe.transform(cliente.CLI_FECHANACIMIENTO, 'dd/MM/yyyy'),
        p_CLI_SEXO: cliente.CLI_SEXO,
        p_CLI_CARGO: cliente.CLI_CARGO,
        p_CLI_DIACHPOS: cliente.CLI_DIACHPOS,
        p_CLI_unifica: cliente.CLI_UNIFICA,
        p_CLI_excluye: cliente.CLI_EXCLUYE,
        p_CLI_descuentolim: cliente.CLI_DESCUENTOLIM,
        p_CLI_codigo3: cliente.CON_CODIGO3,
        p_com_codigo: cliente.COM_CODIGO,
        // campos aumentados
        p_BANCLI_CODIGO: cliente.BANCLI_CODIGO,
        p_CLI_NROCUENTA: cliente.CLI_NROCUENTA,
        p_CLI_LIDER: cliente.CLI_LIDER,
        p_CLI_JEFE: cliente.CLI_JEFE,
        p_CLI_TARDSC: cliente.CLI_TARDSC,
        p_cLI_SUJETO: cliente.CLI_SUJETO,
        p_can_codigo: cliente.CAN_CODIGO,
        p_parr_codigo: cliente.PARR_CODIGO,
        p_cli_estciv: cliente.CLI_ESTCIV,
        P_CLI_ORGING: cliente.CLI_ORGING,
        P_CLI_GMAPS: cliente.CLI_GMAPS
      }
    }, this.confIniciales.httpOptions());
  }

  /////// actualizar
  actualizarCliente(cliente: VenMaeCliente): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/533be532b275fc0', {
      elementos: {
        p_CLI_codigo_1: cliente.CLI_CODIGO,
        p_CLI_codigo_2: cliente.CLI_CODIGO,
        p_GRU_codigo_3: cliente.GRU_CODIGO,
        p_VEN_codigo_4: cliente.VEN_CODIGO,
        p_CLI_nombrec_5: cliente.CLI_NOMBREC,
        p_CLI_nombre_6: cliente.CLI_NOMBRE,
        p_CLI_tipoide: cliente.CLI_TIPOIDE,
        p_CLI_rucide_7: cliente.CLI_RUCIDE,
        p_CLI_direccion1_8: cliente.CLI_DIRECCION1,
        p_CLI_direccion2_9: cliente.CLI_DIRECCION2,
        p_CLI_codpostal_10: cliente.CLI_CODPOSTAL,
        p_CLI_telefono1_11: cliente.CLI_TELEFONO1,
        p_CLI_telefono2_12: cliente.CLI_TELEFONO2,
        p_CLI_fax_13: cliente.CLI_FAX,
        p_CLI_correo_14: cliente.CLI_CORREO,
        p_CLI_contacto_15: cliente.CLI_CONTACTO,
        p_CLI_fecing_16: this.datePipe.transform(cliente.CLI_FECING, 'dd/MM/yyyy'),
        p_CLI_limcredit_17: cliente.CLI_LIMCREDIT,
        p_CLI_diacredit_18: cliente.CLI_DIACREDIT,
        p_CLI_descuento_19: Number(cliente.CLI_DESCUENTO),
        p_CLI_iva_20: cliente.CLI_IVA,
        p_CLI_contribuyente_21: cliente.CLI_CONTRIBUYENTE,
        p_CLI_lista_22: cliente.CLI_LISTA,
        p_CON_codigo1_23: cliente.CON_CODIGO1,
        p_CON_codigo2_24: cliente.CON_CODIGO2,
        p_CLI_zona_25: cliente.CLI_ZONA,
        p_CLI_observacion_26: cliente.CLI_OBSERVACION,
        p_NOM_codigo_28: cliente.NOM_CODIGO,
        p_DEP_codigo_29: cliente.DEP_CODIGO,
        p_CLI_tipo_30: cliente.CLI_TIPO,
        p_CLI_provincia_31: cliente.CLI_PROVINCIA,
        p_CIU_codigo_32: cliente.CIU_CODIGO,
        p_TCR_codigo_33: cliente.TCR_CODIGO,
        p_CLI_trfiva_34: cliente.CLI_TRFIVA,
        p_CLI_trfretencion_35: cliente.CLI_TRFRETENCION,
        p_CBR_codigo_36: cliente.CBR_CODIGO,
        p_CLI_estado_37: cliente.CLI_ESTADO,
        p_ENCFFA_codigo_38: cliente.ENCFFA_CODIGO,
        p_CLI_linneg_39: cliente.CLI_LINNEG,
        p_CLI_porcedescuento_40: cliente.CLI_PORCEDESCUENTO,
        p_CLI_valorrecargo_41: cliente.CLI_VALORRECARGO,
        p_CLI_porcerecargo_42: cliente.CLI_PORCERECARGO,
        p_CEN_codigo_43: cliente.CEN_CODIGO,
        p_CLI_LOCAL: cliente.CLI_LOCAL,
        p_CLI_FECHANACIMIENTO: this.datePipe.transform(cliente.CLI_FECHANACIMIENTO, 'dd/MM/yyyy'),
        p_CLI_SEXO: cliente.CLI_SEXO,
        p_CLI_CARGO: cliente.CLI_CARGO,
        p_CLI_DIACHPOS: cliente.CLI_DIACHPOS,
        p_CLI_unifica: cliente.CLI_UNIFICA,
        p_CLI_excluye: cliente.CLI_EXCLUYE,
        p_CLI_descuentolim: cliente.CLI_DESCUENTOLIM,
        p_CON_codigo3: cliente.CON_CODIGO3,
        p_com_codigo: cliente.COM_CODIGO,
        // campos aumentados
        p_BANCLI_CODIGO: cliente.BANCLI_CODIGO,
        p_CLI_NROCUENTA: cliente.CLI_NROCUENTA,
        p_CLI_LIDER: cliente.CLI_LIDER,
        p_CLI_JEFE: cliente.CLI_JEFE,
        p_CLI_TARDSC: cliente.CLI_TARDSC,
        p_cLI_SUJETO: cliente.CLI_SUJETO,
        p_can_codigo: cliente.CAN_CODIGO,
        p_parr_codigo: cliente.PARR_CODIGO,
        p_cli_estciv: cliente.CLI_ESTCIV,
        P_CLI_ORGING: cliente.CLI_ORGING,
        P_CLI_GMAPS: cliente.CLI_GMAPS
      }
    }, this.confIniciales.httpOptions());
  }

  eliminarCliente(cliente: VenMaeCliente): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/75656932b275fc0', {
      elementos: {
        p_CLI_codigo_1: cliente.CLI_CODIGO,
        p_com_codigo: cliente.COM_CODIGO,
      }
    }, this.confIniciales.httpOptions());
  }

  getCuentas(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/cliente/20eeb3cebf55c', {
      elementos: {},
    }, this.confIniciales.httpOptions());
  }

  getCuentasProm() {
    return this.http.post<any[]>(this.way + '/ventas/cliente/20eeb3cebf55c', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }

  EstadoCuentaP(clicodigo): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/ee754dc33cebf800', {
      elementos: {
        p_FECHAEMISION_inicial: '',
        p_FECHAEMISION_final: '',
        p_CLI_codigo: clicodigo,
        p_COM_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }


  getEstadoCuentaP(): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/cliente/83baee754dc340000000', {
      elementos: {},
    }, this.confIniciales.httpOptions()).toPromise();
  }


  consultarListaPrecios(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/cliente/41ddacb9d5677000000', {
      elementos: {}
    }, this.confIniciales.httpOptions());
  }

  getSaldosP(clicodigo): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/20eef1556e399', {
      elementos: {
        p_opcion: 'SALDO',
        p_filtro: '',
        p_condicion: 'AND COM_CODIGO = \'01\' AND CLI_CODIGO = \'' + clicodigo + '\''
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  saldoFacturaPromise(strNumFactura): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/cliente/71556e1ea677dc0000000000', {
      elementos: {
        COMCODIGO: '01',
        NUMFACTURA: strNumFactura,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }

  async getInfoEstadoCuenta(clicodigo, clave) {
    let pausacxctmptrnresumen = [];
    try {
      await this.EstadoCuentaP(clicodigo);
      const estcta = await this.getEstadoCuentaP();
      pausacxctmptrnresumen = estcta;

      let dblTotalDebe = 0;
      let dblTotalHaber = 0;
      let saldo = 0;
      for (const cxctrnres of pausacxctmptrnresumen) {
        cxctrnres.TRNCOBRO_FECHATRN = this.datePipe.transform(cxctrnres.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        if (cxctrnres.TRNCOBRO_IMPORTE > 0) {
          cxctrnres.DEBE = cxctrnres.TRNCOBRO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
          cxctrnres.HABER = 0;
        } else {
          cxctrnres.DEBE = 0;
          cxctrnres.HABER = cxctrnres.TRNCOBRO_IMPORTE * (-1);
          cxctrnres.HABER = cxctrnres.HABER.toFixed(this.confIniciales.getNumDecSist());
        }
        saldo = Number(saldo) + Number(cxctrnres.DEBE) - Number(cxctrnres.HABER);
        cxctrnres.SALDO = saldo.toFixed(this.confIniciales.getNumDecSist());
        dblTotalDebe = dblTotalDebe + Number(cxctrnres.DEBE);
        dblTotalHaber = dblTotalHaber + Number(cxctrnres.HABER);
      }

      const totales = {
        DEBE: dblTotalDebe.toFixed(this.confIniciales.getNumDecSist()),
        HABER: dblTotalHaber.toFixed(this.confIniciales.getNumDecSist()),
        SALDO: saldo.toFixed(this.confIniciales.getNumDecSist()),
        TRNCOBRO_CONCEPTO: 'Totales',
        TRNCOBRO_FECHATRN: ''
      };
      if (pausacxctmptrnresumen !== null) {
        pausacxctmptrnresumen.push(totales);
      }

    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: clave,
        severity: 'error',
        summary: 'Estado de Cuenta',
        detail: mensaje
      });
    }

    return pausacxctmptrnresumen;
  }

  async getInfoSaldos(clicodigo, clave) {
    const pausacxctrnresumen = [];
    try {
      const saldos = await this.getSaldosP(clicodigo);

      let totalimporte = 0;
      let totalpagado = 0;
      let totalsaldo = 0;
      if (saldos !== null) {
        for (const cxctrnres of saldos) {
          cxctrnres.TRNCOBRO_FECHATRN = this.datePipe.transform(cxctrnres.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          if (cxctrnres.TRNCOBRO_FECHAVENCE !== null) {
            cxctrnres.TRNCOBRO_FECHAVENCE = this.datePipe.transform(cxctrnres.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
          }
          if (cxctrnres.SALDO !== undefined) {
            if (cxctrnres.SALDO !== 0) {
              totalsaldo = totalsaldo + Number(cxctrnres.SALDO);
              cxctrnres.PAGADO = cxctrnres.TRNCOBRO_IMPORTE - cxctrnres.SALDO;
              totalpagado = totalpagado + Number(cxctrnres.PAGADO);
              totalimporte = totalimporte + Number(cxctrnres.TRNCOBRO_IMPORTE);

              cxctrnres.TRNCOBRO_IMPORTE = cxctrnres.TRNCOBRO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
              cxctrnres.PAGADO = cxctrnres.PAGADO.toFixed(this.confIniciales.getNumDecSist());
              cxctrnres.SALDO = cxctrnres.SALDO.toFixed(this.confIniciales.getNumDecSist());
              pausacxctrnresumen.push(cxctrnres);
            }
          }
          //CDPJ
          cxctrnres.COMENTARIO=cxctrnres.COMENTARIO === null ||cxctrnres.COMENTARIO === undefined?'':cxctrnres.COMENTARIO
          //CDPJ
        }
      }

      const totales = {
        PAGADO: totalpagado.toFixed(this.confIniciales.getNumDecSist()),
        SALDO: totalsaldo.toFixed(this.confIniciales.getNumDecSist()),
        TRNCOBRO_CONCEPTO: 'Totales',
        TRNCOBRO_IMPORTE: totalimporte.toFixed(this.confIniciales.getNumDecSist()),
        TRNCOBRO_FECHATRN: ''
      };
      if (pausacxctrnresumen !== null) {
        pausacxctrnresumen.push(totales);
      }


    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: clave,
        severity: 'error',
        summary: 'Saldo',
        detail: mensaje
      });
    }

    return pausacxctrnresumen;
  }
  //CDPJ
  async getInfoResumen( clave,usuario) {
    const pausacxctrnresumen = [];
    try {
      const saldos = await this.getResumenCob(usuario);

      let totalimporte = 0;
      let totalpagado = 0;
      let totalsaldo = 0;
      if (saldos !== null) {
        for (const cxctrnres of saldos) {
          // cxctrnres.TRNCOBRO_FECHATRN = this.datePipe.transform(cxctrnres.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          // if (cxctrnres.TRNCOBRO_FECHAVENCE !== null) {
          //   cxctrnres.TRNCOBRO_FECHAVENCE = this.datePipe.transform(cxctrnres.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
          // }
          // if (cxctrnres.SALDO !== undefined) {
          //   // if (cxctrnres.SALDO !== 0) {
          //   //   totalsaldo = totalsaldo + Number(cxctrnres.SALDO);
          //   //   cxctrnres.PAGADO = cxctrnres.TRNCOBRO_IMPORTE - cxctrnres.SALDO;
          //   //   totalpagado = totalpagado + Number(cxctrnres.PAGADO);
          //   //   totalimporte = totalimporte + Number(cxctrnres.TRNCOBRO_IMPORTE);

          //   //   cxctrnres.TRNCOBRO_IMPORTE = cxctrnres.TRNCOBRO_IMPORTE.toFixed(this.confIniciales.getNumDecSist());
          //   //   cxctrnres.PAGADO = cxctrnres.PAGADO.toFixed(this.confIniciales.getNumDecSist());
          //   //   cxctrnres.SALDO = cxctrnres.SALDO.toFixed(this.confIniciales.getNumDecSist());
          //   //   pausacxctrnresumen.push(cxctrnres);
          //   // }
          // }
          // //CDPJ
          // cxctrnres.COMENTARIO=cxctrnres.COMENTARIO === null ||cxctrnres.COMENTARIO === undefined?'':cxctrnres.COMENTARIO
          // //CDPJ
          cxctrnres.DEUDATOTAL=cxctrnres.DEUDATOTAL === null || cxctrnres.DEUDATOTAL === undefined?Number(0).toFixed(this.confIniciales.getNumDecSist()):Number(cxctrnres.DEUDATOTAL).toFixed(this.confIniciales.getNumDecSist())
          pausacxctrnresumen.push(cxctrnres);
        }
      }

      // const totales = {
      //   PAGADO: totalpagado.toFixed(this.confIniciales.getNumDecSist()),
      //   SALDO: totalsaldo.toFixed(this.confIniciales.getNumDecSist()),
      //   TRNCOBRO_CONCEPTO: 'Totales',
      //   TRNCOBRO_IMPORTE: totalimporte.toFixed(this.confIniciales.getNumDecSist()),
      //   TRNCOBRO_FECHATRN: ''
      // };
      // if (pausacxctrnresumen !== null) {
      //   pausacxctrnresumen.push(totales);
      // }


    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: clave,
        severity: 'error',
        summary: 'Resumen',
        detail: mensaje
      });
    }

    return pausacxctrnresumen;
  }
  getResumenCob(usuario): Promise<any> {
    return this.http.post<any>(this.way + '/cxc/cxctrncobranzas/83bbdddd985ee0000000', {
      elementos: {
        usuidentificacion:usuario
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  async getInfoResumenTickets( clave,usuario) {
    const pausacxctrnresumen = [];
    try {
      const saldos = await this.getResumenTickets(usuario);

      let totalimporte = 0;
      let totalpagado = 0;
      let totalsaldo = 0;
      if (saldos !== null) {
        for (const cxctrnres of saldos) {
          cxctrnres.TIEMPOACUM=cxctrnres.TIEMPOACUM === null || cxctrnres.TIEMPOACUM === undefined?Number(0):Number(cxctrnres.TIEMPOACUM)
          pausacxctrnresumen.push(cxctrnres);
        }
      }
    } catch (error1) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeError(error1.error.substr(0, 9));
      this.messageService.add({
        key: clave,
        severity: 'error',
        summary: 'Resumen',
        detail: mensaje
      });
    }

    return pausacxctrnresumen;
  }
  getResumenTickets(usuario): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/controltickets/107776ee7acebb0000000000000', {
      elementos: {
        usuidentificacion:usuario
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
  getreferenciatisecl(): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/ventas/cliente/41dddb9eedbaec0000000000', {
      elementos: {}
    }, this.confIniciales.httpOptions()).toPromise();
  }

  guardarventrnsertran(ventrnsertran): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/10f2b6d56feebf00000000', {
      elementos: {
        p_cli_codigo: ventrnsertran.CLI_CODIGO,
        p_ser_codigo: ventrnsertran.SER_CODIGO,
        p_ser_descripcion: ventrnsertran.SER_DESCRIPCION,
        p_com_codigo: ventrnsertran.COM_CODIGO,
        p_trnsertran_precio: Number(ventrnsertran.TRNSERTRAN_PRECIO),
        p_trnsertran_descuento: Number(ventrnsertran.TRNSERTRAN_DESCUENTO),
        p_trnsertran_tipo: ventrnsertran.TRNSERTRAN_TIPO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actualizarventrnsertran(ventrnsertran): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/299df2ab2299df2ab2', {
      elementos: {
        p_cli_codigo: ventrnsertran.CLI_CODIGO,
        p_ser_codigo: ventrnsertran.SER_CODIGO,
        p_com_codigo: ventrnsertran.COM_CODIGO,
        p_trnsertran_precio: Number(ventrnsertran.TRNSERTRAN_PRECIO),
        p_trnsertran_descuento: Number(ventrnsertran.TRNSERTRAN_DESCUENTO),
        p_trnsertran_tipo: ventrnsertran.TRNSERTRAN_TIPO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  eliminarventrnsertran(ventrnsertran): Promise<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/1d595a5756feec000000000', {
      elementos: {
        p_cli_codigo: ventrnsertran.CLI_CODIGO,
        p_ser_codigo: ventrnsertran.SER_CODIGO,
        p_com_codigo: ventrnsertran.COM_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  getventrnsertran(clicodigo): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/10777eebf1dbef000', {
      elementos: {
        clicodigo
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarValidacion(valida, clicodigo): Observable<any> {
    return this.http.post<any>(this.way + '/ventas/cliente/299df2ab256fea0000000000', {
      elementos: {
        valida, clicodigo
      }
    }, this.confIniciales.httpOptions());
  }

}
