import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';
import {Observable} from 'rxjs';
import {Conencasil} from '../coninterfaces/conencasil';
import {Condetasil} from '../coninterfaces/condetasil';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';

@Injectable({
  providedIn: 'root'
})
export class ConencasilService {
  
  get detencasil(): Condetasil[] {
    return this._detencasil;
  }
  
  set detencasil(value: Condetasil[]) {
    this._detencasil = value;
  }
  
  public dblSaldoAsiento = 0;
  public dblTotalDebe = 0;
  public dblTotalCreditos = 0;
  private _detencasil: Condetasil[];

  public hmpConfiguracion: any[];
  public encabezadoEncasilSelect: Conencasil = {};
  public bolVerificar: boolean;

  private way: string;
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario, 
              private confIniciales: ConfInicialesService, 
              private init: NuevoComponentService) {
    this.way = sessionStorage.getItem('way');
  }
  
  refrescarURL() {
    if (this.way === null) {
      this.way = sessionStorage.getItem('way');
    }
  }
  
  getConfiguraciones(codigo): number {
    const valorConfig = this.hmpConfiguracion.find(element => element.CFG_CODIGO === codigo).CFG_VALOR1;
    return valorConfig;
  }
  
  
  navegarRegistro(strTipo, conencasil: Conencasil): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/conencasil/babeedba12e7780', {
        elementos: {
          p_tipo: strTipo,
          p_com_codigo: '01',
          p_asi_nro: conencasil.ASI_NRO,
          p_tipdia: conencasil.TIPDIA_CODIGO
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  getEncConencasilProm(strAsiNro) {
    /* const strSql = 'SELECT * from CON_ENCASI_L where ASI_NRO =\'' + strAsiNro + '\' AND COM_CODIGO = \'01\''; */
    return this.http.post<any[]>(this.way + '/cont/conencasil/1077598bbaec57000', {
        elementos: {
          ASI_NRO: strAsiNro
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }

  erBancodigo(strConcodigo) {
    return this.http.post(this.way + '/cont/conencasil/3b6b55d986ca18', {
      elementos: {
        CON_CODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConcentro(strConcodigo) {
    return this.http.post(this.way + '/cont/conencasil/3b6cc5d8ebf778', {
      elementos: {
        CON_CODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erContipgas(strConcodigo) {
    return this.http.post(this.way + '/cont/conencasil/3b6cc5fb2cc15c', {
      elementos: {
        CON_CODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erTipgascodigo() {
    return this.http.post(this.way + '/cont/conencasil/1dbecb305719870000', {
      elementos: { },
    }, this.confIniciales.httpOptions());
  }

  erTrnbanconcilia(strBanNumero, strBancodigo, strTipo) {
    return this.http.post(this.way + '/cont/conencasil/edf7775aaecc6000', {
      elementos: {
        TRNBAN_NUMERO: strBanNumero,
        BAN_CODIGO: strBancodigo,
        TRNBAN_TIPO: strTipo
      },
    }, this.confIniciales.httpOptions());
  }

  erConnombre(strConcodigo) {
    return this.http.post(this.way + '/cont/conencasil/3b6cc5ef8b2f6e', {
      elementos: {
        CON_CODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions());
  }

  erConnombreProm(strConcodigo) {
    return this.http.post(this.way + '/cont/conencasil/3b6cc5ef8b2f6e', {
      elementos: {
        CON_CODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  calcularCostoTotal() {
    let intIndiceLista = 0;
    let dblSaldo = 0;
    let dblTotalDebitos = 0;
    let dblTotalHaber = 0;
    
    for (intIndiceLista = 0; intIndiceLista < this.detencasil.length; intIndiceLista++) {
      const item = this.detencasil[intIndiceLista];
      if (item.CON_CODIGO !== '') {
        dblTotalDebitos = Number(dblTotalDebitos) + Number(item.DETASI_DEBE);
        dblTotalHaber = Number(dblTotalHaber) + Number(item.DETASI_HABER);
      }
    }

    dblTotalDebitos = Number(Number(dblTotalDebitos).toFixed(this.confIniciales.getNumDecSist()));
    dblTotalHaber = Number(Number(dblTotalHaber ).toFixed(this.confIniciales.getNumDecSist()));
    
    dblSaldo = dblTotalDebitos - dblTotalHaber;
    
    this.encabezadoEncasilSelect.TOTAL_DEBITOS = String(dblTotalDebitos);
    this.encabezadoEncasilSelect.TOTAL_DEBITOS = Number(this.encabezadoEncasilSelect.TOTAL_DEBITOS).toFixed(this.confIniciales.getNumDecSist());
    
    this.encabezadoEncasilSelect.TOTAL_CREDITOS = String(dblTotalHaber);
    this.encabezadoEncasilSelect.TOTAL_CREDITOS = Number(this.encabezadoEncasilSelect.TOTAL_CREDITOS).toFixed(this.confIniciales.getNumDecSist());
    
    this.encabezadoEncasilSelect.SALDO = String(dblSaldo);
    this.encabezadoEncasilSelect.SALDO = Number(this.encabezadoEncasilSelect.SALDO).toFixed(this.confIniciales.getNumDecSist());
    
    this.dblTotalDebe = dblTotalDebitos;
    this.dblTotalCreditos = dblTotalHaber;
    this.dblSaldoAsiento = dblSaldo;

    this.comas();
    
  }
  
    
  guardarEncasilProm(encAsiL: Conencasil, asiFecha, strTipDia) {
    
    return this.http.post<any[]>(this.way + '/cont/conencasil/715f73aec57254', {
      elementos: {
        p_ASI_fecha_2: asiFecha,
        p_ASI_creditos_3: Number(this.dblTotalCreditos) * -1,
        p_ASI_debitos_4: Number(this.dblTotalDebe),
        p_ASI_descripcion_5: encAsiL.ASI_DESCRIPCION,
        p_COM_codigo_6: '01',
        p_ASI_migra_7: 0,
        p_TIPDIA_codigo_8: strTipDia.codigo,
        p_asi_usuario_act: this.usuario.identificacion,
        p_asi_estado: 0,
        p_asi_comprobante: encAsiL.ASI_COMPROBANTE.toUpperCase(),
        p_asi_mes: encAsiL.ASI_FECHA.getMonth() + 1,
        p_trnban_beneficiario: '',
        p_asi_origen: encAsiL.ASI_ORIGEN
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  getDocumentCont(strAsiNro): Observable<any[]> {
/*     const strSql = 'select asi_documentocont from con_encasi_l where asi_nro=\'' + strAsiNro + '\' AND COM_CODIGO = \'01\'';
 */    
    return this.http.post<any[]>(this.way + '/cont/conencasil/83badc33d666300', {
        elementos: {
          ASI_NRO: strAsiNro
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  // guardarDetasil(detAsiL: Condetasil, asiFecha, intNumLineaDetalle, orden): Observable<any[]> {
  //   return this.http.post<any[]>(this.url + '/ejecutarproceso', {
  //     esquema: this.usuarioEsquema,
  //     elementoEjecutar: 'CON_PCK_DETASI_L.CON_SP_insertarDetAsiento_l_T',
  //     elementos: {
  //       p_DETASI_nrofila_0: intNumLineaDetalle,
  //       p_ASI_nro_1: this.encabezadoEncasilSelect.ASI_NRO,
  //       p_ASI_fecha_2: asiFecha,
  //       p_CEN_codigo_3: detAsiL.CEN_CODIGO,
  //       p_CENSUB_codigo: '',
  //       p_CON_codigo_4: detAsiL.CON_CODIGO,
  //       p_DETASI_concepto_5: detAsiL.DETASI_CONCEPTO,
  //       p_DETASI_importe_6: detAsiL.DETASI_IMPORTE,
  //       p_DETASI_referencia_7: detAsiL.DETASI_REFERENCIA,
  //       p_COM_codigo_11: '01',
  //       p_BAN_codigo_8: detAsiL.BAN_CODIGO,
  //       p_TRNBAN_tipo_9: detAsiL.TRNBAN_TIPO,
  //       p_TRNBAN_numero_10: detAsiL.TRNBAN_NUMERO,
  //       p_TRNRETENCION_nro: detAsiL.TRNRETENCION_NRO,
  //       p_RETENCION_codigo: detAsiL.RETENCION_CODIGO,
  //       p_con_nombre: detAsiL.CON_NOMBRE,
  //       p_detasi_orden: orden,
  //       p_trnban_beneficiario: detAsiL.TRNBAN_BENEFICIARIO,
  //       p_encfac_numero: detAsiL.ENCFAC_NUMERO,
  //       p_encfac_comcodigo: '01',
  //       p_encfac_clicodigo: detAsiL.ENCFAC_CLICODIGO,
  //       p_detasi_tiporeg: detAsiL.DETASI_TIPOREG,
  //       p_detasi_conceptopry: detAsiL.DETASI_CONCEPTOPRY,
  //       p_ConceptoCheque: this.encabezadoEncasilSelect.ASI_DESCRIPCION,
  //       p_prog_codigo: detAsiL.PROG_CODIGO,
  //       p_tipgas_codigo: detAsiL.TIPGAS_CODIGO
  //     },
  //   });
  // }
  
  reversarTransaccion(encAsil: Conencasil): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/conencasil/6ddf76fbb55f88', {
      elementos: {
        p_ASI_nro_1: encAsil.ASI_NRO,
        p_COM_codigo_3: '01',
      },
    }, this.confIniciales.httpOptions());
  }
  
  generarTrnCaja(strAsiNro) {
    return this.http.post<any[]>(this.way + '/cont/conencasil/41d77777762a68', {
      elementos: {
        p_asi_nro: strAsiNro,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion
      },
    }, this.confIniciales.httpOptions());
  }
  
  generarAbonoCXC(strAsiNro) {
    return this.http.post<any[]>(this.way + '/cont/conencasil/41d772978be18c', {
      elementos: {
        p_asi_nro: strAsiNro,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizarEncasilProm(encAsiL: Conencasil, asiFecha, strTipDia) {
    
    return this.http.post<any[]>(this.way + '/cont/conencasil/533be53aec57240', {
      elementos: {
        p_ASI_nro_1: encAsiL.ASI_NRO,
        p_ASI_fecha_2: asiFecha,
        p_ASI_creditos_3: Number(this.dblTotalCreditos) * -1,
        p_ASI_debitos_4: Number(this.dblTotalDebe),
        p_ASI_descripcion_5: encAsiL.ASI_DESCRIPCION,
        p_COM_codigo_6: '01',
        p_ASI_migra_7: Number(0),
        p_TIPDIA_codigo_8: strTipDia.codigo,
        p_asi_usuario_act: this.usuario.identificacion,
        p_asi_estado: Number(0),
        p_asi_comprobante: encAsiL.ASI_COMPROBANTE.toUpperCase(),
        p_asi_mes: encAsiL.ASI_FECHA.getMonth() + 1,
        p_trnban_beneficiario: ''
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarAbono(strAsiNro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/conencasil/3ab2b4aea5e2f8', {
      elementos: {
        ASI_NRO: strAsiNro
      },
    }, this.confIniciales.httpOptions());
    
    
  }
  
  eliminarAsiento(strAsiNro): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/conencasil/1d595a5752b9270000', {
      elementos: {
        p_ASI_nro_1: strAsiNro,
        p_COM_codigo_3: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  actualizaestadoFac(strCodCli, strNumFact): Observable<any[]> {
    if (strCodCli === null) {
      strCodCli = '';
    }
    if (strNumFact === null) {
      strNumFact = '';
    }
    // const strSql = 'begin VEN_SP_ACTLZESTADOXfac(' + '\'01\'' + ',' + '\'' + strCodCli + '\'' + ',' + '\'' + strNumFact + '\');end;';
    
    return this.http.post<any[]>(this.way + '/cont/conencasil/a675dcea9b87a800', {
      elementos: {
        COD_CLIENTE: strCodCli,
        FACT_NUMERO: strNumFact
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarTransaccionBancos(strBanCodigo, strTrnbanTipo, strTrnbanNumero): Observable<any[]> {
    /* const strSql = 'delete from ban_trnban where ban_codigo= \'' + strBanCodigo + '\' ' +
      ' and trnban_tipo= \'' + strTrnbanTipo + '\' ' +
      ' and trnban_numero= \'' + strTrnbanNumero + '\' '; */
    return this.http.post<any[]>(this.way + '/cont/conencasil/1d595bbbbf16ac0000', {
        elementos: {
          BanCodigo: strBanCodigo,
          TrnbanTipo: strTrnbanTipo,
          TrnbanNumero: strTrnbanNumero
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  eliminarLineaAbono(detAsi: Condetasil): Observable<any[]> {
    /* const strSql = 'begin CON_PCK_GENERA_ABNCNJ_ASILT.CXP_SP_ELIMINA_ABONO_CANJE_LN(' +
      '\'' + this.encabezadoEncasilSelect.ASI_NRO + '\'' + ',' + '\'01\'' + ',' + '\'' + detAsi.DETASI_NROFILA + '\');end;'; */
    return this.http.post<any[]>(this.way + '/cont/conencasil/75656acaea5e300', {
      elementos: {
        ASI_NRO: this.encabezadoEncasilSelect.ASI_NRO,
        DETASI_NROFILA: detAsi.DETASI_NROFILA
      },
    }, this.confIniciales.httpOptions());
  }
  
  eliminarDetalleAsiento(detAsi: Condetasil): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/conencasil/756566bbaae49c0', {
      elementos: {
        p_nro_fila: detAsi.DETASI_NROFILA,
        p_ASI_nro_1: this.encabezadoEncasilSelect.ASI_NRO,
        p_COM_codigo_3: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  countEncasil(strAsiNro): Observable<any[]> {
   /*  const strSql = 'select COUNT(*) as NUMREG FROM CON_ENCASI_L where asi_nro= \'' + strAsiNro + '\'' +
      ' and com_codigo= \'01\'' +
      ' and asi_origen is null'; */
    
    return this.http.post<any[]>(this.way + '/cont/conencasil/663d7ebaec57240', {
        elementos: {
          ASI_NRO: strAsiNro
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  anularAsiento(strAsiNro): Observable<any[]> {
   /*  const strSql = 'UPDATE CON_ENCASI_L SET ASI_estado = 0, ASI_ANULADO=1' +
      ' where com_codigo= \'01\'' +
      ' and asi_nro = \'' + strAsiNro + '\''; */
    
    return this.http.post<any[]>(this.way + '/cont/conencasil/abfaaadab9276000', {
        elementos: {
          ASI_NRO: strAsiNro
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  eliminarDistribProg(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/conencasil/eacacd973bbcf000', {
        elementos: {
          ASI_NRO: this.encabezadoEncasilSelect.ASI_NRO
        },
      }, this.confIniciales.httpOptions()
    );
  }
  
  obtenerClave(accion): Observable<any> {
    
    if (accion === 'actDocAnulado') {
      return this.http.post<any>(this.way + '/cont/conencasil/107759557db863000', {
          elementos: {},
        }, this.confIniciales.httpOptions()
      );
    }
    if (accion === 'eliminarAsi') {
      return this.http.post<any>(this.way + '/cont/conencasil/107759557dd593000', {
          elementos: {},
        }, this.confIniciales.httpOptions()
      );
    }
  }
  
  asientoAsociadoConDocumento(strAsiNro, strTipoDiario, strOrigen): Observable<any> {
    return this.http.post<any[]>(this.way + '/cont/conencasil/15c92b98662edc0000', {
      elementos: {
        p_asi_nro: strAsiNro,
        p_tipdia_codigo: strTipoDiario.codigo,
        p_origen: strOrigen,
        p_com_codigo: '01'
      },
    }, this.confIniciales.httpOptions());
  }
  
  activarAsientoAnulado(): Observable<any> {
   /*  const strSql = 'UPDATE CON_ENCASI_L SET ASI_ANULADO=\'0\' ' +
      ' where com_codigo = \'01\' and asi_nro= \'' + this.encabezadoEncasilSelect.ASI_NRO + '\''; */
    
    return this.http.post<any[]>(this.way + '/cont/conencasil/a6755c92afeaa800', {
        elementos: {
          ASI_NRO: this.encabezadoEncasilSelect.ASI_NRO
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  buscarDetFacPendiente(strDocumento, strCodigoCli): Observable<any> {
    
    /* let strSql = 'select a.trncobro_nrocomprobante, a.trncobro_tipodoc,a.cli_codigo,c.con_centro,c.con_nombre,con_codigo1,';
    strSql = strSql + '(select sum(trncobro_importe) from cxc_trnresumen ';
    strSql = strSql + ' where cli_codigo=a.cli_codigo';
    strSql = strSql + ' and trncobro_referencia=a.trncobro_nrocomprobante) as saldo';
    strSql = strSql + ' from cxc_trnresumen  a, ven_maecliente b,con_maecon c';
    strSql = strSql + ' where a.cli_codigo=\'' + strCodigoCli + '\'';
    strSql = strSql + ' and trncobro_nrocomprobante=\'' + strDocumento + '\'';
    strSql = strSql + ' and a.cli_codigo=b.cli_codigo and b.con_codigo1=c.con_codigo'; */
    
    return this.http.post<any[]>(this.way + '/cont/conencasil/bf71aeebd4ccb800', {
        elementos: {
          CLI_CODIGO: strCodigoCli,
          DOCUMENTO: strDocumento
        }
      }, this.confIniciales.httpOptions()
    );
  }
  
  buscarDatosBantrnban(strtrnbanNumero, strbanCodigo, strtrnbanTipo): Observable<any> {
    /* let strSql = 'select a.*,to_char(trnban_fecha,\'dd/mm/yyyy\') as fechaemision,to_char(trnban_fechavence,\'dd/mm/yyyy\') as fechaVencimiento';
    strSql = strSql + ' from ban_trnban a';
    strSql = strSql + ' where trnban_numero= \'' + strtrnbanNumero + '\'';
    strSql = strSql + ' and ban_codigo= \'' + strbanCodigo + '\'';
    strSql = strSql + ' and trnban_tipo= \'' + strtrnbanTipo + '\''; */

    return this.http.post<any[]>(this.way + '/cont/conencasil/5fb8d577bbbad40', {
        elementos: {
          trnbanNumero: strtrnbanNumero,
          banCodigo: strbanCodigo,
          trnbanTipo: strtrnbanTipo
        },
      }, this.confIniciales.httpOptions()
    ); 
  }
  
  actualizarConceptosProy(strConcepto, strDetasiConcepto, strAsinro, strDetasifila): Observable<any> {
    return this.http.post<any[]>(this.way + '/cont/conencasil/299d662ec7673c', {
        elementos: {
          DETASI_CONCEPTOPRY: strConcepto,
          DETASI_CONCEPTO: strDetasiConcepto,
          ASI_NRO: strAsinro,
          DETASI_NROFILA: strDetasifila
        }
      }, this.confIniciales.httpOptions()
    );
    
  }
  
  desplegarTransaccionBancaria(strTrnBanNumero, strTrnBanTipo, strTrnBanCodigo): Observable<any> {
    /* let strSentencia = '';
    strSentencia = 'select  a.*,b.con_codigo as conCodigoBan,con_nombre,con_centro,trnban_concepto from ban_trnban a,ban_maeban b,con_maecon c';
    strSentencia = strSentencia + ' where a.trnban_numero=\'' + strTrnBanNumero + '\'';
    strSentencia = strSentencia + ' and a.trnban_tipo=\'' + strTrnBanTipo + '\'';
    strSentencia = strSentencia + ' and a.ban_codigo=\'' + strTrnBanCodigo + '\'';
    strSentencia = strSentencia + ' and a.ban_codigo=b.ban_codigo and a.com_codigo=b.com_codigo';
    strSentencia = strSentencia + ' and b.con_codigo=c.con_codigo and b.com_codigo=c.com_codigo';
     */

    return this.http.post<any[]>(this.way + '/cont/conencasil/6bb99eed57e2d40', {
        elementos: {
          TrnBanNumero : strTrnBanNumero,
          TrnBanTipo: strTrnBanTipo,
          TrnBanCodigo : strTrnBanCodigo
        },
      }, this.confIniciales.httpOptions()
    );
    
  }
  
  guardarDetasil(detAsiL: Condetasil, asiFecha, intNumLineaDetalle, orden) {
    return this.http.post<any[]>(this.way + '/cont/conencasil/8795b6b5dd57280', {
      elementos: {
        p_DETASI_nrofila_0: intNumLineaDetalle,
        p_ASI_nro_1: this.encabezadoEncasilSelect.ASI_NRO,
        p_ASI_fecha_2: asiFecha,
        p_CEN_codigo_3: detAsiL.CEN_CODIGO,
        p_CENSUB_codigo: '',
        p_CON_codigo_4: detAsiL.CON_CODIGO,
        p_DETASI_concepto_5: detAsiL.DETASI_CONCEPTO,
        p_DETASI_importe_6: detAsiL.DETASI_IMPORTE,
        p_DETASI_referencia_7: detAsiL.DETASI_REFERENCIA,
        p_COM_codigo_11: '01',
        p_BAN_codigo_8: detAsiL.BAN_CODIGO,
        p_TRNBAN_tipo_9: detAsiL.TRNBAN_TIPO,
        p_TRNBAN_numero_10: detAsiL.TRNBAN_NUMERO,
        p_TRNRETENCION_nro: detAsiL.TRNRETENCION_NRO,
        p_RETENCION_codigo: detAsiL.RETENCION_CODIGO,
        p_con_nombre: detAsiL.CON_NOMBRE,
        p_detasi_orden: orden,
        p_trnban_beneficiario: detAsiL.TRNBAN_BENEFICIARIO,
        p_encfac_numero: detAsiL.ENCFAC_NUMERO,
        p_encfac_comcodigo: '01',
        p_encfac_clicodigo: detAsiL.ENCFAC_CLICODIGO,
        p_detasi_tiporeg: detAsiL.DETASI_TIPOREG,
        p_detasi_conceptopry: detAsiL.DETASI_CONCEPTOPRY,
        p_ConceptoCheque: this.encabezadoEncasilSelect.ASI_DESCRIPCION,
        p_prog_codigo: detAsiL.PROG_CODIGO,
        p_tipgas_codigo: detAsiL.TIPGAS_CODIGO
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  comas() {
    this.encabezadoEncasilSelect.TOTAL_DEBITOS = this.init.moneyValidation(this.encabezadoEncasilSelect.TOTAL_DEBITOS);
    this.encabezadoEncasilSelect.TOTAL_CREDITOS = this.init.moneyValidation(this.encabezadoEncasilSelect.TOTAL_CREDITOS);
    this.encabezadoEncasilSelect.SALDO = this.init.moneyValidation(this.encabezadoEncasilSelect.SALDO);
  }

  getTiposdeDiariosProm() {
    return this.http.post<any[]>(this.way + '/cont/conencasil/20eef6596c941', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  getTiposdeDiariosProm1() {
    return this.http.post<any[]>(this.way + '/cont/conencasil/20eef6596c942', {
        elementos: {},
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  getDetConencasilProm(strAsiNro, strOrigen) {
    return this.http.post<any[]>(this.way + '/cont/conencasil/83bad77598bbb00', {
      elementos: {
        p_asi_nro: strAsiNro,
        p_com_codigo: '01',
        p_origen: strOrigen,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
   //CDPJ 
   aplicardistribucionPCC(strAsiNro): Observable<any>{
    return this.http.post<any[]>(this.way + '/cont/conencasil/566b262b6d973c0000000000000000000000000000000', {
      elementos: {
        p_asi_nro1: strAsiNro,
        p_com_codigo1: '01'
       
      },
    }, this.confIniciales.httpOptions());
  }
  erCencodigo(cencodigo) {
    return this.http.post<any[]>(this.way + '/compras/devfacturas/1f5564d56d8ebf0000000000000', {
      elementos: {
        cen_codigo: cencodigo,
        con_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();

  }
  erConnombreallProm(strConcodigo) {
    return this.http.post(this.way + '/cont/conencasil/41dd556acc5d9800000', {
      elementos: {
        CON_CODIGO: strConcodigo
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  //CDPJ
}
