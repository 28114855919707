
<div class="p-grid" style="width: 99%;">
	<div class="p-col-12">
        <app-invmaebarra [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar" [botonRegresar]="btnRegresar"
            [botonBorrar]="btnBorrar"
            (signal)="manejarSenales($event)" [barraBotonesDesplaz] = "true"
            [botonBuscar]="btnBuscar"  
            [barraBotones3]="true" [botonBinocular]="true"
            [barraBotones5]="false" [botonAlmacenardoc] = "true">
        </app-invmaebarra>
	</div>
</div>
<p-confirmDialog [transitionOptions]="'0ms'" key="NomCostFech" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<p-confirmDialog [transitionOptions]="'0ms'" key="NomdetCostFech" title="Confirmar Acción"
                 appendTo="body"></p-confirmDialog>
<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="costosFech"></p-toast>
	
<div class="divgrups">
                    
    <div class="p-grid">
        <div class="p-col-1">
         </div>
        <div class="p-col-4">
            
        </div>
        <div class="p-col-2">
        
        </div>
        <div class="p-col-1">

        </div>
        <div class="p-col-4">
            <table style="width: 100%;text-align: left;">
                <tr style="background-color: rgba(66, 214, 240, 0.829);">
                    <td style="border: 1px solid; text-align: center;">{{identiUsuario}}</td>
                    <td style="border: 1px solid; text-align: center;">{{fecha | date: 'dd/MM/yyyy' }}</td>
                    <td style="border: 1px solid; text-align: center;">{{fecha | date: 'hh:mm:ss' }}</td>
                </tr>
            </table>
        </div>
    </div>
</div>  
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-2">
                    <span>Documento:</span>
                </div>
                <div class="p-col-2">
                    <input class="frm" id="cinfDoc" type="text" pInputText 
                    [(ngModel)]="selectedcostfec.CINF_DOCUMENTO"
                    
                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                    autocomplete="off"  [disabled]="!editar">
                </div>             
                <div class="p-col-2">
                    <span>Centro de Costos:</span>
                </div>
                <div class="p-col-2">
                    <input class="frm" id="cenCod" type="text" pInputText 
                    [(ngModel)]="selectedcostfec.CEN_CODIGO"
                    
                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                    autocomplete="off"  [disabled]="!editar">
                </div>
                <div class="p-col-1">
                    <button type="button"
                                style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                (click)="setFocus($event)" (click)="busCen(selectedcostfec.CEN_CODIGO)"></button>
                </div>
            
                <div class="p-col-3" style="text-align: left">
                    <span style="text-align:left; color: rgb(0,0,255); font-weight: bold;font-size: 15px;">{{span_esta_civil}}</span>
            
                    </div>
            </div>
            <div class="p-grid">
                <div class="p-col-2">	
                    <span>Mes:</span>
                </div>
                <div class="p-col-2">
                    <p-dropdown [options]="cmbMes" [style]="{'width': '100%'}"
                                [(ngModel)]="pMes" optionLabel="label"
                                [panelStyle]="{'width':'110%'}"
                                (onChange)="cambioOptionLicen($event)" 
                                [disabled]="!editar"
                                ></p-dropdown>
                </div>
                <div class="p-col-2">	
                    <span>Año:</span>
                </div>
                <div class="p-col-2">
                    <input class="frm" id="cinfanio" type="number" pInputText 
                    [(ngModel)]="selectedcostfec.CINF_ANIO"
                    
                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"          
                    autocomplete="off" [disabled]="!editar">
                </div>
            </div>
            
        </div>
    </div>
</div>
<p-tabView  [(activeIndex)]="indexTab">
    <p-tabPanel header="Detalle" >
        <app-aggridsaci 
                        [width]=""
                        [height]="largo"
                        [enableSorting]="false"
                        [enableFilter]="true"
                        [rowData]="prodcosfech.costosfec"
                        [frameworkComponents]="frameworkComponents"
                        [animateRows]="true"
                        [rowSelection]="rowSelection"
                        [columnDefs]="columncostfech"
                        [defaultColDef]="defaultColDefcostfech"
                        [rowStyle]="rowStyle"
                        [singleClickEdit]="false"
                        [botones]="true"
                        (selected)="seleccostfech($event)"
                        [mostrarGuardar]="false"
                        (cambios)="cambiocostfech()"
                        [botonesAdicionales]="true"
                        [botonGuardar]="true"
                        [botonNuevo]="!editar"
                        [botonBorrar]="false"
                        [botonRegresar]="true"
                    
        ></app-aggridsaci>
    </p-tabPanel>
</p-tabView>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
					 [consulta]="consulta" [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionbus($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFastEstCiv"
		  [transitionOptions]="'0ms'"
		  [modal]="true"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloEstCiv" [consulta]="consulta" [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="manejarSeleccionEstCiv($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '40vw', height: '400px'}">
    <app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
        [strNumDoc]="selectedcostfec.CINF_DOCUMENTO" [strCampo]="'CINF_DOCUMENTO'"
        (cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'PRODCOSTOXFECHA'" [txtNumComprobante] = "selectedcostfec.CINF_DOCUMENTO"
	(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>