import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { DatePipe } from '@angular/common';
import { MessageService, ConfirmationService,SelectItem } from 'primeng/api';
import { InvmaeloteService } from '../venservicios/invmaelote.service';
import { InvMaelote } from '../veninterfaces/invmaelote';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';//CDPJ
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';

@Component({
  selector: 'app-invmaelote',
  templateUrl: './invmaelote.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvmaeloteComponent implements OnInit {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  largo: string;

  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  oculto: boolean;
  activado: boolean;
  desactivado: boolean;

  loteseleccionado: InvMaelote;
   // busqueda
   opcionbusqueda: string;
   types: SelectItem[];
   arregloCons: any[];
   busquedacampos: string[];
   tabla: string;
   consulta:string;
   where: string;
   displayDialogBusquedaFast: boolean;


  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDefDetalle;

  columnDefsDetalle = [
    { // 0
      headerName: 'Lote', field: 'LOTE_NUMERO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 20
        }
      }
    },
    { // 2
      headerName: 'Artículo', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 20,
          opcionbuscar: true
        }
      }
    }, { // 3
      headerName: 'Cantidad', field: 'LOTE_CANTIDAD', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          clave: false,
          tamanocelda: 20
        }
      }
    },
    { // 4
      headerName: 'F.Fabricación', field: 'LOTE_FECHAFABRICACION', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          clave: false,
          tamanocelda: 20
        }
      }
    },
     { // 5
      headerName: 'F.Vencimiento', field: 'LOTE_FECHAVENCIMIENTO', cellEditor: 'cellPrueba', width: 110,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          clave: false,
          tamanocelda: 20
        }
      }
    },
    { // 6
      headerName: 'Reg.Sanitario', field: 'LOTE_REGSANITARIO', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          opcionbuscar: false
        }
      }
    },
    { // 7
      headerName: 'Presentación', field: 'LOTE_PRESENTACION', cellEditor: 'cellPrueba', width: 150,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          opcionbuscar: false
        }
      }
    },
  ];


  constructor(public maeloteservice: InvmaeloteService, private datePipe: DatePipe,private busqService: InvbusquedaService,
    private messageService: MessageService, private init: NuevoComponentService, private confirmationService: ConfirmationService,private confIniciales: ConfInicialesService) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultColDefDetalle = {
      editable: true,
      width: 80,
      objeto: 'maelote'
    };
  }


  ngOnInit() {
    this.largo = '300';
    this.oculto = true;
    this.activado = false;
    this.desactivado = true;

    this.botonNuevo = this.activado;
    this.botonGuardar = this.desactivado;
    this.botonBorrar = this.activado;
    this.botonRegresar = this.desactivado;
//CDPJ
this.confIniciales.getDecimales('frmINV_TRNLOTE').subscribe((dec) => {
  if (dec !== null) {
    dec.map((decimales) => {
      if (decimales.NUMDEC_TIPDOC === 'detcant') {
        if (decimales.NUMDEC_CAMPO === 'CANTIDAD') {
          this.maeloteservice.decimalesCANTIDADlote = Number(decimales.NUMDEC_NUMDEC);
        } 
      }
    });
    this.columnDefsDetalle[2].cellEditorParams = {
      values: {
        mayusculas: true,
        numeros: true,
        alphabetic: false,
        saltoslinea: 1,
        newrow: false,
        numerodecimales: this.maeloteservice.decimalesCANTIDADlote,
        obligatorio: true,
        fecha: false,
        clave: false,
        tamanocelda: 20,
      }
    };
    this.aggrid.refreshColumnDefs();
  }

})
//CDPJ
    this.mostrarLotes();
  }

  manejarSenales(valor) {

    if (valor === 'Nuevo') {
      this.nuevoLote();
    }

    if (valor === 'Guardar') {
      this.guardarLote();
      this.botonNuevo = this.activado;
      this.botonGuardar = this.desactivado;
      this.botonBorrar = this.activado;
      this.botonRegresar = this.desactivado;
    }

    if (valor === 'Borrar') {
      this.eliminarLote();
    }

    if (valor === 'Cancelar') {
      this.mostrarLotes();
      this.botonNuevo = this.activado;
      this.botonGuardar = this.desactivado;
      this.botonBorrar = this.activado;
      this.botonRegresar = this.desactivado;
    }

    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (valor === 'Buscar') {
      this.buscarLotes();
    }
  }
  buscarLotes(){
    this.opcionbusqueda='lotes'
    this.types=[
      { label: 'Num Lote', value: 'LOTE_NUMERO' },
      { label: 'Art. Código', value: 'ART_CODIGO' },
      { label: 'Art. Nombre', value: 'ART_NOMBRE' },
      { label: 'Fecha Fabricación', value: 'LOTE_FECHAFABRICACION' },
      { label: 'Fecha Vencimiento', value: 'LOTE_FECHAVENCIMIENTO' },
      { label: 'Registro Sanitario', value: 'LOTE_REGSANITARIO' },
    ];
    this.busquedacampos = ['', '', '', '', '', ''];
    this.tabla='INV_MAELOTE';
    this.consulta='*'
    this.where='LOTE_NUMERO IS NOT NULL ORDER BY LOTE_FECHAFABRICACION';

    // this.busqService.busquedaMaelotes().subscribe((datos: any[]) => {
    //   this.arregloCons = datos;
    //   this.arregloCons.map((registro) => {
    //     if (registro.LOTE_FECHAVENCIMIENTO !== null) {
    //       registro.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(registro.LOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
    //     } else {
    //       registro.LOTE_FECHAVENCIMIENTO = '';
    //     }
    //     if (registro.LOTE_FECHAFABRICACION !== null) {
    //       registro.LOTE_FECHAFABRICACION = this.datePipe.transform(registro.LOTE_FECHAFABRICACION, 'dd/MM/yyyy');
    //     } else {
    //       registro.LOTE_FECHAFABRICACION = '';
    //     }
    //   });
    //   //this.spin = false;
    //   this.displayDialogBusquedaFast = true;
    // });
    this.displayDialogBusquedaFast = true;
  }
  manejarSeleccion(opcion){
    if (this.opcionbusqueda === 'lotes') {
      this.llenarLotes(opcion);
      
    }
    this.displayDialogBusquedaFast = false;
  }
  mostrarLotes() {
    this.maeloteservice.listarDatos().subscribe(data => {
    
      const lotes = [];
      for (const rs of data) {
        rs.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(rs.LOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
        // rs.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(rs.LOTE_FECHAVENCIMIENTO, 'yyyy-MM-dd');
        rs.CODIGO_AUXILIAR = 1;
        rs.CLAVE = rs.LOTE_NUMERO;
        rs.LOTE_FECHAFABRICACION = rs.LOTE_FECHAFABRICACION !== null ? this.datePipe.transform(rs.LOTE_FECHAFABRICACION, 'dd/MM/yyyy') : rs.LOTE_FECHAFABRICACION;
        rs.LOTE_CANTIDAD=rs.LOTE_CANTIDAD === null || rs.LOTE_CANTIDAD === undefined? 0.00:Number(rs.LOTE_CANTIDAD).toFixed(this.maeloteservice.decimalesCANTIDADlote)
        rs.LOTE_TIPOARTPROD=rs.LOTE_TIPOARTPROD === null || rs.LOTE_TIPOARTPROD === undefined?'':rs.LOTE_TIPOARTPROD
        lotes.push(rs);
      }
      this.maeloteservice.detmaelote = lotes;
      //this.aggrid.refreshColumnDefs();
    });
  }
  llenarLotes(datos) {
    //this.maeloteservice.listarDatos().subscribe(data => {
    this.maeloteservice.detmaelote = datos;
      const lotes = [];
      for (const rs of this.maeloteservice.detmaelote) {
        rs.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(rs.LOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
        // rs.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(rs.LOTE_FECHAVENCIMIENTO, 'yyyy-MM-dd');
        rs.CODIGO_AUXILIAR = 1;
        rs.CLAVE = rs.LOTE_NUMERO;
        rs.LOTE_FECHAFABRICACION = rs.LOTE_FECHAFABRICACION !== null ? this.datePipe.transform(rs.LOTE_FECHAFABRICACION, 'dd/MM/yyyy') : rs.LOTE_FECHAFABRICACION;
        rs.LOTE_CANTIDAD=rs.LOTE_CANTIDAD === null || rs.LOTE_CANTIDAD === undefined? 0.00:Number(rs.LOTE_CANTIDAD).toFixed(this.maeloteservice.decimalesCANTIDADlote)
        lotes.push(rs);
      }
      this.maeloteservice.detmaelote = lotes;
      this.aggrid.refreshColumnDefs();
    //});
  }
  nuevoLote() {
    const lote: InvMaelote = {
      LOTE_NUMERO: '',
      LOTE_FECHAVENCIMIENTO: '',
      COM_CODIGO: '01',
      ART_CODIGO: '',
      LOTE_CANTIDAD: '',
      LOTE_ORIGEN: 'MAELOT',
      CODIGO_AUXILIAR: 0,
      LOTE_FECHAFABRICACION: '',
      LOTE_REGSANITARIO: '',
      LOTE_PRESENTACION: '',
      LOTE_TIPOARTPROD:''
    };

    const indi = this.maeloteservice.detmaelote.indexOf(this.loteseleccionado);
    if (indi === -1) {
      lote.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.maeloteservice.detmaelote.push(lote);
      this.loteseleccionado = lote;
      this.aggrid.refreshaggrid(this.maeloteservice.detmaelote, this.defaultColDefDetalle.objeto);
    } else {
      lote.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.maeloteservice.detmaelote.splice(indi + 1, 0, lote);
      this.loteseleccionado = lote;
      this.aggrid.refreshaggridindex(this.maeloteservice.detmaelote, this.defaultColDefDetalle.objeto, indi);
    }

    this.botonesmodificar();

  }

  guardarLote() {
    if (this.loteseleccionado.LOTE_NUMERO.length === 0) {
      this.messageService.add({
        key: 'maelote',
        severity: 'error',
        summary: 'Error',
        detail: 'Código  incorrecto.'
      });
      return;
    }
    if (this.loteseleccionado.LOTE_FECHAVENCIMIENTO.length === 0) {
      this.messageService.add({
        key: 'maelote',
        severity: 'error',
        summary: 'Error',
        detail: 'Fecha incorrecta.'
      });
      return;
    }

    // this.loteseleccionado.TRNLOTE_CANTIDAD = Number(this.loteseleccionado.TRNLOTE_CANTIDAD);
    if (this.loteseleccionado.CODIGO_AUXILIAR === 0) {
      this.maeloteservice.insertarMaeLote(this.loteseleccionado).subscribe(data => {
        this.messageService.add({
          key: 'maelote',
          severity: 'success',
          summary: 'Información',
          detail: 'Lote guardado.'
        });
        this.mostrarLotes();
      });
    }
    if (this.loteseleccionado.CODIGO_AUXILIAR === 1) {
      this.maeloteservice.actualizarMaeLote(this.loteseleccionado).subscribe(data => {
        this.messageService.add({
          key: 'maelote',
          severity: 'success',
          summary: 'Información',
          detail: 'Lote actualizado.'
        });
        this.mostrarLotes();
      });
    }

  }

  eliminarLote() {
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro ?',
      header: 'Eliminar Servicio',
      icon: 'pi pi-exclamation-triangle',
      key: 'maeloteconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.maeloteservice.eliminarMaeLote(this.loteseleccionado).subscribe(data => {
          this.messageService.add({
            key: 'maelote',
            severity: 'success',
            summary: 'Información',
            detail: 'Lote eliminado.'
          });
          this.mostrarLotes();
        }, error => {
          this.messageService.add({
            key: 'maelote',
            severity: 'error',
            summary: 'Error',
            detail: error.error
          });
        }
        );
      },
      reject: () => {
      }
    });


  }

  seleccionlote(params) {
    if (params.object === undefined) {
      return;
    }

    this.loteseleccionado = params.object;
  }

  botonesmodificar() {
    this.botonNuevo = this.desactivado;
    this.botonGuardar = this.activado;
    this.botonBorrar = this.desactivado;
    this.botonRegresar = this.activado;
  }


}
