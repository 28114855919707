import { Component, OnInit, ViewChild } from '@angular/core';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { InvbusquedaService } from '../invservicios/invbusqueda.service';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { InvtrnajustenegService } from "../invservicios/invtrnajusteneg.service";
import { InvTrnAjustes } from '../invinterfaces/invtrnajustes';
import { DatePipe } from '@angular/common';
import { MessageService, ConfirmationService, SelectItem } from 'primeng/api';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
@Component({
  selector: 'app-invtrnajusteneg',
  templateUrl: './invtrnajusteneg.component.html',
  styleUrls: ['./invtrnajusteneg.component.css']
})
export class InvtrnajustenegComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo;
  btnGuardar;
  btnBorrar;
  indicador: any;
  permisos: SegMaePermiso;
  decimalesCosto: number;
  // Tabla
  public frameworkComponents;
  rowStyle;

  defaultColTrnAjustesNeg;
  reghora = /^([01]\d|2[0-3)]):([0-5]\d)$/
  rowSelection = 'single';
  bodcodigo: string = ''
  largo: string;
  dettrnajustesneg: InvTrnAjustes
  columnDefstrnAjustesNeg = [
    { // 0
      headerName: 'Artículo', field: 'ART_CODIGO', cellEditor: 'cellPrueba', width: 100,
      editable: (params) => {

        return true;
      },
      // editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true,
          tienecalculos: true,
        }
      }
    },
    { // 1
      headerName: 'Descripcion', field: 'ART_NOMBRE', cellEditor: 'cellPrueba', width: 300,
      editable: (params) => {

        return false;
      },
    },
    {//2
      headerName: 'Fecha', field: 'TRNART_FECHAES', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const bool = this.isedit(params.data)
        return bool;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    {//3
      headerName: 'Hora', field: 'TRNART_HORA', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const bool = this.isedit(params.data)
        return bool;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 5,
          tienecalculos: true
        }
      }
    },
    {//4
      headerName: 'Numero', field: 'TRNART_NUMERO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const bool = this.isedit(params.data)
        return bool;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true
        }
      }
    },
    { // 5
      headerName: 'Costo Total', field: 'TRNART_COSTOT', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'right' },
      editable: (params) => {

        return true;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tienecalculos: true,
          tamanocelda: 30,
          numposneg: true
        }
      }

    },
    { // 6
      headerName: 'Cuenta Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 150,
      editable: (params) => {

        return true;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: false,
          // obligatorio: true,
          fecha: false,
          opcionbuscar: true,
          tamanocelda: 35
        }
      }
    },
    { // 7
      headerName: 'Centro C.', field: 'CEN_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true,
          tienecalculos: true
        }
      }
    }
  ]
  constructor(public invTrnAjusteService: InvtrnajustenegService, private datePipe: DatePipe, private messageService: MessageService,
    private confirmationService: ConfirmationService, private init: NuevoComponentService, private permisosService: PermisosService,
    private confIniciales: ConfInicialesService, private auditoriagral: AuditoriagralService, ) {

    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColTrnAjustesNeg = {
      editable: true,
      width: 100,
      objeto: 'invtrnajustesneg',
      resizable: true
    };
  }

  ngOnInit(): void {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.invTrnAjusteService.detTrnAjustesNeg = [];
    this.dettrnajustesneg = {}
    this.confIniciales.getDecimales('frmINV_TRNAJUSTES').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_CAMPO === 'COSTO') {
            this.decimalesCosto = Number(decimales.NUMDEC_NUMDEC);
            this.invTrnAjusteService.decimales = this.decimalesCosto;
          }
        });
        this.columnDefstrnAjustesNeg[5].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.decimalesCosto,
            obligatorio: false,
            fecha: false,
            tamanocelda: 25,
            tienecalculos: false,
            numposneg: true
          }
        };
        this.aggrid.refreshColumnDefs();
      }
    });
    this.invTrnAjusteService.getBodcodigo().subscribe((dec) => {
      if (dec !== null && dec !== undefined && dec.length > 0) {
        if (dec[0] !== null && dec[0] !== undefined) {
          this.bodcodigo = dec[0].REF_CODIGO
        }

      }

    })
    this.getPermisos();
    this.listarDatos();
  }
  async getPermisos() {
    console.log(this.init.tabs[this.init.tabindex - 1]);
    console.log(this.init.tabs[this.init.tabindex - 1].component);
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.messageService.add({
        key: 'invtrnajustes',
        severity: 'error',
        summary: 'Información',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }
  manejarSenales(evento) {
    if (evento === 'Nuevo') {
      if (this.permisos.PERTODO === 1) {
        this.nuevo();
      } else {
        if (this.permisos.PERINSERCION === 1) {
          this.nuevo();
        } else {
          this.messageService.add({
            key: 'invtrnajustes',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de inserción, acción denegada'
          });
        }
      }
    }
    if (evento === 'Guardar') {
      if (this.permisos.PERTODO === 1) {
        this.guardar();
      } else {
        if (this.permisos.PERACTUALIZACION === 1) {
          this.guardar();
        } else {
          this.messageService.add({
            key: 'invtrnajustes',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de actualización, acción denegada'
          });
        }
      }
    }
    if (evento === 'Cancelar') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.listarDatos();
    }
    if (evento === 'Borrar') {
      if (this.permisos.PERTODO === 1) {
        this.eliminarregistro();
      } else {
        if (this.permisos.PERELIMACION === 1) {
          this.eliminarregistro();
        } else {
          this.messageService.add({
            key: 'invtrnajustes',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de eliminación, acción denegada'
          });
        }
      }
    }
    if (evento === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }

  }
  async eliminarregistro() {
    try {
      if (this.dettrnajustesneg === null || this.dettrnajustesneg === undefined || this.dettrnajustesneg === '') {
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Información',
          detail: 'Seleccione un registro'
        });
        return;
      }
      await this.invTrnAjusteService.eliminarregistro(this.dettrnajustesneg)
      this.auditoriagral.registrarAuditoria('INV_TRNKARDEXAJUSTE', this.dettrnajustesneg.ARTPRE_CODIGO + '/' + this.dettrnajustesneg.TRNART_NUMERO, 'E',
        '', '01', '', '', '', '').subscribe(() => {
        });
      await this.listarDatos();
      this.messageService.add({
        key: 'invtrnajustes',
        severity: 'success',
        summary: 'Información',
        detail: 'Se eliminó el registro exitosamente '
      });

    } catch (err) {
      this.messageService.add({
        key: 'invtrnajustes',
        severity: 'error',
        summary: 'Información',
        detail: 'Error al eliminar el registro ' + err.error
      });
    }
  }
  async listarDatos() {
    const data = await this.invTrnAjusteService.getlistadatos()
    if (data !== null && data !== undefined) {
      this.invTrnAjusteService.detTrnAjustesNeg = data;
      if (this.invTrnAjusteService.detTrnAjustesNeg.length > 0) {
        for (const item of this.invTrnAjusteService.detTrnAjustesNeg) {
          item.TRNART_FECHAES = this.datePipe.transform(item.TRNART_FECHAES, 'dd/MM/yyyy');
          //item.TRNART_HORA=this.datePipe.transform(item.TRNART_HORA, 'HH:mm');
          item.TRNART_COSTOT = Number(Number(item.TRNART_COSTOT).toFixed(2));
          item.NUEVO = false;
        }
        this.aggrid.refreshaggrid(this.invTrnAjusteService.detTrnAjustesNeg, this.defaultColTrnAjustesNeg.objeto);
      }
    }
  }

  nuevo() {
    if (this.bodcodigo === '' || this.bodcodigo === null || this.bodcodigo === undefined) {
      this.messageService.add({
        key: 'invtrnajustes',
        severity: 'error',
        summary: 'Información',
        detail: 'La bodega debe estar parametrizada en el módulo de referencias'
      });
      return;
    }
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    const justeNuevo: InvTrnAjustes = {
      ART_CODIGO: '',
      ART_NOMBRE: '',
      TRNART_FECHAES: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      TRNART_HORA: this.datePipe.transform(new Date(), 'HH:mm'),
      TRNART_NUMERO: '',
      TRNART_COSTOT: Number(0).toFixed(2),
      CON_CODIGO: '',
      CEN_CODIGO: '',
      TRNART_ORIGEN: 'INVA',
      TRNART_TIPO: 'AC',
      TRNART_REFERENCIA: 'AJUSTE COSTOS',
      BOD_CODIGO: this.bodcodigo,
      NUEVO: true,
    };
    this.invTrnAjusteService.detTrnAjustesNeg.push(justeNuevo);
    this.aggrid.refreshaggrid(this.invTrnAjusteService.detTrnAjustesNeg, this.defaultColTrnAjustesNeg.objeto);
    this.dettrnajustesneg = this.invTrnAjusteService.detTrnAjustesNeg[this.invTrnAjusteService.detTrnAjustesNeg.length - 1];
  }
  async guardar() {
    if (this.dettrnajustesneg === null || this.dettrnajustesneg === undefined || this.dettrnajustesneg === '') {
      this.messageService.add({
        key: 'invtrnajustes',
        severity: 'error',
        summary: 'Información',
        detail: 'Seleccione un registro'
      });
      return;
    } else {
      let numart = 0;
      let numcnta = 0;
      let numcen = 0;
      const articulo: any = await this.invTrnAjusteService.encontrarArtCntaySer(this.dettrnajustesneg.ART_CODIGO, 'A');
      const cuenta: any = await this.invTrnAjusteService.encontrarArtCntaySer(this.dettrnajustesneg.CON_CODIGO, 'C');
      const cencod= await this.invTrnAjusteService.validarcencodigo(this.dettrnajustesneg.CEN_CODIGO);
      if (articulo !== null) {
        for (const rs of articulo) {
          numart = rs.NUMERO;
        }
      }
      if (cuenta !== null) {
        for (const rs of cuenta) {
          numcnta = rs.NUMERO;
        }
      }
      if (cencod !== null) {
        for (const rs of cencod) {
          numcen = rs.NUMERO;
        }
      }
      if (this.dettrnajustesneg.TRNART_FECHAES.toString().includes('-')) {
        this.dettrnajustesneg.TRNART_FECHAES = this.datePipe.transform(this.dettrnajustesneg.TRNART_FECHAES, 'dd/MM/yyyy')
      }
      if (this.reghora.test(this.dettrnajustesneg.TRNART_HORA) === false) {
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo hora debe estar el formato HH:mm'
        });
        return;
      }
      if(this.dettrnajustesneg.ART_CODIGO === null || this.dettrnajustesneg.ART_CODIGO === undefined || this.dettrnajustesneg.ART_CODIGO === ''){
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo artículo no debe estar vacío'
        });
        return;
      }
      if(this.dettrnajustesneg.TRNART_NUMERO === null || this.dettrnajustesneg.TRNART_NUMERO === undefined || this.dettrnajustesneg.TRNART_NUMERO === ''){
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo Número no debe estar vacío'
        });
        return;
      }
      if(numart === 0){
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Información',
          detail: 'El artículo '+this.dettrnajustesneg.ART_CODIGO+' No existe'
        });
        return;
      }
      if(numcnta === 0){
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Información',
          detail: 'La cuenta '+this.dettrnajustesneg.CON_CODIGO+' No existe'
        });
        return;
      }
      if(numcen === 0){
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Información',
          detail: 'El centro de costos '+this.dettrnajustesneg.CEN_CODIGO+' No existe'
        });
        return;
      }
      this.guardarfin();
    }

  }
  async guardarfin() {
    try {
      if (this.dettrnajustesneg.NUEVO === true) {
        await this.invTrnAjusteService.insertardetalle(this.dettrnajustesneg);
        this.auditoriagral.registrarAuditoria('INV_TRNKARDEXAJUSTE', this.dettrnajustesneg.ARTPRE_CODIGO + '/' + this.dettrnajustesneg.TRNART_NUMERO, 'I',
          '', '01', '', '', '', '').subscribe(() => {
          });
      } else {
        await this.invTrnAjusteService.actualizardetalle(this.dettrnajustesneg);
        this.auditoriagral.registrarAuditoria('INV_TRNKARDEXAJUSTE', this.dettrnajustesneg.ARTPRE_CODIGO + '/' + this.dettrnajustesneg.TRNART_NUMERO, 'A',
          '', '01', '', '', '', '').subscribe(() => {
          });
      }

      this.messageService.add({
        key: 'invtrnajustes',
        severity: 'success',
        summary: 'Información',
        detail: 'Se insertó el registro exitosamente '
      });
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      await this.listarDatos();
    } catch (error) {
      this.messageService.add({
        key: 'invtrnajustes',
        severity: 'error',
        summary: 'Información',
        detail: 'Hubo un error al inserte el registro ' + error
      });
    }
  }
  seleccionajuste(params) {
    if (params.object === undefined) {
      return;
    }
    this.dettrnajustesneg = params.object;
    this.validanuevoreg();
  }
  validanuevoreg() {
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColTrnAjustesNeg.objeto].getFocusedCell() === null) {
      return;
    }
    console.log(this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColTrnAjustesNeg.objeto].getFocusedCell().column)
    console.log(this.invTrnAjusteService.detTrnAjustesNeg.indexOf(this.dettrnajustesneg))
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColTrnAjustesNeg.objeto].getFocusedCell().column === null) {
      return;
    }
    const colid = this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColTrnAjustesNeg.objeto].getFocusedCell().column.colId
    // if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
    //   this.defaultColDef.objeto].getFocusedCell().column.colId === 'DETFACPRO_UNIDAD') {
    //console.log(this.detfacproSelected.ART_MULTIUNIDAD, this.detfacproSelected.UNIDAD);
    if (this.dettrnajustesneg.NUEVO === true) {
      // const opciones = [];
      // opciones.push(this.detfacproSelected.UNIDAD);
      // this.utilitariosService.getUnidadesEqui(this.detfacproSelected.UNIDAD).subscribe((res) => {
      //   if (res !== null) {
      //     res.map((unidades) => {
      //       opciones.push(unidades.UNI_CODIGODESTINO);
      //     });
      //   }
      this.columnDefstrnAjustesNeg[0].cellEditor = 'cellPrueba';
      this.columnDefstrnAjustesNeg[1].cellEditor = 'cellPrueba';
      this.columnDefstrnAjustesNeg[2].cellEditor = 'cellPrueba';
      this.columnDefstrnAjustesNeg[3].cellEditor = 'cellPrueba';
      this.columnDefstrnAjustesNeg[4].cellEditor = 'cellPrueba';
      this.columnDefstrnAjustesNeg[5].cellEditor = 'cellPrueba';
      this.columnDefstrnAjustesNeg[6].cellEditor = 'cellPrueba';
      this.columnDefstrnAjustesNeg[7].cellEditor = 'cellPrueba';
      this.columnDefstrnAjustesNeg[0].editable = (params) => {
        const boolEdit = this.isedit(params.data);
        return boolEdit;
      };
      this.columnDefstrnAjustesNeg[2].editable = (params) => {
        const boolEdit = this.isedit(params.data);
        return boolEdit;
      };
      this.columnDefstrnAjustesNeg[3].editable = (params) => {
        const boolEdit = this.isedit(params.data);
        return boolEdit;
      };
      this.columnDefstrnAjustesNeg[4].editable = (params) => {
        const boolEdit = this.isedit(params.data);
        return boolEdit;
      };
      this.columnDefstrnAjustesNeg[6].editable = (params) => {
        const boolEdit = true;
        return boolEdit;
      };
      this.columnDefstrnAjustesNeg[7].editable = (params) => {
        const boolEdit = true;
        return boolEdit;
      };
      // this.columnDefsDetfac[5].cellEditorParams = {
      //   values: opciones
      // };
      this.aggrid.refreshColumnDefs();
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColTrnAjustesNeg.objeto].setFocusedCell(
        this.invTrnAjusteService.detTrnAjustesNeg.indexOf(this.dettrnajustesneg), colid);
      //});
      // } 
      // else {
      //   this.columnDefsDetfac[5].cellEditor = 'cellPrueba';
      //   this.columnDefsDetfac[5].editable = false;
      //   this.aggrid.refreshColumnDefs();
      //   this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.encfacproService.detfacproarray[this.indicador].indexOf(this.detfacproSelected), 'DETFACPRO_UNIDAD');
      // }
      // }
    }
  }
  isedit(params) {
    let bool = false;
    if (params.NUEVO === true) {
      bool = true
    } else {
      bool = false
    }
    return bool;
  }
  cambio(evento) {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
  }
}
