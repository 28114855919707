<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="preanuplanc"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="PreAnuPlanc" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>

<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar"
            [botonBorrar]="btnBorrar" [botonRegresar]="false" [barraBotonesDesplaz]="true" [barraBotones3]="true"
            [barraBotonesAsiCon]="true" [barraBotones5]="true" [botonconsolida]="true" [botonBuscarPermanente]="true"
            [botonImprimirPermanente]="true"></app-invmaebarra>
    </div>
</div>
<div class="p-col-12">
    <p-tabView [(activeIndex)]="index" (activeIndexChange)="cambioTab()">
        <p-tabPanel header="Presupuesto Anual Plan Ctas.">
            <div class="divgrups">
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span>Año:</span>
                            </div>
                            <div class="p-col-1">
                                <input pInputText type="text" size="4" id="ENCPRESANU_ANIO" [disabled]="boolnuevo"
                                    oninput="this.value= this.value.replace(/[^0-9]/g, '');"
                                    (keydown.enter)="busquedaanio(conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[indicador].ENCPRESANU_ANIO)"
                                    [(ngModel)]="conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[indicador].ENCPRESANU_ANIO"
                                    autocomplete="off" [maxLength]="4">
                            </div>
                            <div class="p-col-1">
                                <button class="littlebuttons"
                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                    (click)="busquedaanio(conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[indicador].ENCPRESANU_ANIO)"></button>
                            </div>

                        </div>
                    </div>
                    <div class="p-col-12">
                        <div class="p-grid">
                            <div class="p-col-1">
                                <span>Descripción:</span>
                            </div>
                            <div class="p-col-11">
                                <input pInputText type="text" size="14" id="ENCPRESANU_DESCRIPCION"
                                    [disabled]="boolconsolida"
                                    [(ngModel)]="conpresuanucenService.encabezadoPresuAnuSeleccionadoarray[indicador].ENCPRESANU_DESCRIPCION"
                                    autocomplete="off" (input)="cambio($event)" [maxLength]="100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divg" style="height: 650px">
                <app-aggridsaci [width]="" [height]="200" [enableSorting]="false" [enableFilter]="true"
                    [rowData]="conpresuanucenService.detpresuanupuestoarray" [frameworkComponents]="frameworkComponents"
                    [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefspresuAnuCon"
                    [defaultColDef]="defaultColDefPresuanucon" [rowStyle]="rowStyle" [singleClickEdit]="false"
                    [botones]="false" (selected)="selectedPresuAnuCon($event)" (cambios)="cambio($event)">
                </app-aggridsaci>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Presupuesto Por Centro de Costos" [disabled]="boolcc">
            <div class="p-grid">
                <div class="p-col-2">
                    <span>
                        {{cuentaContableAux}}
                    </span>
                </div>
                <div class="p-col-1">
                </div>
                <div class="p-col-9">
                    <span>
                        {{nombreCuentaAux}}
                    </span>
                </div>
            </div>
            <div class="divg" style="height: 650px">
                <app-aggridsaci [width]="" [height]="200" [enableSorting]="false" [enableFilter]="true"
                    [rowData]="conpresuanucenService.detpresuanupuestocenarray"
                    [frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
                    [columnDefs]="columnDefpresuAnuCen" [defaultColDef]="defaultColDefPresuanucen" [rowStyle]="rowStyle"
                    [singleClickEdit]="false" (selected)="selectedPresuAnucen($event)" [botones]="true"
                    [botonNuevo]="true" [botonGuardar]="false" [botonBorrar]="btnGridBorrar" [botonRegresar]="true"
                    [mostrarGuardar]="false" [singleClickEdit]="false" [botonesAdicionales]="false" [btnPresu]="false"
                    (cambios)="cambio($event)" [CC]="true"></app-aggridsaci>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
    <p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
    </p-progressSpinner>

</div>

<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
        [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>


<p-dialog header="Presupuesto" [(visible)]="displayDialogSelectPCC" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '65vw', height: 'auto'} ">
    <p-toast [style]="{marginTop: '41vh'}" position="top-center" key="selectcenpro"></p-toast>
    <div class="p-grid" style="width: 98%">
        <div class="p-col-12">
            <app-invmaebarra (signal)="manejarSenalesPCC($event)" [barraBotonesCRUD]="true" [barraBotonesDesplaz]="true"
                [barraBotones3]="true" [botonVisto]="false" [barraBotonesAsiCon]="true" [barraBotones3Alt]="false"
                [barraBotones5]="true"></app-invmaebarra>
        </div>
    </div>
    <div class="divg" style="height: 450px">
        <app-aggridsaci [width]="" [height]="500" [enableSorting]="false" [enableFilter]="true"
            [rowData]="conpresuanucenService.detpresuanupuestocenproarray" [frameworkComponents]="frameworkComponents"
            [animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefpresuAnuCenPro"
            [defaultColDef]="defaultColDefPresuanucenpro" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false"
            (selected)="selectedPresuAnuCenPro($event)" (cambios)="cambio1($event)">
        </app-aggridsaci>
    </div>
</p-dialog>