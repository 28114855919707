<div style="height: 280px">
	<button style="background-image: url(../../../assets/images/iconos/filter.png);"
			(click)="busquedaIndi(busquedaParams, tipoBusqueda)"></button>
	<button style="background-image: url(../../../assets/images/iconos/flash.png);"
			(click)="recargar()"></button>
	<button style="background-image: url(../../../assets/images/iconos/011-checklist.png); margin-left: 100px"
			(click)="abrirpestanamaestro()"></button>
	<p-table selectionMode="single" editMode="row"
			 [globalFilterFields]="[tipoSeleccionado]"
			 [value]="arregloConsulta" [scrollable]="true" scrollHeight="200px">
		<ng-template pTemplate="colgroup" let-columns>
			<colgroup>
				<col *ngFor="let head of arrayOptions" id="{{head.label}}" style="width: 200px">
			</colgroup>
		</ng-template>
		<ng-template pTemplate="header">
			<tr class="ui-table-scrollable-wrapper">
				<th *ngFor="let head of arrayOptions" style="width: 200px">{{head.label}}</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body"
					 let-rowData>
			<tr *ngIf="!spin" [pSelectableRow]="rowData" (dblclick)="selectedOption(rowData)"
				(keydown.enter)="selectedOption(rowData)" id="tr">
				<td *ngFor="let data of arrayOptions">{{rowData[data.value]}}</td>
			</tr>
		</ng-template>
	</p-table>
</div>
<div>
	<div class="p-grid">
		<div class="p-col-8 text-left">
			<table>
				<tr *ngFor="let data of arrayOptions; let indi = index" style="height: 30px">
					<td>{{data.label}}</td>
					<td style="width: 35px"></td>
					<!-- ///////////////// aumnetos GSRF -->
					<td><input *ngIf="busquedaParams !== undefined && data.value !== 'ENCFAC_FECHAEMISION' 
						&& data.value !== 'ENCPED_FECHAEMISION'
						&& data.value !== 'ENCGRE_FECHAEMISION' && data.value !== 'ENCDVF_FECHAEMISION'
						&& data.value !== 'ENCFACPRO_FECHAEMISION' && data.value !== 'TRNCOBRO_FECHATRN'
						&& data.value !== 'ASI_FECHA' && data.value !== 'DETCASHMNG_FECHA'
						&& data.value !== 'KAR_FECHA' && data.value !== 'ENCORDCOM_FECHAEMISION'
						&& data.value !== 'ENCNOTREC_FECHAEMISION' && data.value !== 'ENCCUADRECAJA_FECHA'
						&& data.value !== 'ENCCUADRECAJA_DESDE' && data.value !== 'ENCCUADRECAJA_HASTA'
						&& data.value !== 'ENCCUOTA_FECHAINICIO' && data.value !== 'TRNART_FECHAES'
						&& data.value !== 'FECHA' && data.value !== 'ENCORDPRO_FECHAEMISION'
						&& data.value !== 'ENCORDPRO_FECHAINICIO' 
						&& data.value !== 'PERIODO_FECINI'
						&& data.value !== 'PERIODO_FECFIN'
						&& data.value !== 'ENCPED_FECHAENTREGA'
						&& data.value !== 'ENCDEV_FECHAEMISION'
						&& data.value !== 'TRNTOMAFISICA_FECHA'
						&& data.value !== 'TRANSFER_FECHAEMISION'
						&& data.value !== 'TRANSFER_FECHATRANSFER'
						&& data.value !== 'TRANSFER_FECHAAUTORIZA'
						&& data.value !== 'TRNTARJETA_FECHATRN'
						&& data.value !== 'TRNBAN_FECHA'
						&& data.value !== 'EMP_FECINGRESO'
						&& data.value !== 'LOTE_FECHAVENCIMIENTO'
						&& data.value !== 'BANINV_FECHAEMISION'
						&& data.value !== 'BANINV_FECHAVENCIMIENTO'"

						 type="text" pInputText size="50"
							   [(ngModel)]="busquedaParams[indi]"
							   (keydown.enter)="busquedaIndi(busquedaParams, tipoBusqueda)"
							   style="width: auto">
						<input *ngIf=" data.value === 'ENCFAC_FECHAEMISION' || data.value === 'ENCPED_FECHAEMISION'
							|| data.value === 'ENCGRE_FECHAEMISION' || data.value === 'ENCDVF_FECHAEMISION'
							|| data.value === 'ENCFACPRO_FECHAEMISION' || data.value === 'TRNCOBRO_FECHATRN'
							|| data.value === 'ASI_FECHA' || data.value === 'DETCASHMNG_FECHA'
							|| data.value === 'KAR_FECHA' || data.value === 'ENCORDCOM_FECHAEMISION'
							|| data.value === 'ENCNOTREC_FECHAEMISION' || data.value === 'ENCCUADRECAJA_FECHA'
							|| data.value === 'ENCCUADRECAJA_DESDE' || data.value === 'ENCCUADRECAJA_HASTA'
							|| data.value === 'ENCCUOTA_FECHAINICIO' || data.value === 'TRNART_FECHAES'
							|| data.value === 'FECHA' || data.value === 'ENCORDPRO_FECHAEMISION'
							|| data.value === 'ENCORDPRO_FECHAINICIO' 
							|| data.value === 'PERIODO_FECINI'
							|| data.value === 'PERIODO_FECFIN'
							|| data.value === 'ENCPED_FECHAENTREGA'
							|| data.value === 'ENCDEV_FECHAEMISION'
							|| data.value === 'TRNTOMAFISICA_FECHA'
							|| data.value === 'TRANSFER_FECHAEMISION'
							|| data.value === 'TRANSFER_FECHATRANSFER'
							|| data.value === 'TRANSFER_FECHAAUTORIZA'
							|| data.value === 'TRNTARJETA_FECHATRN'
							|| data.value === 'TRNBAN_FECHA'
							|| data.value === 'EMP_FECINGRESO'
							|| data.value === 'LOTE_FECHAVENCIMIENTO'
							|| data.value === 'BANINV_FECHAEMISION'
							|| data.value === 'BANINV_FECHAVENCIMIENTO'" 
							 type="date"  size="50"
							   [(ngModel)]="busquedaParams[indi]"
							   (keydown.enter)="busquedaIndi(busquedaParams, tipoBusqueda)"
							   ></td>
				</tr>
			</table>
		</div>
		<div class="p-col-4 text-left">
			<div class="p-grid">
				<div class="p-col-12">
					<p-radioButton name="grupo1" label="Coincidencias" value="contains"
								   [(ngModel)]="tipoBusqueda"></p-radioButton>
				</div>
				<div class="p-col-12">
					<p-radioButton name="grupo1" label="Palabras Completas" value="equals"
								   [(ngModel)]="tipoBusqueda"></p-radioButton>
				</div>
				<!-- CDPJ -->
				<div class="p-col-12" *ngIf="DVBUSGR === 1">
					<p-radioButton name="grupo2" label="Guías de remisión" value="gr"
								   [(ngModel)]="guiaservicio.tipofiltrobus"></p-radioButton>
				</div>
				<div class="p-col-12" *ngIf="DVBUSGR === 1">
					<p-radioButton name="grupo2" label="Notas de entrega" value="ne"
								   [(ngModel)]="guiaservicio.tipofiltrobus"></p-radioButton>
				</div>
				<!-- CDPJ -->
				<div class="p-col-12">
					<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}"
									   animationDuration=".5s"></p-progressSpinner>
				</div>
			</div>
		</div>
	</div>
</div>
