
import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from '../../usuario';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { Cxctmptrnresumen } from '../../ven/veninterfaces/cxctmptrnresumen';
import { Cxctrnresumen } from '../../ven/veninterfaces/cxctrnresumen';
import { Comencdevfac } from '../../com/cominterfaces/comencdevfac';
import { Cxctrndebitobancario } from '../cxcinterfaces/cxctrndebitobancario';
import { Cxctrnretencion } from '../cxcinterfaces/cxctrnretencion';
import { Cxctrnotrosg } from '../cxcinterfaces/cxctrnotrosg';
import { Cxctrnformapago } from '../cxcinterfaces/cxctrnformapago';
import { Cxctrnanexos } from '../cxcinterfaces/cxctrnanexos';
import { Cxctrnvarios } from '../cxcinterfaces/cxctrnvarios';
import { Cxctrncobro } from '../cxcinterfaces/cxctrncobro';
import { SegMaeCompania } from '../../ven/veninterfaces/segmaecompania';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { VenmaeclienteService } from '../../ven/venservicios/venmaecliente.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { CxccarteraactivaService } from '../cxcservicios/cxccarteraactiva.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { CxctrnretencionesService} from '../cxcservicios/cxctrnretenciones.service'


// Ag-grid
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Cxctrnanticipos } from '../cxcinterfaces/cxctrnanticipos';
import { CxcTrncobro } from 'src/app/interfaces/cxctrncobro';
import {promisify}from 'util';
@Component({
  selector: 'app-cxctrnretenciones',
  templateUrl: './cxctrnretenciones.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class CxctrnretencionesComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  hmpConfiguracion: any[];
  indicador: any;

  // dropdowns
  opcionIva: SelectItem[];
  opcionIvaDesc: SelectItem[];
  opcionesBodega: SelectItem[];
  opcionesRegimen: SelectItem[];
  opcionesTipoDoc: SelectItem[];
  opcionesExportacion: SelectItem[];
  opcionesDistrito: SelectItem[];
  opcionesRegRefrendo: SelectItem[];
  opcionesIngresoExterior: SelectItem[];
  opcionesTipoIngresoExterior: SelectItem[];
  opcionesprecio: SelectItem[];

  // dialogs
  displayDialogDetFac: boolean;
  displayDialogPedidos: boolean;
  displayDialogAsiento: boolean;
  displayAsiContable: boolean;
  displayDialogSeriales: boolean;
  displayDialogDescuento: boolean;
  displayDialogBusquedaFast: boolean;
  displayDialogBus: boolean;
  displayDialogPago: boolean;
  dialogFacCanceladas: boolean;
  displayAcciones: boolean;
  displayLog: boolean;
  displayDialogReportes: boolean;
  claveDialog: boolean;
  spin = false;

  // barra
  btnGuardar: boolean;
  btnRegresar: boolean;
  btnBorrar: boolean;
  botonAnular: boolean;
  botonXML: boolean;
  botonReenviarCorreo: boolean;
  botonAsiento: boolean;
  botonPago: boolean;
  botonBuscar: boolean;
  botonVerAsiento: boolean;
  botonAnterior: boolean;
  botonPrimero: boolean;
  botonUltimo: boolean;
  botonSiguiente: boolean;
  barraBotones2: boolean;
  btnNuevo: boolean;

  // interfaces
  trnresumenSeleccionado: Cxctrnresumen;
  facturaPend: Cxctrnresumen;
  detalleRetencionSeleccionado: Cxctrnretencion;
  detalleVariosSeleccionado: Cxctrnvarios;
  detalleVariosSeleccionadoAux: Cxctrnvarios;
  detalleOtrasFSeleccionada: Cxctrnotrosg;
  detalleFormaPago: Cxctrnformapago;
  detalleFormaPagoDev: Cxctrnformapago;
  detalleRetencionSeleccionadoAux: Cxctrnretencion;
  detalleOtrasFSeleccionadaAux: Cxctrnotrosg;
  detalleFormaPagoAux: Cxctrnformapago;
  detalleFormaPagoDevAux: Cxctrnformapago;
  cxctmptrnresumen: Cxctmptrnresumen[];
  cxctrnresumen: Cxctrnresumen[];
  encabezadosCartera: Comencdevfac[];
  anexoSeleccionado: Cxctrnanexos;
  permisosCartera: SegMaePermiso;
  nuevoitem:Cxctrnretencion;//CDPJ

  auxreporte: number = 0;
  strNombreForma: string;

  auxiliar: number;
  auxContEC: number;
  debeTotal: number;
  haberTotal: number;
  indiceTablas: number;
  totalimporte: number;
  totalpagado: number;
  totalsaldo: number;
  totalApagar: number;
  valorDscValor: number;
  valorDscPorcentaje: number;
  numdecsist: number;
  dscClienteActual: number;
  numeroActual: number;
  indice: number;
  i: number;
  activeIndex: number;
  indiceCliente: number;
  indiceDocRef: number;
  listaSaldos: any;
  itemTipoDoc: any;
  listaClientes: any[];
  listaReferencias: any[];
  numeracionActual: any;
  fechaEmision: any;
  fechaInicio: any;
  fechaFin: any;
  horaSistema: any;
  horaInicio: any;
  horaFin: any;
  listaprecios: any[];
  tabDocumentos: boolean;
  booleanAnulado: boolean;
  tabVarios: boolean;
  tabRetenciones: boolean;
  tabOtrosGastos: boolean;
  booleanNumero: boolean;
  tabFormaPago: boolean;
  tabFormaPagoDev: boolean;
  tabEstadoCuentaAnt: boolean;
  tabDebitoBancario: boolean;
  valorInvalidoV: boolean;
  saldoInicial: boolean;
  bolsaldoIni: boolean;
  valorInvalidoP: boolean;
  numeracionDialog: boolean;
  editarCeldas: boolean;
  booleanValor: boolean;
  booleanDatosPantalla: boolean;
  booleanPorcentaje: boolean;
  booleanRegimen: boolean;
  booleanParaiso: boolean;
  booleanRegimenP: boolean;
  booleanRefrendo: boolean;
  booleanImpuestoRenta: boolean;
  booleanBodega: boolean;
  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;
  btnVerificarExis: boolean;
  ventasboolean: boolean;
  comprasboolean: boolean;
  consigboolean: boolean;
  trasladoboolean: boolean;
  devboolean: boolean;
  impboolean: boolean;
  expboolean: boolean;
  otrosboolean: boolean;
  transboolean: boolean;
  cotizacionBoolean: boolean;
  porcIvaBoolean: boolean;
  retencionElect: boolean;
  bolnc: boolean;
  choosedColor: string;
  claveExportNuevo: string;
  tipodetalleactivo: string;
  botonFacNR: string;
  impresoString: string;
  grupo1: string;
  numSerie: string;
  nombrePaisSeleccionado: string;
  codigoPaisRegimen: string;
  codigoPaisParaiso: string;
  dscSeleccionado: string;
  asiento: string;
  numeroFactura: string;
  claveAuth: string;
  saldoCliente: string;
  nombreProveedor: string;
  logResultado: string;
  accionClave: string;
  cobroCodigo: string;
  opcion: string;
  numDocRef: string;
  imagenProducto: string;
  estadoRef: string;
  largoestcta: string;
  choosedColorG: string;
  choosedColorSB: string;
  nombrebodega: string;
  codClienteFac: string;
  nombreClienteFac: string;
  nroFacCancelada: string;
  lblAplicaRegMicroempresas: string;
  tipodetalle = '';
  boltrnaextipcom: boolean;

  // busqueda
  opcionbusqueda: string;
  types: SelectItem[];
  arregloCons: any[];
  arregloBus: any[];
  busquedacampos: string[];
  tabla: string;
  where: string;
  consulta: string;
  busquedaCompuesta: boolean;
  bolestcta: boolean;
  bolsaldo: boolean;

  // aggrid
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  rowStyleEC;
  rowStyleSaldo;
  rowStyleECAnc;
  rowStyleFp;
  rowStyleFpdev;
  defaultColumnDefCarteraDoc;
  defaultColumnDefCarteraRet;
  defaultColumnDefCarteraOtrosG;
  defaultColumnDefCarteraFormaPago;
  defaultColumnDefCarteraFormaPagoDev;
  defaultColumnDefCarteraVarios;
  defaultColumnDefCarteraDebito;
  defaultColumnDefCarteraAnticipo;
  defaultColDefCxctmptrnresumen;
  defaultColDefCxctrnresumen;
  defaultColumnDefFacCanceladas;
  comboOpciones: any[];
  largo: string;
  largoRef: string;

  ecanticipo: any[];
  lblDocCierre = false;

  bolNuevo = false;
  listaDoc: CxcTrncobro[] = [];
  datosfe: SegMaeCompania = {};

  btneditConcil = true;
  editConcil = true;
  displayDialogXml: boolean;//CDPJ
  claveacceso:string//CDPJ
   data={
    autorizacion: {
      estado: 'AUTORIZADO',
      numeroAutorizacion: '0102202307179232990600120010020000057688365479911',
      fechaAutorizacion: '2023-02-03T16:12:33.000Z',
      ambiente: 'PRODUCCIÓN',
      comprobante: '<?xml version="1.0" encoding="UTF-8"?>\n' +
        '<comprobanteRetencion id="comprobante" version="2.0.0">\n' +
        '<infoTributaria>\n' +
        '<ambiente>2</ambiente>\n' +
        '<tipoEmision>1</tipoEmision>\n' +
        '<razonSocial>ASISTENCIA Y PREVISION ASSISPREV S.A.</razonSocial>\n' +
        '<nombreComercial>ASSIS PREV SA</nombreComercial>\n' +
        '<ruc>1792329906001</ruc>\n' +
        '<claveAcceso>0102202307179232990600120010020000057688365479911</claveAcceso>\n' +
        '<codDoc>07</codDoc>\n' +
        '<estab>001</estab>\n' +
        '<ptoEmi>002</ptoEmi>\n' +
        '<secuencial>000005768</secuencial>\n' +
        '<dirMatriz>Av. de los Shyris N33-134 y República del Salvador</dirMatriz>\n' +
        '<agenteRetencion>1</agenteRetencion>\n' +
        '</infoTributaria>\n' +
        '<infoCompRetencion>\n' +
        '<fechaEmision>01/02/2023</fechaEmision>\n' +
        '<contribuyenteEspecial>334</contribuyenteEspecial>\n' +
        '<obligadoContabilidad>SI</obligadoContabilidad>\n' +
        '<tipoIdentificacionSujetoRetenido>04</tipoIdentificacionSujetoRetenido>\n' +
        '<parteRel>NO</parteRel>\n' +
        '<razonSocialSujetoRetenido>MIRIAN ANTONIETA JACHO JAMI</razonSocialSujetoRetenido>\n' +
        '<identificacionSujetoRetenido>1712117975001</identificacionSujetoRetenido>\n' +
        '<periodoFiscal>02/2023</periodoFiscal>\n' +
        '</infoCompRetencion>\n' +
        '<docsSustento>\n' +
        '<docSustento>\n' +
        '<codSustento>01</codSustento>\n' +
        '<codDocSustento>01</codDocSustento>\n' +
        '<numDocSustento>001002000005846</numDocSustento>\n' +
        '<fechaEmisionDocSustento>01/02/2023</fechaEmisionDocSustento>\n' +
        '<pagoLocExt>01</pagoLocExt>\n' +
        '<totalSinImpuestos>150.00</totalSinImpuestos>\n' +
        '<importeTotal>168.00</importeTotal>\n' +
        '<impuestosDocSustento>\n' +
        '<impuestoDocSustento>\n' +
        '<codImpuestoDocSustento>2</codImpuestoDocSustento>\n' +
        '<codigoPorcentaje>2</codigoPorcentaje>\n' +
        '<baseImponible>150.00</baseImponible>\n' +
        '<tarifa>12</tarifa>\n' +
        '<valorImpuesto>18.00</valorImpuesto>\n' +
        '</impuestoDocSustento>\n' +
        '</impuestosDocSustento>\n' +
        '<retenciones>\n' +
        '<retencion>\n' +
        '<codigo>1</codigo>\n' +
        '<codigoRetencion>343</codigoRetencion>\n' +
        '<baseImponible>150.00</baseImponible>\n' +
        '<porcentajeRetener>1.00</porcentajeRetener>\n' +
        '<valorRetenido>1.50</valorRetenido>\n' +
        '</retencion>\n' +
        '<retencion>\n' +
        '<codigo>2</codigo>\n' +
        '<codigoRetencion>2</codigoRetencion>\n' +
        '<baseImponible>18.00</baseImponible>\n' +
        '<porcentajeRetener>70.00</porcentajeRetener>\n' +
        '<valorRetenido>12.60</valorRetenido>\n' +
        '</retencion>\n' +
        '</retenciones>\n' +
        '<pagos>\n' +
        '<pago>\n' +
        '<formaPago>20</formaPago>\n' +
        '<total>153.90</total>\n' +
        '</pago>\n' +
        '</pagos>\n' +
        '</docSustento>\n' +
        '</docsSustento>\n' +
        '<infoAdicional>\n' +
        '<campoAdicional nombre="correo">sacianex5@gmail.com; facturacion@assisprev.com.ec</campoAdicional>\n' +
        '<campoAdicional nombre="descarga">www.sacianex.com</campoAdicional>\n' +
        '<campoAdicional nombre="elaborado_por">SaciJava ERP</campoAdicional>\n' +
        '</infoAdicional>\n' +
        '<ds:Signature xmlns:ds="http://www.w3.org/2000/09/xmldsig#" xmlns:etsi="http://uri.etsi.org/01903/v1.3.2#" Id="Signature305220">\n' +
        '<ds:SignedInfo Id="Signature-SignedInfo684919">\n' +
        '<ds:CanonicalizationMethod Algorithm="http://www.w3.org/TR/2001/REC-xml-c14n-20010315"></ds:CanonicalizationMethod>\n' +
        '<ds:SignatureMethod Algorithm="http://www.w3.org/2000/09/xmldsig#rsa-sha1"></ds:SignatureMethod>\n' +
        '<ds:Reference Id="SignedPropertiesID406302" Type="http://uri.etsi.org/01903#SignedProperties" URI="#Signature305220-SignedProperties5536">\n' +
        '<ds:DigestMethod Algorithm="http://www.w3.org/2000/09/xmldsig#sha1"></ds:DigestMethod>\n' +
        '<ds:DigestValue>27HnZe4TuO93pG7ALG9A1rKbOQo=</ds:DigestValue>\n' +
        '</ds:Reference>\n' +
        '<ds:Reference URI="#Certificate1234635">\n' +
        '<ds:DigestMethod Algorithm="http://www.w3.org/2000/09/xmldsig#sha1"></ds:DigestMethod>\n' +
        '<ds:DigestValue>8874LLpnMOviJQDEfKFQWI05krs=</ds:DigestValue>\n' +
        '</ds:Reference>\n' +
        '<ds:Reference Id="Reference-ID-1031421" URI="#comprobante">\n' +
        '<ds:Transforms>\n' +
        '<ds:Transform Algorithm="http://www.w3.org/2000/09/xmldsig#enveloped-signature"></ds:Transform>\n' +
        '</ds:Transforms>\n' +
        '<ds:DigestMethod Algorithm="http://www.w3.org/2000/09/xmldsig#sha1"></ds:DigestMethod>\n' +
        '<ds:DigestValue>GU8oeTg8LaYANSrc1l76hnR8K/k=</ds:DigestValue>\n' +
        '</ds:Reference>\n' +
        '</ds:SignedInfo>\n' +
        '<ds:SignatureValue Id="SignatureValue204123">\n' +
        'Zv6v1iOxas0/ra6dL/HxeqMo4y8qsCET/yhr3kkNobCes7IIffo2jOveycMLZvy3cPXEimM8iSfN\n' +
        'PQVy3cn5sXV44yRVhrVhN1PtuBibqFuu4X7A6BHKVzXrQFhDQ8t9+5555A27Bm6NyMtxRxO/6RH5\n' +
        'ixNYyn6puz3AiRB1Rpo/PGt7t61rg3ahUqIaNZ2KWWsoUiYmRw667AfNvqOqNWPEOY5E325RypZx\n' +
        'OuYGZkdiFO2D+ZrbjyPSLE31Zok8dmGxVobbfxjdbGKe8014OeqqJb6DnO+n6EQIyNZPIE4Ibjoz\n' +
        'XPsNwv95hh2InLyeBb8OeclZSAGUsOEd2MR6NA==\n' +
        '</ds:SignatureValue>\n' +
        '<ds:KeyInfo Id="Certificate1234635">\n' +
        '<ds:X509Data>\n' +
        '<ds:X509Certificate>\n' +
        'MIIMHzCCCgegAwIBAgIED9KP5DANBgkqhkiG9w0BAQsFADCBmTELMAkGA1UEBhMCRUMxHTAbBgNV\n' +
        'BAoMFFNFQ1VSSVRZIERBVEEgUy5BLiAyMTAwLgYDVQQLDCdFTlRJREFEIERFIENFUlRJRklDQUNJ\n' +
        'T04gREUgSU5GT1JNQUNJT04xOTA3BgNVBAMMMEFVVE9SSURBRCBERSBDRVJUSUZJQ0FDSU9OIFNV\n' +
        'QkNBLTIgU0VDVVJJVFkgREFUQTAeFw0yMzAxMzExNTM3MDdaFw0yNjAxMzAxNTM3MDdaMIGaMSMw\n' +
        'IQYDVQQDDBpLQVJJTkEgSk9TRVRIIEJPUkpBIEZMT1JFUzEVMBMGA1UEBRMMMzEwMTIzMTA0NjQz\n' +
        'MTAwLgYDVQQLDCdFTlRJREFEIERFIENFUlRJRklDQUNJT04gREUgSU5GT1JNQUNJT04xHTAbBgNV\n' +
        'BAoMFFNFQ1VSSVRZIERBVEEgUy5BLiAyMQswCQYDVQQGEwJFQzCCASIwDQYJKoZIhvcNAQEBBQAD\n' +
        'ggEPADCCAQoCggEBAJCuPPl4PXyeV33fjfcCwoX6u+X+t7gECjiQ3J1u1M/m4rE3xnZOwsGn7hfT\n' +
        'tCxAog3pCFhKdqk/ouPREeZdj5srQo8aYJR04ZkFM4WDhVWv/hWf3zvG5U40caPF3oAqSKjpIT74\n' +
        'yPARvsnLZ7zSUV/g0SCsVc8IDstYT+vlu3vHYb++mb66bcpy/ivP4Rf0YyYwBThgng5jf44FES0c\n' +
        'ekSsEesqDOEAv/c6+a1I9dLLx7pg2/ZUx+846NqhySmUp1F0OsIWcwMX4EI3fErzmlaxmBsK+TEH\n' +
        'dmeho5CjnXcJvtujby8W9E7+cFEjiwCUt1Hcq7dcriZ2ZFywPqwSHykCAwEAAaOCB2owggdmMAwG\n' +
        'A1UdEwEB/wQCMAAwHwYDVR0jBBgwFoAUjLrKEVd4JYAdawpLVb+NrmLdvY8wWQYIKwYBBQUHAQEE\n' +
        'TTBLMEkGCCsGAQUFBzABhj1odHRwOi8vb2NzcGd3LnNlY3VyaXR5ZGF0YS5uZXQuZWMvZWpiY2Ev\n' +
        'cHVibGljd2ViL3N0YXR1cy9vY3NwMIHPBgNVHS4EgccwgcQwgcGggb6ggbuGgbhsZGFwOi8vbGRh\n' +
        'cHNkY2EyLnNlY3VyaXR5ZGF0YS5uZXQuZWMvQ049QVVUT1JJREFEIERFIENFUlRJRklDQUNJT04g\n' +
        'U1VCQ0EtMiBTRUNVUklUWSBEQVRBLE9VPUVOVElEQUQgREUgQ0VSVElGSUNBQ0lPTiBERSBJTkZP\n' +
        'Uk1BQ0lPTixPPVNFQ1VSSVRZIERBVEEgUy5BLiAyLEM9RUM/ZGVsdGFSZXZvY2F0aW9uTGlzdD9i\n' +
        'YXNlMCIGA1UdEQQbMBmBF2tib3JqYUBhc3Npc3ByZXYuY29tLmVjMIIBFQYDVR0gBIIBDDCCAQgw\n' +
        'YgYKKwYBBAGCpnICCTBUMFIGCCsGAQUFBwICMEYeRABDAGUAcgB0AGkAZgBpAGMAYQBkAG8AIABk\n' +
        'AGUAIABSAGUAcAByAGUAcwBlAG4AdABhAG4AdABlACAATABlAGcAYQBsMIGhBgorBgEEAYKmcgID\n' +
        'MIGSMIGPBggrBgEFBQcCARaBgmh0dHBzOi8vd3d3LnNlY3VyaXR5ZGF0YS5uZXQuZWMvd3AtY29u\n' +
        'dGVudC9kb3dubG9hZHMvTm9ybWF0aXZhcy9QX2RlX0NlcnRpZmljYWRvcy9Qb2xpdGljYXMgZGUg\n' +
        'Q2VydGlmaWNhZG8gUmVwcmVzZW50YW50ZSBMZWdhbC5wZGYwggKiBgNVHR8EggKZMIIClTCB5aBB\n' +
        'oD+GPWh0dHA6Ly9vY3NwZ3cuc2VjdXJpdHlkYXRhLm5ldC5lYy9lamJjYS9wdWJsaWN3ZWIvc3Rh\n' +
        'dHVzL29jc3CigZ+kgZwwgZkxOTA3BgNVBAMMMEFVVE9SSURBRCBERSBDRVJUSUZJQ0FDSU9OIFNV\n' +
        'QkNBLTIgU0VDVVJJVFkgREFUQTEwMC4GA1UECwwnRU5USURBRCBERSBDRVJUSUZJQ0FDSU9OIERF\n' +
        'IElORk9STUFDSU9OMR0wGwYDVQQKDBRTRUNVUklUWSBEQVRBIFMuQS4gMjELMAkGA1UEBhMCRUMw\n' +
        'gceggcSggcGGgb5sZGFwOi8vbGRhcHNkY2EyLnNlY3VyaXR5ZGF0YS5uZXQuZWMvQ049QVVUT1JJ\n' +
        'REFEIERFIENFUlRJRklDQUNJT04gU1VCQ0EtMiBTRUNVUklUWSBEQVRBLE9VPUVOVElEQUQgREUg\n' +
        'Q0VSVElGSUNBQ0lPTiBERSBJTkZPUk1BQ0lPTixPPVNFQ1VSSVRZIERBVEEgUy5BLiAyLEM9RUM/\n' +
        'Y2VydGlmaWNhdGVSZXZvY2F0aW9uTGlzdD9iYXNlMIHgoIHdoIHahoHXaHR0cHM6Ly9wb3J0YWwt\n' +
        'b3BlcmFkb3IyLnNlY3VyaXR5ZGF0YS5uZXQuZWMvZWpiY2EvcHVibGljd2ViL3dlYmRpc3QvY2Vy\n' +
        'dGRpc3Q/Y21kPWNybCZpc3N1ZXI9Q049QVVUT1JJREFEIERFIENFUlRJRklDQUNJT04gU1VCQ0Et\n' +
        'MiBTRUNVUklUWSBEQVRBLE9VPUVOVElEQUQgREUgQ0VSVElGSUNBQ0lPTiBERSBJTkZPUk1BQ0lP\n' +
        'TixPPVNFQ1VSSVRZIERBVEEgUy5BLiAyLEM9RUMwHQYDVR0OBBYEFLhRahGGwO+yF6hbSLRpbSEH\n' +
        'MJuzMCsGA1UdEAQkMCKADzIwMjMwMTMxMTUzNzA3WoEPMjAyNjAxMzAxNTM3MDdaMAsGA1UdDwQE\n' +
        'AwIF4DAfBgorBgEEAYKmcgMFBBEMD0dFUkVOVEUgR0VORVJBTDAaBgorBgEEAYKmcgMBBAwMCjE3\n' +
        'MTQ2ODU4NjIwFQYKKwYBBAGCpnIDCQQHDAVRVUlUTzARBgorBgEEAYKmcgMiBAMMAS4wQQYKKwYB\n' +
        'BAGCpnIDBwQzDDFBVi4gREUgTE9TIFNIWVJJUyBOMzMxMzQgWSBSRVBVQkxJQ0EgREVMIFNBTFZB\n' +
        'RE9SMB0GCisGAQQBgqZyAwIEDwwNS0FSSU5BIEpPU0VUSDAfBgorBgEEAYKmcgMgBBEMDzAwMjAw\n' +
        'MTAwMDU1Njg5NjATBgorBgEEAYKmcgMhBAUMA1BGWDAXBgorBgEEAYKmcgMMBAkMB0VDVUFET1Iw\n' +
        'FQYKKwYBBAGCpnIDAwQHDAVCT1JKQTA0BgorBgEEAYKmcgMKBCYMJEFTSVNURU5DSUEgWSBQUkVW\n' +
        'SVNJT04gQVNTSVNQUkVWIFMuQTAdBgorBgEEAYKmcgMLBA8MDTE3OTIzMjk5MDYwMDEwEgYKKwYB\n' +
        'BAGCpnIDHQQEDAJObzAWBgorBgEEAYKmcgMEBAgMBkZMT1JFUzAcBgorBgEEAYKmcgMIBA4MDDU5\n' +
        'Mzk5NzM5OTc4NTANBgkqhkiG9w0BAQsFAAOCAgEAheKO7XQ1G9f5kQhDvqDjEtMpt5kwtYOVztcB\n' +
        'NR+hCskKyBVagWee0NSb/1zkRFacbePDSdGNjsGrzXRlu91j6WKB7OLSXmTWcx+EGQN1fg8qD3nY\n' +
        '+JCStDSj7bc8PTkNqBImlVYv2Nz+C9dtxrgUK2tpsNzFPrvihL6F1j3rrVoGOvIFL9LLBox2P6eT\n' +
        'pGaKII/vKN3W1sHuGyT6EG5VwFjAsVGkXk4+2UWXWlYcVEuw7EIq4qYiWcDEc+htkEmYOYOsOBgW\n' +
        'j/HCqcJyENboFtf7RVrzOcXrtLuwFAJZtAx614OLQugesiiQvi/TYCPqVWCmfChfgKvyjHBSyOEx\n' +
        '5ALw0tfGaTiEbux+rnSNaluYEOL0rO5J10GZEdRCFeon56whwzvStxU1gW8TWRARL4wE5Xec4HGm\n' +
        'tTwS3Vg6kXyYUkBDtM237ZPuPJQ7BjT/KEquY0rkMypfP43aMsD2m217karQIQL5gV6VermJpyTe\n' +
        '/D3Or2BvuwIZDK3pNl07zEW4OA6III7q2vwDIcemKKUePRShNzhXgGuS307AvGbb3TTJqqfWnY+N\n' +
        'Q1OLjkUpDtpuf4d3Sn9q0ARBA9mfm/JZS6ivjcJVsAbLZ7PvS/KinDKOmedGDIDbAus35LqCt9Wd\n' +
        't9qlXxhXyo+W6EYE8SctmvFN+iUPqZfQ3zSHzNI=\n' +
        '</ds:X509Certificate>\n' +
        '</ds:X509Data>\n' +
        '<ds:KeyValue>\n' +
        '<ds:RSAKeyValue>\n' +
        '<ds:Modulus>\n' +
        'kK48+Xg9fJ5Xfd+N9wLChfq75f63uAQKOJDcnW7Uz+bisTfGdk7CwafuF9O0LECiDekIWEp2qT+i\n' +
        '49ER5l2PmytCjxpglHThmQUzhYOFVa/+FZ/fO8blTjRxo8XegCpIqOkhPvjI8BG+yctnvNJRX+DR\n' +
        'IKxVzwgOy1hP6+W7e8dhv76ZvrptynL+K8/hF/RjJjAFOGCeDmN/jgURLRx6RKwR6yoM4QC/9zr5\n' +
        'rUj10svHumDb9lTH7zjo2qHJKZSnUXQ6whZzAxfgQjd8SvOaVrGYGwr5MQd2Z6GjkKOddwm+26Nv\n' +
        'Lxb0Tv5wUSOLAJS3Udyrt1yuJnZkXLA+rBIfKQ==\n' +
        '</ds:Modulus>\n' +
        '<ds:Exponent>AQAB</ds:Exponent>\n' +
        '</ds:RSAKeyValue>\n' +
        '</ds:KeyValue>\n' +
        '</ds:KeyInfo>\n' +
        '<ds:Object Id="Signature305220-Object108668"><etsi:QualifyingProperties Target="#Signature305220"><etsi:SignedProperties Id="Signature305220-SignedProperties5536"><etsi:SignedSignatureProperties><etsi:SigningTime>2023-02-03T11:12:32-05:00</etsi:SigningTime><etsi:SigningCertificate><etsi:Cert><etsi:CertDigest><ds:DigestMethod Algorithm="http://www.w3.org/2000/09/xmldsig#sha1"></ds:DigestMethod><ds:DigestValue>7tHtkXrU07FY1FZDl3/zgNef/EQ=</ds:DigestValue></etsi:CertDigest><etsi:IssuerSerial><ds:X509IssuerName>CN=AUTORIDAD DE CERTIFICACION SUBCA-2 SECURITY DATA,OU=ENTIDAD DE CERTIFICACION DE INFORMACION,O=SECURITY DATA S.A. 2,C=EC</ds:X509IssuerName><ds:X509SerialNumber>265457636</ds:X509SerialNumber></etsi:IssuerSerial></etsi:Cert></etsi:SigningCertificate></etsi:SignedSignatureProperties><etsi:SignedDataObjectProperties><etsi:DataObjectFormat ObjectReference="#Reference-ID-1031421"><etsi:Description>Documento de ejemplo</etsi:Description><etsi:MimeType>text/xml</etsi:MimeType></etsi:DataObjectFormat></etsi:SignedDataObjectPr',
      mensajes: null
    }
  }
  ////GSRF
  editpagofr = true;


  columnDefsCxctmptrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC', width: 100,
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Debe', field: 'DEBE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Haber', field: 'HABER', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];
  columnDefsCxctrnresumen = [
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', width: 200
    },
    {
      headerName: 'Tipo', field: 'TRNCOBRO_TIPODOC'
    },
    {
      headerName: 'Número', field: 'TRNCOBRO_NROCOMPROBANTE', width: 200
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHAVENCE', width: 200
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', width: 200
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Pagado', field: 'PAGADO', cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Saldo', field: 'SALDO', cellStyle: { textAlign: 'right' },
    },
  ];
  columDefCarteraDoc = [
    {
      headerName: 'S/N',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: (params) => {
        return false;
      },
      width: 50,
      onCellClicked: (params) => {
        this.docSeleccionado(params);
      }
    },
    {
      headerName: 'Nro. Documento', field: 'TRNCOBRO_NRODOC', cellEditor: 'cellPrueba', width: 180, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 50,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32
        }
      }
    },
    {
      headerName: 'Fecha Vec.', field: 'TRNCOBRO_FECHAVENCE', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 32,
        }
      }
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200,
        }
      }
    },
    {
      headerName: 'Saldo', field: 'TRNCOBRO_SALDOINI', cellEditor: 'agSelectCellEditor', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Importe', field: 'TRNCOBRO_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 0,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Vendedor', field: 'VEN_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEditFP(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 5,
          tienecalculos: true,
          int: true
        }
      }
    }];
  columDefCarteraRetencion = [
    {
      headerName: 'Código', field: 'RETENCION_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 25,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'Descripción', field: 'TRNRETENCION_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 200
        }
      }
    },
    {
      headerName: 'Número', field: 'TRNRETENCION_NRO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 15,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Base', field: 'TRNRETENCION_BASE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: '%', field: 'TRNRETENCION_PORCENTAJE', cellEditor: 'agSelectCellEditor', width: 80, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'T.Retenido', field: 'TRNRETENCION_TOTALRETENIDO', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    }];
  // columDefCarteraOtrosG = [
  //   {
  //     headerName: 'Código', field: 'COMISION_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: true,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: true,
  //         fecha: false,
  //         tamanocelda: 25,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Descripción', field: 'TRNCOMISION_DESCRIPCION', cellEditor: 'cellPrueba', width: 250, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 200
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Número', field: 'TRNCOMISION_NRO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 35,
  //         tienecalculos: true,
  //         int: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Importe', field: 'TRNCOMISION_TOTALRETENIDO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 0,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 32,
  //         tienecalculos: true
  //       }
  //     }
  //   }];
  // columDefCarteraFormaPago = [
  //   {
  //     headerName: 'Tipo', field: 'TRNPAGO_TIPO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: true,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: true,
  //         fecha: false,
  //         tamanocelda: 4,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Código', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: true,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: true,
  //         fecha: false,
  //         tamanocelda: 35,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Número', field: 'TRNPAGO_NUMERO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 45,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Fecha', field: 'TRNPAGO_FECHA', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: true,
  //         tamanocelda: 32,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Ban', field: 'BANCLI_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 35,
  //         tienecalculos: true,
  //         int: true,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Nombre', field: 'BANCLI_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 100,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Número Cuenta', field: 'TRNPAGO_NROCTA', cellEditor: 'cellPrueba', width: 100,
  //     editable: p => {
  //       return p.data.TRNPAGO_TIPO === 'TA' || p.data.TRNPAGO_TIPO === 'CH';
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 25,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Concepto', field: 'TRNPAGO_CONCEPTO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 1000,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Importe', field: 'TRNPAGO_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 32,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'C.Sri', field: 'TRNPAGO_CODSRI', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 2,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Disp.', field: 'TRNPAGO_TIPDIS', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 0,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 4,
  //         tienecalculos: true
  //       }
  //     }
  //   }];
  // columDefCarteraVarios = [
  //   {
  //     headerName: 'Ref. Contable', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: true,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: true,
  //         fecha: false,
  //         tamanocelda: 35,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Descripción', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', width: 250, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 100
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Debe', field: 'DEBE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 32,
  //         tienecalculos: true,
  //         int: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Haber', field: 'HABER', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 32,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Tipo', field: 'TRNBAN_TIPO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: false,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 32,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'C.Costo', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
  //     // , editable: true,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: true,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 0,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: true,
  //         fecha: false,
  //         clave: false,
  //         tamanocelda: 32
  //       }
  //     }
  //   }
  // ];
  // columDefCarteraDebito = [
  //   {
  //     headerName: 'S/N',
  //     field: 'SELECCIONADO',
  //     cellRendererFramework: AggridBanComponent,
  //     editable: false,
  //     width: 50,
  //     onCellClicked: () => {
  //       this.debitoSeleccionado();
  //     }
  //   },
  //   {
  //     headerName: 'Fecha', field: 'TRNPAGO_FECHA', cellEditor: 'cellPrueba', width: 100,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: true,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: true,
  //         fecha: false,
  //         tamanocelda: 20,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Tipo', field: 'TRNPAGO_TIPO', cellEditor: 'cellPrueba', width: 80,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: true,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: true,
  //         fecha: false,
  //         tamanocelda: 4,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Número', field: 'TRNPAGO_NUMERO', cellEditor: 'cellPrueba', width: 100,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 45
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Importe', field: 'TRNPAGO_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 32,
  //         tienecalculos: true,
  //         int: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Nro Cta.', field: 'TRNPAGO_NROCTA', cellEditor: 'cellPrueba', width: 200,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 25,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Cod Ban.', field: 'BANCLI_CODIGO', cellEditor: 'cellPrueba', width: 100,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 10,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Nombre Banco', field: 'BANCLI_NOMBRE', cellEditor: 'cellPrueba', width: 200,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 100,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Nro. Doc.', field: 'TRNCOBRO_NRODOC', cellEditor: 'cellPrueba', width: 100,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 50,
  //         tienecalculos: true
  //       }
  //     }
  //   }];
  // columDefCarteraAnticipo = [
  //   {
  //     headerName: 'Nro Documento', field: 'TRNCOBRO_NRODOC', cellEditor: 'cellPrueba', width: 200,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: true,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: true,
  //         fecha: false,
  //         tamanocelda: 35,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Tipo', field: 'TRNPAGO_TIPO', cellEditor: 'cellPrueba', width: 80,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 4
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Fecha', field: 'TRNPAGO_FECHA', cellEditor: 'cellPrueba', width: 100,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 32,
  //         tienecalculos: true,
  //         int: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Concepto', field: 'TRNPAGO_CONCEPTO', cellEditor: 'cellPrueba', width: 250,
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 1000,
  //         tienecalculos: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Importe', field: 'TRNPAGO_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 32,
  //         tienecalculos: true
  //       }
  //     }
  //   }];
  // columDefCarteraFormaPagoDev = [
  //   {
  //     headerName: 'Tipo', field: 'TRNCAJ_TIPO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: true,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: true,
  //         fecha: false,
  //         tamanocelda: 10,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Caja', field: 'CAJ_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.idEditcajcodigo(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: true,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: true,
  //         fecha: false,
  //         tamanocelda: 10,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Banco', field: 'BAN_CODIGO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 10,
  //         opcionbuscar: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Nombre Banco', field: 'BAN_BANCO', cellEditor: 'cellPrueba', width: 80, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 100,
  //         int: true
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Número', field: 'TRNCAJ_NUMERO', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 20,
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Fecha', field: 'TRNCAJ_FECHA', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: true,
  //         tamanocelda: 32,
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Concepto', field: 'TRNCAJ_DESCRIPCION', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 1,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 200,
  //       }
  //     }
  //   },
  //   {
  //     headerName: 'Importe', field: 'TRNCAJ_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
  //       const boolEdit = this.isEdit(params.data);
  //       return boolEdit;
  //     },
  //     cellEditorParams:
  //     {
  //       values: {
  //         mayusculas: false,
  //         numeros: true,
  //         alphabetic: true,
  //         saltoslinea: 0,
  //         newrow: false,
  //         numerodecimales: 0,
  //         obligatorio: false,
  //         fecha: false,
  //         tamanocelda: 32,
  //         int: true,
  //         tienecalculos: true
  //       }
  //     }
  //   }];
  // columDefFacCanceladas = [
  //   {
  //     headerName: 'Nro. Documento', field: 'TRNDOCUMENTO_NRODOC', width: 150, editable: false
  //   },
  //   {
  //     headerName: 'Nro. Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false
  //   },
  //   {
  //     headerName: 'Fecha', field: 'TRNCOBRO_FECHATRN', editable: false
  //   },
  //   {
  //     headerName: 'Importe Cancelado', field: 'TRNDOCUMENTO_IMPORTE', editable: false, cellStyle: { textAlign: 'right' },
  //   },
  //   {
  //     headerName: 'Cod. Cliente', field: 'CLI_CODIGO', editable: false
  //   }];

  constructor(public cxcCarteraService: CxccarteraactivaService, private confirmationService: ConfirmationService,
    private busquedaSer: InvbusquedaService, private datePipe: DatePipe,
    private message: MessageService, private utilitariosService: UtilitariosService, private cliService: VenmaeclienteService,
    private auditoriagral: AuditoriagralService, private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService, private permisosService: PermisosService, public usuario: Usuario,
    private init: NuevoComponentService,public cxctrnretencionesService:CxctrnretencionesService,
    ) {
    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.data.APLORG_CODIGO === 'SISTEMA' &&
        params.data.TRNCOBRO_CONCEPTO.includes('CUOTA')) {
        return { background: 'rgb(255, 255, 170)' };
      }

      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }

      if ((params.data.TRNCOBRO_FECHATRN === '' || params.data.TRNCOBRO_FECHATRN === null) &&
        (this.activeIndex === 1 || this.activeIndex === 2)) {
        return { background: '#AAFF00' };
      }
      /*if (params.data.TRNCOBRO_NRODOC === '' && this.activeIndex === 1) {
        return {background: '#AAFF00'};
      }*/
      /*if (params.data.APLORG_CODIGO === 'SISTEMA') {
        return {background: '#AAFF00'};
      }*/

    };

    this.rowStyleEC = (params) => {
      if (params.data.TRNCOBRO_TIPO !== 'A') {
        if (params.data.TRNCOBRO_FECHATRN === '') {
          return { background: 'rgb( 255, 255, 170)' };
        } else {
          return { background: 'rgb( 196, 225, 255)' };
        }
      }
    };

    this.rowStyleSaldo = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if (params.data.TRNCOBRO_FECHATRN === '') {
        return { background: 'rgb( 255, 255, 170)' };
      }
    };

    this.rowStyleECAnc = (params) => {
      if (params.data.TRNPAGO_FECHA === '') {
        return { background: 'rgb( 255, 255, 170)' };
      } else {
        return { background: 'rgb( 196, 225, 255)' };
      }
    };

    this.rowStyleFp = (params) => {
      if (params.data.APLORG_CODIGO === 'SISTEMA' &&
        params.data.TRNCOBRO_CONCEPTO.includes('CUOTA')) {
        return { background: 'rgb(255, 255, 170)' };
      }
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if ((params.data.TRNCOBRO_FECHATRN === '' || params.data.TRNCOBRO_FECHATRN === null) &&
        (this.activeIndex === 1 || this.activeIndex === 2)) {
        return { background: '#AAFF00' };
      }
      if (params.data.COLORDOCDEP === true) {
        return { background: 'rgb(255, 207, 185)' };
      }
      if (params.data.COLORDOCCONC === true) {
        return { background: 'rgb(135, 234, 130)' };
      }
    };

    this.rowStyleFpdev = (params) => {
      if (params.data.APLORG_CODIGO === 'SISTEMA' &&
        params.data.TRNCOBRO_CONCEPTO.includes('CUOTA')) {
        return { background: 'rgb(255, 255, 170)' };
      }
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
      if ((params.data.TRNCOBRO_FECHATRN === '' || params.data.TRNCOBRO_FECHATRN === null) &&
        (this.activeIndex === 1 || this.activeIndex === 2)) {
        return { background: '#AAFF00' };
      }
      if (params.data.COLORDOCDEP === true) {
        return { background: 'rgb(255, 207, 185)' };
      }
      if (params.data.COLORDOCCONC === true) {
        return { background: 'rgb(135, 234, 130)' };
      }
    };

    this.defaultColumnDefCarteraDoc = {
      editable: true,
      width: 100,
      objeto: 'cxcdocumentos'
    };
    this.defaultColumnDefCarteraRet = {
      editable: true,
      width: 100,
      objeto: 'cxctrnretenciones'
    };
    this.defaultColumnDefCarteraOtrosG = {
      editable: true,
      width: 100,
      objeto: 'cxcotrosgastos'
    };
    this.defaultColumnDefCarteraFormaPago = {
      editable: true,
      width: 100,
      objeto: 'cxcformapago'
    };
    this.defaultColumnDefCarteraFormaPagoDev = {
      editable: true,
      width: 100,
      objeto: 'cxcformapagodev'
    };
    this.defaultColumnDefCarteraVarios = {
      editable: true,
      width: 100,
      objeto: 'cxcvarios'
    };
    this.defaultColumnDefCarteraDebito = {
      editable: true,
      width: 100,
      objeto: 'cxcdepositoban'
    };
    this.defaultColumnDefCarteraAnticipo = {
      editable: true,
      width: 100,
      objeto: 'cxcanticipo'
    };
    this.defaultColDefCxctmptrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctmptrnresumen'
    };

    this.defaultColDefCxctrnresumen = {
      editable: false,
      width: 100,
      objeto: 'cxctrnresumen'
    };
    this.defaultColumnDefFacCanceladas = {
      editable: true,
      width: 100,
      objeto: 'cxcfacCanceladas'
    };
  }

  ngOnInit() {
    this.cxctrnretencionesService.refrescarURL();
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    console.log(this.indicador);
    this.cxctrnretencionesService.dblTotalDocarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalRetarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalOtrosGarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalFormaPagoarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalFormaDevarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalVariosarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalNDarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalNCarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalCobrararray[this.indicador] = 0;

    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] = {};
    this.botonFacNR = '';
    this.encabezadosCartera = [];
    this.detalleRetencionSeleccionado = {};
    this.detalleVariosSeleccionado = {};
    this.detalleRetencionSeleccionadoAux = {};
    this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
    this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = [];
    //this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = [];
    //this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = [];
    //this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = [];
    //this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador] = [];
    //this.cxctrnretencionesService.cxctrnanticiposarray[this.indicador] = [];
    //this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = [];
    this.opcionesprecio = [];
    //this.listaprecios = [];
   // this.opcionesBodega = [];
    this.opcionesRegimen = [];
    this.opcionesExportacion = [];
    this.opcionesDistrito = [];
    this.opcionesTipoDoc = [];
    this.opcionesRegRefrendo = [];
    this.opcionesIngresoExterior = [];
    this.opcionesTipoIngresoExterior = [];
    this.usuario.loadStorage();
    this.anexoSeleccionado = {};
    this.cxctrnresumen = [];
    this.cxctmptrnresumen = [];
    this.listaSaldos = [];
    this.trnresumenSeleccionado = {};

    this.displayAcciones = false;
    this.displayDialogDescuento = false;
    this.booleanValor = true;
    this.booleanPorcentaje = false;
    this.retencionElect = true;
    this.booleanNumero = true;
    this.bolnc = true;
    this.numSerie = '';
    this.auxiliar = 0;
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] = {};
    this.permisosService.getListaPermisos('CXC', '8', '1002').subscribe((permiso) => {
      this.permisosCartera = permiso[0];
    });
    this.auditoriagral.obtenerConfiguracion1().subscribe((conf) => {
      this.hmpConfiguracion = conf;
    });
    this.numDocRef = '';
    this.auxContEC = 0;
    this.debeTotal = 0;
    this.haberTotal = 0;
    this.asiento = '';
    this.consulta = '';
    this.detalleOtrasFSeleccionada = {};
    this.detalleOtrasFSeleccionadaAux = {};
    this.detalleVariosSeleccionadoAux = {};
    this.accionClave = '';
    this.indiceCliente = 0;
    this.indiceDocRef = 0;
    this.codClienteFac = '';
    this.nombreClienteFac = '';
    this.nroFacCancelada = '';
    this.activeIndex = 0;
    this.tabDocumentos = true;
    //this.tabFormaPago = false;
    this.booleanAnulado = false;
    //this.tabOtrosGastos = false;
    this.tabRetenciones = true;
    //this.dialogFacCanceladas = false;
    //this.tabVarios = false;
    //this.tabFormaPagoDev = false;
    this.indiceTablas = 0;
    this.numeroFactura = '';
    this.tabEstadoCuentaAnt = false;
    //this.tabDebitoBancario = false;
    this.booleanDatosPantalla = true;
    this.largoestcta = '420';
    this.nombrePaisSeleccionado = '';
    this.tipodetalleactivo = 'all';
    this.saldoInicial = false;
    this.dscSeleccionado = 'dscPorcentaje';
    this.claveExportNuevo = '';
    this.codigoPaisRegimen = '';
    this.codigoPaisParaiso = '';
    this.where = '';
    this.booleanBodega = false;
    this.auxContEC = 0;
    this.debeTotal = 0;
    this.haberTotal = 0;
    this.totalimporte = 0;
    this.totalpagado = 0;
    this.totalsaldo = 0;
    this.cobroCodigo = '';
    this.impresoString = '';
    this.valorDscValor = 0;
    this.busquedaCompuesta = false;
    this.valorDscPorcentaje = 0;
    this.porcIvaBoolean = true;
    this.booleanRegimen = true;
    this.booleanParaiso = true;
    this.booleanRegimenP = true;
    this.booleanRefrendo = true;
    this.numeracionActual = {};
    this.dscClienteActual = 0;
    this.totalApagar = 0;
    this.displayDialogDetFac = false;
    this.displayDialogPedidos = false;
    this.displayDialogAsiento = false;
    this.displayAsiContable = false;
    this.displayDialogSeriales = false;
    this.editarCeldas = false;
    this.displayDialogPago = false;
    this.claveDialog = false;
    this.displayLog = false;
    this.spin = false;
    this.displayDialogReportes = false;
    this.valorInvalidoP = false;
    this.valorInvalidoV = false;
    this.numeracionDialog = false;
    this.logResultado = '';
    this.largo = '630';
    this.saldoCliente = '';
    this.estadoRef = '';
    this.nombreProveedor = '';
    this.valorDscValor = 0;
    this.valorDscPorcentaje = 0;
    this.choosedColorG = '#804000';
    this.choosedColorSB = '#00FF00';
    this.choosedColor = '#AAFF00';
    this.claveAuth = '';
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.botonAnular = false;
    this.botonXML = true;
    this.botonReenviarCorreo = true;
    this.botonAsiento = false;
    this.botonVerAsiento = false;
    this.botonBuscar = false;
    this.botonPago = false;
    this.cotizacionBoolean = false;
    this.booleanImpuestoRenta = true;
    this.activeIndex = 0;
    this.ventasboolean = false;
    this.imagenProducto = '';
    this.comprasboolean = false;
    this.consigboolean = false;
    this.trasladoboolean = false;
    this.nombrebodega = '';
    this.devboolean = false;
    this.impboolean = false;
    this.expboolean = false;
    this.otrosboolean = false;
    this.transboolean = false;
    this.displayDialogBus = false;
    this.numeroActual = 0;
    this.numdecsist = this.confIniciales.getNumDecSist();
    this.displayDialogBusquedaFast = false;
    this.comboOpciones = [{ label: 'A', value: 'A' },
    { label: 'S', value: 'S' }, { label: 'C', value: 'C' }, { label: '*', value: '*' }];
    this.tipodetalle = 'A';
    this.opcionIva = [{ label: '12', value: 12 }, { label: '14', value: 14 }];
    this.opcionIvaDesc = [{ label: 'Neto + IVA', value: '1' },
    { label: 'Incluido IVA', value: '2' }, { label: 'Exento', value: '0' }];
    //this.cxctrnretencionesService.dblPorcIvaDevF = 12;
    this.fechaEmision = new Date().toLocaleString().substr(0, 10);
    this.fechaInicio = new Date().toLocaleString().substr(0, 10);
    this.fechaFin = new Date().toLocaleString().substr(0, 10);
    this.horaInicio = new Date();
    this.horaFin = new Date();
    this.horaSistema = new Date();
    this.largo = '650';
    this.largoRef = '400';
    this.indice = 0;
    this.i = 0;
    // this.cxctrnretencionesService.llenarTipoDoc().subscribe((tipodoc) => {
    //   tipodoc.map((doc) => {
    //     this.itemTipoDoc = { label: doc.NOMBRE, value: doc.CODIGO };
    //     this.opcionesTipoDoc.push(this.itemTipoDoc);
    //   });
    //   this.opcionesTipoDoc.push({ label: '', value: '' });
    // });
    // this.grupo1 = 'fac';
    this.cxctrnretencionesService.getListaClientes().subscribe((resCli) => {
      this.listaClientes = resCli;
    });
    // this.lblAplicaRegMicroempresas = '';
    // this.bolsaldoIni = true;
    // this.bolestcta = false;
    // this.bolsaldo = false;
    // this.listaDoc = [];
    this.cxctrnretencionesService.leerConfiguracionDatosElectronicos().subscribe(data => {
      for (const rs of data) {
        this.datosfe = rs;
      }
    });
  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciasIngreRet--;
  }
//CDPJ
  consultarPosicionCliente(valor) {
    if (valor === 'P') {
      this.indiceCliente = 0;
    } else if (valor === 'S') {
      if (this.indiceCliente < this.listaClientes.length - 1) {
        this.indiceCliente++;
      } else {
        this.indiceCliente = this.listaClientes.length - 1;
        valor = 'U';
      }
    } else if (valor === 'A') {
      if (this.indiceCliente > 0) {
        this.indiceCliente--;
      } else {
        this.indiceCliente = 0;
        valor = 'P';
      }
    } else if (valor === 'U') {
      this.indiceCliente = this.listaClientes.length - 1;
    }
    this.consultarReferencias(valor);
  }

  consultarReferencias(valor) {
    this.cxctrnretencionesService.getListaReferencias(this.listaClientes[this.indiceCliente].CLI_CODIGO).subscribe((resRef) => {
      this.listaReferencias = resRef;
      if (valor === 'P' || valor === 'S') {
        this.indiceDocRef = 0;
      } else if (valor === 'A' || valor === 'U') {
        this.indiceDocRef = this.listaReferencias.length - 1;
      }
      this.obtenerDatos(this.listaReferencias[this.indiceDocRef].TRNCOBRO_REFERENCIAEXTERNA);
    });
  }

  primero() {
    this.editpagofr = true;
    this.consultarPosicionCliente('P');
    if (this.botonUltimo === true) {
      this.botonUltimo = !this.botonUltimo;
    }
    if (this.botonSiguiente === true) {
      this.botonSiguiente = !this.botonSiguiente;
    }
    this.botonPrimero = true;
    this.botonAnterior = true;
  }

  siguiente() {
    this.editpagofr = true;
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE.length === 0) {
      this.consultarPosicionCliente('S');
      return;
    }
    if (this.indiceDocRef < this.listaReferencias.length - 1) {
      this.indiceDocRef++;
    } else {
      this.consultarPosicionCliente('S');
      return;
    }
    if (this.botonPrimero === true && this.botonAnterior === true) {
      this.botonPrimero = false;
      this.botonAnterior = false;
    }
    this.consultarReferencias('');
  }

  anterior() {
    this.editpagofr = true;
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE.length === 0) {
      this.consultarPosicionCliente('A');
      return;
    }
    if (this.indiceDocRef > 0) {
      this.indiceDocRef--;
    } else {
      this.consultarPosicionCliente('A');
      return;
    }
    if (this.botonUltimo === true && this.botonSiguiente === true) {
      this.botonUltimo = false;
      this.botonSiguiente = false;
    }
    this.consultarReferencias('');
  }

  ultimo() {
    this.editpagofr = true;
    this.consultarPosicionCliente('U');
    if (this.botonPrimero === true) {
      this.botonPrimero = !this.botonPrimero;
    }
    if (this.botonAnterior === true) {
      this.botonAnterior = !this.botonAnterior;
    }
    this.botonUltimo = true;
    this.botonSiguiente = true;
  }

  nuevo() {
    this.editConcil = true;
    this.lblDocCierre = false;
    this.opcionesTipoDoc = [];
    // this.cxctrnretencionesService.llenarTipoDoc().subscribe((tipodoc) => {
    //   tipodoc.map((doc) => {
    //     this.itemTipoDoc = { label: doc.NOMBRE, value: doc.CODIGO };
    //     this.opcionesTipoDoc.push(this.itemTipoDoc);
    //   });
    //   this.opcionesTipoDoc.push({ label: '', value: '' });
    // });

    if (this.permisosCartera.PERINSERCION === 1) {
      this.nuevaCartera('N');
    } else if (this.permisosCartera.PERINSERCION === 0) {
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'error',
        summary: 'Inserción de Cartera',
        detail: 'El usuario no tiene permisos de inserción, acción denegada'
      });
    }
    document.getElementById("errasiento").style.display = 'none';
  }

  async borrar() {
    this.editpagofr = true;
    // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
    //   if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDOANC) !==
    //     Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE)) {
    //     this.message.add({
    //       key: 'cxctrnretenciones',
    //       severity: 'error',
    //       summary: 'Anular Cartera',
    //       detail: 'No se puede Eliminar el anticipo ya está siendo utilizado'
    //     });
    //     return;
    //   }
    // }
    // conciliado
    if (this.permisosCartera.PERELIMACION === 1) {
      if (this.activeIndex === 0) {

        if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.length > 0) {
          // ver que los documentos no tengan pagos para la eliminación
          // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
          //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
          //   if (await this.existePagoDoc()) {
          //     return;
          //   }
          // }

          // verificar que el cheque girado por la devolución de anticipos
          // no haya sido conciliado
          // if (await this.chequeConciliado('eliminar')) {
          //   return;
          // }

          // // verificar que el cheque cobrado no haya sido depositado
          // if (await this.chequeDepositado('eliminar')) {
          //   return;
          // }

          // // verificar que el efectivo no haya sido depositado
          // if (await this.efectivoDepositado('eliminar')) {
          //   return;
          // }

          // // verificar que los depósitos no hayan sido conciliados
          // if (await this.depositoConciliado('eliminar')) {
          //   return;
          // }

          // // verificar que los vouchers no hayan sido conciliados
          // if (await this.tarjetaConciliada('eliminar')) {
          //   return;
          // }

          // // verificar que el documento no esté en el cierre de caja
          // if (await this.documentoCierre('eliminar')) {
          //   return;
          // }
          // // verificar si el anticipo ya fue utilizado y no dejar
          // // eliminarlo
          // if (this.anticipoUtilizado('eliminar')) {
          //   return;
          // }
          // verifica si existe algun anticipo asociado y si este anticipo ha sido utilizado
          // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CI') {
          //   if (await this.cxc_fnc_verificar_ant_util(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC) !== 0) {
          //     this.message.add({
          //       key: 'cxctrnretenciones',
          //       severity: 'error',
          //       summary: 'Información',
          //       detail: 'No se puede eliminar la transacción, ya que tiene asociado un anticipo ya utilizado'
          //     });
          //     return;
          //   }
          // }
          this.confirmarCartera();
        } else {
          this.message.add({
            key: 'cxctrnretenciones',
            severity: 'error',
            summary: 'Información',
            detail: 'No hay un documento para la eliminación.'
          });
        }
      } else if (this.activeIndex === 3) {
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Eliminación de Anexos',
          detail: 'No se puede eliminar un anexo, solo modificar'
        });
      }
    } else if (this.permisosCartera.PERELIMACION === 0) {
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'error',
        summary: 'Eliminación de Cartera',
        detail: 'El usuario no tiene permisos de eliminación, acción denegada'
      });
    }
    document.getElementById("errasiento").style.display = 'none';
  }

  async guardarNew() {
    this.editpagofr = true;
    if (this.activeIndex === 0) {
      let bolNuevoAux = this.bolNuevo;
      let intTotalDiasMora = 0;

      if (this.bolNuevo) { // nuevo documento
        if (this.permisosCartera !== null) {
          if (this.permisosCartera.PERTODO === 0) {
            if (this.permisosCartera.PERINSERCION === 0) {
              this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
              return;
            }
          }
        }
      } else { // modificar el documento
        if (this.permisosCartera !== null) {
          if (this.permisosCartera.PERTODO === 0) {
            if (this.permisosCartera.PERACTUALIZACION === 0) {
              this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
              return;
            }
          }
        }
      }

      // validar la fecha de cobro
      if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length > 0) {
        const strNOVFECCAN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOVFECCAN').CFG_VALOR1 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOVFECCAN').CFG_VALOR1;
        if (strNOVFECCAN === 0) {
          if (!this.controlarFechaCobro()) {
            this.mensajeAlerta('info', 'Información', 'La fecha del cobro es menor a la fecha de los documentos.');
            return;
          }
        }
      }

      // validar que se haya seleccionado el tipo de documento
      if (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).length === 0) {
        this.mensajeAlerta('info', 'Información', 'Debe escoger el tipo de documento.');
        return;
      }

      // verificar que se haya seleccionado un cliente
      if (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).length === 0) {
        this.mensajeAlerta('info', 'Información', 'No ha seleccionado el cliente.');
        return;
      } else { // verificar la existencia del cliente
        if (await this.existeCliente() === false) {
          this.mensajeAlerta('info', 'Información', 'No existe el cliente.');
          document.getElementById('cliente').focus();
          return;
        }
      }

      // verificar la existencia del vendedor
      if (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO).length > 0) {
        if (await this.existeVendedor() === false) {
          this.mensajeAlerta('info', 'Información', 'No existe el vendedor.');
          document.getElementById('vendedor').focus();
          return;
        }
      }

      // verificar que el número de comprobante(documento) no esté en blanco
      // cuando sea una factura
      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
        if (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).trim().length === 0) {
          this.mensajeAlerta('info', 'Información', 'Debe ingresar el número del documento.');
          return;
        }
      }

      // verificar que el número de comprobante sea único
      if (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length > 0) {
        if (await this.verificarComprobanteUnico() === false) {
          this.mensajeAlerta('info', 'Información', 'El número de comprobante ya existe.');
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
          document.getElementById('numeroCartera').focus();
          return;
        }
      }

      // verificar que el importe no sea mayor que el saldo y que alguna
      // factura haya sido afectada en caso de una cancelación

      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT' ||
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC' ||
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
        if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length > 0) {
          if (this.verificarImporteDoc() === false) {
            return;
          }
        }
      }

      // validar que el total por cobrar sea igual al total de la forma de
      // pago
      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {

        // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
        //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB') {
        //   if (await this.verificarCobroDocVencidos() > 0) {
        //     intTotalDiasMora = await this.verificarDiasMoraDoc();
        //     if (this.verificaSiAgregoDatosMora(intTotalDiasMora) === false) {
        //       this.mensajeAlerta('warn', 'Advertencia', 'Falta ingresar el valor de comisión por mora y comisión por cobranza');
        //       return;
        //     }
        //   }
        // }

        //this.valorExcedeFormaPago();

        // const strANTEXCPAG = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ANTEXCPAG').CFG_VALOR1 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ANTEXCPAG').CFG_VALOR1;
        // if (strANTEXCPAG === 0) { // si no Permitir generar un anticipo a partir del valor que excede la forma de pago
        //   if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO) !== Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR)) {
        //     this.mensajeAlerta('info', 'Información', 'El total de la forma de pago es distinto al total por cobrar');
        //     return;
        //   }
        // } else {
        //   if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO) !==
        //     (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) + Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO))) {
        //     this.mensajeAlerta('info', 'Información', 'El total de la forma de pago es distinto al total por cobrar');
        //     return;
        //   }
        // }
      }

      // validar que el total por cobrar sea igual al total de la forma de pago
      // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
      //   if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO) !==
      //     Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC)) {
      //     this.mensajeAlerta('info', 'Información', 'El total de la forma de pago es distinto al total de los documentos');
      //     return;
      //   }
      // }

      // verificar el importe que sea mayor a cero
      /*  console.log(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE);
      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE !== null) {
        if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE) == 0) {
          this.mensajeAlerta('info', 'Información', 'El total de la forma de pago es distinto al total de los documentos');
          return;
        }
      } */

      // VALIDACIONES DE LAS RETENCIONES
      if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length > 0) {
        if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length > 0) {
          this.eliminarLineaRetEnBlanco();
          if (await this.faltaDatosRet() === true) {
            return;
          }
        }
      }

      // VALIDACIONES DE LOS OTROS GASTOS
      // if (this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].length > 0) {
      //   this.eliminarUltimaLineaOtrosG();
      //   if (await this.faltaDatosOtrosG() === true) {
      //     return;
      //   }
      // }

      // // VALIDACIONES DE LA FORMA DE PAGO
      // if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length > 0) {
      //   this.eliminarUltimaLineaFP();
      //   if (await this.faltaDatosFP()) {
      //     return;
      //   }

      //   if (await this.verificarCodSri() === false) {
      //     this.mensajeAlerta('info', 'Información', 'El código SRI en la forma de pago es incorrecto, por favor verifique');
      //     return;
      //   }
      // }

      /* // VALIDACIONES DE VARIOS
      if (this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length > 0) {
        this.eliminarUltimaLineaVarios()
        if (await this.faltaDatosVarios() === true) {
          return;
        }	
      } */
      await this.guardarCartera();

    }

    // if (this.activeIndex === 3) {
    //   this.validacionTiposSri();
    // }
  }

  async guardar() {

    if (this.activeIndex === 0) {
      this.spin=true//CDPJ
      // validar la fecha de cobro
      if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length > 0) {
        const strNOVFECCAN = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOVFECCAN').CFG_VALOR1 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOVFECCAN').CFG_VALOR1;
        if (strNOVFECCAN === 0) {
          if (!this.controlarFechaCobro()) {
            this.mensajeAlerta('info', 'Información', 'La fecha del cobro es menor a la fecha de los documentos.');
            this.spin=false//CDPJ
            return;
          }
          //GSRF
          if (!this.controlarFechaforpago()) {
            this.mensajeAlerta('info', 'Información', 'La fecha de pago es menor a la fecha de los documentos.');
            this.spin=false//CDPJ
            return;
          }
          if (!this.controlarFechaforpagodev()) {
            this.mensajeAlerta('info', 'Información', 'La fecha de pago es menor a la fecha de los documentos.');
            this.spin=false//CDPJ
            return;
          }
          //GSRF
        }
      }
      console.log(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
      // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPago.objeto];
      if (this.permisosCartera.PERACTUALIZACION === 1) {
        if (this.booleanNumero === false) {
          // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
          //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
          //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
          //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE !== '') {
          //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
          //       const exis = await this.cxctrnretencionesService.verificarComprobanteUnico('N',
          //         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, '');

          //       if (exis[0][':B1'] !== '0' && exis[0][':B1'] !== null && exis[0][':B1'] !== undefined) {
          //         this.message.add({
          //           key: 'cxctrnretenciones',
          //           severity: 'error',
          //           summary: 'Inserción de Cartera',
          //           detail: 'El numero de comprobante ya existe'
          //         });
          //         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
          //         document.getElementById('numeroCartera').focus();
          //         this.spin=false//CDPJ
          //         return;
          //       }

          //     } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
          //       let numeroFac = '';
          //       if (this.cxctrnresumen.length !== 0) {
          //         for (const resumen of this.cxctrnresumen) {
          //           if (resumen.SELECCIONADO === true) {
          //             numeroFac = resumen.TRNCOBRO_NRODOC;
          //             const exis = await this.cxctrnretencionesService.verificarComprobanteUnico('M',
          //               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, numeroFac).catch(e=>{
          //                 this.spin=false//CDPJ
          //               });
          //             console.log(exis);
          //             if (exis[0][':B1'] !== '0') {
          //               this.message.add({
          //                 key: 'cxctrnretenciones',
          //                 severity: 'error',
          //                 summary: 'Inserción de Cartera',
          //                 detail: 'El numero de comprobante ya existe'
          //               });
          //               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
          //               document.getElementById('numeroCartera').focus();
          //               this.spin=false//CDPJ
          //               return;
          //             } else {

          //             }

          //           }
          //         }
          //       }
          //     }
          //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
          //       this.message.add({
          //         key: 'cxctrnretenciones',
          //         severity: 'error',
          //         summary: 'Inserción de Cartera',
          //         detail: 'Escoja un tipo de documento'
          //       });
          //       this.spin=false//CDPJ
          //       return;
          //     } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
          //       this.message.add({
          //         key: 'cxctrnretenciones',
          //         severity: 'error',
          //         summary: 'Inserción de Cartera',
          //         detail: 'Escoja un cliente'
          //       });
          //       this.spin=false//CDPJ
          //       return;
          //     } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
          //       this.message.add({
          //         key: 'cxctrnretenciones',
          //         severity: 'error',
          //         summary: 'Inserción de Cartera',
          //         detail: 'Escoja un vendedor'
          //       });
          //       this.spin=false//CDPJ
          //       return;
          //     } else {
          //       let auxiliar = 0;
          //       let seleccionados = 0;
          //       if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
          //         this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
          //           if (resumen.SELECCIONADO === true) {
          //             seleccionados++;
          //           }
          //         });

          //         if (seleccionados === 0) {
          //           this.message.add({
          //             key: 'cxctrnretenciones',
          //             severity: 'error',
          //             summary: 'Inserción de Cartera',
          //             detail: 'Ningún documento ha sido ' +
          //               'seleccionado.'
          //           });
          //           this.spin=false//CDPJ
          //           return;
          //         }

          //         if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
          //           this.message.add({
          //             key: 'cxctrnretenciones',
          //             severity: 'error',
          //             summary: 'Inserción de Cartera',
          //             detail: 'El total debe ser diferente de cero'
          //           });
          //           this.spin=false//CDPJ
          //           return;
          //         }
          //       }
          //       if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
          //         this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].map((formap) => {
          //           if (formap.TRNPAGO_TIPO === 'CH') {
          //             if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') { // formap.TRNPAGO_NUMERO
          //               this.message.add({
          //                 key: 'cxctrnretenciones',
          //                 severity: 'error',
          //                 summary: 'Inserción de Cartera',
          //                 detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
          //               });
          //               this.spin=false//CDPJ
          //               auxiliar++;
          //             }
          //           }
          //           let fecenc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
          //           if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
          //             fecenc = this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          //           }
          //           console.log(1, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
          //           /* if (formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { // formap.TRNPAGO_TIPO === 'DP' || 
          //             if (formap.TRNPAGO_FECHA !== fecenc) {
          //               this.message.add({
          //                 key: 'cxctrnretenciones',
          //                 severity: 'error',
          //                 summary: 'Inserción de Cartera',
          //                 detail: 'Fecha de pago incorrecta'
          //               });
          //               auxiliar++;
          //             }
          //           } */
          //         });
          //         if (auxiliar === 0) {
          //           this.guardarCartera();
          //         }
          //       } else if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length !== 0) {
          //         let auxiliar1 = 0;
          //         let cont = 0;
          //         let nroretencion = '';
          //         this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].map((ret) => {
          //           if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
          //             this.message.add({
          //               key: 'cxctrnretenciones',
          //               severity: 'error',
          //               summary: 'Inserción de Cartera',
          //               detail: 'No se puede insertar el registro por que falta el código o número de retención'
          //             });
          //             this.spin=false//CDPJ
          //             auxiliar1++;
          //           }

          //           // Verificar número de la retención

          //           cont++;
          //           if (cont > 1) {
          //             if (nroretencion !== ret.TRNRETENCION_NRO) {
          //               this.message.add({
          //                 key: 'pagcon',
          //                 severity: 'error',
          //                 summary: 'Error',
          //                 detail: 'El número de la retención debe ser el mismo'
          //               });
          //               this.spin=false//CDPJ
          //               auxiliar1++;
          //             }
          //           } else {
          //             nroretencion = ret.TRNRETENCION_NRO;
          //           }
          //         });

          //         if (auxiliar1 === 0) {
          //           this.guardarCartera();
          //         }
          //       } else {
          //         this.guardarCartera();
          //       }
          //     }
          //   } else {
          //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
          //       this.message.add({
          //         key: 'cxctrnretenciones',
          //         severity: 'error',
          //         summary: 'Inserción de Cartera',
          //         detail: 'Escoja un tipo de documento'
          //       });
          //       this.spin=false//CDPJ
          //       return;
          //     } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
          //       this.message.add({
          //         key: 'cxctrnretenciones',
          //         severity: 'error',
          //         summary: 'Inserción de Cartera',
          //         detail: 'Escoja un cliente'
          //       });
          //       this.spin=false//CDPJ
          //       return;
          //     } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
          //       this.message.add({
          //         key: 'cxctrnretenciones',
          //         severity: 'error',
          //         summary: 'Inserción de Cartera',
          //         detail: 'Escoja un vendedor'
          //       });
          //       this.spin=false//CDPJ
          //       return;
          //     } else {
          //       let auxiliar = 0;
          //       let seleccionados = 0;
          //       if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
          //         this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
          //           if (resumen.SELECCIONADO === true) {
          //             seleccionados++;
          //           }
          //         });
          //         if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
          //           this.message.add({
          //             key: 'cxctrnretenciones',
          //             severity: 'error',
          //             summary: 'Inserción de Cartera',
          //             detail: 'El total debe ser diferente de cero'
          //           });
          //           this.spin=false//CDPJ
          //           return;
          //         }
          //         if (seleccionados === 0) {
          //           this.message.add({
          //             key: 'cxctrnretenciones',
          //             severity: 'error',
          //             summary: 'Inserción de Cartera',
          //             detail: 'Ningún documento ha sido ' +
          //               'seleccionado.'
          //           });
          //           this.spin=false//CDPJ
          //           return;
          //         }
          //       }

          //       /* if (seleccionados === 0) {
          //         this.message.add({
          //           key: 'cxctrnretenciones',
          //           severity: 'error',
          //           summary: 'Inserción de Cartera',
          //           detail: 'Ningún documento ha sido ' +
          //             'seleccionado.'
          //         });
          //         return;
          //       } */
          //       if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
          //         this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].map((formap) => {
          //           if (formap.TRNPAGO_TIPO === 'TA' || formap.TRNPAGO_TIPO === 'CH') { // formap.TRNPAGO_TIPO === 'DP' || formap.TRNPAGO_TIPO === 'CH'
          //             if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') {
          //               this.message.add({
          //                 key: 'cxctrnretenciones',
          //                 severity: 'error',
          //                 summary: 'Inserción de Cartera',
          //                 detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
          //               });
          //               this.spin=false//CDPJ
          //               auxiliar++;
          //             }
          //           }
          //           let fecenc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
          //           if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
          //             fecenc = this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          //           }
          //           console.log(2, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
          //           /* if ( formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { // formap.TRNPAGO_TIPO === 'DP' 
          //             if (formap.TRNPAGO_FECHA !== fecenc) {
          //               this.message.add({
          //                 key: 'cxctrnretenciones',
          //                 severity: 'error',
          //                 summary: 'Inserción de Cartera',
          //                 detail: 'Fecha de pago incorrecta'
          //               });
          //               auxiliar++;
          //             }
          //           } */
          //         });
          //         if (auxiliar === 0) {
          //           this.guardarCartera();
          //         }
          //       } else if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length !== 0) {
          //         let auxiliar1 = 0;
          //         let cont = 0;
          //         let nroretencion = '';
          //         this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].map((ret) => {
          //           if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
          //             this.message.add({
          //               key: 'cxctrnretenciones',
          //               severity: 'error',
          //               summary: 'Inserción de Cartera',
          //               detail: 'No se puede insertar el registro por que falta el código o número de retención'
          //             });
          //             this.spin=false//CDPJ
          //             auxiliar1++;
          //           }

          //           // Verificar número de la retención

          //           cont++;
          //           if (cont > 1) {
          //             if (nroretencion !== ret.TRNRETENCION_NRO) {
          //               this.message.add({
          //                 key: 'pagcon',
          //                 severity: 'error',
          //                 summary: 'Error',
          //                 detail: 'El número de la retención debe ser el mismo'
          //               });
          //               this.spin=false//CDPJ
          //               auxiliar1++;
          //             }
          //           } else {
          //             nroretencion = ret.TRNRETENCION_NRO;
          //           }
          //         });

          //         if (auxiliar1 === 0) {
          //           this.guardarCartera();
          //         }
          //       } else {
          //         this.guardarCartera();
          //       }
          //     }
          //   }
          // } 
          //else {
            // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
            //   if (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).trim().length === 0) {
            //     this.message.add({
            //       key: 'cxctrnretenciones',
            //       severity: 'info',
            //       summary: 'Inserción de Cartera',
            //       detail: 'Debe ingresar el número del documento.'
            //     });
            //     this.spin=false//CDPJ
            //     return;
            //   }
            // }

            // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
            //   this.message.add({
            //     key: 'cxctrnretenciones',
            //     severity: 'error',
            //     summary: 'Inserción de Cartera',
            //     detail: 'Escoja un tipo de documento'
            //   });
            //   this.spin=false//CDPJ
            //   return;
            // } else 
            if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
              this.message.add({
                key: 'cxctrnretenciones',
                severity: 'error',
                summary: 'Inserción de Cartera',
                detail: 'Escoja un cliente'
              });
              this.spin=false//CDPJ
              return;
            } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
              this.message.add({
                key: 'cxctrnretenciones',
                severity: 'error',
                summary: 'Inserción de Cartera',
                detail: 'Escoja un vendedor'
              });
              this.spin=false//CDPJ
              return;
            } else {
              let auxiliar = 0;
              let seleccionados = 0;
              if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
                this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
                  if (resumen.SELECCIONADO === true) {
                    seleccionados++;
                  }
                });

                // if (seleccionados === 0 &&
                //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ND') {
                //   this.message.add({
                //     key: 'cxctrnretenciones',
                //     severity: 'error',
                //     summary: 'Inserción de Cartera',
                //     detail: 'Ningún documento ha sido ' +
                //       'seleccionado.'
                //   });
                //   this.spin=false//CDPJ
                //   return;
                // }
                // console.log(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);

                // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'NC'
                //   && this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'RT') {
                //   if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
                //     this.message.add({
                //       key: 'cxctrnretenciones',
                //       severity: 'error',
                //       summary: 'Inserción de Cartera',
                //       detail: 'El total debe ser diferente de cero'
                //     });
                //     this.spin=false//CDPJ
                //     return;
                //   }
                // }
              }
              // if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
              //   this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].map((formap) => {
              //     if (formap.TRNPAGO_TIPO === 'TA' || formap.TRNPAGO_TIPO === 'CH') {
              //       if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') {
              //         this.message.add({
              //           key: 'cxctrnretenciones',
              //           severity: 'error',
              //           summary: 'Inserción de Cartera',
              //           detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
              //         });
              //         this.spin=false//CDPJ
              //         auxiliar++;
              //       }
              //     }
              //     let fecenc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
              //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
              //       fecenc = this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
              //     }
              //     console.log(3, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
              //     /* if (formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { // formap.TRNPAGO_TIPO === 'DP' || 
              //       if (formap.TRNPAGO_FECHA !== fecenc) {
              //         this.message.add({
              //           key: 'cxctrnretenciones',
              //           severity: 'error',
              //           summary: 'Inserción de Cartera',
              //           detail: 'Fecha de pago incorrecta'
              //         });
              //         auxiliar++;
              //       }
              //     } */
              //   });
              //   if (auxiliar === 0) {
              //     this.guardarCartera();
              //   }
              // } else
              
              if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length !== 0) {
                let auxiliar1 = 0;
                let cont = 0;
                let nroretencion = '';
                if (this.verificarImporteTotalRet() === false) {
                  auxiliar1++;
                }
                this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].map((ret) => {
                  if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
                    this.message.add({
                      key: 'cxctrnretenciones',
                      severity: 'error',
                      summary: 'Inserción de Cartera',
                      detail: 'No se puede insertar el registro por que falta el código o número de retención'
                    });
                    this.spin=false//CDPJ
                    auxiliar1++;
                  }

                  // Verificar número de la retención

                  cont++;
                  if (cont > 1) {
                    if (nroretencion !== ret.TRNRETENCION_NRO) {
                      this.message.add({
                        key: 'pagcon',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'El número de la retención debe ser el mismo'
                      });
                      this.spin=false//CDPJ
                      auxiliar1++;
                    }
                  } else {
                    nroretencion = ret.TRNRETENCION_NRO;
                  }
                });

                if (auxiliar1 === 0) {
                  this.guardarCartera();
                }
              } else {
                this.guardarCartera();
              }
            }
          //}
        } else if (this.booleanNumero === true) {
          // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === '') {
          //   this.message.add({
          //     key: 'cxctrnretenciones',
          //     severity: 'error',
          //     summary: 'Inserción de Cartera',
          //     detail: 'Escoja un tipo de documento'
          //   });
          //   this.spin=false//CDPJ
          //   return;
          // } else 
          if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
            this.message.add({
              key: 'cxctrnretenciones',
              severity: 'error',
              summary: 'Inserción de Cartera',
              detail: 'Escoja un cliente'
            });
            this.spin=false//CDPJ
            return;
          } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '') {
            this.message.add({
              key: 'cxctrnretenciones',
              severity: 'error',
              summary: 'Inserción de Cartera',
              detail: 'Escoja un vendedor'
            });
            this.spin=false//CDPJ
            return;
          } else {
            let seleccionados = 0;
            if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
              this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
                if (resumen.SELECCIONADO === true) {
                  seleccionados++;
                }
              });

              if (seleccionados === 0) {
                this.message.add({
                  key: 'cxctrnretenciones',
                  severity: 'error',
                  summary: 'Inserción de Cartera',
                  detail: 'Ningún documento ha sido ' +
                    'seleccionado.'
                });
                this.spin=false//CDPJ
                return;
              }

              // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'NC'
              //   && this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'RT') {
              //   if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) === 0) {
              //     this.message.add({
              //       key: 'cxctrnretenciones',
              //       severity: 'error',
              //       summary: 'Inserción de Cartera',
              //       detail: 'El total debe ser diferente de cero'
              //     });
              //     this.spin=false//CDPJ
              //     return;
              //   }
              // }
            }
            // if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
            //   let auxiliar = 0;
            //   this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].map((formap) => {
            //     if (formap.TRNPAGO_TIPO === 'TA' || formap.TRNPAGO_TIPO === 'CH') { // formap.TRNPAGO_TIPO === 'DP' || formap.TRNPAGO_TIPO === 'CH'
            //       if (formap.BANCLI_CODIGO === '' || formap.TRNPAGO_NROCTA === '' || formap.TRNPAGO_NUMERO === '') {
            //         this.message.add({
            //           key: 'cxctrnretenciones',
            //           severity: 'error',
            //           summary: 'Inserción de Cartera',
            //           detail: 'No se puede insertar el registro por que falta código de banco, número de pago, concepto o número de cuenta'
            //         });
            //         this.spin=false//CDPJ
            //         auxiliar++;
            //       }
            //     }

            //     let fecenc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
            //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.length !== 10) {
            //       fecenc = this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
            //     }
            //     console.log(4, formap.TRNPAGO_TIPO, formap.TRNPAGO_FECHA, fecenc);
            //     /* if (formap.TRNPAGO_TIPO === 'EF' || formap.TRNPAGO_TIPO === 'TA') { //formap.TRNPAGO_TIPO === 'DP'
            //       if (formap.TRNPAGO_FECHA !== fecenc) {
            //         this.message.add({
            //           key: 'cxctrnretenciones',
            //           severity: 'error',
            //           summary: 'Inserción de Cartera',
            //           detail: 'Fecha de pago incorrecta'
            //         });
            //         auxiliar++;
            //       }
            //     } */
            //   });
            //   if (auxiliar === 0) {
            //     this.guardarCartera();
            //   }
            // } else
             if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length !== 0) {

              let auxiliar1 = 0;
              let cont = 0;
              let nroretencion = '';
              if (this.verificarImporteTotalRet() === false) {
                auxiliar1++;
              }

              this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].map((ret) => {
                if (ret.RETENCION_CODIGO === '' || ret.TRNRETENCION_NRO === '' || ret.TRNRETENCION_NRO === null) {
                  this.message.add({
                    key: 'cxctrnretenciones',
                    severity: 'error',
                    summary: 'Inserción de Cartera',
                    detail: 'No se puede insertar el registro por que falta el código o número de retención'
                  });
                  this.spin=false//CDPJ
                  auxiliar1++;
                }

                // Verificar número de la retención

                cont++;
                if (cont > 1) {
                  if (nroretencion !== ret.TRNRETENCION_NRO) {
                    this.message.add({
                      key: 'pagcon',
                      severity: 'error',
                      summary: 'Error',
                      detail: 'El número de la retención debe ser el mismo'
                    });
                    this.spin=false//CDPJ
                    auxiliar1++;
                  }
                } else {
                  nroretencion = ret.TRNRETENCION_NRO;
                }
              });

              if (auxiliar1 === 0) {
                this.guardarCartera();
              }
            } else {
              this.guardarCartera();
            }
          }
        }
      } else if (this.permisosCartera.PERACTUALIZACION === 0) {
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Actualización de Cartera',
          detail: 'El usuario no tiene permisos de actualización, acción denegada'
        });
        this.spin=false//CDPJ
      }
    }

    // if (this.activeIndex === 3) {
    //   this.validacionTiposSri();
    // }
  }

  generarasiento() {
    if (this.btnGuardar === true) {
      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Asiento Cartera',
          detail: 'No se puede generar un asiento debido a que el documento proviene de otro origen'
        });
        return;
      } else {
        if (this.saldoInicial === false) {
          this.confirmarAsiento();
        }
      }
    } else {
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'error',
        summary: 'Asiento Cartera',
        detail: 'No se puede generar un asiento debido a que la cxctrnretenciones no esta guardada'
      });
    }
  }

  asientover() {
    this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
      this.asiento = res[0].ASI_NRO;
      if (this.asiento === null) {
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Visualizacion de Asiento Contable',
          detail: 'Esta cxctrnretenciones no tiene un asiento contable'
        });
      } else {
        this.auxiliar++;
        // this.displayDialogAsiento = true;
        this.displayAsiContable = true;
      }
    });
  }

  async manejarSenales(valor) {
    if (valor !== 'Nuevo' && valor !== 'Cancelar'
      && valor !== 'Primero' && valor !== 'Anterior' && valor !== 'Siguiente' && valor !== 'Ultimo'
      && valor !== 'Buscar' && valor !== 'Imprimir' && valor !== 'AsientoVer' && valor !== 'Salir'
      && valor !== 'facturasAbonadas') {
      let dia = new Date().getDate();
      let mes = new Date().getMonth();
      let anio = new Date().getFullYear();
      try {
        // Verifica el periodo contable
        dia = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN === undefined ? dia : this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.getDate();
        mes = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN === undefined ? mes : this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.getMonth();
        anio = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN === undefined ? anio : this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.getFullYear();
      } catch {
        const tmpfecha = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.substr(0, 10);
        console.log(tmpfecha);
        const splfec = tmpfecha.split('/');
        const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
        dia = fecha.getDate();
        mes = fecha.getMonth();
        anio = fecha.getFullYear();
      }

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'info',
          summary: 'Información',
          detail: 'Error, fecha debe de estar dentro del período contable'
        });
        return;
      }
    }
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }

    if (valor === 'Primero') {
      this.primero();
    } else if (valor === 'Siguiente') {
      this.siguiente();
    } else if (valor === 'Anterior') {
      this.anterior();
    } else if (valor === 'Ultimo') {
      this.ultimo();
    } else if (valor === 'Nuevo') {
      this.nuevo();
    } else if (valor === 'Borrar') {
      this.borrar();
    } else if (valor === 'Guardar') {
      // this.guardarNew();
      this.guardar();
    } else if (valor === 'Cancelar') {
      this.cancelarEdicionCartera();
    } else if (valor === 'Anular') {
      this.abrirClave('clave');
    } else if (valor === 'Buscar') {
      this.buscarCartera();
    } else if (valor === 'Asiento') {
      this.generarasiento();
    } else if (valor === 'AsientoVer') {
      this.asientover();
    } else if (valor === 'Firmar') {
      this.insertarEleTrnDoc();
    } else if (valor === 'EnviarCorreo') {
      //this.reenviarCorreo();
    } else if (valor === 'facturasAbonadas') {
      this.abrirFacturasAbonadas();
    }

    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;
      this.displayDialogReportes = true;
    }

    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  nuevaCartera(parametro) {
    this.bolNuevo = true;
    if (parametro === 'N') { // N para nuevo
      this.btnNuevo = true;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal').style.opacity = '1.0';
      /*this.columnDefsDVFDetalle.map((columna) => {
        columna.editable = true;
      });*/
      // this.aggrid.refreshColumnDefs2(this.columnDefsDVFDetalle);
      this.numeroFactura = '';
      this.editarCeldas = false;
      this.tipodetalleactivo = 'all';
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.btnRegresar = false;
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
      this.botonPago = true;
      this.botonAnular = true;
      this.botonBuscar = true;
      this.botonAsiento = true;
      this.botonVerAsiento = true;
      this.porcIvaBoolean = true;
      this.booleanDatosPantalla = false;
      this.tabVarios = false;
      this.cxctrnretencionesService.dblTotalRetarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalDocarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalFormaDevarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalNDarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalFormaPagoarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalOtrosGarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalNCarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalCobrararray[this.indicador] = 0;
      //this.tabDebitoBancario = false;
     // this.tabFormaPagoDev = false;
      this.tabRetenciones = true;
      //this.tabOtrosGastos = false;
      //this.tabEstadoCuentaAnt = false;
      //this.tabFormaPago = false;
      this.impresoString = '';
      this.retencionElect = true;
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] = {
        SALDO: '',
        TRNCOBRO_TIPODOC: 'RT',
        TOTALFORMAPAGO: 0,
        TOTALND: 0,
        TRNCOBRO_IMPORTE: 0,
        TOTALNC: 0,
        TOTALOTROSG: 0,
        TOTALRET: 0,
        TOTALACOBRAR: 0,
        TOTALDOC: 0,
        BAN_BANCO: '',
        BAN_CODIGO: '',
        TRNCOBRO_NROCOMPROBANTE: '',
        TRNCOBRO_SALDOINI: '',
        CON_CODIGO: '',
        TRNCOBRO_NRODOC: '',
        TOTALANTICIPO: '',
        TRNCOBRO_FECHAVENCE: '',
        CLI_NOMBRE: '',
        REALIZADO: '',
        ORIGEN: '',
        VEN_NOMBRE: '',
        APLORG_CODIGO: 'CXC',
        USU_IDENTIFICACION: this.usuario.identificacion,
        TRNCOBRO_CONCEPTO: '',
        BOD_CODIGO: '',
        TRNCOBRO_FECHATRN: new Date(),
        VEN_CODIGO: '',
        CLI_CODIGO: '',
        ASI_NRO: '',
        CABROLADMN_CODIGO: '',
        CABROLVEND_CODIGO: '',
        COM_CODIGO: '01',
        COM_CODIGO_CXP: '01',
        COM_TIPOAMBFACTELEC: '',
        EMP_CODIGO: '',
        EMP_CODIGOVEND: '',
        TRNCOBRO_ANULADO: '',
        TRNCOBRO_CAMBIO: '',
        TRNCOBRO_CUADRE: '',
        TRNCOBRO_EFECTIVO: '',
        TRNCOBRO_FLAG: '1',
        TRNCOBRO_IMPRESO: '',
        TRNCOBRO_LOCALIZACIONXMLRET: '',
        TRNCOBRO_NRODOC_CXP: '',
        TRNCOBRO_NRODOC_OLD: '',
        TRNCOBRO_PATHPDF_FE: '',
        TRNCOBRO_PATHXMLNOAUTO_FE: '',
        TRNCOBRO_PATHXMLRET: '',
        TRNCOBRO_REFERENCIAEXTERNA: '',
        TRNCOBROND_AUTORIZACION_FE: '',
        TRNCOBROND_CLAVEACCESO_FE: '',
        TRNCOBROND_ESTADO_FE: '',
        TRNCOBROND_FECHAAUT_FE: '',
        TRNCOBROND_NUMFACTELEC: ''
      };
      document.getElementById('cliente').focus();
      this.cxctrnretencionesService.getOrigen(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe((resApl) => {
        if (resApl.length !== 0) {
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
            this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-' + resApl[0].APLORG_NOMBRE;
        } else {
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
            this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-';
        }
      });
      this.cxctrnretencionesService.getRealizado(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION).subscribe((resReal) => {
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].REALIZADO = resReal[0].NOMBRE;
      });
      this.indice = -1;
      this.booleanBodega = false;
      this.saldoInicial = false;
      this.booleanAnulado = false;
      this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
      this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = [];
      this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = [];
      this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = [];
      this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = [];
      this.cxctrnretencionesService.cxctrnanticiposarray[this.indicador] = [];
      this.lblAplicaRegMicroempresas = '';
      this.lblAplicaRegMicroempresas = '';
      this.bolestcta = false;
      this.bolsaldo = false;
      this.btneditConcil = true;
    } else if (parametro === 'C') { // C para cancelar
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.botonUltimo = false;
      this.botonAnterior = false;
      this.botonPrimero = false;
      this.botonSiguiente = false;
      this.botonBuscar = false;
      this.botonPago = false;
      this.botonAnular = false;
      this.botonAsiento = false;
      this.botonVerAsiento = false;
      this.porcIvaBoolean = true;
      this.booleanDatosPantalla = false;
      // this.tabVarios = false;
      // this.tabDebitoBancario = false;
      // this.tabFormaPagoDev = false;
      this.tabRetenciones = true;
      this.numeroFactura = '';
      // this.tabOtrosGastos = false;
      this.cxctrnretencionesService.dblTotalRetarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalDocarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalFormaDevarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalNDarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalFormaPagoarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalOtrosGarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalNCarray[this.indicador] = 0;
      this.cxctrnretencionesService.dblTotalCobrararray[this.indicador] = 0;
      this.tabEstadoCuentaAnt = false;
      this.tabFormaPago = false;
      this.impresoString = '';
      this.retencionElect = true;
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] = {
        SALDO: '',
        TRNCOBRO_TIPODOC: '',
        TOTALFORMAPAGO: 0,
        TOTALND: 0,
        TRNCOBRO_IMPORTE: 0,
        TOTALNC: 0,
        TOTALOTROSG: 0,
        TOTALRET: 0,
        TOTALACOBRAR: 0,
        TOTALDOC: 0,
        BAN_BANCO: '',
        BAN_CODIGO: '',
        TRNCOBRO_NROCOMPROBANTE: '',
        TRNCOBRO_SALDOINI: '',
        CON_CODIGO: '',
        TRNCOBRO_NRODOC: '',
        TOTALANTICIPO: '',
        TRNCOBRO_FECHAVENCE: '',
        CLI_NOMBRE: '',
        REALIZADO: '',
        ORIGEN: '',
        VEN_NOMBRE: '',
        APLORG_CODIGO: 'CXC',
        USU_IDENTIFICACION: this.usuario.identificacion,
        TRNCOBRO_CONCEPTO: '',
        BOD_CODIGO: '',
        TRNCOBRO_FECHATRN: new Date(),
        VEN_CODIGO: '',
        CLI_CODIGO: '',
        ASI_NRO: '',
        CABROLADMN_CODIGO: '',
        CABROLVEND_CODIGO: '',
        COM_CODIGO: '01',
        COM_CODIGO_CXP: '01',
        COM_TIPOAMBFACTELEC: '',
        EMP_CODIGO: '',
        EMP_CODIGOVEND: '',
        TRNCOBRO_ANULADO: '',
        TRNCOBRO_CAMBIO: '',
        TRNCOBRO_CUADRE: '',
        TRNCOBRO_EFECTIVO: '',
        TRNCOBRO_FLAG: '1',
        TRNCOBRO_IMPRESO: '',
        TRNCOBRO_LOCALIZACIONXMLRET: '',
        TRNCOBRO_NRODOC_CXP: '',
        TRNCOBRO_NRODOC_OLD: '',
        TRNCOBRO_PATHPDF_FE: '',
        TRNCOBRO_PATHXMLNOAUTO_FE: '',
        TRNCOBRO_PATHXMLRET: '',
        TRNCOBRO_REFERENCIAEXTERNA: '',
        TRNCOBROND_AUTORIZACION_FE: '',
        TRNCOBROND_CLAVEACCESO_FE: '',
        TRNCOBROND_ESTADO_FE: '',
        TRNCOBROND_FECHAAUT_FE: '',
        TRNCOBROND_NUMFACTELEC: ''
      };
      this.booleanBodega = false;
      this.booleanAnulado = false;
      this.saldoInicial = false;
      this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
      this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = [];
      this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = [];
      this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = [];
      this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = [];
      this.cxctrnretencionesService.cxctrnanticiposarray[this.indicador] = [];
      this.lblAplicaRegMicroempresas = '';
      this.btneditConcil = true;
    }
  }



  async obtenerDatos(valor) {
    ///GSRF    
    this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
    this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = [];
    this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = [];
    this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = [];
    this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = [];
    this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador] = [];
    this.cxctrnretencionesService.cxctrnanticiposarray[this.indicador] = [];
    this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = [];
    this.cxctrnretencionesService.dblTotalDocarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalRetarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalOtrosGarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalFormaPagoarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalFormaDevarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalVariosarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalNDarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalNCarray[this.indicador] = 0;
    this.cxctrnretencionesService.dblTotalCobrararray[this.indicador] = 0;
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] = {};
    ///GSRF
    // this.cxctrnretencionesService.dblTotalNCarray[this.indicador] = 0;
    //this.cxctrnretencionesService.dblTotalOtrosGarray[this.indicador] = 0;
    //this.cxctrnretencionesService.dblTotalFormaPagoarray[this.indicador] = 0;
    //this.cxctrnretencionesService.dblTotalFormaDevarray[this.indicador] = 0;
    //this.cxctrnretencionesService.dblTotalRetarray[this.indicador] = 0;
    //this.cxctrnretencionesService.dblTotalDocarray[this.indicador] = 0;
    // this.cxctrnretencionesService.dblTotalNDarray[this.indicador] = 0;
    this.numeroFactura = '';
    this.booleanAnulado = false;
    const res = await this.cxctrnretencionesService.getEncCarteraProm(valor);
    console.log(res[0]);
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] = res[0];
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_ANULADO === 1) {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
      this.booleanAnulado = true;
      this.btnBorrar = true;
    }

    // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NDA') {
    //   this.itemTipoDoc = { label: 'NOTA DE DEBITO', value: 'NDA' };
    //   this.opcionesTipoDoc.push(this.itemTipoDoc);
    // }

    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = 0;
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC =
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC.toFixed(2);
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = 0;
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO =
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO.toFixed(2);
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO = 0;
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO =
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO.toFixed(2);
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG = 0;
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG =
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG.toFixed(2);
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = 0;
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR =
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR.toFixed(2);
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALRET = 0;
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALRET =
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALRET.toFixed(2);
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALNC = 0;
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO =
      Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO).toFixed(2);

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'FC' && this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ANC' &&
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ND' && this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'NDA') {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = null;
    } else {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE =
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE.toFixed(2);
    }


    console.log(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]);

    const data = await this.cxctrnretencionesService.erMaeclienteProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
    for (const resCli of data) {
      if (resCli.CLI_CONREGMIC === 'S'
        || resCli.CLI_AGENTERET === 'S') {
        if (resCli.CLI_CONREGMIC === 'S'
          && resCli.CLI_AGENTERET === 'S') {
          this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
        } else {
          if (resCli.CLI_CONREGMIC === 'S') {
            this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
          }
          if (resCli.CLI_AGENTERET === 'S') {
            this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
          }
        }
      } else {
        this.lblAplicaRegMicroempresas = '';
      }
    }

    // this.consultarPestañas();
    //await this.cargarAnexos();

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC'
      || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA'
      || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB'
      || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT'
      || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN'
      || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
      this.bolsaldoIni = true;
    } else {
      this.bolsaldoIni = false;
    }
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NC') === true ||
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('ND') === true) {
      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('ANC') === false &&
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NDA') === false &&
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC.includes('NDR') === false) {
        this.bolnc = false;
      } else {
        this.bolnc = true;
      }
    } else {
      this.bolnc = true;
    }

    //const bolCierre = await this.documentoCierre('');

    // if (bolCierre) {
    //   document.getElementById('divPrincipal').style.pointerEvents = 'none';
    //   this.btnBorrar = true;
    //   this.botonAnular = true;
    // }



    // if (bolCierre) {
    //   this.lblDocCierre = true;
    // }

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
      this.impresoString = 'IMPRESO';
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      document.getElementById('divPrincipal').style.opacity = '0.7';
      this.btnBorrar = true;
      this.botonAnular = true;
      /*this.columDefCarteraDoc.map((columna) => {
        columna.editable = false;
      });
      this.aggrid.refreshColumnDefs2(this.columDefCarteraDoc);
      this.editarCeldas = true;
      this.tipodetalleactivo = 'none';*/
    } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO === 'CXC') {
      this.impresoString = '';
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal').style.opacity = '1.0';
      this.botonAnular = false;
      this.btnBorrar = false;
      /*this.columDefCarteraDoc.map((columna) => {
        columna.editable = true;
      });
      this.aggrid.refreshColumnDefs2(this.columDefCarteraDoc);
      this.editarCeldas = false;
      this.tipodetalleactivo = 'all';*/
    }

    console.log(new Date(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN));
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN =
      new Date(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN);
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE === null) {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
        new Date(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN);
    } else {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
        new Date(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE);
      console.log(new Date(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE));
    }

    // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
    //   this.booleanBodega = true;
    //   this.tabVarios = false;
    //   this.tabRetenciones = false;
    //   this.tabOtrosGastos = false;
    //   this.tabFormaPago = false;
    //   this.tabFormaPagoDev = false;
    //   this.tabEstadoCuentaAnt = false;
    //   this.tabDebitoBancario = false;
    // } else {
    //   this.booleanBodega = false;
    //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NDA') {
    //     this.tabVarios = false;
    //     this.tabRetenciones = false;
    //     this.tabOtrosGastos = false;
    //     this.tabFormaPago = false;
    //     this.tabEstadoCuentaAnt = false;
    //     this.tabDebitoBancario = false;
    //     this.tabFormaPagoDev = false;
    //   } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
    //     this.tabVarios = true;
    //     this.tabRetenciones = false;
    //     this.tabOtrosGastos = false;
    //     this.tabFormaPago = false;
    //     this.tabEstadoCuentaAnt = false;
    //     this.tabDebitoBancario = false;
    //     this.tabFormaPagoDev = false;

    //     const resTrnRes = await this.cxctrnretencionesService.obtenerDocumentosProm('DT',
    //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);

    //     this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
    //     resTrnRes.map((resumen) => {
    //       console.log(resumen);
    //       let objRes: Cxctrnresumen;
    //       objRes = {};
    //       objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
    //       objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    //       objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
    //       objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
    //       objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
    //       objRes.TRNCOBRO_IMPORTE = resumen.TRNDOCUMENTO_IMPORTE;
    //       objRes.VEN_CODIGO = resumen.VEN_CODIGO;
    //       objRes.SELECCIONADO = true;
    //       objRes.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
    //       this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].push(objRes);
    //     });

    //     this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
    //     this.cxctrnretencionesService.calcularTotalDoc();

    //     const varios = await this.cxctrnretencionesService.obtenerVariosProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
    //     this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = [];
    //     if (varios !== null) {
    //       varios.map((vario) => {
    //         if (Number(vario.TRNVARIOS_IMPORTE) < 0) {
    //           vario.DEBE = 0;
    //           vario.HABER = Math.abs(Number(vario.TRNVARIOS_IMPORTE));
    //         } else if (Number(vario.TRNVARIOS_IMPORTE) >= 0) {
    //           vario.DEBE = vario.TRNVARIOS_IMPORTE;
    //           vario.HABER = 0;
    //         }
    //       });
    //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = varios;
    //     }
    //   } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
    //     this.tabFormaPagoDev = true;
    //     this.tabRetenciones = false;
    //     this.tabOtrosGastos = false;
    //     this.tabEstadoCuentaAnt = false;
    //     this.tabVarios = false;
    //     this.tabDebitoBancario = false;
    //     this.tabFormaPago = false;

    //     const resTrnRes = await this.cxctrnretencionesService.obtenerDocumentosProm('DT', this.cxctrnretencionesService.encabezadoCarteraActivaarray
    //     [this.indicador].TRNCOBRO_NRODOC);

    //     this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
    //     resTrnRes.map((resumen) => {
    //       let objRes: Cxctrnresumen;
    //       objRes = {};
    //       console.log(resumen);
    //       objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
    //       objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    //       objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
    //       objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
    //       objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
    //       objRes.TRNCOBRO_IMPORTE = resumen.TRNDOCUMENTO_IMPORTE;
    //       objRes.VEN_CODIGO = resumen.VEN_CODIGO;
    //       objRes.SELECCIONADO = true;
    //       objRes.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
    //       this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].push(objRes);
    //     });
    //     this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
    //     this.cxctrnretencionesService.calcularTotalDoc();

    //     const formapago = await this.cxctrnretencionesService.obtenerFormadePagoDevProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray
    //     [this.indicador].TRNCOBRO_NRODOC);
    //     console.log(formapago);
    //     const arreglo = [];
    //     for (const fpagodev of formapago) {
    //       fpagodev.TRNCAJ_FECHA = this.datePipe.transform(fpagodev.TRNCAJ_FECHA, 'dd/MM/yyyy');
    //       fpagodev.TRNCAJ_IMPORTE = Math.abs(fpagodev.TRNCAJ_IMPORTE);
    //       arreglo.push(fpagodev);
    //     }

    //     const res2 = await this.cxctrnretencionesService.obtenerFormadePagoDevBancoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray
    //     [this.indicador].TRNCOBRO_NRODOC);
    //     for (const detalle of res2) {
    //       detalle.TRNCAJ_FECHA = this.datePipe.transform(detalle.TRNBAN_FECHA, 'dd/MM/yyyy');
    //       detalle.TRNCAJ_IMPORTE = Math.abs(detalle.TRNBAN_IMPORTE);
    //       detalle.TRNCAJ_TIPO = detalle.TRNBAN_TIPO;
    //       detalle.TRNCAJ_NUMERO = detalle.TRNBAN_NUMERO;
    //       detalle.CAJ_CODIGO = '';
    //       detalle.TRNCAJ_DESCRIPCION = detalle.TRNBAN_CONCEPTO;
    //       const banN = await this.cxctrnretencionesService.erBanbancoProm(detalle.BAN_CODIGO);
    //       detalle.BAN_BANCO = banN[0].BAN_BANCO;
    //       arreglo.push(detalle);
    //     }

    //     this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = arreglo;
    //     // this.cxctrnretencionesService.calcularTotalFormaPagoDev();
    //     await this.efectivoDepositado('COLOR');
    //     await this.chequeDepositado('COLOR');
    //     await this.depositoConciliado('COLOR');
    //     await this.tarjetaConciliada('COLOR');
    //     this.cxctrnretencionesService.calcularTotalFormaPagoDev();

    //   } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
    //     this.tabFormaPago = true;
    //     this.tabEstadoCuentaAnt = true;
    //     this.tabRetenciones = false;
    //     this.tabOtrosGastos = false;
    //     this.tabVarios = false;
    //     this.tabFormaPagoDev = false;
    //     this.tabDebitoBancario = false;

    //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC != null) {
    //       const dblSaldoAnt = await this.cxctrnretencionesService.obtenerSaldoFactura(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC) * (-1);
    //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = Number(dblSaldoAnt).toFixed(2);

    //       if (this.saldoInicial === false) {
    //         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE).toFixed(2);
    //         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE).toFixed(2);
    //         const dblTotalCobrar = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC) - Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALRET)
    //           - Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG);
    //         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = Number(dblTotalCobrar).toFixed(2);
    //       }
    //     }
    //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 0) {
    //       const formapago = await this.cxctrnretencionesService.obtenerFormadePagoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray
    //       [this.indicador].TRNCOBRO_NRODOC);
    //       console.log(formapago);
    //       if (formapago !== null) {
    //         const arreglo = [];
    //         for (const form of formapago) {
    //           form.TRNPAGO_FECHA = this.datePipe.transform(form.TRNPAGO_FECHA, 'dd/MM/yyyy');
    //           if (form.TRNPAGO_TIPO === 'CH') {
    //             const banN = await this.cxctrnretencionesService.erBanclinombreProm(form.BANCLI_CODIGO);
    //             form.BANCLI_NOMBRE = banN[0].BANCLI_NOMBRE;
    //           } else if (form.TRNPAGO_TIPO === 'TA') {
    //             const banN = await this.cxctrnretencionesService.erTarDescripcion(form.BANCLI_CODIGO);
    //             if (banN !== null) {
    //               form.BANCLI_NOMBRE = banN[0].TAR_DESCRIPCION;
    //             }
    //           } else if (form.TRNPAGO_TIPO === 'DP') {
    //             const banN = await this.cxctrnretencionesService.erBanbancoProm(form.BANCLI_CODIGO);
    //             form.BANCLI_NOMBRE = banN[0].BAN_BANCO;
    //           }
    //           arreglo.push(form);
    //         }
    //         this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = arreglo;
    //         this.cxctrnretencionesService.calcularTotalFormaPago();
    //       }
    //     }

    //     await this.consultarECAnticipo();
    //     await this.efectivoDepositado('COLOR');
    //     await this.chequeDepositado('COLOR');
    //     await this.depositoConciliado('COLOR');
    //     await this.tarjetaConciliada('COLOR');
    //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDOANC = await this.obtenerSaldoANC(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);

    //   } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
    //     this.tabRetenciones = false;
    //     this.tabOtrosGastos = false;
    //     this.tabFormaPago = false;
    //     this.tabEstadoCuentaAnt = false;
    //     this.tabFormaPagoDev = false;
    //     this.botonXML = false;
    //     this.botonReenviarCorreo = false;

    //     const varios = await this.cxctrnretencionesService.obtenerVariosProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
    //     this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = [];
    //     if (varios.length > 0) {
    //       const arreglo = [];
    //       for (const vario of varios) {
    //         if (Number(vario.TRNVARIOS_IMPORTE) < 0) {
    //           vario.DEBE = 0;
    //           vario.HABER = Math.abs(Number(vario.TRNVARIOS_IMPORTE));
    //         } else if (Number(vario.TRNVARIOS_IMPORTE) >= 0) {
    //           vario.DEBE = vario.TRNVARIOS_IMPORTE;
    //           vario.HABER = 0;
    //         }
    //         arreglo.push(vario);
    //       }
    //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = arreglo;
    //       this.tabVarios = true;
    //       this.tabDebitoBancario = false;
    //     } else {
    //       this.tabVarios = false;
    //       this.tabDebitoBancario = true;
    //       const ban = await this.cxctrnretencionesService.obtenerNombreBanProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO);
    //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO = ban[0].BAN_BANCO;

    //       const debito = await this.cxctrnretencionesService.obtenerDebitoBancarioProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray
    //       [this.indicador].TRNCOBRO_NRODOC);
    //       const arreglo = [];
    //       for (const deb of debito) {
    //         deb.TRNPAGO_FECHA = this.datePipe.transform(deb.TRNPAGO_FECHA, 'dd/MM/yyyy');
    //         deb.SELECCIONADO = true;
    //         arreglo.push(deb);
    //       }
    //       this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador] = arreglo;
    //       this.cxctrnretencionesService.calcularTotalND();
    //     }

    //   } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
    //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
    //     this.tabRetenciones = true;
    //     this.tabOtrosGastos = true;
    //     this.tabFormaPago = true;
    //     this.tabEstadoCuentaAnt = false;
    //     this.tabVarios = false;
    //     this.tabDebitoBancario = false;
    //     this.tabFormaPagoDev = false;
    //     const resTrnRes = await this.cxctrnretencionesService.obtenerDocumentosProm('DT', this.cxctrnretencionesService.encabezadoCarteraActivaarray
    //     [this.indicador].TRNCOBRO_NRODOC);

    //     this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
    //     if (resTrnRes !== null) {
    //       for (const resumen of resTrnRes) {
    //         let objRes: Cxctrnresumen;
    //         objRes = {};
    //         objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
    //         objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    //         objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
    //         objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
    //         objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
    //         objRes.TRNCOBRO_IMPORTE = String(resumen.TRNDOCUMENTO_IMPORTE.toFixed(2));
    //         objRes.VEN_CODIGO = resumen.VEN_CODIGO;
    //         objRes.STRCUOTAFAC = resumen.TRNDOCUMENTO_REF;
    //         objRes.SELECCIONADO = true;
    //         objRes.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
    //         this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].push(objRes);
    //       }

    //     }
    //     if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
    //       this.numeroFactura = this.cxctrnretencionesService.cxctrnresumenarray[this.indicador][0].TRNCOBRO_NRODOC;
    //     }

    //     this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
    //     this.cxctrnretencionesService.calcularTotalDoc();

    //     const retenciones = await this.cxctrnretencionesService.obtenerRetencionesProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray
    //     [this.indicador].TRNCOBRO_NRODOC);
    //     console.log(retenciones);
    //     this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = [];
    //     if (retenciones.length !== 0) {
    //       this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = retenciones;
    //       //CDPJ
    //       for (const itemRet of this.cxctrnretencionesService.cxctrnretencionarray[this.indicador]) {
    //         itemRet.NUEVALINEA=itemRet.LINEA;
    //       }
    //       //CDPJ
    //       this.cxctrnretencionesService.calcularTotalRetenido(-1);
    //     }

    //     const otrosG = await this.cxctrnretencionesService.obtenerOtrosGastosProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
    //     if (otrosG !== null) {
    //       this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = otrosG;
    //       this.cxctrnretencionesService.calcularTotalOtrosG();
    //     }

    //     const formapago = await this.cxctrnretencionesService.obtenerFormadePagoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray
    //     [this.indicador].TRNCOBRO_NRODOC);
    //     console.log(formapago);
    //     if (formapago !== null) {
    //       const arreglo = [];
    //       for (const form of formapago) {
    //         form.TRNPAGO_FECHA = this.datePipe.transform(form.TRNPAGO_FECHA, 'dd/MM/yyyy');

    //         if (form.TRNPAGO_TIPO === 'CH') {
    //           const banN = await this.cxctrnretencionesService.erBanclinombreProm(form.BANCLI_CODIGO);
    //           if (banN !== null) {
    //             form.BANCLI_NOMBRE = banN[0].BANCLI_NOMBRE;
    //           }
    //         } else if (form.TRNPAGO_TIPO === 'TA') {
    //           const banN = await this.cxctrnretencionesService.erTarDescripcion(form.BANCLI_CODIGO);
    //           if (banN !== null) {
    //             form.BANCLI_NOMBRE = banN[0].TAR_DESCRIPCION;
    //           }
    //         } else {
    //           const banN = await this.cxctrnretencionesService.erBanbancoProm(form.BANCLI_CODIGO);
    //           if (banN !== null) {
    //             form.BANCLI_NOMBRE = banN[0].BAN_BANCO;
    //           }
    //         }
    //         arreglo.push(form);
    //       }

    //       this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = arreglo;
    //       this.cxctrnretencionesService.calcularTotalFormaPago();
    //       await this.efectivoDepositado('COLOR');
    //       await this.chequeDepositado('COLOR');
    //       await this.depositoConciliado('COLOR');
    //       await this.tarjetaConciliada('COLOR');
    //     }

    //   } else 
      
      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
        this.tabRetenciones = true;
        this.tabVarios = false;
        this.tabOtrosGastos = false;
        this.tabFormaPago = false;
        this.tabEstadoCuentaAnt = false;
        this.tabDebitoBancario = false;
        this.tabFormaPagoDev = false;

        const resTrnRes = await this.cxctrnretencionesService.obtenerDocumentosProm('DT', this.cxctrnretencionesService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);
        this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];

        for (const resumen of resTrnRes) {
          let objRes: Cxctrnresumen;
          objRes = {};
          objRes.TRNCOBRO_NRODOC = resumen.TRNDOCUMENTO_NRODOC;
          this.numeroFactura = objRes.TRNCOBRO_NRODOC;
          objRes.TRNCOBRO_FECHATRN = this.datePipe.transform(resumen.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          objRes.TRNCOBRO_FECHAVENCE = this.datePipe.transform(resumen.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
          objRes.TRNCOBRO_CONCEPTO = resumen.TRNCOBRO_CONCEPTO;
          objRes.TRNCOBRO_SALDOINI = resumen.SALDOFAC;
          objRes.TRNCOBRO_IMPORTE = Number(resumen.TRNDOCUMENTO_IMPORTE).toFixed(2);
          objRes.VEN_CODIGO = resumen.VEN_CODIGO;
          objRes.SELECCIONADO = true;
          objRes.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
          this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].push(objRes);
        }
        if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
          this.numeroFactura = this.cxctrnretencionesService.cxctrnresumenarray[this.indicador][0].TRNCOBRO_NRODOC;
        }

        this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
        this.cxctrnretencionesService.calcularTotalDoc();

        const tiporet = await this.cxctrnretencionesService.obtenerTipoRetProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        if (tiporet.length !== 0) {
          if (tiporet[0].TRNRETENCION_TIPORET === 'E') {
            this.retencionElect = true;
          } else {
            this.retencionElect = false;
          }
        }

        const retenciones = await this.cxctrnretencionesService.obtenerRetencionesProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray
        [this.indicador].TRNCOBRO_NRODOC);
        if (retenciones.length !== 0) {
          this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = retenciones;
          //CDPJ
          for (const itemRet of this.cxctrnretencionesService.cxctrnretencionarray[this.indicador]) {
            itemRet.ENCFAC_NUMERO=this.numeroFactura;
          }
          //CDPJ
          this.cxctrnretencionesService.calcularTotalRetenido(-1);
        }
      }
    //}

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 1) {
      this.saldoInicial = true;
      this.bolsaldoIni = true;
      this.tabVarios = false;
      this.tabDebitoBancario = false;
      this.tabFormaPagoDev = false;
      this.tabRetenciones = false;
      this.tabOtrosGastos = false;
      this.tabEstadoCuentaAnt = false;
      //CDPJ
      if(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC'){
        this.tabEstadoCuentaAnt = true;
      }
      //CDPJ
      this.tabFormaPago = false;
    } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI === 0) {
      this.saldoInicial = false;
    }

    const resApl = await this.cxctrnretencionesService.getOrigenProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO);
    if (resApl.length !== 0) {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-' + resApl[0].APLORG_NOMBRE;
    } else {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ORIGEN =
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO + '-';
    }

    const resReal = await this.cxctrnretencionesService.getRealizadoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION);
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].REALIZADO = resReal[0].NOMBRE;

    // this.bolestcta = false;
    // this.bolsaldo = false;

    // const bolCierre2 = await this.documentoCierre('');

    // if (await this.chequeConciliado('') || await this.chequeDepositado('')
    //   || await this.depositoConciliado('') || await this.efectivoDepositado('')
    //   || await this.tarjetaConciliada('') || bolCierre2) {
    //   this.btneditConcil = false;
    //   this.editConcil = false;
    //   document.getElementById('divPrincipal').style.pointerEvents = 'none';
    //   document.getElementById('divPrincipal').style.opacity = '0.7';
    // } else {
    //   this.btneditConcil = true;
    //   this.editConcil = true;
    //   document.getElementById('divPrincipal').style.pointerEvents = 'all';
    //   document.getElementById('divPrincipal').style.opacity = '1.0';
    // }
    //   ///GSRF
    // this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(async(res)  => {
    //   console.log('----***',res)
    //   this.asiento = res[0].ASI_NRO;
    //   if (this.asiento !== null) {
    //     const det = await this.cxctrnretencionesService.getDetConencasilProm(this.asiento, 'CXC');
    //     console.log('----***',det);
    //     let sum = 0;
    //     for (const aux of det) {
    //       sum=aux.DETASI_IMPORTE + sum;
    //     }
    //     console.log('----new',Number(sum.toFixed(2)));
    //     if(Number(sum.toFixed(2)) !== 0){
    //       document.getElementById("errasiento").style.display = '';
    //     }else{
    //       document.getElementById("errasiento").style.display = 'none';
    //     }
    //   }else{
    //     document.getElementById("errasiento").style.display = 'none';
    //   } 
    // });
    //GSRF
  }



  buscarCartera() {
    this.editpagofr = true;
    this.opcion = 'BUSCAR';
    this.busquedaCompuesta = false;
    this.types = [
      { label: 'NÚMERO', value: 'TRNCOBRO_NRODOC' },
      { label: 'TIPO DOC', value: 'TRNCOBRO_TIPODOC' },
      { label: 'FECHA TRANS', value: 'TRNCOBRO_FECHATRN' },
      { label: 'COD_CLIENTE', value: 'CLI_CODIGO' },
      { label: 'CLIENTE', value: 'CLI_NOMBRE' },
      { label: 'CONCEPTO', value: 'TRNCOBRO_CONCEPTO' },
      { label: 'IMPORTE', value: 'TRNCOBRO_IMPORTE' },
      { label: 'FECHA VENC', value: 'TRNCOBRO_FECHAVENCE' },
      { label: 'REF_EXTERNA', value: 'TRNCOBRO_REFERENCIAEXTERNA' },
      { label: 'COMPROBANTE', value: 'TRNCOBRO_NROCOMPROBANTE' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'CXC_TRNCOBRO';
    this.consulta = 'CXC_TRNCOBRO.*, VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO, \'01\') AS CLI_NOMBRE';
    this.where = ' trncobro_tipodoc=\'RT\'';
    this.spin = true;
    this.busquedaSer.busquedaCXCTrnRet1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((registro) => {
        if (registro.TRNCOBRO_IMPORTE !== null) {
          registro.TRNCOBRO_IMPORTE = Number(registro.TRNCOBRO_IMPORTE).toFixed(2);
        }
        if (registro.TRNCOBRO_FECHATRN !== null) {
          registro.TRNCOBRO_FECHATRN = this.datePipe.transform(registro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        } else {
          registro.TRNCOBRO_FECHATRN = '';
        }
        if (registro.TRNCOBRO_FECHAVENCE !== null) {
          registro.TRNCOBRO_FECHAVENCE = this.datePipe.transform(registro.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
        } else {
          registro.TRNCOBRO_FECHAVENCE = '';
        }
      });
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  manejarSeleccion(opcion) {
    if (this.opcion === 'BUSCAR') {
      this.listaClientes.map((cliente) => {
        if (cliente.CLI_CODIGO === opcion.CLI_CODIGO) {
          this.indiceCliente = this.listaClientes.indexOf(cliente);
          this.cxctrnretencionesService.getListaReferencias(cliente.CLI_CODIGO).subscribe((ref) => {
            this.listaReferencias = ref;
            this.listaReferencias.map((refL) => {
              if (refL.TRNCOBRO_REFERENCIAEXTERNA === opcion.TRNCOBRO_NRODOC) {
                this.indiceDocRef = this.listaReferencias.indexOf(refL);
              }
            });
          });
        }
      });
      this.obtenerDatos(opcion.TRNCOBRO_NRODOC);
      this.displayDialogBusquedaFast = false;
    } else if (this.opcion === 'BUSQUEDACLI') {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO = opcion.CLI_CODIGO;
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE = opcion.CLI_NOMBRE;
      this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((res) => {
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = Number(res[0].SALDO).toFixed(2);
      });
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO === null ? '' : opcion.VEN_CODIGO;
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = opcion.VEN_NOMBRE === null ? '' : opcion.VEN_NOMBRE;
      this.displayDialogBusquedaFast = false;
      this.btnNuevoGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
      this.busquedaSer.encontrarRegistro44(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((resCli) => {
        if (resCli !== null) {
          if (resCli[0].CLI_CONREGMIC === 'S'
            || resCli[0].CLI_AGENTERET === 'S') {
            if (resCli[0].CLI_CONREGMIC === 'S'
              && resCli[0].CLI_AGENTERET === 'S') {
              this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
            } else {
              if (resCli[0].CLI_CONREGMIC === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
              }
              if (resCli[0].CLI_AGENTERET === 'S') {
                this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
              }
            }
          } else {
            this.lblAplicaRegMicroempresas = '';
          }
        }
      });
      document.getElementById('vendedor').focus();
      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== '') {
        this.cambiarTipoCartera();
      }
    } 
    // else if (this.opcion === 'BUSQUEDACLIFAC') {
    //   this.codClienteFac = opcion.CLI_CODIGO;
    //   this.nombreClienteFac = opcion.CLI_NOMBRE;
    //   this.cxctrnretencionesService.getDocCobrados(this.codClienteFac, this.nroFacCancelada).subscribe((facpendientes) => {
    //     facpendientes.map((facturas) => {
    //       facturas.TRNCOBRO_FECHATRN = this.datePipe.transform(facturas.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    //     });
    //     this.cxctrnretencionesService.cxctrnfaccanceladasarray[this.indicador] = facpendientes;
    //   });
    //   this.displayDialogBusquedaFast = false;
    //   document.getElementById('nroFacCanc').focus();
    // } 
    // else if (this.opcion === 'BUSQUEDABOD') {
    //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = opcion.BOD_CODIGO;
    //   this.displayDialogBusquedaFast = false;
    //   this.btnNuevo = true;
    //   this.btnGuardar = false;
    //   this.btnBorrar = false;
    //   this.botonPrimero = true;
    //   this.botonSiguiente = true;
    //   this.botonAnterior = true;
    //   this.botonUltimo = true;
    //   document.getElementById('concepto').focus();
    // } else 
    if (this.opcion === 'BUSQUEDAVEN') {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = opcion.VEN_CODIGO;
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = opcion.VEN_NOMBRE;
      this.displayDialogBusquedaFast = false;
      this.btnNuevo = true;
      this.btnGuardar = false;
      this.btnBorrar = false;
      this.botonPrimero = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.botonUltimo = true;
    } 
    // else if (this.opcion === 'BUSQUEDABAN') {
    //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO = opcion.BAN_CODIGO;
    //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO = opcion.BAN_BANCO;
    //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO = opcion.CON_CODIGO;
    //   this.displayDialogBusquedaFast = false;
    //   this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador] = [];
    //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO !== null || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO !== '') {
    //     this.tabVarios = false;
    //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO !== '') {
    //       this.cxctrnretencionesService.obtenerChequesCli(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((cheques) => {
    //         if (cheques !== null) {
    //           cheques.map((cheque) => {
    //             const obj: Cxctrndebitobancario = {};
    //             obj.SELECCIONADO = false;
    //             obj.TRNPAGO_FECHA = this.datePipe.transform(cheque.TRNPAGO_FECHA, 'dd/MM/yyyy');
    //             obj.TRNPAGO_TIPO = cheque.TRNPAGO_TIPO;
    //             obj.TRNPAGO_NUMERO = cheque.TRNPAGO_NUMERO;
    //             obj.TRNPAGO_IMPORTE = Number(cheque.TRNPAGO_IMPORTE).toFixed(2);
    //             obj.TRNPAGO_NROCTA = cheque.TRNPAGO_NROCTA;
    //             obj.BANCLI_CODIGO = cheque.BANCLI_CODIGO;
    //             obj.BANCLI_NOMBRE = cheque.BANCLI_NOMBRE;
    //             obj.TRNCOBRO_NRODOC = cheque.TRNCOBRO_NROCOMPROBANTE;
    //             this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador].push(obj);
    //           });
    //         }
    //         this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador], this.defaultColumnDefCarteraDebito.objeto);
    //       });
    //     }
    //   } else {
    //     this.tabVarios = true;
    //   }
    // }

    if (this.opcion === 'TRNANEX_IDCRETRI') {
      this.anexoSeleccionado.TRNANEX_IDCRETRI = opcion.CODSRI_CODIGO;
      this.anexoSeleccionado.DESCIDCRETRI = opcion.CODSRI_DESCRIPCION;
      document.getElementById('TRNANEX_MONTOIVA').focus();
    }

    if (this.opcion === 'TRNANEX_TIPOSRITARCERO') {
      this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = opcion.REF_CODIGO;
      document.getElementById('TRNANEX_BASEIMPGRABADAIVA').focus();
    }

    if (this.opcion === 'TRNANEX_TIPOSRIGRAVADA') {
      this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = opcion.REF_CODIGO;
      document.getElementById('TRNANEX_MONTOIVA').focus();
    }

    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }

  seleccionDocumento(valor) {
    /*if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
      
      if (valor.object !== undefined) {
        this.numeroFactura = valor.object.TRNCOBRO_NRODOC;
      }
    }*/
  }

  async seleccionRetencion(valor) {
    if (valor === 'nuevo') {
      this.nuevaRetencion();
    } else if (valor === 'eliminar') {
      this.confirmarEliminarRetencion();
    } else if (valor === 'cancelar') {
      this.cancelarRetencion();
    }else if (valor === 'subirxmlret'){
      this.displayDialogXml=true;
      this.claveacceso=''
      
    }
    if (valor.object !== undefined) {
      this.detalleRetencionSeleccionado = valor.object;
    }
  }
  xmlElementToObject(element) {
    const obj = {};
  
    if (element.nodeType === Node.TEXT_NODE) {
      return element.nodeValue.trim();
    }
  
    if (element.nodeType === Node.ELEMENT_NODE) {
      if (element.attributes.length > 0) {
        for (let i = 0; i < element.attributes.length; i++) {
          const attribute = element.attributes[i];
          obj[attribute.name] = attribute.value;
        }
      }
  
      if (element.childNodes.length === 1 && element.firstChild.nodeType === Node.TEXT_NODE) {
        obj[element.tagName] = element.firstChild.nodeValue.trim();
      } else if (element.childNodes.length > 0) {
        for (let i = 0; i < element.childNodes.length; i++) {
          const child = element.childNodes[i];
          if (child.nodeType === Node.ELEMENT_NODE) {
            if (obj[child.tagName] === undefined) {
              obj[child.tagName] = [];
            }
            obj[child.tagName].push(this.xmlElementToObject(child));
          }
        }
      }
    }
  
    return obj;
  }
  readComprobante({descarga}){
      return descarga.autorizacion.comprobante
  }
  async cargardetallexml(){
    this.spin=true;
    this.displayDialogXml=false;
    let estab='';
    let ptoEmi=''
    let secuencial=''
    let numret=''
    let retencion=[]
    const data=await this.cxctrnretencionesService.getxml(this.claveacceso);
      console.log(data)
      //console.log(this.data.autorizacion.comprobante)
      //console.log(typeof this.data.autorizacion.comprobante)
      if(data !== null ){
        //for (const data1 of data){
          console.log(data['autorizacion'].estado)
          //console.log(data.autorizacion.estado)
          if (data['autorizacion'].estado === 'RECHAZADA'){
            this.message.add({
              key: 'cxctrnretenciones',
              severity: 'error',
              summary: 'Información',
              detail: data['autorizacion'].mensajes.mensaje.informacionAdicional
            });
            this.spin=false;
            return;
          }
        //} 
        let comprobante= await this.readComprobante({ descarga: data })
      console.log('comprobante', comprobante)
      const parser = new DOMParser()
      const xmlDoc=parser.parseFromString(comprobante,'text/xml')
      const objetoJavaScript = this.xmlElementToObject(xmlDoc.documentElement);
      console.log('<<<<<<<<<<',objetoJavaScript)
      console.log(objetoJavaScript.infoTributaria[0].ruc[0].ruc)
      console.log(objetoJavaScript.infoTributaria[0].ruc)
      estab=objetoJavaScript.infoTributaria[0].estab[0].estab
      ptoEmi=objetoJavaScript.infoTributaria[0].ptoEmi[0].ptoEmi
      secuencial=objetoJavaScript.infoTributaria[0].secuencial[0].secuencial
      numret=estab+ptoEmi+secuencial;
      //retencion=objetoJavaScript.docsSustento[0].docSustento[0].retenciones[0].retencion;
      if(objetoJavaScript.version === '2.0.0'){
        retencion=objetoJavaScript.docsSustento[0].docSustento[0].retenciones[0].retencion;
      }else if(objetoJavaScript.version === '1.0.0') {
        retencion=objetoJavaScript.impuestos[0].impuesto;
      }
      if(objetoJavaScript.infoTributaria[0].ruc[0].ruc !== this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO){
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Información',
          detail: 'La retención que intenta cargar no le pertenece al cliente seleccionado'
        });
        this.spin=false;
        return;
      }if(objetoJavaScript.infoCompRetencion[0].identificacionSujetoRetenido[0].identificacionSujetoRetenido !== this.datosfe.COMRUCI){
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Información',
          detail: 'La retención que intenta cargar no le pertenece al RUC de la empresa'
        });
        this.spin=false;
        return;
      }

      await this.creardetalleretencionxml(retencion,numret);
      }else{
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Información',
          detail: 'Ocurrio un error durante la descarga del documento del SRI.'
        });
        this.spin=false;
      }
      this.spin=false;
      // const obj=[]
      // const raiz=xmlDoc.documentElement
      // for(const elemento  of raiz.children){
      //   obj.push(elemento)
      // }
      //const parseStringAsync = promisify();

      // parseStringAsync(parseStringAsync).then(result => {
      //   const content = result.autorizacion.comprobante[0];
      //   Parse el string XML a un objeto JavaScript
      //  parseStringAsync(content).then(result1 => {
      //     const caracteresSubstring = 'archivos[i].substring(8, 10)';
      //     if(result1 !== null){
      //       console.log('-------',result1)
      //     }
      //   })
      // })
  }
  async creardetalleretencionxml(retencion,numret){
    
    // await Promise.all(retencion.map(async ret => {//
    for(let ret of retencion ){
      console.log('1--------',ret)
      await this.nuevaRetencion();
      const data =await this.cxctrnretencionesService.encnomretencion(ret.codigoRetencion[0].codigoRetencion);
      const data2=await this.cxctrnretencionesService.encconcodigoretencion(ret.codigoRetencion[0].codigoRetencion);
      const data3=await this.cxctrnretencionesService.enccodigoretencion(ret.codigoRetencion[0].codigoRetencion);
      console.log('1',data)
      console.log('2',data2)
      console.log('3,',data3)
     if(data3 !== null && data3!== undefined){
      if(data3[0] !== null && data3[0] !== undefined && data3[0] !== ''){
        if(data3[0].RETENCION_CODIGO !== null && data3[0].RETENCION_CODIGO !== undefined && data3[0].RETENCION_CODIGO !== ''){
          
          const item: Cxctrnretencion=this.nuevoitem
          console.log(this.nuevoitem);
          item.RETENCION_CODIGO= data3[0].RETENCION_CODIGO
          item.TRNRETENCION_DESCRIPCION=''
          if(data !== null && data !== null ){
            item.TRNRETENCION_DESCRIPCION= data[0].RETENCION_NOMBRE
          }
          item.TRNRETENCION_NRO=numret
          item.TRNRETENCION_BASE= Number(ret.baseImponible[0].baseImponible)
          item.TRNRETENCION_PORCENTAJE= Number(ret.porcentajeRetener[0].porcentajeRetener)
          item.TRNRETENCION_TOTALRETENIDO= Number(ret.valorRetenido[0].valorRetenido)
          item.ENCFAC_NUMERO= this.numeroFactura
          item.CLI_CODIGO= this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO
          item.CEN_CODIGO= ''
          item.CON_CODIGO= ''
          if(data2 !== null && data2 !== null){
            item.CON_CODIGO= data2[0].CON_CODIGO_VTA
          }
          item.TRNRETENCION_ORIGEN= 'CXC'
          // item.LINEA= String(linea + 1),
          item.NUEVALINEA=0//CDPJ
         console.log('baseimpo', ret.baseImponible[0].baseImponible)
         this.cxctrnretencionesService.calcularTotalRetenido(-1);
         this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto);
  
         }
         else{
         this.message.add({
            key: 'cxctrnretenciones',
            severity: 'error',
            summary: 'Información',
            detail: 'No existe el CodSRi.'+ret.codigoRetencion[0].codigoRetencion+' Dentro del maestro de retenciones'
          });
          this.eliminarLineaRetEnBlanco();
         }
      }else{
        this.message.add({
           key: 'cxctrnretenciones',
           severity: 'error',
           summary: 'Información',
           detail: 'No existe el CodSRi.'+ret.codigoRetencion[0].codigoRetencion+' Dentro del maestro de retenciones'
         });
         this.eliminarLineaRetEnBlanco();
        }
      
     }
    }
    //}));
    
  }
  cancelardetallexml(){
    this.displayDialogXml=false;
      this.claveacceso=''
  }
  // seleccionGastos(valor) {
  //   if (valor === 'nuevo') {
  //     this.nuevosGastos();
  //   } else if (valor === 'eliminar') {
  //     this.confirmarEliminarOtrosG();
  //   } else if (valor === 'cancelar') {
  //     this.cancelarOtrosG();
  //   }
  //   if (valor.object !== undefined) {
  //     this.detalleOtrasFSeleccionada = valor.object;
  //   }
  // }

  // seleccionFormaPago(valor) {
  //   if (valor === 'nuevo') {
  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
  //       return;
  //     }
  //     this.nuevaFormaPago();
  //   } else if (valor === 'eliminar') {
  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
  //       return;
  //     }
  //     this.confirmarEliminarFormaPago();
  //   } else if (valor === 'cancelar') {
  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO !== 'CXC') {
  //       return;
  //     }
  //     this.cancelarFormaPago();
  //   }
  //   if (valor.object !== undefined) {
  //     this.detalleFormaPago = valor.object;
  //   }
  // }

  // seleccionFormaPagoDev(valor) {
  //   if (valor === 'nuevo') {
  //     this.nuevaFormaPagoDev();
  //   } else if (valor === 'eliminar') {
  //     this.confirmarEliminarFormaPagoDev();
  //   } else if (valor === 'cancelar') {
  //     this.cancelarFormaPagoDev();
  //   }
  //   if (valor.object !== undefined) {
  //     this.detalleFormaPagoDev = valor.object;
  //   }
  // }

  // seleccionVarios(valor) {
  //   if (valor === 'nuevo') {
  //     this.nuevoVario();
  //   } else if (valor === 'eliminar') {
  //     this.confirmarEliminarVarios();
  //   } else if (valor === 'cancelar') {
  //     this.cancelarVarios();
  //   }
  //   if (valor.object !== undefined) {
  //     this.detalleVariosSeleccionado = valor.object;
  //   }
  // }

  // seleccionDebito() {

  // }

  // seleccionAnt() {

  // }

  // nuevoVario() {
  //   this.editpagofr = false;
  //   this.btnNuevoGrid = true;
  //   this.btnGuardarGrid = false;
  //   this.btnBorrarGrid = false;
  //   this.btnCancelarGrid = false;
  //   const linea = this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length;
  //   const nuevodetalle = {
  //     CON_CODIGO: '',
  //     CON_NOMBRE: '',
  //     TRNVARIOS_IMPORTE: '',
  //     DEBE: '',
  //     HABER: '',
  //     TRNVARIOS_LINEA: 0,
  //     CLI_CODIGO: this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
  //     TRNCOBRO_NRODOC: '',
  //     BAN_CODIGO: '',
  //     TRNBAN_TIPO: '',
  //     TRNBAN_NUMERO: '',
  //     TRNCOBRO_NROCOMPROBANTE: '',
  //     LINEA: String(linea + 1),
  //     CEN_CODIGO:''
  //   };
  //   if (this.detalleVariosSeleccionado !== undefined && this.detalleVariosSeleccionado !== null) {
  //     const indi = this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].indexOf(this.detalleVariosSeleccionado);
  //     if (indi === -1) {
  //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].push(nuevodetalle);
  //       this.detalleVariosSeleccionado = this.cxctrnretencionesService.cxctrnvariosarray[this.indicador][this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length - 1];
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
  //       this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraVarios.objeto].setFocusedCell(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length - 1, 'CON_CODIGO');
  //       this.tabDebitoBancario = false;
  //     } else {
  //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
  //       this.aggrid.refreshaggridindex(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto, indi);
  //       this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraVarios.objeto].setFocusedCell(indi + 1, 'CON_CODIGO');
  //       this.tabDebitoBancario = false;
  //     }
  //   }
  // }

  // confirmarEliminarVarios() {
  //   this.confirmationService.confirm({
  //     message: 'Esta seguro que desea eliminar el registro seleccionado',
  //     header: 'Confirmación',
  //     icon: 'pi pi-exclamation-triangle',
  //     key: 'conftrnretenciones',
  //     acceptLabel: 'Si',
  //     rejectLabel: 'No',
  //     accept: () => {
  //       this.confirmationService.close();
  //       this.eliminarVarios();
  //     },
  //     reject: () => {
  //       this.confirmationService.close();
  //     }
  //   });
  // }

  // eliminarVarios() {
  //   if (this.detalleVariosSeleccionado !== undefined) {
  //     this.editpagofr = false;
  //     this.btnNuevoGrid = false;
  //     this.btnGuardarGrid = false;
  //     this.btnBorrarGrid = false;
  //     this.btnCancelarGrid = false;
  //     if (this.detalleVariosSeleccionado.TRNVARIOS_LINEA === 0) {
  //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].filter((val, j) => j !==
  //         this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].indexOf(this.detalleVariosSeleccionado));
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
  //       if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
  //         this.cxctrnretencionesService.calcularTotalND();
  //         if (this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length === 0) {
  //           this.tabDebitoBancario = true;
  //         }
  //       } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
  //         this.cxctrnretencionesService.calcularTotalNC();
  //       }
  //     } else {
  //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].filter((val, j) => j !==
  //         this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].indexOf(this.detalleVariosSeleccionado));
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
  //       if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
  //         this.cxctrnretencionesService.calcularTotalND();
  //         if (this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length === 0) {
  //           this.tabDebitoBancario = true;
  //         }
  //       } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
  //         this.cxctrnretencionesService.calcularTotalNC();
  //       }
  //     }
  //   }
  // }

  // cancelarVarios() {
  //   if (this.detalleVariosSeleccionado.TRNVARIOS_LINEA === 0) {
  //     this.btnNuevoGrid = false;
  //     this.btnGuardarGrid = true;
  //     this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].filter((val, j) => j !==
  //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].indexOf(this.detalleVariosSeleccionado));
  //     this.detalleVariosSeleccionado = {};
  //   } else if (this.detalleVariosSeleccionado.TRNVARIOS_LINEA !== 0) {
  //     this.btnNuevoGrid = false;
  //     this.btnGuardarGrid = true;
  //     this.cxctrnretencionesService.obtenerVarios(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
  //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = res;
  //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = [];
  //       if (res !== null) {
  //         res.map((vario) => {
  //           if (Number(vario.TRNVARIOS_IMPORTE) < 0) {
  //             vario.DEBE = 0;
  //             vario.HABER = vario.TRNVARIOS_IMPORTE;
  //           } else if (Number(vario.TRNVARIOS_IMPORTE) >= 0) {
  //             vario.DEBE = vario.TRNVARIOS_IMPORTE;
  //             vario.HABER = 0;
  //           }
  //           if (vario.TRNVARIOS_LINEA === this.detalleVariosSeleccionado.LINEA) {
  //             this.detalleVariosSeleccionadoAux
  //               = vario;
  //           }
  //         });
  //         this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = res;
  //         this.detalleFormaPagoDev = this.detalleVariosSeleccionadoAux;
  //         if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
  //           this.cxctrnretencionesService.calcularTotalND();
  //         } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
  //           this.cxctrnretencionesService.calcularTotalNC();
  //         }
  //       }
  //     });
  //   }
  // }

  actualizarTablas() {
    if (this.indiceTablas === 0) {
      if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
        this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      }
    }
    // if (this.tabVarios === true) {
    //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
    //     this.cxctrnretencionesService.calcularTotalND();
    //   }
    // }
    // //GSRF
    // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
    //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDOANC = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE;
    //   this.cxctrnretencionesService.calcularTotalDoc();
    // }  
    // //GSRF
  }

 async nuevaRetencion() {
    if (this.numeroFactura !== '') {
      let intNumRet = 0;
      this.cxctrnretencionesService.erCountSacitrnretencion(this.numeroFactura).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            intNumRet = Number(er[0].COUN);
          }
        }

        if (intNumRet > 0) {
          this.message.add({
            key: 'cxctrnretenciones',
            severity: 'error',
            summary: 'Información',
            detail: 'La factura ya tiene ingresada una retención.'
          });
        } else {
          this.editpagofr = false;
          this.btnNuevoGrid = true;
          this.btnGuardarGrid = false;
          this.btnBorrarGrid = false;
          this.btnCancelarGrid = false;
          const linea = this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length;
          const nuevodetalle = {
            RETENCION_CODIGO: '',
            TRNRETENCION_DESCRIPCION: '',
            TRNRETENCION_NRO: '',
            TRNRETENCION_BASE: 0,
            TRNRETENCION_PORCENTAJE: 0,
            TRNRETENCION_TOTALRETENIDO: 0,
            ENCFAC_NUMERO: this.numeroFactura,
            CLI_CODIGO: this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
            CEN_CODIGO: '',
            CON_CODIGO: '',
            TRNRETENCION_ORIGEN: 'CXC',
            LINEA: String(linea + 1),
            NUEVALINEA:0//CDPJ
          };
                  // Coloca automáticamente el número de la retención de la última fila
            if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length > 0) {
              const intUltFila = this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length;
              nuevodetalle.TRNRETENCION_NRO = this.cxctrnretencionesService.cxctrnretencionarray[this.indicador][intUltFila - 1].TRNRETENCION_NRO;
            } else if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length === 0) {
              nuevodetalle.TRNRETENCION_NRO= '';
            }

          if (this.detalleRetencionSeleccionado !== undefined && this.detalleRetencionSeleccionado !== null) {
            const indi = this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado);
            if (indi === -1) {
              this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].push(nuevodetalle);
              this.detalleRetencionSeleccionado = this.cxctrnretencionesService.cxctrnretencionarray[this.indicador][this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length - 1];
              this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto);
              this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraRet.objeto].setFocusedCell(this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length - 1, 'RETENCION_CODIGO');
            } else {
              //CDPJ
              this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
              this.detalleRetencionSeleccionado = nuevodetalle;
              this.aggrid.refreshaggridindex(this.cxctrnretencionesService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto, indi);
              //CDPJ
              this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraRet.objeto].setFocusedCell(indi + 1, 'RETENCION_CODIGO');
            }
          }
          this.nuevoitem = nuevodetalle;//CDPJ
          console.log(this.nuevoitem);
        }
        
      });
      
    } else {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'error',
        summary: 'Información',
        detail: 'Escoja una factura...'
      });
    }
  }

  // nuevosGastos() {
  //   this.editpagofr = false;
  //   this.btnNuevoGrid = true;
  //   this.btnGuardarGrid = false;
  //   this.btnBorrarGrid = false;
  //   this.btnCancelarGrid = false;
  //   const linea = this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].length;
  //   const nuevodetalle = {
  //     COMISION_CODIGO: '',
  //     TRNCOMISION_DESCRIPCION: '',
  //     TRNCOMISION_NRO: '',
  //     TRNCOMISION_TOTALRETENIDO: 0,
  //     LINEA: String(linea + 1),
  //     TRNCOMISION_BASE: 0,
  //     TRNCOMISION_PORCENTAJE: 0,
  //     TRNCOBRO_NRODOC: this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
  //     TRNCOMISION_ORIGEN: 'CXC',
  //     CLI_CODIGO: this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
  //     CON_CODIGO: '',
  //     TRNCOBRO_NROCOMPROBANTE: this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
  //     TRNCOMISION_FLAG: ''
  //   };
  //   if (this.detalleOtrasFSeleccionada !== undefined && this.detalleOtrasFSeleccionada !== null) {
  //     const indi = this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleOtrasFSeleccionada);
  //     if (indi === -1) {
  //       this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].push(nuevodetalle);
  //       this.detalleOtrasFSeleccionada = this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador][this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].length - 1];
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCarteraOtrosG.objeto);
  //       this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraOtrosG.objeto].setFocusedCell(this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].length - 1, 'COMISION_CODIGO');
  //     } else {
  //       this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
  //       this.aggrid.refreshaggridindex(this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCarteraOtrosG.objeto, indi);
  //       this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraOtrosG.objeto].setFocusedCell(indi + 1, 'COMISION_CODIGO');
  //     }
  //   }
  // }

  // nuevaFormaPago() {
  //   this.editpagofr = false;
  //   this.btnNuevoGrid = true;
  //   this.btnGuardarGrid = false;
  //   this.btnBorrarGrid = false;
  //   this.btnCancelarGrid = false;
  //   const linea = this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length;
  //   const nuevodetalle = {
  //     TRNCOBRO_NRODOC: '',
  //     TRNPAGO_TIPO: '',
  //     CAJ_CODIGO: '',
  //     TRNPAGO_NUMERO: '',
  //     TRNPAGO_CONCEPTO: this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_CONCEPTO,
  //     TRNPAGO_IMPORTE:
  //       Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) - Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO),
  //     BANCLI_CODIGO: '',
  //     BANCLI_NOMBRE: '',
  //     TRNPAGO_NROCTA: '',
  //     TRNPAGO_CODSRI: '',
  //     TRNPAGO_TIPDIS: '',
  //     TRNPAGO_FECHA: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
  //     CON_CODIGO: '',
  //     CLI_CODIGO: this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
  //     TRNCHEPOS_NUMDOC: '',
  //     LINEA: String(linea + 1)
  //   };
  //   this.detalleFormaPago = nuevodetalle;
  //   if (this.detalleFormaPago !== undefined && this.detalleFormaPago !== null) {
  //     const indi = this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].indexOf(this.detalleFormaPago);
  //     if (indi === -1) {
  //       this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].push(nuevodetalle);
  //       this.detalleFormaPago = this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length - 1];
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
  //       this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPago.objeto].setFocusedCell(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length - 1, 'TRNPAGO_TIPO');
  //       this.cxctrnretencionesService.calcularTotalFormaPago();
  //     } else {
  //       this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
  //       this.aggrid.refreshaggridindex(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto, indi);
  //       this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPago.objeto].setFocusedCell(indi + 1, 'TRNPAGO_TIPO');
  //       this.cxctrnretencionesService.calcularTotalFormaPago();
  //     }
  //   }
  // }

  // nuevaFormaPagoDev() {
  //   this.editpagofr = false;
  //   this.btnNuevoGrid = true;
  //   this.btnGuardarGrid = false;
  //   this.btnBorrarGrid = false;
  //   this.btnCancelarGrid = false;
  //   const linea = this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].length;
  //   const nuevodetalle = {
  //     TRNCOBRO_NRODOC: '',
  //     TRNCAJ_TIPO: '',
  //     TRNCAJ_NUMERO: '',
  //     CAJ_CODIGO: '',
  //     BAN_CODIGO: '',
  //     TRNCAJ_FECHA:
  //       String(this.datePipe.transform(new Date(), 'dd/MM/yyyy')),
  //     TRNCAJ_IMPORTE: Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) -
  //       Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO),
  //     TRNCAJ_DESCRIPCION: this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_CONCEPTO,
  //     LINEA: String(linea + 1),
  //     BAN_BANCO: '',
  //     CLI_CODIGO: this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO
  //   };
  //   this.detalleFormaPagoDev = nuevodetalle;
  //   if (this.detalleFormaPagoDev !== undefined && this.detalleFormaPagoDev !== null) {
  //     const indi = this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].indexOf(this.detalleFormaPagoDev);
  //     if (indi === -1) {
  //       this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].push(nuevodetalle);
  //       this.detalleFormaPagoDev = this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador][this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].length - 1];
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador], this.defaultColumnDefCarteraFormaPagoDev.objeto);
  //       this.aggrid.refreshGridData(this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador]);
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador], this.defaultColumnDefCarteraFormaPagoDev.objeto);
  //       this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPagoDev.objeto].setFocusedCell(this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].length - 1, 'TRNCAJ_TIPO');
  //     } else {
  //       this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].splice(indi + 1, 0, nuevodetalle);
  //       this.aggrid.refreshaggridindex(this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador], this.defaultColumnDefCarteraFormaPagoDev.objeto, indi);
  //       this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColumnDefCarteraFormaPagoDev.objeto].setFocusedCell(indi + 1, 'TRNCAJ_TIPO');
  //     }
  //   }
  // }

  cambiarFoco(evento) {
    if (evento.target.id === 'cliente') {
      this.busquedaSer.encontrarRegistro44(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((resCli) => {
        if (resCli !== null) {
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO = resCli[0].CLI_CODIGO;
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE = resCli[0].CLI_NOMBRE;
          this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((res) => {
            this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = Number(res[0].SALDO).toFixed(2);
          });
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = resCli[0].VEN_CODIGO;
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = resCli[0].VEN_NOMBRE;
          this.displayDialogBusquedaFast = false;
          this.btnNuevoGrid = false;
          this.btnBorrarGrid = false;
          this.btnCancelarGrid = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
          document.getElementById('vendedor').focus();
          if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== '') {
            this.cambiarTipoCartera();
          }
          if (resCli[0].CLI_CONREGMIC === 'S'
            || resCli[0].CLI_AGENTERET === 'S') {
            if (resCli[0].CLI_CONREGMIC === 'S'
              && resCli[0].CLI_AGENTERET === 'S') {
              this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas y agente de retención';
            } else {
              if (resCli[0].CLI_CONREGMIC === 'S') {

                this.lblAplicaRegMicroempresas = 'Cliente aplica régimen de Microempresas';
              }
              if (resCli[0].CLI_AGENTERET === 'S') {

                this.lblAplicaRegMicroempresas = 'Cliente aplica agente de retención';
              }
            }
          } else {
            this.lblAplicaRegMicroempresas = '';
          }
        } else {
          this.abrirClientes();
        }
      });
    } 
    // else if (evento.target.id === 'clientefacab') {
    //   this.busquedaSer.encontrarRegistro44(this.codClienteFac).subscribe((resCli) => {
    //     console.log('entro', resCli);
    //     if (resCli !== null) {
    //       this.codClienteFac = resCli[0].CLI_CODIGO;
    //       this.nombreClienteFac = resCli[0].CLI_NOMBRE;
    //       this.cxctrnretencionesService.getDocCobrados(this.codClienteFac, this.nroFacCancelada).subscribe((facpendientes) => {
    //         facpendientes.map((facturas) => {
    //           facturas.TRNCOBRO_FECHATRN = this.datePipe.transform(facturas.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
    //         });
    //         this.cxctrnretencionesService.cxctrnfaccanceladasarray[this.indicador] = facpendientes;
    //       });
    //       this.displayDialogBusquedaFast = false;
    //       document.getElementById('nroFacCanc').focus();
    //     } else {
    //       this.abrirClientesFacAbonadas();
    //     }
    //   });
    // } else
     if (evento.target.id === 'vendedor') {
      this.busquedaSer.encontrarRegistro45(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO).subscribe((resVen) => {
        if (resVen !== null) {
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO = resVen[0].VEN_CODIGO;
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_NOMBRE = resVen[0].VEN_NOMBRE;
          this.displayDialogBusquedaFast = false;
          this.btnNuevo = true;
          this.btnGuardar = false;
          this.btnBorrar = false;
          this.botonPrimero = true;
          this.botonSiguiente = true;
          this.botonAnterior = true;
          this.botonUltimo = true;
        } else {
          this.abrirVendedor();
        }
      });
    } else if (evento.target.id === 'numeroCartera') {
      if (this.booleanBodega === true) {
        document.getElementById('bodega').focus();
      } else if (this.booleanBodega === false) {
        document.getElementById('concepto').focus();
      }
    } 
    // else if (evento.target.id === 'bodega') {
    //   this.busquedaSer.encontrarRegistro40(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO).subscribe((resBod) => {
    //     if (resBod !== null) {
    //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = resBod[0].BOD_CODIGO;
    //       this.displayDialogBusquedaFast = false;
    //       this.btnNuevo = true;
    //       this.btnGuardar = false;
    //       this.btnBorrar = false;
    //       this.botonPrimero = true;
    //       this.botonSiguiente = true;
    //       this.botonAnterior = true;
    //       this.botonUltimo = true;
    //       document.getElementById('concepto').focus();
    //     } else {
    //       this.abrirBodega();
    //     }
    //   });
    // } 
    // else if (evento.target.id === 'concepto') {
    //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
    //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC' ||
    //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN' ||
    //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
    //   } else {
    //     document.getElementById('importe').focus();
    //   }
    // } else if (evento.target.id === 'importe') {
    //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
    //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDOANC = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE;
    //     this.cxctrnretencionesService.calcularTotalDoc();
    //   } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
    //     this.cxctrnretencionesService.calcularTotalND();
    //   } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
    //   } else {
    //     this.cxctrnretencionesService.calcularTotalDoc();
    //   }
    // } else if (evento.target.id === 'banco') {
    //   this.busquedaSer.encontrarRegistro37(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO).subscribe((resBanco) => {
    //     if (resBanco !== null) {
    //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO = resBanco[0].BAN_CODIGO;
    //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO = resBanco[0].BAN_BANCO;
    //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO = resBanco[0].CON_CODIGO;
    //       this.displayDialogBusquedaFast = false;
    //       this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador] = [];
    //       if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO !== null || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_BANCO !== '') {
    //         this.tabVarios = false;
    //         if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO !== '') {
    //           this.cxctrnretencionesService.obtenerChequesCli(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((cheques) => {
    //             if (cheques.length !== 0) {
    //               cheques.map((cheque) => {
    //                 const obj: Cxctrndebitobancario = {};
    //                 obj.SELECCIONADO = false;
    //                 obj.TRNPAGO_FECHA = this.datePipe.transform(cheque.TRNPAGO_FECHA, 'dd/MM/yyyy');
    //                 obj.TRNPAGO_TIPO = cheque.TRNPAGO_TIPO;
    //                 obj.TRNPAGO_NUMERO = cheque.TRNPAGO_NUMERO;
    //                 obj.TRNPAGO_IMPORTE = Number(cheque.TRNPAGO_IMPORTE).toFixed(2);
    //                 obj.TRNPAGO_NROCTA = cheque.TRNPAGO_NROCTA;
    //                 obj.BANCLI_CODIGO = cheque.BANCLI_CODIGO;
    //                 obj.BANCLI_NOMBRE = cheque.BANCLI_NOMBRE;
    //                 obj.TRNCOBRO_NRODOC = cheque.TRNCOBRO_NROCOMPROBANTE;
    //                 this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador].push(obj);
    //               });
    //             }
    //             this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador], this.defaultColumnDefCarteraDebito.objeto);
    //           });
    //         }
    //       } else {
    //         this.tabVarios = true;
    //       }
    //     } else {
    //       this.abrirBancos();
    //     }
    //   });
    // }
  }

  abrirClientes() {
    this.opcion = 'BUSQUEDACLI';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RUCIDE', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'ZONA_NOMBRE' },
      { label: 'Vendedor', value: 'VEN_NOMBRE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'Nombreec', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.consulta = 'ven_maecliente.*,  VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO) AS' +
      ' VEN_NOMBRE, VEN_FNC_OBTENER_ZONACLI(CLI_ZONA) AS ZONA_NOMBRE';
    this.where = '';
    this.spin = true;
    this.busquedaSer.busquedaVenMaeCliente1(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirClientesFacAbonadas() {
    this.opcion = 'BUSQUEDACLIFAC';
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' },
      { label: 'RUCIDE', value: 'CLI_RUCIDE' },
      { label: 'Zona', value: 'ZONA_NOMBRE' },
      { label: 'Vendedor', value: 'VEN_NOMBRE' },
      { label: 'Grupo', value: 'GRU_CODIGO' },
      { label: 'Contacto', value: 'CLI_CONTACTO' },
      { label: 'Placa', value: 'CLI_PLACA' },
      { label: 'Nombreec', value: 'CLI_NOMBREC' },
    ];
    this.busquedacampos = [this.codClienteFac, '', '', '', '', '', '', '', ''];
    this.tabla = 'VEN_MAECLIENTE';
    this.consulta = 'ven_maecliente.*,  VEN_FNC_NOMBRE_VENDEDOR(VEN_CODIGO) AS' +
      ' VEN_NOMBRE, VEN_FNC_OBTENER_ZONACLI(CLI_ZONA) AS ZONA_NOMBRE';
    this.where = '';
    this.spin = true;
    this.busquedaSer.busquedaVenMaeCliente1(this.codClienteFac).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirBodega() {
    this.opcion = 'BUSQUEDABOD';
    this.busquedaCompuesta = false;
    this.types = [
      { label: 'Código', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' }
    ];
    this.busquedacampos = [this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO, ''];
    this.tabla = 'inv_maebodega';
    this.consulta = '*';
    this.where = '';
    this.spin = true;
    this.busquedaSer.busquedaInvMaeBodega1(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  abrirVendedor() {
    this.opcion = 'BUSQUEDAVEN';
    this.types = [
      { label: 'Código', value: 'VEN_CODIGO' },
      { label: 'Nombre', value: 'VEN_NOMBRE' }
    ];
    this.busquedacampos = [this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO, ''];
    this.tabla = 'ven_maevendedor';
    this.consulta = '*';
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === '' || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO === null) {
      this.where = '';
    } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO !== '') {
      this.where = this.types[0].value + ' LIKE \'%' +
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO + '%\'';
    }
    this.spin = true;
    this.busquedaSer.busquedaVenMaeVendedor2(this.where).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  // abrirSustentoTrib() {
  //   if (this.anexoSeleccionado.TRNANEX_IDCRETRI === undefined) {
  //     this.anexoSeleccionado.TRNANEX_IDCRETRI = '';
  //   }

  //   this.opcion = 'TRNANEX_IDCRETRI';
  //   this.types = [
  //     { label: 'Código', value: 'CODSRI_CODIGO' },
  //     { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
  //     { label: 'Tipo', value: 'CODSRI_TIPO' },
  //     { label: 'Año', value: 'CODSRI_ANIO' },
  //     { label: 'Ref', value: 'CODSRI_REF' },
  //     { label: 'Código Con', value: 'CON_CODIGO' },
  //   ];
  //   this.busquedacampos = [this.anexoSeleccionado.TRNANEX_IDCRETRI, '', '', '', '', ''];
  //   this.tabla = 'ANEX_MAECODSRI';
  //   this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
  //   this.where = 'CODSRI_TIPO=\'CT\'';
  //   this.cxctrnretencionesService.erAnexMaeCodSri(this.where, this.anexoSeleccionado.TRNANEX_IDCRETRI).subscribe(er => {
  //     if (er !== null) {
  //       if (er[0] !== undefined) {
  //         this.manejarSeleccion(er[0]);
  //       } else {
  //         this.spin = true;
  //         this.busquedaSer.busquedaAnexMaeCodSri13(this.anexoSeleccionado.TRNANEX_IDCRETRI).subscribe((datos: any[]) => {
  //           this.arregloCons = datos;
  //           this.spin = false;
  //           this.displayDialogBusquedaFast = true;
  //         });
  //       }
  //     } else {
  //       this.spin = true;
  //       this.busquedaSer.busquedaAnexMaeCodSri13(this.anexoSeleccionado.TRNANEX_IDCRETRI).subscribe((datos: any[]) => {
  //         this.arregloCons = datos;
  //         this.spin = false;
  //         this.displayDialogBusquedaFast = true;
  //       });
  //     }
  //   });
  // }

  // abrirTipoComprobante() {
  //   if (this.anexoSeleccionado.TRNANEX_TIPCOM === undefined) {
  //     this.anexoSeleccionado.TRNANEX_TIPCOM = '';
  //   }
  //   this.opcion = 'BUSQUEDATIPOCOMP';
  //   this.types = [
  //     { label: 'Código', value: 'CODSRI_CODIGO' },
  //     { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
  //     { label: 'Tipo', value: 'CODSRI_TIPO' },
  //     { label: 'Año', value: 'CODSRI_ANIO' },
  //     { label: 'Ref', value: 'CODSRI_REF' },
  //     { label: 'Código Con', value: 'CON_CODIGO' },
  //   ];
  //   this.busquedacampos = [this.anexoSeleccionado.TRNANEX_TIPCOM, '', '', '', '', ''];
  //   this.tabla = 'ANEX_MAECODSRI';
  //   this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
  //   this.where = 'UPPER(CODSRI_CODIGO) = \'' +
  //     this.anexoSeleccionado.TRNANEX_TIPCOM +
  //     '\' AND CODSRI_TIPO=\'CT\'';
  //   this.spin = true;
  //   this.cxctrnretencionesService.erCodsridescripcion(this.anexoSeleccionado.TRNANEX_TIPCOM).subscribe((datos: any[]) => {
  //     this.arregloCons = datos;
  //     this.spin = false;
  //     this.displayDialogBusquedaFast = true;
  //   });
  // }

  // abrirCODSRI() {
  //   if (this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO === undefined) {
  //     this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = '';
  //   }
  //   this.opcion = 'BUSQUEDATIPOSRI';
  //   this.busquedacampos = [this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO, '', '', '', ''];
  //   this.tabla = 'ANEX_MAECODSRI';
  //   this.consulta = '*';
  //   this.where = 'CODSRI_TIPO = \'CF\'';

  //   this.types = [
  //     { label: 'Código', value: 'CODSRI_CODIGO' },
  //     { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
  //     { label: 'Año', value: 'CODSRI_ANIO' },
  //     { label: 'Ref', value: 'CODSRI_REF' },
  //     { label: 'CODIGOCON', value: 'CON_CODIGO' }
  //   ];
  //   this.spin = true;
  //   this.busquedaSer.busquedaAnexMaeCodSri14().subscribe((datos: any[]) => {
  //     this.arregloCons = datos;
  //     this.spin = false;
  //     this.displayDialogBusquedaFast = true;
  //   });
  // }

  // buscarReferencia(opcion) {
  //   let parametro = '';
  //   if (opcion === 'TRNANEX_TIPOSRIGRAVADA') {
  //     if (this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA === undefined) {
  //       this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = '';
  //     }
  //     parametro = this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA;
  //   }
  //   if (opcion === 'TRNANEX_TIPOSRITARCERO') {
  //     if (this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO === undefined) {
  //       this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = '';
  //     }
  //     parametro = this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO;
  //   }


  //   this.opcion = opcion;
  //   this.busquedacampos = [parametro, '', '', '', ''];
  //   this.tabla = 'SACI_MAEREFERENCIA';
  //   this.consulta = '*';
  //   this.where = 'REF_TIPO = \'RESVTA\'';

  //   this.types = [
  //     { label: 'Código', value: 'REF_CODIGO' },
  //     { label: 'Nombre', value: 'REF_NOMBRE' }
  //   ];
  //   this.cxctrnretencionesService.erSacimaereferencia(this.where, parametro).subscribe(er => {
  //     console.log(er);
  //     if (er !== null) {
  //       if (er[0] !== undefined) {
  //         this.manejarSeleccion(er[0]);
  //       } else {
  //         this.spin = true;
  //         this.busquedaSer.busquedaSaciMaeReferencia4(parametro).subscribe((datos: any[]) => {
  //           this.arregloCons = datos;
  //           this.spin = false;
  //           this.displayDialogBusquedaFast = true;
  //         });
  //       }
  //     } else {
  //       this.spin = true;
  //       this.busquedaSer.busquedaSaciMaeReferencia4(parametro).subscribe((datos: any[]) => {
  //         this.arregloCons = datos;
  //         this.spin = false;
  //         this.displayDialogBusquedaFast = true;
  //       });
  //     }
  //   });
  // }

   cambiarTipoCartera() {
  //   this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
  //   this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = [];
  //   this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = [];
  //   this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = [];
  //   this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = [];
  //   this.cxctrnretencionesService.cxctrnanticiposarray[this.indicador] = [];
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC = 0;
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC =
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC.toFixed(2);
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = 0;
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO =
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO.toFixed(2);
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO = 0;
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO =
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO.toFixed(2);
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG = 0;
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG =
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALOTROSG.toFixed(2);
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR = 0;
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR =
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR.toFixed(2);
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALRET = 0;
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALRET =
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALRET.toFixed(2);
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALNC = 0;
  //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO =
  //     Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO).toFixed(2);
  //   this.tabVarios = false;
  //   this.tabDebitoBancario = false;
  //   this.tabFormaPagoDev = false;
  //   this.tabRetenciones = false;
  //   this.tabOtrosGastos = false;
  //   this.tabEstadoCuentaAnt = false;
  //   this.tabFormaPago = false;
  //   this.obtenerNumeracionDoc();

  //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC'
  //     || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA'
  //     || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB'
  //     || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT'
  //     || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN'
  //     || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
  //     this.bolsaldoIni = true;
  //   } else {
  //     this.bolsaldoIni = false;
  //   }

  //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO === '') {
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'error',
  //       summary: 'Información',
  //       detail: 'Seleccione un cliente'
  //     });
  //     document.getElementById('cliente').focus();
  //   } else {
  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC') {
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'FC';
  //       this.booleanBodega = true;
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE = new Date();
  //       this.saldoInicial = true;
  //       this.booleanNumero = false;
  //       this.bolsaldoIni = true;
  //       setTimeout(() => {
  //         document.getElementById('numeroCartera').focus();
  //       }, 1000);
  //     }

  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'DAN';
  //       this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
  //       this.booleanBodega = false;
  //       this.saldoInicial = false;
  //       this.tabFormaPagoDev = true;
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
  //       this.cxctrnretencionesService.consultarDocumentosACobrar('ANC', 'UPPER(CLI_CODIGO) LIKE \'' +
  //         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\'').subscribe((res) => {
  //           if (res !== null) {
  //             res.map((devanc) => {
  //               if (devanc.SALDO > 0) {
  //                 const obj: Cxctrnresumen = {};
  //                 obj.SELECCIONADO = false;
  //                 obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
  //                 obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
  //                 if (devanc.FECHAVENCE !== null) {
  //                   obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
  //                 } else {
  //                   obj.TRNCOBRO_FECHAVENCE = '';
  //                 }
  //                 obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
  //                 obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
  //                 obj.TRNCOBRO_IMPORTE = 0;
  //                 if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
  //                   obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
  //                 }
  //                 if (devanc.VENDEDOR !== null) {
  //                   obj.VEN_CODIGO = devanc.VENDEDOR;
  //                 } else {
  //                   obj.VEN_CODIGO = '';
  //                 }
  //                 this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].push(obj);
  //               }
  //             });
  //             this.aggrid.refreshGridData(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]);
  //           }
  //         });
  //     }

  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'ANC';
  //       this.booleanBodega = false;
  //       this.saldoInicial = false;
  //       this.tabFormaPago = true;
  //       this.tabEstadoCuentaAnt = true;
  //       this.booleanNumero = false;
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
  //       this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = [];
  //       this.cxctrnretencionesService.cxctrnanticiposarray[this.indicador] = [];
  //       setTimeout(() => {
  //         document.getElementById('numeroCartera').focus();
  //       }, 1000);
  //     }

  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'NC';
  //       this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
  //       this.booleanBodega = false;
  //       this.saldoInicial = false;
  //       this.tabVarios = true;
  //       this.booleanNumero = false;
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
  //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = [];
  //       //CDPJ
  //       if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCCAVNC').CFG_VALOR1 !== 1){
  //         this.columDefCarteraVarios.splice(5, 1);
  //         this.aggrid.refreshColumnDefs();
  //       }
  //       //CDPJ
  //       this.cxctrnretencionesService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE \'' +
  //         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\'').subscribe((res) => {
  //           if (res !== null) {
  //             res.map((devanc) => {
  //               if (devanc.SALDO > 0) {
  //                 const obj: Cxctrnresumen = {};
  //                 obj.SELECCIONADO = false;
  //                 obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
  //                 obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
  //                 if (devanc.FECHAVENCE !== null) {
  //                   obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
  //                 } else {
  //                   obj.TRNCOBRO_FECHAVENCE = '';
  //                 }
  //                 obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
  //                 obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
  //                 obj.TRNCOBRO_IMPORTE = 0;
  //                 if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
  //                   obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
  //                 }
  //                 if (devanc.VENDEDOR !== null) {
  //                   obj.VEN_CODIGO = devanc.VENDEDOR;
  //                 } else {
  //                   obj.VEN_CODIGO = '';
  //                 }
  //                 this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].push(obj);
  //               }
  //             });
  //             //CDPJ
  //             this.aggrid.refreshGridData(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]);
  //             //CDPJ
  //             //this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
  //             setTimeout(() => {
  //               this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
  //               document.getElementById('numeroCartera').focus();
  //             }, 1000);
  //           }
  //         });
  //     }

  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC = 'ND';
  //       this.booleanBodega = false;
  //       this.saldoInicial = false;
  //       this.tabVarios = true;
  //       this.tabDebitoBancario = true;
  //       this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = [];
  //       this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador] = [];
  //       this.booleanNumero = false;
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE = new Date();
  //       //CDPJ
  //       this.columDefCarteraVarios.splice(5, 1);
  //         this.aggrid.refreshColumnDefs();
  //       //CDPJ
  //       setTimeout(() => {
  //         document.getElementById('numeroCartera').focus();
  //       }, 1000);
  //     }

  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
  //       this.cxctrnretencionesService.tipoDocumentoarray[this.indicador] = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC;
  //       this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
  //       this.booleanBodega = false;
  //       this.saldoInicial = false;
  //       this.tabRetenciones = true;
  //       this.tabOtrosGastos = true;
  //       this.tabFormaPago = true;
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
  //       this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = [];
  //       this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = [];
  //       this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = [];
  //       this.cxctrnretencionesService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE \'' +
  //         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\'').subscribe((res) => {
  //           if (res !== null) {
  //             res.map((devanc) => {
  //               if (devanc.SALDO > 0) {
  //                 const obj: Cxctrnresumen = {};
  //                 obj.SELECCIONADO = false;
  //                 obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
  //                 obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
  //                 if (devanc.FECHAVENCE !== null) {
  //                   obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
  //                 } else {
  //                   obj.TRNCOBRO_FECHAVENCE = '';
  //                 }
  //                 obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO === undefined || devanc.CONCEPTO === null ? '' : devanc.CONCEPTO;
  //                 obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
  //                 obj.TRNCOBRO_IMPORTE = 0;
  //                 if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
  //                   obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
  //                 }
  //                 if (devanc.VENDEDOR !== null) {
  //                   obj.VEN_CODIGO = devanc.VENDEDOR;
  //                 } else {
  //                   obj.VEN_CODIGO = '';
  //                 }
  //                 obj.TRNCOBRO_TIPODOC = devanc.TRNCOBRO_TIPODOC;
  //                 this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].push(obj);
  //               }
  //             });
  //             //CDPJ
  //             this.aggrid.refreshGridData(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]);
  //             //CDPJ
  //             //this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
  //             console.log(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]);
  //             setTimeout(() => {
  //               document.getElementById('numeroCartera').focus();
  //             }, 1000);
  //           }
  //         });
  //     }

      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
        this.cxctrnretencionesService.tipoDocumentoarray[this.indicador] = 'RT';
        this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
        this.booleanBodega = false;
        this.saldoInicial = false;
        this.tabRetenciones = true;
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = 0;
        this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = [];
        this.cxctrnretencionesService.consultarDocumentosACobrar('ON', 'UPPER(CLI_CODIGO) LIKE \'' +
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO + '\'').subscribe((res) => {
            if (res !== null) {
              res.map((devanc) => {
                if (devanc.SALDO > 0) {
                  const obj: Cxctrnresumen = {};
                  obj.SELECCIONADO = false;
                  obj.TRNCOBRO_NRODOC = devanc.DOCUMENTO;
                  obj.TRNCOBRO_FECHATRN = this.datePipe.transform(devanc.FECHA, 'dd/MM/yyyy');
                  if (devanc.FECHAVENCE !== null) {
                    obj.TRNCOBRO_FECHAVENCE = this.datePipe.transform(devanc.FECHAVENCE, 'dd/MM/yyyy');
                  } else {
                    obj.TRNCOBRO_FECHAVENCE = '';
                  }
                  obj.TRNCOBRO_CONCEPTO = devanc.CONCEPTO;
                  obj.TRNCOBRO_SALDOINI = Number(devanc.SALDO).toFixed(2);
                  obj.TRNCOBRO_IMPORTE = 0;
                  if (devanc.APLORG_CODIGO !== null || devanc.APLORG_CODIGO !== undefined) {
                    obj.APLORG_CODIGO = devanc.APLORG_CODIGO;
                  }
                  if (devanc.VENDEDOR !== null) {
                    obj.VEN_CODIGO = devanc.VENDEDOR;
                  } else {
                    obj.VEN_CODIGO = '';
                  }
                  this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].push(obj);
                }
              });
              //CDPJ
              this.aggrid.refreshGridData(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]);
              //CDPJ
              //this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
              setTimeout(() => {
                document.getElementById('numeroCartera').focus();
              }, 1000);
            }
          });
      }
    //}
   }

  
  docSeleccionado(valor) {
    if (valor.data.SELECCIONADO === true && this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      valor.data.TRNCOBRO_IMPORTE = valor.data.TRNCOBRO_SALDOINI;
      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
        this.numeroFactura = valor.data.TRNCOBRO_NRODOC;
      }
      this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      const indi = this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].indexOf(valor.data);
      if (indi === -1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(0, 'SELECCIONADO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(indi, 'SELECCIONADO');
      }
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColumnDefCarteraDoc.objeto].forEachNode((node) => {
          const h = indi;
          if (Number(node.id) === h) {
            node.setSelected(true);
          }
          // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          //   this.defaultColumnDefCarteraDoc.objeto].ensureIndexVisible(h, 'bottom');
        });
      this.cxctrnretencionesService.calcularTotalDoc();
    }

    if (valor.data.SELECCIONADO === false) {
      valor.data.TRNCOBRO_IMPORTE = 0;
      this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador], this.defaultColumnDefCarteraDoc.objeto);
      const indi = this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].indexOf(valor.data);
      if (indi === -1) {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(0, 'SELECCIONADO');
      } else {
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColumnDefCarteraDoc.objeto].setFocusedCell(indi, 'SELECCIONADO');
      }
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColumnDefCarteraDoc.objeto].forEachNode((node) => {
          const h = indi;
          if (Number(node.id) === h) {
            node.setSelected(true);
          }
          // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          //   this.defaultColumnDefCarteraDoc.objeto].ensureIndexVisible(h, 'bottom');
        });
      this.cxctrnretencionesService.calcularTotalDoc();
    }
  }

  // debitoSeleccionado() {
  //   this.cxctrnretencionesService.calcularTotalND();
  // }

  abrirBancos() {
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO === undefined) {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO = '';
    }
    this.opcion = 'BUSQUEDABAN';
    this.types = [
      { label: 'Código', value: 'BAN_CODIGO' },
      { label: 'Banco', value: 'BAN_BANCO' },
      { label: 'Cuenta Nro.', value: 'BAN_CTANRO' },
      { label: 'Jefe Cuenta', value: 'BAN_JEFECTA' },
      { label: 'Sucursal', value: 'BAN_SUCURSAL' },
      { label: 'Dirección', value: 'BAN_DIRECCION' },
      { label: 'Telefóno 1', value: 'BAN_TELEFONO1' },
      { label: 'Referencia Contable', value: 'CON_CODIGO' },
      { label: 'CEN_CODIGO', value: 'CEN_CODIGO' },
      { label: 'Tipo de cuenta', value: 'BAN_TIPOCUENTA' },
      { label: 'Doc por Pagar', value: 'CON_CODIGO2' },
      { label: 'Proceso', value: 'BAN_PROCESO_CM' },
      { label: 'Directorio CM', value: 'BAN_DIRECTORIO_CM' },
      { label: 'Dinero Elect', value: 'BAN_DINEROELEC' },
      { label: 'Tel Cel', value: 'BAN_TELEFONOCEL' },
      { label: 'Último Cheque', value: 'BAN_ULTIMOCHEQUE' },
    ];
    this.busquedacampos = [this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO, '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'BAN_MAEBAN';
    this.consulta = '*';
    this.where = '';
    this.spin = true;
    this.busquedaSer.busquedaBanMaeBan3(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.spin = false;
      this.displayDialogBusquedaFast = true;
    });
  }

  // cambioSaldo() {
  //   if (this.saldoInicial === true) {
  //     this.tabVarios = false;
  //     this.tabDebitoBancario = false;
  //     this.tabFormaPagoDev = false;
  //     this.tabRetenciones = false;
  //     this.tabOtrosGastos = false;
  //     //this.tabEstadoCuentaAnt = false;//CDPJ COMENTÉ, AUNQUE SEA SALDO INICIAL SE DEBE VER EL ESTADO DE CUENTA DEL ANTICIPO
  //     this.tabFormaPago = false;
  //   } else if (this.saldoInicial === false) {
  //     const numeroDoc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
  //     this.cambiarTipoCartera();
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = numeroDoc;
  //   }
  // }

  obtenerNumeracionDoc() {
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC
      === 'AB' || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT' ||
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
        if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROCMPRAUT').CFG_VALOR1 === 1) {
          this.booleanNumero = false;
          if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ANC') {
            this.cxctrnretencionesService.obtenerNumeracion('CI').subscribe((num) => {
              this.numSerie = num[0].NUM_SERIE;
              if (num.length === 0) {
                this.message.add({
                  key: 'cxctrnretenciones',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'No existe una numeración para el documento'
                });
              } else if (Number(num[0].NUM_SECACTUAL) >= Number(num[0].NUM_SECFINAL)) {
                this.message.add({
                  key: 'cxctrnretenciones',
                  severity: 'error',
                  summary: 'Información',
                  detail: 'Se terminó el secuencial de la factura'
                });
              } else {
                const numero = num[0].NUM_SECACTUAL.replace(/\b0+/g, '');
                this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = Number(numero);
                if (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length !== 0) {
                  if (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length <= 10 &&
                    String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length !== 0) {
                    do {
                      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '0' +
                        String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
                    } while (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length <= 10);
                  }
                }
              }
            });
          }
          // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
          //   this.cxctrnretencionesService.obtenerNumeracion('CI').subscribe((num1) => {
          //     console.log(num1);
          //     this.numSerie = num1[0].NUM_SERIE;
          //     if (num1.length === 0) {
          //       this.message.add({
          //         key: 'cxctrnretenciones',
          //         severity: 'error',
          //         summary: 'Información',
          //         detail: 'No existe una numeración para el documento'
          //       });
          //     } else if (Number(num1[0].NUM_SECACTUAL) >= Number(num1[0].NUM_SECFINAL)) {
          //       this.message.add({
          //         key: 'cxctrnretenciones',
          //         severity: 'error',
          //         summary: 'Información',
          //         detail: 'Se terminó el secuencial de la factura'
          //       });
          //     } else {
          //       const numero1 = String(num1[0].NUM_SECACTUAL).replace(/\b0+/g, '');
          //       console.log(numero1);
          //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = Number(numero1);
          //       if (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length !== 0) {
          //         if (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length <= 10 &&
          //           String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length !== 0) {
          //           do {
          //             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '0' +
          //               String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
          //           } while (String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).length <= 10);
          //         }
          //       }
          //     }
          //   });
          // }
        } else {
          if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ANC') {
            this.booleanNumero = false;
          }
        }
      } else {
        this.booleanNumero = true;
      }
    }
  }

  cambiarBotones() {
    this.botonPrimero = false;
    this.botonAnterior = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.botonBuscar = false;
    this.botonAsiento = false;
    this.botonVerAsiento = false;
    this.botonAnular = false;
  }

  confirmarCartera() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el documento',
      header: 'Eliminar Cartera',
      icon: 'pi pi-exclamation-triangle',
      key: 'conftrnretenciones',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarCartera();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async eliminarCartera() {
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] !== undefined) {
      // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
      //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
      //   this.cxctrnretencionesService.existePagoDoc(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((comp) => {
      //     if (comp.length !== 0) {
      //       this.message.add({
      //         key: 'cxctrnretenciones',
      //         severity: 'error',
      //         summary: 'Eliminación de Cartera',
      //         detail: 'El documento tiene cobros, por lo que no se puede eliminar'
      //       });
      //       return;
      //     }
      //   });
      // }
      // if (this.tabFormaPago === true) {
      //   if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
      //     this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].map(async (formap) => {
      //       if (formap.TRNPAGO_TIPO === 'ANCL') {
      //         const eR = await this.cxctrnretencionesService.erTrnpagorefer(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
      //         let strNumDocAnt = '';
      //         if (eR !== undefined) {
      //           if (eR[0].TRNPAGO_REFER !== null) {
      //             strNumDocAnt = eR[0].TRNPAGO_REFER;
      //           }
      //         }

      //         await this.cxctrnretencionesService.eliminarTrnCobroProm(strNumDocAnt);
      //         this.eliminarPagosCuotas();
      //         this.cxctrnretencionesService.eliminarNDATrnResumen(formap.TRNCOBRO_NRODOC).subscribe(() => {//----- actualiza NDA
      //           console.log('Se actualiza');
      //         }, error1 => {
      //           this.message.add({
      //             key: 'cxctrnretenciones',
      //             severity: 'error',
      //             summary: 'Eliminación de Cartera',
      //             detail: 'No se pudo eliminar la cxctrnretenciones, error ' + error1.error
      //           });
      //         });
      //         this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
      //           '', '01', '', '', '', '').subscribe(() => {
      //             this.message.add({
      //               key: 'cxctrnretenciones',
      //               severity: 'success',
      //               summary: 'Eliminación de Cartera',
      //               detail: 'Se eliminó la cxctrnretenciones correctamente'
      //             });
      //             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] = {
      //               SALDO: '',
      //               TRNCOBRO_TIPODOC: '',
      //               TOTALFORMAPAGO: 0,
      //               TOTALND: 0,
      //               TRNCOBRO_IMPORTE: 0,
      //               TOTALNC: 0,
      //               TOTALOTROSG: 0,
      //               TOTALRET: 0,
      //               TOTALACOBRAR: 0,
      //               TOTALDOC: 0,
      //               BAN_BANCO: '',
      //               BAN_CODIGO: '',
      //               TRNCOBRO_NROCOMPROBANTE: '',
      //               TRNCOBRO_SALDOINI: '',
      //               CON_CODIGO: '',
      //               TRNCOBRO_NRODOC: '',
      //               TOTALANTICIPO: '',
      //               TRNCOBRO_FECHAVENCE: '',
      //               CLI_NOMBRE: '',
      //               REALIZADO: '',
      //               ORIGEN: '',
      //               VEN_NOMBRE: '',
      //               APLORG_CODIGO: 'CXC',
      //               USU_IDENTIFICACION: this.usuario.identificacion,
      //               TRNCOBRO_CONCEPTO: '',
      //               BOD_CODIGO: '',
      //               TRNCOBRO_FECHATRN: new Date(),
      //               VEN_CODIGO: '',
      //               CLI_CODIGO: '',
      //               ASI_NRO: '',
      //               CABROLADMN_CODIGO: '',
      //               CABROLVEND_CODIGO: '',
      //               COM_CODIGO: '01',
      //               COM_CODIGO_CXP: '01',
      //               COM_TIPOAMBFACTELEC: '',
      //               EMP_CODIGO: '',
      //               EMP_CODIGOVEND: '',
      //               TRNCOBRO_ANULADO: '',
      //               TRNCOBRO_CAMBIO: '',
      //               TRNCOBRO_CUADRE: '',
      //               TRNCOBRO_EFECTIVO: '',
      //               TRNCOBRO_FLAG: '1',
      //               TRNCOBRO_IMPRESO: '',
      //               TRNCOBRO_LOCALIZACIONXMLRET: '',
      //               TRNCOBRO_NRODOC_CXP: '',
      //               TRNCOBRO_NRODOC_OLD: '',
      //               TRNCOBRO_PATHPDF_FE: '',
      //               TRNCOBRO_PATHXMLNOAUTO_FE: '',
      //               TRNCOBRO_PATHXMLRET: '',
      //               TRNCOBRO_REFERENCIAEXTERNA: '',
      //               TRNCOBROND_AUTORIZACION_FE: '',
      //               TRNCOBROND_CLAVEACCESO_FE: '',
      //               TRNCOBROND_ESTADO_FE: '',
      //               TRNCOBROND_FECHAAUT_FE: '',
      //               TRNCOBROND_NUMFACTELEC: ''
      //             };
      //             this.booleanBodega = false;
      //             this.saldoInicial = false;
      //             this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
      //             this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = [];
      //             this.cxctrnretencionesService.cxctrnanticiposarray[this.indicador] = [];
      //           });


      //       }
      //     });
      //   }
      // }

      if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ELIASIELI').CFG_VALOR1 === 0) {
        if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === false) {
          this.cxctrnretencionesService.anularAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO).subscribe(() => {
          });
        } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === true) {
          this.cxctrnretencionesService.actualizarAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
          });
        }
      } else {
        if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === false) {
          this.cxctrnretencionesService.eliminarAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO).subscribe(() => {
          });
        } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '' && this.saldoInicial === true) {
          this.cxctrnretencionesService.actualizarAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
          });
        }
      }
      ////GSRF
      //this.eliminarFormaPagoDev();
      ////GSRF

      //this.cxctrnretencionesService.eliminarAntAsoc(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe();
       try {
      //    //CDPJ 
      //    if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
      //     await this.cxctrnretencionesService.eliminarMovBan(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC)
      //    }      
      //    //CDPJ
         await this.cxctrnretencionesService.eliminarTrnCobroProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC)
      //   this.eliminarPagosCuotas();
      //   this.cxctrnretencionesService.eliminarNDATrnResumen(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
      //   });
        this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
          '', '01', '', '', '', '').subscribe(() => {
            this.message.add({
              key: 'cxctrnretenciones',
              severity: 'success',
              summary: 'Eliminación de Cartera',
              detail: 'Se eliminó la cxctrnretenciones exitosamente'
            });
            this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] = {
              SALDO: '',
              TRNCOBRO_TIPODOC: '',
              TOTALFORMAPAGO: 0,
              TOTALND: 0,
              TRNCOBRO_IMPORTE: 0,
              TOTALNC: 0,
              TOTALOTROSG: 0,
              TOTALRET: 0,
              TOTALACOBRAR: 0,
              TOTALDOC: 0,
              BAN_BANCO: '',
              BAN_CODIGO: '',
              TRNCOBRO_NROCOMPROBANTE: '',
              TRNCOBRO_SALDOINI: '',
              CON_CODIGO: '',
              TRNCOBRO_NRODOC: '',
              TOTALANTICIPO: '',
              TRNCOBRO_FECHAVENCE: '',
              CLI_NOMBRE: '',
              REALIZADO: '',
              ORIGEN: '',
              VEN_NOMBRE: '',
              APLORG_CODIGO: 'CXC',
              USU_IDENTIFICACION: this.usuario.identificacion,
              TRNCOBRO_CONCEPTO: '',
              BOD_CODIGO: '',
              TRNCOBRO_FECHATRN: new Date(),
              VEN_CODIGO: '',
              CLI_CODIGO: '',
              ASI_NRO: '',
              CABROLADMN_CODIGO: '',
              CABROLVEND_CODIGO: '',
              COM_CODIGO: '01',
              COM_CODIGO_CXP: '01',
              COM_TIPOAMBFACTELEC: '',
              EMP_CODIGO: '',
              EMP_CODIGOVEND: '',
              TRNCOBRO_ANULADO: '',
              TRNCOBRO_CAMBIO: '',
              TRNCOBRO_CUADRE: '',
              TRNCOBRO_EFECTIVO: '',
              TRNCOBRO_FLAG: '1',
              TRNCOBRO_IMPRESO: '',
              TRNCOBRO_LOCALIZACIONXMLRET: '',
              TRNCOBRO_NRODOC_CXP: '',
              TRNCOBRO_NRODOC_OLD: '',
              TRNCOBRO_PATHPDF_FE: '',
              TRNCOBRO_PATHXMLNOAUTO_FE: '',
              TRNCOBRO_PATHXMLRET: '',
              TRNCOBRO_REFERENCIAEXTERNA: '',
              TRNCOBROND_AUTORIZACION_FE: '',
              TRNCOBROND_CLAVEACCESO_FE: '',
              TRNCOBROND_ESTADO_FE: '',
              TRNCOBROND_FECHAAUT_FE: '',
              TRNCOBROND_NUMFACTELEC: ''
            };
            this.booleanBodega = false;
            this.saldoInicial = false;
            this.cxctrnretencionesService.cxctrnresumenarray[this.indicador] = [];
            this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = [];
            this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = [];
            this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = [];
            this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = [];
            this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador] = [];
            this.cxctrnretencionesService.cxctrnvariosarray[this.indicador] = [];
            this.cxctrnretencionesService.cxctrnanticiposarray[this.indicador] = [];
          });
        if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
          this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((doc) => {
            if (doc.APLORG_CODIGO === 'SISTEMA') {
              //this.cxctrnretencionesService.actualizarPagadoCuota(doc).subscribe(() => {
              //});
            }
            if (doc.SELECCIONADO === true && doc.APLORG_CODIGO !== 'SISTEMA') {
              this.cxctrnretencionesService.actualizarestadoFac(doc).subscribe(() => {
              });
            }
          });
        }
      } catch (error1) {
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Eliminación de Cartera',
          detail: 'No se pudo eliminar la cxctrnretenciones, error ' + error1.error
        });
       }
       
    } else {
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'error',
        summary: 'Eliminación de Cartera',
        detail: 'Seleccione una cxctrnretenciones para ser eliminada'
      });
    }
  }

  async cancelarEdicionCartera() {
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' && this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== undefined) {
      const res = await this.cxctrnretencionesService.getEncCarteraProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
      if (res.length === 0) {
        this.nuevaCartera('C');
      } else {
        this.btnNuevo = false;
        this.btnGuardar = true;
        this.btnBorrar = false;
        this.botonAnterior = false;
        this.botonPrimero = false;
        this.botonUltimo = false;
        this.botonSiguiente = false;
        this.botonBuscar = false;
        this.botonPago = false;
        this.botonAnular = false;
        this.botonAsiento = false;
        this.botonVerAsiento = false;
        const array: any = [];
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] = array;//res[0];
        await this.obtenerDatos(res[0].TRNCOBRO_NRODOC);
      }
      /* this.cxctrnretencionesService.getEncCartera(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        if (res.length === 0) {
          this.nuevaCartera('C');
        } else {
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.botonAnterior = false;
          this.botonPrimero = false;
          this.botonUltimo = false;
          this.botonSiguiente = false;
          this.botonBuscar = false;
          this.botonPago = false;
          this.botonAnular = false;
          this.botonAsiento = false;
          this.botonVerAsiento = false;
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] = res[0];
          this.obtenerDatos(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        }
      }); */
    } else {
      this.nuevaCartera('C');
    }
  }

  confirmarAnularCar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea anular la cxctrnretenciones',
      header: 'Anular Cartera',
      icon: 'pi pi-exclamation-triangle',
      key: 'conftrnretenciones',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.anularCartera();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  anularCartera() {
    let errores = 0;

    // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
    //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
    //   this.cxctrnretencionesService.existePagoDoc(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
    //     if (res.length !== 0) {
    //       this.message.add({
    //         key: 'cxctrnretenciones',
    //         severity: 'error',
    //         summary: 'Anular Cartera',
    //         detail: 'No se puede Anular la cxctrnretenciones porque existe un pago'
    //       });
    //       errores++;
    //       return;
    //     }
    //   });
    // }
    // if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
    //   this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].map((formap) => {
    //     if (formap.TRNPAGO_TIPO === 'CH') {
    //       this.cxctrnretencionesService.erTrnbanconcilia(formap.TRNCOBRO_NRODOC, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO).subscribe((exis) => {
    //         if (exis[0].TRNBAN_CONCILIA === 1) {
    //           this.message.add({
    //             key: 'cxctrnretenciones',
    //             severity: 'error',
    //             summary: 'Anular Cartera',
    //             detail: 'No se puede Anular la cxctrnretenciones porque existen documentos conciliados'
    //           });
    //           errores++;
    //           return;
    //         }
    //       });
    //       this.cxctrnretencionesService.erTrncajdepositonro(formap.TRNPAGO_NUMERO, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO, formap.TRNCOBRO_NRODOC).subscribe((exis2) => {
    //         if (exis2[0].TRNCAJ_DEPOSITONRO !== null) {
    //           this.message.add({
    //             key: 'cxctrnretenciones',
    //             severity: 'error',
    //             summary: 'Anular Cartera',
    //             detail: 'No se puede Anular la cxctrnretenciones porque existen documentos depositados'
    //           });
    //           errores++;
    //           return;
    //         }
    //       });
    //     } else if (formap.TRNPAGO_TIPO === 'EF') {
    //       this.cxctrnretencionesService.erTrncajdepositonro2(formap.TRNPAGO_NUMERO, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
    //         formap.TRNPAGO_TIPO, formap.CAJ_CODIGO).subscribe((exis) => {
    //           console.log('*-/-/-/', exis)//GSRF
    //           if (exis[0].TRNCAJ_DEPOSITONRO !== null) {//GSRF
    //           //if (exis[0] === null) {
    //             this.message.add({
    //               key: 'cxctrnretenciones',
    //               severity: 'error',
    //               summary: 'Anular Cartera',
    //               detail: 'No puede anular el cobro, el efectivo ' + formap.TRNPAGO_NUMERO
    //                 + ' está depositado. Nro. Depósito: ' + exis[0].TRNCAJ_DEPOSITONRO
    //             });
    //             errores++;
    //             return;
    //           }
    //         });
    //     } else if (formap.TRNPAGO_TIPO === 'DP') {
    //       this.cxctrnretencionesService.erTrnbanconcilia(formap.TRNPAGO_NUMERO, formap.BANCLI_CODIGO, formap.TRNPAGO_TIPO).subscribe((exis) => {
    //         if (exis.length !== 0) {
    //           if (exis[0].TRNBAN_CONCILIA === 1) {
    //             this.message.add({
    //               key: 'cxctrnretenciones',
    //               severity: 'error',
    //               summary: 'Anular Cartera',
    //               detail: 'No se puede Anular el pago porque el deposito esta conciliado'
    //             });
    //             errores++;
    //             return;
    //           }

    //         }
    //       });
    //     } else if (formap.TRNPAGO_TIPO === 'TA') {
    //       this.cxctrnretencionesService.erTrntarjetacodigo(formap.TRNPAGO_NUMERO, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE,
    //         formap.TRNPAGO_TIPO, formap.CAJ_CODIGO).subscribe((exis) => {
    //           console.log('*/*/*/',exis[0].TRNTARJETA_CODIGO);//GSRF
    //           if (exis[0].TRNTARJETA_CODIGO !== null)  {//GSRF
    //             this.message.add({
    //               key: 'cxctrnretenciones',
    //               severity: 'error',
    //               summary: 'Anular Cartera',
    //               detail: 'No se puede Anular el pago porque el voucher está conciliado'
    //             });
    //             errores++;
    //             return;
    //           }
    //         });
    //     }
    //   });
    // }
    // if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
    //   this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((doc) => {
    //     this.cxctrnretencionesService.erTrncobrocuadre(doc.TRNCOBRO_NRODOC).subscribe((exis) => {
    //       if (exis[0].TRNCOBRO_CUADRE === 1) {
    //         this.message.add({
    //           key: 'cxctrnretenciones',
    //           severity: 'error',
    //           summary: 'Anular Cartera',
    //           detail: 'No se puede Anular el pago porque el documento está en el cierre de caja'
    //         });
    //         errores++;
    //         return;
    //       }
    //     });
    //   });
    // }
    // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
    //   if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDOANC) !==
    //     Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE)) {
    //     this.message.add({
    //       key: 'cxctrnretenciones',
    //       severity: 'error',
    //       summary: 'Anular Cartera',
    //       detail: 'No se puede Anular el anticipo ya está siendo utilizado'
    //     });
    //     errores++;
    //     return;
    //   }
    // }

    setTimeout(() => {
      if (errores === 0) {
        this.finalmenteAnular();
      }
    }, 1500);
  }

  finalmenteAnular() {
    // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
    //   this.cxctrnretencionesService.eliminarCaja(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
    //     this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
    //       '', '01', '', '', '', '').subscribe(() => {
    //       });
    //   });
    //   this.cxctrnretencionesService.eliminarBanco(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
    //     this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
    //       '', '01', '', '', '', '').subscribe(() => {
    //       });
    //   });
    // }
    // if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
    //   this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].map(async (formap) => {
    //     if (formap.TRNPAGO_TIPO === 'ANCL') {
    //       //GSRF
    //       const eR = await this.cxctrnretencionesService.erTrnpagorefer(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
    //           let strNumDocAnt = '';
    //           if (eR !== undefined) {
    //             if (eR[0].TRNPAGO_REFER !== null) {
    //               strNumDocAnt = eR[0].TRNPAGO_REFER;
    //             }
    //           }
    //       console.log(strNumDocAnt)
    //       await this.cxctrnretencionesService.eliminarTrnCobroProm(strNumDocAnt);
    //       //GSRF
    //       //await this.cxctrnretencionesService.eliminarTrnCobroProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
    //       this.eliminarPagosCuotas();
    //       this.cxctrnretencionesService.eliminarNDATrnResumen(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
    //       });
    //       this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
    //         '', '01', '', '', '', '').subscribe(() => {
    //         });

    //     }
    //   });
    // }
    this.cxctrnretencionesService.anularDocumento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
      if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO !== '') {
        this.cxctrnretencionesService.anularAsientoDoc(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO).subscribe(() => {
        });
      }
      if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
        this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((doc) => {
          if (doc.SELECCIONADO === true) {
            this.cxctrnretencionesService.actualizarestadoFac(doc).subscribe(() => {
            });
          }
        });
      }
      this.cxctrnretencionesService.eliminarDocAnulado(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      this.cxctrnretencionesService.eliminarDocAnuladoEnc(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      // this.cxctrnretencionesService.eliminarPagoAnulado(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      // });
      this.cxctrnretencionesService.eliminarRetencionAnulado(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      });
      this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC) + '/' +
        String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE), 'AN',
        '', '01', '', '', '', '').subscribe(() => {
          this.message.add({
            key: 'cxctrnretenciones',
            severity: 'success',
            summary: 'Anular Cartera',
            detail: 'La cxctrnretenciones fue anulada'
          });
          this.booleanAnulado = true;
          this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
            this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
            this.cancelarEdicionCartera();
          });
        });
    });
  }

  confirmarAsiento() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea generar el asiento',
      header: 'Asiento Cartera',
      icon: 'pi pi-exclamation-triangle',
      key: 'conftrnretenciones',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
        //   this.cxctrnretencionesService.generarAsientoNC(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
        //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
        //     this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
        //     this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
        //       '', '01', '', '', '', '').subscribe(() => {
        //         this.message.add({
        //           key: 'cxctrnretenciones',
        //           severity: 'success',
        //           summary: 'Asiento Contable',
        //           detail: 'Se ha generado el asiento contable'
        //         });
        //       });
        //   });
        // } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
        //   if (this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador].length !== 0) {
        //     this.cxctrnretencionesService.generarAsientoND(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
        //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
        //       this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
        //       this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
        //         '', '01', '', '', '', '').subscribe(() => {
        //           this.message.add({
        //             key: 'cxctrnretenciones',
        //             severity: 'success',
        //             summary: 'Asiento Contable',
        //             detail: 'Se ha generado el asiento contable'
        //           });
        //         });
        //     });
        //   } else {
        //     this.cxctrnretencionesService.generarAsientoNDBan(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
        //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
        //       this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
        //       this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
        //         '', '01', '', '', '', '').subscribe(() => {
        //           this.message.add({
        //             key: 'cxctrnretenciones',
        //             severity: 'success',
        //             summary: 'Asiento Contable',
        //             detail: 'Se ha generado el asiento contable'
        //           });
        //         });
        //     });
        //   }
        // } else 
        if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
          this.cxctrnretencionesService.generarAsientoRT(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
            this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
              this.asiento = res[0].ASI_NRO;
              this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
              this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
              this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
                '', '01', '', '', '', '').subscribe(() => {
                  this.message.add({
                    key: 'cxctrnretenciones',
                    severity: 'success',
                    summary: 'Asiento Contable',
                    detail: 'Se ha generado el asiento contable'
                  });
                });
            });
          });
        } 
        // else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
        //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
        //   this.cxctrnretencionesService.generarAsientoCI(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
        //     this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        //       this.asiento = res[0].ASI_NRO;
        //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
        //       this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
        //       this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
        //         '', '01', '', '', '', '').subscribe(() => {
        //           this.message.add({
        //             key: 'cxctrnretenciones',
        //             severity: 'success',
        //             summary: 'Asiento Contable',
        //             detail: 'Se ha generado el asiento contable'
        //           });
        //         });
        //     });
        //   }, error1 => {
        //     this.message.add({
        //       key: 'cxctrnretenciones',
        //       severity: 'error',
        //       summary: 'Asiento Contable',
        //       detail: 'No se ha generado el asiento, error ' + error1.error
        //     });
        //   });
        // } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
        //   this.cxctrnretencionesService.generarAsientoDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
        //     this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        //       this.asiento = res[0].ASI_NRO;
        //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
        //       this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
        //       this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
        //         '', '01', '', '', '', '').subscribe(() => {
        //           this.message.add({
        //             key: 'cxctrnretenciones',
        //             severity: 'success',
        //             summary: 'Asiento Contable',
        //             detail: 'Se ha generado el asiento contable'
        //           });
        //         });
        //     });
        //   });
        // } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
        //   this.cxctrnretencionesService.generarAsientoANC(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
        //     this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        //       this.asiento = res[0].ASI_NRO;
        //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
        //       this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
        //       this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
        //         '', '01', '', '', '', '').subscribe(() => {
        //           this.message.add({
        //             key: 'cxctrnretenciones',
        //             severity: 'success',
        //             summary: 'Asiento Contable',
        //             detail: 'Se ha generado el asiento contable'
        //           });
        //         });
        //     });
        //   });
        // }
        ///GSRF
    this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(async(res)  => {
      console.log('----***',res)
      this.asiento = res[0].ASI_NRO;
      if (this.asiento !== null) {
        const det = await this.cxctrnretencionesService.getDetConencasilProm(this.asiento, 'CXC');
        console.log('----***',det);
        let sum = 0;
        for (const aux of det) {
          sum=aux.DETASI_IMPORTE + sum;
        }
        console.log('----***',Number(sum.toFixed(2)));
        if(Number(sum.toFixed(2)) !== 0){
          document.getElementById("errasiento").style.display = '';
        }else{
          document.getElementById("errasiento").style.display = 'none';
        }
      }else{
        document.getElementById("errasiento").style.display = 'none';
      } 
    });
    //GSRF
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  insertarEleTrnDoc() {
    if (this.btnGuardar === false) {
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'error',
        summary: 'Información',
        detail: 'Primero guarde el documento'
      });
    } else {
      this.abrirClave('firmarDoc');
    }
  }

  abrirClave(accion) {
    this.displayAcciones = false;
    this.accionClave = accion;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
    this.claveDialog = true;
  }

  comprobarClave() {
    this.cxctrnretencionesService.obtenerClave(this.accionClave).subscribe((clave) => {
      // if (this.accionClave === 'firmarDoc') {
      //   let claveReal;
      //   claveReal = clave[0].AUTORIZACION_CLAVE;
      //   if (claveReal === this.claveAuth) {
      //     this.claveDialog = false;
      //     this.firmarDoc();
      //     this.claveAuth = '';
      //   } else if (clave !== this.claveAuth) {
      //     this.message.add({
      //       key: 'autorizacion',
      //       severity: 'error',
      //       summary: 'Clave Autorización',
      //       detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
      //     });
      //   }
      // } else if (this.accionClave === 'activarXML') {
      //   let claveReal;
      //   claveReal = clave[0].AUTORIZACION_CLAVE;
      //   if (claveReal === this.claveAuth) {
      //     this.claveDialog = false;
      //     this.botonXML = false;
      //     this.botonReenviarCorreo = false;
      //     this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '/CXC'), 'A',
      //       '', '01', '', '', '', '').subscribe(() => {
      //       });
      //     this.claveAuth = '';
      //   } else if (clave !== this.claveAuth) {
      //     this.message.add({
      //       key: 'autorizacion',
      //       severity: 'error',
      //       summary: 'Clave Autorización',
      //       detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
      //     });
      //   }
      // } 
     // else {
        let claveReal;
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          this.confirmarAnularCar();
          this.claveAuth = '';
        } else if (clave !== this.claveAuth) {
          this.message.add({
            key: 'autorizacion',
            severity: 'error',
            summary: 'Clave Autorización',
            detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
          });
        }
      //}
    });
  }

  // firmarDoc() {
  //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC !== 'ND') {
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'error',
  //       summary: 'Firmar Documento',
  //       detail: 'Esta opción solo esta disponible para notas de débito'
  //     });
  //     return;
  //   } else {
  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'VA') {
  //       this.message.add({
  //         key: 'cxctrnretenciones',
  //         severity: 'error',
  //         summary: 'Firmar Documento',
  //         detail: 'El documento ya fue procesado'
  //       });
  //       return;
  //     } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'AT' ||
  //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'NT' || this.booleanAnulado === true) {
  //       this.message.add({
  //         key: 'cxctrnretenciones',
  //         severity: 'error',
  //         summary: 'Firmar Documento',
  //         detail: 'Documento no puede ser autorizado'
  //       });
  //       return;
  //     } else {
  //       this.confirmationService.confirm({
  //         message: 'Esta seguro de firmar el documento',
  //         header: 'Firmar Documento',
  //         icon: 'pi pi-exclamation-triangle',
  //         key: 'conftrnretenciones',
  //         acceptLabel: 'Si',
  //         rejectLabel: 'No',
  //         accept: () => {
  //           this.confirmationService.close();
  //           const strNumDoc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
  //           const strFecha = this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
  //           this.cxctrnretencionesService.firmarDoc(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
  //             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE = 'VA';
  //             this.botonAnular = false;
  //             this.message.add({
  //               key: 'cxctrnretenciones',
  //               severity: 'success',
  //               summary: 'Información',
  //               detail: 'Proceso de firma electrónica iniciado correctamente.'
  //             });
  //             const data = await this.utilitariosService.procesoFirmaElectronica(strNumDoc, strFecha);
  //             if (data !== 'NOAUTORIZADO') {
  //               this.message.add({
  //                 key: 'cxctrnretenciones',
  //                 severity: 'warn',
  //                 summary: 'Información',
  //                 detail: data
  //               });
  //             }
  //             this.traerDatosAutorizacion();
  //           });
  //         },
  //         reject: () => {
  //           this.confirmationService.close();
  //         }
  //       });
  //     }
  //   }
  // }

  // traerDatosAutorizacion() {
  //   this.cxctrnretencionesService.cargarDatosDoc(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((resDoc) => {
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE = resDoc[0].TRNCOBROND_ESTADO_FE;
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_AUTORIZACION_FE = resDoc[0].TRNCOBROND_AUTORIZACION_FE;
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_CLAVEACCESO_FE = resDoc[0].TRNCOBROND_CLAVEACCESO_FE;
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'success',
  //       summary: 'Firmar Documento',
  //       detail: 'El documento fue firmado correctamente'
  //     });
  //   });
  // }

  // reenviarCorreo() {
  //   if (this.booleanAnulado === true) {
  //     return;
  //   }
  //   this.cxctrnretencionesService.cargarDatosDoc(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((resDoc) => {
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE = resDoc[0].TRNCOBROND_ESTADO_FE;
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_AUTORIZACION_FE = resDoc[0].TRNCOBROND_AUTORIZACION_FE;
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_CLAVEACCESO_FE = resDoc[0].TRNCOBROND_CLAVEACCESO_FE;
  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'NT') {
  //       this.cxctrnretencionesService.reenviarCorreo(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(async () => {
  //         const data = await this.utilitariosService.reenviarCorreo(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
  //         this.message.add({
  //           key: 'cxctrnretenciones',
  //           severity: 'success',
  //           summary: 'Información',
  //           detail: data
  //         });
  //         this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + 'CXC_REENVIAR_CORREO'), 'A',
  //           '', '01', '', '', '', '').subscribe();
  //       });
  //     } else {
  //       this.message.add({
  //         key: 'cxctrnretenciones',
  //         severity: 'error',
  //         summary: 'Error',
  //         detail: 'Solo puede reenviar el correo si la factura esta notificada'
  //       });
  //     }
  //   });
  // }

  abrirFacturasAbonadas() {
    this.codClienteFac = '';
    this.nombreClienteFac = '';
    this.nroFacCancelada = '';
    this.cxctrnretencionesService.cxctrnfaccanceladasarray[this.indicador] = [];
    this.dialogFacCanceladas = true;
  }

  buscarFacturas() {
    this.cxctrnretencionesService.getDocCobrados(this.codClienteFac, this.nroFacCancelada).subscribe((facpendientes) => {
      facpendientes.map((facturas) => {
        facturas.TRNCOBRO_FECHATRN = this.datePipe.transform(facturas.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
      });
      this.cxctrnretencionesService.cxctrnfaccanceladasarray[this.indicador] = facpendientes;
    });
  }

  // seleccionFacPendiente(valor) {
  //   if (valor.object !== undefined) {
  //     this.facturaPend = valor.object;
  //     this.codClienteFac = valor.object.CLI_CODIGO;
  //     this.cxctrnretencionesService.obtenerClienteNom(this.codClienteFac).subscribe((res) => {
  //       this.nombreClienteFac = res[0].CLI_NOMBRE;
  //       this.nroFacCancelada = valor.object.TRNDOCUMENTO_NRODOC;
  //     });
  //   }
  //}

  seleccionarCartera() {
    this.obtenerDatos(this.facturaPend.TRNCOBRO_NRODOC);
    this.dialogFacCanceladas = false;
  }

  cambio(cambio) {
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador] !== undefined) {
      this.btnGuardar = false;
      this.btnRegresar = false;
      this.btnNuevo = true;
      this.btnNuevoGrid = false;
    }
  }

  nuevoAnexos() {

    this.anexoSeleccionado.DESCTIPOCOMPMODIFICADO = '';
    this.anexoSeleccionado.DESIDSUSTRIB = '';
    this.anexoSeleccionado.DESPERIODOINF = '';
    this.anexoSeleccionado.FECHACONTAB = '';
    this.anexoSeleccionado.FECHAEMISIONCOMP = '';
    this.anexoSeleccionado.IDPROVEEDOR = '';
    this.anexoSeleccionado.IDSUSTRIB = '';
    this.anexoSeleccionado.PERIODOINF = '';

    this.anexoSeleccionado.SECUENCIALCOMP = '';
    this.anexoSeleccionado.SERIECOMP = '';
    this.anexoSeleccionado.COM_CODIGO = '';
    this.anexoSeleccionado.DESCIDCRETRI = '';
    this.anexoSeleccionado.DESCTIPOCOMP = '';
    this.anexoSeleccionado.TRNANEX_AUTDOCMOD = '';
    this.anexoSeleccionado.TRNANEX_AUTORIZACION = '';
    this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA = 0.00;
    this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA = 0.00;
    this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO = 0.00;
    this.anexoSeleccionado.TRNANEX_IDCRETRI = '';
    this.anexoSeleccionado.TRNANEX_MONTOICE = 0.00;
    this.anexoSeleccionado.TRNANEX_MONTOIVA = 0.00;
    this.anexoSeleccionado.TRNANEX_MONTORETIVABIE = 0.00;
    this.anexoSeleccionado.TRNANEX_MONTORETIVASER = 0.00;
    this.anexoSeleccionado.TRNANEX_RETVACIEN = 0.00;
    this.anexoSeleccionado.TRNANEX_SECDOCMOD = '';
    this.anexoSeleccionado.TRNANEX_SERIEDOCMOD = '';
    this.anexoSeleccionado.TRNANEX_TIPCOM = '';
    this.anexoSeleccionado.TRNANEX_TIPCOMMOD = '';
    this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = '';
    this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = '';
    this.anexoSeleccionado.TRNCOBRO_NRODOC = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
  }

  // async cargarAnexos() {
  //   this.nuevoAnexos();
  //   const res2 = await this.cxctrnretencionesService.obtenerDatosAnexosProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
  //   for (const data of res2) {
  //     this.anexoSeleccionado.DESCTIPOCOMPMODIFICADO = data.DESCTIPOCOMPMODIFICADO === null ? '' : data.DESCTIPOCOMPMODIFICADO;
  //     this.anexoSeleccionado.DESIDSUSTRIB = data.DESIDSUSTRIB === null ? '' : data.DESIDSUSTRIB;
  //     this.anexoSeleccionado.DESPERIODOINF = data.DESPERIODOINF === null ? '' : data.DESPERIODOINF;
  //     this.anexoSeleccionado.FECHACONTAB = data.FECHACONTAB === null ? '' : data.FECHACONTAB;
  //     this.anexoSeleccionado.FECHAEMISIONCOMP = data.FECHAEMISIONCOMP === null ? '' : data.FECHAEMISIONCOMP;
  //     this.anexoSeleccionado.IDPROVEEDOR = data.IDPROVEEDOR === null ? '' : data.IDPROVEEDOR;
  //     this.anexoSeleccionado.IDSUSTRIB = data.IDSUSTRIB === null ? '' : data.IDSUSTRIB;
  //     this.anexoSeleccionado.PERIODOINF = data.PERIODOINF === null ? '' : data.PERIODOINF;
  //     this.anexoSeleccionado.SECUENCIALCOMP = data.SECUENCIALCOMP === null ? '' : data.SECUENCIALCOMP;
  //     this.anexoSeleccionado.SERIECOMP = data.SERIECOMP === null ? '' : data.SERIECOMP;

  //     const er = await this.cxctrnretencionesService.erCountTrnDatosAnexProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);

  //     if (Number(er[0].CONTADOR) === 0) {
  //       this.anexoSeleccionado.TRNANEX_SERIEDOCMOD = data.SERIEDOCMODIF === null ? '' : data.SERIEDOCMODIF;
  //       this.anexoSeleccionado.TRNANEX_SECDOCMOD = data.SECUENCIADOCMODIF === null ? '' : data.SECUENCIADOCMODIF;
  //       this.anexoSeleccionado.TRNANEX_AUTDOCMOD = data.AUTORIZACIONDOCMODIF === null ? '' : data.AUTORIZACIONDOCMODIF;
  //     }


  //   }

  //   const res = await this.cxctrnretencionesService.getAnexosProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);

  //   for (const data of res) {
  //     this.anexoSeleccionado.COM_CODIGO = data.COM_CODIGO;
  //     this.anexoSeleccionado.DESCIDCRETRI = data.DESCIDCRETRI;
  //     this.anexoSeleccionado.DESCTIPOCOMP = data.DESCTIPOCOMP;
  //     this.anexoSeleccionado.TRNANEX_AUTDOCMOD = data.TRNANEX_AUTDOCMOD;
  //     this.anexoSeleccionado.TRNANEX_AUTORIZACION = data.TRNANEX_AUTORIZACION;
  //     this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA = data.TRNANEX_BASEIMPGRABADAIVA.toFixed(2);
  //     this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA = data.TRNANEX_BASEIMPNOOBJETOIVA.toFixed(2);
  //     this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO = data.TRNANEX_BASEIMPTARCERO.toFixed(2);
  //     this.anexoSeleccionado.TRNANEX_IDCRETRI = data.TRNANEX_IDCRETRI;
  //     this.anexoSeleccionado.TRNANEX_MONTOICE = data.TRNANEX_MONTOICE.toFixed(2);
  //     this.anexoSeleccionado.TRNANEX_MONTOIVA = data.TRNANEX_MONTOIVA.toFixed(2);
  //     this.anexoSeleccionado.TRNANEX_MONTORETIVABIE = data.TRNANEX_MONTORETIVABIE.toFixed(2);
  //     this.anexoSeleccionado.TRNANEX_MONTORETIVASER = data.TRNANEX_MONTORETIVASER.toFixed(2);
  //     this.anexoSeleccionado.TRNANEX_RETVACIEN = data.TRNANEX_RETVACIEN.toFixed(2);
  //     this.anexoSeleccionado.TRNANEX_SECDOCMOD = data.TRNANEX_SECDOCMOD;
  //     this.anexoSeleccionado.TRNANEX_SERIEDOCMOD = data.TRNANEX_SERIEDOCMOD;
  //     this.anexoSeleccionado.TRNANEX_TIPCOM = data.TRNANEX_TIPCOM;
  //     this.anexoSeleccionado.TRNANEX_TIPCOMMOD = data.TRNANEX_TIPCOMMOD;
  //     this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = data.TRNANEX_TIPOSRIGRAVADA;
  //     this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = data.TRNANEX_TIPOSRITARCERO;
  //     this.anexoSeleccionado.TRNCOBRO_NRODOC = data.TRNCOBRO_NRODOC;
  //   }
  //   // console.log(this.anexoSeleccionado);
  //   await this.pasaTipodocumento();
  // }

  // cargarAnexosOld() {
  //   this.nuevoAnexos();
  //   this.cxctrnretencionesService.obtenerDatosAnexos(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res2) => {
  //     for (const data of res2) {
  //       this.anexoSeleccionado.DESCTIPOCOMPMODIFICADO = data.DESCTIPOCOMPMODIFICADO === null ? '' : data.DESCTIPOCOMPMODIFICADO;
  //       this.anexoSeleccionado.DESIDSUSTRIB = data.DESIDSUSTRIB === null ? '' : data.DESIDSUSTRIB;
  //       this.anexoSeleccionado.DESPERIODOINF = data.DESPERIODOINF === null ? '' : data.DESPERIODOINF;
  //       this.anexoSeleccionado.FECHACONTAB = data.FECHACONTAB === null ? '' : data.FECHACONTAB;
  //       this.anexoSeleccionado.FECHAEMISIONCOMP = data.FECHAEMISIONCOMP === null ? '' : data.FECHAEMISIONCOMP;
  //       this.anexoSeleccionado.IDPROVEEDOR = data.IDPROVEEDOR === null ? '' : data.IDPROVEEDOR;
  //       this.anexoSeleccionado.IDSUSTRIB = data.IDSUSTRIB === null ? '' : data.IDSUSTRIB;
  //       this.anexoSeleccionado.PERIODOINF = data.PERIODOINF === null ? '' : data.PERIODOINF;
  //       this.anexoSeleccionado.SECUENCIALCOMP = data.SECUENCIALCOMP === null ? '' : data.SECUENCIALCOMP;
  //       this.anexoSeleccionado.SERIECOMP = data.SERIECOMP === null ? '' : data.SERIECOMP;

  //       this.cxctrnretencionesService.erCountTrnDatosAnex(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(er => {
  //         if (Number(er[0].CONTADOR) === 0) {
  //           this.anexoSeleccionado.TRNANEX_SERIEDOCMOD = data.SERIEDOCMODIF === null ? '' : data.SERIEDOCMODIF;
  //           this.anexoSeleccionado.TRNANEX_SECDOCMOD = data.SECUENCIADOCMODIF === null ? '' : data.SECUENCIADOCMODIF;
  //           this.anexoSeleccionado.TRNANEX_AUTDOCMOD = data.AUTORIZACIONDOCMODIF === null ? '' : data.AUTORIZACIONDOCMODIF;
  //         }
  //       });
  //     }
  //     this.cxctrnretencionesService.getAnexos(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
  //       for (const data of res) {
  //         this.anexoSeleccionado.COM_CODIGO = data.COM_CODIGO;
  //         this.anexoSeleccionado.DESCIDCRETRI = data.DESCIDCRETRI;
  //         this.anexoSeleccionado.DESCTIPOCOMP = data.DESCTIPOCOMP;
  //         this.anexoSeleccionado.TRNANEX_AUTDOCMOD = data.TRNANEX_AUTDOCMOD;
  //         this.anexoSeleccionado.TRNANEX_AUTORIZACION = data.TRNANEX_AUTORIZACION;
  //         this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA = data.TRNANEX_BASEIMPGRABADAIVA.toFixed(2);
  //         this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA = data.TRNANEX_BASEIMPNOOBJETOIVA.toFixed(2);
  //         this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO = data.TRNANEX_BASEIMPTARCERO.toFixed(2);
  //         this.anexoSeleccionado.TRNANEX_IDCRETRI = data.TRNANEX_IDCRETRI;
  //         this.anexoSeleccionado.TRNANEX_MONTOICE = data.TRNANEX_MONTOICE.toFixed(2);
  //         this.anexoSeleccionado.TRNANEX_MONTOIVA = data.TRNANEX_MONTOIVA.toFixed(2);
  //         this.anexoSeleccionado.TRNANEX_MONTORETIVABIE = data.TRNANEX_MONTORETIVABIE.toFixed(2);
  //         this.anexoSeleccionado.TRNANEX_MONTORETIVASER = data.TRNANEX_MONTORETIVASER.toFixed(2);
  //         this.anexoSeleccionado.TRNANEX_RETVACIEN = data.TRNANEX_RETVACIEN.toFixed(2);
  //         this.anexoSeleccionado.TRNANEX_SECDOCMOD = data.TRNANEX_SECDOCMOD;
  //         this.anexoSeleccionado.TRNANEX_SERIEDOCMOD = data.TRNANEX_SERIEDOCMOD;
  //         this.anexoSeleccionado.TRNANEX_TIPCOM = data.TRNANEX_TIPCOM;
  //         this.anexoSeleccionado.TRNANEX_TIPCOMMOD = data.TRNANEX_TIPCOMMOD;
  //         this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = data.TRNANEX_TIPOSRIGRAVADA;
  //         this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = data.TRNANEX_TIPOSRITARCERO;
  //         this.anexoSeleccionado.TRNCOBRO_NRODOC = data.TRNCOBRO_NRODOC;
  //       }
  //       // console.log(this.anexoSeleccionado);
  //       this.pasaTipodocumentoOld();
  //     });
  //   });
  // }

  // confirmarEliminarFormaPago() {
  //   this.confirmationService.confirm({
  //     message: 'Esta seguro que desea eliminar el registro seleccionado',
  //     header: 'Confirmación',
  //     icon: 'pi pi-exclamation-triangle',
  //     key: 'conftrnretenciones',
  //     acceptLabel: 'Si',
  //     rejectLabel: 'No',
  //     accept: () => {
  //       this.confirmationService.close();
  //       this.eliminarFormaPago();
  //     },
  //     reject: () => {
  //       this.confirmationService.close();
  //     }
  //   });
  // }

  // eliminarFormaPago() {
  //   //this.editpagofr = false;
  //   if (this.detalleFormaPago !== undefined) {
  //     this.editpagofr = false;
  //     this.btnNuevoGrid = false;
  //     this.btnGuardarGrid = false;
  //     this.btnBorrarGrid = false;
  //     this.btnCancelarGrid = false;
  //     if (this.detalleFormaPago.TRNCOBRO_NRODOC === '') {
  //       this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].filter((val, j) => j !==
  //         this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].indexOf(this.detalleFormaPago));
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
  //       this.cxctrnretencionesService.calcularTotalFormaPago();
  //     } else {
  //       this.cxctrnretencionesService.eliminarFormaPago(this.detalleFormaPago.TRNCOBRO_NRODOC).subscribe(() => {
  //         if (this.detalleFormaPago.TRNPAGO_TIPO === 'DP') {
  //           this.cxctrnretencionesService.eliminarDPBanco(this.detalleFormaPago.TRNPAGO_NUMERO).subscribe(() => {
  //           });
  //         }
  //         this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador] = this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].filter((val, j) => j !==
  //           this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].indexOf(this.detalleFormaPago));
  //         this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
  //         this.auditoriagral.registrarAuditoria('CXC_TRNPAGO',
  //           String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '/' +
  //             this.detalleFormaPago.TRNPAGO_NUMERO), 'I',
  //           '', '01', '', '', '', '').subscribe(() => {
  //           });
  //         this.cxctrnretencionesService.calcularTotalFormaPago();
  //       });
  //     }
  //   }
  // }

  // cancelarFormaPago() {
  //   this.editpagofr = true;
  //   if (this.detalleFormaPago.LINEA !== undefined && this.detalleFormaPago.TRNPAGO_TIPO.length === 0) {
  //     this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].splice(
  //       this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].findIndex(det =>
  //         det.LINEA === this.detalleFormaPago.LINEA && det.TRNPAGO_TIPO === this.detalleFormaPago.TRNPAGO_TIPO), 1);
  //     if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length > 0) {
  //       this.detalleFormaPago = this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador]
  //       [this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length - 1];
  //     }
  //     this.cxctrnretencionesService.calcularTotalFormaPago();
  //     this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
  //   }
  //   /*
  //     if (this.detalleFormaPago.LINEA === 0) {
  //       this.btnNuevoGrid = false;
  //       this.btnGuardarGrid = true;
  //       this.cxctrnretencionesService.cxctrnformapago = this.cxctrnretencionesService.cxctrnformapago.filter((val, j) => j !==
  //         this.cxctrnretencionesService.cxctrnformapago.indexOf(this.detalleFormaPago));
  //       this.detalleFormaPago = {};
  //     } else if (this.detalleFormaPago.LINEA !== 0) {
  //       this.btnNuevoGrid = false;
  //       this.btnGuardarGrid = true;
  //       this.cxctrnretencionesService.obtenerFormadePago(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
  //         this.cxctrnretencionesService.cxctrnformapago = res;
  //         this.cxctrnretencionesService.cxctrnformapago.map((detalle) => {
  //           if (detalle.LINEA === this.detalleFormaPago.LINEA) {
  //             this.detalleFormaPagoAux
  //               = detalle;
  //           }
  //         });
  //         this.detalleFormaPago = this.detalleFormaPagoAux;
  //         this.cxctrnretencionesService.calcularTotalFormaPago();
  //       });
  //     }*/
  // }

  // confirmarEliminarOtrosG() {
  //   this.confirmationService.confirm({
  //     message: 'Esta seguro que desea eliminar el registro seleccionado',
  //     header: 'Confirmación',
  //     icon: 'pi pi-exclamation-triangle',
  //     key: 'conftrnretenciones',
  //     acceptLabel: 'Si',
  //     rejectLabel: 'No',
  //     accept: () => {
  //       this.confirmationService.close();
  //       this.eliminarOtrosG();
  //     },
  //     reject: () => {
  //       this.confirmationService.close();
  //     }
  //   });
  // }

  // eliminarOtrosG() {
  //   if (this.detalleOtrasFSeleccionada !== undefined) {
  //     this.editpagofr = false;
  //     this.btnNuevoGrid = false;
  //     this.btnGuardarGrid = false;
  //     this.btnBorrarGrid = false;
  //     this.btnCancelarGrid = false;
  //     if (this.detalleOtrasFSeleccionada.TRNCOBRO_NRODOC === '') {
  //       this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].filter((val, j) => j !==
  //         this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleOtrasFSeleccionada));
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCarteraOtrosG.objeto);
  //       this.cxctrnretencionesService.calcularTotalOtrosG();
  //     } else {
  //       this.cxctrnretencionesService.eliminarCaja(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
  //         this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
  //           '', '01', '', '', '', '').subscribe(() => {
  //           });
  //       });
  //       this.cxctrnretencionesService.eliminarBanco(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
  //         this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
  //           '', '01', '', '', '', '').subscribe(() => {
  //           });
  //       });
  //       this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].filter((val, j) => j !==
  //         this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleFormaPago));
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCarteraOtrosG.objeto);
  //       this.cxctrnretencionesService.calcularTotalOtrosG();
  //     }
  //   }
  // }

  // cancelarOtrosG() {
  //   if (this.detalleOtrasFSeleccionada.LINEA === 0) {
  //     this.btnNuevoGrid = false;
  //     this.btnGuardarGrid = true;
  //     this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].filter((val, j) => j !==
  //       this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].indexOf(this.detalleFormaPago));
  //     this.detalleFormaPago = {};
  //   } else if (this.detalleOtrasFSeleccionada.LINEA !== 0) {
  //     this.btnNuevoGrid = false;
  //     this.btnGuardarGrid = true;
  //     this.cxctrnretencionesService.obtenerOtrosGastos(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
  //       this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador] = res;
  //       this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].map((detalle) => {
  //         if (detalle.LINEA === this.detalleOtrasFSeleccionada.LINEA) {
  //           this.detalleOtrasFSeleccionadaAux
  //             = detalle;
  //         }
  //       });
  //       this.detalleOtrasFSeleccionada = this.detalleOtrasFSeleccionadaAux;
  //       this.cxctrnretencionesService.calcularTotalOtrosG();
  //     });
  //   }
  // }

  confirmarEliminarRetencion() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el registro seleccionado',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'conftrnretenciones',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.eliminarRetencion();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  eliminarRetencion() {
    if (this.detalleRetencionSeleccionado !== undefined) {
      this.editpagofr = false;
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = false;
      this.btnBorrarGrid = false;
      this.btnCancelarGrid = false;
      if (this.detalleRetencionSeleccionado.NUEVALINEA === 0) {
        this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].filter((val, j) => j !==
          this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado));
        this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto);
        this.cxctrnretencionesService.calcularTotalRetenido(0);
      } else {
        // this.cxctrnretencionesService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' +
        //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(() => {
        //     this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].filter((val, j) => j !==
        //       this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado));
        //     this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto);
        //     this.cxctrnretencionesService.calcularTotalRetenido(0);
        //   });
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Cartera Activa',
          detail: 'No es posible eliminar las retenciones guardadas. Elimine y vuelva a ingresar todo el documento.'
        });
        return;
      }
    }
  }

  cancelarRetencion() {
    if (this.detalleRetencionSeleccionado.LINEA === 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].filter((val, j) => j !==
        this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].indexOf(this.detalleRetencionSeleccionado));
      this.detalleFormaPago = {};
    } else if (this.detalleRetencionSeleccionado.LINEA !== 0) {
      this.btnNuevoGrid = false;
      this.btnGuardarGrid = true;
      this.cxctrnretencionesService.obtenerRetenciones(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.cxctrnretencionesService.cxctrnretencionarray[this.indicador] = res;
        this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].map((detalle) => {
          if (detalle.LINEA === this.detalleRetencionSeleccionado.LINEA) {
            this.detalleRetencionSeleccionadoAux
              = detalle;
          }
        });
        this.detalleRetencionSeleccionado = this.detalleRetencionSeleccionadoAux;
        this.cxctrnretencionesService.calcularTotalRetenido(0);
      });
    }
  }

  // confirmarEliminarFormaPagoDev() {
  //   this.confirmationService.confirm({
  //     message: 'Esta seguro que desea eliminar el registro seleccionado',
  //     header: 'Confirmación',
  //     icon: 'pi pi-exclamation-triangle',
  //     key: 'conftrnretenciones',
  //     acceptLabel: 'Si',
  //     rejectLabel: 'No',
  //     accept: () => {
  //       this.confirmationService.close();
  //       this.eliminarFormaPagoDev();
  //     },
  //     reject: () => {
  //       this.confirmationService.close();
  //     }
  //   });
  // }

  // eliminarFormaPagoDev() {
  //   //this.editpagofr = false;
  //   if (this.detalleFormaPagoDev !== undefined) {
  //     this.editpagofr = false;
  //     this.btnNuevoGrid = false;
  //     this.btnGuardarGrid = false;
  //     this.btnBorrarGrid = false;
  //     this.btnCancelarGrid = false;
  //     if (this.detalleFormaPagoDev.LINEA === 0) {
  //       this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].filter((val, j) => j !==
  //         this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].indexOf(this.detalleFormaPagoDev));
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador], this.defaultColumnDefCarteraFormaPagoDev.objeto);
  //       this.cxctrnretencionesService.calcularTotalFormaPagoDev();
  //     } else {
  //       this.cxctrnretencionesService.eliminarCaja(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
  //         this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
  //           '', '01', '', '', '', '').subscribe(() => {
  //           });
  //       });
  //       this.cxctrnretencionesService.eliminarBanco(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
  //         this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
  //           '', '01', '', '', '', '').subscribe(() => {
  //           });
  //       });
  //       this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].filter((val, j) => j !==
  //         this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].indexOf(this.detalleFormaPagoDev));
  //       this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador], this.defaultColumnDefCarteraFormaPagoDev.objeto);
  //       this.cxctrnretencionesService.calcularTotalFormaPagoDev();
  //     }
  //   }
  // }

  // cancelarFormaPagoDev() {
  //   this.editpagofr = true;
  //   if (this.detalleFormaPagoDev.LINEA === 0) {
  //     this.btnNuevoGrid = false;
  //     this.btnGuardarGrid = true;
  //     this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].filter((val, j) => j !==
  //       this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].indexOf(this.detalleFormaPagoDev));
  //     this.detalleFormaPagoDev = {};
  //   } else if (this.detalleFormaPagoDev.LINEA !== 0) {
  //     this.btnNuevoGrid = false;
  //     this.btnGuardarGrid = true;
  //     this.cxctrnretencionesService.obtenerFormadePagoDev(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
  //       this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador] = res;
  //       this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].map((detalle) => {
  //         if (detalle.LINEA === this.detalleFormaPagoDev.LINEA) {
  //           this.detalleFormaPagoDevAux
  //             = detalle;
  //         }
  //       });
  //       this.detalleFormaPagoDev = this.detalleFormaPagoDevAux;
  //       this.cxctrnretencionesService.calcularTotalFormaPagoDev();
  //     });
  //   }
  // }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }

  reversarProceso() {
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      return;
    }
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE !== 'AUT' &&
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE !== 'NT') {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea reversar el proceso?',
        header: 'Reversar Proceso',
        icon: 'pi pi-exclamation-triangle',
        key: 'conftrnretenciones',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          this.utilitariosService.reversarProcesoFE(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC, '05');
          this.utilitariosService.reversarProcesoFEClave(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_CLAVEACCESO_FE);
          this.auditoriagral.registrarAuditoria('ELE_TRNDOCUMENTO',
            String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + 'CXC_REVERSAR'), 'E',
            '', '01', '', '', '', '').subscribe(() => {
              this.botonXML = true;
              this.botonReenviarCorreo = true;
              this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE = '';
              this.message.add({
                key: 'carte',
                severity: 'success',
                summary: 'Reversar Proceso',
                detail: 'Reversar proceso de inicio de firma electrónica terminó satisfactoriamente'
              });
            });
        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }
  }

  // abrirLog() {
  //   if (this.booleanAnulado === false) {
  //     this.cxctrnretencionesService.obtenerLogFE(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC, '06').subscribe((res) => {
  //       if (res.length !== 0) {
  //         if (res[0] !== null) {
  //           this.logResultado = JSON.stringify(res[0].TRNDOC_RESULTADO);
  //         }
  //       }
  //       this.displayLog = true;
  //       this.cerrarSideBar();
  //     });
  //   } else {
  //     return;
  //   }
  // }

  cerrarSideBar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  activarBoton() {
    this.abrirClave('activarXML');
  }

  // refrescarDatosFE() {
  //   this.cxctrnretencionesService.cargarDatosDoc(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((resDoc) => {
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE = resDoc[0].TRNCOBROND_ESTADO_FE;
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_AUTORIZACION_FE = resDoc[0].TRNCOBROND_AUTORIZACION_FE;
  //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_CLAVEACCESO_FE = resDoc[0].TRNCOBROND_CLAVEACCESO_FE;
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'success',
  //       summary: 'Datos Facturación Electrónica',
  //       detail: 'Se han actualizado los datos de la facturación electrónica'
  //     });
  //   });
  // }

  calcularTotalVarios(): number {
    let dblTotalVarios = 0;

    for (let fila = 1; fila <= this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length; fila++) {
      const item = this.cxctrnretencionesService.cxctrnvariosarray[this.indicador][fila - 1];
      dblTotalVarios = dblTotalVarios + Number(item.DEBE) - Number(item.HABER);
      item.DEBE = Number(item.DEBE).toFixed(2);
      item.HABER = Number(item.HABER).toFixed(2);
    }

    return Number(dblTotalVarios);
  }

  // validacionTiposSri() {
  //   let state = true;
  //   let mensaje = '';

  //   if ((this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO === null ? 0 : this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO) > 0) {
  //     if (this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO === undefined) {
  //       this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = '';
  //     }
  //     if (this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO.length === 0) {
  //       state = false;
  //       mensaje = 'El tipo de SRI es obligatorio para la Base Imp. Tarifa 0%. si este es mayor que cero.';
  //     }
  //   } else {
  //     this.anexoSeleccionado.TRNANEX_TIPOSRITARCERO = '';
  //   }

  //   if ((this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA === null ? 0 : this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA) > 0) {
  //     if (this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA === undefined) {
  //       this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = '';
  //     }
  //     if (this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA.length === 0) {
  //       state = false;
  //       mensaje = 'El tipo de SRI es obligatorio para la  Base Imp. Gravada de IVA. si este es mayor que cero.';
  //     }
  //   } else {
  //     this.anexoSeleccionado.TRNANEX_TIPOSRIGRAVADA = '';
  //   }

  //   if (state === true) {
  //     this.guardarDatosAnexos();
  //   } else {
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'error',
  //       summary: 'Información',
  //       detail: mensaje
  //     });
  //   }

  // }

  verificarTotalImpuestos(): boolean {
    let dblBaseImpTarifa0 = 0;
    let dblBaseImpGrabadaIva = 0;
    let dblMontoIva = 0;
    let dblBaseImpNoObjetoIva = 0;
    let dblMontoIce = 0;
    let dblTotalImporteDoc = 0;
    let dblTotalImpuestos = 0;

    dblBaseImpTarifa0 = Number(this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO);
    dblBaseImpGrabadaIva = Number(this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA);
    dblMontoIva = Number(this.anexoSeleccionado.TRNANEX_MONTOIVA);
    dblBaseImpNoObjetoIva = Number(this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA);
    dblMontoIce = Number(this.anexoSeleccionado.TRNANEX_MONTOICE);

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
      dblTotalImporteDoc = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE);
    } else {
      dblTotalImporteDoc = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALNC);
    }

    dblTotalImpuestos = Number(dblBaseImpTarifa0) + Number(dblBaseImpGrabadaIva) + Number(dblMontoIva) +
      Number(dblBaseImpNoObjetoIva) + Number(dblMontoIce);

    console.log(dblTotalImporteDoc, '!==', dblTotalImpuestos);
    if (dblTotalImporteDoc !== dblTotalImpuestos) {
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'error',
        summary: 'Información',
        detail: 'El total de los impuestos debe de ser igual al valor del documento $' + dblTotalImporteDoc
      });
      return false;
    }

    return true;

  }

  faltaDatos(): boolean {
    let bolFalta = false;

    // verificar si la autorización tiene 49 dígitos
    if (this.anexoSeleccionado.TRNANEX_AUTORIZACION === null ||
      this.anexoSeleccionado.TRNANEX_AUTORIZACION === undefined) {
      this.anexoSeleccionado.TRNANEX_AUTORIZACION = '';
    }

    if (this.anexoSeleccionado.TRNANEX_AUTORIZACION.length > 49) {
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'error',
        summary: 'Error',
        detail: 'El número de autorización debe tener 49 dígitos.'
      });
      bolFalta = true;
      return bolFalta;
    }

    // verificar si la autorización del documento origen tiene 49 dígitos

    if (this.anexoSeleccionado.TRNANEX_AUTDOCMOD.length > 49) {
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'error',
        summary: 'Error',
        detail: 'El número de autorización del documento modificado debe tener 49 dígitos.'
      });
      bolFalta = true;
      return bolFalta;
    }

    return bolFalta;
  }

  // guardarDatosAnexos() {

  //   if (this.verificarTotalImpuestos() === false) {
  //     return;
  //   }

  //   // VERIFICAR QUE EL NUMERO DE AUTORIZACION SEA DE 10 DIGITOS
  //   if (this.faltaDatos() === true) {
  //     return;
  //   }

  //   this.cxctrnretencionesService.guardarDatosAnexos(this.anexoSeleccionado).subscribe(() => {
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'success',
  //       summary: 'Datos Anexos',
  //       detail: 'Se guardaron los anexos correctamente'
  //     });
  //   });

  // }

  setFocus(event) {
    let element: any;
    switch (event.target.id) {
      case 'TRNANEX_IDCRETRI': {
        //this.abrirSustentoTrib();
        break;
      }
      case 'TRNANEX_AUTORIZACION': {
        element = document.getElementById('TIPOCOMPMODIF');
        break;
      }
      case 'TIPOCOMPMODIF': {
        element = document.getElementById('SERIEDOCMODIF');
        break;
      }
      case 'SERIEDOCMODIF': {
        element = document.getElementById('SECUENCIADOCMODIF');
        break;
      }
      case 'SECUENCIADOCMODIF': {
        element = document.getElementById('AUTORIZACIONDOCMODIF');
        break;
      }
      case 'AUTORIZACIONDOCMODIF': {
        element = document.getElementById('TRNANEX_BASEIMPTARCERO');
        break;
      }
      case 'TRNANEX_BASEIMPTARCERO': {
        this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO = Number(this.anexoSeleccionado.TRNANEX_BASEIMPTARCERO).toFixed(2);
        element = document.getElementById('TRNANEX_TIPOSRITARCERO');
        break;
      }
      case 'TRNANEX_TIPOSRITARCERO': {
       // this.buscarReferencia('TRNANEX_TIPOSRITARCERO');
        break;
      }
      case 'TRNANEX_BASEIMPGRABADAIVA': {
        this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA = Number(this.anexoSeleccionado.TRNANEX_BASEIMPGRABADAIVA).toFixed(2);
        element = document.getElementById('TRNANEX_TIPOSRIGRAVADA');
        break;
      }
      case 'TRNANEX_TIPOSRIGRAVADA': {
        //this.buscarReferencia('TRNANEX_TIPOSRIGRAVADA');
        break;
      }
      case 'TRNANEX_MONTOIVA': {
        this.anexoSeleccionado.TRNANEX_MONTOIVA = Number(this.anexoSeleccionado.TRNANEX_MONTOIVA).toFixed(2);
        element = document.getElementById('TRNANEX_BASEIMPNOOBJETOIVA');
        break;
      }
      case 'TRNANEX_BASEIMPNOOBJETOIVA': {
        this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA = Number(this.anexoSeleccionado.TRNANEX_BASEIMPNOOBJETOIVA).toFixed(2);
        element = document.getElementById('TRNANEX_MONTOICE');
        break;
      }
      case 'TRNANEX_MONTOICE': {
        this.anexoSeleccionado.TRNANEX_MONTOICE = Number(this.anexoSeleccionado.TRNANEX_MONTOICE).toFixed(2);
        element = document.getElementById('TRNANEX_MONTORETIVABIE');
        break;
      }
      case 'TRNANEX_MONTORETIVABIE': {
        this.anexoSeleccionado.TRNANEX_MONTORETIVABIE = Number(this.anexoSeleccionado.TRNANEX_MONTORETIVABIE).toFixed(2);
        element = document.getElementById('TRNANEX_MONTORETIVASER');
        break;
      }
      case 'TRNANEX_MONTORETIVASER': {
        this.anexoSeleccionado.TRNANEX_MONTORETIVASER = Number(this.anexoSeleccionado.TRNANEX_MONTORETIVASER).toFixed(2);
        element = document.getElementById('TRNANEX_RETVACIEN');
        break;
      }
      case 'TRNANEX_RETVACIEN': {
        this.anexoSeleccionado.TRNANEX_RETVACIEN = Number(this.anexoSeleccionado.TRNANEX_RETVACIEN).toFixed(2);
        element = document.getElementById('TRNANEX_IDCRETRI');
        break;
      }
      default: {
        break;
      }
    }

    if (element === null || element === undefined) {
      return;
    } else {
      element.focus();
    }

  }

  // async pasaTipodocumento() {
  //   let tipoDoc = '';
  //   let tipDocNombre = '';

  //   console.log(this.opcionesTipoDoc);
  //   console.log(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);

  //   for (const listadoc of this.opcionesTipoDoc) {
  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === listadoc.value) {
  //       // strTipoDoc = listaDoc.value;
  //       tipoDoc = listadoc.value;
  //       tipDocNombre = listadoc.label;
  //     }
  //   }

  //   const er = await this.cxctrnretencionesService.erRefnombrecProm(tipoDoc);

  //   if (er !== null) {
  //     if (er[0] !== null) {
  //       tipoDoc = er[0].NOMBREC;
  //     }
  //   }
  //   if (tipoDoc === '1' || tipoDoc === '4' || tipoDoc === '5') {
  //     this.anexoSeleccionado.TRNANEX_TIPCOM = tipoDoc;
  //     this.anexoSeleccionado.DESCTIPOCOMP = tipDocNombre;
  //     this.boltrnaextipcom = true;
  //   } else {
  //     this.anexoSeleccionado.TRNANEX_TIPCOM = '';
  //     this.anexoSeleccionado.DESCTIPOCOMP = '';
  //     this.boltrnaextipcom = false;
  //   }
  // }

  // pasaTipodocumentoOld() {
  //   let tipoDoc = '';
  //   let tipDocNombre = '';

  //   console.log(this.opcionesTipoDoc);
  //   console.log(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);

  //   for (const listadoc of this.opcionesTipoDoc) {
  //     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === listadoc.value) {
  //       // strTipoDoc = listaDoc.value;
  //       tipoDoc = listadoc.value;
  //       tipDocNombre = listadoc.label;
  //     }
  //   }

  //   this.cxctrnretencionesService.erRefnombrec(tipoDoc).subscribe(er => {
  //     if (er !== null) {
  //       if (er[0] !== null) {
  //         tipoDoc = er[0].NOMBREC;
  //       }
  //     }
  //     if (tipoDoc === '1' || tipoDoc === '4' || tipoDoc === '5') {
  //       this.anexoSeleccionado.TRNANEX_TIPCOM = tipoDoc;
  //       this.anexoSeleccionado.DESCTIPOCOMP = tipDocNombre;
  //       this.boltrnaextipcom = true;
  //     } else {
  //       this.anexoSeleccionado.TRNANEX_TIPCOM = '';
  //       this.anexoSeleccionado.DESCTIPOCOMP = '';
  //       this.boltrnaextipcom = false;
  //     }
  //   });
  // }

  async changeTabs() {
    if (this.activeIndex === 1) {
      if (this.bolestcta === false) {
        this.cxctmptrnresumen = [];
        this.bolestcta = true;
        this.cxctmptrnresumen = await this.cliService.getInfoEstadoCuenta(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
          'cxctrnretenciones');
      }
    }
    if (this.activeIndex === 2) {
      if (this.bolsaldo === false) {
        this.cxctrnresumen = [];
        this.bolsaldo = true;
        this.cxctrnresumen = await this.cliService.getInfoSaldos(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO,
          'cxctrnretenciones');
      }
    }
    // if (this.activeIndex === 3) {
    //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND'
    //     || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
    //     this.cargarAnexos();
    //   }
    // }
  }

  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }


  // async consultarECAnticipo() {
  //   this.cxctrnretencionesService.cxctrnanticiposarray[this.indicador] = [];
  //   this.ecanticipo = [];
  //   await this.consultarPagosConAnticipos();
  //   await this.consultarDevolucionesAnt();
  //   // this.cxctrnretencionesService.calcularTotalDoc();
  //   let totalAnticipo = 0;
  //   this.ecanticipo.map((ant) => {
  //     totalAnticipo += Number(ant.TRNPAGO_IMPORTE);
  //   });
  //   const totalAnt = { TRNPAGO_IMPORTE: totalAnticipo.toFixed(2), TRNCOBRO_NRODOC: '', TRNPAGO_TIPO: '' };
  //   this.ecanticipo.push(totalAnt);

  //   this.cxctrnretencionesService.cxctrnanticiposarray[this.indicador] = this.ecanticipo;
  // }

  // async consultarPagosConAnticipos() {
  //   const anticipo = await this.cxctrnretencionesService.obtenerAnticipos(this.cxctrnretencionesService.encabezadoCarteraActivaarray
  //   [this.indicador].TRNCOBRO_NROCOMPROBANTE);
  //   anticipo.map((ant) => {
  //     ant.TRNPAGO_FECHA = this.datePipe.transform(ant.TRNPAGO_FECHA, 'dd/MM/yyyy');
  //     ant.TRNPAGO_TIPO = 'CA/AB';
  //     ant.TRNPAGO_IMPORTE = Number(ant.TRNPAGO_IMPORTE).toFixed(2);
  //     this.ecanticipo.push(ant);
  //   });

  // }

  // async consultarDevolucionesAnt() {

  //   let strSql = 'SELECT D.*, C.TRNCOBRO_FECHATRN, C.TRNCOBRO_CONCEPTO FROM CXC_TRNDOCUMENTO D, CXC_TRNCOBRO C ';
  //   strSql = strSql + 'WHERE TRNDOCUMENTO_NRODOC = \'' + this.cxctrnretencionesService.encabezadoCarteraActivaarray
  //   [this.indicador].TRNCOBRO_NRODOC + '\'';
  //   strSql = strSql + 'AND C.TRNCOBRO_NRODOC = D.TRNCOBRO_NRODOC ';
  //   strSql = strSql + ' AND C.COM_CODIGO = \'01\' ORDER BY TRNCOBRO_FECHATRN ';

  //   try {

  //     const datos = await this.cxctrnretencionesService.consultarDevolucionesAnt(this.cxctrnretencionesService.encabezadoCarteraActivaarray
  //     [this.indicador].TRNCOBRO_NRODOC);

  //     for (const rs of datos) {
  //       const item: Cxctrnanticipos = {};

  //       item.TRNCOBRO_NRODOC = rs.TRNCOBRO_NROCOMPROBANTE;
  //       item.TRNPAGO_TIPO = 'DAN';
  //       item.TRNPAGO_FECHA = this.datePipe.transform(rs.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
  //       item.TRNPAGO_CONCEPTO = rs.TRNCOBRO_CONCEPTO;
  //       item.TRNPAGO_IMPORTE = Number(rs.TRNDOCUMENTO_IMPORTE).toFixed(2);

  //       this.ecanticipo.push(item);
  //       // listaEC.add(item);
  //     }
  //   } catch (error) {
  //   }
  // }

  async actualizarCuotasPagadas(item) {
    const data = {
      ENCFAC_NUMERO: item.TRNCOBRO_NRODOC,
      CLI_CODIGO: item.CLI_CODIGO
    };
    await this.cxctrnretencionesService.actPagadoDETCUOTA(data);
  }

  // eliminarPagosCuotas() {
  //   console.log('ELIMINAR CUOTAS');
  //   console.log(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]);
  //   if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
  //     this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((item) => {
  //       console.log(item.STRCUOTAFAC);
  //       if (item.STRCUOTAFAC === 'C') {
  //         const data = {
  //           TRNCOBRO_NRODOC: item.TRNCOBRO_NRODOC,
  //           CLI_CODIGO: this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO
  //         };
  //         this.actualizarCuotasPagadas(data);
  //       }
  //     });
  //   }
  // }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  // async cxc_fnc_verificar_ant_util(strTrnCobro) {
  //   let intSw = 0;

  //   let strSentencia = 'SELECT cxc_fnc_verificar_ant_util(\'' + strTrnCobro + '\',\'';
  //   strSentencia = strSentencia + '01\') AS DATO FROM DUAL';
  //   try {
  //     const data = await this.cxctrnretencionesService.cxc_fnc_verificar_ant_util(strTrnCobro);

  //     for (const rs of data) {
  //       intSw = rs.DATO;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'success',
  //       summary: 'Error',
  //       detail: 'No se puede verificar si existe algun anticipo asociado'
  //     });
  //   }

  //   return intSw;
  // }

  // async existePagoDoc() {
  //   let bolExiste = false;
  //   let strCobros = '';

  //   /*let strSql = 'SELECT TRNCOBRO_NROCOMPROBANTE FROM CXC_TRNDOCUMENTO WHERE TRNDOCUMENTO_NRODOC=\''
  //     + this.cxctrnretencionesService.cxctrnresumenarray[this.indicador][0].TRNCOBRO_NRODOC + '\'';
  //   strSql = strSql + ' AND COM_CODIGO=\'01\'';*/

  //   try {
  //     const data = await this.cxctrnretencionesService.existePagoDocProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);

  //     for (const rs of data) {
  //       strCobros = strCobros + rs.TRNCOBRO_NROCOMPROBANTE + '/';
  //     }

  //     if (strCobros.length > 0) {
  //       bolExiste = true;

  //       this.message.add({
  //         key: 'cxctrnretenciones',
  //         severity: 'success',
  //         summary: 'Información',
  //         detail: 'El documento no se puede eliminar, porque tiene'
  //           + ' los siguientes cobros: '
  //           + strCobros.substring(0, strCobros.length - 1)
  //       });
  //     }
  //   } catch (error) {
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'success',
  //       summary: 'Error',
  //       detail: 'No se puede verificar los cobros del documento '
  //     });
  //   }

  //   return bolExiste;
  // }

  // async chequeConciliado(strProceso) {
  //   let bolConc = false;

  //   if (this.tabFormaPagoDev !== false) {
  //     for (let fila = 1; fila <= this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].length; fila++) {
  //       if (this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_TIPO === 'CH') {
  //         if (await this.documentoConciliado(this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_NUMERO,
  //           this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador][fila - 1].BAN_CODIGO,
  //           this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_TIPO)) {
  //           if (strProceso !== 'COLOR') {
  //             if (String(strProceso).length > 0) {
  //               this.message.add({
  //                 key: 'cxctrnretenciones',
  //                 severity: 'success',
  //                 summary: 'Información',
  //                 detail: 'No puede '
  //                   + strProceso
  //                   + ' el pago, el cheque '
  //                   + this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador][fila - 1].TRNCAJ_NUMERO
  //                   + ' está conciliado.'
  //               });
  //             }
  //             bolConc = true;
  //             break;
  //           } else {
  //             // modeloFPDev.ponerColorDocConc(fila);
  //           }

  //         }
  //       }
  //     }
  //   }
  //   return bolConc;
  // }

  // async documentoConciliado(strNumDoc, strCodBanco, strTipoDoc) {
  //   let strConcilia = '';
  //   let bolConcilia = false;
  //   try {
  //     const er = await this.cxctrnretencionesService.erTrnbanconciliaProm(strNumDoc, strCodBanco, strTipoDoc);
  //     if (er !== null) {
  //       if (er[0] !== undefined) {
  //         strConcilia = er[0].TRNBAN_CONCILIA;
  //       }
  //     }

  //     console.log(strConcilia);

  //     if (Number(strConcilia) === 1) {
  //       bolConcilia = true;
  //     }
  //   } catch (error) {
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'error',
  //       summary: 'Error',
  //       detail: 'No se puede verificar si el cheque está conciliado.'
  //     });
  //   }

  //   console.log(bolConcilia);

  //   return bolConcilia;

  // }

  // async chequeDepositadoUtil(strNumCheque, strCodBanco, strDocumento) {
  //   let strDepositado = '';
  //   console.log(strNumCheque, strCodBanco, strDocumento);
  //   try {
  //     const er = await this.cxctrnretencionesService.erTrncajdepositonroProm(strNumCheque, strCodBanco, 'CH', strDocumento);
  //     if (er !== null) {
  //       if (er[0] !== undefined) {
  //         strDepositado = er[0].TRNCAJ_DEPOSITONRO === null ? '' : er[0].TRNCAJ_DEPOSITONRO;
  //       }
  //     }
  //   } catch (error1) {
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'error',
  //       summary: 'Error',
  //       detail: 'No se puede verificar si el cheque está depositado.'
  //     });
  //   }

  //   return strDepositado;
  // }

  // async chequeDepositado(strProceso) {
  //   let bolDep = false;

  //   if (this.tabFormaPago === true) {
  //     for (let fila = 1; fila <= this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length; fila++) {
  //       if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'CH') {
  //         const strNumDep = await this.chequeDepositadoUtil(
  //           this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
  //           this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].BANCLI_CODIGO,
  //           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
  //         console.log(strNumDep);
  //         if (strNumDep.length > 0) {
  //           if (strProceso !== 'COLOR') {
  //             if (String(strProceso).length > 0) {
  //               this.message.add({
  //                 key: 'cxctrnretenciones',
  //                 severity: 'error',
  //                 summary: 'Información',
  //                 detail: 'No puede '
  //                   + strProceso
  //                   + ' el cobro, el cheque '
  //                   + this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
  //                   + ' está depositado.'
  //                   + '\r\rNro. Depósito: '
  //                   + strNumDep
  //               });
  //             }
  //             bolDep = true;
  //             break;
  //           } else {
  //             this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCDEP = true;
  //             this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);

  //           }
  //         }

  //       }
  //     }
  //   }
  //   return bolDep;
  // }

  // async efectivoDepositadoUtil(strNumero, strCodCaja, strDocNro) {
  //   let strDepositado = '';

  //   console.log(strNumero, strCodCaja, strDocNro);
  //   try {
  //     const er = await this.cxctrnretencionesService.erTrncajdepositonro2Prom(strNumero, strDocNro, 'EF', strCodCaja);

  //     console.log(er);

  //     if (er !== null) {
  //       if (er[0] !== undefined) {
  //         strDepositado = er[0].TRNCAJ_DEPOSITONRO === null ? '' : er[0].TRNCAJ_DEPOSITONRO;
  //       }
  //     }
  //   } catch (error1) {
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'error',
  //       summary: 'Error',
  //       detail: 'No se puede verificar si el efectivo está depositado.'
  //     });
  //   }

  //   return strDepositado;
  // }

  // async efectivoDepositado(strProceso) {
  //   let bolDep = false;

  //   if (this.tabFormaPago === true) {
  //     for (let fila = 1; fila <= this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length; fila++) {
  //       if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'EF') {
  //         const strNumDep = await this.efectivoDepositadoUtil(
  //           this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
  //           this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].CAJ_CODIGO,
  //           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
  //         console.log(strNumDep);
  //         if (strNumDep.length > 0) {
  //           if (strProceso !== 'COLOR') {
  //             if (String(strProceso).length > 0) {
  //               this.message.add({
  //                 key: 'cxctrnretenciones',
  //                 severity: 'error',
  //                 summary: 'Información',
  //                 detail: 'No puede '
  //                   + strProceso
  //                   + ' el cobro, el efectivo '
  //                   + this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
  //                   + ' está depositado.'
  //                   + '\r\rNro. Depósito: '
  //                   + strNumDep
  //               });
  //             }
  //             bolDep = true;
  //             break;
  //           } else {
  //             this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCDEP = true;
  //             this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return bolDep;
  // }

  // async depositoConciliado(strProceso) {
  //   let bolConc = false;

  //   if (this.tabFormaPago === true) {
  //     for (let fila = 1; fila <= this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length; fila++) {
  //       if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'DP') {
  //         if (await this.documentoConciliado(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
  //           this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].BANCLI_CODIGO,
  //           this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO)) {
  //           if (strProceso !== 'COLOR') {
  //             if (strProceso.length > 0) {
  //               this.message.add({
  //                 key: 'cxctrnretenciones',
  //                 severity: 'error',
  //                 summary: 'Información',
  //                 detail: 'No puede '
  //                   + strProceso
  //                   + ' el pago, el depósito '
  //                   + this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
  //                   + ' está conciliado.'
  //               });
  //             }
  //             bolConc = true;
  //             break;
  //           } else {
  //             this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCCONC = true;
  //             this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);

  //           }
  //         }
  //       }
  //     }
  //   }
  //   return bolConc;
  // }

  // async tarjetaConciliadaUtil(strNumero, strCodCaja, strDocNro) {
  //   let bolConc = false;
  //   let strCodConc = '';
  //   try {
  //     const er = await this.cxctrnretencionesService.erTrntarjetacodigoProm(strNumero, strDocNro, 'TA', strCodCaja);
  //     if (er !== null) {
  //       if (er[0] !== undefined) {
  //         strCodConc = er[0].TRNTARJETA_CODIGO === null ? '' : er[0].TRNTARJETA_CODIGO;
  //       }
  //     }
  //     if (String(strCodConc).length > 0) {
  //       bolConc = true;
  //     }
  //   } catch (error1) {
  //     this.message.add({
  //       key: 'cxctrnretenciones',
  //       severity: 'error',
  //       summary: 'Error',
  //       detail: 'No se puede verificar si la tarjeta está conciliada.'
  //     });
  //   }
  //   return bolConc;
  // }

  // async tarjetaConciliada(strProceso) {
  //   let bolConc = false;

  //   if (this.tabFormaPago === true) {
  //     for (let fila = 1; fila <= this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length; fila++) {
  //       if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_TIPO === 'TA') {
  //         if (await this.tarjetaConciliadaUtil(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO,
  //           this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].CAJ_CODIGO,
  //           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE)) {
  //           if (strProceso !== 'COLOR') {
  //             if (strProceso.length > 0) {
  //               this.message.add({
  //                 key: 'cxctrnretenciones',
  //                 severity: 'error',
  //                 summary: 'Información',
  //                 detail: 'No puede '
  //                   + strProceso
  //                   + ' el pago, el voucher '
  //                   + this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].TRNPAGO_NUMERO
  //                   + ' está conciliado.'
  //               });
  //             }
  //             bolConc = true;
  //             break;
  //           } else {
  //             this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador][fila - 1].COLORDOCCONC = true;
  //             this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);

  //           }
  //         }
  //       }
  //     }
  //   }
  //   return bolConc;
  // }

  // async documentoCierre(strProceso) {
  //   let bolCierre = false;
  //   let strCierre: any = '0';

  //   let strNumDoc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;

  //   if (Object.keys(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).length > 0) {
  //     strNumDoc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
  //   }


  //   try {
  //     strCierre = await this.cxctrnretencionesService.erTrncobrocuadreProm(strNumDoc);

  //     if (strCierre[0].TRNCOBRO_CUADRE === '1') {
  //       if (String(strProceso).length > 0) {
  //         this.message.add({
  //           key: 'cxptrncobro',
  //           severity: 'info',
  //           summary: 'Información',
  //           detail: 'No puede ' + strProceso + ' el pago, el documento está en el cierre de caja.'
  //         });
  //       }
  //       bolCierre = true;
  //     }
  //   } catch (err) {
  //     this.message.add({
  //       key: 'cxptrncobro',
  //       severity: 'error',
  //       summary: 'Error',
  //       detail: 'No se puede verificar si el documento ya está en el cierre de caja.'
  //     });
  //   }

  //   return bolCierre;
  // }

  // anticipoUtilizado(strProceso): boolean {
  //   let bolUtilizado = false;

  //   if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'Anticipo') {
  //     if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI) !==
  //       Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE)) {
  //       bolUtilizado = true;

  //       if (String(strProceso).length > 0) {
  //         this.message.add({
  //           key: 'cxptrncobro',
  //           severity: 'error',
  //           summary: 'Información',
  //           detail: 'No puede ' + strProceso + ' el anticipo, ya está '
  //             + 'utilizado.'
  //         });
  //       }
  //     }
  //   }
  //   return bolUtilizado;
  // }

  // async obtenerSaldoANC(strNumFact) {
  //   const sql = 'SELECT VEN_FNC_SALDOFACTURA(\'01\', \'' + strNumFact + '\') as SALDO FROM DUAL'
  //   let dblSaldo: any = 0;
  //   try {
  //     const rs = await this.cxctrnretencionesService.obtenerSaldoANC(strNumFact);
  //     if (rs !== null) {
  //       dblSaldo = Number(rs[0].SALDO * (-1)).toFixed(2);
  //     }
  //   }
  //   catch (err) {
  //     this.message.add({
  //       key: 'cxptrncobro',
  //       severity: 'error',
  //       summary: 'Información',
  //       detail: 'No se puede obtener el saldo de la factura'
  //     });
  //   }
  //   return dblSaldo;
  // }

  async guardarCartera() {
    // this.editpagofr = true;
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('-')) {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN =
        this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,
          'dd/MM/yyyy');
     } //else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('/')) {
    // }
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE.toString().includes('-')) {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE =
        this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE,
          'dd/MM/yyyy');
     }// else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE.toString().includes('/')) {
    // }
    // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'FC' ||
    //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
    //     await this.saveFCND();
    //   setTimeout(() => {
    //     this.cambiarBotones();
    //     this.cancelarEdicionCartera();
    //   }, 3000);
    // } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
    //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
    //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
    //   await this.saveCAABANC();
    // } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
    //   await this.saveDAN();
    //   await this.consulAsiento()
      
    //   // setTimeout(() => {
    //   //   this.cambiarBotones();
    //   //   this.cancelarEdicionCartera();
    //   // }, 3000);
    // } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
    //   await this.saveNC();
    //   // setTimeout(() => {
    //   //   this.cambiarBotones();
    //   //   this.cancelarEdicionCartera();
    //   // }, 3000);
   // } else
     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT') {
      await this.saveRT();
      this.cambiarBotones();
      await this.cancelarEdicionCartera();
      /*setTimeout(() => {

        this.cambiarBotones();
        this.cancelarEdicionCartera();
      }, 3000);*/
    }
    // ///GSRF
    // this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(async(res)  => {
    //   console.log('----***',res)
    //   this.asiento = res[0].ASI_NRO;
    //   if (this.asiento !== null) {
    //     const det = await this.cxctrnretencionesService.getDetConencasilProm(this.asiento, 'CXC');
    //     console.log('----***',det);
    //     let sum = 0;
    //     for (const aux of det) {
    //       sum=aux.DETASI_IMPORTE + sum;
    //     }
    //     console.log('----***',Number(sum.toFixed(2)));
    //     if(Number(sum.toFixed(2)) !== 0){
    //       document.getElementById("errasiento").style.display = '';
    //       setTimeout(() => {
    //       this.message.add({
    //         key: 'cxctrnretenciones',
    //         severity: 'error',
    //         summary: 'Error en el Asiento Contable',
    //         detail: 'Asiento contable descuadrado'
    //       });
    //     }, 2000);
    //     }
    //   }else{
    //     document.getElementById("errasiento").style.display = 'none';
    //     setTimeout(() => {
    //     this.message.add({
    //       key: 'cxctrnretenciones',
    //       severity: 'error',
    //       summary: 'Visualizacion de Asiento Contable',
    //       detail: 'No se genero el asiento contable'
    //     });
    //   }, 2000);
    //   } 
    // });
    //GSRF
    /* setTimeout(() => {
       this.cambiarBotones();
       this.cancelarEdicionCartera();
     }, 3000); */
  }
  async consulAsiento(){
    ///GSRF
    console.log(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC)
    console.log('----1***',this.asiento)
  
    const resp1= await this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).toPromise()//.subscribe(async(res)  => {
      
      for (const res of resp1){
      this.asiento = res.ASI_NRO;
      console.log('----***',this.asiento)
      console.log('----res***',res)
      if (this.asiento !== null) {
        const det = await this.cxctrnretencionesService.getDetConencasilProm(this.asiento, 'CXC');
        console.log('----***',det);
        let sum = 0;
        for (const aux of det) {
          sum=aux.DETASI_IMPORTE + sum;
        }
        console.log('----***',Number(sum.toFixed(2)));
        if(Number(sum.toFixed(2)) !== 0){
          document.getElementById("errasiento").style.display = '';
          setTimeout(() => {
          this.message.add({
            key: 'cxctrnretenciones',
            severity: 'error',
            summary: 'Error en el Asiento Contable',
            detail: 'Asiento contable descuadrado'
          });
          this.spin=false//CDPJ
        }, 2000);
        }
      }else{
        document.getElementById("errasiento").style.display = 'none';
        setTimeout(() => {
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Visualizacion de Asiento Contable',
          detail: 'No se genero el asiento contable'
        });
        this.spin=false//CDPJ
      }, 2000);
      } 
    }//);
    //GSRF
  }
//   saveFCND() {
//     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE === 0) {
//       this.message.add({
//         key: 'cxctrnretenciones',
//         severity: 'error',
//         summary: 'Inserción de Cartera',
//         detail: 'El valor del importe no puede ser cero'
//       });
//       this.spin=false//CDPJ
//       return;
//     }
//     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ND') {
//       const dblTotalVarios = this.calcularTotalVarios();
//       const dblImporteDoc = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALND);
//       const strNombreDoc = 'Nota de Débito';
//       console.log('1', dblImporteDoc, dblTotalVarios);
//       if (this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador].length === 0) {
//         if (dblImporteDoc === dblTotalVarios) {
//           this.message.add({
//             key: 'cxctrnretenciones',
//             severity: 'error',
//             summary: 'Información',
//             detail: 'El total de Varios debe ser igual al ' +
//               'total del documento ' + strNombreDoc
//           });
//           this.spin=false//CDPJ
//           return;
//         }
//       }
//     }

//     if (this.saldoInicial === true) {
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 1;
//     } else if (this.saldoInicial === false) {
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 0;
//     }
//     if (this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length !== 0) {
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO = this.cxctrnretencionesService.cxctrnvariosarray[this.indicador][0].BAN_CODIGO;
//     }
//     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
//       this.cxctrnretencionesService.insertarCobroFCND(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((res) => {
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = res[0].TRNCOBRO_NRODOC;
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = res[0].TRNCOBRO_NROCOMPROBANTE;
//           this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//             this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(res[0].TRNCOBRO_NRODOC + '/' + res[0].TRNCOBRO_NROCOMPROBANTE), 'I',
//               '', '01', '', '', '', '').subscribe(() => {
//                 this.message.add({
//                   key: 'cxctrnretenciones',
//                   severity: 'success',
//                   summary: 'Inserción de Cartera',
//                   detail: 'Se ha insertado el documento exitosamente'
//                 });
//                 this.spin=false//CDPJ
//                 if (this.tabDebitoBancario === true) {
//                   this.cxctrnretencionesService.generarNDBancario(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//                     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe(() => {
//                       if (this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador].length !== 0) {
//                         this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador].map((debito) => {
//                           if (debito.SELECCIONADO === true) {
//                             this.cxctrnretencionesService.insertarTrnBanDoc(debito, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//                             });
//                           }
//                         });
//                       }
//                       this.cxctrnretencionesService.generarAsientoNDBan(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
//                         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
//                         this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                         this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                             this.message.add({
//                               key: 'cxctrnretenciones',
//                               severity: 'success',
//                               summary: 'Asiento Contable',
//                               detail: 'Se ha generado el asiento contable'
//                             });
//                             this.spin=false//CDPJ
//                           });
//                         this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                       });
//                     });
//                 }
//                 if (this.tabVarios === true) {
//                   this.cxctrnretencionesService.eliminarVarios(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
//                     this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'E',
//                       '', '01', '', '', '', '').subscribe(() => {
//                       });
//                     if (this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length !== 0) {
//                       await Promise.all(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].map(async (vario) => {
//                         vario.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                         vario.TRNCOBRO_NRODOC = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
//                         vario.TRNCOBRO_NROCOMPROBANTE = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
//                         if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
//                           vario.TRNVARIOS_IMPORTE = vario.DEBE;
//                           vario.TRNBAN_TIPO = 'DP';
//                         } else if (vario.HABER !== '' && Number(vario.HABER) > 0) {
//                           vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
//                           vario.TRNBAN_TIPO = 'RE';
//                         } else {
//                           vario.TRNBAN_TIPO = '';
//                         }
//                         vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
//                         this.cxctrnretencionesService.insertarVarios(vario).subscribe(() => {
//                           vario.TRNVARIOS_LINEA = vario.LINEA;
//                           this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'I',
//                             '', '01', '', '', '', '').subscribe(() => {
//                             });
//                         });
//                         this.cxctrnretencionesService.generarAsientoND(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
//                           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
//                           this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                           this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
//                             '', '01', '', '', '', '').subscribe(() => {
//                               this.message.add({
//                                 key: 'cxctrnretenciones',
//                                 severity: 'success',
//                                 summary: 'Asiento Contable',
//                                 detail: 'Se ha generado el asiento contable'
//                               });
//                               this.spin=false//CDPJ
//                             });
//                         });
//                         //CDPJ 
//                         if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
                         
//                           const data= await this.cxctrnretencionesService.insertarMovbancos(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).catch(e=>{
//                             this.spin=false//CDPJ
//                           });
//                         }  
//                       //CDPJ
//                       }));
//                     }
//                   });
//                 }
//               });
//           });
//           // this.cambiarBotones();
//         });
//     } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
//       if (this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length !== 0) {
//         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BAN_CODIGO =
//           this.cxctrnretencionesService.cxctrnvariosarray[this.indicador][0].BAN_CODIGO;
//       }
//       this.cxctrnretencionesService.actualizarCobro(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((res) => {
//           this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//             this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(res[0].TRNCOBRO_NRODOC + '/' + res[0].TRNCOBRO_NROCOMPROBANTE), 'A',
//               '', '01', '', '', '', '').subscribe(() => {
//                 this.message.add({
//                   key: 'cxctrnretenciones',
//                   severity: 'success',
//                   summary: 'Inserción de Cartera',
//                   detail: 'Se ha actualizado el documento exitosamente'
//                 });
//                 this.spin=false//CDPJ
//                 if (this.tabDebitoBancario === true) {
//                   this.cxctrnretencionesService.generarNDBancario(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//                     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe(() => {
//                       if (this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador].length !== 0) {
//                         this.cxctrnretencionesService.cxcdebitobancarioarray[this.indicador].map((debito) => {
//                           if (debito.SELECCIONADO === true) {
//                             this.cxctrnretencionesService.insertarTrnBanDoc(debito, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//                             });
//                           }
//                         });
//                       }
//                       this.cxctrnretencionesService.generarAsientoND(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
//                         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
//                         this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                         this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                             this.message.add({
//                               key: 'cxctrnretenciones',
//                               severity: 'success',
//                               summary: 'Asiento Contable',
//                               detail: 'Se ha generado el asiento contable'
//                             });
//                             this.spin=false//CDPJ
//                           });
//                         this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                       });
//                     });
//                 }
//                 if (this.tabVarios === true) {
//                   this.cxctrnretencionesService.eliminarVarios(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
//                     this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'E',
//                       '', '01', '', '', '', '').subscribe(() => {
//                       });
//                     if (this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length !== 0) {
//                       await Promise.all(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].map(async (vario) => {
//                         vario.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                         vario.TRNCOBRO_NRODOC = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
//                         vario.TRNCOBRO_NROCOMPROBANTE = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
//                         if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
//                           vario.TRNVARIOS_IMPORTE = vario.DEBE;
//                           vario.TRNBAN_TIPO = 'DP';
//                         } else if (vario.HABER !== '' && Number(vario.HABER) > 0) {
//                           vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
//                           vario.TRNBAN_TIPO = 'RE';
//                         } else {
//                           vario.TRNBAN_TIPO = '';
//                         }
//                         vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
//                         this.cxctrnretencionesService.insertarVarios(vario).subscribe(() => {
//                           vario.TRNVARIOS_LINEA = vario.LINEA;
//                           this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(res[0].TRNCOBRO_NRODOC), 'I',
//                             '', '01', '', '', '', '').subscribe(() => {
//                             });
//                         });
//                         this.cxctrnretencionesService.generarAsientoND(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asiento) => {
//                           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asiento[0][':B1'];
//                           this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                           this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asiento[0][':B1'] + '/CXC'), 'I',
//                             '', '01', '', '', '', '').subscribe(() => {
//                               this.message.add({
//                                 key: 'cxctrnretenciones',
//                                 severity: 'success',
//                                 summary: 'Asiento Contable',
//                                 detail: 'Se ha generado el asiento contable'
//                               });
//                               this.spin=false//CDPJ
//                             });
//                           this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                         });
//                         //CDPJ 
//                         if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
                          
//                           const data= await this.cxctrnretencionesService.insertarMovbancos(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).catch(e=>{
//                             this.spin=false//CDPJ
//                           });
//                         }  
//                       //CDPJ
//                       }));
//                     }
//                   });
//                 }
//               });
//           });
//           // this.cambiarBotones();
//           // this.consultarPestañas();
//         });
//     }
//   }

//   async saveCAABANC() {
//     console.log('saveCAABANC');
//     //GSRF
//     for (const item of this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador]) {
//       console.log('-->',item.CAJ_CODIGO)
//       let caja = await this.cxctrnretencionesService.getcaja(item.CAJ_CODIGO)
//       let numdep = await this.cxctrnretencionesService.getDP(item.TRNPAGO_NUMERO,item.BANCLI_CODIGO);
//       console.log('-->',item.TRNPAGO_TIPO)
//       console.log('-->',caja.length)
//       console.log('-->',numdep.length)
//       if(item.TRNPAGO_TIPO !=='DP' && item.TRNPAGO_TIPO === 'EF'){
//       if(caja.length === 0 ){
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Inserción de Cartera',
//           detail: 'Caja no existe'
//         });
//         this.spin=false//CDPJ
//         return;
//       }
//     }

//     if(item.TRNPAGO_TIPO ==='DP'){
//       if (item.BANCLI_CODIGO === null || item.BANCLI_CODIGO === '' || item.BANCLI_CODIGO === undefined ){
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Inserción de Cartera',
//           detail: 'Ingrese Banco'
//         });
//         this.spin=false//CDPJ
//         return;
//       }
//       if (item.TRNPAGO_NUMERO === null || item.TRNPAGO_NUMERO === '' || item.TRNPAGO_NUMERO === undefined ){
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Inserción de Cartera',
//           detail: 'Ingrese Número'
//         });
//         this.spin=false//CDPJ
//         return;
//       }
//       if(numdep.length !== 0 ){
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Inserción de Cartera',
//           detail: 'El número ya existe'
//         });
//         this.spin=false//CDPJ
//         return;
//       }
//     }
//       };

//       if(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC'){
//         if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length === 0 && this.saldoInicial === false) {
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Inserción de Cartera',
//           detail: 'Ingrese forma de pago'
//         });
//         this.spin=false//CDPJ
//         return;
//       } 
//       }
     
//       //GSRF
//     if ((Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) !==
//       Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO)) && this.saldoInicial === false) {
//       this.message.add({
//         key: 'cxctrnretenciones',
//         severity: 'error',
//         summary: 'Inserción de Cartera',
//         detail: 'El total de la forma de pago es distinto al total a cobrar'
//       });
//       this.spin=false//CDPJ
//       return;
//     }
//     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC') {
//       if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE === 0) {
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Inserción de Cartera',
//           detail: 'El valor del importe no puede ser cero'
//         });
//         this.spin=false//CDPJ
//         return;
//       }
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
//       if (this.saldoInicial === false) {
//         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 0;
//       } else if (this.saldoInicial === true) {
//         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 1;
//       }
//       console.log('1');
//       if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
//         console.log('2');
//         let cobro;
//         try {
//           cobro = await this.cxctrnretencionesService.insertarCobroP(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC)

//           console.log('1 Inserta cobro');
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;

//           if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NRODOC;
//           }

//           const saldo = await this.cxctrnretencionesService.obtenerSaldoClienteP(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
//           console.log('2 Obtiene saldo cliente');

//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//           this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
//             '', '01', '', '', '', '').subscribe(() => { });
//           this.message.add({
//             key: 'cxctrnretenciones',
//             severity: 'success',
//             summary: 'Inserción de Cartera',
//             detail: 'Se ha insertado el documento exitosamente'
//           });
//           this.spin=false//CDPJ
//         } catch (err) {
//           this.message.add({
//             key: 'cxctrnretenciones',
//             severity: 'error',
//             summary: 'Error',
//             detail: err.error
//           });
//           this.spin=false//CDPJ
//         }

//         if (this.saldoInicial === false) {
//           if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
//             for (const formap of this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador]) {
//               formap.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//               if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
//                 formap.TRNPAGO_CONCEPTO = '*';
//               }

//               try {
//                 await this.cxctrnretencionesService.insertarFormadePagoProm(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
//                 this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
//                   '', '01', '', '', '', '').subscribe(() => {
//                   });
//                 console.log('3 Inserta forma de pago');
//               } catch (err) {
//                 this.message.add({
//                   key: 'cxctrnretenciones',
//                   severity: 'error',
//                   summary: 'Forma de Pago',
//                   detail: 'No se guardo la forma de pago, error ' + err.error
//                 });
//                 this.spin=false//CDPJ
//               }
//             }

//             try {
//               await this.cxctrnretencionesService.generarAsientoANCProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]);
//               console.log('4 Genera el asiento');
//               const res = await this.cxctrnretencionesService.getAsientoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
//               console.log('5 Obtiene el asiento');
//               this.asiento = res[0].ASI_NRO;
//               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//               this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//               this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
//                 '', '01', '', '', '', '').subscribe(() => {
//                   this.message.add({
//                     key: 'cxctrnretenciones',
//                     severity: 'success',
//                     summary: 'Asiento Contable',
//                     detail: 'Se ha generado el asiento contable'
//                   });
//                   this.spin=false//CDPJ
//                 });
//               this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);

//             } catch (err) {
//               this.message.add({
//                 key: 'cxctrnretenciones',
//                 severity: 'error',
//                 summary: 'Asiento Contable',
//                 detail: 'No se ha generado el asiento contable' + err.error
//               });
//               this.spin=false//CDPJ
//             }

//           }

//         }
//         // this.cambiarBotones();
//         // this.consultarPestañas();
//         this.cambiarBotones();
//         await this.cancelarEdicionCartera();
//         if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROCMPRAUT').CFG_VALOR1 === 1) {
//           this.cxctrnretencionesService.actualizarNumeracion(this.numSerie).subscribe((res) => {
//             console.log(res);
//           });
//         }

//       } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
//         console.log('3');
//         const cobro = await this.cxctrnretencionesService.actualizarCobroProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
//         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//         const saldo = await this.cxctrnretencionesService.obtenerSaldoClienteP(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
//         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//         await this.cxctrnretencionesService.eliminarTrnDocumentoProm('TRNCOBRO_NRODOC = \'' +
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'');
//         if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//           for (const resumen of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
//             if (resumen.SELECCIONADO === true) {
//               resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//               resumen.STRCUOTAFAC = 'F';
//               if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                 resumen.STRCUOTAFAC = 'C';
//               }
//               await this.cxctrnretencionesService.insertarDocPorCobrarP(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
//               if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                 await this.actualizarCuotasPagadas(resumen);
//               }
//               this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
//                 '', '01', '', '', '', '').subscribe(() => {
//                 });
//             }
//           }

//         }

//         if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
//           await this.cxctrnretencionesService.eliminarFormaPagoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
//           for (const formap of this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador]) {
//             //if (formap.TRNPAGO_TIPO === 'DP') {
//             //  await this.cxctrnretencionesService.eliminarDPBancoProm(formap.TRNPAGO_NUMERO);
//             //}
//             formap.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//             if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
//               formap.TRNPAGO_CONCEPTO = '*';
//             }

//             try {
//               await this.cxctrnretencionesService.insertarFormadePagoProm(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
//               this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
//                 '', '01', '', '', '', '').subscribe(() => {
//                 });
//             } catch (err) {
//               this.message.add({
//                 key: 'cxctrnretenciones',
//                 severity: 'error',
//                 summary: 'Forma de Pago',
//                 detail: 'No se guardo la forma de pago, error ' + err.error
//               });
//               this.spin=false//CDPJ
//             }
//           }
//         }

//         this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
//           '', '01', '', '', '', '').subscribe(() => {
//             this.message.add({
//               key: 'cxctrnretenciones',
//               severity: 'success',
//               summary: 'Inserción de Cartera',
//               detail: 'Se ha insertado el documento exitosamente'
//             });
//             this.spin=false//CDPJ
//           });

//         try {
//           await this.cxctrnretencionesService.generarAsientoANCProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]);
//           const res = await this.cxctrnretencionesService.getAsientoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
//           this.asiento = res[0].ASI_NRO;
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//           this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//           this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
//             '', '01', '', '', '', '').subscribe(() => {
//               this.message.add({
//                 key: 'cxctrnretenciones',
//                 severity: 'success',
//                 summary: 'Asiento Contable',
//                 detail: 'Se ha generado el asiento contable'
//               });
//               this.spin=false//CDPJ
//             });
//           this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//         } catch (err) {
//           this.message.add({
//             key: 'cxctrnretenciones',
//             severity: 'error',
//             summary: 'Asiento Contable',
//             detail: 'No se ha generado el asiento contable ' + err.error
//           });
//           this.spin=false//CDPJ
//         }

//         this.cambiarBotones();
//         this.cancelarEdicionCartera();


//       }
//     } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA') {
//       console.log('3');
//       let numeroDoc = '';
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
//       if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
//         console.log('4');
//         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE =
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC;
//         let cobro;
//         try {
//           cobro = await this.cxctrnretencionesService.insertarCobroP(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
//           console.log('1 Insertar Cobro');
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//           if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
//             console.log(cobro[0]);
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NRODOC;
//           }

//           const saldo = await this.cxctrnretencionesService.obtenerSaldoClienteP(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
//           console.log('2 Obtiene saldo cliente');
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;

//           if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//             for (const resumen of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
//               if (resumen.SELECCIONADO === true) {
//                 resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                 numeroDoc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
//                 resumen.STRCUOTAFAC = 'F';
//                 if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                   resumen.STRCUOTAFAC = 'C';
//                 }
//                 await this.cxctrnretencionesService.insertarDocPorCobrarP(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
//                 console.log('3 insertarDocPorCobrar');
//                 if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                   await this.actualizarCuotasPagadas(resumen);
//                   console.log('4 actualizarCuotasPagadas');
//                 }
//                 this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
//                   '', '01', '', '', '', '').subscribe(() => {
//                   });
//               }
//             }
//           }

//           this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
//             '', '01', '', '', '', '').subscribe(() => {
//               this.message.add({
//                 key: 'cxctrnretenciones',
//                 severity: 'success',
//                 summary: 'Inserción de Cartera',
//                 detail: 'Se ha insertado el documento exitosamente'
//               });
//               this.spin=false//CDPJ
//             });

//           if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length !== 0) {
//             for (const ret of this.cxctrnretencionesService.cxctrnretencionarray[this.indicador]) {
//               ret.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//               if (ret.CON_CODIGO === '') {
//                 ret.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
//               }
//               ret.CEN_CODIGO = '';
//               ret.ENCFAC_NUMERO = this.numeroFactura;
//               await this.cxctrnretencionesService.insertarRetencionP(ret, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
//               console.log('5 insertarRetencion');
//               if (this.retencionElect === true) {
//                 await this.cxctrnretencionesService.actualizarTipoRetencionProm(ret, 'E');
//                 console.log('6 insertarRetencion');
//               } else if (this.retencionElect === false) {
//                 await this.cxctrnretencionesService.actualizarTipoRetencionProm(ret, 'F');
//                 console.log('6 insertarRetencion');
//               }
//             }
//           }

//           if (this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].length !== 0) {
//             for (const com of this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador]) {
//               if (com.CON_CODIGO === '' || com.CON_CODIGO === null) {
//                 com.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
//               }

//               await this.cxctrnretencionesService.insertarComisionProm(com, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
//               console.log('7 insertarComision');

//               com.TRNCOBRO_NRODOC = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
//               this.auditoriagral.registrarAuditoria('SACI_TRNCOMISION', String(com.TRNCOMISION_NRO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
//                 '', '01', '', '', '', '').subscribe(() => {
//                 });
//             }
//           }


//         } catch (err) {
//           this.message.add({
//             key: 'cxctrnretenciones',
//             severity: 'error',
//             summary: 'Error',
//             detail: err.error
//           });
//           this.spin=false//CDPJ
//         }

//         try {


//           if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
//             await this.cxctrnretencionesService.eliminarFormaPagoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
//             console.log('8 eliminarFormaPago');
//             for (const formap of this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador]) {
//               //if (formap.TRNPAGO_TIPO === 'DP') {
//               //  await this.cxctrnretencionesService.eliminarDPBancoProm(formap.TRNPAGO_NUMERO);
//               //  console.log('9 eliminarDPBanco');
//               //}
//               formap.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//               if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
//                 formap.TRNPAGO_CONCEPTO = '*';
//               }

//               await this.cxctrnretencionesService.insertarFormadePagoProm(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
//               console.log('10 insertarFormadePago');
//               console.log(formap.TRNPAGO_TIPO);
//               if (formap.TRNPAGO_TIPO === 'ANCL') {
//                 const itemND: Cxctrncobro = {};
//                 itemND.TRNCOBRO_NRODOC = '';
//                 itemND.TRNCOBRO_NROCOMPROBANTE = '';
//                 itemND.TRNCOBRO_FECHATRN = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
//                 itemND.TRNCOBRO_CONCEPTO = 'NOTA DE DEBITO POR EL ANTICIPO ' + formap.TRNPAGO_NUMERO;
//                 itemND.TRNCOBRO_IMPORTE = formap.TRNPAGO_IMPORTE;
//                 itemND.TRNCOBRO_FECHAVENCE = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE === null ? null :
//                   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE;
//                 itemND.APLORG_CODIGO = 'SISTEMA';
//                 itemND.TRNCOBRO_REFERENCIAEXTERNA = null;
//                 itemND.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                 itemND.VEN_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO;
//                 itemND.ASI_NRO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO;
//                 itemND.USU_IDENTIFICACION = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION;
//                 itemND.TRNCOBRO_FLAG = '1';
//                 itemND.BAN_CODIGO = null;
//                 itemND.TRNCOBRO_EFECTIVO = '0.0';
//                 itemND.TRNCOBRO_CAMBIO = '0.0';
//                 itemND.CON_CODIGO = null;
//                 itemND.TRNCOBRO_SALDOINI = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI;
//                 itemND.BOD_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO;

//                 console.log(itemND);
//                 const data = await this.cxctrnretencionesService.insertarCobroP(itemND, 'NDA');
//                 console.log('11 insertarCobro NDA');
//                 console.log(data);
//                 let strNumDocAnt = '';
//                 let strTrnCobroNroDoc = '';
//                 let strTrnCobroNroComprobante = '';

//                 const eR = await this.cxctrnretencionesService.erTrncobronrodocProm(formap.TRNPAGO_NUMERO);
//                 console.log(eR);
//                 console.log('12 encontrar TRNCOBRO_NRODOC');
//                 if (eR !== undefined) {
//                   if (eR[0].TRNCOBRO_NRODOC !== null) {
//                     strNumDocAnt = eR[0].TRNCOBRO_NRODOC;
//                   }
//                 }

//                 for (const rs of data) {
//                   strTrnCobroNroDoc = rs.TRNCOBRO_NRODOC;
//                   strTrnCobroNroComprobante = rs.TRNCOBRO_NROCOMPROBANTE;
//                 }
//                 // actualizar la referencia de la nota de débito con el número del anticipo strTrnCobroNroDoc
//                 await this.cxctrnretencionesService.actualizarNotaDebitoAnticipoProm(strTrnCobroNroDoc, strNumDocAnt);
//                 console.log('12 actualizarNotaDebitoAnticipo');
//                 await this.cxctrnretencionesService.actualizarFormaPagoNDProm(strTrnCobroNroDoc,
//                   formap.TRNPAGO_NUMERO, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
//                 console.log('12 actualizarFormaPagoND');
//               }
//               this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
//                 '', '01', '', '', '', '').subscribe(() => {
//                 });
//             }
//           }

//         } catch (err) {
//           this.message.add({
//             key: 'cxctrnretenciones',
//             severity: 'error',
//             summary: 'Forma de Pago',
//             detail: 'No se guardo la forma de pago, error ' + err.error
//           });
//           this.spin=false//CDPJ
//         }


//         try {
//           await this.cxctrnretencionesService.generarAsientoCIProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]);
//           console.log('12 generarAsientoCI');
//           const res = await this.cxctrnretencionesService.getAsientoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
//           console.log('12 getAsiento', res);
//           this.asiento = res[0].ASI_NRO;
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//           if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//             for (const resumen of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
//               console.log(resumen);
//               if (resumen.SELECCIONADO === true) {
//                 resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                 await this.cxctrnretencionesService.actualizarestadoFacProm(resumen);
//                 console.log('actualizarestadoFac');
//               }
//             }
//           }
//           this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//           this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
//             '', '01', '', '', '', '').subscribe(() => {
//               this.message.add({
//                 key: 'cxctrnretenciones',
//                 severity: 'success',
//                 summary: 'Asiento Contable',
//                 detail: 'Se ha generado el asiento contable'
//               });
//               this.spin=false//CDPJ
//             });

//         } catch (err) {
//           this.message.add({
//             key: 'cxctrnretenciones',
//             severity: 'error',
//             summary: 'Asiento Contable',
//             detail: 'No se ha generado el asiento contable, error' + err.error
//           });
//           this.spin=false//CDPJ
//         }

//         this.cambiarBotones();
//         await this.cancelarEdicionCartera();

//         if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NROCMPRAUT').CFG_VALOR1 === 1) {
//           this.cxctrnretencionesService.actualizarNumeracion(this.numSerie).subscribe((res) => {
//             console.log(res);
//           });
//         }

//       } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Cartera Activa',
//           detail: 'No es posible actualizar el documento. Elimine y vuelva a ingresar.'
//         });
//         this.spin=false//CDPJ
//         return;

//         this.cxctrnretencionesService.actualizarCobro(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//             this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//               this.cxctrnretencionesService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
//                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
//                   if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//                     this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                       if (resumen.SELECCIONADO === true) {
//                         numeroDoc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
//                         resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                         resumen.STRCUOTAFAC = 'F';
//                         if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                           resumen.STRCUOTAFAC = 'C';
//                         }
//                         this.cxctrnretencionesService.insertarDocPorCobrar(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//                             if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                               this.actualizarCuotasPagadas(resumen);
//                             }
//                             this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
//                               '', '01', '', '', '', '').subscribe(() => {
//                               });
//                           });
//                       }
//                     });
//                   }
//                   if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length !== 0) {
//                     this.cxctrnretencionesService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' +
//                       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC +
//                       '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(() => {
//                         this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].map((ret) => {
//                           ret.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                           if (ret.CON_CODIGO === '') {
//                             ret.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
//                           }
//                           ret.CEN_CODIGO = '';
//                           ret.ENCFAC_NUMERO = this.numeroFactura;
//                           this.cxctrnretencionesService.insertarRetencion(ret, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//                               if (this.retencionElect === true) {
//                                 this.cxctrnretencionesService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
//                                 });
//                               } else if (this.retencionElect === false) {
//                                 this.cxctrnretencionesService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
//                                 });
//                               }
//                             });
//                         });
//                       });
//                   }
//                   if (this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].length !== 0) {
//                     this.cxctrnretencionesService.eliminarTrnComision(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
//                       this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].map((com) => {
//                         if (com.CON_CODIGO === '' || com.CON_CODIGO === null) {
//                           com.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
//                         }
//                         this.cxctrnretencionesService.insertarComision(com, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//                             com.TRNCOBRO_NRODOC = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
//                             this.auditoriagral.registrarAuditoria('SACI_TRNCOMISION', String(com.TRNCOMISION_NRO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
//                               '', '01', '', '', '', '').subscribe(() => {
//                               });
//                           });
//                       });
//                     });
//                   }
//                   if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length !== 0) {
//                     this.cxctrnretencionesService.eliminarFormaPago(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
//                       this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].map((formap) => {
//                         if (formap.TRNPAGO_TIPO === 'DP') {
//                           this.cxctrnretencionesService.eliminarDPBanco(formap.TRNPAGO_NUMERO).subscribe(() => {
//                           });
//                         }
//                         formap.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                         if (formap.TRNPAGO_CONCEPTO === '' || formap.TRNPAGO_CONCEPTO === null) {
//                           formap.TRNPAGO_CONCEPTO = '*';
//                         }
//                         this.cxctrnretencionesService.insertarFormadePago(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(async () => {
//                             if (formap.TRNPAGO_TIPO === 'ANCL') {
//                               const itemND: Cxctrncobro = {};
//                               itemND.TRNCOBRO_NRODOC = '';
//                               itemND.TRNCOBRO_NROCOMPROBANTE = '';
//                               itemND.TRNCOBRO_FECHATRN = this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
//                               itemND.TRNCOBRO_CONCEPTO = 'NOTA DE DEBITO POR EL ANTICIPO ' + formap.TRNPAGO_NUMERO;
//                               itemND.TRNCOBRO_IMPORTE = formap.TRNPAGO_IMPORTE;
//                               itemND.TRNCOBRO_FECHAVENCE = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE === null ? null :
//                                 this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
//                               itemND.APLORG_CODIGO = 'SISTEMA';
//                               itemND.TRNCOBRO_REFERENCIAEXTERNA = null;
//                               itemND.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                               itemND.VEN_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO;
//                               itemND.ASI_NRO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO;
//                               itemND.USU_IDENTIFICACION = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].USU_IDENTIFICACION;
//                               itemND.TRNCOBRO_FLAG = '1';
//                               itemND.BAN_CODIGO = null;
//                               itemND.TRNCOBRO_EFECTIVO = '0.0';
//                               itemND.TRNCOBRO_CAMBIO = '0.0';
//                               itemND.CON_CODIGO = null;
//                               itemND.TRNCOBRO_SALDOINI = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI;
//                               itemND.BOD_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO;

//                               let strNumDocAnt = '';

//                               const eRf = await this.cxctrnretencionesService.erTrnpagorefer(formap.TRNCOBRO_NRODOC);
//                               if (eRf !== null) {
//                                 for (const rs of eRf) {
//                                   strNumDocAnt = rs.TRNPAGO_REFER;
//                                 }
//                               }

//                               console.log(strNumDocAnt);
//                               let data: any;
//                               if (strNumDocAnt === '') {
//                                 let strTrnCobroNroDoc = '';
//                                 let strTrnCobroNroComprobante = '';
//                                 data = await this.cxctrnretencionesService.insertarCobroP(itemND, 'NDA');
//                                 console.log('11 insertarCobro NDA');
//                                 console.log(data);
//                                 const eR = await this.cxctrnretencionesService.erTrncobronrodocProm(formap.TRNPAGO_NUMERO);
//                                 console.log(eR);
//                                 console.log('12 encontrar TRNCOBRO_NRODOC');
//                                 if (eR !== undefined) {
//                                   if (eR[0].TRNCOBRO_NRODOC !== null) {
//                                     strNumDocAnt = eR[0].TRNCOBRO_NRODOC;
//                                   }
//                                 }
//                                 for (const rs of data) {
//                                   strTrnCobroNroDoc = rs.TRNCOBRO_NRODOC;
//                                   strTrnCobroNroComprobante = rs.TRNCOBRO_NROCOMPROBANTE;
//                                 }
//                                 // actualizar la referencia de la nota de débito con el número del anticipo strTrnCobroNroDoc
//                                 await this.cxctrnretencionesService.actualizarNotaDebitoAnticipoProm(strTrnCobroNroDoc, strNumDocAnt);
//                                 console.log('12 actualizarNotaDebitoAnticipo');
//                                 await this.cxctrnretencionesService.actualizarFormaPagoNDProm(strTrnCobroNroDoc,
//                                   formap.TRNPAGO_NUMERO, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
//                                 console.log('12 actualizarFormaPagoND');
//                               } else {
//                                 data = await this.cxctrnretencionesService.actualizarCobroProm(itemND, 'NDA');
//                                 console.log('11 actualizarCobro NDA');
//                               }
//                             }
//                             this.cxctrnretencionesService.generarAsientoCI(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//                               this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
//                                 this.asiento = res[0].ASI_NRO;
//                                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//                                 if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//                                   this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                                     if (resumen.SELECCIONADO === true) {
//                                       resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                                       this.cxctrnretencionesService.actualizarestadoFac(resumen).subscribe(() => {
//                                       }, error1 => {
//                                         console.log(error1.error);
//                                       });
//                                     }
//                                   });
//                                 }
//                                 this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
//                                   '', '01', '', '', '', '').subscribe(() => {
//                                   });
//                               });
//                             }, () => {
//                             });
//                             this.auditoriagral.registrarAuditoria('CXC_TRNPAGO', String(cobro[0].TRNCOBRO_NRODOC + '/' + formap.TRNPAGO_NUMERO), 'I',
//                               '', '01', '', '', '', '').subscribe(() => {
//                               });
//                           }, error1 => {
//                             this.message.add({
//                               key: 'cxctrnretenciones',
//                               severity: 'error',
//                               summary: 'Forma de Pago',
//                               detail: 'No se guardo la forma de pago, error ' + error1.error
//                             });
//                           });
//                       });
//                     });
//                   }
//                   this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
//                     '', '01', '', '', '', '').subscribe(() => {
//                       this.message.add({
//                         key: 'cxctrnretenciones',
//                         severity: 'success',
//                         summary: 'Inserción de Cartera',
//                         detail: 'Se ha insertado el documento exitosamente'
//                       });
//                     });
//                   this.cambiarBotones();
//                   this.cancelarEdicionCartera();
//                   setTimeout(() => {
//                     this.cxctrnretencionesService.generarAsientoCI(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//                       this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
//                         this.asiento = res[0].ASI_NRO;
//                         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//                         if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//                           this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                             if (resumen.SELECCIONADO === true) {
//                               resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                               this.cxctrnretencionesService.actualizarestadoFac(resumen).subscribe(() => {
//                               }, error1 => {
//                                 console.log(error1.error);
//                               });
//                             }
//                           });
//                         }
//                         this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                             this.message.add({
//                               key: 'cxctrnretenciones',
//                               severity: 'success',
//                               summary: 'Asiento Contable',
//                               detail: 'Se ha generado el asiento contable'
//                             });
//                           });
//                       });
//                     }, error1 => {
//                       this.message.add({
//                         key: 'cxctrnretenciones',
//                         severity: 'error',
//                         summary: 'Asiento Contable',
//                         detail: 'No se ha generado el asiento, error ' + error1.error
//                       });
//                     });
//                   }, 500);
//                 });
//             });
//             // this.cambiarBotones();
//             // this.consultarPestañas();
//           });
//       }
//     }
//   }

//   async saveDAN() {
//     //GSRF
//     for (const item of  this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador]) {
//       console.log('-->',item.CAJ_CODIGO)
//       let caja = await this.cxctrnretencionesService.getcaja(item.CAJ_CODIGO)
//       //let numdep = await this.cxctrnretencionesService.getDP(item.TRNPAGO_NUMERO,item.BAN_CODIGO);
//       console.log('-->',item.TRNCAJ_TIPO)
//       console.log('-->',caja.length)
//       //console.log('-->',numdep.length)
//       if(item.TRNCAJ_TIPO !=='DP' && item.TRNCAJ_TIPO === 'EF'){
//       if(caja.length === 0 ){
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Inserción de Cartera',
//           detail: 'Caja no existe'
//         });
//         this.spin=false//CDPJ
//         return;
//       }
//     }

//     if(item.TRNCAJ_TIPO ==='CH'){
//       console.log('------)', item.TRNCAJ_NUMERO)
//       if (item.BAN_CODIGO === null || item.BAN_CODIGO === '' || item.BAN_CODIGO === undefined ){
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Inserción de Cartera',
//           detail: 'Ingrese Banco'
//         });
//         this.spin=false//CDPJ
//         return;
//       }
//       if (item.TRNCAJ_NUMERO === null || item.TRNCAJ_NUMERO === '' || item.TRNCAJ_NUMERO === undefined ){
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Inserción de Cartera',
//           detail: 'Ingrese Número'
//         });
//         this.spin=false//CDPJ
//         return;
//       }
      
//     }
//       };

//       if(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'ANC'){
//         if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length === 0) {
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Inserción de Cartera',
//           detail: 'Ingrese forma de pago'
//         });
//         this.spin=false//CDPJ
//         return;
//       } 
//       }
     
//       //GSRF34
//     if (Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR) !== Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO)) {
//       this.message.add({
//         key: 'cxctrnretenciones',
//         severity: 'error',
//         summary: 'Inserción de Cartera',
//         detail: 'El total de la forma de pago es distinto al total a cobrar'
//       });
//       this.spin=false//CDPJ
//       return;
//     }
//     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
//     this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resu) => {
//       if (resu.SELECCIONADO === true) {
//         //// GSTR
//         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE =
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC;
//       }
//     });
//     //CDPJ
//     if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
//       // this.cxctrnretencionesService.insertarCobroDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//       //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).pipe(finalize(async() => {await  this.generarAsientoDAN()})).subscribe(async (cobro) => {
          
//           const res=await this.cxctrnretencionesService.insertarCobroDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).toPromise()
//             for (const cobro of res){
              
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro.TRNCOBRO_NRODOC;
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro.TRNCOBRO_NROCOMPROBANTE;
          
//           //this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//             const resp1= await this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).toPromise()
//             for (const saldo of resp1 ){  
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo.SALDO;
//             this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro.TRNCOBRO_NRODOC + '/' + cobro.TRNCOBRO_NROCOMPROBANTE), 'I',
//               '', '01', '', '', '', '').subscribe(() => {
//                 this.message.add({
//                   key: 'cxctrnretenciones',
//                   severity: 'success',
//                   summary: 'Inserción de Cartera',
//                   detail: 'Se ha insertado el documento exitosamente'
//                 });
//                 this.spin=false//CDPJ
//               });
//             if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//               await Promise.all(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map(async (resumen) => {
//                 if (resumen.SELECCIONADO === true) {
//                   resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                   resumen.STRCUOTAFAC = 'F';
//                   if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                     resumen.STRCUOTAFAC = 'C';
//                   }
//                   // this.cxctrnretencionesService.insertarDocPorCobrar(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                   //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//                       await this.cxctrnretencionesService.insertarDocPorCobrar(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).toPromise()
//                       if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                         this.actualizarCuotasPagadas(resumen);
//                       }
//                       this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro.TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
//                         '', '01', '', '', '', '').subscribe(() => {
//                         });
//                     //});
//                 }
//               }));
//             }
//             if (this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].length !== 0) {
//               await Promise.all(this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].map(async (formap) => {
//                 if (formap.TRNCAJ_TIPO === 'EF') {
//                   //GSRF
//                   if(formap.TRNCAJ_DESCRIPCION === ''){
//                     formap.TRNCAJ_DESCRIPCION = '*';
//                   }
//                   //GSRF
//                   // this.cxctrnretencionesService.insertarCaja(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                   //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
//                       await this.cxctrnretencionesService.insertarCaja(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).toPromise()
//                       this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.TRNCAJ_NUMERO + '/' + cobro.TRNCOBRO_NRODOC), 'I',
//                         '', '01', '', '', '', '').subscribe(() => {
//                         });
//                    // });
//                 } else {
//                   if (formap.CON_CODIGO === '') {
//                     formap.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
//                   }
//                   // this.cxctrnretencionesService.insertarBanco(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                   //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
//                   //     this.cxctrnretencionesService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).subscribe(() => {
//                     await this.cxctrnretencionesService.insertarBanco(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).toPromise()
//                     await this.cxctrnretencionesService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).toPromise()
//                         this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.TRNCAJ_NUMERO + '/' + cobro.TRNCOBRO_NRODOC), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                           });
//                      // });
//                     //});
//                 }
//                 // this.cxctrnretencionesService.generarAsientoDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//                 //   this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
//                 //     this.asiento = res[0].ASI_NRO;
//                 //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//                 //     this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                 //   });
//                 // });
//               }));
//               // this.cxctrnretencionesService.generarAsientoDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//               //   this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
//               //     this.asiento = res[0].ASI_NRO;
//               //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//               //     this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//               //     this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
//               //       '', '01', '', '', '', '').subscribe(() => {
//               //         this.message.add({
//               //           key: 'cxctrnretenciones',
//               //           severity: 'success',
//               //           summary: 'Asiento Contable',
//               //           detail: 'Se ha generado el asiento contable'
//               //         });
//               //       });
//               //     this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//               //   });
//               // });
//             }
//             await this.generarAsientoDAN()
//           }//);
//           // this.cambiarBotones();
//           // this.consultarPestañas();
//            // setTimeout(() => {
//       //   this.cambiarBotones();
//       //   this.cancelarEdicionCartera();
//       // }, 3000);
//          // await this.generarAsientoDAN()
//         }//);
        
//     } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
//       // this.cxctrnretencionesService.actualizarCobro(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//       //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//           const resp=await this.cxctrnretencionesService.actualizarCobro(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).toPromise()
//             for (const cobro of resp ){

//           // this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro.TRNCOBRO_NRODOC;
//           // this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
           
//             const resp1= await this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).toPromise()
//             for (const saldo of resp1 ){
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo.SALDO;
//             // this.cxctrnretencionesService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
//             //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
//             //   });
//               await this.cxctrnretencionesService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
//               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').toPromise()
            
//               if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//                 await Promise.all(this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map(async (resumen) => {
//                 if (resumen.SELECCIONADO === true) {
//                   resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                   resumen.STRCUOTAFAC = 'F';
//                   if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                     resumen.STRCUOTAFAC = 'C';
//                   }
//                   // this.cxctrnretencionesService.insertarDocPorCobrar(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                   //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//                     await this.cxctrnretencionesService.insertarDocPorCobrar(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).toPromise()
//                       if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                         this.actualizarCuotasPagadas(resumen);
//                       }
//                       this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro.TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'A',
//                         '', '01', '', '', '', '').subscribe(() => {
//                         });
//                     //});
//                 }
//               }));
//             }
//             if (this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].length !== 0) {
//               //this.cxctrnretencionesService.eliminarCaja(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
//                 await this.cxctrnretencionesService.eliminarCaja(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).toPromise()
//                 //this.cxctrnretencionesService.eliminarBanco(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
//                 await this.cxctrnretencionesService.eliminarBanco(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).toPromise()
//                 await Promise.all( this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].map(async (formap) => {
//                     if (formap.TRNCAJ_TIPO === 'EF') {
//                       //this.cxctrnretencionesService.insertarCaja(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                         //this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
//                         await this.cxctrnretencionesService.insertarCaja(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).toPromise()
//                         this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.TRNCAJ_NUMERO + '/' + cobro.TRNCOBRO_NRODOC), 'A',
//                             '', '01', '', '', '', '').subscribe(() => {
//                             });
//                         //});
//                     } else {
//                       if (formap.CON_CODIGO === '') {
//                         formap.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
//                       }
//                       // this.cxctrnretencionesService.insertarBanco(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                       //   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
//                           // this.cxctrnretencionesService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).subscribe(() => {
//                         await this.cxctrnretencionesService.insertarBanco(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                              this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).toPromise()
//                         await this.cxctrnretencionesService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).toPromise()
//                             this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.TRNCAJ_NUMERO + '/' + cobro.TRNCOBRO_NRODOC), 'A',
//                               '', '01', '', '', '', '').subscribe(() => {
//                               });
//                           //});
//                         //});
//                     }
//                   }));
//                   // this.cxctrnretencionesService.generarAsientoDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//                   //   this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
//                   //     this.asiento = res[0].ASI_NRO;
//                   //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//                   //     this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                   //   });
//                   // });
//                   // this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
//                   //   '', '01', '', '', '', '').subscribe(() => {
//                   //   });
//                // });
//                 // this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
//                 //   '', '01', '', '', '', '').subscribe(() => {
//                 //   });
//               //});
//             }
//             // this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro.TRNCOBRO_NRODOC + '/' + cobro.TRNCOBRO_NROCOMPROBANTE), 'A',
//             //   '', '01', '', '', '', '').subscribe(() => {
//             //     this.message.add({
//             //       key: 'cxctrnretenciones',
//             //       severity: 'success',
//             //       summary: 'Inserción de Cartera',
//             //       detail: 'Se ha insertado el documento exitosamente'
//             //     });
//             //   });
//               await this.generarAsientoDAN()
//           }//);
//           // this.cambiarBotones();
//           // this.consultarPestañas();
          
//         }//);
//     }
//     //CDPJ
//     // if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
//     //   this.cxctrnretencionesService.insertarCobroDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//     //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//     //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//     //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NROCOMPROBANTE;
//     //       this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//     //         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//     //         this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
//     //           '', '01', '', '', '', '').subscribe(() => {
//     //             this.message.add({
//     //               key: 'cxctrnretenciones',
//     //               severity: 'success',
//     //               summary: 'Inserción de Cartera',
//     //               detail: 'Se ha insertado el documento exitosamente'
//     //             });
//     //           });
//     //         if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//     //           this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//     //             if (resumen.SELECCIONADO === true) {
//     //               resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//     //               resumen.STRCUOTAFAC = 'F';
//     //               if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//     //                 resumen.STRCUOTAFAC = 'C';
//     //               }
//     //               this.cxctrnretencionesService.insertarDocPorCobrar(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//     //                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//     //                   if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//     //                     this.actualizarCuotasPagadas(resumen);
//     //                   }
//     //                   this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
//     //                     '', '01', '', '', '', '').subscribe(() => {
//     //                     });
//     //                 });
//     //             }
//     //           });
//     //         }
//     //         if (this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].length !== 0) {
//     //           this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].map((formap) => {
//     //             if (formap.TRNCAJ_TIPO === 'EF') {
//     //               //GSRF
//     //               if(formap.TRNCAJ_DESCRIPCION === ''){
//     //                 formap.TRNCAJ_DESCRIPCION = '*';
//     //               }
//     //               //GSRF
//     //               this.cxctrnretencionesService.insertarCaja(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//     //                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
//     //                   this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
//     //                     '', '01', '', '', '', '').subscribe(() => {
//     //                     });
//     //                 });
//     //             } else {
//     //               if (formap.CON_CODIGO === '') {
//     //                 formap.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
//     //               }
//     //               this.cxctrnretencionesService.insertarBanco(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//     //                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
//     //                   this.cxctrnretencionesService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).subscribe(() => {
//     //                     this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'I',
//     //                       '', '01', '', '', '', '').subscribe(() => {
//     //                       });
//     //                   });
//     //                 });
//     //             }
//     //             this.cxctrnretencionesService.generarAsientoDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//     //               this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
//     //                 this.asiento = res[0].ASI_NRO;
//     //                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//     //                 this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//     //               });
//     //             });
//     //           });
//     //           this.cxctrnretencionesService.generarAsientoDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//     //             this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
//     //               this.asiento = res[0].ASI_NRO;
//     //               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//     //               this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//     //               this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
//     //                 '', '01', '', '', '', '').subscribe(() => {
//     //                   this.message.add({
//     //                     key: 'cxctrnretenciones',
//     //                     severity: 'success',
//     //                     summary: 'Asiento Contable',
//     //                     detail: 'Se ha generado el asiento contable'
//     //                   });
//     //                 });
//     //               this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//     //             });
//     //           });
//     //         }
//     //       });
//     //       // this.cambiarBotones();
//     //       // this.consultarPestañas();
//     //     });
//     // } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
//     //   this.cxctrnretencionesService.actualizarCobro(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//     //     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//     //       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//     //       this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//     //         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//     //         this.cxctrnretencionesService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
//     //           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
//     //           });
//     //         if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//     //           this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//     //             if (resumen.SELECCIONADO === true) {
//     //               resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//     //               resumen.STRCUOTAFAC = 'F';
//     //               if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//     //                 resumen.STRCUOTAFAC = 'C';
//     //               }
//     //               this.cxctrnretencionesService.insertarDocPorCobrar(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//     //                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//     //                   if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//     //                     this.actualizarCuotasPagadas(resumen);
//     //                   }
//     //                   this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'A',
//     //                     '', '01', '', '', '', '').subscribe(() => {
//     //                     });
//     //                 });
//     //             }
//     //           });
//     //         }
//     //         if (this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].length !== 0) {
//     //           this.cxctrnretencionesService.eliminarCaja(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
//     //             this.cxctrnretencionesService.eliminarBanco(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe(() => {
//     //               this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador].map((formap) => {
//     //                 if (formap.TRNCAJ_TIPO === 'EF') {
//     //                   this.cxctrnretencionesService.insertarCaja(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//     //                     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
//     //                       this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'A',
//     //                         '', '01', '', '', '', '').subscribe(() => {
//     //                         });
//     //                     });
//     //                 } else {
//     //                   if (formap.CON_CODIGO === '') {
//     //                     formap.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
//     //                   }
//     //                   this.cxctrnretencionesService.insertarBanco(formap, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//     //                     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO).subscribe(() => {
//     //                       this.cxctrnretencionesService.actualizarCheque(formap.TRNCAJ_NUMERO, formap.BAN_CODIGO).subscribe(() => {
//     //                         this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(formap.TRNCAJ_NUMERO + '/' + cobro[0].TRNCOBRO_NRODOC), 'A',
//     //                           '', '01', '', '', '', '').subscribe(() => {
//     //                           });
//     //                       });
//     //                     });
//     //                 }
//     //               });
//     //               this.cxctrnretencionesService.generarAsientoDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//     //                 this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
//     //                   this.asiento = res[0].ASI_NRO;
//     //                   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//     //                   this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//     //                 });
//     //               });
//     //               this.auditoriagral.registrarAuditoria('BAN_TRNBAN', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
//     //                 '', '01', '', '', '', '').subscribe(() => {
//     //                 });
//     //             });
//     //             this.auditoriagral.registrarAuditoria('BAN_TRNCAJ', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC), 'E',
//     //               '', '01', '', '', '', '').subscribe(() => {
//     //               });
//     //           });
//     //         }
//     //         this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
//     //           '', '01', '', '', '', '').subscribe(() => {
//     //             this.message.add({
//     //               key: 'cxctrnretenciones',
//     //               severity: 'success',
//     //               summary: 'Inserción de Cartera',
//     //               detail: 'Se ha insertado el documento exitosamente'
//     //             });
//     //           });
//     //         this.cxctrnretencionesService.generarAsientoDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
//     //           this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
//     //             this.asiento = res[0].ASI_NRO;
//     //             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//     //             this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//     //             this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
//     //               String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'),
//     //               'I', '', '01', '', '', '', '').subscribe(() => {
//     //                 this.message.add({
//     //                   key: 'cxctrnretenciones',
//     //                   severity: 'success',
//     //                   summary: 'Asiento Contable',
//     //                   detail: 'Se ha generado el asiento contable'
//     //                 });
//     //               });
//     //             this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//     //           });
//     //         });
//     //       });
//     //       // this.cambiarBotones();
//     //       // this.consultarPestañas();
//     //     });
//     // }
//   }
// //CDPJ
// async generarAsientoDAN(){
//   // this.cxctrnretencionesService.generarAsientoDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).pipe(finalize(async() => {await  this.getAsientoDAN()})).subscribe(() => {
    
//   // });
//   await this.cxctrnretencionesService.generarAsientoDAN(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).toPromise()
// await this.getAsientoDAN()
// }
// async getAsientoDAN(){
//   //this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
//     const resp = await  this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).toPromise()
//     for (const res of resp){
//     this.asiento = res.ASI_NRO;
//     console.log('antes asiento',this.asiento)
//     this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
//     this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//     this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
//       String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'),
//       'I', '', '01', '', '', '', '').subscribe(() => {
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'success',
//           summary: 'Asiento Contable',
//           detail: 'Se ha generado el asiento contable'
//         });
//         this.spin=false//CDPJ
//       });
//     this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//     await  this.cambiarBotones();
//     await this.cancelarEdicionCartera();
//   }//);
// }

// //CDPJ
//   async saveNC() {
//     let errores=0
//     const dblTotalVarios = Number(this.calcularTotalVarios())*(-1);
//     const dblImporteDoc = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALNC) * (-1);
//     const strNombreDoc = 'Nota de Crédito';
//     console.log('2', dblImporteDoc, dblTotalVarios);
//     if (dblImporteDoc !== dblTotalVarios) {//CDPJ ANTES dblImporteDoc == dblTotalVarios
//       this.message.add({
//         key: 'cxctrnretenciones',
//         severity: 'error',
//         summary: 'Información',
//         detail: 'El total de Varios debe ser igual al ' +
//           'total del documento ' + strNombreDoc
//       });
//       this.spin=false//CDPJ
//       return;
//     }
// //CDPJ
// if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CCCAVNC').CFG_VALOR1 === 1 && this.saldoInicial === false ){
//   this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].map((vario) => {
//     vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;
//     if(vario.CON_CENTRO ==='Si'){
//       if(vario.CEN_CODIGO === ''){
//         this.message.add({
//           key: 'cxctrnretenciones',
//           severity: 'error',
//           summary: 'Información',
//           detail: 'Ingrese el centro de costos de la cuenta contable ' + vario.CON_CODIGO
           
//         });
//         errores++;
//         this.spin=false//CDPJ
//         return;
//       }
//     }
//   })
// }

// //CDPJ
//     const exis = await this.cxctrnretencionesService.verificarComprobanteUnico('N',
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE, '');
//     console.log(exis);
//     if (exis[0][':B1'] !== '0' && exis[0][':B1'] !== null && exis[0][':B1'] !== undefined) {
//       this.message.add({
//         key: 'cxctrnretenciones',
//         severity: 'error',
//         summary: 'Inserción de Cartera',
//         detail: 'El numero de comprobante ya existe'
//       });
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = '';
//       document.getElementById('numeroCartera').focus();
//       this.spin=false//CDPJ
//       return;
//     }

//     if (this.saldoInicial === false && errores === 0) {
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 0;
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
//       if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
//         if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//           this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//             if (resumen.SELECCIONADO === true) {
//               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = resumen.TRNCOBRO_IMPORTE;
//             }
//           });
//         }

//         //console.log('insertarCobro--->',this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALNC);
//         this.cxctrnretencionesService.insertarCobro(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//             if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE === '') {
//               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NROCOMPROBANTE;
//             }
//             this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//               if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//                 this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                   if (resumen.SELECCIONADO === true) {
//                     resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                     resumen.TRNCOBRO_REFERENCIA = 'F';
//                     resumen.STRCUOTAFAC = 'F';
//                     if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                       resumen.STRCUOTAFAC = 'C';
//                     }
//                     this.cxctrnretencionesService.insertarDocPorCobrar(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//                         if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                           this.actualizarCuotasPagadas(resumen);
//                         }
//                         this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                           });
//                       });
//                   }
//                 });
//               }
//               this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
//                 '', '01', '', '', '', '').subscribe(() => {
//                   this.message.add({
//                     key: 'cxctrnretenciones',
//                     severity: 'success',
//                     summary: 'Inserción de Cartera',
//                     detail: 'Se ha insertado el documento exitosamente'
//                   });
//                   this.spin=false//CDPJ
//                 });
//             });
//             if (this.tabVarios === true) {
//               this.cxctrnretencionesService.eliminarVarios(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
//                 this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(cobro[0].TRNCOBRO_NRODOC), 'E',
//                   '', '01', '', '', '', '').subscribe(() => {
//                   });
//                 if (this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length !== 0) {
//                   await Promise.all(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].map(async (vario) => {
//                     vario.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                     vario.TRNCOBRO_NRODOC = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
//                     vario.TRNCOBRO_NROCOMPROBANTE = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
//                     if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
//                       vario.TRNVARIOS_IMPORTE = vario.DEBE;
//                     }
//                     if (vario.HABER !== '' && Number(vario.HABER) > 0) {
//                       vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
//                     }
//                     vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
//                     this.cxctrnretencionesService.insertarVarios(vario).subscribe(() => {
//                       vario.TRNVARIOS_LINEA = vario.LINEA;
//                       this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(vario.TRNCOBRO_NRODOC), 'I',
//                         '', '01', '', '', '', '').subscribe(() => {
//                         });
//                       });
//                       this.cxctrnretencionesService.generarAsientoNC(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
//                         this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
//                         this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                         if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//                           this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                             if (resumen.SELECCIONADO === true) {
//                               resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                               this.cxctrnretencionesService.actualizarestadoFac(resumen).subscribe(() => {
//                               }, error1 => {
//                                 console.log(error1.error);
//                               });
//                             }
//                           });
//                         }
//                         this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                         this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                             this.message.add({
//                               key: 'cxctrnretenciones',
//                               severity: 'success',
//                               summary: 'Asiento Contable',
//                               detail: 'Se ha generado el asiento contable'
//                             });
//                             this.spin=false//CDPJ
//                           });
//           this.cambiarBotones();
//         this.cancelarEdicionCartera();
//                       });
                    
//                     //CDPJ 
//                     if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
//                       console.log(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV'))

//                       const data= await this.cxctrnretencionesService.insertarMovbancos(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO);
//                     }  
//                   //CDPJ
//                   }));
//                 }
//               });
//             } else {
//               this.cxctrnretencionesService.generarAsientoNC(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
//                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
//                 this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                 if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//                   this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                     if (resumen.SELECCIONADO === true) {
//                       resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                       this.cxctrnretencionesService.actualizarestadoFac(resumen).subscribe(() => {
//                       }, error1 => {
//                         console.log(error1.error);
//                       });
//                     }
//                   });
//                 }
//                 this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                 this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
//                   '', '01', '', '', '', '').subscribe(() => {
//                     this.message.add({
//                       key: 'cxctrnretenciones',
//                       severity: 'success',
//                       summary: 'Asiento Contable',
//                       detail: 'Se ha generado el asiento contable'
//                     });
//                     this.spin=false//CDPJ
//                   });
//               });
//             }
//             // this.cambiarBotones();
//             // this.consultarPestañas();
//           });
//       } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
//         if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//           this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//             if (resumen.SELECCIONADO === true) {
//               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = resumen.TRNCOBRO_IMPORTE;
//             }
//           });
//         }
//         this.cxctrnretencionesService.actualizarCobro(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//             this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//               this.cxctrnretencionesService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
//                 this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
//                 });
//               if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//                 this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                   if (resumen.SELECCIONADO === true) {
//                     resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                     resumen.TRNCOBRO_REFERENCIA = 'F';
//                     resumen.STRCUOTAFAC = 'F';
//                     if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                       resumen.STRCUOTAFAC = 'C';
//                     }
//                     this.cxctrnretencionesService.insertarDocPorCobrar(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
//                       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
//                         if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
//                           this.actualizarCuotasPagadas(resumen);
//                         }
//                         this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                           });
//                       });
//                   }
//                 });
//               }
//               if (this.tabVarios === true) {
//                 this.cxctrnretencionesService.eliminarVarios(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(async () => {
//                   this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(cobro[0].TRNCOBRO_NRODOC), 'E',
//                     '', '01', '', '', '', '').subscribe(() => {
//                     });
//                   if (this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length !== 0) {
//                     await Promise.all(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].map(async (vario) => {
//                       vario.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                       vario.TRNCOBRO_NRODOC = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
//                       vario.TRNCOBRO_NROCOMPROBANTE = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
//                       if (vario.DEBE !== '' && Number(vario.DEBE) > 0) {
//                         vario.TRNVARIOS_IMPORTE = vario.DEBE;
//                       }
//                       if (vario.HABER !== '' && Number(vario.HABER) > 0) {
//                         vario.TRNVARIOS_IMPORTE = '-' + vario.HABER;
//                       }
//                       vario.CEN_CODIGO=vario.CEN_CODIGO === null || vario.CEN_CODIGO === undefined ?'':vario.CEN_CODIGO;//cdpj
//                       this.cxctrnretencionesService.insertarVarios(vario).subscribe(() => {
//                         vario.TRNVARIOS_LINEA = vario.LINEA;
//                       });
//                         this.cxctrnretencionesService.generarAsientoNC(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
//                           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
//                           this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                           if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//                             this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                               if (resumen.SELECCIONADO === true) {
//                                 resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                                 this.cxctrnretencionesService.actualizarestadoFac(resumen).subscribe(() => {
//                                 }, error1 => {
//                                   console.log(error1.error);
//                                 });
//                               }
//                             });
//                           }
//                           this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                           this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
//                             '', '01', '', '', '', '').subscribe(() => {
//                               this.message.add({
//                                 key: 'cxctrnretenciones',
//                                 severity: 'success',
//                                 summary: 'Asiento Contable',
//                                 detail: 'Se ha generado el asiento contable'
//                               });
//                               this.spin=false//CDPJ
//                             });
//                         });
//                         this.auditoriagral.registrarAuditoria('CXC_TRNVARIOS', String(vario.TRNCOBRO_NRODOC), 'I',
//                           '', '01', '', '', '', '').subscribe(() => {
//                           });
                      
//                       //CDPJ 
//                       if(this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'IMOBANCXCV').CFG_VALOR1 === 1){
//                         const data= await this.cxctrnretencionesService.insertarMovbancos(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_NOMBRE,this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].APLORG_CODIGO);
//                       }  
//                     //CDPJ
//                     }));
//                   }
//                 });
//               } else {
//                 this.cxctrnretencionesService.generarAsientoNC(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe((asi) => {
//                   this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = asi[0][':B1'];
//                   this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                   if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
//                     this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
//                       if (resumen.SELECCIONADO === true) {
//                         resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
//                         this.cxctrnretencionesService.actualizarestadoFac(resumen).subscribe(() => {
//                         }, error1 => {
//                           console.log(error1.error);
//                         });
//                       }
//                     });
//                   }
//                   this.actualizarNumeroAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
//                   this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(asi[0][':B1'] + '/CXC'), 'I',
//                     '', '01', '', '', '', '').subscribe(() => {
//                       this.message.add({
//                         key: 'cxctrnretenciones',
//                         severity: 'success',
//                         summary: 'Asiento Contable',
//                         detail: 'Se ha generado el asiento contable'
//                       });
//                       this.spin=false//CDPJ
//                     });
//                 });
//               }
//               this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
//                 '', '01', '', '', '', '').subscribe(() => {
//                   this.message.add({
//                     key: 'cxctrnretenciones',
//                     severity: 'success',
//                     summary: 'Inserción de Cartera',
//                     detail: 'Se ha insertado el documento exitosamente'
//                   });
//                   this.spin=false//CDPJ
//                 });
//             });
//             // this.cambiarBotones();
//             // this.consultarPestañas();
//           });
//       }
//     } else if (this.saldoInicial === true) {
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_SALDOINI = 1;
//       this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
//       if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
//         this.cxctrnretencionesService.insertarCobro(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//             this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//               this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
//                 '', '01', '', '', '', '').subscribe(() => {
//                   this.message.add({
//                     key: 'cxctrnretenciones',
//                     severity: 'success',
//                     summary: 'Inserción de Cartera',
//                     detail: 'Se ha insertado el documento exitosamente'
//                   });
//                   this.spin=false//CDPJ
//                 });
//             });
//           });
//       } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {
//         this.cxctrnretencionesService.actualizarCobro(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
//           this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
//             this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
//             this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
//               this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
//               this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
//                 '', '01', '', '', '', '').subscribe(() => {
//                   this.message.add({
//                     key: 'cxctrnretenciones',
//                     severity: 'success',
//                     summary: 'Inserción de Cartera',
//                     detail: 'Se ha insertado el documento exitosamente'
//                   });
//                   this.spin=false//CDPJ
//                 });
//             });
//             // this.cambiarBotones();
//             // this.consultarPestañas();
//           });
//       }
//     }
//   }

  async saveRT() {
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].BOD_CODIGO = '';
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC === '') {
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_IMPORTE = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALDOC;
      console.log('await insertarCobroP');
      const cobro = await this.cxctrnretencionesService.insertarCobroP(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = cobro[0].TRNCOBRO_NROCOMPROBANTE;
      console.log('await obtenerSaldoClienteP');
      const saldo = await this.cxctrnretencionesService.obtenerSaldoClienteP(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
      if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
        for (const resumen of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
          if (resumen.SELECCIONADO === true) {
            resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            resumen.STRCUOTAFAC = 'F';
            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              resumen.STRCUOTAFAC = 'C';
            }

            await this.cxctrnretencionesService.insertarDocPorCobrarP(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
              this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);

            console.log('await insertarDocPorCobrarP');

            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              await this.actualizarCuotasPagadas(resumen);
            }
            this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
              '', '01', '', '', '', '').subscribe(() => {
              });
          }
        }
        /*this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map(async (resumen) => {
          if (resumen.SELECCIONADO === true) {
            resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            resumen.STRCUOTAFAC = 'F';
            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              resumen.STRCUOTAFAC = 'C';
            }
            console.log('await insertarDocPorCobrarP');
            await this.cxctrnretencionesService.insertarDocPorCobrarP(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
              this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              this.actualizarCuotasPagadas(resumen);
            }
            this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
              '', '01', '', '', '', '').subscribe(() => {
              });
          }
        });*/
      }
      this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'I',
        '', '01', '', '', '', '').subscribe();
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'success',
        summary: 'Inserción de Cartera',
        detail: 'Se ha insertado el documento exitosamente'
      });
      this.spin=false//CDPJ
      if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length !== 0) {
        for (const ret of this.cxctrnretencionesService.cxctrnretencionarray[this.indicador]) {
          ret.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
          if (ret.CON_CODIGO === '') {
            ret.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
          }
          ret.CEN_CODIGO = '';
          console.log(this.numeroFactura);
          ret.ENCFAC_NUMERO = this.numeroFactura;
          console.log('await insertarRetencionP');
          await this.cxctrnretencionesService.insertarRetencionP(ret, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
            this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
         
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE)+'/codret/'+ret.RETENCION_CODIGO, 'I',
          '', '01', '', '', '', '').subscribe();

          if (this.retencionElect === true) {
            await this.cxctrnretencionesService.actualizarTipoRetencionProm(ret, 'E');
            /*  this.cxctrnretencionesService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
             }); */
          } else if (this.retencionElect === false) {
            await this.cxctrnretencionesService.actualizarTipoRetencionProm(ret, 'F');
            /* this.cxctrnretencionesService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
            }); */
          }
        }
        /*this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].map(async (ret) => {
          ret.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
          if (ret.CON_CODIGO === '') {
            ret.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
          }
          ret.CEN_CODIGO = '';
          console.log(this.numeroFactura);
          ret.ENCFAC_NUMERO = this.numeroFactura;
          console.log('await insertarRetencionP');
          await this.cxctrnretencionesService.insertarRetencionP(ret, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
            this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
          if (this.retencionElect === true) {
            this.cxctrnretencionesService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
            });
          } else if (this.retencionElect === false) {
            this.cxctrnretencionesService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
            });
          }
        });*/
        console.log('SUBSCRIBE generarAsientoRT');
        await this.generarasientoret();

      }
    } else if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '') {

      const cobro = await this.cxctrnretencionesService.actualizarCobroProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC);
      console.log('actualizaCobro')
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
      const saldo = await this.cxctrnretencionesService.obtenerSaldoClienteP(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
      console.log('obtiene saldo cliente');
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
      await this.cxctrnretencionesService.eliminarTrnDocumentoProm('TRNCOBRO_NRODOC = \'' +
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'');
      console.log('eliminartrndocumento');

      if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
        for (const resumen of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
          if (resumen.SELECCIONADO === true) {
            resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            resumen.STRCUOTAFAC = 'F';
            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              resumen.STRCUOTAFAC = 'C';
            }
            await this.cxctrnretencionesService.insertarDocPorCobrarP(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
              this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
            if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
              await this.actualizarCuotasPagadas(resumen);
            }
            this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
              '', '01', '', '', '', '').subscribe(() => { });

          }
        }

      }

      if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length !== 0) {
        await this.cxctrnretencionesService.eliminarTrnRetencionProm('TRNCOBRO_NRODOC = \'' +
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC +
          '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'');
        for (const ret of this.cxctrnretencionesService.cxctrnretencionarray[this.indicador]) {
          ret.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
          if (ret.CON_CODIGO === '') {
            ret.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
          }
          ret.CEN_CODIGO = '';
          ret.ENCFAC_NUMERO = this.numeroFactura;
          await this.cxctrnretencionesService.insertarRetencionP(ret, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
            this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE);
          if (this.retencionElect === true) {
            await this.cxctrnretencionesService.actualizarTipoRetencionProm(ret, 'E');
          } else if (this.retencionElect === false) {
            await this.cxctrnretencionesService.actualizarTipoRetencionProm(ret, 'F');
          }
        }
      }

      this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
        '', '01', '', '', '', '').subscribe(() => {
          this.message.add({
            key: 'cxctrnretenciones',
            severity: 'success',
            summary: 'Inserción de Cartera',
            detail: 'Se ha insertado el documento exitosamente'
          });
          this.spin=false//CDPJ
        });

      try {
        await this.cxctrnretencionesService.generarAsientoRTProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]);
        console.log('Se generó el asiento');
        const res = await this.cxctrnretencionesService.getAsientoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
        console.log('Obtiene el asiento');

        this.asiento = res[0].ASI_NRO;
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
        this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);
        if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
          for (const resumen of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
            if (resumen.SELECCIONADO === true) {
              resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              await this.cxctrnretencionesService.actualizarestadoFacProm(resumen);
            }
          }
        }

        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
          '', '01', '', '', '', '').subscribe();
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'success',
          summary: 'Asiento Contable',
          detail: 'Se ha generado el asiento contable'
        });
        this.spin=false//CDPJ
      } catch (err) {
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'error',
          summary: 'Asiento Contable',
          detail: err.error
        });
        this.spin=false//CDPJ
      }



      /*this.cxctrnretencionesService.actualizarCobro(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador],
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC).subscribe((cobro) => {
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC = cobro[0].TRNCOBRO_NRODOC;
          this.cxctrnretencionesService.obtenerSaldoCliente(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO).subscribe((saldo) => {
            this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].SALDO = saldo[0].SALDO;
            this.cxctrnretencionesService.eliminarTrnDocumento('TRNCOBRO_NRODOC = \'' +
              this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC + '\' AND COM_CODIGO = \'01\'').subscribe(() => {
              });
            if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
              this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
                if (resumen.SELECCIONADO === true) {
                  resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                  resumen.STRCUOTAFAC = 'F';
                  if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                    resumen.STRCUOTAFAC = 'C';
                  }
                  this.cxctrnretencionesService.insertarDocPorCobrar(resumen, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                      if (resumen.APLORG_CODIGO === 'SISTEMA' && resumen.TRNCOBRO_TIPODOC === 'FC') {
                        this.actualizarCuotasPagadas(resumen);
                      }
                      this.auditoriagral.registrarAuditoria('CXC_TRNDOCUMENTO', String(cobro[0].TRNCOBRO_NRODOC + '/' + resumen.TRNCOBRO_NRODOC), 'I',
                        '', '01', '', '', '', '').subscribe(() => {
                        });
                    });
                }
              });
            }
            if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length !== 0) {
              this.cxctrnretencionesService.eliminarTrnRetencion('TRNCOBRO_NRODOC = \'' +
                this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC +
                '\' AND COM_CODIGO = \'01\' AND TRNRETENCION_ORIGEN = \'CXC\'').subscribe(() => {
                });
              this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].map((ret) => {
                ret.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                if (ret.CON_CODIGO === '') {
                  ret.CON_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CON_CODIGO;
                }
                ret.CEN_CODIGO = '';
                ret.ENCFAC_NUMERO = this.numeroFactura;
                this.cxctrnretencionesService.insertarRetencion(ret, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC,
                  this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE).subscribe(() => {
                    if (this.retencionElect === true) {
                      this.cxctrnretencionesService.actualizarTipoRetencion(ret, 'E').subscribe(() => {
                      });
                    } else if (this.retencionElect === false) {
                      this.cxctrnretencionesService.actualizarTipoRetencion(ret, 'F').subscribe(() => {
                      });
                    }
                  });
              });
            }
            this.auditoriagral.registrarAuditoria('CXC_TRNCOBRO', String(cobro[0].TRNCOBRO_NRODOC + '/' + cobro[0].TRNCOBRO_NROCOMPROBANTE), 'A',
              '', '01', '', '', '', '').subscribe(() => {
                this.message.add({
                  key: 'cxctrnretenciones',
                  severity: 'success',
                  summary: 'Inserción de Cartera',
                  detail: 'Se ha insertado el documento exitosamente'
                });
              });
            this.cxctrnretencionesService.generarAsientoRT(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
              this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
                this.asiento = res[0].ASI_NRO;
                this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
                if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
                  this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
                    if (resumen.SELECCIONADO === true) {
                      resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
                      this.cxctrnretencionesService.actualizarestadoFac(resumen).subscribe(() => {
                      }, error1 => {
                        console.log(error1.error);
                      });
                    }
                  });
                }
                this.auditoriagral.registrarAuditoria('CON_ENCASI_L',
                  String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'),
                  'I', '', '01', '', '', '', '').subscribe(() => {
                    this.message.add({
                      key: 'cxctrnretenciones',
                      severity: 'success',
                      summary: 'Asiento Contable',
                      detail: 'Se ha generado el asiento contable'
                    });
                  });
              });
            });
          });
          // this.cambiarBotones();
          // this.consultarPestañas();
        }); */
    }
  }


  // metodos nuevos

  generarasientoretOld() {
    this.cxctrnretencionesService.generarAsientoRT(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.asiento = res[0].ASI_NRO;
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
        if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
          this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
            if (resumen.SELECCIONADO === true) {
              resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              this.cxctrnretencionesService.actualizarestadoFac(resumen).subscribe(() => {
              }, error1 => {
                console.log(error1.error);
              });
            }
          });
        }
        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
          '', '01', '', '', '', '').subscribe();
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'success',
          summary: 'Asiento Contable',
          detail: 'Se ha generado el asiento contable'
        });
      });
    });
  }

  async generarasientoret() {
    try {
      await this.cxctrnretencionesService.generarAsientoRTProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]);
      console.log('Se generó el asiento');
      const res = await this.cxctrnretencionesService.getAsientoProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
      console.log('Obtiene el asiento');

      this.asiento = res[0].ASI_NRO;
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
      if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
        for (const resumen of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
          if (resumen.SELECCIONADO === true) {
            resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
            await this.cxctrnretencionesService.actualizarestadoFacProm(resumen);
          }
        }
      }

      this.actualizarNumeroComprobante(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO);

      this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
        '', '01', '', '', '', '').subscribe();
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'success',
        summary: 'Asiento Contable',
        detail: 'Se ha generado el asiento contable'
      });
      this.spin=false//CDPJ
    } catch (err) {
      this.message.add({
        key: 'cxctrnretenciones',
        severity: 'error',
        summary: 'Asiento Contable',
        detail: err.error
      });
      this.spin=false//CDPJ
    }
    /*this.cxctrnretencionesService.generarAsientoRT(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador]).subscribe(() => {
      this.cxctrnretencionesService.getAsiento(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC).subscribe((res) => {
        this.asiento = res[0].ASI_NRO;
        this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO = this.asiento;
        if (this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length !== 0) {
          this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].map((resumen) => {
            if (resumen.SELECCIONADO === true) {
              resumen.CLI_CODIGO = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO;
              this.cxctrnretencionesService.actualizarestadoFac(resumen).subscribe(() => {
              }, error1 => {
                console.log(error1.error);
              });
            }
          });
        }
        this.auditoriagral.registrarAuditoria('CON_ENCASI_L', String(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].ASI_NRO + '/CXC'), 'I',
          '', '01', '', '', '', '').subscribe();
        this.message.add({
          key: 'cxctrnretenciones',
          severity: 'success',
          summary: 'Asiento Contable',
          detail: 'Se ha generado el asiento contable'
        });
      });
    });*/
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'cxctrnretenciones',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  controlarFechaCobro() {
    let bolCorrecto = true;
    let strFechaDoc = this.obtenerFechaDoc();

    for (const item of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
      if (item.SELECCIONADO !== null) {
        if (item.SELECCIONADO) {
          const strFechaFact = this.dateValidation(item.TRNCOBRO_FECHATRN);
          console.log(strFechaFact.getTime() > strFechaDoc.getTime());
          console.log(strFechaFact.getTime(), strFechaDoc.getTime());
          if (strFechaFact.getTime() > strFechaDoc.getTime()) {
            bolCorrecto = false;
          }
        }
      }
    }

    return bolCorrecto;
  }
  //GSRF
  controlarFechaforpago() {
    let bolCorrecto = true;
    let strFechaDoc = this.obtenerFechaDoc();
    for (const item of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
      if (item.SELECCIONADO !== null) {
        if (item.SELECCIONADO) {
          const strFechaFact = this.dateValidation(item.TRNCOBRO_FECHATRN);
          for (const item1 of this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador]) {
            if (item1.TRNPAGO_TIPO !== null) {
              const strFechaFact1 = this.dateValidation(item1.TRNPAGO_FECHA);
              // console.log(strFechaFact1.getTime() > strFechaFact.getTime());
              //console.log(strFechaFact1.getTime(), strFechaFact.getTime());
              if (strFechaFact.getTime() > strFechaFact1.getTime()) {
                bolCorrecto = false;
              }
            }
          }
        }
      }
    };

    return bolCorrecto;
  }
  controlarFechaforpagodev() {
    let bolCorrecto = true;
    let strFechaDoc = this.obtenerFechaDoc();
    for (const item of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
      if (item.SELECCIONADO !== null) {
        if (item.SELECCIONADO) {
          const strFechaFact = this.dateValidation(item.TRNCOBRO_FECHATRN);
          for (const item1 of this.cxctrnretencionesService.cxctrnformapagodevarray[this.indicador]) {
            if (item1.TRNCAJ_TIPO !== null) {
              const strFechaFact1 = this.dateValidation(item1.TRNCAJ_FECHA);
              //console.log(strFechaFact1.getTime() > strFechaFact.getTime());
              //console.log(strFechaFact1.getTime(), strFechaFact.getTime());
              if (strFechaFact.getTime() > strFechaFact1.getTime()) {
                bolCorrecto = false;
              }
            }
          }
        }
      }
    };

    return bolCorrecto;
  }
  //GSRF

  obtenerFechaDoc() {
    let strFecha: any = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('-')) {
      strFecha = this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,
        'dd/MM/yyyy');
    }
    const fechaAct = String(strFecha).split('/');
    const fechaNew = new Date(Number(fechaAct[2]), Number(fechaAct[1]) - 1, Number(fechaAct[0]));

    return fechaNew;
  }

  obtenerFechaDocString() {
    let strFecha: any = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN;
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN.toString().includes('-')) {
      strFecha = this.datePipe.transform(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_FECHATRN,
        'dd/MM/yyyy');
    }

    return strFecha;
  }

  dateValidation(fecha) {
    let strFecha: any = fecha;
    if (fecha.toString().includes('-')) {
      strFecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    }
    const fechaAct = String(strFecha).split('/');
    const fechaNew = new Date(Number(fechaAct[2]), Number(fechaAct[1]) - 1, Number(fechaAct[0]));

    return fechaNew;
  }

  async existeCliente() {
    let bolExiste = true;
    let strNombreCliente = '';
    try {
      const er = await this.cxctrnretencionesService.erClinombreProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].CLI_CODIGO);
      if (er !== null) {
        if (er[0] !== undefined) {
          strNombreCliente = er[0].CLI_NOMBRE === null ? '' : er[0].CLI_NOMBRE;
        }
      }
      if (String(strNombreCliente).length === 0) {
        bolExiste = false;
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede consultar los clientes. ' + mensaje);
    }

    return bolExiste;
  }

  async existeVendedor() {
    let bolExiste = true;
    let strNombreVend = '';
    try {
      const er = await this.cxctrnretencionesService.erVennombreProm(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].VEN_CODIGO);
      if (er !== null) {
        if (er[0] !== undefined) {
          strNombreVend = er[0].VEN_NOMBRE === null ? '' : er[0].VEN_NOMBRE;
        }
      }
      if (String(strNombreVend).length === 0) {
        bolExiste = false;
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede consultar los vendedores. ' + mensaje);
    }

    return bolExiste;
  }

  async verificarComprobanteUnico() {
    let bolVerificar = true;
    const strParam: any[] = [];
    if (this.bolNuevo) {
      strParam[0] = 'N';
      strParam[3] = '';
    } else {
      strParam[0] = 'M';
      if (this.cxctrnresumen.length > 0) {
        strParam[3] = this.cxctrnresumen[0].TRNCOBRO_NRODOC;
      } else {
        strParam[3] = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
      }
    }

    strParam[1] = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE;
    strParam[2] = '01';
    /*try {
      const rs = await this.cxctrnretencionesService.verificarComprobanteUnicoNew(strParam);
      console.log(rs[0]["'0'"]);
      console.log(rs[0][':B1']);
      if (rs[0][':B1'] !== undefined) {
        bolVerificar = false;
      }
			/*if (rs.next()) {
				if (!rs.getString(1).equals("0"))
					bolVerificar = false;
			} 
    }*//* catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede verificar si el comprobante es único ' + mensaje);
    }*/
    return bolVerificar;
  }

  verificarImporteDoc() {
    let bolVerificar = true;
    let intFilaSel = 0;
    let fila = 0;
    if (this.bolNuevo) {
      for (const item of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
        fila = fila + 1;

        if (Number(item.TRNCOBRO_IMPORTE) > Number(item.TRNCOBRO_SALDOINI)) {
          this.mensajeAlerta('info', 'Información', 'El importe es mayor que el saldo en la fila ' + fila);
          bolVerificar = false;
          return bolVerificar;
        }

        if (item.SELECCIONADO !== null) {
          if (item.SELECCIONADO === false) {
            intFilaSel = intFilaSel + 1;
          }
        }
      }
    }

    if (intFilaSel === this.cxctrnretencionesService.cxctrnresumenarray[this.indicador].length) {
      this.mensajeAlerta('info', 'Información', 'Ningún documento ha sido seleccionado.');
      bolVerificar = false;
      return bolVerificar;
    }

    return bolVerificar;
  }

  async verificarCobroDocVencidos() {
    let dblPorIntMor = 0;
    let strSentencia = '';
    let intNumDias = 0;
    let strPorIntMor = '';
    let dblMontoxMora = 0;
    let dblMontoxMoraTotal = 0;

    const strFechaDoc = this.obtenerFechaDocString();

    dblPorIntMor = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PORINTMOR').CFG_VALOR2; // porcentaje de interés por mora
    for (const item of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {
      if (item.SELECCIONADO !== null) {
        /*if (item.SELECCIONADO === true) {
          strSentencia = 'select to_date(\'' + strFechaDoc + '\',\'dd/mm/yyyy\')-to_date(\'' + item.TRNCOBRO_FECHAVENCE + '\',\'dd/mm/yyyy\') as RESULT  from dual ';
          try {
            const rs = await this.cxctrnretencionesService.ejecutarConsultaPromise(strSentencia);
            if (rs !== null) {
              intNumDias = Number(rs[0].RESULT);
            }

          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'Error al leer número de días ' + mensaje);
          }

          if (intNumDias >= 1) {
            dblMontoxMora = dblMontoxMora + Number(item.TRNCOBRO_IMPORTE);
          }
        }*/
      }

    }

    if (dblMontoxMora > 0) {
      dblMontoxMoraTotal = ((dblPorIntMor / 100) * dblMontoxMora);
    } else {
      dblMontoxMoraTotal = 0;
    }

    console.log(dblMontoxMoraTotal);

    return dblMontoxMoraTotal;
  }

  async verificarDiasMoraDoc() {
    let strSentencia = '';
    let intNumDias = 0;
    let strPorIntMor = '';
    let dblDiasGracia = 0;
    let dblTotalDiasMora = 0;

    const strFechaDoc = this.obtenerFechaDocString();

    dblDiasGracia = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DIAGRACIA').CFG_VALOR2; // días de gracia

    for (const item of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {

      if (item.SELECCIONADO !== null) {

        /*if (item.SELECCIONADO === true) {
          strSentencia = 'select to_date(\'' + strFechaDoc + '\',\'dd/mm/yyyy\')-(to_date(\'' + item.TRNCOBRO_FECHAVENCE + '\',\'dd/mm/yyyy\') + ' + dblDiasGracia + ') as RESULT  from dual ';

          try {
            const rs = await this.cxctrnretencionesService.ejecutarConsultaPromise(strSentencia);
            if (rs !== null) {
              intNumDias = Number(rs[0].RESULT);
            }

          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'Error al leer número de días ' + mensaje);
          }

          if (intNumDias >= 1) {
            dblTotalDiasMora = dblTotalDiasMora + intNumDias;
          }


        }*/

      }

    }
    return dblTotalDiasMora;
  }

  verificaSiAgregoDatosMora(intTotalDiasMora: number) {
    let intCodComParaMora = 0;
    let intCodComParaCobranza = 0;
    let bolResult = false;


    if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'APLPORINT').CFG_VALOR1 == 0) {
      return true;
    }

    // total otros gastos
    for (const item of this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador]) {
      const strCODCOMMOR = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODCOMMOR').CFG_VALOR3 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODCOMMOR').CFG_VALOR3;
      if (item.COMISION_CODIGO === strCODCOMMOR) {
        intCodComParaMora = 1;
      }

      const strCODCOMCOB = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODCOMCOB').CFG_VALOR3 === null ? '' : this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODCOMCOB').CFG_VALOR3;

      if (item.COMISION_CODIGO === strCODCOMCOB) {
        intCodComParaCobranza = 1;
      }

    }


    if (Number(intCodComParaCobranza) === 0) { //si no esta incluido lo de la cobranza
      if (Number(intTotalDiasMora) === 0) {//si no hay dias de mora
        intCodComParaCobranza = 1;
      }
    }


    if (Number(intCodComParaMora) === 0 || Number(intCodComParaCobranza) === 0) {
      bolResult = false;
    } else {
      bolResult = true;
    }

    return bolResult;

  }

  valorExcedeFormaPago() {
    let dblFormaPago = 0;
    let dblTotalCobrar = 0;
    let dblDiferencia = 0;
    let value: any = null;

    dblFormaPago = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALFORMAPAGO);
    dblTotalCobrar = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALACOBRAR);
    dblDiferencia = dblFormaPago - dblTotalCobrar;

    if (dblDiferencia > 0) {
      this.confirmationService.confirm({
        message: 'Transformar el valor que excede en la forma de pago en un anticipo ?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'conftrnretenciones',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.confirmationService.close();
          value = Number(dblDiferencia).toFixed(2);
          this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALANTICIPO = Number(value);
        }, reject: () => {
          this.confirmationService.close();
        }
      });
    }

  }

  eliminarLineaRetEnBlanco() {
    if (this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].length > 0) {
      for (const item of this.cxctrnretencionesService.cxctrnretencionarray[this.indicador]) {
        if (String(item.RETENCION_CODIGO).length === 0 && Number(item.TRNRETENCION_TOTALRETENIDO) === 0) {
          this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].splice(this.cxctrnretencionesService.cxctrnretencionarray[this.indicador].findIndex(
            dato => dato.LINEA === item.LINEA), 1);
        }
      }
      //this.aggrid.refreshColumnDefs();
      this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnretencionarray[this.indicador], this.defaultColumnDefCarteraRet.objeto);
    }
  }

  eliminarUltimaLineaOtrosG() {
    if (this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].length > 0) {
      for (const item of this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador]) {
        if (String(item.COMISION_CODIGO).length === 0 && Number(item.TRNCOMISION_TOTALRETENIDO) === 0) {
          this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].splice(this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador].findIndex(
            dato => dato.LINEA === item.LINEA), 1);
        }
      }
      this.aggrid.refreshColumnDefs();
      this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador], this.defaultColumnDefCarteraOtrosG.objeto);
    }
  }

  async faltaDatosRet() {
    let bolFalta = false;

    for (const item of this.cxctrnretencionesService.cxctrnretencionarray[this.indicador]) {
      let strCodigo = '';
      if (String(item.RETENCION_CODIGO).length === 0) {
        this.mensajeAlerta('info', 'Información', 'Ingrese el código de la Retención.');
        bolFalta = true;
      } else {
        if (String(item.RETENCION_CODIGO).length > 0) { //existencia de la retención
          try {
            const rs = await this.cxctrnretencionesService.erRetencioncodigoProm(item.RETENCION_CODIGO);
            strCodigo = rs[0].RETENCION_CODIGO === null ? '' : rs[0].RETENCION_CODIGO;

          } catch (err) {
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
            this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia de la retención ' + mensaje);
          }

          if (String(strCodigo).length === 0) {
            this.mensajeAlerta('info', 'Información', 'La retención no existe.');
            return bolFalta = true;
          }
        }
        if (String(item.TRNRETENCION_DESCRIPCION).length === 0 || String(item.TRNRETENCION_NRO).length === 0) {
          this.mensajeAlerta('info', 'Información', 'La descripción y el número de retención son obligatorios.');
          return bolFalta = true;
        }

        if (Number(item.TRNRETENCION_BASE) < 0) {
          this.mensajeAlerta('error', 'Error', 'La base de la retención no puede ser menor a cero.');
          return bolFalta = true;
        }

        if (Number(item.TRNRETENCION_PORCENTAJE) < 0) {
          this.mensajeAlerta('error', 'Error', 'El porcentaje de la retención no puede ser menor a cero.');
          return bolFalta = true;
        }

        if (Number(item.TRNRETENCION_TOTALRETENIDO) < 0) {
          this.mensajeAlerta('error', 'Error', 'El total de la retención no puede ser menor a cero.');
          return bolFalta = true;
        }
      }
    }
    return bolFalta;
  }

  // async faltaDatosOtrosG() {
  //   let bolFalta = false;

  //   for (const item of this.cxctrnretencionesService.cxctrnotrosgarray[this.indicador]) {
  //     let strCodigo = '';

  //     if (String(item.COMISION_CODIGO).length === 0) {
  //       this.mensajeAlerta('info', 'Información', 'Ingrese el código de la comisión.');
  //       bolFalta = true;
  //     } else {
  //       if (String(item.COMISION_CODIGO).length > 0) {
  //         try {
  //           const rs = await this.cxctrnretencionesService.erComisioncodigoProm(item.COMISION_CODIGO);
  //           strCodigo = rs[0].COMISION_CODIGO === null ? '' : rs[0].COMISION_CODIGO;
  //         } catch (err) {
  //           let mensaje: string;
  //           mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
  //           this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia  de la comisión ' + mensaje);
  //         }

  //         if (String(strCodigo).length === 0) {
  //           this.mensajeAlerta('info', 'Información', 'La comisión no existe.');
  //           return bolFalta = true;
  //         }
  //       }
  //       if (String(item.TRNCOMISION_DESCRIPCION).length === 0 || String(item.TRNCOMISION_NRO).length === 0) {
  //         this.mensajeAlerta('info', 'Información', 'La descripción y el número de la comisión son obligatorios.');
  //         return bolFalta = true;
  //       }
  //     }
  //   }

  //   return bolFalta;
  // }

  eliminarUltimaLineaFP() {
    if (this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].length > 0) {
      for (const item of this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador]) {
        if (String(item.TRNPAGO_TIPO).length === 0) {
          this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].splice(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador].findIndex(
            dato => dato.LINEA === item.LINEA), 1);
        }
      }
      this.aggrid.refreshColumnDefs();
      this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador], this.defaultColumnDefCarteraFormaPago.objeto);
    }
  }

  // async faltaDatosFP() {
  //   let bolFalta = false;

  //   for (const item of this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador]) {
  //     let strCodigo = '';

  //     if (String(item.TRNPAGO_TIPO).length === 0) { // si no existe tipo de pago
  //       this.mensajeAlerta('info', 'Información', 'Ingrese el tipo de pago.');
  //       bolFalta = true;
  //     } else {
  //       if (String(item.TRNPAGO_TIPO).length > 0) { //existencia de la forma de pago
  //         try {
  //           const rs = await this.cxctrnretencionesService.erFormapagotipoProm(item.TRNPAGO_TIPO);
  //           strCodigo = rs[0].FORMAPAGO_TIPO === null ? '' : rs[0].FORMAPAGO_TIPO;
  //         } catch (err) {
  //           let mensaje: string;
  //           mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
  //           this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia de la forma de pago' + mensaje);
  //         }

  //         if (String(strCodigo).length === 0) {
  //           this.mensajeAlerta('info', 'Información', 'La forma de pago no existe.');
  //           return bolFalta = true;
  //         }
  //       }

  //       if (String(item.TRNPAGO_NUMERO).length === 0 || String(item.TRNPAGO_FECHA).length === 0) {
  //         this.mensajeAlerta('info', 'Información', 'La información está incompleta.');
  //         return bolFalta = true;
  //       }

  //       if (item.TRNPAGO_TIPO !== 'DP' && item.TRNPAGO_TIPO !== 'ANCL') {
  //         if (String(item.CAJ_CODIGO).length === 0) {
  //           this.mensajeAlerta('error', 'Error', 'Ingrese la caja.');
  //           return bolFalta = true;
  //         }
  //       }
  //       //	PAGOS EN CHEQUE O TARJETA
  //       if (item.TRNPAGO_TIPO === 'TA' || item.TRNPAGO_TIPO === 'CH') {
  //         if (String(item.BANCLI_CODIGO).length === 0 || String(item.BANCLI_NOMBRE).length === 0 ||
  //           String(item.TRNPAGO_NROCTA).length == 0) {
  //           this.mensajeAlerta('error', 'Error', 'La información está incompleta.');
  //           return bolFalta = true;
  //         }
  //       }

  //       if (Number(item.TRNPAGO_IMPORTE) <= 0) {
  //         this.mensajeAlerta('error', 'Error', 'El pago no puede ser menor o igual a cero.');
  //         bolFalta = true;
  //       }

  //       if (String(item.CAJ_CODIGO).length > 0) {
  //         if (await this.verificarExistenciaCaja(item.CAJ_CODIGO) === false) {
  //           this.mensajeAlerta('error', 'Error', 'No existe la caja.');
  //           bolFalta = true;
  //         }
  //       }

  //     }
  //   }

  //   return bolFalta;
  // }

  async verificarExistenciaCaja(strCajCodigo) {
    let bolExiste = true;

    /*try {
      const rs = await this.cxctrnretencionesService.verificarExistenciaCaja(strCajCodigo);
      if (rs.length > 0) {
        if (rs[0].CAJ_CODIGO === null) {
          bolExiste = false;
          return bolExiste;
        }
      }
      else
        bolExiste = false;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia de la caja ' + mensaje);
      bolExiste = false;
    }*/

    return bolExiste;
  }

  // async verificarCodSri() {
  //   let bolEliminar = true;
  //   let strCodSri = '';

  //   for (const item of this.cxctrnretencionesService.cxctrnformapagoarray[this.indicador]) {
  //     if (item.TRNPAGO_TIPO !== '' && item.TRNPAGO_CODSRI !== '') {
  //       strCodSri = '';
  //       try {
  //         if (item.TRNPAGO_TIPO === 'TA') {
  //           const rs = await this.cxctrnretencionesService.erCodsricodigo1Prom(item.TRNPAGO_CODSRI);
  //           strCodSri = rs[0].CODSRI_CODIGO === null ? '' : rs[0].CODSRI_CODIGO;
  //         } else {
  //           const rs = await this.cxctrnretencionesService.erCodsricodigo2Prom(item.TRNPAGO_CODSRI);
  //           strCodSri = rs[0].CODSRI_CODIGO === null ? '' : rs[0].CODSRI_CODIGO;
  //         }

  //         if (strCodSri === '') {
  //           bolEliminar = false;
  //           item.TRNPAGO_CODSRI = '';
  //         }

  //       } catch (err) {
  //         let mensaje: string;
  //         mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
  //         this.mensajeAlerta('error', 'Error', 'No se puede verificar el codigo SRI ' + mensaje);
  //       }
  //     }
  //   }
  //   return bolEliminar;
  // }

  eliminarUltimaLineaVarios() {
    if (this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].length > 0) {
      for (const item of this.cxctrnretencionesService.cxctrnvariosarray[this.indicador]) {
        if (String(item.CON_CODIGO).length === 0) {
          this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].splice(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador].findIndex(
            dato => dato.LINEA === item.LINEA), 1);
        }
      }
      this.aggrid.refreshColumnDefs();
      this.aggrid.refreshaggrid(this.cxctrnretencionesService.cxctrnvariosarray[this.indicador], this.defaultColumnDefCarteraVarios.objeto);
    }
  }

  async faltaDatosVarios() {
    let bolFalta = false;
    let dblTotalVarios = this.calcularTotalVarios();
    let dblImporteDoc = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALNC) * (-1);
    let strNombreDoc = 'Nota de Crédito';

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC') {
      dblImporteDoc = Number(this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TOTALNC);
      strNombreDoc = 'Nota de Débito';
    }

    if (dblImporteDoc + dblTotalVarios !== 0) {
      this.mensajeAlerta('info', 'Información', 'El total de Varios debe ser igual al total del documento');
      bolFalta = true;
      return bolFalta;
    }

    for (const item of this.cxctrnretencionesService.cxctrnvariosarray[this.indicador]) {
      if (await this.existeCuentaContable(item.CON_CODIGO) === false) {
        this.mensajeAlerta('info', 'Información', 'No existe la cuenta contable');
        bolFalta = true;
        return bolFalta;
      }
    }
    return bolFalta;
  }

  async existeCuentaContable(strCodCuenta) {
    let bolExiste = false;

    try {

      const rs = await this.cxctrnretencionesService.erConcodigo3Prom(strCodCuenta);
      const strCuenta = rs[0].CON_CODIGO === null ? '' : rs[0].CON_CODIGO;

      if (String(strCuenta).length > 0) {
        bolExiste = true;
      }
    }
    catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede verificar la existencia de la cuenta contable ' + mensaje);
    }
    return bolExiste;
  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  async continuarFELiq() {
    this.closeSidebar();
    const msgclave = 'cxctrnretenciones';
    const strNumDoc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;

    if (strNumDoc === '' || strNumDoc === null || strNumDoc === undefined) {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'No ha seleccionado un documento'
      });
      return;
    }

    if (this.datosfe.COM_FEACTIVADA === '0') {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Facturación electrónica no está activada.'
      });
      return;
    }

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === ''
      || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === undefined
      || this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === null) {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento no ha iniciado el proceso de firma electrónica.'
      });
      return;
    }

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'VA') {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra en su estado inicial, reverse el proceso de firma electrónica.'
      });
      return;
    }

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'NT') {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'El documento se encuentra notificado.'
      });
      return;
    }

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBROND_ESTADO_FE === 'NA') {
      this.message.add({
        key: msgclave,
        severity: 'error',
        summary: 'Información',
        detail: 'Visualize el log. Corrija el documento y reverse el proceso de firma electrónica.'
      });
      return;
    }

    this.spin = true;
    const data = await this.utilitariosService.continuarFirmaElectronica(strNumDoc);
    this.spin = false;
    if (data !== 'NOAUTORIZADO') {
      this.message.add({
        key: msgclave,
        severity: 'success',
        summary: 'Información',
        detail: data
      });
    }
  }

  async actualizarNumeroComprobante(strAsiNro) {
    if (this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NUMASICOM').CFG_VALOR1 === 0) {
      return;
    }

    if (strAsiNro === '') {
      return;
    }

    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE !== '') {//si no esta en blanco el comprobante lo respeta
      return;
    }

    try {
      await this.cxctrnretencionesService.actualizarNumeroComprobante(strAsiNro, this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC);
      this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NROCOMPROBANTE = strAsiNro;
    } catch (err) {
      this.spin=false//CDPJ
      this.mensajeAlerta('error', 'Error', '! Proceso actualiza numero de comprobante  no terminó satisfactoriamente.... ! ' + err.error);
    }

  }

  verificarImporteTotalRet(): boolean {
    let bolVerificar = true;
    for (const itemDoc of this.cxctrnretencionesService.cxctrnresumenarray[this.indicador]) {

      if (itemDoc.SELECCIONADO === true) {
        let dblTotalRet = 0;
        for (const itemRet of this.cxctrnretencionesService.cxctrnretencionarray[this.indicador]) {

          if (itemDoc.TRNCOBRO_NRODOC === itemRet.ENCFAC_NUMERO) {
            dblTotalRet = dblTotalRet + Number(itemRet.TRNRETENCION_TOTALRETENIDO);
          }
        }

        dblTotalRet = Number(Number(dblTotalRet).toFixed(2));

        if (Number(itemDoc.TRNCOBRO_IMPORTE) != dblTotalRet) {
          this.mensajeAlerta('error', 'Error', 'El importe de la factura ' + itemDoc.TRNCOBRO_NRODOC +
            ' no es igual al total de las retenciones');
            this.spin=false//CDPJ
          bolVerificar = false;
          return bolVerificar;
        }
      }
    }

    return bolVerificar;
  }
  ////GSRF
  isEdit(data): boolean {
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' && this.editpagofr === true) {
      return false;
    }
    return true;
  }
  ////GSRF
  async actualizarNumeroAsiento(strAsiNro) {
    const strTrnCobroNroDoc = this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC;
    // FORMAS DE PAGO

    let lstFormaPago = [];

    const data = await this.cxctrnretencionesService.asibangettrnpago(strTrnCobroNroDoc);
    //CDPJ
    if(data !== null && data !== undefined){
      for (const rs of data) {
        lstFormaPago.push({
          strBanCodigo: rs.CAJ_CODIGO,
          strBanNumero: rs.TRNPAGO_NUMERO,
          strBanTipo: rs.TRNPAGO_TIPO
        });
      }
  
      // Actualiza en numero del asiento
  
      // Lee cada una de las formas de pago y actualiza el número del asiento en la transaccion de bancos
  
      // for (const item of lstFormaPago) {
      //   if (item.strBanTipo === "DP") {
      //     await this.cxctrnretencionesService.updateasientobantrnban(strAsiNro, item.strBanCodigo, item.strBanTipo, item.strBanNumero);
      //   } else {
      //     await this.cxctrnretencionesService.updateasientobantrncaj(strAsiNro, item.strBanCodigo, item.strBanTipo, item.strBanNumero);
      //   }
      // }
    }
    //CDPJ
  }

  isEditFP(data) {
    //CDPJ
    if (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'CA' ||
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'AB' ||
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'RT' ||
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'NC' ||
    this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN') {
      this.editConcil=false
  }
    //CDPJ
    if (this.editConcil) {
      return true;
    }
    return false;
  }
  //CDPJ
  idEditcajcodigo(data):boolean{
    console.log(data.TRNCAJ_TIPO)
    if ((this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_NRODOC !== '' && this.editpagofr === true)||
    (this.cxctrnretencionesService.encabezadoCarteraActivaarray[this.indicador].TRNCOBRO_TIPODOC === 'DAN' && data.TRNCAJ_TIPO=== 'CH')) 
    {
      console.log('entro')
      return false;
    }else{
      return true;
    }
  }

  //CDPJ

}
