import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { NomencrolgenecabeService } from '../nomservicios/nomencrolgenecabe.service';
import { ConfirmationService, MessageService } from 'primeng';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
//CDPJ
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { Usuario } from 'src/app/usuario';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
//CDPJ
@Component({
  selector: 'app-nomencrolgenecabe',
  templateUrl: './nomencrolgenecabe.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomencrolgenecabeComponent implements OnInit {
  opcion: string;
  types: SelectItem[];
  tabla: string;
  consulta: string;
  busquedacampos: any[];
  where: string;
  arregloCons: any[];
  arregloBus: any[];
  procAnticipo = false;
  displayDialogBusquedaFast = false;
spin=false;
  inputRolUno = {
    codigo: '',
    nombre: ''
  }
  inputRolDos = {
    codigo: '',
    nombre: ''
  }
  inputEmpleadoUno = {
    id: '',
    nombre: '',
    cod: ''
  }
  inputEmpleadoDos = {
    id: '',
    nombre: '',
    cod: ''
  }
  opcionSelec = '';

  fechasPeriodo: string;
  fechaValidaPeriodo;
  perCodigo = 0;
  constructor(
    private busquedaSer: InvbusquedaService,
    private nomProcLiqRolSrv: NomencrolgenecabeService,
    private confirmationService: ConfirmationService,
    private init: NuevoComponentService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private auditoriagral: AuditoriagralService,
    public usuario: Usuario
    , private utilitariosService: UtilitariosService
  ) { }

  ngOnInit(): void {
    this.spin=false;
    this.buscarPeriodo();
    
  }

  async buscarPeriodo(){

    await this.nomProcLiqRolSrv.buscarPeriodo().then( per => {
       //console.log(per);
       if ( per !== null  ) {

        let fechaInicio = this.convertDate( per[0].PERIODO_FECINI);
        let fechaFin    = this.convertDate(per[0].PERIODO_FECFIN) ;
  
        this.fechasPeriodo = `${fechaInicio}-${fechaFin}`
        this.fechaValidaPeriodo=fechaInicio
        this.perCodigo = per[0].PERIODO_CODIGO;
      }

    } ).catch( e => {
      console.error(e);
      /* let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje); */
    } );


  }  


  convertDate(date: string) {
    const fecha = new Date( date );

    let month = '' + (fecha.getMonth() + 1);
    let day = '' + fecha.getDate();
    let year = fecha.getFullYear();

    if( month.length < 2 ) { month = '0' + month }
    if( day.length < 2 ) { day = '0' + day }

    return `${day}/${month}/${year}`;


  }

  buscarRol(rol: string){

    if( rol === 'rolUno' ){
      this.opcionSelec = rol;
    } else if ( rol === 'rolDos' ){
      this.opcionSelec = rol;
    }
    

    this.opcion = 'BuscarRol';
    this.types = [
      {label: 'CÓDIGO', value: 'TIPROL_CODIGO'},
      {label: 'NOMBRE', value: 'TIPROL_NOMBRE'},
    ];
    this.busquedacampos = ['', ''];
    this.consulta= 'tiprol_codigo, tiprol_nombre'
    this.tabla= 'nom_maetiporol';

    this.where = '';

    this.busquedaSer.busquedaNomMaeTipoRol2().subscribe((res: any[]) => {
      
      this.arregloCons = [ ...res ];
      this.displayDialogBusquedaFast = true;
    });
  }

  buscarEmpleado( emp: string  ){

    if( emp === 'empUno' ){
      this.opcionSelec = emp;
    } else if ( emp === 'empDos' ){
      this.opcionSelec = emp;
    }


    this.opcion = 'Buscar Empleado';
    this.types = [
      {label: 'Código', value: 'EMP_CODIGO'},
      {label: 'ID', value: 'EMP_RUCIDE'},
      {label: 'Apellido', value: 'EMP_APELLIDO'},
      {label: 'Nombre', value: 'EMP_NOMBRE'},
      {label: 'Fecha Ingreso', value: 'EMP_FECINGRESO'},
      {label: 'Estado', value: 'EMP_ACTIVO'},

    ];
    this.busquedacampos = ['', '', '', '', '', ''];
    this.consulta= 'emp_codigo,emp_rucide,emp_nombre,emp_apellido,EMP_FECINGRESO,EMP_ACTIVO, COM_CODIGO'
    this.tabla= 'NOM_VW_MAEEMPLEADO_ACTIVO';

    this.where = ' COM_CODIGO = \'01\'';

    this.busquedaSer.busquedaNomvwmaeEmple1().subscribe((res: any[]) => {
      
      this.arregloCons = [ ...res ]
      this.displayDialogBusquedaFast = true;
    });
  }

  manejarSeleccion(e){

    switch (this.opcionSelec) {
      case 'rolUno':

        this.inputRolUno.codigo = e.TIPROL_CODIGO;
        this.inputRolUno.nombre = e.TIPROL_NOMBRE;
        
        break;
      case 'rolDos':

        this.inputRolDos.codigo = e.TIPROL_CODIGO;
        this.inputRolDos.nombre = e.TIPROL_NOMBRE;
        
        break;
      case 'empUno':

        this.inputEmpleadoUno.id = e.EMP_RUCIDE;
        this.inputEmpleadoUno.nombre = e.EMP_APELLIDO + ' ' + e.EMP_NOMBRE;
        this.inputEmpleadoUno.cod  =  e.EMP_CODIGO;
        
        break;
      case 'empDos':

        this.inputEmpleadoDos.id = e.EMP_RUCIDE;
        this.inputEmpleadoDos.nombre = e.EMP_APELLIDO + ' ' + e.EMP_NOMBRE;
        this.inputEmpleadoDos.cod  =  e.EMP_CODIGO;
        
        break;
    
      default:
        break;
    }

    this.displayDialogBusquedaFast = false;

  }

  manejarSenales(valor){
    switch (valor) {
      case 'Salir':
       this.init.tabs.splice(this.init.tabindex, 1);
      break;
    
    }
  }



  async aceptar(){

    //periodo
    this.spin=true;//CDPJ
      console.log(this.fechaValidaPeriodo);
      let dia = new Date().getDate();
      let mes = new Date().getMonth();
      let anio = new Date().getFullYear();
      // Verifica el periodo contable
      try {
        dia = this.fechaValidaPeriodo.getDate();
        mes = this.fechaValidaPeriodo.getMonth();
        anio = this.fechaValidaPeriodo.getFullYear();
      } catch (e) {
        const tmpfecha = this.fechaValidaPeriodo.substr(0, 10);
        const splfec = tmpfecha.split('/');
        const fecha = new Date(splfec[2], splfec[1] - 1, splfec[0]);
        dia = fecha.getDate();
        mes = fecha.getMonth();
        anio = fecha.getFullYear();
      }

      if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
        this.mensajeAlerta('error', 'Error', 'Error, fecha debe de estar dentro del período contable');
        this.spin=false;//CDPJ
        return;
      }
    





    if(  this.perCodigo === 0 ){

      this.mensajeAlerta('error', 'Error', 'No existe periodo activo o en proceso');
      this.spin=false;//CDPJ
    } else if( this.inputEmpleadoDos.id !== ''  ) {
      await this.nomProcLiqRolSrv.erEmpleadoRucide( "emp_apellido, emp_nombre", "nom_maeempleado","emp_rucide = '" + this.inputEmpleadoDos.id.trim() +"'")
        .then(  dato => {
          this.inputEmpleadoDos.nombre = dato[0].EMP_APELLIDO + ' ' + dato[0].EMP_NOMBRE;
        } ).catch( e => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
          this.spin=false;//CDPJ
        } );

      await this.nomProcLiqRolSrv.erEmpleadoRucide(  "emp_codigo", "nom_maeempleado", "emp_rucide = '" + this.inputEmpleadoDos.id.trim() +"'")
        .then(  dato => {
          this.inputEmpleadoDos.cod = dato[0].EMP_CODIGO
        } ).catch( e => {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
          this.spin=false;//CDPJ
        } );


      
      
    } else if( this.inputEmpleadoUno.id !== '' ) {

      this.inputEmpleadoDos.cod = this.inputEmpleadoUno.cod;
      this.inputEmpleadoDos.id  = this.inputEmpleadoUno.id;
      this.inputEmpleadoDos.nombre = this.inputEmpleadoUno.nombre;

    }

    this.continuar();

  }

  //GSRF
  async continuar(){
    console.log('*/*/*/',this.inputEmpleadoUno.cod,'*/*/*/',this.inputEmpleadoDos.cod)
      let nom: any[];
      let idempl= '';
      if( this.inputEmpleadoUno.id !== '' ) {
        nom =  await this.nomProcLiqRolSrv.buscaalgunosvac(this.perCodigo,this.inputEmpleadoUno.cod,this.inputEmpleadoDos.cod);
        console.log('*/*/*/1',nom)
        if(nom.length === 0){
         this.confirmAntiQuin();
        }else{
         for (const aux of nom) {
           idempl = idempl + aux.EMP_RUCIDE +', '
          }
          this.confirmationService.confirm({
           message: '¿los Empleados con código '+idempl+ 'tienen vacaciones pagadas o gozadas,'+ 
           'si liquida los roles nuevamente debe volver a guardar estos roles, desea continuar?',
           header: 'Confirmación',
           icon: 'pi pi-exclamation-triangle',
           key: 'confirmAntiQuin',
           acceptLabel: 'Sí',
           rejectLabel: 'No',
           accept: () => {
      
            this.confirmAntiQuin();
      
           },
           reject: () => {
            this.spin=false;//CDPJ
           }
         })
        }
        
      }else{
        nom = await this.nomProcLiqRolSrv.buscatodosvac(this.perCodigo);
        console.log('*/*/*/2',nom)
        if(nom.length === 0){
         this.confirmAntiQuin();
        }else{
         for (const aux of nom) {
           idempl = idempl + aux.EMP_RUCIDE +', '
          }
          this.confirmationService.confirm({
           message: '¿los Empleados con código '+idempl+ 'tienen vacaciones pagadas o gozadas,'+ 
           'si liquida los roles nuevamente debe volver a guardar estos roles, desea continuar?',
           header: 'Confirmación',
           icon: 'pi pi-exclamation-triangle',
           key: 'confirmAntiQuin',
           acceptLabel: 'Sí',
           rejectLabel: 'No',
           accept: () => {
      
            this.confirmAntiQuin();
      
           },
           reject: () => {
           }
         })
        }
        
       }
      
     }
   //GSRF


  confirmAntiQuin(){
    if(this.procAnticipo){
      this.confirmationService.confirm({
        message: '¿Está seguro que desea procesar anticipo de quincena?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmAntiQuin',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {

            this.funGEjecutaSP();

        },
        reject: () => {
          this.spin=false;//CDPJ
        }
      })
    } else  {
      this.funGEjecutaSP();
    }
  }


  async funGEjecutaSP(){
    let anticipo = (this.procAnticipo) ?  '1' : '0';
    await this.nomProcLiqRolSrv.genCabeceraDetalle(this.perCodigo, this.inputRolUno.codigo,
       this.inputRolDos.codigo, this.inputEmpleadoUno.nombre, this.inputEmpleadoDos.nombre, anticipo )
        .then( async () => {
          this.auditoriagral.registrarAuditoria('NOM_ENCROLINDI', `LiquidacionRol${this.perCodigo}-----01`, 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
          this.mensajeAlerta('success', 'Información', 'Proceso terminó satisfactoriamente');
          this.spin=false;//CDPJ

        } ).catch( e=>  {

          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', mensaje);
          this.spin=false;//CDPJ
        });
  }

  cancelar(){
    this.init.tabs.splice(this.init.tabindex, 1);
  }

  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'funGEjecutaSP',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }

}
